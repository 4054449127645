import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import { get } from 'lodash';

import BasicInput from '../common/input/basic-input';
import ExpandableSection from '../../../../common/expandable-section/components/expandable-section';
import ColorPalette from '../../../../common/form/components/color-pallete';
import Textarea from '../../../../common/form/components/text-area';
import CustomSelect from '../../../../common/form/components/select';
import CustomInput from '../../../../common/form/components/input';
import SettingsPoints from './settings-points';
import Button from '../../../../common/form/components/button';
import AccessRenderer from '../../../../common/access-renderer/components/access-renderer';

import Icon from '../../../../common/icon/components/icon';

import { FORMS } from '../../../../common/constants';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../common/permissions-constants';
import { areaGroupConstants, formConstants, textAreaMaxChars } from '../../constants/areas-constants';
import { clipTaskOptions } from '../../constants/inspection-settings';
import { validate } from './validators/area-validator';

import Helpers from '../../../../common/helpers';
import SearchSelect from '../common/input/search-select';

import '../../styles/explosive-zone-form.scss';

class AreaForm extends Component {
  changeClipTask = clipTask => {
    const { viewer, changeField } = this.props;
    const { Potree } = window;
    if (viewer && viewer.scene.pointclouds[0]) {
      viewer.setClipTask(Potree.ClipTask[clipTask.value]);
    }
    changeField(formConstants.fields.clipTask, clipTask.value);
  };

  formatDate = input => {
    if (!input) return;

    return Helpers.getDateFromUnix(input);
  };

  render() {
    const { handleSubmit, markOn3d, geometry, viewer, openDeleteArea, user, changeField, areaGroups, areaGroupID, getAreaGroups, formValues, invalid, selectedArea } = this.props;
    const areaGroupsData = areaGroups.map(el => ({ label: el[areaGroupConstants.fields.name], value: el[areaGroupConstants.fields.id] }));
    const selectedAreaGroup = areaGroupsData.find(el => el.value === areaGroupID);
    const { t } = this.context;

    return (
      <AccessRenderer
        visibleFor={PERMISSIONS[PERMISSION_TYPES.areas].edit.name}
        id={selectedArea?.[formConstants.fields.createByUserID]}
        ownerRequiredPermission={PERMISSIONS[PERMISSION_TYPES.areas].create.name}
      >
        {({ hasAccess }) => {
          const readonly = !hasAccess;

          return (
            <form className="defect-form component-form" onSubmit={handleSubmit} noValidate>
              <ExpandableSection expanded={true} title={t('SECTION_TITLE.AREAS')}>
                <Field id={formConstants.fields.areaGroupId} name={formConstants.fields.areaGroupId} component={CustomInput} isHidden={true} type="text" />
                <Field id={formConstants.fields.color} name={formConstants.fields.color} disabled={readonly} component={ColorPalette} />
                {!get(geometry, `coordinates[0]`) && (
                  <div className="add-pin">
                    <div className="missing-pin">
                      <Icon name="warning" warning handleHover={false} size="md" />
                      <p className="f-warning missing-pin__text">{t('WARNING.MISSING_PIN')}</p>
                    </div>
                    <p className="f-warning add-pin__description">{t('AREA.WARNING.MISSING_PIN.DESCRIPTION')}</p>
                    <Button type="button" disabled={invalid || readonly} onClick={() => markOn3d(formValues)} text={t('ADD_AREA.MARK_ON_3D')} />
                    {invalid && <p className="f-secondary-red">{t('AREA.MISSING_PIN.FORM_INVALID.ERROR_MESSAGE')}</p>}
                  </div>
                )}
                <Field
                  id={formConstants.fields.name}
                  name={formConstants.fields.name}
                  disabled={readonly}
                  component={BasicInput}
                  placeholder={t('AREA_DETAILS.FORM_NAME')}
                  label={t('AREA_DETAILS.FORM_NAME')}
                  labelClass="f-secondary-dark defect-form__label"
                  type="text"
                />
                <Field
                  id={formConstants.fields.selectedClipTask}
                  disabled={readonly}
                  disabledItems={readonly ? clipTaskOptions : []}
                  name={formConstants.fields.selectedClipTask}
                  onChange={this.changeClipTask}
                  component={CustomSelect}
                  data={clipTaskOptions}
                  placeholder={t('INSPECTION_SETTINGS.FORM_CLIP_TASK')}
                  label={'INSPECTION_SETTINGS.FORM_CLIP_TASK'}
                  labelClass="f-secondary-dark"
                />
                <div className="inline-wrapper">
                  <Field
                    id={formConstants.fields.areaGroup}
                    disabled={readonly}
                    name={formConstants.fields.areaGroup}
                    component={SearchSelect}
                    handleChange={areaGroup => {
                      changeField(formConstants.fields.areaGroupId, areaGroup.value);
                    }}
                    selectedSuggestion={selectedAreaGroup}
                    suggestions={areaGroupsData}
                    fetchSuggestions={searchText => getAreaGroups(searchText)}
                    clearSuggestions={() => null}
                    className="inline-wrapper__left-content"
                    placeholder={t('AREA_DETAILS.FORM_AREA_GROUP_PLACEHOLDER')}
                    label={'AREA_DETAILS.FORM_AREA_GROUP'}
                    labelClass="f-secondary-dark inline-wrapper__left-content"
                    type="text"
                  />
                </div>
                <Field
                  id={formConstants.fields.description}
                  name={formConstants.fields.description}
                  disabled={readonly}
                  enableAutoResize={true}
                  component={Textarea}
                  maxChars={textAreaMaxChars}
                  labelClass="f-secondary-dark defect-form__label"
                  placeholder={'AREA_DETAILS.FORM_DESCRIPTION'}
                  label={'AREA_DETAILS.FORM_DESCRIPTION'}
                  wrapperClassName={'inspection-input'}
                />

                <Field
                  id={formConstants.fields.createdDate}
                  disabled={true}
                  name={formConstants.fields.createdDate}
                  component={BasicInput}
                  format={this.formatDate}
                  placeholder={t('AREA_DETAILS.DATE_CREATED')}
                  label={t('AREA_DETAILS.DATE_CREATED')}
                  labelClass="f-secondary-dark defect-form__label"
                  type="text"
                />
                <Field
                  id={formConstants.fields.createdBy}
                  disabled={true}
                  name={formConstants.fields.createdBy}
                  component={BasicInput}
                  placeholder={t('AREA_DETAILS.CREATED_BY')}
                  label={t('AREA_DETAILS.CREATED_BY')}
                  labelClass="f-secondary-dark defect-form__label"
                  type="text"
                />

                <SettingsPoints disabled={!hasAccess} changeField={changeField} focusCamera={false} viewer={viewer} />
              </ExpandableSection>
              <div className="buttons">
                <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.areas].delete.name} id={selectedArea?.createByUserID}>
                  {({ hasAccess }) => {
                    return <Button type="button" onClick={openDeleteArea} disabled={Helpers.isGuestUser(user) || !hasAccess} variant="danger-outline" text={t('AREA.FORM_DELETE_TEXT')} />;
                  }}
                </AccessRenderer>
              </div>
            </form>
          );
        }}
      </AccessRenderer>
    );
  }
}

AreaForm.contextTypes = {
  t: PropTypes.func.isRequired,
};
AreaForm = reduxForm({
  form: FORMS.areasForm,
  validate,
  enableReinitialize: true,
})(AreaForm);

export default AreaForm;
