import React, { Component } from 'react';
import ToggleButton from 'react-switch';

import '../styles/toggle.scss';

export default class Toggle extends Component {
  render() {
    const { value, onChange } = this.props;
    return (
      <ToggleButton
        className={`custom-toggle${value ? '' : ' unchecked'}`}
        width={35}
        height={18}
        handleDiameter={19}
        onChange={onChange}
        checked={value}
        checkedIcon={<div />}
        uncheckedIcon={<div />}
      />
    );
  }
}
