export const columnTypes = {
  source: 'sourceColumn',
  issuedKeys: 'issuedKeysColumn',
  freeKeys: 'freeKeys',
};

export const fields = {
  issuedKeys: {
    name: 'LockedKeys',
    label: 'PERMIT_KEYBOX.ISSUED_KEYS',
    placeholder: 'PERMIT_KEYBOX.ISSUED_KEYS_PLACEHOLDER',
    errorRequired: 'PERMIT_KEYBOX.ISSUED_KEYS_REQUIRED',
    id: 'permit-keybox-issuedKeys',
    showInDisplay: true,
    type: columnTypes.issuedKeys,
  },
  freeKeys: {
    label: 'PERMIT_KEYBOX.FREE_KEYS',
    placeholder: 'PERMIT_KEYBOX.FREE_KEYS_PLACEHOLDER',
    errorRequired: 'PERMIT_KEYBOX.FREE_KEYS_REQUIRED',
    id: 'permit-keybox-freeKeys',
    showInDisplay: true,
    type: columnTypes.freeKeys,
  },
  assignedKey: {
    name: 'IssuedKeyName',
    label: 'PERMIT_KEYBOX.ASSIGNED_KEY',
    placeholder: 'PERMIT_KEYBOX.ASSIGNED_KEY_PLACEHOLDER',
    errorRequired: 'PERMIT_KEYBOX.ASSIGNED_KEY_REQUIRED',
    id: 'permit-keybox-assignedKey',
    showInDisplay: true,
  },
  editable: {
    name: 'Editable',
    label: 'PERMIT_KEYBOX.SOURCE',
    placeholder: 'PERMIT_KEYBOX.SOURCE_PLACEHOLDER',
    errorRequired: 'PERMIT_KEYBOX.SOURCE_REQUIRED',
    id: 'permit-keybox-editable',
    showInDisplay: true,
    type: columnTypes.source,
  },
  id: {
    name: 'ID',
  },
  name: {
    name: 'Name',
  },
  keys: {
    name: 'Keys',
  },
  status: {
    name: 'Status',
  },
  issuedKeyName: {
    name: 'IssuedKeyName',
  },
};

export const singleKeyFields = {
  id: 'ID',
  name: 'Name',
  issuedTo: 'IssuedTo',
  issuedToId: 'IssuedToID',
};

export const sortDirection = {
  asc: 'ASC',
  desc: 'DESC',
};

export const filterProps = {
  sortByColumn: 'SortByColumn',
  sortDirection: 'SortDirection',
  perPage: 'PerPage',
  lastSeen: 'LastSeen',
  searchText: 'SearchText',
  hasNext: 'HasNext',
  totalItems: 'TotalItems',
};

export const defaultFilters = {
  [filterProps.sortByColumn]: fields.id.name,
  [filterProps.sortDirection]: sortDirection.asc,
  [filterProps.perPage]: Number.MAX_SAFE_INTEGER,
  [filterProps.lastSeen]: 0,
  [filterProps.searchText]: '',
  [filterProps.hasNext]: false,
};

export const tableConfig = [
  {
    title: 'PERMIT_KEYBOX.NAME',
    key: fields.name.name,
  },
  {
    title: 'PERMIT_KEYBOX.ISSUED_KEYS',
    key: fields.issuedKeys.name,
    type: columnTypes.issuedKeys,
  },
  {
    title: 'PERMIT_KEYBOX.SOURCE',
    key: fields.editable.name,
    type: columnTypes.source,
  },
];

export const initialValuesProp = 'Keyboxes';
