import React from 'react';

const ProjectWrapper = props => {
  const { title, children, className } = props;
  return (
    <div className={`separated-list ${className}`}>
      <div className="header">
        <h5 className="noselect f-primary">{title}</h5>
      </div>
      {React.Children.map(children, child => React.cloneElement(child))}
    </div>
  );
};
export default ProjectWrapper;
