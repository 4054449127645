export const componentFetchParams = {
  PerPage: Number.MAX_SAFE_INTEGER,
  LastSeen: 0,
  SearchText: '',
  SortByColumn: 'ID',
  SortDirection: 'ASC',
};

export const fields = {
  permit: {
    name: 'PermitID',
  },
  componentId: {
    name: 'ID',
  },
  component: {
    name: 'ComponentID',
  },
  componentName: {
    name: 'Name',
  },
  files: {
    name: 'Files',
  },
  colour: {
    name: 'Colour',
  },
};

export const initialValuesProp = 'Components';

export const fileFields = {
  id: 'FileID',
  name: 'FileName',
  thumbnailUrl: 'ThumbnailURL',
  url: 'URL',
};

export const componentDetailsDisplayProps = [
  { name: fields.componentId.name },
  {
    name: fields.componentName.name,
  },
  {
    name: 'Code',
  },
  {
    name: 'Type',
  },
  {
    name: 'SubType',
  },
];
