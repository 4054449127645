export const formConstants = {
  fields: {
    id: 'ID',
    name: 'Name',
    description: 'Description',
    dateCreated: 'CreatedAt',
    dateCompleted: 'CompletedAt',
    creator: 'CreatedBy',
    importance: 'Importance',
    componentName: 'ComponentName',
    componentCode: 'ComponentCode',
    status: 'Status',
    addtionalComments: 'AdditionalNotes',
    startDate: 'StartDate',
  },
};

export const filterFields = {
  projectID: 'ProjectID',
  inspectionId: 'InspectionID',
  searchText: 'SearchText',
  sortByColumn: 'SortByColumn',
  sortByDirection: 'SortByDirection',
  perPage: 'PerPage',
  lastSeen: 'LastSeen',
  hasNext: 'HasNext',
};

export const sortDirection = {
  asc: 'ASC',
  desc: 'DESC',
};

export const checklistItemType = {
  number: 'NUMBER',
  checkbox: 'CHECKBOX',
  text: 'TEXT',
};

export const questionFields = {
  id: 'ID',
  name: 'Name',
  question: 'Question',
  type: 'ChecklistItemTypeValue',
  addtionalComments: 'AdditionalNotes',
  answer: 'Answer',
};

export const defaultFilter = {
  [filterFields.searchText]: '',
  [filterFields.sortByColumn]: formConstants.fields.name,
  [filterFields.sortByDirection]: sortDirection.asc,
  [filterFields.perPage]: 30,
  [filterFields.lastSeen]: 0,
};

export const fileFields = {
  name: 'FileName',
};

export const fileTypes = {
  taskFiles: 'MAINTENANCE_TASK_FILES',
};

export const uploadGroup = 'OTHER_FILES';

export const importanceType = 'importance';

export const statusType = 'status-type';
