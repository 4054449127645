import React from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';

import Checkbox from '../../../../common/form/components/checkbox';
import Icon from '../../../../common/icon/components/icon';
import InfoTooltip from '../../../../common/tooltip/components/info-tooltip';
import SearchInput from '../../../../common/input/components/search-input';
import Helpers from '../../../../common/helpers';
import { assigneeFields, userDetailsDisplayProps } from './constants/constants';

import '../../styles/add-assignees.scss';

const AddContractors = ({ usersAndTeams, workorderContractors, handleUsersAndTeamsSearch, addContractorToWorkorder, removeContractorFromWorkorder, closeConfirmModal }, { t }) => {
  const handleUserAssigned = (item, isSelected) => {
    if (isSelected) {
      removeContractorFromWorkorder(item);
    } else {
      addContractorToWorkorder(item);
    }
  };

  return (
    <div className="add-assignees-container">
      <div className="input-with-dropdown">
        <label className="search-input-label">{t('CHOOSE_WORK_ORDER_NOTIFICATION.ADD_CONTRACTORS')}</label>
        <SearchInput
          onChange={handleUsersAndTeamsSearch}
          placeholder={t('PROJECT.SEARCH.PLACEHOLDER')}
          includeDropdown={true}
          items={
            usersAndTeams && usersAndTeams.length
              ? usersAndTeams.map(item => {
                  return { ...item, PermitTestExpirationDate: item.PermitTestExpirationDate ? Helpers.getDateFromUnix(item.PermitTestExpirationDate) : null };
                })
              : []
          }
          emptyStateLabel={'CREATE_WORKORDER_FORM.USERS_TEAMS_DROPDOWN_EMPTY_STATE'}
          renderItem={(item, index) => {
            const isSelected = find(workorderContractors, i => `${item[assigneeFields.type]}_${item[assigneeFields.id]}` === `${i[assigneeFields.type]}_${i[assigneeFields.id]}`);

            return (
              <div
                className={`dropdown-render-item-container ${isSelected ? 'selected' : ''}`}
                key={`work-order-assignee-${item[assigneeFields.id]}`}
                onClick={() => handleUserAssigned(item, isSelected)}
              >
                <div className="user-checkbox-wrapper">
                  {index === 0 && <p className="f-secondary-dark user-title bold">{t('CHOOSE_WORK_ORDER_NOTIFICATION.USER_TITLE')}</p>}
                  <div>
                    <Checkbox meta={{}} input={{ value: isSelected }} />
                  </div>
                  <p className="f-primary light-bold">{item[assigneeFields.name]}</p>
                </div>
                <div className="user-email-wrapper">
                  {index === 0 && <p className="f-secondary-dark user-title bold">{t('CHOOSE_WORK_ORDER_NOTIFICATION.COMPANY_TITLE')}</p>}
                  <p className="f-primary light-bold">{item[assigneeFields.company] || '-'}</p>
                </div>
              </div>
            );
          }}
        />
      </div>
      <div className="added-items">
        <p className="f-secondary-dark bold">{t('CREATE_WORKORDER_FORM.ADDED_COMPONENTS', { number: workorderContractors ? workorderContractors.length : 0 })}</p>
        <div className="added-items-list">
          {workorderContractors &&
            workorderContractors.map((item, index) => {
              return (
                <div className="item-r" key={`work-order-assigned-${item[assigneeFields.id]}`}>
                  <p className="f-primary c-title">{`${index + 1}. ${item[assigneeFields.name]}`}</p>
                  <InfoTooltip
                    actionsMenu={Helpers.mapInfoIconDisplayProps(item, userDetailsDisplayProps)}
                    offsetY={-330}
                    offsetX={10}
                    Component={() => <Icon name="info" size="sm" />}
                    componentProps={{}}
                    title={item[assigneeFields.name]}
                  />
                  <Icon name="close" className="x-hover pointer" onClick={() => removeContractorFromWorkorder(item, closeConfirmModal)} />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

AddContractors.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default AddContractors;
