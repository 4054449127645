import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { isEmpty } from 'lodash';

import CustomInput from '../../form/components/input';
import { formFields, fields } from '../constants/section-constants';
import { validateSection } from '../validators/validators';

const EditableSectionTitle = ({ sectionIndex, ID, handleUpdateSection, isDisabled, sections, sectionUpdateDebounce }, { t }) => {
  const { name } = formFields;

  const onChange = (_e, value) => {
    const values = { [fields.name]: value };

    const errors = validateSection(values, sections);

    if (isEmpty(errors) && typeof handleUpdateSection === 'function') {
      values[fields.id] = ID;
      handleUpdateSection(values);
    } else if (sectionUpdateDebounce && typeof sectionUpdateDebounce.cancel === 'function') {
      sectionUpdateDebounce.cancel();
    }
  };

  return (
    <Field
      disabled={isDisabled}
      id={`section-name-${sectionIndex}`}
      name={name.name}
      component={CustomInput}
      placeholder={name.placeholder}
      label={`${sectionIndex + 1}. ${t(name.label)}`}
      onChange={onChange}
      size="lg"
      withHiddenError
    />
  );
};

EditableSectionTitle.contextTypes = {
  t: PropTypes.func.isRequired,
};

EditableSectionTitle.propTypes = {
  sectionIndex: PropTypes.number.isRequired,
  ID: PropTypes.number.isRequired,
  formValues: PropTypes.object,
  validationProps: PropTypes.object,
  handleSectionChange: PropTypes.func,
  handleFormChange: PropTypes.func,
  isDisabled: PropTypes.bool,
};

EditableSectionTitle.defaultProps = {
  isDisabled: false,
};

export default EditableSectionTitle;
