import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../icon/components/icon';
// import InfoTooltip from '../../tooltip/components/info-tooltip';
// import Helpers from '../../helpers';
// import { userTeamFields, userDetailsDisplayProps } from '../constants/constants';
import { userTeamFields } from '../constants/constants';

import '../styles/select-users-teams-multiple.scss';

const AddedUsersTeamsList = ({ addedUsersAndTeams, removeAssignee, closeConfirmModal }, { t }) => {
  return (
    <div className="added-items">
      <p className="f-secondary-dark bold">{t('CREATE_WORKORDER_FORM.ADDED_COMPONENTS', { number: addedUsersAndTeams ? addedUsersAndTeams.length : 0 })}</p>
      <div className="added-items-list">
        {addedUsersAndTeams &&
          addedUsersAndTeams.map((item, index) => {
            return (
              <div className="item-r" key={`users-teams-assigned-${item[userTeamFields.id]}`}>
                <p className="f-primary c-title">{`${index + 1}. ${item[userTeamFields.name]}`}</p>
                {/* <InfoTooltip
                  actionsMenu={Helpers.mapInfoIconDisplayProps(item, userDetailsDisplayProps)}
                  offsetY={-330}
                  offsetX={10}
                  Component={() => <Icon name="info" size="sm" />}
                  componentProps={{}}
                  title={item[userTeamFields.name]}
                /> */}
                <Icon name="close" className="x-hover pointer" onClick={() => removeAssignee(item, closeConfirmModal)} />
              </div>
            );
          })}
      </div>
    </div>
  );
};

AddedUsersTeamsList.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default AddedUsersTeamsList;
