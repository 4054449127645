export const tabConstants = {
  viewModes: {
    tabKey: 'VIEW_MODES',
    title: 'INSTRUCTIONS_SCREEN.VIEW_MODES',
  },
  viewerControls: {
    tabKey: 'VIEWER_CONTROLS',
    title: 'INSTRUCTIONS_SCREEN.VIEWER_CONTROLS',
  },
  panControls: {
    tabKey: 'PAN_CONTROLS',
    title: 'INSTRUCTIONS_SCREEN.PAN_CONTROLS',
  },
  controls360: {
    tabKey: '360_CONTROLS',
    title: 'INSTRUCTIONS_SCREEN.360_CONTROLS',
  },
};

export const keyboardKeyTypes = {
  letter: 'letter',
  arrowUp: 'arrowUp',
  arrowDown: 'arrowDown',
  arrowLeft: 'arrowLeft',
  arrowRight: 'arrowRight',
  scroll: 'scroll',
  leftClick: 'left-click',
  rightClick: 'right-click',
  none: 'none',
};
