import AuthApi from '../../../api/create-project/actions';
import { getContactListSuccess, getContactListFailure } from './action-creators';
import Helpers from '../../../common/helpers';
import routesConstants from '../../../common/routes-constants';

export const createProject = values => {
  return async dispatch => {
    try {
      await AuthApi.createProject(values);
      Helpers.goTo(routesConstants.routes.protectedRoutes.project.fullPath);
    } catch (e) {
      console.error(e);
    }
  };
};
export const editProject = values => {
  return async dispatch => {
    try {
      await AuthApi.editProject(values);
      Helpers.goTo(routesConstants.routes.protectedRoutes.project.fullPath);
    } catch (e) {
      console.error(e);
    }
  };
};

export const getContactList = company_id => {
  return async dispatch => {
    try {
      const res = await AuthApi.getContacts([{ company_id }]);
      const {
        data: { Data },
      } = res;
      if (Data && Data.Contacts) {
        dispatch(getContactListSuccess(Data.Contacts));
        dispatch(getContactListFailure(null));
      }
    } catch (e) {
      dispatch(getContactListFailure(e));
    }
  };
};
