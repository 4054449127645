import { debounce, find, get, isEmpty, isEqual, some, toInteger } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Helpers from '../../../../common/helpers';
import { getInspectionAreasClustered } from '../../actions/area-actions';
import { getInspectionExplosiveZonesClustered } from '../../actions/explosive-zones-actions';
import { getInspectionComponentsClustered, getInspectionMeasurementsClustered, getInspectionObservationsClustered } from '../../actions/inspection-actions';
import { getInspectionNDTMeasurementsClustered } from '../../actions/ndt-actions';
import { getInspectionNotificationsClustered } from '../../actions/notification-actions';
import { modules } from '../../constants/constants';
import { formConstants as inspectionSettingsConstants, userSettings } from '../../constants/inspection-settings';
import { filterProps } from '../notifications/constants/constants';
import { getMeasurementGroupsClustered } from '../readings-and-gauges/actions/measurement-group-actions';
import { getMeasurementLocationsClustered, getSelectedMeasurementLocationClustered } from '../readings-and-gauges/actions/measurement-location-actions';
import { getMeasurementPointsClustered } from '../readings-and-gauges/actions/measurement-point-actions';
import { viewOptions } from '../readings-and-gauges/constants/constants';

class BaseModule extends Component {
  constructor(props) {
    super(props);

    const { location } = props;
    const { query } = location;

    this.state = {
      filters: {},
      prevCameraPosition: null,
    };

    this.viewChangedDebounce = debounce(this.handleOnViewChange, 600);
    this.projectID = toInteger(get(query, 'project_id'));
  }

  componentDidMount() {
    const { viewer, module, searchText } = this.props;
    if (viewer) {
      Helpers.addViewerControlsListener(viewer, this.viewChangedDebounce);
      this.getInspectionElementsClustered(null, module, searchText);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      viewer,
      queryItem,
      module,
      searchText,
      elements,
      chHierarchyID,
      ShowNestedComponents,
      levels,
      chShowAll,
      displayUnassignedComponentsLevel,
      view,
      selectedMeasurementLocation,
      visibleGroupID,
      selectedMeasurementGroup,
    } = this.props;
    if (viewer && prevProps.viewer !== viewer) {
      if (prevProps.viewer) {
        Helpers.removeViewerControlsListener(prevProps.viewer, this.viewChangedDebounce);
      }
      Helpers.addViewerControlsListener(viewer, this.viewChangedDebounce);

      this.getInspectionElementsClustered(null, module, searchText);
    }
    if (
      (viewer && (queryItem !== prevProps.queryItem || this.elementsChanged(prevProps.elements, elements))) ||
      prevProps.chHierarchyID !== chHierarchyID ||
      prevProps.ShowNestedComponents !== ShowNestedComponents ||
      this.elementsChanged(prevProps.levels, levels) ||
      prevProps.chShowAll !== chShowAll ||
      prevProps.displayUnassignedComponentsLevel !== displayUnassignedComponentsLevel ||
      prevProps.selectedMeasurementLocation !== selectedMeasurementLocation ||
      prevProps.view !== view ||
      prevProps.visibleGroupID !== visibleGroupID ||
      prevProps.selectedMeasurementGroup !== selectedMeasurementGroup
    ) {
      this.getInspectionElementsClustered(null, module, searchText);
    }
  }

  elementsChanged = (prevElements, currentElements) => {
    if (prevElements?.length !== currentElements?.length) return true;
    // ARRAYS HAS SAME LENGTH
    else if (!isEmpty(prevElements)) {
      return some(prevElements, (element, index) => element.ID !== currentElements[index].ID || element.visible !== currentElements[index].visible); // Array has the same length but don't have same elements
    }
    return false;
  };

  componentWillUnmount() {
    const { viewer } = this.props;
    this.viewChangedDebounce.cancel();
    if (viewer) {
      Helpers.removeViewerControlsListener(viewer, this.viewChangedDebounce);
    }
  }

  handleOnViewChange = e => {
    const { module, searchText } = this.props;
    this.getInspectionElementsClustered(e, module, searchText, {}, true);
  };

  getInspectionElementsClustered = (e, activePage, search, additionalProps = {}, checkIsCamChanged = false, callback) => {
    const { filters, prevCameraPosition } = this.state;
    const {
      getInspectionObservationsClustered,
      getInspectionComponentsClustered,
      // getInspectionMeasurementsClustered,
      getInspectionNDTMeasurementsClustered,
      getInspectionAreasClustered,
      // getInspectionExplosiveZonesClustered,
      getInspectionNotificationsClustered,
      getMeasurementLocationsClustered,
      getSelectedMeasurementLocationClustered,
      getMeasurementPointsClustered,
      inspectionId,
      projectId,
      viewer,
      queryItem,
      elements,
      inspectionDetails,
      chShow,
      chHierarchyID,
      ShowNestedComponents,
      levels,
      chHideAll,
      hideUnassignedComponents,
      displayUnassignedComponentsLevel,
      is3DViewModeActive,
      user: { Setting360View },
      view,
      selectedMeasurementLocation,
      areAllMeasurementGroupsHidden,
      getMeasurementGroupsClustered,
      visibleGroupID,
      selectedMeasurementGroup,
    } = this.props;
    const defaultCameraPosition =
      inspectionDetails && inspectionDetails.CameraPosition && !isEmpty(inspectionDetails.CameraPosition.coordinates) ? inspectionDetails.CameraPosition.coordinates : undefined;
    const cameraPosition = Helpers.getCameraPosition(viewer, defaultCameraPosition);

    if (checkIsCamChanged && isEqual(prevCameraPosition, cameraPosition)) {
      // No need to update, camera position has not been changed
      return;
    }

    additionalProps = {
      CameraPosition: {
        coordinates: [cameraPosition.x, cameraPosition.y, cameraPosition.z],
      },
      SelectedItemID: queryItem || 0,
      HiddenItems: (elements || []).filter(el => !el.visible).map(el => el.ID),
      ...filters,
      ...additionalProps,
    };

    if (activePage === modules.components) {
      additionalProps = {
        ...additionalProps,
        ProjectID: this.projectID,
        HierarchyID: chHierarchyID ? chHierarchyID : null,
        ShowNestedComponents,
        HideAllComponents: chHideAll,
        HideUnassignedComponents: hideUnassignedComponents,
        HiddenLevels: (levels || []).filter(el => !el.visible && typeof el.ID === 'number').map(el => el.ID),
      };
    }

    if (activePage === modules.notifications) {
      additionalProps = {
        ...additionalProps,
        [filterProps.severityFilter]: filters[filterProps.severityFilter]?.length === 2 ? filters[filterProps.severityFilter] : [0, 10],
      };
    }

    if (!is3DViewModeActive) {
      // CLUSTERING CONFIG FOR 360 VIEW - OVERRIDE
      additionalProps = {
        ...additionalProps,
        [inspectionSettingsConstants.fields.numberOfRings]: 2,
        [inspectionSettingsConstants.fields.numberOfSlices]: 0,
        [inspectionSettingsConstants.fields.initialDistance]: Setting360View[userSettings.fields.maxTagsDistance],
        [inspectionSettingsConstants.fields.multiplier]: 2,
      };
    }

    switch (activePage) {
      case modules.defects:
        getInspectionObservationsClustered(inspectionId, activePage, search, additionalProps, callback);
        // getInspectionComponentsClustered(inspectionId, activePage, search, pick(additionalProps, ['CameraPosition']), callback);
        break;
      case modules.components:
        getInspectionComponentsClustered(inspectionId, activePage, search, additionalProps, callback, chShow, displayUnassignedComponentsLevel);
        break;
      // case modules.measurements:
      //   getInspectionMeasurementsClustered(inspectionId, activePage, search, additionalProps, callback);
      //   break;
      case modules.ndtData:
        getInspectionNDTMeasurementsClustered(inspectionId, activePage, search, additionalProps, callback);
        break;
      case modules.areas:
        getInspectionAreasClustered(inspectionId, activePage, search, additionalProps, callback);
        break;
      // case modules.explosiveZones:
      //   getInspectionExplosiveZonesClustered(inspectionId, activePage, search, additionalProps, callback);
      //   break;
      case modules.notifications:
        getInspectionNotificationsClustered(inspectionId, activePage, search, additionalProps, callback);
        break;
      case modules.readingsAndGauges:
        if (view === viewOptions.points) {
          getMeasurementPointsClustered(inspectionId, activePage, search, { ...additionalProps, ProjectID: projectId }, callback);
        } else if (view === viewOptions.location_and_points) {
          if (selectedMeasurementLocation) {
            getMeasurementPointsClustered(inspectionId, activePage, search, { ...additionalProps, ProjectID: projectId, MeasurementLocationID: selectedMeasurementLocation.ID }, callback);
            // Get only selected measurement location, required because we need to decouple and not mix different SystemTypes in one array
            getSelectedMeasurementLocationClustered(inspectionId, activePage, search, { ...additionalProps, ProjectID: projectId, SelectedItemID: selectedMeasurementLocation.ID }, callback);
          } else {
            additionalProps = { ...additionalProps, MeasurementGroupID: selectedMeasurementGroup?.ID };
            getMeasurementLocationsClustered(inspectionId, activePage, search, { ...additionalProps, ProjectID: projectId }, callback);
          }
        } else if (view === viewOptions.location) {
          getMeasurementLocationsClustered(inspectionId, activePage, search, { ...additionalProps, ProjectID: projectId }, callback, queryItem);
        } else {
          if (visibleGroupID) {
            additionalProps = { ...additionalProps, MeasurementGroupID: visibleGroupID };
            getMeasurementGroupsClustered(inspectionId, activePage, search, { ...additionalProps, ProjectID: projectId }, callback);
          } else if (!areAllMeasurementGroupsHidden) {
            // Fetch all measurement locations
            getMeasurementGroupsClustered(inspectionId, activePage, search, { ...additionalProps, ProjectID: projectId }, callback);
          }
        }
        break;
      default:
        break;
    }
    this.setState({ prevCameraPosition: cameraPosition });
  };

  setClusteringFilters = filters => {
    const { module, searchText } = this.props;
    this.setState({ filters }, () => {
      this.getInspectionElementsClustered(null, module, searchText);
    });
  };

  render() {
    const { getInspectionElementsClustered, setClusteringFilters } = this;
    const { queryItem, module, searchText, inspectionId, viewer, elements, elementsClustered, is3DViewModeActive } = this.props;
    const selectedClusterElement = find(elementsClustered, item => item.ID === queryItem);

    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        queryItem,
        module,
        searchText,
        inspectionId,
        viewer,
        elements,
        // Show only unclustered elements in 360 view mode
        elementsClustered: is3DViewModeActive ? elementsClustered : (elementsClustered || []).filter(el => el.NumberOfPoints === 1 || !el.NumberOfPoints),
        selectedClusterElement,
        setClusteringFilters,
        getInspectionElementsClustered,
      })
    );

    return childrenWithProps;
  }
}

const mapStateToProps = state => ({
  inspectionDetails: state.inspectionReducer.inspectionDetails,
  chShow: state.inspectionReducer.chShow,
  chHierarchyID: state.inspectionReducer.chHierarchyID,
  ShowNestedComponents: state.inspectionReducer.showCHSublevels,
  levels: state.inspectionReducer.chLevels,
  chHideAll: state.inspectionReducer.chHideAll,
  hideUnassignedComponents: state.inspectionReducer.hideUnassignedComponents,
  displayUnassignedComponentsLevel: state.inspectionReducer.displayUnassignedComponentsLevel,
  is3DViewModeActive: state.inspectionReducer.is3DViewModeActive,
  user: state.userReducer,
  selectedMeasurementLocation: state.measurementLocationReducer.selectedMeasurementLocation,
  areAllMeasurementGroupsHidden: state.measurementGroupReducer.areAllMeasurementGroupsHidden,
  visibleGroupID: state.measurementGroupReducer.visibleGroupID,
  selectedMeasurementGroup: state.measurementGroupReducer.selectedMeasurementGroup,
});

const mapDispatchToProps = dispatch => ({
  getInspectionObservationsClustered: (id, activePage, search, additionalProps, callback) => dispatch(getInspectionObservationsClustered(id, activePage, search, additionalProps, callback)),
  getInspectionMeasurementsClustered: (id, activePage, search, additionalProps, callback) => dispatch(getInspectionMeasurementsClustered(id, activePage, search, additionalProps, callback)),
  getInspectionComponentsClustered: (id, activePage, search, additionalProps, callback, isComponentHierarchy, isUnassignedLevel) =>
    dispatch(getInspectionComponentsClustered(id, activePage, search, additionalProps, callback, isComponentHierarchy, isUnassignedLevel)),
  getInspectionNDTMeasurementsClustered: (id, activePage, search, additionalProps, callback) => dispatch(getInspectionNDTMeasurementsClustered(id, activePage, search, additionalProps, callback)),
  getInspectionAreasClustered: (id, activePage, search, additionalProps, callback) => dispatch(getInspectionAreasClustered(id, activePage, search, additionalProps, callback)),
  getInspectionExplosiveZonesClustered: (id, activePage, search, additionalProps, callback) => dispatch(getInspectionExplosiveZonesClustered(id, activePage, search, additionalProps, callback)),
  getInspectionNotificationsClustered: (id, activePage, search, additionalProps, callback) => dispatch(getInspectionNotificationsClustered(id, activePage, search, additionalProps, callback)),
  getMeasurementLocationsClustered: (id, activePage, search, additionalProps, callback, measurementLocationID) =>
    dispatch(getMeasurementLocationsClustered(id, activePage, search, additionalProps, callback, measurementLocationID)),
  getSelectedMeasurementLocationClustered: (id, activePage, search, additionalProps, callback, measurementLocationID) =>
    dispatch(getSelectedMeasurementLocationClustered(id, activePage, search, additionalProps, callback, measurementLocationID)),
  getMeasurementPointsClustered: (id, activePage, search, additionalProps, callback) => dispatch(getMeasurementPointsClustered(id, activePage, search, additionalProps, callback)),
  getMeasurementGroupsClustered: (id, activePage, search, additionalProps, callback) => dispatch(getMeasurementGroupsClustered(id, activePage, search, additionalProps, callback)),
});

BaseModule.defaultProps = {
  queryItem: 0,
  searchText: '',
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BaseModule));
