import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { change } from 'redux-form';

import Helpers from '../../../../common/helpers';
import PropTypes from 'prop-types';
import NDTPointForm from './ndt-point-form';
import UploadModal from '../right-toolbar/upload-modal';
import ExternalUploadModal from '../right-toolbar/external-upload-modal';

import { addMeasurementPoint, deleteMeasurementPoint, updateMeasurementPoint } from '../../actions/ndt-actions';
import { supportedMimeTypes } from '../../constants/upload-constants';
import { measurementConstants, measurementPointConstants } from '../../constants/ndt-constants';
import { preventedFieldsPriorComponentIsCreated } from '../../constants/component-constants';
import { handleNDTMeasurementPointModal } from '../../actions/action-creators';
import { getNDTMeasurementPointFilesUploaded } from '../../../upload/actions/external-upload-actions';
import { deleteMeasurementPointFile } from '../../actions/ndt-actions';
import { updateNDTMeasurementPointProperty, addNDTMeasurementPointProperty, getNDTMeasurementPointPropertyNames, deleteNDTMeasurementPointProperty } from '../../actions/inspection-actions';

import '../../styles/ndt-point-details.scss';

class NDTPointDetails extends Component {
  getUploadedFiles = () => {
    const { selectedMeasurementPoint, getMeasurementPointFilesUploaded } = this.props;
    const measureId = get(selectedMeasurementPoint, measurementPointConstants.fields.id),
      isTemp = get(selectedMeasurementPoint, 'isTemp');
    if (measureId) getMeasurementPointFilesUploaded(measureId, isTemp);
  };

  openUploadModal = (onLocalUpload, uploadGroup) => {
    const { openExternalUploadModal } = this;
    const { selectedMeasurementPoint, handleModalData, modalData } = this.props;
    handleModalData({
      ...modalData,
      isOpen: true,
      CustomContent: () => (
        <UploadModal
          closeAction={this.closeUploadModal}
          onDrop={files => onLocalUpload(files, selectedMeasurementPoint)}
          onExternalUpload={() => openExternalUploadModal(uploadGroup)}
          supportedMimeTypes={supportedMimeTypes}
          className="project-dropzone"
          defect={selectedMeasurementPoint}
        />
      ),
      type: 'none',
      confirmAction: this.closeUploadModal,
      closeAction: this.closeUploadModal,
    });
  };

  openExternalUploadModal = uploadGroup => {
    const { selectedMeasurementPoint, handleModalData, modalData } = this.props;
    handleModalData({
      ...modalData,
      isOpen: true,
      CustomContent: () => <ExternalUploadModal closeAction={this.closeUploadModal} defect={selectedMeasurementPoint} uploadGroup={uploadGroup} />,
      type: 'none',
      closeAction: this.closeUploadModal,
    });
  };

  closeUploadModal = () => {
    const { modalData, handleModalData } = this.props;
    this.getUploadedFiles();
    handleModalData({
      ...modalData,
      isOpen: false,
    });
  };

  openFile = file => {
    window.open(file.URL, '_blank');
  };

  submitForm = values => {
    const { updateMeasurementPoint, addMeasurementPoint, inspectionId, measurementPoints, selectedMeasurement, chartFilters } = this.props;
    const newMeasurementPoint = {
      ...values,
      [measurementPointConstants.fields.measuredValue]: Helpers.parseDecimal(values[measurementPointConstants.fields.measuredValue]),
      [measurementPointConstants.fields.measuredDate]: values[measurementPointConstants.fields.measuredDate]
        ? Helpers.getUnixDate(new Date(values[measurementPointConstants.fields.measuredDate]).getTime())
        : null,
    };

    if (newMeasurementPoint.isTemp) {
      addMeasurementPoint(
        inspectionId,
        selectedMeasurement[measurementConstants.fields.id],
        measurementPoints,
        newMeasurementPoint,
        selectedMeasurement[measurementConstants.fields.unit],
        chartFilters
      );
    } else {
      updateMeasurementPoint(
        inspectionId,
        selectedMeasurement[measurementConstants.fields.id],
        { ...newMeasurementPoint, MeasureID: newMeasurementPoint[measurementPointConstants.fields.id] },
        measurementPoints,
        selectedMeasurement[measurementConstants.fields.unit],
        chartFilters
      );
    }
  };

  handleInputDisabled = type => {
    const { handleModalData, modalData } = this.props;
    let content = '';

    switch (type) {
      case preventedFieldsPriorComponentIsCreated.upload:
        content = 'CREATE_MEASUREMENT_POINT.FILL_OTHER_FIELDS';
        break;
      case preventedFieldsPriorComponentIsCreated.customFields:
        content = 'CREATE_MEASUREMENT_POINT.FILL_OTHER_FIELDS_CUSTOM_PROP';
        break;
      default:
        content = 'CREATE_MEASUREMENT_POINT.FILL_OTHER_FIELDS_CUSTOM_PROP';
        break;
    }

    const { t } = this.context;
    handleModalData({
      ...modalData,
      isOpen: true,
      content: t(content),
      type: 'ok',
      closeAction: this.closeUploadModal,
      confirmAction: this.closeUploadModal,
    });
  };

  openDeleteMeasurementModal = () => {
    const { t } = this.context;
    const { selectedMeasurementPoint, toggleDeleteModal, deleteMeasurementPoint, selectedMeasurement, chartFilters, inspectionId, measurementPoints } = this.props;
    if (isEmpty(selectedMeasurementPoint)) return;
    if (selectedMeasurementPoint.isTemp) {
      deleteMeasurementPoint(
        { MeasureID: selectedMeasurementPoint[measurementPointConstants.fields.id], InspectionID: inspectionId, ...selectedMeasurementPoint },
        measurementPoints,
        selectedMeasurement[measurementConstants.fields.id],
        selectedMeasurement[measurementConstants.fields.unit],
        chartFilters
      );
      return;
    }
    const modalData = {
      isOpen: true,
      content: t('NDT_MEASUREMENT_DELETE.DESC', {
        measurementName: `${selectedMeasurementPoint[measurementPointConstants.fields.measuredValue]} (${selectedMeasurement[measurementConstants.fields.unit]})`,
      }),
      type: 'yes-no',
      confirmAction: () =>
        deleteMeasurementPoint(
          { MeasureID: selectedMeasurementPoint[measurementPointConstants.fields.id], InspectionID: inspectionId, ...selectedMeasurementPoint },
          measurementPoints,
          selectedMeasurement[measurementConstants.fields.id],
          selectedMeasurement[measurementConstants.fields.unit],
          chartFilters
        ),
      closeAction: this.closeDeleteMeasurementModal,
      customClassName: 'modal-medium',
    };
    toggleDeleteModal(modalData);
  };

  closeDeleteMeasurementModal = () => {
    const { toggleDeleteModal } = this.props;
    const modalData = {
      isOpen: false,
    };
    toggleDeleteModal(modalData);
  };

  openDeleteFileModal = (fileID, pointId) => {
    const { t } = this.context;
    const { toggleDeleteModal, deleteFile } = this.props;

    const modalData = {
      isOpen: true,
      content: t('NDT_MEASUREMENT_DELETE_FILE.DESC'),
      type: 'yes-no',
      confirmAction: () => {
        deleteFile(fileID, pointId);
        this.closeDeleteMeasurementModal();
      },
      closeAction: this.closeDeleteMeasurementModal,
      customClassName: 'modal-medium',
    };
    toggleDeleteModal(modalData);
  };

  render() {
    const { openUploadModal, submitForm, openFile, handleInputDisabled, openDeleteFileModal, openDeleteMeasurementModal } = this;
    const {
      selectedMeasurementPoint,
      measurementPoints,
      selectedMeasurement,
      uploadProgress,
      measurementPointFiles,
      initialValues,
      changeField,
      //propetries
      properties,
      updateNDTMeasurementPointProperty,
      addNDTMeasurementPointProperty,
      getNDTMeasurementPointPropertyNames,
      deleteNDTMeasurementPointProperty,
    } = this.props;

    if (isEmpty(selectedMeasurement)) {
      return null;
    }

    return (
      <div className="ndt-point-details">
        <NDTPointForm
          onSubmit={submitForm}
          selectedMeasurementPoint={selectedMeasurementPoint}
          selectedMeasurement={selectedMeasurement}
          measurementPoints={measurementPoints}
          openUploadModal={openUploadModal}
          handleInputDisabled={handleInputDisabled}
          openFile={openFile}
          uploadProgressStyle={uploadProgress}
          openDeleteMeasurementModal={openDeleteMeasurementModal}
          measurementPointFiles={measurementPointFiles}
          initialValues={initialValues}
          changeField={changeField}
          deleteFile={openDeleteFileModal}
          getNDTMeasurementPointPropertyNames={getNDTMeasurementPointPropertyNames}
          properties={properties}
          updateNDTMeasurementPointProperty={updateNDTMeasurementPointProperty}
          addNDTMeasurementPointProperty={addNDTMeasurementPointProperty}
          deleteNDTMeasurementPointProperty={deleteNDTMeasurementPointProperty}
        />
      </div>
    );
  }
}
NDTPointDetails.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const { [measurementPointConstants.fields.measuredDate]: selectedPointMeasuredDate, ...restProps } = state.ndtReducer.selectedMeasurementPoint || {};

  return {
    measurementPoints: state.ndtReducer.measurementPoints,
    selectedMeasurementPoint: state.ndtReducer.selectedMeasurementPoint,
    uploadInProgress: state.uploadReducer.uploadInProgress,
    uploadProgress: state.themeReducer.uploadProgress,
    modalData: state.ndtReducer.measurementPointModalData,
    chartFilters: state.ndtReducer.ndtChartFilters,
    properties: state.ndtReducer.measurementPointProperties,
    measurementPointFiles: state.uploadReducer.ndtMeasurementPointFiles,
    initialValues: {
      ...restProps,
      [measurementPointConstants.fields.measuredDate]: selectedPointMeasuredDate ? new Date(selectedPointMeasuredDate * 1000) : null,
    },
  };
};

const mapDispatchToProps = dispatch => ({
  updateMeasurementPoint: (inspectionID, measurementId, newData, currentMeasurements, unit, chartFilters) =>
    dispatch(updateMeasurementPoint(inspectionID, measurementId, newData, currentMeasurements, unit, chartFilters)),
  addMeasurementPoint: (inspectionID, measurementId, currentMeasurements, newData, unit, chartFilters) =>
    dispatch(addMeasurementPoint(inspectionID, measurementId, currentMeasurements, newData, unit, chartFilters)),
  deleteMeasurementPoint: (data, currentMeasurements, MeasurementID, unit, chartFilters) => dispatch(deleteMeasurementPoint(data, currentMeasurements, MeasurementID, unit, chartFilters)),
  handleModalData: data => dispatch(handleNDTMeasurementPointModal(data)),
  getMeasurementPointFilesUploaded: (measurementId, isTemp) => dispatch(getNDTMeasurementPointFilesUploaded(measurementId, isTemp)),
  toggleDeleteModal: data => dispatch(handleNDTMeasurementPointModal(data)),
  deleteFile: (fileID, pointId) => dispatch(deleteMeasurementPointFile(fileID, pointId)),
  changeField: (formName, fieldName, value) => dispatch(change(formName, fieldName, value)),

  //properties
  updateNDTMeasurementPointProperty: property => dispatch(updateNDTMeasurementPointProperty(property)),
  addNDTMeasurementPointProperty: data => dispatch(addNDTMeasurementPointProperty(data)),
  getNDTMeasurementPointPropertyNames: (value, callback) => dispatch(getNDTMeasurementPointPropertyNames(value, callback)),
  deleteNDTMeasurementPointProperty: (data, callback) => dispatch(deleteNDTMeasurementPointProperty(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NDTPointDetails);
