import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ReactResizeDetector from 'react-resize-detector';
import SimpleLoader from '../../global-loader/components/simple-loader';

import { SketchField, Tools } from '@tarik.djurdjevic/react-sketch';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import Helpers from '../../helpers';
import DrawingHelpers from '../../drawing-helpers';
import { defaultStroke } from '../../../app/inspections/constants/default-drawing';
import { imageLoadMaxRetryCount } from '../constants/constants';

import '../styles/image-with-drawing.scss';
import { isEmpty } from 'lodash';

class ImageWithDrawing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageWidth: null,
      imageHeight: null,
      imageLoaded: false,
      imageId: `res-image-${Helpers.uuid4()}`,
      imageLoading: false,
      retryCount: 1,
    };
  }

  handleImageLoaded = e => {
    const { imageLoaded } = this.state;
    const { drawings } = this.props;

    if (!e.currentTarget || imageLoaded) return;
    const { width, height } = e.currentTarget;

    this.setState({ imageLoaded: true, retryCount: 1 });

    // Check if drawings is a string and parse it, otherwise use it as is
    let parsedDrawings = [];
    if (drawings && !isEmpty(drawings)) {
      if (typeof drawings === 'string') {
        try {
          parsedDrawings = JSON.parse(drawings);
        } catch (e) {
          console.error('Failed to parse drawings:', e);
          parsedDrawings = [];
        }
      } else {
        parsedDrawings = drawings;
      }
    }
    this.setState({
      drawings: DrawingHelpers.calculateDefectImageDrawingsOffset(parsedDrawings, width, height),
      imageWidth: width,
      imageHeight: height,
    });
  };

  onResize = (imageWidth, imageHeight) => {
    this.setState({ imageWidth: parseInt(imageWidth), imageHeight: parseInt(imageHeight) });
  };

  handleImageError = e => {
    const { imageId, retryCount } = this.state;

    this.setState({ retryCount: retryCount + 1 });

    if (retryCount < imageLoadMaxRetryCount) {
      setTimeout(() => {
        const image = document.getElementById(imageId);
        if (image) {
          image.src = image.src + ' ';
        }
      }, 1000 * retryCount);
    }
  };

  render() {
    const { t } = this.context;
    const { imageLoaded, imageWidth, imageHeight, imageId, drawings, retryCount } = this.state;
    const { selectedTool, item, isFullScreen, imageType, className, imgClass, lineColor, renderImage, disableZoomAndMove, onClick, sketchProps, id, customCallback, ...restProps } = this.props;

    const imgProps = {
      src: item.src && item.src.getImageSize(imageType, item),
      onLoad: e => {
        this.handleImageLoaded(e, item);
      },
      onError: this.handleImageError,
      alt: item.name,
      className: `auto-resize ${imageLoaded ? '' : 'image-in-proccess'}`,
      id: imageId,
      crossorigin: 'anonymous',
    };

    const { ref, ...sketchPropsFiltered } = sketchProps;
    const sketchFieldProps = {
      className: 'canvas-drawer',
      ref: c => {
        if (!this._sketch && ref) {
          this._sketch = c;
          ref(c);
        }
      },
      height: imageHeight || '100%',
      width: imageWidth || '100%',
      tool: selectedTool,
      value: drawings || null,
      lineColor: lineColor,
      fillColor: 'transparent',
      lineWidth: 3,
      forceValue: true,
      onMouseDown: evt => {
        if (onClick) onClick(evt, this._sketch);
      },
      ...sketchPropsFiltered,
    };

    return (
      <div className="image-with-drawing">
        <TransformWrapper
          disabled={!imageLoaded || disableZoomAndMove}
          panning={{
            disabled: selectedTool !== Tools.DefaultTool,
            lockAxisX: false,
            lockAxisY: false,
            velocityDisabled: true,
          }}
          pinch={{ disabled: true }}
          doubleClick={{ disabled: false }}
        >
          {({ zoomIn, zoomOut, resetTransform, setDefaultState, positionX, positionY, scale, previousScale, ...rest }) => (
            <div className={`image-gallery-image ${className || ''}`} {...restProps}>
              <TransformComponent>
                <div className="image-gallery-image__container">
                  {/* eslint-disable jsx-a11y/alt-text */}
                  {retryCount < imageLoadMaxRetryCount && (
                    <ReactResizeDetector handleWidth handleHeight skipOnMount onResize={this.onResize} refreshMode={'debounce'} refreshRate={200} querySelector={`#${imageId}`}>
                      {renderImage && typeof renderImage === 'function' ? renderImage(imgProps) : <img {...imgProps} />}
                    </ReactResizeDetector>
                  )}
                  {imageLoaded && <SketchField {...sketchFieldProps} />}

                  {!imageLoaded && retryCount < imageLoadMaxRetryCount && <SimpleLoader isLoading={true} className="loader" />}
                  {!imageLoaded && retryCount === imageLoadMaxRetryCount && <p className="error-message">{t('IMAGE_WITH_DRAWINGS.IMAGE_LOAD_ERROR')}</p>}
                </div>
              </TransformComponent>
            </div>
          )}
        </TransformWrapper>
      </div>
    );
  }
}

ImageWithDrawing.defaultProps = {
  isFullScreen: false,
  selectedTool: Tools.DefaultTool,
  disableZoomAndMove: false,
  lineColor: defaultStroke,
  sketchProps: {},
  customCallback: () => null,
};

ImageWithDrawing.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ImageWithDrawing;
