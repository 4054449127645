export default {
  FETCH_KEYBOXES: '/project/keybox/list',
  CREATE_KEYBOX: '/project/keybox/create',
  GET_KEYBOX_DETAILS: '/project/keybox/details',
  DELETE_KEYBOX: '/project/keybox/delete',
  EDIT_KEYBOX_DETAILS: '/project/keybox/update',
  DELETE_KEY: '/project/keybox/key/delete',
  ADD_KEY: '/project/keybox/key/add',
  ISSUE_KEY: '/project/keybox/key/issue',
  RETURN_KEY: '/project/keybox/key/return',
  UPDATE_KEY_NAME: '/project/keybox/key/update',
  FETCH_CONTRACTORS: '/project/keybox/contractor/list',
};
