import { questionTypes } from '../../../../../common/question-components/constants/question-constants';
import { changeStatusQuestionField, questionFields } from '../../../components/right-toolbar/isolation-certificate-details/constants/change-status-constants';

export const validate = (values, props) => {
  const errors = {};
  const questions = props?.questions;

  if (questions && questions.length) {
    for (let i = 0; i < questions.length; i++) {
      if ([questionTypes.TEXT.valueId].indexOf(questions[i][questionFields.type]) > -1) {
        const textQuestionValue = values[`${changeStatusQuestionField}-${questions[i][questionFields.id]}`];
        if (textQuestionValue && textQuestionValue.length > questionTypes.TEXT.maxChars) {
          errors[`${changeStatusQuestionField}-${questions[i][questionFields.id]}`] = 'ISOLATION_CERTIFICATE.TEXTAREA_MAX_LENGTH_QUESTION_VALIDATE';
        }
      }

      if (questions[i][questionFields.isMandatory]) {
        if (
          questions[i][questionFields.type] === questionTypes.CHECKBOX.valueId &&
          !values[`${changeStatusQuestionField}-${questions[i][questionFields.id]}-yes`] &&
          !values[`${changeStatusQuestionField}-${questions[i][questionFields.id]}-no`]
        ) {
          errors[`${changeStatusQuestionField}-${questions[i][questionFields.id]}-yes`] = 'ISOLATION_CERTIFICATE_STATUS.QUESTION_REQUIRED';
        } else if (questions[i][questionFields.type] === questionTypes.MULTI_ANSWER.valueId) {
          let valid = false;
          const options = Object.assign([], questions[i][questionFields.questionOptions]);

          for (let j = 0; j < options.length; j++) {
            if (values[`${changeStatusQuestionField}-${questions[i][questionFields.id]}-option-${options[j][questionFields.id]}`]) {
              valid = true;
              break;
            }
          }

          if (!valid) {
            errors[`${changeStatusQuestionField}-${questions[i][questionFields.id]}-option-${options[0][questionFields.id]}`] = 'ISOLATION_CERTIFICATE_STATUS.QUESTION_REQUIRED';
          }
        } else if (!values[`${changeStatusQuestionField}-${questions[i][questionFields.id]}`]) {
          errors[`${changeStatusQuestionField}-${questions[i][questionFields.id]}`] = 'ISOLATION_CERTIFICATE_STATUS.QUESTION_REQUIRED';
        }
      }
    }
  }

  return errors;
};
