import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { values, debounce, get, isArray } from 'lodash';

import TableDropdown from './table-dropdown';
import DisplayColor from '../../../../../common/display-color/components/display-color';
import DisplayStatus from '../../../../../common/display-status/components/display-status';
import LoadMore from '../../../../../common/load-more/components/load-more';
import Modal from '../../../../../common/modal/components/modal';
import SearchInput from '../../../../../common/input/components/search-input';
import TableComponent from '../../../../../common/table/components/table-component';
import RenderIf from '../../../../../common/render-if/components/render-if';
import Helpers from '../../../../../common/helpers';
import { tableConfig, statuses, filterProps, defaultFilters, fields, confirmationModalContent, confirmationModalTypes, steps, sortDirection } from '../constants/constants';
import { getPermitTemplates, permitTemplateChangeStatus, copyPermitTemplate, deletePermitTemplate } from '../../../actions/permit-actions';
import { openPermitTemplateForm } from '../../../helpers/templates-helpers';
import { routes } from '../../../../../common/routes-constants';

import '../styles/permit-template-list.scss';

class PermitTemplateList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      templates: [],
      filters: defaultFilters,
      modalData: {
        isOpen: false,
      },
      actionInProgress: false,
      isLoading: false,
    };

    this.searchChangeDebounced = debounce(this.handleSearch, 500);
  }

  componentDidMount = () => {
    const { location } = this.props;
    const { query } = location;
    const projectId = get(query, 'project_id');

    if (!projectId) {
      Helpers.goTo(routes.protectedRoutes.project.fullPath);
    }

    this.fetchPermitTemplates(defaultFilters);
  };

  onRowClick = () => {
    console.log('row clicked');
  };

  handleInputChange = e => {
    this.searchChangeDebounced(e.target.value);
  };

  handleSearch = SearchText => {
    const { filters } = this.state;
    const newParams = {
      ...filters,
      [filterProps.lastSeen]: defaultFilters[filterProps.lastSeen],
      SearchText,
    };

    this.fetchPermitTemplates(newParams);
  };

  fetchPermitTemplates = (params, loadMore) => {
    const { templates } = this.state;
    const { location, getPermitTemplates } = this.props;
    const { query } = location;
    const projectId = get(query, 'project_id');
    params = {
      ...params,
      ProjectId: parseInt(projectId, 10),
    };

    const onSuccessFetch = newState => {
      this.setState(newState);

      if (loadMore && newState.templates) {
        Helpers.scrollIntoView('permit-template-list__table-container', `row-${newState.templates - 1}`);
      }
    };

    getPermitTemplates(params, templates, onSuccessFetch, loadMore);
  };

  handleSuccessConfirmModalAction = (actionInProgress, isSuccess, templates, isDelete) => {
    const newState = { actionInProgress };

    if (isSuccess) {
      if (templates && isArray(templates)) {
        newState.templates = templates;
      }

      if (isDelete) {
        const { filters } = this.state;
        newState.filters = {
          ...filters,
          [filterProps.lastSeen]: filters[filterProps.lastSeen] - 1,
          [filterProps.totalItems]: filters[filterProps.totalItems] - 1,
        };
      }
    }

    this.setState(newState);
    this.toggleConfirmationModal(false);
  };

  handleConfirmModalAction = (type, data, isChangeToLiveStatus) => {
    const { location } = this.props;
    const { templates } = this.state;

    switch (type) {
      case confirmationModalTypes.duplicate:
        // if the template is in status "LIVE", prevent the action
        if (data[fields.status] === statuses.live.value) {
          break;
        }

        const { copyPermitTemplate } = this.props;
        copyPermitTemplate(data[fields.id], templateId => openPermitTemplateForm('edit', steps.firstStep, templateId, location?.search));
        break;
      case confirmationModalTypes.delete:
        const { deletePermitTemplate } = this.props;
        deletePermitTemplate(data, Object.assign([], templates), this.handleSuccessConfirmModalAction);
        break;
      case confirmationModalTypes.changeStatus:
        const { permitTemplateChangeStatus } = this.props;
        permitTemplateChangeStatus(data, Object.assign([], templates), this.handleSuccessConfirmModalAction, isChangeToLiveStatus);
        break;
      default:
        break;
    }
  };

  toggleConfirmationModal = (isOpen, type, data, translationData, isChangeToLiveStatus) => {
    const { t } = this.context;
    const modalData = isOpen
      ? {
          isOpen,
          content: t(confirmationModalContent[type]?.content, translationData),
          type: 'yes-no',
          confirmAction: () => this.handleConfirmModalAction(type, data, isChangeToLiveStatus),
          closeAction: () => this.toggleConfirmationModal(false),
        }
      : { isOpen: false };

    this.setState({ modalData });
  };

  onColumnSort = SortByColumn => {
    const { filters } = this.state;
    const newParams = {
      ...filters,
      [filterProps.sortByColumn]: SortByColumn,
      [filterProps.sortDirection]: filters[filterProps.sortDirection] === sortDirection.asc ? sortDirection.desc : sortDirection.asc,
      [filterProps.lastSeen]: 0,
    };

    this.fetchPermitTemplates(newParams);
  };

  loadMore = () => {
    const { filters } = this.state;
    const newParams = { ...filters };

    this.fetchPermitTemplates(newParams, true);
  };

  render() {
    const { t } = this.context;
    const { templates, filters, actionInProgress, modalData, isLoading } = this.state;
    const { location } = this.props;
    const { search } = location;

    return (
      <div className="permit-template-list">
        <div className="permit-template-list__search-input-wrapper">
          <SearchInput onChange={this.handleInputChange} placeholder={t('PROJECT.SEARCH.PLACEHOLDER')} stripped />
        </div>
        <RenderIf if={(!templates || !templates.length) && !isLoading}>
          <div className="empty-state">
            <p className="f-primary">{t('PERMIT_TEMPLATES.EMPTY_STATE')}</p>
          </div>
        </RenderIf>
        <RenderIf if={!isLoading && templates && templates.length > 0}>
          <div className="permit-template-list__table-container">
            <TableComponent
              tableConfig={tableConfig}
              data={templates || []}
              tableCustomClass="permit-template-list__table-container__table"
              onRowClick={this.onRowClick}
              onColumnSort={this.onColumnSort}
              sortingObj={filters}
              translationModule={t}
              formatCell={(value, type, index, item) => {
                if (type === fields.status) {
                  return <DisplayStatus statuses={values(statuses)} status={value} />;
                } else if (type === fields.color) {
                  return <DisplayColor color={value} />;
                } else if (type === fields.actionMenu) {
                  return <TableDropdown item={item} queryParams={search} toggleConfirmationModal={this.toggleConfirmationModal} />;
                } else if (type === fields.description) {
                  return value ? <p className="f-primary shortened">{value}</p> : null;
                } else {
                  return Helpers.formatCell(value, type, index, item);
                }
              }}
              stickyHeader={true}
            />
          </div>
        </RenderIf>
        <div className="permit-template-list__load-more-wrapper">
          <LoadMore
            disabled={!filters[filterProps.hasNext] || isLoading}
            loaded={templates.length}
            total={filters[filterProps.totalItems]}
            totalPosition="center"
            label="COMPONENT_HISTORY.LOAD_MORE"
            showButton
            showTotalUp
            buttonVariant="success-outline"
            resultsText="PERMIT_TEMPLATES_LIST.SHOWING_TOTAL_ITEMS"
            onClick={this.loadMore}
          />
        </div>
        <Modal {...modalData} modalDisabled={actionInProgress} confirmButtonDisabled={actionInProgress} />
      </div>
    );
  }
}

PermitTemplateList.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  getPermitTemplates: (filters, templates, callback, loadMore) => dispatch(getPermitTemplates(filters, templates, callback, loadMore)),
  permitTemplateChangeStatus: (data, templates, callback, isChangeToLiveStatus) => dispatch(permitTemplateChangeStatus(data, templates, callback, isChangeToLiveStatus)),
  copyPermitTemplate: (templateId, callback) => dispatch(copyPermitTemplate(templateId, callback)),
  deletePermitTemplate: (data, templates, callback) => dispatch(deletePermitTemplate(data, templates, callback)),
});

export default withRouter(connect(null, mapDispatchToProps)(PermitTemplateList));
