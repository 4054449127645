import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { find, values, map, isEmpty } from 'lodash';

import Helpers from '../../../../common/helpers';
import SimpleLoader from '../../../../common/global-loader/components/simple-loader';

import { predictiveResults as constants } from './constants/predictive-settings';
import { alarmLevelThemeColors } from '../../constants/ndt-constants';

class PredictiveResults extends Component {
  constructor(props) {
    super(props);
    const colors = values(alarmLevelThemeColors);
    this.state = {
      statusButtons: map(colors, item => {
        return {
          ...item,
          color: props.severityColors[item.key],
        };
      }),
    };
  }

  getColor = status => {
    const { statusButtons } = this.state;
    return (find(statusButtons, i => i.status === status) || {}).color || statusButtons[0].color;
  };

  renderAlarms = () => {
    const { t } = this.context;
    const { results, loadingResults } = this.props;

    if (loadingResults) {
      return <SimpleLoader isLoading={true} width={80} height={80} className="results-loader" />;
    }
    if (isEmpty(results)) {
      return (
        <div className="empty-state">
          <p className="f-primary">{t('NDT_PREDICTIVE_SETTINGS.PREDICTIVE_RESULTS.EMPTY')}</p>
        </div>
      );
    }
    return results.map((alarm, index) => (
      <div key={index} className="alarm-status">
        <div className="status-icon" style={{ backgroundColor: this.getColor(alarm[constants.fields.alarmColor]) }} />
        <p className="input f-primary">{t(`NDT_ALARMS_MODAL.FORM_LABELS_VALUE_${alarm[constants.fields.alarmType]}`, { unit: '' })}</p>
        <div className="predicted-date">
          <label>
            {alarm[constants.fields.date] === 0 ? (
              '---'
            ) : (
              <>
                {Helpers.getDateFromUnix(alarm[constants.fields.date], 'LL')} ( <span className="days">{Helpers.getDaysDifference(alarm[constants.fields.date])}d</span> )
              </>
            )}
          </label>
        </div>
      </div>
    ));
  };

  render() {
    const { t } = this.context;

    return (
      <div className="predictive-settings-results horizontal-padding">
        <div className="results-wrapper">
          <h6 className="f-primary">{t('NDT_PREDICTIVE_SETTINGS.PREDICTIVE_RESULTS')}</h6>
          <div className="alarms">{this.renderAlarms()}</div>
        </div>
      </div>
    );
  }
}
PredictiveResults.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default PredictiveResults;
