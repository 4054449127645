export default {
  LOAD_PROJECTS_SUCCESS: 'loadProjectsSuccess',
  SELECT_PROJECT: 'selectProject',
  TOGGLE_PROJECT_INSPECTIONS: 'toggleProjectInspections',
  SET_PROJECT_EDIT: 'setProjectEdit',
  HANDLE_ARCHIVE_PROJECT_MODAL: 'handleArchiveProjectModal',
  HANDLE_DELETE_PROJECT_MODAL: 'handleDeleteProjectModal',
  SET_PROJECT_DETAILS: 'setProjectDetails',
  RESET_PROJECT_DETAILS: 'resetProjectDetails',
  SET_PROJECT_DMS_CATEGORIES: 'setProjectDMSCategories',
  SET_IS_LOADING_PROJECT_DETAILS: 'setIsLoadingProjectDetails',
};
