import historyItemColorDisplay from '../../../common/history-item/components/history-item-color-display';
import HistoryItemDefectStatus from '../../../common/history-item/components/history-item-defect-status-display';
import inspectionWorkflowConstants from '../../inspection-workflow/constants/inspection-workflow-constants';
import { defectType } from './defect-constants';
import { measurementDropdownActions } from './measurement-constants';

export const formConstants = {
  fields: {
    id: 'ID',
    name: 'Name',
    color: 'Colour',
    transparency: 'Transparency',
    zoneAreas: 'ZoneAreas',
    status: 'Status',
    createdByUserID: 'CreatedBy',
  },
};

export const zoneAreaConstants = {
  fields: {
    id: 'ID',
    name: 'ZoneName',
    color: 'Colour',
    camPosition: 'CameraPosition.coordinates',
    points: 'Geometry.coordinates',
    modelType: 'ModelType',
    modelDetails: 'ModelDetails',
  },
};
export const tableConfig = [
  {
    title: 'ZONES.ID',
    key: formConstants.fields.id,
    enableSort: true,
  },
  {
    title: 'ZONES.NAME',
    key: formConstants.fields.name,
    enableSort: true,
    type: formConstants.fields.name,
    className: 'inline-left full-width',
    class: 'full-width',
  },
  {
    key: formConstants.fields.status,
    type: formConstants.fields.status,
    className: 'inline-right',
  },
];

export const filterFields = {
  searchText: 'SearchText',
  limit: 'PerPage',
  lastSeen: 'LastSeen',
  sortByColumn: 'SortByColumn',
  sortDirection: 'SortDirection',
  createdByFilter: 'CreatedByFilter',
  statusFilter: 'StatusFilter',
  typeFilter: 'TypeFilter',
  sourceFilter: 'SourceFilter',
  selectedNotificationIds: 'SelectedNotificationIDs',
  hasNext: 'HasNext',
  totalItems: 'TotalNumber',
  severityFilter: 'SeverityFilter',
  dateFrom: 'DateFrom',
  dateTo: 'DateTo',
};

export const sortingDirection = {
  asc: 'ASC',
  desc: 'DESC',
};

export const defaultPagingObj = {
  [filterFields.lastSeen]: 0,
  [filterFields.limit]: 50,
  [filterFields.totalItems]: 0,
  [filterFields.hasNext]: false,
};
export const defaultSortingFilter = {
  [filterFields.sortByColumn]: formConstants.fields.id,
  [filterFields.sortDirection]: sortingDirection.asc,
};

export const modelDetailsConstants = {
  fields: {
    width: 'width',
    height: 'height',
    depth: 'depth',
    radius: 'radius',
    radiusTop: 'radiusTop',
    radiusBottom: 'radiusBottom',
    tubeRadius: 'tubeRadius',
    rotation: 'rotation',
    scale: 'scale',
  },
};

export const shapeTypes = {
  cube: 'CUBE',
  cylinder: 'CYLINDER',
  sphere: 'SPHERE',
  ring: 'RING',
  volume: 'VOLUME',
};

export const defaultModelDetails = {
  [shapeTypes.cube]: {
    [modelDetailsConstants.fields.width]: 1,
    [modelDetailsConstants.fields.height]: 1,
    [modelDetailsConstants.fields.depth]: 1,
  },
  [shapeTypes.cylinder]: {
    [modelDetailsConstants.fields.radiusTop]: 1,
    [modelDetailsConstants.fields.radiusBottom]: 1,
    [modelDetailsConstants.fields.height]: 3,
  },
  [shapeTypes.sphere]: {
    [modelDetailsConstants.fields.radius]: 1,
  },
  [shapeTypes.ring]: {
    [modelDetailsConstants.fields.radius]: 1,
    [modelDetailsConstants.fields.tubeRadius]: 2,
  },
};

export const modelTypes = {
  _2D: '2D',
  _3D: '3D',
};

export const shapeTypeDropdownData = [
  {
    label: `SHAPE_TYPE_POINT`,
    value: defectType.point,
    modelType: modelTypes._2D,
    dropdownAction: measurementDropdownActions.point,
  },
  {
    label: `SHAPE_TYPE_LINES`,
    value: defectType.distance,
    modelType: modelTypes._2D,
    dropdownAction: measurementDropdownActions.distance,
  },
  {
    label: `SHAPE_TYPE_AREA`,
    value: defectType.area,
    modelType: modelTypes._2D,
    dropdownAction: measurementDropdownActions.area,
  },
  {
    label: `SHAPE_TYPE_CIRCLE`,
    value: defectType.circle,
    modelType: modelTypes._2D,
    dropdownAction: measurementDropdownActions.circle,
  },
  {
    label: `SHAPE_TYPE_CUBE`,
    value: shapeTypes.cube,
    modelType: modelTypes._3D,
  },
  {
    label: `SHAPE_TYPE_CYLINDER`,
    value: shapeTypes.cylinder,
    modelType: modelTypes._3D,
  },
  {
    label: `SHAPE_TYPE_SPHERE`,
    value: shapeTypes.sphere,
    modelType: modelTypes._3D,
  },
  {
    label: `SHAPE_TYPE_RING`,
    value: shapeTypes.ring,
    modelType: modelTypes._3D,
  },
];

export const DefaultExplosiveZone = {
  [formConstants.fields.name]: 'New zone',
  [formConstants.fields.color]: inspectionWorkflowConstants.severity.green.color,
  [formConstants.fields.transparency]: 1,
};

export const tabNames = {
  details: 'DETAILS',
  history: 'HISTORY',
};

export const toolbarItems = {
  [tabNames.details]: {
    name: tabNames.details,
    label: 'ZONES_FORM_TAB.DETAILS',
  },
  [tabNames.history]: {
    name: tabNames.history,
    label: 'ZONES_FORM_TAB.HISTORY',
  },
};

export const historyItems = {
  Name: {
    title: 'ZONE_HISTORY.NAME',
  },
  Colour: {
    title: 'ZONE_HISTORY.COLOUR',
    component: historyItemColorDisplay,
  },
  Transparency: {
    title: 'ZONE_HISTORY.TRANSPARENCY',
  },
  Status: {
    title: 'ZONE_HISTORY.STATUS',
    component: HistoryItemDefectStatus,
  },
  Geometry: {
    subProp: 'coordinates',
    title: 'ZONE_HISTORY.GEOMETRY',
    decimals: 2,
  },
  CameraPosition: {
    title: 'ZONE_HISTORY.CAMERA_POSITION',
    decimals: 2,
  },
  ModelDetails: {
    title: 'ZONE_HISTORY.MODEL_DETAILS',
    hideValue: true,
  },
  ZoneArea: {
    subProp: 'ZoneName',
    title: 'ZONE_HISTORY.ZONE_AREA',
  },
  ZoneName: {
    title: 'ZONE_HISTORY.ZONE_AREA_NAME',
  },
  ModelType: {
    title: 'ZONE_HISTORY.ZONE_AREA_MODEL_TYPE',
  },
};

export const zoneModificationEvents = {
  zone_created: {
    title: 'ZONE_HISTORY.CREATED_EVENT',
    event: 'zone_created',
  },
  zone_updated: {
    title: 'ZONE_HISTORY.UPDATED_EVENT',
    event: 'zone_updated',
  },
  zone_deleted: {
    title: 'ZONE_HISTORY.DELETE_EVENT',
    event: 'zone_deleted',
    titleClass: 'f-secondary-red',
  },
  'zone-area_created': {
    title: 'ZONE_HISTORY.ZONE_AREA_CREATED_EVENT',
    event: 'zone-area_created',
  },
  'zone-area_updated': {
    title: 'ZONE_HISTORY.ZONE_AREA_UPDATED_EVENT',
    event: 'zone-area_updated',
  },
  'zone-area_deleted': {
    title: 'ZONE_HISTORY.ZONE_AREA_DELETED_EVENT',
    event: 'zone-area_deleted',
    titleClass: 'f-secondary-red',
    hideValue: true,
  },
};

export const historyFilterParams = {
  lastSeen: 'LastSeen',
  perPage: 'PerPage',
  sortByColumn: 'ModifiedAt',
  sortDirection: 'SortDirection',
  totalItems: 'TotalNumber',
  hasNext: 'HasNext',
};

export const historyDefaultPagingObj = {
  [historyFilterParams.lastSeen]: 0,
  [historyFilterParams.perPage]: 20,
  [historyFilterParams.totalItems]: 0,
  [historyFilterParams.hasNext]: false,
};
