import React, { Component } from 'react';
import LineChart from './line-chart';

import PropTypes from 'prop-types';

class NDTMainChart extends Component {
  componentDidMount() {
    const { getChartData } = this.props;
    getChartData();
  }
  render() {
    const {
      chartOptions,
      chartData,
      isEmptyData,
      // renderGroupingTags,
      // ,graphType
      renderAlarmFilters,
    } = this.props;

    return (
      <div className="chart-section">
        <div className="toggle-group">
          <div className="grouping-tags">
            {/* 
            <p className="f-secondary-dark">{t(`NDT_MODAL.FILTER_GROUPING.${graphType}`)}: </p>
            {renderGroupingTags()} 
            */}
          </div>
          <div className="alarm-toggle-wrapper">{renderAlarmFilters()}</div>
        </div>
        <LineChart lineChartOptions={chartOptions} data={chartData} chartHeight="100%" isEmptyData={isEmptyData} />
      </div>
    );
  }
}
NDTMainChart.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default NDTMainChart;
