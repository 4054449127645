import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../../../../../../common/icon/components/icon';
import { fields } from '../../constants/isolation-components-constants';

const IsolationComponentsOrderList = ({ title, isIsolationOrder, components = [], handleOrderComponents, disabled }, { t }) => {
  const onSort = (currentIndex, newIndex) => {
    const currentComponent = Object.assign({}, components[currentIndex]);
    const newComponent = Object.assign({}, components[newIndex]);

    if (isIsolationOrder) {
      currentComponent[fields.isolationOrder.name] = newComponent[fields.isolationOrder.name];
    } else {
      currentComponent[fields.order.name] = newComponent[fields.order.name];
    }

    handleOrderComponents(currentComponent);
  };

  if (!components || !components.length) {
    return null;
  }

  return (
    <div className="components-list-container">
      <p className="f-primary bold">{t(title)}</p>
      <div className="components-list">
        <div className="column-item">
          <p className="f-secondary-dark list-column order-item">{t('ISOLATION_CERTIFICATE.ORDER_COLUMN')}</p>
          <p className="f-secondary-dark list-column">{t('ISOLATION_CERTIFICATE.NAME_COLUMN')}</p>
          <p className="f-secondary-dark action-column">{t('ISOLATION_CERTIFICATE.ACTION_COLUMN')}</p>
        </div>
        {components.map((component, index) => (
          <div className="column-item" key={`order-component-${component[fields.component.name]}-${isIsolationOrder ? 'isolation' : 'de-isolation'}`}>
            <p className="f-primary order-item">{`${index + 1}.`}</p>
            <p className="f-primary">{component[fields.componentName.name]}</p>
            <div className="action-column">
              <Icon name="arrow-up" className={`sort-icon ${!index || disabled ? 'sort-icon-disabled' : ''}`} onClick={() => onSort(index, index - 1)} />
              <Icon name="arrow-down" className={`sort-icon ${index >= components.length - 1 || disabled ? 'sort-icon-disabled' : ''}`} onClick={() => onSort(index, index + 1)} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

IsolationComponentsOrderList.contextTypes = {
  t: PropTypes.func.isRequired,
};

IsolationComponentsOrderList.propTypes = {
  title: PropTypes.string.isRequired,
  components: PropTypes.arrayOf(
    PropTypes.shape({
      ComponentID: PropTypes.number,
      DeisolationOrder: PropTypes.number,
      DesiredState: PropTypes.string,
      Files: PropTypes.array,
      IsolationMethod: PropTypes.string,
      IsolationOrder: PropTypes.number,
      Name: PropTypes.string,
      NormalPosition: PropTypes.string,
    })
  ),
  isIsolationOrder: PropTypes.bool,
  handleOrderComponents: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default IsolationComponentsOrderList;
