import { ReactComponent as icon360 } from '../assets/360.svg';
import { ReactComponent as icon3D } from '../assets/3D.svg';
import { ReactComponent as observationAccident } from '../assets/accident.svg';
import { ReactComponent as alarmBellIcon } from '../assets/alarm-bell-icon.svg';
import { ReactComponent as alertCircle } from '../assets/alert-circle.svg';
import { ReactComponent as alertOctagon } from '../assets/alert-octagon.svg';
import { ReactComponent as alertTriangle } from '../assets/alert-triangle.svg';
import { ReactComponent as measurementAngle } from '../assets/angle-icon.svg';
import { ReactComponent as areas } from '../assets/areas.svg';
import { ReactComponent as arrowDown } from '../assets/arrow-down.svg';
import { ReactComponent as arrowLeft } from '../assets/arrow-left.svg';
import { ReactComponent as arrowRight } from '../assets/arrow-right.svg';
import { ReactComponent as arrowUp } from '../assets/arrow-up.svg';
import { ReactComponent as arrow90 } from '../assets/arrow90.svg';
import { ReactComponent as atSign } from '../assets/at-sign.svg';
import { ReactComponent as barChart } from '../assets/bar-chart.svg';
import { ReactComponent as bell } from '../assets/bell.svg';
import { ReactComponent as box } from '../assets/box.svg';
import { ReactComponent as calendar } from '../assets/calendar.svg';
import { ReactComponent as camera } from '../assets/camera.svg';
import { ReactComponent as checkRounded } from '../assets/check-rounded.svg';
import { ReactComponent as checkToast } from '../assets/check-toast.svg';
import { ReactComponent as check } from '../assets/check.svg';
import { ReactComponent as checkmarkOutline } from '../assets/checkmark-outline.svg';
import { ReactComponent as checkmarkRounded } from '../assets/checkmark-rounded.svg';
import { ReactComponent as checkmark } from '../assets/checkmark.svg';
import { ReactComponent as chevronDown } from '../assets/chevron-down.svg';
import { ReactComponent as chevronUp } from '../assets/chevron-up.svg';
import { ReactComponent as chevron } from '../assets/chevron.svg';
import { ReactComponent as measurementCircle } from '../assets/circle-icon.svg';
import { ReactComponent as clockFilled } from '../assets/clock-filled.svg';
import { ReactComponent as clockRounded } from '../assets/clock-rounded.svg';
import { ReactComponent as clock } from '../assets/clock.svg';
import { ReactComponent as closeFilled } from '../assets/close-filled.svg';
import { ReactComponent as close } from '../assets/close.svg';
import { ReactComponent as measurementArea } from '../assets/combined-shape.svg';
import { ReactComponent as componentBox } from '../assets/component-box.svg';
import { ReactComponent as componentCylinder } from '../assets/component-cylinder.svg';
import { ReactComponent as componentHierarchy } from '../assets/component-hierarchy.svg';
import { ReactComponent as componentPoints } from '../assets/component-point.svg';
import { ReactComponent as componentSphere } from '../assets/component-sphere.svg';
import { ReactComponent as components } from '../assets/components.svg';
import { ReactComponent as observationConcern } from '../assets/concern.svg';
import { ReactComponent as componentCone } from '../assets/cone.svg';
import { ReactComponent as contractor } from '../assets/contractor.svg';
import { ReactComponent as corrosion } from '../assets/corrosion.svg';
import { ReactComponent as criticalHazard } from '../assets/critical-hazard.svg';
import { ReactComponent as crossedOutCircle } from '../assets/crossed-out-circle.svg';
import { ReactComponent as crosshair } from '../assets/crosshair.svg';
import { ReactComponent as cursor } from '../assets/cursor.svg';
import { ReactComponent as observationDamage } from '../assets/damage.svg';
import { ReactComponent as observationDefect } from '../assets/defect-link.svg';
import { ReactComponent as deleteIcon } from '../assets/delete.svg';
import { ReactComponent as dmsDocuments } from '../assets/dms-documents.svg';
import { ReactComponent as dmsFolder } from '../assets/dms-folder.svg';
import { ReactComponent as documentChecklist } from '../assets/document-checklist.svg';
import { ReactComponent as documents } from '../assets/documents.svg';
import { ReactComponent as download } from '../assets/download.svg';
import { ReactComponent as exclamationMark } from '../assets/exclamation-mark.svg';
import { ReactComponent as externalLink } from '../assets/external-link.svg';
import { ReactComponent as eye } from '../assets/eye.svg';
import { ReactComponent as feedback } from '../assets/feedback.svg';
import { ReactComponent as fileText } from '../assets/file-text.svg';
import { ReactComponent as flag } from '../assets/flag.svg';
import { ReactComponent as folder } from '../assets/folder.svg';
import { ReactComponent as greenCheck } from '../assets/green-check.svg';
import { ReactComponent as observationHazard } from '../assets/hazard.svg';
import { ReactComponent as measurementHeight } from '../assets/height-icon.svg';
import { ReactComponent as image } from '../assets/image.svg';
import { ReactComponent as inProgress } from '../assets/in-progress.svg';
import { ReactComponent as info } from '../assets/info.svg';
import { ReactComponent as key } from '../assets/key.svg';
import { ReactComponent as link } from '../assets/link.svg';
import { ReactComponent as list } from '../assets/list.svg';
import { ReactComponent as loader } from '../assets/loader.svg';
import { ReactComponent as lock } from '../assets/lock.svg';
import { ReactComponent as logout } from '../assets/log-out.svg';
import { ReactComponent as mapCrossed } from '../assets/map-crossed.svg';
import { ReactComponent as maximize } from '../assets/maximize.svg';
import { ReactComponent as measurementPoint } from '../assets/measurement-point.svg';
import { ReactComponent as measurements } from '../assets/measurements.svg';
import { ReactComponent as messageCircle } from '../assets/message-circle.svg';
import { ReactComponent as minus } from '../assets/minus.svg';
import { ReactComponent as more } from '../assets/more.svg';
import { ReactComponent as moveTo } from '../assets/move-to.svg';
import { ReactComponent as multiSelect } from '../assets/multi-select.svg';
import { ReactComponent as ndt } from '../assets/ndt.svg';
import { ReactComponent as observationNearMiss } from '../assets/near-miss.svg';
import { ReactComponent as newTab } from '../assets/new-tab.svg';
import { ReactComponent as notVisible } from '../assets/not-visible.svg';
import { ReactComponent as notifications } from '../assets/notifications.svg';
import { ReactComponent as observations } from '../assets/observations.svg';
import { ReactComponent as paperclip } from '../assets/paperclip.svg';
import { ReactComponent as measurementDistance } from '../assets/path.svg';
import { ReactComponent as pdfFile } from '../assets/pdf-file.svg';
import { ReactComponent as pen } from '../assets/pen.svg';
import { ReactComponent as pipes } from '../assets/pipes.svg';
import { ReactComponent as plusCircle } from '../assets/plus-circle.svg';
import { ReactComponent as plusRounded } from '../assets/plus-rounded.svg';
import { ReactComponent as plus } from '../assets/plus.svg';
import { ReactComponent as profile } from '../assets/profile.svg';
import { ReactComponent as projects } from '../assets/projects.svg';
import { ReactComponent as question } from '../assets/question.svg';
import { ReactComponent as refreshIcon } from '../assets/refresh-ccw.svg';
import { ReactComponent as regime } from '../assets/regime.svg';
import { ReactComponent as reissued } from '../assets/reissued.svg';
import { ReactComponent as resize } from '../assets/resize.svg';
import { ReactComponent as sadFace } from '../assets/sad-face.svg';
import { ReactComponent as sapIcon } from '../assets/sap-icon.svg';
import { ReactComponent as search } from '../assets/search.svg';
import { ReactComponent as settings } from '../assets/settings.svg';
import { ReactComponent as share } from '../assets/share.svg';
import { ReactComponent as shield } from '../assets/shield.svg';
import { ReactComponent as smartdrawing } from '../assets/smartdrawing.svg';
import { ReactComponent as target } from '../assets/target.svg';
import { ReactComponent as templates } from '../assets/templates.svg';
import { ReactComponent as threeDotEye } from '../assets/three-dot-eye.svg';
import { ReactComponent as toggler } from '../assets/toggler.svg';
import { ReactComponent as trash } from '../assets/trash.svg';
import { ReactComponent as componentTube } from '../assets/tube.svg';
import { ReactComponent as unlink } from '../assets/unlink.svg';
import { ReactComponent as upload } from '../assets/upload.svg';
import { ReactComponent as users } from '../assets/users.svg';
import { ReactComponent as viewMode } from '../assets/view-mode.svg';
import { ReactComponent as warningOutline } from '../assets/warning-outline.svg';
import { ReactComponent as warning } from '../assets/warning.svg';
import { ReactComponent as workflow } from '../assets/workflow.svg';
import { ReactComponent as workorders } from '../assets/workorders.svg';
import { ReactComponent as zones } from '../assets/zones.svg';
import { ReactComponent as zoomin } from '../assets/zoom-in.svg';
import { ReactComponent as zoomout } from '../assets/zoom-out.svg';
import avg from '../svg-components/Avg';
import max from '../svg-components/Max';
import med from '../svg-components/Med';
import min from '../svg-components/Min';

export const iconstMap = {
  projects,
  users,
  feedback,
  profile,
  logout,
  toggler,
  'arrow-right': arrowRight,
  chevron,
  newTab,
  measurements,
  components,
  observations,
  ndt,
  workorders,
  notifications,
  areas,
  zones,
  documents,
  'dms-documents': dmsDocuments,
  smartdrawing,
  workflow,
  regime,
  arrow90,
  pen,
  target,
  corrosion,
  cursor,
  zoomin,
  zoomout,
  multiSelect,
  refreshIcon,
  resize,
  'view-mode': viewMode,
  settings,
  'plus-rounded': plusRounded,
  measurementDistance,
  measurementArea,
  measurementPoint,
  measurementAngle,
  measurementHeight,
  measurementCircle,
  componentPoints,
  componentBox,
  componentSphere,
  componentCone,
  componentCylinder,
  componentTube,
  observationHazard,
  observationConcern,
  observationDamage,
  observationDefect,
  observationAccident,
  observationNearMiss,
  sapIcon,
  'exclamation-mark': exclamationMark,
  checkmark: checkmark,
  warning,
  eye,
  info,
  delete: deleteIcon,
  'file-text': fileText,
  pipes,
  calendar,
  'chevron-up': chevronUp,
  'chevron-down': chevronDown,
  contractor,
  templates,
  clock,
  lock,
  'arrow-up': arrowUp,
  'arrow-down': arrowDown,
  close,
  list,
  key,
  paperclip,
  trash,
  shield,
  'document-checklist': documentChecklist,
  'alert-octagon': alertOctagon,
  'alert-triangle': alertTriangle,
  'check-rounded': checkRounded,
  'clock-rounded': clockRounded,
  flag,
  box,
  'checkmark-rounded': checkmarkRounded,
  search,
  share,
  maximize,
  download,
  reissued,
  'in-progress': inProgress,
  'plus-circle': plusCircle,
  camera,
  'pdf-file': pdfFile,
  'clock-filled': clockFilled,
  'close-filled': closeFilled,
  plus,
  minus,
  'checkmark-outline': checkmarkOutline,
  'warning-outline': warningOutline,
  'component-hierarchy': componentHierarchy,
  folder,
  'dms-folder': dmsFolder,
  'check-toast': checkToast,
  'not-visible': notVisible,
  'three-dot-eye': threeDotEye,
  image,
  upload,
  'help-circle': question,
  loader,
  'at-sign': atSign,
  'message-circle': messageCircle,
  '3D': icon3D,
  360: icon360,
  'crossed-out-circle': crossedOutCircle,
  'alert-circle': alertCircle,
  more,
  link,
  unlink,
  check,
  'sad-face': sadFace,
  crosshair,
  'critical-hazard': criticalHazard,
  'move-to': moveTo,
  bell,
  'alarm-bell-icon': alarmBellIcon,
  'bar-chart': barChart,
  min,
  max,
  med,
  avg,
  'map-crossed': mapCrossed,
  'green-check': greenCheck,
  'arrow-left': arrowLeft,
  'external-link': externalLink,
};

export const sizeMap = {
  xxs: {
    width: 12,
    height: 12,
  },
  xs: {
    width: 16,
    height: 16,
  },
  sm: {
    width: 20,
    height: 20,
  },
  md: {
    width: 24,
    height: 24,
  },
  xxl: {
    width: 48,
    height: 48,
  },
};
