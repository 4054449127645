import React from 'react';

import InputRange from 'react-input-range';
import RenderIf from '../../../../../common/render-if/components/render-if';

const CustomInputRange = ({ input: { onChange, ...restInput }, className, maxValue, minValue, label, labelClass, id, disabled, inputProps, isHidden, isRequired }) => {
  const handleChange = val => {
    if (restInput.value !== val) {
      onChange && onChange(val);
      inputProps.onChange(val);
    }
  };
  let valueProps = {};

  if (!restInput.value) {
    valueProps = {
      value: minValue || 0,
    };
  }

  return (
    <div className={`inspection-input ${className || ''} ${isHidden ? ' hidden-field' : ''}`}>
      {label && (
        <label className={`inspection-input__label ${labelClass || 'f-secondary-dark'}`} htmlFor={id}>
          {label}
          <RenderIf if={isRequired}>*</RenderIf>
        </label>
      )}
      <InputRange
        disabled={disabled || false}
        maxValue={maxValue}
        minValue={minValue}
        {...inputProps}
        {...restInput}
        {...valueProps}
        onChange={inputProps && inputProps.onChange && typeof inputProps.onChange ? handleChange : onChange}
      />
    </div>
  );
};

CustomInputRange.defaultProps = {
  input: {},
};

export default CustomInputRange;
