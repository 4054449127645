import constants from '../constants/request-reset-password-constants';
import { EMAIL_REGEX } from '../../../common/constants';

export const validate = values => {
  const errors = {};
  if (!values[constants.fields.email]) {
    errors[constants.fields.email] = 'RESET_PASSWORD.EMAIL_REQUIRED';
  }
  if (values[constants.fields.email] && !EMAIL_REGEX.test(values[constants.fields.email])) {
    errors[constants.fields.email] = 'RESET_PASSWORD.EMAIL_INVALID';
  }

  return errors;
};
