export const userTeamFields = {
  id: 'ID',
  name: 'Name',
  type: 'Type',
  email: 'Email',
  status: 'Status',
  company: 'Company',
  membersCount: 'MembersCount',
};

export const userDetailsDisplayProps = [
  { name: 'ID' },
  {
    name: 'Company',
  },
  {
    name: 'Email',
  },
  {
    name: 'Phone',
  },
  {
    name: 'PermitTestExpirationDate',
  },
];

export const participantTypes = {
  assignee: 'ASSIGNEE',
  collaborator: 'COLLABORATOR',
};
