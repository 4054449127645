export default {
  SELECT_ALL_DEFECTS: 'selectAllDefects',
  SELECT_ALL_MEASUREMENTS: 'selectAllMeasurements',
  DESELECT_ALL_DEFECTS: 'deselectAllDefects',
  DESELECT_ALL_MEASUREMENTS: 'deselectAllMeasurements',
  UPDATE_SELECTED_DEFECT: 'updateSelectedDefect',
  SELECT_ALL_DEFECTS_TEMP: 'selectAllDefectsTemp',
  SELECT_ALL_MEASUREMENTS_TEMP: 'selectAllMeasurementsTemp',
  DESELECT_ALL_DEFECTS_TEMP: 'deselectAllDefectsTemp',
  DESELECT_ALL_MEASUREMENTS_TEMP: 'deselectAllMeasurementsTemp',
  TOGGLE_DEFECT: 'toggleDefect',
  TOGGLE_MEASUREMENT: 'toggleMeasurement',
  TOGGLE_DEFECT_TEMP: 'toggleDefectTemp',
  TOGGLE_MEASUREMENT_TEMP: 'toggleMeasurementTemp',
  SELECT_OBJECT_TOOL: 'selectObjectTool',
  TOGGLE_LEFT_TOOLBAR: 'toggleLeftToolbar',
  TOGGLE_TOP_TOOLBAR: 'toggleTopToolbar',
  TOGGLE_RIGHT_TOOLBAR: 'toggleRightToolbar',
  SELECT_DETAILS_PAGE: 'selectDetailsPage',
  SET_INSPECTION_DETAILS: 'setInspectionDetails',
  UPDATE_INSPECTION_DETAILS: 'editInspectionDetails',
  SET_INSPECTION_DEFECTS: 'setInspectionDefects',
  AMEND_INSPECTION_DEFECTS: 'amendInspectionDefects',
  SET_INSPECTION_DEFECTS_LOADING: 'setInspectionDefectsLoading',
  FETCH_DEFECT_PROPERTIES_SUCCESS: 'fetchDefectPropertiesSuccess',
  FETCH_COMPONENT_PROPERTIES_SUCCESS: 'fetchComponentPropertiesSuccess',
  APPEND_DEFECT_PROPERTY: 'appendDefectProperty',
  APPEND_COMPONENT_PROPERTY: 'appendComponentProperty',
  UPDATE_DEFECT_PROPERTY: 'updateDefectProperty',
  UPDATE_COMPONENT_PROPERTY: 'updateComponentProperty',
  REMOVE_DEFECT_PROPERTY: 'removeDefectProperty',
  REMOVE_COMPONENT_PROPERTY: 'removeComponentProperty',
  SET_INSPECTION_MEASUREMENTS: 'setInspectionMeasurements',
  AMEND_INSPECTION_MEASUREMENTS: 'amendInspectionMeasurements',
  SET_INSPECTION_MEASUREMENTS_LOADING: 'setInspectionMeasurementsLoading',
  SET_PROJECT_INSPECTIONS: 'setProjectInspections',
  SET_SELECTED_DEFECT: 'setSelectedDefect',
  APPEND_DEFECT: 'appendDefect',
  APPEND_TEMP_DEFECT: 'appendTempDefect',
  APPEND_MEASUREMENT: 'appendMeasurement',
  APPEND_TEMP_MEASUREMENT: 'appendTempMeasurement',
  UPDATE_DEFECT_SUCCESS: 'updateDefectSuccess',
  UPDATE_TEMP_DEFECT_SUCCESS: 'updateTempDefectSuccess',
  UPDATE_MEASUREMENT_SUCCESS: 'updateMeasurementSuccess',
  UPDATE_TEMP_MEASUREMENT_SUCCESS: 'updateTempMeasurementSuccess',
  APPEND_ELEMENT_COMMENT: 'appendElementComment',
  APPEND_DEFECT_TEMP_COMMENT: 'appendDefectTempComment',
  APPEND_MEASUREMENT_TEMP_COMMENT: 'appendMeasurementTempComment',
  HANDLE_DELETE_DEFECT_MODAL: 'handleDeleteDefectModal',
  HANDLE_DELETE_SHARE_MODAL: 'handleDeleteShareModal',
  SET_ACTIVE_LEFT_SIDEBAR: 'setActiveLeftSidebar',
  FETCH_SHARE_LINKS_SUCCESS: 'fetchShareLinksSuccess',
  APPEND_SHARE_LINK: 'appendShareLink',
  SET_INSPECTION_ID: 'setInspectionId',
  SET_PROJECT_ID: 'setProjectId',
  UPDATE_INSPECTION_SETTINGS: 'updateInspectionSettings',
  SET_DEFECT_LABEL_SUGGESTIONS: 'setDefectLabelSuggestions',
  CLEAR_DEFECT_LABEL_SUGGESTIONS: 'clearDefectLabelSuggestions',
  SET_COMPONENT_LABEL_SUGGESTIONS: 'setComponentLabelSuggestions',
  CLEAR_COMPONENT_LABEL_SUGGESTIONS: 'clearComponentLabelSuggestions',
  SET_PARENT_ASSET_SUGGESTIONS: 'setParentAssetSuggestions',
  CLEAR_PARENT_ASSET_SUGGESTIONS: 'clearParentAssetSuggestions',
  SET_COMPONENT_TYPE_SUGGESTIONS: 'setComponentTypeSuggestions',
  CLEAR_COMPONENT_TYPE_SUGGESTIONS: 'clearComponentTypeSuggestions',
  SET_COMPONENT_SUB_TYPE_SUGGESTIONS: 'setComponentSubTypeSuggestions',
  CLEAR_COMPONENT_SUB_TYPE_SUGGESTIONS: 'clearComponentSubTypeSuggestions',
  SET_MATERIAL_SUGGESTIONS: 'setMaterialSuggestions',
  CLEAR_MATERIAL_SUGGESTIONS: 'clearMaterialSuggestions',
  SET_MANUFACTURER_SUGGESTIONS: 'setManufacturerSuggestions',
  CLEAR_MANUFACTURER_SUGGESTIONS: 'clearManufacturerSuggestions',
  SELECT_ALL_COMPONENTS: 'selectAllComponents',
  DESELECT_ALL_COMPONENTS: 'deselectAllComponents',
  TOGGLE_COMPONENT: 'toggleComponent',
  SELECT_ALL_COMPONENTS_TEMP: 'selectAllComponentsTemp',
  DESELECT_ALL_COMPONENTS_TEMP: 'deselectAllComponentsTemp',
  TOGGLE_COMPONENT_TEMP: 'toggleComponentTemp',
  SET_INSPECTION_COMPONENTS: 'setInspectionComponents',
  SET_INSPECTION_COMPONENTS_LOADING: 'setInspectionComponentsLoading',
  AMEND_INSPECTION_COMPONENTS: 'amendInspectionComponents',
  SET_INSPECTION_COMPONENTS_CLUSTERED: 'setInspectionComponentsClustered',
  SET_INSPECTION_OBSERVATION_CLUSTERED: 'setInspectionObservationClustered',
  SET_INSPECTION_MEASUREMENTS_CLUSTERED: 'setInspectionMeasurementsClustered',
  SET_INSPECTION_NDT_MEASUREMENTS_CLUSTERED: 'setInspectionNDTMeasurementsClustered',
  APPEND_COMPONENTS: 'appendComponents',
  APPEND_TEMP_COMPONENTS: 'appendTempComponents',
  UPDATE_COMPONENTS_SUCCESS: 'updateComponentsSuccess',
  UPDATE_TEMP_COMPONENTS_SUCCESS: 'updateTempComponentsSuccess',
  APPEND_COMPONENT_TEMP_COMMENT: 'appendComponentTempComment',
  SET_COMPONENT_FILTER: 'setComponentFilter',
  SET_COMPONENT_DETAILS_DATA: 'setComponentDetailsData',
  SET_DEFECT_TYPE_SUGGESTIONS: 'setDefectTypeSuggestions',
  SET_FETCHING_REPORT_DATA_ACTIVE: 'setFetchingReportDataActive',
  SET_DOWNLOAD_REPORT_HISTORY_DATA: 'setDownloadReportData',
  SET_DOWNLOAD_REPORT_PREFERENCES: 'setDownloadReportPreferences',
  SET_DOWNLOAD_REPORT_PREFERENCES_FIELD: 'setDownloadReportPreferencesField',
  SET_DOWNLOAD_REPORT_POTREE: 'setDownloadReportPotree',
  APPEND_TEMP_INSPECT_POINT: 'APPEND_TEMP_INSPECT_POINT',
  UPDATE_TEMP_INSPECT_POINT_SUCCESS: 'UPDATE_TEMP_INSPECT_POINT_SUCCESS',
  SET_NOTIFICATIONS: 'setNotifications',
  SET_NOTIFICATIONS_LOADING: 'setNotificationsLoading',
  AMEND_MOTIFICATIONS: 'amendNotifications',
  SET_EXPLOSIVE_ZONES: 'setExplosiveZones',
  SET_INSPECTION_EXPLOSIVE_ZONES_CLUSTERED: 'setInspectionExplosiveZonesClustered',
  SET_EXPLOSIVE_ZONES_LOADING: 'setExplosiveZonesLoading',
  HANDLE_DELETE_EXPLOSIVE_ZONE_MODAL: 'handleDeleteExplosiveZoneModal',
  SET_EXPLOSIVE_ZONE_AREAS: 'setExplosiveZoneAreas',
  AMEND_EXPLOSIVE_ZONE_AREAS: 'amendExplosiveZoneAreas',
  TOGGLE_EXPLOSIVE_ZONE: 'toggleExplosiveZone',
  SELECT_ALL_ZONES: 'selectAllZones',
  DESELECT_ALL_ZONES: 'deselectAllZones',
  SET_AREAS: 'setAreas',
  AMEND_AREAS: 'amendAreas',
  SET_AREAS_LOADING: 'setAreasLoadin',
  SET_INSPECTION_AREAS_CLUSTERED: 'setInspectionAreasClustered',
  HANDLE_DELETE_AREA_MODAL: 'handleDeleteAreaModal',
  UPDATE_AREA_IN_ARRAY: 'updateAreaInArray',
  DELETE_AREA_IN_ARRAY: 'deleteAreaInArray',
  DESELECT_ALL_AREAS: 'deselecrtAllAreas',
  SELECT_ALL_AREAS: 'selectAllAreas',
  TOGGLE_AREA: 'toggleArea',
  SHOW_AREA: 'showArea',
  HIDE_AREA: 'hideArea',
  HANDLE_INSPECTION_MODAL: 'handleInspectionModal',
  SET_NDT_MEASUREMENT_LOCATIONS: 'setNdtMeasurementLocations',
  AMEND_NDT_MEASUREMENT_LOCATIONS: 'amendNdtMeasurementLocations',
  SET_NDT_MEASUREMENT_LOCATIONS_LOADING: 'setNdtMeasurementLocationsLoading',
  SET_NDT_MEASUREMENT_LABEL_SUGGESTIONS: 'setNdtMeasurementLabelSuggestions',
  CLEAR_NDT_MEASUREMENT_LABEL_SUGGESTIONS: 'clearNdtMeasurementLabelSuggestions',
  FETCH_NDT_MEASUREMENT_PROPERTIES_SUCCESS: 'fetchNdtMeasurementPropertiesSuccess',
  SET_NDT_MEASUREMENT_POINT_LABEL_SUGGESTIONS: 'setNdtMeasurementPointLabelSuggestions',
  CLEAR_NDT_MEASUREMENT_POINT_LABEL_SUGGESTIONS: 'clearNdtMeasurementPointLabelSuggestions',
  FETCH_NDT_MEASUREMENT_POINT_PROPERTIES_SUCCESS: 'fetchNdtMeasurementPointPropertiesSuccess',
  APPEND_NDT_MEASUREMENT_PROPERTY: 'appendNdtMeasurementProperty',
  UPDATE_NDT_MEASUREMENT_PROPERTY: 'updateNdtMeasurementProperty',
  REMOVE_NDT_MEASUREMENT_PROPERTY: 'removeNdtMeasurementProperty',
  APPEND_NDT_MEASUREMENT_POINT_PROPERTY: 'appendNdtMeasurementPointProperty',
  UPDATE_NDT_MEASUREMENT_POINT_PROPERTY: 'updateNdtMeasurementPointProperty',
  REMOVE_NDT_MEASUREMENT_POINT_PROPERTY: 'removeNdtMeasurementPointProperty',
  HANDLE_DELETE_NDT_MEASUREMENT_MODAL: 'handleDeleteNDTMeasurementModal',
  TOGGLE_NDT_MEASUREMENT: 'toggleNDTMeasurement',
  SHOW_ALL_NDT_MEASUREMENT: 'showAllNDTMeasurement',
  HIDE_ALL_NDT_MEASUREMENT: 'hideAllNDTMeasurement',
  SET_NDT_ALARMS: 'setNdtAlarms',
  SET_DESCRIPTOR_DATA: 'setDescriptorData',
  SET_NDT_MEASUREMENT_POINTS: 'setNDTMeasurementPoints',
  SET_ACTIVE_NDT_MEASUREMENT_POINT: 'setActiveNDTMeasurementPoint',
  HANDLE_NDT_MEASUREMENT_POINT_MODAL: 'handleNDTMeasurementPointModal',
  SET_NDT_CHART_FILTER: 'setNDTChartFilter',
  SET_NDT_CHART_TYPES_DATA: 'setNDTChartTypesData',
  SET_NDT_CHART_PERIODS_DATA: 'setNDTChartPeriodsData',
  SET_NDT_CHART_GROUPS_DATA: 'setNDTChartGroupsData',
  SET_NDT_MAIN_CHART_DATA: 'setNDTMainChartData',
  SET_NDT_SMALL_CHART_DATA: 'setNDTSmallChartData',
  SET_NDT_SMALL_CHART_DATA_LOADING: 'setNDTSmallChartDataLoading',
  SET_NDT_CHART_PPERIOD_GROUPS_DATA: 'setNdtChardPeriodGroupData',
  SET_NDT_EMAILS: 'setNdtEmails',
  ADD_ALARM_EMAIL_INTO_ARRAY: 'addAlarmEmailIntoArray',
  REMOVE_ALARM_EMAIL_FROM_ARRAY: 'removeAlarmEmailFromArray',
  SET_NDT_MEASUREMENTS_COMPONENTS: 'setNdtMeasurementsComponents',
  SET_NDT_MEASUREMENTS_UNITS: 'setNdtMeasurementsUnits',
  SET_NDT_ALARMS_TRIGGERED: 'setNdtAlarmsTriggererd',
  SET_NDT_PREDICTIVE_TYPES_DATA: 'setNDTPredictiveTypesData',

  SET_NDT_PREDICTIVE_SETTINGS_DATA: 'setNDTPredictiveSettingsData',
  FETCH_PREDICTIVE_RESULTS_START: 'fetcPredictiveResultsStart',
  FETCH_PREDICTIVE_RESULTS_SUCCESS: 'fetcPredictiveResultsSuccess',
  FETCH_PREDICTIVE_RESULTS_FAILURE: 'fetcPredictiveResultsFailure',
  SAVE_COMPONENT_START: 'saveComponentStart',
  SAVE_COMPONENT_SUCCESS: 'saveComponentSuccess',
  SAVE_COMPONENT_FAILURE: 'saveComponentFailure',
  HANDLE_ADD_DOCUMENT_IN_ARRAY: 'handleAddDocumentInArray',
  HANDLE_REMOVE_ITEM_BY_PROP: 'handleRemoveItemByProp',
  HANDLE_DEFECT_RELATED_COMPONENTS_VISIBLE: 'handleDefectRelatedComponentsVisible',
  FETCH_AREA_GROUPS_DROPDOWN: 'fetchAreaGroupsDropdown',
  FETCH_AREA_GROUPS_FILTER_DROPDOWN: 'fetchAreaGroupsFilterDropdown',
  ADD_AREA_GROUPS_DROPDOWN_ITEM_AT_INDEX: 'addAreaGroupsDropdownItemAtIndex',
  UPDATE_AREA_GROUPS_DROPDOWN_ITEM_BY_PROP: 'updateAreaGroupsDropdownItemByProp',
  REMOVE_AREA_GROUPS_DROPDOWN_ITEM_BY_PROP: 'removeAreaGroupsDropdownItemByProp',
  SET_COMPONENT_RELATED_DEFECTS: 'setComponentRelatedDefects',
  SET_COMPONENT_RELATED_DEFECTS_LOADING: 'setComponentRelatedDefectsLoading',
  SET_SEARCH_TEXT_LEFT_TOOLBAR: 'setSearchTextLeftToolbar',
  HANDLE_REAL_CAMERA_TYPE_CHANGE: 'handleRealCameraTypeChange',
  HANDLE_SET_OBSERVATION_TYPES: 'handleSetObservationTypess',
  SET_FILTER_GROUP_SUGGESTIONS: 'setFilterGroupSuggestions',
  CLEAR_FILTER_GROUP_SUGGESTIONS: 'clearFilterGroupSuggestions',
  HANDLE_SET_OBSERVATION_NOTIFICATIONS: 'handleSetObsservationNotifications',
  SET_OBSERVATION_NOTIFICATIONS_LOADING: 'setObservationNotificationsLoading',
  REMOVE_NOTIFICATION_BY_PROP: 'removeNotificationByProp',
  UPDATE_NOTIFICATION_BY_PROP: 'updateNotificationByProp',
  HANDLE_DELETE_NOTIFICATION_MODAL: 'handleDeleteNotificationModal',
  SET_WORK_ORDERS: 'handleSetWorkOrders',
  AMEND_WORK_ORDERS: 'handleAmendWorkOrders',
  HANDLE_WORK_ORDERS_LOADING: 'handleWorkOrdersLoading',
  HANDLE_DELETE_WORK_ORDER_MODAL: 'handleDeleteWorkorderModal',
  HANDLE_REMOVE_WORK_ORDER: 'handleRemoveWorkorder',
  REMOVE_WORKORDER_BY_PROP: 'removeWorkorderByProp',
  UPDATE_WORKORDER_BY_PROP: 'updateWorkorderByProp',
  SET_WORK_ORDER_COMPONENTS: 'setWorkOrderComponents',
  AMEND_WORK_ORDER_COMPONENT: 'amendWorkOrderComponent',
  REMOVE_WORK_ORDER_COMPONENT: 'removeWorkOrderComponent',
  SET_WORK_ORDER_ASSIGNEES: 'setWorkOrderAssignees',
  AMEND_WORK_ORDER_ASSIGNEES: 'amendWorkOrderAssignees',
  REMOVE_WORK_ORDER_ASSIGNEES: 'removeWorkOrderAssignees',
  SET_WORK_ORDER_CONTRIBUTORS: 'setWorkOrderContributors',
  AMEND_WORK_ORDER_CONTRIBUTOR: 'amendWorkOrderContributor',
  REMOVE_WORK_ORDER_CONTRIBUTOR: 'removeWorkOrderContributor',
  SET_WORK_ORDER_CHECKLISTS: 'setWorkOrderChecklists',
  AMEND_WORK_ORDER_CHECKLIST: 'amendWorkOrderChecklist',
  REMOVE_WORK_ORDER_CHECKLIST: 'removeWorkOrderChecklist',
  SET_WORK_ORDER_AREAS: 'setWorkOrderAreas',
  AMEND_WORK_ORDER_AREA: 'amendWorkOrderArea',
  UPDATE_WORK_ORDER_AREA: 'updateWorkOrderArea',
  REMOVE_WORK_ORDER_AREA: 'removeWorkOrderArea',
  SET_WORK_ORDER_FILES: 'setWorkOrderFiles',
  SET_WORK_ORDER_COMPLETION_FILES: 'setWorkOrderCompletionFiles',
  SET_WORK_ORDER_COMMENTS: 'setWorkOrderComments',
  HANDLE_WORK_ORDER_UPDATE_COUNT_FIELD: 'handleWorkOrderUpdateField',
  REMOVE_FILE_BY_SECTION: 'removeFileBySection',
  REMOVE_WORK_ORDER_COMPLETION_FILE_BY_SECTION: 'removeWorkOrderCompletionFileBySection',
  SET_NOTIFICATION_WORK_ORDERS: 'setNotificationWorkOrders',
  AMEND_NOTIFICATION_WORK_ORDERS: 'amendNotificationWorkOrders',
  UPDATE_WORK_ORDER_CHECKLIST_IN_LIST: 'updateWorkOrderChecklist',
  HANDLE_SET_NDT_MEASUREMENT_NOTIFICATIONS: 'handleSetNdtMeasurementNotifications',
  SET_DEFECT_DETAILS_LOADING_LOADING: 'handleDefectDetailsLoading',
  SET_COMPONENT_DETAILS_LOADING: 'handleSetComponentDetailsLoading',
  SET_MEASUREMENT_DETAILS_LOADING_LOADING: 'handleSetMeasurementsLoading',
  SET_WORK_ORDER_DETAILS_LOADING: 'handleSetWorkOrderDetailsLoading',
  SET_NOTIFICATION_DETAILS_LOADING: 'handleSetNotificationDetailsLoading',
  SET_EXPLOSIVE_ZONE_DETAILS_LOADING: 'handleSetExplosiveZonesLoading',
  SET_AREA_DETAILS_LOADING: 'haandleSetAreaDetailsLoading',
  SET_NDT_MEASUREMENT_DETAILS_LOADING: 'setNdtMeasurementDetailsLoading',
  HANDLE_CREATE_WORK_ORDER_MODAL: 'handleCreateWorkOrderModal',
  SET_SORT_BY_FILTER: 'setSortByFilter',
  SET_ISOLATION_CERTIFICATES: 'setIsolationCertificates',
  SET_ISOLATION_CERTIFICATES_FILTERS: 'setIsolationCertificatesFilters',
  SET_PERMITS_DATA: 'setPermitsData',
  SET_PERMIT_DETAILS_DATA: 'setPermitDetailsData',
  SET_WORK_ORDER_PERMITS: 'setWorkOrderPermits',
  AMEND_WORK_ORDER_PERMIT: 'amendWorkOrderPermit',
  REMOVE_WORK_ORDER_PERMIT: 'removeWorkOrderPermits',
  SET_PERMITS_FILTERS: 'setPermitsFilters',
  SET_WORK_ORDER_FILTERS: 'setWorkOrderFilters',
  SET_WORK_ORDER_PAGING: 'setWorkOrderPaging',
  SET_LOCATION_SUGGESTIONS: 'setLocationSuggestions',
  CLEAR_LOCATION_SUGGESTIONS: 'clearLocationSuggestions',
  SET_CH_SHOW: 'setCHshow',
  SET_HIERARCHY_ID: 'setHierarchyID',
  SET_IMAGES_360_REF: 'setImages360Ref',
  SET_SHOW_CH_SUB_LEVELS: 'setShowCHSubLevels',
  SET_CH_LEVELS: 'setCHLevels',
  TOGGLE_LEVEL: 'toggleLevel',
  SET_CH_HIDE_ALL: 'setCHHideAll',
  AMEND_REGIME_WORK_ORDERS: 'handleAmendRegimeWorkOrders',
  HANDLE_REGIME_WORK_ORDERS_LOADING: 'handleSetRegimeWorkOrdersLoading',
  SET_REGIME_WORK_ORDER_FILTERS: 'setRegimeWorkOrderFilters',
  SET_WORK_ORDER_PAGING_REGIME: 'setWorkOrderPagingRegime',
  SET_COMPONENTS_FILTER: 'setComponentsFilter',
  SET_PANORAMIC_IMAGES: 'setPanoramicImages',
  SET_HIDE_UNASSIGNED_COMPONENTS: 'setHideUnassignedComponents',
  SET_DISPLAY_UNASSIGNED_COMPONENTS_LEVEL: 'setDisplayUnassignedComponentsLevel',
  SET_IS_3D_VIEW_MODE_ACTIVE: 'setIs3DViewModeActive',
  FETCH_NOTIFICATION_PROPERTIES_SUCCESS: 'fetchNotificationPropertiesSuccess',
  SET_NOTIFICATION_LABEL_SUGGESTIONS: 'setNotificationLabelSuggestions',
  CLEAR_NOTIFICATION_LABEL_SUGGESTIONS: 'clearNotificationLabelSuggestions',
  SET_DMS_CATEGORIES: 'setDMSCategories',
  SET_WORK_ORDER_PROPERTIES: 'setWorkOrderProperties',
  SET_COMPONENT_LOCATION_EDITING: 'setComponentLocationEditing',
  SET_COMPONENT_LOCATION_ADDING: 'setComponentLocationAdding',
  SET_DEFECT_LOCATION_EDITING: 'setDefectLocationEditing',
  SET_DEFECT_LOCATION_ADDING: 'setDefectLocationAdding',
  SET_NOTIFICATIONS_FILTER: 'setNotificationsFilter',
  SET_NOTIFICATIONS_CLUSTERED: 'setNotificationsClustered',
  SET_TOGGLED_NOTIFICATION: 'setToggledNotification',
  SET_TOGGLE_NOTIFICATIONS_HIDDEN: 'setToggleNotificationsHidden',
  SET_NOTIFICATION_LOCATION_EDITING: 'setNotificationLocationEditing',
  SET_NOTIFICATION_LOCATION_ADDING: 'setNotificationLocationAdding',
  UPDATE_NOTIFICATIONS_SUCCESS: 'updateNotificationSuccess',
  SET_NOTIFICATION_FORM_STATE: 'setNotificationFormState',
  ADD_NOTIFICATION_PROPERTIES: 'addNotificationProperties',
  RESET_NOTIFICATION_FILTERS_PROP: 'resetNotificationFiltersProp',
  REMOVE_DELETED_NOTIFICATION_CUSTOM_PROPERTY: 'removeDeletedNotificationCustomProperty',
  REPLACE_ADDED_NOTIFICATION_CUSTOM_PROPERTY: 'replaceAddedNotificationCustomProperty',
  SET_OBSERVATION_SUBTYPES_SUGGESTIONS: 'setObservationSubTypesSuggestions',
  CLEAR_OBSERVATION_SUBTYPES_SUGGESTIONS: 'clearObservationSubTypesSuggestions',
  SET_OBSERVATION_CONTRIBUTORS: 'setObservationContributors',
  AMEND_OBSERVATION_CONTRIBUTOR: 'amendObservationContributor',
  REMOVE_OBSERVATION_CONTRIBUTOR: 'removeObservationContributor',
  SET_WORKORDER_LABEL_SUGGESTIONS: 'setWorkorderLabelSuggestions',
  CLEAR_WORKORDER_LABEL_SUGGESTIONS: 'clearWorkorderLableSuggestions',
  SET_WORK_ORDER_CP_TEMPLATES: 'setWorkOrderCPTemplates',
  AMEND_WORK_ORDER_CP_TEMPLATE: 'amendWorkOrderCPTemplate',
  REMOVE_WORK_ORDER_CP_TEMPLATE: 'removeWorkOrderCPTemplates',
  SET_WORK_ORDER_CP_SECTIONS: 'setWorkOrderCPSections',
  LINK_WORK_ORDER_CP_TEMPLATE_TO_COMPONENTS: 'linkWorkOrderCPTemplateToComponents',
  UNLINK_WORK_ORDER_CP_TEMPLATE_FROM_COMPONENTS: 'unlinkWorkOrderCPTemplateFromComponents',
  REMOVE_WORK_ORDER_COMPONENT_FROM_CP_TEMPLATE: 'removeWorkOrderComponentFromCPTemplate',
  SET_OBSERVATION_FORM_STATE: 'setObservationFormState',
  REPLACE_ADDED_OBSERVATION_CUSTOM_PROPERTY: 'replaceAddedObservationCustomProperty',
  SET_CREATE_NOTIFICATION_FORM_SUBMITTING: 'setCreateNotificationFormSubmitting',
  SET_WORK_ORDER_OBSERVATIONS: 'setWorkOrderObservations',
  SET_OBSERVATION_WORK_ORDERS: 'setObservationWorkOrders',
  SET_COMPONENT_FORM_STATE: 'setComponentFormState',
  REPLACE_ADDED_COMPONENT_CUSTOM_PROPERTY: 'replaceAddedComponentCustomProperty',
  SET_UNSAVED_CHANGES_DIRTY: 'setUnsavedChangesDirty',
  CLEAR_UNSAVED_CHANGES_DIRTY: 'clearUnsavedChangesDirty',
};
