import React, { useMemo } from 'react';
import { Field, getFormSyncErrors, reduxForm } from 'redux-form';
import { FORMS } from '../../../../../../../../common/constants';
import { fields, fieldsValidatorConstants } from '../constants/constants';
import CustomInput from '../../../../../../../../common/form/components/input';
import Textarea from '../../../../../../../../common/form/components/text-area';
import CustomDatePicker from '../../../../../../../../common/form/components/date-picker';
import CheckBox from '../../../../../../../../common/form/components/checkbox';
import Button from '../../../../../../../../common/form/components/button';
import '../styles/version-modal.scss';
import Icon from '../../../../../../../../common/icon/components/icon';
import { getIconForFileType } from '../../../../../../actions/table-formatting';

import PropTypes from 'prop-types';
import { validateVersionMetaData } from '../validators/version-form-validator';
import RenderIf from '../../../../../../../../common/render-if/components/render-if';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

const VersionModalForm = (props, context) => {
  const { file, closeAction, handleSubmit, isEditMode, initialValues, isButtonDisabled, formErrors, nextVersionNumber } = props;
  const { t } = context;

  const fileData = useMemo(() => {
    if (isEditMode) {
      return {
        [fields.name]: initialValues[fields.fileName],
        [fields.fileType]: initialValues[fields.fileType],
      };
    } else {
      return {
        [fields.name]: file[fields.name],
        [fields.fileType]: file[fields.fileType],
      };
    }
  }, [isEditMode, file, initialValues]);
  const fileVersion = useMemo(() => {
    return isEditMode ? file[fields.fileVersion] : nextVersionNumber;
  }, [isEditMode, nextVersionNumber, file]);
  // TODO: Handle the modal rendering if isEdit is active

  return (
    <form noValidate className="version-modal" onSubmit={handleSubmit}>
      <div className="version-modal__file-details-container mb-32">
        <p className="version-modal__file-details-container__title mb-12">{t('DMS_FILE.FILE')}</p>
        <div className="version-modal__file-details-container__file-details">
          <div className={`version-modal__file-details-container__file-details__name ${isEditMode ? 'edit-mode' : ''}`}>
            <p className="data-title">{t('NAME')}</p>
            <div className="name-container">
              <Icon name={getIconForFileType(fileData[fields.fileType])} size="md" active handleHover={false} />
              <p title={fileData[fields.name]} className="file-name">
                {fileData[fields.name]}
              </p>
            </div>
          </div>
          <div className={`version-modal__file-details-container__file-details__version ${isEditMode ? 'edit-mode' : ''}`}>
            <p className="data-title">{t('DMS_FILE.SYSTEM_VERSION')}</p>
            <p>{fileVersion}</p>
          </div>
          <RenderIf if={!isEditMode}>
            <div className="version-modal__file-details-container__file-details__active">
              <p className="data-title">
                <Icon name="checkmark" active={true} size="xs" />
                {t('DMS_FILE.ACTIVE_VERSION')}
              </p>
              <Field id={fields.isActive} name={fields.isActive} component={CheckBox} checkboxText="YES_CAPITALIZED" />
            </div>
          </RenderIf>
        </div>
      </div>
      <div className="version-modal__file-metadata-section">
        <p className="version-modal__file-metadata-section__title mb-16 light-bold">{t(`DMS_FILE.${isEditMode ? 'EDIT' : 'ADD'}_FILE_DETAILS`)}</p>
        <div className="version-modal__file-metadata-section__row">
          <div className="version-modal__file-metadata-section__row__field">
            <Field id={fields.versionNumber} name={fields.versionNumber} component={CustomInput} placeholder={t('DMS_FILE.VERSION_NUMBER')} label={t('DMS_FILE.VERSION_NUMBER')} size="lg" />
          </div>
          <div className="version-modal__file-metadata-section__row__field">
            <Field
              id={fields.versionDate}
              name={fields.versionDate}
              component={CustomDatePicker}
              placeholder={t('DMS_FILE.VERSION_DATE_TIME')}
              label={t('DMS_FILE.VERSION_DATE_TIME')}
              isRequired
              size="lg"
              validateUsingReduxForm
              isClearable={false}
              disabledKeyboardNavigation={true}
              dateFormat="MMMM d, yyyy h:mm aa"
              timeFormat="h:mm aa"
              showTimeSelect
              onKeyDown={e => e && e.preventDefault()}
            />
          </div>
        </div>
        <div className="version-modal__file-metadata-section-row">
          {/*pr-10 is here temporary until we add support for smart drawings*/}
          <div className="version-modal__file-metadata-section__row__field pr-10">
            <Field
              id={fields.versionDescription}
              name={fields.versionDescription}
              component={Textarea}
              placeholder={t('DMS_FILE.VERSION_DESCRIPTION')}
              label={t('DMS_FILE.VERSION_DESCRIPTION')}
              maxChars={fieldsValidatorConstants[fields.versionDescription].maxChars}
              className={'input-wrapper__input version-modal__description_field'}
            />
          </div>
        </div>
      </div>
      <div className="version-modal__button-section">
        <Button onClick={closeAction} type="button" text={t('CANCEL')} variant="gray-outline" className="modal-button-custom h-md" />
        <Button disabled={isButtonDisabled || (formErrors && !isEmpty(formErrors))} text={t(isEditMode ? 'SAVE' : 'UPLOAD')} variant="success" className="modal-button-custom h-md" />
      </div>
    </form>
  );
};

VersionModalForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  formErrors: getFormSyncErrors(FORMS.DMSFileVersionModalForm)(state),
});

export default connect(
  mapStateToProps,
  null
)(reduxForm({ form: FORMS.DMSFileVersionModalForm, validate: validateVersionMetaData, touchOnChange: true, destroyOnUnmount: true, enableReinitialize: true })(VersionModalForm));
