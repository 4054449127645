import React from 'react';
import PropTypes from 'prop-types';
import { values, filter } from 'lodash';

import Helpers from '../../../../../common/helpers';
import DisplayStatus from '../../../../../common/display-status/components/display-status';
import { fields as isolationCertificateFields } from '../constants/isolation-certificate-constants';
import { columnTypes, singleKeyFields } from '../constants/keybox-constants';
import { statuses } from '../../isolation-certificates/constants/isolation-certificates-table';

const IsolationCertificateItem = ({ component = {}, fields = isolationCertificateFields }, { t }) => {
  let componentFields = values(fields);
  componentFields = filter(componentFields, { showInDisplay: true });

  const getValue = field => {
    const value = component[field.name];

    if (fields.status && field.name === fields.status.name) {
      return <DisplayStatus statuses={values(statuses)} status={value} />;
    }
    if (field.type === columnTypes.source) {
      return component[fields.editable.name] ? t('PERMIT_KEYBOX.SOURCE.PERMIT') : t('PERMIT_KEYBOX.SOURCE.ISOLATION_CERTIFICATE');
    } else if (field.type === columnTypes.issuedKeys) {
      const issuedKeys = (component[fields.keys.name] || []).filter(key => key[singleKeyFields.issuedToId]);
      return issuedKeys.map(key => key[singleKeyFields.name]).join(', ') || '-';
    } else if (field.type === columnTypes.freeKeys) {
      const freeKeys = (component[fields.keys.name] || []).filter(key => !key[singleKeyFields.issuedToId]);
      return freeKeys.map(key => key[singleKeyFields.name]).join(', ') || '-';
    }
    if (value) {
      return Helpers.formatCell(value, field.type);
    }

    return '-';
  };

  return (
    <div className="isolation-certificate-item-container">
      {componentFields.map(field => (
        <div className="isolation-certificate-item" key={`component-item-${component[fields.id.name]}-${field.name}`}>
          <p className="f-secondary-dark">{t(field.label)}</p>
          <p className="f-primary">{getValue(field)}</p>
        </div>
      ))}
    </div>
  );
};

IsolationCertificateItem.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default IsolationCertificateItem;
