import PropTypes from 'prop-types';
import React from 'react';

import AddButton from '../../add-button/components/add-button';
import Icon from '../../icon/components/icon';
import { defaultOptionsFieldName, questionOptionsFields } from '../constants/question-constants';
import QuestionField from './question-field';

const QuestionOptions = ({
  questionId,
  fieldsName,
  minFields = 2,
  options,
  handleAddQuestionOption,
  handleUpdateQuestionOption,
  handleDeleteQuestionOption,
  forceShowAddButton,
  addButtonText,
  optionLabel,
  questionOptionUpdateDebounce,
  disabled,
  addButtonDisabled,
  placeholder,
  isRequired,
}) => {
  const { name, id } = questionOptionsFields;

  const onFieldChange = (value, index) => {
    // additionally disables the update function to trigger if validation fails
    if (!value || typeof handleUpdateQuestionOption !== 'function' || value?.length > name.maxChars) {
      if (questionOptionUpdateDebounce && typeof questionOptionUpdateDebounce.cancel === 'function') {
        questionOptionUpdateDebounce.cancel();
      }

      return;
    }

    const option = options[index];

    if (option) {
      option[name.name] = value;

      handleUpdateQuestionOption(option, index);
    }
  };

  const onOptionDelete = index => {
    if (typeof handleDeleteQuestionOption === 'function') {
      const option = options[index];
      handleDeleteQuestionOption(index, option);
    }
  };

  return (
    <div className="question-options" noValidate>
      <div className="question-options__wrapper">
        {options &&
          options.length > 0 &&
          options.map((option, index) => {
            return (
              <div className={`question-options__wrapper__field ${(index + 1) % 3 === 0 ? '' : 'with-padding'}`} key={`section-question-option-${option[id.name]}`}>
                <QuestionField
                  name={`${fieldsName || defaultOptionsFieldName}-${name.name}-${option[id.name]}`}
                  label={optionLabel || name.label}
                  placeholder={placeholder || name.placeholder}
                  type={name.type}
                  id={`section-question-option-${option[id.name]}`}
                  labelTranslationObj={{ number: index + 1 }}
                  onChange={(_e, value) => onFieldChange(value, index)}
                  placeholderTranslationObj={{ number: index + 1 }}
                  style={{ minWidth: '150px' }}
                  disabled={disabled}
                  isRequired
                />
                {options && options.length > minFields && !disabled && (
                  <Icon size="sm" className="question-options__wrapper__field__icon" name="close" onClick={() => onOptionDelete(index, options)} />
                )}
              </div>
            );
          })}
        {((options && options.length > 0) || forceShowAddButton) && !disabled && (
          <AddButton onClick={() => handleAddQuestionOption(questionId, options)} disabled={addButtonDisabled}>
            {addButtonText || 'SECTIONS.QUESTION_OPTION_ADD'}
          </AddButton>
        )}
      </div>
    </div>
  );
};

QuestionOptions.defaultProps = {
  questionOptionsFields: questionOptionsFields,
  forceShowAddButton: false,
};

QuestionOptions.propTypes = {
  questionId: PropTypes.number,
  fieldsName: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
    })
  ),
  handleAddQuestionOption: PropTypes.func,
  handleUpdateQuestionOption: PropTypes.func,
  handleDeleteQuestionOption: PropTypes.func,
  forceShowAddButton: PropTypes.bool,
  addButtonText: PropTypes.string,
  optionLabel: PropTypes.string,
};

QuestionOptions.defaultProps = {
  options: [],
};

export default QuestionOptions;
