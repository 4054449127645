import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { placements } from '../../../../constants/constants';

import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { DIMENSIONS, FORMS } from '../../../../../../common/constants';
import TimeSeriesGraph from '../../../../../../common/graphs/components/time-series-graph';
import Helpers from '../../../../../../common/helpers';
import CollapsibleToolbar from '../../../collapsible-toolbar';
import { formConstants as mpFormConstants } from '../../constants/measurement-point-constants';
import { aggregationFields, chunkFields, formConstants, settingsFormConstants, timePeriodFields } from '../../constants/time-series-graph-constants';
import '../../styles/time-series-graph-configuration.scss';
import TimeSeriesGraphSettings from './time-series-graph-settings';

const TimeSeriesGraphConfiguration = ({ queryItem, getTimeSeriesGraphData, showGraphingGroup, formValues, canSave, saveSettings, getMeasurementPointGraphDetails }) => {
  const [rightCollapsed, setRightCollapsed] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const [graphLoading, setGraphLoading] = useState(false);
  const [appliedGraphSettings, setAppliedGraphSettings] = useState({});

  const fetchGraphData = settingsValues => {
    if (isEmpty(settingsValues)) {
      return;
    }

    setGraphLoading(true);
    const formatedGraphSettings = {
      ...settingsValues,
      [settingsFormConstants.graphingGroup.name]: settingsValues[settingsFormConstants.graphingGroup.name]?.[formConstants.id],
      [settingsFormConstants.aggregation.name]: (settingsValues[settingsFormConstants.aggregation.name] || []).map(el => el[aggregationFields.key]),
      [settingsFormConstants.chunks.name]: settingsValues[settingsFormConstants.chunks.name]?.[chunkFields.key],
      [settingsFormConstants.dateFrom.name]: Helpers.dateToUnix(settingsValues[settingsFormConstants.dateFrom.name]),
      [settingsFormConstants.dateTo.name]: Helpers.dateToUnix(settingsValues[settingsFormConstants.dateTo.name]),
      [settingsFormConstants.timePeriod.name]: settingsValues[settingsFormConstants.timePeriod.name]?.[timePeriodFields.value],
    };

    getTimeSeriesGraphData(
      formatedGraphSettings,
      graphData => {
        const mergedData = (graphData || []).map(data => ({
          ...data,
          Series: Helpers.mergeSeriesData(data.Series, data[mpFormConstants.fields.scaleFactor] || undefined, data[mpFormConstants.fields.inverted]),
        }));
        setGraphData(mergedData);
        setAppliedGraphSettings(settingsValues);
        setGraphLoading(false);
      },
      () => {
        setGraphData([]);
        setGraphLoading(false);
      }
    );
  };

  return (
    <div className="time-series-graph-configuration">
      <div className={`time-series-graph-configuration__graph-wrapper ${rightCollapsed ? '' : 'with-right-padding'}`}>
        <TimeSeriesGraph
          data={graphData}
          isLoading={graphLoading}
          showAlarms={formValues?.[settingsFormConstants.showAlarms.name]}
          visibleAggregations={appliedGraphSettings?.[settingsFormConstants.aggregation.name]}
          ignoreTheme
        />
      </div>
      <CollapsibleToolbar
        collapsed={rightCollapsed}
        toggleToolbar={() => setRightCollapsed(!rightCollapsed)}
        placement={placements.right}
        resizable={false}
        defaultWidth={DIMENSIONS.mlTimeSeriesGraphRightToolbar}
      >
        <TimeSeriesGraphSettings
          queryItem={queryItem}
          fetchGraphData={fetchGraphData}
          showGraphingGroup={showGraphingGroup}
          formValues={formValues}
          canSave={canSave}
          saveSettings={saveSettings}
          getMeasurementPointGraphDetails={getMeasurementPointGraphDetails}
        />
      </CollapsibleToolbar>
    </div>
  );
};

TimeSeriesGraphConfiguration.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  formValues: getFormValues(FORMS.timeSeriesGraphSettingsForm)(state),
});

export default connect(mapStateToProps, null)(TimeSeriesGraphConfiguration);
