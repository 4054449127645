import React, { Component } from 'react';
import SortingIcon from './sorting-icon';

class TableHeader extends Component {
  render() {
    const { tableConfig, translationModule, onColumnSort, sortingObj, stickyHeader, sortByColumnProp, sortDirectionProp } = this.props;

    return (
      <thead>
        <tr>
          {tableConfig.map((item, index) => {
            if (item.hidden) {
              return null;
            }
            return (
              <th
                key={index}
                onClick={() => (item.enableSort && onColumnSort ? onColumnSort(item.sortKey || item.key) : null)}
                className={`${stickyHeader ? 'sticky' : ''} ${onColumnSort ? 'clickable' : ''} ${item.class || ''}`}
              >
                {item.HeaderContent ? (
                  <item.HeaderContent />
                ) : (
                  <span className="table-header"> {translationModule && item.title ? translationModule(item.title, item.translationProps) : item.title} </span>
                )}
                {item.enableSort && (
                  <SortingIcon
                    {...{
                      sortingObj,
                      sortDirectionProp,
                      isDisabled: sortingObj[sortByColumnProp] !== item.key,
                    }}
                  />
                )}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  }
}

export default TableHeader;
