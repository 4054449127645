import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Button from '../../../../../common/form/components/button';
import Textarea from '../../../../../common/form/components/text-area';
import Helpers from '../../../../../common/helpers';
import RenderIf from '../../../../../common/render-if/components/render-if';
import { commentsField, fields } from '../isolation-certificate-details/constants/comments-constants';
import SectionEmptyState from './section-empty-state';

import '../../../styles/isolation-certificate-comments.scss';

class Comments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      [fields.comment.name]: '',
      disabled: false,
    };
  }

  componentDidUpdate = prevProps => {
    /*
    This component updates its state based on the ID of the module prop that is passed in. We check if the ID of the 
    module has changed between the previous props and the current props. If it has, we reset the component's state 
    by emptying the 'comment' field and enabling the component. This ensures that the component is properly updated
    when the module ID changes.
    */
    const { module: prevModule } = prevProps;
    const { module: currentModule } = this.props;

    if (prevModule.ID !== currentModule.ID) {
      this.setState({
        [fields.comment.name]: '',
        disabled: false,
      });
    }
  };

  handleAddComment = () => {
    const { customAction, handleCustomAction, initialValues } = this.props;

    const stateCleanup = isSuccess => {
      const newState = {
        disabled: false,
      };

      if (isSuccess) {
        newState[fields.comment.name] = '';
      }

      this.setState(newState);
    };

    if (this.state[fields.comment.name] && this.state[fields.comment.name].length <= 500 && typeof handleCustomAction === 'function') {
      this.setState({ disabled: true });
      handleCustomAction(customAction, { [fields.comment.name]: this.state[fields.comment.name], [fields.moduleSectionId.name]: initialValues[fields.sectionId.name] }, stateCleanup);
    }
  };

  render() {
    const { t } = this.context;
    const { disabled } = this.state;
    const { comment, createdAt, createdBy, commentText, id } = fields;
    const { initialValues, module, emptyStateTitle } = this.props;
    const comments = initialValues?.[commentsField];
    const moduleComments = comments || (module && module.workorderComments);

    return (
      <div className="comments-container">
        <RenderIf if={!moduleComments || !moduleComments.length}>
          <SectionEmptyState title={emptyStateTitle} />
        </RenderIf>
        <RenderIf if={moduleComments && moduleComments.length > 0}>
          {moduleComments &&
            moduleComments.length > 0 &&
            moduleComments.map(item => (
              <div className="display-comment-item" key={`isolation-certificate-comment-${item[id.name]}`}>
                <div className="title-wrapper">
                  <p className="f-secondary-dark bold">{item[createdBy.name]}</p>
                  <p className="f-secondary-dark bold create-date">{Helpers.getDateFromUnix(item[createdAt.name])}</p>
                </div>
                <p className="f-primary bold">{item[commentText.name]}</p>
              </div>
            ))}
        </RenderIf>
        <div className="add-comment-wrapper">
          <label>{t(comment.label)}</label>
          <div className="textarea-wrapper" id="comment-add-container">
            <Textarea
              input={{ onChange: e => this.setState({ [comment.name]: e.target.value }), value: this.state[comment.name] }}
              meta={{}}
              placeholder={comment.label}
              name={comment.name}
              maxChars={comment.maxChars}
              disabled={disabled}
            />
          </div>
          <div className="add-comment-button">
            <Button
              disabled={disabled || !this.state[comment.name] || this.state[comment.name].length > comment.maxChars}
              type="button"
              text={t('DEFECT_DETAILS.FORM_ADD_COMMENT_BUTTON')}
              onClick={this.handleAddComment}
            />
          </div>
        </div>
      </div>
    );
  }
}

Comments.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Comments;
