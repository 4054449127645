import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Icon from '../../icon/components/icon';
import '../styles/stepper.scss';

const Stepper = ({ stepperData, activeStep, showInfoIcon = true }, { t }) => {
  const isActive = (activeStep, index) => {
    return Math.floor(activeStep) >= index;
  };

  const getColumns = () => {
    return '1fr '.repeat(stepperData.length);
  };

  const renderHeaderContent = activeStep => {
    const foundIndex = stepperData.findIndex(item => Math.floor(activeStep) === parseInt(item.stepValue, 10));

    if (foundIndex > -1) {
      return (
        <>
          <p className="f-primary">{`${foundIndex + 1}. ${t(stepperData[foundIndex].name)}`}</p>

          <label>
            {showInfoIcon && <Icon name="info" handleHover={false} size="xs" />}
            {t('STEPPER.STEP_DESCRIPTION', { current: foundIndex + 1, last: stepperData.length })}
          </label>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="stepper">
      <div className="stepper__header">{renderHeaderContent(activeStep)}</div>
      <div className="stepper__steps" style={{ gridTemplateColumns: getColumns() }}>
        {stepperData.map((item, index) => {
          const isStepActive = isActive(activeStep, item.stepValue);

          return (
            <Fragment key={index}>
              <div className={`step ${isStepActive ? 'active' : ''}`} />
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

Stepper.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Stepper;
