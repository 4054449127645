import React from 'react';
import PropTypes from 'prop-types';
import ConfirmModalForm from './confirm-modal-form/confirm-modal-form';

const ConfirmModal = ({ levelName, customConfirmAction, ...restProps }, { t }) => {
  return (
    <div className="component-hierarchy__modal__confirm">
      <p>{t('COMPONENT_HIERARCHY_MODAL.CONFIRM_TYPE_PASSWORD')}</p>
      <p>{t('COMPONENT_HIERARCHY_MODAL.CONFIRM_LEVELS_COMPONENTS_ALL', { levelName })}</p>
      <ConfirmModalForm onSubmit={customConfirmAction} {...restProps} />
    </div>
  );
};

ConfirmModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ConfirmModal;
