import routesConstants from './routes';
import { Api } from '../../core/api';
import { API_BASE_URL } from '../../common/constants';
import { generateQueryParams } from '../helpers/helper';

const createProject = async values => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CREATE_PROJECT}`, values, { needLoader: true });
};
const editProject = async values => {
  return await Api.post(`${API_BASE_URL}${routesConstants.EDIT_PROJECT}`, values, { needLoader: true });
};

const getContacts = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_CONTACTS}${generateQueryParams(params)}`);
};

export default {
  createProject,
  getContacts,
  editProject,
};
