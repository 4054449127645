export const fields = {
  title: 'Title',
  fileName: 'FileName',
  group: 'Group',
  created: 'CreatedAt',
  createdBy: 'CreatedBy',
  id: 'FileID',
  mainId: 'ID',
  url: 'URL',
  status: 'status',
  revision: 'Revision',
  revisionDate: 'RevisionDate',
  createdByID: 'CreatedByUserID',
};

export const filterProps = {
  hasNext: 'HasNext',
  lastSeen: 'LastSeen',
  perPage: 'PerPage',
  searchText: 'SearchText',
  sortByColumn: 'SortByColumn',
  sortDirection: 'SortDirection',
  uploadGroup: 'UploadGroup',
  totalItems: 'TotalNumber',
  groupFilter: 'TypeFilter',
  dateFrom: 'DateFrom',
  dateTo: 'DateTo',
  createdByFilter: 'CreatedByFilter',
};

export const sortingDirection = {
  asc: 'ASC',
  desc: 'DESC',
};

export const tableConfig = [
  {
    title: 'DOCUMENTS.ID',
    key: fields.mainId,
    enableSort: true,
  },
  {
    title: 'DOCUMENTS.NAME',
    key: fields.fileName,
    enableSort: true,
    type: fields.fileName,
    className: 'inline-left',
  },
  {
    title: 'DOCUMENTS.DATE_CREATED',
    key: fields.created,
    enableSort: true,
    type: fields.created,
  },
  {
    key: fields.status,
    type: fields.status,
    className: 'inline-right',
  },
];

export const groupKeys = {
  mechanicalAndElectrical: 'MECHANICAL_AND_TECHNICAL',
  pAndID: 'P_AND_ID',
  other: 'OTHER',
};

export const quickFilter = [
  {
    value: groupKeys.mechanicalAndElectrical,
    field: filterProps.groupFilter,
    colorKey: 'severityGreen',
    label: 'DOCUMENTS_Q_FILTER.MECHANICAL',
    active: true,
  },
  {
    value: groupKeys.pAndID,
    field: filterProps.groupFilter,
    colorKey: 'severityRed',
    label: 'DOCUMENTS_Q_FILTER.P_AND_ID',
    active: true,
  },
  {
    value: groupKeys.other,
    field: filterProps.groupFilter,
    colorKey: 'severityOrange',
    label: 'DOCUMENTS_Q_FILTER.OTHER',
    active: true,
  },
];

export const defaultPagingObj = {
  [filterProps.lastSeen]: 0,
  [filterProps.perPage]: 50,
  [filterProps.totalItems]: 0,
  [filterProps.hasNext]: false,
};
export const defaultSortingFilter = {
  [filterProps.sortByColumn]: fields.mainId,
  [filterProps.sortDirection]: sortingDirection.asc,
};

export const documentSupportedMimeTypes = 'image/jpeg, image/png, application/pdf, image/svg+xml, image/tiff, image/gif';
