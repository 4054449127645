import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../form/components/button';
import '../styles/pagination-navigator.scss';

const PaginationNavigator = (
  { className = '', isLoading = false, label = 'PAGINATION_INFO', currentStart = 1, currentEnd = 20, totalItems = 0, prevButtonProps = {}, nextButtonProps = {} },
  { t }
) => {
  prevButtonProps = {
    text: 'PREVIOUS',
    onClick: () => {},
    disabled: false,
    width: 'sm',
    variant: 'success-outline',
    ...prevButtonProps,
  };
  nextButtonProps = {
    text: 'NEXT',
    onClick: () => {},
    disabled: false,
    width: 'sm',
    variant: 'success-outline',
    ...nextButtonProps,
  };

  return (
    <div className={`pagination-navigator ${isLoading ? 'loading' : ''} ${className}`}>
      {/* Previous Button */}
      <Button
        type="button"
        disabled={prevButtonProps.disabled || isLoading}
        width={prevButtonProps.width}
        variant={nextButtonProps.variant}
        className="load-more__button"
        onClick={prevButtonProps.onClick}
        text={t(prevButtonProps.text)}
        icon="arrow-left"
        iconProps={{ active: true }}
        iconPosition="left"
        keepOriginalText
      />

      {/* Showing Items Information */}
      <label className={`pagination-info`}>{t(label, { currentStart, currentEnd, totalItems })}</label>

      {/* Next Button */}
      <Button
        type="button"
        disabled={nextButtonProps.disabled || isLoading}
        width={nextButtonProps.width}
        variant={nextButtonProps.variant}
        className="load-more__button"
        onClick={nextButtonProps.onClick}
        text={t(nextButtonProps.text)}
        icon="arrow-right"
        iconProps={{ active: true }}
        keepOriginalText
      />
    </div>
  );
};

PaginationNavigator.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default PaginationNavigator;
