import actionTypes from './action-types';

export const setCollapsibleInfo = data => {
  return {
    type: actionTypes.SET_COLLAPSIBLE_INFO,
    data,
  };
};

export const setCollapsibleInfoIsDisplayed = data => {
  return {
    type: actionTypes.SET_CI_IS_DISPLAYED,
    data,
  };
};

export const setCollapsibleInfoIsExpanded = data => {
  return {
    type: actionTypes.SET_CI_IS_EXPANDED,
    data,
  };
};

export const setCollapsibleInfoContent = data => {
  return {
    type: actionTypes.SET_CI_CONTENT,
    data,
  };
};

export const setCollapsibleInfoContentProps = data => {
  return {
    type: actionTypes.SET_CI_CONTENT_PROPS,
    data,
  };
};

export const setCollapsibleInfoTitle = data => {
  return {
    type: actionTypes.SET_CI_TITLE,
    data,
  };
};

export const resetCollapsibleInfo = () => {
  return {
    type: actionTypes.RESET_CI,
  };
};
