import React from 'react';

import SelectUsersTeamsMultiple from './select-users-teams-multiple';
import { isEmpty } from 'lodash';
import Button from '../../form/components/button';
import { participantTypes, userTeamFields } from '../constants/constants';

import '../styles/add-contributors.scss';
import PropTypes from 'prop-types';

const AddContributors = (
  { addContributor, removeContributor, collaboratorLabel, assigneeLabel, collaborators, assignees, addedCollaborators, selectedAssignee, searchContributors, confirmAction, customClassName },
  { t }
) => {
  return (
    <div className={`add-contributors-container ${customClassName ? customClassName : ''}`}>
      <SelectUsersTeamsMultiple
        handleUsersAndTeamsSearch={e => searchContributors(e.target.value, participantTypes.assignee)}
        addAssignee={item => {
          if (!selectedAssignee && item[userTeamFields.id] > 0) {
            addContributor({ ...item, ParticipantType: participantTypes.assignee });
            return;
          }

          if (selectedAssignee) {
            if (item[userTeamFields.id] > 0) {
              removeContributor({ ...selectedAssignee, ParticipantType: participantTypes.assignee }, () => {
                addContributor({ ...item, ParticipantType: participantTypes.assignee });
              });
            } else if (item[userTeamFields.id] === -2) {
              removeContributor({ ...selectedAssignee, ParticipantType: participantTypes.assignee });
            }
          }
        }}
        removeAssignee={item => removeContributor({ ...item, ParticipantType: participantTypes.assignee }, () => searchContributors('', participantTypes.assignee))}
        searchLabelText={assigneeLabel}
        usersAndTeams={assignees}
        addedUsersAndTeams={isEmpty(selectedAssignee) ? [] : [selectedAssignee]}
      />
      <SelectUsersTeamsMultiple
        handleUsersAndTeamsSearch={e => searchContributors(e.target.value, participantTypes.collaborator)}
        addAssignee={item => addContributor({ ...item, ParticipantType: participantTypes.collaborator })}
        removeAssignee={item => removeContributor({ ...item, ParticipantType: participantTypes.collaborator }, () => searchContributors('', participantTypes.collaborator))}
        searchLabelText={collaboratorLabel}
        usersAndTeams={collaborators}
        addedUsersAndTeams={addedCollaborators}
      />
      <div className="add-contributors-container__buttons">
        <Button type="button" variant="success-outline" height="md" width="sm" onClick={confirmAction} text={t('CLOSE')} />
      </div>
    </div>
  );
};

AddContributors.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default AddContributors;
