import { isEmpty, isObject, uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';

import MeasurementPointQuestion from '../../question-components/components/measurement-point-question';
import QuestionField from '../../question-components/components/question-field';
import QuestionOptions from '../../question-components/components/question-options';
import {
  additionalFields,
  defaultOptionsFieldName,
  defaultQuestion,
  defaultQuestionName,
  questionTypes as defaultQuestionTypes,
  defaultSelectKeys,
} from '../../question-components/constants/question-constants';
import RenderIf from '../../render-if/components/render-if';
import { questionsValidator, validateQuestion } from '../validators/validators';
import AdditionalFields from './additional-fields';
import SectionActions from './section-actions';

const EditableFields = ({
  questionTypes,
  hideQuestionNumber,
  sectionId,
  sectionQuestionsName,
  questionValidators,
  handleFieldDelete,
  handleFieldSort,
  handleFieldChange,
  hideAdditionalFields,
  components,
  handleComponentSelected,
  sectionQuestions,
  handleDeleteComponent,
  toggleAdditionalFields,
  handleAddQuestionOption,
  handleUpdateQuestionOption,
  handleDeleteQuestionOption,
  handleManageWorkAreas,
  handleAdd3DPosition,
  isLoading,
  questionModalData,
  handleComponentPicker3D,
  handleComponentPickerPDF,
  handleComponentPickerCH,
  stickActions,
  showQuestionDescription,
  removeFieldArrayFields,
  pushFieldArrayItem,
  toggleUploadModal,
  handleDeleteFiles,
  handleScreenshotFrom3D,
  questionUpdateDebounce,
  questionOptionUpdateDebounce,
  isDisabled,
  hideMandatoryCheckbox,
  isSectionLocked,
  projectDMSCategories,
  fetchQuestionFiles,
  sectionHasOneOrLessQuestions,
  inspectionId,
}) => {
  const onFieldChange = async (value, question, field, index, skipReinitialize) => {
    if (question) {
      const questionField = Object.assign({}, question);
      if (field.name === defaultQuestion.questionType.name) {
        const fieldValue = isObject(value) && value.value ? value[defaultSelectKeys.valueKey] : value;

        // this is due to difference in what API returns and expects in update
        if (field.alternativeName) {
          questionField[field.alternativeName] = fieldValue;
        }

        questionField[field.name] = fieldValue;

        if (fieldValue !== defaultQuestionTypes.NO_ANSWER.valueId) {
          questionField[defaultQuestion.isMandatory.name] = false;
        }

        // if the "allowMultipleAnswers" is set to "true" and question type is changed, set it now to "false"
        if (questionField[defaultQuestion.allowMultipleAnswers.name] && fieldValue !== defaultQuestionTypes.MULTI_ANSWER.valueId) {
          questionField[defaultQuestion.allowMultipleAnswers.name] = false;
          questionField.QuestionOptions = null;
        }

        // if the question type is not anymore "MULTI_ANSWER", remove question options from form fields
        if (fieldValue !== defaultQuestionTypes.MULTI_ANSWER.valueId) {
          removeFieldArrayFields(`${sectionQuestionsName}[${index}].QuestionOptions`);
        }

        // if it's a change to "MULTI_ANSWER", add function to question object to push elements in the fields array
        if (fieldValue === defaultQuestionTypes.MULTI_ANSWER.valueId) {
          questionField.pushFieldArrayItem = pushFieldArrayItem;
        }

        // if the "isMandatory" is set to "true" and question type is changed to "NO_ANSWER", set it now to "false"
        if (questionField[defaultQuestion.isMandatory.name] && fieldValue === defaultQuestionTypes.NO_ANSWER.valueId) {
          questionField[defaultQuestion.isMandatory.name] = false;
        }
      } else if (field.name === defaultQuestion.isMandatory.name) {
        questionField[field.name] = value;
        if (!value) {
          // if isMandatory is false automatically uncheck the mandatory file upload checkbox
          questionField[defaultQuestion.isMandatoryFileUpload.name] = false;
        }
      } else if (field.name === defaultQuestion.isMandatoryFileUpload.name) {
        questionField[field.name] = value;
        if (value) {
          // if isMandatoryFileUpload is true automatically check the mandatory checkbox
          questionField[defaultQuestion.isMandatory.name] = true;
        }
      } else {
        questionField[field.name] = value;
      }

      const errors = await validateQuestion(questionField, { sectionQuestionsName, questionValidators, question, isSingleCheck: true });

      if (isEmpty(errors) && typeof handleFieldChange === 'function') {
        if (!questionField[defaultQuestion.questionType.alternativeName] || isObject(questionField[defaultQuestion.questionType.alternativeName])) {
          questionField[defaultQuestion.questionType.alternativeName] = questionField[defaultQuestion.questionType.name][defaultSelectKeys.valueKey]
            ? questionField[defaultQuestion.questionType.name][defaultSelectKeys.valueKey]
            : questionField[defaultQuestion.questionType.name];
        }

        handleFieldChange(questionField, Object.assign([], sectionQuestions), index, field, skipReinitialize);
      } else if (questionUpdateDebounce && typeof questionUpdateDebounce.cancel === 'function') {
        questionUpdateDebounce.cancel();
      }
    }
  };

  const onFieldDelete = index => {
    const questions = Object.assign([], sectionQuestions);
    // you can't delete the last field
    if (sectionHasOneOrLessQuestions && !isSectionLocked) {
      return;
    }

    const question = questions[index];

    if (question && typeof handleFieldDelete === 'function') {
      handleFieldDelete(question.ID, index, question[defaultQuestion.name.name]);
    }
  };

  const showMultipleAnswersField = index => {
    const questions = Object.assign([], sectionQuestions);
    const question = questions[index];
    let value = question[defaultQuestion.questionType.name];

    if (isObject(value)) {
      value = value[defaultSelectKeys.valueKey];
    }

    return value === defaultQuestionTypes.MULTI_ANSWER.valueId;
  };

  const isMandatoryFieldDisabled = index => {
    const questions = Object.assign([], sectionQuestions);
    const question = questions[index];
    let value = question[defaultQuestion.questionType.name];

    if (isObject(value)) {
      value = value[defaultSelectKeys.valueKey];
    }

    return value === defaultQuestionTypes.NO_ANSWER.valueId || (hideMandatoryCheckbox && !isSectionLocked);
  };

  const isMandatoryFileUploadDisabled = () => {
    return hideMandatoryCheckbox && !isSectionLocked;
  };

  const getQuestion = index => {
    return sectionQuestions && sectionQuestions[index] ? sectionQuestions[index] : {};
  };

  const getQuestionOptions = index => {
    let options = sectionQuestions && sectionQuestions[index]?.QuestionOptions ? sectionQuestions[index].QuestionOptions : [];
    options = uniqBy(options, 'ID');

    return options;
  };

  const { description } = additionalFields;

  return (
    <div className="editable-fields-container">
      <form noValidate>
        {sectionQuestions &&
          sectionQuestions.length > 0 &&
          sectionQuestions.map((question, index) => {
            const isMeasurementPointQuestion = question?.QuestionType === defaultQuestionTypes.MEASUREMENT_POINT.valueId;
            const isNoAnswerQuestion = question?.QuestionType === defaultQuestionTypes.NO_ANSWER.valueId;
            return (
              <div key={`section-question-field-${question.ID}`}>
                <div className="editable-fields-container__wrapper">
                  <RenderIf if={!hideQuestionNumber}>
                    <p className="f-primary editable-fields-container__wrapper__question-number">{`${index + 1}.`}</p>
                  </RenderIf>
                  <RenderIf if={!isMeasurementPointQuestion}>
                    <div className="editable-fields-container__main-inputs">
                      <QuestionField
                        name={`${defaultQuestionName}-${defaultQuestion.name.name}-${question.ID}`}
                        label={defaultQuestion.name.label}
                        placeholder={defaultQuestion.name.placeholder}
                        type={defaultQuestionTypes.TEXT.value}
                        id={`${defaultQuestion.name.name}-${sectionId}-${index}`}
                        sectionId={sectionId}
                        onChange={(_e, value) => onFieldChange(value, question, defaultQuestion.name, index, true)}
                        withRightPadding
                        fullWidth
                        disabled={isDisabled}
                      />
                    </div>
                    <RenderIf if={questionTypes && questionTypes.length}>
                      <div className="editable-fields-container__main-inputs">
                        <QuestionField
                          name={`${defaultQuestionName}-${defaultQuestion.questionType.name}-${question.ID}`}
                          label={defaultQuestion.questionType.label}
                          id={`${defaultQuestion.questionType.name}-${sectionId}-${index}`}
                          sectionId={sectionId}
                          type={defaultQuestionTypes.SELECT.value}
                          dataKey={defaultSelectKeys.valueKey}
                          textField={defaultSelectKeys.textKey}
                          data={questionTypes}
                          style={{ minWidth: '200px' }}
                          onChange={(_e, value) => onFieldChange(value, question, defaultQuestion.questionType, index)}
                          withLeftPadding
                          disabled={isDisabled}
                        />
                      </div>
                    </RenderIf>
                  </RenderIf>
                  <RenderIf if={isMeasurementPointQuestion}>
                    <MeasurementPointQuestion question={question} inspectionId={inspectionId} />
                  </RenderIf>
                  <SectionActions
                    currentIndex={index}
                    sortDownDisabled={!index || isDisabled}
                    sortUpDisabled={index === sectionQuestions.length - 1 || isDisabled}
                    deleteDisabled={(sectionHasOneOrLessQuestions && !isSectionLocked) || isDisabled}
                    handleDeleteSection={() => onFieldDelete(index)}
                    handleSort={(oldIndex, newIndex) => handleFieldSort(oldIndex, newIndex, sectionQuestions[index])}
                    isLoading={isLoading}
                    stickActions={stickActions}
                    className="question-actions-sticked"
                    areQuestionsActions
                  />
                </div>
                <div className="editable-fields-container__checkbox-wrapper">
                  <RenderIf if={!isMandatoryFieldDisabled(index)}>
                    <QuestionField
                      name={`${defaultQuestionName}-${defaultQuestion.isMandatory.name}-${question.ID}`}
                      checkboxText={defaultQuestion.isMandatory.label}
                      id={`${defaultQuestion.isMandatory.name}-${sectionId}-${index}`}
                      sectionId={sectionId}
                      type={defaultQuestionTypes.CHECKBOX.value}
                      onChange={(_e, value) => onFieldChange(value, question, defaultQuestion.isMandatory, index)}
                      disabled={isMandatoryFieldDisabled(index) || isDisabled}
                      withRightPadding
                    />
                  </RenderIf>
                  <RenderIf if={!isNoAnswerQuestion && !isMandatoryFileUploadDisabled()}>
                    <QuestionField
                      name={`${defaultQuestionName}-${defaultQuestion.isMandatoryFileUpload.name}-${question.ID}`}
                      checkboxText={defaultQuestion.isMandatoryFileUpload.label}
                      id={`${defaultQuestion.isMandatoryFileUpload.name}-${sectionId}-${index}`}
                      sectionId={sectionId}
                      type={defaultQuestionTypes.CHECKBOX.value}
                      onChange={(_e, value) => onFieldChange(value, question, defaultQuestion.isMandatoryFileUpload, index)}
                      disabled={isMandatoryFileUploadDisabled() || isDisabled}
                      withRightPadding
                    />
                  </RenderIf>
                  <RenderIf if={showMultipleAnswersField(index)}>
                    <QuestionField
                      name={`${defaultQuestionName}-${defaultQuestion.allowMultipleAnswers.name}-${question.ID}`}
                      checkboxText={defaultQuestion.allowMultipleAnswers.label}
                      id={`${defaultQuestion.allowMultipleAnswers.name}-${sectionId}-${index}`}
                      sectionId={sectionId}
                      type={defaultQuestionTypes.CHECKBOX.value}
                      onChange={(_e, value) => onFieldChange(value, question, defaultQuestion.allowMultipleAnswers, index)}
                      disabled={isDisabled}
                      withLeftPadding
                    />
                  </RenderIf>
                </div>
                {sectionQuestions && sectionQuestions[index]?.QuestionOptions && sectionQuestions[index]?.QuestionOptions.length && (
                  <QuestionOptions
                    fieldsName={defaultOptionsFieldName}
                    questionId={sectionQuestions && sectionQuestions[index]?.ID}
                    handleAddQuestionOption={handleAddQuestionOption}
                    handleUpdateQuestionOption={handleUpdateQuestionOption}
                    handleDeleteQuestionOption={handleDeleteQuestionOption}
                    options={getQuestionOptions(index)}
                    questionOptionUpdateDebounce={questionOptionUpdateDebounce}
                    disabled={isDisabled}
                  />
                )}
                <RenderIf if={hideAdditionalFields && showQuestionDescription}>
                  <div className="editable-fields-container__description-field">
                    <QuestionField
                      name={`${defaultQuestionName}-${description.name}-${question.ID}`}
                      label={description.label}
                      placeholder={description.placeholder}
                      type={description.type}
                      id={`${description.name}-${sectionId}-${index}`}
                      sectionId={sectionId}
                      onChange={(_e, value) => onFieldChange(value, question, description, index, true)}
                      maxChars={description.maxChars}
                      fullWidth
                      disabled={isDisabled}
                    />
                  </div>
                </RenderIf>
                <AdditionalFields
                  hideAdditionalFields={hideAdditionalFields || isMeasurementPointQuestion}
                  questionIndex={index}
                  sectionId={sectionId}
                  onFieldChange={onFieldChange}
                  components={components}
                  question={getQuestion(index)}
                  questions={sectionQuestions || []}
                  handleComponentSelected={handleComponentSelected}
                  handleDeleteComponent={handleDeleteComponent}
                  isExpanded={getQuestion(index).areAdditionalFieldsExpanded}
                  toggleAdditionalFields={toggleAdditionalFields}
                  handleManageWorkAreas={handleManageWorkAreas}
                  handleAdd3DPosition={handleAdd3DPosition}
                  handleScreenshotFrom3D={handleScreenshotFrom3D}
                  questionModalData={questionModalData}
                  handleComponentPicker3D={handleComponentPicker3D}
                  handleComponentPickerPDF={handleComponentPickerPDF}
                  handleComponentPickerCH={handleComponentPickerCH}
                  toggleUploadModal={toggleUploadModal}
                  handleDeleteFiles={handleDeleteFiles}
                  isDisabled={isDisabled}
                  projectDMSCategories={projectDMSCategories}
                  fetchQuestionFiles={fetchQuestionFiles}
                />
              </div>
            );
          })}
      </form>
    </div>
  );
};

EditableFields.propTypes = {
  questionTypes: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number,
      ChecklistItemType: PropTypes.string,
      ChecklistItemTypeName: PropTypes.string,
    })
  ),
  hideQuestionNumber: PropTypes.bool,
  member: PropTypes.string,
  ID: PropTypes.number,
  sectionId: PropTypes.number,
  sectionQuestionsName: PropTypes.string,
  questionValidators: PropTypes.object,
  handleFieldDelete: PropTypes.func.isRequired,
  handleFieldSort: PropTypes.func.isRequired,
  hideAdditionalFields: PropTypes.bool,
  hideMandatoryCheckbox: PropTypes.bool,
  components: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
    })
  ),
  handleComponentSelected: PropTypes.func,
  sectionQuestions: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number,
      Name: PropTypes.string.isRequired,
      QuestionType: PropTypes.number,
      QuestionOrder: PropTypes.number,
      IsMandatory: PropTypes.bool,
      AllowMultipleAnswers: PropTypes.bool,
      EstimatedTime: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      CreatedAt: PropTypes.number,
    })
  ),
  handleDeleteComponent: PropTypes.func,
  toggleAdditionalFields: PropTypes.func,
  handleAddQuestionOption: PropTypes.func,
  handleUpdateQuestionOption: PropTypes.func,
  handleDeleteQuestionOption: PropTypes.func,
  isLoading: PropTypes.bool,
  stickActions: PropTypes.bool,
  showQuestionDescription: PropTypes.bool,
  removeFieldArrayFields: PropTypes.func,
  pushFieldArrayItem: PropTypes.func,
  toggleUploadModal: PropTypes.func,
  handleDeleteFiles: PropTypes.func,
  isDisabled: PropTypes.bool,
  isSectionLocked: PropTypes.bool,
};

EditableFields.defaultProps = {
  hideQuestionNumber: false,
};

export default reduxForm({
  validate: questionsValidator,
  enableReinitialize: true,
  touchOnChange: true,
  destroyOnUnmount: true,
})(EditableFields);
