import { isEmpty } from 'lodash';
import { workAreaConstants } from '../../../constants/work-order-constants';

export const validate = values => {
  const errors = {};

  if (!values || !values[workAreaConstants.fields.name]) {
    errors[workAreaConstants.fields.name] = 'MANDATORY_FIELD';
  } else if (values[workAreaConstants.fields.name].length < 3) {
    errors[workAreaConstants.fields.name] = 'WORK_AREA_FORM.ERROR.NAME_LENGTH';
  } else if (values[workAreaConstants.fields.name].length > 50) {
    errors[workAreaConstants.fields.name] = 'WORK_AREA_FORM.ERROR.NAME_MAX_LENGTH';
  }

  if (!values || !values[workAreaConstants.fields.color]) {
    errors[workAreaConstants.fields.color] = 'MANDATORY_FIELD';
  }

  if (!values || !values.CameraPosition || isEmpty(values.CameraPosition.coordinates)) {
    errors[workAreaConstants.fields.camPos] = 'WORK_AREA_FORM.ERROR.CAMERA_GEOMETRY_REQUIRED';
  }

  return errors;
};
