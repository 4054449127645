import React, { useMemo } from 'react';
// styles
import '../../../styles/group-form.scss';
// prop types
import PropTypes from 'prop-types';
// constants
import { FORMS } from '../../../../../../../common/constants';
import { fields } from '../../../constants/constants';
//package imports
import { reduxForm, Field, getFormSyncErrors, isDirty } from 'redux-form';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
//Components
import CustomInput from '../../../../../../../common/form/components/input';
import Button from '../../../../../../../common/form/components/button';
//validation
import { validateGroupForm as validate } from '../../../validators/group-form/group-from-validation';

const GroupForm = (props, { t }) => {
  const { handleSubmit, formErrors, isEdit, isDirty } = props;

  const isSubmitButtonDisabled = useMemo(() => !isEmpty(formErrors), [formErrors]);

  return (
    <form noValidate className="group-form" onSubmit={handleSubmit}>
      <Field id={fields.name} name={fields.name} component={CustomInput} size="lg" isRequired placeholder={t('READINGS_AND_GAUGES.GROUP_NAME')} label={t('READINGS_AND_GAUGES.GROUP_NAME')} />
      <Button text={t(isEdit ? 'SAVE' : 'READINGS_AND_GAUGES.CREATE_GROUP')} height="md" width="lg" isCapitalized disabled={isSubmitButtonDisabled || (isEdit && !isDirty)} />
    </form>
  );
};

GroupForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  formErrors: getFormSyncErrors(FORMS.groupForm)(state),
  isDirty: isDirty(FORMS.groupForm)(state),
});

export default connect(mapStateToProps, null)(reduxForm({ form: FORMS.groupForm, validate, touchOnChange: true, destroyOnUnmount: true, enableReinitialize: true })(GroupForm));
