import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';

import AddButton from '../../../../common/add-button/components/add-button';
import Button from '../../../../common/form/components/button';

import Icon from '../../../../common/icon/components/icon';
import { modelTypes, zoneAreaConstants } from '../../constants/explosive-zones-constants';
import ZoneAreaForm from './zone-area-types/zone-area-form';

const AddZoneArea = ({ viewer, canCreate, handleSubmit, disabled, nameField, showOnly2D, isAddingLocation, setIsAddingLocation, addZoneAreaStart, isAdding, setIsAdding, initialValues }, { t }) => {
  const [selectedType, setSelectedType] = useState(modelTypes._2D);

  const handleFormSubmit = (values, dropdownAction) => {
    handleSubmit(values, selectedType, dropdownAction);
  };

  const zoneAreaProps = {
    zoneArea: {
      [zoneAreaConstants.fields.modelType]: selectedType,
      ...initialValues,
    },
    setSelectedType,
    handleSubmit: handleFormSubmit,
    isAdding: true,
    isAddingLocation,
    disabled,
    nameField,
    showOnly2D,
    viewer,
  };

  const renderBottomContent = () => {
    if (isAddingLocation) {
      return (
        <div className="adding-location">
          <div className="info-wrapper">
            <Icon name="info" handleHover={false} className="adding-location__icon" />
            <p className="f-secondary-dark adding-location__text">{t('ADD_ZONE_AREA.ADDING_LOCATION_DESCRIPTION')}</p>
          </div>
          <div className="action-buttons">
            <Button type="button" width="lg" height="md" disabled text={t('ADD_ZONE_AREA.FORM_MARK_ON_3D')} />
            <Button
              type="button"
              width="lg"
              height="md"
              onClick={() => {
                viewer.dispatchEvent({
                  type: 'cancel_all_insertions',
                });
                setIsAddingLocation(false);
              }}
              variant="gray-outline"
              text={t('CANCEL')}
            />
          </div>
        </div>
      );
    }
    if (canCreate) {
      return (
        <div>
          {isAdding ? (
            <Button type="button" className="work-areas-form__cancel-btn" onClick={() => setIsAdding(false)} variant="gray-outline" width="lg" height="md" text={t('ADD_ZONE_AREA.FORM_CANCEL')} />
          ) : (
            <AddButton onClick={addZoneAreaStart} className="work-areas-form__add-btn">
              {'ADD_ZONE_AREA.FORM_ADD_ZONE_AREA'}
            </AddButton>
          )}
        </div>
      );
    }
  };

  return (
    <div className={`defect-form work-areas-form ${isAdding ? 'work-areas-form--active  v-container-padding' : ''}  h-container-padding`}>
      {isAdding && (
        <Fragment>
          <ZoneAreaForm {...zoneAreaProps} />
        </Fragment>
      )}
      {renderBottomContent()}
    </div>
  );
};

AddZoneArea.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default AddZoneArea;
