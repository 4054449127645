import { debounce, find, isEmpty, isString, keys, map, reduce } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { change, reduxForm } from 'redux-form';

import AccessRenderer from '../../../../common/access-renderer/components/access-renderer';
import Modal from '../../../../common/modal/components/modal';
import AddAreaModal from './common/add-area-modal';
import AddAreaGroupModal from './common/manage-area-group-modal';
import ModuleHeader from './common/module-header';
import SearchText from './common/search-text';
import VisibilityBox from './common/visibility-box';
import ShapeRenderer from './shape-renderer';

import Icon from '../../../../common/icon/components/icon';
import ItemsTableRenderer from '../../../../common/items-renderer/components/items-table-renderer';
import AreasFilter from './filters/areas-filter';

import { deselectAllAreas, selectAllAreas, toggleArea } from '../../actions/action-creators';
import { getAreaGroupsDropdown, updateArea } from '../../actions/area-actions';

import { FORMS } from '../../../../common/constants';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../common/permissions-constants';
import { areaGroupConstants, defaultArea, defaultPagingObj, defaultSortingFilter, filterParams, formConstants, sortingDirection, tableConfig } from '../../constants/areas-constants';
import { clipTaskOptions } from '../../constants/inspection-settings';

import { FEATURES } from '../../../../common/constants';
import Helpers from '../../../../common/helpers';
import { routes } from '../../../../common/routes-constants';

import '../../styles/areas-list.scss';

class Areas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalData: {
        isOpen: false,
      },
      advancedFilter: {},
      pagingObject: defaultPagingObj,
      sortingFilter: defaultSortingFilter,
    };

    this.searchChangeDebounced = debounce(this.getAreas, 300);
  }

  componentDidUpdate = prevProps => {
    const { searchText } = this.props;
    if (isString(searchText) && prevProps.searchText !== searchText) {
      this.setState({
        pagingObject: defaultPagingObj,
      });
      this.searchChangeDebounced();
    }
  };

  componentDidMount = () => {
    const { queryItem } = this.props;
    let params = {};

    if (queryItem) {
      params = {
        IncludedIDs: [queryItem],
      };
    } else {
      this.setDefaultClipTask();
    }

    this.getAreas(params);
  };

  getAreas = (params, loadMore = false) => {
    const { getAreas, searchText, setClusteringFilters } = this.props;
    let { advancedFilter, pagingObject, sortingFilter } = this.state;

    //normalize advanced filter
    const newAdvancedFilter = reduce(
      advancedFilter,
      (obj, it, key) => {
        if ([filterParams.createdByFilter].indexOf(key) > -1) {
          obj[key] = map(advancedFilter[key], ({ ID, UserID }) => ID || UserID);
        } else if ([filterParams.groupFilter].indexOf(key) > -1) {
          obj[key] = map(advancedFilter[key], ({ Name }) => Name).toString();
        } else if ([filterParams.dateFrom].indexOf(key) > -1) {
          obj[key] = (advancedFilter[key] && Helpers.getUnixDate(new Date(advancedFilter[key]).getTime())) || null;
        } else if ([filterParams.dateTo].indexOf(key) > -1) {
          // sets end/to dates to end of day 23:59:59 to include that day in filter results
          obj[key] = (advancedFilter[key] && Helpers.getUnixDate(new Date(advancedFilter[key]).setHours(23, 59, 59))) || null;
        }
        return obj;
      },
      {}
    );

    setClusteringFilters({ ...newAdvancedFilter });

    getAreas(searchText, { ...newAdvancedFilter, ...pagingObject, ...sortingFilter, ...params }, loadMore, (incomingFilter, c) => {
      this.setState({ pagingObject: { ...pagingObject, [filterParams.totalItems]: incomingFilter[filterParams.totalItems], [filterParams.hasNext]: incomingFilter[filterParams.hasNext] } });
    });
  };

  goToHistoryPage = queryParams => {
    const path = `${routes.protectedRoutes.modulesHistory.fullPath}${queryParams}`;
    Helpers.goTo(path);
  };

  setDefaultClipTask = () => {
    const { viewer } = this.props;
    const { Potree } = window;
    if (viewer && viewer.scene.pointclouds[0]) {
      viewer.setClipTask(Potree.ClipTask[clipTaskOptions[0].value]);
    }
  };

  getCurrentAreasOn3D = selectedArea => {
    const { elementsClustered } = this.props;

    let areas3D = [];
    if (!isEmpty(selectedArea)) {
      const item = elementsClustered.find(el => el[formConstants.fields.id] === selectedArea[formConstants.fields.id]);
      if (!item || isEmpty(item.Geometry) || isEmpty(item.Geometry.coordinates)) {
        areas3D = [];
      } else {
        areas3D = [item];
      }
    } else {
      areas3D = [...elementsClustered];
    }

    return areas3D;
  };

  openAddAreaModal = () => {
    const { t } = this.context;
    const { setModalState, inspectionDetails, getCameraPosition, addArea, getAreaGroupsDropdown } = this.props;

    const closeAction = () => setModalState({ isOpen: false });

    const modalData = {
      isOpen: true,
      type: 'none',
      title: t('ADD_AREA.TITLE'),
      CustomContent: () => (
        <AddAreaModal
          onSubmit={values => {
            addArea(
              {
                ...defaultArea,
                ...values,
                [formConstants.fields.areaGroupId]: values[formConstants.fields.areaGroupId].ID,
              },
              getCameraPosition(),
              closeAction
            );
          }}
          getAreaGroupsDropdown={text => getAreaGroupsDropdown(inspectionDetails.InspectionID, text)}
          getCameraPosition={getCameraPosition}
          closeAction={closeAction}
        />
      ),
      closeAction,
    };

    setModalState(modalData);
  };

  openAddGroupModal = () => {
    const { t } = this.context;
    const { setModalState, getCameraPosition, createAreaGroup, inspectionDetails, getAreaGroupsDropdown, updateAreaGroupName, deleteAreaGroup } = this.props;

    const closeAction = () => setModalState({ isOpen: false });

    getAreaGroupsDropdown(inspectionDetails.InspectionID, '', () => {
      const modalData = {
        isOpen: true,
        type: 'ok',
        title: t('ADD_GROUP.TITLE'),
        customClassName: 'modal-large',
        CustomContent: () => (
          <AddAreaGroupModal
            onSubmit={({ [areaGroupConstants.fields.name]: name }, b, { reset }, d) => {
              createAreaGroup(name, reset);
            }}
            getCameraPosition={getCameraPosition}
            deleteAreaGroup={deleteAreaGroup}
            closeAction={closeAction}
            updateAreaGroup={updateAreaGroupName}
            defaultGroupId={inspectionDetails.DefaultAreaGroup}
          />
        ),
        closeAction,
        confirmAction: closeAction,
      };

      setModalState(modalData);
    });
  };

  loadMore = () => {
    const { pagingObject } = this.state;
    const { elements } = this.props;
    this.setState({
      pagingObject: {
        ...pagingObject,
        [filterParams.lastSeen]: elements.length || 0,
      },
    });
    this.searchChangeDebounced(null, true);
  };

  onColumnSort = column => {
    const { sortingFilter } = this.state;

    this.setState({
      sortingFilter: {
        [filterParams.sortByColumn]: column,
        [filterParams.sortDirection]: sortingFilter[filterParams.sortDirection] === sortingDirection.asc ? sortingDirection.desc : sortingDirection.asc,
      },
      pagingObject: defaultPagingObj,
    });

    this.searchChangeDebounced();
  };

  openAdvancedFilter = () => {
    const { t } = this.context;
    const { setModalState } = this.props;
    const { advancedFilter } = this.state;
    const closeAction = () => setModalState({ isOpen: false });

    const modalData = {
      isOpen: true,
      type: 'none',
      title: t('ADVANCED_FILTERS.TITLE'),
      CustomContent: () => (
        <AreasFilter
          submitForm={values => {
            this.setState({
              advancedFilter: values,
              pagingObject: defaultPagingObj,
            });
            closeAction();
            this.searchChangeDebounced();
          }}
          resetFilter={() => {
            this.setState({
              advancedFilter: {},
              pagingObject: defaultPagingObj,
            });
            closeAction();
            this.searchChangeDebounced();
          }}
          initialValues={advancedFilter}
          closeAction={closeAction}
        />
      ),
      customClassName: 'modal-large defects-filter',
      closeAction,
    };
    setModalState(modalData);
  };

  render() {
    const { t } = this.context;
    const { modalData, pagingObject, sortingFilter, advancedFilter } = this.state;
    const {
      elements,
      queryItem,
      selectAll,
      deselectAll,
      updateArea,
      toggleArea,
      viewer,
      changeField,
      scrollToElement,
      areasLoading,
      handleItemClick,
      title,
      backAction,
      moduleActionsMenu,
      icon,
      location,
    } = this.props;

    const selectedArea = find(elements, item => item.ID === queryItem);
    const areas3D = this.getCurrentAreasOn3D(selectedArea);

    return (
      <AccessRenderer
        visibleFor={PERMISSIONS[PERMISSION_TYPES.areas].edit.name}
        id={selectedArea && selectedArea[formConstants.fields.createByUserID]}
        ownerRequiredPermission={PERMISSIONS[PERMISSION_TYPES.areas].create.name}
      >
        {({ hasAccess }) => (
          <ShapeRenderer
            deselectAll={deselectAll}
            selectAll={() => {
              handleItemClick();
              selectAll();
            }}
            toggleElement={toggleArea}
            deselectAllTemp={() => null}
            selectAllTemp={() => null}
            toggleElementTemp={() => null}
            updateElementGeometry={element => {
              if (!hasAccess) {
                return;
              }
              if (isEmpty(selectedArea)) {
                updateArea(element);
              } else {
                changeField(formConstants.fields.geometry, element[formConstants.fields.geometry]);
                changeField(formConstants.fields.modelDetails, element[formConstants.fields.modelDetails]);
              }
            }}
            selectElement={(data, autoScroll) => {
              if (autoScroll) {
                scrollToElement(data);
              }
              handleItemClick(data[formConstants.fields.id]);
            }}
            disableMove={!hasAccess}
            elements={areas3D}
            selectedDefect={selectedArea}
            viewer={viewer}
          >
            {({ elementClickHandler, elementShowHandler, elementHideHandler, selectAllHandler, deselectAllHandler }) => (
              <div className="areas-list">
                <ModuleHeader
                  {...{
                    icon,
                    title,
                    backAction,
                    actionsMenu: [
                      ...moduleActionsMenu,
                      ...(FEATURES.modulesHistory?.visible
                        ? [
                            {
                              title: 'INSPECTION_FULLSCREEN.VIEW_HISTORY',
                              action: () => this.goToHistoryPage(location?.search),
                            },
                          ]
                        : []),
                      {
                        title: 'AREAS.MANAGE_GROUP',
                        action: this.openAddGroupModal,
                        access: {
                          visibleFor: PERMISSIONS[PERMISSION_TYPES.areas].create.name,
                        },
                      },
                      {
                        title: 'AREAS.ADD_AREA',
                        action: this.openAddAreaModal,
                        access: {
                          visibleFor: PERMISSIONS[PERMISSION_TYPES.areas].create.name,
                        },
                      },
                    ],
                  }}
                />
                <div className="advanced-filter">
                  <SearchText />
                  <p className="f-secondary-green noselect " onClick={this.openAdvancedFilter}>
                    {t('ADVANCED_FILTER', { active: keys(advancedFilter).length })}
                  </p>
                </div>

                <VisibilityBox
                  {...{
                    selectAllHandler,
                    deselectAllHandler,
                  }}
                />

                <ItemsTableRenderer
                  isLoading={areasLoading}
                  tableConfig={tableConfig}
                  translationModule={t}
                  data={map(elements, item => ({ ...item, selected: item[formConstants.fields.id] === queryItem ? true : false }))}
                  onRowClick={elementClickHandler}
                  onColumnSort={this.onColumnSort}
                  sortingObj={sortingFilter}
                  loadMore={{
                    visible: pagingObject[filterParams.hasNext],
                    disabled: areasLoading,
                    onClick: this.loadMore,
                  }}
                  // sortingObj={sortingFilter}
                  formatCell={(value, type, index, item) => {
                    if (type === formConstants.fields.status) {
                      return (
                        <>
                          {!item.Geometry ||
                            isEmpty(item.Geometry) ||
                            (isEmpty(item.Geometry.coordinates) && (
                              <div className="missing-pin">
                                <Icon name="warning" handleHover={false} size="md" warning title={t('ITEMS_LIST.WARNING_ICON_TEXT')} />
                              </div>
                            ))}

                          <Icon
                            name="eye"
                            active={item.visible}
                            size="xs"
                            onClick={item.visible ? e => elementHideHandler(e, item) : e => elementShowHandler(e, item)}
                            title={t('ITEMS_LIST.VISIBILTY_ICON')}
                          />
                        </>
                      );
                    }
                    return value;
                  }}
                  emptyStateText={'INSPECTION_AREAS.EMPTY_STATE'}
                  // sortByColumnProp={filterFields.sortByColumn}
                  // onColumnSort={this.onColumnSort}
                />
                {pagingObject[filterParams.totalItems] > 0 && (
                  <p className="f-secondary-dark total">
                    {t('SHOWING_TOTAL_ITEMS', {
                      items: elements?.length || 0,
                      totalItems: pagingObject[filterParams.totalItems],
                    })}
                  </p>
                )}

                <Modal {...modalData} />
              </div>
            )}
          </ShapeRenderer>
        )}
      </AccessRenderer>
    );
  }
}

Areas.contextTypes = {
  t: PropTypes.func.isRequired,
};

Areas = reduxForm({
  form: FORMS.inspectionSettings,
  enableReinitialize: true,
})(Areas);

const mapDispatchToProps = dispatch => ({
  updateArea: data => dispatch(updateArea(data)),
  deselectAll: () => dispatch(deselectAllAreas()),
  selectAll: () => dispatch(selectAllAreas()),
  toggleArea: id => dispatch(toggleArea(id)),
  changeField: (fieldName, value) => dispatch(change(FORMS.areasForm, fieldName, value)),
  getAreaGroupsDropdown: (inspectionId, searchText, callback) => dispatch(getAreaGroupsDropdown(inspectionId, searchText, callback)),
});

const mapStateToProps = state => ({
  initialValues: {
    ...state.inspectionReducer.inspectionSettings,
  },
  viewer: state.potreeReducer.viewerInstance,
  inspectionDetails: state.inspectionReducer.inspectionDetails,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Areas));
