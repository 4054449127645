import PropTypes from 'prop-types';
import React from 'react';

import { ALARMS, formConstants as alarmFormConstants } from '../../constants/alarm-constants';

import { get } from 'lodash';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import Toggle from '../../../../../../common/form/components/toggle-field';
import { fetchMeasurementPointUsersAndTeams } from '../../actions/measurement-point-actions';
import '../../styles/alarms-form-fields.scss';
import AlarmFormField from './alarm-form-field';

const AlarmsFormFields = ({ fields, formValues, searchUsersAndTeams, alarmDescriptors, defaultComponent, unit }, { t }) => {
  const renderAlarms = () => {
    return fields.map((fieldName, index, _fields) => {
      const alarmValues = get(formValues, fieldName);
      const isEnabled = alarmValues?.[alarmFormConstants.fields.enabled.name];
      const alarmConstants = ALARMS.find(a => a.id === alarmValues?.[alarmFormConstants.fields.name.name]);

      return (
        <div className="alarms-form-fields__alarm-wrapper" key={index}>
          <div className="alarm-content">
            <div className="alarm-content__header">
              <p className="f-primary">{t(`${alarmConstants?.displayName}`)}</p>
              <Field id={`${fieldName}.${alarmFormConstants.fields.enabled.id}`} name={`${fieldName}.${alarmFormConstants.fields.enabled.name}`} component={Toggle} inline={false} disabled={false} />
            </div>
            {isEnabled && (
              <AlarmFormField
                fieldName={fieldName}
                formValues={formValues}
                searchUsersAndTeams={searchUsersAndTeams}
                alarmDescriptors={alarmDescriptors}
                defaultComponent={defaultComponent}
                unit={unit}
              />
            )}{' '}
          </div>
        </div>
      );
    });
  };

  return <div className="alarms-form-fields">{renderAlarms()}</div>;
};

AlarmsFormFields.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  searchUsersAndTeams: (searchText, callback, loadingCallback) => dispatch(fetchMeasurementPointUsersAndTeams(searchText, callback, loadingCallback)),
});

export default connect(null, mapDispatchToProps)(AlarmsFormFields);
