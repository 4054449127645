import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { isEmpty } from 'lodash';

import Button from '../../../common/form/components/button';
import CustomInput from '../../../common/form/components/input';
import CustomDatePicker from '../../../common/form/components/date-picker';

import { FORMS } from '../../../common/constants';
import { fileFields, formConstants, steps, userFields } from '../constants/constants';
import { validate } from '../validators/contractor-form-validator';
import Icon from '../../../common/icon/components/icon';
import CustomSelect from '../../../common/form/components/select';

const CreateContractorForm = (
  { handleSubmit, activeStep, lastStep, stepAction, invalid, files, isEditMode, openFilesModalData, deleteContractorFile, users, change, isDisabled, isDateClearable },
  { t }
) => {
  const handleOpenFile = url => {
    if (url) {
      window.open(url, '_blank').focus();
    }
  };

  const regularUsers = useMemo(() => {
    return users && users.length > 0 ? users.filter(user => user[userFields.notContractor]) : [];
  }, [users]);

  const getButtonText = () => {
    if (activeStep === steps.firstStep && !isEditMode) {
      return 'CONTRACTOR_FORM.CREATE';
    }

    if (isDisabled) {
      return activeStep === steps.firstStep ? 'NEXT' : 'OK';
    }

    return 'CONTRACTOR_FORM.SAVE';
  };

  return (
    <form className="contractor-form" onSubmit={handleSubmit} noValidate>
      {isEditMode && <Field id={formConstants.fields.id} name={formConstants.fields.id} component={CustomInput} type="text" isHidden />}

      {activeStep === steps.firstStep && (
        <>
          {!isEditMode && (
            <>
              <p className="f-primary">
                {t('CONTRACTOR_FORM.EXISTING_USER_EXPLANATION')}
                <label> ({t('FIELD.OPTIONAL')})</label>
              </p>
              <Field
                id={formConstants.fields.userObject}
                name={formConstants.fields.userObject}
                data={regularUsers}
                component={CustomSelect}
                valueField={userFields.id}
                textField={userFields.name}
                className="select-d"
                placeholder={t('CONTRACTOR_FORM.EXISTING_USER')}
                style={{ marginTop: '17px' }}
                disabled={isDisabled}
                size="lg"
                onChange={(_a, value) => {
                  change(formConstants.fields.userId, value[userFields.id]);
                  change(formConstants.fields.name, value[userFields.name]);
                  change(formConstants.fields.email, value[userFields.email]);
                  change(formConstants.fields.phone, value[userFields.phone]);
                  change(formConstants.fields.company, value[userFields.company]);
                }}
              />
            </>
          )}
          <div className="form-fields">
            <Field
              id={formConstants.fields.name}
              name={formConstants.fields.name}
              component={CustomInput}
              disabled={isDisabled}
              placeholder={'CONTRACTOR_FORM.NAME'}
              label={'CONTRACTOR_FORM.NAME'}
              type="text"
              size="lg"
            />

            <Field
              id={formConstants.fields.company}
              name={formConstants.fields.company}
              component={CustomInput}
              disabled={isDisabled}
              placeholder={'CONTRACTOR_FORM.COMPANY'}
              label={'CONTRACTOR_FORM.COMPANY'}
              type="text"
              size="lg"
            />
          </div>
          <div className="form-fields">
            <Field
              id={formConstants.fields.email}
              name={formConstants.fields.email}
              component={CustomInput}
              disabled={isDisabled}
              placeholder={'CONTRACTOR_FORM.EMAIL'}
              label={'CONTRACTOR_FORM.EMAIL'}
              type="text"
              size="lg"
            />
            <Field
              id={formConstants.fields.phone}
              name={formConstants.fields.phone}
              component={CustomInput}
              disabled={isDisabled}
              placeholder={'CONTRACTOR_FORM.PHONE'}
              label={'CONTRACTOR_FORM.PHONE'}
              type="text"
              size="lg"
            />
          </div>
        </>
      )}
      {activeStep === steps.secondStep && (
        <>
          <div className="form-fields">
            <Field
              disabledKeyboardNavigation={true}
              dateFormat="MMMM d, yyyy"
              id={formConstants.fields.permitTestDate}
              name={formConstants.fields.permitTestDate}
              component={CustomDatePicker}
              popperPlacement="bottom-end"
              placeholder={'CONTRACTOR_FORM.PERMIT_TEST_DATE'}
              label={'CONTRACTOR_FORM.PERMIT_TEST_DATE'}
              disabled={isDisabled}
              isClearable={isDateClearable}
              size="lg"
            />
            <Field
              disabledKeyboardNavigation={true}
              dateFormat="MMMM d, yyyy"
              id={formConstants.fields.permitTestExpirationDate}
              name={formConstants.fields.permitTestExpirationDate}
              component={CustomDatePicker}
              popperPlacement="bottom-end"
              placeholder={'CONTRACTOR_FORM.PERMIT_TEST_EXPIRATION_DATE'}
              label={'CONTRACTOR_FORM.PERMIT_TEST_EXPIRATION_DATE'}
              disabled={isDisabled}
              isClearable={isDateClearable}
              size="lg"
            />
          </div>
          <div className="added-items">
            <h6 className="f-primary">
              {t('CONTRACTOR_FORM.ADDED_FILES')}
              <label> ({t('FIELD.OPTIONAL')})</label>
            </h6>

            <div className="added-items-list">
              {files &&
                files.map((item, index) => {
                  return (
                    <div className="item-r" key={`contractor-file-${item[fileFields.id]}`} onClick={() => handleOpenFile(item[fileFields.url])}>
                      <p className="f-primary c-title">{`${index + 1}. ${item[fileFields.name]}`}</p>
                      {!isDisabled && (
                        <Icon
                          name="delete"
                          size="xs"
                          onClick={e => {
                            e.stopPropagation();
                            deleteContractorFile(item);
                          }}
                          className={`icon`}
                        />
                      )}
                    </div>
                  );
                })}
              {isEmpty(files) && (
                <div className="empty-state">
                  <p className="f-secondary-dark">{t('CONTRACTOR_FORM.NO_FILES')} </p>
                  <div className={`add-files ${isDisabled ? '' : 'pointer'}`} onClick={() => (!isDisabled ? openFilesModalData() : null)}>
                    <p className="f-secondary-dark"> {t('CONTRACTOR_FORM.ADD_FILES')}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Button disabled={isDisabled} type="button" variant="gray-outline" width="lg" text={t('CONTRACTOR_FORM.UPLOAD_DOCUMENTS')} onClick={() => openFilesModalData()} />
        </>
      )}

      <div className="buttons">
        <Button
          type="button"
          variant="gray-outline"
          width="sm"
          height="md"
          text={activeStep === steps.firstStep ? t('CONTRACTOR_FORM.CANCEL') : t('CONTRACTOR_FORM.BACK')}
          onClick={() => stepAction(false)}
        />
        <Button width="sm" height="md" disabled={activeStep === lastStep && invalid} text={t(getButtonText())} />
      </div>
    </form>
  );
};

CreateContractorForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default reduxForm({
  form: FORMS.createContractorForm,
  validate: validate,
})(CreateContractorForm);
