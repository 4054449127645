import PropTypes from 'prop-types';
import React from 'react';
import TableTemplate from '../../../../common/double-table/components/table-template';
import EmptyState from '../../../../common/empty-state-v2/components/empty-state';
import Button from '../../../../common/form/components/button';
import Helpers from '../../../../common/helpers';
import Icon from '../../../../common/icon/components/icon';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../common/permissions-constants';
import RenderIf from '../../../../common/render-if/components/render-if';
import '../../styles/work-order-section-components.scss';
import { statuses } from '../observations/constants/constants';
import { formConstants, observationTableConfig } from './constants/constants';

const observationTableFormatCells = (value, type, _searchTerm, _row, _onDropdownClick, t, _pointerDotID, user) => {
  if (type === formConstants.fields.icon) {
    const status = statuses[value?.toLowerCase()];
    return <Icon name={status.icon} {...status.iconProps} />;
  } else
    return (
      <p className="f-primary three-dot-text" title={Helpers.hasAccess({ user, visibleFor: PERMISSIONS[PERMISSION_TYPES.observations].view.name }) ? value : t('COMPONENTS_TAB.NO_PERMISSION')}>
        {value}
      </p>
    );
};

const WorkOrderSectionObservations = ({ module, handleCustomAction, customAction, user }, { t }) => {
  const { onModuleItemDeepLinkHandler } = module;

  return (
    <div className="work-order-section-observations">
      <RenderIf if={module && module.workOrderObservations?.length > 0}>
        <Button
          type="button"
          text={t('OBJECT_TOOLBOX.CREATE_DEFECT_TITLE')}
          variant="success-outline"
          onClick={() => {
            handleCustomAction(customAction);
          }}
          disabled={!Helpers.hasAccess({ user, visibleFor: PERMISSIONS[PERMISSION_TYPES.observations].create.name })}
          isCapitalized
          className="notification-WO-tab__create-button__container__button-width mb-20"
        />
        <div className="notification-WO-tab__table">
          <TableTemplate
            data={module.workOrderObservations}
            handleFormatCells={observationTableFormatCells}
            showSearchBar={false}
            tableConfig={observationTableConfig}
            showInfo={false}
            stickyHeader={false}
            rowClickDisabled={!Helpers.hasAccess({ user, visibleFor: PERMISSIONS[PERMISSION_TYPES.observations].view.name })}
            // defects is not in constants since the module.defects value is not the same as the prop name
            onRowClick={row => onModuleItemDeepLinkHandler(row, 'defects')}
            noDataText={t('WORK_ORDERS.NO_COMPONENTS')}
            user={user}
          />
        </div>
      </RenderIf>
      <RenderIf if={(module && !module.workOrderObservations) || module.workOrderObservations.length === 0}>
        <EmptyState
          emptyStateText={t('WORK_ORDERS.OBSERVATION_SECTION.EMPTY_STATE')}
          showButton={true}
          buttonText={t('OBJECT_TOOLBOX.CREATE_DEFECT_TITLE')}
          buttonAction={() => handleCustomAction(customAction)}
          buttonDisabled={!Helpers.hasAccess({ user, visibleFor: PERMISSIONS[PERMISSION_TYPES.observations].create.name })}
          transparent
        />
      </RenderIf>
    </div>
  );
};

WorkOrderSectionObservations.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default WorkOrderSectionObservations;
