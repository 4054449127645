import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import ChevronDown from '../../../navigation/assets/chevron-down.png';
import { ReactComponent as LocationIcon } from '../assets/sap-assets/location-icon.svg';

import equipmentConstants from '../constants/equipment-constants';
import Icon from '../../../../common/icon/components/icon';

import '../styles/equipment-item.scss';

const leftUnitMargin = 15;

class EquipmentItem extends Component {
  renderChildrenItems = () => {
    const { item, level, handleItemClick, handleItemSelect } = this.props;
    if (isEmpty(item.children) || !item.active) {
      return;
    }

    return (
      <div className="children">
        {item.children.map(item => (
          <EquipmentItem
            level={level + 1}
            key={item.id}
            handleItemClick={handleItemClick}
            handleItemSelect={handleItemSelect}
            item={{
              ...item,
              children: (item[equipmentConstants.formConstants.fields.children] || []).map(child => ({
                ...child,
                id: child[equipmentConstants.formConstants.fields.id],
                name: child[equipmentConstants.formConstants.fields.name],
                count: child[equipmentConstants.formConstants.fields.count],
              })),
            }}
          />
        ))}
      </div>
    );
  };

  handleSelect = e => {
    const { handleItemSelect, item } = this.props;

    e.stopPropagation();
    e.preventDefault();
    handleItemSelect(item);
  };

  handleClick = e => {
    const { handleItemClick, item } = this.props;

    if (isEmpty(item.children)) return;

    e.stopPropagation();
    handleItemClick(item);
  };

  render() {
    const { item } = this.props;

    return (
      <div style={{ marginLeft: `${leftUnitMargin}px` }} className={`equipment-item ${isEmpty(item.children) ? '' : 'expandable'}`}>
        <div className={`item`} ref={ref => (this.itemRef = ref)} onClick={this.handleClick}>
          <div className="item__equipment-type">
            {item[equipmentConstants.formConstants.fields.objectType] === equipmentConstants.objectTypes.equipment ? (
              <Icon name="components" size="xs" />
            ) : (
              <LocationIcon height={14} className={`svg-black-g`} />
            )}
          </div>
          <div className="item__checkbox">
            <input id={`${item.id}`} name={`${item.id}`} type="checkbox" onChange={() => null} checked={item.selected} />
            <label className="box" htmlFor={`${item.id}`} onClick={this.handleSelect} />
          </div>
          <div title={item.name} className="item__name">
            <p>{item.name}</p>
            <label>({item.count || 0})</label>
          </div>
          <div className="item__dropdown-arrow">
            <div className={`arrow arrow--${item.active ? 'up' : 'down'}`}>
              <img src={ChevronDown} alt="chevron" />
            </div>
          </div>
        </div>
        {this.renderChildrenItems()}
      </div>
    );
  }
}

EquipmentItem.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default EquipmentItem;
