import React, { Component } from 'react';

import RenderIf from '../../render-if/components/render-if';
import Lottie from 'react-lottie';
import { animation } from '../constants/demo';

class Loader extends Component {
  handleClick = e => {
    e.preventDefault();
  };
  render() {
    const { isLoading, width, height, className, customText, divStyle, ...restProps } = this.props;
    return (
      <RenderIf if={isLoading > 0}>
        <div className={className || ''} style={divStyle}>
          <Lottie
            options={{
              animationData: animation,
              loop: true,
              autoplay: true,
            }}
            height={width}
            width={height}
            title={className || ''}
            {...restProps}
          />
          {customText && <p className="f-primary">{customText}</p>}
        </div>
      </RenderIf>
    );
  }
}

Loader.defaultProps = {
  width: 200,
  height: 200,
};

export default Loader;
