import Toggle from '../../../../../common/form/components/toggle-field';
import Helpers from '../../../../../common/helpers';
import Icon from '../../../../../common/icon/components/icon';
import InfoTooltip from '../../../../../common/tooltip/components/info-tooltip';
import MultiplePreviewPopup from '../../../../../common/tooltip/components/multiple-preview-popover';

export const settingsFormConstants = {
  graphingGroup: {
    id: 'graphingGroup',
    name: 'DefaultGraphID',
    label: 'TIME_SERIES_GRAPH.SETTINGS_FORM.GRAPHING_GROUP.LABEL',
    placeholder: 'TIME_SERIES_GRAPH.SETTINGS_FORM.GRAPHING_GROUP.PLACEHOLDER',
  },
  aggregation: {
    id: 'aggregation',
    name: 'AggregationType',
    label: 'TIME_SERIES_GRAPH.SETTINGS_FORM.AGGREGATION.LABEL',
    placeholder: 'TIME_SERIES_GRAPH.SETTINGS_FORM.AGGREGATION.PLACEHOLDER',
    errorRequired: 'TIME_SERIES_GRAPH.SETTINGS_FORM.AGGREGATION.ERROR_IS_REQUIRED',
  },
  timePeriod: {
    id: 'timePeriod',
    name: 'TimePeriodType',
    label: 'TIME_SERIES_GRAPH.SETTINGS_FORM.TIME_PERIOD.LABEL',
    placeholder: 'TIME_SERIES_GRAPH.SETTINGS_FORM.TIME_PERIOD.PLACEHOLDER',
    errorRequired: 'TIME_SERIES_GRAPH.SETTINGS_FORM.TIME_PERIOD.ERROR_IS_REQUIRED',
  },
  chunks: {
    id: 'chunks',
    name: 'Chunk',
    label: 'TIME_SERIES_GRAPH.SETTINGS_FORM.CHUNKS.LABEL',
    placeholder: 'TIME_SERIES_GRAPH.SETTINGS_FORM.CHUNKS.PLACEHOLDER',
    errorRequired: 'TIME_SERIES_GRAPH.SETTINGS_FORM.CHUNKS.ERROR_IS_REQUIRED',
  },
  showAlarms: {
    id: 'showAlarms',
    name: 'ShowAlarms',
    label: 'TIME_SERIES_GRAPH.SETTINGS_FORM.SHOW_ALARMS.LABEL',
    placeholder: 'TIME_SERIES_GRAPH.SETTINGS_FORM.SHOW_ALARMS.PLACEHOLDER',
    description: 'TIME_SERIES_GRAPH.SETTINGS_FORM.SHOW_ALARMS.DESCRIPTION',
  },
  dateFrom: {
    id: 'dateFrom',
    name: 'DateFrom',
    label: 'TIME_SERIES_GRAPH.SETTINGS_FORM.DATE_FROM.LABEL',
    placeholder: 'TIME_SERIES_GRAPH.SETTINGS_FORM.DATE_FROM.PLACEHOLDER',
    errorRequired: 'TIME_SERIES_GRAPH.SETTINGS_FORM.DATE_FROM.ERROR_REQUIRED',
  },
  dateTo: {
    id: 'dateTo',
    name: 'DateTo',
    label: 'TIME_SERIES_GRAPH.SETTINGS_FORM.DATE_TO.LABEL',
    placeholder: 'TIME_SERIES_GRAPH.SETTINGS_FORM.DATE_TO.PLACEHOLDER',
    errorRequired: 'TIME_SERIES_GRAPH.SETTINGS_FORM.DATE_FROM.ERROR_REQUIRED',
  },
};

export const formConstants = {
  aggregation: 'Aggregation',
  chunk: 'Chunk',
  createdBy: 'CreatedBy',
  id: 'ID',
  isDefault: 'IsDefault',
  measurementPoints: 'MeasurementPoints',
  name: 'Name',
  startDateFrom: 'StartDateFrom',
  startDateTo: 'StartDateTo',
  timePeriod: 'TimePeriodType',
};

export const timePeriods = t => [
  { ID: 'LAST_WEEK', Name: t('TIME_SERIES_GRAPH.LAST_WEEK') },
  { ID: 'LAST_MONTH', Name: t('TIME_SERIES_GRAPH.LAST_MONTH') },
  { ID: 'LAST_YEAR', Name: t('TIME_SERIES_GRAPH.LAST_YEAR') },
  { ID: 'CUSTOM', Name: t('TIME_SERIES_GRAPH.CUSTOM') },
];

export const aggregationFields = {
  key: 'Key',
  value: 'Value',
};

export const timePeriodFields = {
  key: 'Name',
  value: 'ID',
};

export const chunkFields = {
  key: 'Key',
  value: 'Value',
};

export const customTimePeriodIndex = 3;

export const defaultGraphSettingsValues = (aggregationList, chunkList, timePeriodList) => {
  const defaultTimePeriod = timePeriodList[2]; // LAST_YEAR
  const rangeLastYear = Helpers.getDateRangeByTimePeriod(defaultTimePeriod[timePeriodFields.value]);

  return {
    [settingsFormConstants.aggregation.name]: [aggregationList[0]], // AVERAGE
    [settingsFormConstants.timePeriod.name]: defaultTimePeriod,
    [settingsFormConstants.chunks.name]: chunkList[chunkList.length - 2], // MONTHLY
    [settingsFormConstants.dateFrom.name]: new Date(rangeLastYear.DateFrom * 1000),
    [settingsFormConstants.dateTo.name]: new Date(rangeLastYear.DateTo * 1000),
    [settingsFormConstants.showAlarms.name]: false,
  };
};

export const graphingGroupsTableConfig = [
  {
    title: 'GRAPHING_GROUPS_LIST.TABLE.NAME',
    key: formConstants.name,
    type: formConstants.name,
    class: 'name-column',
    isSort: false,
  },
  {
    title: 'GRAPHING_GROUPS_LIST.TABLE.MEASUREMENT_POINTS',
    key: formConstants.measurementPoints,
    type: formConstants.measurementPoints,
    isSort: false,
    class: 'measurement-points-column',
  },
  {
    title: 'GRAPHING_GROUPS_LIST.TABLE.SET_AS_DEFAULT',
    key: formConstants.isDefault,
    type: formConstants.isDefault,
    isSort: false,
    class: 'is-default-column',
  },
  {
    title: '',
    key: 'actions',
    type: 'actions',
    class: 'action-column',
  },
];

export const formatGraphingGroupTableCells = (value, type, searchTerm, row, t, restProps) => {
  const { editGraphingGroup, deleteGraphingGroup, setAsDefault } = restProps;
  if (type === formConstants.name) {
    return (
      <p className="graphing-groups-list__title f-primary" title={value}>
        {Helpers.getSearchTermText(value, searchTerm)}
      </p>
    );
  } else if (type === formConstants.measurementPoints) {
    const mpNumber = (value || []).length;

    if (mpNumber < 2) {
      return (
        <p className="f-primary">{mpNumber && row[formConstants.measurementPoints] && row[formConstants.measurementPoints][0] ? row[formConstants.measurementPoints][0][formConstants.name] : '-'}</p>
      );
    } else {
      return (
        <div className="graphing-groups-list__measurement-points">
          <InfoTooltip
            customComponent={<MultiplePreviewPopup title={row[formConstants.name]} subTitle={t('GRAPHING_GROUPS_LIST.TABLE.MEASUREMENT_POINTS')} list={value} />}
            offsetY={10}
            offsetX={-20}
            Component={() => (
              <div className="graphing-groups-list__measurement-points-popover">
                <p className="f-primary">{t('MULTIPLE', { numberOfItems: mpNumber })}</p>
                <Icon name="info" handleHover={false} size="xs" />
              </div>
            )}
          />
        </div>
      );
    }
  } else if (type === formConstants.isDefault) {
    return <Toggle input={{ value, onChange: e => setAsDefault(e, row) }} inline={false} className="graphing-groups-list__toggle" />;
  } else if (type === 'actions') {
    const canDelete = !row[formConstants.isDefault];
    return (
      <>
        <Icon name="pen" size="xs" onClick={e => editGraphingGroup(e, row)} />
        <Icon disabled={!canDelete} handleHover={canDelete} name="trash" size="xs" onClick={e => (canDelete ? deleteGraphingGroup(e, row) : null)} />
      </>
    );
  } else {
    return <p className="f-primary">{value ? value : '-'}</p>;
  }
};

export const steps = {
  firstStep: 1,
  secondStep: 2,
};
