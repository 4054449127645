import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../common/form/components/button';

import { componentFields, filterValues, componentFilterValues, componentTagsTableConfig, formatComponentTagsTableCells } from '../constants/constants';
import AccessRenderer from '../../../common/access-renderer/components/access-renderer';

import '../style/tagging-tools.scss';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../common/permissions-constants';
import TableTemplate from '../../../common/double-table/components/table-template';

class TaggingTools extends Component {
  render() {
    const { t } = this.context;
    const { tags, markOnPdfClick, notInThisPdfClick, confirmTagClick, component, handleRemoveDrawing, handleDrawingListClick, filter, selectedDrawing, activePdfPageNumber } = this.props;
    const allCurrentPageComponentTagsConfirmed = tags.filter(tag => tag[componentFields.pageNumber] === activePdfPageNumber).every(tag => tag[componentFields.confirmed] === true);

    return (
      <div className="tagging-tools">
        <h6 className="f-primary noselect">{t('PDF_TAG.RIGHT.TAGGING_TOOLS_TITLE')}</h6>

        <div className="buttons">
          <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.smartDrawing].manipulate.name}>
            {({ hasAccess }) => (
              <Button
                text={t('PDF_TAG.RIGHT.NOT_IN_THIS_PDF')}
                onClick={() => notInThisPdfClick(component)}
                variant="gray-outline"
                width="lg"
                disabled={!hasAccess || !tags || tags.length === 0}
                keepOriginalText
              />
            )}
          </AccessRenderer>
          {filter[filterValues.componentFilter] !== componentFilterValues.cvFound && (
            <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.smartDrawing].manipulate.name}>
              {({ hasAccess }) => (
                <Button
                  text={t('PDF_TAG.RIGHT.MARK_ON_PDF')}
                  onClick={markOnPdfClick}
                  variant="gray-outline"
                  width="lg"
                  disabled={!hasAccess || !component[componentFields.componentId] || component[componentFields.componentId] < 0}
                  keepOriginalText
                />
              )}
            </AccessRenderer>
          )}
          {tags && tags.length > 0 && (
            <Button
              text={t(filter[filterValues.componentFilter] !== componentFilterValues.cvFound ? 'PDF_TAG.RIGHT.CONFIRM_TAG' : 'PDF_TAG.RIGHT.SAVE_TAG')}
              onClick={confirmTagClick}
              variant="warning"
              width="lg"
              disabled={allCurrentPageComponentTagsConfirmed}
            />
          )}
        </div>

        <div className="tags">
          <TableTemplate
            tableConfig={componentTagsTableConfig}
            filters={{}}
            data={tags}
            onSortClick={() => null}
            loadMoreOnClick={() => null}
            quickFilters={[]}
            onQuickFilterClick={() => null}
            handleFormatCells={formatComponentTagsTableCells}
            openAdvancedFilters={() => null}
            selectedItem={selectedDrawing}
            isLoading={null}
            TotalItems={tags.length}
            searchPlaceholder={''}
            sortDirection={null}
            sortByColumn={null}
            resultsText={'COMPONENT_TAGS.TOTAL'}
            searchInputOnChange={() => null}
            searchInputValue={''}
            hasNext={null}
            selectedQuickFilters={null}
            activeAdvancedFilters={null}
            noDataText={t('COMPONENT_TAGS.NO_DATA')}
            customTableClass={'component-tags-table'}
            showSearchBar={false}
            onDeleteRow={item => handleRemoveDrawing(item, item.PageNumber)}
            onRowClick={item => handleDrawingListClick(item)}
          />
        </div>
      </div>
    );
  }
}

TaggingTools.defaultProps = {
  tags: [],
};

TaggingTools.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default TaggingTools;
