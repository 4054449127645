import { fields } from '../constants/isolation-certificate-form';

export const validate = values => {
  const errors = {};
  const { isolationTemplate, name, description, startDate, endDate } = fields;

  if (!values[isolationTemplate.name]) {
    errors[isolationTemplate.name] = isolationTemplate.errorRequired;
  }

  if (!values[name.name]) {
    errors[name.name] = name.errorRequired;
  }

  if (values[name.name] && values[name.name].length < name.minChars) {
    errors[name.name] = name.errorMinChars;
  }

  if (values[name.name] && values[name.name].length > name.maxChars) {
    errors[name.name] = name.errorMaxChars;
  }

  if (values[description.name] && values[description.name].length > description.maxChars) {
    errors[description.name] = description.errorMaxChars;
  }

  if (!values[startDate.name]) {
    errors[startDate.name] = startDate.errorRequired;
  }

  if (
    values[startDate.name] &&
    values[endDate.name] &&
    (new Date(values[endDate.name]) < new Date(values[startDate.name]) || new Date(values[endDate.name]).getTime() === new Date(values[startDate.name]).getTime())
  ) {
    errors[endDate.name] = endDate.errorMinDate;
  }

  return errors;
};
