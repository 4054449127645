import React from 'react';
import PropTypes from 'prop-types';
import { values, filter } from 'lodash';

import Helpers from '../../../../../common/helpers';
import DisplayStatus from '../../../../../common/display-status/components/display-status';
import { statuses } from '../../../constants/work-order-constants';

const WorkOrderItem = ({ component = {}, fields }, { t }) => {
  let componentFields = values(fields);
  componentFields = filter(componentFields, { showInDisplay: true });

  const getValue = field => {
    const value = component[field.name];

    if (fields.status && field.name === fields.status.name) {
      return <DisplayStatus statuses={values(statuses)} status={value} />;
    }
    if (value) {
      return Helpers.formatCell(value, field.type);
    }

    return '-';
  };

  return (
    <div className="work-order-item-container">
      {componentFields.map(field => (
        <div className="work-order-item" key={`component-item-${component[fields.id.name]}-${field.name}`}>
          <p className="f-secondary-dark">{t(field.label)}</p>
          <p className="f-primary">{getValue(field)}</p>
        </div>
      ))}
    </div>
  );
};

WorkOrderItem.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default WorkOrderItem;
