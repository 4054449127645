import React from 'react';
import PropTypes from 'prop-types';

import CreateButton from '../../../../../common/create-button/components/create-button';
import SmallDropdown from '../../../../../common/small-dropdown/components/small-dropdown';
import Icon from '../../../../../common/icon/components/icon';

import '../../../styles/module-header.scss';

const ModuleHeader = (props, context) => {
  const { t } = context;
  const { actionsMenu, title, backAction, backButtonText, icon: ModuleIcon, getClickAction, user, actionButtontext, handleActionButtonClick, actionButtonProps } = props;

  return (
    <div className="module-header">
      <div className="back-button" onClick={backAction}>
        <Icon name="chevron" size="xs" rotation={180} />
        <p className="f-secondary-green link">{t(backButtonText)}</p>
      </div>
      <div className="header">
        <div className="text">
          {ModuleIcon && <ModuleIcon active={true} />}
          <h4 className="f-primary">{title}</h4>
        </div>
        <div className="module-header-actions">
          {actionButtontext && handleActionButtonClick && (
            <CreateButton user={user || {}} onClick={handleActionButtonClick} {...(actionButtonProps || {})}>
              {actionButtontext}
            </CreateButton>
          )}
          {actionsMenu?.length > 0 && (
            <SmallDropdown
              className="documents-dropdown"
              actionsMenu={actionsMenu}
              getClickAction={(action, b, selfClose, e) => {
                e?.preventDefault && e.preventDefault();
                selfClose();
                if (action) {
                  if (typeof action === 'function') {
                    action();
                  } else if (typeof action === 'string' && getClickAction) {
                    getClickAction(action);
                  }
                }
              }}
              offsetX={10}
              asButton
            />
          )}
        </div>
      </div>
    </div>
  );
};
ModuleHeader.contextTypes = {
  t: PropTypes.func.isRequired,
};
ModuleHeader.defaultProps = {
  title: '',
  backButtonText: 'MODULE_HEADER.BACK',
  actionsMenu: [],
};

export default ModuleHeader;
