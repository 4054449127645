import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import RegisterForm from './register-form';
import RegisterWrapper from '../../shared/common-component/components/register-wrapper';
import Modal from '../../../common/modal/components/modal';

import { register } from '../actions/register-actions';

import Helpers from '../../../common/helpers';
import routesConstants from '../../../common/routes-constants';
import registerConstants from '../constants/register-constants';

class Register extends Component {
  state = {
    modalData: {
      isOpen: false,
    },
    origin: null,
  };

  openSuccessModal = () => {
    const { t } = this.context;
    this.setState(prevProps => ({
      modalData: {
        ...prevProps.modalData,
        isOpen: true,
        content: t('REGISTER_MODAL.CHECK_EMAIL'),
        confirmAction: this.closeSuccessModal,
        closeAction: this.closeSuccessModal,
      },
    }));
  };
  closeSuccessModal = () => {
    this.setState(prevProps => ({
      modalData: {
        ...prevProps.modalData,
        isOpen: false,
      },
    }));

    Helpers.goTo(routesConstants.routes.unProtectedRoutes.login.fullPath);
  };

  componentDidMount = () => {
    const {
      user: { Token },
    } = this.props;
    if (Token) Helpers.goTo(routesConstants.defaultRoute);

    const { query } = this.props.location;
    const origin = query && query.origin;

    this.setState({ origin });
  };

  handleSubmit = values => {
    const { register } = this.props;
    const { origin } = this.state;

    register({ ...values, [registerConstants.fields.origin]: origin }, this.openSuccessModal);
  };
  render() {
    const { modalData } = this.state;
    return (
      <RegisterWrapper>
        <div className="bg-wrapper">
          <div className="bg-image" />
        </div>
        <div className="register-wrapper">
          <RegisterForm onSubmit={this.handleSubmit} />
        </div>
        <Modal {...modalData} />
      </RegisterWrapper>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userReducer,
});

const mapDispatchToProps = dispatch => ({ register: (values, openSuccessModal) => dispatch(register(values, openSuccessModal)) });

Register.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
