import { API_BASE_URL } from '../../common/constants';
import { Api } from '../../core/api';
import { generateQueryParams } from '../helpers/helper';
import routesConstants from './routes';

// GROUP

const fetchGroups = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_GROUPS}`, data);
};

const createGroup = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CREATE_GROUP}`, data);
};

const editGroup = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.EDIT_GROUP}`, data);
};

const deleteGroup = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_GROUP}`, data);
};

// ML

const fetchMeasurementLocations = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_MEASUREMENT_LOCATIONS}`, data);
};

const createMeasurementLocation = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CREATE_MEASUREMENT_LOCATION}`, data);
};

const getMeasurementLocationDetails = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_MEASUREMENT_LOCATION_DETAILS}${generateQueryParams(params)}`);
};

const updateMeasurementLocationDetails = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_MEASUREMENT_LOCATION_DETAILS}`, data);
};

const deleteMeasurementLocation = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_MEASUREMENT_LOCATION}`, data);
};

const getMeasurementLocationsClustered = async (data, options) => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_MEASUREMENT_LOCATIONS_CLUSTERED}`, data, options);
};

const updateMeasurementLocationGeometry = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_MEASUREMENT_LOCATION_GEOMETRY}`, data);
};

const linkMeasurementLocationComponent = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.LINK_MEASUREMENT_LOCATION_COMPONENT}`, data);
};

const changeMeasurementGroup = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CHANGE_MEASUREMENT_GROUP}`, data);
};

const getMeasurementLocationTimeSeriesGraph = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_MEASUREMENT_LOCATION_TIME_SERIES_GRAPH}`, data);
};

// MP

const fetchMeasurementPoints = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_MEASUREMENT_POINTS}`, data);
};

const createMeasurementPoint = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CREATE_MEASUREMENT_POINT}`, data);
};

const getMeasurementPointDetails = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_MEASUREMENT_POINT_DETAILS}${generateQueryParams(params)}`);
};

const updateMeasurementPointDetails = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_MEASUREMENT_POINT_DETAILS}`, data);
};

const deleteMeasurementPoint = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_MEASUREMENT_POINT}`, data);
};

const getMeasurementPointsClustered = async (data, options) => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_MEASUREMENT_POINTS_CLUSTERED}`, data, options);
};

const updateMeasurementPointGeometry = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_MEASUREMENT_POINT_GEOMETRY}`, data);
};

const linkMeasurementPointComponent = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.LINK_MEASUREMENT_POINT_COMPONENT}`, data);
};

const unlinkMeasurementPointComponent = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UNLINK_MEASUREMENT_POINT_COMPONENT}`, data);
};

const fetchUsersAndTeams = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_USERS_AND_TEAMS}`, data);
};

const fetchAlarmsDescriptors = async () => {
  return await Api.get(`${API_BASE_URL}${routesConstants.FETCH_ALARM_DESCRIPTORS}`);
};

const fetchMeasurementPointAlarms = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_MEASUREMENT_POINT_ALARMS}`, data);
};

const updateMeasurementPointAlarms = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_MEASUREMENT_POINT_ALARMS}`, data);
};

const saveMeasurementReading = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.SAVE_MEASUREMENT_READING}`, data);
};

const updateMeasurementReading = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_MEASUREMENT_READING}`, data);
};

const fetchMeasurementReadings = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_MEASUREMENT_READINGS}`, data);
};

const deleteMeasurementReading = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_MEASUREMENT_READING}`, data);
};

const fetchMeasurementPointObservations = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_MEASUREMENT_POINT_OBSERVATIONS}`, data);
};

const fetchMeasurementPointNotifications = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_MEASUREMENT_POINT_NOTIFICATIONS}`, data);
};

const fetchMeasurementPointsForMeasurementLocation = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_MEASUREMENT_POINTS_FOR_MEASUREMENT_LOCATION}`, data);
};

const updateOrderMeasurementPoints = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_ORDER_MEASUREMENT_POINTS_INSIDE_MEASUREMENT_LOCATION}`, data);
};

const getMeasurementPointTimeSeriesGraph = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_MEASUREMENT_POINT_TIME_SERIES_GRAPH}`, data);
};

const getGraphingGroupList = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_GRAPHING_GROUP_LIST}`, data);
};

const getAggregationTypes = async () => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_AGGREGATION_TYPES}`);
};

const getChunks = async () => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_CHUNKS}`);
};

const saveGraphingGroup = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CREATE_GRAPHING_GROUP}`, data);
};

const deleteGraphingGroup = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_GRAPHING_GROUP}`, data);
};

const getGraphingGroupDetails = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_GRAPHING_GROUP_DETAILS}`, data);
};

const saveAsDefaultGraphingGroup = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.SAVE_AS_DEFAULT_GRAPHING_GROUP}`, data);
};

// generic
const generateAndDownloadReport = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GENERATE_AND_DOWNLOAD_REPORT}`, data);
};

const fetchUnits = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_UNITS}`, data);
};

const getMeasurementPointGraphDetails = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_MEASUREMENT_POINT_GRAPH_DETAILS}`, data);
};

const saveMeasurementPointGraphDetails = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.SAVE_MEASUREMENT_POINT_GRAPH_DETAILS}`, data);
};

// Checklist procedure template API actions
const fetchChecklistProcedureMeasurementLocations = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_CP_MEASUREMENT_LOCATIONS}`, data);
};

const fetchChecklistProcedureMeasurementPoints = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_CP_MEASUREMENT_POINTS}`, data);
};

const fetchChecklistProcedureMeasurementGroups = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_CP_MEASUREMENT_GROUPS}`, data);
};

const linkMeasurementLocationToChecklistProcedure = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.LINK_MEASUREMENT_LOCATION_TO_CP}`, data);
};

const linkMeasurementPointToChecklistProcedure = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.LINK_MEASUREMENT_POINT_TO_CP}`, data);
};

const fetchChecklistProcedureLinkedMeasurementPoints = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_CP_LINKED_MEASUREMENT_POINTS}`, data);
};

const unlinkMeasurementPointFromChecklist = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UNLINK_MEASUREMENT_POINT_FROM_CP}`, data);
};

const unlinkMeasurementLocationFromChecklistProcedure = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UNLINK_MEASUREMENT_LOCATION_FROM_CP}`, data);
};

export default {
  fetchGroups,
  createGroup,
  editGroup,
  deleteGroup,
  fetchMeasurementLocations,
  createMeasurementLocation,
  getMeasurementLocationDetails,
  updateMeasurementLocationDetails,
  deleteMeasurementLocation,
  getMeasurementLocationsClustered,
  updateMeasurementLocationGeometry,
  linkMeasurementLocationComponent,
  changeMeasurementGroup,
  fetchMeasurementPoints,
  createMeasurementPoint,
  getMeasurementPointDetails,
  updateMeasurementPointDetails,
  deleteMeasurementPoint,
  getMeasurementPointsClustered,
  updateMeasurementPointGeometry,
  linkMeasurementPointComponent,
  fetchUsersAndTeams,
  fetchAlarmsDescriptors,
  unlinkMeasurementPointComponent,
  fetchMeasurementPointAlarms,
  updateMeasurementPointAlarms,
  saveMeasurementReading,
  updateMeasurementReading,
  fetchMeasurementReadings,
  deleteMeasurementReading,
  fetchMeasurementPointObservations,
  fetchMeasurementPointNotifications,
  fetchMeasurementPointsForMeasurementLocation,
  updateOrderMeasurementPoints,
  getGraphingGroupList,
  getAggregationTypes,
  getChunks,
  saveGraphingGroup,
  getGraphingGroupDetails,
  getMeasurementLocationTimeSeriesGraph,
  deleteGraphingGroup,
  getMeasurementPointTimeSeriesGraph,
  getMeasurementPointGraphDetails,
  saveAsDefaultGraphingGroup,
  generateAndDownloadReport,
  fetchUnits,
  saveMeasurementPointGraphDetails,
  fetchChecklistProcedureMeasurementLocations,
  fetchChecklistProcedureMeasurementPoints,
  fetchChecklistProcedureMeasurementGroups,
  linkMeasurementLocationToChecklistProcedure,
  linkMeasurementPointToChecklistProcedure,
  fetchChecklistProcedureLinkedMeasurementPoints,
  unlinkMeasurementPointFromChecklist,
  unlinkMeasurementLocationFromChecklistProcedure,
};
