import { Component } from 'react';

class RenderIf extends Component {
  render() {
    if (this.props.if) {
      return this.props.children;
    }

    return null;
  }
}

export default RenderIf;
