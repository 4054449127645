import PropTypes from 'prop-types';
import React from 'react';

import { userTeamFields } from '../../../../../../../common/user-team/constants/constants';
import { formatAlarmSectionList } from '../../../actions/table-formatting';
import { formConstants } from '../../../constants/alarm-constants';
import { formConstants as alarmFormConstants } from '../../../constants/measurement-point-constants';

import '../../../styles/alarm-section-list.scss';

const AlarmSectionList = ({ alarm, unit }, { t }) => {
  return (
    alarm && (
      <div className="alarm-section-list">
        <div className="alarm-section-list-item">
          <label>{t('ALARMS.SECTION.DESCRIPTOR_LABEL')}</label>
          <div className="item">
            <p className="f-primary light-bold component-name">{formatAlarmSectionList(alarm && alarm[formConstants.fields.descriptor.name][alarmFormConstants.fields.description])}</p>
          </div>
        </div>

        <div className="alarm-section-list-item">
          <label>{t('ALARMS.SECTION.ALARM_LEVEL_LABEL', { unit: unit || '-' })}</label>
          <div className="item">
            <p className="f-primary light-bold component-name">{formatAlarmSectionList(alarm[formConstants.fields.alarmLevel.name], formConstants.fields.alarmLevel.name)}</p>
          </div>
        </div>
        <div className="alarm-section-list-item">
          <label>{t('ALARMS.SECTION.NOTIFY_USER_LABEL')}</label>
          {alarm?.[formConstants.fields.notifyAssignees.name] && alarm?.[formConstants.fields.notifyAssignees.name].length > 0 ? (
            alarm?.[formConstants.fields.notifyAssignees.name].map(item => (
              <div className="item" key={`alarm-section-list-item-${item[userTeamFields.name]}`}>
                <p className="f-primary light-bold component-name">{item[userTeamFields.name]}</p>
              </div>
            ))
          ) : (
            <p className="f-primary light-bold component-name">-</p>
          )}
        </div>

        <div className="alarm-section-list-item">
          <label>{t('ALARMS.SECTION.COLOUR_TAG_LABEL')}</label>
          <div className="item">
            <p className="f-primary light-bold component-name">{formatAlarmSectionList(alarm[formConstants.fields.colour.name], formConstants.fields.colour.name)}</p>
          </div>
        </div>

        <div className="alarm-section-list-item">
          <label>{t('ALARMS.SECTION.NOTIFICATION_LABEL')}</label>
          <div className="item">
            <p className="f-primary light-bold component-name">
              {formatAlarmSectionList(alarm[formConstants.fields.enableNotificationCreation.name], formConstants.fields.enableNotificationCreation.name, t)}
            </p>
          </div>
        </div>

        <div className="alarm-section-list-item">
          <label>{t('ALARMS.SECTION.OBSERVATION_LABEL')}</label>
          <div className="item">
            <p className="f-primary light-bold component-name">{formatAlarmSectionList(alarm[formConstants.fields.enableDefectCreation.name], formConstants.fields.enableDefectCreation.name, t)}</p>
          </div>
        </div>
      </div>
    )
  );
};

AlarmSectionList.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default AlarmSectionList;
