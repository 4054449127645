import { defectType } from './defect-constants';

import { ReactComponent as ActiveLines } from '../assets/active lines.svg';
import { ReactComponent as InactiveLines } from '../assets/inactive lines.svg';
import { ReactComponent as ActivePoints } from '../assets/active points.svg';
import { ReactComponent as InactivePoints } from '../assets/inactive points.svg';
import { ReactComponent as ActiveAreas } from '../assets/active areas.svg';
import { ReactComponent as InactiveAreas } from '../assets/inactive areas.svg';
import { ReactComponent as ActiveAngles } from '../assets/active angles.svg';
import { ReactComponent as InactiveAngles } from '../assets/inactive angles.svg';
import { ReactComponent as ActiveHeights } from '../assets/active heights.svg';
import { ReactComponent as InactiveHeights } from '../assets/inactive heights.svg';
import { ReactComponent as ActiveCircle } from '../assets/active circle.svg';
import { ReactComponent as InactiveCircle } from '../assets/inactive circle.svg';

// LIGHT THEME
import { ReactComponent as ActiveLinesLight } from '../assets/active lines-light.svg';
import { ReactComponent as InactiveLinesLight } from '../assets/inactive lines-light.svg';
import { ReactComponent as ActivePointsLight } from '../assets/active points-light.svg';
import { ReactComponent as InactivePointsLight } from '../assets/inactive points-light.svg';
import { ReactComponent as ActiveAreasLight } from '../assets/active areas-light.svg';
import { ReactComponent as InactiveAreasLight } from '../assets/inactive areas-light.svg';
import { ReactComponent as ActiveAnglesLight } from '../assets/active angles-light.svg';
import { ReactComponent as InactiveAnglesLight } from '../assets/inactive angles-light.svg';
import { ReactComponent as ActiveHeightsLight } from '../assets/active heights-light.svg';
import { ReactComponent as InactiveHeightsLight } from '../assets/inactive heights-light.svg';
import { ReactComponent as ActiveCircleLight } from '../assets/active circle-light.svg';
import { ReactComponent as InactiveCircleLight } from '../assets/inactive circle-light.svg';
import Icon from '../../../common/icon/components/icon';
import HistoryItemColorDisplay from '../../../common/history-item/components/history-item-color-display';
import HistoryItemFileDisplay from '../../../common/history-item/components/history-item-file-display';

export const measurementDropdownActions = {
  points: 'toggle-points',
  point: 'point-action',
  area: 'area-action',
  distance: 'distance-action',
  angle: 'angle-action',
  height: 'height-action',
  circle: 'circle-action',
};

export const measurementFields = {
  lines: 'lines',
  points: 'points',
  areas: 'areas',
  angles: 'angles',
  heights: 'heights',
  circles: 'circles',
};

export const filterParams = {
  searchText: 'SearchText',
  sortByColumn: 'SortByColumn',
  sortDirection: 'SortDirection',
  selectedFilter: 'SelectedFilter',
  includedIDs: 'IncludedIDs',

  typeFilter: 'TypeFilter',
  dateFrom: 'DateFrom',
  dateTo: 'DateTo',
  createdByFilter: 'CreatedByFilter',

  lastSeen: 'LastSeen',
  perPage: 'PerPage',
  totalItems: 'TotalNumber',
  hasNext: 'HasNext',
};

export const measurementIcons = {
  dark: {
    [measurementFields.lines]: {
      icon: active => (active ? ActiveLines : InactiveLines),
      label: 'INSPECTION_MEASUREMENTS_LINES',
      field: filterParams.typeFilter,
      value: defectType.distance,
      active: true,
    },
    [measurementFields.points]: {
      icon: active => (active ? ActivePoints : InactivePoints),
      label: 'INSPECTION_MEASUREMENTS_POINTS',
      value: defectType.point,
      field: filterParams.typeFilter,
      active: false,
    },
    [measurementFields.areas]: {
      icon: active => (active ? ActiveAreas : InactiveAreas),
      label: 'INSPECTION_MEASUREMENTS_AREAS',
      value: defectType.area,
      field: filterParams.typeFilter,
      active: false,
    },
    [measurementFields.angles]: {
      icon: active => (active ? ActiveAngles : InactiveAngles),
      label: 'INSPECTION_MEASUREMENTS_ANGLES',
      value: defectType.angle,
      field: filterParams.typeFilter,

      active: false,
    },
    [measurementFields.heights]: {
      icon: active => (active ? ActiveHeights : InactiveHeights),
      label: 'INSPECTION_MEASUREMENTS_HEIGHT_MEASUREMENTS',
      value: defectType.height,
      field: filterParams.typeFilter,
      active: false,
    },
    [measurementFields.circles]: {
      icon: active => (active ? ActiveCircle : InactiveCircle),
      label: 'INSPECTION_MEASUREMENTS_CIRCLE_MEASUREMENTS',
      value: defectType.circle,
      field: filterParams.typeFilter,
      active: false,
    },
  },
  light: {
    [measurementFields.lines]: {
      icon: active => (active ? ActiveLinesLight : InactiveLinesLight),
      label: 'INSPECTION_MEASUREMENTS_LINES',
      value: defectType.distance,
      field: filterParams.typeFilter,
      active: true,
    },
    [measurementFields.points]: {
      icon: active => (active ? ActivePointsLight : InactivePointsLight),
      label: 'INSPECTION_MEASUREMENTS_POINTS',
      value: defectType.point,
      field: filterParams.typeFilter,
      active: false,
    },
    [measurementFields.areas]: {
      icon: active => (active ? ActiveAreasLight : InactiveAreasLight),
      label: 'INSPECTION_MEASUREMENTS_AREAS',
      value: defectType.area,
      field: filterParams.typeFilter,
      active: false,
    },
    [measurementFields.angles]: {
      icon: active => (active ? ActiveAnglesLight : InactiveAnglesLight),
      label: 'INSPECTION_MEASUREMENTS_ANGLES',
      value: defectType.angle,
      field: filterParams.typeFilter,
      active: false,
    },
    [measurementFields.heights]: {
      icon: active => (active ? ActiveHeightsLight : InactiveHeightsLight),
      label: 'INSPECTION_MEASUREMENTS_HEIGHT_MEASUREMENTS',
      value: defectType.height,
      field: filterParams.typeFilter,
      active: false,
    },
    [measurementFields.circles]: {
      icon: active => (active ? ActiveCircleLight : InactiveCircleLight),
      label: 'INSPECTION_MEASUREMENTS_CIRCLE_MEASUREMENTS',
      value: defectType.circle,
      field: filterParams.typeFilter,
      active: false,
    },
  },
};

export const measurementTools = [
  {
    id: '1',
    icon: ({ className, ...restProps }) => <Icon name="measurementDistance" size="xs" handleHover={false} className={`${className} concern`} {...restProps} />,
    title: 'MEASUREMENT_TOOLS.MEASUREMENTS.DISTANCE',
    action: measurementDropdownActions.distance,
  },
  {
    id: '2',
    icon: ({ className, ...restProps }) => <Icon name="measurementArea" size="xs" handleHover={false} className={`${className} concern`} {...restProps} />,
    title: 'MEASUREMENT_TOOLS.MEASUREMENTS.AREA',
    action: measurementDropdownActions.area,
  },
  {
    id: '3',
    icon: ({ className, ...restProps }) => <Icon name="measurementPoint" size="xs" handleHover={false} className={`${className} concern`} {...restProps} />,
    title: 'MEASUREMENT_TOOLS.MEASUREMENTS.POINT',
    action: measurementDropdownActions.point,
  },
  {
    id: '4',
    icon: ({ className, ...restProps }) => <Icon name="measurementAngle" size="xs" handleHover={false} className={`${className} concern`} {...restProps} />,
    title: 'MEASUREMENT_TOOLS.MEASUREMENTS.ANGLE',
    action: measurementDropdownActions.angle,
  },
  {
    id: '5',
    icon: ({ className, ...restProps }) => <Icon name="measurementHeight" size="xs" handleHover={false} className={`${className} concern`} {...restProps} />,
    title: 'MEASUREMENT_TOOLS.MEASUREMENTS.HEIGHT',
    action: measurementDropdownActions.height,
  },
  {
    id: '6',
    icon: ({ className, ...restProps }) => <Icon name="measurementCircle" size="xs" handleHover={false} className={`${className} concern`} {...restProps} />,
    title: 'MEASUREMENT_TOOLS.MEASUREMENTS.CIRCLE',
    action: measurementDropdownActions.circle,
  },
];

export const maxDefectUploadFileCount = 10;

export const maxDefectUploadFileSize = {
  value: 50,
  unit: 'mb',
  bytes: 52428800,
};
export const textAreaMaxChars = 2000;

export const uploadGroups = {
  other: 'OTHER_FILES',
  otherImages: 'OTHER_IMAGES',
  linkedImages: 'LINKED_IMAGES',
  resolutionImages: 'RESOLUTION_IMAGES',
  resolutionFiles: 'RESOLUTION_FILES',
  measurementOther: 'OTHER',
};

export const formConstants = {
  fields: {
    color: 'Colour',
    componentType: 'ComponentType',
    name: 'Name',
    code: 'ComponentCode',
    manufacturer: 'Manufacturer',
    material: 'Material',
    type: 'DefectType',
    severity: 'Severity',
    description: 'Description',
    recommendation: 'Recommendation',
    footage: 'Assets',
    date: 'DateCreated',
    loggedBy: 'LoggedByUser.Name',
    loggedByUserID: 'LoggedByUser.ID',
    points: 'Geometry.coordinates',
    comments: 'comments',
    camPosition: 'CameraPosition.coordinates',
    component: 'component',
    componentID: 'ComponentID',
    linkedDefect: 'SelectedDefect',
    linkedDefectID: 'SelectedDefectID',
    id: 'ID',
    status: 'Status',
    // constants for the lists
    // points object fields
    x: '0',
    y: '1',
    z: '2',
    pointName: 'name',
    // comments object fields
    author: 'author',
    text: 'text',
    systemType: 'SystemType',
    drawings: 'Drawings',
    resolvedComment: 'ResolvedComment',
    resolved: 'Resolved',
    resolvedByUser: 'ResolvedByUser',
    resolvedDate: 'ResolvedDate',
    mainType: 'MainType',
    actionedByUser: 'ActionedBy',
    actionedDate: 'ActionedAt',
  },
};

export const tableConfig = [
  {
    title: 'MEASUREMENTS.ID',
    key: formConstants.fields.id,
    enableSort: true,
  },
  {
    title: 'MEASUREMENTS.NAME',
    key: formConstants.fields.name,
    enableSort: true,
    type: formConstants.fields.name,
  },
  {
    key: formConstants.fields.status,
    type: formConstants.fields.status,
    className: 'inline-right',
  },
];

export const sortingDirection = {
  asc: 'ASC',
  desc: 'DESC',
};

export const defaultPagingObj = {
  [filterParams.lastSeen]: 0,
  [filterParams.perPage]: 50,
  [filterParams.totalItems]: 0,
  [filterParams.hasNext]: false,
};
export const defaultSortingFilter = {
  [filterParams.sortByColumn]: formConstants.fields.id,
  [filterParams.sortDirection]: sortingDirection.asc,
};

export const tabNames = {
  details: 'DETAILS',
  history: 'HISTORY',
};

export const toolbarItems = {
  [tabNames.details]: {
    name: tabNames.details,
    label: 'MEASUREMENT_DETAILS.DETAILS_TAB',
  },
  [tabNames.history]: {
    name: tabNames.history,
    label: 'MEASUREMENT_DETAILS.HISTORY_TAB',
  },
};

export const historyFilterParams = {
  lastSeen: 'LastSeen',
  perPage: 'PerPage',
  sortByColumn: 'ModifiedAt',
  sortDirection: 'SortDirection',
  totalItems: 'TotalNumber',
  hasNext: 'HasNext',
};

export const historyDefaultPagingObj = {
  [historyFilterParams.lastSeen]: 0,
  [historyFilterParams.perPage]: 20,
  [historyFilterParams.totalItems]: 0,
  [historyFilterParams.hasNext]: false,
};

export const historyItems = {
  Name: {
    title: 'MEASUREMENT_HISTORY.MEASUREMENT_NAME',
  },
  Colour: {
    title: 'MEASUREMENT_HISTORY.MEASUREMENT_COLOUR',
    component: HistoryItemColorDisplay,
  },
  Geometry: {
    subProp: 'coordinates',
    title: 'COMPONENT_HISTORY.GEOMETRY',
    decimals: 2,
  },
  CameraPosition: {
    title: 'MEASUREMENT_HISTORY.MEASUREMENT_CAMERA_POSITION',
    decimals: 2,
  },
  Description: {
    title: 'MEASUREMENT_HISTORY.MEASUREMENT_DESCRIPTION',
  },
  Files: {
    title: 'MEASUREMENT_HISTORY.MEASUREMENT_FILE',
    isFile: true,
    component: HistoryItemFileDisplay,
  },
  Message: {
    title: 'MEASUREMENT_HISTORY.MEASUREMENT_MESSAGE',
    subProp: 'Message',
  },
};

export const measurementModificationEvents = {
  measurement_created: {
    title: 'MEASUREMENT_HISTORY.CREATED_EVENT',
    event: 'measurement_created',
  },
  measurement_deleted: {
    title: 'MEASUREMENT_HISTORY.DELETE_EVENT',
    event: 'measurement_deleted',
    titleClass: 'f-secondary-red',
  },
  measurement_updated: {
    title: 'MEASUREMENT_HISTORY.UPDATED_EVENT',
    event: 'measurement_updated',
  },
  measurement_position_updated: {
    title: 'MEASUREMENT_HISTORY.POSITION_UPDATED',
    event: 'measurement_position_updated',
  },
  measurement_file_unlinked: {
    title: 'MEASUREMENT_HISTORY.DELETED_EVENT',
    event: 'measurement_file_unlinked',
    titleClass: 'f-secondary-red',
  },
  measurement_message_added: {
    title: 'MEASUREMENT_HISTORY.MESSAGE_ADDED',
    event: 'measurement_message_added',
  },
  measurement_file_linked: {
    title: 'MEASUREMENT_HISTORY.UPLOADED_EVENT',
    event: 'measurement_file_linked',
  },
};
