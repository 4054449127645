export const pointImagesStep = 12;

export const defaultPotreId = 'potree_render_area';

export const defaultFilterOther = {
  LastSeen: 0,
  PerPage: 10,
  HasNext: true,
  TotalItems: 0,
  ContextualisationSelection: 'UNCONTEXTUALISED',
  OrderBy: 'CreatedAt',
};
