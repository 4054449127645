import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';

import { ReactComponent as CameraSvg } from '../../assets/camera-shutter.svg';

import Helpers from '../../../../common/helpers';
import { cameraChangedMessageDelay } from '../../constants/constants';

class SetCameraPosition extends Component {
  state = {
    cameraChanged: false,
  };

  setCameraPosition = () => {
    const { viewer, changeField, handleChange, formName, fieldName } = this.props;

    const currentCamPos = Helpers.getCameraPosition(viewer);
    const newCam = [currentCamPos.x, currentCamPos.y, currentCamPos.z];
    changeField(formName, fieldName, newCam);
    handleChange(newCam);
    this.setState({ cameraChanged: true });
    setTimeout(() => {
      this.setState({ cameraChanged: false });
    }, cameraChangedMessageDelay);
  };

  render() {
    const { isHidden, meta, disabled, viewer, horizontalAlign } = this.props;
    const { cameraChanged } = this.state;
    const { t } = this.context;
    return (
      <div className={`points-container${isHidden ? ' hidden-field' : ''} ${disabled || !viewer ? 'disabled' : ''}`}>
        <div className={`camera-container ${horizontalAlign === 'right' ? 'right-align' : 'left-align'}`}>
          {cameraChanged && <p className="f-secondary-green set-camera__text">{t('DEFECT_DETAILS.FORM_SET_CAMERA_POSITION_SET')}</p>}

          <div className="set-camera" onClick={this.setCameraPosition}>
            <CameraSvg className="set-camera__icon" />
            <p className="f-secondary-green set-camera__text">{t('DEFECT_DETAILS.FORM_SET_CAMERA')}</p>
          </div>
        </div>
        <div className={`error-message${meta && meta.error && meta.touched ? '--visible' : ''}`}>{meta && meta.error && meta.touched && t(meta.error.string, meta.error.params)}</div>
      </div>
    );
  }
}

SetCameraPosition.defaultProps = {
  horizontalAlign: 'right',
};

SetCameraPosition.propTypes = {
  horizontalAlign: PropTypes.oneOf(['right, left']),
};

SetCameraPosition.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  return { viewer: props.viewer || state.potreeReducer.viewerInstance };
};

const mapDispatchToProps = dispatch => ({ changeField: (formName, field, value) => dispatch(change(formName, field, value)) });

export default connect(mapStateToProps, mapDispatchToProps)(SetCameraPosition);
