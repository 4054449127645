import React from 'react';
import Icon from '../../../../common/icon/components/icon';
import '../../styles/ch-right-side-add-new-button.scss';

const AddNewPropertyButton = ({ text, onClick }) => {
  const handleClick = e => {
    e?.preventDefault();
    onClick && onClick();
  };
  return (
    <button className="add-new-prop-button" onClick={handleClick} type="button">
      <Icon size="sm" name="plus-circle" active />
      <p className="add-new-prop-button__text bold">{text}</p>
    </button>
  );
};
export default AddNewPropertyButton;
