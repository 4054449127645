import React from 'react';
import PropTypes from 'prop-types';

import FilesDisplay from './files-display';
import Icon from '../../../../../../../common/icon/components/icon';

import '../../../../../styles/files-manipulation.scss';

const FilesManipulation = ({ componentId, files = [], toggleUploadModal, handleDeleteFile, isInModal, handleScreenshotFrom3D }, { t }) => {
  return (
    <div className="files-items">
      <div className={`files-item-header ${isInModal && (!files || !files.length) ? 'files-item-header-separated' : ''}`}>
        <p className="f-secondary-dark bold">{t('ISOLATION_CERTIFICATE.FILES')}</p>
        <div className="files-item-actions">
          <div className="action-item take-screenshot-item" onClick={() => handleScreenshotFrom3D(componentId)}>
            <Icon name="camera" active />
            <p className="f-secondary-green">{t('ISOLATION_CERTIFICATE.TAKE_SCREENSHOT')}</p>
          </div>
          <div className="action-item" onClick={() => toggleUploadModal(componentId)}>
            <Icon name="documents" active />
            <p className="f-secondary-green">{t('ISOLATION_CERTIFICATE.UPLOAD_FILE')}</p>
          </div>
        </div>
      </div>
      <FilesDisplay componentId={componentId} files={files} handleDeleteFile={handleDeleteFile} withBorder />
    </div>
  );
};

FilesManipulation.contextTypes = {
  t: PropTypes.func.isRequired,
};

FilesManipulation.propTypes = {
  componentId: PropTypes.number.isRequired,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      DocumentURL: PropTypes.string,
      FileID: PropTypes.number,
      FileName: PropTypes.string,
      Key: PropTypes.string,
      ThumbnailURL: PropTypes.string,
      URL: PropTypes.string,
    })
  ),
  toggleUploadModal: PropTypes.func.isRequired,
  handleDeleteFile: PropTypes.func.isRequired,
};

export default FilesManipulation;
