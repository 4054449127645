import { values } from 'lodash';
import React from 'react';
import StatusesFilter from '../../../../../common/statuses-filter/components/statuses-filter';
import { filterSections, statuses } from '../../../constants/constants';

const DMSStatusesFilter = ({ quickFiltersChange, value }) => {
  const handleQuickFilter = values => {
    quickFiltersChange({ [filterSections.statuses.valueKey]: values });
  };
  return <StatusesFilter statuses={values(statuses)} onChange={handleQuickFilter} selected={value} />;
};

export default DMSStatusesFilter;
