import { firstStepFields } from '../components/permits/constants/constants';

export const validate = values => {
  const errors = {};
  const { name, description, color } = firstStepFields;

  if (!values[name.name]) {
    errors[name.name] = name.errorRequired;
  }

  if (values[name.name] && values[name.name].length < name.minChars) {
    errors[name.name] = name.errorMinChars;
  }

  if (values[description.name] && values[description.name].length > description.maxChars) {
    errors[description.name] = description.errorMaxChars;
  }

  if (!values[color.name]) {
    errors[color.name] = color.errorRequired;
  }

  return errors;
};
