import React, { Component } from 'react';
import { isEmpty } from 'lodash';

class DeleteComponentModal extends Component {
  render() {
    const { componentLinkedDefects, deleteText, deleteTextWithDefects } = this.props;

    return (
      <div>
        <p className="f-primary">{deleteText}</p>
        <br />
        {!isEmpty(componentLinkedDefects) && (
          <div>
            <p className="f-primary">{deleteTextWithDefects}</p>
            <ol>
              {componentLinkedDefects.map((item, index) => {
                return (
                  <li className="f-primary" key={index}>
                    {item.Name}
                  </li>
                );
              })}
            </ol>
          </div>
        )}
      </div>
    );
  }
}

export default DeleteComponentModal;
