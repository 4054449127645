import React, { Component, Fragment } from 'react';
import Dropzone from '../../../../common/upload/components/upload-dropzone';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { isEmpty } from 'lodash';

import UploadAssetsModal from '../../../project/components/upload-assets-modal';
import { setUploadItems } from '../../../upload/actions/upload-actions';

import { ReactComponent as pricing } from '../../../navigation/assets/pricing.svg';
// import { ReactComponent as Database } from '../../assets/database.svg';

import Button from '../../../../common/form/components/button';

import '../../styles/inspection-modal.scss';

// Legacy component we will delete this once we introduce the DMS
class UploadModal extends Component {
  confirmAction = () => {
    const { closeAction, setUploadItems } = this.props;

    setUploadItems(null);
    closeAction();
  };
  render() {
    const { t } = this.context;
    const { uploadFiles, uploadInProgress } = this.props;

    return (
      <div className="upload-modal inspection-modal noselect ">
        <div className="title">
          <h5>{t('INSPECTION_UPLOAD_MODAL.TITLE')}</h5>
        </div>
        <div className="options">
          {isEmpty(uploadFiles) && (
            <Fragment>
              {/* <div className="upload-box">
                <div className="external-upload" onClick={onExternalUpload}>
                  <Database />
                </div>
                <h6>{t('INSPECTION_UPLOAD_MODAL.FROM_DATABASE')}</h6>
              </div> */}
              <div className="upload-box">
                <Dropzone {...this.props} CustomImage={pricing} multiple={true} />
                <h6>{t('INSPECTION_UPLOAD_MODAL.FROM_LOCAL')}</h6>
              </div>
            </Fragment>
          )}
          {!isEmpty(uploadFiles) && (
            <div>
              <UploadAssetsModal />
              <Button onClick={this.confirmAction} disabled={uploadInProgress} type="button" text={t('OK')} className="modal__button" />
            </div>
          )}
        </div>
      </div>
    );
  }
}
UploadModal.contextTypes = {
  t: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  uploadFiles: state.uploadReducer.uploadTmpFiles,
  uploadInProgress: state.uploadReducer.uploadInProgress,
});

const mapDispatchToProps = dispatch => ({
  setUploadItems: assets => dispatch(setUploadItems(assets)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadModal);
