import React from 'react';
import PropTypes from 'prop-types';

import SmallDropdown from '../../../common/small-dropdown/components/small-dropdown';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../common/permissions-constants';
import { fields } from '../constants/constants';

const KeyOptions = ({ item, openConfirmationPopup, returnKey, openIssueKeyPopup }, { t }) => {
  let menuOptions = [
    {
      title: 'KEY_ACTIONS.ISSUE',
      action: () => openIssueKeyPopup(item),
      access: {
        visibleFor: PERMISSIONS[PERMISSION_TYPES.keybox].manage.name,
      },
      isHidden: !!item.IssuedTo,
    },
    {
      title: 'KEY_ACTIONS.RETURN',
      action: () =>
        openConfirmationPopup(t('KEY_POPUP.TITLE'), t('KEY_POPUP.DESC', { key: item[fields.name] }), closeAction => {
          returnKey(item[fields.id], closeAction);
        }),
      access: {
        visibleFor: PERMISSIONS[PERMISSION_TYPES.keybox].manage.name,
      },
      isHidden: !item.IssuedTo,
    },
  ];

  return (
    <SmallDropdown
      className="page-navigation__wrapper__title-container__menu__dropdown"
      actionsMenu={menuOptions}
      getClickAction={(action, b, selfClose, e) => {
        e?.preventDefault && e.preventDefault();
        selfClose();
        if (action) {
          if (typeof action === 'function') {
            action();
          }
        }
      }}
      offsetX={10}
    />
  );
};

KeyOptions.contextTypes = {
  t: PropTypes.func.isRequired,
};

KeyOptions.propTypes = {
  item: PropTypes.shape({
    IssuedTo: PropTypes.string.isRequired,
  }).isRequired,
};

export default KeyOptions;
