import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, pick, findIndex } from 'lodash';
import SmallDropdown from '../../../common/small-dropdown/components/small-dropdown';
import Button from '../../../common/form/components/button';
import EditPdfForm from './edit-pdf-form';
import Helpers from '../../../common/helpers';
import routesConstants from '../../../common/routes-constants';
import constants from '../constants/constants';
import { fields, filterProps } from '../../document-management/constants/constants';
import { connect } from 'react-redux';
import { setTableData } from '../actions/action-creators';
import { itemActions, actionsMenu } from '../constants/dropdown-menu';

const TableDropdown = ({ data, inspectionId, projectId, itemIndex, tableData, setTableData, deleteDMFile, editPDF, deletePDF, setModalState }, { t }) => {
  const openEditPDFModal = useCallback(
    (pdfItem, index) => {
      const formValues = {
        ...pdfItem,
        [constants.fields.revisionDate]: pdfItem[constants.fields.revisionDate] > 0 ? new Date(pdfItem[constants.fields.revisionDate] * 1000) : null,
      };
      setModalState({
        modalData: {
          title: t('EDIT_PDF_MODAL.TITLE'),
          CustomContent: () => (
            <EditPdfForm
              initialValues={formValues}
              onSubmit={params => {
                params = {
                  ...params,
                  [constants.fields.revisionDate]: params[constants.fields.revisionDate] ? Helpers.getUnixDate(params[constants.fields.revisionDate].getTime()) : 0,
                };
                params = pick(params, [constants.fields.id, constants.fields.title, constants.fields.revision, constants.fields.revisionDate, constants.fields.drawingNumber]);

                editPDF(index, params, () => setModalState({ modalData: { isOpen: false } }));
              }}
              onCancel={() => setModalState({ modalData: { isOpen: false } })}
            />
          ),
          isOpen: true,
          type: '',
          customClassName: 'edit-pdf-modal',
          closeAction: () => setModalState({ modalData: { isOpen: false } }),
        },
      });
    },
    [t, setModalState, editPDF]
  );

  const goToTag = useCallback(() => {
    Helpers.goTo(routesConstants.routes.protectedRoutes.pdfTag.fullPath, [{ inspection_id: inspectionId }, { project_id: projectId }, { file_id: data.FileID }]);
  }, [inspectionId, projectId, data]);

  const getClickAction = useCallback(
    (action, _, closeSettings) => {
      if (action === itemActions.view) {
        goToTag();
      } else if (action === itemActions.download) {
        Helpers.downloadFile(data.URL, '.pdf', data.FileName);
      } else if (action === itemActions.delete) {
        const onSuccessfulDelete = () => {
          const newTableData = Object.assign([], tableData);
          newTableData.splice(
            findIndex(tableData, file => file.FileID === data.FileID),
            1
          );
          setTableData(constants.sections.pAndId.name, newTableData);
        };
        deleteDMFile({ [filterProps.sourceID]: data[fields.fileId], FileName: data[fields.fileName] }, onSuccessfulDelete);
      } else if (action === itemActions.edit) {
        openEditPDFModal(data, itemIndex);
      }
      closeSettings();
    },
    [goToTag, data, itemIndex, tableData, setTableData, deleteDMFile, openEditPDFModal]
  );

  return (
    <div className="actions">
      <Button text={t('EDIT_PDF.TAG')} type="button" onClick={goToTag} variant="warning" width="sm" />
      <SmallDropdown className={isEmpty(actionsMenu) ? 'disabled' : ''} actionsMenu={actionsMenu} getClickAction={getClickAction} offsetX={-150} />
    </div>
  );
};

TableDropdown.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  setTableData: (section, data) => dispatch(setTableData(section, data)),
});

export default connect(null, mapDispatchToProps)(TableDropdown);
