import React from 'react';

const SingleItem = props => {
  const {
    item,
    onItemClick,
    selectedItem,
    hoveredItem,
    renderItemRightComponent,
    index,
    customSelectedFunction,
    customHoveredFunction,
    customIdentificatorFunction,
    cutomNameFunction,
    renderLeftComponent,
  } = props;

  let isActive,
    isHovered = false;
  let customIdentificator = '';
  let name = '';

  if (customSelectedFunction) {
    isActive = customSelectedFunction(item);
  } else {
    isActive = selectedItem && selectedItem.ID === item.ID;
  }

  if (customHoveredFunction) {
    isHovered = customHoveredFunction(item);
  } else {
    isHovered = hoveredItem && hoveredItem.ID === item.ID;
  }

  if (customIdentificatorFunction) {
    customIdentificator = customIdentificatorFunction(item, index);
  } else {
    customIdentificator = `comp-${item.ID}`;
  }

  if (cutomNameFunction) {
    name = cutomNameFunction(item);
  } else {
    name = item.Name;
  }

  return (
    <div onClick={e => onItemClick(e, item)} className={`item ${isActive ? 'selected' : ''} ${isHovered ? 'hovered' : ''} ${customIdentificator}`}>
      {renderLeftComponent && renderLeftComponent(item, index)}
      <p title={name} className={`${item.visible ? 'f-primary' : 'f-secondary-dark'} noselect`}>
        {name}
      </p>
      {renderItemRightComponent && renderItemRightComponent(item, index)}
    </div>
  );
};

export default SingleItem;
