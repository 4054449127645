import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ResetPasswordForm from './reset-password-form';
import RequestResetPasswordForm from './request-reset-password-form';
import BasicWrapper from '../../shared/common-component/components/basic-wrapper';
import { resetPassword, requestUserReset } from '../actions/reset-password-actions';
import Modal from '../../../common/modal/components/modal';

import setPassConsts from '../constants/set-password-constants';

class ResetPassword extends Component {
  state = {
    token: null,
    modalData: {
      isOpen: false,
    },
  };
  openResetPasswordModal = () => {
    const { t } = this.context;
    this.setState(prevProps => ({
      modalData: {
        ...prevProps.modalData,
        isOpen: true,
        content: t('RESET_PASSWORD.CHECK_EMAIL'),
        confirmAction: this.closeResetPasswordModal,
        closeAction: this.closeResetPasswordModal,
      },
    }));
  };
  closeResetPasswordModal = () => {
    this.setState(prevProps => ({
      modalData: {
        ...prevProps.modalData,
        isOpen: false,
      },
    }));
  };

  resetUserPass = values => {
    const { resetPassword } = this.props;
    let { query } = this.props.location;
    const Token = query && query.token;

    //hack as set and reset use the same Form
    values[setPassConsts.fields.termsAndConditionsAccepted] = true;
    resetPassword({ ...values, Token });
  };
  requestUserReset = values => {
    const { requestUserReset } = this.props;
    requestUserReset(values, this.openResetPasswordModal);
  };
  componentDidMount = () => {
    const { query } = this.props.location;
    const token = query && query.token;
    this.setState({ token });
  };

  render() {
    const { token, modalData } = this.state;

    return (
      <BasicWrapper>
        {token && <ResetPasswordForm onSubmit={this.resetUserPass} />}
        {!token && <RequestResetPasswordForm onSubmit={this.requestUserReset} />}
        <Modal {...modalData} />
      </BasicWrapper>
    );
  }
}

const mapStateToProps = state => ({
  // disabledForm: state.passwordResetReducer.passwordResetDisabled,
});

const mapDispatchToProps = dispatch => ({
  resetPassword: values => dispatch(resetPassword(values)),
  requestUserReset: (values, openResetPasswordModal) => dispatch(requestUserReset(values, openResetPasswordModal)),
});

ResetPassword.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
