import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { createNumberMask } from 'redux-form-input-masks';

import CheckBox from '../../../../common/form/components/checkbox';
import CustomInput from '../../../../common/form/components/input';
import CustomSelect from '../../../../common/form/components/select';
import DatePicker from '../../../../common/form/components/date-picker';
import RadioButton from '../../../../common/form/components/radio-button';
import { formConstants, intervalFields, endCheckBoxValues } from '../../constants/regime-constants';

const ScheduleRegimeForm = ({ isPreviewMode, formValues, intervalList, handleEndsChange, handleOnOrAfterChange }, { t }) => {
  const numberMask = createNumberMask({
    decimalPlaces: 0,
    allowNegative: false,
  });
  return (
    <>
      <div className="grouped-input">
        <Field
          disabledKeyboardNavigation={true}
          id={formConstants.fields.startDate}
          name={formConstants.fields.startDate}
          component={DatePicker}
          minDate={new Date()}
          popperPlacement="bottom-end"
          label={t('CREATE_REGIME_FORM.START_DATE_LABEL')}
          placeholder={t('CREATE_REGIME_FORM.START_DATE_LABEL')}
          disabled={isPreviewMode}
          validateUsingReduxForm
          isRequired
          dateFormat="MMMM d, yyyy h:mm aa"
          timeFormat="h:mm aa"
          showTimeSelect
        />
      </div>
      <div className="default-checkbox-container">
        <Field
          id={formConstants.fields.recurringTask}
          name={formConstants.fields.recurringTask}
          labelClass="f-secondary-dark"
          component={CheckBox}
          checkboxText={'CREATE_REGIME_FORM.RECURRING_TASK_LABEL'}
          disabled={isPreviewMode}
          onChange={handleEndsChange}
        />
      </div>
      <div className={`recurring-task-details ${!formValues[formConstants.fields.recurringTask] ? 'recurring-task-details-disable' : ''}`}>
        <div className="double">
          <div className="split-input">
            <div className="double">
              <div className="split-input">
                <Field
                  id={formConstants.fields.intervalNumber}
                  name={formConstants.fields.intervalNumber}
                  component={CustomInput}
                  placeholder={t('CREATE_REGIME_FORM.REPEAT_EVERY')}
                  label={t('CREATE_REGIME_FORM.REPEAT_EVERY')}
                  type="tel"
                  size="lg"
                  isRequired
                  disabled={isPreviewMode || !formValues[formConstants.fields.recurringTask]}
                  {...numberMask}
                />
              </div>
              <div className="split-input">
                <Field
                  id={formConstants.fields.taskIntervalId}
                  name={formConstants.fields.taskIntervalId}
                  data={intervalList}
                  component={CustomSelect}
                  valueField={intervalFields.id}
                  textField={intervalFields.name}
                  className="select-d"
                  label={t('CREATE_REGIME_FORM.PERIOD')}
                  size="lg"
                  disabled={isPreviewMode || !formValues[formConstants.fields.recurringTask]}
                  isRequired
                />
              </div>
            </div>
          </div>
        </div>
        <div className="field-wrapper ends-label">
          <label className="field-wrapper__label">{t('CREATE_REGIME_FORM.ENDS_LABEL')}</label>
        </div>
        <div className="double">
          <div className="split-input">
            <div className="double">
              <div className="split-input radio-button-container">
                <Field
                  name={formConstants.fields.ends}
                  component={RadioButton}
                  value={endCheckBoxValues.on}
                  type="radio"
                  id={`${formConstants.fields.ends}`}
                  label={t('CREATE_REGIME_FORM.ON')}
                  disabled={isPreviewMode || !formValues[formConstants.fields.recurringTask]}
                  onChange={() => handleOnOrAfterChange(endCheckBoxValues.on)}
                />
              </div>
              <div className="split-input with-hidden-error-message">
                <Field
                  disabledKeyboardNavigation={true}
                  dateFormat="MMMM d, yyyy"
                  id={formConstants.fields.endDate}
                  name={formConstants.fields.endDate}
                  component={DatePicker}
                  minDate={new Date()}
                  popperPlacement="bottom-end"
                  disabled={isPreviewMode || !formValues[formConstants.fields.recurringTask] || formValues[formConstants.fields.ends] === endCheckBoxValues.off}
                  validateUsingReduxForm
                  isRequired
                  hideCalendarIcon={isPreviewMode || !formValues[formConstants.fields.recurringTask] || formValues[formConstants.fields.ends] === endCheckBoxValues.off}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="double after-inputs-container">
          <div className="split-input">
            <div className="double">
              <div className="split-input radio-button-container">
                <Field
                  name={formConstants.fields.ends}
                  component={RadioButton}
                  value={endCheckBoxValues.off}
                  type="radio"
                  id={`${formConstants.fields.ends}`}
                  label={t('CREATE_REGIME_FORM.AFTER')}
                  disabled={isPreviewMode || !formValues[formConstants.fields.recurringTask]}
                  onChange={() => handleOnOrAfterChange(endCheckBoxValues.off)}
                />
              </div>
              <div className="split-input with-hidden-error-message with-absolute-error-message">
                <Field
                  id={formConstants.fields.occurancesTreshold}
                  name={formConstants.fields.occurancesTreshold}
                  component={CustomInput}
                  placeholder={t('CREATE_REGIME_FORM.OCCURANCES_LABEL')}
                  type="number"
                  size="lg"
                  isRequired
                  disabled={isPreviewMode || !formValues[formConstants.fields.recurringTask] || formValues[formConstants.fields.ends] === endCheckBoxValues.on}
                  {...numberMask}
                />
              </div>
            </div>
          </div>
          <div className="split-input">
            <div className="field-wrapper">
              <label className="field-wrapper__label label-transformation">{t('CREATE_REGIME_FORM.OCCURANCES')}</label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ScheduleRegimeForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ScheduleRegimeForm;
