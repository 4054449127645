export const colorPalette = {
  green: '#01f6a5',
  lightBlue: '#0dcfda',
  blue: '#419bf9',
  purple: '#db7cee',
  yellow: '#fecf33',
  lightOrange: '#f69833',
  red: '#cc0033',
};

export const defaultColor = Object.keys(colorPalette)[0];
