import React from 'react';
import RenderIf from '../../../../../common/render-if/components/render-if';
import InfoTooltip from '../../../../../common/tooltip/components/info-tooltip';
import { statuses, fields } from '../../observations/constants/constants';
import { observationsTypesIconsMap, observationsTypesTranslationsMap } from '../../../constants/defect-constants';
import AddObservationPopover from './add-observation-popover';
import Icon from '../../../../../common/icon/components/icon';
import PropTypes from 'prop-types';

const getStatusProps = status => statuses[status?.toLowerCase()];

const ObservationItemRow = ({ item, index, isDropdown, action }, { t }) => {
  const status = getStatusProps(item[fields.status]);
  return (
    <div className={`observation-item-row ${isDropdown ? '' : 'added-item'}`} id={`row-${index}`} onClick={isDropdown ? () => action(item) : null}>
      <div className="id-cell">
        <RenderIf if={index === 0}>
          <p className="column-header user-title bold mb-8">{t('ID')}</p>
        </RenderIf>
        <p className="light-bold">{item[fields.id]}</p>
      </div>
      <div className="name-cell">
        <RenderIf if={index === 0}>
          <p className="column-header user-title bold mb-8">{t('NAME')}</p>
        </RenderIf>
        <div className="name-cell__content">
          <p className="light-bold three-dot-text">{item[fields.name]}</p>
          <InfoTooltip
            customComponent={<AddObservationPopover observation={item} />}
            offsetY={8}
            offsetX={8}
            Component={() => <Icon name="info" size="sm" />}
            componentProps={{ title: '' }}
            containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
          />
        </div>
      </div>
      <div className="type-cell">
        <RenderIf if={index === 0}>
          <p className="column-header user-title bold mb-8">{t('DISPLAY_PROPS.TYPE')}</p>
        </RenderIf>
        <div className="type-cell__content">
          <Icon name={observationsTypesIconsMap[item[fields.type]]} handleHover={false} size="sm" />
          <p>{t(observationsTypesTranslationsMap[item[fields.type]])}</p>
        </div>
      </div>
      <div className={`status-cell ${isDropdown ? '' : 'has-unlink'}`}>
        <RenderIf if={index === 0}>
          <p className="column-header user-title bold mb-8">{t('OBSERVATIONS.STATUS')}</p>
        </RenderIf>
        <div className="status-cell__content">
          <Icon name={status.icon} {...status.iconProps} size="sm" />
          <p>{t(status.title)}</p>
        </div>
      </div>
      <RenderIf if={!isDropdown}>
        <div className={`unlink-cell ${index === 0 ? 'first' : ''}`}>
          <Icon size="sm" name="close" onClick={() => action(item)} />
        </div>
      </RenderIf>
    </div>
  );
};

ObservationItemRow.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ObservationItemRow;
