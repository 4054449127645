import { debounce, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import AddZoneArea from '../../../app/inspections/components/right-toolbar/add-zone-area';
import InspectionView from '../../../app/inspections/potree/components/main-view';
import WorkAreas from './work-areas';

import { getInspectionDetails } from '../../../app/inspections/actions/inspection-actions';
import Helpers from '../../helpers';

import { measurementTypes } from '../../../app/inspections/constants/constants';
import { modelTypes } from '../../../app/inspections/constants/explosive-zones-constants';
import { measurementDropdownActions } from '../../../app/inspections/constants/measurement-constants';
import { formConstants } from '../constants/constants';

import EmptyState from '../../empty-state-v2/components/empty-state';
import '../styles/work-areas-modal.scss';

const WorkAreasModal = (props, { t }) => {
  const [viewerInstance, setViewerInstance] = useState(null);
  const [currentInsertion, setCurrentInsertion] = useState(null);
  const [isAddingLocation, setIsAddingLocation] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const parentRef = useRef(null);

  const updateWorkArea = (data, dataCallback) => {
    // TODO: this might be an issue, check why does addWorkArea reflect on the main view immediately
    // should startShapeInsertion also be used?
    const { handleWorkAreaUpdate, question } = props;
    handleWorkAreaUpdate(question[formConstants.fields.id], data, dataCallback);
  };
  const formChangeDebounce = useRef(debounce(updateWorkArea, 300)).current;

  useEffect(() => {
    const { inspectionId } = props;
    props.getInspectionDetails(inspectionId);

    return () => {
      formChangeDebounce.cancel();
    };
    // TODO: discuss with Tarik Dj. to see why props was in the dependency array of this useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formChangeDebounce]);

  const createViewerInstance = newInstance => {
    setViewerInstance(newInstance);
  };

  const startShapeInsertion = (shapeType, element, onFinish) => {
    if (viewerInstance) {
      const shapeTool = new window.Potree.ShapeTool(viewerInstance);

      if (currentInsertion !== null) {
        currentInsertion.removeEventListener('finish_inserting_shape', e => onShapeInsertionFinish(e, onFinish));
      }
      // Start insertion
      let measurement = shapeTool.startInsertion({
        shapeType,
        modelDetails: element.ModelDetails,
        name: 'Shape',
      });
      shapeTool.addEventListener('finish_inserting_shape', e => onShapeInsertionFinish(e, onFinish));
      measurement.addEventListener('finish_inserting_shape', e => onShapeInsertionFinish(e, onFinish));
      setCurrentInsertion(measurement);
    }
  };

  const getCameraPosition = () => {
    const { inspectionDetails } = props;
    const defaultCameraPosition =
      inspectionDetails && inspectionDetails.CameraPosition && !isEmpty(inspectionDetails.CameraPosition.coordinates) ? inspectionDetails.CameraPosition.coordinates : undefined;
    return Helpers.getCameraPosition(viewerInstance, defaultCameraPosition);
  };

  const onShapeInsertionFinish = (e, callback) => {
    const { inspectionId } = props;
    setCurrentInsertion(null);
    const camPos = getCameraPosition();
    const type = e.shape.shapeType;
    let body = {
      InspectionID: parseInt(inspectionId),
      CameraPosition: {
        coordinates: [camPos.x, camPos.y, camPos.z],
      },
      Geometry: {
        type: type,
        coordinates: [[e.shape.position.x, e.shape.position.y, e.shape.position.z]],
      },
      ModelDetails: e.shape.modelDetails,
    };

    callback(body);
    viewerInstance.scene.removeShape(e.shape);
    e.target.removeEventListener('finish_inserting_shape', e => onShapeInsertionFinish(e, callback));
    e.shape.removeEventListener('finish_inserting_shape', e => onShapeInsertionFinish(e, callback));
  };

  const startWorkAreaInsertion = (action, onFinish) => {
    if (viewerInstance) {
      const measuringTool = new window.Potree.MeasuringTool(viewerInstance);

      if (currentInsertion !== null) {
        currentInsertion.removeEventListener('finish_inserting_measurement', e => onWorkAreaInsertionFinish(e, onFinish));
      }
      if (action === measurementDropdownActions.point) {
        let measurement = measuringTool.startInsertion({
          showDistances: false,
          showAngles: false,
          showCoordinates: true,
          showArea: false,
          closed: true,
          maxMarkers: 1,
          coordinatesText: 'New Area',
          systemType: measurementTypes.measurement,
          name: 'Point',
        });
        measuringTool.addEventListener('finish_inserting_measurement', e => onWorkAreaInsertionFinish(e, onFinish));
        measurement.addEventListener('finish_inserting_measurement', e => onWorkAreaInsertionFinish(e, onFinish));
        setCurrentInsertion(measurement);
      } else if (action === measurementDropdownActions.area) {
        let measurement = measuringTool.startInsertion({
          showDistances: true,
          showArea: true,
          closed: true,
          coordinatesText: 'New Area',
          systemType: measurementTypes.measurement,
          name: 'Area',
        });
        measuringTool.addEventListener('finish_inserting_measurement', e => onWorkAreaInsertionFinish(e, onFinish));
        measurement.addEventListener('finish_inserting_measurement', e => onWorkAreaInsertionFinish(e, onFinish));
        setCurrentInsertion(measurement);
      } else if (action === measurementDropdownActions.distance) {
        let measurement = measuringTool.startInsertion({
          showDistances: true,
          showArea: false,
          closed: false,
          coordinatesText: 'New Area',
          systemType: measurementTypes.measurement,
          name: 'Distance',
        });
        measuringTool.addEventListener('finish_inserting_measurement', e => onWorkAreaInsertionFinish(e, onFinish));
        measurement.addEventListener('finish_inserting_measurement', e => onWorkAreaInsertionFinish(e, onFinish));
        setCurrentInsertion(measurement);
      } else if (action === measurementDropdownActions.circle) {
        let measurement = measuringTool.startInsertion({
          showDistances: true,
          showArea: false,
          showCircle: true,
          closed: false,
          maxMarkers: 2,
          coordinatesText: 'New circle',
          systemType: measurementTypes.measurement,
          name: 'Circle',
        });
        measuringTool.addEventListener('finish_inserting_measurement', e => onWorkAreaInsertionFinish(e, onFinish));
        measurement.addEventListener('finish_inserting_measurement', e => onWorkAreaInsertionFinish(e, onFinish));
        setCurrentInsertion(measurement);
      } else return;
    }
  };

  const onWorkAreaInsertionFinish = (e, callback) => {
    const { inspectionId } = props;
    setCurrentInsertion(null);
    if (isEmpty(e.measure.points)) {
      e.target.removeEventListener('finish_inserting_measurement', e => onWorkAreaInsertionFinish(e, callback));
      e.measure.removeEventListener('finish_inserting_measurement', e => onWorkAreaInsertionFinish(e, callback));
      viewerInstance.scene.removeMeasurement(e.measure);
      return;
    }
    const camPos = getCameraPosition();
    const type = Helpers.getDefectType(e.measure.name);
    let body = {
      InspectionID: parseInt(inspectionId),
      CameraPosition: {
        coordinates: [camPos.x, camPos.y, camPos.z],
      },
      Geometry: {
        type: type,
        coordinates: [...e.measure.points.map(point => [point.position.x, point.position.y, point.position.z])],
      },
    };

    callback(body);

    viewerInstance.scene.removeMeasurement(e.measure);
    e.target.removeEventListener('finish_inserting_measurement', e => onWorkAreaInsertionFinish(e, callback));
    e.measure.removeEventListener('finish_inserting_measurement', e => onWorkAreaInsertionFinish(e, callback));
  };

  const handleAddWorkArea = (vals, modelType, dropdownAction2D = measurementDropdownActions.area) => {
    // TODO: this flow might be used to fix update WA, check why does addWorkArea reflect on the main view immediately

    const { handleWorkAreaAdd, question } = props;
    if (!question) return;
    setIsAddingLocation(true);
    if (modelType === modelTypes._2D) {
      startWorkAreaInsertion(dropdownAction2D, newBody => {
        const data = { ...vals, ...newBody, [formConstants.fields.modelType]: modelType };
        handleWorkAreaAdd(question[formConstants.fields.id], data);
        setIsAddingLocation(false);
        setIsAdding(false);
      });
    } else {
      const { Geometry, ...shapePayload } = vals;
      if (Geometry.type) {
        startShapeInsertion(Geometry.type, shapePayload, newBody => {
          const data = { ...newBody, ...shapePayload, [formConstants.fields.modelType]: modelType };
          handleWorkAreaAdd(question[formConstants.fields.id], data);
          setIsAddingLocation(false);
          setIsAdding(false);
        });
      }
    }
  };

  const handleAddWorkAreaStart = () => {
    const currentCamera = Helpers.getCameraPosition(viewerInstance);
    const newCamPos = [currentCamera.x, currentCamera.y, currentCamera.z];
    setIsAdding(true);
    setInitialValues(prevValues => ({ ...prevValues, CameraPosition: { coordinates: newCamPos } }));
  };

  const renderSidebar = () => {
    if (isEmpty(props.question?.QuestionAreas) && !isAdding) {
      return (
        <React.Fragment>
          <h5 className="f-primary h-container-padding v-container-padding">{t('WORK_AREAS.EDIT_TITLE')}</h5>
          <div>
            <EmptyState
              emptyStateText={t('WORK_AREAS.EMPTY_STATE_TEXT')}
              showButton
              transparent
              buttonText={t('ADD_ZONE_AREA.FORM_ADD_ZONE_AREA')}
              buttonAction={handleAddWorkAreaStart}
              buttonDisabled={props.viewOnly}
            />
          </div>
        </React.Fragment>
      );
    }
    return (
      <>
        <h5 className="f-primary h-container-padding v-container-padding">{!isAdding ? t('WORK_AREAS.EDIT_TITLE') : t('WORK_AREAS.TITLE')}</h5>
        {!props.viewOnly && (
          <AddZoneArea
            canCreate={true}
            nameField={formConstants.fields.name}
            handleSubmit={handleAddWorkArea}
            isAddingLocation={isAddingLocation}
            showOnly2D={props.showOnly2D}
            setIsAddingLocation={setIsAddingLocation}
            viewer={viewerInstance}
            addZoneAreaStart={handleAddWorkAreaStart}
            isAdding={isAdding}
            setIsAdding={setIsAdding}
            initialValues={initialValues}
          />
        )}

        <WorkAreas
          elements={props.question?.QuestionAreas || []}
          handleItemClick={() => null}
          viewOnly={props.viewOnly}
          viewer={viewerInstance}
          handleWorkAreaUpdate={formChangeDebounce}
          handleDeleteWorkArea={workArea => props.handleDeleteWorkArea(props.question[formConstants.fields.id], workArea)}
          backButtonText={props.backButtonText}
          showOnly2D={props.showOnly2D}
          isAdding={isAdding}
        />
      </>
    );
  };

  return (
    <div className="work-areas-modal">
      <InspectionView
        parentRef={parentRef}
        isWorkflow={false}
        potreeId={'work-areas-potree'}
        createViewerInstance={createViewerInstance}
        viewer={viewerInstance}
        showCameras={false}
        showScreenToolbox
      />
      <div className="sidebar">{renderSidebar()}</div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  getInspectionDetails: inspectionId => dispatch(getInspectionDetails(inspectionId)),
});

WorkAreasModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(WorkAreasModal);
