import actionTypes from '../constants/action-types';

import ReducerHelpers from '../../../common/reducer-helpers';

const rolesState = {
  rolesData: null,
  rolesLoading: false,
};

export const rolesTableReducer = (state = rolesState, payload) => {
  switch (payload.type) {
    case actionTypes.SET_ROLES:
      return { ...state, rolesData: payload.data };
    case actionTypes.SET_ROLE_LOADING:
      return { ...state, rolesLoading: payload.data };
    case actionTypes.AMEND_ROLES:
      return { ...state, rolesData: [...state.rolesData, ...payload.data] };
    case actionTypes.UPDATE_ROLE_BY_PROP: {
      return { ...state, rolesData: ReducerHelpers.updateItemInListByProp(state.rolesData, payload.data.item, payload.data.prop) };
    }
    case actionTypes.HANDLE_ADD_ROLE: {
      return {
        ...state,
        rolesData: [payload.data, ...state.rolesData],
      };
    }
    case actionTypes.REMOVE_ROLE_BY_PROP:
      return {
        ...state,
        rolesData: ReducerHelpers.removeItemByProp(state.rolesData, payload.data.item, payload.data.prop),
      };
    default:
      return state;
  }
};
