import { questionTypes } from '../../../../../common/question-components/constants/question-constants';

export const firstStepFields = {
  name: {
    name: 'Name',
    label: 'PERMIT_TEMPLATE.NAME_LABEL',
    placeholder: 'PERMIT_TEMPLATE.NAME_PLACEHOLDER',
    errorRequired: 'PERMIT_TEMPLATE.NAME_ERROR_REQUIRED',
    errorMinChars: 'PERMIT_TEMPLATE.NAME_ERROR_MIN_CHARS',
    minChars: 3,
  },
  description: {
    name: 'Description',
    label: 'PERMIT_TEMPLATE.DESCRIPTION_LABEL',
    placeholder: 'PERMIT_TEMPLATE.DESCRIPTION_PLACEHOLDER',
    maxChars: 2000,
    errorMaxChars: 'PERMIT_TEMPLATE.DESCRIPTION_ERROR_MAX_CHARS',
  },
  color: {
    name: 'Colour',
    label: 'PERMIT_TEMPLATE.COLOR_LABEL',
    errorRequired: 'PERMIT_TEMPLATE.COLOR_REQUIRED',
  },
  signatureRequired: {
    name: 'SignatureRequired',
    label: 'PERMIT_TEMPLATE.SIGNATURE_REQUIRED_LABEL',
  },
};

export const steps = {
  firstStep: '1',
  secondStep: '2',
};

export const stepperData = [
  {
    name: 'PERMIT_TEMPLATE.FIRST_STEP',
    stepValue: steps.firstStep,
  },
  {
    name: 'PERMIT_TEMPLATE.SECOND_STEP',
    stepValue: steps.secondStep,
  },
];

export const fields = {
  id: 'ID',
  status: 'Status',
  projectId: 'ProjectID',
  name: 'Name',
  description: 'Description',
  color: 'Colour',
  createdByName: 'CreatedByName',
  createdBy: 'CreatedBy',
  createdAt: 'CreatedAt',
  actionMenu: 'ActionMenu',
  permitTemplateId: 'PermitTemplateID',
};

export const statuses = {
  draft: {
    value: 'DRAFT',
    title: 'CHECKLISTS_PROCEDURES_LIST.DRAFT_STATUS',
    icon: 'clock',
  },
  raised: {
    value: 'RAISED',
    title: 'CHECKLISTS_PROCEDURES_LIST.RAISED_STATUS',
    icon: 'clock-filled',
    iconClass: 'checklists__table-wrapper__table__icon-grey',
  },
  live: {
    value: 'LIVE',
    title: 'CHECKLISTS_PROCEDURES_LIST.LIVE_STATUS',
    icon: 'checkmark',
    iconProps: {
      active: true,
    },
    iconClass: 'checklists__table-wrapper__table__icon-green',
  },
};

export const defaultColor = '#8E919B';

export const permitTemplateInitialValues = {
  [firstStepFields.name.name]: '',
  [firstStepFields.description.name]: '',
  [firstStepFields.color.name]: defaultColor,
  [firstStepFields.signatureRequired.name]: false,
};

export const tableConfig = [
  {
    title: 'PERMIT_TEMPLATES_LIST.ID',
    key: fields.id,
    enableSort: true,
    class: 'id-column',
  },
  {
    title: 'PERMIT_TEMPLATES_LIST.NAME',
    key: fields.name,
    enableSort: true,
  },
  {
    title: 'PERMIT_TEMPLATES_LIST.COLOR',
    key: fields.color,
    enableSort: true,
    type: fields.color,
  },
  {
    title: 'PERMIT_TEMPLATES_LIST.DESCRIPTION',
    key: fields.description,
    enableSort: false,
    type: fields.description,
  },
  {
    title: 'PERMIT_TEMPLATES_LIST.STATUS',
    key: fields.status,
    enableSort: true,
    type: fields.status,
  },
  {
    title: 'PERMIT_TEMPLATES_LIST.CREATOR',
    key: fields.createdByName,
    sortKey: fields.createdBy,
    enableSort: true,
  },
  {
    title: 'PERMIT_TEMPLATES_LIST.CREATED_DATE',
    key: fields.createdAt,
    enableSort: true,
    type: 'date',
  },
  {
    title: '',
    type: fields.actionMenu,
    enableSort: false,
    class: 'checklists__table-wrapper__table__inline-right',
    className: 'checklists__table-wrapper__table__inline-flex-right',
  },
];

export const sortDirection = {
  asc: 'ASC',
  desc: 'DESC',
};

export const filterProps = {
  sortByColumn: 'SortByColumn',
  sortDirection: 'SortDirection',
  perPage: 'PerPage',
  lastSeen: 'LastSeen',
  searchText: 'SearchText',
  hasNext: 'HasNext',
  totalItems: 'TotalItems',
};

export const defaultFilters = {
  [filterProps.sortByColumn]: fields.id,
  [filterProps.sortDirection]: sortDirection.asc,
  [filterProps.perPage]: 20,
  [filterProps.lastSeen]: 0,
  [filterProps.searchText]: '',
  [filterProps.hasNext]: false,
};

export const confirmationModalTypes = {
  duplicate: 'duplicate',
  delete: 'delete',
  changeStatus: 'change-status',
};

export const confirmationModalContent = {
  [confirmationModalTypes.duplicate]: {
    content: 'PERMIT_TEMPLATES_LIST.DUPLICATE_ACTION',
  },
  [confirmationModalTypes.delete]: {
    content: 'PERMIT_TEMPLATES_LIST.DELETE_ACTION',
  },
  [confirmationModalTypes.changeStatus]: {
    content: 'PERMIT_TEMPLATES_LIST.CHANGE_STATUS_ACTION',
  },
};

export const permitTemplateSections = [
  {
    Name: 'Details',
    nonTranslatable: true,
    lockedQuestions: [
      {
        name: 'Details_Name',
        isLocked: true,
        type: questionTypes.TEXT.value,
        label: 'PERMIT_TEMPLATE.DETAILS_NAME',
        placeholder: 'PERMIT_TEMPLATE.DETAILS_NAME',
        props: {
          style: {
            maxWidth: '50%',
          },
        },
      },
      {
        name: 'Details_Description',
        isLocked: true,
        type: questionTypes.TEXTAREA.value,
        label: 'PERMIT_TEMPLATE.DETAILS_DESCRIPTION',
        placeholder: 'PERMIT_TEMPLATE.DETAILS_DESCRIPTION',
        props: {
          fullWidth: true,
          isInline: true,
          maxChars: 2000,
        },
      },
    ],
  },
  {
    Name: 'Dates',
    nonTranslatable: true,
    lockedQuestions: [
      {
        name: 'Start_Date',
        isLocked: true,
        type: questionTypes.TEXT.value,
        label: 'PERMIT_TEMPLATE.DATES_START_DATE',
        placeholder: 'PERMIT_TEMPLATE.DATES_START_DATE',
        props: {
          withRightPadding: true,
          isInline: true,
          style: {
            width: '50%',
          },
        },
      },
      {
        name: 'End_Date',
        isLocked: true,
        type: questionTypes.TEXT.value,
        label: 'PERMIT_TEMPLATE.DATES_END_DATE',
        placeholder: 'PERMIT_TEMPLATE.DATES_END_DATE',
        props: {
          withLeftPadding: true,
          isInline: true,
          style: {
            width: '50%',
          },
        },
      },
      {
        name: 'Revalidation_Time',
        isLocked: true,
        type: questionTypes.TEXT.value,
        label: 'PERMIT_TEMPLATE.DATES_REVALIDATION_TIME',
        placeholder: 'PERMIT_TEMPLATE.DATES_REVALIDATION_TIME_PLACEHOLDER',
        props: {
          withRightPadding: true,
          isInline: true,
          style: {
            width: '50%',
          },
        },
      },
      {
        name: 'Interval',
        isLocked: true,
        type: questionTypes.TEXT.value,
        label: 'PERMIT_TEMPLATE.DATES_INTERVAL',
        placeholder: 'PERMIT_TEMPLATE.DATES_INTERVAL',
        props: {
          withLeftPadding: true,
          isInline: true,
          style: {
            width: '50%',
          },
        },
      },
    ],
  },
  {
    Name: 'Files',
    nonTranslatable: true,
    isFilesUpload: true,
    hideLockIcon: true,
  },
];
