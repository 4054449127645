import actionTypes from '../constants/action-types';

export const setMainFilter = data => {
  return {
    type: actionTypes.SET_MAIN_FILTER,
    data,
  };
};

export const setWorkflowImages = data => {
  return {
    type: actionTypes.SET_WORKFLOW_IMAGES,
    data,
  };
};

export const activateWorkflowImage = data => {
  return {
    type: actionTypes.ACTIVATE_WORKFLOW_IMAGE,
    data,
  };
};

export const setWorkflowFimageUpdated = data => {
  return {
    type: actionTypes.SET_WORKFLOW_IMAGE_UPDATED,
    data,
  };
};

export const addWorkflowFimageAtIndex = data => {
  return {
    type: actionTypes.ADD_NEW_WORKFLOW_IMAGE,
    data,
  };
};
