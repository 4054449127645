import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Field, reduxForm } from 'redux-form';

import { FORMS } from '../../../../common/constants';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../common/permissions-constants';

import { validate } from './validators/ndt-alarms-validatior';

import CustomInput from '../common/input/basic-input';
import MultipleEmailsInput from '../common/input/multiple-emilas-input';
import CustomSelect from '../../../../common/form/components/select';
import Toggle from '../../../../common/form/components/toggle-field';
import Button from '../../../../common/form/components/button';
import AccessRenderer from '../../../../common/access-renderer/components/access-renderer';

import ColorPalette from '../../../../common/form/components/color-pallete';

import { formSections, sections } from './constants/ndt-alarms';

import '../../styles/ndt-alarms-modal.scss';

class NDTModalForm extends Component {
  getValueFieldLabel = (variant, field, sectionName) => {
    const { t } = this.context;
    const { unit } = this.props;

    if ([sections.high.name, sections.low.name].indexOf(sectionName) > -1) {
      return t(`NDT_ALARMS_MODAL.FORM_LABELS_${field.toUpperCase()}_${variant.toUpperCase()}`, { unit: `(${unit})` });
    } else if ([sections.excessiveRate.name].indexOf(sectionName) > -1) {
      return t(`NDT_ALARMS_MODAL.FORM_LABELS_${field.toUpperCase()}_${variant.toUpperCase()}`, { unit: `(${unit}/${t(variant)})` });
    }

    return t(`NDT_ALARMS_MODAL.FORM_LABELS_${field.toUpperCase()}_${variant.toUpperCase()}`, { unit: `` });
  };

  renderForm = (variants, sectionName) => {
    const { t } = this.context;
    const { descriptorData, availableColors, disabledData, openEmailModal, ndtEmails, initialValues, ownerID } = this.props;

    return (
      <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.ndt].edit.name} id={ownerID} ownerRequiredPermission={PERMISSIONS[PERMISSION_TYPES.ndt].create.name}>
        {({ hasAccess }) => {
          return (
            <div className="variant-wrapper">
              {Object.keys(variants).map((varKey, varIndex) => {
                const singleVariant = variants[varKey];
                const valueLabel = this.getValueFieldLabel(varKey, singleVariant.value, sectionName);
                const formDisabled = disabledData && disabledData[`${varKey}`];
                const emails = ndtEmails[varKey];
                //last two items need to drop up in select component
                const shoudDropUp = [sections.excessiveRate.variants.Y, sections.excessiveRate.variants.M].indexOf(varKey) > -1;

                return (
                  <div className={`single-variant ${formDisabled ? 'disabled' : ''} ${hasAccess ? '' : 'full-disabled'}`} key={varIndex}>
                    <Field
                      id={`${singleVariant.active}_${varKey}`}
                      name={`${singleVariant.active}_${varKey}`}
                      component={Toggle}
                      className="normal-input"
                      label={t('NDT_ALARMS_MODAL.FORM_LABELS_ACTIVE')}
                      labelClass="f-secondary-dark"
                      hideBorder
                      inline={false}
                    />
                    <Field
                      id={`${singleVariant.value}_${varKey}`}
                      name={`${singleVariant.value}_${varKey}`}
                      label={valueLabel}
                      labelClass="f-secondary-dark"
                      component={CustomInput}
                      type="number"
                      disabled={formDisabled || !hasAccess}
                    />
                    <Field
                      id={`${singleVariant.operationID}_${varKey}`}
                      name={`${singleVariant.operationID}_${varKey}`}
                      component={CustomSelect}
                      data={descriptorData}
                      className="ndt-dropdown inspection-input"
                      label={'NDT_ALARMS_MODAL.FORM_LABELS_DESCRIPTOR'}
                      disabledItems={formDisabled || !hasAccess ? descriptorData : []}
                      dropUp={shoudDropUp}
                    />

                    <Field
                      id={`${singleVariant.email}_${varKey}`}
                      name={`${singleVariant.email}_${varKey}`}
                      label={t('NDT_ALARMS_MODAL.FORM_LABELS_EMAIL')}
                      labelClass="f-secondary-dark"
                      component={MultipleEmailsInput}
                      type="text"
                      disabled={formDisabled}
                      emails={emails}
                      onClick={() => openEmailModal(initialValues[`${singleVariant.id}_${varKey}`], varKey)}
                    />
                    <Field
                      id={`${singleVariant.color}_${varKey}`}
                      name={`${singleVariant.color}_${varKey}`}
                      component={ColorPalette}
                      colorPalette={availableColors}
                      label={t('NDT_ALARMS_MODAL.FORM_LABELS_COLOR')}
                      className="normal-input"
                      disabled={formDisabled}
                    />
                  </div>
                );
              })}
            </div>
          );
        }}
      </AccessRenderer>
    );
  };

  render() {
    const { t } = this.context;
    const { closeModal, invalid, anyTouched, submit, ownerID } = this.props;
    return (
      <>
        <form className="ndt-modal-alarms-form">
          {Object.keys(formSections).map((key, index) => {
            return (
              <div key={index} className="section-wrapper">
                <p className="f-primary bold section-title">{t(formSections[key].label)}</p>
                {this.renderForm(formSections[key].variants, key)}
              </div>
            );
          })}
        </form>
        <div className="buttons">
          <Button variant="gray-outline" width="sm" type="button" text={t('NDT_ALARMS_MODAL.CANCEL')} onClick={closeModal} />
          <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.ndt].edit.name} id={ownerID} ownerRequiredPermission={PERMISSIONS[PERMISSION_TYPES.ndt].create.name}>
            {({ hasAccess }) => {
              return <Button width="sm" disabled={(invalid && anyTouched) || !hasAccess} type="button" text={t('NDT_ALARMS_MODAL.SAVE')} onClick={() => submit(FORMS.alarmsCreate)} />;
            }}
          </AccessRenderer>
        </div>
      </>
    );
  }
}

NDTModalForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

NDTModalForm = reduxForm({
  form: FORMS.alarmsCreate,
  validate,
  enableReinitialize: true,
})(NDTModalForm);

export default NDTModalForm;
