import React from 'react';
import RenderIf from '../../../../../../../../common/render-if/components/render-if';
import InfoTooltip from '../../../../../../../../common/tooltip/components/info-tooltip';
import PropTypes from 'prop-types';
import Icon from '../../../../../../../../common/icon/components/icon';
import '../../../styles/work-order-item-row.scss';
import AddWorkOrderPopover from './add-work-order-popover';
import { fields } from '../../../../../work-order/constants/work-order-right-side-constants';
import Helpers from '../../../../../../../../common/helpers';
import { FEATURES } from '../../../../../../../../common/constants';

const WorkOrderItemRow = ({ item, index, isDropdown, action }, { t }) => {
  const workOrderStartTime = item[fields.startDate] ? Helpers.getDateFromUnix(item[fields.startDate]) : '-';
  const workOrderEndTime = item[fields.endDate] ? Helpers.getDateFromUnix(item[fields.endDate]) : '-';
  const extIDVisible = FEATURES.workorders.externalID.visible;
  const id = item[extIDVisible ? fields.externalID : fields.id];

  return (
    <div className={`work-order-item-row ${isDropdown ? '' : 'added-item'}`} id={`row-${index}`} onClick={isDropdown ? () => action(item) : null}>
      <div className="id-cell">
        <RenderIf if={index === 0}>
          <p className="column-header user-title bold mb-8">{t(extIDVisible ? 'EXTERNAL_ID_SHORT' : 'ID')}</p>
        </RenderIf>
        <p className="light-bold three-dot-text" title={id}>
          {id ? id : '-'}
        </p>
      </div>
      <div className="name-cell">
        <RenderIf if={index === 0}>
          <p className="column-header user-title bold mb-8">{t('NAME')}</p>
        </RenderIf>
        <div className="name-cell__content">
          <p className="light-bold three-dot-text">{item[fields.name]}</p>
          <InfoTooltip
            customComponent={<AddWorkOrderPopover workOrder={item} />}
            offsetY={8}
            offsetX={8}
            Component={() => <Icon name="info" size="sm" />}
            componentProps={{ title: '' }}
            containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
          />
        </div>
      </div>
      <div className="date-cell">
        <RenderIf if={index === 0}>
          <p className="column-header user-title bold mb-8">{t('PERMIT_WORK_ORDERS.START_DATE')}</p>
        </RenderIf>
        <p className="light-bold three-dot-text" title={workOrderStartTime}>
          {workOrderStartTime}
        </p>
      </div>
      <div className={`date-cell ${isDropdown ? '' : 'has-unlink'}`}>
        <RenderIf if={index === 0}>
          <p className="column-header user-title bold mb-8">{t('PERMIT_WORK_ORDERS.END_DATE')}</p>
        </RenderIf>
        <p className="light-bold three-dot-text" title={workOrderEndTime}>
          {workOrderEndTime}
        </p>
      </div>
      <RenderIf if={!isDropdown}>
        <div className={`unlink-cell ${index === 0 ? 'first' : ''}`}>
          <Icon size="sm" name="close" onClick={() => action(item)} />
        </div>
      </RenderIf>
    </div>
  );
};

WorkOrderItemRow.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default WorkOrderItemRow;
