import React from 'react';
import { formConstants as regimeConstants } from '../../maintenance-regime/constants/regime-constants';
import { commonFields as NDTAlarmConstants } from '../components/ndt/constants/ndt-alarms';
import { measurementConstants as NDTconstants } from '../constants/ndt-constants';
import { formConstants as defectConstants } from './defect-constants';

import Icon from '../../../common/icon/components/icon';
import { measurementTypes } from './constants';

export const formConstants = {
  fields: {
    id: 'ID',
    name: 'Name',
    actionedAt: 'ActionedAt',
    actionedBy: 'ActionedBy',
    closedAt: 'ClosedAt',
    closedBy: 'ClosedBy',
    createdAt: 'CreatedAt',
    createdBy: 'CreatedBy',
    inspectionID: 'InspectionID',
    severity: 'Severity',
    source: 'Source',
    sourceName: 'SourceName',
    sourceID: 'SourceID',
    startedAt: 'StartedAt',
    status: 'Status',
    type: 'Type',
    updatedAt: 'UpdatedAt',
    sourceDetails: 'SourceDetails',
    sourceCustom: 'SourceCustom',
    relatedWorkOrders: 'WorkOrdersNumber',
    statusExpanded: 'statusExpanded',
    dateLong: 'dateLong',
    createdByID: 'CreatedByID',
    clipTask: 'ClipTask',
    showCameras: 'ShowCameras',
    showCameraThumbnails: 'ShowCameraThumbnails',
    numberOfRings: 'NumberOfRings',
    numberOfSlices: 'NumberOfSlices',
    initialDistance: 'InitialDistance',
    multiplier: 'Multiplier',
    points: 'Geometry.coordinates',
    mainType: 'MainType',
    camPosition: 'CameraPosition.coordinates',
    value: 'Value',
    loggedByUserID: 'LoggedByUser.ID',
    critical: 'Critical',
  },
};

export const filterFields = {
  searchText: 'SearchText',
  limit: 'Limit',
  lastSeen: 'LastSeen',
  sortByColumn: 'SortBy',
  sortDirection: 'SortDirection',
  createdByFilter: 'CreatedByFilter',
  statusFilter: 'StatusFilter',
  typeFilter: 'TypeFilter',
  sourceFilter: 'SourceFilter',
  selectedNotificationIds: 'SelectedNotificationIDs',
  hasNext: 'HasNext',
  totalItems: 'TotalNumber',
  severityFilter: 'SeverityFilter',
  dateFrom: 'DateFrom',
  dateTo: 'DateTo',
  componentsSearchFilter: 'ComponentsSearchFilter',
  createdInLastXDaysFilter: 'CreatedInLastXDaysFilter',
  hasWorkOrdersFilter: 'HasWorkOrdersFilter',
  hasLinkedEquipment: 'HasLinkedEquipment',
  hasFiles: 'HasFilesFilter',
  has3DLocationFilter: 'Has3DLocationFilter',
  properties: 'Properties',
};

export const sortingDirection = {
  asc: 'ASC',
  desc: 'DESC',
};

export const tableConfig = [
  {
    title: 'NOTIFICATIONS.ID',
    key: formConstants.fields.id,
    enableSort: true,
  },
  {
    title: 'NOTIFICATIONS.NAME',
    key: formConstants.fields.name,
    enableSort: true,
    type: formConstants.fields.name,
    className: 'inline-left',
  },
  {
    title: 'NOTIFICATIONS.DATE_STARTED',
    key: formConstants.fields.startedAt,
    enableSort: true,
    type: formConstants.fields.startedAt,
  },
  {
    key: formConstants.fields.status,
    type: formConstants.fields.status,
    className: 'inline-right',
  },
];

export const tableConfigLite = [
  {
    title: 'NOTIFICATIONS.ID',
    key: formConstants.fields.id,
    enableSort: true,
  },
  {
    title: 'NOTIFICATIONS.NAME',
    key: formConstants.fields.name,
    enableSort: true,
    type: formConstants.fields.name,
    className: 'inline-left',
    class: 'big',
  },
  {
    title: 'NOTIFICATIONS.STATUS',
    key: formConstants.fields.status,
    type: formConstants.fields.statusExpanded,
    class: 'medium',
    enableSort: true,
    className: 'inline-left',
  },
  {
    title: 'NOTIFICATIONS.STARTED_AT',
    key: formConstants.fields.startedAt,
    enableSort: true,
    class: 'big',
    type: formConstants.fields.dateLong,
  },
  {
    title: 'NOTIFICATIONS.TYPE',
    key: formConstants.fields.type,
    enableSort: true,
    type: formConstants.fields.type,
  },
  {
    title: 'NOTIFICATIONS.SOURCE',
    key: formConstants.fields.source,
    enableSort: true,
    class: 'big',
    type: formConstants.fields.source,
  },
  {
    title: 'NOTIFICATIONS.SEVERITY',
    key: formConstants.fields.severity,
    enableSort: true,
    type: formConstants.fields.severity,
  },
  {
    title: 'NOTIFICATIONS.CREATED_AT',
    key: formConstants.fields.createdAt,
    enableSort: true,
    class: 'big',
    type: formConstants.fields.dateLong,
  },
  {
    title: 'NOTIFICATIONS.CREATED_BY',
    key: formConstants.fields.createdBy,
    enableSort: true,
    type: formConstants.fields.createdBy,
  },
  {
    title: 'NOTIFICATIONS.ACTIONED_AT',
    key: formConstants.fields.actionedAt,
    enableSort: true,
    class: 'big',
    type: formConstants.fields.dateLong,
  },
  {
    title: 'NOTIFICATIONS.ACTIONED_BY',
    key: formConstants.fields.actionedBy,
    enableSort: true,
    type: formConstants.fields.actionedBy,
  },
  {
    title: 'NOTIFICATIONS.CLOSED_AT',
    key: formConstants.fields.closedAt,
    enableSort: true,
    class: 'big',
    type: formConstants.fields.dateLong,
  },
  {
    title: 'NOTIFICATIONS.CLOSED_BY',
    key: formConstants.fields.closedBy,
    enableSort: true,
    type: formConstants.fields.closedBy,
  },
  {
    title: 'NOTIFICATIONS.RELATED_WORK_ORDERS',
    key: formConstants.fields.relatedWorkOrders,
    enableSort: true,
    type: formConstants.fields.relatedWorkOrders,
  },
];

export const tabNames = {
  details: 'DETAILS',
  comments: 'COMMENTS',
  workOrders: 'WORK_ORDERS',
};

export const toolbarItems = {
  [tabNames.details]: {
    name: tabNames.details,
    label: 'COMPONENET_FORM_TAB.DETAILS',
  },
  [tabNames.workOrders]: {
    name: tabNames.workOrders,
    label: 'COMPONENT_FORM.WORK_ORDERS',
  },
  [tabNames.comments]: {
    name: tabNames.comments,
    label: 'COMPONENET_FORM_TAB.COMMENTS',
  },
};

export const notificationStatus = {
  open: {
    value: 'OPEN',
    title: 'NOTIFICATIONS_STATUS_COLORS.RED',
    icon: 'clock',
    skipPermissionCheck: true,
    isRegularStatus: true,
  },
  actioned: {
    value: 'ACTIONED',
    title: 'NOTIFICATIONS_STATUS_COLORS.ORANGE',
    icon: 'clock',
    skipPermissionCheck: true,
    isRegularStatus: true,
  },
  closed: {
    value: 'CLOSED',
    title: 'NOTIFICATIONS_STATUS_COLORS.GREEN',
    icon: 'clock',
    skipPermissionCheck: true,
    isRegularStatus: true,
  },
};

export const expandableSectionInfoTypes = {
  source: 'source',
  components: 'components',
};

export const quickFilter = [
  {
    value: notificationStatus.closed,
    field: filterFields.statusFilter,
    label: 'NOTIFICATIONS_STATUS_COLORS.GREEN',
    active: false,
    colorKey: 'severityGreen',
    customComponent: (className, active = true, restProps) => <Icon name="checkmark" active={active} size="xs" handleHover={false} className={`def-icon ${className || ''}`} {...restProps} />,
  },
  {
    value: notificationStatus.actioned,
    field: filterFields.statusFilter,
    label: 'NOTIFICATIONS_STATUS_COLORS.ORANGE',
    active: true,
    colorKey: 'severityOrange',
    customComponent: (className = '', restProps) => <Icon name="exclamation-mark" warning size="xs" className={`def-icon ${className || ''}`} handleHover={false} {...restProps} />,
  },
  {
    value: notificationStatus.open,
    field: filterFields.statusFilter,
    label: 'NOTIFICATIONS_STATUS_COLORS.RED',
    active: true,
    colorKey: 'severityRed',
    customComponent: (className, restProps) => <Icon name="exclamation-mark" danger size="xs" className={`def-icon ${className || ''}`} handleHover={false} {...restProps} />,
  },
];

export const defaultPagingObj = {
  [filterFields.lastSeen]: 0,
  [filterFields.limit]: 50,
  [filterFields.totalItems]: 0,
  [filterFields.hasNext]: false,
};
export const defaultSortingFilter = {
  [filterFields.sortByColumn]: formConstants.fields.startedAt,
  [filterFields.sortDirection]: sortingDirection.asc,
};

export const notificationTypeFields = {
  value: 'Value',
  name: 'Name',
};

export const notificationTypes = {
  reactive: 'REACTIVE',
  predictive: 'PREDICTIVE',
  preventative: 'PREVENTATIVE',
};

export const sourceTypes = {
  regime: 'REGIME',
  observation: 'OBSERVATION',
  ndtMeasurement: 'NDT-MEASUREMENT',
  measurementPoint: 'MEASUREMENT-POINT',
};

export const notificationTooltipProps = {
  [notificationTypes.reactive]: [
    defectConstants.fields.name,
    // defectConstants.fields.type,
    defectConstants.fields.severity,
    defectConstants.fields.mainType,
    defectConstants.fields.description,
    defectConstants.fields.recommendation,
  ],
  [notificationTypes.preventative]: [regimeConstants.fields.title, regimeConstants.fields.plainInterval, regimeConstants.fields.descripton, regimeConstants.fields.importance],
  [notificationTypes.predictive]: [
    NDTconstants.fields.name,
    NDTAlarmConstants.value,
    'Unit',
    'Operation',
    NDTconstants.fields.componentName,
    NDTconstants.fields.filterGroup,
    NDTconstants.fields.threshold,
  ],
};

// const below is not used anymore, should be removed
export const typeDropdownData = [
  {
    label: `NOTIFICATION_TYPE.REACTIVE`,
    value: notificationTypes.reactive,
  },
  {
    label: `NOTIFICATION_TYPE.PREDICTIVE`,
    value: notificationTypes.predictive,
  },
  {
    label: `NOTIFICATION_TYPE.PREVENTATIVE`,
    value: notificationTypes.preventative,
  },
];

// const below is not used anymore, should be removed
export const typeLabelTranslationMap = {
  [notificationTypes.predictive]: 'NOTIFICATION_TYPE.PREDICTIVE',
  [notificationTypes.preventative]: 'NOTIFICATION_TYPE.PREVENTATIVE',
  [notificationTypes.reactive]: 'NOTIFICATION_TYPE.REACTIVE',
};

// const below is not used anymore, should be removed
export const sourceLabelTranslationMap = {
  [sourceTypes.observation]: 'INSPECTION_DEFECTS',
  [sourceTypes.ndtMeasurement]: 'NDT_DATA',
  [sourceTypes.regime]: 'PAGE_TITLE.MAINTENANCE_REGIME',
  [sourceTypes.measurementPoint]: 'MEASUREMENT-POINT',
};

export const getSourceObjectForDropdown = t => {
  return [
    {
      value: sourceTypes.regime,
      label: t(sourceLabelTranslationMap[sourceTypes.regime]),
    },
    {
      value: sourceTypes.observation,
      label: t(sourceLabelTranslationMap[sourceTypes.observation]),
    },
    {
      value: sourceTypes.ndtMeasurement,
      label: t('READINGS_AND_GAUGES_OLD'),
    },
    {
      value: sourceTypes.measurementPoint,
      label: t('READINGS_AND_GAUGES'),
    },
  ];
};

export const severityConstants = {
  green: { keys: [0, -1], color: 'severityGreen' },
  yellow: { keys: [1, 2, 3], color: 'severityYellow' },
  orange: { keys: [4, 5, 6, 7], color: 'severityOrange' },
  red: { keys: [8, 9, 10], color: 'severityRed' },
};

export const DefaultNotification = camPos => ({
  Name: 'New Notification',
  SystemType: measurementTypes.notification,
  Geometry: {
    type: 'Point',
    coordinates: [],
  },
  CameraPosition: camPos || {
    coordinates: [0, 0, 0],
  },
});
