import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { ReactComponent as PlusSign } from '../assets/plus.svg';

import '../styles/basic-input.scss';

export default class MultipleEmailsInput extends Component {
  handleChange = e => {
    const {
      input: { onChange },
      handleChange,
    } = this.props;
    onChange(e);
    if (handleChange) handleChange(e.target.value);
  };
  renderEmails = () => {
    const { emails, onClick, disabled } = this.props;
    return (
      <div className="value-wrapper">
        {!isEmpty(emails) && <p className="value f-primary">{emails[0].Email}</p>}
        {!isEmpty(emails) && emails.length && emails.length - 1 > 0 && <p className="other">+ {emails.length - 1}</p>}

        <PlusSign className="add-icon pointer" onClick={disabled ? null : onClick} />
      </div>
    );
  };

  render() {
    const { t } = this.context;

    const {
      input: { onChange, ...restInputProps },
      type,
      placeholder,
      meta,
      className,
      id,
      label,
      labelClass,
      disabled,
      hideBorder,
      isHidden,
      inputProps,
      hidden,
      emails,
    } = this.props;

    return (
      <div className={`inspection-input ${isHidden ? 'hidden-field' : ''} email-input`}>
        {label && (
          <label className={`inspection-input__label ${labelClass || 'f-secondary-dark'}`} htmlFor={id}>
            {label}
          </label>
        )}
        <div className={`inspection-input__input-wrapper${hideBorder ? '' : ' border'}` + (meta && meta.error && meta.touched ? ' invalid ' : '')}>
          <input
            id={id}
            onChange={this.handleChange}
            value={emails && emails[0] ? emails.toString() : ''}
            {...inputProps}
            {...restInputProps}
            readOnly={disabled}
            disabled={disabled}
            type={type || 'text'}
            placeholder={placeholder || ''}
            className={(meta && meta.error && meta.touched ? 'invalid ' : '') + `${className || 'input f-primary'} ${hidden ? 'hidden' : ''}`}
          />
          {this.renderEmails()}
        </div>
        <div className={`error-message${meta && meta.error && meta.touched ? '--visible' : ''}`}>{meta && meta.error && meta.touched && t(meta.error.string, meta.error.params)}</div>
      </div>
    );
  }
}

MultipleEmailsInput.defaultProps = {
  hidden: true,
};

MultipleEmailsInput.contextTypes = {
  t: PropTypes.func.isRequired,
};
