import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { debounce, chain } from 'lodash';

import Modal from '../../../common/modal/components/modal';
import InfoTooltip from '../../../common/tooltip/components/info-tooltip';

import SingleTable from './single-table';
import DropdownMenu from './table-dropdown';
import Toolbar from '../../../common/toolbar/components/toolbar';
import UploadProjectAssetsModal from '../../project/components/upload-assets-modal';
import Icon from '../../../common/icon/components/icon';

import Helpers from '../../../common/helpers';
import routesConstants from '../../../common/routes-constants';

import constants from '../constants/constants';

import { getTableData, deletePDF, editPDF, batchItemsUpdate } from '../actions/table-actions';
import { handleClearTableData } from '../actions/action-creators';
import { fields } from '../constants/constants';

import uploadConstants from '../../upload/constants/constants';

import { setUploadItems, setSingleUploadItem } from '../../upload/actions/upload-actions';
import { uploadPDF } from '../actions/pdf-actions';

import '../styles/pdf-tag-list.scss';
import { deleteDMFile } from '../../document-management/actions/dm-api-calls';
import ActionModal from '../../document-management/components/modals/action-modal/action-modal';

class PdfTagList extends Component {
  constructor(props) {
    super(props);
    const { query } = props.router.location;

    const sections = chain(constants.sections)
      .values()
      .map(({ title, name, hidden, ...rest }) => {
        return {
          name,
          label: title,
          rest,
          hidden,
        };
      })
      .reverse()
      .value();

    this.state = {
      inspectionId: parseInt(query.inspection_id),
      projectId: parseInt(query.project_id),
      toolbarItems: sections,
      activeToolbarItem: constants.sections.pAndId.name,
      modalData: {
        isOpen: false,
      },
      deleteModalData: {
        isOpen: false,
      },
    };
    this.searchChangeDebounced = debounce(this.onSearch, 500);
    this.startPooling = null;
  }

  onRowClick = id => {
    //TO DO do something
  };

  componentWillUnmount = () => {
    const { handleClearTableData } = this.props;
    clearInterval(this.startPooling);
    this.startPooling = null;
    handleClearTableData();
  };

  componentDidMount = () => {
    const { projectId, inspectionId } = this.state;
    const { getTableData, tables } = this.props;

    if (!projectId || !inspectionId) {
      Helpers.goTo(routesConstants.routes.protectedRoutes.project.fullPath);
    }
    getTableData({ ...tables[constants.sections.pAndId.name].tableParams, InspectionID: inspectionId }, constants.sections.pAndId.name);

    this.startPooling = setInterval(this._setInterval, 5000);
  };

  _setInterval = () => {
    const { inspectionId, activeToolbarItem: activeTab } = this.state;
    const { tables, batchItemsUpdate } = this.props;
    let data = (tables[activeTab] && tables[activeTab].tableData) || [];

    data = chain(data)
      .filter(item => item[constants.fields.status] === constants.statusOptions.started)
      .map(item => parseFloat(item[constants.fields.id]))
      .value();
    if (data.length > 0) {
      batchItemsUpdate(activeTab, data, inspectionId);
    }
  };

  onColumnSort = (section, SortByColumn) => {
    const { getTableData, tables } = this.props;

    const oldParams = tables[section].tableParams;

    const newParams = {
      ...oldParams,
      SortByColumn,
      SortDirection: oldParams.SortDirection === constants.sortDirection.asc ? constants.sortDirection.desc : constants.sortDirection.asc,
      LastSeen: 0,
    };

    getTableData(newParams, section);
  };

  loadMore = section => {
    const { getTableData, tables } = this.props;

    const { tableParams, tableData, name } = tables[section];

    const newParams = { ...tableParams, LastSeen: tableParams.LastSeen + tableParams.PerPage };

    getTableData(newParams, section, () => Helpers.scrollIntoView(`tw-${name}`, `row-${tableData.length - 1}`), true);
  };

  formatCell = (value, type, rowIndex, row) => {
    if (type === 'date' && !isNaN(value) && value > 0) {
      return Helpers.getDateFromUnix(value);
    }
    if (!value) {
      return '-';
    }

    if (type === fields.title) {
      return (
        <div className="name">
          <span className="f-primary dots" title={value}>
            {value || row.FileName}
          </span>
        </div>
      );
    }

    return value;
  };

  onDrop = (files, uploadGroup) => {
    const { activeToolbarItem: activeItem } = this.state;
    const {
      uploadPDF,
      setUploadItems,
      setSingleUploadItem,
      restUploadParams,
      location: { query },
    } = this.props;
    const tmp_files = files.map((file, index) => {
      const tmp_file = {
        type: Helpers.getFileType(file.type),
        mimeType: file.type,
        title: '',
        description: '',
        real_name: Helpers.formatFileName(file.name),
      };
      file.timeStamp = Date.now();
      file.real_name = tmp_file.real_name;
      file.uploadType = uploadConstants.uploadType.pdfTaggingFiles;
      const dataParams = { ProjectID: parseInt(query.project_id), InspectionID: parseInt(query.inspection_id), UploadGroup: uploadGroup, ...(restUploadParams || {}) };
      uploadPDF(file, setSingleUploadItem, index, dataParams, activeItem);
      return { ...tmp_file, ...file };
    });

    setUploadItems(tmp_files);
    this.openProjectAssetsModal();
  };

  openProjectAssetsModal = () => {
    const { t } = this.context;
    const { setUploadItems } = this.props;

    this.setState(prevState => ({
      modalData: {
        ...prevState.modalData,
        title: t('PROJECT_ASSETS_UPLOAD_MODAL.TITLE'),
        customClassName: 'project-assets-modal modal-small',
        CustomContent: () => (
          <UploadProjectAssetsModal
            onDrop={() => null}
            className="project-dropzone"
            label={t('PROJECT_ASSETS_UPLOAD_MODAL.DESCRIPTION')}
            labelWhite={t('PROJECT_ASSETS_UPLOAD_MODAL.TITLE')}
            labelGreen={t('PROJECT_UPLOAD_MODAL.BROWSE')}
          />
        ),
        isOpen: true,
        type: 'ok',
        confirmAction: () => {
          this.setState({ modalData: { isOpen: false } });
          setUploadItems([]);
        },
        closeAction: () => {
          this.setState({ modalData: { isOpen: false } });
          setUploadItems([]);
        },
      },
    }));
  };

  handleActiveTab = activeItem => {
    const { inspectionId } = this.state;
    const { getTableData } = this.props;

    this.setState({ activeToolbarItem: activeItem });

    getTableData({ ...constants.pdfDataParams, InspectionID: inspectionId, UploadGroup: constants.sections[activeItem].uploadGroup }, constants.sections[activeItem].name);
  };

  onSearch = text => {
    const { inspectionId, activeToolbarItem: activeItem } = this.state;
    const { getTableData } = this.props;

    getTableData({ ...constants.pdfDataParams, InspectionID: inspectionId, UploadGroup: constants.sections[activeItem].uploadGroup, SearchText: text }, constants.sections[activeItem].name);
  };

  getTooltipData = data => {
    return {
      [`PDF_TABLE.${constants.fields.fileName.toUpperCase()}`]: data[constants.fields.fileName],
      [`PDF_TABLE.${constants.fields.createdBy.toUpperCase()}`]: data[constants.fields.createdBy],
      [`PDF_TABLE.${constants.fields.lastModified.toUpperCase()}`]: Helpers.getDateFromUnix(data[constants.fields.lastModified]),
      [`PDF_TABLE.${constants.fields.modifiedBy.toUpperCase()}`]: data[constants.fields.modifiedBy],
    };
  };

  closeDeleteModal = () => this.setState({ deleteModalData: { isOpen: false } });

  openDeleteModal = (data, callback) => {
    const { deleteDMFile } = this.props;
    const { t } = this.context;
    const customConfirmAction = () => {
      deleteDMFile(data, callback);
      this.closeDeleteModal();
    };
    this.setState({
      deleteModalData: {
        type: '',
        className: 'modal-no-max-height modal-medium',
        isOpen: true,
        closeAction: this.closeDeleteModal,
        customCloseAction: this.closeDeleteModal,
        CustomContent: dynamicProps => <ActionModal {...dynamicProps} />,
        customConfirmAction,
        title: t('DELETE_FILE'),
        firstParagraph: 'SECTIONS.DELETE_FILE_CONTENT',
        firstParagraphProps: { name: data.FileName },
        confirmButtonText: 'DELETE',
      },
    });
  };

  render() {
    const { projectId, inspectionId, toolbarItems, activeToolbarItem: activeItem, modalData, deleteModalData } = this.state;
    const { tables, user, deletePDF, editPDF, tableDataLoading } = this.props;
    const { handleActiveTab, openDeleteModal } = this;

    const tableProps = {
      ...tables[activeItem],
      tableConfig: [
        {
          title: '',
          CustomComponent: (data, itemIndex) => (
            <InfoTooltip
              actionsMenu={this.getTooltipData(data)}
              offsetY={10}
              offsetX={5}
              Component={() => <Icon name="info" handleHover={false} size="xs" />}
              containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
            />
          ),
          enableSort: false,
        },
        ...tables[activeItem].tableConfig,
        {
          title: 'TABLE.ACTIONS',
          CustomComponent: (data, itemIndex) => (
            <DropdownMenu
              data={data}
              itemIndex={itemIndex}
              inspectionId={inspectionId}
              projectId={projectId}
              user={user}
              setModalState={state => this.setState(state)}
              deletePDF={(index, params, callback, setFormError) => deletePDF(activeItem, index, params, callback, setFormError)}
              editPDF={(index, params, callback) => editPDF(activeItem, index, params, callback)}
              deleteDMFile={(data, callback) => openDeleteModal(data, callback)}
              tableData={tables[activeItem].tableData}
              // in case we need to update the pdf-tag-list immediately callback is required (e.g. batchItemsUpdate)
              // deleteDMFile={(data, callback) => deleteDMFile(data, batchItemsUpdate(activeItem, data, inspectionId))}
            />
          ),
          enableSort: false,
        },
      ],
    };

    return (
      <div className={`pdf-tag-list-wrapper tabs-hidden`}>
        <Toolbar {...{ toolbarItems, handleActiveTab, activeItem }} />
        <SingleTable
          {...tableProps}
          formatCell={this.formatCell}
          loadMore={() => this.loadMore(activeItem)}
          onRowClick={() => null}
          key={activeItem}
          onColumnSort={column => this.onColumnSort(tables[activeItem].name, column)}
          onSearch={e => this.searchChangeDebounced(e.target.value)}
          title={constants.sections[activeItem].title}
          onDrop={files => {
            this.onDrop(files, tables[activeItem].uploadGroup);
          }}
          supportedMimeTypes={constants.sections[activeItem].supportedMimeTypes}
          tableDataLoading={tableDataLoading}
        />
        <Modal {...modalData} />
        <Modal {...deleteModalData} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  tables: state.pdfTableReducer.tables,
  user: state.userReducer,
  tableDataLoading: state.pdfTableReducer.tableDataLoading,
});

const mapDispatchToProps = dispatch => ({
  getTableData: (params, section, callback, loadMore) => dispatch(getTableData(params, section, callback, loadMore)),
  uploadPDF: (file, callbackFunction, index, data, section) => dispatch(uploadPDF(file, data, callbackFunction, index, section)),
  setUploadItems: assets => dispatch(setUploadItems(assets)),
  setSingleUploadItem: (progress, index) => dispatch(setSingleUploadItem(progress, index)),
  deletePDF: (section, index, params, callback, setFormError) => dispatch(deletePDF(section, index, params, callback, setFormError)),
  editPDF: (section, index, params, callback) => dispatch(editPDF(section, index, params, callback)),
  batchItemsUpdate: (section, ids, inspectionId) => dispatch(batchItemsUpdate(section, ids, inspectionId)),
  handleClearTableData: () => dispatch(handleClearTableData()),
  deleteDMFile: (data, callback) => dispatch(deleteDMFile(data, callback)),
});

PdfTagList.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(PdfTagList);
