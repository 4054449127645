import { capitalize, values } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';

import { FORMS } from '../../../../common/constants';
import CustomProperties from '../../../../common/custom-property/components/custom-properties';
import CustomDatePicker from '../../../../common/form/components/date-picker';
import CustomInput from '../../../../common/form/components/input';
import CustomSelect from '../../../../common/form/components/select';
import Textarea from '../../../../common/form/components/text-area';
import TimePicker from '../../../../common/form/components/time-picker';
import { priorities } from '../../../inspections/constants/work-order-constants';
import { workOrderSubTypes } from './constants/constants';
import { formFields } from './constants/work-order-details-constants';
import { workorderValidate } from './validators/workorder-validator';

const BasicDetailsForm = ({ workOrderTypes, severityColors, disabled, properties, getWorkOrderCustomProperties, additionalWorkorderTypes }, { t }) => {
  const { name, subType, additionalSubType, type, description, toolsRequired, severity, startDate, endDate, dueDate, estimatedWrenchTime } = formFields;
  const workOrderPriorities = React.useMemo(() => {
    return values(priorities);
  }, []);

  return (
    <form noValidate>
      <Field
        id={name.id}
        name={name.name}
        component={CustomInput}
        placeholder={t(name.label)}
        label={t(name.label)}
        type="text"
        size="lg"
        onlyDisabled={disabled}
        additionalClassName="text-transform-none"
        labelClass="text-transform-none"
      />
      <div className="work-order-custom-select">
        <Field
          id={subType.id}
          name={subType.name}
          data={values(workOrderSubTypes)}
          component={CustomSelect}
          label={t(subType.label)}
          placeholder={t(subType.label)}
          valueComponent={item => capitalize(item.text)}
          renderListItem={item => capitalize(item.text)}
          size="lg"
          readOnly={disabled}
        />
      </div>
      <div className="work-order-custom-select">
        <Field
          id={type.id}
          name={type.name}
          data={workOrderTypes}
          component={CustomSelect}
          label={t(type.label)}
          placeholder={t(type.label)}
          size="lg"
          valueComponent={item => capitalize(item.text)}
          renderListItem={item => capitalize(item.text)}
          readOnly={disabled}
        />
      </div>
      <div className="work-order-custom-select">
        <Field
          id={additionalSubType.id}
          name={additionalSubType.name}
          data={values(additionalWorkorderTypes)}
          component={CustomSelect}
          label={t(additionalSubType.label)}
          placeholder={t(additionalSubType.label)}
          valueComponent={item => capitalize(item.text)}
          renderListItem={item => capitalize(item.text)}
          size="lg"
          readOnly={disabled}
        />
      </div>
      <div className="work-order-textarea">
        <Field
          id={description.id}
          name={description.name}
          label={t(description.label)}
          component={Textarea}
          placeholder={t(description.label)}
          maxChars={description.maxChars}
          onlyDisabled={disabled}
          enableAutoResize={true}
          size="lg"
        />
      </div>
      <div className="work-order-textarea">
        <Field
          id={toolsRequired.id}
          name={toolsRequired.name}
          component={Textarea}
          placeholder={t(toolsRequired.label)}
          label={t(toolsRequired.label)}
          size="lg"
          maxChars={toolsRequired.maxChars}
          onlyDisabled={disabled}
        />
      </div>
      <div className="work-order-custom-select">
        <Field
          id={severity.id}
          name={severity.name}
          data={workOrderPriorities}
          readOnly={disabled}
          valueField="value"
          textField="title"
          component={CustomSelect}
          placeholder={t(severity.label)}
          label={t(severity.label)}
          valueComponent={({ item }) => (
            <div className="priority-dropdown-item without-padding">
              {item.color ? <div className="priority-icon" style={{ backgroundColor: severityColors[item.color] }}></div> : null}
              <p className="f-primary">{t(item.title)}</p>
            </div>
          )}
          optionComponent={({ dataItem, onSelect }) => {
            return (
              <div className="priority-dropdown-item" onClick={() => onSelect(dataItem.value)}>
                {dataItem.color ? <div className="priority-icon" style={{ backgroundColor: severityColors[dataItem.color] }}></div> : null}
                <p className="f-primary">{t(dataItem.title)}</p>
              </div>
            );
          }}
          size="lg"
        />
      </div>
      <div className="work-order-custom-date-picker">
        <Field
          disabledKeyboardNavigation={true}
          id={startDate.id}
          name={startDate.name}
          component={CustomDatePicker}
          popperPlacement="bottom-end"
          placeholder={startDate.label}
          label={startDate.label}
          size="lg"
          dateFormat="MMMM d, yyyy h:mm aa"
          timeFormat="h:mm aa"
          showTimeSelect
          validateUsingReduxForm
          disabled={disabled}
        />
      </div>
      <div className="work-order-custom-date-picker">
        <Field
          disabledKeyboardNavigation={true}
          id={endDate.id}
          name={endDate.name}
          component={CustomDatePicker}
          popperPlacement="bottom-end"
          placeholder={endDate.label}
          label={endDate.label}
          size="lg"
          dateFormat="MMMM d, yyyy h:mm aa"
          timeFormat="h:mm aa"
          showTimeSelect
          validateUsingReduxForm
          disabled={disabled}
          isEnd
        />
      </div>
      <div className="work-order-time-picker">
        <Field id={estimatedWrenchTime.id} name={estimatedWrenchTime.name} component={TimePicker} label={t(estimatedWrenchTime.label)} size="lg" showPlaceholderWhileValue onlyDisabled={disabled} />
      </div>
      <div className="work-order-custom-date-picker">
        <Field
          disabledKeyboardNavigation={true}
          id={dueDate.id}
          name={dueDate.name}
          component={CustomDatePicker}
          popperPlacement="bottom-end"
          placeholder={dueDate.label}
          label={dueDate.label}
          size="lg"
          dateFormat="MMMM d, yyyy h:mm aa"
          timeFormat="h:mm aa"
          showTimeSelect
          validateUsingReduxForm
          disabled={disabled}
          isEnd
        />
      </div>
      <div className="work-order-custom-props">
        <CustomProperties
          isDisabled={true}
          formName={FORMS.workOrderCustomPropertiesForm}
          properties={properties}
          getProperties={getWorkOrderCustomProperties}
          updateProperty={() => {}}
          addProperty={() => {}}
          deleteProperty={() => {}}
          getSuggestions={() => {}}
          customButton={<></>}
        />
      </div>
    </form>
  );
};

BasicDetailsForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default reduxForm({
  form: FORMS.workOrderBasicDetailsForm,
  validate: workorderValidate,
  enableReinitialize: true,
  touchOnChange: true,
})(BasicDetailsForm);
