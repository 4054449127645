import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { map, isEmpty } from 'lodash';

import Button from '../../../../common/form/components/button';
import CustomInput from '../common/input/basic-input';

import { Field, reduxForm } from 'redux-form';
import { validateEmailForm } from './validators/ndt-alarms-validatior';

import { FORMS } from '../../../../common/constants';
import { commonFields } from './constants/ndt-alarms';

import '../../styles/ndt-alarms-modal-email.scss';

class NDTAlarmsModalEmail extends Component {
  onSubmit = vals => {
    const { alarmId, addAlarmEmail, type, resetForm } = this.props;

    addAlarmEmail(vals[commonFields.email], alarmId, type, () => {
      resetForm(FORMS.emailCreate);
    });
  };
  render() {
    const { t } = this.context;
    const { emails, invalid, anyTouched, handleSubmit, removeAlarmEmail, type, closeModal } = this.props;

    return (
      <div className="ndt-alarms-modal-email">
        <form className="main-form" noValidate onSubmit={handleSubmit(this.onSubmit)}>
          <Field id={commonFields.email} name={commonFields.email} label={t('NDT_ALARMS_MODAL.FORM_LABELS_EMAIL')} labelClass="f-secondary-dark" component={CustomInput} type="text" />
          <Button width="sm" text={t('NDT_EMAILS_MODAL.SUBMIT_BUTTON')} disabled={invalid && anyTouched} />
        </form>
        <div className="emails-list">
          {!isEmpty(emails) &&
            map(emails, (item, index) => {
              return (
                <div className="single-item inspection-input" key={index}>
                  <div className="border">
                    <p className="f-primary">{item[commonFields.email]}</p>
                    <div className="f-secondary-green remove-btn" onClick={() => removeAlarmEmail(item[commonFields.id], type, item)}>
                      {t('NDT_EMAILS_MODAL.REMOVE')}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <Button width="sm" marginAuto text={t('NDT_EMAILS_MODAL.DONE_BUTTON')} type="button" onClick={closeModal} />
      </div>
    );
  }
}
NDTAlarmsModalEmail.contextTypes = {
  t: PropTypes.func.isRequired,
};
NDTAlarmsModalEmail = reduxForm({
  form: FORMS.emailCreate,
  validate: validateEmailForm,
  enableReinitialize: true,
})(NDTAlarmsModalEmail);

export default NDTAlarmsModalEmail;
