const eventType = {
  radio: 'radio',
  number: 'number',
};

const zoomLevels = [
  {
    value: 1,
    label: 1,
  },
  {
    value: 1.5,
    label: 1.5,
  },
  {
    value: 2,
    label: 2,
  },
  {
    value: 4,
    label: 4,
  },
  {
    value: 5,
    label: 5,
  },
];

const formConstants = {
  fields: {
    id: 'ID',
    fileID: 'FileID',
    name: 'FileName',
    url: 'FileURL',
    thumbnailUrl: 'ThumbnailURL',
    documentSizeUrl: 'DocumentURL',
    reviewed: 'Reviewed',
    selected: 'SelectedForWorkflow',
    createdAt: 'CreatedAt',
    resolution: 'Resolution',
    highestSeverity: 'HighestSeverity',
  },
};

const imageSelectionTypes = {
  all: 'ALL',
  selected: 'SELECTED',
  single: 'SINGLE',
};

const imageSelectionStatus = {
  select: 'SELECT',
  deselect: 'DESELECT',
};

const severity = {
  green: { keys: [0, -1], color: 'green', label: 'SEVERITY_LEGEND.GREEN' },
  yellow: { keys: [1, 2, 3], color: 'yellow', label: 'SEVERITY_LEGEND.YELLOW' },
  orange: { keys: [4, 5, 6, 7], color: 'orange', label: 'SEVERITY_LEGEND.ORANGE' },
  red: { keys: [8, 9, 10], color: 'red', label: 'SEVERITY_LEGEND.RED' },
};

const displayProps = [
  {
    name: 'FileName',
  },
  {
    name: 'CreatedAt',
    type: 'date',
  },
];

const defaultFilter = {
  LastSeen: 0,
  PerPage: 100,
  HasNext: true,
  TotalItems: 0,
};

const contextualized = 'Contextualized';

export default {
  eventType,
  zoomLevels,
  displayProps,
  formConstants,
  imageSelectionTypes,
  imageSelectionStatus,
  severity,
  contextualized,
  defaultFilter,
};
