export default {
  GET_NDT_MEASUREMENT_LOCATIONS: '/inspection/ndt/measurement/list',
  GET_NDT_MEASUREMENT_LOCATIONS_LITE: '/inspection/ndt/measurement/lite-list',
  GET_NDT_MEASUREMENT_DETAILS: '/inspection/ndt/measurement/details',
  CREATE_NDT_MEASUREMENT_LOCATION: '/inspection/ndt/measurement/add',
  UPDATE_NDT_MEASUREMENT_LOCATION: '/inspection/ndt/measurement/update',
  DELETE_NDT_MEASUREMENT_LOCATION: '/inspection/ndt/measurement/delete',
  GET_NDT_MEASUREMENT_PROPERTIES: '/properties/measurement/get',
  ADD_NDT_MEASUREMENT_PROPERTY: '/properties/measurement/add',
  DELETE_NDT_MEASUREMENT_FILE: '/inspection/ndt/measurement/file/unlink',
  DELETE_NDT_MEASUREMENT_POINT_FILE: '/inspection/ndt/measure/file/unlink',
  GET_NDT_MEASUREMENT_POINTS: '/inspection/ndt/measurement/measure/list',
  GET_NDT_MEASUREMENT_POINT_DETAILS: '/inspection/ndt/measurement/measure/details',
  CREATE_NDT_MEASUREMENT_POINT: '/inspection/ndt/measurement/measure/add',
  UPDATE_NDT_MEASUREMENT_POINT: '/inspection/ndt/measurement/measure/update',
  DELETE_NDT_MEASUREMENT_POINT: '/inspection/ndt/measurement/measure/delete',
  GET_DESCRIPTION_DATA: '/inspection/ndt/measurement/alarm/operators/list',
  GET_ALARMS: '/inspection/ndt/measurement/alarm/list',
  SAVE_ALARMS: '/inspection/ndt/measurement/alarms/add',
  GET_NDT_MEASUREMENT_POINT_PROPERTIES: '/properties/measurement/measure/get',
  ADD_NDT_MEASUREMENT_POINT_PROPERTY: '/properties/measurement/measure/add',
  GET_NDT_CHART_PERIODS: '/inspection/ndt/measurement/chart/periods',
  GET_NDT_CHART_TYPES: '/inspection/ndt/measurement/chart/types',
  GET_NDT_CHART_GROUPS: '/inspection/ndt/measurement/chart/groups',
  GET_NDT_CHART_DATA: '/inspection/ndt/measurement/chart/data',
  GET_NDT_CHART_POINT_GROUPS: '/inspection/ndt/measurement/chart/point-groups',
  GET_NDT_EMAILS: '/inspection/ndt/measurement/alarm/email/list',
  ADD_ALARM_EMAIL: '/inspection/ndt/measurement/alarm/email/add',
  REMOVE_ALARM_EMAIL: '/inspection/ndt/measurement/alarm/email/delete',
  UPDATE_NDT_MEASUREMENT_LOCATION_GEOMETRY: '/inspection/ndt/measurement/position/update',
  GET_NDT_MEASUREMENTS_COMPONENTS: '/inspection/ndt/measurement/chart/components',
  GET_NDT_MEASUREMENT_UNITS: '/inspection/ndt/measurement/chart/units',
  GET_NDT_ALARMS_TRIGGERD: '/inspection/ndt/measurement/chart/units',
  GET_NDT_MEASUREMENT_GROUP_DETAILS: '/inspection/ndt/measurement/charts/list',
  GET_NDT_PREDICTIVE_TYPES: '/inspection/ndt/measurement/predictive-chart/types',
  GET_NDT_PREDICTIVE_SETTINGS: '/inspection/ndt/measurement/predictive-settings/get',
  UPDATE_NDT_PREDICTIVE_SETTINGS: '/inspection/ndt/measurement/predictive-settings/add',
  GET_NDT_CHART_DEFAULTS: '/inspection/ndt/measurement/chart/defaults',
  GET_NDT_PREDICTIVE_CHART_DATA: '/inspection/ndt/measurement/predictive-chart/data',
  GET_FILTER_GROUP_SUGGESTIONS: '/inspection/ndt/measurement/filter-group/list',
  DOWNLOAD_REPORT: '/inspection/ndt/report/xlsx',
  GET_MEASUREMENTS_CLUSTERED: '/inspection/ndt/measurement/clustered/list',
  UPDATE_ALARMS_NOTIFICATIONS: '/inspection/ndt/alarm/notification/update',
  DELETE_NDT_PROPERTY: '/inspection/ndt/measurement/property/delete',
  DELETE_NDT_MEASUREMENT_POINT_PROPERTY: '/inspection/ndt/measurement/measure/property/delete',
  UPDATE_NDT_PROPERTY: '/inspection/ndt/measurement/property/update',
  UPDATE_NDT_MEASUREMENT_POINT_PROPERTY: '/inspection/ndt/measurement/measure/property/update',
  GET_NDT_MEASUREMENT_PROPERTY_NAMES: '/properties/measurement/names',
  GET_NDT_MEASUREMENT_POINT_PROPERTY_NAMES: '/properties/measurement/measure/names',
  UPDATE_NDT_MEASUREMENT_COMPONENT: '/inspection/ndt/measurement/link/component',
};
