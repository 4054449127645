// TODO: refactor this component as reusable (search-dropdown.js) and apply it instead of this component & add-permits.js
import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../../common/icon/components/icon';
import InfoTooltip from '../../../../common/tooltip/components/info-tooltip';
import SearchInput from '../../../../common/input/components/search-input';
import Helpers from '../../../../common/helpers';
import { workOrderFields } from './constants/permit-form-constants';
import LoadMore from '../../../../common/load-more/components/load-more';
import { filterFields } from '../../constants/work-order-constants';
import PermitWorkOrderPopover from './permit-workorder-popover';

const PermitWorkOrderDropdown = (
  { workOrders, selectedWorkOrder, handleSearchWorkOrders, addWorkOrderToPermit, removeWorkOrderFromPermit, workOrdersLoading, workOrderPagingObject, loadMore },
  { t }
) => {
  const handleAssignWorkOrder = (item, isSelected) => {
    if (isSelected) {
      removeWorkOrderFromPermit(item);
    } else {
      addWorkOrderToPermit(item);
    }
  };

  return (
    <div className="add-work-order-permits">
      <div className="input-with-dropdown">
        <div className="d-flex align-items-center justify-content-between">
          <label className="search-input-label">{t('CREATE_PERMIT_FORM.PERMIT_WORK_ORDER_LABEL')}</label>
        </div>
        <SearchInput
          onChange={e => handleSearchWorkOrders(e.target.value)}
          placeholder={t('PROJECT.SEARCH.PLACEHOLDER')}
          includeDropdown={selectedWorkOrder ? false : true}
          items={workOrders || []}
          emptyStateLabel={'WORK_ORDERS.EMPTY_STATE'}
          renderItem={(item, index) => {
            const isSelected = selectedWorkOrder && selectedWorkOrder[workOrderFields.id] === item[workOrderFields.id];

            return (
              <>
                <div
                  className={`dropdown-render-item-container ${isSelected ? 'selected' : ''}`}
                  key={`work-order-permit-${item[workOrderFields.id]}`}
                  onClick={() => handleAssignWorkOrder(item, isSelected)}
                >
                  <div className="workorder-wrapper workorder-id">
                    {index === 0 && <p className="f-secondary-dark heading-title bold">{t('PERMITS_TABLE.ID')}</p>}
                    <p className="f-primary light-bold module-name">{item[workOrderFields.id]}</p>
                  </div>
                  <div className="workorder-wrapper workorder-name">
                    {index === 0 && <p className="f-secondary-dark heading-title bold">{t('WORK_ORDER.PERMIT_NAME')}</p>}
                    <div className="f-primary light-bold module-name">
                      <span>{item[workOrderFields.name]}</span>
                      <div className="name">
                        <InfoTooltip
                          customComponent={
                            <PermitWorkOrderPopover
                              workOrderName={item[workOrderFields.name]}
                              workOrderDescription={item[workOrderFields.description]}
                              workOrderComponents={item[workOrderFields.components]}
                            />
                          }
                          offsetY={10}
                          offsetX={-20}
                          Component={() => (
                            <div className="permits-popover">
                              <Icon name="info" handleHover={true} size="xs" />
                            </div>
                          )}
                          containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="workorder-wrapper">
                    {index === 0 && <p className="f-secondary-dark heading-title bold">{t('PERMITS_TABLE.START_DATE')}</p>}
                    <p className="f-primary light-bold module-name">{Helpers.getDateFromUnix(item[workOrderFields.startDate])}</p>
                  </div>
                  <div className="workorder-wrapper">
                    {index === 0 && <p className="f-secondary-dark heading-title bold">{t('PERMITS_TABLE.END_DATE')}</p>}
                    <p className="f-primary light-bold module-name">{Helpers.getDateFromUnix(item[workOrderFields.dueDate])}</p>
                  </div>
                </div>
                {index === workOrders.length - 1 && workOrderPagingObject && (
                  <LoadMore
                    disabled={!workOrderPagingObject[filterFields.hasNext] || workOrdersLoading}
                    loaded={workOrders.length}
                    total={workOrderPagingObject[filterFields.totalItems]}
                    totalPosition="center"
                    isLoading={workOrdersLoading}
                    label="COMPONENT_HISTORY.LOAD_MORE"
                    showButton
                    showTotalUp
                    buttonVariant="success-outline"
                    resultsText="WORK_ORDERS.SHOWING_TOTAL_ITEMS"
                    onClick={loadMore}
                  />
                )}
              </>
            );
          }}
        />
      </div>
    </div>
  );
};

PermitWorkOrderDropdown.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default PermitWorkOrderDropdown;
