import { find, isEmpty, some } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';

import inspectionWorkflowConstants from '../../../inspection-workflow/constants/inspection-workflow-constants';
import cameraHelperVariants from '../../../start-workflow/constants/camera-helper-variants';

class CameraRenderer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      objectElements: [],
    };
  }

  componentDidMount() {
    const { viewer } = this.props;
    if (!viewer) return;
    this.cameraHelperTool = new window.Potree.CameraHelperTool(viewer);
  }

  componentWillUnmount() {
    this.removeAll();
  }

  removeAll = () => {
    const { viewer } = this.props;
    const { objectElements } = this.state;
    if (viewer) {
      objectElements.forEach(defectObject => {
        const cameraHelper = defectObject.cameraHelper;
        cameraHelper.removeEventListener('camera_helper_clicked', this.onMarkerClick);
        viewer.scene.removeCameraHelper(defectObject.cameraHelper);
      });
      this.setState({ objectElements: [] });
    }
  };

  componentDidUpdate = prevProps => {
    let { elements, viewer } = this.props;
    if (viewer && viewer !== prevProps.viewer) {
      this.cameraHelperTool = new window.Potree.CameraHelperTool(viewer);
    }
    if (this.elementsChanged(prevProps.elements, elements) || viewer !== prevProps.viewer) {
      this.removeAll();
      // Some element id changed but arrays has same length
      elements.forEach(element => {
        this.addCameraHelper(element);
      });
    }
  };

  elementsChanged = (prevElements, currentElements) => {
    const { setActiveCamera, cameraFocus } = this.props;
    if (prevElements.length !== currentElements.length) return true;
    // ARRAYS HAS SAME LENGTH
    else if (!isEmpty(prevElements)) {
      prevElements.forEach((element, index) => {
        if (element.ID === currentElements[index].ID) {
          const existingIndex = this.state.objectElements.findIndex(el => el.id === element.ID);
          if (existingIndex > -1) {
            const cameraHelper = this.state.objectElements[existingIndex].cameraHelper;

            // UPDATE ELEMENT
            if (element.selected !== currentElements[index].selected) {
              setActiveCamera(cameraHelper);
              if (currentElements[index].visible && cameraFocus) {
                cameraHelper.selected = currentElements[index].selected;
              }
            }
            if (element.withThumbnail !== currentElements[index].withThumbnail) {
              cameraHelper.thumbnailVisible = currentElements[index].withThumbnail;
            }
            if (element.visible !== currentElements[index].visible) {
              cameraHelper.visible = currentElements[index].visible;
            }
          }
        }
      });
      return some(prevElements, (element, index) => element.ID !== currentElements[index].ID); // Array has the same length but don't have same elements
    }
    return false;
  };

  onMarkerClick = e => {
    const { elements, handleMarkerClick } = this.props;
    const defectObject = this.state.objectElements.find(el => el.cameraHelper.uuid === e.cameraHelper.uuid);
    if (defectObject && defectObject.id) {
      const element = elements.find(d => d.ID === defectObject.id);
      if (element) {
        handleMarkerClick(element);
      } else {
        console.warn('Clicked marker is not present in component state');
      }
    }
  };

  cameraHelperConfig = (camera, image, cameraVariant, selectedColor, visible, thumbnailVisible) => {
    const { viewer } = this.props;
    if (!viewer) return null;
    var helper = new window.Potree.CameraHelper(viewer, camera, image, cameraVariant, selectedColor, visible, thumbnailVisible);

    helper.addEventListener('camera_helper_clicked', this.onMarkerClick);

    return helper;
  };

  fetchReviewedVariant = severity => {
    const res = find(inspectionWorkflowConstants.severity, item => {
      return item.keys.indexOf(severity) > -1;
    });

    return res ? cameraHelperVariants[res.color] : cameraHelperVariants.default;
  };

  addCameraHelper = element => {
    const { viewer, setActiveCamera, cameraFocus } = this.props;
    if (!viewer) return;
    let cameraHelper = null;

    // CAMERA HELPER CONFIGURATION
    if (!element.Transform || isEmpty(element.Transform.Position)) return;
    let camera = new window.THREE.PerspectiveCamera(60, 512 / 341, 5, 10);

    camera.position.set(element.Transform.Position.X, element.Transform.Position.Y, element.Transform.Position.Z);
    camera.rotation.set(element.Transform.Rotation.X * (Math.PI / 180.0), element.Transform.Rotation.Y * (Math.PI / 180.0), element.Transform.Rotation.Z * (Math.PI / 180.0));
    let cameraHelperVariant = cameraHelperVariants.default;

    if (element.Reviewed) {
      cameraHelperVariant = this.fetchReviewedVariant(element.HighestSeverity);
    }

    cameraHelper = this.cameraHelperConfig(camera, { src: element.ThumbnailURL }, cameraHelperVariant, cameraHelperVariants.selected._sphereColor, element.visible, element.withThumbnail);

    if (element.selected) {
      setActiveCamera(cameraHelper);
    }

    // CHECK IF ELEMENT IS ALREADY IN ARRAY
    this.setState(prevState => {
      const existingIndex = prevState.objectElements.findIndex(el => el.id === element.ID);
      if (existingIndex > -1) {
        const newElementObjects = [...prevState.objectElements];
        newElementObjects[existingIndex].cameraHelper = cameraHelper;
        return { objectElements: newElementObjects };
      }

      viewer.scene.addCameraHelper(cameraHelper);
      if (cameraFocus) {
        cameraHelper.selected = element.selected;
      }
      return { objectElements: [...prevState.objectElements, { id: element.ID, cameraHelper }] };
    });
  };

  removeCameraHelper = element => {
    const { viewer } = this.props;

    const existingIndex = this.state.objectElements.findIndex(el => el.id === element.ID);

    if (existingIndex > -1) {
      const cameraHelper = this.state.objectElements[existingIndex].cameraHelper;
      cameraHelper.removeEventListener('camera_helper_clicked', this.onMarkerClick);
      viewer.scene.removeCameraHelper(cameraHelper);
      this.setState(prevState => {
        let newElementObjects = prevState.objectElements.filter(el => el.id !== element.ID);
        return { objectElements: newElementObjects };
      });
    }
  };
  // END

  // selectDefect = element => {
  //   const { getDefectDetails } = this.props;
  //   getDefectDetails(element);
  // };

  // onElementClick = (e, element) => {
  //   const { viewer } = this.props;
  //   e.stopPropagation();
  //   if (!isEmpty(element.Geometry.coordinates) && !isEmpty(element.Geometry.coordinates[0])) {
  //     viewer.zoomToPosition(
  //       { x: element.CameraPosition.coordinates[0], y: element.CameraPosition.coordinates[1], z: element.CameraPosition.coordinates[2] },
  //       { x: element.Geometry.coordinates[0][0], y: element.Geometry.coordinates[0][1], z: element.Geometry.coordinates[0][2] },
  //       500
  //     );
  //   }
  //   this.selectDefect(element);
  // };

  showElement = (e, element) => {
    const { toggleElement, toggleElementTemp } = this.props;

    e.stopPropagation();
    if (element.IsTemp) toggleElementTemp(element.ID);
    else toggleElement(element.ID);
  };

  hideElement = (e, element) => {
    const { toggleElement, toggleElementTemp } = this.props;

    e.stopPropagation();
    if (element.IsTemp) toggleElementTemp(element.ID);
    else toggleElement(element.ID);
  };

  handleSelectAll = (type = null, persistState = false) => {
    const { selectAll, selectAllTemp } = this.props;

    selectAll();
    selectAllTemp();
  };

  handleDeselectAll = (type = null, persistState = false) => {
    const { deselectAll, deselectAllTemp } = this.props;

    deselectAll();
    deselectAllTemp();
    this.removeAllDefectsFromScene();
  };

  render() {
    const { children } = this.props;
    return (
      <>
        {children &&
          children({
            // elementClickHandler: this.onElementClick,
            elementShowHandler: this.showElement,
            elementHideHandler: this.hideElement,
            selectAllHandler: this.handleSelectAll,
            deselectAllHandler: this.handleDeselectAll,
          })}
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  viewer: props.viewer || state.potreeReducer.viewerInstance,
  user: state.userReducer,
});
const mapDispatchToProps = dispatch => ({
  changeField: (form, field, value) => dispatch(change(form, field, value)),
});

CameraRenderer.contextTypes = {
  t: PropTypes.func.isRequired,
};

CameraRenderer.propTypes = {
  children: PropTypes.func.isRequired,
};

CameraRenderer.defaultProps = {
  cameraFocus: true,
};

export default connect(mapStateToProps, mapDispatchToProps)(CameraRenderer);
