import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ItemsTableRenderer from '../../../../../common/items-renderer/components/items-table-renderer';
import Icon from '../../../../../common/icon/components/icon';
import ChangeStatusSignatures from './change-status-signatures';

import Helpers from '../../../../../common/helpers';
import { tableConfig, formConstants, columnTypes } from '../../../constants/change-status-history-constants';
import { fields as signatureFields } from '../isolation-certificate-details/constants/change-status-constants';

import '../../../styles/module-change-status-history-modal.scss';

class ModuleChangeStatusHistoryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null,
    };
  }

  onRowClick = item => {
    //This is commented out due to CLAR-2068, although in design it should be expandable section
    /*const { selectedItem } = this.state;
    if (!selectedItem || item[formConstants.fields.id] !== selectedItem[formConstants.fields.id]) {
      this.setState({ selectedItem: item });
    } else {
      this.setState({ selectedItem: null });
    }*/
  };

  getTableData = () => {
    const { selectedItem } = this.state;
    const { data } = this.props;

    const countVisibleHeaders = (tableConfig.filter(headerItem => !headerItem.visible) || []).length;
    const selectedIndex = data.findIndex(item => selectedItem && item[formConstants.fields.id] === selectedItem[formConstants.fields.id]);
    if (selectedIndex > -1) {
      return [
        ...data.slice(0, selectedIndex),
        {
          ...data[selectedIndex],
          selected: true,
        },
        {
          CustomComponent: () => (
            <td colSpan={countVisibleHeaders} className="details">
              <ChangeStatusSignatures
                assignee={{
                  [signatureFields.assigneeName]: data[selectedIndex][formConstants.fields.signedByUser],
                  [signatureFields.signature]: data[selectedIndex][formConstants.fields.signedSignature],
                }}
                witness={{
                  [signatureFields.witnessName]: data[selectedIndex][formConstants.fields.witnessedByUser],
                  [signatureFields.signature]: data[selectedIndex][formConstants.fields.witnessedSignature],
                }}
                expanded={true}
              />
            </td>
          ),
        },
        ...data.slice(selectedIndex + 1),
      ];
    } else {
      return data;
    }
  };

  render() {
    const { t } = this.context;
    const { selectedItem } = this.state;

    const tableData = this.getTableData();
    return (
      <div className="module-change-status-history-modal">
        <ItemsTableRenderer
          tableConfig={tableConfig}
          translationModule={t}
          data={tableData}
          onRowClick={(e, item) => this.onRowClick(item)}
          emptyStateText={'CHANGE_STATUS_HISTORY.TABLE.EMPTY_STATE'}
          formatCell={(value, type, index, item) => {
            if (type === columnTypes.expand) {
              return (
                <Icon name={selectedItem && item[formConstants.fields.id] === selectedItem[formConstants.fields.id] ? 'chevron-up' : 'chevron-down'} className="toggle-action toggle-action-expand" />
              );
            }
            return Helpers.formatCell(value, type, index, item);
          }}
        />
      </div>
    );
  }
}

ModuleChangeStatusHistoryModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ModuleChangeStatusHistoryModal;
