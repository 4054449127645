import routesConstants from './routes';
import { Api } from '../../core/api';
import { API_BASE_URL } from '../../common/constants';

const createUser = async values => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CREATE_USER}`, values, { needLoader: true });
};

const editUser = async values => {
  return await Api.post(`${API_BASE_URL}${routesConstants.EDIT_USER}`, values, { needLoader: true });
};

const getUserDetails = async values => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_USER_DETAILS}`, values, { needLoader: true });
};

const getUsers = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_USERS}`, params);
};

const getUsersTableData = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_USERS_TABLE_DATA}`, params);
};

const activateUser = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ACTIVATE_USER}`, params);
};
const deleteUser = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_USER}`, params);
};

const getErrorCodes = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_ERROR_CODES}`);
};

export default {
  getUsers,
  getUsersTableData,
  activateUser,
  deleteUser,
  createUser,
  editUser,
  getUserDetails,
  getErrorCodes,
};
