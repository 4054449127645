import { fields } from './work-order-right-side-constants';

export const formFields = {
  workCompleted: {
    name: fields.workCompleted,
    label: 'WORK_ORDER.WORK_COMPLETED_LABEL',
    id: 'work-order-completion-form-work-completed',
    maxChars: 500,
  },
  recommendations: {
    name: fields.recommendations,
    label: 'WORK_ORDER.RECOMMENDATIONS_LABEL',
    id: 'work-order-completion-form-recommendations',
    maxChars: 500,
  },
  actualEndTime: {
    name: fields.actualEndTime,
    label: 'WORK_ORDER.ACTUAL_END_DATE_LABEL',
    id: 'work-order-completion-form-actual-end-date',
    dateFormat: 'MMMM d, yyyy h:mm aa',
    timeFormat: 'h:mm aa',
  },
  actualWrenchTime: {
    name: fields.actualWrenchTime,
    label: 'WORK_ORDER.ACTUAL_WRENCH_TIME_LABEL',
    id: 'work-order-completion-form-actual-wrench-time',
  },
  signedBy: {
    name: fields.signedBy,
    label: 'WORK_ORDER.ACTUAL_SIGNED_BY_LABEL',
    id: 'work-order-completion-form-signed-by',
  },
  signedAt: {
    name: fields.signedAt,
    label: 'WORK_ORDER.ACTUAL_SIGNED_AT_LABEL',
    id: 'work-order-completion-form-signed-at',
  },
};
