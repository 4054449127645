import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { debounce, get, isEmpty, isString, keys, map, reduce, toInteger } from 'lodash';
import {
  clearUnsavedChangesDirty,
  deselectAllComponents,
  deselectAllComponentsTemp,
  selectAllComponents,
  selectAllComponentsTemp,
  setComponentsFilters,
  setDisplayUnassignedComponentsLevel,
  setInspectionComponents,
  setInspectionComponentsLoading,
  toggleComponent,
  toggleComponentTemp,
} from '../../actions/action-creators';
import { updateElementGeometry } from '../../actions/inspection-actions';

import AccessRenderer from '../../../../common/access-renderer/components/access-renderer';
import Icon from '../../../../common/icon/components/icon';
import ItemsTableRenderer from '../../../../common/items-renderer/components/items-table-renderer';
import ModuleHeader from './common/module-header';
import SearchText from './common/search-text';
import VisibilityBox from './common/visibility-box';
import ComponentFilter from './filters/components-filter';
import InspectionRenderer from './inspection-renderer';

import { FEATURES, SHOW_GEOMETRY_MISSING_WARNING } from '../../../../common/constants';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../common/permissions-constants';
import { routes } from '../../../../common/routes-constants';
import { defaultFilter, filterParams, formConstants, sortingDirection, tableConfig } from '../../constants/component-constants';

import Helpers from '../../../../common/helpers';

import ReadMoreTooltip from '../../../../common/read-more-tooltip/components/read-more-tooltip';
import RenderIf from '../../../../common/render-if/components/render-if';
import InfoTooltip from '../../../../common/tooltip/components/info-tooltip';
import { setCHshow, setSearchText } from '../../actions/action-creators';
import '../../styles/components-list.scss';
import CHDisplay from './components/components/ch-display';

class Components extends Component {
  constructor(props) {
    super(props);
    const { location } = props;
    const { query } = location;

    this.searchChangeDebounced = debounce(this.getComponents, 300);
    this.state = {
      advancedFilter: {},
    };
    this.ProjectID = toInteger(get(query, 'project_id'));
    //Checks if user has permission for CH and if it is allowed in config
    this.userCanSeeCH = FEATURES.componentHierarchy.componentsModule.visible;
  }

  componentWillUnmount() {
    const { setInspectionComponents, setComponentsFilters, setDisplayUnassignedComponentsLevel } = this.props;
    setInspectionComponents([]);
    setComponentsFilters(defaultFilter);
    setDisplayUnassignedComponentsLevel(false);
  }

  getComponents = (params, loadMore = false) => {
    const { getComponents, searchText, setClusteringFilters, setComponentsFilters, filters, chShow } = this.props;
    let { advancedFilter } = this.state;
    //normalize advanced filter
    const newAdvancedFilter = reduce(
      advancedFilter,
      (obj, it, key) => {
        if ([filterParams.componentTypeFilter, filterParams.componentSubTypeFilter].indexOf(key) > -1) {
          obj[key] = advancedFilter[key].toString();
        } else if ([filterParams.createdByFilter].indexOf(key) > -1) {
          obj[key] = map(advancedFilter[key], ({ ID, UserID }) => ID || UserID);
        } else if ([filterParams.dateFrom].indexOf(key) > -1) {
          obj[key] = (advancedFilter[key] && Helpers.getUnixDate(new Date(advancedFilter[key]).getTime())) || null;
        } else if ([filterParams.dateTo].indexOf(key) > -1) {
          // sets end/to dates to end of day 23:59:59 to include that day in filter results
          obj[key] = (advancedFilter[key] && Helpers.getUnixDate(new Date(advancedFilter[key]).setHours(23, 59, 59))) || null;
        } else if (
          [filterParams.hasNotificationFilter, filterParams.hasObservationFilter, filterParams.hasWorkOrderFilter, filterParams.hasLocationFilter, filterParams.isCriticalEquipmentFilter].indexOf(
            key
          ) > -1
        ) {
          obj[key] = Helpers.castToggleRadioInputAnswer(advancedFilter[key]);
        }
        return obj;
      },
      {}
    );

    setClusteringFilters({ ...newAdvancedFilter });
    getComponents(
      searchText,
      { ...newAdvancedFilter, ...filters, ...params, ProjectID: this.ProjectID },
      (arr, incomingFilter, c) => {
        //Per page is set this way due to strange behavior on initial fetch, perPage gets overridden to 0 instead to stay 20
        setComponentsFilters({ ...incomingFilter, [filterParams.perPage]: defaultFilter[filterParams.perPage] });
        if (loadMore && chShow) {
          Helpers.scrollIntoView('components-table', `row-${filters[filterParams.lastSeen] - 1}`, -800);
        }
      },
      loadMore
    );
  };

  componentDidUpdate = prevProps => {
    const { searchText, chShow, displayUnassignedComponentsLevel, setComponentsFilters, filters, setInspectionComponents, setInspectionComponentsLoading } = this.props;

    if (isString(searchText) && prevProps.searchText !== searchText) {
      setInspectionComponents([]);
      setInspectionComponentsLoading(true);

      const newFilters = {
        ...filters,
        [filterParams.searchText]: searchText,
        [filterParams.lastSeen]: defaultFilter[filterParams.lastSeen],
        [filterParams.hasNext]: defaultFilter[filterParams.hasNext],
      };
      setComponentsFilters(newFilters);
      this.searchChangeDebounced(newFilters);
    }
    if (prevProps.chShow !== chShow || prevProps.displayUnassignedComponentsLevel !== displayUnassignedComponentsLevel) {
      const { setSearchText } = this.props;
      setSearchText('');
      setComponentsFilters(defaultFilter);
      this.getComponents(defaultFilter);
    }
  };

  componentDidMount = () => {
    const { queryItem, setCHshow } = this.props;
    let params = Object.assign({}, defaultFilter);

    setCHshow(this.userCanSeeCH);

    if (queryItem) {
      params = {
        ...params,
        IncludedIDs: [queryItem],
      };
    }
    setComponentsFilters(params);
    this.getComponents(params);
  };

  onColumnSort = (column, HierarchyID) => {
    const { filters, setComponentsFilters, searchText } = this.props;
    setComponentsFilters({
      ...defaultFilter,
      [filterParams.sortByColumn]: column,
      [filterParams.sortDirection]: filters[filterParams.sortDirection] === sortingDirection.asc ? sortingDirection.desc : sortingDirection.asc,
      [filterParams.hierarchyID]: HierarchyID,
      [filterParams.searchText]: searchText,
    });

    this.searchChangeDebounced();
  };

  loadMore = () => {
    this.searchChangeDebounced(null, true);
  };

  openAdvancedFilter = () => {
    const { t } = this.context;
    const { setModalState, setComponentsFilters } = this.props;
    const { advancedFilter } = this.state;
    const closeAction = () => setModalState({ isOpen: false });

    const modalData = {
      isOpen: true,
      type: 'none',
      title: t('ADVANCED_FILTERS.TITLE'),
      CustomContent: () => (
        <ComponentFilter
          submitForm={values => {
            this.setState({
              advancedFilter: values,
            });
            setComponentsFilters(defaultFilter);
            closeAction();
            this.searchChangeDebounced();
          }}
          resetFilter={() => {
            this.setState({
              advancedFilter: {},
            });
            setComponentsFilters(defaultFilter);
            closeAction();
            this.searchChangeDebounced();
          }}
          initialValues={advancedFilter}
          closeAction={closeAction}
        />
      ),
      customClassName: 'modal-large defects-filter',
      closeAction,
    };
    setModalState(modalData);
  };

  createNewComponentHandler = () => {
    const { createNewComponent, setComponentsFilters, filters } = this.props;

    const callbackOnCreate = () => {
      setComponentsFilters({ ...filters, [filterParams.totalItems]: filters[filterParams.totalItems] + 1 });
    };

    createNewComponent(callbackOnCreate);
  };

  handleCreateNewComponent = async () => {
    const { isDirty, clearUnsavedChangesDirty } = this.props;

    if (isDirty) {
      if (window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
        await clearUnsavedChangesDirty();
        this.createNewComponentHandler();
      }
    } else {
      this.createNewComponentHandler();
    }
  };

  goToHistoryPage = queryParams => {
    const { setDisplayUnassignedComponentsLevel, setCHshow } = this.props;
    setDisplayUnassignedComponentsLevel(false);
    setCHshow(true);
    const path = `${routes.protectedRoutes.modulesHistory.fullPath}${queryParams}`;
    Helpers.goTo(path);
  };

  goToAssignComponentsPage = queryParams => {
    const { setCHshow } = this.props;
    setCHshow(true);
    const path = `${routes.protectedRoutes.assignComponents.fullPath}${queryParams}`;
    Helpers.goTo(path);
  };

  render() {
    const { t } = this.context;
    const { advancedFilter } = this.state;
    const { search } = this.props.location;
    const {
      title,
      backAction,
      elements,
      deselectAll,
      selectAll,
      toggleComponent,
      deselectAllComponentsTemp,
      selectAllComponentsTemp,
      toggleComponentTemp,
      updateGeometry,
      scrollToElement,
      generateCustomIdentificator,
      elementsClustered,
      componentsLoading,
      handleItemClick,
      queryItem,
      selectedClusterElement,
      viewer,
      tableConfig,
      moduleActionsMenu,
      icon,
      user,
      setCHshow,
      chShow,
      filters,
      fullScreen,
      setDisplayUnassignedComponentsLevel,
      componentLocationEditing,
      setComponentsFilters,
    } = this.props;

    const toggleHierarchyView = toggle => {
      if (toggle && chShow !== true) {
        this.setState({ advancedFilter: {} });
        setComponentsFilters(defaultFilter);
        setCHshow(true);
      } else if (!toggle && chShow !== false) {
        setCHshow(false);
        setDisplayUnassignedComponentsLevel(false);
      }
    };

    const customActionForFullScreen = () => {
      moduleActionsMenu[0].action && moduleActionsMenu[0].action();
      setCHshow(true);
    };
    let componentActionsMenu = Object.assign([], moduleActionsMenu);
    componentActionsMenu[0] = { ...moduleActionsMenu[0], action: customActionForFullScreen };
    componentActionsMenu = FEATURES.modulesHistory?.visible
      ? [
          ...componentActionsMenu,
          {
            title: 'INSPECTION_FULLSCREEN.VIEW_HISTORY',
            action: () => this.goToHistoryPage(search),
          },
        ]
      : componentActionsMenu;

    componentActionsMenu = FEATURES.componentHierarchy.assignComponents.visible
      ? [
          ...componentActionsMenu,
          {
            title: 'COMPONENTS.ASSIGN_COMPONENTS',
            action: () => this.goToAssignComponentsPage(search),
            access: {
              visibleFor: PERMISSIONS[PERMISSION_TYPES.componentsHierarchy].edit.name,
            },
          },
        ]
      : componentActionsMenu;

    componentActionsMenu = this.userCanSeeCH
      ? [
          ...componentActionsMenu,
          {
            title: 'COMPONENTS.VIEW_AS',
            placement: viewer ? 'right' : 'left',
            children: [
              { id: 1, title: 'COMPONENTS.VIEW_AS_CH', action: () => toggleHierarchyView(true) },
              { id: 2, title: 'COMPONENTS.VIEW_AS_NO_CH', action: () => toggleHierarchyView(false) },
            ],
          },
        ]
      : componentActionsMenu;

    return (
      <AccessRenderer
        visibleFor={PERMISSIONS[PERMISSION_TYPES.components].edit.name}
        id={selectedClusterElement && selectedClusterElement[formConstants.fields.createdById]}
        ownerRequiredPermission={PERMISSIONS[PERMISSION_TYPES.components].create.name}
      >
        {({ hasAccess }) => {
          return (
            <InspectionRenderer
              deselectAll={deselectAll}
              selectAll={selectAll}
              toggleElement={toggleComponent}
              deselectAllTemp={deselectAllComponentsTemp}
              selectAllTemp={selectAllComponentsTemp}
              toggleElementTemp={toggleComponentTemp}
              selectElement={(data, autoScroll) => {
                handleItemClick(data.ID);
                if (autoScroll) {
                  scrollToElement(data);
                }
              }}
              updateElementGeometry={(...args) => (hasAccess ? updateGeometry(...args) : null)}
              queryItem={queryItem}
              elements={elementsClustered.map(item => {
                if (item[formConstants.fields.id] === queryItem) {
                  return { ...item, enableMove: componentLocationEditing };
                } else {
                  return item;
                }
              })}
              disableMove={!componentLocationEditing}
              viewer={viewer}
            >
              {({ elementClickHandler, elementShowHandler, elementHideHandler, selectAllHandler, deselectAllHandler }) => (
                <div className="components-list">
                  <ModuleHeader
                    {...{
                      title,
                      icon,
                      backAction,
                      actionsMenu: componentActionsMenu,
                      actionButtontext: 'CREATE_BUTTON_TEXT.NEW',
                      handleActionButtonClick: this.handleCreateNewComponent,
                      actionButtonProps: {
                        user,
                        visibleFor: PERMISSIONS[PERMISSION_TYPES.components].create.name,
                      },
                    }}
                  />
                  <RenderIf if={!chShow}>
                    <div className="advanced-filter">
                      <SearchText maxLength={60} />
                      <p className="f-secondary-green noselect pointer link" onClick={() => this.openAdvancedFilter()}>
                        {t('ADVANCED_FILTER', { active: keys(advancedFilter).length })}
                      </p>
                    </div>

                    {viewer && (
                      <VisibilityBox
                        {...{
                          selectAllHandler,
                          deselectAllHandler,
                        }}
                      />
                    )}

                    <ItemsTableRenderer
                      isLoading={componentsLoading}
                      tableConfig={tableConfig}
                      translationModule={t}
                      data={map(elements, item => ({ ...item, selected: item[formConstants.fields.id] === queryItem ? true : false }))}
                      onRowClick={elementClickHandler}
                      loadMore={{
                        visible: filters[filterParams.hasNext],
                        disabled: componentsLoading,
                        onClick: this.loadMore,
                      }}
                      emptyStateText={'INSPECTION_COMPONENTS.EMPTY_STATE'}
                      customIdentificatorFunction={generateCustomIdentificator}
                      sortingObj={filters}
                      onColumnSort={this.onColumnSort}
                      formatCell={(value, type, index, item) => {
                        const { t } = this.context;
                        if (type === formConstants.fields.unresolvedDefectsCount) {
                          return (
                            <>
                              {item[formConstants.fields.integrationName] === 'SAP' && (
                                <div className="missing-pin">
                                  <Icon name="sapIcon" handleHover={false} />
                                </div>
                              )}
                              {item[formConstants.fields.critical] && !fullScreen ? (
                                <div className="missing-pin">
                                  <InfoTooltip
                                    offsetY={8}
                                    offsetX={8}
                                    customComponent={<p className="f-primary">{t('CRITICAL_EQUIPMENT.TITLE')}</p>}
                                    Component={() => <Icon name="critical-hazard" warning handleHover={false} size="xs" />}
                                    componentProps={{ title: '' }}
                                    containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
                                  />
                                </div>
                              ) : null}
                              {SHOW_GEOMETRY_MISSING_WARNING && (!item.Geometry || isEmpty(item.Geometry) || isEmpty(item.Geometry.coordinates)) && (
                                <div className="missing-pin">
                                  <InfoTooltip
                                    offsetY={8}
                                    offsetX={8}
                                    customComponent={<p className="f-primary">{t('INFO.MISSING_LOCATION')}</p>}
                                    Component={() => <Icon name="map-crossed" warning handleHover={false} size="xs" />}
                                    componentProps={{ title: '' }}
                                    containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
                                  />
                                </div>
                              )}
                              {item[formConstants.fields.unresolvedDefectsCount] > 0 && (
                                <div className="unresolved-def" title={t('COMPONENT_LIST.COMPONENT_OBSERVATIONS')}>
                                  <span className="f-white">{`${item[formConstants.fields.unresolvedDefectsCount] > 9 ? '9+' : item[formConstants.fields.unresolvedDefectsCount]} `}</span>
                                </div>
                              )}
                              {viewer && (
                                <Icon
                                  name="eye"
                                  active={item.visible}
                                  size="xs"
                                  onClick={item.visible ? e => elementHideHandler(e, item) : e => elementShowHandler(e, item)}
                                  title={t('ITEMS_LIST.VISIBILTY_ICON')}
                                />
                              )}
                            </>
                          );
                        }

                        if (type === formConstants.fields.description) {
                          return value ? (
                            <span className="description-column__container">
                              <ReadMoreTooltip value={value} onClick={() => handleItemClick(item[formConstants.fields.id])} />
                            </span>
                          ) : (
                            <span className="description-column__container">-</span>
                          );
                        }

                        if (type === formConstants.fields.critical) {
                          return value ? (
                            <div className="missing-pin">
                              <InfoTooltip
                                offsetY={8}
                                offsetX={8}
                                customComponent={<p className="f-primary">{t('CRITICAL_EQUIPMENT.TITLE')}</p>}
                                Component={() => <Icon name="critical-hazard" warning handleHover={false} size="xs" />}
                                componentProps={{ title: '' }}
                                containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
                              />
                            </div>
                          ) : (
                            '-'
                          );
                        }

                        return value;
                      }}
                    />
                    {filters[filterParams.totalItems] > 0 && (
                      <p className="f-secondary-dark total">
                        {t('SHOWING_TOTAL_ITEMS', {
                          items: elements?.length || 0,
                          totalItems: filters[filterParams.totalItems],
                        })}
                      </p>
                    )}
                  </RenderIf>
                  <RenderIf if={chShow}>
                    <CHDisplay
                      elements={elements}
                      totalItems={filters[filterParams.totalItems]}
                      onElementClick={elementClickHandler}
                      getComponents={this.getComponents}
                      componentsFilters={{ ...filters }}
                      defaultComponentsFilters={defaultFilter}
                      isLoadingComponents={componentsLoading}
                      onComponentsColumnSort={this.onColumnSort}
                      hideElements={elementHideHandler}
                      showElements={elementShowHandler}
                      selectAll={selectAllHandler}
                      deselectAll={deselectAllHandler}
                      fullScreen={fullScreen}
                    />
                  </RenderIf>
                </div>
              )}
            </InspectionRenderer>
          );
        }}
      </AccessRenderer>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  deselectAll: type => dispatch(deselectAllComponents(type)),
  selectAll: type => dispatch(selectAllComponents(type)),
  toggleComponent: id => dispatch(toggleComponent(id)),
  // TEMP
  deselectAllComponentsTemp: type => dispatch(deselectAllComponentsTemp(type)),
  selectAllComponentsTemp: type => dispatch(selectAllComponentsTemp(type)),
  toggleComponentTemp: id => dispatch(toggleComponentTemp(id)),
  //
  updateGeometry: data => dispatch(updateElementGeometry(data)),
  setInspectionComponents: data => dispatch(setInspectionComponents(data)),
  setCHshow: chShow => dispatch(setCHshow(chShow)),
  setSearchText: searchText => dispatch(setSearchText(searchText)),
  setComponentsFilters: filters => dispatch(setComponentsFilters(filters)),
  setDisplayUnassignedComponentsLevel: displayUnassignedComponentsLevel => dispatch(setDisplayUnassignedComponentsLevel(displayUnassignedComponentsLevel)),
  setInspectionComponentsLoading: data => dispatch(setInspectionComponentsLoading(data)),
  clearUnsavedChangesDirty: () => dispatch(clearUnsavedChangesDirty()),
});

const mapStateToProps = state => ({
  chShow: state.inspectionReducer.chShow,
  displayUnassignedComponentsLevel: state.inspectionReducer.displayUnassignedComponentsLevel,
  filters: state.inspectionReducer.componentsFilter,
  componentLocationEditing: state.inspectionReducer.componentLocationEditing,
  viewer: state.potreeReducer.viewerInstance,
  isDirty: state.unsavedChangesReducer.isDirty,
});

Components.contextTypes = {
  t: PropTypes.func.isRequired,
};

Components.defaultProps = {
  tableConfig: tableConfig,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Components));
