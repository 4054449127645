import React, { Component } from 'react';

import constants from '../../start-workflow/constants/orientation-constants';
import { isEmpty } from 'lodash';

import CircleLoader from '../../../common/circle-loader/components/circle-loader';
import LegendSquare from '../../inspection-workflow/components/legend-square';
import ExpandableSection from '../../../common/expandable-section/components/expandable-section';

import Helpers from '../../../common/helpers';

import '../styles/image-list-view.scss';

class ImageListView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      delay: true,
    };
  }

  onClick = (index, item) => {
    const { handleImageActive, delayMs } = this.props;
    const { delay } = this.state;
    if (delay) {
      this.setState({ delay: false });
      handleImageActive(index, item);
      setTimeout(() => {
        this.setState({ delay: true });
      }, delayMs);
    }
  };

  handleClick = (index, item) => {
    const { handleImageActive, delayMs } = this.props;
    if (!handleImageActive) {
      return null;
    }

    return delayMs ? this.onClick(index, item) : handleImageActive(index, item);
  };

  renderImages = images => {
    const { orientation, showReviewed, activeIndex, uploadProgressStyle, gridImageType, CustomComponent, withTitle } = this.props;

    return images.map((item, index) => {
      return (
        <div className={`image-item  ${item.selected ? 'selected' : ''} ${index === activeIndex ? 'active' : ''}`} key={index}>
          <input id={`${index}`} name={`${index}`} type="checkbox" onChange={() => null} checked={(isNaN(activeIndex) && item.selected) || index === activeIndex || false} />

          <label
            className="image-wrapper"
            htmlFor={`${index}`}
            onClick={() => this.handleClick(index, item)}
            style={orientation === constants.orientation.grid ? { background: `transparent url("${item.src && item.src.getImageSize(gridImageType, item)}") center / contain no-repeat` } : null}
          >
            <p className="f-primary image-name" title={withTitle ? item.name : ''}>
              {item.name}
            </p>
            {item.progress > 0 && item.progress < 100 && <CircleLoader {...uploadProgressStyle} percent={item.progress} strokeWidth={20} />}
            {showReviewed && item.reviewed && (
              <div className="icon-item reviewed">
                <LegendSquare className={`${Helpers.fetchReviewedClass(item.highestSeverity)}`} />
              </div>
            )}
            {CustomComponent && <CustomComponent index={index} item={item} />}
          </label>
        </div>
      );
    });
  };

  renderSectionView = imageSections => {
    const { handlePreventClick, PaginatiorComponent } = this.props;
    return imageSections.map((item, index) => {
      return (
        <ExpandableSection
          key={index}
          handlePreventClick={handlePreventClick ? ev => handlePreventClick(index, ev) : null}
          customExpanded={item.expanded}
          title={item.title}
          CustomComponent={item.customComponent}
        >
          {this.renderImages(item.images)}
          {item.hasPagination && PaginatiorComponent(item)}
        </ExpandableSection>
      );
    });
  };

  render() {
    const { images, renderSectionView, orientation, className, withNumbering } = this.props;
    if (isEmpty(images)) {
      return null;
    }

    return (
      <div className={`image-list-view ${className || ''} ${renderSectionView ? 'section-view' : ''}`}>
        <div className={`list-wrapper ${orientation || 'grid'} ${withNumbering ? 'number-list' : ''}`}>
          {renderSectionView && this.renderSectionView(images)}
          {!renderSectionView && this.renderImages(images)}
        </div>
      </div>
    );
  }
}

export default ImageListView;
