import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Helpers from '../../../../common/helpers';

import ImageWithDrawing from '../../../../common/image-components/components/image-with-drawing';
import LegendSquare from '../../../inspection-workflow/components/legend-square';
import Icon from '../../../../common/icon/components/icon';

import { AMAZON_IMAGE_SIZES } from '../../../../common/constants';

import '../../styles/point-images-list.scss';

class PointImagesList extends Component {
  render() {
    const { items, handleItemClick, currentSlideIndex, CustomComponent, imageType, id, cols } = this.props;

    const gridTemplateColumns = '1fr '.repeat(cols);

    return (
      <div className="image-list" style={{ gridTemplateColumns }} id={id}>
        {items?.map((item, index) => {
          const showReviewed = item.Contextualized === false;
          return (
            <div
              onClick={() => (handleItemClick ? handleItemClick(item, index) : null)}
              key={index}
              className={`image-item${index === currentSlideIndex ? ' active' : ''}`}
              title={item.name}
              id={item.name}
            >
              <ImageWithDrawing disableZoomAndMove={true} className="image-item__image-container" imgClass="image-thumbnail" imageType={imageType} item={item} drawings={item.Drawings} />
              {CustomComponent ? (
                CustomComponent(item, index)
              ) : (
                <Icon name="newTab" size="xs" className="new-tab" onClick={e => Helpers.openInNewTab(e, item.src.getImageSize(AMAZON_IMAGE_SIZES.large.name, item))} />
              )}
              {showReviewed && item.Reviewed && (
                <div className="icon-item reviewed">
                  <LegendSquare className={`${Helpers.fetchReviewedClass(item.HighestSeverity)}`} />
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  }
}

PointImagesList.contextTypes = {
  t: PropTypes.func.isRequired,
};

PointImagesList.defaultProps = {
  cols: 2,
};

export default PointImagesList;
