import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import FieldUpload from '../right-toolbar/component-assets';

import Helpers from '../../../../common/helpers';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../common/permissions-constants';
import { AMAZON_IMAGE_SIZES } from '../../../../common/constants';
import { fields } from './constants/work-order-right-side-constants';

import '../../styles/work-order-section-files.scss';
import '../../styles/work-order-files.scss';
import EmptyState from '../../../../common/empty-state-v2/components/empty-state';

const WorkOrderSectionFiles = ({ disabled, module, ...rest }, { t }) => {
  const imageType = useState(AMAZON_IMAGE_SIZES.small.name);
  const filesByCategory = {};

  if (isEmpty(module?.workorderFiles)) {
    const { sectionAction, handleCustomAction } = rest;
    return (
      <EmptyState
        emptyStateText={t('NO_FILES_ADDED')}
        showButton
        transparent
        buttonText={t('CREATE_REGIME_FORM.ADD_FILES')}
        buttonAction={() => handleCustomAction(sectionAction)}
        buttonDisabled={disabled}
      />
    );
  }

  if (!isEmpty(module?.workorderFiles)) {
    module?.projectDMSCategories.forEach(c => {
      const category = c.ID;

      if (!category) return null;

      const categoryFiles = module?.workorderFiles.filter(cf => cf.CategoryID === category) || [];
      filesByCategory[c.Name] = categoryFiles;
    });
  }

  return module?.projectDMSCategories.map(category => {
    return (
      <FieldUpload
        disabled={disabled}
        label={t(category.Name)}
        labelClass="f-secondary-dark defect-form__label"
        noFileText={t('NO_FILES_IN_CATEGORY')}
        files={filesByCategory[category.Name]}
        imageType={imageType}
        showUploadSvg={false}
        actionsMenu={[
          {
            title: 'COMPONENT_ASSETS_DROPDOWN.SETTINGS_MENU.ITEM_1',
            action: data => {
              module?.openFile(data);
            },
          },
          {
            title: 'COMPONENT_ASSETS_DROPDOWN.SETTINGS_MENU.ITEM_2',
            action: data => {
              Helpers.getFileExtensionAndDownload(data);
            },
          },
          {
            title: 'COMPONENT_ASSETS_DROPDOWN.SETTINGS_MENU.UNLINK_FILE',
            action: file => {
              if (module?.unlinkFile) {
                module?.unlinkFile(file);
              } else if (module?.unlinkCompletionFile) {
                module?.unlinkCompletionFile(file);
              }
            },
            access: {
              id: module[fields.createdBy],
              ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.workOrders].create.name,
              visibleFor: [PERMISSIONS[PERMISSION_TYPES.workOrders].edit.name],
            },
          },
        ]}
      />
    );
  });
};

WorkOrderSectionFiles.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default WorkOrderSectionFiles;
