import { updateSyncErrors } from 'redux-form';

import { userLogin, userLogout, userLoginError, setLoginStep, setLoginTempToken, setResendExpiration } from './action-creators';
import { handleGlobalLoader } from '../../../common/global-loader/actions/loader-actions';

import { setTheme } from '../../profile/actions/profile-actions';
import { setLanguage } from 'redux-i18n';

import AuthApi from '../../../api/login/actions';
import Helpers from '../../../common/helpers';
import routesConstants from '../../../common/routes-constants';
import { store } from '../../../index';

import { isEmpty, get } from 'lodash';

import { THEMES, DEFAULT_LANGUAGE, FORMS } from '../../../common/constants';
import loginConstants from '../constants/login-constants';

export const login = (standardLogin, token, params) => {
  return async dispatch => {
    try {
      let res = {};
      if (token && token) {
        res = await AuthApi.tokenLogin({ Token: token });
      } else {
        res = await AuthApi.login({ email: standardLogin.email, password: standardLogin.password });
      }
      let {
        data: { Data },
      } = res;
      if (Data) {
        //set default theme
        dispatch(setUser(Data, params));
      } else {
        const errorMessage = Helpers.getLoginValidation();
        dispatch(loginError(errorMessage));
      }
    } catch (error) {
      const errorMessage = Helpers.getLoginValidation(error);
      dispatch(loginError(errorMessage));
    }
  };
};

export const authenticateUser = params => {
  return async dispatch => {
    try {
      const res = await AuthApi.authenticateUser(params.email, params.password);
      const data = get(res, 'data.Data');
      const { MFAMethod } = res.data.Data;
      if (!isEmpty(data)) {
        switch (MFAMethod) {
          case loginConstants.verificationTypes.email:
            dispatch(setLoginStep(loginConstants.loginSteps.EMAIL_VERIFICATION));
            break;
          case loginConstants.verificationTypes.authenticatior:
            dispatch(setLoginStep(loginConstants.loginSteps.ATUHENTICATOR_VERIFICATION));
            break;
          default:
            dispatch(setLoginStep(loginConstants.loginSteps.EMAIL_VERIFICATION));
            break;
        }
        dispatch(setLoginTempToken(data.Token));

        dispatch(setResendExpiration(data.ExpiresAt));
      }
    } catch (error) {
      const errorMessage = Helpers.getLoginValidation(error);
      dispatch(loginError(errorMessage));
    }
  };
};

export const verifyUser = (params, type, redirectRoute) => {
  return async dispatch => {
    try {
      let res = {};
      switch (type) {
        case loginConstants.loginSteps.EMAIL_VERIFICATION:
          res = await AuthApi.verifyUserEmail(params);
          break;
        case loginConstants.loginSteps.ATUHENTICATOR_VERIFICATION:
          res = await AuthApi.verifyUserAuthenticator(params);
          break;
        default:
          res = null;
          break;
      }

      let data = get(res, 'data.Data');
      if (!isEmpty(data)) {
        dispatch(setUser(data, null, redirectRoute));
      }
    } catch (error) {
      dispatch(
        updateSyncErrors(FORMS.emailVerification, {
          [loginConstants.verificationFields.code]: 'LOGIN.MFA_WRONG_CODE',
        })
      );
    }
  };
};

export const resend = (params, type, callback) => {
  return async dispatch => {
    switch (type) {
      case loginConstants.loginSteps.EMAIL_VERIFICATION:
        await AuthApi.resendEmail(params);
        break;
      default:
        break;
    }
    callback();
  };
};

export const clearTempLoginData = () => {
  return async dispatch => {
    dispatch(setLoginTempToken(null));
    dispatch(setLoginStep(loginConstants.loginSteps.LOGIN));
    dispatch(setResendExpiration());
  };
};

export const setUser = (Data, params, redirectRoute) => {
  return async dispatch => {
    Data.Theme = Data.Theme || THEMES.light.name;

    dispatch(userLogin(Data));
    dispatch(setTheme(Data.Theme));
    dispatch(setLanguage(Data.Language || DEFAULT_LANGUAGE));
    dispatch(loginError(null));
    if (Helpers.isGuestUser(Data)) {
      Helpers.goTo(routesConstants.routes.protectedRoutes.inspections.fullPath, params);
    } else {
      Helpers.goTo(redirectRoute || routesConstants.defaultRoute);
    }
  };
};

export const logout = withHardReaload => {
  return dispatch => {
    dispatch(handleGlobalLoader(true));
    dispatch(userLogout());
    dispatch(setTheme());
    setTimeout(() => {
      if (!withHardReaload) {
        Helpers.goTo(routesConstants.routes.unProtectedRoutes.login.fullPath);
      } else {
        //logout user and cancel his uploads

        window.location.href = '/';
      }
      dispatch(handleGlobalLoader(false));
    }, 200);
  };
};

export const updateUser = props => {
  return dispatch => {
    dispatch(userLogin(props));
  };
};

export const loginError = errorMessage => {
  return dispatch => {
    dispatch(userLoginError(errorMessage));
    if (errorMessage) Helpers.goTo(routesConstants.routes.unProtectedRoutes.login.fullPath);
  };
};

export const dispatchUserLogoutWired = () => store.dispatch(logout(true));
