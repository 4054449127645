import { formConstants, descriptionMaxChars } from '../components/isolations/constants/constants';

export const validate = values => {
  const errors = {};

  if (!values[formConstants.fields.name]) {
    errors[formConstants.fields.name] = 'ISOLATION_TEMPLATE_FORM_VALIDATION.USER_NAME_REQUIRED';
  }

  if (values[formConstants.fields.name] && values[formConstants.fields.name].length < 3) {
    errors[formConstants.fields.name] = 'ISOLATION_TEMPLATE_FORM_VALIDATION.USER_NAME_LENGTH';
  }

  if (values[formConstants.fields.description] && values[formConstants.fields.description].length > descriptionMaxChars) {
    errors[formConstants.fields.description] = 'ISOLATION_TEMPLATE_FORM_VALIDATION.DESCRIPTION_LENGTH';
  }

  return errors;
};
