import { debounce, findIndex, remove } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import CommentsTab from '../../../../common/comments-tab/components/comments-tab';
import { commentFields } from '../../../../common/comments-tab/constants/constants';
import FlowStatusWrapper from '../../../../common/flow-status/components/flow-status-wrapper';
import SimpleLoader from '../../../../common/global-loader/components/simple-loader';
import Helpers from '../../../../common/helpers';
import Modal from '../../../../common/modal/components/modal';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../common/permissions-constants';
import { questionTypes } from '../../../../common/question-components/constants/question-constants';
import ReducerHelpers from '../../../../common/reducer-helpers';
import RenderIf from '../../../../common/render-if/components/render-if';
import Tab from '../../../../common/tabs/component/tab';
import Tabs from '../../../../common/tabs/component/tabs';
import { fileDetailsSections } from '../../../document-management/constants/constants';
import { setPermitsData } from '../../actions/action-creators';
import { fetchCommentUsersAndTeams } from '../../actions/inspection-actions';
import {
  addComponent,
  addPermitComment,
  answerOnQuestion,
  archivePermit,
  deleteComponent,
  deletePermit,
  deletePermitComment,
  deletePermitFile,
  fetchPermitComments,
  generateAndDownloadPermitPDF,
  getPermitDetails,
  getPermitStatusFlows,
  updatePermitGeometry,
} from '../../actions/permit-actions';
import { moduleSectionsFields } from '../../constants/constants';
import { setCameraPositionAndFocusOnFirstWorkArea } from '../../helpers/inspection-helper';
import '../../styles/permit-details.scss';
import CriticalEquipmentInfo from '../right-toolbar/common/critical-equipment-info';
import ModuleHeader from '../right-toolbar/common/module-header';
import { fields as componentConstants } from './constants/components-constants';
import { fields as isolationCertFields } from './constants/isolation-certificate-constants';
import { fields as keyboxFields } from './constants/keybox-constants';
import { downloadTypesForPermits, filterProps, permitFields, statuses, tabNames, fields as tableFields, toolbarItems } from './constants/permit-constants';
import PermitSections from './permit-sections';

// TODO: refactor to functional component
class PermitDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      activeToolbarItem: toolbarItems[tabNames.details].name,
      permitDetails: {},
      isDownloadInProgress: false,
      actionModalData: {
        isOpen: false,
      },
      commentsList: [],
      commentsLoading: false,
      addCommentLoading: false,
      commentUsersList: [],
      commentTeamsList: [],
      commentUsersAndTeamsLoading: false,
    };

    this.handleAnswerQuestionDebounce = debounce(this.handleAnswerQuestion, 600);
  }

  componentDidMount = () => {
    const { queryItem, getPermitDetails } = this.props;

    if (queryItem) {
      getPermitDetails(queryItem, (...args) => this.handleSuccessFetchPermitDetails(...args, true));
    }
  };

  componentDidUpdate = prevProps => {
    const { queryItem, getPermitDetails } = this.props;

    if (prevProps.queryItem !== queryItem && queryItem) {
      this.setState({
        isLoading: true,
        activeToolbarItem: toolbarItems[tabNames.details].name,
      });
      getPermitDetails(queryItem, (...args) => this.handleSuccessFetchPermitDetails(...args, true));
    }
  };

  handleSuccessFetchPermitDetails = (isLoading, permitDetails, shouldFocusCamera = false) => {
    const { viewer } = this.props;
    const state = {
      isLoading,
    };

    if (permitDetails) {
      state.permitDetails = permitDetails;
    }

    this.setState(state);
    if (shouldFocusCamera) {
      setCameraPositionAndFocusOnFirstWorkArea(permitDetails?.CameraPosition, permitDetails?.WorkAreas, viewer);
    }
  };

  handleAnswerQuestion = values => {
    const question = Object.assign({}, values.question);
    delete values.question;
    const { answerOnQuestion } = this.props;

    const onSuccess = () => {
      const { permitDetails } = this.state;
      const permit = Object.assign({}, permitDetails);

      const sectionIndex = findIndex(permit.Sections, { ID: question.SectionID });

      if (sectionIndex > -1) {
        const section = permit.Sections[sectionIndex];
        const questionIndex = findIndex(section.SectionQuestions, { ID: question.ID });
        if (questionIndex > -1) {
          const foundQuestion = section.SectionQuestions[questionIndex];
          const foundQuestionType = Object.values(questionTypes).find(qt => qt.valueId === foundQuestion.QuestionType);

          if (foundQuestionType.value !== 'MULTI_ANSWER') {
            /* Updating the value of the answer to the question. */
            if (!foundQuestion.Answers || foundQuestion?.Answers.length === 0) {
              /* in case the input has no answer value, add it */
              foundQuestion.Answers = [];
              foundQuestion.Answers.push({ [foundQuestionType.fieldName]: values[foundQuestionType.fieldName] });
            } else foundQuestion.Answers[0][foundQuestionType.fieldName] = values[foundQuestionType.fieldName];
            /* Replacing the question at the index of questionIndex with the foundQuestion. */
            section.SectionQuestions.splice(questionIndex, 1, foundQuestion);
          } else {
            section.SectionQuestions.splice(questionIndex, 1, question);
          }
          permit.Sections.splice(sectionIndex, 1, section);
          /* Setting the state of the permitDetails property to the permit object. */
          // comments out the state setting due to input fields re-setting the input value
          // this.setState({ permitDetails: permit });
        }
      }
    };

    answerOnQuestion(values, onSuccess);
  };

  handleAddIsolationCertificateSuccess = (permitId, certificate) => {
    const { permits, setPermits } = this.props;
    const { permitDetails } = this.state;
    const newPermitDetails = Object.assign({}, permitDetails);
    newPermitDetails[permitFields.isolationCertificates] = ReducerHelpers.addItemToIndex(newPermitDetails[permitFields.isolationCertificates], certificate, 0);

    // if added certificate contains Keybox, add it to permit details
    if (certificate[permitFields.keyboxId] !== 0) {
      const newKeybox = {
        [permitFields.id]: certificate[permitFields.keyboxId],
        [tableFields.name]: certificate[permitFields.keyboxName],
        [tableFields.keys]: null,
      };
      newPermitDetails[permitFields.keyboxes] = ReducerHelpers.addItemToIndex(newPermitDetails[permitFields.keyboxes], newKeybox, 0);
    }

    this.setState({ permitDetails: newPermitDetails });

    setPermits(
      false,
      ReducerHelpers.updateItemInListByProp(
        permits,
        {
          [tableFields.id]: permitId,
          [tableFields.isolationCertificates]: newPermitDetails[permitFields.isolationCertificates] || [],
          [tableFields.keyboxes]: (newPermitDetails[permitFields.keyboxes] || []).map(keybox => ({ ...keybox, [tableFields.keys]: null })),
        },
        tableFields.id
      )
    );
  };

  handleRemoveIsolationCertificateSuccess = (permitId, certificate) => {
    const { permits, setPermits } = this.props;
    const { permitDetails } = this.state;
    const newPermitDetails = Object.assign({}, permitDetails);
    newPermitDetails[permitFields.isolationCertificates] = ReducerHelpers.removeItemByProp(newPermitDetails[permitFields.isolationCertificates], certificate, isolationCertFields.id.name);

    // if removed certificate contains Keybox, remove it from permit details
    if (certificate[permitFields.keyboxId] !== 0) {
      const foundKeybox = newPermitDetails[permitFields.keyboxes].find(keybox => keybox[permitFields.id] === certificate[permitFields.keyboxId]);
      if (foundKeybox) {
        newPermitDetails[permitFields.keyboxes] = ReducerHelpers.removeItemByProp(newPermitDetails[permitFields.keyboxes], foundKeybox, keyboxFields.id.name);
      }
    }

    this.setState({ permitDetails: newPermitDetails });

    setPermits(
      false,
      ReducerHelpers.updateItemInListByProp(
        permits,
        {
          [tableFields.id]: permitId,
          [tableFields.isolationCertificates]: newPermitDetails[permitFields.isolationCertificates] || [],
          [tableFields.keyboxes]: (newPermitDetails[permitFields.keyboxes] || []).map(keybox => ({ ...keybox, [tableFields.keys]: null })),
        },
        tableFields.id
      )
    );
  };

  handleAddKeyboxSuccess = (permitId, keybox) => {
    const { permits, setPermits } = this.props;
    const { permitDetails } = this.state;

    const newPermitDetails = Object.assign({}, permitDetails);
    newPermitDetails[permitFields.keyboxes] = ReducerHelpers.addItemToIndex(newPermitDetails[permitFields.keyboxes], keybox, 0);

    this.setState({ permitDetails: newPermitDetails });

    setPermits(
      false,
      ReducerHelpers.updateItemInListByProp(
        permits,
        {
          [tableFields.id]: permitId,
          [tableFields.keyboxes]: (newPermitDetails[permitFields.keyboxes] || []).map(keybox => ({ ...keybox, [tableFields.keys]: null })),
        },
        tableFields.id
      )
    );
  };

  handleRemoveKeyboxSuccess = (permitId, keybox) => {
    const { permits, setPermits } = this.props;
    const { permitDetails } = this.state;

    const newPermitDetails = Object.assign({}, permitDetails);
    newPermitDetails[permitFields.keyboxes] = ReducerHelpers.removeItemByProp(newPermitDetails[permitFields.keyboxes], keybox, keyboxFields.id.name);

    this.setState({ permitDetails: newPermitDetails });

    setPermits(
      false,
      ReducerHelpers.updateItemInListByProp(
        permits,
        {
          [tableFields.id]: permitId,
          [tableFields.keyboxes]: (newPermitDetails[permitFields.keyboxes] || []).map(keybox => ({ ...keybox, [tableFields.keys]: null })),
        },
        tableFields.id
      )
    );
  };

  handleComponentSelected = (permitId, components) => {
    if (!components || !components.length) {
      return;
    }

    const { addComponent } = this.props;
    const component = components[0];
    const values = {
      [componentConstants.permit.name]: permitId,
      [componentConstants.component.name]: component[componentConstants.componentId.name],
    };

    addComponent(values, component => {
      const { permitDetails } = this.state;
      const newDetails = Object.assign({}, permitDetails);
      const componentsArray = Object.assign([], permitDetails[moduleSectionsFields.components]);
      componentsArray.push({
        ...components[0],
        ...component,
      });
      newDetails[moduleSectionsFields.components] = componentsArray;
      this.setState({ permitDetails: newDetails });
    });
  };

  handleDeleteComponent = (permitId, componentId) => {
    const { deleteComponent } = this.props;
    const values = {
      [componentConstants.permit.name]: permitId,
      [componentConstants.component.name]: componentId,
    };

    deleteComponent(values, () => {
      const { permitDetails } = this.state;
      const newDetails = Object.assign({}, permitDetails);
      remove(newDetails[moduleSectionsFields.components], { [componentConstants.componentId.name]: componentId });

      this.setState({ permitDetails: newDetails });
    });
  };

  handleCustomAction = (action, values, onSuccess) => {
    if (action && typeof action === 'function') {
      const { permitDetails } = this.state;

      action(values, Object.assign({}, permitDetails), (isSuccess, permitDetails) => {
        if (isSuccess && permitDetails) {
          this.setState({ permitDetails: permitDetails });
        }

        if (onSuccess) {
          onSuccess(isSuccess);
        }
      });
    }
  };

  handleChangeModule = module => {
    this.setState({ permitDetails: module });
  };

  handleDeletePermitFile = (permitId, fileId, callback) => {
    const { deletePermitFile } = this.props;

    deletePermitFile(permitId, fileId, () => {
      const { permitDetails } = this.state;
      const newDetails = Object.assign({}, permitDetails);

      remove(newDetails[moduleSectionsFields.files], { FileID: fileId });

      this.setState({ permitDetails: newDetails });
      if (callback) {
        callback();
      }
    });
  };

  handleMaximize = () => {};

  handleDownload = downloadType => {
    const { isDownloadInProgress } = this.state;

    if (isDownloadInProgress) {
      return;
    }

    this.setState({ isDownloadInProgress: true });
    const { queryItem, generateAndDownloadPDF } = this.props;
    generateAndDownloadPDF(queryItem, () => this.setState({ isDownloadInProgress: false }), downloadType === downloadTypesForPermits.additionalPageIncluded);
  };

  handleShare = () => {};

  toggleHeaderActionsModal = (values, isArchiveAction) => {
    if (!values) return;
    const { t } = this.context;
    const { permitDetails } = this.state;
    const { archivePermit, deletePermit, permits, filters, projectId } = this.props;
    const newFilters = Object.assign({}, filters);
    newFilters[filterProps.projectId] = projectId;
    newFilters[filterProps.lastSeen] = 0;

    const handleSuccessHeaderAction = (permit, isDeleteAction) => {
      this.setState({ permitDetails: permit, actionModalData: { isOpen: false } });

      if (isDeleteAction) {
        const { location } = this.props;
        const queryParams = { ...location.query };
        delete queryParams.selected_item;
        const params = [];

        for (let key in queryParams) {
          params.push({
            [key]: queryParams[key],
          });
        }

        Helpers.goTo(location.pathname, params);
      }
    };

    const getActionContent = () => {
      if (!isArchiveAction) {
        return 'PERMIT.DELETE_PERMIT_CONTENT';
      }

      return values[permitFields.archive] ? 'PERMIT.ARCHIVE_PERMIT_CONTENT' : 'PERMIT.UNARCHIVE_PERMIT_CONTENT';
    };

    const action = isArchiveAction
      ? () => archivePermit(values, Object.assign({}, permitDetails), Object.assign([], permits), newFilters, permit => handleSuccessHeaderAction(permit))
      : () => deletePermit(values, Object.assign([], permits), Object.assign({}, filters), (permit, isDeleteAction) => handleSuccessHeaderAction(permit, isDeleteAction));

    const actionModalData = {
      isOpen: true,
      content: t(getActionContent(), { name: permitDetails[tableFields.name] }),
      type: 'yes-no',
      customClassName: 'delete-confirm-modal',
      confirmAction: action,
      closeAction: () => this.setState({ actionModalData: { isOpen: false } }),
    };

    this.setState({ actionModalData });
  };

  fetchPermitCommentsHandler = () => {
    const { fetchPermitComments, queryItem } = this.props;

    const onSuccessFetch = newState => {
      this.setState({ commentsList: newState.commentsList, commentsLoading: newState.commentsLoading });
      if (newState.commentsList) {
        Helpers.scrollIntoView('comments-list-wrapper', `comment-${newState.commentsList.length - 1}`, 0);
      }
    };

    fetchPermitComments({ [commentFields.permitId]: queryItem }, onSuccessFetch);
  };

  addPermitCommentHandler = (commentValue, commentTags, resetCommentInput, scrollIntoView) => {
    const { addPermitComment, inspectionId, projectId } = this.props;
    const { permitDetails } = this.state;

    // TODO: replace with CommentSectionID once API tweaks the response for GET permitDetails
    const permitCommentSection = permitDetails.Sections.find(s => s[commentFields.name] === 'Comments');

    const commentParams = {
      InspectionID: inspectionId,
      ProjectID: projectId,
      [commentFields.id]: permitDetails[permitFields.id],
      [commentFields.tags]: commentTags,
      [commentFields.moduleSectionID]: permitCommentSection[permitFields.id],
      Comment: commentValue,
    };
    addPermitComment(
      commentParams,
      () => this.fetchPermitCommentsHandler(),
      loading => this.setState({ addCommentLoading: loading })
    );
    resetCommentInput();
    scrollIntoView();
  };

  deletePermitCommentHandler = comment => {
    const { deletePermitComment } = this.props;
    const commentParams = { [commentFields.moduleCommentID]: comment[commentFields.id], CommentID: null };

    deletePermitComment(
      commentParams,
      () => this.fetchPermitCommentsHandler(false),
      loading => this.setState({ addCommentLoading: loading })
    );
  };

  searchUserAndTeamsHandler = searchTerm => {
    const { fetchCommentUsersAndTeams } = this.props;
    fetchCommentUsersAndTeams(
      searchTerm,
      (usersList, teamsList) => this.setState({ commentUsersList: usersList, commentTeamsList: teamsList }),
      loading =>
        this.setState({
          commentUsersAndTeamsLoading: loading,
        })
    );
  };

  setPermitCameraPosition = newValue => {
    const { permitDetails } = this.state;
    const { queryItem, updateGeometry } = this.props;

    updateGeometry({ ID: queryItem, [permitFields.cameraPosition]: { coordinates: newValue } }, () =>
      this.setState({
        permitDetails: { ...permitDetails, [permitFields.cameraPosition]: { coordinates: newValue } },
      })
    );
  };

  render() {
    const {
      activeToolbarItem,
      permitDetails,
      isDownloadInProgress,
      actionModalData,
      isLoading,
      commentsList,
      commentsLoading,
      addCommentLoading,
      commentUsersList,
      commentTeamsList,
      commentUsersAndTeamsLoading,
    } = this.state;
    const { queryItem, projectId, inspectionId, getPermitStatusFlows, user, viewer } = this.props;

    const menuOptions = [
      {
        title: permitDetails[permitFields.archived] ? 'PERMIT.UNARCHIVE' : 'PERMIT.ARCHIVE',
        action: () => this.toggleHeaderActionsModal({ [permitFields.id]: queryItem, [permitFields.archive]: !permitDetails[permitFields.archived] }, true),
        access: {
          visibleFor: PERMISSIONS[PERMISSION_TYPES.permits].archive.name,
        },
        isDisabled:
          [statuses.authorised.value, statuses.issued.value, statuses.reissued.value, statuses.surrendered.value, statuses.rejected.value].indexOf(permitDetails[moduleSectionsFields.status]) > -1,
      },
      {
        title: 'PERMIT.DELETE_PERMIT',
        action: () => this.toggleHeaderActionsModal({ [permitFields.id]: queryItem }, false),
        access: {
          visibleFor: PERMISSIONS[PERMISSION_TYPES.permits].delete.name,
        },
        separator: true,
        isHighlighted: true,
        isDisabled:
          [statuses.approved.value, statuses.authorised.value, statuses.issued.value, statuses.reissued.value, statuses.surrendered.value].indexOf(permitDetails[moduleSectionsFields.status]) > -1,
      },
    ];

    const multipleDownloadOptions = [
      {
        title: 'PERMIT_DOWNLOAD_OPTION.FULL_PERMIT',
        action: downloadTypesForPermits.fullPermit,
      },
      {
        title: 'PERMIT_DOWNLOAD_OPTION.ADDITIONAL_PAGE',
        action: downloadTypesForPermits.additionalPageIncluded,
      },
    ];

    const relatedComponents = permitDetails[fileDetailsSections.components.valueKey];
    const containsCriticalEquipment = relatedComponents?.some(component => component.Critical);

    return (
      <div className={`permit-details ${isLoading ? 'loading' : ''}`}>
        <RenderIf if={isLoading}>
          <SimpleLoader isLoading={true} className="loader" />
        </RenderIf>
        <RenderIf if={!isLoading}>
          <Tabs
            defaultTabKey={activeToolbarItem}
            navigationClassName="tabs"
            tabsHeader={
              activeToolbarItem === tabNames.details ? (
                <ModuleHeader
                  id={queryItem}
                  handleMaximize={this.handleMaximize}
                  handleDownload={this.handleDownload}
                  handleShare={this.handleShare}
                  menuOptions={menuOptions}
                  isDownloadDisabled={isDownloadInProgress}
                  hideMaximize
                  hideShare
                  multipleDownload
                  multipleDownloadOptions={multipleDownloadOptions}
                />
              ) : null
            }
            onChange={tab => this.setState({ activeToolbarItem: tab })}
          >
            <Tab title={toolbarItems[tabNames.details].label} tabKey={tabNames.details}>
              <RenderIf if={containsCriticalEquipment}>
                <CriticalEquipmentInfo className="permit-details-critical-equipment" title={'CRITICAL_EQUIPMENT.TITLE'} paragraph={'CRITICAL_EQUIPMENT_PERMITS.PARAGRAPH'} />
              </RenderIf>
              <PermitSections
                queryItem={queryItem}
                projectId={projectId}
                permitDetails={permitDetails}
                inspectionId={parseInt(inspectionId)}
                handleChangeModule={this.handleChangeModule}
                handleAnswerQuestion={this.handleAnswerQuestionDebounce}
                handleAddIsolationCertificateSuccess={this.handleAddIsolationCertificateSuccess}
                handleRemoveIsolationCertificateSuccess={this.handleRemoveIsolationCertificateSuccess}
                handleAddKeyboxSuccess={this.handleAddKeyboxSuccess}
                handleRemoveKeyboxSuccess={this.handleRemoveKeyboxSuccess}
                handleComponentSelected={this.handleComponentSelected}
                handleDeleteComponent={this.handleDeleteComponent}
                handleCustomAction={this.handleCustomAction}
                handleDeletePermitFile={this.handleDeletePermitFile}
                handleSuccessFetchPermitDetails={this.handleSuccessFetchPermitDetails}
                isLoading={isLoading}
                setPermitCameraPosition={this.setPermitCameraPosition}
                viewer={viewer}
              />
            </Tab>
            <Tab title={toolbarItems[tabNames.comments].label} tabKey={tabNames.comments}>
              <CommentsTab
                commentsList={commentsList}
                commentsLoading={commentsLoading}
                addCommentLoading={addCommentLoading}
                fetchCommentsList={this.fetchPermitCommentsHandler}
                onAddCommentClick={this.addPermitCommentHandler}
                onDeleteCommentClick={this.deletePermitCommentHandler}
                fetchCommentUsersAndTeams={this.searchUserAndTeamsHandler}
                commentUsersList={commentUsersList}
                commentTeamsList={commentTeamsList}
                commentUsersAndTeamsLoading={commentUsersAndTeamsLoading}
                user={user}
                addCommentPermission={PERMISSIONS[PERMISSION_TYPES.permits].addComment.name}
              />
            </Tab>
            <Tab title={toolbarItems[tabNames.flow].label} tabKey={tabNames.flow}>
              <FlowStatusWrapper flowStatusModuleId={queryItem} parentProps={this.props} getFlowStatusData={getPermitStatusFlows} statuses={statuses} />
            </Tab>
          </Tabs>
        </RenderIf>
        <Modal {...actionModalData} />
      </div>
    );
  }
}

PermitDetails.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  permits: state.permitsReducer.permits,
  user: state.userReducer,
  filters: state.permitsReducer.filters,
  viewer: state.potreeReducer.viewerInstance,
});

const mapDispatchToProps = dispatch => ({
  getPermitDetails: (permitId, callback) => dispatch(getPermitDetails(permitId, callback)),
  getPermitStatusFlows: (permitId, callback) => dispatch(getPermitStatusFlows(permitId, callback)),
  generateAndDownloadPDF: (permitId, callback, additionalPageIncluded) => dispatch(generateAndDownloadPermitPDF(permitId, callback, additionalPageIncluded)),
  archivePermit: (data, permit, permits, filters, callback) => dispatch(archivePermit(data, permit, permits, filters, callback)),
  deletePermit: (data, permits, filters, callback) => dispatch(deletePermit(data, permits, filters, callback)),
  answerOnQuestion: (data, callback) => dispatch(answerOnQuestion(data, callback)),
  setPermits: (isLoading, permits) => dispatch(setPermitsData(isLoading, permits)),
  addComponent: (data, callback) => dispatch(addComponent(data, callback)),
  deleteComponent: (data, callback) => dispatch(deleteComponent(data, callback)),
  deletePermitFile: (permitId, fileId, section, callback) => dispatch(deletePermitFile(permitId, fileId, section, callback)),
  fetchPermitComments: (params, callback) => dispatch(fetchPermitComments(params, callback)),
  addPermitComment: (params, dataCallback, loadingCallback) => dispatch(addPermitComment(params, dataCallback, loadingCallback)),
  deletePermitComment: (params, dataCallback, loadingCallback) => dispatch(deletePermitComment(params, dataCallback, loadingCallback)),
  fetchCommentUsersAndTeams: (searchTerm, dataCallback, loadingCallback) => dispatch(fetchCommentUsersAndTeams(searchTerm, dataCallback, loadingCallback)),
  updateGeometry: (data, callback) => dispatch(updatePermitGeometry(data, callback)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PermitDetails));
