import routesConstants from './routes';
import { Api } from '../../core/api';
import { API_BASE_URL } from '../../common/constants';
import { generateQueryParams } from '../helpers/helper';

const getWorkflowImages = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.EXTERNAL_FILES_WORKFLOW}`, params);
};

const selectWorkflowImages = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.SELECT_WORKFLOW_IMAGES}`, params);
};

const generateComputerVision = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GENERATE_COMPUTER_VISON}${generateQueryParams(params)}`, params);
};
const generateComputerVisionEmptyResp = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GENERATE_COMPUTER_VISON_EMPTY_RESP}${generateQueryParams(params)}`, params);
};

export default {
  getWorkflowImages,
  selectWorkflowImages,
  generateComputerVision,
  generateComputerVisionEmptyResp,
};
