import actionTypes from '../constants/action-types';

const mfaState = {
  mfaTypes: null,
  mfaTypeData: {},
  mfaCodes: [],
};

export const mfaReducer = (state = mfaState, payload) => {
  switch (payload.type) {
    case actionTypes.HANDLE_MFA_TYPES:
      return { ...state, mfaTypes: payload.data };
    case actionTypes.HANDLE_MFA_TYPE_DATA:
      return { ...state, mfaTypeData: payload.data };
    case actionTypes.HANDLE_MFA_TYPE_CODES:
      return { ...state, mfaCodes: payload.data };
    default:
      return state;
  }
};
