import React from 'react';
import PropTypes from 'prop-types';
import { find, values, filter } from 'lodash';

import Icon from '../../../../../common/icon/components/icon';
import Helpers from '../../../../../common/helpers';
import { questionFields } from '../isolation-certificate-details/constants/change-status-constants';
import { questionTypes } from '../../../../../common/question-components/constants/question-constants';

const ChangeStatusQuestionsDisplay = ({ questions }, { t }) => {
  const types = React.useMemo(() => {
    return values(questionTypes);
  }, []);

  const getMultiAnswerValues = options => {
    const answers = filter(options, { [questionFields.isAnswer]: true });

    if (!answers || !answers.length) {
      return '-';
    }

    return answers.map((option, index) => (
      <p key={`question-option-display-${option[questionFields.id]}`} className="f-primary question-font-weight question-options-separator">{`${index + 1}. ${option[questionFields.name]}`}</p>
    ));
  };

  const getCheckboxValue = value => {
    if (value === null || value === undefined) {
      return '-';
    }

    return (
      <div className="checkbox-field-answer">
        <Icon name="checkmark-rounded" />
        <p className="f-primary question-font-weight">{t(value ? 'MODULES_SECTIONS.QUESTION_CHECKBOX_YES' : 'MODULES_SECTIONS.QUESTION_CHECKBOX_NO')}</p>
      </div>
    );
  };

  const getFieldValue = question => {
    const answers = question[questionFields.answers];
    let value = '-';

    const type = find(types, { valueId: question[questionFields.type] });

    if (!type) {
      return value;
    }

    const answer = answers && answers.length > 0 ? answers[0] : {};
    value = answer?.[type.fieldName];

    switch (question[questionFields.type]) {
      case questionTypes.DATE.valueId:
        return <p className="f-primary question-font-weight">{value ? Helpers.getDateFromUnixMs(value) : '-'}</p>;
      case questionTypes.CHECKBOX.valueId:
        return getCheckboxValue(value);
      case questionTypes.MULTI_ANSWER.valueId:
        return getMultiAnswerValues(question[questionFields.questionOptions]);
      case questionTypes.NO_ANSWER.valueId:
        return <p className="f-primary question-font-weight">{question[questionFields.name]}</p>;
      default:
        return <p className="f-primary question-font-weight">{value || '-'}</p>;
    }
  };

  return (
    <div className="change-status-questions-container">
      <h6 className="f-primary bold">{t('CHANGE_STATUS_SECTION.QUESTIONS')}</h6>
      {questions &&
        questions.length > 0 &&
        questions.map((question, index) => (
          <div className="change-status-question" key={`change-status-question-${question[questionFields.id]}`}>
            <p className="f-primary bold question-title-separator">{`${index + 1}. ${
              question[questionFields.type] === questionTypes.NO_ANSWER.valueId ? t('MODULES_SECTIONS.PROCEDURE_TITLE') : question[questionFields.name]
            }`}</p>
            <p className="f-secondary-dark bold question-description-separator">{t('CHANGE_STATUS_SECTION.DESCRIPTION')}</p>
            <p className="f-primary question-default-separator default-font-weight">{question[questionFields.description] || '-'}</p>
            <p className="f-secondary-dark bold question-description-separator">{t('CHANGE_STATUS_SECTION.ANSWER')}</p>
            {getFieldValue(question)}
          </div>
        ))}
    </div>
  );
};

ChangeStatusQuestionsDisplay.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ChangeStatusQuestionsDisplay;
