import actionTypes from '../actions/action-types';

const collapsableInfoState = {
  isDisplayed: false,
  isExpanded: false,
  title: '',
  customContent: null,
  customContentProps: null,
};

export const collapsableInfoReducer = (state = collapsableInfoState, payload) => {
  switch (payload.type) {
    case actionTypes.SET_COLLAPSIBLE_INFO:
      return payload.data;
    case actionTypes.SET_CI_CONTENT:
      return { ...state, customContent: payload.data };
    case actionTypes.SET_CI_CONTENT_PROPS:
      return { ...state, customContentProps: payload.data };
    case actionTypes.SET_CI_IS_DISPLAYED:
      return { ...state, isDisplayed: payload.data };
    case actionTypes.SET_CI_IS_EXPANDED:
      return { ...state, isExpanded: payload.data };
    case actionTypes.SET_CI_TITLE:
      return { ...state, title: payload.data };
    case actionTypes.RESET_CI:
      return collapsableInfoState;
    default:
      return state;
  }
};
