export default {
  GET_PERMITS: '/project/permit/list',
  CREATE_PERMIT: '/project/permit/create',
  GET_PERMIT_DETAILS: '/project/permit/details',
  UPDATE_PERMIT: '/project/permit/update',
  UPDATE_PERMIT_DATES: '/project/permit/update/dates',
  GET_PERMIT_ISOLATION_CERTIFICATES: '/project/permit/isolationcert/search',
  LINK_ISOLATION_CERTIFICATE: '/project/permit/isolationcert/add',
  REMOVE_ISOLATION_CERTIFICATE: '/project/permit/isolationcert/delete',
  GET_PERMIT_KEYBOXES: '/project/permit/keybox/search',
  LINK_KEYBOX: '/project/permit/keybox/add',
  REMOVE_KEYBOX: '/project/permit/keybox/delete',
  CHANGE_PERMIT_STATUS: '/project/permit/status',
  CHANGE_PERMIT_STATUS_TO_ISSUE: '/project/permit/status/issued/set',
  CHANGE_PERMIT_STATUS_TO_SURRENDER: '/project/permit/status/surrendered/set',
  CHANGE_PERMIT_STATUS_TO_REISSUE: '/project/permit/status/reissued/set',
  CHANGE_PERMIT_STATUS_TO_HANDBACK: '/project/permit/status/handback/set',
  GET_HANDBACK_STATUS_DATA: '/project/permit/status/handback/get',
  ARCHIVE_PERMIT: '/project/permit/archive',
  DELETE_PERMIT: '/project/permit/delete',
  GENERATE_PDF: '/project/permit/pdf',
  GET_STATUS_HISTORY: '/project/permit/status/history',
  ANSWER_ON_QUESTION: '/project/permit/answer/add',
  ADD_COMPONENT: '/project/permit/component/add',
  DELETE_COMPONENT: '/project/permit/component/delete',
  DELETE_PERMIT_FILE: '/project/permit/file/unlink',
  GET_PERMIT_CONTRACTORS_LIST: '/project/permit/contractors/list',
  GET_PERMIT_STATUS_FLOWS: '/project/permit/status/flow',
  FETCH_PERMIT_COMMENTS: '/project/permit/comment/list',
  ADD_PERMIT_COMMENT: '/project/permit/comment/add',
  DELETE_PERMIT_COMMENT: '/project/permit/comment/delete',
  UPDATE_PERMIT_GEOMETRY: '/project/permit/geometry/update',
};
