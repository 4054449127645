import React, { Component } from 'react';
import DropzoneLib from 'react-dropzone';
import PropTypes from 'prop-types';

import '../style/upload-dropzone.scss';

class Dropzone extends Component {
  render() {
    const { onDrop, multiple, disabled, label, className, labelWhite, labelWhiteRest, labelGreen, CustomImage, labelUnderImage, CustomComponent } = this.props;
    return (
      <div className={`dropzone-wrapper ${disabled ? 'disabled' : ''}`}>
        {label && <label className="label-before-image"> {label} </label>}
        <DropzoneLib onDrop={(file, id) => onDrop(file, id)} multiple={multiple} disabled={disabled}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            const { onClick, ...restProps } = getRootProps();

            return (
              <section className={`${className} ${isDragActive ? 'drag-active ' : ''}`}>
                <div
                  {...restProps}
                  className={`drop-field ${disabled ? 'disabled' : ''}`}
                  onClick={e => {
                    const event = Object.assign({}, e);
                    // e.stopPropagation();
                    if (onClick) onClick(event);
                  }}
                >
                  <input {...getInputProps()} />
                  {(labelWhite || labelGreen || labelWhiteRest) && (
                    <p className="f-secondary-dark p-above">
                      {labelWhite}
                      {labelGreen && <em>{labelGreen}</em>}
                      {labelWhiteRest}
                    </p>
                  )}
                  {CustomImage && <CustomImage />}
                  {labelUnderImage && <p className="f-secondary-dark p-below">{labelUnderImage}</p>}
                </div>
              </section>
            );
          }}
        </DropzoneLib>
        {CustomComponent && <CustomComponent />}
      </div>
    );
  }
}

Dropzone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  children: PropTypes.object,
  // depreceted format of supported MimeTypes introduced in v13.0.0 of react-dropzone
  // New format: {"image/*": [".jpeg", ".png"]}
  supportedMimeTypes: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string))]),
};
Dropzone.defaultProps = {
  supportedMimeTypes: '',
  className: 'dropzone',
};

export default Dropzone;
