import React from 'react';
import PropTypes from 'prop-types';

import { fields } from './constants/isolation-certificates-table';

import '../../styles/isolation-certificate-popover.scss';

const IsolationCertificatePopover = ({ certificateName, permits = [] }, { t }) => (
  <div className="isolation-certificate-popover">
    <p className="f-primary certificate-name">{certificateName}</p>
    <p className="f-secondary-dark permit-item">{t('ISOLATION_CERTIFICATES.LINKED_PERMITS')}</p>
    {permits && permits.length > 0 && permits.map(permit => <p className="f-primary permit-item">{permit[fields.name]}</p>)}
  </div>
);

IsolationCertificatePopover.contextTypes = {
  t: PropTypes.func.isRequired,
};

IsolationCertificatePopover.propTypes = {
  certificateName: PropTypes.string,
  permits: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number,
      Name: PropTypes.string,
      Status: PropTypes.string,
    })
  ),
};

export default IsolationCertificatePopover;
