import { debounce, remove } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import DoubleTable from '../../../../../common/double-table/components/double-table';
import Helpers from '../../../../../common/helpers';
import Modal from '../../../../../common/modal/components/modal';
import { setGenericNotification } from '../../../../../common/notification/actions/action-creators';
import PathViewer from '../../../../../common/path-viewer/components/path-viewer';
import { fetchComponentHierarchyLevels, fetchComponents, updateComponentHierarchyComponent } from '../../../../component-hierarchy/actions/component-hierarchy-levels-actions';
import { formatComponentCells, formatLevelCells } from '../../../../component-hierarchy/components/ch-modals/pick-level-modal/actions/actions';
import { SearchPropName, defaultComponentsFilters, defaultFilters, filterProps, modalFirstTableConfig, modalSecondTableConfig } from '../../../../component-hierarchy/constants/constants';
import ComponentHierarchyHelpers from '../../../../component-hierarchy/helpers/helpers';
import { clearUnsavedChangesDirty, setComponentFormState, setInspectionComponents } from '../../../actions/action-creators';
import { formConstants } from '../../../constants/component-constants';
import '../../../styles/change-hierarchy-modal.scss';
import ChangeHierarchyDetailsSection from './change-hierarchy-modal/change-hierarchy-details-section';

const ChangeHierarchyModal = (props, context) => {
  const {
    selectedComponent: { ID },
    selectedComponent,
    updateComponentHierarchyComponent,
    customCloseAction,
    changeField,
    setInspectionComponents,
    components,
    setGenericNotification,
    fetchComponentHierarchyLevels,
    fetchComponents,
    ProjectID,
    setComponentFormState,
    clearUnsavedChangesDirty,
    isDirty,
  } = props;
  const { t } = context;

  const [levelPath, setLevelPath] = useState([{ Code: t('COMPONENT_HIERARCHY.ALL_LEVELS'), Name: '' }]);
  const [componentLevels, setComponentLevels] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [filters, setFilters] = useState(defaultFilters);
  const [levelsSearchText, setLevelsSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [currentLevelID, setCurrentLevelID] = useState(null);
  const [componentsData, setComponentsData] = useState([]);
  const [componentsTotalItems, setComponentsTotalItems] = useState(0);
  const [isLoadingComponents, setIsLoadingComponents] = useState(false);
  const [componentsFilters, setComponentsFilters] = useState(defaultComponentsFilters);
  const [componentsSearchText, setComponentsSearchText] = useState('');
  const [confirmChangeHierarchyModal, setConfirmChangeHierarchyModal] = useState({ isOpen: false });

  const stateCallback = newState => {
    if (newState.componentLevels !== undefined) setComponentLevels(newState.componentLevels);
    if (newState.TotalItems !== undefined) setTotalItems(newState.TotalItems);
    if (newState.components !== undefined) setComponentsData(newState.components);
    if (newState.componentsTotalItems !== undefined) setComponentsTotalItems(newState.componentsTotalItems);
    if (newState.confirmChangeHierarchyModal !== undefined) setConfirmChangeHierarchyModal(newState.confirmChangeHierarchyModal);
    if (newState.currentLevelID !== undefined) setCurrentLevelID(newState.currentLevelID);
    if (newState.levelPath !== undefined) setLevelPath(newState.levelPath);
    if (newState.levelsSearchText !== undefined) setLevelsSearchText(newState.levelsSearchText);
    if (newState.isLoadingComponents !== undefined) setIsLoadingComponents(newState.isLoadingComponents);
    if (newState.isLoading !== undefined) setIsLoading(newState.isLoading);
    if (newState.filters !== undefined) setFilters(newState.filters);
    if (newState.componentsFilters !== undefined) setComponentsFilters(newState.componentsFilters);
  };

  const fetchLevels = useCallback(
    (filters, loadMore) => {
      const onSuccessFetch = newState => {
        stateCallback(newState);
        if (loadMore && newState.componentLevels) {
          Helpers.scrollIntoView('modal-level-table', `row-${filters[filterProps.lastSeen] - 1}`, -350);
        }
      };

      fetchComponentHierarchyLevels({ ProjectID, ...filters }, onSuccessFetch, loadMore, componentLevels);
    },
    [ProjectID, componentLevels, fetchComponentHierarchyLevels]
  );

  const fetchComponentsData = useCallback(
    (filters, loadMore) => {
      const onSuccessFetch = newState => {
        stateCallback(newState);
        if (loadMore && newState.components) {
          Helpers.scrollIntoView('components-table', `row-${filters[filterProps.lastSeen] - 1}`, -250);
        }
      };

      fetchComponents({ ProjectID, ...filters }, onSuccessFetch, loadMore, componentsData);
    },
    [ProjectID, componentsData, fetchComponents]
  );

  useEffect(() => {
    fetchLevels(filters, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRowClick = row => {
    if (!levelPath || !levelPath[0]) return;
    ComponentHierarchyHelpers.onRowLevelClick(row, levelPath, filters, defaultFilters, fetchLevels, stateCallback, null, SearchPropName.levels);
    ComponentHierarchyHelpers.onComponentLevelClick(row, componentsFilters, defaultComponentsFilters, fetchComponentsData, stateCallback, null, SearchPropName.components);
  };

  const handlePathBackClick = () => {
    ComponentHierarchyHelpers.onPathBackClick(levelPath, filters, defaultFilters, fetchLevels, stateCallback, null, SearchPropName.levels);
    ComponentHierarchyHelpers.onPathBackClickComponents(levelPath, componentsFilters, defaultComponentsFilters, fetchComponentsData, stateCallback, null, SearchPropName.components);
  };

  const handlePathClick = ID => {
    ComponentHierarchyHelpers.onPathClick(ID, levelPath, filters, defaultFilters, fetchLevels, stateCallback, null, SearchPropName.levels);
    ComponentHierarchyHelpers.onPathClickComponent(ID, componentsFilters, defaultComponentsFilters, fetchComponentsData, stateCallback, null, SearchPropName.components);
  };

  const handleSearchLevels = debounce(SearchText => {
    ComponentHierarchyHelpers.onSearch(SearchText, filters, defaultFilters, fetchLevels);
  }, 300);

  const handleSearchComponents = debounce(SearchText => {
    ComponentHierarchyHelpers.onSearch(SearchText, componentsFilters, defaultComponentsFilters, fetchComponentsData, { [filterProps.hierarchyID]: currentLevelID });
  }, 300);

  const handleInputLevelChange = e => {
    setLevelsSearchText(e.target.value);
    handleSearchLevels(e.target.value);
  };

  const handleInputComponentsChange = e => {
    setComponentsSearchText(e.target.value);
    handleSearchComponents(e.target.value);
  };

  const onLevelColumnSort = SortByColumn => {
    ComponentHierarchyHelpers.onColumnSort(SortByColumn, filters, fetchLevels);
  };

  const onComponentsColumnSort = SortByColumn => {
    ComponentHierarchyHelpers.onColumnSort(SortByColumn, componentsFilters, fetchComponentsData);
  };

  const updateComponent = (isUnassign = false) => {
    const HierarchyID = currentLevelID && !isUnassign ? currentLevelID : null;
    const onSuccess = () => {
      let newHierarchy = '';
      const newComponents = [...components];
      remove(newComponents, { ID });
      newHierarchy = isUnassign ? t('COMPONENT_HIERARCHY.UNASSIGNED') : Helpers.getShortenedPath(levelPath, formConstants.fields.code);
      if (isUnassign) closeConfirmChangeHierarchyModal();
      // TODO: move HierarchyID and Hierarchy to constants

      changeField('HierarchyID', HierarchyID);
      changeField('Hierarchy', newHierarchy);
      if (!isDirty) {
        // if there were no changes in the form before the hierarchy change, clear the unsaved changes listeners
        setComponentFormState({ hasUnsavedChanges: false });
        clearUnsavedChangesDirty();
      }
      setInspectionComponents(newComponents);
      setGenericNotification({
        isDisplayed: true,
        type: 'success',
        icon: 'checkmark-outline',
        text: t('COMPONENT_CHANGE_HIERARCHY.SUCCESS_NOTIFICATION'),
      });
      customCloseAction();
    };

    updateComponentHierarchyComponent({ HierarchyID, ComponentID: ID }, onSuccess);
  };

  const openConfirmChangeHierarchyModal = () => {
    setConfirmChangeHierarchyModal({
      isOpen: true,
      type: 'cancel-confirm',
      title: t('COMPONENT_CONFIRM_CHANGE_HIERARCHY_MODAL.TITLE'),
      customClassName: 'modal-medium',
      content: t('COMPONENT_CONFIRM_CHANGE_HIERARCHY_MODAL.CONFIRM', { Name: selectedComponent.Name }),
      closeAction: closeConfirmChangeHierarchyModal,
      confirmAction: () => updateComponent(true),
    });
  };

  const closeConfirmChangeHierarchyModal = () => {
    setConfirmChangeHierarchyModal({ isOpen: false });
  };

  const isNested = levelPath.length > 1;
  const isAddButtonDisabled = !isNested || selectedComponent.HierarchyID === currentLevelID;
  const isMarkAsUnassignedButtonDisabled = selectedComponent.HierarchyID === null;

  return (
    <div className="change-hierarchy-modal">
      <div className="change-hierarchy-modal__container">
        <div className="change-hierarchy-modal__container__left-side">
          <div className={`change-hierarchy-modal__container__left-side__path ${isNested ? 'show' : 'hide'}`}>
            <PathViewer levels={levelPath} onPathClick={handlePathClick} onBackClick={handlePathBackClick} />
          </div>
          <div className={`change-hierarchy-modal__container__left-side__table ${isNested ? 'path-opened' : ''}`}>
            <DoubleTable
              firstTableProps={{
                tableConfig: modalFirstTableConfig,
                handleFormatCells: formatLevelCells,
                data: componentLevels,
                sortDirection: filterProps.sortDirection,
                sortByColumn: filterProps.sortByColumn,
                filters,
                searchPlaceholder: t('ASSIGN_COMPONENTS.SEARCH_LEVEL'),
                noDataText: t('ASSIGN_COMPONENTS.LEVEL_EMPTY_STATE'),
                selectedItem: null,
                TotalItems: totalItems,
                hasNext: filters[filterProps.hasNext],
                onRowClick: handleRowClick,
                loadMoreOnClick: () => fetchLevels(filters, true),
                customTableClass: 'modal-level-table',
                isLoading,
                searchInputOnChange: handleInputLevelChange,
                searchInputValue: levelsSearchText,
                onSortClick: onLevelColumnSort,
                pointerDotID: selectedComponent.HierarchyID,
              }}
              secondTableProps={{
                tableConfig: modalSecondTableConfig,
                data: componentsData,
                handleFormatCells: formatComponentCells,
                sortDirection: filterProps.sortDirection,
                sortByColumn: filterProps.sortByColumn,
                filters: componentsFilters,
                searchPlaceholder: t('ASSIGN_COMPONENTS.SEARCH_COMPONENTS', { currentLevel: `${levelPath[levelPath.length - 1]?.Code} ${levelPath[levelPath.length - 1]?.Name}` }),
                noDataText: t('ASSIGN_COMPONENTS.COMPONENT_EMPTY_STATE'),
                selectedItem: null,
                searchInputValue: componentsSearchText,
                customTableClass: 'components-table',
                searchInputOnChange: handleInputComponentsChange,
                loadMoreOnClick: () => fetchComponentsData(componentsFilters, true),
                hasNext: componentsFilters[filterProps.hasNext],
                TotalItems: componentsTotalItems,
                isLoading: isLoadingComponents,
                onSortClick: onComponentsColumnSort,
                onRowClick: () => null,
              }}
            />
          </div>
        </div>
        <div className="change-hierarchy-modal__container__component-info">
          <ChangeHierarchyDetailsSection
            initialValues={selectedComponent}
            successButtonDisabled={isAddButtonDisabled}
            successButtonAction={updateComponent}
            dangerButtonAction={openConfirmChangeHierarchyModal}
            closeModal={customCloseAction}
            dangerButtonDisabled={isMarkAsUnassignedButtonDisabled}
          />
        </div>
      </div>
      <Modal {...confirmChangeHierarchyModal} />
    </div>
  );
};

ChangeHierarchyModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

ChangeHierarchyModal.propTypes = {
  ProjectID: PropTypes.number.isRequired,
  selectedComponent: PropTypes.object.isRequired,
  updateComponentHierarchyComponent: PropTypes.func.isRequired,
  customCloseAction: PropTypes.func.isRequired,
  changeField: PropTypes.func.isRequired,
  setInspectionComponents: PropTypes.func.isRequired,
  setGenericNotification: PropTypes.func.isRequired,
  fetchComponentHierarchyLevels: PropTypes.func.isRequired,
  fetchComponents: PropTypes.func.isRequired,
  components: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  ProjectID: state.inspectionReducer.projectID,
  components: state.inspectionReducer.components,
  isDirty: state.unsavedChangesReducer.isDirty,
});

const mapDispatchToProps = dispatch => ({
  fetchComponentHierarchyLevels: (data, callback, loadMore, componentLevels) => dispatch(fetchComponentHierarchyLevels(data, callback, loadMore, componentLevels)),
  fetchComponents: (data, callback, loadMore, components) => dispatch(fetchComponents(data, callback, loadMore, components)),
  updateComponentHierarchyComponent: (data, callback) => dispatch(updateComponentHierarchyComponent(data, callback)),
  setInspectionComponents: data => dispatch(setInspectionComponents(data)),
  setGenericNotification: data => dispatch(setGenericNotification(data)),
  setComponentFormState: state => dispatch(setComponentFormState(state)),
  clearUnsavedChangesDirty: () => dispatch(clearUnsavedChangesDirty()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeHierarchyModal);
