import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import { FORMS } from '../../../common/constants';

import { validate } from '../validators/reset-password-validators';
import resetPasswordConstatnts from '../constants/reset-password-constants';
import CustomInput from '../../../common/form/components/input';
import Button from '../../../common/form/components/button';
import '../styles/reset-password-form.scss';

class ResetPasswordForm extends Component {
  render() {
    const { t } = this.context;
    const { handleSubmit, invalid, anyTouched } = this.props;
    return (
      <form className="login-form" onSubmit={handleSubmit} noValidate>
        <Field
          id={resetPasswordConstatnts.fields.password}
          name={resetPasswordConstatnts.fields.password}
          label="RESET_PASSWORD.PASSWORD"
          labelClass="f-secondary-dark"
          component={CustomInput}
          type="password"
          placeholder="RESET_PASSWORD.PASSWORD"
        />
        <Field
          id={resetPasswordConstatnts.fields.confirmPassword}
          name={resetPasswordConstatnts.fields.confirmPassword}
          label={'RESET_PASSWORD.CONFIRM_PASSWORD'}
          labelClass="f-secondary-dark"
          component={CustomInput}
          type="password"
          placeholder="RESET_PASSWORD.CONFIRM_PASSWORD"
        />
        <Button disabled={invalid && anyTouched} text={t('CONFIRM')} />
      </form>
    );
  }
}

ResetPasswordForm.contextTypes = {
  t: PropTypes.func.isRequired,
};
ResetPasswordForm = reduxForm({
  form: FORMS.resetPassword,
  validate,
})(ResetPasswordForm);

export default ResetPasswordForm;
