import Icon from '../../../../../../../common/icon/components/icon';
import { isEmpty } from 'lodash';
import { tableFields, componentActions } from '../constants/constants';
import React from 'react';
import { colorPalette } from '../../../../../../../common/form/constants/constants';
import Helpers from '../../../../../../../common/helpers';

const getComponentNameCell = (item, value, searchTerm) => {
  return (
    <div className="assign-components__name">
      {item.Colour ? <div className="assign-components__name__status-icon" style={{ backgroundColor: colorPalette[item.Colour] }} /> : null}
      <div className="f-primary assign-components__name__text">{Helpers.getSearchTermText(value, searchTerm)}</div>
    </div>
  );
};

const getValueWithSearchTerm = (value, searchTerm) => {
  return isEmpty(value) ? '-' : searchTerm ? Helpers.getSearchTermText(value, searchTerm) : value;
};

export const formatLevelCells = (value, type, searchTerm) => {
  if (type === tableFields.icon) {
    return <Icon name="folder" size="sm" />;
  } else if (type === tableFields.code || type === tableFields.name) {
    return getValueWithSearchTerm(value, searchTerm);
  } else {
    return value ? value : '-';
  }
};
export const formatComponentCells = (value, type, searchTerm, row, onDropdownClick, t) => {
  if (type === tableFields.icon) {
    return <Icon name="components" active size="sm" />;
  } else if (type === tableFields.actionMenu) {
    return (
      <button className="action-menu-column__button" onClick={e => onDropdownClick(e, row, componentActions.unassign)}>
        <Icon name="plus-circle" rotation={45} danger handleHover={false} />
      </button>
    );
  } else if (type === tableFields.code || type === tableFields.type) {
    return getValueWithSearchTerm(value, searchTerm);
  } else if (type === tableFields.name) {
    return getComponentNameCell(row, value, searchTerm);
  } else if (type === tableFields.critical) {
    return value ? t('COMPONENT_DETAILS.CRITICAL_YES') : t('COMPONENT_DETAILS.CRITICAL_NO');
  } else {
    return value ? value : '-';
  }
};

export const formatUnassignedComponentCells = (value, type, searchTerm, row, onDropdownClick) => {
  if (type === tableFields.actionMenu) {
    return (
      <button className="action-menu-column__button" onClick={e => onDropdownClick(e, row, componentActions.assign)}>
        <Icon name="plus-circle" active handleHover={false} />
      </button>
    );
  } else if (type === tableFields.name) {
    return getComponentNameCell(row, value, searchTerm);
  } else {
    return getValueWithSearchTerm(value, searchTerm);
  }
};
