export const formConstants = {
  fields: {
    id: 'ID',
    signedAt: 'SignedAt',
    signedByUser: 'SignedByUser',
    signedSignature: 'SignedSignature',
    witnessedByUser: 'WhitnessedByUser',
    witnessedSignature: 'WhitnessedSignature',
  },
};

export const columnTypes = {
  expand: 'expand',
};

export const tableConfig = [
  {
    title: 'CHANGE_STATUS_HISTORY.TABLE.SIGNED_DATE_TIME',
    key: formConstants.fields.signedAt,
    type: 'date',
  },
  {
    title: 'CHANGE_STATUS_HISTORY.TABLE.ASSIGNEE',
    key: formConstants.fields.signedByUser,
  },
  {
    title: 'CHANGE_STATUS_HISTORY.TABLE.WITNESS',
    key: formConstants.fields.witnessedByUser,
  },
  //Commented out due to CLAR-2068, although in design this field should be in the table
  /*{
    key: 'actions',
    type: 'expand',
  },*/
];
