import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import BackButton from '../../../common/back-button/components/back-button';
import EmptyState from '../../../common/empty-state/components/empty-state';
import Helpers from '../../../common/helpers';
import HistoryItem from '../../../common/history-item/components/history-item';
import LoadMore from '../../../common/load-more/components/load-more';
import RenderIf from '../../../common/render-if/components/render-if';
import { routes } from '../../../common/routes-constants';
import ToolbarTitle from '../../../common/toolbar-title/components/toolbar-title';
import { componentModificationEvents, historyDefaultPagingObj, historyFilterParams, historyItems, sortingDirection } from '../../inspections/constants/component-constants';
import { modules } from '../../inspections/constants/constants';
import {
  historyDefaultPagingObj as defectHistoryDefaultPagingObj,
  historyFilterParams as defectHistoryFilterParams,
  historyItems as defectHistoryItems,
  defectModificationEvents,
  sortingDirection as defectSortingDirection,
} from '../../inspections/constants/defect-constants';
import {
  historyDefaultPagingObj as measurementHistoryDefaultPagingObj,
  historyFilterParams as measurementHistoryFilterParams,
  historyItems as measurementHistoryItems,
  measurementModificationEvents,
  sortingDirection as measurementSortingDirection,
} from '../../inspections/constants/measurement-constants';
import { params } from '../../profile/constants/profile-constants';

import {
  areaGroupModificationEvents,
  historyDefaultPagingObj as areaHistoryDefaultPagingObj,
  historyFilterParams as areaHistoryFilterParams,
  historyItems as areaHistoryItems,
  areaModificationEvents,
  sortingDirection as areaSortingDirection,
  eventTypePrefixes,
  groupHistoryItems,
} from '../../inspections/constants/areas-constants';

import {
  historyDefaultPagingObj as ndtHistoryDefaultPagingObj,
  historyFilterParams as ndtHistoryFilterParams,
  historyItems as ndtHistoryItems,
  ndtModificationEvents,
  sortingDirection as ndtSortingDirection,
} from '../../inspections/constants/ndt-constants';

import { generateQueryParams } from '../../../api/helpers/helper';
import { getInspectionDetails } from '../../inspections/actions/inspection-actions';
import {
  getAreaModuleHistory,
  getComponentModuleHistory,
  getDefectModuleHistory,
  getExplosiveZonesModuleHistory,
  getMeasurementModuleHistory,
  getNDTModuleHistory,
} from '../actions/module-history-actions';

import '../styles/modules-history.scss';

const moduleConstants = {
  [modules.components]: {
    title: 'COMPONENT_HISTORY.TITLE',
    sortingDirection: sortingDirection,
    historyDefaultPagingObj: historyDefaultPagingObj,
    historyFilterParams: historyFilterParams,
    historyTypes: [
      {
        moduleName: 'COMPONENT_HISTORY.MODULE_NAME',
        items: historyItems,
        events: componentModificationEvents,
        moduleCreateEvent: componentModificationEvents.component_created.event,
        moduleDeleteEvent: componentModificationEvents.component_deleted.event,
        moduleFileUnlinkEvent: componentModificationEvents.component_file_unlinked.event,
      },
    ],
  },
  [modules.defects]: {
    title: 'DEFECT_HISTORY.TITLE',
    sortingDirection: defectSortingDirection,
    historyDefaultPagingObj: defectHistoryDefaultPagingObj,
    historyFilterParams: defectHistoryFilterParams,
    historyTypes: [
      {
        moduleName: 'DEFECT_HISTORY.MODULE_NAME',
        items: defectHistoryItems,
        events: defectModificationEvents,
        moduleCreateEvent: defectModificationEvents.defect_created.event,
        moduleDeleteEvent: defectModificationEvents.defect_deleted.event,
        moduleFileUnlinkEvent: defectModificationEvents.defect_file_unlinked.event,
      },
    ],
  },
  [modules.measurements]: {
    title: 'MEASUREMENT_HISTORY.TITLE',
    sortingDirection: measurementSortingDirection,
    historyDefaultPagingObj: measurementHistoryDefaultPagingObj,
    historyFilterParams: measurementHistoryFilterParams,
    historyTypes: [
      {
        moduleName: 'MEASUREMENT_HISTORY.MODULE_NAME',
        items: measurementHistoryItems,
        events: measurementModificationEvents,
        moduleCreateEvent: measurementModificationEvents.measurement_created.event,
        moduleDeleteEvent: measurementModificationEvents.measurement_deleted.event,
        moduleFileUnlinkEvent: measurementModificationEvents.measurement_file_unlinked.event,
      },
    ],
  },
  [modules.areas]: {
    title: 'AREA_HISTORY.TITLE',
    sortingDirection: areaSortingDirection,
    historyDefaultPagingObj: areaHistoryDefaultPagingObj,
    historyFilterParams: areaHistoryFilterParams,
    historyTypes: [
      {
        moduleName: 'AREA_HISTORY.MODULE_NAME',
        items: areaHistoryItems,
        events: areaModificationEvents,
        moduleCreateEvent: areaModificationEvents.area_created.event,
        moduleDeleteEvent: areaModificationEvents.area_deleted.event,
      },
      {
        eventTypePrefix: eventTypePrefixes.areaGroup,
        moduleName: 'AREA_GROUP_HISTORY.MODULE_NAME',
        items: groupHistoryItems,
        events: areaGroupModificationEvents,
        moduleCreateEvent: areaGroupModificationEvents['area-group_created'].event,
        moduleDeleteEvent: areaGroupModificationEvents['area-group_deleted'].event,
      },
    ],
  },
  [modules.ndtData]: {
    title: 'NDT_MEASUREMENTS.TITLE',
    sortingDirection: ndtSortingDirection,
    historyDefaultPagingObj: ndtHistoryDefaultPagingObj,
    historyFilterParams: ndtHistoryFilterParams,
    historyTypes: [
      {
        moduleName: 'NDT_MEASUREMENTS.MODULE_NAME',
        items: ndtHistoryItems,
        events: ndtModificationEvents,
        moduleCreateEvent: ndtModificationEvents.ndt_measurement_location_created.event,
        moduleDeleteEvent: ndtModificationEvents.ndt_measurement_location_deleted.event,
        moduleFileUnlinkEvent: ndtModificationEvents.ndt_measurement_location_file_unlinked.event,
      },
    ],
  },
  // [modules.explosiveZones]: {
  //   title: 'ZONE_HISTORY.TITLE',
  //   sortingDirection: zoneSortingDirection,
  //   historyDefaultPagingObj: zoneHistoryDefaultPagingObj,
  //   historyFilterParams: zoneHistoryFilterParams,
  //   historyTypes: [
  //     {
  //       moduleName: 'ZONE_HISTORY.MODULE_NAME',
  //       items: zoneHistoryItems,
  //       events: zoneModificationEvents,
  //       moduleCreateEvent: zoneModificationEvents.zone_created.event,
  //       moduleDeleteEvent: zoneModificationEvents.zone_deleted.event,
  //     },
  //   ],
  // },
};

class ModulesHistory extends Component {
  constructor(props) {
    super(props);
    const type = get(props.location.query, 'type');

    this.state = {
      isLoading: false,
      history: [],
      paging: moduleConstants[type].historyDefaultPagingObj,
    };
  }

  getHistoryItemProps = item => {
    const { query } = this.props.location;
    const type = get(query, 'type');

    const moduleProps = moduleConstants[type];

    if (!moduleProps || isEmpty(moduleProps.historyTypes)) {
      return null;
    }

    // Get history item props for specific group of events by matching begining of eventType string
    // default is the first element
    const historyItemProps =
      moduleProps.historyTypes.find(el => {
        return el.eventTypePrefix && typeof item.eventType === 'string' && item.eventType.substring(0, el.eventTypePrefix.length) === el.eventTypePrefix;
      }) || moduleProps.historyTypes[0];

    switch (type) {
      case modules.defects:
      case modules.ndtData:
        return {
          ...moduleProps,
          ...historyItemProps,
          getRedirectPath: (selected_item, query, module) => {
            const {
              inspectionDetails: { DefaultComponent },
            } = this.props;

            //default component has no details
            if (selected_item === DefaultComponent && module === modules.components) {
              return null;
            }

            return this.getRedirectPath(selected_item, query, module);
          },
        };
      // case modules.explosiveZones:
      case modules.measurements:
      case modules.areas:
      case modules.components:
        return {
          ...moduleProps,
          ...historyItemProps,
          getRedirectPath: this.getRedirectPath,
        };
      default:
        return null;
    }
  };

  getFetchAction = () => {
    const { query } = this.props.location;
    const type = get(query, 'type');
    const { getComponentModuleHistory, getDefectModuleHistory, getMeasurementModuleHistory, getAreaModuleHistory, getNDTModuleHistory } = this.props;

    switch (type) {
      case modules.components:
        return getComponentModuleHistory;
      case modules.defects:
        return getDefectModuleHistory;
      case modules.measurements:
        return getMeasurementModuleHistory;
      case modules.areas:
        return getAreaModuleHistory;
      case modules.ndtData:
        return getNDTModuleHistory;
      // case modules.explosiveZones:
      //   return getExplosiveZonesModuleHistory;
      default:
        return null;
    }
  };

  fetchModuleHistory = () => {
    const { query } = this.props.location;
    const projectId = get(query, 'project_id');
    const inspectionId = get(query, 'inspection_id');
    const params = {
      LastSeen: this.state.paging[historyFilterParams.lastSeen],
      PerPage: this.state.paging[historyFilterParams.perPage],
      SortByColumn: historyFilterParams.sortByColumn,
      SortDirection: sortingDirection.desc,
      ProjectId: parseInt(projectId, 10),
      InspectionId: parseInt(inspectionId, 10),
    };

    const action = this.getFetchAction();

    if (action && typeof action === 'function') {
      action(params, this.state.history, this.state.paging, data => this.setState(data));
    }
  };

  getRedirectPath = (selected_item, { inspection_id, project_id, type }, module) => {
    const { user } = this.props;

    const basePath = user[params.fullScreenEnabled] ? routes.protectedRoutes.fullScreen.fullPath : routes.protectedRoutes.inspections.fullPath;
    return `${basePath}${generateQueryParams([{ project_id }, { inspection_id }, { type: module || type }, { selected_item }])}`;
  };

  componentDidMount = () => {
    const { query } = this.props.location;
    const { getInspectionDetails } = this.props;
    const type = get(query, 'type');
    const projectId = get(query, 'project_id');
    const inspectionId = get(query, 'inspection_id');

    if (!type || !projectId || !inspectionId) {
      Helpers.goTo(routes.protectedRoutes.project.fullPath);
      return;
    }

    this.fetchModuleHistory();
    //get inspection details as we need default component
    getInspectionDetails(inspectionId);
  };

  render() {
    const { query, search } = this.props.location;
    const backButtonPath = this.props.user[params.fullScreenEnabled] ? routes.protectedRoutes.fullScreen.fullPath : routes.protectedRoutes.inspections.fullPath;
    const type = get(query, 'type');

    const moduleProps = moduleConstants[type];

    if (!moduleProps || isEmpty(moduleProps.historyTypes)) {
      return null;
    }

    return (
      <div className="modules-history">
        <div className="modules-history__header">
          <BackButton path={`${backButtonPath}${search}`} />
          <ToolbarTitle variant="large">{moduleProps.title}</ToolbarTitle>
        </div>
        <RenderIf if={!this.state.isLoading && !this.state.history.length}>
          <EmptyState title="COMPONENT_HISTORY.EMPTY_STATE_TITLE" message="MODULES_HISTORY.EMPTY_STATE_MESSAGE" style={{ height: '80vh' }} />
        </RenderIf>
        <RenderIf if={!this.state.isLoading && this.state.history.length > 0}>
          <>
            <div className={`modules-history__items ${this.state.isLoading ? 'background-blurred' : ''}`}>
              {this.state.history.map(item => {
                const historyItemProps = this.getHistoryItemProps(item);
                return (
                  <HistoryItem
                    key={item.id}
                    items={historyItemProps.items}
                    events={historyItemProps.events}
                    item={item}
                    moduleName={historyItemProps.moduleName}
                    moduleCreateEvent={historyItemProps.moduleCreateEvent}
                    moduleDeleteEvent={historyItemProps.moduleDeleteEvent}
                    moduleFileUnlinkEvent={historyItemProps.moduleFileUnlinkEvent}
                    getRedirectPath={(selected_item, module) => historyItemProps.getRedirectPath(selected_item, query, module)}
                    redirectPath={this.getRedirectPath(item.itemID, query)}
                    isFullScreen
                  />
                );
              })}
            </div>
            <div className="modules-history__footer">
              <LoadMore
                disabled={!this.state.paging[historyFilterParams.hasNext] || this.state.isLoading}
                loaded={this.state.history.length}
                total={this.state.paging[historyFilterParams.totalItems]}
                totalPosition="center"
                isLoading={this.state.isLoading}
                label="COMPONENT_HISTORY.LOAD_MORE"
                isFixed
                showButton
                showTotalUp
                onClick={this.fetchModuleHistory}
              />
            </div>
          </>
        </RenderIf>
      </div>
    );
  }
}

ModulesHistory.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.userReducer,
  inspectionDetails: state.inspectionReducer.inspectionDetails,
});

const mapDispatchToProps = dispatch => ({
  getComponentModuleHistory: (params, history, paging, callback) => dispatch(getComponentModuleHistory(params, history, paging, callback)),
  getDefectModuleHistory: (params, history, paging, callback) => dispatch(getDefectModuleHistory(params, history, paging, callback)),
  getInspectionDetails: id => dispatch(getInspectionDetails(id)),
  getMeasurementModuleHistory: (params, history, paging, callback) => dispatch(getMeasurementModuleHistory(params, history, paging, callback)),
  getAreaModuleHistory: (params, history, paging, callback) => dispatch(getAreaModuleHistory(params, history, paging, callback)),
  getNDTModuleHistory: (params, history, paging, callback) => dispatch(getNDTModuleHistory(params, history, paging, callback)),
  getExplosiveZonesModuleHistory: (params, history, paging, callback) => dispatch(getExplosiveZonesModuleHistory(params, history, paging, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModulesHistory);
