import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';

import QuestionOptions from '../../../common/question-components/components/question-options';
import Button from '../../../common/form/components/button';

import { fields } from '../constants/constants';
import { FORMS } from '../../../common/constants';
import { validateSecondStep } from '../validatiors/create-keybox-validator';

const CreateKeyboxSecondStep = (
  { handleSubmit, invalid, anyTouched, isEdit, backAction, handleAddQuestionOption, handleUpdateQuestionOption, handleDeleteQuestionOption, disabled, options },
  { t }
) => {
  return (
    <form onSubmit={handleSubmit} noValidate>
      <QuestionOptions
        fieldsName={fields.keys}
        questionId={0}
        handleAddQuestionOption={handleAddQuestionOption}
        handleUpdateQuestionOption={handleUpdateQuestionOption}
        handleDeleteQuestionOption={handleDeleteQuestionOption}
        minFields={0}
        options={options}
        forceShowAddButton
        addButtonText="KEYBOX_FORM.NEW_KEY"
        optionLabel="KEYBOX_FORM.KEY_NAME"
        addButtonDisabled={invalid && anyTouched}
      />

      <div className="buttons">
        <Button variant="gray-outline" type="button" text={'BACK'} width="sm" height="md" onClick={backAction} disabled={disabled} />
        <Button type="submit" disabled={(invalid && anyTouched) || disabled} text={t(isEdit ? 'KEYBOX_FORM.SAVE_BTN' : 'KEYBOX_FORM.CREATE_BTN')} width="sm" height="md" />
      </div>
    </form>
  );
};

CreateKeyboxSecondStep.contextTypes = {
  t: PropTypes.func.isRequired,
};

CreateKeyboxSecondStep.propTypes = {
  isEdit: PropTypes.bool,
  closeAction: PropTypes.func.isRequired,
};

export default reduxForm({
  form: FORMS.addKeyboxKeys,
  enableReinitialize: true,
  destroyOnUnmount: true,
  touchOnChange: true,
  validate: validateSecondStep,
})(CreateKeyboxSecondStep);
