import actionTypes from '../constants/action-types';

const unsavedChangesState = {
  isDirty: false,
};

export const unsavedChangesReducer = (state = unsavedChangesState, payload) => {
  switch (payload.type) {
    case actionTypes.SET_UNSAVED_CHANGES_DIRTY:
      return { ...state, isDirty: payload.data };
    case actionTypes.CLEAR_UNSAVED_CHANGES_DIRTY:
      return { ...state, isDirty: false };
    default:
      return state;
  }
};

export default unsavedChangesReducer;
