import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';

import Helpers from '../../../../common/helpers';
import PropTypes from 'prop-types';
import AddQuickPointForm from './add-quick-point-form';

import { addMeasurementPoint } from '../../actions/ndt-actions';
import { measurementConstants, measurementPointConstants } from '../../constants/ndt-constants';

import '../../styles/add-quick-point-modal.scss';

class AddQuickPointModal extends Component {
  submitForm = values => {
    const { addMeasurementPoint, inspectionId, selectedMeasurement, chartFilters, closeModal, user } = this.props;
    const newMeasurementPoint = {
      ...values,
      [measurementPointConstants.fields.measuredByUser]: user.Name,
      [measurementPointConstants.fields.measuredValue]: Helpers.parseDecimal(values[measurementPointConstants.fields.measuredValue]),
      [measurementPointConstants.fields.measuredDate]: values[measurementPointConstants.fields.measuredDate]
        ? Helpers.getUnixDate(new Date(values[measurementPointConstants.fields.measuredDate]).getTime())
        : null,
    };

    addMeasurementPoint(
      inspectionId,
      selectedMeasurement[measurementConstants.fields.id],
      [],
      newMeasurementPoint,
      selectedMeasurement[measurementConstants.fields.unit] || ' - ',
      chartFilters,
      closeModal
    );
  };

  render() {
    const { submitForm } = this;
    const { changeField, selectedMeasurement, closeModal } = this.props;

    return (
      <div className="add-quick-point-modal-content">
        <AddQuickPointForm
          onSubmit={submitForm}
          onCancel={closeModal}
          changeField={changeField}
          unit={selectedMeasurement[measurementConstants.fields.unit] || ' - '}
          description={selectedMeasurement[measurementConstants.fields.description] || ''}
          threshold={selectedMeasurement[measurementConstants.fields.threshold] || ''}
          initialValues={{ [measurementPointConstants.fields.measuredDate]: new Date() }}
        />
      </div>
    );
  }
}
AddQuickPointModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    chartFilters: state.ndtReducer.ndtChartFilters,
    user: state.userReducer,
  };
};

const mapDispatchToProps = dispatch => ({
  addMeasurementPoint: (inspectionID, measurementId, currentMeasurements, newData, unit, chartFilters, callback) =>
    dispatch(addMeasurementPoint(inspectionID, measurementId, currentMeasurements, newData, unit, chartFilters, callback)),
  changeField: (formName, fieldName, value) => dispatch(change(formName, fieldName, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddQuickPointModal);
