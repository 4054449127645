import React, { Component } from 'react';
import PropTypes from 'prop-types';

import RenderIf from '../../render-if/components/render-if';
import { ReactComponent as BackSvg } from '../assets/back.svg';
import { ReactComponent as ExpandSvg } from '../assets/expand-icon.svg';
import { ReactComponent as MinimizeSvg } from '../assets/minimize-icon.svg';
import { ReactComponent as SplitHorizontal } from '../assets/divider-split-vertical.svg';
import { ReactComponent as SplitVertical } from '../assets/divider-split-horizontal.svg';

import Helpers from '../../helpers';
import { modalOpenedClass } from '../constants/modal-constants';

import '../styles/modal.scss';

class ModalMinimizable extends Component {
  constructor(props) {
    super(props);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      modalHash: null,
    };

    this.node = React.createRef();
  }

  escFunction = event => {
    const { isOpen, closeAction } = this.props;
    if (event.keyCode === 27) {
      //Do whatever when esc is pressed
      if (isOpen && closeAction) closeAction();
    }
  };

  componentDidUpdate(prevProps) {
    const { separatePage } = this.props;
    if (separatePage !== prevProps.separatePage) {
      if (separatePage) {
        const { modalHash } = this.state;
        document.removeEventListener('keydown', this.escFunction, false);
        document.body.classList.remove(modalHash);
      } else {
        document.addEventListener('keydown', this.escFunction, false);
        const parent = this.node && this.node.current && this.node.current.parentNode;

        const additionalClass = parent && parent.classList ? (parent.classList.value.indexOf('navigation') > -1 ? 'from-navi' : '') : '';

        const modalHash = `${modalOpenedClass}_${additionalClass}_${Helpers.uuid4()}`;

        this.setState({ modalHash });
        document.body.classList.add(modalHash);
      }
    }
  }

  componentDidMount() {
    const { separatePage } = this.props;
    if (separatePage) return;
    document.addEventListener('keydown', this.escFunction, false);
    const parent = this.node && this.node.current && this.node.current.parentNode;

    const additionalClass = parent && parent.classList ? (parent.classList.value.indexOf('navigation') > -1 ? 'from-navi' : '') : '';

    const modalHash = `${modalOpenedClass}_${additionalClass}_${Helpers.uuid4()}`;

    this.setState({ modalHash });
    document.body.classList.add(modalHash);
  }

  componentWillUnmount = () => {
    const { modalHash } = this.state;
    if (!modalHash) return;
    document.removeEventListener('keydown', this.escFunction, false);
    document.body.classList.remove(modalHash);
  };

  render() {
    const {
      title,
      content,
      isMinimized,
      CustomContent,
      closeAction,
      minimizeAction,
      maximizeAction,
      separatePageAction,
      modalDisabled,
      customClassName,
      backAction,
      fileName,
      FileIcon,
      setSplitScreenLayout,
      separatePage,
      splitScreenEnabled,
    } = this.props;

    return separatePage ? (
      <div className="separate-page">
        <div className="separate-page__header">
          <div className="left-content">
            {FileIcon && <FileIcon className="svg-white-g" />}
            <RenderIf if={title}>
              <p className="f-primary left-content__title">{title}</p>
            </RenderIf>
          </div>
          <div className="center-content">
            {fileName && (
              <p className="f-primary three-dot-text" title={fileName}>
                {fileName}
              </p>
            )}
          </div>
          <div className="right-content">
            {closeAction && (
              <>
                <ExpandSvg className="action-item svg-white-g" onClick={maximizeAction} />
                {setSplitScreenLayout && splitScreenEnabled && (
                  <>
                    <SplitHorizontal
                      width={15}
                      height={15}
                      className="action-item svg-white"
                      onClick={() => {
                        setSplitScreenLayout(0);
                      }}
                    />
                    <SplitVertical
                      className="action-item svg-white"
                      width={15}
                      height={15}
                      onClick={() => {
                        setSplitScreenLayout(1);
                      }}
                    />
                  </>
                )}
                <div className="action-item close-modal f-primary" onClick={modalDisabled ? null : closeAction}>
                  X
                </div>
              </>
            )}
          </div>
        </div>
        {content && <div className="modal__content">{content}</div>}
        {CustomContent && <CustomContent {...this.props} />}
      </div>
    ) : (
      <div className={`modal-container interactable-backdrop ${isMinimized ? 'minimized' : ''} ${customClassName || ''}`}>
        <div className="modal-wrapper modal-minimizable">
          {!isMinimized ? (
            <>
              {backAction && <BackSvg className="back svg-white" onClick={modalDisabled ? null : backAction} />}
              <div className="modal-minimizable__header">
                <div className="left-content">
                  {FileIcon && <FileIcon />}
                  <RenderIf if={title}>
                    <p className="left-content__title">{title}</p>
                  </RenderIf>
                </div>
                <div className="center-content">
                  {fileName && (
                    <p className="three-dot-text" title={fileName}>
                      {fileName}
                    </p>
                  )}
                </div>
                <div className="right-content">
                  {closeAction && (
                    <>
                      <div className="minimize-icon-wrapper" onClick={minimizeAction}>
                        <MinimizeSvg className="action-item minimize-icon svg-white" />
                      </div>
                      {splitScreenEnabled && <SplitHorizontal width={15} height={15} className="action-item minimize-icon svg-white" onClick={separatePageAction} />}
                      <div className="action-item close-modal" onClick={modalDisabled ? null : closeAction}>
                        X
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="modal">
                {content && <div className="modal__content">{content}</div>}
                {CustomContent && <CustomContent {...this.props} />}
              </div>
            </>
          ) : (
            <div className="modal-minimizable__header">
              <div className="left-content">{FileIcon && <FileIcon />}</div>
              <div className="center-content">
                {fileName && (
                  <p className="three-dot-text" title={fileName}>
                    {fileName}
                  </p>
                )}
              </div>
              <div className="right-content">
                {closeAction && (
                  <>
                    <ExpandSvg className="action-item svg-white" onClick={maximizeAction} />
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

ModalMinimizable.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeAction: PropTypes.func,
};
ModalMinimizable.defaultProps = {
  isOpen: false,
  isMinimized: false,
  splitScreenEnabled: false,
};

export default props => {
  return props.isOpen ? <ModalMinimizable {...props} /> : null;
};
