import { setRolesData, amendRolesData, handleRemoveRole, handleRoleLoading, handleAddRole, handleUpdateRole } from './action-creators';

import rolesApi from '../../../api/roles/actions';
import { roleFields } from '../constants/role-constants';

export const getRolesData = (params, callback, loadMore = false) => {
  return async dispatch => {
    try {
      dispatch(handleRoleLoading(true));
      const res = await rolesApi.getRoles(params);
      let { Data } = res.data;
      const data = Data.Items;
      if (loadMore) {
        dispatch(amendRolesData(data));
      } else {
        dispatch(setRolesData(data));
      }

      dispatch(handleRoleLoading(false));

      callback && callback(Data.HasNext);
    } catch (err) {
      dispatch(handleRoleLoading(false));
    }
  };
};

export const createRole = (params, callback) => {
  return async dispatch => {
    try {
      const res = await rolesApi.createRole(params);
      callback && callback();
      dispatch(handleAddRole(res.data.Data));
    } catch (err) {}
  };
};

export const updateRole = (params, callback) => {
  return async dispatch => {
    try {
      await rolesApi.updateRole(params);
      callback && callback();
      dispatch(handleUpdateRole(params));
    } catch (err) {
      callback && callback();
    }
  };
};

export const deleteRole = (params, callback) => {
  return async dispatch => {
    try {
      await rolesApi.deleteRole({ PolicyID: params[roleFields.id] });
      callback && callback();
      dispatch(handleRemoveRole(params));
    } catch (err) {
      callback && callback();
    }
  };
};

export const getRolePermissions = (role, callback) => {
  return async () => {
    try {
      const res = await rolesApi.getRolePermissions([{ policy_id: role[roleFields.id] }], { needLoader: true });
      callback && callback(res.data.Data);
    } catch (err) {
      callback && callback();
    }
  };
};

export const getRoleUsers = (roleId, callback) => {
  return async () => {
    try {
      const res = await rolesApi.getRoleUsers([{ policy_id: roleId }], { needLoader: true });
      const { Data } = res.data;
      callback && callback(Data);
    } catch (err) {
      callback && callback();
    }
  };
};
