import React, { Component } from 'react';

import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ReactComponent as CameraSvg } from '../../assets/camera-shutter.svg';
import { formConstants } from '../../constants/inspection-settings';

import AccessRenderer from '../../../../common/access-renderer/components/access-renderer';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../common/permissions-constants';
import { cameraChangedMessageDelay } from '../../constants/constants';

class SettingsPoints extends Component {
  state = {
    cameraChanged: false,
  };
  getCameraPosition = () => {
    const { viewer, inspectionDetails } = this.props;
    const cameraPosition = get(viewer, 'scene.cameraP.position');
    if (cameraPosition) {
      return { x: cameraPosition.x, y: cameraPosition.y, z: cameraPosition.z };
    } else if (inspectionDetails.CameraPosition && !isEmpty(inspectionDetails.CameraPosition.coordinates)) {
      return { x: inspectionDetails.CameraPosition.coordinates[0], y: inspectionDetails.CameraPosition.coordinates[1], z: inspectionDetails.CameraPosition.coordinates[2] };
    } else return { x: 0, y: 0, z: 0 };
  };

  setCameraPosition = () => {
    const { changeField, viewer, focusCamera } = this.props;

    const newCam = [this.getCameraPosition().x, this.getCameraPosition().y, this.getCameraPosition().z];
    changeField(formConstants.fields.cameraPosition, newCam);
    if (viewer && focusCamera) {
      viewer.zoomToPosition({ x: newCam[0], y: newCam[1], z: newCam[2] }, null, 500);
    }
    this.setState({ cameraChanged: true });
    setTimeout(() => {
      this.setState({ cameraChanged: false });
    }, cameraChangedMessageDelay);
  };

  render() {
    const { isHidden, disabled } = this.props;
    const { cameraChanged } = this.state;
    const { t } = this.context;

    return (
      <div className={`points-container${isHidden ? ' hidden-field' : ''} ${disabled ? 'disabled' : ''}`}>
        <div className="camera-container">
          {cameraChanged && <p className="f-secondary-green set-camera__text">{t('DEFECT_DETAILS.FORM_SET_CAMERA_POSITION_SET')}</p>}

          <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.projects].edit.name}>
            {({ hasAccess }) => {
              return hasAccess ? (
                <div className="set-camera" onClick={this.setCameraPosition}>
                  <CameraSvg className="set-camera__icon" />
                  <p className="f-secondary-green set-camera__text">{t('DEFECT_DETAILS.FORM_SET_CAMERA')}</p>
                </div>
              ) : null;
            }}
          </AccessRenderer>
        </div>
      </div>
    );
  }
}

SettingsPoints.contextTypes = {
  t: PropTypes.func.isRequired,
};

SettingsPoints.defaultProps = {
  focusCamera: true,
};

const mapStateToProps = state => {
  return {
    inspectionDetails: state.inspectionReducer.inspectionDetails,
  };
};

export default connect(mapStateToProps, null)(SettingsPoints);
