export const fields = {
  icon: 'icon',
  name: 'Name',
  status: 'Status',
  id: 'ID',
};

//TODO: Adjust it to support full screen in future
export const tableConfig = [
  // Commented out due to BE not supporting Status
  {
    title: '',
    key: fields.status,
    type: fields.icon,
    class: 'icon-column',
  },
  {
    title: 'ID',
    key: fields.id,
    type: fields.id,
    class: 'id-column',
    isSort: false,
  },
  {
    title: 'NAME',
    key: fields.name,
    type: fields.name,
    class: 'name-column',
    isSort: false,
  },
];
