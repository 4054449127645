import { statuses } from '../constants/constants';
import { fields } from '../constants/constants';
import Icon from '../../../../../common/icon/components/icon';
import Helpers from '../../../../../common/helpers';

// TODO: reuse as it repeats in /inspections/components/observations/actions/table-formatting.js
const statusesIconMap = {
  [statuses.draft.value]: {
    icon: statuses.draft.icon,
    props: statuses.draft.iconProps,
  },
  [statuses.raised.value]: {
    icon: statuses.raised.icon,
    props: statuses.raised.iconProps,
  },
  [statuses.approved.value]: {
    icon: statuses.approved.icon,
    props: statuses.approved.iconProps,
  },
  [statuses.rejected.value]: {
    icon: statuses.rejected.icon,
    props: statuses.rejected.iconProps,
  },
  [statuses.authorised.value]: {
    icon: statuses.authorised.icon,
    props: statuses.authorised.iconProps,
  },
  [statuses.inProgress.value]: {
    icon: statuses.inProgress.icon,
    props: statuses.inProgress.iconProps,
  },
  [statuses.completed.value]: {
    icon: statuses.completed.icon,
    props: statuses.completed.iconProps,
  },
  [statuses.closed.value]: {
    icon: statuses.closed.icon,
    props: statuses.closed.iconProps,
  },
  [statuses.startInNextWeek.value]: {
    icon: statuses.startInNextWeek.icon,
    props: statuses.startInNextWeek.iconProps,
  },
  [statuses.startInNextMonth.value]: {
    icon: statuses.startInNextMonth.icon,
    props: statuses.startInNextMonth.iconProps,
  },
  [statuses.onHold.value]: {
    icon: statuses.onHold.icon,
    props: statuses.onHold.iconProps,
  },
  [statuses.archived.value]: {
    icon: statuses.archived.icon,
    props: statuses.archived.iconProps,
  },
  [statuses.overdueFilter.value]: {
    icon: statuses.overdueFilter.icon,
    props: statuses.overdueFilter.iconProps,
  },
};

export const formatWorkOrdersTableCells = (
  value,
  type,
  searchTerm,
  _row,
  _onDropdownClick,
  _t,
  _pointerDotID,
  _user,
  _onToggleClick,
  _onDeleteRow,
  _statusData,
  _handleChangeStatus,
  _onLinkFileClick,
  _selectedItem
) => {
  if (type === fields.icon) {
    return <Icon name={statusesIconMap[value].icon} {...statusesIconMap[value].props} size="sm" />;
  } else if (type === fields.startDate) {
    return <p className="f-primary">{Helpers.getDateFromUnix(value, 'D MMM, YYYY')}</p>;
  } else if (type === fields.name) {
    return (
      <p className="f-primary" title={value}>
        {Helpers.getSearchTermText(Helpers.getShortenedText(value, 25), searchTerm)}
      </p>
    );
  } else {
    return value ? value : '-';
  }
};
