import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { get, find, debounce } from 'lodash';

import PermitTemplateFirstStep from './permit-template-first-step';
import PermitTemplateSecondStep from './permit-template-second-step';
import BackButton from '../../../../../common/back-button/components/back-button';
import Modal from '../../../../../common/modal/components/modal';
import RenderIf from '../../../../../common/render-if/components/render-if';
import Stepper from '../../../../../common/stepper/components/stepper';
import UploadAssetsModal from '../../../../project/components/upload-assets-modal';
import Helpers from '../../../../../common/helpers';
import { routes } from '../../../../../common/routes-constants';
import { stepperData, steps, fields, statuses, permitTemplateInitialValues, firstStepFields, defaultColor } from '../constants/constants';
import { openPermitTemplateForm } from '../../../helpers/templates-helpers';
import { uploadType } from '../../../../upload/constants/constants';
import { questionOptionsFields } from '../../../../../common/question-components/constants/question-constants';
import { fetchQuestionTypes } from '../../../../checklists-procedures-management/actions/checklists-procedures-management-actions';
import { setSingleUploadItem, setUploadItems, uploadAsset } from '../../../../upload/actions/upload-actions';
import { FORMS } from '../../../../../common/constants';
import {
  savePermitTemplate,
  getPermitTemplateDetails,
  getPermitTemplateSections,
  getPermitTemplateFiles,
  addPermitTemplateSection,
  updatePermitTemplateSection,
  updatePermitTemplateSectionQuestion,
  updatePermitTemplateSectionQuestionOption,
  deletePermitTemplateSection,
  sortPermitTemplateSections,
  addPermitTemplateSectionQuestion,
  deletePermitTemplateSectionQuestion,
  sortPermitTemplateSectionQuestions,
  addPermitTemplateSectionQuestionOption,
  deletePermitTemplateSectionQuestionOption,
  deletePermitTemplateFile,
} from '../../../actions/permit-actions';

import '../styles/permit-template-management.scss';

class PermitTemplateManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      permitTemplate: permitTemplateInitialValues,
      questionTypes: [],
      modalData: {
        isOpen: false,
      },
    };

    this.sectionFormChangeDebounce = debounce(this.submitSectionForm, 600);
    this.questionFormChangeDebounce = debounce(this.submitQuestionForm, 600);
    this.questionOptionChangeDebounce = debounce(this.submitQuestionOptionForm, 600);
  }

  componentDidMount = () => {
    const { params, location } = this.props;
    const step = params.step;
    const existingStep = find(stepperData, { stepValue: step });
    const { query } = location;
    const inspectionId = get(query, 'inspection_id');
    const projectId = get(query, 'project_id');

    // if in the URL is non existing step, or there are no "inspection_id" and "project_id" in
    // query params redirect user back to the C&P list
    if (!inspectionId || !projectId || !existingStep) {
      this.handleCancelButtonClick();
      return;
    }

    if (params?.id) {
      const id = parseInt(params.id, 10);
      const { getPermitTemplateDetails } = this.props;

      getPermitTemplateDetails(id, (isLoading, data) => this.setState({ isLoading, permitTemplate: data || permitTemplateInitialValues }));

      if (step === steps.secondStep) {
        this.fetchSecondStepData();
      }
    }
  };

  componentDidUpdate = prevProps => {
    const { params } = this.props;

    if (params?.step === steps.secondStep && prevProps.params?.step !== params?.step) {
      this.fetchSecondStepData();
    }

    if (params?.id && prevProps.params?.step !== params?.step) {
      const { getPermitTemplateDetails } = this.props;
      getPermitTemplateDetails(parseInt(params.id, 10), (isLoading, data) => this.setState({ isLoading, permitTemplate: data || permitTemplateInitialValues }));
    }
  };

  fetchSecondStepData = () => {
    const { t } = this.context;
    const { params, getPermitTemplateSections, fetchQuestionTypes } = this.props;
    const id = parseInt(params.id, 10);

    fetchQuestionTypes(t, (isLoading, questionTypes) => this.setState({ isLoading, questionTypes }));
    getPermitTemplateSections(id);
  };

  handleSuccessFirstStep = (isLoading, isSuccess, id) => {
    this.setState({ isLoading });

    if (isSuccess && id) {
      const { params } = this.props;
      const { location } = this.props;
      openPermitTemplateForm(params?.mode, steps.secondStep, id, location?.search);
    }
  };

  canEdit = () => {
    const { permitTemplate } = this.state;
    const { params } = this.props;
    return [statuses.live.value].indexOf(permitTemplate?.[fields.status] || '') === -1 && params?.mode === 'edit';
  };

  handleFirstStepFormSubmit = values => {
    const { savePermitTemplate, location, params } = this.props;
    const { query } = location;
    const projectId = get(query, 'project_id');
    values[fields.projectId] = parseInt(projectId, 10);
    const isEdit = params?.id ? true : false;
    const isDisabledSave = !this.canEdit();

    if (isDisabledSave) {
      this.handleSuccessFirstStep(false, true, values[fields.id]);
      return;
    }

    savePermitTemplate(values, isEdit, this.handleSuccessFirstStep);
  };

  handleCancelButtonClick = () => {
    const path = this.getBackButtonPath();
    Helpers.goTo(path);
  };

  getBackButtonPath = () => {
    const { location } = this.props;

    return `${routes.protectedRoutes.templates.fullPath}${location?.search ? location.search : ''}`;
  };

  submitSectionForm = values => {
    const { updatePermitTemplateSection, sections } = this.props;
    updatePermitTemplateSection(values, Object.assign([], sections));
  };

  submitQuestionForm = (values, field, skipReinitialize) => {
    const { updatePermitTemplateSectionQuestion, sections } = this.props;

    updatePermitTemplateSectionQuestion(values, Object.assign([], sections), field, skipReinitialize);
  };

  submitQuestionOptionForm = values => {
    const { updatePermitTemplateSectionQuestionOption, sections } = this.props;

    updatePermitTemplateSectionQuestionOption(values, Object.assign([], sections));
  };

  handleUpdateSection = values => {
    this.sectionFormChangeDebounce(values, true);
  };

  handleAddQuestion = sectionId => {
    const { addPermitTemplateSectionQuestion, sections } = this.props;

    addPermitTemplateSectionQuestion(sectionId, Object.assign([], sections));
  };

  handleFieldDelete = (sectionQuestionId, index, question) => {
    const { t } = this.context;
    const { deletePermitTemplateSectionQuestion, sections } = this.props;

    const modalData = {
      isOpen: true,
      content: t('SECTIONS.DELETE_QUESTION_CONTENT', { question }),
      customClassName: 'delete-confirm-modal',
      type: 'yes-no',
      confirmAction: () => deletePermitTemplateSectionQuestion(sectionQuestionId, Object.assign([], sections), index, this.handleCloseModal),
      closeAction: this.handleCloseModal,
    };

    this.setState({ modalData });
  };

  handleFieldSort = (currentIndex, newIndex, question) => {
    const { sections, sortPermitTemplateSectionQuestions } = this.props;

    sortPermitTemplateSectionQuestions(question.SectionID, currentIndex, newIndex, Object.assign([], sections));
  };

  handleDeleteSection = (sectionId, section) => {
    const { t } = this.context;
    const { sections, deletePermitTemplateSection } = this.props;

    const modalData = {
      isOpen: true,
      content: t('SECTIONS.DELETE_SECTION_CONTENT', { section }),
      type: 'yes-no',
      customClassName: 'delete-confirm-modal',
      confirmAction: () => deletePermitTemplateSection(sectionId, Object.assign([], sections), this.handleCloseModal),
      closeAction: this.handleCloseModal,
    };

    this.setState({ modalData });
  };

  handleFieldChange = (values, _questions, _index, field, skipReinitialize) => {
    this.questionFormChangeDebounce(values, field, skipReinitialize);
  };

  handleAddQuestionOption = questionId => {
    const { sections, addPermitTemplateSectionQuestionOption } = this.props;

    addPermitTemplateSectionQuestionOption(questionId, Object.assign([], sections));
  };

  handleUpdateQuestionOption = values => {
    this.questionOptionChangeDebounce(values);
  };

  handleDeleteQuestionOption = (index, option) => {
    const { t } = this.context;
    const { sections, deletePermitTemplateSectionQuestionOption } = this.props;

    const modalData = {
      isOpen: true,
      content: t('SECTIONS.DELETE_QUESTION_OPTION_CONTENT', { name: option ? option[questionOptionsFields.name.name] : '' }),
      customClassName: 'delete-confirm-modal',
      type: 'yes-no',
      confirmAction: () => deletePermitTemplateSectionQuestionOption(index, option, Object.assign([], sections), this.handleCloseModal),
      closeAction: this.handleCloseModal,
    };

    this.setState({ modalData });
  };

  handleSectionSort = (currentIndex, newIndex) => {
    const { params, sections, sortPermitTemplateSections } = this.props;

    sortPermitTemplateSections(parseInt(params.id, 10), currentIndex, newIndex, Object.assign([], sections));
  };

  handleAddSection = () => {
    const { params, sections, addPermitTemplateSection } = this.props;
    addPermitTemplateSection(parseInt(params.id, 10), Object.assign([], sections));
  };

  handleBackButtonClick = () => {
    const { location, params } = this.props;
    openPermitTemplateForm(params?.mode, steps.firstStep, params?.id, location?.search);
  };

  handleFinishButtonClick = () => {
    Helpers.goTo(this.getBackButtonPath());
  };

  onDropAsset = files => {
    const { uploadAsset, setUploadItems, setSingleUploadItem, location, params } = this.props;
    const { query } = location;
    const projectId = get(query, 'project_id');

    const tmp_files = files.map((file, index) => {
      const tmp_file = {
        type: Helpers.getFileType(file.type),
        mimeType: file.type,
        title: '',
        description: '',
        real_name: Helpers.formatFileName(file.name),
      };
      file.timeStamp = Date.now();
      file.real_name = tmp_file.real_name;
      file.uploadType = uploadType.permitTemplateFiles;

      uploadAsset(file, { PermitTemplateID: parseInt(params.id, 10), ProjectID: parseInt(projectId, 10) }, setSingleUploadItem, index);
      return { ...tmp_file, ...file };
    });

    setUploadItems(tmp_files);
  };

  toggleUploadModal = sectionId => {
    const { t } = this.context;
    const confirmAction = () => {
      const { params, sections, getPermitTemplateFiles, setUploadItems } = this.props;
      getPermitTemplateFiles(parseInt(params.id, 10), Object.assign([], sections));
      setUploadItems([]);

      this.setState({
        modalData: {
          isOpen: false,
        },
      });
    };

    this.setState(prevState => ({
      modalData: {
        ...prevState.uploadModalData,
        title: t('CONTRACTOR_MODAL.FILES_UPLOAD.TITLE'),
        customClassName: 'project-assets-modal modal-small',
        CustomContent: () => (
          <UploadAssetsModal
            onDrop={files => this.onDropAsset(files, sectionId)}
            className="project-dropzone"
            labelWhite={t('CONTRACTOR_MODAL.FILES_UPLOAD.TITLE')}
            labelGreen={t('CONTRACTOR_MODAL.FILES_UPLOAD.BROWSE')}
          />
        ),
        isOpen: true,
        type: 'ok',
        confirmAction: confirmAction,
        closeAction: confirmAction,
      },
    }));
  };

  handleCloseModal = () => {
    this.setState({ modalData: { isOpen: false } });
  };

  handleDeleteSectionFile = (file, sectionId) => {
    const { t } = this.context;
    const { params, sections, deletePermitTemplateFile } = this.props;

    const modalData = {
      isOpen: true,
      content: t('SECTIONS.DELETE_FILE_CONTENT', { name: file.FileName || '' }),
      customClassName: 'delete-confirm-modal',
      type: 'yes-no',
      confirmAction: () => deletePermitTemplateFile(parseInt(params.id, 10), file.FileID, sectionId, Object.assign([], sections), this.handleCloseModal),
      closeAction: this.handleCloseModal,
    };

    this.setState({ modalData });
  };

  render() {
    const { t } = this.context;
    const { isLoading, permitTemplate, questionTypes, modalData } = this.state;
    const { params, sections, formValues } = this.props;
    const step = params?.step;
    const id = params?.id;

    return (
      <div className="permit-template-management">
        <div className="permit-template-management__container">
          <BackButton path={this.getBackButtonPath()} textClass="f-secondary-dark" />
          <h3 className="f-primary permit-template-management__container__title">{t(id ? 'PERMIT_TEMPLATE.EDIT_TITLE' : 'PERMIT_TEMPLATE.CREATE_TITLE')}</h3>
          <div className="permit-template-management__container__stepper-wrapper">
            <Stepper stepperData={stepperData} activeStep={step} />
          </div>
          <RenderIf if={step === steps.firstStep}>
            <PermitTemplateFirstStep
              initialValues={Object.assign({ [firstStepFields.color.name]: defaultColor }, permitTemplate)}
              handleFormSubmit={this.handleFirstStepFormSubmit}
              isEdit={id ? true : false}
              handleCancelButtonClick={this.handleCancelButtonClick}
              isLoading={isLoading}
              isDisabled={!this.canEdit()}
              formValues={formValues}
            />
          </RenderIf>
          <RenderIf if={step === steps.secondStep}>
            <PermitTemplateSecondStep
              sections={sections}
              questionTypes={questionTypes}
              handleUpdateSection={this.handleUpdateSection}
              handleAddQuestion={this.handleAddQuestion}
              handleFieldDelete={this.handleFieldDelete}
              handleFieldSort={this.handleFieldSort}
              handleDeleteSection={this.handleDeleteSection}
              handleFieldChange={this.handleFieldChange}
              handleAddQuestionOption={this.handleAddQuestionOption}
              handleUpdateQuestionOption={this.handleUpdateQuestionOption}
              handleDeleteQuestionOption={this.handleDeleteQuestionOption}
              handleSectionSort={this.handleSectionSort}
              isLoading={isLoading}
              handleAddSection={this.handleAddSection}
              handleBackButtonClick={this.handleBackButtonClick}
              handleFinishButtonClick={this.handleFinishButtonClick}
              toggleUploadModal={this.toggleUploadModal}
              handleDeleteSectionFile={this.handleDeleteSectionFile}
              isDisabled={!this.canEdit()}
              sectionUpdateDebounce={this.sectionFormChangeDebounce}
              questionUpdateDebounce={this.questionFormChangeDebounce}
              questionOptionUpdateDebounce={this.questionOptionChangeDebounce}
            />
          </RenderIf>
        </div>
        <Modal {...modalData} />
      </div>
    );
  }
}

PermitTemplateManagement.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    sections: state.templateReducer.permitSections,
    formValues: getFormValues(FORMS.permitTemplateForm)(state),
  };
};

const mapDispatchToProps = dispatch => ({
  savePermitTemplate: (data, isEdit, callback) => dispatch(savePermitTemplate(data, isEdit, callback)),
  getPermitTemplateDetails: (templateId, callback) => dispatch(getPermitTemplateDetails(templateId, callback)),
  getPermitTemplateSections: templateId => dispatch(getPermitTemplateSections(templateId)),
  fetchQuestionTypes: (t, successCallback) => dispatch(fetchQuestionTypes(t, successCallback)),
  uploadAsset: (file, IDs, callbackFunction, index, customDoneFunc) => dispatch(uploadAsset(file, IDs, callbackFunction, index, customDoneFunc)),
  setSingleUploadItem: (progress, index) => dispatch(setSingleUploadItem(progress, index)),
  setUploadItems: assets => dispatch(setUploadItems(assets)),
  getPermitTemplateFiles: (templateId, sections) => dispatch(getPermitTemplateFiles(templateId, sections)),
  addPermitTemplateSection: (templateId, sections) => dispatch(addPermitTemplateSection(templateId, sections)),
  updatePermitTemplateSection: (data, sections) => dispatch(updatePermitTemplateSection(data, sections)),
  updatePermitTemplateSectionQuestion: (question, sections, field, skipReinitialize) => dispatch(updatePermitTemplateSectionQuestion(question, sections, field, skipReinitialize)),
  updatePermitTemplateSectionQuestionOption: (option, sections) => dispatch(updatePermitTemplateSectionQuestionOption(option, sections)),
  deletePermitTemplateSection: (sectionId, sections, callback) => dispatch(deletePermitTemplateSection(sectionId, sections, callback)),
  sortPermitTemplateSections: (templateId, currentIndex, newIndex, sections) => dispatch(sortPermitTemplateSections(templateId, currentIndex, newIndex, sections)),
  addPermitTemplateSectionQuestion: (sectionId, sections) => dispatch(addPermitTemplateSectionQuestion(sectionId, sections)),
  deletePermitTemplateSectionQuestion: (sectionQuestionId, sections, index, callback) => dispatch(deletePermitTemplateSectionQuestion(sectionQuestionId, sections, index, callback)),
  sortPermitTemplateSectionQuestions: (sectionId, currentIndex, newIndex, sections) => dispatch(sortPermitTemplateSectionQuestions(sectionId, currentIndex, newIndex, sections)),
  addPermitTemplateSectionQuestionOption: (data, sections) => dispatch(addPermitTemplateSectionQuestionOption(data, sections)),
  deletePermitTemplateSectionQuestionOption: (index, option, sections, callback) => dispatch(deletePermitTemplateSectionQuestionOption(index, option, sections, callback)),
  deletePermitTemplateFile: (templateId, fileId, sectionId, sections, callback) => dispatch(deletePermitTemplateFile(templateId, fileId, sectionId, sections, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PermitTemplateManagement);
