import TableTemplate from './table-template';
import '../styles/double-table.scss';

const DoubleTable = ({ firstTableProps, secondTableProps }) => {
  return (
    <div className="double-table">
      <div className="double-table__table">
        <TableTemplate {...firstTableProps} />
      </div>
      <div className="double-table__table">
        <TableTemplate {...secondTableProps} />
      </div>
    </div>
  );
};

export default DoubleTable;
