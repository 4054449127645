import { findIndex, uniqBy } from 'lodash';
import componentHierarchyAPI from '../../../api/component-hierarchy-api/actions';
import Helpers from '../../../common/helpers';
import { ActionTypesToolbar, fields } from '../constants/constants';

export const fetchComponentHierarchyLevels = (filters, callback, loadMore, componentLevels) => {
  return async () => {
    try {
      callback({ isLoading: true });
      const res = await componentHierarchyAPI.fetchComponentHierarchyLevels(filters);
      const data = res?.data?.Data;
      callback({
        componentLevels: loadMore ? [...componentLevels, ...data.Items] : data.Items,
        TotalItems: data.TotalItems,
        isLoading: false,
        filters: { ...filters, HasNext: data.HasNext || false, LastSeen: data.LastSeen },
      });
    } catch (e) {
      callback({ isLoading: false });
      console.error(e);
    }
  };
};

export const createComponentHierarchyLevel = (data, levels, TotalItems, callback) => {
  return async () => {
    try {
      callback({ isRightSideLoading: true, toolbarCollapsed: false });
      const res = await componentHierarchyAPI.createComponentHierarchyLevels(data);
      const level = res?.data?.Data;
      level[fields.createdAt] = Helpers.getDateFromUnix(level[fields.createdAt]);
      callback &&
        callback({
          isRightSideLoading: false,
          componentLevels: [...(levels || []), level],
          selectedItem: level?.ID,
          selectedItemData: level,
          TotalItems: TotalItems + 1,
          persistedSelectedItem: level?.ID,
          isRightSideDisabled: false,
        });
    } catch (e) {
      callback({ isRightSideLoading: false, toolbarCollapsed: true });
      console.error(e);
    }
  };
};

export const fetchComponentHierarchyLevelDetails = (hierarchy_id, callback, actionType, isPropertyAdd = false) => {
  return async () => {
    try {
      callback({ isRightSideLoading: !isPropertyAdd, toolbarCollapsed: false });
      const res = await componentHierarchyAPI.fetchComponentHierarchyLevelDetails([{ hierarchy_id }]);
      const level = res?.data?.Data;
      level[fields.createdAt] = Helpers.getDateFromUnix(level[fields.createdAt]);
      const callbackObj = isPropertyAdd
        ? { selectedItemData: level, selectedItemDataProperties: level?.Properties, selectedItem: hierarchy_id, persistedSelectedItem: hierarchy_id }
        : {
            isRightSideLoading: false,
            selectedItemData: level,
            selectedItemDataProperties: level?.Properties,
            selectedItem: hierarchy_id,
            persistedSelectedItem: hierarchy_id,
            isRightSideDisabled: actionType === ActionTypesToolbar.view,
          };
      callback && callback(callbackObj);
    } catch (e) {
      callback({ isRightSideLoading: false, toolbarCollapsed: true });
      console.error(e);
    }
  };
};

export const updateComponentHierarchyLevel = (data, componentLevels, callback) => {
  return async () => {
    try {
      await componentHierarchyAPI.updateComponentHierarchyLevel(data);
      callback &&
        callback({
          componentLevels: Object.assign([], componentLevels, { [findIndex(componentLevels, { ID: data.ID })]: data }),
        });
    } catch (e) {
      console.error(e);
    }
  };
};

export const fetchComponentHierarchyLevelPropertiesSuggestions = (SearchText, callback, ProjectID) => {
  return async () => {
    try {
      const res = await componentHierarchyAPI.fetchComponentHierarchyLevelPropertiesSuggestions({ ProjectID, SearchText });
      const { Data } = res.data;
      if (Data && Data.PropertyNames) {
        callback && callback(Data.PropertyNames);
      }
    } catch (e) {
      console.error(e);
    }
  };
};

export const addComponentHierarchyLevelProperty = (property, callback, ProjectID, HierarchyID) => {
  return async () => {
    try {
      const res = await componentHierarchyAPI.addComponentHierarchyLevelProperty({ Property: { ProjectID, HierarchyID, Name: property?.Name, Value: property?.Value } });
      const { Property } = res?.data?.Data;
      callback && callback(Property);
    } catch (e) {
      console.error(e);
    }
  };
};

export const updateComponentHierarchyLevelProperty = (property, ProjectID, HierarchyID) => {
  return async () => {
    try {
      await componentHierarchyAPI.updateComponentHierarchyLevelProperty({ Property: { ProjectID, HierarchyID, ...property } });
    } catch (e) {
      console.error(e);
    }
  };
};

export const deleteComponentHierarchyLevelProperty = (property, callback, HierarchyID) => {
  return async () => {
    try {
      const { ID, isTemp } = property;
      if (!isTemp) {
        await componentHierarchyAPI.deleteComponentHierarchyLevelProperty({ Property: { ID, HierarchyID } });
      }
      callback && callback(property);
    } catch (e) {
      console.error(e);
    }
  };
};
export const fetchUnassignedComponents = (filters, callback, loadMore, unassignedComponents) => {
  return async () => {
    try {
      callback({ isLoadingUnassignedComponents: true });
      const res = await componentHierarchyAPI.fetchUnassignedComponents(filters);
      const data = res?.data?.Data;
      callback({
        unassignedComponents: loadMore ? [...unassignedComponents, ...data.Components] : data.Components,
        unassignedComponentsTotalItems: data.TotalNumber,
        isLoadingUnassignedComponents: false,
        unassignedComponentsFilters: { ...filters, HasNext: data.HasNext || false, LastSeen: data.LastSeen },
      });
    } catch (e) {
      callback({ isLoadingUnassignedComponents: false });
      console.error(e);
    }
  };
};
export const fetchComponents = (filters, callback, loadMore, components, isUnassignedLevel = false) => {
  return async () => {
    try {
      callback({ isLoadingComponents: true });
      const apiCall = isUnassignedLevel ? componentHierarchyAPI.fetchUnassignedComponents : componentHierarchyAPI.fetchComponentHierarchyComponents;
      const res = await apiCall(filters);
      const data = res?.data?.Data;
      callback({
        components: uniqBy(loadMore ? [...components, ...data.Components] : data.Components, 'ID'),
        componentsTotalItems: data.TotalNumber,
        isLoadingComponents: false,
        componentsFilters: { ...filters, HasNext: data.HasNext || false, LastSeen: data.LastSeen },
      });
    } catch (e) {
      callback({ isLoadingComponents: false });
      console.error(e);
    }
  };
};
export const updateComponentHierarchyComponent = (data, callback) => {
  return async () => {
    try {
      await componentHierarchyAPI.updateComponentHierarchyComponent(data);
      callback && callback();
    } catch (e) {
      console.error(e);
    }
  };
};
export const fetchComponentDetails = (params, callback, t) => {
  return async () => {
    try {
      callback && callback({ isLoadingSelectedComponentData: true });
      const res = await componentHierarchyAPI.fetchComponentHierarchyComponentDetails(params);
      const data = res?.data?.Data;
      data.Critical = data?.Critical ? (t ? t('COMPONENT_DETAILS.CRITICAL_YES') : 'Yes') : t ? t('COMPONENT_DETAILS.CRITICAL_NO') : 'No';
      callback && callback({ selectedComponentData: data, selectedComponent: data?.ID, toolbarCollapsed: false, isLoadingSelectedComponentData: false });
    } catch (e) {
      callback && callback({ isLoadingSelectedComponentData: false });
      console.error(e);
    }
  };
};
export const deleteLevel = (data, callback) => {
  return async () => {
    try {
      await componentHierarchyAPI.deleteComponentHierarchyLevel(data);
      callback && callback();
    } catch (e) {
      console.error(e);
    }
  };
};
export const fetchComponentProperties = (ComponentID, callback) => {
  return async () => {
    try {
      const res = await componentHierarchyAPI.fetchComponentHierarchyComponentProperties({ ComponentID });
      const { Data } = res?.data;
      callback && callback({ selectedComponentProperties: Data.Properties });
    } catch (e) {
      console.error(e);
    }
  };
};
