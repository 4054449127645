import { Component } from 'react';
import SearchInput from '../../../common/input/components/search-input';
import PageNavigation from '../../../common/page-navigation/components/page-navigation';
import { actionButtonProps, defaultFilters, filterProps, placements, fields, DeleteActionTypes, DeleteActionText } from '../constants/constants';
import PropTypes from 'prop-types';
import routesConstants from '../../../common/routes-constants';
import '../styles/component-hierarchy.scss';
import RenderIf from '../../../common/render-if/components/render-if';
import { isEmpty, debounce, get, toInteger, remove } from 'lodash';
import CHEmptyState from './ch-empty-state';
import CHTable from './ch-table/ch-table';
import {
  fetchComponentHierarchyLevels,
  createComponentHierarchyLevel,
  fetchComponentHierarchyLevelDetails,
  updateComponentHierarchyLevel,
  fetchComponentHierarchyLevelPropertiesSuggestions,
  addComponentHierarchyLevelProperty,
  updateComponentHierarchyLevelProperty,
  deleteComponentHierarchyLevelProperty,
  deleteLevel,
} from '../actions/component-hierarchy-levels-actions';
import { connect } from 'react-redux';
import Loader from '../../../common/global-loader/components/simple-loader';
import Helpers from '../../../common/helpers';
import CHNoData from './ch-table-no-data';
import PathViewer from '../../../common/path-viewer/components/path-viewer';
import Toolbar from '../../inspections/components/collapsible-toolbar';
import CHRightSide from './ch-right-side/ch-right-side';
import { validateLevel } from '../validators/ch-right-side-form';
import Notification from '../../../common/notification/components/notification';
import { ActionTypesToolbar } from '../constants/constants';
import Modal from '../../../common/modal/components/modal';
import DeleteModal from './ch-modals/delete-modal';
import ConfirmModal from './ch-modals/confirm-modal';
import PickLevelModal from './ch-modals/pick-level-modal/pick-level-modal';
import ComponentHierarchyHelpers from '../helpers/helpers';

class ComponentHierarchy extends Component {
  constructor(props, context) {
    super(props);

    const { t } = context;
    const { location } = props;
    const { query } = location;

    this.state = {
      componentLevels: [],
      filters: defaultFilters,
      TotalItems: 0,
      isLoading: false,
      levelPath: [{ Code: t('COMPONENT_HIERARCHY.ALL_LEVELS') }],
      SearchText: '',
      toolbarCollapsed: true,
      selectedItem: null,
      selectedItemData: {},
      selectedItemDataProperties: [],
      isRightSideLoading: false,
      currentLevelID: null,
      persistedSelectedItem: null,
      levelNestedNumber: null,
      notificationData: {
        isDisplayed: false,
      },
      isRightSideDisabled: true,
      deleteModalData: {
        isOpen: false,
      },
      confirmModalData: {
        isOpen: false,
      },
      pickLevelModalData: {
        isOpen: false,
      },
    };
    this.searchChangeDebounced = debounce(this.handleSearch, 300);
    this.FormSubmitDebounce = debounce(this.submitForm, 250);
    this.ProjectID = toInteger(get(query, 'project_id'));
  }

  componentDidMount = () => {
    const { filters } = this.state;

    this.fetchLevels(filters);
  };

  onSuccessFetch = newState => this.setState({ ...newState });

  onSuccessPropAdd = prop => {
    const { selectedItem } = this.state;
    const { fetchComponentHierarchyLevelDetails } = this.props;
    fetchComponentHierarchyLevelDetails(selectedItem, this.onSuccessFetch, null, true);
  };

  fetchLevels = (filters, loadMore) => {
    const { componentLevels } = this.state;
    const { fetchComponentHierarchyLevels } = this.props;

    const onSuccessFetch = newState => {
      this.setState({ ...newState, toolbarCollapsed: true });

      if (loadMore && newState.componentLevels) {
        Helpers.scrollIntoView('ch-table-container', `row-${filters[filterProps.lastSeen] - 1}`, -250);
      }
    };

    fetchComponentHierarchyLevels({ ProjectID: this.ProjectID, ...filters }, onSuccessFetch, loadMore, componentLevels);
  };

  onColumnSort = SortByColumn => {
    const { filters } = this.state;
    ComponentHierarchyHelpers.onColumnSort(SortByColumn, filters, this.fetchLevels);
  };

  handleSearch = SearchText => {
    const { filters } = this.state;
    ComponentHierarchyHelpers.onSearch(SearchText, filters, defaultFilters, this.fetchLevels);
  };
  handleInputChange = e => {
    this.setState({ SearchText: e.target.value });
    this.searchChangeDebounced(e.target.value);
  };

  loadMore = () => {
    const { filters } = this.state;
    ComponentHierarchyHelpers.onLoadMore(filters, this.fetchLevels);
  };

  handleRowClick = row => {
    const { filters, levelPath } = this.state;
    ComponentHierarchyHelpers.onRowLevelClick(row, levelPath, filters, defaultFilters, this.fetchLevels, this.onSuccessFetch);
  };
  handlePathBackClick = () => {
    const { levelPath, filters } = this.state;
    ComponentHierarchyHelpers.onPathBackClick(levelPath, filters, defaultFilters, this.fetchLevels, this.onSuccessFetch);
  };
  handlePathClick = ID => {
    const { levelPath, filters } = this.state;
    ComponentHierarchyHelpers.onPathClick(ID, levelPath, filters, defaultFilters, this.fetchLevels, this.onSuccessFetch);
  };

  handleToolbarClick = (_, e) => {
    const { toolbarCollapsed, selectedItem } = this.state;
    e?.stopPropagation();
    if (!selectedItem) return;
    this.setState({ toolbarCollapsed: !toolbarCollapsed });
  };

  handleActionClick = (row, actionType) => {
    const closeAction = () => this.setState({ deleteModalData: { isOpen: false } });
    const { t } = this.context;
    if (actionType === ActionTypesToolbar.delete) {
      this.setState({
        deleteModalData: {
          isOpen: true,
          type: '',
          actionType,
          customClassName: 'modal-no-max-height modal-medium',
          closeAction,
          CustomContent: dynamicProps => <DeleteModal {...dynamicProps} />,
          title: t('COMPONENT_HIERARCHY_MODAL.DELETE_TITLE'),
          levelName: `${row?.Code} ${row?.Name}`,
          customConfirmAction: this.handleDeleteModalAction,
          levelInfo: {
            HierarchyID: row?.ID,
            ProjectID: this.ProjectID,
          },
        },
      });
    } else {
      const { fetchComponentHierarchyLevelDetails } = this.props;
      fetchComponentHierarchyLevelDetails(row?.ID, this.onSuccessFetch, actionType);
    }
  };

  handleAddNewLevelClick = e => {
    e?.stopPropagation();
    const { createComponentHierarchyLevel } = this.props;
    const { componentLevels, TotalItems, currentLevelID, levelPath, levelNestedNumber } = this.state;
    const { t } = this.context;

    this.setState({ selectedItemDataProperties: [] });
    if (levelNestedNumber > 10 || levelPath?.length > 10) {
      this.setState({
        notificationData: {
          isDisplayed: true,
          type: 'error',
          icon: 'close',
          text: t('COMPONENT_HIERARCHY.MAX_LEVEL'),
          onClickClose: () => this.setState({ notificationData: { isDisplayed: false } }),
        },
      });
      return;
    }

    createComponentHierarchyLevel(
      { ProjectID: this.ProjectID, [filterProps.parentID]: currentLevelID, [fields.name]: t('COMPONENT_HIERARCHY.NEW_LEVEL') },
      componentLevels,
      TotalItems,
      this.onSuccessFetch
    );
  };

  handleOnChange = (values, _, c) => {
    if (!c.pristine) {
      const err = validateLevel(values);
      if (isEmpty(err)) this.FormSubmitDebounce(values);
    }
  };
  submitForm = values => {
    const { componentLevels } = this.state;
    const { updateComponentHierarchyLevel, currentLevelID } = this.props;
    updateComponentHierarchyLevel({ ProjectID: this.ProjectID, [filterProps.parentID]: currentLevelID, ...values }, componentLevels, this.onSuccessFetch);
  };

  handleDeselectItem = e => {
    e?.stopPropagation();
    this.setState({ selectedItem: null, toolbarCollapsed: true });
  };

  handleFetchSuggestion = (SearchText, callback) => {
    const { fetchComponentHierarchyLevelPropertiesSuggestions } = this.props;
    fetchComponentHierarchyLevelPropertiesSuggestions(SearchText, callback, this.ProjectID);
  };
  handleAddProperty = property => {
    const { persistedSelectedItem } = this.state;
    const { addComponentHierarchyLevelProperty } = this.props;
    addComponentHierarchyLevelProperty(property, this.onSuccessPropAdd, this.ProjectID, persistedSelectedItem);
  };
  handleUpdateProperty = property => {
    const { persistedSelectedItem } = this.state;
    const { updateComponentHierarchyLevelProperty } = this.props;
    //Callback was moved in front due to the laginess of the UI, while waiting for API response
    updateComponentHierarchyLevelProperty(property, this.ProjectID, persistedSelectedItem);
  };
  handleDeleteProperty = (property, callback) => {
    const { selectedItem, selectedItemDataProperties } = this.state;
    const { deleteComponentHierarchyLevelProperty } = this.props;
    const onSuccess = prop => {
      callback && callback();
      const newProp = remove(selectedItemDataProperties, item => item.ID !== prop?.ID);
      this.setState({ selectedItemDataProperties: newProp });
    };
    deleteComponentHierarchyLevelProperty(property, onSuccess, selectedItem);
  };

  //handle modal actions
  handleDeleteModalAction = type => {
    const { t } = this.context;
    if (type === DeleteActionTypes.deleteAndMove) {
      const closeAction = () => this.setState({ pickLevelModalData: { isOpen: false } });
      this.setState(prevState => ({
        pickLevelModalData: {
          isOpen: true,
          type: '',
          title: t('COMPONENT_HIERARCHY_MODAL.MOVE_TITLE'),
          actionType: type,
          closeAction,
          customCloseAction: closeAction,
          CustomContent: dynamicProps => <PickLevelModal {...dynamicProps} />,
          customClassName: 'modal-no-max-height modal-large',
          ProjectID: this.ProjectID,
          levelID: prevState.deleteModalData.levelInfo.HierarchyID,
          initialValues: {
            ...prevState.deleteModalData.levelInfo,
            DeleteAll: type === DeleteActionTypes.deleteAll,
          },
          customConfirmAction: this.handleConfirmModalAction,
        },
        deleteModalData: {
          isOpen: false,
        },
      }));
    } else if (type === DeleteActionTypes.deleteAndUnassign) {
      const { deleteModalData } = this.state;
      this.handleConfirmModalAction({ ...deleteModalData?.levelInfo, DeleteAll: type === DeleteActionTypes.deleteAll }, type);
    } else {
      const closeAction = () => this.setState({ confirmModalData: { isOpen: false } });
      this.setState(prevState => ({
        confirmModalData: {
          levelName: prevState.deleteModalData.levelName,
          initialValues: {
            ...prevState.deleteModalData.levelInfo,
            DeleteAll: type === DeleteActionTypes.deleteAll,
          },
          isOpen: true,
          actionType: type,
          type: '',
          title: t('COMPONENT_HIERARCHY_MODAL.CONFIRM_TITLE'),
          closeAction,
          customCloseAction: closeAction,
          CustomContent: dynamicProps => <ConfirmModal {...dynamicProps} />,
          customClassName: 'modal-no-max-height modal-medium',
          customConfirmAction: this.handleConfirmModalAction,
        },
        deleteModalData: {
          isOpen: false,
        },
      }));
    }
  };
  handleConfirmModalAction = (values, type) => {
    const { deleteLevel } = this.props;
    const { componentLevels, TotalItems } = this.state;
    const { t } = this.context;
    const closeAction = () => this.setState({ notificationData: { isDisplayed: false } });
    const onSuccessDelete = () => {
      const newComponents = Object.assign([], componentLevels);
      remove(newComponents, { ID: values.HierarchyID });
      this.setState(prevState => ({
        componentLevels: newComponents,
        confirmModalData: { isOpen: false },
        pickLevelModalData: { isOpen: false },
        deleteModalData: { isOpen: false },
        notificationData: {
          isDisplayed: true,
          icon: 'trash',
          type: 'error',
          text: t(DeleteActionText[prevState?.confirmModalData?.actionType || prevState?.pickLevelModalData?.actionType || type]),
          closeAction,
        },
        TotalItems: TotalItems - 1,
      }));
      setTimeout(() => closeAction(), 5000);
    };
    deleteLevel(values, onSuccessDelete);
  };

  render() {
    const { location } = this.props;
    const { search } = location;
    const { t } = this.context;
    const {
      componentLevels,
      filters,
      TotalItems,
      isLoading,
      levelPath,
      SearchText,
      toolbarCollapsed,
      selectedItem,
      isRightSideLoading,
      selectedItemData,
      selectedItemDataProperties,
      notificationData,
      isRightSideDisabled,
      deleteModalData,
      confirmModalData,
      pickLevelModalData,
    } = this.state;
    const isNested = levelPath.length > 1;

    return (
      <div className={`component-hierarchy default-background ${toolbarCollapsed ? '' : 'toolbar-opened'}`}>
        <div onClick={this.handleDeselectItem}>
          <PageNavigation
            backButtonPath={`${routesConstants.routes.protectedRoutes.projectSettings.fullPath}${search}`}
            title="COMPONENT_HIERARCHY.TITLE"
            icon="component-hierarchy"
            withBottomBorder
            actionButtontext="COMPONENT_HIERARCHY.NEW_LEVEL"
            actionButtonProps={actionButtonProps}
            handleActionButtonClick={this.handleAddNewLevelClick}
          />
        </div>
        <div className={`component-hierarchy__container `} onClick={this.handleDeselectItem}>
          <SearchInput onChange={this.handleInputChange} placeholder={t('PROJECT.SEARCH.PLACEHOLDER')} stripped wrapperClass="component-hierarchy__container__search" value={SearchText} />
          <div className={`component-hierarchy__container__path ${isNested ? 'show' : 'hide'}`}>
            <PathViewer levels={levelPath} onBackClick={this.handlePathBackClick} onPathClick={this.handlePathClick} />
          </div>
          <RenderIf if={isEmpty(componentLevels) && !isLoading && !filters?.SearchText}>
            <CHEmptyState create={isNested} />
          </RenderIf>
          <RenderIf if={!isEmpty(componentLevels) && !isLoading}>
            <CHTable
              data={componentLevels}
              filters={filters}
              searchTerm={filters?.SearchText}
              TotalItems={TotalItems}
              onSortClick={this.onColumnSort}
              loadMoreOnClick={this.loadMore}
              loadMoreDisabled={!filters?.HasNext}
              onRowClick={this.handleRowClick}
              onDropdownClick={this.handleActionClick}
              selectedItem={selectedItem}
            />
          </RenderIf>
          <RenderIf if={isEmpty(componentLevels) && !isLoading && filters?.SearchText}>
            <CHNoData />
          </RenderIf>
          <RenderIf if={isLoading}>
            <Loader isLoading={isLoading} />
          </RenderIf>
        </div>
        <Toolbar
          collapsed={toolbarCollapsed}
          placement={placements.right}
          resizable={false}
          toggleToolbar={this.handleToolbarClick}
          children={
            <CHRightSide
              isLoading={isRightSideLoading}
              initialValues={selectedItemData}
              onChange={this.handleOnChange}
              properties={selectedItemDataProperties}
              getSuggestions={this.handleFetchSuggestion}
              addProperty={this.handleAddProperty}
              updateProperty={this.handleUpdateProperty}
              deleteProperty={this.handleDeleteProperty}
              isDisabled={isRightSideDisabled}
            />
          }
          stopProp={true}
        />
        <Notification {...notificationData} />
        <Modal {...deleteModalData} />
        <Modal {...confirmModalData} />
        <Modal {...pickLevelModalData} />
      </div>
    );
  }
}

ComponentHierarchy.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  fetchComponentHierarchyLevels: (data, callback, loadMore, componentLevels) => dispatch(fetchComponentHierarchyLevels(data, callback, loadMore, componentLevels)),
  createComponentHierarchyLevel: (data, levels, TotalItems, callback) => dispatch(createComponentHierarchyLevel(data, levels, TotalItems, callback)),
  fetchComponentHierarchyLevelDetails: (data, callback, actionType, isPropertyAdd) => dispatch(fetchComponentHierarchyLevelDetails(data, callback, actionType, isPropertyAdd)),
  updateComponentHierarchyLevel: (data, componentLevels, callback) => dispatch(updateComponentHierarchyLevel(data, componentLevels, callback)),
  fetchComponentHierarchyLevelPropertiesSuggestions: (SearchText, callback, ProjectID) => dispatch(fetchComponentHierarchyLevelPropertiesSuggestions(SearchText, callback, ProjectID)),
  addComponentHierarchyLevelProperty: (property, callback, ProjectID, HierarchyID) => dispatch(addComponentHierarchyLevelProperty(property, callback, ProjectID, HierarchyID)),
  updateComponentHierarchyLevelProperty: (property, ProjectID, HierarchyID) => dispatch(updateComponentHierarchyLevelProperty(property, ProjectID, HierarchyID)),
  deleteComponentHierarchyLevelProperty: (property, callback, HierarchyID) => dispatch(deleteComponentHierarchyLevelProperty(property, callback, HierarchyID)),
  deleteLevel: (data, callback) => dispatch(deleteLevel(data, callback)),
});

export default connect(null, mapDispatchToProps)(ComponentHierarchy);
