import React from 'react';
import PropTypes from 'prop-types';

import '../styles/paginator.scss';

const Paginator = (props, context) => {
  const { text, onClick, disabled, className, id } = props;

  return (
    <div className={`paginator noselect ${className || ''} ${disabled ? 'disabled' : ''}`} onClick={disabled ? () => null : onClick} id={id || ''}>
      <p className={`${disabled ? 'f-secondary-dark' : 'f-secondary-green'} link`}>{text}</p>
    </div>
  );
};
Paginator.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default Paginator;
