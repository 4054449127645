import { formConstants } from '../constants/constants';
import { EMAIL_REGEX, PHONE_REGEX } from '../../../common/constants';

export const validate = values => {
  const errors = {};

  if (!values[formConstants.fields.name]) {
    errors[formConstants.fields.name] = 'CONTRACTOR_FORM_VALIDATION.USER_NAME_REQUIRED';
  }

  if (values[formConstants.fields.name] && values[formConstants.fields.name].length < 3) {
    errors[formConstants.fields.name] = 'CONTRACTOR_FORM_VALIDATION.USER_NAME_MIN_LENGTH';
  }

  if (values[formConstants.fields.name] && values[formConstants.fields.name].length > 50) {
    errors[formConstants.fields.name] = 'CONTRACTOR_FORM_VALIDATION.USER_NAME_MAX_LENGTH';
  }

  if (values[formConstants.fields.email] && !EMAIL_REGEX.test(values[formConstants.fields.email])) {
    errors[formConstants.fields.email] = 'CONTRACTOR_FORM_VALIDATION.EMAIL_INVALID';
  }

  if (values[formConstants.fields.phone] && PHONE_REGEX.test(values[formConstants.fields.phone])) {
    errors[formConstants.fields.phone] = 'CONTRACTOR_FORM_VALIDATION.CONTACT_PHONE_INVALID';
  }

  return errors;
};
