import React from 'react';
import Icon from '../../icon/components/icon';
import PropTypes from 'prop-types';
import { iconstMap } from '../../icon/constants/constants';

import '../styles/navigation-button.scss';

const NavigationButton = ({ label, icon, newFeature, comingSoon, action, route }, { t }) => {
  const handleOnClick = () => {
    if (comingSoon) return;
    action && route && action(route);
  };

  return (
    <button className={`navigation-button ${newFeature ? 'new' : ''} ${comingSoon ? 'soon' : ''}`} onClick={handleOnClick}>
      <div className="navigation-button__info">
        <Icon name={icon} size="md" active />
        <p className="f-primary light-bold">{t(label)}</p>
        {newFeature && <p className="f-primary navigation-button__new-or-soon-box">{t('INSPECTION_DETAILS.NEW')}</p>}
        {comingSoon && <p className="f-primary navigation-button__new-or-soon-box"> {t('INSPECTION_DETAILS.SOON')}</p>}
      </div>
      <div className="navigation-button__icon">
        <Icon name="chevron" size="sm" solid />
      </div>
    </button>
  );
};

NavigationButton.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.oneOf(Object.keys(iconstMap)).isRequired,
  newFeature: PropTypes.bool,
  comingSoon: PropTypes.bool,
  action: PropTypes.func,
  route: PropTypes.string,
};

NavigationButton.defaultProps = {
  newFeature: false,
  comingSoon: false,
};

NavigationButton.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default NavigationButton;
