import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { createNumberMask } from 'redux-form-input-masks';

import CustomInput from '../../../../common/form/components/input';
import CustomSelect from '../../../../common/form/components/select';
import Textarea from '../../../../common/form/components/text-area';
import TimePicker from '../../../../common/form/components/time-picker';
import { formConstants, advancedDetailsMaxChars, intervalFields } from '../../constants/regime-constants';

const AdvancedDetailsForm = ({ isPreviewMode, intervalList }, { t }) => {
  const numberMask = createNumberMask({
    decimalPlaces: 0,
    allowNegative: false,
  });

  return (
    <>
      <Field
        id={formConstants.fields.descripton}
        name={formConstants.fields.descripton}
        component={Textarea}
        autoFocus={true}
        disabled={isPreviewMode}
        placeholder={t('CREATE_REGIME_FORM.DESCRIPTION')}
        label={t('CREATE_REGIME_FORM.DESCRIPTION')}
        type="text"
        size="lg"
        maxChars={advancedDetailsMaxChars.description}
      />
      <Field
        id={formConstants.fields.toolsRequired}
        name={formConstants.fields.toolsRequired}
        component={Textarea}
        autoFocus={true}
        disabled={isPreviewMode}
        placeholder={t('CREATE_REGIME_FORM.TOOLS_REQUIRED')}
        label={t('CREATE_REGIME_FORM.TOOLS_REQUIRED')}
        type="text"
        size="lg"
        maxChars={advancedDetailsMaxChars.toolsRequired}
      />
      <div className="double">
        <div className="split-input">
          <div className="double">
            <div className="split-input">
              <Field
                id={formConstants.fields.taskIntervalNumber}
                name={formConstants.fields.taskIntervalNumber}
                component={CustomInput}
                placeholder={t('CREATE_REGIME_FORM.TASK_DURATION')}
                label={t('CREATE_REGIME_FORM.TASK_DURATION')}
                type="tel"
                size="lg"
                isRequired
                disabled={isPreviewMode}
                {...numberMask}
              />
            </div>
            <div className="split-input with-fake-label">
              <Field
                id={formConstants.fields.intervalId}
                name={formConstants.fields.intervalId}
                data={intervalList}
                component={CustomSelect}
                valueField={intervalFields.id}
                textField={intervalFields.name}
                className="select-d"
                size="lg"
                disabled={isPreviewMode}
              />
            </div>
          </div>
        </div>
        <div className="split-input wrench-time-container">
          <Field
            id={formConstants.fields.estimatedWrenchTime}
            name={formConstants.fields.estimatedWrenchTime}
            component={TimePicker}
            disabled={isPreviewMode}
            label={t('CREATE_REGIME_FORM.ESTIMATED_WRENCH_TIME')}
            type="text"
            size="lg"
            showPlaceholderWhileValue
          />
        </div>
      </div>
    </>
  );
};

AdvancedDetailsForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default AdvancedDetailsForm;
