export default {
  GET_TEAMS: '/company/teams',
  UPDATE_TEAM: '/company/team/update',
  DELETE_TEAM: '/company/team/delete',
  CREATE_TEAM: '/company/team/create',
  ADD_TEAM_USER: '/company/team/user/add',
  DELETE_TEAM_USER: '/company/team/user/delete',
  GET_TEAM_USERS: '/company/team/users',
  SEARCH_ASSIGNEES: '/company/assignee/search',
};
