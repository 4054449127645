import React from 'react';
import DropdownMenu from '../components/users/table-dropdown';

const usersTableHeader = [
  {
    title: 'TABLE.USER_NO',
    key: 'UserID',
    enableSort: true,
  },
  {
    title: 'TABLE.USER_NAME',
    key: 'UserName',
    enableSort: true,
  },
  {
    title: 'TABLE.EMAIL',
    key: 'UserEmail',
    enableSort: true,
  },
  {
    title: 'TABLE.LAST_LOGIN',
    key: 'LastTimeLogged',
    enableSort: true,
    type: 'date',
  },
  {
    title: 'TABLE.ACTIVE',
    key: 'UserActive',
    enableSort: true,
    type: 'active',
    translatable: true,
  },
  {
    title: 'TABLE.PHONE_NUMBER',
    key: 'UserPhone',
    enableSort: true,
  },
  {
    title: 'TABLE.ROLE',
    key: 'Policies',
    type: 'Policies',
    enableSort: true,
  },
  {
    title: 'TABLE.ACTIONS',
    CustomComponent: (data, itemIndex) => <DropdownMenu data={data} itemIndex={itemIndex} />,
    enableSort: false,
  },
];

const sortDirection = {
  asc: 'ASC',
  desc: 'DESC',
};

const userDataParams = {
  SortByColumn: 'UserID',
  SortDirection: sortDirection.asc,
  LastSeen: 0,
  SearchText: '',
  PerPage: 20,
  HasNext: false,
  ActiveUsers: false,
};

export const formConstants = {
  fields: {
    id: 'AccountID',
    name: 'Name',
    email: 'Email',
    company: 'CompanyID',
    phone: 'Phone',
    role: 'Policies',
    userRoleId: 'UserRoleID',
    userId: 'UserID',
  },
};

export default {
  usersTableHeader,
  sortDirection,
  userDataParams,
  formConstants,
};
