import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field, FieldArray, reduxForm } from 'redux-form';

import Button from '../../../../../common/form/components/button';
import CustomSelect from '../../../../../common/form/components/select';
import SignatureField from '../../../../../common/form/components/signature-field';
import RenderIf from '../../../../../common/render-if/components/render-if';

import { FORMS } from '../../../../../common/constants';
import { contractorDropdownFields, formConstants, keyFields, steps } from '../constants/issue-permit-constants';
import { fields as keyboxFields, singleKeyFields } from '../constants/keybox-constants';
import { validate } from '../validators/issue-permit-validator';

// TODO: refactor to functional component
class IssuePermitForm extends Component {
  renderAssignedKeys = ({ fields }) => {
    const { t } = this.context;
    const { keyboxes, change } = this.props;

    return fields.map((assignedKey, index) => {
      const availableKeys = (keyboxes[index][keyboxFields.keys.name] || []).map(el => ({ ...el, KeyboxName: keyboxes[index][keyboxFields.name.name] })).filter(key => !key[singleKeyFields.issuedToId]);
      return (
        <div className="lock-key">
          <p className="f-primary keybox-name">{keyboxes[index][keyboxFields.name.name]}</p>
          <Field
            id={`${assignedKey}.${keyFields.selectedKey}`}
            name={`${assignedKey}.${keyFields.selectedKey}`}
            label="PERMIT.ISSUE_PERMIT_FORM.LOCK_KEY"
            labelClass="f-secondary-light"
            data={availableKeys}
            component={CustomSelect}
            valueField={singleKeyFields.id}
            textField={singleKeyFields.name}
            onChange={(a, value) => {
              change(`${assignedKey}.${keyFields.id}`, value[singleKeyFields.id]);
            }}
            placeholder={t('PERMIT.ISSUE_PERMIT_FORM.LOCK_KEY')}
          />
        </div>
      );
    });
  };
  render() {
    const { t } = this.context;
    const { handleSubmit, activeStep, lastStep, stepAction, contractors, disabled, signatureRequired } = this.props;
    const { contractor, assigneeSignature, witnessSignature, assignedKeys } = formConstants.fields;

    return (
      <form className="issue-permit-form" onSubmit={handleSubmit} noValidate>
        <div className="issue-permit-form__content">
          {activeStep === steps.firstStep && (
            <div className="assigned-keys">
              <FieldArray name={assignedKeys} component={this.renderAssignedKeys} />
            </div>
          )}
          {activeStep === steps.secondStep && (
            <>
              <div className="contractors-dropdown">
                <Field
                  id={contractor.id}
                  name={contractor.name}
                  label={contractor.label}
                  placeholder={t(contractor.placeholder)}
                  component={CustomSelect}
                  data={contractors || []}
                  size="lg"
                  valueField={contractorDropdownFields.valueField}
                  textField={contractorDropdownFields.textField}
                  disabled={disabled}
                  filter="contains"
                  preventOnBlur
                />
              </div>
              <RenderIf if={signatureRequired}>
                <div className="signature-field-container">
                  <Field
                    id={assigneeSignature.id}
                    querySelector={assigneeSignature.querySelector}
                    name={assigneeSignature.name}
                    label={assigneeSignature.label}
                    placeholder={assigneeSignature.label}
                    component={SignatureField}
                    size="xl"
                    disabled={disabled}
                  />
                </div>
                <div className="signature-field-container">
                  <Field
                    id={witnessSignature.id}
                    querySelector={witnessSignature.querySelector}
                    name={witnessSignature.name}
                    label={witnessSignature.label}
                    placeholder={witnessSignature.label}
                    component={SignatureField}
                    size="xl"
                    disabled={disabled}
                  />
                </div>
              </RenderIf>
            </>
          )}
        </div>

        <div className="buttons">
          <Button
            type="button"
            variant="gray-outline"
            width="sm"
            text={t(activeStep === steps.firstStep ? 'PERMIT.ISSUE_PERMIT_POPUP.CANCEL' : 'PERMIT.ISSUE_PERMIT_POPUP.BACK')}
            onClick={() => stepAction(false)}
          />
          <Button keepOriginalText text={t(activeStep === lastStep ? 'PERMIT.ISSUE_PERMIT_POPUP.SAVE' : 'PERMIT.ISSUE_PERMIT_POPUP.NEXT')} />
        </div>
      </form>
    );
  }
}

IssuePermitForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

IssuePermitForm = reduxForm({
  form: FORMS.issuePermitForm,
  validate,
})(IssuePermitForm);

export default IssuePermitForm;
