import uploadApi from '../../../api/upload/actions';

export const uploadType = {
  defectFiles: 'DEFECT_FILES',
  measurementFiles: 'MAIN_MEASUREMENT_FILES',
  componentFiles: 'COMPONENT_FILES',
  inspectionImages: 'INSPECTION_IMAGES',
  ndtMeasurementFiles: 'MEASUREMENT_FILES',
  ndtMeasurementPointFiles: 'MEASURE_FILES',
  pdfTaggingFiles: 'PDF_TAGGING',
  inspectionFiles: 'INSPECTION_FILES',
  contractorFiles: 'CONTRACTOR_FILES',
  isolationTemplateFiles: 'ISOLATION_TEMPLATE_FILES',
  checklistProcedureQuestionFiles: 'SECTION_QUESTION_FILES',
  permitTemplateFiles: 'PERMIT_TEMPLATE_FILES',
  isolationComponentFiles: 'ISOLATION_COMPONENT_FILES',
  noTypeFiles: 'NO_TYPE',
  workOrderFiles: 'WORK_ORDER_COMPLETION_FILES',
  maintenanceRegimeFiles: 'MAINTENANCE_REGIME_FILES',
  permitFiles: 'PERMIT_FILES',
  dmFiles: 'DOCUMENT_MANAGEMENT_FILES',
  dmFilesFromInspection: 'DOCUMENT_MANAGEMENT_FILES_FROM_INSPECTION',
  dmFilesFromDefect: 'DOCUMENT_MANAGEMENT_FILES_FROM_DEFECT',
  dmFilesFromNotification: 'DOCUMENT_MANAGEMENT_FILES_FROM_NOTIFICATION',
  dmFilesFromWorkorder: 'DOCUMENT_MANAGEMENT_FILES_FROM_WORKORDER',
  dmFilesFromWorkorderWizard: 'DOCUMENT_MANAGEMENT_FILES_FROM_WORKORDER_WIZARD',
  dmFilesFromChecklistQuestion: 'DOCUMENT_MANAGEMENT_FILES_FROM_CHECKLIST_QUESTION',
  dmFilesFromChecklistQuestionEvidence: 'DOCUMENT_MANAGEMENT_FILES_FROM_CHECKLIST_QUESTION_EVIDENCE',
  dmFilesFromWorkorderCompletion: 'DOCUMENT_MANAGEMENT_FILES_FROM_WORKORDER_COMPLETION',
};

const uploadGroup = {
  other: 'OTHER',
  otherFiles: 'OTHER_FILES',
};

const uploadActionsByType = {
  [uploadType.defectFiles]: {
    getSignedUrl: uploadApi.uploadDefectFile,
  },
  [uploadType.measurementFiles]: {
    getSignedUrl: uploadApi.uploadMeasurementFile,
  },
  [uploadType.componentFiles]: {
    getSignedUrl: uploadApi.uploadComponentFile,
  },
  [uploadType.inspectionImages]: {
    getSignedUrl: uploadApi.getSignedUrl,
  },
  [uploadType.ndtMeasurementFiles]: {
    getSignedUrl: uploadApi.uploadNDTMeasurementFile,
  },
  [uploadType.ndtMeasurementPointFiles]: {
    getSignedUrl: uploadApi.uploadNDTMeasurementPointFile,
  },
  [uploadType.pdfTaggingFiles]: {
    getSignedUrl: uploadApi.uploadPdfTaggingFile,
  },
  [uploadType.inspectionFiles]: {
    getSignedUrl: uploadApi.uploadInspectionFile,
  },
  [uploadType.contractorFiles]: {
    getSignedUrl: uploadApi.uploadContractorFile,
  },
  [uploadType.isolationTemplateFiles]: {
    getSignedUrl: uploadApi.uploadIsolationTemplateFile,
  },
  [uploadType.checklistProcedureQuestionFiles]: {
    getSignedUrl: uploadApi.uploadChecklistProcedureQuestionFile,
  },
  [uploadType.dmFilesFromChecklistQuestionEvidence]: {
    getSignedUrl: uploadApi.uploadChecklistProcedureQuestionEvidenceFile,
  },
  [uploadType.permitTemplateFiles]: {
    getSignedUrl: uploadApi.uploadPermitTemplateFile,
  },
  [uploadType.isolationComponentFiles]: {
    getSignedUrl: uploadApi.uploadIsolationComponentFile,
  },
  [uploadType.noTypeFiles]: {
    getSignedUrl: uploadApi.getSignedUrl,
  },
  [uploadType.workOrderFiles]: {
    getSignedUrl: uploadApi.uploadWorkOrderFile,
  },
  [uploadType.maintenanceRegimeFiles]: {
    getSignedUrl: uploadApi.uploadMaintenanceRegimeFile,
  },
  [uploadType.permitFiles]: {
    getSignedUrl: uploadApi.uploadPermitFiles,
  },
  [uploadType.dmFiles]: {
    getSignedUrl: uploadApi.uploadDMFiles,
  },
  [uploadType.dmFilesFromInspection]: {
    getSignedUrl: uploadApi.uploadDMFilesFromInspection,
  },
  [uploadType.dmFilesFromDefect]: {
    getSignedUrl: uploadApi.uploadDMFilesFromDefect,
  },
  [uploadType.dmFilesFromNotification]: {
    getSignedUrl: uploadApi.uploadDMFilesFromNotification,
  },
  [uploadType.dmFilesFromWorkorder]: {
    getSignedUrl: uploadApi.uploadDMFilesFromWorkorder,
  },
  [uploadType.dmFilesFromWorkorderWizard]: {
    getSignedUrl: uploadApi.uploadDMFilesFromWorkorderWizard,
  },
  [uploadType.dmFilesFromWorkorderCompletion]: {
    getSignedUrl: uploadApi.uploadDMFilesFromWorkorderCompletion,
  },
  [uploadType.dmFilesFromChecklistQuestion]: {
    getSignedUrl: uploadApi.uploadDMFilesFromChecklistQuestion,
  },
};

const photohrammetryUploadRetryCount = 3;

export default { uploadType, photohrammetryUploadRetryCount, uploadGroup, uploadActionsByType };
