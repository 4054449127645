import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { isEmpty, map, debounce, filter } from 'lodash';

import { selectProject, toggleProjectInspections } from '../actions/action-creators';
import { loadProjects, deleteProject, toggleDeleteProjectModal, deleteInspection, archiveProjectModal, archiveProject, setProjectToEdit } from '../actions/project-actions';
import { uploadAsset, setUploadItems, setSingleUploadItem } from '../../upload/actions/upload-actions';

import UploadProjectAssetsModal from './upload-assets-modal';
import Sidebar from './sidebar';
import GoogleMap from './map';
import Modal from '../../../common/modal/components/modal';
import PasswordConfirmationModal from '../../../common/modal/components/password-confirmation-modal';

import Helpers from '../../../common/helpers';

import uploadConstants from '../../upload/constants/constants';
import routesConstants from '../../../common/routes-constants';
import { inspectionAssetSupportedMimeTypes } from '../constants/upload-constants';
import { projectActions, inspectionActions, settingsMenu } from '../constants/settings-menu';
import { projectTypes, projectSortData, sortingOptions, sortingOrder } from '../constants/project-fields';
import { params } from '../../profile/constants/profile-constants';

import '../styles/project.scss';

class Project extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      sortedProjectsObject: [
        {
          ...projectTypes.active,
          settingsMenu: settingsMenu,
          inspectionMenu: [],
        },
        {
          ...projectTypes.archived,
          settingsMenu: settingsMenu,
          inspectionMenu: [],
        },
      ],
      modalData: {
        isOpen: false,
        closeAction: this.closeModal.bind(this),
      },
      startDate: new Date(),
      searchFilter: {
        [sortingOptions.sortByColumn]: projectSortData[0].value,
        [sortingOptions.order]: sortingOrder.asc,
      },
    };
    this.searchChangeDebounced = debounce(this.searchProjects, 500);
  }

  componentDidMount() {
    const { loadProjects } = this.props;
    const { searchFilter } = this.state;
    loadProjects(searchFilter, null);
  }

  componentWillUnmount() {
    this.searchChangeDebounced.cancel();
  }

  getProjectsInterval = () => {
    const { projects, loadProjects } = this.props;
    const { searchFilter } = this.state;

    if (!isEmpty(projects)) {
      loadProjects(searchFilter, projects);
    }
  };

  sortProjects = projects => {
    const { sortedProjectsObject } = this.state;

    const res = map(sortedProjectsObject, item => {
      return { ...item, items: filter(projects, item.customFilter) };
    });

    return res;
  };

  searchProjects = data => {
    const { searchFilter } = this.state;
    const { loadProjects } = this.props;
    const newProps = { ...searchFilter, [data.key]: data.value };

    this.setState({ searchFilter: newProps });

    loadProjects(newProps, null);
  };

  handleSearchProjects = data => {
    this.searchChangeDebounced(data);
  };

  handleSortingChanged = data => {
    const { searchFilter } = this.state;
    const { loadProjects } = this.props;
    const newProps = { ...searchFilter, ...data };

    this.setState({ searchFilter: newProps });

    loadProjects(newProps, null);
  };

  handleDate = date => {
    this.setState({
      startDate: date,
    });
  };

  getInspectionClickAction = (action, project, inspection) => {
    if (action === inspectionActions.deleteInspection) {
      this.openDeleteInspectionModal(inspection);
    }
    if (action === inspectionActions.uploadInspectionImages) {
      this.openInspectionAssetModal(project.ID, inspection.ID);
    }
  };

  getProjectClickAction = (action, project) => {
    const { setProjectToEdit } = this.props;

    if (action === projectActions.archiveProject || action === projectActions.unarchiveProject) {
      return this.openArchiveProjectModal(project);
    }
    if (action === projectActions.editProject) {
      setProjectToEdit(project);
    }
    if (action === projectActions.deleteProject) {
      this.openDeleteProjectModal(project);
    }
    if (action === projectActions.openIntegrations) {
      this.openProjectIntegrations(project);
    }
  };

  openArchiveProjectModal = project => {
    const { t } = this.context;
    const { archiveProjectModal, archiveProject } = this.props;

    const isActive = project.Active;

    const modalData = {
      isOpen: true,
      content: t('ARCHIVE_PROJECT_MODAL.DESC', {
        isActiveText: t(isActive ? 'ARCHIVE_PROJECT_MODAL.CONTENT_DEACTIVATE_PROJECT' : 'ARCHIVE_PROJECT_MODAL.CONTENT_ACTIVATE_PROJECT'),
        projectName: project.Name,
      }),
      type: 'yes-no',
      title: t('ARCHIVE_PROJECT_MODAL.ACTIVATE_PROJECT', {
        isActiveText: t(isActive ? 'ARCHIVE_PROJECT_MODAL.TITLE_DEACTIVATE_PROJECT' : 'ARCHIVE_PROJECT_MODAL.TITLE_ACTIVATE_PROJECT'),
        projectName: project.Name,
      }),
      confirmAction: () => archiveProject(project),
      closeAction: () => archiveProjectModal({ isOpen: false }),
    };
    archiveProjectModal(modalData);
  };

  openDeleteProjectModal = project => {
    const { t } = this.context;
    const { toggleDeleteProjectModal, deleteProject } = this.props;
    const modalData = {
      isOpen: true,
      content: t('DELETE_PROJECT_MODAL.DESC', { projectName: project.Name }),
      title: t('DELETE_PROJECT_MODAL.DELETE_PROJECT'),
      confirmAction: (password, setFormError) => deleteProject({ ProjectID: project.ID, Password: password }, setFormError),
      closeAction: () => toggleDeleteProjectModal({ isOpen: false }),
      customClassName: 'delete-project-modal modal-small',
    };
    toggleDeleteProjectModal(modalData);
  };

  openProjectIntegrations = project => {
    Helpers.goTo(routesConstants.routes.protectedRoutes.integrations.fullPath, [{ project_id: project.ID }]);
  };

  openDeleteInspectionModal = inspection => {
    const { t } = this.context;
    const { toggleDeleteProjectModal, deleteInspection } = this.props;
    const modalData = {
      isOpen: true,
      content: t('DELETE_INSPECTION_MODAL.DESC', { inspectionName: inspection.Name }),
      title: t('DELETE_INSPECTION_MODAL.DELETE_PROJECT'),
      confirmAction: (password, setFormError) => deleteInspection({ InspectionID: inspection.ID, Password: password }, setFormError, t('DELETE_INSPECTION_MODAL.ARE_YOU_SURE')),
      closeAction: () => toggleDeleteProjectModal({ isOpen: false }),
      customClassName: 'delete-project-modal modal-small',
    };
    toggleDeleteProjectModal(modalData);
  };

  openInspectionAssetModal = (projectId, inspectionId) => {
    const { t } = this.context;
    this.setState(prevState => ({
      modalData: {
        ...prevState.modalData,
        title: t('INSPECTION_ASSETS_UPLOAD_MODAL.TITLE'),
        CustomContent: () => (
          <UploadProjectAssetsModal
            supportedMimeTypes={inspectionAssetSupportedMimeTypes}
            onDrop={file => this.onDropAsset(file, uploadConstants.uploadType.inspectionImages, projectId, inspectionId)}
            className="project-dropzone"
            label={t('INSPECTION_ASSETS_UPLOAD_MODAL.TITLE')}
            labelWhite={t('INSPECTION_ASSETS_UPLOAD_MODAL.TITLE')}
            labelGreen={t('INSPECTION_UPLOAD_MODAL.BROWSE')}
          />
        ),
        isOpen: true,
        type: 'ok',
        confirmAction: this.closeModal,
        closeAction: this.closeModal,
      },
    }));
  };

  closeModal = () => {
    const { setUploadItems } = this.props;
    setUploadItems(null);
    this.setState({ modalData: { isOpen: false } });
  };

  onDropAsset = (files, uploadType, ProjectID, InspectionID) => {
    const { uploadAsset, setUploadItems, setSingleUploadItem } = this.props;

    const tmp_files = files.map((file, index) => {
      const tmp_file = {
        type: Helpers.getFileType(file.type),
        mimeType: file.type,
        title: '',
        description: '',
        real_name: Helpers.formatFileName(file.name),
      };
      file.timeStamp = Date.now();
      file.real_name = tmp_file.real_name;
      file.uploadType = uploadType;

      uploadAsset(file, { ProjectID, InspectionID }, setSingleUploadItem, index);
      return { ...tmp_file, ...file };
    });
    setUploadItems(tmp_files);
  };

  handleMarkerClick = project => {
    const { toggleDropdown } = this.props;

    toggleDropdown(project.ID);
    setTimeout(() => {
      Helpers.scrollIntoView('project-list', `${project.Name}_${project.ID}`, 0);
    }, 0);
  };

  handleViewInspection = (project_id, inspection_id) => {
    const { user } = this.props;

    const route = user[params.fullScreenEnabled] ? routesConstants.routes.protectedRoutes.fullScreen.fullPath : routesConstants.routes.protectedRoutes.inspections.fullPath;

    Helpers.goTo(route, [{ project_id }, { inspection_id }]);
  };

  render() {
    const { modalData, searchFilter } = this.state;
    const { projects, selectProject, toggleDropdown, uploadInProgress, archiveProjectModalData, deleteProjectModalData, selectedProject, mapStyles } = this.props;
    const sortedProjects = this.sortProjects(projects);

    return (
      <div className="project-container">
        <Sidebar
          projects={sortedProjects}
          selectProject={selectProject}
          toggleDropdown={toggleDropdown}
          handleFilterChanged={this.handleSearchProjects}
          handleSortingChanged={this.handleSortingChanged}
          openInspectionAssetModal={this.openInspectionAssetModal}
          getProjectClickAction={this.getProjectClickAction}
          getInspectionClickAction={this.getInspectionClickAction}
          handleViewInspection={this.handleViewInspection}
          sortingObj={searchFilter}
        />
        <GoogleMap selectedProject={selectedProject} data={projects} selectProject={selectProject} handleMarkerClick={this.handleMarkerClick} toggleDropdown={toggleDropdown} styles={mapStyles} />

        <Modal {...modalData} modalDisabled={uploadInProgress} />
        <Modal {...archiveProjectModalData} />
        <PasswordConfirmationModal {...deleteProjectModalData} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  projects: state.projectReducer.projects,
  uploadInProgress: state.uploadReducer.uploadInProgress,
  archiveProjectModalData: state.projectReducer.archiveProjectModalData,
  deleteProjectModalData: state.projectReducer.deleteProjectModalData,
  selectedProject: state.projectReducer.hoveredProject,
  mapStyles: state.themeReducer.map,
  themeColors: state.themeReducer.severityColors,
  user: state.userReducer,
});
const mapDispatchToProps = dispatch => ({
  selectProject: id => dispatch(selectProject(id)),
  toggleDropdown: id => dispatch(toggleProjectInspections(id)),
  loadProjects: (data, persistState) => dispatch(loadProjects(data, persistState)),
  uploadAsset: (file, IDs, callbackFunction, index) => dispatch(uploadAsset(file, IDs, callbackFunction, index)),
  setUploadItems: assets => dispatch(setUploadItems(assets)),
  setSingleUploadItem: (progress, index) => dispatch(setSingleUploadItem(progress, index)),
  deleteProject: (data, setFormError) => dispatch(deleteProject(data, setFormError)),
  toggleDeleteProjectModal: params => dispatch(toggleDeleteProjectModal(params)),
  deleteInspection: (data, setFormError, popupMessage) => dispatch(deleteInspection(data, setFormError, popupMessage)),
  archiveProjectModal: params => dispatch(archiveProjectModal(params)),
  archiveProject: project => dispatch(archiveProject(project)),
  setProjectToEdit: project => dispatch(setProjectToEdit(project)),
});

Project.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Project);
