import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
//styles
import '../../styles/manage-groups-modal.scss';
//components
import TableTemplate from '../../../../../../common/double-table/components/table-template';
import Modal from '../../../../../../common/modal/components/modal';
import ActionModal from '../../../../../document-management/components/modals/action-modal/action-modal';
import GroupForm from './group-form/group-form';
//constants
import { FORMS } from '../../../../../../common/constants';
import {
  defaultFilters,
  deleteWithModalFields,
  fields,
  getSingleRnGTableConfig,
  getTableFormat,
  groupActionTypes,
  groupDeleteActionTypes,
  sortDirections,
  viewOptions,
} from '../../constants/constants';
//package imports
import { debounce, findIndex, sortBy, uniqBy } from 'lodash';
import { connect } from 'react-redux';
import { isDirty } from 'redux-form';
//prop types
import PropTypes from 'prop-types';
// actions
import { setGenericNotification } from '../../../../../../common/notification/actions/action-creators';
import { createGroup, deleteGroup, editGroup, fetchGroups } from '../../actions/measurement-group-actions';
//helpers
import { withRouter } from 'react-router';
import ConfirmWithModal from '../../../../../../common/confirm-with-modal/confirm-with-modal';
import EmptyState from '../../../../../../common/empty-state-v2/components/empty-state';
import Helpers from '../../../../../../common/helpers';
import { PERMISSION_TYPES, PERMISSIONS } from '../../../../../../common/permissions-constants';
import ReducerHelpers from '../../../../../../common/reducer-helpers';
import useConfirmOnInspectionExit from '../../../../../../hooks/use-confirm-on-inspection-exit';
import { setMeasurementGroups } from '../../actions/action-creators';

const ManageGroupsModal = (props, { t }) => {
  const {
    projectID,
    fetchGroups,
    createGroup,
    editGroup,
    isFormDirty,
    deleteGroup,
    defaultReadingGroupID,
    setGenericNotification,
    inspectionId,
    measurementGroups,
    setMeasurementGroups,
    user,
    router,
  } = props;

  const groupCreate = useMemo(() => Helpers.hasAccess({ user, visibleFor: PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].groupCreate.name }), [user]);

  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({ ...defaultFilters, [fields.projectID]: projectID });
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [modalData, setModalData] = useState({ isOpen: false });
  const [confirmModalData, setConfirmModalData] = useState({ isOpen: false });
  const [formHasUnsavedChanges, setFormHasUnsavedChanges] = useState(false);
  const formIsDirty = useRef(false);

  const fetchData = useCallback(
    (filters, loadMore = false) => {
      const setNewData = newData => {
        // filter out the groups by id, so there arent any duplicates
        const dataToSet = loadMore ? uniqBy([...data, ...newData], fields.id) : newData;
        setData(dataToSet);
      };
      const setDataLoaded = isLoading => {
        setIsDataLoading(isLoading);
        if (loadMore && !isLoading) {
          Helpers.scrollIntoView('groups-table', `row-${filters[fields.lastSeen] - 1}`, -250);
        }
      };
      fetchGroups(filters, setNewData, setFilters, null, setDataLoaded);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchTextChanged = useCallback(
    debounce(searchText => fetchData({ ...filters, [fields.lastSeen]: 0, [fields.searchText]: searchText }), 300),
    [fetchData]
  );

  const handleSearchInputChange = e => {
    const searchText = e.target.value;
    setSearchText(searchText);
    searchTextChanged(searchText);
  };

  const handleLoadMoreClick = () => fetchData(filters, true);

  const handleSortClick = sortByColumn => {
    fetchData({
      ...filters,
      [fields.lastSeen]: 0,
      [fields.sortDirection]: filters[fields.sortDirection] === sortDirections.ASC ? sortDirections.DESC : sortDirections.ASC,
      [fields.sortByColumn]: sortByColumn,
    });
  };

  const closeModal = () => setModalData({ isOpen: false, hasUnsavedChanges: true });
  const closeConfirmModal = () => setConfirmModalData({ isOpen: false });

  const closeModalAndConfirmModal = () => {
    closeModal();
    closeConfirmModal();
    clearUnsavedFormChanges();
  };

  const handleClickOnNewGroup = () => {
    setModalData({
      isOpen: true,
      CustomContent: dynamicProps => <GroupForm {...dynamicProps} />,
      closeAction: handleEditCloseModalAction,
      type: '',
      title: t('READINGS_AND_GAUGES.NEW_GROUP'),
      onSubmit: handleCreateNewGroupSubmit,
      isEdit: false,
    });
  };

  const handleCreateNewGroupSubmit = values => {
    createGroup(values[fields.name], projectID, inspectionId, newGroup => {
      const sortedData = sortBy([...data, newGroup], item => (item[fields.name] === 'NO_GROUP' ? '' : item[fields.name].toLowerCase()));
      setData(sortedData);
      setMeasurementGroups(sortedData);
      setFilters({ ...filters, [fields.totalNumber]: filters[fields.totalNumber] + 1 });
      closeModal();
    });
    clearUnsavedFormChanges();
  };

  const handleEditGroupSubmit = values => {
    editGroup(values[fields.name], values[fields.id], projectID, () => {
      const indexOfUpdatedGroup = findIndex(data, { [fields.id]: values[fields.id] });
      const newDataObj = Object.assign([], data);
      if (indexOfUpdatedGroup < -1) return;
      newDataObj[indexOfUpdatedGroup] = { ...newDataObj[indexOfUpdatedGroup], [fields.name]: values[fields.name] };
      setData(newDataObj);
      setMeasurementGroups(newDataObj);
      closeModal();
    });
    clearUnsavedFormChanges();
  };

  const handleEditCloseModalAction = (isFormDirty, title, firstParagraph) => {
    if (isFormDirty) {
      setConfirmModalData({
        isOpen: true,
        CustomContent: dynamicProps => <ActionModal {...dynamicProps} />,
        closeAction: closeConfirmModal,
        type: '',
        title: t(title),
        confirmButtonText: 'LEAVE',
        firstParagraph: firstParagraph,
        secondParagraph: 'READINGS_AND_GAUGES.EDIT_GROUP_UNSAVED_CHANGES_PARAGRAPH_2',
        customCloseAction: closeConfirmModal,
        customConfirmAction: closeModalAndConfirmModal,
      });
    } else {
      closeModal();
    }
  };

  const handleGroupActionClicked = (e, action, group) => {
    e.stopPropagation();
    if (action === groupActionTypes.edit) {
      setModalData({
        isOpen: true,
        CustomContent: dynamicProps => <GroupForm {...dynamicProps} />,
        closeAction: handleEditCloseModalAction,
        type: '',
        title: t('READINGS_AND_GAUGES.EDIT_GROUP'),
        onSubmit: handleEditGroupSubmit,
        initialValues: group,
        isEdit: true,
      });
    }
    if (action === groupActionTypes.delete) {
      setModalData({
        isOpen: true,
        customClassName: 'manage-groups-action__delete',
        CustomContent: dynamicProps => <ActionModal {...dynamicProps} />,
        closeAction: closeModal,
        type: '',
        title: t('READINGS_AND_GAUGES.DELETE_GROUP_TITLE'),
        closeButtonVariant: 'danger-outline',
        closeButtonText: 'READINGS_AND_GAUGES.DELETE_GROUP_AND_MOVE',
        confirmButtonText: 'READINGS_AND_GAUGES.DELETE_GROUP',
        firstParagraph: 'READINGS_AND_GAUGES.DELETE_GROUP_FIRST_PARAGAPH',
        firstParagraphProps: { groupName: group[fields.name] },
        secondParagraph: 'READINGS_AND_GAUGES.DELETE_GROUP_SECOND_PARAGAPH',
        customCloseAction: () => handleDeleteAndMoveClick(group, groupDeleteActionTypes.deleteAndMove),
        customConfirmAction: () => handleDeleteAllClick(group, groupDeleteActionTypes.deleteAll),
      });
    }
  };

  const handleDeleteAndMoveClick = (group, type) => {
    setConfirmModalData({
      customClassName: 'delete-and-move-modal',
      isOpen: true,
      type: '',
      CustomContent: dynamicProps => <ActionModal {...dynamicProps} />,
      closeAction: closeConfirmModal,
      title: t('READINGS_AND_GAUGES.CONFIRM_DELETE'),
      confirmButtonText: 'CONFIRM',
      closeButtonText: t('CANCEL'),
      firstParagraph: 'READINGS_AND_GAUGES.CONFIRM_DELETE_WARNING',
      customCloseAction: closeConfirmModal,
      customConfirmAction: () => handleDeleteGroup(group, type),
    });
  };

  const handleDeleteAllClick = (group, type) => {
    setConfirmModalData({
      isOpen: true,
      type: '',
      CustomContent: dynamicProps => <ConfirmWithModal {...dynamicProps} />,
      closeAction: closeConfirmModal,
      title: t('READINGS_AND_GAUGES.CONFIRM_WITH_PASSWORD'),
      customCloseAction: closeConfirmModal,
      onSubmit: values => handleDeleteGroup(group, type, values[deleteWithModalFields.field.name]),
      text: 'READINGS_AND_GAUGES.CONFIRM_WITH_PASSWORD_MODAL_PARAGRAPH',
      textProps: { groupName: group[fields.name] },
      placeholder: deleteWithModalFields.field.placeholder,
      label: deleteWithModalFields.field.label,
      id: deleteWithModalFields.field.id,
      name: deleteWithModalFields.field.name,
      customClassName: 'modal-medium confirmation-modal',
    });
  };

  const handleDeleteGroup = (group, type, confirmationCheck) => {
    const movePointToGroupID = type === groupDeleteActionTypes.deleteAndMove ? defaultReadingGroupID : null;
    const confirmText = type === groupDeleteActionTypes.deleteAll ? confirmationCheck : null;

    const handleSuccessfulDelete = () => {
      setData(ReducerHelpers.removeItemByProp(data, group, fields.id));
      setFilters({ ...filters, [fields.totalNumber]: filters[fields.totalNumber] - 1 });
      closeModal();
      closeConfirmModal();
      setGenericNotification({
        isDisplayed: true,
        type: 'error',
        icon: 'trash',
        text: t('READINGS_AND_GAUGES.GROUP_DELETED', { groupName: group[fields.name] }),
      });
    };

    setMeasurementGroups(ReducerHelpers.removeItemByProp(measurementGroups, group, fields.id));
    deleteGroup(group[fields.id], movePointToGroupID, confirmText, type === groupDeleteActionTypes.deleteAll, handleSuccessfulDelete);
  };

  const emptyStateProps = {
    emptyStateText: t('READINGS_AND_GAUGES.GROUP_EMPTY_STATE.TEXT'),
    transparent: true,
  };

  const clearUnsavedFormChanges = () => {
    setFormHasUnsavedChanges(false);
    formIsDirty.current = false;
  };

  useConfirmOnInspectionExit({
    router,
    route: router.currentRoute,
    isDirty: formHasUnsavedChanges,
    clearUnsavedChangesDirty: () => clearUnsavedFormChanges(),
  });

  useEffect(() => {
    setFormHasUnsavedChanges(isFormDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormDirty]);

  useEffect(() => {
    fetchData(filters);
    //eslint-disable-next-line
  }, []);

  return (
    <div className="manage-groups-modal">
      <TableTemplate
        data={data}
        handleFormatCells={getTableFormat[viewOptions.group]}
        tableConfig={getSingleRnGTableConfig[viewOptions.group](true, true)}
        filters={filters}
        sortByColumn={fields.sortByColumn}
        sortDirection={fields.sortDirection}
        searchPlaceholder={t('SEARCH')}
        loadMoreOnClick={handleLoadMoreClick}
        onSortClick={handleSortClick}
        searchInputOnChange={handleSearchInputChange}
        isLoading={isDataLoading}
        searchTerm={searchText}
        hasNext={filters[fields.hasNext]}
        TotalItems={filters[fields.totalNumber]}
        customTableClass="groups-table"
        addButtonText={t('READINGS_AND_GAUGES.NEW_GROUP')}
        addButtonAction={handleClickOnNewGroup}
        addButtonDisabled={!groupCreate}
        onDropdownClick={handleGroupActionClicked}
        onRowClick={() => null}
        emptyStateComponent={() => <EmptyState {...emptyStateProps} />}
        user={user}
      />
      {/* Close Action is being set this way due to need to update isFormDirty prop for the case
      when user closes the editing group modal with unsaved changes */}
      <Modal
        {...modalData}
        closeAction={() =>
          modalData?.closeAction(
            isFormDirty,
            modalData?.isEdit === false ? 'READINGS_AND_GAUGES.LEAVE_ADD_GROUP' : 'READINGS_AND_GAUGES.LEAVE_EDIT_GROUP',
            modalData?.isEdit === false ? 'READINGS_AND_GAUGES.ADD_GROUP_UNSAVED_CHANGES_PARAGRAPH_1' : 'READINGS_AND_GAUGES.EDIT_GROUP_UNSAVED_CHANGES_PARAGRAPH_1'
          )
        }
      />
      <Modal {...confirmModalData} />
    </div>
  );
};

ManageGroupsModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isFormDirty: isDirty(FORMS.groupForm)(state),
  defaultReadingGroupID: state.projectDetailsReducer.DefaultReadingGroupID,
  measurementGroups: state.measurementGroupReducer.measurementGroups,
});

const mapDispatchToProps = dispatch => ({
  fetchGroups: (filters, callback, filtersCallback, errorCallback, loadingCallback) => dispatch(fetchGroups(filters, callback, filtersCallback, errorCallback, loadingCallback)),
  createGroup: (name, projectID, inspectionId, callback) => dispatch(createGroup(name, projectID, inspectionId, callback)),
  editGroup: (name, ID, projectID, callback) => dispatch(editGroup(name, ID, projectID, callback)),
  deleteGroup: (id, movePointsToGroupID, confirmationCheck, deleteAll, callback) => dispatch(deleteGroup(id, movePointsToGroupID, confirmationCheck, deleteAll, callback)),
  setGenericNotification: data => dispatch(setGenericNotification(data)),
  setMeasurementGroups: data => dispatch(setMeasurementGroups(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageGroupsModal));
