import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Button from '../../../../../common/form/components/button';
import CustomInput from '../../../../../common/form/components/input';
import TextArea from '../../../../../common/form/components/text-area';
import CheckBox from '../../../../../common/form/components/checkbox';

import { FORMS } from '../../../../../common/constants';
import { descriptionMaxChars, formConstants, steps } from '../constants/constants';
import { validate } from '../../../validators/isolation-template-validators';

class IsolationTemplateForm extends Component {
  getSubmitButtonText = () => {
    const { isEditMode, lastStep, activeStep } = this.props;
    if (activeStep === lastStep) {
      return 'ISOLATION_TEMPLATE_FORM.SAVE';
    } else if (activeStep === steps.firstStep && !isEditMode) {
      return 'ISOLATION_TEMPLATE_FORM.CREATE';
    } else {
      return 'ISOLATION_TEMPLATE_FORM.NEXT';
    }
  };
  render() {
    const { t } = this.context;
    const { handleSubmit, activeStep, lastStep, stepAction, invalid, isEditMode, viewOnly } = this.props;

    return (
      <form className="contractor-form" onSubmit={handleSubmit} noValidate>
        {isEditMode && <Field id={formConstants.fields.id} name={formConstants.fields.id} component={CustomInput} type="text" isHidden />}

        {activeStep === steps.firstStep && (
          <>
            <Field
              id={formConstants.fields.name}
              name={formConstants.fields.name}
              component={CustomInput}
              disabled={viewOnly}
              placeholder={'ISOLATION_TEMPLATE_FORM.NAME'}
              label={'ISOLATION_TEMPLATE_FORM.NAME'}
              type="text"
              additionalClassName="text-transform-none"
              labelClass="text-transform-none"
            />

            <div className="form-fields">
              <Field
                id={formConstants.fields.description}
                name={formConstants.fields.description}
                component={TextArea}
                placeholder={'ISOLATION_TEMPLATE_FORM.DESCRIPTION_PLACEHOLDER'}
                label={'ISOLATION_TEMPLATE_FORM.DESCRIPTION'}
                labelClass="f-secondary-dark"
                enableResize={true}
                hideBorder={true}
                disabled={viewOnly}
                maxChars={descriptionMaxChars}
              />
              <Field
                id={formConstants.fields.signatureRequired}
                name={formConstants.fields.signatureRequired}
                labelClass="f-secondary-dark"
                component={CheckBox}
                disabled={viewOnly}
                checkboxText={'ISOLATION_TEMPLATE_FORM.SIGNATURE_REQUIRED'}
              />
            </div>
          </>
        )}
        {activeStep === steps.secondStep && <></>}

        <div className="buttons">
          <Button
            type="button"
            variant="gray-outline"
            width="sm"
            text={activeStep === steps.firstStep ? t('ISOLATION_TEMPLATE_FORM.CANCEL') : t('ISOLATION_TEMPLATE_FORM.BACK')}
            onClick={() => stepAction(false)}
          />
          <Button width="sm" disabled={activeStep === lastStep && invalid} text={t(this.getSubmitButtonText())} />
        </div>
      </form>
    );
  }
}

IsolationTemplateForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

IsolationTemplateForm = reduxForm({
  form: FORMS.isolationTemplateForm,
  enableReinitialize: true,
  destroyOnUnmount: true,
  validate: validate,
})(IsolationTemplateForm);

export default IsolationTemplateForm;
