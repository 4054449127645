export default {
  GET_ZONES: '/inspection/atmospheric_zone/list',
  GET_ZONE_DETAILS: '/inspection/atmospheric_zone/details',
  CREATE_ZONE: '/inspection/atmospheric_zone/create',
  DELETE_ZONE: '/inspection/atmospheric_zone/delete',
  UPDATE_ZONE: '/inspection/atmospheric_zone/update',
  GET_ZONE_AREAS: '/inspection/zones/list',
  CREATE_ZONE_AREA: '/inspection/zones/create',
  UPDATE_ZONE_AREA: '/inspection/zones/update',
  DELETE_ZONE_AREA: '/inspection/zones/delete',
  GET_EXPLOSIVE_ZONE_CLUSTERED: '/inspection/atmospheric_zone/list',
  GET_EXPLOSIVE_ZONE_HISTORY: '/inspection/atmospheric_zone/history/get',
};
