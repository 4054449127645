import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import '../styles/basic-input.scss';

export default class BasicInput extends Component {
  handleChange = e => {
    const {
      input: { onChange },
      handleChange,
    } = this.props;
    onChange(e);
    if (handleChange) handleChange(e.target.value);
  };

  wheelListener = event => {
    if (document.activeElement.type === 'number') {
      document.activeElement.blur();
    }
  };

  componentDidMount = () => {
    document.addEventListener('wheel', this.wheelListener);
  };

  componentWillUnmount = () => {
    document.removeEventListener('wheel', this.wheelListener);
  };

  render() {
    const {
      input: { onChange, ...restInputProps },
      type,
      placeholder,
      meta,
      className,
      id,
      label,
      labelClass,
      disabled,
      hideBorder,
      isHidden,
      inputProps,
      renderPreComponent,
      showError,
      autoFocus,
      labelActions,
      onClick = () => null,
      clickable,
    } = this.props;
    const { t } = this.context;
    let valueProps = {};
    const hasMetaError = meta && meta.error;
    const hasMetaWarning = meta && meta.warning;
    const invalidClass = `invalid--${hasMetaError ? 'error' : 'warning'}`;

    if (!restInputProps?.value) {
      valueProps = {
        value: '',
      };
    }

    return (
      <div className={`inspection-input ${isHidden ? 'hidden-field' : ''} ${clickable ? 'clickable' : ''}`}>
        {label && (
          <div className="inline-wrapper">
            <label className={`inspection-input__label ${labelClass || 'f-secondary-dark'}`} htmlFor={id}>
              {label}
            </label>
            {!isEmpty(labelActions) &&
              labelActions.map((el, index) => {
                return <el.IconComponent key={index} onClick={el.onClick} className={`${el.iconClass || ''} label-container__icon ${el.type || ''} label-container__icon--active svg-gray-g`} />;
              })}
          </div>
        )}
        <div className={`inspection-input__input-wrapper${hideBorder ? '' : ' border'} ${hasMetaError || hasMetaWarning ? invalidClass : ''}`} onClick={onClick}>
          {renderPreComponent && renderPreComponent()}
          <input
            id={id}
            onChange={this.handleChange}
            {...inputProps}
            {...restInputProps}
            {...valueProps}
            readOnly={disabled}
            disabled={disabled}
            type={type || 'text'}
            placeholder={placeholder || ''}
            className={(meta && meta.error && meta.touched ? 'invalid ' : '') + `${className || 'input f-primary'}`}
            autoComplete="off"
            autoFocus={autoFocus}
          />
        </div>
        {showError && (
          <div className="field-message">
            <p className={`field-message__error ${meta && meta.error ? 'show' : ''}`}>{meta && meta.error && t(meta.error.string, meta.error.params)}</p>
            <p className={`field-message__warning ${meta && meta.warning ? 'show' : ''}`}>{meta && meta.warning && t(meta.warning.string, meta.warning.params)}</p>
          </div>
        )}
      </div>
    );
  }
}

BasicInput.contextTypes = {
  t: PropTypes.func.isRequired,
};

BasicInput.defaultProps = {
  showError: true,
};
