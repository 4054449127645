import React from 'react';

import '../../styles/team-users-modal.scss';

const TeamUsersModal = ({ users }) => {
  return (
    <div className="team-users">
      {users.map((user, index) => {
        return (
          <div className="item" key={index}>
            <p className="f-primary">{`${index + 1}. ${user.Name} `}</p>
          </div>
        );
      })}
    </div>
  );
};

TeamUsersModal.defaultProps = {
  users: [],
};

export default TeamUsersModal;
