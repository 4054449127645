import loginConstants from '../../../app/login/constants/login-constants';

export const validate = values => {
  const errors = {};

  if (!values[loginConstants.verificationFields.code]) {
    errors[loginConstants.verificationFields.code] = 'LOGIN.VALIDATION_CODE_REQUIRED';
  }
  if (values[loginConstants.verificationFields.code] && values[loginConstants.verificationFields.code].length !== 6) {
    errors[loginConstants.verificationFields.code] = 'LOGIN.VALIDATION_CODE_LENGTH';
  }
  if (values[loginConstants.verificationFields.code] && isNaN(values[loginConstants.verificationFields.code])) {
    errors[loginConstants.verificationFields.code] = 'LOGIN.VALIDATION_CODE_NUMBERS_ONLY';
  }
  return errors;
};
