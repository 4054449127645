import { debounce, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Helpers from '../../../../common/helpers';
import { notificationModalCustom } from '../../../../common/modal/actions/modal-actions';
import RenderIf from '../../../../common/render-if/components/render-if';
import WarningIndicator from '../../../../common/warning-indicator/components/warning-indicator';
import { setSingleUploadItem, setUploadItems, uploadAsset } from '../../../upload/actions/upload-actions';
import { deleteWOCompletionFile, getWorkOrderCompletionDetails, getWorkOrderCompletionDetailsFiles, singleItemUploadDone, updateWorkOrderCompletionDetails } from '../../actions/work-order-actions';
import { statuses } from '../../constants/work-order-constants';
import { formFields } from './constants/work-order-completion-constants';
import { validateUpdate } from './validators/work-order-completed-form-validator';
import WorkOrderCompletionForm from './work-order-completion/work-order-completion-form';

// TODO: rewrite to func component, use constants for fields
class WorkOrderCompletionSections extends Component {
  constructor(props) {
    super(props);

    this.state = {
      completionData: {},
    };
    this.FormSubmitDebounce = debounce(this.submitForm, 250);
  }
  componentDidMount() {
    const { workOrderDetails } = this.props;
    const isCompleted = workOrderDetails?.Status === statuses.completed.value;
    if (isCompleted) this.fetchInitialData();
  }
  componentDidUpdate(prevProps) {
    const { workOrderDetails } = this.props;
    if (workOrderDetails.ID !== prevProps.workOrderDetails.ID) {
      this.fetchInitialData();
    }
  }
  fetchInitialData = () => {
    const { getWorkOrderCompletionDetails, getWorkOrderCompletionDetailsFiles, workOrderDetails } = this.props;
    getWorkOrderCompletionDetails(workOrderDetails.ID, this.setCompletionDetails);
    getWorkOrderCompletionDetailsFiles(workOrderDetails.ID);
  };
  setCompletionDetails = completionData => {
    completionData.ActualEndDate = Helpers.getDateFromUnix(completionData?.ActualEndDate);
    completionData.SignedAt = Helpers.getDateFromUnix(completionData?.SignedAt);
    this.setState({ completionData });
  };

  handleOnChange = (values, _, c) => {
    if (!c.pristine) {
      const { workOrderDetails } = this.props;
      const err = validateUpdate(values, { workOrderDetails });
      if (isEmpty(err)) this.FormSubmitDebounce(values);
    }
  };
  submitForm = values => {
    const { workOrderDetails, updateWorkOrderCompletionDetails } = this.props;
    //the reason for this const is if the UnixDate is set, 1970 is shown as the year
    const actualEndTimeValue = values[formFields.actualEndTime.name];
    values[formFields.actualEndTime.name] = values[formFields.actualEndTime.name] ? Helpers.getUnixDate(new Date(values[formFields.actualEndTime.name]).getTime()) : null;
    updateWorkOrderCompletionDetails(workOrderDetails.ID, values);
    values[formFields.actualEndTime.name] = actualEndTimeValue;
    this.setState({ completionData: values });
  };

  render() {
    const { t } = this.context;
    const { workOrderDetails, formDisabled, workOrderCompletionFiles, projectDMSCategories, openFile, openEditFilesModal, unlinkCompletionFile } = this.props;
    const isCompleted = workOrderDetails?.Status === statuses.completed.value;
    const { completionData } = this.state;

    return (
      <div className="work-order-right-side-content">
        <RenderIf if={isCompleted}>
          <WorkOrderCompletionForm
            initialValues={Object.assign({}, completionData)}
            onChange={this.handleOnChange}
            disabled={formDisabled}
            workOrderDetails={workOrderDetails}
            openFile={openFile}
            unlinkCompletionFile={unlinkCompletionFile}
            openEditFilesModal={openEditFilesModal}
            workOrderCompletionFiles={workOrderCompletionFiles}
            projectDMSCategories={projectDMSCategories}
          />
        </RenderIf>
        <RenderIf if={!isCompleted}>
          <WarningIndicator title={t('WORK_ORDER.NOT_COMPLETED_YET')} />
        </RenderIf>
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => ({
  getWorkOrderCompletionDetails: (workOrderId, callback) => dispatch(getWorkOrderCompletionDetails(workOrderId, callback)),
  getWorkOrderCompletionDetailsFiles: workOrderId => dispatch(getWorkOrderCompletionDetailsFiles(workOrderId)),
  updateWorkOrderCompletionDetails: (workOrderID, values) => dispatch(updateWorkOrderCompletionDetails(workOrderID, values)),
  uploadAsset: (file, IDs, callbackFunction, index, customDoneFunc) => dispatch(uploadAsset(file, IDs, callbackFunction, index, customDoneFunc)),
  setSingleUploadItem: (progress, index) => dispatch(setSingleUploadItem(progress, index)),
  setUploadItems: assets => dispatch(setUploadItems(assets)),
  singleItemUploadDone: (url, callback) => dispatch(singleItemUploadDone(url, callback)),
  deleteWOCompletionFile: (workOrderId, fileId, callback) => dispatch(deleteWOCompletionFile(workOrderId, fileId, callback)),
  notificationModalCustom: (errorMessage, title) => dispatch(notificationModalCustom(true, errorMessage, title)),
});
WorkOrderCompletionSections.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default withRouter(connect(null, mapDispatchToProps)(WorkOrderCompletionSections));
