import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field, getFormSyncErrors, reduxForm } from 'redux-form';
import { FORMS } from '../../../../../common/constants';
import Button from '../../../../../common/form/components/button';
import CustomInput from '../../../../../common/form/components/input';
import { createFolderModalFormFields } from '../../../constants/constants';
import { validateCreateNewFolder as validate } from '../../../validators/form-validators';

const CreateFolderForm = ({ handleSubmit, customCloseAction, formErrors }, { t }) => {
  const { name } = createFolderModalFormFields;

  return (
    <form noValidate className="document-management__modal__create-folder" onSubmit={handleSubmit}>
      <Field id={name.id} name={name.name} component={CustomInput} placeholder={t(name.label)} label={t(name.label)} type="text" size="lg" isRequired />
      <Button disabled={formErrors && !isEmpty(formErrors)} text={t('DOCUMENT_MANAGEMENT.CREATE')} variant="success" className="modal-button-custom h-md" />
      <Button onClick={customCloseAction} type="button" text={t('CANCEL')} variant="gray-outline" className="modal-button-custom h-md" />
    </form>
  );
};

CreateFolderForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  formErrors: getFormSyncErrors(FORMS.createNewFolderForm)(state),
});

export default connect(mapStateToProps, null)(reduxForm({ form: FORMS.createNewFolderForm, validate })(CreateFolderForm));
