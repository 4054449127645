import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Line } from 'rc-progress';

import '../styles/generic-upload.scss';

class UploadBar extends Component {
  render() {
    const { percent, fileName, uploadProgress } = this.props;
    return (
      <div className="generic-upload-container">
        <p className="smaller">
          {fileName} - {percent} %
        </p>
        <Line className="upload-line" {...uploadProgress} percent={percent} />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  uploadProgress: state.themeReducer.uploadProgress,
});

const mapDispatchToProps = dispatch => ({});

UploadBar.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadBar);
