import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Button from '../../../common/form/components/button';
import CustomInput from '../../../common/form/components/input';
import Textarea from '../../../common/form/components/text-area';
import { FORMS } from '../../../common/constants';
import { firstStepFields } from '../constants/checklists-procedures-constants';
import { validate } from '../validators/first-step-validator';

const ChecklistProceduresFirstStep = ({ handleSubmit, invalid, anyTouched, handleFormSubmit, isEdit, handleCancelButtonClick, isLoading, isDisabled }, { t }) => {
  const { name, description } = firstStepFields;

  const getSubmitButtonContent = () => {
    if (isDisabled) {
      return 'CHECKLISTS_PROCEDURES_MANAGEMENT.NEXT_BTN';
    }

    return isEdit ? 'CHECKLISTS_PROCEDURES_MANAGEMENT.SAVE_BTN' : 'CHECKLISTS_PROCEDURES_MANAGEMENT.CREATE_BTN';
  };

  return (
    <form className="checklists-procedures-management__container__first-step" onSubmit={handleSubmit(handleFormSubmit)} noValidate>
      <div className="checklists-procedures-management__container__first-step__inputs checklists-procedures-management__container__first-step__input_name">
        <Field id={name.name} name={name.name} label={name.label} component={CustomInput} type="text" placeholder={name.placeholder} disabled={isLoading || isDisabled} />
      </div>
      <div className="checklists-procedures-management__container__first-step__inputs">
        <Field
          id={description.name}
          name={description.name}
          component={Textarea}
          placeholder={description.placeholder}
          label={description.label}
          maxChars={description.maxChars}
          disabled={isLoading || isDisabled}
          enableAutoResize
        />
      </div>
      <div className="checklists-procedures-management__container__first-step__buttons">
        <Button variant="gray-outline" type="button" disabled={isLoading} text={t('CANCEL')} width="sm" height="md" onClick={handleCancelButtonClick} />
        <Button
          className="checklists-procedures-management__container__first-step__buttons__submit-btn"
          disabled={isLoading || (invalid && anyTouched)}
          text={t(getSubmitButtonContent())}
          width="sm"
          height="md"
        />
      </div>
    </form>
  );
};

ChecklistProceduresFirstStep.contextTypes = {
  t: PropTypes.func.isRequired,
};

ChecklistProceduresFirstStep.propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  handleCancelButtonClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

ChecklistProceduresFirstStep.defaultProps = {
  isEdit: false,
  isLoading: false,
  isDisabled: false,
};

export default reduxForm({
  form: FORMS.checklistsProceduresFirstStep,
  enableReinitialize: true,
  touchOnChange: true,
  validate,
})(ChecklistProceduresFirstStep);
