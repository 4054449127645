import { take } from 'lodash';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { setActiveCameraHelper } from '../../../start-workflow/actions/action-creators';
import { setCurrentImage } from '../../actions/inspection-actions';

import { FEATURES } from '../../../../common/constants';
import { detailsPages } from '../../constants/constants';
import { setActiveSlide } from '../../potree/actions/action-creators';
import CameraRenderer from './camera-renderer';

class Cameras extends Component {
  handleMarkerClick = camera => {
    const { setCurrentImage, filteredImages, setActiveSlide } = this.props;
    const index = filteredImages.findIndex(el => camera.FileID === el.ID);
    if (index > -1) {
      setCurrentImage(filteredImages[index], index, false);
      setActiveSlide(index);
    } else {
      console.warn("Selected camera doesn't exist in current array of selected images for workflow");
    }
  };

  render() {
    const { viewer, setActiveCameraHelper, filteredImages, activeDetailsPage, currentImage, cameraPositionVisible, cameraThumbnailVisible } = this.props;
    const camerasToRender =
      activeDetailsPage === detailsPages.inspect
        ? (filteredImages || []).map(el => ({
            ...el,
            Transform: { Position: el.CameraPosition, Rotation: el.CameraRotation },
            selected: currentImage && currentImage.ID ? currentImage.ID === el.ID : false,
            visible: cameraPositionVisible,
            withThumbnail: cameraThumbnailVisible,
          }))
        : [];
    return (
      <Fragment>
        <CameraRenderer
          deselectAll={() => null}
          selectAll={() => null}
          toggleElement={() => null}
          deselectAllTemp={() => null}
          selectAllTemp={() => null}
          toggleElementTemp={() => null}
          setActiveCamera={setActiveCameraHelper}
          handleMarkerClick={this.handleMarkerClick}
          elements={camerasToRender}
          viewer={viewer}
          cameraFocus={FEATURES.inspectCameraFocus.visible}
        >
          {({ elementClickHandler, elementShowHandler, elementHideHandler, selectAllHandler, deselectAllHandler }) => <></>}
        </CameraRenderer>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  filteredImages: state.potreeReducer.pointImagesTake > 0 ? take(state.potreeReducer.pointImages, state.potreeReducer.pointImagesTake) : state.potreeReducer.pointImages,
  activeDetailsPage: state.inspectionReducer.activeDetailsPage,
  currentImage: state.startWorkflowReducer.currentImage,
  cameraPositionVisible: state.startWorkflowReducer.cameraPositionVisible,
  cameraThumbnailVisible: state.startWorkflowReducer.cameraThumbnailVisible,
});

const mapDispatchToProps = dispatch => ({
  setActiveCameraHelper: cameraHelper => dispatch(setActiveCameraHelper(cameraHelper)),
  setCurrentImage: (image, index, moveCamera) => dispatch(setCurrentImage(image, index, moveCamera)),
  setActiveSlide: index => dispatch(setActiveSlide(index)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cameras);
