import React from 'react';
import SmallDropdown from '../../../../../../common/small-dropdown/components/small-dropdown';
import Icon from '../../../../../../common/icon/components/icon';

const ThreeDotMenu = ({ showAll, hideAll, showNested, nestedComponentsAction }, { t }) => {
  const menuOptions = [
    {
      title: 'COMPONENTS_THREE_DOT_MENU.SHOW_ALL',
      action: () => showAll(),
    },
    {
      title: 'COMPONENTS_THREE_DOT_MENU.HIDE_ALL',
      action: () => hideAll(),
    },
    {
      title: `COMPONENTS_THREE_DOT_MENU.${showNested ? 'HIDE' : 'SHOW'}_SUB_LEVELS`,
      action: () => nestedComponentsAction(),
    },
  ];

  return (
    <SmallDropdown
      actionsMenu={menuOptions}
      InfoIcon={() => <Icon size="md" name="three-dot-eye" />}
      getClickAction={(action, b, selfClose, e) => {
        e?.preventDefault && e.preventDefault();
        selfClose();
        if (!action) return;
        else if (typeof action === 'function') action();
      }}
      offsetX={-140}
      offsetY={10}
    />
  );
};

export default ThreeDotMenu;
