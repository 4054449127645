import { cloneDeep, filter, isEmpty, pick, remove } from 'lodash';

import ndtActions from '../../../api/ndt-api/actions';

import {
  amendNdtMeasurementLocations,
  fetchNDTMeasurementPointPropertiesSuccess,
  fetchPredictedResultsFailure,
  fetchPredictedResultsStart,
  fetchPredictedResultsSuccess,
  handleDeleteNDTMeasurementModal,
  handleNDTMeasurementPointModal,
  setActiveNDTMeasurementPoint,
  setFilterGroupSuggestions,
  setInspectionNDTMeasurementsClustered,
  setNDTChartFilter,
  setNDTChartGroupsDropdownData,
  setNDTChartPeriodsDropdownData,
  setNDTChartPointGroupsData,
  setNDTChartTypesDropdownData,
  setNDTMainChartData,
  setNDTPredictiveSettingsData,
  setNDTPredictiveTypesDropdownData,
  setNDTSmallChartData,
  setNDTSmallChartDataLoading,
  setNdtMeasurementLocations,
  setNdtMeasurementLocationsLoading,
  setNdtMeasurementPoints,
  setNdtMeasurementsComponents,
  setNdtMeasurementsUnits,
} from './action-creators';
import { getNDTMeasurementPointProperties } from './inspection-actions';

import axios from 'axios';
import Helpers from '../../../common/helpers';
import { getNDTMeasurementFilesUploaded, getNDTMeasurementPointFilesUploaded } from '../../upload/actions/external-upload-actions';
import { defaultPredictiveSettings } from '../components/ndt/constants/predictive-settings';
import { formConstants as inspectionSettingsConstants } from '../constants/inspection-settings';
import { chartFilters, graphTypeValues, groupValues, measurementConstants, measurementPointConstants } from '../constants/ndt-constants';

let cancelToken;
export const getMeasurements = (InspectionID, SearchText = '', optionalParams = {}, loadMore = false, callback, isLite = false) => {
  return async dispatch => {
    if (!InspectionID) {
      return;
    }
    try {
      dispatch(setNdtMeasurementLocationsLoading(true));

      const action = isLite ? ndtActions.getNDTMeasurementsLite : ndtActions.getNDTMeasurements;

      const res = await action({ InspectionID, SearchText, ...optionalParams });

      const { Measurements, ...restParams } = res.data.Data;

      callback && callback(restParams);

      if (!loadMore) {
        dispatch(
          setNdtMeasurementLocations(
            (Measurements || []).map(el => ({
              ...el,
              ID: el[measurementConstants.fields.measurementId],
              [measurementConstants.fields.name]: el[measurementConstants.fields.measurementName],
              visible: true,
            }))
          )
        );
      } else {
        dispatch(
          amendNdtMeasurementLocations(
            (Measurements || []).map(el => ({
              ...el,
              ID: el[measurementConstants.fields.measurementId],
              [measurementConstants.fields.name]: el[measurementConstants.fields.measurementName],
              visible: true,
            }))
          )
        );
      }

      dispatch(setNdtMeasurementLocationsLoading(false));
    } catch (err) {
      dispatch(setNdtMeasurementLocationsLoading(false));
    }
  };
};

export const getMeasurementDetails = async measurementId => {
  let res = await ndtActions.getNDTMeasurementDetails([{ measurement_id: measurementId }]);

  const { Data } = res.data;
  return Data;
};

export const getMeasurementGroupDetails = async ComponentID => {
  let res = await ndtActions.getNDTMeasurementGroupDetails({ ComponentID });

  const { Data } = res.data;
  return Data;
};

export const addMeasurement = (InspectionID, currentMeasurements, data, camPos, callback) => {
  return async dispatch => {
    try {
      const res = await ndtActions.createMeasurementLocation({
        InspectionID,
        ...data,
        [measurementConstants.fields.camPos]: {
          coordinates: [camPos?.x, camPos?.y, camPos?.z],
        },
      });
      const { Data } = res.data;
      const newMeasurements = [...currentMeasurements, { ...data, ...Data, visible: true }];
      if (Data) {
        dispatch(setNdtMeasurementLocations(newMeasurements));
        callback && callback(Data);
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateMeasurement = (data, currentMeasurements) => {
  return async (dispatch, getState) => {
    const { measurementLocationsClustered } = getState().ndtReducer;
    try {
      if (isEmpty(data)) return;

      const newData = { ...data, [measurementConstants.fields.componentName]: !isEmpty(data.component) ? data.component.label : data[measurementConstants.fields.componentName] };
      await ndtActions.updateMeasurementLocation(newData);

      const newMeasurementLocationsClustered = updateMeasurementLocationInArray(newData[measurementConstants.fields.id], newData, measurementLocationsClustered);
      dispatch(setInspectionNDTMeasurementsClustered(newMeasurementLocationsClustered));

      const newMeasurementLocations = updateMeasurementLocationInArray(newData[measurementConstants.fields.id], newData, currentMeasurements);
      dispatch(setNdtMeasurementLocations(newMeasurementLocations));

      return data;
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateMeasurementComponent = (MeasurementID, ComponentID) => {
  return async dispatch => {
    try {
      await ndtActions.updateMeasurementomponent({ MeasurementID, ComponentID });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateMeasurementGeometry = (data, currentMeasurements, callback) => {
  return async (dispatch, getState) => {
    const { measurementLocationsClustered } = getState().ndtReducer;
    try {
      if (isEmpty(data) || isEmpty(data.Geometry)) return;

      const newData = { ...data };
      await ndtActions.updateMeasurementLocationGeometry({ Geometry: newData.Geometry, MeasurementID: newData[measurementConstants.fields.id] });

      const newMeasurementLocationsClustered = updateMeasurementLocationInArray(newData[measurementConstants.fields.id], newData, measurementLocationsClustered);
      dispatch(setInspectionNDTMeasurementsClustered(newMeasurementLocationsClustered));

      const newMeasurementLocations = updateMeasurementLocationInArray(newData[measurementConstants.fields.id], newData, currentMeasurements);
      dispatch(setNdtMeasurementLocations(newMeasurementLocations));

      callback && callback(newData);
      return data;
    } catch (e) {
      console.log(e);
    }
  };
};

export const deleteMeasurement = (data, currentMeasurements, callback) => {
  return async (dispatch, getState) => {
    const { measurementLocationsClustered } = getState().ndtReducer;
    try {
      if (isEmpty(data)) return;

      await ndtActions.deleteMeasurementLocation(data);

      const newMeasurementLocationsClustered = deleteMeasurementLocationInArray(data[measurementConstants.fields.measurementId], measurementLocationsClustered);
      dispatch(setInspectionNDTMeasurementsClustered(newMeasurementLocationsClustered));

      const newMeasurementLocations = deleteMeasurementLocationInArray(data[measurementConstants.fields.measurementId], currentMeasurements);
      dispatch(setNdtMeasurementLocations(newMeasurementLocations));

      dispatch(handleDeleteNDTMeasurementModal({ isOpen: false }));

      callback && callback();
      return data;
    } catch (e) {
      dispatch(handleDeleteNDTMeasurementModal({ isOpen: false }));
      console.log(e);
    }
  };
};

export const updateMeasurementLocationInArray = (id, newMeasurementLocationData, currentMeasurementLocations) => {
  const foundIndex = (currentMeasurementLocations || []).findIndex(el => el[measurementConstants.fields.id] === id);
  if (foundIndex > -1) {
    const newMeasurementLocations = cloneDeep(currentMeasurementLocations);
    newMeasurementLocations[foundIndex] = { ...newMeasurementLocations[foundIndex], ...newMeasurementLocationData };
    return newMeasurementLocations;
  } else {
    console.warn('Element with: ', id, ' ID has not been found in array');
    return currentMeasurementLocations;
  }
};

export const deleteMeasurementLocationInArray = (id, currentMeasurementLocations) => {
  const newMeasurementLocations = cloneDeep(currentMeasurementLocations);
  remove(newMeasurementLocations, item => item[measurementConstants.fields.id] === id);
  return newMeasurementLocations;
};

export const deleteMeasurementFile = (FileID, MeasurementID) => {
  return async dispatch => {
    await ndtActions.deleteNDTMeasurementFile({ FileID, MeasurementID });
    dispatch(getNDTMeasurementFilesUploaded(MeasurementID));
  };
};

// MEASUREMENT POINT ACTIONS
export const selectNDTMeasurementPoint = (data, currentMeasurementPoint) => {
  return async dispatch => {
    if (!isEmpty(currentMeasurementPoint) && !isEmpty(data) && data[measurementPointConstants.fields.id] === currentMeasurementPoint[measurementPointConstants.fields.id]) {
      return;
    }

    // reset properties
    dispatch(fetchNDTMeasurementPointPropertiesSuccess(null));

    let payload = { ...data };
    if (data.ID >= 0 && !data.isTemp) {
      // Make an api call
      const res = await getMeasurementPointDetails(data.ID);
      dispatch(getNDTMeasurementPointFilesUploaded(data.ID, false));
      dispatch(getNDTMeasurementPointProperties(data.ID, false));
      payload = res;
    } else {
      dispatch(getNDTMeasurementPointFilesUploaded(data.ID, true));
      dispatch(getNDTMeasurementPointProperties(data.ID, true));
    }

    dispatch(setActiveNDTMeasurementPoint(payload));
  };
};

export const getMeasurementPoints = (MeasurementID, filterData, callback = () => null, currentPoints = []) => {
  return async dispatch => {
    if (!MeasurementID) {
      return;
    }
    const res = await ndtActions.getNDTMeasurementPoints({ MeasurementID, ...filterData });
    const { Measures, ...restProps } = res.data.Data;

    callback(restProps);

    const dataToSave = currentPoints.concat((Measures || []).map(el => ({ ...el })));

    dispatch(setNdtMeasurementPoints(dataToSave));
  };
};

export const getMeasurementPointDetails = async measureId => {
  let res = await ndtActions.getNDTMeasurementPointDetails([{ measure_id: measureId }]);

  const { Data } = res.data;
  return Data;
};

export const addTempMeasurementPoint = (data, currentMeasurements, callback = () => null) => {
  return async (dispatch, getState) => {
    const { userReducer } = getState();
    const newData = {
      ...(data || {}),
      [measurementPointConstants.fields.id]: 'temp_property_' + Date.now(),
      [measurementPointConstants.fields.measuredDate]: Helpers.getUnixDate(Date.now()),
      [measurementPointConstants.fields.measuredByUser]: userReducer.Name,
      [measurementPointConstants.fields.filesCount]: 0,
      [measurementPointConstants.fields.imagesCount]: 0,
      isTemp: true,
    };
    const newMeasurements = [newData, ...currentMeasurements];
    await dispatch(setNdtMeasurementPoints(newMeasurements));
    await dispatch(selectNDTMeasurementPoint(newData, null));
    callback();
  };
};

export const addMeasurementPoint = (InspectionID, MeasurementID, currentMeasurements, data, unit, chartFilterData, callback = () => null) => {
  return async dispatch => {
    try {
      const res = await ndtActions.createMeasurementPoint({
        ...data,
        InspectionID,
        MeasurementID,
      });
      const { Data } = res.data;
      if (!isEmpty(Data)) {
        const newData = { ...data, ...Data, isTemp: false };
        dispatch(updateMeasurementPointsInArray(data[measurementPointConstants.fields.id], newData, currentMeasurements));
        dispatch(selectNDTMeasurementPoint({}, null));
        dispatch(getChartDefaultValues(MeasurementID, chartFilterData[chartFilters.type]));
        dispatch(getNDTMainChartData(MeasurementID, unit, chartFilterData));
      }
      callback();
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateMeasurementPoint = (InspectionID, MeasurementID, data, currentMeasurements, unit, chartFilterData) => {
  return async dispatch => {
    try {
      if (isEmpty(data)) return;

      const newData = {
        ...data,
        [measurementPointConstants.fields.imagesCount]: isEmpty(data[measurementPointConstants.fields.imagesCount]) ? 0 : data[measurementPointConstants.fields.imagesCount].length,
        [measurementPointConstants.fields.filesCount]: isEmpty(data[measurementPointConstants.fields.filesCount]) ? 0 : data[measurementPointConstants.fields.filesCount].length,
      };
      if (!data.isTemp) {
        await ndtActions.updateMeasurementPoint({
          ...newData,
          InspectionID,
          MeasurementID,
        });
      }
      dispatch(updateMeasurementPointsInArray(newData[measurementPointConstants.fields.id], newData, currentMeasurements));
      dispatch(selectNDTMeasurementPoint({}, null));
      dispatch(getChartDefaultValues(MeasurementID, chartFilterData[chartFilters.type]));
      dispatch(getNDTMainChartData(MeasurementID, unit, chartFilterData));

      return newData;
    } catch (e) {
      console.log(e);
    }
  };
};

export const deleteMeasurementPoint = (data, currentMeasurements, MeasurementID, unit, chartFilterData) => {
  return async dispatch => {
    try {
      if (isEmpty(data)) return;

      if (!data.isTemp) {
        await ndtActions.deleteMeasurementPoint(data);
      }
      dispatch(selectNDTMeasurementPoint({}));
      dispatch(deleteMeasurementPointsInArray(data[measurementPointConstants.fields.id], currentMeasurements));
      dispatch(handleNDTMeasurementPointModal({ isOpen: false }));
      dispatch(getChartDefaultValues(MeasurementID, chartFilterData[chartFilters.type]));
      dispatch(getNDTMainChartData(MeasurementID, unit, chartFilterData));
      return data;
    } catch (e) {
      dispatch(handleNDTMeasurementPointModal({ isOpen: false }));
      console.log(e);
    }
  };
};

export const updateMeasurementPointsInArray = (id, newMeasurementPointsData, currentMeasurementPoints) => {
  return dispatch => {
    const foundIndex = (currentMeasurementPoints || []).findIndex(el => el[measurementPointConstants.fields.id] === id);
    if (foundIndex > -1) {
      const newMeasurementPoints = cloneDeep(currentMeasurementPoints);
      newMeasurementPoints[foundIndex] = { ...newMeasurementPoints[foundIndex], ...newMeasurementPointsData };
      dispatch(setNdtMeasurementPoints(newMeasurementPoints));
    } else {
      console.warn('Element with: ', id, ' ID has not been found in array');
    }
  };
};

export const deleteMeasurementPointsInArray = (id, currentMeasurementPoints) => {
  return dispatch => {
    const newMeasurementPoints = cloneDeep(currentMeasurementPoints);
    remove(newMeasurementPoints, item => item[measurementPointConstants.fields.id] === id);
    dispatch(setNdtMeasurementPoints(newMeasurementPoints));
  };
};

export const deleteMeasurementPointFile = (FileID, MeasureID) => {
  return async dispatch => {
    await ndtActions.deleteNDTMeasurementPointFile({ FileID, MeasureID });
    dispatch(getNDTMeasurementPointFilesUploaded(MeasureID));
  };
};

export const getChartDefaultValues = (measurement_id, graphType = null) => {
  return async dispatch => {
    try {
      const res = await ndtActions.getNDTChartDefaults([{ measurement_id }]);
      const { Data } = res.data;
      if (Data[chartFilters.dateFrom]) {
        dispatch(setNDTChartFilter({ key: chartFilters.dateFrom, value: new Date(Data[chartFilters.dateFrom] * 1000) }));
      }
      if (Data[chartFilters.dateTo]) {
        dispatch(setNDTChartFilter({ key: chartFilters.dateTo, value: new Date(Data[chartFilters.dateTo] * 1000) }));
      }

      switch (graphType) {
        case graphTypeValues.excessiveRate:
          if (!isEmpty(Data.ExcessiveGroups) && Data.ExcessiveGroups[0]) {
            await dispatch(setNDTChartGroupsDropdownData(Data.ExcessiveGroups));
            const defaultValue = Data.ExcessiveGroups[0].Key;
            // Set default value
            dispatch(setChartFilter(chartFilters.group, defaultValue, measurement_id));
          } else {
            await dispatch(setNDTChartGroupsDropdownData([]));
          }
          break;
        default:
          if (!isEmpty(Data.Groups) && Data.Groups[0]) {
            await dispatch(setNDTChartGroupsDropdownData(Data.Groups));
            const defaultValue = Data.SelectedGroup;
            // Set default value
            dispatch(setChartFilter(chartFilters.group, defaultValue, measurement_id));
          } else {
            await dispatch(setNDTChartGroupsDropdownData([]));
          }
          break;
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const getChartPointGroups = () => {
  return async dispatch => {
    try {
      const res = await ndtActions.getNDTChartPointGroups();
      const { Data } = res.data;
      if (!isEmpty(Data) && Data[0]) {
        await dispatch(setNDTChartPointGroupsData(Data));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const getChartTypesDropdown = measurementId => {
  return async dispatch => {
    try {
      const res = await ndtActions.getNDTChartTypes();
      const { Data } = res.data;
      if (!isEmpty(Data) && Data[0]) {
        await dispatch(setNDTChartTypesDropdownData(Data));
        const defaultValue = Data[0].Key;
        // Set default value
        dispatch(setChartFilter(chartFilters.type, defaultValue, measurementId));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const getNDTChartDropdownsData = measurementId => {
  return async dispatch => {
    await dispatch(getChartDefaultValues(measurementId));
    await dispatch(getChartTypesDropdown(measurementId));
  };
};

export const setChartFilter = (key, newValue, measurementId) => {
  return dispatch => {
    dispatch(setNDTChartFilter({ key, value: newValue }));
    switch (key) {
      case chartFilters.type:
        dispatch(getChartDefaultValues(measurementId, newValue));
        break;
      default:
        return;
    }
  };
};

export const getNDTMainChartData = (MeasurementID, unit, ndtChartFilters, callback = () => null) => {
  return async dispatch => {
    if (!MeasurementID || !ndtChartFilters[chartFilters.group] || !ndtChartFilters[chartFilters.type]) {
      return;
    }
    try {
      dispatch(setNDTMainChartData([]));
      dispatch(fetchPredictedResultsStart());
      const res = await ndtActions.getNDTChartData({
        MeasurementID,
        ...ndtChartFilters,
        [chartFilters.dateFrom]: ndtChartFilters[chartFilters.dateFrom] ? Helpers.getUnixDate(new Date(ndtChartFilters[chartFilters.dateFrom]).getTime()) : null,
        [chartFilters.dateTo]: ndtChartFilters[chartFilters.dateTo] ? Helpers.getUnixDate(new Date(ndtChartFilters[chartFilters.dateTo]).getTime()) : null,
      });
      const { Data } = res.data;
      if (!isEmpty(Data) && !isEmpty(Data.Points)) {
        const { data } = Helpers.mapLineNDTChartData(Data.Points || [], unit);
        await dispatch(setNDTMainChartData(data || []));
        callback();
      } else {
        dispatch(setNDTMainChartData([]));
      }
      if (!isEmpty(Data)) {
        dispatch(fetchPredictedResultsSuccess(Data.PredictedResults || []));
      }
    } catch (e) {
      dispatch(setNDTMainChartData([]));
      dispatch(fetchPredictedResultsFailure([]));
      console.log(e);
    }
  };
};

export const getNDTSmallChartData = (MeasurementID, unit) => {
  return async dispatch => {
    try {
      const defaultFilter = {
        DateFrom: null,
        DateTo: null,
        Group: groupValues.none,
        Type: 'MEASUREMENT-VALUES',
      };
      dispatch(setNDTSmallChartData([]));
      dispatch(setNDTSmallChartDataLoading(true));
      const defaultRes = await ndtActions.getNDTChartDefaults([{ measurement_id: MeasurementID }]);
      const { Data: Default } = defaultRes.data;
      if (Default[chartFilters.dateFrom] && Default[chartFilters.dateTo]) {
        const res = await ndtActions.getNDTChartData({
          MeasurementID,
          ...defaultFilter,
          DateFrom: Default[chartFilters.dateFrom],
          DateTo: Default[chartFilters.dateTo],
        });
        const { Data } = res.data;
        if (!isEmpty(Data) && !isEmpty(Data.Points)) {
          const { data } = Helpers.mapLineNDTChartData(Data.Points || [], unit);
          dispatch(setNDTSmallChartData(data || []));
        } else {
          dispatch(setNDTSmallChartData([]));
        }
      }
      dispatch(setNDTSmallChartDataLoading(false));
    } catch (e) {
      dispatch(setNDTSmallChartData([]));
      dispatch(setNDTSmallChartDataLoading(false));

      console.log(e);
    }
  };
};

export const getNdtMeasurementsComponents = inspectionId => {
  return async dispatch => {
    try {
      const res = await ndtActions.getNdtMeasurementsComponents([{ inspection_id: inspectionId }]);
      const { Data } = res.data;

      if (!isEmpty(Data) && Data[0]) {
        await dispatch(setNdtMeasurementsComponents(Data));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const getNdtMeasurementsUnits = inspectionId => {
  return async dispatch => {
    try {
      const res = await ndtActions.getNdtMeasurementsUnits([{ inspection_id: inspectionId }]);
      const { Data } = res.data;

      if (!isEmpty(Data) && Data[0]) {
        await dispatch(setNdtMeasurementsUnits(Data));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const getNdtAlarmsTriggered = inspectionId => {
  return async dispatch => {
    try {
      // const res = await ndtActions.getNdtAlarmsTriggered([{ inspection_id: inspectionId }]);
      // const { Data } = res.data;
      // if (!isEmpty(Data) && Data[0]) {
      //   await dispatch(setNdtAlarmsTriggered(Data));
      // }
    } catch (e) {
      console.log(e);
    }
  };
};

export const getNDTChartPeriodData = inspectionId => {
  return async dispatch => {
    try {
      // const res = await ndtActions.getNdtAlarmsTriggered([{ inspection_id: inspectionId }]);
      // const { Data } = res.data;

      const Data = [
        {
          Key: 'DAY',
          Value: 'Day',
        },
        {
          Key: 'WEEK',
          Value: 'Week',
        },
        {
          Key: 'MONTH',
          Value: 'Month',
        },
        {
          Key: 'YEAR',
          Value: 'Year',
        },
      ];

      if (!isEmpty(Data) && Data[0]) {
        await dispatch(setNDTChartPeriodsDropdownData(Data));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const getPredictiveTypesDropdown = () => {
  return async dispatch => {
    try {
      const res = await ndtActions.getNDTPredictiveTypes();
      const { Data } = res.data;
      if (!isEmpty(Data)) {
        await dispatch(setNDTPredictiveTypesDropdownData(Data));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const getPredictiveSettings = measurement_id => {
  return async dispatch => {
    try {
      const res = await ndtActions.getNDTPredictiveSettings([{ measurement_id }]);
      const { Data } = res.data;
      if (!isEmpty(Data)) {
        dispatch(setNDTPredictiveSettingsData(Data));
      } else {
        dispatch(setNDTPredictiveSettingsData({ ...defaultPredictiveSettings, MeasurementID: measurement_id }));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const updatePredictiveSettings = (data, measurementId, unit, ndtChartFilters, callback) => {
  return async dispatch => {
    try {
      await ndtActions.updateNDTPredictiveSettings(data);
      dispatch(getNDTMainChartData(measurementId, unit, ndtChartFilters, callback));
    } catch (e) {
      console.log(e);
    }
  };
};

export const getFilterGroupSuggestions = (inspID, SearchText) => {
  return async (dispatch, getState) => {
    const {
      inspectionReducer: { inspectionID },
    } = getState();

    const res = await ndtActions.getFilterGroupSuggestions({ SearchText, InspectionID: inspID || inspectionID });
    const { Data } = res.data;
    if (Data && Data.Items) {
      //remove empty strings
      dispatch(setFilterGroupSuggestions(filter(Data.Items, item => item)));
    }
  };
};

export const getInspectionNDTMeasurementsClustered = (inspection_id, activeLeftSidebar, searchText = '', optionalParams = {}, callback) => {
  return async (dispatch, getState) => {
    const {
      inspectionReducer: { inspectionSettings },
    } = getState();
    const clusterConfiguration = pick(inspectionSettings, [
      inspectionSettingsConstants.fields.numberOfRings,
      inspectionSettingsConstants.fields.numberOfSlices,
      inspectionSettingsConstants.fields.initialDistance,
      inspectionSettingsConstants.fields.multiplier,
    ]);
    try {
      //Check if there are any previous pending requests
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel('Operation canceled due to new request.');
      }

      //Save the cancel token for the current request
      cancelToken = axios.CancelToken.source();

      const res = await ndtActions.getMeasurementsClustered(
        { InspectionID: parseInt(inspection_id), SearchText: searchText, ...clusterConfiguration, ...optionalParams },
        { cancelToken: cancelToken.token }
      );
      const { Data } = res.data;

      dispatch(
        setInspectionNDTMeasurementsClustered(
          (Data.Items || []).map((el, index) => ({
            ...el,
            ID: el.ID > 0 ? el.ID : `CLUSTER_${Date.now()}_${index}`,
          }))
        )
      );
      callback &&
        callback(
          (Data.Items || []).map((el, index) => ({
            ...el,
            ID: el.ID > 0 ? el.ID : `CLUSTER_${Date.now()}_${index}`,
          }))
        );
    } catch (e) {
      console.log(e);
    }
  };
};
