import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Tabs from '../../../common/tabs/component/tabs';
import Tab from '../../../common/tabs/component/tab';
import PageNavigation from '../../../common/page-navigation/components/page-navigation';
import Isolations from './isolations/components/isolation-templates';
import PermitTemplates from './permits/components/permit-template-list';
import IsolationTemplateModal from './isolations/components/isolation-template-modal';

import Helpers from '../../../common/helpers';

import { getIsolationTemplates } from '../actions/isolation-actions';
import { setTemplateModalData, setSelectedIsolationTemplate } from '../actions/action-creators';

import { tabConstants } from '../constants/constants';
import routesConstants, { routes } from '../../../common/routes-constants';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../common/permissions-constants';
import { defaultFilter as isolationDefaultFilter, filterFields } from './isolations/constants/constants';
import { steps } from './permits/constants/constants';
import { openPermitTemplateForm } from '../helpers/templates-helpers';

import '../styles/templates.scss';

class Templates extends Component {
  constructor(props) {
    super(props);
    const { query, search } = props.router.location;

    this.state = {
      projectId: parseInt(query.project_id),
      activeTab: tabConstants.permits.tabKey,
      isolationFilter: {
        ...isolationDefaultFilter,
        ProjectID: parseInt(query.project_id),
      },
      menuOptions: {
        [tabConstants.permits.tabKey]: [
          {
            title: 'PERMIT_TEMPLATES.CREATE_NEW',
            titleIcon: 'plus-circle',
            action: () => openPermitTemplateForm('edit', steps.firstStep, null, search),
            access: {
              visibleFor: PERMISSIONS[PERMISSION_TYPES.permitTemplates].create.name,
            },
          },
        ],
        [tabConstants.isolations.tabKey]: [
          {
            title: 'ISOLATION_TEMPLATES.CREATE_NEW',
            titleIcon: 'plus-circle',
            action: this.openIsolationCreateModal.bind(this, true),
            access: {
              visibleFor: PERMISSIONS[PERMISSION_TYPES.isolationTemplates].create.name,
            },
          },
        ],
        [tabConstants.senseChecks.tabKey]: [
          {
            title: 'SENSE_CHECK_TEMPLATES.CREATE_NEW',
            titleIcon: 'plus-circle',
            action: () => console.log('SENSE CHECKS CREATE NEW'),
            access: {
              visibleFor: PERMISSIONS[PERMISSION_TYPES.senseChecks].create.name,
            },
          },
        ],
      },
      isolationTemplatesFetched: false,
    };
  }

  componentDidMount = () => {
    const { projectId } = this.state;

    if (!projectId) {
      Helpers.goTo(routesConstants.routes.protectedRoutes.project.fullPath);
    }
  };

  openIsolationCreateModal = (isCreate, viewOnly) => {
    const { setTemplateModalData, setSelectedIsolationTemplate } = this.props;
    const { t } = this.context;

    const closeAction = () => {
      setTemplateModalData({ isOpen: false });
      setSelectedIsolationTemplate({});
    };

    setTemplateModalData({
      isOpen: true,
      title: t(isCreate ? 'ISOLATION_TEMPLATE_MODAL.CREATE_TITLE' : 'ISOLATION_TEMPLATE_MODAL.EDIT_TITLE'),
      CustomContent: dynamicProps => <IsolationTemplateModal {...dynamicProps} isEditMode={false} closeAction={closeAction} />,
      customClassName: 'modal-large',
      type: 'none',
      closeAction,
    });
  };

  fetchTemplateList = tabKey => {
    const { projectId, isolationFilter } = this.state;
    const { getIsolationTemplates } = this.props;
    switch (tabKey) {
      case tabConstants.isolations.tabKey:
        getIsolationTemplates({ ...isolationDefaultFilter, ProjectID: projectId }, newFilter => {
          this.setState({ isolationTemplatesFetched: true, isolationFilter: { ...isolationFilter, ...newFilter, [filterFields.lastSeen]: isolationDefaultFilter[filterFields.lastSeen] } });
        });
        return;
      case tabConstants.permits.tabKey:
        this.setState({ isolationTemplatesFetched: false });
        return;
      default:
        return;
    }
  };

  handleCreateNewClick = tabKey => {
    const { location } = this.props;

    switch (tabKey) {
      case tabConstants.permits.tabKey:
        openPermitTemplateForm('edit', steps.firstStep, null, location?.search);
        break;
      case tabConstants.isolations.tabKey:
        this.openIsolationCreateModal(true);
        break;
      case tabConstants.senseChecks.tabKey:
        break;
      default:
        break;
    }
  };

  getCreatePermission = tabKey => {
    switch (tabKey) {
      case tabConstants.permits.tabKey:
        return PERMISSIONS[PERMISSION_TYPES.permitTemplates].create.name;
      case tabConstants.isolations.tabKey:
        return PERMISSIONS[PERMISSION_TYPES.isolationTemplates].create.name;
      case tabConstants.senseChecks.tabKey:
        return PERMISSIONS[PERMISSION_TYPES.senseChecks].create.name;
      default:
        return '';
    }
  };

  render() {
    const { activeTab, projectId, isolationFilter, isolationTemplatesFetched } = this.state;
    const { location, getIsolationTemplates, modalData, setTemplateModalData } = this.props;
    const { search } = location;
    const backButtonPath = routes.protectedRoutes.projectSettings.fullPath;

    return (
      <div className="templates default-background">
        <PageNavigation
          backButtonPath={`${backButtonPath}${search}`}
          title="PAGE_TITLE.TEMPLATES"
          icon="templates"
          withBottomBorder
          actionButtontext="CREATE_BUTTON_TEXT.NEW"
          handleActionButtonClick={() => this.handleCreateNewClick(activeTab)}
          actionButtonProps={{
            visibleFor: this.getCreatePermission(activeTab),
          }}
        />
        <Tabs
          onChange={tabkey => {
            this.setState({ activeTab: tabkey });
            this.fetchTemplateList(tabkey);
          }}
        >
          <Tab title={tabConstants.permits.title} tabKey={tabConstants.permits.tabKey} access={{ visibleFor: PERMISSIONS[PERMISSION_TYPES.permitTemplates].view.name }}>
            <PermitTemplates />
          </Tab>
          <Tab title={tabConstants.isolations.title} tabKey={tabConstants.isolations.tabKey} access={{ visibleFor: PERMISSIONS[PERMISSION_TYPES.isolationTemplates].view.name }}>
            <Isolations
              getIsolationTemplates={getIsolationTemplates}
              projectId={projectId}
              filter={isolationFilter}
              setFilter={isolationFilter => this.setState({ isolationFilter })}
              setTemplateModalData={setTemplateModalData}
              modalData={modalData}
              isolationTemplatesFetched={isolationTemplatesFetched}
            />
          </Tab>
          {/* <Tab title={tabConstants.senseChecks.title} tabKey={tabConstants.senseChecks.tabKey} access={{ visibleFor: PERMISSIONS[PERMISSION_TYPES.senseChecks].view.name }}>
            <div />
          </Tab> */}
        </Tabs>
      </div>
    );
  }
}

Templates.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  modalData: state.templateReducer.modalData,
});

const mapDispatchToProps = dispatch => ({
  getIsolationTemplates: (params, successCallback, loadMore, currentLength) => dispatch(getIsolationTemplates(params, successCallback, loadMore, currentLength)),
  setTemplateModalData: data => dispatch(setTemplateModalData(data)),
  setSelectedIsolationTemplate: template => dispatch(setSelectedIsolationTemplate(template)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Templates);
