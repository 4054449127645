export default {
  SET_SELECTED_DEFECT: 'setSelectedDefectWorkflow',
  SET_DEFECTS_DATA: 'setDefectsData',
  SET_ALL_DEFECTS_DATA: 'setAllDefectsData',
  SET_IMAGE_DEFECTS_DATA: 'setImageDefectsData',
  UPDATE_DEFECTS_DATA: 'updateDefectsData',
  SET_ACTIVE_DEFECTS_TAB: 'setActiveDefectsTab',
  SET_CURRENT_IMAGE: 'setCurrentImage',
  TOGGLE_WORKFLOW_DEFECT: 'toggleWorkflowDefect',
  SELECT_ALL_WORKFLOW_DEFECTS: 'selectAllWorkflowDefects',
  DESELECT_ALL_WORKFLOW_DEFECTS: 'deselectAllWorkflowDefects',
  SELECT_ALL_WORKFLOW_DEFECTS_TEMP: 'selectAllWorkflowDefectsTemp',
  DESELECT_ALL_WORKFLOW_DEFECTS_TEMP: 'deselectAllWorkflowDefectsTemp',
  TOGGLE_WORKFLOW_DEFECT_TEMP: 'toggleWorkflowDefectTemp',
  SET_ACTIVE_STEP: 'setActiveStep',
  NEXT_STEP: 'nextWorkflowStep',
  PREVIOUS_STEP: 'previousWorkflowStep',
  SET_NEW_DEFECT: 'setNewDefect',
  SET_COMPONENT_DEFECTS: 'setComponentDefects',
  SET_LINKED_DEFECT_DETAILS: 'setLinkedDefectDetails',
  SET_DEFECTS_DATA_TABLE_PARAMS: 'setDefectsDataTableParams',
  SET_COMMENTS_DATA: 'setCommentsData',
  HANDLE_DELETE_IMAGE_DEFECT_MODAL: 'handleDeleteImageDefectModal',
  SET_DEFECTS_DATA_LODADING: 'setDefectsDataLoading',
  SELECT_ALL_WORKFLOW_CAMERAS: 'selectAllWorkflowCameras',
  DESELECT_ALL_WORKFLOW_CAMERAS: 'deselectAllWorkflowCameras',
  SELECT_ALL_WORKFLOW_CAMERAS_POSITIONS: 'selectAllCameraPositions',
  DESELECT_ALL_WORKFLOW_CAMERAS_POSITIONS: 'deselectAllCameraPositions',
  SELECT_ALL_WORKFLOW_CAMERA_THUMBNAILS: 'selectAllWorkflowCameraThumbnails',
  DESELECT_ALL_WORKFLOW_CAMERA_THUMBNAILS: 'deselectAllWorkflowCameraThumbnails',
  TOGGLE_WORKFLOW_CAMERA: 'toggleWorkflowCamera',
  SET_IMAGE_TOOL: 'setImageTool',
  SET_IMAGE_DRAWINGS: 'setImageDrawings',
  SET_SELECTED_DEFECT_LOADER: 'setSelectedDefectLoader',
  SET_ACTIVE_CAMERA_HELPER: 'setActiveCameraHelper',
  SET_DRAWINGS_DATA_LODADING: 'setDrawingsDataLoading',
  SET_IMAGE_COUNT_STATISTICS: 'setImageCountStatistics',
};
