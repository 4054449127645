import { values } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { createNumberMask } from 'redux-form-input-masks';

import { FORMS } from '../../../../common/constants';
import CustomInput from '../../../../common/form/components/input';
import CustomSelect from '../../../../common/form/components/select';
import Textarea from '../../../../common/form/components/text-area';
import TimePicker from '../../../../common/form/components/time-picker';
import { priorities } from '../../../inspections/constants/work-order-constants';
import { formConstants, formFields, intervalFields } from '../../constants/regime-constants';
import { regimeValidate } from '../../validators/regime-validators';

const BasicDetailsForm = ({ severityColors, disabled, intervalList, additionalWorkorderTypes }, { t }) => {
  const { name, description, toolsRequired, intervalNumber, intervalName, estimatedWrenchTime } = formFields;
  const workOrderPriorities = React.useMemo(() => {
    return values(priorities);
  }, []);

  const numberMask = createNumberMask({
    decimalPlaces: 0,
    allowNegative: false,
  });

  return (
    <form noValidate>
      <Field
        id={name.id}
        name={name.name}
        component={CustomInput}
        placeholder={t(name.label)}
        label={t(name.label)}
        type="text"
        size="lg"
        disabled={disabled}
        isRequired
        additionalClassName="text-transform-none"
        labelClass="text-transform-none"
      />
      <div className="split-input maintenance-regime-custom-select work-order-custom-select">
        <Field
          id={formConstants.fields.importance}
          name={formConstants.fields.importance}
          data={workOrderPriorities}
          valueField="value"
          textField="title"
          component={CustomSelect}
          placeholder={t('CREATE_REGIME_FORM.PRIORITY')}
          label={t('CREATE_REGIME_FORM.PRIORITY')}
          valueComponent={({ item }) => (
            <div className="priority-dropdown-item without-padding">
              {item.color ? <div className="priority-icon" style={{ backgroundColor: severityColors[item.color] }}></div> : null}
              <p className="f-primary">{t(item.title)}</p>
            </div>
          )}
          optionComponent={({ dataItem, onSelect }) => {
            return (
              <div className="priority-dropdown-item" onClick={() => onSelect(dataItem.value)}>
                {dataItem.color ? <div className="priority-icon" style={{ backgroundColor: severityColors[dataItem.color] }}></div> : null}
                <p className="f-primary">{t(dataItem.title)}</p>
              </div>
            );
          }}
          size="lg"
          isRequired
          disabled={disabled}
        />
      </div>
      <div className="creates-wrapper">
        <label className="creates-wrapper__label">{t('CREATES')}</label>
        <label className="creates-wrapper__type">{t('WORK_ORDER.TITLE')}</label>
      </div>
      <div className="split-input with-fake-label">
        <Field
          id={formConstants.fields.additionalSubType}
          name={formConstants.fields.additionalSubType}
          data={additionalWorkorderTypes}
          component={CustomSelect}
          valueField="value"
          textField="title"
          label={t('WORK_ORDER.ADDITIONAL_SUB_TYPE_LABEL')}
          placeholder={t('WORK_ORDER.ADDITIONAL_SUB_TYPE_LABEL')}
          className="select-d"
          size="lg"
          disabled={disabled}
        />
      </div>
      <div className="maintenance-regime-textarea work-order-textarea">
        <Field
          id={description.id}
          name={description.name}
          label={t(description.label)}
          component={Textarea}
          placeholder={t(description.label)}
          maxChars={description.maxChars}
          disabled={disabled}
          enableAutoResize={true}
          size="lg"
        />
      </div>

      <div className="maintenance-regime-textarea work-order-textarea">
        <Field
          id={toolsRequired.id}
          name={toolsRequired.name}
          component={Textarea}
          placeholder={t(toolsRequired.label)}
          label={t(toolsRequired.label)}
          size="lg"
          maxChars={toolsRequired.maxChars}
          disabled={disabled}
        />
      </div>
      <div className="double">
        <div className="split-input">
          <div className="double">
            <div className="split-input">
              <Field
                id={intervalNumber.id}
                name={intervalNumber.name}
                component={CustomInput}
                placeholder={t(intervalNumber.label)}
                label={t(intervalNumber.label)}
                type="tel"
                size="lg"
                isRequired
                disabled={disabled}
                {...numberMask}
              />
            </div>
            <div className="split-input with-fake-label">
              <Field
                id={intervalName.id}
                name={intervalName.name}
                data={intervalList}
                component={CustomSelect}
                valueField={intervalFields.id}
                textField={intervalFields.name}
                className="select-d"
                size="lg"
                disabled={disabled}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="double">
        <div className="split-input wrench-time-container">
          <Field
            id={estimatedWrenchTime.id}
            name={estimatedWrenchTime.name}
            component={TimePicker}
            disabled={disabled}
            label={t('CREATE_REGIME_FORM.ESTIMATED_WRENCH_TIME')}
            type="text"
            size="lg"
            showPlaceholderWhileValue
          />
        </div>
      </div>
    </form>
  );
};

BasicDetailsForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default reduxForm({
  form: FORMS.maintenanceRegimeBasicDetailsForm,
  validate: regimeValidate,
  enableReinitialize: true,
  touchOnChange: true,
  destroyOnUnmount: true,
})(BasicDetailsForm);
