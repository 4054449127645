import React from 'react';
import Icon from '../../../common/icon/components/icon';

import { formConstants } from '../../inspections/constants/defect-constants';

export const TABS = {
  all: 'ALL',
  single: 'SINGLE',
};
export const dropdownConstants = {
  key: 'Key',
  value: 'Value',
};

export const TOOLBAR_DATA = [
  // {
  //   name: TABS.all,
  //   label: 'START_WORKFLOW.DEFECTS.ALL_TITLE',
  // },
  {
    name: TABS.single,
    label: 'START_WORKFLOW.DEFECTS.IMAGE_TITLE',
  },
];

export const defectTableHeader = (handleHideDefect, handleShowDefect) => [
  {
    title: 'TABLE.DEFECT_NAME',
    key: formConstants.fields.name,
    enableSort: true,
  },
  {
    title: 'TABLE.DEFECT_TYPE',
    key: formConstants.fields.type,
    enableSort: true,
  },
  {
    title: 'TABLE.DEFECT_SEVERITY',
    key: formConstants.fields.severity,
    enableSort: true,
  },
  {
    title: 'TABLE.DEFECT_CREATED',
    key: formConstants.fields.date,
    enableSort: true,
    type: 'date',
  },
  {
    title: 'TABLE.DEFECT_ACTIONS',
    CustomComponent: defect => (
      <div className="defect-item__actions">
        <Icon name="eye" active={defect.visible} size="xs" onClick={defect.visible ? e => handleHideDefect(e, defect) : e => handleShowDefect(e, defect)} />
      </div>
    ),
    enableSort: false,
  },
];

const sortDirection = {
  asc: 'ASC',
  desc: 'DESC',
};

const defectsDataParams = {
  SortByColumn: formConstants.fields.severity,
  SortDirection: sortDirection.desc,
  SearchText: '',
};

export default {
  defectsDataParams,
  sortDirection,
};
