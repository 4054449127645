import { isEmpty } from 'lodash';
import { MAX_SAFE_DECIMAL_STRING, MIN_SAFE_DECIMAL_STRING } from '../../../../../common/constants';
import Helpers from '../../../../../common/helpers';
import { formConstants as alarmFormConstants } from '../constants/alarm-constants';
import { formConstants as mpFormConstants } from '../constants/measurement-point-constants';

export const alarmsValidate = values => {
  const errors = {
    [mpFormConstants.fields.alarms]: [],
  };
  let hasError = false;

  (values?.[mpFormConstants.fields.alarms] || []).forEach((alarm, index) => {
    errors[mpFormConstants.fields.alarms].push({});

    if (!alarm[alarmFormConstants.fields.enabled.name]) {
      return;
    }

    if (alarm[alarmFormConstants.fields.alarmLevel.name] === undefined || alarm[alarmFormConstants.fields.alarmLevel.name] === null) {
      errors[mpFormConstants.fields.alarms][index][alarmFormConstants.fields.alarmLevel.name] = alarmFormConstants.fields.alarmLevel.errorRequired;
    } else if (isNaN(alarm[alarmFormConstants.fields.alarmLevel.name])) {
      errors[mpFormConstants.fields.alarms][index][alarmFormConstants.fields.alarmLevel.name] = alarmFormConstants.fields.alarmLevel.errorIncorrectFormat;
      // } else if (alarm[alarmFormConstants.fields.alarmLevel.name] < MIN_SAFE_DECIMAL) {
      //   errors[mpFormConstants.fields.alarms][index][alarmFormConstants.fields.alarmLevel.name] = alarmFormConstants.fields.alarmLevel.errorMin;
      // } else if (alarm[alarmFormConstants.fields.alarmLevel.name] > MAX_SAFE_DECIMAL) {
      //   errors[mpFormConstants.fields.alarms][index][alarmFormConstants.fields.alarmLevel.name] = alarmFormConstants.fields.alarmLevel.errorMax;
      // }
    } else if (
      Helpers.parseIntegerFraction(alarm?.[alarmFormConstants.fields.alarmLevel.name].toString()) < Helpers.parseIntegerFraction(MIN_SAFE_DECIMAL_STRING) ||
      Helpers.parseDecimalFraction(alarm?.[alarmFormConstants.fields.alarmLevel.name].toString()) < Helpers.parseDecimalFraction(MIN_SAFE_DECIMAL_STRING)
    ) {
      errors[mpFormConstants.fields.alarms][index][alarmFormConstants.fields.alarmLevel.name] = alarmFormConstants.fields.alarmLevel.errorMin;
    } else if (
      Helpers.parseIntegerFraction(alarm?.[alarmFormConstants.fields.alarmLevel.name].toString()) > Helpers.parseIntegerFraction(MAX_SAFE_DECIMAL_STRING) ||
      Helpers.parseDecimalFraction(alarm?.[alarmFormConstants.fields.alarmLevel.name].toString()) > Helpers.parseDecimalFraction(MAX_SAFE_DECIMAL_STRING)
    ) {
      errors[mpFormConstants.fields.alarms][index][alarmFormConstants.fields.alarmLevel.name] = alarmFormConstants.fields.alarmLevel.errorMax;
    }

    if (!alarm[alarmFormConstants.fields.descriptor.name]) {
      errors[mpFormConstants.fields.alarms][index][alarmFormConstants.fields.descriptor.name] = alarmFormConstants.fields.descriptor.errorRequired;
    }

    if (!alarm[alarmFormConstants.fields.colour.name]) {
      errors[mpFormConstants.fields.alarms][index][alarmFormConstants.fields.colour.name] = alarmFormConstants.fields.colour.errorRequired;
    }

    if (!isEmpty(errors[mpFormConstants.fields.alarms])) {
      hasError = true;
    }
  });

  return hasError ? errors : {};
};
