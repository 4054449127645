import actionTypes from '../constants/action-types';

export const setMfaTypes = data => ({
  type: actionTypes.HANDLE_MFA_TYPES,
  data,
});

export const setMfaTypeData = data => ({
  type: actionTypes.HANDLE_MFA_TYPE_DATA,
  data,
});

export const setMfaTypeCodes = data => ({
  type: actionTypes.HANDLE_MFA_TYPE_CODES,
  data,
});
