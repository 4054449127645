import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import { FORMS } from '../../../common/constants';

import { isEmpty } from 'lodash';

import { validate } from '../validators/login-validators';

import loginConstants from '../constants/login-constants';
import routesConstants from '../../../common/routes-constants';
import Helpers from '../../../common/helpers';

import CustomInput from '../../../common/form/components/input';
import Button from '../../../common/form/components/button';

import { MAIN_LOGO } from '../../../common/constants';

import '../styles/login-form.scss';
import PasswordInput from '../../../common/form/components/password-input';

class LoginForm extends Component {
  render() {
    const { t } = this.context;
    const { handleSubmit, invalid, anyTouched, loginError, clearErrors } = this.props;
    return (
      <form className="login-form" onSubmit={handleSubmit} noValidate>
        <div className="logo">{typeof MAIN_LOGO === 'string' ? <img src={MAIN_LOGO} alt="logo" /> : <MAIN_LOGO />}</div>
        <h4> {t('LOGIN.TITLE')}</h4>
        <Field
          id={loginConstants.fields.username}
          name={loginConstants.fields.username}
          label="LOGIN.EMAIL_FIELD"
          component={CustomInput}
          type="email"
          placeholder="LOGIN.EMAIL_FIELD"
          size="lg"
          onChange={() => clearErrors()}
        />
        <Field
          id={loginConstants.fields.password}
          name={loginConstants.fields.password}
          component={PasswordInput}
          type="password"
          placeholder="LOGIN.PASSWORD_FIELD"
          label={'LOGIN.PASSWORD_FIELD'}
          size="lg"
          onChange={() => clearErrors()}
        />
        <Button disabled={invalid && anyTouched} text={t('LOGIN')} height="md" />
        <div className="error-placeholder">{!isEmpty(loginError) && t(loginError)}</div>
        <label onClick={() => Helpers.goTo(routesConstants.routes.unProtectedRoutes.resetPassword.fullPath)} className="login-form__link f-secondary-dark noselect">
          {t('LOGIN.FORGOT_PASSWORD')}?
        </label>
      </form>
    );
  }
}

LoginForm.contextTypes = {
  t: PropTypes.func.isRequired,
};
LoginForm = reduxForm({
  form: FORMS.login,
  validate,
})(LoginForm);

export default LoginForm;
