import {
  amendInspectionDetails,
  appendComponent,
  appendElementComment,
  appendShareLink,
  appendTempComponent,
  fetchComponentPropertiesSuccess,
  fetchDefectPropertiesSuccess,
  fetchNDTMeasurementPointPropertiesSuccess,
  fetchNDTMeasurementPropertiesSuccess,
  fetchNotificationPropertiesSuccess,
  fetchShareLinksSuccess,
  handleDeleteDefectModal,
  handleDeleteNDTMeasurementModal,
  handleDeleteShareLinkModal,
  handleInspectionModal,
  handleSetNDTMeasurementNotifications,
  handleSetObservationNotifications,
  handleSetObservationTypes,
  removeComponentProperty,
  removeDefectProperty,
  removeDeletedNotificationCustomProperty,
  replaceAddedObservationCustomProperty,
  replaceComponentCustomProperty,
  saveComponentFailure,
  saveComponentStart,
  saveComponentSuccess,
  setActiveDetailsPage,
  setComponentDetailsData,
  setComponentFilterData,
  setComponentLabelSuggestions,
  setComponentRelatedDefects,
  setComponentRelatedDefectsLoading,
  setComponentSubTypeSuggestions,
  setComponentTypeSuggestions,
  setDefectLabelSuggestions,
  setDefectTypeSuggestions,
  setElementDetails,
  setInspectionComponentsClustered,
  setInspectionDetails,
  setInspectionID,
  setInspectionMeasurementsClustered,
  setInspectionObservationsClustered,
  setLocationSuggestions,
  setManufacturerSuggestions,
  setMaterialSuggestions,
  setNDTMeasurementDetailsLoading,
  setNDTMeasurementLabelSuggestions,
  setNDTMeasurementPointLabelSuggestions,
  setNotificationLabelSuggestions,
  setObservationNotificationsLoading,
  setObservationSubTypesSuggestions,
  setObservationWorkOrders,
  setPanoramicImages,
  setParentAssetSuggestions,
  setProjectID,
  setProjectInspections,
  setWorkorderLabelSuggestions,
  toogleLeftToolbar,
  toogleRightToolbar,
  toogleTopToolbar,
  updateComponentTempSuccess,
} from './action-creators';

import { handleAddNewComponent, setPdfComponents } from '../../pdf-tag/actions/action-creators';

import { setCurrentImageData, setDefectsLoading } from '../../start-workflow/actions/action-creators';

import { getMeasurementFilesUploaded } from '../../upload/actions/external-upload-actions';

import workflowActions from '../../../api/inspection-workflow/actions';
import inspectionApi from '../../../api/inspection/actions';
import ndtActions from '../../../api/ndt-api/actions';
import notificationApi from '../../../api/notifications/actions';
import startWorkflowApi from '../../../api/start-workflow/actions';
import workOrdersApi from '../../../api/work-orders/actions';
import ElementActions from '../constants/element-actions';

import workflowConstants from '../../inspection-workflow/constants/inspection-workflow-constants';
import { DefaultComponent, formConstants as componentConstants, defaultFilter } from '../constants/component-constants';
import { measurementTypes, modules, placements } from '../constants/constants';

import Helpers from '../../../common/helpers';
import routesConstants from '../../../common/routes-constants';
import { getElementActions, getElementActionsByActiveSidebar } from '../helpers/inspection-helper';

import { filter, get, isEmpty, omit, pick, values } from 'lodash';

import { formConstants as inspectionSettingsConstants } from '../constants/inspection-settings';

import { DefaultDefect, defectType, formConstants, observationsTypes } from '../constants/defect-constants';

import axios from 'axios';
import { touch, updateSyncErrors, updateSyncWarnings } from 'redux-form';
import { commentFields, commentTagTypes } from '../../../common/comments-tab/constants/constants';
import { dispatchErrorModalWired } from '../../../common/modal/actions/modal-actions';
import { getComponentDMSFilesUploaded } from '../../upload/actions/external-upload-actions';
import { measurementConstants } from '../constants/ndt-constants';
import { setPointImages, setPointImagesLoading } from '../potree/actions/action-creators';
import { fetchImagesRelatedToPoint, get3DPointFrom2D } from '../potree/actions/actions';
import { getMeasurementDetails, getMeasurementGroupDetails, getNDTSmallChartData } from './ndt-actions';

let cancelTokens = {
  componentsClustered: undefined,
  observationsClustered: undefined,
  measurementsClustered: undefined,
  panoramicImages: undefined,
};

export const toggleToolbar = (placement, implicitState) => {
  return async dispatch => {
    switch (placement) {
      case placements.left:
        dispatch(toogleLeftToolbar(implicitState));
        break;
      case placements.top:
        dispatch(toogleTopToolbar(implicitState));
        break;
      case placements.right:
        dispatch(toogleRightToolbar(implicitState));
        break;
      default:
        console.warn(`${placement} doesn't exist`);
        break;
    }
  };
};

export const selectDetailsPage = page => {
  return (dispatch, getState) => {
    const {
      inspectionReducer: { rightCollapsed },
    } = getState();
    // TESTING
    if (rightCollapsed) {
      dispatch(toggleToolbar(placements.right));
    }

    dispatch(setActiveDetailsPage(page));
  };
};

export const getInspectionDetails = inspection_id => {
  return async dispatch => {
    try {
      const converted_inspectionID = parseInt(inspection_id);
      const res = await inspectionApi.getInspectionDetails([{ inspection_id: converted_inspectionID }]);
      const { Data } = res.data;
      dispatch(setInspectionDetails(Data));
      dispatch(setInspectionID(converted_inspectionID));
    } catch (e) {
      console.error(e);
    }
  };
};
export const getInspectionDefects = (inspection_id, activeLeftSidebar, searchText = '', optionalParams = {}, callback, loadMore, isLite = false, isHierarchy = false, isUnassignedLevel = false) => {
  return async (dispatch, getState) => {
    const {
      userReducer,
      inspectionReducer: { inspectionID },
    } = getState();
    inspection_id = inspection_id || inspectionID;

    try {
      if (Helpers.isGuestUser(userReducer)) {
        Object.keys(ElementActions).forEach(async key => {
          const { dispatchActions, requestActions } = ElementActions[key];
          const getElements = isHierarchy ? null : isLite ? requestActions.getInspectionElementsLite : requestActions.getInspectionElements;
          dispatch(dispatchActions.setElementsLoading(true));
          const res = await getElements({ InspectionID: parseInt(inspection_id), SearchText: searchText, ...optionalParams });
          const { Data } = res.data;
          let dataToSave = Data.Defects || Data.Components || Data;
          dataToSave = dataToSave?.length ? dataToSave : [];

          if (loadMore) {
            dispatch(dispatchActions.amendInspectionElements(dataToSave));
          } else {
            dispatch(dispatchActions.setInspectionElements(dataToSave));
          }

          dispatch(dispatchActions.setElementsLoading(false));

          callback && callback(dataToSave);
        });
      } else {
        const elementActions = getElementActionsByActiveSidebar(activeLeftSidebar);

        if (!elementActions || !elementActions.requestActions || !elementActions.dispatchActions) return;
        try {
          const paramsToSend = { InspectionID: parseInt(inspection_id), SearchText: searchText, ...optionalParams };
          dispatch(elementActions.dispatchActions.setElementsLoading(true));
          //picks type of request in case its hierarchy view on
          let getElements = elementActions.requestActions.getInspectionElements;
          if (isLite) {
            if (isHierarchy) {
              if (isUnassignedLevel) getElements = elementActions.requestActions.getInspectionHierarchyUnassignedElements;
              else getElements = elementActions.requestActions.getInspectionHierarchyElements;
            } else getElements = elementActions.requestActions.getInspectionElementsLite;
          } else {
            if (isHierarchy) {
              if (isUnassignedLevel) getElements = elementActions.requestActions.getInspectionHierarchyUnassignedElements;
              else getElements = elementActions.requestActions.getInspectionHierarchyElements;
            }
            //if not hierarchy it is initial value of getElements
          }
          const res = await getElements(paramsToSend);
          const { Data } = res.data;
          let dataToSave = Data.Defects || Data.Components || Data.MainMeasurements || Data;
          dataToSave = dataToSave?.length ? dataToSave : [];

          const restProps = omit(Data, ['Defects', 'Components', 'MainMeasurements']);
          if (loadMore) {
            dispatch(elementActions.dispatchActions.amendInspectionElements(dataToSave));
          } else {
            dispatch(elementActions.dispatchActions.setInspectionElements(dataToSave));
          }
          dispatch(elementActions.dispatchActions.setElementsLoading(false));
          callback && callback(dataToSave, { ...paramsToSend, ...restProps });
        } catch (err) {
          dispatch(elementActions.dispatchActions.setElementsLoading(false));
        }
      }
    } catch (e) {
      console.error(e);
    }
  };
};

export const getInspectionComponentsClustered = (inspection_id, activeLeftSidebar, searchText = '', optionalParams = {}, callback, isHierarchy = false, isUnassignedLevel = false) => {
  return async (dispatch, getState) => {
    const {
      inspectionReducer: { inspectionSettings },
    } = getState();
    const clusterConfiguration = pick(inspectionSettings, [
      inspectionSettingsConstants.fields.numberOfRings,
      inspectionSettingsConstants.fields.numberOfSlices,
      inspectionSettingsConstants.fields.initialDistance,
      inspectionSettingsConstants.fields.multiplier,
    ]);
    try {
      //Check if there are any previous pending requests
      if (typeof cancelTokens.componentsClustered != typeof undefined) {
        cancelTokens.componentsClustered.cancel('Operation canceled due to new request.');
      }

      //Save the cancel token for the current request
      cancelTokens.componentsClustered = axios.CancelToken.source();

      const action = isHierarchy ? (isUnassignedLevel ? inspectionApi.getHierarchyUnassignedComponentsClustered : inspectionApi.getHierarchyComponentsClustered) : inspectionApi.getComponentsClustered;
      const res = await action({ InspectionID: parseInt(inspection_id), SearchText: searchText, ...clusterConfiguration, ...optionalParams }, { cancelToken: cancelTokens.componentsClustered.token });
      const { Data } = res.data;

      dispatch(
        setInspectionComponentsClustered(
          (Data.Items || []).map((el, index) => ({
            ...el,
            ID: el.ID > 0 ? el.ID : `CLUSTER_${Date.now()}_${index}`,
          }))
        )
      );
      callback &&
        callback(
          (Data.Items || []).map((el, index) => ({
            ...el,
            ID: el.ID > 0 ? el.ID : `CLUSTER_${Date.now()}_${index}`,
          }))
        );
    } catch (e) {
      console.error(e);
    }
  };
};

export const getInspectionObservationsClustered = (inspection_id, activeLeftSidebar, searchText = '', optionalParams = {}, callback) => {
  return async (dispatch, getState) => {
    const {
      inspectionReducer: { inspectionSettings },
    } = getState();
    const clusterConfiguration = pick(inspectionSettings, [
      inspectionSettingsConstants.fields.numberOfRings,
      inspectionSettingsConstants.fields.numberOfSlices,
      inspectionSettingsConstants.fields.initialDistance,
      inspectionSettingsConstants.fields.multiplier,
    ]);
    try {
      //Check if there are any previous pending requests
      if (typeof cancelTokens.observationsClustered != typeof undefined) {
        cancelTokens.observationsClustered.cancel('Operation canceled due to new request.');
      }

      //Save the cancel token for the current request
      cancelTokens.observationsClustered = axios.CancelToken.source();

      const res = await inspectionApi.getObservationsClustered(
        {
          InspectionID: parseInt(inspection_id),
          SearchText: searchText,
          SystemType: measurementTypes.defect,
          ...clusterConfiguration,
          ...optionalParams,
        },
        { cancelToken: cancelTokens.observationsClustered.token }
      );
      const { Data } = res.data;

      dispatch(
        setInspectionObservationsClustered(
          (Data.Items || []).map((el, index) => ({
            ...el,
            ID: el.ID > 0 ? el.ID : `CLUSTER_${Date.now()}_${index}`,
          }))
        )
      );
      callback &&
        callback(
          (Data.Items || []).map((el, index) => ({
            ...el,
            ID: el.ID > 0 ? el.ID : `CLUSTER_${Date.now()}_${index}`,
          }))
        );
    } catch (e) {
      console.error(e);
    }
  };
};

export const getInspectionMeasurementsClustered = (inspection_id, activeLeftSidebar, searchText = '', optionalParams = {}, callback) => {
  return async (dispatch, getState) => {
    const {
      inspectionReducer: { inspectionSettings },
    } = getState();
    const clusterConfiguration = pick(inspectionSettings, [
      inspectionSettingsConstants.fields.numberOfRings,
      inspectionSettingsConstants.fields.numberOfSlices,
      inspectionSettingsConstants.fields.initialDistance,
      inspectionSettingsConstants.fields.multiplier,
    ]);
    try {
      //Check if there are any previous pending requests
      if (typeof cancelTokens.measurementsClustered != typeof undefined) {
        cancelTokens.measurementsClustered.cancel('Operation canceled due to new request.');
      }

      //Save the cancel token for the current request
      cancelTokens.measurementsClustered = axios.CancelToken.source();

      const res = await inspectionApi.getMeasurementsClustered(
        { InspectionID: parseInt(inspection_id), SearchText: searchText, ...clusterConfiguration, ...optionalParams },
        { cancelToken: cancelTokens.measurementsClustered.token }
      );
      const { Data } = res.data;

      dispatch(
        setInspectionMeasurementsClustered(
          (Data.Items || []).map((el, index) => ({
            ...el,
            ID: el.ID > 0 ? el.ID : `CLUSTER_${Date.now()}_${index}`,
          }))
        )
      );
      callback &&
        callback(
          (Data.Items || []).map((el, index) => ({
            ...el,
            ID: el.ID > 0 ? el.ID : `CLUSTER_${Date.now()}_${index}`,
          }))
        );
    } catch (e) {
      console.error(e);
    }
  };
};

export const getInspectionElementsByType = (inspection_id, type, searchText = '', setDataToLocalState = false) => {
  return async dispatch => {
    try {
      const { dispatchActions, requestActions } = ElementActions[type];
      const res = await requestActions.getInspectionElements({
        InspectionID: parseInt(inspection_id),
        ...defaultFilter,
        SearchText: searchText,
        PerPage: Number.MAX_SAFE_INTEGER,
      });
      const { Data } = res.data;
      let dataToSave = Data.Defects || Data.Components || Data;
      dataToSave = dataToSave?.length ? dataToSave : [];

      if (setDataToLocalState) {
        return dataToSave;
      }

      dispatch(dispatchActions.setInspectionElements(dataToSave));
    } catch (e) {
      console.error(e);
    }
  };
};

export const editInspectionDetails = (InspectionID, InspectionDate, inspections, callback) => {
  return async (dispatch, getState) => {
    await inspectionApi.editInspectionDetails({ InspectionID, InspectionDate });

    callback();
    /*eslint array-callback-return:*/
    inspections.map(item => {
      if (item.ID === InspectionID) {
        item.InspectionDate = InspectionDate;
      }
    });

    dispatch(setProjectInspections(inspections));
    dispatch(amendInspectionDetails({ InspectionDate }));
  };
};

export const getInspectionsByProjectId = project_id => {
  return async dispatch => {
    try {
      const res = await inspectionApi.getProjectInspections([{ project_id }]);
      const { Data } = res.data;
      if (Data.Inspections) dispatch(setProjectInspections(Data.Inspections));
    } catch (e) {
      console.error(e);
    }
  };
};

export const selectDefect = data => {
  return async dispatch => {
    if (data.defect.SystemType === measurementTypes.inspect) {
      // no need to do anything
      return;
    }
    dispatch(setElementDetails(data || {}));
  };
};

export const selectNDTMeasurement = (data, currentMeasurement, callback, includeLoader = true) => {
  return async dispatch => {
    try {
      // reset properties
      includeLoader && dispatch(setNDTMeasurementDetailsLoading(true));
      dispatch(fetchNDTMeasurementPropertiesSuccess(null));

      let payload = data;
      if (data.ID >= 0) {
        // Make an api call
        const res = await getMeasurementDetails(data.ID);
        dispatch(getNDTSmallChartData(res[measurementConstants.fields.id], res[measurementConstants.fields.unit] || ''));
        payload = res;
      }

      callback && callback(payload);
      dispatch(setNDTMeasurementDetailsLoading(false));
    } catch (e) {
      console.error(e);
      dispatch(setNDTMeasurementDetailsLoading(false));
    }
  };
};

export const selectNDTMeasurementGroup = (compID, callback) => {
  return async dispatch => {
    const data = await getMeasurementGroupDetails(compID);

    callback && callback({ data, ComponentID: compID });
  };
};

// TODO: skip fetch comments for defects once we have the time for it
// TODO: rename this to getSelectedModuleItemDetails and selectedModuleItem
export const getDefectDetails = (defect, callback, legacyProps = {}, includeLoader = true, saveItem = false) => {
  return async dispatch => {
    const { requestActions, dispatchActions } = getElementActions(defect);
    if (!requestActions || !dispatchActions) {
      return null;
    }
    try {
      if (defect.IsTemp) {
        dispatch(selectDefect({ defect, comments: defect.commentsTemp || [] } || {}));
        return;
      }

      includeLoader && dispatch(dispatchActions.handleElementDetailsLoading(true));

      const res = await requestActions.getElementDetails(defect.ID);
      const comments = requestActions.getElementComments && (await requestActions.getElementComments(defect.ID));

      const { Data } = res.data;
      const commentsAll = [...(defect.commentsTemp || []), ...(comments?.data.Data || [])];
      const defectAll = { ...Data, ...legacyProps };
      if (saveItem) {
        dispatch(selectDefect({ defect: defectAll || {}, comments: commentsAll }));
      }
      callback && callback(defectAll, commentsAll);
      includeLoader && dispatch(dispatchActions.handleElementDetailsLoading(false));
    } catch (e) {
      console.error(e);
      includeLoader && dispatch(dispatchActions.handleElementDetailsLoading(false));
    }
  };
};

export const getComponentRelatedDefects = (ComponentID, SearchText) => {
  return async dispatch => {
    try {
      dispatch(setComponentRelatedDefectsLoading(true));

      const res = await inspectionApi.getComponentRelatedDefects({ ComponentID, SearchText });
      let Data = get(res, 'data.Data');

      dispatch(setComponentRelatedDefects(Data || []));

      dispatch(setComponentRelatedDefectsLoading(false));
    } catch (e) {
      console.error(e);
      dispatch(setComponentRelatedDefectsLoading(false));
    }
  };
};

export const setComponentFilter = id => {
  return async dispatch => {
    try {
      const { requestActions } = ElementActions[measurementTypes.component];
      const res = await requestActions.getElementDetails(id);
      const { Data } = res.data;
      dispatch(setComponentFilterData(Data));
    } catch (e) {
      console.error(e);
    }
  };
};

export const setComponentDetails = id => {
  return async dispatch => {
    try {
      if (id && id > 0) {
        const { requestActions } = ElementActions[measurementTypes.component];
        const res = await requestActions.getElementDetails(id);
        const { Data } = res.data;
        dispatch(setComponentDetailsData(Data));
      } else {
        dispatch(setComponentDetailsData(null));
      }
    } catch (e) {
      console.error(e);
    }
  };
};

export const createNewDefect = (data, callback) => {
  return async (dispatch, getState) => {
    const {
      userReducer,
      inspectionReducer: { inspectionDetails },
      unsavedChangesReducer: { isDirty },
    } = getState();
    try {
      // serves as a pause in execution, so that the unsaved changes popup appears
      if (isDirty) return;
      const { dispatchActions, requestActions, defaultElement } = getElementActions(data);

      if (Helpers.isGuestUser(userReducer)) {
        const newId = 'temp_measure_' + Date.now();
        dispatch(dispatchActions.appendElementTemp({ ...defaultElement(inspectionDetails.CameraPosition), ...data, ID: newId }));
        dispatch(selectDefect({ defect: { ...defaultElement(inspectionDetails.CameraPosition), ...data, ID: newId }, comments: [] }));
        callback && callback({ ...defaultElement(inspectionDetails.CameraPosition), ...data, ID: newId });
      } else {
        const res = await requestActions.createElement({ ...data });
        const Data = get(res, 'data.Data');
        if (Data) {
          dispatch(dispatchActions.appendElement({ ...data, ...pick(Data, [formConstants.fields.status, formConstants.fields.id, formConstants.fields.color, formConstants.fields.name]) }));
          dispatch(selectDefect({ defect: { ...Data, ...pick(data, [formConstants.fields.systemType]) }, comments: [] }));
          callback && callback({ ...Data, ...pick(data, [formConstants.fields.systemType]) });
        } else {
          dispatch(dispatchActions.appendElementTemp({ ...defaultElement(inspectionDetails.CameraPosition), ...data, ID: -2 }));
          dispatch(selectDefect({ defect: { ...defaultElement(inspectionDetails.CameraPosition), ...data, ID: -2 }, comments: [] }));
          callback && callback({ ...defaultElement(inspectionDetails.CameraPosition), ...data, ID: -2 });
        }
      }
    } catch (e) {
      console.error(e);
    }
  };
};

export const updateDefect = (data, needsUpdate = true, callback) => {
  if (isEmpty(data)) return;
  const { dispatchActions, requestActions, FORM } = getElementActions(data);
  return async dispatch => {
    try {
      if (!needsUpdate) return;
      if (data.IsTemp) {
        dispatch(dispatchActions.updateElementTempSuccess(data, 'update'));
      } else {
        const res = await requestActions.updateElement(data);
        dispatch(updateSyncWarnings(FORM.name, Helpers.getWarnings(res.data.Warnings)));
        dispatch(dispatchActions.updateElementSuccess(data, 'update'));
      }

      callback && callback();

      return data;
    } catch (e) {
      const customError = Helpers.getErrorContent(e);
      const errCode = Helpers.getErrorCode(e);
      if (customError) {
        dispatch(
          updateSyncErrors(FORM.name, {
            Code: { string: customError },
          })
        );
        if (errCode === 1149) {
          dispatch(updateSyncWarnings(FORM.name, { [formConstants.fields.name]: { string: customError } }));
        } else if (errCode === 5901) {
          dispatch(touch(FORM.name, 'Code')); // Simulate that the 'Code' field has been touched
        }
      } else {
        dispatchErrorModalWired(true, e);
      }
    }
  };
};

export const updateElementGeometry = (data, needsUpdate = true) => {
  return async dispatch => {
    try {
      // TODO: OPTIMISTIC RESPONSE
      if (isEmpty(data)) return;
      const { dispatchActions, requestActions } = getElementActions(data);
      if (!needsUpdate) return;
      if (data.IsTemp || data.ID < 0) {
        dispatch(dispatchActions.updateElementTempSuccess(data, 'update'));
      } else {
        await requestActions.updateElementGeometry({ ID: data.ID, Geometry: data.Geometry, CameraPosition: data.CameraPosition });
        dispatch(dispatchActions.updateElementSuccess({ ID: data.ID, Geometry: data.Geometry, CameraPosition: data.CameraPosition }, 'update'));
      }
    } catch (e) {
      console.error(e);
    }
  };
};

// DYNAMIC PROPERTIES
export const updateDynamicProperty = data => {
  return async dispatch => {
    try {
      if (isEmpty(data)) return;
      const { ID, Name, Value } = data;
      await inspectionApi.updateDynamicProperty({ Property: { ID, Name, Value } });
    } catch (e) {
      console.error(e);
    }
  };
};

export const updateDefectProperty = data => {
  return async dispatch => {
    try {
      if (isEmpty(data)) return;
      const { ID, Name, Value, DefectID } = data;
      await inspectionApi.updateDefectProperty({ Property: { ID, Name, Value, DefectID } });
    } catch (e) {
      console.error(e);
    }
  };
};

export const deleteDefectProperty = (data, callback) => {
  return async dispatch => {
    try {
      if (isEmpty(data)) return;
      const { ID, isTemp } = data;
      if (!isTemp) {
        await inspectionApi.deleteDefectProperty({ PropertyID: ID });
      }
      dispatch(removeDefectProperty({ ID }));
      callback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const updateComponentProperty = data => {
  return async dispatch => {
    try {
      if (isEmpty(data)) return;
      const { ID, Name, Value, ComponentID } = data;
      await inspectionApi.updateComponentProperty({ Property: { ID, Name, Value, ComponentID } });
    } catch (e) {
      console.error(e);
    }
  };
};

export const deleteComponentProperty = (data, callback) => {
  return async dispatch => {
    try {
      if (isEmpty(data)) return;
      const { ID, isTemp } = data;
      if (!isTemp) {
        await inspectionApi.deleteComponentProperty({ PropertyID: ID });
      }
      dispatch(removeComponentProperty({ ID }));
      callback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const updateNDTProperty = data => {
  return async dispatch => {
    try {
      if (isEmpty(data)) return;
      const { ID, Name, Value, MeasurementID } = data;
      await ndtActions.updateNDTProperty({ Property: { ID, Name, Value, MeasurementID } });
    } catch (e) {
      console.error(e);
    }
  };
};

export const deleteNDTMeasurementProperty = (data, callback) => {
  return async dispatch => {
    try {
      if (isEmpty(data)) return;
      const { ID, isTemp } = data;
      if (!isTemp) {
        await ndtActions.deleteNDTProperty({ PropertyID: ID });
      }
      callback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const updateNDTMeasurementPointProperty = data => {
  return async dispatch => {
    try {
      if (isEmpty(data)) return;
      const { ID, Name, Value, MeasureID } = data;
      await ndtActions.updateNDTMeasurementPointProperty({ Property: { ID, Name, Value, MeasureID } });
    } catch (e) {
      console.error(e);
    }
  };
};

export const deleteNDTMeasurementPointProperty = (data, callback) => {
  return async dispatch => {
    try {
      if (isEmpty(data)) return;
      const { ID, isTemp } = data;
      if (!isTemp) {
        await ndtActions.deleteNDTMeasurementPointProperty({ PropertyID: ID });
      }
      callback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const updateNotificationProperty = data => {
  return async dispatch => {
    try {
      if (isEmpty(data)) return;
      const { ID, Name, Value, NotificationID } = data;
      await notificationApi.updateNotificationCustomProperty({ Property: { ID, Name, Value, NotificationID } });
    } catch (e) {
      console.error(e);
    }
  };
};

export const deleteNotificationProperty = (data, callback) => {
  return async dispatch => {
    try {
      if (isEmpty(data)) return;
      const { ID, isTemp } = data;
      if (!isTemp) {
        await notificationApi.deleteNotificationCustomProperty({ PropertyID: ID });
      }
      dispatch(removeDeletedNotificationCustomProperty(ID));
      callback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const getDefectProperties = defectID => {
  return async dispatch => {
    try {
      dispatch(fetchDefectPropertiesSuccess(null));
      if (!defectID || defectID < 0) return;

      const res = await inspectionApi.getDefectProperties({ DefectID: defectID });
      const { Data } = res.data;
      if (Data) dispatch(fetchDefectPropertiesSuccess(Data.Properties || []));
    } catch (e) {
      console.error(e);
    }
  };
};

export const getComponentProperties = componentID => {
  return async dispatch => {
    try {
      dispatch(fetchComponentPropertiesSuccess(null));
      if (!componentID || componentID < 0) return;

      const res = await inspectionApi.getComponentProperties({ ComponentID: componentID });
      const { Data } = res.data;
      dispatch(fetchComponentPropertiesSuccess((Data && Data.Properties) || null));
    } catch (e) {
      console.error(e);
    }
  };
};

export const getNDTMeasurementProperties = MeasurementID => {
  return async dispatch => {
    try {
      dispatch(fetchNDTMeasurementPropertiesSuccess(null));
      if (!MeasurementID || MeasurementID < 0) return;

      const res = await ndtActions.getNDTMeasurementProperties({ MeasurementID });
      const { Data } = res.data;
      dispatch(fetchNDTMeasurementPropertiesSuccess((Data && Data.Properties) || null));
    } catch (e) {
      console.error(e);
    }
  };
};

export const getNDTMeasurementPointProperties = (MeasureID, isTemp) => {
  return async dispatch => {
    try {
      dispatch(fetchNDTMeasurementPointPropertiesSuccess(null));
      if (!MeasureID || MeasureID < 0 || isTemp) return;

      const res = await ndtActions.getNDTMeasurementPointProperties({ MeasureID });
      const { Data } = res.data;
      dispatch(fetchNDTMeasurementPointPropertiesSuccess((Data && Data.Properties) || null));
    } catch (e) {
      console.error(e);
    }
  };
};

export const getNotificationCustomProperties = notificationID => {
  return async dispatch => {
    try {
      dispatch(fetchNotificationPropertiesSuccess(null));
      if (!notificationID || notificationID < 0) return;

      const res = await notificationApi.getNotificationCustomProperties({ NotificationID: notificationID });
      const { Data } = res.data;
      dispatch(fetchNotificationPropertiesSuccess((Data && Data.Properties) || null));
    } catch (e) {
      console.error(e);
    }
  };
};

export const getNotificationCustomPropertyDependencies = (NotificationID, PropertyValue, PropertyName, callback) => {
  return async dispatch => {
    try {
      const res = await notificationApi.fetchNotificationCustomPropDependantValues({ NotificationID, PropertyValue, PropertyName });
      const { Data } = res.data;
      callback && typeof callback === 'function' && callback(Data);
    } catch (e) {
      console.error(e);
    }
  };
};

export const addDefectProperty = data => {
  return async dispatch => {
    try {
      if (!data) return;
      const { ID, isTemp, ...otherData } = data;
      await inspectionApi.addDefectProperty({ Property: otherData });
      dispatch(getDefectProperties(otherData.DefectID));
    } catch (e) {
      console.error(e);
    }
  };
};

export const addComponentProperty = data => {
  return async dispatch => {
    try {
      if (!data) return;
      const { ID, isTemp, ...otherData } = data;
      await inspectionApi.addComponentProperty({ Property: otherData });
      dispatch(getComponentProperties(otherData.ComponentID));
    } catch (e) {
      console.error(e);
    }
  };
};

export const addNDTMeasurementProperty = data => {
  return async dispatch => {
    try {
      if (!data) return;
      const { ID, isTemp, ...otherData } = data;
      await ndtActions.addNDTMeasurementProperty({ Property: otherData });

      dispatch(getNDTMeasurementProperties(otherData.MeasurementID));
    } catch (e) {
      console.error(e);
    }
  };
};

export const addNDTMeasurementPointProperty = data => {
  return async dispatch => {
    try {
      if (!data) return;
      const { ID, isTemp, ...otherData } = data;
      await ndtActions.addNDTMeasurementPointProperty({ Property: otherData });

      dispatch(getNDTMeasurementPointProperties(otherData.MeasureID));
    } catch (e) {
      console.error(e);
    }
  };
};

export const deleteDefect = (data, callback = null, deleteOnlyInternal) => {
  return async dispatch => {
    try {
      ///aa
      dispatch(setDefectsLoading(true));
      const { dispatchActions, requestActions } = getElementActions(data);

      if (!deleteOnlyInternal) {
        await requestActions.deleteElement(data);
        dispatch(
          toggleDeleteDefectModal({
            isOpen: false,
          })
        );
      }
      callback && callback(data, 'delete');
      dispatch(dispatchActions.updateElementSuccess(data, 'delete'));
      dispatch(selectDefect({ defect: {} }));
      dispatch(setDefectsLoading(false));
    } catch (e) {
      console.error(e);
      dispatch(setDefectsLoading(false));
      dispatch(
        toggleDeleteDefectModal({
          isOpen: false,
        })
      );
    }
  };
};

export const addDefectComment = data => {
  return async (dispatch, getState) => {
    const { userReducer } = getState();
    try {
      const { dispatchActions, requestActions } = getElementActions(data.defect);
      if (Helpers.isGuestUser(userReducer)) {
        const commentPayload = {
          CreatedAt: Date.now(),
          DefectID: data.defect.ID,
          ID: 'temp_comment_' + Date.now(),
          Message: data.Message,
          Read: false,
          UserName: userReducer.Name,
        };
        dispatch(dispatchActions.appendElementTempComment({ commentPayload, defect: data.defect }));
        dispatch(appendElementComment(commentPayload));
      } else {
        const res = await requestActions.addComment(data.Message, data.defect.ID);
        const { Data } = res.data;
        if (Data) dispatch(appendElementComment(Data));
      }
    } catch (e) {
      console.error(e);
    }
  };
};

export const toggleDeleteDefectModal = data => {
  return dispatch => {
    dispatch(handleDeleteDefectModal(data));
  };
};

export const toggleDeleteShareModal = data => {
  return dispatch => {
    dispatch(handleDeleteShareLinkModal(data));
  };
};

export const toggleDeleteNDTMeasurementModal = data => {
  return dispatch => {
    dispatch(handleDeleteNDTMeasurementModal(data));
  };
};

// inspection modal handlers start

export const toggleInspectionModal = data => {
  return dispatch => {
    dispatch(handleInspectionModal(data));
  };
};

export const closeInspectionModal = () => {
  return (dispatch, getState) => {
    const {
      inspectionReducer: { inspectionModalData },
    } = getState();
    const newModalData = {
      ...inspectionModalData,
      isOpen: false,
      isMinimized: false,
    };

    dispatch(setPdfComponents([]));
    dispatch(handleInspectionModal(newModalData));
  };
};

export const minimizeInspectionModal = () => {
  return (dispatch, getState) => {
    const {
      inspectionReducer: { inspectionModalData },
    } = getState();
    const newModalData = {
      ...inspectionModalData,
      isMinimized: true,
      separatePage: false,
    };

    dispatch(handleInspectionModal(newModalData));
  };
};

export const maximizeInspectionModal = () => {
  return (dispatch, getState) => {
    const {
      inspectionReducer: { inspectionModalData },
    } = getState();
    const newModalData = {
      ...inspectionModalData,
      isMinimized: false,
      separatePage: false,
    };

    dispatch(handleInspectionModal(newModalData));
  };
};

export const openAsSeparatePageInspectionModal = () => {
  return (dispatch, getState) => {
    const {
      inspectionReducer: { inspectionModalData },
    } = getState();
    const newModalData = {
      ...inspectionModalData,
      isMinimized: false,
      separatePage: true,
    };

    dispatch(handleInspectionModal(newModalData));
  };
};

// inspection modal handlers end

export const getShareLinks = inspection_id => {
  return async dispatch => {
    try {
      const res = await inspectionApi.getShareLinks([{ inspection_id }]);
      const { Data } = res.data;
      dispatch(fetchShareLinksSuccess(Data.ShareLinks || []));
    } catch (e) {
      console.error(e);
    }
  };
};

export const generateShareLink = inspection_id => {
  return async dispatch => {
    try {
      const res = await inspectionApi.generateShareLink({ InspectionID: inspection_id });
      const { Data } = res.data;
      if (Data.ShareLink) dispatch(appendShareLink(Data.ShareLink));
    } catch (e) {
      console.error(e);
    }
  };
};

export const toggleShareLink = link_id => {
  return async (dispatch, getState) => {
    const {
      inspectionReducer: { shareLinks },
    } = getState();
    try {
      const res = await inspectionApi.toggleShareLink({ ID: link_id });
      const { Data } = res.data;
      if (Data.ShareLink) {
        let newShareLinks = shareLinks.map(link => (link_id === link.ID ? { ...link, Active: Data.ShareLink.Active } : link));
        dispatch(fetchShareLinksSuccess(newShareLinks));
      }
    } catch (e) {
      console.error(e);
    }
  };
};

export const deleteShareLink = link_id => {
  return async (dispatch, getState) => {
    const {
      inspectionReducer: { shareLinks },
    } = getState();
    try {
      await inspectionApi.deleteShareLink({ ID: link_id });
      dispatch(
        toggleDeleteShareModal({
          isOpen: false,
        })
      );
      const foundIndex = shareLinks.findIndex(link => link_id === link.ID);
      if (foundIndex > -1) {
        let newShareLinks = [...shareLinks];
        newShareLinks.splice(foundIndex, 1);
        dispatch(fetchShareLinksSuccess(newShareLinks));
      }
    } catch (e) {
      console.error(e);
    }
  };
};

export const setCurrentProjectId = projectId => {
  return async dispatch => {
    dispatch(setProjectID(projectId));
  };
};

export const updateInspectionDetails = data => {
  return async (dispatch, getState) => {
    const {
      inspectionReducer: { inspectionID },
    } = getState();
    if (!inspectionID) return;
    const res = await inspectionApi.updateInspectionDetails({ InspectionID: inspectionID, ...data });
    const { Data } = res.data;
    if (Data) dispatch(setInspectionDetails(Data));
  };
};

export const createNewComponent = data => {
  return async (dispatch, getState) => {
    const {
      userReducer,
      inspectionReducer: { inspectionDetails },
    } = getState();
    try {
      if (Helpers.isGuestUser(userReducer)) {
        const newId = 'temp_measure_' + Date.now();

        dispatch(updateComponentTempSuccess({ ...data, ComponentID: data.ID }, 'delete'));
        dispatch(appendTempComponent({ ...DefaultComponent(inspectionDetails.CameraPosition), ...data, ID: newId }));
        dispatch(selectDefect({ defect: { ...DefaultComponent(inspectionDetails.CameraPosition), ...data, ID: newId }, comments: [] }));
      } else {
        dispatch(saveComponentStart());
        const res = await inspectionApi.createComponent({ ...data });
        const Data = get(res, 'data.Data');

        if (Data) {
          const normalizedComponent = { ...Data, Drawings: [] };

          await dispatch(updateComponentTempSuccess({ ...data, ComponentID: data.ID }, 'delete'));
          await dispatch(appendComponent({ ...normalizedComponent }));
          await dispatch(selectDefect({ defect: { ...normalizedComponent }, comments: [] }));
          dispatch(saveComponentSuccess());
        } else {
          dispatch(saveComponentFailure('Unable to save the component'));
        }
      }
    } catch (e) {
      dispatch(saveComponentFailure('Unable to save the component'));
      console.error(e);
    }
  };
};

// FETCH SUGGESTIONS

export const getDefectPropertyNames = (value, callback) => {
  return async (dispatch, getState) => {
    const {
      inspectionReducer: { projectID },
    } = getState();
    if (!projectID) return;
    const res = await inspectionApi.getDefectPropertyNames({ ProjectID: projectID, SearchText: value });
    const { Data } = res.data;

    if (Data?.PropertyNames) {
      dispatch(setDefectLabelSuggestions(Data.PropertyNames));
      callback && callback(Data.PropertyNames);
    }
  };
};

export const getComponentPropertyNames = (value, callback) => {
  return async (dispatch, getState) => {
    const {
      inspectionReducer: { projectID },
    } = getState();
    if (!projectID) return;
    const res = await inspectionApi.getComponentPropertyNames({ ProjectID: projectID, SearchText: value });
    const { Data } = res.data;
    if (Data && Data.PropertyNames) {
      dispatch(setComponentLabelSuggestions(Data.PropertyNames));
      callback && callback(Data.PropertyNames);
    }
  };
};

export const getNDTMeasurementPropertyNames = (value, callback) => {
  return async (dispatch, getState) => {
    const {
      inspectionReducer: { projectID },
    } = getState();
    if (!projectID) return;
    const res = await ndtActions.getNDTMeasurementPropertyNames({ ProjectID: projectID, SearchText: value });
    const { Data } = res.data;
    if (Data && Data.PropertyNames) {
      dispatch(setNDTMeasurementLabelSuggestions(Data.PropertyNames));
      callback && callback(Data.PropertyNames);
    }
  };
};

export const getNDTMeasurementPointPropertyNames = (value, callback) => {
  return async (dispatch, getState) => {
    const {
      inspectionReducer: { projectID },
    } = getState();
    if (!projectID) return;
    const res = await ndtActions.getNDTMeasurementPointPropertyNames({ ProjectID: projectID, SearchText: value });
    const { Data } = res.data;
    if (Data && Data.PropertyNames) {
      dispatch(setNDTMeasurementPointLabelSuggestions(Data.PropertyNames));
      callback && callback(Data.PropertyNames);
    }
  };
};

export const getNotificationPropertyNames = (value, callback) => {
  return async (dispatch, getState) => {
    const {
      inspectionReducer: { projectID },
    } = getState();
    if (!projectID) return;
    const res = await notificationApi.getNotificationCustomPropertyNames({ ProjectID: projectID, SearchText: value });
    const { Data } = res.data;
    if (Data && Data.PropertyNames) {
      dispatch(setNotificationLabelSuggestions(Data.PropertyNames));
      callback && callback(Data.PropertyNames);
    }
  };
};

export const getWorkorderPropertyNames = (value, callback) => {
  return async (dispatch, getState) => {
    const {
      inspectionReducer: { projectID },
    } = getState();
    if (!projectID) return;
    const res = await workOrdersApi.getWorkorderCustomPropertyNames({ ProjectID: projectID, SearchText: value });
    const { Data } = res.data;
    if (Data && Data.PropertyNames) {
      dispatch(setWorkorderLabelSuggestions(Data.PropertyNames));
      callback && callback(Data.PropertyNames);
    }
  };
};

// COMPONENT
export const getParentAssetSuggestions = (SearchText, inspId) => {
  return async (dispatch, getState) => {
    const {
      inspectionReducer: { inspectionID: InspectionID },
    } = getState();
    const res = await inspectionApi.getComponentAssetSuggestions({ SearchText, InspectionID: inspId || InspectionID });
    const { Data } = res.data;
    if (Data && Data.Items) dispatch(setParentAssetSuggestions(Data.Items));
  };
};

export const getComponentTypeSuggestions = (SearchText, inspId) => {
  return async (dispatch, getState) => {
    const {
      inspectionReducer: { inspectionID: InspectionID },
    } = getState();

    const res = await inspectionApi.getComponentTypeSuggestions({ SearchText, InspectionID: inspId || InspectionID });
    const { Data } = res.data;
    if (Data && Data.Items) dispatch(setComponentTypeSuggestions(filter(Data.Items, item => item)));
  };
};

export const getComponentSubTypeSuggestions = SearchText => {
  return async (dispatch, getState) => {
    const {
      inspectionReducer: { inspectionID: InspectionID },
    } = getState();
    const res = await inspectionApi.getComponentSubTypeSuggestions({ SearchText, InspectionID });
    const { Data } = res.data;
    if (Data && Data.Items) dispatch(setComponentSubTypeSuggestions(Data.Items));
  };
};

export const getMaterialSuggestions = SearchText => {
  return async (dispatch, getState) => {
    const {
      inspectionReducer: { inspectionID: InspectionID },
    } = getState();
    const res = await inspectionApi.getComponentMaterialSuggestions({ SearchText, InspectionID });
    const { Data } = res.data;
    if (Data && Data.Items) dispatch(setMaterialSuggestions(Data.Items));
  };
};

export const getManufacturerSuggestions = (SearchText, inspId) => {
  return async (dispatch, getState) => {
    const {
      inspectionReducer: { inspectionID: InspectionID },
    } = getState();
    const res = await inspectionApi.getComponentManufacturerSuggestions({ SearchText, InspectionID: inspId || InspectionID });
    const { Data } = res.data;
    if (Data && Data.Items) dispatch(setManufacturerSuggestions(Data.Items));
  };
};

export const getLocationSuggestions = (SearchText, inspId) => {
  return async (dispatch, getState) => {
    const {
      inspectionReducer: { inspectionID: InspectionID },
    } = getState();
    const res = await inspectionApi.getComponentLocationSuggestions({ SearchText, InspectionID: inspId || InspectionID });
    const { Data } = res.data;
    if (Data && Data.Items) dispatch(setLocationSuggestions(Data.Items));
  };
};

export const getDefectTypeSuggestions = (SearchText, inspId) => {
  return async (dispatch, getState) => {
    const {
      inspectionReducer: { inspectionID: InspectionID },
    } = getState();
    const res = await inspectionApi.getDefectTypeSuggestions({ SearchText, InspectionID: inspId || InspectionID });
    const { Data } = res.data;
    if (Data && Data.Items) dispatch(setDefectTypeSuggestions(Data.Items));
  };
};

export const getComponentLinkedDefects = (ComponentID, callback) => {
  return async () => {
    try {
      const res = await inspectionApi.getComponentLinkedDefects({ ComponentID });
      const { Data } = res.data;

      if (callback) {
        callback(Data && Data.Defects);
      }
    } catch (err) {}
  };
};

export const deleteComponentFile = (FileID, ComponentID, callback, CategoryID) => {
  return async dispatch => {
    //SourceID since the DMS route need FileID to be SourceID
    await inspectionApi.unlinkComponentFile({ FileID, ComponentID, SourceID: FileID, CategoryID });
    dispatch(getComponentDMSFilesUploaded(ComponentID));

    if (callback && typeof callback === 'function') {
      callback();
    }
  };
};

export const deleteMeasurementRelatedFile = (FileID, ID, severity) => {
  return async dispatch => {
    await inspectionApi.unlinkMeasurementFile({ FileID, ID });
    dispatch(getMeasurementFilesUploaded(ID, severity));
  };
};

export const handleQuickDefect = (drawings, projectId, inspectionID, file, location, cameraPosition, imagePosition, cameraHelper, depth, withContextualization = true) => {
  return async (dispatch, getState) => {
    const {
      inspectionReducer: { inspectionDetails },
    } = getState();
    //temp disabled until workflow is resolved
    // if (inspectionID)
    //   await workflowActions.selectWorkflowImages({
    //     InspectionID: inspectionID,
    //     SelectionType: workflowConstants.imageSelectionTypes.all,
    //     SelectionStatus: workflowConstants.imageSelectionStatus.deselect,
    //   });
    // await workflowActions.selectWorkflowImages({
    //   InspectionID: inspectionID,
    //   SelectionType: workflowConstants.imageSelectionTypes.single,
    //   SelectionStatus: workflowConstants.imageSelectionStatus.select,
    //   FileID: file[workflowConstants.formConstants.fields.fileID],
    // });
    // Helpers.goTo(routesConstants.routes.protectedRoutes.startWorkflow.fullPath, [{ project_id: projectId }, { inspection_id: inspectionID }], { drawings, obj: file, location, cameraPosition });

    const obj = {
      ...DefaultDefect({ coordinates: values(cameraPosition) }),
      SystemType: measurementTypes.defect,
      ComponentID: inspectionDetails.DefaultComponent,
      [formConstants.fields.mainType]: observationsTypes.defect,
      Geometry: {
        type: defectType.point,
        coordinates: location ? [[location.X, location.Y, location.Z]] : [[0, 0, 0]],
      },
      InspectionID: inspectionID,
    };

    if (withContextualization) {
      if (!imagePosition || !imagePosition.left || !imagePosition.top) throw new Error('cannot find point on the point cloud');
      const point = await get3DPointFrom2D(
        { InspectionID: inspectionID, FileID: file[workflowConstants.formConstants.fields.id], PointPosition: { X: imagePosition.left, Y: imagePosition.top, Z: 1 }, Depth: depth },
        cameraHelper
      );
      if (!point) throw new Error('cannot find point on the point cloud');

      obj.Geometry.coordinates = [[point.x, point.y, point.z]];
    }

    dispatch(
      createNewDefect(obj, async data => {
        await startWorkflowApi.saveDrawing({
          FileID: file[workflowConstants.formConstants.fields.id],
          DefectID: data[formConstants.fields.id],
          [formConstants.fields.drawings]: JSON.stringify(drawings),
        });

        Helpers.goTo(routesConstants.routes.protectedRoutes.inspections.fullPath, [
          { project_id: projectId },
          { inspection_id: inspectionID },
          { type: modules.defects },
          { selected_item: data[formConstants.fields.id] },
        ]);
      })
    );
  };
};

export const handleEditDrawing = (projectId, inspectionID, file, preSelectedDefect) => {
  return async () => {
    if (inspectionID)
      await workflowActions.selectWorkflowImages({
        InspectionID: inspectionID,
        SelectionType: workflowConstants.imageSelectionTypes.all,
        SelectionStatus: workflowConstants.imageSelectionStatus.deselect,
      });
    await workflowActions.selectWorkflowImages({
      InspectionID: inspectionID,
      SelectionType: workflowConstants.imageSelectionTypes.single,
      SelectionStatus: workflowConstants.imageSelectionStatus.select,
      FileID: file[workflowConstants.formConstants.fields.fileID],
    });

    Helpers.goTo(routesConstants.routes.protectedRoutes.startWorkflow.fullPath, [{ project_id: projectId }, { inspection_id: inspectionID }], { preSelectedDefect });
  };
};

export const duplicateComponent = (ID, Name, callback) => {
  return async dispatch => {
    const res = await inspectionApi.duplicateComponent({ ID, Name });
    const { Data } = res.data;
    if (!isEmpty(Data)) {
      const normalizedComponent = { ...Data, Drawings: [] };
      dispatch(appendComponent(normalizedComponent));

      //tmp fix for drawings screen - should be the same reducer
      dispatch(handleAddNewComponent(normalizedComponent));

      callback && callback(Data[componentConstants.fields.id]);

      setTimeout(() => {
        Helpers.scrollIntoView('items-table-renderer', null, 1000, `id-${Data[componentConstants.fields.id]}`);

        //tmp fix for drawings screen
        Helpers.scrollIntoView('items-renderer__container', null, 1000, `--${Data[componentConstants.fields.id]}`);
      }, 100);
    }
  };
};

export const setCurrentImage = (image, index, moveCamera = true) => {
  return async dispatch => {
    dispatch(setCurrentImageData({ image, index }));
  };
};

export const getDefectRelatedImages = (inspectionID, point, onSuccess, Type) => {
  return async dispatch => {
    try {
      dispatch(setPointImagesLoading(true));
      dispatch(setPointImages([]));
      const imageItems = await fetchImagesRelatedToPoint({ InspectionID: inspectionID, Type, PointPosition: { X: point[0], Y: point[1], Z: point[2] } });
      dispatch(setPointImages(imageItems));
      onSuccess(imageItems);
      dispatch(setPointImagesLoading(false));
    } catch (e) {
      console.error(e);
      dispatch(setPointImagesLoading(false));
    }
  };
};

export const getObservationTypes = () => {
  return async dispatch => {
    try {
      const res = await inspectionApi.getObservationTypes();
      dispatch(handleSetObservationTypes(res.data.Data));
    } catch (e) {
      console.error(e);
    }
  };
};

export const getObservationNotifications = defect_id => {
  return async dispatch => {
    try {
      dispatch(setObservationNotificationsLoading(true));
      const res = await inspectionApi.getObservationNotifications([{ defect_id }]);
      dispatch(handleSetObservationNotifications(res.data.Data));
      dispatch(setObservationNotificationsLoading(false));
    } catch (e) {
      console.error(e);
      dispatch(setObservationNotificationsLoading(false));
    }
  };
};

export const getNDTMeasurementNotifications = measurement_id => {
  return async dispatch => {
    try {
      const res = await inspectionApi.getNdtMeasurementNotifications([{ measurement_id }]);
      dispatch(handleSetNDTMeasurementNotifications(res.data.Data));
    } catch (e) {
      console.error(e);
    }
  };
};

export const updateObservationComponent = (DefectID, ComponentID) => {
  return async () => {
    try {
      await inspectionApi.updateObservationComponent({ DefectID, ComponentID });
    } catch (e) {
      console.error(e);
    }
  };
};

export const unlinkObservationComponent = DefectID => {
  return async () => {
    try {
      await inspectionApi.unlinkObservationComponent({ DefectID });
    } catch (e) {
      console.error(e);
    }
  };
};

export const getComponentHistory = (params, history, paging, callback) => {
  return async () => {
    try {
      callback({ isLoading: true });
      const res = await inspectionApi.getComponentHistory(params);
      const data = res?.data?.Data;

      if (data?.history) {
        callback({
          isLoading: false,
          history: [...history, ...data.history],
          paging: { ...paging, HasNext: data.hasNext || false, LastSeen: data.lastSeen, TotalNumber: data.totalItems },
        });
      } else {
        callback({ isLoading: false });
      }
    } catch (e) {
      callback({ isLoading: false });
      console.error(e);
    }
  };
};

export const getDefectHistory = (params, history, paging, callback) => {
  return async () => {
    try {
      callback({ isLoading: true });
      const res = await inspectionApi.getDefectHistory(params);
      const data = res?.data?.Data;

      if (data?.history) {
        callback({
          isLoading: false,
          history: [...history, ...data.history],
          paging: { ...paging, HasNext: data.hasNext || false, LastSeen: data.lastSeen, TotalNumber: data.totalItems },
        });
      } else {
        callback({ isLoading: false });
      }
    } catch (e) {
      callback({ isLoading: false });
      console.error(e);
    }
  };
};

export const getMeasurementHistory = (params, history, paging, callback) => {
  return async () => {
    try {
      callback({ isLoading: true });
      const res = await inspectionApi.getMeasurementHistory(params);
      const data = res?.data?.Data;

      if (data?.history) {
        callback({
          isLoading: false,
          history: [...history, ...data.history],
          paging: { ...paging, HasNext: data.hasNext || false, LastSeen: data.lastSeen, TotalNumber: data.totalItems },
        });
      } else {
        callback({ isLoading: false });
      }
    } catch (e) {
      callback({ isLoading: false });
      console.error(e);
    }
  };
};

export const getNDTHistory = (params, history, paging, callback) => {
  return async () => {
    try {
      callback({ isLoading: true });
      const res = await inspectionApi.getNDTHistory(params);
      const data = res?.data?.Data;

      if (data?.history) {
        callback({
          isLoading: false,
          history: [...history, ...data.history],
          paging: { ...paging, HasNext: data.hasNext || false, LastSeen: data.lastSeen, TotalNumber: data.totalItems },
        });
      } else {
        callback({ isLoading: false });
      }
    } catch (e) {
      callback({ isLoading: false });
      console.error(e);
    }
  };
};

export const getPanoramicImages = (inspection_id, additionalPayload = {}, callback = null) => {
  return async dispatch => {
    try {
      if (typeof cancelTokens.panoramicImages != typeof undefined) {
        cancelTokens.panoramicImages.cancel('Operation canceled due to new request.');
      }

      //Save the cancel token for the current request
      cancelTokens.panoramicImages = axios.CancelToken.source();

      const converted_inspectionID = parseInt(inspection_id);
      const res = await inspectionApi.getPanoramicImages({ InspectionID: converted_inspectionID, ...additionalPayload }, { cancelToken: cancelTokens.panoramicImages.token, skipErrorModal: true });
      const { Data } = res.data;
      const panoramicImages = { ImageInfos: Data && !isEmpty(Data.Images) ? Data.Images : [] };
      dispatch(setPanoramicImages(panoramicImages));
      if (callback !== null) {
        callback(Data);
      }
    } catch (e) {
      console.error(e);
    }
  };
};

export const linkDMSFileFromComponent = (componentId, files, callback) => {
  return async () => {
    try {
      await inspectionApi.linkDMSFileFromComponent({ ComponentID: componentId, Files: files });
      callback && callback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const unlinkDMSFileFromComponent = (sourceId, componentId, categoryID, callback) => {
  return async () => {
    try {
      await inspectionApi.unlinkDMSFileFromComponent({ SourceID: sourceId, ComponentID: componentId, CategoryID: categoryID });
      callback && callback();
    } catch (e) {
      console.error(e);
    }
  };
};

// component comments
export const fetchComponentComments = (params, callback) => {
  return async () => {
    try {
      callback({
        commentsLoading: true,
      });
      const res = await inspectionApi.fetchComponentComments([params]);
      const { Data } = res?.data;
      callback({
        commentsList: Data,
        commentsLoading: false,
      });
    } catch (e) {
      callback({
        commentsLoading: false,
      });
      console.error(e);
    }
  };
};

export const addComponentComment = (filters, dataCallback, loadingCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      await inspectionApi.addComponentComment(filters);
      dataCallback && typeof dataCallback === 'function' && dataCallback();
      isLoadingCallbackValid && loadingCallback(false);
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
    }
  };
};

export const deleteComponentComment = (filters, dataCallback, loadingCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      await inspectionApi.deleteComponentComment(filters);
      dataCallback && typeof dataCallback === 'function' && dataCallback();
      isLoadingCallbackValid && loadingCallback(false);
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
    }
  };
};

export const fetchCommentUsersAndTeams = (searchText = '', dataCallback, loadingCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      const res = await inspectionApi.fetchCommentUsersAndTeams({ SearchText: searchText });
      const { Data } = res?.data;
      const { Users, Teams } = Data;
      // added dropdownType so it can be used for grouping
      // eslint-disable-next-line
      Users.map(user => ((user[commentFields.dropdownType] = commentTagTypes.users), (user[commentFields.type] = commentTagTypes.user)));
      // eslint-disable-next-line
      Teams.map(team => ((team[commentFields.dropdownType] = commentTagTypes.teams), (team[commentFields.type] = commentTagTypes.team)));
      dataCallback && typeof dataCallback === 'function' && dataCallback(Users, Teams);
      isLoadingCallbackValid && loadingCallback(false);
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
    }
  };
};

// observation/defect comments
export const fetchObservationComments = (params, callback) => {
  return async () => {
    try {
      callback({
        commentsLoading: true,
      });
      const res = await inspectionApi.fetchObservationComments([params]);
      const { Data } = res?.data;
      callback({
        commentsList: Data,
        commentsLoading: false,
      });
    } catch (e) {
      callback({
        commentsLoading: false,
      });
      console.error(e);
    }
  };
};

export const addObservationComment = (filters, dataCallback, loadingCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      await inspectionApi.addObservationComment(filters);
      dataCallback && typeof dataCallback === 'function' && dataCallback();
      isLoadingCallbackValid && loadingCallback(false);
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
    }
  };
};

export const deleteObservationComment = (filters, dataCallback, loadingCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      await inspectionApi.deleteObservationComment(filters);
      dataCallback && typeof dataCallback === 'function' && dataCallback();
      isLoadingCallbackValid && loadingCallback(false);
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
    }
  };
};

export const fetchSubTypesSuggestions = (SearchText, inspectionId) => {
  return async (dispatch, getState) => {
    const {
      inspectionReducer: { inspectionID: InspectionID },
    } = getState();
    const res = await inspectionApi.getObservationSubTypesSuggestions({ SearchText, InspectionID: inspectionId || InspectionID });
    const { Data } = res.data;
    if (Data && Data.Items) dispatch(setObservationSubTypesSuggestions(Data.Items));
  };
};

export const updateObservationProperties = (data, callbackSuccess) => {
  return async dispatch => {
    try {
      if (isEmpty(data)) {
        callbackSuccess && typeof callbackSuccess === 'function' && callbackSuccess();
        return;
      } else {
        const res = await inspectionApi.addDefectProperty({ Properties: data });
        const { Properties } = res.data.Data;
        dispatch(replaceAddedObservationCustomProperty(Properties));
        callbackSuccess && typeof callbackSuccess === 'function' && callbackSuccess();
      }
    } catch (e) {
      console.error(e);
    }
  };
};

export const fetchObservationWorkOrders = DefectID => {
  return async dispatch => {
    try {
      const res = await inspectionApi.fetchObservationWorkOrders({ DefectID });
      const { Data } = res.data;
      dispatch(setObservationWorkOrders(Data));
    } catch (e) {
      console.error(e);
    }
  };
};

export const fetchWorkOrdersForObservations = (DefectID, InspectionID, SearchText, pagingFilters, SearchByExternalID, callback, loadingCallback) => {
  return async () => {
    try {
      loadingCallback && typeof loadingCallback === 'function' && loadingCallback(true);
      const res = await inspectionApi.fetchWorkOrdersForObservations({ DefectID, InspectionID, SearchText, SearchByExternalID, ...pagingFilters });
      const { Data } = res.data;
      const { DefectWorkOrderItem, ...newPagingFilters } = Data;
      callback && typeof callback === 'function' && callback(DefectWorkOrderItem, newPagingFilters);
      loadingCallback && typeof loadingCallback === 'function' && loadingCallback(false);
    } catch (e) {
      loadingCallback && typeof loadingCallback === 'function' && loadingCallback(false);
      console.error(e);
    }
  };
};

export const linkWorkOrderToObservation = (DefectID, WorkOrderID, callback) => {
  return async () => {
    try {
      inspectionApi.linkWorkOrderToObservation({ DefectID, WorkOrderID });
      callback && typeof callback === 'function' && callback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const unlinkWorkOrderFromObservation = (DefectID, WorkOrderID, callback) => {
  return async () => {
    try {
      inspectionApi.unlinkWorkOrderFromObservation({ DefectID, WorkOrderID });
      callback && typeof callback === 'function' && callback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const updateComponentProperties = (data, callbackSuccess) => {
  return async dispatch => {
    try {
      if (isEmpty(data)) {
        // call callback just to reset progress
        callbackSuccess && typeof callbackSuccess === 'function' && callbackSuccess();
        return;
      }
      const res = await inspectionApi.updateComponentPropetries({ Properties: data });
      const { Properties } = res.data.Data;
      dispatch(replaceComponentCustomProperty(Properties));
      callbackSuccess && typeof callbackSuccess === 'function' && callbackSuccess();
    } catch (e) {
      console.error(e);
    }
  };
};
