export default {
  GET_PERMIT_TEMPLATE_DETAILS: '/project/permit/template/details',
  CREATE_PERMIT_TEMPLATE: '/project/permit/template/create',
  UPDATE_PERMIT_TEMPLATE: '/project/permit/template/update',
  GET_PERMIT_TEMPLATES: '/project/permit/template/list',
  GET_PERMIT_TEMPLATES_FILTERS: '/project/permit/templates/filters',
  DELETE_PERMIT_TEMPLATE: '/project/permit/template/delete',
  GET_PERMIT_TEMPLATE_SECTIONS: '/project/permit/template/section/list',
  GET_PERMIT_TEMPLATE_FILES: '/project/permit/template/files/list',
  DELETE_PERMIT_TEMPLATE_FILE: '/project/permit/template/file/unlink',
  ADD_PERMIT_TEMPLATE_SECTION: '/project/permit/template/section/add',
  DELETE_PERMIT_TEMPLATE_SECTION: '/project/permit/template/section/delete',
  ORDER_PERMIT_TEMPLATE_SECTIONS: '/project/permit/template/section/order',
  UPDATE_PERMIT_TEMPLATE_SECTION: '/project/permit/template/section/update',
  UPDATE_PERMIT_TEMPLATE_SECTION_QUESTION: '/project/permit/template/section/question/update',
  UPDATE_PERMIT_TEMPLATE_SECTION_QUESTION_OPTION: '/project/permit/template/section/question/option/update',
  ADD_PERMIT_TEMPLATE_SECTION_QUESTION: '/project/permit/template/section/question/add',
  DELETE_PERMIT_TEMPLATE_SECTION_QUESTION: '/project/permit/template/section/question/delete',
  ORDER_PERMIT_TEMPLATE_SECTION_QUESTIONS: '/project/permit/template/section/question/order',
  ADD_PERMIT_TEMPLATE_SECTION_QUESTION_OPTION: '/project/permit/template/section/question/option/add',
  DELETE_PERMIT_TEMPLATE_SECTION_QUESTION_OPTION: '/project/permit/template/section/question/option/delete',
  PERMIT_TEMPLATE_CHANGE_STATUS: '/project/permit/template/status',
  COPY_PERMIT_TEMPLATE: '/project/permit/template/copy',
  ADD_PERMIT_AREA: '/project/permit/work_area/add',
  UPDATE_PERMIT_AREA: '/project/permit/work_area/update',
  DELETE_PERMIT_AREA: '/project/permit/work_area/delete',
  ADD_PERMIT_COMMENT: '/project/permit/comment/add',
};
