import keyboxApi from '../../../api/keybox/actions';

import { fields, keyFields } from '../constants/constants';

export const fetchKeyboxes = (filters, incomingKeyboxes, sucessCallback, loadMore = false) => {
  return async () => {
    try {
      sucessCallback({ isLoading: true });
      const res = await keyboxApi.fetchKeyboxes(filters);
      const data = res?.data?.Data;
      const newKeyboxes = data.Keyboxes || [];
      sucessCallback({
        isLoading: false,
        keyboxes: loadMore ? [...incomingKeyboxes, ...newKeyboxes] : newKeyboxes,
        filters: { ...filters, HasNext: data.HasNext || false, LastSeen: data.LastSeen, TotalItems: data.TotalItems },
      });
    } catch (e) {
      sucessCallback({ isLoading: false });
    }
  };
};

export const createKeybox = (data, sucessCallback, erroCallback) => {
  return async () => {
    try {
      sucessCallback({ isLoading: true });
      const res = await keyboxApi.createKeybox(data);
      const resData = res?.data?.Data;

      sucessCallback({ data: resData, isLoading: false });
    } catch (e) {
      erroCallback({ isLoading: false });
    }
  };
};

export const getKeyboxDetails = (keybox_id, sucessCallback, erroCallback) => {
  return async () => {
    try {
      sucessCallback({ isLoading: true });
      const res = await keyboxApi.getKeyboxDetails([{ keybox_id }]);

      const resData = res?.data?.Data;
      sucessCallback({ keybox: resData, isLoading: false });
    } catch (e) {
      erroCallback({ isLoading: false });
    }
  };
};

export const deleteKeybox = (KeyboxID, sucessCallback, erroCallback) => {
  return async () => {
    try {
      sucessCallback({ isLoading: true });
      await keyboxApi.deleteKeybox({ KeyboxID });

      sucessCallback({ keybox: null, isLoading: false });
    } catch (e) {
      erroCallback({ isLoading: false });
    }
  };
};

export const editKeyboxDetails = (values, sucessCallback, erroCallback) => {
  return async () => {
    try {
      const res = await keyboxApi.editKeyboxDetails({ ...values, KeyboxID: values[fields.id] });
      sucessCallback(res.data.Data);
    } catch (e) {
      erroCallback && erroCallback();
    }
  };
};

export const deleteKey = (KeyboxID, KeyID, sucessCallback, erroCallback) => {
  return async () => {
    try {
      const res = await keyboxApi.deleteKey({ KeyboxID, KeyID });
      sucessCallback(res.data.Data);
    } catch (e) {
      erroCallback && erroCallback();
    }
  };
};

export const addKey = (KeyboxID, key, sucessCallback, erroCallback) => {
  return async () => {
    try {
      const res = await keyboxApi.addKey({ KeyboxID, ...key });
      sucessCallback(res.data.Data);
    } catch (e) {
      erroCallback && erroCallback();
    }
  };
};

export const issueKey = (key, contractor, sucessCallback, erroCallback) => {
  return async () => {
    try {
      const res = await keyboxApi.issueKey({ KeyID: key[keyFields.id], ContractorID: contractor.ID });
      sucessCallback(res.data.Data);
    } catch (e) {
      erroCallback && erroCallback();
    }
  };
};

export const returnKey = (KeyID, sucessCallback, erroCallback) => {
  return async () => {
    try {
      const res = await keyboxApi.returnKey({ KeyID });
      sucessCallback(res.data.Data);
    } catch (e) {
      erroCallback && erroCallback();
    }
  };
};

export const updateKeyName = ({ [fields.id]: KeyID, [fields.name]: Name }, sucessCallback, erroCallback) => {
  return async () => {
    try {
      const res = await keyboxApi.updateKeyName({ KeyID, Name });
      sucessCallback(res.data.Data);
    } catch (e) {
      erroCallback && erroCallback();
    }
  };
};

export const fetchContractors = (SearchText, params, sucessCallback, erroCallback) => {
  return async () => {
    try {
      const res = await keyboxApi.fetchContractors({ SearchText, ...params });

      sucessCallback(res.data.Data);
    } catch (e) {
      erroCallback && erroCallback();
    }
  };
};
