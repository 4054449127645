import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { ReactComponent as UploadSvg } from '../../assets/upload-component-asset.svg';

import { isEmpty } from 'lodash';

import Helpers from '../../../../common/helpers';

import { setUploadItems, uploadAsset, setSingleUploadItem } from '../../../upload/actions/upload-actions';

import uploadConstants from '../../../upload/constants/constants';

import SmallDropdown from '../../../../common/small-dropdown/components/small-dropdown';

import { measurementTypes } from '../../constants/constants';
import '../../styles/component-assets.scss';
import ImageWithDrawing from '../../../../common/image-components/components/image-with-drawing';
import Icon from '../../../../common/icon/components/icon';
import { fields } from '../../../document-management/constants/constants';
import { getIconForFileType } from '../../../document-management/actions/table-formatting';

class ComponentFieldUpload extends Component {
  state = {
    files: [],
  };

  renderFiles = () => {
    const { noFileText, files, imageAction, imageType, actionsMenu } = this.props;
    const openFileAction = (actionsMenu || []).find(action => action.title === 'COMPONENT_ASSETS_DROPDOWN.SETTINGS_MENU.ITEM_1').action || null;

    if (isEmpty(files)) {
      return (
        <div className="empty-container">
          <p className="f-secondary-dark">{noFileText}</p>
        </div>
      );
    }
    return files.map((item, index) => {
      return (
        <div key={index} className="file-container">
          {item.restricted && <div className="dot green"> </div>}
          {item.isImage && item.Drawings && item.Drawings !== '' ? (
            <div
              onClick={() => (imageAction ? imageAction(item, index) : null)}
              key={item.FileID}
              className="image-container image-item"
              title={Helpers.decodeIfStringEncoded(item.FileName)}
              id={item.FileName}
            >
              <ImageWithDrawing disableZoomAndMove={true} className="image-item__image-container" imgClass="image-thumbnail" imageType={imageType} item={item} drawings={item.Drawings} />
            </div>
          ) : (
            <Icon name={getIconForFileType(item[fields.fileType])} size="md" handleHover={false} className="icon-path-stroke" onClick={imageAction ? () => imageAction(item, files, index) : null} />
          )}
          <p key={index} className="f-primary filename light-bold" title={item ? Helpers.decodeIfStringEncoded(item.FileName) : ''} onClick={openFileAction ? () => openFileAction(item, files) : null}>
            {item && Helpers.decodeIfStringEncoded(item.FileName)}
          </p>
          <SmallDropdown
            actionsMenu={(actionsMenu || []).map(actionItem => {
              if (!!actionItem.isDisabled) {
                return { ...actionItem, isDisabled: typeof actionItem.isDisabled === 'function' ? actionItem.isDisabled(item) : actionItem.isDisabled };
              } else {
                return actionItem;
              }
            })}
            getClickAction={(action, index, selfClose) => {
              action(item);
              selfClose();
            }}
            offsetX={-100}
          />
        </div>
      );
    });
  };

  onLocalUpload = (files, defect) => {
    const { uploadAsset, setUploadItems, setSingleUploadItem, inspectionID, projectID, uploadType, restUploadParams } = this.props;

    if (!defect) return;

    const tmp_files = files.map((file, index) => {
      const tmp_file = {
        type: Helpers.getFileType(file.type),
        mimeType: file.type,
        title: '',
        description: '',
        real_name: Helpers.formatFileName(file.name),
      };

      file.timeStamp = Date.now();
      file.real_name = tmp_file.real_name;
      file.uploadType = uploadType || uploadConstants.uploadType.defectFiles;

      let dataParams = { InspectionID: inspectionID, ProjectID: projectID, ...(restUploadParams || {}) };
      if (defect.SystemType === measurementTypes.component) {
        dataParams.ComponentID = defect.ID;
      } else if (defect.SystemType === measurementTypes.measurement) {
        dataParams.MeasurementID = defect.ID;
      } else {
        dataParams.DefectID = defect.ID;
      }

      uploadAsset(file, setSingleUploadItem, index, dataParams);
      return { ...tmp_file, ...file };
    });
    setUploadItems(tmp_files);
  };

  render() {
    const { label, labelClass, id, openUploadModal, className, disabled, files, thumbnailsView, supportedMimeTypes = null, showUploadSvg = true } = this.props;
    const { onLocalUpload } = this;

    if (isEmpty(files)) {
      return null;
    }

    return (
      <div className={`component-assets ${className || ''}`}>
        {label && (
          <div className="label-container">
            <label className={`label-container__label ${labelClass || 'f-primary'}`} htmlFor={id}>
              {label}
            </label>
            {showUploadSvg && (
              <UploadSvg
                onClick={disabled ? null : () => openUploadModal(onLocalUpload, (files && files.length) || 0, supportedMimeTypes)}
                className={`svg-primary-g label-container__upload ${disabled ? 'disabled' : ''}`}
              />
            )}
          </div>
        )}

        <div className={`${thumbnailsView ? 'thumbnail-view' : ''}`}>{this.renderFiles()}</div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  projectID: state.inspectionReducer.projectID,
  inspectionID: state.inspectionReducer.inspectionID,
});

const mapDispatchToProps = dispatch => ({
  uploadAsset: (file, callbackFunction, index, data) => dispatch(uploadAsset(file, data, callbackFunction, index)),
  setUploadItems: assets => dispatch(setUploadItems(assets)),
  setSingleUploadItem: (progress, index) => dispatch(setSingleUploadItem(progress, index)),
});

ComponentFieldUpload.defaultProps = {
  thumbnailsView: false,
  actionsMenu: [],
  showUploadSvg: true,
  openUploadModal: () => null,
};

ComponentFieldUpload.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ComponentFieldUpload);
