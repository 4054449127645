export const tabNames = {
  graph: 'DETAILS',
  measurementReadings: 'ALARMS',
  predictiveAnalyticSettings: 'HISTORY',
};

export const toolbarItems = {
  [tabNames.graph]: {
    name: tabNames.graph,
    label: 'MP.TIME_SERIES_GRAPH_MODAL_TAB.GRAPH',
  },
  [tabNames.measurementReadings]: {
    name: tabNames.measurementReadings,
    label: 'MP.TIME_SERIES_GRAPH_MODAL_TAB.MEASUREMENT_READINGS',
  },
  [tabNames.predictiveAnalyticSettings]: {
    name: tabNames.predictiveAnalyticSettings,
    label: 'MP.TIME_SERIES_GRAPH_MODAL_TAB.PREDICTIVE_ANALYTIC_SETTINGS',
  },
};

export const fields = {
  id: 'ID',
  name: 'Name',
  icon: 'Icon',
  createdAt: 'CreatedAt',
  createdBy: 'CreatedBy',
  createdByUser: 'CreatedByUser',
  toggle: 'toggle',
  alarm: 'alarm',
  returnToDefault: 'returnToDefault',
  searchText: 'SearchText',
  perPage: 'PerPage',
  lastSeen: 'LastSeen',
  sortByColumn: 'SortByColumn',
  sortDirection: 'SortDirection',
  projectID: 'ProjectID',
};

export const formConstants = {
  fields: {
    id: 'ID',
    measurementDate: 'MeasurementDate',
    measuredByID: 'MeasuredByID',
    expectedRange: 'ValuesNumber',
    values: 'Values',
    averageValue: 'AverageValue',
    valuesNumber: 'ValuesNumber',
    measuredBy: 'MeasuredBy',
    editReading: 'editReading',
  },
};

export const filterParams = {
  searchText: 'SearchText',
  lastSeen: 'LastSeen',
  perPage: 'PerPage',
  sortByColumn: 'SortByColumn',
  sortDirection: 'SortDirection',
  totalItems: 'TotalNumber',
  hasNext: 'HasNext',
  measurementPointID: 'MeasurementPointID',
  measurementPointName: 'MeasurementPointName',
};

export const sortDirections = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const defaultFilter = {
  [filterParams.searchText]: '',
  [filterParams.lastSeen]: 0,
  [filterParams.perPage]: 20,
  [filterParams.totalItems]: 0,
  [filterParams.hasNext]: false,
  [filterParams.sortDirection]: sortDirections.DESC,
  [filterParams.sortByColumn]: formConstants.fields.id,
};

export const tableConfig = [
  {
    title: 'MP.TIME_SERIES_GRAPH_MODAL_TAB.MEASUREMENT_READINGS.ID',
    key: formConstants.fields.id,
    type: formConstants.fields.id,
    class: 'id-column',
    isSort: true,
  },
  {
    title: 'MP.TIME_SERIES_GRAPH_MODAL_TAB.MEASUREMENT_READINGS.MEASUREMENT_READING',
    key: formConstants.fields.averageValue,
    type: formConstants.fields.averageValue,
    class: 'measurement-reading-column',
    isSort: true,
  },
  {
    title: 'MP.TIME_SERIES_GRAPH_MODAL_TAB.MEASUREMENT_READINGS.VALUES_PER_READING',
    key: formConstants.fields.valuesNumber,
    type: formConstants.fields.valuesNumber,
    class: 'values-per-reading-column',
    isSort: true,
  },
  {
    title: 'MP.TIME_SERIES_GRAPH_MODAL_TAB.MEASUREMENT_READINGS.MEASUREMENT_DATE',
    key: formConstants.fields.measurementDate,
    type: formConstants.fields.measurementDate,
    class: 'measurement-date',
    isSort: true,
  },
  {
    title: 'MP.TIME_SERIES_GRAPH_MODAL_TAB.MEASUREMENT_READINGS.MEASURED_BY',
    key: formConstants.fields.measuredBy,
    type: formConstants.fields.measuredBy,
    class: 'measured-by-column',
    isSort: true,
  },
  {
    title: '',
    key: formConstants.fields.editReading,
    type: formConstants.fields.editReading,
    class: 'edit-reading-column',
  },
];
