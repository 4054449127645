import routesConstants from './routes';
import { Api } from '../../core/api';
import { API_BASE_URL } from '../../common/constants';

const login = async ({ email, password }) => {
  return await Api.post(`${API_BASE_URL}${routesConstants.LOGIN}`, { email, password }, { needLoader: true });
};

const tokenLogin = async ({ Token }) => {
  return await Api.post(`${API_BASE_URL}${routesConstants.TOKEN_LOGIN}`, { Token }, { needLoader: true, allowGuestUserAction: true });
};

const updateUserSettings = async unit => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_USER_SETTINGS}`, { ...unit }, { needLoader: true });
};

const updateUser360Settings = async unit => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_USER_360_SETTINGS}`, { ...unit }, { needLoader: true });
};

const register = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.REGISTER_COMPANY}`, params, { needLoader: true });
};

const getUnitData = async () => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_UNIT_DATA}`);
};
const authenticateUser = async (email, password) => {
  return await Api.post(`${API_BASE_URL}${routesConstants.AUTHENTICATE}`, { email, password }, { needLoader: true });
};

const changeProfile = async UserRoleID => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CHANGE_PROFILE}`, { UserRoleID });
};

const verifyUserEmail = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.VERIFY_USER_EMAIL}`, params, { skipErrorModal: true });
};

const verifyUserAuthenticator = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.VERIFY_USER_AUTHENTICATOR}`, params, { skipErrorModal: true });
};

const resendEmail = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.RESEND_EMAIL}`, params);
};

export default {
  login,
  tokenLogin,
  register,
  updateUserSettings,
  updateUser360Settings,
  getUnitData,
  authenticateUser,
  changeProfile,
  verifyUserEmail,
  resendEmail,
  verifyUserAuthenticator,
};
