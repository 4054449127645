import actionTypes from '../constants/action-types';
import constants from '../constants/constants';

const usersTableState = {
  usersTableData: null,
  usersTableParams: constants.userDataParams,
  activateModalData: {
    isOpen: false,
  },
  userModalData: {
    isOpen: false,
  },
};

export const usersTableReducer = (state = usersTableState, payload) => {
  switch (payload.type) {
    case actionTypes.HANDLE_ACTIVATE_USER_MODAL:
      return { ...state, activateModalData: payload.data };
    case actionTypes.HANDLE_USER_MODAL:
      return { ...state, userModalData: payload.data };
    case actionTypes.SET_USERS_DATA:
      return { ...state, usersTableData: payload.data };
    case actionTypes.SET_USERS_DATA_TABLE_PARAMS: {
      return { ...state, usersTableParams: payload.data };
    }
    case actionTypes.DELETE_USER:
      return {
        ...state,
        usersTableData: [...state.usersTableData.slice(0, payload.data), ...state.usersTableData.slice(payload.data + 1)],
      };
    case actionTypes.SET_ROLE_LIST_DATA:
      return {
        ...state,
        roleList: payload.data,
      };
    default:
      return state;
  }
};
