export const componentFetchParams = {
  PerPage: Number.MAX_SAFE_INTEGER,
  LastSeen: 0,
  SearchText: '',
  SortByColumn: 'ID',
  SortDirection: 'ASC',
};

export const fields = {
  isolationCertificate: {
    name: 'IsolationCertificateID',
  },
  componentId: {
    name: 'ID',
  },
  component: {
    name: 'ComponentID',
  },
  componentName: {
    name: 'Name',
    critical: 'Critical',
  },
  files: {
    name: 'Files',
  },
  colour: {
    name: 'Colour',
  },
  isolationState: {
    name: 'DesiredState',
    label: 'ISOLATION_CERTIFICATE.ISOLATION_STATE',
    placeholder: 'ISOLATION_CERTIFICATE.ISOLATION_STATE_PLACEHOLDER',
    errorRequired: 'ISOLATION_CERTIFICATE.ISOLATION_STATE_REQUIRED',
    id: 'isolation-desired-state',
    showInDisplay: true,
    maxLength: 100,
    errorMaxLength: 'ISOLATION_CERTIFICATE.MAX_LENGTH_VALIDATE',
  },
  normalPosition: {
    name: 'NormalPosition',
    label: 'ISOLATION_CERTIFICATE.ISOLATION_POSITION',
    placeholder: 'ISOLATION_CERTIFICATE.ISOLATION_POSITION_PLACEHOLDER',
    errorRequired: 'ISOLATION_CERTIFICATE.ISOLATION_POSITION_REQUIRED',
    id: 'isolation-normal-position',
    showInDisplay: true,
    maxLength: 100,
    errorMaxLength: 'ISOLATION_CERTIFICATE.MAX_LENGTH_VALIDATE',
  },
  isolationMethod: {
    name: 'IsolationMethod',
    label: 'ISOLATION_CERTIFICATE.ISOLATION_METHOD',
    placeholder: 'ISOLATION_CERTIFICATE.ISOLATION_METHOD_PLACEHOLDER',
    errorRequired: 'ISOLATION_CERTIFICATE.ISOLATION_METHOD_REQUIRED',
    id: 'isolation-isolation-method',
    showInDisplay: true,
    maxLength: 100,
    errorMaxLength: 'ISOLATION_CERTIFICATE.MAX_LENGTH_VALIDATE',
  },
  order: {
    name: 'DeisolationOrder',
    label: 'ISOLATION_CERTIFICATE.DE_ISOLATION_ORDER',
    placeholder: 'ISOLATION_CERTIFICATE.DE_ISOLATION_ORDER_PLACEHOLDER',
    id: 'de-isolation-order',
    showInDisplay: true,
  },
  isolationOrder: {
    name: 'IsolationOrder',
  },
};

export const defaultValues = {
  [fields.isolationState.name]: 'Desired Isolation State',
  [fields.normalPosition.name]: 'Normal Position',
  [fields.isolationMethod.name]: 'Isolation Method',
};

export const initialValuesProp = 'Components';

export const fileFields = {
  id: 'FileID',
  name: 'FileName',
  thumbnailUrl: 'ThumbnailURL',
  url: 'URL',
};
