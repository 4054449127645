import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { ReactComponent as UploadSvg } from '../../assets/upload-component-asset.svg';

import { isEmpty } from 'lodash';

import Helpers from '../../../../common/helpers';

import { setUploadItems, uploadAsset, setSingleUploadItem } from '../../../upload/actions/upload-actions';

import uploadConstants from '../../../upload/constants/constants';

import SmallDropdown from '../../../../common/small-dropdown/components/small-dropdown';

import CircleLoader from '../../../../common/circle-loader/components/circle-loader';
import BasicImage from '../../../../common/image-components/components/basic-image';

import '../../styles/component-assets.scss';
import '../../styles/thumbnail-assets.scss';

class MeasurementFieldUpload extends Component {
  state = {
    files: [],
  };

  renderFiles = () => {
    const { noFileText, files, imageAction, imageType, preview, uploadProgressStyle, actionsMenu } = this.props;

    if (isEmpty(files)) {
      return (
        <div className="empty-container">
          <p className="f-primary filename">{noFileText}</p>
        </div>
      );
    }
    return files.map((item, index) => {
      const isUploading = item.progress > 0 && item.progress < 100;
      if (preview === 'image-thumbnail') {
        return (
          <div key={index} className="file-container">
            <div className="image-container" onClick={imageAction ? () => imageAction(item, files, index) : null}>
              <BasicImage
                src={item && item.src && item.src.getImageSize(imageType, item)}
                customImageComponent={() => <label style={{ backgroundImage: `url('${item && item.src && item.src.getImageSize(imageType, item)}')` }} />}
              />
            </div>

            {isUploading && <CircleLoader percent={item.progress} {...uploadProgressStyle} strokeWidth={20} />}
            {!isUploading && (
              <SmallDropdown
                actionsMenu={actionsMenu}
                getClickAction={(action, index, selfClose) => {
                  action(item);
                  selfClose();
                }}
                offsetX={-100}
              />
            )}
          </div>
        );
      } else {
        return (
          <div key={index} className="file-container">
            <div className="image-container" onClick={imageAction ? () => imageAction(item, files, index) : null}>
              <BasicImage src={item && item.src && item.src.getImageSize(imageType, item)} alt="preview" className="image-container__file-image" />
            </div>
            <p key={index} className="f-primary filename" title={item ? item.FileName : ''}>
              {item && item.FileName}
            </p>
            <SmallDropdown
              actionsMenu={actionsMenu}
              getClickAction={(action, index, selfClose) => {
                action(item);
                selfClose();
              }}
              offsetX={-100}
            />
          </div>
        );
      }
    });
  };

  onLocalUpload = (files, defect) => {
    const { uploadAsset, setUploadItems, setSingleUploadItem, inspectionID, projectID, uploadType, restUploadParams } = this.props;

    if (!defect) return;

    const tmp_files = files.map((file, index) => {
      const tmp_file = {
        type: Helpers.getFileType(file.type),
        mimeType: file.type,
        title: '',
        description: '',
        real_name: Helpers.formatFileName(file.name),
      };

      file.timeStamp = Date.now();
      file.real_name = tmp_file.real_name;
      file.uploadType = uploadType || uploadConstants.uploadType.ndtMeasurementFiles;

      let dataParams = { InspectionID: inspectionID, ProjectID: projectID, ...(restUploadParams || {}) };
      dataParams.MeasurementID = defect.ID;

      uploadAsset(file, setSingleUploadItem, index, dataParams);
      return { ...tmp_file, ...file };
    });
    setUploadItems(tmp_files);
  };

  getContainerClassName = () => {
    const { preview } = this.props;
    let className = 'component-assets';
    if (preview === 'image-thumbnail') {
      className = 'thumbnail-assets';
    }
    return className;
  };

  render() {
    const { label, labelClass, id, openUploadModal, className, disabled } = this.props;
    const { onLocalUpload } = this;

    return (
      <div className={`${this.getContainerClassName()} ${className || ''}`}>
        {label && (
          <div className="label-container">
            <label className={`label-container__label ${labelClass || 'f-secondary-dark'}`} htmlFor={id}>
              {label}
            </label>
            <UploadSvg onClick={disabled ? null : () => openUploadModal(onLocalUpload)} className={`svg-primary-g label-container__upload ${disabled ? 'disabled' : ''}`} />
          </div>
        )}
        <div className="files">{this.renderFiles()}</div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  projectID: state.inspectionReducer.projectID,
  inspectionID: state.inspectionReducer.inspectionID,
});

const mapDispatchToProps = dispatch => ({
  uploadAsset: (file, callbackFunction, index, data) => dispatch(uploadAsset(file, data, callbackFunction, index)),
  setUploadItems: assets => dispatch(setUploadItems(assets)),
  setSingleUploadItem: (progress, index) => dispatch(setSingleUploadItem(progress, index)),
});

MeasurementFieldUpload.contextTypes = {
  t: PropTypes.func.isRequired,
};

MeasurementFieldUpload.propTypes = {
  preview: PropTypes.oneOf(['list', 'image-thumbnail']),
};

MeasurementFieldUpload.defaultProps = {
  preview: 'list',
};

export default connect(mapStateToProps, mapDispatchToProps)(MeasurementFieldUpload);
