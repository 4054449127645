import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../styles/comments-tab.scss';
import Button from '../../form/components/button';
import { commentFields, formFields, submitButtonName, commentTagTypes } from '../constants/constants';
import CommentsEmptyState from './comments-empty-state';
import Helpers from '../../helpers';
import CommentDropdown from './comment-dropdown';
import userConstants from '../../../app/login/constants/user-constants';
import { isEmpty, trim } from 'lodash';
import CommentsTextBox from '../../comments-text-box/components/comments-text-box';
import { verifyTags } from '../helpers/comments-tab-helpers';
import { EditorState } from 'draft-js';
import Loader from '../../global-loader/components/simple-loader';
import Icon from '../../icon/components/icon';
import Modal from '../../modal/components/modal';
import { connect } from 'react-redux';
import { getTeamUsers } from '../../../app/members/actions/teams-data-actions';
import ShowTeamUserModal from '../../comments-text-box/modal/show-team-users-modal';

const CommentsTab = (
  {
    commentsList,
    commentsLoading,
    addCommentLoading,
    fetchCommentsList,
    fetchCommentUsersAndTeams,
    onAddCommentClick,
    onDeleteCommentClick,
    className,
    commentUsersList,
    commentTeamsList,
    commentUsersAndTeamsLoading,
    user,
    addCommentPermission,
    getTeamUsers,
    addCommentDisabled,
    deleteCommentPermission,
    ...rest
  },
  { t }
) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [commentTags, setCommentTags] = useState([]);
  const [commentInputFocused, setCommentInputFocused] = useState(false);
  const [modalData, setModalData] = useState({ isOpen: false });
  const commentValue = editorState.getCurrentContent().getPlainText();

  useEffect(() => {
    fetchCommentsList();

    return () => resetStateToInitialState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetStateToInitialState = () => {
    setEditorState(EditorState.createEmpty());
    setCommentTags([]);
    setCommentInputFocused(false);
  };

  const hasAddCommentPermission = Helpers.hasAccess({
    user,
    visibleFor: addCommentPermission,
    id: user[userConstants.userFields.userId],
    ownerRequiredPermission: addCommentPermission,
  });

  const addCommentButtonDisabled = () => {
    return !hasAddCommentPermission || addCommentLoading || !commentValue || isEmpty(trim(commentValue)) || commentValue.length > formFields.comment.maxChars || addCommentDisabled;
  };

  const fetchTeamUsers = (id, teamName) => {
    const closeAction = () => setModalData({ isOpen: false });
    const onFetch = users =>
      setModalData({ isOpen: true, closeAction, CustomContent: dynamicProps => <ShowTeamUserModal {...dynamicProps} />, users, type: '', className: 'modal-small', title: teamName });
    getTeamUsers(id, onFetch);
  };

  const onAddTagHandler = tag => setCommentTags([...commentTags, tag]);

  const handleCommentAreaOnBlur = evt => {
    evt.persist();
    if (!evt) return null;

    // toggles the visibility of comment submit btn
    if (!evt.relatedTarget) {
      setCommentInputFocused(false);
      return;
    }
    setCommentInputFocused(evt.relatedTarget.attributes.name.nodeValue === submitButtonName);
  };

  const handleCommentSectionFocus = e => {
    setCommentInputFocused(true);
  };

  const renderCommentsList = () => {
    if (!commentsList || commentsList.length === 0) return <CommentsEmptyState />;
    if (commentsLoading) return <Loader isLoading={commentsLoading} />;

    return commentsList.map((comment, index) => {
      const { Tags } = comment;
      const tagDictionary = {};

      // we iterate through all tags and fill up the tagDictionary
      // tagDictionary will be later used to match tags to replace
      Tags.forEach(tag => {
        tagDictionary[`@${tag[commentFields.taggedEntityName].toLowerCase()}#`] = {
          content: tag[commentFields.taggedEntityName],
          type: tag[commentFields.type],
          id: tag[commentFields.taggedEntityID],
        };
      });

      let commentContent = comment[commentFields.value];

      const tagPattern = /(@.*?#)/g;
      const commentParts = commentContent.split(tagPattern);

      // Map each part to render as span or custom tag for user/team
      const renderedParts = commentParts.map((commentPart, index) => {
        if (commentPart.match(tagPattern) && tagDictionary[commentPart.toLowerCase()]) {
          const tagObj = tagDictionary[commentPart.toLowerCase()];
          return (
            <span className="comment__tag-highlight" key={index}>
              @{tagObj.content}
              {tagObj.type === commentTagTypes.team ? (
                <Icon
                  name="users"
                  size="xs"
                  onClick={() => {
                    fetchTeamUsers(tagObj.id, tagObj.content);
                  }}
                />
              ) : (
                ''
              )}
            </span>
          );
        } else {
          const spanValue = commentPart.replace(/\n/g, '<br/> ');
          return <span key={index} dangerouslySetInnerHTML={{ __html: spanValue }} />;
        }
      });

      const hasDeleteCommentPermission = comment[commentFields.createdBy] === user[userConstants.userFields.userId] && hasAddCommentPermission;

      return (
        <div className="comment" id={`comment-${index}`} key={comment[commentFields.id]}>
          <div className="comment__header">
            <p className="comment__author f-primary">{comment[commentFields.createdByUser]}</p>
            <p className="comment__date">{Helpers.checkHoursDifference(comment[commentFields.createdAt], t)}</p>
            {hasDeleteCommentPermission && <CommentDropdown row={comment} onClick={comment => onDeleteCommentClick(comment)} deleteCommentPermission={hasDeleteCommentPermission} />}
          </div>
          <div className="comment__content">{renderedParts}</div>
        </div>
      );
    });
  };
  const renderCommentInputField = () => (
    <div className="add-comment-wrapper">
      <div className="textarea-wrapper" id="comment-add-container">
        <CommentsTextBox
          isExpanded={commentInputFocused}
          suggestions={[...commentUsersList, ...commentTeamsList]}
          fetchSuggestions={fetchCommentUsersAndTeams}
          onBlur={handleCommentAreaOnBlur}
          onFocus={handleCommentSectionFocus}
          onAddMention={onAddTagHandler}
          editorState={editorState}
          setEditorState={setEditorState}
          getTeamUsers={fetchTeamUsers}
        />
      </div>
      {commentInputFocused && (
        <div className={`${className ? className : ''} add-comment-button`}>
          {/* TODO: add addCommentLoading with spinner if adding is in progress */}
          {/* TODO: disable if !queryItem || queryItem < 0 for newly created module items */}
          <Button
            disabled={addCommentButtonDisabled()}
            type="button"
            name={submitButtonName}
            text={t('COMMENTS.POST_A_COMMENT')}
            onClick={() =>
              onAddCommentClick(
                commentValue,
                verifyTags(commentValue, commentTags),
                () => resetStateToInitialState(),
                () => Helpers.scrollIntoView('comments-list-wrapper', `comment-${commentsList.length - 1}`, 0)
              )
            }
            keepOriginalText
          />
        </div>
      )}
    </div>
  );

  return (
    <div className="comments-tab">
      <div className="comments-list-wrapper">{renderCommentsList()}</div>
      <Fragment>{renderCommentInputField()}</Fragment>
      <Modal {...modalData} />
    </div>
  );
};

CommentsTab.contextTypes = {
  t: PropTypes.func.isRequired,
};

CommentsTab.propTypes = {
  commentsList: PropTypes.array,
  commentsLoading: PropTypes.bool,
  addCommentDisabled: PropTypes.bool,
  deleteCommentPermission: PropTypes.bool,
  onAddCommentClick: PropTypes.func.isRequired,
  onDeleteCommentClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

CommentsTab.defaultProps = {
  commentsList: [],
  commentsLoading: false,
  addCommentDisabled: false,
  deleteCommentPermission: false,
  onAddCommentClick: false,
  onDeleteCommentClick: false,
  className: '',
};

const mapDispatchToProps = dispatch => ({
  getTeamUsers: (id, callback) => dispatch(getTeamUsers(id, callback)),
});

export default connect(null, mapDispatchToProps)(CommentsTab);
