import { fields } from '../../../../constants/constants';

export const tableConfig = [
  {
    title: '',
    key: fields.isActive,
    type: fields.isActive,
    class: 'is-active-column',
  },
  {
    title: 'Name',
    key: fields.fileName,
    type: fields.fileName,
    class: 'name-column',
  },
  {
    title: 'DMS_FILE.SYSTEM_VERSION',
    key: fields.fileVersion,
    type: fields.fileVersion,
    class: 'system-version-column',
  },
  {
    title: 'DMS_FILE.VERSION_NUMBER',
    key: fields.versionNumber,
    type: fields.versionNumber,
    class: 'version-number-column',
  },
  {
    title: 'DMS_FILE.VERSION_DATE',
    key: fields.versionDate,
    type: fields.versionDate,
    class: 'version-date-column',
  },
  {
    title: '',
    key: fields.actionMenu,
    type: fields.actionMenu,
    class: 'action-menu-column',
  },
];

export const versionHistoryDropdownActions = {
  edit: 'edit',
  download: 'download',
  setAsActive: 'setAsActive',
  delete: 'delete',
};
