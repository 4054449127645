import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
//components
import SingleRnGItemTable from '../single-item-table/single-item-table';
//constants
import { uniqBy } from 'lodash';
import AccessRenderer from '../../../../../../common/access-renderer/components/access-renderer';
import Helpers from '../../../../../../common/helpers';
import { modules } from '../../../../constants/constants';
import { defaultFilters, fields, viewOptions } from '../../constants/constants';

const MeasurementGroupsTableView = (props, { t }) => {
  const {
    projectID,
    handleBackClick,
    handlePathClick,
    path,
    navigateToPath,
    view,
    fetchDataAction,
    selectMeasurementGroup,
    user,
    showPath,
    selectedClusterElement,
    showAdditionalOptions = true,
    searchPlaceholder,
    isNewMeasurementPointModal = false,
    visibleFor,
  } = props;
  const [measurementGroups, setMeasurementGroups] = useState([]);
  const [measurementGroupsFilter, setMeasurementGroupsFilter] = useState(defaultFilters);
  const [measurementGroupsLoading, setMeasurementGroupsLoading] = useState(false);

  const fetchDataCallback = useCallback(
    (filters, loadMore = false) => {
      const handleFetchData = (data, newFilters) => {
        const newData = loadMore ? [...measurementGroups, ...data] : data;

        setMeasurementGroups(uniqBy(newData, fields.id));
        setMeasurementGroupsFilter({ ...measurementGroupsFilter, ...newFilters });
        setMeasurementGroupsLoading(false);
        if (loadMore) {
          Helpers.scrollIntoView('measurement-groups-table', null, -250);
        }
      };

      fetchDataAction({ ...filters, [fields.projectID]: projectID }, handleFetchData, setMeasurementGroupsLoading);
    },
    [fetchDataAction, projectID, measurementGroups, measurementGroupsFilter]
  );

  const handleRowClick = row => {
    selectMeasurementGroup(row);
    const newPathItem = { [fields.name]: t(row[fields.name]), queryParams: { type: modules.readingsAndGauges, view: viewOptions.location_and_points } };
    navigateToPath(newPathItem);
  };

  const emptyStateProps = {
    emptyStateText: t('READINGS_AND_GAUGES.GROUP_EMPTY_STATE.TEXT'),
    showButton: false,
    transparent: true,
    buttonAction: () => null,
    buttonDisabled: true,
  };

  return (
    <AccessRenderer visibleFor={visibleFor} id={selectedClusterElement && selectedClusterElement[fields.createdBy]}>
      {({ hasAccess: canEdit }) => {
        return canEdit ? (
          <SingleRnGItemTable
            customTableClass="measurement-groups-table"
            title={'READINGS_AND_GAUGES.ALL_GROUPS'}
            isFullScreen={true}
            view={view}
            handleRowClick={handleRowClick}
            showPath={showPath}
            path={path}
            navigateToPath={navigateToPath}
            handleBackClick={handleBackClick}
            handlePathClick={handlePathClick}
            projectID={projectID}
            fetchData={fetchDataCallback}
            data={measurementGroups}
            filters={measurementGroupsFilter}
            defaultFilters={defaultFilters}
            isDataLoading={measurementGroupsLoading}
            emptyStateProps={emptyStateProps}
            user={user}
            showAdditionalOptions={showAdditionalOptions}
            searchPlaceholder={searchPlaceholder}
            isNewMeasurementPointModal={isNewMeasurementPointModal}
          />
        ) : null;
      }}
    </AccessRenderer>
  );
};

MeasurementGroupsTableView.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default MeasurementGroupsTableView;
