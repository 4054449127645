import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import BackButton from '../../back-button/components/back-button';
import CreateButton from '../../create-button/components/create-button';
import Icon from '../../icon/components/icon';
import SmallDropdown from '../../small-dropdown/components/small-dropdown';

import '../styles/page-navigation.scss';

class PageNavigation extends Component {
  render() {
    const {
      title,
      icon,
      withBottomBorder,
      backButtonPath,
      nonTranslatableTitle,
      titleProps,
      iconProps,
      menuOptions,
      getClickAction,
      actionButtontext,
      handleActionButtonClick,
      actionButtonProps,
      secondActionButtonText,
      handleSecondActionButtonClick,
      secondActionButtonProps,
      user,
      ...rest
    } = this.props;
    const { t } = this.context;

    return (
      <div className={`page-navigation ${withBottomBorder ? 'page-navigation--with-border' : ''}`} {...rest}>
        <div className="page-navigation__wrapper">
          {backButtonPath && (
            <div className="page-navigation__wrapper__back-button">
              <BackButton path={backButtonPath} textClass="f-secondary-green link" />
            </div>
          )}
          <div className="page-navigation__wrapper__title-container">
            {title && (
              <div className="page-navigation__wrapper__title-container__title">
                {icon && <Icon name={icon} className="page-navigation__wrapper__title-container__title__icon" {...iconProps} />}
                <h5 className="f-primary " {...titleProps}>
                  {nonTranslatableTitle ? title : t(title)}
                </h5>
              </div>
            )}
            <div className="page-navigation__wrapper__title-container__menu">
              {secondActionButtonText && handleSecondActionButtonClick && (
                <CreateButton user={user} onClick={handleSecondActionButtonClick} {...(secondActionButtonProps || {})}>
                  {secondActionButtonText}
                </CreateButton>
              )}
              {actionButtontext && handleActionButtonClick && (
                <CreateButton user={user} onClick={handleActionButtonClick} {...(actionButtonProps || {})}>
                  {actionButtontext}
                </CreateButton>
              )}
              {menuOptions.length > 0 && (
                <SmallDropdown
                  className="page-navigation__wrapper__title-container__menu__dropdown"
                  actionsMenu={menuOptions}
                  getClickAction={(action, b, selfClose, e) => {
                    e?.preventDefault && e.preventDefault();
                    selfClose();
                    if (action) {
                      if (typeof action === 'function') {
                        action();
                      } else if (typeof action === 'string' && getClickAction) {
                        getClickAction(action);
                      }
                    }
                  }}
                  offsetX={10}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PageNavigation.contextTypes = {
  t: PropTypes.func.isRequired,
};

PageNavigation.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  withBottomBorder: PropTypes.bool,
  backButtonPath: PropTypes.string,
  nonTranslatableTitle: PropTypes.bool,
  titleProps: PropTypes.object,
  iconProps: PropTypes.object,
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      access: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          visibleFor: PropTypes.arrayOf(PropTypes.string),
        }),
      ]),
      action: PropTypes.func,
      icon: PropTypes.string,
    })
  ),
  getClickAction: PropTypes.func,
  actionButtontext: PropTypes.string,
  handleActionButtonClick: PropTypes.func,
  secondActionButtonText: PropTypes.string,
  handleSecondActionButtonClick: PropTypes.func,
};

PageNavigation.defaultProps = {
  title: '',
  icon: '',
  withBottomBorder: false,
  backButtonPath: '',
  nonTranslatableTitle: false,
  titleProps: {},
  iconProps: {},
  menuOptions: [],
};

const mapStateToProps = state => ({
  user: state.userReducer,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PageNavigation);
