/**
 * This is a wrapper component for expandable section with specific location section logic.
 * It is extracted in this manner so it does not violate the Single Responsibility Principle, where a component should ideally do one thing.
 * Also, it improves reusability, maintainability and ensures the Separation of Concerns principle where
 * each component handles its specific logic, making the code more modular and easier to understand.
 */
import PropTypes from 'prop-types';
import React from 'react';
import ExpandableSection from '../../expandable-section/components/expandable-section';
import Icon from '../../icon/components/icon';
import '../styles/location-expandable-section.scss';

const LocationExpandableSection = props => {
  const { onToggleLocationSection, location, showLocationIcon, ...restProps } = props;

  return (
    <ExpandableSection
      {...restProps}
      customComponentProps={{
        ...restProps.customComponentProps,
        onToggleLocationSection,
      }}
      CustomComponent={({ isExpanded }) => <Icon size="sm" name={isExpanded ? 'eye' : 'not-visible'} onClick={onToggleLocationSection} />}
      hideExpandAction={true}
      hideEditAction={true}
      className="location-expandable-section"
      titleIcon={showLocationIcon && (location ? <Icon name="green-check" handleHover={false} size="xs" /> : <Icon name="map-crossed" handleHover={false} size="xs" />)}
    />
  );
};

LocationExpandableSection.propTypes = {
  ...ExpandableSection.propTypes,
  onToggleLocationSection: PropTypes.func,
};

LocationExpandableSection.defaultProps = {
  ...ExpandableSection.defaultProps,
  onToggleLocationSection: () => null,
  showLocationIcon: false,
};

export default LocationExpandableSection;
