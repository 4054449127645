import React from 'react';
import PropTypes from 'prop-types';
import '../styles/radio-buttons.scss';

const RadioButton = props => {
  const { input, name, value, className, label, key, CustomComponent, disabled, isRequired } = props;

  return (
    <div className={`single-radio ${input.checked ? 'active' : ''} ${className || ''} ${disabled ? 'disabled' : ''}`} key={key}>
      <input className="radio-button" type="radio" value={value} name={name} {...input} disabled={disabled} />
      {label && (
        <label className="radio-label f-primary noselect" htmlFor={name}>
          {`${label}${isRequired ? '*' : ''}`}
        </label>
      )}
      {CustomComponent && CustomComponent()}
    </div>
  );
};

RadioButton.propTypes = {
  input: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  key: PropTypes.string,
  CustomComponent: PropTypes.func,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
};

RadioButton.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default RadioButton;
