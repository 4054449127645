import { values } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import CustomInput from '../../../../common/form/components/input';
import RadioButton from '../../../../common/form/components/radio-button';
import CustomSelect from '../../../../common/form/components/select';
import { priorities } from '../../../inspections/constants/work-order-constants';
import { formConstants, sources } from '../../constants/regime-constants';

const BasicDetailsFormStep = ({ isPreviewMode, severityColors, additionalWorkorderTypes }, { t }) => {
  const workOrderPriorities = React.useMemo(() => {
    return values(priorities);
  }, []);

  return (
    <>
      <div className="double">
        <div className="split-input">
          <Field
            id={formConstants.fields.title}
            name={formConstants.fields.title}
            component={CustomInput}
            disabled={isPreviewMode}
            placeholder={t('CREATE_REGIME_FORM.TITLE')}
            label={t('CREATE_REGIME_FORM.TITLE')}
            type="text"
            size="lg"
            isRequired
            additionalClassName="text-transform-none"
            labelClass="text-transform-none"
          />
        </div>
        <div className="split-input maintenance-regime-custom-select">
          <Field
            id={formConstants.fields.importance}
            name={formConstants.fields.importance}
            data={workOrderPriorities}
            disabled={isPreviewMode}
            valueField="value"
            textField="title"
            component={CustomSelect}
            placeholder={t('CREATE_REGIME_FORM.PRIORITY')}
            label={t('CREATE_REGIME_FORM.PRIORITY')}
            valueComponent={({ item }) => (
              <div className="priority-dropdown-item without-padding">
                {item.color ? <div className="priority-icon" style={{ backgroundColor: severityColors[item.color] }}></div> : null}
                <p className="f-primary">{t(item.title)}</p>
              </div>
            )}
            optionComponent={({ dataItem, onSelect }) => {
              return (
                <div className="priority-dropdown-item" onClick={() => onSelect(dataItem.value)}>
                  {dataItem.color ? <div className="priority-icon" style={{ backgroundColor: severityColors[dataItem.color] }}></div> : null}
                  <p className="f-primary">{t(dataItem.title)}</p>
                </div>
              );
            }}
            size="lg"
            isRequired
          />
        </div>
      </div>
      <div className="radio-button-container">
        <Field
          name={formConstants.fields.source}
          component={RadioButton}
          value={sources.workOrder}
          type="radio"
          id={`${formConstants.fields.workOrder}`}
          label={t('CREATE_REGIME_FORM.CREATE_WORK_ORDER')}
          disabled={isPreviewMode}
        />
      </div>
      <div className="half-width-input maintenance-regime-custom-select">
        <Field
          id={formConstants.fields.additionalSubType}
          name={formConstants.fields.additionalSubType}
          data={additionalWorkorderTypes}
          disabled={isPreviewMode}
          component={CustomSelect}
          placeholder={t('CHOOSE_WORK_ORDER_NOTIFICATION.WO_SUB_TYPE_LABEL')}
          label={t('CHOOSE_WORK_ORDER_NOTIFICATION.WO_SUB_TYPE_LABEL')}
          size="lg"
        />
      </div>
    </>
  );
};

BasicDetailsFormStep.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default BasicDetailsFormStep;
