import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { debounce, find, isEmpty, isString, keys, map, reduce, values } from 'lodash';

import InspectionRenderer from './inspection-renderer';

import AccessRenderer from '../../../../common/access-renderer/components/access-renderer';
import Icon from '../../../../common/icon/components/icon';
import ItemsTableRenderer from '../../../../common/items-renderer/components/items-table-renderer';
import ModuleHeader from './common/module-header';
import SearchText from './common/search-text';
import VisibilityBox from './common/visibility-box';
import NDTMeasurementsFilter from './filters/ndt-measurements-filter';

import { alarmLevelThemeColors, defaultPagingObj, defaultSortingFilter, filterParams, measurementConstants, sortingDirection, tableConfig } from '../../constants/ndt-constants';

import { hideAllNDTMeasurementByComponentName, showAllNDTMeasurementByComponentName, toggleNDTMeasurement } from '../../actions/action-creators';

import { FEATURES } from '../../../../common/constants';
import { PERMISSION_TYPES, PERMISSIONS } from '../../../../common/permissions-constants';
import { routes } from '../../../../common/routes-constants';
import { updateMeasurementGeometry } from '../../actions/ndt-actions';

import Helpers from '../../../../common/helpers';

import '../../styles/ndt-measurements.scss';

class NDTMeasurements extends Component {
  constructor(props) {
    super(props);

    const colors = values(alarmLevelThemeColors);

    this.state = {
      advancedFilter: {},
      pagingObject: defaultPagingObj,
      sortingFilter: defaultSortingFilter,

      quickFilter: map(colors, item => {
        return {
          ...item,
          color: props.severityColors[item.key],
        };
      }),
    };
    this.searchChangeDebounced = debounce(this.getMeasurements, 300);
  }

  componentDidMount = () => {
    const { queryItem } = this.props;
    let params = {};

    if (queryItem) {
      params = {
        IncludedIDs: [queryItem],
      };
    }

    this.getMeasurements(params);
  };

  getMeasurements = (params, loadMore = false) => {
    const { getMeasurements, searchText, setClusteringFilters } = this.props;
    let { advancedFilter, pagingObject, sortingFilter } = this.state;

    //normalize advanced filter
    const newAdvancedFilter = reduce(
      advancedFilter,
      (obj, it, key) => {
        if ([filterParams.componentIDsFilter, filterParams.createdByFilter].indexOf(key) > -1) {
          obj[key] = map(advancedFilter[key], ({ ID, UserID }) => ID || UserID);
        } else if ([filterParams.dateFrom].indexOf(key) > -1) {
          obj[key] = (advancedFilter[key] && Helpers.getUnixDate(new Date(advancedFilter[key]).getTime())) || null;
        } else if ([filterParams.dateTo].indexOf(key) > -1) {
          // sets end/to dates to end of day 23:59:59 to include that day in filter results
          obj[key] = (advancedFilter[key] && Helpers.getUnixDate(new Date(advancedFilter[key]).setHours(23, 59, 59))) || null;
        } else if ([filterParams.hasNotificationFilter, filterParams.hasAlarmsFilter].indexOf(key) > -1) {
          obj[key] = advancedFilter[key];
        } else if (filterParams.groupFilter) {
          obj[key] = advancedFilter[key].toString();
        }
        return obj;
      },
      {}
    );

    setClusteringFilters({ ...newAdvancedFilter });

    getMeasurements(searchText, { ...newAdvancedFilter, ...pagingObject, ...sortingFilter, ...params }, loadMore, (incomingFilter, c) => {
      this.setState({ pagingObject: { ...pagingObject, [filterParams.totalItems]: incomingFilter[filterParams.totalItems], [filterParams.hasNext]: incomingFilter[filterParams.hasNext] } });
    });
  };

  componentDidUpdate = prevProps => {
    const { searchText } = this.props;

    if (isString(searchText) && prevProps.searchText !== searchText) {
      this.setState({
        pagingObject: defaultPagingObj,
      });
      this.searchChangeDebounced();
    }
  };

  getColor = status => {
    const { quickFilter } = this.state;
    return (find(quickFilter, i => i.status === status) || {}).color || quickFilter[0].color;
  };

  onColumnSort = column => {
    const { sortingFilter } = this.state;

    this.setState({
      sortingFilter: {
        [filterParams.sortByColumn]: column,
        [filterParams.sortDirection]: sortingFilter[filterParams.sortDirection] === sortingDirection.asc ? sortingDirection.desc : sortingDirection.asc,
      },
      pagingObject: defaultPagingObj,
    });

    this.searchChangeDebounced();
  };

  loadMore = () => {
    const { pagingObject } = this.state;
    const { elements } = this.props;
    this.setState({
      pagingObject: {
        ...pagingObject,
        [filterParams.lastSeen]: elements.length || 0,
      },
    });
    this.searchChangeDebounced(null, true);
  };
  openAdvancedFilter = () => {
    const { t } = this.context;
    const { setModalState } = this.props;
    const { advancedFilter } = this.state;
    const closeAction = () => setModalState({ isOpen: false });

    const modalData = {
      isOpen: true,
      type: 'none',
      title: t('ADVANCED_FILTERS.TITLE'),
      CustomContent: () => (
        <NDTMeasurementsFilter
          submitForm={values => {
            this.setState({
              advancedFilter: values,
              pagingObject: defaultPagingObj,
            });
            closeAction();
            this.searchChangeDebounced();
          }}
          resetFilter={() => {
            this.setState({
              advancedFilter: {},
              pagingObject: defaultPagingObj,
            });
            closeAction();
            this.searchChangeDebounced();
          }}
          initialValues={advancedFilter}
          closeAction={closeAction}
        />
      ),
      customClassName: 'modal-large defects-filter',
      closeAction,
    };
    setModalState(modalData);
  };

  goToHistoryPage = queryParams => {
    const path = `${routes.protectedRoutes.modulesHistory.fullPath}${queryParams}`;
    Helpers.goTo(path);
  };

  render() {
    const { t } = this.context;
    const { sortingFilter, pagingObject, advancedFilter } = this.state;

    const {
      elements,
      elementsClustered,
      addMeasurement,
      getCameraPosition,
      defaultComponentID,
      toggleMeasurement,
      openDownloadReportModalForMeasurments,
      openAddQuickNDTPointModal,
      updateGeometry,
      scrollToElement,
      generateCustomIdentificator,
      measurementLocationsLoading,
      handleItemClick,
      queryItem,
      selectedClusterElement,
      title,
      backAction,
      moduleActionsMenu,
      viewer,
      tableConfig,
      icon,
      location,
      deselectAll,
      selectAll,
    } = this.props;

    return (
      <Fragment>
        <AccessRenderer
          visibleFor={PERMISSIONS[PERMISSION_TYPES.ndt].edit.name}
          id={selectedClusterElement && selectedClusterElement[measurementConstants.fields.createdBy]}
          ownerRequiredPermission={PERMISSIONS[PERMISSION_TYPES.ndt].create.name}
        >
          {({ hasAccess }) => {
            return (
              <InspectionRenderer
                deselectAll={deselectAll}
                selectAll={selectAll}
                toggleElement={toggleMeasurement}
                deselectAllTemp={() => null}
                selectAllTemp={() => null}
                toggleElementTemp={() => null}
                selectElement={(data, autoScroll) => {
                  if (autoScroll) {
                    scrollToElement(data);
                  }
                  handleItemClick(data.ID);
                }}
                updateElementGeometry={data => {
                  if (!hasAccess) {
                    //CHECK OWNERSHIP PER element
                    return;
                  }

                  updateGeometry(data, elements, newData => handleItemClick(newData.ID));
                }}
                hoveredDefect={() => null}
                elements={elementsClustered || []}
                disableMove={!hasAccess}
                queryItem={queryItem}
                viewer={viewer}
              >
                {({ elementClickHandler, elementShowHandler, elementHideHandler, selectAllHandler, deselectAllHandler }) => (
                  <div className="ndt-measurements-list">
                    <ModuleHeader
                      {...{
                        title,
                        icon,
                        backAction,
                        actionsMenu: [
                          ...moduleActionsMenu,
                          ...(FEATURES.modulesHistory?.visible
                            ? [
                                {
                                  title: 'INSPECTION_FULLSCREEN.VIEW_HISTORY',
                                  action: () => this.goToHistoryPage(location?.search),
                                },
                              ]
                            : []),
                          {
                            title: 'NDT_MEASUREMENTS.CREATE_NEW',
                            action: () =>
                              addMeasurement(
                                {
                                  ComponentID: defaultComponentID,
                                  ComponentName: t('DEFAULT_COMPONENT.NAME'),
                                  Name: 'New measurement',
                                },
                                getCameraPosition()
                              ),
                            access: {
                              visibleFor: PERMISSIONS[PERMISSION_TYPES.ndt].create.name,
                            },
                          },

                          {
                            title: 'INSPECTION_DETAILS_DOWNLOAD_REPORT',
                            action: openDownloadReportModalForMeasurments,
                          },
                        ],
                      }}
                    />
                    <div className="advanced-filter">
                      <SearchText />
                      <p className="f-secondary-green noselect pointer link" onClick={this.openAdvancedFilter}>
                        {t('ADVANCED_FILTER', { active: keys(advancedFilter).length })}
                      </p>
                    </div>

                    {viewer && (
                      <VisibilityBox
                        {...{
                          selectAllHandler,
                          deselectAllHandler,
                        }}
                      />
                    )}

                    <ItemsTableRenderer
                      isLoading={measurementLocationsLoading}
                      tableConfig={tableConfig}
                      translationModule={t}
                      data={map(elements, item => ({ ...item, selected: item[measurementConstants.fields.id] === queryItem ? true : false }))}
                      onRowClick={elementClickHandler}
                      loadMore={{
                        visible: pagingObject[filterParams.hasNext],
                        disabled: measurementLocationsLoading,
                        onClick: this.loadMore,
                      }}
                      sortingObj={sortingFilter}
                      onColumnSort={this.onColumnSort}
                      formatCell={(value, type, index, item) => {
                        if ([measurementConstants.fields.nameNoStatus, measurementConstants.fields.name].indexOf(type) > -1) {
                          return (
                            <div className="name">
                              {type === measurementConstants.fields.name && (
                                <div className="status-icon" style={{ backgroundColor: this.getColor(item[measurementConstants.fields.alarmColor]) }} title={t('NDT_MEASUREMENTS.STATUS_ICON')} />
                              )}
                              <span title={value} className="f-primary dots">
                                {value}
                              </span>
                            </div>
                          );
                        }

                        if (type === measurementConstants.fields.description) {
                          return (
                            <div className="long-field" title={value}>
                              {value?.length > 50 ? value.substring(0, 50) : value || '-'}
                            </div>
                          );
                        }
                        if (type === measurementConstants.fields.lastMeasurementDate) {
                          return !isNaN(value) && value > 0 ? Helpers.getDateFromUnix(value) : '-';
                        }

                        if (type === measurementConstants.fields.alarmColor) {
                          return (
                            <>
                              {!item.Geometry ||
                                isEmpty(item.Geometry) ||
                                (isEmpty(item.Geometry.coordinates) && (
                                  <div className="missing-pin">
                                    <Icon name="warning" warning handleHover={false} size="md" title={t('ITEMS_LIST.WARNING_ICON_TEXT')} />
                                  </div>
                                ))}
                              <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.ndt].createMeasurementPoint.name}>
                                {({ hasAccess }) => {
                                  return (
                                    <div title={t('ADD_QUICK_NDT_POINT.ADD_NEW_BUTTON_TEXT')} className={`add-ndt-point ${hasAccess ? '' : 'disabled'}`}>
                                      <Icon
                                        name="plus-rounded"
                                        size="sm"
                                        active
                                        onClick={hasAccess ? () => openAddQuickNDTPointModal(item[measurementConstants.fields.name], { selectedMeasurement: item }) : null}
                                      />
                                    </div>
                                  );
                                }}
                              </AccessRenderer>
                              {viewer && (
                                <Icon
                                  name="eye"
                                  active={item.visible}
                                  size="xs"
                                  onClick={item.visible ? e => elementHideHandler(e, item) : e => elementShowHandler(e, item)}
                                  title={item.visible ? t('NDT_MEASUREMENTS_LIST.HIDE_ICON') : t('NDT_MEASUREMENTS_LIST.SHOW_ICON')}
                                />
                              )}
                            </>
                          );
                        }

                        return value || '-';
                      }}
                      emptyStateText={'NDT_DATA.EMPTY_STATE'}
                      customIdentificatorFunction={generateCustomIdentificator}
                    />
                    {pagingObject[filterParams.totalItems] > 0 && (
                      <p className="f-secondary-dark total">
                        {t('SHOWING_TOTAL_ITEMS', {
                          items: elements?.length || 0,
                          totalItems: pagingObject[filterParams.totalItems],
                        })}
                      </p>
                    )}
                  </div>
                )}
              </InspectionRenderer>
            );
          }}
        </AccessRenderer>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  deselectAll: componentName => dispatch(hideAllNDTMeasurementByComponentName(componentName)),
  selectAll: componentName => dispatch(showAllNDTMeasurementByComponentName(componentName)),
  toggleMeasurement: id => dispatch(toggleNDTMeasurement(id)),
  //
  updateGeometry: (data, currentMeasurements, callback) => dispatch(updateMeasurementGeometry(data, currentMeasurements, callback)),
});

NDTMeasurements.contextTypes = {
  t: PropTypes.func.isRequired,
};
NDTMeasurements.defaultProps = {
  tableConfig: tableConfig,
};

export default withRouter(connect(null, mapDispatchToProps)(NDTMeasurements));
