import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { find, get, values } from 'lodash';

import { toggleToolbar } from '../actions/inspection-actions';

import CollapsibleToolbar from './collapsible-toolbar';
import InspectionWrapper from './inspection-wrapper';
import LeftToolbar from './left-toolbar/left-toolbar';
import RightToolbar from './right-toolbar/right-toolbar';

import Modal from '../../../common/modal/components/modal';

import { detailsPages, modules, modulesFullScreen, placements } from '../constants/constants';

import { DIMENSIONS } from '../../../common/constants';

import '../styles/inspection.scss';

class InspectionScreen extends Component {
  render() {
    const {
      inspectionReducer: { leftCollapsed, rightCollapsed, activeDetailsPage },
      toggleToolbar,
      uploadInProgress,
      viewer,
      selectTool,
      currentInsertion,
      setCurrentInsertion,
      createNewDefect,
      getCameraPosition,
      handleObjectToolClick,
      getDefectType,
      showObjectToolbox,
      scrollToElement,
      createWOModalData,
      router,
      route,
    } = this.props;
    const { query } = this.props.location;

    const selectedType = !query.type || !find(modules, item => item === query.type) ? modules.details : query.type;
    const view = query.view;

    return (
      <InspectionWrapper {...this.props}>
        {({
          handleActivePage,
          openDownloadReportModal,
          openDownloadReportModalForMeasurments,
          openDownloadReportModalSingleDefect,
          openAddQuickNDTPointModal,
          openInspectionAssetModal,
          openDocumentsUploadModal,
          switchToFullScreenMode,
          setModalState,
          handleInspectionClick,
          modalData,
        }) => (
          <Fragment>
            <CollapsibleToolbar collapsed={leftCollapsed} toggleToolbar={toggleToolbar} placement={placements.left}>
              <LeftToolbar
                //routing
                inspectionId={parseInt(query.inspection_id)}
                projectId={parseInt(query.project_id)}
                queryItem={!isNaN(query.selected_item) ? parseInt(query.selected_item) : null}
                handleActivePage={handleActivePage}
                activeItem={selectedType}
                //end routing
                handleObjectToolClick={handleObjectToolClick}
                openDownloadReportModal={openDownloadReportModal}
                openDownloadReportModalForMeasurments={openDownloadReportModalForMeasurments}
                openAddQuickNDTPointModal={openAddQuickNDTPointModal}
                getCameraPosition={getCameraPosition}
                viewer={viewer}
                openInspectionAssetModal={action => openInspectionAssetModal(action)}
                openDocumentsUploadModal={openDocumentsUploadModal}
                setModalState={setModalState}
                scrollToElement={scrollToElement}
                handleInspectionClick={handleInspectionClick}
                sharedModuleActionsMenu={
                  //filter out full screen mode modules
                  find(values(modulesFullScreen), item => item === selectedType)
                    ? [
                        {
                          title: 'INSPECTION.SWITCH_TO_FULLSCREEN',
                          action: switchToFullScreenMode,
                        },
                      ]
                    : []
                }
                view={view}
              />
            </CollapsibleToolbar>

            <CollapsibleToolbar
              collapsed={rightCollapsed || !activeDetailsPage}
              toggleToolbar={toggleToolbar}
              placement={placements.right}
              resizable={activeDetailsPage === detailsPages.inspect ? true : false}
              defaultWidth={DIMENSIONS.inspectionRightToolbar}
            >
              <RightToolbar
                //routing
                inspectionId={parseInt(query.inspection_id)}
                projectId={parseInt(query.project_id)}
                handleActivePage={handleActivePage}
                queryItem={!isNaN(query.selected_item) ? parseInt(query.selected_item) : null}
                router={router}
                route={route}
                //remove reducer saving delay
                activeDetailsPage={!get(query, 'details') && selectedType !== modules.details ? query.type : get(query, 'details')}
                //end routing
                getCameraPosition={getCameraPosition}
                viewer={viewer}
                openInspectionAssetModal={action => openInspectionAssetModal(action)}
                selectTool={selectTool}
                currentInsertion={currentInsertion}
                setCurrentInsertion={setCurrentInsertion}
                createNewDefect={createNewDefect}
                handleObjectToolClick={handleObjectToolClick}
                getDefectType={getDefectType}
                showObjectToolbox={showObjectToolbox}
                openDownloadReportModalSingleDefect={openDownloadReportModalSingleDefect}
                view={view}
              />
            </CollapsibleToolbar>
            <Modal {...modalData} modalDisabled={uploadInProgress} />
            <Modal {...createWOModalData} inspectionId={parseInt(query.inspection_id)} projectId={parseInt(query.project_id)} />
          </Fragment>
        )}
      </InspectionWrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    inspectionReducer: state.inspectionReducer,
    uploadInProgress: state.uploadReducer.uploadInProgress,
    createWOModalData: state.workOrderReducer.createWOModalData,
  };
};

const mapDispatchToProps = dispatch => ({
  toggleToolbar: (placement, implicitState) => dispatch(toggleToolbar(placement, implicitState)),
});

InspectionScreen.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(InspectionScreen);
