export const fields = {
  id: 'ID',
  name: 'Name',
  nextDate: 'NextTask',
};

export const componentFields = {
  id: 'ID',
  name: 'Name',
  critical: 'Critical',
  code: 'Code',
};

export const componentsTableConfig = [
  {
    title: 'Code',
    key: componentFields.code,
    class: 'cp-id',
  },
  {
    title: 'NAME',
    key: componentFields.name,
    type: componentFields.name,
    class: 'cp-name cp-template-name',
  },
  {
    key: componentFields.critical,
    type: componentFields.critical,
    class: 'linked-equipment critical-icon',
  },
];

export const tableConfig = [
  {
    title: 'ID',
    key: fields.id,
    type: fields.id,
    class: 'id-column',
    isSort: true,
  },
  {
    title: 'INSPECTION_MAINTENANCE_REGIMES.NAME',
    key: fields.name,
    type: fields.name,
    class: 'name-column__maintenance-regimes',
    isSort: true,
  },
  {
    title: 'INSPECTION_MAINTENANCE_REGIMES.NEXT_WO_TASK_DATE',
    key: fields.nextDate,
    type: fields.nextDate,
    class: 'next-task-column__maintenance-regimes',
    isSort: true,
  },
];

export const filterProps = {
  componentID: 'ComponentID',
  searchText: 'SearchText',
  sortByColumn: 'SortByColumn',
  sortDirection: 'SortDirection',
  statusFilter: 'StatusFilter',
  lastSeen: 'LastSeen',
  perPage: 'PerPage',
  hasNext: 'HasNext',
};

export const statuses = {
  draft: {
    value: 'DRAFT',
    title: 'MAINTENANCE_REGIME.DRAFT',
    icon: 'clock',
    isRegularStatus: true,
    skipPermissionCheck: true,
    iconProps: {
      active: true,
    },
  },
  raised: {
    value: 'RAISED',
    title: 'MAINTENANCE_REGIME.RAISED',
    icon: 'clock-filled',
    isRegularStatus: true,
    iconProps: {
      active: true,
    },
  },
  live: {
    value: 'LIVE',
    title: 'MAINTENANCE_REGIME.LIVE',
    icon: 'checkmark',
    isRegularStatus: true,
    iconProps: {
      active: true,
    },
  },
};

export const sortDirection = {
  asc: 'ASC',
  desc: 'DESC',
};

export const defaultFilters = {
  [filterProps.sortByColumn]: fields.id,
  [filterProps.sortDirection]: sortDirection.asc,
  [filterProps.perPage]: 10,
  [filterProps.lastSeen]: 0,
  [filterProps.searchText]: '',
  [filterProps.statusFilter]: [],
};
