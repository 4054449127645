import { statuses } from '../../../../components/isolation-certificates/constants/isolation-certificates-table';
import Comments from '../../common/comments';
import ModuleChangeStatusSection from '../../common/module-change-status-section';
import DetailsForm from '../components/details/details-form';
import FilesSection from '../components/details/files-section';
import IsolationComponents from '../components/details/isolation-components';
import KeyBoxesForm from '../components/details/key-boxes-form';
import PermitsDisplay from '../components/details/permits-display';
import { validate as detailsFormValidator } from '../validators/details-form-validators';
import { fields as detailsFields } from './details-form-constants';
import { initialValuesProp as isolationComponentsIntialValuesProp } from './isolation-components-constants';
import { fields as keyBoxFields } from './key-boxes-form-constants';

export const sections = {
  Details: {
    title: 'ISOLATION_CERTIFICATE.DETAILS_SECTION',
    component: DetailsForm,
    translatableTitle: true,
    fields: detailsFields,
    action: 'updateIsolationCertificateDetails',
    validate: detailsFormValidator,
    disabledForStatuses: [statuses.rejected.value, statuses.deIsolated.value],
  },
  Keybox: {
    title: 'ISOLATION_CERTIFICATE.KEYBOXES_SECTION',
    component: KeyBoxesForm,
    translatableTitle: true,
    fields: keyBoxFields,
    initialValuesProp: 'Keybox',
    action: 'assignKeyBox',
    disabledForStatuses: [statuses.rejected.value, statuses.live.value, statuses.underTest.value, statuses.longTerm.value, statuses.deIsolated.value],
    showError: false,
  },
  Isolations: {
    title: 'ISOLATION_CERTIFICATE.ISOLATIONS_SECTION',
    component: IsolationComponents,
    translatableTitle: true,
    initialValuesProp: isolationComponentsIntialValuesProp,
    sectionAction: 'toggleIsolationComponentsModal',
    disabledForStatuses: [
      statuses.rejected.value,
      statuses.approved.value,
      statuses.authorised.value,
      statuses.live.value,
      statuses.underTest.value,
      statuses.longTerm.value,
      statuses.deIsolated.value,
    ],
    showError: false,
  },
  Permits: {
    title: 'ISOLATION_CERTIFICATE.PERMITS_SECTION',
    component: PermitsDisplay,
    translatableTitle: true,
    showError: false,
  },
  Files: {
    title: 'ISOLATION_CERTIFICATE.FILES_SECTION',
    component: FilesSection,
    sectionAction: 'openEditFilesModal',
    translatableTitle: true,
    showError: false,
  },
  Comments: {
    title: 'ISOLATION_CERTIFICATE.COMMENTS_SECTION',
    component: Comments,
    translatableTitle: true,
    customAction: 'handleAddIsolationCertificateComment',
    emptyStateTitle: 'ISOLATION_CERTIFICATE.COMMENTS_EMPTY_STATE',
    showError: false,
    isHidden: true,
  },
  Approved: {
    title: 'ISOLATION_CERTIFICATE.APPROVED_SECTION',
    component: ModuleChangeStatusSection,
    translatableTitle: true,
    icon: statuses.approved.icon,
    hideSignatures: true,
    hideAssignee: true,
    witnessLabel: 'ISOLATION_CERTIFICATES.APPROVED_BY',
    showError: false,
  },
  Authorised: {
    title: 'ISOLATION_CERTIFICATE.AUTHORISED_SECTION',
    component: ModuleChangeStatusSection,
    translatableTitle: true,
    icon: statuses.authorised.icon,
    iconClass: 'authorized-status-icon',
    hideSignatures: true,
    hideAssignee: true,
    witnessLabel: 'ISOLATION_CERTIFICATES.AUTHORISED_BY',
    showError: false,
  },
  'Under Test': {
    title: 'ISOLATION_CERTIFICATE.UNDER_TEST_SECTION',
    component: ModuleChangeStatusSection,
    translatableTitle: true,
    icon: statuses.underTest.icon,
    showError: false,
  },
  Live: {
    title: 'ISOLATION_CERTIFICATE.LIVE_SECTION',
    component: ModuleChangeStatusSection,
    translatableTitle: true,
    icon: statuses.live.icon,
    showError: false,
  },
  'Long Term': {
    title: 'ISOLATION_CERTIFICATE.LONG_TERM_SECTION',
    component: ModuleChangeStatusSection,
    translatableTitle: true,
    icon: statuses.longTerm.icon,
    showError: false,
  },
  Deisolated: {
    title: 'ISOLATION_CERTIFICATE.DE_ISOLATED_SECTION',
    component: ModuleChangeStatusSection,
    translatableTitle: true,
    icon: statuses.deIsolated.icon,
    iconClass: 'deisolated-status-icon',
    showError: false,
  },
  'Populated Drawings': {
    title: 'ISOLATION_CERTIFICATE.POPULATED_DRAWINGS_SECTION',
    component: FilesSection,
    translatableTitle: true,
    filesProp: 'PopulatedDrawings',
    emptyStateTitle: 'ISOLATION_CERTIFICATE.POPULATED_DRAWINGS_EMPTY_STATE',
    sectionAction: 'openEditFilesModal',
    customAction: 'generateAndDownloadPDFPopulatedDrawings',
    isHidden: false,
    disabledForStatuses: [
      statuses.draft.value,
      statuses.raised.value,
      statuses.rejected.value,
      statuses.approved.value,
      statuses.authorised.value,
      statuses.live.value,
      statuses.underTest.value,
      statuses.longTerm.value,
      statuses.deIsolated.value,
    ],
    showError: false,
  },
};

export const customSectionDisabledStatuses = [statuses.rejected.value, statuses.deIsolated.value];
