import React from 'react';
import ExpandableSection from '../../../../../common/expandable-section/components/expandable-section';
import { filterSections, quickFiltersDefault } from '../../../constants/constants';
import PropTypes from 'prop-types';
import Button from '../../../../../common/form/components/button';
import RenderIf from '../../../../../common/render-if/components/render-if';
import { isEqual } from 'lodash';
import '../../../styles/dms-filters.scss';

const DMSFilters = ({ quickFiltersChange, value, labels, labelsLoading }, { t }) => {
  const displayResetButton = !isEqual(value, quickFiltersDefault);

  return (
    <div className="dms-filters">
      <div className="dms-filters__header">
        <h5 className={`dms-filters__header__title ${displayResetButton ? 'mb-12' : ''}`}>{t('FILTERS')}</h5>
        <RenderIf if={displayResetButton}>
          <Button height="md" variant="success-outline" text={t('DOCUMENT_MANAGEMENT_FILTERS.RESET')} onClick={() => quickFiltersChange(quickFiltersDefault)} />
        </RenderIf>
      </div>
      {Object.values(filterSections).map((item, index) => {
        return (
          item.component && (
            <ExpandableSection title={item.title ? t(item.title) : ''} key={`filter-${item.title}-${index}`}>
              <item.component quickFiltersChange={quickFiltersChange} value={value[item.valueKey] || {}} labels={labels} labelsLoading={labelsLoading} {...item.componentProps} />
            </ExpandableSection>
          )
        );
      })}
    </div>
  );
};

DMSFilters.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default DMSFilters;
