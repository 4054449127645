import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { isEmpty, some } from 'lodash';
import { ReactComponent as Grid } from '../assets/grid.svg';
import { ReactComponent as List } from '../assets/list.svg';
import constants from '../../start-workflow/constants/orientation-constants';

import '../styles/image-filter.scss';

class ImageFilter extends Component {
  render() {
    const { t } = this.context;
    const { orientation, images, selectAll, removeSelected, setOrientation, hideOrientationPicker, CustomComponent, removeLabel } = this.props;
    const selectAllEnabled = some(images, { selected: false });
    const deselectAllEnabled = some(images, { selected: true });
    const removeDisabled = isEmpty(images) || !some(images, { selected: true });

    return (
      <div className="image-filter">
        <div className="filters">
          {removeSelected && (
            <label className={`f-secondary-green link ${removeDisabled ? 'disabled' : ''}`} onClick={() => removeSelected()}>
              {t(removeLabel || 'PHOTOGRAMMETRY.FILTER_REMOVE')}
            </label>
          )}
          {selectAll && (
            <Fragment>
              <label className={`f-secondary-green link ${selectAllEnabled ? '' : 'disabled'}`} onClick={selectAllEnabled ? () => selectAll(true, images) : null}>
                {t('PHOTOGRAMMETRY.FILTER_SELECT_ALL')}
              </label>
              <label className={`f-secondary-green link ${deselectAllEnabled ? '' : 'disabled'}`} onClick={deselectAllEnabled ? () => selectAll(false, images) : null}>
                {t('PHOTOGRAMMETRY.FILTER_DESELECT_ALL')}
              </label>
            </Fragment>
          )}
        </div>
        {CustomComponent && <CustomComponent />}
        {!hideOrientationPicker && setOrientation && (
          <div className="change-view">
            <List onClick={() => setOrientation(constants.orientation.list)} className={`${constants.orientation.list === orientation ? 'active' : ''}`} />
            <Grid onClick={() => setOrientation(constants.orientation.grid)} className={`${constants.orientation.grid === orientation ? 'active' : ''}`} />
          </div>
        )}
      </div>
    );
  }
}

ImageFilter.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ImageFilter;
