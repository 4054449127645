export const inspectedCountConstants = {
  totalImages: 'TotalNumberOfImages',
  imagesNoDefect: 'ImagesWithNoDefect',
  imagesWithDefects: 'ImagesWithDefects',
  images1to3Severity: 'ImagesWithSeverity13',
  images4to7Severity: 'ImagesWithSeverity47',
  images8to10Severity: 'ImagesWithSeverity810',
};

export const defaultInspectedImagesCount = {
  [inspectedCountConstants.totalImages]: 0,
  [inspectedCountConstants.imagesNoDefect]: 0,
  [inspectedCountConstants.images1to3Severity]: 0,
  [inspectedCountConstants.images4to7Severity]: 0,
  [inspectedCountConstants.images8to10Severity]: 0,
};
