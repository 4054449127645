import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import MfaSelectTypeForm from './mfa-select-type-form';
import VerificationForm from '../../../common/verification-form/components/verification-form';
import SMSType from './sms-type';
import AuthenticatiorType from './authenticator-type';
import Modal from '../../../common/modal/components/modal';

import { getMfaTypes, setupMfa, verifyMfa } from '../actions/mfa-actions';

import Helpers from '../../../common/helpers';

import { mfaTypeFields, resendTimer } from '../constants/mfa-constants';
import routesConstants from '../../../common/routes-constants';
import loginConstants from '../../login/constants/login-constants';

import '../styles/mfa.scss';

class MFA extends Component {
  state = {
    selectedType: null,
    modalData: {
      isOpen: false,
    },
  };

  componentDidMount = () => {
    const { getMfaTypes } = this.props;
    getMfaTypes();
  };

  cancelClick = () => {
    Helpers.goTo(routesConstants.routes.protectedRoutes.profile.fullPath);
  };
  selectType = ({ [mfaTypeFields.type]: selectedType }, callback = () => null) => {
    const { setupMfa } = this.props;

    setupMfa(selectedType, () => {
      this.setState({ selectedType });
      callback();
    });
  };

  renderActiveScreen = () => {
    const { t } = this.context;
    const { mfaTypes, mfaTypeData, verifyMfa, mfaCodes } = this.props;
    const { selectedType } = this.state;

    switch (selectedType) {
      case loginConstants.verificationTypes.email:
        return (
          <VerificationForm
            {...{
              resend: setTimer => this.selectType({ [mfaTypeFields.type]: loginConstants.verificationTypes.email }, setTimer),
              resendExpiration: resendTimer,
              enableTimer: true,
              cancelClick: this.cancelClick,
              title: t('LOGIN.EMAIL_VERIFICATION_SUB_TITLE'),
              subtitle: t('LOGIN.EMAIL_VERIFICATION_DESCRIPTION'),
              onSubmit: data => verifyMfa(loginConstants.verificationTypes.email, data, this.cancelClick),
            }}
          />
        );
      case loginConstants.verificationTypes.authenticatior:
        return (
          <AuthenticatiorType
            cancelClick={this.cancelClick}
            data={mfaTypeData}
            verifyMfa={(data, callback) => verifyMfa(loginConstants.verificationTypes.authenticatior, data, callback, this.errorCallback)}
            mfaCodes={mfaCodes}
          />
        );
      case loginConstants.verificationTypes.sms:
        return <SMSType cancelClick={this.cancelClick} data={mfaTypeData} />;
      default:
        return (
          <MfaSelectTypeForm
            {...{
              mfaTypes,
              onSubmit: this.selectType,
              cancelClick: this.cancelClick,
            }}
          />
        );
    }
  };

  closeModal = () => {
    this.setState(prevProps => ({
      modalData: {
        ...prevProps.modalData,
        isOpen: false,
      },
    }));
  };

  errorCallback = () => {
    const { t } = this.context;

    this.setState({
      modalData: {
        isOpen: true,
        title: t('AUTHENTICATOR_MODAL.NOT_VALID_TITLE'),
        content: t('AUTHENTICATOR_MODAL.NOT_VALID_CONTENT'),
        confirmAction: this.closeModal,
        closeAction: this.closeModal,
      },
    });
  };

  render() {
    const { selectedType, modalData } = this.state;

    return (
      <div className="mfa">
        <div className={`mfa-container ${(selectedType && selectedType.toLowerCase()) || ''}`}>{this.renderActiveScreen()}</div>
        <Modal {...modalData} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  mfaTypes: state.mfaReducer.mfaTypes,
  mfaTypeData: state.mfaReducer.mfaTypeData,
  mfaCodes: state.mfaReducer.mfaCodes,
});

const mapDispatchToProps = dispatch => ({
  getMfaTypes: () => dispatch(getMfaTypes()),
  setupMfa: (type, callback) => dispatch(setupMfa(type, callback)),
  verifyMfa: (type, data, successCallback, errorCallback) => dispatch(verifyMfa(type, data, successCallback, errorCallback)),
});

MFA.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(MFA);
