import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import '../styles/info-popover.scss';
import '../styles/tooltip.scss';
import InfoPopover from './info-popover';

class InfoTooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settingsOpen: false,
      position: {},
      pageX: null,
      pageY: null,
    };
    this.node = React.createRef();
    this.dropdown = React.createRef();
  }
  componentDidUpdate = prevProps => {
    const { scrollPosition } = this.props;

    const prevscrollPosition = prevProps.scrollPosition;

    if (scrollPosition !== prevscrollPosition) {
      this.handleClickOutside();
    }
  };

  toggleSettings = (e, forceState) => {
    const { settingsOpen } = this.state;
    const { pageX, pageY } = e;

    const isOpen = typeof forceState === 'boolean' ? forceState : !settingsOpen;

    this.setState({ pageX });
    this.setState({ pageY });

    this.setState({ settingsOpen: isOpen });

    this.calcPosition(pageX, pageY);
  };

  calcPosition = (pageX, pageY) => {
    const { offsetX, offsetY } = this.props;
    const pos = { left: pageX + offsetX, top: pageY + offsetY };

    const dropdownHeight = get(this.dropdown, 'current.node.current.clientHeight');
    const dropdownWidth = get(this.dropdown, 'current.node.current.clientWidth');

    //if dropdown is near bottom of the page make it go up
    if (dropdownHeight && dropdownHeight + pageY > window.innerHeight) {
      pos.top = pageY - dropdownHeight;
    }
    if (dropdownWidth && pageX + dropdownWidth > window.innerWidth) {
      pos.left = pageX - dropdownWidth;
    }
    this.setState({ position: pos });
  };

  closeSettings = () => {
    this.setState({ settingsOpen: false });
  };

  renderInfoItem = () => {
    const { t } = this.context;
    const { actionsMenu, customComponent } = this.props;

    if (!actionsMenu && !customComponent) {
      return null;
    }
    if (typeof actionsMenu === 'object' && !isEmpty(actionsMenu)) {
      return (
        <div className="container">
          {Object.keys(actionsMenu).map((key, index) => {
            const value = actionsMenu[key];
            return (
              <Fragment key={index}>
                <div className="info-popover__label-container">
                  <span key={index} className="f-secondary-dark label">
                    {t(key)}
                  </span>
                </div>
                <div className="info-popover__value-container">
                  <span key={index} className="f-primary value">
                    {value || '--'}
                  </span>
                </div>
              </Fragment>
            );
          })}
        </div>
      );
    } else if (!isEmpty(actionsMenu)) {
      return (
        <div className="text-container">
          <span className="f-primary">{actionsMenu}</span>
        </div>
      );
    } else if (customComponent) {
      return <div className="custom-component-container">{customComponent}</div>;
    }
  };

  handleClickOutside = () => {
    const { clickOutsideHandler } = this.props;
    if (clickOutsideHandler && typeof clickOutsideHandler === 'function') {
      clickOutsideHandler();
    } else {
      this.closeSettings();
    }
  };

  render() {
    const { settingsOpen, position } = this.state;
    const {
      className,
      Component,
      onClick,
      opened,
      containerProps: { onMouseEnter, autoHandlePopover, onMouseLeave, ...restContainerProps },
      componentProps,
      title,
    } = this.props;
    const isOpen = opened !== null && typeof opened !== 'undefined' ? opened : settingsOpen;

    return (
      <div
        onClick={e => {
          e.stopPropagation();
          if (onClick && typeof onClick === 'function') {
            onClick(e);
          } else {
            this.toggleSettings(e);
          }
        }}
        onMouseEnter={e => {
          if (onMouseEnter && typeof onMouseEnter === 'function') {
            const { pageX, pageY } = e;

            this.setState({ pageX });
            this.setState({ pageY });

            onMouseEnter();

            if (autoHandlePopover) {
              this.toggleSettings(e, true);
            } else {
              this.calcPosition(pageX, pageY);
            }
          }
        }}
        onMouseLeave={e => {
          if (onMouseLeave && typeof onMouseLeave === 'function') {
            const { pageX, pageY } = e;

            this.setState({ pageX });
            this.setState({ pageY });

            onMouseLeave();

            if (autoHandlePopover) {
              this.toggleSettings(e, false);
            }
          }
        }}
        {...restContainerProps}
        className={`info-tooltip ${className || ''}`}
        ref={this.node}
      >
        {Component ? <Component {...componentProps} /> : <div>missing Component prop</div>}
        <InfoPopover position={position} customNode={this.node} clickOutsideHandler={this.handleClickOutside} isOpen={isOpen} anchor="bottom-left" ref={this.dropdown} title={title}>
          {(selected, hovered) => {
            return <Fragment>{isOpen ? this.renderInfoItem() : null}</Fragment>;
          }}
        </InfoPopover>
      </div>
    );
  }
}

InfoTooltip.contextTypes = {
  t: PropTypes.func.isRequired,
};

InfoTooltip.defaultProps = {
  offsetX: 0,
  offsetY: 0,
  containerProps: {},
};

const mapStateToProps = state => ({
  scrollPosition: state.navReducer.scrollPosition,
});

export default connect(mapStateToProps, null)(InfoTooltip);
