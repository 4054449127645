import React, { useEffect, useState } from 'react';
import NotificationsWrapper from './notifications-wrapper';
// TODO: tweak this: reusing the same styles from observations
import '../../observations/styles/component-defect-tab.scss';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { getComponentNotifications } from '../actions/notifications-api-calls';
import { get } from 'lodash';
import { defaultFilters, fields, filterProps } from '../constants/constants';
import Helpers from '../../../../../common/helpers';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../../common/permissions-constants';
import routesConstants from '../../../../../common/routes-constants';
import { modules } from '../../../constants/constants';
import EmptyState from '../../../../../common/empty-state/components/empty-state';
import RenderIf from '../../../../../common/render-if/components/render-if';

const ComponentsNotificationsTab = ({ location, getComponentNotifications, user, selectedComponent, inspectionId }) => {
  const { query } = location;
  const componentID = parseInt(get(query, 'selected_item')) || selectedComponent[fields.id];
  const [filters, setFilters] = useState({ ComponentID: componentID, ...defaultFilters });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const userHasAccess = Helpers.hasAccess({ user, visibleFor: PERMISSIONS[PERMISSION_TYPES.notifications].view.name });

  useEffect(() => {
    userHasAccess && componentID && componentID > 0 && fetchNotifications(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchNotifications = (filters, loadMore) => {
    if (!componentID || componentID < 1) return;

    const onSuccess = (items, newFilters) => {
      setLoading(false);
      setData(loadMore ? [...data, ...items] : items);
      setFilters(prev => ({ ...prev, ...newFilters }));
      if (loadMore) {
        // TODO: fix scrollIntoView for notifs tab, seems like the ref might cause the issue
        Helpers.scrollIntoView('notifications-table', `row-${filters[filterProps.lastSeen]}`, -525);
      }
    };
    getComponentNotifications(filters, onSuccess, setLoading);
  };

  const handleOnRowClick = row => {
    if (!row.ID) return;
    const path = user?.FullScreenEnabled ? routesConstants.routes.protectedRoutes.fullScreen.fullPath : routesConstants.routes.protectedRoutes.inspections.fullPath;
    const { query } = location;
    const newQuery = { ...query, type: modules.notifications, selected_item: row?.ID };
    const params = Object.keys(newQuery).map(key => {
      const obj = {};
      obj[key] = newQuery[key];
      return obj;
    });
    Helpers.goTo(path, params);
  };

  // TODO: check if wrapperClassName and customTableClassName can be reused
  return (
    <>
      <RenderIf if={userHasAccess}>
        <NotificationsWrapper
          data={data || []}
          filters={filters}
          wrapperClassName="component-defect-tab"
          loading={loading}
          fetchNotifications={fetchNotifications}
          customTableClassName="notifications-table"
          onRowClick={handleOnRowClick}
          isTab
          inspectionId={inspectionId}
        />
      </RenderIf>
      <RenderIf if={!userHasAccess}>
        <EmptyState icon="crossed-out-circle" iconSize="xs" message="COMPONENTS_TAB.NO_PERMISSION" title="COMPONENTS_TAB.NO_PERMISSION_TITLE" />
      </RenderIf>
    </>
  );
};

const mapStateToProps = state => ({
  user: state.userReducer,
});

const mapDispatchToProps = dispatch => ({
  getComponentNotifications: (filters, dataCallback, loadingCallback) => dispatch(getComponentNotifications(filters, dataCallback, loadingCallback)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ComponentsNotificationsTab));
