import React from 'react';

import FilesDisplay from './files-display';

const FilesSection = ({ module, filesProp, emptyStateTitle, forceEmptyState, customAction, handleCustomAction }) => {
  return (
    <div className="files-items">
      <FilesDisplay
        files={module?.[filesProp || 'Files']}
        emptyStateTitle={emptyStateTitle}
        showEmptyState
        forceEmptyState={forceEmptyState}
        handleFileClickCustomAction={file => handleCustomAction(customAction, { IsolationCertificateID: module.ID, FileID: file.FileID })}
      />
    </div>
  );
};

export default FilesSection;
