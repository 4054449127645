import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { isEmpty, isObject, isArray } from 'lodash';

import { ReactComponent as TreeViewIcon } from '../../assets/tree-view.svg';
import { ReactComponent as ListViewIcon } from '../../assets/list-view.svg';

import SingleItem from './single-item';

import Loader from '../../global-loader/components/simple-loader';

import { groupingOptions } from '../constants/constants';

import '../styles/items-renderer.scss';

class ItemsRender extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeView: props.defaultView,
    };
  }

  handleGroupingOptionClick = activeView => {
    const { handleGroupingOptionClick } = this.props;
    this.setState({ activeView });
    handleGroupingOptionClick(activeView);
  };

  renderItems = () => {
    const { t } = this.context;
    const { activeView } = this.state;
    const {
      items,
      handleItemClick,
      activeItem,
      hoveredItem,
      renderItemRightComponent,
      CustomEmptyState,
      getItemsGrouped,
      renderGroupItem,
      customSelectedFunction,
      customHoveredFunction,
      customIdentificatorFunction,
      cutomNameFunction,
      renderLeftComponent,
      renderEmptyGroupContent,
      isLoading,
    } = this.props;

    if (isEmpty(items)) {
      return (
        <div className="empty-state padding-horizontal">
          <div className="empty-state__wrapper">
            {!isLoading && (
              <>
                {CustomEmptyState ? (
                  <CustomEmptyState />
                ) : (
                  <>
                    <p className="f-secondary-dark empty-state__title">{t('INSPECTION_COMPONENTS.EMPTY_STATE')}</p>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      );
    } else {
      const grouped = getItemsGrouped ? getItemsGrouped(activeView) : items;
      if (isArray(grouped))
        return (
          <div className="padding-horizontal">
            {items.map((item, index) => {
              return (
                <SingleItem
                  key={index}
                  item={item}
                  onItemClick={(e, component) => handleItemClick(e, component)}
                  selectedItem={activeItem}
                  hoveredItem={hoveredItem}
                  renderItemRightComponent={renderItemRightComponent}
                  index={index}
                  customSelectedFunction={customSelectedFunction}
                  customHoveredFunction={customHoveredFunction}
                  customIdentificatorFunction={customIdentificatorFunction}
                  cutomNameFunction={cutomNameFunction}
                  renderLeftComponent={renderLeftComponent}
                />
              );
            })}
          </div>
        );
      else if (isObject(grouped)) {
        return Object.keys(grouped).map((key, index) => {
          return (
            <div className="padding-horizontal" key={index}>
              {renderGroupItem ? renderGroupItem(key, grouped[key], index) : <h5 className="f-primary">{key}</h5>}
              {!isEmpty(grouped[key])
                ? grouped[key].map((item, index) => {
                    return (
                      <SingleItem
                        key={index}
                        item={item}
                        onItemClick={(e, component) => handleItemClick(e, component)}
                        selectedItem={activeItem}
                        hoveredItem={hoveredItem}
                        renderItemRightComponent={renderItemRightComponent}
                        index={index}
                        customSelectedFunction={customSelectedFunction}
                        customHoveredFunction={customHoveredFunction}
                        customIdentificatorFunction={customIdentificatorFunction}
                        cutomNameFunction={cutomNameFunction}
                        renderLeftComponent={renderLeftComponent}
                      />
                    );
                  })
                : renderEmptyGroupContent && renderEmptyGroupContent(key)}
            </div>
          );
        });
      } else {
        return null;
      }
    }
  };

  render() {
    const { t } = this.context;
    const { isLoading, RestItems, enableGrouping, className, fullWidth } = this.props;
    const { activeView } = this.state;

    return (
      <div className={`items-renderer ${fullWidth ? 'full-width' : ''} ${className}`}>
        <Loader isLoading={isLoading} className="items-renderer__loader" />
        {(enableGrouping || RestItems) && (
          <div className="tools padding-horizontal">
            {enableGrouping && (
              <div className="grouping-items">
                <ListViewIcon
                  name={groupingOptions.regular}
                  onClick={() => this.handleGroupingOptionClick(groupingOptions.regular)}
                  className={`tools__icon svg-gray-g ${activeView === groupingOptions.regular ? 'active' : ''}`}
                  title={t('INSPECTION_COMPONENTS.TOOLS_LIST_VIEW_TITLE')}
                />
                <TreeViewIcon
                  name={groupingOptions.section}
                  onClick={() => this.handleGroupingOptionClick(groupingOptions.section)}
                  className={`tools__icon svg-gray-g ${activeView === groupingOptions.section ? 'active' : ''}`}
                  title={t('INSPECTION_COMPONENTS.TOOLS_TREE_VIEW_TITLE')}
                />
              </div>
            )}

            {RestItems && (
              <div className="rest-items">
                <RestItems />
              </div>
            )}
          </div>
        )}
        <div className="items-renderer__container">{this.renderItems()}</div>
      </div>
    );
  }
}

ItemsRender.defaultProps = {
  items: [],
  RestItems: null,
  isLoading: false,
  defaultView: groupingOptions.regular,
  enableGrouping: false,
  handleGroupingOptionClick: () => null,
  fullWidth: true,
  className: '',
};

ItemsRender.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ItemsRender;
