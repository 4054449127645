import actionTypes from '../constants/action-types';

const genericNotificationState = {
  isDisplayed: false,
  type: 'error',
  icon: '',
  text: '',
  iconProps: {},
  wrapperClassName: '',
};

export const genericNotificationReducer = (state = genericNotificationState, payload) => {
  switch (payload.type) {
    case actionTypes.SET_GENERIC_NOTIFICATION:
      return { ...state, ...payload.data };
    default:
      return state;
  }
};
