import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { FORMS } from '../../../../common/constants';
import CustomInput from '../../../../common/form/components/input';
import UneditableInfo from '../../../../common/form/components/uneditable-info';
import { formFields } from '../../constants/constants';
import PropTypes from 'prop-types';
import CustomProperties from '../../../../common/custom-property/components/custom-properties';
import AddNewPropertyButton from './ch-right-side-add-new-button';
import { validateLevel } from '../../validators/ch-right-side-form';

const CHRightSideBasicForm = ({ isDisabled, properties, getSuggestions, addProperty, updateProperty, deleteProperty }, { t }) => {
  const { name, code, costCentre, location, owner, maintenanceID, createdAt, createdBy } = formFields;
  return (
    <form noValidate className={`${isDisabled ? 'ch-right-side-form__disabled' : ''}`}>
      <p className="f-primary ch-right-side-form__title">{t('COMPONENT_HIERARCHY_LEVEL_DETAILS.TITLE')}</p>
      <Field id={code.id} name={code.name} component={isDisabled ? UneditableInfo : CustomInput} placeholder={t(code.label)} label={t(code.label)} type="text" size="lg" isRequired />
      <Field
        id={name.id}
        name={name.name}
        component={isDisabled ? UneditableInfo : CustomInput}
        placeholder={t(name.label)}
        label={t(name.label)}
        type="text"
        size="lg"
        isRequired
        additionalClassName="text-transform-none"
        labelClass="text-transform-none"
      />
      <Field id={costCentre.id} name={costCentre.name} component={isDisabled ? UneditableInfo : CustomInput} placeholder={t(costCentre.label)} label={t(costCentre.label)} type="text" size="lg" />
      <Field id={location.id} name={location.name} component={isDisabled ? UneditableInfo : CustomInput} placeholder={t(location.label)} label={t(location.label)} type="text" size="lg" />
      <Field id={owner.id} name={owner.name} component={isDisabled ? UneditableInfo : CustomInput} placeholder={t(owner.label)} label={t(owner.label)} type="text" size="lg" />
      <Field
        id={maintenanceID.id}
        name={maintenanceID.name}
        component={isDisabled ? UneditableInfo : CustomInput}
        placeholder={t(maintenanceID.label)}
        label={t(maintenanceID.label)}
        type="text"
        size="lg"
      />
      <CustomProperties
        isDisabled={isDisabled}
        formName={FORMS.componentHierarchyLevelCustomProp}
        properties={properties || []}
        getProperties={() => null}
        updateProperty={property => updateProperty(property)}
        addProperty={data => addProperty(data)}
        deleteProperty={(data, callback) => deleteProperty(data, callback)}
        getSuggestions={getSuggestions}
        customButton={!isDisabled ? <AddNewPropertyButton text={t('ADD_PROPERTY')} /> : <></>}
      />
      <div className="ch-right-side-form__uneditable-info">
        <Field id={createdBy.id} name={createdBy.name} component={UneditableInfo} placeholder={t(createdBy.label)} label={t(createdBy.label)} type="text" size="lg" />
        <Field id={createdAt.id} name={createdAt.name} component={UneditableInfo} placeholder={t(createdAt.label)} label={t(createdAt.label)} type="text" size="lg" />
      </div>
    </form>
  );
};

CHRightSideBasicForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default reduxForm({ form: FORMS.componentHierarchyLevel, validate: validateLevel, enableReinitialize: true, touchOnChange: true, destroyOnUnmount: true })(CHRightSideBasicForm);
