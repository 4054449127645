import PropTypes from 'prop-types';
import React from 'react';

import ComponentDetails from '../../inspections/components/right-toolbar/component-details';
import { componentFields } from '../constants/constants';
import PickComponentScreen from './pick-component-screen';
import TaggingTools from './tagging-tools';

import { withRouter } from 'react-router';
import ConfirmOnExitWrapper from '../../shared/common-component/components/use-confirm-wrapper';
import '../style/right-toolbar.scss';

const RightToolbar = ({
  updateComponent,
  objectToolClick,
  createComponent,
  component,
  duplicateComponent,
  tags,
  markOnPdfClick,
  notInThisPdfClick,
  confirmTagClick,
  fetchSuggestions,
  components,
  handleMergeCvWithNewComponent,
  handleMergeCvWithExistingComponent,
  inspectionId,
  handleRemoveDrawing,
  selectedDrawing,
  handleDrawingListClick,
  setComponentDetails,
  componentDetails,
  filter,
  customDeleteCallback,
  projectId,
  fileId,
  activePdfPageNumber,
}) => {
  const renderRightSide = () => {
    if (component && component[componentFields.componentId])
      return (
        <ComponentDetails
          showGeometryWarning={false}
          updateDefect={updateComponent}
          objectToolClick={objectToolClick}
          createComponent={createComponent}
          defaultComponent={component}
          queryItem={component[componentFields.componentId]}
          isPdfScreen={true}
          duplicateComponent={duplicateComponent}
          inspectionId={inspectionId}
          projectId={projectId}
          customDeleteCallback={customDeleteCallback}
          underlyingFileId={fileId}
          customComponent={() => (
            <TaggingTools
              {...{
                tags,
                confirmTagClick,
                markOnPdfClick,
                notInThisPdfClick,
                handleRemoveDrawing,
                selectedDrawing,
                component,
                handleDrawingListClick,
                filter,
                activePdfPageNumber,
              }}
            />
          )}
        />
      );
    else if (component && component[componentFields.cvFoundID]) {
      return (
        <PickComponentScreen
          {...{
            fetchSuggestions,
            component,
            components,
            handleMergeCvWithNewComponent,
            handleMergeCvWithExistingComponent,
            initialValues: {
              component: ' ',
            },
            notInThisPdfClick,
            setComponentDetails,
            componentDetails,
          }}
        />
      );
    }
  };

  return <div className="r-toolbar">{renderRightSide()}</div>;
};

RightToolbar.contextTypes = {
  t: PropTypes.func.isRequired,
};

const WrappedRightToolbar = props => (
  <ConfirmOnExitWrapper isDirty={props.isDirty} router={props.router} route={props.route} clearUnsavedChangesDirty={props.clearUnsavedChangesDirty}>
    <RightToolbar {...props} />
  </ConfirmOnExitWrapper>
);

export default withRouter(WrappedRightToolbar);
