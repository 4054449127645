import actionTypes from '../constants/action-types';

export const getContactListSuccess = data => {
  return {
    data,
    type: actionTypes.GET_CONTACT_LIST_SUCCESS,
  };
};

export const getContactListFailure = data => {
  return {
    data,
    type: actionTypes.GET_CONTACT_LIST_FAILURE,
  };
};
