import React from 'react';
import { commentActionTypes } from '../constants/constants';
import SmallDropdown from '../../small-dropdown/components/small-dropdown';

const CommentDropdown = ({ onClick, row, deleteCommentPermission }) => {
  const menuOptions = [
    {
      title: 'COMMENTS.DELETE_COMMENT',
      action: () => onClick(row, commentActionTypes.delete),
      access: {
        visibleFor: deleteCommentPermission,
      },
      separator: true,
      isHighlighted: true,
      icon: '',
    },
  ];
  return (
    <SmallDropdown
      actionsMenu={menuOptions}
      getClickAction={(action, b, selfClose, e) => {
        e?.preventDefault && e.preventDefault();
        selfClose();
        if (action && typeof action === 'function') {
          action();
        }
      }}
      offsetX={10}
    />
  );
};

export default CommentDropdown;
