import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import { debounce, get } from 'lodash';
import SelectUsersTeamsMultiple from '../../../../../../common/user-team/components/select-users-teams-multiple';
import { formConstants } from '../../constants/alarm-constants';
import '../../styles/alarm-form-field.scss';

const AlarmAssigneesFields = ({ fields, formValues, searchUsersAndTeams }, { t }) => {
  const [usersAndTeams, setUsersAndTeams] = useState([]);
  const addedUsersAndTeams = get(formValues, fields.name);

  useEffect(() => {
    handleSearchUsersAndTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchUsersAndTeams = searchText => {
    searchUsersAndTeams(searchText || '', users => {
      setUsersAndTeams(users);
    });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchUsersAndTeamsDebounced = useCallback(debounce(handleSearchUsersAndTeams, 300), []);

  return (
    <SelectUsersTeamsMultiple
      handleUsersAndTeamsSearch={e => handleSearchUsersAndTeamsDebounced(e.target.value)}
      addAssignee={item => fields.push(item)}
      removeAssignee={(_item, index) => fields.remove(index)}
      searchLabelText={formConstants.fields.notifyAssignees.label}
      usersAndTeams={usersAndTeams}
      addedUsersAndTeams={addedUsersAndTeams}
      showAddedUsersTeamsList={false}
    />
  );
};

AlarmAssigneesFields.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default AlarmAssigneesFields;
