import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';

import CustomSelect from '../../../../../../../common/form/components/select';
import Icon from '../../../../../../../common/icon/components/icon';
import RenderIf from '../../../../../../../common/render-if/components/render-if';
import { getKeyBoxesFields, fields, dropdownFields, keyBoxDetailsFields } from '../../constants/key-boxes-form-constants';
import { statusTypes } from '../../../../../../keybox/constants/constants';
import { fields as sectionFields } from '../../constants/constants';
import { getAvailableKeyBoxes } from '../../actions/isolation-certificate-details-actions';
import { remove, uniqBy } from 'lodash';

import '../../../../../styles/key-boxes-form.scss';

class KeyBoxesForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      keyBoxes: [],
    };
  }
  componentDidMount = () => {
    this.fetchAvailableKeyBoxes();
  };

  componentDidUpdate = prevProps => {
    const { keyBoxes } = this.state;
    const { module } = this.props;

    if (
      (prevProps.module?.[sectionFields.archived] !== module?.[sectionFields.archived] || prevProps.module?.[sectionFields.status] !== module?.[sectionFields.status]) &&
      (!keyBoxes || !keyBoxes.length)
    ) {
      this.fetchAvailableKeyBoxes();
    }
  };

  fetchAvailableKeyBoxes = () => {
    const { projectId, moduleId, getAvailableKeyBoxes, module, disabled } = this.props;

    if (!module[sectionFields.archived] && !disabled) {
      getAvailableKeyBoxes(
        {
          [getKeyBoxesFields.projectId]: projectId,
          [getKeyBoxesFields.moduleId]: moduleId,
        },
        keyBoxes => this.checkIfIsolationCertificateHasKeyBoxAssigned(keyBoxes)
      );
    }
  };
  getKeyBoxStatus = status => {
    const { t } = this.context;

    switch (status) {
      case statusTypes.inUse:
        return (
          <div className="key-box-status">
            <p className="f-secondary-dark">{t('KEYBOX.IN_USE')}</p>
          </div>
        );
      case statusTypes.locked:
        return (
          <div className="key-box-status">
            <Icon name="lock" className="locked-icon" />
            <p className="f-secondary-dark">{t('KEYBOX.LOCKED')}</p>
          </div>
        );
      default:
        return null;
    }
  };
  returnUnassignElement = () => {
    const { t } = this.context;

    return { ID: null, Name: t('ISOLATION_CERTIFICATES.UNASSIGN_KEYBOX') };
  };
  /**
   * Function to check if a Isolation Certificate Has A KeyBox Assigned
   * If there is no keyBox assigned only the state is set with keyBoxes param
   * Else it adds an element to unassign keyBox
   */
  checkIfIsolationCertificateHasKeyBoxAssigned = keyBoxes => {
    const { module } = this.props;
    if (module && module[keyBoxDetailsFields.keyBoxProp]) {
      this.setState({ keyBoxes: [this.returnUnassignElement(), ...keyBoxes] });
      return;
    }
    this.setState({ keyBoxes });
  };

  filterKeyBoxList = keyBoxID => {
    const { keyBoxes } = this.state;
    const { keyBox } = fields;
    const newKeyBoxes = keyBoxID ? uniqBy([this.returnUnassignElement(), ...keyBoxes], keyBox.initialValuesName) : remove(keyBoxes, item => item.ID !== null);
    this.setState({ keyBoxes: newKeyBoxes });
  };

  render() {
    const { keyBox } = fields;
    const { t } = this.context;
    const { keyBoxes } = this.state;
    const { disabled, module } = this.props;
    const keyBoxDetails = Object.assign({}, module ? module[keyBoxDetailsFields.keyBoxProp] : {});
    return (
      <form className="key-boxes-form" noValidate>
        <div className="key-boxes-select">
          <RenderIf if={module[sectionFields.archived] || disabled}>
            <p className="f-primary">{`${t(keyBox.label)}: ${keyBoxDetails?.Name ? keyBoxDetails.Name : '-'}`}</p>
          </RenderIf>
          <RenderIf if={!module[sectionFields.archived] && !disabled}>
            <Field
              id={keyBox.id}
              name={keyBox.name}
              label={keyBox.label}
              placeholder={t(keyBox.placeholder)}
              component={CustomSelect}
              data={keyBoxes}
              size="lg"
              disabled={disabled}
              valueField={dropdownFields.valueField}
              textField={dropdownFields.textField}
              withHiddenError
              renderValue={item => item[dropdownFields.textField]}
              optionComponent={({ dataItem, onSelect }) => {
                return (
                  <div
                    className={`key-boxes-select-item ${dataItem[dropdownFields.status] === statusTypes.locked ? 'key-boxes-select-item-disabled' : ''}`}
                    onClick={() => {
                      if (dataItem[dropdownFields.status] === statusTypes.locked) {
                        return;
                      }

                      onSelect({ ...dataItem, [dropdownFields.prevKeyBoxID]: keyBoxDetails.ID, callback: () => this.filterKeyBoxList(dataItem.ID) });
                    }}
                  >
                    <p className={`bold ${dataItem[dropdownFields.status] === statusTypes.locked ? 'f-secondary-dark' : 'f-primary'}`}>{dataItem[dropdownFields.textField]}</p>
                    {this.getKeyBoxStatus(dataItem[dropdownFields.status])}
                  </div>
                );
              }}
            />
          </RenderIf>
        </div>
        <div className="display-item">
          <p className="f-secondary-dark bold">{t('ISOLATION_CERTIFICATE.ISSUED_KEYS')}</p>
          <p className="f-primary bold">{keyBoxDetails[keyBoxDetailsFields.issuedKeys] || 0}</p>
        </div>
        <div className="display-item">
          <p className="f-secondary-dark bold">{t('ISOLATION_CERTIFICATE.FREE_KEYS')}</p>
          <p className="f-primary bold">{keyBoxDetails[keyBoxDetailsFields.freeKeys] || 0}</p>
        </div>
      </form>
    );
  }
}

KeyBoxesForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  getAvailableKeyBoxes: (data, callback) => dispatch(getAvailableKeyBoxes(data, callback)),
});

KeyBoxesForm = connect(null, mapDispatchToProps)(KeyBoxesForm);

export default reduxForm({
  enableReinitialize: true,
  touchOnChange: true,
  destroyOnUnmount: true,
})(KeyBoxesForm);
