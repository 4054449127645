import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { debounce, find, get, isEmpty } from 'lodash';

import Modal from '../../../../common/modal/components/modal';
import Stepper from '../../../../common/stepper/components/stepper';
import { ComponentPicker3D, ComponentPickerCH, ComponentPickerPDF } from '../../../../common/question-components/components';
import CreatePermitForm from './create-permit-form';

import { getWorkOrders, searchComponents } from '../../actions/work-order-actions';
import { addComponent, createPermit, deleteComponent, updatePermitDates } from '../../actions/permit-actions';
import { fields as componentConstants } from './constants/components-constants';
import { permitFormSteps } from './constants/permit-form-constants';
import { componentFields, fields } from './constants/permit-form-constants';

import ReducerHelpers from '../../../../common/reducer-helpers';
import Helpers from '../../../../common/helpers';

import '../../styles/create-work-order-modal.scss';
import { filterFields, defaultPagingObj, defaultQuickFilters } from '../../constants/work-order-constants';
import { setWorkOrderFilters, setWorkOrderPaging, setWorkOrders } from '../../actions/action-creators';
import { dateFields } from './constants/section-form-constants';
import { change } from 'redux-form';
import { FORMS } from '../../../../common/constants';

class CreatePermitModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: permitFormSteps.firstStep,
      lastStep: permitFormSteps.secondStep,
      stepperData: [
        {
          name: 'CREATE_PERMIT_POPUP.STEP_1',
          stepValue: permitFormSteps.firstStep,
          description: 'CREATE_PERMIT_POPUP.STEP_1_DESC',
          disabled: false,
        },
        {
          name: 'CREATE_PERMIT_POPUP.STEP_2',
          stepValue: permitFormSteps.secondStep,
          description: 'CREATE_PERMIT_POPUP.STEP_2_DESC',
          disabled: false,
        },
      ],
      components: [],
      permitComponents: null,
      copyWODetailsCheckbox: false,
      searchWorkOrderText: '',
      permitDetails: {},
      selectedWorkOrder: null,
    };
    this.searchChangeDebounced = debounce(this.getWorkOrders, 250);
  }

  componentDidMount = () => {
    const { setWorkOrders, setWorkOrderFilters } = this.props;
    const filters = Object.assign({}, defaultQuickFilters);
    if (filters && filters[filterFields.startInThirtyDaysFilter]) filters[filterFields.startInThirtyDaysFilter] = false;
    if (filters && filters[filterFields.startInNextWeekFilter]) filters[filterFields.startInNextWeekFilter] = false;
    if (filters && filters[filterFields.onHold]) filters[filterFields.onHold] = false;
    if (filters && filters[filterFields.assignedToMeFilter]) filters[filterFields.assignedToMeFilter] = false;
    if (filters && filters[filterFields.archived]) filters[filterFields.archived] = false;
    if (filters && filters[filterFields.overdueFilter]) filters[filterFields.overdueFilter] = false;
    if (filters && filters[filterFields.statusFilter]) filters[filterFields.statusFilter] = [];

    setWorkOrderFilters(filters);
    setWorkOrders([]);
    this.setState({
      selectedWorkOrder: null,
    });
  };

  componentWillUnmount = () => {
    const { setWorkOrders } = this.props;
    setWorkOrders([]);
    this.setState({
      selectedWorkOrder: null,
    });
  };

  componentDidUpdate = prevProps => {
    const { copyWODetailsCheckbox } = this.state;
    const { setWorkOrderPaging, workOrders, workOrdersLoading, workOrdersFilters, changeField } = this.props;

    if (copyWODetailsCheckbox && (!workOrders || workOrders.length === 0) && !workOrdersLoading) {
      setWorkOrderPaging(defaultPagingObj);
      this.searchChangeDebounced(workOrdersFilters, false, true, () => {
        // will skip the loop, uncheck the checkbox because request has failed
        this.setState({ copyWODetailsCheckbox: !copyWODetailsCheckbox });
        changeField(FORMS.createPermitForm, fields.copyWorkOrderDetails.name, !copyWODetailsCheckbox);
      });
    }
  };

  getWorkOrders = (params, loadMore = false, isLite = true, errorCallback = null) => {
    const { getWorkOrders, workOrderPagingObject, setWorkOrderPaging, location, workOrdersFilters } = this.props;
    const { searchWorkOrderText } = this.state;
    const { query } = location;

    const inspectionId = parseInt(get(query, 'inspection_id'), 10);
    if (params && params[filterFields.startInThirtyDaysFilter]) params[filterFields.startInThirtyDaysFilter] = false;
    if (params && params[filterFields.statusFilter]) params[filterFields.statusFilter] = [];

    getWorkOrders(
      inspectionId,
      { ...workOrdersFilters, ...workOrderPagingObject, ...params, [filterFields.searchText]: searchWorkOrderText },
      loadMore,
      (arr, incomingFilter, c) => {
        setWorkOrderPaging({ ...workOrderPagingObject, [filterFields.totalItems]: incomingFilter[filterFields.totalItems], [filterFields.hasNext]: incomingFilter[filterFields.hasNext] });

        if (loadMore) {
          Helpers.scrollIntoView('table-container', `row-${arr?.length - 1}`);
        }
      },
      isLite,
      errorCallback
    );
  };

  loadMore = () => {
    const { workOrders, workOrderPagingObject, setWorkOrderPaging } = this.props;

    setWorkOrderPaging({
      ...workOrderPagingObject,
      [filterFields.lastSeen]: workOrders.length || 0,
    });

    this.searchChangeDebounced(null, true);
  };

  handleSelectWorkOrder = workOrder => {
    if (workOrder) {
      this.setState({
        selectedWorkOrder: workOrder,
      });
    } else {
      this.setState({
        selectedWorkOrder: null,
      });
    }
  };

  handleSubmit = values => {
    let { activeStep } = this.state;
    const { closeAction, updatePermitDates, permits, permitDetailsData } = this.props;

    if (activeStep === permitFormSteps.firstStep) {
      this.setState({
        permitDetails: values,
      });
      this.createPermit(values);
      this.handleSearchComponents('');
    } else if (activeStep === permitFormSteps.secondStep) {
      if (values[fields.startDate.name] || values[fields.endDate.name]) {
        // update dates
        values[fields.startDate.name] = Helpers.getUnixDate(Helpers.dateToUnix(values[fields.startDate.name]) * 1000);
        values[fields.endDate.name] = Helpers.getUnixDate(Helpers.dateToUnix(values[fields.endDate.name]) * 1000);
        values[dateFields.timeInterval.name] = 'Hour';
        values[dateFields.revalidationTime.name] = 12;
        values[componentConstants.permit.name] = permitDetailsData[componentConstants.componentId.name];
        const data = { modules: permits, values: values };
        updatePermitDates(data);
      }

      closeAction();
    }

    this.setStep(true);
  };

  setStep = forward => {
    const { closeAction } = this.props;
    let { activeStep, lastStep } = this.state;
    const nextStep = forward ? ++activeStep : --activeStep;
    if (nextStep < permitFormSteps.firstStep || nextStep > lastStep) {
      closeAction();
      return;
    }

    this.setState({ activeStep: nextStep });
  };

  createPermit = values => {
    const { permitComponents } = this.state;
    const { user, inspectionId, createPermit, permits, filters } = this.props;

    const ComponentIDs = (permitComponents || []).map(el => el[componentFields.id]);

    createPermit(
      {
        ...values,
        InspectionID: inspectionId,
        ComponentIDs,
      },
      user.Name,
      Object.assign([], permits),
      Object.assign({}, filters)
    );
  };

  handleSearchComponents = SearchText => {
    const { searchComponents, location } = this.props;
    const { query } = location;

    const inspectionId = parseInt(get(query, 'inspection_id'), 10);
    searchComponents(inspectionId, SearchText, data => this.setState({ components: data }), { PerPage: Number.MAX_SAFE_INTEGER, LastSeen: 0 });
  };

  handleSearchWorkOrders = SearchText => {
    const { selectedWorkOrder } = this.state;

    // reset selected work order on search type so that work order dropdown can open
    if (selectedWorkOrder) {
      this.setState({
        selectedWorkOrder: null,
      });
    }

    this.setState({
      searchWorkOrderText: SearchText,
    });
    this.searchChangeDebounced();
  };

  addComponentToPermit = items => {
    const { permitComponents } = this.state;
    const { permitDetailsData, addComponent } = this.props;
    const component = items && items.length ? items[0] : {};
    const existingComponent = find(permitComponents, { [componentFields.id]: component[componentFields.id] });

    // prevent adding existing component
    if (existingComponent && existingComponent[componentFields.id]) {
      return;
    }

    items = items.map(component => {
      return { ...component, Name: component.SystemType === componentFields.clusterType && !component[componentFields.name] ? component[componentFields.id] : component[componentFields.name] };
    });

    const values = {
      [componentConstants.permit.name]: permitDetailsData[componentConstants.componentId.name],
      [componentConstants.component.name]: component[componentConstants.componentId.name],
    };
    addComponent(values, () => this.setState(state => ({ permitComponents: [...(state.permitComponents || []), ...items] })));
  };

  removeComponentFromPermit = item => {
    const { permitDetailsData, deleteComponent } = this.props;

    const component = Number.isInteger(item) ? { [componentFields.id]: item } : item;

    const values = {
      [componentConstants.permit.name]: permitDetailsData[componentConstants.componentId.name],
      [componentConstants.component.name]: component[componentConstants.componentId.name],
    };

    deleteComponent(values, () => {
      this.setState(state => ({ permitComponents: ReducerHelpers.removeItemByProp(state.permitComponents || [], component, componentFields.id) }));
    });
  };

  handleComponentPicker3D = () => {
    const { location } = this.props;
    const { query } = location;

    const inspectionId = parseInt(get(query, 'inspection_id'), 10);
    const closeAction = () => {
      this.setState({ componentSelectionModal: { isOpen: false } });
    };

    this.setState({
      componentSelectionModal: {
        isOpen: true,
        CustomContent: dynamicProps => (
          <ComponentPicker3D
            {...dynamicProps}
            handleComponentSelected={(_moduleId, components) => this.addComponentToPermit(components)}
            handleDeleteComponent={(_moduleId, componentId) => this.removeComponentFromPermit(componentId)}
            inspectionId={inspectionId}
            closeAction={closeAction}
          />
        ),
        customClassName: 'modal-no-max-height modal-large',
        type: 'none',
        closeAction,
      },
    });
  };

  handleComponentPickerPDF = () => {
    const { location } = this.props;
    const { query } = location;

    const inspectionId = parseInt(get(query, 'inspection_id'), 10);
    const closeAction = () => {
      this.setState({ componentSelectionModal: { isOpen: false } });
    };

    this.setState({
      componentSelectionModal: {
        isOpen: true,
        CustomContent: dynamicProps => (
          <ComponentPickerPDF
            {...dynamicProps}
            handleComponentSelected={(_moduleId, components) => this.addComponentToPermit(components)}
            handleDeleteComponent={(_moduleId, componentId) => this.removeComponentFromPermit(componentId)}
            inspectionId={inspectionId}
            closeAction={closeAction}
          />
        ),
        customClassName: 'modal-no-max-height modal-large',
        type: 'none',
        closeAction,
      },
    });
  };

  handleComponentPickerCH = () => {
    const { inspectionId } = this.props;
    const { t } = this.context;
    const closeAction = () => {
      this.setState({ componentSelectionModal: { isOpen: false } });
    };

    this.setState({
      componentSelectionModal: {
        isOpen: true,
        CustomContent: dynamicProps => (
          <ComponentPickerCH
            {...dynamicProps}
            handleComponentSelected={(_moduleId, components) => this.addComponentToPermit(components)}
            handleDeleteComponent={(_moduleId, componentId) => this.removeComponentFromPermit(componentId)}
            inspectionId={inspectionId}
            closeAction={closeAction}
          />
        ),
        customClassName: 'modal-no-max-height modal-large',
        type: 'none',
        title: t('QUESTION_COMPONENT_PICKER.CHOOSE_COMPONENTS'),
        closeAction,
        customCloseAction: closeAction,
      },
    });
  };

  render() {
    const { permitDetails, stepperData, activeStep, lastStep, components, selectedWorkOrder, permitComponents, componentSelectionModal, copyWODetailsCheckbox } = this.state;
    const { className, closeAction, workOrders, onSearchPermitTemplates, templates, workOrdersLoading, workOrderPagingObject } = this.props;
    const activeStepObj = find(stepperData, item => item.stepValue === activeStep);

    return (
      <div className={`create-work-order-modal ${className || ''}`}>
        <Stepper {...{ stepperData, activeStep }} />
        <CreatePermitForm
          onSubmit={this.handleSubmit}
          stepAction={this.setStep}
          initialValues={{
            [fields.startDate.name]: !isEmpty(permitDetails) && permitDetails[fields.startDate.name] ? new Date(permitDetails[fields.startDate.name] * 1000) : null,
            [fields.endDate.name]: !isEmpty(permitDetails) && permitDetails[fields.endDate.name] ? new Date(permitDetails[fields.endDate.name] * 1000) : null,
          }}
          activeStep={activeStep}
          activeStepObj={activeStepObj}
          lastStep={lastStep}
          templates={templates}
          permitComponents={permitComponents}
          searchComponents={this.handleSearchComponents}
          components={components}
          addComponentToPermit={this.addComponentToPermit}
          removeComponentFromPermit={this.removeComponentFromPermit}
          handleComponentPicker3D={this.handleComponentPicker3D}
          handleComponentPickerPDF={this.handleComponentPickerPDF}
          handleComponentPickerCH={this.handleComponentPickerCH}
          handleWorkOrderDetailsCopy={(_e, value) => this.setState({ copyWODetailsCheckbox: value })}
          handleSelectWorkOrder={this.handleSelectWorkOrder}
          selectedWorkOrder={selectedWorkOrder}
          copyWODetailsCheckbox={copyWODetailsCheckbox}
          workOrders={workOrders}
          closeModal={closeAction}
          onSearchPermitTemplates={onSearchPermitTemplates}
          onSearchWorkOrders={this.handleSearchWorkOrders}
          workOrderPagingObject={workOrderPagingObject}
          workOrdersLoading={workOrdersLoading}
          loadMore={this.loadMore}
        />
        <Modal {...componentSelectionModal} components={components} question={{ ID: 1, QuestionsComponents: permitComponents }} />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  workOrderPagingObject: state.workOrderReducer.pagingObject,
  workOrders: state.workOrderReducer.workOrders,
  workOrdersLoading: state.workOrderReducer.workOrdersLoading,
  workOrdersFilters: state.workOrderReducer.filters,
  permits: state.permitsReducer.permits,
  user: state.userReducer,
  permitDetailsData: state.permitsReducer.permitDetailsData,
  filters: state.permitsReducer.filters,
});

const mapDispatchToProps = dispatch => ({
  searchComponents: (inspection_id, searchText, successCallback, otherParams) => dispatch(searchComponents(inspection_id, searchText, successCallback, otherParams)),
  createPermit: (data, createdByName, permits, filter, callback) => dispatch(createPermit(data, createdByName, permits, filter, callback)),
  updatePermitDates: data => dispatch(updatePermitDates(data)),
  getWorkOrders: (inspection_id, params, loadMore, successCallback, isLite = false, errorCallback) => dispatch(getWorkOrders(inspection_id, params, loadMore, successCallback, isLite, errorCallback)),
  setWorkOrders: data => dispatch(setWorkOrders(data)),
  setWorkOrderPaging: pagingObject => dispatch(setWorkOrderPaging(pagingObject)),
  setWorkOrderFilters: filters => dispatch(setWorkOrderFilters(filters)),
  addComponent: (data, callback) => dispatch(addComponent(data, callback)),
  deleteComponent: (data, callback) => dispatch(deleteComponent(data, callback)),
  changeField: (formName, fieldName, value) => dispatch(change(formName, fieldName, value)),
});

CreatePermitModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreatePermitModal));
