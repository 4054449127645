import HistoryItemAreaGroupChange from '../../../common/history-item/components/history-item-area-group-changed';
import HistoryItemColorDisplay from '../../../common/history-item/components/history-item-color-display';
import inspectionWorkflowConstants from '../../inspection-workflow/constants/inspection-workflow-constants';

export const areaGroupConstants = {
  fields: {
    id: 'ID',
    name: 'Name',
  },
};

export const formConstants = {
  fields: {
    id: 'ID',
    name: 'Name',
    color: 'Colour',
    description: 'Description',
    camPos: 'CameraPosition',
    transparency: 'Transparency',
    modelDetails: 'ModelDetails',
    geometry: 'Geometry',
    clipTask: 'Clipping',
    selectedClipTask: 'SelectedClipTask',
    areaGroupId: 'AreaGroupID',
    areaGroup: 'AreaGroup',
    areaGroupName: 'AreaGroupName',
    status: 'status',
    createdDate: 'CreatedAt',
    createdBy: 'CreatedBy',
    createByUserID: 'CreatedByUserID',
  },
};
export const textAreaMaxChars = 2000;

export const modelDetailsConstants = {
  fields: {
    width: 'width',
    height: 'height',
    depth: 'depth',
    radius: 'radius',
    radiusTop: 'radiusTop',
    radiusBottom: 'radiusBottom',
    tubeRadius: 'tubeRadius',
    rotation: 'rotation',
    scale: 'scale',
  },
};

export const shapeTypes = {
  cube: 'CUBE',
  cylinder: 'CYLINDER',
  sphere: 'SPHERE',
  ring: 'RING',
};

export const defaultModelDetails = {
  [shapeTypes.cube]: {
    [modelDetailsConstants.fields.width]: 1,
    [modelDetailsConstants.fields.height]: 1,
    [modelDetailsConstants.fields.depth]: 1,
  },
};

export const defaultArea = {
  [formConstants.fields.name]: 'New area',
  [formConstants.fields.color]: inspectionWorkflowConstants.severity.green.color,
  [formConstants.fields.transparency]: 1,
};

export const tableConfig = [
  {
    title: 'AREAS.ID',
    key: formConstants.fields.id,
    enableSort: true,
  },
  {
    title: 'AREAS.NAME',
    key: formConstants.fields.name,
    enableSort: true,
    type: formConstants.fields.name,
    className: 'inline-left',
  },
  {
    key: formConstants.fields.status,
    type: formConstants.fields.status,
    className: 'inline-right',
  },
];

export const sortingDirection = {
  asc: 'ASC',
  desc: 'DESC',
};

export const filterParams = {
  searchText: 'SearchText',
  sortByColumn: 'SortByColumn',
  sortDirection: 'SortDirection',

  groupFilter: 'GroupByFilter',
  createdByFilter: 'CreatedByFilter',
  dateFrom: 'DateFrom',
  dateTo: 'DateTo',

  lastSeen: 'LastSeen',
  perPage: 'PerPage',
  totalItems: 'TotalNumber',
  hasNext: 'HasNext',
};

export const defaultPagingObj = {
  [filterParams.lastSeen]: 0,
  [filterParams.perPage]: 50,
  [filterParams.totalItems]: 0,
  [filterParams.hasNext]: false,
};
export const defaultSortingFilter = {
  [filterParams.sortByColumn]: formConstants.fields.id,
  [filterParams.sortDirection]: sortingDirection.asc,
};

export const tabNames = {
  details: 'DETAILS',
  history: 'HISTORY',
};

export const toolbarItems = {
  [tabNames.details]: {
    name: tabNames.details,
    label: 'AREA_DETAILS.DETAILS_TAB',
  },
  [tabNames.history]: {
    name: tabNames.history,
    label: 'AREA_DETAILS.HISTORY_TAB',
  },
};

export const historyFilterParams = {
  lastSeen: 'LastSeen',
  perPage: 'PerPage',
  sortByColumn: 'ModifiedAt',
  sortDirection: 'SortDirection',
  totalItems: 'TotalNumber',
  hasNext: 'HasNext',
};

export const historyDefaultPagingObj = {
  [historyFilterParams.lastSeen]: 0,
  [historyFilterParams.perPage]: 20,
  [historyFilterParams.totalItems]: 0,
  [historyFilterParams.hasNext]: false,
};

export const historyItems = {
  Name: {
    title: 'AREA_HISTORY.AREA_NAME',
  },
  Colour: {
    title: 'AREA_HISTORY.AREA_COLOUR',
    component: HistoryItemColorDisplay,
  },
  Geometry: {
    subProp: 'coordinates',
    title: 'COMPONENT_HISTORY.GEOMETRY',
    decimals: 2,
  },
  CameraPosition: {
    title: 'AREA_HISTORY.AREA_CAMERA_POSITION',
    decimals: 2,
  },
  Description: {
    title: 'AREA_HISTORY.AREA_DESCRIPTION',
  },
  AreaGroupID: {
    title: 'AREA_HISTORY.AREA_GROUP_ID',
    component: HistoryItemAreaGroupChange,
  },
  Clipping: {
    title: 'AREA_HISTORY.CLIP_TASK',
    translatable: true,
  },
  ModelDetails: {
    title: 'AREA_HISTORY.MODEL_DETAILS',
    hideValue: true,
  },
};

export const areaModificationEvents = {
  area_created: {
    title: 'AREA_HISTORY.CREATED_EVENT',
    event: 'area_created',
  },
  area_deleted: {
    title: 'AREA_HISTORY.DELETE_EVENT',
    event: 'area_deleted',
  },
  area_updated: {
    title: 'AREA_HISTORY.UPDATED_EVENT',
    event: 'area_updated',
  },
};

export const groupHistoryItems = {
  Name: {
    title: 'AREA_GROUP_HISTORY.AREA_NAME',
  },
};

export const areaGroupModificationEvents = {
  'area-group_created': {
    title: 'AREA_GROUP_HISTORY.CREATED_EVENT',
    event: 'area-group_created',
    linkDisabled: true,
  },
  'area-group_deleted': {
    title: 'AREA_GROUP_HISTORY.DELETE_EVENT',
    event: 'area-group_deleted',
    linkDisabled: true,
  },
  'area-group_updated': {
    title: 'AREA_GROUP_HISTORY.UPDATED_EVENT',
    event: 'area-group_updated',
    linkDisabled: true,
  },
};

export const eventTypePrefixes = {
  areaGroup: 'area-group',
  area: 'area',
};
