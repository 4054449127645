import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, orderBy } from 'lodash';

import IsolationComponentsForm from './isolation-components-form';
import FilesManipulation from './files-manipulation';
import Button from '../../../../../../../common/form/components/button';
import { ComponentPicker } from '../../../../../../../common/question-components/components';
import { fields } from '../../constants/isolation-components-constants';
import { validate } from '../../validators/isolation-component-validators';

import '../../../../../styles/isolation-components-modal.scss';
import Icon from '../../../../../../../common/icon/components/icon';

const IsolationComponentsModal = (
  {
    isolationCertificateId,
    components,
    selectedComponents = [],
    handleComponentSelected,
    handleComponentPicker3D,
    handleComponentPickerPDF,
    handleComponentPickerCH,
    handleUpdateIsolationComponent,
    handleDeleteIsolationComponent,
    toggleUploadModal,
    handleDeleteFile,
    closeAction,
    handleScreenshotFrom3D,
    toggleComponentsOrderModal,
  },
  { t }
) => {
  const handleComponentChange = (values, _b, c) => {
    if (!c.pristine) {
      // if the form has validations, validate it
      if (typeof validate === 'function') {
        const errors = validate(values);
        if (!isEmpty(errors)) {
          return;
        }
      }

      handleUpdateIsolationComponent(values);
    }
  };

  const onComponentSelected = (certificateId, components) => {
    if (components && components.length > 0) {
      handleComponentSelected(certificateId, components);
    }
  };

  const displayComponents = orderBy(selectedComponents, [fields.isolationOrder.name], ['asc']);

  return (
    <div className="isolation-components-modal">
      {components && components.length > 0 && (
        <ComponentPicker
          components={components}
          questionId={isolationCertificateId}
          handleComponentSelected={onComponentSelected}
          handleComponentPicker3D={() => handleComponentPicker3D()}
          handleComponentPickerPDF={() => handleComponentPickerPDF()}
          handleComponentPickerCH={() => handleComponentPickerCH()}
          selectedComponents={selectedComponents}
        />
      )}
      {selectedComponents && selectedComponents.length > 0 && (
        <div className="selected-components">
          <p className="f-secondary-dark">{t('ISOLATION_CERTIFICATE.ADD_COMPONENTS_TITLE', { number: selectedComponents.length })}</p>
          <div className="selected-components-container">
            {displayComponents.map((component, index) => (
              <div key={`isolation-component-${index}`}>
                <div className="component-title-wrapper">
                  <p className="f-primary">{`${index + 1}.`}</p>
                  <div className="component-item">
                    <div className="component-name-container">
                      <p className="f-primary">{component[fields.componentName.name]}</p>
                      <Icon name="close" className="delete-action" onClick={() => handleDeleteIsolationComponent(isolationCertificateId, component[fields.component.name])} />
                    </div>
                    <IsolationComponentsForm
                      initialValues={Object.assign({ [fields.isolationCertificate.name]: isolationCertificateId }, component)}
                      form={`isolation-component-form-${index}`}
                      onChange={handleComponentChange}
                      componentOrder={component[fields.order.name]}
                      formIndex={index}
                    />
                    <FilesManipulation
                      componentId={component[fields.component.name]}
                      files={component[fields.files.name]}
                      toggleUploadModal={toggleUploadModal}
                      handleDeleteFile={handleDeleteFile}
                      isInModal
                      handleScreenshotFrom3D={handleScreenshotFrom3D}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className={`isolations-modal-footer ${selectedComponents && selectedComponents.length > 0 ? 'isolations-modal-footer-with-border' : ''}`}>
        {selectedComponents && selectedComponents.length > 1 && (
          <p className="action-title f-secondary-green bold" onClick={() => toggleComponentsOrderModal(true)}>
            {t('ISOLATION_CERTIFICATE.ORDER_ACTION_TITLE')}
          </p>
        )}
        <div className="action-buttons-container">
          <Button variant="success-outline" height="md" width="sm" text={t('ISOLATION_CERTIFICATE.CANCEL_BUTTON')} onClick={closeAction} />
          <Button className="save-btn" height="md" width="sm" variant="success" text={t('ISOLATION_CERTIFICATE.SAVE_BUTTON')} onClick={closeAction} />
        </div>
      </div>
    </div>
  );
};

IsolationComponentsModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

IsolationComponentsModal.propTypes = {
  isolationCertificateId: PropTypes.number,
  components: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
    })
  ),
  selectedComponents: PropTypes.arrayOf(
    PropTypes.shape({
      ComponentID: PropTypes.number,
      DeisolationOrder: PropTypes.number,
      DesiredState: PropTypes.string,
      Files: PropTypes.array,
      IsolationMethod: PropTypes.string,
      IsolationOrder: PropTypes.number,
      Name: PropTypes.string,
      ComponentName: PropTypes.string,
      NormalPosition: PropTypes.string,
    })
  ),
  handleComponentSelected: PropTypes.func.isRequired,
  handleComponentPicker3D: PropTypes.func,
  handleComponentPickerPDF: PropTypes.func,
  handleUpdateIsolationComponent: PropTypes.func.isRequired,
  handleDeleteIsolationComponent: PropTypes.func.isRequired,
  toggleUploadModal: PropTypes.func.isRequired,
  handleDeleteFile: PropTypes.func.isRequired,
  handleScreenshotFrom3D: PropTypes.func.isRequired,
  toggleComponentsOrderModal: PropTypes.func.isRequired,
};

export default IsolationComponentsModal;
