export const fields = {
  versionDate: 'VersionDate',
  versionNumber: 'VersionNumber',
  versionDescription: 'VersionDescription',
  isActive: 'IsActive',
  fileVersion: 'FileVersion',
  numberOfFileVersions: 'NumberOfFileVersions',
  name: 'name',
  fileName: 'FileName',
  fileType: 'FileType',
  versionID: 'VersionID',
  id: 'ID',
};

export const fieldsValidatorConstants = {
  [fields.versionDescription]: {
    maxChars: 500,
  },
  [fields.versionNumber]: {
    maxChars: 20,
  },
};
