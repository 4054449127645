import React from 'react';
import SmallDropdown from '../../../../common/small-dropdown/components/small-dropdown';
import { ActionTypesToolbar, fields } from '../../constants/constants';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../common/permissions-constants';

const CHTableDropdown = ({ onClick, row }, { t }) => {
  const menuOptions = [
    {
      title: 'COMPONENT_HIERARCHY_TABLE_DROPDOWN.VIEW',
      action: () => onClick(row, ActionTypesToolbar.view),
      access: {
        visibleFor: PERMISSIONS[PERMISSION_TYPES.componentsHierarchy].view.name,
      },
    },
    {
      title: 'COMPONENT_HIERARCHY_TABLE_DROPDOWN.EDIT',
      action: () => onClick(row, ActionTypesToolbar.edit),
      access: {
        visibleFor: PERMISSIONS[PERMISSION_TYPES.componentsHierarchy].edit.name,
        id: row[fields.createdByID],
        ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.componentsHierarchy].create.name,
      },
    },
    {
      title: 'COMPONENT_HIERARCHY_TABLE_DROPDOWN.DELETE',
      action: () => onClick(row, ActionTypesToolbar.delete),
      separator: true,
      isHighlighted: true,
      access: {
        visibleFor: PERMISSIONS[PERMISSION_TYPES.componentsHierarchy].delete.name,
      },
    },
  ];

  return (
    <SmallDropdown
      actionsMenu={menuOptions}
      getClickAction={(action, b, selfClose, e) => {
        e?.preventDefault && e.preventDefault();
        selfClose();
        if (action) {
          if (typeof action === 'function') {
            action();
          }
        }
      }}
      offsetX={10}
    />
  );
};

export default CHTableDropdown;
