import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TermsAndConditions extends Component {
  render() {
    return (
      <div className="terms-and-conditions">
        <h4 className="p1">
          <span className="s1">HyBird Ltd. Terms and Conditions</span>
        </h4>
        <p className="p1">
          <b>1. Definitions and Interpretation</b>
        </p>
        <p className="p1">1. Unless the context requires otherwise, in these Terms and Conditions and in a Subscription:</p>
        <table className="space">
          <tr>
            <td>
              <b>Annual Services</b>
            </td>
            <td>means the provision by HyBird Ltd. of the Services specified in a Subscription for a fixed period of twelve (12) months.</td>
          </tr>
          <tr>
            <td>
              <b>HyBird Ltd. </b>
            </td>
            <td>HyBird Ltd, a company registered in England with number 09863415 and its registered office at Unit 4, 45 Holmes Road, London, United Kingdom, NW5 3AN.</td>
          </tr>
          <tr>
            <td>
              <b>HyBird Ltd. Platform</b>
            </td>
            <td>means HyBird Ltd.’s proprietary web platform which can be accessed at insite.hybirdtech.com or any hybirdtech.com domain.</td>
          </tr>
          {/* <tr>
            <td>“Annual Services”</td>
            <td>means the provision by HyBird Ltd. of the Services specified in a Subscription for a fixed period of twelve (12) months.</td>
          </tr> */}
          <tr>
            <td>
              <b>HyBird Ltd. Pricing and Payments Policy</b>
            </td>
            <td>means the then-current version of HyBird Ltd.’s pricing and payments policy in respect of HyBird Ltd. Services.</td>
          </tr>
          <tr>
            <td>
              <b>HyBird Ltd. Services</b>
            </td>
            <td>
              means the workflow automation software tools with the functionality, features and other benefits, and subject to the workflow and monthly actions limits, associated with a “Guest”,
              “Company”, or “Admin”.
            </td>
          </tr>{' '}
          <tr>
            <td>
              <b>Business Days</b>
            </td>
            <td>Mondays to Fridays, excluding English Bank and public holidays.</td>
          </tr>{' '}
          <tr>
            <td>
              <b>Charges</b>
            </td>
            <td>
              means:{' '}
              <ul>
                <li>in the case of Annual Services, the annual subscription charges or;</li>
                <li>in the case of Monthly Services, the monthly subscription charges,</li>
              </ul>
            </td>
          </tr>{' '}
          <tr>
            <td>
              <b>Contract</b>
            </td>
            <td>
              means a contract between HyBird Ltd. and the Customer made up of a Subscription, these Terms and Conditions and any documents expressly referred to in either of them, and any amendments
              to that contract from time totime.
            </td>
          </tr>{' '}
          <tr>
            <td>
              <b>Customer</b>
            </td>
            <td>means the person or entity identified as such in a Subscription.</td>
          </tr>
          <tr>
            <td>
              <b>Customer Data</b>
            </td>
            <td>means any data provided by the Customer to HyBird Ltd.or created by the Customer under or in connection with aContract.</td>
          </tr>
          <tr>
            <td>
              <b>Customer Personal Data</b>
            </td>
            <td>means any Personal Data provided by the Customer to HyBird Ltd. under or in connection with a Contract and in respect of which the Customer is a Data Controller.</td>
          </tr>
          <tr>
            <td>
              <b>Confidential Information</b>
            </td>
            <td>
              means any information disclosed or made available (whether in writing, verbally or otherwise) by either party(<b>"Discloser”</b>) to the other party (<b>“Recipient”</b>) including:
              <ul>
                <li>any information that is marked, or at the time of disclosure is otherwise designated, as being confidential;</li>
                <li>
                  any information that would be regarded as confidential by a reasonable business person in or relating to the business, affairs operations, processes, products, inventions, know-how,
                  trade secrets, designs or software of the Discloser (including, in the case of HyBird Ltd.);and
                </li>

                <li>any information or analysis derived from any of the information referred to in (a) or (b).</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <b>Data Controller</b>
            </td>
            <td>has the meaning given to it in the Data Protection Legislation.</td>
          </tr>
          <tr>
            <td>
              <b>Data Processor</b>
            </td>
            <td>has the meaning given to it in the Data Protection Legislation.</td>
          </tr>
          <tr>
            <td>
              <b>Data Protection Legislation</b>
            </td>
            <td>
              means:
              <ul>
                <li>the EU Data Protection Directive (Directive 95/46/EC), the Data Protection Act 1998 and Electronic Communications (EC Directive) Regulations 2003;</li>
                <li>he EU General Data Protection Regulation (Regulation (EU) 2016/679);</li>
                <li>Data Protection Act 2018; and</li>
                <li>
                  any other data protection laws and regulations, orders and any codes of practice, guidelines and recommendations issued by the information commissioner’s office or any replacement or
                  equivalent body, as amended and in force from time to time.
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <b>Force Majeure Event</b>
            </td>
            <td>
              an event, or a series of related events, that is outside thereasonable control of the party affected, including: failures of the internet or any public telecommunications network; hacker
              attacks; denial of service attacks; virus or other malicious software attacks or infections; power failures; industrial disputes affecting any third party; changes to the law; disasters,
              explosions, fires, floods, riots, terrorist attacks and wars; failure of computer systems or infrastructure owned or managed by a party’ssubcontractor or supplier.
            </td>
          </tr>
          <tr>
            <td>
              <b>Intellectual Property Rights</b>
            </td>
            <td>
              patents, rights in inventions, know how, show how and trade secrets, copyright and related rights, moral rights, registered designs, design rights, database rights, semiconductor
              topography rights, trade marks and service marks, trade names, business names, brand names, get up, logos, domain names and URLs, rights in unfair competition, goodwill and rights to sue
              for passing off and any other intellectual property rights (in each case, whether or not registered, and including all applications to register and rights to apply to register anyof them
              and all rights to sue for any past or present infringement of them) and all rights or forms of protection having equivalent or similar effect in any jurisdiction.
            </td>
          </tr>
          <tr>
            <td>
              <b>Monthly Services</b>
            </td>
            <td>means the provision by HyBird Ltd. of the Services specified in a Subscription on a monthly basis.</td>
          </tr>
          <tr>
            <td>
              <b>Personal Data</b>
            </td>
            <td>has the meaning given to it in the Data Protection Legislation.</td>
          </tr>
          <tr>
            <td>
              <b>Platform</b>
            </td>
            <td>refers to any software platform HyBird Ltd. offers on the cloud, or on premise.</td>
          </tr>
          <tr>
            <td>
              <b>Processing</b>
            </td>
            <td>has the meaning given to it in the Data Protection Legislation.</td>
          </tr>
          <tr>
            <td>
              <b>Services</b>
            </td>
            <td>
              means:
              <ul>
                <li>the HyBird Ltd. Services; and</li>
                <li>the Platform Services,or, as required by the context, either of them.</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <b>Specifications</b>
            </td>
            <td>means the descriptions of the HyBird Ltd. Services on theHyBird Ltd. Platform.</td>
          </tr>
          <tr>
            <td>
              <b>Subscription</b>
            </td>
            <td>means an order for the supply of Services that is submitted by the Customer via the HyBird Ltd. Platform and is accepted in writing by HyBird Ltd.</td>
          </tr>
          <tr>
            <td>
              <b>Support Services</b>
            </td>
            <td>
              means the support and maintenance services that are available in relation to a “Free”, “Starter”, “Integrator” or “Enterprise” package, as further described on the HyBird Ltd. Support
              Policy.
            </td>
          </tr>
          <tr>
            <td>
              <b>Term</b>
            </td>
            <td>has the meaning given to it in clause 2.1.</td>
          </tr>
          <tr>
            <td>
              <b>Workflows</b>
            </td>
            <td>has the meaning given to it in clause 8.3.</td>
          </tr>
        </table>
        <p className="p2">2. In the Contract, a reference to a statute or statutory provision includes a reference to:</p>
        <ul>
          <li>that statute or statutory provision as modified, consolidated and/or re-enacted from time to time; and</li>
          <li>any subordinate legislation made under that statute or statutory provision.</li>
        </ul>
        <p className="p1">3. The clause headings do not affect the interpretation of the Contract.</p>
        <p className="p1">
          4.In the Contract, general words shall not be given a restrictive interpretation by reason of being preceded or followed by words indicating a particular class of acts, matters or things.
        </p>
        <p className="p1">5. In case of a conflict or inconsistency between these Terms and Conditions and any provision in a Subscription, the provision in the Subscription prevails.</p>
        <p className="p1">
          <b>2. Term</b>
        </p>
        <p className="p2">
          1. Subject to earlier termination in accordance with clause 14, the Contract will continue in force until cancelled by either party in accordance with the HyBird Ltd. Pricing and Payments
          Policy, such cancellation to be effective:
        </p>
        <ul>
          <li>in the case of Annual Services, at the end of the then-current 12-month subscription period; or</li>
          <li>in the case of Monthly Services, at the end of the then-current one-month subscription period,</li>
          <li>(the“Term”).</li>
        </ul>
        <p className="p2">2. The parties agree that:</p>
        <ul>
          <li>the Annual Services will renew automatically for successive, 12-month subscription periods; and</li>
          <li>the Monthly Services will renew automatically for successive, one-month subscription periods.</li>
        </ul>
        <p className="p1">
          <b>3. HyBird Ltd. Services</b>
        </p>
        <p className="p1">
          1. Subject to the Customer complying with clauses 3.2 and 3.3, HyBird Ltd. grants the Customer a non-exclusive, non-transferable licence to access the HyBird Ltd. Platform duringthe Term for
          the sole purpose of accessing and using the HyBird Ltd. Services for its business purposes.
        </p>
        <p className="p2">2. The Customer agrees that it will throughout the Term:</p>
        <ul>
          <li>use the HyBird Ltd. Services in a professional manner and only for the purposes for which they are made available;</li>
          <li>use the HyBird Ltd. Services in accordance with all applicable laws; and</li>
          <li>choose a strong password and keep all login credentials secure and confidential, and otherwise ensure that no unauthorised person gains access to the HyBird Ltd. Services.</li>
        </ul>
        <p className="p2">3. The Customer agrees that it will not:</p>
        <ul>
          <li>sub-license, rent, lease, loan, or sell its right to access the HyBird Ltd. Services to any third party, or use the HyBird Ltd. Services for the benefit of any third party; or</li>
          <li>
            not use the HyBird Ltd. Services in any way that is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity; or
          </li>
          <li>send spam or other unwelcomed communications to others; or</li>
          <li>breach the intellectual property or other proprietary rights of HyBird Ltd. orany third party; or</li>
          <li>upload to the HyBird Ltd. Platform anything that contains software viruses, worms, or any other harmful code; or</li>
          <li>reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code for the HyBird Ltd. Platform or any related technology, or any part thereof; or</li>
          <li>“scrape,” “crawl” or “spider” the HyBird Ltd. Platform, whether by manual means or using automated software, devices, scripts robots; or</li>
          <li>
            interfere with the operation of, or place an unreasonable load on, the HyBird Ltd. Platform, including by way of spam, denial of service attacks or viruses, or otherwise use the HyBird
            Ltd. Platform in any way that causes, or may cause, damage to it, provided that (for the avoidance of doubt) nothing in this clause shall prevent or restrict the Customer from creating,
            using, copying, selling or otherwise exploiting Workflows.
          </li>
        </ul>
        <p className="p2">
          4. If the Customer breaches of any of the conditions or restrictions set out in clauses3.2 or 3.3, then HyBird Ltd. may with immediate effect and without any obligation to provide notice:
        </p>
        <ul>
          <li>suspend the Customer’s access to the HyBird Ltd. Platform and use of the HyBird Ltd. Services; and/or</li>
          <li>terminate the Contract.</li>
        </ul>
        <p className="p1">
          <b>4. Support Services and Service Availability</b>
        </p>
        <p className="p1">
          1. HyBird Ltd. shall use all reasonable commercial endeavours to ensure that the availability of the HyBird Ltd. Services and Platforms during any calendar month during the Term (“Service
          Availability”) exceeds 99.7%.
        </p>
        <p className="p2">2. For the purposes of calculating Service Availability under clause 4.2, any periods of non-availability of the HyBird Ltd. Services caused by:</p>
        <ul>
          <li>scheduled or planned maintenance carried out by HyBird Ltd.; or</li>
          <li>non-availability of the internet, or a failure of the Customer’s equipment, or a power outage at the Customer’s premises; or</li>
          <li>any act or omission of the Customer, or any person acting on behalf of the Customer, which prevents or delays the availability of the HyBird Ltd. Services; or</li>
          <li>a Force Majeure Event.</li>
          shall be disregarded
        </ul>
        <p className="p1">
          3. If the Customer has purchased any paid Package of Annual Services and ServiceAvailability is less than 99.7% during three consecutive calendar months during the Term, then the Customer
          may treat such non-availability as a material breach which is not of remedy for the purposes of clause 14.1
        </p>
        <p className="p1">
          <b>5. Customer Data</b>
        </p>
        <p className="p2">1. The Customer hereby grants to HyBird Ltd. the following non-exclusive licences:</p>
        <ul>
          <li>
            to collect, copy, store, distribute, export, edit and translate the Customer Data to the extent reasonably required for the performance of HyBird Ltd.'s obligations and the exercise of
            HyBird Ltd.'s rights under these Terms and Conditions, together with the right to sub-license these rights to its hosting, connectivity and telecommunications service providers to the
            extent reasonably required for the performance of HyBird Ltd.'s obligations and the exercise of HyBird Ltd.'s rights under the Contract; and
          </li>
          <li>
            to collect, store, aggregate, analyse and create copies of the Customer Data for the purposes of optimising and improving the Services, and creating new or derivative products and
            services.
          </li>
        </ul>
        <p className="p2">2. The Customer warrants to HyBird Ltd. that the use of the Customer Data by HyBird Ltd. in accordance with the Contract will not:</p>
        <ul>
          <li>breach the provisions of any law, statute or regulation;</li>
          <li>infringe the Intellectual Property Rights or other legal rights of any person;or</li>
          <li>give rise to any cause of action against HyBird Ltd.,</li>
          in each case in any jurisdiction and under any applicable law.
        </ul>
        <p className="p1">
          <b>6. Confidentiality</b>
        </p>
        <p className="p2">1. The Recipient shall:</p>
        <ul>
          <li>keep the Confidential Information of the Discloser strictly confidential;</li>
          <li>
            not disclose the Discloser’s Confidential Information to any person without the Discloser’s prior written consent, and then only under conditions of confidentiality no less onerous than
            those contained in theseTerms and Conditions; and
          </li>

          <li>
            use the same degree of care to protect the confidentiality of the Discloser’s Confidential Information as the Recipient uses to protect its own confidential information of a similar
            nature, being at least a reasonable degree of care.
          </li>
        </ul>
        <p className="p1">
          2. Notwithstanding clause 6.1, the Recipient may disclose the Discloser’s Confidential Information to its officers, employees, professional advisers, insurers, agents and subcontractors who
          are bound by a written agreement or professional obligation to protect the confidentiality of such Confidential Information.
        </p>
        <p className="p2">3. This clause 6 imposes no obligations upon the Recipient with respect to the Discloser’s Confidential Information that:</p>
        <ul>
          <li>is known to the Recipient before disclosure under or in connection with the Contract, and is not subject to any other obligation of confidentiality;</li>
          <li>is or becomes publicly known through no act or default of the Recipient; or</li>
          <li>is obtained by the Recipient from a third party in circumstances where theRecipient has no reason to believe that there has been a breach of an obligation of confidentiality.</li>
        </ul>
        <p className="p1">
          4. The restrictions in this clause 6 do not apply to the extent that any Confidential Information is required to be disclosed by any law or regulation, by any judicial or governmental order
          or request, or pursuant to disclosure requirements relating to the listing of the stock of the Recipient on any recognised stock exchange.
        </p>
        <p className="p1">
          5. The provisions of this clause 6 shall continue in force for a period of five (5) yearsfollowing the expiry or earlier termination of the Contract, at the end of which period they will
          cease to have effect.
        </p>
        <p className="p1">
          <b>7. Data Protection</b>
        </p>
        <p className="p1">
          1.. The parties agree that, in respect of any Customer Personal Data that is providedby the Customer to HyBird Ltd. then, for the purposes of the Data Protection Legislation, the Customer is
          the Data Controller and HyBird Ltd. is the Data Processor of such Customer Personal Data
        </p>
        <p className="p2">2.HyBird Ltd. will, at all times:</p>

        <ul>
          <li>
            process the Customer Personal Data only to the extent, and in such a manner, as is necessary for the purposes of the Contract and in accordance with the Customer’s lawful written
            instructions from time to time;
          </li>
          <li>
            ensure the reliability of all its personnel who have access to Customer Personal Data and, in particular, ensure that any person authorised to process Customer Personal Data in connection
            with the Contract is subject to a duty of confidentiality;
          </li>

          <li>
            provide the Customer, at the Customer’s own expense, with reasonable co-operation and assistance in relation to the Customer’s obligations and rights under Data Protection Legislation;
          </li>
          <li>
            implement and maintain technical and organisational measures against the unauthorised or unlawful processing to, and the accidental loss or destruction of, or damage to, Customer Personal
            Data;
          </li>
          <li className="with-cildren-and-dot">
            promptly notify the Customer, and provide such co-operation, assistance and information as the Customer may reasonably require, if HyBird Ltd.:
            <ul className="dashed">
              <li>
                receives any complaint, notice or communication which relates to the processing of the Customer Personal Data under the Contractor to either party’s compliance with Data Protection
                Legislation; or
              </li>
              <li>becomes aware of any personal data breach unless it is unlikely to result in a risk to a data subject;</li>
            </ul>
          </li>

          <li>
            keep a written record of all processing of Customer Personal Data carriedout in the course of the HyBird Ltd. Services and provide the Customer orits third-party representatives a copy of
            HyBird Ltd.’s most recent audit results to demonstrate HyBird Ltd.’s compliance with its obligations as a processor under the Contract; and
          </li>
          <li>
            destroy or, if required by the Customer and at the Customer’s cost, return all Customer Personal Data that it processes acting on behalf of the Customer and has in its possession and
            delete existing copies of such data unless applicable law requires storage of the Customer Personal Data.
          </li>
        </ul>
        <p className="p1">3. The Customer warrants that it will comply with its obligations under the Data Protection Legislation.</p>

        <p className="p1">
          <b>8. Intellectual Property Rights</b>
        </p>
        <p className="p1">
          1. Nothing in the Contract shall operate to assign or otherwise transfer any Intellectual Property Rights from HyBird Ltd. to the Customer, whether created prior to or during the Term.
        </p>
        <p className="p1">
          2. All Intellectual Property Rights in HyBird Ltd. Platforms, and Services, together with any improvements, enhancements or other modifications to either of them, whether or not created
          jointly with the Customer, (<b>“Improvements”</b>) shall vest and remain vested in HyBird Ltd..
        </p>
        <p className="p1">
          3. Subject to clause 8.2, all Intellectual Property Rights in in all workflows and other materials created by Customer using the HyBird Ltd. Services (<b>“Workflows”</b>) shall vest or
          remain vested in the Customer.
        </p>
        <p className="p1">
          4. At HyBird Ltd.’s request and expense the Customer will perform (or procure the performance of) all further acts and things, and execute and deliver (or procure the execution or delivery
          of) all further documents which HyBird Ltd. reasonably considers necessary to vest ownership of any Intellectual Property Rights in any Improvements in HyBird Ltd., or otherwise togive
          effect to this clause 8.
        </p>
        <p className="p1">
          <b>9. Charges</b>
        </p>
        <p className="p2">1. The Customer agrees that HyBird Ltd. may use the payment method selected by the Customer to process payment of the Charges:</p>
        <ul>
          <li>in the case of Annual Services, annually in advance; and</li>
          <li>in the case of Monthly Services, monthly in advance.</li>
        </ul>
        <p className="p1">2. The Charges are exclusive of UK value added tax (VAT) or any other applicable sales tax, which will be added to the Charges and paid in accordance with clause 9.1.</p>
        <p className="p1">
          3. HyBird Ltd. may increase the Charges by giving to the Customer at least thirty (30) days' prior written notice, provided that, in the case of Annual Services, any increases to the Charges
          shall only be effective following expiry of the then-current 12-month subscription period.
        </p>
        <p className="p1">
          4. The Customer acknowledges and agrees that if HyBird Ltd. is unable to collect payment of the Charges using the payment method selected by the Customer, HyBird Ltd. may, without prejudice
          to its other rights (whether under the Contract or otherwise), suspend the provision of the HyBird Ltd. Services or Platforms with immediate effect and without any obligation to give prior
          notice of such suspension to the Customer or to refund the Customer for any Charges relating to the period of suspension.
        </p>

        <p className="p1">
          <b>10. Warranties</b>
        </p>
        <p className="p2">1. Each party warrants to other party that:</p>
        <ul>
          <li>it has the legal right and authority to enter into the Contract and to perform its obligations under the Contract; and</li>
          <li>it will comply with all applicable legal and regulatory requirements applying to the exercise of its rights and the fulfilment of its obligations under the Contract.</li>
        </ul>
        <p className="p2">2. HyBird Ltd. warrants to the Customer that:</p>
        <ul>
          <li>the Services and Platform are provided “as is”;</li>
          <li>the HyBird Ltd. Services shall in all material respects comply with the Specification so far as is commercially reasonably;</li>

          <li>the HyBird Ltd. Services will incorporate security features reflecting the requirements of good industry practice.</li>
        </ul>
        <p className="p1">
          3. All of the parties' warranties and representations in respect of the subject matter of the Contract are expressly set out in the Contract. To the maximum extent permitted by applicable
          law, no other warranties or representations concerning the subject matter of the Contract will be implied into the Contract or any related contract.
        </p>
        <p className="p1">
          <b>11. Acknowledgements and warranty limitations</b>
        </p>
        <p className="p1">
          1. The Customer acknowledges that, despite all reasonable commercial endeavours, complex software is never wholly free from defects, errors and bugs; and subject to the other provisions of
          the Contract, HyBird Ltd. gives no warranty or representation that the HyBird Ltd. Services will be wholly free from defects, errors and bugs.
        </p>
        <p className="p1">
          2. The Customer acknowledges that, despite all reasonable commercial endeavours, complex software is never entirely free from security vulnerabilities; and subject to the other provisions of
          the Contract, HyBird Ltd. gives no warranty or representation that the HyBird Ltd. Services will be entirely secure.
        </p>
        <p className="p1">
          3. Except to the extent expressly provided otherwise in the Contract, HyBird Ltd. does not warrant or represent that the HyBird Ltd. Services or the use of the HyBird Ltd. Servicesby the
          Customer will be suitable for the Customer’s purposes, or will provide the Customer with any return on investment, cost saving or other specific result or outcome.
        </p>

        <p className="p1">
          <b>12. Limitations and exclusions of liability</b>
        </p>
        <p className="p2">1. Nothing in the Contract will limit or exclude:</p>
        <ul>
          <li>any liability for fraud or fraudulent misrepresentation; or</li>
          <li>any liability in any way that is not permitted under applicable law.</li>
        </ul>

        <p className="p2">2. The limitations and exclusions of liability set out in this clause 12 and elsewhere in the Contract:</p>
        <ul>
          <li>are subject to clause 12.1; and</li>
          <li>
            govern all liabilities arising under the Contract or relating to the subject matter of the Contract, including liabilities arising in contract, in tort (including negligence) and for
            breach of statutory duty, except to the extent expressly provided otherwise in the Contract.
          </li>
        </ul>
        <p className="p2">3. HyBird Ltd. shall not be liable to the Customer in respect of:</p>
        <ul>
          <li>any losses arising out of a Force Majeure Event; or</li>
          <li>any loss of profits or anticipated savings; or</li>
          <li>any loss of revenue or income; or</li>
          <li>any loss of use or production; or</li>
          <li>any loss of business, contracts or opportunities; or</li>
          <li>any loss or corruption of any data, database or software</li>
          whether or not such losses or loss would also fall within clause 12.4.
        </ul>
        <p className="p1">4. Neither party shall be liable to the other party in respect of any special, indirect orconsequential loss or damage.</p>

        <p className="p1">
          <b>13. Force Majeure Event</b>
        </p>
        <p className="p1">
          1. If a Force Majeure Event gives rise to a failure or delay in either party performing any obligation under the Contract (other than any obligation to make a payment), that obligation will
          be suspended for the duration of the Force Majeure Event.
        </p>
        <p className="p2">
          2. A party that becomes aware of a Force Majeure Event which gives rise to, or which is likely to give rise to, any failure or delay in that party performing any obligation under
          theContract, must:
        </p>
        <ul>
          <li>promptly notify the other; and</li>
          <li>inform the other of the period for which it is estimated that such failure or delay will continue.</li>
        </ul>
        <p className="p1">
          3. A party whose performance of its obligations under the Contract is affected by a Force Majeure Event must take reasonable steps to mitigate the effects of the Force Majeure Event.
        </p>
        <p className="p1">
          <b>14. Termination</b>
        </p>
        <p className="p1">
          1. Either party may terminate the Contract immediately by giving written notice of termination to the other party if the other party commits any material breach of the Contract which, in the
          case of a breach which is capable of remedy, is not remedied within thirty (30) days of the date on which the other party is given written notice requiring the breach to be remedied.
        </p>

        <p className="p2">2. Either party may terminate the Contract immediately by giving written notice of termination to the other party if:</p>
        <ul>
          <li>
            the other party is dissolved, ceases to conduct all (or substantially all) of its business, is or becomes unable to pay its debts as they fall due, is or becomes insolvent or is declared
            insolvent, or convenes a meeting or makes or proposes to make any arrangement or composition with its creditors; or
          </li>
          <li>an administrator, administrative receiver, liquidator, receiver, trustee, manager or similar is appointed over any material part of the assets of theother party; or</li>
          <li>
            an order is made for the winding up of the other party, or the other party passes a resolution for its winding up (other than for the purpose of a solvent company reorganisation where the
            resulting entity will assume all the obligations of the other party under the Contract).
          </li>
        </ul>
        <p className="p1">3. HyBird Ltd. may terminate the Contract at any time at HyBird Ltd.’s sole discretion.</p>
        <p className="p1">
          <b>15. Effects of termination</b>
        </p>
        <p className="p1">
          1. Upon the termination of the Contract, all of the provisions of the Contract shall cease to have effect, save that the following provisions of the Contract shall survive and continueto
          have effect (in accordance with their express terms or otherwise indefinitely): clauses 1, 5, 6, 7, 8, 9, 12, 15, 17, 20, 21, 22, 24 and 25.
        </p>
        <p className="p1">2. The termination of the Contract shall not affect the accrued rights of either party.</p>
        <p className="p1">
          3. On termination of the Contract for any reason the Customer must immediately pay to HyBird Ltd. any Charges in respect of Services provided to the Customer before the termination of the
          Contract.
        </p>
        <p className="p1">
          4. The Customer agrees that it is the Customer’s responsibility to retrieve a copy of the Customer Data within thirty (30) days of the date on which termination of the Contract is effective,
          following which date HyBird Ltd. is entitled to delete the Customer Data without notice.
        </p>
        <p className="p1">
          <b>16. Marketing and Publicity</b>
        </p>
        <p className="p1">
          1. The Customer agrees that HyBird Ltd. may refer to the Customer as being a userof the HyBird Ltd. Services in its marketing materials, press releases, or on its website.
        </p>
        <p className="p1">
          <b>17. Notices and Consents</b>
        </p>
        <p className="p1">
          1. Any notice or consent from one party to the other party under the Contract must be given by email using, in the case of HyBird Ltd.,
          <a href="mailto:contact@hybirdtech.com">contact@hybirdtech.com</a> or, in the case of the Customer, the email address set out in the Subscription
        </p>
        <p className="p1">
          <b>18. Subcontracting</b>
        </p>
        <p className="p1">
          1. HyBird Ltd. may subcontract any of its obligations under the Contract, provided that HyBird Ltd. shall remain responsible to the Customer for the performance of any subcontracted
          obligations.
        </p>
        <p className="p1">
          <b>19. Assignment</b>
        </p>
        <p className="p1">
          1. The Customer must not assign, transfer or otherwise deal with its contractual rights and/or obligations under the Contract without the prior written consent of HyBird Ltd., such consent
          not to be unreasonably withheld or delayed.
        </p>
        <p className="p1">
          <b>20. No waivers</b>
        </p>
        <p className="p1">1. No breach of any provision of the Contract will be waived except with the expresswritten consent of the party not in breach.</p>
        <p className="p1">
          2. No waiver of any breach of any provision of the Contract shall be construed as a further or continuing waiver of any breach of that provision or any other provision of the Contract.
        </p>

        <p className="p1">
          <b>21. Severability</b>
        </p>
        <p className="p1">
          1. If a provision of the Contract is determined by any court or other competent authority to be unlawful and/or unenforceable, the other provisions will continue in effect.
        </p>
        <p className="p1">
          2. If any unlawful and/or unenforceable provision of the Contract would be lawful or enforceable if part of it were deleted, that part will be deemed to be deleted, and the rest of the
          provision will continue in effect.
        </p>

        <p className="p1">
          <b>22. Third party rights</b>
        </p>
        <p className="p1">1. The Contract is for the benefit of the parties, and is not intended to benefit or be enforceable by any third party.</p>

        <p className="p1">
          <b>23. Variation</b>
        </p>
        <p className="p1">1. The Contract may not be varied except in accordance with this clause 23.</p>
        <p className="p1">2. Subject to clause 23.3, the Contract may be varied by means of a written document signed, including by electronic signature, by or on behalf of each party.</p>
        <p className="p1">
          3. HyBird Ltd. may from time to time make changes to the Specification, provided that any such change shall not adversely affect the core functionality of the HyBird Ltd. Services in any
          material respect.
        </p>

        <p className="p1">
          <b>24. Entire agreement</b>
        </p>
        <p className="p1">
          1. The Contract shall constitute the entire agreement between the parties in relation to its subject matter, and shall supersede all previous agreements, arrangements and understandings
          between the parties in respect of that subject matter.
        </p>
        <p className="p1">2. Neither party will have any remedy in respect of any misrepresentation (whether written or oral) made to it upon which it relied in entering into the Contract.</p>
        <p className="p1">3. The provisions of this clause 24 are subject to clause 12.1.</p>

        <p className="p1">
          <b>25. Law and jurisdiction</b>
        </p>
        <p className="p1">
          1. The Contract and all disputes or claims arising out of or in connection with the Contract or its subject matter or formation (including non-contractual disputes or claims) shall be
          governed by and construed in accordance with English law.
        </p>
        <p className="p1">
          2. Any disputes relating to the Contract or its subject matter or formation (including non-contractual disputes or claims) shall be subject to the exclusive jurisdiction of the courts of
          England.
        </p>
      </div>
    );
  }
}

TermsAndConditions.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default TermsAndConditions;
