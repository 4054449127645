import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../icon/components/icon';

import '../styles/add-button.scss';

const AddButton = ({ disabled, nonTranslatable, translationObject, onClick, children, className, ...rest }, { t }) => (
  <div className={`add-button ${className || ''} ${disabled || !onClick ? 'disabled' : ''}`} onClick={disabled ? null : onClick} {...rest}>
    <Icon className={`add-button__icon ${disabled ? 'add-button__icon-disabled' : ''}`} name="plus-rounded" active={!disabled && onClick ? true : false} />
    <p className={`add-button__text ${disabled || !onClick ? 'f-secondary-dark' : 'f-secondary-green'}`}>{nonTranslatable ? children : t(children, translationObject)}</p>
  </div>
);

AddButton.contextTypes = {
  t: PropTypes.func.isRequired,
};

AddButton.propTypes = {
  disabled: PropTypes.bool,
  nonTranslatable: PropTypes.bool,
  translationObject: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

AddButton.defaultProps = {
  disabled: false,
  nonTranslatable: false,
  translationObject: {},
};

export default AddButton;
