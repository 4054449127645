import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Tabs from '../../tabs/component/tabs';
import Tab from '../../tabs/component/tab';
import ViewModes from './view-modes';
import ViewerControls from './viewer-controls';
import PanControls from './pan-controls';
import Controls360 from './360-controls';

import { tabConstants } from '../constants/constants';

import '../styles/instructions-screen.scss';

class InstructionsScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: tabConstants.viewModes.tabKey,
    };
  }
  render() {
    return (
      <div className="instructions-screen">
        <Tabs
          onChange={tabkey => {
            this.setState({ activeTab: tabkey });
          }}
        >
          <Tab title={tabConstants.viewModes.title} tabKey={tabConstants.viewModes.tabKey}>
            <ViewModes />
          </Tab>
          <Tab title={tabConstants.viewerControls.title} tabKey={tabConstants.viewerControls.tabKey}>
            <ViewerControls />
          </Tab>
          <Tab title={tabConstants.panControls.title} tabKey={tabConstants.panControls.tabKey}>
            <PanControls />
          </Tab>
          <Tab title={tabConstants.controls360.title} tabKey={tabConstants.controls360.tabKey}>
            <Controls360 />
          </Tab>
        </Tabs>
      </div>
    );
  }
}
InstructionsScreen.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default InstructionsScreen;
