import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';

import CustomInput from '../../../../common/form/components/input';
import Textarea from '../../../../common/form/components/text-area';

import { FORMS } from '../../../../common/constants';
import UneditableInfo from '../../../../common/form/components/uneditable-info';
import { detailsFields } from './constants/section-form-constants';
import sectionsValidator from './validators/sections-validator';

const DetailsForm = ({ disabled, initialValues }) => {
  const { name, description, templateName } = detailsFields;
  return (
    <form noValidate>
      <Field id={name.id} name={name.name} label={name.label} component={CustomInput} placeholder={name.placeholder} disabled={disabled} isRequired size="lg" />
      <Field id={templateName.id} name={templateName.name} label={templateName.label} component={UneditableInfo} />
      <Field
        id={description.id}
        name={description.name}
        label={description.label}
        component={Textarea}
        placeholder={description.placeholder}
        maxChars={description.maxChars}
        disabled={disabled}
        enableAutoResize={true}
        size="lg"
      />
    </form>
  );
};

DetailsForm.propTypes = {
  disabled: PropTypes.bool,
};

export default reduxForm({
  form: FORMS.permitDetails,
  validate: sectionsValidator.detailsValidate,
  enableReinitialize: true,
})(DetailsForm);
