import React from 'react';
import PropTypes from 'prop-types';
import { commentTagTypes } from '../../comments-tab/constants/constants';

const CommentUsersAndTeamsDropdown = ({ children }, { t }) => {
  const filterByType = type => children.filter(child => child.props.mention.Type === type);
  const users = filterByType(commentTagTypes.user),
    teams = filterByType(commentTagTypes.team);

  return (
    <div className="mention-suggestions">
      {users.length !== 0 ? (
        <>
          <p className="mention-suggestions__section-title">{t('MEMBERS.USERS')}</p>
          {users}
        </>
      ) : null}
      {teams.length !== 0 ? (
        <>
          <p className="mention-suggestions__section-title">{t('MEMBERS.TEAMS')}</p>
          {teams}
        </>
      ) : null}
    </div>
  );
};

CommentUsersAndTeamsDropdown.contextTypes = {
  t: PropTypes.func.isRequired,
};
CommentUsersAndTeamsDropdown.propTypes = {
  children: PropTypes.array.isRequired,
};

export default CommentUsersAndTeamsDropdown;
