import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import DropdownMenu, { MenuItem } from './dropdown-menu';

import '../styles/dropdown.scss';

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false,
    };
    this.dropdown = React.createRef();
  }

  componentDidMount() {
    const { node } = this.props;
    if (!node && !node.current) return;
    node.current.addEventListener('click', this.handleClick, false);
  }

  componentWillUnmount() {
    const { node } = this.props;
    if (node && node.current) {
      node.current.removeEventListener('click', this.handleClick, false);
    }
  }

  handleClick = () => {
    const { isDisabled } = this.props;
    if (!isDisabled) {
      this.toggleDropdown();
    }
  };

  toggleDropdown = () => {
    const { opened } = this.state;

    this.setState({ opened: !opened });
  };

  closeDropdown = () => {
    this.setState({ opened: false });
  };

  renderMenuItems = (hovered, opened) => {
    const { closeDropdown } = this;
    const { actionsMenu, getClickAction, Item } = this.props;
    return actionsMenu.map((item, index) => {
      const isHovered = hovered === item.id;
      return (
        <MenuItem key={index} className={`dropdown_custom__item ${hovered === item.id ? 'hovered' : ''}`} id={item.id} onClick={() => getClickAction(item.action)}>
          {Item({ item, isHovered, closeDropdown, opened })}
        </MenuItem>
      );
    });
  };

  render() {
    const { opened } = this.state;
    const { anchor, node, position } = this.props;
    return (
      <DropdownMenu customNode={node} position={position} clickOutsideHandler={this.closeDropdown} isOpen={opened} anchor={anchor} className="dropdown_custom" ref={this.dropdown}>
        {(selected, hovered) => {
          return <Fragment>{this.renderMenuItems(hovered, opened)}</Fragment>;
        }}
      </DropdownMenu>
    );
  }
}

Dropdown.contextTypes = {
  t: PropTypes.func.isRequired,
};
Dropdown.defaultProps = {
  offset: 0,
  anchor: 'bottom-left',
};

export default Dropdown;
