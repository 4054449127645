import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';

import Button from '../../../../common/form/components/button';
import CustomInput from '../../../../common/form/components/input';
import CustomSelect from '../../../../common/form/components/select';
import DatePicker from '../../../../common/form/components/date-picker';
import Icon from '../../../../common/icon/components/icon';
import Textarea from '../../../../common/form/components/text-area';
import { FORMS } from '../../../../common/constants';
import { fields, defaultFilter, filterFields, permitTemplateFields, workOrderFields } from './constants/permit-form-constants';
import { validate } from './validators/create-permit-validator';

import '../../styles/create-permit-form.scss';
import { connect } from 'react-redux';
import { permitFormSteps } from './constants/permit-form-constants';
import CheckBox from '../../../../common/form/components/checkbox';
import WorkOrderComponents from '../work-order/work-order-components';
import PermitWorkOrderDropdown from './permit-work-order-dropdown';
import RenderIf from '../../../../common/render-if/components/render-if';

const CreatePermitForm = (
  {
    handleSubmit,
    onSubmit,
    closeModal,
    activeStep,
    projectId,
    templates,
    onSearchPermitTemplates,
    onSearchWorkOrders,
    change,
    modalDisabled,
    handleWorkOrderDetailsCopy,
    copyWODetailsCheckbox,
    workOrders,
    selectedWorkOrder,
    components,
    permitComponents,
    addComponentToPermit,
    removeComponentFromPermit,
    handleComponentPicker3D,
    handleComponentPickerPDF,
    handleComponentPickerCH,
    handleSelectWorkOrder,
    workOrdersLoading,
    workOrderPagingObject,
    loadMore,
    stepAction,
  },
  { t }
) => {
  const { permitTemplate, copyWorkOrderDetails, workOrder, name, description, startDate, endDate } = fields;

  const handleSearchPermitTemplates = value => {
    defaultFilter[filterFields.searchText] = value;
    defaultFilter[filterFields.projectID] = projectId;

    onSearchPermitTemplates(defaultFilter);
  };

  const handleSearchWorkOrders = value => {
    onSearchWorkOrders(value);
  };

  const addWorkOrderToPermit = value => {
    change(workOrder.name, value[workOrderFields.id], true);
    change(name.name, value[workOrderFields.name], true);
    change(description.name, value[workOrderFields.description], true);
    change(startDate.name, value[workOrderFields.startDate], true);
    change(endDate.name, value[workOrderFields.dueDate], true);
    handleSelectWorkOrder(value);
  };

  const removeWorkOrderFromPermit = () => {
    change(workOrder.name, '', true);
    change(name.name, '', true);
    change(description.name, '', true);
    change(startDate.name, null, true);
    change(endDate.name, null, true);
    handleSelectWorkOrder(null);
  };

  const onFormSubmit = values => {
    onSubmit(values);
  };

  return (
    <div className="create-permit-form">
      <form onSubmit={handleSubmit(onFormSubmit)} noValidate>
        {activeStep === permitFormSteps.firstStep && (
          <>
            <div className="permit-template">
              <Field
                id={permitTemplate.id}
                name={permitTemplate.name}
                label={permitTemplate.label}
                placeholder={
                  <div className="template-placeholder">
                    <Icon name="search" className="template-placeholder-icon" />
                    <p className="f-secondary-dark">{t(permitTemplate.placeholder)}</p>
                  </div>
                }
                component={CustomSelect}
                data={templates}
                size="lg"
                disabled={modalDisabled}
                onSearch={handleSearchPermitTemplates}
                valueField={permitTemplateFields.id}
                textField={permitTemplateFields.name}
                filter="contains"
                isRequired
                input={{
                  onChange: item => {
                    change(permitTemplate.name, item[permitTemplateFields.id], true);
                  },
                }}
              />
            </div>
            <div className="permit-checkbox w-100">
              <Field
                id={copyWorkOrderDetails.id}
                name={copyWorkOrderDetails.name}
                labelClass="f-secondary-dark"
                component={CheckBox}
                checkboxText={copyWorkOrderDetails.label}
                grayCheckboxText={copyWorkOrderDetails.note}
                onChange={handleWorkOrderDetailsCopy}
              />
            </div>
            {copyWODetailsCheckbox && (
              <div className="permit-template permit-workorders">
                <PermitWorkOrderDropdown
                  workOrders={workOrders}
                  selectedWorkOrder={selectedWorkOrder}
                  handleSearchWorkOrders={handleSearchWorkOrders}
                  addWorkOrderToPermit={addWorkOrderToPermit}
                  removeWorkOrderFromPermit={removeWorkOrderFromPermit}
                  workOrdersLoading={workOrdersLoading}
                  workOrderPagingObject={workOrderPagingObject}
                  loadMore={loadMore}
                />
              </div>
            )}
            <div className="input-name">
              <Field
                id={name.id}
                name={name.name}
                label={name.label}
                component={CustomInput}
                placeholder={name.placeholder}
                disabled={modalDisabled}
                isRequired
                size="lg"
                additionalClassName="text-transform-none"
                labelClass="text-transform-none"
              />
            </div>
            <Field
              id={description.id}
              name={description.name}
              label={description.label}
              component={Textarea}
              placeholder={description.placeholder}
              maxChars={description.maxChars}
              disabled={modalDisabled}
              size="lg"
            />
          </>
        )}
        {activeStep === permitFormSteps.secondStep && (
          <>
            <div className="date-pickers">
              <div className="start-date-wrapper">
                <Field
                  id={startDate.id}
                  name={startDate.name}
                  label={startDate.label}
                  component={DatePicker}
                  placeholder={startDate.placeholder}
                  disabled={modalDisabled}
                  disabledKeyboardNavigation
                  dateFormat="MMMM d, yyyy h:mm aa"
                  timeFormat="h:mm aa"
                  showTimeSelect
                  validateUsingReduxForm
                  size="lg"
                  popperPlacement="top"
                />
              </div>
              <div className="end-date-wrapper">
                <Field
                  id={endDate.id}
                  name={endDate.name}
                  label={endDate.label}
                  component={DatePicker}
                  placeholder={endDate.placeholder}
                  disabled={modalDisabled}
                  disabledKeyboardNavigation
                  dateFormat="MMMM d, yyyy h:mm aa"
                  timeFormat="h:mm aa"
                  showTimeSelect
                  validateUsingReduxForm
                  size="lg"
                  popperPlacement="top"
                />
              </div>
            </div>
            <div className="components">
              <WorkOrderComponents
                components={components}
                workorderComponents={permitComponents}
                addComponentToWorkorder={addComponentToPermit}
                removeComponentFromWorkorder={removeComponentFromPermit}
                handleComponentPicker3D={handleComponentPicker3D}
                handleComponentPickerPDF={handleComponentPickerPDF}
                handleComponentPickerCH={handleComponentPickerCH}
                hideCheckbox
              />
            </div>
          </>
        )}
        <div className="buttons-container">
          <RenderIf if={activeStep === permitFormSteps.firstStep}>
            <Button type="button" variant="gray-outline" width="sm" height="md" text={t('CREATE_PERMIT_FORM.CANCEL_BUTTON')} disabled={modalDisabled} onClick={closeModal} />
          </RenderIf>

          <Button
            type="submit"
            className="submit-button"
            width="sm"
            height="md"
            text={activeStep === permitFormSteps.firstStep ? t('CREATE_PERMIT_FORM.SUBMIT_BUTTON') : t('CREATE_PERMIT_FORM.SAVE_BUTTON')}
            disabled={modalDisabled}
          />
        </div>
      </form>
    </div>
  );
};

CreatePermitForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { initialValues }) => {
  return {
    formValues: state.form[FORMS.createPermitForm]?.values || {},
    initialValues,
  };
};

const FormDecoratedComponent = reduxForm({
  form: FORMS.createPermitForm,
  validate,
  touchOnChange: true,
  enableReinitialize: true,
  destroyOnUnmount: true,
})(CreatePermitForm);

const ConnectedAndFormDecoratedComponent = connect(mapStateToProps, null)(FormDecoratedComponent);

export default ConnectedAndFormDecoratedComponent;
