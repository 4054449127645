import React, { Component } from 'react';

import IsolationCertificateItem from '../isolation-certificate-section/isolation-certificate-item';
import ExpandableSection from '../../../../../common/expandable-section/components/expandable-section';
import SectionEmptyState from '../../right-toolbar/common/section-empty-state';
import Icon from '../../../../../common/icon/components/icon';
import { fields, initialValuesProp } from '../constants/keybox-constants';

import '../../../styles/isolation-certificates-section.scss';

class PermitKeyBoxes extends Component {
  render() {
    const { module } = this.props;
    const keyboxes = module[initialValuesProp];

    if (!keyboxes || !keyboxes.length) {
      return <SectionEmptyState title="PERMIT_KEYBOX.EMPTY_STATE" />;
    }

    return (
      <div className="isolation-certificates-section">
        {keyboxes && keyboxes.length > 0 && (
          <>
            {keyboxes.map((keybox, index) => (
              <div className="component-item-header" key={`section-component-item-${index}`}>
                <ExpandableSection
                  CustomComponent={({ isExpanded }) => (
                    <div className="component-header">
                      <h6 className="f-primary component-header-title">{`${index + 1}.  ${keybox[fields.name.name]}`}</h6>
                      <Icon name={isExpanded ? 'chevron-up' : 'chevron-down'} className="component-header-toggle" />
                    </div>
                  )}
                  className="expanded-section"
                  customExpanded={keybox.isExpanded}
                  hideExpandAction
                >
                  <IsolationCertificateItem component={keybox} componentIndex={index} fields={fields} />
                </ExpandableSection>
              </div>
            ))}
          </>
        )}
      </div>
    );
  }
}

export default PermitKeyBoxes;
