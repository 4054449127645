import { fields } from './work-order-right-side-constants';

export const formFields = {
  name: {
    name: fields.name,
    label: 'WORK_ORDER.NAME_LABEL',
    id: 'work-order-basic-details-name',
  },
  subType: {
    name: fields.subType,
    label: 'WORK_ORDER.SUB_TYPE_LABEL',
    id: 'work-order-basic-details-sub-type',
  },
  additionalSubType: {
    name: fields.additionalSubType,
    label: 'WORK_ORDER.ADDITIONAL_SUB_TYPE_LABEL',
    id: 'work-order-basic-details-sub-type',
  },
  type: {
    name: fields.type,
    label: 'WORK_ORDER.TYPE_LABEL',
    id: 'work-order-basic-details-type',
  },
  description: {
    name: fields.description,
    label: 'WORK_ORDER.DESCRIPTION_LABEL',
    id: 'work-order-basic-details-description',
    maxChars: 400,
  },
  toolsRequired: {
    name: fields.toolsRequired,
    label: 'WORK_ORDER.TOOLS_REQUIRED_LABEL',
    id: 'work-order-basic-details-tools-required',
    maxChars: 400,
  },
  severity: {
    name: fields.severity,
    label: 'WORK_ORDER.PRIORITY_LABEL',
    id: 'work-order-basic-details-priority',
  },
  startDate: {
    name: fields.startDate,
    label: 'WORK_ORDER.START_DATE_LABEL',
    id: 'work-order-basic-details-start-date',
  },
  endDate: {
    name: fields.endDate,
    label: 'WORK_ORDER.END_DATE_LABEL',
    id: 'work-order-basic-details-end-date',
  },
  dueDate: {
    name: fields.dueDate,
    label: 'WORK_ORDER.DUE_DATE_LABEL',
    id: 'work-order-basic-details-due-date',
  },
  estimatedWrenchTime: {
    name: fields.estimatedWrenchTime,
    label: 'WORK_ORDER.ESTIMATED_WRENCH_TIME_LABEL',
    id: 'work-order-basic-details-estimated-wrench-time',
  },
};
