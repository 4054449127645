import { setTableData, setLoadMoreItems, setTableParams, removeItemAtIndex, updateItemAtIndex, updateItemById, handleTableDataLoading } from './action-creators';
import pdfTagListApi from '../../../api/pdf-tag-list/actions';

import { forEach } from 'lodash';
import constants from '../constants/constants';

export const getTableData = (params, section, callback, loadMore) => {
  return async dispatch => {
    dispatch(handleTableDataLoading(true));
    try {
      const res = await pdfTagListApi.getPdfTagTableData(params);
      let { Data } = res.data;
      const data = Data.PdfTaggedDocuments;
      if (loadMore) {
        dispatch(setLoadMoreItems(section, data || []));
      } else {
        dispatch(setTableData(section, data));
      }

      callback && callback();

      dispatch(setTableParams(section, { ...params, HasNext: Data.HasNext, PerPage: Data.PerPage }));
      dispatch(handleTableDataLoading(false));
    } catch (err) {
      dispatch(handleTableDataLoading(false));
    }
  };
};

export const deletePDF = (section, index, params, callback, setFormError) => {
  return async dispatch => {
    await pdfTagListApi.deletePDF(params);
    dispatch(removeItemAtIndex(section, index));
    // setFormError('aaaaa');
    callback && callback();
  };
};

export const editPDF = (section, index, params, callback) => {
  return async dispatch => {
    await pdfTagListApi.editPDF(params);
    dispatch(updateItemAtIndex(section, index, params));
    callback && callback();
  };
};

export const batchItemsUpdate = (section, FileIDS, InspectionID) => {
  return async dispatch => {
    const res = await pdfTagListApi.getMultipleFilesStatus({ FileIDS, InspectionID });
    const itemsToUpdate = res.data.Data.PdfTaggedDocuments || [];
    if (itemsToUpdate.length > 0) {
      forEach(itemsToUpdate, item => {
        dispatch(updateItemById(section, item, item[constants.fields.id]));
      });
    }
  };
};
