import React, { Component } from 'react';

import PropTypes from 'prop-types';

import '../styles/view-modes.scss';

class ViewModes extends Component {
  render() {
    const { t } = this.context;
    return (
      <div className="view-modes">
        <div className="view-mode-item">
          <p className="bold">{t('VIEW_MODES.360_VIEW.TITLE')}</p>
          <p>{t('VIEW_MODES.360_VIEW.DESCRIPTION')}</p>
        </div>
        <div className="view-mode-item">
          <p className="bold">{t('VIEW_MODES.3D_VIEW.TITLE')}</p>
          <p>{t('VIEW_MODES.3D_VIEW.DESCRIPTION')}</p>
        </div>
      </div>
    );
  }
}
ViewModes.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ViewModes;
