import React from 'react';
import SmallDropdown from '../../../../common/small-dropdown/components/small-dropdown';
import { fields, itemActionTypes } from '../../constants/constants';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../common/permissions-constants';
import { ReactComponent as trash } from '../../../../common/icon/assets/trash.svg';

const DMSTableDropdown = ({ onClick, row, isFolder }) => {
  const menuOptions = [
    {
      title: isFolder ? 'DOCUMENT_MANAGEMENT_TABLE_DROPDOWN.EDIT_FOLDER' : 'DOCUMENT_MANAGEMENT_TABLE_DROPDOWN.EDIT_FILE',
      action: () => onClick(row, itemActionTypes[isFolder ? 'folder' : 'file'].edit),
      access: {
        visibleFor: PERMISSIONS[PERMISSION_TYPES.documentManagement].edit.name,
        id: row[fields.createdByID],
        ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.documentManagement].create.name,
      },
    },
    {
      title: isFolder ? 'DOCUMENT_MANAGEMENT_TABLE_DROPDOWN.DELETE_FOLDER' : 'DOCUMENT_MANAGEMENT_TABLE_DROPDOWN.DELETE_FILE',
      action: () => onClick(row, itemActionTypes[isFolder ? 'folder' : 'file'].delete),
      access: {
        visibleFor: PERMISSIONS[PERMISSION_TYPES.documentManagement].delete.name,
      },
      separator: true,
      isHighlighted: true,
      icon: trash,
      customMenuItemClassName: 'red-icon stroke',
    },
  ];
  return (
    <SmallDropdown
      actionsMenu={menuOptions}
      getClickAction={(action, b, selfClose, e) => {
        e?.preventDefault && e.preventDefault();
        selfClose();
        if (action && typeof action === 'function') {
          action();
        }
      }}
      offsetX={10}
    />
  );
};

export default DMSTableDropdown;
