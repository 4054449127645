import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import EmptyState from '../../../../common/empty-state/components/empty-state';
import HistoryItem from '../../../../common/history-item/components/history-item';
import LoadMore from '../../../../common/load-more/components/load-more';
import ToolbarTitle from '../../../../common/toolbar-title/components/toolbar-title';

import { sortingDirection, historyDefaultPagingObj, historyFilterParams, historyItems, defectModificationEvents } from '../../constants/defect-constants';
import { routes } from '../../../../common/routes-constants';
import { params } from '../../../profile/constants/profile-constants';
import { modules } from '../../constants/constants';
import { getHistoryLength } from '../../helpers/inspection-helper';

import { getDefectHistory } from '../../actions/inspection-actions';
import { generateQueryParams } from '../../../../api/helpers/helper';

import '../../styles/module-item-history.scss';

class DefectHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      history: [],
      paging: historyDefaultPagingObj,
    };
  }

  fetchHistory = () => {
    this.props.getDefectHistory(
      {
        ItemId: this.props.defectId,
        LastSeen: this.state.paging[historyFilterParams.lastSeen],
        PerPage: this.state.paging[historyFilterParams.perPage],
        SortByColumn: historyFilterParams.sortByColumn,
        SortDirection: sortingDirection.desc,
      },
      this.state.history,
      this.state.paging,
      data => this.setState(data)
    );
  };

  componentDidMount = () => {
    this.fetchHistory();
  };
  getRedirectPath = (selected_item, type = modules.components) => {
    const { inspection_id, project_id, user, defaultComponent } = this.props;

    //default component has no details
    if (selected_item === defaultComponent) {
      return null;
    }

    const basePath = user[params.fullScreenEnabled] ? routes.protectedRoutes.fullScreen.fullPath : routes.protectedRoutes.inspections.fullPath;
    return `${basePath}${generateQueryParams([{ project_id }, { inspection_id }, { type }, { selected_item }])}`;
  };

  render() {
    const historyLength = getHistoryLength(this.state.history, defectModificationEvents, historyItems, defectModificationEvents.defect_created.event, defectModificationEvents.defect_deleted.event);

    if (!this.state.isLoading && (!this.state.history.length || !historyLength)) {
      return <EmptyState title="COMPONENT_HISTORY.EMPTY_STATE_TITLE" message="DEFECT_HISTORY.EMPTY_STATE_MESSAGE" />;
    }

    return (
      <div className="module-item-history">
        <ToolbarTitle variant="medium">{'DEFECT_HISTORY.TITLE'}</ToolbarTitle>
        <div className="module-item-history__wrapper">
          <div className={this.state.isLoading ? 'background-blurred' : ''}>
            {this.state.history.map(item => (
              <HistoryItem
                key={item.id}
                items={historyItems}
                item={item}
                moduleName="DEFECT_HISTORY.MODULE_NAME"
                events={defectModificationEvents}
                moduleCreateEvent={defectModificationEvents.defect_created.event}
                moduleFileUnlinkEvent={defectModificationEvents.defect_file_unlinked.event}
                moduleDeleteEvent={defectModificationEvents.defect_deleted.event}
                getRedirectPath={this.getRedirectPath}
              />
            ))}
          </div>
          <LoadMore
            disabled={!this.state.paging[historyFilterParams.hasNext] || this.state.isLoading}
            loaded={historyLength}
            total={this.state.paging[historyFilterParams.totalItems]}
            totalPosition="center"
            isLoading={this.state.isLoading}
            label="LOAD_MORE"
            isFixed
            onClick={this.fetchHistory}
          />
        </div>
      </div>
    );
  }
}

DefectHistory.contextTypes = {
  t: PropTypes.func.isRequired,
};

DefectHistory.propTypes = {
  defectId: PropTypes.number.isRequired,
  project_id: PropTypes.number.isRequired,
  inspection_id: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
  user: state.userReducer,
});

const mapDispatchToProps = dispatch => ({
  getDefectHistory: (params, history, paging, callback) => dispatch(getDefectHistory(params, history, paging, callback)),
});

DefectHistory = connect(mapStateToProps, mapDispatchToProps)(DefectHistory);

export default DefectHistory;
