import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { change } from 'redux-form';
import { FORMS } from '../../../../../../common/constants';
import Loader from '../../../../../../common/global-loader/components/simple-loader';
import Helpers from '../../../../../../common/helpers';

import { isEmpty } from 'lodash';
import { getDefaultTimeSeriesSettings, getGraphingGroupDetails } from '../../actions/measurement-location-actions';
import { aggregationFields, chunkFields, defaultGraphSettingsValues, formConstants, settingsFormConstants, timePeriodFields, timePeriods } from '../../constants/time-series-graph-constants';
import '../../styles/time-series-graph-settings.scss';
import TimeSeriesGraphSettingsForm from './time-series-graph-settings-form';

const TimeSeriesGraphSettings = (props, context) => {
  const { t } = context;
  const {
    getDefaultTimeSeriesSettings,
    queryItem,
    getGraphingGroupDetails,
    fetchGraphData,
    showGraphingGroup = true,
    changeField,
    formValues,
    saveSettings,
    canSave,
    getMeasurementPointGraphDetails,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [graphSettingsLoading, setGraphSettingsLoading] = useState(false);
  const [graphingGroups, setGraphingGroups] = useState([]);
  const [aggregationList, setAggregationList] = useState([]);
  const [chunks, setChunks] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const timePeriodsList = timePeriods(t);

  useEffect(() => {
    fetchDefaultTimeSeriesGraphConfiguration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDefaultTimeSeriesGraphConfiguration = () => {
    setIsLoading(true);
    getDefaultTimeSeriesSettings(
      showGraphingGroup ? queryItem : null, // don't need to pass measurementLocationId since we are not using the graphing groups in this case
      data => {
        const { graphingGroups, aggregationList, chunks } = data;
        setGraphingGroups(graphingGroups);
        setAggregationList(aggregationList);
        setChunks(chunks);

        if (showGraphingGroup) {
          const foundIndex = (graphingGroups || []).findIndex(group => group[formConstants.isDefault]);
          if (foundIndex > -1) {
            const defaultGraphingGroup = graphingGroups[foundIndex];
            handleChangeGraphingGroup(defaultGraphingGroup, aggregationList, chunks);
          }
        } else {
          // REFACTOR: Currently only param (showGraphingGroup) that indicates we are viewing mp graph if set to false: Would be good to move all the api calls to parent
          if (getMeasurementPointGraphDetails && typeof getMeasurementPointGraphDetails === 'function') {
            setGraphSettingsLoading(true);
            getMeasurementPointGraphDetails(
              queryItem,
              data => {
                if (isEmpty(data) || isEmpty(data.DefaultGraphDetails)) {
                  const initialFormValues = defaultGraphSettingsValues(aggregationList, chunks, timePeriodsList);
                  setInitialValues(initialFormValues);
                  handleSubmit(initialFormValues);
                } else {
                  const { DefaultGraphDetails: graphDetails } = data;
                  applyGraphingGroupDetails(graphDetails, aggregationList, chunks);
                }

                setGraphSettingsLoading(false);
              },
              () => {
                setGraphSettingsLoading(false);
              }
            );
          } else {
            const initialFormValues = defaultGraphSettingsValues(aggregationList, chunks, timePeriodsList);
            setInitialValues(initialFormValues);
            handleSubmit(initialFormValues);
          }
        }

        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
      }
    );
  };

  const handleChangeGraphingGroup = (graphingGroup, newAggregationList, newChunks) => {
    setGraphSettingsLoading(true);
    getGraphingGroupDetails(
      queryItem,
      graphingGroup[formConstants.id],
      data => {
        applyGraphingGroupDetails(data, newAggregationList || aggregationList, newChunks || chunks);

        setGraphSettingsLoading(false);
      },
      () => {
        setGraphSettingsLoading(false);
      }
    );
  };

  // aggregationList is in params because it can be called before the state is updated, pass aggregation list or any other param after mutation
  const applyGraphingGroupDetails = (graphingGroupDetails, aggregationList, chunks) => {
    const selectedTimePeriodKey = graphingGroupDetails?.[formConstants.timePeriod];
    const dateRange = Helpers.getDateRangeByTimePeriod(selectedTimePeriodKey, graphingGroupDetails?.[formConstants.startDateFrom], graphingGroupDetails?.[formConstants.startDateTo]);
    const selectedAggregationKeys = Helpers.parseAggregationsFromString(graphingGroupDetails?.[formConstants.aggregation]);
    let initialAggregation = [];
    (selectedAggregationKeys || []).forEach(key => {
      initialAggregation.push(Helpers.getObjectByKey(aggregationList, aggregationFields.key, key));
    });

    // Set initial settings form values
    const values = {
      [settingsFormConstants.graphingGroup.name]: graphingGroupDetails,
      [settingsFormConstants.aggregation.name]: initialAggregation,
      [settingsFormConstants.timePeriod.name]: Helpers.getObjectByKey(timePeriodsList, timePeriodFields.value, selectedTimePeriodKey) || timePeriodsList[timePeriodsList.length - 1],
      [settingsFormConstants.chunks.name]: Helpers.getObjectByKey(chunks, chunkFields.key, graphingGroupDetails?.[formConstants.chunk]) || chunks[chunks.length - 1],
      [settingsFormConstants.dateFrom.name]: new Date(dateRange.DateFrom * 1000),
      [settingsFormConstants.dateTo.name]: new Date(dateRange.DateTo * 1000),
      [settingsFormConstants.showAlarms.name]: graphingGroupDetails[settingsFormConstants.showAlarms.name],
    };
    setInitialValues(values);
    handleSubmit(values);
  };

  const handleSubmit = values => {
    fetchGraphData(values);
  };

  const handleSaveSettings = useCallback(
    () => {
      if (!formValues?.[settingsFormConstants.graphingGroup.name]?.[formConstants.id] && showGraphingGroup) {
        return;
      }
      const handleSaveSuccess = () => {
        handleSubmit(formValues);
      };

      const formattedValues = {
        ...formValues,
        [settingsFormConstants.aggregation.name]: (formValues[settingsFormConstants.aggregation.name] || []).map(el => el[aggregationFields.key]),
        [settingsFormConstants.chunks.name]: formValues[settingsFormConstants.chunks.name]?.[chunkFields.key],
        [formConstants.startDateFrom]: Helpers.dateToUnix(formValues[settingsFormConstants.dateFrom.name]),
        [formConstants.startDateTo]: Helpers.dateToUnix(formValues[settingsFormConstants.dateTo.name]),
        [formConstants.timePeriod]: formValues[settingsFormConstants.timePeriod.name]?.[timePeriodFields.value],
      };

      const graphingGroupValues = showGraphingGroup ? formattedValues[settingsFormConstants.graphingGroup.name] : {};

      saveSettings(
        queryItem,
        {
          ...graphingGroupValues,
          ...formattedValues,
          [settingsFormConstants.graphingGroup.name]: undefined,
          GraphID: showGraphingGroup ? formattedValues[settingsFormConstants.graphingGroup.name]?.[formConstants.id] : undefined,
          Aggregations: formattedValues[settingsFormConstants.aggregation.name],
        },
        handleSaveSuccess
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formValues]
  );

  if (isLoading) {
    return <Loader isLoading={true} />;
  }

  return (
    <div className="time-series-graph-settings">
      <TimeSeriesGraphSettingsForm
        graphingGroups={graphingGroups}
        aggregationList={aggregationList}
        chunks={chunks}
        initialValues={initialValues}
        handleChangeGraphingGroup={handleChangeGraphingGroup}
        onSubmit={handleSubmit}
        graphSettingsLoading={graphSettingsLoading}
        showGraphingGroup={showGraphingGroup}
        changeField={changeField}
        formValues={formValues}
        timePeriodsList={timePeriodsList}
        handleSaveSettings={handleSaveSettings}
        canSave={canSave}
      />
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  getDefaultTimeSeriesSettings: (measurementLocationId, successCallback, errorCallback) => dispatch(getDefaultTimeSeriesSettings(measurementLocationId, successCallback, errorCallback)),
  getGraphingGroupDetails: (measurementLocationId, graphingGroupId, successCallback, errorCallback) =>
    dispatch(getGraphingGroupDetails(measurementLocationId, graphingGroupId, successCallback, errorCallback)),
  changeField: (fieldName, value) => dispatch(change(FORMS.timeSeriesGraphSettingsForm, fieldName, value)),
});

TimeSeriesGraphSettings.contextTypes = {
  t: PropTypes.func.isRequired,
};

TimeSeriesGraphSettings.defaultProps = {};

export default connect(null, mapDispatchToProps)(TimeSeriesGraphSettings);
