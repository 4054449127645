import React from 'react';
import { reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';
import Button from '../../../../form/components/button';
import DisplayColor from '../../../../display-color/components/display-color';
import { colorPalette } from '../../../../form/constants/constants';
import UneditableInfo from '../../../../form/components/uneditable-info';
import { FORMS } from '../../../../constants';
import RenderIf from '../../../../render-if/components/render-if';
import Loader from '../../../../global-loader/components/simple-loader';
import { formConstants } from '../../../../../app/inspections/constants/component-constants';
import { isEmpty } from 'lodash';

const ComponentPickerCHDetails = ({ isLoading, initialValues, buttonDisabled, buttonAction }, { t }) => {
  return (
    <>
      <RenderIf if={!isLoading && !isEmpty(initialValues)}>
        <form noValidate className="component-picker-ch__details">
          <div className="component-picker-ch__details__title">
            <div className="component-picker-ch__details__title__color">
              <DisplayColor color={colorPalette[initialValues[formConstants.fields.color]]} />
            </div>
            <div className="component-picker-ch__details__title__text">
              <h5 className="f-primary bold">{initialValues[formConstants.fields.name]}</h5>
            </div>
          </div>
          <Button
            variant="success"
            type="button"
            text={t('QUESTION_COMPONENT_PICKER.ADD_COMPONENT_BUTTON')}
            height="md"
            width="lg"
            className="mb-12"
            disabled={buttonDisabled}
            onClick={() => buttonAction(false)}
          />
          <Field id={formConstants.fields.hierarchy} name={formConstants.fields.hierarchy} component={UneditableInfo} label={t('COMPONENT_DETAILS.FORM_HIERARCHY')} />
          <Field id={formConstants.fields.componentType} name={formConstants.fields.componentType} component={UneditableInfo} label={t('COMPONENT_DETAILS.FORM_COMPONENT_TYPE')} />
          <Field id={formConstants.fields.componentSubType} name={formConstants.fields.componentSubType} component={UneditableInfo} label={t('COMPONENT_DETAILS.FORM_COMPONENT_SUB_TYPE')} />
          <Field id={formConstants.fields.asset} name={formConstants.fields.asset} component={UneditableInfo} label={t('COMPONENT_DETAILS.FORM_PARENT_ASSET')} />
          <Field id={formConstants.fields.code} name={formConstants.fields.code} component={UneditableInfo} label={t('COMPONENT_DETAILS.FORM_CODE')} />
          <Field id={formConstants.fields.weight} name={formConstants.fields.weight} component={UneditableInfo} label={t('COMPONENT_DETAILS.FORM_WEIGHT')} />
          <Field id={formConstants.fields.manufacturer} name={formConstants.fields.manufacturer} component={UneditableInfo} label={t('COMPONENT_DETAILS.FORM_MANUFACTURER')} />
        </form>
      </RenderIf>
      <RenderIf if={isLoading}>
        <Loader isLoading={isLoading} />
      </RenderIf>
    </>
  );
};

ComponentPickerCHDetails.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default reduxForm({ form: FORMS.componentPickerCHDetailsSection, enableReinitialize: true, touchOnChange: true, destroyOnUnmount: true })(ComponentPickerCHDetails);
