import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { reduxForm, Field } from 'redux-form';

import { debounce, keys } from 'lodash';

import MultiSelect from '../../../../common/form/components/multiselect';
import Button from '../../../../common/form/components/button';
import DatePicker from '../../../../common/form/components/date-picker';

import { getUsers } from '../../../members/actions/users-data-actions';
import { getIsolationTemplatesFilters } from '../../actions/create-isolation-certificates';

import Helpers from '../../../../common/helpers';

import { FORMS } from '../../../../common/constants';

import { filterFields, defaultFilter } from './constants/isolation-certificate-form';
import { filterFields as templateFilterFields } from '../../../templates/components/isolations/constants/constants';

import '../../styles/defects-filter.scss';

class IsolationCertificatesFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templates: [],
    };
    this.getIsolationTemplatesFiltersDebounced = debounce(this.getIsolationTemplatesFilters, 300);
    this.getUsersDebounced = debounce(props.getUsers, 300);
  }

  componentDidMount = () => {
    const { getUsers } = this.props;

    this.getIsolationTemplatesFilters('');
    getUsers();
  };

  getIsolationTemplatesFilters = searchText => {
    const { getIsolationTemplatesFilters, projectId } = this.props;
    getIsolationTemplatesFilters({ ...defaultFilter, [templateFilterFields.perPage]: Number.MAX_SAFE_INTEGER, ProjectID: projectId, [templateFilterFields.searchText]: searchText }, templates =>
      this.setState({ templates })
    );
  };

  render() {
    const { t } = this.context;

    const { templates } = this.state;
    const { handleSubmit, resetFilter, users, formValues, submitForm } = this.props;

    return (
      <form
        className="filter-form"
        onSubmit={handleSubmit(vals => {
          submitForm(Helpers.removeUnusedKeys(vals));
        })}
      >
        <div className="scrollable">
          <div className="grid">
            <Field
              label={'ISOLATION_CERTIFICATES_FILTERS.ISOLATION_TEMPLATE'}
              placeholder={'ISOLATION_CERTIFICATES_FILTERS.ISOLATION_TEMPLATE'}
              id={filterFields.isolationTemplate}
              name={filterFields.isolationTemplate}
              component={MultiSelect}
              valueField={'Name'}
              textField={'Name'}
              data={templates || []}
              onSearch={text => {
                this.getIsolationTemplatesFiltersDebounced(text);
              }}
              onToggle={isOpen => {
                if (isOpen && templates?.length === 0) {
                  this.getIsolationTemplatesFiltersDebounced('');
                }
              }}
            />
          </div>

          <div className="grid">
            <Field
              disabledKeyboardNavigation={true}
              dateFormat="MMMM d, yyyy"
              id={filterFields.dateFrom}
              name={filterFields.dateFrom}
              component={DatePicker}
              maxDate={formValues?.[filterFields.dateTo] || null}
              popperPlacement="bottom-end"
              placeholder={'ISOLATION_CERTIFICATES_FILTERS.DATE_CREATED_FROM'}
              label={'ISOLATION_CERTIFICATES_FILTERS.DATE_CREATED_FROM'}
              isClearable={true}
            />
            <Field
              disabledKeyboardNavigation={true}
              dateFormat="MMMM d, yyyy"
              id={filterFields.dateTo}
              name={filterFields.dateTo}
              component={DatePicker}
              minDate={formValues?.[filterFields.dateFrom] || null}
              popperPlacement="bottom-end"
              placeholder={'ISOLATION_CERTIFICATES_FILTERS.DATE_CREATED_TO'}
              label={'ISOLATION_CERTIFICATES_FILTERS.DATE_CREATED_TO'}
              isClearable={true}
            />
            <Field
              label={'ISOLATION_CERTIFICATES_FILTERS.CREATED_BY'}
              placeholder={'ISOLATION_CERTIFICATES_FILTERS.CREATED_BY'}
              id={filterFields.createdBy}
              name={filterFields.createdBy}
              component={MultiSelect}
              data={users || []}
              valueField={'UserID'}
              textField={'UserName'}
              onSearch={text => {
                this.getUsersDebounced(text);
              }}
              onToggle={isOpen => {
                if (isOpen && (!users || users.length === 0)) {
                  this.getUsersDebounced('');
                }
              }}
            />
          </div>

          <div className="grid">
            <Field
              disabledKeyboardNavigation={true}
              dateFormat="MMMM d, yyyy"
              id={filterFields.liveFrom}
              name={filterFields.liveFrom}
              component={DatePicker}
              maxDate={formValues?.[filterFields.liveTo] || null}
              popperPlacement="bottom-end"
              placeholder={'ISOLATION_CERTIFICATES_FILTERS.DATE_MARKED_AS_LIVE_FROM'}
              label={'ISOLATION_CERTIFICATES_FILTERS.DATE_MARKED_AS_LIVE_FROM'}
              isClearable={true}
            />
            <Field
              disabledKeyboardNavigation={true}
              dateFormat="MMMM d, yyyy"
              id={filterFields.liveTo}
              name={filterFields.liveTo}
              component={DatePicker}
              minDate={formValues?.[filterFields.liveFrom] || null}
              popperPlacement="bottom-end"
              placeholder={'ISOLATION_CERTIFICATES_FILTERS.DATE_MARKED_AS_LIVE_TO'}
              label={'ISOLATION_CERTIFICATES_FILTERS.DATE_MARKED_AS_LIVE_TO'}
              isClearable={true}
            />
            <Field
              label={'ISOLATION_CERTIFICATES_FILTERS.MARKED_AS_LIVE_BY'}
              placeholder={'ISOLATION_CERTIFICATES_FILTERS.MARKED_AS_LIVE_BY'}
              id={filterFields.markedAsLiveBy}
              name={filterFields.markedAsLiveBy}
              component={MultiSelect}
              data={users || []}
              valueField={'UserID'}
              textField={'UserName'}
              onSearch={text => {
                this.getUsersDebounced(text);
              }}
              onToggle={isOpen => {
                if (isOpen && (!users || users.length === 0)) {
                  this.getUsersDebounced('');
                }
              }}
            />
          </div>
          <div className="grid">
            <Field
              disabledKeyboardNavigation={true}
              dateFormat="MMMM d, yyyy"
              id={filterFields.deisolatedFrom}
              name={filterFields.deisolatedFrom}
              component={DatePicker}
              maxDate={formValues?.[filterFields.deisolatedTo] || null}
              popperPlacement="bottom-end"
              placeholder={'ISOLATION_CERTIFICATES_FILTERS.DATE_MARKED_AS_DEISOLATED_FROM'}
              label={'ISOLATION_CERTIFICATES_FILTERS.DATE_MARKED_AS_DEISOLATED_FROM'}
              isClearable={true}
            />
            <Field
              disabledKeyboardNavigation={true}
              dateFormat="MMMM d, yyyy"
              id={filterFields.deisolatedTo}
              name={filterFields.deisolatedTo}
              component={DatePicker}
              minDate={formValues?.[filterFields.deisolatedFrom] || null}
              popperPlacement="bottom-end"
              placeholder={'ISOLATION_CERTIFICATES_FILTERS.DATE_MARKED_AS_DEISOLATED_TO'}
              label={'ISOLATION_CERTIFICATES_FILTERS.DATE_MARKED_AS_DEISOLATED_TO'}
              isClearable={true}
            />
            <Field
              label={'ISOLATION_CERTIFICATES_FILTERS.MARKED_AS_DEISOLATED'}
              placeholder={'ISOLATION_CERTIFICATES_FILTERS.MARKED_AS_DEISOLATED'}
              id={filterFields.markedAsDeisolatedBy}
              name={filterFields.markedAsDeisolatedBy}
              component={MultiSelect}
              data={users || []}
              valueField={'UserID'}
              textField={'UserName'}
              onSearch={text => {
                this.getUsersDebounced(text);
              }}
              onToggle={isOpen => {
                if (isOpen && (!users || users.length === 0)) {
                  this.getUsersDebounced('');
                }
              }}
            />
          </div>
        </div>
        <div className="buttons">
          <Button type="button" variant="gray-outline" text={t('RESET_FILTERS')} onClick={resetFilter} />
          <Button text={t('APPLY_FILTERS', { count: keys(Helpers.removeUnusedKeys(formValues)).length || 0 })} />
        </div>
      </form>
    );
  }
}
IsolationCertificatesFilter.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  getIsolationTemplatesFilters: (params, callback) => dispatch(getIsolationTemplatesFilters(params, callback)),
  getUsers: SearchText => dispatch(getUsers({ SearchText })),
});

const mapStateToProps = (state, { initialValues }) => {
  const {
    usersTableReducer: { usersTableData },
  } = state;

  return {
    users: usersTableData,
    formValues: state.form[FORMS.isolationCertificateFilters]?.values || {},
    initialValues,
  };
};

IsolationCertificatesFilter = reduxForm({
  form: FORMS.isolationCertificateFilters,
  enableReinitialize: true,
})(IsolationCertificatesFilter);

export default connect(mapStateToProps, mapDispatchToProps)(IsolationCertificatesFilter);
