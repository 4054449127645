import actionCreaators from '../constants/action-types';

export const setTemplateModalData = data => {
  return {
    type: actionCreaators.SET_TEMPLATE_MODAL_DATA,
    data,
  };
};

export const setIsolationTemplateList = data => {
  return {
    type: actionCreaators.SET_ISOLATION_TEMPLATE_LIST,
    data,
  };
};

export const amendIsolationTemplateList = data => {
  return {
    type: actionCreaators.AMEND_ISOLATION_TEMPLATE_LIST,
    data,
  };
};

export const setSelectedIsolationTemplate = data => {
  return {
    type: actionCreaators.SET_SELECTED_ISOLATION_TEMPLATE,
    data,
  };
};

export const removeIsolationTemplateByProp = (item, prop = 'ID') => {
  return {
    type: actionCreaators.REMOVE_ISOLATION_TEMPLATE_BY_PROP,
    data: {
      item,
      prop,
    },
  };
};

export const addIsolationTemplateAtIndex = (item, index = 0) => {
  return {
    type: actionCreaators.ADD_ISOLATION_TEMPLATE_AT_INDEX,
    data: {
      item,
      index,
    },
  };
};
export const updateIsolationTemplateByProp = (item, prop = 'ID') => {
  return {
    type: actionCreaators.UPDATE_ISOLATION_TEMPLATE_BY_PROP,
    data: {
      item,
      prop,
    },
  };
};

export const updateIsolationSections = sections => {
  return {
    type: actionCreaators.UPDATE_ISOLATION_SECTIONS,
    data: sections,
  };
};

export const updatePermitTemplateSections = sections => {
  return {
    type: actionCreaators.UPDATE_PERMIT_TEMPLATE_ISOLATION_SECTIONS,
    data: sections,
  };
};
