export const detailsFields = {
  name: {
    name: 'Name',
    label: 'PERMIT_FORM.NAME_LABEL',
    placeholder: 'PERMIT_FORM.NAME_PLACEHOLDER',
    errorRequired: 'PERMIT_FORM.NAME_REQUIRED',
    minChars: 3,
    maxChars: 60,
    errorMinChars: 'PERMIT_FORM.NAME_MIN_CHARS',
    errorMaxChars: 'PERMIT_FORM.NAME_MAX_CHARS',
    id: 'permit-details-name',
  },
  templateName: {
    name: 'TemplateName',
    label: 'PERMIT_FORM.TEMPLATE_NAME_LABEL',
    placeholder: 'PERMIT_FORM.TEMPLATE_NAME_PLACEHOLDER',
    id: 'permit-details-template-name',
    isDisabled: true,
  },
  description: {
    name: 'Description',
    label: 'PERMIT_FORM.DESCRIPTION_LABEL',
    placeholder: 'PERMIT_FORM.DESCRIPTION_PLACEHOLDER',
    maxChars: 400,
    errorMaxChars: 'PERMIT_FORM.DESCRIPTION_MAX_CHARS',
    id: 'permit-details-description',
  },
};

export const dateFields = {
  startDate: {
    name: 'StartDate',
    label: 'PERMIT_FORM.START_DATE_LABEL',
    placeholder: 'PERMIT_FORM.START_DATE_PLACEHOLDER',
    errorRequired: 'PERMIT_FORM.START_DATE_REQUIRED',
    errorMinDate: 'PERMIT_FORM.START_DATE_MIN_DATE',
    id: 'permit-details-start-date',
    isDate: true,
  },
  endDate: {
    name: 'EndDate',
    label: 'PERMIT_FORM.END_DATE_LABEL',
    placeholder: 'PERMIT_FORM.END_DATE_PLACEHOLDER',
    errorRequired: 'PERMIT_FORM.END_DATE_REQUIRED',
    errorMinDate: 'PERMIT_FORM.END_DATE_MIN_DATE',
    id: 'permit-details-end-date',
    isDate: true,
  },
  revalidationTime: {
    name: 'RevalidationTime',
    label: 'PERMIT_FORM.REVALIDATION_TIME_LABEL',
    placeholder: 'PERMIT_FORM.REVALIDATION_TIME_PLACEHOLDER',
    id: 'permit-details-revalidation-time',
    errorMin: 'PERMIT_FORM.REVALIDATION_TIME_MIN_VALUE',
    errorMax: 'PERMIT_FORM.REVALIDATION_TIME_MAX_VALUE',
  },
  timeInterval: {
    name: 'RevalidationInterval',
    label: 'PERMIT_FORM.REVALIDATION_TIME_INTERVAL_LABEL',
    placeholder: 'PERMIT_FORM.REVALIDATION_TIME_INTERVAL_PLACEHOLDER',
    id: 'permit-details-revalidation-time-interval',
  },
};
