import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../../common/modal/components/modal';
import AccessRenderer from '../../../../common/access-renderer/components/access-renderer';
import Icon from '../../../../common/icon/components/icon';

import { FEATURES } from '../../../../common/constants';

import '../../styles/inspection-details.scss';
import { get } from 'lodash';

class InspectionDetails extends Component {
  state = {
    modalData: {
      isOpen: false,
    },
  };

  openFullDetailsModal = () => {
    const { t } = this.context;

    const closeModal = () =>
      this.setState({
        modalData: {
          isOpen: false,
        },
      });

    this.setState({
      modalData: {
        isOpen: true,
        title: t('INSPECTION_DETAILS.TITLE'),
        type: 'ok',
        confirmAction: closeModal,
        closeAction: closeModal,
      },
    });
  };

  render() {
    const { t } = this.context;
    const { details, modules, tools } = this.props;
    const { modalData } = this.state;
    const longitude = get(details, 'Location.Longitude') || '0',
      latitude = get(details, 'Location.Latitude') || '0',
      email = get(details, 'ContactPerson.Email') || '-',
      address = get(details, 'Location.Address') || '-',
      phone = get(details, 'ContactPerson.Phone') || '-',
      name = get(details, 'ContactPerson.Name') || '-';

    return (
      <div className="inspection-details">
        <p className="f-secondary-dark">{`${longitude}° N,${latitude}° W`}</p>
        <p className="f-secondary-dark space">{email}</p>
        <span className="f-secondary-green link" onClick={this.openFullDetailsModal}>
          {t('INSPECTION_DETAILS.MORE_INFO')}
        </span>

        <div className="divider" />
        <div className="main-lists">
          <div className="modules">
            <label className="f-secondary-dark">{t('INSPECTION_DETAILS.MODULES')}</label>
            {modules.map((item, index) => {
              return (
                <AccessRenderer visibleFor={item.visibleFor} key={index}>
                  {({ hasAccess }) => {
                    return hasAccess ? (
                      <div key={index} className={`single-module ${item.active ? 'active' : ''}`} onClick={item.action} data-cy={item.dataCy}>
                        <div className="text">
                          {item.icon && <item.icon active={true} size="sm" />}
                          <p className="f-primary">{t(item.label)}</p>
                        </div>
                        <Icon name="chevron" size="sm" solid />
                      </div>
                    ) : null;
                  }}
                </AccessRenderer>
              );
            })}
          </div>
          {FEATURES.toolsButton.visible && (
            <div className="tools">
              <label className="f-secondary-dark">{t('INSPECTION_DETAILS.TOOLS')}</label>

              {tools.map((item, index) => {
                const { visible, newFeature, comingSoon } = item.feature;
                return (
                  <AccessRenderer visibleFor={item.visibleFor} key={index}>
                    {({ hasAccess }) => {
                      return hasAccess && visible && !item.hidden ? (
                        <div key={index} className={`single-module ${newFeature ? 'new' : ''} ${comingSoon ? 'soon' : ''}`} onClick={!comingSoon && item.action ? item.action : () => null}>
                          <div className="text">
                            {item.icon && <item.icon active={true} size="sm" />}
                            <p className="f-primary">{t(item.label)}</p>
                            {newFeature && <p className="f-primary soon-box">{t('INSPECTION_DETAILS.NEW')}</p>}
                            {comingSoon && <p className="f-primary soon-box"> {t('INSPECTION_DETAILS.SOON')}</p>}
                          </div>
                          <Icon name="newTab" size="xs" solid />
                        </div>
                      ) : null;
                    }}
                  </AccessRenderer>
                );
              })}
            </div>
          )}
        </div>

        <Modal
          {...{
            ...modalData,
            CustomContent: () => (
              <div className="inspection-details-modal">
                <div className="details__info">
                  <div className="details__label">
                    <p className="f-secondary-dark">{t('INSPECTION_DETAILS_INSPECTIONS_NUMBER')}</p>
                  </div>
                  <div className="details__value">
                    <p className="f-primary">{details.NumberOfInspections}</p>
                  </div>
                </div>
                <div className="details__info">
                  <div className="details__label">
                    <p className="f-secondary-dark">{t('INSPECTION_DETAILS_DEFECTS_NUMBER')}</p>
                  </div>
                  <div className="details__value">
                    <p className="f-primary">{details.NumberOfDefects}</p>
                  </div>
                </div>
                <div className="details__info">
                  <div className="details__label">
                    <p className="f-secondary-dark">{t('INSPECTION_DETAILS_ADDRESS')}</p>
                  </div>
                  <div className="details__value">
                    <p className="f-primary">{address}</p>
                  </div>
                </div>
                <div className="details__info">
                  <div className="details__label">
                    <p className="f-secondary-dark">{t('INSPECTION_DETAILS_LOCATION')}</p>
                  </div>
                  <div className="details__value">
                    <p className="f-primary">{`${longitude}° N,${latitude}° W`}</p>
                  </div>
                </div>
                <div className="details__info">
                  <div className="details__label">
                    <p className="f-secondary-dark">{t('INSPECTION_DETAILS_CONTACT_NAME')}</p>
                  </div>
                  <div className="details__value">
                    <p className="f-primary">{name}</p>
                  </div>
                </div>
                <div className="details__info">
                  <div className="details__label">
                    <p className="f-secondary-dark">{t('INSPECTION_DETAILS_CONTACT_EMAIL')}</p>
                  </div>
                  <div className="details__value">
                    <p className="f-primary">{email}</p>
                  </div>
                </div>
                <div className="details__info">
                  <div className="details__label">
                    <p className="f-secondary-dark">{t('INSPECTION_DETAILS_CONTACT_PHONE')}</p>
                  </div>
                  <div className="details__value">
                    <p className="f-primary">{phone}</p>
                  </div>
                </div>
              </div>
            ),
          }}
        />
      </div>
    );
  }
}

InspectionDetails.defaultProps = {
  tools: [],
  modules: [],
};

InspectionDetails.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default InspectionDetails;
