export const fields = {
  name: {
    name: 'Name',
    label: 'ISOLATION_CERTIFICATES_FORM.NAME_LABEL',
    placeholder: 'ISOLATION_CERTIFICATES_FORM.NAME_PLACEHOLDER',
    errorRequired: 'ISOLATION_CERTIFICATES_FORM.NAME_REQUIRED',
    minChars: 3,
    maxChars: 60,
    errorMinChars: 'ISOLATION_CERTIFICATES_FORM.NAME_MIN_CHARS',
    errorMaxChars: 'ISOLATION_CERTIFICATES_FORM.NAME_MAX_CHARS',
    id: 'isolation-details-name',
  },
  description: {
    name: 'Description',
    label: 'ISOLATION_CERTIFICATES_FORM.DESCRIPTION_LABEL',
    placeholder: 'ISOLATION_CERTIFICATES_FORM.DESCRIPTION_PLACEHOLDER',
    maxChars: 300,
    errorMaxChars: 'ISOLATION_CERTIFICATES_FORM.DESCRIPTION_MAX_CHARS',
    id: 'isolation-details-description',
  },
  startDate: {
    name: 'StartDate',
    label: 'ISOLATION_CERTIFICATES_FORM.START_DATE_LABEL',
    placeholder: 'ISOLATION_CERTIFICATES_FORM.START_DATE_PLACEHOLDER',
    errorRequired: 'ISOLATION_CERTIFICATES_FORM.START_DATE_REQUIRED',
    errorMinDate: 'ISOLATION_CERTIFICATES_FORM.START_DATE_MIN_DATE',
    id: 'isolation-details-start-date',
    isDate: true,
  },
  endDate: {
    name: 'EndDate',
    label: 'ISOLATION_CERTIFICATES_FORM.END_DATE_LABEL',
    placeholder: 'ISOLATION_CERTIFICATES_FORM.END_DATE_PLACEHOLDER',
    errorRequired: 'ISOLATION_CERTIFICATES_FORM.END_DATE_REQUIRED',
    errorMinDate: 'ISOLATION_CERTIFICATES_FORM.END_DATE_MIN_DATE',
    id: 'isolation-details-end-date',
    isDate: true,
  },
  templateName: {
    name: 'TemplateName',
    label: 'ISOLATION_CERTIFICATE.TEMPLATE_NAME_LABEL',
    placeholder: 'ISOLATION_CERTIFICATE.TEMPLATE_NAME_PLACEHOLDER',
    id: 'isolation-details-template-name',
    isDisabled: true,
  },
};
