import React from 'react';
import Loader from '../../global-loader/components/simple-loader';

import { isEmpty } from 'lodash';

import TableComponent from '../../table/components/table-component';

import { _loadMore } from '../constants/constants';

import '../styles/items-table-renderer.scss';

class ItemsTableRenderer extends React.Component {
  state = {
    loadMoreOffset: 0,
  };

  renderRest = () => {
    const { translationModule, isLoading, emptyStateText, data } = this.props;

    if (isEmpty(data) && !isLoading) {
      return (
        <div className="empty-state">
          <p className="f-primary">{translationModule ? translationModule(emptyStateText) : emptyStateText}</p>
        </div>
      );
    }
  };
  //keep load more button in center when table is scrolled horizontaly
  onScroll = e => {
    this.setState({ loadMoreOffset: e?.currentTarget?.scrollLeft || 0 });
  };
  render() {
    const { loadMoreOffset } = this.state;
    const { translationModule, loadMore, isLoading, data, stickyHeader } = this.props;
    const { disabled, onClick, text, visible } = { ..._loadMore, ...loadMore };

    return (
      <div className={`items-table-renderer ${isLoading ? 'loading' : ''}`} onScroll={this.onScroll}>
        <TableComponent {...this.props} stickyHeader={stickyHeader} />
        {this.renderRest()}

        {!isEmpty(data) && visible && !isLoading && (
          <span className={`f-secondary-green load-more ${disabled ? 'disabled' : ''}`} style={{ marginLeft: `${loadMoreOffset}px` }} onClick={disabled ? () => null : onClick}>
            {translationModule ? translationModule(text) : text}
          </span>
        )}
        {isLoading && <Loader isLoading={true} className="loader" />}
      </div>
    );
  }
}

ItemsTableRenderer.defaultProps = {
  loadMore: {
    visible: false,
    text: 'ITEMS_TABLE_RENDERER.LOAD_MORE',
  },
  emptyStateText: 'ITEMS_TABLE_RENDERER.EMPTY_TEXT',
  stickyHeader: true,
};

export default ItemsTableRenderer;
