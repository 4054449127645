import React from 'react';
import { defectWOTableFormatting } from '../actions/table-formatting';
import { defectWorkOrdersTableConfig } from '../constants/constants';
import TableTemplate from '../../../../../../common/double-table/components/table-template';
import PropTypes from 'prop-types';
import RenderIf from '../../../../../../common/render-if/components/render-if';
import EmptyState from '../../../../../../common/empty-state-v2/components/empty-state';

const ObservationWorkOrdersSection = ({ workOrders, onWorkOrderClick, rowClickDisabled, user }, { t }) => {
  return (
    <div className="notification-WO-tab__table">
      <RenderIf if={workOrders && workOrders?.length > 0}>
        <TableTemplate
          data={workOrders}
          handleFormatCells={defectWOTableFormatting}
          showSearchBar={false}
          tableConfig={defectWorkOrdersTableConfig}
          showInfo={false}
          stickyHeader={false}
          rowClickDisabled={rowClickDisabled}
          onRowClick={onWorkOrderClick}
          noDataText={t('NOTIFICATIONS_FORM.WO_EMPTY_STATE')}
          user={user}
        />
      </RenderIf>
      <RenderIf if={!workOrders || workOrders.length === 0}>
        <EmptyState emptyStateText={t('OBSERVATIONS.WORK_ORDERS_SECTION.EMPTY_STATE')} showButton={false} transparent />
      </RenderIf>
    </div>
  );
};

ObservationWorkOrdersSection.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ObservationWorkOrdersSection;
