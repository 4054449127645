import { defaultFilters } from '../components/permits/constants/permit-constants';
import actionTypes from '../constants/action-types';

const permitsDefaultState = {
  permits: [],
  permitsLoading: false,
  permitDetailsData: null,
  filters: defaultFilters,
};

export const permitsReducer = (state = permitsDefaultState, payload) => {
  switch (payload.type) {
    case actionTypes.SET_PERMITS_DATA:
      return {
        ...state,
        permitsLoading: payload.data.isLoading || false,
        permits: payload.data.permits || [],
      };
    case actionTypes.SET_PERMIT_DETAILS_DATA:
      return {
        ...state,
        permitDetailsData: payload.data.permitDetailsData || null,
      };
    case actionTypes.SET_PERMITS_FILTERS:
      return {
        ...state,
        filters: payload.data,
      };

    default:
      return state;
  }
};
