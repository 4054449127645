import routesConstants from './routes';
import { Api } from '../../core/api';
import { API_BASE_URL } from '../../common/constants';

const getPdfList = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_PDF_LIST_DMS}`, params);
};
const getPdfComponents = async (params, options) => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_PDF_COMPONENTS_DMS}`, params, options);
};

const saveComponentTag = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.SAVE_COMPONENT_TAG_DMS}`, params);
};

const confirmTag = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CONFIRM_TAG_DMS}`, params);
};

const getComponentDrawings = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_COMPONENT_DRAWINGS_DMS}`, params);
};

const notInThisPdfCVFound = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.NOT_IN_THIS_PDF_CV_FOUND_DMS}`, params);
};

const linkToComponent = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.LINK_TO_COMPONENT_TAG_DMS}`, params);
};

const deleteComponentTag = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_COMPONENT_TAG_DMS}`, params);
};

const deleteComponentTags = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_COMPONENT_TAGS_DMS}`, params);
};

const updateComponentTag = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_COMPONENT_TAG_DMS}`, params);
};

const getComponentDrawingsDetails = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_COMPONENT_DRAWINGS_DETAILS}`, params);
};

export default {
  getPdfList,
  getPdfComponents,
  saveComponentTag,
  confirmTag,
  getComponentDrawings,
  notInThisPdfCVFound,
  linkToComponent,
  deleteComponentTag,
  deleteComponentTags,
  updateComponentTag,
  getComponentDrawingsDetails,
};
