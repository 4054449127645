import { store } from '../../../index';
import { changeUserTheme } from './action-creators';
import { updateUser } from '../../login/actions/login-actions';
import { setLanguage } from 'redux-i18n';
import ThemeHelpers from '../../../common/theme-helpers';
import userApi from '../../../api/login/actions';

import { get } from 'lodash';

export const changeTheme = (theme, data) => {
  return async dispatch => {
    try {
      const selectedTheme = ThemeHelpers.GetTheme(theme);
      await userApi.updateUserSettings(data);

      dispatch(updateUser({ Theme: theme }));
      dispatch(changeUserTheme(selectedTheme));
    } catch (e) {
      console.log(e);
    }
  };
};
export const setTheme = theme => {
  return dispatch => {
    try {
      const selectedTheme = ThemeHelpers.GetTheme(theme);

      dispatch(changeUserTheme(selectedTheme));
    } catch (e) {
      console.log(e);
    }
  };
};

export const changeSettings = unit => {
  return async dispatch => {
    try {
      await userApi.updateUserSettings(unit);
      dispatch(updateUser(unit));
    } catch (e) {
      console.log(e);
    }
  };
};

export const changeUserLanguage = (lang, data) => {
  return async dispatch => {
    try {
      await userApi.updateUserSettings(data);
      dispatch(updateUser(data));
      dispatch(setLanguage(lang));
    } catch (e) {
      console.log(e);
    }
  };
};

export const changeUser360Settings = data => {
  return async dispatch => {
    try {
      await userApi.updateUser360Settings(data);
      dispatch(updateUser({ Setting360View: data }));
    } catch (e) {
      console.log(e);
    }
  };
};

export const getUnitData = callback => {
  return async dispatch => {
    try {
      let res = await userApi.getUnitData();
      res = get(res, 'data.Data');
      if (res) {
        callback && callback(res);
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const changeEmailCommentAlerting = data => {
  return async dispatch => {
    try {
      await userApi.updateUserSettings(data);
      dispatch(updateUser(data));
    } catch (e) {
      console.log(e);
    }
  };
};

export const dispatchchangeThemeWired = () => store.dispatch(changeTheme());
