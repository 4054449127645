const fields = {
  inspectionSummary: 'InspectionSummary',
  sortBy: 'SortBy',
  description: 'InspectionSummary',
  recomendations: 'Recomendations',
  imagesPerPage: 'AdditionalImages',
  includeComponentDetails: 'IncludeComponentDetails',
  includeDefectSummary: 'IncludeDefectSummary',
  includeRecommendationSummary: 'IncludeRecommendedSummary',
  coverPDF: 'CoverPDF',
  includedIDs: 'IncludedIDs',
};

const objectConstruction = {
  key: 'Key',
  value: 'Value',
};

const tabNames = {
  downloadReport: 'download-report',
  reportHistory: 'reportHistory',
};

const tabs = [
  {
    name: tabNames.downloadReport,
    label: 'DOWNLOAD_REPORT.NEW_REPORT',
  },
  {
    name: tabNames.reportHistory,
    label: 'DOWNLOAD_REPORT.REPORT_HISTORY',
  },
];

const reportTableHeader = [
  {
    type: 'enumerated',
    title: 'DOWNLOAD_REPORT_TABLE.CREATOR',
    key: 'UserName',
    className: 'three-dots',
  },
  {
    title: 'DOWNLOAD_REPORT_TABLE.DATE_CREATED',
    key: 'ReportDate',
    type: 'date',
  },
];

const descriptionMaxChars = 4000;

const defaultValue = 'ALL';

const steps = {
  firstStep: 1,
  secondStep: 2,
  thirdStep: 3,
  fourthStep: 4,
  fifthStep: 5,
};

const stepperData = [
  {
    name: 'DOWNLOAD_REPORT.STEP_2',
    stepValue: steps.firstStep,
  },
  {
    name: 'DOWNLOAD_REPORT.STEP_3',
    stepValue: steps.secondStep,
  },
  {
    name: 'DOWNLOAD_REPORT.STEP_4',
    stepValue: steps.thirdStep,
  },
  {
    name: 'DOWNLOAD_REPORT.STEP_5',
    stepValue: steps.fourthStep,
  },
  {
    name: 'DOWNLOAD_REPORT.STEP_6',
    stepValue: steps.fifthStep,
  },
];

const imagesPerPageValues = {
  rightValue: 6,
  leftValue: 2,
};
const supportedMimeTypes = 'application/pdf';

//5 MB in bytes
const maxFileSize = 5000000;

const fileUploadType = 'PDF_COVER';

const potreeId = 'download_report_potree_1';

const mapId = 'download_report_map_1';

export default {
  fields,
  objectConstruction,
  defaultValue,
  tabs,
  tabNames,
  reportTableHeader,
  descriptionMaxChars,
  steps,
  stepperData,
  imagesPerPageValues,
  supportedMimeTypes,
  maxFileSize,
  fileUploadType,
  potreeId,
  mapId,
};
