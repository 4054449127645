import React from 'react';
import Icon from '../../icon/components/icon';
import '../styles/empty-state.scss';
import PropTypes from 'prop-types';
import RenderIf from '../../render-if/components/render-if';
import Button from '../../form/components/button';
import Helpers from '../../helpers';

const EmptyState = ({ iconName, iconProps, emptyStateText, showButton, buttonAction, buttonDisabled, buttonText, transparent }) => {
  return (
    <div className={`empty-state-container ${transparent ? 'transparent' : ''}`}>
      <div className="empty-state-container__content">
        <Icon name={iconName} size="xxl" {...iconProps} data-cy="empty-state-icon" />
        <p className="empty-state-container__content__text" data-cy="empty-state-text">
          {emptyStateText}
        </p>
        <RenderIf if={showButton}>
          <Button
            icon="plus-circle"
            text={Helpers.getShortenedText(buttonText, 27)}
            variant="success-outline"
            iconProps={{ handleHover: false, active: true }}
            onClick={buttonAction}
            disabled={buttonDisabled}
            className="default-background mt-12"
            height="sm"
            width="md"
            isCapitalized
            data-cy="empty-state-button"
            type="button"
          />
        </RenderIf>
      </div>
    </div>
  );
};

EmptyState.propTypes = {
  iconName: PropTypes.string,
  iconProps: PropTypes.object,
  emptyStateText: PropTypes.string.isRequired,
};

EmptyState.defaultProps = {
  iconName: 'sad-face',
  iconProps: {},
  showButton: false,
};

export default EmptyState;
