import { values } from 'lodash';
import Icon from '../../../../../../common/icon/components/icon';
import { fields } from '../constants/constants';
import { statuses } from '../../../notifications/constants/constants';
import { statuses as WOStatuses } from '../../../../constants/work-order-constants';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../../../common/permissions-constants';
import Helpers from '../../../../../../common/helpers';

export const defectNotificationTableFormatting = (value, type) => {
  if (type === fields.icon) {
    const statusesArray = values(statuses);
    const foundStatus = statusesArray.find(obj => obj.value === value);
    return <Icon name={foundStatus.icon || 'exclamation-mark'} size="xs" {...foundStatus.iconProps} />;
  } else return <p className="f-primary">{value ? value : '-'}</p>;
};

export const defectWOTableFormatting = (value, type, _searchTerm, _row, _onDropdownClick, t, _pointerDotID, user) => {
  if (type === fields.icon) {
    const statusesArray = values(WOStatuses);
    const foundStatus = statusesArray.find(obj => obj.value === value);
    return <Icon name={foundStatus.icon || 'clock'} size="xs" />;
  } else
    return (
      <p className="f-primary" title={Helpers.hasAccess({ user, visibleFor: PERMISSIONS[PERMISSION_TYPES.workOrders].view.name }) ? value : t('COMPONENTS_TAB.NO_PERMISSION')}>
        {value ? value : '-'}
      </p>
    );
};
