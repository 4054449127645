import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Helpers from '../../../../common/helpers';

import { saveChanges, resetForm, getIntegrationDetails, synchronise, getEquipment, collapseEquipmentItem, selectEquipmentItem, getIntegrationStatus, selectLocations } from '../../actions/sap-actions';
import { setSAPChangesSaved } from '../../actions/action-creators';

import SapForm from './sap-form';
import SyncModal from './sync-modal';
import Modal from '../../../../common/modal/components/modal';

import routesConstants from '../../../../common/routes-constants';
import sapConstants from '../constants/sap-constants';

class SAP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      syncModalData: {
        isOpen: false,
      },
    };
    this.interval = null;
  }
  componentDidMount() {
    const { resetForm, getDetails, projectID, getIntegrationStatus } = this.props;

    resetForm();
    getDetails(projectID);
    getIntegrationStatus(projectID);
    this.interval = setInterval(this.startFetchingIntegrationStatus, 5000);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  startFetchingIntegrationStatus = () => {
    const { getIntegrationStatus, projectID } = this.props;

    getIntegrationStatus(projectID);
  };

  handleSubmit = values => {
    const { t } = this.context;
    const { integrationStatus, projectID, sync } = this.props;
    if (integrationStatus === sapConstants.integrationSatus.inProgress) {
      return;
    }
    sync(values, projectID, t);
  };

  handleCancel = () => {
    Helpers.goTo(routesConstants.routes.protectedRoutes.project.fullPath);
  };

  handleChange = () => {
    const { setChangesSaved } = this.props;
    setChangesSaved(false);
  };

  handleSaveData = values => {
    const { t } = this.context;
    const { saveData, isSaving, projectID } = this.props;
    if (isSaving) return;
    saveData(values, projectID, t);
  };

  openSyncModal = values => {
    const { t } = this.context;
    const { projectID, getEquipment, collapseEquipmentItem, selectEquipmentItem, selectLocations } = this.props;

    this.setState(prevState => ({
      syncModalData: {
        ...prevState.syncModalData,
        customClassName: 'sync-modal-wrapper modal-medium',
        title: t('INTEGRATIONS.SAP.SYNC_MODAL.TITLE'),
        CustomContent: () => (
          <SyncModal
            projectID={projectID}
            handleSubmit={equipment => selectLocations(equipment, projectID, this.closeSyncModal, t)}
            getEquipment={() => getEquipment(projectID)}
            collapseEquipmentItem={collapseEquipmentItem}
            selectEquipmentItem={selectEquipmentItem}
          />
        ),
        isOpen: true,
        type: 'none',
        closeAction: this.closeSyncModal,
      },
    }));
  };

  closeSyncModal = () => {
    this.setState(prevState => ({ syncModalData: { ...prevState.syncModalData, isOpen: false } }));
  };

  render() {
    const { syncModalData } = this.state;
    const { isSaved, isSaving, isSynchronizing, theme, formError, initialValues, infoModalData, integrationStatus } = this.props;

    return (
      <Fragment>
        <SapForm
          onSubmit={this.handleSubmit}
          saveData={this.handleSaveData}
          onChange={this.handleChange}
          onCancel={this.handleCancel}
          isSaved={isSaved}
          isSaving={isSaving}
          isSynchronizing={isSynchronizing}
          formError={formError}
          theme={theme}
          status={integrationStatus}
          openSyncModal={values => this.openSyncModal(values)}
          initialValues={initialValues}
        />
        <Modal {...infoModalData} />
        <Modal {...syncModalData} />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isSaved: state.sapReducer.isSaved,
  isSaving: state.sapReducer.isSaving,
  isSynchronizing: state.sapReducer.isSynchronizing,
  formError: state.sapReducer.formError,
  theme: state.userReducer.Theme,
  initialValues: state.sapReducer.sapDetails,
  infoModalData: state.sapReducer.infoModalData,
  integrationStatus: state.sapReducer.integrationStatus,
});

const mapDispatchToProps = dispatch => ({
  getDetails: projectID => dispatch(getIntegrationDetails(projectID)),
  setChangesSaved: isSaved => dispatch(setSAPChangesSaved(isSaved)),
  saveData: (data, projectID, translation) => dispatch(saveChanges(data, projectID, translation)),
  sync: (data, projectID, translation) => dispatch(synchronise(data, projectID, translation)),
  resetForm: () => dispatch(resetForm()),
  getEquipment: projectID => dispatch(getEquipment(projectID)),
  collapseEquipmentItem: (id, equipment) => dispatch(collapseEquipmentItem(id, equipment)),
  selectEquipmentItem: (id, equipment) => dispatch(selectEquipmentItem(id, equipment)),
  getIntegrationStatus: projectID => dispatch(getIntegrationStatus(projectID)),
  selectLocations: (equipment, projectID, translation, closeAction) => dispatch(selectLocations(equipment, projectID, translation, closeAction)),
});

SAP.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SAP);
