import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import EmptyState from '../../../../common/empty-state/components/empty-state';
import HistoryItem from '../../../../common/history-item/components/history-item';
import LoadMore from '../../../../common/load-more/components/load-more';
import ToolbarTitle from '../../../../common/toolbar-title/components/toolbar-title';
import RenderIf from '../../../../common/render-if/components/render-if';
import { sortingDirection, historyDefaultPagingObj, historyFilterParams, historyItems, ndtModificationEvents } from '../../constants/ndt-constants';
import { getHistoryLength } from '../../helpers/inspection-helper';
import { getNDTHistory } from '../../actions/inspection-actions';
import { params } from '../../../profile/constants/profile-constants';
import { routes } from '../../../../common/routes-constants';
import { generateQueryParams } from '../../../../api/helpers/helper';
import { modules } from '../../constants/constants';

import '../../styles/module-item-history.scss';

class NDTMeasurementHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      history: [],
      paging: historyDefaultPagingObj,
    };
  }

  fetchHistory = () => {
    this.props.getNDTHistory(
      {
        ItemId: this.props.measurementID,
        LastSeen: this.state.paging[historyFilterParams.lastSeen],
        PerPage: this.state.paging[historyFilterParams.perPage],
        SortByColumn: historyFilterParams.sortByColumn,
        SortDirection: sortingDirection.desc,
      },
      this.state.history,
      this.state.paging,
      data => this.setState(data)
    );
  };

  componentDidMount = () => {
    this.fetchHistory();
  };

  getRedirectPath = (selected_item, type = modules.components) => {
    const { inspection_id, project_id, user, defaultComponent } = this.props;

    //default component has no details
    if (selected_item === defaultComponent) {
      return null;
    }

    const basePath = user[params.fullScreenEnabled] ? routes.protectedRoutes.fullScreen.fullPath : routes.protectedRoutes.inspections.fullPath;
    return `${basePath}${generateQueryParams([{ project_id }, { inspection_id }, { type }, { selected_item }])}`;
  };

  render() {
    const historyLength = getHistoryLength(
      this.state.history,
      ndtModificationEvents,
      historyItems,
      ndtModificationEvents.ndt_measurement_location_created.event,
      ndtModificationEvents.ndt_measurement_location_deleted.event
    );

    return (
      <div className="module-item-history">
        <ToolbarTitle variant="medium">{'NDT_MEASUREMENTS.TITLE'}</ToolbarTitle>
        <RenderIf if={!this.state.isLoading && (!this.state.history.length || !historyLength)}>
          <EmptyState title="NDT_MEASUREMENTS.EMPTY_STATE_TITLE" message="NDT_MEASUREMENTS.EMPTY_STATE_MESSAGE" style={{ height: '80vh' }} />
        </RenderIf>
        <RenderIf if={!this.state.isLoading && this.state.history.length > 0 && historyLength > 0}>
          <div className="module-item-history__wrapper">
            <div className={this.state.isLoading ? 'background-blurred' : ''}>
              {this.state.history.map(item => (
                <HistoryItem
                  key={item.id}
                  items={historyItems}
                  item={item}
                  moduleName="NDT_MEASUREMENTS.MODULE_NAME"
                  events={ndtModificationEvents}
                  moduleCreateEvent={ndtModificationEvents.ndt_measurement_location_created.event}
                  moduleFileUnlinkEvent={ndtModificationEvents.ndt_measurement_location_file_linked.event}
                  moduleDeleteEvent={ndtModificationEvents.ndt_measurement_location_deleted.event}
                  getRedirectPath={this.getRedirectPath}
                />
              ))}
            </div>
            <LoadMore
              disabled={!this.state.paging[historyFilterParams.hasNext] || this.state.isLoading}
              loaded={this.state.history.length}
              total={this.state.paging[historyFilterParams.totalItems]}
              totalPosition="center"
              isLoading={this.state.isLoading}
              label="COMPONENT_HISTORY.LOAD_MORE"
              isFixed
              onClick={this.fetchHistory}
            />
          </div>
        </RenderIf>
      </div>
    );
  }
}

NDTMeasurementHistory.contextTypes = {
  t: PropTypes.func.isRequired,
};

NDTMeasurementHistory.propTypes = {
  measurementID: PropTypes.number.isRequired,
  project_id: PropTypes.number.isRequired,
  inspection_id: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
  user: state.userReducer,
});

const mapDispatchToProps = dispatch => ({
  getNDTHistory: (params, history, paging, callback) => dispatch(getNDTHistory(params, history, paging, callback)),
});

NDTMeasurementHistory = connect(mapStateToProps, mapDispatchToProps)(NDTMeasurementHistory);

export default NDTMeasurementHistory;
