import { THEMES } from './constants';

import { maps } from './theme-config/map-style';
import { lineChartStyle } from './theme-config/line-chart-style';
import { columntChartStyle } from './theme-config/column-chart-style';
import { thisMonthLaneStyle } from './theme-config/this-month-lane-style';
import { lastMonthLaneStyle } from './theme-config/last-month-lane-style';
import { uploadProgress } from './theme-config/upload-progress-style';

class ThemeHelpers {
  static setBodyTheme(currentTheme) {
    document.body.setAttribute('data-theme', currentTheme);
  }

  static GetTheme = theme => {
    if (!theme) {
      this.setBodyTheme(THEMES.light.name);
      return null;
    }

    const currentTheme = THEMES[theme];
    const colorPallet = currentTheme.colors;
    this.setBodyTheme(currentTheme.name);

    return {
      lineChartOptions: this.getLineChartOptions(colorPallet),
      comlumnChartOptions: this.getColumnChartOptions(colorPallet),
      thisMonthLine: this.getThisMontLane(colorPallet),
      lastMonthLine: this.getlastMonthLine(colorPallet),
      uploadProgress: this.getUploadProgress(colorPallet),
      map: maps[currentTheme.name] && maps[currentTheme.name].theme,
      severityColors: colorPallet,
    };
  };

  static getLineChartOptions(colors) {
    return {
      ...lineChartStyle,
      backgroundColor: colors.boxItemsThemeColor,
      colors: [colors.secondaryThemeColor],
      hAxis: {
        textStyle: {
          color: colors.secondaryFontColorLight,
        },
      },
      vAxis: {
        gridlines: {
          color: colors.boxItemsSeparatorColor,
        },
        minorGridlines: {
          color: colors.boxItemsThemeColor,
        },
        baselineColor: colors.boxItemsSeparatorColor,
      },
    };
  }

  static getColumnChartOptions(colors) {
    return {
      ...columntChartStyle,
      backgroundColor: colors.boxItemsThemeColor,
      vAxis: {
        gridlines: {
          color: colors.boxItemsSeparatorColor,
        },
        minorGridlines: {
          color: colors.boxItemsThemeColor,
        },
        baselineColor: colors.boxItemsSeparatorColor,
      },
    };
  }
  static getThisMontLane(colors) {
    return {
      ...thisMonthLaneStyle,
      strokeColor: colors.secondaryThemeColor,
      trailColor: colors.boxItemsSeparatorColor,
    };
  }

  static getlastMonthLine(colors) {
    return {
      ...lastMonthLaneStyle,
      strokeColor: colors.secondaryFontColorLight,
      trailColor: colors.boxItemsSeparatorColor,
    };
  }
  static getUploadProgress(colors) {
    return {
      ...uploadProgress,
      strokeColor: colors.secondaryThemeColor,
      trailColor: colors.boxItemsSeparatorColor,
    };
  }
}

export default ThemeHelpers;
