import inspectionApi from '../../../api/inspection/actions';

export const getComponentModuleHistory = (params, history, paging, callback) => {
  return async () => {
    try {
      callback({ isLoading: true });
      const res = await inspectionApi.getComponentModuleHistory(params);
      const data = res?.data?.Data;

      if (data?.history) {
        callback({
          isLoading: false,
          history: [...history, ...data.history],
          paging: { ...paging, HasNext: data.hasNext || false, LastSeen: data.lastSeen, TotalNumber: data.totalItems },
        });
      } else {
        callback({ isLoading: false });
      }
    } catch (e) {
      callback({ isLoading: false });
      console.log(e);
    }
  };
};

export const getDefectModuleHistory = (params, history, paging, callback) => {
  return async () => {
    try {
      callback({ isLoading: true });
      const res = await inspectionApi.getDefectModuleHistory(params);
      const data = res?.data?.Data;

      if (data?.history) {
        callback({
          isLoading: false,
          history: [...history, ...data.history],
          paging: { ...paging, HasNext: data.hasNext || false, LastSeen: data.lastSeen, TotalNumber: data.totalItems },
        });
      } else {
        callback({ isLoading: false });
      }
    } catch (e) {
      callback({ isLoading: false });
      console.log(e);
    }
  };
};

export const getMeasurementModuleHistory = (params, history, paging, callback) => {
  return async () => {
    try {
      callback({ isLoading: true });
      const res = await inspectionApi.getMeasurementModuleHistory(params);
      const data = res?.data?.Data;

      if (data?.history) {
        callback({
          isLoading: false,
          history: [...history, ...data.history],
          paging: { ...paging, HasNext: data.hasNext || false, LastSeen: data.lastSeen, TotalNumber: data.totalItems },
        });
      } else {
        callback({ isLoading: false });
      }
    } catch (e) {
      callback({ isLoading: false });
      console.log(e);
    }
  };
};

export const getAreaModuleHistory = (params, history, paging, callback) => {
  return async () => {
    try {
      callback({ isLoading: true });
      const res = await inspectionApi.getAreaModuleHistory(params);
      const data = res?.data?.Data;

      if (data?.history) {
        callback({
          isLoading: false,
          history: [...history, ...data.history],
          paging: { ...paging, HasNext: data.hasNext || false, LastSeen: data.lastSeen, TotalNumber: data.totalItems },
        });
      } else {
        callback({ isLoading: false });
      }
    } catch (e) {
      callback({ isLoading: false });
      console.log(e);
    }
  };
};

export const getNDTModuleHistory = (params, history, paging, callback) => {
  return async () => {
    try {
      callback({ isLoading: true });
      const res = await inspectionApi.getNDTModuleHistory(params);
      const data = res?.data?.Data;

      if (data?.history) {
        callback({
          isLoading: false,
          history: [...history, ...data.history],
          paging: { ...paging, HasNext: data.hasNext || false, LastSeen: data.lastSeen, TotalNumber: data.totalItems },
        });
      } else {
        callback({ isLoading: false });
      }
    } catch (e) {
      callback({ isLoading: false });
      console.log(e);
    }
  };
};

export const getExplosiveZonesModuleHistory = (params, history, paging, callback) => {
  return async () => {
    try {
      callback({ isLoading: true });
      const res = await inspectionApi.getExplosiveZonesModuleHistory(params);
      const data = res?.data?.Data;

      if (data?.history) {
        callback({
          isLoading: false,
          history: [...history, ...data.history],
          paging: { ...paging, HasNext: data.hasNext || false, LastSeen: data.lastSeen, TotalNumber: data.totalItems },
        });
      } else {
        callback({ isLoading: false });
      }
    } catch (e) {
      callback({ isLoading: false });
      console.log(e);
    }
  };
};
