import { isEmpty } from 'lodash';
import React from 'react';
import { colorPalette } from '../../../../../../../common/form/constants/constants';
import Helpers from '../../../../../../../common/helpers';
import Icon from '../../../../../../../common/icon/components/icon';
import ReadMoreTooltip from '../../../../../../../common/read-more-tooltip/components/read-more-tooltip';
import InfoTooltip from '../../../../../../../common/tooltip/components/info-tooltip';
import { componentHasAllCoordinatesZero } from '../../../../../helpers/inspection-helper';
import { tableFields } from '../constants/constants';
import LevelPopover from '../level-popover';

const getComponentNameCell = (item, value, searchTerm) => {
  return (
    <div className="assign-components__name">
      {item.Colour ? <div className="assign-components__name__status-icon" style={{ backgroundColor: colorPalette[item.Colour] }} /> : null}
      <div className="f-primary assign-components__name__text">{Helpers.getSearchTermText(value, searchTerm)}</div>
    </div>
  );
};

const getValueWithSearchTerm = (value, searchTerm) => {
  return isEmpty(value) ? '-' : searchTerm ? Helpers.getSearchTermText(value, searchTerm) : value;
};

export const formatLevelCells = (value, type, searchTerm, row, onDropdownClick, t) => {
  if (type === tableFields.icon) {
    return <Icon name="folder" size="sm" />;
  } else if (type === tableFields.code) {
    return getValueWithSearchTerm(value, searchTerm);
  } else if (type === tableFields.name) {
    return (
      <div className="ch-display__level__name">
        {getValueWithSearchTerm(value, searchTerm)}
        <InfoTooltip
          customComponent={<LevelPopover level={row} />}
          Component={() => <Icon name="info" size="sm" />}
          className="ch-display__level__details"
          containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
        />
      </div>
    );
  } else if (type === tableFields.actionMenu) {
    return (
      <button className="action-menu-column__button" onClick={e => onDropdownClick(e, row)}>
        <Icon name={row.visible ? 'eye' : 'not-visible'} size="sm" />
      </button>
    );
  } else {
    return value ? value : '-';
  }
};
export const formatComponentCells = (value, type, searchTerm, row, onDropdownClick, t) => {
  if (type === tableFields.icon) {
    return <Icon name="components" active size="sm" />;
  } else if (type === tableFields.code || type === tableFields.type) {
    return <p className="f-primary component-code">{getValueWithSearchTerm(value, searchTerm)}</p>;
  } else if (type === tableFields.name) {
    return getComponentNameCell(row, value, searchTerm);
  } else if (type === tableFields.actionMenu) {
    return (
      <>
        {row[tableFields.critical] ? (
          <div className="critical-pin">
            <InfoTooltip
              offsetY={8}
              offsetX={8}
              customComponent={<p className="f-primary">{t('CRITICAL_EQUIPMENT.TITLE')}</p>}
              Component={() => <Icon name="critical-hazard" warning handleHover={false} size="md" />}
              componentProps={{ title: '' }}
              containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
            />
          </div>
        ) : null}
        {componentHasAllCoordinatesZero(row?.Geometry?.coordinates || []) ? (
          <div className="missing-pin">
            <InfoTooltip
              offsetY={8}
              offsetX={8}
              customComponent={<p className="f-primary">{t('INFO.MISSING_LOCATION')}</p>}
              Component={() => <Icon name="map-crossed" warning handleHover={false} size="xs" />}
              componentProps={{ title: '' }}
              containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
            />
          </div>
        ) : null}
        <button className="action-menu-column__button" onClick={e => onDropdownClick(e, row)}>
          <Icon name={row.visible ? 'eye' : 'not-visible'} size="sm" />
        </button>
      </>
    );
  } else {
    return value ? value : '-';
  }
};

export const formatLevelCellsFS = (value, type, searchTerm, row, onDropdownClick, t) => {
  if (type === tableFields.icon) {
    return <Icon name="folder" size="sm" />;
  } else if (type === tableFields.code) {
    return getValueWithSearchTerm(value, searchTerm);
  } else if (type === tableFields.name) {
    return <div className="ch-display__level__name">{getValueWithSearchTerm(value, searchTerm)}</div>;
  } else {
    return value ? value : '-';
  }
};
export const formatComponentCellsFS = (value, type, searchTerm, row, onDropdownClick, t, onRowClick) => {
  if (type === tableFields.icon) {
    return <Icon name="components" active size="sm" />;
  } else if (type === tableFields.code || type === tableFields.type) {
    return <p className="f-primary component-code">{getValueWithSearchTerm(value, searchTerm)}</p>;
  } else if (type === tableFields.name) {
    return getComponentNameCell(row, value, searchTerm);
  } else if (type === tableFields.critical) {
    return (
      <>
        {value ? (
          <div className="missing-pin">
            <InfoTooltip
              offsetY={8}
              offsetX={8}
              customComponent={<p className="f-primary">{t('CRITICAL_EQUIPMENT.TITLE')}</p>}
              Component={() => <Icon name="critical-hazard" warning handleHover={false} size="xs" />}
              componentProps={{ title: '' }}
              containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
            />
          </div>
        ) : (
          '-'
        )}
      </>
    );
  } else if (type === tableFields.description) {
    return value ? (
      <span className="description-column__container">
        <ReadMoreTooltip value={value} onClick={() => onRowClick(row)} />
      </span>
    ) : (
      '-'
    );
  } else {
    return value ? value : '-';
  }
};
