import { findIndex, isEmpty } from 'lodash';
import permitTemplateApi from '../../../api/permit-template/actions';
import permitsApi from '../../../api/permits/actions';
import Helpers from '../../../common/helpers';
import ReducerHelpers from '../../../common/reducer-helpers';
import { formConstants as issueFormConstants, keyFields as issueKeyFields } from '../components/permits/constants/issue-permit-constants';
import { fields, filterProps, permitFields, statuses } from '../components/permits/constants/permit-constants';
import { sections } from '../components/permits/constants/section-constants';
import { dateFields, detailsFields } from '../components/permits/constants/section-form-constants';
import { moduleSectionsFields } from '../constants/constants';
import { mapSections } from '../helpers/inspection-helper';
import { setPermitDetailsData, setPermitsData, setPermitsFilters } from './action-creators';

export const getPermits = (filters, permits, loadMore = false, callback, advancedFilter = {}) => {
  return async dispatch => {
    try {
      dispatch(setPermitsData(true));
      const res = await permitsApi.getPermits({ ...filters, ...advancedFilter });
      const data = res?.data?.Data;

      if (data?.Items) {
        const newPermits = loadMore ? [...permits, ...data.Items] : data.Items;
        dispatch(setPermitsData(false, newPermits));

        const newFilters = {
          ...filters,
          [filterProps.hasNext]: data[filterProps.hasNext] || false,
          [filterProps.lastSeen]: data[filterProps.lastSeen],
          [filterProps.totalItems]: data[filterProps.totalItems],
        };

        dispatch(setPermitsFilters(newFilters));
        if (typeof callback === 'function') {
          callback(newPermits.length);
        }
      } else {
        dispatch(setPermitsData(false));
      }
    } catch (e) {
      dispatch(setPermitsData(false));
    }
  };
};

export const getPermitTemplates = (data, callback) => {
  return async () => {
    try {
      const res = await permitTemplateApi.getPermitTemplates(data);
      const templates = res?.data?.Data?.PermitTemplate;

      if (templates && typeof callback === 'function') {
        callback(templates);
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const getPermitTemplatesFilters = (data, callback) => {
  return async () => {
    try {
      const res = await permitTemplateApi.getPermitTemplatesFilters(data);
      const templates = res?.data?.Data;

      if (templates && typeof callback === 'function') {
        callback(templates);
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const createPermit = (data, createdByName, permits, filters, callback) => {
  return async dispatch => {
    try {
      const res = await permitsApi.createPermit(data);
      let permit = res?.data?.Data;
      permit = { ...permit, [fields.permitTemplate]: permit[fields.templateName] };

      if (permit) {
        permit[fields.linkedIsolationCertificates] = 0;
        permit[fields.createdBy] = createdByName;
        permits.unshift(permit);

        filters[filterProps.totalItems] += 1;
        filters[filterProps.lastSeen] += 1;
      }

      dispatch(setPermitsFilters(filters));
      dispatch(setPermitsData(false, permits));
      dispatch(setPermitDetailsData(permit));

      if (typeof callback === 'function') {
        callback();
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const getPermitDetails = (permitId, callback) => {
  return async () => {
    try {
      callback(true);
      const res = await permitsApi.getPermitDetails([{ permit_id: permitId }]);
      let permitDetails = res?.data?.Data;

      if (permitDetails && permitDetails.Sections) {
        permitDetails = await mapSections(permitDetails, sections, detailsFields.name.name);
      }

      callback(false, permitDetails);
    } catch (e) {
      callback(false);
      console.log(e);
    }
  };
};

export const updatePermitDetails = data => {
  return async dispatch => {
    try {
      await permitsApi.updatePermit(data.values);
      const permits = Object.assign([], data.modules);

      const newPermits = ReducerHelpers.updateItemInListByProp(permits, { ...data.values, [permitFields.id]: data.values[permitFields.permitId] }, permitFields.id);
      dispatch(setPermitsData(false, newPermits));
    } catch (e) {
      console.log(e);
    }
  };
};

export const updatePermitDates = data => {
  return async dispatch => {
    try {
      data.values = {
        ...data.values,
        [dateFields.timeInterval.name]: data.values[dateFields.timeInterval.name] ? data.values[dateFields.timeInterval.name].ID : null,
        [dateFields.revalidationTime.name]: data.values[dateFields.revalidationTime.name] || null,
      };
      await permitsApi.updatePermitDates(data.values);
      const permits = Object.assign([], data.modules);

      const newPermits = ReducerHelpers.updateItemInListByProp(permits, { ...data.values, [permitFields.id]: data.values[permitFields.permitId] }, permitFields.id);
      dispatch(setPermitsData(false, newPermits));
    } catch (e) {
      console.log(e);
    }
  };
};

export const archivePermit = (data, permit, permits, filters, callback) => {
  return async dispatch => {
    try {
      await permitsApi.archivePermit(data);
      permit[permitFields.archived] = data[permitFields.archive];

      dispatch(getPermits(filters, permits, false));

      callback(permit);
    } catch (e) {
      console.log(e);
    }
  };
};

export const deletePermit = (data, permits, filters, callback) => {
  return async dispatch => {
    try {
      await permitsApi.deletePermit(data);
      permits = ReducerHelpers.removeItemByProp(permits, data, permitFields.id);

      filters[filterProps.totalItems] -= 1;
      filters[filterProps.lastSeen] -= 1;

      dispatch(setPermitsFilters(filters));
      dispatch(setPermitsData(false, permits));
      callback({}, true);
    } catch (e) {
      console.log(e);
    }
  };
};

export const getPermitIsolationCertificates = (filters, isolationCertificates, loadMore = false, callback) => {
  return async () => {
    try {
      const res = await permitsApi.getPermitIsolationCertificates(filters);
      const data = res?.data?.Data;

      if (data?.Items) {
        if (callback) {
          const newFilters = {
            ...filters,
            [filterProps.hasNext]: data[filterProps.hasNext] || false,
            [filterProps.lastSeen]: data[filterProps.lastSeen],
            [filterProps.totalItems]: data[filterProps.totalItems],
          };

          callback({ filters: newFilters, isolationCertificates: loadMore ? [...isolationCertificates, ...data.Items] : data.Items });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const linkIsolationCertificate = (params, callback) => {
  return async () => {
    try {
      await permitsApi.linkIsolationCertificate(params);

      if (typeof callback === 'function') {
        callback();
      }
    } catch (e) {}
  };
};

export const removeIsolationCertificate = (params, callback) => {
  return async () => {
    try {
      await permitsApi.removeIsolationCertificate(params);

      if (typeof callback === 'function') {
        callback();
      }
    } catch (e) {}
  };
};

export const getPermitKeyboxes = (filters, keyboxes, loadMore = false, callback) => {
  return async () => {
    try {
      const res = await permitsApi.getPermitKeyboxes(filters);
      const data = res?.data?.Data;

      if (data?.Items) {
        if (callback) {
          const newFilters = {
            ...filters,
            [filterProps.hasNext]: data[filterProps.hasNext] || false,
            [filterProps.lastSeen]: data[filterProps.lastSeen],
            [filterProps.totalItems]: data[filterProps.totalItems],
          };

          callback({ filters: newFilters, keyboxes: loadMore ? [...keyboxes, ...data.Items] : data.Items });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const linkKeybox = (params, callback) => {
  return async () => {
    try {
      await permitsApi.linkKeybox(params);

      if (typeof callback === 'function') {
        callback();
      }
    } catch (e) {}
  };
};

export const removeKeybox = (params, callback) => {
  return async () => {
    try {
      await permitsApi.removeKeybox(params);

      if (typeof callback === 'function') {
        callback();
      }
    } catch (e) {}
  };
};

const formatPermitOnStatusChange = async (permits, filters, data) => {
  const index = findIndex(permits, { [moduleSectionsFields.id]: data[permitFields.permitId] });

  if (index > -1) {
    // if the new status of the permit is one of the selected for the filters, or it's show all, just change it,
    // else, remove it since now it shouldn't be in the list
    if (!filters[filterProps.status] || !filters[filterProps.status].length || filters[filterProps.status].indexOf(data[permitFields.status]) > -1) {
      const permit = Object.assign({}, permits[index]);
      permit[permitFields.status] = data[permitFields.status];
      permits.splice(index, 1, permit);
    } else {
      permits.splice(index, 1);
    }
  }

  return permits;
};

export const changePermitStatus = (data, permits, filters, callback) => {
  return async dispatch => {
    try {
      await permitsApi.changePermitStatus(data);

      permits = await formatPermitOnStatusChange(permits, filters, data);

      dispatch(setPermitsData(false, permits));
      callback(true);
    } catch (e) {
      console.log(e);
      callback();
    }
  };
};

export const changePermitStatusToIssued = (data, permits, filters, callback) => {
  return async dispatch => {
    try {
      await permitsApi.changePermitStatusToIssue(data);

      let newPermits = Object.assign([], permits);
      newPermits = await formatPermitOnStatusChange(permits, filters, { ...data, [permitFields.status]: statuses.issued.value });

      const keyboxes = (data[issueFormConstants.fields.assignedKeys] || []).map(assignedKey => ({
        ID: assignedKey[issueKeyFields.keyboxId],
        [issueFormConstants.fields.name]: assignedKey[issueKeyFields.selectedKey]?.KeyboxName,
        [fields.keys]: [assignedKey[issueKeyFields.selectedKey]],
      }));
      newPermits = ReducerHelpers.updateItemInListByProp(
        newPermits,
        {
          [fields.issuedAt]: Helpers.getUnixDate(new Date().getTime()),
          [fields.issuedTo]: data[issueFormConstants.fields.contractor.name].Name,
          [permitFields.id]: data[permitFields.permitId],
          [permitFields.keyboxes]: keyboxes,
        },
        permitFields.id
      );

      dispatch(setPermitsData(false, newPermits));
      callback(true);
    } catch (e) {
      console.log(e);
      callback();
    }
  };
};

export const changePermitStatusToSurrendered = (data, permits, filters, callback) => {
  return async dispatch => {
    try {
      await permitsApi.changePermitStatusToSurrender(data);

      permits = await formatPermitOnStatusChange(permits, filters, data);

      dispatch(setPermitsData(false, permits));
      callback(true);
    } catch (e) {
      console.log(e);
      callback();
    }
  };
};

export const changePermitStatusToReIssued = (data, permits, filters, callback) => {
  return async dispatch => {
    try {
      await permitsApi.changePermitStatusToReIssue(data);

      permits = await formatPermitOnStatusChange(permits, filters, data);

      dispatch(setPermitsData(false, permits));
      callback(true);
    } catch (e) {
      console.log(e);
      callback();
    }
  };
};

export const changePermitStatusToHandback = (data, permits, filters, callback) => {
  return async dispatch => {
    try {
      await permitsApi.changePermitStatusToHandback(data);

      permits = await formatPermitOnStatusChange(permits, filters, data);

      dispatch(setPermitsData(false, permits));
      callback(true);
    } catch (e) {
      console.log(e);
      callback();
    }
  };
};

export const getHandbackStatusData = (data, callback) => {
  return async () => {
    try {
      const res = await permitsApi.getHandbackStatusData(data);
      const handbackData = res?.data?.Data;

      if (handbackData) {
        callback(true, handbackData);
      }
    } catch (e) {
      console.log(e);
      callback();
    }
  };
};

export const generateAndDownloadPermitPDF = (permit_id, callback, additional_empty_page) => {
  return async () => {
    try {
      const res = await permitsApi.generatePDF([{ permit_id }, { additional_empty_page }]);
      const fileName = res.request.getResponseHeader('content-disposition').split('filename=')[1].split(';')[0];

      const file = res?.data;
      file.name = fileName || null;
      if (file) {
        Helpers.downloadPDFFile(file);
      }

      callback();
    } catch (e) {
      console.log(e);
      callback();
    }
  };
};

export const getStatusHistory = (data, callback) => {
  return async () => {
    try {
      const res = await permitsApi.getStatusHistory(data);
      const statusHistory = res?.data?.Data;

      if (statusHistory) {
        callback(true, statusHistory);
      }
    } catch (e) {
      console.log(e);
      callback();
    }
  };
};

export const answerOnQuestion = (data, callback) => {
  return async () => {
    try {
      await permitsApi.answerOnQuestion(data);

      if (typeof callback === 'function') {
        callback();
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const addComponent = (data, callback) => {
  return async () => {
    try {
      const res = await permitsApi.addComponent(data);
      const component = res?.data?.Data;

      callback(component);
    } catch (e) {
      console.log(e);
    }
  };
};

export const deleteComponent = (data, callback) => {
  return async () => {
    try {
      await permitsApi.deleteComponent(data);

      callback();
    } catch (e) {
      console.log(e);
    }
  };
};

export const deletePermitFile = (PermitID, FileID, successCallback) => {
  return async () => {
    try {
      await permitsApi.deletePermitFile({ FileID, PermitID });

      successCallback && successCallback();
    } catch (err) {
      console.log(err);
    }
  };
};

export const getPermitContractorsList = (data, callback) => {
  return async () => {
    try {
      const res = await permitsApi.getPermitContractorsList(data);
      const contractors = res?.data?.Data?.Contractors;

      if (contractors) {
        callback(contractors);
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const getPermitStatusFlows = (permitID, callback) => {
  return async () => {
    try {
      const res = await permitsApi.getPermitStatusFlows([{ permit_id: permitID }]);

      callback && callback(res?.data?.Data);
    } catch (e) {
      console.log(e);
    }
  };
};
export const addPermitWorkArea = (permitId, data) => {
  return async () => {
    try {
      if (isEmpty(data)) return;

      await permitTemplateApi.addPermitWorkArea({ PermitId: permitId, ...data });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updatePermitWorkArea = (permitId, data, dataCallback = () => null) => {
  return async () => {
    try {
      if (isEmpty(data)) return;

      const res = await permitTemplateApi.updatePermitWorkArea({ PermitId: permitId, ...data });
      const { Data } = res.data;
      dataCallback && typeof dataCallback === 'function' && dataCallback(Data);
    } catch (e) {
      console.log(e);
    }
  };
};

export const deletePermitWorkArea = (permitId, data) => {
  return async () => {
    try {
      if (isEmpty(data)) return;

      await permitTemplateApi.deletePermitWorkArea({ PermitId: permitId, AreaID: data[fields.id] });
    } catch (e) {
      console.log(e);
    }
  };
};

// permit comments
export const fetchPermitComments = (params, callback) => {
  return async () => {
    try {
      callback({
        commentsLoading: true,
      });
      const res = await permitsApi.fetchPermitComments([params]);
      const { Data } = res?.data;
      callback({
        commentsList: Data,
        commentsLoading: false,
      });
    } catch (e) {
      callback({
        commentsLoading: false,
      });
      console.error(e);
    }
  };
};

export const addPermitComment = (filters, dataCallback, loadingCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      await permitsApi.addPermitComment(filters);
      dataCallback && typeof dataCallback === 'function' && dataCallback();
      isLoadingCallbackValid && loadingCallback(false);
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
    }
  };
};

export const deletePermitComment = (filters, dataCallback, loadingCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      await permitsApi.deletePermitComment(filters);
      dataCallback && typeof dataCallback === 'function' && dataCallback();
      isLoadingCallbackValid && loadingCallback(false);
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
    }
  };
};

export const updatePermitGeometry = (data, successCallback) => {
  return async () => {
    try {
      await permitsApi.updatePermitGeometry(data);
      successCallback && successCallback();
    } catch (e) {
      console.error(e);
    }
  };
};
