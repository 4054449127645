import constants from '../constants/constants';
import { PASSWORD_REGEX } from '../../../common/constants';

export const validate = values => {
  const errors = {};

  if (!values[constants.fields.oldPassword] || !PASSWORD_REGEX.test(values[constants.fields.oldPassword])) {
    errors[constants.fields.oldPassword] = 'CHANGE_PASSWORD.PASSWORD_NOT_VALID';
  }

  if (!values[constants.fields.newPassword] || !PASSWORD_REGEX.test(values[constants.fields.newPassword])) {
    errors[constants.fields.newPassword] = 'CHANGE_PASSWORD.PASSWORD_NOT_VALID';
  }

  if (!values[constants.fields.confirmNewPassword] || !PASSWORD_REGEX.test(values[constants.fields.confirmNewPassword])) {
    errors[constants.fields.confirmNewPassword] = 'CHANGE_PASSWORD.PASSWORD_NOT_VALID';
  }

  if (values[constants.fields.newPassword] !== values[constants.fields.confirmNewPassword]) {
    errors[constants.fields.confirmNewPassword] = 'RESET_PASSWORD.PASSWORD_DID_NOT_MATCH';
  }

  return errors;
};
