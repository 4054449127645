export const tabConstants = {
  graph: {
    tabKey: 'GRAPH',
    title: 'NDT_MODAL.TAB_TITLE_1',
  },
  points: {
    tabKey: 'POINTS',
    title: 'NDT_MODAL.TAB_TITLE_2',
  },
  predictiveSettings: {
    tabKey: 'PREDICTIVE_SETTINGS',
    title: 'NDT_MODAL.TAB_TITLE_3',
  },
};
