import { uniqBy } from 'lodash';

import ReducerHelpers from '../../../common/reducer-helpers';
import actionTypes from '../constants/action-types';
import { formConstants } from '../components/isolations/constants/constants';

const templateState = {
  isolations: [],
  selectedIsolation: null,
  modalData: {
    isOpen: false,
  },
  sections: [],
  permitSections: [],
};

export const templateReducer = (state = templateState, payload) => {
  switch (payload.type) {
    case actionTypes.SET_TEMPLATE_MODAL_DATA:
      return { ...state, modalData: payload.data };
    case actionTypes.SET_ISOLATION_TEMPLATE_LIST:
      return { ...state, isolations: uniqBy(payload.data, formConstants.fields.id) };
    case actionTypes.AMEND_ISOLATION_TEMPLATE_LIST:
      const isolationTemplates = [...state.isolations, ...payload.data];
      return { ...state, isolations: uniqBy(isolationTemplates, formConstants.fields.id) };
    case actionTypes.SET_SELECTED_ISOLATION_TEMPLATE:
      return { ...state, selectedIsolation: payload.data };
    case actionTypes.ADD_ISOLATION_TEMPLATE_AT_INDEX:
      return {
        ...state,
        isolations: ReducerHelpers.addItemToIndex(state.isolations, payload.data.item, payload.data.index),
      };
    case actionTypes.UPDATE_ISOLATION_TEMPLATE_BY_PROP:
      return {
        ...state,
        isolations: ReducerHelpers.updateItemInListByProp(state.isolations, payload.data.item, payload.data.prop),
      };
    case actionTypes.REMOVE_ISOLATION_TEMPLATE_BY_PROP:
      return {
        ...state,
        isolations: ReducerHelpers.removeItemByProp(state.isolations, payload.data.item, payload.data.prop),
      };
    case actionTypes.UPDATE_ISOLATION_SECTIONS:
      return { ...state, sections: payload.data };
    case actionTypes.UPDATE_PERMIT_TEMPLATE_ISOLATION_SECTIONS:
      return { ...state, permitSections: payload.data };
    default:
      return state;
  }
};
