import PropTypes from 'prop-types';
import React from 'react';
import useConfirmOnInspectionExit from '../../../../hooks/use-confirm-on-inspection-exit';

const ConfirmOnInspectionExitWrapper = ({ title, message, isDirty, router, route, clearUnsavedChangesDirty, children }, { t }) => {
  useConfirmOnInspectionExit({
    title: title || t('GENERIC_UNSAVED_CHANGES_TITLE'),
    message: message || t('GENERIC_UNSAVED_CHANGES_MESSAGE'),
    isDirty,
    router,
    route,
    clearUnsavedChangesDirty,
  });

  return <>{children}</>;
};

ConfirmOnInspectionExitWrapper.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ConfirmOnInspectionExitWrapper;
