import { formConstants } from '../constants/constants';
import { EMAIL_REGEX, SPECIAL_CHAR_REGEX, PHONE_REGEX } from '../../../common/constants';

export const validate = values => {
  const errors = {};

  if (!values[formConstants.fields.name]) {
    errors[formConstants.fields.name] = 'CREATE_USER.USER_NAME_REQUIRED';
  }

  if (values[formConstants.fields.name] && SPECIAL_CHAR_REGEX.test(values[formConstants.fields.name])) {
    errors[formConstants.fields.name] = 'CREATE_COMPANY.COMPANY_USER_NAME_SPECIAL_CHAR_NOT_ALLOWED';
  }

  if (!values[formConstants.fields.email]) {
    errors[formConstants.fields.email] = 'CREATE_USER.USER_EMAIL_REQUIRED';
  }

  if (values[formConstants.fields.email] && !EMAIL_REGEX.test(values[formConstants.fields.email])) {
    errors[formConstants.fields.email] = 'CREATE_USER.EMAIL_INVALID';
  }

  if (values[formConstants.fields.phone] && PHONE_REGEX.test(values[formConstants.fields.phone])) {
    errors[formConstants.fields.phone] = 'CREATE_PROJECT.CONTACT_PHONE_INVALID';
  }

  return errors;
};
