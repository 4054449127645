import React from 'react';
import PropTypes from 'prop-types';

import RenderFiles from './render-files';
import RenderIf from '../../../../common/render-if/components/render-if';
import { WOFileGroups } from './constants/constants';

import '../../styles/work-order-files.scss';

const WorkOrderFiles = (
  { workorderFiles, openFilesModalData, openImagesSliderModal, deleteWorkOrderFile, hideRams, otherFilesLabel, disabled, hideDelete, showImages, otherImages, isFilesSectionSelected },
  { t }
) => {
  return (
    <div className="work-order-files">
      <RenderIf if={showImages}>
        <div className="upload-checklist added-items">
          <p className="f-primary title">{t(otherFilesLabel || 'CREATE_WORKORDER_FORM.ADDED_IMAGES')}</p>
          <div className="files-items-list">
            <RenderFiles
              files={workorderFiles && workorderFiles[WOFileGroups.images]}
              uploadGroup={otherImages ? WOFileGroups.otherImages : WOFileGroups.images}
              imageAction={openImagesSliderModal}
              noFileText="CREATE_WORKORDER_FORM.NO_IMAGES"
              openFilesModalData={openFilesModalData}
              deleteWorkOrderFile={deleteWorkOrderFile}
              disabled={disabled}
              hideUpload={!isFilesSectionSelected}
            />
          </div>
        </div>
      </RenderIf>
      <div className="upload-checklist added-items">
        <div className="files-items-list">
          <RenderFiles
            files={workorderFiles && workorderFiles[WOFileGroups.otherFiles]}
            uploadGroup={WOFileGroups.otherFiles}
            imageAction={openImagesSliderModal}
            noFileText="CREATE_WORKORDER_FORM.NO_OTHER_FILES"
            openFilesModalData={openFilesModalData}
            deleteWorkOrderFile={deleteWorkOrderFile}
            disabled={disabled}
            hideDelete={hideDelete || false}
            hideUpload={!isFilesSectionSelected}
          />
        </div>
      </div>
      <RenderIf if={!hideRams}>
        <div className="upload-checklist added-items">
          <p className="f-primary title">{t('CREATE_WORKORDER_FORM.ADDED_RAMS_FILES')}</p>
          <div className="files-items-list">
            <RenderFiles
              files={workorderFiles && workorderFiles[WOFileGroups.rams]}
              uploadGroup={WOFileGroups.rams}
              imageAction={openImagesSliderModal}
              noFileText="CREATE_WORKORDER_FORM.NO_RAM_FILES"
              openFilesModalData={openFilesModalData}
              deleteWorkOrderFile={deleteWorkOrderFile}
              disabled={disabled}
              hideUpload={!isFilesSectionSelected}
            />
          </div>
        </div>
      </RenderIf>
    </div>
  );
};

WorkOrderFiles.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default WorkOrderFiles;
