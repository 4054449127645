import projectActionTypes from '../constants/action-types';

export const loadProjectsSuccess = data => {
  return {
    type: projectActionTypes.LOAD_PROJECTS_SUCCESS,
    data,
  };
};

export const selectProject = data => {
  return {
    type: projectActionTypes.SELECT_PROJECT,
    data,
  };
};

export const toggleProjectInspections = data => {
  return {
    type: projectActionTypes.TOGGLE_PROJECT_INSPECTIONS,
    data,
  };
};

export const setProjectEdit = data => {
  return {
    type: projectActionTypes.SET_PROJECT_EDIT,
    data,
  };
};

export const handleArchiveProjectModal = data => {
  return {
    type: projectActionTypes.HANDLE_ARCHIVE_PROJECT_MODAL,
    data,
  };
};

export const handleDeleteProjectModal = data => {
  return {
    type: projectActionTypes.HANDLE_DELETE_PROJECT_MODAL,
    data,
  };
};

export const setProjectDetails = data => {
  return {
    type: projectActionTypes.SET_PROJECT_DETAILS,
    data,
  };
};

export const resetProjectDetails = () => {
  return {
    type: projectActionTypes.RESET_PROJECT_DETAILS,
  };
};

export const setDMSCategories = data => {
  return {
    data,
    type: projectActionTypes.SET_PROJECT_DMS_CATEGORIES,
  };
};

export const setDMSDataLoading = data => {
  return {
    data,
    type: projectActionTypes.SET_IS_LOADING_PROJECT_DETAILS,
  };
};
