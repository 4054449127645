import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import Autocomplete from 'react-google-autocomplete';
import Geocode from 'react-geocode';

import { GOOGLE_API_KEY } from '../../../common/constants';

import { ReactComponent as DescriptionSvg } from '../assets/map-location.svg';
import { ReactComponent as PinToCenter } from '../assets/pin-to-center.svg';
import { ReactComponent as MarkerSvg } from '../assets/marker.svg';

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: '',
      city: '',
      area: '',
      state: '',
      mapPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng,
      },
      zoom: 8,
      markerPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng,
      },
    };
  }

  /**
   * Get the current address from the default map position and set those values in the state
   */
  componentDidMount() {
    Geocode.fromLatLng(this.state.mapPosition.lat, this.state.mapPosition.lng).then(
      response => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          state = this.getState(addressArray);

        this.setState({
          address: address ? address : '',
          area: area ? area : '',
          city: city ? city : '',
          state: state ? state : '',
        });
      },
      error => {
        console.error(error);
      }
    );
    this.handleAddressChange();
  }
  /**
   * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
   *
   * @param nextProps
   * @param nextState
   * @return {boolean}
   */
  // shouldComponentUpdate(nextProps, nextState) {
  // if (
  //   this.state.markerPosition.lat !== this.props.center.lat ||
  //   this.state.address !== nextState.address ||
  //   this.state.city !== nextState.city ||
  //   this.state.area !== nextState.area ||
  //   this.state.state !== nextState.state ||
  //   this.props.fieldProps !== nextProps.fieldProps
  // ) {
  //   return true;
  // } else if (this.props.center.lat === nextProps.center.lat) {
  //   return false;
  // }
  // }

  /**
   * Get the city and set the city input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getCity = addressArray => {
    let city = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && 'administrative_area_level_2' === addressArray[i].types[0]) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };
  /**
   * Get the area and set the area input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getArea = addressArray => {
    let area = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if ('sublocality_level_1' === addressArray[i].types[j] || 'locality' === addressArray[i].types[j]) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };
  /**
   * Get the address and set the address input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getState = addressArray => {
    let state = '';
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };
  /**
   * And function for city,state and address input
   * @param event
   */
  // onChangeInernal = event => {
  //   this.setState({ [event.target.name]: event.target.value });
  // };
  /**
   * This Event triggers when the marker window is closed
   *
   * @param event
   */
  onInfoWindowClose = event => {};

  onPlaceSelected = place => {
    if (!place || !place.geometry) return;
    const address = place.formatted_address || '',
      addressArray = place.address_components || '',
      city = this.getCity(addressArray),
      area = this.getArea(addressArray),
      state = this.getState(addressArray),
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng();
    // Set these values in the state.
    this.setState(
      {
        address: address ? address : '',
        area: area ? area : '',
        city: city ? city : '',
        draggable: true,
        state: state ? state : '',
        markerPosition: {
          lat: latValue,
          lng: lngValue,
        },
        mapPosition: {
          lat: latValue,
          lng: lngValue,
        },
      },
      () => this.handleAddressChange()
    );
  };

  renderMarkers = (map, maps) => {
    const { markerPosition } = this.state;
    return (
      <div className="marker-container" lat={markerPosition.lat} lng={markerPosition.lng}>
        <MarkerSvg />
      </div>
    );
  };

  handleMouseMovement = (childKey, childProps, mouse) => {
    // function is just a stub to test callbacks
    this.setState(
      {
        draggable: false,
        markerPosition: {
          lat: mouse.lat,
          lng: mouse.lng,
        },
      },
      () => this.handleAddressChange()
    );
  };

  handleMouseUp = (childKey, childProps, mouse) => {
    this.setState({ draggable: true });
    // function is just a stub to test callbacks
  };

  onChange = ({ center, zoom }) => {
    this.setState({
      mapPosition: { lat: center.lat, lng: center.lng },
      zoom,
    });
  };

  handleAddressChange = () => {
    const {
      fieldProps: {
        input: { onChange },
      },
    } = this.props;
    const { address, markerPosition } = this.state;
    if (!onChange || typeof onChange !== 'function') return;
    onChange({
      adressName: address,
      location: {
        lat: markerPosition.lat,
        lng: markerPosition.lng,
      },
    });
  };

  movePinToCenter = () => {
    const { mapPosition } = this.state;

    this.setState({
      markerPosition: {
        lat: mapPosition.lat,
        lng: mapPosition.lng,
      },
    });
  };

  render() {
    const { t } = this.context;
    const { mapPosition, draggable } = this.state;
    const { styles } = this.props;

    return (
      <div className="create-project-container__google-map">
        <Autocomplete
          style={{
            width: '100%',
          }}
          className="address-input"
          onPlaceSelected={this.onPlaceSelected}
          types={[]}
        />
        <div className="map-view">
          <GoogleMapReact
            options={{ styles }}
            ref={ref => (this.mapRef = ref)}
            draggable={draggable}
            bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
            onChange={this.onChange}
            zoom={this.state.zoom}
            center={{ lat: mapPosition.lat, lng: mapPosition.lng }}
            onChildMouseDown={this.handleMouseMovement}
            onChildMouseUp={this.handleMouseUp}
            onChildMouseMove={this.handleMouseMovement}
            onChildClick={() => null}
            onClick={() => null}
            yesIWantToUseGoogleMapApiInternals
          >
            {this.renderMarkers()}
          </GoogleMapReact>
          <div title={t('MARKER.MOVE_TO_CENTER')} onClick={this.movePinToCenter} className="map-view__move-pin">
            <PinToCenter width={20} height={20} className="svg-black-g" />
          </div>
        </div>
        <div className="description">
          <div className="description__wrapper">
            <DescriptionSvg className="svg-white-g" />
            <p className="f-primary description__text">Move pin on the map after typing in the address to get the more precise location</p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    styles: state.themeReducer.map,
  };
};

Map.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, null)(Map);
