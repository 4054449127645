import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Icon from '../../../common/icon/components/icon';
import Modal from '../../../common/modal/components/modal';
import NavigationItems from './navigation-items';

import { toggleNav } from '../actions/navigation-actions';
import { activeClassName, inactiveClassName } from '../constants/navigation-constants';

import { MAIN_LOGO } from '../../../common/constants';
import { logout } from '../../login/actions/login-actions';

import ProfileImage from '../../../common/image-components/components/profile-image';

import Helpers from '../../../common/helpers';

import routesConstants from '../../../common/routes-constants';

import '../styles/navigation.scss';

class Navigation extends Component {
  state = {
    logoutModalData: {
      isOpen: false,
    },
  };

  openLogoutModal = () => {
    const { t } = this.context;

    this.setState(prevState => ({
      logoutModalData: {
        ...prevState.logoutModalData,
        title: t('LOGOUT.TITLE'),
        content: t('LOGOUT.DESC'),
        isOpen: true,
        type: 'yes-no',
        confirmAction: this.logoutUser,
        closeAction: this.closeLogoutModal,
      },
    }));
  };

  openLogoutModalHandler = () => {
    const { isDirty } = this.props;
    if (isDirty) {
      if (window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
        this.openLogoutModal();
      }
    } else this.openLogoutModal();
  };

  logoutUser = () => {
    const { logout } = this.props;
    this.closeLogoutModal();
    logout();
  };

  closeLogoutModal = () => {
    this.setState(prevState => ({
      logoutModalData: {
        ...prevState.logoutModalData,
        isOpen: false,
      },
    }));
  };

  render() {
    const { navActive, toggleNav, user, customSignOutClick, hideToggle } = this.props;
    const { logoutModalData } = this.state;

    return (
      <div className={`navigation navigation--${navActive ? activeClassName : inactiveClassName}`}>
        <div className="navigation-container">
          <div className="logo" onClick={() => Helpers.goTo(routesConstants.defaultRoute)}>
            {user.CompanyLogo ? <ProfileImage image={user.CompanyLogo} squared={false} /> : typeof MAIN_LOGO === 'string' ? <img src={MAIN_LOGO} alt={'logo'} /> : <MAIN_LOGO />}
          </div>

          <NavigationItems logoutProps={{ onClick: customSignOutClick || this.openLogoutModalHandler }} renderEssential={hideToggle} />

          {!hideToggle && (
            <div onClick={toggleNav} className="navigation-container__toggle">
              <Icon name={'toggler'} />
            </div>
          )}
        </div>
        <Modal {...logoutModalData} />
      </div>
    );
  }
}

Navigation.contextTypes = {
  t: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  navActive: state.navReducer.opened,
  user: state.userReducer,
  isDirty: state.unsavedChangesReducer.isDirty,
});

const mapDispatchToProps = dispatch => ({
  toggleNav: () => dispatch(toggleNav()),
  logout: withHardReload => {
    dispatch(logout(withHardReload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
