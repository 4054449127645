import { debounce, find, get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { fields as customPropertiesConstants } from '../../../common/advanced-filter-custom-property/constants/constants';
import BackButton from '../../../common/back-button/components/back-button';
import Helpers from '../../../common/helpers';
import Modal from '../../../common/modal/components/modal';
import { questionOptionsFields } from '../../../common/question-components/constants/question-constants';
import RenderIf from '../../../common/render-if/components/render-if';
import { routes } from '../../../common/routes-constants';
import Stepper from '../../../common/stepper/components/stepper';
import ActionModal from '../../document-management/components/modals/action-modal/action-modal';
import { setSingleUploadItem, setUploadItems, uploadAsset } from '../../upload/actions/upload-actions';
import uploadConstants from '../../upload/constants/constants';
import {
  addCustomProperties,
  addQuestionComponent,
  addQuestionOption,
  addQuestionWorkArea,
  addSection,
  addSectionQuestion,
  createChecklistProcedure,
  deleteCustomProperty,
  deleteQuestionComponent,
  deleteQuestionOption,
  deleteQuestionWorkArea,
  deleteSection,
  deleteSectionQuestion,
  deleteSectionQuestionFile,
  fetchChecklistProcedure,
  fetchChecklistProcedureSectionQuestions,
  fetchChecklistProcedureSectionsV2,
  fetchComponents,
  fetchQuestionTypes,
  getChecklistProceduresProperties,
  getChecklistQuestionDMSFilesUploaded,
  sortQuestions,
  sortSections,
  updateChecklistProcedure,
  updateCustomProperty,
  updateQuestionOption,
  updateQuestionTagLocation,
  updateQuestionWorkArea,
  updateSection,
  updateSectionQuestion,
} from '../actions/checklists-procedures-management-actions';
import { componentSearchParams, defaultPagingObj, fields, filterProps, statuses, stepperData, steps } from '../constants/checklists-procedures-constants';
import { openCreateChecklistsForm } from '../helpers/checklists-procedures-management-helpers';
import ChecklistsProceduresCustomProperties from './checklists-procedures-custom-properties';
import ChecklistsProceduresFirstStep from './checklists-procedures-first-step';
import ChecklistsProceduresSecondStep from './checklists-procedures-second-step';

import { setGenericNotification } from '../../../common/notification/actions/action-creators';
import { PERMISSION_TYPES, PERMISSIONS } from '../../../common/permissions-constants';
import { DMSCategoriesModules } from '../../document-management/constants/constants';
import NewMeasurementPointWrapper from '../../inspections/components/readings-and-gauges/components/new-measurement-point-wrapper/new-measurement-point-wrapper';
import { moduleSectionQuestionFields, moduleSectionsFields } from '../../inspections/constants/constants';
import { fetchDMSCategories } from '../../project/actions/project-actions';
import '../styles/checklists-procedures-management.scss';

class ChecklistsProceduresManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      sectionsChanged: false,
      checklistProcedure: {},
      checklistProcedureProperties: [],
      sections: [],
      questionTypes: [],
      components: [],
      sectionFilters: { ...defaultPagingObj },
      modalData: {
        isOpen: false,
      },
      addQuestionDisabled: false,
      addNewMeasurementPointDisabled: false, // most likely obsolete, since we can use addQuestionDisabled
    };
    // Currently we are only reducing the debounce value, but this is not a permanent fix
    this.sectionFormChangeDebounce = debounce(this.submitSectionForm, 400);
    this.questionFormChangeDebounce = debounce(this.submitQuestionForm, 400);
    this.questionOptionChangeDebounce = debounce(this.submitQuestionOptionForm, 400);
  }

  componentDidMount = () => {
    const { sectionFilters } = this.state;
    const { params, location, getChecklistProceduresProperties, fetchDMSCategories } = this.props;
    const step = params.step;
    const existingStep = find(stepperData, { stepValue: step });
    const { query } = location;
    const inspectionId = parseInt(get(query, 'inspection_id'), 10);
    const projectId = parseInt(get(query, 'project_id'), 10);

    // if in the URL is non existing step, or there are no "inspection_id" and "project_id" in
    // query params redirect user back to the C&P list
    if (!inspectionId || !projectId || !existingStep) {
      this.handleCancelButtonClick();
      return;
    }
    fetchDMSCategories(projectId, DMSCategoriesModules.checklistQuestions);

    if (params?.id) {
      const id = parseInt(params.id, 10);
      const { fetchChecklistProcedure } = this.props;

      fetchChecklistProcedure(id, (isLoading, data) => this.setState({ isLoading, checklistProcedure: data || {} }));

      if (step === steps.secondStep) {
        getChecklistProceduresProperties(id, properties => {
          this.setState({ checklistProcedureProperties: properties });
        });
      }
      if (step === steps.thirdStep) {
        this.getChecklistProcedureSections(sectionFilters);
      }
    }
  };

  componentDidUpdate = prevProps => {
    const { sectionFilters } = this.state;
    const { params, getChecklistProceduresProperties } = this.props;

    if (params?.step === steps.secondStep && prevProps.params?.step !== params?.step) {
      if (params?.id) {
        const id = parseInt(params.id, 10);
        getChecklistProceduresProperties(id, properties => {
          this.setState({ checklistProcedureProperties: properties });
        });

        // if the user navigates back from Sections step to the properties step, reset the sections filters
        this.setState({
          sectionFilters: { ...defaultPagingObj },
        });
      }
    }

    if (params?.step === steps.thirdStep && prevProps.params?.step !== params?.step) {
      this.getChecklistProcedureSections(sectionFilters);
    }

    if (params?.id && prevProps.params?.step !== params?.step) {
      const { fetchChecklistProcedure } = this.props;
      fetchChecklistProcedure(parseInt(params.id, 10), (isLoading, data) => this.setState({ isLoading, checklistProcedure: data || {} }));
    }
  };

  componentWillUnmount() {
    this.sectionFormChangeDebounce.cancel();
    this.questionFormChangeDebounce.cancel();
    this.questionOptionChangeDebounce.cancel();
  }

  getComponents = search => {
    const { location, fetchComponents } = this.props;
    const { query } = location;
    const inspectionId = get(query, 'inspection_id');
    const componentsFetchParams = { ...componentSearchParams, InspectionID: parseInt(inspectionId, 10), SearchText: search || '' };

    fetchComponents(componentsFetchParams, components => this.setState({ components }));
  };

  handleSuccessSectionsManipulations = (requestInProgress, isLoading, sections, isSectionChanged, toggleAddQuestionButton, newFilters) => {
    const { modalData, sectionsChanged, addQuestionDisabled, sectionFilters, addNewMeasurementPointDisabled } = this.state;
    const newState = {
      isLoading,
      requestInProgress,
    };

    if (isSectionChanged) {
      newState.sectionsChanged = !sectionsChanged;
    }

    if (sections) {
      newState.sections = sections;
    }

    if (modalData?.isOpen) {
      newState.modalData = {
        isOpen: false,
      };
    }

    if (newFilters) {
      newState.sectionFilters = { ...sectionFilters, ...newFilters };
    }

    // toggles the "New Question" and "New Measurement Point" button
    if (toggleAddQuestionButton) {
      newState.addQuestionDisabled = !addQuestionDisabled;
      newState.addNewMeasurementPointDisabled = !addNewMeasurementPointDisabled;
    }

    this.setState(newState);
  };

  handleSuccessSectionQuestionManipulations = (requestInProgress, isLoading, sectionId, questions, newFilters) => {
    const { sections } = this.state;

    const newState = {};

    if (sectionId) {
      newState.sections = sections.map(s => {
        if (s[moduleSectionsFields.id] === sectionId) {
          const newQuestionsState = {
            isLoading,
            requestInProgress,
          };

          if (newFilters) {
            newQuestionsState.QuestionFilters = { ...(s.QuestionFilters || {}), ...newFilters };
          }

          if (questions) {
            newQuestionsState.SectionQuestions = questions;
          }

          return { ...s, ...newQuestionsState };
        }
        return s;
      });
    }

    this.setState(newState);
  };

  getChecklistProcedureSections = filters => {
    const { fetchChecklistProcedureSectionsV2, fetchQuestionTypes, params } = this.props;
    const id = parseInt(params.id, 10);
    const { t } = this.context;

    fetchQuestionTypes(t, (isLoading, questionTypes) => this.setState({ isLoading, questionTypes }));
    fetchChecklistProcedureSectionsV2({ ChecklistAndProcedureTemplateID: id, ...filters }, this.handleSuccessSectionsManipulations);
    this.getComponents();
  };

  getChecklistProcedureSectionQuestions = (sectionId, filters) => {
    const { fetchChecklistProcedureSectionQuestions, params } = this.props;
    const id = parseInt(params.id, 10);

    fetchChecklistProcedureSectionQuestions(id, sectionId, { ...filters }, this.handleSuccessSectionQuestionManipulations);
  };

  getBackButtonPath = () => {
    const { location } = this.props;

    return `${routes.protectedRoutes.checklistsProceduresList.fullPath}${location?.search ? location.search : ''}`;
  };

  handleCancelButtonClick = () => {
    const path = this.getBackButtonPath();
    Helpers.goTo(path);
  };

  handleSuccessFirstStep = (isLoading, isSuccess, id) => {
    this.setState({ isLoading });

    if (isSuccess && id) {
      const { params } = this.props;
      const { location } = this.props;
      openCreateChecklistsForm(params?.mode, steps.secondStep, id, location?.search);
    }
  };

  handleFirstStepFormSubmit = values => {
    const { createChecklistProcedure, updateChecklistProcedure, location, params } = this.props;
    const { query } = location;
    const projectId = get(query, 'project_id');

    if (!this.canEdit()) {
      const { location } = this.props;
      openCreateChecklistsForm(params?.mode, steps.secondStep, params?.id, location?.search);
      return;
    }

    if (params?.id) {
      updateChecklistProcedure(Object.assign({ ProjectID: parseInt(projectId, 10) }, values), this.handleSuccessFirstStep);
    } else {
      createChecklistProcedure(Object.assign({ ProjectID: parseInt(projectId, 10) }, values), this.handleSuccessFirstStep);
    }
  };

  submitSectionForm = values => {
    const { sections } = this.state;
    const { updateSection } = this.props;
    updateSection(values, Object.assign([], sections), this.handleSuccessSectionsManipulations);
  };

  submitQuestionForm = (values, field, skipReinitialize) => {
    const { sections } = this.state;
    const { updateSectionQuestion } = this.props;

    // Clone the current sections to revert in case of an error
    const originalSections = JSON.parse(JSON.stringify(sections));

    // Optimistically update the UI
    const sectionIndex = sections.findIndex(section => section.ID === values.SectionID);
    if (sectionIndex > -1) {
      const questionIndex = sections[sectionIndex].SectionQuestions.findIndex(question => question.ID === values.ID);
      if (questionIndex > -1) {
        sections[sectionIndex].SectionQuestions[questionIndex] = { ...sections[sectionIndex].SectionQuestions[questionIndex], ...values };
        this.setState({ sections });
      }
    }

    this.handleSuccessSectionsManipulations(true, false);

    // TODO: refactor this to a simpler flow
    // Proceed with the API call
    updateSectionQuestion(
      values,
      Object.assign([], sections),
      (success, updatedSections, shouldReinitialize, toggleAddQuestionButton) => {
        if (!success) {
          // Revert to original state in case of an error
          this.setState({ sections: originalSections });
          this.handleSuccessSectionsManipulations(false, false);
          // Optionally, show an error message to the user
        } else {
          // If handleSuccessSectionsManipulations does additional state manipulations,
          // call it here with the updatedSections if necessary
          this.handleSuccessSectionsManipulations(false, false, updatedSections, shouldReinitialize, toggleAddQuestionButton);
        }
      },
      field,
      skipReinitialize
    );
  };

  handleUpdateSection = values => {
    // disable adding of new question(s) until API call is done
    // adding of new question(s) is enabled in the handleSuccessSectionsManipulations callback
    this.setState({
      addQuestionDisabled: true,
      addNewMeasurementPointDisabled: true,
    });
    this.sectionFormChangeDebounce(values, true);
  };

  submitQuestionOptionForm = values => {
    const { sections } = this.state;
    const { updateQuestionOption } = this.props;
    updateQuestionOption(values, Object.assign([], sections), this.handleSuccessSectionsManipulations);
  };

  handleAddQuestion = sectionId => {
    const { sections } = this.state;
    const { addSectionQuestion } = this.props;
    addSectionQuestion(sectionId, Object.assign([], sections), this.handleSuccessSectionsManipulations);
  };

  handleAddNewMeasurementPointQuestion = (sectionId, _section, QuestionFilters) => {
    const { t } = this.context;
    const { location, user } = this.props;
    const { query } = location;
    const projectId = get(query, 'project_id');

    const close = () => {
      this.reloadSectionQuestions({ QuestionFilters: QuestionFilters, ID: sectionId });
      this.setState({ modalData: { isOpen: false } });
    };

    const modalData = {
      isOpen: true,
      CustomContent: dynamicProps => (
        <NewMeasurementPointWrapper {...dynamicProps} projectId={parseInt(projectId, 10)} user={user} additionalFetchMLProps={{ [moduleSectionQuestionFields.sectionId]: sectionId }} />
      ),
      title: t('READINGS_AND_GAUGES.MEASUREMENT_POINTS.EMPTY_STATE.ACTION_TEXT'),
      type: 'none',
      customClassName: 'modal-no-max-height modal-large',
      confirmAction: () => null,
      closeAction: close,
      customCloseAction: close,
    };

    this.setState({ modalData });
  };

  handleDeleteSection = (sectionId, section) => {
    const { t } = this.context;
    const { sections, sectionFilters } = this.state;
    const { deleteSection } = this.props;

    const modalData = {
      isOpen: true,
      content: t('SECTIONS.DELETE_SECTION_CONTENT', { section }),
      type: 'yes-no',
      customClassName: 'delete-confirm-modal',
      confirmAction: () =>
        deleteSection(sectionId, Object.assign([], sections), sectionFilters, (requestInProgress, isLoading, sections, isSectionChanged, toggleAddQuestionButton, newFilters) => {
          if (newFilters) {
            let prevLastSeen = Helpers.calculatePreviousLastSeen(newFilters[filterProps.lastSeen], defaultPagingObj[filterProps.perPage]);
            if (prevLastSeen === newFilters[filterProps.totalNumber]) {
              this.loadPreviousSections();
            } else {
              this.reloadSections();
            }
          } else {
            this.handleSuccessSectionsManipulations(requestInProgress, isLoading, sections, isSectionChanged, toggleAddQuestionButton, newFilters);
          }
        }),
      closeAction: () => this.setState({ modalData: { isOpen: false } }),
    };

    this.setState({ modalData });
  };

  handleFieldChange = (values, _questions, _index, field, skipReinitialize) => {
    // disable adding of new question until API call is done
    // adding of new question is enabled in the callback of questionFormChangeDebounce
    this.setState({
      addQuestionDisabled: true,
      addNewMeasurementPointDisabled: true,
    });
    this.questionFormChangeDebounce(values, field, skipReinitialize);
  };

  handleFieldDelete = (sectionQuestionId, index, question) => {
    const { t } = this.context;
    const { sections } = this.state;
    const { deleteSectionQuestion } = this.props;

    const modalData = {
      isOpen: true,
      content: t('SECTIONS.DELETE_QUESTION_CONTENT', { question }),
      customClassName: 'delete-confirm-modal',
      type: 'yes-no',
      confirmAction: () =>
        deleteSectionQuestion(
          sectionQuestionId,
          (requestInProgress, isLoading, sections, newQuestionFilters, section) => {
            if (newQuestionFilters) {
              let prevLastSeen = Helpers.calculatePreviousLastSeen(newQuestionFilters[filterProps.lastSeen], defaultPagingObj[filterProps.perPage]);

              if (prevLastSeen === newQuestionFilters[filterProps.totalNumber]) {
                this.loadPreviousSectionQuestions(section);
              } else {
                this.reloadSectionQuestions(section);
              }
            } else {
              this.handleSuccessSectionsManipulations(requestInProgress, isLoading, sections);
            }
          },
          Object.assign([], sections),
          index
        ),
      closeAction: () => this.setState({ modalData: { isOpen: false } }),
    };

    this.setState({ modalData });
  };

  handleFieldSort = (currentIndex, newIndex, question) => {
    const { sortQuestions } = this.props;
    const { sections } = this.state;

    sortQuestions(question?.SectionID, currentIndex, newIndex, Object.assign([], sections), this.handleSuccessSectionsManipulations);
  };

  handleAddSection = () => {
    const { sections, sectionFilters } = this.state;
    const { params, addSection } = this.props;
    const id = parseInt(params.id, 10);

    addSection(id, Object.assign([], sections), sectionFilters, this.handleSuccessSectionsManipulations);
  };

  goToStep = (step = steps.firstStep) => {
    const { location, params } = this.props;
    openCreateChecklistsForm(params?.mode, step, params?.id, location?.search);
  };

  handleFinishButtonClick = () => {
    Helpers.goTo(this.getBackButtonPath());
  };

  handleComponentSelected = (questionId, components) => {
    const { addQuestionComponent } = this.props;
    const { sections } = this.state;

    addQuestionComponent(questionId, components, sections, this.handleSuccessSectionsManipulations);
  };

  handleDeleteComponent = (questionId, componentId, componentName) => {
    const { deleteQuestionComponent } = this.props;
    const { sections } = this.state;
    const { t } = this.context;

    const modalData = {
      isOpen: true,
      content: t('SECTIONS.DELETE_QUESTION_COMPONENT_CONTENT', { component: componentName }),
      customClassName: 'delete-confirm-modal',
      type: 'yes-no',
      confirmAction: () => deleteQuestionComponent(questionId, componentId, sections, this.handleSuccessSectionsManipulations),
      closeAction: () => this.setState({ modalData: { isOpen: false } }),
    };

    this.setState({ modalData });
  };

  handleWorkAreaAdd = (questionId, workArea) => {
    const { addQuestionWorkArea } = this.props;
    const { sections } = this.state;

    addQuestionWorkArea(questionId, workArea, sections, this.handleSuccessSectionsManipulations);
  };

  handleUpdateTagLocation = (questionId, tag, callback = () => null) => {
    const { updateQuestionTagLocation } = this.props;
    const { sections } = this.state;

    updateQuestionTagLocation(questionId, tag, sections, (...args) => {
      this.handleSuccessSectionsManipulations(...args);
      // if location update req finished / isLoading is false
      if (!args[0]) {
        callback();
      }
    });
  };

  handleDeleteWorkArea = (questionId, workArea) => {
    const { deleteQuestionWorkArea } = this.props;
    const { sections } = this.state;
    const { t } = this.context;

    const modalData = {
      isOpen: true,
      content: t('SECTIONS.DELETE_QUESTION_WORK_AREA_CONTENT', { areaName: workArea.Name }),
      customClassName: 'delete-confirm-modal',
      type: 'yes-no',
      confirmAction: () => deleteQuestionWorkArea(questionId, workArea, sections, this.handleSuccessSectionsManipulations),
      closeAction: () => this.setState({ modalData: { isOpen: false } }),
    };

    this.setState({ modalData });
  };

  handleWorkAreaUpdate = (questionId, workArea) => {
    const { updateQuestionWorkArea } = this.props;
    const { sections } = this.state;

    updateQuestionWorkArea(questionId, workArea, sections, this.handleSuccessSectionsManipulations);
  };

  toggleAdditionalFields = (isExpanded, sectionId, questionIndex) => {
    const { sections, sectionsChanged } = this.state;
    let newSections = Object.assign([], sections);

    if (questionIndex > -1) {
      newSections = newSections.map(section => {
        if (section.ID === sectionId) {
          section.SectionQuestions[questionIndex].areAdditionalFieldsExpanded = isExpanded;
        }

        return section;
      });
    }

    this.setState({ sections: newSections, sectionsChanged: !sectionsChanged });
  };

  handleAddQuestionOption = questionId => {
    const { sections } = this.state;
    const { addQuestionOption } = this.props;

    addQuestionOption(questionId, Object.assign([], sections), this.handleSuccessSectionsManipulations);
  };

  handleUpdateQuestionOption = values => {
    this.questionOptionChangeDebounce(values);
  };

  handleDeleteQuestionOption = (index, option) => {
    const { t } = this.context;
    const { sections } = this.state;
    const { deleteQuestionOption } = this.props;

    const modalData = {
      isOpen: true,
      content: t('SECTIONS.DELETE_QUESTION_OPTION_CONTENT', { name: option ? option[questionOptionsFields.name.name] : '' }),
      customClassName: 'delete-confirm-modal',
      type: 'yes-no',
      confirmAction: () => deleteQuestionOption(index, option, Object.assign([], sections), this.handleSuccessSectionsManipulations),
      closeAction: () => this.setState({ modalData: { isOpen: false } }),
    };

    this.setState({ modalData });
  };

  handleSectionSort = (currentIndex, newIndex) => {
    const { params, sortSections } = this.props;
    const { sections } = this.state;

    if (params?.id) {
      sortSections(parseInt(params?.id, 10), currentIndex, newIndex, Object.assign([], sections), this.handleSuccessSectionsManipulations);
    }
  };

  onDropAsset = (files, questionId, uploadGroup, section) => {
    const { t } = this.context;
    const { uploadAsset, setUploadItems, setSingleUploadItem, location, setGenericNotification } = this.props;
    const { query } = location;
    const projectId = get(query, 'project_id');

    const tmp_files = files.map((file, index) => {
      const tmp_file = {
        type: Helpers.getFileType(file.type),
        mimeType: file.type,
        title: '',
        description: '',
        real_name: Helpers.formatFileName(file.name),
      };
      file.timeStamp = Date.now();
      file.real_name = tmp_file.real_name;
      file.uploadType = uploadConstants.uploadType.checklistProcedureQuestionFiles;

      uploadAsset(file, { SectionQuestionID: questionId, ProjectID: parseInt(projectId, 10), UploadGroup: uploadGroup }, setSingleUploadItem, index, () => {
        setGenericNotification({
          isDisplayed: true,
          type: 'success',
          icon: 'checkmark-outline',
          text: t('DOCUMENT_MANAGEMENT.FILE_UPLOAD_SUCCESS', { plural: '', folderName: '' }),
        });

        this.reloadSectionQuestions(section);
      });
      return { ...tmp_file, ...file };
    });

    setUploadItems(tmp_files);
  };

  handleDeleteSectionFile = (file, questionId) => {
    const { t } = this.context;
    const { sections } = this.state;
    const { deleteSectionQuestionFile } = this.props;

    const modalData = {
      isOpen: true,
      content: t('SECTIONS.DELETE_QUESTION_FILE_CONTENT', { name: file?.FileName ? file.FileName : '' }),
      customClassName: 'delete-confirm-modal',
      type: 'yes-no',
      confirmAction: () => deleteSectionQuestionFile(questionId, file.FileID, Object.assign([], sections), this.handleSuccessSectionsManipulations),
      closeAction: () => this.setState({ modalData: { isOpen: false } }),
    };

    this.setState({ modalData });
  };

  canEdit = () => {
    const { params } = this.props;
    const { checklistProcedure } = this.state;
    const mode = get(params, 'mode');
    return checklistProcedure?.[fields.status] !== statuses.live.value && mode === 'edit';
  };

  handleSubmitCustomProperties = values => {
    const { addCustomProperties, params } = this.props;

    // Add properties with no ID
    const propertiesToAdd = (get(values, fields.properties) || []).filter(property => !property[customPropertiesConstants.id]);
    const id = parseInt(params?.id, 10);

    if (!isEmpty(propertiesToAdd)) {
      addCustomProperties(propertiesToAdd, id, data => {
        this.goToStep(steps.thirdStep);
      });
    } else {
      this.goToStep(steps.thirdStep);
    }
  };

  handleUpdateCustomProperty = (property, successCallback) => {
    const { updateCustomProperty, params } = this.props;

    const id = parseInt(params?.id, 10);
    updateCustomProperty(property, id, successCallback);
  };

  handleDeleteCustomProperty = (property, successCallback) => {
    const { deleteCustomProperty, params } = this.props;
    const { t } = this.context;

    const id = parseInt(params?.id, 10);

    const closeDeleteModal = () => {
      this.setState({ modalData: { isOpen: false } });
    };
    const customConfirmAction = () => {
      deleteCustomProperty(property, id, (...args) => {
        successCallback && successCallback(...args);
        closeDeleteModal();
      });
    };

    this.setState({
      modalData: {
        type: '',
        className: 'modal-no-max-height modal-medium',
        isOpen: true,
        closeAction: closeDeleteModal,
        customCloseAction: closeDeleteModal,
        CustomContent: dynamicProps => <ActionModal {...dynamicProps} />,
        customConfirmAction,
        title: t('CHECKLISTS_PROCEDURES_MANAGEMENT.DELETE_CUSTOM_PROPERTY_TITLE'),
        firstParagraph: 'CHECKLISTS_PROCEDURES_MANAGEMENT.DELETE_CUSTOM_PROPERTY_CONTENT',
        firstParagraphProps: { name: property[customPropertiesConstants.name] },
        confirmButtonText: 'CHECKLISTS_PROCEDURES_MANAGEMENT.DELETE_CUSTOM_PROPERTY_BUTTON',
      },
    });
  };

  fetchQuestionFiles = questionId => {
    const { getChecklistQuestionDMSFilesUploaded } = this.props;
    getChecklistQuestionDMSFilesUploaded(questionId);
  };

  loadPreviousSections = () => {
    const { sectionFilters } = this.state;
    let prevLastSeen = Helpers.calculatePreviousLastSeen(sectionFilters[filterProps.lastSeen], defaultPagingObj[filterProps.perPage]);

    const newFilters = {
      ...sectionFilters,
      [filterProps.lastSeen]: prevLastSeen - defaultPagingObj[filterProps.perPage],
    };

    this.getChecklistProcedureSections(newFilters);
  };

  reloadSections = () => {
    const { sectionFilters } = this.state;
    let prevLastSeen = Helpers.calculatePreviousLastSeen(sectionFilters[filterProps.lastSeen], defaultPagingObj[filterProps.perPage]);

    const newFilters = {
      ...sectionFilters,
      [filterProps.lastSeen]: prevLastSeen,
    };

    this.getChecklistProcedureSections(newFilters);
  };

  loadNextSections = () => {
    const { sectionFilters } = this.state;
    this.getChecklistProcedureSections(sectionFilters);
  };

  onToggleSectionExpand = (section, isExpanded) => {
    if (isExpanded) {
      this.getChecklistProcedureSectionQuestions(section[moduleSectionsFields.id], defaultPagingObj);
    }
  };

  loadPreviousSectionQuestions = section => {
    let prevLastSeen = Helpers.calculatePreviousLastSeen(section.QuestionFilters?.[filterProps.lastSeen], defaultPagingObj[filterProps.perPage]);

    const newFilters = {
      ...(section.QuestionFilters || {}),
      [filterProps.lastSeen]: prevLastSeen - defaultPagingObj[filterProps.perPage],
    };

    this.getChecklistProcedureSectionQuestions(section[moduleSectionsFields.id], newFilters);
  };

  reloadSectionQuestions = section => {
    let prevLastSeen = Helpers.calculatePreviousLastSeen(section.QuestionFilters?.[filterProps.lastSeen], defaultPagingObj[filterProps.perPage]);

    const newFilters = {
      ...(section.QuestionFilters || {}),
      [filterProps.lastSeen]: prevLastSeen,
    };

    this.getChecklistProcedureSectionQuestions(section[moduleSectionsFields.id], newFilters);
  };

  loadNextSectionQuestions = section => {
    this.getChecklistProcedureSectionQuestions(section[moduleSectionsFields.id], section.QuestionFilters);
  };

  render() {
    const { params, location, user } = this.props;
    const { t } = this.context;
    const {
      isLoading,
      checklistProcedure,
      sections,
      questionTypes,
      modalData,
      components,
      sectionsChanged,
      checklistProcedureProperties,
      addQuestionDisabled,
      addNewMeasurementPointDisabled,
      sectionFilters,
    } = this.state;
    const step = params.step;
    const id = params?.id;
    const { query } = location;
    const inspectionId = parseInt(get(query, 'inspection_id'), 10);

    const userCanLinkMeasurementPoint = !Helpers.hasAccess({
      user,
      visibleFor: [PERMISSIONS[PERMISSION_TYPES.checklists].measurementPointLink.name],
    });

    return (
      <div className="checklists-procedures-management">
        <div className="checklists-procedures-management__container">
          <BackButton path={this.getBackButtonPath()} textClass="f-secondary-green link" />
          <h3 className="f-primary checklists-procedures-management__container__title">{t(id ? 'CHECKLISTS_PROCEDURES_MANAGEMENT.EDIT_TITLE' : 'CHECKLISTS_PROCEDURES_MANAGEMENT.CREATE_TITLE')}</h3>
          <div className="checklists-procedures-management__container__stepper-wrapper">
            <Stepper stepperData={stepperData} activeStep={step} showInfoIcon={false} />
          </div>
          <RenderIf if={step === steps.firstStep}>
            <ChecklistsProceduresFirstStep
              initialValues={checklistProcedure}
              handleFormSubmit={this.handleFirstStepFormSubmit}
              isEdit={id ? true : false}
              handleCancelButtonClick={this.handleCancelButtonClick}
              isLoading={isLoading}
              isDisabled={!this.canEdit()}
            />
          </RenderIf>
          <RenderIf if={step === steps.secondStep}>
            <ChecklistsProceduresCustomProperties
              isLoading={isLoading}
              isDisabled={!this.canEdit()}
              handleBackButtonClick={() => this.goToStep(steps.firstStep)}
              submitForm={this.handleSubmitCustomProperties}
              updateCustomProperty={this.handleUpdateCustomProperty}
              deleteCustomProperty={this.handleDeleteCustomProperty}
              initialValues={{ Properties: checklistProcedureProperties }}
            />
          </RenderIf>
          <RenderIf if={step === steps.thirdStep}>
            <ChecklistsProceduresSecondStep
              isSectionsLoading={isLoading}
              isDisabled={!this.canEdit()}
              addQuestionDisabled={addQuestionDisabled}
              sections={sections}
              questionTypes={questionTypes}
              handleUpdateSection={this.handleUpdateSection}
              handleAddQuestion={this.handleAddQuestion}
              handleFieldChange={this.handleFieldChange}
              handleFieldDelete={this.handleFieldDelete}
              handleFieldSort={this.handleFieldSort}
              handleAddSection={this.handleAddSection}
              handleDeleteSection={this.handleDeleteSection}
              handleBackButtonClick={() => this.goToStep(steps.secondStep)}
              handleFinishButtonClick={this.handleFinishButtonClick}
              handleComponentSelected={this.handleComponentSelected}
              handleWorkAreaAdd={this.handleWorkAreaAdd}
              handleUpdateTagLocation={this.handleUpdateTagLocation}
              handleWorkAreaUpdate={this.handleWorkAreaUpdate}
              components={components}
              sectionsChanged={sectionsChanged}
              handleDeleteComponent={this.handleDeleteComponent}
              handleDeleteWorkArea={this.handleDeleteWorkArea}
              toggleAdditionalFields={this.toggleAdditionalFields}
              handleAddQuestionOption={this.handleAddQuestionOption}
              handleUpdateQuestionOption={this.handleUpdateQuestionOption}
              handleDeleteQuestionOption={this.handleDeleteQuestionOption}
              handleSectionSort={this.handleSectionSort}
              inspectionId={inspectionId}
              onDropAsset={this.onDropAsset}
              handleDeleteSectionFile={this.handleDeleteSectionFile}
              sectionUpdateDebounce={this.sectionFormChangeDebounce}
              questionUpdateDebounce={this.questionFormChangeDebounce}
              questionOptionUpdateDebounce={this.questionOptionChangeDebounce}
              fetchQuestionFiles={this.fetchQuestionFiles}
              hideNewMeasurementPointQuestionButton={userCanLinkMeasurementPoint}
              addNewMeasurementPointDisabled={addNewMeasurementPointDisabled}
              handleAddNewMeasurementPointQuestion={this.handleAddNewMeasurementPointQuestion}
              loadPreviousSections={this.loadPreviousSections}
              loadNextSections={this.loadNextSections}
              sectionFilters={sectionFilters}
              onToggleSectionExpand={this.onToggleSectionExpand}
              loadPreviousQuestions={this.loadPreviousSectionQuestions}
              loadNextQuestions={this.loadNextSectionQuestions}
            />
          </RenderIf>
        </div>
        <Modal {...modalData} modalDisabled={isLoading} confirmButtonDisabled={isLoading} />
      </div>
    );
  }
}
ChecklistsProceduresManagement.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    user: state.userReducer,
  };
};

const mapDispatchToProps = dispatch => ({
  createChecklistProcedure: (values, callback) => dispatch(createChecklistProcedure(values, callback)),
  fetchChecklistProcedure: (id, callback) => dispatch(fetchChecklistProcedure(id, callback)),
  updateChecklistProcedure: (values, callback) => dispatch(updateChecklistProcedure(values, callback)),
  updateSection: (values, sections, callback) => dispatch(updateSection(values, sections, callback)),
  fetchChecklistProcedureSectionsV2: (data, callback, questionId, areAdditionalFieldsExpanded) => dispatch(fetchChecklistProcedureSectionsV2(data, callback, questionId, areAdditionalFieldsExpanded)),
  fetchChecklistProcedureSectionQuestions: (ChecklistAndProcedureTemplateID, SectionID, filters, callback) =>
    dispatch(fetchChecklistProcedureSectionQuestions(ChecklistAndProcedureTemplateID, SectionID, filters, callback)),
  addSectionQuestion: (sectionId, sections, callback) => dispatch(addSectionQuestion(sectionId, sections, callback)),
  addSection: (checklistProcedureId, sections, filters, callback) => dispatch(addSection(checklistProcedureId, sections, filters, callback)),
  deleteSection: (sectionId, sections, filters, callback) => dispatch(deleteSection(sectionId, sections, filters, callback)),
  updateSectionQuestion: (question, sections, callback, field, skipReinitialize) => dispatch(updateSectionQuestion(question, sections, callback, field, skipReinitialize)),
  deleteSectionQuestion: (sectionQuestionId, callback, sections, index) => dispatch(deleteSectionQuestion(sectionQuestionId, callback, sections, index)),
  fetchQuestionTypes: (t, callback) => dispatch(fetchQuestionTypes(t, callback)),
  fetchComponents: (data, callback) => dispatch(fetchComponents(data, callback)),
  addQuestionComponent: (questionId, components, sections, callback) => dispatch(addQuestionComponent(questionId, components, sections, callback)),
  deleteQuestionComponent: (questionId, componentId, sections, callback) => dispatch(deleteQuestionComponent(questionId, componentId, sections, callback)),
  addQuestionWorkArea: (questionId, workArea, sections, callback) => dispatch(addQuestionWorkArea(questionId, workArea, sections, callback)),
  deleteQuestionWorkArea: (questionId, workArea, sections, callback) => dispatch(deleteQuestionWorkArea(questionId, workArea, sections, callback)),
  updateQuestionWorkArea: (questionId, workArea, sections, callback) => dispatch(updateQuestionWorkArea(questionId, workArea, sections, callback)),
  addQuestionOption: (questionId, sections, callback) => dispatch(addQuestionOption(questionId, sections, callback)),
  updateQuestionOption: (values, sections, callback) => dispatch(updateQuestionOption(values, sections, callback)),
  deleteQuestionOption: (index, option, sections, callback) => dispatch(deleteQuestionOption(index, option, sections, callback)),
  sortSections: (templateId, currentIndex, newIndex, sections, callback) => dispatch(sortSections(templateId, currentIndex, newIndex, sections, callback)),
  sortQuestions: (sectionId, currentIndex, newIndex, sections, callback) => dispatch(sortQuestions(sectionId, currentIndex, newIndex, sections, callback)),
  uploadAsset: (file, IDs, callbackFunction, index, customDoneFunc) => dispatch(uploadAsset(file, IDs, callbackFunction, index, customDoneFunc)),
  setSingleUploadItem: (progress, index) => dispatch(setSingleUploadItem(progress, index)),
  setUploadItems: assets => dispatch(setUploadItems(assets)),
  deleteSectionQuestionFile: (questionId, fileId, sections, callback) => dispatch(deleteSectionQuestionFile(questionId, fileId, sections, callback)),
  getChecklistProceduresProperties: (id, callback) => dispatch(getChecklistProceduresProperties(id, callback)),
  addCustomProperties: (properties, templateId, callback) => dispatch(addCustomProperties(properties, templateId, callback)),
  updateCustomProperty: (property, templateId, callback) => dispatch(updateCustomProperty(property, templateId, callback)),
  deleteCustomProperty: (property, templateId, callback) => dispatch(deleteCustomProperty(property, templateId, callback)),
  updateQuestionTagLocation: (questionId, location, sections, callback) => dispatch(updateQuestionTagLocation(questionId, location, sections, callback)),
  fetchDMSCategories: (projectId, moduleName, callback) => dispatch(fetchDMSCategories(projectId, moduleName, callback)),
  getChecklistQuestionDMSFilesUploaded: id => dispatch(getChecklistQuestionDMSFilesUploaded(id)),
  setGenericNotification: data => dispatch(setGenericNotification(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistsProceduresManagement);
