import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { FORMS } from '../../../../../common/constants';
import ExpandableSection from '../../../../../common/expandable-section/components/expandable-section';
import CustomDatePicker from '../../../../../common/form/components/date-picker';
import Textarea from '../../../../../common/form/components/text-area';
import TimePicker from '../../../../../common/form/components/time-picker';
import UneditableInfo from '../../../../../common/form/components/uneditable-info';
import Icon from '../../../../../common/icon/components/icon';
import { modules } from '../../../constants/constants';
import '../../../styles/work-order-completion-form.scss';
import { formFields } from '../constants/work-order-completion-constants';
import { validateUpdate } from '../validators/work-order-completed-form-validator';
import WorkOrderSectionFiles from '../work-order-section-files';

const WorkOrderCompletionForm = ({ disabled, openEditFilesModal, openFile, unlinkCompletionFile, workOrderCompletionFiles, projectDMSCategories, workOrderDetails }, { t }) => {
  return (
    <form noValidate className="work-order-completion-form">
      <div className="work-order-textarea">
        <Field
          name={formFields.workCompleted.name}
          label={t(formFields.workCompleted.label)}
          id={formFields.workCompleted.id}
          component={Textarea}
          maxChars={formFields.workCompleted.maxChars}
          disabled={disabled}
          validateUsingReduxForm
        />
      </div>
      <div className="work-order-textarea">
        <Field
          name={formFields.recommendations.name}
          label={t(formFields.recommendations.label)}
          component={Textarea}
          maxChars={formFields.recommendations.maxChars}
          disabled={disabled}
          validateUsingReduxForm
        />
      </div>
      <div className="work-order-custom-date-picker">
        <Field
          disabledKeyboardNavigation
          name={formFields.actualEndTime.name}
          label={t(formFields.actualEndTime.label)}
          component={CustomDatePicker}
          size="lg"
          disabled={disabled}
          dateFormat={formFields.actualEndTime.dateFormat}
          timeFormat={formFields.actualEndTime.timeFormat}
          showTimeSelect
          validateUsingReduxForm
        />
      </div>
      <Field
        id={1}
        name={formFields.actualWrenchTime.name}
        component={TimePicker}
        label={t(formFields.actualWrenchTime.label)}
        size="lg"
        showPlaceholderWhileValue
        onlyDisabled={disabled}
        validateUsingReduxForm
      />
      <div className="work-order-uneditable-info">
        <Field name={formFields.signedAt.name} component={UneditableInfo} label={t(formFields.signedAt.label)} size="lg" showPlaceholderWhileValue />
      </div>
      <div className="work-order-uneditable-info">
        <Field name={formFields.signedBy.name} component={UneditableInfo} label={t(formFields.signedBy.label)} size="lg" showPlaceholderWhileValue />
      </div>
      <ExpandableSection
        CustomComponent={({ isExpanded }) => (
          <div className="completion-component-header">
            <h5 className="f-primary section-name">{t('WORK_ORDER.UPLOAD_FILES_LABEL')}</h5>
            <div className="component-actions">
              <Icon
                onClick={e => {
                  e.stopPropagation();
                  openEditFilesModal(modules.workorderCompletion);
                }}
                name="pen"
                className="component-header-toggle"
                size="md"
              />
              <Icon name={isExpanded ? 'chevron-up' : 'chevron-down'} className="component-header-toggle" size="md" />
            </div>
          </div>
        )}
        className="expanded-section"
        hideExpandAction
        initiallyExpanded
      >
        <WorkOrderSectionFiles
          module={{
            projectDMSCategories: projectDMSCategories,
            workorderFiles: workOrderCompletionFiles,
            openFile: openFile,
            unlinkCompletionFile: unlinkCompletionFile,
            ...workOrderDetails,
          }}
          handleCustomAction={() => openEditFilesModal(modules.workorderCompletion)}
        />
      </ExpandableSection>
    </form>
  );
};

WorkOrderCompletionForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

WorkOrderCompletionForm.propTypes = {
  disabled: PropTypes.bool.isRequired,
  openEditFilesModal: PropTypes.func.isRequired,
  openFile: PropTypes.func.isRequired,
  unlinkCompletionFile: PropTypes.func.isRequired,
  workOrderCompletionFiles: PropTypes.array.isRequired,
  projectDMSCategories: PropTypes.array.isRequired,
  workOrderDetails: PropTypes.object.isRequired,
};

export default reduxForm({
  form: FORMS.completionTab,
  validate: validateUpdate,
  enableReinitialize: true,
  touchOnChange: true,
  destroyOnUnmount: true,
})(WorkOrderCompletionForm);
