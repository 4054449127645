import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { isEmpty, isObject } from 'lodash';

import FieldGenerator from '../../../app/inspections/components/common/input/field-generator';
import AutoComplete from '../../../app/inspections/components/common/input/label-autocomplete';
import Icon from '../../icon/components/icon';

import Helpers from '../../helpers';
import { customPropertyFieldTypes, formConstants } from '../constants/property-constants';
import CustomSelect from '../../form/components/select';

const Property = (props, { t }) => {
  const { deleteProperty, getNameSuggestions, clearSuggestions, suggestions, fields, handleChange, properties, isDisabled, isEnhancedDesignCustomProp, showPropActions } = props;

  const handleBlurOnNameField = index => {
    if (properties?.[index] && isEmpty(properties[index]?.Name)) {
      handleChange({ newValue: t('PROPERTY.VALUE_LABEL'), changedField: formConstants.fields.name, property: properties[index] });
    }
  };

  const getIsPropSelected = (propValue, item) => {
    if (isObject(propValue)) {
      // if the value is object we check if the ID is 0, if not we determine based on id, if now we determine on value
      if (propValue[formConstants.fields.id] > 0) return propValue[formConstants.fields.id] === item[formConstants.fields.id];
      return propValue[formConstants.fields.value] === item[formConstants.fields.value];
    }
    // if it isn't project we determine based on value
    return propValue === item[formConstants.fields.value];
  };

  return !isEmpty(fields)
    ? fields.map((field, index) => {
        let fieldProps = {};
        if (properties[index].FieldType !== customPropertyFieldTypes.select) {
          fieldProps = {
            inputProps: { onChange: value => handleChange({ newValue: value, changedField: formConstants.fields.value, property: properties[index] }) },
            component: FieldGenerator,
            labelFieldProps: {
              id: `${field}.${formConstants.fields.name}`,
              name: `${field}.${formConstants.fields.name}`,
              isNew: properties?.[index]?.isNew,
              component: AutoComplete,
              suggestions: Helpers.convertSuggestionArray(suggestions),
              fetchSuggestions: getNameSuggestions,
              clearSuggestions: clearSuggestions,
              placeholder: t('DEFECT_PROPERTY.LABEL_PLACEHOLDER'),
              hideBorder: true,
              inputProps: {
                maxLength: 50,
                onChange: value => handleChange({ newValue: value, changedField: formConstants.fields.name, property: properties[index] }),
                handleOnBlur: () => handleBlurOnNameField(index),
              },
              type: 'text',
              isEnhancedDesignCustomProp: isEnhancedDesignCustomProp,
            },
            showActions: showPropActions,
            labelActions: showPropActions
              ? [
                  {
                    IconComponent: props => (isDisabled ? null : <Icon name={isEnhancedDesignCustomProp ? 'trash' : 'delete'} size={isEnhancedDesignCustomProp ? 'xs' : 'xxs'} {...props} />),
                    onClick: () => {
                      if (!isDisabled) {
                        deleteProperty(properties[index], () => fields.remove(index));
                      }
                    },
                    type: 'danger',
                  },
                ]
              : [],
            placeholder: t('PROPERTY.VALUE_PLACEHOLDER'),
            label: properties?.[index]?.Name && properties?.[index]?.Name !== '' ? properties?.[index]?.Name : t('PROPERTY.VALUE_LABEL'),
            labelClass: 'f-secondary-dark defect-form__label light-bold',
            type: 'text',
            isEnhancedDesignCustomProp: isEnhancedDesignCustomProp,
            hideBorder: isEnhancedDesignCustomProp,
          };
        } else {
          fieldProps = {
            onChange: value => {
              handleChange({ newValue: value.Value, changedField: formConstants.fields.value, property: properties[index] });
            },
            component: CustomSelect,
            placeholder: t('PROPERTY.VALUE_PLACEHOLDER'),
            label: properties?.[index]?.Name && properties?.[index]?.Name !== '' ? properties?.[index]?.Name : t('PROPERTY.VALUE_LABEL'),
            labelClass: 'f-primary defect-form__label light-bold mb-4',
            type: 'text',
            isEnhancedDesignCustomProp: isEnhancedDesignCustomProp,
            hideBorder: isEnhancedDesignCustomProp,
            data: properties[index][formConstants.fields.fieldValues],
            itemComponent: ({ item }) => {
              const selected = getIsPropSelected(properties[index][formConstants.fields.value], item);
              return (
                <p>
                  <span className="f-primary">{item.Value}</span>
                  <span className={`${selected ? 'f-white' : 'f-secondary-dark'}`}> - {item.Description}</span>
                </p>
              );
            },
            valueComponent: ({ item }) => {
              // when item is picked we get item to extract value from it, on initial render we only get value is item
              return <div>{item[formConstants.fields.value] || item}</div>;
            },
            valueField: formConstants.fields.value,
            size: 'lg',
          };
        }
        return (
          <Field id={`${field}.${formConstants.fields.value}`} disabled={isDisabled} name={`${field}.${formConstants.fields.value}`} key={`${field}.${formConstants.fields.value}`} {...fieldProps} />
        );
      })
    : null;
};

Property.contextTypes = {
  t: PropTypes.func.isRequired,
};

Property.defaultProps = {
  showActions: true,
};

export default Property;
