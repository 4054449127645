import React from 'react';
import PropTypes from 'prop-types';

import '../../../styles/visibility-box.scss';
import RenderIf from '../../../../../common/render-if/components/render-if';
import Icon from '../../../../../common/icon/components/icon';

const VisibilityBox = (props, context) => {
  const { t } = context;
  const { selectAllHandler, deselectAllHandler, showIcon, allVisible } = props;

  const onIconClick = allVisible ? deselectAllHandler : selectAllHandler;

  return (
    <div className="visibility-box">
      <h6 className="f-primary">{t('INSPECTION_ALL')}</h6>
      <div className="controls">
        <RenderIf if={showIcon}>
          <Icon name={allVisible ? 'eye' : 'not-visible'} size="md" onClick={onIconClick} />
        </RenderIf>
        <RenderIf if={!showIcon}>
          <p
            onClick={() => {
              selectAllHandler();
            }}
            className="f-secondary-green noselect pointer link"
          >
            {t('INSPECTION_SHOW_ALL')}
          </p>
          <p className="f-primary separator">|</p>
          <p
            onClick={() => {
              deselectAllHandler();
            }}
            className="f-secondary-green noselect pointer link"
          >
            {t('INSPECTION_HIDE_ALL')}
          </p>
        </RenderIf>
      </div>
    </div>
  );
};
VisibilityBox.contextTypes = {
  t: PropTypes.func.isRequired,
};
VisibilityBox.defaultProps = {
  selectAllHandler: () => null,
  deselectAllHandler: () => null,
  showIcon: false,
};

export default VisibilityBox;
