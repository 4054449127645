import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
// import Highcharts from 'highcharts/highstock';
import { isEmpty } from 'lodash';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { alarmColorPalette, formConstants as alarmConstants } from '../../../app/inspections/components/readings-and-gauges/constants/alarm-constants';
import { formConstants as mpFormConstants } from '../../../app/inspections/components/readings-and-gauges/constants/measurement-point-constants';
import { aggregationFields } from '../../../app/inspections/components/readings-and-gauges/constants/time-series-graph-constants';
import { filterParams } from '../../../app/inspections/components/readings-and-gauges/constants/time-series-graph-modal-constants';
import { THEMES } from '../../constants';
import Loader from '../../global-loader/components/simple-loader';
import '../styles/time-series-graph.scss';
import CustomLegend from './custom-legend';

highchartsMore(Highcharts);

let TimeSeriesGraph = ({ colors, unit = '-', data = [], isLoading = false, showLegend = true, showAlarms = false, forwardRef, visibleAggregations = [] }, { t }) => {
  const [chartInstance, setChartInstance] = useState(null);
  const [extremes, setExtremes] = useState({ min: null, max: null });
  const [selectedMeasurementPoint, setSelectedMeasurementPoint] = useState(null);
  const [plotLines, setPlotLines] = useState([]);

  const mappedChartData = useMemo(() => {
    const mergedSeries = [];
    const availableColors = [
      colors.colorGreen,
      colors.colorYellow,
      colors.colorOrange,
      colors.colorRed,
      colors.colorPink,
      colors.colorPurple,
      colors.colorBlue,
      colors.colorGreenDark,
      colors.colorYellowDark,
      colors.colorOrangeDark,
      colors.colorRedDark,
      colors.colorPinkDark,
      colors.colorPurpleDark,
      colors.colorBlueDark,
      colors.colorGreenLight,
      colors.colorYellowLight,
      colors.colorOrangeLight,
      colors.colorRedLight,
      colors.colorPinkLight,
      colors.colorPurpleLight,
      colors.colorBlueLight,
    ];
    const lineOptions = {
      type: 'line',
    };
    const areaRangeOptions = {
      type: 'arearange',
    };

    const getAlarmColor = alarm => {
      const color = alarmColorPalette.hasOwnProperty(alarm[alarmConstants.fields.colour.name]) ? alarmColorPalette[alarm[alarmConstants.fields.colour.name]] : null;
      const isObject = typeof color === 'object';

      return isObject ? color.value : color;
    };

    const constructSeriesObjectByVisibleAggregations = (seriesItem, parent, parentIndex) => {
      const staticProps = {
        measurementPointName: parent?.[filterParams.measurementPointName] || `Measurement Point Name ${parentIndex}`,
        measurementPointID: parent?.[filterParams.measurementPointID],
        alarms: (parent?.[mpFormConstants.fields.alarms] || []).map(alarm => ({
          name: alarm[alarmConstants.fields.name.name],
          value: alarm[alarmConstants.fields.alarmLevel.name],
          color: getAlarmColor(alarm),
        })),
        scaleFactor: parent?.[mpFormConstants.fields.scaleFactor] && parent?.[mpFormConstants.fields.scaleFactor] !== 0 ? parent?.[mpFormConstants.fields.scaleFactor] : 1,
        inverted: parent?.[mpFormConstants.fields.inverted],
        dashStyle: seriesItem[mpFormConstants.fields.name] === 'Median' || seriesItem[mpFormConstants.fields.name] === 'Min/Max' ? 'Dash' : 'Solid',
        color: availableColors[parentIndex] || colors.primaryFontColor,
      };
      let dynamicProps = {
        data: seriesItem.Data,
        name: `${seriesItem[mpFormConstants.fields.name]}`,
      };

      if (seriesItem[mpFormConstants.fields.name] === 'Min/Max') {
        // Min/Max clustered
        if (visibleAggregations.some(aggregation => aggregation[aggregationFields.key] === 'MAXIMUM') && visibleAggregations.some(aggregation => aggregation[aggregationFields.key] === 'MINIMUM')) {
          // show min and max on the chart in form of area range
          dynamicProps = { ...dynamicProps, ...areaRangeOptions };
        } else {
          dynamicProps = { ...dynamicProps, ...lineOptions };
          if (visibleAggregations.some(aggregation => aggregation[aggregationFields.key] === 'MINIMUM')) {
            dynamicProps = { ...dynamicProps, data: (seriesItem.Data || []).map(values => [values[0], values[1]]), name: 'Min' };
          } else if (visibleAggregations.some(aggregation => aggregation[aggregationFields.key] === 'MAXIMUM')) {
            dynamicProps = { ...dynamicProps, data: (seriesItem.Data || []).map(values => [values[0], values[2]]), name: 'Max' };
          } else {
            // Series should not be visible on graph, aggregation is not visible
            return null;
          }
        }
      } else {
        if (visibleAggregations.some(aggregation => aggregation[aggregationFields.value] === seriesItem.Name)) {
          dynamicProps = { ...dynamicProps, ...lineOptions };
        } else {
          // Series should not be visible on graph, aggregation is not visible
          return null;
        }
      }

      return { ...staticProps, ...dynamicProps };
    };

    data.forEach((parent, i) => {
      parent.Series?.forEach(seriesItem => {
        if (!isEmpty(seriesItem.Data)) {
          const seriesObject = constructSeriesObjectByVisibleAggregations(seriesItem, parent, i);
          if (seriesObject) {
            mergedSeries.push(seriesObject);
          }
        }
      });
    });

    return mergedSeries;
  }, [data, visibleAggregations, colors]);

  const calculateNewExtremes = useCallback(
    ({ min: currentMin, max: currentMax }, plotlines) => {
      if (showAlarms) {
        const alarmValues = plotlines.map(p => p.value);
        const nextMin = Math.min(...alarmValues, currentMin);
        const nextMax = Math.max(...alarmValues, currentMax);

        if (currentMin !== nextMin || currentMax !== nextMax) {
          setExtremes({ min: nextMin, max: nextMax });
        }
      }
    },
    [showAlarms]
  );

  if (isLoading) {
    return <Loader height="100%" isLoading={true} divStyle={{ margin: 'auto' }} />;
  }

  const handleSeriesMouseOver = debounce(function (event) {
    const chart = this.chart;
    if (!chart) {
      return;
    }

    const measurementPointName = this.userOptions?.measurementPointName;

    // Clear previously highlighted points
    chart.series?.forEach(s => {
      s.setState('normal');
    });

    // Highlight points and series based on proximity to mouse
    chart.series?.forEach(s => {
      if (s.userOptions?.measurementPointName === measurementPointName) {
        s.setState('normal');
      } else {
        s.setState('inactive');
      }
    });
  }, 100);

  const handleSeriesMouseOut = debounce(function () {
    const chart = this.chart;
    if (!chart) {
      return;
    }

    chart.series?.forEach(s => {
      s.setState('normal');
    });
  }, 100);

  const handleSeriesClick = debounce(function (event) {
    if (!showAlarms) {
      return;
    }
    const clickedMeasurementPointName = this.userOptions?.measurementPointName;

    if (selectedMeasurementPoint && selectedMeasurementPoint.measurementPointName === clickedMeasurementPointName) {
      // Deselect if the same measurement point is clicked again
      setSelectedMeasurementPoint(null);
      setPlotLines([]);
      // reset the extremes
      setExtremes({ min: null, max: null });
    } else {
      setSelectedMeasurementPoint(this.userOptions);

      const alarmsData = this.userOptions?.alarms || [];
      const newPlotLines = alarmsData.map(alarm => ({
        color: alarm.color,
        dashStyle: 'Dash',
        value: alarm.value,
        width: 2,
        label: {
          text: alarm.name,
        },
      }));

      calculateNewExtremes({ min: this.yAxis.min, max: this.yAxis.max }, newPlotLines);
      setPlotLines(newPlotLines);
    }
  }, 100);

  const options = {
    legend: {
      itemStyle: {
        color: colors.primaryFontColor,
      },
      itemHiddenStyle: { color: '#cccccc' },
      itemHoverStyle: { color: colors.secondaryFontColorLight },
    },
    title: {
      text: '',
    },
    plotOptions: {
      series: {
        showInLegend: false,
        marker: {
          enabled: false,
          //   radius: 4,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
        events: {
          mouseOver: handleSeriesMouseOver,
          mouseOut: handleSeriesMouseOut,
          click: handleSeriesClick,
        },
      },
      line: {
        zIndex: 1,
        findNearestPointBy: 'x',
      },
      arearange: {
        lineWidth: 0,
        fillOpacity: 0.4,
        findNearestPointBy: 'x',
      },
    },
    series: mappedChartData,
    tooltip: {
      crosshairs: true,
      useHTML: true,
      formatter: function () {
        if (!this) {
          return;
        }
        const hoveredX = this.x;
        const measurementPointName = this.series?.userOptions?.measurementPointName;
        if (isEmpty(measurementPointName)) {
          return;
        }

        let tooltipHtml = `<div class="time-series-graph__tooltip-container"><h6 class="f-primary time-series-graph__tooltip-container__title"><span style="color: ${this.series.color}">&#9632;</span>${measurementPointName}</h6>`;
        tooltipHtml += `<p class="f-primary time-series-graph__tooltip-container__date">${Highcharts.dateFormat('%A, %b %e, %Y', hoveredX)}</p>`;

        this.series.chart.series?.forEach(series => {
          if (series.userOptions?.measurementPointName === measurementPointName) {
            series.points.forEach(point => {
              if (point.x === hoveredX && series.visible) {
                const isMinMax = series.name.toLowerCase().includes('min/max');
                if (isMinMax && point.low !== null && point.high !== null) {
                  tooltipHtml += `<p class="f-secondary-dark time-series-graph__tooltip-container__label">Min</p><p class="f-primary time-series-graph__tooltip-container__value">${point.low}</p>`;
                  tooltipHtml += `<p class="f-secondary-dark time-series-graph__tooltip-container__label">Max</p><p class="f-primary time-series-graph__tooltip-container__value">${point.high}</p>`;
                } else if (isMinMax && point.low !== null) {
                  tooltipHtml += `<p class="f-secondary-dark time-series-graph__tooltip-container__label">Min</p><p class="f-primary time-series-graph__tooltip-container__value">${point.low}</p>`;
                } else if (isMinMax && point.high !== null) {
                  tooltipHtml += `<p class="f-secondary-dark time-series-graph__tooltip-container__label">Max</p><p class="f-primary time-series-graph__tooltip-container__value">${point.high}</p>`;
                } else if (point.y !== null) {
                  tooltipHtml += `<p class="f-secondary-dark time-series-graph__tooltip-container__label">${series.name}</p><p class="f-primary time-series-graph__tooltip-container__value">${point.y}</p>`;
                }
              }
            });
          }
        });

        if (this.series?.userOptions?.scaleFactor !== 1) {
          tooltipHtml += `<p class="f-secondary-dark time-series-graph__tooltip-container__label">${t(
            'GRAPHING_GROUPS.ADD_MEASUREMENT_POINT.SCALE_FACTOR'
          )}</p><p class="f-primary time-series-graph__tooltip-container__value">${this.series?.userOptions?.scaleFactor}</p>`;
        }
        if (this.series?.userOptions?.inverted) {
          tooltipHtml += `<p class="f-secondary-dark time-series-graph__tooltip-container__label">${t(
            'GRAPHING_GROUPS.ADD_MEASUREMENT_POINT.INVERTED'
          )}</p><p class="f-primary time-series-graph__tooltip-container__value">${t('TABLE_COLUMN_DATA.YES')}</p>`;
        }
        tooltipHtml += '</div>';

        return tooltipHtml;
      },
    },
    xAxis: {
      gridLineColor: colors.boxItemsSeparatorColor,
      minorGridLineColor: colors.boxItemsThemeColor,
      baselineColor: colors.boxItemsSeparatorColor,
      gridLineWidth: 1,
      tickWidth: 0,
      labels: {
        style: {
          color: colors.primaryFontColor,
        },
      },
      type: 'datetime',
    },
    yAxis: {
      gridLineColor: colors.boxItemsSeparatorColor,
      minorGridLineColor: colors.boxItemsThemeColor,
      baselineColor: colors.boxItemsSeparatorColor,
      gridLineWidth: 1,
      tickWidth: 0,
      title: {
        text: ``,
        style: {
          color: colors.primaryFontColor,
        },
      },
      labels: {
        style: {
          color: colors.primaryFontColor,
        },
      },
      min: extremes.min,
      max: extremes.max,
      plotLines: showAlarms ? plotLines : [],
      events: {
        afterSetExtremes: e => calculateNewExtremes(e, plotLines),
      },
    },
    rangeSelector: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    chart: {
      panning: {
        enabled: true,
        type: 'x',
      },
      panKey: 'alt',
      zoomType: 'x',
      backgroundColor: 'transparent',
      events: {
        load() {
          setChartInstance(this);
        },
      },
    },
  };

  const isDataEmpty = data?.every(parent => parent.Series?.every(seriesItem => isEmpty(seriesItem.Data)));

  return (
    <div className="time-series-graph">
      {!isDataEmpty ? (
        <>
          {/* <HighchartsReact constructorType={'stockChart'} ref={forwardRef} highcharts={Highcharts} options={options} containerProps={{ style: { flex: '1 0 0' } }} /> */}
          <HighchartsReact ref={forwardRef} highcharts={Highcharts} options={options} containerProps={{ style: { flex: '1 0 0' } }} />
          {showLegend && <CustomLegend chart={chartInstance} />}
        </>
      ) : (
        <div style={{ width: '100%', height: '100%' }}>
          <div className={`empty-state-active`}>
            <h4 className="empty-state-title f-secondary-light"> {t('GRAPH.NO_DATA_AVAILABLE')}</h4>
          </div>
        </div>
      )}
    </div>
  );
};

TimeSeriesGraph.propTypes = {
  colors: PropTypes.object.isRequired,
  unit: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  showLegend: PropTypes.bool.isRequired,
  visibleAggregations: PropTypes.arrayOf(
    PropTypes.shape({
      Key: PropTypes.string.isRequired,
      Value: PropTypes.string.isRequired,
    })
  ),
};

TimeSeriesGraph.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
  colors: props.ignoreTheme ? THEMES.light.colors : state.themeReducer.severityColors,
});

export default connect(mapStateToProps, null)(forwardRef((props, ref) => <TimeSeriesGraph forwardRef={ref} {...props} />));
