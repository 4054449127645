import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { find, debounce } from 'lodash';

import Modal from '../../../../../common/modal/components/modal';
import Stepper from '../../../../../common/stepper/components/stepper';
import RenderIf from '../../../../../common/render-if/components/render-if';
import UploadAssetsModal from '../../../../project/components/upload-assets-modal';
import IsolationTemplateForm from './isolation-template-form';
import IsolationTemplateSections from './isolation-template-sections';

import Helpers from '../../../../../common/helpers';
import { uploadType } from '../../../../upload/constants/constants';
import { formConstants, steps, filterFields } from '../constants/constants';

import '../styles/isolation-template-modal.scss';
import {
  createIsolationTemplate,
  updateIsolationTemplate,
  getIsolationSections,
  addIsolationSection,
  updateIsolationSection,
  deleteIsolationSection,
  addIsolationSectionQuestion,
  deleteIsolationSectionQuestion,
  updateIsolationSectionQuestion,
  addIsolationSectionQuestionOption,
  updateIsolationSectionQuestionOption,
  deleteIsolationSectionQuestionOption,
  sortIsolationSections,
  sortIsolationSectionQuestions,
  getIsolationTemplateFiles,
  deleteIsolationTemplateFile,
} from '../../../actions/isolation-actions';
import { fetchQuestionTypes } from '../../../../checklists-procedures-management/actions/checklists-procedures-management-actions';
import { setSingleUploadItem, setUploadItems, uploadAsset } from '../../../../upload/actions/upload-actions';
import { questionOptionsFields } from '../../../../../common/question-components/constants/question-constants';

class IsolationTemplateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isEditMode: props.isEditMode,
      activeStep: steps.firstStep,
      lastStep: steps.secondStep,
      stepperData: [
        {
          name: 'ISOLATION_TEMPLATE_MODAL.STEP_1',
          stepValue: steps.firstStep,
          description: 'ISOLATION_TEMPLATE_MODAL.STEP_1_DESC',
          disabled: false,
        },
        {
          name: 'ISOLATION_TEMPLATE_MODAL.STEP_2',
          stepValue: steps.secondStep,
          description: 'ISOLATION_TEMPLATE_MODAL.STEP_2_DESC',
          disabled: false,
        },
      ],
      questionTypes: [],
      modalData: {
        isOpen: false,
      },
    };

    this.sectionFormChangeDebounce = debounce(this.submitSectionForm, 600);
    this.questionFormChangeDebounce = debounce(this.submitQuestionForm, 600);
    this.questionOptionChangeDebounce = debounce(this.submitQuestionOptionForm, 600);
  }

  submitSectionForm = values => {
    const { updateIsolationSection, sections } = this.props;
    updateIsolationSection(values, Object.assign([], sections));
  };

  submitQuestionForm = values => {
    const { updateIsolationSectionQuestion, sections } = this.props;

    updateIsolationSectionQuestion(values, Object.assign([], sections));
  };

  submitQuestionOptionForm = values => {
    const { updateIsolationSectionQuestionOption, sections } = this.props;

    updateIsolationSectionQuestionOption(values, Object.assign([], sections));
  };

  handleSuccessSubmit = (newState, isCreate) => {
    if (newState.activeStep === steps.secondStep) {
      const { t } = this.context;
      const { getIsolationSections, isolation, fetchQuestionTypes } = this.props;

      getIsolationSections({ TemplateID: isolation[formConstants.fields.id] }, () => this.setState(newState));
      fetchQuestionTypes(t, (isLoading, questionTypes) => this.setState({ isLoading, questionTypes }));

      if (isCreate) {
        const { filter, setFilter } = this.props;
        setFilter({ ...filter, [filterFields.totalItems]: filter[filterFields.totalItems] + 1 });
      }
      return;
    }

    this.setState(newState);
  };

  handleSubmit = values => {
    let { activeStep } = this.state;
    const { createIsolationTemplate, updateIsolationTemplate, isolation, projectId, viewOnly } = this.props;
    values = {
      ...values,
    };

    if (activeStep === steps.firstStep) {
      if (viewOnly) {
        this.handleSuccessSubmit({ activeStep: ++activeStep });
      } else if (isolation && isolation[formConstants.fields.id]) {
        updateIsolationTemplate(values, () => this.handleSuccessSubmit({ activeStep: ++activeStep }));
      } else {
        createIsolationTemplate({ ...values, ProjectID: projectId }, () => this.handleSuccessSubmit({ activeStep: ++activeStep, isEditMode: true }, true));
      }
    } else if (activeStep === steps.secondStep) {
      // TODO
    }
    this.setStep(true);
  };

  setStep = forward => {
    const { closeAction } = this.props;
    let { activeStep, lastStep } = this.state;
    const nextStep = forward ? ++activeStep : --activeStep;
    if (nextStep < steps.firstStep || nextStep > lastStep) {
      closeAction();
      return;
    }

    this.setState({ activeStep: nextStep });
  };

  handleCloseModal = () => {
    this.setState({ modalData: { isOpen: false } });
  };

  handleAddSection = () => {
    const { isolation, sections, addIsolationSection } = this.props;
    addIsolationSection(isolation[formConstants.fields.id], Object.assign([], sections));
  };

  handleUpdateSection = values => {
    this.sectionFormChangeDebounce(values, true);
  };

  handleDeleteSection = (sectionId, section) => {
    const { t } = this.context;
    const { sections, deleteIsolationSection } = this.props;

    const modalData = {
      isOpen: true,
      content: t('SECTIONS.DELETE_SECTION_CONTENT', { section }),
      type: 'yes-no',
      customClassName: 'delete-confirm-modal',
      confirmAction: () => deleteIsolationSection(sectionId, Object.assign([], sections), this.handleCloseModal),
      closeAction: this.handleCloseModal,
    };

    this.setState({ modalData });
  };

  handleBackButtonClick = () => {
    const { activeStep } = this.state;

    this.setState({ activeStep: activeStep - 1 });
  };

  handleFinishButtonClick = () => {
    const { closeAction } = this.props;

    closeAction();
  };

  handleAddQuestion = sectionId => {
    const { addIsolationSectionQuestion, sections } = this.props;

    addIsolationSectionQuestion(sectionId, Object.assign([], sections));
  };

  handleFieldDelete = (sectionQuestionId, index, question) => {
    const { t } = this.context;
    const { deleteIsolationSectionQuestion, sections } = this.props;

    const modalData = {
      isOpen: true,
      content: t('SECTIONS.DELETE_QUESTION_CONTENT', { question }),
      customClassName: 'delete-confirm-modal',
      type: 'yes-no',
      confirmAction: () => deleteIsolationSectionQuestion(sectionQuestionId, Object.assign([], sections), index, this.handleCloseModal),
      closeAction: this.handleCloseModal,
    };

    this.setState({ modalData });
  };

  handleFieldChange = values => {
    this.questionFormChangeDebounce(values);
  };

  handleAddQuestionOption = questionId => {
    const { sections, addIsolationSectionQuestionOption } = this.props;

    addIsolationSectionQuestionOption(questionId, Object.assign([], sections));
  };

  handleUpdateQuestionOption = values => {
    this.questionOptionChangeDebounce(values);
  };

  handleDeleteQuestionOption = (index, option) => {
    const { t } = this.context;
    const { sections, deleteIsolationSectionQuestionOption } = this.props;

    const modalData = {
      isOpen: true,
      content: t('SECTIONS.DELETE_QUESTION_OPTION_CONTENT', { name: option ? option[questionOptionsFields.name.name] : '' }),
      customClassName: 'delete-confirm-modal',
      type: 'yes-no',
      confirmAction: () => deleteIsolationSectionQuestionOption(index, option, Object.assign([], sections), this.handleCloseModal),
      closeAction: this.handleCloseModal,
    };

    this.setState({ modalData });
  };

  handleSectionSort = (currentIndex, newIndex) => {
    const { isolation, sections, sortIsolationSections } = this.props;

    sortIsolationSections(isolation[formConstants.fields.id], currentIndex, newIndex, Object.assign([], sections));
  };

  handleFieldSort = (currentIndex, newIndex, question) => {
    const { sections, sortIsolationSectionQuestions } = this.props;

    sortIsolationSectionQuestions(question.SectionID, currentIndex, newIndex, Object.assign([], sections));
  };

  onDropAsset = files => {
    const { uploadAsset, setUploadItems, setSingleUploadItem, projectId, isolation } = this.props;

    const tmp_files = files.map((file, index) => {
      const tmp_file = {
        type: Helpers.getFileType(file.type),
        mimeType: file.type,
        title: '',
        description: '',
        real_name: Helpers.formatFileName(file.name),
      };
      file.timeStamp = Date.now();
      file.real_name = tmp_file.real_name;
      file.uploadType = uploadType.isolationTemplateFiles;

      uploadAsset(file, { IsolationTemplateID: isolation[formConstants.fields.id], ProjectID: projectId }, setSingleUploadItem, index);
      return { ...tmp_file, ...file };
    });

    setUploadItems(tmp_files);
  };

  toggleUploadModal = sectionId => {
    const { t } = this.context;
    const confirmAction = () => {
      const { isolation, sections, getIsolationTemplateFiles, setUploadItems } = this.props;
      getIsolationTemplateFiles(isolation[formConstants.fields.id], Object.assign([], sections));
      setUploadItems([]);

      this.setState({
        modalData: {
          isOpen: false,
        },
      });
    };

    this.setState(prevState => ({
      modalData: {
        ...prevState.uploadModalData,
        title: t('CONTRACTOR_MODAL.FILES_UPLOAD.TITLE'),
        customClassName: 'project-assets-modal modal-small',
        CustomContent: () => (
          <UploadAssetsModal
            onDrop={files => this.onDropAsset(files, sectionId)}
            className="project-dropzone"
            labelWhite={t('CONTRACTOR_MODAL.FILES_UPLOAD.TITLE')}
            labelGreen={t('CONTRACTOR_MODAL.FILES_UPLOAD.BROWSE')}
          />
        ),
        isOpen: true,
        type: 'ok',
        confirmAction: confirmAction,
        closeAction: confirmAction,
      },
    }));
  };

  handleDeleteIsolationTemplateFile = (file, sectionId) => {
    const { t } = this.context;
    const { isolation, sections, deleteIsolationTemplateFile } = this.props;

    const modalData = {
      isOpen: true,
      content: t('SECTIONS.DELETE_FILE_CONTENT', { name: file.FileName || '' }),
      customClassName: 'delete-confirm-modal',
      type: 'yes-no',
      confirmAction: () => deleteIsolationTemplateFile(isolation[formConstants.fields.id], file.FileID, sectionId, Object.assign([], sections), this.handleCloseModal),
      closeAction: this.handleCloseModal,
    };

    this.setState({ modalData });
  };

  render() {
    const { t } = this.context;
    const { stepperData, activeStep, lastStep, isEditMode, questionTypes, modalData } = this.state;
    const { className, isolation, sections, viewOnly } = this.props;

    const activeStepObj = find(stepperData, item => item.stepValue === activeStep);

    return (
      <div className={`isolation-template-modal ${className || ''}`}>
        <Stepper {...{ stepperData, activeStep }} />

        <p className="f-primary step-desc">{(activeStepObj && t(activeStepObj.description)) || ''}</p>

        <RenderIf if={activeStep === steps.firstStep}>
          <IsolationTemplateForm
            onSubmit={this.handleSubmit}
            stepAction={this.setStep}
            initialValues={{
              ...isolation,
            }}
            activeStep={activeStep}
            activeStepObj={activeStepObj}
            lastStep={lastStep}
            isEditMode={isEditMode}
            viewOnly={viewOnly}
          />
        </RenderIf>
        <RenderIf if={activeStep === steps.secondStep}>
          <IsolationTemplateSections
            sections={sections}
            questionTypes={questionTypes}
            handleAddSection={this.handleAddSection}
            handleUpdateSection={this.handleUpdateSection}
            handleBackButtonClick={this.handleBackButtonClick}
            handleFinishButtonClick={this.handleFinishButtonClick}
            handleAddQuestion={this.handleAddQuestion}
            handleFieldDelete={this.handleFieldDelete}
            handleFieldChange={this.handleFieldChange}
            handleAddQuestionOption={this.handleAddQuestionOption}
            handleUpdateQuestionOption={this.handleUpdateQuestionOption}
            handleDeleteQuestionOption={this.handleDeleteQuestionOption}
            handleDeleteSection={this.handleDeleteSection}
            handleSectionSort={this.handleSectionSort}
            handleFieldSort={this.handleFieldSort}
            toggleUploadModal={this.toggleUploadModal}
            handleDeleteSectionFile={this.handleDeleteIsolationTemplateFile}
            sectionUpdateDebounce={this.sectionFormChangeDebounce}
            questionUpdateDebounce={this.questionFormChangeDebounce}
            questionOptionUpdateDebounce={this.questionOptionChangeDebounce}
            viewOnly={viewOnly}
          />
        </RenderIf>
        <Modal {...modalData} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    sections: state.templateReducer.sections,
  };
};

const mapDispatchToProps = dispatch => ({
  createIsolationTemplate: (data, successCallback) => dispatch(createIsolationTemplate(data, successCallback)),
  updateIsolationTemplate: (data, successCallback) => dispatch(updateIsolationTemplate(data, successCallback)),
  getIsolationSections: (data, successCallback) => dispatch(getIsolationSections(data, successCallback)),
  fetchQuestionTypes: (t, successCallback) => dispatch(fetchQuestionTypes(t, successCallback)),
  addIsolationSection: (templateId, sections) => dispatch(addIsolationSection(templateId, sections)),
  updateIsolationSection: (data, sections) => dispatch(updateIsolationSection(data, sections)),
  deleteIsolationSection: (sectionId, sections, successCallback) => dispatch(deleteIsolationSection(sectionId, sections, successCallback)),
  addIsolationSectionQuestion: (sectionId, sections) => dispatch(addIsolationSectionQuestion(sectionId, sections)),
  deleteIsolationSectionQuestion: (sectionQuestionId, sections, index, successCallback) => dispatch(deleteIsolationSectionQuestion(sectionQuestionId, sections, index, successCallback)),
  updateIsolationSectionQuestion: (data, sections) => dispatch(updateIsolationSectionQuestion(data, sections)),
  addIsolationSectionQuestionOption: (data, sections) => dispatch(addIsolationSectionQuestionOption(data, sections)),
  updateIsolationSectionQuestionOption: (data, sections) => dispatch(updateIsolationSectionQuestionOption(data, sections)),
  deleteIsolationSectionQuestionOption: (index, option, sections, successCallback) => dispatch(deleteIsolationSectionQuestionOption(index, option, sections, successCallback)),
  sortIsolationSections: (templateId, currentIndex, newIndex, sections) => dispatch(sortIsolationSections(templateId, currentIndex, newIndex, sections)),
  sortIsolationSectionQuestions: (sectionId, currentIndex, newIndex, sections) => dispatch(sortIsolationSectionQuestions(sectionId, currentIndex, newIndex, sections)),
  uploadAsset: (file, IDs, callbackFunction, index, customDoneFunc) => dispatch(uploadAsset(file, IDs, callbackFunction, index, customDoneFunc)),
  setSingleUploadItem: (progress, index) => dispatch(setSingleUploadItem(progress, index)),
  setUploadItems: assets => dispatch(setUploadItems(assets)),
  getIsolationTemplateFiles: (templateId, sections) => dispatch(getIsolationTemplateFiles(templateId, sections)),
  deleteIsolationTemplateFile: (templateId, fileId, sectionId, sections, successCallback) => dispatch(deleteIsolationTemplateFile(templateId, fileId, sectionId, sections, successCallback)),
});

IsolationTemplateModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(IsolationTemplateModal);
