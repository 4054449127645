import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import '../styles/workflow-stepper.scss';

class WorkflowStepper extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  isActive = (activeStep, index) => {
    return Math.floor(activeStep) >= index;
  };

  getColumns = () => {
    const { stepperData, includeLines } = this.props;

    if (includeLines && stepperData.length > 1) {
      let style = '';
      for (let i = 0; i < stepperData.length + stepperData.length - 1; i++) {
        if (i % 2 === 0) {
          style += '2fr ';
        } else {
          style += '1.5fr ';
        }
      }
      return style;
    }

    return '1fr '.repeat(stepperData.length);
  };

  render() {
    const { t } = this.context;
    const { stepperData, activeStep, includeLines } = this.props;

    return (
      <div className="workflow-stepper" style={{ gridTemplateColumns: this.getColumns() }}>
        {stepperData.map((item, index) => {
          const isActive = this.isActive(activeStep, item.stepValue);

          return (
            <Fragment key={index}>
              <div className={`step ${isActive ? 'active' : ''}`}>
                <div className="circle">
                  <p className={`noselect ${isActive ? '' : 'f-secondary-dark'}`}>{index + 1}</p>
                </div>

                <p className={`noselect ${isActive ? 'f-primary' : 'f-secondary-dark'}`}> {t(item.name)}</p>
              </div>
              {includeLines && index < stepperData.length - 1 && (
                <div className={`line`}>
                  <hr />
                </div>
              )}
            </Fragment>
          );
        })}
      </div>
    );
  }
}

WorkflowStepper.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default WorkflowStepper;
