const columntChartStyle = {
  legend: { position: 'none' },
  bars: 'vartical',
  bar: { groupWidth: '60%' },
  animation: {
    duration: 1000,
    easing: 'inAndOut',
    startup: true,
  },
  chartArea: {
    left: 0,
    right: 0,
    top: 0,
    bottom: 20,
    width: '100%',
  },
  vAxis: {
    textStyle: {
      opacity: 0,
    },
  },
};

export { columntChartStyle };
