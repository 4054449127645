import React from 'react';
import PropTypes from 'prop-types';

import BasicImage from '../../image-components/components/basic-image';
import Button from '../../form/components/button';
import Icon from '../../icon/components/icon';
import RenderIf from '../../render-if/components/render-if';
import Helpers from '../../helpers';

const FilesUpload = ({ featureId, files, uploadButtonIcon, toggleUploadModal, handleDeleteFiles, disabled }, { t }) => {
  const handleFileOpen = url => {
    if (url) {
      window.open(url, '_blank').focus();
    }
  };

  return (
    <div className="files-list">
      <RenderIf if={files && files.length > 0}>
        <p className="f-secondary-dark files-list__subtitle">{t('ISOLATION_TEMPLATE_SECTIONS.FILES.NAME')}</p>
      </RenderIf>
      {files &&
        files.map((item, index) => {
          return (
            <div className={`files-list__item ${index === files.length - 1 ? '' : 'files-list__item-with-border'}`} key={`file-item-${item.FileID || index}`}>
              <div className="files-list__item__title">
                <RenderIf if={item.ThumbnailURL && Helpers.isImage(item.FileName)}>
                  <BasicImage hideImageLoader className="files-list__item__title__img" src={item.ThumbnailURL} alt={`file-thumbnail-${featureId}-${index}`} onClick={() => handleFileOpen(item.URL)} />
                </RenderIf>
                <RenderIf if={item.URL && !Helpers.isImage(item.FileName)}>
                  <Icon name={Helpers.isPDF(item.FileName) ? 'pdf-file' : 'file-text'} className="files-list__item__title__file-icon" onClick={() => handleFileOpen(item.URL)} />
                </RenderIf>
                <p className="f-primary c-title" onClick={() => handleFileOpen(item.URL)}>{`${item.ThumbnailURL || item.URL ? '' : `${index + 1}. `}${item.FileName}`}</p>
              </div>
              <RenderIf if={!disabled}>
                <Icon className="files-list__item__delete-icon" name="trash" size="xs" onClick={() => handleDeleteFiles(item, featureId)} />
              </RenderIf>
            </div>
          );
        })}
      <Button
        className="files-list__button"
        type="button"
        disabled={disabled}
        icon={uploadButtonIcon}
        iconProps={{ active: true }}
        variant="gray-outline"
        height="md"
        width="lg"
        text={t('ISOLATION_TEMPLATE_SECTIONS.FILES_UPLOAD.TITLE')}
        onClick={() => toggleUploadModal(featureId)}
      />
    </div>
  );
};

FilesUpload.contextTypes = {
  t: PropTypes.func.isRequired,
};

FilesUpload.propTypes = {
  featureId: PropTypes.number.isRequired,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      FileID: PropTypes.number.isRequired,
      FileName: PropTypes.string.isRequired,
      Key: PropTypes.string,
      DocumentURL: PropTypes.string,
      ThumbnailURL: PropTypes.string,
      URL: PropTypes.string,
    })
  ),
  uploadButtonIcon: PropTypes.string,
  toggleUploadModal: PropTypes.func.isRequired,
  handleDeleteFiles: PropTypes.func.isRequired,
};

FilesUpload.defaultProps = {
  files: [],
  uploadButtonIcon: 'paperclip',
};

export default FilesUpload;
