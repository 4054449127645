import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { change, reset } from 'redux-form';

import { map, filter, isEmpty } from 'lodash';

import DownloadReportFrom from './download-single-defect-report-form';
import Stepper from '../../../app/start-workflow/components/workflow-stepper';
import EmailModal from '../../../app/inspections/components/ndt/ndt-alarms-modal-email';
import Modal from '../../modal/components/modal';

import ScreenshotHelpers from '../../screenshoot-helpers';

import { executeDownloadReport } from '../../download-report-modal/actions/download-report-actions';

import { FORMS } from '../../constants';
import constants from '../constants/download-report-constants';
import { formConstants } from '../../../app/inspections/constants/defect-constants';

import '../styles/download-defect-report.scss';

class DownloadDefectReportModal extends Component {
  state = {
    step: constants.steps.firstStep,
    ModelView: null,
    lastStep: constants.steps.secondStep,
    instance: null,
    emails: [],
    modalData: {
      isOpen: false,
    },
  };

  submitForm = vs => {
    const { downloadReport } = this.props;
    this.getInspectionScreenshot(`#${constants.potreeId}`, () => {
      const vals = this.prepareReportValues(vs);
      downloadReport(vals, this.callbackAction, 'DOWNLOAD_REPORT.SUCCESS_MESSAGE', 'DOWNLOAD_REPORT.SUCCESS_MESSAGE_TITLE');
    });
  };

  prepareReportValues = vs => {
    const { ModelView } = this.state;
    const { inspectionId, reduceToSelectedImages, defect } = this.props;

    // remove ALL prop if present or empty prop values
    let vals = Object.assign({}, vs);

    vals = {
      InspectionID: inspectionId,
      SelectionReport: reduceToSelectedImages || false,
      ...vals,
      ModelView,
      DefectID: defect[formConstants.fields.id],
      [constants.fields.emailList]: vals[constants.fields.emailList].toString(),
    };
    return vals;
  };

  callbackAction = () => {
    const { closeModal } = this.props;
    closeModal(true);
  };

  changeStep = direction => {
    let { step } = this.state;

    const nextStep = direction ? step + 1 : step - 1;

    const setStep = () => {
      this.setState({ step: nextStep });
    };

    if (direction && nextStep === constants.steps.secondStep) {
    }

    setStep();
  };

  getInspectionScreenshot = (querySelector, onFinish) => {
    ScreenshotHelpers.takeScreenshot(
      querySelector,
      url => {
        this.setState({ ModelView: url });
      },
      onFinish
    );
  };

  createViewerInstance = newInstance => {
    const { defect: element } = this.props;

    this.setState({ instance: newInstance });

    if (
      !isEmpty(element.Geometry) &&
      !isEmpty(element.Geometry.coordinates) &&
      !isEmpty(element.Geometry.coordinates[0]) &&
      !isEmpty(element.CameraPosition) &&
      !isEmpty(element.CameraPosition.coordinates) &&
      newInstance
    ) {
      setTimeout(() => {
        newInstance.zoomToPosition({ x: element.CameraPosition.coordinates[0], y: element.CameraPosition.coordinates[1], z: element.CameraPosition.coordinates[2] }, element.Geometry.coordinates, 500);
      }, 500);
    }
  };

  openEmailModal = () => {
    const { t } = this.context;

    const { resetForm } = this.props;

    const closeAction = () => {
      this.setState({
        modalData: {
          isOpen: false,
        },
      });
    };

    const removeAlarmEmail = (a, b, { Email }) => {
      const { changeField } = this.props;
      const { emails } = this.state;
      const resEmails = filter(emails, item => {
        return item.Email !== Email;
      });
      this.setState({
        emails: resEmails,
      });

      changeField(
        constants.fields.emailList,
        map(resEmails, item => item.Email)
      );
    };
    const addAlarmEmail = (Email, a, b, resetCallback) => {
      const { changeField } = this.props;
      const { emails } = this.state;
      const newEmails = [...emails, { Email }];
      this.setState({
        emails: newEmails,
      });
      changeField(
        constants.fields.emailList,
        map(newEmails, item => item.Email)
      );
      resetCallback();
    };

    this.setState({
      modalData: {
        isOpen: true,
        title: t('NDT_EMAILS_MODAL.TITLE'),
        type: '',
        customClassName: 'modal-no-max-height email-modal',
        CustomContent: restProps => <EmailModal addAlarmEmail={addAlarmEmail} {...restProps} removeAlarmEmail={removeAlarmEmail} resetForm={resetForm} closeModal={closeAction} />,
        closeAction: closeAction,
      },
    });
  };

  render() {
    const { step, lastStep, instance, modalData, emails } = this.state;
    const { initialValues, defect } = this.props;

    return (
      <div className="download-report">
        <div className="download-report__form">
          <Stepper stepperData={constants.stepperData} activeStep={step} />

          <DownloadReportFrom
            onSubmit={this.submitForm}
            preventSubmit={step === lastStep}
            initialValues={initialValues}
            step={step}
            nextStep={step === lastStep ? null : () => this.changeStep(true)}
            previousStep={step === constants.steps.firstStep ? null : () => this.changeStep(false)}
            createViewerInstance={this.createViewerInstance}
            viewer={instance}
            potreeId={constants.potreeId}
            defects={[defect]}
            mapId={constants.mapId}
            openEmailModal={this.openEmailModal}
            emails={emails}
          />
        </div>

        <Modal {...modalData} emails={emails} />
      </div>
    );
  }
}
DownloadDefectReportModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    initialValues: {
      [constants.fields.description]: '',
      [constants.fields.recomendations]: '',
      [constants.fields.imagesPerPage]: false,
      [constants.fields.includeComponentDetails]: true,
      [constants.fields.includeDefectSummary]: false,
      [constants.fields.includeRecommendationSummary]: false,
      [constants.fields.emailList]: [],
    },
  };
};

const mapDispatchToProps = dispatch => ({
  downloadReport: (data, callback, successMessage, successMessageTitle, selectedFile) => dispatch(executeDownloadReport(data, callback, successMessage, successMessageTitle, selectedFile)),
  changeField: (field, value) => dispatch(change(FORMS.dowloadDefectReport, field, value)),
  resetForm: form => dispatch(reset(form)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadDefectReportModal);
