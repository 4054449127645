import { isObject } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Icon from '../../icon/components/icon';
import RenderIf from '../../render-if/components/render-if';
import InfoTooltip from '../../tooltip/components/info-tooltip';
import '../styles/input.scss';

/**
 * CustomInput is a reusable input component with various customization options.
 * It supports different input types, icons, tooltips, and validation error handling.
 */
class CustomInput extends Component {
  render() {
    const { t } = this.context;
    const {
      input,
      meta,
      type,
      placeholder,
      className,
      additionalClassName,
      id,
      label,
      labelClass,
      labelTooltip,
      labelTooltipText,
      labelTooltipIcon,
      disabled,
      isHidden,
      max,
      autoFocus,
      size,
      withHiddenError,
      onlyDisabled,
      isRequired,
      handleChange,
      inputProps,
      hasIcon,
      iconName,
      iconRotation,
      redWarning,
    } = this.props;

    const onChange = e => {
      input.onChange(e.target.value);

      if (handleChange) {
        handleChange(e.target.value);
      }
    };

    const isMetaErrorObject = isObject(meta.error);

    return (
      <div className={`field-wrapper${disabled ? ' field-wrapper--disabled' : ''}${onlyDisabled ? ' field-wrapper--only-disabled' : ''} ${isHidden ? 'hidden-field' : ''}`}>
        {label && (
          <label className={`field-wrapper__label ${labelClass}`} htmlFor={id}>
            {`${t(label)}${isRequired ? '*' : ''}`}
            <RenderIf if={labelTooltip}>
              <InfoTooltip
                customComponent={<p>{labelTooltipText}</p>}
                offsetY={10}
                offsetX={-20}
                Component={() => <Icon name={labelTooltipIcon} handleHover={false} size="xs" />}
                containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
              />
            </RenderIf>
          </label>
        )}
        <div className="input-wrapper">
          <RenderIf if={hasIcon}>
            <Icon name={iconName} className="input-icon" iconRotation={iconRotation} size="md" />
          </RenderIf>
          <input
            id={id}
            disabled={disabled}
            readOnly={disabled}
            {...input}
            {...inputProps}
            onChange={onChange}
            max={max}
            type={type}
            autoCorrect="off"
            spellCheck={false}
            placeholder={placeholder ? t(placeholder) : ''}
            className={`${className || 'input-wrapper__input'} ${meta?.error && meta.touched ? 'invalid ' : ''} ${
              meta?.warning ? `${redWarning ? 'red-warning-border' : 'warning-border'}` : ''
            } ${size} ${additionalClassName} ${hasIcon ? 'has-icon' : ''}`}
            autoFocus={autoFocus}
          />
          <div className={`error-message${meta && ((meta.error && meta.touched) || meta.warning) ? '--visible' : ''} ${withHiddenError ? 'with-hidden-error-message' : ''}`}>
            <RenderIf if={meta?.error}>
              <p className="error">{t(isMetaErrorObject ? meta?.error?.string : meta?.error, isMetaErrorObject ? meta?.error.params : null)}</p>
            </RenderIf>
            <RenderIf if={meta?.warning}>
              <p className={`${redWarning ? 'red-warning' : 'warning'}`}>{t(meta?.warning?.string, meta?.warning?.params)}</p>
            </RenderIf>
          </div>
        </div>
      </div>
    );
  }
}

CustomInput.propTypes = {
  id: PropTypes.string.isRequired,
  input: PropTypes.object,
  meta: PropTypes.object,
  type: PropTypes.oneOf(['text', 'number', 'email']),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  labelClass: PropTypes.string,
  additionalClassName: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  isHidden: PropTypes.bool,
  autoFocus: PropTypes.bool,
  max: PropTypes.number,
  size: PropTypes.oneOf(['sm', 'lg']),
  withHiddenError: PropTypes.bool,
  labelTooltip: PropTypes.bool,
  labelTooltipText: PropTypes.string,
  labelTooltipIcon: PropTypes.string,
  hasIcon: PropTypes.bool,
  iconName: PropTypes.string,
  iconRotation: PropTypes.number,
  redWarning: PropTypes.bool,
};

CustomInput.defaultProps = {
  type: 'text',
  placeholder: PropTypes.string,
  className: '',
  labelClass: '',
  additionalClassName: '',
  disabled: false,
  isHidden: false,
  autoFocus: false,
  size: 'sm',
  withHiddenError: false,
  labelTooltip: false,
  labelTooltipText: '',
  labelTooltipIcon: 'info',
  hasIcon: false,
  redWarning: false,
};

CustomInput.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default CustomInput;
