import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { chain, map } from 'lodash';

// import Toolbar from '../../toolbar/components/toolbar';
import { getXslxData, downloadNDTReport } from '../actions/download-report-actions';
import { getFilterGroupSuggestions } from '../../../app/inspections/actions/ndt-actions';

import DownloadReportXslx from './download-report-xslx';
import CustomTable from '../../table/components/table-component';

import { isEmpty } from 'lodash';

import Helpers from '../../helpers';

import constants from '../constants/download-report-constants';

import '../styles/download-report.scss';

class DownloadReportModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeItem: constants.tabs[0].name,
    };
  }

  componentDidMount = () => {
    const { getXslxData, inspectionId, getFilterGroupSuggestions } = this.props;
    getXslxData(inspectionId);
    getFilterGroupSuggestions(inspectionId, '');
  };

  handleActiveTab = type => {
    // const { fetchDownloadReportHistory, inspectionId } = this.props;

    this.setState({ activeItem: type });
    if (type === constants.tabNames.reportHistory) {
      // fetchDownloadReportHistory(inspectionId);
    }
  };

  downloadReportXslx = values => {
    const { downloadNDTReport, inspectionId, closeModal, measurementId, skipStepXslx } = this.props;
    //format values
    let valsToSave = {};

    Object.keys(constants.multiSelectFields).forEach(key => {
      const objKey = constants.multiSelectFields[key];
      if (values[objKey]) {
        valsToSave = {
          ...valsToSave,
          [objKey]: values[objKey].indexOf(constants.defaultValue) > -1 ? [] : map(values[objKey], item => item[constants.objectConstruction.key]),
        };
      }
    });

    Object.keys(constants.dropdownFields).forEach(key => {
      const objKey = constants.dropdownFields[key];
      if (values[objKey]) {
        valsToSave = {
          ...valsToSave,
          [objKey]: values[objKey][constants.objectConstruction.key],
        };
      }
    });
    Object.keys(constants.dateFields).forEach(key => {
      const objKey = constants.dateFields[key];
      if (values[objKey]) {
        valsToSave = {
          ...valsToSave,
          [objKey]: parseInt(new Date(values[objKey]).getTime() / 1000),
        };
      }
    });
    //if it is for single measuerement
    if (skipStepXslx) {
      valsToSave = {
        ...valsToSave,
        [constants.fields.measurementIDs]: [measurementId],
      };
    }

    downloadNDTReport(inspectionId, { ...values, ...valsToSave }, closeModal);
  };

  render() {
    const { t } = this.context;
    const { activeItem } = this.state;
    const { downloadReportHistory, chartPeriods, ndtChartPeriodGroupData, skipStepXslx, ndtMeasurementsComponents, ndtMeasurementsUnits, ndtAlarmsTriggered, filterGroupSuggestions, closeModal } =
      this.props;

    const filterGroups = chain(filterGroupSuggestions)
      .filter(item => item)
      .map(item => ({ [constants.objectConstruction.key]: item, [constants.objectConstruction.value]: item }))
      .value();

    return (
      <div className="download-report-wrapper">
        {/* <Toolbar toolbarItems={constants.tabs} activeItem={activeItem} handleActiveTab={this.handleActiveTab} /> */}

        {activeItem === constants.tabNames.downloadReport && (
          <DownloadReportXslx
            {...{
              filterGroups,
              chartPeriods,
              ndtChartPeriodGroupData,
              ndtMeasurementsComponents,
              ndtMeasurementsUnits,
              ndtAlarmsTriggered,
              skipStep: skipStepXslx,
              downloadReport: this.downloadReportXslx,
              closeModal,
            }}
          />
        )}
        {activeItem === constants.tabNames.reportHistory && (
          <div className="download-report__table">
            {!isEmpty(downloadReportHistory) ? (
              <CustomTable
                tableConfig={constants.reportTableHeader}
                data={downloadReportHistory || []}
                translationModule={t}
                formatCell={Helpers.formatCell}
                onRowClick={this.onRowClick}
                stickyHeader={true}
              />
            ) : (
              <div>
                <p className="f-primary">{t('DOWNLOAD_REPORT.EMPTY_STATE')}</p>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
DownloadReportModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    chartPeriods: state.ndtReducer.chartPeriods,
    ndtChartPeriodGroupData: state.ndtReducer.ndtChartPeriodGroupData,
    ndtMeasurementsComponents: state.ndtReducer.ndtMeasurementsComponents,
    ndtMeasurementsUnits: state.ndtReducer.ndtMeasurementsUnits,
    ndtAlarmsTriggered: state.ndtReducer.ndtAlarmsTriggered,
    filterGroupSuggestions: state.ndtReducer.filterGroupSuggestions,
    // downloadReportHistory,
  };
};

const mapDispatchToProps = dispatch => ({
  getXslxData: inspectionId => dispatch(getXslxData(inspectionId)),
  getFilterGroupSuggestions: (inspectionid, searchText) => dispatch(getFilterGroupSuggestions(inspectionid, searchText)),
  downloadNDTReport: (inspectionid, params, sucessCallback) => dispatch(downloadNDTReport(inspectionid, params, sucessCallback)),
  // fetchDownloadReportHistory: inspectionID => dispatch(fetchDownloadReportHistory(inspectionID)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadReportModal);
