import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import ButtonWithIcon from '../../../../../common/button-with-icon/button-with-icon';
import TableTemplate from '../../../../../common/double-table/components/table-template';
import Helpers from '../../../../../common/helpers';
import { fetchVersionHistoryForDocument, setFileVersionAsActive } from '../../../actions/dm-api-calls';
import { setNewActiveVersion } from '../../../constants/action-creators';
import { fields } from '../../../constants/constants';
import { formatVersionHistoryTable } from './actions/table-formatting';
import { tableConfig, versionHistoryDropdownActions } from './constants/constants';
import './styles/version-history-modal.scss';

const VersionHistoryModal = (props, context) => {
  const {
    fetchVersionHistoryForDocument,
    sourceID,
    openUploadNewVersion,
    versionHistory,
    versionHistoryLoading,
    setFileVersionAsActive,
    setNewActiveVersion,
    updateSelectedHierarchyDetailsData,
    openEditVersionModal,
    handleDeleteVersionAction,
    canUploadNewVersion,
    documentDetails,
    formHasUnsavedChanges,
  } = props;
  const { t } = context;

  useEffect(() => {
    fetchVersionHistoryForDocument({ SourceID: sourceID });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const downloadFile = file => {
    Helpers.getFileExtensionAndDownload(file);
  };

  const handleSetAsActiveAction = file => {
    if (!formHasUnsavedChanges || window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
      if (!file && !file[fields.id]) return;
      const onVersionActivated = () => {
        const itemsToUpdateOnDetails = {
          ...pick(file, [
            fields.fileVersion,
            fields.versionCreatedAt,
            fields.versionCreatedBy,
            fields.versionDate,
            fields.versionDescription,
            fields.versionNumber,
            fields.fileSizeKB,
            fields.fileType,
            fields.url,
          ]),
          [fields.isPdf]: Helpers.isPDF(file[fields.fileName], file[fields.fileType]),
          [fields.isImage]: Helpers.isImage(file[fields.fileName], file[fields.fileType]),
        };
        setNewActiveVersion(file[fields.id]);
        updateSelectedHierarchyDetailsData(itemsToUpdateOnDetails);
      };
      setFileVersionAsActive({ VersionID: file[fields.id] }, onVersionActivated);
    }
  };

  const handleClickOnEditAction = (versionFile, documentDetails) => {
    openEditVersionModal(versionFile, documentDetails);
  };

  const dropdownActions = {
    [versionHistoryDropdownActions.download]: downloadFile,
    [versionHistoryDropdownActions.setAsActive]: handleSetAsActiveAction,
    [versionHistoryDropdownActions.edit]: handleClickOnEditAction,
    [versionHistoryDropdownActions.delete]: handleDeleteVersionAction,
  };

  const handleDropdownAction = (action, file, documentDetails) => {
    dropdownActions[action] && typeof dropdownActions[action] === 'function' && dropdownActions[action](file, documentDetails);
  };

  // Created var to add document details to each row
  // Looked like a better solution than to drill prop through the table template
  const versionHistoryData = useMemo(() => {
    return versionHistory.map(version => {
      return { ...version, documentDetails };
    });
  }, [versionHistory, documentDetails]);

  return (
    <div className="version-history-modal">
      <ButtonWithIcon icon="plus-circle" noBorder type="success" bold onClick={openUploadNewVersion} text={t('DMS_FILE.UPLOAD_NEW_VERSION')} isDisabled={!canUploadNewVersion} />
      <TableTemplate
        tableConfig={tableConfig}
        data={versionHistoryData}
        handleFormatCells={formatVersionHistoryTable}
        showInfo={false}
        showSearchBar={false}
        showOptionFor3DToggle={false}
        onRowClick={() => null}
        isLoading={versionHistoryLoading}
        onDropdownClick={handleDropdownAction}
      />
    </div>
  );
};

VersionHistoryModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

VersionHistoryModal.propTypes = {
  sourceID: PropTypes.number.isRequired,
  openUploadNewVersion: PropTypes.func.isRequired,
  updateSelectedHierarchyDetailsData: PropTypes.func.isRequired,
  openEditVersionModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  versionHistory: state.dmsVersionHistoryReducer.versionHistory,
  versionHistoryLoading: state.dmsVersionHistoryReducer.versionHistoryLoading,
});

const mapDispatchToProps = dispatch => ({
  fetchVersionHistoryForDocument: data => dispatch(fetchVersionHistoryForDocument(data)),
  setFileVersionAsActive: (data, callback) => dispatch(setFileVersionAsActive(data, callback)),
  setNewActiveVersion: id => dispatch(setNewActiveVersion(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VersionHistoryModal);
