import { PERMISSIONS, PERMISSION_TYPES } from '../../../common/permissions-constants';

export const teamFields = {
  name: 'Name',
  id: 'ID',
  users: 'Users',
  createdAt: 'CreatedAt',
  usersNO: 'UsersNo',
  teamId: 'TeamID',
  userId: 'UserID',
};

export const assigneesTypes = {
  team: 'TEAM',
  user: 'USER',
};

export const userFields = {
  name: 'Name',
  id: 'ID',
  email: 'Email',
  userName: 'UserName',
  userId: 'UserID',
  userEmail: 'UserEmail',
  userActive: 'UserActive',
  userCompanyID: 'UserCompanyID',
};

export const teamsTableHeader = [
  {
    title: 'TEAM_TABLE.ID',
    key: teamFields.id,
    enableSort: true,
  },
  {
    title: 'TEAM_TABLE.NAME',
    key: teamFields.name,
    enableSort: true,
  },
  {
    title: 'TEAM_TABLE.NO_USERS',
    key: teamFields.users,
    type: teamFields.users,
    enableSort: true,
  },
  {
    title: 'TEAM_TABLE.DATE_CREATED',
    key: teamFields.createdAt,
    enableSort: true,
    type: 'date',
  },
];

export const sortDirection = {
  asc: 'ASC',
  desc: 'DESC',
};

export const filterProps = {
  sortByColumn: 'SortByColumn',
  sortByDirection: 'SortByDirection',
  perPage: 'PerPage',
  lastSeen: 'LastSeen',
  searchText: 'SearchText',
  hasNext: 'HasNext',
};

export const defaultTeamsFilter = {
  [filterProps.sortByColumn]: teamFields.id,
  [filterProps.sortByDirection]: sortDirection.asc,
  [filterProps.perPage]: 20,
  [filterProps.lastSeen]: 0,
  [filterProps.searchText]: '',
  [filterProps.hasNext]: false,
};

export const actionTypes = {
  editTeam: 'editTeam',
  deleteTeam: 'deleteTeam',
  addMembers: 'addMembers',
};
export const actionsMenu = [
  {
    title: 'TEAM_TABLE_SIDEBAR.SETTINGS_MENU.ITEM_1',
    id: '1',
    action: actionTypes.editTeam,
    access: {
      visibleFor: [PERMISSIONS[PERMISSION_TYPES.people].edit.name],
    },
  },
  {
    title: 'TEAM_TABLE_SIDEBAR.SETTINGS_MENU.ITEM_2',
    id: '2',
    action: actionTypes.addMembers,
    access: {
      visibleFor: [PERMISSIONS[PERMISSION_TYPES.people].edit.name],
    },
  },
  {
    title: 'TEAM_TABLE_SIDEBAR.SETTINGS_MENU.ITEM_3',
    id: '3',
    action: actionTypes.deleteTeam,
    access: {
      visibleFor: [PERMISSIONS[PERMISSION_TYPES.people].delete.name],
    },
  },
];

export const userSearchParams = {
  PerPage: Number.MAX_SAFE_INTEGER,
  LastSeen: 0,
  SearchText: '',
  SortByColumn: userFields.name,
  SortDirection: 'ASC',
  ActiveUsers: true,
};
