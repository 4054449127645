import { debounce, filter, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import BaseModule from '../../../app/inspections/components/left-toolbar/base-module';
import InspectionView from '../../../app/inspections/potree/components/main-view';
import ChooseComponents from './choose-components';
import ComponentsDisplay3D from './components-display-3D';

import { getInspectionDetails } from '../../../app/inspections/actions/inspection-actions';

import { modules } from '../../../app/inspections/constants/constants';
import componentConstants from '../constants/component-constants';
import { componentsPickerSelectKeys } from '../constants/question-constants';

import '../styles/component-picker-modal.scss';

class ComponentPicker3D extends Component {
  constructor(props) {
    super(props);
    this.handleComponentSearch = debounce(this.handleComponentSearch, 300);

    this.state = {
      viewerInstance: null,
      filteredComponents: [],
      selectedComponent: null,
    };
    this.parentRef = React.createRef();
  }

  componentDidMount() {
    const { getInspectionDetails, inspectionId } = this.props;

    getInspectionDetails(inspectionId);
  }

  // only handles for DMS related search API triggering
  componentDidUpdate() {
    const { components, searchApiRequired } = this.props;
    const { filteredComponents } = this.state;

    // For small arrays like these (max 20 items per array), the performance impact of using _.isEqual()
    // is likely negligible, and the convenience it provides for deep comparison outweighs
    // any minor performance considerations.
    if (searchApiRequired && !isEqual(filteredComponents, components)) {
      this.setState({
        filteredComponents: components,
      });
    }
  }

  createViewerInstance = newInstance => {
    this.setState({ viewerInstance: newInstance });
  };

  onComponentChange = value => {
    this.setState({ selectedComponent: value });
  };

  addSelectedComponent = () => {
    const { selectedComponent } = this.state;
    const { handleComponentSelected, question } = this.props;

    if (selectedComponent) {
      selectedComponent.ComponentName = selectedComponent[componentsPickerSelectKeys.textKey];
      selectedComponent.ComponentID = selectedComponent[componentsPickerSelectKeys.valueKey];
      const componentIds = [selectedComponent];
      if (typeof handleComponentSelected === 'function') {
        handleComponentSelected(question.ID, componentIds);
      }
    }
  };

  // TODO: this is redundant code as it appears both on component-picker as well, move it to parent
  handleComponentSearch = value => {
    const { components, searchApiRequired, handleAPIComponentSearch } = this.props;
    const lowerCaseValue = value.toLowerCase();

    if (searchApiRequired) {
      handleAPIComponentSearch(lowerCaseValue);
    } else {
      const filtered = value
        ? filter(components, item => {
            return item[componentsPickerSelectKeys.textKey].toLowerCase().indexOf(lowerCaseValue) > -1;
          })
        : components;

      this.setState({ filteredComponents: filtered });
    }
  };

  // TODO: redundant code, requires refactoring (appears on component-picker)
  handleInputFocus = () => {
    const { filteredComponents } = this.state;

    if (!filteredComponents || !filteredComponents.length) {
      const { searchApiRequired } = this.props;
      if (searchApiRequired) {
        const { handleAPIComponentSearch } = this.props;
        handleAPIComponentSearch('');
      } else {
        const { components } = this.props;
        this.setState({ filteredComponents: components });
      }
    }
  };

  render() {
    const { question, componentsClustered, inspectionId, handleDeleteComponent, searchApiRequired, componentsFilterProps, loadMoreOnClick, keepComponentsDropdownVisible, componentsLoading } =
      this.props;
    const { viewerInstance, filteredComponents, selectedComponent } = this.state;
    const selectedComponents = question?.QuestionsComponents || [];

    return (
      <div className="component-picker-modal">
        <InspectionView
          parentRef={this.parentRef}
          isWorkflow={false}
          potreeId={componentConstants.potreeId}
          createViewerInstance={this.createViewerInstance}
          viewer={viewerInstance}
          showCameras={false}
          showScreenToolbox
        />
        <div className="sidebar">
          <BaseModule module={modules.components} viewer={viewerInstance} inspectionId={inspectionId} elementsClustered={componentsClustered}>
            <ComponentsDisplay3D question={question} handleDeleteComponent={handleDeleteComponent} selectedComponents={selectedComponents} onComponentChange={this.onComponentChange} />
          </BaseModule>
          <ChooseComponents
            selectedComponents={selectedComponents}
            handleComponentSearch={this.handleComponentSearch}
            filteredComponents={filteredComponents}
            onComponentChange={this.onComponentChange}
            handleInputFocus={this.handleInputFocus}
            selectedComponent={selectedComponent}
            addSelectedComponent={this.addSelectedComponent}
            searchApiRequired={searchApiRequired}
            componentsFilterProps={componentsFilterProps}
            loadMoreOnClick={loadMoreOnClick}
            keepComponentsDropdownVisible={keepComponentsDropdownVisible}
            componentsLoading={componentsLoading}
          />
        </div>
      </div>
    );
  }
}

ComponentPicker3D.propTypes = {
  searchApiRequired: PropTypes.bool,
  handleAPIComponentSearch: PropTypes.func,
  componentsFilterProps: PropTypes.object,
  loadMoreOnClick: PropTypes.func,
  componentsSearchText: PropTypes.string,
  keepComponentsDropdownVisible: PropTypes.bool,
};

ComponentPicker3D.defaultProps = {
  searchApiRequired: false,
  handleAPIComponentSearch: searchTerm => null,
  componentsFilterProps: {},
  loadMoreOnClick: () => null,
  componentsSearchText: '',
  keepComponentsDropdownVisible: false,
};

const mapStateToProps = state => {
  return {
    componentsClustered: state.inspectionReducer.componentsClustered,
  };
};

const mapDispatchToProps = dispatch => ({
  getInspectionDetails: inspectionId => dispatch(getInspectionDetails(inspectionId)),
});

ComponentPicker3D.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ComponentPicker3D);
