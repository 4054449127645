import React, { Component, forwardRef } from 'react';

import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import Icon from '../../icon/components/icon';
import Portal from '../../portal/components/portal';

import 'react-datepicker/dist/react-datepicker.css';
import Helpers from '../../helpers';
import '../styles/date-picker.scss';
import '../styles/input.scss';

const CustomInput = forwardRef(({ selectedDate, isClearable, onIconClick, placeholderText, hideCalendarIcon, ...props }, ref) => {
  return (
    <>
      <input {...props} ref={ref} placeholder={placeholderText} />
      {!(isClearable && selectedDate) && !hideCalendarIcon && <Icon name="calendar" solid className="react-datepicker__close-icon" onClick={onIconClick} />}
    </>
  );
});
export default class CustomDatePicker extends Component {
  constructor(props) {
    super(props);

    this.calendarRef = React.createRef(null);
  }

  onIconClick = () => {
    this.calendarRef?.current?.setOpen?.(true);
  };

  render() {
    const { t } = this.context;
    const {
      input: { onChange: inputOnChange, ...filteredInput } = { onChange: null },
      className,
      label,
      labelClass,
      meta,
      onChange,
      selected,
      size,
      placeholder,
      hideCalendarIcon,
      validateUsingReduxForm,
      withHiddenError,
      isRequired,
      isEnd,
      isClearable,
    } = this.props;
    let selectedDate = selected || filteredInput.value || null;

    // check if the moment object represents midnight in the given timezone
    const momentDate = Helpers.getDateMomentObject(selectedDate);
    const isEndDateMidnight = momentDate.hour() === 0 && momentDate.minute() === 0 && momentDate.second() === 0 && momentDate.millisecond() === 0;

    // if End Date is set to 12:00AM, re-set it to 11:59PM for the same day
    selectedDate = isEnd && isEndDateMidnight ? Helpers.setDateTimeToMidnight(selectedDate) : selectedDate;

    return (
      <div className="field-wrapper">
        {label && <label className={`field-wrapper__label ${labelClass}`}>{`${t(label)}${isRequired ? '*' : ''}`}</label>}
        <div className="input-wrapper">
          <DatePicker
            {...this.props}
            ref={this.calendarRef}
            popperContainer={({ children }) => <Portal children={children} />}
            selected={selectedDate ? Helpers.getCurrentDateAndTimeInMs(selectedDate) : selectedDate}
            {...filteredInput}
            onChange={onChange || inputOnChange}
            autoComplete="off"
            customInput={
              <CustomInput
                {...{
                  type: 'text',
                  selectedDate,
                  placeholderText: (placeholder && t(placeholder)) || '',
                  className: `${className || 'input-wrapper__input'} ${size}`,
                  onIconClick: this.onIconClick,
                  hideCalendarIcon,
                  isClearable,
                }}
              />
            }
          />
          {meta && validateUsingReduxForm && (
            <div className={`error-message${meta.error && (meta.dirty || meta.touched) ? '--visible' : ''} ${withHiddenError ? 'with-hidden-error-message' : ''}`}>{meta.error && t(meta.error)}</div>
          )}
          {meta && !validateUsingReduxForm && (
            <div className={`error-message${meta.error ? '--visible' : ''} ${withHiddenError ? 'with-hidden-error-message' : ''}`}>{meta.error && t(meta.error.string, meta.error.params)}</div>
          )}
        </div>
      </div>
    );
  }
}

CustomDatePicker.propTypes = {
  input: PropTypes.object,
  onChange: PropTypes.func,
  className: PropTypes.string,
  labelClass: PropTypes.string,
  placeHolder: PropTypes.string,
  label: PropTypes.string,
  meta: PropTypes.object,
  selected: PropTypes.any,
  size: PropTypes.oneOf(['sm', 'lg']),
  hideCalendarIcon: PropTypes.bool,
  validateUsingReduxForm: PropTypes.bool,
  withHiddenError: PropTypes.bool,
};

CustomDatePicker.defaultProps = {
  className: '',
  labelClass: '',
  size: 'sm',
  hideCalendarIcon: false,
  validateUsingReduxForm: false,
  withHiddenError: false,
};

CustomDatePicker.contextTypes = {
  t: PropTypes.func.isRequired,
};
