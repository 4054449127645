import { debounce, isEmpty, uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Helpers from '../../../../../../common/helpers';
import Modal from '../../../../../../common/modal/components/modal';
import ReducerHelpers from '../../../../../../common/reducer-helpers';
import RenderIf from '../../../../../../common/render-if/components/render-if';
import ActionModal from '../../../../../document-management/components/modals/action-modal/action-modal';
import { modules } from '../../../../constants/constants';
import { fetchChecklistProceduresMeasurementGroups } from '../../actions/measurement-group-actions';
import { fetchChecklistProceduresMeasurementLocations, linkMeasurementLocationToChecklistProcedure, unlinkMeasurementLocationFromChecklistProcedure } from '../../actions/measurement-location-actions';
import {
  fetchChecklistProceduresLinkedMeasurementPoints,
  fetchChecklistProceduresMeasurementPoints,
  linkMeasurementPointToChecklistProcedure,
  unlinkMeasurementPointFromChecklistProcedure,
} from '../../actions/measurement-point-actions';
import { fields, viewOptions } from '../../constants/constants';
import { addedMeasurementPointsDefaultFilter, defaultFilter, sortDirections } from '../../constants/measurement-point-constants';

import AddedMeasurementPointsTableView from '../table-view/added-measurement-points-table-view';
import MeasurementGroupsTableView from '../table-view/measurement-groups-table-view';
import MeasurementLocationsTableView from '../table-view/measurement-locations-table-view';
import MeasurementPointsTableView from '../table-view/measurement-points-table-view';

import { PERMISSION_TYPES, PERMISSIONS } from '../../../../../../common/permissions-constants';
import '../../styles/new-measurement-point-wrapper.scss';

const NewMeasurementPointWrapper = (props, { t }) => {
  const {
    projectId,
    user,
    defaultReadingGroupID,
    fetchChecklistProceduresMeasurementGroups,
    fetchChecklistProceduresMeasurementLocations,
    fetchChecklistProceduresMeasurementPoints,
    additionalFetchMLProps,
    linkMeasurementLocationToChecklistProcedure,
    linkMeasurementPointToChecklistProcedure,
    customCloseAction,
    fetchChecklistProceduresLinkedMeasurementPoints,
    unlinkMeasurementPointFromChecklistProcedure,
    unlinkMeasurementLocationFromChecklistProcedure,
  } = props;
  const [path, setPath] = useState([{ [fields.name]: t('READINGS_AND_GAUGES.ALL_GROUPS'), [fields.returnToDefault]: true, queryParams: { type: modules.readingsAndGauges } }]);
  const [activeView, setActiveView] = useState(viewOptions.group);
  const [selectedMeasurementGroup, setSelectedMeasurementGroup] = useState(null); // mostly used for breadcrumb
  const [selectedMeasurementLocation, setSelectedMeasurementLocation] = useState(null); // mostly used for breadcrumb
  const [selectedMeasurementPoint, setSelectedMeasurementPoint] = useState(null); // mostly used for breadcrumb
  const [confirmationModalData, setConfirmationModalData] = useState({ isOpen: false });

  // State for added measurement points data
  const [addedMeasurementPoints, setAddedMeasurementPoints] = useState([]);
  const [addedMeasurementPointsFilters, setAddedMeasurementPointsFilters] = useState({ ...addedMeasurementPointsDefaultFilter });
  const [isAddedMeasurementPointsLoading, setIsAddedMeasurementPointsLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [applyEmptyMeasurementPointsList, setEmptyMeasurementPointsList] = useState(false);
  const [shouldUpdateMeasurementLocations, setShouldUpdateMeasurementLocations] = useState(false);
  const [updatedMeasurementPoint, setUpdatedMeasurementPoint] = useState(null);

  const sectionId = additionalFetchMLProps?.[fields.sectionID];

  // redundant as basically same method is used in readings-and-gauges.js, but this one manipulates state, rather than reducer
  // TODO: discuss with the team how to not duplicate this method
  const navigateToPath = useCallback(
    (pathItem, newPathArray) => {
      if (isEmpty(pathItem)) {
        return;
      }
      let newPath = Object.assign([], path);
      setEmptyMeasurementPointsList(false); // reset Measurement Points table state

      const { queryParams } = pathItem;
      // Navigate to desired path
      if (!queryParams.selected_item) {
        // Reset selected measurement location whenever selected_item is empty, which means neither mp or ml is selected
        setSelectedMeasurementLocation(null);
      }

      setActiveView(queryParams.view || viewOptions.group);

      // Set new path
      if (newPathArray) {
        // override the existing path
        setPath(newPathArray);
      } else {
        // Set new path
        if (!isEmpty(path) && path[path.length - 1].queryParams?.selected_item && queryParams.selected_item) {
          // only one query item with distinct type can be present in the path, and it should always come as a last item.
          newPath.pop();
        }
        setPath([...newPath, pathItem]);
      }
    },
    [setActiveView, setSelectedMeasurementLocation, path]
  );

  const handleBackClick = () => {
    const newPath = Object.assign([], path);
    newPath.pop();
    if (newPath[newPath.length - 1]) {
      navigateToPath(newPath[newPath.length - 1], newPath);
    }
  };

  const handlePathClick = (_id, selectedPath) => {
    const index = path.findIndex(element => element[fields.name] === selectedPath[fields.name]);
    let newPath = Object.assign([], path);

    if (index > -1) {
      // Return a new array with elements up to and including the found element
      newPath = newPath.slice(0, index + 1);
    }
    navigateToPath(selectedPath, newPath);
  };

  const handleLinkUnlinkAllMPsFromML = (selectedMeasurementLocation, shouldLink, successCallback) => {
    if (!selectedMeasurementLocation || !selectedMeasurementLocation?.[fields.id]) return;
    let title = '';
    let firstParagraph = '';
    if (shouldLink) {
      title = 'ADD_MEASUREMENT_POINTS';
      firstParagraph = 'ADD_MEASUREMENT_POINTS.CONFIRMATION_PARAGRAPH';
      showConfirmationModal(title, firstParagraph, selectedMeasurementLocation, shouldLink, successCallback);
    } else {
      title = 'REMOVE_MEASUREMENT_POINTS';
      firstParagraph = 'REMOVE_MEASUREMENT_POINTS.CONFIRMATION_PARAGRAPH';
      showConfirmationModal(title, firstParagraph, selectedMeasurementLocation, shouldLink, successCallback);
    }
  };

  const handleConfirmAddAllMPsFromMl = (activeMeasurementLocation, successCallback) => {
    // close confirmation modal
    closeConfirmationModal();

    // then update the added MPs
    linkMeasurementLocationToChecklistProcedure(
      { ...additionalFetchMLProps, [fields.measurementLocationID]: activeMeasurementLocation[fields.id] },
      { ...defaultFilter },
      (newData, newFilters) => {
        if (selectedMeasurementLocation?.[fields.id] === activeMeasurementLocation[fields.id]) {
          setEmptyMeasurementPointsList(true); // set Measurement Points table to empty state, only if the active and selected MLs are the same
          // we do not want to set the empty MP list if other ML is selected to be linked
        }
        const newAddedMeasurementPointsList = uniqBy([...addedMeasurementPoints, ...newData], fields.id);

        setAddedMeasurementPoints(newAddedMeasurementPointsList);
        const newTotalNumber = activeMeasurementLocation[fields.totalNumberOfMeasurementPoints] - activeMeasurementLocation[fields.numberOfLinkedMeasurementPoints];
        setAddedMeasurementPointsFilters({
          ...addedMeasurementPointsFilters,
          [fields.totalNumber]: addedMeasurementPointsFilters[fields.totalNumber] + newTotalNumber,
          [fields.hasNext]: newFilters[fields.hasNext],
        });
        successCallback && typeof successCallback === 'function' && successCallback(); // updates the selected ML to show the X icon
      },
      loading => console.log('loading', loading),
      error => console.log('error', error)
    );
  };

  const handleConfirmRemoveAllMPsFromMl = (activeMeasurementLocation, successCallback) => {
    // close confirmation modal
    closeConfirmationModal();

    // then update the added MPs
    unlinkMeasurementLocationFromChecklistProcedure(
      { ...additionalFetchMLProps, [fields.measurementLocationID]: activeMeasurementLocation[fields.id] },
      { ...defaultFilter },
      newData => {
        if (selectedMeasurementLocation?.[fields.id] === activeMeasurementLocation?.[fields.id]) {
          setEmptyMeasurementPointsList(true); // set Measurement Points table to empty state, only if the active and selected MLs are the same
          // we do not want to set the empty MP list if other ML is selected to be linked
        }
        // Filter out the items from addedMeasurementPoints that are present in newData
        const updatedMeasurementPoints = addedMeasurementPoints.filter(point => !newData.some(newPoint => newPoint[fields.id] === point[fields.id]));

        // const newTotalNumber = addedMeasurementPointsFilters[fields.totalNumber] - activeMeasurementLocation[fields.totalNumberOfMeasurementPoints];
        // Update the state with the filtered list
        setAddedMeasurementPoints(uniqBy(updatedMeasurementPoints, fields.id));

        // Update the filters to reflect the new total number
        setAddedMeasurementPointsFilters({
          ...addedMeasurementPointsFilters,
          [fields.totalNumber]: updatedMeasurementPoints.length, // TODO: something is off with this totalNumber after multiple unlink action - need to check this after the BE fixes
        });
        successCallback && typeof successCallback === 'function' && successCallback(); // updates the selected ML to show the + icon
      },
      () => {
        // loading callback
        if (selectedMeasurementLocation?.[fields.id] === activeMeasurementLocation?.[fields.id]) {
          // to trigger the fetch of the MPs in the selected ML
          setEmptyMeasurementPointsList(false); // set Measurement Points table to non-empty state, only if the active and selected MLs are the same
          // we do not want to set the empty MP list if other ML is selected to be linked
        }
      }
    );
  };

  const showConfirmationModal = (title, firstParagraph, data, shouldLink, successCallback) => {
    setConfirmationModalData({
      isOpen: true,
      CustomContent: dynamicProps => <ActionModal {...dynamicProps} />,
      closeAction: () => setConfirmationModalData({ isOpen: false }),
      type: '',
      title: t(title),
      confirmButtonText: shouldLink ? 'PROCEED' : 'REMOVE_MEASUREMENT_POINTS',
      closeButtonText: 'CANCEL',
      firstParagraph: firstParagraph,
      // if unlink action, then display the number of already linked measurement points that shall be removed, otherwise subtract already linked measurement points from total number of linked measurement points
      firstParagraphProps: {
        mpNumber: shouldLink ? data[fields.totalNumberOfMeasurementPoints] - data[fields.numberOfLinkedMeasurementPoints] : data[fields.numberOfLinkedMeasurementPoints],
        mlName: data[fields.name],
      },
      secondParagraph: null,
      customCloseAction: () => closeConfirmationModal({ isOpen: false }),
      customConfirmAction: shouldLink ? () => handleConfirmAddAllMPsFromMl(data, successCallback) : () => handleConfirmRemoveAllMPsFromMl(data, successCallback),
      customClassName: 'confirmation-new-mp-modal modal-large',
      customClassWrapperName: 'confirmation-new-mp-modal__picker',
      confirmButtonProps: {
        variant: shouldLink ? 'success' : 'danger',
        isCapitalized: true,
      },
    });
  };

  const closeConfirmationModal = () => {
    setConfirmationModalData({ isOpen: false });
  };

  const fetchAddedMeasurementPoints = useCallback(
    (filters, loadMore = false) => {
      const handleFetchData = (newData, newFilters) => {
        const dataToSet = loadMore ? [...addedMeasurementPoints, ...newData] : newData;
        setIsAddedMeasurementPointsLoading(true);
        setAddedMeasurementPoints(uniqBy(dataToSet, fields.id));
        setAddedMeasurementPointsFilters({ ...filters, ...newFilters });
        setIsAddedMeasurementPointsLoading(false);

        if (loadMore) {
          Helpers.scrollIntoView('selected-points-table', `row-${filters[fields.lastSeen] - 1}`);
        }
      };

      fetchChecklistProceduresLinkedMeasurementPoints({ ...filters, SectionID: sectionId }, handleFetchData, setIsAddedMeasurementPointsLoading);
    },
    [fetchChecklistProceduresLinkedMeasurementPoints, sectionId, addedMeasurementPoints]
  );

  useEffect(() => {
    fetchAddedMeasurementPoints(addedMeasurementPointsFilters);
    //eslint-disable-next-line
  }, []);

  const handleLoadMoreClick = () => fetchAddedMeasurementPoints(addedMeasurementPointsFilters, true);

  const handleSortClick = sortByColumn => {
    fetchAddedMeasurementPoints({
      ...addedMeasurementPointsFilters,
      [fields.lastSeen]: 0,
      [fields.sortDirection]: addedMeasurementPointsFilters[fields.sortDirection] === sortDirections.ASC ? sortDirections.DESC : sortDirections.ASC,
      [fields.sortByColumn]: sortByColumn,
    });
  };

  const handleSearchInputChange = e => {
    const searchText = e.target.value;
    setSearchText(searchText);
    searchTextChanged(searchText);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchTextChanged = useCallback(
    debounce(searchText => fetchAddedMeasurementPoints({ ...addedMeasurementPointsFilters, [fields.lastSeen]: 0, [fields.searchText]: searchText }), 300),
    [fetchAddedMeasurementPoints]
  );

  const handleUnlinkMeasurementPoint = measurementPoint => {
    if (!measurementPoint) return;
    const data = {
      [fields.sectionID]: sectionId,
      [fields.measurementPointID]: measurementPoint[fields.id],
    };
    unlinkMeasurementPointFromChecklistProcedure(
      data,
      () => {
        if (measurementPoint[fields.measurementLocationID] === selectedMeasurementLocation?.[fields.id]) {
          // only if the selected ML is the same as active ML - we want to trigger the mp list endpoint
          setEmptyMeasurementPointsList(true); // serves as toggle in the mp-table-view component to fetch data, as a dependency in the useEffect hook
        }

        setShouldUpdateMeasurementLocations(true); // serves as toggle in the ml-table-view component to update icon, as a dependency in the useEffect hook
        const newItems = ReducerHelpers.removeItemByProp(addedMeasurementPoints, measurementPoint, fields.id);

        setAddedMeasurementPoints(uniqBy(newItems, fields.id));
        setAddedMeasurementPointsFilters({
          ...addedMeasurementPointsFilters,
          [fields.totalNumber]: addedMeasurementPointsFilters[fields.totalNumber] - 1,
          [fields.lastSeen]: addedMeasurementPointsFilters[fields.lastSeen] - 1,
        });
        const updatedMp = { ...measurementPoint, link: false }; // link flag determines the TotalNumberOfMeasurementPoints assigned to the parent ML
        setUpdatedMeasurementPoint(updatedMp);
        setShouldUpdateMeasurementLocations(false); // serves as toggle in the ml-table-view component to update icon, as a dependency in the useEffect hook
        if (measurementPoint?.[fields.measurementLocationID] === selectedMeasurementLocation?.[fields.id]) {
          // only if the selected ML is the same as active ML - we want to trigger the mp list endpoint
          setEmptyMeasurementPointsList(false); // serves as toggle in the mp-table-view component to fetch data, as a dependency in the useEffect hook
        }
      },
      () => {
        // resets the state, so that the useEffect is not triggered in the ML table view component
        setUpdatedMeasurementPoint(null);
      }
    );
  };

  const handleLinkMeasurementPoint = (measurementPoint, successCallback) => {
    if (!measurementPoint) return;
    const data = {
      [fields.sectionID]: sectionId,
      [fields.measurementPointID]: measurementPoint[fields.id],
    };
    linkMeasurementPointToChecklistProcedure(
      data,
      linkedMp => {
        setShouldUpdateMeasurementLocations(true); // if the only MP from the ML is linked, update the ML icon to X
        const newAddedMeasurementPointsList = uniqBy([...addedMeasurementPoints, linkedMp], fields.id);
        setAddedMeasurementPoints(newAddedMeasurementPointsList);
        setAddedMeasurementPointsFilters({
          ...addedMeasurementPointsFilters,
          [fields.totalNumber]: addedMeasurementPointsFilters[fields.totalNumber] + 1,
          [fields.lastSeen]: addedMeasurementPointsFilters[fields.lastSeen] + 1,
        });
        successCallback && typeof successCallback === 'function' && successCallback(linkedMp); // filters out the linked MP from the MP table list
        const updatedMp = { ...linkedMp, link: true }; // link flag determines the TotalNumberOfMeasurementPoints assigned to the parent ML
        setUpdatedMeasurementPoint(updatedMp);
        setShouldUpdateMeasurementLocations(false);
      },
      // loading callback
      () => {
        // resets the state, so that the useEffect is not triggered in the ML table view component
        setUpdatedMeasurementPoint(null);
      }
    );
  };

  return (
    <div className="new-measurement-point-wrapper">
      <div className="new-measurement-point-wrapper__container">
        <div className="new-measurement-point-wrapper__container__left-side">
          <RenderIf if={activeView === viewOptions.group}>
            <MeasurementGroupsTableView
              visibleFor={[PERMISSIONS[PERMISSION_TYPES.checklists].measurementPointLink.name]}
              isFullScreen={true}
              view={activeView}
              path={path}
              navigateToPath={navigateToPath}
              handleBackClick={handleBackClick}
              handlePathClick={handlePathClick}
              projectID={projectId}
              viewer={null}
              user={user} // TODO: probably not required, since there will be no additional conditional logic depending on the user or permission
              showPath={true}
              elementsClustered={null}
              showAdditionalOptions={false}
              searchPlaceholder={t('READINGS_AND_GAUGES.GROUP.MEASUREMENT_GROUP.SEARCH')}
              isNewMeasurementPointModal
              selectMeasurementGroup={setSelectedMeasurementGroup}
              fetchDataAction={fetchChecklistProceduresMeasurementGroups}
            />
          </RenderIf>
          <RenderIf if={activeView === viewOptions.location_and_points}>
            <div className="double-table">
              <div className="double-table__table">
                <MeasurementLocationsTableView
                  visibleFor={[PERMISSIONS[PERMISSION_TYPES.checklists].measurementPointLink.name]}
                  searchPlaceholder={t('READINGS_AND_GAUGES.GROUP.MEASUREMENT_LOCATION.SEARCH', {
                    groupName: selectedMeasurementGroup ? t(selectedMeasurementGroup[fields.name]) : '',
                  })}
                  isFullScreen={true}
                  view={activeView}
                  path={path}
                  navigateToPath={navigateToPath}
                  handleBackClick={handleBackClick}
                  handlePathClick={handlePathClick}
                  projectID={projectId}
                  elementsClustered={null}
                  selectedClusterElement={null}
                  selectedMeasurementGroupId={selectedMeasurementGroup?.[fields.id] || defaultReadingGroupID}
                  user={user} // TODO: probably not required, since there will be no additional conditional logic depending on the user or permission
                  setSelectedMeasurementLocation={setSelectedMeasurementLocation}
                  selectedMeasurementLocation={selectedMeasurementLocation || null}
                  isNewMeasurementPointModal
                  toggleModalAction={(row, shouldLink, successCallback) => handleLinkUnlinkAllMPsFromML(row, shouldLink, successCallback)}
                  fetchDataAction={fetchChecklistProceduresMeasurementLocations} // for now only C&P module utilizes this component
                  additionalFetchMLProps={additionalFetchMLProps} // additionalFetchMLProps is a dynamic placeholder, but for now only C&P module utilizes this component
                  shouldUpdateMeasurementLocations={shouldUpdateMeasurementLocations}
                  updatedMeasurementPoint={updatedMeasurementPoint}
                />
              </div>
              <div className="double-table__table">
                <MeasurementPointsTableView
                  visibleFor={[PERMISSIONS[PERMISSION_TYPES.checklists].measurementPointLink.name]}
                  searchPlaceholder={t('READINGS_AND_GAUGES.GROUP.MEASUREMENT_POINT.SEARCH', {
                    groupName: selectedMeasurementLocation ? t(selectedMeasurementLocation[fields.name]) : '',
                  })}
                  view={activeView}
                  path={path}
                  showPath={false}
                  navigateToPath={navigateToPath}
                  handleBackClick={handleBackClick}
                  handlePathClick={handlePathClick}
                  projectID={projectId}
                  elementsClustered={null}
                  selectedMeasurementPoint={selectedMeasurementPoint || null}
                  selectedClusterElement={null}
                  selectedMeasurementLocation={selectedMeasurementLocation || null}
                  user={user}
                  handleOpenCreateMeasurementPointModal={() => null}
                  setSelectedMeasurementPoint={setSelectedMeasurementPoint}
                  isNewMeasurementPointModal
                  fetchDataAction={fetchChecklistProceduresMeasurementPoints} // for now only C&P module utilizes this component
                  additionalFetchMLProps={additionalFetchMLProps} // additionalFetchMLProps is a dynamic placeholder, but for now only C&P module utilizes this component
                  applyEmptyMeasurementPointsList={applyEmptyMeasurementPointsList}
                  toggleModalAction={(row, successCallback) => handleLinkMeasurementPoint(row, successCallback)}
                />
              </div>
            </div>
          </RenderIf>
        </div>
        <div className="new-measurement-point-wrapper__container__right-side">
          <AddedMeasurementPointsTableView
            closeAction={customCloseAction}
            filters={addedMeasurementPointsFilters}
            data={addedMeasurementPoints}
            searchText={searchText}
            handleLoadMoreClick={handleLoadMoreClick}
            handleSearchInputChange={handleSearchInputChange}
            isDataLoading={isAddedMeasurementPointsLoading}
            handleSortClick={handleSortClick}
            handleUnlinkMeasurementPoint={handleUnlinkMeasurementPoint}
          />
        </div>
      </div>
      <Modal {...confirmationModalData} />
    </div>
  );
};

NewMeasurementPointWrapper.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  defaultReadingGroupID: state.projectDetailsReducer.DefaultReadingGroupID,
});

const mapDispatchToProps = dispatch => ({
  fetchChecklistProceduresMeasurementLocations: (filters, dataCallback, loadingCallback, errorCallback) =>
    dispatch(fetchChecklistProceduresMeasurementLocations(filters, dataCallback, loadingCallback, errorCallback)),
  fetchChecklistProceduresMeasurementGroups: (filters, dataCallback, loadingCallback, errorCallback) =>
    dispatch(fetchChecklistProceduresMeasurementGroups(filters, dataCallback, loadingCallback, errorCallback)),
  fetchChecklistProceduresMeasurementPoints: (filters, dataCallback, loadingCallback, errorCallback) =>
    dispatch(fetchChecklistProceduresMeasurementPoints(filters, dataCallback, loadingCallback, errorCallback)),
  linkMeasurementLocationToChecklistProcedure: (params, measurementLocationFilters, dataCallback, loadingCallback, errorCallback) =>
    dispatch(linkMeasurementLocationToChecklistProcedure(params, measurementLocationFilters, dataCallback, loadingCallback, errorCallback)),
  linkMeasurementPointToChecklistProcedure: (params, dataCallback, loadingCallback, errorCallback) =>
    dispatch(linkMeasurementPointToChecklistProcedure(params, dataCallback, loadingCallback, errorCallback)),
  fetchChecklistProceduresLinkedMeasurementPoints: (filters, dataCallback, loadingCallback, errorCallback) =>
    dispatch(fetchChecklistProceduresLinkedMeasurementPoints(filters, dataCallback, loadingCallback, errorCallback)),
  unlinkMeasurementPointFromChecklistProcedure: (data, dataCallback, loadingCallback, errorCallback) =>
    dispatch(unlinkMeasurementPointFromChecklistProcedure(data, dataCallback, loadingCallback, errorCallback)),
  unlinkMeasurementLocationFromChecklistProcedure: (params, measurementLocationFilters, dataCallback, loadingCallback, errorCallback) =>
    dispatch(unlinkMeasurementLocationFromChecklistProcedure(params, measurementLocationFilters, dataCallback, loadingCallback, errorCallback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewMeasurementPointWrapper);
