import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { map } from 'lodash';

import { Field, reduxForm } from 'redux-form';
import BasicInput from '../../common/input/basic-input';
import Button from '../../../../../common/form/components/button';
import AreaGroup from './area-group';

import { FORMS } from '../../../../../common/constants';
import { areaGroupConstants } from '../../../constants/areas-constants';
import { validateGroup } from '../validators/area-group-validator';

import '../../../styles/manage-area-groups.scss';

class AddAreaModal extends Component {
  render() {
    const { t } = this.context;
    const { handleSubmit, areaGroups, deleteAreaGroup, updateAreaGroup, defaultGroupId } = this.props;

    return (
      <div className="manage-area-groups">
        <div className="area-groups-wrapper">
          <h5 className="f-primary">{t('AREA_FORM.GROUPS_SECTION')}</h5>
          <div className="area-groups">
            {map(areaGroups, (item, index) => {
              return (
                <AreaGroup
                  key={index}
                  name={item[areaGroupConstants.fields.name]}
                  id={item[areaGroupConstants.fields.id]}
                  deleteAreaGroup={deleteAreaGroup}
                  updateAreaGroup={updateAreaGroup}
                  defaultGroupId={defaultGroupId}
                />
              );
            })}
          </div>
        </div>
        <form className="manage-area-groups__form" onSubmit={handleSubmit} noValidate>
          <Field
            id={areaGroupConstants.fields.name}
            name={areaGroupConstants.fields.name}
            component={BasicInput}
            autoFocus={true}
            label={t('AREA_FORM.AREA_NAME')}
            placeholder={t('AREA_FORM.AREA_NAME')}
            type="text"
          />

          <div className="buttons">
            <Button text={t('AREA_GROUP.FORM_ADD')} width="sm" />
          </div>
        </form>
      </div>
    );
  }
}
AddAreaModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

AddAreaModal = reduxForm({
  form: FORMS.addAreaGroupForm,
  validate: validateGroup,
  enableReinitialize: true,
})(AddAreaModal);

const mapStateToProps = state => ({
  areaGroups: state.areasReducer.areaGroupsDropdownData,
});

export default connect(mapStateToProps, null)(AddAreaModal);
