import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../icon/components/icon';

const LockedSectionTitle = ({ Name, sectionIndex, nonTranslatable, isExpandable, isSectionExpanded, toggleSectionExpanded, hideLockIcon }, { t }) => {
  return (
    <div className="section__container__locked" onClick={isExpandable ? toggleSectionExpanded : () => null}>
      <h6 className="f-primary">{`${sectionIndex + 1}. ${nonTranslatable ? Name : t(Name)}`}</h6>
      {!hideLockIcon && <Icon name="lock" size="xs" className="f-primary section__container__locked__icon" />}
      {isExpandable && <Icon name={isSectionExpanded ? 'chevron-up' : 'chevron-down'} className="section__clickable section__container__locked__expandable-icon" />}
    </div>
  );
};

LockedSectionTitle.contextTypes = {
  t: PropTypes.func.isRequired,
};

LockedSectionTitle.propTypes = {
  Name: PropTypes.string.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  nonTranslatable: PropTypes.bool,
  isExpandable: PropTypes.bool,
  isSectionExpanded: PropTypes.bool,
  toggleSectionExpanded: PropTypes.func,
  hideLockIcon: PropTypes.bool,
};

LockedSectionTitle.defaultProps = {
  nonTranslatable: false,
  isExpandable: false,
  isSectionExpanded: false,
};

export default LockedSectionTitle;
