import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { getInspectionDetails } from '../../../app/inspections/actions/inspection-actions';
import InspectionRenderer from '../../../app/inspections/components/left-toolbar/inspection-renderer';
import InspectionView from '../../../app/inspections/potree/components/main-view';

import RenderIf from '../../render-if/components/render-if';
import '../style/show-3d-location-modal.scss';

const Show3DLocationModal = ({ potreeId, elements, cameraPositionCoordinates, geometryCoordinates, getInspectionDetails, inspectionId }) => {
  const parentRef = useRef(null);
  const [viewerInstance, setViewerInstance] = useState(null);

  const createViewerInstance = newInstance => {
    setViewerInstance(newInstance);
  };

  useEffect(() => {
    if (!viewerInstance) {
      return;
    }

    if (cameraPositionCoordinates && geometryCoordinates) {
      viewerInstance.zoomToPosition(
        {
          x: cameraPositionCoordinates[0],
          y: cameraPositionCoordinates[1],
          z: cameraPositionCoordinates[2],
        },
        geometryCoordinates,
        1000
      );
    }
  }, [viewerInstance, cameraPositionCoordinates, geometryCoordinates]);

  useEffect(() => {
    getInspectionDetails(inspectionId);
  }, [getInspectionDetails, inspectionId]);

  return (
    <div className="show-3d-location-modal">
      <InspectionView parentRef={parentRef} isWorkflow={false} potreeId={potreeId} createViewerInstance={createViewerInstance} viewer={viewerInstance} showCameras={false} showScreenToolbox />
      <RenderIf if={viewerInstance}>
        <InspectionRenderer
          deselectAll={() => null}
          selectAll={() => null}
          toggleElement={() => null}
          deselectAllTemp={() => null}
          selectAllTemp={() => null}
          toggleElementTemp={() => null}
          selectElement={() => null}
          elements={elements || []}
          disableMove={true}
          viewer={viewerInstance}
        />
      </RenderIf>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    inspectionDetails: state.inspectionReducer.inspectionDetails,
  };
};

const mapDispatchToProps = dispatch => ({
  getInspectionDetails: inspectionId => dispatch(getInspectionDetails(inspectionId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Show3DLocationModal);
