import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import ImagesDropdownMenu from './images-dropdown-menu';
import Button from '../../../../common/form/components/button';
import BasicImage from '../../../../common/image-components/components/basic-image';
import ImageWithDrawing from '../../../../common/image-components/components/image-with-drawing';
import Icon from '../../../../common/icon/components/icon';
import Helpers from '../../../../common/helpers';
import genericFileImage from '../../../inspections/assets/component-generic-file.svg';
import { AMAZON_IMAGE_SIZES } from '../../../../common/constants';
import { fileFields, imagesValidation } from './constants/constants';

const RenderFiles = (
  { files, noFileText, uploadGroup, preview, openFilesModalData, imageAction, deleteWorkOrderFile, hideDelete, hideUpload, hideFileNameTitle, hideEmptyState, uploadButtonText, uploadIcon, disabled },
  { t }
) => {
  const openFile = url => {
    window.open(url, '_blank').focus();
  };

  if (!hideEmptyState && isEmpty(files) && !disabled) {
    return (
      <div className="files-empty-state">
        <div className="upload-files-container" onClick={() => openFilesModalData(uploadGroup)}>
          <Icon name="paperclip" className="upload-files-icon" active />
          <p className="f-secondary-green bold">{t(noFileText)} </p>
        </div>
        <p className="f-secondary-dark upload-description"> {t('WORK_ORDER_FILES.FILES_DESCRIPTION', { number: imagesValidation.maxFiles, size: imagesValidation.value })}</p>
      </div>
    );
  }

  files = Helpers.mapExternalFilesForModal(files, genericFileImage);

  const getFileDisplay = (file, src) => {
    if (Helpers.isImage(file[fileFields.name])) {
      return <BasicImage hideImageLoader className="image-container__file-image" src={src} alt={`file-item-${file[fileFields.id]}`} />;
    }

    if (Helpers.isPDF(file[fileFields.name])) {
      return <Icon name="pdf-file" className="file-other" />;
    }

    return <Icon name="file-text" className="file-other" />;
  };

  return (
    <>
      {!hideFileNameTitle && <p className="f-secondary-dark work-order-file-name">{t('WORK_ORDER_FILES.UPLOAD_FILE_NAME')}</p>}
      {files.map((item, index) => {
        const src = item && item.src && item.src.getImageSize(AMAZON_IMAGE_SIZES.small.name, item);

        if (preview === 'image-thumbnail') {
          return (
            <div key={index} className="file-container">
              <div className="image-container" onClick={imageAction ? () => imageAction(item, files, index) : null}>
                {item.Drawings ? (
                  <ImageWithDrawing
                    disableZoomAndMove={true}
                    className="image-item__image-container"
                    imgClass="image-thumbnail"
                    imageType={AMAZON_IMAGE_SIZES.small}
                    item={item}
                    drawings={item.Drawings}
                  />
                ) : (
                  <BasicImage src={src} customImageComponent={() => <label style={{ backgroundImage: `url('${src}')` }} />} />
                )}
              </div>

              <ImagesDropdownMenu data={item} deleteFile={() => deleteWorkOrderFile(item, uploadGroup)} />
            </div>
          );
        } else {
          return (
            <div key={index} className={`file-container ${index < files.length - 1 ? 'file-container-with-border' : ''}`}>
              {item.restricted && <div className="dot green"> </div>}
              <div className="image-container" onClick={() => openFile(item[fileFields.url])}>
                {getFileDisplay(item, src)}
              </div>
              <p className="f-primary c-title filename" onClick={() => openFile(item[fileFields.url])}>
                {item[fileFields.name]}
              </p>
              {!hideDelete && !disabled && <Icon name="trash" className={`pointer ${disabled ? 'delete-icon-disabled' : ''}`} onClick={() => deleteWorkOrderFile(item, uploadGroup)} />}
            </div>
          );
        }
      })}
      {!hideUpload && (
        <Button
          className="work-order-upload-btn"
          type="button"
          icon={uploadIcon || 'paperclip'}
          iconProps={{ active: true }}
          variant="gray-outline"
          height="md"
          disabled={(files && files.length >= imagesValidation.maxFiles) || disabled}
          width="lg"
          text={t(uploadButtonText || 'WORK_ORDER_FILES.UPLOAD_FILES')}
          onClick={() => openFilesModalData(uploadGroup)}
        />
      )}
    </>
  );
};

RenderFiles.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default RenderFiles;
