import { findIndex, remove } from 'lodash';

import {
  addIsolationTemplateAtIndex,
  amendIsolationTemplateList,
  removeIsolationTemplateByProp,
  setIsolationTemplateList,
  setSelectedIsolationTemplate,
  updateIsolationSections,
  updateIsolationTemplateByProp,
} from './action-creators';

import isolationTmplActions from '../../../api/isolation-template/actions';
import { additionalFields, questionOptionsFields } from '../../../common/question-components/constants/question-constants';
import { timeToMilliseconds } from '../../../common/section/helpers/section-helpers';
import { getSectionAndQuestionDetails } from '../../checklists-procedures-management/helpers/checklists-procedures-management-helpers';
import { formConstants } from '../components/isolations/constants/constants';
import { mapTemplateSections } from '../helpers/templates-helpers';

export const getIsolationTemplates = (params, successAction = () => null, loadMore, currentLength) => {
  return async dispatch => {
    try {
      const res = await isolationTmplActions.getIsolationTemplates(params);
      const { IsolationTemplates, ...filterParams } = res.data.Data;
      if (loadMore) {
        dispatch(amendIsolationTemplateList(IsolationTemplates));
      } else {
        dispatch(setIsolationTemplateList(IsolationTemplates));
      }

      const isolationsLength = loadMore && currentLength ? currentLength + IsolationTemplates.length : IsolationTemplates.length;
      successAction && successAction(filterParams, isolationsLength);
    } catch (error) {
      console.error(error);
    }
  };
};

export const getIsolationTemplateDetails = (isolation_template_id, successAction) => {
  return async dispatch => {
    try {
      const res = await isolationTmplActions.getIsolationTemplateDetails([{ isolation_template_id }]);
      const { Data } = res.data;
      dispatch(setSelectedIsolationTemplate(Data));

      successAction && successAction(Data);
    } catch (error) {
      console.error(error);
    }
  };
};

export const createIsolationTemplate = (params, successAction) => {
  return async dispatch => {
    try {
      const res = await isolationTmplActions.createIsolationTemplate(params);
      const { Data } = res.data;
      dispatch(setSelectedIsolationTemplate(Data));
      dispatch(addIsolationTemplateAtIndex(Data));

      successAction && successAction(Data);
    } catch (error) {
      console.error(error);
    }
  };
};

export const duplicateIsolationTemplate = (ID, successAction) => {
  return async dispatch => {
    try {
      const res = await isolationTmplActions.duplicateIsolationTemplate({ ID });
      const { Data } = res.data;

      dispatch(addIsolationTemplateAtIndex(Data));

      successAction && successAction(Data);
    } catch (error) {
      console.error(error);
    }
  };
};

export const updateIsolationTemplate = (params, successAction) => {
  return async dispatch => {
    try {
      const res = await isolationTmplActions.updateIsolationTemplate(params);
      const { Data } = res.data;
      dispatch(updateIsolationTemplateByProp(Data));
      dispatch(setSelectedIsolationTemplate(Data));

      successAction && successAction();
    } catch (error) {
      console.error(error);
    }
  };
};

export const updateStatus = (params, successAction) => {
  return async dispatch => {
    try {
      await isolationTmplActions.updateStatus(params);
      dispatch(updateIsolationTemplateByProp(params));

      successAction && successAction();
    } catch (error) {
      console.error(error);
    }
  };
};
export const updateLiveStatus = (params, successAction) => {
  return async dispatch => {
    try {
      await isolationTmplActions.updateStatus(params);
      dispatch(updateIsolationTemplateByProp(params));

      successAction && successAction();
    } catch (error) {
      console.error(error);
    }
  };
};

export const deleteIsolationTemplate = (isolation, successAction) => {
  return async dispatch => {
    try {
      await isolationTmplActions.deleteIsolationTemplate({ ID: isolation[formConstants.fields.id] });

      dispatch(removeIsolationTemplateByProp(isolation));
      successAction && successAction();
    } catch (error) {
      console.error(error);
    }
  };
};

export const getIsolationSections = data => {
  return async dispatch => {
    try {
      dispatch(updateIsolationSections([]));
      const res = await isolationTmplActions.getIsolationTemplateSections(data);
      let sections = res?.data?.Data ? res.data.Data : [];
      const response = await isolationTmplActions.getIsolationTemplateFiles(data.TemplateID);
      const files = response?.data?.Data;
      sections = mapTemplateSections(sections, files);

      dispatch(updateIsolationSections(sections));
    } catch (error) {
      console.error(error);
    }
  };
};

export const getIsolationTemplateFiles = (templateId, sections) => {
  return async dispatch => {
    try {
      const res = await isolationTmplActions.getIsolationTemplateFiles(templateId);
      const files = res?.data?.Data;
      sections = mapTemplateSections(sections, files);

      dispatch(updateIsolationSections(sections));
    } catch (error) {
      console.error(error);
    }
  };
};

export const deleteIsolationTemplateFile = (templateId, fileId, sectionId, sections, callback) => {
  return async dispatch => {
    try {
      await isolationTmplActions.deleteIsolationTemplateFile({ IsolationTemplateID: templateId, FileID: fileId });

      sections = sections.map(section => {
        if (section.ID === sectionId) {
          remove(section.files, { FileID: fileId });
          section.changed = !section.changed;
        }

        return section;
      });

      dispatch(updateIsolationSections(sections));

      if (callback) {
        callback();
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const addIsolationSection = (templateId, sections) => {
  return async dispatch => {
    try {
      const res = await isolationTmplActions.addIsolationTemplateSection({ TemplateID: templateId });
      const section = res?.data?.Data;

      if (section) {
        sections.push(section);
      }

      dispatch(updateIsolationSections(sections));
    } catch (error) {
      console.error(error);
    }
  };
};

export const updateIsolationSection = (data, sections) => {
  return async dispatch => {
    try {
      await isolationTmplActions.updateIsolationTemplateSection(data);

      const index = findIndex(sections, { [formConstants.fields.id]: data[formConstants.fields.id] });

      if (index > -1) {
        const section = Object.assign({}, sections[index]);
        section[formConstants.fields.name] = data[formConstants.fields.name];
        sections[index] = section;

        dispatch(updateIsolationSections(sections));
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const deleteIsolationSection = (sectionId, sections, callback) => {
  return async dispatch => {
    try {
      await isolationTmplActions.deleteIsolationTemplateSection({ SectionID: sectionId });

      remove(sections, { ID: sectionId });

      dispatch(updateIsolationSections(sections));

      if (callback) {
        callback();
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const sortIsolationSections = (templateId, currentIndex, newIndex, sections) => {
  return async dispatch => {
    try {
      const currentSection = Object.assign({}, sections[currentIndex]);
      const newSection = Object.assign({}, sections[newIndex]);

      if (currentSection && newSection) {
        const data = {
          TemplateID: templateId,
          SectionID: currentSection.ID,
          NewPosition: newSection.SectionOrder,
        };

        await isolationTmplActions.sortIsolationTemplateSections(data);

        const currentOrder = currentSection.SectionOrder;
        currentSection.SectionOrder = newSection.SectionOrder;
        newSection.SectionOrder = currentOrder;

        sections.splice(currentIndex, 1, newSection);
        sections.splice(newIndex, 1, currentSection);
      }

      dispatch(updateIsolationSections(sections));
    } catch (e) {
      console.log(e);
    }
  };
};

export const addIsolationSectionQuestion = (sectionId, sections) => {
  return async dispatch => {
    try {
      const res = await isolationTmplActions.addIsolationTemplateSectionQuestion({ SectionId: sectionId });
      const question = res?.data?.Data;

      if (question) {
        const index = findIndex(sections, { ID: sectionId });

        if (index > -1) {
          sections[index].changed = !sections[index].changed;
          if (sections[index].SectionQuestions) {
            sections[index].SectionQuestions.push(question);
          } else {
            sections[index].SectionQuestions = [question];
          }
        }
      }

      dispatch(updateIsolationSections(sections));
    } catch (error) {
      console.error(error);
    }
  };
};

export const deleteIsolationSectionQuestion = (sectionQuestionId, sections, index, callback) => {
  return async dispatch => {
    try {
      await isolationTmplActions.deleteIsolationTemplateSectionQuestion({ SectionQuestionID: sectionQuestionId });

      const data = await getSectionAndQuestionDetails(sectionQuestionId, sections);

      if (data?.question) {
        sections[data.sectionIndex].changed = !sections[data.sectionIndex].changed;
        sections[data.sectionIndex].SectionQuestions.splice(index, 1);

        dispatch(updateIsolationSections(sections));
      }

      if (typeof callback === 'function') {
        callback();
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const sortIsolationSectionQuestions = (sectionId, currentIndex, newIndex, sections) => {
  return async dispatch => {
    try {
      const index = findIndex(sections, { ID: sectionId });

      if (index > -1) {
        const section = Object.assign({}, sections[index]);
        const currentQuestion = Object.assign({}, section.SectionQuestions[currentIndex]);
        const newQuestion = Object.assign({}, section.SectionQuestions[newIndex]);

        if (currentQuestion && newQuestion) {
          const data = {
            SectionQuestionID: currentQuestion.ID,
            NewPosition: newQuestion.QuestionOrder,
          };

          await isolationTmplActions.sortIsolationTemplateSectionQuestions(data);

          const currentOrder = currentQuestion.QuestionOrder;
          currentQuestion.QuestionOrder = newQuestion.QuestionOrder;
          newQuestion.QuestionOrder = currentOrder;

          section.SectionQuestions.splice(currentIndex, 1, newQuestion);
          section.SectionQuestions.splice(newIndex, 1, currentQuestion);
          section.changed = !section.changed;

          sections.splice(index, 1, section);
        }
      }

      dispatch(updateIsolationSections(sections));
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateIsolationSectionQuestion = (question, sections) => {
  return async dispatch => {
    try {
      const data = await getSectionAndQuestionDetails(question.ID, sections);

      if (data?.question) {
        if (typeof question[additionalFields.estimatedTime.name] === 'string') {
          question[additionalFields.estimatedTime.name] = timeToMilliseconds(question[additionalFields.estimatedTime.name]);
        }

        const res = await isolationTmplActions.updateIsolationTemplateSectionQuestion(question);
        const updatedQuestion = res?.data?.Data;

        if (updatedQuestion) {
          sections[data.sectionIndex].SectionQuestions.splice(data.questionIndex, 1, updatedQuestion);
          sections[data.sectionIndex].changed = !sections[data.sectionIndex].changed;
        }

        if (updatedQuestion.QuestionOptions && typeof question.pushFieldArrayItem === 'function') {
          question.pushFieldArrayItem(updatedQuestion.QuestionOptions, data.questionIndex);
        }
      }

      dispatch(updateIsolationSections(sections));
    } catch (e) {
      console.log(e);
    }
  };
};

export const addIsolationSectionQuestionOption = (questionId, sections) => {
  return async dispatch => {
    try {
      const data = await getSectionAndQuestionDetails(questionId, sections);

      if (data?.question) {
        const res = await isolationTmplActions.addIsolationTemplateSectionQuestionOption({ SectionQuestionID: questionId });
        const option = res?.data?.Data;

        sections[data.sectionIndex].changed = !sections[data.sectionIndex].changed;
        data.question.QuestionOptions.push(option);
        sections[data.sectionIndex].SectionQuestions.splice(data.questionIndex, 1, data.question);
      }

      dispatch(updateIsolationSections(sections));
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateIsolationSectionQuestionOption = (values, sections) => {
  return async dispatch => {
    try {
      const data = await getSectionAndQuestionDetails(values.SectionQuestionID, sections);

      if (data.question) {
        await isolationTmplActions.updateIsolationTemplateSectionQuestionOption(values);

        const optionIndex = findIndex(data.question.QuestionOptions, { ID: values.ID });

        if (optionIndex > -1) {
          data.question.QuestionOptions.splice(optionIndex, 1, values);
          sections[data.sectionIndex].SectionQuestions.splice(data.questionIndex, 1, data.question);
        }

        dispatch(updateIsolationSections(sections));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const deleteIsolationSectionQuestionOption = (index, option, sections, callback) => {
  return async dispatch => {
    try {
      if (option) {
        const { sectionQuestionId, id } = questionOptionsFields;
        const data = await getSectionAndQuestionDetails(option[sectionQuestionId.name], sections);

        if (data?.question) {
          await isolationTmplActions.deleteIsolationTemplateSectionQuestionOption({ SectionQuestionID: option[sectionQuestionId.name], SectionQuestionOptionID: option[id.name] });

          sections[data.sectionIndex].changed = !sections[data.sectionIndex].changed;
          data.question.QuestionOptions.splice(index, 1);
          sections[data.sectionIndex].SectionQuestions.splice(data.questionIndex, 1, data.question);
        }
      }

      dispatch(updateIsolationSections(sections));

      if (callback) {
        callback();
      }
    } catch (e) {
      console.log(e);
    }
  };
};
