const fields = {
  projectName: 'ProjectName',
  contactName: 'ContactName',
  selectContact: 'ContactID',
  email: 'ContactEmail',
  phone: 'ContactPhone',
  address: 'Location',
  company: 'CompanyID',
};
const editFields = {
  projectId: 'ProjectID',
  projectName: 'ProjectName',
  selectedContact: 'ContactID',
  address: 'Location',
};

export default {
  fields,
  editFields,
};
