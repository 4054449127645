import actionTypes from '../constants/action-types';
import constants from '../constants/constants';

import { findIndex } from 'lodash';

const usersTableState = { ...constants.usersTableState, tableDataLoading: false };

export const pdfTableReducer = (state = usersTableState, payload) => {
  switch (payload.type) {
    case actionTypes.HANDLE_ACTIVATE_USER_MODAL:
      return { ...state, activateModalData: payload.data };
    case actionTypes.SET_TABLE_DATA:
      return {
        ...state,
        tables: {
          ...state.tables,
          [payload.data.section]: {
            ...state.tables[payload.data.section],
            tableData: payload.data.items,
          },
        },
      };
    case actionTypes.SET_TABLE_PARAMS: {
      return {
        ...state,
        tables: {
          ...state.tables,
          [payload.data.section]: {
            ...state.tables[payload.data.section],
            tableParams: payload.data.items,
          },
        },
      };
    }
    case actionTypes.SET_LOAD_MORE_ITEMS: {
      return {
        ...state,
        tables: {
          ...state.tables,
          [payload.data.section]: {
            ...state.tables[payload.data.section],
            tableData: state.tables[payload.data.section].tableData.concat(payload.data.items),
          },
        },
      };
    }
    case actionTypes.HANDLE_DELETE_ITEM_AT_INDEX: {
      return {
        ...state,
        tables: {
          ...state.tables,
          [payload.data.section]: {
            ...state.tables[payload.data.section],
            tableData: [...state.tables[payload.data.section].tableData.slice(0, payload.data.index), ...state.tables[payload.data.section].tableData.slice(payload.data.index + 1)],
          },
        },
      };
    }
    case actionTypes.HANDLE_UPDATE_ITEM_AT_INDEX: {
      return {
        ...state,
        tables: {
          ...state.tables,
          [payload.data.section]: {
            ...state.tables[payload.data.section],
            tableData: [
              ...state.tables[payload.data.section].tableData.slice(0, payload.data.index),
              { ...state.tables[payload.data.section].tableData[payload.data.index], ...payload.data.item },
              ...state.tables[payload.data.section].tableData.slice(payload.data.index + 1),
            ],
          },
        },
      };
    }
    case actionTypes.HANDLE_ADD_ITEM_AT_INDEX: {
      return {
        ...state,
        tables: {
          ...state.tables,
          [payload.data.section]: {
            ...state.tables[payload.data.section],
            tableData: [
              ...state.tables[payload.data.section].tableData.slice(0, payload.data.index),
              { ...payload.data.item },
              ...state.tables[payload.data.section].tableData.slice(payload.data.index),
            ],
          },
        },
      };
    }
    case actionTypes.HANDLE_UPDATE_ITEM_BY_ID: {
      const index = findIndex(state.tables[payload.data.section].tableData, item => item[constants.fields.id] === payload.data.id);
      if (index < 0) {
        return { ...state };
      }

      return {
        ...state,
        tables: {
          ...state.tables,
          [payload.data.section]: {
            ...state.tables[payload.data.section],
            tableData: [...state.tables[payload.data.section].tableData.slice(0, index), { ...payload.data.item }, ...state.tables[payload.data.section].tableData.slice(index + 1)],
          },
        },
      };
    }
    case actionTypes.HANDLE_CLEAR_TABLE_DATA: {
      return { ...state, ...constants.usersTableState };
    }
    case actionTypes.HANDLE_TABLE_DATA_LOADING: {
      return { ...state, tableDataLoading: payload.data };
    }
    default:
      return state;
  }
};
