import React, { Component } from 'react';
import '../styles/breadcrumb-popover.scss';

const Popover = ({ path, hovered, pageX, pageY }) => {
  return (
    <div className={`popover ${hovered ? 'show' : 'hide'}`} style={{ left: pageX - 100, top: pageY + 10 }}>
      <p>{path}</p>
    </div>
  );
};

class BreadcrumbsPopover extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hovered: false,
      pageX: 0,
      pageY: 0,
    };
  }
  handleHover = e => {
    const { pageX, pageY } = e;
    this.setState({ hovered: true, pageX, pageY });
  };
  handleHoverOut = () => {
    this.setState({ hovered: false });
  };

  render() {
    const { hovered, pageX, pageY } = this.state;
    const { levels, propInPath } = this.props;
    let path = '';
    levels.forEach(level => {
      path = `${path}${level[propInPath]} / `;
    });
    return (
      <>
        <Popover path={path} hovered={hovered} pageX={pageX} pageY={pageY} />
        <p onMouseOver={this.handleHover} onMouseOut={this.handleHoverOut} className="three-dots">
          ...
        </p>
      </>
    );
  }
}

export default BreadcrumbsPopover;
