import React from 'react';
import PropTypes from 'prop-types';

const ToolbarTitle = ({ variant = 'medium', className = '', children, ...rest }, { t }) => {
  if (!children) {
    return null;
  }

  const getVariant = () => {
    switch (variant) {
      case 'small':
        return 'p';
      case 'medium':
        return 'h5';
      case 'large':
        return 'h3';
      default:
        return 'h3';
    }
  };

  const CustomTag = `${getVariant()}`;

  return (
    <CustomTag className={`f-primary ${className}`} {...rest}>
      {t(children)}
    </CustomTag>
  );
};

ToolbarTitle.contextTypes = {
  t: PropTypes.func.isRequired,
};

ToolbarTitle.propTypes = {
  variant: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default ToolbarTitle;
