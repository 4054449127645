export const steps = {
  firstStep: 1,
  secondStep: 2,
  thirdStep: 3,
  fourthStep: 4,
  fifthStep: 5,
  sixthStep: 6,
  seventhStep: 7,
};

export const formConstants = {
  fields: {
    id: 'ID',
    name: 'Name',
    code: 'Code',
    descripton: 'Description',
    dateCreated: 'CreatedAt',
    interval: 'IntervalName',
    startDate: 'StartDate',
    componentsNumber: 'Components',
    importance: 'Importance',
    endDate: 'DueDate',
    intervalId: 'IntervalID',
    intervalNumber: 'IntervalNumber',
    perComponent: 'WorkOrderPerComponent',
    notificationAmount: 'notificationAmount',
    notificationType: 'Type',
    nextMaintenanceTask: 'NextTask',
    inspectionId: 'InspectionID',
    status: 'Status',
    ghostNotifications: 'GhostNotifications',
    ghostComponents: 'GhostComponents',
    ghostChecklists: 'GhostChecklist',
    ghostAssignees: 'GhostAssignees',
    description: 'Description',
    severity: 'Severity',
    dueDate: 'ExpectedDueDate',
    sendEmailWorkOrderAssigned: 'SendEmailWorkOrderAssigned',
    subType: 'SubType',
    toolsRequired: 'ToolsRequired',
    estimatedWrenchTime: 'EstimatedWrenchTime',
    icon: 'Icon',
    checklistAndProcedureTemplates: 'ChecklistAndProcedureTemplates',
    additionalSubType: 'AdditionalSubType',
    critical: 'Critical',
  },
};

export const componentFields = {
  id: 'ID',
  name: 'Name',
  compID: 'ComponentID',
  clusterType: 'Cluster',
};

export const notificationFields = {
  id: 'ID',
  name: 'Name',
  notifID: 'NotificationID',
};

export const assigneeFields = {
  id: 'ID',
  name: 'Name',
  type: 'Type',
  email: 'Email',
  status: 'Status',
  company: 'Company',
  participantType: 'ParticipantType',
  assigneeType: 'AssigneeType',
};

export const checklistFields = {
  name: 'Name',
  id: 'ID',
  isSubmitted: 'IsSubmitted',
  properties: 'Properties',
  questionsAnswered: 'QuestionsAnswered',
  questionsTotal: 'QuestionsAnswered',
  sections: 'Sections',
  workOrderChecklistTemplateID: 'WorkOrderChecklistTemplateID',
  isLinkedToComponent: 'isLinkedToComponent',
};

export const intervalFields = {
  name: 'Name',
  code: 'Code',
  id: 'ID',
};

export const textAreaMaxChars = 2000;
export const intervalListMaxValue = 60;
export const workOrderNameMaxChars = 150;

export const fileTypes = {
  noTypeFiles: 'NO_TYPE',
  workOrderFiles: 'WORK_ORDER_COMPLETION_FILES',
};

export const WOFileGroups = {
  otherFiles: 'OTHER_FILES',
  images: 'IMAGES',
  rams: 'RAMS',
  permits: 'PERMITS',
  statusFiles: 'STATUS_FILES',
  statusImages: 'STATUS_IMAGES',
  checklistFiles: 'CHECKLIST_FILES',
  otherImages: 'OTHER_IMAGES',
};

export const WOUploadType = 'WORK_ORDER_FILES';

export const fileFields = {
  id: 'FileID',
  name: 'FileName',
  url: 'URL',
};

export const validationTypes = {
  notifications: 'notification',
  images: 'images',
};

export const notificationValidation = {
  limit: 10,
  type: validationTypes.notifications,
};

export const imagesValidation = {
  maxFiles: 5,
  value: 50,
  unit: 'mb',
  bytes: 52428800,
};

export const WOassetsMimeTypes = {
  [WOFileGroups.images]: 'image/jpeg, image/png, image/svg+xml, image/tiff, image/gif',
};

export const checklistStatuses = {
  new: 'NEW',
  inProgress: 'UNCOMPLETED',
  completed: 'COMPLETED',
};

export const workOrderSubTypes = {
  planned: 'PLANNED',
  unPlanned: 'UNPLANNED',
};

export const componentDetailsDisplayProps = [
  { name: 'ID' },
  {
    name: 'Name',
  },
  {
    name: 'Code',
  },
  {
    name: 'Type',
  },
  {
    name: 'SubType',
  },
  {
    name: 'AdditionalSubType',
  },
];

export const userDetailsDisplayProps = [
  { name: 'ID' },
  {
    name: 'Company',
  },
  {
    name: 'Email',
  },
  {
    name: 'Phone',
  },
  {
    name: 'PermitTestExpirationDate',
  },
];

export const permitDetailsDisplayProps = [
  { name: 'ID' },
  {
    name: 'Name',
  },
  {
    name: 'TemplateName',
  },
  {
    name: 'StartDate',
    type: 'date',
  },
  {
    name: 'EndDate',
    type: 'date',
  },
];

export const workAreaDetailsDisplayProps = [
  { name: 'ID' },
  {
    name: 'Name',
  },
  {
    name: 'Colour',
  },
];

export const intervalTypes = ['HOUR', 'DAY', 'WEEK', 'MONTH'];

export const workAreaTableConfig = [
  { title: 'NAME', key: formConstants.fields.name, type: formConstants.fields.name, class: 'full-width' },
  { type: 'focus', key: 'focus', class: 'focus-icon' },
];
export const componentsTableConfig = [
  { title: 'CODE', key: formConstants.fields.code, type: formConstants.fields.code, class: 'component-name' },
  { title: 'NAME', key: formConstants.fields.name, type: formConstants.fields.name },
  { title: '', key: formConstants.fields.critical, type: formConstants.fields.critical, class: 'critical-icon' },
];
export const permitsTableConfig = [
  { title: '', key: formConstants.fields.status, type: formConstants.fields.status, class: 'permit-status-icon' },
  { title: 'ID', key: formConstants.fields.id, type: formConstants.fields.id, class: 'permit-id' },
  { title: 'NAME', key: formConstants.fields.name, type: formConstants.fields.name },
];

export const observationTableConfig = [
  { title: '', key: formConstants.fields.status, type: formConstants.fields.icon, class: 'observation-status-icon' },
  { title: 'ID', key: formConstants.fields.id, type: formConstants.fields.id, class: 'observation-id' },
  { title: 'NAME', key: formConstants.fields.name, type: formConstants.fields.name, class: 'observation-name' },
];
