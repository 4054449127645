import { areaGroupConstants, formConstants } from '../../../constants/areas-constants';

export const validateArea = values => {
  const errors = {};

  if (!values[formConstants.fields.name]) {
    errors[formConstants.fields.name] = 'AREA_GROUP.FORM_ERROR.NAME_REQUIRED';
  }

  return errors;
};

export const validateGroup = values => {
  const errors = {};

  if (!values[areaGroupConstants.fields.name]) {
    errors[areaGroupConstants.fields.name] = { string: 'AREA_GROUP.FORM_ERROR.NAME_REQUIRED' };
  }

  return errors;
};
