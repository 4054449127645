import { debounce, find, isArray, isEmpty, keys, reduce, values } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import DisplayStatus from '../../../../common/display-status/components/display-status';
import SimpleLoader from '../../../../common/global-loader/components/simple-loader';
import Helpers from '../../../../common/helpers';
import { notificationModalCustom } from '../../../../common/modal/actions/modal-actions';
import Modal from '../../../../common/modal/components/modal';
import StatusChangeNoteModal from '../../../../common/modal/components/status-change-note-modal';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../common/permissions-constants';
import { ComponentPicker3D, ComponentPickerPDF } from '../../../../common/question-components/components';
import RenderIf from '../../../../common/render-if/components/render-if';
import { routes } from '../../../../common/routes-constants';
import StatusesFilter from '../../../../common/statuses-filter/components/statuses-filter';
import Tab from '../../../../common/tabs/component/tab';
import Tabs from '../../../../common/tabs/component/tabs';
import { setRegimeWorkOrderFilters, setWorkOrderPagingRegime } from '../../../inspections/actions/action-creators';
import RegimeWorkordersFilter from '../../../inspections/components/left-toolbar/filters/regime-workorders-filter';
import InspectionRenderer from '../../../inspections/components/left-toolbar/inspection-renderer';
import ChangeStatusDropdown from '../../../inspections/components/right-toolbar/common/change-status-dropdown';
import CriticalEquipmentInfo from '../../../inspections/components/right-toolbar/common/critical-equipment-info';
import ModuleHeader from '../../../inspections/components/right-toolbar/common/module-header';
import { getSelectedStatuses } from '../../../inspections/components/work-order/helpers/work-order-helpers';
import { modules } from '../../../inspections/constants/constants';
import { defaultQuickFilterRegimeTasks, filterFields, sortDirections } from '../../../inspections/constants/work-order-constants';
import '../../../inspections/styles/work-order-right-side.scss';
import { params } from '../../../profile/constants/profile-constants';
import { changeStatus, getRegimeChecklists, getRegimeComponents, getRegimeDetails, getRegimeFiles, getRegimeTasks, updateRegime } from '../../actions/regime-actions';
import { tabNames, toolbarItems } from '../../constants/maintenance-regime-right-side-constants';
import { confirmationModalTypes, defaultPagingObjRegime, defaultSortingFilterRegime, fileGroups, formConstants, regimeWOstatuses, statuses, steps } from '../../constants/regime-constants';
import { regimeValidate } from '../../validators/regime-validators';
import BasicDetailsForm from './basic-details-form';
import MaintenanceRegimeSections from './maintenance-regime-sections';
import MaintenanceRegimeTasks from './maintenance-regime-tasks';

class MaintenanceRegimeRightSide extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeToolbarItem: toolbarItems[tabNames.details].name,
      maintenanceRegimeDetails: {},
      components: [],
      editFilesModalData: {
        isOpen: false,
      },
      uploadModalData: {
        isOpen: false,
      },
      confirmModalData: {
        isOpen: false,
      },
      isDownloadInProgress: false,
      changeStatusConfirmModal: {
        isOpen: false,
      },
      changeStatusInProgress: false,
      completedStatusModal: {
        isOpen: false,
      },
      componentSelectionModal: {
        isOpen: false,
      },
      messageModalData: {
        isOpen: false,
      },
      statusChangeNoteModalData: {
        isOpen: false,
      },
      advancedFilter: {},
      sortingFilter: defaultSortingFilterRegime,
    };

    this.handleBasicDetailsChangeDebounced = debounce(this.handleBasicDetailsChange, 600);
    this.getRegimeTasksDebounced = debounce(this.fetchRegimeTasks, 250);
  }

  componentDidMount = () => {
    this.fetchInitialData();
    const { queryItem } = this.props;
    let params = {};

    if (queryItem) {
      params = {
        ...params,
        [filterFields.regimeId]: queryItem,
        [filterFields.perPage]: 10,
      };
      this.getRegimeTasksDebounced(params);
    }
  };

  componentDidUpdate = prevProps => {
    const { queryItem, closeRegimeModalTriggered, setRegimeWorkOrderFilters } = this.props;

    if ((prevProps.queryItem !== queryItem && queryItem) || prevProps.closeRegimeModalTriggered !== closeRegimeModalTriggered) {
      this.fetchInitialData();
      let params = {};
      params = {
        ...params,
        [filterFields.regimeId]: queryItem,
        [filterFields.perPage]: 10,
      };
      // reset advanced filter for tasks
      this.setState({
        advancedFilter: {},
        pagingObject: defaultPagingObjRegime,
      });
      const filters = Object.assign({}, defaultQuickFilterRegimeTasks);
      if (filters && filters[filterFields.startInNextWeekFilter]) filters[filterFields.startInNextWeekFilter] = false;
      if (filters && filters[filterFields.onHold]) filters[filterFields.onHold] = false;
      if (filters && filters[filterFields.assignedToMeFilter]) filters[filterFields.assignedToMeFilter] = false;
      if (filters && filters[filterFields.archived]) filters[filterFields.archived] = false;
      if (filters && filters[filterFields.overdueFilter]) filters[filterFields.overdueFilter] = false;
      if (filters && filters[filterFields.statusFilter]) filters[filterFields.statusFilter] = '';
      setRegimeWorkOrderFilters(filters);
      this.getRegimeTasksDebounced(params);
    }
  };

  fetchInitialData = () => {
    const { queryItem, getRegimeDetails, getRegimeComponents, getRegimeChecklists, getRegimeFiles } = this.props;

    if (queryItem) {
      getRegimeDetails(queryItem, maintenanceRegimeDetails => {
        this.setState({ maintenanceRegimeDetails });
      });

      getRegimeComponents(queryItem);
      getRegimeChecklists(queryItem);
      getRegimeFiles(queryItem, fileGroups.otherFiles);
      this.getRegimeTasksDebounced();
    }
  };

  fetchRegimeTasks = (params, loadMore = false) => {
    const { advancedFilter, sortingFilter } = this.state;
    const { getRegimeTasks, regimeFilters, pagingObjectRegime, setWorkOrderPagingRegime, queryItem } = this.props;

    //normalize advanced filter
    const newAdvancedFilter = reduce(
      advancedFilter,
      (obj, it, key) => {
        if ([filterFields.startFrom, filterFields.endFrom, filterFields.dueFrom].indexOf(key) > -1) {
          obj[key] = (advancedFilter[key] && Helpers.getUnixDate(new Date(advancedFilter[key]).getTime())) || null;
        } else if ([filterFields.startTo, filterFields.endTo, filterFields.dueTo].indexOf(key) > -1) {
          // sets end/to dates to end of day 23:59:59 to include that day in filter results
          obj[key] = (advancedFilter[key] && Helpers.getUnixDate(new Date(advancedFilter[key]).setHours(23, 59, 59))) || null;
        } else {
          obj[key] = advancedFilter[key];
        }
        return obj;
      },
      {}
    );

    /* Checking if the advancedFilter is empty, if it is, it deletes the startDateFrom and startDateTo filters. */
    if (isEmpty(advancedFilter)) {
      delete regimeFilters[filterFields.startDateFrom];
      delete regimeFilters[filterFields.startDateTo];
    }
    /* Checking if the advancedFilter is empty and if the startDateFrom and startDateTo are not in the
    advancedFilter. If they are not in the advancedFilter, then it deletes them from the filters. */
    if (!isEmpty(advancedFilter) && !advancedFilter[filterFields.startDateFrom] && regimeFilters[filterFields.startDateFrom]) {
      delete regimeFilters[filterFields.startDateFrom];
    }
    if (!isEmpty(advancedFilter) && !advancedFilter[filterFields.startDateTo] && regimeFilters[filterFields.startDateTo]) {
      delete regimeFilters[filterFields.startDateTo];
    }

    getRegimeTasks({ RegimeID: queryItem, ...regimeFilters, ...newAdvancedFilter, ...pagingObjectRegime, ...sortingFilter, ...params }, loadMore, (arr, incomingFilter, c) => {
      setWorkOrderPagingRegime({ ...pagingObjectRegime, [filterFields.totalItems]: incomingFilter[filterFields.totalItems], [filterFields.hasNext]: incomingFilter[filterFields.hasNext] });

      if (loadMore) {
        Helpers.scrollIntoView('table-container', `row-${arr?.length - 1}`);
      }
    });
  };

  loadMore = () => {
    const { regimeTasks, pagingObjectRegime, setWorkOrderPagingRegime } = this.props;
    setWorkOrderPagingRegime({
      ...pagingObjectRegime,
      [filterFields.lastSeen]: regimeTasks.length || 0,
    });

    this.getRegimeTasksDebounced(null, true);
  };

  handleMaximize = () => {};

  handleDownload = () => {};

  handleShare = () => {};

  handleBasicDetailsChange = (values, c) => {
    if (!c.pristine) {
      const errors = regimeValidate(values);

      if (!isEmpty(errors)) {
        if (typeof c.touch === 'function') {
          c.touch(formConstants.fields.startDate);
          c.touch(formConstants.fields.endDate);
          c.touch(formConstants.fields.dueDate);
        }

        Helpers.scrollToFirstError(errors);

        return;
      }

      const { updateRegime, inspectionId } = this.props;
      if (typeof values[formConstants.fields.intervalTaskName] === 'object') {
        values[formConstants.fields.intervalTaskName] = values[formConstants.fields.intervalTaskName].Name;
      }

      updateRegime(
        {
          ...values,
          InspectionID: inspectionId,
          CreatedBy: values[formConstants.fields.createdByUser].toString(),
          [formConstants.fields.dueDate]: values[formConstants.fields.dueDate] ? Helpers.getUnixDate(new Date(values[formConstants.fields.dueDate] * 1000).getTime()) : null,
          [formConstants.fields.startDate]: values[formConstants.fields.startDate] ? Helpers.getUnixDate(new Date(values[formConstants.fields.startDate] * 1000).getTime()) : null,
          [formConstants.fields.endDate]: values[formConstants.fields.endDate] ? Helpers.getUnixDate(new Date(values[formConstants.fields.endDate] * 1000).getTime()) : null,
        },
        () => {
          const { maintenanceRegimeDetails } = this.state;
          const details = { ...maintenanceRegimeDetails, ...values, CreatedBy: values.CreatedBy.toString() };

          this.setState({ maintenanceRegimeDetails: details });
        }
      );
    }
  };

  handleSearchComponents = searchText => {
    const { inspectionId, searchComponents } = this.props;

    searchComponents(inspectionId, searchText || '', components => this.setState({ components }), { PerPage: Number.MAX_SAFE_INTEGER, LastSeen: 0 });
  };

  handleComponentPicker3D = () => {
    const { inspectionId } = this.props;
    const closeAction = () => {
      this.setState({ componentSelectionModal: { isOpen: false } });
    };

    this.setState({
      componentSelectionModal: {
        isOpen: true,
        CustomContent: dynamicProps => (
          <ComponentPicker3D
            {...dynamicProps}
            handleComponentSelected={(_moduleId, components) => this.handleAddWorkOrderComponent(components)}
            handleDeleteComponent={(_moduleId, componentId) => this.handleRemoveWorkOrderComponent(componentId)}
            inspectionId={inspectionId}
            closeAction={closeAction}
          />
        ),
        customClassName: 'modal-no-max-height modal-large',
        type: 'none',
        closeAction,
      },
    });
  };

  handleComponentPickerPDF = () => {
    const { inspectionId } = this.props;
    const closeAction = () => {
      this.setState({ componentSelectionModal: { isOpen: false } });
    };

    this.setState({
      componentSelectionModal: {
        isOpen: true,
        CustomContent: dynamicProps => (
          <ComponentPickerPDF
            {...dynamicProps}
            handleComponentSelected={(_moduleId, components) => this.handleAddWorkOrderComponent(components)}
            handleDeleteComponent={(_moduleId, componentId) => this.handleRemoveWorkOrderComponent(componentId)}
            inspectionId={inspectionId}
            closeAction={closeAction}
          />
        ),
        customClassName: 'modal-no-max-height modal-large',
        type: 'none',
        closeAction,
      },
    });
  };

  handleSectionAction = (sectionAction, props, cb) => {
    let requestedStep;

    // TODO: improve handling of clicked section by title
    if (cb && cb.title === 'MAINTENANCE_REGIME.FILES_SECTION') {
      // files section clicked, provide third step
      requestedStep = steps.thirdStep;
    } else if (cb && cb.title === 'MAINTENANCE_REGIME.COMPONENTS_SECTION') {
      // components section clicked, provide fourth step
      requestedStep = steps.fourthStep;
    } else if (cb && cb.title === 'MAINTENANCE_REGIME.SCHEDULER_SECTION') {
      // scheduler section clicked, provide fifth step
      requestedStep = steps.sixthStep;
    }

    // if section action is editing a section then provide the step to open in regime form stepper
    // false parameter is to set the previewMode to false
    const action = this[sectionAction] || this.props[sectionAction](props, false, requestedStep);

    if (typeof action === 'function') {
      action(...Object.values(props || {}));
      if (cb && typeof cb === 'function') {
        // cleans up the comment textarea input field
        cb(true);
      }
    }
  };

  basicDetailsDisabled = () => {
    const { maintenanceRegimeDetails } = this.state;

    return [statuses.live.value].indexOf(maintenanceRegimeDetails[formConstants.fields.status]) > -1;
  };

  handleQuickFilter = values => {
    const { setWorkOrderPagingRegime, regimeFilters } = this.props;
    const filters = Object.assign({}, regimeFilters);

    if (isArray(values)) {
      filters[filterFields.statusFilter] = values.length ? values.toString() : '';
    } else {
      filters[values] = !filters[values];
    }

    setWorkOrderPagingRegime(defaultPagingObjRegime);

    this.getRegimeTasksDebounced(filters);
  };

  onColumnSort = column => {
    const { sortingFilter } = this.state;
    const { setWorkOrderPagingRegime } = this.props;

    this.setState({
      sortingFilter: {
        [filterFields.sortByColumn]: column,
        [filterFields.sortDirection]: sortingFilter[filterFields.sortDirection] === sortDirections.asc ? sortDirections.desc : sortDirections.asc,
      },
    });

    setWorkOrderPagingRegime(defaultPagingObjRegime);

    this.getRegimeTasksDebounced();
  };

  formatCell = (value, type, index, item) => {
    if ([formConstants.fields.dueDate].indexOf(type) > -1) {
      return !isNaN(value) && value > 0 ? Helpers.getDateFromUnix(value, 'DD/MM/YY') : '-';
    }

    if (type === formConstants.fields.nameLite) {
      return (
        <div className="work-order-priority">
          <p className="f-primary work-order-name ">{value || '-'}</p>
        </div>
      );
    } else if (type === formConstants.fields.dateShort) {
      return !isNaN(value) && value > 0 ? Helpers.getDateFromUnix(value, 'DD MMM, YYYY') : '-';
    } else if (type === formConstants.fields.statusText) {
      return <DisplayStatus statuses={values(regimeWOstatuses)} status={value} displayTitle={false} />;
    }
    return value || '-';
  };

  getOnHoldActionPermission = () => {
    const { maintenanceRegimeDetails } = this.state;
    const workOrderStatus = values(statuses);

    const status = find(workOrderStatus, { value: maintenanceRegimeDetails[formConstants.fields.status] });

    return status?.editPermission || '';
  };

  handleOnHoldAction = () => {
    const { maintenanceRegimeDetails } = this.state;
    const { changeWorkOrderOnHold, queryItem } = this.props;
    const workOrder = Object.assign({}, maintenanceRegimeDetails);

    const onDone = isSuccess => {
      if (isSuccess) {
        workOrder[formConstants.fields.onHold] = !workOrder[formConstants.fields.onHold];
      }

      this.setState({ confirmModalData: { isOpen: false }, maintenanceRegimeDetails: workOrder });
    };

    changeWorkOrderOnHold({ [formConstants.fields.workOrderID]: queryItem, [formConstants.fields.onHold]: !maintenanceRegimeDetails[formConstants.fields.onHold] }, onDone);
  };

  openAdvancedFilter = () => {
    const { t } = this.context;
    const { setModalState, projectId, setRegimeWorkOrderFilters } = this.props;
    const { advancedFilter } = this.state;
    const closeAction = () => setModalState({ isOpen: false });

    const modalData = {
      isOpen: true,
      type: 'none',
      title: t('ADVANCED_FILTERS.TITLE'),
      CustomContent: () => (
        <RegimeWorkordersFilter
          submitForm={values => {
            this.setState({
              advancedFilter: values,
              pagingObject: defaultPagingObjRegime,
            });
            closeAction();
            this.getRegimeTasksDebounced();
          }}
          resetFilter={() => {
            this.setState({
              advancedFilter: {},
              pagingObject: defaultPagingObjRegime,
            });
            closeAction();
            setRegimeWorkOrderFilters(defaultQuickFilterRegimeTasks);
            this.getRegimeTasksDebounced();
          }}
          initialValues={advancedFilter}
          closeAction={closeAction}
          projectId={projectId}
        />
      ),
      customClassName: 'modal-large defects-filter',
      closeAction,
    };

    setModalState(modalData);
  };

  render() {
    const {
      activeToolbarItem,
      maintenanceRegimeDetails,
      uploadModalData,
      confirmModalData,
      isDownloadInProgress,
      changeStatusConfirmModal,
      changeStatusInProgress,
      completedStatusModal,
      messageModalData,
      statusChangeNoteModalData,
      advancedFilter,
      sortingFilter,
    } = this.state;
    const {
      queryItem,
      intervalList,
      regimeFiles,
      regimeComponents,
      severityColors,
      workorderAreas,
      user,
      regimeTasks,
      pagingObjectRegime,
      regimeTasksLoading,
      projectId,
      inspectionId,
      toggleConfirmationModal,
      maintenanceRegimeDetailsLoading,
      regimeFilters,
      viewer,
      regimeChecklists,
      openFillInChecklistModalData,
      additionalWorkorderTypes,
    } = this.props;
    const { t } = this.context;

    const menuOptions = [
      {
        title: 'MAINTENANCE_REGIME.DELETE',
        access: {
          visibleFor: PERMISSIONS[PERMISSION_TYPES.maintenanceRegimes].delete.name,
        },
        separator: true,
        isHighlighted: true,
        action: () =>
          toggleConfirmationModal(
            true,
            confirmationModalTypes.delete,
            { [formConstants.fields.id]: maintenanceRegimeDetails[formConstants.fields.id] },
            { name: maintenanceRegimeDetails[formConstants.fields.title] },
            () => toggleConfirmationModal(false)
          ),
      },
    ];

    const containsCriticalEquipment = regimeComponents && regimeComponents.some(component => component[formConstants.fields.critical]);

    return (
      <div className={`maintenance-regime-right-side work-order-right-side ${maintenanceRegimeDetailsLoading ? 'loading' : ''}`}>
        <InspectionRenderer
          deselectAll={() => null}
          selectAll={() => null}
          toggleElement={() => null}
          deselectAllTemp={() => null}
          selectAllTemp={() => null}
          toggleElementTemp={() => null}
          selectElement={() => null}
          updateElementGeometry={() => null}
          selectedDefect={null}
          elements={(regimeComponents || []).concat(workorderAreas || []).map(component => ({
            ...component,
            visible: true,
          }))}
          disableMove={true}
          viewer={viewer}
        />
        <RenderIf if={maintenanceRegimeDetailsLoading}>
          <SimpleLoader isLoading={true} className="loader" />
        </RenderIf>
        <RenderIf if={!maintenanceRegimeDetailsLoading}>
          <Tabs
            defaultTabKey={activeToolbarItem}
            navigationClassName="tabs"
            onChange={tabkey => {
              this.setState({ activeToolbarItem: tabkey });
            }}
            tabsHeader={
              <ModuleHeader
                id={queryItem}
                handleMaximize={this.handleMaximize}
                handleDownload={this.handleDownload}
                handleShare={this.handleShare}
                menuOptions={maintenanceRegimeDetails[formConstants.fields.status] === statuses.live.value ? [] : menuOptions}
                isDownloadDisabled={isDownloadInProgress}
                hideMaximize
                hideShare
                hideDownload
              />
            }
          >
            <Tab title={toolbarItems[tabNames.details].label} tabKey={tabNames.details}>
              <RenderIf if={containsCriticalEquipment}>
                <CriticalEquipmentInfo title={'CRITICAL_EQUIPMENT.TITLE'} paragraph={'CRITICAL_EQUIPMENT_MAINTENANCE_REGIME.PARAGRAPH'} className="maintenance-regime-critical-eq" />
              </RenderIf>
              <div className="work-order-right-side-content">
                <ChangeStatusDropdown
                  statuses={statuses}
                  module={Object.assign({}, maintenanceRegimeDetails)}
                  user={user}
                  handleChangeStatus={data =>
                    toggleConfirmationModal(
                      true,
                      confirmationModalTypes.changeStatus,
                      { [formConstants.fields.id]: maintenanceRegimeDetails[formConstants.fields.id], [formConstants.fields.status]: data[formConstants.fields.status] },
                      { name: maintenanceRegimeDetails[formConstants.fields.title], ...{ status: t(data[formConstants.fields.status]) } },
                      () => this.fetchInitialData()
                    )
                  }
                  createdById={maintenanceRegimeDetails[formConstants.fields.createdBy]}
                />
                <BasicDetailsForm
                  initialValues={Object.assign({}, maintenanceRegimeDetails)}
                  severityColors={severityColors}
                  disabled={this.basicDetailsDisabled()}
                  onChange={(values, _b, c) => this.handleBasicDetailsChangeDebounced(values, c)}
                  intervalList={intervalList}
                  additionalWorkorderTypes={additionalWorkorderTypes}
                />
                <MaintenanceRegimeSections
                  moduleDetails={{
                    ...maintenanceRegimeDetails,
                    ...{
                      maintenanceRegimeComponents: regimeComponents,
                      openFillInChecklistModalData,
                      workOrderCPTemplates: regimeChecklists,
                      maintenanceRegimeFiles: regimeFiles,
                    },
                  }}
                  handleSectionActionClicked={this.handleSectionAction}
                  user={user}
                />
              </div>
            </Tab>
            <Tab title={toolbarItems[tabNames.pmTasks].label} tabKey={tabNames.pmTasks}>
              <div className="work-order-right-side-content">
                <div className="status-filters">
                  <div className="d-flex justify-content-between">
                    <p className="f-secondary-dark status-filters-title bold">{t('PERMITS.FILTERS_TITLE')}</p>
                    <p className="f-secondary-green noselect pointer link" onClick={this.openAdvancedFilter}>
                      {t('ADVANCED_FILTER', { active: keys(advancedFilter).length })}
                    </p>
                  </div>
                  <StatusesFilter
                    statuses={values(regimeWOstatuses)}
                    selected={getSelectedStatuses(regimeFilters)}
                    onChange={this.handleQuickFilter}
                    isAdjustedForSmallerScreen={true}
                    isLoading={regimeTasksLoading}
                  />
                </div>
                <MaintenanceRegimeTasks
                  moduleDetails={{
                    ...maintenanceRegimeDetails,
                    ...{ maintenanceRegimeTasks: regimeTasks },
                    ...{ sortingFilter: sortingFilter },
                    ...{ pagingObject: pagingObjectRegime },
                  }}
                  onColumnSort={this.onColumnSort}
                  formatCell={this.formatCell}
                  onRowClick={(e, item) => {
                    Helpers.goTo(user[params.fullScreenEnabled] ? routes.protectedRoutes.fullScreen.fullPath : routes.protectedRoutes.inspections.fullPath, [
                      { project_id: projectId },
                      { inspection_id: inspectionId },
                      { type: modules.workorders },
                      { selected_item: item[formConstants.fields.id] },
                    ]);
                  }}
                  translationModule={t}
                  loadMore={this.loadMore}
                  isLoading={regimeTasksLoading}
                  projectId={projectId}
                  inspectionId={inspectionId}
                />
              </div>
            </Tab>
          </Tabs>
        </RenderIf>
        <Modal {...uploadModalData} />
        <Modal {...confirmModalData} />
        <Modal {...changeStatusConfirmModal} modalDisabled={changeStatusInProgress} />
        <Modal {...completedStatusModal} modalDisabled={changeStatusInProgress} changeStatusInProgress={changeStatusInProgress} />
        <Modal {...messageModalData} />
        <StatusChangeNoteModal {...statusChangeNoteModalData} />
      </div>
    );
  }
}

MaintenanceRegimeRightSide.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    severityColors: state.themeReducer.severityColors,
    maintenanceRegimeDetailsLoading: state.regimeReducer.maintenanceRegimeDetailsLoading,
    user: state.userReducer,
    regimeFilters: state.regimeReducer.regimeFilters,
    pagingObjectRegime: state.regimeReducer.pagingObjectRegime,
    workOrders: state.workOrderReducer.workOrders,
    regimeTasks: state.regimeReducer.regimeTasks,
    regimeTasksLoading: state.regimeReducer.regimeTasksLoading,
    viewer: state.potreeReducer.viewerInstance,
  };
};

const mapDispatchToProps = dispatch => ({
  getRegimeDetails: (data, includeLoader, callback) => dispatch(getRegimeDetails(data, includeLoader, callback)),
  updateRegime: (data, callback) => dispatch(updateRegime(data, callback)),
  getRegimeComponents: id => dispatch(getRegimeComponents(id)),
  getRegimeChecklists: id => dispatch(getRegimeChecklists(id)),
  getRegimeFiles: (MaintenanceRegimeID, group) => dispatch(getRegimeFiles(MaintenanceRegimeID, group)),
  notificationModalCustom: (errorMessage, title) => dispatch(notificationModalCustom(true, errorMessage, title)),
  changeStatus: (data, filters, workOrders, callback) => dispatch(changeStatus(data, filters, workOrders, callback)),
  getRegimeTasks: (data, loadMore, callback) => dispatch(getRegimeTasks(data, loadMore, callback)),
  setWorkOrderPagingRegime: pagingObjectRegime => dispatch(setWorkOrderPagingRegime(pagingObjectRegime)),
  setRegimeWorkOrderFilters: filters => dispatch(setRegimeWorkOrderFilters(filters)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MaintenanceRegimeRightSide));
