import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Helpers from '../../../common/helpers';
import { isEmpty, find } from 'lodash';

import Button from '../../../common/form/components/button';
import SearchSelect from '../../inspections/components/common/input/search-select';
import AccessRenderer from '../../../common/access-renderer/components/access-renderer';
import { Field, reduxForm } from 'redux-form';
import { componentFields, detailsKeys } from '../constants/constants';

import { ReactComponent as EmptyIcon } from '../../../common/assets/electrician.svg';

import { PERMISSIONS, PERMISSION_TYPES } from '../../../common/permissions-constants';
import '../style/pick-component-screen.scss';

class PickComponentScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pickedComponent: null,
    };
    this.node = React.createRef();
  }

  componentWillUnmount = () => {
    const { setComponentDetails } = this.props;
    const { current } = this.node;
    if (current) {
      current.removeEventListener('keydown', this.enterFunction);
    }
    setComponentDetails();
  };

  componentDidMount = () => {
    const { fetchSuggestions } = this.props;
    fetchSuggestions('');
    const { current } = this.node;
    current.addEventListener('keydown', this.enterFunction, false);
  };

  enterFunction = e => {
    //if enter is pressed submit the form
    if (e.keyCode === 13) {
      const { current } = this.node;
      e.preventDefault();

      const button = find(current.children, item => {
        if (item && item.type === 'submit') {
          return item;
        }
      });
      button && button.click();
    }
  };

  render() {
    const { t } = this.context;
    const { components, fetchSuggestions, handleMergeCvWithNewComponent, handleMergeCvWithExistingComponent, notInThisPdfClick, component, setComponentDetails, componentDetails } = this.props;
    const { pickedComponent } = this.state;
    const componentsData = (components || []).map(el => ({ label: el[componentFields.name], value: el[componentFields.componentId] }));

    return (
      <div className="pick-component-screen">
        <div className="inline-wrapper component-name">
          <h4 className="f-primary">{component[componentFields.name]}</h4>
        </div>

        <AccessRenderer visibleFor={[PERMISSIONS[PERMISSION_TYPES.components].create.name, PERMISSIONS[PERMISSION_TYPES.smartDrawing].manipulate.name]} operator="AND">
          {({ hasAccess }) => <Button text={t('PDF_TAG.LEFT.CREATE_COMPONENT')} disabled={!hasAccess} onClick={handleMergeCvWithNewComponent} />}
        </AccessRenderer>
        <p className="f-primary or">{t('PDF_TAG.LEFT.OR')}</p>
        <div className="inline-wrapper">
          <form ref={this.node} noValidate>
            <Field
              id={'component'}
              disabled={false}
              name={'component'}
              component={SearchSelect}
              handleChange={comp => {
                this.setState({ pickedComponent: comp });
                setComponentDetails(comp.value);
              }}
              suggestions={componentsData}
              fetchSuggestions={searchText => fetchSuggestions(searchText)}
              clearSuggestions={() => null}
              className="inline-wrapper__left-content"
              placeholder={t('DEFECT_DETAILS.COMPONENT_PLACEHOLDER')}
              label={'DEFECT_DETAILS.COMPONENT'}
              labelClass="f-secondary-dark inline-wrapper__left-content"
              type="text"
            />
          </form>
        </div>
        <div className="details-box inline-wrapper">
          {!isEmpty(componentDetails) ? (
            <div className="details-items">
              {detailsKeys.map(key => {
                return (
                  <Fragment key={key}>
                    <p className="f-secondary-dark">{t(`PDF_TAG.PICK_COMPONENT.${key.toUpperCase()}`)} </p>
                    {key === componentFields.dateCreated ? (
                      <p className="f-primary">{Helpers.getDateFromUnix(componentDetails[key])}</p>
                    ) : (
                      <p className="f-primary">{`${componentDetails[key] ? componentDetails[key] : '-'}`}</p>
                    )}
                  </Fragment>
                );
              })}
            </div>
          ) : (
            <div className="empty-state">
              <EmptyIcon />
              <p className="f-primary noselect">{t('COMPONENT_SELECT.NO_COMPONENT_SELECTED')} </p>
            </div>
          )}
        </div>

        <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.smartDrawing].manipulate.name}>
          {({ hasAccess }) => (
            <>
              <Button
                text={t('PDF_TAG.RIGHT.RELATE_TO_THE_COMPONENT')}
                className="space-bottom"
                disabled={!hasAccess || !pickedComponent}
                onClick={e => handleMergeCvWithExistingComponent(pickedComponent)}
              />
              <Button text={t('PDF_TAG.RIGHT.DELETE_TAG')} disabled={!hasAccess} variant="gray-outline" onClick={() => notInThisPdfClick(component)} />
            </>
          )}
        </AccessRenderer>
      </div>
    );
  }
}

PickComponentScreen.contextTypes = {
  t: PropTypes.func.isRequired,
};

PickComponentScreen = reduxForm({
  form: 'temp-form',
})(PickComponentScreen);

export default PickComponentScreen;
