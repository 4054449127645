import PropTypes from 'prop-types';
import React from 'react';

import AddButton from '../../../../../common/add-button/components/add-button';
import { FORMS } from '../../../../../common/constants';
import Button from '../../../../../common/form/components/button';
import { defaultQuestionValidators } from '../../../../../common/question-components/constants/question-constants';
import Section from '../../../../../common/section/components/section';
import { defaultSectionQuestionsName } from '../../../../../common/section/constants/section-constants';

const PermitTemplateSecondStep = (
  {
    sections,
    questionTypes,
    handleUpdateSection,
    handleAddQuestion,
    handleFieldDelete,
    handleFieldSort,
    handleDeleteSection,
    handleFieldChange,
    handleAddQuestionOption,
    handleUpdateQuestionOption,
    handleDeleteQuestionOption,
    handleSectionSort,
    isLoading,
    handleAddSection,
    handleBackButtonClick,
    handleFinishButtonClick,
    toggleUploadModal,
    handleDeleteSectionFile,
    isDisabled,
    sectionUpdateDebounce,
    questionUpdateDebounce,
    questionOptionUpdateDebounce,
  },
  { t }
) => {
  return (
    <div className="isolation-template-modal__sections">
      {sections.map((section, index) => (
        <Section
          {...section}
          form={`${FORMS.sectionsForm}-permit-template-${section.ID}`}
          key={`permit-template-section-${section.ID}`}
          initialValues={section}
          sectionIndex={index}
          sectionsLength={sections.length}
          questions={section.SectionQuestions}
          sectionQuestionsName={defaultSectionQuestionsName}
          questionValidators={defaultQuestionValidators}
          questionTypes={questionTypes}
          handleSectionChange={handleUpdateSection}
          handleAddQuestion={handleAddQuestion}
          handleFieldDelete={handleFieldDelete}
          handleFieldSort={handleFieldSort}
          handleUpdateSection={handleUpdateSection}
          handleDeleteSection={handleDeleteSection}
          handleFieldChange={handleFieldChange}
          isExpandable={
            (section.SectionQuestions && section.SectionQuestions.length > 0) || (section.lockedQuestions && section.lockedQuestions.length > 0) || section.isFilesUpload || section.Editable
              ? true
              : false
          }
          deleteDisabled={sections.length <= 1}
          sectionsChanged={section.changed}
          handleAddQuestionOption={handleAddQuestionOption}
          handleUpdateQuestionOption={handleUpdateQuestionOption}
          handleDeleteQuestionOption={handleDeleteQuestionOption}
          handleSort={handleSectionSort}
          isLoading={isLoading}
          requestInProgress={isLoading}
          hideAddQuestionButton={section.Locked && !section.Editable}
          hideAdditionalFields
          stickActions
          showQuestionDescription
          toggleUploadModal={toggleUploadModal}
          handleDeleteSectionFile={handleDeleteSectionFile}
          isLocked={section.Locked}
          isDisabled={isDisabled}
          sections={sections}
          sectionUpdateDebounce={sectionUpdateDebounce}
          questionUpdateDebounce={questionUpdateDebounce}
          questionOptionUpdateDebounce={questionOptionUpdateDebounce}
          hideNewMeasurementPointQuestionButton={true}
          hideMandatoryCheckbox
        />
      ))}
      <div className="checklists-procedures-management__container__second-step__add-button-wrapper">
        <AddButton disabled={isDisabled} onClick={handleAddSection}>
          {'SECTIONS.ADD_SECTION'}
        </AddButton>
      </div>
      <div className="checklists-procedures-management__container__second-step__buttons">
        <div className="checklists-procedures-management__container__second-step__buttons__wrapper">
          <Button
            variant="gray-outline"
            type="button"
            text={t('BACK')}
            width="sm"
            height="md"
            className="checklists-procedures-management__container__second-step__buttons__wrapper__btn"
            onClick={handleBackButtonClick}
          />
          <Button type="button" text={t('FINISH')} width="sm" height="md" onClick={handleFinishButtonClick} />
        </div>
      </div>
    </div>
  );
};

PermitTemplateSecondStep.contextTypes = {
  t: PropTypes.func.isRequired,
};

PermitTemplateSecondStep.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number.isRequired,
      SectionOrder: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
      SectionQuestions: PropTypes.arrayOf(
        PropTypes.shape({
          ID: PropTypes.number,
          Name: PropTypes.string.isRequired,
          QuestionType: PropTypes.number,
          QuestionOrder: PropTypes.number,
          IsMandatory: PropTypes.bool,
          AllowMultipleAnswers: PropTypes.bool,
          EstimatedTime: PropTypes.number,
          CreatedAt: PropTypes.number,
        })
      ),
    })
  ),
  questionTypes: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number,
      ChecklistItemType: PropTypes.string,
      ChecklistItemTypeName: PropTypes.string,
    })
  ),
  sectionsChanged: PropTypes.bool,
  handleUpdateSection: PropTypes.func.isRequired,
  handleAddQuestion: PropTypes.func.isRequired,
  handleFieldDelete: PropTypes.func.isRequired,
  handleFieldSort: PropTypes.func.isRequired,
  handleDeleteSection: PropTypes.func.isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  handleAddQuestionOption: PropTypes.func.isRequired,
  handleUpdateQuestionOption: PropTypes.func.isRequired,
  handleDeleteQuestionOption: PropTypes.func.isRequired,
  handleSectionSort: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  handleAddSection: PropTypes.func.isRequired,
  handleBackButtonClick: PropTypes.func.isRequired,
  handleFinishButtonClick: PropTypes.func.isRequired,
  toggleUploadModal: PropTypes.func.isRequired,
  handleDeleteSectionFile: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  viewOnly: PropTypes.bool,
};

export default PermitTemplateSecondStep;
