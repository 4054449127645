import { formConstants, workOrderNameMaxChars } from '../constants/constants';
import { formFields } from '../constants/work-order-details-constants';

export const workorderValidate = values => {
  const errors = {};

  if (!values[formConstants.fields.subType]) {
    errors[formConstants.fields.subType] = 'WORKORDER_VALIDATION.WORKORDER_SUB_TYPE_REQUIRED';
  }

  if (!values[formConstants.fields.notificationType]) {
    errors[formConstants.fields.notificationType] = 'WORKORDER_VALIDATION.WORKORDER_TYPE_REQUIRED';
  }

  if (!values[formConstants.fields.name]) {
    errors[formConstants.fields.name] = 'WORKORDER_VALIDATION.NAME_REQUIRED';
  }

  if (values[formConstants.fields.name] && values[formConstants.fields.name].length < 3) {
    errors[formConstants.fields.name] = 'WORKORDER_VALIDATION.NAME_MIN_CHARS';
  }

  if (values[formConstants.fields.name] && values[formConstants.fields.name].length > workOrderNameMaxChars) {
    errors[formConstants.fields.name] = 'WORK_ORDER_DETAILS_FORM.NAME_MAX_CHARS';
  }

  if (!values[formConstants.fields.severity]) {
    errors[formConstants.fields.severity] = 'WORKORDER_VALIDATION.SEVERITY_REQUIRED';
  }

  if (!values[formConstants.fields.startDate]) {
    errors[formConstants.fields.startDate] = 'WORKORDER_VALIDATION.START_DATE_REQUIRED';
  }

  if (!values[formConstants.fields.dueDate]) {
    errors[formConstants.fields.dueDate] = 'WORKORDER_VALIDATION.DUE_DATE_REQUIRED';
  }

  if (!values[formConstants.fields.endDate]) {
    errors[formConstants.fields.endDate] = 'WORKORDER_VALIDATION.END_DATE_REQUIRED';
  }

  if (
    values[formConstants.fields.startDate] &&
    values[formConstants.fields.endDate] &&
    (new Date(values[formConstants.fields.endDate]) < new Date(values[formConstants.fields.startDate]) ||
      new Date(values[formConstants.fields.endDate]).getTime() === new Date(values[formConstants.fields.startDate]).getTime())
  ) {
    errors[formConstants.fields.endDate] = 'CREATE_WORKORDER_FORM.END_START_DATE_MIN_ERROR';
  }

  if (values[formConstants.fields.description] && values[formConstants.fields.description].length > formFields.description.maxChars) {
    errors[formConstants.fields.description] = 'WORK_ORDER_DETAILS_FORM.MAX_CHARS';
  }

  if (values[formConstants.fields.toolsRequired] && values[formConstants.fields.toolsRequired].length > formFields.toolsRequired.maxChars) {
    errors[formConstants.fields.toolsRequired] = 'WORK_ORDER_DETAILS_FORM.MAX_CHARS';
  }

  return errors;
};
