export default {
  GET_PDF_TAG_TABLE_DATA: '/inspection/tagged/documents/list',
  OCR_COMPONENTS_SAVE: '/ocr/components/save',
  DELETE_PDF: '/inspection/tagged/documents/delete',
  EDIT_PDF: '/inspection/tagged/documents/update',
  MULTIPLE_FILE_STATUS: '/inspection/tagged/documents/status/list',
  GET_INSPECTION_DOCUMENTS: '/inspection/files/list',
  DELETE_INSPECTION_DOCUMENT: '/inspection/other/documents/delete',
  GET_PDF_TAG_TABLE_DATA_DMS: '/project/tagged/documents/list',
  EDIT_PDF_DMS: '/project/tagged/documents/update',
  MULTIPLE_FILE_STATUS_DMS: '/project/tagged/documents/status/list',
  GET_INSPECTION_DOCUMENTS_DMS: '/project/files/list',
  DELETE_INSPECTION_DOCUMENT_DMS: '/project/other/documents/delete',
};
