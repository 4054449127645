import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { SketchField, Tools } from '@tarik.djurdjevic/react-sketch';
import Icon from '../../icon/components/icon';

import { defaultStroke } from '../../../app/inspections/constants/default-drawing';

import '../styles/drawing-board.scss';

// TODO: refactor to functional component
class DrawingBoard extends Component {
  constructor(props) {
    super(props);
    this.sketchRef = null;
  }

  render() {
    const { selectedTool, lineColor, sketchProps, querySelector } = this.props;

    const { ref, ...sketchPropsFiltered } = sketchProps;
    const sketchFieldProps = {
      className: 'canvas-drawer',
      ref: c => {
        ref(c);
        if (!this.sketchRef && ref) {
          this.sketchRef = c;
        }
      },
      height: '150',
      width: '600',
      tool: selectedTool,
      lineColor: lineColor,
      fillColor: 'transparent',
      lineWidth: 3,
      ...sketchPropsFiltered,
    };

    return (
      <div className="drawing-board">
        <Icon className="drawing-board__icon" name="trash" size="xs" onClick={() => this.sketchRef && this.sketchRef.clear()} />
        <div className="drawing-board-container" id={querySelector}>
          <SketchField {...sketchFieldProps} />
        </div>
      </div>
    );
  }
}

DrawingBoard.defaultProps = {
  selectedTool: Tools.Pan,
  lineColor: defaultStroke,
  sketchProps: {},
};

DrawingBoard.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default DrawingBoard;
