import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Button from '../../../../../common/form/components/button';
import Checkbox from '../../../../../common/form/components/checkbox';
import ColorPicker from '../../../../inspections/components/common/input/color-picker';
import CustomInput from '../../../../../common/form/components/input';
import Textarea from '../../../../../common/form/components/text-area';
import { FORMS } from '../../../../../common/constants';
import { firstStepFields, defaultColor } from '../constants/constants';
import { validate } from '../../../validators/permit-template-validators';

const PermitTemplateFirstStep = ({ handleSubmit, change, invalid, anyTouched, handleFormSubmit, isEdit, handleCancelButtonClick, isLoading, isDisabled, initialValues, formValues }, { t }) => {
  const { name, description, signatureRequired, color } = firstStepFields;

  const getSubmitButtonContent = () => {
    if (isDisabled) {
      return 'CHECKLISTS_PROCEDURES_MANAGEMENT.NEXT_BTN';
    }

    return isEdit ? 'CHECKLISTS_PROCEDURES_MANAGEMENT.SAVE_BTN' : 'CHECKLISTS_PROCEDURES_MANAGEMENT.CREATE_BTN';
  };

  const getHexColor = color => {
    return color.split('').splice(0, 7).join('');
  };

  return (
    <form className="permit-template-management__container__first-step" onSubmit={handleSubmit(handleFormSubmit)} noValidate>
      <div className="permit-template-management__container__first-step__inputs permit-template-management__container__first-step__input_name">
        <Field
          id={name.name}
          name={name.name}
          label={name.label}
          component={CustomInput}
          type="text"
          placeholder={name.placeholder}
          disabled={isLoading || isDisabled}
          additionalClassName="text-transform-none"
          labelClass="text-transform-none"
        />
      </div>
      <div className="permit-template-management__container__first-step__inputs">
        <Field
          id={description.name}
          name={description.name}
          component={Textarea}
          placeholder={description.placeholder}
          label={description.label}
          maxChars={description.maxChars}
          disabled={isLoading || isDisabled}
        />
      </div>
      <div className="permit-template-management__container__first-step__color-picker-container">
        <ColorPicker
          color={formValues?.[color.name] || initialValues?.[color.name] || defaultColor}
          onColorChange={value => change(color.name, value)}
          name={color.name}
          changeField={(name, value) => change(name, getHexColor(value))}
          defaultColor={defaultColor}
          className="inline"
          placeholder={t(color.label)}
          label={t(color.label)}
          labelClass="f-primary label-color"
          disableColorPicker={isLoading || isDisabled}
        />
      </div>
      <div className="permit-template-management__container__first-step__checkbox-input">
        <Field id={signatureRequired.name} name={signatureRequired.name} labelClass="f-secondary-dark" component={Checkbox} disabled={isLoading || isDisabled} checkboxText={signatureRequired.label} />
      </div>
      <div className="permit-template-management__container__first-step__buttons">
        <Button variant="gray-outline" type="button" disabled={isLoading} text={t('CANCEL')} width="sm" height="md" onClick={handleCancelButtonClick} />
        <Button
          className="permit-template-management__container__first-step__buttons__submit-btn"
          disabled={isLoading || (invalid && anyTouched)}
          text={t(getSubmitButtonContent())}
          width="sm"
          height="md"
        />
      </div>
    </form>
  );
};

PermitTemplateFirstStep.contextTypes = {
  t: PropTypes.func.isRequired,
};

PermitTemplateFirstStep.propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  handleCancelButtonClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

PermitTemplateFirstStep.defaultProps = {
  isEdit: false,
  isLoading: false,
  isDisabled: false,
};

export default reduxForm({
  form: FORMS.permitTemplateForm,
  enableReinitialize: true,
  touchOnChange: true,
  destroyOnUnmount: true,
  validate,
})(PermitTemplateFirstStep);
