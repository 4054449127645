import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { formConstants } from '../../constants/checklist-constants';
import { checklistItemType } from '../../constants/mtask-constants';

import '../../styles/checklist-details.scss';

const ChecklistDetails = ({ selectedChecklistItems }, { t }) => {
  const getQuestionType = item => {
    if (item[formConstants.fields.checklistItemType] === checklistItemType.number) {
      return t('CHECKLIST_DETAILS.QUESTION_TYPE_NUM');
    } else if (item[formConstants.fields.checklistItemType] === checklistItemType.text) {
      return t('CHECKLIST_DETAILS.QUESTION_TYPE_TEXT');
    } else {
      return t('CHECKLIST_DETAILS.QUESTION_TYPE_YES_NO');
    }
  };

  return (
    <div className="checklist-details">
      {selectedChecklistItems &&
        selectedChecklistItems.map((item, index) => {
          return (
            <div className="question" key={index}>
              <p className="f-primary">{`${index + 1}. ${item[formConstants.fields.name]}`} </p>
              <p className="f-primary question-type">
                {t('CHECKLIST_DETAILS.QUESTION_TYPE')} - {getQuestionType(item)}
              </p>
            </div>
          );
        })}
      {isEmpty(selectedChecklistItems) && <p className="f-primary empty-state">{t('CHECKLIST_DETAILS.QUESTION_TYPE_EMPTY_STATE')}</p>}
      <p />
    </div>
  );
};
ChecklistDetails.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ChecklistDetails;
