export const tabNames = {
  details: 'DETAILS',
  comments: 'COMMENTS',
  flow: 'FLOW',
};

export const toolbarItems = {
  [tabNames.details]: {
    name: tabNames.details,
    label: 'ISOLATION_CERTIFICATE.TAB_DETAILS',
  },
  [tabNames.comments]: {
    name: tabNames.comments,
    label: 'COMPONENET_FORM_TAB.COMMENTS',
  },
  [tabNames.flow]: {
    name: tabNames.flow,
    label: 'ISOLATION_CERTIFICATE.TAB_FLOW',
  },
};

export const fields = {
  id: 'ID',
  archived: 'Archived',
  archive: 'Archive',
  isolationCertificateId: 'IsolationCertificateID',
  status: 'Status',
  name: 'Name',
};

export const imagesValidation = {
  maxFiles: 5,
  value: 50,
  unit: 'mb',
  bytes: 52428800,
};

export const pdfDownloadOptions = {
  populatedDrawings: {
    name: 'PopulatedDrawings',
    includeHandler: 'includePopulatedDrawingsPDF',
  },
  filesAndImages: {
    name: 'FilesAndImages',
    includeHandler: 'includeFilesAndImagesPDF',
  },
};

export const equipmentKey = 'Components';
