import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { debounce } from 'lodash';

import { Field, reduxForm } from 'redux-form';
import BasicInput from '../../common/input/basic-input';
import CustomSelect from '../../../../../common/form/components/select';
import Button from '../../../../../common/form/components/button';

import { FORMS } from '../../../../../common/constants';
import { formConstants, areaGroupConstants } from '../../../constants/areas-constants';
import { validateArea } from '../validators/area-group-validator';

import '../../../styles/add-area.scss';

class AddAreaModal extends Component {
  constructor(props) {
    super(props);
    this.getAreaGroupsDropdownDebounced = debounce(props.getAreaGroupsDropdown, 300);
  }
  componentDidMount = () => {
    this.getAreaGroupsDropdownDebounced('');
  };

  render() {
    const { t } = this.context;
    const { handleSubmit, closeAction, areaGroups = [] } = this.props;

    return (
      <div className="add-area">
        <form className="area-area__form" onSubmit={handleSubmit} noValidate>
          <Field
            id={formConstants.fields.name}
            name={formConstants.fields.name}
            component={BasicInput}
            autoFocus={true}
            label={t('AREA_FORM.AREA_NAME')}
            placeholder={t('AREA_FORM.AREA_NAME')}
            type="text"
          />

          <Field
            id={formConstants.fields.areaGroupId}
            name={formConstants.fields.areaGroupId}
            label={'AREA_DETAILS.FORM_AREA_GROUP'}
            labelClass="f-secondary-light"
            component={CustomSelect}
            valueField={areaGroupConstants.fields.id}
            textField={areaGroupConstants.fields.name}
            data={areaGroups || []}
            defaultValue={areaGroups && areaGroups[0] ? areaGroups[0] : null}
            onSearch={searchText => this.getAreaGroupsDropdownDebounced(searchText)}
          />

          <div className="buttons">
            <Button type="button" text={t('AREA_ADD.FORM_CANCEL')} variant="gray-outline" onClick={closeAction} />
            <Button text={t('AREA_ADD.FORM_SAVE')} />
          </div>
        </form>
      </div>
    );
  }
}
AddAreaModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

AddAreaModal = reduxForm({
  form: FORMS.addAreaForm,
  validate: validateArea,
  enableReinitialize: true,
})(AddAreaModal);

const mapStateToProps = (state, props) => ({
  areaGroups: state.areasReducer.areaGroupsDropdownData,
  initialValues: {
    [formConstants.fields.areaGroupId]: state.areasReducer.areaGroupsDropdownData?.[0],
  },
});

export default connect(mapStateToProps, null)(AddAreaModal);
