import { debounce, isString, keys, map, reduce, values } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { change } from 'redux-form';

import DisplayStatus from '../../../../common/display-status/components/display-status';
import Helpers from '../../../../common/helpers';
import Icon from '../../../../common/icon/components/icon';
import ItemsTableRenderer from '../../../../common/items-renderer/components/items-table-renderer';
import LoadMore from '../../../../common/load-more/components/load-more';
import Modal from '../../../../common/modal/components/modal';
import { PERMISSION_TYPES, PERMISSIONS } from '../../../../common/permissions-constants';
import StatusesFilter from '../../../../common/statuses-filter/components/statuses-filter';
import InfoTooltip from '../../../../common/tooltip/components/info-tooltip';
import { setIsolationCertificates, setIsolationCertificatesFilters } from '../../actions/action-creators';
import { createIsolationCertificate, getIsolationTemplates } from '../../actions/create-isolation-certificates';
import ModuleHeader from '../left-toolbar/common/module-header';
import SearchText from '../left-toolbar/common/search-text';
import { defaultFilter, filterFields } from './constants/isolation-certificate-form';
import { columnTypes, defaultFilters, fields, filterProps, sortDirection, statuses, tableConfigLite } from './constants/isolation-certificates-table';
import CreateIsolationCertificateForm from './create-isolation-certificate-form';
import IsolationCertificatePopover from './isolation-certificate-popover';
import IsolationCertificatesFilter from './isolation-certificates-filter';

import '../../styles/isolation-certificates.scss';

class IsolationCertificates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalData: {
        isOpen: false,
      },
      templates: [],
      advancedFilter: {},
    };

    this.searchIsolationTemplatesDebounce = debounce(this.onSearchIsolationTemplates, 600);
    this.onStatusChangeDebounce = debounce(this.onStatusChange, 200);
    this.getIsolationCertificatesDebounced = debounce(this.fetchIsolationCertificates, 200);
  }

  componentDidMount = () => {
    const { filters } = this.props;
    this.fetchIsolationCertificates(filters, false);
  };

  componentWillUnmount = () => {
    const { setIsolationCertificates, setIsolationCertificatesFilters } = this.props;

    setIsolationCertificates(false, []);
    setIsolationCertificatesFilters(defaultFilters);
  };

  componentDidUpdate = prevProps => {
    const { searchText } = this.props;

    if (isString(searchText) && prevProps.searchText !== searchText) {
      const { filters } = this.props;
      this.getIsolationCertificatesDebounced(filters, false);
    }
  };

  fetchIsolationCertificates = (params, loadMore, filterStatuses, isStatusChange) => {
    const { advancedFilter } = this.state;
    const { getIsolationCertificates, searchText, projectId } = this.props;
    params[filterProps.projectId] = projectId;
    params[filterProps.searchText] = searchText;
    params[filterProps.lastSeen] = loadMore ? params[filterProps.lastSeen] : defaultFilters[filterProps.lastSeen];

    if (isStatusChange) {
      if (filterStatuses === '') {
        // resets singleSelected filter status/flag when archived filter turned off
        params[filterProps.singleSelected] = [];
        params[filterProps.singleSelected] = '';
        params[filterProps.archived] = false;
      }

      if (filterStatuses) {
        if (filterStatuses === statuses.archived.value) {
          params[filterProps.archived] = filterStatuses === statuses.archived.value;
          params[filterProps.singleSelected] = filterStatuses;
        } else {
          params[filterProps.status] = filterStatuses;
        }
      }
    }

    const onSuccessFetch = listLength => {
      if (loadMore && listLength) {
        Helpers.scrollIntoView('table-container', `row-${listLength - 1}`);
      }
    };

    //normalize advanced filter
    const newAdvancedFilter = reduce(
      advancedFilter,
      (obj, it, key) => {
        if ([filterFields.createdBy, filterFields.markedAsLiveBy, filterFields.markedAsDeisolatedBy].indexOf(key) > -1) {
          obj[key] = map(advancedFilter[key], ({ ID, UserID }) => ID || UserID);
        } else if ([filterFields.isolationTemplate].indexOf(key) > -1) {
          obj[key] = map(advancedFilter[key], ({ Name }) => Name);
        } else if ([filterFields.dateFrom, filterFields.liveFrom, filterFields.deisolatedFrom].indexOf(key) > -1) {
          obj[key] = (advancedFilter[key] && Helpers.getUnixDate(new Date(advancedFilter[key]).getTime())) || null;
        } else if ([filterFields.dateTo, filterFields.liveTo, filterFields.deisolatedTo].indexOf(key) > -1) {
          // sets end/to dates to end of day 23:59:59 to include that day in filter results
          obj[key] = (advancedFilter[key] && Helpers.getUnixDate(new Date(advancedFilter[key]).setHours(23, 59, 59))) || null;
        } else {
          obj[key] = advancedFilter[key];
        }
        return obj;
      },
      {}
    );

    getIsolationCertificates(params, loadMore, onSuccessFetch, newAdvancedFilter);
  };

  onColumnSort = SortByColumn => {
    const { filters } = this.props;
    const params = {
      ...filters,
      [filterProps.sortByColumn]: SortByColumn,
      [filterProps.sortDirection]: filters[filterProps.sortDirection] === sortDirection.asc ? sortDirection.desc : sortDirection.asc,
      [filterProps.lastSeen]: 0,
    };

    this.fetchIsolationCertificates(params, false);
  };

  onStatusChange = value => {
    const { filters } = this.props;

    this.fetchIsolationCertificates(filters, false, value, true);
  };

  onRowClick = id => {
    const { handleItemClick } = this.props;
    handleItemClick(id);
  };

  loadMore = () => {
    const { filters } = this.props;

    this.fetchIsolationCertificates(filters, true);
  };

  onSearchIsolationTemplates = params => {
    const { getIsolationTemplates } = this.props;

    getIsolationTemplates(params, templates => this.setState({ templates }));
  };

  onCreateIsolationCertificateSubmit = values => {
    const { user, isolationCertificates, filters, createIsolationCertificate } = this.props;

    createIsolationCertificate(values, user.Name, Object.assign([], isolationCertificates), Object.assign({}, filters), id => this.toggleCreateCertificateModal(false, id));
  };

  toggleCreateCertificateModal = (isOpen, isolationCertificateId) => {
    const { projectId, changeField } = this.props;
    const { t } = this.context;

    defaultFilter[filterFields.searchText] = '';
    defaultFilter[filterFields.projectID] = projectId;

    this.searchIsolationTemplatesDebounce(defaultFilter);

    let modalData = {
      isOpen,
    };

    if (isOpen) {
      modalData = {
        ...modalData,
        ...{
          isOpen: true,
          title: t('ISOLATION_CERTIFICATES.CREATE_MODAL_TITLE'),
          CustomContent: dynamicProps => (
            <CreateIsolationCertificateForm
              handleFormSubmit={this.onCreateIsolationCertificateSubmit}
              closeModal={() => this.toggleCreateCertificateModal(false)}
              projectId={projectId}
              onSearchIsolationTemplates={this.searchIsolationTemplatesDebounce}
              changeField={changeField}
              {...dynamicProps}
            />
          ),
          customClassName: 'modal-large',
          type: 'none',
          closeAction: () => this.toggleCreateCertificateModal(false),
        },
      };
    }

    this.setState({ modalData });

    if (!isOpen && isolationCertificateId) {
      const { location } = this.props;
      const queryParams = { ...location.query };
      delete queryParams.selected_item;
      const params = [];

      for (let key in queryParams) {
        params.push({
          [key]: queryParams[key],
        });
      }

      params.push({ selected_item: isolationCertificateId });

      Helpers.goTo(location.pathname, params);
    }
  };

  openAdvancedFilter = () => {
    const { t } = this.context;
    const { projectId, filters, setModalState } = this.props;
    const { advancedFilter } = this.state;
    const closeAction = () => setModalState({ isOpen: false });

    const modalData = {
      isOpen: true,
      type: 'none',
      title: t('ADVANCED_FILTERS.TITLE'),
      CustomContent: () => (
        <IsolationCertificatesFilter
          submitForm={values => {
            this.setState(
              {
                advancedFilter: values,
              },
              this.getIsolationCertificatesDebounced(filters, false)
            );
            closeAction();
          }}
          resetFilter={() => {
            this.setState(
              {
                advancedFilter: {},
              },
              this.getIsolationCertificatesDebounced(filters, false)
            );
            closeAction();
          }}
          initialValues={advancedFilter}
          closeAction={closeAction}
          projectId={projectId}
        />
      ),
      customClassName: 'modal-large defects-filter',
      closeAction,
    };

    setModalState(modalData);
  };

  render() {
    const { t } = this.context;
    const { modalData, templates, advancedFilter } = this.state;
    const { title, backAction, moduleActionsMenu, icon, isolationCertificates, isolationCertificatesLoading, tableConfig, queryItem, filters, user, isInLeftToolbar } = this.props;

    return (
      <div className="isolation-certificates">
        <ModuleHeader
          {...{
            title,
            icon,

            backAction,
            actionsMenu: moduleActionsMenu,
            actionButtontext: 'CREATE_BUTTON_TEXT.NEW',
            handleActionButtonClick: () => this.toggleCreateCertificateModal(true),
            actionButtonProps: {
              user,
              visibleFor: PERMISSIONS[PERMISSION_TYPES.isolationCertificates].create.name,
            },
          }}
        />
        <div className="advanced-filter">
          <SearchText maxLength={60} />
          <p className="f-secondary-green noselect pointer link" onClick={this.openAdvancedFilter}>
            {t('ADVANCED_FILTER', { active: keys(advancedFilter).length })}
          </p>
        </div>
        <div className="status-filters">
          <p className="f-secondary-dark status-filters-title bold">{t('ISOLATION_CERTIFICATES.FILTERS_TITLE')}</p>
          <StatusesFilter
            statuses={values(statuses)}
            selected={filters[filterProps.status]}
            singleSelected={filters[filterProps.singleSelected]}
            onChange={this.onStatusChangeDebounce}
            isAdjustedForSmallerScreen={isInLeftToolbar}
            isLoading={isolationCertificatesLoading}
          />
        </div>
        <div className="table-container">
          <ItemsTableRenderer
            isLoading={isolationCertificatesLoading}
            tableConfig={tableConfig || tableConfigLite}
            translationModule={t}
            emptyStateText="ISOLATION_CERTIFICATES.EMPTY_STATE"
            data={map(isolationCertificates, item => ({ ...item, selected: item[fields.id] === queryItem }))}
            onRowClick={(_e, item) => this.onRowClick(item[fields.id])}
            sortingObj={{
              [filterProps.sortByColumn]: filters[filterProps.sortByColumn],
              [filterProps.sortDirection]: filters[filterProps.sortDirection],
            }}
            formatCell={(value, type, index, item) => {
              if (type === columnTypes.status) {
                return <DisplayStatus statuses={values(statuses)} status={value} />;
              }

              if (type === columnTypes.statusCustom) {
                return <Icon name="eye" active={item[fields.id] === queryItem} size="xs" onClick={() => console.log('Item clicked')} title={t('ITEMS_LIST.VISIBILTY_ICON')} />;
              }

              if (type === columnTypes.keySafeNumber) {
                return value || '-';
              }

              if (type === columnTypes.linkedPermits) {
                const linkedPermitsNumber = parseInt(value, 10);

                if (linkedPermitsNumber < 2) {
                  return linkedPermitsNumber && item[fields.permits] && item[fields.permits][0] ? item[fields.permits][0][fields.name] : '-';
                } else {
                  return (
                    <div className="name">
                      <InfoTooltip
                        customComponent={<IsolationCertificatePopover certificateName={item[fields.name]} permits={item[fields.permits]} />}
                        offsetY={10}
                        offsetX={-20}
                        Component={() => (
                          <div className="permits-popover">
                            <p className="f-primary">{t('ISOLATION_CERTIFICATES.LINKED_PERMITS_PLACEHOLDER', { number: value })}</p>
                            <Icon name="info" handleHover={false} size="xs" />
                          </div>
                        )}
                      />
                    </div>
                  );
                }
              }

              return Helpers.formatCell(value, type, index, item);
            }}
            sortByColumnProp={filterProps.sortByColumn}
            onColumnSort={this.onColumnSort}
          />
          <LoadMore
            disabled={!filters[filterProps.hasNext] || isolationCertificatesLoading}
            loaded={isolationCertificates.length}
            total={filters[filterProps.totalItems]}
            totalPosition="center"
            isLoading={isolationCertificatesLoading}
            label="COMPONENT_HISTORY.LOAD_MORE"
            showButton
            showTotalUp
            buttonVariant="success-outline"
            resultsText="ISOLATION_CERTIFICATES.SHOWING_TOTAL_ITEMS"
            onClick={this.loadMore}
          />
        </div>
        <Modal {...modalData} templates={templates} modalDisabled={isolationCertificatesLoading} />
      </div>
    );
  }
}

IsolationCertificates.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.userReducer,
  filters: state.isolationCertificatesReducer.filters,
});

const mapDispatchToProps = dispatch => ({
  getIsolationTemplates: (params, callback) => dispatch(getIsolationTemplates(params, callback)),
  changeField: (form, field, value, touch) => dispatch(change(form, field, value, touch)),
  createIsolationCertificate: (values, createdByName, isolationCertificates, filters, callback) =>
    dispatch(createIsolationCertificate(values, createdByName, isolationCertificates, filters, callback)),
  setIsolationCertificates: (isLoading, certificates) => dispatch(setIsolationCertificates(isLoading, certificates)),
  setIsolationCertificatesFilters: filters => dispatch(setIsolationCertificatesFilters(filters)),
});

IsolationCertificates = connect(mapStateToProps, mapDispatchToProps)(IsolationCertificates);

export default withRouter(IsolationCertificates);
