import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import InspectionRenderer from '../../../app/inspections/components/left-toolbar/inspection-renderer';
import ShapeRenderer from '../../../app/inspections/components/left-toolbar/shape-renderer';
import ZoneAreaForm from '../../../app/inspections/components/right-toolbar/zone-area-types/zone-area-form';
import Icon from '../../icon/components/icon';
import ItemsTableRenderer from '../../items-renderer/components/items-table-renderer';

import { formConstants, workAreasTableHeader } from '../constants/constants';

import { validate } from '../../../app/inspections/components/right-toolbar/validators/area-measurement-validator';
import { modelTypes } from '../../../app/inspections/constants/explosive-zones-constants';
import { colorPalette } from '../../form/constants/constants';

import ActionModal from '../../../app/document-management/components/modals/action-modal/action-modal';
import Modal from '../../modal/components/modal';
import '../styles/work-areas.scss';

class WorkAreas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedWorkArea: null,
      deleteModal: {
        isOpen: false,
      },
    };
  }

  getWorkAreasToRender = () => {
    const { elements, showOnly2D } = this.props;

    let areas2D = elements.filter(workArea => workArea[formConstants.fields.modelType] === modelTypes._2D);
    let areas3D = elements.filter(workArea => workArea[formConstants.fields.modelType] === modelTypes._3D);
    if (showOnly2D) {
      areas2D = [...elements];
      areas3D = [];
    }

    return { areas2D, areas3D };
  };

  handleWorkAreaChange = (data, changedVals) => {
    const { handleWorkAreaUpdate } = this.props;
    const updatedData = { ...data, ...changedVals };

    if (isEmpty(validate(updatedData))) {
      handleWorkAreaUpdate(updatedData, data => this.setState({ selectedWorkArea: data }));
    }
  };

  getTableData = () => {
    const { elements, showOnly2D, viewer } = this.props;
    const { selectedWorkArea } = this.state;
    const selectedIndex = selectedWorkArea ? elements.findIndex(workArea => workArea[formConstants.fields.id] === selectedWorkArea[formConstants.fields.id]) : -1;
    const countVisibleHeaders = (workAreasTableHeader.filter(headerItem => !headerItem.visible) || []).length + 1; // + 1 because we are adding actions

    let newElements = [...elements];
    if (selectedIndex > -1) {
      newElements = [
        ...newElements.slice(0, selectedIndex),
        {
          ...newElements[selectedIndex],
          selected: true,
        },
        {
          CustomComponent: () => (
            <td colSpan={countVisibleHeaders}>
              <ZoneAreaForm
                zoneArea={selectedWorkArea}
                nameField={formConstants.fields.name}
                showOnly2D={showOnly2D}
                handleChange={this.handleWorkAreaChange}
                finishEditing={this.deselectWorkArea}
                viewer={viewer}
              />
            </td>
          ),
        },
        ...newElements.slice(selectedIndex + 1),
      ];
    }

    return newElements;
  };

  startWorkAreaEdit = workArea => {
    this.setState({ selectedWorkArea: workArea });
  };

  deselectWorkArea = () => {
    this.setState({ selectedWorkArea: null });
  };

  toggleConfirmationModal = (isOpen, title, content, contentProps = {}, confirmAction = () => null) => {
    const { t } = this.context;
    if (isOpen) {
      const closeAction = () => this.setState({ deleteModal: { isOpen: false } });
      const handleConfirmAction = () => {
        if (typeof confirmAction === 'function') {
          confirmAction();
        }
        closeAction();
      };

      this.setState({
        deleteModal: {
          isOpen: true,
          CustomContent: dynamicProps => <ActionModal {...dynamicProps} />,
          closeAction: closeAction,
          type: '',
          title: t(title),
          confirmButtonText: 'WORK_ORDER.DELETE_WORK_AREA',
          closeButtonText: 'CANCEL',
          firstParagraph: t(content, { areaName: contentProps.areaName }),
          customCloseAction: closeAction,
          customConfirmAction: handleConfirmAction,
          customClassName: 'work-areas-action__delete',
        },
      });
    } else {
      this.setState({ deleteModal: { isOpen: false } });
    }
  };

  render() {
    const { t } = this.context;
    const { deleteModal, selectedWorkArea } = this.state;
    const { handleWorkAreaUpdate, viewer, handleDeleteWorkArea, viewOnly, isAdding } = this.props;
    const { areas2D: areas, areas3D: shapes } = this.getWorkAreasToRender();
    const tableData = this.getTableData();

    return (
      <Fragment>
        <InspectionRenderer
          deselectAll={() => null}
          selectAll={() => null}
          toggleElement={() => null}
          deselectAllTemp={() => null}
          selectAllTemp={() => null}
          toggleElementTemp={() => null}
          updateElementGeometry={element => {
            // to update the area, it needs to be selected first
            if (handleWorkAreaUpdate && selectedWorkArea) {
              handleWorkAreaUpdate(element, data => this.setState({ selectedWorkArea: data }));
            }
          }}
          selectElement={() => null}
          elements={areas.map(item => {
            if (selectedWorkArea && item[formConstants.fields.id] === selectedWorkArea[formConstants.fields.id]) {
              return { ...item, enableMove: true, visible: true };
            } else {
              return { ...item, enableMove: false, visible: true };
            }
          })}
          queryItem={selectedWorkArea ? selectedWorkArea[formConstants.fields.id] : null}
          selectedDefect={selectedWorkArea || null}
          viewer={viewer}
        >
          {({ elementClickHandler }) => (
            <div className="work-areas h-container-padding">
              {!isAdding ? (
                <ItemsTableRenderer
                  className="work-areas__table"
                  tableConfig={[
                    ...workAreasTableHeader,
                    {
                      CustomComponent: (data, itemIndex) =>
                        viewOnly ? null : (
                          <div className="work-areas__table-actions" key={itemIndex}>
                            <Icon name="pen" size="xs" onClick={e => this.startWorkAreaEdit(data)} />
                            <Icon
                              name="trash"
                              size="xs"
                              onClick={_e =>
                                this.toggleConfirmationModal(true, 'WORK_AREAS.DELETE_AREA_MODAL.TITLE', 'WORK_AREAS.DELETE_AREA_MODAL.DESC', { areaName: data[formConstants.fields.name] }, () =>
                                  handleDeleteWorkArea(data)
                                )
                              }
                            />
                          </div>
                        ),
                      enableSort: false,
                    },
                  ]}
                  translationModule={t}
                  data={tableData}
                  onRowClick={(e, item) => {
                    elementClickHandler(e, item);
                  }}
                  formatCell={(value, type, index, item) => {
                    if (type === formConstants.fields.name) {
                      return (
                        <div className="work-areas__name">
                          {item.Colour ? <div className="work-areas__name__status-icon" style={{ backgroundColor: colorPalette[item.Colour] }} /> : null}
                          <div title={value} className="f-primary work-areas__name__text">
                            {value}
                          </div>
                        </div>
                      );
                    }
                    return value;
                  }}
                  emptyStateText={'WORK_AREAS.TABLE.EMPTY_STATE'}
                />
              ) : null}
            </div>
          )}
        </InspectionRenderer>
        <ShapeRenderer
          deselectAll={() => null}
          selectAll={() => null}
          toggleElement={() => null}
          deselectAllTemp={() => null}
          selectAllTemp={() => null}
          toggleElementTemp={() => null}
          updateElementGeometry={element => {
            // to update the area, it needs to be selected first
            if (handleWorkAreaUpdate && selectedWorkArea) {
              handleWorkAreaUpdate(element, data => this.setState({ selectedWorkArea: data }));
            }
          }}
          selectElement={() => null}
          elements={shapes.map(el => ({ ...el, visible: true }))}
          selectedDefect={null}
          viewer={viewer}
        >
          {({ elementClickHandler, elementShowHandler, elementHideHandler, selectAllHandler, deselectAllHandler }) => <div />}
        </ShapeRenderer>
        <Modal {...deleteModal} />
      </Fragment>
    );
  }
}

WorkAreas.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default WorkAreas;
