import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import I18n from 'redux-i18n';
import { PersistGate } from 'redux-persist/integration/react';
import { crossTabListener } from './core/cross-tab-listener';

import Routes from './core/routes';

import { translations } from './core/translations';

import { configureStore } from './core/store';

export const { persistor, store, config } = configureStore();

//enable communication beetween 2 tabs using persistor(local storage)
export const rehydrate = () => {
  window.addEventListener(
    'storage',
    crossTabListener(store, config, () => {
      window.removeEventListener('storage', crossTabListener, false);
    }),
    false
  );
};

const router = (
  <PersistGate loading={null} persistor={persistor}>
    <Provider store={store}>
      <I18n translations={translations}>
        <Routes />
      </I18n>
    </Provider>
  </PersistGate>
);

render(router, document.getElementById('root'));
