export const filterProps = {
  sortByColumn: 'SortByColumn',
  sortDirection: 'SortDirection',
  perPage: 'PerPage',
  lastSeen: 'LastSeen',
  searchText: 'SearchText',
  hasNext: 'HasNext',
  totalItems: 'TotalItems',
  createdByFilter: 'CreatedByFilter',
  properties: 'Properties',
  totalNumber: 'TotalNumber',
};

export const defaultPagingObj = {
  [filterProps.lastSeen]: 0,
  [filterProps.perPage]: 20,
  [filterProps.totalItems]: 0,
  [filterProps.totalNumber]: 0,
  [filterProps.hasNext]: false,
};
