import React from 'react';
import PropTypes from 'prop-types';

import SmallDropdown from '../../../../../common/small-dropdown/components/small-dropdown';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../../common/permissions-constants';
import { steps, fields, statuses, confirmationModalTypes } from '../constants/constants';
import { openPermitTemplateForm } from '../../../helpers/templates-helpers';

const TableDropdown = ({ item, queryParams, toggleConfirmationModal }, { t }) => {
  const translationData = {
    name: item[fields.name],
  };

  const menuOptions = [
    {
      title: 'PERMIT_TEMPLATES_LIST.VIEW',
      action: () => openPermitTemplateForm('view', steps.firstStep, item[fields.id], queryParams),
    },
    {
      title: 'PERMIT_TEMPLATES_LIST.EDIT',
      action: () => openPermitTemplateForm('edit', steps.firstStep, item[fields.id], queryParams),
      access: {
        visibleFor: PERMISSIONS[PERMISSION_TYPES.permitTemplates].edit.name,
        id: item[fields.createdBy],
        ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.permitTemplates].create.name,
      },
      isDisabled: [statuses.live.value].indexOf(item[fields.status]) > -1,
    },
    {
      title: 'PERMIT_TEMPLATES_LIST.CHANGE_TO_RAISED',
      isHidden: item[fields.status] !== statuses.draft.value,
      action: () =>
        toggleConfirmationModal(
          true,
          confirmationModalTypes.changeStatus,
          { [fields.id]: item[fields.id], [fields.status]: statuses.raised.value },
          { ...translationData, ...{ status: t('CHECKLISTS_PROCEDURES_LIST.RAISED_STATUS') } }
        ),
      access: {
        visibleFor: PERMISSIONS[PERMISSION_TYPES.permitTemplates].edit.name,
        id: item[fields.createdBy],
        ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.permitTemplates].create.name,
      },
    },
    {
      title: 'PERMIT_TEMPLATES_LIST.CHANGE_TO_DRAFT',
      isHidden: item[fields.status] !== statuses.raised.value,
      action: () =>
        toggleConfirmationModal(
          true,
          confirmationModalTypes.changeStatus,
          { [fields.id]: item[fields.id], [fields.status]: statuses.draft.value },
          { ...translationData, ...{ status: t('CHECKLISTS_PROCEDURES_LIST.DRAFT_STATUS') } }
        ),
      access: {
        visibleFor: PERMISSIONS[PERMISSION_TYPES.permitTemplates].edit.name,
        id: item[fields.createdBy],
        ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.permitTemplates].create.name,
      },
    },
    {
      title: 'PERMIT_TEMPLATES_LIST.CHANGE_TO_LIVE',
      isHidden: item[fields.status] !== statuses.raised.value,
      action: () =>
        toggleConfirmationModal(
          true,
          confirmationModalTypes.changeStatus,
          { [fields.id]: item[fields.id], [fields.status]: statuses.live.value },
          { ...translationData, ...{ status: t('CHECKLISTS_PROCEDURES_LIST.LIVE_STATUS') } },
          true
        ),
      access: {
        visibleFor: PERMISSIONS[PERMISSION_TYPES.permitTemplates].approve.name,
      },
    },
    {
      title: 'PERMIT_TEMPLATES_LIST.CHANGE_TO_RAISED',
      isHidden: item[fields.status] !== statuses.live.value,
      action: () =>
        toggleConfirmationModal(
          true,
          confirmationModalTypes.changeStatus,
          { [fields.id]: item[fields.id], [fields.status]: statuses.raised.value },
          { ...translationData, ...{ status: t('CHECKLISTS_PROCEDURES_LIST.RAISED_STATUS') } },
          true
        ),
      access: {
        visibleFor: PERMISSIONS[PERMISSION_TYPES.permitTemplates].approve.name,
      },
    },
    {
      title: 'PERMIT_TEMPLATES_LIST.DUPLICATE',
      action: () => toggleConfirmationModal(true, confirmationModalTypes.duplicate, { [fields.id]: item[fields.id] }, translationData),
      access: {
        visibleFor: PERMISSIONS[PERMISSION_TYPES.permitTemplates].edit.name,
        id: item[fields.createdBy],
        ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.permitTemplates].create.name,
      },
    },
    {
      title: 'PERMIT_TEMPLATES_LIST.DELETE',
      action: () => toggleConfirmationModal(true, confirmationModalTypes.delete, { [fields.permitTemplateId]: item[fields.id] }, translationData),
      access: {
        visibleFor: PERMISSIONS[PERMISSION_TYPES.permitTemplates].delete.name,
      },
      separator: true,
      isHighlighted: true,
    },
  ];

  return (
    <SmallDropdown
      className="page-navigation__wrapper__title-container__menu__dropdown"
      actionsMenu={menuOptions}
      getClickAction={(action, b, selfClose, e) => {
        e?.preventDefault && e.preventDefault();
        selfClose();
        if (action) {
          if (typeof action === 'function') {
            action();
          }
        }
      }}
      offsetX={10}
    />
  );
};

TableDropdown.contextTypes = {
  t: PropTypes.func.isRequired,
};

TableDropdown.propTypes = {
  item: PropTypes.shape({
    ID: PropTypes.number.isRequired,
    Name: PropTypes.string.isRequired,
    Status: PropTypes.string.isRequired,
    Description: PropTypes.string,
    CreatedAt: PropTypes.number.isRequired,
    CreatedBy: PropTypes.number.isRequired,
    CreatedByName: PropTypes.string.isRequired,
  }).isRequired,
  queryParams: PropTypes.string,
  toggleConfirmationModal: PropTypes.func.isRequired,
};

export default TableDropdown;
