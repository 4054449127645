import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { each } from 'lodash';

import { LANDING_TYPE } from '../../../common/constants';

import { saveAccesToken } from '../actions/actions';

import Helpers from '../../../common/helpers';
import routesConstants from '../../../common/routes-constants';

// example success url
//http://localhost:7777/app/landing?type=dropbox#access_token=9hO2xkeXzlAAAAAAAAAAVSK62W61mfq4kRNVfjaITnA-IgjmftbDFf1r1gRRQgIB&token_type=bearer&state=%3FprojectId%3D177%26projectName%3DDigester&uid=2304318368&account_id=dbid%3AAABoakmX1MTX139euYNPCFhZBhWFDXKBr5M

//example fail url

class Landing extends Component {
  getParamsFromQuery = query => {
    query = query.split('&');
    let obj = {};
    each(query, item => {
      obj[item.split('=')[0]] = item.split('=')[1];
    });
    return obj;
  };

  getParamsFromHash = hash => {
    let query = hash.substring(1, hash.length);
    return this.getParamsFromQuery(query);
  };

  componentDidMount = () => {
    const { saveAccesToken } = this.props;
    const { query, hash } = this.props.location;

    switch (query.type) {
      case LANDING_TYPE.dropbox:
        const object = this.getParamsFromHash(hash);
        //save access token and amend userReducer
        //redirect to inial page
        if (!object.error)
          saveAccesToken(object, () => {
            setTimeout(() => {
              window.close();
            }, 5000);
          });
        else {
          alert('error connecting to dropbox');
          //   display error message with param error_description
        }
        // render or open popup for success
        this.setState({ type: query.type, success: object.error ? false : true });

        break;
      default:
        Helpers.goTo(routesConstants.defaultRoute);
        break;
    }
  };

  render() {
    return (
      <div className="landing-container">
        <p>landing</p>
      </div>
    );
  }
}

Landing.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  saveAccesToken: (data, callback) => dispatch(saveAccesToken(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
