import { debounce, isArray, isEqual, values } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import TableTemplate from '../../../../../common/double-table/components/table-template';
import { getSelectedStatuses } from '../../work-order/helpers/work-order-helpers';
import { formatMaintenanceRegimesTableCells } from '../actions/table-formatting';
import { defaultFilters, filterProps, sortDirection, statuses, tableConfig } from '../constants/constants';

const MaintenanceRegimesWrapper = ({ data, filters, wrapperClassName, loading, fetchComponentRegimes, customTableClassName, onRowClick }, { t }) => {
  const [searchText, setSearchText] = useState('');

  const isFetchComponentRegimesValid = fetchComponentRegimes && typeof fetchComponentRegimes === 'function';

  const onSort = (SortByColumn = {}) => {
    const newFilters = {
      ...filters,
      [filterProps.sortByColumn]: SortByColumn,
      [filterProps.sortDirection]: filters[filterProps.sortDirection] === sortDirection.asc ? sortDirection.desc : sortDirection.asc,
      [filterProps.lastSeen]: 0,
    };
    isFetchComponentRegimesValid && fetchComponentRegimes(newFilters);
  };

  const onLoadMoreClick = () => {
    filters[filterProps.lastSeen] = data.length;
    isFetchComponentRegimesValid && fetchComponentRegimes(filters, true);
  };

  const onSearch = SearchText => {
    const newFilters = {
      ...filters,
      [filterProps.lastSeen]: 0,
      SearchText,
    };
    isFetchComponentRegimesValid && fetchComponentRegimes(newFilters);
  };

  const onQuickFilterClick = quickFilters => {
    const newFilters = {
      ...filters,
      [filterProps.lastSeen]: 0,
    };
    if (isArray(quickFilters)) {
      newFilters[filterProps.statusFilter] = [quickFilters.length ? quickFilters.toString() : ''];
    }

    isFetchComponentRegimesValid && fetchComponentRegimes(newFilters);
  };

  const handleSearchInput = e => {
    setSearchText(e.target.value);
    searchTextChanged(e.target.value);
  };

  const searchTextChanged = debounce(onSearch, 300);

  const isFilterApplied = isEqual(defaultFilters[filterProps.statusFilter], filters[filterProps.statusFilter]) && defaultFilters[filterProps.searchText] === filters[filterProps.searchText];

  return (
    <div className={wrapperClassName ? wrapperClassName : ''}>
      <TableTemplate
        tableConfig={tableConfig}
        filters={filters}
        data={data}
        onSortClick={onSort}
        loadMoreOnClick={onLoadMoreClick}
        quickFilters={values(statuses)}
        onQuickFilterClick={onQuickFilterClick}
        handleFormatCells={formatMaintenanceRegimesTableCells}
        selectedItem={null}
        isLoading={loading}
        TotalItems={filters.TotalNumber}
        searchPlaceholder={t('SEARCH')}
        sortDirection={filterProps.sortDirection}
        sortByColumn={filterProps.sortByColumn}
        resultsText={'MAINTENANCE_REGIME.REGIMES_NUMBER'}
        searchInputOnChange={handleSearchInput}
        searchInputValue={searchText}
        hasNext={filters[filterProps.hasNext]}
        selectedQuickFilters={getSelectedStatuses(filters)}
        singleSelected={filters[filterProps.statusFilter]}
        noDataText={isFilterApplied ? t('MAINTENANCE_REGIME.EMPTY_STATE') : t('MAINTENANCE_REGIME.EMPTY_STATE_FILTERED')}
        customTableClass={customTableClassName}
        onRowClick={onRowClick}
      />
    </div>
  );
};

MaintenanceRegimesWrapper.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default MaintenanceRegimesWrapper;
