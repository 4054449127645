import React from 'react';
import TableTemplate from '../../double-table/components/table-template';
import { showTeamUserTableConfig } from '../constants/constants';

const ShowTeamUserModal = ({ users }) => {
  return (
    <>
      <TableTemplate
        data={users}
        showSearchBar={false}
        showInfo={false}
        filters={{}}
        tableConfig={showTeamUserTableConfig}
        handleFormatCells={val => val}
        customTableClass="show-team-user-table"
        showHeader
        selectedItem={null}
      />
    </>
  );
};

export default ShowTeamUserModal;
