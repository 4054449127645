import React, { useState, useEffect, useCallback } from 'react';
import SearchInput from '../../input/components/search-input';
import RenderIf from '../../render-if/components/render-if';
import Button from '../../form/components/button';
import PropTypes from 'prop-types';
import '../styles/add-module-item-modal.scss';
import ReducerHelpers from '../../reducer-helpers';
import Helpers from '../../helpers';
import { defaultPagingFilters, fields } from '../constants/constants';
import { debounce } from 'lodash';
import LoadMore from '../../load-more/components/load-more';

const AddModuleItemModal = (props, { t }) => {
  const { ItemRowComponent, linkedModuleItems, customCloseAction, moduleItemTitle, emptyStateLabel, setModuleItemsInReducer, fetchModuleItems, linkCall, unlinkCall } = props;
  const [placeholder, setPlaceholder] = useState(moduleItemTitle);
  const [moduleItems, setModuleItems] = useState([]);
  const [loadingModuleItems, setLoadingModuleItems] = useState(false);
  const [pagingFilters, setPagingFilters] = useState(defaultPagingFilters);
  const [moduleItemSearchText, setModuleItemSearchText] = useState('');
  const handleInputFocus = () => setPlaceholder('PROJECT.SEARCH.PLACEHOLDER');
  const handleInputBlur = () => setPlaceholder(moduleItemTitle);

  const searchModuleItems = useCallback(
    (searchText, loadMore = false) => {
      fetchModuleItems(
        searchText,
        pagingFilters,
        (newModuleItems, newPagingFilters) => {
          setModuleItems(loadMore ? [...moduleItems, ...newModuleItems] : newModuleItems);
          setPagingFilters({ ...pagingFilters, ...newPagingFilters });
          setModuleItemSearchText(searchText);
          if (loadMore) {
            Helpers.scrollIntoView('items-dropdown', `row-${pagingFilters[fields.lastSeen] - 1}`, 0);
          }
        },
        setLoadingModuleItems
      );
      // eslint-disable-next-line
    },
    [fetchModuleItems, pagingFilters, moduleItems]
  );

  // eslint-disable-next-line
  const handleModuleItemSearch = useCallback(debounce(searchModuleItems, 200), []);

  const handleModuleItemLink = moduleItem => {
    linkCall(moduleItem[fields.id], () => {
      setModuleItemsInReducer([...linkedModuleItems, moduleItem]);
      setModuleItems(ReducerHelpers.removeItemByProp(moduleItems, moduleItem, fields.id));
    });
  };

  const handleModuleItemUnlink = moduleItem => {
    unlinkCall(moduleItem[fields.id], () => {
      setModuleItemsInReducer(ReducerHelpers.removeItemByProp(linkedModuleItems, moduleItem, fields.id));
      setModuleItems([...moduleItems, moduleItem]);
    });
  };

  const handleLoadMoreClick = () => searchModuleItems(moduleItemSearchText, true);

  useEffect(() => {
    searchModuleItems('');
    // eslint-disable-next-line
  }, []);

  return (
    <div className="add-module-item-modal">
      <div className="add-module-item-modal__content">
        <div className="input-with-dropdown">
          <label className="search-input-label">{t(moduleItemTitle)}</label>
          <SearchInput
            onChange={e => handleModuleItemSearch(e.target.value)}
            placeholder={t(placeholder)}
            includeDropdown={true}
            emptyStateLabel={t(emptyStateLabel)}
            onInputFocus={handleInputFocus}
            onBlur={handleInputBlur}
            items={moduleItems}
            renderItem={(item, index) => {
              return (
                <>
                  <ItemRowComponent item={item} index={index} isDropdown action={handleModuleItemLink} />
                  <RenderIf if={index === moduleItems?.length - 1}>
                    <LoadMore
                      disabled={!pagingFilters[fields.hasNext]}
                      label="COMPONENT_HISTORY.LOAD_MORE"
                      showButton
                      buttonVariant="success-outline"
                      buttonWidth="sm"
                      onClick={handleLoadMoreClick}
                      showTotalItems={false}
                    />
                  </RenderIf>
                </>
              );
            }}
            isDropdownDataLoading={loadingModuleItems}
          />
        </div>
        <p className="f-secondary-dark bold mt-32">{t('ADDED')}</p>
        <RenderIf if={linkedModuleItems}>
          {linkedModuleItems.map((item, index) => {
            return (
              <div>
                <ItemRowComponent item={item} index={index} action={handleModuleItemUnlink} />
              </div>
            );
          })}
        </RenderIf>
      </div>
      <div className="add-module-item-modal__buttons">
        <Button onClick={customCloseAction} height="md" variant="success-outline" width="sm" text={t('CLOSE')} />
      </div>
    </div>
  );
};

AddModuleItemModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

AddModuleItemModal.propTypes = {
  ItemRowComponent: PropTypes.func.isRequired,
  linkedModuleItems: PropTypes.array.isRequired,
  customCloseAction: PropTypes.func.isRequired,
  moduleItemTitle: PropTypes.string.isRequired,
  emptyStateLabel: PropTypes.string.isRequired,
  setModuleItemsInReducer: PropTypes.func.isRequired,
  fetchModuleItems: PropTypes.func.isRequired,
  linkCall: PropTypes.func.isRequired,
  unlinkCall: PropTypes.func.isRequired,
};

export default AddModuleItemModal;
