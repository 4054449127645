import { PERMISSION_TYPES, PERMISSIONS } from '../../../../../common/permissions-constants';

export const fields = {
  id: 'ID',
  name: 'Name',
  isolationTemplate: 'IsolationTemplate',
  linkedPermits: 'LinkedPermits',
  status: 'Status',
  numberOfIsolations: 'NumberOfIsolations',
  keySafeNumber: 'KeyboxName',
  createDate: 'CreatedAt',
  createdBy: 'CreatedBy',
  createdByName: 'CreatedByName',
  statusCustom: 'statusCustom',
  components: 'Components',
  permits: 'Permits',
};

export const columnTypes = {
  status: 'status',
  date: 'date',
  statusCustom: 'statusCustom',
  keySafeNumber: 'keySafeNumber',
  linkedPermits: 'linkedPermits',
};

export const sortDirection = {
  asc: 'ASC',
  desc: 'DESC',
};

export const filterProps = {
  sortByColumn: 'SortByColumn',
  sortDirection: 'SortDirection',
  perPage: 'PerPage',
  lastSeen: 'LastSeen',
  searchText: 'SearchText',
  status: 'StatusFilter',
  archived: 'ArchivedFilter',
  singleSelected: 'SingleSelected',
  hasNext: 'HasNext',
  totalItems: 'TotalItems',
  projectId: 'ProjectID',
};

export const defaultFilters = {
  [filterProps.sortByColumn]: fields.id,
  [filterProps.sortDirection]: sortDirection.asc,
  [filterProps.perPage]: 20,
  [filterProps.lastSeen]: 0,
  [filterProps.searchText]: '',
  [filterProps.hasNext]: false,
  [filterProps.status]: [],
};

export const tableConfigLite = [
  {
    title: 'ISOLATION_CERTIFICATES.ID',
    key: fields.id,
    enableSort: true,
  },
  {
    title: 'ISOLATION_CERTIFICATES.NAME',
    key: fields.name,
    enableSort: true,
  },
  {
    key: fields.statusCustom,
    type: columnTypes.statusCustom,
    className: 'inline-right',
  },
];

export const tableConfig = [
  {
    title: 'ISOLATION_CERTIFICATES.ID',
    key: fields.id,
    enableSort: true,
    class: 'id-column',
  },
  {
    title: 'ISOLATION_CERTIFICATES.NAME',
    key: fields.name,
    enableSort: true,
  },
  {
    title: 'ISOLATION_CERTIFICATES.TEMPLATE_NAME',
    key: fields.isolationTemplate,
    enableSort: true,
  },
  {
    title: 'ISOLATION_CERTIFICATES.LINKED_PERMITS',
    key: fields.linkedPermits,
    enableSort: true,
    type: columnTypes.linkedPermits,
  },
  {
    title: 'ISOLATION_CERTIFICATES.STATUS',
    key: fields.status,
    enableSort: true,
    type: columnTypes.status,
    class: 'status-column',
  },
  {
    title: 'ISOLATION_CERTIFICATES.NO_OF_ISOLATIONS',
    key: fields.numberOfIsolations,
    enableSort: true,
  },
  {
    title: 'ISOLATION_CERTIFICATES.KEY_SAFE_NUMBER',
    key: fields.keySafeNumber,
    enableSort: true,
    type: columnTypes.keySafeNumber,
  },
  {
    title: 'ISOLATION_CERTIFICATES.CREATE_DATE',
    key: fields.createDate,
    enableSort: true,
    type: columnTypes.date,
  },
  {
    title: 'ISOLATION_CERTIFICATES.CREATED_BY',
    key: fields.createdByName,
    enableSort: true,
  },
];

export const statuses = {
  draft: {
    value: 'DRAFT',
    title: 'ISOLATION_CERTIFICATES.DRAFT',
    icon: 'clock',
    skipPermissionCheck: true,
    goToStatuses: ['RAISED'],
    isRegularStatus: true,
    modalTitle: 'ISOLATION_CERTIFICATE_STATUS.DRAFT_TITLE',
    editPermission: PERMISSIONS[PERMISSION_TYPES.isolationCertificates].edit.name,
    ownershipPermission: PERMISSIONS[PERMISSION_TYPES.isolationCertificates].create.name,
  },
  raised: {
    value: 'RAISED',
    title: 'ISOLATION_CERTIFICATES.RAISED',
    icon: 'clock-filled',
    editPermission: PERMISSIONS[PERMISSION_TYPES.isolationCertificates].edit.name,
    transitionPermission: PERMISSIONS[PERMISSION_TYPES.isolationCertificates].edit.name,
    ownershipPermission: PERMISSIONS[PERMISSION_TYPES.isolationCertificates].create.name,
    goToStatuses: ['APPROVED'],
    isRegularStatus: true,
    modalTitle: 'ISOLATION_CERTIFICATE_STATUS.RAISED_TITLE',
  },
  approved: {
    value: 'APPROVED',
    title: 'ISOLATION_CERTIFICATES.APPROVED',
    icon: 'checkmark-rounded',
    iconProps: {
      active: true,
    },
    iconFilledWhenActive: true,
    editPermission: PERMISSIONS[PERMISSION_TYPES.isolationCertificates].approveEdit.name,
    transitionPermission: PERMISSIONS[PERMISSION_TYPES.isolationCertificates].toApprove.name,
    goToStatuses: ['AUTHORISED'],
    isRegularStatus: true,
    modalTitle: 'ISOLATION_CERTIFICATE_STATUS.APPROVED_TITLE',
  },
  rejected: {
    value: 'REJECTED',
    title: 'ISOLATION_CERTIFICATES.REJECTED',
    icon: 'alert-octagon',
    editPermission: PERMISSIONS[PERMISSION_TYPES.isolationCertificates].edit.name,
    transitionPermission: PERMISSIONS[PERMISSION_TYPES.isolationCertificates].edit.name,
    fromStatuses: ['RAISED', 'APPROVED', 'AUTHORISED'],
    isRegularStatus: true,
    goToStatuses: ['DRAFT'],
    modalTitle: 'ISOLATION_CERTIFICATE_STATUS.REJECTED_TITLE',
  },
  authorised: {
    value: 'AUTHORISED',
    title: 'ISOLATION_CERTIFICATES.AUTHORISED',
    icon: 'check-rounded',
    iconProps: {
      active: true,
    },
    editPermission: PERMISSIONS[PERMISSION_TYPES.isolationCertificates].authoriseEdit.name,
    transitionPermission: PERMISSIONS[PERMISSION_TYPES.isolationCertificates].toAuthorise.name,
    goToStatuses: ['LIVE'],
    modalTitle: 'ISOLATION_CERTIFICATE_STATUS.AUTHORISED_TITLE',
    actionButtonText: 'ISOLATION_CERTIFICATE_STATUS.AUTHORISED_BUTTON',
    sectionName: 'Authorised',
    isRegularStatus: true,
  },
  underTest: {
    value: 'UNDER_TEST',
    title: 'ISOLATION_CERTIFICATES.UNDER_TEST',
    icon: 'flag',
    iconProps: {
      active: true,
    },
    iconFilledWhenActive: true,
    editPermission: PERMISSIONS[PERMISSION_TYPES.isolationCertificates].underTestEdit.name,
    transitionPermission: PERMISSIONS[PERMISSION_TYPES.isolationCertificates].toUnderTest.name,
    goToStatuses: ['LIVE', 'LONG_TERM'],
    modalTitle: 'ISOLATION_CERTIFICATE_STATUS.UNDER_TEST_TITLE',
    actionButtonText: 'ISOLATION_CERTIFICATE_STATUS.UNDER_TEST_BUTTON',
    sectionName: 'Under Test',
  },
  live: {
    value: 'LIVE',
    title: 'ISOLATION_CERTIFICATES.LIVE',
    icon: 'checkmark',
    iconProps: {
      active: true,
    },
    editPermission: PERMISSIONS[PERMISSION_TYPES.isolationCertificates].liveEdit.name,
    transitionPermission: PERMISSIONS[PERMISSION_TYPES.isolationCertificates].toLive.name,
    goToStatuses: ['DEISOLATED', 'LONG_TERM', 'UNDER_TEST'],
    modalTitle: 'ISOLATION_CERTIFICATE_STATUS.LIVE_TITLE',
    actionButtonText: 'ISOLATION_CERTIFICATE_STATUS.LIVE_BUTTON',
    sectionName: 'Live',
  },
  longTerm: {
    value: 'LONG_TERM',
    title: 'ISOLATION_CERTIFICATES.LONG_TERM',
    icon: 'clock-rounded',
    iconProps: {
      active: true,
    },
    iconFilledWhenActive: true,
    editPermission: PERMISSIONS[PERMISSION_TYPES.isolationCertificates].longTermEdit.name,
    transitionPermission: PERMISSIONS[PERMISSION_TYPES.isolationCertificates].toLongTerm.name,
    goToStatuses: ['DEISOLATED'],
    modalTitle: 'ISOLATION_CERTIFICATE_STATUS.LONG_TERM_TITLE',
    actionButtonText: 'ISOLATION_CERTIFICATE_STATUS.LONG_TERM_BUTTON',
    sectionName: 'Long Term',
  },
  deIsolated: {
    value: 'DEISOLATED',
    title: 'ISOLATION_CERTIFICATES.DE_ISOLATED',
    icon: 'box',
    transitionPermission: PERMISSIONS[PERMISSION_TYPES.isolationCertificates].toDeIsolate.name,
    modalTitle: 'ISOLATION_CERTIFICATE_STATUS.DE_ISOLATED_TITLE',
    actionButtonText: 'ISOLATION_CERTIFICATE_STATUS.DE_ISOLATED_BUTTON',
    sectionName: 'Deisolated',
  },
  archived: {
    value: 'ARCHIVED',
    title: 'ISOLATION_CERTIFICATES.ARCHIVED',
    isSingleSelect: true,
  },
};
