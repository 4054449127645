import PropTypes from 'prop-types';
import React from 'react';
import EmptyState from '../../../../../../../common/empty-state-v2/components/empty-state';
import ExpandableSection from '../../../../../../../common/expandable-section/components/expandable-section';
import Icon from '../../../../../../../common/icon/components/icon';
import RenderIf from '../../../../../../../common/render-if/components/render-if';
import { alarmColorPalette, formConstants as alarmFormConstants } from '../../../constants/alarm-constants';
import { formConstants } from '../../../constants/measurement-point-constants';

import '../../../styles/alarms-section.scss';
import AlarmSectionList from './alarm-section-list';

const AlarmsSection = ({ alarms, openAlarmsEditModal, unit, readonly }, { t }) => {
  return (
    <React.Fragment>
      <RenderIf if={alarms}>
        <div className="alarms-section">
          {alarms.map((alarm, index) => (
            <div className="component-item-header" key={`section-component-item-${index}`}>
              <ExpandableSection
                CustomComponent={({ isExpanded }) => (
                  <div className="component-header">
                    <div className={`alarm-icon ${alarmColorPalette[alarm[formConstants.fields.color]].name}`}>
                      <Icon size="sm" name="alarm-bell-icon" handleHover={false} disabled={true} />
                    </div>
                    <h6 className="f-primary component-header-title">
                      <span className="capitalize">{alarm[formConstants.fields.name]}</span>
                      {` - ${alarm[alarmFormConstants.fields.descriptor.name][formConstants.fields.description]} ${alarm[alarmFormConstants.fields.alarmLevel.name]} ${unit}`}
                    </h6>
                    <Icon name={isExpanded ? 'chevron-up' : 'chevron-down'} className="component-header-toggle" />
                  </div>
                )}
                className="expanded-section"
                hideExpandAction
              >
                <AlarmSectionList alarm={alarm} unit={unit} />
              </ExpandableSection>
            </div>
          ))}
        </div>
      </RenderIf>
      <RenderIf if={alarms && alarms.length === 0}>
        <EmptyState transparent emptyStateText={t('ALARMS.SECTION.EMPTY_STATE')} showButton={!readonly} buttonText={t('ALARMS.SECTION.ADD_ALARM')} buttonAction={openAlarmsEditModal} />
      </RenderIf>
    </React.Fragment>
  );
};

AlarmsSection.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default AlarmsSection;
