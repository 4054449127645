import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { find, isEmpty } from 'lodash';

import SearchInput from '../../input/components/search-input';
import Button from '../../form/components/button';

import { additionalFields, componentDetailsDisplayProps, componentsPickerSelectKeys } from '../constants/question-constants';
import Helpers from '../../helpers';

import '../styles/choose-components.scss';
import LoadMore from '../../load-more/components/load-more';

class ChooseComponents extends Component {
  renderComponentDetails = () => {
    const { t } = this.context;
    const { selectedComponent } = this.props;
    const actionsMenu = Helpers.mapInfoIconDisplayProps(selectedComponent, componentDetailsDisplayProps);
    if (!actionsMenu) {
      return null;
    }
    if (typeof actionsMenu === 'object' && !isEmpty(actionsMenu)) {
      return (
        <div className="container">
          {Object.keys(actionsMenu).map((key, index) => {
            const value = actionsMenu[key];
            return (
              <Fragment key={index}>
                <label className="f-secondary-dark label">{t(key)}:</label>
                <p className="f-primary value">{value || '--'}</p>
              </Fragment>
            );
          })}
        </div>
      );
    }
  };

  render() {
    const { t } = this.context;
    const {
      selectedComponents,
      handleComponentSearch,
      filteredComponents,
      onComponentChange,
      handleInputFocus,
      selectedComponent,
      addSelectedComponent,
      searchApiRequired,
      componentsFilterProps,
      loadMoreOnClick,
      keepComponentsDropdownVisible,
      componentsLoading,
    } = this.props;
    const { component } = additionalFields;

    return (
      <div className="choose-components">
        <h5 className="f-primary">{t('QUESTION_COMPONENT_PICKER.CHOOSE_COMPONENTS')}</h5>
        <SearchInput
          onChange={e => handleComponentSearch(e.target.value)}
          placeholder={t('SECTIONS.QUESTION_COMPONENT_SEARCH')}
          includeDropdown
          keepDropdownVisible={keepComponentsDropdownVisible && !selectedComponent}
          items={filteredComponents}
          emptyStateLabel={'SECTIONS.QUESTION_COMPONENT_SEARCH_PLACEHOLDER'}
          onInputFocus={handleInputFocus}
          isDropdownDataLoading={componentsLoading}
          renderItem={(item, index) => {
            const isDisabled = find(selectedComponents, i => item[componentsPickerSelectKeys.valueKey] === i.ComponentID);
            return (
              <>
                <p key={index} id={`row-${index}`} className={`pointer ${isDisabled ? 'disabled' : ''}`} onClick={isDisabled ? () => null : () => onComponentChange(item)}>
                  {item[component.name]}
                </p>
                {searchApiRequired && filteredComponents?.length > 0 && filteredComponents.length - index === 1 && componentsFilterProps.HasNext && (
                  <LoadMore
                    disabled={!componentsFilterProps.HasNext}
                    label="COMPONENT_HISTORY.LOAD_MORE"
                    showButton
                    buttonVariant="success-outline"
                    buttonWidth="sm"
                    onClick={loadMoreOnClick}
                    showTotalItems={false}
                  />
                )}
              </>
            );
          }}
        />
        {selectedComponent && (
          <div className="component-details">
            <div className="component-details__buttons">
              <Button type="button" width="lg" onClick={addSelectedComponent} text={t('QUESTION_COMPONENT_PICKER.ADD_COMPONENT_BUTTON')} />
            </div>
            <div className="component-details__wrapper">{this.renderComponentDetails()}</div>
          </div>
        )}
      </div>
    );
  }
}

ChooseComponents.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ChooseComponents;
