import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ChromePicker } from 'react-color';
import Helpers from '../../../../../common/helpers';
import '../styles/color-picker.scss';

class ColorPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayColorPicker: false,
      internalColor: props.color || props.defaultColor,
    };
    this.node = React.createRef();
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  handleClick = e => {
    if (this.node && this.node.current && this.node.current.contains(e.target)) {
      // element is clicked no need to call click outside handler function
      return;
    }
    const { displayColorPicker } = this.state;
    if (displayColorPicker) this.handleClose();
  };

  togglePicker = e => {
    const { disableColorPicker } = this.props;
    if (disableColorPicker) {
      return;
    }
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = () => {
    const { onColorChange, changeField, name } = this.props;
    let { internalColor } = this.state;

    changeField(name, internalColor);

    if (onColorChange) {
      onColorChange(internalColor);
    }
  };

  handleColorChange = color => {
    if (color.rgb) {
      color = Helpers.RGBAToHexA(color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a);
    }
    this.setState({ internalColor: color });
  };
  render() {
    const { t } = this.context;
    const { internalColor } = this.state;
    const { color, label, labelClass, id, className, size, showLabel, title, disableColorPicker } = this.props;

    return (
      <div className={`inspection-input ${className || ''}`} title={disableColorPicker ? t('START_WORKFLOW.COLOR_PICKER_DISABLED') : title || ''}>
        {label && (
          <label className={`inspection-input__label ${labelClass || 'f-secondary-dark'}`} htmlFor={id}>
            {label}
          </label>
        )}
        <div className={`color-picker ${disableColorPicker ? 'disabled' : ''}`}>
          <div
            ref={this.node}
            className={`color-picker__circle ${size}`}
            onClick={this.togglePicker}
            style={{ backgroundColor: color, border: size === 'small' ? `solid 1px ${color}` : `solid 2px ${color}` }}
          >
            <div>
              {this.state.displayColorPicker ? (
                <div onClick={e => e.stopPropagation()} className="color-picker__popover" onMouseUp={this.handleChange}>
                  <div className="color-picker__cover" />
                  <ChromePicker color={internalColor} onChange={this.handleColorChange} />
                </div>
              ) : null}
            </div>
          </div>
          {showLabel && (
            <div className="color-picker__value">
              <p className="f-primary text">{color}</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}
ColorPicker.contextTypes = {
  t: PropTypes.func.isRequired,
};

ColorPicker.propTypes = {
  changeField: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['large', 'medium', 'small']),
};

ColorPicker.defaultProps = {
  size: 'medium',
  showLabel: true,
};

export default ColorPicker;
