import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import TruncatedText from '../../form/components/truncated-text';

export const DropdownContainer = ({ children }) => <Fragment>{children}</Fragment>;

class InfoPopover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hoveredItem: null,
      selectedItem: null,
    };
    this.node = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, true);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, true);
  }

  handleClick = e => {
    const { clickOutsideHandler, customNode } = this.props;
    if (customNode && customNode.current && customNode.current.contains(e.target)) return;

    if (this.node && this.node.current && this.node.current.contains(e.target)) {
      // element is clicked no need to call click outside handler function
      return;
    }

    if (clickOutsideHandler && typeof clickOutsideHandler === 'function') clickOutsideHandler();
  };

  handleItemHoverEnter = hoveredItem => {
    this.setState({ hoveredItem });
  };
  handleItemHoverExit = () => {
    this.setState({ hoveredItem: null });
  };

  handleItemClick = selectedItem => {
    if (this.state.selectedItem === selectedItem) {
      this.setState({ selectedItem: null });
    }
    this.setState({ selectedItem });
  };

  render() {
    const { isOpen, children, className, position, anchor, title } = this.props;
    const { hoveredItem, selectedItem } = this.state;
    const menuItemsWrapper = children(selectedItem, hoveredItem);
    const childrenWithProps = React.Children.map(menuItemsWrapper.props.children, child => {
      const { onMouseEnter, onMouseLeave, onClick, id } = child.props;
      return React.cloneElement(child, {
        onMouseEnter: () => {
          this.handleItemHoverEnter(id);
          if (onMouseEnter && typeof onMouseEnter === 'function') onMouseEnter();
        },
        onMouseLeave: () => {
          this.handleItemHoverExit(id);
          if (onMouseLeave && typeof onMouseLeave === 'function') onMouseLeave();
        },
        onClick: () => {
          this.handleItemClick(id);
          if (onClick && typeof onClick === 'function') onClick();
        },
      });
    });

    return (
      <div ref={this.node} className={`info-popover info-popover${isOpen ? '--visible' : '--hidden'} ${anchor} ${className || ''}`} style={position}>
        <>
          {title && <TruncatedText className="bold tooltip-title" text={title} />}
          {childrenWithProps}
        </>
      </div>
    );
  }
}

InfoPopover.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.func.isRequired,
  clickOutsideHandler: PropTypes.func,
  anchor: PropTypes.oneOf(['top-left', 'top-right', 'center-top', 'center-bottom', 'bottom-left', 'bottom-right', 'center']),
};

export default InfoPopover;
