import React, { Component } from 'react';
import { isEmpty, eq, values, map, find } from 'lodash';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';

import Helpers from '../../../../common/helpers';

import NDTMeasurementChart from './ndt-measurement-chart';
import BasicInput from '../common/input/basic-input';
import Button from '../../../../common/form/components/button';
import SettingsPoints from '../right-toolbar/settings-points';
import ExpandableSection from '../../../../common/expandable-section/components/expandable-section';
import CustomSelect from '../../../../common/form/components/select';
import InfoTooltip from '../../../../common/tooltip/components/info-tooltip';
import Textarea from '../../../../common/form/components/text-area';
import AutoComplete from '../common/input/autocomplete';
import CustomProperties from '../../../../common/custom-property/components/custom-properties';

import FieldUpload from './ndt-measurement-assets';
import ItemsRenderer from '../../../../common/items-renderer/components/items-renderer';
import AccessRenderer from '../../../../common/access-renderer/components/access-renderer';
import Icon from '../../../../common/icon/components/icon';

import { AMAZON_IMAGE_SIZES, FEATURES, FORMS } from '../../../../common/constants';
import { alarmLevelThemeColors, measurementConstants as formConstants, uploadGroups } from '../../constants/ndt-constants';
import ColorPalette from '../../../../common/form/components/color-pallete';

import { validate } from './validators/measurement-validator';
import { measurementDropdownActions } from '../../constants/measurement-constants';

import {
  setComponentDetails,
  //properties
  updateNDTProperty,
  getNDTMeasurementProperties,
  addNDTMeasurementProperty,
  getNDTMeasurementPropertyNames,
  deleteNDTMeasurementProperty,
} from '../../actions/inspection-actions';
import { deleteMeasurement, deleteMeasurementFile, getFilterGroupSuggestions, updateMeasurementGeometry } from '../../actions/ndt-actions';
import { clearFilterGroupSuggestions, handleDeleteNDTMeasurementModal, setComponentDetailsData } from '../../actions/action-creators';

import uploadConstants from '../../../upload/constants/constants';
import { formConstants as componentConstants, showFields } from '../../constants/component-constants';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../common/permissions-constants';
import { modules } from '../../constants/constants';

import '../../styles/component-form.scss';
import '../../styles/ndt-measurement-form.scss';

class NDTMeasurementForm extends Component {
  constructor(props) {
    super(props);
    const colors = values(alarmLevelThemeColors);
    this.state = {
      details: null,
      imageType: AMAZON_IMAGE_SIZES.small.name,

      statusButtons: map(colors, item => {
        return {
          ...item,
          color: props.severityColors[item.key],
        };
      }),
    };
  }

  formatDate = input => {
    if (!input) return;

    return Helpers.getDateFromUnix(input);
  };

  componentDidMount = () => {
    const { componentDetails } = this.props;
    this.setState({ details: Helpers.mapInfoIconDisplayProps(componentDetails, showFields) });
  };

  componentDidUpdate(prevProps) {
    const { componentID, setComponentDetails, setComponentDetailsData, componentDetails } = this.props;
    if (componentID !== prevProps.componentID) {
      if (componentID) {
        setComponentDetails(componentID);
      } else {
        setComponentDetailsData(null);
      }
    }
    if (!eq(componentDetails, prevProps.componentDetails)) {
      this.setState({ details: Helpers.mapInfoIconDisplayProps(componentDetails, showFields) });
    }
  }

  openDeleteMeasurementModal = () => {
    const { t } = this.context;
    const { selectedMeasurement, toggleDeleteModal, deleteMeasurement, measurementLocations, handleActivePage } = this.props;
    if (isEmpty(selectedMeasurement)) return;
    const modalData = {
      isOpen: true,
      content: t('NDT_MEASUREMENT_DELETE.DESC', { measurementName: selectedMeasurement[formConstants.fields.name] }),
      type: 'yes-no',
      confirmAction: () => deleteMeasurement({ MeasurementID: selectedMeasurement[formConstants.fields.id] }, measurementLocations, () => handleActivePage(modules.ndtData)),
      closeAction: this.closeDeleteMeasurementModal,
      customClassName: 'modal-medium',
    };
    toggleDeleteModal(modalData);
  };

  openDeleteFileModal = (fileID, measurementId) => {
    const { t } = this.context;
    const { toggleDeleteModal, deleteFile } = this.props;

    const modalData = {
      isOpen: true,
      content: t('NDT_MEASUREMENT_DELETE_FILE.DESC'),
      type: 'yes-no',
      confirmAction: () => {
        deleteFile(fileID, measurementId);
        this.closeDeleteMeasurementModal();
      },
      closeAction: this.closeDeleteMeasurementModal,
      customClassName: 'modal-medium',
    };
    toggleDeleteModal(modalData);
  };

  closeDeleteMeasurementModal = () => {
    const { toggleDeleteModal } = this.props;
    const modalData = {
      isOpen: false,
    };
    toggleDeleteModal(modalData);
  };

  getColor = status => {
    const { statusButtons } = this.state;
    return (find(statusButtons, i => i.status === status) || {}).color || statusButtons[0].color;
  };

  alarmStatuses = alarms => {
    const { t } = this.context;
    return (
      <div className={`inspection-input`}>
        <label className={`inspection-input__label f-secondary-dark defect-form__label`}>{t('NDT_MEASUREMENT_DETAILS.FORM_ALARM_STATUS')}</label>
        <div className={`inspection-input__input-wrapper border`}>
          {isEmpty(alarms) ? (
            <div className="alarm-status">
              <div className="status-icon" style={{ backgroundColor: this.getColor('') }} />
              <p className="input f-primary">{t('NDT_MEASUREMENT_DETAILS.FORM_ALARM_STATUS.EMPTY')}</p>
            </div>
          ) : (
            alarms.map(alarm => (
              <div key={alarm[formConstants.fields.measurementAlarmName]} className="alarm-status">
                <div className="status-icon" style={{ backgroundColor: this.getColor(alarm[formConstants.fields.measurementAlarmColor]) }} />
                <p className="input f-primary">{t(`NDT_ALARMS_MODAL.FORM_LABELS_VALUE_${alarm[formConstants.fields.measurementAlarmName]}`, { unit: '' })}</p>
              </div>
            ))
          )}
        </div>
        <div className={`error-message`} />
      </div>
    );
  };

  render() {
    const { t } = this.context;
    const { details, imageType } = this.state;
    const {
      selectedMeasurement,
      markPointLocation,
      invalid,
      coordinates,
      showGeometryWarning,
      openNDTModal,
      changeField,
      viewer,
      components,
      componentDetails,
      componentID,
      openUploadModal,
      openFile,
      measurementFiles,
      openAlarmsModal,
      unit,
      measurmentID,
      uploadProgressStyle,
      openDownloadReportModalForMeasurments,
      filterGroupSuggestions,
      fetchFilterGroupSuggestions,
      clearFilterGroupSuggestions,
      inspectionId,
      measurementNotifications,
      handleActivePage,
      openSetNotificationsModal,

      // properties
      properties,
      updateNDTProperty,
      getNDTMeasurementProperties,
      addNDTMeasurementProperty,
      getNDTMeasurementPropertyNames,
      deleteNDTMeasurementProperty,
      handleSubmit,
      updateGeometry,
      measurementLocations,
      updateMeasurementComponent,
    } = this.props;
    const images = !isEmpty(measurementFiles) ? measurementFiles[uploadGroups.imageFiles] || [] : [],
      files = !isEmpty(measurementFiles) ? measurementFiles[uploadGroups.otherFiles] || [] : [];

    const componentsData = components.map(el => ({ label: el[componentConstants.fields.name], value: el[componentConstants.fields.id] }));
    const selectedComponent = components.find(el => el[componentConstants.fields.id] === componentID);

    const ownerID = selectedMeasurement[formConstants.fields.createdByID];

    return (
      <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.ndt].edit.name} id={ownerID} ownerRequiredPermission={PERMISSIONS[PERMISSION_TYPES.ndt].create.name}>
        {({ hasAccess }) => {
          const readonly = !hasAccess;

          return (
            <form className="defect-form component-form ndt-measurement-form" noValidate onSubmit={handleSubmit}>
              <ExpandableSection expanded={true} title={t('SECTION_TITLE.NDT_MEASUREMENT_DETAILS')}>
                {showGeometryWarning && isEmpty(coordinates) && (
                  <div className="add-pin">
                    <div className="missing-pin">
                      <Icon name="warning" warning handleHover={false} size="md" className="svg-warning-g missing-pin__icon" />
                      <p className="f-warning missing-pin__text">{t('WARNING.MISSING_PIN')}</p>
                    </div>
                    <p className="f-warning add-pin__description">{t('NDT_MEASUREMENT.WARNING.MISSING_PIN.DESCRIPTION')}</p>
                    {/* MARK PIN ON 3D BUTTON */}
                    <Button
                      width="lg"
                      disabled={invalid || !hasAccess}
                      onClick={() =>
                        markPointLocation(measurementDropdownActions.point, newBody => {
                          if (newBody.Geometry) {
                            updateGeometry({ ...newBody, [formConstants.fields.id]: measurmentID }, measurementLocations, newData => changeField('Geometry', newData.Geometry));
                          }
                        })
                      }
                      className="add-pin__button"
                      text={t('MARK_ON_3D.BUTTON.TEXT')}
                    />
                    {invalid && <p className="f-secondary-red">{t('COMPONENT_DETAILS.MISSING_PIN.FORM_INVALID.ERROR_MESSAGE')}</p>}
                  </div>
                )}
                <Field id={formConstants.fields.color} name={formConstants.fields.color} component={ColorPalette} disabled={readonly} />
                <NDTMeasurementChart unit={unit} measurementId={selectedMeasurement[formConstants.fields.id]} />
                <div className="buttons">
                  <Button type="button" variant="gray-outline" className="space-bottom" onClick={() => openNDTModal(unit)} text={t('NDT_MEASUREMENT_DETAILS.EXPAND_GRAPH_BUTTON_TEXT')} />
                  <Button
                    type="button"
                    variant="gray-outline"
                    disabled={!measurmentID || measurmentID < 0}
                    onClick={() => openAlarmsModal(unit, measurmentID)}
                    text={t('NDT_MEASUREMENT_DETAILS.ALARMS_BUTTON_TEXT')}
                  />
                </div>
                {this.alarmStatuses(selectedMeasurement[formConstants.fields.measurementAlarms])}
                <Field
                  id={formConstants.fields.name}
                  name={formConstants.fields.name}
                  component={BasicInput}
                  disabled={readonly}
                  placeholder={t('NDT_MEASUREMENT_DETAILS.FORM_NAME')}
                  label={t('NDT_MEASUREMENT_DETAILS.FORM_NAME')}
                  labelClass="f-secondary-dark defect-form__label"
                  type="text"
                />
                <Field
                  id={formConstants.fields.unit}
                  name={formConstants.fields.unit}
                  component={BasicInput}
                  disabled={readonly}
                  placeholder={t('NDT_MEASUREMENT_DETAILS.FORM_MEASUREMENT_UNIT')}
                  label={t('NDT_MEASUREMENT_DETAILS.FORM_MEASUREMENT_UNIT')}
                  labelClass="f-secondary-dark defect-form__label"
                  type="text"
                />
                <div className="inline-wrapper">
                  <Field
                    id={formConstants.fields.component}
                    disabled={readonly}
                    disabledItems={readonly ? componentsData : []}
                    name={formConstants.fields.component}
                    onChange={component => {
                      changeField(formConstants.fields.componentID, component.value);
                      updateMeasurementComponent(selectedMeasurement[formConstants.fields.id], component.value);
                    }}
                    labelActions={[
                      {
                        IconComponent: props =>
                          componentDetails ? (
                            <InfoTooltip
                              actionsMenu={details || {}}
                              offsetY={10}
                              offsetX={-200}
                              Component={() => <p className="f-secondary-green link noselect">{t('SHOW_COMPONENT_DETAILS')}</p>}
                              className="image-details inline-wrapper__right-content"
                              componentProps={{ title: '' }}
                            />
                          ) : null,
                      },
                    ]}
                    component={CustomSelect}
                    data={componentsData}
                    defaultValue={
                      selectedComponent ? { label: selectedComponent[componentConstants.fields.name], value: componentID } : { label: t('DEFECT_DETAILS.COMPONENT_PLACEHOLDER'), value: null }
                    }
                    className="inline-wrapper__left-content"
                    placeholder={t('DEFECT_DETAILS.COMPONENT_PLACEHOLDER')}
                    label={'NDT_MEASUREMENT_DETAILS.COMPONENT'}
                    labelClass="f-secondary-dark inline-wrapper__left-content"
                  />
                </div>
                <Field
                  id={formConstants.fields.filterGroup}
                  name={formConstants.fields.filterGroup}
                  component={AutoComplete}
                  disabled={readonly}
                  suggestions={Helpers.convertSuggestionArray(filterGroupSuggestions)}
                  fetchSuggestions={val => fetchFilterGroupSuggestions(inspectionId, val)}
                  clearSuggestions={clearFilterGroupSuggestions}
                  placeholder={t('NDT_MEASUREMENT_DETAILS.FORM_MEASUREMENT_FILTER_GROUP')}
                  label={t('NDT_MEASUREMENT_DETAILS.FORM_MEASUREMENT_FILTER_GROUP')}
                  labelClass="f-secondary-dark defect-form__label"
                  type="text"
                />
                <Field
                  id={formConstants.fields.description}
                  name={formConstants.fields.description}
                  component={Textarea}
                  disabled={readonly}
                  placeholder={'NDT_MEASUREMENT_DETAILS.FORM_MEASUREMENT_DESCRIPTION'}
                  label={'NDT_MEASUREMENT_DETAILS.FORM_MEASUREMENT_DESCRIPTION'}
                  labelClass="f-secondary-dark defect-form__label"
                  maxChars={2000}
                  enableAutoResize={true}
                  wrapperClassName={'inspection-input'}
                />
                <Field
                  id={formConstants.fields.threshold}
                  name={formConstants.fields.threshold}
                  component={BasicInput}
                  disabled={readonly}
                  placeholder={t('NDT_MEASUREMENT_DETAILS.FORM_MEASUREMENT_THRESHOLD')}
                  label={t('NDT_MEASUREMENT_DETAILS.FORM_MEASUREMENT_THRESHOLD')}
                  labelClass="f-secondary-dark defect-form__label"
                  type="text"
                />
                <Field
                  id={formConstants.fields.lastMeasurementDate}
                  disabled={true}
                  name={formConstants.fields.lastMeasurementDate}
                  component={BasicInput}
                  format={this.formatDate}
                  placeholder={t('NDT_MEASUREMENT_DETAILS.FORM_LAST_DATE')}
                  label={t('NDT_MEASUREMENT_DETAILS.FORM_LAST_DATE')}
                  labelClass="f-secondary-dark defect-form__label"
                  type="text"
                />
                <Field
                  id={formConstants.fields.lastMeasurementValue}
                  disabled={true}
                  name={formConstants.fields.lastMeasurementValue}
                  component={BasicInput}
                  placeholder={t('NDT_MEASUREMENT_DETAILS.FORM_LAST_VALUE', { unit })}
                  label={t('NDT_MEASUREMENT_DETAILS.FORM_LAST_VALUE', { unit })}
                  labelClass="f-secondary-dark defect-form__label"
                  type="text"
                />

                <Field
                  id={formConstants.fields.measurementNumber}
                  disabled={true}
                  name={formConstants.fields.measurementNumber}
                  component={BasicInput}
                  placeholder={t('NDT_MEASUREMENT_DETAILS.FORM_MEASUREMENTS_NUMBER')}
                  label={t('NDT_MEASUREMENT_DETAILS.FORM_MEASUREMENTS_NUMBER')}
                  labelClass="f-secondary-dark defect-form__label"
                  type="text"
                />
                <Field
                  id={formConstants.fields.createdDate}
                  disabled={true}
                  name={formConstants.fields.createdDate}
                  component={BasicInput}
                  format={this.formatDate}
                  placeholder={t('NDT_MEASUREMENT_DETAILS.DATE_CREATED')}
                  label={t('NDT_MEASUREMENT_DETAILS.DATE_CREATED')}
                  labelClass="f-secondary-dark defect-form__label"
                  type="text"
                />
                <Field
                  id={formConstants.fields.createdBy}
                  disabled={true}
                  name={formConstants.fields.createdBy}
                  component={BasicInput}
                  placeholder={t('NDT_MEASUREMENT_DETAILS.CREATED_BY')}
                  label={t('NDT_MEASUREMENT_DETAILS.CREATED_BY')}
                  labelClass="f-secondary-dark defect-form__label"
                  type="text"
                />

                <SettingsPoints changeField={changeField} focusCamera={false} viewer={viewer} disabled={readonly} />

                <CustomProperties
                  isDisabled={readonly}
                  formName={FORMS.dynamicNDTMeasurementForm}
                  properties={properties}
                  getProperties={() => getNDTMeasurementProperties(selectedMeasurement.ID)}
                  updateProperty={property => updateNDTProperty(property)}
                  addProperty={data => addNDTMeasurementProperty(data)}
                  restProps={{ MeasurementID: selectedMeasurement.ID }}
                  deleteProperty={(data, calllback) => deleteNDTMeasurementProperty(data, calllback)}
                  getSuggestions={getNDTMeasurementPropertyNames}
                />
              </ExpandableSection>
              <ExpandableSection title={t('SECTION_TITLE.COMPONENT_FILES')}>
                <FieldUpload
                  label={t('NDT_MEASUREMENT_DETAILS.FORM_IMAGES')}
                  labelClass="f-secondary-dark defect-form__label"
                  noFileText={t('NDT_MEASUREMENT_DETAILS.FORM_NO_IMAGES')}
                  openUploadModal={onLocalUpload => openUploadModal(onLocalUpload, uploadGroups.images)}
                  uploadType={uploadConstants.uploadType.ndtMeasurementFiles}
                  files={images}
                  restUploadParams={{ UploadGroup: uploadGroups.images }}
                  imageType={imageType}
                  disabled={readonly}
                  uploadProgressStyle={uploadProgressStyle}
                  preview="image-thumbnail"
                  actionsMenu={[
                    {
                      title: 'COMPONENT_ASSETS_DROPDOWN.SETTINGS_MENU.ITEM_1',
                      action: data => {
                        openFile(data, images);
                      },
                    },
                    {
                      title: 'COMPONENT_ASSETS_DROPDOWN.SETTINGS_MENU.ITEM_2',
                      action: data => {
                        Helpers.getFileExtensionAndDownload(data);
                      },
                    },
                    {
                      title: 'COMPONENT_ASSETS_DROPDOWN.SETTINGS_MENU.ITEM_4',
                      action: ({ FileID }) => this.openDeleteFileModal(FileID, selectedMeasurement[formConstants.fields.id]),
                      access: {
                        id: ownerID,
                        ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.ndt].create.name,
                        visibleFor: [PERMISSIONS[PERMISSION_TYPES.ndt].edit.name],
                      },
                    },
                  ]}
                />
                <FieldUpload
                  label={t('NDT_MEASUREMENT_DETAILS.FORM_FILES')}
                  labelClass="f-secondary-dark defect-form__label"
                  noFileText={t('NDT_MEASUREMENT_DETAILS.FORM_NO_FILES')}
                  openUploadModal={onLocalUpload => openUploadModal(onLocalUpload, uploadGroups.files)}
                  uploadType={uploadConstants.uploadType.ndtMeasurementFiles}
                  files={files}
                  disabled={readonly}
                  restUploadParams={{ UploadGroup: uploadGroups.files }}
                  imageType={imageType}
                  uploadProgressStyle={uploadProgressStyle}
                  actionsMenu={[
                    {
                      title: 'COMPONENT_ASSETS_DROPDOWN.SETTINGS_MENU.ITEM_1',
                      action: data => {
                        openFile(data);
                      },
                    },
                    {
                      title: 'COMPONENT_ASSETS_DROPDOWN.SETTINGS_MENU.ITEM_2',
                      action: data => {
                        Helpers.getFileExtensionAndDownload(data);
                      },
                    },
                    {
                      title: 'COMPONENT_ASSETS_DROPDOWN.SETTINGS_MENU.ITEM_4',
                      action: ({ FileID }) => this.openDeleteFileModal(FileID, selectedMeasurement[formConstants.fields.id]),
                      access: {
                        id: ownerID,
                        ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.ndt].create.name,
                        visibleFor: [PERMISSIONS[PERMISSION_TYPES.ndt].edit.name],
                      },
                    },
                  ]}
                />
              </ExpandableSection>
              {FEATURES.notifications.visible && (
                <ExpandableSection
                  expanded={true}
                  title={t('NDT_DETAILS.SECTION_NOTIFICATIONS')}
                  CustomComponent={() => (
                    <p
                      className="f-secondary-green link noselect settings-btn"
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        openSetNotificationsModal();
                      }}
                    >
                      {t('NDT_DETAILS.SECTION_NOTIFICATIONS_SETTINGS')}
                    </p>
                  )}
                >
                  <>
                    <ItemsRenderer
                      items={measurementNotifications}
                      className={'ob-notifications'}
                      renderLeftComponent={({ ID }) => {
                        return <span className="f-secondary-dark">#{ID}</span>;
                      }}
                      handleItemClick={(e, element) => {
                        handleActivePage(modules.notifications, element.ID);
                      }}
                      CustomEmptyState={() => {
                        return <p className="f-primary"> {t('DEFECT_DETAILS.NOTIFICATIONS_EMPTY_STATE')}</p>;
                      }}
                    />
                  </>
                </ExpandableSection>
              )}
              <div className="buttons">
                <Button
                  type="button"
                  variant="success-outline"
                  text={t('NDT_MEASUREMENT_DETAILS.GENERATE_REPORT')}
                  onClick={() => openDownloadReportModalForMeasurments(measurmentID)}
                  className="space-bottom"
                />
                <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.ndt].delete.name}>
                  {({ hasAccess }) => {
                    return (
                      <Button disabled={!hasAccess} type="button" variant="danger-outline" onClick={this.openDeleteMeasurementModal} text={t('NDT_MEASUREMENT_DETAILS.FORM_DELETE_MEASUREMENT')} />
                    );
                  }}
                </AccessRenderer>
              </div>
            </form>
          );
        }}
      </AccessRenderer>
    );
  }
}
const selector = formValueSelector(FORMS.ndtMeasurementForm);

NDTMeasurementForm.contextTypes = {
  t: PropTypes.func.isRequired,
};
NDTMeasurementForm = reduxForm({
  form: FORMS.ndtMeasurementForm,
  validate,
  enableReinitialize: true,
})(NDTMeasurementForm);

const mapStateToProps = (state, props) => {
  const coordinates = selector(state, formConstants.fields.points),
    componentID = selector(state, formConstants.fields.componentID),
    measurmentID = selector(state, formConstants.fields.id),
    unit = selector(state, formConstants.fields.unit) || ' - ';

  return {
    components: [props.defaultComponent, ...state.inspectionReducer.components],
    measurementFiles: state.uploadReducer.ndtMeasurementFiles,
    componentDetails: state.inspectionReducer.componentDetails,
    properties: state.ndtReducer.measurementProperties,
    coordinates,
    componentID,
    unit,
    measurmentID,
    severityColors: state.themeReducer.severityColors,
    filterGroupSuggestions: state.ndtReducer.filterGroupSuggestions,
  };
};
const mapDispatchToProps = dispatch => ({
  deleteFile: (fileID, measurementId) => dispatch(deleteMeasurementFile(fileID, measurementId)),
  toggleDeleteModal: data => dispatch(handleDeleteNDTMeasurementModal(data)),
  deleteMeasurement: (data, currentMeasurements, callback) => dispatch(deleteMeasurement(data, currentMeasurements, callback)),
  setComponentDetails: id => dispatch(setComponentDetails(id)),
  setComponentDetailsData: data => dispatch(setComponentDetailsData(data)),
  changeField: (fieldName, value) => dispatch(change(FORMS.ndtMeasurementForm, fieldName, value)),
  fetchFilterGroupSuggestions: (inspectionId, val) => dispatch(getFilterGroupSuggestions(inspectionId, val)),
  clearFilterGroupSuggestions: () => dispatch(clearFilterGroupSuggestions()),
  updateGeometry: (data, currentMeasurements, callback) => dispatch(updateMeasurementGeometry(data, currentMeasurements, callback)),

  //properties
  updateNDTProperty: property => dispatch(updateNDTProperty(property)),
  getNDTMeasurementProperties: id => dispatch(getNDTMeasurementProperties(id)),
  addNDTMeasurementProperty: data => dispatch(addNDTMeasurementProperty(data)),
  getNDTMeasurementPropertyNames: (value, callback) => dispatch(getNDTMeasurementPropertyNames(value, callback)),
  deleteNDTMeasurementProperty: (data, callback) => dispatch(deleteNDTMeasurementProperty(data, callback)),
});

NDTMeasurementForm = connect(mapStateToProps, mapDispatchToProps)(NDTMeasurementForm);
export default NDTMeasurementForm;
