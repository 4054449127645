import { isEmpty, pick } from 'lodash';
import notificationsApi from '../../../api/notifications/actions';
import { filterProps, updateNotificationPropertiesShouldTrigger } from '../components/notifications/constants/constants';
import { formConstants as notificationConstants } from '../constants/notification-constants';

import axios from 'axios';
import {
  amendNotifications,
  handleDeleteNotificationModal,
  handleRemoveNotificationByProp,
  handleUpdateNotificationByProp,
  replaceAddedNotificationCustomProperty,
  setNotificationDetailsLoading,
  setNotificationWorkOrders,
  setNotifications,
  setNotificationsClustered,
  setNotificationsLoading,
} from './action-creators';

let cancelToken;

export const getNotifications = (InspectionID, params, successCallback, loadMore) => {
  return async dispatch => {
    if (!InspectionID) {
      return;
    }
    try {
      dispatch(setNotificationsLoading(true));

      const res = await notificationsApi.getNotifications({ InspectionID, ...params });
      const { Items, ...rest } = res.data;
      if (loadMore) {
        dispatch(amendNotifications(Items));
      } else {
        dispatch(setNotifications(Items));
      }
      const filtersToBePassedToCallback = pick(rest, [[filterProps.totalNumber], [filterProps.hasNext], [filterProps.lastSeen]]);
      dispatch(setNotificationsLoading(false));
      successCallback && typeof successCallback === 'function' && successCallback(Items, filtersToBePassedToCallback);
    } catch (err) {
      console.error(err);
      dispatch(setNotificationsLoading(false));
    }
  };
};

export const createNotificationFromObservation = (InspectionID, DefectID, Configuration, successCallback, errorCallback) => {
  return async () => {
    try {
      await notificationsApi.createNotificationFromObservation({ InspectionID, DefectID, ...Configuration });
      successCallback();
    } catch (e) {
      console.error(e);
      errorCallback();
    }
  };
};

export const fetchDetailsForCreationOfNotification = (ProjectID, DefectID, successCallback) => {
  return async () => {
    try {
      const res = await notificationsApi.fetchDetailsForCreationOfNotification({ ProjectID, DefectID });
      const { Data } = res.data;
      successCallback && typeof successCallback === 'function' && successCallback(Data);
    } catch (e) {
      console.error(e);
    }
  };
};

export const fetchCustomPropDependantValues = (PropertyName, Dependencies, PropertyValue, successCallback) => {
  return async () => {
    try {
      const res = await notificationsApi.fetchCustomPropDependantValues({ PropertyName, Dependencies, PropertyValue });
      const { Data } = res.data;
      successCallback && typeof successCallback === 'function' && successCallback(Data);
    } catch (e) {
      console.error(e);
    }
  };
};

export const getNotificationDetails = (notificationId, includeLoader = true, callback) => {
  return async dispatch => {
    try {
      includeLoader && dispatch(setNotificationDetailsLoading(true));

      let res = await notificationsApi.getNotificationDetails([{ notification_id: notificationId }]);
      const { Data } = res.data;
      includeLoader && dispatch(setNotificationDetailsLoading(false));

      callback && callback(Data);
    } catch (err) {
      includeLoader && dispatch(setNotificationDetailsLoading(false));
    }
  };
};

export const getNotificationWorkOrders = ID => {
  return async dispatch => {
    try {
      const res = await notificationsApi.getNotificationWorkOrders({ ID });
      dispatch(setNotificationWorkOrders(res.data.Data));
    } catch (e) {
      console.error(e);
    }
  };
};

export const updateNotification = (data, callbackSuccess = () => null, dataCallback = () => null) => {
  return async dispatch => {
    try {
      if (isEmpty(data)) return;

      // TODO: discuss why we do not use the response data to update the selected notification? Is it because of 'Geometry' and 'Camera Position'?
      // BE returns null for Geometry and Camera Position props for some reason on update
      await notificationsApi.updateNotification(data);
      dispatch(handleUpdateNotificationByProp(data));
      callbackSuccess && typeof callbackSuccess === 'function' && callbackSuccess();
      // TODO: once BE returns Geometry and Camera Position in response, uncomment below and use the API data
      // dataCallback && typeof dataCallback === 'function' && dataCallback(Data);
    } catch (e) {
      console.error(e);
    }
  };
};

export const removeNotification = (data, callback) => {
  return async dispatch => {
    try {
      if (isEmpty(data)) return;

      await notificationsApi.deleteNotification({ ID: data[notificationConstants.fields.id] });
      dispatch(handleRemoveNotificationByProp(data));

      callback && callback();
    } catch (e) {
      dispatch(setNotificationsLoading(false));
      dispatch(
        handleDeleteNotificationModal({
          isOpen: false,
        })
      );
      console.error(e);
    }
  };
};

// notification comments
export const fetchNotificationComments = (params, callback) => {
  return async () => {
    try {
      callback({
        commentsLoading: true,
      });
      const res = await notificationsApi.fetchNotificationComments([params]);
      const { Data } = res?.data;
      callback({
        commentsList: Data,
        commentsLoading: false,
      });
    } catch (e) {
      callback({
        commentsLoading: false,
      });
      console.error(e);
    }
  };
};

export const addNotificationComment = (filters, dataCallback, loadingCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      await notificationsApi.addNotificationComment(filters);
      dataCallback && typeof dataCallback === 'function' && dataCallback();
      isLoadingCallbackValid && loadingCallback(false);
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
    }
  };
};

export const deleteNotificationComment = (filters, dataCallback, loadingCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      await notificationsApi.deleteNotificationComment(filters);
      dataCallback && typeof dataCallback === 'function' && dataCallback();
      isLoadingCallbackValid && loadingCallback(false);
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
    }
  };
};

export const getInspectionNotificationsClustered = (inspection_id, activeLeftSidebar, searchText = '', optionalParams = {}) => {
  return async (dispatch, getState) => {
    const {
      inspectionReducer: { inspectionSettings },
    } = getState();
    const clusterConfiguration = pick(inspectionSettings, [
      notificationConstants.fields.numberOfRings,
      notificationConstants.fields.numberOfSlices,
      notificationConstants.fields.initialDistance,
      notificationConstants.fields.multiplier,
    ]);
    try {
      //Check if there are any previous pending requests
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel('Operation canceled due to new request.');
      }

      //Save the cancel token for the current request
      cancelToken = axios.CancelToken.source();

      const res = await notificationsApi.fetchNotificationsClustered(
        {
          InspectionID: parseInt(inspection_id),
          SearchText: searchText,
          ...clusterConfiguration,
          ...optionalParams,
        },
        { cancelToken }
      );
      const { Data } = res.data;

      dispatch(
        setNotificationsClustered(
          (Data.Items || []).map((el, index) => ({
            ...el,
            ID: el.ID > 0 ? el.ID : `CLUSTER_${Date.now()}_${index}`,
          }))
        )
      );
    } catch (e) {
      console.error(e);
    }
  };
};

export const updateNotificationProperties = (data, callbackSuccess) => {
  return async dispatch => {
    try {
      if (!updateNotificationPropertiesShouldTrigger(data)) {
        return;
      }
      const res = await notificationsApi.updateOrAddNotificationCustomProperties({ Properties: data });
      const { Properties } = res.data.Data;
      dispatch(replaceAddedNotificationCustomProperty(Properties));
      callbackSuccess && typeof callbackSuccess === 'function' && callbackSuccess();
    } catch (e) {
      console.error(e);
    }
  };
};
