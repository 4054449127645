import { EMAIL_REGEX } from '../../../../../common/constants';
import { commonFields } from '../constants/ndt-alarms';
import { forEach, isEmpty, map } from 'lodash';

export const validate = values => {
  const errors = {};

  const acitveBtns = Object.keys(values).filter(key => key.startsWith(commonFields.active));

  forEach(acitveBtns, item => {
    //if alarm is active
    if (values[item]) {
      const alarm = item.split('_')[0];
      const extenstion = item.substr(alarm.length + 1, item.length);
      // const emailField = `${commonFields.email}_${extenstion}`;
      const valueField = `${commonFields.value}_${extenstion}`;

      if (isNaN(parseFloat(values[valueField]))) {
        errors[valueField] = {
          string: 'NDT_ALARMS_MODAL.VALUE_NOT_VALID',
        };
      }
    }
  });

  return errors;
};

export const validateEmailForm = (values, props) => {
  const errors = {};

  if (!values[commonFields.email]) {
    errors[commonFields.email] = {
      string: 'NDT_ALARMS_MODAL.EMIAL_NOT_VALID',
    };
  }

  if (values[commonFields.email] && !EMAIL_REGEX.test(values[commonFields.email])) {
    errors[commonFields.email] = {
      string: 'NDT_ALARMS_MODAL.EMIAL_NOT_VALID',
    };
  }

  if (!isEmpty(props.emails) && values[commonFields.email] && map(props.emails, item => item.Email).indexOf(values[commonFields.email]) > -1) {
    errors[commonFields.email] = {
      string: 'NDT_ALARMS_MODAL.EMAIL_DUPLICATED',
    };
  }

  return errors;
};
