import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Button from '../../../common/form/components/button';
import CustomInput from '../../../common/form/components/input';
import Textarea from '../../../common/form/components/text-area';

import { fields, textAreaMaxChars } from '../constants/constants';
import { FORMS } from '../../../common/constants';
import { validateFirstStep } from '../validatiors/create-keybox-validator';

const CreateKeyboxFirstStep = ({ handleSubmit, invalid, anyTouched, isEdit, closeAction }, { t }) => {
  return (
    <form onSubmit={handleSubmit} noValidate>
      <Field
        id={fields.name}
        name={fields.name}
        label={'KEYBOX_FIELDS.NAME'}
        component={CustomInput}
        type="text"
        placeholder={'KEYBOX_FIELDS.NAME'}
        additionalClassName="text-transform-none"
        labelClass="text-transform-none"
      />
      <Field id={fields.description} name={fields.description} component={Textarea} placeholder={'KEYBOX_FIELDS.DESCRIPTION'} label={'KEYBOX_FIELDS.DESCRIPTION'} maxChars={textAreaMaxChars} />

      <div className="buttons">
        <Button variant="gray-outline" type="button" text={'CANCEL'} width="sm" height="md" onClick={closeAction} />
        <Button disabled={invalid && anyTouched} text={t(isEdit ? 'KEYBOX_FORM.SAVE_BTN' : 'KEYBOX_FORM.NEXT')} width="sm" height="md" />
      </div>
    </form>
  );
};

CreateKeyboxFirstStep.contextTypes = {
  t: PropTypes.func.isRequired,
};

CreateKeyboxFirstStep.propTypes = {
  isEdit: PropTypes.bool,
  closeAction: PropTypes.func.isRequired,
};

export default reduxForm({
  form: FORMS.createKeybox,
  enableReinitialize: true,
  validate: validateFirstStep,
})(CreateKeyboxFirstStep);
