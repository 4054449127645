import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from '../../icon/components/icon';
import ToolboxWrapper from '../../toolbox/components/tool-wrapper';

import { PERMISSIONS, PERMISSION_TYPES } from '../../permissions-constants';
import '../style/pdf-toolbox.scss';

class PDFToolbox extends Component {
  render() {
    const { zoomIn, zoomOut, resetTransform, isEditingDrawing, toggleEditMode, activeTool, editEnabled } = this.props;

    return (
      <div className="pdf-toolbox">
        <ToolboxWrapper
          toolbox={[
            {
              icon: props => <Icon name="measurements" solid {...props} />,
              action: toggleEditMode,
              title: isEditingDrawing ? 'PDF_TOOLBOX.DISABLE_EDIT_TITLE' : 'PDF_TOOLBOX.ENABLE_EDIT_TITLE',
              hide: !editEnabled,
              access: {
                visibleFor: PERMISSIONS[PERMISSION_TYPES.smartDrawing].manipulate.name,
              },
              toolType: 'select',
            },
            {
              icon: props => <Icon name="zoomin" solid {...props} />,
              action: zoomIn,
              title: 'PDF_TOOLBOX.ZOOM_IN_TITLE',
            },
            {
              icon: props => <Icon name="zoomout" solid {...props} />,
              action: zoomOut,
              title: 'PDF_TOOLBOX.ZOOM_OUT_TITLE',
            },
            {
              icon: props => <Icon name="resize" solid {...props} />,
              action: resetTransform,
              title: 'PDF_TOOLBOX.RESET_POSITION_TITLE',
            },
          ]}
          activeTool={activeTool}
          isEditingDrawing={isEditingDrawing}
        />
      </div>
    );
  }
}

PDFToolbox.contextTypes = {
  t: PropTypes.func.isRequired,
};

PDFToolbox.defaultProps = {
  editEnabled: false,
};

export default PDFToolbox;
