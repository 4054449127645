import actionTypes from '../constants/action-types';

export const setUploadTmpFiles = data => {
  return {
    data,
    type: actionTypes.SET_UPLOAD_TMP_FILES,
  };
};

export const setSingleUploadItemProgress = data => {
  return {
    data,
    type: actionTypes.SET_SINGLE_UPLOADITEM_PROGRESS,
  };
};

//external upload files
export const setExternaledUploadFiles = data => {
  return {
    data,
    type: actionTypes.SET_EXTERNAL_UPLOADED_FILES,
  };
};

export const setComponentFilesUploaded = data => {
  return {
    data,
    type: actionTypes.SET_COMPONENT_FILES_UPLOADED,
  };
};

export const setDefectFilesUploaded = data => {
  return {
    data,
    type: actionTypes.SET_DEFECT_FILES_UPLOADED,
  };
};

export const setNotificationFilesUploaded = data => {
  return {
    data,
    type: actionTypes.SET_NOTIFICATION_FILES_UPLOADED,
  };
};

export const setWorkorderFilesUploaded = data => {
  return {
    data,
    type: actionTypes.SET_WORKORDER_FILES_UPLOADED,
  };
};

export const amendWorkorderFilesUploaded = data => {
  return {
    data,
    type: actionTypes.AMEND_WORKORDER_FILES_UPLOADED,
  };
};

export const removeWorkorderFileUploadedByID = data => {
  return {
    data,
    type: actionTypes.REMOVE_WORKORDER_FILE_BY_ID,
  };
};

export const setChecklistQuestionFilesUploaded = (data, prop) => {
  return {
    data,
    prop,
    type: actionTypes.SET_CHECKLIST_QUESTION_FILES_UPLOADED,
  };
};

export const amendChecklistQuestionFilesUploaded = (data, prop) => {
  return {
    data,
    prop,
    type: actionTypes.AMEND_CHECKLIST_QUESTION_FILES_UPLOADED,
  };
};

export const removeChecklistQuestionFileUploadedByProp = (data, prop) => {
  return {
    data,
    prop,
    type: actionTypes.REMOVE_CHECKLIST_QUESTION_FILE_BY_PROP,
  };
};

export const defectFilesLoading = data => {
  return {
    data,
    type: actionTypes.SET_DEFECT_FILES_UPLOADED_LOADING,
  };
};

export const setMeasurementFilesUploaded = data => {
  return {
    data,
    type: actionTypes.SET_MEASUREMENT_FILES_UPLOADED,
  };
};

export const measurementFilesLoading = data => {
  return {
    data,
    type: actionTypes.SET_MEASUREMENT_FILES_UPLOADED_LOADING,
  };
};

export const setItemToCancel = data => {
  return {
    data,
    type: actionTypes.SET_ITEM_TO_CANCEL,
  };
};

export const removeItemToCancel = data => {
  return {
    data,
    type: actionTypes.REMOVE_ITEM_TO_CANCEL,
  };
};

export const setNDTMeasurementFilesUploaded = data => {
  return {
    data,
    type: actionTypes.SET_NDT_MEASUREMENT_FILES_UPLOADED,
  };
};

export const setNDTMeasurementPointFilesUploaded = data => {
  return {
    data,
    type: actionTypes.SET_NDT_MEASUREMENT_POINT_FILES_UPLOADED,
  };
};

export const setUploadBatchFiles = data => {
  return { type: actionTypes.SET_UPLOAD_BATCH_FILES, data };
};

export const updateUploadBatchFile = data => {
  return { type: actionTypes.UPDATE_UPLOAD_BATCH_FILE, data };
};

export const doBatchCleanUp = data => {
  return { type: actionTypes.DO_BATCH_CLEAN_UP, data };
};

export const setBatchFileFailed = data => {
  return { type: actionTypes.SET_BATCH_FILE_FAILED, data };
};
