import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { isString, map } from 'lodash';

import SearchInput from '../../../common/input/components/search-input';
import Button from '../../../common/form/components/button';
import InfoTooltip from '../../../common/tooltip/components/info-tooltip';
import ItemsRenderer from '../../../common/items-renderer/components/items-renderer';
import AccessRenderer from '../../../common/access-renderer/components/access-renderer';
import BackButton from '../../../common/back-button/components/back-button';
import { routes } from '../../../common/routes-constants';
import CustomSelect from '../../../common/form/components/select';
import Icon from '../../../common/icon/components/icon';

import { filterValues, componentFilterValues, tooltipData, componentFields } from '../constants/constants';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../common/permissions-constants';

import '../style/left-toolbar.scss';

class LeftToolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownData: map(componentFilterValues, item => {
        return {
          value: item,
          label: `PDF_TYPE_DROPDOWN.${item}`,
        };
      }),
    };
  }

  hasTags = drawings => {
    if (!drawings) return;
    // iterate through all component drawings
    return drawings.some(drawing => {
      const draw = isString(drawing[componentFields.drawing]) ? JSON.parse(drawing[componentFields.drawing]) : drawing[componentFields.drawing];
      return draw && draw.objects && draw.objects.length > 0;
    });
  };

  renderCustomRightComponent = component => {
    const { t } = this.context;
    const { filter, notInThisPdfClick } = this.props;
    if (filter) {
      switch (filter[filterValues.componentFilter]) {
        case componentFilterValues.cvLinked:
          return (
            <Fragment>
              {component.Confirmed ? (
                <Icon name="checkmark" active={true} title={t('PDF_TAG.CHECKMARK.CHECKED')} size="xs" handleHover={false} />
              ) : (
                <>
                  <Icon name="checkmark" warning title={t('PDF_TAG.CHECKMARK.NOT_CHECKED')} size="xs" handleHover={false} />
                </>
              )}
            </Fragment>
          );
        case componentFilterValues.cvFound:
          if (component[componentFields.componentId] && component[componentFields.componentId] > 0) {
            return (
              <>
                <Icon name="checkmark" warning title={t('PDF_TAG.CHECKMARK.NOT_CHECKED')} size="xs" handleHover={false} />
                <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.smartDrawing].manipulate.name}>
                  {({ hasAccess }) =>
                    hasAccess && (
                      <Icon
                        name="delete"
                        size="xs"
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          notInThisPdfClick(component);
                        }}
                      />
                    )
                  }
                </AccessRenderer>
              </>
            );
          } else {
            return (
              <>
                <Icon name="help-circle" size="xs" handleHover={false} />
                <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.smartDrawing].manipulate.name}>
                  {({ hasAccess }) =>
                    hasAccess && (
                      <Icon
                        name="delete"
                        size="xs"
                        style={{ marginLeft: '5px' }}
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          notInThisPdfClick(component);
                        }}
                      />
                    )
                  }
                </AccessRenderer>
              </>
            );
          }
        default:
          return (
            <Fragment>
              {component[componentFields.taggedInAnotherFile] ? (
                <>
                  <Icon name="checkmark" title={t('PDF_TAG.TAGGED_IN_ANOTHER_FILE')} size="xs" handleHover={false} />
                  <Icon name="file-text" size="xs" style={{ marginLeft: '5px' }} title={t('PDF_TAG.TAGGED_IN_ANOTHER_FILE')} handleHover={false} />
                </>
              ) : null}
              {component[componentFields.drawings] && this.hasTags(component[componentFields.drawings]) ? (
                <>
                  {component[componentFields.confirmed] ? (
                    <Icon name="checkmark" active={true} title={t('PDF_TAG.CHECKMARK.CHECKED')} size="xs" handleHover={false} />
                  ) : (
                    <>
                      <Icon name="checkmark" warning title={t('PDF_TAG.CHECKMARK.NOT_CHECKED')} size={'xs'} handleHover={false} />
                    </>
                  )}
                </>
              ) : null}
            </Fragment>
          );
      }
    }
  };

  customSelectedFunction = item => {
    const { selectedComponent, filter } = this.props;
    if (selectedComponent) {
      switch (filter[filterValues.componentFilter]) {
        case componentFilterValues.cvLinked:
          return item && item[componentFields.componentId] === selectedComponent[componentFields.componentId] && item[componentFields.cvFoundID] === selectedComponent[componentFields.cvFoundID];
        case componentFilterValues.cvFound:
          return item && item[componentFields.cvFoundID] === selectedComponent[componentFields.cvFoundID];
        default:
          return item && item[componentFields.componentId] === selectedComponent[componentFields.componentId];
      }
    }
    return false;
  };

  render() {
    const { t } = this.context;
    const { dropdownData } = this.state;
    const { renderCustomRightComponent } = this;
    const { handleFilterChanged, components, selectedComponent, handleComponentClick, componentsLoading, handleCreateComponentClick, filter, customIdentificatorFunction, location } = this.props;
    const { search } = location;

    const pdfTagListPath = routes.protectedRoutes.pdfTagList.fullPath;

    /* Removing the file_id from the url. */
    const params = new URLSearchParams(search);
    params.delete('file_id');

    const backButtonPath = `${pdfTagListPath}?${params}`;

    return (
      <div className="l-toolbar">
        <div className="section">
          <BackButton path={`${backButtonPath}`} />
          <AccessRenderer visibleFor={[PERMISSIONS[PERMISSION_TYPES.components].create.name]}>
            {({ hasAccess }) => (
              <Button
                text={t('PDF_TAG.LEFT.CREATE_COMPONENT')}
                width="lg"
                onClick={handleCreateComponentClick}
                disabled={filter[filterValues.componentFilter] !== componentFilterValues.all || !hasAccess}
              />
            )}
          </AccessRenderer>

          <CustomSelect
            data={dropdownData}
            defaultValue={filter[filterValues.componentFilter]}
            onChange={item => handleFilterChanged(item.value, filterValues.componentFilter)}
            valueComponent={({ item: { label } }) => t(label)}
            itemComponent={({ item: { label } }) => t(label)}
            label={'PDF_TYPE_DROPDOWN.TITLE'}
            className="component-flter"
            labelActions={[
              {
                IconComponent: () => (
                  <InfoTooltip
                    actionsMenu={t(tooltipData[filter[filterValues.componentFilter]])}
                    offsetY={0}
                    offsetX={5}
                    Component={() => <Icon name="info" handleHover={false} size="xs" />}
                    containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
                  />
                ),
              },
            ]}
          />

          <SearchInput onChange={e => handleFilterChanged(e.target.value, filterValues.searchText)} placeholder={t('PROJECT.SEARCH.PLACEHOLDER')} />
          {filter[filterValues.componentFilter] === componentFilterValues.cvFound && components && components.length > 0 && (
            <p className="f-primary ramaining">{t('PDF_TAG.LEFT_TOOLBAR.REMAINING', { num: components.length })}</p>
          )}
        </div>

        <ItemsRenderer
          handleItemClick={handleComponentClick}
          isLoading={componentsLoading}
          items={components}
          activeItem={selectedComponent}
          hoveredItem={selectedComponent}
          renderItemRightComponent={renderCustomRightComponent}
          customSelectedFunction={this.customSelectedFunction}
          customHoveredFunction={() => false}
          customIdentificatorFunction={customIdentificatorFunction}
          CustomEmptyState={() => {
            return !componentsLoading && <p className="f-primary">{t(`PDF_TAG.LEFT.NO_COMPONENTS`)}</p>;
          }}
        />
      </div>
    );
  }
}

LeftToolbar.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default LeftToolbar;
