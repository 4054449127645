import React from 'react';
import PropTypes from 'prop-types';

import '../styles/display-color.scss';

const DisplayColor = ({ color, ...rest }) => <div className="display-color-item" style={{ backgroundColor: color }}></div>;

DisplayColor.propTypes = {
  color: PropTypes.string.isRequired,
};

export default DisplayColor;
