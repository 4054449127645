import isolationCertificateApi from '../../../api/isolation-certificates/actions';
import { fields, filterProps } from '../components/isolation-certificates/constants/isolation-certificates-table';
import { setIsolationCertificates, setIsolationCertificatesFilters } from './action-creators';

export const getIsolationTemplates = (data, callback) => {
  return async () => {
    try {
      const res = await isolationCertificateApi.getIsolationCertificateTemplates(data);
      const templates = res?.data?.Data?.IsolationTemplates;

      if (templates && typeof callback === 'function') {
        callback(templates);
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const getIsolationTemplatesFilters = (data, callback) => {
  return async () => {
    try {
      const res = await isolationCertificateApi.getIsolationCertificateTemplatesFilters(data);
      const templates = res?.data?.Data;

      if (templates && typeof callback === 'function') {
        callback(templates);
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const createIsolationCertificate = (data, createdByName, isolationCertificates, filters, callback) => {
  return async dispatch => {
    try {
      dispatch(setIsolationCertificates(true, isolationCertificates));

      const res = await isolationCertificateApi.createIsolationCertificate(data);
      const certificate = res?.data?.Data;

      if (certificate) {
        certificate[fields.linkedPermits] = 0;
        certificate[fields.numberOfIsolations] = 0;
        certificate[fields.createdByName] = createdByName;
        isolationCertificates.unshift(certificate);

        filters[filterProps.totalItems] += 1;
        filters[filterProps.lastSeen] += 1;
      }

      dispatch(setIsolationCertificatesFilters(filters));
      dispatch(setIsolationCertificates(false, isolationCertificates));

      if (typeof callback === 'function') {
        callback(certificate[fields.id]);
      }
    } catch (e) {
      dispatch(setIsolationCertificates(false, isolationCertificates));
      console.log(e);
    }
  };
};
