// import { isEmpty } from 'lodash';
import { formConstants, textAreaMaxChars } from '../../../constants/defect-constants';

export const validate = values => {
  const errors = {};

  if (!values[formConstants.fields.name] || values[formConstants.fields.name].length < 3) {
    errors[formConstants.fields.name] = 'DEFECT_FORM.ERROR.NAME_LENGTH_MIN';
  }

  // if (values[formConstants.fields.systemType] === measurementTypes.defect && !values[formConstants.fields.componentID]) {
  //   errors[formConstants.fields.component] = 'DEFECT_FORM.ERROR.COMPONENT_REQUIRED';
  // }

  // if (!values.Geometry || isEmpty(values.Geometry.coordinates)) {
  //   errors[formConstants.fields.points] = 'DEFECT_FORM.ERROR.GEOMETRY_REQUIRED';
  // }

  if (values[formConstants.fields.description] && values[formConstants.fields.description].length > textAreaMaxChars) {
    errors[formConstants.fields.description] = 'DEFECT_FORM.ERROR.DESCRIPTION_TOO_LONG';
  }

  if (values[formConstants.fields.recommendation] && values[formConstants.fields.recommendation].length > textAreaMaxChars) {
    errors[formConstants.fields.recommendation] = 'DEFECT_FORM.ERROR.RECOMMENDATION_TOO_LONG';
  }
  if (values[formConstants.fields.resolvedComment] && values[formConstants.fields.resolvedComment].length > textAreaMaxChars) {
    errors[formConstants.fields.resolvedComment] = 'DEFECT_FORM.ERROR.RECOMMENDATION_TOO_LONG';
  }

  return errors;
};
