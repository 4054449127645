import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CreateContractorModal from './create-contractor-modal';
import Modal from '../../../common/modal/components/modal';
import Tabs from '../../../common/tabs/component/tabs';
import Tab from '../../../common/tabs/component/tab';
import ContractorsList from './contractors-list';
import LoadMore from '../../../common/load-more/components/load-more';
import PageNavigation from '../../../common/page-navigation/components/page-navigation';

import Helpers from '../../../common/helpers';
import ReducerHelpers from '../../../common/reducer-helpers';
import {
  createContractor,
  deleteContractor,
  deleteContractorFile,
  getContractorDetails,
  getContractorFiles,
  getContractors,
  getContractorUserList,
  restoreContractor,
  updateContractor,
  updateContractorStatus,
} from '../actions/contractor-management-actions';
import routesConstants, { routes } from '../../../common/routes-constants';
import { defaultContractorsFilter, filterProps, formConstants, sortingDirection, tabConstants } from '../constants/constants';
import { params } from '../../profile/constants/profile-constants';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../common/permissions-constants';

import '../styles/contractor-management.scss';

class ContractorManagement extends Component {
  constructor(props) {
    super(props);
    const { query } = props.router.location;

    this.state = {
      projectId: parseInt(query.project_id),
      activeTab: tabConstants.active.tabKey,
      filter: {
        ...defaultContractorsFilter,
      },
      modalData: {
        isOpen: false,
        isDateClearable: false,
      },
      confirmModalData: {
        isOpen: false,
      },
      contractors: [],
      selectedContractor: null,
      isLoading: false,
    };
  }

  componentDidMount = () => {
    const { projectId, activeTab } = this.state;

    if (!projectId) {
      Helpers.goTo(routesConstants.routes.protectedRoutes.project.fullPath);
    }
    this.fetchContractors(activeTab, defaultContractorsFilter);
  };

  fetchContractors = (activeTab, params, callback, loadMore) => {
    const { projectId, contractors } = this.state;
    const { getContractors } = this.props;
    params = {
      ...params,
      ActiveFilter: activeTab === tabConstants.active.tabKey,
      ProjectId: parseInt(projectId, 10),
    };

    getContractors(params, contractors, callback ? callback : data => this.setState(data), loadMore);
  };

  onColumnSort = SortByColumn => {
    const { filter, activeTab } = this.state;
    const newParams = {
      ...filter,
      [filterProps.sortByColumn]: SortByColumn,
      [filterProps.sortDirection]: filter[filterProps.sortDirection] === sortingDirection.asc ? sortingDirection.desc : sortingDirection.asc,
      [filterProps.lastSeen]: 0,
    };

    this.fetchContractors(activeTab, newParams);
  };

  loadMore = () => {
    const { filter, contractors, activeTab } = this.state;
    const newParams = { ...filter };

    this.fetchContractors(
      activeTab,
      newParams,
      data => {
        this.setState(data);
        Helpers.scrollIntoView('contractors-list-table-container', `row-${contractors.length - 1}`);
      },
      true
    );
  };

  handleSearch = SearchText => {
    const { filter, activeTab } = this.state;
    const newParams = {
      ...filter,
      [filterProps.lastSeen]: defaultContractorsFilter[filterProps.lastSeen],
      SearchText,
    };
    this.fetchContractors(activeTab, newParams);
  };

  openContractorEditModal = (contractor, viewOnly = false) => {
    const { getContractorDetails } = this.props;
    const { t } = this.context;

    const closeAction = () => {
      this.setState({ modalData: { isOpen: false } });
    };

    const openAction = (isCreate = false) => {
      this.setState({
        modalData: {
          isOpen: true,
          isDateClearable: false,
          title: isCreate ? t('CONTRACTOR_MODAL.CREATE_TITLE') : t('CONTRACTOR_MODAL.EDIT_TITLE'),
          CustomContent: dynamicProps => <CreateContractorModal {...dynamicProps} viewOnly={viewOnly} isEditMode={!isCreate} closeAction={closeAction} />,
          customClassName: 'modal-no-max-height modal-large',
          type: 'none',
          closeAction,
        },
      });
    };
    //for edit mode fetch details
    if (contractor && contractor[formConstants.fields.id]) {
      getContractorDetails(contractor[formConstants.fields.id], Data => {
        if (Data && Data[formConstants.fields.id] > 0) {
          this.setState({ selectedContractor: Data });
          openAction(false);
        }
      });
    } else {
      //for create mode open instant
      this.setState({ selectedContractor: {} });
      openAction(true);
    }
  };

  handleCreateContractor = (values, callback, errorCallback) => {
    const { contractors, projectId, filter } = this.state;
    const { createContractor } = this.props;

    createContractor(
      { ...values, ProjectId: projectId },
      data => {
        this.setState({ selectedContractor: data, contractors: [data, ...contractors], filter: { ...filter, [filterProps.totalItems]: filter[filterProps.totalItems] + 1 } });
        callback && callback(data);
      },
      e => errorCallback(e)
    );
  };

  handleUpdateContractor = (values, callback, errorCallback) => {
    const { contractors, projectId } = this.state;
    const { updateContractor } = this.props;
    updateContractor(
      projectId,
      values,
      data => {
        this.setState({ contractors: ReducerHelpers.updateItemInListByProp(contractors, data, formConstants.fields.id) });
        callback && callback();
      },
      e => errorCallback(e)
    );
  };

  handleRestoreContractor = (values, callback) => {
    const { activeTab, filter, projectId } = this.state;
    const { restoreContractor } = this.props;
    const restoreContractorValues = { ...values, [formConstants.fields.projectId]: projectId };
    restoreContractor(restoreContractorValues, data => {
      // continues to next step in form
      callback && callback(data);
      const newParams = {
        ...filter,
        [filterProps.lastSeen]: defaultContractorsFilter[filterProps.lastSeen],
      };
      this.fetchContractors(activeTab, newParams);
    });
  };

  closeConfirmModal = () =>
    this.setState({
      confirmModalData: {
        isOpen: false,
      },
    });

  setConfirmModalData = (confirmAction, content) => {
    const { t } = this.context;
    this.setState({
      confirmModalData: {
        isOpen: true,
        title: t('CONTRACTOR_MANAGEMENT.DELETE_POPUP_TITLE'),
        content,
        type: 'yes-no',
        confirmAction: () => {
          confirmAction();
          this.closeConfirmModal();
        },
        closeAction: this.closeConfirmModal,
      },
    });
  };

  handleDeleteContractor = (values, callback) => {
    const { t } = this.context;
    const { contractors, filter } = this.state;
    const { deleteContractor } = this.props;

    this.setConfirmModalData(
      () =>
        deleteContractor(values, data => {
          this.setState({
            selectedContractor: data,
            contractors: ReducerHelpers.removeItemByProp(contractors, data, formConstants.fields.id),
            filter: { ...filter, [filterProps.lastSeen]: filter[filterProps.lastSeen] - 1, [filterProps.totalItems]: filter[filterProps.totalItems] - 1 },
          });
          callback && callback();
        }),
      t('CONTRACTOR_MANAGEMENT.DELETE_ARE_YOU_SURE', { contractor: values[formConstants.fields.name] })
    );
  };

  updateContractorStatus = (item, callback) => {
    const { contractors, filter } = this.state;
    const { updateContractorStatus } = this.props;
    updateContractorStatus({ [formConstants.fields.id]: item[formConstants.fields.id], [formConstants.fields.active]: !item[formConstants.fields.active] }, data => {
      this.setState({
        contractors: ReducerHelpers.removeItemByProp(contractors, data, formConstants.fields.id),
        filter: { ...filter, [filterProps.lastSeen]: filter[filterProps.lastSeen] - 1, [filterProps.totalItems]: filter[filterProps.totalItems] - 1 },
      });
      callback && callback();
    });
  };

  render() {
    const { contractors, filter, isLoading, modalData, selectedContractor, confirmModalData, projectId, activeTab } = this.state;
    const {
      handleCreateContractor,
      handleUpdateContractor,
      handleDeleteContractor,
      openContractorEditModal,
      fetchContractors,
      onColumnSort,
      handleSearch,
      loadMore,
      updateContractorStatus,
      handleRestoreContractor,
    } = this;
    const { user, location, getContractorFiles, deleteContractorFile, getContractorUserList } = this.props;
    const { search } = location;
    const backButtonPath = user[params.fullScreenEnabled] ? routes.protectedRoutes.fullScreen.fullPath : routes.protectedRoutes.inspections.fullPath;

    const contractorListProps = {
      contractors,
      filter,
      onColumnSort,
      handleSearch,
      handleDeleteContractor,
      openContractorEditModal,
      updateContractorStatus,
      isLoading,
    };

    return (
      <div className="contractor-management">
        <PageNavigation
          backButtonPath={`${backButtonPath}${search}`}
          title="PAGE_TITLE.CONTRACTOR_MANAGEMENT"
          icon="contractor"
          withBottomBorder
          actionButtontext="CREATE_BUTTON_TEXT.NEW"
          handleActionButtonClick={() => openContractorEditModal()}
          actionButtonProps={{
            visibleFor: PERMISSIONS[PERMISSION_TYPES.contractors].create.name,
            disabled: activeTab !== tabConstants.active.tabKey,
          }}
        />
        <Tabs
          onChange={tabkey => {
            this.setState({ activeTab: tabkey });
            fetchContractors(tabkey, defaultContractorsFilter);
          }}
        >
          <Tab title={tabConstants.active.title} tabKey={tabConstants.active.tabKey}>
            <ContractorsList {...contractorListProps} />
          </Tab>
          <Tab title={tabConstants.archived.title} tabKey={tabConstants.archived.tabKey}>
            <ContractorsList {...contractorListProps} isArchived={true} />
          </Tab>
        </Tabs>
        <LoadMore
          disabled={!filter[filterProps.hasNext] || isLoading}
          loaded={contractors.length}
          total={filter[filterProps.totalItems]}
          totalPosition="center"
          isLoading={isLoading}
          label="COMPONENT_HISTORY.LOAD_MORE"
          showButton
          showTotalUp
          buttonVariant="success-outline"
          onClick={loadMore}
        />
        <Modal
          {...{
            ...modalData,
            contractor: selectedContractor,
            handleCreateContractor,
            handleUpdateContractor,
            getContractorFiles,
            deleteContractorFile,
            getContractorUserList,
            projectId,
            handleRestoreContractor,
          }}
        />
        <Modal {...confirmModalData} />
      </div>
    );
  }
}

ContractorManagement.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.userReducer,
});

const mapDispatchToProps = dispatch => ({
  getContractors: (filter, contractors, callback, loadMore) => dispatch(getContractors(filter, contractors, callback, loadMore)),
  getContractorDetails: (contractorId, callback) => dispatch(getContractorDetails(contractorId, callback)),
  createContractor: (data, callback, errorCallback) => dispatch(createContractor(data, callback, errorCallback)),
  updateContractor: (projectId, data, callback, errorCallback) => dispatch(updateContractor(projectId, data, callback, errorCallback)),
  updateContractorStatus: (data, callback) => dispatch(updateContractorStatus(data, callback)),
  deleteContractor: (data, callback) => dispatch(deleteContractor(data, callback)),
  getContractorFiles: (contractorId, callback) => dispatch(getContractorFiles(contractorId, callback)),
  deleteContractorFile: (data, callback) => dispatch(deleteContractorFile(data, callback)),
  getContractorUserList: (data, callback) => dispatch(getContractorUserList(data, callback)),
  restoreContractor: (data, callback) => dispatch(restoreContractor(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContractorManagement);
