export const fields = {
  status: 'Status',
  editPermission: 'editPermission',
  transitionPermission: 'transitionPermission',
  ownershipPermission: 'ownershipPermission',
  fromStatuses: 'fromStatuses',
  goToStatuses: 'goToStatuses',
  createdBy: 'CreatedBy',
  answers: 'Answers',
  contractorId: 'ContractorID',
  sectionId: 'SectionID',
  assigneeName: 'SignedByUser',
  witnessName: 'WhitnessedByUser',
  signature: 'Signature',
  statusChangeAssigneeID: 'StatusChangeAssigneeID',
  statusChangeAssigneeName: 'StatusChangeAssigneeName',
  statusChangeWitnessName: 'StatusChangeWitnessName',
  statusChangeWitnessID: 'StatusChangeWitnessID',
  statusChangedAt: 'StatusChangedAt',
  ignoreStatusEditPermission: 'ignoreStatusEditPermission',
};

export const formFields = {
  status: {
    name: fields.status,
    label: 'ISOLATION_CERTIFICATE_STATUS.LABEL_STATUS',
    id: 'isolation-certificate-status-field',
  },
};

export const dropdownFields = {
  valueField: 'value',
  textField: 'title',
  icon: 'icon',
};

export const changeStatusFields = {
  contractor: {
    id: 'change-status-contractor',
    name: 'PersonID',
    label: 'ISOLATION_CERTIFICATE_STATUS.ISOLATOR_LABEL',
    placeholder: 'ISOLATION_CERTIFICATE_STATUS.ISOLATOR_LABEL',
    errorRequired: 'ISOLATION_CERTIFICATE_STATUS.ISOLATOR_REQUIRED',
  },
  assigneeSignature: {
    id: 'change-status-assignee-signature',
    querySelector: 'change-status-assignee-signature-query-selector',
    name: 'AssigneeSignature',
    label: 'ISOLATION_CERTIFICATE_STATUS.ISOLATOR_SIGNATURE_LABEL',
    errorRequired: 'ISOLATION_CERTIFICATE_STATUS.ISOLATOR_SIGNATURE_REQUIRED',
  },
  witnessSignature: {
    id: 'change-status-witness-signature',
    querySelector: 'change-status-witness-signature-query-selector',
    name: 'WitnessSignature',
    label: 'ISOLATION_CERTIFICATE_STATUS.WITNESS_SIGNATURE_LABEL',
    errorRequired: 'ISOLATION_CERTIFICATE_STATUS.WITNESS_SIGNATURE_REQUIRED',
  },
};

export const contractorDropdownFields = {
  valueField: 'ID',
  textField: 'Name',
};

export const steps = {
  first: '1',
  second: '2',
  third: '3',
};

export const questionsIsolationComponentsStepperData = isDeisolatedStatusRequested => [
  {
    name: 'ISOLATION_CERTIFICATE_STATUS.FIRST_STEP',
    stepValue: steps.first,
  },
  {
    name: isDeisolatedStatusRequested ? 'ISOLATION_CERTIFICATE_STATUS.LIVE_DISCLAIMER_DEISOLATIONS_COMPLETED' : 'ISOLATION_CERTIFICATE_STATUS.LIVE_DISCLAIMER_ISOLATIONS_COMPLETED',
    stepValue: steps.second,
  },
  {
    name: 'ISOLATION_CERTIFICATE_STATUS.SECOND_STEP',
    stepValue: steps.third,
  },
];

export const changeStatusQuestionField = 'change-status-question-field';
export const changeStatusIsolationComponentField = 'change-status-isolation-component-field';

export const questionFields = {
  isMandatory: 'IsMandatory',
  name: 'Name',
  allowMultipleAnswers: 'AllowMultipleAnswers',
  type: 'QuestionType',
  id: 'ID',
  sectionId: 'SectionID',
  questionOptions: 'QuestionOptions',
  description: 'Description',
  components: 'QuestionsComponents',
  estimatedTime: 'EstimatedTime',
  screenshots: 'QuestionsScreenshots',
  files: 'QuestionFiles',
  areas: 'QuestionAreas',
  screenshotsExpanded: 'screenshotsExpanded',
  imagesExpanded: 'imagesExpanded',
  answers: 'Answers',
  isAnswer: 'IsAnswer',
};

export const isolationComponentFields = {
  componentId: 'ComponentID',
  name: 'Name',
  colour: 'Colour',
  componentCode: 'ComponentCode',
  componentType: 'ComponentType',
  componentSubType: 'ComponentSubType',
  desiredState: 'DesiredState',
  normalPosition: 'NormalPosition',
  isolationMethod: 'IsolationMethod',
  deisolationOrder: 'DeisolationOrder',
  isolationOrder: 'IsolationOrder',
  files: 'Files',
  filesExpanded: 'filesExpanded',
  isChecked: 'isChecked',
  critical: 'Critical',
};
