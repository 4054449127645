import React from 'react';
import HistoryItemColorDisplay from '../../../common/history-item/components/history-item-color-display';
import HistoryItemComponentChange from '../../../common/history-item/components/history-item-component-change';
import HistoryItemFileDisplay from '../../../common/history-item/components/history-item-file-display';
import { ReactComponent as FilesIcon } from '../assets/file.svg';
import { ReactComponent as ImagesIcon } from '../assets/multiple-images-icon.svg';

export const measurementConstants = {
  fields: {
    id: 'ID',
    componentName: 'ComponentName',
    alarmColor: 'StatusColour',
    name: 'Name',
    measurementName: 'MeasurementName',
    camPos: 'CameraPosition',
    unit: 'MeasurementUnit',
    color: 'Colour',
    component: 'component',
    componentID: 'ComponentID',
    points: 'Geometry.coordinates',
    lastMeasurementDate: 'LastMeasurementDate',
    lastMeasurementValue: 'LastMeasurementValue',
    measurementNumber: 'MeasuresCount',
    measurementId: 'MeasurementID',
    measurementAlarms: 'MeasurementAlarmsDetails',
    measurementAlarmName: 'AlarmName',
    measurementAlarmColor: 'AlarmColour',
    filterGroup: 'FilterGroup',
    description: 'Description',
    threshold: 'Threshold',
    operationName: 'OperationName',
    createdDate: 'CreatedAt',
    createdBy: 'CreatedBy',
    createdByID: 'CreatedByUserID',
    nameNoStatus: 'nameNoStatus',
    status: 'AlarmStatus',
    notificationsNumber: 'NotificationsNumber',
  },
};

export const equipmentFields = {
  critical: 'Critical',
};

export const sortingDirection = {
  asc: 'ASC',
  desc: 'DESC',
};

export const filterParams = {
  searchText: 'SearchText',
  sortByColumn: 'SortByColumn',
  sortDirection: 'SortDirection',
  selectedFilter: 'SelectedFilter',
  includedIDs: 'IncludedIDs',

  dateFrom: 'DateFrom',
  dateTo: 'DateTo',
  componentIDsFilter: 'ComponentIDsFilter',
  createdByFilter: 'CreatedByFilter',
  hasNotificationFilter: 'HasNotificationsFilter',
  hasAlarmsFilter: 'HasAlarmsFilter',
  groupFilter: 'GroupFilter',

  lastSeen: 'LastSeen',
  perPage: 'PerPage',
  totalItems: 'TotalNumber',
  hasNext: 'HasNext',
};

export const defaultPagingObj = {
  [filterParams.lastSeen]: 0,
  [filterParams.perPage]: 50,
  [filterParams.totalItems]: 0,
  [filterParams.hasNext]: false,
};

export const defaultSortingFilter = {
  [filterParams.sortByColumn]: measurementConstants.fields.name,
  [filterParams.sortDirection]: sortingDirection.asc,
};

export const tableConfig = [
  {
    title: 'MEASUREMENTS.ID',
    key: measurementConstants.fields.id,
    enableSort: true,
  },
  {
    title: 'NDT_MEASUREMENTS.NAME',
    key: measurementConstants.fields.name,
    enableSort: true,
    type: measurementConstants.fields.name,
  },
  {
    title: 'NDT_MEASUREMENTS.FILTER_GROUP',
    key: measurementConstants.fields.filterGroup,
    enableSort: true,
    type: measurementConstants.fields.filterGroup,
    className: 'ellipsis',
  },
  {
    key: measurementConstants.fields.alarmColor,
    type: measurementConstants.fields.alarmColor,
    className: 'inline-right',
  },
];
export const tableConfigLite = [
  {
    title: 'MEASUREMENTS.ID',
    key: measurementConstants.fields.id,
    enableSort: true,
  },
  {
    title: 'MEASUREMENTS.NAME',
    key: measurementConstants.fields.name,
    enableSort: true,
    class: 'big',
    type: measurementConstants.fields.name,
  },
  {
    key: measurementConstants.fields.alarmColor,
    type: measurementConstants.fields.alarmColor,
    className: 'inline-right',
  },
  {
    title: 'NDT_MEASUREMENTS.FILTER_GROUP',
    key: measurementConstants.fields.filterGroup,
    enableSort: true,
    type: measurementConstants.fields.filterGroup,
  },
  {
    title: 'NDT_MEASUREMENTS.STATUS',
    key: measurementConstants.fields.status,
    enableSort: true,
    type: measurementConstants.fields.status,
  },
  {
    title: 'NDT_MEASUREMENTS.UNIT',
    key: measurementConstants.fields.unit,
    enableSort: true,
    type: measurementConstants.fields.unit,
  },
  {
    title: 'NDT_MEASUREMENTS.COMPONENT',
    key: measurementConstants.fields.componentName,
    enableSort: true,
    class: 'medium',
    type: measurementConstants.fields.componentName,
  },
  {
    title: 'NDT_MEASUREMENTS.DESCTIPTION',
    key: measurementConstants.fields.description,
    class: 'large',
    type: measurementConstants.fields.description,
  },
  {
    title: 'NDT_MEASUREMENTS.THRESHOLD',
    key: measurementConstants.fields.threshold,
    enableSort: true,
    type: measurementConstants.fields.threshold,
  },
  {
    title: 'NDT_MEASUREMENTS.LAST_MEASURE',
    key: measurementConstants.fields.lastMeasurementValue,
    enableSort: true,
    class: 'big',
    type: measurementConstants.fields.lastMeasurementValue,
  },
  {
    title: 'NDT_MEASUREMENTS.LAST_MEASURE_DATE',
    key: measurementConstants.fields.lastMeasurementDate,
    enableSort: true,
    class: 'big',
    type: measurementConstants.fields.lastMeasurementDate,
  },
  {
    title: 'NDT_MEASUREMENTS.CREATED_BY',
    key: measurementConstants.fields.createdBy,
    enableSort: true,
    class: 'big',
    type: measurementConstants.fields.createdBy,
  },
  {
    title: 'NDT_MEASUREMENTS.NOTIFICATIONS_NUMBER',
    key: measurementConstants.fields.notificationsNumber,
    enableSort: true,
    type: measurementConstants.fields.notificationsNumber,
  },
];

export const measurementPointConstants = {
  fields: {
    id: 'ID',
    measuredValue: 'MeasuredValue',
    measuredDate: 'MeasuredDate',
    measuredByUser: 'MeasuredByUser',
    measuredByDevice: 'MeasuredByDevice',
    filesCount: 'Files',
    imagesCount: 'Images',
    color: 'Colour',
    createdByID: 'CreatedByUserID',
  },
};

export const uploadGroups = {
  images: 'IMAGES',
  files: 'OTHER',
  imageFiles: 'Images',
  otherFiles: 'OtherFiles',
};

const alarmColorConstants = {
  green: 'severityGreen',
  yellow: 'severityYellow',
  orange: 'severityOrange',
  red: 'severityRed',
};

export const alarmLevelThemeColors = {
  severityGreen: {
    field: filterParams.statusFilter,
    status: alarmColorConstants.green,
    key: 'severityGreen',
    label: 'STATUS_COLORS.GREEN',
    active: false,
  },
  severityYellow: {
    field: filterParams.statusFilter,
    status: alarmColorConstants.yellow,
    key: 'severityYellow',
    label: 'STATUS_COLORS.YELLOW',
    active: true,
  },
  severityOrange: {
    field: filterParams.statusFilter,
    status: alarmColorConstants.orange,
    key: 'severityOrange',
    label: 'STATUS_COLORS.ORANGE',
    active: true,
  },
  severityRed: {
    field: filterParams.statusFilter,
    status: alarmColorConstants.red,
    key: 'severityRed',
    label: 'STATUS_COLORS.RED',
    active: true,
  },
};

export const chartFilters = {
  type: 'Type',
  group: 'Group',
  dateFrom: 'DateFrom',
  dateTo: 'DateTo',
};

export const groupValues = {
  day: 'DAY',
  week: 'WEEK',
  month: 'MONTH',
  months3: '3-MONTHS',
  months6: '6-MONTHS',
  year: 'YEAR',
  all: 'ALL',
  none: 'NONE',
};

export const graphTypeValues = {
  excessiveRate: 'EXCESSIVE-RATE',
  measurementValues: 'MEASUREMENT-VALUES',
};

export const excessiveRateAlarms = {
  [groupValues.day]: ['ERD'],
  [groupValues.week]: ['ERW'],
  [groupValues.month]: ['ERM'],
  [groupValues.year]: ['ERY'],
};

export const emptyStateIndicator = 'empty';

export const sortingDirections = {
  asc: 'ASC',
  desc: 'DESC',
};
export const perPage = 20;

export const chartDateConfig = {
  default: {
    defaultDate: currentDate => new Date(new Date(currentDate).getFullYear(), 0, 1),
    dateFormat: 'MMMM d, yyyy h:mm aa',
    timeFormat: 'h:mm aa',
    showTimeSelect: true,
  },
  [groupValues.day]: {
    defaultDate: currentDate => currentDate,
    dateFormat: 'MMMM d, yyyy h:mm aa',
    timeFormat: 'h:mm aa',
    showTimeSelect: true,
  },
  [groupValues.week]: {
    defaultDate: currentDate => {
      const d = new Date(currentDate);
      let day = d.getDay(),
        diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
      return new Date(d.setDate(diff));
    },
    dateFormat: 'MMMM d, yyyy',
  },
  [groupValues.month]: {
    defaultDate: currentDate => new Date(currentDate.getFullYear(), currentDate.getMonth(), 2),
    dateFormat: 'MMMM, yyyy',
    showMonthYearPicker: true,
  },
  [groupValues.months3]: {
    defaultDate: currentDate => {
      const d = new Date(currentDate.getFullYear(), currentDate.getMonth(), 2);
      d.setMonth(d.getMonth() - 2);
      return d;
    },
    dateFormat: 'MMMM, yyyy',
    showMonthYearPicker: true,
  },
  [groupValues.months6]: {
    defaultDate: currentDate => {
      const d = new Date(currentDate.getFullYear(), currentDate.getMonth(), 2);
      d.setMonth(d.getMonth() - 5);
      return d;
    },
    dateFormat: 'MMMM, yyyy',
    showMonthYearPicker: true,
  },
  [groupValues.year]: {
    defaultDate: currentDate => new Date(new Date(currentDate).getFullYear(), 0, 2),
    dateFormat: 'yyyy',
    showYearPicker: true,
  },
  [groupValues.all]: {
    defaultDate: () => null,
    dateFormat: 'yyyy',
    showYearPicker: true,
  },
};

export const tabNames = {
  details: 'DETAILS',
  history: 'HISTORY',
};

export const toolbarItems = {
  [tabNames.details]: {
    name: tabNames.details,
    label: 'NDT_MEASUREMENTS.DETAILS_TAB',
  },
  [tabNames.history]: {
    name: tabNames.history,
    label: 'NDT_MEASUREMENTS.HISTORY_TAB',
  },
};

export const historyItems = {
  Name: {
    title: 'NDT_MEASUREMENTS.NAME',
  },
  Geometry: {
    subProp: 'coordinates',
    title: 'NDT_MEASUREMENTS.GEOMETRY',
    decimals: 2,
  },
  CameraPosition: {
    title: 'NDT_MEASUREMENTS.CAMERA_POSITION',
    decimals: 2,
  },
  MeasurementPoints: {
    title: 'NDT_MEASUREMENTS.MEASUREMENT_POINT',
  },
  Colour: {
    title: 'NDT_MEASUREMENTS.COLOUR',
    component: HistoryItemColorDisplay,
  },
  NdtAlarmEmail: {
    subProp: 'Email',
    title: 'NDT_MEASUREMENTS.ALARM_EMAIL',
  },
  ComponentID: {
    title: 'NDT_MEASUREMENTS.COMPONENT',
    component: HistoryItemComponentChange,
  },
  FilterGroup: {
    title: 'NDT_MEASUREMENTS.FILTER_GROUP',
  },
  Unit: {
    title: 'NDT_MEASUREMENTS.UNIT',
  },
  Description: {
    title: 'NDT_MEASUREMENTS.DESCRIPTION',
  },
  Treshold: {
    title: 'NDT_MEASUREMENTS.TRESHOLD',
  },
  Property: {
    isCustomProp: true,
    nonTranslatableTitle: true,
  },
  Active: {
    title: 'NDT_MEASUREMENTS.ACTIVE',
  },
  Value: {
    title: 'NDT_MEASUREMENTS.VALUE',
  },
  MeasurementPoint: {
    subProp: 'MeasuredValue',
    title: 'NDT_MEASUREMENTS.MEASUREMENT_POINT',
    displayItemName: true,
  },
  MeasureID: {
    title: 'NDT_MEASUREMENTS.MEASUREMENT_POINT',
    displayItemName: true,
  },
  MeasuredValue: {
    title: 'NDT_MEASUREMENTS.MEASUREMENT_POINT',
    displayItemName: true,
  },
  Files: {
    title: 'NDT_MEASUREMENTS.FILE',
    isFile: true,
    component: HistoryItemFileDisplay,
  },
};

export const ndtModificationEvents = {
  ndt_measurement_location_created: {
    title: 'NDT_MEASUREMENTS.CREATED_EVENT',
    event: 'ndt_measurement_location_created',
  },
  ndt_measurement_location_updated: {
    title: 'NDT_MEASUREMENTS.UPDATED_EVENT',
    event: 'ndt_measurement_location_updated',
  },
  ndt_measurement_location_deleted: {
    title: 'NDT_MEASUREMENTS.DELETED_EVENT',
    event: 'ndt_measurement_location_deleted',
    titleClass: 'f-secondary-red',
  },
  ndt_measurement_location_component_linked: {
    title: 'NDT_MEASUREMENTS.COMPONENT_LINKED',
    event: 'ndt_measurement_location_component_linked',
  },
  ndt_measurement_location_file_linked: {
    title: 'NDT_MEASUREMENTS.FILE_UPLOADED',
    event: 'ndt_measurement_location_file_linked',
  },
  ndt_measurement_location_file_unlinked: {
    title: 'NDT_MEASUREMENTS.FILE_DELETED',
    event: 'ndt_measurement_location_file_unlinked',
    titleClass: 'f-secondary-red',
  },
  ndt_measurement_location_position_updated: {
    title: 'NDT_MEASUREMENTS.UPDATED_EVENT',
    event: 'ndt_measurement_location_position_updated',
  },
  ndt_measurement_location_custom_property_added: {
    title: 'NDT_MEASUREMENTS.LOCATION_PROPERTY_ADDED_EVENT',
    event: 'ndt_measurement_location_custom_property_added',
  },
  ndt_measurement_location_custom_property_updated: {
    title: 'NDT_MEASUREMENTS.LOCATION_PROPERTY_UPDATED_EVENT',
    event: 'ndt_measurement_location_custom_property_updated',
  },
  ndt_measurement_location_custom_property_deleted: {
    title: 'NDT_MEASUREMENTS.LOCATION_PROPERTY_DELETED_EVENT',
    event: 'ndt_measurement_location_custom_property_deleted',
    titleClass: 'f-secondary-red',
  },
  ndt_measurement_point_created: {
    title: 'NDT_MEASUREMENTS.POINT_CREATED_EVENT',
    event: 'ndt_measurement_point_created',
  },
  ndt_measurement_point_updated: {
    title: 'NDT_MEASUREMENTS.UPDATED_EVENT',
    event: 'ndt_measurement_point_updated',
  },
  ndt_measurement_point_deleted: {
    title: 'NDT_MEASUREMENTS.DELETED_EVENT',
    event: 'ndt_measurement_point_deleted',
    titleClass: 'f-secondary-red',
  },
  ndt_measurement_point_file_linked: {
    title: 'NDT_MEASUREMENTS.FILE_UPLOADED',
    event: 'ndt_measurement_point_file_linked',
  },
  ndt_measurement_point_file_unlinked: {
    title: 'NDT_MEASUREMENTS.FILE_DELETED',
    event: 'ndt_measurement_point_file_unlinked',
    titleClass: 'f-secondary-red',
  },
  ndt_measurement_point_custom_property_added: {
    title: 'NDT_MEASUREMENTS.ADDED_EVENT',
    event: 'ndt_measurement_point_custom_property_added',
  },
  ndt_measurement_point_custom_property_updated: {
    title: 'NDT_MEASUREMENTS.UPDATED_EVENT',
    event: 'ndt_measurement_point_custom_property_updated',
  },
  ndt_measurement_point_custom_property_deleted: {
    title: 'NDT_MEASUREMENTS.DELETED_EVENT',
    event: 'ndt_measurement_point_custom_property_deleted',
    titleClass: 'f-secondary-red',
  },
  ndt_measurement_location_alarm_created: {
    title: 'NDT_MEASUREMENTS.ALARM_CREATED_EVENT',
    event: 'ndt_measurement_location_alarm_created',
  },
  ndt_measurement_location_alarm_updated: {
    title: 'NDT_MEASUREMENTS.ALARM_UPDATED_EVENT',
    event: 'ndt_measurement_location_alarm_updated',
  },
  ndt_measurement_location_alarm_email_added: {
    title: 'NDT_MEASUREMENTS.ALARM_ADDED_EVENT',
    event: 'ndt_measurement_location_alarm_email_added',
  },
  ndt_measurement_location_alarm_email_updated: {
    title: 'NDT_MEASUREMENTS.ALARM_UPDATED_EVENT',
    event: 'ndt_measurement_location_alarm_email_updated',
  },
  ndt_measurement_location_alarm_email_deleted: {
    title: 'NDT_MEASUREMENTS.ALARM_DELETED_EVENT',
    event: 'ndt_measurement_location_alarm_email_deleted',
    titleClass: 'f-secondary-red',
  },
  ndt_measurement_location_alarm_notifications_enabled: {
    title: 'NDT_MEASUREMENTS.UPDATED_EVENT',
    event: 'ndt_measurement_location_alarm_notifications_enabled',
  },
  ndt_measurement_location_predictive_setting_added: {
    title: 'NDT_MEASUREMENTS.ADDED_EVENT',
    event: 'ndt_measurement_location_predictive_setting_added',
  },
};

export const historyFilterParams = {
  lastSeen: 'LastSeen',
  perPage: 'PerPage',
  sortByColumn: 'ModifiedAt',
  sortDirection: 'SortDirection',
  totalItems: 'TotalNumber',
  hasNext: 'HasNext',
};

export const historyDefaultPagingObj = {
  [historyFilterParams.lastSeen]: 0,
  [historyFilterParams.perPage]: 20,
  [historyFilterParams.totalItems]: 0,
  [historyFilterParams.hasNext]: false,
};

const pointsTableHeader = (translate, unit) => [
  {
    title: 'NDT_TABLE.ID',
    key: measurementPointConstants.fields.id,
  },
  {
    title: translate('NDT_TABLE.MEASUREMENT', { unit }),
    key: measurementPointConstants.fields.measuredValue,
    // enableSort: true,
  },
  {
    title: 'NDT_TABLE.DATE',
    key: measurementPointConstants.fields.measuredDate,
    type: 'date',
    enableSort: true,
  },
  {
    title: 'NDT_TABLE.ADDED_BY',
    key: measurementPointConstants.fields.measuredByUser,
    // enableSort: true,
  },
  {
    title: 'NDT_TABLE.IMAGES',
    CustomComponent: data => (
      <div className="files-count">
        <ImagesIcon className="svg-white-g" height={15} />
        <p className="f-primary">+ {data[measurementPointConstants.fields.imagesCount]}</p>
      </div>
    ),
    // enableSort: true,
  },
  {
    title: 'NDT_TABLE.FILES',
    CustomComponent: data => (
      <div className="files-count">
        <FilesIcon className="svg-white-g" height={15} />
        <p className="f-primary">+ {data[measurementPointConstants.fields.filesCount]}</p>
      </div>
    ),
    // enableSort: true,
  },
  {
    title: '',
    CustomComponent: data => <p className="f-secondary-green link">{translate('NDT_TABLE_ACTIONS.MENU.ITEM_1')}</p>,
    // enableSort: false,
  },
];

export default {
  pointsTableHeader,
  alarmColorConstants,
  alarmLevelThemeColors,
};
