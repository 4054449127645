const loaderColor = '#0000ff';
const loaderSize = 60;
const loaderSizeUnit = 'px';

const loaderTypes = {
  computerVision: {
    name: 'computer-vision',
  },
};

export default {
  loaderColor,
  loaderSize,
  loaderSizeUnit,
  loaderTypes,
};
