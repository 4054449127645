import React from 'react';
import { Field, reduxForm, change } from 'redux-form';
import { fileTypesOptionsValues, filterSections } from '../../../constants/constants';
import CustomCheckBox from '../../../../../common/form/components/custom-checkbox';
import { FORMS } from '../../../../../common/constants';

let DMSFileTypesForm = ({ handleCheckboxChange, initialValues }) => {
  const handleChange = (e, item) => {
    change(item, e.target.checked);

    handleCheckboxChange({
      ...initialValues,
      [item]: e.target.checked,
    });
  };

  return (
    <form noValidate>
      {Object.values(fileTypesOptionsValues).map((item, index) => {
        return (
          <Field
            name={item.name}
            id={item.name}
            component={CustomCheckBox}
            value={initialValues[item.name]}
            checkboxText={item.checkboxText}
            className={'base'}
            onChange={e => handleChange(e, item.name)}
            key={`${item.name}-${index}`}
          />
        );
      })}
    </form>
  );
};

DMSFileTypesForm = reduxForm({ form: FORMS.dmsFiltersFileTypeForm, enableReinitialize: true, touchOnChange: true, destroyOnUnmount: true })(DMSFileTypesForm);

const DMSFileTypes = ({ quickFiltersChange, value }) => {
  return <DMSFileTypesForm handleCheckboxChange={e => quickFiltersChange({ [filterSections.fileTypes.valueKey]: e })} initialValues={value} />;
};

export default DMSFileTypes;
