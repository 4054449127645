import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { submit } from 'redux-form';

import { get } from 'lodash';
import { FORMS } from '../../constants';

import Helpers from '../../helpers';

import { formValueSelector, change } from 'redux-form';

import DownloadReportFormXslx from './download-report-form-xslx';
import constants from '../constants/download-report-constants';

class DownloadReportXslx extends Component {
  render() {
    const {
      chartPeriods,
      ndtChartPeriodGroupData,
      ndtMeasurementsComponents,
      ndtMeasurementsUnits,
      ndtAlarmsTriggered,
      initialValues,
      downloadReport,
      submitTrigger,
      filterGroups,
      skipStep,
      closeModal,
    } = this.props;

    return (
      <div className="download-report">
        <DownloadReportFormXslx
          {...{
            ndtMeasurementsComponents,
            ndtMeasurementsUnits,
            ndtAlarmsTriggered,
            chartPeriods,
            ndtChartPeriodGroupData,
            initialValues,
            submitTrigger,
            filterGroups,
            onSubmit: downloadReport,
            skipStep,
            closeModal,
          }}
        />
      </div>
    );
  }
}
DownloadReportXslx.contextTypes = {
  t: PropTypes.func.isRequired,
};
const selector = formValueSelector(FORMS.downloadReportXslx);

//handle if all is selected to make it remove all items and select all and vice versa
const handleMultiSelectValues = (selector, change) => {
  Object.keys(constants.multiSelectFields).forEach(key => {
    const currentVal = selector(constants.multiSelectFields[key]);

    if (currentVal) {
      const indexOfDefault = currentVal.indexOf(constants.defaultValue);

      if (indexOfDefault === 0 && currentVal.length > 1) {
        // if all is present after one selection then remove it
        change(constants.multiSelectFields[key], currentVal.splice(indexOfDefault, 1));
      } else if (indexOfDefault > 0 && currentVal.length > 0) {
        //if all are is selected deselect all
        change(constants.multiSelectFields[key], currentVal.splice(0, currentVal.length, constants.defaultValue));
      }
    }
  });
};

const mapDispatchToProps = dispatch => ({
  submitTrigger: () => {
    dispatch(submit(FORMS.downloadReportXslx));
  },
});

const mapStateToProps = (state, { chartPeriods, ndtChartPeriodGroupData, ndtMeasurementsComponents, ndtMeasurementsUnits, ndtAlarmsTriggered, skipStep, filterGroups }) => {
  let initialValues = {
    // [constants.fields.timeline]: chartPeriods && chartPeriods[0],
    // [constants.fields.whichMeasurement]: ndtChartPeriodGroupData && ndtChartPeriodGroupData[0],
    [constants.fields.includeAnalytics]: true,
    //set initial date if there is no date present new Date creates infinite loop
    [constants.fields.dateFrom]: get(state, `form[${FORMS.downloadReportXslx}].initial[${constants.fields.dateFrom}]`) || new Date().setDate(new Date().getDate() - 7),
    [constants.fields.dateTo]: get(state, `form[${FORMS.downloadReportXslx}].initial[${constants.fields.dateTo}]`) || new Date(),
  };

  //for longer form
  if (!skipStep) {
    handleMultiSelectValues(
      propName => selector(state, propName),
      (propName, value) => change(FORMS.downloadReportXslx, propName, value)
    );
    initialValues = {
      ...initialValues,
      [constants.fields.components]: [constants.defaultValue],
      [constants.fields.measurmentUnits]: [constants.defaultValue],
      [constants.fields.includeAlarmsTriggered]: false,
      // [constants.fields.alarmsTriggered]: [constants.defaultValue],
      [constants.fields.filterGroups]: [constants.defaultValue],
    };
  }

  return {
    ndtMeasurementsComponents: Helpers.mapReportData(ndtMeasurementsComponents, constants.defaultValue),
    ndtMeasurementsUnits: Helpers.mapReportData(ndtMeasurementsUnits, constants.defaultValue),
    ndtAlarmsTriggered: Helpers.mapReportData(ndtAlarmsTriggered, constants.defaultValue),
    filterGroups: Helpers.mapReportData(filterGroups, constants.defaultValue),
    initialValues,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadReportXslx);
