import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { FORMS } from '../../../common/constants';

import constants from '../constants/constants';
import CustomInput from '../../../common/form/components/input';
import DatePicker from '../../../common/form/components/date-picker';

import Button from '../../../common/form/components/button';

class EditPdfForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: props.initialValues[constants.fields.revisionDate],
    };
  }

  handleFormKeyPress = e => {
    if (e.key === 'Enter') e.preventDefault();
  };

  render() {
    const { t } = this.context;
    const { startDate } = this.state;
    const { handleSubmit, invalid, anyTouched, onCancel } = this.props;

    return (
      <form className="edit-pdf-form" onSubmit={handleSubmit} onKeyPress={this.handleFormKeyPress} noValidate>
        <Field
          id={constants.fields.revisionDate}
          name={constants.fields.revisionDate}
          disabledKeyboardNavigation={true}
          selected={startDate}
          onChange={(e, date) => {
            this.setState({ startDate: date });
          }}
          dateFormat="MMMM d, yyyy"
          label="EDIT_PDF_MODAL.FORM.REVISION_DATE"
          placeholderText={`${t('EDIT_PDF_MODAL.FORM.REVISION_DATE')}`}
          component={DatePicker}
        />
        <Field
          id={constants.fields.title}
          name={constants.fields.title}
          label="EDIT_PDF_MODAL.FORM.TITLE"
          labelClass="f-secondary-light"
          component={CustomInput}
          placeholder="EDIT_PDF_MODAL.FORM.TITLE"
          disabled
        />
        <Field
          id={constants.fields.drawingNumber}
          name={constants.fields.drawingNumber}
          label="EDIT_PDF_MODAL.FORM.DRAWING_NUMBER"
          labelClass="f-secondary-light"
          component={CustomInput}
          placeholder="EDIT_PDF_MODAL.FORM.DRAWING_NUMBER"
        />
        <Field
          id={constants.fields.revision}
          name={constants.fields.revision}
          label="EDIT_PDF_MODAL.FORM.REVISION"
          labelClass="f-secondary-light"
          component={CustomInput}
          placeholder="EDIT_PDF_MODAL.FORM.REVISION"
        />

        <div className="buttons">
          <Button type="button" variant="gray-outline" width="sm" text={t('CREATE_USER.CANCEL')} onClick={() => onCancel()} />
          <Button disabled={invalid && anyTouched} width="sm" text={t('CREATE_USER.SAVE')} />
        </div>
      </form>
    );
  }
}

EditPdfForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

EditPdfForm = reduxForm({
  form: FORMS.editPdf,
  enableReinitialize: true,
})(EditPdfForm);

export default connect(null, null)(EditPdfForm);
