import { debounce, keys, map, reduce, values } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import DisplayStatus from '../../../../common/display-status/components/display-status';
import Icon from '../../../../common/icon/components/icon';
import SearchInput from '../../../../common/input/components/search-input';
import ItemsTableRenderer from '../../../../common/items-renderer/components/items-table-renderer';
import LoadMore from '../../../../common/load-more/components/load-more';
import StatusesFilter from '../../../../common/statuses-filter/components/statuses-filter';
import ModuleHeader from '../left-toolbar/common/module-header';

import DisplayColor from '../../../../common/display-color/components/display-color';
import Helpers from '../../../../common/helpers';
import Modal from '../../../../common/modal/components/modal';
import InfoTooltip from '../../../../common/tooltip/components/info-tooltip';
import MultiplePreviewPopup from '../../../../common/tooltip/components/multiple-preview-popover';

import { PERMISSIONS, PERMISSION_TYPES } from '../../../../common/permissions-constants';
import { setPermitsData, setPermitsFilters, setSearchText } from '../../actions/action-creators';
import { getPermitTemplatesFilters } from '../../actions/permit-actions';
import { columnTypes, defaultFilters, fields, filterProps, sortDirection, statuses, tableConfigLite } from './constants/permit-constants';
import { defaultFilter, filterFields } from './constants/permit-form-constants';

import '../../styles/permits.scss';
import { singleKeyFields } from './constants/keybox-constants';
import CreatePermitModal from './create-permit-modal';
import PermitsFilter from './permits-filter';

// TODO: refactor to functional component
class Permits extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalData: {
        isOpen: false,
      },
      advancedFilter: {},
      templates: [],
    };

    this.searchPermitTemplatesDebounce = debounce(this.onSearchPermitTemplates, 600);
    this.onStatusChangeDebounce = debounce(this.onStatusChange, 200);
    this.searchChangeDebounced = debounce(this.search, 250);
    this.closePermitModalDebounced = debounce(this.closeCreatePermitModal, 350);
  }
  componentDidMount = () => {
    const { filters } = this.props;
    const { projectId } = this.props;

    this.fetchPermits(filters, false);
    this.onSearchPermitTemplates({ ...defaultFilter, [filterFields.projectID]: projectId });
  };

  componentWillUnmount = () => {
    const { setPermitsData, setPermitsFilters } = this.props;

    setPermitsData(false, []);
    setPermitsFilters(defaultFilters);
  };

  handleInputChange = e => {
    const { setSearchText } = this.props;
    setSearchText(e.target.value);
    this.searchChangeDebounced(e.target.value);
  };

  search = () => {
    const { filters } = this.props;
    this.fetchPermits(filters, false);
  };

  fetchPermits = (params, loadMore, filterStatuses, isStatusChange) => {
    const { advancedFilter } = this.state;
    const { getPermits, searchText, projectId } = this.props;
    params[filterProps.projectId] = projectId;
    params[filterProps.searchText] = searchText;
    params[filterProps.lastSeen] = loadMore ? params[filterProps.lastSeen] : defaultFilters[filterProps.lastSeen];

    if (isStatusChange) {
      if (filterStatuses === '') {
        // resets singleSelected filter status/flag when archived filter turned off
        params[filterProps.singleSelected] = [];
        params[filterProps.singleSelected] = '';
        params[filterProps.archived] = false;
      }

      if (filterStatuses) {
        if (filterStatuses === statuses.archived.value) {
          params[filterProps.archived] = filterStatuses === statuses.archived.value;
          params[filterProps.singleSelected] = filterStatuses;
        } else {
          params[filterProps.status] = filterStatuses;
        }
      }
    }

    const onSuccessFetch = listLength => {
      if (loadMore && listLength) {
        Helpers.scrollIntoView('table-container', `row-${listLength - 1}`);
      }
    };

    //normalize advanced filter
    const newAdvancedFilter = reduce(
      advancedFilter,
      (obj, it, key) => {
        if ([filterProps.createdBy, filterProps.issuedTo, filterProps.handbackedTo].indexOf(key) > -1) {
          obj[key] = map(advancedFilter[key], ({ ID, UserID }) => ID || UserID);
        } else if ([filterProps.permitType].indexOf(key) > -1) {
          obj[key] = map(advancedFilter[key], ({ Name }) => Name);
        } else if ([filterProps.dateFrom, filterProps.issuedDateFrom, filterProps.handbackedDateFrom].indexOf(key) > -1) {
          obj[key] = (advancedFilter[key] && Helpers.getUnixDate(new Date(advancedFilter[key]).getTime())) || null;
        } else if ([filterProps.dateTo, filterProps.issuedDateTo, filterProps.handbackedDateTo].indexOf(key) > -1) {
          // sets end/to dates to end of day 23:59:59 to include that day in filter results
          obj[key] = (advancedFilter[key] && Helpers.getUnixDate(new Date(advancedFilter[key]).setHours(23, 59, 59))) || null;
        } else {
          obj[key] = advancedFilter[key];
        }
        return obj;
      },
      {}
    );

    getPermits(params, loadMore, onSuccessFetch, newAdvancedFilter);
  };

  onColumnSort = SortByColumn => {
    const { filters } = this.props;
    const params = {
      ...filters,
      [filterProps.sortByColumn]: SortByColumn,
      [filterProps.sortDirection]: filters[filterProps.sortDirection] === sortDirection.asc ? sortDirection.desc : sortDirection.asc,
      [filterProps.lastSeen]: 0,
    };

    this.fetchPermits(params, false);
  };

  onStatusChange = value => {
    const { filters } = this.props;

    this.fetchPermits(filters, false, value, true);
  };

  onRowClick = id => {
    const { handleItemClick } = this.props;
    handleItemClick(id);
  };

  loadMore = () => {
    const { filters } = this.props;

    this.fetchPermits(filters, true);
  };

  onSearchPermitTemplates = params => {
    const { getPermitTemplatesFilters, projectId } = this.props;
    params = { ...params, [filterFields.projectID]: projectId };

    getPermitTemplatesFilters(params, templates => this.setState({ templates }));
  };

  closeCreatePermitModal = () => {
    const { permitDetailsData } = this.props;

    this.toggleCreatePermitModal(false);
    // trigger opening of right-hand slider with permit details
    if (permitDetailsData && permitDetailsData[fields.id]) {
      this.onRowClick(permitDetailsData[fields.id]);
    }
  };

  toggleCreatePermitModal = isOpen => {
    const { projectId, inspectionId, changeField } = this.props;
    const { t } = this.context;

    let modalData = {
      isOpen,
    };

    if (isOpen) {
      modalData = {
        ...modalData,
        ...{
          isOpen: true,
          title: t('PERMITS.CREATE_MODAL_TITLE'),
          CustomContent: dynamicProps => (
            <CreatePermitModal
              {...dynamicProps}
              projectId={projectId}
              onSearchPermitTemplates={this.searchPermitTemplatesDebounce}
              changeField={changeField}
              inspectionId={inspectionId}
              closeAction={() => this.closePermitModalDebounced()}
            />
          ),
          customClassName: 'modal-large',
          type: 'none',
          closeAction: () => this.closePermitModalDebounced(),
        },
      };
    }

    this.setState({ modalData });
  };

  openAdvancedFilter = () => {
    const { t } = this.context;
    const { projectId, filters, setModalState } = this.props;
    const { advancedFilter } = this.state;
    const closeAction = () => setModalState({ isOpen: false });

    const modalData = {
      isOpen: true,
      type: 'none',
      title: t('ADVANCED_FILTERS.TITLE'),
      CustomContent: () => (
        <PermitsFilter
          submitForm={values => {
            this.setState(
              {
                advancedFilter: values,
              },
              () => this.fetchPermits(filters, false)
            );
            closeAction();
          }}
          resetFilter={() => {
            this.setState(
              {
                advancedFilter: {},
              },
              () => this.fetchPermits(filters, false)
            );
            closeAction();
          }}
          initialValues={advancedFilter}
          closeAction={closeAction}
          projectId={projectId}
        />
      ),
      customClassName: 'modal-large defects-filter',
      closeAction,
    };

    setModalState(modalData);
  };

  render() {
    const { t } = this.context;
    const { modalData, templates, advancedFilter } = this.state;
    const { title, backAction, moduleActionsMenu, icon, permits, permitsLoading, tableConfig, queryItem, filters, user, isInLeftToolbar } = this.props;
    const { handleInputChange } = this;

    return (
      <div className="permits">
        <ModuleHeader
          {...{
            title,
            icon,

            backAction,
            actionsMenu: moduleActionsMenu,
            actionButtontext: 'CREATE_BUTTON_TEXT.NEW',
            handleActionButtonClick: () => this.toggleCreatePermitModal(true),
            actionButtonProps: {
              user,
              visibleFor: PERMISSIONS[PERMISSION_TYPES.permits].create.name,
            },
          }}
        />
        <div className="advanced-filter">
          <SearchInput onChange={handleInputChange} placeholder={t('PROJECT.SEARCH.PLACEHOLDER')} stripped />
          <p className="f-secondary-green noselect pointer link" onClick={this.openAdvancedFilter}>
            {t('ADVANCED_FILTER', { active: keys(advancedFilter).length })}
          </p>
        </div>
        <div className="status-filters">
          <p className="f-secondary-dark status-filters-title bold">{t('PERMITS.FILTERS_TITLE')}</p>
          <StatusesFilter
            statuses={values(statuses)}
            selected={filters[filterProps.status]}
            singleSelected={filters[filterProps.singleSelected]}
            onChange={this.onStatusChangeDebounce}
            isAdjustedForSmallerScreen={isInLeftToolbar}
            isLoading={permitsLoading}
          />
        </div>
        <div className="table-container">
          <ItemsTableRenderer
            isLoading={permitsLoading}
            tableConfig={tableConfig || tableConfigLite}
            translationModule={t}
            emptyStateText="PERMITS.EMPTY_STATE"
            data={map(permits, item => ({ ...item, selected: item[fields.id] === queryItem }))}
            onRowClick={(_e, item) => this.onRowClick(item[fields.id])}
            sortingObj={{
              [filterProps.sortByColumn]: filters[filterProps.sortByColumn],
              [filterProps.sortDirection]: filters[filterProps.sortDirection],
            }}
            formatCell={(value, type, index, item) => {
              if (type === columnTypes.status) {
                return <DisplayStatus statuses={values(statuses)} status={value} />;
              } else if (type === columnTypes.statusCustom) {
                return <Icon name="eye" active={item[fields.id] === queryItem} size="xs" onClick={() => console.log('Item clicked')} title={t('ITEMS_LIST.VISIBILTY_ICON')} />;
              } else if (type === columnTypes.permitType) {
                return (
                  <>
                    <DisplayColor color={item[fields.colour]} />
                    <span className="f-primary">{value}</span>
                  </>
                );
              } else if (type === columnTypes.keyboxes) {
                const keyboxNumber = (value || []).length;

                if (keyboxNumber < 2) {
                  return keyboxNumber && item[fields.keyboxes] && item[fields.keyboxes][0] ? item[fields.keyboxes][0][fields.name] : '-';
                } else {
                  return (
                    <div className="name">
                      <InfoTooltip
                        customComponent={<MultiplePreviewPopup title={item[fields.name]} subTitle={t('PERMITS_TABLE.KEYBOXES')} list={value} />}
                        offsetY={10}
                        offsetX={-20}
                        Component={() => (
                          <div className="permits-popover">
                            <p className="f-primary">{t('ISOLATION_CERTIFICATES.LINKED_PERMITS_PLACEHOLDER', { number: keyboxNumber })}</p>
                            <Icon name="info" handleHover={false} size="xs" />
                          </div>
                        )}
                      />
                    </div>
                  );
                }
              } else if (type === columnTypes.keys) {
                let keys = [];
                (item[fields.keyboxes] || []).forEach(keybox => {
                  keys = [...keys, ...(keybox[fields.keys] || []).map(key => ({ ...key, [fields.name]: `${key[singleKeyFields.name]} (${keybox[fields.name]})` }))];
                });
                const keysNumber = (keys || []).length;

                if (keysNumber < 2) {
                  return keysNumber && item[fields.keyboxes][0][fields.keys] && item[fields.keyboxes][0][fields.keys][0] ? item[fields.keyboxes][0][fields.keys][0][singleKeyFields.name] : '-';
                } else {
                  return (
                    <div className="name">
                      <InfoTooltip
                        customComponent={<MultiplePreviewPopup title={item[fields.name]} subTitle={t('PERMITS_TABLE.KEYS')} list={keys} />}
                        offsetY={10}
                        offsetX={-20}
                        Component={() => (
                          <div className="permits-popover">
                            <p className="f-primary">{t('ISOLATION_CERTIFICATES.LINKED_PERMITS_PLACEHOLDER', { number: keysNumber })}</p>
                            <Icon name="info" handleHover={false} size="xs" />
                          </div>
                        )}
                      />
                    </div>
                  );
                }
              } else if (type === columnTypes.isolationCertificates) {
                const certificatesNumber = (value || []).length;

                if (certificatesNumber < 2) {
                  return certificatesNumber && item[fields.isolationCertificates] && item[fields.isolationCertificates][0] ? item[fields.isolationCertificates][0][fields.name] : '-';
                } else {
                  return (
                    <div className="name">
                      <InfoTooltip
                        customComponent={<MultiplePreviewPopup title={item[fields.name]} subTitle={t('PERMITS_TABLE.LINKED_ISOLATION_CERTIFICATES')} list={value} />}
                        offsetY={10}
                        offsetX={-20}
                        Component={() => (
                          <div className="permits-popover">
                            <p className="f-primary">{t('ISOLATION_CERTIFICATES.LINKED_PERMITS_PLACEHOLDER', { number: certificatesNumber })}</p>
                            <Icon name="info" handleHover={false} size="xs" />
                          </div>
                        )}
                      />
                    </div>
                  );
                }
              }

              return Helpers.formatCell(value, type, index, item);
            }}
            sortByColumnProp={filterProps.sortByColumn}
            onColumnSort={this.onColumnSort}
          />
          <LoadMore
            disabled={!filters[filterProps.hasNext] || permitsLoading}
            loaded={permits.length}
            total={filters[filterProps.totalItems]}
            totalPosition="center"
            isLoading={permitsLoading}
            label="COMPONENT_HISTORY.LOAD_MORE"
            showButton
            showTotalUp
            buttonVariant="success-outline"
            resultsText="PERMITS.SHOWING_TOTAL_ITEMS"
            onClick={this.loadMore}
          />
        </div>
        <Modal {...modalData} templates={templates} modalDisabled={permitsLoading} />
      </div>
    );
  }
}

Permits.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.userReducer,
  filters: state.permitsReducer.filters,
  permitDetailsData: state.permitsReducer.permitDetailsData,
});

const mapDispatchToProps = dispatch => ({
  getPermitTemplatesFilters: (params, callback) => dispatch(getPermitTemplatesFilters(params, callback)),
  setPermitsData: (isLoading, permits) => dispatch(setPermitsData(isLoading, permits)),
  setPermitsFilters: filters => dispatch(setPermitsFilters(filters)),
  setSearchText: val => dispatch(setSearchText(val)),
});

Permits = connect(mapStateToProps, mapDispatchToProps)(Permits);

export default withRouter(Permits);
