import imageSAP from '../assets/sap.png';

const integrationActions = {
  SAP: 'sapAction',
};

const integrations = [
  {
    key: '1',
    name: 'SAP',
    action: integrationActions.SAP,
    imageSrc: imageSAP,
    title: 'INTEGRATIONS.SAP.TITLE',
  },
];

export default {
  integrations,
};
