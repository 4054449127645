import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../form/components/button';
import RenderIf from '../../render-if/components/render-if';
import Loader from '../../global-loader/components/simple-loader';

import '../styles/load-more.scss';

const LoadMore = (
  {
    label = 'LOAD_MORE',
    disabled = false,
    isLoading = false,
    loadMoreOffset = 0,
    loaded = 0,
    total = 0,
    totalPosition = 'right',
    isFixed = false,
    showTotalUp = false,
    showTotalItems = true,
    showButton = false,
    hideLoadMore = false,
    onlyText = false,
    buttonVariant = 'success',
    resultsText = 'SHOWING_TOTAL_ITEMS',
    buttonWidth = 'sm',
    onClick,
  },
  { t }
) => {
  return (
    <div className="load-more">
      <RenderIf if={showTotalItems && showTotalUp && !hideLoadMore}>
        <p className="f-secondary-dark" style={{ textAlign: totalPosition }}>
          {t(resultsText, { items: loaded, totalItems: total })}
        </p>
      </RenderIf>
      <RenderIf if={showButton && !hideLoadMore && !onlyText}>
        <Button type="button" disabled={disabled} width={buttonWidth} variant={buttonVariant} className="load-more__button" onClick={onClick} text={t(label)} />
      </RenderIf>
      <RenderIf if={!showButton && !hideLoadMore && !onlyText}>
        <span className={`f-secondary-green load-more__link ${disabled ? 'disabled' : ''}`} style={{ marginLeft: `${loadMoreOffset}px` }} onClick={disabled ? () => null : onClick}>
          {t(label)}
        </span>
      </RenderIf>
      {isLoading && <Loader isLoading={true} className="loader" />}
      <RenderIf if={showTotalItems && !showTotalUp && !hideLoadMore}>
        <div className="load-more__results" style={{ position: isFixed ? 'fixed' : 'initial' }}>
          <p className="f-secondary-dark" style={{ textAlign: totalPosition }}>
            {t(resultsText, { items: loaded, totalItems: total })}
          </p>
        </div>
      </RenderIf>
    </div>
  );
};

LoadMore.contextTypes = {
  t: PropTypes.func.isRequired,
};

LoadMore.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadMoreOffset: PropTypes.number,
  loaded: PropTypes.number,
  total: PropTypes.number,
  totalPosition: PropTypes.oneOf(['left', 'center', 'right']),
  showTotalUp: PropTypes.bool,
  onlyText: PropTypes.bool,
  showButton: PropTypes.bool,
  hideLoadMore: PropTypes.bool,
  showTotalItems: PropTypes.bool,
  buttonVariant: PropTypes.oneOf(['success', 'success-outline', 'danger', 'danger-outline', 'gray', 'gray-outline', 'warning', 'warning-outline']),
  onClick: PropTypes.func.isRequired,
  buttonWidth: PropTypes.oneOf(['sm', 'md', 'lg']).isRequired,
};

export default LoadMore;
