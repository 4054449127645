import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CustomSelect from '../../../common/form/components/select';

import '../style/top-toolbar.scss';

class TopToolbar extends Component {
  render() {
    const { t } = this.context;
    const { pdfList, selectedFile, setSelectedFile } = this.props;

    return (
      <div className="t-toolbar">
        <CustomSelect
          className="pdf-select"
          label="PDF_TAG.TOP.DROPDOWN_TITLE"
          labelClass="f-secondary-light"
          data={pdfList || []}
          defaultValue={pdfList && pdfList[0] && selectedFile ? selectedFile : null}
          type="text"
          placeholder={t('PDF_TAG.TOP.DROPDOWN_PLACEHOLDER')}
          valueField="FileID"
          textField={'FileName'}
          onChange={setSelectedFile}
          groupBy={'FileType'}
        />
      </div>
    );
  }
}

TopToolbar.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default TopToolbar;
