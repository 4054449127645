export const fields = {
  permitTemplate: {
    name: 'TemplateID',
    label: 'CREATE_PERMIT_FORM.PERMIT_TEMPLATE_LABEL',
    placeholder: 'CREATE_PERMIT_FORM.PERMIT_TEMPLATE_PLACEHOLDER',
    errorRequired: 'CREATE_PERMIT_FORM.PERMIT_TEMPLATE_REQUIRED',
    id: 'permit-template',
  },
  copyWorkOrderDetails: {
    name: 'CopyWorkOrderDetails',
    label: 'CREATE_PERMIT_FORM.PERMIT_WORK_ORDER_DETAILS_COPY_LABEL',
    note: 'CREATE_PERMIT_FORM.PERMIT_WORK_ORDER_DETAILS_COPY_LABEL_NOTE',
    id: 'permit-work-order-details-copy',
  },
  workOrder: {
    name: 'WorkOrderID',
    label: 'CREATE_PERMIT_FORM.PERMIT_WORK_ORDER_LABEL',
    placeholder: 'CREATE_PERMIT_FORM.PERMIT_TEMPLATE_PLACEHOLDER',
    errorRequired: 'CREATE_PERMIT_FORM.PERMIT_WORK_ORDER_REQUIRED',
    id: 'permit-work-order',
  },
  name: {
    name: 'Name',
    label: 'CREATE_PERMIT_FORM.NAME_LABEL',
    placeholder: 'CREATE_PERMIT_FORM.NAME_PLACEHOLDER',
    errorRequired: 'CREATE_PERMIT_FORM.NAME_REQUIRED',
    minChars: 3,
    maxChars: 60,
    errorMinChars: 'CREATE_PERMIT_FORM.NAME_MIN_CHARS',
    errorMaxChars: 'CREATE_PERMIT_FORM.NAME_MAX_CHARS',
    id: 'permit-name',
  },
  description: {
    name: 'Description',
    label: 'CREATE_PERMIT_FORM.DESCRIPTION_LABEL',
    placeholder: 'CREATE_PERMIT_FORM.DESCRIPTION_PLACEHOLDER',
    maxChars: 400,
    errorMaxChars: 'CREATE_PERMIT_FORM.DESCRIPTION_MAX_CHARS',
    id: 'permit-description',
  },
  startDate: {
    name: 'StartDate',
    label: 'CREATE_PERMIT_FORM.START_DATE_LABEL',
    placeholder: 'CREATE_PERMIT_FORM.START_DATE_PLACEHOLDER',
    errorRequired: 'CREATE_PERMIT_FORM.START_DATE_REQUIRED',
    errorMinDate: 'CREATE_PERMIT_FORM.START_DATE_MIN_DATE',
    id: 'permit-start-date',
  },
  endDate: {
    name: 'EndDate',
    label: 'CREATE_PERMIT_FORM.END_DATE_LABEL',
    placeholder: 'CREATE_PERMIT_FORM.END_DATE_PLACEHOLDER',
    errorRequired: 'CREATE_PERMIT_FORM.END_DATE_REQUIRED',
    errorMinDate: 'CREATE_PERMIT_FORM.END_DATE_MIN_DATE',
    id: 'permit-end-date',
  },
};

export const filterFields = {
  projectID: 'ProjectID',
  searchText: 'SearchText',
  status: 'StatusFilter',
  sortByColumn: 'SortByColumn',
  sortByDirection: 'SortDirection',
  perPage: 'PerPage',
  lastSeen: 'LastSeen',
  hasNext: 'HasNext',
  totalItems: 'TotalItems',
};

export const sortDirection = {
  asc: 'ASC',
  desc: 'DESC',
};

export const defaultFilter = {
  [filterFields.searchText]: '',
  [filterFields.sortByColumn]: 'ID',
  [filterFields.status]: 'LIVE',
  [filterFields.sortByDirection]: sortDirection.desc,
  [filterFields.perPage]: Number.MAX_SAFE_INTEGER,
  [filterFields.lastSeen]: 0,
};

export const permitTemplateFields = {
  id: 'ID',
  name: 'Name',
};

export const workOrderFields = {
  id: 'ID',
  name: 'Name',
  description: 'Description',
  startDate: 'StartDate',
  dueDate: 'DueDate',
  components: 'Components',
};

export const workOrderDetailsDisplayProps = [
  {
    name: 'Name',
  },
  {
    name: 'Description',
  },
  {
    name: 'Components',
  },
];

export const permitFormSteps = {
  firstStep: 1,
  secondStep: 2,
};

export const componentFields = {
  id: 'ID',
  name: 'Name',
  compID: 'ComponentID',
  clusterType: 'Cluster',
};
