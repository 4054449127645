import routesConstants from './routes';
import { Api } from '../../core/api';
import { API_BASE_URL } from '../../common/constants';
import { generateQueryParams } from '../helpers/helper';

const getAreas = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_AREAS}`, data);
};

const getAreaDetails = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_AREA_DETAILS}${generateQueryParams(params)}`);
};

const createArea = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CREATE_AREA}`, data);
};

const updateArea = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_AREA}`, data);
};

const deleteArea = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_AREA}`, data);
};

const getAreaGroups = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_AREA_GROUPS}`, data);
};

const getAreaGroupsFilter = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_AREA_GROUPS_FILTER}`, data);
};

const createAreaGroup = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CREATE_AREA_GROUP}`, data);
};

const deleteAreaGroup = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_AREA_GROUP}`, data);
};

const updateAreaGroup = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_AREA_GROUP}`, data);
};

export const getAreasClustered = async (data, options) => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_AREAS_CLUSTERED}`, data, options);
};

export const getAreaItemHistory = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_AREA_ITEM_HISTORY}`, data);
};

export default {
  getAreas,
  getAreaDetails,
  createArea,
  deleteArea,
  updateArea,
  getAreaGroups,
  createAreaGroup,
  deleteAreaGroup,
  updateAreaGroup,
  getAreasClustered,
  getAreaGroupsFilter,
  getAreaItemHistory,
};
