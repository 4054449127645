import actionTypes from '../constants/action-types';

export const setTableData = (section, items) => {
  return {
    type: actionTypes.SET_TABLE_DATA,
    data: {
      section,
      items,
    },
  };
};

export const setLoadMoreItems = (section, items) => {
  return {
    type: actionTypes.SET_LOAD_MORE_ITEMS,
    data: {
      section,
      items,
    },
  };
};

export const setTableParams = (section, params) => {
  return {
    type: actionTypes.SET_TABLE_PARAMS,
    data: {
      section,
      items: params,
    },
  };
};
export const handleActivateUserModal = data => {
  return {
    type: actionTypes.HANDLE_ACTIVATE_USER_MODAL,
    data,
  };
};

export const removeItemAtIndex = (section, index) => {
  return {
    type: actionTypes.HANDLE_DELETE_ITEM_AT_INDEX,
    data: {
      section,
      index,
    },
  };
};
export const updateItemAtIndex = (section, index, item) => {
  return {
    type: actionTypes.HANDLE_UPDATE_ITEM_AT_INDEX,
    data: {
      section,
      index,
      item,
    },
  };
};

export const addItemAtIndex = (section, index, item) => {
  return {
    type: actionTypes.HANDLE_ADD_ITEM_AT_INDEX,
    data: {
      section,
      index,
      item,
    },
  };
};

export const updateItemById = (section, item, id) => {
  return {
    type: actionTypes.HANDLE_UPDATE_ITEM_BY_ID,
    data: {
      section,
      item,
      id,
    },
  };
};

export const handleClearTableData = () => {
  return {
    type: actionTypes.HANDLE_CLEAR_TABLE_DATA,
  };
};

export const handleTableDataLoading = data => {
  return {
    type: actionTypes.HANDLE_TABLE_DATA_LOADING,
    data,
  };
};
