import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { filter } from 'lodash';

import { toggleCamera, selectAllCameras, deselectAllCameras, setActiveCameraHelper } from '../actions/action-creators';
import CameraRenderer from '../../inspections/components/left-toolbar/camera-renderer';
import inspectionWorkflowConstants from '../../inspection-workflow/constants/inspection-workflow-constants';
import { setCurrentImage } from '../actions/start-workflow-actions';

class Cameras extends Component {
  handleMarkerClick = camera => {
    const { workflowImagesSelected, setCurrentImage, inspectionID, defectsTableParams } = this.props;
    const index = workflowImagesSelected.findIndex(el => camera.ID === el[inspectionWorkflowConstants.formConstants.fields.id]);
    if (index > -1) {
      setCurrentImage(workflowImagesSelected[index], index, inspectionID, defectsTableParams);
    } else {
      console.warn("Selected camera doesn't exist in current array of selected images for workflow");
    }
  };

  render() {
    const {
      deselectAll,
      selectAll,
      toggleCamera,
      deselectAllCamerasTemp,
      selectAllCamerasTemp,
      toggleCameraTemp,
      setActiveCameraHelper,
      workflowImagesSelected,
      currentImage,
      cameraPositionVisible,
      cameraThumbnailVisible,
    } = this.props;

    const camerasToRender = (workflowImagesSelected || []).map(el => ({
      ...el,
      selected: currentImage && currentImage.ID ? currentImage.ID === el.ID : false,
      visible: cameraPositionVisible,
      withThumbnail: cameraThumbnailVisible,
    }));
    return (
      <Fragment>
        <CameraRenderer
          deselectAll={deselectAll}
          selectAll={selectAll}
          toggleElement={toggleCamera}
          deselectAllTemp={deselectAllCamerasTemp}
          selectAllTemp={selectAllCamerasTemp}
          toggleElementTemp={toggleCameraTemp}
          setActiveCamera={setActiveCameraHelper}
          handleMarkerClick={this.handleMarkerClick}
          elements={camerasToRender}
        >
          {({ elementClickHandler, elementShowHandler, elementHideHandler, selectAllHandler, deselectAllHandler }) => <div />}
        </CameraRenderer>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  workflowImagesSelected: filter(state.workflowReducer.workflowImages, { [inspectionWorkflowConstants.formConstants.fields.selected]: true }) || [],
  inspectionID: state.inspectionReducer.inspectionID,
  defectsTableParams: state.startWorkflowReducer.defectsTableParams,
  currentImage: state.startWorkflowReducer.currentImage,
  cameraPositionVisible: state.startWorkflowReducer.cameraPositionVisible,
  cameraThumbnailVisible: state.startWorkflowReducer.cameraThumbnailVisible,
});

const mapDispatchToProps = dispatch => ({
  setCurrentImage: (image, index, inspectionID, tableParams) => dispatch(setCurrentImage(image, index, inspectionID, tableParams)),
  setActiveCameraHelper: cameraHelper => dispatch(setActiveCameraHelper(cameraHelper)),
  deselectAll: () => dispatch(deselectAllCameras()),
  selectAll: () => dispatch(selectAllCameras()),
  toggleCamera: id => dispatch(toggleCamera(id)),
  // TEMP
  deselectAllCamerasTemp: () => null,
  selectAllCamerasTemp: () => null,
  toggleCameraTemp: id => null,
});

export default connect(mapStateToProps, mapDispatchToProps)(Cameras);
