import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { alarmColorPalette } from '../../../app/inspections/components/readings-and-gauges/constants/alarm-constants';
import { measurementTypes } from '../../../app/inspections/constants/constants';
import Helpers from '../../helpers';
import Icon from '../../icon/components/icon';
import Modal from '../../modal/components/modal';
import Show3DModal from '../../show-3d-location-modal/components/show-3d-location-modal';
import InfoTooltip from '../../tooltip/components/info-tooltip';
import { alarmDefaults, formConstants } from '../constants/measurement-point-question-constants';
import '../styles/measurement-point-question.scss';

const MeasurementPointQuestion = ({ question, inspectionId }, { t }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [modalData, setModalData] = useState({ isOpen: false });

  const toggleExpand = () => setIsExpanded(prev => !prev);

  const open3DModal = (geometry, cameraPosition, name, systemType, colour) => {
    const element = {
      ...question,
      [formConstants.fields.name]: name,
      [formConstants.fields.visible]: true,
      [formConstants.fields.cameraPosition]: { [formConstants.fields.coordinates]: cameraPosition },
      [formConstants.fields.geometry]: { [formConstants.fields.coordinates]: geometry, type: 'Point' },
      [formConstants.fields.systemType]: systemType,
      [formConstants.fields.colour]: colour,
    };
    setModalData({
      isOpen: true,
      type: '',
      closeAction: () => setModalData({ isOpen: false }),
      title: t('SECTIONS.TAG_LOCATION_LABEL'),
      customClassName: 'location-modal modal-large',
      customClassWrapperName: 'location-modal__picker',
      CustomContent: () => (
        <Show3DModal potreeId="location-of-measurement-potree" geometryCoordinates={geometry} cameraPositionCoordinates={cameraPosition} inspectionId={inspectionId} elements={[element]} />
      ),
    });
  };

  const mappedAlarms = alarmDefaults.map(
    defaultAlarm => question?.[formConstants.fields.questionMeasurementPointAlarm]?.find(alarm => alarm?.[formConstants.fields.name] === defaultAlarm?.[formConstants.fields.name]) || defaultAlarm
  );

  const renderAlarm = alarm => (
    <div key={alarm?.[formConstants.fields.name]} className="alarm">
      <label>
        {t('ALARM')} ({t(`ALARMS.${alarm?.[formConstants.fields.name].toUpperCase().replace('-', '_')}.NAME`)})
      </label>
      <div className="alarm__name">
        {alarm?.Colour && (
          <div className={`alarm-icon ${alarmColorPalette[alarm?.[formConstants.fields.colour]]?.name}`}>
            <Icon size="sm" name="alarm-bell-icon" disabled />
          </div>
        )}
        <div className="alarm__name__text f-primary" title={alarm?.AlarmLevel !== null ? `${alarm?.AlarmLevel} ${question[formConstants.fields.measurementPointUnit]}` : '-'}>
          {alarm?.AlarmLevel !== null ? `${alarm?.AlarmLevel} ${question[formConstants.fields.measurementPointUnit]}` : '-'}
        </div>
      </div>
    </div>
  );

  const renderLocationInfo = (labelKey, paragraph, geometry, cameraPosition, name, systemType, colour) => (
    <div className="show-location">
      <label>{t(labelKey)}</label>
      {!isEmpty(geometry) ? (
        <div className="location-text" onClick={() => open3DModal(geometry, cameraPosition, name, systemType, colour)}>
          {t('SHOW_TAGGED_3D_LOCATION')} <Icon name="external-link" size="xs" />
        </div>
      ) : (
        <InfoTooltip
          className="no-location-tooltip"
          offsetY={6}
          offsetX={6}
          customComponent={
            <div className="container">
              <span className="f-primary">{t('NO_LOCATION')}</span>
              <p className="f-primary">{t(paragraph)}</p>
              <br />
              <p className="f-primary">{t('EDIT_PERMISSIONS_REQUIRED')}</p>
            </div>
          }
          Component={() => (
            <div className="f-primary">
              {t('NO_LOCATION')} <Icon name="info" size="xs" className="info-icon" />
            </div>
          )}
          containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
        />
      )}
    </div>
  );

  return (
    <>
      <div className={`measurement-point-question-section ${isExpanded ? 'expanded' : ''}`}>
        <div className="measurement-point-section-top" onClick={toggleExpand}>
          <div className="measurement-point-header">
            <div className="mp-name-col">
              <div className="item">
                <div className="name f-primary">{question?.[formConstants.fields.name] || '-'}</div>
                <p className="f-secondary-dark names" title={`${t(question?.[formConstants.fields.readingsAndGaugesGroupName])}/${question?.[formConstants.fields.measurementLocationName] || ''}`}>
                  <span className="truncated-text">{t(question?.[formConstants.fields.readingsAndGaugesGroupName])}</span>/
                  <span className="truncated-text">{question?.[formConstants.fields.measurementLocationName]}</span>
                </p>
              </div>
            </div>
            <div className="mp-unit-col">
              <div className="item">
                <label>{t('MEASUREMENT_POINT_DETAILS.FORM_UNIT')}</label>
                <span className="f-primary name">{Helpers.truncateText(question?.[formConstants.fields.measurementPointUnit], 10) || '-'}</span>
              </div>
            </div>
            <div className="mp-exp-range-col">
              <div className="item">
                <label>{t('MEASUREMENT_POINT_DETAILS.FORM_EXPECTED_RANGE')}</label>
                <span className="f-primary name" title={question?.[formConstants.fields.expectedRange] || '-'}>
                  {question?.[formConstants.fields.expectedRange] || '-'}
                </span>
              </div>
            </div>
            <div className="toggle-action">
              <Icon name={isExpanded ? 'chevron-up' : 'chevron-down'} size="md" className="component-header-toggle" />
            </div>
          </div>
        </div>

        {isExpanded && (
          <div className="measurement-point-question-section__expanded">
            <div className="measurement-point-question-section__expanded__alarms">{mappedAlarms.map(renderAlarm)}</div>
            <div className="measurement-point-question-section__expanded__location">
              {renderLocationInfo(
                'M_LOCATION_3D_LOCATION',
                'NO_LOCATION.PARAGRAPH.ML',
                question?.[formConstants.fields.measurementLocationGeometry]?.[formConstants.fields.coordinates],
                question?.[formConstants.fields.measurementLocationCameraPosition]?.[formConstants.fields.coordinates],
                question?.[formConstants.fields.measurementLocationName],
                measurementTypes.rgMeasurementLocation,
                question?.[formConstants.fields.measurementLocationColour]
              )}
              {renderLocationInfo(
                'M_POINT_3D_LOCATION',
                'NO_LOCATION.PARAGRAPH.MP',
                question?.[formConstants.fields.measurementPointGeometry]?.[formConstants.fields.coordinates],
                question?.[formConstants.fields.measurementPointCameraPosition]?.[formConstants.fields.coordinates],
                question?.[formConstants.fields.name],
                measurementTypes.rgMeasurementPoint,
                question?.[formConstants.fields.measurementPointColour]
              )}
            </div>
          </div>
        )}
      </div>
      <Modal {...modalData} />
    </>
  );
};

MeasurementPointQuestion.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default MeasurementPointQuestion;
