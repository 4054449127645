import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import EmptyState from '../../../../common/empty-state/components/empty-state';
import HistoryItem from '../../../../common/history-item/components/history-item';
import LoadMore from '../../../../common/load-more/components/load-more';
import ToolbarTitle from '../../../../common/toolbar-title/components/toolbar-title';
import { getHistoryLength } from '../../helpers/inspection-helper';
import RenderIf from '../../../../common/render-if/components/render-if';
import { sortingDirection, historyDefaultPagingObj, historyFilterParams, historyItems, componentModificationEvents } from '../../constants/component-constants';
import { getComponentHistory } from '../../actions/inspection-actions';

import '../../styles/module-item-history.scss';

class ComponentHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      history: [],
      paging: historyDefaultPagingObj,
    };
  }

  fetchHistory = () => {
    this.props.getComponentHistory(
      {
        ItemId: this.props.componentID,
        LastSeen: this.state.paging[historyFilterParams.lastSeen],
        PerPage: this.state.paging[historyFilterParams.perPage],
        SortByColumn: historyFilterParams.sortByColumn,
        SortDirection: sortingDirection.desc,
      },
      this.state.history,
      this.state.paging,
      data => this.setState(data)
    );
  };

  componentDidMount = () => {
    this.fetchHistory();
  };

  render() {
    const historyLength = getHistoryLength(
      this.state.history,
      componentModificationEvents,
      historyItems,
      componentModificationEvents.component_created.event,
      componentModificationEvents.component_deleted.event
    );

    return (
      <div className="module-item-history">
        <ToolbarTitle variant="medium">{'COMPONENT_HISTORY.TITLE'}</ToolbarTitle>
        <RenderIf if={!this.state.isLoading && (!this.state.history.length || !historyLength)}>
          <EmptyState title="COMPONENT_HISTORY.EMPTY_STATE_TITLE" message="COMPONENT_HISTORY.EMPTY_STATE_MESSAGE" style={{ height: '80vh' }} />
        </RenderIf>
        <RenderIf if={!this.state.isLoading && this.state.history.length > 0 && historyLength > 0}>
          <div className="module-item-history__wrapper">
            <div className={this.state.isLoading ? 'background-blurred' : ''}>
              {this.state.history.map(item => (
                <HistoryItem
                  key={item.id}
                  items={historyItems}
                  item={item}
                  moduleName="COMPONENT_HISTORY.MODULE_NAME"
                  events={componentModificationEvents}
                  moduleCreateEvent={componentModificationEvents.component_created.event}
                  moduleFileUnlinkEvent={componentModificationEvents.component_file_unlinked.event}
                  moduleDeleteEvent={componentModificationEvents.component_deleted.event}
                />
              ))}
            </div>
            <LoadMore
              disabled={!this.state.paging[historyFilterParams.hasNext] || this.state.isLoading}
              loaded={this.state.history.length}
              total={this.state.paging[historyFilterParams.totalItems]}
              totalPosition="center"
              isLoading={this.state.isLoading}
              label="COMPONENT_HISTORY.LOAD_MORE"
              isFixed
              onClick={this.fetchHistory}
            />
          </div>
        </RenderIf>
      </div>
    );
  }
}

ComponentHistory.contextTypes = {
  t: PropTypes.func.isRequired,
};

ComponentHistory.propTypes = {
  componentID: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  getComponentHistory: (params, history, paging, callback) => dispatch(getComponentHistory(params, history, paging, callback)),
});

ComponentHistory = connect(mapStateToProps, mapDispatchToProps)(ComponentHistory);

export default ComponentHistory;
