import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { setCollapsibleInfoIsDisplayed, setCollapsibleInfoTitle } from '../../../../common/collapsible-info/actions/action-creators';
import TableTemplate from '../../../../common/double-table/components/table-template';
import RenderIf from '../../../../common/render-if/components/render-if';
import { formatUploadDrawerFileTableCells } from '../../actions/table-formatting';
import { fields, uploadFileTableConfig } from '../../constants/constants';
import '../../styles/dms-upload-drawer.scss';

const DMSUploadDrawer = ({ uploadFiles, uploadBatches, setCollapsibleInfoTitle, setCollapsibleInfoIsDisplayed }, { t }) => {
  const filesToPresent = uploadFiles.map(file => ({ ...file, [fields.progress]: !file[fields.progress] ? 0 : file[fields.progress] }));
  const uploadedItems = uploadFiles.length - uploadFiles.filter(file => !file.progress || file.progress !== 100).length;
  const displayCounter = uploadedItems !== uploadFiles.length;

  // this useEffects purpose is to update the title of the parent component (CollapsibleInfo)
  useEffect(() => {
    if (uploadFiles.length === 0) {
      setCollapsibleInfoIsDisplayed(false);
    } else {
      if (uploadBatches.every(batch => batch.batchCompleted === true)) {
        setCollapsibleInfoTitle(t('DOCUMENT_MANAGEMENT.FILE_UPLOADED_TITLE', { totalItems: uploadFiles.length }));
      } else {
        setCollapsibleInfoTitle(t('DOCUMENT_MANAGEMENT.FILE_UPLOADING_TITLE', { totalItems: uploadFiles.length }));
      }
    }
    //eslint-disable-next-line
  }, [uploadFiles, uploadBatches]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
    //eslint-disable-next-line
  }, []);

  const handleBeforeUnload = e => {
    if (uploadBatches.every(batch => batch.batchCompleted !== true)) {
      e.preventDefault();
      // Reason for returnValue = '' is that any custom message is overridden by the browser
      e.returnValue = '';
    }
  };

  return (
    <div className="dms-upload-drawer">
      <RenderIf if={displayCounter}>
        <div className="dms-upload-drawer__uploaded_info">
          <p className="f-primary">{t('DOCUMENT_MANAGEMENT.FILE_UPLOAD_PROGRESS_TITLE', { totalItems: uploadFiles.length, uploadedItems })}</p>
        </div>
      </RenderIf>
      <div className={`dms-upload-drawer__table-container ${!displayCounter ? 'full-height' : ''}`}>
        <TableTemplate
          data={filesToPresent}
          showSearchBar={false}
          showInfo={false}
          filters={{}}
          tableConfig={uploadFileTableConfig}
          handleFormatCells={formatUploadDrawerFileTableCells}
          customTableClass="dms-upload-drawer__table"
          showHeader={false}
          selectedItem={null}
        />
      </div>
    </div>
  );
};

DMSUploadDrawer.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  setCollapsibleInfoTitle: data => dispatch(setCollapsibleInfoTitle(data)),
  setCollapsibleInfoIsDisplayed: isDisplayed => dispatch(setCollapsibleInfoIsDisplayed(isDisplayed)),
});

const mapStateToProps = state => ({
  uploadFiles: state.uploadBatchesReducer.uploadFiles,
  uploadBatches: state.uploadBatchesReducer.uploadBatches,
});

export default connect(mapStateToProps, mapDispatchToProps)(DMSUploadDrawer);
