import { find, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import Modal from '../../../../../../common/modal/components/modal';
import Tab from '../../../../../../common/tabs/component/tab';
import Tabs from '../../../../../../common/tabs/component/tabs';
import ModuleHeader from '../../common/module-header';
import MeasurementLocationForm from './measurement-location-form';

import { FEATURES, FORMS } from '../../../../../../common/constants';
import { formConstants, tabNames, toolbarItems } from '../constants/measurement-location-constants';

import Loader from '../../../../../../common/global-loader/components/simple-loader';
import Helpers from '../../../../../../common/helpers';

import { change, formValueSelector, isDirty } from 'redux-form';
import ConfirmWithModal from '../../../../../../common/confirm-with-modal/confirm-with-modal';
import { setGenericNotification } from '../../../../../../common/notification/actions/action-creators';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../../../common/permissions-constants';
import ActionModal from '../../../../../document-management/components/modals/action-modal/action-modal';
import { setElementDetails, updateElementDetails } from '../../../../actions/action-creators';
import { getDefectDetails, updateElementGeometry } from '../../../../actions/inspection-actions';
import { defaultComponentName } from '../../../../constants/component-constants';
import { measurementTypes, modules } from '../../../../constants/constants';
import {
  setMeasurementLocationDetailsErrorModal,
  setMeasurementLocationFormState,
  setMeasurementLocationObjectAdding,
  setMeasurementLocationObjectEditing,
  toggleMeasurementLocation,
} from '../../../readings-and-gauges/actions/action-creators';
import {
  changeMeasurementGroup,
  deleteMeasurementLocation,
  getDefaultTimeSeriesSettings,
  getGraphingGroupDetails,
  getMeasurementLocationTimeSeriesGraph,
  linkMeasurementLocationComponent,
  saveGraphingGroup,
  updateMeasurementLocation,
} from '../../../readings-and-gauges/actions/measurement-location-actions';
import ChangeMeasurementGroupModal from '../../../readings-and-gauges/components/change-measurement-group-modal/change-measurement-group-modal';
import { deleteWithModalFields } from '../../../readings-and-gauges/constants/constants';

import { withRouter } from 'react-router';
import routesConstants from '../../../../../../common/routes-constants';
import useConfirmOnInspectionExit from '../../../../../../hooks/use-confirm-on-inspection-exit';
import { generateAndDownloadReport } from '../../../readings-and-gauges/actions/measurement-group-actions';
import { fetchMeasurementPoints } from '../../../readings-and-gauges/actions/measurement-point-actions';
import { DEFAULT_COLOR } from '../../../readings-and-gauges/constants/measurement-location-constants';
import { formConstants as mpFormConstants } from '../../../readings-and-gauges/constants/measurement-point-constants';
import {
  aggregationFields,
  chunkFields,
  formConstants as graphFormConstants,
  settingsFormConstants,
  timePeriodFields,
  timePeriods,
} from '../../../readings-and-gauges/constants/time-series-graph-constants';
import '../styles/measurement-location-details.scss';
import GenerateReportModal from './modals/generate-report-modal';

const MeasurementLocationDetails = (
  {
    queryItem,
    updateMeasurementLocation,
    deleteMeasurementLocation,
    getDefectDetails,
    viewer,
    inspectionDetails,
    measurementLocationDetailsLoading,
    setMeasurementLocationFormState,
    measurementLocationFormState,
    measurementLocationClustered,
    showGeometryWarning = true,
    projectID,
    inspectionID,
    handleActivePage,
    user,
    selectedMeasurementLocation,
    setSelectedMeasurementLocation,
    images360Ref,
    objectToolClick,
    measurementLocationObjectEditing,
    measurementLocationObjectAdding,
    setMeasurementLocationObjectEditing,
    setMeasurementLocationObjectAdding,
    updateGeometry,
    setGenericNotification,
    linkComponent,
    measurementGroupId,
    changeField,
    changeMeasurementGroup,
    getDefaultTimeSeriesSettings,
    getGraphingGroupDetails,
    getMeasurementLocationTimeSeriesGraph,
    saveGraphingGroup,
    router,
    toggleMeasurementLocation,
    isGraphingGroupFormDirty,
    generateAndDownloadReport,
    location,
    errorModalData,
    setMeasurementLocationDetailsErrorModal,
    updateElementDetails,
  },
  context
) => {
  const { t } = context;
  const [confirmModalData, setConfirmModalData] = useState({
    isOpen: false,
  });
  const [modalData, setModalData] = useState({
    isOpen: false,
  });
  const [activeToolbarItem] = useState(toolbarItems[tabNames.details].name);
  const [elementAlreadyFocused, setElementAlreadyFocused] = useState(false);
  const [prevQueryItem, setPrevQueryItem] = useState(null);
  const [prevViewer, setPrevViewer] = useState(null);
  const [componentDetails, setComponentDetails] = useState(null);
  const { query } = location;
  const currentView = useMemo(() => query[routesConstants.queryAlias.view], [query]);

  const resetFormUnsavedChanges = () => {
    setMeasurementLocationFormState({ requestInProgress: false, hasUnsavedChanges: false });
  };

  useConfirmOnInspectionExit({
    title: t('GENERIC_UNSAVED_CHANGES_TITLE'),
    message: t('GENERIC_UNSAVED_CHANGES_MESSAGE'),
    router: router,
    route: router.location,
    isDirty: measurementLocationFormState?.hasUnsavedChanges,
    clearUnsavedChangesDirty: () => resetFormUnsavedChanges(),
  });

  const [graphingGroupDetails, setGraphingGroupDetails] = useState(null);
  const [graphDetailsIsLoading, setGraphDetailsIsLoading] = useState(false);
  const [graphLoading, setGraphLoading] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const timePeriodsList = useMemo(() => timePeriods(t), [t]);

  const submitForm = (values, customCallback = () => null) => {
    const { requestInProgress } = measurementLocationFormState;
    if (requestInProgress) return;
    const { Geometry, CameraPosition, ...valuesToSave } = values;
    let callback = customCallback;

    setMeasurementLocationFormState({ requestInProgress: true });

    updateMeasurementLocation(valuesToSave, () => {
      resetFormUnsavedChanges(); // to disable the Save button
      callback();
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const formChangeDebounce = (_values, _b, c) => {
    setMeasurementLocationFormState({ hasUnsavedChanges: c?.dirty });
  };

  useEffect(() => {
    if (!queryItem || queryItem < 0) {
      setElementAlreadyFocused(true);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryItem]);

  useEffect(() => {
    if (queryItem && queryItem > 0) {
      if (queryItem < 0) {
        setMeasurementLocationObjectEditing(false);
        // Reset measurementLocation state when queryItem is less than 0
        setSelectedMeasurementLocation({});
      } else if (queryItem !== prevQueryItem || viewer !== prevViewer) {
        // Fetch data when queryItem changes or viewer changes
        fetchData(tabNames.details);
      }
    }

    // Update previous queryItem and viewer
    setPrevQueryItem(queryItem);
    setPrevViewer(viewer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryItem, viewer]);

  const fetchMesurementLocationSeriesGraph = (graphParams, successCallback, errorCallback) => {
    getMeasurementLocationTimeSeriesGraph(
      queryItem,
      graphParams,
      graphData => {
        successCallback && typeof successCallback === 'function' && successCallback(graphData || []);
      },
      () => {
        errorCallback && typeof errorCallback === 'function' && errorCallback();
      }
    );
  };

  const fetchGraphData = settingsValues => {
    if (isEmpty(settingsValues)) {
      return;
    }

    const formatedGraphSettings = {
      ...settingsValues,
      [settingsFormConstants.graphingGroup.name]: settingsValues[settingsFormConstants.graphingGroup.name]?.[graphFormConstants.id],
      [settingsFormConstants.aggregation.name]: (settingsValues[settingsFormConstants.aggregation.name] || []).map(el => el[aggregationFields.key]),
      [settingsFormConstants.chunks.name]: settingsValues[settingsFormConstants.chunks.name]?.[chunkFields.key],
    };

    setGraphLoading(true);

    fetchMesurementLocationSeriesGraph(
      formatedGraphSettings,
      graphData => {
        const mergedData = (graphData || []).map(data => ({
          ...data,
          Series: Helpers.mergeSeriesData(data.Series, data[mpFormConstants.fields.scaleFactor] || undefined, data[mpFormConstants.fields.inverted]),
        }));
        setGraphData(mergedData);
        setGraphLoading(false);
      },
      () => {
        setGraphData([]);
        setGraphLoading(false);
      }
    );
  };

  const getDefaultGraphDetails = () => {
    setGraphDetailsIsLoading(true);

    getDefaultTimeSeriesSettings(
      queryItem,
      data => {
        const { graphingGroups, aggregationList, chunks } = data;

        const foundIndex = (graphingGroups || []).findIndex(group => group[graphFormConstants.isDefault]);
        if (foundIndex > -1) {
          const defaultGraphingGroup = graphingGroups[foundIndex];

          getGraphingGroupDetails(
            queryItem,
            defaultGraphingGroup[graphFormConstants.id],
            data => {
              const selectedTimePeriodKey = data?.[graphFormConstants.timePeriod];
              const selectedAggregationKeys = Helpers.parseAggregationsFromString(data?.[graphFormConstants.aggregation]);
              let initialAggregation = [];
              (selectedAggregationKeys || []).forEach(key => {
                initialAggregation.push(Helpers.getObjectByKey(aggregationList, aggregationFields.key, key));
              });

              // Set initial settings form values
              const details = {
                [settingsFormConstants.graphingGroup.name]: data,
                [settingsFormConstants.aggregation.name]: initialAggregation,
                [settingsFormConstants.timePeriod.name]: Helpers.getObjectByKey(timePeriodsList, timePeriodFields.value, selectedTimePeriodKey) || timePeriodsList[timePeriodsList.length - 1],
                [settingsFormConstants.chunks.name]: Helpers.getObjectByKey(chunks, chunkFields.key, data?.[graphFormConstants.chunk]) || chunks[chunks.length - 1],
                [settingsFormConstants.dateFrom.name]: data?.[graphFormConstants.startDateFrom],
                [settingsFormConstants.dateTo.name]: data?.[graphFormConstants.startDateTo],
              };
              fetchGraphData(details, queryItem);
              setGraphingGroupDetails(details);

              setGraphDetailsIsLoading(false);
            },
            () => {
              setGraphDetailsIsLoading(false);
            }
          );
        } else {
          setGraphingGroupDetails(null);
          setGraphData([]);
        }
        setGraphDetailsIsLoading(false);
      },
      () => {
        setGraphDetailsIsLoading(false);
      }
    );
  };

  const fetchComponentDetails = useCallback(
    componentId => {
      getDefectDetails(
        { [formConstants.fields.id]: componentId || inspectionDetails.DefaultComponent, SystemType: measurementTypes.component },
        component => {
          setComponentDetails(component);
        },
        false
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getDefectDetails, inspectionDetails, setComponentDetails, setSelectedMeasurementLocation, componentDetails]
  );

  const fetchData = tabName => {
    if (!queryItem || queryItem < 0) return;
    if (tabName === tabNames.details) {
      setMeasurementLocationObjectEditing(false);
      getDefectDetails(
        { ID: queryItem, SystemType: measurementTypes.rgMeasurementLocation },
        measurementLocationDetails => {
          getDefaultGraphDetails();
          fetchComponentDetails(measurementLocationDetails[formConstants.fields.componentId]);
          if (measurementLocationDetails?.Geometry?.coordinates?.[0] && measurementLocationDetails?.CameraPosition?.coordinates && viewer && !elementAlreadyFocused) {
            // we can use here Geometry and Camera position from measurementLocationDetails object since it is freshly fetched but usually we need to use it from clustered elements, they are always up to date
            viewer.zoomToPosition(
              { x: measurementLocationDetails.CameraPosition.coordinates[0], y: measurementLocationDetails.CameraPosition.coordinates[1], z: measurementLocationDetails.CameraPosition.coordinates[2] },
              measurementLocationDetails.Geometry.coordinates,
              500
            );
            setElementAlreadyFocused(true);
          }
        },
        true
      );
    }
  };

  const toggleConfirmationModal = useCallback((isOpen, title, content, confirmAction, closeButtonText) => {
    if (isOpen) {
      setConfirmModalData({
        isOpen: true,
        type: '',
        CustomContent: dynamicProps => <ActionModal {...dynamicProps} />,
        content: content,
        title: title,
        customClassName: 'modal-medium confirmation-modal',
        customConfirmAction: () => {
          confirmAction();
        },
        confirmButtonText: title,
        ...(closeButtonText ? { closeButtonText } : {}),
        customCloseAction: () => toggleConfirmationModal(false),
      });
    } else {
      setConfirmModalData({ isOpen: false });
    }
  }, []);

  const handleDeleteMeasurementLocation = useCallback(() => {
    const handleDeleteWithConfirmedPassword = confirmationCheck => {
      deleteMeasurementLocation(selectedMeasurementLocation, confirmationCheck, context, () => {
        handleActivePage(modules.readingsAndGauges, null, null, currentView);
        toggleConfirmationModal(false);
      });
    };

    const openPasswordConfirmationModal = () => {
      setConfirmModalData({
        isOpen: true,
        type: '',
        CustomContent: dynamicProps => <ConfirmWithModal {...dynamicProps} />,
        closeAction: () => toggleConfirmationModal(false),
        title: t('MEASUREMENT_LOCATION.MODAL.CONFIRM_DELETE.TITLE'),
        customCloseAction: () => toggleConfirmationModal(false),
        onSubmit: values => handleDeleteWithConfirmedPassword(values[deleteWithModalFields.field.name]),
        text: 'MEASUREMENT_LOCATION.MODAL.CONFIRM_DELETE.PARAGRAPH',
        placeholder: deleteWithModalFields.field.placeholder,
        label: deleteWithModalFields.field.label,
        id: deleteWithModalFields.field.id,
        name: deleteWithModalFields.field.name,
        customClassName: 'modal-medium confirmation-modal',
      });
    };

    toggleConfirmationModal(
      true,
      t('MEASUREMENT_LOCATION.MODAL.DELETE.TITLE'),
      t('MEASUREMENT_LOCATION.MODAL.DELETE.DESC', { mlName: selectedMeasurementLocation[formConstants.fields.name] }),
      openPasswordConfirmationModal
    );
  }, [deleteMeasurementLocation, selectedMeasurementLocation, context, handleActivePage, toggleConfirmationModal, currentView, t]);

  const handleComponentChange = useCallback(
    componentId => {
      if (componentId) {
        linkComponent(componentId, queryItem, () => {
          fetchComponentDetails(componentId);
        });
      } else {
        linkComponent(null, queryItem, () => {
          fetchComponentDetails(null);
          // if no componentId set selectedMeasurementLocationDefect[formConstants.fields.componentId] to null, so Create MP copy from ML works properly
          updateElementDetails({ ...selectedMeasurementLocation, [formConstants.fields.componentId]: null });
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryItem, linkComponent, fetchComponentDetails]
  );

  if (measurementLocationDetailsLoading || !inspectionDetails) {
    return <Loader isLoading={true} />;
  }

  const generateAndDownloadReportHandler = data => {
    generateAndDownloadReport(
      data,
      () => {
        closeModal();
        // success generic notification
        setGenericNotification({ isDisplayed: true, type: 'success', text: t('GENERATE_AND_DOWNLOAD_REPORT.SUCCESS_NOTIFICATION'), icon: 'checkmark-outline', wrapperClassName: 'full-width' });
      },
      () => {
        closeModal();
        // error generic notification
        setGenericNotification({ isDisplayed: true, type: 'error', text: t('GENERATE_AND_DOWNLOAD_REPORT.ERROR_NOTIFICATION'), icon: 'info', wrapperClassName: 'full-width' });
      }
    );
  };

  const handleGenerateReportClick = () => {
    setModalData({
      isOpen: true,
      type: '',
      CustomContent: dynamicProps => <GenerateReportModal handleSubmit={data => generateAndDownloadReportHandler(data)} showDatesOnly={true} {...dynamicProps} />,
      closeAction: closeModal,
      title: t('INSPECTION_DETAILS_DOWNLOAD_REPORT'),
      projectID,
      inspectionId: inspectionID,
      selectedMeasurementLocation,
      customClassName: 'generate-report-modal',
    });
  };

  const menuOptions = [
    {
      title: 'INSPECTION_DETAILS_DOWNLOAD_REPORT',
      action: handleGenerateReportClick,
    },
    {
      title: 'MEASUREMENT_LOCATION_DETAILS.ACTIONS.DELETE_TEXT',
      isHighlighted: true,
      separator: true,
      access: {
        visibleFor: PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].locationDelete.name,
      },
      action: () => handleDeleteMeasurementLocation(),
    },
  ];

  const handleSubmittedForm = values => {
    submitForm(values, false);
  };

  const openChangeMeasurementGroupModal = () => {
    setModalData({
      isOpen: true,
      type: '',
      CustomContent: dynamicProps => <ChangeMeasurementGroupModal measurementLocation={selectedMeasurementLocation} {...dynamicProps} />,
      closeAction: closeModal,
      title: t('READINGS_AND_GAUGES.CHANGE_MEASUREMENT_GROUP'),
      projectID,
    });
  };

  const closeModal = () => setModalData({ isOpen: false });

  const handleChangeMeasurementGroup = (group, callback = () => null) => {
    changeMeasurementGroup(group[formConstants.fields.id], queryItem, { ProjectID: projectID }, () => {
      changeField(formConstants.fields.measurementGroup, group[formConstants.fields.name]);
      changeField(formConstants.fields.measurementGroupId, group[formConstants.fields.id]);
      callback();
    });
  };

  const selectedMLClustered = find(measurementLocationClustered, item => item[formConstants.fields.id] === queryItem);
  const initialValues = selectedMeasurementLocation && { ...selectedMeasurementLocation, [formConstants.fields.color]: selectedMeasurementLocation[formConstants.fields.color] || DEFAULT_COLOR };

  return (
    <div className="measurement-location-details">
      <Tabs defaultTabKey={activeToolbarItem} navigationClassName="component-details__tabs" onChange={fetchData} tabsHeader={<ModuleHeader id={queryItem} menuOptions={menuOptions} />}>
        <Tab title={toolbarItems[tabNames.details].label} tabKey={tabNames.details}>
          <MeasurementLocationForm
            inspectionDetails={inspectionDetails}
            projectID={projectID}
            queryItem={queryItem}
            inspectionID={inspectionID}
            toggleConfirmationModal={toggleConfirmationModal}
            handleActivePage={handleActivePage}
            selectedMeasurementLocation={selectedMeasurementLocation}
            onChange={formChangeDebounce}
            onSubmit={handleSubmittedForm}
            showGeometryWarning={showGeometryWarning}
            formHasUnsavedChanges={measurementLocationFormState?.hasUnsavedChanges || measurementLocationFormState?.requestInProgress}
            requestInProgress={measurementLocationFormState?.requestInProgress}
            locationObject={{
              ...Helpers.getModuleLocationObject({
                ...initialValues,
                Geometry: selectedMLClustered?.Geometry || initialValues?.Geometry,
                CameraPosition: selectedMLClustered?.CameraPosition || initialValues?.CameraPosition,
              }),
              visible: selectedMLClustered?.visible || false,
            }}
            initialValues={initialValues}
            viewer={viewer}
            user={user}
            measurementLocationObjectAdding={measurementLocationObjectAdding}
            measurementLocationObjectEditing={measurementLocationObjectEditing}
            setMeasurementLocationObjectAdding={setMeasurementLocationObjectAdding}
            setMeasurementLocationObjectEditing={setMeasurementLocationObjectEditing}
            images360Ref={images360Ref}
            objectToolClick={objectToolClick}
            updateGeometry={updateGeometry}
            componentDetails={componentDetails}
            defaultComponent={{ [formConstants.fields.name]: defaultComponentName, [formConstants.fields.id]: inspectionDetails?.DefaultComponent }}
            handleComponentChange={handleComponentChange}
            setGenericNotification={setGenericNotification}
            openChangeMeasurementGroupModal={openChangeMeasurementGroupModal}
            fetchData={fetchData}
            saveGraphingGroup={saveGraphingGroup}
            toggleMeasurementLocation={toggleMeasurementLocation}
            isGraphingGroupFormDirty={isGraphingGroupFormDirty}
            graphingGroupDetails={graphingGroupDetails}
            graphDetailsIsLoading={graphDetailsIsLoading}
            graphLoading={graphLoading}
            graphData={graphData}
            timePeriodsList={timePeriodsList}
            refetchGraphDetails={getDefaultGraphDetails}
            errorModalData={errorModalData}
            setMeasurementLocationDetailsErrorModal={setMeasurementLocationDetailsErrorModal}
          />
        </Tab>
        {FEATURES.modulesHistory?.visible && (
          <Tab title={toolbarItems[tabNames.history].label} tabKey={tabNames.history} visible={false}>
            {/* TODO: Implement history tab */}
          </Tab>
        )}
      </Tabs>
      <Modal {...modalData} activeGroupId={measurementGroupId} handleChangeMeasurementGroup={handleChangeMeasurementGroup} />
      <Modal {...confirmModalData} />
    </div>
  );
};

const selector = formValueSelector(FORMS.measurementLocationForm);

const mapStateToProps = state => ({
  selectedMeasurementLocation: state.inspectionReducer.selectedDefect,
  measurementLocationDetailsLoading: state.measurementLocationReducer.measurementLocationDetailsLoading,
  measurementLocationFormState: state.measurementLocationReducer.measurementLocationFormState,
  measurementLocationClustered: state.measurementLocationReducer.measurementLocationsClustered,
  measurementLocationObjectEditing: state.measurementLocationReducer.measurementLocationObjectEditing,
  measurementLocationObjectAdding: state.measurementLocationReducer.measurementLocationObjectAdding,
  inspectionDetails: state.inspectionReducer.inspectionDetails,
  user: state.userReducer,
  images360Ref: state.inspectionReducer.images360Ref,
  measurementGroupId: selector(state, formConstants.fields.measurementGroupId),
  isGraphingGroupFormDirty: isDirty(FORMS.createGraphingGroupForm)(state),
  errorModalData: state.measurementLocationReducer.measurementLocationDetailsErrorModal,
});

const mapDispatchToProps = dispatch => ({
  updateMeasurementLocation: (measurementLocation, callback) => dispatch(updateMeasurementLocation(measurementLocation, callback)),
  getDefectDetails: (defect, callback, saveItem) => dispatch(getDefectDetails(defect, callback, {}, true, saveItem)),
  deleteMeasurementLocation: (data, confirmationCheck, context, successCallback, errorCallback) =>
    dispatch(deleteMeasurementLocation(data, confirmationCheck, context, successCallback, errorCallback)),
  setMeasurementLocationFormState: data => dispatch(setMeasurementLocationFormState(data)),
  setMeasurementLocationObjectEditing: val => dispatch(setMeasurementLocationObjectEditing(val)),
  setMeasurementLocationObjectAdding: val => dispatch(setMeasurementLocationObjectAdding(val)),
  setSelectedMeasurementLocation: measurementLocation => dispatch(setElementDetails({ defect: measurementLocation })),
  updateGeometry: data => dispatch(updateElementGeometry(data)),
  setGenericNotification: data => dispatch(setGenericNotification(data)),
  linkComponent: (componentId, measurementLocationId, successCallback, errorCallback) => dispatch(linkMeasurementLocationComponent(componentId, measurementLocationId, successCallback, errorCallback)),
  changeMeasurementGroup: (measurementGroupId, measurementLocationId, additionalData, successCallback, errorCallback) =>
    dispatch(changeMeasurementGroup(measurementGroupId, measurementLocationId, additionalData, successCallback, errorCallback)),
  changeField: (fieldName, value) => dispatch(change(FORMS.measurementLocationForm, fieldName, value)),
  fetchMeasurementPoints: (filters, loadMore, successCallback, errorCallback) => dispatch(fetchMeasurementPoints(filters, loadMore, successCallback, errorCallback)),
  getDefaultTimeSeriesSettings: (measurementLocationId, successCallback, errorCallback) => dispatch(getDefaultTimeSeriesSettings(measurementLocationId, successCallback, errorCallback)),
  getGraphingGroupDetails: (measurementLocationId, graphingGroupId, successCallback, errorCallback) =>
    dispatch(getGraphingGroupDetails(measurementLocationId, graphingGroupId, successCallback, errorCallback)),
  getMeasurementLocationTimeSeriesGraph: (measurementLocationId, graphSettings, successCallback, errorCallback) =>
    dispatch(getMeasurementLocationTimeSeriesGraph(measurementLocationId, graphSettings, successCallback, errorCallback)),
  saveGraphingGroup: (data, successCallback, errorCallback) => dispatch(saveGraphingGroup(data, successCallback, errorCallback)),
  toggleMeasurementLocation: id => dispatch(toggleMeasurementLocation(id)),
  generateAndDownloadReport: (data, callback, errorCallback, loadingCallback) => dispatch(generateAndDownloadReport(data, callback, errorCallback, loadingCallback)),
  setMeasurementLocationDetailsErrorModal: data => dispatch(setMeasurementLocationDetailsErrorModal(data)),
  updateElementDetails: details => dispatch(updateElementDetails(details)),
});

MeasurementLocationDetails.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MeasurementLocationDetails));
