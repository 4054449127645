import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';

import InspectionView from '../../../app/inspections/potree/components/main-view';
import AddButton from '../../add-button/components/add-button';
import Icon from '../../icon/components/icon';
import PointImagesList from '../../../app/inspections/components/right-toolbar/point-images-list';
import ImageSlider from '../../../app/inspections/components/right-toolbar/image-slider';
import InspectionRenderer from '../../../app/inspections/components/left-toolbar/inspection-renderer';

import { getInspectionDetails } from '../../../app/inspections/actions/inspection-actions';

import constants from '../constants/constants';
import ScreenshotHelpers from '../../screenshoot-helpers';
import Helpers from '../../helpers';
import { AMAZON_IMAGE_SIZES } from '../../constants';
import { additionalFields } from '../../question-components/constants/question-constants';

import '../styles/screenshot-picker.scss';

class ScreenshotPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewerInstance: null,
      imageType: AMAZON_IMAGE_SIZES.medium.name,
      activeItemIndex: props.defaultActiveSlideIndex || 0,
    };
    this.parentRef = React.createRef();
  }

  componentDidMount() {
    const { getInspectionDetails, inspectionId } = this.props;

    getInspectionDetails(inspectionId);
  }

  createViewerInstance = newInstance => {
    this.setState({ viewerInstance: newInstance });
  };

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  getInspectionScreenshot = (querySelector, onFinish) => {
    const { onDropAsset, question } = this.props;
    ScreenshotHelpers.takeScreenshot(
      querySelector,
      base64img => {
        const file = this.dataURLtoFile(base64img, `screenshot_${Date.now()}.png`);
        onDropAsset([file], question.ID, 'SCREENSHOT');
      },
      onFinish
    );
  };
  openFullScreen = (item, index) => {
    const el = document.querySelectorAll('.screenshot-picker .image-gallery-fullscreen-button');
    //click on full screen icon
    //click on full screen will also slide the slider to the clicked element
    this.setActiveSlide(index);
    if (el && el[0]) {
      el[0].click();
    }
  };

  setImageSliderRef = ref => {
    this._imageGallery = ref;
  };

  setActiveSlide = index => {
    this.setState({ activeItemIndex: index });
  };

  getScreenshots = () => {
    const { question, questionScreenshotFiles } = this.props;

    if (questionScreenshotFiles) {
      return get(question.ID, questionScreenshotFiles);
    }

    return Helpers.mapExternalFilesForModal(question?.QuestionsScreenshots);
  };

  render() {
    const { t } = this.context;
    const { viewOnly, question } = this.props;
    const { viewerInstance, imageType, activeItemIndex } = this.state;

    const screenshotFiles = this.getScreenshots();
    return (
      <div className="screenshot-picker">
        <InspectionView
          parentRef={this.parentRef}
          isWorkflow={false}
          potreeId={constants.potreeId}
          createViewerInstance={this.createViewerInstance}
          viewer={viewerInstance}
          showCameras={false}
          showScreenToolbox
        />
        <InspectionRenderer
          deselectAll={() => null}
          selectAll={() => null}
          toggleElement={() => null}
          deselectAllTemp={() => null}
          selectAllTemp={() => null}
          toggleElementTemp={() => null}
          updateElementGeometry={() => null}
          selectElement={() => null}
          elements={[
            { ...question, visible: true },
            ...(question?.[additionalFields.components.name] ? Helpers.formatComponents(question?.[additionalFields.components.name].map(component => ({ ...component, visible: true }))) : []),
          ]}
          selectedDefect={null}
          viewer={viewerInstance}
          disableMove={true}
        />
        <div className="sidebar">
          <h5 className="f-primary">{t('SCREENSHOT_PICKER_3D.TITLE')}</h5>
          <AddButton disabled={viewOnly} className="bottom-spacing" onClick={() => this.getInspectionScreenshot(`#${constants.potreeId}`, () => null)}>
            {'ADDITIONAL_FIELDS.TAKE_SCREENSHOT_FROM_3D'}
          </AddButton>
          <div className="point-images__slider padding-horizontal">
            <ImageSlider
              images={screenshotFiles}
              currentSlideIndex={activeItemIndex}
              onSlide={currentIndex => this.setActiveSlide(currentIndex)}
              imageType={imageType}
              setImageSliderRef={this.setImageSliderRef}
              openInspectionAssetModal={() => null}
            />
          </div>

          <div className="point-images__section">
            <PointImagesList
              CustomComponent={(item, index) => <Icon name="newTab" size="xs" className="new-tab" onClick={() => this.openFullScreen(item, index)} />}
              items={screenshotFiles}
              currentSlideIndex={activeItemIndex}
              handleItemClick={(item, index) => this.setActiveSlide(index)}
              imageType={AMAZON_IMAGE_SIZES.small.name}
              cols={2}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  getInspectionDetails: inspectionId => dispatch(getInspectionDetails(inspectionId)),
});

ScreenshotPicker.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreenshotPicker);
