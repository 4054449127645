import React from 'react';
import PropTypes from 'prop-types';

import constants from '../constants/inspection-workflow-constants';
import LegendSquare from './legend-square';

import '../styles/legend.scss';

const Legend = (props, context) => {
  const renderLegend = () => {
    const { t } = context;
    const { label } = props;
    return Object.keys(constants.severity).map((key, index) => {
      return (
        <div className="icon-item" key={index}>
          {index === 0 && label && <p className="f-primary"> {label}</p>}
          <LegendSquare className={constants.severity[key].color} title={t(constants.severity[key].label)} />
        </div>
      );
    });
  };

  return <div className="icon-legend">{renderLegend()}</div>;
};
Legend.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default Legend;
