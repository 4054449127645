import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../icon/components/icon';

import '../styles/empty-state.scss';

const EmptyState = ({ icon = 'pipes', title = 'EMPTY_STATE.TITLE', message = 'EMPTY_STATE.MESSAGE', titleSize = 'medium', messageSize = 'medium', ...rest }, { t }) => (
  <div className="empty-state" {...rest}>
    <div className="empty-state__icon-wrapper">
      <Icon name={icon} className="empty-state__icon-wrapper--icon" />
    </div>
    <p className={`f-primary bold empty-state__title ${`empty-state__title-${titleSize}`}`}>{t(title)}</p>
    <p className={`f-primary empty-state__message ${`empty-state__message-${messageSize}`}`}>{t(message)}</p>
  </div>
);

EmptyState.contextTypes = {
  t: PropTypes.func.isRequired,
};

EmptyState.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  titleSize: PropTypes.oneOf(['small', 'medium', 'large']),
  messageSize: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default EmptyState;
