import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../../common/form/components/button';

const SectionEmptyState = ({ title = 'MODULES_SECTIONS.EMPTY_STATE_DEFAULT_TITLE', actionButtonText, handleActionButtonClick }, { t }) => (
  <div className="section-empty-state">
    <p className="f-secondary-dark">{t(title)}</p>
    {actionButtonText && typeof handleActionButtonClick === 'function' && <Button variant="success" text={t(actionButtonText)} onClick={handleActionButtonClick} />}
  </div>
);

SectionEmptyState.contextTypes = {
  t: PropTypes.func.isRequired,
};

SectionEmptyState.propTypes = {
  title: PropTypes.string,
  actionButtonText: PropTypes.string,
  handleActionButtonClick: PropTypes.func,
};

export default SectionEmptyState;
