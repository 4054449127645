import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { debounce, chain, each } from 'lodash';

import TableComponent from '../../../../common/table/components/table-component';
import SearchInput from '../../../../common/input/components/search-input';
import Button from '../../../../common/form/components/button';
import Modal from '../../../../common/modal/components/modal';
import CreateTeam from './create-team-popup';
import AddMemebrs from './add-members-popup';
import DropdownMenu from './table-dropdown';
import Icon from '../../../../common/icon/components/icon';
import InfoTooltip from '../../../../common/tooltip/components/info-tooltip';

import Helpers from '../../../../common/helpers';

import { getTeamsData, createTeam, updateTeam, deleteTeam, addTeamUser, deleteTeamUser } from '../../actions/teams-data-actions';
import { setTeamsData, setUsersData } from '../../actions/action-creators';
import { getUsers } from '../../actions/users-data-actions';

import { teamsTableHeader, defaultTeamsFilter, sortDirection, filterProps, teamFields, userSearchParams, userFields } from '../../constants/team-constants';
import AccessRenderer from '../../../../common/access-renderer/components/access-renderer';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../common/permissions-constants';

class Teams extends Component {
  constructor(props) {
    super(props);

    this.searchChangeDebounced = debounce(this.search, 500);
    this.state = {
      filter: {
        ...defaultTeamsFilter,
      },
      modalData: {
        isOpen: false,
      },
      selectedIndex: -1,
    };
  }
  componentDidMount = () => {
    const { filter } = this.state;
    const { getTeamsData } = this.props;
    getTeamsData(filter, HasNext => {
      this.setState({ filter: { ...filter, HasNext } });
    });
  };

  componentWillUnmount() {
    const { resetTeamsData, resetUsersData } = this.props;
    this.searchChangeDebounced.cancel();
    resetTeamsData();
    resetUsersData();
  }

  handleInputChange = e => {
    this.searchChangeDebounced(e.target.value);
  };

  search = SearchText => {
    const { filter } = this.state;
    const { getTeamsData } = this.props;

    const newParams = { ...filter, SearchText, [filterProps.lastSeen]: 0 };

    getTeamsData(newParams, HasNext => {
      this.setState({ filter: { ...newParams, HasNext } });
    });
  };

  onColumnSort = SortByColumn => {
    const { filter } = this.state;
    const { getTeamsData } = this.props;
    const newParams = {
      ...filter,
      [filterProps.sortByColumn]: SortByColumn,
      [filterProps.sortByDirection]: filter[filterProps.sortByDirection] === sortDirection.asc ? sortDirection.desc : sortDirection.asc,
      [filterProps.lastSeen]: 0,
    };

    getTeamsData(newParams, HasNext => {
      this.setState({ filter: { ...newParams, HasNext } });
    });
  };

  loadMore = () => {
    const { filter } = this.state;
    const { getTeamsData, teamsTableData } = this.props;
    const newParams = { ...filter, [filterProps.lastSeen]: filter[filterProps.lastSeen] + filter[filterProps.perPage] };

    getTeamsData(
      newParams,
      HasNext => {
        this.setState({ filter: { ...newParams, HasNext } });
        Helpers.scrollIntoView('members-container', `row-${teamsTableData.length - 1}`);
      },
      true
    );
  };

  closeModal = () =>
    this.setState({
      modalData: {
        isOpen: false,
      },
    });

  openCreateTeam = () => {
    const { t } = this.context;
    const { createTeam } = this.props;
    this.setState({
      modalData: {
        isOpen: true,
        title: t('TEAMS_CREATE_POPUP.TITLE'),
        CustomContent: () => <CreateTeam onSubmit={params => createTeam(params, this.closeModal)} />,
        type: 'none',
        closeAction: () => this.closeModal(),
      },
    });
  };

  openEditTeam = team => {
    const { t } = this.context;
    const { editTeam } = this.props;
    this.setState({
      modalData: {
        isOpen: true,
        title: t('TEAMS_EDIT_POPUP.TITLE'),
        CustomContent: () => <CreateTeam onSubmit={params => editTeam(params, this.closeModal)} initialValues={team} submitButtonText={'EDIT_TEAM_POPUP.SUBMIT'} />,
        type: 'none',
        closeAction: () => this.closeModal(),
      },
    });
  };

  openAddMembers = (selTeam, selectedIndex) => {
    const { t } = this.context;
    const { addTeamUser, deleteTeamUser, getUsers } = this.props;

    this.setState({ selectedIndex });

    this.setState({
      modalData: {
        isOpen: true,
        title: t('ADD_MEMBERS_POPUP.TITLE'),
        CustomContent: incomingProps => (
          <AddMemebrs
            {...incomingProps}
            addMember={(team, user, callback) => addTeamUser(team, user, callback)}
            removeMember={(team, user, callback) => deleteTeamUser(team, user, callback)}
            closeAction={this.closeModal}
            searchUsers={SearchText => getUsers({ ...userSearchParams, SearchText })}
            // submitButtonText={'EDIT_TEAM_POPUP.SUBMIT'}
          />
        ),
        type: 'none',
        closeAction: () => {
          this.closeModal();
          this.setState({ selectedIndex: -1 });
        },
      },
    });
  };

  openDeleteConfirmationModal = team => {
    const { t } = this.context;
    const { deleteTeam } = this.props;
    this.setState({
      modalData: {
        isOpen: true,
        title: t('TEAMS_DELETE_POPUP.TITLE'),
        content: t('TEAMS_DELETE_POPUP.DESCRIPTION', { team: team[teamFields.name] }),
        type: 'yes-no',
        closeAction: () => this.closeModal(),
        confirmAction: () => deleteTeam(team, this.closeModal),
      },
    });
  };

  render() {
    const { t } = this.context;
    const { filter, modalData, selectedIndex } = this.state;
    const { teamsTableData, teamsLoading, users } = this.props;

    const usersFiltered = chain(users || [])
      .map(item => ({
        [userFields.email]: item[userFields.userEmail],
        [userFields.id]: item[userFields.userId],
        [userFields.name]: item[userFields.userName],
      }))
      .value();

    const selectedTeam = teamsTableData?.[selectedIndex] || {};

    return (
      <div className="members-container">
        <div className="search-combo">
          <SearchInput onChange={this.handleInputChange} placeholder={t('PROJECT.SEARCH.PLACEHOLDER')} />
          <AccessRenderer visibleFor={[PERMISSIONS[PERMISSION_TYPES.people].create.name]}>
            {({ hasAccess }) => (hasAccess ? <Button type="button" text={t('TEAMS_TABLE.ADD_TEAM')} onClick={this.openCreateTeam} /> : null)}
          </AccessRenderer>
        </div>
        <TableComponent
          tableConfig={[
            ...teamsTableHeader,
            {
              title: 'TEAM_TABLE.ACTIONS',
              CustomComponent: (data, itemIndex) => (
                <DropdownMenu data={data} itemIndex={itemIndex} openEditTeam={this.openEditTeam} openDeleteTeam={this.openDeleteConfirmationModal} openAddMembers={this.openAddMembers} />
              ),
              enableSort: false,
            },
          ]}
          data={teamsTableData || []}
          tableCustomClass="users-table"
          onRowClick={this.onRowClick}
          onColumnSort={this.onColumnSort}
          sortingObj={filter}
          translationModule={t}
          formatCell={(value, type, index, item) => {
            if (type === teamFields.users) {
              const users = teamsTableData[index][teamFields.users] || [];
              let actionsMenu = {};
              if (users.length > 0) {
                each(users, (item, index) => {
                  actionsMenu = { ...actionsMenu, [`${index + 1}`]: item[userFields.name] };
                });
              } else {
                actionsMenu = t('TEAM_TABLE.USERS_TOOLTIP_EMPTY');
              }

              return (
                <div className="info-box">
                  <p className="f-primary">{users.length || 0}</p>
                  <InfoTooltip
                    actionsMenu={actionsMenu}
                    offsetY={10}
                    offsetX={15}
                    Component={() => <Icon name="info" handleHover={false} size="xs" />}
                    containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
                  />
                </div>
              );
            } else {
              return Helpers.formatCell(value, type, index, item);
            }
          }}
          sortDirectionProp={filterProps.sortByDirection}
          stickyHeader={true}
        />
        <Button
          disabled={!filter[filterProps.hasNext] || teamsLoading}
          text={t('LOAD_MORE')}
          variant="success-outline"
          height="md"
          className="load-more"
          width="sm"
          onClick={this.loadMore}
          type="button"
        />
        <Modal {...modalData} users={usersFiltered} selectedTeam={selectedTeam} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  teamsTableData: state.teamsTableReducer.teamsData,
  teamsLoading: state.teamsTableReducer.teamsLoading,
  users: state.usersTableReducer.usersTableData,
});

const mapDispatchToProps = dispatch => ({
  getTeamsData: (params, callback, loadMore) => dispatch(getTeamsData(params, callback, loadMore)),
  getUsers: params => dispatch(getUsers(params)),

  resetTeamsData: () => dispatch(setTeamsData([])),
  resetUsersData: () => dispatch(setUsersData([])),

  createTeam: (props, callback) => dispatch(createTeam(props, callback)),
  editTeam: (props, callback) => dispatch(updateTeam(props, callback)),
  deleteTeam: (props, callback) => dispatch(deleteTeam(props, callback)),

  addTeamUser: (team, user, callback) => dispatch(addTeamUser(team, user, callback)),
  deleteTeamUser: (team, user, callback) => dispatch(deleteTeamUser(team, user, callback)),
});

Teams.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Teams);
