import { fileGroups, statuses } from './regime-constants';

import { FEATURES } from '../../../common/constants';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../common/permissions-constants';
import WorkOrderSectionCPTemplates from '../../inspections/components/work-order/work-order-section-cp-templates';
import MaintenanceRegimeSectionComponents from '../components/maintenance-regime/maintenance-regime-section-components';
import MaintenanceRegimeSectionFiles from '../components/maintenance-regime/maintenance-regime-section-files';
import MaintenanceRegimeSectionScheduler from '../components/maintenance-regime/maintenance-regime-section-scheduler';

export const sections = {
  Files: {
    ID: 1,
    title: 'MAINTENANCE_REGIME.FILES_SECTION',
    component: MaintenanceRegimeSectionFiles,
    translatableTitle: true,
    Locked: true,
    sectionAction: 'openRegimeEditModal',
    fileGroup: fileGroups.otherFiles,
    disabledForStatuses: [statuses.live.value],
  },
  Components: {
    ID: 3,
    title: 'MAINTENANCE_REGIME.COMPONENTS_SECTION',
    component: MaintenanceRegimeSectionComponents,
    translatableTitle: true,
    Locked: true,
    sectionAction: 'openRegimeEditModal',
    disabledForStatuses: [statuses.live.value],
  },
  ChecklistsAndProcedures: {
    ID: 4,
    title: 'MAINTENANCE_REGIME.CHECKLISTS_SECTION',
    component: WorkOrderSectionCPTemplates,
    translatableTitle: true,
    Locked: true,
    sectionAction: 'openCPTemplatesModal',
    disabledForStatuses: [statuses.live.value],
    showError: false,
    isHidden: !FEATURES.checklistsAndProcedures.visible,
    editPermission: PERMISSIONS[PERMISSION_TYPES.maintenanceRegimes].regimeCPLink.name,
    ignoreStatusEditPermission: true,
  },
  Scheduler: {
    ID: 5,
    title: 'MAINTENANCE_REGIME.SCHEDULER_SECTION',
    component: MaintenanceRegimeSectionScheduler,
    translatableTitle: true,
    Locked: true,
    sectionAction: 'openRegimeEditModal',
    disabledForStatuses: [statuses.live.value],
  },
};
