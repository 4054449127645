import React, { Component } from 'react';

import { ReactComponent as UploadSvg } from '../assets/uploading-archive.svg';

import '../styles/upload-button.scss';

export default class UploadButton extends Component {
  render() {
    const { onClick, text } = this.props;
    return (
      <div onClick={onClick} className="upload-button">
        <UploadSvg className="svg-primary-g upload-button__icon" />
        <p className="f-secondary-green upload-button__text">{text}</p>
      </div>
    );
  }
}
