import passwordConstants from '../constants/password-constants';
import { PASSWORD_REGEX } from '../../../common/constants';

export const validate = values => {
  const errors = {};
  if (!values[passwordConstants.fields.password] || !PASSWORD_REGEX.test(values[passwordConstants.fields.password])) {
    errors[passwordConstants.fields.password] = 'LOGIN.PASSWORD_NOT_VALID';
  }

  return errors;
};
