import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { isEmpty } from 'lodash';

import { AMAZON_IMAGE_SIZES } from '../../../../common/constants';

import { getFilesExternalUploaded, saveExternalFiles } from '../../../upload/actions/external-upload-actions';

import ExternalUploadForm from './external-upload-form';

import '../../styles/external-upload.scss';

class ExternalUploadModal extends Component {
  renderPlaceholder = () => {
    const { t } = this.context;
    return <p>{t('EXTERNAL_UPLOAD.NO_FILES')}</p>;
  };

  componentDidMount = () => {
    const { getFilesExternalUploaded, projectID, defect } = this.props;
    if (!isEmpty(defect) && defect.ID) {
      getFilesExternalUploaded(projectID, defect);
    }
  };

  handleSubmit = vals => {
    const { externalFiles, saveExternalFiles, defect, closeAction, uploadGroup, isValid } = this.props;
    /*eslint array-callback-return:*/
    const files = Object.keys(vals).map(key => {
      if (vals[key]) {
        const index = parseFloat(key.split('_')[1]);
        return externalFiles[index].FileID.toString();
      }
    });

    if (isValid(files)) {
      if (!isEmpty(defect) && defect.ID) {
        saveExternalFiles(defect, files, uploadGroup, closeAction);
      }
    }
    //do upload
  };

  render() {
    const { t } = this.context;
    const { externalFiles } = this.props;

    return (
      <div className="external-upload noselect">
        <div className="title">
          <h5>{t('INSPECTION_UPLOAD_MODAL_EXTERNAL.TITLE')}</h5>
        </div>
        {isEmpty(externalFiles) ? this.renderPlaceholder() : <ExternalUploadForm fields={externalFiles} onSubmit={this.handleSubmit} imageType={AMAZON_IMAGE_SIZES.small.name} />}
      </div>
    );
  }
}
ExternalUploadModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

ExternalUploadModal.defaultProps = {
  isValid: () => true,
};
const mapStateToProps = state => ({
  externalFiles: state.uploadReducer.externalUploadedFiles,
  projectID: state.inspectionReducer.projectID,
  inspectionID: state.inspectionReducer.inspectionID,
});
const mapDispatchToProps = dispatch => ({
  getFilesExternalUploaded: (projectID, defect) => dispatch(getFilesExternalUploaded(projectID, defect)),
  saveExternalFiles: (defect, files, uploadGroup, callback) => dispatch(saveExternalFiles(defect, files, uploadGroup, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExternalUploadModal);
