import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { find } from 'lodash';

import CreateProjectForm from './create-project-form';
import GoogleMap from './map';

import { editProject, getContactList } from '../actions/create-project-actions';
import { setProjectToEdit } from '../../project/actions/project-actions';

import constants from '../constants/constants';
import Helpers from '../../../common/helpers';
import routesConstants from '../../../common/routes-constants';

import '../styles/create-project-form.scss';

class EditProject extends Component {
  componentDidMount = () => {
    const { getContactList, projectToEdit } = this.props;

    if (!projectToEdit) {
      Helpers.goTo(routesConstants.routes.protectedRoutes.project.fullPath);
    }
    getContactList(projectToEdit.CompanyID);
  };

  renderAddress = props => {
    const { t } = this.context;
    return (
      <Fragment>
        <label className="f-secondary-light address-label">{t(props.label)}</label>
        <GoogleMap fieldProps={props} center={{ lat: props.input.value.Latitude, lng: props.input.value.Longitude }} />
      </Fragment>
    );
  };

  handleFormKeyPress = e => {
    if (e.key === 'Enter') e.preventDefault();
  };

  handleSubmit = values => {
    const { editProject } = this.props;
    let vals = {};

    vals[constants.editFields.selectedContact] = values[constants.editFields.selectedContact].id;
    vals[constants.editFields.address] = values[constants.editFields.address].location
      ? {
          Address: values[constants.editFields.address].addressName,
          Latitude: values[constants.editFields.address].location.lat,
          Longitude: values[constants.editFields.address].location.lng,
        }
      : {
          Address: values[constants.editFields.address].Address,
          Latitude: values[constants.editFields.address].Latitude,
          Longitude: values[constants.editFields.address].Longitude,
        };
    editProject({ ...values, ...vals });
  };

  onCancel = () => {
    const { setProjectToEdit } = this.props;
    setProjectToEdit(null);
    Helpers.goTo(routesConstants.routes.protectedRoutes.project.fullPath);
  };

  render() {
    const { contacts, initialValues } = this.props;
    return (
      <div className="create-project-container">
        <CreateProjectForm
          onSubmit={this.handleSubmit}
          onCancel={this.onCancel}
          initialValues={initialValues}
          contacts={contacts}
          handleFormKeyPress={this.handleFormKeyPress}
          renderAddress={this.renderAddress}
          isEdit={true}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { contactList } = state.createProjectReducer;
  const { projectToEdit } = state.projectEditReducer;

  let initialValues = {};

  const selectedContact = contactList && find(contactList, contact => contact.ID === projectToEdit.ContactPerson.ID);

  if (projectToEdit)
    initialValues = {
      [constants.editFields.projectId]: projectToEdit.ID,
      [constants.editFields.projectName]: projectToEdit.Name,
      [constants.editFields.selectedContact]: selectedContact ? { id: selectedContact.ID, label: selectedContact.Name, value: selectedContact.ID } : {},
      [constants.editFields.address]: projectToEdit.Location,
    };
  return {
    contacts:
      contactList.map(contact => ({
        id: contact.ID,
        value: contact.ID,
        Name: contact.Name,
        CompanyName: contact.CompanyName,
      })) || [],
    initialValues: { ...initialValues },
    projectToEdit,
  };
};

const mapDispatchToProps = dispatch => ({
  editProject: values => dispatch(editProject(values)),
  getContactList: compId => dispatch(getContactList(compId)),
  setProjectToEdit: value => dispatch(setProjectToEdit(value)),
});

EditProject.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProject);
