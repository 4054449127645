import routesConstants from './routes';
import { Api } from '../../core/api';
import { API_BASE_URL } from '../../common/constants';
import { generateQueryParams } from '../helpers/helper';

const getPermits = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_PERMITS}`, data);
};

const getPermitDetails = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_PERMIT_DETAILS}${generateQueryParams(params)}`);
};

const createPermit = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CREATE_PERMIT}`, data);
};

const updatePermit = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_PERMIT}`, data);
};

const updatePermitDates = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_PERMIT_DATES}`, data);
};

const getPermitIsolationCertificates = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_PERMIT_ISOLATION_CERTIFICATES}`, data);
};

const linkIsolationCertificate = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.LINK_ISOLATION_CERTIFICATE}`, data);
};

const removeIsolationCertificate = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.REMOVE_ISOLATION_CERTIFICATE}`, data);
};

const getPermitKeyboxes = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_PERMIT_KEYBOXES}`, data);
};

const linkKeybox = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.LINK_KEYBOX}`, data);
};

const removeKeybox = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.REMOVE_KEYBOX}`, data);
};

const changePermitStatus = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CHANGE_PERMIT_STATUS}`, data);
};

const changePermitStatusToIssue = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CHANGE_PERMIT_STATUS_TO_ISSUE}`, data);
};

const changePermitStatusToSurrender = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CHANGE_PERMIT_STATUS_TO_SURRENDER}`, data);
};

const changePermitStatusToReIssue = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CHANGE_PERMIT_STATUS_TO_REISSUE}`, data);
};

const changePermitStatusToHandback = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CHANGE_PERMIT_STATUS_TO_HANDBACK}`, data);
};

const getHandbackStatusData = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_HANDBACK_STATUS_DATA}`, data);
};

const archivePermit = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ARCHIVE_PERMIT}`, data);
};

const deletePermit = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_PERMIT}`, data);
};

const generatePDF = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GENERATE_PDF}${generateQueryParams(params)}`, { responseType: 'blob' });
};

const getStatusHistory = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_STATUS_HISTORY}`, data);
};

const answerOnQuestion = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ANSWER_ON_QUESTION}`, data);
};

const addComponent = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_COMPONENT}`, data);
};

const deleteComponent = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_COMPONENT}`, data);
};

const deletePermitFile = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_PERMIT_FILE}`, data);
};

const getPermitContractorsList = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_PERMIT_CONTRACTORS_LIST}`, data);
};

const getPermitStatusFlows = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_PERMIT_STATUS_FLOWS}${generateQueryParams(params)}`);
};

export const fetchPermitComments = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.FETCH_PERMIT_COMMENTS}${generateQueryParams(params)}`);
};

export const addPermitComment = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_PERMIT_COMMENT}`, params);
};

export const deletePermitComment = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_PERMIT_COMMENT}`, params);
};

export const updatePermitGeometry = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_PERMIT_GEOMETRY}`, data);
};

export default {
  getPermits,
  getPermitDetails,
  createPermit,
  updatePermit,
  updatePermitDates,
  getPermitIsolationCertificates,
  linkIsolationCertificate,
  removeIsolationCertificate,
  getPermitKeyboxes,
  linkKeybox,
  removeKeybox,
  changePermitStatus,
  changePermitStatusToIssue,
  changePermitStatusToSurrender,
  changePermitStatusToReIssue,
  changePermitStatusToHandback,
  getHandbackStatusData,
  archivePermit,
  deletePermit,
  generatePDF,
  getStatusHistory,
  answerOnQuestion,
  addComponent,
  deleteComponent,
  deletePermitFile,
  getPermitContractorsList,
  getPermitStatusFlows,
  fetchPermitComments,
  addPermitComment,
  deletePermitComment,
};
