import { setWorkflowImages, activateWorkflowImage } from './action-creators';
import { handleGlobalLoader } from '../../../common/global-loader/actions/loader-actions';

import workflowActions from '../../../api/inspection-workflow/actions';

import workflowConstants from '../constants/inspection-workflow-constants';
import loaderConstants from '../../../common/global-loader/constants/loader-constants';

import { map, find, isEmpty } from 'lodash';

import Helpers from '../../../common/helpers';

export const fetchWorkflowImages = async (InspectionID, SelectionType, additionalProps = {}) => {
  let res = await workflowActions.getWorkflowImages({ InspectionID, SelectionType, ...additionalProps });
  const {
    Data: { Images, ...restProps },
  } = res.data;
  let dataToSave = null;
  if (Images) {
    dataToSave = map(Images, item => {
      return {
        src: Helpers.getUploadImageSource(item[workflowConstants.formConstants.fields.name], item[workflowConstants.formConstants.fields.url]),
        thumbnailUrl: item[workflowConstants.formConstants.fields.thumbnailUrl],
        documentSizeUrl: item[workflowConstants.formConstants.fields.documentSizeUrl],
        ...item,
        actionsMenu: { ...Helpers.mapInfoIconDisplayProps(item, workflowConstants.displayProps) },
      };
    });
  }

  return { images: dataToSave, filter: { ...restProps } };
};

//fetch images for the first workflow screen
export const getWorkflowImages = (InspectionID, selectionType, defineOpenedAccordion, cFilter, nonCFilter, setFilters) => {
  return async dispatch => {
    try {
      dispatch(handleGlobalLoader(true));
      const result1 = await fetchWorkflowImages(InspectionID, selectionType, cFilter);
      const result2 = await fetchWorkflowImages(InspectionID, selectionType, nonCFilter);
      const allImages = ((result1 && result1.images) || []).concat((result2 && result2.images) || []);

      if (!isEmpty(allImages)) {
        defineOpenedAccordion && defineOpenedAccordion(allImages, result1.filter.TotalItems, result2.filter.TotalItems);
      }

      setFilters && setFilters(result1.filter, result2.filter);

      dispatch(setWorkflowImages(allImages));

      dispatch(handleGlobalLoader(false));
    } catch (err) {
      dispatch(handleGlobalLoader(false));
    }
  };
};

export const getWorkflowImagesSingleType = (InspectionID, selectionType, filter, callback, currentImages = []) => {
  return async dispatch => {
    try {
      dispatch(handleGlobalLoader(true));
      const result = await fetchWorkflowImages(InspectionID, selectionType, filter);

      const images = (result && result.images) || [];

      callback && callback(result.filter, images);

      const allImages = currentImages.concat((result && result.images) || []);
      dispatch(setWorkflowImages(allImages));

      dispatch(handleGlobalLoader(false));
    } catch (err) {
      dispatch(handleGlobalLoader(false));
    }
  };
};

export const setSelection = selected => {
  return async (dispatch, getState) => {
    const {
      workflowReducer: { workflowImages },
      inspectionReducer: { inspectionID },
    } = getState();

    if (inspectionID)
      await workflowActions.selectWorkflowImages({
        InspectionID: inspectionID,
        SelectionType: workflowConstants.imageSelectionTypes.all,
        SelectionStatus: selected ? workflowConstants.imageSelectionStatus.select : workflowConstants.imageSelectionStatus.deselect,
      });
    const res = Helpers.setSelected(selected, workflowImages, workflowConstants.formConstants.fields.selected);
    dispatch(setWorkflowImages(res));
  };
};

export const setImageActive = (id, files) => {
  return async (dispatch, getState) => {
    const {
      inspectionReducer: { inspectionID },
    } = getState();
    const item = find(files, { ID: id });
    if (inspectionID)
      await workflowActions.selectWorkflowImages({
        InspectionID: inspectionID,
        SelectionType: workflowConstants.imageSelectionTypes.single,
        SelectionStatus: item[workflowConstants.formConstants.fields.selected] ? workflowConstants.imageSelectionStatus.deselect : workflowConstants.imageSelectionStatus.select,
        FileID: item[workflowConstants.formConstants.fields.id],
      });
    dispatch(activateWorkflowImage(id));
  };
};

export const generateComputerVision = (InspectionID, callback) => {
  return async dispatch => {
    try {
      dispatch(handleGlobalLoader(true, loaderConstants.loaderTypes.computerVision.name));
      await workflowActions.generateComputerVisionEmptyResp([{ inspection_id: InspectionID }]);

      callback();
      // }
      dispatch(handleGlobalLoader(false));
    } catch (err) {
      dispatch(handleGlobalLoader(false));
    }
  };
};
