export const alarmDefaults = [
  { Name: 'high-high', AlarmLevel: null, Colour: null },
  { Name: 'high', AlarmLevel: null, Colour: null },
  { Name: 'low', AlarmLevel: null, Colour: null },
  { Name: 'low-low', AlarmLevel: null, Colour: null },
];

export const formConstants = {
  fields: {
    name: 'Name',
    measurementPointUnit: 'MeasurementPointUnit',
    expectedRange: 'ExpectedRange',
    readingsAndGaugesGroupName: 'ReadingsAndGaugesGroupName',
    measurementLocationName: 'MeasurementLocationName',
    questionMeasurementPointAlarm: 'QuestionMeasurementPointAlarm',
    measurementPointGeometry: 'MeasurementPointGeometry',
    measurementPointCameraPosition: 'MeasurementPointCameraPosition',
    measurementLocationGeometry: 'MeasurementLocationGeometry',
    measurementLocationCameraPosition: 'MeasurementLocationCameraPosition',
    colour: 'Colour',
    coordinates: 'coordinates',
    visible: 'visible',
    cameraPosition: 'CameraPosition',
    geometry: 'Geometry',
    systemType: 'SystemType',
    measurementPointColour: 'MeasurementPointColour',
    measurementLocationColour: 'MeasurementLocationColour',
  },
};
