import { cloneDeep, filter, get, isEmpty, map } from 'lodash';

import { setWorkflowImages } from '../../inspection-workflow/actions/action-creators';
import { getInspectionDetails } from '../../inspections/actions/inspection-actions';
import {
  handleDeleteImageDefectModal,
  setActiveDefectsTab,
  setAllDefectsData,
  setCommentsData,
  setComponentDefectsData,
  setCurrentImageData,
  setDefectsData,
  setDefectsLoading,
  setDrawingsLoading,
  setImageDefectsData,
  setImageDrawings,
  setImagesCountStatistics,
  setLinkedDefectDetails,
  setNewDefect,
  setSelectedDefectData,
  setSelectedDefectLoader,
  updateDefectsData,
} from './action-creators';

import startWorkflowApi from '../../../api/start-workflow/actions';
import { handleGlobalLoader } from '../../../common/global-loader/actions/loader-actions';
import { fetchWorkflowImages } from '../../inspection-workflow/actions/workflow-actions';
import { appendComponent, setComponentDetailsData, updateComponentTempSuccess } from '../../inspections/actions/action-creators';
import ElementActions from '../../inspections/constants/element-actions';
import { goToStep } from './stepper-actions';

import inspectionApi from '../../../api/inspection/actions';
import { default as inspectionWorkflowConstants, default as workflowConstants } from '../../inspection-workflow/constants/inspection-workflow-constants';
import { measurementTypes } from '../../inspections/constants/constants';
import { formConstants } from '../../inspections/constants/defect-constants';
import { stepperConstants } from '../constants/constants';
import { TABS } from '../constants/defect-list-constants';

import DrawingHelpers from '../../../common/drawing-helpers';
import { get3DPointFrom2D } from '../../inspections/potree/actions/actions';

export const setCurrentImage = (image, index, inspectionID, defectsTableParams, callbackOnPredefinedDrawing) => {
  return async dispatch => {
    dispatch(setCurrentImageData({ image, index }));

    // Reset/Refetch when image is changed
    dispatch(setActiveDefectsTab(TABS.single));
    dispatch(getDefects(inspectionID, defectsTableParams, TABS.single, image, callbackOnPredefinedDrawing));
    dispatch(goToStep(stepperConstants.QUESTIONNAIRE));
    dispatch(setNewDefect(null));
  };
};

//fetch images workflow second screen
export const getWorkflowImagesSecondScreen = (selectedImages, InspectionID, selectionType, defectsTableParams, defectsTab, cameraSelectionType, callback, callbackOnPredefinedDrawing) => {
  return async dispatch => {
    //refetch if user reloads
    try {
      dispatch(handleGlobalLoader(true));

      dispatch(getInspectionDetails(InspectionID));

      const { images: workflowImages } = await fetchWorkflowImages(InspectionID, selectionType);
      dispatch(setWorkflowImages(workflowImages));

      selectedImages = filter(workflowImages, { [workflowConstants.formConstants.fields.selected]: true });
      if (!isEmpty(selectedImages)) {
        dispatch(setCurrentImage(selectedImages[0], 0, InspectionID, defectsTableParams, callbackOnPredefinedDrawing));
      } else {
        dispatch(setWorkflowImages([]));
        callback && callback();
      }

      dispatch(handleGlobalLoader(false));
    } catch (err) {
      dispatch(handleGlobalLoader(false));
    }
  };
};

export const resetWorkflowImagesSecondScreen = (InspectionID, selectionType, currentImage, cameraSelectionType) => {
  return async dispatch => {
    //refetch if user reloads
    try {
      const { images: workflowImages } = await fetchWorkflowImages(InspectionID, selectionType);

      dispatch(setWorkflowImages(workflowImages));
    } catch (err) {
      console.log(err);
    }
  };
};

export const createNewDefect = (data, imagePosition, fileID, depth, cameraHelper, withContextualization, onSuccess = () => null) => {
  return async (dispatch, getState) => {
    const {
      inspectionReducer: { inspectionID },
      startWorkflowReducer: { defects, imageDefects, allDefects },
    } = getState();
    dispatch(setDefectsLoading(true));
    dispatch(setDrawingsLoading(true));
    // dispatch(handleGlobalLoader(true));
    try {
      if (withContextualization) {
        if (!imagePosition || !imagePosition.left || !imagePosition.top) throw new Error('cannot find point on the point cloud');
        const point = await get3DPointFrom2D({ InspectionID: inspectionID, FileID: fileID, PointPosition: { X: imagePosition.left, Y: imagePosition.top, Z: 1 }, Depth: depth }, cameraHelper);
        if (!point) throw new Error('cannot find point on the point cloud');

        data.Geometry.coordinates = [[point.x, point.y, point.z]];
      }

      const res = await ElementActions[measurementTypes.defect].requestActions.createElement({ ...data, SystemType: measurementTypes.defect, InspectionID: parseInt(inspectionID) });
      const Data = get(res, 'data.Data');

      dispatch(setSelectedDefect(Data, false));
      dispatch(setDefectsData([...(filter(defects, item => item.ID > 0) || []), Data]));
      dispatch(setImageDefectsData([...(filter(imageDefects, item => item.ID > 0) || []), Data]));
      dispatch(setAllDefectsData([...(filter(allDefects, item => item.ID > 0) || []), Data]));

      // Load drawings when defect is created
      const drawings = JSON.parse(Data[formConstants.fields.drawings]);
      dispatch(setImageDrawings(drawings));

      await dispatch(setDefectsLoading(false));
      await dispatch(setDrawingsLoading(false));
      // dispatch(handleGlobalLoader(false));

      onSuccess(cloneDeep(Data));
    } catch (error) {
      dispatch(goToStep(stepperConstants.QUESTIONNAIRE));
      console.error(error);
      dispatch(setDefectsLoading(false));
      dispatch(setDrawingsLoading(false));
      // dispatch(handleGlobalLoader(false));
    }
  };
};

export const setNewTempDefect = (data, defects, imageDefects, allDefects) => {
  return async dispatch => {
    try {
      const Data = { ...data, SystemType: measurementTypes.defect };

      dispatch(setSelectedDefectData(Data));
      dispatch(setDefectsData([...defects, Data]));
      dispatch(setImageDefectsData([...imageDefects, Data]));
      dispatch(setAllDefectsData([...allDefects, Data]));
    } catch (error) {
      console.error(error);
    }
  };
};

export const updateTempDefect = (data, defects, imageDefects, allDefects) => {
  return async dispatch => {
    try {
      const foundIndex = defects.findIndex(el => el.ID === data.ID);
      if (foundIndex > -1) {
        const Data = { ...data, SystemType: measurementTypes.defect };
        const newDefects = [...defects];
        const newImageDefects = [...imageDefects];
        const newAllDefects = [...allDefects];
        newDefects[foundIndex] = Data;
        newImageDefects[foundIndex] = Data;
        newAllDefects[foundIndex] = Data;
        dispatch(setSelectedDefectData(Data));
        dispatch(setDefectsData([...newDefects]));
        dispatch(setImageDefectsData([...newImageDefects]));
        dispatch(setAllDefectsData([...newAllDefects]));
      } else {
        console.warn('There is no defect item with id: ', data.ID);
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const createNewComponent = data => {
  return async dispatch => {
    try {
      const res = await inspectionApi.createComponent({ ...data });
      const Data = get(res, 'data.Data');
      if (Data) {
        dispatch(updateComponentTempSuccess({ ...data, ComponentID: data.ID }, 'delete'));
        dispatch(appendComponent({ ...Data }));
        dispatch(setComponentDetailsData(Data));
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const createNewTempComponent = (data, callback = () => null) => {
  return async (dispatch, getState) => {
    const {
      inspectionReducer: { inspectionDetails },
    } = getState();
    try {
      const { dispatchActions, defaultElement } = ElementActions[measurementTypes.component];
      dispatch(dispatchActions.appendElementTemp({ ...defaultElement(inspectionDetails.CameraPosition), ...data, ID: -2 }));
      dispatch(setComponentDetailsData({ ...defaultElement(inspectionDetails.CameraPosition), ...data, ID: -2 }));
      callback();
    } catch (error) {
      console.error(error);
    }
  };
};

export const updateComponent = data => {
  return async dispatch => {
    try {
      const { requestActions, dispatchActions } = ElementActions[measurementTypes.component];
      if (data.IsTemp) {
        dispatch(dispatchActions.updateElementTempSuccess(data, 'update'));
      } else {
        await requestActions.updateElement(data);
        dispatch(dispatchActions.updateElementSuccess(data, 'update'));
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const getDefects = (inspection_id, params, activeTab, currentImage, callbackOnPredefinedDrawing) => {
  return async (dispatch, getState) => {
    const {
      themeReducer: { severityColors },
    } = getState();

    try {
      let res = null;
      dispatch(setDefectsLoading(true));
      dispatch(setDrawingsLoading(true));

      if (TABS.all === activeTab) {
        res = await ElementActions[measurementTypes.defect].requestActions.getInspectionElements({ InspectionID: parseInt(inspection_id), Filter: 'SELECTED', ...params });
      } else if (TABS.single === activeTab && currentImage) {
        res = await ElementActions[measurementTypes.defect].requestActions.getInspectionElements({
          InspectionID: parseInt(inspection_id),
          FileID: currentImage[inspectionWorkflowConstants.formConstants.fields.id],
          ...params,
        });
      }

      if (res) {
        const { Data } = res.data;
        if (Data && Data.Defects) {
          if (TABS.all === activeTab) {
            dispatch(setAllDefectsData(Data.Defects));
            dispatch(setImageDrawings());
          } else {
            let defects = Data.Defects;
            dispatch(setImageDefectsData(defects));
            if (!callbackOnPredefinedDrawing) {
              dispatch(setImageDrawings(DrawingHelpers.extractImageDrawings(defects, severityColors)));
            }
          }
          dispatch(setDefectsData(Data.Defects));
        } else {
          if (TABS.all === activeTab) {
            dispatch(setAllDefectsData([]));
            dispatch(setImageDrawings());
          } else {
            dispatch(setImageDefectsData([]));
            dispatch(setImageDrawings());
          }
          dispatch(setDefectsData([]));
        }
      }
      dispatch(setDefectsLoading(false));
      if (callbackOnPredefinedDrawing) {
        callbackOnPredefinedDrawing(() => dispatch(setDrawingsLoading(false)));
      } else {
        dispatch(setDrawingsLoading(false));
      }
    } catch (error) {
      console.error(error);
      dispatch(setDefectsLoading(false));
      dispatch(setDrawingsLoading(false));
    }
  };
};

export const setSelectedDefect = (defect, withRedirect = true) => {
  return async dispatch => {
    try {
      dispatch(setSelectedDefectLoader(true));
      dispatch(setSelectedDefectData(defect));

      if (isEmpty(defect) || defect.ID < 0) {
        return;
      }

      const res = await inspectionApi.getDefectComments([{ defect_id: defect.ID }]);
      const Data = get(res, 'data.Data');

      //change step when fetching defect
      if (withRedirect) {
        dispatch(goToStep(stepperConstants.DEFECT_EDIT));
      }

      if (Data) {
        dispatch(setCommentsData(Data));
      } else {
        dispatch(setCommentsData([]));
      }
      dispatch(setSelectedDefectLoader(false));
    } catch (e) {
      console.log(e);
      dispatch(setSelectedDefectLoader(false));
    }
  };
};

export const getDefectDetails = (defect, selectedImage, activeTab) => {
  return async dispatch => {
    try {
      dispatch(setSelectedDefect(null));
      if (defect.IsTemp) {
        dispatch(setSelectedDefect(defect));
        return;
      }
      const res = await ElementActions[measurementTypes.defect].requestActions.getElementDetails(defect.ID);
      const { Data } = res.data;
      if (Data) {
        dispatch(setSelectedDefect(Data));
        // loadDrawings
        if (activeTab === TABS.single) {
          dispatch(getDefectDrawings([{ file_id: selectedImage[workflowConstants.formConstants.fields.id] }, { defect_id: Data[formConstants.fields.id] }], defect[formConstants.fields.severity]));
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const getDefectsByComponent = data => {
  return async dispatch => {
    try {
      const res = await startWorkflowApi.getComponentDefects(data);
      const Data = get(res, 'data.Data');
      dispatch(setComponentDefectsData(Data || []));
    } catch (e) {
      console.log(e);
    }
  };
};

export const getLinkedDefectDetails = id => {
  return async dispatch => {
    try {
      const res = await ElementActions[measurementTypes.defect].requestActions.getElementDetails(id);
      const { Data } = res.data;
      if (Data) dispatch(setLinkedDefectDetails(Data));
    } catch (e) {
      console.log(e);
    }
  };
};

export const saveLinkedDrawing = data => {
  return async (dispatch, getState) => {
    const {
      startWorkflowReducer: { imageDefects, allDefects, defects, linkedDefect },
    } = getState();
    dispatch(setDefectsLoading(true));

    try {
      await startWorkflowApi.saveDrawing(data);
      dispatch(setDefectsData([...(filter(defects, item => item.ID > 0) || []), linkedDefect]));
      dispatch(setImageDefectsData([...(filter(imageDefects, item => item.ID > 0) || []), linkedDefect]));
      dispatch(setAllDefectsData([...(filter(allDefects, item => item.ID > 0) || []), linkedDefect]));
      dispatch(setLinkedDefectDetails(null));
      // dispatch(goToNextStep());
      dispatch(setDefectsLoading(false));
    } catch (e) {
      dispatch(setDefectsLoading(false));

      console.log(e);
    }
  };
};

export const saveImageDrawing = data => {
  return async dispatch => {
    try {
      await startWorkflowApi.saveDrawing(data);
    } catch (e) {
      console.log(e);
    }
  };
};

export const reviewImage = (workflowImages, inspectionID, currentImage) => {
  return async dispatch => {
    try {
      if (!inspectionID || isEmpty(currentImage) || isEmpty(workflowImages)) return;
      await startWorkflowApi.reviewImage({ InspectionID: inspectionID, FIleID: currentImage[inspectionWorkflowConstants.formConstants.fields.id] });
      let newWorfklowImages = [...(workflowImages || [])];
      const foundIndex = newWorfklowImages.findIndex(el => el[inspectionWorkflowConstants.formConstants.fields.id] === currentImage[inspectionWorkflowConstants.formConstants.fields.id]);
      if (foundIndex > -1) {
        newWorfklowImages[foundIndex][inspectionWorkflowConstants.formConstants.fields.reviewed] = true;
        dispatch(setWorkflowImages(newWorfklowImages));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateDefect = (data, isTemp, drawings, currentImage) => {
  return async (dispatch, getState) => {
    const {
      startWorkflowReducer: { defects },
    } = getState();

    try {
      let res = null;
      let Data = data;
      if (!isTemp) {
        res = await ElementActions[measurementTypes.defect].requestActions.updateElement(data);
        Data = get(res, 'data.Data');
      }
      const index = defects.findIndex(element => element.ID === Data.ID);
      if (index > -1) {
        let newElements = cloneDeep(defects);
        newElements[index] = { ...defects[index], ...Data };
        //update defect color if severity is changed
        if (drawings && defects[index][formConstants.fields.severity] !== Data[formConstants.fields.severity]) {
          dispatch(updateDefectColor(drawings, Data.Severity, true, currentImage, Data));
        }
        dispatch(updateDefectsData(newElements));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

const updateDefectColor = (drawing, severity, includeLoader, currentImage, data) => {
  return async (dispatch, getState) => {
    const { severityColors } = getState().themeReducer;
    includeLoader && dispatch(setDrawingsLoading(true));

    const draw = !isNaN(severity) ? DrawingHelpers.adjustStrokeColor(drawing, severity, severityColors) : drawing;
    await dispatch(setImageDrawings(draw));

    await dispatch(
      saveImageDrawing({ FileID: currentImage[workflowConstants.formConstants.fields.id], DefectID: data[formConstants.fields.id], [formConstants.fields.drawings]: JSON.stringify(draw) })
    );

    includeLoader && dispatch(setDrawingsLoading(false));
  };
};

export const getDefectDrawings = (params, defectSeverity) => {
  return async (dispatch, getState) => {
    const { severityColors } = getState().themeReducer;
    try {
      dispatch(setDrawingsLoading(true));
      const res = await startWorkflowApi.getDefectDrawings(params);
      const Data = get(res, 'data.Data');

      if (Data && Data.Drawing && Data.Drawing !== 'null') {
        let drawing = JSON.parse(Data.Drawing);
        drawing = !isNaN(defectSeverity) ? DrawingHelpers.adjustStrokeColor(drawing, defectSeverity, severityColors) : drawing;

        dispatch(setImageDrawings(drawing));
      }
      dispatch(setDrawingsLoading(false));
    } catch (e) {
      console.log(e);
      dispatch(setDrawingsLoading(false));
    }
  };
};

export const addDefectComment = data => {
  return async (dispatch, getState) => {
    const {
      startWorkflowReducer: { defectComments },
    } = getState();
    try {
      const res = await inspectionApi.addDefectComment({ Message: data.Message, DefectID: data.defect.ID });
      const { Data } = res.data;
      if (Data) {
        const newComments = [...(defectComments || []), Data];
        dispatch(setCommentsData(newComments));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const removeWorkflowDefect = data => {
  return async (dispatch, getState) => {
    const {
      startWorkflowReducer: { defects },
      themeReducer: { severityColors },
    } = getState();

    dispatch(setDrawingsLoading(true));
    try {
      let newDefects = [...defects];
      const foundIndex = newDefects.findIndex(el => el.ID === data.DefectID);
      if (foundIndex > -1) {
        newDefects.splice(foundIndex, 1);
        dispatch(setDefectsData(newDefects));
        dispatch(setImageDefectsData(newDefects));
        dispatch(setSelectedDefect(null));
        dispatch(setImageDrawings(DrawingHelpers.extractImageDrawings(newDefects, severityColors)));
        dispatch(goToStep(stepperConstants.QUESTIONNAIRE));
        dispatch(setDrawingsLoading(false));
      }
    } catch (e) {
      console.log(e);
      dispatch(setDrawingsLoading(false));
    }
  };
};

export const clearImageDrawings = () => {
  return async dispatch => {
    dispatch(setDrawingsLoading(true));
    await dispatch(setImageDrawings(null));
    dispatch(setDrawingsLoading(false));
  };
};

export const deleteLinkedDefect = (data, onSuccess = () => null) => {
  return async (dispatch, getState) => {
    const {
      startWorkflowReducer: { defects },
    } = getState();
    try {
      dispatch(setDefectsLoading(true));

      await startWorkflowApi.removeImageDefect(data);
      dispatch(
        toggleDeleteImageDefectModal({
          isOpen: false,
        })
      );
      let newDefects = cloneDeep(defects);
      const foundIndex = newDefects.findIndex(el => el.ID === data.DefectID);
      if (foundIndex > -1) {
        newDefects.splice(foundIndex, 1);
        dispatch(setDefectsData(newDefects));
        dispatch(setImageDefectsData(newDefects));
        dispatch(setSelectedDefect(null));
        onSuccess();
        // dispatch(goToStep(stepperConstants.QUESTIONNAIRE));
      }
      dispatch(setDefectsLoading(false));
    } catch (e) {
      console.log(e);
      dispatch(setDefectsLoading(false));

      dispatch(
        toggleDeleteImageDefectModal({
          isOpen: false,
        })
      );
    }
  };
};

export const deleteLinkedImage = (data, defect, currentImage, callback) => {
  return async dispatch => {
    try {
      dispatch(setDefectsLoading(true));

      await startWorkflowApi.removeImageDefect(data);
      await dispatch(getDefectDetails(defect, currentImage));

      dispatch(setDefectsLoading(false));
      callback && callback();
    } catch (e) {
      console.log(e);
      dispatch(setDefectsLoading(false));
    }
  };
};

export const toggleDeleteImageDefectModal = data => {
  return dispatch => {
    dispatch(handleDeleteImageDefectModal(data));
  };
};

export const savePointRelatedImages = (defect, currentImage, imageItems, callback) => {
  return async dispatch => {
    try {
      const images = map(imageItems, item => {
        return { FileID: item.FileID, [formConstants.fields.drawings]: JSON.stringify(item.Drawings) };
      });

      const data = { DefectID: defect[formConstants.fields.id], [formConstants.fields.drawings]: images };

      await startWorkflowApi.saveDrawings(data);

      await dispatch(getDefectDetails(defect, currentImage));
      callback && callback();
    } catch (e) {
      console.log(e);
    }
  };
};

export const reloadAllImageDrawings = (inspectionId, params, activeTab, currentImage) => {
  return async dispatch => {
    //possible additional logic here
    await dispatch(getDefects(inspectionId, params, activeTab, currentImage));
  };
};

export const getInspectedImagesCount = (inspectionID, selectionType) => {
  return async dispatch => {
    try {
      const res = await startWorkflowApi.getInspectedImagesCount([{ inspection_id: inspectionID }, { selection_type: selectionType }]);
      const Data = get(res, 'data.Data');

      dispatch(setImagesCountStatistics(Data));
    } catch (e) {
      console.log(e);
    }
  };
};

export const resetDefectList = (defects, imageDefects, allDefects) => {
  return async dispatch => {
    dispatch(setDefectsLoading(true));

    dispatch(setDefectsData([...(filter(defects, item => item.ID > 0) || [])]));
    dispatch(setImageDefectsData([...(filter(imageDefects, item => item.ID > 0) || [])]));
    dispatch(setAllDefectsData([...(filter(allDefects, item => item.ID > 0) || [])]));

    dispatch(setDefectsLoading(false));
  };
};
