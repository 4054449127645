import React from 'react';
import { values } from 'lodash';

import ModuleSections from '../right-toolbar/common/module-sections';
import { sections } from './constants/work-order-sections-constants';
import { statuses } from '../../constants/work-order-constants';
import { fields } from './constants/work-order-right-side-constants';

const WorkOrderSections = ({ moduleDetails, handleSectionActionClicked, user, ...restProps }) => (
  <ModuleSections
    module={moduleDetails}
    sections={values(sections)}
    handleSectionActionClicked={handleSectionActionClicked}
    handleCustomAction={handleSectionActionClicked}
    statuses={statuses}
    user={user}
    createdById={moduleDetails[fields.createdBy]}
    skipOrdering
    {...restProps}
  />
);

export default WorkOrderSections;
