import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { debounce, flatten, forEach, isEmpty, map } from 'lodash';

import DownloadReportModalMeasurements from '../../../../common/download-report-modal-measurments/components/download-report-modal';
import Loader from '../../../../common/global-loader/components/simple-loader';
import Modal from '../../../../common/modal/components/modal';
import Tab from '../../../../common/tabs/component/tab';
import Tabs from '../../../../common/tabs/component/tabs';
import LinkedImagesSliderSlider from '../../../start-workflow/components/linked-images-slider';
import ExternalUploadModal from '../right-toolbar/external-upload-modal';
import UploadModal from '../right-toolbar/upload-modal';
import NdtAlarmsModal from './ndt-alarms-modal';
import NDTMeasurementForm from './ndt-measurement-form';
import NDTMeasurementHistory from './ndt-measurement-history';
import NdtModal from './ndt-modal';
import NdtNotificationsModal from './ndt-notifications-modal';

import { FEATURES } from '../../../../common/constants';
import orientationConstants from '../../../start-workflow/constants/orientation-constants';
import { measurementTypes } from '../../constants/constants';
import { equipmentFields, measurementConstants, tabNames, toolbarItems } from '../../constants/ndt-constants';
import { supportedMimeTypes } from '../../constants/upload-constants';
import { commonFields, sections } from './constants/ndt-alarms';
import { validate } from './validators/measurement-validator';

import { getNDTMeasurementFilesUploaded } from '../../../upload/actions/external-upload-actions';
import { setNdtAlarms, setSearchText } from '../../actions/action-creators';
import { getInspectionElementsByType, getNDTMeasurementNotifications, selectNDTMeasurement } from '../../actions/inspection-actions';
import { updateMeasurement, updateMeasurementComponent } from '../../actions/ndt-actions';
import { addAlarmEmail, getDescriptorData, getNdtAlarms, removeAlarmEmail, saveAlarms, updateAlarmsNotifications } from '../../actions/ndt-alarms-actions';

import Helpers from '../../../../common/helpers';
import RenderIf from '../../../../common/render-if/components/render-if';
import CriticalEquipmentInfo from '../right-toolbar/common/critical-equipment-info';

const sectionVariants = flatten(
  map(sections, item => {
    return map(item.variants);
  })
);

class NDTMeasurementDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unsavedChanges: false,
      modalData: {
        isOpen: false,
      },
      ndtModalData: {
        isOpen: false,
      },
      confirmationModal: {
        isOpen: false,
      },
      setNotificationsModal: {
        isOpen: false,
      },
      measurement: {},
      activeToolbarItem: toolbarItems[tabNames.details].name,
      elementAlreadyFocused: false,
    };
    this.formChangeDebounce = debounce(this.submitForm, 200);
  }

  getUploadedFiles = () => {
    const { queryItem, getMeasurementFilesUploaded } = this.props;
    getMeasurementFilesUploaded(queryItem);
  };

  fetchData = (ID, tabName) => {
    const { elementAlreadyFocused } = this.state;
    const { selectMeasurement, getInspectionElementsByType, getNDTMeasurementNotifications, inspectionId, viewer } = this.props;
    if (!ID || tabName !== tabNames.details || !inspectionId) {
      return;
    }
    selectMeasurement({ ID }, null, measurement => {
      this.setState({ measurement });
      this.getUploadedFiles();
      getNDTMeasurementNotifications(ID);
      getInspectionElementsByType(inspectionId, measurementTypes.component);
      if (measurement?.Geometry?.coordinates?.[0] && measurement?.CameraPosition?.coordinates && viewer && !elementAlreadyFocused) {
        viewer.zoomToPosition(
          { x: measurement.CameraPosition.coordinates[0], y: measurement.CameraPosition.coordinates[1], z: measurement.CameraPosition.coordinates[2] },
          measurement.Geometry.coordinates,
          500
        );
        this.setState({ elementAlreadyFocused: true });
      }
    });
  };

  componentDidMount() {
    const { queryItem } = this.props;
    if (!queryItem || queryItem < 0) {
      // It is not a deep link so the camera should not focus on it
      this.setState({ elementAlreadyFocused: true });
      return;
    }
    this.fetchData(queryItem, tabNames.details);
  }

  componentDidUpdate = prevProps => {
    const { queryItem, viewer } = this.props;

    // reset state when Create new ndt-measurement is clicked
    if (prevProps.queryItem !== queryItem && queryItem && queryItem < 0) {
      this.setState({ measurement: {} });
    }
    if (queryItem && queryItem > 0 && (prevProps.queryItem !== queryItem || viewer !== prevProps.viewer)) {
      this.fetchData(queryItem, tabNames.details);
    }
  };

  componentWillUnmount() {
    this.formChangeDebounce.cancel();
  }

  openUploadModal = (onLocalUpload, uploadGroup) => {
    const { openExternalUploadModal } = this;
    const { measurement } = this.state;
    this.setState(prevProps => ({
      modalData: {
        ...prevProps.modalData,
        isOpen: true,
        CustomContent: () => (
          <UploadModal
            closeAction={this.closeUploadModal}
            onDrop={files => onLocalUpload(files, measurement)}
            onExternalUpload={() => openExternalUploadModal(uploadGroup)}
            supportedMimeTypes={supportedMimeTypes}
            className="project-dropzone"
            defect={measurement}
          />
        ),
        type: 'none',
        confirmAction: this.closeUploadModal,
        closeAction: this.closeUploadModal,
      },
    }));
  };

  openExternalUploadModal = uploadGroup => {
    const { measurement } = this.state;
    this.setState(prevProps => ({
      modalData: {
        ...prevProps.modalData,
        isOpen: true,
        CustomContent: () => <ExternalUploadModal closeAction={this.closeUploadModal} defect={measurement} uploadGroup={uploadGroup} />,
        type: 'none',
        closeAction: this.closeUploadModal,
      },
    }));
  };

  closeUploadModal = () => {
    this.getUploadedFiles();
    this.setState(prevProps => ({
      modalData: {
        ...prevProps.modalData,
        isOpen: false,
      },
    }));
  };

  openImagesModal = (selectedImage, linkedImages) => {
    const { setOrientation } = this.props;
    let index = 0;
    linkedImages = map(linkedImages, (item, i) => {
      item.src = Helpers.getUploadImageSource(item.FileName, item.URL);
      if (selectedImage.FileID === item.FileID) {
        index = i;
      }
      return item;
    });

    this.setState(prevState => ({
      modalData: {
        ...prevState.modalData,
        customClassName: 'linked-images-slider',
        CustomContent: () => (
          <LinkedImagesSliderSlider
            linkedImages={linkedImages}
            currentSlideIndex={index}
            setOrientation={setOrientation}
            disabledOrientations={[orientationConstants.orientation.chart]}
            isPreviewModal={true}
          />
        ),
        isOpen: true,
        type: 'none',
        closeAction: () => this.setState({ modalData: { isOpen: false } }),
      },
    }));
  };

  openFile = (file, otherImages) => {
    if (file.isImage) {
      this.openImagesModal(file, otherImages);
    } else {
      Helpers.getFileExtensionAndDownload(file);
    }
  };

  closeNDTModal = () => {
    const { measurement } = this.state;

    this.triggerLeftHandSideReload();
    this.fetchData(measurement.ID, tabNames.details);

    this.setState(prevProps => ({
      ndtModalData: {
        ...prevProps.ndtModalData,
        isOpen: false,
      },
    }));
  };

  handleOnChange = (values, a, b) => {
    if (!b.pristine) {
      const errors = validate(values);
      if (isEmpty(errors)) {
        this.formChangeDebounce(values, true);
      }
    }
  };

  submitForm = values => {
    const { updateMeasurement, ndtMeasurementLocations } = this.props;
    const { comments, Geometry, ...measurementValues } = values;

    updateMeasurement(measurementValues, ndtMeasurementLocations);
  };

  openNDTModal = unit => {
    const { inspectionId, queryItem } = this.props;
    this.setState({
      ndtModalData: {
        customClassName: 'ndt-modal dark modal-large',
        CustomContent: () => <NdtModal closeModal={this.closeNDTModal} measurementId={queryItem} inspectionId={inspectionId} unit={unit} />,
        isOpen: true,
        type: 'none',
        closeAction: this.closeNDTModal,
      },
    });
  };

  onSubmitAlarmsForm = (vals, measurmentID) => {
    const { saveAlarms, resetNdtAlarms } = this.props;
    let submitArr = [];

    //map request
    forEach(sectionVariants, item => {
      let objToPush = {};
      Object.keys(vals).forEach(submitKey => {
        const newKey = submitKey.split('_')[0];
        const extenstion = submitKey.substr(newKey.length + 1, submitKey.length);

        if (extenstion === item) {
          let value = vals[submitKey];
          if (newKey === commonFields.operationID) {
            value = (value && value.value) || value;
          }
          if (newKey === commonFields.value) {
            value = parseFloat(value);
          }

          objToPush = {
            ...objToPush,
            [newKey]: value,
          };
        }
      });
      submitArr = [...submitArr, { ...objToPush, [commonFields.alarmType]: item }];
    });

    saveAlarms(measurmentID, submitArr, () => {
      this.setState({ unsavedChanges: false });
      resetNdtAlarms();
      this.closeAlarmsModal();
    });
  };

  onChangeAlarmsForm = () => {
    this.setState({ unsavedChanges: true });
  };

  closeAlarmsModal = () => {
    const { measurement } = this.state;

    this.triggerLeftHandSideReload();

    this.fetchData(measurement.ID, tabNames.details);
    this.setState(prevProps => ({
      ndtModalData: {
        ...prevProps.ndtModalData,
        isOpen: false,
      },
    }));
  };

  triggerLeftHandSideReload = () => {
    const { setSearchText, searchText } = this.props;

    setSearchText(searchText + ' ');
    setTimeout(() => {
      setSearchText(searchText);
    }, 300);
  };

  closeAlarmsModalWithVerification = () => {
    const { t } = this.context;
    const { unsavedChanges } = this.state;

    if (unsavedChanges) {
      this.setState({
        confirmationModal: {
          isOpen: true,
          content: t('NDT_ALARMS_MODAL.UNSAVED_CHANGES'),
          type: 'no-yes',
          confirmAction: () => {
            this.setState({ confirmationModal: { isOpen: false } });
            this.closeAlarmsModal();
          },
          closeAction: () => {
            this.setState({ confirmationModal: { isOpen: false } });
          },
        },
      });
    } else {
      this.closeAlarmsModal();
    }
  };

  openAlarmsModal = (unit, measurmentID) => {
    const { measurement } = this.state;

    const ownerID = measurement[measurementConstants.fields.createdByID];

    this.setState({
      ndtModalData: {
        customClassName: 'ndt-alarms-modal modal-large',
        CustomContent: () => (
          <NdtAlarmsModal
            onSubmit={this.onSubmitAlarmsForm}
            onChange={this.onChangeAlarmsForm}
            closeModal={this.closeAlarmsModalWithVerification}
            unit={unit}
            measurmentID={measurmentID}
            ownerID={ownerID}
          />
        ),
        isOpen: true,
        type: 'none',
        closeAction: this.closeAlarmsModalWithVerification,
      },
    });
  };

  openSetNotificationsModal = unit => {
    const { updateAlarmsNotifications } = this.props;
    const { measurement } = this.state;

    const closeModal = () =>
      this.setState({
        setNotificationsModal: {
          isOpen: false,
        },
      });

    this.setState({
      setNotificationsModal: {
        customClassName: 'ndt-alarms-modal modal-large',
        CustomContent: () => (
          <NdtNotificationsModal
            onSubmit={vals => {
              //save it on BE TO DO
              let submitArr = [];
              //map request
              forEach(sectionVariants, item => {
                let objToPush = {};
                Object.keys(vals).forEach(submitKey => {
                  const newKey = submitKey.split('_')[0];
                  const extenstion = submitKey.substr(newKey.length + 1, submitKey.length);
                  if (extenstion === item) {
                    let value = vals[submitKey];

                    if ([commonFields.enableNotifications, commonFields.id].indexOf(newKey) > -1) {
                      objToPush = {
                        ...objToPush,
                        [newKey]: value,
                      };
                    }
                  }
                });
                submitArr = [...submitArr, objToPush];
              });
              submitArr = map(submitArr, item => ({ ...item, [commonFields.enableNotifications]: item[commonFields.enableNotifications] || false }));
              updateAlarmsNotifications({ AlarmNotification: submitArr }, closeModal);
            }}
            closeModal={closeModal}
            unit={unit}
            measurmentID={measurement[measurementConstants.fields.id]}
          />
        ),
        isOpen: true,
        type: 'none',
        closeAction: closeModal,
      },
    });
  };

  openDownloadReportModalForMeasurments = measurementId => {
    const { t } = this.context;
    const {
      inspectionDetails: { InspectionID },
    } = this.props;

    const closeAction = () => this.setState({ modalData: { isOpen: false } });

    this.setState(prevState => ({
      modalData: {
        ...prevState.modalData,
        customClassName: 'download-report-modal-measurements modal-medium',
        title: t('INSPECTION_DETAILS.DOWNLOAD_REPORT'),
        CustomContent: () => <DownloadReportModalMeasurements inspectionId={InspectionID} measurementId={measurementId} closeModal={closeAction} skipStepXslx={1} />,
        isOpen: true,
        type: 'none',
        closeAction,
      },
    }));
  };

  render() {
    const { t } = this.context;
    const { openUploadModal, openNDTModal, handleOnChange, openFile, openAlarmsModal, openDownloadReportModalForMeasurments, openSetNotificationsModal } = this;
    const {
      markPointLocation,
      showGeometryWarning,
      viewer,
      inspectionDetails,
      deleteMeasurementModalData,
      ndtMeasurementLocations,
      uploadInProgress,
      measurementPointModalData,
      uploadProgress,
      inspectionId,
      measurementNotifications,
      handleActivePage,
      measurementDetailsLoading,
      queryItem,
      updateMeasurementComponent,
      projectId,
      componentDetails,
    } = this.props;
    const { ndtModalData, modalData, confirmationModal, measurement, setNotificationsModal, activeToolbarItem } = this.state;

    const containsCriticalEquipment = componentDetails && componentDetails[equipmentFields.critical] === true;

    if (measurementDetailsLoading) {
      return <Loader isLoading={true} />;
    }

    if (!inspectionDetails || isEmpty(measurement)) {
      return null;
    }

    return (
      <div className="ndt-measurement-details">
        <Tabs defaultTabKey={activeToolbarItem} navigationClassName="component-details__tabs" onChange={tabName => this.fetchData(queryItem, tabName)}>
          <Tab title={toolbarItems[tabNames.details].label} tabKey={tabNames.details}>
            <>
              <RenderIf if={containsCriticalEquipment}>
                <CriticalEquipmentInfo title={'CRITICAL_EQUIPMENT.TITLE'} paragraph={'CRITICAL_EQUIPMENT_NOTIFICATIONS.PARAGRAPH'} />
              </RenderIf>
              <NDTMeasurementForm
                {...{
                  showGeometryWarning,
                  markPointLocation,
                  selectedMeasurement: measurement,
                  initialValues: measurement,
                  onSubmit: handleOnChange,
                  onChange: handleOnChange,
                  openUploadModal,
                  openNDTModal,
                  openAlarmsModal,
                  openFile,
                  viewer,
                  openDownloadReportModalForMeasurments,
                  measurementLocations: ndtMeasurementLocations,
                  uploadProgressStyle: uploadProgress,
                  inspectionId,
                  measurementNotifications,
                  handleActivePage,
                  openSetNotificationsModal,
                  defaultComponent: { Name: t('DEFAULT_COMPONENT.NAME'), ID: inspectionDetails.DefaultComponent },
                  updateMeasurementComponent,
                }}
              />
              <Modal {...ndtModalData} />
              <Modal {...modalData} modalDisabled={uploadInProgress} />
              <Modal {...deleteMeasurementModalData} />
              <Modal {...measurementPointModalData} />
              <Modal {...confirmationModal} />
              <Modal {...setNotificationsModal} />
            </>
          </Tab>
          {FEATURES.modulesHistory?.visible && (
            <Tab title={toolbarItems[tabNames.history].label} tabKey={tabNames.history}>
              <NDTMeasurementHistory measurementID={queryItem} project_id={projectId} inspection_id={inspectionId} defaultComponent={inspectionDetails.DefaultComponent} />
            </Tab>
          )}
        </Tabs>
      </div>
    );
  }
}
NDTMeasurementDetails.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  ndtMeasurementLocations: state.ndtReducer.measurementLocations,
  deleteMeasurementModalData: state.ndtReducer.delMeasurementModalData,
  viewer: state.potreeReducer.viewerInstance,
  uploadInProgress: state.uploadReducer.uploadInProgress,
  uploadProgress: state.themeReducer.uploadProgress,
  inspectionDetails: state.inspectionReducer.inspectionDetails,
  measurementPointModalData: state.ndtReducer.measurementPointModalData,
  searchText: state.inspectionReducer.searchText,
  measurementNotifications: state.ndtReducer.measurementNotifications,
  measurementDetailsLoading: state.ndtReducer.measurementDetailsLoading,
  componentDetails: state.inspectionReducer.componentDetails,
});

const mapDispatchToProps = dispatch => ({
  updateMeasurement: (newData, currentMeasurements) => dispatch(updateMeasurement(newData, currentMeasurements)),
  getInspectionElementsByType: (inspectionID, elementType) => dispatch(getInspectionElementsByType(inspectionID, elementType)),
  getMeasurementFilesUploaded: measurementId => dispatch(getNDTMeasurementFilesUploaded(measurementId)),
  setSearchText: text => dispatch(setSearchText(text)),
  selectMeasurement: (data, currentMeasurement, callback) => dispatch(selectNDTMeasurement(data, currentMeasurement, callback)),
  getNdtAlarms: measurmentId => dispatch(getNdtAlarms(measurmentId, null, true)),
  getDescriptorData: () => dispatch(getDescriptorData()),
  saveAlarms: (measurmentId, alarms, callback) => dispatch(saveAlarms(measurmentId, alarms, callback)),
  resetNdtAlarms: () => dispatch(setNdtAlarms()),
  addAlarmEmail: (email, id, type, callback) => dispatch(addAlarmEmail(email, id, type, callback)),
  removeAlarmEmail: (id, type) => dispatch(removeAlarmEmail(id, type)),
  getNDTMeasurementNotifications: measurementId => dispatch(getNDTMeasurementNotifications(measurementId)),
  updateAlarmsNotifications: (alarms, sucessCallback) => dispatch(updateAlarmsNotifications(alarms, sucessCallback)),
  updateMeasurementComponent: (id, componentID) => dispatch(updateMeasurementComponent(id, componentID)),
});

NDTMeasurementDetails.defaultProps = {
  showGeometryWarning: true,
};

export default connect(mapStateToProps, mapDispatchToProps)(NDTMeasurementDetails);
