import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Field, reduxForm } from 'redux-form';

import DatePicker from '../../../../common/form/components/date-picker';

import { AMAZON_IMAGE_SIZES, FORMS } from '../../../../common/constants';
import { formConstants, dateRangeOptions } from './constants/predictive-settings';
import RadioButton from '../../../../common/form/components/radio-button';
import ToggleField from '../../../../common/form/components/toggle-field';
import CustomSelect from '../../../../common/form/components/select';

// import { validate } from './validators/measurement-point-validator';

class PredictiveSettingsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageType: AMAZON_IMAGE_SIZES.small.name,
    };
  }

  handleDateChange = (date, fieldName) => {
    const { changeField } = this.props;

    //validate dates... start date must not be past end date
    if (fieldName === formConstants.fields.dateFrom) {
      let { dateTo } = this.props;
      const dateFrom = new Date(date).getTime();
      dateTo = new Date(dateTo).getTime();

      if (dateFrom > dateTo) {
        changeField(formConstants.fields.dateTo, dateFrom);
      }
    }

    if (fieldName === formConstants.fields.dateTo) {
      let { dateFrom } = this.props;
      const dateTo = new Date(date).getTime();
      dateFrom = new Date(dateFrom).getTime();

      if (dateFrom > dateTo) {
        changeField(formConstants.fields.dateFrom, dateTo);
      }
    }

    const dateToSave = new Date(date);
    changeField(fieldName, dateToSave);
  };

  render() {
    const { t } = this.context;
    const { dateFrom, dateTo, predictiveTypes, predictiveTypeID, changeField, dateRange } = this.props;
    const selectedType = predictiveTypes ? predictiveTypes.find(el => el.value === predictiveTypeID) : null;

    return (
      <form className="predictive-settings-form horizontal-padding" noValidate>
        <h6 className="f-primary title">{t('NDT_PREDICTIVE_SETTINGS.PREDICTIVE_ANALYTICS')}</h6>
        <div className="form-wrapper">
          <div className="section">
            <Field
              id={formConstants.fields.displayPredictive}
              name={formConstants.fields.displayPredictive}
              component={ToggleField}
              label={t('NDT_PREDICTIVE_SETTINGS.FORM.DISPLAY_PREDICTIVE_ANALYTICS')}
              labelClass="f-primary"
            />
            <Field
              id={formConstants.fields.predictiveType}
              name={formConstants.fields.predictiveType}
              label="NDT_PREDICTIVE_SETTINGS.FORM.PREDICTIVE_TYPE"
              labelClass="f-primary"
              onChange={el => {
                changeField(FORMS.ndtPredictiveSettings, formConstants.fields.predictiveTypeID, el.value);
              }}
              data={predictiveTypes}
              defaultValue={selectedType ? { label: selectedType.label, value: selectedType.value } : null}
              component={CustomSelect}
              placeholder={t('NDT_PREDICTIVE_SETTINGS.FORM.PREDICTIVE_TYPE')}
            />
            <label className="description">
              {selectedType ? (
                <>
                  <strong>{t('NDT_PREDICTIVE_SETTINGS.FORM.PREDICTIVE_TYPE')}</strong>
                  {t('NDT_PREDICTIVE_SETTINGS.FORM.PREDICTIVE_TYPE_DESC')}
                </>
              ) : (
                ''
              )}
            </label>
            <p className="f-primary">{t('NDT_PREDICTIVE_SETTINGS.FORM.DATE_RANGE')}</p>
            <label className="description">
              <strong>{t('NDT_PREDICTIVE_SETTINGS.FORM.DATE_RANGE')}</strong> {t('NDT_PREDICTIVE_SETTINGS.FORM.DATE_RANGE_DESCRIPTION')}
            </label>
            {Object.keys(dateRangeOptions).map((key, index) => (
              <Field name={formConstants.fields.dateRange} component={RadioButton} value={key} type="radio" id={key} key={index} label={t(`NDT_PREDICTIVE_SETTINGS.DATE_RANGE.${key.toUpperCase()}`)} />
            ))}
            <div className={`predictive-settings-form__date-selector ${dateRange !== 'ALL' ? 'visible' : ''}`}>
              <div className="date-wrapper">
                <label className="f-secondary-dark">{t('NDT_PREDICTIVE_SETTINGS.FORM.DATE_FROM')}</label>
                <Field
                  selected={dateFrom}
                  disabledKeyboardNavigation={true}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  timeFormat="h:mm aa"
                  showTimeSelect
                  id={formConstants.fields.dateFrom}
                  name={formConstants.fields.dateFrom}
                  onChange={d => this.handleDateChange(d, formConstants.fields.dateFrom)}
                  component={DatePicker}
                />
              </div>
              <div className="date-wrapper">
                <label className="f-secondary-dark">{t('NDT_PREDICTIVE_SETTINGS.FORM.DATE_TO')}</label>
                <Field
                  disabledKeyboardNavigation={true}
                  onChange={d => this.handleDateChange(d, formConstants.fields.dateTo)}
                  selected={dateTo}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  timeFormat="h:mm aa"
                  showTimeSelect
                  id={formConstants.fields.dateTo}
                  name={formConstants.fields.dateTo}
                  component={DatePicker}
                />
              </div>
            </div>
          </div>
          <div className="section">
            <div className="toggle-card">
              <label className="f-secondary-green bold">{t('NDT_PREDICTIVE_SETTINGS.COMING_SOON')}</label>
              <Field
                id={formConstants.fields.displayPredictive}
                name={formConstants.fields.displayPredictive}
                disabled={true}
                component={ToggleField}
                label={t('NDT_PREDICTIVE_SETTINGS.FORM.REMOVE_OUTLIERS')}
                labelClass="f-primary"
              />
              <label className="description">
                <strong>{t('NDT_PREDICTIVE_SETTINGS.FORM.REMOVE_OUTLIERS')}</strong> {t('NDT_PREDICTIVE_SETTINGS.FORM.REMOVE_OUTLIERS_DESCRIPTION')}
              </label>
            </div>
            <div className="toggle-card">
              <label className="f-secondary-green bold">{t('NDT_PREDICTIVE_SETTINGS.COMING_SOON')}</label>
              <Field
                id={formConstants.fields.displayPredictive}
                name={formConstants.fields.displayPredictive}
                disabled={true}
                component={ToggleField}
                label={t('NDT_PREDICTIVE_SETTINGS.FORM.REMOVE_ZERO_VALUES')}
                labelClass="f-primary"
              />
              <label className="description">
                <strong>{t('NDT_PREDICTIVE_SETTINGS.FORM.REMOVE_ZERO_VALUES')}</strong> {t('NDT_PREDICTIVE_SETTINGS.FORM.REMOVE_ZERO_VALUES_DESCRIPTION')}
              </label>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

PredictiveSettingsForm.contextTypes = {
  t: PropTypes.func.isRequired,
};
PredictiveSettingsForm = reduxForm({
  form: FORMS.ndtPredictiveSettings,
  //   validate,
  enableReinitialize: true,
})(PredictiveSettingsForm);

export default PredictiveSettingsForm;
