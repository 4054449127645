import React, { Component } from 'react';
import Helpers from '../../helpers';
import PropTypes from 'prop-types';
import { ReactComponent as GreenArrowDown } from '../../../common/assets/arrow-down-green.svg';
import RenderIf from '../../render-if/components/render-if';
import '../styles/flow-status.scss';

class FlowStatus extends Component {
  render() {
    const { t } = this.context;
    const { Status, ChangedBy, ChangedAt, isLastItem, statuses } = this.props;
    const statusLabel = Object.values(statuses).find(status => status.value === Status).title;
    const changedAtUnix = Helpers.getDateFromUnix(ChangedAt);

    return (
      <div className="status-wrapper">
        <div className="status-wrapper__inner">
          <div className="status-wrapper__label">{t(statusLabel)}</div>
          <RenderIf if={!isLastItem}>
            <GreenArrowDown />
          </RenderIf>
        </div>
        <div className="status-wrapper__status">{t('FLOW_TAB.FLOW_USER_STATUS_CHANGE_AT', { user: ChangedBy, changedAt: changedAtUnix })}</div>
      </div>
    );
  }
}

FlowStatus.contextTypes = {
  t: PropTypes.func.isRequired,
};

FlowStatus.propTypes = {
  Status: PropTypes.string,
  ChangedBy: PropTypes.string,
  ChangedAt: PropTypes.number,
  isLastItem: PropTypes.bool,
};

FlowStatus.defaultProps = {
  Status: '',
  ChangedBy: '',
  ChangedAt: 1675430119,
  isLastItem: false,
};
export default FlowStatus;
