import { cloneDeep, filter, get, isEmpty, map } from 'lodash';

import potreeApi from '../../../../api/potree/actions';
import { notificationModalCustom } from '../../../../common/modal/actions/modal-actions';
import { setImageDrawings } from '../../../start-workflow/actions/action-creators';
import { createNewDefect, updateDefect } from '../../../start-workflow/actions/start-workflow-actions';
import { selectObjectTool } from '../../actions/action-creators';
import { setCurrentImage } from '../../actions/inspection-actions';
import { setActiveSlide, setPointImages, setPointImagesLoading, setPointImagesTake } from './action-creators';

import DrawingHelpers from '../../../../common/drawing-helpers';
import Helpers from '../../../../common/helpers';

import routesConstants, { routes } from '../../../../common/routes-constants';
import { fetchWorkflowImages } from '../../../inspection-workflow/actions/workflow-actions';
import inspectionWorkflowConstants from '../../../inspection-workflow/constants/inspection-workflow-constants';
import { detailsPages, modules } from '../../constants/constants';
import { formConstants } from '../../constants/defect-constants';

export const fetchImagesRelatedToPoint = async data => {
  if (!data.Type) {
    data = {
      ...data,
      Type: 'UAV',
    };
  }

  const res = await potreeApi.getPointImages(data);
  const Data = get(res, 'data.Data');

  if (Data) {
    let imageItems = Helpers.formatImageArray(Data);
    imageItems = imageItems.map((item, index) => ({
      ...item,
      ...Data[index],
      FileName: Data[index].Name,
      FileID: Data[index].ID,
      Drawings: DrawingHelpers.generateImagePointDrawing(Data[index].ImageResolution, Data[index].PointSelected),
    }));

    imageItems = Helpers.mapExternalFilesForModal(imageItems);
    return imageItems;
  } else return null;
};

export const getImagesForSelectedPoint = (
  inspectionId,
  projectId,
  data,
  currentImage,
  newDefect,
  isWorkflow,
  error = { message: '', title: '' },
  errorImage = { message: '', title: '' },
  callback = () => null
) => {
  return async dispatch => {
    try {
      const params = [{ project_id: projectId }, { inspection_id: inspectionId }];
      Helpers.goTo(routes.protectedRoutes.inspections.fullPath, params.concat([{ type: modules.details }, { details: detailsPages.inspect }]));

      dispatch(setPointImagesData([]));
      dispatch(setActiveSlide(0));
      dispatch(setPointImagesLoading(true));
      dispatch(setPointImagesTake(-1));

      const imageItems = await fetchImagesRelatedToPoint({ ...data, InspectionID: inspectionId });

      if (!isEmpty(imageItems)) {
        await dispatch(setPointImagesData(imageItems));
        if (isWorkflow) {
          dispatch(getImagesForSelectedPointSuccesWorkflow(imageItems, newDefect, currentImage, errorImage, callback));
        } else {
          dispatch(selectObjectTool(null));
          callback && callback(imageItems);
        }
        // when images are fetched set first image as initial current image
        dispatch(setCurrentImage(imageItems[0], 0, false));
        dispatch(setActiveSlide(0));

        dispatch(setPointImagesLoading(false));
      } else {
        if (error && error.message) {
          dispatch(notificationModalCustom(true, error.message, error.title));
        }
        callback && callback();
        dispatch(setPointImagesData([]));
        dispatch(setPointImagesLoading(false));
        dispatch(setPointImagesTake());
      }
    } catch (e) {
      console.log(e);
      dispatch(setPointImagesData([]));
      dispatch(setPointImagesLoading(false));
      dispatch(setPointImagesTake(-1));
    }
  };
};

export const getOtherImages = (inspectionID, projectID, selectionType, nonCfilter, includeLoader = true, callback) => {
  return async dispatch => {
    const params = [{ project_id: projectID }, { inspection_id: inspectionID }];
    Helpers.goTo(routes.protectedRoutes.inspections.fullPath, params.concat([{ type: modules.details }, { details: detailsPages.inspect }]));
    dispatch(setPointImagesData([]));
    dispatch(setActiveSlide(0));
    includeLoader && dispatch(setPointImagesLoading(true));
    const { images, filter } = await fetchWorkflowImages(inspectionID, selectionType, nonCfilter);
    callback &&
      callback(
        map(images, item => ({ ...item, FileID: item.ID })),
        filter
      );
    includeLoader && dispatch(setPointImagesLoading(false));
  };
};

export const setPointImagesData = images => {
  return async dispatch => {
    dispatch(setPointImages(images));
  };
};

export const loadMoreImages = (take, callback) => {
  return async dispatch => {
    dispatch(setPointImagesTake(take));
    callback && callback();
  };
};

export const getImagesForSelectedPointSuccesWorkflow = (imageItems, newDefect, currentImage, error, callback) => {
  return async dispatch => {
    const index = imageItems.findIndex(item => currentImage[inspectionWorkflowConstants.formConstants.fields.id] === item.ID);
    if (index > -1) {
      await dispatch(createNewDefect({ FileID: imageItems[index].FileID, ...newDefect, [formConstants.fields.drawings]: JSON.stringify(imageItems[index].Drawings) }));
      dispatch(setImageDrawings(imageItems[index].Drawings));
      callback();
    } else {
      dispatch(notificationModalCustom(true, error.message, error.title));
    }
  };
};

export const selectMultipleWorkflowImages = async (InspectionID, SelectedFiles) => {
  const res = await potreeApi.selectMultipleWorkflowImages({ InspectionID, SelectedFiles });
  return res;
};

export const selectImagesForWorkflow = (project_id, inspection_id, data) => {
  return async dispatch => {
    await selectMultipleWorkflowImages(inspection_id, data);
    Helpers.goTo(routesConstants.routes.protectedRoutes.inspectionWorkflow.fullPath, [{ project_id }, { inspection_id }]);
  };
};

export const get3DPointFrom2D = async (data, cameraHelper) => {
  const res = await potreeApi.image2DTo3D(data);
  const Data = get(res, 'data.Data');
  if (Data && !isEmpty(Data.PointFrom) && !isEmpty(Data.PointTo) && cameraHelper) {
    const pointFrom = new window.THREE.Vector3(Data.PointFrom.X, Data.PointFrom.Y, Data.PointFrom.Z);
    const pointTo = new window.THREE.Vector3(Data.PointTo.X, Data.PointTo.Y, Data.PointTo.Z);
    // const pointFrom = new window.THREE.Vector3(673267.460306, 5721571.751729, 125.387284);
    // const pointTo = new window.THREE.Vector3(673267.319, 5721572.15, 124.481819);
    const point = await cameraHelper.shootRayThroughPoint(pointFrom, pointTo);
    return point;
  }
  return null;
};

export const shootRaycastThroughImagePoint = (inspectionID, fileID, imagePosition, cameraHelper, depth, onSuccess, translations, Type) => {
  return async dispatch => {
    try {
      dispatch(setPointImagesLoading(true));
      dispatch(setPointImages([]));
      const point = await get3DPointFrom2D({ InspectionID: inspectionID, FileID: fileID, PointPosition: { X: imagePosition.left, Y: imagePosition.top, Z: 1 }, Depth: depth }, cameraHelper);

      if (!point) {
        return;
      }

      const error = { message: translations('ERROR_MESSAGE.MESSAGE.NO_IMAGES_FOR_SELECTED_POINT'), title: translations('ERROR_MESSAGE.TITLE.NO_IMAGES_FOR_SELECTED_POINT') };
      const imageItems = await fetchImagesRelatedToPoint({ InspectionID: inspectionID, Type, PointPosition: { X: point.x, Y: point.y, Z: point.z } });
      if (imageItems) {
        // Remove image that is being inspected
        const filteredImages = filter(imageItems, item => item.id !== fileID);
        await dispatch(setPointImages(filteredImages));
        // SUCCESS OPEN MODAL
        onSuccess(imageItems);
      } else {
        dispatch(notificationModalCustom(true, error.message, error.title));
        dispatch(setPointImages([]));
      }
      dispatch(setPointImagesLoading(false));
    } catch (e) {
      dispatch(setPointImagesLoading(false));
      console.log(e);
    }
  };
};

export const updateDefectPositionFromImage = (inspectionID, fileID, imagePosition, depth, cameraHelper, selectedDefect, callback) => {
  return async dispatch => {
    try {
      if (!imagePosition || !imagePosition.left || !imagePosition.top) return;
      const point = await get3DPointFrom2D({ InspectionID: inspectionID, FileID: fileID, PointPosition: { X: imagePosition.left, Y: imagePosition.top, Z: 1 }, Depth: depth }, cameraHelper);
      if (!point || isEmpty(selectedDefect)) return;

      let updatedDefect = cloneDeep(selectedDefect);
      updatedDefect.Geometry.coordinates = [[point.x, point.y, point.z]];
      if (updatedDefect.ID < 0) {
        //update temp defect
        dispatch(updateDefect(updatedDefect, updatedDefect.ID < 0));
      }
      callback(updatedDefect);
    } catch (e) {
      console.log(e);
    }
  };
};
