import routesConstants from './routes';
import { Api } from '../../core/api';
import { API_BASE_URL } from '../../common/constants';
import { generateQueryParams } from '../helpers/helper';

const getProjects = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_PROJECTS}`, data);
};

const archiveProject = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ARCHIVE_PROJECT}`, data);
};

const deleteProject = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_PROJECT}`, data);
};

const deleteInspection = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_INSPECTION}`, data);
};

const getProjectDetails = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_PROJECT_DETAILS}${generateQueryParams(params)}`);
};
export const fetchDMSCategories = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_DMS_CATEGORIES}`, data);
};

export default {
  getProjects,
  archiveProject,
  deleteProject,
  deleteInspection,
  getProjectDetails,
  fetchDMSCategories,
};
