import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { reduxForm, Field } from 'redux-form';

import { debounce, keys } from 'lodash';

import MultiSelect from '../../../../../common/form/components/multiselect';
import Button from '../../../../../common/form/components/button';
import DatePicker from '../../../../../common/form/components/date-picker';

import { getUsers } from '../../../../members/actions/users-data-actions';

import Helpers from '../../../../../common/helpers';

import { FORMS } from '../../../../../common/constants';

import { filterParams } from '../../../constants/measurement-constants';

import '../../../styles/defects-filter.scss';

class MeasurementsFilters extends Component {
  constructor(props) {
    super(props);
    this.getUsersDebounced = debounce(props.getUsers, 300);
  }
  componentDidMount = () => {
    const { getUsers } = this.props;
    getUsers();
  };

  render() {
    const { t } = this.context;
    const { handleSubmit, resetFilter, users, formValues, submitForm } = this.props;

    return (
      <form
        className="filter-form"
        onSubmit={handleSubmit(vals => {
          submitForm(Helpers.removeUnusedKeys(vals));
        })}
      >
        <div className="scrollable">
          <div className="grid">
            <Field
              label={'MEASUREMENTS_FILTERS.CREATED_BY'}
              placeholder={'MEASUREMENTS_FILTERS.CREATED_BY'}
              id={filterParams.createdByFilter}
              name={filterParams.createdByFilter}
              component={MultiSelect}
              data={users || []}
              valueField={'UserID'}
              textField={'UserName'}
              onSearch={text => {
                this.getUsersDebounced(text);
              }}
              onToggle={isOpen => {
                if (isOpen && (!users || users.length === 0)) {
                  this.getUsersDebounced('');
                }
              }}
            />
          </div>
          <div className="grid">
            <Field
              disabledKeyboardNavigation={true}
              dateFormat="MMMM d, yyyy"
              id={filterParams.dateFrom}
              name={filterParams.dateFrom}
              component={DatePicker}
              maxDate={formValues?.[filterParams.dateTo] || null}
              popperPlacement="bottom-end"
              placeholder={'MEASUREMENTS_FILTERS.DATE_FROM'}
              label={'MEASUREMENTS_FILTERS.DATE_FROM'}
              isClearable={true}
            />
            <Field
              disabledKeyboardNavigation={true}
              dateFormat="MMMM d, yyyy"
              id={filterParams.dateTo}
              name={filterParams.dateTo}
              component={DatePicker}
              minDate={formValues?.[filterParams.dateFrom] || null}
              popperPlacement="bottom-end"
              placeholder={'MEASUREMENTS_FILTERS.DATE_TO'}
              label={'MEASUREMENTS_FILTERS.DATE_TO'}
              isClearable={true}
            />
          </div>
        </div>
        <div className="buttons">
          <Button type="button" variant="gray-outline" text={t('RESET_FILTERS')} onClick={resetFilter} />
          <Button text={t('APPLY_FILTERS', { count: keys(Helpers.removeUnusedKeys(formValues)).length || 0 })} />
        </div>
      </form>
    );
  }
}
MeasurementsFilters.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  getUsers: SearchText => dispatch(getUsers({ SearchText })),
});

const mapStateToProps = (state, { initialValues }) => {
  const {
    usersTableReducer: { usersTableData },
  } = state;

  return {
    users: usersTableData,
    formValues: state.form[FORMS.measurementsFilters]?.values || {},
  };
};

MeasurementsFilters = reduxForm({
  form: FORMS.measurementsFilters,
  enableReinitialize: true,
})(MeasurementsFilters);

export default connect(mapStateToProps, mapDispatchToProps)(MeasurementsFilters);
