import React, { Component } from 'react';
import PropTypes from 'prop-types';

import WorkOrderItem from './work-order-item';
import ExpandableSection from '../../../../../common/expandable-section/components/expandable-section';
import SectionEmptyState from '../../right-toolbar/common/section-empty-state';
import Icon from '../../../../../common/icon/components/icon';
import { fields, initialValuesProp } from '../constants/work-order-constants';

import '../../../styles/work-orders-section.scss';

class PermitWorkOrders extends Component {
  render() {
    const { t } = this.context;
    const { module } = this.props;
    const workOrders = module[initialValuesProp];

    if (!workOrders || !workOrders.length) {
      return <SectionEmptyState title="PERMIT_WORK_ORDERS.EMPTY_STATE" />;
    }

    return (
      <div className="work-orders-section">
        <p className="f-secondary-dark">{t('PERMIT_WORK_ORDERS.ADDED_WORK_ORDERS', { number: workOrders && workOrders.length > 0 ? workOrders.length : 0 })}</p>
        {workOrders && workOrders.length > 0 && (
          <>
            {workOrders.map((workOrder, index) => (
              <div className="component-item-header" key={`section-component-item-${index}`}>
                <ExpandableSection
                  CustomComponent={({ isExpanded }) => (
                    <div className="component-header">
                      <h6 className="f-primary component-header-title">{`${workOrder[fields.name.name]}`}</h6>
                      <Icon name={isExpanded ? 'chevron-up' : 'chevron-down'} className="component-header-toggle" />
                    </div>
                  )}
                  className="expanded-section"
                  customExpanded={workOrder.isExpanded}
                  hideExpandAction
                >
                  <WorkOrderItem component={workOrder} componentIndex={index} fields={fields} />
                </ExpandableSection>
              </div>
            ))}
          </>
        )}
      </div>
    );
  }
}

PermitWorkOrders.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default PermitWorkOrders;
