import { debounce, get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import TableTemplate from '../../../../../../../common/double-table/components/table-template';
import Helpers from '../../../../../../../common/helpers';
import routesConstants from '../../../../../../../common/routes-constants';
import { getMeasurementPointObservations } from '../../../../readings-and-gauges/actions/measurement-point-actions';

import { fields } from '../../../../readings-and-gauges/constants/constants';
import { formatObservationsTableCells } from '../../actions/table-formatting';
import { defaultFilters, filterProps, observationsTableConfig, sortDirection } from '../../constants/measurement-point-obeservations-constant';

import EmptyState from '../../../../../../../common/empty-state/components/empty-state';
import { PERMISSION_TYPES, PERMISSIONS } from '../../../../../../../common/permissions-constants';
import RenderIf from '../../../../../../../common/render-if/components/render-if';
import { modules } from '../../../../../constants/constants';
import '../../styles/measurement-point-observations-tab.scss';

const MeasurementPointObservationsTab = ({ location, getMeasurementPointObservations, user, selectedComponent }, { t }) => {
  const measurementPointID = parseInt(selectedComponent[fields.id]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState({ MeasurementPointID: measurementPointID, ...defaultFilters });

  const userHasAccess = Helpers.hasAccess({ user, visibleFor: PERMISSIONS[PERMISSION_TYPES.observations].view.name });

  const fetchObservations = (filters, loadMore = false) => {
    if (!measurementPointID) return;

    const onSuccess = (items, newFilters) => {
      setLoading(false);
      setData(loadMore ? [...data, ...items] : items);
      setFilters(prev => ({ ...prev, ...newFilters }));
      if (loadMore) {
        Helpers.scrollIntoView('observations-table', `row-${filters[filterProps.lastSeen]}`, -525);
      }
    };

    getMeasurementPointObservations(filters, onSuccess, setFilters, null, setLoading);
  };

  useEffect(() => {
    userHasAccess && fetchObservations(filters);
    //eslint-disable-next-line
  }, []);

  const isFetchObservationsValid = fetchObservations && typeof fetchObservations === 'function';

  const onLoadMoreClick = () => {
    filters[filterProps.lastSeen] = data.length;
    isFetchObservationsValid && fetchObservations(filters, true);
  };

  const onSearch = SearchText => {
    const newFilters = {
      ...filters,
      [filterProps.lastSeen]: 0,
      SearchText,
    };
    isFetchObservationsValid && fetchObservations(newFilters);
  };

  const handleSearchInput = e => {
    setSearchText(e.target.value);
    searchTextChanged(e.target.value);
  };

  const searchTextChanged = debounce(onSearch, 300);

  const onSort = (SortByColumn = {}) => {
    const newFilters = {
      ...filters,
      [filterProps.sortByColumn]: SortByColumn,
      [filterProps.sortDirection]: filters[filterProps.sortDirection] === sortDirection.asc ? sortDirection.desc : sortDirection.asc,
      [filterProps.lastSeen]: 0,
    };
    isFetchObservationsValid && fetchObservations(newFilters);
  };

  const handleOnRowClick = row => {
    if (!row.ID) return;
    const path = user?.FullScreenEnabled ? routesConstants.routes.protectedRoutes.fullScreen.fullPath : routesConstants.routes.protectedRoutes.inspections.fullPath;
    const { query } = location;
    const inspectionId = get(query, 'inspection_id');
    const projectId = get(query, 'project_id');

    Helpers.goTo(path, [{ project_id: projectId }, { inspection_id: inspectionId }, { type: modules.defects }, { selected_item: row[fields.id] }]);
  };

  return (
    <div className="mp-observations-tab">
      <RenderIf if={userHasAccess}>
        <TableTemplate
          tableConfig={observationsTableConfig}
          filters={filters}
          data={data}
          onSortClick={onSort}
          loadMoreOnClick={onLoadMoreClick}
          handleFormatCells={formatObservationsTableCells}
          isLoading={loading}
          selectedItem={null}
          TotalItems={filters[filterProps.totalItems]}
          searchPlaceholder={t('SEARCH')}
          sortDirection={filterProps.sortDirection}
          sortByColumn={filterProps.sortByColumn}
          resultsText={'COMPONENT_OBSERVATIONS.TOTAL'}
          searchInputOnChange={handleSearchInput}
          searchInputValue={searchText}
          hasNext={filters[filterProps.hasNext]}
          noDataText={t('OBSERVATION_SEARCH.EMPTY_STATE')}
          customTableClass={'observations-table'}
          onRowClick={handleOnRowClick}
        />
      </RenderIf>
      <RenderIf if={!userHasAccess}>
        <EmptyState icon="crossed-out-circle" iconSize="xs" message="COMPONENTS_TAB.NO_PERMISSION" title="COMPONENTS_TAB.NO_PERMISSION_TITLE" />
      </RenderIf>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  getMeasurementPointObservations: (filters, callback, filtersCallback, errorCallback, loadingCallback) =>
    dispatch(getMeasurementPointObservations(filters, callback, filtersCallback, errorCallback, loadingCallback)),
});

const mapStateToProps = state => ({
  user: state.userReducer,
});

MeasurementPointObservationsTab.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MeasurementPointObservationsTab));
