import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';

import Helpers from '../../../common/helpers';
import { isEmpty } from 'lodash';

import '../styles/image-slider.scss';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import inspectionWorkflowConstants from '../../../app/inspection-workflow/constants/inspection-workflow-constants';

class CustomSlider extends Component {
  renderItem = item => {
    return (
      <div className="image-gallery-image">
        <img src={item.src} alt={item[inspectionWorkflowConstants.formConstants.fields.name]} />

        <div className="custom-overlay">
          <p className="f-primary"> {item[inspectionWorkflowConstants.formConstants.fields.resolution] || 'some resolution'}</p>
          <p className="f-primary"> {item[inspectionWorkflowConstants.formConstants.fields.name]}</p>
          <p className="f-primary"> {Helpers.getDateFromUnixMs(item[inspectionWorkflowConstants.formConstants.fields.createdAt])} </p>
        </div>
      </div>
    );
  };

  renderLeftNav = onClick => {
    const { t } = this.context;
    const {
      items,
      forwardRef: { current },
    } = this.props;
    if (!current) return;
    return (
      <div className="arrow-left">
        <p className="f-primary prev" onClick={onClick}>
          {t('SLIDER.PREV')}
        </p>
        <div className="count">
          <p className="f-primary"> {`${current.getCurrentIndex() + 1}/${items.length}`}</p>
        </div>
      </div>
    );
  };

  renderRightNav = onClick => {
    const { t } = this.context;
    return (
      <div className="arrow-right">
        <p className="f-primary next" onClick={onClick}>
          {t('SLIDER.NEXT')}
        </p>
      </div>
    );
  };

  render() {
    const { t } = this.context;
    const { items, CustomEmptyState, forwardRef, CustomComponent, highlightSlider } = this.props;

    return (
      <div className={`slider ${highlightSlider ? 'highlighted' : ''}`}>
        <ImageGallery ref={forwardRef} lazyLoad={true} {...this} {...this.props} />
        {CustomComponent && <CustomComponent />}
        {isEmpty(items) && CustomEmptyState && <CustomEmptyState />}
        {isEmpty(items) && !CustomEmptyState && (
          <div className="galery-empty">
            <p className="f-secondary-dark"> {t('SLIDER.IMAGE_PREVIEW')}</p>
            <p className="f-secondary-dark">{t('SLIDER.SELECT_IMAGES')}</p>
          </div>
        )}
      </div>
    );
  }
}

CustomSlider.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default React.forwardRef((props, ref) => {
  return <CustomSlider forwardRef={ref} {...props} />;
});
