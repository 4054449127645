import React, { useEffect, useRef } from 'react';
import Icon from '../../icon/components/icon';

const AggregationLabelIcon = ({ aggregationName, color }) => {
  const iconsMap = {
    Median: 'med',
    Average: 'avg',
    'Min/Max': 'max',
    Min: 'min',
    Max: 'max',
  };
  const iconRef = useRef();

  useEffect(() => {
    if (iconRef.current) {
      const pathElements = iconRef.current.getElementsByTagName('path');
      for (const pathEl of pathElements) {
        if (pathEl) {
          pathEl.style.stroke = color;
        }
      }
    }
  }, [color]);

  return iconsMap[aggregationName] && <Icon ref={iconRef} name={iconsMap[aggregationName]} size="xs" handleHover={false} className={`aggregation-label-icon`} />;
};
export default AggregationLabelIcon;
