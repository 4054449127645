import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { remove } from 'lodash';
import RenderIf from '../../../../../common/render-if/components/render-if';
import Loader from '../../../../../common/global-loader/components/simple-loader';
import Icon from '../../../../../common/icon/components/icon';
import PropTypes from 'prop-types';
import { filterSections } from '../../../constants/constants';
import '../../../styles/dms-labels.scss';

//if becomes more used we should move this to common

const DMSLabelsFilter = (props, { t }) => {
  const { labels, labelsLoading, isAdjustedForSmallerScreen, isLoading, value, quickFiltersChange } = props;

  //state
  const [isExpanded, setIsExpanded] = useState(false);

  const isRegularView = !isAdjustedForSmallerScreen || (isAdjustedForSmallerScreen && isExpanded);

  const handleQuickFilter = values => {
    quickFiltersChange({ [filterSections.labels.valueKey]: values });
  };
  const toggleLabelInFilter = ID => {
    const newValue = Object.assign([], value);
    if (newValue.includes(ID)) {
      remove(newValue, itemID => itemID === ID);
    } else {
      newValue.push(ID);
    }
    handleQuickFilter(newValue);
  };

  const renderLabels = () => {
    return labels.map(item => {
      return (
        <div
          key={`dms-labels-item-${item.Name}`}
          className={`dms-labels__wrapper__item ${isLoading ? 'disabled' : ''} ${value.includes(item.ID) ? 'dms-labels__wrapper__item-active' : ''}`}
          onClick={() => toggleLabelInFilter(item.ID)}
        >
          {item.Colour && item.Colour !== '' && <div className="dms-labels__wrapper__item__color" style={{ backgroundColor: item.Colour }} />}
          <p className={`dms-labels__wrapper__item__text f-primary light-bold`}>{item.Name}</p>
        </div>
      );
    });
  };

  return (
    <div>
      <RenderIf if={labelsLoading}>
        <Loader isLoading={labelsLoading} />
      </RenderIf>
      <RenderIf if={!labelsLoading}>
        <div className={`dms-labels ${isRegularView ? 'dms-labels-expanded' : ''}`}>
          <div className={`dms-labels__wrapper ${isRegularView ? 'dms-labels-expanded__wrapper' : ''}`}>
            {renderLabels()}
            <RenderIf if={isExpanded}>
              <div className="dms-labels__more-wrapper dms-labels__more-wrapper-adjusted" onClick={() => setIsExpanded(false)}>
                <Icon name="minus" />
                <p className="f-primary light-bold">{t('STATUS_FILTERS.LESS')}</p>
              </div>
            </RenderIf>
          </div>

          {isAdjustedForSmallerScreen && (
            <RenderIf if={!isExpanded}>
              <div className="dms-labels__more-wrapper" onClick={() => setIsExpanded(true)}>
                <Icon name="plus" />
                <p className="f-primary light-bold">{t('STATUS_FILTERS.MORE')}</p>
              </div>
            </RenderIf>
          )}
        </div>
      </RenderIf>
    </div>
  );
};

DMSLabelsFilter.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default withRouter(connect(null, null)(DMSLabelsFilter));
