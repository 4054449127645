import React from 'react';
import PropTypes from 'prop-types';

import { isEmpty } from 'lodash';

import { userTeamFields } from '../constants/constants';
import '../styles/contributors-list.scss';
import EmptyState from '../../empty-state-v2/components/empty-state';
import { filterFields } from '../../../app/inspections/constants/work-order-constants';

const ContributorsList = ({ contributors, handleCustomAction, sectionAction, disabled, emptyStateText, buttonText }, { t }) => {
  if (!contributors || (isEmpty(contributors[filterFields.collaborators]) && isEmpty(contributors[filterFields.assignees]) && isEmpty(contributors[filterFields.contractors]))) {
    return (
      <EmptyState emptyStateText={t(emptyStateText)} buttonText={t(buttonText)} buttonAction={() => handleCustomAction(sectionAction)} showButton={!disabled} buttonDisabled={disabled} transparent />
    );
  }

  return (
    contributors && (
      <div className="contributors-list">
        {contributors[filterFields.assignees] && (
          <div className="assignee-list">
            <label>{t('CONTRIBUTORS_LIST.ASSIGNEE_LABEL_TEXT')}</label>
            {contributors[filterFields.assignees].map(item => (
              <div className="assignee-item" key={`work-order-assignee-${item[userTeamFields.id]}`}>
                <p className="f-primary light-bold component-name">{item[userTeamFields.name]}</p>
              </div>
            ))}
          </div>
        )}

        {contributors[filterFields.collaborators] && (
          <div className="collaborator-list">
            <label>{t('CONTRIBUTORS_LIST.COLLABORATORS_LABEL_TEXT')}</label>
            {contributors[filterFields.collaborators].map(item => (
              <div className="assignee-item" key={`work-order-collaborator-${item[userTeamFields.id]}`}>
                <p className="f-primary light-bold component-name">{item[userTeamFields.name]}</p>
              </div>
            ))}
          </div>
        )}
        {contributors[filterFields.contractors] && (
          <div className="contractor-list">
            <label>{t('WORK_ORDER.CONTRACTORS_SECTION')}</label>
            {contributors[filterFields.contractors].map(item => (
              <div className="assignee-item" key={`work-order-contractor-${item[userTeamFields.id]}`}>
                <p className="f-primary light-bold component-name">{item[userTeamFields.name]}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    )
  );
};

ContributorsList.contextTypes = {
  t: PropTypes.func.isRequired,
};

ContributorsList.defaultProps = {
  emptyStateText: 'OBSERVATIONS_FORM.NO_ASSIGNEES',
  buttonText: 'WORK_ORDER.ADD_ASSIGNEES',
};

export default ContributorsList;
