import React from 'react';

import '../styles/main-wrapper.scss';

const RegisterWrapper = ({ children }) => {
  return (
    <div className="main-wrapper">
      <div className="register-container">{children}</div>
    </div>
  );
};

export default RegisterWrapper;
