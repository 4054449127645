import { isBoolean } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Icon from '../../icon/components/icon';
import '../styles/expandable-section.scss';

class ExpandableSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: props.initiallyExpanded || props.expanded || false,
    };

    this.node = React.createRef();
  }

  handleClick = e => {
    const { expanded } = this.state;
    const { onExpand } = this.props;

    this.setState({ expanded: !expanded });
    onExpand && onExpand(!expanded);
  };

  render() {
    const { expanded } = this.state;
    const { title, titleIcon, children, handlePreventClick, customExpanded, CustomComponent, className, hideExpandAction, customComponentProps, hideEditAction, onEditClick } = this.props;

    let expandedVar = isBoolean(customExpanded) ? customExpanded : expanded;

    return (
      <div className={`expandable-section ${expandedVar ? 'open' : ''} ${className || ''}`} data-cy="expandable-section">
        <div className="header" onClick={handlePreventClick ? handlePreventClick : this.handleClick}>
          <div className="header__wrapper">
            <div className="header__wrapper__title">
              <h5 className="header__title f-primary">{title}</h5>
              {titleIcon}
            </div>
            {CustomComponent && <CustomComponent {...customComponentProps} isExpanded={expandedVar} />}
            <div className="header__actions">
              {!hideEditAction && <Icon size="sm" name="pen" onClick={onEditClick} />}
              {!hideExpandAction && <>{expandedVar ? <Icon size="md" name="chevron-up" /> : <Icon size="md" name="chevron-down" />}</>}
            </div>
          </div>
        </div>
        <div className={`section-content section-content--${expandedVar ? 'visible' : 'hidden'}`}>{children}</div>
      </div>
    );
  }
}

ExpandableSection.propTypes = {
  title: PropTypes.string,
  handlePreventClick: PropTypes.func,
  customExpanded: PropTypes.bool,
  CustomComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.elementType]),
  className: PropTypes.string,
  hideExpandAction: PropTypes.bool,
  customComponentProps: PropTypes.object,
  onExpand: PropTypes.func,
  titleIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.elementType]),
};

ExpandableSection.defaultProps = {
  title: '',
  className: '',
  hideExpandAction: false,
  hideEditAction: true,
  customComponentProps: {},
  onExpand: () => null,
  titleIcon: null,
};

export default ExpandableSection;
