import routesConstants from './routes';
import { Api } from '../../core/api';
import { API_BASE_URL } from '../../common/constants';
import { generateQueryParams } from '../helpers/helper';

const downloadReport = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DOWNLOAD_REPORT}`, data, { responseType: 'blob' });
};

const fetchDownloadReportHistory = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.DOWNLOAD_REPORT_HISTORY}${generateQueryParams(params)}`, { needLoader: true });
};

const fetchPreferences = async () => {
  return await Api.get(`${API_BASE_URL}${routesConstants.DOWNLOAD_REPORT_PREFERENCES}`, { needLoader: true });
};
const removeCoverImage = async () => {
  return await Api.get(`${API_BASE_URL}${routesConstants.REMOVE_COVER_IMAGE}`);
};

export default {
  downloadReport,
  fetchDownloadReportHistory,
  fetchPreferences,
  removeCoverImage,
};
