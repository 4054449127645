import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import MultipleEmailsInput from '../../../app/inspections/components/common/input/multiple-emilas-input';
import Button from '../../form/components/button';
import ToggleField from '../../form/components/toggle-field';
import InspectionView from '../../../app/inspections/potree/components/main-view';
import InspectionRenderer from '../../../app/inspections/components/left-toolbar/inspection-renderer';

import { FORMS } from '../../constants';

import constants from '../constants/download-report-constants';

class DownloadDefectReportForm extends Component {
  constructor(props) {
    super(props);
    this.parentRef = React.createRef();
  }

  render() {
    const { t } = this.context;
    const { handleSubmit, nextStep, previousStep, step, createViewerInstance, viewer, potreeId, defects, openEmailModal, invalid, emails, preventSubmit } = this.props;

    return (
      <form className="download-report-form" onSubmit={handleSubmit ? handleSubmit : null}>
        {step === constants.steps.firstStep && (
          <Fragment>
            <Field
              id={constants.fields.imagesPerPage}
              name={constants.fields.imagesPerPage}
              component={ToggleField}
              label={t('DOWNLOAD_REPORT.IMAGES_PER_PAGE')}
              sublabel={t('DOWNLOAD_REPORT.IMAGES_PER_PAGE_SUBLABEL')}
              className={'multiple-labels-toggle'}
              rightValue={constants.imagesPerPageValues.rightValue}
              leftValue={constants.imagesPerPageValues.leftValue}
            />
            <label className="email-explanation">{t('DOWNLOAD_REPORT.EMAIL_EXPLANATION')}</label>
            <Field
              id={constants.fields.emailList}
              name={constants.fields.emailList}
              label={t('NDT_ALARMS_MODAL.FORM_LABELS_EMAIL')}
              labelClass="f-secondary-dark"
              component={MultipleEmailsInput}
              type="text"
              emails={emails}
              onClick={openEmailModal}
            />
          </Fragment>
        )}
        {step === constants.steps.secondStep && (
          <Fragment>
            <p className="f-secondary-dark screenshot-description">{t('DOWNLOAD_REPORT.INSPECTION_VIEW_DESCRIPTION')}</p>
            <InspectionView parentRef={this.parentRef} isWorkflow={false} potreeId={potreeId} createViewerInstance={createViewerInstance} viewer={viewer} showCameras={false} />
            {viewer && (
              <InspectionRenderer
                deselectAll={() => null}
                selectAll={() => null}
                toggleElement={() => null}
                deselectAllTemp={() => null}
                selectAllTemp={() => null}
                toggleElementTemp={() => null}
                selectElement={() => null}
                elements={(defects || []).map(el => ({ ...el, variant: 1 }))}
                disableMove={true}
                viewer={viewer}
              >
                {() => null}
              </InspectionRenderer>
            )}
          </Fragment>
        )}

        {!preventSubmit && (
          <div className="multiple-buttons">
            <Button type="button" disabled={!previousStep} width="sm" text={t('DOWNLOAD_REPORT.PREVIOUS_STEP')} onClick={previousStep} />
            <Button type="button" disabled={!nextStep || invalid} width="sm" text={t('DOWNLOAD_REPORT.NEXT_STEP')} onClick={nextStep} />
          </div>
        )}
        {preventSubmit && (
          <div className="multiple-buttons">
            <Button type="button" disabled={!previousStep} width="sm" text={t('DOWNLOAD_REPORT.PREVIOUS_STEP')} onClick={previousStep} />
            <Button width="sm" text={t('DOWNLOAD_REPORT.GENERATE_BUTTON')} />
          </div>
        )}
      </form>
    );
  }
}
DownloadDefectReportForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

DownloadDefectReportForm = reduxForm({
  form: FORMS.dowloadDefectReport,
  enableReinitialize: true,
})(DownloadDefectReportForm);

export default DownloadDefectReportForm;
