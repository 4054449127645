import PropTypes from 'prop-types';
import React from 'react';
import ToggleButton from 'react-switch';
import { Field } from 'redux-form';
import '../styles/toggle.scss';
import RadioButton from './radio-button';

const ToggleRadioInput = ({ input = {}, inputProps, meta = {}, id, label, labelClass, disabled, isHidden, sublabel, className, inline = true, isRequired }, { t }) => {
  const { name, value } = input;

  return (
    <div className={`toggle-radio-input-field-wrapper field-wrapper ${inline ? 'inline' : ''} ${disabled ? 'field-wrapper--disabled' : ''} ${isHidden ? 'hidden-field' : ''} ${className} `}>
      <div className="input-wrapper">
        <ToggleButton
          className={`custom-toggle${input.value ? '' : ' unchecked'}`}
          width={36}
          height={20}
          handleDiameter={21}
          onChange={val => {
            if (disabled) {
              return;
            }
            input?.onChange?.(val);
            inputProps?.onChange?.(val);
          }}
          checked={input.value || false}
          checkedIcon={<div />}
          uncheckedIcon={<div />}
        />
        {(label || sublabel) && (
          <div className="labels">
            {label && (
              <label className={`field-wrapper__label ${labelClass || ''}`} htmlFor={id}>
                {`${label}${isRequired ? '*' : ''}`}
              </label>
            )}
          </div>
        )}
        <div className={`radio-buttons-container ${value !== '' && value ? 'highlight-labels' : ''}`}>
          <div className="grid">
            {/* value === true check is to set it to true by default if toggle is true || 'YES' which is handled in the transformation of filter} */}
            <Field name={name} component={RadioButton} value={value === true || 'YES'} type="radio" id={id} label={t('YES_CAPITALIZED')} disabled={value === ''} />
            <Field name={name} component={RadioButton} value={'NO'} type="radio" id={id} label={t('NO_CAPITALIZED')} disabled={value === ''} />
          </div>
        </div>
      </div>
      <div className={`error-message${meta.error && meta.touched ? '--visible' : ''}`}>{meta.error && t(meta.error)}</div>
    </div>
  );
};

ToggleRadioInput.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ToggleRadioInput;
