import { isArray, isEmpty } from 'lodash';
import Helpers from '../../../../../common/helpers';
import { filterFields } from '../../../constants/work-order-constants';

class WorkOrderHelpers {
  static isOverdue = (startDate, dueDate) => {
    const dateToCompare = new Date(dueDate || startDate).getTime();
    const today = new Date().getTime();

    return Helpers.getUnixDate(dateToCompare) < Helpers.getUnixDate(today);
  };
}

export default WorkOrderHelpers;

// statuses helpers (re-used on Work Orders module and PM Tasks tab on Maintenance regimes)
export const getSelectedStatuses = filters => {
  let result;
  if (isArray(filters[filterFields.statusFilter])) {
    result = !isEmpty(filters[filterFields.statusFilter]) ? filters[filterFields.statusFilter].toString().split(',') : [];
  } else result = filters[filterFields.statusFilter] ? filters[filterFields.statusFilter].split(',') : [];

  if (filters[filterFields.assignedToMeFilter]) {
    result.push(filterFields.assignedToMeFilter);
  }

  if (filters[filterFields.startInNextWeekFilter]) {
    result.push(filterFields.startInNextWeekFilter);
  }

  if (filters[filterFields.startInThirtyDaysFilter]) {
    result.push(filterFields.startInThirtyDaysFilter);
  }

  if (filters[filterFields.onHold]) {
    result.push(filterFields.onHold);
  }

  if (filters[filterFields.archived]) {
    result.push(filterFields.archived);
  }

  if (filters[filterFields.overdueFilter]) {
    result.push(filterFields.overdueFilter);
  }

  if (filters[filterFields.linkedToMe]) {
    result.push(filterFields.linkedToMe);
  }

  return result;
};
