import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { reduxForm, Field, FieldArray } from 'redux-form';
import { debounce, keys } from 'lodash';

import MultiSelect from '../../../common/form/components/multiselect';
import AdvancedFilterCustomPropertyField from '../../../common/advanced-filter-custom-property/components/advanced-filter-custom-property';
import Button from '../../../common/form/components/button';

import { getUsers } from '../../members/actions/users-data-actions';
import { getChecklistProceduresPropertyNames } from '../actions/checklists-procedures-management-actions';

import Helpers from '../../../common/helpers';

import { FORMS } from '../../../common/constants';
import { filterProps } from '../constants/checklists-procedures-constants';

import '../../inspections/styles/defects-filter.scss';

let ChecklistsProceduresFilter = ({ handleSubmit, resetFilter, users, formValues, submitForm, getUsers, getChecklistProceduresPropertyNames, projectId }, { t }) => {
  const getUsersDebounced = debounce(getUsers, 300);

  useEffect(() => {
    getUsers();
    //eslint-disable-next-line
  }, []);

  return (
    <form
      className="filter-form"
      onSubmit={handleSubmit(vals => {
        submitForm(Helpers.removeUnusedKeys(vals));
      })}
    >
      <div className="scrollable">
        <div className="grid">
          <Field
            label={'CHECKLISTS_PROCEDURES_FILTERS.CREATED_BY'}
            placeholder={'CHECKLISTS_PROCEDURES_FILTERS.CREATED_BY'}
            id={filterProps.createdByFilter}
            name={filterProps.createdByFilter}
            component={MultiSelect}
            data={users || []}
            valueField={'UserID'}
            textField={'UserName'}
            onSearch={text => {
              getUsersDebounced(text);
            }}
            onToggle={isOpen => {
              if (isOpen && (!users || users.length === 0)) {
                getUsersDebounced('');
              }
            }}
            size={'lg'}
          />
        </div>
        <FieldArray
          name={filterProps.properties}
          component={AdvancedFilterCustomPropertyField}
          fetchCustomProps={(searchText, callback) => getChecklistProceduresPropertyNames(searchText, projectId, callback)}
          form={FORMS.checklistsProceduresFilters}
          formValues={formValues}
        />
      </div>
      <div className="buttons">
        <Button type="button" variant="gray-outline" height={'md'} width={'sm'} text={t('RESET_FILTERS')} onClick={resetFilter} />
        <Button height={'md'} width={'sm'} text={t('APPLY_FILTERS', { count: keys(Helpers.removeUnusedKeys(formValues)).length || 0 })} />
      </div>
    </form>
  );
};

ChecklistsProceduresFilter.contextTypes = {
  t: PropTypes.func.isRequired,
};

ChecklistsProceduresFilter.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired,
  users: PropTypes.array,
  formValues: PropTypes.object,
  submitForm: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  projectId: PropTypes.number.isRequired,
};

const mapStateToProps = (state, { initialValues }) => {
  const {
    usersTableReducer: { usersTableData },
  } = state;

  return {
    users: usersTableData,
    initialValues,
    formValues: state.form[FORMS.checklistsProceduresFilters]?.values || {},
  };
};

const mapDispatchToProps = dispatch => ({
  getUsers: SearchText => dispatch(getUsers({ SearchText })),
  getChecklistProceduresPropertyNames: (searchText, projectId, callback) => dispatch(getChecklistProceduresPropertyNames(searchText, projectId, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: FORMS.checklistsProceduresFilters,
    enableReinitialize: true,
  })(ChecklistsProceduresFilter)
);
