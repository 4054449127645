import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { isEmpty } from 'lodash';
import ExpandableSection from '../../../../common/expandable-section/components/expandable-section';

import Modal from '../../../../common/modal/components/modal';
import NdtModal from './ndt-modal';
import NDTMeasurementChart from './ndt-measurement-chart';
import Button from '../../../../common/form/components/button';

import Helpers from '../../../../common/helpers';

import { selectNDTMeasurementGroup } from '../../actions/inspection-actions';

import { modules } from '../../constants/constants';
import '../../styles/ndt-measurements-grouped.scss';

class NDTMeasurementGroupDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ndtModalData: {
        isOpen: false,
      },
      selectedMeasurementGroup: {},
    };
  }

  fetchMeasurementGroupDetails = id => {
    const { selectNDTMeasurementGroup } = this.props;
    if (!id) {
      return;
    }
    selectNDTMeasurementGroup(id, selectedMeasurementGroup => {
      this.setState({ selectedMeasurementGroup });
      selectNDTMeasurementGroup(selectedMeasurementGroup.ComponentID);
    });
  };

  componentDidMount() {
    const { queryItem } = this.props;
    this.fetchMeasurementGroupDetails(queryItem);
  }

  componentDidUpdate = prevProps => {
    const { queryItem } = this.props;

    if (prevProps.queryItem !== queryItem && queryItem && queryItem > 0) {
      this.fetchMeasurementGroupDetails(queryItem);
    }
  };

  closeNDTModal = () => {
    const { selectedMeasurementGroup } = this.state;

    this.fetchMeasurementGroupDetails(selectedMeasurementGroup.ComponentID);
    this.setState(prevProps => ({
      ndtModalData: {
        ...prevProps.ndtModalData,
        isOpen: false,
      },
    }));
  };

  submitForm = values => {
    const { updateMeasurement, ndtMeasurementLocations } = this.props;
    const { comments, ...measurementValues } = values;

    updateMeasurement(measurementValues, ndtMeasurementLocations);
  };

  openNDTModal = (measurementId, unit) => {
    const { inspectionId } = this.props;

    this.setState({
      ndtModalData: {
        customClassName: 'ndt-modal dark modal-large',
        CustomContent: () => <NdtModal closeModal={this.closeNDTModal} measurementId={measurementId} inspectionId={inspectionId} unit={unit} />,
        isOpen: true,
        type: 'none',
        closeAction: this.closeNDTModal,
      },
    });
  };

  render() {
    const { t } = this.context;
    const { openNDTModal } = this;
    const { selectedMeasurementGroup } = this.state;
    const { handleActivePage } = this.props;
    const { ndtModalData } = this.state;

    return (
      <div className="ndt-measurement-details-grouped">
        {selectedMeasurementGroup &&
          selectedMeasurementGroup.data &&
          selectedMeasurementGroup.data.map(({ Charts, Unit, Name, MeasurementID }, index) => {
            let data = [];
            if (!isEmpty(Charts)) {
              data = (Helpers.mapLineNDTChartData(Charts || [], Unit) || []).data || [];
            }

            return (
              <ExpandableSection expanded={true} title={Name} key={index}>
                <NDTMeasurementChart unit={Unit} measurementId={MeasurementID} passedChartData={data} />
                <div className="buttons">
                  <Button type="button" variant="gray-outline" onClick={() => openNDTModal(MeasurementID, Unit)} text={t('NDT_MEASUREMENT_DETAILS.EXPAND_GRAPH_BUTTON_TEXT')} />
                  <Button variant="gray-outline" type="button" onClick={() => handleActivePage(modules.ndtData, MeasurementID)} text={t('NDT_MEASUREMENT_DETAILS.VIEW_MEASUREMENT')} />
                </div>
              </ExpandableSection>
            );
          })}
        <Modal {...ndtModalData} />
      </div>
    );
  }
}
NDTMeasurementGroupDetails.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  selectNDTMeasurementGroup: (componentId, callback) => dispatch(selectNDTMeasurementGroup(componentId, callback)),
});

NDTMeasurementGroupDetails.defaultProps = {
  showGeometryWarning: true,
};

export default connect(mapStateToProps, mapDispatchToProps)(NDTMeasurementGroupDetails);
