import navigationActionTypes from '../constants/action-types';

export const openNavigation = () => {
  return {
    type: navigationActionTypes.HANDLE_OPEN_NAV,
  };
};

export const closeNavigation = () => {
  return {
    type: navigationActionTypes.HANDLE_CLOSE_NAV,
  };
};

export const setScrollPosition = data => {
  return {
    type: navigationActionTypes.SET_SCROLL_POSITION,
    data,
  };
};
