import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import Icon from '../../../../../common/icon/components/icon';
import RenderIf from '../../../../../common/render-if/components/render-if';
import { isolationComponentFields } from '../../right-toolbar/isolation-certificate-details/constants/change-status-constants';
import SectionIsolationComponentDetails from './section-isolation-component-details';
import CheckBox from '../../../../../common/form/components/checkbox';

/*
 COMPONENT DUPLICATE OF:
  src/app/inspections/components/right-toolbar/isolation-certificate-details/components/details/isolation-components.js
 */

const SectionIsolationComponent = (
  { isolationComponent = {}, isolationComponentNumber, onFieldChange, formErrors, anyTouched, toggleIsolationComponentDetails, hideIsolationComponentAdditionalDetails },
  { t }
) => {
  const getIsolationComponentField = isolationComponent => {
    const { componentId, name } = isolationComponentFields;
    const fieldId = `isolation-component-field-${isolationComponent[componentId]}`;

    return (
      <div className="isolation-component-item">
        <div className="isolation-component-item-aligned">
          <div className="isolation-component-checkbox">
            <Field id={fieldId} name={fieldId} labelClass="f-secondary-dark" component={CheckBox} checkboxText={''} onChange={(_e, value) => onFieldChange(isolationComponent, value)} />
          </div>
          <p className="f-primary bold isolation-component-title">
            {isolationComponentNumber}. {isolationComponent[name]}
          </p>
          <Icon
            name={isolationComponent?.isExpanded ? 'chevron-up' : 'chevron-down'}
            className="toggle-isolation-component-details"
            onClick={() => toggleIsolationComponentDetails(isolationComponentNumber - 1)}
          />
        </div>
        {anyTouched && formErrors[name] && <p className="form-error">{t(formErrors[name])}</p>}
      </div>
    );
  };

  return (
    <div className="section-isolation-component">
      <div className="isolation-component-wrapper">
        <div className="isolation-component-wrapper-item">{getIsolationComponentField(isolationComponent)}</div>
        <RenderIf if={isolationComponent.isExpanded}>
          <SectionIsolationComponentDetails
            hideAdditionalDetails={hideIsolationComponentAdditionalDetails}
            expandFiles={fileType => toggleIsolationComponentDetails(isolationComponentNumber - 1, fileType)}
            isolationComponent={isolationComponent}
          />
        </RenderIf>
      </div>
    </div>
  );
};

SectionIsolationComponent.contextTypes = {
  t: PropTypes.func.isRequired,
};

SectionIsolationComponent.propTypes = {
  isolationComponent: PropTypes.shape({
    ComponentID: PropTypes.number,
    Name: PropTypes.string.isRequired,
    Colour: PropTypes.string,
    ComponentCode: PropTypes.string,
    ComponentType: PropTypes.string,
    ComponentSubType: PropTypes.string,
    DesiredState: PropTypes.string,
    NormalPosition: PropTypes.string,
    IsolationMethod: PropTypes.string,
    DeisolationOrder: PropTypes.number,
    IsolationOrder: PropTypes.number,
    Files: PropTypes.array,
  }),
  isolationComponentNumber: PropTypes.number,
  onFieldChange: PropTypes.func.isRequired,
  formErrors: PropTypes.object,
  anyTouched: PropTypes.bool,
  toggleIsolationComponentDetails: PropTypes.func.isRequired,
  hideIsolationComponentAdditionalDetails: PropTypes.bool,
};

export default SectionIsolationComponent;
