import React, { Component } from 'react';

import IsolationCertificateItem from './isolation-certificate-item';
import ExpandableSection from '../../../../../common/expandable-section/components/expandable-section';
import SectionEmptyState from '../../right-toolbar/common/section-empty-state';
import Icon from '../../../../../common/icon/components/icon';
import { fields, initialValuesProp } from '../constants/isolation-certificate-constants';

import '../../../styles/isolation-certificates-section.scss';

class IsolationCertificates extends Component {
  render() {
    const { module } = this.props;
    const isolationCertificates = module[initialValuesProp];

    if (!isolationCertificates || !isolationCertificates.length) {
      return <SectionEmptyState title="PERMIT.ISOLATION_CERT_EMPTY_STATE" />;
    }

    return (
      <div className="isolation-certificates-section">
        {isolationCertificates && isolationCertificates.length > 0 && (
          <>
            {isolationCertificates.map((certificate, index) => (
              <div className="component-item-header" key={`section-component-item-${index}`}>
                <ExpandableSection
                  CustomComponent={({ isExpanded }) => (
                    <div className="component-header">
                      <h6 className="f-primary bold">{`${index + 1}.  ${certificate[fields.name.name]}`}</h6>
                      <Icon name={isExpanded ? 'chevron-up' : 'chevron-down'} className="component-header-toggle" />
                    </div>
                  )}
                  className="expanded-section"
                  customExpanded={certificate.isExpanded}
                  hideExpandAction
                >
                  <IsolationCertificateItem component={certificate} componentIndex={index} />
                </ExpandableSection>
              </div>
            ))}
          </>
        )}
      </div>
    );
  }
}

export default IsolationCertificates;
