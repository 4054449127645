import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import { FORMS } from '../../../common/constants';

import { validate } from '../validators/change-password-validators';
import constants from '../constants/constants';
import CustomInput from '../../../common/form/components/input';
import Button from '../../../common/form/components/button';

class ChangePasswordForm extends Component {
  render() {
    const { t } = this.context;
    const { handleSubmit, invalid, anyTouched, cancelAction } = this.props;

    return (
      <form className="change-password-form" onSubmit={handleSubmit} noValidate>
        <div className="form-fields">
          <Field
            id={constants.fields.oldPassword}
            name={constants.fields.oldPassword}
            component={CustomInput}
            type="password"
            placeholder="CHANGE_PASSWORD.CURRENT_PASSWORD_FIELD"
            label={'CHANGE_PASSWORD.CURRENT_PASSWORD_FIELD'}
          />
          <Field
            id={constants.fields.newPassword}
            name={constants.fields.newPassword}
            component={CustomInput}
            type="password"
            placeholder="CHANGE_PASSWORD.NEW_PASSWORD_FIELD"
            label={'CHANGE_PASSWORD.NEW_PASSWORD_FIELD'}
          />
          <Field
            id={constants.fields.confirmNewPassword}
            name={constants.fields.confirmNewPassword}
            component={CustomInput}
            type="password"
            placeholder="LOGIN.PASSWORD_FIELD"
            label={'LOGIN.PASSWORD_FIELD'}
          />
        </div>

        <div className="buttons">
          <Button type="button" variant="gray-outline" width="sm" text={t('CHANGE_PASSWORD.CANCEL')} onClick={() => cancelAction()} />
          <Button disabled={invalid && anyTouched} width="sm" text={t('CHANGE_PASSWORD.SAVE')} />
        </div>
      </form>
    );
  }
}

ChangePasswordForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

ChangePasswordForm = reduxForm({
  form: FORMS.changePassword,
  validate,
  enableReinitialize: true,
})(ChangePasswordForm);

export default ChangePasswordForm;
