import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { chain, find, isEmpty } from 'lodash';

import AccessRenderer from '../../../../common/access-renderer/components/access-renderer';
import Loader from '../../../../common/global-loader/components/simple-loader';
import Helpers from '../../../../common/helpers';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../common/permissions-constants';
import RenderIf from '../../../../common/render-if/components/render-if';
import routesConstants from '../../../../common/routes-constants';
import PageNotFound from '../../../page-not-found/components/page-not-found';
import { handleCreateWorkOrderModal, handleDefectRelatedComponentsVisible, setWorkOrderFilters, setWorkOrderPaging } from '../../actions/action-creators';
import { addArea, createAreaGroup, deleteAreaGroup, getAreas, updateAreaGroupName } from '../../actions/area-actions';
import { addExplosiveZone, getExplosiveZones } from '../../actions/explosive-zones-actions';
import { createNewDefect, editInspectionDetails, getDefectDetails, getInspectionDefects } from '../../actions/inspection-actions';
import { getIsolationCertificates } from '../../actions/isolation-certificates';
import { addMeasurement, getMeasurements } from '../../actions/ndt-actions';
import { getNotifications } from '../../actions/notification-actions';
import { getPermits } from '../../actions/permit-actions';
import { getWorkOrders } from '../../actions/work-order-actions';
import { componentDropdownActions } from '../../constants/component-constants';
import { activeItemsData, measurementTypes, modules, objectTools, toolTypes } from '../../constants/constants';
import { getElementActions } from '../../helpers/inspection-helper';
import '../../styles/left-toolbar.scss';
import IsolationCertificates from '../isolation-certificates/isolation-certificates';
import Permits from '../permits/permits';
import { viewOptions } from '../readings-and-gauges/constants/constants';
import ReadingsAndGauges from '../readings-and-gauges/readings-and-gauges';
import Areas from './areas';
import BaseModule from './base-module';
import Components from './components';
import Defects from './defects';
import EditableDate from './editable-date';
import InspectionDetails from './inspection-details';
import Measurements from './measurements';
import NDTMeasurements from './ndt-measurements';
import Notifications from './notifications';
import Workorders from './work-orders';

class LeftToolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: null,
    };
  }

  handleDateChange = date => {
    //selectedDate
    const { editInspectionDetails, inspectionId, inspections } = this.props;
    const dateToSave = new Date(date);

    const today = new Date();
    // if date to save is in future with time, reset time to todays max available time in hours
    if (dateToSave.getDate() === today.getDate() && dateToSave.getHours() > today.getHours()) {
      dateToSave.setHours(today.getHours());
      dateToSave.setMinutes(0);
      dateToSave.setSeconds(0);
      dateToSave.setMilliseconds(0);
    }

    this.setState({ selectedDate: new Date(dateToSave) });
    // handle date changed on Api
    const unixDate = Helpers.getUnixDate(new Date(dateToSave).getTime());

    editInspectionDetails(inspectionId, unixDate, inspections, () => this.setState({ selectedDate: null }));
  };

  renderContent = () => {
    const { t } = this.context;
    const {
      activeItem,
      severityColors,
      inspectionDetails,
      defects,
      defectsClustered,
      measurements,
      measurementsClustered,
      measurementsLoading,
      components,
      componentsLoading,
      defectsTemp,
      measurementsTemp,
      componentsTemp,
      openDownloadReportModal,
      notifications,
      getCameraPosition,
      // explosiveZones,
      // explosiveZonesClustered,
      // getExplosiveZones,
      // addExplosiveZone,
      areas,
      areasClustered,
      getAreas,
      addArea,
      getNDTMeasurements,
      ndtMeasurementLocations,
      ndtMeasurementLocationsClustered,
      addNdtMeasurement,
      openDownloadReportModalForMeasurments,
      viewer,
      setModalState,
      componentsVisible,
      componentsClustered,
      handleDefectRelatedComponentsVisible,
      inspectionId,
      addAreaGroup,
      deleteAreaGroup,
      getInspectionDefects,
      updateAreaGroupName,
      scrollToElement,
      searchText,
      openAddQuickNDTPointModal,
      defectsLoading,
      // zonesLoading,
      areasLoading,
      measurementLocationsLoading,
      getNotifications,
      notificationsLoading,
      getWorkOrders,
      workOrders,
      handleCreateWorkOrderModal,
      workOrderAreas,
      queryItem,
      handleActivePage,
      workOrdersLoading,
      sharedModuleActionsMenu,
      handleObjectToolClick,
      getIsolationCertificates,
      isolationCertificates,
      isolationCertificatesLoading,
      getPermits,
      permits,
      permitsLoading,
      workOrderFilters,
      workOrderPagingObject,
      setWorkOrderFilters,
      setWorkOrderPaging,
      user,
      createNewDefect,
      chShow,
      displayUnassignedComponentsLevel,
      chHierarchyID,
      notificationsClustered,
      measurementLocations,
      measurementLocationsClustered,
      measurementPoints,
      measurementPointsClustered,
      view,
      measurementGroupsClustered,
      measurementGroups,
    } = this.props;

    if (isEmpty(inspectionDetails)) return null;

    const autoScrollConfig = Helpers.autoScrollConstants[activeItem];
    const autoScrollProps = {
      scrollToElement: element => !isEmpty(autoScrollConfig) && scrollToElement(element, autoScrollConfig.generateCustomIdentificator, autoScrollConfig.offset),
      generateCustomIdentificator: !isEmpty(autoScrollConfig) && autoScrollConfig.generateCustomIdentificator,
    };

    const headerProps = {
      title: t(find(activeItemsData, { name: activeItem })?.label || ''),
      icon: find(activeItemsData, { name: activeItem })?.icon,
      backAction: () => handleActivePage(modules.details),
      moduleActionsMenu: sharedModuleActionsMenu,
    };

    switch (activeItem) {
      case modules.details:
        return inspectionDetails ? (
          <InspectionDetails
            details={inspectionDetails}
            modules={chain(activeItemsData)
              .filter(item => item.name !== modules.details && !item.hidden)
              .map(item => ({
                ...item,
                action: () => {
                  handleActivePage(item.name);
                },
              }))
              .value()}
            tools={toolTypes.map(item => ({
              ...item,
              action: () => {
                const { newFeature, comingSoon } = item.feature;
                if (newFeature || comingSoon) {
                  return;
                }

                const goToRoute = !isEmpty(item.route) ? routesConstants.routes.protectedRoutes[item.route].fullPath : null;
                goToRoute && Helpers.goTo(goToRoute, [{ project_id: inspectionDetails.ProjectID }, { inspection_id: inspectionDetails.InspectionID }]);
              },
            }))}
          />
        ) : null;
      case modules.measurements:
        return (
          <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.measurements].view.name}>
            {({ hasAccess }) => {
              return hasAccess ? (
                <BaseModule
                  module={modules.measurements}
                  queryItem={queryItem}
                  searchText={searchText}
                  inspectionId={inspectionId}
                  viewer={viewer}
                  elements={[...measurements, ...measurementsTemp]}
                  elementsClustered={[...measurementsClustered, ...measurementsTemp]}
                  projectId={inspectionDetails.ProjectID}
                  view={view}
                >
                  <Measurements
                    measurementsLoading={measurementsLoading}
                    getMeasurements={(searchT, params, callback, loadMore) => getInspectionDefects(inspectionId, modules.measurements, searchT, params, callback, loadMore)}
                    handleItemClick={selectedItem => handleActivePage(modules.measurements, selectedItem)}
                    setModalState={setModalState}
                    {...headerProps}
                    {...autoScrollProps}
                  />
                </BaseModule>
              ) : (
                <PageNotFound hideSideImage />
              );
            }}
          </AccessRenderer>
        );
      case modules.defects:
        return (
          <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.observations].view.name}>
            {({ hasAccess }) => {
              return hasAccess ? (
                <BaseModule
                  module={modules.defects}
                  queryItem={queryItem}
                  searchText={searchText}
                  inspectionId={inspectionId}
                  viewer={viewer}
                  elements={[...defects, ...defectsTemp]}
                  elementsClustered={[...defectsClustered, ...componentsTemp]}
                  projectId={inspectionDetails.ProjectID}
                  view={view}
                >
                  <Defects
                    openDownloadReportModal={openDownloadReportModal}
                    components={componentsVisible ? componentsClustered : []}
                    handleDefectRelatedComponentsVisible={handleDefectRelatedComponentsVisible}
                    severityColors={severityColors}
                    createNewDefect={type => {
                      handleObjectToolClick(objectTools.link, type, true, null, true);
                    }}
                    getInspectionDefects={(searchT, params, callback, loadMore) => getInspectionDefects(inspectionId, modules.defects, searchT, params, callback, loadMore)}
                    defectsLoading={defectsLoading}
                    handleItemClick={selectedItem => handleActivePage(activeItem, selectedItem)}
                    setModalState={setModalState}
                    {...headerProps}
                    {...autoScrollProps}
                  />
                </BaseModule>
              ) : (
                <PageNotFound hideSideImage />
              );
            }}
          </AccessRenderer>
        );
      case modules.components:
        return (
          <BaseModule
            module={modules.components}
            queryItem={queryItem}
            searchText={searchText}
            inspectionId={inspectionId}
            viewer={viewer}
            elements={[...components, ...componentsTemp]}
            elementsClustered={[...componentsClustered, ...componentsTemp]}
            projectId={inspectionDetails.ProjectID}
            view={view}
          >
            <Components
              componentsLoading={componentsLoading}
              getComponents={(searchT, params, callback, loadMore) => {
                getInspectionDefects(inspectionId, modules.components, searchT, params, callback, loadMore, false, chShow, displayUnassignedComponentsLevel);
              }}
              createNewComponent={callback => {
                const { defaultElement } = getElementActions({ SystemType: measurementTypes.component });
                createNewDefect({ ...defaultElement(inspectionDetails.CameraPosition), InspectionID: inspectionId, HierarchyID: chHierarchyID }, data => {
                  handleObjectToolClick(objectTools.component, componentDropdownActions.points, true, null, false);
                  handleActivePage(activeItem, data.ID);
                  autoScrollProps.scrollToElement(data);
                  callback && callback();
                });
              }}
              handleItemClick={selectedItem => handleActivePage(activeItem, selectedItem)}
              setModalState={setModalState}
              user={user}
              fullScreen={false}
              {...headerProps}
              {...autoScrollProps}
              componentsShow={chShow}
            />
          </BaseModule>
        );

      case modules.workorders:
        return (
          <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.workOrders].view.name}>
            {({ hasAccess }) => {
              return hasAccess ? (
                <Workorders
                  elements={workOrders}
                  getWorkOrders={(params, loadMore, sucessCallback) => getWorkOrders(inspectionDetails.InspectionID, params, loadMore, sucessCallback)}
                  severityColors={severityColors}
                  workOrdersLoading={workOrdersLoading}
                  searchText={searchText}
                  handleCreateWorkOrderModal={handleCreateWorkOrderModal}
                  inspectionId={inspectionId}
                  workOrderAreas={workOrderAreas}
                  handleItemClick={selectedItem => handleActivePage(activeItem, selectedItem)}
                  setModalState={setModalState}
                  queryItem={queryItem}
                  filters={workOrderFilters}
                  pagingObject={workOrderPagingObject}
                  setWorkOrderFilters={setWorkOrderFilters}
                  setWorkOrderPaging={setWorkOrderPaging}
                  projectId={inspectionDetails.ProjectID}
                  user={user}
                  isInLeftToolbar
                  {...headerProps}
                />
              ) : (
                <PageNotFound hideSideImage />
              );
            }}
          </AccessRenderer>
        );
      case modules.notifications:
        return (
          <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.notifications].view.name}>
            {({ hasAccess }) =>
              hasAccess ? (
                <BaseModule
                  module={modules.notifications}
                  queryItem={queryItem}
                  searchText={searchText}
                  inspectionId={inspectionId}
                  viewer={viewer}
                  elements={notifications}
                  elementsClustered={notificationsClustered}
                  projectId={inspectionDetails.ProjectID}
                  view={view}
                >
                  <Notifications
                    getNotifications={(params, successCallback, loadMore) => getNotifications(inspectionDetails.InspectionID, params, successCallback, loadMore)}
                    notifications={notifications}
                    notificationsLoading={notificationsLoading}
                    viewer={viewer}
                    searchText={searchText}
                    queryItem={queryItem}
                    setModalState={setModalState}
                    handleItemClick={selectedItem => handleActivePage(activeItem, selectedItem)}
                    projectId={inspectionDetails.ProjectID}
                    {...autoScrollProps}
                    {...headerProps}
                  />
                </BaseModule>
              ) : (
                <PageNotFound />
              )
            }
          </AccessRenderer>
        );
      // case modules.explosiveZones:
      //   return (
      //     <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.zones].view.name}>
      //       {({ hasAccess }) =>
      //         hasAccess ? (
      //           <BaseModule
      //             module={modules.explosiveZones}
      //             queryItem={queryItem}
      //             searchText={searchText}
      //             inspectionId={inspectionId}
      //             viewer={viewer}
      //             elements={explosiveZones}
      //             elementsClustered={explosiveZonesClustered}
      //           >
      //             <ExplosiveZones
      //               getExplosiveZones={(searchText, params, loadMore, callback) => getExplosiveZones(inspectionId, searchText, params, loadMore, callback)}
      //               addExplosiveZone={data => addExplosiveZone(inspectionId, explosiveZones, data, newZone => handleActivePage(modules.explosiveZones, newZone.ID))}
      //               zonesLoading={zonesLoading}
      //               handleItemClick={selectedItem => handleActivePage(modules.explosiveZones, selectedItem)}
      //               {...headerProps}
      //               {...autoScrollProps}
      //             />
      //           </BaseModule>
      //         ) : (
      //           <PageNotFound hideSideImage />
      //         )
      //       }
      //     </AccessRenderer>
      //   );
      case modules.areas:
        return (
          <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.areas].view.name}>
            {({ hasAccess }) => {
              return hasAccess ? (
                <BaseModule
                  module={modules.areas}
                  queryItem={queryItem}
                  searchText={searchText}
                  inspectionId={inspectionId}
                  viewer={viewer}
                  elements={areas}
                  elementsClustered={areasClustered}
                  projectId={inspectionDetails.ProjectID}
                  view={view}
                >
                  <Areas
                    getAreas={(searchText, params, loadMore, callback) => getAreas(inspectionId, searchText, params, loadMore, callback)}
                    addArea={(data, camPos, callback) =>
                      addArea(inspectionId, areas, data, camPos, data => {
                        callback && callback();
                        scrollToElement(data, autoScrollConfig.generateCustomIdentificator, autoScrollConfig.offset);
                        handleActivePage(modules.areas, data.ID);
                      })
                    }
                    deleteAreaGroup={(id, emptyGroups) => deleteAreaGroup(id, emptyGroups, areas)}
                    updateAreaGroupName={(id, name) => updateAreaGroupName(id, name, areas)}
                    createAreaGroup={(name, callback) => addAreaGroup(inspectionId, name, callback)}
                    getCameraPosition={getCameraPosition}
                    areasLoading={areasLoading}
                    handleItemClick={selectedItem => handleActivePage(modules.areas, selectedItem)}
                    setModalState={setModalState}
                    {...headerProps}
                    {...autoScrollProps}
                  />
                </BaseModule>
              ) : (
                <PageNotFound hideSideImage />
              );
            }}
          </AccessRenderer>
        );
      case modules.ndtData:
        return (
          <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.ndt].view.name}>
            {({ hasAccess }) => {
              return hasAccess ? (
                <BaseModule
                  module={modules.ndtData}
                  queryItem={queryItem}
                  searchText={searchText}
                  inspectionId={inspectionId}
                  viewer={viewer}
                  elements={ndtMeasurementLocations}
                  elementsClustered={ndtMeasurementLocationsClustered}
                  projectId={inspectionDetails.ProjectID}
                  view={view}
                >
                  <NDTMeasurements
                    severityColors={severityColors}
                    defaultComponentID={inspectionDetails.DefaultComponent}
                    getMeasurements={(searchText, params, loadMore, callback) => getNDTMeasurements(inspectionId, searchText, params, loadMore, callback)}
                    measurementLocationsLoading={measurementLocationsLoading}
                    addMeasurement={(data, camPos) =>
                      addNdtMeasurement(inspectionId, ndtMeasurementLocations, data, camPos, data => {
                        autoScrollProps.scrollToElement(data);
                        handleActivePage(modules.ndtData, data.ID);
                      })
                    }
                    getCameraPosition={getCameraPosition}
                    openDownloadReportModalForMeasurments={openDownloadReportModalForMeasurments}
                    openAddQuickNDTPointModal={openAddQuickNDTPointModal}
                    handleItemClick={selectedItem => handleActivePage(activeItem, selectedItem)}
                    setModalState={setModalState}
                    {...headerProps}
                    {...autoScrollProps}
                  />
                </BaseModule>
              ) : (
                <PageNotFound hideSideImage />
              );
            }}
          </AccessRenderer>
        );

      case modules.isolationCertificates:
        return (
          <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.isolationCertificates].view.name}>
            {({ hasAccess }) => {
              return hasAccess ? (
                <BaseModule
                  module={modules.isolationCertificates}
                  queryItem={queryItem}
                  searchText={searchText}
                  inspectionId={inspectionId}
                  viewer={viewer}
                  projectId={inspectionDetails.ProjectID}
                  view={view}
                >
                  <IsolationCertificates
                    projectId={inspectionDetails.ProjectID}
                    searchText={searchText}
                    queryItem={queryItem}
                    getIsolationCertificates={(params, loadMore, callback, advancedFilter) =>
                      getIsolationCertificates(params, Object.assign([], isolationCertificates), loadMore, callback, advancedFilter)
                    }
                    isolationCertificates={isolationCertificates}
                    isolationCertificatesLoading={isolationCertificatesLoading}
                    handleItemClick={selectedItem => handleActivePage(activeItem, selectedItem)}
                    isInLeftToolbar
                    setModalState={setModalState}
                    {...headerProps}
                    {...autoScrollProps}
                  />
                </BaseModule>
              ) : (
                <PageNotFound hideSideImage />
              );
            }}
          </AccessRenderer>
        );
      case modules.permits:
        return (
          <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.permits].view.name}>
            {({ hasAccess }) => {
              return hasAccess ? (
                <BaseModule module={modules.permits} queryItem={queryItem} searchText={searchText} inspectionId={inspectionId} viewer={viewer} projectId={inspectionDetails.ProjectID} view={view}>
                  <Permits
                    projectId={inspectionDetails.ProjectID}
                    searchText={searchText}
                    queryItem={queryItem}
                    getPermits={(params, loadMore, callback, advancedFilter) => getPermits(params, Object.assign([], permits), loadMore, callback, advancedFilter)}
                    permits={permits}
                    permitsLoading={permitsLoading}
                    handleItemClick={selectedItem => handleActivePage(activeItem, selectedItem)}
                    isInLeftToolbar
                    setModalState={setModalState}
                    {...headerProps}
                    {...autoScrollProps}
                  />
                </BaseModule>
              ) : (
                <PageNotFound hideSideImage />
              );
            }}
          </AccessRenderer>
        );
      case modules.readingsAndGauges:
        const readingsAndGaugesClusterProps = {
          [viewOptions.location]: {
            elements: measurementLocations,
            elementsClustered: measurementLocationsClustered,
          },
          [viewOptions.points]: {
            elements: measurementPoints,
            elementsClustered: measurementPointsClustered,
          },
          [viewOptions.location_and_points]: {
            elements: [...(measurementPoints || []), ...(measurementLocations || [])],
            elementsClustered: [...(measurementPointsClustered || []), ...(measurementLocationsClustered || [])],
          },
          [viewOptions.group]: {
            elements: measurementGroups,
            elementsClustered: measurementGroupsClustered,
          },
        };
        const { elements, elementsClustered } = readingsAndGaugesClusterProps[view || viewOptions.group] || readingsAndGaugesClusterProps[viewOptions.location];
        return (
          <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].view.name}>
            {({ hasAccess }) => {
              return hasAccess ? (
                <BaseModule
                  module={modules.readingsAndGauges}
                  queryItem={queryItem}
                  searchText={searchText}
                  inspectionId={inspectionId}
                  viewer={viewer}
                  projectId={inspectionDetails.ProjectID}
                  elements={elements || []}
                  elementsClustered={elementsClustered || []}
                  view={view}
                >
                  <ReadingsAndGauges
                    measurementPointsClustered={measurementPointsClustered}
                    measurementLocationsClustered={measurementLocationsClustered}
                    handleActivePage={handleActivePage}
                    projectID={inspectionDetails.ProjectID}
                    user={user}
                    {...headerProps}
                    {...autoScrollProps}
                  />
                </BaseModule>
              ) : (
                <PageNotFound hideSideImage />
              );
            }}
          </AccessRenderer>
        );
      default:
        return <div>{t('INSPECTION_NO_CONTENT')}</div>;
    }
  };

  render() {
    const { projectDetails, activeItem, inspectionDetails } = this.props;

    return (
      <Fragment>
        <div className="left-toolbar padding-horizontal padding-vertical">
          <RenderIf if={projectDetails.isLoading}>
            <Loader isLoading={projectDetails.isLoading} />
          </RenderIf>
          <RenderIf if={!projectDetails.isLoading}>
            <RenderIf if={activeItem === modules.details}>
              <div className="left-toolbar__header">
                <div className="header">
                  <h3 className="f-primary noselect">{projectDetails ? projectDetails.ProjectName : ''}</h3>
                  <EditableDate
                    displayValue={inspectionDetails ? Helpers.getDateFromUnix(inspectionDetails.InspectionDate) : ''}
                    currentDate={inspectionDetails ? inspectionDetails.InspectionDate : null}
                    disabled
                  />
                </div>
              </div>
            </RenderIf>

            <div className="left-toolbar__main-content">{this.renderContent()}</div>
          </RenderIf>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  inspectionDetails: state.inspectionReducer.inspectionDetails,
  inspections: state.inspectionReducer.inspections,
  chShow: state.inspectionReducer.chShow,
  chHierarchyID: state.inspectionReducer.chHierarchyID,
  displayUnassignedComponentsLevel: state.inspectionReducer.displayUnassignedComponentsLevel,
  defects: state.inspectionReducer.defects,
  defectsLoading: state.inspectionReducer.defectsLoading,
  defectsTemp: state.inspectionReducer.defectsTemp,
  measurements: state.inspectionReducer.measurements,
  measurementsTemp: state.inspectionReducer.measurementsTemp,
  measurementsLoading: state.inspectionReducer.measurementsLoading,
  components: state.inspectionReducer.components,
  componentsLoading: state.inspectionReducer.componentsLoading,
  componentsClustered: state.inspectionReducer.componentsClustered,
  componentsVisible: state.inspectionReducer.componentsVisible,
  componentsTemp: state.inspectionReducer.componentsTemp,
  severityColors: state.themeReducer.severityColors,

  //workorders
  workOrders: state.workOrderReducer.workOrders,
  workOrderAreas: state.workOrderReducer.workOrderAreas,
  workOrdersLoading: state.workOrderReducer.workOrdersLoading,
  workOrderFilters: state.workOrderReducer.filters,
  workOrderPagingObject: state.workOrderReducer.pagingObject,

  selectedDefect: state.inspectionReducer.selectedDefect,
  explosiveZones: state.explosiveZonesReducer.zones,
  explosiveZonesClustered: state.explosiveZonesReducer.zonesClustered,
  zonesLoading: state.explosiveZonesReducer.zonesLoading,
  areas: state.areasReducer.areas,
  areasClustered: state.areasReducer.areasClustered,
  areasLoading: state.areasReducer.areasLoading,
  ndtMeasurementLocations: state.ndtReducer.measurementLocations,
  ndtMeasurementLocationsClustered: state.ndtReducer.measurementLocationsClustered,
  measurementLocationsLoading: state.ndtReducer.measurementLocationsLoading,
  viewer: props.viewer || state.potreeReducer.viewerInstance,
  inspectionModalData: state.inspectionReducer.inspectionModalData,
  searchText: state.inspectionReducer.searchText,
  defectsClustered: state.inspectionReducer.observationsClustered,
  measurementsClustered: state.inspectionReducer.measurementsClustered,
  // isolations
  isolationCertificates: state.isolationCertificatesReducer.isolationCertificates,
  isolationCertificatesLoading: state.isolationCertificatesReducer.isolationCertificatesLoading,
  // permits
  permits: state.permitsReducer.permits,
  permitsLoading: state.permitsReducer.permitsLoading,
  //project
  projectDetails: state.projectDetailsReducer,
  // notifications
  notificationsClustered: state.notificationReducer.notificationsClustered,
  notifications: state.notificationReducer.notifications,
  // readings and gauges
  // measurement locations
  measurementLocations: state.measurementLocationReducer.measurementLocations,
  measurementLocationsClustered: state.measurementLocationReducer.measurementLocationsClustered,
  // measurement points
  measurementPoints: state.measurementPointReducer.measurementPoints,
  measurementPointsClustered: state.measurementPointReducer.measurementPointsClustered,
  // measurement groups
  measurementGroupsClustered: state.measurementGroupReducer.measurementGroupsClustered,
  measurementGroups: state.measurementGroupReducer.measurementGroups,
  user: state.userReducer,
});

const mapDispatchToProps = dispatch => ({
  getInspectionDefects: (id, activePage, search, additionalProps, callback, loadMore, isLite, isComponentHierarchy, isUnassignedLevel) =>
    dispatch(getInspectionDefects(id, activePage, search, additionalProps, callback, loadMore, isLite, isComponentHierarchy, isUnassignedLevel)),
  editInspectionDetails: (inspectionId, date, inspections, callback) => dispatch(editInspectionDetails(inspectionId, date, inspections, callback)),
  getDefectDetails: (element, callback) => dispatch(getDefectDetails(element, callback, {}, false, true)),
  getNotifications: (inspectionID, params, successCallback, loadMore) => dispatch(getNotifications(inspectionID, params, successCallback, loadMore, false)),
  getNDTMeasurements: (inspectionId, searchText, params, loadMore, callback) => dispatch(getMeasurements(inspectionId, searchText, params, loadMore, callback)),
  getExplosiveZones: (inspectionId, searchText, params, loadMore, callback) => dispatch(getExplosiveZones(inspectionId, searchText, params, loadMore, callback)),
  addExplosiveZone: (inspectionId, currentZones, newData, callback) => dispatch(addExplosiveZone(inspectionId, currentZones, newData, callback)),
  getAreas: (inspectionId, searchText, params, loadMore, callback) => dispatch(getAreas(inspectionId, searchText, params, loadMore, callback)),
  addNdtMeasurement: (inspectionId, currentZones, newData, camPos, callback) => dispatch(addMeasurement(inspectionId, currentZones, newData, camPos, callback)),
  handleDefectRelatedComponentsVisible: isVisible => dispatch(handleDefectRelatedComponentsVisible(isVisible)),
  addArea: (inspectionId, currentAreas, newData, camPos, callback) => dispatch(addArea(inspectionId, currentAreas, newData, camPos, callback)),
  addAreaGroup: (inspectionId, name, callback) => dispatch(createAreaGroup(inspectionId, name, callback)),
  deleteAreaGroup: (areaGroupId, emptyGroups, currentAreas) => dispatch(deleteAreaGroup(areaGroupId, emptyGroups, currentAreas)),
  updateAreaGroupName: (areaGroupId, name) => dispatch(updateAreaGroupName(areaGroupId, name)),
  //workOrders
  getWorkOrders: (inspectionId, params, loadMore, sucessCallback) => dispatch(getWorkOrders(inspectionId, params, loadMore, sucessCallback)),
  handleCreateWorkOrderModal: data => dispatch(handleCreateWorkOrderModal(data)),
  getIsolationCertificates: (filters, isolationCertificates, loadMore, callback, advancedFilter) =>
    dispatch(getIsolationCertificates(filters, isolationCertificates, loadMore, callback, advancedFilter)),
  getPermits: (filters, isolationCertificates, loadMore, callback, advancedFilter) => dispatch(getPermits(filters, isolationCertificates, loadMore, callback, advancedFilter)),
  setWorkOrderFilters: filters => dispatch(setWorkOrderFilters(filters)),
  setWorkOrderPaging: pagingObject => dispatch(setWorkOrderPaging(pagingObject)),
  createNewDefect: (data, callback) => dispatch(createNewDefect(data, callback)),
});

LeftToolbar.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftToolbar);
