import React, { Component } from 'react';
import { connect } from 'react-redux';

import Hubspot from './app/hubspot/components/hubspot';
import Loader from './common/global-loader/components/loader';
import GenericModal from './common/modal/components/generic-modal';

import Helpers from './common/helpers';
import ThemeHelpers from './common/theme-helpers';

import { setLanguage } from 'redux-i18n';
import { fetchAndCompareMappedErrorCodes } from './app/members/actions/users-data-actions';

import { DEFAULT_LANGUAGE, FAVICON, HUBSPOT_BASE_URL, HUBSPOT_PORTAL_ID, isDevelopment } from './common/constants';
import { supportedErrorsContent } from './common/modal/constants/modal-constants';
import translationConstants from './languages/en.json';

import './common/string-extension-methods';

import CollapsibleInfo from './common/collapsible-info/components/collapsible-info';
import GenericNotification from './common/notification/components/generic-notification';
import './common/styles/app.scss';

class App extends Component {
  componentDidMount() {
    const {
      user: { Theme, Language, Token },
      setLanguage,
      fetchAndCompareMappedErrorCodes,
    } = this.props;
    if (Theme) {
      ThemeHelpers.setBodyTheme(Theme);
    }
    Helpers.setFavicon(FAVICON);

    setLanguage(Language || DEFAULT_LANGUAGE);
    if (Token && Token.Token) {
      if (isDevelopment) fetchAndCompareMappedErrorCodes(translationConstants, supportedErrorsContent);
    }
  }

  render() {
    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        currentPath: this.props.currentPath,
      })
    );
    return (
      <div className="main-container">
        <Loader />
        <GenericModal customClassName="generic-modal" />
        <GenericNotification />
        <CollapsibleInfo />
        <div className="main-container__content">{childrenWithProps}</div>
        <Hubspot portalId={HUBSPOT_PORTAL_ID} baseUrl={HUBSPOT_BASE_URL} />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  user: state.userReducer,
});

const mapDispatchToProps = dispatch => ({
  setLanguage: lang => dispatch(setLanguage(lang)),
  fetchAndCompareMappedErrorCodes: (translationConstants, supportedErrorCodes) => dispatch(fetchAndCompareMappedErrorCodes(translationConstants, supportedErrorCodes)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
