import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

class Hubspot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasLoaded: false,
      activeConversation: null,
    };
  }
  componentDidMount = () => {
    const { baseUrl, portalId } = this.props;

    if (portalId) {
      window.hsConversationsOnReady = [
        () => {
          this.setHasLoaded(true);
        },
      ];

      let script = document.createElement('script');
      script.src = `${baseUrl}/${portalId}.js`;
      script.async = true;

      document.body.appendChild(script);
    }
  };

  setHasLoaded = (hasLoaded = false) => {
    this.setState({ hasLoaded });
    const callback = payload => {
      this.setState({ activeConversation: payload.conversation.conversationId });
    };

    if (hasLoaded) {
      window.HubSpotConversations.on('conversationStarted', callback);
      this.closeHandler();
    } else {
      window.HubSpotConversations.off('conversationStarted', callback);
    }
  };

  openHandler = () => {
    window.HubSpotConversations.widget.open();
  };

  closeHandler = () => {
    window.HubSpotConversations.widget.close();
  };

  render() {
    const { children, portalId } = this.props;
    const { hasLoaded, activeConversation } = this.state;

    return (
      <Fragment>
        {portalId &&
          children &&
          children({
            hasLoaded,
            activeConversation,
            openHandler: hasLoaded ? this.openHandler : null,
            closeHandler: hasLoaded ? this.closeHandler : null,
          })}
      </Fragment>
    );
  }
}

Hubspot.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Hubspot;
