import React from 'react';
import Button from '../../../../common/form/components/button';
import PropTypes from 'prop-types';
import { DeleteActionTypes } from '../../constants/constants';

const DeleteModal = ({ levelName, customConfirmAction }, { t }) => {
  return (
    <div className="component-hierarchy__modal__delete">
      <p>{t('COMPONENT_HIERARCHY_MODAL.ARE_YOU_SURE', { levelName })}</p>
      <p>{t('COMPONENT_HIERARCHY_MODAL.OPTION_TO_MOVE_COMPONENTS')}</p>
      <Button
        onClick={() => customConfirmAction(DeleteActionTypes.deleteAndUnassign)}
        type="button"
        text={t('COMPONENT_HIERARCHY_MODAL.DELETE_AND_UNASSIGN')}
        variant="danger-outline"
        className="modal-button-custom h-md"
      />
      <Button
        onClick={() => customConfirmAction(DeleteActionTypes.deleteAndMove)}
        type="button"
        text={t('COMPONENT_HIERARCHY_MODAL.DELETE_AND_MOVE')}
        variant="danger-outline"
        className="modal-button-custom h-md"
      />
      <Button onClick={() => customConfirmAction(DeleteActionTypes.deleteAll)} type="button" text={t('COMPONENT_HIERARCHY_MODAL.DELETE_ALL')} variant="danger" className="modal-button-custom h-md" />
    </div>
  );
};

DeleteModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default DeleteModal;
