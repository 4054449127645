import routesConstants from './routes';
import { Api } from '../../core/api';
import { API_BASE_URL } from '../../common/constants';

const getTeams = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_TEAMS}`, params);
};

const createTeam = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CREATE_TEAM}`, params);
};
const updateTeam = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_TEAM}`, params);
};
const deleteTeam = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_TEAM}`, params);
};
const addTeamUser = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_TEAM_USER}`, params);
};
const deleteTeamUser = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_TEAM_USER}`, params);
};
const getTeamUsers = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_TEAM_USERS}`, params);
};
const searchAssignees = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.SEARCH_ASSIGNEES}`, data);
};

export default {
  getTeams,
  updateTeam,
  deleteTeam,
  createTeam,
  addTeamUser,
  deleteTeamUser,
  getTeamUsers,
  searchAssignees,
};
