import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { validate } from '../validators/create-project-validators';

import constants from '../constants/constants';
import { FORMS } from '../../../common/constants';

import CustomInput from '../../../common/form/components/input';
import CustomSelect from '../../../common/form/components/select';
import Button from '../../../common/form/components/button';

class CreateProjectForm extends Component {
  render() {
    const { t } = this.context;
    const { handleSubmit, invalid, anyTouched, selectedContact, onCancel, contacts, handleFormKeyPress, renderAddress, isEdit, itemComponent } = this.props;

    return (
      <form className="create-project-form" onSubmit={handleSubmit} onKeyPress={handleFormKeyPress} noValidate>
        <div className="left">
          <div className="form-fields">
            {isEdit && (
              <Field
                id={constants.editFields.projectId}
                name={constants.fields.projectId}
                labelClass="f-secondary-light"
                component={CustomInput}
                isHidden={true}
                type="text"
                placeholder="CREATE_PROJECT.PROJECT_NAME"
              />
            )}
            <Field
              id={constants.fields.projectName}
              name={constants.fields.projectName}
              label="CREATE_PROJECT.PROJECT_NAME"
              labelClass="f-secondary-light"
              component={CustomInput}
              type="email"
              placeholder="CREATE_PROJECT.PROJECT_NAME"
            />
            <Field
              id={constants.fields.selectContact}
              name={constants.fields.selectContact}
              label="CREATE_PROJECT.SELECT_CONTACT"
              labelClass="f-secondary-light"
              data={contacts}
              component={CustomSelect}
              itemComponent={itemComponent}
              textField={'Name'}
              type="email"
              placeholder={t('CREATE_PROJECT.SELECT_CONTACT')}
            />
          </div>
          {!isEdit && (
            <Fragment>
              <label className="f-secondary-light contact-fields__group-name">{t('CREATE_PROJECT.SITE_MANAGER_DETAILS')}</label>
              <div className="contact-fields">
                <Field
                  id={constants.fields.contactName}
                  name={constants.fields.contactName}
                  label="CREATE_PROJECT.CONTACT_NAME"
                  labelClass="f-secondary-light"
                  component={CustomInput}
                  disabled={!selectedContact || selectedContact.ID !== null}
                  type="email"
                  placeholder="CREATE_PROJECT.CONTACT_NAME"
                />
                <Field
                  id={constants.fields.email}
                  name={constants.fields.email}
                  label="CREATE_PROJECT.CONTACT_EMAIL"
                  labelClass="f-secondary-light"
                  component={CustomInput}
                  type="email"
                  disabled={!selectedContact || selectedContact.ID !== null}
                  placeholder="CREATE_PROJECT.CONTACT_EMAIL"
                />
                <Field
                  id={constants.fields.phone}
                  name={constants.fields.phone}
                  component={CustomInput}
                  type="text"
                  placeholder="CREATE_PROJECT.CONTACT_PHONE"
                  label="CREATE_PROJECT.CONTACT_PHONE"
                  disabled={!selectedContact || selectedContact.ID !== null}
                  labelClass="f-secondary-light"
                />
              </div>
            </Fragment>
          )}
        </div>
        <div className="right">
          <Field
            id={constants.fields.address}
            name={constants.fields.address}
            component={renderAddress}
            type="text"
            placeholder="CREATE_PROJECT.ADDRESS"
            label="CREATE_PROJECT.ADDRESS"
            labelClass="f-secondary-light"
          />
        </div>
        <div className="buttons">
          <Button type="button" variant="gray-outline" width="sm" text={t('CREATE_PROJECT.CANCEL')} onClick={onCancel} />
          <Button disabled={invalid && anyTouched} width="sm" text={t('CREATE_PROJECT.SAVE')} />
        </div>
      </form>
    );
  }
}

CreateProjectForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

CreateProjectForm = reduxForm({
  form: FORMS.createProject,
  validate,
  enableReinitialize: true,
})(CreateProjectForm);

export default CreateProjectForm;
