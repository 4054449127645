export default {
  GET_INSPECTION_DETAILS: '/inspection/details',
  GET_PROJECT_INSPECTIONS: '/company/projects/inspections',
  GET_INSPECTION_DEFECTS: '/inspection/defects',
  GET_INSPECTION_DEFECTS_LITE: '/inspection/defects-lite',
  GET_INSPECTION_MEASUREMENTS: '/inspection/measurement/list',
  GET_INSPECTION_COMPONENTS: '/inspection/component/list',
  GET_INSPECTION_COMPONENTS_LITE: '/inspection/component/lite-list',
  GET_DEFECT_DETAILS: '/inspection/defect/details',
  GET_MEASUREMENT_DETAILS: '/inspection/measurement/details',
  GET_COMPONENT_DETAILS: '/inspection/component/details',
  CREATE_NEW_DEFECT: '/inspection/defect/create',
  CREATE_NEW_MEASUREMENT: '/inspection/measurement/create',
  CREATE_NEW_COMPONENT: '/inspection/component/add',
  UPDATE_DEFECT: '/inspection/defect/update',
  UPDATE_DEFECT_GEOMETRY: '/inspection/defect/position/update',
  UPDATE_MEASUREMENT_GEOMETRY: '/inspection/measurement/position/update',
  UPDATE_MEASUREMENT: '/inspection/measurement/update',
  UPDATE_DEFECT_PROPERTY: '/inspection/defect/property/update',
  UPDATE_COMPONENT_PROPERTY: '/inspection/component/property/update',
  DELETE_DEFECT_PROPERTY: '/inspection/defect/property/delete',
  DELETE_COMPONENT_PROPERTY: '/inspection/component/property/delete',
  GET_DEFECT_PROPERTIES: '/properties/defect/get',
  GET_COMPONENT_PROPERTIES: '/properties/component/get',
  ADD_DEFECT_PROPERTY: '/properties/defect/add',
  ADD_COMPONENT_PROPERTY: '/properties/component/add',
  GET_DEFECT_PROPERTY_NAMES: '/properties/defect/names',
  GET_COMPONENT_PROPERTY_NAMES: '/properties/component/names',
  UPDATE_COMPONENT: '/inspection/component/update',
  UPDATE_COMPONENT_GEOMETRY: '/inspection/component/position/update',
  DELETE_DEFECT: '/inspection/defect/delete',
  DELETE_MEASUREMENT: '/inspection/measurement/delete',
  DELETE_COMPONENT: '/inspection/component/delete',
  ADD_DEFECT_COMMENT: '/messages/add',
  GET_DEFECT_COMMENTS: '/messages',
  ADD_MEASUREMENT_COMMENT: '/inspection/measurement/messages/add',
  GET_MEASUREMENT_COMMENTS: '/inspection/measurement/messages',
  GET_SHARE_LINKS: '/share_link/list',
  GENERATE_SHARE_LINK: '/share_link/create',
  TOGGLE_SHARE_LINK: '/share_link/activate',
  DELETE_SHARE_LINK: '/share_link/delete',
  UPDATE_INSPECTION_DETAILS: '/inspection/details/update',
  GET_COMPONENT_ASSET_SUGGESTIONS: '/shared/company/asset_type',
  GET_COMPONENT_TYPE_SUGGESTIONS: '/shared/company/component_types',
  GET_COMPONENT_SUB_TYPE_SUGGESTIONS: '/shared/company/component_sub_type',
  GET_COMPONENT_MATERIAL_SUGGESTIONS: '/shared/company/materials',
  GET_COMPONENT_MANUFACTURER_SUGGESTIONS: '/shared/company/manufacturers',
  GET_COMPONENT_LOCATION_SUGGESTIONS: '/shared/company/locations',
  EDIT_INSPECTION_DETAILS: '/inspection/update',
  GET_COMPONENT_DEFECT_TYPE_SUGGESTIONS: '/shared/company/defect_type',
  GET_COMPONENT_LINKED_DEFECTS: '/inspection/component/delete-check  ',
  UNLINK_COMPONENT_FILE: '/inspection/component/unlink',
  UNLINK_MEASUREMENT_FILE: '/inspection/measurement/files/unlink',
  DUPLICATE_COMPONENT: '/inspection/component/copy',
  GET_COMPONENT_RELATED_DEFECTS: '/inspection/component/defects/details',
  GET_UPCOMING_COMPONENT_TASKS: '/inspection/component/maintenance/task/upcoming/list',
  GET_COMPONENT_TASKS_BY_TYPE: '/inspection/component/maintenance/task/overdue/list',
  GET_COMPONENTS_CLUSTERED: '/inspection/component/clustered/list',
  GET_OBSERVATIONS_CLUSTERED: '/inspection/defect/clustered/list',
  GET_MEASUREMENTS_CLUSTERED: '/inspection/measurement/clustered/list',
  GET_OBSERVATION_TYPES: '/inspection/defect/types',
  GET_OBSERVATION_NOTIFICATIONS: '/inspection/notification/defect/list',
  GET_NDT_NOTIFICATIONS: '/inspection/notification/ndt/measurement/list',
  UPDATE_OBSERVATION_COMPONENT: '/inspection/defect/link/component',
  GET_COMPONENT_HISTORY: '/inspection/component/history/get',
  GET_COMPONENT_MODULE_HISTORY: '/inspection/component/module/history/get',
  GET_DEFECT_HISTORY: '/inspection/defect/history/get',
  GET_DEFECT_MODULE_HISTORY: '/inspection/defect/module/history/get',
  GET_MEASUREMENT_HISTORY: '/inspection/measurement/history/get',
  GET_MEASUREMENT_MODULE_HISTORY: '/inspection/measurement/module/history/get',
  GET_AREA_MODULE_HISTORY: '/inspection/area/module/history/get',
  GET_NDT_HISTORY: '/inspection/ndt/measurement/history/get',
  GET_NDT_MODULE_HISTORY: '/inspection/ndt/measurement/module/history/get',
  GET_ZONES_MODULE_HISTORY: '/inspection/atmospheric_zone/module/history/get',
  GET_INSPECTION_HIERARCHY_COMPONENTS: '/project/hierarchy/components/list',
  GET_HIERARCHY_COMPONENTS_CLUSTERED: '/project/hierarchy/components/clustered',
  GET_PANORAMIC_IMAGES: '/inspection/panoramic/images/list',
  GET_PANORAMIC_IMAGE_TILES: '/inspection/panoramic/images/tiles/list',
  GET_HIERARCHY_UNASSIGNED_COMPONENTS_CLUSTERED: '/project/hierarchy/components/unassigned/clustered',
  GET_HIERARCHY_UNASSIGNED_COMPONENTS: '/project/hierarchy/components/unassigned',
  LINK_DMS_FILE_FROM_COMPONENT: '/project/component/dms/file/link',
  UNLINK_DMS_FILE_FROM_COMPONENT: '/project/component/dms/file/unlink',
  FETCH_COMPONENT_COMMENTS: '/inspection/component/comment/list',
  ADD_COMPONENT_COMMENT: '/inspection/component/comment/add',
  DELETE_COMPONENT_COMMENT: '/inspection/component/comment/delete',
  FETCH_COMMENT_USERS_AND_TEAMS: '/inspection/comment/users/teams/list',
  FETCH_OBSERVATION_COMMENTS: '/inspection/defect/comment/list',
  ADD_OBSERVATION_COMMENT: '/inspection/defect/comment/add',
  DELETE_OBSERVATION_COMMENT: '/inspection/defect/comment/delete',
  FETCH_COMPONENTS_OBSERVATIONS: '/project/component/defects/list',
  FETCH_COMPONENTS_NOTIFICATIONS: '/project/component/notifications/list',
  FETCH_COMPONENTS_WORK_ORDERS: '/project/component/workorders/list',
  UPDATE_NOTIFICATION_GEOMETRY: '/inspection/notification/position/update',
  FETCH_OBSERVATION_FILES: '/project/document/defect/files/list',
  SAVE_OBSERVATION_DRAWING: '/inspection/defect/drawings/save',
  UPDATE_OBSERVATION_DRAWING: '/inspection/defect/drawings/update',
  GET_OBSERVATION_SUBTYPE_SUGGESTIONS: '/shared/company/defect_type',
  FETCH_NOTIFICATION_FILES: '/project/document/notification/files/list',
  SEARCH_OBSERVATION_CONTRIBUTORS: '/inspection/defect/assignments/search',
  SEARCH_CONTRIBUTORS: '/inspection/defects/assignments/list',
  GET_OBSERVATION_CONTRIBUTORS: '/inspection/defect/assignments',
  ADD_OBSERVATION_CONTRIBUTOR: '/inspection/defect/assignment/add',
  DELETE_OBSERVATION_CONTRIBUTOR: '/inspection/defect/assignment/delete',
  UNLINK_OBSERVATION_COMPONENT: '/inspection/defect/unlink/component',
  FETCH_WORKORDER_FILES: '/project/document/workorders/files/list',
  FETCH_WORKORDER_COMPLETION_FILES: '/inspection/workorder/dms/completion/files/list',
  FETCH_OBSERVATION_WORK_ORDERS: '/inspection/defect/workorders/list',
  FETCH_WORK_ORDERS_FOR_OBSERVATION: '/inspection/defect/workorders/search',
  LINK_WORK_ORDER_TO_OBSERVATION: '/inspection/defect/link/workorder',
  UNLINK_WORK_ORDER_FROM_OBSERVATION: '/inspection/defect/unlink/workorder',
  UPDATE_COMPONENT_PROPERTIES: '/properties/component/batch/add',
  FETCH_COMPONENT_REGIMES: '/project/component/regime/list',
  FETCH_WORKORDER_EVIDENCE_FILES: '/project/workorder/dms/evidence/files/list',
};
