import { FEATURES } from '../../../../../../common/constants';

export const componentDetailsDisplayProps = [
  { name: 'ID' },
  {
    name: 'Name',
  },
  {
    name: 'Code',
  },
  {
    name: 'Type',
  },
];

export const defectNotificationFields = {
  name: 'Name',
  severity: 'Severity',
  properties: 'Properties',
  fieldType: 'FieldType',
  dependency: 'Dependency',
  fieldValues: 'FieldValues',
  value: 'Value',
  order: 'Order',
  linkedComponent: 'LinkedComponent',
};

export const linkedComponentDetailsDisplayProps = [
  {
    name: 'Type',
    title: 'NOTIFICATION_FORM.SOURCE_DETAILS_MainType',
  },
  {
    name: 'SubType',
    title: 'NOTIFICATION_FORM.SOURCE_DETAILS_Type',
  },
  {
    name: 'Code',
    title: 'COMPONENTS.CODE',
  },
  {
    name: 'CreatedBy',
    title: 'DOCUMENT_HIERARCHY_FORM.CREATED_BY',
  },
];

export const fields = {
  icon: 'icon',
  id: 'ID',
  name: 'Name',
  status: 'Status',
  externalID: 'ExternalNotificationID',
  externalIDShort: 'ExternalID',
};

export const defectNotificationsTableConfig = [
  {
    title: '',
    key: fields.status,
    type: fields.icon,
    class: 'icon-column',
    isSort: false,
  },
  {
    title: FEATURES.notifications.externalID.visible ? 'EXTERNAL_ID_SHORT' : 'ID',
    key: FEATURES.notifications.externalID.visible ? fields.externalID : fields.id,
    type: fields.id,
    class: 'id-column',
    isSort: false,
  },
  {
    title: 'NAME',
    key: fields.name,
    type: fields.name,
    class: 'name-column',
    isSort: false,
  },
];

export const defectWorkOrdersTableConfig = [
  {
    title: '',
    key: fields.status,
    type: fields.icon,
    class: 'icon-column',
  },
  {
    title: FEATURES.workorders.externalID ? 'EXTERNAL_ID_SHORT' : 'ID',
    key: FEATURES.workorders.externalID ? fields.externalIDShort : fields.id,
    type: fields.id,
    class: 'id-column',
    isSort: false,
  },
  {
    title: 'NAME',
    key: fields.name,
    type: fields.name,
    class: 'name-column',
    isSort: false,
  },
];
