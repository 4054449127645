import { find, findIndex, isEmpty, reverse, uniqBy, values } from 'lodash';

import Helpers from '../../../common/helpers';
import { questionTypes } from '../../../common/question-components/constants/question-constants';
import { statuses as permitStatuses } from '../components/permits/constants/permit-constants';
import { questionFields } from '../components/right-toolbar/isolation-certificate-details/constants/change-status-constants';
import { fields } from '../components/work-order/constants/work-order-right-side-constants';
import { defaultComponentName } from '../constants/component-constants';
import { measurementTypes, modules } from '../constants/constants';
import ElementActions from '../constants/element-actions';
import { statuses } from '../constants/work-order-constants';

export const getElementActions = data => {
  return ElementActions[data.SystemType];
};

export const getElementActionsByActiveSidebar = activeTab => {
  switch (activeTab) {
    case modules.measurements:
      return ElementActions[measurementTypes.measurement];
    case modules.defects:
      return ElementActions[measurementTypes.defect];
    case modules.components:
      return ElementActions[measurementTypes.component];
    case modules.notifications:
      return ElementActions[measurementTypes.notification];
    case modules.readingsAndGauges:
      return ElementActions[measurementTypes.rgMeasurementLocation];
    default:
      return null;
  }
};

export const getHistoryLength = (history = [], events = {}, historyItems = {}, moduleCreateEvent, moduleDeleteEvent) => {
  const result = [];

  for (let i = 0; i < history.length; i++) {
    if (history[i].eventType === moduleCreateEvent || history[i].eventType === moduleDeleteEvent || (events[history[i].eventType] && historyItems[history[i].columnName])) {
      result.push(history[i]);
    }
  }

  return result.length;
};

export const mapSections = async (module, sections, sectionName, alternateSectionName, cleanUpDuplicateQuestion) => {
  const moduleSections = Object.assign([], module.Sections);

  moduleSections.map(item => {
    let section = {};

    if (alternateSectionName) {
      item[alternateSectionName] = item[sectionName];
    }

    if (item.Locked && sections[item[sectionName]]) {
      section = sections[item[sectionName]];

      if (section.fields) {
        for (let key in section.fields) {
          section[section.fields[key].name] = module[section.fields[key].name];
        }
      }
    }

    if (item.SectionQuestions && cleanUpDuplicateQuestion) {
      section.SectionQuestions = reverse(uniqBy(reverse(item.SectionQuestions), 'ID'));
      section.SectionQuestions.forEach((question, index) => {
        if (question.QuestionOptions) {
          section.SectionQuestions[index].QuestionOptions = reverse(uniqBy(reverse(question.QuestionOptions), 'ID'));
        }
      });
    }

    return Object.assign(item, section);
  });

  module.Sections = moduleSections;

  return module;
};

export const getModuleQuestionFieldValue = (name, question, value, change, formValues) => {
  switch (question[questionFields.type]) {
    case questionTypes.DATE.valueId:
      return Helpers.isDateValid(value) ? Helpers.getCurrentDateAndTimeInMs(value) : value;
    case questionTypes.NUMBER.valueId:
      return value ? Number(value) : value;
    case questionTypes.MULTI_ANSWER.valueId:
      const questionOptions = Object.assign([], question[questionFields.questionOptions]);

      if (!question[questionFields.allowMultipleAnswers]) {
        for (let i = 0; i < questionOptions.length; i++) {
          const fieldName = `${name}-option-${questionOptions[i][questionFields.id]}`;
          const fieldValue = questionOptions[i][questionFields.id] === value && !formValues[fieldName] ? value : null;

          change(fieldName, fieldValue);
        }
      }

      return value;
    case questionTypes.CHECKBOX.valueId:
      change(`${name}-yes`, value ? 'true' : null);
      change(`${name}-no`, value ? null : 'true');

      return value;
    default:
      return value;
  }
};

export const getQuestionType = (type, types) => {
  const selected = find(types, { valueId: type });

  return selected || {};
};

export const getModuleQuestionsInitialValues = (answers, name) => {
  const initialValues = {};
  const types = values(questionTypes);
  let fieldName = '';

  for (let i = 0; i < answers.length; i++) {
    fieldName = `${name}-${answers[i][questionFields.id]}`;
    const type = getQuestionType(answers[i][questionFields.type], types);
    switch (answers[i][questionFields.type]) {
      case questionTypes.MULTI_ANSWER.valueId:
        if (answers[i][type.fieldName] && answers[i][type.fieldName].length) {
          const selectedOptions = Object.assign([], answers[i][type.fieldName]);
          for (let j = 0; j < selectedOptions.length; j++) {
            initialValues[`${fieldName}-option-${selectedOptions[j]}`] = selectedOptions[j];
          }
        }
        break;
      case questionTypes.CHECKBOX.valueId:
        initialValues[`${fieldName}-yes`] = answers[i][type.fieldName] ? 'true' : null;
        initialValues[`${fieldName}-no`] = answers[i][type.fieldName] === false ? 'false' : null;
        break;
      default:
        initialValues[fieldName] = answers[i][type.fieldName];
        break;
    }
  }

  return initialValues;
};

export const isWorkOrderChangeStatusValid = (status, permits) => {
  let valid = {
    isValid: true,
    id: '',
    message: '',
    contentId: '',
    buttonText: '',
  };

  // if WO status is about to be changed to "Authorised" or "In Progress", all permits needs
  // to be in either "Issued" or "Re-Issued" status
  if ([statuses.authorised.value, statuses.inProgress.value].indexOf(status) > -1 && permits && permits.length) {
    const index = findIndex(permits, permit => {
      return permit[fields.status] !== permitStatuses.issued.value && permit[fields.status] !== permitStatuses.reissued.value && permit[fields.status] !== permitStatuses.handback.value;
    });

    if (index > -1) {
      valid = {
        isValid: false,
        message: 'WORK_ORDER.PERMITS_NOT_VALID',
        id: `module-section-title-Permits`,
        contentId: 'module-section-content-Permits',
        buttonText: 'WORK_ORDER.SEE_PERMITS',
      };
    }
  }

  // if WO status is about to be changed to "Completed", all permits needs
  // to be in the Hand Back status
  if (status === statuses.completed.value && permits && permits.length) {
    const index = findIndex(permits, permit => {
      return permit[fields.status] !== permitStatuses.handback.value;
    });

    if (index > -1) {
      valid = {
        isValid: false,
        message: 'WORK_ORDER.PERMITS_HANDBACK_NOT_VALID',
        id: `module-section-title-Permits`,
        contentId: 'module-section-content-Permits',
        buttonText: 'WORK_ORDER.SEE_PERMITS',
      };
    }
  }

  return valid;
};

export const componentHasAllCoordinatesZero = coordinates => {
  // meaning it is clicked outside the 3D model; coordinates [0, 0, 0]
  for (const coordinate of coordinates) {
    if (coordinate.some(value => value !== 0)) {
      return false;
    }
  }
  return true;
};

export const setCameraPositionAndFocusOnFirstWorkArea = (cameraPos, workAreas, viewer) => {
  if (isEmpty(workAreas) || isEmpty(viewer)) {
    return;
  }
  const firstWorkArea = workAreas[0];
  if (
    !isEmpty(cameraPos?.coordinates) &&
    !componentHasAllCoordinatesZero([cameraPos?.coordinates]) &&
    !isEmpty(firstWorkArea.Geometry?.coordinates) &&
    !componentHasAllCoordinatesZero(firstWorkArea.Geometry?.coordinates)
  ) {
    // Set camera position and focus on first work area
    viewer.zoomToPosition({ x: cameraPos.coordinates[0], y: cameraPos.coordinates[1], z: cameraPos.coordinates[2] }, firstWorkArea.Geometry.coordinates, 500);
  }
};

/**
 * The function `isDefaultComponent` checks if a given component matches the default component name or
 * ID.
 * @param component - The `component` parameter is an object representing a component in a software
 * system. It contains information such as the component's name, id, and other
 * properties.
 * @param defaultComponentId - The `defaultComponentId` parameter is the unique identifier of the
 * default component that is being compared with the component passed to the `isDefaultComponent`
 * function. The function checks if the component's name or id matches the default component's name or
 * id to determine if it is the default component.
 * @returns The function `isDefaultComponent` is returning a boolean value indicating whether the given
 * `component` matches the default component based on the `defaultComponentId` or
 * `defaultComponentName`.
 */
export const isDefaultComponent = (component, defaultComponentId) => {
  return component[fields.name] === defaultComponentName || component[fields.id] === defaultComponentId;
};
