import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { get } from 'lodash';
import { Field, arraySplice } from 'redux-form';

import Icon from '../../../common/icon/components/icon';
import CustomInput from '../../../common/form/components/input';
import ButtonWithIcon from '../../../common/button-with-icon/button-with-icon';
import RenderIf from '../../../common/render-if/components/render-if';
import { initialCustomPropFilter, fields as fieldConstants } from '../../../common/advanced-filter-custom-property/constants/constants';

const ChecklistsProceduresCustomPropertyFields = (props, { t }) => {
  const { fields, form, arrayName, formValues, arraySplice, onChange, deleteCustomProperty, isDisabled } = props;
  const removeField = (field, index) => {
    // Here fields.remove should have been used but since we use array of object the problem was not solvable
    // For more info you can check out https://github.com/redux-form/redux-form/issues/2666
    const property = get(formValues, field);
    if (property?.[fieldConstants.id]) {
      deleteCustomProperty(property, () => {
        // callback success
        arraySplice(form, arrayName, index);
      });
    } else {
      arraySplice(form, arrayName, index);
    }
  };

  const isCustomPropertyPresent = formValues?.[arrayName]?.length > 0;
  return (
    <>
      {fields.map((field, index) => {
        const currentProperty = get(formValues, field);
        const isFieldNameEmpty = !currentProperty?.[fieldConstants.name];

        return (
          <div className="advanced-filter-custom-property-field">
            <div className="advanced-filter-custom-property-field__fields-container">
              <div className="advanced-filter-custom-property-field__fields-container__field">
                <Field
                  name={`${field}.${fieldConstants.name}`}
                  component={CustomInput}
                  size="lg"
                  placeholder={'DEFECT_PROPERTY.LABEL_PLACEHOLDER'}
                  label={'DEFECT_PROPERTY.LABEL'}
                  handleChange={val => onChange({ ...currentProperty, [fieldConstants.name]: val }, val, index, fieldConstants.name)}
                  key={`${index}_${fields.length}_Name`}
                  disabled={isDisabled}
                />
              </div>

              <div className="advanced-filter-custom-property-field__fields-container__field">
                <Field
                  name={`${field}.${fieldConstants.value}`}
                  component={CustomInput}
                  size="lg"
                  label={'PROPERTY.VALUE_LABEL'}
                  placeholder={'PROPERTY.VALUE_PLACEHOLDER'}
                  key={`${index}_${fields.length}_Value`}
                  handleChange={val => onChange({ ...currentProperty, [fieldConstants.value]: val }, val, index, fieldConstants.value)}
                  disabled={!isCustomPropertyPresent || (isCustomPropertyPresent && isFieldNameEmpty) || isDisabled}
                />
              </div>
              <Icon name="close" handleHover={!isDisabled} handleFocus={false} onClick={() => (!isDisabled ? removeField(field, index) : null)} />
            </div>
          </div>
        );
      })}
      <RenderIf if={!isDisabled}>
        <ButtonWithIcon
          icon="plus-circle"
          text={t('NEW_PROPERTY')}
          noBorder
          type="success"
          bold
          customClassName="mt-20"
          onClick={() => {
            fields.push(initialCustomPropFilter);
          }}
        />
      </RenderIf>
    </>
  );
};

ChecklistsProceduresCustomPropertyFields.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  arraySplice: (form, field, index) => dispatch(arraySplice(form, field, index, 1)),
});

export default connect(null, mapDispatchToProps)(ChecklistsProceduresCustomPropertyFields);
