import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

class SMSType extends Component {
  componentDidMount = () => {};

  render() {
    return (
      <Fragment>
        <p className="f-primary">sms type</p>
      </Fragment>
    );
  }
}

SMSType.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default SMSType;
