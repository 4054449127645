import { MAX_SAFE_DECIMAL, MIN_SAFE_DECIMAL } from '../../../../../common/constants';
import { measurementReadingFormConstants } from '../constants/measurement-readings-constants';

export const measurementReadingValidate = values => {
  let errors = {};
  const valuesArray = values[measurementReadingFormConstants.fields.values];

  if (valuesArray) {
    const valuesErrors = [];
    valuesArray.forEach((value, index) => {
      if (!value) {
        valuesErrors[index] = 'DEFECT_PROPERTY.ERROR.VALUE.REQUIRED_FIELD';
      } else if (isNaN(value)) {
        valuesErrors[index] = 'READINGS_AND_GAUGES.MEASUREMENT_READINGS_FORM.READING.INCORRECT_FORMAT';
      } else if (value < MIN_SAFE_DECIMAL) {
        valuesErrors[index] = 'READING.MIN_DECIMAL_ERROR';
      } else if (value > MAX_SAFE_DECIMAL) {
        valuesErrors[index] = 'READING.MAX_DECIMAL_ERROR';
      }
    });

    if (valuesErrors.length) {
      errors[measurementReadingFormConstants.fields.values] = valuesErrors;
    }
  }

  return errors;
};
