import loginActionTypes from '../constants/action-types';
import loginConstants from '../constants/login-constants';

const loginState = {
  loginError: null,
  loginStep: loginConstants.loginSteps.LOGIN,
  loginTempToken: null,

  resendExpiration: loginConstants.defaultResendExpirationTime,
};

export const loginReducer = (state = loginState, payload) => {
  switch (payload.type) {
    case loginActionTypes.HANDLE_LOGIN_ERROR:
      return { ...state, loginError: payload.data };
    case loginActionTypes.SET_LOGIN_STEP:
      return { ...state, loginStep: payload.data };
    case loginActionTypes.SET_LOGIN_TEMP_TOKEN:
      return { ...state, loginTempToken: payload.data };
    case loginActionTypes.RESEND_EXPIRATION:
      return { ...state, resendExpiration: payload.data || loginConstants.defaultResendExpirationTime };
    default:
      return state;
  }
};
