import { find, findIndex, findLast, isEmpty, isNumber, isObject } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Stepper from '../../../../common/stepper/components/stepper';
import CreateRegimeForm from './create-regime-form';

import { fileGroups, formConstants, statuses } from '../../constants/regime-constants';

import { priorities } from '../../../inspections/constants/work-order-constants';
import { intervalFields, sources, steps } from '../../constants/regime-constants';

import Helpers from '../../../../common/helpers';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../common/permissions-constants';
import RenderIf from '../../../../common/render-if/components/render-if';
import '../../styles/maintenance-regime-create-modal.scss';

class CreateRegimeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: props.requestedStep ? props.requestedStep : steps.firstStep,
      lastStep: steps.sixthStep,
      stepperData: [
        {
          name: 'CREATE_REGIME_POPUP.STEP_1',
          stepValue: steps.firstStep,
          description: 'CREATE_REGIME_POPUP.STEP_1_DESC',
        },
        {
          name: 'CREATE_REGIME_POPUP.STEP_2',
          stepValue: steps.secondStep,
          description: 'CREATE_REGIME_POPUP.STEP_2_DESC',
        },
        {
          name: 'CREATE_REGIME_POPUP.STEP_3',
          stepValue: steps.thirdStep,
          description: 'CREATE_REGIME_POPUP.STEP_3_DESC',
        },
        {
          name: 'CREATE_REGIME_POPUP.STEP_4',
          stepValue: steps.fourthStep,
          description: 'CREATE_REGIME_POPUP.STEP_4_DESC',
        },
        {
          name: 'CREATE_REGIME_POPUP.STEP_5',
          stepValue: steps.fifthStep,
          description: 'CREATE_REGIME_POPUP.STEP_5_DESC',
          access: {
            visibleFor: [PERMISSIONS[PERMISSION_TYPES.maintenanceRegimes].regimeCPLink.name],
          },
        },
        {
          name: 'CREATE_REGIME_POPUP.STEP_6',
          stepValue: steps.sixthStep,
          description: 'CREATE_REGIME_POPUP.STEP_6_DESC',
        },
      ],
    };
  }

  componentDidMount = () => {
    let { activeStep, stepperData, lastStep } = this.state;
    const { searchComponents, components, user } = this.props;

    const mappedStepperData = stepperData.map(step => ({ ...step, isHidden: step.access ? !Helpers.hasAccess({ user, ...step.access }) : false }));
    const lastStepData = findLast(mappedStepperData, step => !step.isHidden);
    const newLastStep = !isEmpty(lastStepData) ? lastStepData.stepValue : lastStep;
    this.setState({ lastStep: newLastStep, stepperData: mappedStepperData.filter(step => !step.isHidden) });

    if (activeStep === steps.fourthStep && components && components.length === 0) {
      // in case inspection components are not fetched (i.e. edit of regime components), fetch inspection components
      searchComponents('');
    }
  };

  handleSubmit = values => {
    const {
      regime,
      createRegime,
      updateRegime,
      getRegimeComponents,
      getRegimeFiles,
      getIntervalList,
      updateInterval,
      searchComponents,
      closeAction,
      intervalList,
      searchChecklists,
      saveChecklists,
      regimeChecklists,
      isCreate,
    } = this.props;
    let { activeStep, lastStep } = this.state;

    if (activeStep === steps.firstStep && activeStep !== lastStep) {
      values = {
        ...values,
        [formConstants.fields.importance]: (values[formConstants.fields.importance] && values[formConstants.fields.importance].Value) || values[formConstants.fields.importance],
      };

      if (!this.canEdit()) {
        this.setStep(true);
      } else {
        // if it's update, just move to next step, API will be triggered on the last step
        if (regime && regime[formConstants.fields.id]) {
          this.setStep(true);
        } else {
          createRegime(values, () => {
            this.setStep(true);
          });
        }
      }
    } else if (activeStep === steps.secondStep && activeStep !== lastStep) {
      getRegimeFiles(fileGroups.regimeFiles);
      this.setStep(true);
    } else if (activeStep === steps.thirdStep && activeStep !== lastStep) {
      searchComponents('');
      getRegimeComponents(regime[formConstants.fields.id], () => this.setStep(true));
      // if isCreate is active, fetch checklists for the checklists step and go to the next step
    } else if (activeStep === steps.fourthStep && activeStep !== lastStep && isCreate) {
      searchChecklists('');
      this.setStep(true);
    } else if (activeStep === steps.fifthStep && activeStep !== lastStep) {
      getIntervalList(() => this.setStep(true));
      const ChecklistTemplateIDs = Helpers.removeStandaloneCPTemplatesIfLinked(regimeChecklists).map(el => ({ [formConstants.fields.id]: el[formConstants.fields.id], ComponentID: el.ComponentID }));
      saveChecklists(regime[formConstants.fields.id], { ChecklistTemplateIDs });
      // if isCreate is not active, means it is triggered from the right-hand side, but still needs to trigger the update
    } else if (activeStep === lastStep || !isCreate) {
      if (!this.canEdit()) {
        closeAction(activeStep);
        return;
      }

      const data = Object.assign({}, values);
      data[formConstants.fields.taskIntervalNumber] = data[formConstants.fields.taskIntervalNumber] || null;
      data[formConstants.fields.occurancesTreshold] = data[formConstants.fields.occurancesTreshold] || null;

      if (data[formConstants.fields.startDate]) {
        const startDate = new Date(data[formConstants.fields.startDate]).getTime();
        data[formConstants.fields.startDate] = Helpers.getUnixDate(startDate);
      }

      if (data[formConstants.fields.endDate]) {
        const endDate = new Date(data[formConstants.fields.endDate]).getTime();
        data[formConstants.fields.endDate] = Helpers.getUnixDate(endDate);
      }

      if (data[formConstants.fields.intervalId] && isObject(data[formConstants.fields.intervalId])) {
        data[formConstants.fields.intervalId] = data[formConstants.fields.intervalId][intervalFields.id];
      }

      if (data[formConstants.fields.taskIntervalId] && isObject(data[formConstants.fields.taskIntervalId])) {
        data[formConstants.fields.taskIntervalId] = data[formConstants.fields.taskIntervalId][intervalFields.id];
      }

      const interval = find(intervalList, { [intervalFields.id]: data[formConstants.fields.intervalId] });

      if (interval) {
        data[formConstants.fields.intervalTaskName] = interval[intervalFields.name];
      }

      if (!data[formConstants.fields.recurringTask]) {
        // if recurring task is false, send interval as null
        data[formConstants.fields.taskIntervalId] = null;
        data[formConstants.fields.intervalName] = '';
        data[formConstants.fields.intervalNumber] = null;
      }

      if (regime && regime[formConstants.fields.id]) {
        updateRegime({ ...data, [formConstants.fields.createdByUser]: data && data[formConstants.fields.createdByUser].toString() }, () => this.setStep(true));
      } else {
        updateInterval(data, () => this.setStep(true));
      }
    }
  };

  setStep = forward => {
    const { closeAction, requestedStep } = this.props;
    let { activeStep, lastStep, stepperData } = this.state;
    const nextStep = forward ? ++activeStep : --activeStep;
    if (nextStep < steps.firstStep || nextStep > lastStep || requestedStep) {
      closeAction(activeStep);
      return;
    }
    if (findIndex(stepperData, stepData => stepData.stepValue === nextStep) > -1) {
      // step is not hidden
      this.setState({ activeStep: nextStep });
      return;
    } else {
      this.setState({ activeStep: nextStep }, () => this.setStep(forward));
    }
  };

  getIntervalIdInitialValue = (initialValues, intervalList, fieldName, intervalName) => {
    if (initialValues && initialValues[fieldName]) {
      return initialValues[fieldName];
    }

    if (initialValues && initialValues[intervalName] && intervalList && intervalList.length > 0) {
      const interval = find(intervalList, { [intervalFields.name]: initialValues[intervalName] });

      if (interval) {
        return interval[intervalFields.id];
      }
    }

    if (initialValues && initialValues[fieldName]) {
      return isObject(initialValues[fieldName]) ? initialValues[fieldName][intervalFields.id] : initialValues[fieldName];
    }

    if (intervalList && intervalList.length > 0) {
      return intervalList[0][intervalFields.id];
    }

    return null;
  };

  getFormInitialValues = intervalList => {
    const { regime, formValues } = this.props;

    if (formValues && isObject(formValues[formConstants.fields.intervalId])) {
      formValues[formConstants.fields.intervalId] = formValues[formConstants.fields.intervalId][formConstants.fields.id];
    }

    if (formValues && isObject(formValues[formConstants.fields.taskIntervalId])) {
      formValues[formConstants.fields.taskIntervalId] = formValues[formConstants.fields.taskIntervalId][formConstants.fields.id];
    }

    const initialValues = Object.assign(regime || {}, formValues || {});

    if (initialValues[formConstants.fields.startDate] && isNumber(initialValues[formConstants.fields.startDate])) {
      initialValues[formConstants.fields.startDate] = new Date(parseFloat(initialValues[formConstants.fields.startDate] + '000'));
    }

    if (initialValues[formConstants.fields.endDate] && isNumber(initialValues[formConstants.fields.endDate])) {
      initialValues[formConstants.fields.endDate] = new Date(parseFloat(initialValues[formConstants.fields.endDate] + '000'));
    }

    // for now we will hardcode the source to always be work order, later we will have the option to switch it to be notification as well
    // initialValues[formConstants.fields.source] = initialValues && initialValues[formConstants.fields.source] ? initialValues[formConstants.fields.source] : sources.workOrder;
    initialValues[formConstants.fields.source] = sources.workOrder;
    initialValues[formConstants.fields.importance] = initialValues && initialValues[formConstants.fields.importance] ? initialValues[formConstants.fields.importance] : priorities.none.value;
    initialValues[formConstants.fields.intervalId] = this.getIntervalIdInitialValue(initialValues, intervalList, formConstants.fields.intervalId, formConstants.fields.intervalTaskName);
    initialValues[formConstants.fields.intervalNumber] = initialValues[formConstants.fields.intervalNumber] || 0;
    initialValues[formConstants.fields.taskIntervalId] = this.getIntervalIdInitialValue(initialValues, intervalList, formConstants.fields.taskIntervalId, formConstants.fields.intervalName);
    initialValues[formConstants.fields.taskIntervalNumber] = initialValues[formConstants.fields.taskIntervalNumber] || 0;

    return initialValues;
  };

  canEdit = () => {
    const { regime, isPreviewMode } = this.props;

    return [statuses.live.value].indexOf(regime?.[formConstants.fields.status] || '') === -1 && !isPreviewMode;
  };

  render() {
    const { stepperData, activeStep, lastStep } = this.state;
    const {
      className,
      regime,
      openFilesModalData,
      getRegimeFiles,
      regimeFiles,
      unlinkRegimeFile,
      regimeComponents,
      searchComponents,
      components,
      addComponentToRegime,
      removeComponentFromRegime,
      checklistList,
      searchChecklists,
      regimeChecklists,
      addChecklistToTheRegime,
      deleteRegimeChecklist,
      intervalList,
      setConfirmModalData,
      closeConfirmModal,
      severityColors,
      closeAction,
      handleComponentPicker3D,
      handleComponentPickerPDF,
      handleComponentPickerCH,
      formValues,
      requestedStep,
      user,
      linkRegimeCPTemplateToComponents,
      unlinkRegimeCPTemplateFromComponents,
      checklists,
      removeComponentFromCPTemplate,
      additionalWorkorderTypes,
    } = this.props;

    return (
      <div className={`maintenance-regime-create-modal ${className || ''}`}>
        <RenderIf if={!requestedStep}>
          <Stepper {...{ stepperData, activeStep }} />
        </RenderIf>
        {intervalList && intervalList.length > 0 && (
          <CreateRegimeForm
            onSubmit={this.handleSubmit}
            stepAction={this.setStep}
            initialValues={this.getFormInitialValues(intervalList)}
            activeStep={activeStep}
            lastStep={lastStep}
            isIntervalEnabled={(regime && regime[formConstants.fields.intervalId] > 0) || false}
            regimeFiles={regimeFiles}
            openFilesModalData={type =>
              openFilesModalData(type, () => {
                getRegimeFiles(type);
              })
            }
            unlinkRegimeFile={unlinkRegimeFile}
            regimeComponents={regimeComponents}
            searchComponents={searchComponents}
            searchChecklists={searchChecklists}
            components={components}
            addComponentToRegime={addComponentToRegime}
            removeComponentFromRegime={removeComponentFromRegime}
            checklistList={checklistList}
            regimeChecklists={regimeChecklists}
            checklists={checklists}
            addChecklistToTheRegime={addChecklistToTheRegime}
            deleteRegimeChecklist={deleteRegimeChecklist}
            intervalList={intervalList}
            setConfirmModalData={setConfirmModalData}
            closeConfirmModal={closeConfirmModal}
            severityColors={severityColors}
            closeAction={closeAction}
            handleComponentPicker3D={handleComponentPicker3D}
            handleComponentPickerPDF={handleComponentPickerPDF}
            handleComponentPickerCH={handleComponentPickerCH}
            formValues={formValues}
            isPreviewMode={!this.canEdit()}
            hideCheckbox={false}
            user={user}
            linkRegimeCPTemplateToComponents={linkRegimeCPTemplateToComponents}
            unlinkRegimeCPTemplateFromComponents={unlinkRegimeCPTemplateFromComponents}
            removeComponentFromCPTemplate={removeComponentFromCPTemplate}
            additionalWorkorderTypes={additionalWorkorderTypes}
            // disable step navigation if edit action came from right-hand side details section (Files, Components)
            disableStepNavigation={requestedStep}
          />
        )}
      </div>
    );
  }
}

CreateRegimeModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default CreateRegimeModal;
