import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../icon/components/icon';

const CommentsEmptyState = ({ className }, { t }) => {
  return (
    <div className={`${className ? className : ''} comments-empty-state-wrapper`}>
      <Icon name="message-circle" className="comments-empty-state-wrapper__icon" />
      <p className="comments-empty-state-wrapper__title">{t('COMMENTS.EMPTY_STATE.NO_COMMENTS_TITLE')}</p>
      <p className="comments-empty-state-wrapper__description">{t('COMMENTS.EMPTY_STATE.NO_COMMENTS_DESCRIPTION')}</p>
    </div>
  );
};

CommentsEmptyState.contextTypes = {
  t: PropTypes.func.isRequired,
};

CommentsEmptyState.propTypes = {
  className: PropTypes.string,
};

CommentsEmptyState.defaultProps = {
  className: '',
};

export default CommentsEmptyState;
