export const fields = {
  id: 'ID',
  name: 'Name',
};

export const formFields = {
  name: {
    name: fields.name,
    label: 'SECTIONS.NAME_LABEL',
    placeholder: 'SECTIONS.NAME_PLACEHOLDER',
    errorRequired: 'SECTIONS.NAME_ERROR_REQUIRED',
    errorMinChars: 'SECTIONS.NAME_ERROR_MIN_CHARS',
    errorExistingName: 'SECTIONS.NAME_ERROR_EXISTING',
    errorMaxChars: 'SECTIONS.NAME_ERROR_MAX_CHARS',
    minChars: 3,
    maxChars: 100,
  },
};

export const defaultSectionQuestionsName = 'SectionQuestions';
