import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import RenderIf from '../../../../common/render-if/components/render-if';
import Icon from '../../../../common/icon/components/icon';
import PropTypes from 'prop-types';
import { fields } from '../../constants/constants';
import { orderBy } from 'lodash';
import '../../styles/dms-labels.scss';

const DMSFileLabels = (props, { t }) => {
  const { isAdjustedForSmallerScreen, selectedDMSFileLabels } = props;
  const [isExpanded, setIsExpanded] = useState(false);

  const sortedSelectedDMSFileLabels = useMemo(() => {
    return orderBy(selectedDMSFileLabels, fields.name, 'asc');
  }, [selectedDMSFileLabels]);

  if (!selectedDMSFileLabels || selectedDMSFileLabels.length < 1) return <div className="labels-empty-state">{t('NO_LABEL_ADDED')}</div>;

  const isRegularView = !isAdjustedForSmallerScreen || (isAdjustedForSmallerScreen && isExpanded);

  const renderLabels = () => {
    return sortedSelectedDMSFileLabels.map(item => {
      return (
        <div key={`dms-labels-item-${item[fields.name]}`} className={`dms-labels__wrapper__item `}>
          {item[fields.colour] && item[fields.colour] !== '' && <div className="dms-labels__wrapper__item__color" style={{ backgroundColor: item[fields.colour] }} />}
          <p className={`dms-labels__wrapper__item__text f-primary light-bold`}>{item[fields.name]}</p>
        </div>
      );
    });
  };

  return (
    <div>
      <div className={`dms-labels ${isRegularView ? 'dms-labels-expanded' : ''}`}>
        <div className={`dms-labels__wrapper ${isRegularView ? 'dms-labels-expanded__wrapper' : ''}`}>
          <RenderIf if={isExpanded}>
            <div className="dms-labels__more-wrapper dms-labels__more-wrapper-adjusted" onClick={() => setIsExpanded(false)}>
              <Icon name="minus" />
              <p className="f-primary light-bold">{t('STATUS_FILTERS.LESS')}</p>
            </div>
          </RenderIf>
          {renderLabels()}
        </div>

        {isAdjustedForSmallerScreen && (
          <RenderIf if={!isExpanded}>
            <div className="dms-labels__more-wrapper" onClick={() => setIsExpanded(true)}>
              <Icon name="plus" />
              <p className="f-primary light-bold">{t('STATUS_FILTERS.MORE')}</p>
            </div>
          </RenderIf>
        )}
      </div>
    </div>
  );
};

DMSFileLabels.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  selectedDMSFileLabels: state.dmsLabelsReducer.selectedDMSFileLabels,
});

export default withRouter(connect(mapStateToProps, null)(DMSFileLabels));
