import { fetchComponentRegimes } from '../../../../../api/inspection/actions';

export const getComponentRegimes = (filters, dataCallback, loadingCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      const res = await fetchComponentRegimes(filters);
      const { Data } = res?.data;
      const { Regimes, ...rest } = Data;
      dataCallback && typeof dataCallback === 'function' && dataCallback(Regimes, { ...filters, ...rest });
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
    }
  };
};
