import React from 'react';
import PropTypes from 'prop-types';

const Entry = (props, { t }) => {
  const { mention, theme, ...parentProps } = props;

  return (
    <div {...parentProps}>
      <div className={'mentionSuggestionsEntryContainer'}>
        <div className={'mentionSuggestionsEntryContainerRight'}>
          <div className={theme.mentionSuggestionsEntryText}>{mention.Name}</div>
          {mention.Email && <p className={theme.mentionSuggestionsEntrySubText}>{mention.Email}</p>}
          {mention.MembersCount && <p className={theme.mentionSuggestionsEntrySubText}>{t('MENTION.TEAM_MEMBERS', { number: mention.MembersCount })}</p>}
        </div>
      </div>
    </div>
  );
};
export default Entry;

Entry.contextTypes = {
  t: PropTypes.func.isRequired,
};
