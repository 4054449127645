import { store } from '../../../../../index';
import { updateElementInArray } from '../../../actions/action-creators';
import actionTypes from '../constants/action-types';
import { formConstants } from '../constants/measurement-location-constants';

// MEASUREMENT GROUP ACTIONS
export const setMeasurementGroup = data => {
  return {
    data,
    type: actionTypes.SET_MEASUREMENT_GROUP,
  };
};
export const setMeasurementGroups = data => {
  return {
    data,
    type: actionTypes.SET_MEASUREMENT_GROUPS,
  };
};

export const setMeasurementGroupsClustered = data => {
  return {
    data,
    type: actionTypes.SET_MEASUREMENT_GROUPS_CLUSTERED,
  };
};

export const toggleMeasurementGroupVisibility = data => {
  return {
    data,
    type: actionTypes.TOGGLE_MEASUREMENT_GROUP_VISIBILITY,
  };
};

export const selectAllMeasurementGroups = data => {
  return {
    data,
    type: actionTypes.SELECT_ALL_MEASUREMENT_GROUPS,
  };
};

export const deselectAllMeasurementGroups = data => {
  return {
    data,
    type: actionTypes.DESELECT_ALL_MEASUREMENT_GROUPS,
  };
};

// MEASUREMENT LOCATION ACTIONS
export const selectAllMeasurementLocations = data => {
  return {
    data,
    type: actionTypes.SELECT_ALL_MEASUREMENT_LOCATIONS,
  };
};

export const deselectAllMeasurementLocations = data => {
  return {
    data,
    type: actionTypes.DESELECT_ALL_MEASUREMENT_LOCATIONS,
  };
};

export const toggleMeasurementLocation = data => {
  return {
    data,
    type: actionTypes.TOGGLE_MEASUREMENT_LOCATION,
  };
};

export const setMeasurementLocations = data => {
  return {
    data,
    type: actionTypes.SET_MEASUREMENT_LOCATIONS,
  };
};

export const amendMeasurementLocations = data => {
  return {
    data,
    type: actionTypes.AMEND_MEASUREMENT_LOCATIONS,
  };
};

export const setMeasurementLocationsClustered = data => {
  return {
    data,
    type: actionTypes.SET_MEASUREMENT_LOCATIONS_CLUSTERED,
  };
};

export const setMeasurementLocationsFilters = data => {
  return {
    type: actionTypes.SET_MEASUREMENT_LOCATIONS_FILTER,
    data,
  };
};

export const setMeasurementLocationsLoading = data => {
  return {
    data,
    type: actionTypes.SET_MEASUREMENT_LOCATIONS_LOADING,
  };
};

export const setMeasurementLocationDetailsLoading = data => {
  return {
    data,
    type: actionTypes.SET_MEASUREMENT_LOCATION_DETAILS_LOADING,
  };
};

export const updateMeasurementLocationInArray = (data, method, prop = formConstants.fields.id) => {
  const { measurementLocations, measurementLocationsClustered } = store.getState().measurementLocationReducer;
  let clusters = [];

  if (method === 'update') {
    clusters = updateElementInArray(data, measurementLocationsClustered);
    data = updateElementInArray(data, measurementLocations);
  } else if (method === 'delete') {
    clusters = measurementLocationsClustered.filter(ml => ml[prop] !== data[prop]);
    data = measurementLocations.filter(ml => ml[prop] !== data[prop]);
  } else if (method === 'add') {
    clusters = [...measurementLocationsClustered, data];
    data = [data, ...measurementLocations];
  }

  return {
    data,
    clusters,
    type: actionTypes.UPDATE_MEASUREMENT_LOCATION_SUCCESS,
  };
};

export const setMeasurementLocationFormState = data => {
  return {
    type: actionTypes.SET_MEASUREMENT_LOCATION_FORM_STATE,
    data,
  };
};

export const setMeasurementLocationObjectEditing = data => {
  return {
    data,
    type: actionTypes.SET_MEASUREMENT_LOCATION_OBJECT_EDITING,
  };
};

export const setMeasurementLocationObjectAdding = data => {
  return {
    data,
    type: actionTypes.SET_MEASUREMENT_LOCATION_OBJECT_ADDING,
  };
};

export const setSelectedMeasurementLocation = data => {
  return {
    data,
    type: actionTypes.SET_SELECTED_MEASUREMENT_LOCATION,
  };
};

export const setMeasurementLocationDetailsErrorModal = data => {
  return {
    data,
    type: actionTypes.SET_MEASUREMENT_LOCATION_DETAILS_ERROR_MODAL,
  };
};

// MEASUREMENT POINT ACTIONS
export const selectAllMeasurementPoints = data => {
  return {
    data,
    type: actionTypes.SELECT_ALL_MEASUREMENT_POINTS,
  };
};

export const deselectAllMeasurementPoints = data => {
  return {
    data,
    type: actionTypes.DESELECT_ALL_MEASUREMENT_POINTS,
  };
};

export const toggleMeasurementPoint = data => {
  return {
    data,
    type: actionTypes.TOGGLE_MEASUREMENT_POINT,
  };
};

export const setMeasurementPoints = data => {
  return {
    data,
    type: actionTypes.SET_MEASUREMENT_POINTS,
  };
};

export const amendMeasurementPoints = data => {
  return {
    data,
    type: actionTypes.AMEND_MEASUREMENT_POINTS,
  };
};

export const setMeasurementPointsClustered = data => {
  return {
    data,
    type: actionTypes.SET_MEASUREMENT_POINTS_CLUSTERED,
  };
};

export const setMeasurementPointsFilters = data => {
  return {
    type: actionTypes.SET_MEASUREMENT_POINTS_FILTER,
    data,
  };
};

export const setMeasurementPointsLoading = data => {
  return {
    data,
    type: actionTypes.SET_MEASUREMENT_POINTS_LOADING,
  };
};

export const setMeasurementPointDetailsLoading = data => {
  return {
    data,
    type: actionTypes.SET_MEASUREMENT_POINT_DETAILS_LOADING,
  };
};

export const updateMeasurementPointInArray = (data, method, prop = formConstants.fields.id) => {
  const { measurementPoints, measurementPointsClustered } = store.getState().measurementPointReducer;
  let clusters = [];

  if (method === 'update') {
    clusters = updateElementInArray(data, measurementPointsClustered);
    data = updateElementInArray(data, measurementPoints);
  } else if (method === 'delete') {
    clusters = measurementPointsClustered.filter(ml => ml[prop] !== data[prop]);
    data = measurementPoints.filter(ml => ml[prop] !== data[prop]);
  } else if (method === 'add') {
    clusters = [...measurementPointsClustered, data];
    data = [data, ...measurementPoints];
  }

  return {
    data,
    clusters,
    type: actionTypes.UPDATE_MEASUREMENT_POINT_SUCCESS,
  };
};

export const setMeasurementPointFormState = data => {
  return {
    type: actionTypes.SET_MEASUREMENT_POINT_FORM_STATE,
    data,
  };
};

export const setMeasurementPointLocationObjectEditing = data => {
  return {
    data,
    type: actionTypes.SET_MEASUREMENT_POINT_LOCATION_OBJECT_EDITING,
  };
};

export const setMeasurementPointLocationObjectAdding = data => {
  return {
    data,
    type: actionTypes.SET_MEASUREMENT_POINT_LOCATION_OBJECT_ADDING,
  };
};
