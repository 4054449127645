import actionTypes from '../constants/action-types.js';
import { defaultFilters, fields } from '../constants/constants.js';

const measurementLocationState = {
  selectedMeasurementGroup: null,
  measurementGroups: [],
  measurementGroupsClustered: [],
  filters: defaultFilters,
  areAllMeasurementGroupsHidden: true,
  visibleGroupID: null,
};

export const measurementGroupReducer = (state = measurementLocationState, payload) => {
  switch (payload.type) {
    case actionTypes.SET_MEASUREMENT_GROUP:
      return {
        ...state,
        selectedMeasurementGroup: payload.data,
      };
    case actionTypes.SET_MEASUREMENT_GROUPS:
      return {
        ...state,
        measurementGroups: payload.data || [],
      };
    case actionTypes.SET_MEASUREMENT_GROUPS_CLUSTERED:
      return {
        ...state,
        measurementGroupsClustered: payload.data.map(ml => ({ ...ml, visible: true })),
      };
    case actionTypes.TOGGLE_MEASUREMENT_GROUP_VISIBILITY:
      let newVisibleGroupID = payload.data;
      if (state.visibleGroupID && state.visibleGroupID === newVisibleGroupID) {
        newVisibleGroupID = null;
      }
      return {
        ...state,
        measurementGroups: state.measurementGroups.map(group => {
          return { ...group, visible: group[fields.id] === payload.data || state.visibleGroupID === group[fields.id] ? !group.visible : group.visible };
        }),
        visibleGroupID: newVisibleGroupID,
      };
    case actionTypes.SELECT_ALL_MEASUREMENT_GROUPS:
      return {
        ...state,
        areAllMeasurementGroupsHidden: false,
        visibleGroupID: null,
        measurementGroups: state.measurementGroups.map(group => {
          return { ...group, visible: true };
        }),
        measurementGroupsClustered: state.measurementGroupsClustered.map(ml => {
          return { ...ml, visible: true };
        }),
      };
    case actionTypes.DESELECT_ALL_MEASUREMENT_GROUPS:
      return {
        ...state,
        areAllMeasurementGroupsHidden: true,
        visibleGroupID: null,
        measurementGroups: state.measurementGroups.map(group => {
          return { ...group, visible: false };
        }),
        measurementGroupsClustered: state.measurementGroupsClustered.map(ml => {
          return { ...ml, visible: false };
        }),
      };
    default:
      return state;
  }
};
