import { isEmpty, values } from 'lodash';
import DisplayStatus from '../../../common/display-status/components/display-status';
import Toggle from '../../../common/form/components/toggle-field';
import Helpers from '../../../common/helpers';
import Icon from '../../../common/icon/components/icon';
import PointerDot from '../../../common/pointer-dot/pointer-dot';
import RenderIf from '../../../common/render-if/components/render-if';
import InfoTooltip from '../../../common/tooltip/components/info-tooltip';
import { ComponentHierarchyUnassignedLevelID } from '../../component-hierarchy/constants/constants';
import ChangeStatusDropdown from '../../inspections/components/right-toolbar/common/change-status-dropdown';
import DMSTableDropdown from '../components/dms-table/dms-table-dropdown';
import { fields, fileTypesOptions, fileTypesOptionsValues, filterProps, statuses } from '../constants/constants';
import dmsHelpers from '../helpers/dms-helpers';

const getValueWithSearchTerm = (value, searchTerm) => {
  return isEmpty(value) ? '-' : searchTerm ? Helpers.getSearchTermText(value, searchTerm) : value;
};

export const getIconForFileType = (fileType, isUploadDrawer = false) => {
  if (!fileType) return;
  const type = fileType.toLowerCase();
  if (fileTypesOptionsValues[fileTypesOptions.pdf].value.includes(type) && !isUploadDrawer) {
    return 'pdf-file';
  } else if (fileTypesOptionsValues[fileTypesOptions.images].value.includes(type)) {
    return 'image';
  }
  return 'documents';
};

export const formatFolderTableCells = (
  value,
  type,
  searchTerm,
  row,
  onDropdownClick,
  t,
  pointerDotID,
  _user,
  _onToggleClick,
  _onDeleteRow,
  _statusData,
  _handleChangeStatus,
  _onLinkFileClick,
  selectedItem,
  _cell,
  _hasLinkDMSFilePermissions,
  _onRowClick,
  _toggleModalAction,
  _onReorderClick,
  _rowIndex,
  defaultUnassignedDMSFolderID
) => {
  if (type === fields.icon) {
    return row[fields.id] !== selectedItem ? <Icon name="folder" size="sm" /> : <Icon name="pen" size="sm" handleHover={false} />;
  } else if (type === fields.name) {
    return (
      <div className="folder_name">
        {getValueWithSearchTerm(value, searchTerm)}
        <RenderIf if={pointerDotID === row[fields.id]}>
          <PointerDot type="success" text={t('DOCUMENT_HIERARCHY_CHANGE.CURRENT_LOCATION')} ignoreTheme />
        </RenderIf>
      </div>
    );
  } else if (type === fields.actionMenu) {
    // hides actionMenu for Unassigned folder and also for module Unassigned subfolders
    return row?.IsUnassigned || row[filterProps.parentID] === defaultUnassignedDMSFolderID ? <></> : <DMSTableDropdown onClick={onDropdownClick} row={row} isFolder />;
  } else if (type === fields.createdAt) {
    // hides createdAt field in table row for Unassigned folder and also for module Unassigned subfolders
    if (row[fields.name] === ComponentHierarchyUnassignedLevelID || row[filterProps.parentID] === defaultUnassignedDMSFolderID) return '-';
    else return Helpers.getDateFromUnix(value);
  } else {
    return value ? value : '-';
  }
};

export const formatFileTableCells = (
  value,
  type,
  searchTerm,
  row,
  onDropdownClick,
  _t,
  _pointerDotID,
  _user,
  _onToggleClick,
  _onDeleteRow,
  _statusData,
  _handleChangeStatus,
  onLinkFileClick,
  selectedItem,
  _cell,
  hasLinkDMSFilePermissions
) => {
  if (type === fields.icon) {
    return row.ID !== selectedItem ? <Icon name={getIconForFileType(value)} size="sm" active /> : <Icon name="pen" size="sm" handleHover={false} />;
  } else if (type === fields.fileName) {
    const decodedFileName = Helpers.decodeIfStringEncoded(value);

    return (
      <p className="file-name-column__text f-primary" title={decodedFileName}>
        {getValueWithSearchTerm(decodedFileName, searchTerm)}
      </p>
    );
  } else if (type === fields.actionMenu) {
    return <DMSTableDropdown onClick={onDropdownClick} row={row} />;
  } else if (type === fields.createdAt) {
    return Helpers.getDateFromUnix(value);
  } else if (type === fields.status) {
    return <DisplayStatus statuses={values(statuses)} status={value} />;
  } else if (type === fields.fileSize) {
    return Helpers.formatBytes(value);
  } else if (type === fields.toggleLinkDocumentAction) {
    // formatted so it fits the file properties that fits onLinkFileToComponent
    const file = { ...row, fileId: row.ID };
    // if the user does not have moduleItem:ChooseFromDMS, he cannot link a file
    return <Icon name="plus-circle" className={!hasLinkDMSFilePermissions ? 'disabled' : ''} active handleHover={true} onClick={hasLinkDMSFilePermissions ? () => onLinkFileClick(file) : null} />;
  } else {
    return value ? value : '-';
  }
};

export const formatBooleanPill = (value, row, onToggleHandler) => {
  return <Toggle width={36} height={20} handleDiameter={21} input={{ value: value, onChange: value => onToggleHandler(value, row) }} />;
};

export const formatUploadedFileTableCells = (value, type, _searchInputValue, row, _onDropdownClick, _t, _pointerDotID, user, onToggleClick, onDeleteRow, statusData, handleChangeStatus) => {
  if (type === fields.icon) {
    return <Icon name={getIconForFileType(value)} size="sm" active />;
  } else if (type === fields.realName) {
    const decodedFileName = Helpers.decodeIfStringEncoded(value);

    return decodedFileName;
  } else if (type === fields.smartDrawing) {
    // allows SmartDrawing for multiple pages PDFs with under 20 pages
    if (row[fields.fileType] !== fileTypesOptions.pdf || row[fields.numberOfPages] > 20) return <></>;
    return formatBooleanPill(Boolean(value), row, onToggleClick);
  } else if (type === fields.deleteFileRowAction) {
    return <Icon name="trash" className="pointer" onClick={() => onDeleteRow(row)} />;
  } else if (type === fields.status) {
    const statuses = dmsHelpers.getStatuses(statusData);
    return (
      <ChangeStatusDropdown
        statuses={statuses}
        module={Object.assign({}, row)}
        user={user}
        handleChangeStatus={status => handleChangeStatus(status, row)}
        createdById={user[fields.userId]}
        finalStatus={''}
      />
    );
  } else {
    return value ? value : '-';
  }
};

export const formatComponentTableCells = (
  value,
  type,
  searchTerm,
  row,
  onDropdownClick,
  t,
  pointerDotID,
  _user,
  _onToggleClick,
  _onDeleteRow,
  _statusData,
  _handleChangeStatus,
  _onLinkFileClick,
  selectedItem
) => {
  if (type === fields.icon) {
    return <Icon name="components" active size="sm" />;
  } else if (type === fields.componentName) {
    return (
      <p title={value} className="f-primary">
        {value}
      </p>
    );
  } else if (type === fields.critical) {
    return (
      <>
        {value ? (
          <InfoTooltip
            className="critical-tooltip"
            offsetY={6}
            offsetX={6}
            customComponent={<span className="f-primary">{t('CRITICAL_EQUIPMENT.TITLE')}</span>}
            Component={() => <Icon name="critical-hazard" handleHover={false} size="sm" />}
            componentProps={{ title: '' }}
            containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
          />
        ) : (
          ''
        )}
      </>
    );
  }
  return value ? value : '-';
};

export const formatObservationTableCells = (value, type) => {
  if (type === fields.icon) {
    return <Icon name="observations" active size="sm" />;
  } else if (type === fields.defectName) {
    return (
      <p title={value} className="f-primary">
        {value}
      </p>
    );
  }
  return value ? value : '-';
};

export const formatNotificationTableCells = (value, type) => {
  if (type === fields.icon) {
    return <Icon name="notifications" active size="sm" />;
  } else if (type === fields.notificationName) {
    return (
      <p title={value} className="f-primary">
        {value}
      </p>
    );
  }
  return value ? value : '-';
};

export const formatWorkorderTableCells = (value, type) => {
  if (type === fields.icon) {
    return <Icon name="workorders" active size="sm" />;
  } else if (type === fields.workorderName) {
    return (
      <p title={value} className="f-primary">
        {value}
      </p>
    );
  }
  return value ? value : '-';
};

export const formatUploadDrawerFileTableCells = (value, type, _searchTerm, row) => {
  if (type === fields.icon) {
    return <Icon name={getIconForFileType(value, true)} size="sm" active />;
  } else if (type === fields.name) {
    const decodedFileName = Helpers.decodeIfStringEncoded(value);

    return (
      <p className="upload-name-col__title f-primary" title={decodedFileName}>
        {decodedFileName}
      </p>
    );
  } else if (type === fields.progress) {
    if (row.failedUpload) return <Icon name="alert-octagon" danger size="xs" />;
    else if (value === 100) return <Icon name="checkmark" active size="xs" />;
    else
      return (
        <div className="upload-progress-col__loader">
          <span className="f-primary">{value}%</span>
          <Icon name="loader" size="xs" className="upload-progress-col__loader__icon" />
        </div>
      );
  } else {
    return value ? value : '-';
  }
};

export const formatChecklistTemplateTableCells = (value, type) => {
  if (type === fields.icon) {
    return <Icon name="list" active size="sm" />;
  } else if (type === fields.name) {
    return (
      <p title={value} className="f-primary">
        {value}
      </p>
    );
  }
  return value ? value : '-';
};
