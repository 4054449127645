export default {
  GET_ISOLATION_CERTIFICATES: '/project/isolation/certificate/list',
  CREATE_ISOLATION_CERTIFICATE: '/project/isolation/certificate/create',
  GET_ISOLATION_CERTIFICATE_DETAILS: '/project/isolation/certificate/details',
  ARCHIVE_ISOLATION_CERTIFICATE: '/project/isolation/certificate/archive',
  DELETE_ISOLATION_CERTIFICATE: '/project/isolation/certificate/delete',
  UPDATE_ISOLATION_CERTIFICATE_DETAILS: '/project/isolation/certificate/update',
  GET_ISOLATION_CERTIFICATE_TEMPLATES: '/project/isolation/certificate/template/list',
  GET_ISOLATION_CERTIFICATE_TEMPLATES_FILTERS: '/project/isolation/certificate/templates/filters',
  ANSWER_ON_QUESTION: '/project/isolation/certificate/answer/add',
  GET_AVAILABLE_KEY_BOXES: '/project/isolation/certificate/keybox/list',
  ASSIGN_KEY_BOX: '/project/isolation/certificate/keybox/assign',
  ADD_ISOLATION_COMPONENT: '/project/isolation/certificate/component/add',
  UPDATE_ISOLATION_COMPONENT: '/project/isolation/certificate/component/update',
  DELETE_ISOLATION_COMPONENT: '/project/isolation/certificate/component/delete',
  DELETE_ISOLATION_COMPONENT_FILE: '/project/isolation/certificate/file/unlink',
  ORDER_ISOLATION_COMPONENTS: '/project/isolation/certificate/isolations/order',
  CHANGE_ISOLATION_CERTIFICATE_STATUS: '/project/isolation/certificate/status',
  GENERATE_PDF: '/project/isolation/certificate/pdf',
  GENERATE_PDF_POPULATED_DRAWING: '/project/isolation/certificate/pdf/populated/drawing',
  CHANGE_ISOLATION_CERTIFICATE_STATUS_WITH_DATA: '/project/isolation/certificate/status_with_data',
  GET_ISOLATION_CONTRACTORS_LIST: '/project/isolation/certificate/contractors/list',
  DELETE_ISOLATION_CERTIFICATE_FILE: '/project/isolation/certificate/file/unlink',
  UNASSIGN_KEY_BOX: '/project/isolation/certificate/keybox/unassign',
  GET_ISOLATION_CERTIFICATE_STATUS_FLOWS: '/project/isolation/certificate/status/flow',
  FETCH_ISOLATION_CERTIFICATE_COMMENTS: '/project/isolation/certificate/comment/list',
  ADD_ISOLATION_CERTIFICATE_COMMENT: '/project/isolation/certificate/comment/add',
  DELETE_ISOLATION_CERTIFICATE_COMMENT: '/project/isolation/certificate/comment/delete',
};
