import { get, isEmpty } from 'lodash';
import { fetchComponentObservations, fetchObservationFiles, saveObservationDrawings, updateObservationDrawings } from '../../../../../api/inspection/actions';
import observationApi from '../../../../../api/inspection/actions';
import { setDefectFilesUploaded } from '../../../../upload/actions/action-creators';
import { assigneeFields } from '../../../constants/defect-constants';
import { amendObservationContributor, setObservationContributors, removeObservationContributor } from '../../../actions/action-creators';
import Helpers from '../../../../../common/helpers';
import genericFileImage from '../../../../inspections/assets/component-generic-file.svg';

export const getComponentObservations = (filters, dataCallback, loadingCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      const res = await fetchComponentObservations(filters);
      const { Data } = res?.data;
      const { Items, ...rest } = Data;
      dataCallback && typeof dataCallback === 'function' && dataCallback(Items, { ...filters, ...rest });
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
    }
  };
};

export const getObservationDMSFilesUploaded = defect_id => {
  return async dispatch => {
    try {
      const res = await fetchObservationFiles([{ defect_id }]);
      let Data = get(res, 'data.Data');

      if (Data) {
        Data = Helpers.mapExternalFilesForModal(Data, genericFileImage);
        dispatch(setDefectFilesUploaded(Data));
      } else {
        dispatch(setDefectFilesUploaded({}));
      }
    } catch (e) {
      console.error(e);
      dispatch(setDefectFilesUploaded({}));
    }
  };
};

// TODO: call in component
export const saveObservationDrawing = (data, dataCallback, loadingCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      await saveObservationDrawings(data);
      // const { Data } = res?.data;
      // console.log('saveObservationDrawing Data', Data);
      // const { Items, ...rest } = Data;
      // dataCallback && typeof dataCallback === 'function' && dataCallback(Items, { ...filters, ...rest });
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
    }
  };
};

// TODO: call in component
export const updateObservationDrawing = (data, dataCallback, loadingCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      await updateObservationDrawings(data);
      // const { Data } = res?.data;
      // console.log('updateObservationDrawing Data', Data);
      // const { Items, ...rest } = Data;
      // dataCallback && typeof dataCallback === 'function' && dataCallback(Items, { ...filters, ...rest });
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
    }
  };
};

export const searchContributors = (params, callback) => {
  return async dispatch => {
    const res = await observationApi.searchContributors(params);

    let { Data } = res.data;

    callback && callback(Data);
  };
};

export const searchObservationContributors = (params, callback) => {
  return async dispatch => {
    const res = await observationApi.searchObservationContributors(params);

    let { Data } = res.data;

    callback && callback(Data);
  };
};

export const getObservationContributors = (defectId, successCallback) => {
  return async dispatch => {
    try {
      const res = await observationApi.getObservationContributors({ DefectID: defectId });
      const { Data } = res.data;

      dispatch(setObservationContributors(Data || []));

      successCallback && successCallback(Data);
    } catch (err) {
      console.error(err);
    }
  };
};

export const addObservationContributor = (defectId, projectId, inspectionId, data) => {
  return async dispatch => {
    try {
      if (isEmpty(data)) return;
      await observationApi.addObservationContributor({
        DefectID: defectId,
        ProjectID: projectId,
        InspectionID: inspectionId,
        AssigneeID: data[assigneeFields.id],
        AssigneeType: data[assigneeFields.type] || data[assigneeFields.assigneeType],
        ParticipantType: data[assigneeFields.participantType],
      });

      dispatch(amendObservationContributor(data));
    } catch (e) {
      console.error(e);
    }
  };
};

export const deleteObservationContributor = (defectId, projectId, inspectionId, data, callback) => {
  return async dispatch => {
    try {
      if (isEmpty(data)) return;

      await observationApi.deleteObservationContributor({
        DefectID: defectId,
        ProjectID: projectId,
        InspectionID: inspectionId,
        AssigneeID: data[assigneeFields.id],
        AssigneeType: data[assigneeFields.type] || data[assigneeFields.assigneeType],
        ParticipantType: data[assigneeFields.participantType],
      });

      dispatch(removeObservationContributor(data));
      callback && callback();
    } catch (e) {
      console.error(e);
    }
  };
};
