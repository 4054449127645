export const formConstants = {
  fields: {
    id: 'ID',
    name: 'Name',
    color: 'Colour',
    camPosition: 'CameraPosition.coordinates',
    points: 'Geometry.coordinates',
    modelType: 'ModelType',
    modelDetails: 'ModelDetails',
  },
};

export const workAreasTableHeader = [
  {
    title: 'WORK_AREAS.TABLE.NAME',
    key: formConstants.fields.name,
    type: formConstants.fields.name,
    className: 'name-column ellipsis',
  },
];
