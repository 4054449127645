import routesConstants from './routes';
import { Api } from '../../core/api';
import { API_BASE_URL } from '../../common/constants';
import { generateQueryParams } from '../helpers/helper';

const getIsolationTemplates = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_ISOLATION_TEMPLATES}`, data);
};

const getIsolationTemplateDetails = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_ISOLATION_TEMPLATE_DETAILS}${generateQueryParams(params)}`);
};

const createIsolationTemplate = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CREATE_ISOLATION_TEMPLATE}`, data);
};
const duplicateIsolationTemplate = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DUPLICATE_ISOLATION_TEMPLATE}`, data);
};

const updateIsolationTemplate = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_ISOLATION_TEMPLATE}`, data);
};

const deleteIsolationTemplate = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_ISOLATION_TEMPLATE}`, data);
};

const updateStatus = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_ISOLATION_TEMPLATE_STATUS}`, data);
};
const updateLiveStatus = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_ISOLATION_TEMPLATE_LIVE_STATUS}`, data);
};

const getIsolationTemplateSections = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_ISOLATION_TEMPLATE_SECTIONS}`, data);
};

const addIsolationTemplateSection = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_ISOLATION_TEMPLATE_SECTION}`, data);
};

const updateIsolationTemplateSection = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_ISOLATION_TEMPLATE_SECTION}`, data);
};

const deleteIsolationTemplateSection = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_ISOLATION_TEMPLATE_SECTION}`, data);
};

const addIsolationTemplateSectionQuestion = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_ISOLATION_TEMPLATE_SECTION_QUESTION}`, data);
};

const deleteIsolationTemplateSectionQuestion = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_ISOLATION_TEMPLATE_SECTION_QUESTION}`, data);
};

const updateIsolationTemplateSectionQuestion = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_ISOLATION_TEMPLATE_SECTION_QUESTION}`, data);
};

const addIsolationTemplateSectionQuestionOption = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_ISOLATION_TEMPLATE_SECTION_QUESTION_OPTION}`, data);
};

const updateIsolationTemplateSectionQuestionOption = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_ISOLATION_TEMPLATE_SECTION_QUESTION_OPTION}`, data);
};

const deleteIsolationTemplateSectionQuestionOption = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_ISOLATION_TEMPLATE_SECTION_QUESTION_OPTION}`, data);
};

const sortIsolationTemplateSections = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.SORT_ISOLATION_TEMPLATE_SECTION}`, data);
};

const sortIsolationTemplateSectionQuestions = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.SORT_ISOLATION_TEMPLATE_SECTION_QUESTION}`, data);
};

const getIsolationTemplateFiles = async templateId => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_ISOLATION_TEMPLATE_FILES}?isolation_template_id=${templateId}`);
};

const deleteIsolationTemplateFile = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_ISOLATION_TEMPLATE_FILE}`, data);
};

export default {
  getIsolationTemplates,
  getIsolationTemplateDetails,
  createIsolationTemplate,
  duplicateIsolationTemplate,
  updateIsolationTemplate,
  deleteIsolationTemplate,
  updateStatus,
  updateLiveStatus,
  getIsolationTemplateSections,
  addIsolationTemplateSection,
  updateIsolationTemplateSection,
  deleteIsolationTemplateSection,
  addIsolationTemplateSectionQuestion,
  deleteIsolationTemplateSectionQuestion,
  updateIsolationTemplateSectionQuestion,
  addIsolationTemplateSectionQuestionOption,
  updateIsolationTemplateSectionQuestionOption,
  deleteIsolationTemplateSectionQuestionOption,
  sortIsolationTemplateSections,
  sortIsolationTemplateSectionQuestions,
  getIsolationTemplateFiles,
  deleteIsolationTemplateFile,
};
