import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Modal from '../../../common/modal/components/modal';
import BasicWrapper from '../../shared/common-component/components/basic-wrapper';
import { checkToken, setPassword } from '../actions/reset-password-actions';
import SetPasswordForm from './set-password-form';

import Helpers from '../../../common/helpers';
import routesConstants from '../../../common/routes-constants';

import { PRIVACY_AND_POLICY_LINK, TERMS_LINK } from '../../../common/constants';
import '../styles/set-password.scss';

class SetPassword extends Component {
  state = {
    token: null,
    modalData: {
      isOpen: false,
    },
  };
  openTermsAndConditionsModal = e => {
    e.preventDefault();

    Helpers.windowOpen(TERMS_LINK, true);
  };
  openDataPolicyModal = e => {
    e.preventDefault();
    Helpers.windowOpen(PRIVACY_AND_POLICY_LINK, true);
  };
  closeModal = () => {
    this.setState(prevProps => ({
      modalData: {
        ...prevProps.modalData,
        isOpen: false,
      },
    }));
  };

  handleSubmit = values => {
    const { setPassword } = this.props;
    const { token } = this.state;

    setPassword({ ...values, Token: token });
  };

  componentDidMount = () => {
    const { checkToken } = this.props;
    const { query } = this.props.location;
    const token = query && query.token;
    const goToLogin = () => Helpers.goTo(routesConstants.routes.unProtectedRoutes.login.fullPath);
    const setLoading = isLoading => this.setState({ isLoading });

    if (!token) {
      goToLogin();
    }

    const onTokenChecked = isActive => {
      if (!isActive) goToLogin();
    };

    checkToken(token, onTokenChecked, setLoading);

    this.setState({ token });
  };

  render() {
    const { t } = this.context;
    const { modalData } = this.state;
    const { openTermsAndConditionsModal, openDataPolicyModal } = this;
    return (
      <BasicWrapper>
        <div className="set-password-wrapper">
          <h3 className="f-primary bold">{t('SET_PASSWORD')}</h3>
          <SetPasswordForm
            onSubmit={this.handleSubmit}
            CustomLabel={
              <p className="terms-and-conditions-wrapper">
                {t('SET_PASSWORD.TERMS_AND_CONDITIONS_1')}{' '}
                <span className="clickable" onClick={openTermsAndConditionsModal}>
                  {' '}
                  {t('SET_PASSWORD.TERMS_AND_CONDITIONS_2')}{' '}
                </span>{' '}
                {t('SET_PASSWORD.TERMS_AND_CONDITIONS_3')}{' '}
                <span className="clickable" onClick={openDataPolicyModal}>
                  {t('SET_PASSWORD.TERMS_AND_CONDITIONS_4')}
                </span>
              </p>
            }
          />
        </div>
        <Modal {...modalData} />
      </BasicWrapper>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setPassword: values => dispatch(setPassword(values)),
  checkToken: (token, callback) => dispatch(checkToken(token, callback)),
});

SetPassword.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(SetPassword);
