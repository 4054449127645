import ModuleChangeStatusSection from '../../right-toolbar/common/module-change-status-section';
import DatesSectionForm from '../dates-section-form';
import DetailsForm from '../details-section-form';
import IsolationCertificates from '../isolation-certificate-section/isolation-certificates';
import PermitKeyBoxes from '../keybox-section/keyboxes';
import PermitSectionComponents from '../components-section/permit-section-components';
import PermitWorkOrders from '../work-orders-section/work-orders';
import FilesSection from '../../right-toolbar/isolation-certificate-details/components/details/files-section';
import { statuses } from './permit-constants';
import { dateFields, detailsFields } from './section-form-constants';
import sectionsValidator from '../validators/sections-validator';
import { initialValuesProp as componentsIntialValuesProp } from '../../right-toolbar/isolation-certificate-details/constants/isolation-components-constants';
import Comments from '../../right-toolbar/common/comments';
import ModuleSectionWorkAreas from '../../work-order/module-section-work-areas';

export const sections = {
  Details: {
    title: 'PERMIT.DETAILS_SECTION',
    component: DetailsForm,
    translatableTitle: true,
    fields: detailsFields,
    action: 'updatePermitDetails',
    validate: sectionsValidator.detailsValidate,
    disabledForStatuses: [statuses.rejected.value, statuses.handback.value],
  },
  Dates: {
    title: 'PERMIT.DATES_SECTION',
    component: DatesSectionForm,
    translatableTitle: true,
    fields: dateFields,
    action: 'updatePermitDates',
    validate: sectionsValidator.datesValidate,
    disabledForStatuses: [statuses.rejected.value, statuses.handback.value],
  },
  'Work Orders': {
    title: 'PERMIT.WORK_ORDERS_SECTION',
    component: PermitWorkOrders,
    translatableTitle: true,
    showError: false,
  },
  'Isolation Certificates': {
    title: 'PERMIT.ISOLATIONS_CERTIFICATES_SECTION',
    component: IsolationCertificates,
    translatableTitle: true,
    sectionAction: 'toggleIsolationCertificateModal',
    disabledForStatuses: [
      statuses.approved.value,
      statuses.authorised.value,
      statuses.issued.value,
      statuses.surrendered.value,
      statuses.reissued.value,
      statuses.handback.value,
      statuses.rejected.value,
    ],
    showError: false,
  },
  Keyboxes: {
    title: 'PERMIT.KEYBOX_SECTION',
    component: PermitKeyBoxes,
    translatableTitle: true,
    sectionAction: 'toggleKeyboxModal',
    disabledForStatuses: [statuses.issued.value, statuses.surrendered.value, statuses.reissued.value, statuses.rejected.value, statuses.handback.value],
    showError: false,
  },
  'Work Areas': {
    title: 'PERMIT.WORK_AREAS_SECTION',
    component: ModuleSectionWorkAreas,
    translatableTitle: true,
    sectionAction: 'handleOpenWorkAreaModal',
    disabledForStatuses: [statuses.issued.value, statuses.surrendered.value, statuses.reissued.value, statuses.rejected.value, statuses.handback.value],
    showError: false,
  },
  Comments: {
    title: 'PERMIT.COMMENTS_SECTION',
    component: Comments,
    translatableTitle: true,
    customAction: 'handleAddPermitComment',
    showError: false,
    isHidden: true,
  },
  Components: {
    title: 'PERMIT.COMPONENTS_SECTION',
    component: PermitSectionComponents,
    translatableTitle: true,
    initialValuesProp: componentsIntialValuesProp,
    sectionAction: 'toggleComponentsModal',
    disabledForStatuses: [
      statuses.approved.value,
      statuses.authorised.value,
      statuses.issued.value,
      statuses.surrendered.value,
      statuses.reissued.value,
      statuses.handback.value,
      statuses.rejected.value,
    ],
    showError: false,
  },
  Files: {
    title: 'PERMIT.FILES_SECTION',
    component: FilesSection,
    sectionAction: 'openEditFilesModal',
    translatableTitle: true,
    showError: false,
  },
  Authorised: {
    title: 'PERMIT.AUTHORISED_SECTION',
    component: ModuleChangeStatusSection,
    translatableTitle: true,
    icon: statuses.authorised.icon,
    iconClass: 'authorized-status-icon',
    hideSignatures: true,
    hideAssignee: true,
    witnessLabel: 'ISOLATION_CERTIFICATES.AUTHORISED_BY',
    showError: false,
  },
  Approved: {
    title: 'PERMIT.APPROVED_SECTION',
    component: ModuleChangeStatusSection,
    translatableTitle: true,
    icon: statuses.approved.icon,
    iconClass: 'authorized-status-icon',
    hideSignatures: true,
    hideAssignee: true,
    witnessLabel: 'ISOLATION_CERTIFICATES.APPROVED_BY',
    showError: false,
  },
  Issued: {
    title: 'PERMIT.ISSUED_SECTION',
    component: ModuleChangeStatusSection,
    translatableTitle: true,
    icon: statuses.issued.icon,
    showError: false,
  },
  Surrendered: {
    title: 'PERMIT.SURRENDER_SECTION',
    component: ModuleChangeStatusSection,
    translatableTitle: true,
    icon: statuses.surrendered.icon,
    showViewMore: true,
    showError: false,
  },
  'Re-issued': {
    title: 'PERMIT.REISSUE_SECTION',
    component: ModuleChangeStatusSection,
    translatableTitle: true,
    icon: statuses.reissued.icon,
    showViewMore: true,
    showError: false,
  },
  'Hand Back': {
    title: 'PERMIT.HANDBACK_SECTION',
    component: ModuleChangeStatusSection,
    translatableTitle: true,
    icon: statuses.handback.icon,
    showError: false,
  },
};

export const customSectionDisabledStatuses = [statuses.rejected.value, statuses.handback.value];
