import { find, uniqBy, values } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import Icon from '../../../../../common/icon/components/icon';
import { questionTypes } from '../../../../../common/question-components/constants/question-constants';
import RenderIf from '../../../../../common/render-if/components/render-if';
import { changeStatusQuestionField, questionFields } from '../../right-toolbar/isolation-certificate-details/constants/change-status-constants';
import SectionQuestionDetails from './section-question-details';

const SectionQuestion = ({ question = {}, questionNumber, onFieldChange, formErrors, anyTouched, toggleQuestionDetails, hideQuestionAdditionalDetails }, { t }) => {
  const types = React.useMemo(() => {
    return values(questionTypes);
  }, []);

  const getQuestionField = question => {
    const { name, type, id, questionOptions } = questionFields;
    const questionName = `${changeStatusQuestionField}-${question[id]}`;
    const questionType = find(types, { valueId: question[type] });
    const fieldId = `question-field-${question[id]}`;

    switch (question[type]) {
      case questionTypes.TEXT.valueId:
        return (
          <div className="question-item">
            <div className="question-item-aligned">
              <p className="f-primary bold question-title">{`${question[name]}${question[questionFields.isMandatory] ? '*' : ''}`}</p>
              <div className="question-input question-input-aligned">
                <Field
                  id={fieldId}
                  placeholder={t('ISOLATION_CERTIFICATE_STATUS.TEXT_PLACEHOLDER')}
                  name={questionName}
                  component={questionTypes.TEXT.component}
                  onChange={(_e, value) => onFieldChange(questionName, question, value, questionType)}
                  size="lg"
                  withHiddenError
                />
              </div>
              <Icon name={question?.isExpanded ? 'chevron-up' : 'chevron-down'} className="toggle-question-details" onClick={() => toggleQuestionDetails(questionNumber - 1)} />
            </div>
            {anyTouched && formErrors[questionName] && <p className="form-error">{t(formErrors[questionName])}</p>}
          </div>
        );
      case questionTypes.NUMBER.valueId:
        return (
          <div className="question-item">
            <div className="question-item-aligned">
              <p className="f-primary bold question-title">{`${question[name]}${question[questionFields.isMandatory] ? '*' : ''}`}</p>
              <div className="question-input question-input-aligned">
                <Field
                  id={fieldId}
                  name={questionName}
                  placeholder={t('ISOLATION_CERTIFICATE_STATUS.NUMBER_PLACEHOLDER')}
                  component={questionTypes.NUMBER.component}
                  type="number"
                  onChange={(_e, value) => onFieldChange(questionName, question, value, questionType)}
                  size="lg"
                  withHiddenError
                />
              </div>
              <Icon name={question?.isExpanded ? 'chevron-up' : 'chevron-down'} className="toggle-question-details" onClick={() => toggleQuestionDetails(questionNumber - 1)} />
            </div>
            {anyTouched && formErrors[questionName] && <p className="form-error">{t(formErrors[questionName])}</p>}
          </div>
        );
      case questionTypes.CHECKBOX.valueId: {
        return (
          <div className="question-item">
            <div className="question-item-aligned question-item-checkboxes">
              <p className="f-primary bold question-title">{`${question[name]}${question[questionFields.isMandatory] ? '*' : ''}`}</p>
              <div className="checkboxes-question">
                <Field
                  id={`${fieldId}-yes`}
                  name={`${questionName}-yes`}
                  component={questionTypes.CHECKBOX.component}
                  checkboxText={t('MODULES_SECTIONS.QUESTION_CHECKBOX_YES')}
                  value={'true'}
                  onChange={() => onFieldChange(questionName, question, true, questionType)}
                  restprops={{ name: `${questionName}-yes` }}
                />
                <Field
                  id={`${fieldId}-no`}
                  name={`${questionName}-no`}
                  component={questionTypes.CHECKBOX.component}
                  checkboxText={t('MODULES_SECTIONS.QUESTION_CHECKBOX_NO')}
                  value={'false'}
                  onChange={() => onFieldChange(questionName, question, false, questionType)}
                  restprops={{ name: `${questionName}-no` }}
                />
              </div>
              <Icon name={question?.isExpanded ? 'chevron-up' : 'chevron-down'} className="toggle-question-details" onClick={() => toggleQuestionDetails(questionNumber - 1)} />
            </div>
            {anyTouched && formErrors[`${questionName}-yes`] && <p className="form-error">{t(formErrors[`${questionName}-yes`])}</p>}
          </div>
        );
      }
      case questionTypes.MULTI_ANSWER.valueId:
        if (question[questionOptions] && question[questionOptions].length > 0) {
          const uniqueMultiAnswers = uniqBy(question[questionOptions], questionFields.id);
          return (
            <div className="question-item">
              <div className="question-item-aligned">
                <p className="f-primary bold question-title">{`${question[name]}${question[questionFields.isMandatory] ? '*' : ''}`}</p>
                <Icon name={question?.isExpanded ? 'chevron-up' : 'chevron-down'} className="toggle-question-details toggle-to-right" onClick={() => toggleQuestionDetails(questionNumber - 1)} />
              </div>
              <div className="multi-answers-container">
                {uniqueMultiAnswers.map(option => (
                  <div key={`question-option-${question[id]}-${option[id]}`} className="question-option">
                    <Field
                      id={`${fieldId}-${option[id]}`}
                      name={`${questionName}-option-${option[id]}`}
                      component={questionTypes.CHECKBOX.component}
                      value={option[id]}
                      onChange={() => onFieldChange(questionName, question, option[id], questionType)}
                      checkboxText={option[name]}
                      shortenText
                      restprops={{ name: `${questionName}-option-${option[id]}` }}
                    />
                  </div>
                ))}
              </div>
              {anyTouched && formErrors[`${questionName}-option-${question[questionOptions][0][questionFields.id]}`] && (
                <p className="form-error">
                  {t(question[questionFields.allowMultipleAnswers] ? 'ISOLATION_CERTIFICATE_STATUS.QUESTION_MULTI_ANSWER_REQUIRED' : 'ISOLATION_CERTIFICATE_STATUS.QUESTION_SINGLE_ANSWER_REQUIRED')}
                </p>
              )}
            </div>
          );
        }

        return null;
      case questionTypes.DATE.valueId:
        return (
          <div className="question-item">
            <div className="question-item-aligned">
              <p className="f-primary bold question-title">{`${question[name]}${question[questionFields.isMandatory] ? '*' : ''}`}</p>
              <div className="question-input question-input-aligned">
                <Field
                  id={fieldId}
                  name={questionName}
                  component={questionTypes.DATE.component}
                  placeholder={t('MODULES_SECTIONS.DATE_PLACEHOLDER')}
                  disabledKeyboardNavigation
                  dateFormat="MMMM d, yyyy h:mm aa"
                  timeFormat="h:mm aa"
                  showTimeSelect
                  size="lg"
                  popperPlacement="top"
                  onChange={value => onFieldChange(questionTypes.DATE.fieldName, question, value, questionType)}
                  withHiddenError
                />
              </div>
              <Icon name={question?.isExpanded ? 'chevron-up' : 'chevron-down'} className="toggle-question-details" onClick={() => toggleQuestionDetails(questionNumber - 1)} />
            </div>
            {anyTouched && formErrors[questionName] && <p className="form-error">{t(formErrors[questionName])}</p>}
          </div>
        );
      case questionTypes.NO_ANSWER.valueId:
        return (
          <div className="question-item">
            <p className="f-primary bold procedure-title">{t('MODULES_SECTIONS.PROCEDURE_TITLE')}</p>
            <p className="f-primary">{`${question[name]}${question[questionFields.isMandatory] ? '*' : ''}`}</p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="section-question">
      <div className="question-number">
        <p className="f-secondary-green bold">{`${questionNumber}.`}</p>
      </div>
      <div className="question-wrapper">
        <div className="question-wrapper-item">{getQuestionField(question)}</div>
        <RenderIf if={question.isExpanded}>
          <SectionQuestionDetails hideAdditionalDetails={hideQuestionAdditionalDetails} expandImages={imageType => toggleQuestionDetails(questionNumber - 1, imageType)} question={question} />
        </RenderIf>
      </div>
    </div>
  );
};

SectionQuestion.contextTypes = {
  t: PropTypes.func.isRequired,
};

SectionQuestion.propTypes = {
  question: PropTypes.shape({
    ID: PropTypes.number,
    Name: PropTypes.string.isRequired,
    QuestionType: PropTypes.number,
    QuestionOrder: PropTypes.number,
    IsMandatory: PropTypes.bool,
    AllowMultipleAnswers: PropTypes.bool,
    EstimatedTime: PropTypes.number,
    CreatedAt: PropTypes.number,
    SectionID: PropTypes.number,
  }),
  questionNumber: PropTypes.number,
  onFieldChange: PropTypes.func.isRequired,
  formErrors: PropTypes.object,
  anyTouched: PropTypes.bool,
  toggleQuestionDetails: PropTypes.func.isRequired,
  hideQuestionAdditionalDetails: PropTypes.bool,
};

export default SectionQuestion;
