import React from 'react';
import PropTypes from 'prop-types';

import { QuestionField } from '../../question-components/components';

const StaticFields = ({ questions }) => {
  return (
    <div className="static-fields-container ">
      {questions.map((question, index) => {
        return <QuestionField key={`static-question-field-${index}`} {...question} {...(question.props || {})} onlyDisabled />;
      })}
    </div>
  );
};

StaticFields.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      isLocked: PropTypes.bool,
      label: PropTypes.string,
      placeholder: PropTypes.string,
      props: PropTypes.object,
    })
  ),
};

StaticFields.defaultProps = {
  questions: [],
};

export default StaticFields;
