import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { change, formValueSelector, getFormValues } from 'redux-form';
import { isEmpty, debounce } from 'lodash';

import Modal from '../../../../common/modal/components/modal';
import AreaForm from './area-form';
import Loader from '../../../../common/global-loader/components/simple-loader';
import Tabs from '../../../../common/tabs/component/tabs';
import Tab from '../../../../common/tabs/component/tab';
import AreaHistory from './area-history';

import { handleDeleteAreaModal, showArea } from '../../actions/action-creators';

import { deleteArea, getAreaGroupsFilterDropdown, updateArea, getAreaDetails } from '../../actions/area-actions';

import { validate } from './validators/area-validator';

import { modules, objectTools } from '../../constants/constants';
import { FEATURES, FORMS } from '../../../../common/constants';
import { formConstants, toolbarItems, tabNames } from '../../constants/areas-constants';
import { clipTaskOptions } from '../../constants/inspection-settings';

import '../../styles/explosive-zone-details.scss';

class AreaDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedArea: {},
      activeToolbarItem: toolbarItems[tabNames.details].name,
    };
    this.formChangeDebounce = debounce(this.submitForm, 100);
  }

  fetchData = (id, tabName) => {
    const { getAreaDetails, inspectionId, getAreaGroupsFilterDropdown, viewer } = this.props;
    const { Potree } = window;

    if (!id) {
      return;
    }
    if (tabName === tabNames.details) {
      getAreaDetails(id, selectedArea => {
        const selectedClipTask = clipTaskOptions.find(o => selectedArea && o.value === selectedArea[formConstants.fields.clipTask]) || clipTaskOptions[1];
        this.setState({
          selectedArea: {
            ...selectedArea,
            [formConstants.fields.selectedClipTask]: selectedClipTask,
          },
        });
        if (viewer) {
          viewer.setClipTask(Potree.ClipTask[selectedClipTask.value]);
        }

        getAreaGroupsFilterDropdown(inspectionId);
      });
    }
  };

  componentDidMount() {
    const { queryItem } = this.props;

    this.fetchData(queryItem, tabNames.details);
  }

  componentDidUpdate = prevProps => {
    const { queryItem } = this.props;

    if (prevProps.queryItem !== queryItem && queryItem && queryItem > 0) {
      this.fetchData(queryItem, tabNames.details);
    }
  };

  submitForm = values => {
    const { updateArea } = this.props;

    updateArea(values);
  };

  handleOnChange = (values, b, c) => {
    if (!c.pristine) {
      const errors = validate(values);
      if (isEmpty(errors)) {
        this.formChangeDebounce(values);
      }
    }
  };

  openDeleteArea = () => {
    const { t } = this.context;
    const { toggleDeleteModal, deleteArea, handleActivePage } = this.props;
    const {
      selectedArea: { ID },
    } = this.state;

    if (!ID || ID < 0) {
      deleteArea({ ID }, () => handleActivePage(modules.areas));
    } else {
      const deleteAreaModalData = {
        isOpen: true,
        type: 'yes-no',
        title: t('DELETE_AREA_MODAL.TITLE'),
        closeAction: this.closeDeleteArea,
        confirmAction: () => deleteArea({ ID }, () => handleActivePage(modules.areas)),
      };

      toggleDeleteModal(deleteAreaModalData);
    }
  };

  markOn3d = vals => {
    const { objectToolClick, changeField, showArea } = this.props;
    objectToolClick(objectTools.volumeClip, '', false, newBody => {
      // const data = { ...vals, ...newBody };
      // updateArea({ ...data, visible: true });
      changeField(formConstants.fields.geometry, newBody[formConstants.fields.geometry]);
      changeField(formConstants.fields.modelDetails, newBody[formConstants.fields.modelDetails]);
      showArea(vals[formConstants.fields.id]);
    });
  };

  closeDeleteArea = () => {
    const { toggleDeleteModal } = this.props;
    const modalData = {
      isOpen: false,
    };
    toggleDeleteModal(modalData);
  };

  render() {
    const { selectedArea, activeToolbarItem } = this.state;
    const { inspectionId, deleteAreaModalData, user, areaGroupID, getAreaGroupsFilterDropdown, areaGroups, changeField, geometry, formValues, viewer, areaDetailsLoading, queryItem } = this.props;

    if (areaDetailsLoading) {
      return <Loader isLoading={true} />;
    }

    return (
      <div className="component-details">
        <Tabs defaultTabKey={activeToolbarItem} navigationClassName="component-details__tabs" onChange={tabName => this.fetchData(queryItem, tabName)}>
          <Tab title={toolbarItems[tabNames.details].label} tabKey={tabNames.details}>
            <AreaForm
              viewer={viewer}
              onChange={this.handleOnChange}
              changeField={changeField}
              openDeleteArea={this.openDeleteArea}
              initialValues={selectedArea}
              selectedArea={selectedArea}
              user={user}
              markOn3d={this.markOn3d}
              geometry={geometry}
              areaGroups={areaGroups}
              areaGroupID={areaGroupID}
              formValues={formValues}
              getAreaGroups={searchText => getAreaGroupsFilterDropdown(inspectionId, searchText)}
            />
          </Tab>
          {FEATURES.modulesHistory?.visible && (
            <Tab title={toolbarItems[tabNames.history].label} tabKey={tabNames.history}>
              <AreaHistory areaID={queryItem} />
            </Tab>
          )}
        </Tabs>
        <Modal {...deleteAreaModalData} />
      </div>
    );
  }
}

AreaDetails.contextTypes = {
  t: PropTypes.func.isRequired,
};

const selector = formValueSelector(FORMS.areasForm);

const mapStateToProps = state => {
  const areaGroupID = selector(state, formConstants.fields.areaGroupId),
    geometry = selector(state, formConstants.fields.geometry);

  return {
    deleteAreaModalData: state.areasReducer.delAreaModalData,
    areas: state.areasReducer.areas,
    user: state.userReducer,
    viewer: state.potreeReducer.viewerInstance,
    areaGroupID,
    areaGroups: state.areasReducer.areaGroupsFilterDropdownData,
    geometry,
    formValues: getFormValues(FORMS.areasForm)(state),
    areaDetailsLoading: state.areasReducer.areaDetailsLoading,
  };
};

const mapDispatchToProps = dispatch => ({
  deleteArea: (data, cb) => dispatch(deleteArea(data, cb)),
  updateArea: data => dispatch(updateArea(data)),
  toggleDeleteModal: data => dispatch(handleDeleteAreaModal(data)),
  changeField: (fieldName, value) => dispatch(change(FORMS.areasForm, fieldName, value)),
  getAreaGroupsFilterDropdown: (inspectionId, searchText) => dispatch(getAreaGroupsFilterDropdown(inspectionId, searchText)),
  showArea: id => dispatch(showArea(id)),
  getAreaDetails: (id, callback) => dispatch(getAreaDetails(id, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AreaDetails);
