export default {
  SAP_SET_INTEGRATION_DETAILS: 'sapSetIntegrationDetails',
  SAP_SET_CHANGES_SAVED: 'sapSetChangesSaved',
  SAP_SAVE_DATA_START: 'sapSaveDataStart',
  SAP_SAVE_DATA_FAILURE: 'sapSaveDataFailure',
  SAP_SAVE_DATA_SUCCESS: 'sapSaveDataSuccess',
  SAP_SYNC_DATA_START: 'sapSyncDataStart',
  SAP_SYNC_DATA_FAILURE: 'sapSyncDataFailure',
  SAP_SYNC_DATA_SUCCESS: 'sapSyncDataSuccess',
  SET_INFO_MODAL_DATA_SAP: 'setInfoModalDataSap',
  GET_EQUIPMENT_START: 'getEquipmentStart',
  GET_EQUIPMENT_SUCCESS: 'getEquipmentSuccess',
  GET_EQUIPMENT_FAILURE: 'getEquipmentFailure',
  SET_EQUIPMENT_DATA: 'setEquipmentData',
  SET_SELECTED_NOTIFICATION: 'setSelectedNotification',
  SET_INTEGRATION_STATUS: 'setIntegrationStatus',
  SET_NOTIFICATIONS_DATA: 'setNotificationsData',
  GET_NOTIFICATIONS_START: 'getNotificationsStart',
  GET_NOTIFICATIONS_SUCCESS: 'getNotificationsSuccess',
  GET_NOTIFICATIONS_FAILURE: 'getNotificationsFailure',
};
