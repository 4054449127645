import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { isEmpty } from 'lodash';

import Button from '../../../../common/form/components/button';
import CustomInput from '../../../../common/form/components/input';
import BasicInput from '../common/input/basic-input';
import Textarea from '../../../../common/form/components/text-area';
import Comments from './comments';
import FieldUpload from './component-assets';
import AccessRenderer from '../../../../common/access-renderer/components/access-renderer';
import ColorPalette from '../../../../common/form/components/color-pallete';
import SetCameraPosition from './set-camera-position';
import Modal from '../../../../common/modal/components/modal';
import CustomInputRange from '../common/input/input-range';

import Helpers from '../../../../common/helpers';

import { toggleDeleteDefectModal, deleteDefect, setComponentDetails, addDefectComment, deleteMeasurementRelatedFile } from '../../actions/inspection-actions';
import { setComponentDetailsData } from '../../actions/action-creators';

import { validate } from './validators/defect-validator';

import { formConstants, uploadGroups, textAreaMaxChars } from '../../constants/measurement-constants';
import uploadConstants from '../../../upload/constants/constants';
import { modules } from '../../constants/constants';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../common/permissions-constants';
import { FORMS, AMAZON_IMAGE_SIZES } from '../../../../common/constants';

import 'react-input-range/lib/css/index.css';

class MeasurementForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newComment: '',
      imageType: AMAZON_IMAGE_SIZES.small.name,
      modalData: {
        isOpen: false,
      },
    };
  }

  handleSeverityChange = val => {
    const { changeField } = this.props;
    const color = Helpers.fetchReviewedClass(val);

    changeField(formConstants.fields.color, color);
  };

  formatDate = input => {
    if (!input) return;

    return Helpers.getDateFromUnix(input);
  };

  openDeleteDefectModal = () => {
    const { t } = this.context;
    const { selectedDefect, toggleDeleteModal, deleteDefect, handleActivePage } = this.props;
    const modalData = {
      isOpen: true,
      content: t('DELETE_MEASUREMENT_MODAL.DESC', { defectName: selectedDefect.Name }),
      type: 'yes-no',
      confirmAction: () => {
        deleteDefect({ MeasurementID: selectedDefect[formConstants.fields.id], SystemType: selectedDefect.SystemType }, () => {
          handleActivePage(modules.measurements);
        });
        this.closeDeleteDefectModal();
      },
      closeAction: this.closeDeleteDefectModal,
    };
    toggleDeleteModal(modalData);
  };

  closeDeleteDefectModal = () => {
    const { toggleDeleteModal } = this.props;
    const modalData = {
      isOpen: false,
    };
    toggleDeleteModal(modalData);
  };

  render() {
    const { t } = this.context;
    const { imageType } = this.state;
    const { handleSubmit, changeField, user, openUploadModal, selectedDefect, comments, addDefectComment, measurementFiles, deleteMeasurementRelatedFile, loggedByUserID } = this.props;

    const { modalData } = this.state;

    const otherFiles = !isEmpty(measurementFiles) ? measurementFiles[uploadGroups.measurementOther] || [] : [];

    return (
      <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.measurements].edit.name} id={loggedByUserID} ownerRequiredPermission={PERMISSIONS[PERMISSION_TYPES.measurements].create.name}>
        {({ hasAccess }) => {
          const readonly = !hasAccess;

          return (
            <form className="defect-form" onSubmit={handleSubmit} noValidate>
              <div className="defect-form__container" id={'defect-form'}>
                <Field id={formConstants.fields.systemType} name={formConstants.fields.systemType} labelClass="f-secondary-light" component={CustomInput} isHidden={true} type="text" />
                <Field id={formConstants.fields.color} disabled={readonly} name={formConstants.fields.color} component={ColorPalette} />

                <Field
                  id={formConstants.fields.name}
                  disabled={readonly}
                  name={formConstants.fields.name}
                  component={BasicInput}
                  placeholder={t('MEASUREMENT_DETAILS.FORM_NAME')}
                  label={t('MEASUREMENT_DETAILS.FORM_NAME')}
                  labelClass="f-secondary-dark defect-form__label"
                  type="text"
                />

                <Field
                  id={formConstants.fields.type}
                  disabled={readonly}
                  name={formConstants.fields.type}
                  component={BasicInput}
                  isHidden={true}
                  placeholder={t('DEFECT_DETAILS.FORM_TYPE')}
                  label={t('DEFECT_DETAILS.FORM_TYPE')}
                  labelClass="f-secondary-dark defect-form__label"
                  type="text"
                />
                <Field
                  id={formConstants.fields.severity}
                  disabled={readonly}
                  name={formConstants.fields.severity}
                  minValue={1}
                  maxValue={10}
                  isHidden={true}
                  inputProps={{ onChange: this.handleSeverityChange }}
                  component={CustomInputRange}
                  placeholder={t('DEFECT_DETAILS.FORM_SEVERITY')}
                  label={t('DEFECT_DETAILS.FORM_SEVERITY')}
                  labelClass="f-secondary-dark defect-form__label defect-form__label--range"
                  hideBorder
                  type="range"
                />
                <div className="comments-container">
                  <Field
                    id={formConstants.fields.description}
                    disabled={readonly}
                    name={formConstants.fields.description}
                    component={Textarea}
                    placeholder={'DEFECT_DETAILS.FORM_DESCRIPTION_PLACEHOLDER'}
                    label={'DEFECT_DETAILS.FORM_DESCRIPTION'}
                    labelClass="f-secondary-dark defect-form__label"
                    maxChars={textAreaMaxChars}
                    enableAutoResize={true}
                    wrapperClassName={'inspection-input'}
                  />
                </div>

                <FieldUpload
                  disabled={readonly}
                  label={t('DEFECT_FORM.FORM_FILES')}
                  labelClass="f-secondary-dark defect-form__label"
                  noFileText={t('DEFECT_FORM.NO_FILES')}
                  className="defect-form__field-container"
                  openUploadModal={openUploadModal}
                  uploadType={uploadConstants.uploadType.measurementFiles}
                  files={otherFiles}
                  restUploadParams={{ UploadGroup: uploadGroups.measurementOther }}
                  imageType={imageType}
                  deleteFile={fileID => deleteMeasurementRelatedFile(fileID, selectedDefect.ID)}
                  actionsMenu={[
                    {
                      title: 'COMPONENT_ASSETS_DROPDOWN.SETTINGS_MENU.ITEM_1',
                      action: data => {
                        window.open(data.URL);
                      },
                    },
                    {
                      title: 'COMPONENT_ASSETS_DROPDOWN.SETTINGS_MENU.ITEM_2',
                      action: data => {
                        Helpers.getFileExtensionAndDownload(data);
                      },
                    },
                    {
                      title: 'COMPONENT_ASSETS_DROPDOWN.SETTINGS_MENU.ITEM_4',
                      action: file => deleteMeasurementRelatedFile(file.FileID, selectedDefect.ID),
                      access: {
                        id: loggedByUserID,
                        ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.measurements].create.name,
                        visibleFor: [PERMISSIONS[PERMISSION_TYPES.measurements].edit.name],
                      },
                    },
                  ]}
                />

                <Field
                  id={formConstants.fields.date}
                  disabled={true}
                  name={formConstants.fields.date}
                  component={BasicInput}
                  format={this.formatDate}
                  placeholder={t('DEFECT_DETAILS.FORM_DATE')}
                  label={t('DEFECT_DETAILS.FORM_DATE')}
                  labelClass="f-secondary-dark defect-form__label"
                  type="text"
                />
                <Field
                  id={formConstants.fields.loggedBy}
                  disabled={true}
                  name={formConstants.fields.loggedBy}
                  component={BasicInput}
                  placeholder={t('DEFECT_DETAILS.FORM_LOGGED_BY')}
                  label={t('DEFECT_DETAILS.FORM_LOGGED_BY')}
                  labelClass="f-secondary-dark defect-form__label"
                  type="text"
                />
                <SetCameraPosition disabled={readonly} handleChange={newValue => changeField('CameraPosition', { coordinates: newValue })} />
              </div>
              <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.measurements].addComment.name}>
                {({ hasAccess }) => {
                  return (
                    <Comments
                      id={formConstants.fields.comments}
                      disabled={!hasAccess}
                      name={formConstants.fields.comments}
                      selectedDefect={selectedDefect}
                      comments={comments}
                      addComment={addDefectComment}
                      placeholder={t('DEFECT_DETAILS.FORM_ADD_COMMENT_PLACEHOLDER')}
                      label={t('DEFECT_DETAILS.FORM_COMMENTS')}
                      labelClass="f-secondary-dark defect-form__label horizontal-padding"
                      type="text"
                    />
                  );
                }}
              </AccessRenderer>

              <div className="buttons">
                <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.measurements].delete.name}>
                  {({ hasAccess }) => {
                    return (
                      <Button
                        type="button"
                        onClick={this.openDeleteDefectModal}
                        disabled={Helpers.isGuestUser(user) || !hasAccess}
                        text={t('DEFECT_DETAILS.FORM_DELETE_MEASUREMENT')}
                        variant="danger-outline"
                      />
                    );
                  }}
                </AccessRenderer>
              </div>

              <Modal {...modalData} />
            </form>
          );
        }}
      </AccessRenderer>
    );
  }
}

MeasurementForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

MeasurementForm = reduxForm({
  form: FORMS.measurementForm,
  validate,
  enableReinitialize: true,
})(MeasurementForm);

const selector = formValueSelector(FORMS.measurementForm);
const mapStateToProps = (state, props) => {
  const loggedBy = selector(state, formConstants.fields.loggedBy),
    loggedByUserID = selector(state, formConstants.fields.loggedByUserID),
    SystemType = selector(state, formConstants.fields.systemType),
    componentID = selector(state, formConstants.fields.componentID);

  return {
    user: state.userReducer,
    comments: state.inspectionReducer.defectComments,
    componentDetails: state.inspectionReducer.componentDetails,
    viewer: state.potreeReducer.viewerInstance,
    measurementFilesLoading: state.uploadReducer.measurementFilesLoading,
    componentID,
    loggedBy,
    SystemType,
    loggedByUserID,
  };
};
const mapDispatchToProps = dispatch => ({
  toggleDeleteModal: data => dispatch(toggleDeleteDefectModal(data)),
  deleteDefect: (data, callback) => dispatch(deleteDefect(data, callback)),
  setComponentDetails: id => dispatch(setComponentDetails(id)),
  setComponentDetailsData: data => dispatch(setComponentDetailsData(data)),
  addDefectComment: data => dispatch(addDefectComment(data)),
  deleteMeasurementRelatedFile: (fileID, defectID) => dispatch(deleteMeasurementRelatedFile(fileID, defectID)),
});

MeasurementForm = connect(mapStateToProps, mapDispatchToProps)(MeasurementForm);
export default MeasurementForm;
