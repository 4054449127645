import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { isEmpty } from 'lodash';

import '../styles/toolbar.scss';

class Toolbar extends Component {
  render() {
    const { t } = this.context;
    const { activeItem, toolbarItems, handleActiveTab, className } = this.props;

    return (
      <div className={`custom-toolbar ${className || ''}`}>
        {!isEmpty(toolbarItems) &&
          toolbarItems.map((item, index) => {
            return item.hidden ? null : (
              <div key={index} onClick={handleActiveTab ? () => handleActiveTab(item.name) : null} className={`item ${activeItem === item.name ? 'item--active' : ''}`}>
                <p className="f-primary noselect">{t(item.label)}</p>
              </div>
            );
          })}
      </div>
    );
  }
}

Toolbar.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Toolbar;
