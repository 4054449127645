export default {
  SET_TEMPLATE_MODAL_DATA: 'setTemplateModalData',
  SET_ISOLATION_TEMPLATE_LIST: 'handleSetIsolationTemplateList',
  AMEND_ISOLATION_TEMPLATE_LIST: 'handleAmendIsolationTemplateList',
  SET_SELECTED_ISOLATION_TEMPLATE: 'setSelectedIsolationTemplate',
  REMOVE_ISOLATION_TEMPLATE_BY_PROP: 'removeIsolationTemplateByProp',
  ADD_ISOLATION_TEMPLATE_AT_INDEX: 'addIsolationTemplateAtIndex',
  UPDATE_ISOLATION_TEMPLATE_BY_PROP: 'updateIsolationTemplateByProp',
  UPDATE_ISOLATION_SECTIONS: 'updateIsolationSections',
  UPDATE_PERMIT_TEMPLATE_ISOLATION_SECTIONS: 'updatePermitTemplateIsolationSections',
};
