import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { isEmpty } from 'lodash';

import ProjectItem from './project-item';
import ProjectHeader from './project-header';

import ProjectWrapper from './project-wrapper';

import { projectTypes } from '../constants/project-fields';

import '../styles/sidebar.scss';

class Sidebar extends Component {
  renderProjectsByType = (projects, index) => {
    const { t } = this.context;
    const {
      toggleDropdown,
      selectProject,
      deleteProject,
      deleteInspection,
      toggleDeleteProjectModal,
      archiveProjectModal,
      archiveProject,
      setProjectToEdit,
      openInspectionAssetModal,
      getProjectClickAction,
      getInspectionClickAction,
      handleViewInspection,
    } = this.props;

    const emptyState = <p className="empty-state f-primary noselect">{t('PROJECT.EMPTY_STATE')}</p>;

    if (isEmpty(projects.items)) {
      return (
        <ProjectWrapper title={t(projects.title)} key={index}>
          {emptyState}
        </ProjectWrapper>
      );
    }

    switch (projects.name) {
      case projectTypes.active.name:
        return (
          <ProjectWrapper title={t(projects.title)} key={index} className="sidebar__active">
            {projects.items.map((project, index) => (
              <ProjectItem
                key={project.ID}
                selectProject={selectProject}
                toggleDropdown={toggleDropdown}
                project={project}
                openInspectionAssetModal={inspectionId => openInspectionAssetModal(project.ID, inspectionId)}
                deleteProject={deleteProject}
                toggleDeleteProjectModal={toggleDeleteProjectModal}
                deleteInspection={deleteInspection}
                archiveProjectModal={archiveProjectModal}
                archiveProject={archiveProject}
                setProjectToEdit={setProjectToEdit}
                getInspectionClickAction={getInspectionClickAction}
                getProjectClickAction={getProjectClickAction}
                settingsMenu={projects.settingsMenu}
                inspectionMenu={projects.inspectionMenu}
                handleViewInspection={handleViewInspection}
                index={index}
              />
            ))}
          </ProjectWrapper>
        );
      case projectTypes.archived.name:
        return (
          <ProjectWrapper title={t(projects.title)} key={index} className="sidebar__archived">
            {projects.items.map(project => (
              <ProjectItem
                key={project.ID}
                selectProject={selectProject}
                toggleDropdown={toggleDropdown}
                project={project}
                deleteProject={deleteProject}
                toggleDeleteProjectModal={toggleDeleteProjectModal}
                archiveProjectModal={archiveProjectModal}
                archiveProject={archiveProject}
                setProjectToEdit={setProjectToEdit}
                getProjectClickAction={getProjectClickAction}
                settingsMenu={projects.settingsMenu}
                reducedActions={true}
                handleViewInspection={handleViewInspection}
              />
            ))}
          </ProjectWrapper>
        );
      default:
        return <div />;
    }
  };

  render() {
    const { projects, handleFilterChanged, sortingObj, handleSortingChanged } = this.props;

    return (
      <div className="sidebar-container" data-cy="projects-sidebar">
        <div className="sidebar">
          <ProjectHeader handleFilterChanged={handleFilterChanged} sortingObj={sortingObj} handleSortingChanged={handleSortingChanged} />
          <div className="project-list" data-cy="projects-list">
            {(projects || []).map((p, index) => this.renderProjectsByType(p, index))}
          </div>
        </div>
      </div>
    );
  }
}

Sidebar.contextTypes = {
  t: PropTypes.func.isRequired,
};

Sidebar.propTypes = {
  projects: PropTypes.array.isRequired,
  handleFilterChanged: PropTypes.func.isRequired,
  handleSortingChanged: PropTypes.func.isRequired,
};

export default Sidebar;
