import { isEmpty } from 'lodash';
import { filterFields, formConstants } from '../../../constants/notification-constants';

export const validate = values => {
  const errors = {};
  if (!isEmpty(values[filterFields.properties])) {
    const propertyArrayErrors = [];
    values[filterFields.properties].forEach((el, index) => {
      if (!isEmpty(el[formConstants.fields.name]) && isEmpty(el[formConstants.fields.value])) {
        propertyArrayErrors[index] = { [formConstants.fields.value]: 'ADVANCED_CUSTOM_PROPS_SEARCH_KEYWORD_MISSING' };
      }
    });
    if (!isEmpty(propertyArrayErrors)) {
      errors[filterFields.properties] = propertyArrayErrors;
    }
  }
  return errors;
};
