import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import '../../../styles/upload-file-modal.scss';
import Dropzone from '../../../../../common/upload/components/upload-dropzone';
import { fields, sortDirection, supportedUploadFileMimeTypes, uploadedFileTableConfig } from '../../../constants/constants';
import TableTemplate from '../../../../../common/double-table/components/table-template';
import { formatUploadedFileTableCells } from '../../../actions/table-formatting';
import Button from '../../../../../common/form/components/button';
import { onDMSDropAsset, isUploadedDMSFileValid, isUploadedDMSFileNameValid } from '../../../helpers/dms-helpers';
import { setGenericNotification } from '../../../../../common/notification/actions/action-creators';
import { connect } from 'react-redux';
import { uploadedFileValidation } from '../../../constants/constants';
import RenderIf from '../../../../../common/render-if/components/render-if';
import VersionModal from './components/version-modal/version-modal';
import { fetchNewVersionNumberForDocument } from '../../../actions/dm-api-calls';

const UploadFileModal = (props, context) => {
  const { t } = context;
  const { label, labelWhite, labelGreen, fileUploadHandler, closeAction, user, statusData, setGenericNotification, isVersionModal, documentDetails, fetchNewVersionNumberForDocument } = props;
  const [uploadFiles, setUploadItems] = useState([]);
  // next version number is fetched here and not in versionModal since from here we start the process of adding new file
  // plus I didn't want to do encapsulate versionModal with connect from react-redux
  const [nextVersionNumber, setNewVersionNumber] = useState(1);
  const isUploadFileEmpty = useMemo(() => isEmpty(uploadFiles), [uploadFiles]);

  useEffect(() => {
    if (isVersionModal) {
      fetchNewVersionNumberForDocument(documentDetails[fields.sourceID], onSuccessfulNextVersionFetch);
    }
    // eslint-disable-next-line
  }, []);

  const onSuccessfulNextVersionFetch = version => setNewVersionNumber(version);

  const onSmartDrawingToggle = (value, row) => {
    const newUploadedFiles = [...uploadFiles];
    const foundIndex = newUploadedFiles.findIndex(file => file.index === row.index);

    if (foundIndex > -1) {
      newUploadedFiles[foundIndex][fields.smartDrawing] = value;
      setUploadItems(newUploadedFiles);
    }
  };

  const onDeleteRow = row => {
    const newUploadedFiles = [...uploadFiles];
    const foundIndex = newUploadedFiles.findIndex(file => file.index === row.index);
    if (foundIndex > -1) {
      newUploadedFiles.splice(foundIndex, 1);
      setUploadItems(newUploadedFiles);
    }
  };

  const handleChangeStatus = (status, row) => {
    const newUploadedFiles = [...uploadFiles];
    const foundIndex = newUploadedFiles.findIndex(file => file.index === row.index);
    if (foundIndex > -1) {
      newUploadedFiles[foundIndex][fields.status] = status.value;
      setUploadItems(newUploadedFiles);
    }
  };

  const onDropAssetHandler = async files => {
    if (!isUploadedDMSFileValid(files)) {
      setGenericNotification({
        isDisplayed: true,
        type: 'error',
        text: t('WORK_ORDER_CREATE_VALIDATION.VALIDATION_FILE_SIZE', { fileSize: `${uploadedFileValidation.value}${uploadedFileValidation.unit}` }),
        icon: 'close',
      });
    } else if (!isUploadedDMSFileNameValid(files)) {
      setGenericNotification({
        isDisplayed: true,
        type: 'error',
        text: t('DOCUMENT_MANAGEMENT_MODAL.FILE_NAME_MAX'),
        icon: 'close',
      });
    }
    /* In version modal upload we only accept one file
    With multiple:false in Dropzone when user drags and drops multiple files they are passed as empty array
    hence the isEmpty(files) */
    if (isVersionModal && isEmpty(files)) {
      setGenericNotification({
        isDisplayed: true,
        type: 'error',
        text: t('DMS_FILE.VERSION_UPLOAD_MAX_ONE_FILE'),
        icon: 'close',
      });
      return;
    }

    await onDMSDropAsset(files, newFiles => setUploadItems([...uploadFiles, ...newFiles]));
  };

  // TODO: might think about moving file rendering to a separate component
  const renderUploadFilesContent = uploadFiles => {
    return (
      <div className="dm-upload-file-modal__content">
        <div className="files-title">{t('PERMIT.FILES_SECTION')}</div>
        <TableTemplate
          data={uploadFiles}
          tableConfig={uploadedFileTableConfig}
          filters={{}}
          sortDirection={sortDirection.asc}
          sortByColumn={fields.realName}
          handleFormatCells={formatUploadedFileTableCells}
          TotalItems={uploadFiles.length}
          searchPlaceholder={''}
          noDataText={t('DOCUMENT_MANAGEMENT_TABLE.FILE_TABLE_NO_DATA')}
          onRowClick={() => null}
          onSortClick={() => null}
          isLoading={false}
          hasNext={false}
          loadMoreOnClick={() => null}
          customTableClass={'files-table'}
          searchInputValue={''}
          searchInputOnChange={() => null}
          onDropdownClick={() => null}
          selectedItem={null}
          showSearchBar={false}
          user={user}
          onToggleClick={onSmartDrawingToggle}
          onDeleteRow={onDeleteRow}
          handleChangeStatus={handleChangeStatus}
          statusData={statusData}
        />

        <Dropzone
          {...props}
          supportedMimeTypes={supportedUploadFileMimeTypes}
          onDrop={file => onDropAssetHandler(file)}
          label={label}
          labelWhite={t('ADD_MORE_FILES')}
          labelGreen={labelGreen}
          multiple={true}
        />
        <div className="files-cta-wrapper">
          <Button onClick={closeAction} type="button" text={t('CANCEL')} variant="gray-outline" className="modal-button-custom h-md" />
          {/* TODO: should the upload button be disabled if !isUploadedDMSFileValid or !isUploadedDMSFileNameValid validation fails? */}
          <Button onClick={() => fileUploadHandler(uploadFiles)} text={t('UPLOAD')} variant="success" className="modal-button-custom h-md" />
        </div>
      </div>
    );
  };

  return (
    <div className="upload-modal upload-dm-file-modal noselect">
      <RenderIf if={!isVersionModal && !isUploadFileEmpty}>{renderUploadFilesContent(uploadFiles)}</RenderIf>
      <RenderIf if={isVersionModal && !isUploadFileEmpty}>
        <VersionModal
          versionFile={uploadFiles[0] ? uploadFiles[0] : {}}
          documentDetails={documentDetails}
          closeAction={closeAction}
          fileUploadHandler={fileUploadHandler}
          nextVersionNumber={nextVersionNumber}
        />
      </RenderIf>
      <RenderIf if={isUploadFileEmpty}>
        <Dropzone {...props} onDrop={file => onDropAssetHandler(file)} label={label} labelWhite={labelWhite} labelGreen={labelGreen} multiple={!isVersionModal} />
      </RenderIf>
    </div>
  );
};

UploadFileModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  setGenericNotification: data => dispatch(setGenericNotification(data)),
  fetchNewVersionNumberForDocument: (sourceID, callback) => dispatch(fetchNewVersionNumberForDocument(sourceID, callback)),
});

export default connect(null, mapDispatchToProps)(UploadFileModal);
