import actionTypes from '../../../app/inspections/constants/action-types';

const inspectionState = {
  downloadReportHistory: null,
  downloadReportPreferences: null,
  sortByFilter: null,
};

export const downloadReportReducer = (state = inspectionState, payload) => {
  switch (payload.type) {
    case actionTypes.SET_DOWNLOAD_REPORT_HISTORY_DATA:
      return {
        ...state,
        downloadReportHistory: payload.data,
      };
    case actionTypes.SET_DOWNLOAD_REPORT_PREFERENCES:
      return {
        ...state,
        downloadReportPreferences: payload.data,
      };
    case actionTypes.SET_DOWNLOAD_REPORT_PREFERENCES_FIELD:
      return {
        ...state,
        downloadReportPreferences: { ...state.downloadReportPreferences, ...payload.data },
      };
    case actionTypes.SET_SORT_BY_FILTER:
      return { ...state, sortByFilter: payload.data };
    default:
      return state;
  }
};
