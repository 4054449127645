import React from 'react';
import { TablePropTypes } from '../constants/constants';
import '../styles/table.scss';
import Body from './body';
import Header from './header';

const TableWithPath = ({
  tableConfig,
  data,
  translationModule,
  onRowClick,
  rowClickDisabled,
  onSortClick,
  sortingObj,
  formatCell,
  stickyHeader,
  sortByColumnProp,
  sortDirectionProp,
  selectedItem,
  showHeader = true,
  loadForDMS = false,
  previousScroll = 0,
  containerRef = null,
}) => {
  return (
    <div className="twp">
      {showHeader && <Header {...{ tableConfig, translationModule, onSortClick, sortingObj, stickyHeader, sortByColumnProp, sortDirectionProp }} />}
      <Body {...{ tableConfig, data, onRowClick, rowClickDisabled, formatCell, selectedItem, loadForDMS, previousScroll, containerRef }} />
    </div>
  );
};

TableWithPath.propTypes = { ...TablePropTypes };
export default TableWithPath;
