import React, { useCallback, useMemo, useRef, useState } from 'react';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin from '@draft-js-plugins/mention';
import '../styles/editor.scss';
import mentionsStyles from '../styles/mentions.module.scss';

import '../styles/comments-text-box.scss';
import CommentUsersAndTeamsDropdown from './comment-users-and-teams-dropdown';
import Icon from '../../icon/components/icon';
import { commentTagTypes } from '../../comments-tab/constants/constants';
import Entry from './mention-entry';
import PropTypes from 'prop-types';

const CommentsTextBox = ({ isExpanded, suggestions, editorState, onBlur, setEditorState, fetchSuggestions, onFocus, onAddMention, getTeamUsers, maxLength }, { t }) => {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const suggestionToPresent = suggestions.map(sug => ({ ...sug, name: sug.Name }));

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
      theme: mentionsStyles,
      mentionPrefix: '@',
      supportWhitespace: true,
      mentionComponent(mentionProps) {
        const decoratedText = mentionProps.decoratedText.slice(0, -1);
        return (
          <span className={mentionProps.className} data-offset-key={`${mentionProps.children[0].props.key}`} contentEditable={false}>
            <span data-text="true">
              {decoratedText}
              {mentionProps.mention.Type === commentTagTypes.team ? <Icon name="users" size="xs" onClick={() => getTeamUsers(mentionProps.mention.ID, mentionProps.mention.name.slice(0, -1))} /> : ''}
            </span>
          </span>
        );
      },
    });
    const { MentionSuggestions } = mentionPlugin;
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
    //eslint-disable-next-line
  }, []);

  const onChange = useCallback(
    _editorState => {
      setEditorState(_editorState);
    },
    [setEditorState]
  );
  const onOpenChange = useCallback(_open => {
    setOpen(_open);
  }, []);
  const onSearchChange = useCallback(
    ({ value }) => {
      fetchSuggestions(value);
    },
    [fetchSuggestions]
  );

  const onMentionAdded = useCallback(
    tag => {
      tag.name = `${tag.name}#`;
      onAddMention(tag);
      fetchSuggestions('');
    },
    [fetchSuggestions, onAddMention]
  );

  const handleBeforeInput = useCallback(
    chars => {
      const totalLength = chars.length + editorState.getCurrentContent().getPlainText().length;
      const flag = totalLength > maxLength ? 'handled' : 'not-handled';
      return flag;
    },
    [editorState, maxLength]
  );

  return (
    <div
      className={`editor ${isExpanded ? 'expanded' : ''}`}
      onClick={() => {
        ref.current.focus();
      }}
      onBlur={onBlur}
      onFocus={onFocus}
    >
      <Editor
        editorKey={'editor'}
        editorState={editorState}
        handlePastedText={(value, html, editorState) => {
          setEditorState(editorState);
        }}
        onChange={onChange}
        plugins={plugins}
        ref={ref}
        placeholder={isExpanded ? t('MENTION.EXPANDED_PLACEHOLDER') : t('MENTION.SHRUNK_PLACEHOLDER')}
        handleBeforeInput={handleBeforeInput}
      />
      <MentionSuggestions
        open={open}
        onOpenChange={onOpenChange}
        suggestions={suggestionToPresent}
        onSearchChange={onSearchChange}
        onAddMention={onMentionAdded}
        entryComponent={Entry}
        popoverContainer={({ children }) => {
          return <CommentUsersAndTeamsDropdown children={children} />;
        }}
      />
    </div>
  );
};

CommentsTextBox.contextTypes = {
  t: PropTypes.func.isRequired,
};

CommentsTextBox.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  suggestions: PropTypes.array.isRequired,
  editorState: PropTypes.object.isRequired,
  onBlur: PropTypes.func.isRequired,
  setEditorState: PropTypes.func.isRequired,
  fetchSuggestions: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onAddMention: PropTypes.func.isRequired,
  getTeamUsers: PropTypes.func.isRequired,
  maxLength: PropTypes.number,
};

CommentsTextBox.defaultProps = {
  maxLength: 1000,
};

export default CommentsTextBox;
