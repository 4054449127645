import { cloneDeep, filter, find, findIndex, isEmpty, values } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Field, FieldArray, getFormSyncErrors, getFormValues, reduxForm } from 'redux-form';

import ExpandableSection from '../../../../common/expandable-section/components/expandable-section';
import RenderIf from '../../../../common/render-if/components/render-if';

import Helpers from '../../../../common/helpers';
import Icon from '../../../../common/icon/components/icon';
import PaginationNavigator from '../../../../common/load-more/components/pagination-navigator';
import Modal from '../../../../common/modal/components/modal';
import { alarmDefaults } from '../../../../common/question-components/constants/measurement-point-question-constants';
import { additionalFields, questionTypes } from '../../../../common/question-components/constants/question-constants';
import ReducerHelpers from '../../../../common/reducer-helpers';
import { millisecondsToTime } from '../../../../common/section/helpers/section-helpers';
import Show3dLocationModal from '../../../../common/show-3d-location-modal/components/show-3d-location-modal';
import InfoTooltip from '../../../../common/tooltip/components/info-tooltip';
import { getIconForFileType } from '../../../document-management/actions/table-formatting';
import { fields as dmsFields } from '../../../document-management/constants/constants';
import { measurementTypes, moduleSectionQuestionFields } from '../../constants/constants';
import { componentHasAllCoordinatesZero } from '../../helpers/inspection-helper';
import ReadingFormFields from '../readings-and-gauges/components/measurement-reading/reading-form-fields';
import { alarmColorPalette } from '../readings-and-gauges/constants/alarm-constants';
import { formConstants } from '../readings-and-gauges/constants/measurement-point-constants';
import { defaultPagingObj, filterProps } from './constants/work-order-checklist-constants';
import { fields } from './constants/work-order-right-side-constants';
import { mandatoryQuestionAnswered, questionAnswered } from './helpers/checklist-procedure-helpers';
import { validate } from './validators/work-order-questions-form-validator';
import WorkOrderSectionComponents from './work-order-section-components';
import WorkOrderSectionEvidenceFiles from './work-order-section-evidence-files';

const WorkOrderSectionChecklistQuestionsForm = (
  {
    questions = [],
    moduleId,
    handleFieldChange,
    disabled,
    change,
    touchField,
    handle3DTagLocationClick,
    formErrors,
    formValues,
    handleFileOpen,
    filesPropName,
    showEmptyVersion,
    getWorkOrderChecklistSectionQuestionDetails,
    inspectionId,
    questionsFilters,
    loadPreviousQuestions,
    loadNextQuestions,
    questionsLoading = false,
    defaultWorkorderDMSCategoryID,
  },
  { t }
) => {
  const [stateQuestions, setStateQuestions] = useState([]);
  const [modalData, setModalData] = useState({ isOpen: false });
  const [evidenceUploadModal, setEvidenceUploadModal] = useState({ isOpen: false });

  const evidenceFiles = useMemo(() => {
    if (evidenceUploadModal.isOpen && evidenceUploadModal.selectedQuestion && evidenceUploadModal.selectedQuestion[moduleSectionQuestionFields.id]) {
      // modal opened question id valid
      const files = formValues[`${moduleSectionQuestionFields.evidenceFiles}-${evidenceUploadModal.selectedQuestion[moduleSectionQuestionFields.id]}`] || [];
      return files.map(f => ({ ...f, [dmsFields.categoryId]: defaultWorkorderDMSCategoryID }));
    }
    return [];
  }, [evidenceUploadModal, formValues, defaultWorkorderDMSCategoryID]);

  useEffect(() => {
    setStateQuestions(questions.map(q => ({ ...q, isExpanded: false })));
  }, [questions]);

  const getFieldValue = (question, value, readingIndex) => {
    let name = '';
    let fieldValue = '';

    switch (question[moduleSectionQuestionFields.type]) {
      case questionTypes.DATE.valueId:
        fieldValue = Helpers.isDateValid(value) ? Helpers.getUnixDate(new Date(value).getTime()) : value;
        return { value: fieldValue, question };
      case questionTypes.NUMBER.valueId:
        fieldValue = value ? Number(value) : value;
        return { value: fieldValue, question };
      case questionTypes.MULTI_ANSWER.valueId:
        const questionCopy = cloneDeep(question);
        name = `${questionTypes.MULTI_ANSWER.fieldName}-${questionCopy[moduleSectionQuestionFields.id]}`;
        const questionOptions = Object.assign([], questionCopy[moduleSectionQuestionFields.questionOptions]);

        const selectedOptions = filter(questionOptions, questionOption => {
          return formValues[`${name}-${questionOption[moduleSectionQuestionFields.id]}`];
        }).map(option => option[moduleSectionQuestionFields.id]);

        const index = selectedOptions.indexOf(value);
        const questionOptionsIndex = findIndex(questionOptions, { [moduleSectionQuestionFields.id]: value });

        if (questionCopy[moduleSectionQuestionFields.allowMultiple]) {
          const option = questionOptions[questionOptionsIndex];
          option[moduleSectionQuestionFields.isAnswer] = index === -1;
          questionOptions.splice(questionOptionsIndex, 1, option);
          questionCopy[moduleSectionQuestionFields.questionOptions] = questionOptions;

          if (index > -1) {
            selectedOptions.splice(index, 1);
          } else {
            selectedOptions.push(value);
          }

          return { value: selectedOptions, questionCopy };
        } else {
          for (let i = 0; i < questionOptions.length; i++) {
            const option = questionOptions[i];
            if (option[moduleSectionQuestionFields.id] === value && !option[moduleSectionQuestionFields.isAnswer]) {
              option[moduleSectionQuestionFields.isAnswer] = true;
            } else {
              option[moduleSectionQuestionFields.isAnswer] = false;
            }

            questionOptions.splice(i, 1, option);
            questionCopy[moduleSectionQuestionFields.questionOptions] = questionOptions;

            change(`${name}-${questionOptions[i][moduleSectionQuestionFields.id]}`, questionOptions[i][moduleSectionQuestionFields.id] === questionCopy[moduleSectionQuestionFields.id] ? value : null);
          }

          fieldValue = index > -1 ? [] : [value];
          return { value: fieldValue, questionCopy };
        }
      case questionTypes.CHECKBOX.valueId:
        name = `${questionTypes.CHECKBOX.fieldName}-${question[moduleSectionQuestionFields.id]}`;
        change(`${name}-yes`, value ? 'true' : null);
        change(`${name}-no`, value ? null : 'true');

        return { value, question };

      case questionTypes.MEASUREMENT_POINT.valueId:
        const mpQuestionCopy = cloneDeep(question);
        const prevAnswerReadingValues = mpQuestionCopy?.Answers?.length > 0 && question.Answers[0].AnswerReadingValues ? [...mpQuestionCopy.Answers[0].AnswerReadingValues] : []; // Ensure this is initialized correctly

        fieldValue = value ? Number(value) : null;
        name = `${questionTypes.MEASUREMENT_POINT.fieldName}-${question[moduleSectionQuestionFields.id]}`;

        // Initialize AnswerReadingValues if not present
        if (!mpQuestionCopy.Answers || mpQuestionCopy.Answers.length === 0) {
          mpQuestionCopy.Answers = [{ AnswerReadingValues: [] }];
        } else if (!mpQuestionCopy.Answers[0].AnswerReadingValues) {
          mpQuestionCopy.Answers[0].AnswerReadingValues = [];
        }

        if (readingIndex !== null && readingIndex >= 0 && readingIndex < prevAnswerReadingValues.length) {
          // Update the specific index if provided
          prevAnswerReadingValues[readingIndex] = fieldValue;
        } else {
          // Add the new value if no specific index is provided or index is out of bounds
          prevAnswerReadingValues.push(fieldValue);

          // Ensure the array doesn't exceed 5 values
          if (prevAnswerReadingValues.length > 5) {
            prevAnswerReadingValues.shift(); // Remove the oldest value
          }
        }

        mpQuestionCopy.Answers[0].AnswerReadingValues = [...prevAnswerReadingValues];

        const foundQuestionIndex = stateQuestions.findIndex(q => q[fields.id] === mpQuestionCopy[fields.id]);
        if (foundQuestionIndex > -1) {
          // update the state so that the latest data is manipulated here
          let tempStateQuestions = [...stateQuestions];
          tempStateQuestions.splice(foundQuestionIndex, 1, mpQuestionCopy);
          setStateQuestions(tempStateQuestions);
        }
        return { value: prevAnswerReadingValues, mpQuestionCopy };
      default:
        return { value, question };
    }
  };

  const onFieldChange = (field, question, newValue, oldValue, readingIndex) => {
    // readingIndex is used here for distinction by index on which reading is changed
    if (typeof handleFieldChange === 'function') {
      const fieldValue = getFieldValue(question, newValue, readingIndex);
      const isCheckboxSameValue = field === questionTypes.CHECKBOX.fieldName && oldValue === newValue;
      const isNewValueEmptyString = (field === questionTypes.TEXT.fieldName || field === questionTypes.NUMBER.fieldName) && typeof newValue === 'string' && newValue === '';

      const values = {
        [moduleSectionQuestionFields.moduleId]: moduleId,
        [moduleSectionQuestionFields.questionId]: question[moduleSectionQuestionFields.id],
        [moduleSectionQuestionFields.type]: question[moduleSectionQuestionFields.type],
        [field]: isCheckboxSameValue || isNewValueEmptyString ? null : fieldValue?.value,
        question: fieldValue?.question,
      };

      handleFieldChange(values);
    }
  };

  const onFieldRemove = (field, question, removeIndex, fieldRemoveCallback) => {
    if (typeof onRemoveFieldHandler === 'function') {
      const fieldValue = onRemoveFieldHandler(field, question, removeIndex);

      const values = {
        [moduleSectionQuestionFields.moduleId]: moduleId,
        [moduleSectionQuestionFields.questionId]: question[moduleSectionQuestionFields.id],
        [moduleSectionQuestionFields.type]: question[moduleSectionQuestionFields.type],
        [field]: fieldValue?.value,
        question: fieldValue?.question,
      };

      fieldRemoveCallback && typeof fieldRemoveCallback === 'function' && fieldRemoveCallback();
      handleFieldChange(values);
    }
  };

  const onRemoveFieldHandler = (_field, question, removeIndex) => {
    const mpQuestionCopy = cloneDeep(question);
    const prevAnswerReadingValues = mpQuestionCopy?.Answers?.length > 0 && mpQuestionCopy.Answers[0].AnswerReadingValues ? [...mpQuestionCopy.Answers[0].AnswerReadingValues] : []; // Ensure this is initialized correctly

    switch (mpQuestionCopy[moduleSectionQuestionFields.type]) {
      case questionTypes.MEASUREMENT_POINT.valueId:
        if (prevAnswerReadingValues?.length === 0) return;
        if (Array.isArray(prevAnswerReadingValues) && removeIndex >= 0 && removeIndex < prevAnswerReadingValues.length) {
          prevAnswerReadingValues.splice(removeIndex, 1);
        }
        mpQuestionCopy.Answers[0].AnswerReadingValues = [...prevAnswerReadingValues];

        const foundQuestionIndex = stateQuestions.findIndex(q => q[fields.id] === mpQuestionCopy[fields.id]);
        if (foundQuestionIndex > -1) {
          // update the state so that the latest data is manipulated here
          let tempStateQuestions = [...stateQuestions];
          tempStateQuestions.splice(foundQuestionIndex, 1, mpQuestionCopy);
          setStateQuestions(tempStateQuestions);
        }

        if (prevAnswerReadingValues?.length === 0) return; // prevent sending a empty array as a value, since it results in an error from the BE side, with this check no value will be sent
        return { value: prevAnswerReadingValues?.length > 0 ? prevAnswerReadingValues : null, mpQuestionCopy };

      default:
        return { value: prevAnswerReadingValues, mpQuestionCopy };
    }
  };

  const getField = question => {
    const types = values(questionTypes);
    const type = find(types, { valueId: question[moduleSectionQuestionFields.type] });
    const name = type ? `${type.fieldName}-${question[moduleSectionQuestionFields.id]}` : '';
    const id = type ? `question-field-${question[moduleSectionQuestionFields.sectionId]}-${question[moduleSectionQuestionFields.id]}` : '';

    switch (question[moduleSectionQuestionFields.type]) {
      case questionTypes.TEXT.valueId:
        return (
          <Field
            id={id}
            name={name}
            component={questionTypes.TEXT_LONG.component}
            onChange={(_e, value) => onFieldChange(questionTypes.TEXT.fieldName, question, value)}
            size="lg"
            enableAutoResize={true}
            disabled={disabled}
            withHiddenError
            maxChars={questionTypes.TEXT_LONG.maxChars}
            max={questionTypes.TEXT_LONG.maxChars}
            maxLength={questionTypes.TEXT_LONG.maxChars}
            placeholder={t('TEXT')}
          />
        );
      case questionTypes.NUMBER.valueId:
        return (
          <Field
            id={id}
            name={name}
            component={questionTypes.NUMBER.component}
            type="number"
            onChange={(_e, value) => onFieldChange(questionTypes.NUMBER.fieldName, question, value)}
            size="lg"
            withHiddenError
            disabled={disabled}
            placeholder={t('NUMBER')}
          />
        );
      case questionTypes.CHECKBOX.valueId: {
        const prevCheckboxValue = question.Answers && question.Answers[0] && question.Answers[0].AnswerCheckbox;
        return (
          <div className="checkboxes-question">
            <Field
              id={`${id}-yes`}
              name={`${name}-yes`}
              component={questionTypes.CHECKBOX.component}
              checkboxText={t('MODULES_SECTIONS.QUESTION_CHECKBOX_YES')}
              value={true}
              onChange={() => onFieldChange(questionTypes.CHECKBOX.fieldName, question, true, prevCheckboxValue)}
              restprops={{ name: `${name}-yes` }}
              disabled={disabled}
            />
            <Field
              id={`${id}-no`}
              name={`${name}-no`}
              component={questionTypes.CHECKBOX.component}
              checkboxText={t('MODULES_SECTIONS.QUESTION_CHECKBOX_NO')}
              value={false}
              onChange={() => onFieldChange(questionTypes.CHECKBOX.fieldName, question, false, prevCheckboxValue)}
              restprops={{ name: `${name}-no` }}
              disabled={disabled}
            />
          </div>
        );
      }
      case questionTypes.MULTI_ANSWER.valueId:
        if (question[moduleSectionQuestionFields.questionOptions] && question[moduleSectionQuestionFields.questionOptions].length > 0) {
          return (
            <div className="multi-answers-container">
              {question[moduleSectionQuestionFields.questionOptions].map((option, index) => (
                <div key={`question-option-${question[moduleSectionQuestionFields.id]}-${option[moduleSectionQuestionFields.id]}`} className={'question-option'}>
                  <Field
                    id={`${id}-${option[moduleSectionQuestionFields.id]}`}
                    name={`${name}-${option[moduleSectionQuestionFields.id]}`}
                    component={questionTypes.CHECKBOX.component}
                    value={option[moduleSectionQuestionFields.id]}
                    onChange={() => onFieldChange(questionTypes.MULTI_ANSWER.fieldName, question, option[moduleSectionQuestionFields.id])}
                    checkboxText={option[moduleSectionQuestionFields.name]}
                    shortenText
                    restprops={{ name: `${name}-${option[moduleSectionQuestionFields.id]}` }}
                    disabled={disabled}
                  />
                </div>
              ))}
            </div>
          );
        }

        return null;
      case questionTypes.DATE.valueId:
        return (
          <Field
            id={id}
            name={name}
            component={questionTypes.DATE.component}
            placeholder={t('MODULES_SECTIONS.DATE_PLACEHOLDER')}
            disabledKeyboardNavigation
            dateFormat="MMMM d, yyyy h:mm aa"
            timeFormat="h:mm aa"
            showTimeSelect
            size="lg"
            popperPlacement="top"
            withHiddenError
            onChange={value => onFieldChange(questionTypes.DATE.fieldName, question, value)}
            disabled={disabled}
          />
        );
      case questionTypes.NO_ANSWER.valueId:
        return null;
      case questionTypes.MEASUREMENT_POINT.valueId:
        return (
          <div className="measurement-point-question-container">
            <FieldArray
              id={id}
              name={name}
              component={ReadingFormFields}
              unit={question[formConstants.fields.measurementPointUnit] || ''}
              isEdit={false}
              showLabel={false}
              placeholder={'NUMBER'}
              showInputAddon
              disabled={disabled}
              onChange={(readingIndex, value) => onFieldChange(questionTypes.MEASUREMENT_POINT.fieldName, question, value, null, readingIndex)}
              removeReadingHandler={(index, fieldRemoveCallback) => onFieldRemove(questionTypes.MEASUREMENT_POINT.fieldName, question, index, fieldRemoveCallback)}
            />
            <div className="measurement-point-question-container__expected-range-wrapper">
              <label className="measurement-point-question-container__expected-range-wrapper__label">{t('MEASUREMENT_POINT_DETAILS.FORM_EXPECTED_RANGE')}</label>
              <label className="measurement-point-question-container__expected-range-wrapper__label-value">{question[formConstants.fields.expectedRange] || ''}</label>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const getQuestionTitle = (question, index) => {
    const { type, questionOptions, name } = moduleSectionQuestionFields;

    // TODO: this can be moved to reducer and reused in section-title
    const isQuestionAnswered = questionAnswered(question, formValues);
    const isQuestionMultipleAnswersAnswered = question[type] === questionTypes.MULTI_ANSWER.valueId && question[questionOptions]?.some(qo => questionAnswered(qo, formValues));
    const isMandatoryQuestionAnswered = mandatoryQuestionAnswered(question, formErrors);

    if (question[type] === questionTypes.NO_ANSWER.valueId) {
      return (
        <div className="no-answer-label">
          <span className="section-question-state">{index + 1}.</span>
          <p className="section-question-state__question-label__no-answer">{question[name]}</p>
        </div>
      );
    }

    const questionStateClass = !isMandatoryQuestionAnswered
      ? 'section-question-state section-question-state__error'
      : isQuestionAnswered || isQuestionMultipleAnswersAnswered
      ? 'section-question-state section-question-state__completed'
      : 'section-question-state';

    return (
      <>
        <span className={questionStateClass}>{index + 1}.</span>
        <span className="section-question-state__question-label">{`${question[name]}`}</span>
      </>
    );
  };

  const toggleQuestionDetails = question => {
    const isMeasurementPointQuestion = question[moduleSectionQuestionFields.type] === questionTypes.MEASUREMENT_POINT.valueId;
    if (isMeasurementPointQuestion && !question.isExpanded && !showEmptyVersion) {
      getWorkOrderChecklistSectionQuestionDetails(question[moduleSectionQuestionFields.sectionId], question[fields.id], successData => {
        const newQuestions = ReducerHelpers.updateItemInListByProp(stateQuestions, { ...question, ...successData, isExpanded: !question.isExpanded }, moduleSectionQuestionFields.id);
        setStateQuestions(newQuestions);
      });
    } else {
      const newQuestions = ReducerHelpers.updateItemInListByProp(stateQuestions, { ...question, isExpanded: !question.isExpanded }, moduleSectionQuestionFields.id);
      setStateQuestions(newQuestions);
    }
  };

  const renderPaginationNavigator = () => {
    if (questionsFilters?.[filterProps.totalNumber] <= defaultPagingObj[filterProps.perPage]) {
      return null;
    }
    const pageCurrentStart = questionsFilters?.[filterProps.lastSeen] ? Math.max(questionsFilters?.[filterProps.lastSeen] - defaultPagingObj[filterProps.perPage], 0) : 0; // clamp to 0 if negative value
    return (
      <PaginationNavigator
        isLoading={questionsLoading}
        className="section-content-item__pagination-navigator"
        currentStart={pageCurrentStart}
        currentEnd={pageCurrentStart + (questions || []).length}
        totalItems={questionsFilters?.[filterProps.totalNumber]}
        label="CHECKLISTS_PROCEDURES_MANAGEMENT.QUESTIONS.PAGINATION_INFO"
        prevButtonProps={{
          disabled: questionsFilters?.[filterProps.lastSeen] - defaultPagingObj[filterProps.perPage] <= 0,
          onClick: loadPreviousQuestions,
          text: 'CHECKLISTS_PROCEDURES_MANAGEMENT.QUESTIONS.PREV_BUTTON_TEXT',
        }}
        nextButtonProps={{
          disabled: !questionsFilters?.[filterProps.hasNext],
          onClick: loadNextQuestions,
          text: 'CHECKLISTS_PROCEDURES_MANAGEMENT.QUESTIONS.NEXT_BUTTON_TEXT',
        }}
      />
    );
  };

  const renderFilesSection = files => {
    return (
      <div className="category-wrapper">
        <h3 className="title">{t('WORKORDER_SECTION_TITLE.FILES')}</h3>
        {files?.length > 0 && (
          <div className="category-files-wrapper">
            {files.map(file => {
              file.isPDF = Helpers.isPDF(file[fields.fileName], file[fields.fileType]);
              file.isImage = Helpers.isImage(file[fields.fileName], file[fields.fileType]);

              return (
                <div className="row" key={file[fields.fileName]} onClick={() => handleFileOpen(file)}>
                  <Icon name={getIconForFileType(file[fields.fileName])} size="sm" active />
                  <p title={Helpers.decodeIfStringEncoded(file[fields.fileName])} className="title">
                    {Helpers.decodeIfStringEncoded(file[fields.fileName])}
                  </p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const getCoordinatesText = geometry => {
    const coordinates = geometry?.coordinates;
    if (!isEmpty(coordinates) && !componentHasAllCoordinatesZero(coordinates)) {
      const coordinatesLongText = (coordinates[0] || []).join(' / '),
        coordinatesShortText = (coordinates[0] || []).map(cord => Number(cord).toFixed(2)).join(' / ');
      return { shortText: coordinatesShortText, longText: coordinatesLongText };
    } else {
      return null;
    }
  };

  // Memoize the mapped alarms to avoid recalculating on every render
  const getMappedAlarms = useCallback(question => {
    return alarmDefaults.map(
      defaultAlarm => question?.[formConstants.fields.questionMeasurementPointAlarm]?.find(alarm => alarm?.[formConstants.fields.name] === defaultAlarm?.[formConstants.fields.name]) || defaultAlarm
    );
  }, []);

  // Memoize the renderAlarm function
  const renderAlarm = useCallback(
    (alarm, question) => {
      const alarmLevel = alarm?.Level ? alarm?.Level : alarm?.AlarmLevel ? alarm?.AlarmLevel : '';
      return (
        <div key={alarm?.[formConstants.fields.name]} className="alarm">
          <label>
            {t('ALARM')} ({t(`ALARMS.${alarm?.[formConstants.fields.name].toUpperCase().replace('-', '_')}.NAME`)})
          </label>
          <div className="alarm__name">
            {alarm?.Colour && (
              <div className={`alarm-icon ${alarmColorPalette[alarm?.[formConstants.fields.colour]]?.name}`}>
                <Icon size="sm" name="alarm-bell-icon" disabled />
              </div>
            )}
            <div className="text f-primary">{alarmLevel ? `${alarmLevel} ${question[formConstants.fields.measurementPointUnit]}` : '-'}</div>
          </div>
        </div>
      );
    },
    [t]
  );

  const open3DModal = (question, geometry, cameraPosition, name, systemType, colour) => {
    const element = {
      ...question,
      [formConstants.fields.name]: name,
      [formConstants.fields.visible]: true,
      [formConstants.fields.cameraPosition]: { [formConstants.fields.coordinates]: cameraPosition },
      [formConstants.fields.geometry]: { [formConstants.fields.coordinates]: geometry, type: 'Point' },
      [formConstants.fields.systemType]: systemType,
      [formConstants.fields.color]: colour,
    };
    setModalData({
      isOpen: true,
      type: '',
      closeAction: () => setModalData({ isOpen: false }),
      title: t('SECTIONS.TAG_LOCATION_LABEL'),
      customClassName: 'location-modal modal-large',
      customClassWrapperName: 'location-modal__picker',
      CustomContent: () => (
        <Show3dLocationModal potreeId="location-of-measurement-potree" geometryCoordinates={geometry} cameraPositionCoordinates={cameraPosition} inspectionId={inspectionId} elements={[element]} />
      ),
    });
  };

  const renderLocationInfo = (question, labelKey, paragraph, geometry, cameraPosition, name, systemType, colour) => {
    return (
      <div className="show-location">
        <label>{t(labelKey)}</label>
        {!isEmpty(geometry) ? (
          <div className="location-text" onClick={() => open3DModal(question, geometry, cameraPosition, name, systemType, colour)}>
            {t('SHOW_TAGGED_3D_LOCATION')} <Icon name="external-link" size="xs" />
          </div>
        ) : (
          <InfoTooltip
            className="no-location-tooltip"
            offsetY={6}
            offsetX={6}
            customComponent={
              <div className="container">
                <span className="f-primary">{t('NO_LOCATION')}</span>
                <p className="f-primary">{t(paragraph)}</p>
                <br />
                <p className="f-primary">{t('EDIT_PERMISSIONS_REQUIRED')}</p>
              </div>
            }
            Component={() => (
              <div className="f-primary">
                {t('NO_LOCATION')} <Icon name="info" size="xs" className="info-icon" />
              </div>
            )}
            containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
          />
        )}
      </div>
    );
  };

  const renderReadingEquipmentInfo = question => {
    if (!question) return;
    const linkedMPEquipment = question[formConstants.fields.measurementPointComponents]?.length > 0 && question[formConstants.fields.measurementPointComponents][0];
    return (
      <div className="reading-equipment-wrapper">
        <div className="reading">
          <label>{t('MEASUREMENT_POINT_DETAILS.MEASUREMENT_READINGS.LAST_READING')}</label>
          <p>{question[formConstants.fields.lastReadingValue] ? `${question[formConstants.fields.lastReadingValue]} ${question[formConstants.fields.measurementPointUnit]}` : '-'}</p>
        </div>
        <div className="reading">
          <label>{t('MEASUREMENT_POINT_DETAILS.MEASUREMENT_READINGS.LAST_READING_DATE')}</label>
          <p>{question[formConstants.fields.lastReadingDate] ? Helpers.getDateFromUnix(question[formConstants.fields.lastReadingDate]) : '-'}</p>
        </div>
        <div className="reading equipment">
          <label>{t('SECTIONS.QUESTION_COMPONENT_LABEL')}</label>
          {linkedMPEquipment ? (
            <InfoTooltip
              className="equipment-info-tooltip"
              offsetY={6}
              offsetX={6}
              customComponent={
                <div className="equipment-info-tooltip__wrapper">
                  <div>
                    <label>{t('ID')}</label>
                    <p className="f-primary">{linkedMPEquipment[formConstants.fields.id] || '-'}</p>
                  </div>
                  <div>
                    <label>{t('CODE')}</label>
                    <p className="f-primary">{linkedMPEquipment[formConstants.fields.code] || '-'}</p>
                  </div>
                  <div>
                    <label>{t('NAME')}</label>
                    <p className="f-primary">{linkedMPEquipment[formConstants.fields.name] || '-'}</p>
                  </div>
                </div>
              }
              Component={() => (
                <div className="name_container">
                  <p className="f-primary name">{Helpers.truncateText(linkedMPEquipment[formConstants.fields.name], 35) || '-'}</p>
                  <Icon name="info" handleHover={false} size="sm" className="info-icon" />
                </div>
              )}
              componentProps={{ title: '' }}
              containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
            />
          ) : (
            '-'
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <form className="custom-section-form" onSubmit={e => e.preventDefault()}>
        {renderPaginationNavigator()}
        {stateQuestions?.length > 0 &&
          stateQuestions.map((question, index) => {
            const coordinatesText = getCoordinatesText(question[additionalFields.geometry.name]);
            const questionTitle = getQuestionTitle(question, index);
            const isQuestionMandatory = question[moduleSectionQuestionFields.isMandatory];
            const isMeasurementPointQuestion = question[moduleSectionQuestionFields.type] === questionTypes.MEASUREMENT_POINT.valueId;

            return (
              <div className="question-field">
                <div className={`question-field-container ${isMeasurementPointQuestion ? 'align-top' : ''}`} key={`section-question-field-${question[moduleSectionQuestionFields.id]}`}>
                  <div className="question-field-name-container">
                    <h6 className="f-primary light-bold question-label">
                      <div className="flex-center">
                        {questionTitle}
                        {isQuestionMandatory ? <span className="f-secondary-red">{isQuestionMandatory ? '*' : ''}</span> : null}
                      </div>
                      {isMeasurementPointQuestion && (
                        // displays the MP group and MP location breadcrumb
                        <p className="f-secondary-dark name">{`${t(question?.[formConstants.fields.readingsAndGaugesGroupName])}/${question?.[formConstants.fields.measurementLocationName]}`}</p>
                      )}
                    </h6>
                    <div className="question-field-details-container">
                      <ExpandableSection
                        CustomComponent={({ isExpanded }) => <p className="f-secondary-dark light-bold">{isExpanded ? 'Less Details' : 'More Details'}</p>}
                        key={`filter-${question[moduleSectionQuestionFields.id]}`}
                        handlePreventClick={() => toggleQuestionDetails(question)}
                        customExpanded={question.isExpanded}
                      ></ExpandableSection>
                    </div>
                  </div>
                  <div className="question-input-field">{getField(question)}</div>
                </div>
                <RenderIf if={question.isExpanded}>
                  {/*  hide these rows for MP question type, since they are obsolote for MPs */}
                  {!isMeasurementPointQuestion ? (
                    <div className="question-field-details-wrapper">
                      <div className="inline-row">
                        <div className="item">
                          <p className="f-secondary-dark item__label">{t(additionalFields.description.label)}</p>
                          <p className="f-primary item__value">{question[additionalFields.description.name] ? question[additionalFields.description.name] : '-'}</p>
                        </div>
                        <div className="item">
                          <p className="f-secondary-dark item__label">{t(additionalFields.estimatedTime.label)}</p>
                          <p className="f-primary item__value">
                            {question[additionalFields.estimatedTime.name] && typeof question[additionalFields.estimatedTime.name] === 'number'
                              ? millisecondsToTime(question[additionalFields.estimatedTime.name] * 1000)
                              : '-'}
                          </p>
                        </div>
                      </div>
                      <div className="inline-row">
                        <div>
                          <p className="f-secondary-dark light-bold">{t(additionalFields.components.label)}</p>
                          {!isEmpty(question[additionalFields.components.name]) ? (
                            <WorkOrderSectionComponents
                              module={{ workOrderComponents: Helpers.formatComponents(question[additionalFields.components.name]) }}
                              disabled={true}
                              listWithDeeplink={false}
                            />
                          ) : (
                            '-'
                          )}
                        </div>
                      </div>
                      <div className="inline-row">
                        <div>
                          <p className="f-secondary-dark light-bold">{t(additionalFields.geometry.label)}</p>
                          {!isEmpty(coordinatesText) ? (
                            <p className="f-secondary-green link" onClick={() => handle3DTagLocationClick(question)} title={coordinatesText.longText}>
                              {coordinatesText.shortText}
                            </p>
                          ) : (
                            '-'
                          )}
                        </div>
                      </div>
                      <div className="inline-row">{renderFilesSection(question[filesPropName])}</div>
                    </div>
                  ) : (
                    //show these rows only for MP question type
                    <div className="measurement-point-question-section__expanded">
                      <div className="measurement-point-question-section__expanded__readings"> {renderReadingEquipmentInfo(question)}</div>
                      <div className="measurement-point-question-section__expanded__alarms"> {getMappedAlarms(question).map(alarm => renderAlarm(alarm, question))}</div>
                      <div className="measurement-point-question-section__expanded__location">
                        {renderLocationInfo(
                          question,
                          'M_LOCATION_3D_LOCATION',
                          'NO_LOCATION.PARAGRAPH.ML',
                          question?.[formConstants.fields.measurementLocationGeometry]?.[formConstants.fields.coordinates],
                          question?.[formConstants.fields.measurementLocationCameraPosition]?.[formConstants.fields.coordinates],
                          question?.[formConstants.fields.measurementLocationName],
                          measurementTypes.rgMeasurementLocation,
                          question?.[formConstants.fields.measurementLocationColour]
                        )}
                        {renderLocationInfo(
                          question,
                          'M_POINT_3D_LOCATION',
                          'NO_LOCATION.PARAGRAPH.MP',
                          question?.[formConstants.fields.measurementPointGeometry]?.[formConstants.fields.coordinates],
                          question?.[formConstants.fields.measurementPointCameraPosition]?.[formConstants.fields.coordinates],
                          question?.[formConstants.fields.name],
                          measurementTypes.rgMeasurementPoint,
                          question?.[formConstants.fields.measurementPointColour]
                        )}
                      </div>
                    </div>
                  )}
                </RenderIf>
                {!showEmptyVersion && (
                  <Field
                    id={`question-field-${question[moduleSectionQuestionFields.sectionId]}-${question[moduleSectionQuestionFields.id]}`}
                    name={`${moduleSectionQuestionFields.evidenceFiles}-${question[moduleSectionQuestionFields.id]}`}
                    component={WorkOrderSectionEvidenceFiles}
                    placeholder={t('MODULES_SECTIONS.DATE_PLACEHOLDER')}
                    updateFiles={value => change(`${moduleSectionQuestionFields.evidenceFiles}-${question[moduleSectionQuestionFields.id]}`, value)}
                    module={question}
                    disabled={disabled}
                    handleFileOpen={handleFileOpen}
                    isMandatory={question[moduleSectionQuestionFields.isMandatoryFileUpload]}
                    touchField={() => touchField(`${moduleSectionQuestionFields.evidenceFiles}-${question[moduleSectionQuestionFields.id]}`)}
                    defaultWorkorderDMSCategoryID={defaultWorkorderDMSCategoryID}
                    setModalData={setEvidenceUploadModal}
                  />
                )}
              </div>
            );
          })}
        {renderPaginationNavigator()}
      </form>
      <Modal {...modalData} />
      <Modal {...evidenceUploadModal} checklistQuestionEvidenceFiles={evidenceFiles} />
    </>
  );
};

WorkOrderSectionChecklistQuestionsForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

WorkOrderSectionChecklistQuestionsForm.propTypes = {
  moduleId: PropTypes.number.isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number,
      Name: PropTypes.string.isRequired,
      QuestionType: PropTypes.number,
      QuestionOrder: PropTypes.number,
      IsMandatory: PropTypes.bool,
      AllowMultipleAnswers: PropTypes.bool,
      EstimatedTime: PropTypes.number,
      CreatedAt: PropTypes.number,
      SectionID: PropTypes.number,
    })
  ),
  handleFieldChange: PropTypes.func,
  disabled: PropTypes.bool,
  change: PropTypes.func,
  formErrors: PropTypes.object,
  formValues: PropTypes.object,
  handle3DTagLocationClick: PropTypes.func,
  categoryPropName: PropTypes.string,
  filesPropName: PropTypes.string,
  inspectionId: PropTypes.number,
};

const mapStateToProps = (state, props) => ({
  formErrors: getFormSyncErrors(props.form)(state),
  formValues: getFormValues(props.form)(state),
});

export default connect(
  mapStateToProps,
  null
)(
  reduxForm({
    enableReinitialize: true,
    touchOnChange: true,
    destroyOnUnmount: true,
    validate,
  })(WorkOrderSectionChecklistQuestionsForm)
);
