import PropTypes from 'prop-types';
import React from 'react';

import RenderIf from '../../../../common/render-if/components/render-if';
import SectionEmptyState from '../../../inspections/components/right-toolbar/common/section-empty-state';
import RenderFiles from '../../../inspections/components/work-order/render-files';
import { fileGroups as RegimeFileGroups } from '../../constants/regime-constants';

import '../../../inspections/styles/work-order-files.scss';
import '../../../inspections/styles/work-order-section-files.scss';

const MaintenanceRegimeSectionFiles = ({ disabled, module, fileGroup }, { t }) => {
  const fileGroups = React.useMemo(() => {
    const otherFiles = (module?.maintenanceRegimeFiles && module.maintenanceRegimeFiles[RegimeFileGroups.otherFiles]) || [];

    return {
      otherFiles,
    };
  }, [module?.maintenanceRegimeFiles]);

  return (
    <div className="work-order-section-files work-order-files">
      <RenderIf if={fileGroup === RegimeFileGroups.otherFiles}>
        <div className={`work-order-other-files ${(!fileGroups || fileGroups.otherFiles.length === 0) && disabled ? 'work-order-other-files-without-margin' : ''}`}>
          <p className="f-primary light-bold files-title">{t('WORK_ORDER.FILES_SECTION_DRAWINGS_TITLE')}</p>
          <RenderIf if={!fileGroups || fileGroups.otherFiles.length === 0}>
            <SectionEmptyState title="WORK_ORDER.NO_FILES_EMPTY_STATE" />
          </RenderIf>
          <RenderIf if={fileGroups && fileGroups.otherFiles && fileGroups.otherFiles.length > 0}>
            <RenderFiles files={fileGroups.otherFiles} hideDelete hideUpload hideFileNameTitle />
          </RenderIf>
        </div>
      </RenderIf>
    </div>
  );
};

MaintenanceRegimeSectionFiles.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default MaintenanceRegimeSectionFiles;
