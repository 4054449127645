export default {
  GET_CHECKLISTS: '/inspection/maintenance/regime/create/checklist/template/list',
  GET_CHECKLIST_TYPES: '/inspection/checklist/template/item/type/list',
  GET_CHECKLIST_DETAILS: '/inspection/checklist/template/details',
  CREATE_CHECKLIST: '/inspection/checklist/template/create',
  DELETE_CHECKLIST: '/inspection/checklist/template/delete',
  UPDATE_CHECKLIST: '/inspection/checklist/template/update',
  ADD_CHECKLIST_ITEM: '/inspection/checklist/template/item/create',
  DELETE_CHECKLIST_ITEM: '/inspection/checklist/template/item/delete',
  UPDATE_CHECKLIST_ITEM: '/inspection/checklist/template/item/update',
  GET_REGIMES: '/inspection/maintenance/regime/list',
  GET_REGIME_DETAILS: '/inspection/maintenance/regime/details',
  CREATE_REGIME: '/inspection/maintenance/regime/create',
  UPDATE_REGIME: '/inspection/maintenance/regime/update',
  GET_REGIME_COMPONENTS: '/inspection/maintenance/regime/components/list',
  GET_IMPORTANCE_LIST: '/inspection/maintenance/regime/importance/list',
  GET_REGIME_FILES: '/inspection/maintenance/regime/files/list',
  UNLINK_REGIME_FILE: '/inspection/maintenance/regime/file/unlink',
  ADD_COMPONENT_TO_REGIME: '/inspection/maintenance/regime/component/add',
  REMOVE_COMPONENT_FROM_REGIME: '/inspection/maintenance/regime/component/delete',
  GET_REGIME_CHECKLISTS: '/inspection/maintenance/regime/checklist/template/list',
  ADD_CHECKLIST_TO_THE_REGIME: '/inspection/maintenance/regime/checklist/add',
  DELETE_CHECKLIST_FROM_REGIME: '/inspection/maintenance/regime/checklist/delete',
  DUPLICATE_REGIME: '/inspection/maintenance/regime/duplicate',
  GET_INTERVAL_LIST: '/inspection/maintenance/regime/intervals/list',
  UPDATE_INTERVAL: '/inspection/maintenance/regime/interval/update',
  CONFIRM_REGIME: '/inspection/maintenance/regime/confirm',
  GET_TASKS: '/inspection/maintenance/task/list',
  GET_TASK_DETAILS: '/inspection/maintenance/task/details',
  UPDATE_TASK: '/inspection/maintenance/task/status/update',
  UPDATE_TASK_ANSWER: '/inspection/maintenance/task/checklist/answer/update',
  GET_TASK_FILES: '/inspection/mainenance/task/files/list',
  DELETE_REGIME: '/inspection/maintenance/regime/delete',
  CHANGE_REGIME_STATUS: '/inspection/maintenance/regime/status/update',
  GET_REGIME_TASKS: '/inspection/maintenance/regime/tasks/list',
  SAVE_REGIME_CHECKLISTS: '/inspection/maintenance/regime/checklist/template/save',
  GENERATE_REGIME_CHECKLIST_TEMPLATE_PDF: '/inspection/maintenance/regime/checklist/pdf',
  GET_REGIME_CHECKLIST_TEMPLATE_SECTIONS: '/inspection/maintenance/regime/checklist/template/sections/list/v2',
  GET_REGIME_CHECKLIST_TEMPLATE_SECTION_QUESTIONS: '/inspection/maintenance/regime/checklist/template/section/question/list',
};
