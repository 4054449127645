import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SearchIcon } from '../assets/search-icon.svg';

import '../styles/search-input-small.scss';

class SearchInputSmall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownVisible: false,
    };
    this.node = React.createRef();
    this.inputRef = React.createRef();
  }

  handleClickOutside = e => {
    if (this.node && this.node.current && this.node.current.contains(e.target)) {
      // element is clicked no need to call click outside handler function
      return;
    }
    this.setState({ dropdownVisible: false });
  };

  componentDidMount = () => {
    const { includeDropdown } = this.props;
    if (includeDropdown) {
      document.addEventListener('click', this.handleClickOutside);
    }
  };

  componentWillUnmount = () => {
    const { includeDropdown } = this.props;
    if (includeDropdown) {
      document.removeEventListener('click', this.handleClickOutside);
    }
  };

  render() {
    const { t } = this.context;
    const { dropdownVisible } = this.state;
    const { includeDropdown, items, renderItem, emptyStateLabel, charLimitation, onFocus, ...restProps } = this.props;

    const isCharLimited = !isNaN(charLimitation) ? this.inputRef?.current?.value?.length < charLimitation : false;

    return (
      <div className="search-small" ref={this.node}>
        <SearchIcon className="search-small__icon stroke-primary-g" />
        <input
          {...restProps}
          onFocus={() => {
            this.setState({ dropdownVisible: true });
          }}
          type="text"
          className="search-small__input"
          ref={this.inputRef}
          data-cy="search-input"
        />
        {includeDropdown && dropdownVisible && !isCharLimited && (
          <div className="items-dropdown">
            {items.map((item, index) => {
              return (
                <div key={index} className="item">
                  {renderItem ? renderItem(item, index) : null}{' '}
                </div>
              );
            })}
            {items.length === 0 && (
              <div className="empty-state">
                <p className="f-primary">{t(emptyStateLabel)}</p>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

SearchInputSmall.contextTypes = {
  t: PropTypes.func.isRequired,
};

SearchInputSmall.defaultProps = {
  includeDropdown: false,
  items: [],
  renderItem: null,
  emptyStateLabel: 'SEARCH_INPUT.EMPTY_STATE',
  charLimitation: null,
};

export default SearchInputSmall;
