import { formFields } from '../constants/constants';

export const validateLevel = values => {
  const errors = {};
  if (!values[formFields.code.name]) {
    errors[formFields.code.name] = 'COMPONENT_HIERARCHY_FORM.REQUIRED';
  }
  if (!values[formFields.name.name]) {
    errors[formFields.name.name] = 'COMPONENT_HIERARCHY_FORM.REQUIRED';
  }
  if (values[formFields.code.name]?.length > 20) {
    errors[formFields.code.name] = 'COMPONENT_HIERARCHY_FORM.CODE_LARGE';
  }
  if (values[formFields.name.name]?.length < 3) {
    errors[formFields.name.name] = 'COMPONENT_HIERARCHY_FORM.NAME_SHORT';
  }
  if (values[formFields.name.name]?.length > 80) {
    errors[formFields.name.name] = 'COMPONENT_HIERARCHY_FORM.NAME_LARGE';
  }
  return errors;
};
