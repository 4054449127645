import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change, updateSyncErrors } from 'redux-form';
import { find, isEmpty, map, some } from 'lodash';

import Modal from '../../../common/modal/components/modal';
import LinkedImagesSliderSlider from '../../start-workflow/components/linked-images-slider';
import CreateContractorForm from './create-contractor-form';
import UploadAssetsModal from '../../project/components/upload-assets-modal';
import Stepper from '../../../common/stepper/components/stepper';

import { setSingleUploadItem, setUploadItems, uploadAsset } from '../../upload/actions/upload-actions';
import { notificationModalCustom } from '../../../common/modal/actions/modal-actions';

import { steps, fileFields, formConstants, imagesValidation } from '../constants/constants';
import orientationConstants from '../../start-workflow/constants/orientation-constants';

import ReducerHelpers from '../../../common/reducer-helpers';
import Helpers from '../../../common/helpers';

import { uploadType } from '../../upload/constants/constants';

import '../styles/create-contractor-modal.scss';
import { FORMS } from '../../../common/constants';

class CreateContractorModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditMode: props.isEditMode,
      activeStep: steps.firstStep,
      lastStep: steps.secondStep,
      isDateClearable: props.isDateClearable,
      stepperData: [
        {
          name: 'CONTRACTOR_MODAL.STEP_1',
          stepValue: steps.firstStep,
          description: 'CONTRACTOR_MODAL.STEP_1_DESC',
          disabled: false,
        },
        {
          name: 'CONTRACTOR_MODAL.STEP_2',
          stepValue: steps.secondStep,
          description: 'CONTRACTOR_MODAL.STEP_2_DESC',
          disabled: false,
        },
      ],
      files: [],
      users: [],
      verificationModalData: {
        isOpen: false,
      },
      uploadModalData: {
        isOpen: false,
      },
      restoreContractorModalData: {
        isOpen: false,
      },
    };
  }

  componentDidMount() {
    const { isEditMode } = this.props;

    if (!isEditMode) {
      this.handleSearchUsers();
    }
  }

  handleSearchUsers = (SearchText = '') => {
    const { getContractorUserList, projectId } = this.props;
    getContractorUserList({ SearchText, ProjectID: projectId }, users => {
      this.setState({ users });
    });
  };

  handleSubmit = values => {
    let { activeStep } = this.state;
    const { handleCreateContractor, handleUpdateContractor, getContractorFiles, changeField, viewOnly, handleRestoreContractor, updateSyncErrors } = this.props;
    values = {
      ...values,
      [formConstants.fields.permitTestDate]: values[formConstants.fields.permitTestDate] ? Helpers.getUnixDate(new Date(values[formConstants.fields.permitTestDate]).getTime()) : null,
      [formConstants.fields.permitTestExpirationDate]: values[formConstants.fields.permitTestExpirationDate]
        ? Helpers.getUnixDate(new Date(values[formConstants.fields.permitTestExpirationDate]).getTime())
        : null,
    };
    if (activeStep === steps.firstStep && !viewOnly) {
      if (values && values[formConstants.fields.id]) {
        handleUpdateContractor(
          values,
          () => this.setStep(true),
          e => {
            const errCode = Helpers.getErrorCode(e);
            // errCode === 1122 means that email is already taken we show message only
            if (errCode === 1122) {
              const { t } = this.context;

              // display email already taken mesage
              updateSyncErrors(FORMS.createContractorForm, { [formConstants.fields.email]: t(`ERROR_MODAL.${errCode}`) });
            }
          }
        );
        getContractorFiles(values[formConstants.fields.id], files => this.setState({ files }));
      } else {
        handleCreateContractor(
          values,
          data => {
            changeField(formConstants.fields.id, data[formConstants.fields.id]);
            this.setStep(true);
            this.setState({ isEditMode: true });
          },
          e => {
            const errCode = Helpers.getErrorCode(e);
            // errCode === 1122 means that email is already taken we show message only, errCode === 3213 we show restore contractor modal
            if (errCode === 3213) {
              // display restore contractor popup
              const { t } = this.context;
              this.setState({
                restoreContractorModalData: {
                  isOpen: true,
                  title: t('CONTRACTOR_MANAGEMENT.RESTORE_CONTRACTOR_POPUP_TITLE'),
                  content: t('CONTRACTOR_MANAGEMENT.RESTORE_CONTRACTOR_POPUP_DESCRIPTION'),
                  type: 'yes-no',
                  confirmAction: () => {
                    handleRestoreContractor(values, data => {
                      this.setState({ restoreContractorModalData: { isOpen: false } });
                      this.setStep(true);
                      // updates the ID field of contractor so it can be used on update step
                      changeField(formConstants.fields.id, data[formConstants.fields.contractorId]);
                    });
                  },
                  closeAction: () => {
                    // if user clicks cancel in restore contractor popup then display error email taken in form
                    this.setState({
                      restoreContractorModalData: {
                        isOpen: false,
                      },
                    });
                    updateSyncErrors(FORMS.createContractorForm, { [formConstants.fields.email]: t(`ERROR_MODAL.${errCode}`) });
                  },
                },
              });
            } else if (errCode === 1122) {
              const { t } = this.context;

              // display email already taken mesage
              updateSyncErrors(FORMS.createContractorForm, { [formConstants.fields.email]: t(`ERROR_MODAL.${errCode}`) });
            }
          }
        );
      }
    } else if (activeStep === steps.secondStep && !viewOnly) {
      handleUpdateContractor(values, () => this.setStep(true));
    } else {
      getContractorFiles(values[formConstants.fields.id], files => this.setState({ files }));
      this.setStep(true);
    }
  };

  setStep = forward => {
    const { closeAction } = this.props;
    let { activeStep, lastStep } = this.state;
    const nextStep = forward ? ++activeStep : --activeStep;
    if (nextStep < steps.firstStep || nextStep > lastStep) {
      closeAction();
      return;
    }

    this.setState({ activeStep: nextStep });
  };

  isValid = (items, files) => {
    const { notificationModalCustom } = this.props;

    const allFiles = items.concat(files);

    if (allFiles && allFiles.length > imagesValidation.maxFiles) {
      notificationModalCustom(
        {
          key: 'WORK_ORDER_CREATE_VALIDATION.VALIDATION_MAX_FILES',
          props: { maxFiles: imagesValidation.maxFiles, current: files && files.length ? files.length : 0 },
        },
        'WORK_ORDER_CREATE_VALIDATION.VALIDATION_FILE_SIZE_TITLE'
      );

      return false;
    }

    const hasLargeFiles = some(items, item => item.size > imagesValidation.bytes);

    if (hasLargeFiles) {
      //open modal with message
      notificationModalCustom(
        {
          key: 'WORK_ORDER_CREATE_VALIDATION.VALIDATION_FILE_SIZE',
          props: { fileSize: `${imagesValidation.value}${imagesValidation.unit}` },
        },
        'WORK_ORDER_CREATE_VALIDATION.VALIDATION_FILE_SIZE_TITLE'
      );
      return false;
    }

    return true;
  };

  onDropAsset = files => {
    const { uploadAsset, setUploadItems, setSingleUploadItem, projectId, contractor } = this.props;

    if (!this.isValid(files, Object.assign([], this.state.files))) {
      return false;
    }

    const tmp_files = files.map((file, index) => {
      const tmp_file = {
        type: Helpers.getFileType(file.type),
        mimeType: file.type,
        title: '',
        description: '',
        real_name: Helpers.formatFileName(file.name),
      };
      file.timeStamp = Date.now();
      file.real_name = tmp_file.real_name;
      file.uploadType = uploadType.contractorFiles;

      uploadAsset(file, { ProjectID: projectId, ContractorID: contractor[formConstants.fields.id] }, setSingleUploadItem, index);
      return { ...tmp_file, ...file };
    });
    setUploadItems(tmp_files);
  };

  openFilesModalData = (onModalClose = () => null) => {
    const { t } = this.context;
    const { setUploadItems } = this.props;

    const confirmAction = () => {
      setUploadItems([]);
      onModalClose();
      this.setState({
        uploadModalData: {
          isOpen: false,
        },
      });
    };
    this.setState(prevState => ({
      uploadModalData: {
        ...prevState.uploadModalData,
        title: t('CONTRACTOR_MODAL.FILES_UPLOAD.TITLE'),
        customClassName: 'project-assets-modal modal-small',
        CustomContent: () => (
          <UploadAssetsModal
            onDrop={files => this.onDropAsset(files)}
            className="project-dropzone"
            labelWhite={t('CONTRACTOR_MODAL.FILES_UPLOAD.TITLE')}
            labelGreen={t('CONTRACTOR_MODAL.FILES_UPLOAD.BROWSE')}
          />
        ),
        isOpen: true,
        type: 'ok',
        confirmAction: confirmAction,
        closeAction: confirmAction,
      },
    }));
  };

  unlinkContractorFile = item => {
    const { files } = this.state;
    const { deleteContractorFile, contractor } = this.props;
    const { t } = this.context;

    const closeAction = () => this.setState({ verificationModalData: { isOpen: false } });

    const modalData = {
      isOpen: true,
      content: t(`CONTRACTOR_MODAL.DELETE_FILE`),
      type: 'yes-no',
      confirmAction: () => {
        deleteContractorFile({ ContractorID: contractor[formConstants.fields.id], FileID: item[fileFields.id] }, () => {
          if (files && !isEmpty(files)) {
            this.setState({ files: ReducerHelpers.removeItemByProp(files, item, fileFields.id) });
          }
          closeAction();
        });
      },
      closeAction,
    };

    this.setState({ verificationModalData: modalData });
  };

  openImagesSliderModal = (selectedImage, images, index) => {
    images = map(images, item => {
      item.src = Helpers.getUploadImageSource(item.FileName, item.URL);
      return item;
    });

    const closeAction = () => this.setState({ imagesSliderModal: { isOpen: false } });

    this.setState(prevState => ({
      imagesSliderModal: {
        ...prevState.modalData,
        customClassName: 'linked-images-slider',
        CustomContent: () => (
          <LinkedImagesSliderSlider linkedImages={images} currentSlideIndex={index} setOrientation={() => null} disabledOrientations={[orientationConstants.orientation.chart]} isPreviewModal={true} />
        ),
        isOpen: true,
        type: 'none',
        closeAction,
      },
    }));
  };

  render() {
    const { t } = this.context;
    const { stepperData, activeStep, lastStep, files, uploadModalData, verificationModalData, isEditMode, users, isDateClearable, restoreContractorModalData } = this.state;
    const { className, contractor, getContractorFiles, viewOnly } = this.props;

    const activeStepObj = find(stepperData, item => item.stepValue === activeStep);
    const defaultPermitTestDate = new Date();
    const defaultPermitTestExpirationDate = Helpers.addMonths(new Date(), 12);

    return (
      <div className={`contractor-create-modal ${className || ''}`}>
        <Stepper {...{ stepperData, activeStep }} />

        <p className="f-primary step-desc">{(activeStepObj && t(activeStepObj.description)) || ''}</p>

        <CreateContractorForm
          onSubmit={this.handleSubmit}
          stepAction={this.setStep}
          initialValues={{
            ...contractor,
            [formConstants.fields.permitTestDate]:
              contractor && contractor[formConstants.fields.permitTestDate] ? new Date(contractor[formConstants.fields.permitTestDate] * 1000) : defaultPermitTestDate,
            [formConstants.fields.permitTestExpirationDate]:
              contractor && contractor[formConstants.fields.permitTestExpirationDate] ? new Date(contractor[formConstants.fields.permitTestExpirationDate] * 1000) : defaultPermitTestExpirationDate,
          }}
          activeStep={activeStep}
          activeStepObj={activeStepObj}
          lastStep={lastStep}
          files={files}
          openFilesModalData={() =>
            this.openFilesModalData(() => {
              getContractorFiles(contractor[formConstants.fields.id], files => this.setState({ files }));
            })
          }
          deleteContractorFile={this.unlinkContractorFile}
          openImagesSliderModal={this.openImagesSliderModal}
          users={users}
          handleSearchUsers={this.handleSearchUsers}
          isEditMode={isEditMode}
          isDisabled={viewOnly}
          isDateClearable={isDateClearable}
        />
        <Modal {...uploadModalData} />
        <Modal {...verificationModalData} />
        <Modal {...restoreContractorModalData} />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  uploadAsset: (file, IDs, callbackFunction, index, customDoneFunc) => dispatch(uploadAsset(file, IDs, callbackFunction, index, customDoneFunc)),
  setSingleUploadItem: (progress, index) => dispatch(setSingleUploadItem(progress, index)),
  setUploadItems: assets => dispatch(setUploadItems(assets)),
  changeField: (fieldName, value) => dispatch(change(FORMS.createContractorForm, fieldName, value)),
  notificationModalCustom: (errorMessage, title) => dispatch(notificationModalCustom(true, errorMessage, title)),
  updateSyncErrors: (formName, error) => dispatch(updateSyncErrors(formName, error)),
});

CreateContractorModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(CreateContractorModal);
