import { isEmpty, pick } from 'lodash';
import areasApi from '../../../api/areas-api/actions';
import {
  handleDeleteAreaModal,
  setAreas,
  amendAreas,
  updateAreaInArray,
  deleteAreaInArray,
  setAreaGroupsDropdown,
  setAreaGroupsFilterDropdown,
  setAreasLoading,
  setAreaDetailsLoading,
  addAreaGroupsDropdownItemAtIndex,
  updateAreaGroupsDropdownItemByProp,
  removeAreaGroupsDropdownItemByProp,
  setInspectionAreasClustered,
} from './action-creators';
import { formConstants, areaGroupConstants } from '../constants/areas-constants';
import { formConstants as inspectionSettingsConstants } from '../constants/inspection-settings';
import Helpers from '../../../common/helpers';
import axios from 'axios';

let cancelToken;

export const getAreas = (InspectionID, SearchText = '', optionalParams = {}, loadMore = false, callback) => {
  return async dispatch => {
    if (!InspectionID) {
      return;
    }
    try {
      dispatch(setAreasLoading(true));

      const res = await areasApi.getAreas({ InspectionID, SearchText, ...optionalParams });
      const { Areas, ...restParams } = res.data.Data;

      const areas = (Areas || []).map(el => ({
        ...el,
        visible: true,
        [formConstants.fields.modelDetails]: isEmpty(el[formConstants.fields.modelDetails]) ? {} : Helpers.parseJson(el[formConstants.fields.modelDetails]),
      }));

      if (loadMore) {
        dispatch(amendAreas(areas));
      } else {
        dispatch(setAreas(areas));
      }

      callback && callback(restParams);

      dispatch(setAreasLoading(false));
    } catch (err) {
      dispatch(setAreasLoading(false));
    }
  };
};

export const getAreaDetails = (areaId, callback) => {
  return async dispatch => {
    try {
      dispatch(setAreaDetailsLoading(true));

      let res = await areasApi.getAreaDetails([{ area_id: areaId }]);
      const { Data } = res.data;

      callback &&
        callback({
          ...Data,
          [formConstants.fields.modelDetails]: isEmpty(Data[formConstants.fields.modelDetails]) ? {} : Helpers.parseJson(Data[formConstants.fields.modelDetails]),
        });
      dispatch(setAreaDetailsLoading(false));
    } catch (err) {
      dispatch(setAreaDetailsLoading(false));
    }
  };
};

export const addArea = (InspectionID, currentAreas, data, camPos, callback) => {
  return async dispatch => {
    const newAreas = [{ ...data, visible: isEmpty(data.Geometry) ? false : true }, ...(currentAreas || [])];
    try {
      dispatch(setAreas(newAreas));
      const res = await areasApi.createArea({
        InspectionID,
        ...data,
        [formConstants.fields.modelDetails]: isEmpty(data[formConstants.fields.modelDetails]) ? '' : JSON.stringify(data[formConstants.fields.modelDetails]),
        [formConstants.fields.camPos]: {
          coordinates: [camPos.x, camPos.y, camPos.z],
        },
      });
      const { Data } = res.data;
      if (Data) {
        dispatch(
          updateAreaInArray(data[formConstants.fields.id], {
            ...Data,
            [formConstants.fields.modelDetails]: isEmpty(Data[formConstants.fields.modelDetails]) ? {} : Helpers.parseJson(Data[formConstants.fields.modelDetails]),
          })
        );
        callback && callback(Data);
      } else {
        dispatch(deleteAreaInArray(data[formConstants.fields.id]));
      }
    } catch (e) {
      dispatch(deleteAreaInArray(data[formConstants.fields.id]));
      console.log(e);
    }
  };
};

export const updateArea = data => {
  return async dispatch => {
    try {
      const res = await areasApi.updateArea({
        ...data,
        [formConstants.fields.modelDetails]: isEmpty(data[formConstants.fields.modelDetails]) ? '' : JSON.stringify(data[formConstants.fields.modelDetails]),
      });
      const { Data } = res.data;

      dispatch(
        updateAreaInArray(data[formConstants.fields.id], {
          ...data,
          ...Data,
          [formConstants.fields.modelDetails]: isEmpty(Data[formConstants.fields.modelDetails]) ? {} : Helpers.parseJson(Data[formConstants.fields.modelDetails]),
        })
      );
    } catch (e) {
      console.log(e);
    }
  };
};

export const deleteArea = (data, callback = null, deleteOnlyInternal) => {
  return async dispatch => {
    try {
      if (!deleteOnlyInternal) {
        await areasApi.deleteArea({ AreaID: data[formConstants.fields.id] });
        dispatch(
          handleDeleteAreaModal({
            isOpen: false,
          })
        );
      }

      dispatch(deleteAreaInArray(data[formConstants.fields.id]));

      if (callback) callback(data, 'delete');
    } catch (e) {
      console.log(e);
      dispatch(
        handleDeleteAreaModal({
          isOpen: false,
        })
      );
    }
  };
};

export const createAreaGroup = (inspection_id, Name, callback) => {
  return async dispatch => {
    try {
      const res = await areasApi.createAreaGroup({ InspectionID: inspection_id, Name });
      const { Data } = res.data;
      dispatch(addAreaGroupsDropdownItemAtIndex(Data));
      callback && callback();
    } catch (e) {
      console.log(e);
    }
  };
};

export const deleteAreaGroup = areaGroupId => {
  return async dispatch => {
    try {
      await areasApi.deleteAreaGroup({ AreaGroupID: areaGroupId });

      dispatch(removeAreaGroupsDropdownItemByProp({ [areaGroupConstants.fields.id]: areaGroupId }));
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateAreaGroupName = (areaGroupId, name) => {
  return async dispatch => {
    try {
      await areasApi.updateAreaGroup({ [areaGroupConstants.fields.id]: areaGroupId, [areaGroupConstants.fields.name]: name });
      dispatch(updateAreaGroupsDropdownItemByProp({ [areaGroupConstants.fields.id]: areaGroupId, [areaGroupConstants.fields.name]: name }));
    } catch (e) {
      console.log(e);
    }
  };
};

export const getAreaGroupsDropdown = (inspection_id, searchText = '', callback) => {
  return async (dispatch, getState) => {
    try {
      const {
        inspectionReducer: { inspectionID },
      } = getState();

      const res = await areasApi.getAreaGroups({ InspectionID: inspection_id || inspectionID, SearchText: searchText });
      const { Data } = res.data;
      dispatch(setAreaGroupsDropdown(Data));
      callback && callback();
    } catch (e) {
      console.log(e);
    }
  };
};
export const getAreaGroupsFilterDropdown = (inspection_id, searchText = '', callback) => {
  return async (dispatch, getState) => {
    try {
      const {
        inspectionReducer: { inspectionID },
      } = getState();

      const res = await areasApi.getAreaGroupsFilter({ InspectionID: inspection_id || inspectionID, SearchText: searchText });
      const { Data } = res.data;
      dispatch(setAreaGroupsFilterDropdown(Data));
      callback && callback();
    } catch (e) {
      console.log(e);
    }
  };
};

export const getInspectionAreasClustered = (inspection_id, activeLeftSidebar, searchText = '', optionalParams = {}, callback) => {
  return async (dispatch, getState) => {
    const {
      inspectionReducer: { inspectionSettings },
    } = getState();
    const clusterConfiguration = pick(inspectionSettings, [
      inspectionSettingsConstants.fields.numberOfRings,
      inspectionSettingsConstants.fields.numberOfSlices,
      inspectionSettingsConstants.fields.initialDistance,
      inspectionSettingsConstants.fields.multiplier,
    ]);
    try {
      //Check if there are any previous pending requests
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel('Operation canceled due to new request.');
      }

      //Save the cancel token for the current request
      cancelToken = axios.CancelToken.source();

      const res = await areasApi.getAreasClustered({ InspectionID: parseInt(inspection_id), SearchText: searchText, ...clusterConfiguration, ...optionalParams }, { cancelToken: cancelToken.token });
      const { Data } = res.data;

      dispatch(
        setInspectionAreasClustered(
          (Data.Areas || []).map((el, index) => ({
            ...el,
            ID: el.ID > 0 ? el.ID : `CLUSTER_${Date.now()}_${index}`,
            [formConstants.fields.modelDetails]: isEmpty(el[formConstants.fields.modelDetails]) ? {} : Helpers.parseJson(el[formConstants.fields.modelDetails]),
          }))
        )
      );
      callback &&
        callback(
          (Data.Areas || []).map((el, index) => ({
            ...el,
            ID: el.ID > 0 ? el.ID : `CLUSTER_${Date.now()}_${index}`,
            [formConstants.fields.modelDetails]: isEmpty(el[formConstants.fields.modelDetails]) ? {} : Helpers.parseJson(el[formConstants.fields.modelDetails]),
          }))
        );
    } catch (e) {
      console.log(e);
    }
  };
};

export const getAreaItemHistory = (params, history, paging, callback) => {
  return async () => {
    try {
      callback({ isLoading: true });
      const res = await areasApi.getAreaItemHistory(params);
      const data = res?.data?.Data;

      if (data?.history) {
        callback({
          isLoading: false,
          history: [...history, ...data.history],
          paging: { ...paging, HasNext: data.hasNext || false, LastSeen: data.lastSeen, TotalNumber: data.totalItems },
        });
      } else {
        callback({ isLoading: false });
      }
    } catch (e) {
      callback({ isLoading: false });
      console.log(e);
    }
  };
};
