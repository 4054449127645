import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { debounce, chain } from 'lodash';

import TableComponent from '../../../../common/table/components/table-component';
import SearchInput from '../../../../common/input/components/search-input';
import Button from '../../../../common/form/components/button';
import Modal from '../../../../common/modal/components/modal';
import DropdownMenu from './table-dropdown';
import RoleForm from './role-form';
import RoleUsers from './role-users';
import Icon from '../../../../common/icon/components/icon';

import Helpers from '../../../../common/helpers';

import { getRolesData, deleteRole, createRole, updateRole, getRolePermissions, getRoleUsers } from '../../actions/role-data-actions';
import { setRolesData, setUsersData } from '../../actions/action-creators';

import { rolesTableHeader, sortDirection, filterProps, roleFields, defaultRolesFilter } from '../../constants/role-constants';

import AccessRenderer from '../../../../common/access-renderer/components/access-renderer';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../common/permissions-constants';
import '../../styles/roles.scss';

class Roles extends Component {
  constructor(props) {
    super(props);

    this.searchChangeDebounced = debounce(this.search, 500);
    this.state = {
      filter: {
        ...defaultRolesFilter,
      },
      modalData: {
        isOpen: false,
      },
    };
  }
  componentDidMount = () => {
    const { filter } = this.state;
    const { getRolesData } = this.props;
    getRolesData(filter, HasNext => {
      this.setState({ filter: { ...filter, HasNext } });
    });
  };

  componentWillUnmount() {
    const { resetRolesData, resetUsersData } = this.props;
    this.searchChangeDebounced.cancel();
    resetRolesData();
    resetUsersData();
  }

  handleInputChange = e => {
    this.searchChangeDebounced(e.target.value);
  };

  search = SearchText => {
    const { filter } = this.state;
    const { getRolesData } = this.props;

    const newParams = { ...filter, SearchText, [filterProps.lastSeen]: 0 };

    getRolesData(newParams, HasNext => {
      this.setState({ filter: { ...newParams, HasNext } });
    });
  };

  onColumnSort = SortByColumn => {
    const { filter } = this.state;
    const { getRolesData } = this.props;
    const newParams = {
      ...filter,
      [filterProps.sortByColumn]: SortByColumn,
      [filterProps.sortByDirection]: filter[filterProps.sortByDirection] === sortDirection.asc ? sortDirection.desc : sortDirection.asc,
      [filterProps.lastSeen]: 0,
    };

    getRolesData(newParams, HasNext => {
      this.setState({ filter: { ...newParams, HasNext } });
    });
  };

  loadMore = () => {
    const { filter } = this.state;
    const { getRolesData, rolesTableData } = this.props;
    const newParams = { ...filter, [filterProps.lastSeen]: filter[filterProps.lastSeen] + filter[filterProps.perPage] };

    getRolesData(
      newParams,
      HasNext => {
        this.setState({ filter: { ...newParams, HasNext } });
        Helpers.scrollIntoView('members-container', `row-${rolesTableData.length - 1}`);
      },
      true
    );
  };

  closeModal = () =>
    this.setState({
      modalData: {
        isOpen: false,
      },
    });

  openCreateRole = () => {
    const { t } = this.context;
    const { createRole } = this.props;
    this.setState({
      modalData: {
        isOpen: true,
        title: t('ROLES_CREATE_POPUP.TITLE'),
        customClassName: 'create-role-popup',
        CustomContent: () => <RoleForm submitForm={params => createRole(params, this.closeModal)} />,
        type: 'none',
        closeAction: () => this.closeModal(),
      },
    });
  };

  openEditRole = role => {
    const { t } = this.context;
    const { updateRole, getRolePermissions } = this.props;

    getRolePermissions(role, permissions => {
      const initialValues = chain(permissions)
        .map(item => item.Name)
        .reduce((obj, key) => {
          obj[key] = true;
          return obj;
        }, {})
        .value();

      this.setState({
        modalData: {
          isOpen: true,
          title: t('EDIT_ROLE_POPUP.TITLE'),
          customClassName: 'create-role-popup',
          CustomContent: () => (
            <RoleForm
              submitForm={params => updateRole({ ...params, [roleFields.id]: role[roleFields.id] }, this.closeModal)}
              initialValues={{ ...role, ...initialValues }}
              submitButtonText={'EDIT_ROLE_POPUP.SUBMIT'}
            />
          ),
          type: 'none',
          closeAction: () => this.closeModal(),
        },
      });
    });
  };

  openDeleteConfirmationModal = role => {
    const { t } = this.context;
    const { deleteRole } = this.props;
    this.setState({
      modalData: {
        isOpen: true,
        title: t('ROLES_DELETE_POPUP.TITLE'),
        content: t('ROLES_DELETE_POPUP.DESCRIPTION', { role: role[roleFields.name] }),
        type: 'yes-no',
        closeAction: () => this.closeModal(),
        confirmAction: () => deleteRole(role, this.closeModal),
      },
    });
  };

  openRoleUsersModal = role => {
    const { t } = this.context;
    const { getRoleUsers } = this.props;

    this.setState({
      modalData: {
        isOpen: true,
        title: t('ROLE_USERS_POPUP.TITLE'),
        CustomContent: incomingProps => <RoleUsers {...incomingProps} closeAction={this.closeModal} searchUsers={callback => getRoleUsers(role[roleFields.id], callback)} />,
        type: 'none',
        closeAction: () => {
          this.closeModal();
        },
      },
    });
  };

  render() {
    const { t } = this.context;
    const { filter, modalData } = this.state;
    const { rolesTableData, rolesLoading, user } = this.props;

    return (
      <div className="members-container roles">
        <div className="search-combo">
          <SearchInput onChange={this.handleInputChange} placeholder={t('PROJECT.SEARCH.PLACEHOLDER')} />
          <AccessRenderer visibleFor={[PERMISSIONS[PERMISSION_TYPES.people].create.name]}>
            {({ hasAccess }) => (hasAccess ? <Button type="button" text={t('ROLES_TABLE.ADD_ROLE')} onClick={this.openCreateRole} /> : null)}
          </AccessRenderer>
        </div>
        <TableComponent
          tableConfig={[
            ...rolesTableHeader,
            {
              title: 'TEAM_TABLE.ACTIONS',
              CustomComponent: (data, itemIndex) => <DropdownMenu data={data} itemIndex={itemIndex} user={user} openDeleteRole={this.openDeleteConfirmationModal} openEditRole={this.openEditRole} />,
              enableSort: false,
            },
          ]}
          data={rolesTableData || []}
          tableCustomClass="users-table"
          onRowClick={this.onRowClick}
          onColumnSort={this.onColumnSort}
          sortingObj={filter}
          translationModule={t}
          formatCell={(value, type, index, item) => {
            if (type === roleFields.assignedUsers) {
              const usersCount = rolesTableData[index][roleFields.assignedUsers] || 0;

              return (
                <div onClick={() => this.openRoleUsersModal(item)} className="info-box">
                  <p className="f-primary">{usersCount || 0}</p>
                  <Icon name="info" handleHover={false} size="xs" />
                </div>
              );
            } else {
              return Helpers.formatCell(value, type, index, item);
            }
          }}
          sortDirectionProp={filterProps.sortByDirection}
          stickyHeader={true}
        />
        <Button
          disabled={!filter[filterProps.hasNext] || rolesLoading}
          text={t('LOAD_MORE')}
          variant="success-outline"
          height="md"
          width="sm"
          className="load-more"
          onClick={this.loadMore}
          type="button"
        />
        <Modal {...modalData} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  rolesTableData: state.rolesTableReducer.rolesData,
  rolesLoading: state.rolesTableReducer.rolesLoading,
  user: state.userReducer,
});

const mapDispatchToProps = dispatch => ({
  getRolesData: (params, callback, loadMore) => dispatch(getRolesData(params, callback, loadMore)),
  createRole: (props, callback) => dispatch(createRole(props, callback)),
  updateRole: (props, callback) => dispatch(updateRole(props, callback)),
  deleteRole: (props, callback) => dispatch(deleteRole(props, callback)),
  getRolePermissions: (role, callback) => dispatch(getRolePermissions(role, callback)),
  resetRolesData: () => dispatch(setRolesData([])),
  resetUsersData: () => dispatch(setUsersData([])),
  getRoleUsers: (roleId, callback) => dispatch(getRoleUsers(roleId, callback)),
});

Roles.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Roles);
