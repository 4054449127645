import React from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { FORMS } from '../../../../../common/constants';
import ButtonWithIcon from '../../../../../common/button-with-icon/button-with-icon';
//import UneditableInfo from '../../../../../common/form/components/uneditable-info';
import { fields } from '../../../constants/constants';
import Button from '../../../../../common/form/components/button';
import Helpers from '../../../../../common/helpers';

const ChangeDMSLocationRightSide = ({ initialValues, successButtonDisabled, successButtonAction, closeModal }, { t }) => {
  const decodedFileName = Helpers.decodeIfStringEncoded(initialValues[fields.fileName]);

  return (
    <form noValidate className="change-hierarchy-details-section">
      <ButtonWithIcon text={t('DOCUMENT_HIERARCHY_CHANGE.BACK_BUTTON', { name: decodedFileName })} type="info" icon="chevron" noBorder iconRotation={180} onClick={closeModal} />
      <div className="change-hierarchy-details-section__title">
        <div className="change-hierarchy-details-section__title__text">
          <h5 className="f-primary bold">{decodedFileName}</h5>
        </div>
      </div>
      <Button
        variant="success"
        type="button"
        text={t('DOCUMENT_HIERARCHY_CHANGE.BUTTON_TEXT')}
        height="md"
        width="lg"
        className="mb-12"
        disabled={successButtonDisabled}
        onClick={successButtonAction}
      />
      {/*<Field id={fileDetailsSections.fields.hierarchy} name={'Name'} component={UneditableInfo} label={t('COMPONENT_DETAILS.FORM_HIERARCHY')} />*/}
    </form>
  );
};

ChangeDMSLocationRightSide.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default reduxForm({ form: FORMS.dmsChangeLocationFileDetailsForm, enableReinitialize: true, touchOnChange: true, destroyOnUnmount: true })(ChangeDMSLocationRightSide);
