import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { values } from 'lodash';

import { fields, isolationsTableConfig, permitsTableConfig, singleKeyFields, statusTypes } from '../constants/constants';

import Button from '../../../common/form/components/button';
import InfoTooltip from '../../../common/tooltip/components/info-tooltip';
import KeyPermitPopover from './key-permit-popover';
import Icon from '../../../common/icon/components/icon';
import KeyOptions from './key-options';
import AccessRenderer from '../../../common/access-renderer/components/access-renderer';
import ExpandableSection from '../../../common/expandable-section/components/expandable-section';
import ItemsTableRenderer from '../../../common/items-renderer/components/items-table-renderer';
import DisplayStatus from '../../../common/display-status/components/display-status';

import Helpers from '../../../common/helpers';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../common/permissions-constants';
import { statuses as isolationStatuses } from '../../inspections/components/isolation-certificates/constants/isolation-certificates-table';
import { statuses as permitStatuses } from '../../inspections/components/permits/constants/permit-constants';

class KeyboxDetails extends Component {
  getPermitName = name => {
    if (!name || !name.length) {
      return '-';
    }

    if (name && name.length > 20) {
      return `${name.substring(0, 20)}...`;
    }

    return name;
  };

  render() {
    const { t } = this.context;
    const { keybox, isLoading, deleteKeybox, openEditKeybox, openConfirmationPopup, openIssueKeyPopup, returnKey } = this.props;

    if (!keybox && !isLoading) {
      return <div className="keybox-empty-state"></div>;
    }
    if (!keybox && isLoading) {
      return null;
    }

    return (
      <div className="keybox-details">
        <h5 className="f-primary">{keybox[fields.name]}</h5>
        <p className="f-secondary-dark space-top">{keybox[fields.description]}</p>

        <ExpandableSection expanded={true} title={t('KEYBOX_DETAILS.CERTIFICATES')}>
          <ItemsTableRenderer
            tableConfig={isolationsTableConfig}
            data={keybox[fields.isolationCertificates] || []}
            tableCustomClass="keyboxes__table-wrapper__table"
            translationModule={t}
            stickyHeader={false}
            formatCell={(value, type, index, item) => {
              if (type === 'status') {
                return <DisplayStatus statuses={values(isolationStatuses)} status={value} />;
              }

              return Helpers.formatCell(value, type, index, item);
            }}
          />
        </ExpandableSection>
        <ExpandableSection expanded={true} title={t('KEYBOX_DETAILS.PERMITS')}>
          <ItemsTableRenderer
            tableConfig={permitsTableConfig}
            data={keybox[fields.permits] || []}
            tableCustomClass="keyboxes__table-wrapper__table"
            translationModule={t}
            stickyHeader={false}
            formatCell={(value, type, index, item) => {
              if (type === 'status') {
                return <DisplayStatus statuses={values(permitStatuses)} status={value} />;
              }

              return Helpers.formatCell(value, type, index, item);
            }}
          />
        </ExpandableSection>
        <ExpandableSection expanded={true} title={t('KEYBOX_DETAILS.KEYS')}>
          <>
            {(keybox[fields.keys] || []).map((item, index) => {
              return (
                <div className="keybox-item space-top" key={index}>
                  <div className="left">
                    <div className={`status ${item[singleKeyFields.issuedTo] ? 'used' : ''}`}></div>
                    <p className="f-primary">{item[singleKeyFields.name]}</p>
                  </div>
                  <div className="right">
                    <KeyOptions item={item} openConfirmationPopup={openConfirmationPopup} keyboxId={keybox[fields.id]} openIssueKeyPopup={openIssueKeyPopup} returnKey={returnKey} />
                  </div>
                  <div className="details">
                    <div className="single-detail">
                      <label>{t('KEYBOX.PERMIT')}</label>
                      <div className="single-detail-duo">
                        <p className="f-primary">{this.getPermitName(item[singleKeyFields.permitName])}</p>
                        {item && item[singleKeyFields.permitName] && (
                          <InfoTooltip customComponent={<KeyPermitPopover keyDetails={item} />} offsetY={-345} offsetX={-180} Component={() => <Icon name="info" size="sm" />} />
                        )}
                      </div>
                    </div>
                    <div className="single-detail">
                      <label>{t('KEYBOX.ISSUED_TO')}</label>
                      <p className="f-primary">{item[singleKeyFields.issuedTo] || '-'}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        </ExpandableSection>
        <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.keybox].edit.name} id={keybox[fields.createdByID]} ownerRequiredPermission={PERMISSIONS[PERMISSION_TYPES.keybox].create.name}>
          {({ hasAccess }) => {
            return (
              <Button
                type="button"
                disabled={!hasAccess || keybox[fields.status] === statusTypes.locked || keybox[fields.status] === statusTypes.inUse}
                width={'lg'}
                variant={'success-outline'}
                text={t('KEYBOX_DETAILS.EDIT')}
                className={'space-top'}
                onClick={openEditKeybox}
              />
            );
          }}
        </AccessRenderer>

        <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.keybox].delete.name}>
          {({ hasAccess }) => {
            return (
              <Button
                type="button"
                disabled={!hasAccess || keybox[fields.status] === statusTypes.locked || keybox[fields.status] === statusTypes.inUse}
                width={'lg'}
                variant={'danger-outline'}
                text={t('KEYBOX_DETAILS.DELETE')}
                className={'space-top'}
                onClick={() =>
                  openConfirmationPopup(t('KEYBOX_DETAILS.DELETE_MODAL.TITLE'), t('KEYBOX_DETAILS.DELETE_MODAL.DESC', { name: keybox[fields.name] }), closeAction => {
                    deleteKeybox(closeAction);
                  })
                }
              />
            );
          }}
        </AccessRenderer>
      </div>
    );
  }
}

KeyboxDetails.contextTypes = {
  t: PropTypes.func.isRequired,
};

KeyboxDetails.propTypes = {};

export default KeyboxDetails;
