export const generateQueryParams = params => {
  if (params.length === 0) return '';
  let query = '?';
  params.forEach((param, index) => {
    const key = Object.keys(param)[0];
    if (param[key] || !isNaN(param[key])) {
      query += `${key}=${param[key] + (index === params.length - 1 ? '' : '&')}`;
    }
  });
  return query;
};

export const generateParamsFromQuery = query => {
  // Check if the input is null, undefined, or not an object
  if (!query || typeof query !== 'object') {
    return [];
  }

  // Convert the query object into an array of key-value pair objects
  return Object.entries(query).map(([key, value]) => {
    return { [key]: value };
  });
};
