export const fields = {
  id: 'ID',
  name: 'Name',
  description: 'Description',
  certificate: 'Certificate',
  status: 'Status',
  issuedkeys: 'LockedKeys',
  totalKeys: 'TotalKeys',
  keys: 'Keys',
  createdByID: 'CreatedByID',
  freeKeys: 'FreeKeys',
  isolationCertificates: 'IsolationCertificates',
  permits: 'Permits',
};

export const keyFields = {
  id: 'ID',
  name: 'Name',
};

export const sortDirection = {
  asc: 'ASC',
  desc: 'DESC',
};

export const filterProps = {
  sortByColumn: 'SortByColumn',
  sortDirection: 'SortDirection',
  perPage: 'PerPage',
  lastSeen: 'LastSeen',
  searchText: 'SearchText',
  hasNext: 'HasNext',
  totalItems: 'TotalItems',
};

export const textAreaMaxChars = 2000;

export const defaultFilters = {
  [filterProps.sortByColumn]: fields.id,
  [filterProps.sortDirection]: sortDirection.asc,
  [filterProps.perPage]: 20,
  [filterProps.lastSeen]: 0,
  [filterProps.searchText]: '',
  [filterProps.hasNext]: false,
};

export const tableConfig = [
  {
    title: 'KEYBOX_LIST.ID',
    key: fields.id,
    enableSort: true,
  },
  {
    title: 'KEYBOX_LIST.NAME',
    key: fields.name,
    enableSort: true,
  },
  {
    title: 'KEYBOX_DETAILS.ISOLATION_CERTIFICATES',
    key: fields.certificate,
    type: fields.isolationCertificates,
  },
  {
    title: 'KEYBOX_LIST.STATUS',
    key: fields.status,
    type: fields.status,
  },
  {
    title: 'KEYBOX_LIST.ISSUED_KEYS',
    key: fields.issuedkeys,
  },
  {
    title: 'KEYBOX_LIST.TOTAL_KEYS',
    key: fields.totalKeys,
    type: fields.totalKeys,
  },
];

export const steps = {
  firstStep: 1,
  secondStep: 2,
};

export const stepperData = [
  {
    name: 'KEYBOX_CREATE.FIRST_STEP',
    stepValue: steps.firstStep,
  },
  {
    name: 'KEYBOX_CREATE.SECOND_STEP',
    stepValue: steps.secondStep,
  },
];

export const tabNames = {
  details: 'DETAILS',
  history: 'HISTORY',
};

export const toolbarItems = {
  [tabNames.details]: {
    name: tabNames.details,
    label: 'KEYBOX.DETAILS_TAB',
  },
  [tabNames.history]: {
    name: tabNames.history,
    label: 'KEYBOX.HISTORY_TAB',
  },
};

export const singleKeyFields = {
  id: 'ID',
  name: 'Name',
  issuedTo: 'IssuedTo',
  issuedToId: 'IssuedToID',
  permitName: 'AssignedPermitName',
  permitStatus: 'AssignedPermitStatus',
  permitTemplateName: 'AssignedPermitTemplateName',
  permitTemplateColor: 'AssignedPermitTemplateColor',
  permitIssuedDate: 'AssignedPermitIssuedDate',
  permitIssuedTo: 'AssignedPermitIssuedTo',
  permitIsolationCertificates: 'AsignedPermitIsolationCertificates',
};

export const statusTypes = {
  notInUse: 'NOT_IN_USE',
  inUse: 'IN_USE',
  locked: 'LOCKED',
};

export const isolationCertificateFields = {
  id: 'ID',
  name: 'Name',
  status: 'Status',
};

export const permitFields = {
  id: 'ID',
  name: 'Name',
  status: 'Status',
};

export const isolationsTableConfig = [
  {
    title: 'KEYBOX_ISOLATIONS_LIST.ID',
    key: isolationCertificateFields.id,
  },
  {
    title: 'KEYBOX_ISOLATIONS_LIST.NAME',
    key: isolationCertificateFields.name,
  },
  {
    title: 'KEYBOX_ISOLATIONS_LIST.STATUS',
    key: isolationCertificateFields.status,
    type: 'status',
  },
];

export const permitsTableConfig = [
  {
    title: 'KEYBOX_PERMITS_LIST.ID',
    key: permitFields.id,
  },
  {
    title: 'KEYBOX_PERMITS_LIST.NAME',
    key: permitFields.name,
  },
  {
    title: 'KEYBOX_PERMITS_LIST.STATUS',
    key: permitFields.status,
    type: 'status',
  },
];
