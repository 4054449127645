import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Tools } from '@tarik.djurdjevic/react-sketch';
import DrawingBoard from '../../drawing-board/components/drawing-board';
import Icon from '../../icon/components/icon';
import Modal from '../../modal/components/modal';
import ScreenshotHelpers from '../../screenshoot-helpers';

import '../styles/input.scss';

// TODO: refactor to functional component
class SignatureField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalData: {
        isOpen: false,
      },
    };
    this.sketchRef = null;
  }

  setSketchRef = ref => {
    if (ref) {
      this.sketchRef = ref;
    }
  };

  saveDrawingAsImage = onFinish => {
    const {
      input: { onChange },
      querySelector,
    } = this.props;

    // Check if the canvas is empty
    // Get the canvas element from the sketch reference
    const canvas = this.sketchRef._canvas;
    // Get the 2D rendering context of the canvas
    const ctx = canvas.getContext('2d');
    // Get the image data of the canvas from the top-left corner to the bottom-right corner
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    // Check if the canvas is empty by iterating through the image data channels
    const isCanvasEmpty = !Array.from(imageData.data).some(channel => channel !== 0);

    if (isCanvasEmpty) {
      this.setState({ modalData: { isOpen: false } });
      return;
    } else {
      ScreenshotHelpers.takeScreenshot(
        `#${querySelector}`,
        base64img => {
          onChange(base64img);

          if (onFinish) {
            onFinish();
          }
        },
        () => null
      );
    }
  };

  openEditModal = () => {
    const { querySelector } = this.props;
    const sketchProps = {
      ref: this.setSketchRef,
    };
    const { t } = this.context;

    const closeAction = () => {
      this.setState({ modalData: { isOpen: false } });
    };

    this.setState({
      modalData: {
        isOpen: true,
        title: t('SIGNATURE_FIELD.EDIT_MODAL.TITLE'),
        CustomContent: dynamicProps => <DrawingBoard querySelector={querySelector} selectedTool={Tools.Pencil} sketchProps={sketchProps} lineColor="#000" />,
        customClassName: 'modal-no-max-height modal-large',
        type: 'ok',
        confirmAction: () => this.saveDrawingAsImage(closeAction),
        closeAction,
      },
    });
  };

  render() {
    const { t } = this.context;
    const { modalData } = this.state;
    const { input, meta, className, id, label, labelClass, disabled, size, withHiddenError, onlyDisabled, isHidden, editable, isRequired } = this.props;

    return (
      <div className="signature-field">
        <div className={`field-wrapper${disabled ? ' field-wrapper--disabled' : ''}${onlyDisabled ? ' field-wrapper--only-disabled' : ''} ${isHidden ? 'hidden-field' : ''}`}>
          {label && (
            <label className={`field-wrapper__label ${labelClass}`} htmlFor={id}>
              {`${t(label)}${isRequired ? '*' : ''}`}
            </label>
          )}
          <div className="input-wrapper" id={id}>
            <div
              className={(meta?.error && meta.touched ? 'invalid ' : '') + `${className || 'input-wrapper__input'} ${size} signature-input`}
              style={input.value ? { backgroundImage: `url(${input.value})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' } : {}}
            >
              {editable && <Icon name="pen" size="xs" onClick={this.openEditModal} className={`icon`} />}
            </div>
            <div className={`error-message${meta?.error && meta.touched ? '--visible' : ''} ${withHiddenError ? 'with-hidden-error-message' : ''}`}>{meta?.error && t(meta.error)}</div>
          </div>
        </div>
        <Modal {...modalData} />
      </div>
    );
  }
}

SignatureField.propTypes = {
  id: PropTypes.string.isRequired,
  input: PropTypes.object,
  meta: PropTypes.object,
  type: PropTypes.oneOf(['text', 'number', 'email']),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  labelClass: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  isHidden: PropTypes.bool,
  autoFocus: PropTypes.bool,
  max: PropTypes.number,
  size: PropTypes.oneOf(['sm', 'lg', 'xl']),
  withHiddenError: PropTypes.bool,
};

SignatureField.defaultProps = {
  type: 'text',
  placeholder: PropTypes.string,
  className: '',
  labelClass: '',
  disabled: false,
  isHidden: false,
  autoFocus: false,
  size: 'sm',
  withHiddenError: false,
  editable: true,
};

SignatureField.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default SignatureField;
