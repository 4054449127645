import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';

import CustomInput from '../../../../../../../common/form/components/input';
import { fields } from '../../constants/isolation-components-constants';
import { validate } from '../../validators/isolation-component-validators';

const IsolationComponentsForm = ({ componentOrder, formIndex }) => {
  const { isolationState, isolationMethod, normalPosition, order } = fields;

  return (
    <form className="isolation-component-form" noValidate>
      <div className="fields-container">
        <div className="field-item-wrapper">
          <Field id={`${isolationState.id}-${formIndex}`} name={isolationState.name} label={isolationState.label} component={CustomInput} placeholder={isolationState.placeholder} size="lg" />
        </div>
        <div className="field-item-wrapper">
          <Field id={`${isolationMethod.id}-${formIndex}`} name={isolationMethod.name} label={isolationMethod.label} component={CustomInput} placeholder={isolationMethod.placeholder} size="lg" />
        </div>
        <div className="field-item-wrapper">
          <Field id={`${normalPosition.id}-${formIndex}`} name={normalPosition.name} label={normalPosition.label} component={CustomInput} placeholder={normalPosition.placeholder} size="lg" />
        </div>
        <div className="field-item-wrapper order-input">
          <Field
            id={`${order.id}-${formIndex}`}
            name={order.name}
            label={order.label}
            component={CustomInput}
            placeholder={order.placeholder}
            size="lg"
            input={{ value: `${componentOrder}.` }}
            onlyDisabled
          />
        </div>
      </div>
    </form>
  );
};

IsolationComponentsForm.propTypes = {
  componentOrder: PropTypes.number.isRequired,
  formIndex: PropTypes.number.isRequired,
};

export default reduxForm({
  validate,
  enableReinitialize: true,
  touchOnChange: true,
  destroyOnUnmount: true,
})(IsolationComponentsForm);
