import { debounce, isArray, isEqual, keys, map, reduce, values } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import TableTemplate from '../../../../../common/double-table/components/table-template';
import Helpers from '../../../../../common/helpers';
import Modal from '../../../../../common/modal/components/modal';
import DefectsFilter from '../../left-toolbar/filters/defects-filter';
import { getSelectedStatuses } from '../../notifications/helpers/notifications-helpers';
import { formatObservationsTableCells } from '../actions/table-formatting';
import { defaultFilters, filterProps, sortDirection, statuses, tableConfig } from '../constants/constants';
import { deleteAdvancedFiltersFromFilters } from '../helpers/observations-helpers';

const ObservationsWrapper = ({ data, filters, wrapperClassName, loading, fetchObservations, customTableClassName, onRowClick, inspectionId }, { t }) => {
  const [searchText, setSearchText] = useState('');
  const [advancedFilters, setAdvancedFilters] = useState({});
  const [modalData, setModalData] = useState({ isOpen: false });

  const isFetchObservationsValid = fetchObservations && typeof fetchObservations === 'function';

  const onSort = (SortByColumn = {}) => {
    const newFilters = {
      ...filters,
      [filterProps.sortByColumn]: SortByColumn,
      [filterProps.sortDirection]: filters[filterProps.sortDirection] === sortDirection.asc ? sortDirection.desc : sortDirection.asc,
      [filterProps.lastSeen]: 0,
    };
    isFetchObservationsValid && fetchObservations(newFilters);
  };

  const onSearch = SearchText => {
    const newFilters = {
      ...filters,
      [filterProps.lastSeen]: 0,
      SearchText,
    };
    isFetchObservationsValid && fetchObservations(newFilters);
  };

  const onQuickFilterClick = quickFilters => {
    const newFilters = {
      ...filters,
      [filterProps.lastSeen]: 0,
    };
    if (isArray(quickFilters)) {
      newFilters[filterProps.statusFilter] = [quickFilters.length ? quickFilters.toString() : ''];
    }
    isFetchObservationsValid && fetchObservations(newFilters);
  };

  const onLoadMoreClick = () => {
    filters[filterProps.lastSeen] = data.length;
    isFetchObservationsValid && fetchObservations(filters, true);
  };

  const handleFileSearchInput = e => {
    setSearchText(e.target.value);
    searchTextChanged(e.target.value);
  };

  const searchTextChanged = debounce(onSearch, 300);

  const applyAdvancedFilters = (values = {}) => {
    setAdvancedFilters(values);
    //normalize advanced filter
    const newAdvancedFilter = reduce(
      values,
      (obj, it, key) => {
        if (key === filterProps.severityFilter) {
          //need to check with BE
          obj[key] = [values[filterProps.severityFilter].min, values[filterProps.severityFilter].max];
        } else if ([filterProps.componentTypeFilter, filterProps.defectMainTypeFilter, filterProps.defectTypeFilter].indexOf(key) > -1) {
          obj[key] = values[key].toString();
        } else if ([filterProps.componentIDsFilter, filterProps.createdByFilter].indexOf(key) > -1) {
          obj[key] = map(values[key], ({ ID, UserID }) => ID || UserID);
        } else if ([filterProps.dateFrom, filterProps.actionedFrom, filterProps.closedFrom].indexOf(key) > -1) {
          obj[key] = (values[key] && Helpers.getUnixDate(new Date(values[key]).getTime())) || null;
        } else if ([filterProps.dateTo, filterProps.actionedTo, filterProps.closedTo].indexOf(key) > -1) {
          // sets end/to dates to end of day 23:59:59 to include that day in filter results
          obj[key] = (values[key] && Helpers.getUnixDate(new Date(values[key]).setHours(23, 59, 59))) || null;
        } else if ([filterProps.hasWorkOrder, filterProps.hasNotificationFilter, filterProps.hasFiles, filterProps.has3DLocationFilter].indexOf(key) > -1) {
          // casting YES / NO to boolean values, otherwise sending filter as null
          obj[key] = Helpers.castToggleRadioInputAnswer(values[key]);
        } else {
          obj[key] = values[key];
        }
        return obj;
      },
      {}
    );

    fetchObservations({ ...deleteAdvancedFiltersFromFilters(filters), ...newAdvancedFilter, [filterProps.lastSeen]: 0 });
    closeModal();
  };

  const resetAdvancedFilters = () => {
    setAdvancedFilters({});
    fetchObservations({ ...deleteAdvancedFiltersFromFilters(filters), [filterProps.lastSeen]: 0 });
    closeModal();
  };

  const openAdvancedFilters = () =>
    setModalData({
      isOpen: true,
      type: 'none',
      title: t('OBSERVATIONS.ADVANCED_FILTERS'),
      CustomContent: () => <DefectsFilter hideComponentFilters initialValues={advancedFilters} submitForm={applyAdvancedFilters} resetFilter={resetAdvancedFilters} inspectionId={inspectionId} />,
      customClassName: 'modal-large defects-filter',
      closeAction: closeModal,
    });

  const closeModal = () => setModalData({ isOpen: false });

  const advancedFiltersLength = keys(advancedFilters).length;
  const isFilterApplied =
    advancedFiltersLength === 0 && isEqual(defaultFilters[filterProps.statusFilter], filters[filterProps.statusFilter]) && defaultFilters[filterProps.searchText] === filters[filterProps.searchText];

  return (
    <div className={wrapperClassName ? wrapperClassName : ''}>
      <TableTemplate
        tableConfig={tableConfig}
        filters={filters}
        data={data}
        onSortClick={onSort}
        loadMoreOnClick={onLoadMoreClick}
        quickFilters={values(statuses)}
        onQuickFilterClick={onQuickFilterClick}
        handleFormatCells={formatObservationsTableCells}
        openAdvancedFilters={openAdvancedFilters}
        selectedItem={null}
        isLoading={loading}
        TotalItems={filters[filterProps.totalItems]}
        searchPlaceholder={t('SEARCH')}
        sortDirection={filterProps.sortDirection}
        sortByColumn={filterProps.sortByColumn}
        resultsText={'COMPONENT_OBSERVATIONS.TOTAL'}
        searchInputOnChange={handleFileSearchInput}
        searchInputValue={searchText}
        hasNext={filters[filterProps.hasNext]}
        selectedQuickFilters={getSelectedStatuses(filters)}
        activeAdvancedFilters={advancedFiltersLength}
        noDataText={isFilterApplied ? t('COMPONENT_OBSERVATIONS.NO_DATA') : t('COMPONENT_OBSERVATIONS.NO_DATA_FILTERED')}
        customTableClass={customTableClassName}
        onRowClick={onRowClick}
      />
      <Modal {...modalData} />
    </div>
  );
};

ObservationsWrapper.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ObservationsWrapper;
