import React from 'react';

import HistoryItemComponentChange from '../../../common/history-item/components/history-item-component-change';
import HistoryItemDefectStatus from '../../../common/history-item/components/history-item-defect-status-display';
import HistoryItemDefectType from '../../../common/history-item/components/history-item-defect-type-display';
import HistoryItemFileDisplay from '../../../common/history-item/components/history-item-file-display';

import inspectionWorkflowConstants from '../../inspection-workflow/constants/inspection-workflow-constants';

import Icon from '../../../common/icon/components/icon';

import Helpers from '../../../common/helpers';
import { measurementTypes } from './constants';

export const formConstants = {
  fields: {
    color: 'Colour',
    componentType: 'ComponentType',
    name: 'Name',
    code: 'ComponentCode',
    manufacturer: 'Manufacturer',
    material: 'Material',
    type: 'DefectType',
    severity: 'Severity',
    description: 'Description',
    recommendation: 'Recommendation',
    footage: 'Assets',
    date: 'DateCreated',
    createdById: 'CreatedBy',
    loggedBy: 'LoggedByUser.Name',
    loggedByUserID: 'LoggedByUser.ID',
    createdByUserID: 'CreatedByUser.ID',
    points: 'Geometry.coordinates',
    comments: 'comments',
    camPosition: 'CameraPosition.coordinates',
    component: 'component',
    componentID: 'ComponentID',
    critical: 'Critical',
    linkedDefect: 'SelectedDefect',
    linkedDefectID: 'SelectedDefectID',
    id: 'ID',
    status: 'Status',
    // constants for the lists
    // points object fields
    x: '0',
    y: '1',
    z: '2',
    pointName: 'name',
    // comments object fields
    author: 'author',
    text: 'text',
    systemType: 'SystemType',
    drawings: 'Drawings',
    resolvedComment: 'ResolvedComment',
    resolved: 'Resolved',
    resolvedByUser: 'ResolvedByUser',
    resolvedDate: 'ResolvedDate',
    mainType: 'MainType',
    actionedByUser: 'ActionedBy',
    actionedDate: 'ActionedAt',
    notificationsCount: 'LinkedNotifications',
    statusCustom: 'statusCustom',
    iconsCustom: 'iconsCustom',
    componentName: 'ComponentName',
    componentCode: 'ComponentCode',
  },
};

export const showFields = [
  { name: formConstants.fields.name },
  { name: formConstants.fields.color },
  { name: formConstants.fields.description },
  { name: formConstants.fields.severity },
  { name: formConstants.fields.type },
  { name: formConstants.fields.date, type: 'date' },
];

export const observationTypeFields = {
  value: 'Value',
  name: 'Name',
};

export const DefaultDefect = camPos => ({
  Name: 'New Observation',
  Colour: inspectionWorkflowConstants.severity.yellow.color,
  SystemType: measurementTypes.defect,
  ComponentCode: '',
  Manufacturer: '',
  Material: '',
  DefectType: '',
  Severity: 1,
  Description: '',
  Assets: null,
  Geometry: {
    type: 'Point',
    coordinates: [],
  },
  CameraPosition: camPos || {
    coordinates: [0, 0, 0],
  },
  commentsTemp: [],
  Resolved: false,
  DateCreated: Helpers.getUnixDate(Date.now()),
});

export const DefaultInspectPoint = camPos => ({
  Name: '',
  Colour: '',
  DefectType: '',
  LoggedByUser: {
    ID: 9,
    Name: 'Edin Kulovic',
    Email: '',
    Phone: '',
  },
  IsTemp: true,
  DateCreated: 1567679755,
  Geometry: {
    type: '',
    coordinates: null,
  },
  CameraPosition: camPos || {
    coordinates: [673232.3908495903, 5721537.019584537, 88.93808889389038],
  },
});

export const defectType = {
  point: 'Point',
  area: 'Polygon',
  distance: 'LineString',
  angle: 'Angle',
  height: 'Height',
  circle: 'Circle',
};

export const uploadGroups = {
  other: 'OTHER_FILES',
  otherImages: 'OTHER_IMAGES',
  linkedImages: 'LINKED_IMAGES',
  resolutionImages: 'RESOLUTION_IMAGES',
  resolutionFiles: 'RESOLUTION_FILES',
  measurementOther: 'OTHER',
};

export const displayProps = [
  {
    name: 'FileName',
  },
  {
    name: 'CreatedAt',
    type: 'date',
  },
];

export const textAreaMaxChars = 2000;
export const defectStatus = {
  // resolved: 'NOT_STARTED',
  // notResolved: 'COMPLETE',
  open: 'OPEN',
  actioned: 'ACTIONED',
  closed: 'CLOSED',
};

export const statuses = {
  open: {
    value: 'OPEN',
    title: 'OBSERVATION_STATUS.OPEN',
    icon: 'exclamation-mark',
    iconProps: {
      danger: true,
      handleHover: false,
    },
    skipPermissionCheck: true,
    isRegularStatus: true,
    modalTitle: 'ISOLATION_CERTIFICATE_STATUS.DRAFT_TITLE',
  },
  actioned: {
    value: 'ACTIONED',
    title: 'OBSERVATION_STATUS.ACTIONED',
    icon: 'exclamation-mark',
    iconProps: {
      warning: true,
      handleHover: false,
    },
    skipPermissionCheck: true,
    isRegularStatus: true,
  },
  closed: {
    value: 'CLOSED',
    title: 'OBSERVATION_STATUS.CLOSED',
    icon: 'checkmark',
    iconProps: {
      active: true,
    },
    isRegularStatus: true,
    skipPermissionCheck: true,
  },
};

export const sortingDirection = {
  asc: 'ASC',
  desc: 'DESC',
};

export const tabNames = {
  details: 'DETAILS',
  comments: 'COMMENTS',
  history: 'HISTORY',
  notifications: 'NOTIFICATIONS',
};

export const toolbarItems = {
  [tabNames.details]: {
    name: tabNames.details,
    label: 'OBSERVATION_FORM_TAB.DETAILS',
  },
  [tabNames.comments]: {
    name: tabNames.comments,
    label: 'COMPONENET_FORM_TAB.COMMENTS',
  },
  [tabNames.history]: {
    name: tabNames.history,
    label: 'OBSERVATION_FORM_TAB.HISTORY',
  },
  [tabNames.notifications]: {
    name: tabNames.notifications,
    label: 'DEFECT_DETAILS.SECTION_NOTIFICATIONS',
  },
};

export const filterParams = {
  searchText: 'SearchText',
  sortByColumn: 'SortByColumn',
  sortDirection: 'SortDirection',
  selectedFilter: 'SelectedFilter',
  includedIDs: 'IncludedIDs',
  statusFilter: 'StatusFilter',
  severityFilter: 'SeverityFilter',
  dateFrom: 'DateFrom',
  dateTo: 'DateTo',
  actionedFrom: 'ActionedFrom',
  actionedTo: 'ActionedTo',
  closedFrom: 'ClosedFrom',
  closedTo: 'ClosedTo',
  componentTypeFilter: 'ComponentTypeFilter',
  componentIDsFilter: 'ComponentIDsFilter',
  defectTypeFilter: 'DefectTypeFilter',
  defectMainTypeFilter: 'DefectMainTypeFilter',
  createdByFilter: 'CreatedByFilter',
  hasNotificationFilter: 'HasNotificationFilter',
  lastSeen: 'LastSeen',
  perPage: 'PerPage',
  totalItems: 'TotalNumber',
  hasNext: 'HasNext',
  assignees: 'Assignees',
  collaborators: 'Collaborators',
  linkedToMe: 'LinkedToMe',
  hasWorkOrder: 'HasWorkOrdersFilter',
  hasFiles: 'HasFilesFilter',
  has3DLocationFilter: 'Has3DLocationFilter',
  properties: 'Properties',
  componentsSearchFilter: 'ComponentSearchText',
};

export const defaultPagingObj = {
  [filterParams.lastSeen]: 0,
  [filterParams.perPage]: 50,
  [filterParams.totalItems]: 0,
  [filterParams.hasNext]: false,
};
export const defaultSortingFilter = {
  [filterParams.sortByColumn]: formConstants.fields.id,
  [filterParams.sortDirection]: sortingDirection.desc,
};

export const quickFilter = [
  {
    value: defectStatus.open,
    field: filterParams.statusFilter,
    colorKey: 'severityRed',
    label: 'DEFECT_STATUS_COLORS.RED',
    active: true,
    customComponent: (className = '', size = 'sm', restProps) => <Icon name="exclamation-mark" danger size={size} handleHover={false} className={`def-icon ${className || ''}`} {...restProps} />,
  },
  {
    value: defectStatus.actioned,
    field: filterParams.statusFilter,
    colorKey: 'severityOrange',
    label: 'DEFECT_STATUS_COLORS.ORANGE',
    active: true,
    customComponent: (className = '', size = 'sm', restProps) => <Icon name="exclamation-mark" warning className={`def-icon ${className || ''}`} handleHover={false} size={size} {...restProps} />,
  },
  {
    value: defectStatus.closed,
    field: filterParams.statusFilter,
    colorKey: 'severityGreen',
    label: 'DEFECT_STATUS_COLORS.GREEN',
    active: false,
    customComponent: (className = '', size = 'sm', active = true, restProps) => (
      <Icon name="checkmark" size={size} active={active} handleHover={false} className={`def-icon ${className || ''}`} {...restProps} />
    ),
  },
  {
    value: defectStatus.actioned,
    field: filterParams.linkedToMe,
    label: 'QUICK_FILTER.LINKED_TO_ME',
    active: false,
    customComponent: () => null,
  },
];

export const maxDefectUploadFileSize = {
  value: 50,
  unit: 'mb',
  bytes: 52428800,
};

export const maxDefectUploadFileCount = 10;

export const observationsTypes = {
  hazard: 'HAZARD',
  damage: 'DAMAGE',
  defect: 'DEFECT',
  accident: 'ACCIDENT',
  concern: 'CONCERN',
  nearMiss: 'NEAR-MISS',
};

export const observationsTypesIconsMap = {
  [observationsTypes.hazard]: 'observationHazard',
  [observationsTypes.damage]: 'observationDamage',
  [observationsTypes.defect]: 'observationDefect',
  [observationsTypes.accident]: 'observationAccident',
  [observationsTypes.concern]: 'observationConcern',
  [observationsTypes.nearMiss]: 'observationNearMiss',
};

export const observationsTypesTranslationsMap = {
  [observationsTypes.hazard]: 'DEFECT_TOOLS.HAZARD',
  [observationsTypes.damage]: 'DEFECT_TOOLS.DAMAGE',
  [observationsTypes.defect]: 'DEFECT_TOOLS.DEFECT',
  [observationsTypes.accident]: 'DEFECT_TOOLS.ACCIDENT',
  [observationsTypes.concern]: 'DEFECT_TOOLS.CONCERN',
  [observationsTypes.nearMiss]: 'DEFECT_TOOLS.NEAR_MISS',
};

export const observationTools = [
  {
    id: '1',
    icon: ({ size = 'xs', ...restProps }) => {
      return <Icon name="observationHazard" handleHover={false} size={size} {...restProps} />;
    },
    title: 'DEFECT_TOOLS.HAZARD',
    action: observationsTypes.hazard,
    customMenuItemClassName: 'child-svg-mr-10',
  },
  {
    id: '2',
    icon: ({ size = 'xs', ...restProps }) => <Icon name="observationDamage" size={size} handleHover={false} {...restProps} />,
    title: 'DEFECT_TOOLS.DAMAGE',
    action: observationsTypes.damage,
    customMenuItemClassName: 'child-svg-mr-10',
  },
  {
    id: '3',
    icon: ({ size = 'xs', ...restProps }) => <Icon name="observationDefect" size={size} handleHover={false} {...restProps} />,
    title: 'DEFECT_TOOLS.DEFECT',
    action: observationsTypes.defect,
    customMenuItemClassName: 'child-svg-mr-10',
  },
  {
    id: '4',
    icon: ({ size = 'xs', ...restProps }) => <Icon name="observationAccident" size={size} handleHover={false} {...restProps} />,
    title: 'DEFECT_TOOLS.ACCIDENT',
    action: observationsTypes.accident,
    customMenuItemClassName: 'child-svg-mr-10',
  },
  {
    id: '5',
    icon: ({ size = 'xs', ...restProps }) => {
      return <Icon name="observationConcern" size={size} handleHover={false} {...restProps} />;
    },
    title: 'DEFECT_TOOLS.CONCERN',
    action: observationsTypes.concern,
    customMenuItemClassName: 'child-svg-mr-10',
  },
  {
    id: '6',
    icon: ({ size = 'xs', ...restProps }) => <Icon name="observationNearMiss" size={size} handleHover={false} {...restProps} />,
    title: 'DEFECT_TOOLS.NEAR_MISS',
    action: observationsTypes.nearMiss,
    customMenuItemClassName: 'child-svg-mr-10',
  },
];

export const tableConfigTypes = {
  nameLite: 'nameLite',
  name: 'name',
  severityLite: 'severityLite',
  severity: 'severity',
  statusCustomLite: 'statusCustomLite',
  statusCustom: 'statusCustom',
  iconsCustom: 'iconsCustom',
  iconsCustomLite: 'iconsCustomLite',
  mainTypeLite: 'mainTypeLite',
  date: 'createdDate',
  dateLite: 'createdDateLite',
  descriptionLite: 'descriptionLite',
  recommendationLite: 'recommendationLite',
};

export const tableConfig = [
  {
    title: '',
    key: formConstants.fields.iconsCustom,
    enableSort: false,
    className: 'inline-left',
    type: tableConfigTypes.iconsCustom,
  },
  {
    title: 'OBSERVATIONS.NAME',
    key: formConstants.fields.name,
    enableSort: true,
    className: 'inline-left',
    type: tableConfigTypes.name,
  },
  {
    title: 'OBSERVATIONS.SEVERITY',
    key: formConstants.fields.severity,
    enableSort: true,
    type: tableConfigTypes.severity,
  },
  {
    title: 'OBSERVATIONS.DATE_CREATED',
    key: formConstants.fields.date,
    type: tableConfigTypes.date,
    enableSort: true,
  },
  {
    key: formConstants.fields.statusCustom,
    type: tableConfigTypes.statusCustom,
    className: 'inline-right',
  },
];

export const tableConfigLite = [
  {
    title: 'OBSERVATIONS.ID',
    key: formConstants.fields.id,
    enableSort: true,
  },
  {
    title: 'OBSERVATIONS.NAME_LITE',
    key: formConstants.fields.name,
    enableSort: true,
    className: 'inline-left',
    class: 'big',
    type: tableConfigTypes.nameLite,
  },
  {
    key: formConstants.fields.iconsCustom,
    type: tableConfigTypes.iconsCustomLite,
    className: 'inline-left',
  },
  {
    title: 'OBSERVATIONS.STATUS',
    key: formConstants.fields.status,
    enableSort: true,
    class: 'medium',
    type: tableConfigTypes.statusCustomLite,
    className: 'inline-left',
  },
  {
    title: 'OBSERVATIONS.MAIN_TYPE',
    key: formConstants.fields.mainType,
    enableSort: true,
    class: 'medium',
    className: 'inline-left',
    type: tableConfigTypes.mainTypeLite,
  },
  {
    title: 'OBSERVATIONS.SEVERITY_LIGHT',
    key: formConstants.fields.severity,
    enableSort: true,
    // class: 'medium',
    // className: 'inline-left',
    type: tableConfigTypes.severityLite,
  },
  {
    title: 'OBSERVATIONS.SUB_TYPE',
    key: formConstants.fields.type,
    enableSort: true,
    className: 'inline-left',
  },
  {
    title: 'OBSERVATIONS.DATE_CREATED_LITE',
    key: formConstants.fields.date,
    enableSort: true,
    class: 'big',
    className: 'inline-left',
    type: tableConfigTypes.dateLite,
  },
  {
    title: 'OBSERVATIONS.DESCRIPTION',
    key: formConstants.fields.description,
    class: 'large',
    className: 'inline-left',
    type: tableConfigTypes.descriptionLite,
  },
  {
    title: 'OBSERVATIONS.RECOMMENDATION',
    key: formConstants.fields.recommendation,
    class: 'large',
    className: 'inline-left',
    type: tableConfigTypes.recommendationLite,
  },
  {
    title: 'OBSERVATIONS.NOTIFICATIONS_COUNT',
    key: formConstants.fields.notificationsCount,
    enableSort: true,
    className: 'inline-left',
    type: formConstants.fields.notificationsCount,
  },
];

export const otherImagesSupportedTypes = 'image/jpeg, image/png, image/svg+xml, image/tiff, image/gif';
export const otherFilesSupportedTypes = ''; // Just an empty string for now since it is all other files
export const downloadReportLimitation = 50;

export const historyItems = {
  Name: {
    title: 'DEFECT_HISTORY.NAME',
  },
  Component: {
    title: 'DEFECT_HISTORY.COMPONENT',
    component: HistoryItemComponentChange,
  },
  MainType: {
    title: 'DEFECT_HISTORY.MAIN_TYPE',
    component: HistoryItemDefectType,
  },
  DefectType: {
    title: 'DEFECT_HISTORY.DEFECT_TYPE',
  },
  Severity: {
    title: 'DEFECT_HISTORY.SEVERITY',
  },
  Recommendation: {
    title: 'DEFECT_HISTORY.RECOMMENDATION',
  },
  Description: {
    title: 'DEFECT_HISTORY.DESCRIPTION',
  },
  Property: {
    isCustomProp: true,
    nonTranslatableTitle: true,
  },
  Files: {
    title: 'DEFECT_HISTORY.FILE',
    isFile: true,
    component: HistoryItemFileDisplay,
  },
  Images: {
    title: 'DEFECT_HISTORY.IMAGES',
    isFile: true,
    component: HistoryItemFileDisplay,
  },
  Status: {
    title: 'DEFECT_HISTORY.STATUS',
    component: HistoryItemDefectStatus,
  },
  Geometry: {
    subProp: 'coordinates',
    title: 'DEFECT_HISTORY.GEOMETRY',
    decimals: 2,
  },
  CameraPosition: {
    title: 'DEFECT_HISTORY.CAMERA_POSITION',
    decimals: 2,
  },
  Message: {
    title: 'DEFECT_HISTORY.DEFECT_MESSAGE',
    subProp: 'Message',
  },
};

export const defectModificationEvents = {
  defect_created: {
    title: 'DEFECT_HISTORY.CREATED_EVENT',
    event: 'defect_created',
  },
  defect_updated: {
    title: 'DEFECT_HISTORY.UPDATED_EVENT',
    event: 'defect_updated',
  },
  defect_component_linked: {
    title: 'DEFECT_HISTORY.DEFECT_COMPONENT_LINKED_EVENT',
    event: 'defect_component_linked',
  },
  defect_custom_property_added: {
    title: 'DEFECT_HISTORY.DEFECT_CUSTOM_PROPERTY_ADDED_EVENT',
    event: 'defect_custom_property_added',
  },
  defect_custom_property_updated: {
    title: 'DEFECT_HISTORY.DEFECT_CUSTOM_PROPERTY_UPDATED_EVENT',
    event: 'defect_custom_property_updated',
  },
  defect_custom_property_deleted: {
    title: 'DEFECT_HISTORY.DEFECT_CUSTOM_PROPERTY_DELETED_EVENT',
    event: 'defect_custom_property_deleted',
    titleClass: 'f-secondary-red',
  },
  defect_file_unlinked: {
    title: 'DEFECT_HISTORY.FILE_DELETED',
    event: 'defect_file_unlinked',
    titleClass: 'f-secondary-red',
  },
  defect_file_linked: {
    title: 'DEFECT_HISTORY.FILEU_PLOADED',
    event: 'defect_file_unlinked',
  },
  defect_deleted: {
    title: 'DEFECT_HISTORY.DELETE_EVENT',
    event: 'defect_deleted',
    titleClass: 'f-secondary-red',
  },
  defect_position_updated: {
    title: 'DEFECT_HISTORY.UPDATED_EVENT',
    event: 'defect_position_updated',
  },
  defect_image_linked: {
    title: 'DEFECT_HISTORY.DEFECT_IMAGE_LINKED',
    event: 'defect_position_updated',
  },
  defect_message_added: {
    title: 'DEFECT_HISTORY.DEFECT_MESSAGE_POSTED',
    event: 'defect_message_added',
  },
};

export const historyFilterParams = {
  lastSeen: 'LastSeen',
  perPage: 'PerPage',
  sortByColumn: 'ModifiedAt',
  sortDirection: 'SortDirection',
  totalItems: 'TotalNumber',
  hasNext: 'HasNext',
};

export const historyDefaultPagingObj = {
  [historyFilterParams.lastSeen]: 0,
  [historyFilterParams.perPage]: 20,
  [historyFilterParams.totalItems]: 0,
  [historyFilterParams.hasNext]: false,
};

export const assigneeTypes = {
  team: 'TEAM',
  user: 'USER',
};

export const collaboratorTypes = {
  team: 'TEAM',
  user: 'USER',
};

export const assigneeFields = {
  id: 'ID',
  name: 'Name',
  type: 'Type',
  participantType: 'ParticipantType',
  assigneeType: 'AssigneeType',
};

export const collaboratorFields = {
  id: 'ID',
  name: 'Name',
  type: 'Type',
  participantType: 'ParticipantType',
  assigneeType: 'AssigneeType',
};

export const sortDirection = {
  asc: 'ASC',
  desc: 'DESC',
};

export const defaultDefectComponentsFilters = {
  [filterParams.sortByColumn]: formConstants.fields.id,
  [filterParams.sortDirection]: sortDirection.desc,
  [filterParams.perPage]: 20,
  [filterParams.lastSeen]: 0,
  [filterParams.searchText]: '',
  [filterParams.hasNext]: false,
};

export const severityDefault = {
  filter: { min: 0, max: 10 },
  value: { min: 1, max: 10 },
};
