import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../../../common/form/components/button';
import SimpleLoader from '../../../../common/global-loader/components/simple-loader';
import Equipment from './equipment';

import '../styles/sync-modal.scss';

class SyncModal extends Component {
  componentDidMount() {
    const { getEquipment } = this.props;

    getEquipment();
  }

  renderEquipment = () => {
    const { t } = this.context;
    const { handleSubmit, equipment, collapseEquipmentItem, selectEquipmentItem, fetchingEquipment } = this.props;

    if (fetchingEquipment) {
      return (
        <div className="loader-wrapper">
          <SimpleLoader isLoading={true} width={80} height={80} className="equipment-loader" customText={t('INSPECTION_NOTIFICATIONS.LOADING_TEXT')} />
        </div>
      );
    }

    return (
      <Fragment>
        <div className="sync-modal__list">
          <Equipment equipment={equipment} collapseEquipmentItem={id => collapseEquipmentItem(id, equipment)} selectEquipmentItem={id => selectEquipmentItem(id, equipment)} />
        </div>
        <Button type="button" className="sync-modal__button" text={t('INTEGRATIONS.SAP.SYNC_MODAL.SUBMIT_BUTTON')} onClick={() => handleSubmit(equipment)} />
      </Fragment>
    );
  };

  render() {
    const { t } = this.context;

    return (
      <div className="sync-modal">
        <p className="sync-modal__description">{t('INTEGRATIONS.SAP.SYNC_MODAL.DESCRIPTION')}</p>
        {this.renderEquipment()}
      </div>
    );
  }
}

SyncModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  equipment: state.sapReducer.equipment,
  fetchingEquipment: state.sapReducer.fetchingEquipment,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SyncModal);
