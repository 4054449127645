import PropTypes from 'prop-types';
import React from 'react';
import TableTemplate from '../../../../common/double-table/components/table-template';
import EmptyState from '../../../../common/empty-state-v2/components/empty-state';
import Helpers from '../../../../common/helpers';
import Icon from '../../../../common/icon/components/icon';
import RenderIf from '../../../../common/render-if/components/render-if';
import InfoTooltip from '../../../../common/tooltip/components/info-tooltip';
import { modules } from '../../constants/constants';
import { isDefaultComponent } from '../../helpers/inspection-helper';
import '../../styles/work-order-section-components.scss';
import { componentDetailsDisplayProps, componentsTableConfig } from './constants/constants';
import { fields } from './constants/work-order-right-side-constants';

const WorkOrderSectionComponents = ({ module, handleCustomAction, sectionAction, disabled, listWithDeeplink, defaultComponentId, ...rest }, { t }) => {
  const { onModuleItemDeepLinkHandler } = module;

  // listWithDeeplink flag toggles if the UI format with the deeplinks (card-style) should be displayed
  // decided to go with the flag approach so as not to introduce a new component, but rather we can re-use this one as we need for both cases
  if (listWithDeeplink) {
    return (
      <div className="work-order-section-components">
        <RenderIf if={module && module.workOrderComponents?.length > 0}>
          <div className="notification-WO-tab__table">
            <TableTemplate
              data={module.workOrderComponents}
              handleFormatCells={(value, type, _searchTerm, row) => {
                if (isDefaultComponent(row, defaultComponentId)) {
                  return (
                    <div className="disabled">
                      {value}
                      <InfoTooltip
                        offsetY={8}
                        offsetX={8}
                        customComponent={<span>{t('DEFAULT_COMPONENT.DEEP_LINK_LABEL')}</span>}
                        Component={() => <Icon name="info" size="sm" />}
                        componentProps={{ title: '' }}
                        containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
                      />{' '}
                    </div>
                  );
                } else if (value !== '' && type !== fields.critical) return value;
                else if (type === fields.critical) {
                  return (
                    <>
                      {value ? (
                        <InfoTooltip
                          className="critical-tooltip"
                          offsetY={6}
                          offsetX={6}
                          customComponent={<span className="f-primary">Critical Equipment</span>}
                          Component={() => <Icon name="critical-hazard" handleHover={false} size="sm" />}
                          componentProps={{ title: '' }}
                          containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
                        />
                      ) : null}
                    </>
                  );
                } else return '-';
              }}
              showSearchBar={false}
              tableConfig={componentsTableConfig}
              showInfo={false}
              stickyHeader={false}
              onRowClick={row => (isDefaultComponent(row, defaultComponentId) ? () => null : onModuleItemDeepLinkHandler(row, modules.components.toLowerCase()))}
              noDataText={t('WORK_ORDERS.NO_COMPONENTS')}
            />
          </div>
        </RenderIf>
        <RenderIf if={(module && !module.workOrderComponents) || module.workOrderComponents.length === 0}>
          <EmptyState
            buttonAction={() => handleCustomAction(sectionAction)}
            buttonText={t('WORK_ORDER.ADD_COMPONENTS')}
            transparent
            emptyStateText={t('NO_EQUIPMENT_EMPTY_STATE')}
            buttonDisabled={disabled}
            showButton={!disabled}
          />
        </RenderIf>
      </div>
    );
  } else {
    return (
      <div className="work-order-section-components">
        {module?.workOrderComponents.map(item => (
          <div className="component-item" key={`work-order-component-${item[fields.id]}`}>
            <p className="f-primary light-bold component-name">{item[fields.name]}</p>
            <InfoTooltip
              actionsMenu={Helpers.mapInfoIconDisplayProps(item, componentDetailsDisplayProps)}
              offsetY={8}
              offsetX={8}
              Component={() => <Icon name="info" className="info-tooltip-icon" size="sm" />}
              componentProps={{ title: '' }}
            />
          </div>
        ))}
      </div>
    );
  }
};

WorkOrderSectionComponents.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default WorkOrderSectionComponents;
