import routesConstants from './routes';
import { Api } from '../../core/api';
import { API_BASE_URL } from '../../common/constants';

const requestResetPassword = async values => {
  return await Api.post(`${API_BASE_URL}${routesConstants.REQUEST_RESET_PASSWORD}`, values, { needLoader: true });
};

const resetPassword = async values => {
  return await Api.post(`${API_BASE_URL}${routesConstants.RESET_PASSWORD}`, values, { needLoader: true });
};

const checkSetTokenPassword = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CHECK_SET_PASSWORD_TOKEN}`, data, { needLoader: true });
};

export default {
  requestResetPassword,
  resetPassword,
  checkSetTokenPassword,
};
