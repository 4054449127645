import { debounce, find, isEmpty, map, pick, uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import Loader from '../../../../common/global-loader/components/simple-loader';
import Modal from '../../../../common/modal/components/modal';
import NotificationForm from './notification-form';

import { FEATURES, FORMS } from '../../../../common/constants';
import {
  closeInspectionModal,
  deleteNotificationProperty,
  fetchCommentUsersAndTeams,
  getDefectDetails,
  getNotificationCustomProperties,
  getNotificationCustomPropertyDependencies,
  getNotificationPropertyNames,
  maximizeInspectionModal,
  minimizeInspectionModal,
  openAsSeparatePageInspectionModal,
  toggleInspectionModal,
} from '../../actions/inspection-actions';
import { addNotificationComment, deleteNotificationComment, fetchNotificationComments, removeNotification, updateNotification, updateNotificationProperties } from '../../actions/notification-actions';
import { getComponentsByNotificationIds } from '../../actions/work-order-actions';

import Helpers from '../../../../common/helpers';
import { routes } from '../../../../common/routes-constants';
import {
  fetchNotificationPropertiesSuccess,
  handleCreateWorkOrderModal,
  handleDeleteNotificationModal,
  setElementDetails,
  setNotificationFormState,
  setUnsavedChangesDirty,
} from '../../actions/action-creators';
import { detailsPages, measurementTypes, modules } from '../../constants/constants';
import { formConstants, sourceTypes, tabNames, toolbarItems } from '../../constants/notification-constants';

import { withRouter } from 'react-router';
import { destroy } from 'redux-form';
import { ReactComponent as PDFIcon } from '../../../../common/assets/pdf-icon.svg';
import CommentsTab from '../../../../common/comments-tab/components/comments-tab';
import { commentFields } from '../../../../common/comments-tab/constants/constants';
import DrawingHelpers from '../../../../common/drawing-helpers';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../common/permissions-constants';
import RenderIf from '../../../../common/render-if/components/render-if';
import Tab from '../../../../common/tabs/component/tab';
import Tabs from '../../../../common/tabs/component/tabs';
import { unlinkDMSFileFromNotification } from '../../../document-management/actions/dm-api-calls';
import { setPdfComponents } from '../../../pdf-tag/actions/action-creators';
import { changePDFPage, getPdfComponentsForPopup } from '../../../pdf-tag/actions/pdf-tag-actions';
import { componentFields, componentFilterValues } from '../../../pdf-tag/constants/constants';
import LinkedImagesSliderSlider from '../../../start-workflow/components/linked-images-slider';
import orientationConstants from '../../../start-workflow/constants/orientation-constants';
import { setUploadItems } from '../../../upload/actions/upload-actions';
import { defaultComponentName, preventedFieldsPriorComponentIsCreated } from '../../constants/component-constants';
import { notificationTypes } from '../../constants/notification-constants';
import '../../styles/notification-details.scss';
import { getNotificationDMSFilesUploaded } from '../notifications/actions/notifications-api-calls';
import { fields, statuses, updateNotificationPropertiesShouldTrigger } from '../notifications/constants/constants';
import { viewOptions } from '../readings-and-gauges/constants/constants';
import CreateWorkOrderModal from '../work-order/create-work-order-modal';
import CriticalEquipmentInfo from './common/critical-equipment-info';
import ModuleHeader from './common/module-header';
import ComponentPDF from './component-pdf';
import NotificationWorkOrdersTab from './notification-details/notifications-work-order-tab';

// TODO: rewrite to functional component ASAP so we can make usage of useMemo
class NotificationDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      relatedComponents: [],
      activeToolbarItem: toolbarItems[tabNames.details].name,
      commentsList: [],
      commentsLoading: false,
      addCommentLoading: false,
      commentUsersList: [],
      commentTeamsList: [],
      commentUsersAndTeamsLoading: false,
      modalData: { isOpen: false },
      elementAlreadyFocused: false,
      deleteFileModalData: {
        isOpen: false,
      },
    };
    this.formChangeDebounce = debounce(this.submitForm, 100);
  }

  componentWillUnmount() {
    this.formChangeDebounce.cancel();
  }

  componentDidMount = () => {
    const { queryItem } = this.props;

    if (!queryItem || queryItem < 0) {
      this.setState({ elementAlreadyFocused: true });
      return;
    }

    this.fetchData(tabNames.details);
  };

  componentDidUpdate = prevProps => {
    const { queryItem, viewer } = this.props;

    if ((queryItem && queryItem > 0 && prevProps.queryItem !== queryItem) || viewer !== prevProps.viewer) {
      this.fetchData(tabNames.details);
    }
  };

  fetchData = tabName => {
    const { elementAlreadyFocused } = this.state;
    const { getComponentsByNotificationIds, queryItem, getDefectDetails, setNotificationFormState, getNotificationDMSFilesUploaded, viewer, setUnsavedChangesDirty } = this.props;

    if (tabName === tabNames.details) {
      setUnsavedChangesDirty(false);
      setNotificationFormState({ requestInProgress: true });

      getDefectDetails(
        { ID: queryItem, SystemType: measurementTypes.notification },
        notification => {
          getNotificationDMSFilesUploaded(queryItem);
          // getComponentsByNotificationIds triggers ONLY if the User has Components:View permission (might be obsolete, since Components:View will be hardcoded to ON)
          getComponentsByNotificationIds([queryItem], data => this.setState({ relatedComponents: data || [] }));
          if (notification?.Geometry?.coordinates?.[0] && notification?.CameraPosition?.coordinates && viewer && !elementAlreadyFocused) {
            // we can use here Geometry and Camera position from notification object since it is freshly fetched but usually we need to use it from clustered elements, they are always up to date
            viewer.zoomToPosition(
              { x: notification.CameraPosition.coordinates[0], y: notification.CameraPosition.coordinates[1], z: notification.CameraPosition.coordinates[2] },
              notification.Geometry.coordinates,
              500
            );
            this.setState({ elementAlreadyFocused: true });
          }
        },
        true
      );
    }
    setNotificationFormState({ unsavedChanges: {}, hasUnsavedChanges: false, hasUnsavedCustomProps: false, unsavedCustomProps: [], requestInProgress: false });
    this.setState({ activeToolbarItem: tabName });
  };

  submitForm = values => {
    const { updateNotification, setSelectedNotification, setNotificationFormState, updateNotificationProperties, unsavedCustomProps, requestInProgress, setUnsavedChangesDirty } = this.props;
    if (requestInProgress) return;
    // The request in progress is set here so it prevents the user from sending multiple request
    // In case he decides to click 10 time save button
    // TODO: Look in to solution with debounce, can it be done without requestInProgress?
    setNotificationFormState({ requestInProgress: true });
    const preparedCustomProps = unsavedCustomProps.map(prop => {
      if (typeof prop[fields.id] === 'string') {
        delete prop[fields.id];
      }
      return prop;
    });
    const newValues = Object.assign({}, values);
    newValues[fields.createdAt] = Helpers.getUnixDate(new Date(values[fields.createdAt]).getTime());
    newValues[fields.actionedAt] = newValues[fields.actionedAt] === 0 ? newValues[fields.actionedAt] : Helpers.getUnixDate(new Date(values[fields.actionedAt]).getTime());
    newValues[fields.closedAt] = newValues[fields.closedAt] === 0 ? newValues[fields.closedAt] : Helpers.getUnixDate(new Date(values[fields.closedAt]).getTime());
    newValues[fields.updatedCustomProperties] = updateNotificationPropertiesShouldTrigger(preparedCustomProps);

    updateNotification(newValues, () => {
      setUnsavedChangesDirty(false);
      setNotificationFormState({ requestInProgress: false, unsavedChanges: {}, hasUnsavedChanges: false });

      updateNotificationProperties(preparedCustomProps, () => {
        setNotificationFormState({ hasUnsavedCustomProps: false, unsavedCustomProps: [] });
      });
      setSelectedNotification(newValues);
    });
  };

  handleChangeStatus = ({ value }) => {
    const { user, selectedNotification, unsavedChanges } = this.props;
    let statusChangeInformation = {};

    if (value === statuses.open.value) {
      statusChangeInformation = {
        [formConstants.fields.createdAt]: Helpers.getDateFromUnix(selectedNotification[formConstants.fields.createdAt]),
        [formConstants.fields.actionedAt]: 0,
        [formConstants.fields.actionedBy]: '',
        [formConstants.fields.closedAt]: 0,
        [formConstants.fields.closedBy]: '',
      };
    } else if (value === statuses.actioned.value) {
      statusChangeInformation = {
        [formConstants.fields.createdAt]: Helpers.getDateFromUnix(selectedNotification[formConstants.fields.createdAt]),
        [formConstants.fields.actionedAt]: Helpers.getDateFromUnixMs(Date.now()),
        [formConstants.fields.actionedBy]: user[formConstants.fields.name],
        [formConstants.fields.closedAt]: 0,
        [formConstants.fields.closedBy]: '',
      };
    } else if (value === statuses.closed.value) {
      statusChangeInformation = {
        [formConstants.fields.createdAt]: Helpers.getDateFromUnix(selectedNotification[formConstants.fields.createdAt]),
        [formConstants.fields.actionedAt]: Helpers.getDateFromUnix(selectedNotification[formConstants.fields.actionedAt]),
        [formConstants.fields.closedAt]: Helpers.getDateFromUnixMs(Date.now()),
        [formConstants.fields.closedBy]: user[formConstants.fields.name],
      };
      if (selectedNotification[formConstants.fields.status] === statuses.open.value) {
        statusChangeInformation = {
          ...statusChangeInformation,
          [formConstants.fields.actionedAt]: 0,
          [formConstants.fields.actionedBy]: '',
        };
      }
    }
    const updatedNotification = { ...selectedNotification, ...unsavedChanges, [formConstants.fields.status]: value, ...statusChangeInformation };
    this.submitForm(updatedNotification);
  };

  fetchNotificationCommentsHandler = (_scrollIntoCallbackEnabled = true) => {
    const { fetchNotificationComments, queryItem } = this.props;

    const onSuccessFetch = newState => {
      this.setState({ commentsList: newState.commentsList, commentsLoading: newState.commentsLoading });

      if (newState.commentsList) {
        Helpers.scrollIntoView('comments-list-wrapper', `comment-${newState.commentsList.length - 1}`, 0);
      }
    };

    fetchNotificationComments({ [commentFields.notificationId]: queryItem }, onSuccessFetch);
  };

  addNotificationCommentHandler = (commentValue, commentTags, resetCommentInput, scrollIntoView) => {
    const { addNotificationComment, queryItem, inspectionId, projectId } = this.props;
    const commentParams = { InspectionID: inspectionId, ProjectID: projectId, [commentFields.tags]: commentTags, [commentFields.moduleItemID]: queryItem, Comment: commentValue };
    addNotificationComment(
      commentParams,
      () => this.fetchNotificationCommentsHandler(),
      loading => this.setState({ addCommentLoading: loading })
    );
    resetCommentInput();
    scrollIntoView();
  };

  deleteNotificationCommentHandler = comment => {
    const { deleteNotificationComment, queryItem } = this.props;
    const commentParams = { [commentFields.moduleCommentID]: queryItem, CommentID: comment[commentFields.id] };
    deleteNotificationComment(
      commentParams,
      () => this.fetchNotificationCommentsHandler(false),
      loading => this.setState({ addCommentLoading: loading })
    );
  };

  searchUserAndTeamsHandler = searchTerm => {
    const { fetchCommentUsersAndTeams } = this.props;
    fetchCommentUsersAndTeams(
      searchTerm,
      (usersList, teamsList) => this.setState({ commentUsersList: usersList, commentTeamsList: teamsList }),
      loading =>
        this.setState({
          commentUsersAndTeamsLoading: loading,
        })
    );
  };
  openDeleteNotificationModal = () => {
    const { t } = this.context;
    const { toggleDeleteModal, handleActivePage, deleteNotification, selectedNotification } = this.props;
    const closeModal = () => toggleDeleteModal({ isOpen: false });
    const modalData = {
      isOpen: true,
      content: t('DELETE_NOTIFICATION_MODAL.DESC', { notificationName: `#${selectedNotification[formConstants.fields.id]} ${selectedNotification[formConstants.fields.name]}` }),
      type: 'yes-no',
      confirmAction: () => {
        deleteNotification(selectedNotification, () => handleActivePage(modules.notifications));
        closeModal();
      },
      closeAction: closeModal,
    };
    toggleDeleteModal(modalData);
  };

  openWorkOrderCreationModal = (notificationType, notificationName) => {
    const { t } = this.context;
    const { handleCreateWorkOrderModal, inspectionId, destroyForm, selectedNotification } = this.props;
    let modalData;
    const closeAction = () => {
      destroyForm(FORMS.createWorkorderForm);
      modalData = { isOpen: false };
      handleCreateWorkOrderModal(modalData);
    };
    modalData = {
      isOpen: true,
      title: t('CREATE_WORK_ORDER_MODAL.TITLE'),
      CustomContent: dynamicProps => <CreateWorkOrderModal {...dynamicProps} inspectionId={inspectionId} closeAction={closeAction} createWOFromNotification />,
      customClassName: 'modal-large',
      type: 'none',
      notificationType: selectedNotification[formConstants.fields.type],
      selectedNotification: selectedNotification,
      startDate:
        selectedNotification[formConstants.fields.type] && notificationTypes.preventative === selectedNotification[formConstants.fields.type]
          ? selectedNotification[formConstants.fields.startedAt]
          : null,
      closeAction,
    };

    handleCreateWorkOrderModal(modalData);
  };

  onFormChange = (values, _b, c) => {
    const { setNotificationFormState, setUnsavedChangesDirty } = this.props;
    setNotificationFormState({ hasUnsavedChanges: c.dirty });
    setUnsavedChangesDirty(c.dirty);
    if (c.dirty) {
      const newValues = pick(values, [[fields.name], [fields.severity]]);
      setNotificationFormState({ unsavedChanges: newValues });
    }
  };

  handleChangeCameraUpdate = notification => {
    const { setSelectedNotification, unsavedChanges, formHasUnsavedChanges } = this.props;
    let notificationToBeSaved = {};
    if (formHasUnsavedChanges) {
      notificationToBeSaved = { ...notification, ...unsavedChanges };
    } else {
      notificationToBeSaved = notification;
    }
    setSelectedNotification(notificationToBeSaved);
  };

  handleCustomPropertyUpdate = property => {
    const { unsavedCustomProps, setNotificationFormState } = this.props;
    const newUnsavedCustomProps = unsavedCustomProps;
    const positionOfProperty = newUnsavedCustomProps.findIndex(prop => prop[fields.id] === property[fields.id]);
    if (property[fields.hasDependencies]) {
      const { getNotificationCustomPropertyDependencies } = this.props;
      const { NotificationID, Name, Value } = property;
      getNotificationCustomPropertyDependencies(NotificationID, Value, Name, this.handleDependencyUpdate);
    }
    if (newUnsavedCustomProps[positionOfProperty]) {
      newUnsavedCustomProps[positionOfProperty] = property;
    } else {
      newUnsavedCustomProps.push(property);
    }
    setNotificationFormState({ unsavedCustomProps: newUnsavedCustomProps, hasUnsavedCustomProps: true });
  };

  handleDependencyUpdate = data => {
    const { setNotificationProperties, properties, setNotificationFormState, unsavedCustomProps, queryItem } = this.props;
    const dependentPropsUpdated = properties.map(prop => {
      const dependentPropIndex = data.findIndex(dependentProp => dependentProp[fields.id] === prop[fields.id]);

      if (dependentPropIndex < 0) return prop;
      else {
        // add the dependant value to unsaved custom prop so it can be updated also
        // filter it by is since there might be already a change for that custom prop
        setNotificationFormState({ unsavedCustomProps: uniqBy([{ ...data[dependentPropIndex], [fields.notificationID]: queryItem }, ...unsavedCustomProps], fields.id) });
        return data[dependentPropIndex];
      }
    });
    setNotificationProperties(dependentPropsUpdated);
  };

  // REDUNDANCY for handling opening PDF file inside module START
  // maybe implement it as hook, and just handle state from component to hook and vice versa
  openImagesModal = (selectedImage, linkedImages) => {
    const { setOrientation, selectedNotification, user } = this.props;
    let index = 0;
    linkedImages = map(linkedImages, (item, i) => {
      item.src = Helpers.getUploadImageSource(item.FileName, item.URL);
      if (selectedImage.FileID === item.FileID) {
        index = i;
      }
      return item;
    });

    this.setState(prevState => ({
      modalData: {
        ...prevState.modalData,
        customClassName: 'linked-images-slider',
        CustomContent: () => (
          <LinkedImagesSliderSlider
            linkedImages={linkedImages}
            currentSlideIndex={index}
            setOrientation={setOrientation}
            disabledOrientations={[orientationConstants.orientation.chart]}
            isPreviewModal={true}
            openDeleteFileModal={this.openDeleteFileModal}
            hasUnlinkAccess={{
              user,
              id: selectedNotification[formConstants.fields.createdByID],
              ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.notifications].create.name,
              visibleFor: [PERMISSIONS[PERMISSION_TYPES.notifications].edit.name],
            }}
          />
        ),
        isOpen: true,
        type: 'none',
        closeAction: () => this.setState({ modalData: { isOpen: false } }),
      },
    }));
  };

  selectComponentFromDrawing = (clickedObj, elementAlreadyFocused) => {
    const { components, activeTab, handleActivePage } = this.props;
    const comp = DrawingHelpers.findComponentByDrawingGuid(components, clickedObj);

    if (isEmpty(comp) || activeTab !== modules.notifications) {
      // if object is empty or active tab is not component
      return;
    }

    //redirect
    this.setState({
      elementAlreadyFocused: false,
    });
    handleActivePage(modules.defects, comp.ID);
  };

  // redundant logic! refactor this ASAP (defect-details, component-details, and here)
  openFile = (file, imageFiles) => {
    const { t } = this.context;
    const { elementAlreadyFocused } = this.state;
    const {
      inspectionId,
      selectedNotification,
      isPdfScreen,
      getPdfComponentsForPopup,
      setPdfComponents,
      toggleInspectionModal,
      inspectionModalData,
      closeInspectionModal,
      minimizeInspectionModal,
      maximizeInspectionModal,
      changePDFPage,
      openAsSeparatePageInspectionModal,
    } = this.props;
    if (file.isPDF || Helpers.isPDF(file[fields.fileName] || file[fields.name] || file.name, file[fields.fileType])) {
      getPdfComponentsForPopup(
        inspectionId,
        file.FileID,
        // sets default filters and pageNumber to 1
        {
          SearchText: '',
          ComponentFilter: componentFilterValues.all,
        },
        1,
        selectedNotification[formConstants.fields.id],
        d => {
          const confirmedDrawings = (d || []).filter(cDrawing => cDrawing[componentFields.confirmed]);
          setPdfComponents(confirmedDrawings);
          // open regular modal for tools since inspectionModal works only on MODULES level, but not on Tools modules
          // to check if we are on Tools part of the app we use isPdfScreen property
          // TODO: figure out if isPdfScreen is needed
          if (isPdfScreen) {
            this.setState(prevProps => ({
              ...prevProps.modalData,
              isOpen: true,
              title: t('PDF_VIEWER.MODAL.TITLE'),
              customClassName: 'modal-large',
              closeAction: () => {
                setPdfComponents([]);
                this.setState({ modalData: { isOpen: false } });
              },
              CustomContent: props => (
                <ComponentPDF
                  file={file}
                  selectComponent={clickedObj => this.selectComponentFromDrawing(clickedObj, elementAlreadyFocused)}
                  rightCollapsed={true}
                  inspectionId={inspectionId}
                  // when PDF opened in popup from module details or DMS, we show only confirmed tags/drawings
                  showOnlyConfirmedDrawings={true}
                  {...props}
                />
              ),
            }));
          } else {
            toggleInspectionModal({
              ...inspectionModalData,
              isOpen: true,
              isMinimized: false,
              FileIcon: props => <PDFIcon height={18} {...props} />,
              fileName: file.name,
              title: t('PDF_VIEWER.MODAL.TITLE'),
              customClassName: 'modal-large',
              closeAction: closeInspectionModal,
              minimizeAction: () =>
                changePDFPage(
                  1, // pdfPageNumber set to 1 because component-pdf on unmount sets pdfPageNumber to 1 in reducer
                  inspectionId,
                  file.FileID,
                  {
                    SearchText: '',
                    ComponentFilter: componentFilterValues.all,
                  },
                  null,
                  true,
                  () => {
                    minimizeInspectionModal();
                  }
                ),
              maximizeAction: () =>
                changePDFPage(
                  1, // pdfPageNumber set to 1 because component-pdf on unmount sets pdfPageNumber to 1 in reducer
                  inspectionId,
                  file.FileID,
                  {
                    SearchText: '',
                    ComponentFilter: componentFilterValues.all,
                  },
                  null,
                  true,
                  () => {
                    maximizeInspectionModal();
                  }
                ),
              separatePageAction: () =>
                changePDFPage(
                  1, // pdfPageNumber set to 1 because component-pdf on unmount sets pdfPageNumber to 1 in reducer
                  inspectionId,
                  file.FileID,
                  {
                    SearchText: '',
                    ComponentFilter: componentFilterValues.all,
                  },
                  null,
                  true,
                  () => {
                    openAsSeparatePageInspectionModal();
                  }
                ),
              CustomContent: props => (
                <ComponentPDF
                  file={file}
                  selectComponent={clickedObj => this.selectComponentFromDrawing(clickedObj, elementAlreadyFocused)}
                  rightCollapsed={true}
                  // when // when PDF opened in popup from module details or DMS, we show only confirmed tags/drawings
                  showOnlyConfirmedDrawings={true}
                  {...props}
                />
              ),
            });
          }
        }
      );
    } else if (file.isImage || Helpers.isImage(file[fields.fileName] || file[fields.name] || file.name, file[fields.fileType])) {
      const images = isEmpty(imageFiles) ? [file] : imageFiles;
      this.openImagesModal(file, images);
    } else {
      Helpers.getFileExtensionAndDownload(file);
    }
  };

  handleInputDisabled = type => {
    let content = '';

    switch (type) {
      case preventedFieldsPriorComponentIsCreated.upload:
        content = 'CREATE_COMPONENT.FILL_OTHER_FIELDS';
        break;
      case preventedFieldsPriorComponentIsCreated.customFields:
        content = 'CREATE_COMPONENT.FILL_OTHER_FIELDS_CUSTOM_PROP';
        break;
      default:
        content = 'CREATE_COMPONENT.FILL_OTHER_FIELDS_CUSTOM_PROP';
        break;
    }

    const { t } = this.context;
    this.setState(prevProps => ({
      modalData: {
        ...prevProps.modalData,
        isOpen: true,
        content: t(content),
        type: 'ok',
        closeAction: this.closeUploadModal,
        confirmAction: this.closeUploadModal,
      },
    }));
  };

  closeUploadModal = () => {
    const { setUploadItems } = this.props;
    setUploadItems(null);
    this.getUploadedFiles();
    this.setState(prevProps => ({
      modalData: {
        ...prevProps.modalData,
        isOpen: false,
      },
    }));
  };

  getUploadedFiles = () => {
    const { getNotificationDMSFilesUploaded, queryItem } = this.props;
    if (queryItem && queryItem > 0) {
      getNotificationDMSFilesUploaded(queryItem);
    }
  };

  // REDUNDANCY for handling opening PDF file inside module END

  openDeleteFileModal = (fileID, fileName, categoryID) => {
    const { t } = this.context;
    const { queryItem, getNotificationDMSFilesUploaded, unlinkDMSFileFromNotification } = this.props;

    const deleteFileModalData = {
      isOpen: true,
      content: t('COMPONENT_DETAILS.DELETE_FILE_DESC', { fileName }),
      title: t('COMPONENT_DETAILS.DELETE_FILE_TITLE'),
      type: 'yes-no',
      confirmAction: () =>
        unlinkDMSFileFromNotification(
          fileID,
          queryItem,
          categoryID,
          () => getNotificationDMSFilesUploaded(queryItem),
          () => this.setState({ deleteFileModalData: { isOpen: false }, modalData: { isOpen: false } })
        ),
      closeAction: () => this.setState({ deleteFileModalData: { isOpen: false } }),
      customClassName: 'modal-small',
    };

    this.setState({ deleteFileModalData });
  };

  render() {
    const { activeToolbarItem, relatedComponents, commentsList, commentsLoading, addCommentLoading, commentUsersList, commentTeamsList, commentUsersAndTeamsLoading, modalData, deleteFileModalData } =
      this.state;
    const {
      user,
      severityColors,
      generalStatus,
      deleteNotificationModalData,
      deleteNotification,
      toggleDeleteModal,
      handleCreateWorkOrderModal,
      inspectionId,
      notificationType,
      notificationName,
      handleActivePage,
      sourceType,
      projectId,
      notificationDetailsLoading,
      properties,
      getProperties,
      deleteProperty,
      getSuggestions,
      queryItem,
      showGeometryWarning,
      changeField,
      objectToolClick,
      selectedNotification,
      formHasUnsavedChanges,
      formHasUnsavedCustomProperties,
      notificationFiles,
      inspectionDetails,
      toggleElement,
      notificationsClustered,
    } = this.props;

    const isSaveButtonEnabled = formHasUnsavedChanges || formHasUnsavedCustomProperties;

    const menuOptions = [
      {
        title: 'NOTIFICATION_DETAILS.FORM_DELETE',
        action: () => this.openDeleteNotificationModal(),
        access: {
          visibleFor: PERMISSIONS[PERMISSION_TYPES.notifications].delete.name,
        },
        isHighlighted: true,
      },
    ];

    // TODO: add loading of relatedComponents to show Loader
    const containsCriticalEquipment = relatedComponents?.some(component => component[fields.critical]);

    if (notificationDetailsLoading) {
      return <Loader isLoading={true} />;
    }

    const selectedNotificationClustered = find(notificationsClustered, item => item[formConstants.fields.id] === queryItem);

    return (
      <div className="notification-details" id="notification-details">
        <Tabs
          defaultTabKey={activeToolbarItem}
          navigationClassName="component-details__tabs"
          onChange={this.fetchData}
          className="notification-details__tabs"
          tabsHeader={
            activeToolbarItem !== tabNames.comments ? (
              <ModuleHeader
                isCustomProp={FEATURES.notifications.externalID.visible}
                isCustomPropTranslation="EXTERNAL_ID_SHORT"
                isCustomPropData={selectedNotification ? selectedNotification[fields.externalNotificationID] : null}
                menuOptions={menuOptions}
                id={queryItem}
              />
            ) : null
          }
          formHasUnsavedChanges={formHasUnsavedChanges}
          setFormUnsavedChanges={setNotificationFormState}
        >
          <Tab title={toolbarItems[tabNames.details].label} tabKey={tabNames.details}>
            <RenderIf if={containsCriticalEquipment}>
              <CriticalEquipmentInfo title={'CRITICAL_EQUIPMENT.TITLE'} paragraph={'CRITICAL_EQUIPMENT_NOTIFICATIONS.PARAGRAPH'} />
            </RenderIf>
            <NotificationForm
              onSubmit={this.submitForm}
              user={user}
              initialValues={{
                ...selectedNotification,
                [formConstants.fields.createdAt]: selectedNotification && Helpers.getDateFromUnix(selectedNotification[formConstants.fields.createdAt]),
                [formConstants.fields.actionedAt]:
                  selectedNotification && selectedNotification[formConstants.fields.actionedAt] !== 0 ? Helpers.getDateFromUnix(selectedNotification[formConstants.fields.actionedAt]) : 0,
                [formConstants.fields.closedAt]:
                  selectedNotification && selectedNotification[formConstants.fields.closedAt] !== 0 ? Helpers.getDateFromUnix(selectedNotification[formConstants.fields.closedAt]) : 0,
                [formConstants.fields.sourceCustom]:
                  !isEmpty(selectedNotification) && `${selectedNotification[formConstants.fields.sourceName] || ''} (${selectedNotification[formConstants.fields.source] || ''})`,
              }}
              severityColors={severityColors}
              generalStatus={generalStatus}
              deleteNotification={deleteNotification}
              toggleDeleteModal={toggleDeleteModal}
              notificationType={notificationType}
              notificationName={notificationName}
              handleCreateWorkOrderModal={handleCreateWorkOrderModal}
              inspectionId={inspectionId}
              handleActivePage={handleActivePage}
              relatedComponents={relatedComponents}
              properties={properties}
              getProperties={getProperties}
              updateProperty={this.handleCustomPropertyUpdate}
              addProperty={this.handleCustomPropertyUpdate}
              deleteProperty={deleteProperty}
              getSuggestions={getSuggestions}
              notificationId={queryItem}
              onChangeStatus={this.handleChangeStatus}
              onChange={this.onFormChange}
              formHasUnsavedChanges={isSaveButtonEnabled}
              // deeplink for sources
              handleSourceClick={_data => {
                const sourceId = selectedNotification[formConstants.fields.sourceID];

                if (!sourceId) return;

                if (sourceType === sourceTypes.ndtMeasurement) {
                  handleActivePage(modules.ndtData, sourceId);
                } else if (sourceType === sourceTypes.observation) {
                  handleActivePage(modules.defects, sourceId);
                  // need to test this once we enable adding notifications from maintenance regimes again. This case was removed in previous releases, so in case it comes back we have a handler for it.
                } else if (sourceType === sourceTypes.regime) {
                  Helpers.goTo(routes.protectedRoutes.maintenanceRegime.fullPath, [{ project_id: projectId }, { inspection_id: inspectionId }, { selected_item: sourceId }]);
                } else if (sourceType === sourceTypes.measurementPoint) {
                  handleActivePage(modules.readingsAndGauges, sourceId, detailsPages.measurementPoint, viewOptions.points);
                }
              }}
              showGeometryWarning={showGeometryWarning}
              changeField={changeField}
              objectToolClick={objectToolClick}
              handleChangeCameraUpdate={this.handleChangeCameraUpdate}
              openFile={this.openFile}
              handleInputDisabled={this.handleInputDisabled}
              notificationFiles={notificationFiles}
              openDeleteFileModal={this.openDeleteFileModal}
              defaultComponent={{ [fields.name]: defaultComponentName, [fields.id]: inspectionDetails?.DefaultComponent }}
              locationObject={{
                ...Helpers.getModuleLocationObject({
                  ...selectedNotification,
                  Geometry: selectedNotificationClustered?.Geometry || selectedNotification?.Geometry,
                  CameraPosition: selectedNotificationClustered?.CameraPosition || selectedNotification?.CameraPosition,
                }),
                visible: selectedNotificationClustered?.visible || false,
              }}
              toggleElement={toggleElement}
            />
            <Modal {...deleteNotificationModalData} />
          </Tab>
          <Tab title={toolbarItems[tabNames.workOrders].label} tabKey={tabNames.workOrders}>
            <NotificationWorkOrdersTab
              openWorkOrderCreationModal={this.openWorkOrderCreationModal}
              notificationName={notificationName}
              notificationType={notificationType}
              handleActivePage={handleActivePage}
              queryItem={queryItem}
              user={user}
            />
          </Tab>
          <Tab title={toolbarItems[tabNames.comments].label} tabKey={tabNames.comments}>
            <CommentsTab
              commentsList={commentsList}
              commentsLoading={commentsLoading}
              addCommentLoading={addCommentLoading}
              fetchCommentsList={this.fetchNotificationCommentsHandler}
              onAddCommentClick={this.addNotificationCommentHandler}
              onDeleteCommentClick={this.deleteNotificationCommentHandler}
              fetchCommentUsersAndTeams={this.searchUserAndTeamsHandler}
              commentUsersList={commentUsersList}
              commentTeamsList={commentTeamsList}
              commentUsersAndTeamsLoading={commentUsersAndTeamsLoading}
              user={user}
              addCommentPermission={PERMISSIONS[PERMISSION_TYPES.notifications].addComment.name}
            />
          </Tab>
        </Tabs>
        <Modal {...modalData} />
        <Modal {...deleteFileModalData} />
      </div>
    );
  }
}

NotificationDetails.contextTypes = {
  t: PropTypes.func.isRequired,
};

NotificationDetails.defaultProps = {
  isPdfScreen: false,
};

const selector = formValueSelector(FORMS.notificationForm);
const mapStateToProps = state => {
  const notificationType = selector(state, formConstants.fields.type),
    notificationName = selector(state, formConstants.fields.name),
    generalStatus = selector(state, formConstants.fields.status),
    sourceType = selector(state, formConstants.fields.source);

  return {
    user: state.userReducer,
    severityColors: state.themeReducer.severityColors,
    generalStatus,
    sourceType,
    notificationType,
    notificationName,
    deleteNotificationModalData: state.notificationReducer.delNotificationModalData,
    notificationDetailsLoading: state.notificationReducer.notificationDetailsLoading,
    properties: state.notificationReducer.customProperties,
    selectedNotification: state.inspectionReducer.selectedDefect,
    // TODO: this might be obsolete since we are using use-confirm-on-exit hook to handle unsaved changes in the forms
    unsavedChanges: state.notificationReducer.notificationForm.unsavedChanges,
    formHasUnsavedChanges: state.notificationReducer.notificationForm.hasUnsavedChanges,
    formHasUnsavedCustomProperties: state.notificationReducer.notificationForm.hasUnsavedCustomProps,
    unsavedCustomProps: state.notificationReducer.notificationForm.unsavedCustomProps,
    requestInProgress: state.notificationReducer.notificationForm.requestInProgress,
    activeTab: state.inspectionReducer.activeLeftSidebar,
    components: state.pdfTagReducer.pdfComponents,
    inspectionModalData: state.inspectionReducer.inspectionModalData,
    notificationFiles: state.uploadReducer.notificationFiles,
    is3DViewModeActive: state.inspectionReducer.is3DViewModeActive,
    inspectionDetails: state.inspectionReducer.inspectionDetails,
    notificationsClustered: state.notificationReducer.notificationsClustered,
  };
};

const mapDispatchToProps = dispatch => ({
  updateNotification: (data, callback) => dispatch(updateNotification(data, callback)),
  deleteNotification: (data, callback) => dispatch(removeNotification(data, callback)),
  toggleDeleteModal: data => dispatch(handleDeleteNotificationModal(data)),
  handleCreateWorkOrderModal: data => dispatch(handleCreateWorkOrderModal(data)),
  getComponentsByNotificationIds: (ids, callback) => dispatch(getComponentsByNotificationIds(ids, callback)),
  //properties
  getProperties: id => dispatch(getNotificationCustomProperties(id)),
  deleteProperty: (data, callback) => dispatch(deleteNotificationProperty(data, callback)),
  getSuggestions: (value, callback) => dispatch(getNotificationPropertyNames(value, callback)),
  // comments
  fetchNotificationComments: (params, callback) => dispatch(fetchNotificationComments(params, callback)),
  addNotificationComment: (params, dataCallback, loadingCallback) => dispatch(addNotificationComment(params, dataCallback, loadingCallback)),
  deleteNotificationComment: (params, dataCallback, loadingCallback) => dispatch(deleteNotificationComment(params, dataCallback, loadingCallback)),
  fetchCommentUsersAndTeams: (searchTerm, dataCallback, loadingCallback) => dispatch(fetchCommentUsersAndTeams(searchTerm, dataCallback, loadingCallback)),
  destroyForm: formName => dispatch(destroy(formName)),
  getDefectDetails: (defect, callback, saveItem) => dispatch(getDefectDetails(defect, callback, {}, true, saveItem)),
  setSelectedNotification: notification => dispatch(setElementDetails({ defect: notification })),
  setNotificationFormState: state => dispatch(setNotificationFormState(state)),
  updateNotificationProperties: (properties, callback) => dispatch(updateNotificationProperties(properties, callback)),
  // pdf files rendering and popup
  getNotificationDMSFilesUploaded: defectId => dispatch(getNotificationDMSFilesUploaded(defectId)),
  toggleInspectionModal: data => dispatch(toggleInspectionModal(data)),
  closeInspectionModal: () => dispatch(closeInspectionModal()),
  minimizeInspectionModal: () => dispatch(minimizeInspectionModal()),
  maximizeInspectionModal: () => dispatch(maximizeInspectionModal()),
  changePDFPage: (newPDFPageNumber, inspectionId, fileId, filter, selectedComponent, showOnlyConfirmedDrawings, callback) =>
    dispatch(changePDFPage(newPDFPageNumber, inspectionId, fileId, filter, selectedComponent, showOnlyConfirmedDrawings, callback)),
  openAsSeparatePageInspectionModal: () => dispatch(openAsSeparatePageInspectionModal()),
  getPdfComponentsForPopup: (inspectionID, fileId, filter, pageNumber, compId, callback) => dispatch(getPdfComponentsForPopup(inspectionID, fileId, filter, pageNumber, compId, callback)),
  setPdfComponents: selectedModuleItemDrawings => dispatch(setPdfComponents(selectedModuleItemDrawings)),
  setUploadItems: files => dispatch(setUploadItems(files)),
  unlinkDMSFileFromNotification: (sourceId, notificationId, categoryID, callback, modalCallback) =>
    dispatch(unlinkDMSFileFromNotification(sourceId, notificationId, categoryID, callback, modalCallback)),
  getNotificationCustomPropertyDependencies: (notificationID, propertyValue, propertyName, callback) =>
    dispatch(getNotificationCustomPropertyDependencies(notificationID, propertyValue, propertyName, callback)),
  setNotificationProperties: data => dispatch(fetchNotificationPropertiesSuccess(data)),
  setUnsavedChangesDirty: data => dispatch(setUnsavedChangesDirty(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotificationDetails));
