import PropTypes from 'prop-types';
import React from 'react';

import { MAX_DESCRIPTION_CHAR_SHOW } from '../../constants';
import Icon from '../../icon/components/icon';
import InfoTooltip from '../../tooltip/components/info-tooltip';

import '../styles/read-more-tooltip.scss';

const ReadMoreTooltip = ({ value, offsetY, offsetX, onClick }) => {
  return (
    <React.Fragment>
      {value.length > MAX_DESCRIPTION_CHAR_SHOW ? (
        <div className="read-more-cell__container">
          <span className="f-primary read-more-span">{value}</span>
          <InfoTooltip
            offsetY={offsetY ? offsetY : 8}
            offsetX={offsetX ? offsetX : 8}
            customComponent={
              <span onClick={onClick}>
                <p className="read-more-tooltip">{value}</p>
              </span>
            }
            Component={() => (
              <div className="read-more-cell__container">
                <div className="read-more-info__container">
                  <Icon name="info" handleHover={false} size="xs" className="info-icon" />
                </div>
              </div>
            )}
            componentProps={{ title: '' }}
          />
        </div>
      ) : (
        <span className="f-primary read-more-span">{value}</span>
      )}
    </React.Fragment>
  );
};

ReadMoreTooltip.propTypes = {
  value: PropTypes.string.isRequired,
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
  onClick: PropTypes.func,
};

export default ReadMoreTooltip;
