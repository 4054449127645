import React from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';

import Icon from '../../icon/components/icon';
import InfoTooltip from '../../tooltip/components/info-tooltip';
import Helpers from '../../../common/helpers';
import { componentDetailsDisplayProps, componentFields } from '../constants/question-constants';

import '../styles/question-field.scss';

const ComponentsDisplay = ({ components, componentNameKey, componentIdKey, selectedComponents, featureId, handleDeleteComponent }, { t }) => {
  const getComponentDetails = componentId => {
    if (components.length) {
      const component = find(components, { [componentFields.id]: componentId });
      return component || {};
    }

    return {};
  };

  if (!selectedComponents || !selectedComponents.length) {
    return null;
  }

  return (
    <div className="components-display bottom-spacing">
      <p className="f-secondary-dark">{t('SECTIONS.QUESTION_ADDED_COMPONENTS')}</p>
      {selectedComponents.map(component => (
        <div className="components-display__item-container" key={`component-display-${component[componentIdKey]}`}>
          <div className="components-display__item-container__title-wrapper">
            <p className="f-primary components-display__item-container__title-wrapper__title">{component[componentNameKey]}</p>
            <InfoTooltip
              actionsMenu={Helpers.mapInfoIconDisplayProps(getComponentDetails(component[componentIdKey]), componentDetailsDisplayProps)}
              offsetY={8}
              offsetX={8}
              Component={() => <Icon name="info" size="sm" />}
              className="image-details inline-wrapper__right-content"
              componentProps={{ title: '' }}
            />
          </div>
          {typeof handleDeleteComponent === 'function' ? (
            <Icon name="close" className="components-display__item-container__close-icon" onClick={() => handleDeleteComponent(featureId, component.ID, component[componentNameKey])} />
          ) : null}
        </div>
      ))}
    </div>
  );
};

ComponentsDisplay.contextTypes = {
  t: PropTypes.func.isRequired,
};

ComponentsDisplay.propTypes = {
  components: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
    })
  ),
  componentNameKey: PropTypes.string,
  componentIdKey: PropTypes.string,
  selectedComponents: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number.isRequired,
      ComponentName: PropTypes.string.isRequired,
    })
  ),
  featureId: PropTypes.number, // The ID of the feature, for example questionId if the component is tied to the question
  handleDeleteComponent: PropTypes.func,
};

ComponentsDisplay.defaultProps = {
  components: [],
  selectedComponents: [],
  componentNameKey: 'ComponentName',
  componentIdKey: 'ComponentID',
};

export default ComponentsDisplay;
