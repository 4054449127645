import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { arrayPush, arrayRemoveAll, reduxForm } from 'redux-form';

import ExpandableSection from '../../expandable-section/components/expandable-section';
import Modal from '../../modal/components/modal';
import WorkAreasModal from '../../question-areas/components/work-areas-modal';
import { ComponentPicker3D, ComponentPickerCH, ComponentPickerPDF } from '../../question-components/components';
import ScreenshotPicker from '../../question-screenshots/components/screenshot-picker';
import RenderIf from '../../render-if/components/render-if';
import SectionContent from './section-content';
import SectionTitle from './section-title';

import { validate } from '../validators/validators';

import QuestionTagLocation from '../../question-tag-location/components/question-tag-location';
import '../styles/section.scss';

class Section extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSectionExpanded: props.sectionExpanded || false,
      questionModalData: {
        isOpen: false,
      },
      currentQuestionId: -1,
    };
  }

  toggleSectionExpanded = () => {
    const { onToggleSectionExpand } = this.props;
    const { isSectionExpanded } = this.state;
    this.setState({ isSectionExpanded: !isSectionExpanded });
    onToggleSectionExpand && onToggleSectionExpand(!isSectionExpanded);
  };

  onDeleteSection = () => {
    const { ID, Name, handleDeleteSection } = this.props;

    if (handleDeleteSection && typeof handleDeleteSection === 'function') {
      handleDeleteSection(ID, Name);
    }
  };

  handleManageWorkAreas = question => {
    const { inspectionId, handleWorkAreaAdd, handleDeleteWorkArea, handleWorkAreaUpdate, isDisabled } = this.props;
    const closeAction = () => {
      this.setState({ questionModalData: { isOpen: false }, currentQuestionId: -1 });
    };

    this.setState({
      questionModalData: {
        isOpen: true,
        CustomContent: dynamicProps => (
          <WorkAreasModal
            {...dynamicProps}
            inspectionId={inspectionId}
            handleWorkAreaAdd={handleWorkAreaAdd}
            handleDeleteWorkArea={handleDeleteWorkArea}
            handleWorkAreaUpdate={handleWorkAreaUpdate}
            closeAction={closeAction}
            viewOnly={isDisabled}
          />
        ),
        customClassName: 'modal-no-max-height modal-large',
        customClassWrapperName: 'no-padding',
        type: 'none',
        closeAction,
      },
      currentQuestionId: question.ID,
    });
  };

  handleAdd3DPosition = question => {
    const { inspectionId, isDisabled, handleUpdateTagLocation } = this.props;
    const closeAction = () => {
      this.setState({ questionModalData: { isOpen: false }, currentQuestionId: -1 });
    };

    this.setState({
      questionModalData: {
        isOpen: true,
        CustomContent: dynamicProps => (
          <QuestionTagLocation {...dynamicProps} inspectionId={inspectionId} handleUpdateLocation={handleUpdateTagLocation} closeAction={closeAction} viewOnly={isDisabled} />
        ),
        customClassName: 'modal-no-max-height modal-large',
        type: 'none',
        closeAction,
      },
      currentQuestionId: question.ID,
    });
  };

  handleComponentPicker3D = question => {
    const { inspectionId, handleComponentSelected, handleDeleteComponent } = this.props;
    const closeAction = () => {
      this.setState({ questionModalData: { isOpen: false }, currentQuestionId: -1 });
    };

    this.setState({
      questionModalData: {
        isOpen: true,
        CustomContent: dynamicProps => (
          <ComponentPicker3D {...dynamicProps} handleComponentSelected={handleComponentSelected} handleDeleteComponent={handleDeleteComponent} inspectionId={inspectionId} closeAction={closeAction} />
        ),
        customClassName: 'modal-no-max-height modal-large',
        type: 'none',
        closeAction,
      },
      currentQuestionId: question.ID,
    });
  };

  handleComponentPickerPDF = question => {
    const { inspectionId, handleComponentSelected, handleDeleteComponent } = this.props;
    const closeAction = () => {
      this.setState({ questionModalData: { isOpen: false }, currentQuestionId: -1 });
    };

    this.setState({
      questionModalData: {
        isOpen: true,
        CustomContent: dynamicProps => (
          <ComponentPickerPDF {...dynamicProps} handleComponentSelected={handleComponentSelected} handleDeleteComponent={handleDeleteComponent} inspectionId={inspectionId} closeAction={closeAction} />
        ),
        customClassName: 'modal-no-max-height modal-large',
        type: 'none',
        closeAction,
      },
      currentQuestionId: question.ID,
    });
  };

  handleComponentPickerCH = question => {
    const { inspectionId, handleComponentSelected, handleDeleteComponent } = this.props;
    const { t } = this.context;
    const closeAction = () => {
      this.setState({ questionModalData: { isOpen: false }, currentQuestionId: -1 });
    };

    this.setState({
      questionModalData: {
        isOpen: true,
        CustomContent: dynamicProps => (
          <ComponentPickerCH {...dynamicProps} handleComponentSelected={handleComponentSelected} handleDeleteComponent={handleDeleteComponent} inspectionId={inspectionId} closeAction={closeAction} />
        ),
        customClassName: 'modal-no-max-height modal-large',
        type: 'none',
        title: t('QUESTION_COMPONENT_PICKER.CHOOSE_COMPONENTS'),
        closeAction,
        customCloseAction: closeAction,
      },
      currentQuestionId: question.ID,
    });
  };

  handleScreenshotFrom3D = (question, defaultActiveSlideIndex) => {
    const { inspectionId, onDropAsset, isDisabled } = this.props;
    const closeAction = () => {
      this.setState({ questionModalData: { isOpen: false }, currentQuestionId: -1 });
    };

    this.setState({
      questionModalData: {
        isOpen: true,
        CustomContent: dynamicProps => (
          <ScreenshotPicker {...dynamicProps} viewOnly={isDisabled} inspectionId={inspectionId} onDropAsset={onDropAsset} closeAction={closeAction} defaultActiveSlideIndex={defaultActiveSlideIndex} />
        ),
        customClassName: 'modal-no-max-height modal-large',
        type: 'none',
        closeAction,
      },
      currentQuestionId: question.ID,
    });
  };

  removeFieldArrayFields = field => {
    const { form, fieldArrayRemoveAll } = this.props;

    fieldArrayRemoveAll(form, field);
  };

  pushFieldArrayItem = (options, index) => {
    const { form, sectionQuestionsName, fieldArrayPush } = this.props;

    if (options && options.length) {
      for (let i = 0; i < options.length; i++) {
        fieldArrayPush(form, `${sectionQuestionsName}[${index}].QuestionOptions`, options[i]);
      }
    }
  };

  render() {
    const { isSectionExpanded, questionModalData, currentQuestionId } = this.state;
    const {
      formValues,
      ID,
      Name,
      questions,
      sectionIndex,
      sectionsLength,
      isLocked,
      isExpandable,
      hideReorder,
      deleteDisabled,
      nonTranslatable,
      hideAddQuestionButton,
      hideAdditionalFields,
      handleSort,
      handleAddQuestion,
      handleFieldDelete,
      handleFieldSort,
      handleFieldChange,
      SectionCustomContent,
      sectionQuestionsName,
      questionValidators,
      questionTypes,
      handleUpdateSection,
      handleComponentSelected,
      handleWorkAreaAdd,
      handleUpdateTagLocation,
      components,
      sectionsChanged,
      handleDeleteComponent,
      toggleAdditionalFields,
      handleAddQuestionOption,
      handleDeleteQuestionOption,
      handleUpdateQuestionOption,
      isLoading,
      isDisabled,
      addQuestionDisabled,
      lockedQuestions,
      stickActions,
      sortUpDisabled,
      sortDownDisabled,
      showQuestionDescription,
      isFilesUpload,
      hideLockIcon,
      toggleUploadModal,
      files,
      handleDeleteSectionFile,
      sections,
      sectionUpdateDebounce,
      questionUpdateDebounce,
      questionOptionUpdateDebounce,
      hideMandatoryCheckbox,
      projectDMSCategories,
      fetchQuestionFiles,
      hideNewMeasurementPointQuestionButton,
      addNewMeasurementPointDisabled,
      handleAddNewMeasurementPointQuestion,
      QuestionFilters,
      loadPreviousQuestions,
      loadNextQuestions,
      showQuestionsPaginationNavigator = false,
      requestInProgress = false,
      inspectionId,
    } = this.props;

    const sectionContentProps = {
      questions: questions,
      sectionId: ID,
      hideAddQuestionButton: hideAddQuestionButton,
      sectionQuestionsName: sectionQuestionsName,
      questionValidators: questionValidators,
      questionTypes: questionTypes,
      handleFieldDelete: handleFieldDelete,
      handleFieldSort: handleFieldSort,
      handleAddQuestion: handleAddQuestion,
      handleFieldChange: handleFieldChange,
      SectionCustomContent: SectionCustomContent,
      formValues: formValues,
      hideAdditionalFields: hideAdditionalFields,
      components: components,
      handleComponentSelected: handleComponentSelected,
      handleWorkAreaAdd,
      handleUpdateTagLocation,
      sectionsChanged: sectionsChanged,
      handleDeleteComponent: handleDeleteComponent,
      toggleAdditionalFields: toggleAdditionalFields,
      handleAddQuestionOption: handleAddQuestionOption,
      handleUpdateQuestionOption: handleUpdateQuestionOption,
      handleDeleteQuestionOption: handleDeleteQuestionOption,
      isLoading: isLoading,
      isDisabled: isDisabled,
      addQuestionDisabled: addQuestionDisabled,
      handleManageWorkAreas: this.handleManageWorkAreas,
      handleAdd3DPosition: this.handleAdd3DPosition,
      handleComponentPicker3D: this.handleComponentPicker3D,
      handleComponentPickerPDF: this.handleComponentPickerPDF,
      handleComponentPickerCH: this.handleComponentPickerCH,
      handleScreenshotFrom3D: this.handleScreenshotFrom3D,
      questionModalData,
      lockedQuestions,
      showQuestionDescription,
      isFilesUpload,
      toggleUploadModal,
      files,
      handleDeleteSectionFile,
      removeFieldArrayFields: this.removeFieldArrayFields,
      pushFieldArrayItem: this.pushFieldArrayItem,
      stickActions,
      questionUpdateDebounce,
      questionOptionUpdateDebounce,
      isLocked,
      hideMandatoryCheckbox,
      projectDMSCategories,
      fetchQuestionFiles,
      addNewMeasurementPointDisabled,
      handleAddNewMeasurementPointQuestion,
      hideNewMeasurementPointQuestionButton,
      QuestionFilters,
      loadPreviousQuestions,
      loadNextQuestions,
      showQuestionsPaginationNavigator,
      requestInProgress,
      inspectionId: inspectionId,
    };

    const currentQuestion = questions && questions.length > 0 ? questions.find(el => el.ID === currentQuestionId) : {};

    return (
      <div className="section">
        <form
          noValidate
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <RenderIf if={isExpandable}>
            <ExpandableSection
              CustomComponent={SectionTitle}
              className="expanded-section"
              customExpanded={isSectionExpanded}
              customComponentProps={{
                ID,
                isLocked,
                hideReorder,
                deleteDisabled,
                sectionsLength,
                Name,
                nonTranslatable,
                sectionIndex,
                isExpandable,
                isSectionExpanded,
                toggleSectionExpanded: this.toggleSectionExpanded,
                handleSort,
                onDeleteSection: this.onDeleteSection,
                handleUpdateSection,
                isLoading: requestInProgress,
                isDisabled,
                stickActions,
                sortUpDisabled,
                sortDownDisabled,
                hideLockIcon,
                sections,
                sectionUpdateDebounce,
              }}
              hideExpandAction
            >
              <SectionContent {...sectionContentProps} />
            </ExpandableSection>
            <Modal {...questionModalData} question={currentQuestion} components={components} />
          </RenderIf>
          <RenderIf if={!isExpandable}>
            <>
              <SectionTitle
                ID={ID}
                isLocked={isLocked}
                hideReorder={hideReorder}
                deleteDisabled={deleteDisabled}
                sectionsLength={sectionsLength}
                Name={Name}
                nonTranslatable={nonTranslatable}
                sectionIndex={sectionIndex}
                isExpandable={isExpandable}
                isSectionExpanded={isSectionExpanded}
                toggleSectionExpanded={this.toggleSectionExpanded}
                handleSort={handleSort}
                onDeleteSection={this.onDeleteSection}
                handleUpdateSection={handleUpdateSection}
                isLoading={requestInProgress}
                isDisabled={isDisabled}
                stickActions={stickActions}
                sortUpDisabled={sortUpDisabled}
                sortDownDisabled={sortDownDisabled}
                hideLockIcon={hideLockIcon}
                sections={sections}
              />
              <SectionContent {...sectionContentProps} />
            </>
          </RenderIf>
        </form>
      </div>
    );
  }
}

Section.contextTypes = {
  t: PropTypes.func.isRequired,
};

Section.propTypes = {
  form: PropTypes.string.isRequired,
  ID: PropTypes.number.isRequired,
  SectionOrder: PropTypes.number.isRequired,
  Name: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number,
      Name: PropTypes.string.isRequired,
      QuestionType: PropTypes.number,
      QuestionOrder: PropTypes.number,
      IsMandatory: PropTypes.bool,
      AllowMultipleAnswers: PropTypes.bool,
      EstimatedTime: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      CreatedAt: PropTypes.number,
    })
  ),
  questionTypes: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number,
      ChecklistItemType: PropTypes.string,
      ChecklistItemTypeName: PropTypes.string,
    })
  ),
  sectionIndex: PropTypes.number.isRequired,
  sectionsLength: PropTypes.number,
  isLocked: PropTypes.bool,
  isExpandable: PropTypes.bool,
  hideReorder: PropTypes.bool,
  deleteDisabled: PropTypes.bool,
  sectionExpanded: PropTypes.bool,
  nonTranslatable: PropTypes.bool,
  hideAddQuestionButton: PropTypes.bool,
  hideAdditionalFields: PropTypes.bool,
  handleDeleteSection: PropTypes.func,
  handleSort: PropTypes.func,
  handleAddQuestion: PropTypes.func,
  handleFieldDelete: PropTypes.func,
  handleFieldSort: PropTypes.func,
  handleUpdateSection: PropTypes.func,
  handleFieldChange: PropTypes.func,
  SectionCustomContent: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.elementType]),
  sectionQuestionsName: PropTypes.string.isRequired,
  questionValidators: PropTypes.object,
  components: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
    })
  ),
  handleComponentSelected: PropTypes.func,
  handleWorkAreaAdd: PropTypes.func,
  handleUpdateTagLocation: PropTypes.func,
  sectionsChanged: PropTypes.bool,
  handleDeleteComponent: PropTypes.func,
  toggleAdditionalFields: PropTypes.func,
  handleAddQuestionOption: PropTypes.func,
  handleUpdateQuestionOption: PropTypes.func,
  handleDeleteQuestionOption: PropTypes.func,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  addQuestionDisabled: PropTypes.bool,
  inspectionId: PropTypes.number,
  lockedQuestions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      isLocked: PropTypes.bool,
      label: PropTypes.string,
      placeholder: PropTypes.string,
      props: PropTypes.object,
    })
  ),
  stickActions: PropTypes.bool,
  sortUpDisabled: PropTypes.bool,
  sortDownDisabled: PropTypes.bool,
  showQuestionDescription: PropTypes.bool,
  isFilesUpload: PropTypes.bool,
  hideLockIcon: PropTypes.bool,
  toggleUploadModal: PropTypes.func,
  onDropAsset: PropTypes.func,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      FileID: PropTypes.number.isRequired,
      FileName: PropTypes.string.isRequired,
      Key: PropTypes.string,
      DocumentURL: PropTypes.string,
      ThumbnailURL: PropTypes.string,
      URL: PropTypes.string,
    })
  ),
  handleDeleteSectionFile: PropTypes.func,
  hideMandatoryCheckbox: PropTypes.bool,
};

Section.defaultProps = {
  sectionsLength: 0,
  isLocked: false,
  isExpandable: false,
  hideReorder: false,
  deleteDisabled: false,
  sectionExpanded: false,
  nonTranslatable: false,
  hideAddQuestionButton: false,
  hideAdditionalFields: false,
  questions: [],
  sectionQuestionsName: '',
  questionValidators: {},
  questionTypes: [],
  sectionsChanged: false,
  isLoading: false,
  isDisabled: false,
  addQuestionDisabled: false,
  stickActions: false,
  sortUpDisabled: false,
  sortDownDisabled: false,
  showQuestionDescription: false,
  isFilesUpload: false,
  hideLockIcon: false,
  files: [],
};

const mapStateToProps = (state, props) => {
  return {
    formValues: state.form[props.form]?.values,
    projectDMSCategories: state.projectDetailsReducer.projectDMSCategories,
  };
};

const mapDispatchToProps = dispatch => ({
  fieldArrayRemoveAll: (formName, field) => dispatch(arrayRemoveAll(formName, field)),
  fieldArrayPush: (formName, field, value) => dispatch(arrayPush(formName, field, value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    enableReinitialize: false,
    touchOnChange: true,
  })(Section)
);
