import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { FORMS } from '../../../../../common/constants';

import ColorPalette from '../../../../../common/form/components/color-pallete';
import BasicInput from '../../../../../common/form/components/input';
import SetCameraPosition from '../set-camera-position';

import { workAreaConstants } from '../../../constants/work-order-constants';
import { validate } from '../validators/area-measurement-validator';

class AreaMeasurementForm extends Component {
  render() {
    const { t } = this.context;
    const { handleSubmit, disabled, handleInputChange, data, nameField, FormButtons, viewer } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <Field
          component={BasicInput}
          inputProps={{ value: data[nameField] }}
          handleChange={val => handleInputChange(nameField, val)}
          placeholder={t('WORK_ORDER_DETAILS.FORM_NAME')}
          label={t('WORK_ORDER_DETAILS.FORM_NAME')}
          labelClass="f-secondary-dark defect-form__label"
          type="text"
          disabled={disabled}
          name={nameField}
          id={nameField}
          size="lg"
          isRequired
        />
        <Field
          id={workAreaConstants.fields.color}
          name={workAreaConstants.fields.color}
          handleChange={val => handleInputChange(workAreaConstants.fields.color, val)}
          component={ColorPalette}
          label="COLOUR_TAG"
          size="xl"
          isRequired
        />
        <SetCameraPosition disabled={disabled} handleChange={newValue => handleInputChange('CameraPosition', { coordinates: newValue })} viewer={viewer} horizontalAlign="left" />
        <FormButtons />
      </form>
    );
  }
}

AreaMeasurementForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

AreaMeasurementForm = reduxForm({
  form: FORMS.areaMeasurementForm,
  validate,
  enableReinitialize: true,
})(AreaMeasurementForm);

const mapStateToProps = (state, props) => ({
  initialValues: props.data,
});

export default connect(mapStateToProps, null)(AreaMeasurementForm);
