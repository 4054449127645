const lineChartStyle = {
  animationEnabled: true,
  lineWidth: '2',
  legend: 'none',
  pointShape: 'circle',
  pointSize: 40,
  pointsVisible: true,
  animation: {
    duration: 1000,
    easing: 'inAndOut',
    startup: true,
  },
  chartArea: {
    left: 0,
    right: 0,
    top: 0,
    width: '50%',
    height: 240,
  },
  hAxis: {
    borderColor: '#00E396',
    textStyle: {
      fontFamily: 'Montserrat',
      fontSize: '14px',
      bold: false,
    },
  },
  vAxis: {
    textStyle: {
      color: '#FFF',
      fontFamily: 'Montserrat',
      fontSize: '14px',
      bold: false,
      opacity: 0,
    },
  },
  series: {
    0: { curveType: 'function' },
  },
};

export { lineChartStyle };
