import { API_BASE_URL } from '../../common/constants';
import { Api } from '../../core/api';
import { generateQueryParams } from '../helpers/helper';
import routesConstants from './routes';

const fetchDMFolders = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_FOLDERS}`, data);
};

const fetchComponentDMFolders = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_COMPONENT_FOLDERS}`, data);
};

const fetchNotificationDMFolders = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_NOTIFICATION_FOLDERS}`, data);
};

const fetchObservationDMFolders = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_OBSERVATION_FOLDERS}`, data);
};

const fetchWorkorderDMFolders = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_WORKORDER_FOLDERS}`, data);
};

const fetchWorkorderCompletionDMFolders = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_WORKORDER_COMPLETION_FOLDERS}`, data);
};

const fetchCPQuestionDMFolders = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_CP_QUESTION_FOLDERS}`, data);
};

const createDMFolder = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CREATE_FOLDER}`, data);
};

const fetchDMLabels = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_LABELS}`, data);
};

const fetchDMFiles = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_FILES}`, data);
};

const fetchComponentDMFiles = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_COMPONENT_FILES}`, data);
};

const fetchNotificationDMFiles = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_NOTIFICATION_FILES}`, data);
};

const fetchObservationDMFiles = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_OBSERVATION_FILES}`, data);
};

const fetchWorkorderDMFiles = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_WORKORDER_FILES}`, data);
};

const fetchWorkorderCompletionDMFiles = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_WORKORDER_COMPLETION_FILES}`, data);
};

const fetchCPQuestionDMFiles = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_CP_QUESTION_FILES}`, data);
};

const fetchDMFolderDetails = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.FETCH_FOLDER_HIERARCHY_LEVEL_DETAILS}${generateQueryParams(params)}`);
};

const fetchDMFileDetails = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.FETCH_FILE_HIERARCHY_LEVEL_DETAILS}${generateQueryParams(params)}`);
};

const updateDMFolderDetails = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_FOLDER_DETAILS}`, data);
};

const deleteDMFolder = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_DM_FOLDER}`, data);
};

const deleteDMFile = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_DMS_FILE}`, data);
};

const moveDMSFile = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.MOVE_DMS_FILE}`, data);
};

const updateDMSFile = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_DMS_FILE}`, data);
};

const fetchDMSStatuses = async () => {
  return await Api.get(`${API_BASE_URL}${routesConstants.FETCH_STATUSES}`);
};

const fetchComponentsToLink = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_COMPONENTS_TO_LINK}`, data);
};

const linkComponentsToFile = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.LINK_COMPONENTS_TO_FILE}`, data);
};

const addLabelToDMSFile = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_LABEL_TO_DMS_FILE}`, data);
};

const removeLabelFromDMSFile = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.REMOVE_LABEL_FROM_DMS_FILE}`, data);
};

const confirmDMSFileUploadIsDone = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CONFIRM_DMS_FILE_UPLOAD_IS_DONE}`, data);
};

const confirmComponentDMSFileUploadIsDone = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CONFIRM_COMPONENT_DMS_FILE_UPLOAD_IS_DONE}`, data);
};

const confirmNotificationDMSFileUploadIsDone = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CONFIRM_NOTIFICATION_DMS_FILE_UPLOAD_IS_DONE}`, data);
};

const confirmObservationDMSFileUploadIsDone = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CONFIRM_OBSERVATION_DMS_FILE_UPLOAD_IS_DONE}`, data);
};

const confirmWorkorderDMSFileUploadIsDone = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CONFIRM_WORKORDER_DMS_FILE_UPLOAD_IS_DONE}`, data);
};

const confirmWorkorderWizardDMSFileUploadIsDone = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CONFIRM_WORKORDER_WIZARD_DMS_FILE_UPLOAD_IS_DONE}`, data);
};

const confirmCPQuestionDMSFileUploadIsDone = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CONFIRM_CP_QUESTION_DMS_FILE_UPLOAD_IS_DONE}`, data);
};

const confirmCPEvidenceDMSFileUploadIsDone = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CONFIRM_CP_EVIDENCE_DMS_FILE_UPLOAD_IS_DONE}`, data);
};

const confirmWorkorderCompletionDMSFileUploadIsDone = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CONFIRM_WORKORDER_COMPLETION_DMS_FILE_UPLOAD_IS_DONE}`, data);
};

const linkDMSFileFromObservation = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.LINK_FILE_FROM_OBSERVATION}`, data);
};

const unlinkDMSFileFromObservation = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UNLINK_FILE_FROM_OBSERVATION}`, data);
};
const linkObservationToFile = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.LINK_OBSERVATION_TO_FILE}`, data);
};

const unlinkObservationToFile = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UNLINK_OBSERVATION_TO_FILE}`, data);
};

const linkDMSFileFromNotification = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.LINK_FILE_FROM_NOTIFICATION}`, data);
};

const unlinkDMSFileFromNotification = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UNLINK_FILE_FROM_NOTIFICATION}`, data);
};
const linkNotificationToFile = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.LINK_NOTIFICATION_TO_FILE}`, data);
};

const unlinkNotificationToFile = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UNLINK_NOTIFICATION_TO_FILE}`, data);
};

const linkDMSFileFromWorkorder = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.LINK_FILE_FROM_WORKORDER}`, data);
};

const linkDMSFileFromWorkorderCompletion = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.LINK_FILE_FROM_WORKORDER_COMPLETION}`, data);
};

const unlinkDMSFileFromWorkorder = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UNLINK_FILE_FROM_WORKORDER}`, data);
};

const unlinkDMSFileFromWorkorderCompletion = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UNLINK_FILE_FROM_WORKORDER_COMPLETION}`, data);
};

const linkWorkorderToFile = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.LINK_WORKORDER_TO_FILE}`, data);
};

const unlinkWorkorderToFile = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UNLINK_WORKORDER_TO_FILE}`, data);
};

const fetchDMSDocumentVersionHistory = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_DMS_DOCUMENT_VERSION_HISTORY}`, data);
};

const setFileVersionAsActive = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.SET_DMS_FILE_VERSION_TO_ACTIVE}`, data);
};

const updateDocumentVersion = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_DOCUMENT_VERSION}`, data);
};

const deleteDMSFileVersion = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_DMS_FILE_VERSION}`, data);
};

const linkDMSFileFromChecklistQuestion = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.LINK_FILE_FROM_CHECKLIST_QUESTION}`, data);
};

const unlinkDMSFileFromChecklistQuestion = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UNLINK_FILE_FROM_CHECKLIST_QUESTION}`, data);
};

const linkDMSFileFromChecklistQuestionEvidence = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.LINK_FILE_FROM_CHECKLIST_QUESTION_EVIDENCE}`, data);
};

const unlinkDMSFileFromChecklistQuestionEvidence = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UNLINK_FILE_FROM_CHECKLIST_QUESTION_EVIDENCE}`, data);
};

const fetchNewVersionNumberForDocument = async file_id => {
  return await Api.get(`${API_BASE_URL}${routesConstants.FETCH_NEW_VERSION_NUMBER_FOR_DOCUMENT}${generateQueryParams([{ file_id }])}`);
};

export default {
  fetchDMFolders,
  fetchComponentDMFolders,
  fetchNotificationDMFolders,
  fetchObservationDMFolders,
  fetchWorkorderDMFolders,
  fetchWorkorderCompletionDMFolders,
  fetchCPQuestionDMFolders,
  createDMFolder,
  fetchDMLabels,
  fetchDMFiles,
  fetchComponentDMFiles,
  fetchNotificationDMFiles,
  fetchObservationDMFiles,
  fetchWorkorderDMFiles,
  fetchWorkorderCompletionDMFiles,
  fetchCPQuestionDMFiles,
  fetchDMFolderDetails,
  fetchDMFileDetails,
  moveDMSFile,
  fetchDMSStatuses,
  updateDMSFile,
  updateDMFolderDetails,
  deleteDMFolder,
  deleteDMFile,
  fetchComponentsToLink,
  linkComponentsToFile,
  addLabelToDMSFile,
  removeLabelFromDMSFile,
  confirmDMSFileUploadIsDone,
  confirmComponentDMSFileUploadIsDone,
  confirmNotificationDMSFileUploadIsDone,
  confirmObservationDMSFileUploadIsDone,
  confirmWorkorderDMSFileUploadIsDone,
  confirmWorkorderWizardDMSFileUploadIsDone,
  confirmCPQuestionDMSFileUploadIsDone,
  confirmCPEvidenceDMSFileUploadIsDone,
  confirmWorkorderCompletionDMSFileUploadIsDone,
  linkDMSFileFromObservation,
  unlinkDMSFileFromObservation,
  linkObservationToFile,
  unlinkObservationToFile,
  linkDMSFileFromNotification,
  unlinkDMSFileFromNotification,
  linkNotificationToFile,
  unlinkNotificationToFile,
  linkDMSFileFromWorkorder,
  linkDMSFileFromWorkorderCompletion,
  unlinkDMSFileFromWorkorder,
  unlinkDMSFileFromWorkorderCompletion,
  linkWorkorderToFile,
  unlinkWorkorderToFile,
  fetchDMSDocumentVersionHistory,
  setFileVersionAsActive,
  updateDocumentVersion,
  deleteDMSFileVersion,
  linkDMSFileFromChecklistQuestion,
  unlinkDMSFileFromChecklistQuestion,
  linkDMSFileFromChecklistQuestionEvidence,
  unlinkDMSFileFromChecklistQuestionEvidence,
  fetchNewVersionNumberForDocument,
};
