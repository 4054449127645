import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../../../../common/form/components/button';

const ActionModal = (
  { firstParagraph, firstParagraphProps, secondParagraph, confirmButtonText, closeButtonText, closeButtonVariant, customCloseAction, customConfirmAction, modalDisabled, confirmButtonProps },
  { t }
) => {
  return (
    <div className="document-management__modal__action-modal">
      <p className="document-management__modal__action-modal__paragraph first-paragraph" dangerouslySetInnerHTML={{ __html: t(firstParagraph, firstParagraphProps) }}></p>
      {secondParagraph && secondParagraph !== '' && <p className="document-management__modal__action-modal__paragraph second-paragraph" dangerouslySetInnerHTML={{ __html: t(secondParagraph) }}></p>}
      <Button onClick={customCloseAction} type="button" disabled={modalDisabled} text={t(closeButtonText)} variant={closeButtonVariant} height="md" className="modal-button-custom" />
      <Button onClick={customConfirmAction} type="button" disabled={modalDisabled} text={t(confirmButtonText)} height="md" className="modal-button-custom" {...confirmButtonProps} />
    </div>
  );
};

ActionModal.defaultProps = {
  closeButtonText: 'CLOSE',
  closeButtonVariant: 'gray-outline',
  confirmButtonProps: {
    variant: 'danger',
  },
};

ActionModal.propTypes = {
  firstParagraph: PropTypes.string.isRequired,
  firstParagraphProps: PropTypes.object,
  secondParagraph: PropTypes.string,
  confirmButtonProps: PropTypes.object,
  confirmButtonText: PropTypes.string.isRequired,
  customCloseAction: PropTypes.func.isRequired,
  customConfirmAction: PropTypes.func.isRequired,
  closeButtonText: PropTypes.string,
  closeButtonVariant: PropTypes.string,
};

ActionModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ActionModal;
