import { FEATURES } from '../../../common/constants';
import { formConstants, pointSizingOptions, pointMaterialOptions, pointShapeOptions, clipTaskOptions } from './inspection-settings';

export const defectDetails = {
  Name: 'Electrical',
  Colour: 'yellow',
  ComponentCode: 'Code',
  Manufacturer: 'Manufacturer',
  Material: 'Polymer',
  DefectType: 'Surface erosion',
  Severity: 3,
  Description: 'Standard Monday wire check',
  Assets: '',
  date: 'Wed Jul 17 2019',
  'LoggedByUser.Name': 'Test user',
  points: [
    {
      name: 'Point 1',
      x: 1.19,
      y: -0.01,
      z: -3.09,
    },
  ],
  comments: [
    {
      author: 'johnjake23',
      text: 'Is this issue resolved already? Mark wants to know..',
    },
    {
      author: 'mathew_a',
      text: 'This has been hadeled already...',
    },
  ],
};

export const InitialInspectionSettings = {
  [formConstants.fields.maxPoints]: 1,
  [formConstants.fields.pointSize]: 1.3,
  [formConstants.fields.FOV]: 60,
  [formConstants.fields.EDL]: true,
  [formConstants.fields.EDLRadius]: 1.4,
  [formConstants.fields.EDLStrength]: 0.05,
  [formConstants.fields.PointSizing]: pointSizingOptions[2],
  [formConstants.fields.PointType]: pointMaterialOptions[0],
  [formConstants.fields.Opacity]: 1,
  [formConstants.fields.Quality]: pointShapeOptions[0],
  [formConstants.fields.HighQuality]: true,
  [formConstants.fields.minNodeSize]: 0,
  [formConstants.fields.zoomSpeed]: 4,
  [formConstants.fields.clipTask]: clipTaskOptions[1],
  [formConstants.fields.numberOfRings]: FEATURES.clusteringSettings.numberOfRings,
  [formConstants.fields.numberOfSlices]: FEATURES.clusteringSettings.numberOfSlices,
  [formConstants.fields.initialDistance]: FEATURES.clusteringSettings.initialDistance,
  [formConstants.fields.multiplier]: FEATURES.clusteringSettings.multiplier,
};
