import { THEMES } from '../../../common/constants';
import Icon from '../../../common/icon/components/icon';

export const pdfStatus = {
  noTag: 'NO_TAG',
  manual: 'MANUAL',
  computerVision: 'COMPUTER_VISION',
};

export const componentFilterValues = {
  all: 'ALL',
  cvFound: 'CV_FOUND',
  cvLinked: 'CV_LINKED',
};

export const tagFields = {
  tagId: 'TagID',
  drawing: 'Drawing',
};

export const componentFields = {
  componentId: 'ID',
  cvFoundID: 'CVFoundID',
  name: 'Name',
  drawing: 'Drawing',
  drawings: 'Drawings',
  taggingType: 'TaggingType',
  confirmed: 'Confirmed',
  componentCode: 'ComponentCode',
  type: 'Type',
  dateCreated: 'DateCreated',
  isTemp: 'IsTemp',
  componentType: 'ComponentType',
  inspectionId: 'InspectionID',
  taggedInAnotherFile: 'TaggedInAnotherFile',
  pageNumber: 'PageNumber',
  critical: 'Critical',
};

export const defaultRectangle = {
  opacity: 0.5,
  strokeWidth: 7,
};

export const getSelectedRectangleProps = (selectable, currentTheme) => {
  if (!THEMES) return '';
  return {
    stroke: THEMES[currentTheme].colors.severityRed,
    fill: THEMES[currentTheme].colors.severityRed,
    selectable: selectable,
  };
};

export const getSelectedComponentProps = (selected, currentTheme) => {
  if (!THEMES) return '';
  return {
    stroke: selected ? THEMES[currentTheme].colors.severityRed : THEMES[currentTheme].colors.severityGreen,
    fill: '',
    selectable: false,
  };
};

export const filterValues = {
  searchText: 'SearchText',
  componentFilter: 'ComponentFilter',
  pageNumber: 'PageNumber',
};

export const tooltipData = {
  [componentFilterValues.all]: 'COMPONENT_FILTER.TOOLTIP_ALL',
  [componentFilterValues.cvFound]: 'COMPONENT_FILTER.TOOLTIP_CV_FOUND',
  [componentFilterValues.cvLinked]: 'COMPONENT_FILTER.TOOLTIP_CV_LINKED',
};

export const defaultFilter = {
  [filterValues.searchText]: '',
  [filterValues.pageNumber]: 1,
  [filterValues.componentFilter]: componentFilterValues.all,
};

export const detailsKeys = ['Name', 'Type', 'SubType', 'Code', 'AssetType', 'Weight', 'Manufacturer', 'DateCreated'];

export const componentTagsFields = {
  uuid: 'uuid',
  pageNumber: 'pageNumber',
  deleteFileRowAction: 'DeleteFileRowAction',
  index: 'index',
};

export const componentTagsTableConfig = [
  {
    title: 'PDF_TAG.PICK_COMPONENT.NAME',
    key: componentTagsFields.uuid,
    type: componentTagsFields.uuid,
    class: 'icon-column',
  },
  {
    title: 'PAGE',
    key: componentFields.pageNumber,
    type: componentFields.pageNumber,
    class: 'created-at-column',
    isSort: false,
  },
  {
    title: '',
    type: componentTagsFields.deleteFileRowAction,
    key: componentTagsFields.deleteFileRowAction,
    isSort: false,
    class: 'action-menu-column',
    className: 'action-menu',
  },
];

export const formatComponentTagsTableCells = (
  value,
  type,
  _searchInputValue,
  row,
  _onDropdownClick,
  _t,
  _pointerDotID,
  _user,
  _onToggleClick,
  onDeleteRow,
  _statusData,
  _handleChangeStatus,
  _onLinkFileClick,
  selectedItem
) => {
  const parsedRowTagUuid = JSON.parse(row.Drawing)?.objects[0]?.uuid;
  const rowEqualsSelectedTag = parsedRowTagUuid === selectedItem?.uuid;
  if (type === componentTagsFields.uuid) {
    return (
      <p className="f-primary" style={{ color: rowEqualsSelectedTag ? '#58b895' : '' }}>
        item {row[componentTagsFields.index]}
      </p>
    );
  } else if (type === componentFields.pageNumber) {
    return <p className="f-primary">{value}</p>;
  } else if (type === componentTagsFields.deleteFileRowAction) {
    return (
      <Icon
        name="trash"
        className="pointer"
        onClick={e => {
          e.stopPropagation();
          onDeleteRow(row);
        }}
      />
    );
  } else {
    return value ? value : '-';
  }
};
