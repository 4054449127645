import { advancedDetailsMaxChars, endCheckBoxValues, formConstants, regimeNameMaxChars } from '../constants/regime-constants';

export const regimeValidate = (values, props) => {
  const errors = {};

  if (!values[formConstants.fields.title]) {
    errors[formConstants.fields.title] = 'REGIME_VALIDATION.NAME_REQUIRED';
  }

  if (values[formConstants.fields.title] && values[formConstants.fields.title].length < 3) {
    errors[formConstants.fields.title] = 'CREATE_REGIME_FORM.NAME_MIN_LENGTH';
  }

  if (values[formConstants.fields.title] && values[formConstants.fields.title].length > regimeNameMaxChars) {
    errors[formConstants.fields.title] = 'CREATE_REGIME_FORM.NAME_MAX_CHARS';
  }

  if (!values[formConstants.fields.importance]) {
    errors[formConstants.fields.importance] = 'CREATE_REGIME_FORM.IMPORTANCE_REQUIRED';
  }

  if (!values[formConstants.fields.taskIntervalNumber]) {
    errors[formConstants.fields.taskIntervalNumber] = 'CREATE_REGIME_FORM.TASK_DURATION_REQUIRED';
  }

  if (!values[formConstants.fields.startDate]) {
    errors[formConstants.fields.startDate] = 'CREATE_REGIME_FORM.START_DATE_REQUIRED';
  }

  if (values[formConstants.fields.descripton] && values[formConstants.fields.descripton].length > advancedDetailsMaxChars.description) {
    errors[formConstants.fields.descripton] = 'CREATE_REGIME_FORM.MAX_CHARS';
  }

  if (values[formConstants.fields.toolsRequired] && values[formConstants.fields.toolsRequired].length > advancedDetailsMaxChars.toolsRequired) {
    errors[formConstants.fields.toolsRequired] = 'CREATE_REGIME_FORM.MAX_CHARS';
  }

  if (values[formConstants.fields.recurringTask]) {
    // if (!values[formConstants.fields.taskIntervalNumber]) {
    //   errors[formConstants.fields.taskIntervalNumber] = 'CREATE_REGIME_FORM.REPEAT_EVERY_REQUIRED';
    // }

    // if (!values[formConstants.fields.taskIntervalId]) {
    //   errors[formConstants.fields.taskIntervalId] = 'CREATE_REGIME_FORM.PERIOS_REQUIRED';
    // }

    if (values[formConstants.fields.ends] === endCheckBoxValues.on && !values[formConstants.fields.endDate]) {
      errors[formConstants.fields.endDate] = 'CREATE_REGIME_FORM.END_DATE_REQUIRED';
    }

    if (values[formConstants.fields.ends] === endCheckBoxValues.off && !values[formConstants.fields.occurancesTreshold]) {
      errors[formConstants.fields.occurancesTreshold] = 'CREATE_REGIME_FORM.OCCURANCES_REQUIRED';
    }

    if (values[formConstants.fields.ends] === endCheckBoxValues.on && values[formConstants.fields.endDate] && values[formConstants.fields.endDate] <= values[formConstants.fields.startDate]) {
      errors[formConstants.fields.endDate] = 'CREATE_REGIME_FORM.END_DATE_VALID';
    }

    if (
      values[formConstants.fields.ends] === endCheckBoxValues.on &&
      values[formConstants.fields.endDate] &&
      new Date(values[formConstants.fields.endDate]) > new Date(new Date().setFullYear(new Date().getFullYear() + 10))
    ) {
      errors[formConstants.fields.endDate] = 'CREATE_REGIME_FORM.END_DATE_MAX';
    }
  }

  return errors;
};
