import PropTypes from 'prop-types';
import React from 'react';

import AddButton from '../../../common/add-button/components/add-button';
import { FORMS } from '../../../common/constants';
import Button from '../../../common/form/components/button';
import Loader from '../../../common/global-loader/components/simple-loader';
import PaginationNavigator from '../../../common/load-more/components/pagination-navigator';
import { defaultQuestionValidators } from '../../../common/question-components/constants/question-constants';
import Section from '../../../common/section/components/section';
import { defaultSectionQuestionsName } from '../../../common/section/constants/section-constants';
import { defaultPagingObj, filterProps } from '../constants/checklists-procedures-constants';

const ChecklistsProceduresSecondStep = (
  {
    isSectionsLoading,
    isDisabled,
    addQuestionDisabled,
    sections,
    questionTypes,
    handleUpdateSection,
    handleAddQuestion,
    handleFieldChange,
    handleFieldDelete,
    handleFieldSort,
    handleAddSection,
    handleDeleteSection,
    handleBackButtonClick,
    handleFinishButtonClick,
    components,
    handleComponentSelected,
    handleWorkAreaAdd,
    handleUpdateTagLocation,
    handleWorkAreaUpdate,
    sectionsChanged,
    handleDeleteComponent,
    handleDeleteWorkArea,
    toggleAdditionalFields,
    handleAddQuestionOption,
    handleUpdateQuestionOption,
    handleDeleteQuestionOption,
    handleSectionSort,
    inspectionId,
    onDropAsset,
    handleDeleteSectionFile,
    sectionUpdateDebounce,
    questionUpdateDebounce,
    questionOptionUpdateDebounce,
    fetchQuestionFiles,
    hideNewMeasurementPointQuestionButton,
    addNewMeasurementPointDisabled,
    handleAddNewMeasurementPointQuestion,
    loadPreviousSections,
    loadNextSections,
    sectionFilters,
    onToggleSectionExpand,
    loadPreviousQuestions,
    loadNextQuestions,
  },
  { t }
) => {
  if (isSectionsLoading) {
    return <Loader isLoading={true} />;
  }

  const renderPaginationNavigator = () => {
    if (sectionFilters[filterProps.totalNumber] <= defaultPagingObj[filterProps.perPage]) {
      return null;
    }

    const pageCurrentStart = sectionFilters[filterProps.lastSeen] ? Math.max(sectionFilters[filterProps.lastSeen] - defaultPagingObj[filterProps.perPage], 0) : 0; // clamp to 0 if negative value
    return (
      <PaginationNavigator
        isLoading={isSectionsLoading}
        className="checklists-procedures-management__container__second-step__section-pagination-navigator"
        currentStart={pageCurrentStart}
        currentEnd={pageCurrentStart + sections.length}
        totalItems={sectionFilters[filterProps.totalNumber]}
        label="CHECKLISTS_PROCEDURES_MANAGEMENT.SECTIONS.PAGINATION_INFO"
        prevButtonProps={{
          disabled: sectionFilters[filterProps.lastSeen] - defaultPagingObj[filterProps.perPage] <= 0,
          onClick: loadPreviousSections,
          text: 'CHECKLISTS_PROCEDURES_MANAGEMENT.SECTIONS.PREV_BUTTON_TEXT',
        }}
        nextButtonProps={{
          disabled: !sectionFilters[filterProps.hasNext],
          onClick: loadNextSections,
          text: 'CHECKLISTS_PROCEDURES_MANAGEMENT.SECTIONS.NEXT_BUTTON_TEXT',
        }}
      />
    );
  };

  return (
    <div className="checklists-procedures-management__container__second-step">
      {renderPaginationNavigator()}
      {sections.map((section, index) => (
        <Section
          {...section}
          form={`${FORMS.sectionsForm}-checklist-procedure-template-${section.ID}`}
          key={`section-${section.ID}`}
          initialValues={section}
          sectionIndex={index}
          sectionsLength={sections.length}
          questions={section.SectionQuestions}
          sectionQuestionsName={defaultSectionQuestionsName}
          questionValidators={defaultQuestionValidators}
          questionTypes={questionTypes}
          handleSectionChange={handleUpdateSection}
          handleAddQuestion={handleAddQuestion}
          handleFieldDelete={handleFieldDelete}
          handleFieldSort={handleFieldSort}
          handleUpdateSection={handleUpdateSection}
          handleDeleteSection={handleDeleteSection}
          handleFieldChange={handleFieldChange}
          isExpandable
          deleteDisabled={sections.length <= 1 && sectionFilters[filterProps.lastSeen] - defaultPagingObj[filterProps.perPage] <= 0}
          nonTranslatable
          components={components}
          handleComponentSelected={handleComponentSelected}
          handleWorkAreaAdd={handleWorkAreaAdd}
          handleUpdateTagLocation={handleUpdateTagLocation}
          handleWorkAreaUpdate={handleWorkAreaUpdate}
          sectionsChanged={sectionsChanged}
          handleDeleteComponent={handleDeleteComponent}
          handleDeleteWorkArea={handleDeleteWorkArea}
          toggleAdditionalFields={toggleAdditionalFields}
          handleAddQuestionOption={handleAddQuestionOption}
          handleUpdateQuestionOption={handleUpdateQuestionOption}
          handleDeleteQuestionOption={handleDeleteQuestionOption}
          handleSort={handleSectionSort}
          inspectionId={inspectionId}
          isLoading={section.isLoading}
          isDisabled={isDisabled}
          addQuestionDisabled={addQuestionDisabled}
          onDropAsset={(...args) => {
            onDropAsset(...args, section);
          }}
          handleDeleteSectionFile={handleDeleteSectionFile}
          sections={sections}
          sectionUpdateDebounce={sectionUpdateDebounce}
          questionUpdateDebounce={questionUpdateDebounce}
          questionOptionUpdateDebounce={questionOptionUpdateDebounce}
          fetchQuestionFiles={fetchQuestionFiles}
          sectionExpanded={false}
          hideNewMeasurementPointQuestionButton={hideNewMeasurementPointQuestionButton}
          addNewMeasurementPointDisabled={addNewMeasurementPointDisabled}
          handleAddNewMeasurementPointQuestion={handleAddNewMeasurementPointQuestion}
          onToggleSectionExpand={isExpanded => onToggleSectionExpand(section, isExpanded)}
          loadPreviousQuestions={() => loadPreviousQuestions(section)}
          loadNextQuestions={() => loadNextQuestions(section)}
          showQuestionsPaginationNavigator
        />
      ))}
      <div className="checklists-procedures-management__container__second-step__add-button-wrapper">
        <AddButton disabled={isDisabled} onClick={handleAddSection}>
          {'SECTIONS.ADD_SECTION'}
        </AddButton>
      </div>
      {renderPaginationNavigator()}
      <div className="checklists-procedures-management__container__second-step__buttons">
        <div className="checklists-procedures-management__container__second-step__buttons__wrapper">
          <Button
            variant="gray-outline"
            type="button"
            text={t('BACK')}
            width="sm"
            height="md"
            className="checklists-procedures-management__container__second-step__buttons__wrapper__btn"
            onClick={handleBackButtonClick}
          />
          <Button type="button" text={t('FINISH')} width="sm" height="md" onClick={handleFinishButtonClick} />
        </div>
      </div>
    </div>
  );
};

ChecklistsProceduresSecondStep.contextTypes = {
  t: PropTypes.func.isRequired,
};

ChecklistsProceduresSecondStep.propTypes = {
  isSectionsLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number.isRequired,
      SectionOrder: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
      SectionQuestions: PropTypes.arrayOf(
        PropTypes.shape({
          ID: PropTypes.number,
          Name: PropTypes.string.isRequired,
          QuestionType: PropTypes.number,
          QuestionOrder: PropTypes.number,
          IsMandatory: PropTypes.bool,
          AllowMultipleAnswers: PropTypes.bool,
          EstimatedTime: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          CreatedAt: PropTypes.number,
        })
      ),
    })
  ),
  questionTypes: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number,
      ChecklistItemType: PropTypes.string,
      ChecklistItemTypeName: PropTypes.string,
    })
  ),
  handleUpdateSection: PropTypes.func.isRequired,
  handleAddQuestion: PropTypes.func.isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  handleFieldDelete: PropTypes.func.isRequired,
  handleFieldSort: PropTypes.func.isRequired,
  handleAddSection: PropTypes.func.isRequired,
  handleDeleteSection: PropTypes.func.isRequired,
  handleBackButtonClick: PropTypes.func.isRequired,
  handleFinishButtonClick: PropTypes.func.isRequired,
  components: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
    })
  ),
  handleComponentSelected: PropTypes.func,
  sectionsChanged: PropTypes.bool,
  handleDeleteComponent: PropTypes.func.isRequired,
  toggleAdditionalFields: PropTypes.func.isRequired,
  handleAddQuestionOption: PropTypes.func.isRequired,
  handleUpdateQuestionOption: PropTypes.func.isRequired,
  handleDeleteQuestionOption: PropTypes.func.isRequired,
  handleSectionSort: PropTypes.func.isRequired,
  onDropAsset: PropTypes.func.isRequired,
  handleDeleteSectionFile: PropTypes.func.isRequired,
};

ChecklistsProceduresSecondStep.defaultProps = {
  isSectionsLoading: false,
  isDisabled: false,
  sections: [],
  questionTypes: [],
};

export default ChecklistsProceduresSecondStep;
