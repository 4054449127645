import { cloneDeep, get, isEmpty } from 'lodash';
import {
  getEquipmentFailure,
  getEquipmentStart,
  getEquipmentSuccess,
  getNotificationsFailure,
  getNotificationsStart,
  getNotificationsSuccess,
  saveDataFailureSAP,
  saveDataStartSAP,
  saveDataSuccessSAP,
  setEquipmentData,
  setInfoModalDataSAP,
  setIntegrationStatus,
  setNotificationDetails,
  setNotificationsData,
  setSAPChangesSaved,
  setSAPDetails,
  syncDataFailureSAP,
  syncDataStartSAP,
  syncDataSuccessSAP,
} from './action-creators';

import integrationApi from '../../../api/integrations/actions';

import equipmentConstants from '../forms/constants/equipment-constants';
import sapConstants from '../forms/constants/sap-constants';

export const getIntegrationDetails = projectID => {
  return async dispatch => {
    try {
      const res = await integrationApi.getDetailsSAP({ ProjectID: projectID });
      const { Data } = res.data;
      if (Data) {
        if (Data[sapConstants.formConstants.fields.activated]) {
          dispatch(setSAPChangesSaved());
        }
        dispatch(setSAPDetails(Data));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const saveChanges = (data, projectID, translation) => {
  return async dispatch => {
    try {
      dispatch(saveDataStartSAP());
      const res = await integrationApi.saveSAP({ ...data, ProjectID: projectID });
      const { Data } = res.data;
      if (Data) {
        dispatch(saveDataSuccessSAP());
        dispatch(
          setInfoModalDataSAP({
            isOpen: true,
            type: 'ok',
            title: translation('INTEGRATIONS.FORMS.SAP.SUCCESS_MESSAGE.TITLE'),
            content: translation('INTEGRATIONS.FORMS.SAP.SUCCESS_MESSAGE.CONTENT'),
            customClassName: 'modal-medium',
            closeAction: () => dispatch(setInfoModalDataSAP({ isOpen: false })),
            confirmAction: () => dispatch(setInfoModalDataSAP({ isOpen: false })),
          })
        );
      } else {
        dispatch(saveDataFailureSAP('INTEGRATIONS.FORMS.SAP.SAVE.412.REQUEST_ERROR'));
      }
    } catch (e) {
      const generalCode = get(e, 'response.status');
      if (generalCode === 412) {
        dispatch(saveDataFailureSAP('INTEGRATIONS.FORMS.SAP.SAVE.412.REQUEST_ERROR'));
      }
      console.log(e);
    }
  };
};

export const synchronise = (data, projectID, translation) => {
  return async dispatch => {
    try {
      dispatch(syncDataStartSAP());
      dispatch(setIntegrationStatus(sapConstants.integrationSatus.inProgress));
      await integrationApi.syncSAP();
      // SUCCESS
      dispatch(syncDataSuccessSAP());
      dispatch(getIntegrationStatus(projectID));
      dispatch(
        setInfoModalDataSAP({
          isOpen: true,
          type: 'ok',
          title: translation('INTEGRATIONS.FORMS.SAP.SYNC.SUCCESS_MESSAGE.TITLE'),
          content: translation('INTEGRATIONS.FORMS.SAP.SYNC.SUCCESS_MESSAGE.CONTENT'),
          customClassName: 'modal-medium',
          closeAction: () => dispatch(setInfoModalDataSAP({ isOpen: false })),
          confirmAction: () => dispatch(setInfoModalDataSAP({ isOpen: false })),
        })
      );
    } catch (e) {
      const generalCode = get(e, 'response.status');
      if (generalCode === 412) {
        dispatch(syncDataFailureSAP('INTEGRATIONS.FORMS.SAP.SYNC.412.REQUEST_ERROR'));
      }
      console.log(e);
    }
  };
};

export const getEquipment = projectID => {
  return async dispatch => {
    try {
      dispatch(getEquipmentStart());
      const res = await integrationApi.fetchProjectEquipment({ ProjectID: projectID });
      const { Data } = res.data;
      dispatch(getEquipmentSuccess());
      if (Data && !isEmpty(Data.Locations)) {
        const copyArray = cloneDeep(Data.Locations);
        copyArray.forEach(item => {
          setPropertyValueRecursive(
            item,
            [
              { key: 'selected', value: false },
              { key: 'active', value: false },
            ],
            equipmentConstants.formConstants.fields.children
          );
        });

        dispatch(setEquipmentData(copyArray));
      } else {
        dispatch(setEquipmentData([]));
      }
    } catch (e) {
      dispatch(getEquipmentFailure());
      console.log(e);
    }
  };
};

export const selectLocations = (equipment, projectID, closePopup, translation) => {
  return async dispatch => {
    try {
      const selectedLocations = filterLocations(equipment, equipmentConstants.formConstants.fields.children, item => item.selected === true);
      const convertedLocations = selectedLocations.map(location => location[equipmentConstants.formConstants.fields.id]);
      await integrationApi.selectLocations({ ProjectID: projectID, LocationNames: convertedLocations });
      closePopup();
      dispatch(
        setInfoModalDataSAP({
          isOpen: true,
          type: 'ok',
          title: translation('INTEGRATIONS.FORMS.SAP.SELECT_EQUIPMENT.SUCCESS_MESSAGE.TITLE'),
          content: translation('INTEGRATIONS.FORMS.SAP.SELECT_EQUIPMENT.SUCCESS_MESSAGE.CONTENT'),
          customClassName: 'modal-medium',
          closeAction: () => dispatch(setInfoModalDataSAP({ isOpen: false })),
          confirmAction: () => dispatch(setInfoModalDataSAP({ isOpen: false })),
        })
      );
    } catch (e) {
      console.log(e);
    }
  };
};

export const getIntegrationStatus = projectID => {
  return async dispatch => {
    try {
      const res = await integrationApi.getIntegrationStatus({ ProjectID: projectID });
      const { Data } = res.data;
      if (Data && Data.SyncStatus) {
        // SUCCESS
        dispatch(setIntegrationStatus(Data.SyncStatus));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

const filterLocations = (arr, nestingKey, filterFunction, resultArray = []) => {
  if (isEmpty(arr)) {
    return resultArray;
  } else {
    arr.forEach(item => {
      if (filterFunction(item)) {
        resultArray.push(item);
      }
      resultArray = [...resultArray, ...filterLocations(item[nestingKey], nestingKey, filterFunction)];
    });
    return resultArray;
  }
};

const findItemNested = (arr, itemId, nestingKey) => {
  /* eslint-disable array-callback-return */
  return arr.reduce((a, item) => {
    if (a) return a;
    if (item[equipmentConstants.formConstants.fields.id] === itemId) return item;
    if (item[nestingKey]) return findItemNested(item[nestingKey], itemId, nestingKey);
  }, null);
  /* eslint-enable array-callback-return */
};

const setPropertyValueRecursive = (item, propsArray, nestingKey) => {
  if (isEmpty(item[nestingKey])) {
    propsArray.forEach(prop => {
      item[prop.key] = prop.value;
    });
  } else {
    propsArray.forEach(prop => {
      item[prop.key] = prop.value;
    });
    item[nestingKey].forEach(nestedEl => setPropertyValueRecursive(nestedEl, propsArray, nestingKey));
  }
  return item;
};

export const collapseEquipmentItem = (id, equipmentArray) => {
  return async dispatch => {
    try {
      // dispatch(saveDataStartSAP());
      const copyArray = cloneDeep(equipmentArray);
      const foundItem = findItemNested(copyArray, id, equipmentConstants.formConstants.fields.children);
      if (!isEmpty(foundItem)) {
        foundItem.active = !foundItem.active;
        dispatch(setEquipmentData(copyArray));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const selectEquipmentItem = (id, equipmentArray) => {
  return async dispatch => {
    try {
      // dispatch(saveDataStartSAP());
      const copyArray = cloneDeep(equipmentArray);
      const foundItem = findItemNested(copyArray, id, equipmentConstants.formConstants.fields.children);
      if (!isEmpty(foundItem)) {
        setPropertyValueRecursive(foundItem, [{ key: 'selected', value: !foundItem.selected }], equipmentConstants.formConstants.fields.children);
        // foundItem.selected = !foundItem.selected;
        // if (!isEmpty(foundItem[equipmentConstants.formConstants.fields.children])) {

        //   foundItem[equipmentConstants.formConstants.fields.children].forEach(item => (item.selected = foundItem.selected));
        // }
        dispatch(setEquipmentData(copyArray));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const resetForm = () => {
  return async dispatch => {
    try {
      dispatch(setSAPChangesSaved(false));
    } catch (e) {
      console.log(e);
    }
  };
};

// NOTIFICATIONS

export const getNotificationDetails = notification => {
  return async dispatch => {
    try {
      // const res = await integrationApi.getNotificationDetails({ NotificationID: notification[notificationFields.fields.notificationId] });
      // const { Data } = res.data;
      // if (Data) {
      //   console.log(Data);
      //   dispatch(setNotificationDetails(Data));
      // }else{
      //   dispatch(setNotificationDetails(null));
      // }

      dispatch(setNotificationDetails({ ...notification }));
    } catch (e) {
      console.log(e);
    }
  };
};

export const getNotifications = (projectID, inspectionID, searchText) => {
  return async dispatch => {
    try {
      dispatch(getNotificationsStart());
      const res = await integrationApi.getNotifications({ InspectionID: inspectionID, ProjectID: projectID, SearchText: searchText });
      const { Data } = res.data;
      dispatch(getNotificationsSuccess());
      if (Data && Data.Notifications) {
        dispatch(setNotificationsData(Data.Notifications));
      } else {
        dispatch(setNotificationsData([]));
      }
    } catch (e) {
      dispatch(getNotificationsFailure());
      console.log(e);
    }
  };
};
