export const fields = {
  isolationTemplate: {
    name: 'IsolationTemplateID',
    label: 'ISOLATION_CERTIFICATES_FORM.ISOLATION_TEMPLATE_LABEL',
    placeholder: 'ISOLATION_CERTIFICATES_FORM.ISOLATION_TEMPLATE_PLACEHOLDER',
    errorRequired: 'ISOLATION_CERTIFICATES_FORM.ISOLATION_TEMPLATE_REQUIRED',
    id: 'isolation-certificate-template',
  },
  name: {
    name: 'Name',
    label: 'ISOLATION_CERTIFICATES_FORM.NAME_LABEL',
    placeholder: 'ISOLATION_CERTIFICATES_FORM.NAME_PLACEHOLDER',
    errorRequired: 'ISOLATION_CERTIFICATES_FORM.NAME_REQUIRED',
    minChars: 3,
    maxChars: 60,
    errorMinChars: 'ISOLATION_CERTIFICATES_FORM.NAME_MIN_CHARS',
    errorMaxChars: 'ISOLATION_CERTIFICATES_FORM.NAME_MAX_CHARS',
    id: 'isolation-certificate-name',
  },
  description: {
    name: 'Description',
    label: 'ISOLATION_CERTIFICATES_FORM.DESCRIPTION_LABEL',
    placeholder: 'ISOLATION_CERTIFICATES_FORM.DESCRIPTION_PLACEHOLDER',
    maxChars: 300,
    errorMaxChars: 'ISOLATION_CERTIFICATES_FORM.DESCRIPTION_MAX_CHARS',
    id: 'isolation-certificate-description',
  },
  startDate: {
    name: 'StartDate',
    label: 'ISOLATION_CERTIFICATES_FORM.START_DATE_LABEL',
    placeholder: 'ISOLATION_CERTIFICATES_FORM.START_DATE_PLACEHOLDER',
    errorRequired: 'ISOLATION_CERTIFICATES_FORM.START_DATE_REQUIRED',
    errorMinDate: 'ISOLATION_CERTIFICATES_FORM.START_DATE_MIN_DATE',
    id: 'isolation-certificate-start-date',
  },
  endDate: {
    name: 'EndDate',
    label: 'ISOLATION_CERTIFICATES_FORM.END_DATE_LABEL',
    placeholder: 'ISOLATION_CERTIFICATES_FORM.END_DATE_PLACEHOLDER',
    errorRequired: 'ISOLATION_CERTIFICATES_FORM.END_DATE_REQUIRED',
    errorMinDate: 'ISOLATION_CERTIFICATES_FORM.END_DATE_MIN_DATE',
    id: 'isolation-certificate-end-date',
  },
};

export const filterFields = {
  projectID: 'ProjectID',
  searchText: 'SearchText',
  status: 'StatusFilter',
  sortByColumn: 'SortByColumn',
  sortByDirection: 'SortByDirection',
  isolationTemplate: 'IsolationTemplateFilter',
  dateFrom: 'DateFrom',
  dateTo: 'DateTo',
  liveFrom: 'LiveFrom',
  liveTo: 'LiveTo',
  deisolatedFrom: 'DeIsolatedFrom',
  deisolatedTo: 'DeIsolatedTo',
  createdBy: 'CreatedByFilter',
  markedAsLiveBy: 'LiveByFilter',
  markedAsDeisolatedBy: 'DeIsolatedByFilter',
  perPage: 'PerPage',
  lastSeen: 'LastSeen',
  hasNext: 'HasNext',
  totalItems: 'TotalItems',
};

export const sortDirection = {
  asc: 'ASC',
  desc: 'DESC',
};

export const defaultFilter = {
  [filterFields.searchText]: '',
  [filterFields.sortByColumn]: 'ID',
  [filterFields.status]: ['LIVE'],
  [filterFields.sortByDirection]: sortDirection.desc,
  [filterFields.perPage]: Number.MAX_SAFE_INTEGER,
  [filterFields.lastSeen]: 0,
};

export const isolationTemplateFields = {
  id: 'ID',
  name: 'Name',
};
