import React from 'react';
import { Field, reduxForm } from 'redux-form';
import CustomInput from '../../../../../common/form/components/input';
import { FORMS } from '../../../../../common/constants';
import { confirmModalInputFields as fields } from '../../../constants/constants';
import PropTypes from 'prop-types';
import { validate } from '../../../validators/confirm-modal-form';
import Button from '../../../../../common/form/components/button';

const ConfirmModalForm = ({ handleSubmit, customCloseAction }, { t }) => {
  return (
    <form className="component-hierarchy__modal__confirm__form" onSubmit={handleSubmit} noValidate>
      <Field id={fields.password.id} name={fields.password.name} component={CustomInput} placeholder={t(fields.password.placeholder)} label={t(fields.password.label)} type="password" size="lg" />
      <Button text={t('COMPONENT_HIERARCHY_MODAL.CONFIRM')} variant="danger" className="modal-button-custom h-md" />
      <Button onClick={customCloseAction} type="button" text={t('COMPONENT_HIERARCHY_MODAL.CANCEL')} variant="danger-outline" className="modal-button-custom h-md" />
    </form>
  );
};

ConfirmModalForm.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default reduxForm({
  form: FORMS.confirmModalFormCH,
  validate,
})(ConfirmModalForm);
