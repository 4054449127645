import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import GoogleMapReact from 'google-map-react';

import { isEmpty } from 'lodash';

import Helpers from '../../helpers';

import { validate } from '../validators/download-report-validators';

import Textarea from '../../form/components/text-area';
import Select from '../../form/components/select';
import Button from '../../form/components/button';
import ToggleField from '../../form/components/toggle-field';
import CustomDropzone from '../../upload/components/upload-dropzone';
import InspectionView from '../../../app/inspections/potree/components/main-view';
import InspectionRenderer from '../../../app/inspections/components/left-toolbar/inspection-renderer';

import { FORMS, GOOGLE_API_KEY } from '../../constants';

import constants from '../constants/download-report-constants';
import { mapSettings } from '../../../app/project/constants/map-settings';

import RenderIf from '../../render-if/components/render-if';

import '../../../app/project/styles/map.scss';

class DownloadReportModal extends Component {
  constructor(props) {
    super(props);
    this.parentRef = React.createRef();
  }

  renderMarkers = (map, maps) => {
    const { projects, projectId } = this.props;

    const currentProject = projects.find(project => parseInt(project.ID) === parseInt(projectId));

    return currentProject
      ? new maps.Marker({
          position: { lat: currentProject.Location.Latitude, lng: currentProject.Location.Longitude },
          map,
          title: currentProject.Name,
        })
      : null;
  };

  getMapOptions = maps => {
    return {
      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.ROADMAP,
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.DEFAULT,
        position: maps.ControlPosition.TOP_RIGHT,
        mapTypeIds: [maps.MapTypeId.ROADMAP, maps.MapTypeId.SATELLITE, maps.MapTypeId.HYBRID, maps.MapTypeId.TERRAIN],
      },
    };
  };

  render() {
    const { t } = this.context;
    const {
      sortByFilter,
      handleSubmit,
      fetchingReportData,
      downloadReport,
      nextStep,
      previousStep,
      step,
      onDrop,
      selectedFileUrl,
      selectedFileName,
      createViewerInstance,
      viewer,
      potreeId,
      defects,
      mapId,
      invalid,
      removeImage,
      defectsAffected,
    } = this.props;

    return (
      <form className="download-report-form" onSubmit={handleSubmit ? handleSubmit(vals => downloadReport(vals)) : () => null}>
        {step === constants.steps.firstStep && (
          <Fragment>
            <p className="f-primary defects-affected">{t('DOWNLOAD_REPORT.DEFECTS_AFFECTED', { defectsAffected })}</p>

            <Field
              id={constants.fields.description}
              name={constants.fields.description}
              component={Textarea}
              placeholder={'DOWNLOAD_REPORT.FORM_DESCRIPTION_PLACEHOLDER'}
              label={'DOWNLOAD_REPORT.FORM_DESCRIPTION'}
              sublabel={t('DOWNLOAD_REPORT.FORM_DESCRIPTION_SUBLABEL')}
              labelClass="f-secondary-dark defect-form__label"
              enableResize={true}
              hideBorder={true}
              maxChars={constants.descriptionMaxChars}
            />
            <Field
              id={constants.fields.recomendations}
              name={constants.fields.recomendations}
              component={Textarea}
              placeholder={'DOWNLOAD_REPORT.FORM_RECOMENDATION_PLACEHOLDER'}
              label={'DOWNLOAD_REPORT.FORM_RECOMENDATION'}
              sublabel={t('DOWNLOAD_REPORT.FORM_RECOMENDATION_SUBLABEL')}
              labelClass="f-secondary-dark defect-form__label"
              enableResize={true}
              hideBorder={true}
              maxChars={constants.descriptionMaxChars}
            />
          </Fragment>
        )}
        {step === constants.steps.secondStep && (
          <Fragment>
            <div className="split-field">
              <Field
                label={'DOWNLOAD_REPORT.SORT_BY'}
                id={constants.fields.sortBy}
                name={constants.fields.sortBy}
                component={Select}
                valueField={constants.objectConstruction.value}
                textField={constants.objectConstruction.key}
                disabled={isEmpty(sortByFilter)}
                data={sortByFilter || []}
                defaultValue={sortByFilter && sortByFilter[0]}
                placeholder={t('DOWNLOAD_REPORT.SELECT_SORT_BY')}
              />
              <div className="cover-upload field-wrapper">
                <p className="f-primary">{t('DOWNLOAD_REPORT.UPLOAD_COVER_PAGE')}</p>
                <CustomDropzone
                  onDrop={onDrop}
                  labelUnderImage={t('DOWNLOAD_REPORT.CHOOSE_FILE')}
                  supportedMimeTypes={constants.supportedMimeTypes}
                  label={t('DOWNLOAD_REPORT.UPLOAD_COVER_PAGE_DESCTIPTION')}
                  CustomComponent={
                    selectedFileUrl
                      ? () => (
                          <div className="label-after-image">
                            <label
                              className="clickable file-name"
                              onClick={e => {
                                Helpers.openInNewTab(e, selectedFileUrl);
                              }}
                            >
                              {selectedFileName}
                            </label>
                            <label className="close clickable" onClick={removeImage}>
                              x
                            </label>
                          </div>
                        )
                      : () => <label className="label-after-image clickable">{t('DOWNLOAD_REPORT.SELECTED_FILE_NO_FILE')}</label>
                  }
                />
              </div>
            </div>

            <Field
              id={constants.fields.imagesPerPage}
              name={constants.fields.imagesPerPage}
              component={ToggleField}
              label={t('DOWNLOAD_REPORT.IMAGES_PER_PAGE')}
              sublabel={t('DOWNLOAD_REPORT.IMAGES_PER_PAGE_SUBLABEL')}
              className={'multiple-labels-toggle'}
              rightValue={constants.imagesPerPageValues.rightValue}
              leftValue={constants.imagesPerPageValues.leftValue}
            />
            <Field
              id={constants.fields.includeComponentDetails}
              name={constants.fields.includeComponentDetails}
              component={ToggleField}
              label={t('DOWNLOAD_REPORT.INCLUDE_COMPONENT_DETAILS')}
              sublabel={t('DOWNLOAD_REPORT.INCLUDE_COMPONENT_DETAILS_SUBLABEL')}
              className={'multiple-labels-toggle'}
            />
            <Field
              id={constants.fields.includeDefectSummary}
              name={constants.fields.includeDefectSummary}
              component={ToggleField}
              label={t('DOWNLOAD_REPORT.INCLUDE_DEFECT_SUMMARY')}
              sublabel={t('DOWNLOAD_REPORT.INCLUDE_DEFECT_SUMMARY_SUBLABEL')}
              className={'multiple-labels-toggle'}
            />
            <Field
              id={constants.fields.includeRecommendationSummary}
              name={constants.fields.includeRecommendationSummary}
              component={ToggleField}
              label={t('DOWNLOAD_REPORT.INCLUDE_RECOMENDATION_SUMMARY')}
              sublabel={t('DOWNLOAD_REPORT.INCLUDE_RECOMENDATION_SUMMARY_SUBLABEL')}
              className={'multiple-labels-toggle'}
            />
          </Fragment>
        )}
        {step === constants.steps.thirdStep && (
          <Fragment>
            <p className="f-secondary-dark screenshot-description">{t('DOWNLOAD_REPORT.INSPECTION_VIEW_DESCRIPTION')}</p>
            <InspectionView parentRef={this.parentRef} isWorkflow={false} potreeId={potreeId} createViewerInstance={createViewerInstance} viewer={viewer} showCameras={false} />
            <RenderIf if={viewer}>
              <InspectionRenderer
                deselectAll={() => null}
                selectAll={() => null}
                toggleElement={() => null}
                deselectAllTemp={() => null}
                selectAllTemp={() => null}
                toggleElementTemp={() => null}
                selectElement={() => null}
                // elements={(defects || []).map(el => ({ ...el, variant: 1 }))}
                elements={defects}
                disableMove={true}
                viewer={viewer}
              >
                {() => null}
              </InspectionRenderer>
            </RenderIf>
          </Fragment>
        )}
        {step === constants.steps.fourthStep && (
          <Fragment>
            <p className="f-secondary-dark screenshot-description">{t('DOWNLOAD_REPORT.GOOGLE_MAPS_DESCRIPTION')}</p>
            <div id={mapId} className="google-map">
              <div className="map-wrapper">
                <GoogleMapReact
                  bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
                  defaultZoom={2}
                  defaultCenter={{ lat: mapSettings.defaultLocation.lat, lng: mapSettings.defaultLocation.lng }}
                  options={this.getMapOptions}
                  onGoogleApiLoaded={({ map, maps }) => this.renderMarkers(map, maps)}
                  yesIWantToUseGoogleMapApiInternals
                />
              </div>
            </div>
          </Fragment>
        )}
        {step === constants.steps.fifthStep && (
          <div className="report-complete">
            <p>{t('DOWNLOAD_REPORT.STEPPER_COMPLETION_MESSAGE')}</p>
          </div>
        )}
        {!handleSubmit && (
          <div className="multiple-buttons">
            <Button type="button" disabled={fetchingReportData || !previousStep} width="sm" text={t('DOWNLOAD_REPORT.PREVIOUS_STEP')} onClick={previousStep} />
            <Button type="button" disabled={fetchingReportData || !nextStep || invalid} width="sm" text={t('DOWNLOAD_REPORT.NEXT_STEP')} onClick={nextStep} />
          </div>
        )}
        {handleSubmit && (
          <div className="multiple-buttons">
            <Button type="button" disabled={fetchingReportData || !previousStep} width="sm" text={t('DOWNLOAD_REPORT.PREVIOUS_STEP')} onClick={previousStep} />
            <Button disabled={fetchingReportData} width="sm" text={t('DOWNLOAD_REPORT.DOWNLOAD_BUTTON')} />
          </div>
        )}
      </form>
    );
  }
}
DownloadReportModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

DownloadReportModal = reduxForm({
  form: FORMS.downloadReport,
  enableReinitialize: true,
  validate,
})(DownloadReportModal);

export default DownloadReportModal;
