import { capitalize, values } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import CustomSelect from '../../../../common/form/components/select';

import { formConstants, workOrderSubTypes } from './constants/constants';

import '../../styles/choose-notification-form.scss';

const ChooseNotificationForm = ({ workorderTypes, additionalWorkOrderTypes }, { t }) => {
  return (
    <div className="choose-notification-form">
      <div className="inputs-container">
        <div className="single-input-wrapper">
          <Field
            id={formConstants.fields.subType}
            name={formConstants.fields.subType}
            data={values(workOrderSubTypes)}
            component={CustomSelect}
            label={t('CHOOSE_WORK_ORDER_NOTIFICATION.SUB_TYPE_LABEL')}
            placeholder={t('CHOOSE_WORK_ORDER_NOTIFICATION.SUB_TYPE_LABEL')}
            valueComponent={item => capitalize(item.text)}
            renderListItem={item => capitalize(item.text)}
            size="lg"
          />
        </div>
        <div className="inputs-row">
          <div className="multi-input-wrapper half-width">
            <Field
              id={formConstants.fields.notificationType}
              name={formConstants.fields.notificationType}
              data={workorderTypes}
              component={CustomSelect}
              label={t('CHOOSE_WORK_ORDER_NOTIFICATION.TYPE_LABEL')}
              placeholder={t('CHOOSE_WORK_ORDER_NOTIFICATION.TYPE_LABEL')}
              size="lg"
              valueComponent={item => capitalize(item.text)}
              renderListItem={item => capitalize(item.text)}
            />
          </div>
          <div className="multi-input-wrapper half-width">
            <Field
              label={t('CHOOSE_WORK_ORDER_NOTIFICATION.WO_SUB_TYPE_LABEL')}
              placeholder={t('CHOOSE_WORK_ORDER_NOTIFICATION.WO_SUB_TYPE_LABEL')}
              id={formConstants.fields.additionalSubType}
              name={formConstants.fields.additionalSubType}
              component={CustomSelect}
              data={additionalWorkOrderTypes ? values(additionalWorkOrderTypes) : []}
              size={'lg'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ChooseNotificationForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

ChooseNotificationForm.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number,
      Name: PropTypes.string,
      Type: PropTypes.string,
    })
  ),
  workorderTypes: PropTypes.arrayOf(PropTypes.string),
  workorderNotifications: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number,
      Name: PropTypes.string,
      Type: PropTypes.string,
    })
  ),
  selWorkorderType: PropTypes.string,
  clearNotifications: PropTypes.func,
  addNotification: PropTypes.func,
  removeNotification: PropTypes.func,
  closeConfirmModal: PropTypes.func,
};

export default ChooseNotificationForm;
