import { isEmpty, values } from 'lodash';
import React, { Fragment } from 'react';
import { IndexRoute, Redirect, Route, Router, browserHistory } from 'react-router';

import App from '../App';
import CommonComponent from '../app/shared/common-component/components/common-component';

import { DISABLED_FEATURES, GENERIC_QUERY_PARAMS } from '../common/constants';
import routesConstants from '../common/routes-constants';
import { composeEnterHooksSeries } from '../common/routes-helper';

import { dispatchUserLogoutWired } from '../app/login/actions/login-actions';

import Helpers from '../common/helpers';

import { store } from '../index';

import '../common/styles/global-style.scss';

const Routes = props => {
  const isAuthenticated = (nextState, replace) => {
    const { Token } = store.getState().userReducer;
    if (!Token || !Token.Token) {
      const { location } = nextState;
      const { pathname, search } = location;
      const redirectTo = `${pathname}${search}`;
      const params = !isEmpty(redirectTo) ? [{ redirectTo }] : null;
      Helpers.goTo(routesConstants.routes.unProtectedRoutes.login.fullPath, params);
      return false;
    }

    return true;
  };

  const isDisabledForUser = nextState => {
    const { Features, Token } = store.getState().userReducer;
    const {
      location: { pathname, query },
    } = nextState;

    if (Token && Token.Token) {
      if (pathname === routesConstants.routes.protectedRoutes.landing.fullPath && !query.type) {
        Helpers.goTo(routesConstants.defaultRoute);
        return false;
      }

      if (Features) {
        if (pathname === routesConstants.routes.protectedRoutes.inspectionWorkflow.fullPath || pathname === routesConstants.routes.protectedRoutes.startWorkflow.fullPath) {
          const isDisabledForUser = Helpers.checkIsFeatureDisabled(DISABLED_FEATURES.workflow, Features);
          if (isDisabledForUser) {
            Helpers.goTo(routesConstants.routes.unProtectedRoutes.login.fullPath);
            return false;
          }
        }
      }
    }

    return true;
  };

  const checkRedirect = nextState => {
    const { Token } = store.getState().userReducer;
    if (Token && Token.Token) {
      const location = nextState.location;
      //pop user into the app if he is logged in
      const forceLogin = ((location || {}).query || {})[GENERIC_QUERY_PARAMS.forceLogin];
      if (!forceLogin) {
        if (
          [
            routesConstants.routes.unProtectedRoutes.login.fullPath,
            routesConstants.routes.unProtectedRoutes.register.fullPath,
            routesConstants.routes.unProtectedRoutes.resetPassword.fullPath,
            routesConstants.routes.unProtectedRoutes.setPassword.fullPath,
            '/',
          ].indexOf(location.pathname) > -1
        ) {
          Helpers.goTo(routesConstants.routes.protectedRoutes.project.fullPath);
          return false;
        }
      } else {
        dispatchUserLogoutWired();
        return false;
      }
    }
    return true;
  };

  const renderRoutes = (routes, additionalFunc) => {
    const additoinalChecks = additionalFunc ? { onEnter: additionalFunc } : {};

    if (routes && routes.length > 0) {
      return routes.map((route, index) => {
        if (route.subPaths) {
          return (
            <Fragment key={index}>
              {!isEmpty(route.redirectTo) && <Redirect from={route.path} to={route.redirectTo.pathname} />}
              <Route {...route} path={route.path} component={route.component}>
                {renderRoutes(values(route.subPaths))}
              </Route>
            </Fragment>
          );
        } else {
          return (
            <Fragment key={index}>
              {!isEmpty(route.redirectTo) && <Redirect from={route.path} to={route.redirectTo.pathname} />}
              <Route {...route} path={route.path} component={route.component} {...additoinalChecks} />
            </Fragment>
          );
        }
      });
    } else return null;
  };

  return (
    <Router history={browserHistory}>
      <Route path="/" component={App}>
        <IndexRoute component={routesConstants.routes.unProtectedRoutes.login.component} onEnter={composeEnterHooksSeries(checkRedirect)} />
        {renderRoutes(values(routesConstants.routes.unProtectedRoutes), composeEnterHooksSeries(checkRedirect))}
        <Route path="/app" onEnter={composeEnterHooksSeries(isAuthenticated, isDisabledForUser)} component={CommonComponent}>
          {renderRoutes(values(routesConstants.routes.protectedRoutes))}
          <Route path="*" {...routesConstants.routes.unProtectedRoutes.notFound} />
        </Route>
        <Route path="*" {...routesConstants.routes.unProtectedRoutes.notFound} />
        <Redirect from="*" to={routesConstants.routes.unProtectedRoutes.notFound.fullPath} />
      </Route>
    </Router>
  );
};

export default Routes;

export const history = browserHistory;
