import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { filter, map, pick } from 'lodash';

import Tabs from '../../../common/tabs/component/tabs';
import Tab from '../../../common/tabs/component/tab';
import KeyboxDetails from './keybox-details';

import { toolbarItems, tabNames, fields, singleKeyFields, keyFields, statusTypes } from '../constants/constants';

import { getKeyboxDetails, deleteKeybox, editKeyboxDetails, deleteKey, addKey, updateKeyName } from '../actions/keybox-actions';
import Helpers from '../../../common/helpers';
import { routes } from '../../../common/routes-constants';

import '../styles/keybox-wrapper.scss';

class KeyboxWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeToolbarItem: toolbarItems[tabNames.details].name,
      keybox: null,
      isLoading: false,
    };
  }

  componentDidMount = () => {
    const { selected_item } = this.props;
    if (selected_item) {
      this.fetchData();
    }
  };

  componentDidUpdate = prevProps => {
    const { selected_item } = this.props;
    if (selected_item && selected_item !== prevProps.selected_item) {
      this.fetchData();
    }
  };

  fetchData = (tab = tabNames.details) => {
    const { getKeyboxDetails, selected_item } = this.props;
    if (tab === tabNames.details) {
      getKeyboxDetails(
        selected_item,
        result => {
          this.setState(prevProps => ({
            ...prevProps,
            ...result,
          }));
        },
        result => {
          this.setState(prevProps => ({
            ...prevProps,
            keybox: null,
            ...result,
          }));
        }
      );
    }
  };

  deleteKeybox = callbackSuccess => {
    const { deleteKeybox, deleteCallback, location } = this.props;
    const { query } = location;
    const { keybox } = this.state;

    deleteKeybox(keybox[fields.id], result => {
      this.setState(prevProps => ({
        ...prevProps,
        ...result,
      }));

      deleteCallback(keybox[fields.id]);
      callbackSuccess();
      Helpers.goTo(routes.protectedRoutes.keybox.fullPath, [{ project_id: query.project_id }, { inspection_id: query.inspection_id }]);
    });
  };

  issueKey = (key, contractor, closeModal) => {
    const { keybox } = this.state;
    const { issueKey } = this.props;

    issueKey(keybox[fields.id], key, contractor, () => {
      this.setState(prevState => ({
        keybox: {
          ...prevState.keybox,
          [fields.status]: prevState.keybox[fields.status] !== statusTypes.locked ? statusTypes.locked : prevState.keybox[fields.status],
          [fields.keys]: map(prevState.keybox[fields.keys], item => {
            if (item[keyFields.id] === key[keyFields.id]) {
              return { ...item, [singleKeyFields.issuedTo]: contractor.Name, [singleKeyFields.issuedToId]: contractor.ID };
            }
            return item;
          }),
        },
      }));
      closeModal();
    });
  };

  returnKey = (keyID, closeModal) => {
    const { keybox } = this.state;
    const { returnKey } = this.props;

    returnKey(keybox[fields.id], keyID, () => {
      const newKeys = map(keybox[fields.keys], item => {
        if (item[keyFields.id] === keyID) {
          // sets the permit name and issued to properties to null
          return { ...item, [singleKeyFields.permitName]: null, [singleKeyFields.issuedTo]: null };
        }
        return item;
      });
      const isLastKeyReturned =
        filter(newKeys, item => {
          return item[singleKeyFields.issuedTo];
        }).length === 0;

      this.setState(prevState => ({
        keybox: {
          ...prevState.keybox,
          [fields.status]: isLastKeyReturned ? statusTypes.notInUse : prevState.keybox[fields.status],
          [fields.keys]: newKeys,
        },
      }));
      closeModal();
    });
  };

  render() {
    const { openEditKeybox, editCallback, editKeyboxDetails, deleteKey, addKey, openConfirmationPopup, updateKeyName, openIssueKeyPopup } = this.props;
    const { activeToolbarItem, keybox, isLoading } = this.state;

    return (
      <div className="keybox-wrapper">
        <Tabs defaultTabKey={activeToolbarItem} navigationClassName="component-details__tabs" onChange={this.fetchData}>
          <Tab title={toolbarItems[tabNames.details].label} tabKey={tabNames.details}>
            <KeyboxDetails
              keybox={keybox}
              isLoading={isLoading}
              returnKey={this.returnKey}
              deleteKeybox={this.deleteKeybox}
              openConfirmationPopup={openConfirmationPopup}
              openIssueKeyPopup={key => {
                openIssueKeyPopup(key, this.issueKey);
              }}
              openEditKeybox={() =>
                openEditKeybox(
                  keybox,
                  incomingKeybox => {
                    editKeyboxDetails(incomingKeybox, newKeybox => {
                      editCallback(newKeybox);
                      const res = pick(newKeybox, [fields.name, fields.description]);
                      this.setState({ keybox: { ...keybox, ...res } });
                    });
                  },
                  (keyID, successCallback) => {
                    deleteKey(keybox[fields.id], keyID, () => {
                      successCallback();

                      this.setState(prevState => ({
                        ...prevState.keybox,
                        keybox: {
                          ...prevState.keybox,
                          [fields.keys]: filter(prevState.keybox[fields.keys], item => {
                            return item[singleKeyFields.id] !== keyID;
                          }),
                        },
                      }));
                    });
                  },
                  (key, successCallback) => {
                    addKey(keybox[fields.id], key, newKey => {
                      successCallback(newKey);
                      this.setState(prevState => ({
                        ...prevState,
                        keybox: {
                          ...prevState.keybox,
                          [fields.keys]: [...(prevState.keybox[fields.keys] || []), newKey],
                        },
                      }));
                    });
                  },
                  (key, callback) => {
                    updateKeyName(key, () => {
                      this.setState(prevState => ({
                        ...prevState,
                        keybox: {
                          ...prevState.keybox,
                          [fields.keys]: (prevState.keybox[fields.keys] || []).map(item => {
                            if (item[keyFields.id] === key[keyFields.id]) {
                              return { ...item, ...key };
                            }
                            return item;
                          }),
                        },
                      }));

                      if (callback) {
                        callback();
                      }
                    });
                  }
                )
              }
            />
          </Tab>
        </Tabs>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getKeyboxDetails: (id, successCallback, errorCallback) => dispatch(getKeyboxDetails(id, successCallback, errorCallback)),
  deleteKeybox: (id, successCallback) => dispatch(deleteKeybox(id, successCallback)),
  editKeyboxDetails: (values, successCallback) => dispatch(editKeyboxDetails(values, successCallback)),
  deleteKey: (keyboxID, keyID, successCallback) => dispatch(deleteKey(keyboxID, keyID, successCallback)),
  addKey: (keyboxID, key, successCallback) => dispatch(addKey(keyboxID, key, successCallback)),
  updateKeyName: (key, successCallback) => dispatch(updateKeyName(key, successCallback)),
});

KeyboxWrapper.propTypes = {};

KeyboxWrapper.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(KeyboxWrapper);
