import React, { Component } from 'react';
import { isEmpty } from 'lodash';

import SectionEmptyState from './section-empty-state';
import ChangeStatusSignatures from './change-status-signatures';
import ChangeStatusQuestionsDisplay from './change-status-questions-display';
import Icon from '../../../../../common/icon/components/icon';
import RenderIf from '../../../../../common/render-if/components/render-if';
import Helpers from '../../../../../common/helpers';
import { questionTypes } from '../../../../../common/question-components/constants/question-constants';
import LoadMore from '../../../../../common/load-more/components/load-more';
import { fields } from '../isolation-certificate-details/constants/change-status-constants';

import '../../../styles/module-change-status-section.scss';

class ModuleChangeStatusSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };
  }

  filterQuestion = () => {
    const { SectionQuestions } = this.props;
    const result = {
      questions: [],
      assignee: {},
      witness: {},
      showEmptyState: true,
      createdAt: '',
    };

    if (!SectionQuestions || !SectionQuestions.length) {
      return result;
    }

    for (let i = 0; i < SectionQuestions.length; i++) {
      if ([questionTypes.SIGNATURE.assigneeName, questionTypes.SIGNATURE.witnessName, questionTypes.SIGNATURE.assignee].indexOf(SectionQuestions[i].Name) > -1) {
        if ([questionTypes.SIGNATURE.assigneeName, questionTypes.SIGNATURE.assignee].indexOf(SectionQuestions[i].Name) > -1) {
          result.assignee = SectionQuestions[i].Signature;
          result.createdAt = Helpers.getDateFromUnix(SectionQuestions[i]?.Signature?.SignedAt);
        }

        if (SectionQuestions[i].Signature && SectionQuestions[i].Name === questionTypes.SIGNATURE.witnessName) {
          result.witness = SectionQuestions[i].Signature;
        }
      } else {
        result.questions.push(SectionQuestions[i]);
      }
    }

    return result;
  };

  render() {
    const { expanded } = this.state;
    const { showViewMore, handleCustomAction, hideSignatures, hideAssignee, hideWitness, witnessLabel, module } = this.props;
    const { SignatureRequired } = module;
    let { assignee, witness, questions, createdAt } = this.filterQuestion();

    if (isEmpty(assignee) || isEmpty(witness)) {
      if (!this.props[fields.statusChangedAt]) {
        return <SectionEmptyState />;
      } else {
        createdAt = Helpers.getDateFromUnix(this.props[fields.statusChangedAt]);
        assignee = {
          [fields.assigneeName]: this.props[fields.statusChangeAssigneeName],
        };
        witness = {
          [fields.witnessName]: this.props[fields.statusChangeWitnessName],
        };
      }
    }

    return (
      <div className="module-change-status-section">
        <div className={`status-title-container ${hideSignatures ? 'disabled' : ''}`} onClick={() => (!hideSignatures ? this.setState({ expanded: !expanded }) : null)}>
          <h6 className="f-primary bold">{createdAt}</h6>
          <RenderIf if={!hideSignatures}>
            <Icon name={expanded ? 'chevron-up' : 'chevron-down'} className="toggle-status-change-details" />
          </RenderIf>
        </div>
        <ChangeStatusSignatures
          assignee={assignee}
          showAssignee={!hideAssignee}
          showWitness={!hideWitness}
          witness={witness}
          expanded={expanded}
          hideSignatures={hideSignatures}
          witnessLabel={witnessLabel}
          signatureRequired={SignatureRequired}
        />
        <RenderIf if={questions && questions.length > 0 && expanded}>
          <ChangeStatusQuestionsDisplay questions={questions} />
        </RenderIf>
        <RenderIf if={showViewMore && handleCustomAction}>
          <LoadMore showButton label="CHANGE_STATUS_SECTON.VIEW_MORE" buttonWidth="lg" resultsText="" buttonVariant="success-outline" onClick={() => handleCustomAction(this.props)} />
        </RenderIf>
      </div>
    );
  }
}

ModuleChangeStatusSection.defaultProps = {
  showViewMore: false,
  hideSignatures: false,
  hideAssignee: false,
  hideWitness: false,
  handleCustomAction: null,
};

export default ModuleChangeStatusSection;
