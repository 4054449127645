import actionTypes from '../constants/action-types';
import { componentFields } from '../constants/constants';

export const setPdfList = data => {
  return {
    type: actionTypes.SET_PDF_LIST,
    data,
  };
};

export const setPdfFileLoading = data => {
  return {
    type: actionTypes.SET_PDF_FILE_LOADING,
    data,
  };
};

export const setSelectedFile = data => {
  return {
    type: actionTypes.SET_SELECTED_FILE,
    data,
  };
};

export const setPdfComponents = data => {
  return {
    type: actionTypes.SET_PDF_COMPONENTS,
    data,
  };
};

export const amendPdfComponents = data => {
  return {
    type: actionTypes.AMEND_PDF_COMPONENTS,
    data,
  };
};

export const setComponentsLoading = data => {
  return {
    type: actionTypes.SET_COMPONENTS_LOADING,
    data,
  };
};

export const setPdfPopupComponentsLoading = data => {
  return {
    type: actionTypes.SET_PDF_POPUP_COMPONENTS_LOADING,
    data,
  };
};

export const handleAddNewComponent = data => {
  return {
    type: actionTypes.HANDLE_ADD_NEW_COMPONENT,
    data,
  };
};

export const updateComponentInArray = (component, id, prop = componentFields.componentId) => {
  return {
    type: actionTypes.HANDLE_UPDATE_COMPONENT_IN_ARRAY,
    data: {
      component,
      id,
      prop,
    },
  };
};
export const removeComponentInArray = (id, prop = componentFields.componentId) => {
  return {
    type: actionTypes.HANDLE_REMOVE_COMPONENT_IN_ARRAY,
    data: {
      id,
      prop,
    },
  };
};

export const selectActiveDrawing = data => {
  return {
    type: actionTypes.SELECT_ACTIVE_DRAWING,
    data,
  };
};

export const setPdfPageNumber = data => {
  return {
    type: actionTypes.SET_PDF_PAGE_NUMBER,
    data,
  };
};
