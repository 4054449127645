import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { isEmpty } from 'lodash';
import Helpers from '../../../common/helpers';

import SmallDropdown from '../../../common/small-dropdown/components/small-dropdown';
import Icon from '../../../common/icon/components/icon';

import InspectionItem from './inspection-item';

import '../styles/project-sidebar.scss';

class ProjectItem extends Component {
  render() {
    const { project, selectProject, toggleDropdown, getInspectionClickAction, settingsMenu, getProjectClickAction, reducedActions, inspectionMenu, handleViewInspection, index } = this.props;
    const emptyInspections = isEmpty(project.Inspections);

    if (reducedActions)
      return (
        <div className={`project-sidebar project-border`} id={`${project.Name}_${project.ID}`} data-cy={index}>
          <div className={`project ${project.selected ? ' project--selected' : ''}`} onMouseEnter={() => selectProject(project.ID)}>
            <div className="project__name noselect">
              <p className={project.selected ? 'f-white bold' : 'f-primary bold'}>{project.Name}</p>
            </div>
            <div className="project__date noselect">
              {!emptyInspections && <p className={project.selected ? 'f-white bold' : 'f-secondary-dark bold'}>{Helpers.getDateFromUnix(project.Inspections[0].InspectionDate, 'MMM DD, YYYY')}</p>}
            </div>
            {!isEmpty(settingsMenu) && <SmallDropdown actionsMenu={settingsMenu} getClickAction={action => getProjectClickAction(action, project)} offsetX={10} />}
          </div>
        </div>
      );
    return (
      <div className={`project-sidebar${!project.active && project.selected ? '' : ' project-border'}`} id={`${project.Name}_${project.ID}`} data-cy={index}>
        <div onMouseEnter={() => selectProject(project.ID)} onClick={() => toggleDropdown(project.ID)} className={`project${project.selected ? ' project--selected' : ''}`}>
          <div className={`project__dropdown-arrow project__dropdown-arrow--${!emptyInspections && project.active ? 'up' : 'down'}${emptyInspections ? ' hidden' : ''}`}>
            <Icon name="chevron-down" className={`project__icon ${project.selected ? 'project__icon-selected' : ''}`} />
          </div>
          <div className="project__name noselect">
            <p className={project.selected ? 'f-white bold' : 'f-primary bold'} title={project.Name}>
              {project.Name}
            </p>
          </div>
          <div className="project__date noselect">
            {!emptyInspections && <p className={project.selected ? 'f-white bold' : 'f-secondary-dark bold'}>{Helpers.getDateFromUnix(project.Inspections[0].InspectionDate, 'MMM DD, YYYY')}</p>}
          </div>
          {!isEmpty(settingsMenu) && <SmallDropdown actionsMenu={settingsMenu} getClickAction={action => getProjectClickAction(action, project)} offsetX={10} />}
        </div>
        {!emptyInspections && project.active && (
          <div className="sub-projects" data-cy="sub-projects">
            {!isEmpty(project.Inspections)
              ? project.Inspections.map((inspection, index) => {
                  return (
                    <InspectionItem
                      key={index}
                      project={project}
                      inspection={inspection}
                      getInspectionClickAction={getInspectionClickAction}
                      inspectionMenu={inspectionMenu}
                      handleViewInspection={handleViewInspection}
                    />
                  );
                })
              : null}
          </div>
        )}
      </div>
    );
  }
}

ProjectItem.contextTypes = {
  t: PropTypes.func.isRequired,
};

ProjectItem.propTypes = {
  project: PropTypes.object.isRequired,
};
export default ProjectItem;
