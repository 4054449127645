import routesConstants from './routes';
import { Api } from '../../core/api';
import { API_BASE_URL } from '../../common/constants';

const getPermitTemplateDetails = async templateId => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_PERMIT_TEMPLATE_DETAILS}?permit_template_id=${templateId}`);
};

const createPermitTemplate = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CREATE_PERMIT_TEMPLATE}`, data);
};

const updatePermitTemplate = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_PERMIT_TEMPLATE}`, data);
};

const getPermitTemplates = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_PERMIT_TEMPLATES}`, data);
};

const getPermitTemplatesFilters = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_PERMIT_TEMPLATES_FILTERS}`, data);
};

const deletePermitTemplate = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_PERMIT_TEMPLATE}`, data);
};

const getPermitTemplateSections = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_PERMIT_TEMPLATE_SECTIONS}`, data);
};

const getPermitTemplateFiles = async permitTemplateId => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_PERMIT_TEMPLATE_FILES}?permit_template_id=${permitTemplateId}`);
};

const deletePermitTemplateFile = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_PERMIT_TEMPLATE_FILE}`, data);
};

const addPermitTemplateSection = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_PERMIT_TEMPLATE_SECTION}`, data);
};

const deletePermitTemplateSection = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_PERMIT_TEMPLATE_SECTION}`, data);
};

const orderPermitTemplateSections = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ORDER_PERMIT_TEMPLATE_SECTIONS}`, data);
};

const updatePermitTemplateSection = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_PERMIT_TEMPLATE_SECTION}`, data);
};

const updatePermitTemplateSectionQuestion = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_PERMIT_TEMPLATE_SECTION_QUESTION}`, data);
};

const updatePermitTemplateSectionQuestionOption = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_PERMIT_TEMPLATE_SECTION_QUESTION_OPTION}`, data);
};

const addPermitTemplateSectionQuestion = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_PERMIT_TEMPLATE_SECTION_QUESTION}`, data);
};

const deletePermitTemplateSectionQuestion = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_PERMIT_TEMPLATE_SECTION_QUESTION}`, data);
};

const orderPermitTemplateSectionQuestions = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ORDER_PERMIT_TEMPLATE_SECTION_QUESTIONS}`, data);
};

const addPermitTemplateSectionQuestionOption = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_PERMIT_TEMPLATE_SECTION_QUESTION_OPTION}`, data);
};

const deletePermitTemplateSectionQuestionOption = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_PERMIT_TEMPLATE_SECTION_QUESTION_OPTION}`, data);
};

const permitTemplateChangeStatus = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.PERMIT_TEMPLATE_CHANGE_STATUS}`, data);
};

const copyPermitTemplate = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.COPY_PERMIT_TEMPLATE}`, data);
};

const addPermitWorkArea = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_PERMIT_AREA}`, data);
};

const updatePermitWorkArea = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_PERMIT_AREA}`, data);
};

const deletePermitWorkArea = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_PERMIT_AREA}`, data);
};

const addPermitComment = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_PERMIT_COMMENT}`, data);
};

export default {
  getPermitTemplateDetails,
  createPermitTemplate,
  updatePermitTemplate,
  getPermitTemplates,
  getPermitTemplatesFilters,
  deletePermitTemplate,
  getPermitTemplateSections,
  getPermitTemplateFiles,
  deletePermitTemplateFile,
  addPermitTemplateSection,
  deletePermitTemplateSection,
  orderPermitTemplateSections,
  updatePermitTemplateSection,
  updatePermitTemplateSectionQuestion,
  updatePermitTemplateSectionQuestionOption,
  addPermitTemplateSectionQuestion,
  deletePermitTemplateSectionQuestion,
  orderPermitTemplateSectionQuestions,
  addPermitTemplateSectionQuestionOption,
  deletePermitTemplateSectionQuestionOption,
  permitTemplateChangeStatus,
  copyPermitTemplate,
  addPermitWorkArea,
  updatePermitWorkArea,
  deletePermitWorkArea,
  addPermitComment,
};
