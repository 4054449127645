import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Loader from '../../../../common/global-loader/components/simple-loader';
import LineChart from './line-chart';

import Helpers from '../../../../common/helpers';
import { lineChartStyle } from './constants/line-chart-styles';

import { getNdtAlarms } from '../../actions/ndt-alarms-actions';
import { emptyStateIndicator } from '../../constants/ndt-constants';

import '../../styles/ndt-modal.scss';

class NDTMeasurementChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartWrapper: null,
      alarms: [],
      chartHeight: '200px',
    };
  }

  componentDidMount() {
    const { measurementId, getNdtAlarms } = this.props;

    if (measurementId) {
      getNdtAlarms(measurementId, data => this.formatData(data));
    }
  }

  formatData = data => {
    return this.setState({ alarms: data.map(el => ({ ...el, active: true, color: el.Colour, label: el.AlarmType, alarmType: el.AlarmType, value: el.Value })) });
  };

  customEmptyFunc = data => {
    return !data || !data[0] || data[0][0] === emptyStateIndicator;
  };

  mapChartData = (data, alarmLabels, alarmValues) => {
    //return default value to clear the chart from prevoius values
    if (!data || !data[0])
      return [
        [emptyStateIndicator, ''],
        ['', 0],
      ];
    const newData = [[...data[0], ...alarmLabels], ...data.slice(1).map(el => [...el, ...alarmValues])];

    return newData;
  };

  render() {
    const { t } = this.context;
    const { alarms, chartHeight } = this.state;
    const { colors, unit, chartData, chartsLoading } = this.props;

    // chartData = isEmpty(chartData) ? [[emptyStateIndicator, ''], ['', 0]] : chartData;
    // let optionalColors = [];
    // const showMin = !isEmpty(chartData) && !isEmpty(chartData[0]) && chartData[0].findIndex(string => string.includes('Min')) > -1 ? true : false;
    // const showMax = !isEmpty(chartData) && !isEmpty(chartData[0]) && chartData[0].findIndex(string => string.includes('Max')) > -1 ? true : false;
    // if (showMin) {
    //   optionalColors.push(colors.severityRed);
    // }
    // if (showMax) {
    //   optionalColors.push(colors.severityGreen);
    // }

    // const viewWindow =
    //   !isEmpty(chartData) && !Helpers.isLineChartEmpty(chartData) && !isEmpty(chartData[1])
    //     ? {
    //         min: chartData[1][0],
    //         max: chartData.length <= 20 ? chartData[chartData.length - 1][0] : chartData[20][0],
    //       }
    //     : {};

    if (chartsLoading) {
      return <Loader height={chartHeight} isLoading={true} divStyle={{ margin: 'auto' }} />;
    }

    const measurementValuesAlarms = ['HH', 'H', 'LL', 'L'];
    const filteredAlarms = alarms.filter(alarm => {
      return alarm.Active && measurementValuesAlarms.indexOf(alarm.alarmType) > -1;
    });

    const activeAlarms = filteredAlarms.filter(alarm => alarm.active);
    const alarmLabels = activeAlarms.map(alarm => t(`NDT_ALARMS_MODAL.FORM_LABELS_VALUE_${alarm.label}`, { unit: `${alarm.value} (${unit})` }));
    const alarmValues = activeAlarms.map(alarm => alarm.value);
    const alarmColors = activeAlarms.map(alarm => colors[alarm.color] || colors.severityGreen);
    const alarmSeries = {};
    const mappedChartData = this.mapChartData(chartData, alarmLabels, alarmValues);

    activeAlarms.forEach((alarm, index) => {
      const baseOptions = {
        pointsVisible: false,
        lineDashStyle: [8, 8],
      };
      alarmSeries[index - 1 + mappedChartData[0].length - activeAlarms.length] = {
        ...baseOptions,
      };
    });

    let optionalColors = [];
    const showMin = !Helpers.isLineChartEmpty(mappedChartData) && mappedChartData[0].findIndex(string => string.includes('Min')) > -1 ? true : false;
    const showMax = !Helpers.isLineChartEmpty(mappedChartData) && mappedChartData[0].findIndex(string => string.includes('Max')) > -1 ? true : false;
    const showPredictive = !Helpers.isLineChartEmpty(mappedChartData) && mappedChartData[0].findIndex(string => string.includes('Predictive')) > -1 ? true : false;
    if (showMin) {
      optionalColors.push(colors.severityRed);
    }
    if (showMax) {
      optionalColors.push(colors.severityGreen);
    }
    if (showPredictive) {
      optionalColors.push('#2150d1');
    }

    const viewWindow = Helpers.isLineChartEmpty(mappedChartData)
      ? {}
      : {
          min: mappedChartData && mappedChartData[1] ? mappedChartData[1][0] : 0,
          max: mappedChartData && mappedChartData.length <= 500 ? mappedChartData[mappedChartData.length - 1][0] : mappedChartData[500][0],
        };

    const options = {
      ...lineChartStyle,
      legend: {},
      backgroundColor: colors.boxItemsThemeColor,
      series: {
        [optionalColors.length - 1]: {
          pointsVisible: false,
          curveType: showPredictive ? 'function' : 'none',
        },
        ...lineChartStyle.series,
        ...alarmSeries,
      },
      colors: [...optionalColors, colors.primaryFontColor, ...alarmColors],
      hAxis: {
        gridlines: {
          color: colors.boxItemsSeparatorColor,
        },
        minorGridlines: {
          color: colors.boxItemsThemeColor,
        },
        baselineColor: colors.boxItemsSeparatorColor,
        textStyle: {
          color: colors.secondaryFontColorLight,
        },
        viewWindow,
      },
      vAxis: {
        gridlines: {
          color: colors.boxItemsSeparatorColor,
        },
        minorGridlines: {
          color: colors.boxItemsThemeColor,
        },
        baselineColor: colors.boxItemsSeparatorColor,
        textStyle: {
          color: colors.secondaryFontColorLight,
        },
        titleTextStyle: {
          color: colors.secondaryFontColorLight,
        },
        title: `MEASUREMENT (${unit})`,
      },
      explorer: {
        actions: ['dragToPan', 'rightClickToReset'],
        axis: 'horizontal',
      },
      chartArea: {
        left: 60,
        width: '94%',
        height: '80%',
      },
      width: '100%',
    };

    return <LineChart lineChartOptions={options} data={mappedChartData} chartHeight={chartHeight} isEmptyData={this.customEmptyFunc} />;
  }
}

NDTMeasurementChart.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    colors: state.themeReducer.severityColors,
    chartData: props.passedChartData || state.ndtReducer.ndtSmallChartData,
    chartsLoading: state.ndtReducer.ndtSmallChartDataLoading,
  };
};

const mapDispatchToProps = dispatch => ({
  getNdtAlarms: (measurmentId, callback) => dispatch(getNdtAlarms(measurmentId, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NDTMeasurementChart);
