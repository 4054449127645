import React, { Component } from 'react';
import { isEmpty, find, debounce } from 'lodash';
import PropTypes from 'prop-types';
import SearchInput from '../../../../common/input/components/search-input';
import { ReactComponent as XSign } from '../../../../common/assets/x-sign.svg';

import Button from '../../../../common/form/components/button';

import { userFields, teamFields } from '../../constants/team-constants';

import '../../styles/add-members-popup.scss';

class AddMembersPopup extends Component {
  constructor(props) {
    super(props);
    this.searchUsersSearchChangeDebounced = debounce(props.searchUsers, 500);
  }

  componentDidMount = () => {
    const { searchUsers } = this.props;

    searchUsers('');
  };

  handleUsersSearch = e => {
    this.searchUsersSearchChangeDebounced(e.target.value);
  };
  render() {
    const { t } = this.context;
    const { users, addMember, removeMember, closeAction, selectedTeam } = this.props;

    const members = selectedTeam[teamFields.users];

    return (
      <div className="member-form" data-cy="team-members-form">
        <SearchInput
          onChange={this.handleUsersSearch}
          placeholder={t('PROJECT.SEARCH.PLACEHOLDER')}
          includeDropdown={true}
          items={users || []}
          // charLimitation={componentSearchCharLimitation}
          emptyStateLabel={'ADD_MEMBERS_POPUP.EMPTY_SEARCH'}
          renderItem={(item, index) => {
            const isDisabled = find(members, i => item[teamFields.id] === i[userFields.id]);

            return (
              <p key={index} className={`f-primary pointer ${isDisabled ? 'disabled' : ''}`} onClick={isDisabled ? () => null : () => addMember(selectedTeam, item)}>
                {item[userFields.name]}
              </p>
            );
          }}
        />

        <div className="added-items">
          <h6 className="f-primary">{t('ADD_MEMBERS_POPUP.ADDED_USERS')}</h6>
          <div className="added-items-list">
            {members &&
              members.map((item, index) => {
                return (
                  <div className="item-r" key={index} data-cy="member">
                    <p className="f-primary c-title">{`${index + 1}. ${item[userFields.name]}`}</p>
                    <XSign className="x-hover  pointer" onClick={() => removeMember(selectedTeam, item)} data-cy="remove-member" />
                  </div>
                );
              })}

            {isEmpty(members) && (
              <div className="empty-state">
                <p className="f-secondary-dark">{t('ADD_MEMBERS_POPUP.NO_MEMBERS')} </p>
              </div>
            )}
          </div>
        </div>

        <Button type="button" text={t('ADD_MEMBERS_POPUP.OK')} onClick={closeAction} />
      </div>
    );
  }
}

AddMembersPopup.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default AddMembersPopup;
