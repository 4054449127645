import { FEATURES } from '../../../common/constants';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../common/permissions-constants';

const projectActions = {
  archiveProject: 'archiveProject',
  unarchiveProject: 'unarchiveProject',
  editProject: 'editProject',
  deleteProject: 'deleteProject',
  uploadInspectionImages: 'uploadImages',
  openIntegrations: 'opentIntegrations',
};

const inspectionActions = {
  deleteInspection: 'deleteInspection',
};
const settingsMenu = [
  {
    title: 'PROJECT.SIDEBAR.SETTINGS_MENU.ITEM_1',
    id: '1',
    action: projectActions.editProject,
    isHidden: !FEATURES.updateProject.visible,
    access: {
      visibleFor: [PERMISSIONS[PERMISSION_TYPES.projects].edit.name],
    },
  },
  //{
  //     title: 'PROJECT.SIDEBAR.SETTINGS_MENU.ITEM_2',
  //     id: '2',
  //     action: projectActions.deleteProject,
  //     isHidden: !FEATURES.deleteProject.visible,
  //     visibleFor: [],
  //   },
  //   {
  //     title: 'PROJECT.SIDEBAR.SETTINGS_MENU.ITEM_3',
  //     id: '3',
  //     action: projectActions.archiveProject,
  //     visibleFor: [],
  //   },
  //   {
  //     title: 'PROJECT.SIDEBAR.SETTINGS_MENU.ITEM_6',
  //     id: '6',
  //     action: projectActions.openIntegrations,
  //     isHidden: !FEATURES.integrations.visible,
  //     visibleFor: [],
  //   },
];

// const settingsArchivedMenu = [
//   {
//     title: 'PROJECT.SIDEBAR.SETTINGS_MENU.ITEM_1',
//     id: '1',
//     action: projectActions.editProject,
//     isHidden: !FEATURES.updateProject.visible,
//     visibleFor: [],
//   },
//   {
//     title: 'PROJECT.SIDEBAR.SETTINGS_MENU.ITEM_2',
//     id: '2',
//     action: projectActions.deleteProject,
//     isHidden: !FEATURES.deleteProject.visible,
//     visibleFor: [],
//   },
//   {
//     title: 'PROJECT.SIDEBAR.SETTINGS_MENU.ITEM_4',
//     id: '4',
//     action: projectActions.unarchiveProject,
//     visibleFor: [],
//   },
//   {
//     title: 'PROJECT.SIDEBAR.SETTINGS_MENU.ITEM_6',
//     id: '6',
//     action: projectActions.openIntegrations,
//     isHidden: !FEATURES.integrations.visible,
//     visibleFor: [],
//   },
// ];

// const inspectionMenu = [
//   {
//     title: 'INSPECTION_MENU.DELETE_INSPECTION',
//     id: '1',
//     action: inspectionActions.deleteInspection,
//     isHidden: !FEATURES.deleteInspection.visible,
//     visibleFor: [],
//   },
//   {
//     title: 'INSPECTION_MENU.UPLOAD_IMAGES',
//     id: '1',
//     action: inspectionActions.uploadInspectionImages,
//     visibleFor: [],
//   },
// ];

export { projectActions, settingsMenu, inspectionActions };
