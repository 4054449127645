import { fields, MAX_GROUP_CHARACTERS, MIN_GROUP_CHARACTERS } from '../../constants/constants';

export const validateGroupForm = values => {
  const errors = {};
  const groupName = values[fields.name];

  const invalidNamesPattern = /no\s*group/i;

  if (!groupName) {
    errors[fields.name] = 'READINGS_AND_GAUGES.GROUP_NAME_REQUIRED';
  } else {
    if (groupName.length > MAX_GROUP_CHARACTERS) {
      errors[fields.name] = `READINGS_AND_GAUGES.GROUP_NAME_MAX`;
    }

    if (groupName.length < MIN_GROUP_CHARACTERS) {
      errors[fields.name] = `READINGS_AND_GAUGES.GROUP_NAME_MIN`;
    }

    if (invalidNamesPattern.test(groupName)) {
      errors[fields.name] = 'ERROR_MODAL.6503';
    }
  }

  return errors;
};
