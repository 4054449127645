import React from 'react';
import PropTypes from 'prop-types';

import SectionEmptyState from '../../../common/section-empty-state';
import BasicImage from '../../../../../../../common/image-components/components/basic-image';
import Icon from '../../../../../../../common/icon/components/icon';
import Helpers from '../../../../../../../common/helpers';
import { fileFields } from '../../constants/isolation-components-constants';

const FilesDisplay = ({ title, withBorder = false, componentId, files = [], handleDeleteFile, handleFileClickCustomAction, showEmptyState, emptyStateTitle, forceEmptyState }, { t }) => {
  const handleFileClick = file => {
    /*
     for populated drawings section on Isolation Certificates details we trigger
      a custom handleFileClickCustomAction to download PDF with populated drawings
    */
    if (handleFileClickCustomAction) handleFileClickCustomAction(file);
    else {
      const win = window.open(file.URL, '_blank');
      win.focus();
    }
  };

  const getFileDisplay = file => {
    if (Helpers.isImage(file[fileFields.name]) && file[fileFields.thumbnailUrl]) {
      return <BasicImage hideImageLoader className="file-image" src={file[fileFields.thumbnailUrl]} alt={`file-item-${file[fileFields.id]}`} />;
    }

    if (Helpers.isPDF(file[fileFields.name])) {
      return <Icon name="pdf-file" className="file-other" />;
    }

    return <Icon name="file-text" className="file-other" />;
  };

  if (forceEmptyState || (showEmptyState && (!files || !files.length))) {
    return <SectionEmptyState title={emptyStateTitle || 'ISOLATION_CERTIFICATE.FILES_EMPTY_STATE'} />;
  }

  return (
    <>
      {title && <p className="f-secondary-dark">{t(title)}</p>}
      {files &&
        files.length > 0 &&
        files.map(file => (
          <div className={`file-display-item ${withBorder ? 'with-border' : ''}`} key={`file-display-item-${file[fileFields.id]}`}>
            <div className="file-item-name" onClick={() => handleFileClick(file)}>
              {getFileDisplay(file)}
              <p className="f-primary file-name">{file[fileFields.name]}</p>
            </div>
            {handleDeleteFile && <Icon className="delete-icon" name="trash" onClick={() => handleDeleteFile(componentId, file[fileFields.id])} />}
          </div>
        ))}
    </>
  );
};

FilesDisplay.contextTypes = {
  t: PropTypes.func.isRequired,
};

FilesDisplay.propTypes = {
  componentId: PropTypes.number,
  withBorder: PropTypes.bool,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      DocumentURL: PropTypes.string,
      FileID: PropTypes.number,
      FileName: PropTypes.string,
      Key: PropTypes.string,
      ThumbnailURL: PropTypes.string,
      URL: PropTypes.string,
    })
  ),
  handleDeleteFile: PropTypes.func,
  handleFileClickCustomAction: PropTypes.func,
  forceEmptyState: PropTypes.bool,
  emptyStateTitle: PropTypes.string,
};

export default FilesDisplay;
