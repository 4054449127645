import React from 'react';
import PropTypes from 'prop-types';
import { LevelPopoverFields } from './constants/constants';

const LevelPopover = ({ level }, { t }) => {
  if (!level) {
    return null;
  }

  return (
    <div className="level-popover">
      <p className="f-primary bold">{level.Name || '-'}</p>
      {LevelPopoverFields.map((item, index) => {
        return (
          <div className="single-detail-item" key={index}>
            <p className="f-secondary-dark single-detail-item-title">{t(`COMPONENT_HIERARCHY_TABLE.${item.title}`)}</p>
            <p className="f-primary">{level[item.field] ? level[item.field] : '-'}</p>
          </div>
        );
      })}
    </div>
  );
};

LevelPopover.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default LevelPopover;
