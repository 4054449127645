import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../form/components/button';
import Helpers from '../../helpers';

import '../styles/create-button.scss';
import RenderIf from '../../render-if/components/render-if';
import SmallDropdown from '../../small-dropdown/components/small-dropdown';

const CreateButton = (
  {
    disabled,
    nonTranslatable,
    translationObject,
    onClick,
    children,
    className,
    icon,
    user,
    visibleFor,
    createdById,
    ownershipPermission,
    stopProp,
    hasDropdown,
    dropdownMenu,
    getClickAction,
    ...rest
  },
  { t }
) => {
  const buttonDisabled =
    disabled ||
    !Helpers.hasAccess({
      user,
      visibleFor,
      id: createdById,
      ownerRequiredPermission: ownershipPermission,
    });

  const handleButtonClick = e => {
    if (buttonDisabled || typeof onClick !== 'function') {
      return;
    }

    stopProp ? onClick(e) : onClick();
  };

  return (
    <>
      <RenderIf if={!hasDropdown}>
        <Button
          width="xs"
          variant="success"
          icon={icon || 'plus-circle'}
          disabled={buttonDisabled}
          text={nonTranslatable ? children : t(children, translationObject || {})}
          onClick={handleButtonClick}
          className="create-button"
          type="button"
          {...rest}
        />
      </RenderIf>
      <RenderIf if={hasDropdown}>
        <SmallDropdown
          isButton
          buttonProps={{
            width: 'xs',
            variant: 'success',
            icon: icon || 'plus-circle',
            disabled: buttonDisabled,
            text: nonTranslatable ? children : t(children, translationObject || {}),
            className: 'create-button',
            type: 'button',
            ...rest,
          }}
          actionsMenu={dropdownMenu}
          className="create-button__dropdown-button"
          getClickAction={(action, b, selfClose, e) => {
            e?.preventDefault && e.preventDefault();
            selfClose();
            if (action) {
              if (typeof action === 'function') {
                action();
              } else if (typeof action === 'string' && getClickAction) {
                getClickAction(action);
              }
            }
          }}
        />
      </RenderIf>
    </>
  );
};

CreateButton.contextTypes = {
  t: PropTypes.func.isRequired,
};

CreateButton.propTypes = {
  disabled: PropTypes.bool,
  nonTranslatable: PropTypes.bool,
  translationObject: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  icon: PropTypes.string,
  user: PropTypes.object,
  visibleFor: PropTypes.array,
  createdById: PropTypes.number,
  ownershipPermission: PropTypes.string,
};

CreateButton.defaultProps = {
  disabled: false,
  nonTranslatable: false,
  translationObject: {},
};

export default CreateButton;
