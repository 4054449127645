import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import update from 'react-addons-update';

import Helpers from '../../../common/helpers';

import { isEmpty, map, filter, find, findIndex } from 'lodash';

import UploadButton from '../../../common/form/components/upload-button';
import ListView from './image-list-view';
import ImageFilter from './image-filter';
import Legend from './legend';
import HeaderSlider from '../../start-workflow/components/header-slider';
import Button from '../../../common/form/components/button';
import Dropzone from '../../../common/upload/components/upload-dropzone';
import Paginator from './paginator';

import { getWorkflowImages, setImageActive, setSelection, generateComputerVision, getWorkflowImagesSingleType } from '../actions/workflow-actions';
import { uploadWorkflowAsset } from '../actions/workflow-upload-actions';
import { getInspectionDetails, setCurrentProjectId } from '../../inspections/actions/inspection-actions';

import routesConstants from '../../../common/routes-constants';
import workflowConstants from '../constants/inspection-workflow-constants';
import uploadConstants from '../../upload/constants/constants';
import { AMAZON_IMAGE_SIZES } from '../../../common/constants';
import { inspectionAssetSupportedMimeTypes, inspectionSupportedTypesText } from '../../project/constants/upload-constants';
import { Tools } from '@tarik.djurdjevic/react-sketch';

import '../styles/inspection-workflow.scss';

class InspectionWorkflow extends Component {
  constructor(props, context) {
    super(props);
    const {
      location: { query },
    } = props;
    const { t } = context;

    this.node = React.createRef();
    this.controls = React.createRef();
    this.state = {
      controlsActive: false,
      isFullscreen: false,
      totalSelectedImages: 0,
      lastSelectedActiveItemSrc: null,
      cFilter: {
        ...workflowConstants.defaultFilter,
        ContextualisationSelection: 'CONTEXTUALISED',
      },
      nonCFilter: {
        ...workflowConstants.defaultFilter,
        ContextualisationSelection: 'UNCONTEXTUALISED',
      },
      sections: [
        {
          title: t('WORKFLOW_SECTION.SECTION_TITLE_1', { count: 0 }),
          defaultLabel: 'WORKFLOW_SECTION.SECTION_TITLE_1',
          prop: workflowConstants.contextualized,
          value: true,
          expanded: false,
          hasPagination: true,
          paginatorId: 'paginator-1',
          count: 0,
        },
        {
          title: t('WORKFLOW_SECTION.SECTION_TITLE_2', { count: 0 }),
          defaultLabel: 'WORKFLOW_SECTION.SECTION_TITLE_2',
          prop: workflowConstants.contextualized,
          value: false,
          expanded: false,
          customComponent: () => (
            <Dropzone
              onDrop={file => this.onDropAsset(file, uploadConstants.uploadType.inspectionImages, parseInt(query.project_id), parseInt(query.inspection_id))}
              supportedMimeTypes={inspectionAssetSupportedMimeTypes}
              supportedFileExtentions={inspectionSupportedTypesText}
              CustomImage={UploadButton}
              labelUnderImage={t('WORKFLOW.UPLOAD_IMAGES')}
            />
          ),
          hasPagination: true,
          paginatorId: 'paginator-2',
          count: 0,
        },
      ],
    };
  }
  componentDidMount = () => {
    const { cFilter, nonCFilter } = this.state;

    const {
      getWorkflowImages,
      getInspectionDetails,
      setActiveProject,
      location: { query },
    } = this.props;
    if (!query.inspection_id || !query.project_id) {
      return;
    }
    getInspectionDetails(parseInt(query.inspection_id));
    setActiveProject(parseInt(query.project_id));
    getWorkflowImages(parseInt(query.inspection_id), workflowConstants.imageSelectionTypes.all, this.defineInitialState, cFilter, nonCFilter, (cFilterIncoming, nonCFilterIncoming) => {
      this.setState({ cFilter: { ...cFilter, ...cFilterIncoming } });
      this.setState({ nonCFilter: { ...nonCFilter, ...nonCFilterIncoming } });
      this.setState({ totalSelectedImages: cFilterIncoming.SelectedImagesCount + nonCFilterIncoming.SelectedImagesCount || 0 });
    });
  };

  generateComputerVision = () => {
    const { generateComputerVision, inspectionID, getWorkflowImages } = this.props;

    generateComputerVision(inspectionID, () => {
      let { cFilter, nonCFilter } = this.state;
      //reset filters and recall the main route
      cFilter = { ...cFilter, ...workflowConstants.defaultFilter };
      nonCFilter = { ...nonCFilter, ...workflowConstants.defaultFilter };

      getWorkflowImages(parseInt(inspectionID), workflowConstants.imageSelectionTypes.all, this.defineInitialState, cFilter, nonCFilter, (cFilterIncoming, nonCFilterIncoming) => {
        this.setState({ cFilter: { ...cFilter, ...cFilterIncoming } });
        this.setState({ nonCFilter: { ...nonCFilter, ...nonCFilterIncoming } });
        this.setState({ totalSelectedImages: cFilterIncoming.SelectedImagesCount + nonCFilterIncoming.SelectedImagesCount || 0 });
      });
    });
  };

  loadMore = item => {
    const {
      getWorkflowImagesSingleType,
      location: { query },
      workflowImages,
    } = this.props;

    let { cFilter, nonCFilter } = this.state;

    const filterToSend = item.value ? cFilter : nonCFilter;

    getWorkflowImagesSingleType(
      parseInt(query.inspection_id),
      workflowConstants.imageSelectionTypes.all,
      filterToSend,
      (incomingFilter, allImages) => {
        if (item.value) {
          this.setState({ cFilter: { ...cFilter, ...incomingFilter } });
        } else {
          this.setState({ nonCFilter: { ...nonCFilter, ...incomingFilter } });
        }
        this.updateCounters(allImages, item.value, incomingFilter.TotalItems);

        setTimeout(() => {
          Helpers.scrollIntoView('section-content', item.paginatorId);
        });
      },
      workflowImages
    );
  };

  updateCounters = (allImages, isContextualized, totalImages) => {
    const { sections } = this.state;
    const { t } = this.context;

    const index = isContextualized ? 0 : 1;

    const totalCount = sections[index].count + allImages.length;
    const countLabel = `${totalCount}/${totalImages}`;

    const newSections = map(sections, (item, i) => {
      if (index === i) {
        const title = t(item.defaultLabel, { count: countLabel });

        return { ...item, title, count: totalCount };
      }
      return item;
    });

    this.setState({ sections: newSections });
  };

  defineInitialState = (images, totalImagesContextualized, totalImagesNonContextualized) => {
    const { t } = this.context;
    const { sections } = this.state;
    const contextualized = filter(images, { [workflowConstants.contextualized]: true }) || [];
    const nonContextualized = ((images && images.length) || 0) - ((contextualized && contextualized.length) || 0);
    //define accordion state
    const contextualizedCount = `${contextualized.length}/${totalImagesContextualized}`;
    const nonContextualizedCount = `${nonContextualized}/${totalImagesNonContextualized}`;

    if (!isEmpty(contextualized)) {
      this.setState({
        sections: update(sections, {
          0: { expanded: { $set: true }, title: { $set: t('WORKFLOW_SECTION.SECTION_TITLE_1', { count: contextualizedCount }) }, count: { $set: contextualized.length } },
          1: { expanded: { $set: false }, title: { $set: t('WORKFLOW_SECTION.SECTION_TITLE_2', { count: nonContextualizedCount }) }, count: { $set: nonContextualized } },
        }),
      });
    } else {
      this.setState({
        sections: update(sections, {
          0: { expanded: { $set: false }, title: { $set: t('WORKFLOW_SECTION.SECTION_TITLE_1', { count: contextualizedCount }) }, count: { $set: contextualized.length } },
          1: { expanded: { $set: true }, title: { $set: t('WORKFLOW_SECTION.SECTION_TITLE_2', { count: nonContextualizedCount }) }, count: { $set: nonContextualized } },
        }),
      });
    }
  };

  updateAccordionCounters = images => {
    const { t } = this.context;
    const { sections } = this.state;
    const contextualized = filter(images, { [workflowConstants.contextualized]: true }) || [];
    const nonContextualized = ((images && images.length) || 0) - ((contextualized && contextualized.length) || 0);

    //define accordion state
    this.setState({
      sections: update(sections, {
        0: { expanded: { $set: false } },
        1: { expanded: { $set: true }, title: { $set: t('WORKFLOW_SECTION.SECTION_TITLE_2', { count: nonContextualized }) } },
      }),
    });
  };

  handleImageActive = (index, item) => {
    const { totalSelectedImages } = this.state;
    const { workflowImages, handleImageActive } = this.props;
    if (!item.id) {
      return;
    }
    handleImageActive(item.ID, workflowImages);

    //if image is selected go to that image
    //if image is unselected go to last image
    if (!item[workflowConstants.formConstants.fields.selected]) {
      this.setState({ lastSelectedActiveItemSrc: item.src });
      this.setState({ totalSelectedImages: totalSelectedImages + 1 });
    } else {
      //if user wants to select image next to unselected one apply logic here
      this.setState({ totalSelectedImages: totalSelectedImages - 1 });
      this.setState({ lastSelectedActiveItemSrc: null });
    }
  };

  updateFullScreen = isFullscreen => {
    this.setState({ isFullscreen: isFullscreen || false });
  };

  onDropAsset = (files, uploadType, ProjectID, InspectionID) => {
    const { uploadWorkflowAsset, workflowImages } = this.props;

    const startIndex = workflowImages.length || 0;

    files.forEach((file, index) => {
      const tmp_file = {
        type: Helpers.getFileType(file.type),
        mimeType: file.type,
        title: '',
        description: '',
        real_name: Helpers.formatFileName(file.name),
        src: Helpers.getImageUrlfromFile(file),
        Contextualized: false,
      };
      file.timeStamp = Date.now();
      file.real_name = tmp_file.real_name;
      file.uploadType = uploadType;
      file[workflowConstants.formConstants.fields.selected] = false;
      uploadWorkflowAsset(file, { ProjectID, InspectionID }, startIndex + index, { ...tmp_file, ...file });
    });
    //mimic non contextualized images to update counters
    this.updateAccordionCounters(workflowImages.concat(files));
  };

  previousClick = () => {
    const { current } = this.node;
    current.slideLeft();
  };
  nextClick = () => {
    const { current } = this.node;
    current.slideRight();
  };

  onSlide = slideIndex => {
    const { activeImages } = this.props;
    this.setState({ lastSelectedActiveItemSrc: activeImages[slideIndex].src });
  };

  handlePreventClick = (index, e) => {
    this.setState(prepvProps => ({
      sections: Object.assign(
        [],
        map(prepvProps.sections, (item, i) => {
          if (i === index) {
            item.expanded = !item.expanded;
          } else {
            item.expanded = false;
          }
          return item;
        })
      ),
    }));
  };

  selectAll = selected => {
    const { cFilter, nonCFilter } = this.state;
    const { selectAll } = this.props;
    selectAll(selected);

    if (selected) {
      this.setState({ totalSelectedImages: cFilter.TotalItems + nonCFilter.TotalItems });
    } else {
      this.setState({ totalSelectedImages: 0 });
    }
  };

  render() {
    const { t } = this.context;
    const { workflowImages, uploadProgressStyle, activeImages, projectID, inspectionID, inspectionDetails } = this.props;
    const { sections, lastSelectedActiveItemSrc, isFullscreen, cFilter, nonCFilter, totalSelectedImages } = this.state;
    const activeItem = lastSelectedActiveItemSrc
      ? find(activeImages, item => {
          return item.src === lastSelectedActiveItemSrc;
        })
      : activeImages && activeImages[activeImages.length - 1];
    const activeIndex = !isEmpty(activeItem) ? findIndex(activeImages, { src: activeItem.src }) : 0;

    const headerProps = {
      workflowImagesSelected: activeImages,
      setSelectedDefect: null,
      currentSlideIndex: activeIndex,
      renderLeftNav: () => null,
      renderRightNav: () => null,
      previousClick: this.previousClick,
      nextClick: this.nextClick,
      handleImageActive: this.handleImageActive,
      setOrientation: () => null,
      currentImage: activeItem,
      onSlide: this.onSlide,
      disableDrawingTools: true,
      imageType: AMAZON_IMAGE_SIZES.small.name,
      updateFullScreen: this.updateFullScreen,
      sliderImageType: isFullscreen ? AMAZON_IMAGE_SIZES.large.name : AMAZON_IMAGE_SIZES.medium.name,
      isFullscreen,
    };

    return (
      <div className="workflow-container">
        <div className="boxes-wrapper">
          <div className="box left">
            <div className="box-title">
              <p className="f-primary">{t('WORKFLOW.IMAGES_TITLE')}</p>

              <Legend label={t('WORKFLOW.SEVERITY')} />
            </div>
            <ImageFilter
              images={Helpers.formatImageArray(workflowImages)}
              selectAll={this.selectAll}
              CustomComponent={() => {
                return inspectionDetails && inspectionDetails.ProcessedWithCV ? <Button type="button" text={t('WORKFLOW.AUTOPICK_IMAGES')} onClick={this.generateComputerVision} /> : null;
              }}
            />

            <ListView
              showReviewed={true}
              delayMs={300}
              images={Helpers.formatImageArrayWithSections(workflowImages, sections)}
              handleImageActive={this.handleImageActive}
              handleImageRemove={this.handleImageRemove}
              orientation={'grid'}
              uploadProgressStyle={uploadProgressStyle}
              renderSectionView={true}
              handlePreventClick={this.handlePreventClick}
              gridImageType={AMAZON_IMAGE_SIZES.small.name}
              PaginatiorComponent={item => {
                return <Paginator text={t('WORKFLOW.LOAD_MORE_IMAGES')} onClick={() => this.loadMore(item)} disabled={item.value ? !cFilter.HasNext : !nonCFilter.HasNext} id={item.paginatorId} />;
              }}
            />
          </div>
          <div className="box">
            <div className="box-title">
              <p className="f-primary">{t('WORKFLOW.VIEWER_TITLE')}</p>
            </div>
            <div className="box-content auto-scroll">
              <div className="slider-section">
                <HeaderSlider {...headerProps} ref={this.node} selectDrawingTool={() => null} selectedTool={Tools.DefaultTool} orientation={null} drawings={null} />
              </div>
              <div className="button-section">
                <Button
                  onClick={() => Helpers.goTo(routesConstants.routes.protectedRoutes.startWorkflow.fullPath, [{ project_id: projectID }, { inspection_id: inspectionID }])}
                  type="button"
                  width="lg"
                  text={t('WORKFLOW.START_INSPECTION_WORKFLOW', {
                    images: `${!isNaN(totalSelectedImages) && totalSelectedImages > 0 ? totalSelectedImages : 0}`,
                  })}
                  disabled={isNaN(totalSelectedImages) || totalSelectedImages <= 0}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    workflowImages: state.workflowReducer.workflowImages,
    inspectionDetails: state.inspectionReducer.inspectionDetails,
    updater: state.workflowReducer.updater,
    activeImages: state.workflowReducer.activeImages,
    uploadTmpFiles: state.uploadReducer.uploadTmpFiles,
    uploadProgressStyle: state.themeReducer.uploadProgress,
    inspectionID: state.inspectionReducer.inspectionID,
    projectID: state.inspectionReducer.projectID,
  };
};
const mapDispatchToProps = dispatch => ({
  getWorkflowImages: (inspectionId, selectionType, defineOpenedAccordion, cFilter, nonCFilter, callback, currentImages) =>
    dispatch(getWorkflowImages(inspectionId, selectionType, defineOpenedAccordion, cFilter, nonCFilter, callback, currentImages)),
  getWorkflowImagesSingleType: (inspectionId, selectionType, filter, callback, currentImages) => dispatch(getWorkflowImagesSingleType(inspectionId, selectionType, filter, callback, currentImages)),
  selectAll: selected => dispatch(setSelection(selected)),
  handleImageActive: (id, files) => dispatch(setImageActive(id, files)),
  uploadWorkflowAsset: (file, IDs, callbackFunction, index, customObj) => dispatch(uploadWorkflowAsset(file, IDs, callbackFunction, index, customObj)),
  getInspectionDetails: id => dispatch(getInspectionDetails(id)),
  setActiveProject: projectId => dispatch(setCurrentProjectId(projectId)),
  generateComputerVision: (inspectionId, callback) => dispatch(generateComputerVision(inspectionId, callback)),
});

InspectionWorkflow.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(InspectionWorkflow);
