import resetPasswordConstatns from '../constants/reset-password-constants';
import { PASSWORD_REGEX } from '../../../common/constants';

export const validate = values => {
  const errors = {};

  if (!values[resetPasswordConstatns.fields.password] || !PASSWORD_REGEX.test(values[resetPasswordConstatns.fields.password])) {
    errors[resetPasswordConstatns.fields.password] = 'LOGIN.PASSWORD_NOT_VALID';
  }

  if (!values[resetPasswordConstatns.fields.confirmPassword] || !PASSWORD_REGEX.test(values[resetPasswordConstatns.fields.confirmPassword])) {
    errors[resetPasswordConstatns.fields.confirmPassword] = 'LOGIN.PASSWORD_NOT_VALID';
  }

  if (values[resetPasswordConstatns.fields.confirmPassword] !== values[resetPasswordConstatns.fields.password])
    errors[resetPasswordConstatns.fields.confirmPassword] = 'RESET_PASSWORD.PASSWORD_DID_NOT_MATCH';

  return errors;
};
