import { PERMISSIONS, PERMISSION_TYPES } from '../../../common/permissions-constants';

export const actionButtonProps = {
  visibleFor: [PERMISSIONS[PERMISSION_TYPES.componentsHierarchy].create.name],
  stopProp: true,
};
export const fields = {
  code: 'Code',
  name: 'Name',
  costCentre: 'CostCenter',
  location: 'Location',
  owner: 'Owner',
  maintenanceID: 'MaintenanceID',
  actionMenu: 'ActionMenu',
  icon: 'Icon',
  createdBy: 'CreatedByUser',
  createdAt: 'CreatedAt',
  createdByID: 'CreatedBy',
  type: 'Type',
  critical: 'Critical',
  parentAsset: 'ParentAsset',
  id: 'ID',
};

export const tableConfig = [
  {
    title: '',
    key: fields.icon,
    type: fields.icon,
    class: 'icon-column',
  },
  {
    title: 'COMPONENT_HIERARCHY_TABLE.CODE',
    key: fields.code,
    type: fields.code,
    isSort: true,
    class: 'code-column',
  },
  {
    title: 'COMPONENT_HIERARCHY_TABLE.NAME',
    key: fields.name,
    type: fields.name,
    isSort: true,
    class: 'name-column',
  },
  {
    title: 'COMPONENT_HIERARCHY_TABLE.COST_CENTRE',
    key: fields.costCentre,
    type: fields.costCentre,
    isSort: true,
    class: 'cost-centre-column',
  },
  {
    title: 'COMPONENT_HIERARCHY_TABLE.LOCATION',
    key: fields.location,
    type: fields.location,
    isSort: true,
    class: 'location-column',
  },
  {
    title: 'COMPONENT_HIERARCHY_TABLE.OWNER',
    key: fields.owner,
    type: fields.owner,
    isSort: true,
    class: 'owner-column',
  },
  {
    title: 'COMPONENT_HIERARCHY_TABLE.MAINTENANCE_ID',
    key: fields.maintenanceID,
    type: fields.maintenanceID,
    isSort: true,
    class: 'maintenance-id-column',
  },
  {
    title: '',
    type: fields.actionMenu,
    key: fields.actionMenu,
    isSort: false,
    class: 'action-menu-column',
    className: 'action-menu',
  },
];
export const filterProps = {
  sortByColumn: 'SortByColumn',
  sortDirection: 'SortDirection',
  perPage: 'PerPage',
  lastSeen: 'LastSeen',
  searchText: 'SearchText',
  hasNext: 'HasNext',
  totalItems: 'TotalItems',
  parentID: 'ParentID',
  rootID: 'RootID',
  hierarchyID: 'HierarchyID',
};

export const sortDirection = {
  asc: 'ASC',
  desc: 'DESC',
};

export const defaultFilters = {
  [filterProps.sortByColumn]: fields.code,
  [filterProps.sortDirection]: sortDirection.asc,
  [filterProps.perPage]: 50,
  [filterProps.lastSeen]: 0,
  [filterProps.searchText]: '',
  [filterProps.hasNext]: false,
};

export const defaultComponentsFilters = {
  [filterProps.sortByColumn]: fields.code,
  [filterProps.sortDirection]: sortDirection.asc,
  [filterProps.perPage]: 50,
  [filterProps.lastSeen]: 0,
  [filterProps.searchText]: '',
  [filterProps.hasNext]: false,
};

export const placements = {
  left: 'left',
  right: 'right',
  top: 'top',
  bottom: 'bottom',
};

export const formFields = {
  code: {
    name: fields.code,
    label: 'COMPONENT_HIERARCHY_TABLE.CODE',
    id: 'component-hierarchy-form-code',
  },
  name: {
    name: fields.name,
    label: 'COMPONENT_HIERARCHY_FORM.NAME',
    id: 'component-hierarchy-form-name',
  },
  costCentre: {
    name: fields.costCentre,
    label: 'COMPONENT_HIERARCHY_TABLE.COST_CENTRE',
    id: 'component-hierarchy-form-cost-centre',
  },
  location: {
    name: fields.location,
    label: 'COMPONENT_HIERARCHY_TABLE.LOCATION',
    id: 'component-hierarchy-form-location',
  },
  owner: {
    name: fields.owner,
    label: 'COMPONENT_HIERARCHY_TABLE.OWNER',
    id: 'component-hierarchy-form-owner',
  },
  maintenanceID: {
    name: fields.maintenanceID,
    label: 'COMPONENT_HIERARCHY_TABLE.MAINTENANCE_ID',
    id: 'component-hierarchy-form-maintenance-id',
  },
  createdBy: {
    name: fields.createdBy,
    label: 'COMPONENT_HIERARCHY_FORM.CREATED_BY',
    id: 'component-hierarchy-form-created-by',
  },
  createdAt: {
    name: fields.createdAt,
    label: 'COMPONENT_HIERARCHY_FORM.CREATED_AT',
    id: 'component-hierarchy-form-created-at',
  },
};

export const ActionTypesToolbar = {
  view: 'view',
  edit: 'edit',
  delete: 'delete',
};

export const DeleteActionTypes = {
  deleteAll: 'deleteAll',
  deleteAndMove: 'deleteAndMove',
  deleteAndUnassign: 'deleteAndUnassign',
};

export const inputFields = {
  password: 'Password',
};

export const confirmModalInputFields = {
  password: {
    name: inputFields.password,
    label: 'COMPONENT_HIERARCHY_MODAL.CONFIRM_PASSWORD',
    placeholder: 'COMPONENT_HIERARCHY_MODAL.CONFIRM_PASSWORD',
    id: 'confirm-modal-ch-password',
    required: 'COMPONENT_HIERARCHY_FORM.REQUIRED',
  },
};

export const DeleteActionText = {
  [DeleteActionTypes.deleteAll]: 'COMPONENT_HIERARCHY_NOTIFICATION.DELETE_ALL_SUCCESSFUL',
  [DeleteActionTypes.deleteAndUnassign]: 'COMPONENT_HIERARCHY_NOTIFICATION.DELETE_AND_UNASSIGN_SUCCESSFUL',
  [DeleteActionTypes.deleteAndMove]: 'COMPONENT_HIERARCHY_NOTIFICATION.DELETE_AND_MOVE_SUCCESSFUL',
};

export const modalFirstTableConfig = tableConfig.slice(0, -1);

export const modalSecondTableConfig = [
  {
    title: '',
    key: fields.icon,
    type: fields.icon,
    class: 'icon-column',
  },
  {
    title: 'COMPONENT_HIERARCHY_TABLE.CODE',
    key: fields.code,
    type: fields.code,
    isSort: true,
    class: 'code-column',
  },
  {
    title: 'COMPONENT_HIERARCHY_TABLE.NAME',
    key: fields.name,
    type: fields.name,
    isSort: true,
    class: 'name-column',
  },
  {
    title: 'COMPONENT_HIERARCHY_TABLE.TYPE',
    key: fields.type,
    type: fields.type,
    isSort: true,
    class: 'cost-centre-column',
  },
  {
    title: 'COMPONENT_HIERARCHY_TABLE.CRITICAL',
    key: fields.critical,
    type: fields.critical,
    isSort: true,
    class: 'location-column',
  },
  {
    title: 'COMPONENT_HIERARCHY_TABLE.PARENT_ASSET',
    key: fields.parentAsset,
    type: fields.parentAsset,
    isSort: true,
    class: 'owner-column',
  },
  {
    title: 'COMPONENT_HIERARCHY_TABLE.ID',
    key: fields.id,
    type: fields.id,
    isSort: true,
    class: 'maintenance-id-column',
  },
];

export const SearchPropName = {
  levels: 'levelsSearchText',
  components: 'componentsSearchText',
};
export const ComponentHierarchyUnassignedLevelID = 'Unassigned';
