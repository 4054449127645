import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { formValueSelector, getFormValues } from 'redux-form';
import { submit, reset } from 'redux-form';

import { map, flatten, forEach, chain, transform, isEmpty, find } from 'lodash';

import { connect } from 'react-redux';

import { FORMS } from '../../../../common/constants';

import NdtAlarmsForm from './ndt-alarms-modal-form';
import Modal from '../../../../common/modal/components/modal';
import EmailModal from './ndt-alarms-modal-email';

import { getNdtAlarms, getDescriptorData, addAlarmEmail, removeAlarmEmail } from '../../actions/ndt-alarms-actions';
import { setNdtAlarms } from '../../actions/action-creators';

import { sections, defaultAlarmValues, commonFields, colorsMap } from './constants/ndt-alarms';

import '../../styles/ndt-alarms-modal.scss';

const sectionVariants = flatten(
  map(sections, item => {
    return map(item.variants);
  })
);

class NDTModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalData: {
        isOpen: false,
      },
    };
  }

  componentDidMount = () => {
    const { getNdtAlarms, getDescriptorData, measurmentID } = this.props;
    getDescriptorData();
    getNdtAlarms(measurmentID);
  };

  componentWillUnmount = () => {
    const { resetNdtAlarms } = this.props;
    resetNdtAlarms();
  };

  openEmailModal = (alarmId, type) => {
    const { t } = this.context;
    const { addAlarmEmail, removeAlarmEmail, resetForm } = this.props;
    this.setState({ selectedEmailKey: type });

    const closeAction = () => {
      this.setState({
        modalData: {
          isOpen: false,
        },
      });
    };

    this.setState({
      modalData: {
        isOpen: true,
        title: t('NDT_EMAILS_MODAL.TITLE'),
        type: '',
        customClassName: 'modal-no-max-height',
        CustomContent: restProps => (
          <EmailModal alarmId={alarmId} addAlarmEmail={addAlarmEmail} type={type} {...restProps} removeAlarmEmail={removeAlarmEmail} resetForm={resetForm} closeModal={closeAction} />
        ),
        closeAction: closeAction,
      },
    });
  };

  render() {
    const { t } = this.context;
    const { initialValues, unit, changeField, availableColors, descriptorData, disabledData, submit, ndtEmails, onSubmit, closeModal, onChange, measurmentID, ownerID } = this.props;
    const { modalData, confirmationModal, selectedEmailKey } = this.state;
    const { openEmailModal } = this;

    return (
      <div className="ndt-alarms-modal-content">
        <div className="ndt-alarms-modal-content__header">
          <h3 className="f-primary">{t('NDT_ALARMS_MODAL.TITLE')}</h3>
        </div>
        <NdtAlarmsForm
          {...{
            onSubmit: vals => onSubmit(vals, measurmentID),
            closeModal,
            unit,
            descriptorData,
            initialValues,
            onChange,
            availableColors,
            changeField,
            disabledData,
            submit,
            ndtEmails,
            openEmailModal,
            ownerID,
          }}
        />

        <Modal {...modalData} emails={ndtEmails && ndtEmails[selectedEmailKey]} />
        <Modal {...confirmationModal} />
      </div>
    );
  }
}

NDTModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

NDTModal.defaultProps = {
  unit: 'mm',
};

const mapInitialValues = (predefinedVals, defaultColor, dData, ndtEmails) => {
  let resObj = {};

  if (!isEmpty(predefinedVals)) {
    const res = chain(predefinedVals)
      .transform((obj, values, key) => {
        const newObj = transform(values, (a, b, c) => {
          const props = `${c}_${values[commonFields.alarmType]}`;

          if (c === commonFields.color && !b) {
            b = defaultColor;
          }
          if (c === commonFields.email) {
            b = !isEmpty(ndtEmails) ? find(ndtEmails, { AlarmID: values[commonFields.id] }) : '';
          }

          if (c === commonFields.operationID && !isNaN(b)) {
            if (dData) {
              b = !isNaN(b) ? find(dData, item => item.value === b) : dData[0];
            } else {
              b = null;
            }
          }

          a[props] = b;
        });
        obj[0] = { ...obj[0], ...newObj };
      })
      .value();
    resObj = res[0];
  } else {
    forEach(sectionVariants, sectionItem => {
      Object.keys(defaultAlarmValues).map(key => {
        const newKey = `${key}_${sectionItem}`;
        let newVal = defaultAlarmValues[key];
        if (key === commonFields.color) {
          newVal = defaultColor;
        }

        if (key === commonFields.operationID) {
          if ([sections.low.variants.LL, sections.low.variants.L].indexOf(sectionItem) > -1) {
            newVal = find(dData, { value: 1 });
          } else {
            newVal = dData[0];
          }
        }

        resObj = {
          ...resObj,
          [newKey]: newVal,
        };
        return null;
      });
    });
  }

  return resObj;
};
const selector = formValueSelector(FORMS.alarmsCreate);

const getDisabledData = state => {
  let resObj = {};
  forEach(sectionVariants, item => {
    resObj[item] = !(selector(state, `${commonFields.active}_${item}`) || false);
  });

  return resObj;
};

const mapDispatchToProps = dispatch => ({
  getNdtAlarms: measurmentId => dispatch(getNdtAlarms(measurmentId, null, true)),
  getDescriptorData: () => dispatch(getDescriptorData()),
  resetNdtAlarms: () => dispatch(setNdtAlarms()),
  submit: () => dispatch(submit(FORMS.alarmsCreate)),
  addAlarmEmail: (email, id, type, callback) => dispatch(addAlarmEmail(email, id, type, callback)),
  removeAlarmEmail: (id, type) => dispatch(removeAlarmEmail(id, type)),
  resetForm: form => dispatch(reset(form)),
});

const mapStateToProps = state => {
  const colors = state.themeReducer.severityColors;
  const fetchedVals = state.ndtReducer.ndtAlarms;
  const descriptorData = state.ndtReducer.descriptorData;
  const ndtEmails = state.ndtReducer.ndtEmails;
  const availableColors = colors
    ? {
        [colorsMap.severityYellow]: colors.severityYellow,
        [colorsMap.severityOrange]: colors.severityOrange,
        [colorsMap.severityRed]: colors.severityRed,
      }
    : {};
  const disabledData = getDisabledData(state);

  return {
    availableColors,
    descriptorData,
    initialValues: mapInitialValues(fetchedVals, colorsMap.severityYellow, descriptorData, ndtEmails),
    formValues: getFormValues(FORMS.alarmsCreate)(state),
    disabledData,
    ndtEmails,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NDTModal);
