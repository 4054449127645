import React from 'react';
import Icon from '../../icon/components/icon';
import InfoTooltip from '../../tooltip/components/info-tooltip';
import { TablePropTypes } from '../constants/constants';
import '../styles/table-header.scss';
import SortIcon from './sort-icon';

const renderTableHeaderCells = (config, t, onSortClick, sortingObj, sortDirectionProp, sortByColumnProp) => {
  return config.map((cell, index) => {
    return (
      <div
        className={`twp-header__cell ${cell.class} ${cell.isSort ? 'clickable' : ''} ${cell.headerClassName ? cell.headerClassName : ''}`}
        onClick={() => (cell.isSort && onSortClick ? onSortClick(cell.key) : null)}
        key={index}
      >
        <div className={`twp-header__cell__container`}>
          <p className={`twp-header__cell__container__text`}>{t ? t(cell.title) : cell.title}</p>
          {cell.isSort ? (
            <SortIcon
              {...{
                sortingObj,
                sortDirectionProp,
                isDisabled: sortingObj[sortByColumnProp] !== cell.key,
              }}
            />
          ) : null}
          {/* this serves to present label on table header column */}
          {cell.isInfoTooltip ? (
            <InfoTooltip
              customComponent={<label>{t(cell.infoTooltipLabel)}</label>}
              offsetY={8}
              offsetX={8}
              Component={() => <Icon name="info" size="sm" />}
              componentProps={{ title: '' }}
              containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
            />
          ) : null}
        </div>
      </div>
    );
  });
};

const Header = ({ tableConfig, translationModule, stickyHeader, onSortClick, sortingObj, sortDirectionProp, sortByColumnProp }) => {
  return <div className={`twp-header ${stickyHeader ? 'sticky' : ''}`}>{renderTableHeaderCells(tableConfig, translationModule, onSortClick, sortingObj, sortDirectionProp, sortByColumnProp)}</div>;
};

const { tableConfig, translationModule, stickyHeader, onSortClick, sortingObj, sortDirectionProp, sortByColumnProp } = TablePropTypes;

Header.propTypes = {
  tableConfig,
  translationModule,
  stickyHeader,
  onSortClick,
  sortingObj,
  sortDirectionProp,
  sortByColumnProp,
};

export default Header;
