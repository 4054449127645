import React from 'react';
import { defectNotificationFields, linkedComponentDetailsDisplayProps } from '../constants/constants';
import PropTypes from 'prop-types';

const DefectNotificationComponentPopover = ({ component }, { t }) => {
  return (
    <div>
      <p className="f-primary bold">{component[defectNotificationFields.name]}</p>
      {linkedComponentDetailsDisplayProps.map(prop => {
        return (
          <>
            <p className="f-secondary-dark mt-12 light-bold">{t(prop.title)}</p>
            <p className="f-primary light-bold mt-4">{component[prop.name] ? component[prop.name] : '-'}</p>
          </>
        );
      })}
    </div>
  );
};

DefectNotificationComponentPopover.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default DefectNotificationComponentPopover;
