import React from 'react';
import PropTypes from 'prop-types';

import { fields } from '../constants/constants';

import '../../../app/inspections/styles/isolation-certificate-popover.scss';

const KeyboxPopover = ({ certificateName, isolationCertificates = [] }, { t }) => (
  <div className="isolation-certificate-popover">
    <p className="f-primary certificate-name">{certificateName}</p>
    <p className="f-secondary-dark permit-item">{t('KEYBOX_DETAILS.ISOLATION_CERTIFICATES')}</p>
    {isolationCertificates && isolationCertificates.length > 0 && isolationCertificates.map(ic => <p className="f-primary permit-item">{ic[fields.name]}</p>)}
  </div>
);

KeyboxPopover.contextTypes = {
  t: PropTypes.func.isRequired,
};

KeyboxPopover.propTypes = {
  certificateName: PropTypes.string,
  isolationCertificates: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number,
      Name: PropTypes.string,
      Status: PropTypes.string,
    })
  ),
};

export default KeyboxPopover;
