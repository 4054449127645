import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';

import { debounce, isEmpty, some } from 'lodash';

import ExternalUploadModal from './external-upload-modal';
import { validate } from './validators/defect-validator';

import Modal from '../../../../common/modal/components/modal';
import Tab from '../../../../common/tabs/component/tab';
import Tabs from '../../../../common/tabs/component/tabs';
import MeasurementForm from './measurement-form';
import MeasurementHistory from './measurement-history';
import UploadModal from './upload-modal';

import { FEATURES } from '../../../../common/constants';
import { notificationModalCustom } from '../../../../common/modal/actions/modal-actions';
import { getMeasurementFilesUploaded } from '../../../upload/actions/external-upload-actions';
import { getDefectDetails, updateDefect } from '../../actions/inspection-actions';

import { FORMS } from '../../../../common/constants';
import Loader from '../../../../common/global-loader/components/simple-loader';
import { setUploadItems } from '../../../upload/actions/upload-actions';
import { measurementTypes } from '../../constants/constants';
import { formConstants, maxDefectUploadFileCount, maxDefectUploadFileSize, tabNames, toolbarItems } from '../../constants/measurement-constants';

class MeasurementDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalData: {
        isOpen: false,
      },
      modalRelatedImagesData: {
        isOpen: false,
      },
      measurement: {},
      activeToolbarItem: toolbarItems[tabNames.details].name,
      elementAlreadyFocused: false,
    };
    this.formChangeDebounce = debounce(this.submitForm, 100);
  }
  componentWillUnmount() {
    this.formChangeDebounce.cancel();
  }

  fetchData = tabName => {
    const { elementAlreadyFocused } = this.state;
    const { queryItem, getMeasurementFilesUploaded, getDefectDetails, viewer } = this.props;
    if (!queryItem || queryItem <= 0) {
      return;
    }

    if (tabName === tabNames.details) {
      getDefectDetails(queryItem, measurement => {
        this.setState({ measurement });
        getMeasurementFilesUploaded(queryItem);
        if (measurement?.Geometry?.coordinates?.[0] && measurement?.CameraPosition?.coordinates && viewer && !elementAlreadyFocused) {
          viewer.zoomToPosition(
            { x: measurement.CameraPosition.coordinates[0], y: measurement.CameraPosition.coordinates[1], z: measurement.CameraPosition.coordinates[2] },
            measurement.Geometry.coordinates,
            500
          );
          this.setState({ elementAlreadyFocused: true });
        }
      });
    }
  };

  componentDidMount() {
    const { queryItem } = this.props;
    if (!queryItem || queryItem < 0) {
      // It is not a deep link so the camera should not focus on it
      this.setState({ elementAlreadyFocused: true });
      return;
    }
    this.fetchData(tabNames.details);
  }

  componentDidUpdate = prevProps => {
    const { queryItem, viewer } = this.props;

    if (queryItem && queryItem > 0 && (prevProps.queryItem !== queryItem || viewer !== prevProps.viewer)) {
      this.fetchData(tabNames.details);
    }
  };

  handleOnChange = (values, b, c, oldValues) => {
    if (!c.pristine) {
      const errors = validate(values);

      if (isEmpty(errors)) {
        this.formChangeDebounce(values);
      }
    }
  };

  submitForm = values => {
    const { updateDefect } = this.props;
    const { comments, Geometry, ...defectValues } = values;

    updateDefect(defectValues, () => null);
  };

  openUploadModal = (onLocalUpload, currentFilesCount = 0, supportedMimeTypes) => {
    const { openExternalUploadModal } = this;
    const { measurement } = this.state;
    const { notificationModalCustom } = this.props;
    this.setState(prevProps => ({
      modalData: {
        ...prevProps.modalData,
        isOpen: true,
        CustomContent: () => (
          <UploadModal
            closeAction={this.closeUploadModal}
            onDrop={files => {
              const hasLargeFiles = some(files, item => item.size > maxDefectUploadFileSize.bytes);
              if (hasLargeFiles) {
                //open modal with message
                notificationModalCustom(
                  true,
                  { key: 'RIGHT_TOOlBAR.INSPECTION_ASSETS_UPLOAD_MODAL.ERROR_FILE_SIZE', props: { fileSize: `${maxDefectUploadFileSize.value}${maxDefectUploadFileSize.unit}` } },
                  'RIGHT_TOOlBAR.INSPECTION_ASSETS_UPLOAD_MODAL.ERROR_FILE_SIZE_TITLE'
                );
                return;
              }
              const filesTotal = (files.length || 0) + currentFilesCount;
              if (filesTotal > maxDefectUploadFileCount) {
                //open modal with message
                notificationModalCustom(true, 'INSPECTION_ASSET_MODAL_ERROR.ERROR_TEXT', 'INSPECTION_ASSET_MODAL_ERROR.ERROR_TITLE');
                return;
              }

              onLocalUpload(files, measurement);
            }}
            supportedMimeTypes={supportedMimeTypes}
            onExternalUpload={() => openExternalUploadModal(currentFilesCount)}
            className="project-dropzone"
            defect={measurement}
          />
        ),
        type: 'none',
        confirmAction: this.closeUploadModal,
        closeAction: this.closeUploadModal,
      },
    }));
  };

  openExternalUploadModal = currentFilesCount => {
    const { defect } = this.props;
    this.setState(prevProps => ({
      modalData: {
        ...prevProps.modalData,
        isOpen: true,
        CustomContent: () => (
          <ExternalUploadModal
            closeAction={this.closeUploadModal}
            defect={defect}
            isValid={files => {
              const filesTotal = (files.length || 0) + currentFilesCount;
              if (filesTotal > maxDefectUploadFileCount) {
                //open modal with message
                notificationModalCustom(true, 'INSPECTION_ASSET_MODAL_ERROR.ERROR_TEXT', 'INSPECTION_ASSET_MODAL_ERROR.ERROR_TITLE');
                return false;
              }
              return true;
            }}
          />
        ),
        type: 'none',
        closeAction: this.closeUploadModal,
      },
    }));
  };

  openConfirmationModal = confirmAction => {
    const { t } = this.context;
    this.setState({
      modalData: {
        isOpen: true,
        type: 'yes-no',
        content: t('DEFECT_RESOLVE.CONFIRMATION_MODAL.TEXT'),
        title: t('DEFECT_RESOLVE.CONFIRMATION_MODAL.TITLE'),
        confirmAction: () => {
          confirmAction();
          this.setState({ modalData: { isOpen: false } });
        },
        closeAction: () => this.setState({ modalData: { isOpen: false } }),
      },
    });
  };

  closeUploadModal = () => {
    const { measurement } = this.state;
    const { getMeasurementFilesUploaded } = this.props;
    setUploadItems(null);
    getMeasurementFilesUploaded(measurement[formConstants.fields.id]);
    this.setState(prevProps => ({
      modalData: {
        ...prevProps.modalData,
        isOpen: false,
      },
    }));
  };

  render() {
    const { t } = this.context;
    const { modalData, modalRelatedImagesData, measurement, activeToolbarItem } = this.state;
    const { deleteDefectModalData, uploadInProgress, inspectionID, changeField, measurementFiles, openDownloadReportModalSingleDefect, handleActivePage, measurementsDetailsLoading, queryItem } =
      this.props;

    const { openUploadModal, openConfirmationModal } = this;

    if (measurementsDetailsLoading) {
      return <Loader isLoading={true} />;
    }

    return (
      <div className="defect-details">
        <Tabs defaultTabKey={activeToolbarItem} navigationClassName="component-details__tabs" onChange={this.fetchData}>
          <Tab title={toolbarItems[tabNames.details].label} tabKey={tabNames.details}>
            <>
              <div className="defect-details__title f-primary">{t('DEFECT_DETAILS')}</div>
              <MeasurementForm
                onChange={modalData.isOpen ? null : this.handleOnChange}
                openUploadModal={openUploadModal}
                inspectionID={inspectionID}
                changeField={changeField}
                measurementFiles={measurementFiles}
                openConfirmationModal={openConfirmationModal}
                openDownloadReportModalSingleDefect={openDownloadReportModalSingleDefect}
                initialValues={measurement}
                selectedDefect={measurement}
                handleActivePage={handleActivePage}
              />
            </>
          </Tab>
          {FEATURES.modulesHistory?.visible && (
            <Tab title={toolbarItems[tabNames.history].label} tabKey={tabNames.history}>
              <MeasurementHistory measurementID={queryItem} />
            </Tab>
          )}
        </Tabs>
        <Modal {...modalData} modalDisabled={uploadInProgress} />
        <Modal {...modalRelatedImagesData} />
        <Modal {...deleteDefectModalData} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  deleteDefectModalData: state.inspectionReducer.delDefectModalData,
  uploadInProgress: state.uploadReducer.uploadInProgress,
  measurementFiles: state.uploadReducer.measurementFiles,
  measurementsDetailsLoading: state.inspectionReducer.measurementsDetailsLoading,
});

const mapDispatchToProps = dispatch => ({
  updateDefect: (defect, callback) => dispatch(updateDefect(defect, true, callback)),
  setUploadItems: () => setUploadItems(null),
  getMeasurementFilesUploaded: defectId => dispatch(getMeasurementFilesUploaded(defectId)),
  changeField: (fieldName, value) => dispatch(change(FORMS.measurementForm, fieldName, value)),
  notificationModalCustom: (isOpen, errorMessage, title) => dispatch(notificationModalCustom(isOpen, errorMessage, title)),
  getDefectDetails: (ID, callback) => dispatch(getDefectDetails({ ID, [formConstants.fields.systemType]: measurementTypes.measurement }, callback, {}, true, true)),
});

MeasurementDetails.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(MeasurementDetails);
