import React, { Component } from 'react';

import { chain, isEmpty } from 'lodash';

import DatePicker from '../../../../common/form/components/date-picker';
import SmallDropdown from '../../../../common/small-dropdown/components/small-dropdown';

import { editInspectionMinDate } from '../../constants/edit-inspection';

import Helpers from '../../../../common/helpers';

import '../../styles/editable-date.scss';
import Icon from '../../../../common/icon/components/icon';

class EditableDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditMode: false,
    };
    this.node = React.createRef();
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }

  handleClickOutside = e => {
    if (this.node && this.node.current && this.node.current.contains(e.target)) {
      // element is clicked no need to call click outside handler function
      return;
    }
    const { isEditMode } = this.state;
    if (isEditMode) {
      this.handleEditMode();
    }
  };

  handleEditMode = () => {
    const { isEditMode } = this.state;
    this.setState({ isEditMode: !isEditMode });
  };

  handleOnClose = () => {
    const { isEditMode } = this.state;
    if (isEditMode) {
      this.handleEditMode();
    }
  };

  getMaxTime = selectedDate => {
    const current = new Date(selectedDate);
    const all = new Date(new Date().setHours(23)).setMinutes(59);
    return current.getDate() === new Date().getDate() ? new Date() : all;
  };

  render() {
    const { displayValue, currentDate, handleDateChange, selectedDate, disabled, inspections, inspectionId, handleInspectionClick } = this.props;
    const { isEditMode } = this.state;

    const actionsMenu = chain(inspections)
      .filter(item => item.ID !== inspectionId)
      .map(item => {
        return {
          title: Helpers.getDateFromUnix(item.InspectionDate, 'MMM DD'),
          action: () => {
            handleInspectionClick(item.ID);
          },
        };
      })
      .value();

    return (
      <div className="editable-date" ref={this.node}>
        {!isEditMode && <p className="f-secondary-dark noselect">{displayValue}</p>}
        {isEditMode && (
          <DatePicker
            disabledKeyboardNavigation={true}
            selected={selectedDate || (currentDate ? new Date(currentDate * 1000) : new Date())}
            onChange={handleDateChange}
            dateFormat="MMMM d, yyyy h:mm aa"
            timeFormat="h:mm aa"
            showTimeSelect
            popperPlacement="bottom-end"
            onCalendarClose={this.handleOnClose}
            minDate={new Date(editInspectionMinDate * 1000)}
            maxDate={new Date()}
            maxTime={this.getMaxTime(currentDate * 1000)}
            minTime={new Date(new Date().setHours(0)).setMinutes(0)}
            disabled={disabled}
            open={isEditMode}
          />
        )}
        {!disabled && displayValue && (
          <>
            <Icon name="pen" size="xs" className="label-container__icon" onClick={this.handleEditMode} />

            {!isEmpty(actionsMenu) && (
              <SmallDropdown
                actionsMenu={actionsMenu}
                getClickAction={(action, b, selfClose, e) => {
                  e?.preventDefault && e.preventDefault();
                  selfClose();
                  action && action();
                }}
              />
            )}
          </>
        )}
      </div>
    );
  }
}
export default EditableDate;
