import React from 'react';
import { ReactComponent as SortUp } from '../assets/sort-up.svg';
import { ReactComponent as SortDown } from '../assets/sort-down.svg';
import { sortingOrder } from '../constants/constants';
import '../styles/sort-icon.scss';

const SortIcon = ({ sortingObj, isDisabled, sortDirectionProp }) => {
  const sort = !sortingObj || isDisabled ? sortingOrder.none : sortingObj[sortDirectionProp] === sortingOrder.asc ? sortingOrder.asc : sortingOrder.desc;

  return (
    <div className="sort-icon" data-cy="sort-icon">
      <SortUp className={`small ${sort === sortingOrder.desc ? 'svg-primary-g' : 'svg-gray-g'}`} />
      <SortDown className={`small ${sort === sortingOrder.asc ? 'svg-primary-g' : 'svg-gray-g'}`} />
    </div>
  );
};

export default SortIcon;
