import React from 'react';
import PropTypes from 'prop-types';

import Helpers from '../../../common/helpers';
import Icon from '../../icon/components/icon';

import '../styles/back-button.scss';

const BackButton = ({ path, text = 'BACK', iconClass = '', textClass = 'f-secondary-green' }, { t }) => (
  <div className="back-button" onClick={() => Helpers.goTo(path)}>
    <Icon name="chevron" className={iconClass} />
    <p className={`back-button__text ${textClass}`}>{t(text)}</p>
  </div>
);

BackButton.contextTypes = {
  t: PropTypes.func.isRequired,
};

BackButton.propTypes = {
  path: PropTypes.string.isRequired,
  text: PropTypes.string,
  iconClass: PropTypes.string,
  textClass: PropTypes.string,
};

export default BackButton;
