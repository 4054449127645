import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

import TableComponent from '../../../common/table/components/table-component';
import Helpers from '../../../common/helpers';
import SearchInput from '../../../common/input/components/search-input';
import SmallDropdown from '../../../common/small-dropdown/components/small-dropdown';
import RenderIf from '../../../common/render-if/components/render-if';

import { contractorTableHeader, formConstants } from '../constants/constants';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../common/permissions-constants';

import '../styles/contractors-list.scss';

class ContractorsList extends Component {
  constructor(props) {
    super(props);
    this.searchChangeDebounced = debounce(props.handleSearch, 500);
  }
  componentWillUnmount() {
    this.searchChangeDebounced.cancel();
  }

  handleInputChange = e => {
    this.searchChangeDebounced(e.target.value);
  };
  render() {
    const { contractors, filter, onColumnSort, openContractorEditModal, handleDeleteContractor, updateContractorStatus, isArchived, isLoading } = this.props;
    const { t } = this.context;
    const menuOptions = (data, itemIndex) => [
      {
        title: 'CONTRACTORS_TABLE.ACTIONS_MENU_ITEM_VIEW',
        action: () => openContractorEditModal(data, true),
      },
      {
        title: 'CONTRACTORS_TABLE.ACTIONS_MENU_ITEM_EDIT',
        action: () => openContractorEditModal(data),
        access: {
          visibleFor: PERMISSIONS[PERMISSION_TYPES.contractors].edit.name,
          ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.contractors].create.name,
          id: data[formConstants.fields.createdBy],
        },
      },
      {
        title: isArchived ? 'CONTRACTORS_TABLE.ACTIONS_MENU_ITEM_UNARCHIVE' : 'CONTRACTORS_TABLE.ACTIONS_MENU_ITEM_ARCHIVE',
        action: () => updateContractorStatus(data),
        access: {
          visibleFor: PERMISSIONS[PERMISSION_TYPES.contractors].edit.name,
          ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.contractors].create.name,
          id: data[formConstants.fields.createdBy],
        },
      },
      {
        title: 'CONTRACTORS_TABLE.ACTIONS_MENU_ITEM_DELETE',
        action: () => handleDeleteContractor(data),
        access: {
          visibleFor: PERMISSIONS[PERMISSION_TYPES.contractors].delete.name,
        },
        separator: true,
        isHighlighted: true,
      },
    ];

    return (
      <div className="contractors-list">
        <div className="contractors-list-search-wrapper">
          <SearchInput onChange={this.handleInputChange} placeholder={t('PROJECT.SEARCH.PLACEHOLDER')} stripped />
        </div>
        <RenderIf if={(!contractors || !contractors.length) && !isLoading}>
          <div className="empty-state">
            <p className="f-primary">{t('CONTRACTORS.EMPTY_STATE')}</p>
          </div>
        </RenderIf>
        <RenderIf if={!isLoading && contractors && contractors.length > 0}>
          <div className="contractors-list-table-container">
            <TableComponent
              tableConfig={[
                ...contractorTableHeader,
                {
                  title: 'CONTRACTORS_TABLE.ACTIONS',
                  CustomComponent: (data, itemIndex) => (
                    <SmallDropdown
                      actionsMenu={menuOptions(data, itemIndex)}
                      getClickAction={(action, b, selfClose, e) => {
                        e?.preventDefault && e.preventDefault();
                        selfClose();
                        if (action) {
                          action();
                        }
                      }}
                      offsetX={-150}
                    />
                  ),
                  enableSort: false,
                },
              ]}
              data={contractors || []}
              tableCustomClass="contractor-table"
              //   onRowClick={this.onRowClick}
              onColumnSort={onColumnSort}
              sortingObj={filter}
              translationModule={t}
              formatCell={(value, type, index, item) => {
                return Helpers.formatCell(value, type, index, item);
              }}
              stickyHeader={true}
            />
          </div>
        </RenderIf>
      </div>
    );
  }
}

ContractorsList.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ContractorsList;
