import { map } from 'lodash';
import React from 'react';
import ItemsTableRenderer from '../../../../common/items-renderer/components/items-table-renderer';
import LoadMore from '../../../../common/load-more/components/load-more';
import RenderIf from '../../../../common/render-if/components/render-if';
import { tableConfigRegime } from '../../../inspections/constants/work-order-constants';
import { filterFields, formConstants } from '../../constants/regime-constants';

const MaintenanceRegimeTasks = ({ moduleDetails, onColumnSort, onRowClick, formatCell, translationModule, loadMore, regimeTasksLoading, projectId, inspectionId }) => {
  return (
    <div className="table-container">
      <ItemsTableRenderer
        isLoading={regimeTasksLoading}
        tableConfig={tableConfigRegime}
        translationModule={translationModule}
        data={map(moduleDetails.maintenanceRegimeTasks, item => ({ ...item, selected: item[formConstants.fields.id] === moduleDetails[formConstants.fields.id] ? true : false }))}
        onRowClick={onRowClick}
        sortingObj={moduleDetails.sortingFilter}
        onColumnSort={onColumnSort}
        formatCell={formatCell}
        emptyStateText={'WORK_ORDERS.EMPTY_STATE'}
        sortByColumnProp={filterFields.sortByColumn}
      />
      <RenderIf if={moduleDetails.maintenanceRegimeTasks && moduleDetails.maintenanceRegimeTasks.length > 0}>
        <LoadMore
          disabled={!moduleDetails.pagingObject[filterFields.hasNext]}
          loaded={moduleDetails.maintenanceRegimeTasks?.length}
          total={moduleDetails.pagingObject[filterFields.totalItems]}
          totalPosition="center"
          label="COMPONENT_HISTORY.LOAD_MORE"
          showButton
          showTotalUp
          buttonVariant="success-outline"
          resultsText="WORK_ORDERS.SHOWING_TOTAL_ITEMS"
          onClick={loadMore}
        />
      </RenderIf>
    </div>
  );
};

export default MaintenanceRegimeTasks;
