import { find, isEmpty, map } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import AccessRenderer from '../../access-renderer/components/access-renderer';
import Helpers from '../../helpers';
import Tab from './tab';

import { clearUnsavedChangesDirty } from '../../../app/inspections/actions/action-creators';
import SmallDropdown from '../../small-dropdown/components/small-dropdown';
import '../styles/tabs.scss';

const Tabs = ({ children, className, defaultTabKey, navigationClassName, tabsHeader, showDropdown, formHasUnsavedChanges, setFormUnsavedChanges, clearUnsavedChangesDirty, user, onChange }, { t }) => {
  const [activeTabKey, setActiveTabKey] = useState(defaultTabKey);

  useEffect(() => {
    const firstChildWithPermissions = find(children, child => child?.props?.access && Helpers.hasAccess({ user, ...child.props.access }));
    const key = firstChildWithPermissions?.props?.tabKey;

    if (key && key !== activeTabKey) {
      changeTab(key);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeTab = (tabKey, scrollEnabled) => {
    // used for handling unsaved changes in the form its being called in
    if (!formHasUnsavedChanges || window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
      setFormUnsavedChanges && typeof setFormUnsavedChanges === 'function' && setFormUnsavedChanges({ hasUnsavedChanges: false });
      clearUnsavedChangesDirty && typeof clearUnsavedChangesDirty === 'function' && clearUnsavedChangesDirty();

      setActiveTabKey(tabKey);
      if (onChange && typeof onChange === 'function') {
        onChange(tabKey);
      }
      if (scrollEnabled) {
        Helpers.scrollIntoView('tabs__navigation work-tab__tabs', tabKey, 0, null, true);
      }
    }
  };

  const getActiveTabKey = children => {
    const key = activeTabKey;

    if (!isEmpty(key)) {
      return key;
    } else if (isEmpty(children) || !children[0].props.tabKey) {
      return null;
    }
    return children[0].props.tabKey;
  };

  const renderNavigationItems = children => {
    const activeTabKey = getActiveTabKey(children);

    return map(children, (child, index) => {
      if (!child.props.visible) {
        return null;
      }

      return (
        <AccessRenderer {...(child.props.access || {})} key={index}>
          {({ hasAccess }) => (
            <div
              onClick={!child.props.disabled ? () => (hasAccess ? changeTab(child.props.tabKey) : null) : () => null}
              className={`tabs__navigation-item ${!hasAccess ? 'disabled' : ''} ${activeTabKey === child.props.tabKey ? 'active' : ''}`}
              data-cy="tab-navigation"
              id={child.props.tabKey}
              disabled={child.props.disabled}
            >
              <p className="f-primary">{t(child.props.title)}</p>
              {child.props.customContent}
            </div>
          )}
        </AccessRenderer>
      );
    });
  };

  const renderTabContent = children => {
    const activeTabKey = getActiveTabKey(children);

    const foundIndex = children.findIndex && children.findIndex(child => child.props.tabKey === activeTabKey);
    if (foundIndex > -1) {
      return children[foundIndex].props.children;
    }
    return null;
  };

  const renderDropdown = children => {
    if (!showDropdown) return;
    const activeTabKey = getActiveTabKey(children);
    const visibleChildren = children.filter(child => child.props.visible);
    const options = visibleChildren.map(child => ({
      title: t(child.props.title),
      access: child.props.access || {},
      action: () => changeTab(child.props.tabKey, true),
      checkIcon: activeTabKey === child.props.tabKey && 'check',
      customMenuItemClassName: activeTabKey === child.props.tabKey && 'tabs-dropdown',
    }));

    return (
      <SmallDropdown
        actionsMenu={options}
        getClickAction={(action, b, selfClose, e) => {
          e?.preventDefault && e.preventDefault();
          selfClose();
          if (action && typeof action === 'function') {
            action();
          }
        }}
      />
    );
  };

  const clonedChildren = React.Children.map(children, child => {
    if (child && child.type === Tab) {
      return React.cloneElement(child);
    }
  });

  return (
    <div className={`tabs ${className || ''}`}>
      <div className={`${showDropdown && 'tabs__navigation-wrap'}`}>
        <div className={`tabs__navigation ${navigationClassName}`}>{renderNavigationItems(clonedChildren)}</div>
        {renderDropdown(clonedChildren)}
      </div>
      {tabsHeader && <div className="tabs__header-component">{tabsHeader}</div>}
      <div className="tabs__content">{renderTabContent(clonedChildren)}</div>
    </div>
  );
};

Tabs.contextTypes = {
  t: PropTypes.func.isRequired,
};

Tabs.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.shape({
      type: PropTypes.oneOf([Tab]),
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.oneOf([Tab]),
      })
    ),
  ]).isRequired,
  navigationClassName: PropTypes.string,
  showDropdown: PropTypes.bool,
  formHasUnsavedChanges: PropTypes.bool,
  setFormUnsavedChanges: PropTypes.func,
};

Tabs.defaultProps = {
  formHasUnsavedChanges: false,
  setFormUnsavedChanges: () => null,
};

const mapStateToProps = state => ({
  scrollPosition: state.navReducer.scrollPosition,
  user: state.userReducer,
});

const mapDispatchToProps = dispatch => ({
  clearUnsavedChangesDirty: () => dispatch(clearUnsavedChangesDirty()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tabs);
