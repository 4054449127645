import { filter } from 'lodash';
import actionTypes from '../constants/action-types';

import Helpers from '../../../common/helpers';
import workflowConstants from '../constants/inspection-workflow-constants';

const workflow = {
  workflowImages: null,
  activeImages: null,
  updater: false,
};

export const workflowReducer = (state = workflow, payload) => {
  switch (payload.type) {
    case actionTypes.SET_WORKFLOW_IMAGES:
      return { ...state, workflowImages: payload.data, activeImages: filter(payload.data, { [workflowConstants.formConstants.fields.selected]: true }), updater: !state.updater };
    case actionTypes.ADD_NEW_WORKFLOW_IMAGE:
      return {
        ...state,
        workflowImages: [...state.workflowImages.slice(0, payload.data.index), payload.data.image, ...state.workflowImages.slice(payload.data.index)],
      };
    case actionTypes.SET_WORKFLOW_IMAGE_UPDATED:
      return {
        ...state,
        workflowImages: [
          ...state.workflowImages.slice(0, payload.data.index),
          { ...state.workflowImages[payload.data.index], ...payload.data.image },
          ...state.workflowImages.slice(payload.data.index + 1),
        ],
      };
    case actionTypes.ACTIVATE_WORKFLOW_IMAGE:
      const newImages = Helpers.activateImageById(payload.data, state.workflowImages, workflowConstants.formConstants.fields.selected);
      return {
        ...state,
        workflowImages: newImages,
        activeImages: filter(newImages, { [workflowConstants.formConstants.fields.selected]: true }),
        updater: !state.updater,
      };

    default:
      return state;
  }
};
