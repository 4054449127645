import React from 'react';

import PropTypes from 'prop-types';

import TableComponent from '../../../common/table/components/table-component';
import Button from '../../../common/form/components/button';
import SearchInput from '../../../common/input/components/search-input';

import SimpleLoader from '../../../common/global-loader/components/simple-loader';
import AccessRenderer from '../../../common/access-renderer/components/access-renderer';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../common/permissions-constants';

const PdfTagTable = ({ tableData, tableParams, tableConfig, loadMore, onRowClick, onColumnSort, name, formatCell, supportedMimeTypes, onSearch, onDrop, tableDataLoading }, { t }) => {
  return (
    <div className="section-wrapper">
      <div className="title-section">
        <SearchInput onChange={onSearch} placeholder={t('PROJECT.SEARCH.PLACEHOLDER')} />
      </div>
      <div className={`table-wrapper tw-${name}`}>
        <TableComponent
          tableConfig={tableConfig}
          data={tableData || []}
          onRowClick={onRowClick}
          onColumnSort={onColumnSort}
          sortingObj={tableParams}
          translationModule={t}
          formatCell={formatCell}
          stickyHeader={true}
        />
        {tableDataLoading && <SimpleLoader isLoading={true} className="loader" />}
        {!tableDataLoading && tableData && tableData.length === 0 && (
          <AccessRenderer visibleFor={[PERMISSIONS[PERMISSION_TYPES.smartDrawing].create.name]}>
            {({ hasAccess }) => (
              <div className="empty-state">
                <p className="f-primary">{t('PDF_TAG_LIST.EMPTY_STATE')}</p>
              </div>
            )}
          </AccessRenderer>
        )}
      </div>
      <div className="button">
        <Button disabled={!tableParams.HasNext} text={t('LOAD_MORE')} width="sm" onClick={loadMore} type="button" />
      </div>
    </div>
  );
};

PdfTagTable.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default PdfTagTable;
