import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { take, isEmpty, filter } from 'lodash';

import ExternalUploadForm from '../../inspections/components/right-toolbar/external-upload-form';
import SimpleLoader from '../../../common/global-loader/components/simple-loader';
import Toolbar from '../../../common/toolbar/components/toolbar';

import Helpers from '../../../common/helpers';

import { realCameraTypeItems, imagesCameraPosition } from '../../inspections/constants/constants';

import '../../../app/inspections/styles/external-upload.scss';

class ExternalImagesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      takeItems: props.perPage,
      realCameraType: imagesCameraPosition.uav,
    };
  }

  renderPlaceholder = () => {
    const { t } = this.context;
    return <p>{t('EXTERNAL_UPLOAD.NO_FILES')}</p>;
  };

  handleSubmit = vals => {
    const { externalFiles, saveExternalFiles } = this.props;

    /*eslint array-callback-return:*/
    const files = Object.keys(vals).map(key => {
      if (vals[key]) {
        const index = parseFloat(key.split('_')[1]);
        if (!isNaN(index) && externalFiles[index]) {
          return externalFiles[index];
        }
      }
    });

    saveExternalFiles(files);
  };

  loadMore = () => {
    const { perPage, externalFiles } = this.props;
    const { takeItems } = this.state;

    const newTake = takeItems + perPage;

    this.setState({ takeItems: newTake });

    const items = take(externalFiles, newTake);

    setTimeout(() => {
      Helpers.scrollIntoView('upload-fields', '', 0, `item-${items.length - 1}`);
    }, 300);
  };

  render() {
    const { t } = this.context;
    const { takeItems, realCameraType } = this.state;
    const { externalFiles, imageType, loadWithDrawings, cols, title, includeLoader, className, includePaging, isLoading, refetchData } = this.props;

    return (
      <div className={`external-upload noselect ${className}`}>
        <div className="title">
          <h5>{title ? t(title) : t('INSPECTION_UPLOAD_MODAL_EXTERNAL.TITLE')}</h5>
          <Toolbar
            activeItem={realCameraType}
            toolbarItems={filter(realCameraTypeItems, item => item.name !== imagesCameraPosition.other)}
            handleActiveTab={realCameraType => {
              this.setState({ realCameraType });
              refetchData && refetchData(realCameraType);
            }}
            className="padding-horizontal"
          />
        </div>
        {isLoading ? (
          <SimpleLoader isLoading={true} />
        ) : isEmpty(externalFiles) ? (
          this.renderPlaceholder()
        ) : (
          <ExternalUploadForm
            fields={includePaging ? take(externalFiles, takeItems) : externalFiles}
            loadMore={includePaging && externalFiles.length > 0 && externalFiles.length > takeItems ? this.loadMore : null}
            onSubmit={this.handleSubmit}
            imageType={imageType}
            loadWithDrawings={loadWithDrawings}
            cols={cols}
            includeLoader={includeLoader}
            includePaging={includePaging}
          />
        )}
      </div>
    );
  }
}
ExternalImagesModal.contextTypes = {
  t: PropTypes.func.isRequired,
};
ExternalImagesModal.defaultProps = {
  perPage: 10,
};

const mapStateToProps = (state, props) => {
  return {
    isLoading: state.potreeReducer.pointImagesLoading,
    externalFiles: state.potreeReducer.pointImages,
  };
};

export default connect(mapStateToProps, null)(ExternalImagesModal);
