import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ProfileImg from '../../../common/image-components/components/profile-image';

// import { PUBLIC_URL, LANDING_TYPE } from '../../../common/constants';

import Helpers from '../../../common/helpers';
import routesConstants from '../../../common/routes-constants';

import '../styles/profile-details.scss';

class Profile extends Component {
  render() {
    const { t } = this.context;
    const {
      user: { Name, ProfileImage, Email },
    } = this.props;

    return (
      <div className="profile-details">
        <ProfileImg image={ProfileImage || null} />
        <h4 className="f-primary profile-details__username">{Name}</h4>
        <p className="f-secondary-dark profile-details__location">{Email}</p>
        <div className="profile-details__separator" />
        <p className="f-secondary-green profile-details__link" onClick={() => Helpers.goTo(routesConstants.routes.protectedRoutes.changePassord.fullPath)}>
          {t('PROFILE.CHANGE_PASSWORD')}
        </p>
        <div className="profile-details__separator" />
        <p className="f-secondary-green profile-details__link" onClick={() => Helpers.goTo(routesConstants.routes.protectedRoutes.mfaSetup.fullPath)}>
          {t('PROFILE.AUTHENTICATION_SETTINGS')}
        </p>
      </div>
    );
  }
}

Profile.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Profile;
