import { filter, find, get, values } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import Modal from '../../../common/modal/components/modal';
import ModalMinimizable from '../../../common/modal/components/modal-minimizable';
import CollapsibleToolbar from '../../inspections/components/collapsible-toolbar';
import InspectionWrapper from '../../inspections/components/inspection-wrapper';
import RightToolbar from '../../inspections/components/right-toolbar/right-toolbar';
import ModulePicker from './module-picker';

import { DIMENSIONS } from '../../../common/constants';
import Helpers from '../../../common/helpers';
import { activeItemsData, modulesFullScreen, placements } from '../../inspections/constants/constants';

import { toggleToolbar } from '../../inspections/actions/inspection-actions';

import '../styles/full-screen.scss';

class FullScreen extends Component {
  scrollToElement = (data, generateCustomIdentificator, autoScrollOffset, delay = 500) => {
    setTimeout(() => {
      Helpers.scrollIntoView('items-table-renderer', null, autoScrollOffset, generateCustomIdentificator(data));
    }, delay);
  };

  render() {
    const {
      inspectionReducer: { rightCollapsed, activeDetailsPage },
      toggleToolbar,
      uploadInProgress,
      createWOModalData,
      inspectionModalData,
      pdfPopupLoading,
      router,
      route,
    } = this.props;
    const { query } = this.props.location;
    const selectedType = !query.type || !find(modulesFullScreen, item => item === query.type) ? modulesFullScreen.details : query.type;
    const view = query.view;

    return (
      <div className="full-screen">
        <InspectionWrapper {...this.props} modules={modulesFullScreen}>
          {({
            handleActivePage,
            openDownloadReportModal,
            openDownloadReportModalForMeasurments,
            openAddQuickNDTPointModal,
            openInspectionAssetModal,
            openDocumentsUploadModal,
            openDownloadReportModalSingleDefect,
            setModalState,
            switchTo3DMode,
            handleInspectionClick,
            modalData,
          }) => (
            <Fragment>
              <ModulePicker
                inspectionId={parseInt(query.inspection_id)}
                projectId={parseInt(query.project_id)}
                queryItem={!isNaN(query.selected_item) ? parseInt(query.selected_item) : null}
                handleActivePage={handleActivePage}
                activeItem={selectedType}
                openDownloadReportModal={openDownloadReportModal}
                openDownloadReportModalForMeasurments={openDownloadReportModalForMeasurments}
                openAddQuickNDTPointModal={openAddQuickNDTPointModal}
                getCameraPosition={() => null}
                openInspectionAssetModal={action => openInspectionAssetModal(action)}
                openDocumentsUploadModal={openDocumentsUploadModal}
                setModalState={setModalState}
                scrollToElement={this.scrollToElement}
                handleInspectionClick={handleInspectionClick}
                activeItemsData={filter(activeItemsData, ({ name, hidden }) => values(modulesFullScreen).indexOf(name) > -1 && !hidden)}
                sharedModuleActionsMenu={[
                  {
                    title: 'INSPECTION_FULLSCREEN.SWITCH_TO_3D',
                    action: switchTo3DMode,
                  },
                ]}
                className={rightCollapsed || !activeDetailsPage ? '' : 'module-picker__half-width'}
              />
              <CollapsibleToolbar
                collapsed={rightCollapsed || !activeDetailsPage}
                toggleToolbar={toggleToolbar}
                placement={placements.right}
                resizable={false}
                defaultWidth={DIMENSIONS.inspectionRightToolbar}
              >
                <RightToolbar
                  //routing
                  inspectionId={parseInt(query.inspection_id)}
                  projectId={parseInt(query.project_id)}
                  handleActivePage={handleActivePage}
                  queryItem={!isNaN(query.selected_item) ? parseInt(query.selected_item) : null}
                  //remove reducer saving delay
                  activeDetailsPage={!get(query, 'details') && selectedType !== modulesFullScreen.details ? query.type : get(query, 'details')}
                  //end routing
                  getCameraPosition={() => null}
                  openInspectionAssetModal={action => openInspectionAssetModal(action)}
                  showObjectToolbox={false}
                  openDownloadReportModalSingleDefect={openDownloadReportModalSingleDefect}
                  showGeometryWarning={false}
                  router={router}
                  route={route}
                  view={view}
                />
              </CollapsibleToolbar>
              <Modal {...modalData} modalDisabled={uploadInProgress} />
              <Modal {...createWOModalData} inspectionId={parseInt(query.inspection_id)} projectId={parseInt(query.project_id)} />
              <ModalMinimizable {...inspectionModalData} componentsLoading={pdfPopupLoading} />
            </Fragment>
          )}
        </InspectionWrapper>
      </div>
    );
  }
}

FullScreen.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  inspectionReducer: state.inspectionReducer,
  uploadInProgress: state.uploadReducer.uploadInProgress,
  createWOModalData: state.workOrderReducer.createWOModalData,
  inspectionModalData: state.inspectionReducer.inspectionModalData,
  pdfPopupLoading: state.pdfTagReducer.pdfPopupLoading,
});

const mapDispatchToProps = dispatch => ({
  toggleToolbar: (placement, implicitState) => dispatch(toggleToolbar(placement, implicitState)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FullScreen);
