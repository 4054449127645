import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { isEmpty } from 'lodash';

import { projectActions, actionsMenu } from '../../constants/dropdown-menu';
import SmallDropdown from '../../../../common/small-dropdown/components/small-dropdown';
import Modal from '../../../../common/modal/components/modal';

import UserModal from './user-modal';
import { toggleActivateUserModal, activateUser, deleteUser, toggleUserModal } from '../../actions/users-data-actions';

class TableDropdown extends Component {
  state = {
    modalData: {
      isOpen: false,
    },
  };

  openEditUserModal = (data, username) => {
    const { toggleUserModal } = this.props;
    const { t } = this.context;

    const closeAction = () => {
      const modalData = {
        isOpen: false,
      };
      toggleUserModal(modalData);
    };

    const modalData = {
      isOpen: true,
      title: t('EDIT_USER.TITLE', { user: username }),
      CustomContent: () => <UserModal userId={data.UserID} closeAction={closeAction} />,
      type: 'none',
      closeAction,
    };
    toggleUserModal(modalData);
  };

  openActivateUserModal = () => {
    const { t } = this.context;
    const { data, toggleActivateUserModal } = this.props;
    const modalData = {
      isOpen: true,
      content: t('ACTIVATE_USER_MODAL.DESC', {
        user: data.UserName,
        isActiveText: data.UserActive ? t('ACTIVATE_USER_MODAL.CONTENT_ACTIVATE_USER') : t('ACTIVATE_USER_MODAL.CONTENT_DEACTIVATE_USER'),
      }),
      type: 'yes-no',
      title: t('ACTIVATE_USER_MODAL.ACTIVATE_USER', { isActiveText: data.UserActive ? t('ACTIVATE_USER_MODAL.TITLE_ACTIVATE_USER') : t('ACTIVATE_USER_MODAL.TITLE_DEACTIVATE_USER') }),
      confirmAction: this.activateSelectedUser,
      closeAction: this.closeActivateUserModal,
    };
    toggleActivateUserModal(modalData);
  };

  openDeleteUserModal = (params, username, index) => {
    const { t } = this.context;
    const { deleteUser } = this.props;

    this.setState({
      modalData: {
        isOpen: true,
        content: t('DELETE_USER.DESC', { user: username }),
        type: 'yes-no',
        title: t('DELETE_USER.TITLE', { user: username }),
        confirmAction: () => deleteUser(params, () => this.setState({ modalData: { isOpen: false } }), index),
        closeAction: () => this.setState({ modalData: { isOpen: false } }),
      },
    });
  };

  closeActivateUserModal = () => {
    const { toggleActivateUserModal } = this.props;
    const modalData = {
      isOpen: false,
    };
    toggleActivateUserModal(modalData);
  };

  activateSelectedUser = () => {
    const { data, activateUser, usersTableData } = this.props;
    activateUser(data, usersTableData);
  };
  getClickAction = action => {
    const { data, itemIndex } = this.props;
    if (action === projectActions.editUser) {
      this.openEditUserModal(data, data.UserName);
    } else if (action === projectActions.activateUser || action === projectActions.deactivateUser) {
      this.openActivateUserModal();
    } else if (action === projectActions.deleteUser) {
      this.openDeleteUserModal({ UserID: data.UserID }, data.UserName, itemIndex);
    }
  };
  render() {
    const { data, user } = this.props;
    const { modalData } = this.state;
    const actionToExclude = data.UserActive ? projectActions.activateUser : projectActions.deactivateUser;
    let filteredMenu = [];

    // disable user to do manipulations with his current role
    if (data.UserID !== user.UserID) {
      filteredMenu = actionsMenu.filter(item => item.action !== actionToExclude);
    }
    // disable user activation if user has not set the password yet (if EmailVerified property is null)
    if (!data.EmailVerified) {
      filteredMenu = actionsMenu.filter(item => item.action !== 'activateUser' && item.action !== 'deactivateUser');
    }

    return (
      <Fragment>
        <SmallDropdown className={isEmpty(filteredMenu) ? 'disabled' : ''} actionsMenu={filteredMenu} getClickAction={this.getClickAction} offsetX={-150} />
        {modalData && <Modal {...modalData} />}
      </Fragment>
    );
  }
}
const mapStateToProps = state => ({
  user: state.userReducer,
  usersTableData: state.usersTableReducer.usersTableData,
});

const mapDispatchToProps = dispatch => ({
  toggleActivateUserModal: params => dispatch(toggleActivateUserModal(params)),
  toggleUserModal: params => dispatch(toggleUserModal(params)),
  activateUser: (params, usersTableData) => dispatch(activateUser(params, usersTableData)),
  deleteUser: (params, callback, index) => dispatch(deleteUser(params, callback, index)),
});
TableDropdown.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(TableDropdown);
