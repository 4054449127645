const fields = {
  username: 'email',
  password: 'password',
};

const verificationFields = {
  code: 'Code',
};

const loginSteps = {
  LOGIN: 0,
  EMAIL_VERIFICATION: 1,
  ATUHENTICATOR_VERIFICATION: 2,
};

const verificationTypes = {
  email: 'EMAIL',
  authenticatior: 'AUTHENTICATOR',
  sms: 'SMS',
};

const defaultResendExpirationTime = -1;

export default {
  fields,
  loginSteps,
  verificationTypes,
  defaultResendExpirationTime,
  verificationFields,
};
