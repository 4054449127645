import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { Field, reduxForm } from 'redux-form';

import Helpers from '../../../../common/helpers';

import BasicInput from '../common/input/basic-input';
import Button from '../../../../common/form/components/button';
import DatePicker from '../../../../common/form/components/date-picker';
import FieldUpload from './ndt-measurement-assets';
import AccessRenderer from '../../../../common/access-renderer/components/access-renderer';
import CustomProperties from '../../../../common/custom-property/components/custom-properties';

import { AMAZON_IMAGE_SIZES, FORMS } from '../../../../common/constants';
import { measurementConstants, measurementPointConstants as formConstants, uploadGroups } from '../../constants/ndt-constants';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../common/permissions-constants';

import { validate } from './validators/measurement-point-validator';

import uploadConstants from '../../../upload/constants/constants';
import { preventedFieldsPriorComponentIsCreated } from '../../constants/component-constants';

import '../../styles/component-form.scss';
import '../../styles/ndt-measurement-form.scss';

class NDTPointForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageType: AMAZON_IMAGE_SIZES.small.name,
    };
  }

  formatDate = input => {
    if (!input) return;

    return Helpers.getDateFromUnix(input);
  };

  handleDateChange = (date, fieldName) => {
    const { changeField } = this.props;

    if (!date) return;
    const dateToSave = new Date(date).getTime();
    changeField(FORMS.ndtMeasurementPointForm, fieldName, dateToSave);
  };

  render() {
    const { t } = this.context;
    const { imageType } = this.state;
    const {
      handleSubmit,
      selectedMeasurement,
      measurementPointFiles,
      openUploadModal,
      openFile,
      deleteFile,
      selectedMeasurementPoint,
      handleInputDisabled,
      uploadProgressStyle,
      openDeleteMeasurementModal,
      properties,
      updateNDTMeasurementPointProperty,
      addNDTMeasurementPointProperty,
      deleteNDTMeasurementPointProperty,
      getNDTMeasurementPointPropertyNames,
    } = this.props;
    const images = !isEmpty(measurementPointFiles) ? measurementPointFiles[uploadGroups.imageFiles] || [] : [],
      files = !isEmpty(measurementPointFiles) ? measurementPointFiles[uploadGroups.otherFiles] || [] : [];

    const isDisabled = selectedMeasurementPoint.isTemp;
    const ownerID = selectedMeasurementPoint[formConstants.fields.createdByID];

    const customPorpertiesProps = {
      properties,
      getProperties: () => null,
      updateProperty: property => updateNDTMeasurementPointProperty(property),
      addProperty: data => addNDTMeasurementPointProperty(data),
      restProps: { MeasureID: selectedMeasurementPoint.ID },
      deleteProperty: (data, calllback) => deleteNDTMeasurementPointProperty(data, calllback),
      getSuggestions: getNDTMeasurementPointPropertyNames,
    };
    return (
      <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.ndt].editMeasurementPoint.name} id={ownerID} ownerRequiredPermission={PERMISSIONS[PERMISSION_TYPES.ndt].createMeasurementPoint.name}>
        {({ hasAccess }) => {
          const readOnly = selectedMeasurementPoint.isTemp ? false : !hasAccess;

          return (
            <form className="defect-form component-form ndt-point-form" onSubmit={handleSubmit} noValidate>
              <div className="main-section">
                <div className="details-section">
                  <Field
                    id={formConstants.fields.measuredValue}
                    name={formConstants.fields.measuredValue}
                    component={BasicInput}
                    disabled={readOnly}
                    placeholder={t('NDT_MEASUREMENT_POINT.FORM_MEASURED_VALUE', { unit: selectedMeasurement[measurementConstants.fields.unit] })}
                    label={t('NDT_MEASUREMENT_POINT.FORM_MEASURED_VALUE', { unit: selectedMeasurement[measurementConstants.fields.unit] })}
                    labelClass="f-secondary-dark defect-form__label"
                    type="number"
                  />
                  <Field
                    disabledKeyboardNavigation={true}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    timeFormat="h:mm aa"
                    disabled={readOnly}
                    id={formConstants.fields.measuredDate}
                    name={formConstants.fields.measuredDate}
                    placeholder={t('NDT_MEASUREMENT_POINT.FORM_MEASURED_DATE')}
                    label="NDT_MEASUREMENT_POINT.FORM_MEASURED_DATE"
                    labelClass="f-secondary-dark defect-form__label"
                    component={DatePicker}
                    maxDate={new Date()}
                    size="lg"
                    validateUsingReduxForm
                    isClearable={false}
                    showTimeSelect
                  />
                  <Field
                    id={formConstants.fields.measuredByDevice}
                    name={formConstants.fields.measuredByDevice}
                    component={BasicInput}
                    disabled={readOnly}
                    placeholder={t('NDT_MEASUREMENT_POINT.FORM_MEASURED_DEVICE')}
                    label={t('NDT_MEASUREMENT_POINT.FORM_MEASURED_DEVICE')}
                    labelClass="f-secondary-dark defect-form__label"
                    type="text"
                  />
                  <Field
                    id={formConstants.fields.measuredByUser}
                    name={formConstants.fields.measuredByUser}
                    disabled={true}
                    component={BasicInput}
                    placeholder={t('NDT_MEASUREMENT_POINT.FORM_MEASURED_BY_USER')}
                    label={t('NDT_MEASUREMENT_POINT.FORM_MEASURED_BY_USER')}
                    labelClass="f-secondary-dark defect-form__label"
                    type="text"
                  />

                  <CustomProperties
                    isDisabled={readOnly}
                    formName={FORMS.dynamicNDTMeasurementForm}
                    handleInputDisabled={() => handleInputDisabled(preventedFieldsPriorComponentIsCreated.customFields)}
                    {...customPorpertiesProps}
                  />
                </div>
                <div className="files-section">
                  <FieldUpload
                    label={t('NDT_MEASUREMENT_DETAILS.FORM_IMAGES')}
                    labelClass="f-secondary-dark defect-form__label"
                    noFileText={t('NDT_MEASUREMENT_DETAILS.FORM_NO_IMAGES')}
                    openUploadModal={onLocalUpload => (isDisabled ? handleInputDisabled(preventedFieldsPriorComponentIsCreated.upload) : openUploadModal(onLocalUpload, uploadGroups.imageFiles))}
                    uploadType={uploadConstants.uploadType.ndtMeasurementPointFiles}
                    files={images}
                    disabled={readOnly}
                    restUploadParams={{ MeasureID: selectedMeasurementPoint[formConstants.fields.id], UploadGroup: uploadGroups.images }}
                    imageType={imageType}
                    uploadProgressStyle={uploadProgressStyle}
                    preview="image-thumbnail"
                    actionsMenu={[
                      {
                        title: 'COMPONENT_ASSETS_DROPDOWN.SETTINGS_MENU.ITEM_1',
                        action: data => {
                          openFile(data);
                        },
                      },
                      {
                        title: 'COMPONENT_ASSETS_DROPDOWN.SETTINGS_MENU.ITEM_2',
                        action: data => {
                          Helpers.getFileExtensionAndDownload(data);
                        },
                      },
                      {
                        title: 'COMPONENT_ASSETS_DROPDOWN.SETTINGS_MENU.ITEM_4',
                        action: ({ FileID }) => deleteFile(FileID, selectedMeasurementPoint[formConstants.fields.id]),
                        access: {
                          id: ownerID,
                          ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.ndt].createMeasurementPoint.name,
                          visibleFor: [PERMISSIONS[PERMISSION_TYPES.ndt].editMeasurementPoint.name],
                        },
                      },
                    ]}
                  />
                  <FieldUpload
                    label={t('NDT_MEASUREMENT_DETAILS.FORM_FILES')}
                    labelClass="f-secondary-dark defect-form__label"
                    noFileText={t('NDT_MEASUREMENT_DETAILS.FORM_NO_FILES')}
                    openUploadModal={onLocalUpload => (isDisabled ? handleInputDisabled(preventedFieldsPriorComponentIsCreated.upload) : openUploadModal(onLocalUpload, uploadGroups.otherFiles))}
                    uploadType={uploadConstants.uploadType.ndtMeasurementPointFiles}
                    files={files}
                    disabled={readOnly}
                    restUploadParams={{ MeasureID: selectedMeasurementPoint[formConstants.fields.id], UploadGroup: uploadGroups.files }}
                    imageType={imageType}
                    uploadProgressStyle={uploadProgressStyle}
                    actionsMenu={[
                      {
                        title: 'COMPONENT_ASSETS_DROPDOWN.SETTINGS_MENU.ITEM_1',
                        action: data => {
                          openFile(data);
                        },
                        access: {
                          id: ownerID,
                          ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.ndt].createMeasurementPoint.name,
                          visibleFor: [PERMISSIONS[PERMISSION_TYPES.ndt].editMeasurementPoint.name],
                        },
                      },
                      {
                        title: 'COMPONENT_ASSETS_DROPDOWN.SETTINGS_MENU.ITEM_2',
                        action: data => {
                          Helpers.getFileExtensionAndDownload(data);
                        },
                        access: {
                          id: ownerID,
                          ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.ndt].createMeasurementPoint.name,
                          visibleFor: [PERMISSIONS[PERMISSION_TYPES.ndt].editMeasurementPoint.name],
                        },
                      },
                      {
                        title: 'COMPONENT_ASSETS_DROPDOWN.SETTINGS_MENU.ITEM_4',
                        action: ({ FileID }) => deleteFile(FileID, selectedMeasurementPoint[formConstants.fields.id]),
                        access: {
                          id: ownerID,
                          ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.ndt].createMeasurementPoint.name,
                          visibleFor: [PERMISSIONS[PERMISSION_TYPES.ndt].editMeasurementPoint.name],
                        },
                      },
                    ]}
                  />
                </div>
              </div>
              <div className="buttons">
                <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.ndt].deleteMeasurementPoint.name}>
                  {({ hasAccess }) => {
                    return (
                      <Button
                        disabled={!hasAccess}
                        type="button"
                        variant="gray-outline"
                        width="sm"
                        onClick={openDeleteMeasurementModal}
                        text={t('NDT_MEASUREMENT_POINT.FORM_DELETE_MEASUREMENT_POINT')}
                      />
                    );
                  }}
                </AccessRenderer>

                <Button disabled={readOnly} width="sm" text={t('NDT_MEASUREMENT_POINT.FORM_SAVE_MEASUREMENT_POINT')} />
              </div>
            </form>
          );
        }}
      </AccessRenderer>
    );
  }
}

NDTPointForm.contextTypes = {
  t: PropTypes.func.isRequired,
};
NDTPointForm = reduxForm({
  form: FORMS.ndtMeasurementPointForm,
  validate,
  enableReinitialize: true,
  destroyOnUnmount: false,
})(NDTPointForm);

export default NDTPointForm;
