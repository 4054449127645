import actionTypes from '../constants/action-types';

import ReducerHelpers from '../../../common/reducer-helpers';

const checklistState = {
  checklistList: null,
  checklistTypes: [],
  selectedChecklist: null,
  selectedChecklistItems: [],
};

export const checklistReducer = (state = checklistState, payload) => {
  switch (payload.type) {
    case actionTypes.SET_CHECKLIST_LIST:
      return { ...state, checklistList: payload.data };
    case actionTypes.AMEND_CHECKLIST_LIST:
      return { ...state, checklistList: [...state.checklistList, ...payload.data] };
    case actionTypes.ADD_CHECKLIST_ITEM_AT_INDEX:
      return {
        ...state,
        checklistList: ReducerHelpers.addItemToIndex(state.checklistList, payload.data.item, payload.data.index),
      };
    case actionTypes.UPDATE_CHECKLIST_ITEM_BY_PROP:
      return {
        ...state,
        checklistList: ReducerHelpers.updateItemInListByProp(state.checklistList, payload.data.item, payload.data.prop),
      };
    case actionTypes.REMOVE_CHECKLIST_BY_PROP:
      return {
        ...state,
        checklistList: ReducerHelpers.removeItemByProp(state.checklistList, payload.data.item, payload.data.prop),
      };
    case actionTypes.SET_CHECKLIST_TYPES:
      return { ...state, checklistTypes: payload.data };
    case actionTypes.SET_SELECTED_CHECKLIST:
      return { ...state, selectedChecklist: payload.data };
    case actionTypes.SET_SELECTED_CHECKLIST_ITEMS:
      return { ...state, selectedChecklistItems: payload.data };
    case actionTypes.UPDATE_SELECTED_CHECKLIST:
      return { ...state, selectedChecklist: { ...state.selectedChecklist, ...payload.data } };
    case actionTypes.HANDLE_ADD_CHECKLIST_ITEM_AT_INDEX:
      return {
        ...state,
        selectedChecklistItems: ReducerHelpers.addItemToIndex(state.selectedChecklistItems, payload.data.item, payload.data.index),
      };
    case actionTypes.HANDLE_REMOVE_CHECKLIST_ITEM_BY_PROP:
      return {
        ...state,
        selectedChecklistItems: ReducerHelpers.removeItemByProp(state.selectedChecklistItems, payload.data.item, payload.data.prop),
      };
    case actionTypes.HANDLE_UPDATE_CHECKLIST_ITEM_BY_PROP:
      return {
        ...state,
        selectedChecklistItems: ReducerHelpers.updateItemInListByProp(state.selectedChecklistItems, payload.data.item, payload.data.prop),
      };
    default:
      return state;
  }
};
