import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { isString } from 'lodash';

import { toggleGenericModal } from '../actions/action-creators';
import Modal from './modal';

import '../styles/error-modal.scss';

class GenericModal extends Component {
  render() {
    const { t } = this.context;
    const { content, title, type, customClassName } = this.props;

    const resContent = isString(content) ? content && t(content) : t(content.key, content.props);

    return <Modal {...this.props} content={resContent} title={title ? t(title) : ''} customClassName={`${type || ''} ${customClassName || ''}`} />;
  }
}

GenericModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isOpen: state.errorModalReducer.isOpen,
  title: state.errorModalReducer.title,
  content: state.errorModalReducer.content,
});

const mapDispatchToProps = dispatch => ({ confirmAction: () => dispatch(toggleGenericModal(false)) });

export default connect(mapStateToProps, mapDispatchToProps)(GenericModal);
