import { remove, findIndex } from 'lodash';

import permitTemplateApi from '../../../api/permit-template/actions';
import { fields } from '../components/permits/constants/constants';
import { updatePermitTemplateSections } from './action-creators';
import { mapTemplateSections } from '../helpers/templates-helpers';
import { getSectionAndQuestionDetails } from '../../checklists-procedures-management/helpers/checklists-procedures-management-helpers';
import { additionalFields, questionOptionsFields } from '../../../common/question-components/constants/question-constants';
import { timeToMilliseconds } from '../../../common/section/helpers/section-helpers';
import { permitTemplateSections } from '../components/permits/constants/constants';

export const savePermitTemplate = (values, isEdit, callback) => {
  return async () => {
    try {
      callback(true);
      const res = isEdit ? await permitTemplateApi.updatePermitTemplate(values) : await permitTemplateApi.createPermitTemplate(values);
      const data = res?.data?.Data;

      callback(false, true, data[fields.id]);
    } catch (e) {
      callback(false, false);
    }
  };
};

export const getPermitTemplateDetails = (templateId, callback) => {
  return async () => {
    try {
      callback(true);
      const res = await permitTemplateApi.getPermitTemplateDetails(templateId);
      const data = res?.data?.Data;

      callback(false, data);
    } catch (e) {
      callback(false);
      console.error(e);
    }
  };
};

export const getPermitTemplates = (filters, templates, callback, loadMore = false) => {
  return async () => {
    try {
      callback({ isLoading: true });
      const res = await permitTemplateApi.getPermitTemplates(filters);
      const data = res?.data?.Data;

      if (data?.PermitTemplate) {
        callback({
          isLoading: false,
          templates: loadMore ? [...templates, ...data.PermitTemplate] : data.PermitTemplate,
          filters: { ...filters, HasNext: data.HasNext || false, LastSeen: data.LastSeen, TotalItems: data.TotalItems },
        });
      } else {
        callback({ isLoading: false });
      }
    } catch (e) {
      callback({ isLoading: false });
    }
  };
};

export const deletePermitTemplate = (data, templates, callback) => {
  return async () => {
    try {
      callback(true);
      await permitTemplateApi.deletePermitTemplate(data);

      remove(templates, { [fields.id]: data[fields.permitTemplateId] });
      callback(false, true, templates, true);
    } catch (e) {
      callback({ isLoading: false });
      callback(false);
    }
  };
};

export const getPermitTemplateSections = templateId => {
  return async dispatch => {
    try {
      dispatch(updatePermitTemplateSections([]));
      const res = await permitTemplateApi.getPermitTemplateSections({ TemplateID: templateId });
      let sections = res?.data?.Data ? res.data.Data : [];
      const response = await permitTemplateApi.getPermitTemplateFiles(templateId);
      const files = response?.data?.Data;
      sections = mapTemplateSections(sections, files, permitTemplateSections);

      dispatch(updatePermitTemplateSections(sections));
    } catch (error) {
      console.error(error);
    }
  };
};

export const deletePermitTemplateFile = (templateId, fileId, sectionId, sections, callback) => {
  return async dispatch => {
    try {
      await permitTemplateApi.deletePermitTemplateFile({ PermitTemplateID: templateId, FileID: fileId });

      sections = sections.map(section => {
        if (section.ID === sectionId) {
          remove(section.files, { FileID: fileId });
          section.changed = !section.changed;
        }

        return section;
      });

      dispatch(updatePermitTemplateSections(sections));

      if (callback) {
        callback();
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const getPermitTemplateFiles = (templateId, sections) => {
  return async dispatch => {
    try {
      const res = await permitTemplateApi.getPermitTemplateFiles(templateId);
      const files = res?.data?.Data;
      sections = mapTemplateSections(sections, files, permitTemplateSections);

      dispatch(updatePermitTemplateSections(sections));
    } catch (error) {
      console.error(error);
    }
  };
};

export const addPermitTemplateSection = (templateId, sections) => {
  return async dispatch => {
    try {
      const res = await permitTemplateApi.addPermitTemplateSection({ TemplateID: templateId });
      const section = res?.data?.Data;

      if (section) {
        sections.push(section);
      }

      dispatch(updatePermitTemplateSections(sections));
    } catch (error) {
      console.error(error);
    }
  };
};

export const deletePermitTemplateSection = (sectionId, sections, callback) => {
  return async dispatch => {
    try {
      await permitTemplateApi.deletePermitTemplateSection({ SectionID: sectionId });

      remove(sections, { ID: sectionId });

      dispatch(updatePermitTemplateSections(sections));

      if (callback) {
        callback();
      }
    } catch (e) {
      console.error(e);
    }
  };
};

export const sortPermitTemplateSections = (templateId, currentIndex, newIndex, sections) => {
  return async dispatch => {
    try {
      const currentSection = Object.assign({}, sections[currentIndex]);
      const newSection = Object.assign({}, sections[newIndex]);

      if (currentSection && newSection) {
        const data = {
          TemplateID: templateId,
          SectionID: currentSection.ID,
          NewPosition: newSection.SectionOrder,
        };

        await permitTemplateApi.orderPermitTemplateSections(data);

        const currentOrder = currentSection.SectionOrder;
        currentSection.SectionOrder = newSection.SectionOrder;
        newSection.SectionOrder = currentOrder;

        sections.splice(currentIndex, 1, newSection);
        sections.splice(newIndex, 1, currentSection);
      }

      dispatch(updatePermitTemplateSections(sections));
    } catch (e) {
      console.error(e);
    }
  };
};

export const updatePermitTemplateSection = (data, sections) => {
  return async dispatch => {
    try {
      await permitTemplateApi.updatePermitTemplateSection(data);

      const index = findIndex(sections, { [fields.id]: data[fields.id] });

      if (index > -1) {
        const section = Object.assign({}, sections[index]);
        section[fields.name] = data[fields.name];
        sections[index] = section;

        dispatch(updatePermitTemplateSections(sections));
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const updatePermitTemplateSectionQuestion = (question, sections, field, skipReinitialize) => {
  return async dispatch => {
    try {
      const data = await getSectionAndQuestionDetails(question.ID, sections);

      if (data?.question) {
        if (typeof question[additionalFields.estimatedTime.name] === 'string') {
          question[additionalFields.estimatedTime.name] = timeToMilliseconds(question[additionalFields.estimatedTime.name]);
        }

        const values = Object.assign({}, data.question);
        values[field.name] = question[field.name];
        values.ChecklistItemTypeID = question.QuestionType;

        const res = await permitTemplateApi.updatePermitTemplateSectionQuestion(values);
        const updatedQuestion = res?.data?.Data;

        if (updatedQuestion) {
          sections[data.sectionIndex].SectionQuestions.splice(data.questionIndex, 1, updatedQuestion);

          if (!skipReinitialize) {
            sections[data.sectionIndex].changed = !sections[data.sectionIndex].changed;
          }
        }

        if (updatedQuestion.QuestionOptions && typeof question.pushFieldArrayItem === 'function') {
          question.pushFieldArrayItem(updatedQuestion.QuestionOptions, data.questionIndex);
        }
      }

      dispatch(updatePermitTemplateSections(sections));
    } catch (e) {
      console.error(e);
    }
  };
};

export const updatePermitTemplateSectionQuestionOption = (values, sections) => {
  return async dispatch => {
    try {
      const data = await getSectionAndQuestionDetails(values.SectionQuestionID, sections);

      if (data.question) {
        await permitTemplateApi.updatePermitTemplateSectionQuestionOption(values);

        const optionIndex = findIndex(data.question.QuestionOptions, { ID: values.ID });

        if (optionIndex > -1) {
          data.question.QuestionOptions.splice(optionIndex, 1, values);
          sections[data.sectionIndex].SectionQuestions.splice(data.questionIndex, 1, data.question);
        }

        dispatch(updatePermitTemplateSections(sections));
      }
    } catch (e) {
      console.error(e);
    }
  };
};

export const addPermitTemplateSectionQuestion = (sectionId, sections) => {
  return async dispatch => {
    try {
      const res = await permitTemplateApi.addPermitTemplateSectionQuestion({ SectionId: sectionId });
      const question = res?.data?.Data;

      if (question) {
        const index = findIndex(sections, { ID: sectionId });

        if (index > -1) {
          sections[index].changed = !sections[index].changed;
          if (sections[index].SectionQuestions) {
            sections[index].SectionQuestions.push(question);
          } else {
            sections[index].SectionQuestions = [question];
          }
        }
      }

      dispatch(updatePermitTemplateSections(sections));
    } catch (error) {
      console.error(error);
    }
  };
};

export const deletePermitTemplateSectionQuestion = (sectionQuestionId, sections, index, callback) => {
  return async dispatch => {
    try {
      await permitTemplateApi.deletePermitTemplateSectionQuestion({ SectionQuestionID: sectionQuestionId });

      const data = await getSectionAndQuestionDetails(sectionQuestionId, sections);

      if (data?.question) {
        sections[data.sectionIndex].changed = !sections[data.sectionIndex].changed;
        sections[data.sectionIndex].SectionQuestions.splice(index, 1);

        dispatch(updatePermitTemplateSections(sections));
      }

      if (typeof callback === 'function') {
        callback();
      }
    } catch (e) {
      console.error(e);
    }
  };
};

export const sortPermitTemplateSectionQuestions = (sectionId, currentIndex, newIndex, sections) => {
  return async dispatch => {
    try {
      const index = findIndex(sections, { ID: sectionId });

      if (index > -1) {
        const section = Object.assign({}, sections[index]);
        const currentQuestion = Object.assign({}, section.SectionQuestions[currentIndex]);
        const newQuestion = Object.assign({}, section.SectionQuestions[newIndex]);

        if (currentQuestion && newQuestion) {
          const data = {
            SectionQuestionID: currentQuestion.ID,
            NewPosition: newQuestion.QuestionOrder,
          };

          await permitTemplateApi.orderPermitTemplateSectionQuestions(data);

          const currentOrder = currentQuestion.QuestionOrder;
          currentQuestion.QuestionOrder = newQuestion.QuestionOrder;
          newQuestion.QuestionOrder = currentOrder;

          section.SectionQuestions.splice(currentIndex, 1, newQuestion);
          section.SectionQuestions.splice(newIndex, 1, currentQuestion);
          section.changed = !section.changed;

          sections.splice(index, 1, section);
        }
      }

      dispatch(updatePermitTemplateSections(sections));
    } catch (e) {
      console.error(e);
    }
  };
};

export const addPermitTemplateSectionQuestionOption = (questionId, sections) => {
  return async dispatch => {
    try {
      const data = await getSectionAndQuestionDetails(questionId, sections);

      if (data?.question) {
        const res = await permitTemplateApi.addPermitTemplateSectionQuestionOption({ SectionQuestionID: questionId });
        const option = res?.data?.Data;

        sections[data.sectionIndex].changed = !sections[data.sectionIndex].changed;
        data.question.QuestionOptions.push(option);
        sections[data.sectionIndex].SectionQuestions.splice(data.questionIndex, 1, data.question);
      }

      dispatch(updatePermitTemplateSections(sections));
    } catch (e) {
      console.error(e);
    }
  };
};

export const deletePermitTemplateSectionQuestionOption = (index, option, sections, callback) => {
  return async dispatch => {
    try {
      if (option) {
        const { sectionQuestionId, id } = questionOptionsFields;
        const data = await getSectionAndQuestionDetails(option[sectionQuestionId.name], sections);

        if (data?.question) {
          await permitTemplateApi.deletePermitTemplateSectionQuestionOption({ SectionQuestionID: option[sectionQuestionId.name], SectionQuestionOptionID: option[id.name] });

          sections[data.sectionIndex].changed = !sections[data.sectionIndex].changed;
          data.question.QuestionOptions.splice(index, 1);
          sections[data.sectionIndex].SectionQuestions.splice(data.questionIndex, 1, data.question);
        }
      }

      dispatch(updatePermitTemplateSections(sections));

      if (callback) {
        callback();
      }
    } catch (e) {
      console.error(e);
    }
  };
};

export const permitTemplateChangeStatus = (data, templates, callback) => {
  return async () => {
    try {
      callback(true);

      await permitTemplateApi.permitTemplateChangeStatus(data);

      if (templates) {
        const index = findIndex(templates, { [fields.id]: data[fields.id] });

        if (index > -1) {
          const template = { ...templates[index], [fields.status]: data[fields.status] };
          templates[index] = template;
        }
      }

      callback(false, true, templates);
    } catch (e) {
      callback(false);
    }
  };
};

export const copyPermitTemplate = (templateId, callback) => {
  return async () => {
    try {
      const res = await permitTemplateApi.copyPermitTemplate({ [fields.permitTemplateId]: templateId });
      const template = res?.data?.Data;

      if (template && typeof callback === 'function') {
        callback(template[fields.id]);
      }
    } catch (error) {
      console.error(error);
    }
  };
};
