import React from 'react';
import PropTypes from 'prop-types';
import { values } from 'lodash';

import DisplayStatus from '../../../common/display-status/components/display-status';
import Helpers from '../../../common/helpers';
import { singleKeyFields } from '../constants/constants';
import { statuses } from '../../inspections/components/permits/constants/permit-constants';

const KeyPermitPopover = ({ keyDetails }, { t }) => {
  if (!keyDetails) {
    return null;
  }

  const getIsolationCertificateText = () => {
    if (!keyDetails[singleKeyFields.permitIsolationCertificates] || !keyDetails[singleKeyFields.permitIsolationCertificates].length) {
      return '-';
    }

    if (keyDetails[singleKeyFields.permitIsolationCertificates] && keyDetails[singleKeyFields.permitIsolationCertificates].length === 1) {
      return keyDetails[singleKeyFields.permitIsolationCertificates][0][singleKeyFields.name];
    }

    if (keyDetails[singleKeyFields.permitIsolationCertificates] && keyDetails[singleKeyFields.permitIsolationCertificates].length > 1) {
      return t('ISOLATION_CERTIFICATES.LINKED_PERMITS_PLACEHOLDER', { number: keyDetails[singleKeyFields.permitIsolationCertificates].length });
    }
  };

  return (
    <div className="key-permit-popover">
      <p className="f-primary bold">{keyDetails[singleKeyFields.permitName]}</p>
      <div className="single-detail-item">
        <p className="f-secondary-dark single-detail-item-title">{t('KEYBOX_DETAILS.TEMPLATE_NAME')}</p>
        <div className="single-detail-item-duo">
          {keyDetails[singleKeyFields.permitTemplateColor] && keyDetails[singleKeyFields.permitTemplateName] && (
            <div className="permit-template-color" style={{ backgroundColor: keyDetails[singleKeyFields.permitTemplateColor] }}></div>
          )}
          <p className="f-primary">{keyDetails[singleKeyFields.permitTemplateName] || '-'}</p>
        </div>
      </div>
      <div className="single-detail-item">
        <p className="f-secondary-dark single-detail-item-title">{t('KEYBOX_DETAILS.STATUS')}</p>
        <DisplayStatus statuses={values(statuses)} status={keyDetails[singleKeyFields.permitStatus]} />
      </div>
      <div className="single-detail-item">
        <p className="f-secondary-dark single-detail-item-title">{t('KEYBOX_DETAILS.ISSUED_DATE')}</p>
        <p className="f-primary">{keyDetails[singleKeyFields.permitIssuedDate] ? Helpers.getDateFromUnix(keyDetails[singleKeyFields.permitIssuedDate]) : '-'}</p>
      </div>
      <div className="single-detail-item">
        <p className="f-secondary-dark single-detail-item-title">{t('KEYBOX_DETAILS.ISSUED_TO')}</p>
        <p className="f-primary">{keyDetails[singleKeyFields.permitIssuedTo] || '-'}</p>
      </div>
      <div className="single-detail-item">
        <p className="f-secondary-dark single-detail-item-title">{t('KEYBOX_DETAILS.ISOLATION_CERTIFICATES')}</p>
        <p className="f-primary">{getIsolationCertificateText()}</p>
      </div>
    </div>
  );
};

KeyPermitPopover.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default KeyPermitPopover;
