import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../common/form/components/button';

import '../styles/recovery-keys.scss';

class RecoveryKeys extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: true,
    };
  }

  copyToClipboard = e => {
    const { codes } = this.props;
    navigator.clipboard.writeText(codes);
    this.setState({ disabled: false });
  };

  print = () => {
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');

    mywindow.document.write('<html><head><title>' + document.title + '</title>');
    mywindow.document.write('</head><body >');
    mywindow.document.write('<h1>' + document.title + '</h1>');
    mywindow.document.write(document.getElementsByClassName('recovery-keys')[0].innerHTML);
    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();

    this.setState({ disabled: false });
  };

  render() {
    const { t } = this.context;
    const { disabled } = this.state;
    const { finishClick, codes } = this.props;
    return (
      <Fragment>
        <h3 className="f-primary">{t('AUTHENTICATOR.RECOVERY_KEYS_TITLE')}</h3>
        <p className="f-secondary-dark">{t('AUTHENTICATOR.RECOVERY_KEYS_SUBTITLE')}</p>
        <p className="f-secondary-dark">{t('AUTHENTICATOR.RECOVERY_KEYS_WE_RECOMEND')}</p>
        <div className="recovery-keys">
          {codes.map((code, index) => {
            return (
              <p className="code f-primary" key={index}>
                {code}
              </p>
            );
          })}
        </div>

        <Button type="button" onClick={this.print} variant="gray-outline" text={t('AUTHENTICATOR.PRINT')} />
        <Button type="button" onClick={this.copyToClipboard} variant="gray-outline" text={t('AUTHENTICATOR.COPY')} />
        <div className="separator" />
        <Button type="button" disabled={disabled} onClick={finishClick} text={t('AUTHENTICATOR.FINISH')} />
      </Fragment>
    );
  }
}

RecoveryKeys.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default RecoveryKeys;
