import { textAreaMaxChars } from '../../right-toolbar/readings-and-gauges/constants/measurement-point-constants';
import { formConstants, steps } from '../constants/measurement-point-constants';
import { alarmsValidate } from './alarms-validator';

export const measurementPointValidate = (values, { activeStep }) => {
  let errors = {};

  if (activeStep === steps.firstStep) {
    errors = measurementPointDetailsValidate(values);
  }

  if (activeStep === steps.secondStep) {
    // No need to validate alarm fields on first step
    errors = alarmsValidate(values);
  }

  return errors;
};

export const measurementPointDetailsValidate = values => {
  let errors = {};

  if (!values[formConstants.fields.name]) {
    errors[formConstants.fields.name] = 'MEASUREMENT_POINT_DETAILS.FORM_NAME.ERROR_REQUIRED';
  }

  if (values[formConstants.fields.name] && values[formConstants.fields.name].length < 3) {
    errors[formConstants.fields.name] = 'MEASUREMENT_POINT_DETAILS.FORM_NAME.ERROR_MIN_CHARS';
  }

  if (values[formConstants.fields.name] && values[formConstants.fields.name].length > 50) {
    errors[formConstants.fields.name] = 'MEASUREMENT_POINT_DETAILS.FORM_NAME.ERROR_MAX_CHARS';
  }

  if (values[formConstants.fields.unit] && values[formConstants.fields.unit].length > 10) {
    errors[formConstants.fields.unit] = 'MEASUREMENT_POINT_DETAILS.FORM_UNIT.ERROR_MAX_CHARS';
  }

  if (values[formConstants.fields.expectedRange] && values[formConstants.fields.expectedRange].length > 50) {
    errors[formConstants.fields.expectedRange] = 'MEASUREMENT_POINT_DETAILS.FORM_EXPECTED_RANGE.ERROR_MAX_CHARS';
  }

  if (values[formConstants.fields.description] && values[formConstants.fields.description].length > textAreaMaxChars) {
    errors[formConstants.fields.description] = 'MEASUREMENT_POINT_FORM.ERROR.DESCRIPTION_LENGTH';
  }

  return errors;
};
