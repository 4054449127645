import React from 'react';
import PropTypes from 'prop-types';

// import BasicImage from '../../../../../common/image-components/components/basic-image';
import RenderIf from '../../../../../common/render-if/components/render-if';
import { isolationComponentFields } from '../../right-toolbar/isolation-certificate-details/constants/change-status-constants';
import FilesDisplay from '../isolation-certificate-details/components/details/files-display';

const SectionIsolationComponentDetails = ({ isolationComponent, expandFiles, hideAdditionalDetails }, { t }) => {
  // const getFiles = (files, filesProp) => {
  //   let selectedFiles = Object.assign([], files);

  //   if (selectedFiles && selectedFiles.length) {
  //     if (selectedFiles.length > 3 && !isolationComponent[filesProp]) {
  //       selectedFiles = selectedFiles.slice(0, 3);
  //     }

  //     return (
  //       <div className="screenshots-container">
  //         {selectedFiles.map((item, index) => (
  //           <div className={`screenshot-image ${(index + 1) % 3 === 0 ? '' : 'screenshot-image-with-padding'}`}>
  //             <BasicImage src={item.URL} alt={`question-screenshot-${isolationComponent[isolationComponentFields.componentID]}-${index}`} />
  //             <p className="f-primary bold screenshot-name">{t(item.FileName)}</p>
  //           </div>
  //         ))}
  //         <RenderIf if={files.length > 3}>
  //           <p className="show-more-text f-secondary-green bold" onClick={() => expandFiles(filesProp)}>
  //             {isolationComponent[filesProp] ? t('ISOLATION_CERTIFICATE_STATUS.IMAGES_VIEW_LESS') : t('ISOLATION_CERTIFICATE_STATUS.IMAGES_VIEW_MORE', { number: files.length - 3 })}
  //           </p>
  //         </RenderIf>
  //       </div>
  //     );
  //   } else {
  //     return <p className="f-primary">{'-'}</p>;
  //   }
  // };

  const renderIsolationDetail = (title, value) => (
    <>
      <p className="f-secondary-dark bold label-separator detail-title">{title}</p>
      <p className="f-primary detail-description">{value || '-'}</p>
    </>
  );

  return (
    <div className="isolation-component-details">
      {renderIsolationDetail(t('ISOLATION_CERTIFICATE.ISOLATION_STATE'), isolationComponent[isolationComponentFields.desiredState])}
      {renderIsolationDetail(t('ISOLATION_CERTIFICATE.ISOLATION_METHOD'), isolationComponent[isolationComponentFields.isolationMethod])}
      {renderIsolationDetail(t('ISOLATION_CERTIFICATE.ISOLATION_POSITION'), isolationComponent[isolationComponentFields.normalPosition])}
      {renderIsolationDetail(t('ISOLATION_CERTIFICATE.DE_ISOLATION_ORDER_TITLE'), isolationComponent[isolationComponentFields.deisolationOrder] + '.')}
      <div className="files-items">
        <FilesDisplay
          title={isolationComponent[isolationComponentFields.files] && isolationComponent[isolationComponentFields.files].length > 0 ? 'ISOLATION_CERTIFICATE.FILES' : ''}
          files={isolationComponent[isolationComponentFields.files]}
        />
      </div>
      <RenderIf if={!hideAdditionalDetails}>{/* TODO: render if more details needed or more than 3 Files per isolation component */}</RenderIf>
    </div>
  );
};

SectionIsolationComponentDetails.contextTypes = {
  t: PropTypes.func.isRequired,
};

SectionIsolationComponentDetails.propTypes = {
  isolationComponent: PropTypes.shape({
    ComponentID: PropTypes.number,
    Name: PropTypes.string.isRequired,
    Colour: PropTypes.string,
    ComponentCode: PropTypes.string,
    ComponentType: PropTypes.string,
    ComponentSubType: PropTypes.string,
    DesiredState: PropTypes.string,
    NormalPosition: PropTypes.string,
    IsolationMethod: PropTypes.string,
    DeisolationOrder: PropTypes.number,
    IsolationOrder: PropTypes.number,
    Files: PropTypes.array,
  }),
  expandFiles: PropTypes.func.isRequired,
  hideAdditionalDetails: PropTypes.bool,
};

export default SectionIsolationComponentDetails;
