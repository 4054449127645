import actionTypes from '../constants/action-types';

const dmsLabelsState = {
  dmsLabels: [],
  dmsLabelsLoading: false,
  selectedDMSFileLabels: [],
};

export const dmsLabelsReducer = (state = dmsLabelsState, payload) => {
  switch (payload.type) {
    case actionTypes.SET_DMS_LABELS:
      return {
        ...state,
        dmsLabels: payload.data,
      };
    case actionTypes.SET_DMS_LABELS_LOADING:
      return {
        ...state,
        dmsLabelsLoading: payload.data,
      };
    case actionTypes.SET_SELECTED_DMS_FILE_LABELS:
      return {
        ...state,
        selectedDMSFileLabels: payload.data,
      };
    default:
      return state;
  }
};
