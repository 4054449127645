import React from 'react';
import PropTypes from 'prop-types';

import { find } from 'lodash';

import RenderIf from '../../render-if/components/render-if';
import Icon from '../../icon/components/icon';

import { observationTools } from '../../../app/inspections/constants/defect-constants';

const HistoryItemDefectType = ({ from, to }) => {
  const getIcon = action => {
    const obj = find(observationTools, { action });

    return obj && <obj.icon className="history-item-defect-type-display__icon" />;
  };

  return (
    <div className="history-item-defect-type-display">
      <RenderIf if={from}>
        <div className="history-item-defect-type-display__item">
          {getIcon(from)}
          {from}
        </div>
      </RenderIf>
      <Icon name="arrow-right" size="sm" className="history-item-display__icon" />

      <RenderIf if={to}>
        <div className="history-item-defect-type-display__item">
          {getIcon(to)}
          {to}
        </div>
      </RenderIf>
    </div>
  );
};

HistoryItemDefectType.propTypes = {
  from: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  to: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default HistoryItemDefectType;
