import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Helpers from '../../../common/helpers';
import routesConstants, { routes } from '../../../common/routes-constants';
import { params } from '../../profile/constants/profile-constants';
import RegimeTable from './maintenance-regime/regime-table';

import '../styles/maintenance-regime.scss';

const MaintenanceRegime = ({ location, router, user }) => {
  useEffect(() => {
    const { query } = location;

    const projectId = parseInt(query.project_id);
    const inspectionId = parseInt(query.inspection_id);

    if (!projectId || !inspectionId) {
      Helpers.goTo(routesConstants.routes.protectedRoutes.project.fullPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { query } = location;
  const { project_id: projectId, inspection_id: inspectionId } = query;
  const backButtonPath = user[params.fullScreenEnabled] ? routes.protectedRoutes.fullScreen.fullPath : routes.protectedRoutes.inspections.fullPath;

  return (
    <>
      <div className="maintenance-regime">
        <RegimeTable
          projectId={parseInt(projectId, 10)}
          inspectionId={parseInt(inspectionId, 10)}
          selected_item={!isNaN(query.selected_item) ? parseInt(query.selected_item) : null}
          previewMode={query.preview === 'true'}
          backButtonPath={`${backButtonPath}?project_id=${projectId}&inspection_id=${inspectionId}`} // redirect to the main screen, no need for additional query params
          location={location}
        />
      </div>
    </>
  );
};

MaintenanceRegime.contextTypes = {
  t: PropTypes.func.isRequired,
};

MaintenanceRegime.propTypes = {
  location: PropTypes.object,
  router: PropTypes.object,
  user: PropTypes.object,
};

const mapStateToProps = state => ({
  user: state.userReducer,
});

export default connect(mapStateToProps, null)(MaintenanceRegime);
