import PropTypes from 'prop-types';
import React from 'react';
import Tab from '../../tabs/component/tab';
import Tabs from '../../tabs/component/tabs';
import '../styles/work-tab.scss';

const WorkTabs = ({ tabs = [], selectedComponent = {}, inspectionId, queryItem }, { t }) => {
  return (
    <React.Fragment>
      <Tabs defaultTabKey={tabs[0].tabKey} navigationClassName="work-tab__tabs" showDropdown>
        {tabs.map(tab => {
          return (
            <Tab title={t(tab.title)} tabKey={tab.tabKey} key={tab.tabKey} visible={tab.visible}>
              <tab.Children selectedComponent={selectedComponent} inspectionId={inspectionId} queryItem={queryItem} />
            </Tab>
          );
        })}
      </Tabs>
    </React.Fragment>
  );
};

WorkTabs.contextTypes = {
  t: PropTypes.func.isRequired,
};

WorkTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      tabKey: PropTypes.string.isRequired,
      Children: PropTypes.func.isRequired,
    })
  ),
  inspectionId: PropTypes.number.isRequired,
};

export default WorkTabs;
