import { WOFileGroups } from './constants';
import { statuses } from '../../../constants/work-order-constants';

import WorkOrderSectionFiles from '../work-order-section-files';
import WorkOrderSectionPermits from '../work-order-section-permits';
import WorkOrderSectionComponents from '../work-order-section-components';
import WorkOrderSectionAssignees from '../work-order-section-assignees';
import ModuleSectionWorkAreas from '../module-section-work-areas';
import SectionEmptyState from '../../right-toolbar/common/section-empty-state';
import WorkOrderSectionContractors from '../work-order-section-contractors';
import WorkOrderSectionCPTemplates from '../work-order-section-cp-templates';
import WorkOrderSectionObservations from '../work-order-section-observations';
import { FEATURES } from '../../../../../common/constants';
import WorkOrderSectionChecklists from '../work-order-section-checklists';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../../common/permissions-constants';

export const sections = {
  Files: {
    ID: 1,
    title: 'WORK_ORDER.FILES_SECTION',
    component: WorkOrderSectionFiles,
    translatableTitle: true,
    Locked: true,
    sectionAction: 'openEditFilesModal',
    fileGroup: WOFileGroups.otherFiles,
    disabledForStatuses: [statuses.closed.value, statuses.rejected.value, statuses.completed.value],
    editPermission: PERMISSIONS[PERMISSION_TYPES.workOrders].chooseFromDMS.name,
  },
  Components: {
    ID: 2,
    title: 'WORK_ORDER.COMPONENTS_SECTION',
    component: WorkOrderSectionComponents,
    translatableTitle: true,
    Locked: true,
    sectionAction: 'openComponentsModal',
    disabledForStatuses: [statuses.authorised.value, statuses.inProgress.value, statuses.closed.value, statuses.completed.value, statuses.rejected.value],
    showError: false,
    // listWithDeeplink flag toggles if the UI format with the deeplinks (card-style) should be displayed
    listWithDeeplink: true,
  },
  Observations: {
    ID: 3,
    title: 'WORK_ORDER.OBSERVATIONS_SECTION',
    component: WorkOrderSectionObservations,
    translatableTitle: true,
    Locked: true,
    sectionAction: 'openObservationsModal',
    customAction: 'createObservation',
    showError: false,
    editPermission: PERMISSIONS[PERMISSION_TYPES.workOrders].workOrdersObservationLink.name,
    ignoreStatusEditPermission: true,
  },
  Contractors: {
    ID: 4,
    title: 'WORK_ORDER.CONTRACTORS_SECTION',
    component: WorkOrderSectionContractors,
    translatableTitle: true,
    Locked: true,
    sectionAction: 'openContractorsModal',
    disabledForStatuses: [statuses.authorised.value, statuses.inProgress.value, statuses.closed.value, statuses.completed.value, statuses.rejected.value],
    showError: false,
    isHidden: !FEATURES.contractors.visible,
  },
  Assignees: {
    ID: 5,
    title: 'WORK_ORDER.ASSIGNEES_SECTION',
    component: WorkOrderSectionAssignees,
    translatableTitle: true,
    Locked: true,
    sectionAction: 'openAssigneesModal',
    disabledForStatuses: [statuses.authorised.value, statuses.inProgress.value, statuses.closed.value, statuses.completed.value, statuses.rejected.value],
    showError: false,
  },
  Alerts: {
    ID: 6,
    title: 'WORK_ORDER.ALERTS_SECTION',
    component: SectionEmptyState,
    translatableTitle: true,
    Locked: true,
    disabledForStatuses: [statuses.authorised.value, statuses.inProgress.value, statuses.closed.value, statuses.completed.value, statuses.rejected.value],
    sectionAction: 'openAlertsModal',
    isHidden: true,
    showError: false,
  },
  Permits: {
    ID: 7,
    title: 'WORK_ORDER.PERMITS_SECTION',
    component: WorkOrderSectionPermits,
    translatableTitle: true,
    Locked: true,
    sectionAction: 'openPermitsModal',
    disabledForStatuses: [statuses.authorised.value, statuses.inProgress.value, statuses.closed.value, statuses.completed.value, statuses.rejected.value],
    showError: false,
    isHidden: !FEATURES.permits.visible,
  },
  WorkAreas: {
    ID: 8,
    title: 'WORK_ORDER.WORK_AREAS_SECTION',
    component: ModuleSectionWorkAreas,
    translatableTitle: true,
    Locked: true,
    sectionAction: 'handleOpenWorkAreaModal',
    disabledForStatuses: [statuses.authorised.value, statuses.inProgress.value, statuses.closed.value, statuses.completed.value, statuses.rejected.value],
    showError: false,
  },
  CPTemplates: {
    ID: 9,
    title: 'WORK_ORDER.CP_TEMPLATES_SECTION',
    component: WorkOrderSectionCPTemplates,
    translatableTitle: true,
    Locked: true,
    sectionAction: 'openCPTemplatesModal',
    disabledForStatuses: [statuses.authorised.value, statuses.inProgress.value, statuses.closed.value, statuses.completed.value, statuses.rejected.value],
    showError: false,
    isHidden: !FEATURES.checklistsAndProcedures.visible,
    editPermission: PERMISSIONS[PERMISSION_TYPES.workOrders].workOrdersCPLink.name,
    ignoreStatusEditPermission: true,
  },
  Checklists: {
    ID: 10,
    title: 'WORK_ORDER.CHECKLISTS_SECTION',
    component: WorkOrderSectionChecklists,
    translatableTitle: true,
    Locked: true,
    sectionAction: '',
    disabledForStatuses: [statuses.draft.value, statuses.raised.value, statuses.approved.value, statuses.authorised.value, statuses.rejected.value],
    showError: false,
    isHidden: !FEATURES.checklistsAndProcedures.visible,
    editPermission: PERMISSIONS[PERMISSION_TYPES.workOrders].fillInCP.name,
    ignoreStatusEditPermission: true,
  },
  Source: {
    ID: 11,
    title: 'WORK_ORDER.SOURCE_SECTION',
    component: SectionEmptyState,
    translatableTitle: true,
    Locked: true,
    isHidden: true,
    showError: false,
  },
};
