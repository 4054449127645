import constants from '../constants/download-report-constants';

export const validate = (values, { skipStep }) => {
  const errors = {};

  if (!skipStep) {
    if (!values[constants.fields.components] || values[constants.fields.components].length === 0) {
      errors[constants.fields.components] = 'DOWLOAD_REPORT.PLEASE_SELECT_ONE_OPTION';
    }
    // if (!values[constants.fields.alarmsTriggered] || values[constants.fields.alarmsTriggered].length === 0) {
    //   errors[constants.fields.alarmsTriggered] = 'DOWLOAD_REPORT.PLEASE_SELECT_ONE_OPTION';
    // }
    if (!values[constants.fields.measurmentUnits] || values[constants.fields.measurmentUnits].length === 0) {
      errors[constants.fields.measurmentUnits] = 'DOWLOAD_REPORT.PLEASE_SELECT_ONE_OPTION';
    }
  }
  const dateFrom = new Date(values[constants.fields.dateFrom]).getTime();
  const dateTo = new Date(values[constants.fields.dateTo]).getTime();

  if (dateFrom && dateTo && dateFrom > dateTo) {
    errors[constants.fields.dateTo] = { string: 'DOWLOAD_REPORT.DATE_TO_MUST_BE_GRATER' };
  }

  return errors;
};
