export default {
  LOGIN: '/account/login',
  UPDATE_USER_SETTINGS: '/account/settings/update',
  UPDATE_USER_360_SETTINGS: '/account/settings/360/update',
  TOKEN_LOGIN: '/share_link/login',
  REGISTER_COMPANY: '/company/register',
  GET_UNIT_DATA: '/account/settings',
  AUTHENTICATE: '/account/authenticate',
  CHANGE_PROFILE: '/account/roles/switch',
  VERIFY_USER_EMAIL: '/account/mfa/code/method/email',
  RESEND_EMAIL: '/account/mfa/method/email/resend',
  VERIFY_USER_AUTHENTICATOR: '/account/mfa/code/method/authenticator',
};
