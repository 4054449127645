import React from 'react';
import PropTypes from 'prop-types';

const TimePicker = (
  { input, meta, hoursPlaceholder, minutesPlaceholder, className, id, label, labelClass, disabled, isHidden, autoFocus, size, withHiddenError, onlyDisabled, showPlaceholderWhileValue, isRequired },
  { t }
) => {
  const hoursName = 'hours';
  const minutesName = 'minutes';

  const timeToSeconds = (hours, minutes) => {
    return hours * 60 * 60 + minutes * 60;
  };

  const secondsToTime = value => {
    if (!value) {
      return { [hoursName]: '', [minutesName]: '' };
    }

    let seconds = value;
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    // 👇️ if seconds are greater than 30, round minutes up (optional)
    minutes = seconds >= 30 ? minutes + 1 : minutes;

    minutes = minutes % 60;

    return { [hoursName]: hours ? (hours < 10 ? `0${hours}` : hours) : '', [minutesName]: minutes ? (minutes < 10 ? `0${minutes}` : minutes) : '' };
  };

  const getValue = isHours => {
    const value = input?.value;
    const time = secondsToTime(value);

    return isHours ? time.hours || '' : time.minutes || '';
  };

  const handleTimeChange = (e, isHours) => {
    const value = input?.value;
    const timeValue = parseInt(e.target.value || 0);

    if ((isHours && timeValue > 99) || (!isHours && timeValue > 59)) {
      return;
    }

    const time = secondsToTime(value);
    const hoursValue = isHours ? parseInt(e.target.value || 0) : time.hours;
    const minutesValue = isHours ? time.minutes : parseInt(e.target.value || 0);

    const milliseconds = timeToSeconds(hoursValue, minutesValue);

    if (input?.onChange) {
      input.onChange(milliseconds, input?.name);
    }
  };

  return (
    <div className={`field-wrapper${disabled ? ' field-wrapper--disabled' : ''}${onlyDisabled ? ' field-wrapper--only-disabled' : ''} ${isHidden ? 'hidden-field' : ''}`}>
      {label && (
        <label className={`field-wrapper__label ${labelClass}`} htmlFor={id}>
          {`${t(label)}${isRequired ? '*' : ''}`}
        </label>
      )}
      <div className="time-picker-wrapper">
        <div className="time-picker-hours">
          <input
            id={id}
            disabled={disabled}
            readOnly={disabled}
            name={hoursName}
            value={getValue(true)}
            onChange={e => handleTimeChange(e, true)}
            type="text"
            autoCorrect="off"
            spellCheck={false}
            placeholder={t(hoursPlaceholder || 'CREATE_WORKORDER_FORM.HOURS_PLACEHOLDER')}
            className={(meta?.error && meta.touched ? 'invalid ' : '') + `${className || 'input-wrapper__input'} ${size}`}
            autoFocus={autoFocus}
          />
          {showPlaceholderWhileValue && getValue(true) && <p className="f-primary fake-placeholder">{t(hoursPlaceholder || 'CREATE_WORKORDER_FORM.HOURS_PLACEHOLDER')}</p>}
        </div>
        <div className="time-picker-minutes">
          <input
            id={id}
            disabled={disabled}
            readOnly={disabled}
            name={minutesName}
            value={getValue()}
            onChange={e => handleTimeChange(e)}
            type="text"
            autoCorrect="off"
            spellCheck={false}
            placeholder={t(minutesPlaceholder || 'CREATE_WORKORDER_FORM.MINUTES_PLACEHOLDER')}
            className={(meta?.error && meta.touched ? 'invalid ' : '') + `${className || 'input-wrapper__input'} ${size}`}
            autoFocus={autoFocus}
          />
          {showPlaceholderWhileValue && getValue() && <p className="f-primary fake-placeholder">{t(minutesPlaceholder || 'CREATE_WORKORDER_FORM.MINUTES_PLACEHOLDER')}</p>}
        </div>
      </div>
      <div className={`error-message${meta?.error && meta.touched ? '--visible' : ''} ${withHiddenError ? 'with-hidden-error-message' : ''}`}>{meta?.error && t(meta.error)}</div>
    </div>
  );
};

TimePicker.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default TimePicker;
