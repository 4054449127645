import { setWorkflowFimageUpdated, addWorkflowFimageAtIndex } from './action-creators';

import { uploadAsset } from '../../upload/actions/upload-actions';

import Helpers from '../../../common/helpers';

import workflowConstants from '../constants/inspection-workflow-constants';

import uploadApi from '../../../api/upload/actions';
import { store } from '../../../index';

export const uploadWorkflowAsset = (file, Ids, index, customObj) => {
  return async dispatch => {
    dispatch(addWorkflowFimageAtIndex({ index, image: customObj }));
    dispatch(uploadAsset(file, Ids, setSingleWorkflowUploadAssetWired, index, setSingleWorkflowUploadFileDoneWired));
  };
};

const setSingleWorkflowUploadAssetWired = (progress, index) => {
  store.dispatch(setSingleWorkflowUploadAsset(progress, index));
};

const setSingleWorkflowUploadAsset = (progress, index) => {
  return async dispatch => {
    dispatch(
      setWorkflowFimageUpdated({
        index,
        image: {
          progress,
        },
      })
    );
  };
};

export const setSingleWorkflowUploadFileDoneWired = (index, url) => {
  store.dispatch(setSingleWorkflowUploadFileDone(index, url));
};

const setSingleWorkflowUploadFileDone = (index, url) => {
  return async dispatch => {
    const res = await uploadApi.setUploadDone({ Key: Helpers.getKeyFromUrl(url), WorkflowData: true });
    const { Data } = res.data;

    const file = Data;
    const image = {
      ...file,
      [workflowConstants.formConstants.fields.id]: file.FileID,
      actionsMenu: { ...Helpers.mapInfoIconDisplayProps(file, workflowConstants.displayProps) },
    };
    //save data
    dispatch(setWorkflowFimageUpdated({ index, image }));

    //save src when image is loaded due to aws delay
    const interval = setInterval(() => {
      let imgObj = new Image();
      imgObj.src = file.URL;
      imgObj.onload = () => {
        dispatch(setWorkflowFimageUpdated({ index, image: { src: file.URL, [workflowConstants.formConstants.fields.url]: file.URL } }));
        clearInterval(interval);
        imgObj = null;
      };
    }, 1500);
  };
};
