export default {
  GET_CONTRACTORS_LIST: '/project/contractors/list',
  GET_CONTRACTOR_DETAILS: '/project/contractor/details',
  CREATE_CONTRACTOR: '/project/contractor/create',
  UPDATE_CONTRACTOR: '/project/contractor/update',
  UPDATE_CONTRACTOR_STATUS: '/project/contractor/status/update',
  DELETE_CONTRACTOR: '/project/contractor/delete',
  GET_CONTRACTOR_FILES: '/project/contractor/files/list',
  UNLINK_CONTRACTOR_FILE: '/project/contractor/file/unlink',
  GET_USERS: '/project/contractor/users/list',
  RESTORE_CONTRACTOR: '/project/contractor/restore',
};
