import React from 'react';
import PropTypes from 'prop-types';
import { values, find } from 'lodash';

import SectionEmptyState from '../../../common/section-empty-state';
import { permitsField, fields } from '../../constants/permits-constants';
import { statuses } from '../../../../permits/constants/permit-constants';
import Icon from '../../../../../../../common/icon/components/icon';

import '../../../../../styles/isolation-certificate-permits.scss';

const PermitsDisplay = ({ module }, { t }) => {
  const permits = module?.[permitsField];

  const showEmptyState = () => {
    if (!permits || !permits.length) {
      return true;
    }

    if (!permits[0][fields.id]) {
      return true;
    }

    return false;
  };

  const displayStatus = permitStatus => {
    const permitStatuses = values(statuses);
    const status = find(permitStatuses, { value: permitStatus });

    if (status) {
      return (
        <div className="permit-status-wrapper">
          <Icon className="permit-status-icon" name={status.icon} />
          <p className="f-primary bold">{t(status.title)}</p>
        </div>
      );
    }

    return null;
  };

  if (showEmptyState()) {
    return <SectionEmptyState title="ISOLATION_CERTIFICATE.PERMITS_EMPTY_STATE" />;
  }

  return (
    <div className="permits-display-container">
      {permits &&
        permits.length > 0 &&
        permits.map(permit => (
          <div className="permit-item" key={permit[fields.id]}>
            <p className="f-primary bold">{permit[fields.name]}</p>
            {displayStatus(permit[fields.status])}
          </div>
        ))}
    </div>
  );
};

PermitsDisplay.contextTypes = {
  t: PropTypes.func.isRequired,
};

PermitsDisplay.propTypes = {
  module: PropTypes.shape({
    Permits: PropTypes.arrayOf(
      PropTypes.shape({
        ID: PropTypes.number,
        Name: PropTypes.string,
        Status: PropTypes.string,
      })
    ),
  }),
};

export default PermitsDisplay;
