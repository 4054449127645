import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { get } from 'lodash';

import PageNavigation from '../../page-navigation/components/page-navigation';
import RenderIf from '../../render-if/components/render-if';
import Helpers from '../../helpers';
import routesConstants, { routes } from '../../routes-constants';

import '../styles/header-title.scss';
import { params } from '../../../app/profile/constants/profile-constants';

const HeaderTitle = ({ text, icon, reducedHeader, className, user }) => {
  useEffect(() => {
    const checkLocation = () => {
      if (browserHistory.getCurrentLocation) {
        const location = browserHistory.getCurrentLocation() || { search: '' };

        const query = location.query;
        const inspectionId = get(query, 'inspection_id');
        const projectId = get(query, 'project_id');

        if (!inspectionId || !projectId) {
          Helpers.goTo(routes.protectedRoutes.project.fullPath);
        }
      }
    };

    checkLocation();
  }, []);

  let search = '';
  if (browserHistory.getCurrentLocation) {
    const location = browserHistory.getCurrentLocation() || { search: '' };
    search = location.search;
  }
  const backButtonPath = `${user[params.fullScreenEnabled] ? routesConstants.routes.protectedRoutes.fullScreen.fullPath : routesConstants.routes.protectedRoutes.inspections.fullPath}${search}`;

  return (
    <div className={`title-container ${text ? '' : 'no-text'} ${reducedHeader ? 'reduced-header' : ''} ${className || ''}`}>
      <RenderIf if={text}>
        <PageNavigation backButtonPath={backButtonPath} title={text} icon={icon} withBottomBorder />
      </RenderIf>
    </div>
  );
};

HeaderTitle.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  reducedHeader: PropTypes.bool,
  className: PropTypes.string,
  user: PropTypes.object.isRequired,
};

HeaderTitle.defaultProps = {
  reducedHeader: false,
};

const mapStateToProps = state => {
  return {
    inspectionDetails: state.inspectionReducer.inspectionDetails,
    user: state.userReducer,
  };
};

export default connect(mapStateToProps, null)(HeaderTitle);
