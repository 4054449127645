import React, { useEffect, useState } from 'react';
import { arraySplice, Field } from 'redux-form';
import SearchSelect from '../../../app/inspections/components/common/input/search-select';
import ButtonWithIcon from '../../button-with-icon/button-with-icon';
import CustomInput from '../../form/components/input';
import Icon from '../../icon/components/icon';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fields as fieldConstants } from '../constants/constants';

import '../styles/advanced-filter-custom-property-field.scss';
import { initialCustomPropFilter } from '../constants/constants';
import { filterFields, formConstants } from '../../../app/inspections/constants/work-order-constants';
import RenderIf from '../../render-if/components/render-if';

const AdvancedFilterCustomPropertyField = (props, { t }) => {
  const { fields, fetchCustomProps, form, arraySplice, formValues } = props;
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    fetchSuggestions('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSuggestions = searchText => {
    fetchCustomProps(searchText, suggestions => {
      setSuggestions(suggestions.map(suggestion => ({ label: suggestion })));
    });
  };

  const removeField = (field, index) => {
    // Here fields.remove should have been used but since we use array of object the problem was not solvable
    // For more info you can check out https://github.com/redux-form/redux-form/issues/2666
    // Slice is used here since we need to get the field name and here we get fieldName[i] so -3 is for [i]
    arraySplice(form, field.slice(0, -3), index);
  };

  const isCustomPropertyPresent = formValues?.[filterFields.properties]?.length > 0;
  return (
    <>
      {fields.map((field, index) => {
        const isFieldNameEmpty = !formValues[filterFields.properties]?.[index]?.[formConstants.fields.name];

        return (
          <div>
            <div className="advanced-filter-custom-property-field">
              <div className="advanced-filter-custom-property-field__header">
                <p className="f-secondary-dark">{`${t('FILTER_GROUP')} ${index + 1}`} </p>
                <Icon name="close" handleFocus={false} onClick={() => removeField(field, index)} />
              </div>
              <div className="advanced-filter-custom-property-field__fields-container">
                <div className="advanced-filter-custom-property-field__fields-container__field">
                  <Field
                    name={`${field}.${fieldConstants.name}`}
                    component={SearchSelect}
                    suggestions={suggestions || []}
                    fetchSuggestions={fetchSuggestions}
                    clearSuggestions={() => []}
                    placeholder={t('CUSTOM_PROPERTY')}
                    label={t('CUSTOM_PROPERTY')}
                    isEnhancedDesign
                    labelClass="f-primary"
                    hasIcon
                    iconName="chevron"
                    iconRotation={90}
                    key={`${index}_${fields.length}_Name`}
                    shouldRenderSuggestionsOnInitialFocus
                  />
                </div>

                <div className="advanced-filter-custom-property-field__fields-container__field">
                  <Field
                    name={`${field}.${fieldConstants.value}`}
                    component={CustomInput}
                    size="lg"
                    label={t('KEYWORD')}
                    labelTooltip
                    labelTooltipText={t('ADVANCED_CUSTOM_PROPS_SEARCH_TOOLTIP')}
                    placeholder={t('KEYWORD')}
                    hasIcon
                    iconName="search"
                    key={`${index}_${fields.length}_Value`}
                    disabled={!isCustomPropertyPresent || (isCustomPropertyPresent && isFieldNameEmpty)}
                  />
                </div>
              </div>
            </div>
            <RenderIf if={index !== fields.length - 1}>
              <div className="or-container">
                <p className="or-container__text">{t('OR')}</p>
              </div>
            </RenderIf>
          </div>
        );
      })}
      <ButtonWithIcon
        icon="plus-circle"
        text={t(fields.length === 0 ? 'ADVANCED_CUSTOM_PROPS_SEARCH_ADD_GROUP_EMPTY' : 'ADVANCED_CUSTOM_PROPS_SEARCH_ADD_GROUP')}
        noBorder
        type="success"
        bold
        customClassName="mt-20"
        onClick={() => {
          fields.push(initialCustomPropFilter);
        }}
      />
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  arraySplice: (form, field, index) => dispatch(arraySplice(form, field, index, 1)),
});

AdvancedFilterCustomPropertyField.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(AdvancedFilterCustomPropertyField);
