import React from 'react';
import PropTypes from 'prop-types';

import '../styles/multiple-preview-popup.scss';

const MultiplePreviewPopup = ({ title, subTitle, list = [] }, { t }) => (
  <div className="multiple-preview-popup">
    <p className="f-primary title bold">{title}</p>
    {subTitle && <p className="f-secondary-dark sub-title">{subTitle}</p>}
    {list && list.length > 0 && list.map(item => <p className="f-primary item">{item.Name}</p>)}
  </div>
);

MultiplePreviewPopup.contextTypes = {
  t: PropTypes.func.isRequired,
};

MultiplePreviewPopup.propTypes = {
  title: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number,
      Name: PropTypes.string,
      Status: PropTypes.string,
    })
  ),
};

export default MultiplePreviewPopup;
