import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helpers from '../../../../common/helpers';

class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newComment: '',
    };
  }

  handleChangeInput = e => {
    this.setState({
      newComment: e.target.value,
    });
  };

  addComment = () => {
    const { addComment, selectedDefect, user } = this.props;
    const { newComment } = this.state;
    if (Helpers.isGuestUser(user)) return;
    if (!newComment || (newComment && newComment.trim() === '')) return;
    if (selectedDefect) addComment({ Message: newComment, defect: selectedDefect });
    this.setState({ newComment: '' });
  };

  renderAuthorField = ({ value, className }) => <p className={'f-secondary-green ' + className}>{value}</p>;
  renderCommentField = ({ value, className }) => <p className={'f-primary ' + className}>{value}</p>;

  render() {
    const { t } = this.context;
    const { comments, placeholder, label, labelClass, id, user, disabled } = this.props;

    return (
      <div className="comments-container">
        {label && (
          <label className={`inspection-input__label ${labelClass || 'f-secondary-dark'}`} htmlFor={id}>
            {label}
          </label>
        )}
        {!disabled && (
          <div className={`add-comment${Helpers.isGuestUser(user) ? ' hidden' : ''}`}>
            <div className="horizontal-padding">
              <textarea
                onKeyUp={e => {
                  if (e.key === 'Enter') this.addComment();
                }}
                rows="3"
                cols="50"
                placeholder={placeholder}
                value={this.state.newComment}
                name="newComment"
                onChange={this.handleChangeInput}
                className="f-primary add-comment__textarea"
                readOnly={disabled}
              />
            </div>
            <div className="add-comment__button-container">
              <button disabled={Helpers.isGuestUser(user)} type="button" onClick={() => this.addComment()} className="add-comment__button">
                {t('DEFECT_DETAILS.FORM_ADD_COMMENT_BUTTON')}
              </button>
            </div>
          </div>
        )}
        <div className="comment-list">
          {comments.map((comment, index) => {
            return (
              <div key={index} className="comment-list__row">
                <div className="horizontal-padding">
                  {this.renderAuthorField({ value: comment.UserName, className: 'comment-list__item author-text' })}
                  {this.renderCommentField({ value: comment.Message, className: 'comment-list__item comment-text' })}
                  {comment.CreatedAt && <p className="f-secondary-dark comment-date">{Helpers.getDateFromUnix(comment.CreatedAt)}</p>}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
Comments.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({ user: state.userReducer });
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Comments);
