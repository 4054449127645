import { isEmpty } from 'lodash';
import { questionTypes } from '../../../../../common/question-components/constants/question-constants';
import { moduleSectionQuestionFields } from '../../../constants/constants';

export const mandatoryQuestionAnswered = (question, formErrors) => {
  if (!formErrors || isEmpty(formErrors) || !question[moduleSectionQuestionFields.isMandatory]) {
    return true;
  }

  const questionId = question[moduleSectionQuestionFields.id];

  return !Object.keys(formErrors).some(key => {
    const idsAfterHyphen = key.split('-')[1];
    return parseInt(idsAfterHyphen, 10) === questionId;
  });
};

export const questionAnswered = (question, formValues) => {
  if (question[moduleSectionQuestionFields.type] === questionTypes.NO_ANSWER.valueId) return true;

  if (!formValues || isEmpty(formValues)) {
    return false;
  }

  const questionId = question[moduleSectionQuestionFields.id];

  return Object.entries(formValues).some(([key, value]) => {
    const idsAfterHyphen = key.split('-')[1];
    if (question[moduleSectionQuestionFields.type] === questionTypes.CHECKBOX.valueId) {
      if (!formValues[key]) {
        return false;
      }
    }
    return parseInt(idsAfterHyphen, 10) === questionId && value !== '' && value !== null && value !== undefined;
  });
};
