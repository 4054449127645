import React from 'react';
import ButtonWithIcon from '../../../../button-with-icon/button-with-icon';
import ComponentList from '../../component-list';

const ComponentPickerCHRightSide = ({ question, handleDeleteComponent, customCloseAction }) => {
  const selectedComponents = question?.QuestionsComponents || [];
  return (
    <div>
      <ButtonWithIcon icon="chevron" iconRotation={180} text="Back" noBorder type="info" onClick={customCloseAction} />
      <ComponentList selectedComponents={selectedComponents} handleDeleteComponent={handleDeleteComponent} question={question} />
    </div>
  );
};

export default ComponentPickerCHRightSide;
