export const fields = {
  icon: 'icon',
  name: 'Name',
  startDate: 'StartDate',
  status: 'Status',
  id: 'ID',
};

//TODO: Adjust it to support full screen in future
export const tableConfig = [
  {
    title: '',
    key: fields.status,
    type: fields.icon,
    class: 'icon-column',
  },
  {
    title: 'NOTIFICATIONS.TITLE',
    key: fields.name,
    type: fields.name,
    class: 'name-column__notification_wo',
    isSort: true,
  },
  {
    title: 'WORKORDER_DETAILS.FORM_START_DATE',
    key: fields.startDate,
    type: fields.startDate,
    class: 'created-at-column',
    isSort: true,
  },
];

export const filterProps = {
  componentID: 'ComponentID',
  searchText: 'SearchText',
  sortByColumn: 'SortByColumn',
  sortDirection: 'SortDirection',
  assignedTo: 'AssignedToFilter',
  severityFilter: 'SeverityFilter',
  statusFilter: 'StatusFilter',
  dateFrom: 'DateFrom',
  dateTo: 'DateTo',
  // typeFilter: 'TypeFilter',
  workOrderTypeFilter: 'WorkOrderTypeFilter',
  createdByFilter: 'CreatedByFilter',
  lastSeen: 'LastSeen',
  perPage: 'PerPage',
  hasNext: 'HasNext',
  overdueFilter: 'OverdueFilter',
  assignedToMeFilter: 'AssignedToLoggedUserFilter',
  startInNextWeekFilter: 'StartInNextWeekFilter',
  startInThirtyDaysFilter: 'StartInThirtyDaysFilter',
  archived: 'ArchivedFilter',
  onHold: 'OnHoldFilter',
  startDateFrom: 'StartDateFrom',
  startDateTo: 'StartDateTo',
  componentTypeFilter: 'ComponentTypeFilter',
  componentIDsFilter: 'ComponentIDsFilter',
  properties: 'Properties',
  assignees: 'Assignees',
  collaborators: 'Collaborators',
  componentsSearchFilter: 'ComponentSearchText',
  hasObservationFilter: 'HasObservationFilter',
  hasChecklistFilter: 'HasChecklistFilter',
  checklistIDsFilter: 'ChecklistIDsFilter',
  additionalSubTypeFilter: 'AdditionalSubTypeFilter',
};

export const statuses = {
  draft: {
    value: 'DRAFT',
    title: 'WORK_ORDERS.DRAFT',
    icon: 'clock',
    isRegularStatus: true,
    modalTitle: 'ISOLATION_CERTIFICATE_STATUS.DRAFT_TITLE',
    skipPermissionCheck: true,
  },
  raised: {
    value: 'RAISED',
    title: 'WORK_ORDERS.RAISED',
    icon: 'clock-filled',
    isRegularStatus: true,
  },
  approved: {
    value: 'APPROVED',
    title: 'WORK_ORDERS.APPROVED',
    icon: 'checkmark-rounded',
    iconProps: {
      active: true,
    },
    iconFilledWhenActive: true,
    isRegularStatus: true,
  },
  rejected: {
    value: 'REJECTED',
    title: 'WORK_ORDERS.REJECTED',
    icon: 'alert-octagon',
    isRegularStatus: true,
  },
  authorised: {
    value: 'AUTHORISED',
    title: 'WORK_ORDERS.AUTHORISED',
    icon: 'check-rounded',
    iconProps: {
      active: true,
    },
    isRegularStatus: true,
  },
  inProgress: {
    value: 'IN-PROGRESS',
    title: 'WORK_ORDERS.IN_PROGRESS',
    icon: 'in-progress',
    iconProps: {
      active: true,
    },
    iconFilledWhenActive: true,
    isRegularStatus: true,
  },
  completed: {
    value: 'COMPLETED',
    title: 'WORK_ORDERS.COMPLETED',
    icon: 'checkmark',
    iconProps: {
      active: true,
    },
    isFinalStatus: true,
  },
  closed: {
    value: 'CLOSED',
    title: 'WORK_ORDERS.CLOSED',
    icon: 'close-filled',
    iconProps: {
      active: true,
    },
    isRegularStatus: true,
    isFinalStatus: true,
  },
  startInNextWeek: {
    value: filterProps.startInNextWeekFilter,
    title: 'WORK_ORDERS.START_IN_NEXT_WEEK',
    isSingleSelect: true,
    includeInStatuses: true,
  },
  startInNextMonth: {
    value: filterProps.startInThirtyDaysFilter,
    title: 'WORK_ORDERS.START_IN_NEXT_MONTH',
    isSingleSelect: true,
    includeInStatuses: true,
  },
  onHold: {
    value: filterProps.onHold,
    title: 'WORK_ORDERS.ON_HOLD',
    isSingleSelect: true,
    includeInStatuses: true,
  },
  archived: {
    value: filterProps.archived,
    title: 'WORK_ORDERS.ARCHIVED',
    isSingleSelect: true,
    includeInStatuses: true,
  },
  overdueFilter: {
    value: filterProps.overdueFilter,
    title: 'WORKORDER_FILTERS.OVERDUE',
    isSingleSelect: true,
    includeInStatuses: true,
  },
};

// In future we should just have one sortDirection in main consts
// TODO: this is repetitive across the app
export const sortDirection = {
  asc: 'ASC',
  desc: 'DESC',
};

export const defaultFilters = {
  [filterProps.sortByColumn]: fields.startDate,
  [filterProps.sortDirection]: sortDirection.desc,
  [filterProps.perPage]: 10,
  [filterProps.lastSeen]: 0,
  [filterProps.searchText]: '',
  [filterProps.hasNext]: false,
  [filterProps.statusFilter]: [],
  // set start in next 30 days by default
  [filterProps.startInThirtyDaysFilter]: true,
  [filterProps.onHold]: false,
  [filterProps.archived]: false,
  [filterProps.overdueFilter]: false,
  [filterProps.startInNextWeekFilter]: false,
};
