import React, { Component } from 'react';

import PropTypes from 'prop-types';
import KeyboardKey from './keyboard-key';

import { keyboardKeyTypes } from '../constants/constants';

import '../styles/controls.scss';

class ViewerControls extends Component {
  render() {
    const { t } = this.context;
    return (
      <div className="controls">
        <div className="controls__row">
          <div className="controls__row__keys-visual-col controls__item">
            <div className="wasd-layout">
              <KeyboardKey type={keyboardKeyTypes.arrowUp} />
              <KeyboardKey type={keyboardKeyTypes.arrowLeft} />
              <KeyboardKey type={keyboardKeyTypes.arrowDown} />
              <KeyboardKey type={keyboardKeyTypes.arrowRight} />
            </div>
          </div>
          <div className="controls__row__keys-textual-col controls__item">
            <p className="f-secondary-dark">{t('VIEWER_CONTROLS.KEYS_TEXTUAL.MOVE.VARIANT_1')}</p>
          </div>
          <div className="controls__row__description-col controls__item">
            <p className="f-primary">{t('VIEWER_CONTROLS.DESCRIPTION.MOVE.VARIANT_1')}</p>
          </div>
        </div>
        <div className="controls__row">
          <div className="controls__row__keys-visual-col controls__item">
            <div className="wasd-layout">
              <KeyboardKey letter="W" />
              <KeyboardKey letter="A" />
              <KeyboardKey letter="S" />
              <KeyboardKey letter="D" />
            </div>
          </div>
          <div className="controls__row__keys-textual-col controls__item">
            <p className="f-secondary-dark">{t('VIEWER_CONTROLS.KEYS_TEXTUAL.MOVE.VARIANT_2')}</p>
          </div>
          <div className="controls__row__description-col controls__item">
            <p className="f-primary">{t('VIEWER_CONTROLS.DESCRIPTION.MOVE.VARIANT_2')}</p>
          </div>
        </div>
        <div className="controls__row">
          <div className="controls__row__keys-visual-col controls__item">
            <div className="inline-layout">
              <KeyboardKey letter="R" />
              <KeyboardKey letter="F" />
            </div>
          </div>
          <div className="controls__row__keys-textual-col controls__item">
            <p className="f-secondary-dark">{t('VIEWER_CONTROLS.KEYS_TEXTUAL.ELEVATION.VARIANT_1')}</p>
          </div>
          <div className="controls__row__description-col controls__item">
            <p className="f-primary">{t('VIEWER_CONTROLS.DESCRIPTION.ELEVATION.VARIANT_1')}</p>
          </div>
        </div>
        <div className="controls__row">
          <div className="controls__row__keys-visual-col controls__item">
            <div className="inline-layout">
              <KeyboardKey letter="<" />
              <KeyboardKey letter=">" />
            </div>
          </div>
          <div className="controls__row__keys-textual-col controls__item">
            <p className="f-secondary-dark">{t('VIEWER_CONTROLS.KEYS_TEXTUAL.ELEVATION.VARIANT_2')}</p>
          </div>
          <div className="controls__row__description-col controls__item">
            <p className="f-primary">{t('VIEWER_CONTROLS.DESCRIPTION.ELEVATION.VARIANT_2')}</p>
          </div>
        </div>
        <div className="controls__row">
          <div className="controls__row__keys-visual-col controls__item">
            <KeyboardKey type={keyboardKeyTypes.leftClick} />
          </div>
          <div className="controls__row__keys-textual-col controls__item">
            <p className="f-secondary-dark">{t('VIEWER_CONTROLS.KEYS_TEXTUAL.ROTATE.VARIANT_1')}</p>
          </div>
          <div className="controls__row__description-col controls__item">
            <p className="f-primary">{t('VIEWER_CONTROLS.DESCRIPTION.ROTATE.VARIANT_1')}</p>
          </div>
        </div>
        <div className="controls__row">
          <div className="controls__row__keys-visual-col controls__item">
            <KeyboardKey type={keyboardKeyTypes.rightClick} />
          </div>
          <div className="controls__row__keys-textual-col controls__item">
            <p className="f-secondary-dark">{t('VIEWER_CONTROLS.KEYS_TEXTUAL.MOVE.VARIANT_3')}</p>
          </div>
          <div className="controls__row__description-col controls__item">
            <p className="f-primary">{t('VIEWER_CONTROLS.DESCRIPTION.MOVE.VARIANT_3')}</p>
          </div>
        </div>
        <div className="controls__row">
          <div className="controls__row__keys-visual-col controls__item">
            <KeyboardKey type={keyboardKeyTypes.scroll} />
          </div>
          <div className="controls__row__keys-textual-col controls__item">
            <p className="f-secondary-dark">{t('VIEWER_CONTROLS.KEYS_TEXTUAL.MOVEMENT_SPEED.VARIANT_1')}</p>
          </div>
          <div className="controls__row__description-col controls__item">
            <p className="f-primary">{t('VIEWER_CONTROLS.DESCRIPTION.MOVEMENT_SPEED.VARIANT_1')}</p>
          </div>
        </div>
      </div>
    );
  }
}
ViewerControls.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ViewerControls;
