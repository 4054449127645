import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ResizableRect from 'react-resizable-rotatable-draggable';

import '../styles/resize-box.scss';

class ResizeBox extends Component {
  render() {
    const { width, top, left, height, children, onResize, className, direction, isHidden } = this.props;

    return (
      <div className={`resizable-box ${isHidden ? 'resizable-box--hidden' : ''} ${isNaN(height) ? height : ''} ${isNaN(width) ? width : ''} ${className}`}>
        <ResizableRect left={left || 0} top={top || 0} width={isNaN(width) ? 100 : width} height={isNaN(height) ? 100 : height} zoomable={direction || 'w'} onResize={onResize} />
        {children}
      </div>
    );
  }
}

ResizeBox.propTypes = {
  anchor: PropTypes.oneOf(['max-height', 'max-width']),
};

export default ResizeBox;
