import React from 'react';
import { reduxForm, Field } from 'redux-form';
import UneditableInfo from '../../../../../../../common/form/components/uneditable-info';
import { formConstants } from '../../../../../constants/component-constants';
import { FORMS } from '../../../../../../../common/constants';
import PropTypes from 'prop-types';
import Loader from '../../../../../../../common/global-loader/components/simple-loader';

import '../styles/details-form.scss';
import RenderIf from '../../../../../../../common/render-if/components/render-if';
import CustomProperties from '../../../../../../../common/custom-property/components/custom-properties';

const DetailsForm = ({ isLoading, properties }, { t }) => {
  return (
    <>
      <RenderIf if={isLoading}>
        <Loader isLoading={isLoading} />
      </RenderIf>
      <RenderIf if={!isLoading}>
        <form noValidate className="details-form">
          <p className="f-primary details-form__title">{t('COMPONENT_DETAILS.TITLE')}</p>
          <Field id={formConstants.fields.hierarchy} name={formConstants.fields.hierarchy} component={UneditableInfo} label={t('COMPONENT_DETAILS.FORM_HIERARCHY')} />
          <Field id={formConstants.fields.name} name={formConstants.fields.name} component={UneditableInfo} label={t('COMPONENT_DETAILS.FORM_NAME')} />
          <Field id={formConstants.fields.description} name={formConstants.fields.description} component={UneditableInfo} label={t('COMPONENT_DETAILS.FORM_DESCRIPTION')} />
          <Field id={formConstants.fields.componentType} name={formConstants.fields.componentType} component={UneditableInfo} label={t('COMPONENT_DETAILS.FORM_COMPONENT_TYPE')} />
          <Field id={formConstants.fields.componentSubType} name={formConstants.fields.componentSubType} component={UneditableInfo} label={t('COMPONENT_DETAILS.FORM_COMPONENT_SUB_TYPE')} />
          <Field id={formConstants.fields.critical} name={formConstants.fields.critical} component={UneditableInfo} label={t('COMPONENT_DETAILS.FORM_CRITICAL_COMPONENT')} />
          <Field id={formConstants.fields.code} name={formConstants.fields.code} component={UneditableInfo} label={t('COMPONENT_DETAILS.FORM_CODE')} />
          <Field id={formConstants.fields.asset} name={formConstants.fields.asset} component={UneditableInfo} label={t('COMPONENT_DETAILS.FORM_PARENT_ASSET')} />
          <Field id={formConstants.fields.manufacturer} name={formConstants.fields.manufacturer} component={UneditableInfo} label={t('COMPONENT_DETAILS.FORM_MANUFACTURER')} />
          <Field id={formConstants.fields.material} name={formConstants.fields.material} component={UneditableInfo} label={t('COMPONENT_DETAILS.FORM_MATERIAL')} />
          <Field id={formConstants.fields.weight} name={formConstants.fields.weight} component={UneditableInfo} label={t('COMPONENT_DETAILS.FORM_WEIGHT')} />
          <CustomProperties
            isDisabled={true}
            formName={FORMS.unassignedComponentDetailsUneditableProp}
            properties={properties || []}
            getProperties={() => {}}
            updateProperty={() => {}}
            addProperty={() => {}}
            deleteProperty={() => {}}
            getSuggestions={() => {}}
            customButton={<></>}
          />
        </form>
      </RenderIf>
    </>
  );
};
DetailsForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default reduxForm({ form: FORMS.unassignedComponentDetailsUneditable, enableReinitialize: true, touchOnChange: true, destroyOnUnmount: true })(DetailsForm);
