import React, { Component } from 'react';
import { connect } from 'react-redux';

import { isEmpty, filter } from 'lodash';

import { toggleDefect, toggleDefectTemp, selectAllDefects, selectAllDefectsTemp, deselectAllDefects, deselectAllDefectsTemp, setNewDefect } from '../actions/action-creators';
import { goToStep } from '../actions/stepper-actions';
import { getDefectDetails, resetDefectList } from '../actions/start-workflow-actions';

import DefectList from './defect-list';
import InspectionRenderer from '../../inspections/components/left-toolbar/inspection-renderer';
import SimpleLoader from '../../../common/global-loader/components/simple-loader';

import { stepperConstants } from '../constants/constants';
import { updateElementGeometry } from '../../inspections/actions/inspection-actions';

class Defects extends Component {
  state = {
    preSelectedDefect: null,
  };
  componentDidMount = () => {
    const { preSelectedDefect } = this.props;
    //save value before it is destroyed
    this.setState({ preSelectedDefect: preSelectedDefect });
  };

  selectDefect = element => {
    const { getDefectDetails, currentImage, activeTab, resetDefectList, setNewDefect, defects, imageDefects, allDefects } = this.props;

    if (!element.ID || element.ID < 0) {
      // handle temp item click
      return;
    }
    getDefectDetails(element, currentImage, activeTab);
    goToStep(stepperConstants.DEFECT_EDIT);
    resetDefectList(defects, imageDefects, allDefects);
    //set defect to null in order to abandon create
    setNewDefect();
  };

  render() {
    const { preSelectedDefect } = this.state;
    const { defects, deselectAll, selectAll, toggleDefect, deselectAllDefectsTemp, selectAllDefectsTemp, toggleDefectTemp, selectedDefect, updateGeometry, defectsLoading, viewer } = this.props;

    //show only defects thag are in edit state
    const filteredDefects = filter(defects, item => item.ID < 0);
    const defectsToDisplay = !isEmpty(filteredDefects) ? filteredDefects : defects;

    return (
      <InspectionRenderer
        deselectAll={deselectAll}
        selectAll={selectAll}
        toggleElement={toggleDefect}
        deselectAllTemp={deselectAllDefectsTemp}
        selectAllTemp={selectAllDefectsTemp}
        toggleElementTemp={toggleDefectTemp}
        selectElement={this.selectDefect}
        updateElementGeometry={updateGeometry}
        elements={defectsToDisplay}
        selectedDefect={selectedDefect}
        viewer={viewer}
      >
        {({ elementClickHandler, elementShowHandler, elementHideHandler, selectAllHandler, deselectAllHandler }) =>
          defectsLoading ? (
            <div className="defects-loader-w">
              <SimpleLoader isLoading={true} width={80} height={80} className="defects-loader" />
            </div>
          ) : (
            <DefectList
              handleDefectClick={elementClickHandler}
              handleShowDefect={elementShowHandler}
              handleHideDefect={elementHideHandler}
              handleSelectAll={selectAllHandler}
              handleDeselectAll={deselectAllHandler}
              defects={defects}
              preSelectedDefect={preSelectedDefect}
            />
          )
        }
      </InspectionRenderer>
    );
  }
}

const mapStateToProps = state => ({
  // componentFilter: state.inspectionReducer.componentFilter,
  defects: state.startWorkflowReducer.defects,
  selectedDefect: state.startWorkflowReducer.selectedDefect,
  currentImage: state.startWorkflowReducer.currentImage,
  activeTab: state.startWorkflowReducer.activeDefectsTab,
  allDefects: state.startWorkflowReducer.allDefects,
  imageDefects: state.startWorkflowReducer.imageDefects,
  defectsLoading: state.startWorkflowReducer.defectsLoading,
  viewer: state.potreeReducer.viewerInstance,
});

const mapDispatchToProps = dispatch => ({
  deselectAll: () => dispatch(deselectAllDefects()),
  selectAll: () => dispatch(selectAllDefects()),
  toggleDefect: id => dispatch(toggleDefect(id)),
  // TEMP
  deselectAllDefectsTemp: () => dispatch(deselectAllDefectsTemp()),
  selectAllDefectsTemp: () => dispatch(selectAllDefectsTemp()),
  toggleDefectTemp: id => dispatch(toggleDefectTemp(id)),
  //
  getDefectDetails: (element, currentImage, activeTab) => dispatch(getDefectDetails(element, currentImage, activeTab)),
  goToStep: val => dispatch(goToStep(val)),
  resetDefectList: (defects, imageDefects, allDefects) => dispatch(resetDefectList(defects, imageDefects, allDefects)),
  setNewDefect: () => dispatch(setNewDefect(null)),
  updateGeometry: data => dispatch(updateElementGeometry(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Defects);
