const userFields = {
  email: 'Email',
  companyId: 'CompanyID',
  companyLogo: 'CompanyLogo',
  distanceUnit: 'DistanceUnit',
  features: 'Features',
  fullName: 'Name',
  profileImage: 'ProfileImage',
  role: 'Role',
  temperatureUnit: 'TemperatureUnit',
  theme: 'Theme',
  token: 'Token',
  companyName: 'CompanyName',
  userRoleId: 'UserRoleID',
  userId: 'UserID',
};

export default {
  userFields,
};
