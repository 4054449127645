import routesConstants from './routes';
import { Api } from '../../core/api';
import { API_BASE_URL } from '../../common/constants';
import dmsRouteConstants from '../document-management-api/routes';

const getPdfTagTableData = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_PDF_TAG_TABLE_DATA_DMS}`, params);
};

const ocrComponentsSave = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.OCR_COMPONENTS_SAVE}`, params);
};
const deletePDF = async params => {
  return await Api.post(`${API_BASE_URL}${dmsRouteConstants.DELETE_DMS_FILE}`, params);
};

const editPDF = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.EDIT_PDF_DMS}`, params);
};

const getMultipleFilesStatus = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.MULTIPLE_FILE_STATUS_DMS}`, params);
};
const getInspectionDocuments = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_INSPECTION_DOCUMENTS_DMS}`, params);
};
const deleteInspectionDocument = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_INSPECTION_DOCUMENT_DMS}`, params);
};

export default {
  getPdfTagTableData,
  ocrComponentsSave,
  deletePDF,
  editPDF,
  getMultipleFilesStatus,
  getInspectionDocuments,
  deleteInspectionDocument,
};
