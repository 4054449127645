import { find } from 'lodash';

import Helpers from '../../../common/helpers';
import { routes } from '../../../common/routes-constants';
import { isolationTemplateSections } from '../components/isolations/constants/constants';

export const mapTemplateSections = (sections, files, staticSections) => {
  return sections.map(section => {
    let mappedSection = section;
    const selectedSection = find(staticSections || isolationTemplateSections, { Name: section.Name });

    if (selectedSection) {
      mappedSection = { ...selectedSection, ...section, files: selectedSection.isFilesUpload && files ? files : [] };
    }

    return mappedSection;
  });
};

export const openPermitTemplateForm = (mode, step, id, queryParams) => {
  if (!step) {
    return;
  }

  Helpers.goTo(`${routes.protectedRoutes.permitTemplateManagement.fullPath}/${mode}/${step}/${id || ''}${queryParams || ''}`);
};
