import routesConstants from './routes';
import { Api } from '../../core/api';
import { API_BASE_URL } from '../../common/constants';
import { generateQueryParams } from '../helpers/helper';

const fetchKeyboxes = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_KEYBOXES}`, data);
};

const createKeybox = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CREATE_KEYBOX}`, data);
};

const getKeyboxDetails = async data => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_KEYBOX_DETAILS}${generateQueryParams(data)}`);
};

const deleteKeybox = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_KEYBOX}`, data);
};

const editKeyboxDetails = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.EDIT_KEYBOX_DETAILS}`, data);
};

const deleteKey = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_KEY}`, data);
};

const addKey = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_KEY}`, data);
};

const issueKey = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ISSUE_KEY}`, data);
};

const returnKey = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.RETURN_KEY}`, data);
};

const updateKeyName = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_KEY_NAME}`, data);
};

const fetchContractors = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_CONTRACTORS}`, data);
};

export default {
  fetchKeyboxes,
  createKeybox,
  getKeyboxDetails,
  deleteKeybox,
  editKeyboxDetails,
  deleteKey,
  addKey,
  issueKey,
  returnKey,
  updateKeyName,
  fetchContractors,
};
