import loginActionTypes from '../constants/action-types';

const userState = {
  Email: null,
};

export const userReducer = (state = userState, payload) => {
  switch (payload.type) {
    case loginActionTypes.HANDLE_USER_LOGIN:
      return { ...state, ...payload.data };
    case loginActionTypes.HANDLE_LOG_OUT:
      return { ...userState };
    default:
      return state;
  }
};
