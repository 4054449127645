import { find, isEmpty, values } from 'lodash';

import { questionTypes } from '../../../../../common/question-components/constants/question-constants';
import { moduleSectionQuestionFields } from '../../../constants/constants';

export const validateSingleQuestion = (question, formValues) => {
  let error = null;
  const types = values(questionTypes);
  const type = find(types, { valueId: question[moduleSectionQuestionFields.type] });
  const name = type ? `${type.fieldName}-${question[moduleSectionQuestionFields.id]}` : '';

  if ([questionTypes.TEXT_LONG.valueId].indexOf(question[moduleSectionQuestionFields.type]) > -1) {
    if (formValues[name] && formValues[name].length > questionTypes.TEXT_LONG.maxChars) {
      error = 'ISOLATION_CERTIFICATE.TEXTAREA_MAX_LENGTH_QUESTION_VALIDATE';
    }
  }

  if ([questionTypes.TEXT.valueId, questionTypes.NUMBER.valueId].indexOf(question[moduleSectionQuestionFields.type]) > -1) {
    if (formValues[name] && formValues[name].length > 4000) {
      error = 'ISOLATION_CERTIFICATE.MAX_LENGTH_QUESTION_VALIDATE';
    }
  }
  if (question[moduleSectionQuestionFields.isMandatory]) {
    if ([questionTypes.TEXT.valueId].indexOf(question[moduleSectionQuestionFields.type]) > -1) {
      if (isEmpty(formValues[name])) {
        error = 'CUSTOM_SECTION_FORM.ANSWER_REQUIRED';
      }
    }
    if ([questionTypes.DATE.valueId].indexOf(question[moduleSectionQuestionFields.type]) > -1) {
      if (!formValues[name]) {
        error = {
          string: 'CUSTOM_SECTION_FORM.ANSWER_REQUIRED',
        };
      }
    }
    if ([questionTypes.NUMBER.valueId].indexOf(question[moduleSectionQuestionFields.type]) > -1) {
      if (!formValues[name] && parseInt(formValues[name], 10) !== 0) {
        error = 'CUSTOM_SECTION_FORM.ANSWER_REQUIRED';
      }
    }
    if ([questionTypes.CHECKBOX.valueId].indexOf(question[moduleSectionQuestionFields.type]) > -1) {
      if (!formValues[`${name}-no`] && !formValues[`${name}-yes`]) {
        error = 'CUSTOM_SECTION_FORM.ANSWER_REQUIRED';
      }
    }
    if ([questionTypes.MULTI_ANSWER.valueId].indexOf(question[moduleSectionQuestionFields.type]) > -1) {
      let isValid = false;
      // at least one answer needs to be checked
      for (const option of question[moduleSectionQuestionFields.questionOptions]) {
        if (formValues[`${name}-${option[moduleSectionQuestionFields.id]}`]) {
          isValid = true;
        }
      }
      if (!isValid) {
        error = 'CUSTOM_SECTION_FORM.ANSWER_REQUIRED';
      }
    }
  }
  return error;
};
export const validate = (formValues, props) => {
  const errors = {};
  const questions = props?.questions;

  if (questions && questions.length > 0) {
    const types = values(questionTypes);

    for (let i = 0; i < questions.length; i++) {
      const type = find(types, { valueId: questions[i][moduleSectionQuestionFields.type] });
      const name = type ? `${type.fieldName}-${questions[i][moduleSectionQuestionFields.id]}` : '';
      const questionError = validateSingleQuestion(questions[i], formValues);
      if (questionError) {
        errors[name] = questionError;
      }
    }
  }

  return errors;
};
