import { PERMISSIONS, PERMISSION_TYPES } from '../../../common/permissions-constants';
import { userFields } from './team-constants';

export const roleFields = {
  name: 'Name',
  description: 'Description',
  id: 'ID',
  assignedUsers: 'AssignedUsers',
  createdAt: 'CreatedAt',
};

export const rolesTableHeader = [
  {
    title: 'ROLES_TABLE.ID',
    key: roleFields.id,
    enableSort: true,
  },
  {
    title: 'ROLES_TABLE.NAME',
    key: roleFields.name,
    enableSort: true,
  },
  {
    title: 'ROLES_TABLE.DESCRIPTION',
    key: roleFields.description,
    enableSort: true,
  },
  {
    title: 'ROLES_TABLE.NO_USERS',
    key: roleFields.assignedUsers,
    type: roleFields.assignedUsers,
    enableSort: true,
  },
  {
    title: 'ROLES_TABLE.DATE_CREATED',
    key: roleFields.createdAt,
    enableSort: true,
    type: 'date',
  },
];

export const sortDirection = {
  asc: 'ASC',
  desc: 'DESC',
};

export const filterProps = {
  sortByColumn: 'SortByColumn',
  sortByDirection: 'SortByDirection',
  perPage: 'PerPage',
  lastSeen: 'LastSeen',
  searchText: 'SearchText',
  hasNext: 'HasNext',
};

export const defaultRolesFilter = {
  [filterProps.sortByColumn]: roleFields.id,
  [filterProps.sortByDirection]: sortDirection.asc,
  [filterProps.perPage]: 20,
  [filterProps.lastSeen]: 0,
  [filterProps.searchText]: '',
  [filterProps.hasNext]: false,
};

export const actionTypes = {
  editRole: 'editRole',
  deleteRole: 'deleteRole',
};

export const actionsMenu = [
  {
    title: 'ROLES_TABLE_SIDEBAR.SETTINGS_MENU.ITEM_1',
    id: '1',
    action: actionTypes.editRole,
    access: {
      visibleFor: [PERMISSIONS[PERMISSION_TYPES.people].edit.name],
    },
  },
  {
    title: 'ROLES_TABLE_SIDEBAR.SETTINGS_MENU.ITEM_2',
    id: '2',
    action: actionTypes.deleteRole,
    access: {
      visibleFor: [PERMISSIONS[PERMISSION_TYPES.people].delete.name],
    },
  },
];

export const userSearchParams = {
  PerPage: Number.MAX_SAFE_INTEGER,
  LastSeen: 0,
  SearchText: '',
  SortByColumn: userFields.name,
  SortDirection: 'ASC',
  ActiveUsers: true,
};
