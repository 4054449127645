export default {
  CREATE_USER: '/account/create',
  EDIT_USER: '/account/update',
  GET_USERS_TABLE_DATA: '/account/users/list',
  ACTIVATE_USER: '/account/activate',
  DELETE_USER: '/account/delete',
  FETCH_USER_DETAILS: '/account/user/details',
  GET_USERS: '/account/users',
  GET_ERROR_CODES: '/shared/errors',
};
