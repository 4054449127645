import { measurementTypes } from './constants';

export default {
  [measurementTypes.defect]: {
    variants: [
      {
        showDistances: false,
        showAngles: false,
        showCoordinates: true,
        showArea: false,
        closed: true,
      },
      {
        showDistances: false,
        showAngles: false,
        showCoordinates: false,
        showArea: false,
        closed: true,
        systemType: measurementTypes.measurement,
      },
    ],
  },
};
