import {
  loadProjectsSuccess,
  setProjectEdit,
  handleArchiveProjectModal,
  handleDeleteProjectModal,
  setProjectDetails,
  resetProjectDetails,
  setDMSCategories,
  setDMSDataLoading,
} from './action-creators';
import { setItemToCancel } from '../../upload/actions/action-creators';
import ProjectApi from '../../../api/project/actions';

import Helpers from '../../../common/helpers';
import routesConstants from '../../../common/routes-constants';

import { map, flatten, some } from 'lodash';

export const loadProjects = (data, currentProjects) => {
  return async dispatch => {
    const res = await ProjectApi.getProjects(data);
    const {
      data: { Data },
    } = res;

    if (Data) {
      let res = Data.Projects;
      if (currentProjects) {
        res = Helpers.updateProjectArray(currentProjects, Data.Projects);
      }
      dispatch(loadProjectsSuccess(res || []));
    }
  };
};

export const setProjectToEdit = project => {
  return async dispatch => {
    dispatch(setProjectEdit(project));
    if (project) {
      Helpers.goTo(routesConstants.routes.protectedRoutes.editProject.fullPath);
    }
  };
};

export const archiveProjectModal = params => {
  return dispatch => {
    dispatch(handleArchiveProjectModal(params));
  };
};

export const archiveProject = params => {
  return async (dispatch, getState) => {
    const {
      projectReducer: { projects },
    } = getState();
    try {
      await ProjectApi.archiveProject({ ProjectID: params.ID });
      const foundIndex = projects.findIndex(p => p.ID === params.ID);
      if (foundIndex > -1) {
        let newProjects = [...projects];
        newProjects[foundIndex] = { ...params, Active: !params.Active };
        dispatch(loadProjectsSuccess(newProjects));
        dispatch(
          archiveProjectModal({
            isOpen: false,
          })
        );
      }
    } catch (err) {
      dispatch(
        archiveProjectModal({
          isOpen: false,
        })
      );
    }
  };
};

export const toggleDeleteProjectModal = params => {
  return dispatch => {
    dispatch(handleDeleteProjectModal(params));
  };
};

export const deleteProject = (data, setFormError) => {
  return async (dispatch, getState) => {
    const {
      projectReducer: { projects },
    } = getState();
    try {
      await ProjectApi.deleteProject(data);
      dispatch(
        toggleDeleteProjectModal({
          isOpen: false,
        })
      );
      let newProjects = projects.filter(project => project.ID !== data.ProjectID);
      dispatch(loadProjectsSuccess(newProjects));
      setFormError(null);
    } catch (err) {
      const errorMessage = Helpers.getErrorContent(err);
      setFormError(errorMessage);
    }
  };
};

export const deleteInspection = (data, setFormError, popupMessage) => {
  return async (dispatch, getState) => {
    const { projects } = getState().projectReducer;
    const { uploadInspectionFile } = getState().uploadReducer;
    //if current file is uploading than do refreh to stop upload else just delete
    let isCurrentFileUploading = false;
    if (uploadInspectionFile) {
      const inspections = flatten(map(projects, 'Inspections'));
      isCurrentFileUploading = some(inspections, { ID: uploadInspectionFile.InspectionID });
    }
    try {
      const res = window.confirm(popupMessage);
      if (res) {
        await ProjectApi.deleteInspection(data);
        dispatch(
          toggleDeleteProjectModal({
            isOpen: false,
          })
        );
        if (isCurrentFileUploading) {
          dispatch(setItemToCancel(uploadInspectionFile.timeStamp));
        }

        setFormError(null);
      }
    } catch (err) {
      const errorMessage = Helpers.getErrorContent(err);
      setFormError(errorMessage);
    }
  };
};

export const fetchProjectDetails = project_id => {
  return async dispatch => {
    try {
      dispatch(setDMSDataLoading(true));
      const res = await ProjectApi.getProjectDetails([{ project_id }]);
      const { Data } = res.data;
      dispatch(setProjectDetails(Data));
      dispatch(setDMSDataLoading(false));
    } catch (err) {
      console.error(err);
      dispatch(resetProjectDetails());
      dispatch(setDMSDataLoading(false));
    }
  };
};

export const fetchDMSCategories = (projectId, moduleName, callback, loadingCallback, updateValueInReducer = true) => {
  return async dispatch => {
    try {
      loadingCallback && typeof loadingCallback === 'function' && loadingCallback(true);
      const res = await ProjectApi.fetchDMSCategories({ ProjectID: projectId, ModuleName: moduleName });
      const { Data } = res?.data;
      updateValueInReducer && dispatch(setDMSCategories(Data));
      callback && callback(Data);
      loadingCallback && typeof loadingCallback === 'function' && loadingCallback(false);
    } catch (e) {
      loadingCallback && typeof loadingCallback === 'function' && loadingCallback(false);
      console.error(e);
    }
  };
};
