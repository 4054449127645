import { Component } from 'react';
import DoubleTable from '../../../../../../common/double-table/components/double-table';
import {
  firstTableConfig,
  secondTableConfig,
  unassignedTableConfig,
  defaultLevelFilters,
  filterProps,
  defaultUnassignedFilters,
  componentActionsProps,
  componentActions,
  defaultComponentsFilters,
  placements,
  SearchPropName,
} from './constants/constants';
import PageNavigation from '../../../../../../common/page-navigation/components/page-navigation';
import PropTypes from 'prop-types';
import routesConstants from '../../../../../../common/routes-constants';
import { connect } from 'react-redux';
import { formatComponentCells, formatLevelCells, formatUnassignedComponentCells } from '../assign-components/actions/actions';
import { toInteger, get, debounce, remove, isEmpty } from 'lodash';
import {
  fetchComponentHierarchyLevels,
  fetchUnassignedComponents,
  fetchComponents,
  updateComponentHierarchyComponent,
  fetchComponentDetails,
  fetchComponentProperties,
} from '../../../../../component-hierarchy/actions/component-hierarchy-levels-actions';
import Helpers from '../../../../../../common/helpers';
import '../assign-components/styles/assign-components.scss';
import TableTemplate from '../../../../../../common/double-table/components/table-template';
import PathViewer from '../../../../../../common/path-viewer/components/path-viewer';
import Notification from '../../../../../../common/notification/components/notification';
import Toolbar from '../../../collapsible-toolbar';
import DetailsForm from './components/details-form';
import ComponentHierarchyHelpers from '../../../../../component-hierarchy/helpers/helpers';

class AssignComponents extends Component {
  constructor(props, context) {
    super(props);
    const { location } = props;
    const { query } = location;
    const { t } = context;

    this.state = {
      componentLevels: [],
      TotalItems: 0,
      filters: defaultLevelFilters,
      [SearchPropName.level]: '',
      levelPath: [{ Code: t('COMPONENT_HIERARCHY.ALL_LEVELS'), Name: '' }],
      isLoading: false,
      currentLevelID: null,
      unassignedComponents: [],
      unassignedComponentsTotalItems: 0,
      isLoadingUnassignedComponents: false,
      unassignedComponentsFilters: defaultUnassignedFilters,
      [SearchPropName.unassigned]: '',
      components: [],
      componentsTotalItems: 0,
      isLoadingComponents: false,
      componentsFilters: defaultComponentsFilters,
      [SearchPropName.components]: '',
      notificationData: { isDisplayed: false },
      selectedComponent: null,
      selectedComponentData: {},
      isLoadingSelectedComponentData: false,
      toolbarCollapsed: true,
      selectedComponentProperties: null,
    };
    this.ProjectID = toInteger(get(query, 'project_id'));
    this.searchLevelsChangeDebounced = debounce(this.handleSearchLevels, 300);
    this.searchUnassignedChangeDebounced = debounce(this.handleSearchUnassigned, 300);
    this.searchComponentsChangeDebounced = debounce(this.handleSearchComponents, 300);
  }
  componentDidMount = () => {
    const { filters, unassignedComponentsFilters } = this.state;

    this.fetchLevels(filters);
    this.fetchUnassignedComponents(unassignedComponentsFilters);
  };

  onSuccessFetch = newState => this.setState({ ...newState });

  closeNotificationTimeout = () => {
    setTimeout(() => {
      this.setState({ notificationData: { isDisplayed: false } });
    }, 5000);
  };

  closeNotification = () => this.setState({ notificationData: { isDisplayed: false } });

  //fetching items functions
  fetchLevels = (filters, loadMore) => {
    const { componentLevels, levelsSearchText } = this.state;
    const { fetchComponentHierarchyLevels } = this.props;

    const onSuccessFetch = newState => {
      this.setState({ ...newState });

      if (loadMore && newState.componentLevels) {
        Helpers.scrollIntoView('level-table', `row-${filters[filterProps.lastSeen] - 1}`, isEmpty(levelsSearchText) ? -350 : -200);
      }
    };

    fetchComponentHierarchyLevels({ ProjectID: this.ProjectID, ...filters }, onSuccessFetch, loadMore, componentLevels);
  };

  fetchUnassignedComponents = (filters, loadMore) => {
    const { unassignedComponents } = this.state;
    const { fetchUnassignedComponents } = this.props;

    const onSuccessFetch = newState => {
      this.setState({ ...newState });

      if (loadMore && newState.unassignedComponents) {
        Helpers.scrollIntoView('unassigned-table', `row-${filters[filterProps.lastSeen] - 1}`, -250);
      }
    };

    fetchUnassignedComponents({ ProjectID: this.ProjectID, ...filters }, onSuccessFetch, loadMore, unassignedComponents);
  };

  fetchComponents = (filters, loadMore) => {
    const { components } = this.state;
    const { fetchComponents } = this.props;

    const onSuccessFetch = newState => {
      this.setState({ ...newState });

      if (loadMore && newState.components) {
        Helpers.scrollIntoView('components-table', `row-${filters[filterProps.lastSeen] - 1}`, -250);
      }
    };

    fetchComponents({ ProjectID: this.ProjectID, ...filters }, onSuccessFetch, loadMore, components);
  };

  //load more functions
  loadMoreLevels = () => {
    const { filters } = this.state;
    this.fetchLevels(filters, true);
  };

  loadMoreUnassigned = () => {
    const { unassignedComponentsFilters } = this.state;
    this.fetchUnassignedComponents(unassignedComponentsFilters, true);
  };
  loadMoreComponents = () => {
    const { componentsFilters } = this.state;
    this.fetchComponents(componentsFilters, true);
  };

  //handling search functions
  handleSearchLevels = SearchText => {
    const { filters } = this.state;
    ComponentHierarchyHelpers.onSearch(SearchText, filters, defaultLevelFilters, this.fetchLevels);
  };

  handleSearchUnassigned = SearchText => {
    const { unassignedComponentsFilters } = this.state;
    ComponentHierarchyHelpers.onSearch(SearchText, unassignedComponentsFilters, defaultUnassignedFilters, this.fetchUnassignedComponents);
  };
  handleSearchComponents = SearchText => {
    const { componentsFilters, currentLevelID } = this.state;
    ComponentHierarchyHelpers.onSearch(SearchText, componentsFilters, defaultComponentsFilters, this.fetchComponents, { [filterProps.hierarchyID]: currentLevelID });
  };

  //handling input changes
  handleInputLevelChange = e => {
    this.setState({ levelsSearchText: e.target.value });
    this.searchLevelsChangeDebounced(e.target.value);
  };
  handleInputUnassignedChange = e => {
    this.setState({ unassignedSearchText: e.target.value });
    this.searchUnassignedChangeDebounced(e.target.value);
  };
  handleInputComponentsChange = e => {
    this.setState({ componentsSearchText: e.target.value });
    this.searchComponentsChangeDebounced(e.target.value);
  };

  //handle sort click

  onLevelColumnSort = SortByColumn => {
    const { filters } = this.state;
    ComponentHierarchyHelpers.onColumnSort(SortByColumn, filters, this.fetchLevels);
  };

  onUnassignedColumnSort = SortByColumn => {
    const { unassignedComponentsFilters } = this.state;
    ComponentHierarchyHelpers.onColumnSort(SortByColumn, unassignedComponentsFilters, this.fetchUnassignedComponents);
  };
  onComponentsColumnSort = SortByColumn => {
    const { componentsFilters } = this.state;
    ComponentHierarchyHelpers.onColumnSort(SortByColumn, componentsFilters, this.fetchComponents);
  };

  //handle path interaction
  handleRowClick = row => {
    const { levelPath, filters, componentsFilters } = this.state;
    ComponentHierarchyHelpers.onRowLevelClick(row, levelPath, filters, defaultLevelFilters, this.fetchLevels, this.onSuccessFetch, null, SearchPropName.level);
    ComponentHierarchyHelpers.onComponentLevelClick(row, componentsFilters, defaultComponentsFilters, this.fetchComponents, this.onSuccessFetch, null, SearchPropName.components);
  };
  handlePathBackClick = () => {
    const { levelPath, filters, componentsFilters } = this.state;
    ComponentHierarchyHelpers.onPathBackClick(levelPath, filters, defaultLevelFilters, this.fetchLevels, this.onSuccessFetch, null, SearchPropName.level);
    ComponentHierarchyHelpers.onPathBackClickComponents(levelPath, componentsFilters, defaultComponentsFilters, this.fetchComponents, this.onSuccessFetch, null, SearchPropName.components);
  };
  handlePathClick = ID => {
    const { levelPath, filters, componentsFilters } = this.state;
    ComponentHierarchyHelpers.onPathClick(ID, levelPath, filters, defaultLevelFilters, this.fetchLevels, this.onSuccessFetch, null, SearchPropName.level);
    ComponentHierarchyHelpers.onPathClickComponent(ID, componentsFilters, defaultComponentsFilters, this.fetchComponents, this.onSuccessFetch, null, SearchPropName.components);
  };

  //handle add/remove click
  handleComponentAction = (e, row, type) => {
    e?.stopPropagation();
    const { currentLevelID } = this.state;
    const { updateComponentHierarchyComponent } = this.props;
    const { t } = this.context;
    if (type === componentActions.assign && !currentLevelID) {
      this.setState({
        notificationData: {
          isDisplayed: true,
          type: 'error',
          icon: 'close',
          text: t('ASSIGN_COMPONENTS.ASSIGN_ERROR_NO_LEVEL'),
          onClickClose: this.closeNotification,
        },
      });
      this.closeNotificationTimeout();
      return;
    }
    const reqObj = {
      [componentActionsProps.hierarchyID]: type === componentActions.unassign ? null : currentLevelID,
      [componentActionsProps.componentID]: row?.ID,
    };
    const onSuccessAction = () => {
      const { components, unassignedComponents, componentsTotalItems, unassignedComponentsTotalItems, unassignedComponentsFilters, componentsFilters } = this.state;
      const newUnassigned = Object.assign([], unassignedComponents);
      const newComponents = Object.assign([], components);
      let newComponentsTotalItems = 0,
        newUnassignedComponentsTotalItems = 0;

      if (type === componentActions.assign) {
        remove(newUnassigned, { ID: row?.ID });
        newComponents.push(row);
        newUnassignedComponentsTotalItems = unassignedComponentsTotalItems - 1;
        newComponentsTotalItems = componentsTotalItems + 1;
      } else {
        remove(newComponents, { ID: row?.ID });
        newUnassigned.push(row);
        newUnassignedComponentsTotalItems = unassignedComponentsTotalItems + 1;
        newComponentsTotalItems = componentsTotalItems - 1;
      }
      //Checking if there are more items to fetch after the last item from state is delete
      if (type === componentActions.assign && unassignedComponentsFilters[filterProps.hasNext] && newUnassigned.length === 0) {
        this.fetchUnassignedComponents(defaultUnassignedFilters);
      } else if (type === componentActions.unassign && componentsFilters[filterProps.hasNext] && newComponents.length === 0) {
        const newParams = {
          ...componentsFilters,
          [filterProps.lastSeen]: defaultLevelFilters[filterProps.lastSeen],
        };
        this.fetchComponents(newParams);
      } else {
        this.setState({
          unassignedComponents: newUnassigned,
          components: newComponents,
          componentsTotalItems: newComponentsTotalItems,
          unassignedComponentsTotalItems: newUnassignedComponentsTotalItems,
        });
      }
    };
    updateComponentHierarchyComponent(reqObj, onSuccessAction);
  };

  //handle click
  handleComponentsClick = row => {
    const { fetchComponentDetails, fetchComponentProperties } = this.props;
    const { t } = this.context;
    fetchComponentDetails([{ component_id: row?.ID }], this.onSuccessFetch, t);
    fetchComponentProperties(row.ID, this.onSuccessFetch);
  };

  //handle toolbar click
  handleToolbarClick = (_, e) => {
    const { toolbarCollapsed, selectedComponent } = this.state;
    e?.stopPropagation();
    if (!selectedComponent) return;
    this.setState({ toolbarCollapsed: !toolbarCollapsed });
  };

  render() {
    const { t } = this.context;
    const { user, location } = this.props;
    const { search } = location;
    const backButtonPath = user?.FullScreenEnabled ? routesConstants.routes.protectedRoutes.fullScreen.fullPath : routesConstants.routes.protectedRoutes.inspections.fullPath;
    const {
      componentLevels,
      TotalItems,
      levelsSearchText,
      unassignedComponents,
      unassignedComponentsTotalItems,
      unassignedSearchText,
      unassignedComponentsFilters,
      filters,
      levelPath,
      isLoading,
      isLoadingUnassignedComponents,
      components,
      componentsTotalItems,
      isLoadingComponents,
      componentsFilters,
      componentsSearchText,
      notificationData,
      toolbarCollapsed,
      selectedComponentData,
      selectedComponent,
      isLoadingSelectedComponentData,
      selectedComponentProperties,
    } = this.state;
    const isNested = levelPath.length > 1;

    return (
      <div className="default-background assign-components">
        <div className="assign-components__left-side">
          <div className="assign-components__left-side__page-nav">
            <PageNavigation backButtonPath={`${backButtonPath}${search}`} title={t('ASSIGN_COMPONENTS.TITLE')} icon="components" withBottomBorder />
          </div>
          <div className="assign-components__left-side__unassigned-comp">
            <div className="assign-components__left-side__unassigned-comp__title">
              <h5 className="f-primary">{t('COMPONENTS.UNASSIGNED_COMPONENTS')}</h5>
            </div>
            <div className="assign-components__left-side__unassigned-comp__table">
              <TableTemplate
                {...{
                  data: unassignedComponents,
                  tableConfig: unassignedTableConfig,
                  filters: unassignedComponentsFilters,
                  TotalItems: unassignedComponentsTotalItems,
                  handleFormatCells: formatUnassignedComponentCells,
                  selectedItem: selectedComponent,
                  searchPlaceholder: t('ASSIGN_COMPONENTS.SEARCH_UNASSIGNED_COMPONENTS'),
                  loadMoreOnClick: this.loadMoreUnassigned,
                  searchInputOnChange: this.handleInputUnassignedChange,
                  searchInputValue: unassignedSearchText,
                  sortDirection: filterProps.sortDirection,
                  sortByColumn: filterProps.sortByColumn,
                  onSortClick: this.onUnassignedColumnSort,
                  customTableClass: 'unassigned-table',
                  isLoading: isLoadingUnassignedComponents,
                  noDataText: t('ASSIGN_COMPONENTS.COMPONENT_EMPTY_STATE'),
                  onDropdownClick: this.handleComponentAction,
                  onRowClick: this.handleComponentsClick,
                  hasNext: unassignedComponentsFilters[filterProps.hasNext],
                }}
              />
            </div>
          </div>
        </div>
        <div className={`assign-components__right-side ${toolbarCollapsed ? '' : 'toolbar-opened'}`}>
          <div className="assign-components__right-side__title">
            <h5 className="f-primary">{t('ASSIGN_COMPONENTS.SELECT_LEVEL')}</h5>
          </div>
          <div className={`assign-components__right-side__path ${isNested ? 'show' : 'hide'}`}>
            <PathViewer levels={levelPath} onBackClick={this.handlePathBackClick} onPathClick={this.handlePathClick} />
          </div>
          <div className={`assign-components__right-side__body ${isNested ? 'has-path' : ''}`}>
            <DoubleTable
              firstTableProps={{
                data: componentLevels,
                tableConfig: firstTableConfig,
                filters: filters,
                TotalItems: TotalItems,
                handleFormatCells: formatLevelCells,
                selectedItem: null,
                searchPlaceholder: t('ASSIGN_COMPONENTS.SEARCH_LEVEL'),
                loadMoreOnClick: this.loadMoreLevels,
                searchInputOnChange: this.handleInputLevelChange,
                searchInputValue: levelsSearchText,
                sortDirection: filterProps.sortDirection,
                sortByColumn: filterProps.sortByColumn,
                onSortClick: this.onLevelColumnSort,
                customTableClass: 'level-table',
                onRowClick: this.handleRowClick,
                isLoading,
                noDataText: t('ASSIGN_COMPONENTS.LEVEL_EMPTY_STATE'),
                hasNext: filters[filterProps.hasNext],
              }}
              secondTableProps={{
                data: components,
                tableConfig: secondTableConfig,
                filters: componentsFilters,
                TotalItems: componentsTotalItems,
                handleFormatCells: formatComponentCells,
                selectedItem: selectedComponent,
                searchPlaceholder: t('ASSIGN_COMPONENTS.SEARCH_COMPONENTS', { currentLevel: `${levelPath[levelPath.length - 1]?.Code} ${levelPath[levelPath.length - 1]?.Name}` }),
                searchInputValue: componentsSearchText,
                sortDirection: filterProps.sortDirection,
                sortByColumn: filterProps.sortByColumn,
                isLoading: isLoadingComponents,
                noDataText: t('ASSIGN_COMPONENTS.COMPONENT_EMPTY_STATE'),
                onDropdownClick: this.handleComponentAction,
                searchInputOnChange: this.handleInputComponentsChange,
                onSortClick: this.onComponentsColumnSort,
                loadMoreOnClick: this.loadMoreComponents,
                customTableClass: 'components-table',
                onRowClick: this.handleComponentsClick,
                hasNext: componentsFilters[filterProps.hasNext],
              }}
            />
          </div>
        </div>
        <Notification {...notificationData} />
        <Toolbar
          collapsed={toolbarCollapsed}
          placement={placements.right}
          resizable={false}
          toggleToolbar={this.handleToolbarClick}
          children={<DetailsForm initialValues={selectedComponentData} isLoading={isLoadingSelectedComponentData} properties={selectedComponentProperties} />}
        />
      </div>
    );
  }
}
AssignComponents.contextTypes = {
  t: PropTypes.func.isRequired,
};
const mapStateToProps = state => {
  return {
    user: state.userReducer,
  };
};
const mapDispatchToProps = dispatch => ({
  fetchComponentHierarchyLevels: (data, callback, loadMore, componentLevels) => dispatch(fetchComponentHierarchyLevels(data, callback, loadMore, componentLevels)),
  fetchUnassignedComponents: (data, callback, loadMore, unassignedComponents) => dispatch(fetchUnassignedComponents(data, callback, loadMore, unassignedComponents)),
  fetchComponents: (data, callback, loadMore, components) => dispatch(fetchComponents(data, callback, loadMore, components)),
  updateComponentHierarchyComponent: (data, callback) => dispatch(updateComponentHierarchyComponent(data, callback)),
  fetchComponentDetails: (data, callback, t) => dispatch(fetchComponentDetails(data, callback, t)),
  fetchComponentProperties: (id, callback) => dispatch(fetchComponentProperties(id, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignComponents);
