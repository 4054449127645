import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import { FORMS } from '../../../common/constants';

import Helpers from '../../../common/helpers';
import routesConstants from '../../../common/routes-constants';

import { validate } from '../validators/request-reset-password-validatiors';
import constants from '../constants/request-reset-password-constants';
import CustomInput from '../../../common/form/components/input';
import Button from '../../../common/form/components/button';

import '../styles/reset-password-form.scss';

class RequestResetPasswordForm extends Component {
  render() {
    const { t } = this.context;
    const { handleSubmit, invalid, anyTouched, disabledForm } = this.props;

    return (
      <form className="login-form" onSubmit={handleSubmit} noValidate>
        <Field
          id={constants.fields.email}
          name={constants.fields.email}
          disabled={disabledForm}
          label="RESET_PASSWORD.EMAIL"
          labelClass="f-secondary-dark"
          component={CustomInput}
          type="email"
          placeholder="RESET_PASSWORD.EMAIL"
        />
        <Button disabled={(invalid && anyTouched) || disabledForm} text={t('RESET_PASSWORD')} />
        <label onClick={() => Helpers.goTo(routesConstants.routes.unProtectedRoutes.login.fullPath)} className="login-form__link space f-secondary-dark noselect ">
          {t('REQUEST_RESET_PASSWORD.BACK_TO_LOGIN')}
        </label>
      </form>
    );
  }
}

RequestResetPasswordForm.contextTypes = {
  t: PropTypes.func.isRequired,
};
RequestResetPasswordForm = reduxForm({
  form: FORMS.requestResetPassword,
  validate,
})(RequestResetPasswordForm);

export default RequestResetPasswordForm;
