import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { debounce, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import Helpers from '../../../../../common/helpers';
import Icon from '../../../../../common/icon/components/icon';
import Modal from '../../../../../common/modal/components/modal';
import { setGenericNotification } from '../../../../../common/notification/actions/action-creators';
import { ComponentPicker, ComponentPicker3D, ComponentPickerCH, ComponentPickerPDF } from '../../../../../common/question-components/components';
import InfoTooltip from '../../../../../common/tooltip/components/info-tooltip';
import { componentDetailsDisplayProps, componentFields } from '../../work-order/constants/constants';
import { defaultComponentsFilters, filterParams } from '../constants/component-picker-constants';
import '../styles/component-picker-with-list.scss';

const ComponentPickerWithList = (
  { questionId, fetchComponents, inspectionId, setGenericNotification, selectedComponents, onComponentSelect, additionalOptions, disabled, searchPlaceholder },
  { t }
) => {
  const [components, setComponents] = useState([]);
  const [modalData, setModalData] = useState({ isOpen: false });
  const [componentFilters, setComponentFilters] = useState({});
  const [componentsSearchText, setComponentsSearchText] = useState('');
  const [keepComponentsDropdownVisible, setKeepComponentsDropdownVisible] = useState(false);

  useEffect(() => {
    getComponentsToLink(defaultComponentsFilters);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setModalData(prevState => ({
      ...prevState,
      handleComponentSelected: (_, component) => handleComponentSelect(_, component),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedComponents]);

  const handleComponentSelect = (_, component) => {
    if (!isEmpty(component) && (selectedComponents || []).findIndex(c => c?.[componentFields.id] === component[0]?.[componentFields.id]) > -1) {
      setGenericNotification({ isDisplayed: true, icon: 'alert-circle', text: t('ERROR_MODAL.1003'), iconProps: { className: 'white' } });
    } else {
      onComponentSelect(component);
      setModalData(prevState => ({
        ...prevState,
        question: { QuestionsComponents: component },
        handleComponentSelected: (_, component) => handleComponentSelect(_, component),
        handleDeleteComponent: (_, id) => handleDeleteComponent(id, component),
      }));
    }
  };

  const handleDeleteComponent = () => {
    handleComponentSelect(null, []);
  };

  const getComponentsToLink = (filters, loadMore = false) => {
    const handleGetComponentsToLink = (data, newFilters) => {
      const newData = loadMore ? [...components, ...data] : data;

      setComponents(newData);
      setComponentFilters({ ...componentFilters, ...newFilters });
      if (loadMore) {
        Helpers.scrollIntoView('items-dropdown', `row-${filters[filterParams.lastSeen] - 1}`, 0);
      }
    };
    fetchComponents({ ...filters, InspectionID: inspectionId }, handleGetComponentsToLink);
  };

  const handleComponentSearchTextChanged = SearchText => {
    setKeepComponentsDropdownVisible(true);
    setComponentsSearchText(SearchText);
    componentsSearchTextChanged(SearchText);
  };

  const onSearch = (SearchText, filters, apiCall, additionalFilters = {}) => {
    const newFilters = {
      ...filters,
      ...additionalFilters,
      [filterParams.lastSeen]: 0,
      SearchText,
    };
    apiCall && typeof apiCall === 'function' && apiCall(newFilters);
  };

  const handleComponentSearch = SearchText => onSearch(SearchText, componentFilters, getComponentsToLink);

  const componentsSearchTextChanged = debounce(handleComponentSearch, 300);

  const loadMoreComponents = () => {
    setKeepComponentsDropdownVisible(true);
    getComponentsToLink(componentFilters, true);
  };

  const closeModalAction = () => {
    setModalData({ isOpen: false });
  };

  const handleComponentPicker3D = () => {
    setModalData({
      isOpen: true,
      CustomContent: dynamicProps => <ComponentPicker3D {...dynamicProps} inspectionId={inspectionId} closeAction={closeModalAction} />,
      customClassName: 'modal-no-max-height modal-large dms-component-picker-pdf-3d',
      question: { QuestionsComponents: selectedComponents },
      handleComponentSelected: handleComponentSelect,
      handleDeleteComponent: () => handleDeleteComponent(),
      type: 'none',
      closeAction: closeModalAction,
    });
  };

  const handleComponentPickerPDF = () => {
    setModalData({
      isOpen: true,
      CustomContent: dynamicProps => <ComponentPickerPDF {...dynamicProps} inspectionId={inspectionId} closeAction={closeModalAction} />,
      customClassName: 'modal-no-max-height modal-large dms-component-picker-pdf-3d',
      question: { QuestionsComponents: selectedComponents },
      handleComponentSelected: handleComponentSelect,
      handleDeleteComponent: () => handleDeleteComponent(),
      type: 'none',
      closeAction: closeModalAction,
    });
  };

  const handleComponentPickerCH = () => {
    setModalData({
      isOpen: true,
      CustomContent: dynamicProps => <ComponentPickerCH {...dynamicProps} inspectionId={inspectionId} closeAction={closeModalAction} />,
      customClassName: 'modal-no-max-height modal-large',
      type: 'none',
      title: t('QUESTION_COMPONENT_PICKER.CHOOSE_COMPONENTS'),
      closeAction: closeModalAction,
      customCloseAction: closeModalAction,
      question: { QuestionsComponents: selectedComponents },
      handleComponentSelected: handleComponentSelect,
      handleDeleteComponent: () => handleDeleteComponent(),
    });
  };

  return (
    <div className="component-picker-with-list">
      <div className="input-with-dropdown">
        {!disabled && (
          <ComponentPicker
            components={components}
            questionId={questionId}
            handleComponentSelected={handleComponentSelect}
            handleComponentPickerPDF={handleComponentPickerPDF}
            handleComponentPicker3D={handleComponentPicker3D}
            handleComponentPickerCH={handleComponentPickerCH}
            selectedComponents={selectedComponents}
            // searchApiRequired because of lazy loading and pagination when loading components for DMS file
            searchApiRequired={true}
            handleAPIComponentSearch={handleComponentSearchTextChanged}
            componentsFilterProps={componentFilters}
            loadMoreOnClick={loadMoreComponents}
            componentsSearchText={componentsSearchText}
            keepComponentsDropdownVisible={keepComponentsDropdownVisible}
            additionalOptions={additionalOptions}
            searchPlaceholder={searchPlaceholder}
          />
        )}
      </div>
      {selectedComponents && selectedComponents.length > 0 && (
        <div className="selected-components">
          <div className="selected-components-container">
            {selectedComponents.map((component, index) => (
              <div className="component-info-wrapper" key={`file-component-${index}`}>
                <div className="component-item">
                  <div className="component-name-container">
                    <p className="f-primary ">{index + 1}.</p>
                    <p className="f-primary name">{component?.Name}</p>
                    <InfoTooltip
                      actionsMenu={Helpers.mapInfoIconDisplayProps(component, componentDetailsDisplayProps)}
                      offsetY={8}
                      offsetX={8}
                      Component={() => <Icon name="info" size="sm" />}
                      componentProps={{ title: '' }}
                      containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
                    />
                  </div>
                  <div className="component-delete-container">
                    <Icon
                      name="close"
                      className="delete-action"
                      onClick={() => {
                        handleDeleteComponent();
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <Modal
        {...modalData}
        // in order to lift state up and avoid using reducer, the following states and methods are propagated directly to modal component
        searchApiRequired={true}
        handleAPIComponentSearch={handleComponentSearchTextChanged}
        components={components}
        componentsFilterProps={componentFilters}
        componentsSearchText={componentsSearchText}
        keepComponentsDropdownVisible={keepComponentsDropdownVisible}
        loadMoreOnClick={loadMoreComponents}
      />
    </div>
  );
};

ComponentPickerWithList.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  setGenericNotification: data => dispatch(setGenericNotification(data)),
});

export default connect(null, mapDispatchToProps)(ComponentPickerWithList);
