export default {
  SET_USERS_DATA: 'setUsersData',
  SET_USERS_DATA_TABLE_PARAMS: 'setUsersDataTableParams',
  HANDLE_ACTIVATE_USER_MODAL: 'handleActivateUserModal',
  DELETE_USER: 'handleDeleteUSer',
  SET_TEAMS: 'handleSetTeams',
  AMEND_TEAMS: 'handleAmendTeams',
  UPDATE_TEAM_BY_PROP: 'handleUpdateTeamByProp',
  REMOVE_TEAM_BY_PROP: 'handleRemoveTeamByProp',
  SET_TEAMS_LOADING: 'handleSetTeamsLoading',
  HANDLE_ADD_TEAM: 'handleAddTeam',
  SET_ROLES: 'handleSetRoles',
  AMEND_ROLES: 'handleAmendRoles',
  REMOVE_ROLE_BY_PROP: 'handleRemoveRoleByProp',
  SET_ROLE_LOADING: 'hanleSetRoleLoading',
  HANDLE_ADD_ROLE: 'handleAddRole',
  UPDATE_ROLE_BY_PROP: 'handleUpdateRoleByProp',
  HANDLE_USER_MODAL: 'handleUserModal',
  SET_ROLE_LIST_DATA: 'setRoleListData',
};
