import { defectNotificationFields } from '../constants/constants';

export const validate = values => {
  const errors = {};

  if (!values[defectNotificationFields.name]) {
    errors[defectNotificationFields.name] = 'NOTIFICATION.NAME_REQUIRED';
  }

  if (values[defectNotificationFields.name] && values[defectNotificationFields.name].length < 3) {
    errors[defectNotificationFields.name] = 'NOTIFICATION.NAME_MIN_SIZE';
  }

  if (values[defectNotificationFields.name] && values[defectNotificationFields.name].length > 100) {
    errors[defectNotificationFields.name] = 'NOTIFICATION.NAME_MAX_SIZE';
  }

  return errors;
};
