import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../styles/input.scss';
import Icon from '../../icon/components/icon';

const PasswordInput = (props, { t }) => {
  const {
    input,
    meta,
    placeholder,
    className,
    additionalClassName,
    id,
    label,
    labelClass,
    disabled,
    isHidden,
    max,
    autoFocus,
    size,
    withHiddenError,
    onlyDisabled,
    isRequired,
    handleChange,
    inputProps,
  } = props;

  const [showPassword, setShowPassword] = useState(false);

  const onEyeIconClick = e => {
    e.stopPropagation();
    setShowPassword(!showPassword);
  };

  const onChange = e => {
    input.onChange(e.target.value);

    if (handleChange) {
      handleChange(e.target.value);
    }
  };
  return (
    <div className={`field-wrapper${disabled ? ' field-wrapper--disabled' : ''}${onlyDisabled ? ' field-wrapper--only-disabled' : ''} ${isHidden ? 'hidden-field' : ''}`}>
      {label && (
        <label className={`field-wrapper__label ${labelClass}`} htmlFor={id}>
          {`${t(label)}${isRequired ? '*' : ''}`}
        </label>
      )}
      <div className="input-wrapper">
        <div className="password-input-wrapper">
          <input
            id={id}
            disabled={disabled}
            readOnly={disabled}
            {...input}
            {...inputProps}
            onChange={onChange}
            max={max}
            type={showPassword ? 'text' : 'password'}
            autoCorrect="off"
            spellCheck={false}
            placeholder={placeholder ? t(placeholder) : ''}
            className={(meta?.error && meta.touched ? 'invalid ' : '') + `${className || 'input-wrapper__input'} ${size} ${additionalClassName} has-password-icon`}
            autoFocus={autoFocus}
          />
          <Icon name={showPassword ? 'not-visible' : 'eye'} className={`password-input-icon ${size} `} size="sm" onClick={onEyeIconClick} />
        </div>

        <div className={`error-message${meta?.error && meta.touched ? '--visible' : ''} ${withHiddenError ? 'with-hidden-error-message' : ''}`}>{meta?.error && t(meta.error)}</div>
      </div>
    </div>
  );
};

PasswordInput.propTypes = {
  id: PropTypes.string.isRequired,
  input: PropTypes.object,
  meta: PropTypes.object,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  labelClass: PropTypes.string,
  additionalClassName: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  isHidden: PropTypes.bool,
  autoFocus: PropTypes.bool,
  max: PropTypes.number,
  size: PropTypes.oneOf(['sm', 'lg']),
  withHiddenError: PropTypes.bool,
};

PasswordInput.defaultProps = {
  placeholder: PropTypes.string,
  className: '',
  labelClass: '',
  additionalClassName: '',
  disabled: false,
  isHidden: false,
  autoFocus: false,
  size: 'lg',
  withHiddenError: false,
};

PasswordInput.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default PasswordInput;
