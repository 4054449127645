import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';

import ToggleField from '../../../../common/form/components/toggle-field';
import Button from '../../../../common/form/components/button';

import { FORMS } from '../../../../common/constants';
import { pdfDownloadOptions } from '../../constants/work-order-constants';

class DownloadWorkOrderPdfOptions extends Component {
  render() {
    const { t } = this.context;
    const { closeAction, handleSubmit, submitForm } = this.props;

    return (
      <form
        className="include-options-form"
        onSubmit={handleSubmit(vals => {
          submitForm(vals);
        })}
      >
        <label className="include-label">{t('ISOLATION_CERTIFICATE.INCLUDE_LABEL')}</label>
        <div className="download-option">
          <Field
            id={pdfDownloadOptions.includeChecklists.name}
            name={pdfDownloadOptions.includeChecklists.name}
            component={ToggleField}
            label={t('WORK_ORDER.INCLUDE_CHECKLISTS')}
            className={'multiple-labels-toggle'}
          />
        </div>
        <div className="buttons">
          <Button
            onClick={handleSubmit(vals => {
              submitForm(vals);
            })}
            type="button"
            text={t('GENERATE_AND_DOWNLOAD')}
            className="modal__button modal__button_red"
          />
          <Button onClick={closeAction} type="button" text={t('CANCEL')} variant="gray-outline" />
        </div>
      </form>
    );
  }
}
DownloadWorkOrderPdfOptions.contextTypes = {
  t: PropTypes.func.isRequired,
};

DownloadWorkOrderPdfOptions = reduxForm({
  form: FORMS.downloadWorkOrderPdfOptions,
  enableReinitialize: true,
})(DownloadWorkOrderPdfOptions);

export default connect(null, null)(DownloadWorkOrderPdfOptions);
