export const defaultStroke = '#01f6a5';

export const DefaultDrawing = {
  version: '0.0.1',
  objects: [
    {
      type: 'rect',
      version: '0.0.1',
      originX: 'left',
      originY: 'top',
      left: 29.21,
      top: 47.61,
      width: 160,
      height: 120,
      fill: 'transparent',
      stroke: defaultStroke,
      strokeWidth: 4,
      strokeDashArray: null,
      strokeLineCap: 'butt',
      strokeDashOffset: 0,
      strokeLineJoin: 'miter',
      strokeMiterLimit: 4,
      scaleX: 0.08,
      scaleY: 0.08,
      angle: 0,
      flipX: false,
      flipY: false,
      opacity: 0.8,
      shadow: null,
      visible: true,
      clipTo: null,
      backgroundColor: '',
      fillRule: 'nonzero',
      paintFirst: 'fill',
      globalCompositeOperation: 'source-over',
      transformMatrix: null,
      skewX: 0,
      skewY: 0,
      rx: 0,
      ry: 0,
      strokeUniform: true,
      noScaleCache: false,
      transparentCorners: false,
      hasRotatingPoint: false,
    },
  ],
  canvasWidth: 138,
  canvasHeight: 92,
};

export const DrawingRequirements = {
  width: 4,
  height: 4,
  canvasWidth: 382,
  canvasHeight: 287,
};
