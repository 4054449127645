export const formConstants = {
  fields: {
    displayPredictive: 'DisplayPredictiveAnalytics',
    predictiveType: 'PredictiveType',
    predictiveTypeID: 'PredictiveTypeID',
    dateRange: 'DateRange',
    dateFrom: 'DateFrom',
    dateTo: 'DateTo',
    removeOutliers: 'RemoveOutliers',
    removeZeroValues: 'RemoveZeroValues',
  },
};

export const predictiveResults = {
  fields: {
    alarmType: 'AlarmType',
    alarmColor: 'Colour',
    date: 'TriggeredDate',
  },
};

export const dateRangeOptions = {
  ALL: {
    isActive: false,
  },
  SPECIFIC: {
    isActive: false,
  },
};

export const defaultPredictiveSettings = {
  [formConstants.fields.displayPredictive]: false,
  [formConstants.fields.predictiveTypeID]: 1,
  [formConstants.fields.dateRange]: 'ALL',
  [formConstants.fields.dateFrom]: null,
  [formConstants.fields.dateTo]: null,
};
