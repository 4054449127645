import { remove, isEmpty, cloneDeep, pick } from 'lodash';

import explosiveZonesApi from '../../../api/explosive-zones-api/actions';
import { formConstants, zoneAreaConstants } from '../constants/explosive-zones-constants';
import { formConstants as inspectionSettingsConstants } from '../constants/inspection-settings';
import {
  handleDeleteExplosiveZoneModal,
  setExplosiveZones,
  setExplosiveZoneAreas,
  amendExplosiveZones,
  setExplosiveZonesLodaing,
  seExplosiveZoneDetailsLoading,
  setInspectionExplosiveZonesClustered,
} from './action-creators';
import axios from 'axios';

let cancelToken;

export const getExplosiveZones = (InspectionID, SearchText = '', optionalParams = {}, loadMore = false, callback) => {
  return async dispatch => {
    if (!InspectionID) {
      return;
    }
    try {
      dispatch(setExplosiveZonesLodaing(true));

      const res = await explosiveZonesApi.getExplosiveZones({ InspectionID, SearchText, ...optionalParams });
      const { Zones, ...restProps } = res.data.Data;

      const zones = (Zones || []).map(el => ({
        ...el,
        [formConstants.fields.zoneAreas]: (el[formConstants.fields.zoneAreas] || []).map(zoneArea => ({
          ...zoneArea,
          [zoneAreaConstants.fields.modelDetails]: JSON.parse(isEmpty(zoneArea[zoneAreaConstants.fields.modelDetails]) ? 'null' : zoneArea[zoneAreaConstants.fields.modelDetails]),
        })),
        visible: true,
      }));

      if (loadMore) {
        dispatch(amendExplosiveZones(zones));
      } else {
        dispatch(setExplosiveZones(zones));
      }

      callback && callback(restProps);

      dispatch(setExplosiveZonesLodaing(false));
    } catch (err) {
      dispatch(setExplosiveZonesLodaing(false));
    }
  };
};

export const getExplosiveZoneDetails = (zoneId, callback) => {
  return async dispatch => {
    dispatch(seExplosiveZoneDetailsLoading(true));
    try {
      let res = await explosiveZonesApi.getExplosiveZoneDetails([{ atmospheric_zone_id: zoneId }]);
      const { Data } = res.data;

      callback && callback(Data);
      dispatch(seExplosiveZoneDetailsLoading(false));
    } catch (err) {
      dispatch(seExplosiveZoneDetailsLoading(false));
    }
  };
};

export const addExplosiveZone = (InspectionID, currentZones, data, callback) => {
  return async dispatch => {
    const newZones = [...currentZones, { ...data, visible: true }];
    try {
      // Append element in explosive zones array
      dispatch(setExplosiveZones(newZones));
      const res = await explosiveZonesApi.createExplosiveZone({ InspectionID, ...data });
      const { Data } = res.data;
      if (Data) {
        dispatch(updateZoneInArray(data[formConstants.fields.id], Data, newZones));
        callback && callback(Data);
      } else {
        dispatch(deleteZoneInArray(data[formConstants.fields.id], newZones));
      }
    } catch (e) {
      dispatch(deleteZoneInArray(data[formConstants.fields.id], newZones));
      console.log(e);
    }
  };
};

export const updateExplosiveZone = data => {
  return async (dispatch, getState) => {
    const { zones } = getState().explosiveZonesReducer;
    try {
      const res = await explosiveZonesApi.updateExplosiveZone(data);
      const { Data } = res.data;

      dispatch(updateZoneInArray(data[formConstants.fields.id], Data, zones));
    } catch (e) {
      console.log(e);
    }
  };
};

export const deleteExplosiveZone = (data, callback = null, deleteOnlyInternal) => {
  return async (dispatch, getState) => {
    const { zones } = getState().explosiveZonesReducer;
    try {
      if (!deleteOnlyInternal) {
        await explosiveZonesApi.deleteExplosiveZone({ AtmosphericZoneID: data[formConstants.fields.id] });
        dispatch(
          handleDeleteExplosiveZoneModal({
            isOpen: false,
          })
        );
      }

      dispatch(deleteZoneInArray(data[formConstants.fields.id], zones));

      if (callback) callback(data, 'delete');
    } catch (e) {
      console.log(e);
      dispatch(
        handleDeleteExplosiveZoneModal({
          isOpen: false,
        })
      );
    }
  };
};

// ZONE AREAS
export const getZoneAreas = data => {
  return async dispatch => {
    try {
      dispatch(setExplosiveZoneAreas([]));

      const res = await explosiveZonesApi.getExplosiveZoneAreas(data);
      const { Data } = res.data;
      if (!isEmpty(Data)) {
        dispatch(
          setExplosiveZoneAreas(
            (Data || []).map(el => ({
              ...el,
              [zoneAreaConstants.fields.modelDetails]: JSON.parse(isEmpty(el[zoneAreaConstants.fields.modelDetails]) ? 'null' : el[zoneAreaConstants.fields.modelDetails]),
            }))
          )
        );
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const createZoneArea = (data, AtmosphericZoneID) => {
  return async (dispatch, getState) => {
    const { zones, zoneAreas } = getState().explosiveZonesReducer;
    const selectedZone = zones.find(el => el[formConstants.fields.id] === AtmosphericZoneID);
    if (isEmpty(selectedZone)) {
      return;
    }

    try {
      const reqPayload = { ...data, [zoneAreaConstants.fields.modelDetails]: JSON.stringify(data[zoneAreaConstants.fields.modelDetails]) };
      const res = await explosiveZonesApi.createExplosiveZoneArea({ AtmosphericZoneID, ...reqPayload });
      const { Data } = res.data;
      if (Data) {
        // Append element in explosive zones areas array
        const newZoneAreas = [
          ...zoneAreas,
          { ...Data, [zoneAreaConstants.fields.modelDetails]: JSON.parse(isEmpty(Data[zoneAreaConstants.fields.modelDetails]) ? 'null' : Data[zoneAreaConstants.fields.modelDetails]) },
        ];
        dispatch(setExplosiveZoneAreas(newZoneAreas));
        dispatch(updateZoneInArray(AtmosphericZoneID, { ...selectedZone, [formConstants.fields.zoneAreas]: newZoneAreas }, zones));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateZoneArea = (data, AtmosphericZoneID) => {
  return async (dispatch, getState) => {
    const { zones, zoneAreas } = getState().explosiveZonesReducer;
    const selectedZone = zones.find(el => el[formConstants.fields.id] === AtmosphericZoneID);
    if (isEmpty(selectedZone)) {
      return;
    }
    try {
      const reqPayload = { ...data, [zoneAreaConstants.fields.modelDetails]: JSON.stringify(data[zoneAreaConstants.fields.modelDetails]) };
      const res = await explosiveZonesApi.updateExplosiveZoneArea({ AtmosphericZoneID, ...reqPayload });
      const { Data } = res.data;
      if (Data) {
        const foundIndex = zoneAreas.findIndex(area => area[formConstants.fields.id] === data[formConstants.fields.id]);
        if (foundIndex > -1) {
          const newZoneAreas = cloneDeep(zoneAreas);
          newZoneAreas[foundIndex] = {
            ...Data,
            [zoneAreaConstants.fields.modelDetails]: JSON.parse(isEmpty(Data[zoneAreaConstants.fields.modelDetails]) ? 'null' : Data[zoneAreaConstants.fields.modelDetails]),
          };
          dispatch(setExplosiveZoneAreas(newZoneAreas));
          dispatch(updateZoneInArray(AtmosphericZoneID, { ...selectedZone, [formConstants.fields.zoneAreas]: newZoneAreas }, zones));
        } else {
          console.warn('Something went wrong! Updated element not found in referenced array');
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const deleteZoneArea = (data, AtmosphericZoneID) => {
  return async (dispatch, getState) => {
    const { zones, zoneAreas } = getState().explosiveZonesReducer;
    const selectedZone = zones.find(el => el[formConstants.fields.id] === AtmosphericZoneID);
    if (isEmpty(selectedZone)) {
      return;
    }
    try {
      await explosiveZonesApi.deleteExplosiveZoneArea({ ZoneID: data[formConstants.fields.id] });
      const newZoneAreas = cloneDeep(zoneAreas);
      remove(newZoneAreas, area => area[formConstants.fields.id] === data[formConstants.fields.id]);
      dispatch(setExplosiveZoneAreas(newZoneAreas));
      dispatch(updateZoneInArray(AtmosphericZoneID, { ...selectedZone, [formConstants.fields.zoneAreas]: newZoneAreas }, zones));
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateZoneInArray = (id, newZoneData, currentZones) => {
  return dispatch => {
    const foundIndex = (currentZones || []).findIndex(el => el[formConstants.fields.id] === id);
    if (foundIndex > -1) {
      const newZones = cloneDeep(currentZones);
      newZones[foundIndex] = { ...newZones[foundIndex], ...newZoneData };
      dispatch(setExplosiveZones(newZones));
    } else {
      console.warn('Element with: ', id, ' ID has not been found in array');
    }
  };
};

export const deleteZoneInArray = (id, currentZones) => {
  return dispatch => {
    const newZones = cloneDeep(currentZones);
    remove(newZones, item => item[formConstants.fields.id] === id);
    dispatch(setExplosiveZones(newZones));
  };
};

export const getInspectionExplosiveZonesClustered = (inspection_id, activeLeftSidebar, searchText = '', optionalParams = {}, callback) => {
  return async (dispatch, getState) => {
    const {
      inspectionReducer: { inspectionSettings },
    } = getState();
    const clusterConfiguration = pick(inspectionSettings, [
      inspectionSettingsConstants.fields.numberOfRings,
      inspectionSettingsConstants.fields.numberOfSlices,
      inspectionSettingsConstants.fields.initialDistance,
      inspectionSettingsConstants.fields.multiplier,
    ]);
    try {
      //Check if there are any previous pending requests
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel('Operation canceled due to new request.');
      }

      //Save the cancel token for the current request
      cancelToken = axios.CancelToken.source();

      const res = await explosiveZonesApi.getExplosiveZonesClustered(
        { InspectionID: parseInt(inspection_id), SearchText: searchText, ...clusterConfiguration, ...optionalParams },
        { cancelToken: cancelToken.token }
      );
      const { Data } = res.data;

      dispatch(
        setInspectionExplosiveZonesClustered(
          (Data.Zones || []).map((el, index) => ({
            ...el,
            [formConstants.fields.zoneAreas]: (el[formConstants.fields.zoneAreas] || []).map(zoneArea => ({
              ...zoneArea,
              [zoneAreaConstants.fields.modelDetails]: JSON.parse(isEmpty(zoneArea[zoneAreaConstants.fields.modelDetails]) ? 'null' : zoneArea[zoneAreaConstants.fields.modelDetails]),
            })),
            ID: el.ID > 0 ? el.ID : `CLUSTER_${Date.now()}_${index}`,
          }))
        )
      );
      callback &&
        callback(
          (Data.Zones || []).map((el, index) => ({
            ...el,
            [formConstants.fields.zoneAreas]: (el[formConstants.fields.zoneAreas] || []).map(zoneArea => ({
              ...zoneArea,
              [zoneAreaConstants.fields.modelDetails]: JSON.parse(isEmpty(zoneArea[zoneAreaConstants.fields.modelDetails]) ? 'null' : zoneArea[zoneAreaConstants.fields.modelDetails]),
            })),
            ID: el.ID > 0 ? el.ID : `CLUSTER_${Date.now()}_${index}`,
          }))
        );
    } catch (e) {
      console.log(e);
    }
  };
};

export const getExplosiveZoneHistory = (params, history, paging, callback) => {
  return async () => {
    try {
      callback({ isLoading: true });
      const res = await explosiveZonesApi.getExplosiveZoneHistory(params);
      const data = res?.data?.Data;

      if (data?.history) {
        callback({
          isLoading: false,
          history: [...history, ...data.history],
          paging: { ...paging, HasNext: data.hasNext || false, LastSeen: data.lastSeen, TotalNumber: data.totalItems },
        });
      } else {
        callback({ isLoading: false });
      }
    } catch (e) {
      callback({ isLoading: false });
      console.log(e);
    }
  };
};
