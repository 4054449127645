import React from 'react';
import PropTypes from 'prop-types';

import HistoryItemDisplay from './history-item-display';
import RenderIf from '../../render-if/components/render-if';
import Helpers from '../../../common/helpers';
import { alarms } from '../../../app/inspections/components/ndt/constants/ndt-alarms';

import '../styles/history-item.scss';

const HistoryItem = (
  { items, events, item = {}, isFullScreen = false, moduleName = '', moduleCreateEvent = '', moduleDeleteEvent = '', moduleFileUnlinkEvent = '', redirectPath, getRedirectPath },
  { t }
) => {
  const getTitleAction = eventType => {
    const event = events[eventType];

    return event?.title ? t(event.title) : '';
  };

  const getItemTextClassName = (isDeleteEvent, linkDisabled) => {
    let textClass = `history-item__top--title${linkDisabled ? '' : '__link'}`;
    if (isDeleteEvent) {
      textClass += ' f-secondary-red';
    } else if (!linkDisabled) {
      textClass += ' f-secondary-green';
    }

    return textClass;
  };

  const getAlarmTitle = () => {
    // for NDT alarms, add the alarm type in the title
    if (alarms[item.itemName]) {
      return `${t('NDT_MEASUREMENTS.FOR')} ${t(alarms[item.itemName])}`;
    }

    return '';
  };

  const getToValue = () => {
    // when it's create/delete module, there is not "columnName"
    return [moduleCreateEvent, moduleDeleteEvent].includes(item.eventType) ? item.itemName : item.to;
  };

  const getTitle = () => {
    const historyItem = items[item.columnName];

    if (historyItem?.isCustomProp) {
      let obj = item.to || item.from;
      obj = JSON.parse(obj);

      return obj?.Name ? `"${obj.Name}"` : '';
    }

    if (historyItem?.isFile) {
      let file = item.to || item.from;
      file = JSON.parse(file);
      const fileName = file?.FileName || '';

      return Helpers.isImage(fileName) ? 'HISTORY_ITEM.IMAGE' : 'HISTORY_ITEM.FILE';
    }

    // when it's create/delete module, there is not "columnName"
    return [moduleCreateEvent, moduleDeleteEvent].includes(item.eventType) ? moduleName : items[item.columnName].title;
  };

  const isBoolean = value => {
    if (!value) {
      return false;
    }

    return value.toString() === 'true' || value.toString() === 'false';
  };

  const getBooleanValue = value => {
    return value.toString() === 'true' ? t('YES') : t('NO');
  };

  const displayItemName = () => {
    const historyItem = items[item.columnName];

    if (historyItem?.displayItemName && item.itemName && !redirectPath) {
      return ` - ${item.itemName}`;
    }

    return '';
  };

  const getChangedData = () => {
    const historyItem = items[item.columnName];
    const event = events[item.eventType];

    if (historyItem?.hideValue || event?.hideValue) {
      return null;
    }

    const additionalProps = {};

    // we need to extract values from custom props and files because they are in JSON
    if (historyItem?.isFile || historyItem?.isCustomProp) {
      let toValue = item.to || item.from;
      toValue = JSON.parse(toValue);

      if (historyItem.isFile) {
        additionalProps.to = toValue.FileName;
        additionalProps.fileName = toValue.FileName;
        additionalProps.isDeleted = item.eventType === moduleFileUnlinkEvent;
      } else {
        additionalProps.to = toValue.Value;
        additionalProps.from = '';
      }
    } else if (isBoolean(item.to) || isBoolean(item.from)) {
      additionalProps.from = isBoolean(item.from) ? getBooleanValue(item.from) : item.from;
      additionalProps.to = isBoolean(item.to) ? getBooleanValue(item.to) : item.to;
    }

    // if history item has a custom component, then display it
    if (historyItem?.component) {
      const Component = historyItem.component;
      return <Component {...item} to={getToValue()} items={items} getRedirectPath={getRedirectPath} decimals={historyItem?.decimals} {...additionalProps} />;
    }

    return <HistoryItemDisplay {...item} to={getToValue()} translatable={historyItem?.translatable} items={items} decimals={historyItem?.decimals} {...additionalProps} />;
  };

  if (!items[item.columnName] && item.eventType !== moduleCreateEvent && item.eventType !== moduleDeleteEvent) {
    return null;
  }

  const historyItem = items[item.columnName];
  const isDeleteEvent = item.eventType === moduleDeleteEvent;
  const event = events[item.eventType];

  return (
    <div className={`history-item ${isFullScreen ? 'history-item-aligned' : ''}`}>
      <div className="history-item__top">
        <p className={`f-primary history-item__top--title ${event?.titleClass ? event?.titleClass : ''}`}>
          <b>{item.modifiedByName || ''}</b>
          {` ${t(getTitleAction(item.eventType))} ${historyItem?.nonTranslatableTitle ? getTitle() : t(getTitle())}${displayItemName()} ${getAlarmTitle()}`}
          <RenderIf if={redirectPath}>
            {` - `}
            <span className={getItemTextClassName(isDeleteEvent, event?.linkDisabled)} onClick={isDeleteEvent || event?.linkDisabled ? null : () => Helpers.goTo(redirectPath)}>{`#${item.itemID} ${
              item.itemName ? `- ${item.itemName}` : ''
            }`}</span>
          </RenderIf>
        </p>
        {!isDeleteEvent && <div className="f-primary history-item__top--changed">{getChangedData()}</div>}
      </div>
      <p className={`f-secondary-dark history-item__date ${isFullScreen ? 'history-item__date-aligned' : ''}`}>{Helpers.getDateFromUnix(item.modifiedAt)}</p>
    </div>
  );
};

HistoryItem.contextTypes = {
  t: PropTypes.func.isRequired,
};

HistoryItem.propTypes = {
  items: PropTypes.object.isRequired,
  events: PropTypes.object.isRequired,
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    columnName: PropTypes.string,
    eventType: PropTypes.string.isRequired,
    itemID: PropTypes.number.isRequired,
    modifiedAt: PropTypes.number.isRequired,
    modifiedBy: PropTypes.number.isRequired,
    modifiedByName: PropTypes.string.isRequired,
    from: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    to: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  isFullScreen: PropTypes.bool,
  moduleName: PropTypes.string,
  moduleCreateEvent: PropTypes.string,
  moduleDeleteEvent: PropTypes.string,
  moduleFileUnlinkEvent: PropTypes.string,
  redirectPath: PropTypes.string,
  getRedirectPath: PropTypes.func,
};

export default HistoryItem;
