import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field, FieldArray, reduxForm } from 'redux-form';

import { concat, debounce, isEqual, keys, values } from 'lodash';

import AdvancedFilterCustomPropertyField from '../../../../../common/advanced-filter-custom-property/components/advanced-filter-custom-property';
import Button from '../../../../../common/form/components/button';
import MultiSelect from '../../../../../common/form/components/multiselect';
import CustomInputRange from '../../../components/common/input/input-range';
// import SelectUsersTeamsMultiple from '../../../../../common/user-team/components/select-users-teams-multiple';
import { FEATURES, FORMS } from '../../../../../common/constants';
import DatePicker from '../../../../../common/form/components/date-picker';
import ToggleRadioInput from '../../../../../common/form/components/toggle-radio-input-field';
import Helpers from '../../../../../common/helpers';
import SearchInput from '../../../../../common/input/components/search-input';
import RenderIf from '../../../../../common/render-if/components/render-if';
import { participantTypes } from '../../../../../common/user-team/constants/constants';
import { getUsers } from '../../../../members/actions/users-data-actions';
import { getComponentTypeSuggestions, getWorkorderPropertyNames } from '../../../actions/inspection-actions';
import { getAdditionalWorkorderTypes, getChecklistTemplatesForWorkOrderAdvancedFilters, searchContributors, searchUsersAndTeams } from '../../../actions/work-order-actions';
import { notificationTypes } from '../../../constants/notification-constants';
import { filterFields } from '../../../constants/work-order-constants';
import '../../../styles/defects-filter.scss';
import { assigneeFields } from '../../work-order/constants/constants';

const severityDefaultValue = { min: 1, max: 10 };

const excludeSpecificProp = (object, prop = filterFields.severityFilter, specificValue = severityDefaultValue) => {
  return {
    ...object,
    [prop]: !object[prop] || isEqual(object[prop], specificValue) ? null : object[prop],
  };
};

class WorkordersFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usersAndTeams: [],
      collaborators: [],
      assignees: [],
      checklists: [],
      checklistInputDisabled: true,
      additionalWorkorderTypes: [],
    };
    this.getComponentTypeSuggestionsDebounced = debounce(props.getComponentTypeSuggestions, 300);
    this.getUsersDebounced = debounce(props.getUsers, 300);
    this.getUsersAndTeams = debounce(this.handleSearchUsersAndTeams, 300);
    this.searchContributorsDebounced = debounce(this.handleSearchContributors, 300);
    this.searchChecklistsDebounced = debounce(this.handleSearchChecklist, 300);
  }

  handleSearchUsersAndTeams = SearchText => {
    const { searchUsersAndTeams, projectId } = this.props;
    searchUsersAndTeams(
      {
        SearchText,
        SortByColumn: 'UserID',
        SortDirection: 'ASC',
        LastSeen: 0,
        PerPage: 1000,
        ProjectID: projectId,
      },
      data => this.setState({ usersAndTeams: data })
    );
  };

  componentDidMount = () => {
    const { getComponentTypeSuggestions, getUsers, getAdditionalWorkorderTypes, hideComponentFilters = false } = this.props;
    !hideComponentFilters && getComponentTypeSuggestions();
    getUsers();
    this.handleSearchUsersAndTeams('');
    this.handleSearchContributors('', participantTypes.assignee);
    this.handleSearchContributors('', participantTypes.collaborator);
    getAdditionalWorkorderTypes(additionalWorkorderTypes => this.setState({ additionalWorkorderTypes }));
  };

  componentDidUpdate = prevProps => {
    const { formValues, change } = this.props;
    const { formValues: prevFormVal } = prevProps;
    if (formValues[filterFields.hasChecklistFilter] !== prevFormVal[filterFields.hasChecklistFilter]) {
      this.setState({ checklistInputDisabled: !Helpers.castToggleRadioInputAnswer(formValues[filterFields.hasChecklistFilter]) });
      if (!Helpers.castToggleRadioInputAnswer(formValues[filterFields.hasChecklistFilter])) change(filterFields.checklistIDsFilter, []);
    }
  };

  handleSearchContributors = (searchText, participantType) => {
    const { searchContributors, inspectionId } = this.props;
    searchContributors(
      {
        SearchText: searchText || '',
        ParticipantType: participantType,
        InspectionID: inspectionId,
      },
      users => {
        users = concat(users.Users || [], users.Teams || []);
        if (participantType === participantTypes.assignee) {
          this.setState({ assignees: users });
        } else {
          this.setState({ collaborators: users });
        }
      }
    );
  };

  handleAddContributor = user => {
    const { array } = this.props;
    if (user[assigneeFields.participantType] === participantTypes.assignee) {
      array.push(filterFields.assignees, user);
    } else {
      array.push(filterFields.collaborators, user);
    }
  };

  handleRemoveContributor = user => {
    const { formValues, array } = this.props;
    if (user[assigneeFields.participantType] === participantTypes.assignee) {
      const foundIndex = (formValues[filterFields.assignees] || []).findIndex(contributor => contributor[assigneeFields.id] === user[assigneeFields.id]);
      if (foundIndex > -1) {
        array.remove(filterFields.assignees, foundIndex);
      }
    } else {
      const foundIndex = (formValues[filterFields.collaborators] || []).findIndex(contributor => contributor[assigneeFields.id] === user[assigneeFields.id]);
      if (foundIndex > -1) {
        array.remove(filterFields.collaborators, foundIndex);
      }
    }
  };

  handleComponentSearchInput = e => {
    const { change } = this.props;
    const text = e.target.value;
    this.setState({ componentSearchText: text });
    change(filterFields.componentsSearchFilter, text);
  };

  handleSearchChecklist = searchText => {
    const { getChecklistTemplatesForWorkOrderAdvancedFilters, projectId } = this.props;
    getChecklistTemplatesForWorkOrderAdvancedFilters(
      {
        SearchText: searchText || '',
        ProjectID: projectId,
        // using max safe integer here so we get all of the checklists here
        [filterFields.perPage]: Number.MAX_SAFE_INTEGER,
        [filterFields.lastSeen]: 0,
        // Not sure if we use the live status here - BE returns every WO that has C&P linked
        [filterFields.statusFilter]: '',
      },
      checklists => {
        this.setState({ checklists });
      }
    );
  };

  render() {
    const { t } = this.context;
    const { collaborators, assignees, usersAndTeams, componentSearchText, checklists, checklistInputDisabled, additionalWorkorderTypes } = this.state;
    const { componentTypeSuggestions, handleSubmit, resetFilter, users, formValues, submitForm, hideComponentFilters = false, getWorkorderPropertyNames, hideCustomPropsFilter = false } = this.props;

    return (
      <form
        className="filter-form"
        onSubmit={handleSubmit(vals => {
          submitForm(Helpers.removeUnusedKeys(excludeSpecificProp(vals)));
        })}
      >
        <div className="scrollable">
          <div className="grid">
            <Field
              label={'WORKORDER_FILTERS.TYPE_FILTER'}
              placeholder={'FILTERS_NONE'}
              id={filterFields.workOrderTypeFilter}
              name={filterFields.workOrderTypeFilter}
              component={MultiSelect}
              data={values(notificationTypes) || []}
              size={'lg'}
            />
            <Field
              label={'WORKORDER_FILTERS.SUB_TYPE_FILTER'}
              placeholder={'FILTERS_NONE'}
              id={filterFields.additionalSubTypeFilter}
              name={filterFields.additionalSubTypeFilter}
              component={MultiSelect}
              data={values(additionalWorkorderTypes) || []}
              size={'lg'}
            />
          </div>
          <RenderIf if={!hideComponentFilters}>
            <div className="grid">
              <Field
                label={'WORKORDER_FILTERS.COMPONENT_TYPE'}
                placeholder={'FILTERS_NONE'}
                id={filterFields.componentTypeFilter}
                name={filterFields.componentTypeFilter}
                component={MultiSelect}
                data={componentTypeSuggestions || []}
                onSearch={text => {
                  this.getComponentTypeSuggestionsDebounced(text);
                }}
                onToggle={isOpen => {
                  if (isOpen && componentTypeSuggestions?.length === 0) {
                    this.getComponentTypeSuggestionsDebounced('');
                  }
                }}
                size={'lg'}
              />
              <div className="search-field-wrapper">
                <SearchInput
                  label={'WORKORDER_FILTERS.COMPONENT'}
                  onChange={this.handleComponentSearchInput}
                  stripped={false}
                  placeholder={t('KEYWORD')}
                  wrapperClass="filter-form-search__wrapper"
                  value={formValues[filterFields.componentsSearchFilter] ? formValues[filterFields.componentsSearchFilter] : componentSearchText}
                />
              </div>
            </div>
          </RenderIf>
          <div className="grid">
            <Field
              id={filterFields.severityFilter}
              name={filterFields.severityFilter}
              minValue={severityDefaultValue.min}
              maxValue={severityDefaultValue.max}
              isHidden={false}
              component={CustomInputRange}
              label={t('WORKORDER_FILTERS.SEVERITY')}
              labelClass="f-secondary-dark defect-form__label defect-form__label--range"
              hideBorder
              type="range"
              size={'lg'}
            />
          </div>
          <div className="grid">
            <RenderIf if={FEATURES?.contractors?.visible}>
              <Field
                label={'WORKORDER_FILTERS.ASSIGNED_TO'}
                placeholder={'WORKORDER_FILTERS.ASSIGNED_TO'}
                id={filterFields.assignedToFilter}
                name={filterFields.assignedToFilter}
                component={MultiSelect}
                data={usersAndTeams || []}
                valueField={'ID'}
                textField={'Name'}
                onSearch={text => {
                  this.getUsersAndTeams(text);
                }}
                onToggle={isOpen => {
                  if (isOpen && (!users || users.length === 0)) {
                    this.getUsersAndTeams('');
                  }
                }}
                size={'lg'}
              />
            </RenderIf>

            <Field
              label={'WORKORDER_FILTERS.CREATED_BY'}
              placeholder={'WORKORDER_FILTERS.CREATED_BY'}
              id={filterFields.createdByFilter}
              name={filterFields.createdByFilter}
              component={MultiSelect}
              data={users || []}
              valueField={'UserID'}
              textField={'UserName'}
              onSearch={text => {
                this.getUsersDebounced(text);
              }}
              onToggle={isOpen => {
                if (isOpen && (!users || users.length === 0)) {
                  this.getUsersDebounced('');
                }
              }}
              size={'lg'}
            />
          </div>
          <div className="grid">
            <Field
              label={'WORKORDER_FILTERS.ASSIGNEES'}
              placeholder={'WORKORDER_FILTERS.ASSIGNEES'}
              id={filterFields.assignees}
              name={filterFields.assignees}
              component={MultiSelect}
              data={assignees || []}
              valueField={'ID'}
              textField={'Name'}
              onSearch={text => {
                this.searchContributorsDebounced(text, participantTypes.assignee);
              }}
              onToggle={isOpen => {
                if (isOpen && (!assignees || assignees.length === 0)) {
                  this.searchContributorsDebounced('', participantTypes.assignee);
                }
              }}
              size={'lg'}
            />
            <Field
              label={'WORKORDER_FILTERS.COLLABORATORS'}
              placeholder={'WORKORDER_FILTERS.COLLABORATORS'}
              id={filterFields.collaborators}
              name={filterFields.collaborators}
              component={MultiSelect}
              data={collaborators || []}
              valueField={'ID'}
              textField={'Name'}
              onSearch={text => {
                this.searchContributorsDebounced(text, participantTypes.collaborator);
              }}
              onToggle={isOpen => {
                if (isOpen && (!collaborators || collaborators.length === 0)) {
                  this.searchContributorsDebounced('', participantTypes.collaborator);
                }
              }}
              size={'lg'}
            />
          </div>
          {/* 
          // commenting for now until new design comes in, discussed here: https://hybirdclarity.atlassian.net/browse/CLR-3970?focusedCommentId=22853
          <div className="grid">
            <SelectUsersTeamsMultiple
              handleUsersAndTeamsSearch={e => this.searchContributorsDebounced(e.target.value, participantTypes.assignee)}
              addAssignee={item => this.handleAddContributor({ ...item, ParticipantType: participantTypes.assignee })}
              removeAssignee={item => this.handleRemoveContributor({ ...item, ParticipantType: participantTypes.assignee })}
              searchLabelText={'WORKORDER_FILTERS.ASSIGNEES'}
              usersAndTeams={assignees}
              addedUsersAndTeams={formValues[filterFields.assignees]}
              showAddedUsersTeamsList={false}
            />
            <SelectUsersTeamsMultiple
              handleUsersAndTeamsSearch={e => this.searchContributorsDebounced(e.target.value, participantTypes.collaborator)}
              addAssignee={item => this.handleAddContributor({ ...item, ParticipantType: participantTypes.collaborator })}
              removeAssignee={item => this.handleRemoveContributor({ ...item, ParticipantType: participantTypes.collaborator })}
              searchLabelText={'WORKORDER_FILTERS.COLLABORATORS'}
              usersAndTeams={collaborators}
              addedUsersAndTeams={formValues[filterFields.collaborators]}
              showAddedUsersTeamsList={false}
            />
          </div> */}
          <div className="grid">
            <Field
              disabledKeyboardNavigation={true}
              dateFormat="MMMM d, yyyy"
              id={filterFields.startDateFrom}
              name={filterFields.startDateFrom}
              component={DatePicker}
              maxDate={formValues?.[filterFields.startDateTo] || null}
              popperPlacement="bottom-end"
              placeholder={'WORKORDER_FILTERS.DATE_FROM'}
              label={'WORKORDER_FILTERS.DATE_FROM'}
              isClearable={true}
              size={'lg'}
            />
            <Field
              disabledKeyboardNavigation={true}
              dateFormat="MMMM d, yyyy"
              id={filterFields.startDateTo}
              name={filterFields.startDateTo}
              component={DatePicker}
              minDate={formValues?.[filterFields.startDateFrom] || null}
              popperPlacement="bottom-end"
              placeholder={'WORKORDER_FILTERS.DATE_TO'}
              label={'WORKORDER_FILTERS.DATE_TO'}
              isClearable={true}
              size={'lg'}
            />
          </div>
          <div className="grid">
            <div>
              <Field
                id={filterFields.hasChecklistFilter}
                name={filterFields.hasChecklistFilter}
                component={ToggleRadioInput}
                label={t('WORK_ORDERS.ADVANCED_FILTER.HAS_CHECKLIST_AND_PROCEDURES_TEMPLATE')}
                className={'multiple-labels-toggle'}
              />
              <Field
                id={filterFields.hasObservationFilter}
                name={filterFields.hasObservationFilter}
                component={ToggleRadioInput}
                label={t('WORK_ORDERS.ADVANCED_FILTER.HAS_OBSERVATION')}
                className={'multiple-labels-toggle'}
              />
            </div>
            <Field
              label={'WORK_ORDERS.ADVANCED_FILTER.CHECKLIST_AND_PROCEDURES'}
              placeholder={'SEARCH'}
              id={filterFields.checklistIDsFilter}
              name={filterFields.checklistIDsFilter}
              component={MultiSelect}
              data={checklists}
              valueField={'ID'}
              textField={'Name'}
              onSearch={text => {
                this.searchChecklistsDebounced(text);
              }}
              onToggle={isOpen => {
                if (isOpen && (!checklists || checklists.length === 0)) {
                  this.handleSearchChecklist('');
                }
              }}
              size={'lg'}
              labelClass="text-transform-none"
              disabled={checklistInputDisabled}
            />
          </div>
          <RenderIf if={!hideCustomPropsFilter}>
            <FieldArray
              name={filterFields.properties}
              component={AdvancedFilterCustomPropertyField}
              fetchCustomProps={getWorkorderPropertyNames}
              form={FORMS.workordersFilter}
              formValues={formValues}
            />
          </RenderIf>{' '}
        </div>
        <div className="buttons">
          <Button type="button" height={'md'} width={'sm'} variant="gray-outline" text={t('RESET_ALL_FILTERS')} onClick={resetFilter} />
          <Button height={'md'} width={'sm'} text={t('APPLY_FILTERS', { count: keys(Helpers.removeUnusedKeys(formValues)).length || 0 })} />
        </div>
      </form>
    );
  }
}
WorkordersFilter.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  getComponentTypeSuggestions: text => dispatch(getComponentTypeSuggestions(text)),
  getUsers: SearchText => dispatch(getUsers({ SearchText })),
  getAdditionalWorkorderTypes: successCallback => dispatch(getAdditionalWorkorderTypes(successCallback)),
  searchUsersAndTeams: (params, successCallback) => dispatch(searchUsersAndTeams(params, successCallback)),
  searchContributors: (params, successCallback) => dispatch(searchContributors(params, successCallback)),
  getWorkorderPropertyNames: (searchText, callback) => dispatch(getWorkorderPropertyNames(searchText, callback)),
  getChecklistTemplatesForWorkOrderAdvancedFilters: (filters, callback) => dispatch(getChecklistTemplatesForWorkOrderAdvancedFilters(filters, callback)),
});

const mapStateToProps = (state, { initialValues }) => {
  const {
    inspectionReducer: { componentTypeSuggestions },
    usersTableReducer: { usersTableData },
  } = state;

  return {
    componentTypeSuggestions: Helpers.mapReportData(componentTypeSuggestions),
    users: usersTableData,
    formValues: excludeSpecificProp(state.form[FORMS.workordersFilter]?.values || {}),
    initialValues: {
      ...initialValues,
      [filterFields.severityFilter]: initialValues[filterFields.severityFilter] || severityDefaultValue,
    },
  };
};

WorkordersFilter = reduxForm({
  form: FORMS.workordersFilter,
  enableReinitialize: true,
})(WorkordersFilter);

export default connect(mapStateToProps, mapDispatchToProps)(WorkordersFilter);
