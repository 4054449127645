import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../../../common/icon/components/icon';
import SmallDropdown from '../../../../../common/small-dropdown/components/small-dropdown';
import RenderIf from '../../../../../common/render-if/components/render-if';
import '../../../styles/module-actions.scss';

const ModuleActions = ({ handleMaximize, handleShare, handleDownload, menuOptions = [], isDownloadDisabled, hideDownload, hideShare, hideMaximize, multipleDownload, multipleDownloadOptions }) => {
  return (
    <div className="module-actions">
      <RenderIf if={handleMaximize && !hideMaximize}>
        <Icon className="item" name="maximize" onClick={handleMaximize} />
      </RenderIf>
      <RenderIf if={handleDownload && !hideDownload}>
        <RenderIf if={multipleDownload}>
          <SmallDropdown
            className="page-navigation__wrapper__title-container__menu__dropdown"
            actionsMenu={multipleDownloadOptions}
            InfoIcon={() => <Icon className={`item ${isDownloadDisabled ? 'action-disabled' : ''}`} name="download" />}
            getClickAction={(action, b, selfClose, e) => {
              e?.preventDefault && e.preventDefault();
              selfClose();
              if (action) {
                if (typeof action === 'string') {
                  handleDownload(action);
                }
              }
            }}
            offsetX={10}
            offsetY={24}
          />
        </RenderIf>
        <RenderIf if={!multipleDownload}>
          <Icon className={`item ${isDownloadDisabled ? 'action-disabled' : ''}`} name="download" onClick={handleDownload} />
        </RenderIf>
      </RenderIf>
      <RenderIf if={handleShare && !hideShare}>
        <Icon className="item" name="share" onClick={handleShare} />
      </RenderIf>
      <RenderIf if={menuOptions && menuOptions.length > 0}>
        <SmallDropdown
          className="page-navigation__wrapper__title-container__menu__dropdown"
          actionsMenu={menuOptions}
          getClickAction={(action, b, selfClose, e) => {
            e?.preventDefault && e.preventDefault();
            selfClose();
            if (action) {
              if (typeof action === 'function') {
                action();
              }
            }
          }}
          offsetX={10}
        />
      </RenderIf>
    </div>
  );
};

ModuleActions.propTypes = {
  handleZoomIn: PropTypes.func,
  handleShare: PropTypes.func,
  handleDownload: PropTypes.func,
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      action: PropTypes.func,
      access: PropTypes.shape({
        visibleFor: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
        id: PropTypes.number,
        ownerRequiredPermission: PropTypes.string,
      }),
      isDisabled: PropTypes.bool,
      isHidden: PropTypes.bool,
    })
  ),
  isDownloadDisabled: PropTypes.bool,
  hideDownload: PropTypes.bool,
  hideShare: PropTypes.bool,
  hideMaximize: PropTypes.bool,
  multipleDownload: PropTypes.bool,
  multipleDownloadOptions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      action: PropTypes.string.isRequired,
      access: PropTypes.shape({
        visibleFor: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
        id: PropTypes.number,
        ownerRequiredPermission: PropTypes.string,
      }),
      isDisabled: PropTypes.bool,
      isHidden: PropTypes.bool,
    })
  ),
};

export default ModuleActions;
