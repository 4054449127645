import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RenderIf from '../../render-if/components/render-if';
import FlowStatus from './flow-status';

class FlowStatusWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      flowData: {},
    };
  }
  componentDidMount() {
    this.fetchInitialData();
  }
  componentDidUpdate(prevProps) {
    const { flowStatusModuleId } = this.props;
    if (flowStatusModuleId && flowStatusModuleId !== prevProps.flowStatusModuleId) {
      this.fetchInitialData();
    }
  }
  fetchInitialData = () => {
    const { getFlowStatusData, flowStatusModuleId } = this.props;
    getFlowStatusData(flowStatusModuleId, this.setFlowStatusDetails);
  };
  setFlowStatusDetails = flowData => {
    this.setState({ flowData });
  };

  render() {
    const { t } = this.context;
    const { flowData } = this.state;
    const { statuses } = this.props;

    return (
      <div className="flow-status-wrapper-content">
        <RenderIf if={!flowData || flowData?.length === 0}>
          <div className="no-flow-status">{t('FLOW_TAB.NO_FLOW_RECORDED')}</div>
        </RenderIf>
        <RenderIf if={flowData && flowData?.length > 0}>
          <div className="flow-status-wrapper">
            <div className="labels">
              <div>{t('FLOW_TAB.STATUS')}</div>
              <div>{t('FLOW_TAB.COMPLETED_BY')}</div>
            </div>
            <div className="statuses">
              {flowData?.length > 0 &&
                flowData.map((status, index) => {
                  return <FlowStatus {...status} isLastItem={flowData.length - 1 === index} key={`status-${index}`} statuses={statuses} />;
                })}
            </div>
          </div>
        </RenderIf>
      </div>
    );
  }
}

FlowStatusWrapper.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default withRouter(connect(null)(FlowStatusWrapper));
