import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { FORMS } from '../../../../common/constants';

import { isEmpty, debounce } from 'lodash';

import { validate } from '../../validators/create-user-validators';
import { formConstants } from '../../constants/constants';
import CustomInput from '../../../../common/form/components/input';
import MultiSelect from '../../../../common/form/components/multiselect';
import Button from '../../../../common/form/components/button';

class CreateUserForm extends Component {
  constructor(props) {
    super(props);

    this.getRolesDebounced = debounce(props.getRoles, 300);
  }

  componentWillUnmount() {
    this.getRolesDebounced.cancel();
  }

  handleFormKeyPress = e => {
    if (e.key === 'Enter') e.preventDefault();
  };

  render() {
    const { t } = this.context;

    const { handleSubmit, invalid, anyTouched, roleList, isEditMode, onCancel } = this.props;

    return (
      <form className="create-user-form" onSubmit={handleSubmit} onKeyPress={this.handleFormKeyPress} noValidate>
        {isEditMode && <Field id={formConstants.fields.id} name={formConstants.fields.id} component={CustomInput} type="text" isHidden />}
        <div className="form-fields">
          <Field id={formConstants.fields.name} name={formConstants.fields.name} label="CREATE_USER.FULL_NAME" component={CustomInput} type="text" placeholder="CREATE_USER.FULL_NAME" />
        </div>
        <div className="form-fields">
          <Field id={formConstants.fields.email} name={formConstants.fields.email} label="CREATE_USER.EMAIL" component={CustomInput} type="email" placeholder="CREATE_USER.EMAIL" />

          <Field id={formConstants.fields.phone} name={formConstants.fields.phone} label="CREATE_USER.PHONE" component={CustomInput} type="text" placeholder={'CREATE_USER.PHONE'} />
        </div>
        <div className="form-fields">
          <Field
            id={formConstants.fields.role}
            name={formConstants.fields.role}
            label="CREATE_USER.ROLE"
            component={MultiSelect}
            valueField={'ID'}
            textField={'Name'}
            data={roleList || []}
            onSearch={text => {
              this.getRolesDebounced(text);
            }}
            onToggle={isOpen => {
              if (isOpen && roleList?.length === 0) {
                this.getRolesDebounced('');
              }
            }}
            placeholder={t('CREATE_USER.ROLE')}
          />
        </div>
        <div className="buttons">
          <Button type="button" variant="gray-outline" width="sm" text={t('CREATE_USER.CANCEL')} onClick={() => onCancel()} />
          <Button disabled={invalid && anyTouched} width="sm" text={t('CREATE_USER.CREATE')} />
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { roleList } = state.usersTableReducer;
  const { userToEdit } = props;

  const isEditMode = !isEmpty(userToEdit);
  let initialValues = {};

  if (isEditMode) {
    initialValues = {
      [formConstants.fields.role]: userToEdit[formConstants.fields.role],
      [formConstants.fields.email]: userToEdit.UserEmail,
      [formConstants.fields.name]: userToEdit.UserName,
      [formConstants.fields.phone]: userToEdit.UserPhone,
      [formConstants.fields.id]: userToEdit.UserID,
    };
  }

  return {
    roleList,
    isEditMode,
    initialValues,
  };
};

const mapDispatchToProps = dispatch => ({});

CreateUserForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

CreateUserForm = reduxForm({
  form: FORMS.createUser,
  validate,
  enableReinitialize: true,
})(CreateUserForm);

export default connect(mapStateToProps, mapDispatchToProps)(CreateUserForm);
