export default {
  SELECT_ALL_MEASUREMENT_LOCATIONS: 'selectAllMeasurementLocations',
  DESELECT_ALL_MEASUREMENT_LOCATIONS: 'deselectAllMeasurementLocations',
  TOGGLE_MEASUREMENT_LOCATION: 'toggleMeasurementLocation',
  SET_MEASUREMENT_LOCATIONS: 'setMeasurementLocations',
  AMEND_MEASUREMENT_LOCATIONS: 'amendMeasurementLocations',
  SET_MEASUREMENT_LOCATIONS_LOADING: 'setMeasurementLocationsLoading',
  SET_MEASUREMENT_LOCATIONS_FILTER: 'setMeasurementLocationsFilter',
  SET_MEASUREMENT_LOCATION_DETAILS_LOADING: 'setMeasurementLocationDetailsLoading',
  SET_MEASUREMENT_LOCATION_FORM_STATE: 'setMeasurementLocationFormState',
  SET_MEASUREMENT_LOCATION_OBJECT_EDITING: 'setMeasurementLocationObjectEditing',
  SET_MEASUREMENT_LOCATION_OBJECT_ADDING: 'setMeasurementLocationObjectAdding',
  SET_MEASUREMENT_LOCATIONS_CLUSTERED: 'setMeasurementLocationClustered',
  UPDATE_MEASUREMENT_LOCATION_SUCCESS: 'updateMeasurementLocationSuccess',
  SET_MEASUREMENT_GROUP: 'setMeasurementGroup',
  SET_MEASUREMENT_GROUPS: 'setMeasurementGroups',
  SELECT_ALL_MEASUREMENT_POINTS: 'selectAllMeasurementPoints',
  DESELECT_ALL_MEASUREMENT_POINTS: 'deselectAllMeasurementPoints',
  TOGGLE_MEASUREMENT_POINT: 'toggleMeasurementPoint',
  SET_MEASUREMENT_POINTS: 'setMeasurementPoints',
  AMEND_MEASUREMENT_POINTS: 'amendMeasurementPoints',
  SET_MEASUREMENT_POINTS_FILTER: 'setMeasurementPointsFilter',
  SET_MEASUREMENT_POINTS_LOADING: 'setMeasurementPointsLoading',
  SET_MEASUREMENT_POINT_FORM_STATE: 'setMeasurementPointFormState',
  SET_MEASUREMENT_POINTS_CLUSTERED: 'setMeasurementPointsClustered',
  SET_MEASUREMENT_POINT_LOCATION_OBJECT_EDITING: 'setMeasurementPointLocationObjectEditing',
  SET_MEASUREMENT_POINT_LOCATION_OBJECT_ADDING: 'setMeasurementPointLocationObjectAdding',
  UPDATE_MEASUREMENT_POINT_SUCCESS: 'updateMeasurementPointSuccess',
  SET_MEASUREMENT_POINT_DETAILS_LOADING: 'setMeasurementPointDetailsLoading',
  SET_SELECTED_MEASUREMENT_LOCATION: 'setSelectedMeasurementLocation',
  SET_MEASUREMENT_GROUPS_CLUSTERED: 'setMeasurementGroupClustered',
  SELECT_ALL_MEASUREMENT_GROUPS: 'selectAllMeasurementGroups',
  DESELECT_ALL_MEASUREMENT_GROUPS: 'deselectAllMeasurementGroups',
  TOGGLE_MEASUREMENT_GROUP_VISIBILITY: 'toggleMeasurementGroupVisibility',
  SET_MEASUREMENT_LOCATION_DETAILS_ERROR_MODAL: 'setMeasurementLocationDetailsErrorModal',
};
