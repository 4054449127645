import PropTypes from 'prop-types';
import React from 'react';

import { isEmpty, uniqBy } from 'lodash';
import AccessRenderer from '../../../../../../common/access-renderer/components/access-renderer';
import TableTemplate from '../../../../../../common/double-table/components/table-template';

import Button from '../../../../../../common/form/components/button';
import { PERMISSION_TYPES, PERMISSIONS } from '../../../../../../common/permissions-constants';
import { fields } from '../../constants/constants';
import { addedMeasurementPointsDefaultFilter, addedMeasurementPointsFilterParams, selectedMeasurementPointsTableConfig } from '../../constants/measurement-point-constants';

import EmptyState from '../../../../../../common/empty-state-v2/components/empty-state';
import { formatSelectedMeasurementPoints } from '../../actions/table-formatting';
import '../../styles/added-measurement-points.scss';

const AddedMeasurementPointsTableView = (props, { t }) => {
  const { selectedClusterElement, closeAction, filters, data, handleLoadMoreClick, handleSortClick, handleSearchInputChange, searchText, isDataLoading, handleUnlinkMeasurementPoint } = props;

  const emptyStateProps = {
    emptyStateText: t('READINGS_AND_GAUGES.MEASUREMENT_POINTS.EMPTY_STATE.TITLE'),
    showButton: false,
    transparent: true,
    buttonAction: () => null,
    buttonDisabled: true,
  };

  return (
    <AccessRenderer
      visibleFor={PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].pointEdit.name}
      id={selectedClusterElement && selectedClusterElement[fields.createdBy]}
      ownerRequiredPermission={PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].pointCreate.name}
    >
      {({ hasAccess: canEdit }) => {
        return (
          <div className="selected-measurement-points-table-view">
            <div className="selected-measurement-points-table-view__content">
              <div className="selected-measurement-points-table-view__content__title">
                <h5>{t('CHECKLISTS_PROCEDURES_MANAGEMENT.ADDED_MEASUREMENT_POINTS')}</h5>
              </div>
              <TableTemplate
                customTableClass="selected-points-table"
                data={uniqBy(data, fields.id)}
                handleFormatCells={formatSelectedMeasurementPoints}
                tableConfig={selectedMeasurementPointsTableConfig}
                filters={filters}
                defaultFilters={addedMeasurementPointsDefaultFilter}
                sortByColumn={fields.sortByColumn}
                sortDirection={fields.sortDirection}
                searchPlaceholder={t('SEARCH')}
                loadMoreOnClick={handleLoadMoreClick}
                onSortClick={handleSortClick}
                searchInputOnChange={handleSearchInputChange}
                searchInputValue={searchText}
                isLoading={isDataLoading}
                searchTerm={searchText}
                hasNext={filters[addedMeasurementPointsFilterParams.hasNext]}
                TotalItems={filters[addedMeasurementPointsFilterParams.totalItems]}
                onRowClick={() => null}
                emptyStateComponent={() => <EmptyState {...emptyStateProps} />}
                resultsText={t('MEASUREMENT_LOCATION_DETAILS.MEASUREMENT_POINTS.TOTAL')}
                onDropdownClick={handleUnlinkMeasurementPoint}
                showSearchBar={!isEmpty(data) || searchText}
              />
            </div>
            <div className="close-action">
              <Button type="button" variant="success-outline" height={'md'} width={'lg'} text={t('DONE')} onClick={closeAction} />
            </div>
          </div>
        );
      }}
    </AccessRenderer>
  );
};

AddedMeasurementPointsTableView.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default AddedMeasurementPointsTableView;
