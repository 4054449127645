import { values } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import CustomDatePicker from '../../../../common/form/components/date-picker';
import CustomInput from '../../../../common/form/components/input';
import CustomSelect from '../../../../common/form/components/select';
import Textarea from '../../../../common/form/components/text-area';
import TimePicker from '../../../../common/form/components/time-picker';
import { priorities } from '../../../inspections/constants/work-order-constants';
import { formConstants } from './constants/constants';
import { formFields } from './constants/work-order-details-constants';

import '../../styles/work-order-details-form.scss';

const WorkOrderDetailsForm = ({ isPreviewMode, severityColors }, { t }) => {
  const workOrderPriorities = React.useMemo(() => {
    return values(priorities);
  }, []);

  return (
    <div className="work-order-details-form">
      <div className="double">
        <div className="split-input">
          <Field
            id={formConstants.fields.name}
            name={formConstants.fields.name}
            component={CustomInput}
            disabled={isPreviewMode}
            placeholder={t('CREATE_WORKORDER_FORM.NAME')}
            label={t('CREATE_WORKORDER_FORM.NAME')}
            type="text"
            size="lg"
            additionalClassName="text-transform-none"
            labelClass="text-transform-none"
          />
        </div>
      </div>
      <Field
        id={formConstants.fields.descripton}
        name={formConstants.fields.descripton}
        disabled={isPreviewMode}
        component={Textarea}
        placeholder={'CREATE_WORKORDER_FORM.DESCRIPTION'}
        label={'CREATE_WORKORDER_FORM.DESCRIPTION'}
        maxChars={formFields.description.maxChars}
        enableAutoResize={true}
        size="lg"
        withHiddenError
      />
      <Field
        id={formConstants.fields.toolsRequired}
        name={formConstants.fields.toolsRequired}
        disabled={isPreviewMode}
        component={Textarea}
        placeholder={'WORK_ORDER_DETAILS_FORM.TOOLS_LABEL'}
        label={'WORK_ORDER_DETAILS_FORM.TOOLS_LABEL'}
        maxChars={formFields.toolsRequired.maxChars}
        enableAutoResize={true}
        size="lg"
        withHiddenError
      />
      <div className="double">
        <div className="split-input">
          <Field
            id={formConstants.fields.severity}
            name={formConstants.fields.severity}
            data={workOrderPriorities}
            disabled={isPreviewMode}
            valueField="value"
            textField="title"
            component={CustomSelect}
            placeholder={t('CREATE_WORKORDER_FORM.SEVERITY')}
            label={t('CREATE_WORKORDER_FORM.SEVERITY')}
            valueComponent={({ item }) => (
              <div className="priority-dropdown-item without-padding">
                {item.color ? <div className="priority-icon" style={{ backgroundColor: severityColors[item.color] }}></div> : null}
                <p className="f-primary">{t(item.title)}</p>
              </div>
            )}
            optionComponent={({ dataItem, onSelect }) => {
              return (
                <div className="priority-dropdown-item" onClick={() => onSelect(dataItem.value)}>
                  {dataItem.color ? <div className="priority-icon" style={{ backgroundColor: severityColors[dataItem.color] }}></div> : null}
                  <p className="f-primary">{t(dataItem.title)}</p>
                </div>
              );
            }}
            size="lg"
          />
        </div>
      </div>
      <div className="double">
        <div className="split-input">
          <Field
            disabledKeyboardNavigation={true}
            id={formConstants.fields.startDate}
            name={formConstants.fields.startDate}
            component={CustomDatePicker}
            minDate={new Date()}
            popperPlacement="bottom-end"
            placeholder={'CREATE_WORKORDER_FORM.START_DATE'}
            label={'CREATE_WORKORDER_FORM.START_DATE'}
            size="lg"
            dateFormat="MMMM d, yyyy h:mm aa"
            timeFormat="h:mm aa"
            showTimeSelect
            disabled={isPreviewMode}
            validateUsingReduxForm
          />
        </div>
        <div className="split-input">
          <Field
            disabledKeyboardNavigation={true}
            id={formConstants.fields.endDate}
            name={formConstants.fields.endDate}
            component={CustomDatePicker}
            popperPlacement="bottom-end"
            placeholder={'CREATE_WORKORDER_FORM.END_DATE'}
            label={'CREATE_WORKORDER_FORM.END_DATE'}
            size="lg"
            dateFormat="MMMM d, yyyy h:mm aa"
            timeFormat="h:mm aa"
            showTimeSelect
            disabled={isPreviewMode}
            validateUsingReduxForm
          />
        </div>
      </div>
      <div className="double">
        <div className="split-input">
          <Field
            disabledKeyboardNavigation={true}
            id={formConstants.fields.dueDate}
            name={formConstants.fields.dueDate}
            component={CustomDatePicker}
            popperPlacement="bottom-end"
            placeholder={'CREATE_WORKORDER_FORM.DUE_DATE'}
            label={'CREATE_WORKORDER_FORM.DUE_DATE'}
            size="lg"
            dateFormat="MMMM d, yyyy h:mm aa"
            timeFormat="h:mm aa"
            showTimeSelect
            disabled={isPreviewMode}
            validateUsingReduxForm
          />
        </div>
        <div className="split-input">
          <Field
            id={formConstants.fields.estimatedWrenchTime}
            name={formConstants.fields.estimatedWrenchTime}
            component={TimePicker}
            disabled={isPreviewMode}
            label={t('CREATE_WORKORDER_FORM.ESTIMATED_WRENCH_TIME')}
            type="text"
            size="lg"
            showPlaceholderWhileValue
          />
        </div>
      </div>
    </div>
  );
};

WorkOrderDetailsForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default WorkOrderDetailsForm;
