export const formConstants = {
  fields: {
    notificationId: 'NotificationID',
    shortDescription: 'ShortDescription',
    status: 'Status',
    statusDescription: 'StatusDescription',
    notificationType: 'NotificationType',
    notificationTypeDescription: 'NotificationTypeDescription',
    priority: 'Priority',
    priorityDescription: 'PriorityDescription',
    isInternal: 'IsInternal',
    createdBy: 'CreatedBy',
    creationDateTime: 'CreationDateTime',
    lastChangedBy: 'LastChangedBy',
    lastChangeDateTime: 'LastChangeDateTime',
    longDescription: 'LongDescription',
    startDate: 'StartDate',
    endDate: 'EndDate',
    malfunctionStartDate: 'MalfunctionStartDate',
    malfunctionEndDate: 'MalfunctionEndDate',
    progressStatus: 'ProgressStatus',
    progressStatusDescription: 'ProgressStatusDescription',
    equipmentId: 'EquipmentID',
    equipmentName: 'EquipmentName',
    rootEquipmentId: 'RootEquipmentID',
    rootEquipmentName: 'RootEquipmentName',
    locationId: 'LocationID',
    breakdown: 'Breakdown',
    coordinates: 'Coordinates',
    source: 'Source',
    location: 'Location',
    operator: 'Operator',
    proposedFailureModeDesc: 'ProposedFailureModeDesc',
    confirmedFailureModeDesc: 'ConfirmedFailureModeDesc',
    systemProposedFailureModeDesc: 'SystemProposedFailureModeDesc',
    effectDesc: 'EffectDesc',
    causeDesc: 'CauseDesc',
    instructionTitle: 'InstructionTitle',
  },
};

const status = {
  new: 'NEW',
  published: 'PBD',
  completed: 'CPT',
  released: 'REL',
  inProcess: 'IPR',
  closed: 'CSD',
};

const mappedDetailsProps = {
  //SAP CONSTANTS

  // [formConstants.fields.notificationId]: {
  //   label: 'Notification ID',
  // },
  // [formConstants.fields.shortDescription]: {
  //   label: 'Short Description',
  // },
  // [formConstants.fields.status]: {
  //   label: 'Status',
  // },
  // [formConstants.fields.statusDescription]: {
  //   label: 'Status Description',
  // },
  // [formConstants.fields.notificationType]: {
  //   label: 'Notification Type',
  // },
  // [formConstants.fields.priority]: {
  //   label: 'Priority',
  // },
  // [formConstants.fields.createdBy]: {
  //   label: 'Created By',
  // },
  // [formConstants.fields.creationDateTime]: {
  //   label: 'Created at',
  // },
  // [formConstants.fields.equipmentId]: {
  //   label: 'Equipment ID',
  // },
  // [formConstants.fields.equipmentName]: {
  //   label: 'Equipment Name',
  // },
  // [formConstants.fields.locationId]: {
  //   label: 'Location ID',
  // },
  // [formConstants.fields.coordinates]: {
  //   label: 'Coordinates',
  // },
  // [formConstants.fields.progressStatus]: {
  //   label: 'Progress Status',
  // },

  // TEMP CONSTANTS
  [formConstants.fields.shortDescription]: {
    label: 'Name of Sensor',
  },
  [formConstants.fields.statusDescription]: {
    label: 'Status Description',
  },
  [formConstants.fields.notificationType]: {
    label: 'Component Attached to',
  },
  [formConstants.fields.creationDateTime]: {
    label: 'Date Installed',
  },
  [formConstants.fields.rootEquipmentName]: {
    label: 'Current Value',
  },
  [formConstants.fields.createdBy]: {
    label: 'High Alarm Level',
  },
  [formConstants.fields.equipmentId]: {
    label: 'Low Alarm Level',
  },
  [formConstants.fields.equipmentName]: {
    label: 'Excessive Rate Alarm',
  },
};

export default {
  status,
  mappedDetailsProps,
};
