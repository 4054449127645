import { filterProps } from '../constants/constants';

export const deleteAdvancedFiltersFromFilters = filters => {
  filters[filterProps.severityFilter] = [];
  filters[filterProps.dateFrom] = null;
  filters[filterProps.dateTo] = null;
  filters[filterProps.actionedFrom] = null;
  filters[filterProps.actionedTo] = null;
  filters[filterProps.closedFrom] = null;
  filters[filterProps.closedTo] = null;
  filters[filterProps.defectMainTypeFilter] = '';
  filters[filterProps.defectTypeFilter] = '';
  filters[filterProps.createdByFilter] = [];
  filters[filterProps.hasNotificationFilter] = null;
  filters[filterProps.has3DLocationFilter] = null;
  filters[filterProps.hasWorkOrder] = null;
  filters[filterProps.hasFiles] = null;
  filters[filterProps.assignees] = undefined;
  filters[filterProps.collaborators] = undefined;

  return filters;
};
