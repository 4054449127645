import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';

import { FORMS } from '../../../../common/constants';
import Button from '../../../../common/form/components/button';
import CustomDatePicker from '../../../../common/form/components/date-picker';
import Textarea from '../../../../common/form/components/text-area';
import TimePicker from '../../../../common/form/components/time-picker';
import Helpers from '../../../../common/helpers';
import { completedFormFields, fields } from './constants/work-order-right-side-constants';
import { validate } from './validators/work-order-completed-form-validator';

import { modules } from '../../constants/constants';
import '../../styles/work-order-completed-form.scss';
import EditModuleItemFilesModal from '../right-toolbar/common/edit-module-item-files-modal';

const WorkOrderCompletedForm = ({ cancelAction, handleSubmit, changeStatusInProgress, projectDMSCategories, workOrderDetails, handleFormSubmit }, { t }) => {
  const onSubmit = values => {
    values[fields.actualEndTime] = Helpers.getUnixDate(new Date(values[fields.actualEndTime]).getTime());
    handleFormSubmit(values);
  };

  const { workCompleted, recommendations, actualEndTime, actualWrenchTime } = completedFormFields;

  return (
    <div className="work-order-completed-form">
      <p className="f-secondary-dark modal-subtitle">{t('WORK_ORDER.COMPLETED_MODAL_SUBTITLE')}</p>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Field id={workCompleted.id} name={workCompleted.name} component={Textarea} placeholder={workCompleted.label} label={workCompleted.label} maxChars={workCompleted.maxChars} />
        <Field id={recommendations.id} name={recommendations.name} component={Textarea} placeholder={recommendations.label} label={recommendations.label} maxChars={recommendations.maxChars} />
        <div className="split-fields">
          <div className="split-field-item date-picker-wrapper">
            <Field
              disabledKeyboardNavigation
              id={actualEndTime.id}
              name={actualEndTime.name}
              component={CustomDatePicker}
              popperPlacement="bottom-end"
              placeholder={actualEndTime.label}
              label={actualEndTime.label}
              size="lg"
              dateFormat="MMMM d, yyyy h:mm aa"
              timeFormat="h:mm aa"
              showTimeSelect
              validateUsingReduxForm
            />
          </div>
          <div className="split-field-item time-picker-wrapper">
            <Field id={actualWrenchTime.id} name={actualWrenchTime.name} component={TimePicker} label={t(actualWrenchTime.label)} type="text" size="lg" showPlaceholderWhileValue />
          </div>
        </div>
        <div className="upload-files-container">
          <EditModuleItemFilesModal projectDMSCategories={projectDMSCategories} selectedModuleItem={workOrderDetails} moduleType={modules.workorderCompletion} overrideModuleTypeWithProp />
        </div>
        <div className="buttons-container">
          <Button type="button" variant="gray-outline" width="sm" height="md" text={t('WORK_ORDER.CANCEL_BTN')} onClick={cancelAction} disabled={changeStatusInProgress} />
          <Button height="md" className="button-submit" width="sm" text={t('WORK_ORDER.SUBMIT_BTN')} disabled={changeStatusInProgress} />
        </div>
      </form>
    </div>
  );
};

WorkOrderCompletedForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

WorkOrderCompletedForm.propTypes = {
  cancelAction: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  changeStatusInProgress: PropTypes.bool.isRequired,
  projectDMSCategories: PropTypes.array.isRequired,
  workOrderDetails: PropTypes.object.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: FORMS.workOrderCompletedForm,
  validate,
  onSubmitFail: errors => Helpers.scrollToFirstError(errors),
})(WorkOrderCompletedForm);
