import actionTypes from '../constants/action-types';

const projectEditState = {
  projectToEdit: null,
};

export const projectEditReducer = (state = projectEditState, payload) => {
  switch (payload.type) {
    case actionTypes.SET_PROJECT_EDIT:
      return {
        ...state,
        projectToEdit: payload.data,
      };
    default:
      return state;
  }
};
