import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray, reduxForm } from 'redux-form';

import { validate } from '../validators/property-validator';

import Property from './property';

import { formConstants } from '../constants/property-constants';

let CustomPropertiesForm = props => {
  const { handleChange, isDisabled, form, suggestions, deleteProperty, getNameSuggestions, clearSuggestions, properties, isEnhancedDesignCustomProp, showActions, formClassName } = props;
  return (
    <form noValidate onSubmit={e => e.preventDefault()} className={formClassName}>
      <FieldArray
        id={formConstants.fields.properties}
        isDisabled={isDisabled}
        name={formConstants.fields.properties}
        formName={form}
        component={Property}
        handleChange={handleChange}
        properties={properties}
        suggestions={suggestions}
        deleteProperty={deleteProperty}
        getNameSuggestions={getNameSuggestions}
        clearSuggestions={clearSuggestions}
        isEnhancedDesignCustomProp={isEnhancedDesignCustomProp}
        showPropActions={showActions}
      />
    </form>
  );
};

CustomPropertiesForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

CustomPropertiesForm.defaultProps = {
  formClassName: '',
};

CustomPropertiesForm = reduxForm({
  validate,
  enableReinitialize: true,
})(CustomPropertiesForm);

export default CustomPropertiesForm;
