import { isEmpty } from 'lodash';
import { fields as fieldConstants } from '../../../common/advanced-filter-custom-property/constants/constants';
import { fields } from '../constants/checklists-procedures-constants';

export const validateSingleField = (el, index, propertyArrayErrors) => {
  const propertyErrors = {};
  if (!el || !el[fieldConstants.name]) {
    propertyErrors[fieldConstants.name] = 'DEFECT_PROPERTY.ERROR.NAME.REQUIRED_FIELD';
    propertyArrayErrors[index] = propertyErrors;
  }
  if (!el || !el[fieldConstants.value]) {
    propertyErrors[fieldConstants.value] = 'DEFECT_PROPERTY.ERROR.VALUE.REQUIRED_FIELD';
    propertyArrayErrors[index] = propertyErrors;
  }
  return propertyErrors;
};

export const validate = values => {
  const errors = {};
  if (!isEmpty(values[fields.properties])) {
    const propertyArrayErrors = [];
    values[fields.properties].forEach((el, index) => {
      validateSingleField(el, index, propertyArrayErrors);
    });
    if (!isEmpty(propertyArrayErrors)) {
      errors[fields.properties] = propertyArrayErrors;
    }
  }

  return errors;
};
