import { measurementPointConstants } from '../../../constants/ndt-constants';

export const validate = values => {
  const errors = {};

  if (isNaN(values[measurementPointConstants.fields.measuredValue])) {
    errors[measurementPointConstants.fields.measuredValue] = {
      string: 'NDT_MEASUREMENT_POINT.ERROR.MEASURED_VALUE_REQUIRED',
    };
  }

  if (!values[measurementPointConstants.fields.measuredDate]) {
    errors[measurementPointConstants.fields.measuredDate] = 'NDT_MEASUREMENT_POINT.ERROR.MEASURED_DATE_REQUIRED';
  }

  return errors;
};
