import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import CustomInput from '../../../../../../../common/form/components/input';
import DatePicker from '../../../../../../../common/form/components/date-picker';
import Textarea from '../../../../../../../common/form/components/text-area';
import { fields } from '../../constants/details-form-constants';
import { validate } from '../../validators/details-form-validators';

const DetailsForm = ({ disabled }) => {
  const { name, description, startDate, endDate, templateName } = fields;

  return (
    <form noValidate>
      <Field id={name.id} name={name.name} label={name.label} component={CustomInput} placeholder={name.placeholder} onlyDisabled={disabled} isRequired size="lg" />
      <Field id={templateName.id} name={templateName.name} label={templateName.label} component={CustomInput} placeholder={templateName.placeholder} onlyDisabled isRequired size="lg" />
      <Field
        id={description.id}
        name={description.name}
        label={description.label}
        component={Textarea}
        placeholder={description.placeholder}
        maxChars={description.maxChars}
        onlyDisabled={disabled}
        enableAutoResize={true}
        size="lg"
      />
      <Field
        id={startDate.id}
        name={startDate.name}
        label={startDate.label}
        component={DatePicker}
        placeholder={startDate.placeholder}
        disabled={disabled}
        disabledKeyboardNavigation
        dateFormat="MMMM d, yyyy h:mm aa"
        timeFormat="h:mm aa"
        showTimeSelect
        validateUsingReduxForm
        isRequired
        size="lg"
      />
      <Field
        id={endDate.id}
        name={endDate.name}
        label={endDate.label}
        component={DatePicker}
        placeholder={endDate.placeholder}
        disabled={disabled}
        disabledKeyboardNavigation
        dateFormat="MMMM d, yyyy h:mm aa"
        timeFormat="h:mm aa"
        showTimeSelect
        validateUsingReduxForm
        isEnd
        size="lg"
      />
    </form>
  );
};

DetailsForm.propTypes = {
  disabled: PropTypes.bool,
};

export default reduxForm({
  validate,
  enableReinitialize: true,
  touchOnChange: true,
  destroyOnUnmount: true,
})(DetailsForm);
