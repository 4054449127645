// import pdfTagListApi from '../../../api/pdf-tag-list/actions';
import { uploadAsset } from '../../upload/actions/upload-actions';
import { addItemAtIndex } from './action-creators';
import uploadApi from '../../../api/upload/actions';
import Helpers from '../../../common/helpers';

import constants from '../constants/constants';

export const uploadPDF = (file, data, callbackFunction, index, section) => {
  return async dispatch => {
    try {
      dispatch(
        uploadAsset(file, data, callbackFunction, index, async (index, url) => {
          const res = await uploadApi.setUploadDone({ Key: Helpers.getKeyFromUrl(url) });
          dispatch(onSingleFileUploadDone(data.InspectionID, data.ProjectID, index, url, res.data.Data, section));
        })
      );
    } catch (err) {
      console.log(err);
    }
  };
};

export const onSingleFileUploadDone = (InspectionID, ProjectID, index, url, data, section) => {
  return async dispatch => {
    try {
      console.log('Upload done, sending an api request', ProjectID, InspectionID, index, url);

      if (!data[constants.fields.id]) {
        console.error('Upload done, missing file id', ProjectID, InspectionID, index, url);
        return;
      }

      dispatch(
        addItemAtIndex(section, 0, {
          [constants.fields.id]: data[constants.fields.id],
          [constants.fields.title]: data[constants.fields.fileName],
          [constants.fields.fileName]: data[constants.fields.fileName],
          [constants.fields.createdAt]: data[constants.fields.createdAt],
          [constants.fields.status]: constants.statusOptions.started,
        })
      );
    } catch (err) {
      console.log(err);
    }
  };
};
