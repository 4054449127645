import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import DropdownMenu, { MenuItem } from '../../dropdown/components/dropdown-menu';

import ObjectDropdown from '../../toolbox/components/object-dropdown';

import { get, isEmpty } from 'lodash';
import AccessRenderer from '../../access-renderer/components/access-renderer';
import Icon from '../../icon/components/icon';
import Portal from '../../portal/components/portal';

import Button from '../../form/components/button';
import RenderIf from '../../render-if/components/render-if';
import InfoTooltip from '../../tooltip/components/info-tooltip';
import '../styles/small-dropdown.scss';

class SmallDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settingsOpen: false,
      position: {},
      pageX: null,
      pageY: null,
    };
    this.node = React.createRef();
    this.dropdown = React.createRef();
  }
  componentDidUpdate = prevProps => {
    const { scrollPosition } = this.props;

    const prevscrollPosition = prevProps.scrollPosition;

    if (scrollPosition !== prevscrollPosition) {
      this.closeSettings();
    }
  };

  toggleSettings = e => {
    const { settingsOpen } = this.state;
    const { pageX, pageY } = e.nativeEvent;

    this.setState({ pageX });
    this.setState({ pageY });

    this.setState({ settingsOpen: !settingsOpen });

    this.calcPosition(pageX, pageY);
  };

  calcPosition = (pageX, pageY) => {
    const { offsetX, offsetY } = this.props;
    const pos = { left: pageX + offsetX, top: pageY + offsetY };

    const dropdownHeight = get(this.dropdown, 'current.node.current.clientHeight');
    const dropdownWidth = get(this.dropdown, 'current.node.current.clientWidth');

    //if dropdown is near bottom of the page make it go up
    if (dropdownHeight && dropdownHeight + pageY > window.innerHeight) {
      pos.top = pageY - dropdownHeight;
    }
    if (dropdownWidth && pageX + dropdownWidth > window.innerWidth) {
      pos.left = pageX - dropdownWidth;
    }
    this.setState({ position: pos });
  };

  closeSettings = () => {
    this.setState({ settingsOpen: false });
  };

  renderMenuItems = hovered => {
    const { t } = this.context;
    const { actionsMenu, getClickAction, itemIndex } = this.props;
    return actionsMenu.map((item, index) => {
      if (!isEmpty(item.children)) {
        const newNode = React.createRef();

        return (
          <MenuItem key={index} className={`dropdown__item  ${hovered === item.id || hovered === index ? 'hovered' : ''}`} id={item.id || index}>
            <AccessRenderer {...item.access}>
              {({ hasAccess }) => {
                return (
                  <Fragment>
                    <div className={`wrapper ${hasAccess ? '' : 'disabled'}`} onClick={hasAccess && getClickAction ? e => getClickAction(item.action, itemIndex, () => null, e) : null} ref={newNode}>
                      {item.icon && <item.icon width={14} height={14} />}
                      {item.titleIcon && <Icon className="title-icon" name={item.titleIcon} />}
                      <p className={`${getClickAction ? '' : 'noselect'}`}>{t(item.title)}</p>
                      <Icon name="chevron" rotation={90} size="xs" className="arrow" />
                    </div>
                    <ObjectDropdown
                      isDisabled={!hasAccess}
                      placement={item.placement}
                      refNode={newNode}
                      actionsMenu={item.children}
                      getClickAction={action => (hasAccess && getClickAction ? getClickAction(action, itemIndex, () => null, null) : null)}
                    />
                    {item.checkIcon && <Icon className="check-icon" name={item.checkIcon} />}
                  </Fragment>
                );
              }}
            </AccessRenderer>
          </MenuItem>
        );
      } else {
        if (item.isHidden) {
          return null;
        }
        return (
          <MenuItem key={index} className={`dropdown__item  ${hovered === item.id || hovered === index ? 'hovered' : ''} `} id={item.id || index}>
            <AccessRenderer {...item.access}>
              {({ hasAccess }) => {
                return (
                  <div
                    className={`wrapper ${hasAccess && !item.isDisabled ? '' : 'disabled'} ${item.separator ? 'separator' : ''} ${item.isHighlighted ? 'highlighted' : ''} ${
                      item.customMenuItemClassName && item.customMenuItemClassName
                    }`}
                    onClick={hasAccess && !item.isDisabled && getClickAction ? e => getClickAction(item.action, itemIndex, this.closeSettings, e) : null}
                  >
                    {item.icon && <item.icon width={14} height={14} {...item.iconProps} />}
                    {item.titleIcon && <Icon className="title-icon" name={item.titleIcon} />}
                    <p className={`${getClickAction && !item.isDisabled ? '' : 'noselect'} `}>{t(item.title)}</p>
                    {item.showInfoTooltip && (
                      <InfoTooltip
                        customComponent={
                          <label
                            style={{
                              color: '#999',
                              width: '75px',
                              display: 'flex',
                            }}
                          >
                            {t(item.infoTooltipTitle)}
                          </label>
                        }
                        offsetY={8}
                        offsetX={-20}
                        Component={() => <Icon name="info" size="sm" />}
                        componentProps={{ title: '' }}
                        containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
                      />
                    )}
                    {item.checkIcon && <Icon className="check-icon" name={item.checkIcon} size="sm" />}
                  </div>
                );
              }}
            </AccessRenderer>
          </MenuItem>
        );
      }
    });
  };

  render() {
    const { settingsOpen, position } = this.state;
    const { className, InfoIcon, asButton, portalProps, isButton, buttonProps } = this.props;

    return (
      <div
        onClick={e => {
          e.stopPropagation();
          this.toggleSettings(e);
        }}
        className={`small-dropdown__settings ${className || ''} ${asButton ? 'small-dropdown__settings-as-button' : ''}`}
        ref={this.node}
        data-cy="settings-icon"
      >
        <RenderIf if={isButton}>
          <Button
            onClick={e => {
              e.stopPropagation();
              this.toggleSettings(e);
            }}
            {...buttonProps}
          />
        </RenderIf>
        <RenderIf if={!isButton}>{InfoIcon ? <InfoIcon /> : <div className="small-dropdown__settings-icon" />}</RenderIf>
        <Portal shouldRender={true} {...portalProps}>
          <DropdownMenu position={position} customNode={this.node} clickOutsideHandler={this.closeSettings} isOpen={settingsOpen} anchor="bottom-left" className="dropdown" ref={this.dropdown}>
            {(selected, hovered) => {
              return <Fragment>{this.renderMenuItems(hovered)}</Fragment>;
            }}
          </DropdownMenu>
        </Portal>
      </div>
    );
  }
}

SmallDropdown.contextTypes = {
  t: PropTypes.func.isRequired,
};
SmallDropdown.defaultProps = {
  offsetX: 0,
  offsetY: 0,
  showInfoTooltip: false,
};

const mapStateToProps = state => ({
  scrollPosition: state.navReducer.scrollPosition,
});

export default connect(mapStateToProps, null)(SmallDropdown);
