import React, { Component, createRef, Fragment } from 'react';

import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import Icon from '../../icon/components/icon';
import Dropdown from '../../dropdown/components/dropdown';

import '../styles/object-dropdown.scss';

class ObjectDropdown extends Component {
  handleCloseDropdown = data => {
    if (data && data.parent) {
      this.handleCloseDropdown(data.parent);
    } else {
      // JUST CLOSE THE ROOT MENU ITEM
      data.closeDropdown();
      return;
    }
  };

  menuItem = data => {
    const { getClickAction, placement } = this.props;
    const { item, isHovered } = data;
    const { t } = this.context;
    if (!isEmpty(item.children)) {
      const newNode = createRef();
      return (
        <Fragment>
          <div className={`menu_item ${isHovered ? 'hovered' : ''} ${item.className || ''}`} ref={newNode}>
            <Icon name="chevron" size="xs" rotation={90} handleHover={false} className="arrow" />
            {item.icon && <item.icon className="menu_item__icon" />}
            <p className="menu_item__text f-secondary-dark">{t(item.title)}</p>
          </div>
          <Dropdown
            // position={{ bottom: '30px' }}
            anchor={placement === 'left' ? 'bottom-left' : 'bottom-right'}
            node={newNode}
            Item={itemData => this.menuItem({ ...itemData, parent: data })}
            actionsMenu={item.children}
            getClickAction={getClickAction}
          />
        </Fragment>
      );
    } else {
      return (
        <div onClick={() => this.handleCloseDropdown(data)} className={`menu_item ${isHovered ? 'hovered' : ''} ${item.className || ''}`}>
          {item.icon && <item.icon className="menu_item__icon" />}
          <p className="menu_item__text f-secondary-dark">{t(item.title)}</p>
        </div>
      );
    }
  };

  render() {
    const { actionsMenu, getClickAction, refNode, isDisabled, placement, offsetX, offsetY } = this.props;

    return (
      <Dropdown
        isDisabled={isDisabled}
        showArrow={true}
        position={{ [placement]: offsetX, bottom: offsetY }}
        anchor={placement === 'left' ? 'bottom-left' : 'bottom-right'}
        node={refNode}
        Item={this.menuItem}
        actionsMenu={actionsMenu}
        getClickAction={getClickAction}
      />
    );
  }
}

ObjectDropdown.contextTypes = {
  t: PropTypes.func.isRequired,
};

ObjectDropdown.propTypes = {
  placement: PropTypes.oneOf(['left', 'right']),
};

ObjectDropdown.defaultProps = {
  placement: 'left',
  offsetX: '0',
  offsetY: '0',
};

export default ObjectDropdown;
