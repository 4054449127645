import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { debounce } from 'lodash';
import Button from '../../../common/form/components/button';
import CustomSelect from '../../../common/form/components/select';

import { fetchContractors } from '../actions/keybox-actions';

import '../styles/issue-key.scss';

class CreateKeybox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contractors: [],
      selectedContractor: null,
    };
    this.handleFetchContractorsDebounced = debounce(this.handleFetchContractors, 500);
  }

  componentDidMount = () => {
    this.handleFetchContractors('');
  };

  handleFetchContractors = text => {
    const { fetchContractors, projectId, keyboxId } = this.props;

    fetchContractors(text, { ProjectID: parseInt(projectId), KeyboxID: parseInt(keyboxId) }, ({ Contractors }) => {
      this.setState({ contractors: Contractors });
    });
  };

  render() {
    const { t } = this.context;
    const { closeAction, issueKey, selectedKey } = this.props;
    const { selectedContractor, contractors } = this.state;

    return (
      <div className="issue-key">
        <h3 className="title">{t('ISSUE_KEY.MODAL_TITLE', { keyName: selectedKey['Name'] })}</h3>
        <p className="f-secondary-dark description">{t('ISSUE_KEY.MODAL_DESCRIPTION')}</p>
        <CustomSelect
          onChange={selectedContractor => this.setState({ selectedContractor })}
          label={'ISSUE_KEY.CHOOSE_CONTRACTOR'}
          valueField={'ID'}
          textField={'Name'}
          data={contractors || []}
          placeholder={t('ISSUE_KEY.SELECT_CONTRACTOR')}
          onSearch={search => this.handleFetchContractorsDebounced(search)}
          filter={() => true}
        />
        <div className="buttons">
          <Button variant="gray-outline" type="button" text={t('ISSUE_KEY.CLOSE')} width="sm" height="md" onClick={closeAction} />
          <Button type={'button'} disabled={!selectedContractor} text={t('ISSUE_KEY.SAVE_BTN')} width="sm" height="md" onClick={() => issueKey(selectedKey, selectedContractor, closeAction)} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  fetchContractors: (text, params, sucessCallback) => dispatch(fetchContractors(text, params, sucessCallback)),
});

CreateKeybox.contextTypes = {
  t: PropTypes.func.isRequired,
};

CreateKeybox.propTypes = {};

export default connect(null, mapDispatchToProps)(CreateKeybox);
