import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { defaultPagingObj, filterProps } from '../../../app/checklists-procedures-management/constants/checklists-procedures-constants';
import AddButton from '../../add-button/components/add-button';
import Loader from '../../global-loader/components/simple-loader';
import PaginationNavigator from '../../load-more/components/pagination-navigator';
import { additionalFields, defaultOptionsFieldName, defaultQuestion, defaultQuestionName, questionTypes as defaultQuestionTypes } from '../../question-components/constants/question-constants';
import RenderIf from '../../render-if/components/render-if';
import EditableFields from './editable-fields';
import FilesUpload from './files-upload';
import StaticFields from './static-fields';

const SectionContent = ({
  questions,
  questionTypes,
  sectionId,
  hideAddQuestionButton,
  hideAdditionalFields,
  sectionQuestionsName,
  questionValidators,
  handleFieldChange,
  handleFieldDelete,
  handleFieldSort,
  handleAddQuestion,
  SectionCustomContent,
  components,
  handleComponentSelected,
  handleDeleteComponent,
  toggleAdditionalFields,
  handleAddQuestionOption,
  handleUpdateQuestionOption,
  handleDeleteQuestionOption,
  handleManageWorkAreas,
  handleAdd3DPosition,
  questionModalData,
  handleComponentPicker3D,
  handleComponentPickerPDF,
  handleComponentPickerCH,
  lockedQuestions,
  stickActions,
  showQuestionDescription,
  isFilesUpload,
  toggleUploadModal,
  files,
  handleDeleteSectionFile,
  removeFieldArrayFields,
  pushFieldArrayItem,
  handleScreenshotFrom3D,
  questionUpdateDebounce,
  questionOptionUpdateDebounce,
  isDisabled,
  addQuestionDisabled,
  isLocked,
  hideMandatoryCheckbox,
  projectDMSCategories,
  fetchQuestionFiles,
  hideNewMeasurementPointQuestionButton = false,
  addNewMeasurementPointDisabled = false,
  handleAddNewMeasurementPointQuestion = () => null,
  isLoading,
  QuestionFilters,
  loadPreviousQuestions,
  loadNextQuestions,
  showQuestionsPaginationNavigator,
  requestInProgress,
  inspectionId,
}) => {
  const sectionHasOneOrLessQuestions = useMemo(() => {
    if (!questions) {
      return true;
    }
    if (showQuestionsPaginationNavigator) {
      // last item in the list and first page
      return questions.length <= 1 && QuestionFilters?.[filterProps.lastSeen] - defaultPagingObj[filterProps.perPage] <= 0;
    }
    return questions.length <= 1;
  }, [questions, showQuestionsPaginationNavigator, QuestionFilters]);

  if (isLoading) {
    return <Loader isLoading={true} />;
  }

  const getQuestionsInitialValues = () => {
    const { name, questionType, isMandatory, isMandatoryFileUpload, allowMultipleAnswers } = defaultQuestion;
    const { description, estimatedTime } = additionalFields;
    const initialValues = {};

    if (questions && questions.length > 0) {
      for (let i = 0; i < questions.length; i++) {
        initialValues[`${defaultQuestionName}-${name.name}-${questions[i].ID}`] = questions[i][name.name];
        initialValues[`${defaultQuestionName}-${questionType.name}-${questions[i].ID}`] = questions[i][questionType.name];
        initialValues[`${defaultQuestionName}-${isMandatory.name}-${questions[i].ID}`] = questions[i][isMandatory.name];
        initialValues[`${defaultQuestionName}-${isMandatoryFileUpload.name}-${questions[i].ID}`] = questions[i][isMandatoryFileUpload.name];
        initialValues[`${defaultQuestionName}-${allowMultipleAnswers.name}-${questions[i].ID}`] = questions[i][allowMultipleAnswers.name];
        initialValues[`${defaultQuestionName}-${description.name}-${questions[i].ID}`] = questions[i][description.name];
        initialValues[`${defaultQuestionName}-${estimatedTime.name}-${questions[i].ID}`] = questions[i][estimatedTime.name];

        if (questions[i][questionType.name] === defaultQuestionTypes.MULTI_ANSWER.valueId && questions[i].QuestionOptions) {
          for (let j = 0; j < questions[i].QuestionOptions.length; j++) {
            initialValues[`${defaultOptionsFieldName}-${name.name}-${questions[i].QuestionOptions[j].ID}`] = questions[i].QuestionOptions[j][name.name];
          }
        }
      }
    }

    return initialValues;
  };

  const renderPaginationNavigator = () => {
    if (!showQuestionsPaginationNavigator || QuestionFilters?.[filterProps.totalNumber] <= defaultPagingObj[filterProps.perPage]) {
      return null;
    }
    const pageCurrentStart = QuestionFilters?.[filterProps.lastSeen] ? Math.max(QuestionFilters?.[filterProps.lastSeen] - defaultPagingObj[filterProps.perPage], 0) : 0; // clamp to 0 if negative value
    return (
      <PaginationNavigator
        isLoading={requestInProgress}
        className="checklists-procedures-management__container__second-step__section-pagination-navigator"
        currentStart={pageCurrentStart}
        currentEnd={pageCurrentStart + (questions || []).length}
        totalItems={QuestionFilters?.[filterProps.totalNumber]}
        label="CHECKLISTS_PROCEDURES_MANAGEMENT.QUESTIONS.PAGINATION_INFO"
        prevButtonProps={{
          disabled: QuestionFilters?.[filterProps.lastSeen] - defaultPagingObj[filterProps.perPage] <= 0,
          onClick: loadPreviousQuestions,
          text: 'CHECKLISTS_PROCEDURES_MANAGEMENT.QUESTIONS.PREV_BUTTON_TEXT',
        }}
        nextButtonProps={{
          disabled: !QuestionFilters?.[filterProps.hasNext],
          onClick: loadNextQuestions,
          text: 'CHECKLISTS_PROCEDURES_MANAGEMENT.QUESTIONS.NEXT_BUTTON_TEXT',
        }}
      />
    );
  };
  return (
    <div className="section__content">
      <RenderIf if={!SectionCustomContent}>
        {isFilesUpload ? (
          <FilesUpload disabled={isDisabled} featureId={sectionId} files={files} toggleUploadModal={toggleUploadModal} handleDeleteFiles={handleDeleteSectionFile} />
        ) : (
          <>
            {renderPaginationNavigator()}
            <RenderIf if={lockedQuestions.length > 0}>
              <StaticFields questions={lockedQuestions} />
            </RenderIf>
            <EditableFields
              form={`editable-form-with-section-${sectionId}`}
              initialValues={getQuestionsInitialValues()}
              questionTypes={questionTypes}
              handleFieldChange={handleFieldChange}
              handleFieldDelete={handleFieldDelete}
              handleFieldSort={handleFieldSort}
              sectionId={sectionId}
              sectionQuestionsName={sectionQuestionsName}
              questionValidators={questionValidators}
              hideAdditionalFields={hideAdditionalFields}
              components={components}
              handleComponentSelected={handleComponentSelected}
              sectionQuestions={questions}
              handleDeleteComponent={handleDeleteComponent}
              toggleAdditionalFields={toggleAdditionalFields}
              handleAddQuestionOption={handleAddQuestionOption}
              handleUpdateQuestionOption={handleUpdateQuestionOption}
              handleDeleteQuestionOption={handleDeleteQuestionOption}
              isLoading={requestInProgress}
              stickActions={stickActions}
              showQuestionDescription={showQuestionDescription}
              removeFieldArrayFields={removeFieldArrayFields}
              pushFieldArrayItem={pushFieldArrayItem}
              handleManageWorkAreas={handleManageWorkAreas}
              handleAdd3DPosition={handleAdd3DPosition}
              questionModalData={questionModalData}
              handleComponentPicker3D={handleComponentPicker3D}
              handleComponentPickerPDF={handleComponentPickerPDF}
              handleComponentPickerCH={handleComponentPickerCH}
              toggleUploadModal={toggleUploadModal}
              handleDeleteFiles={handleDeleteSectionFile}
              handleScreenshotFrom3D={handleScreenshotFrom3D}
              questionUpdateDebounce={questionUpdateDebounce}
              questionOptionUpdateDebounce={questionOptionUpdateDebounce}
              isDisabled={isDisabled}
              isSectionLocked={isLocked}
              hideMandatoryCheckbox={hideMandatoryCheckbox}
              projectDMSCategories={projectDMSCategories}
              fetchQuestionFiles={fetchQuestionFiles}
              sectionHasOneOrLessQuestions={sectionHasOneOrLessQuestions}
              inspectionId={inspectionId}
            />
            <RenderIf if={!hideAddQuestionButton}>
              <AddButton disabled={isDisabled || addQuestionDisabled} className="question-add-button" onClick={() => handleAddQuestion(sectionId, Object.assign([], questions))}>
                {'SECTIONS.ADD_QUESTION'}
              </AddButton>
            </RenderIf>
            <RenderIf if={!hideNewMeasurementPointQuestionButton && !hideAddQuestionButton}>
              <AddButton
                disabled={isDisabled || addNewMeasurementPointDisabled}
                className="question-add-button mt-16"
                onClick={() => handleAddNewMeasurementPointQuestion(sectionId, Object.assign([], questions), QuestionFilters)}
              >
                {'READINGS_AND_GAUGES.MEASUREMENT_POINTS.EMPTY_STATE.ACTION_TEXT'}
              </AddButton>
            </RenderIf>
            {renderPaginationNavigator()}
          </>
        )}
      </RenderIf>
      <RenderIf if={SectionCustomContent}>{SectionCustomContent ? <SectionCustomContent /> : null}</RenderIf>
    </div>
  );
};

SectionContent.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number,
      Name: PropTypes.string.isRequired,
      QuestionType: PropTypes.number,
      QuestionOrder: PropTypes.number,
      IsMandatory: PropTypes.bool,
      AllowMultipleAnswers: PropTypes.bool,
      EstimatedTime: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      CreatedAt: PropTypes.number,
    })
  ),
  questionTypes: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number,
      ChecklistItemType: PropTypes.string,
      ChecklistItemTypeName: PropTypes.string,
    })
  ),
  sectionId: PropTypes.number,
  hideAddQuestionButton: PropTypes.bool,
  hideAdditionalFields: PropTypes.bool,
  sectionQuestionsName: PropTypes.string.isRequired,
  questionValidators: PropTypes.object,
  handleFieldChange: PropTypes.func,
  handleFieldDelete: PropTypes.func,
  handleFieldSort: PropTypes.func,
  SectionCustomContent: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.elementType]),
  components: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
    })
  ),
  handleComponentSelected: PropTypes.func,
  handleDeleteComponent: PropTypes.func,
  toggleAdditionalFields: PropTypes.func,
  handleAddQuestionOption: PropTypes.func,
  handleUpdateQuestionOption: PropTypes.func,
  handleDeleteQuestionOption: PropTypes.func,
  handleManageWorkAreas: PropTypes.func,
  handleAdd3DPosition: PropTypes.func,
  handleScreenshotFrom3D: PropTypes.func,
  isLoading: PropTypes.bool,
  lockedQuestions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      isLocked: PropTypes.bool,
      label: PropTypes.string,
      placeholder: PropTypes.string,
      props: PropTypes.object,
    })
  ),
  stickActions: PropTypes.bool,
  showQuestionDescription: PropTypes.bool,
  isFilesUpload: PropTypes.bool,
  toggleUploadModal: PropTypes.func,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      FileID: PropTypes.number.isRequired,
      FileName: PropTypes.string.isRequired,
      Key: PropTypes.string,
      DocumentURL: PropTypes.string,
      ThumbnailURL: PropTypes.string,
      URL: PropTypes.string,
    })
  ),
  handleDeleteSectionFile: PropTypes.func,
  removeFieldArrayFields: PropTypes.func,
  pushFieldArrayItem: PropTypes.func,
  isLocked: PropTypes.bool,
  hideMandatoryCheckbox: PropTypes.bool,
};

SectionContent.defaultProps = {
  questions: [],
  lockedQuestions: [],
};

export default SectionContent;
