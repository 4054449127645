import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { find } from 'lodash';

import { Field, reduxForm, formValueSelector } from 'redux-form';
import { FORMS } from '../../../common/constants';
import { mfaTypeFields } from '../constants/mfa-constants';
import Icon from '../../../common/icon/components/icon';

import RadioButton from '../../../common/form/components/radio-button';
import Button from '../../../common/form/components/button';

import '../styles/mfa-form.scss';

class MFA extends Component {
  renderTypes = types => {
    const { t } = this.context;
    return types.map(({ type, IsActive, disabled }, index) => {
      return (
        <Field
          name={mfaTypeFields.type}
          component={RadioButton}
          value={type}
          type="radio"
          id={type}
          key={index}
          disabled={disabled}
          label={t(`MFA.${type.toUpperCase()}`)}
          CustomComponent={
            IsActive ? () => <Icon name={'checkmark'} active className="custom-checkmark" /> : disabled ? () => <p className="f-secondary-green custom-checkmark">{t('MFA.COMING_SOON')}</p> : null
          }
        />
      );
    });
  };

  render() {
    const { t } = this.context;
    const { mfaTypes, cancelClick, handleSubmit, confirmDisabled } = this.props;

    return (
      <form className="mfa-form" onSubmit={handleSubmit}>
        <div className="mfa-types">{this.renderTypes(mfaTypes || [])}</div>
        <div className="buttons">
          <Button type="button" onClick={cancelClick} variant="gray-outline" text={t('MFA.CANCEL')} />
          <Button text={t('MFA.NEXT')} disabled={confirmDisabled} />
        </div>
      </form>
    );
  }
}
const selector = formValueSelector(FORMS.mfaSetup);

const mapStateToProps = (state, props) => {
  const defalutActive = (find(props.mfaTypes, { IsActive: true }) || {}).type;
  const currentSelected = selector(state, mfaTypeFields.type);

  return {
    initialValues: { [mfaTypeFields.type]: defalutActive },
    confirmDisabled: currentSelected === defalutActive,
  };
};

MFA.contextTypes = {
  t: PropTypes.func.isRequired,
};
MFA = reduxForm({
  form: FORMS.mfaSetup,
  enableReinitialize: true,
})(MFA);

export default connect(mapStateToProps, null)(MFA);
