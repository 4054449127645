import routesConstants from './routes';
import { Api } from '../../core/api';
import { API_BASE_URL } from '../../common/constants';
import { generateQueryParams } from '../helpers/helper';

const getNDTMeasurements = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_NDT_MEASUREMENT_LOCATIONS}`, data);
};

const getNDTMeasurementsLite = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_NDT_MEASUREMENT_LOCATIONS_LITE}`, data);
};

const getNDTMeasurementDetails = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_NDT_MEASUREMENT_DETAILS}${generateQueryParams(params)}`);
};

const createMeasurementLocation = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CREATE_NDT_MEASUREMENT_LOCATION}`, data);
};

const updateMeasurementLocation = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_NDT_MEASUREMENT_LOCATION}`, data);
};

const updateMeasurementLocationGeometry = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_NDT_MEASUREMENT_LOCATION_GEOMETRY}`, data);
};

const deleteMeasurementLocation = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_NDT_MEASUREMENT_LOCATION}`, data);
};

const getNDTMeasurementProperties = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_NDT_MEASUREMENT_PROPERTIES}`, data);
};

const addNDTMeasurementProperty = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_NDT_MEASUREMENT_PROPERTY}`, data);
};

export const deleteNDTMeasurementFile = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_NDT_MEASUREMENT_FILE}`, data);
};

const updateMeasurementomponent = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_NDT_MEASUREMENT_COMPONENT}`, data);
};

// MEASUREMENT POINT ACTIONS

const getNDTMeasurementPoints = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_NDT_MEASUREMENT_POINTS}`, data);
};

const getNDTMeasurementPointDetails = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_NDT_MEASUREMENT_POINT_DETAILS}${generateQueryParams(params)}`);
};

const createMeasurementPoint = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CREATE_NDT_MEASUREMENT_POINT}`, data);
};

const updateMeasurementPoint = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_NDT_MEASUREMENT_POINT}`, data);
};

const deleteMeasurementPoint = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_NDT_MEASUREMENT_POINT}`, data);
};
const getAlarms = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_ALARMS}`, data);
};

const getDescriptorData = async () => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_DESCRIPTION_DATA}`);
};

const saveAlarms = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.SAVE_ALARMS}`, data);
};

const getNDTMeasurementPointProperties = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_NDT_MEASUREMENT_POINT_PROPERTIES}`, data);
};

const addNDTMeasurementPointProperty = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_NDT_MEASUREMENT_POINT_PROPERTY}`, data);
};

export const deleteNDTMeasurementPointFile = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_NDT_MEASUREMENT_POINT_FILE}`, data);
};

// CHART ACTIONS

const getNDTChartData = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_NDT_CHART_DATA}`, data);
};
const getNDTPredictiveChartData = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_NDT_PREDICTIVE_CHART_DATA}`, data);
};

const getNDTChartPeriods = async () => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_NDT_CHART_PERIODS}`);
};

const getNDTChartTypes = async () => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_NDT_CHART_TYPES}`);
};

const getNDTChartGroups = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_NDT_CHART_GROUPS}${generateQueryParams(params)}`);
};

const getNDTChartPointGroups = async () => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_NDT_CHART_POINT_GROUPS}`);
};

const getNdtEmails = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_NDT_EMAILS}${generateQueryParams(params)}`);
};

const addAlarmEmail = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_ALARM_EMAIL}`, data);
};
const removeAlarmEmail = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.REMOVE_ALARM_EMAIL}`, data);
};
const getNdtMeasurementsComponents = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_NDT_MEASUREMENTS_COMPONENTS}${generateQueryParams(params)}`);
};
const getNdtMeasurementsUnits = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_NDT_MEASUREMENT_UNITS}${generateQueryParams(params)}`);
};
const getNdtAlarmsTriggered = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_NDT_ALARMS_TRIGGERD}${generateQueryParams(params)}`);
};
const getNDTMeasurementGroupDetails = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_NDT_MEASUREMENT_GROUP_DETAILS}`, data);
};
const getNDTPredictiveTypes = async () => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_NDT_PREDICTIVE_TYPES}`);
};
const getNDTPredictiveSettings = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_NDT_PREDICTIVE_SETTINGS}${generateQueryParams(params)}`);
};
const updateNDTPredictiveSettings = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_NDT_PREDICTIVE_SETTINGS}`, data);
};
const getNDTChartDefaults = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_NDT_CHART_DEFAULTS}${generateQueryParams(params)}`);
};
const getFilterGroupSuggestions = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_FILTER_GROUP_SUGGESTIONS}`, data);
};

const downloadReport = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DOWNLOAD_REPORT}`, data);
};

export const getMeasurementsClustered = async (data, options) => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_MEASUREMENTS_CLUSTERED}`, data, options);
};

const updateAlarmsNotifications = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_ALARMS_NOTIFICATIONS}`, data);
};

const deleteNDTProperty = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_NDT_PROPERTY}`, data);
};

const deleteNDTMeasurementPointProperty = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_NDT_MEASUREMENT_POINT_PROPERTY}`, data);
};

const updateNDTProperty = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_NDT_PROPERTY}`, data);
};

const updateNDTMeasurementPointProperty = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_NDT_MEASUREMENT_POINT_PROPERTY}`, data);
};

const getNDTMeasurementPropertyNames = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_NDT_MEASUREMENT_PROPERTY_NAMES}`, data);
};

const getNDTMeasurementPointPropertyNames = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_NDT_MEASUREMENT_POINT_PROPERTY_NAMES}`, data);
};

export default {
  getNDTMeasurements,
  getNDTMeasurementsLite,
  getNDTMeasurementDetails,
  createMeasurementLocation,
  updateMeasurementLocation,
  updateMeasurementLocationGeometry,
  deleteMeasurementLocation,
  getNDTMeasurementProperties,
  getNDTMeasurementPointProperties,
  addNDTMeasurementProperty,
  addNDTMeasurementPointProperty,
  deleteNDTMeasurementFile,
  deleteNDTMeasurementPointFile,
  getNDTMeasurementPoints,
  getNDTMeasurementPointDetails,
  createMeasurementPoint,
  updateMeasurementPoint,
  deleteMeasurementPoint,
  getDescriptorData,
  getAlarms,
  saveAlarms,
  getNDTChartPeriods,
  getNDTChartTypes,
  getNDTChartGroups,
  getNDTChartData,
  getNDTChartPointGroups,
  getNdtEmails,
  addAlarmEmail,
  removeAlarmEmail,
  getNdtMeasurementsComponents,
  getNdtMeasurementsUnits,
  getNdtAlarmsTriggered,
  getNDTMeasurementGroupDetails,
  getNDTPredictiveTypes,
  getNDTPredictiveSettings,
  updateNDTPredictiveSettings,
  getNDTChartDefaults,
  getNDTPredictiveChartData,
  getFilterGroupSuggestions,
  downloadReport,
  getMeasurementsClustered,
  updateAlarmsNotifications,
  deleteNDTProperty,
  deleteNDTMeasurementPointProperty,
  updateNDTProperty,
  updateNDTMeasurementPointProperty,
  getNDTMeasurementPropertyNames,
  getNDTMeasurementPointPropertyNames,
  updateMeasurementomponent,
};
