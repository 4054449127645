export const formConstants = {
  fields: {
    name: 'Name',
    id: 'ID',
    value: 'Value',
    properties: 'properties',
    fieldType: 'FieldType',
    fieldValues: 'FieldValues',
  },
};

export const customPropertyFieldTypes = {
  input: 'INPUT',
  select: 'SELECT',
};
