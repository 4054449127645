import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { find, capitalize } from 'lodash';
import { createNumberMask } from 'redux-form-input-masks';

import Checkbox from '../../../../common/form/components/checkbox';
import CustomSelect from '../../../../common/form/components/select';
import InfoTooltip from '../../../../common/tooltip/components/info-tooltip';
import Icon from '../../../../common/icon/components/icon';
import CustomInput from '../../../../common/form/components/input';
import SearchInput from '../../../../common/input/components/search-input';
import Helpers from '../../../../common/helpers';
import { assigneeFields, userDetailsDisplayProps, formConstants, intervalTypes } from './constants/constants';

import '../../styles/add-assignees.scss';

const AddAlerts = ({ usersAndTeams, workOrderAlertList, handleUsersAndTeamsSearch, addUserToAlertList, removeUserFromAlertList, closeConfirmModal }, { t }) => {
  const handleUserAssigned = (item, isSelected) => {
    if (isSelected) {
      removeUserFromAlertList(item);
    } else {
      addUserToAlertList(item);
    }
  };

  const numberMask = createNumberMask({
    decimalPlaces: 0,
    allowNegative: false,
  });

  return (
    <div className="add-work-order-alerts">
      <p className="f-primary light-bold">{t('CREATE_WORKORDER_FORM.INTERVAL_DESC')}</p>
      <div className="grouped-input interval-section">
        <div className="interval-section-number">
          <Field
            id={formConstants.fields.intervalNumber}
            name={formConstants.fields.intervalNumber}
            component={CustomInput}
            placeholder={t('CREATE_WORKORDER_FORM.INTERVAL_AMOUNT')}
            label={t('CREATE_WORKORDER_FORM.INTERVAL_AMOUNT')}
            type="tel"
            size="lg"
            {...numberMask}
          />
        </div>
        <div className="work-order-custom-select">
          <Field
            id={formConstants.fields.intervalId}
            name={formConstants.fields.intervalId}
            label={'CREATE_WORKORDER_FORM.INTERVAL_TYPE'}
            data={intervalTypes}
            component={CustomSelect}
            valueComponent={item => capitalize(item.text)}
            renderListItem={item => capitalize(item.text)}
            className="select-d"
            placeholder={t('CREATE_WORKORDER_FORM.INTERVAL_TYPE')}
            size="lg"
          />
        </div>
        <p className="f-primary bold">{t('CHOOSE_WORK_ORDER_NOTIFICATION.BEFORE_START_DATE')}</p>
      </div>

      <div className="input-with-dropdown">
        <label className="search-input-label">{t('CHOOSE_WORK_ORDER_NOTIFICATION.ADD_ASSIGNEES_LABEL')}</label>
        <SearchInput
          onChange={handleUsersAndTeamsSearch}
          placeholder={t('PROJECT.SEARCH.PLACEHOLDER')}
          includeDropdown={true}
          items={
            usersAndTeams && usersAndTeams.length
              ? usersAndTeams.map(item => {
                  return { ...item, PermitTestExpirationDate: item.PermitTestExpirationDate ? Helpers.getDateFromUnix(item.PermitTestExpirationDate) : null };
                })
              : []
          }
          emptyStateLabel={'CREATE_WORKORDER_FORM.USERS_TEAMS_DROPDOWN_EMPTY_STATE'}
          renderItem={(item, index) => {
            const isSelected = find(workOrderAlertList, i => `${item[assigneeFields.type]}_${item[assigneeFields.id]}` === `${i[assigneeFields.type]}_${i[assigneeFields.id]}`);

            return (
              <div className="dropdown-render-item-container" key={`work-order-alert-assignee-${item[assigneeFields.id]}`}>
                <div className="user-checkbox-wrapper">
                  {index === 0 && <p className="f-secondary-dark user-title bold">{t('CHOOSE_WORK_ORDER_NOTIFICATION.USER_TITLE')}</p>}
                  <div onClick={() => handleUserAssigned(item, isSelected)}>
                    <Checkbox meta={{}} input={{ defaultChecked: isSelected }} />
                  </div>
                  <p className="f-primary light-bold">{item[assigneeFields.name]}</p>
                </div>
                <div className="user-email-wrapper">
                  {index === 0 && <p className="f-secondary-dark user-title bold">{t('CHOOSE_WORK_ORDER_NOTIFICATION.EMAIL_TITLE')}</p>}
                  <p className="f-primary light-bold">{item[assigneeFields.email]}</p>
                </div>
              </div>
            );
          }}
        />
      </div>
      <div className="added-items">
        <p className="f-secondary-dark bold">{t('CREATE_WORKORDER_FORM.ADDED_COMPONENTS', { number: workOrderAlertList ? workOrderAlertList.length : 0 })}</p>
        <div className="added-items-list">
          {workOrderAlertList &&
            workOrderAlertList.map((item, index) => {
              return (
                <div className="item-r" key={`work-order-alerts-${item[assigneeFields.id]}`}>
                  <p className="f-primary c-title">{`${index + 1}. ${item[assigneeFields.name]}`}</p>
                  <InfoTooltip
                    actionsMenu={Helpers.mapInfoIconDisplayProps(item, userDetailsDisplayProps)}
                    offsetY={8}
                    offsetX={8}
                    Component={() => <Icon name="info" size="sm" />}
                    componentProps={{}}
                    title={item[assigneeFields.name]}
                  />
                  <Icon name="close" className="x-hover pointer" onClick={() => removeUserFromAlertList(item, closeConfirmModal)} />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

AddAlerts.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default AddAlerts;
