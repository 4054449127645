import { PERMISSIONS, PERMISSION_TYPES } from '../../../common/permissions-constants';

const projectActions = {
  editUser: 'editUser',
  deactivateUser: 'deactivateUser',
  activateUser: 'activateUser',
  deleteUser: 'deleteUser',
};
const actionsMenu = [
  {
    title: 'USER_TABLE_SIDEBAR.SETTINGS_MENU.ITEM_1',
    id: '1',
    action: projectActions.editUser,
    access: {
      visibleFor: [PERMISSIONS[PERMISSION_TYPES.people].edit.name],
    },
  },
  {
    title: 'USER_TABLE_SIDEBAR.SETTINGS_MENU.ITEM_2',
    id: '2',
    action: projectActions.deactivateUser,
    access: {
      visibleFor: [PERMISSIONS[PERMISSION_TYPES.people].edit.name],
    },
  },
  {
    title: 'USER_TABLE_SIDEBAR.SETTINGS_MENU.ITEM_3',
    id: '3',
    action: projectActions.activateUser,
    access: {
      visibleFor: [PERMISSIONS[PERMISSION_TYPES.people].edit.name],
    },
  },
  {
    title: 'USER_TABLE_SIDEBAR.SETTINGS_MENU.ITEM_4',
    id: '4',
    action: projectActions.deleteUser,
    access: {
      visibleFor: [PERMISSIONS[PERMISSION_TYPES.people].delete.name],
    },
  },
];

export { projectActions, actionsMenu };
