import React from 'react';
import Icon from '../icon/components/icon';
import PropTypes from 'prop-types';
import './styles/button-with-icon.scss';

const ButtonWithIcon = ({ type, text, icon, onClick, noBorder, bold, iconRotation, customClassName, isDisabled }) => {
  return (
    <button className={`button-with-icon ${type} ${noBorder ? 'no-border' : ''} ${customClassName ? customClassName : ''}`} type="button" onClick={onClick} disabled={isDisabled}>
      <Icon name={icon} size="sm" handleHover={false} rotation={iconRotation} className={`button-with-icon__icon ${type}`} />
      <p className={`button-with-icon__text ${type} ${bold ? 'bold' : ''}`}>{text}</p>
    </button>
  );
};

ButtonWithIcon.propTypes = {
  type: PropTypes.string.isRequired,
  text: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  noBorder: PropTypes.bool,
  iconRotation: PropTypes.number,
  customClassName: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default ButtonWithIcon;
