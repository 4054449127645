import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { debounce, filter, get, map } from 'lodash';

import SearchInput from '../../../common/input/components/search-input';
import ItemsTableRenderer from '../../../common/items-renderer/components/items-table-renderer';
import LoadMore from '../../../common/load-more/components/load-more';
import PageNavigation from '../../../common/page-navigation/components/page-navigation';

import { createKeybox, fetchKeyboxes, issueKey, returnKey } from '../actions/keybox-actions';

import { PERMISSIONS, PERMISSION_TYPES } from '../../../common/permissions-constants';
import { routes } from '../../../common/routes-constants';
import { defaultFilters, fields, filterProps, sortDirection, statusTypes, tableConfig } from '../constants/constants';

import Modal from '../../../common/modal/components/modal';
import CollapsibleToolbar from '../../inspections/components/collapsible-toolbar';
import CreateKeybox from './create-keybox';
import IssueKeyPopup from './issue-key-popup';
import KeyboxWrapper from './keybox-wrapper';

import { placements } from '../../inspections/constants/constants';

import Helpers from '../../../common/helpers';
import { params } from '../../profile/constants/profile-constants';

import Icon from '../../../common/icon/components/icon';
import InfoTooltip from '../../../common/tooltip/components/info-tooltip';
import '../styles/keybox.scss';
import KeyboxPopover from './keybox-popover';

class Keybox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      keyboxes: [],
      filters: defaultFilters,
      modalData: {
        isOpen: false,
      },
      rightCollapsed: true,
    };
    this.searchChangeDebounced = debounce(this.handleSearch, 500);
  }

  componentDidMount = () => {
    const { location } = this.props;
    const { query } = location;
    const projectId = get(query, 'project_id');

    if (!projectId) {
      Helpers.goTo(routes.protectedRoutes.project.fullPath);
    }

    this.getKeyboxes(defaultFilters);
    if (query.selected_item) {
      this.setState({ rightCollapsed: false });
    }
  };

  toggleToolbar = () => {
    const { rightCollapsed } = this.state;
    this.setState({ rightCollapsed: !rightCollapsed });
  };

  onRowClick = (e, item) => {
    const { query } = this.props.location;
    Helpers.goTo(routes.protectedRoutes.keybox.fullPath, [{ project_id: query.project_id }, { inspection_id: query.inspection_id }, { selected_item: item.ID }]);
    this.setState({ rightCollapsed: true });
    setTimeout(() => {
      this.setState({ rightCollapsed: false });
    }, 500);
  };

  getKeyboxes = (params, loadMore) => {
    const { keyboxes } = this.state;
    const { location, fetchKeyboxes } = this.props;
    const { query } = location;
    const projectId = get(query, 'project_id');
    params = {
      ...params,
      ProjectId: parseInt(projectId, 10),
    };

    const onSuccess = data => {
      this.setState(data);

      if (loadMore && data?.keyboxes) {
        Helpers.scrollIntoView('keyboxes__table-wrapper', `row-${data.keyboxes.length - 1}`);
      }
    };

    fetchKeyboxes(params, keyboxes, onSuccess, loadMore);
  };

  openCreateKeybox = updateKeyName => {
    const { filters } = this.state;
    const { createKeybox, location } = this.props;
    const { query } = location;
    const projectId = parseFloat(get(query, 'project_id'));

    const closeAction = () =>
      this.setState({
        modalData: {
          isOpen: false,
        },
      });

    this.setState({
      modalData: {
        isOpen: true,
        closeAction,
        confirmAction: closeAction,
        type: 'none',
        CustomContent: () => (
          <CreateKeybox
            closeAction={closeAction}
            isEdit={false}
            updateKeyName={updateKeyName}
            updateKeyBoxes={keyBoxes => this.setState({ keyboxes: keyBoxes })}
            createAction={keybox =>
              createKeybox(
                { ...keybox, ProjectID: projectId },
                ({ data, isLoading }) => {
                  if (data) {
                    this.setState(prevState => ({
                      ...prevState,
                      keyboxes: [data, ...prevState.keyboxes],
                      filters: { ...filters, [filterProps.totalItems]: filters[filterProps.totalItems] + 1 },
                      isLoading,
                    }));
                  }

                  if (!isLoading) {
                    closeAction();
                    this.onRowClick(null, data);
                  }
                },
                ({ isLoading }) => {
                  this.setState(prevState => ({
                    ...prevState,
                    isLoading,
                  }));
                }
              )
            }
          />
        ),
      },
    });
  };

  openEditKeybox = (keybox, editDetails, deleteKey, addKey, updateKeyName) => {
    const closeAction = () =>
      this.setState({
        modalData: {
          isOpen: false,
        },
      });

    this.setState({
      modalData: {
        isOpen: true,
        closeAction,
        confirmAction: closeAction,
        type: 'none',
        CustomContent: dynamicProps => (
          <CreateKeybox
            closeAction={closeAction}
            isEdit={true}
            editDetails={editDetails}
            editKeys={() => null}
            initialValues={keybox}
            deleteKey={deleteKey}
            addKey={addKey}
            updateKeyName={updateKeyName}
            updateKeyBoxes={keyBoxes => this.setState({ keyboxes: keyBoxes })}
            {...dynamicProps}
          />
        ),
      },
    });
  };

  openConfirmationPopup = (title, content, confirmAction) => {
    const closeAction = () =>
      this.setState({
        modalData: {
          isOpen: false,
        },
      });

    this.setState({
      modalData: {
        isOpen: true,
        closeAction,
        title,
        content,
        confirmAction: () => {
          confirmAction(closeAction);
        },
        type: 'yes-no',
      },
    });
  };

  onColumnSort = SortByColumn => {
    const { filters } = this.state;
    const newParams = {
      ...filters,
      [filterProps.sortByColumn]: SortByColumn,
      [filterProps.sortDirection]: filters[filterProps.sortDirection] === sortDirection.asc ? sortDirection.desc : sortDirection.asc,
      [filterProps.lastSeen]: 0,
    };

    this.getKeyboxes(newParams);
  };

  loadMore = () => {
    const { filters } = this.state;
    const newParams = { ...filters };

    this.getKeyboxes(newParams, true);
  };

  handleSearch = SearchText => {
    const { filters } = this.state;
    const newParams = {
      ...filters,
      [filterProps.lastSeen]: defaultFilters[filterProps.lastSeen],
      SearchText,
    };

    this.getKeyboxes(newParams);
  };

  handleInputChange = e => {
    this.searchChangeDebounced(e.target.value);
  };

  deleteCallback = id => {
    const { keyboxes } = this.state;
    this.setState({ rightCollapsed: true, keyboxes: filter(keyboxes, item => item[fields.id] !== id) });
  };

  editCallback = newItem => {
    const { keyboxes } = this.state;
    this.setState({
      keyboxes: map(keyboxes, item => {
        if (item[fields.id] === newItem[fields.id]) {
          item = {
            ...item,
            ...newItem,
          };
        }
        return item;
      }),
    });
  };

  openIssueKeyPopup = (key, issueKey) => {
    const { location } = this.props;
    const { query } = location;

    const closeAction = () =>
      this.setState({
        modalData: {
          isOpen: false,
        },
      });

    const projectId = get(query, 'project_id');
    const keyboxId = get(query, 'selected_item');

    this.setState({
      modalData: {
        isOpen: true,
        closeAction,
        confirmAction: closeAction,
        type: 'none',
        CustomContent: () => <IssueKeyPopup closeAction={closeAction} issueKey={issueKey} selectedKey={key} projectId={projectId} keyboxId={keyboxId} />,
      },
    });
  };

  issueKey = (keyboxID, keyID, contractor, successCallback = () => null) => {
    const { keyboxes } = this.state;
    const { issueKey } = this.props;

    issueKey(keyID, contractor, (...args) => {
      successCallback(...args);
      const foundIndex = keyboxes.findIndex(item => item[fields.id] === keyboxID);
      if (foundIndex > -1) {
        const item = keyboxes[foundIndex];
        const isFirstKeyTaken = item[fields.issuedkeys] === 0;

        this.setState({
          keyboxes: [
            ...keyboxes.slice(0, foundIndex),
            {
              ...item,
              ...{
                [fields.issuedkeys]: item[fields.issuedkeys] + 1,
                [fields.freeKeys]: item[fields.freeKeys] - 1,
                [fields.status]: isFirstKeyTaken ? statusTypes.locked : item[fields.status],
              },
            },
            ...keyboxes.slice(foundIndex + 1),
          ],
        });
      }
    });
  };

  returnKey = (keyboxID, keyID, successCallback = () => null) => {
    const { keyboxes } = this.state;
    const { returnKey } = this.props;

    returnKey(keyID, (...args) => {
      successCallback(...args);
      const foundIndex = keyboxes.findIndex(item => item[fields.id] === keyboxID);
      if (foundIndex > -1) {
        const item = keyboxes[foundIndex];
        const isLastKeyReturned = item[fields.issuedkeys] - 1 === 0;
        this.setState({
          keyboxes: [
            ...keyboxes.slice(0, foundIndex),
            {
              ...item,
              ...{
                [fields.issuedkeys]: item[fields.issuedkeys] - 1,
                [fields.freeKeys]: item[fields.freeKeys] + 1,
                [fields.status]: isLastKeyReturned ? statusTypes.notInUse : item[fields.status],
              },
            },
            ...keyboxes.slice(foundIndex + 1),
          ],
        });
      }
    });
  };

  render() {
    const { location, user } = this.props;
    const { query } = location;
    const { keyboxes, filters, isLoading, modalData, rightCollapsed } = this.state;
    const { t } = this.context;

    const projectId = parseInt(get(query, 'project_id'), 10);
    const inspectionId = parseInt(get(query, 'inspection_id'), 10);
    const backButtonPath = user[params.fullScreenEnabled] ? routes.protectedRoutes.fullScreen.fullPath : routes.protectedRoutes.inspections.fullPath;
    const selected_item = (location.query.selected_item && parseInt(location.query.selected_item)) || null;

    const sidebarClosed = rightCollapsed || !selected_item;

    return (
      <div className="keybox-wrapper">
        <div className={`keyboxes ${!sidebarClosed ? 'sidebar-open' : ''}`}>
          <PageNavigation
            backButtonPath={`${backButtonPath}?project_id=${projectId}&inspection_id=${inspectionId}`} // redirect to the main screen, no need for additional query params
            title="KEYBOX.TITLE"
            icon="key"
            withBottomBorder
            actionButtontext="CREATE_BUTTON_TEXT.NEW"
            handleActionButtonClick={this.openCreateKeybox}
            actionButtonProps={{
              visibleFor: [PERMISSIONS[PERMISSION_TYPES.keybox].create.name],
            }}
          />
          <div className="keyboxes__search-input-wrapper">
            <SearchInput onChange={this.handleInputChange} placeholder={t('PROJECT.SEARCH.PLACEHOLDER')} stripped />
          </div>
          <div className={`keyboxes__table-wrapper`}>
            <ItemsTableRenderer
              tableConfig={tableConfig}
              data={map(keyboxes || [], item => ({ ...item, selected: item[fields.id] === selected_item }))}
              tableCustomClass="keyboxes__table-wrapper__table"
              onRowClick={this.onRowClick}
              onColumnSort={this.onColumnSort}
              sortingObj={filters}
              translationModule={t}
              stickyHeader={true}
              emptyStateText="KEYBOX.EMPTY_STATE"
              formatCell={(value, type, index, item) => {
                if (type === fields.status) {
                  return (
                    <div className="status-wrapper">
                      <div className={`status ${value === statusTypes.notInUse ? '' : 'used'}`}></div>
                      <p className="f-primary">{t(`KEYBOX.${value}`)}</p>
                    </div>
                  );
                }
                if (type === fields.totalKeys) {
                  return item[fields.freeKeys] + item[fields.issuedkeys];
                }

                if (type === fields.isolationCertificates) {
                  const linkedIsolationCertificatesNumber = item[fields.isolationCertificates]?.length || 0;
                  if (linkedIsolationCertificatesNumber === 0) return '-';

                  if (linkedIsolationCertificatesNumber < 2) {
                    return linkedIsolationCertificatesNumber && item[fields.isolationCertificates] && item[fields.isolationCertificates][0] ? item[fields.isolationCertificates][0][fields.name] : '-';
                  } else {
                    return (
                      <div className="name">
                        <InfoTooltip
                          customComponent={<KeyboxPopover certificateName={item[fields.name]} isolationCertificates={item[fields.isolationCertificates]} />}
                          offsetY={10}
                          offsetX={-20}
                          Component={() => (
                            <div className="isolation-certificates-popover">
                              <p className="f-primary">{t('ISOLATION_CERTIFICATES.LINKED_PERMITS_PLACEHOLDER', { number: linkedIsolationCertificatesNumber })}</p>
                              <Icon name="info" handleHover={false} size="xs" />
                            </div>
                          )}
                        />
                      </div>
                    );
                  }
                }

                return Helpers.formatCell(value, type, index, item);
              }}
            />
          </div>
          <div className="keyboxes__load-more-wrapper">
            <LoadMore
              disabled={!filters[filterProps.hasNext] || isLoading}
              loaded={keyboxes.length}
              total={filters[filterProps.totalItems]}
              totalPosition="center"
              isLoading={isLoading}
              label="COMPONENT_HISTORY.LOAD_MORE"
              showButton
              showTotalUp
              buttonVariant="success-outline"
              resultsText="KEYBOX_LIST.SHOWING_TOTAL_ITEMS"
              onClick={this.loadMore}
            />
          </div>
        </div>
        <CollapsibleToolbar collapsed={sidebarClosed} toggleToolbar={this.toggleToolbar} placement={placements.right} resizable={false}>
          <KeyboxWrapper
            selected_item={selected_item}
            deleteCallback={this.deleteCallback}
            openEditKeybox={this.openEditKeybox}
            editCallback={this.editCallback}
            openConfirmationPopup={this.openConfirmationPopup}
            openIssueKeyPopup={this.openIssueKeyPopup}
            issueKey={this.issueKey}
            returnKey={this.returnKey}
            location={location}
          />
        </CollapsibleToolbar>
        <Modal {...modalData} keyboxes={Object.assign([], keyboxes)} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.userReducer,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchKeyboxes: (filters, keyboxes, callback, loadMore) => dispatch(fetchKeyboxes(filters, keyboxes, callback, loadMore)),
  createKeybox: (data, successCallback) => dispatch(createKeybox(data, successCallback)),
  issueKey: (keyID, contractor, successCallback) => dispatch(issueKey(keyID, contractor, successCallback)),
  returnKey: (keyID, successCallback, errorCallback) => dispatch(returnKey(keyID, successCallback, errorCallback)),
});

Keybox.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Keybox);
