import React from 'react';
import PropTypes from 'prop-types';

import ModuleActions from './module-actions';

import '../../../styles/module-item-header.scss';
import RenderIf from '../../../../../common/render-if/components/render-if';

const ModuleHeader = (
  {
    id,
    handleMaximize,
    handleShare,
    handleDownload,
    menuOptions = [],
    withBlueBackground = false,
    isDownloadDisabled = false,
    hideDownload,
    hideShare,
    hideMaximize,
    isCustomProp = false,
    isCustomPropTranslation,
    isCustomPropData,
    multipleDownload = false,
    multipleDownloadOptions = [],
  },
  { t }
) => {
  return (
    <div className={`header-container ${withBlueBackground ? 'with-blue-background' : ''}`}>
      <div className="inline">
        <RenderIf if={!isCustomProp}>
          <p className="f-secondary-dark ">{t('ISOLATION_CERTIFICATE.ID')}</p>
          <p className="f-primary  display-id">{id}</p>
        </RenderIf>
        <RenderIf if={isCustomProp}>
          <p className="f-secondary-dark ">{t(isCustomPropTranslation)}:</p>
          <p className="f-primary display-id">{isCustomPropData ? isCustomPropData : '-'}</p>
        </RenderIf>
      </div>
      <div className="header-actions">
        <ModuleActions
          handleMaximize={handleMaximize}
          handleShare={handleShare}
          handleDownload={handleDownload}
          menuOptions={menuOptions}
          isDownloadDisabled={isDownloadDisabled}
          hideDownload={hideDownload}
          hideShare={hideShare}
          hideMaximize={hideMaximize}
          multipleDownload={multipleDownload}
          multipleDownloadOptions={multipleDownloadOptions}
        />
      </div>
    </div>
  );
};

ModuleHeader.contextTypes = {
  t: PropTypes.func.isRequired,
};

ModuleHeader.propTypes = {
  id: PropTypes.number.isRequired,
  handleMaximize: PropTypes.func,
  handleShare: PropTypes.func,
  handleDownload: PropTypes.func,
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      action: PropTypes.func,
      access: PropTypes.shape({
        visibleFor: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
        id: PropTypes.number,
        ownerRequiredPermission: PropTypes.string,
      }),
      isDisabled: PropTypes.bool,
      isHidden: PropTypes.bool,
    })
  ),
  withBlueBackground: PropTypes.bool,
  isDownloadDisabled: PropTypes.bool,
  hideDownload: PropTypes.bool,
  hideShare: PropTypes.bool,
  hideMaximize: PropTypes.bool,
};

export default ModuleHeader;
