import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Helpers from '../../../../common/helpers';
import { DMSCategoriesModules } from '../../../document-management/constants/constants';
import { fetchDMSCategories } from '../../../project/actions/project-actions';
import { setElementDetails, setNotificationFormState, setNotificationsFilters, setToggleNotificationsHidden, setToggledNotification } from '../../actions/action-creators';
import { updateElementGeometry } from '../../actions/inspection-actions';
import { severityDefault } from '../../constants/defect-constants';
import '../../styles/notification-list.scss';
import NotificationsWrapper from '../notifications/components/notifications-wrapper';
import { defaultFilters, fields, filterProps } from '../notifications/constants/constants';
import ModuleHeader from './common/module-header';
import InspectionRenderer from './inspection-renderer';

const Notifications = ({
  getNotifications,
  title,
  backAction,
  moduleActionsMenu,
  icon,
  isFullScreen = false,
  handleItemClick,
  loading,
  notifications,
  setClusteringFilters,
  elementsClustered,
  setToggledNotification,
  areAllNotificationsHidden,
  setToggleNotificationsHidden,
  queryItem,
  notificationLocationEditing,
  updateGeometry,
  selectedNotification,
  setSelectedNotification,
  setNotificationFormState,
  unsavedChanges,
  formHasUnsavedChanges,
  filters,
  setNotificationsFilters,
  fetchDMSCategories,
  projectId,
  viewer,
}) => {
  useEffect(() => {
    fetchNotifications(defaultFilters);
    fetchDMSCategories(projectId, DMSCategoriesModules.notifications);

    return () => {
      setSelectedNotification(null);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchNotifications = (filters, loadMore) => {
    const onSuccess = (_items, newFilters) => {
      setNotificationsFilters({ ...filters, ...newFilters });
      if (loadMore) {
        Helpers.scrollIntoView('notification-list__table', `row-${filters[filterProps.lastSeen]}`, -400);
      }
    };

    const hasAppliedSeverityFilter = !isEmpty(filters[filterProps.severityFilter]); // checks if the User applied the Severity filter from the advanced filter popup
    const preppedFilters = {
      ...filters,
      [filterProps.statusFilter]: filters[filterProps.statusFilter].toString(),
      [filterProps.typeFilter]: filters[filterProps.typeFilter].toString(),
      [filterProps.sourceFilter]: filters[filterProps.sourceFilter].toString(),
      [filterProps.createdInLastXDaysFilter]: parseInt(filters[filterProps.createdInLastXDaysFilter], 10),
      [filterProps.severityFilter]: hasAppliedSeverityFilter ? filters[filterProps.severityFilter] : [severityDefault.filter.min, severityDefault.filter.max], // if the User did not apply Severity filter from the advanced filter popup, send the default [0, 10]
    };

    setClusteringFilters && setClusteringFilters({ ...preppedFilters });
    getNotifications(preppedFilters, onSuccess, loadMore);
  };

  const handleSelectElement = notification => handleItemClick(notification[fields.id]);

  const toggleNotification = ID => setToggledNotification(ID);

  const toggleAllNotifications = () => setToggleNotificationsHidden();

  return (
    <InspectionRenderer
      toggleElement={toggleNotification}
      updateElementGeometry={args => {
        const { ID: selectedNotificationId } = args;
        // prevents the update of geometry if other notification is trying to be moved
        if (queryItem !== selectedNotificationId) return;
        updateGeometry(args);
        setSelectedNotification({ ...selectedNotification, ...unsavedChanges, [fields.geometry]: args[fields.geometry], [fields.camPosition]: args[fields.camPosition] });
        setNotificationFormState({ hasUnsavedChanges: formHasUnsavedChanges });
      }}
      disableMove={!notificationLocationEditing}
      selectElement={handleSelectElement}
      elements={(elementsClustered || []).map(item => {
        if (item[fields.id] === queryItem) {
          return { ...item, enableMove: notificationLocationEditing };
        } else {
          return item;
        }
      })}
      queryItem={queryItem}
      viewer={viewer}
    >
      {({ elementClickHandler, elementShowHandler, elementHideHandler, selectAllHandler, deselectAllHandler }) => (
        <div className="notification-list">
          <ModuleHeader
            {...{
              title,
              icon,
              backAction,
              actionsMenu: moduleActionsMenu,
            }}
          />
          <NotificationsWrapper
            data={notifications}
            filters={filters}
            loading={loading}
            fetchNotifications={fetchNotifications}
            wrapperClassName="notification-list__wrapper uplift-modal" // uplift-modal used to force the appending class to body (in modal.js)
            customTableClassName="notification-list__table"
            isFullScreen={isFullScreen}
            onRowClick={row => elementClickHandler(null, row)}
            onDropdownClick={elementHideHandler}
            allHidden={areAllNotificationsHidden}
            toggleAll={toggleAllNotifications}
            selectedItem={queryItem}
          />
        </div>
      )}
    </InspectionRenderer>
  );
};

const mapStateToProps = state => ({
  filters: state.notificationReducer.notificationsFilters,
  notifications: state.notificationReducer.notifications,
  loading: state.notificationReducer.notificationsLoading,
  areAllNotificationsHidden: state.notificationReducer.areAllNotificationsHidden,
  notificationLocationEditing: state.inspectionReducer.notificationLocationEditing,
  selectedNotification: state.inspectionReducer.selectedDefect,
  unsavedChanges: state.notificationReducer.notificationForm.unsavedChanges,
  formHasUnsavedChanges: state.notificationReducer.notificationForm.hasUnsavedChanges,
  viewer: state.potreeReducer.viewerInstance,
});

const mapDispatchToProps = dispatch => ({
  setToggledNotification: ID => dispatch(setToggledNotification(ID)),
  setToggleNotificationsHidden: () => dispatch(setToggleNotificationsHidden()),
  updateGeometry: data => dispatch(updateElementGeometry(data)),
  setSelectedNotification: notification => dispatch(setElementDetails({ defect: notification })),
  setNotificationFormState: state => dispatch(setNotificationFormState(state)),
  setNotificationsFilters: filters => dispatch(setNotificationsFilters(filters)),
  fetchDMSCategories: (projectId, moduleName, callback) => dispatch(fetchDMSCategories(projectId, moduleName, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
