import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../../icon/components/icon';
import '../styles/_multiple-category-select.scss';

const MultipleCategorySelect = ({ selectedCheckedComponentsLength, onCancelSelection, onChangeCategory, onUnlinkCategory }, { t }) => {
  return (
    <div className="multiple-category-select">
      <div className="multiple-category-select__content left-side">
        <Icon name="close" handleHover onClick={onCancelSelection} />
        <span>
          {selectedCheckedComponentsLength} {t('SELECTED')}
        </span>
      </div>
      <div className={`multiple-category-select__content right-side ${selectedCheckedComponentsLength === 0 ? 'disabled' : ''}`}>
        <div onClick={onChangeCategory}>
          <Icon name="refreshIcon" handleHover />
          {t('CHANGE_CATEGORY')}
        </div>
        <div className="danger" onClick={onUnlinkCategory}>
          <Icon name="close" handleHover={false} />
          {t('COMPONENT_ASSETS_DROPDOWN.SETTINGS_MENU.UNLINK_FILE')}
        </div>
      </div>
    </div>
  );
};

MultipleCategorySelect.contextTypes = {
  t: PropTypes.func.isRequired,
};

MultipleCategorySelect.propTypes = {
  selectedCheckedComponentsLength: PropTypes.number.isRequired,
  onCancelSelection: PropTypes.func.isRequired,
  onChangeCategory: PropTypes.func.isRequired,
  onUnlinkCategory: PropTypes.func.isRequired,
};

MultipleCategorySelect.defaultProps = {
  selectedCheckedComponentsLength: 0,
  onCancelSelection: () => null,
  onChangeCategory: () => null,
  onUnlinkCategory: () => null,
};

export default MultipleCategorySelect;
