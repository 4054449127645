import { chain, cloneDeep, debounce, find, get, isEmpty, isEqual, map, remove, some, uniqBy, values } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { change, getFormSyncErrors } from 'redux-form';
import { ReactComponent as PDFIcon } from '../../../../common/assets/pdf-icon.svg';
import CommentsTab from '../../../../common/comments-tab/components/comments-tab';
import { commentFields } from '../../../../common/comments-tab/constants/constants';
import { FEATURES, FORMS } from '../../../../common/constants';
import FlowStatusWrapper from '../../../../common/flow-status/components/flow-status-wrapper';
import Button from '../../../../common/form/components/button';
import SimpleLoader from '../../../../common/global-loader/components/simple-loader';
import Helpers from '../../../../common/helpers';
import { notificationModalCustom } from '../../../../common/modal/actions/modal-actions';
import Modal from '../../../../common/modal/components/modal';
import StatusChangeNoteModal from '../../../../common/modal/components/status-change-note-modal';
import { setGenericNotification } from '../../../../common/notification/actions/action-creators';
import Notification from '../../../../common/notification/components/notification';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../common/permissions-constants';
import WorkAreasModal from '../../../../common/question-areas/components/work-areas-modal';
import { ComponentPicker3D, ComponentPickerCH, ComponentPickerPDF } from '../../../../common/question-components/components';
import RenderIf from '../../../../common/render-if/components/render-if';
import { routes } from '../../../../common/routes-constants';
import Tab from '../../../../common/tabs/component/tab';
import Tabs from '../../../../common/tabs/component/tabs';
import AddContributors from '../../../../common/user-team/components/add-contributors';
import { participantTypes } from '../../../../common/user-team/constants/constants';
import WarningIndicator from '../../../../common/warning-indicator/components/warning-indicator';
import { unlinkDMSFileFromWorkorder, unlinkDMSFileFromWorkorderCompletion } from '../../../document-management/actions/dm-api-calls';
import ActionModal from '../../../document-management/components/modals/action-modal/action-modal';
import { setPdfComponents } from '../../../pdf-tag/actions/action-creators';
import { changePDFPage, getPdfComponentsForPopup } from '../../../pdf-tag/actions/pdf-tag-actions';
import { componentFields, componentFilterValues } from '../../../pdf-tag/constants/constants';
import { params } from '../../../profile/constants/profile-constants';
import LinkedImagesSliderSlider from '../../../start-workflow/components/linked-images-slider';
import orientationConstants from '../../../start-workflow/constants/orientation-constants';
import { setSingleUploadItem, setUploadItems, uploadAsset } from '../../../upload/actions/upload-actions';
import {
  amendWorkOrderCPTemplate,
  linkWorkOrderCPTemplateToComponents,
  removeWorkOrderCPTemplate,
  removeWorkOrderComponentFromCPTemplate,
  setUnsavedChangesDirty,
  setWorkOrderAreas,
  setWorkOrderCPTemplates,
  setWorkOrderChecklists,
  setWorkOrderCompletionFiles,
  setWorkOrderComponents,
  unlinkWorkOrderCPTemplateFromComponents,
} from '../../actions/action-creators';
import {
  closeInspectionModal,
  fetchCommentUsersAndTeams,
  maximizeInspectionModal,
  minimizeInspectionModal,
  openAsSeparatePageInspectionModal,
  toggleInspectionModal,
} from '../../actions/inspection-actions';
import { getPermitTemplates } from '../../actions/permit-actions';
import {
  addWorkOrderArea,
  addWorkOrderComment,
  addWorkOrderComponent,
  addWorkOrderContractors,
  addWorkOrderContributor,
  addWorkOrderPermit,
  archiveWorkOrder,
  changeWorkOrderOnHold,
  changeWorkOrderStatus,
  createWorkOrderObservation,
  createWorkOrderObservationCP,
  createWorkOrderPermit,
  deleteWOFile,
  deleteWorkOrder,
  deleteWorkOrderComment,
  deleteWorkOrderPermit,
  fetchWorkOrderComments,
  fetchWorkOrderObservations,
  generateAndDownloadChecklistPDF,
  generateAndDownloadPDF,
  generateAndDownloadPDFChecklistTemplatePDF,
  getAdditionalWorkorderTypes,
  getCPTemplates,
  getChecklistTemplatesForWorkOrder,
  getPermits,
  getWorkOrderAreas,
  getWorkOrderChecklistDetails,
  getWorkOrderChecklistSectionQuestionDetails,
  getWorkOrderChecklistSectionQuestions,
  getWorkOrderChecklistSections,
  getWorkOrderChecklistTemplateSectionQuestions,
  getWorkOrderChecklistTemplateSections,
  getWorkOrderChecklists,
  getWorkOrderComponents,
  getWorkOrderContractors,
  getWorkOrderContributors,
  getWorkOrderCustomProperties,
  getWorkOrderFiles,
  getWorkOrderStatusFlows,
  getWorkorderDetails,
  getWorkorderTypes,
  removeWorkorderArea,
  removeWorkorderComponent,
  removeWorkorderContractors,
  removeWorkorderContributor,
  saveCPTemplates,
  searchComponents,
  searchPermits,
  searchUsersAndTeams,
  searchWorkOrderAssignees,
  searchWorkOrderContributors,
  singleItemUploadDone,
  submitChecklist,
  updateChecklistAnswer,
  updateWorkOrderArea,
  updateWorkOrderGeometry,
  updateWorkorder,
  workOrderChecklistValidate,
} from '../../actions/work-order-actions';
import { modules } from '../../constants/constants';
import { observationsTypes } from '../../constants/defect-constants';
import { statuses } from '../../constants/work-order-constants';
import { isWorkOrderChangeStatusValid, setCameraPositionAndFocusOnFirstWorkArea } from '../../helpers/inspection-helper';
import '../../styles/component-files-modal.scss';
import '../../styles/create-work-order-modal.scss';
import '../../styles/work-order-right-side.scss';
import InspectionRenderer from '../left-toolbar/inspection-renderer';
import { defaultFilter, filterFields } from '../permits/constants/permit-form-constants';
import ChangeStatusDropdown from '../right-toolbar/common/change-status-dropdown';
import CriticalEquipmentInfo from '../right-toolbar/common/critical-equipment-info';
import EditModuleItemFilesModal from '../right-toolbar/common/edit-module-item-files-modal';
import ModuleHeader from '../right-toolbar/common/module-header';
import ComponentPDF from '../right-toolbar/component-pdf';
import { getWorkorderCompletionDMSFilesUploaded, getWorkorderDMSFilesUploaded } from '../work-orders/actions/work-orders-api-calls';
import AddContractors from './add-contractors';
import AddCPTemplates from './add-cp-templates';
import AddObservationsToWorkOrder from './add-observations';
import AddPermits from './add-permits';
import BasicDetailsForm from './basic-details-form';
import { WOFileGroups, assigneeFields, checklistFields, formConstants, imagesValidation, notificationValidation, validationTypes } from './constants/constants';
import { checklistTemplateFields, fields, tabNames, toolbarItems, fields as workOrderField } from './constants/work-order-right-side-constants';
import { sections } from './constants/work-order-sections-constants';
import DownloadWorkOrderPdfOptions from './download-work-order-pdf-options';
import ManageAlertForm from './manage-alert-form';
import SubmitChecklistModal from './submit-checklist-modal/submit-checklist-modal';
import { workorderValidate } from './validators/workorder-validator';
import WorkOrderCompletedForm from './work-order-completed-form';
import WorkOrderCompletionSections from './work-order-completion-sections';
import WorkOrderComponents from './work-order-components';
import WorkOrderCreatePermitForm from './work-order-create-permit-form';
import WorkOrderSectionChecklistDetails from './work-order-section-checklist-details';
import WorkOrderSections from './work-order-sections';

class WorkOrderRightSide extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeToolbarItem: toolbarItems[tabNames.details].name,
      workOrderTypes: [],
      workOrderDetails: {},
      permitsList: [],
      components: [],
      users: [],
      assignees: [],
      collaborators: [],
      permitTemplates: [],
      cpTemplates: [],
      editFilesModalData: {
        isOpen: false,
      },
      uploadModalData: {
        isOpen: false,
      },
      confirmModalData: {
        isOpen: false,
      },
      screenShotPickerModal: {
        isOpen: false,
      },
      isDownloadInProgress: false,
      changeStatusConfirmModal: {
        isOpen: false,
      },
      changeStatusInProgress: false,
      completedStatusModal: {
        isOpen: false,
      },
      addPermitsModal: {
        isOpen: false,
      },
      createWorkOrderPermitModal: {
        isOpen: false,
      },
      addComponentsModal: {
        isOpen: false,
      },
      componentSelectionModal: {
        isOpen: false,
      },
      messageModalData: {
        isOpen: false,
      },
      addContractorsModal: {
        isOpen: false,
      },
      addAssigneesModal: {
        isOpen: false,
      },
      workAreasModal: {
        isOpen: false,
      },
      addAlertsModal: {
        isOpen: false,
      },
      statusChangeNoteModalData: {
        isOpen: false,
      },
      filesModalData: {
        isOpen: false,
      },
      addCPTemplatesModal: {
        isOpen: false,
      },
      addObservationsModal: {
        isOpen: false,
      },
      workOrderAlerts: [],
      userAlerts: [],
      commentsList: [],
      commentsLoading: false,
      addCommentLoading: false,
      commentUsersList: [],
      commentTeamsList: [],
      commentUsersAndTeamsLoading: false,
      notificationData: { isDisplayed: false },
    };

    this.searchPermitsDebounced = debounce(this.handleSearchPermits, 300);
    this.searchCPTemplatesDebounced = debounce(this.handleSearchCPTemplates, 300);
    this.searchPermitTemplatesDebounce = debounce(this.onSearchPermitTemplates, 600);
    this.handleAnswerQuestionDebounce = debounce(this.handleAnswerQuestion, 600);
    this.addWorkOrderCPTemplateDebounce = debounce(this.handleAddWorkOrderCPTemplate, 200);
    this.removeWorkOrderCPTemplateDebounce = debounce(this.handleRemoveWorkOrderCPTemplate, 200);
  }

  componentDidMount = () => {
    const { getWorkOrderTypes, getAdditionalWorkorderTypes } = this.props;

    getWorkOrderTypes(workOrderTypes => this.setState({ workOrderTypes }));
    getAdditionalWorkorderTypes(additionalWorkorderTypes => this.setState({ additionalWorkorderTypes }));
    this.fetchInitialData();
  };

  componentDidUpdate = prevProps => {
    const { queryItem, viewer } = this.props;

    if (queryItem && queryItem > 0 && (prevProps.queryItem !== queryItem || viewer !== prevProps.viewer)) {
      this.setState({
        activeToolbarItem: toolbarItems[tabNames.details].name,
      });
      this.fetchInitialData();
    }
  };

  componentWillUnmount = () => {
    const { setWorkOrderAreas, setWorkOrderComponents } = this.props;

    setWorkOrderAreas([]);
    setWorkOrderComponents([]);
  };

  fetchInitialData = () => {
    const {
      queryItem,
      getWorkOrderDetails,
      getWorkOrderComponents,
      getWorkOrderContractors,
      getWorkOrderContributors,
      getWorkOrderChecklists,
      getWorkOrderAreas,
      getPermits,
      getWorkorderDMSFilesUploaded,
      getCPTemplates,
      setWorkOrderCompletionFiles,
      getWorkorderCompletionDMSFilesUploaded,
      getWorkOrderObservations,
      viewer,
      setUnsavedChangesDirty,
      setWorkOrderChecklists,
    } = this.props;

    if (queryItem) {
      setUnsavedChangesDirty(false);

      // reset WO Work Order C&Ps on change of selected WO
      setWorkOrderChecklists([]);
      // reset WO completion files on change of selected WO
      setWorkOrderCompletionFiles([]);
      getWorkOrderDetails(queryItem, true, workOrderDetails => {
        this.setState({ workOrderDetails });
        getWorkOrderAreas(queryItem, ({ workAreas }) => {
          setCameraPositionAndFocusOnFirstWorkArea(workOrderDetails?.CameraPosition, workAreas, viewer);
        });
      });

      getWorkOrderComponents(queryItem);
      getWorkOrderContractors(queryItem);
      getWorkOrderContributors(queryItem);
      getWorkOrderObservations(queryItem);
      getWorkOrderChecklists(queryItem);
      getWorkorderDMSFilesUploaded(queryItem);
      getWorkorderCompletionDMSFilesUploaded(queryItem);
      getPermits(queryItem);
      getCPTemplates(queryItem);
    }
  };

  onSearchPermitTemplates = params => {
    const { getPermitTemplates } = this.props;

    getPermitTemplates(params, permitTemplates => this.setState({ permitTemplates }));
  };

  handleMaximize = () => {};

  handleDownload = () => {
    const { t } = this.context;

    const { isDownloadInProgress } = this.state;

    if (isDownloadInProgress) {
      return;
    }

    // first show the new modal with options (w & w/o checklists)
    // if Cancel clicked, close the new modal
    this.setState({ isDownloadInProgress: true });
    const { queryItem, generateAndDownloadPDF } = this.props;

    const confirmModalData = {
      isOpen: true,
      title: t('WORK_ORDER.DOWNLOAD_WORKORDER_PDF'),
      content: (
        <DownloadWorkOrderPdfOptions
          submitForm={values => {
            // close action modal
            this.setState({ confirmModalData: { isOpen: false } });
            const additionalParams = (Object.keys(values) || []).map(key => ({ [key]: values[key] }));
            generateAndDownloadPDF(queryItem, additionalParams, () => {
              this.setState({ isDownloadInProgress: false, confirmModalData: { isOpen: false } });
            });
            // now display the notification
            this.setState({
              notificationData: {
                isDisplayed: true,
                type: 'info',
                icon: 'check-toast',
                text: t('ISOLATION_CERTIFICATE.DOWNLOAD_NOTIFICATION'),
                onClickClose: this.closeNotification,
              },
            });
            this.closeNotificationTimeout();
          }}
          closeAction={() => this.setState({ confirmModalData: { isOpen: false } })}
        />
      ),

      type: 'cancel-download',
      customClassName: 'download-workorder-pdf-modal',
      closeAction: () => this.setState({ confirmModalData: { isOpen: false } }),
    };

    this.setState({ confirmModalData });
    this.setState({ isDownloadInProgress: false });
  };

  handleShare = () => {};

  handleBasicDetailsChange = (values, c) => {
    const { setUnsavedChangesDirty } = this.props;
    setUnsavedChangesDirty(c.dirty);
    if (!c.pristine) {
      const errors = workorderValidate(values);

      if (!isEmpty(errors)) {
        if (typeof c.touch === 'function') {
          c.touch(workOrderField.startDate);
          c.touch(workOrderField.endDate);
          c.touch(workOrderField.dueDate);
        }

        Helpers.scrollToFirstError(errors);

        return;
      }
    }
  };

  toggleConfirmationModal = (isOpen, title, confirmAction, content) => {
    const { t } = this.context;
    const { setUnsavedChangesDirty } = this.props;

    if (isOpen) {
      const handleConfirmAction = () => {
        if (typeof confirmAction === 'function') {
          confirmAction();
        }
      };

      this.setState({
        confirmModalData: {
          isOpen: true,
          title: t(title),
          type: 'yes-no',
          content,
          customClassName: 'work-order-confirm-modal',
          confirmAction: handleConfirmAction,
          closeAction: () => {
            this.setState({ confirmModalData: { isOpen: false } });
            // hasUnsavedChanges: true added due to the cancel action, the state persists and enables the user to save unsaved form changes
            setUnsavedChangesDirty(true);
          },
        },
      });
    } else {
      this.setState({ confirmModalData: { isOpen: false } });
    }
  };

  handleSearchPermits = searchText => {
    const { searchPermits, queryItem } = this.props;

    searchPermits({ [fields.workOrderID]: queryItem, [fields.searchText]: searchText || '' }, permitsList => this.setState({ permitsList }));
  };

  handleSearchCPTemplates = searchText => {
    const { searchCPTemplates, projectId } = this.props;

    searchCPTemplates({ ...defaultFilter, [filterFields.projectID]: projectId, [fields.searchText]: searchText || '' }, [], ({ checklists }) => this.setState({ cpTemplates: checklists || [] }));
  };

  openEditFilesModal = moduleType => {
    const { t } = this.context;
    const { projectDMSCategories, workOrderFormValues } = this.props;

    this.setState({
      editFilesModalData: {
        isOpen: true,
        type: '',
        title: t('WORK_ORDER.EDIT_FILES'),
        CustomContent: dynamicProps => (
          <EditModuleItemFilesModal
            {...dynamicProps}
            projectDMSCategories={projectDMSCategories}
            selectedModuleItem={workOrderFormValues}
            moduleType={moduleType}
            // overrides for a specific case for WO Completion tab
            overrideModuleTypeWithProp={moduleType === modules.workorderCompletion}
          />
        ),
        customClassName: 'modal-no-max-height modal-large',
        closeAction: () => this.setState({ editFilesModalData: { isOpen: false } }),
        customCloseAction: () => this.setState({ editFilesModalData: { isOpen: false } }),
      },
    });
  };

  unlinkFile = (file, isFromGallery = false) => {
    const { t } = this.context;
    const { getWorkorderDMSFilesUploaded, queryItem, unlinkDMSFileFromWorkorder } = this.props;
    const unlinkFileSuccess = () => {
      unlinkDMSFileFromWorkorder(file.SourceID, queryItem, file.CategoryID, () => getWorkorderDMSFilesUploaded(queryItem));

      this.toggleConfirmationModal(false);
      isFromGallery && this.setState({ filesModalData: { isOpen: false } });
    };
    this.toggleConfirmationModal(true, 'UNLINK_FILE', unlinkFileSuccess, t('COMPONENT_DETAILS.DELETE_FILE_DESC', { fileName: file.FileName }));
  };

  unlinkCompletionFile = (file, isFromGallery = false) => {
    const { t } = this.context;
    const { getWorkorderCompletionDMSFilesUploaded, queryItem, unlinkDMSFileFromWorkorderCompletion } = this.props;
    const unlinkFileSuccess = () => {
      unlinkDMSFileFromWorkorderCompletion(file.SourceID, queryItem, file.CategoryID, () => getWorkorderCompletionDMSFilesUploaded(queryItem));

      this.toggleConfirmationModal(false);
      isFromGallery && this.setState({ filesModalData: { isOpen: false } });
    };
    this.toggleConfirmationModal(true, 'UNLINK_FILE', unlinkFileSuccess, t('COMPONENT_DETAILS.DELETE_FILE_DESC', { fileName: file.FileName }));
  };

  handleAddWorkOrderPermit = permit => {
    const { queryItem, addWorkOrderPermit } = this.props;

    addWorkOrderPermit(queryItem, permit);
  };

  handleRemoveWorkOrderPermit = permit => {
    const { queryItem, deleteWorkOrderPermit } = this.props;

    deleteWorkOrderPermit(queryItem, permit);
  };

  handleAddWorkOrderCPTemplate = cpTemplate => {
    const { addWorkOrderCPTemplate } = this.props;

    addWorkOrderCPTemplate({ ...cpTemplate, [checklistTemplateFields.linkedToEquipment]: cpTemplate[checklistTemplateFields.componentID] !== undefined });
  };

  handleRemoveWorkOrderCPTemplate = cpTemplate => {
    const { deleteWorkOrderCPTemplate } = this.props;

    deleteWorkOrderCPTemplate(cpTemplate);
  };

  onCreateWorkOrderPermitFormSubmit = values => {
    const { queryItem, createWorkOrderPermit } = this.props;

    createWorkOrderPermit({ ...values, WorkOrderID: queryItem }, queryItem, true, () => {
      this.setState({ createWorkOrderPermitModal: { isOpen: false }, addPermitsModal: { isOpen: false } });
    });
  };

  handleOpenCreateWorkOrderPermitModal = () => {
    const { projectId, changeField, getPermitTemplates, workOrderFormValues } = this.props;
    const { t } = this.context;

    getPermitTemplates({ ...defaultFilter, [filterFields.projectID]: projectId }, permitTemplates => this.setState({ permitTemplates }));

    this.setState(prevState => ({
      createWorkOrderPermitModal: {
        ...prevState.createWorkOrderPermitModal,
        title: t('WORK_ORDER.CREATE_PERMIT_MODAL_TITLE'),
        customClassName: 'work-order-default-modal create-work-order-modal',
        CustomContent: dynamicProps => (
          <WorkOrderCreatePermitForm
            handleFormSubmit={this.onCreateWorkOrderPermitFormSubmit}
            closeModal={() => this.setState({ createWorkOrderPermitModal: { isOpen: false } })}
            projectId={projectId}
            onSearchPermitTemplates={this.searchPermitTemplatesDebounce}
            changeField={changeField}
            initialPermitName={workOrderFormValues[fields.name]}
            initialPermitDescription={workOrderFormValues[fields.description]}
            {...dynamicProps}
          />
        ),
        isOpen: true,
        type: 'none',
        closeAction: () => this.setState({ createWorkOrderPermitModal: { isOpen: false } }),
      },
    }));
  };

  getAllPermits = () => {
    const { permitsList } = this.state;

    return chain(permitsList).uniqBy(fields.id).sortBy(fields.id).value();
  };

  openPermitsModal = () => {
    const { t } = this.context;
    const { user } = this.props;
    this.handleSearchPermits();

    this.setState(prevState => ({
      addPermitsModal: {
        ...prevState.addPermitsModal,
        title: t('WORK_ORDER.EDIT_PERMITS'),
        customClassName: 'work-order-default-modal create-work-order-modal',
        CustomContent: dynamicProps => (
          <div className="work-order-form">
            <AddPermits
              handleSearchPermits={this.searchPermitsDebounced}
              addPermitToWorkorder={this.handleAddWorkOrderPermit}
              removePermitFromWorkorder={this.handleRemoveWorkOrderPermit}
              openCreateWorkOrderPermitModal={this.handleOpenCreateWorkOrderPermitModal}
              user={user}
              {...dynamicProps}
            />
            <div className="work-order-default-modal-buttons">
              <Button type="button" variant="gray-outline" height="md" width="sm" onClick={() => this.setState({ addPermitsModal: { isOpen: false } })} text={t('CANCEL')} />
              <Button className="modal-confirm-btn" height="md" width="sm" type="button" onClick={() => this.setState({ addPermitsModal: { isOpen: false } })} text={t('OK')} />
            </div>
          </div>
        ),
        isOpen: true,
        type: 'none',
        confirmAction: () => this.setState({ addPermitsModal: { isOpen: false } }),
        closeAction: () => this.setState({ addPermitsModal: { isOpen: false } }),
      },
    }));
  };

  openCPTemplatesModal = () => {
    const { t } = this.context;
    const {
      user,
      linkWorkorderChecklist,
      unlinkWorkorderChecklist,
      removeComponentFromCPTemplate,
      workOrderComponents,
      workOrderCPTemplates,
      setWorkOrderCPTemplates,
      saveCPTemplates,
      queryItem,
      setGenericNotification,
    } = this.props;

    const previousWorkOrderCPTemplates = cloneDeep(workOrderCPTemplates);
    if (
      !Helpers.hasAccess({
        user,
        visibleFor: [PERMISSIONS[PERMISSION_TYPES.workOrders].workOrdersCPLink.name],
      })
    ) {
      return;
    }
    this.handleSearchCPTemplates();

    const resetWorkOrderCPTemplates = () => {
      // reset state to previous if not saved
      setWorkOrderCPTemplates(previousWorkOrderCPTemplates);
    };

    const closeAction = currentCPTemplates => {
      if (isEqual(previousWorkOrderCPTemplates, currentCPTemplates)) {
        resetWorkOrderCPTemplates();
        this.setState({ addCPTemplatesModal: { isOpen: false } });
      } else {
        // show confirmation popup
        this.toggleConfirmationModal(
          true,
          'WORK_ORDER.EDIT_CP_TEMPLATES.UNSAVED_CHANGES_CONFIRM',
          () => {
            this.toggleConfirmationModal(false);
            resetWorkOrderCPTemplates();
            this.setState({ addCPTemplatesModal: { isOpen: false } });
          },
          t('WORK_ORDER.EDIT_CP_TEMPLATES.UNSAVED_CHANGES_CONFIRM_DESC')
        );
      }
    };

    this.setState(prevState => ({
      addCPTemplatesModal: {
        ...prevState.addCPTemplatesModal,
        title: t('WORK_ORDER.EDIT_CP_TEMPLATES'),
        customClassName: 'work-order-default-modal create-work-order-modal',
        CustomContent: dynamicProps => (
          <div className="work-order-form">
            <AddCPTemplates
              handleSearchCPTemplates={this.searchCPTemplatesDebounced}
              addCPTemplateToWorkorder={this.addWorkOrderCPTemplateDebounce}
              removeCPTemplateFromWorkorder={this.removeWorkOrderCPTemplateDebounce}
              user={user}
              linkToComponent={linkWorkorderChecklist}
              unlinkFromComponent={unlinkWorkorderChecklist}
              workorderComponents={workOrderComponents}
              removeComponentFromCPTemplate={removeComponentFromCPTemplate}
              {...dynamicProps}
            />
            <div className="work-order-default-modal-buttons">
              <Button type="button" variant="gray-outline" height="md" width="sm" onClick={() => closeAction(dynamicProps.workorderCPTemplates)} text={t('CANCEL')} />
              <Button
                className="modal-confirm-btn"
                height="md"
                width="sm"
                type="button"
                onClick={() => {
                  saveCPTemplates(
                    queryItem,
                    { ChecklistTemplateIDs: Helpers.removeStandaloneCPTemplatesIfLinked(dynamicProps.workorderCPTemplates) },
                    () => {
                      setGenericNotification({
                        isDisplayed: true,
                        type: 'success',
                        icon: 'checkmark-outline',
                        text: t('WORK_ORDER.EDIT_CP_TEMPLATES.ON_SAVE_SUCCESS'),
                      });
                    },
                    e => resetWorkOrderCPTemplates()
                  );
                  this.setState({ addCPTemplatesModal: { isOpen: false } });
                }}
                text={t('SAVE')}
              />
            </div>
          </div>
        ),
        isOpen: true,
        type: 'none',
        closeAction,
      },
    }));
  };

  handleSearchComponents = searchText => {
    const { inspectionId, searchComponents } = this.props;

    searchComponents(inspectionId, searchText || '', components => this.setState({ components }), { PerPage: Number.MAX_SAFE_INTEGER, LastSeen: 0 });
  };

  handleAddWorkOrderComponent = items => {
    const { addWorkOrderComponent, queryItem } = this.props;
    const component = items && items.length ? items[0] : {};

    addWorkOrderComponent(queryItem, component);
  };

  handleRemoveWorkOrderComponent = item => {
    const { queryItem, removeWorkorderComponent } = this.props;
    const component = Number.isInteger(item) ? { [fields.id]: item } : item;

    removeWorkorderComponent(queryItem, component);
  };

  handleComponentPicker3D = () => {
    const { inspectionId } = this.props;
    const closeAction = () => {
      this.setState({ componentSelectionModal: { isOpen: false } });
    };

    this.setState({
      componentSelectionModal: {
        isOpen: true,
        CustomContent: dynamicProps => (
          <ComponentPicker3D
            {...dynamicProps}
            handleComponentSelected={(_moduleId, components) => this.handleAddWorkOrderComponent(components)}
            handleDeleteComponent={(_moduleId, componentId) => this.handleRemoveWorkOrderComponent(componentId)}
            inspectionId={inspectionId}
            closeAction={closeAction}
          />
        ),
        customClassName: 'modal-no-max-height modal-large',
        type: 'none',
        closeAction,
      },
    });
  };

  handleComponentPickerPDF = () => {
    const { inspectionId } = this.props;
    const closeAction = () => {
      this.setState({ componentSelectionModal: { isOpen: false } });
    };

    this.setState({
      componentSelectionModal: {
        isOpen: true,
        CustomContent: dynamicProps => (
          <ComponentPickerPDF
            {...dynamicProps}
            handleComponentSelected={(_moduleId, components) => this.handleAddWorkOrderComponent(components)}
            handleDeleteComponent={(_moduleId, componentId) => this.handleRemoveWorkOrderComponent(componentId)}
            inspectionId={inspectionId}
            closeAction={closeAction}
          />
        ),
        customClassName: 'modal-no-max-height modal-large',
        type: 'none',
        closeAction,
      },
    });
  };

  handleComponentPickerCH = () => {
    const { inspectionId } = this.props;
    const { t } = this.context;
    const closeAction = () => {
      this.setState({ componentSelectionModal: { isOpen: false } });
    };

    this.setState({
      componentSelectionModal: {
        isOpen: true,
        CustomContent: dynamicProps => (
          <ComponentPickerCH
            {...dynamicProps}
            handleComponentSelected={(_moduleId, components) => this.handleAddWorkOrderComponent(components)}
            handleDeleteComponent={(_moduleId, componentId) => this.handleRemoveWorkOrderComponent(componentId)}
            inspectionId={inspectionId}
            closeAction={closeAction}
          />
        ),
        customClassName: 'modal-no-max-height modal-large',
        type: 'none',
        title: t('QUESTION_COMPONENT_PICKER.CHOOSE_COMPONENTS'),
        closeAction,
        customCloseAction: closeAction,
      },
    });
  };

  openComponentsModal = () => {
    const { t } = this.context;
    this.handleSearchComponents();

    this.setState(prevState => ({
      addComponentsModal: {
        ...prevState.addComponentsModal,
        title: t('WORK_ORDER.EDIT_COMPONENTS'),
        customClassName: 'work-order-default-modal create-work-order-modal',
        CustomContent: dynamicProps => (
          <div className="work-order-form">
            <WorkOrderComponents
              addComponentToWorkorder={this.handleAddWorkOrderComponent}
              handleComponentPicker3D={this.handleComponentPicker3D}
              handleComponentPickerPDF={this.handleComponentPickerPDF}
              handleComponentPickerCH={this.handleComponentPickerCH}
              removeComponentFromWorkorder={this.handleRemoveWorkOrderComponent}
              hideCheckbox
              componentKey="ID"
              {...dynamicProps}
            />
            <div className="work-order-default-modal-buttons">
              <Button type="button" variant="gray-outline" height="md" width="sm" onClick={() => this.setState({ addComponentsModal: { isOpen: false } })} text={t('CANCEL')} />
              <Button className="modal-confirm-btn" height="md" width="sm" type="button" onClick={() => this.setState({ addComponentsModal: { isOpen: false } })} text={t('OK')} />
            </div>
          </div>
        ),
        isOpen: true,
        type: 'none',
        confirmAction: () => this.setState({ addComponentsModal: { isOpen: false } }),
        closeAction: () => this.setState({ addComponentsModal: { isOpen: false } }),
      },
    }));
  };

  createObservation = () => {
    const { createWorkOrderObservation, queryItem, inspectionId, getWorkOrderObservations, setGenericNotification, workOrderFormValues } = this.props;
    const { t } = this.context;

    if (!queryItem || queryItem <= 0 || !inspectionId || inspectionId <= 0) return;
    createWorkOrderObservation(
      {
        [fields.workOrderID]: queryItem,
        [fields.inspectionID]: inspectionId,
        [fields.name]: workOrderFormValues[fields.name],
        [fields.mainType]: observationsTypes.defect,
        [fields.cameraPosition]: { [fields.coordinates]: [0, 0, 0] },
      },
      () => {
        getWorkOrderObservations(queryItem);
        setGenericNotification({
          isDisplayed: true,
          type: 'success',
          icon: 'checkmark-outline',
          text: t('WORK_ORDER.OBSERVATION_CREATED'),
        });
      }
    );
  };

  closeAddObservationModal = () => this.setState({ addObservationsModal: { isOpen: false } });

  openObservationsModal = () => {
    const { queryItem, inspectionId } = this.props;
    const { t } = this.context;
    this.setState({
      addObservationsModal: {
        isOpen: true,
        CustomContent: dynamicProps => <AddObservationsToWorkOrder {...dynamicProps} />,
        type: 'none',
        title: t('WORK_ORDERS.EDIT_OBSERVATIONS'),
        closeAction: this.closeAddObservationModal,
        customCloseAction: this.closeAddObservationModal,
        workOrderID: queryItem,
        inspectionId,
      },
    });
  };

  handleSearchUsers = searchText => {
    const { searchWorkOrderAssignees, queryItem, projectId } = this.props;

    searchWorkOrderAssignees(
      {
        SearchText: searchText || '',
        SortByColumn: 'UserID',
        SortDirection: 'ASC',
        LastSeen: 0,
        PerPage: Number.MAX_SAFE_INTEGER,
        WorkOrderId: queryItem,
        ProjectID: projectId,
      },
      users => this.setState({ users })
    );
  };

  handleSearchContributors = (searchText, participantType) => {
    const { searchWorkOrderContributors, queryItem, projectId } = this.props;

    searchWorkOrderContributors(
      {
        SearchText: searchText || '',
        WorkOrderId: queryItem,
        ProjectID: projectId,
        ParticipantType: participantType,
      },
      users => {
        if (participantType === participantTypes.assignee) {
          const extendedAssignees = [...users, { [fields.id]: -2, [fields.name]: 'None', DefectType: 'USER' }];
          this.setState({ assignees: extendedAssignees });
        } else {
          this.setState({ collaborators: users });
        }
      }
    );
  };

  handleAddWorkOrderContributor = user => {
    const { queryItem, addWorkOrderContributor, projectId, inspectionId } = this.props;

    addWorkOrderContributor(queryItem, projectId, inspectionId, user);
  };

  handleRemoveWorkOrderContributor = (user, callback = () => null) => {
    const { assignees, collaborators } = this.state;
    const { queryItem, removeWorkorderContributor, projectId, inspectionId } = this.props;

    removeWorkorderContributor(queryItem, projectId, inspectionId, user, () => {
      if (user[assigneeFields.participantType] === participantTypes.assignee) {
        const updatedUser = { ...user, Type: user.AssigneeType };
        this.setState({ assignees: uniqBy([...assignees, updatedUser], 'ID') });
      } else {
        this.setState({ collaborators: uniqBy([...collaborators, user], 'ID') });
      }
      callback();
    });
  };

  openAssigneesModal = () => {
    const { t } = this.context;
    this.handleSearchContributors('', participantTypes.assignee);
    this.handleSearchContributors('', participantTypes.collaborator);

    this.setState(prevState => ({
      addAssigneesModal: {
        ...prevState.addAssigneesModal,
        title: t('WORK_ORDER.EDIT_ASSIGNEES'),
        customClassName: 'work-order-default-modal create-work-order-modal',
        CustomContent: dynamicProps => (
          <AddContributors
            addContributor={this.handleAddWorkOrderContributor}
            removeContributor={this.handleRemoveWorkOrderContributor}
            assigneeLabel="CHOOSE_WORK_ORDER_NOTIFICATION.ADD_ASSIGNEES"
            collaboratorLabel="CHOOSE_WORK_ORDER_NOTIFICATION.ADD_COLLABORATORS"
            searchContributors={this.handleSearchContributors}
            customClassName="work-order-form"
            {...dynamicProps}
          />
        ),
        isOpen: true,
        type: 'none',
        confirmAction: () => this.setState({ addAssigneesModal: { isOpen: false } }),
        closeAction: () => this.setState({ addAssigneesModal: { isOpen: false } }),
      },
    }));
  };

  handleAddWorkOrderContractor = user => {
    const { queryItem, addWorkOrderContractors } = this.props;

    addWorkOrderContractors(queryItem, user);
  };

  handleRemoveWorkOrderContractor = user => {
    const { users } = this.state;
    const { queryItem, removeWorkorderContractors } = this.props;

    removeWorkorderContractors(queryItem, user, () => {
      this.setState({ users: uniqBy([...users, user], 'ID') });
    });
  };

  openContractorsModal = () => {
    const { t } = this.context;
    this.handleSearchUsers();

    this.setState(prevState => ({
      addContractorsModal: {
        ...prevState.addContractorsModal,
        title: t('WORK_ORDER.EDIT_CONTRACTORS'),
        customClassName: 'work-order-default-modal create-work-order-modal',
        CustomContent: dynamicProps => (
          <div className="work-order-form">
            <AddContractors
              handleUsersAndTeamsSearch={e => this.handleSearchUsers(e.target.value)}
              addContractorToWorkorder={this.handleAddWorkOrderContractor}
              removeContractorFromWorkorder={this.handleRemoveWorkOrderContractor}
              {...dynamicProps}
            />
            <div className="work-order-default-modal-buttons">
              <Button type="button" variant="gray-outline" height="md" width="sm" onClick={() => this.setState({ addContractorsModal: { isOpen: false } })} text={t('CANCEL')} />
              <Button className="modal-confirm-btn" height="md" width="sm" type="button" onClick={() => this.setState({ addContractorsModal: { isOpen: false } })} text={t('OK')} />
            </div>
          </div>
        ),
        isOpen: true,
        type: 'none',
        confirmAction: () => this.setState({ addContractorsModal: { isOpen: false } }),
        closeAction: () => this.setState({ addContractorsModal: { isOpen: false } }),
      },
    }));
  };

  addWorkOrderArea = (workOrderId, workArea) => {
    const { addWorkOrderArea } = this.props;

    addWorkOrderArea(workOrderId, workArea);
  };

  removeWorkOrderArea = (workOrderId, workArea) => {
    const { removeWorkorderArea } = this.props;

    removeWorkorderArea(workOrderId, workArea);
  };

  updateWorkOrderAreaHandler = (workOrderId, workArea, dataCallback) => {
    const { updateWorkOrderArea } = this.props;
    updateWorkOrderArea(workOrderId, workArea, dataCallback);
  };

  handleOpenWorkAreaModal = () => {
    const { inspectionId } = this.props;

    const closeAction = () => {
      this.setState({ workAreasModal: { isOpen: false } });
    };

    this.setState({
      workAreasModal: {
        isOpen: true,
        CustomContent: dynamicProps => (
          <WorkAreasModal
            {...dynamicProps}
            inspectionId={inspectionId}
            handleWorkAreaAdd={this.addWorkOrderArea}
            handleDeleteWorkArea={this.removeWorkOrderArea}
            handleWorkAreaUpdate={this.updateWorkOrderAreaHandler}
            closeAction={closeAction}
            showOnly2D={true}
          />
        ),
        customClassName: 'modal-no-max-height',
        customClassWrapperName: 'no-padding',
        type: 'none',
        closeAction,
      },
      currentQuestionId: 1,
    });
  };

  addUserToAlertList = user => {
    const userAlerts = Object.assign([], this.state.userAlerts);
    userAlerts.push(user);

    this.setState({ userAlerts });
  };

  removeUserFromAlertList = user => {
    const userAlerts = Object.assign([], this.state.userAlerts);
    remove(userAlerts, { [workOrderField.id]: user[workOrderField.id] });

    this.setState({ userAlerts });
  };

  handleAddAlert = values => {
    this.setState({
      addAlertsModal: {
        isOpen: false,
      },
    });
  };

  openAlertsModal = () => {
    const { t } = this.context;
    this.handleSearchUsers('');

    const closeModal = () => {
      this.setState({
        addAlertsModal: {
          isOpen: false,
        },
        userAlerts: [],
      });
    };

    this.setState(prevState => ({
      addAlertsModal: {
        ...prevState.addAlertsModal,
        title: t('WORK_ORDER.EDIT_ALERTS'),
        customClassName: 'work-order-default-modal create-work-order-modal',
        CustomContent: dynamicProps => (
          <div className="work-order-form">
            <ManageAlertForm handleUsersAndTeamsSearch={this.handleSearchUsers} closeModal={closeModal} {...dynamicProps} />
          </div>
        ),
        isOpen: true,
        type: 'none',
        confirmAction: closeModal,
        closeAction: closeModal,
      },
    }));
  };

  isValid = (type, items, files) => {
    const { notificationModalCustom } = this.props;
    if (type === validationTypes.notifications) {
      if (items.length >= notificationValidation.limit) {
        notificationModalCustom('WORK_ORDER_CREATE_VALIDATION.NOTIFICATION_LIMIT_DESC', 'WORK_ORDER_CREATE_VALIDATION.NOTIFICATION_LIMIT_TITLE');
        return false;
      }
      return true;
    }
    if (type === validationTypes.images) {
      const allFiles = items.concat(files);

      if (allFiles && allFiles.length > imagesValidation.maxFiles) {
        notificationModalCustom(
          {
            key: 'WORK_ORDER_CREATE_VALIDATION.VALIDATION_MAX_FILES',
            props: { maxFiles: imagesValidation.maxFiles, current: files && files.length ? files.length : 0 },
          },
          'WORK_ORDER_CREATE_VALIDATION.VALIDATION_FILE_SIZE_TITLE'
        );

        return false;
      }

      const hasLargeFiles = some(items, item => item.size > imagesValidation.bytes);

      if (hasLargeFiles) {
        //open modal with message
        notificationModalCustom(
          {
            key: 'WORK_ORDER_CREATE_VALIDATION.VALIDATION_FILE_SIZE',
            props: { fileSize: `${imagesValidation.value}${imagesValidation.unit}` },
          },
          'WORK_ORDER_CREATE_VALIDATION.VALIDATION_FILE_SIZE_TITLE'
        );
        return false;
      }
      return true;
    }
  };

  getImages = () => {
    const { workorderFiles } = this.props;
    const files = workorderFiles?.[WOFileGroups.images];

    return files || [];
  };

  handleSectionAction = (sectionAction, props, cb) => {
    const action = this[sectionAction];

    if (typeof action === 'function') {
      action(...Object.values(props || {}));
      if (cb && typeof cb === 'function') {
        // cleans up the comment textarea input field
        cb(true);
      }
    }
  };

  basicDetailsDisabled = () => {
    const { workOrderFormValues } = this.props;

    if (workOrderFormValues[workOrderField.archived]) {
      return true;
    }

    return [statuses.rejected.value, statuses.completed.value, statuses.closed.value].indexOf(workOrderFormValues[workOrderField.status]) > -1;
  };

  handleChangeStatus = data => {
    const { t } = this.context;
    const {
      changeWorkOrderStatus,
      queryItem,
      filters,
      workOrders,
      workOrderPermits,
      getWorkOrderFiles,
      getWorkOrderChecklists,
      getWorkOrderDetails,
      changeField,
      hasUnsavedChanges,
      setUnsavedChangesDirty,
      workOrderFormValues,
    } = this.props;
    data[workOrderField.id] = queryItem;

    const valid = isWorkOrderChangeStatusValid(data[workOrderField.status], workOrderPermits);

    if (!valid.isValid) {
      const confirmAction = () => {
        this.setState({ messageModalData: { isOpen: false } });

        if (valid.id) {
          const element = document.getElementById(valid.id);
          const contentElement = document.getElementById(valid.contentId);

          if (element) {
            if (!contentElement?.parentElement?.classList.contains('section-content--visible')) {
              element.click();
            }

            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }
      };

      this.setState(prevState => ({
        messageModalData: {
          ...prevState.messageModalData,
          title: t('WORK_ORDER.CHANGE_STATUS_WARNING_TITLE'),
          customClassName: 'work-order-confirm-modal modal-small',
          isOpen: true,
          type: 'ok',
          content: t(valid.message),
          okModalButtonText: valid.buttonText,
          confirmAction: confirmAction,
          closeAction: () => this.setState({ messageModalData: { isOpen: false } }),
        },
      }));

      return;
    }

    const confirmChangeStatus = values => {
      this.setState({ changeStatusInProgress: true });
      changeWorkOrderStatus(values, filters, Object.assign([], workOrders), isSuccess => {
        if (isSuccess) {
          changeField(FORMS.workOrderBasicDetailsForm, fields.status, values[workOrderField.status]);
          this.setState({
            changeStatusConfirmModal: {
              isOpen: false,
            },
            completedStatusModal: {
              isOpen: false,
            },
            statusChangeNoteModalData: {
              isOpen: false,
            },
            changeStatusInProgress: false,
          });

          if (data[workOrderField.status] === statuses.completed.value) {
            getWorkOrderFiles(queryItem);
          }
          if (data[workOrderField.status] === statuses.authorised.value || data[workOrderField.status] === statuses.rejected.value) {
            getWorkOrderChecklists(queryItem);
            getWorkOrderDetails(workOrderFormValues[workOrderField.id], false, data => {
              // changes status and HasUnansweredMandatoryQuestions field values
              changeField(FORMS.workOrderBasicDetailsForm, fields.status, values[workOrderField.status]);
              changeField(FORMS.workOrderBasicDetailsForm, fields.hasUnansweredMandatoryQuestions, data[fields.hasUnansweredMandatoryQuestions]);
            });
          }
          setUnsavedChangesDirty(hasUnsavedChanges); // updates the correct hasUnsavedChanges value, after reinitalize occurs
        } else {
          this.setState({ changeStatusInProgress: false });
        }
      });
    };

    const openStatusChangeNoteModal = values => {
      const isRejectedStatus = values[fields.status] === statuses.rejected.value || false;
      this.setState({
        statusChangeNoteModalData: {
          title: isRejectedStatus ? t('STATUS_CHANGE_NOTE_MODAL.REJECT_TITLE') : t('STATUS_CHANGE_NOTE_MODAL.CLOSE_TITLE'),
          content: isRejectedStatus ? t('STATUS_CHANGE_NOTE_MODAL.WORK_ORDER_REJECTED_CONTENT') : t('STATUS_CHANGE_NOTE_MODAL.WORK_ORDER_CLOSED_CONTENT'),
          confirmButtonLabel: isRejectedStatus ? t('STATUS_CHANGE_NOTE_MODAL.MARK_AS_REJECTED') : t('STATUS_CHANGE_NOTE_MODAL.MARK_AS_CLOSED'),
          isOpen: true,
          confirmAction: comment => confirmChangeStatus({ ...values, Comment: comment.Comment }),
          closeAction: () => this.setState({ statusChangeNoteModalData: { isOpen: false }, changeStatusConfirmModal: { isOpen: false } }),
        },
      });
    };

    if (data?.isRegularStatus) {
      let modalData;
      const rejectedOrClosedStatus = data[fields.status] === statuses.rejected.value || data[fields.status] === statuses.closed.value;

      if (rejectedOrClosedStatus) {
        modalData = {
          type: '',
          isOpen: true,
          closeAction: () => this.setState({ changeStatusConfirmModal: { isOpen: false } }),
          customCloseAction: () => this.setState({ changeStatusConfirmModal: { isOpen: false } }),
          confirmAction: () => openStatusChangeNoteModal(data),
          className: 'modal-no-max-height modal-medium',
          CustomContent: dynamicProps => <ActionModal {...dynamicProps} />,
          // if WO is marked as rejected or closed, display the Status Change Note modal
          customConfirmAction: () => openStatusChangeNoteModal(data),
          firstParagraph: 'WORK_ORDER.CONFIRM_MODAL_CONTENT',
          secondParagraph: data[fields.status] === statuses.rejected.value ? 'WORK_ORDER.CONFIRM_MODAL_CONTENT_CHECKLIST_DELETE' : '',
          confirmButtonText: data[fields.status] === statuses.rejected.value ? 'WORK_ORDER.CHANGE_STATUS_TO_REJECTED' : 'WORK_ORDER.CHANGE_STATUS_TO_CLOSED',
          title: data.modalTitle,
          firstParagraphProps: { status: t(data.title) },
        };
      } else {
        modalData = {
          isOpen: true,
          title: t(data.modalTitle),
          content: t('WORK_ORDER.CONFIRM_MODAL_CONTENT', { status: t(data.title) }),
          type: 'yes-no',
          customClassName: 'change-status-confirm-modal',
          // if WO is marked as rejected or closed, display the Status Change Note modal
          confirmAction: () => confirmChangeStatus(data),
          closeAction: () => this.setState({ changeStatusConfirmModal: { isOpen: false } }),
        };
      }

      this.setState({ changeStatusConfirmModal: modalData });
    } else {
      const { projectDMSCategories } = this.props;

      const changeStatus = values => {
        confirmChangeStatus({ ...values, ...data });
      };

      const modalData = {
        isOpen: true,
        title: t('WORK_ORDER.COMPLETED_MODAL_TITLE'),
        CustomContent: dynamicProps => (
          <WorkOrderCompletedForm
            {...dynamicProps}
            handleFormSubmit={changeStatus}
            cancelAction={() => this.setState({ completedStatusModal: { isOpen: false } })}
            workOrderDetails={workOrderFormValues}
            projectDMSCategories={projectDMSCategories}
          />
        ),
        type: 'none',
        customClassName: 'work-order-completed-modal',
        closeAction: () => this.setState({ completedStatusModal: { isOpen: false } }),
      };

      this.setState({ completedStatusModal: modalData });
    }
  };

  getOnHoldActionPermission = () => {
    const { workOrderFormValues } = this.props;
    const workOrderStatus = values(statuses);

    const status = find(workOrderStatus, { value: workOrderFormValues[workOrderField.status] });

    return status?.editPermission || '';
  };

  handleOnHoldAction = () => {
    const { changeWorkOrderOnHold, queryItem, workOrderFormValues } = this.props;
    const workOrder = Object.assign({}, workOrderFormValues);

    const onDone = isSuccess => {
      if (isSuccess) {
        workOrder[workOrderField.onHold] = !workOrder[workOrderField.onHold];
      }

      this.setState({ confirmModalData: { isOpen: false }, workOrderDetails: workOrder });
    };

    changeWorkOrderOnHold({ [workOrderField.workOrderID]: queryItem, [workOrderField.onHold]: !workOrderFormValues[workOrderField.onHold] }, onDone);
  };

  handleArchiveAction = () => {
    const { archiveWorkOrder, queryItem, workOrderFormValues } = this.props;
    const workOrder = Object.assign({}, workOrderFormValues);

    const onDone = isSuccess => {
      if (isSuccess) {
        workOrder[workOrderField.archived] = !workOrder[workOrderField.archived];
      }

      this.setState({ confirmModalData: { isOpen: false }, workOrderDetails: workOrder });
    };

    archiveWorkOrder({ [workOrderField.workOrderID]: queryItem, [workOrderField.archive]: !workOrderFormValues[workOrderField.archived] }, onDone);
  };

  handleDeleteAction = () => {
    const { deleteWorkOrder, workOrders, queryItem, pagingObject } = this.props;

    const onDone = isSuccess => {
      if (isSuccess) {
        const { location } = this.props;
        const queryParams = { ...location.query };
        delete queryParams.selected_item;
        const params = [];

        for (let key in queryParams) {
          params.push({
            [key]: queryParams[key],
          });
        }

        Helpers.goTo(location.pathname, params);
      }

      this.setState({ confirmModalData: { isOpen: false } });
    };

    deleteWorkOrder({ [workOrderField.workOrderID]: queryItem }, Object.assign([], workOrders), Object.assign({}, pagingObject), onDone);
  };

  fetchWorkOrderCommentsHandler = () => {
    const { fetchWorkOrderComments, queryItem } = this.props;

    const onSuccessFetch = newState => {
      this.setState({ commentsList: newState.commentsList, commentsLoading: newState.commentsLoading });

      if (newState.commentsList) {
        Helpers.scrollIntoView('comments-list-wrapper', `comment-${newState.commentsList.length - 1}`, 0);
      }
    };

    fetchWorkOrderComments({ [commentFields.workOrderId]: queryItem }, onSuccessFetch);
  };

  addWorkOrderCommentHandler = (commentValue, commentTags, resetCommentInput, scrollIntoView) => {
    const { addWorkOrderComment, inspectionId, projectId } = this.props;
    const { workOrderDetails } = this.state; // we use workOrderDetails, since workOrderFormValues are empty object after tab switch

    const commentParams = {
      InspectionID: inspectionId,
      ProjectID: projectId,
      [commentFields.id]: workOrderDetails[workOrderField.id],
      [commentFields.tags]: commentTags,
      [commentFields.moduleSectionID]: workOrderDetails[workOrderField.commentSectionId],
      Comment: commentValue,
    };
    addWorkOrderComment(
      commentParams,
      () => this.fetchWorkOrderCommentsHandler(),
      loading => this.setState({ addCommentLoading: loading })
    );
    resetCommentInput();
    scrollIntoView();
  };

  deleteWorkOrderCommentHandler = comment => {
    const { deleteWorkOrderComment } = this.props;
    const commentParams = { [commentFields.moduleCommentID]: comment[commentFields.id], CommentID: null };

    deleteWorkOrderComment(
      commentParams,
      () => this.fetchWorkOrderCommentsHandler(false),
      loading => this.setState({ addCommentLoading: loading })
    );
  };

  searchUserAndTeamsHandler = searchTerm => {
    const { fetchCommentUsersAndTeams } = this.props;
    fetchCommentUsersAndTeams(
      searchTerm,
      (usersList, teamsList) => this.setState({ commentUsersList: usersList, commentTeamsList: teamsList }),
      loading =>
        this.setState({
          commentUsersAndTeamsLoading: loading,
        })
    );
  };

  openImagesModal = (selectedImage, linkedImages) => {
    const { user, workOrderFormValues } = this.props;
    let index = 0;
    linkedImages = map(linkedImages, (item, i) => {
      item.src = Helpers.getUploadImageSource(item.FileName, item.URL);
      if (selectedImage.FileID === item.FileID) {
        index = i;
      }
      return item;
    });

    this.setState(prevProps => ({
      filesModalData: {
        ...prevProps.filesModalData,
        customClassName: 'linked-images-slider',
        CustomContent: () => (
          <LinkedImagesSliderSlider
            linkedImages={linkedImages}
            currentSlideIndex={index}
            setOrientation={() => null}
            disabledOrientations={[orientationConstants.orientation.chart]}
            isPreviewModal={true}
            openDeleteFileModal={(_id, _name, _category, file) => this.unlinkFile(file, true)}
            hasUnlinkAccess={{
              user,
              id: workOrderFormValues[fields.createdBy],
              ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.workOrders].create.name,
              visibleFor: [PERMISSIONS[PERMISSION_TYPES.workOrders].edit.name],
            }}
          />
        ),
        isOpen: true,
        type: 'none',
        closeAction: () => this.setState({ filesModalData: { isOpen: false } }),
      },
    }));
  };

  closeNotificationTimeout = () => {
    setTimeout(() => {
      this.setState({ notificationData: { isDisplayed: false } });
    }, 5000);
  };

  closeNotification = () => this.setState({ notificationData: { isDisplayed: false } });

  // TODO: redundant code as in component-details. Requires refactoring
  openFile = (file, imageFiles) => {
    const {
      inspectionId,
      setPdfComponents,
      isPdfScreen,
      inspectionModalData,
      toggleInspectionModal,
      closeInspectionModal,
      minimizeInspectionModal,
      maximizeInspectionModal,
      getPdfComponentsForPopup,
      changePDFPage,
      openAsSeparatePageInspectionModal,
    } = this.props;
    const { t } = this.context;
    if (file.isPDF || Helpers.isPDF(file[fields.fileName] || file[fields.name] || file.name, file[fields.fileType])) {
      getPdfComponentsForPopup(
        inspectionId,
        file.FileID,
        // sets default filters and pageNumber to 1
        {
          SearchText: '',
          ComponentFilter: componentFilterValues.all,
        },
        1,
        undefined,
        d => {
          const confirmedDrawings = (d || []).filter(cDrawing => cDrawing[componentFields.confirmed]);
          setPdfComponents(confirmedDrawings);
          // open regular modal for tools since inspectionModal works only on MODULES level, but not on Tools modules
          // to check if we are on Tools part of the app we use isPdfScreen property
          // TODO: figure out if isPdfScreen is needed
          if (isPdfScreen) {
            this.setState(prevProps => ({
              filesModalData: {
                ...prevProps.filesModalData,
                isOpen: true,
                title: t('PDF_VIEWER.MODAL.TITLE'),
                customClassName: 'modal-large',
                closeAction: () => {
                  setPdfComponents([]);
                  this.setState({ filesModalData: { isOpen: false } });
                },
                CustomContent: dynamicProps => (
                  <ComponentPDF
                    {...dynamicProps}
                    file={file}
                    selectComponent={clickedObj => null}
                    rightCollapsed={true}
                    inspectionId={inspectionId}
                    // when PDF opened in popup from module details or DMS, we show only confirmed tags/drawings
                    showOnlyConfirmedDrawings={true}
                  />
                ),
              },
            }));
          } else {
            toggleInspectionModal({
              ...inspectionModalData,
              isOpen: true,
              FileIcon: props => <PDFIcon height={18} {...props} />,
              fileName: file.name,
              title: t('PDF_VIEWER.MODAL.TITLE'),
              customClassName: 'modal-large',
              closeAction: closeInspectionModal,
              minimizeAction: () =>
                changePDFPage(
                  1, // pdfPageNumber set to 1 because component-pdf on unmount sets pdfPageNumber to 1 in reducer
                  inspectionId,
                  file.FileID,
                  {
                    SearchText: '',
                    ComponentFilter: componentFilterValues.all,
                  },
                  null,
                  true,
                  () => {
                    minimizeInspectionModal();
                  }
                ),
              maximizeAction: () =>
                changePDFPage(
                  1, // pdfPageNumber set to 1 because component-pdf on unmount sets pdfPageNumber to 1 in reducer
                  inspectionId,
                  file.FileID,
                  {
                    SearchText: '',
                    ComponentFilter: componentFilterValues.all,
                  },
                  null,
                  true,
                  () => {
                    maximizeInspectionModal();
                  }
                ),
              separatePageAction: () =>
                changePDFPage(
                  1, // pdfPageNumber set to 1 because component-pdf on unmount sets pdfPageNumber to 1 in reducer
                  inspectionId,
                  file.FileID,
                  {
                    SearchText: '',
                    ComponentFilter: componentFilterValues.all,
                  },
                  null,
                  true,
                  () => {
                    openAsSeparatePageInspectionModal();
                  }
                ),
              CustomContent: dynamicProps => (
                <ComponentPDF
                  {...dynamicProps}
                  file={file}
                  selectComponent={clickedObj => null}
                  rightCollapsed={true}
                  // when PDF opened in popup from module details or DMS, we show only confirmed tags/drawings
                  showOnlyConfirmedDrawings={true}
                />
              ),
            });
          }
        }
      );
    } else if (file.isImage || Helpers.isImage(file[fields.fileName] || file[fields.name] || file.name, file[fields.fileType])) {
      const images = isEmpty(imageFiles) ? [file] : imageFiles;
      this.openImagesModal(file, images);
    } else {
      Helpers.getFileExtensionAndDownload(file);
    }
  };

  handleAnswerQuestion = (values, checklistId, successCallback) => {
    delete values.question;
    const { updateChecklistAnswer } = this.props;

    const onSuccess = data => {
      successCallback(data);
    };

    values.ModuleChecklistID = checklistId;
    updateChecklistAnswer(values, onSuccess);
  };

  handleSubmitChecklist = (checklist, successCallback, errorCallback) => {
    const { t } = this.context;
    const { submitChecklist, getWorkOrderDetails, changeField, workOrderFormValues, workOrderChecklistValidate } = this.props;

    const closeAction = () => {
      this.setState({ confirmModalData: { isOpen: false }, checklistModalData: { isOpen: false } });
    };

    const customCloseAction = () => {
      errorCallback();
      closeAction();
    };

    const customConfirmAction = () => {
      submitChecklist(
        checklist[checklistFields.id],
        workOrderFormValues[workOrderField.id],
        () => {
          successCallback();
          // After submit success only update the HasUnansweredMandatoryQuestions property from the API because API needs to recalculate it
          getWorkOrderDetails(workOrderFormValues[workOrderField.id], false, data => {
            changeField(FORMS.workOrderBasicDetailsForm, fields.hasUnansweredMandatoryQuestions, data[fields.hasUnansweredMandatoryQuestions]);
          });
          closeAction();
        },
        () => {
          errorCallback();
          closeAction();
        }
      );
    };

    workOrderChecklistValidate({ [workOrderField.workOrderID]: workOrderFormValues[workOrderField.id], [workOrderField.workOrderChecklistID]: checklist[checklistFields.id] }, validate => {
      this.setState({
        confirmModalData: {
          name: checklist[checklistFields.name],
          isOpen: true,
          validationErrors: validate,
          title: t('WORK_ORDER.CHECKLIST_SUBMIT_CONFIRMATION_MODAL_TITLE'),
          type: '',
          CustomContent: dynamicProps => <SubmitChecklistModal {...dynamicProps} />,
          customClassName: 'work-order-confirm-submit-modal',
          confirmAction: customConfirmAction,
          customConfirmAction: customConfirmAction,
          closeAction: customCloseAction,
          customCloseAction: customCloseAction,
        },
      });
    });
  };

  getChecklistDetails = (checklist, successCallback = () => null, errorCallback = () => null) => {
    const { getWorkOrderChecklistDetails } = this.props;

    getWorkOrderChecklistDetails(checklist[checklistFields.id], successCallback, errorCallback);
  };

  getChecklistSections = (workOrderId, checklist, filters, isTemplate = false, successCallback = () => null, errorCallback = () => null) => {
    const { getWorkOrderChecklistSections, fetchChecklistProcedureTemplateSections } = this.props;

    if (isTemplate) {
      fetchChecklistProcedureTemplateSections(
        { ChecklistAndProcedureTemplateID: checklist[checklistFields.id], ...filters },
        (requestInProgress, isLoading, sections, isSectionChanged, toggleAddQuestionButton, newFilters) => {
          if (requestInProgress || isLoading) {
            return;
          }
          if (sections) {
            // sucess
            successCallback({
              Sections: {
                Items: sections,
                ...newFilters,
              },
            });
          } else {
            // error
            errorCallback();
          }
        }
      );
    } else {
      getWorkOrderChecklistSections(workOrderId, checklist[checklistFields.id], filters, successCallback, errorCallback);
    }
  };

  getChecklistSectionQuestions = (checklist, sectionId, filters, isTemplate = false, successCallback = () => null, errorCallback = () => null) => {
    const { getWorkOrderChecklistSectionQuestions, fetchChecklistProcedureTemplateSectionQuestions } = this.props;

    if (isTemplate) {
      fetchChecklistProcedureTemplateSectionQuestions(checklist[checklistFields.id], sectionId, filters, (requestInProgress, isLoading, sectionId, questions, newFilters) => {
        if (requestInProgress || isLoading) {
          return;
        }
        if (questions) {
          successCallback({
            Items: questions,
            ...newFilters,
          });
        } else {
          errorCallback();
        }
      });
    } else {
      getWorkOrderChecklistSectionQuestions(sectionId, filters, successCallback, errorCallback);
    }
  };

  handleDownloadChecklist = (checklist, isTemplate = false) => {
    const { t } = this.context;
    const { queryItem, generateAndDownloadPDFChecklistTemplatePDF, generateAndDownloadChecklistPDF } = this.props;
    if (isTemplate) {
      generateAndDownloadPDFChecklistTemplatePDF(checklist[checklistFields.id], () => {
        this.setState({ isDownloadInProgress: false, confirmModalData: { isOpen: false } });
      });
    } else {
      generateAndDownloadChecklistPDF(queryItem, checklist[checklistFields.id], () => {
        this.setState({ isDownloadInProgress: false, confirmModalData: { isOpen: false } });
      });
    }
    // now display the notification
    this.setState({
      notificationData: {
        isDisplayed: true,
        type: 'info',
        icon: 'check-toast',
        text: t('ISOLATION_CERTIFICATE.DOWNLOAD_NOTIFICATION'),
        onClickClose: this.closeNotification,
      },
    });
    this.closeNotificationTimeout();
  };

  openFillInChecklistModalData = (checklist, showEmptyVersion) => {
    const { t } = this.context;
    const {
      user,
      severityColors,
      inspectionId,
      projectId,
      workOrderFormValues,
      createWorkOrderObservationCP,
      inspectionDetails,
      getWorkOrderChecklistSectionQuestionDetails,
      defaultWorkorderDMSCategoryID,
    } = this.props;

    const closeAction = () => {
      this.setState({ checklistModalData: { isOpen: false } });
    };

    this.setState({
      checklistModalData: {
        isOpen: true,
        title: checklist[checklistFields.name],
        CustomContent: dynamicProps => (
          <WorkOrderSectionChecklistDetails
            checklistId={checklist[checklistFields.id]}
            checklistName={checklist[checklistFields.name]}
            module={Object.assign({}, workOrderFormValues)} //  send workOrderFormValues here instead of workOrderDetails, since module is used mostly for status checking
            handleAnswerQuestion={(vals, callback) => this.handleAnswerQuestionDebounce(vals, checklist[checklistFields.id], callback)}
            moduleId={workOrderFormValues[workOrderField.id]}
            statuses={statuses}
            createdById={workOrderFormValues[workOrderField.createdBy]}
            user={user}
            severityColors={severityColors}
            showEmptyVersion={showEmptyVersion}
            showProgressBar={!showEmptyVersion}
            disabledForStatuses={sections.Checklists.disabledForStatuses}
            fetchChecklistSections={(filters, successCallback, errorCallback) =>
              this.getChecklistSections(workOrderFormValues[workOrderField.id], checklist, filters, showEmptyVersion, successCallback, errorCallback)
            }
            fetchChecklistQuestions={(sectionId, filters, successCallback, errorCallback) =>
              this.getChecklistSectionQuestions(checklist, sectionId, filters, showEmptyVersion, successCallback, errorCallback)
            }
            handleSubmitChecklist={(successCallback, errorCallback) => this.handleSubmitChecklist(checklist, successCallback, errorCallback)}
            inspectionId={inspectionId}
            handleCancelChecklist={closeAction}
            projectId={projectId}
            workorderId={workOrderFormValues[formConstants.fields.id]}
            handleFileOpen={this.openFile}
            categoryPropName={!showEmptyVersion ? 'Category' : 'Group'}
            filesPropName={!showEmptyVersion ? 'QuestionDMSFiles' : 'QuestionFiles'}
            getWorkOrderChecklistSectionQuestionDetails={getWorkOrderChecklistSectionQuestionDetails}
            createWorkOrderObservationCP={createWorkOrderObservationCP}
            cameraPos={inspectionDetails?.CameraPosition}
            defaultWorkorderDMSCategoryID={defaultWorkorderDMSCategoryID}
            {...dynamicProps}
          />
        ),
        actionItems: [
          {
            onClick: () => this.handleDownloadChecklist(checklist, showEmptyVersion),
            title: t('WORK_ORDER.DOWNLOAD_ICON'),
            icon: 'download',
            iconProps: {},
          },
        ],
        type: 'none',
        customClassName: 'checklist-details-modal',
        closeAction,
        customCloseAction: closeAction,
      },
    });
  };

  handleSaveClick = () => {
    const { workOrderFormValues, workOrderFormErrors, updateWorkorder, setUnsavedChangesDirty } = this.props;

    if (!isEmpty(workOrderFormErrors)) return;

    updateWorkorder(
      {
        ...workOrderFormValues,
        [workOrderField.dueDate]: workOrderFormValues[workOrderField.dueDate] ? Helpers.getUnixDate(new Date(workOrderFormValues[workOrderField.dueDate]).getTime()) : null,
        [workOrderField.startDate]: workOrderFormValues[workOrderField.startDate] ? Helpers.getUnixDate(new Date(workOrderFormValues[workOrderField.startDate]).getTime()) : null,
        [workOrderField.endDate]: workOrderFormValues[workOrderField.endDate] ? Helpers.getUnixDate(new Date(workOrderFormValues[workOrderField.endDate]).getTime()) : null,
      },
      () => {
        const details = { ...workOrderFormValues };
        setUnsavedChangesDirty(false);
        this.setState({ workOrderDetails: details });
      }
    );
  };

  setWorkOrderCameraPosition = newValue => {
    const { queryItem, updateGeometry, workOrderFormValues } = this.props;

    updateGeometry({ ID: queryItem, [fields.cameraPosition]: { coordinates: newValue } }, () =>
      this.setState({
        workOrderDetails: { ...workOrderFormValues, [fields.cameraPosition]: { coordinates: newValue } },
      })
    );
  };

  onModuleItemDeepLinkHandler = (moduleItem, moduleKey) => {
    const { user, projectId, inspectionId } = this.props;
    Helpers.goTo(user[params.fullScreenEnabled] ? routes.protectedRoutes.fullScreen.fullPath : routes.protectedRoutes.inspections.fullPath, [
      { project_id: projectId },
      { inspection_id: inspectionId },
      { type: modules[moduleKey] },
      { selected_item: moduleItem[fields.id] },
    ]);
  };

  onHoldStatusChangeHandler = () => {
    const { hasUnsavedChanges, setUnsavedChangesDirty, workOrderFormValues } = this.props;
    if (!hasUnsavedChanges || window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
      setUnsavedChangesDirty(false);
      this.toggleConfirmationModal(true, workOrderFormValues[fields.onHold] ? 'WORK_ORDER.CONTINUE_CONFIRM_TITLE' : 'WORK_ORDER.ON_HOLD_CONFIRM_TITLE', this.handleOnHoldAction);
    }
  };

  onArchiveStatusChangeHandler = () => {
    const { hasUnsavedChanges, setUnsavedChangesDirty, workOrderFormValues } = this.props;
    if (!hasUnsavedChanges || window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
      setUnsavedChangesDirty(false);
      this.toggleConfirmationModal(true, workOrderFormValues[fields.archived] ? 'WORK_ORDER.UNARCHIVE_CONFIRM_TITLE' : 'WORK_ORDER.ARCHIVE_CONFIRM_TITLE', this.handleArchiveAction);
    }
  };

  render() {
    const { t } = this.context;
    const {
      activeToolbarItem,
      workOrderDetails,
      workOrderTypes,
      additionalWorkorderTypes,
      uploadModalData,
      editFilesModalData,
      confirmModalData,
      screenShotPickerModal,
      isDownloadInProgress,
      changeStatusConfirmModal,
      changeStatusInProgress,
      completedStatusModal,
      addPermitsModal,
      addCPTemplatesModal,
      createWorkOrderPermitModal,
      addComponentsModal,
      components,
      componentSelectionModal,
      messageModalData,
      addAssigneesModal,
      addContractorsModal,
      users,
      assignees,
      collaborators,
      workAreasModal,
      addAlertsModal,
      workOrderAlerts,
      userAlerts,
      permitTemplates,
      statusChangeNoteModalData,
      commentsList,
      commentsLoading,
      addCommentLoading,
      commentUsersList,
      commentTeamsList,
      commentUsersAndTeamsLoading,
      filesModalData,
      cpTemplates,
      checklistModalData,
      notificationData,
      addObservationsModal,
    } = this.state;
    const {
      queryItem,
      severityColors,
      workOrderComponents,
      workorderAreas,
      workorderFiles,
      workorderComments,
      workorderContributors,
      workorderContractors,
      workOrderPermits,
      workOrderCPTemplates,
      workOrderChecklists,
      workOrderObservations,
      user,
      location,
      getWorkOrderStatusFlows,
      workOrderDetailsLoading,
      getWorkOrderCustomProperties,
      workOrderProperties,
      viewer,
      projectDMSCategories,
      uploadInProgress,
      workOrderFormErrors,
      workOrderCompletionFiles,
      inspectionDetails,
      hasUnsavedChanges,
      setUnsavedChangesDirty,
      workOrderFormValues,
    } = this.props;
    let descriptionType = get(location.query, 'type');
    const isArchivedOrOnHold = workOrderFormValues[fields.archived] ? '_ARCHIVED' : workOrderFormValues[fields.onHold] ? '_ON_HOLD' : null;
    /* in case work order has archived or on hold set to true, add it to descriptionType to present the warning message  */
    if (isArchivedOrOnHold) descriptionType += isArchivedOrOnHold;

    const menuOptions = [
      {
        title: workOrderFormValues[fields.onHold] ? 'WORK_ORDER.CONTINUE' : 'WORK_ORDER.ON_HOLD',
        access: {
          visibleFor: this.getOnHoldActionPermission(),
        },
        isDisabled: [statuses.approved.value, statuses.authorised.value, statuses.inProgress.value].indexOf(workOrderFormValues[fields.status]) === -1,
        action: () => this.onHoldStatusChangeHandler(),
      },
      {
        title: workOrderFormValues[fields.archived] ? 'WORK_ORDER.UNARCHIVE' : 'WORK_ORDER.ARCHIVE',
        access: {
          visibleFor: PERMISSIONS[PERMISSION_TYPES.workOrders].archive.name,
        },
        isDisabled: [statuses.draft.value, statuses.raised.value, statuses.approved.value, statuses.completed.value, statuses.closed.value].indexOf(workOrderFormValues[fields.status]) === -1,
        action: () => this.onArchiveStatusChangeHandler(),
      },
      {
        title: 'WORK_ORDER.DELETE',
        access: {
          visibleFor: PERMISSIONS[PERMISSION_TYPES.workOrders].delete.name,
        },
        separator: true,
        isHighlighted: true,
        action: () => this.toggleConfirmationModal(true, 'WORK_ORDER.DELETE_CONFIRM_TITLE', this.handleDeleteAction),
      },
    ];

    const containsCriticalEquipment = workOrderComponents && workOrderComponents.some(component => component[formConstants.fields.critical]);

    return (
      <div className={`work-order-right-side ${workOrderDetailsLoading ? 'loading' : ''}`}>
        <InspectionRenderer
          deselectAll={() => null}
          selectAll={() => null}
          toggleElement={() => null}
          deselectAllTemp={() => null}
          selectAllTemp={() => null}
          toggleElementTemp={() => null}
          selectElement={() => null}
          updateElementGeometry={() => null}
          selectedDefect={null}
          elements={(workOrderComponents || []).concat(workorderAreas || []).map(component => ({
            ...component,
            visible: true,
          }))}
          disableMove={true}
          viewer={viewer}
        />
        <RenderIf if={workOrderDetailsLoading}>
          <SimpleLoader isLoading={true} className="loader" />
        </RenderIf>
        <RenderIf if={!workOrderDetailsLoading}>
          <Tabs
            defaultTabKey={activeToolbarItem}
            navigationClassName="tabs"
            tabsHeader={
              activeToolbarItem === tabNames.details ? (
                <ModuleHeader
                  id={queryItem}
                  handleMaximize={this.handleMaximize}
                  handleDownload={this.handleDownload}
                  handleShare={this.handleShare}
                  menuOptions={menuOptions}
                  isDownloadDisabled={isDownloadInProgress}
                  hideMaximize
                  hideShare
                  isCustomProp={FEATURES.workorders.externalID.visible}
                  isCustomPropTranslation="EXTERNAL_ID_SHORT"
                  isCustomPropData={workOrderFormValues ? workOrderFormValues[fields.externalID] : null}
                />
              ) : null
            }
            onChange={tab => {
              // force wo details fetch every time that user navigates to Details tab so that details, especially status can be updated
              if (tab === toolbarItems[tabNames.details].name) {
                this.fetchInitialData();
              }
              this.setState({ activeToolbarItem: tab });
            }}
            formHasUnsavedChanges={hasUnsavedChanges}
            setFormUnsavedChanges={hasUnsavedChanges => setUnsavedChangesDirty(hasUnsavedChanges)}
          >
            <Tab title={toolbarItems[tabNames.details].label} tabKey={tabNames.details}>
              <RenderIf if={containsCriticalEquipment}>
                <CriticalEquipmentInfo className={'wo-critical-equipment-info'} title={'CRITICAL_EQUIPMENT.TITLE'} paragraph={'CRITICAL_EQUIPMENT_WORK_ORDER.PARAGRAPH'} />
              </RenderIf>
              <div className="work-order-right-side-content">
                <ChangeStatusDropdown
                  statuses={statuses}
                  module={Object.assign({}, workOrderFormValues)}
                  user={user}
                  handleChangeStatus={this.handleChangeStatus}
                  createdById={workOrderFormValues[workOrderField.createdBy]}
                  finalStatus={statuses.closed.value}
                  rejectedStatus={statuses.rejected.value}
                  disabled={workOrderFormValues[workOrderField.onHold] || workOrderFormValues[workOrderField.archived]}
                  descriptionType={descriptionType}
                />

                <RenderIf
                  if={[statuses.completed.value, statuses.closed.value].indexOf(workOrderFormValues[workOrderField.status]) > -1 && workOrderFormValues[workOrderField.hasUnansweredMandatoryQuestions]}
                >
                  <WarningIndicator
                    title={t('WORK_ORDER.WARNING.CHECKLIST_AND_PROCEDURES_NOT_COMPLETED.TITLE')}
                    description={t(`WORK_ORDER.WARNING.CHECKLIST_AND_PROCEDURES_NOT_COMPLETED.DESCRIPTION`)}
                  />
                </RenderIf>
                <BasicDetailsForm
                  initialValues={Object.assign({}, workOrderDetails)}
                  workOrderTypes={workOrderTypes}
                  additionalWorkorderTypes={additionalWorkorderTypes}
                  severityColors={severityColors}
                  disabled={this.basicDetailsDisabled()}
                  onChange={(values, _b, c) => this.handleBasicDetailsChange(values, c)}
                  properties={workOrderProperties}
                  getWorkOrderCustomProperties={() => getWorkOrderCustomProperties(queryItem)}
                />
                <WorkOrderSections
                  moduleDetails={{
                    ...workOrderFormValues,
                    ...{
                      workOrderComponents,
                      workAreas: workorderAreas,
                      workorderFiles,
                      workorderComments,
                      workorderContributors,
                      workorderContractors,
                      workOrderChecklists,
                      workOrderPermits,
                      workOrderCPTemplates,
                      workOrderAlerts,
                      projectDMSCategories,
                      workOrderObservations,
                      setCameraPosition: this.setWorkOrderCameraPosition,
                      openFile: this.openFile,
                      unlinkFile: this.unlinkFile,
                      openFillInChecklistModalData: this.openFillInChecklistModalData,
                      onModuleItemDeepLinkHandler: this.onModuleItemDeepLinkHandler,
                      defaultComponentId: inspectionDetails?.DefaultComponent,
                      workOrderFormValues,
                    },
                  }}
                  viewer={viewer}
                  handleSectionActionClicked={this.handleSectionAction}
                  user={user}
                />
                {/* This has to be type button since all of our sections are not in the form component */}
                <div className="defect-form__save-cta-wrapper default-background px-unset">
                  <Button type="button" width="sm" height="md" text={t('SAVE')} disabled={!hasUnsavedChanges || !isEmpty(workOrderFormErrors)} onClick={this.handleSaveClick} />
                </div>
              </div>
            </Tab>
            <Tab title={toolbarItems[tabNames.comments].label} tabKey={tabNames.comments}>
              <CommentsTab
                commentsList={commentsList}
                commentsLoading={commentsLoading}
                addCommentLoading={addCommentLoading}
                fetchCommentsList={this.fetchWorkOrderCommentsHandler}
                onAddCommentClick={this.addWorkOrderCommentHandler}
                onDeleteCommentClick={this.deleteWorkOrderCommentHandler}
                fetchCommentUsersAndTeams={this.searchUserAndTeamsHandler}
                commentUsersList={commentUsersList}
                commentTeamsList={commentTeamsList}
                commentUsersAndTeamsLoading={commentUsersAndTeamsLoading}
                user={user}
                addCommentPermission={PERMISSIONS[PERMISSION_TYPES.workOrders].addComment.name}
              />
            </Tab>
            <Tab title={toolbarItems[tabNames.completion].label} tabKey={tabNames.completion} visible={FEATURES.workorders.completionTab.visible}>
              <WorkOrderCompletionSections
                workOrderDetails={workOrderDetails}
                formDisabled={toolbarItems[tabNames.completion].formDisabled}
                parentProps={this.props}
                workOrderCompletionFiles={workOrderCompletionFiles}
                projectDMSCategories={projectDMSCategories}
                openFile={this.openFile}
                unlinkCompletionFile={this.unlinkCompletionFile}
                openEditFilesModal={this.openEditFilesModal}
              />
            </Tab>
            <Tab title={toolbarItems[tabNames.flow].label} tabKey={tabNames.flow} visible={FEATURES.workorders.flowTab.visible}>
              <FlowStatusWrapper
                flowStatusModuleId={queryItem}
                formDisabled={toolbarItems[tabNames.completion].formDisabled}
                parentProps={this.props}
                getFlowStatusData={getWorkOrderStatusFlows}
                statuses={statuses}
              />
            </Tab>
          </Tabs>
        </RenderIf>
        <Modal {...editFilesModalData} workorderFiles={workorderFiles} />
        <Modal {...uploadModalData} />
        <Modal {...filesModalData} modalDisabled={uploadInProgress} />
        <Modal {...screenShotPickerModal} question={{ ID: queryItem, QuestionsScreenshots: this.getImages() }} />
        <Modal {...changeStatusConfirmModal} modalDisabled={changeStatusInProgress} />
        <Modal {...completedStatusModal} modalDisabled={changeStatusInProgress} changeStatusInProgress={changeStatusInProgress} />
        <Modal {...addPermitsModal} permits={this.getAllPermits()} workorderPermits={workOrderPermits} />
        <Modal
          {...addCPTemplatesModal}
          cpTemplates={cpTemplates}
          workorderCPTemplates={workOrderCPTemplates}
          closeAction={() => addCPTemplatesModal?.closeAction && addCPTemplatesModal?.closeAction(workOrderCPTemplates)}
        />
        <Modal {...checklistModalData} workOrderChecklists={workOrderChecklists} />
        <Modal {...createWorkOrderPermitModal} templates={permitTemplates} />
        <Modal {...addComponentsModal} components={components} workorderComponents={workOrderComponents} />
        <Modal {...componentSelectionModal} components={components} question={{ ID: 1, QuestionsComponents: workOrderComponents }} />
        <Modal {...messageModalData} />
        <Modal {...addContractorsModal} usersAndTeams={users} workorderContractors={workorderContractors} />
        <Modal
          {...addAssigneesModal}
          collaborators={collaborators}
          assignees={assignees}
          selectedAssignee={isEmpty(workorderContributors.Assignees) ? null : workorderContributors.Assignees[0]}
          addedCollaborators={workorderContributors.Collaborators || []}
        />
        <Modal {...workAreasModal} question={{ ID: queryItem, QuestionAreas: workorderAreas }} />
        <Modal
          {...addAlertsModal}
          users={users}
          workOrderAlertList={userAlerts}
          addUserToAlertList={this.addUserToAlertList}
          removeUserFromAlertList={this.removeUserFromAlertList}
          handleAddAlert={this.handleAddAlert}
        />
        <Modal {...addObservationsModal} linkedObservations={workOrderObservations} />
        <Modal {...confirmModalData} />
        <StatusChangeNoteModal {...statusChangeNoteModalData} />
        <Notification {...notificationData} />
      </div>
    );
  }
}

WorkOrderRightSide.contextTypes = {
  t: PropTypes.func.isRequired,
};

WorkOrderRightSide.defaultProps = {
  isPdfScreen: false,
};

const mapStateToProps = state => {
  return {
    severityColors: state.themeReducer.severityColors,
    workOrderComponents: state.workOrderReducer.workOrderComponents,
    workorderAreas: state.workOrderReducer.workOrderAreas,
    workorderFiles: state.uploadReducer.workorderFiles,
    workOrderCompletionFiles: state.workOrderReducer.workOrderCompletionFiles,
    workorderComments: state.workOrderReducer.workOrderComments,
    workorderContractors: state.workOrderReducer.workOrderContractors,
    workorderContributors: state.workOrderReducer.workOrderContributors,
    workOrderPermits: state.workOrderReducer.workOrderPermits,
    workOrderCPTemplates: state.workOrderReducer.workOrderCPTemplates,
    workOrderChecklists: state.workOrderReducer.workOrderChecklists,
    workOrderObservations: state.workOrderReducer.workOrderObservations,
    workOrderDetailsLoading: state.workOrderReducer.workOrderDetailsLoading,
    user: state.userReducer,
    filters: state.workOrderReducer.filters,
    pagingObject: state.workOrderReducer.pagingObject,
    workOrders: state.workOrderReducer.workOrders,
    workOrderProperties: state.workOrderReducer.workOrderProperties,
    viewer: state.potreeReducer.viewerInstance,
    projectDMSCategories: state.projectDetailsReducer.projectDMSCategories,
    defaultWorkorderDMSCategoryID: state.projectDetailsReducer.DefaultWorkorderDMSCategoryID,
    inspectionModalData: state.inspectionReducer.inspectionModalData,
    uploadInProgress: state.uploadReducer.uploadInProgress,
    workOrderFormValues: state.form[FORMS.workOrderBasicDetailsForm]?.values || {},
    workOrderFormErrors: getFormSyncErrors(FORMS.workOrderBasicDetailsForm)(state),
    inspectionDetails: state.inspectionReducer.inspectionDetails,
    hasUnsavedChanges: state.unsavedChangesReducer.isDirty,
  };
};

const mapDispatchToProps = dispatch => ({
  getWorkOrderDetails: (data, includeLoader, callback) => dispatch(getWorkorderDetails(data, includeLoader, callback)),
  getWorkOrderTypes: callback => dispatch(getWorkorderTypes(callback)),
  getAdditionalWorkorderTypes: successCallback => dispatch(getAdditionalWorkorderTypes(successCallback)),
  updateWorkorder: (data, callback) => dispatch(updateWorkorder(data, callback)),
  getWorkOrderComponents: id => dispatch(getWorkOrderComponents(id)),
  getWorkOrderChecklists: id => dispatch(getWorkOrderChecklists(id)),
  getWorkOrderContributors: id => dispatch(getWorkOrderContributors(id)),
  getWorkOrderObservations: id => dispatch(fetchWorkOrderObservations(id)),
  getWorkOrderAreas: (id, successCallback) => dispatch(getWorkOrderAreas(id, {}, false, successCallback)),
  getWorkOrderFiles: workOrderId => dispatch(getWorkOrderFiles(workOrderId)),
  uploadAsset: (file, IDs, callbackFunction, index, customDoneFunc) => dispatch(uploadAsset(file, IDs, callbackFunction, index, customDoneFunc)),
  setSingleUploadItem: (progress, index) => dispatch(setSingleUploadItem(progress, index)),
  setUploadItems: assets => dispatch(setUploadItems(assets)),
  singleItemUploadDone: (url, callback) => dispatch(singleItemUploadDone(url, callback)),
  deleteWOFile: (workOrderId, fileId, section, callback, isCompletionFile) => dispatch(deleteWOFile(workOrderId, fileId, section, callback, isCompletionFile)),
  notificationModalCustom: (errorMessage, title) => dispatch(notificationModalCustom(true, errorMessage, title)),
  generateAndDownloadPDF: (workOrderId, additionalParams, callback) => dispatch(generateAndDownloadPDF(workOrderId, additionalParams, callback)),
  generateAndDownloadChecklistPDF: (workOrderId, checklistId, callback) => dispatch(generateAndDownloadChecklistPDF(workOrderId, checklistId, callback)),
  generateAndDownloadPDFChecklistTemplatePDF: (checklistTemplateId, callback) => dispatch(generateAndDownloadPDFChecklistTemplatePDF(checklistTemplateId, callback)),
  changeWorkOrderStatus: (data, filters, workOrders, callback) => dispatch(changeWorkOrderStatus(data, filters, workOrders, callback)),
  changeWorkOrderOnHold: (data, callback) => dispatch(changeWorkOrderOnHold(data, callback)),
  archiveWorkOrder: (data, callback) => dispatch(archiveWorkOrder(data, callback)),
  deleteWorkOrder: (data, workOrders, filters, callback) => dispatch(deleteWorkOrder(data, workOrders, filters, callback)),
  getPermits: (workorderId, callback) => dispatch(getPermits(workorderId, callback)),
  searchPermits: (workorderId, callback) => dispatch(searchPermits(workorderId, callback)),
  addWorkOrderPermit: (data, callback) => dispatch(addWorkOrderPermit(data, callback)),
  deleteWorkOrderPermit: (data, callback) => dispatch(deleteWorkOrderPermit(data, callback)),
  searchComponents: (inspectionId, searchText, successCallback, otherParams) => dispatch(searchComponents(inspectionId, searchText, successCallback, otherParams)),
  addWorkOrderComponent: (workOrderId, data) => dispatch(addWorkOrderComponent(workOrderId, data)),
  removeWorkorderComponent: (workOrderId, data, callback) => dispatch(removeWorkorderComponent(workOrderId, data, callback)),
  searchUsersAndTeams: (params, successCallback) => dispatch(searchUsersAndTeams(params, successCallback)),
  searchWorkOrderAssignees: (params, successCallback) => dispatch(searchWorkOrderAssignees(params, successCallback)),
  searchWorkOrderContributors: (params, successCallback) => dispatch(searchWorkOrderContributors(params, successCallback)),
  addWorkOrderContributor: (workOrderId, projectId, inspectionId, data) => dispatch(addWorkOrderContributor(workOrderId, projectId, inspectionId, data)),
  removeWorkorderContributor: (workOrderId, projectId, inspectionId, data, callback) => dispatch(removeWorkorderContributor(workOrderId, projectId, inspectionId, data, callback)),
  addWorkOrderArea: (woID, data) => dispatch(addWorkOrderArea(woID, data)),
  updateWorkOrderArea: (woID, data, dataCallback) => dispatch(updateWorkOrderArea(woID, data, dataCallback)),
  removeWorkorderArea: (woID, data) => dispatch(removeWorkorderArea(woID, data)),
  getWorkOrderStatusFlows: (workOrderId, callback) => dispatch(getWorkOrderStatusFlows(workOrderId, callback)),
  getPermitTemplates: (params, callback) => dispatch(getPermitTemplates(params, callback)),
  createWorkOrderPermit: (values, workorderId, includeLoader, callback) => dispatch(createWorkOrderPermit(values, workorderId, includeLoader, callback)),
  getWorkOrderCustomProperties: id => dispatch(getWorkOrderCustomProperties(id)),
  fetchWorkOrderComments: (params, callback) => dispatch(fetchWorkOrderComments(params, callback)),
  addWorkOrderComment: (params, dataCallback, loadingCallback) => dispatch(addWorkOrderComment(params, dataCallback, loadingCallback)),
  deleteWorkOrderComment: (params, dataCallback, loadingCallback) => dispatch(deleteWorkOrderComment(params, dataCallback, loadingCallback)),
  fetchCommentUsersAndTeams: (searchTerm, dataCallback, loadingCallback) => dispatch(fetchCommentUsersAndTeams(searchTerm, dataCallback, loadingCallback)),
  getWorkOrderContractors: id => dispatch(getWorkOrderContractors(id)),
  addWorkOrderContractors: (workOrderId, data) => dispatch(addWorkOrderContractors(workOrderId, data)),
  removeWorkorderContractors: (workOrderId, data, callback) => dispatch(removeWorkorderContractors(workOrderId, data, callback)),
  getWorkorderDMSFilesUploaded: id => dispatch(getWorkorderDMSFilesUploaded(id)),
  getWorkorderCompletionDMSFilesUploaded: id => dispatch(getWorkorderCompletionDMSFilesUploaded(id)),
  toggleInspectionModal: data => dispatch(toggleInspectionModal(data)),
  closeInspectionModal: () => dispatch(closeInspectionModal()),
  minimizeInspectionModal: () => dispatch(minimizeInspectionModal()),
  maximizeInspectionModal: () => dispatch(maximizeInspectionModal()),
  changePDFPage: (newPDFPageNumber, inspectionId, fileId, filter, selectedComponent, showOnlyConfirmedDrawings, callback) =>
    dispatch(changePDFPage(newPDFPageNumber, inspectionId, fileId, filter, selectedComponent, showOnlyConfirmedDrawings, callback)),
  openAsSeparatePageInspectionModal: () => dispatch(openAsSeparatePageInspectionModal()),
  getPdfComponentsForPopup: (inspectionID, fileId, filter, pageNumber, compId, callback) => dispatch(getPdfComponentsForPopup(inspectionID, fileId, filter, pageNumber, compId, callback)),
  setPdfComponents: selectedModuleItemDrawings => dispatch(setPdfComponents(selectedModuleItemDrawings)),
  setWorkOrderAreas: data => dispatch(setWorkOrderAreas(data)),
  setWorkOrderComponents: data => dispatch(setWorkOrderComponents(data)),
  setWorkOrderCompletionFiles: data => dispatch(setWorkOrderCompletionFiles(data)),
  unlinkDMSFileFromWorkorder: (sourceId, workorderId, categoryID, callback) => dispatch(unlinkDMSFileFromWorkorder(sourceId, workorderId, categoryID, callback)),
  unlinkDMSFileFromWorkorderCompletion: (sourceId, workorderId, categoryID, callback) => dispatch(unlinkDMSFileFromWorkorderCompletion(sourceId, workorderId, categoryID, callback)),
  getCPTemplates: (workorderId, callback) => dispatch(getCPTemplates(workorderId, callback)),
  searchCPTemplates: (filters, checklists, callback, loadMore) => dispatch(getChecklistTemplatesForWorkOrder(filters, checklists, callback, loadMore)),
  addWorkOrderCPTemplate: data => dispatch(amendWorkOrderCPTemplate(data)),
  deleteWorkOrderCPTemplate: data => dispatch(removeWorkOrderCPTemplate(data)),
  linkWorkorderChecklist: data => dispatch(linkWorkOrderCPTemplateToComponents(data)),
  unlinkWorkorderChecklist: data => dispatch(unlinkWorkOrderCPTemplateFromComponents(data)),
  updateChecklistAnswer: (data, callback) => dispatch(updateChecklistAnswer(data, callback)),
  submitChecklist: (checklistId, workOrderId, successCallback, failCallback) => dispatch(submitChecklist(checklistId, workOrderId, successCallback, failCallback)),
  removeComponentFromCPTemplate: (item, component) => dispatch(removeWorkOrderComponentFromCPTemplate(item, component)),
  saveCPTemplates: (workorderId, data, successCallback, failCallback) => dispatch(saveCPTemplates(workorderId, data, successCallback, failCallback)),
  setWorkOrderCPTemplates: data => dispatch(setWorkOrderCPTemplates(data)),
  setGenericNotification: data => dispatch(setGenericNotification(data)),
  createWorkOrderObservation: (data, callback) => dispatch(createWorkOrderObservation(data, callback)),
  changeField: (formName, fieldName, value) => dispatch(change(formName, fieldName, value)),
  updateGeometry: (data, callback) => dispatch(updateWorkOrderGeometry(data, callback)),
  setUnsavedChangesDirty: data => dispatch(setUnsavedChangesDirty(data)),
  setWorkOrderChecklists: data => dispatch(setWorkOrderChecklists(data)),
  getWorkOrderChecklistDetails: (checklistId, successCallback, failCallback) => dispatch(getWorkOrderChecklistDetails(checklistId, successCallback, failCallback)),
  getWorkOrderChecklistSections: (workOrderId, checklistId, filters, successCallback, failCallback) =>
    dispatch(getWorkOrderChecklistSections(workOrderId, checklistId, filters, successCallback, failCallback)),
  getWorkOrderChecklistSectionQuestions: (sectionId, filters, successCallback, failCallback) => dispatch(getWorkOrderChecklistSectionQuestions(sectionId, filters, successCallback, failCallback)),
  getWorkOrderChecklistSectionQuestionDetails: (sectionId, questionId, successCallback, failCallback) =>
    dispatch(getWorkOrderChecklistSectionQuestionDetails(sectionId, questionId, successCallback, failCallback)),
  createWorkOrderObservationCP: (data, callback) => dispatch(createWorkOrderObservationCP(data, callback)),
  fetchChecklistProcedureTemplateSections: (data, callback, questionId, areAdditionalFieldsExpanded) =>
    dispatch(getWorkOrderChecklistTemplateSections(data, callback, questionId, areAdditionalFieldsExpanded)),
  fetchChecklistProcedureTemplateSectionQuestions: (ChecklistAndProcedureTemplateID, SectionID, filters, callback) =>
    dispatch(getWorkOrderChecklistTemplateSectionQuestions(ChecklistAndProcedureTemplateID, SectionID, filters, callback)),
  workOrderChecklistValidate: (data, callback) => dispatch(workOrderChecklistValidate(data, callback)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkOrderRightSide));
