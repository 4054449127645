import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Button from '../../form/components/button';
import Helpers from '../../helpers';
import Icon from '../../icon/components/icon';
import RenderIf from '../../render-if/components/render-if';
import { modalOpenedClass } from '../constants/modal-constants';

import Textarea from '../../form/components/text-area';
import { statusChangeNoteModalConstants } from '../constants/modal-constants';
import '../styles/modal.scss';

class StatusChangeNoteModal extends Component {
  constructor(props) {
    super(props);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      modalHash: null,
      [statusChangeNoteModalConstants.comment.name]: '',
      disabled: false,
    };

    this.node = React.createRef();
  }

  escFunction = event => {
    const { isOpen, closeAction } = this.props;
    if (event.keyCode === 27) {
      if (isOpen && closeAction) closeAction();
    }
  };

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false);
    const parent = this.node && this.node.current && this.node.current.parentNode;

    const additionalClass = parent && parent.classList ? (parent.classList.value.indexOf('navigation') > -1 ? 'from-navi' : '') : '';

    const modalHash = `${modalOpenedClass}_${additionalClass}_${Helpers.uuid4()}`;

    this.setState({ modalHash });
    document.body.classList.add(modalHash);
  }

  componentWillUnmount = () => {
    const { modalHash } = this.state;
    document.removeEventListener('keydown', this.escFunction, false);
    document.body.classList.remove(modalHash);
  };

  handleAddComment = () => {
    const { confirmAction } = this.props;

    const stateCleanup = isSuccess => {
      const newState = {
        disabled: false,
      };

      if (isSuccess) {
        newState[statusChangeNoteModalConstants.comment.name] = '';
      }

      this.setState(newState);
    };

    if (this.state[statusChangeNoteModalConstants.comment.name] && this.state[statusChangeNoteModalConstants.comment.name].length <= 2000 && typeof confirmAction === 'function') {
      this.setState({ disabled: true });
      confirmAction(
        {
          [statusChangeNoteModalConstants.comment.name]: this.state[statusChangeNoteModalConstants.comment.name],
        },
        stateCleanup
      );
    }
  };

  render() {
    const { t } = this.context;
    const { title, content, CustomContent, closeAction, confirmAction, modalDisabled, customClassName, confirmButtonLabel, ...restProps } = this.props;
    const { disabled } = this.state;
    const { comment } = statusChangeNoteModalConstants;

    return (
      <div className={`modal-container rejection-note-modal ${customClassName || ''}`} ref={this.node}>
        <div className="modal-wrapper">
          <RenderIf if={title && title !== ''}>
            <h3 className="modal-wrapper__title noselect">{title}</h3>
          </RenderIf>

          {closeAction && (
            <div className="modal-action-items">
              <Icon name="close" className="close-modal modal-action-items__action-item" onClick={modalDisabled ? null : closeAction} />
            </div>
          )}
          <div className={`modal${title && title !== '' ? ' modal-with-absolute-title' : ''}`}>
            {content && <p className="modal__content light-bold">{content}</p>}
            {CustomContent && <CustomContent {...restProps} />}
            <div className="add-comment-wrapper">
              <label className="comment-label">{t(comment.label)}</label>
              <div className="textarea-wrapper" id="comment-add-container">
                <Textarea
                  input={{ onChange: e => this.setState({ [comment.name]: e.target.value }), value: this.state[comment.name] }}
                  meta={{}}
                  placeholder={comment.label}
                  name={comment.name}
                  maxChars={comment.maxChars}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="modal__button-container">
              <Button onClick={closeAction} type="button" text={t('CANCEL')} variant="gray-outline" />
              <Button
                disabled={disabled || !this.state[comment.name] || this.state[comment.name].length > comment.maxChars}
                type="button"
                text={confirmButtonLabel}
                className="modal__button"
                onClick={this.handleAddComment}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

StatusChangeNoteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeAction: PropTypes.func,
  confirmAction: PropTypes.func,
  CustomContent: PropTypes.func,
};

StatusChangeNoteModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default props => {
  return props.isOpen ? <StatusChangeNoteModal {...props} /> : null;
};
