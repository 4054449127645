export const sections = {
  high: {
    name: 'high',
    variants: {
      HH: 'HH',
      H: 'H',
    },
  },
  low: {
    name: 'low',
    variants: {
      LL: 'LL',
      L: 'L',
    },
  },
  excessiveRate: {
    name: 'excessiveRate',
    variants: {
      D: 'ERD',
      W: 'ERW',
      M: 'ERM',
      Y: 'ERY',
    },
  },
};

export const commonFields = {
  value: 'Value',
  descriptor: 'Descriptor',
  email: 'Email',
  color: 'Colour',
  active: 'Active',
  alarmType: 'AlarmType',
  operationID: 'OperationID',
  id: 'ID',
  enableNotifications: 'EnableNotifications',
};

export const colorsMap = {
  severityYellow: 'severityYellow',
  severityOrange: 'severityOrange',
  severityRed: 'severityRed',
};

export const formSections = {
  [sections.high.name]: {
    label: 'NDT_ALARMS_MODAL.SECTION_TITLE_HIGH',
    variants: {
      [sections.high.variants.HH]: commonFields,
      [sections.high.variants.H]: commonFields,
    },
  },
  [sections.low.name]: {
    label: 'NDT_ALARMS_MODAL.SECTION_TITLE_LOW',
    variants: {
      [sections.low.variants.LL]: commonFields,
      [sections.low.variants.L]: commonFields,
    },
  },
  [sections.excessiveRate.name]: {
    label: 'NDT_ALARMS_MODAL.SECTION_TITLE_EXCESSIVE_RATE',
    variants: {
      [sections.excessiveRate.variants.D]: commonFields,
      [sections.excessiveRate.variants.W]: commonFields,
      [sections.excessiveRate.variants.M]: commonFields,
      [sections.excessiveRate.variants.Y]: commonFields,
    },
  },
};

export const defaultAlarmValues = {
  AlarmType: 'HH',
  Colour: '',
  Active: false,
  Value: null,
  OperationID: 1,
  Email: '',
  Id: 'ID',
  [commonFields.enableNotifications]: false,
};

export const alarms = {
  HH: 'NDT_MEASUREMENTS.ALARM_HIGH_HIGH',
  H: 'NDT_MEASUREMENTS.ALARM_HIGH',
  LL: 'NDT_MEASUREMENTS.ALARM_LOW_LOW',
  L: 'NDT_MEASUREMENTS.ALARM_LOW',
  ERD: 'NDT_MEASUREMENTS.EXCESSIVE_RATE',
};
