import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import EmptyState from '../../../../../common/empty-state/components/empty-state';
import Helpers from '../../../../../common/helpers';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../../common/permissions-constants';
import RenderIf from '../../../../../common/render-if/components/render-if';
import routesConstants from '../../../../../common/routes-constants';
import { modules } from '../../../constants/constants';
import '../../observations/styles/component-defect-tab.scss';
import { getComponentRegimes } from '../actions/maintenance-regimes-api-calls';
import { defaultFilters, fields, filterProps } from '../constants/constants';
import '../styles/component-maitenance-regimes-tab.scss';
import MaintenanceRegimesWrapper from './maintenance-regimes-wrapper';

const ComponentMaintenanceRegimesTab = ({ location, getComponentRegimes, user, selectedComponent, queryItem }) => {
  const componentID = parseInt(queryItem) || selectedComponent[fields.id];
  const [filters, setFilters] = useState({ ComponentID: componentID, ...defaultFilters });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const userHasAccess = Helpers.hasAccess({ user, visibleFor: PERMISSIONS[PERMISSION_TYPES.maintenanceRegimes].view.name });

  const handleOnRowClick = row => {
    if (!row.ID) return;
    const path = routesConstants.routes.protectedRoutes.maintenanceRegime.fullPath;
    const { query } = location;
    const newQuery = { ...query, type: modules.maintenanceRegimes, selected_item: row?.ID };
    const params = Object.keys(newQuery).map(key => {
      const obj = {};
      obj[key] = newQuery[key];
      return obj;
    });
    Helpers.goTo(path, params);
  };

  const fetchComponentRegimes = (filters, loadMore) => {
    if (!componentID || componentID < 1) return;

    const onSuccess = (items, newFilters) => {
      setLoading(false);
      setData(loadMore ? [...data, ...items] : items);
      setFilters(prev => ({ ...prev, ...newFilters }));
      if (loadMore) {
        Helpers.scrollIntoView('maintenance-regimes-table', `row-${filters[filterProps.lastSeen]}`, -525);
      }
    };

    getComponentRegimes(filters, onSuccess, setLoading);
  };

  useEffect(() => {
    userHasAccess && componentID && componentID > 0 && fetchComponentRegimes(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <RenderIf if={userHasAccess}>
        <MaintenanceRegimesWrapper
          data={data || []}
          filters={filters}
          wrapperClassName="component-maintenance-regimes-tab"
          loading={loading}
          fetchComponentRegimes={fetchComponentRegimes}
          customTableClassName="maintenance-regimes-table"
          onRowClick={handleOnRowClick}
        />
      </RenderIf>
      <RenderIf if={!userHasAccess}>
        <EmptyState icon="crossed-out-circle" iconSize="xs" message="COMPONENTS_TAB.NO_PERMISSION" title="COMPONENTS_TAB.NO_PERMISSION_TITLE" />
      </RenderIf>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  user: state.userReducer,
});

const mapDispatchToProps = dispatch => ({
  getComponentRegimes: (filters, dataCallback, loadingCallback) => dispatch(getComponentRegimes(filters, dataCallback, loadingCallback)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ComponentMaintenanceRegimesTab));
