import Helpers from '../../../../../common/helpers';
import Icon from '../../../../../common/icon/components/icon';
import { getIconForFileType } from '../../../../document-management/actions/table-formatting';

export const formConstants = {
  fields: {
    documentURL: 'DocumentURL',
    fileID: 'FileID',
    fileKey: 'FileKey',
    fileName: 'FileName',
    questionID: 'QuestionID',
    thumbnailUrl: 'ThumbnailURL',
    url: 'URL',
    isImage: 'isImage',
    isPdf: 'isPDF',
    uploadedAt: 'UploadedAt',
    uploadedBy: 'UploadedBy',
    createdAt: 'CreatedAt',
    createdByName: 'CreatedByName',
  },
};

export const tableCellTypes = {
  icon: 'Icon',
  deleteFileRowAction: 'deleteRowAction',
  fileName: 'FileName',
};

export const formatFileTableCells = (
  value,
  type,
  _searchInputValue,
  row,
  _onDropdownClick,
  _t,
  _pointerDotID,
  _user,
  _onToggleClick,
  onDeleteRow,
  _statusData,
  _handleChangeStatus,
  _onLinkFileClick,
  _selectedItem,
  _cell,
  _hasLinkDMSFilePermissions,
  _onRowClick,
  _toggleModalAction,
  _onReorderClick,
  rowIndex
) => {
  if (type === tableCellTypes.icon) {
    return <Icon name={getIconForFileType(row[formConstants.fields.fileName])} size="sm" active />;
  } else if (type === tableCellTypes.fileName) {
    const decodedFileName = Helpers.decodeIfStringEncoded(value);

    return <div class="name-container">{decodedFileName}</div>;
  } else if (type === tableCellTypes.deleteFileRowAction) {
    return (
      <Icon
        name="trash"
        size="sm"
        className="pointer"
        onClick={e => {
          e.stopPropagation();
          onDeleteRow(row);
        }}
      />
    );
  } else {
    return Helpers.formatCell(value, type, rowIndex, row) || '-';
  }
};

export const tableConfig = [
  {
    title: '',
    type: tableCellTypes.icon,
    class: 'icon-column',
  },
  {
    title: 'DOCUMENT_MANAGEMENT_TABLE.NAME',
    key: formConstants.fields.fileName,
    type: tableCellTypes.fileName,
    class: 'name-column',
  },
  {
    title: 'CHECKLISTS_PROCEDURES_LIST.CREATED_DATE',
    key: formConstants.fields.createdAt,
    type: 'date',
    class: 'date-column',
  },
  {
    title: 'CHECKLISTS_PROCEDURES_LIST.CREATOR',
    key: formConstants.fields.createdByName,
    class: 'uploaded-by-column',
  },
  {
    title: '',
    type: tableCellTypes.deleteFileRowAction,
    class: 'action-column',
    className: 'action-menu',
  },
];
