import React from 'react';
import PropTypes from 'prop-types';

import RenderIf from '../../render-if/components/render-if';
import Icon from '../../icon/components/icon';

const HistoryItemAreaGroupChange = ({ from, to }) => {
  const getObject = value => {
    if (!value) {
      return {};
    }

    if (value && parseInt(value)) {
      return { ID: parseInt(value), Name: '' };
    } else {
      let res = {};

      try {
        return JSON.parse(value);
      } catch (err) {
        return res;
      }
    }
  };

  return (
    <div className="history-item-display">
      <RenderIf if={from}>
        <div>{`#${getObject(from)?.ID} ${getObject(from)?.Name}`}</div>
      </RenderIf>
      <Icon name="arrow-right" size="sm" className="history-item-display__icon" />

      <RenderIf if={to}>
        <div>{`#${getObject(to)?.ID} ${getObject(to)?.Name}`}</div>
      </RenderIf>
    </div>
  );
};
HistoryItemAreaGroupChange.contextTypes = {
  t: PropTypes.func.isRequired,
};

HistoryItemAreaGroupChange.propTypes = {
  from: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  to: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default HistoryItemAreaGroupChange;
