import { completedFormFields } from '../constants/work-order-right-side-constants';

export const validate = values => {
  const errors = {};
  const { workCompleted, recommendations, actualEndTime } = completedFormFields;

  if (!values[workCompleted.name]) {
    errors[workCompleted.name] = workCompleted.errorRequired;
  }

  if (values[workCompleted.name] && values[workCompleted.name].length > workCompleted.maxChars) {
    errors[workCompleted.name] = workCompleted.errorMaxChars;
  }

  if (values[recommendations.name] && values[recommendations.name].length > recommendations.maxChars) {
    errors[recommendations.name] = recommendations.errorMaxChars;
  }

  if (!values[actualEndTime.name]) {
    errors[actualEndTime.name] = actualEndTime.errorRequired;
  }

  return errors;
};

export const validateUpdate = values => {
  const errors = {};
  const { workCompleted, recommendations, actualEndTime } = completedFormFields;

  if (!values[workCompleted.name]) {
    errors[workCompleted.name] = workCompleted.errorRequired;
  }

  if (values[workCompleted.name] && values[workCompleted.name].length > workCompleted.maxChars) {
    errors[workCompleted.name] = workCompleted.errorMaxChars;
  }

  if (values[recommendations.name] && values[recommendations.name].length > recommendations.maxChars) {
    errors[recommendations.name] = recommendations.errorMaxChars;
  }

  if (!values[actualEndTime.name]) {
    errors[actualEndTime.name] = actualEndTime.errorRequired;
  }

  return errors;
};
