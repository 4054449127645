import React, { Component, Fragment } from 'react';

import { isEmpty } from 'lodash';

import ToolsBar from './tools-bar';
import CustomSlider from '../../../common/slider/components/custom-slider';
import ImageListView from '../../inspection-workflow/components/image-list-view';
import HeaderSliderItem from './header-slider-item';
import ImageDescription from './image-description';
import ChartView from './charts-view';

import orientationConstants from '../../start-workflow/constants/orientation-constants';

import Helpers from '../../../common/helpers';

import '../styles/header-slider.scss';

class HeaderSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fillColor: '#d83d1a66',
      savedSketch: null,
    };
  }

  setOrientation = orientation => {
    const { setOrientation } = this.props;

    setOrientation(orientation);
  };

  // removeSelectedObject = () => {
  //   const { sketchRef } = this.props;
  //   if (!sketchRef) return;
  //   sketchRef.removeSelected();
  // };

  renderItem = item => {
    const {
      selectedTool,
      selectDrawingTool,
      drawingToolsEnabled,
      drawings,
      enableDrawing,
      currentImage,
      isFullScreen,
      sliderImageType,
      currentCamera,
      sketchRef,
      setSketchRef,
      drawingsLoading,
      onDrawingAdded,
      onDrawingEdited,
      onColorChange,
      modalDataFullScreen,
      fillColor,
    } = this.props;

    return (
      <HeaderSliderItem
        onColorChange={onColorChange}
        selectedTool={selectedTool}
        fillColor={fillColor}
        item={item}
        selectDrawingTool={selectDrawingTool}
        drawingToolsEnabled={drawingToolsEnabled}
        isFullScreen={isFullScreen}
        drawings={drawings}
        enableDrawing={enableDrawing}
        currentImage={currentImage}
        sliderImageType={sliderImageType}
        currentCamera={currentCamera}
        sketchRef={sketchRef}
        setSketchRef={setSketchRef}
        drawingsLoading={drawingsLoading}
        // removeSelectedObject={this.removeSelectedObject}
        onDrawingAdded={onDrawingAdded}
        onObjectAdded={() => null}
        onObjectModified={onDrawingEdited}
        modalDataFullScreen={modalDataFullScreen}
      />
    );
  };

  renderFullOrientation = () => {
    const { workflowImagesSelected, renderRightNav, renderLeftNav, forwardRef, currentImage, currentSlideIndex, onSlide, updateFullScreen, isFullScreen, highlightSlider, renderCustomControls } =
      this.props;

    return (
      <Fragment>
        <CustomSlider
          ref={forwardRef}
          additionalClass={isEmpty(workflowImagesSelected) ? 'no-images' : ''}
          items={workflowImagesSelected || []}
          showPlayButton={false}
          showThumbnails={false}
          renderRightNav={renderRightNav}
          renderLeftNav={renderLeftNav}
          renderItem={this.renderItem}
          slideDuration={100}
          disableArrowKeys={!isFullScreen}
          showNav={false}
          onScreenChange={updateFullScreen}
          startIndex={currentSlideIndex > 0 ? currentSlideIndex : 0}
          onSlide={onSlide}
          highlightSlider={highlightSlider}
          renderCustomControls={renderCustomControls}
        />
        {<ImageDescription activeItem={currentImage} />}
      </Fragment>
    );
  };

  renderGridOrientation = () => {
    const { workflowImagesSelected, handleImageActive, gridImageType, currentSlideIndex } = this.props;
    return (
      <div className="main-container">
        <ImageListView
          showReviewed={true}
          orientation={orientationConstants.orientation.grid}
          images={Helpers.formatImageArray(workflowImagesSelected)}
          handleImageActive={handleImageActive}
          activeIndex={currentSlideIndex}
          gridImageType={gridImageType}
        />
      </div>
    );
  };

  renderChartOrientation = () => {
    const { severityColors, getInspectedImagesCount, inspectedImagesCount } = this.props;

    return (
      <div className="main-container">
        <ChartView severityColors={severityColors} getInspectedImagesCount={getInspectedImagesCount} inspectedImagesCount={inspectedImagesCount} />
      </div>
    );
  };

  render() {
    const {
      workflowImagesSelected,
      currentSlideIndex,
      previousClick,
      nextClick,
      orientation,
      selectedTool,
      selectDrawingTool,
      drawingToolsEnabled,
      enableDrawing,
      disableColorPicker,
      drawings,
      onColorChange,
      fillColor,
      disabledOrientations,
      CustomToolsComponent,
      currentImage,
      hideToolsBar,
    } = this.props;

    return (
      <div className="header-slider">
        <div className="header">
          <div className="header__slider">
            {(orientation === orientationConstants.orientation.full || orientation === null) && this.renderFullOrientation()}

            {orientation === orientationConstants.orientation.grid && this.renderGridOrientation()}

            {orientation === orientationConstants.orientation.chart && this.renderChartOrientation()}

            {!hideToolsBar && (
              <ToolsBar
                {...{
                  workflowImagesSelected,
                  currentSlideIndex,
                  previousClick,
                  nextClick,
                  orientation,
                  selectedTool,
                  fillColor,
                  onColorChange,
                  drawingToolsEnabled,
                  enableDrawing,
                  selectDrawingTool,
                  disabledOrientations,
                  setOrientation: this.setOrientation,
                  disableColorPicker: disableColorPicker && (!drawings || isEmpty(drawings.objects)),
                  CustomToolsComponent,
                  currentImage,
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default React.forwardRef((props, node) => {
  return <HeaderSlider forwardRef={node} {...props} />;
});
