import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { isEmpty } from 'lodash';

import { Tools } from '@tarik.djurdjevic/react-sketch';
import InfoTooltip from '../../../common/tooltip/components/info-tooltip';
import Modal from '../../../common/modal/components/modal';
import Icon from '../../../common/icon/components/icon';

import Helpers from '../../../common/helpers';

import { DrawingToolsFullscreen } from './drawing-tools';
import workflowConstants from '../../inspection-workflow/constants/inspection-workflow-constants';

import ImageWithDrawing from '../../../common/image-components/components/image-with-drawing';
import SimpleLoader from '../../../common/global-loader/components/simple-loader';
import RenderIf from '../../../common/render-if/components/render-if';

class HeaderSliderItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detailsOpen: false,
    };
  }

  setTooltipDetailsOn = () => {
    this.setState({ detailsOpen: true });
  };

  setTooltipDetailsOff = () => {
    this.setState({ detailsOpen: false });
  };

  isItemActive = () => {
    const { currentImage, item } = this.props;
    return !isEmpty(currentImage) && !isEmpty(item) && currentImage[workflowConstants.formConstants.fields.id] === item[workflowConstants.formConstants.fields.id];
  };

  renderActiveDrawing = () => {
    const { drawingsLoading, selectedTool, fillColor, item, isFullScreen, drawings, enableDrawing, sliderImageType, setSketchRef, onDrawingAdded, onObjectAdded, onObjectModified } = this.props;

    if (!this.isItemActive()) {
      return null;
    }

    if (drawingsLoading) {
      return <SimpleLoader isLoading={true} className="image-loader" />;
    }

    const lineColor = fillColor ? fillColor.split('').splice(0, 7).join('') : '';

    const sketchSharedProps = {
      ref: setSketchRef,
    };
    const sketchProps = enableDrawing
      ? {
          onMouseUp: () => {
            if (selectedTool === Tools.Rectangle) {
              onDrawingAdded();
            }
          },
          onObjectMoving: () => console.log('Object is moving '),
          onObjectAdded: () => onObjectAdded(),
          ...sketchSharedProps,
        }
      : {
          onObjectModified: () => onObjectModified(),
          ...sketchSharedProps,
        };
    return (
      <ImageWithDrawing item={{ ...item }} drawings={drawings} lineColor={lineColor} sketchProps={sketchProps} isFullScreen={isFullScreen} imageType={sliderImageType} selectedTool={selectedTool} />
    );
  };

  render() {
    const { detailsOpen } = this.state;
    const { selectedTool, fillColor, selectDrawingTool, item, onColorChange, drawingToolsEnabled, enableDrawing, modalDataFullScreen } = this.props;

    return (
      <div className="header__slider-item">
        {this.renderActiveDrawing()}

        <div className="tools-container">
          {drawingToolsEnabled && (
            <DrawingToolsFullscreen onColorChange={onColorChange} selectedTool={selectedTool} fillColor={fillColor} selectDrawingTool={selectDrawingTool} enableDrawing={enableDrawing} />
          )}
        </div>
        <div className="custom-overlay">
          {item[workflowConstants.formConstants.fields.resolution] && <p className="f-white"> {item[workflowConstants.formConstants.fields.resolution]}</p>}
          <p className="f-white"> {item[workflowConstants.formConstants.fields.name]}</p>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p className="f-white">{Helpers.getDateFromUnix(item[workflowConstants.formConstants.fields.createdAt])}</p>
            <RenderIf if={!isEmpty(item.actionsMenu)}>
              <InfoTooltip
                actionsMenu={item.actionsMenu}
                offsetY={10}
                offsetX={-30}
                opened={detailsOpen}
                Component={() => <Icon name="info" handleHover={false} size="xs" />}
                className="image-details"
                componentProps={{ title: '' }}
                containerProps={{ onMouseEnter: this.setTooltipDetailsOn, onMouseLeave: this.setTooltipDetailsOff }}
              />
            </RenderIf>
          </div>
        </div>
        <Modal {...modalDataFullScreen} />
      </div>
    );
  }
}

HeaderSliderItem.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default HeaderSliderItem;
