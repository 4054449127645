import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ResizableRect from '../../../common/resize-box/components/resize-box';
import Icon from '../../../common/icon/components/icon';

import { placements } from '../constants/constants';

import '../styles/toolbar.scss';

class Toolbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      minWidth: props.defaultWidth,
      width: props.defaultWidth,
      height: 'max-height',
    };
  }

  onResize = ({ width }) => {
    const { minWidth } = this.state;

    this.setState({ width: width > minWidth ? width : minWidth });
  };

  resetParams = () => {
    const { defaultWidth } = this.props;
    this.setState({ width: defaultWidth });
  };

  renderToolbar(style) {
    const { children, placement, collapsed, toggleToolbar, className, stopProp } = this.props;
    const { width } = this.state;

    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, {
        currentWidth: style ? width : null,
        onClose: () => this.resetParams(),
      })
    );

    return (
      <div
        className={`toolbar-container toolbar-container__${placement} toolbar-container__${placement}--${collapsed ? 'hidden' : 'visible'} ${className || ''} ${style ? 'inherit-size' : ''}`}
        style={style || {}}
      >
        <div className="toolbar">
          <div onClick={e => (stopProp ? toggleToolbar(placement, e) : toggleToolbar(placement))} className="toolbar__collapse-btn">
            <Icon name={'toggler'} className={`${collapsed ? 'reverse' : ''}`} />
          </div>
          {childrenWithProps}
        </div>
      </div>
    );
  }

  render() {
    const { resizable, collapsed, placement } = this.props;

    const { width, height } = this.state;
    return resizable ? (
      <ResizableRect {...{ width: width || 100, height: height || 100 }} direction="w" onResize={this.onResize} className={`toolbar-resizable ${collapsed ? 'contents' : ''}`}>
        {this.renderToolbar({ [placement]: collapsed ? -width + 'px' : 0, width: width + 'px' })}
      </ResizableRect>
    ) : (
      this.renderToolbar()
    );
  }
}

Toolbar.contextTypes = {
  t: PropTypes.func.isRequired,
};

Toolbar.propTypes = {
  placement: PropTypes.oneOf([placements.left, placements.right, placements.top, placements.bottom]),
  toggleToolbar: PropTypes.func,
  collapsed: PropTypes.bool.isRequired,
};

Toolbar.defaultProps = {
  placement: placements.left,
  collapsed: false,
  stopProp: false,
};

export default Toolbar;
