import React from 'react';

import PropTypes from 'prop-types';
import { keyboardKeyTypes } from '../constants/constants';

import '../styles/keyboard-key.scss';

const KeyboardKey = ({ letter, type = keyboardKeyTypes.letter }) => {
  const renderKey = () => {
    switch (type) {
      case keyboardKeyTypes.letter:
        return <div className="letter-key">{letter}</div>;
      case keyboardKeyTypes.arrowDown:
        return <div className="arrow arrow--down" />;
      case keyboardKeyTypes.arrowUp:
        return <div className="arrow arrow--up" />;
      case keyboardKeyTypes.arrowRight:
        return <div className="arrow arrow--right" />;
      case keyboardKeyTypes.arrowLeft:
        return <div className="arrow arrow--left" />;
      case keyboardKeyTypes.scroll:
        return <div className="wheel" />;
      case keyboardKeyTypes.leftClick:
        return (
          <>
            <div className="left-click" />
            <div className="wheel" />
          </>
        );
      case keyboardKeyTypes.rightClick:
        return (
          <>
            <div className="right-click" />
            <div className="wheel" />
          </>
        );
      default:
        return null;
    }
  };
  const getModifierClassName = () => {
    switch (type) {
      case keyboardKeyTypes.scroll:
      case keyboardKeyTypes.leftClick:
      case keyboardKeyTypes.rightClick:
        return ` keyboard-key--${type}`;
      default:
        return '';
    }
  };
  return <div className={`keyboard-key${getModifierClassName()}`}>{renderKey()}</div>;
};

KeyboardKey.propTypes = {
  letter: PropTypes.string,
  type: PropTypes.oneOf(Object.values(keyboardKeyTypes)),
};

export default KeyboardKey;
