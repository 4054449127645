export const fields = {
  id: 'ID',
  lastSeen: 'LastSeen',
  perPage: 'PerPage',
  hasNext: 'HasNext',
  totalItems: 'TotalItems',
};

export const defaultPagingFilters = {
  [fields.lastSeen]: 0,
  [fields.perPage]: 10,
};
