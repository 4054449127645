import { Component } from 'react';
import '../../styles/ch-right-side.scss';
import ChRightSideBasicForm from './ch-right-side-basic-form';
import Loader from '../../../../common/global-loader/components/simple-loader';
import RenderIf from '../../../../common/render-if/components/render-if';

class CHRightSide extends Component {
  render() {
    const { isLoading, initialValues, onChange, properties, getSuggestions, addProperty, updateProperty, deleteProperty, isDisabled } = this.props;
    return (
      <div className="ch-right-side">
        <RenderIf if={isLoading}>
          <Loader isLoading={isLoading} />
        </RenderIf>
        <RenderIf if={!isLoading}>
          <ChRightSideBasicForm
            initialValues={initialValues}
            onChange={onChange}
            properties={properties}
            getSuggestions={getSuggestions}
            addProperty={addProperty}
            updateProperty={updateProperty}
            deleteProperty={deleteProperty}
            isDisabled={isDisabled}
          />
        </RenderIf>
      </div>
    );
  }
}
export default CHRightSide;
