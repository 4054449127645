import contractorsApi from '../../../api/contractors/actions';
import { formConstants } from '../constants/constants';
import { uniqBy } from 'lodash';

export const getContractors = (filter, contractors, callback, loadMore = false) => {
  return async () => {
    try {
      callback({ isLoading: true });
      const res = await contractorsApi.getContractorsList(filter);
      const data = res?.data?.Data;

      if (data?.Contractors) {
        let newContractors = loadMore ? [...contractors, ...data.Contractors] : data.Contractors;
        newContractors = uniqBy(newContractors, formConstants.fields.id);
        callback({
          isLoading: false,
          contractors: newContractors,
          filter: { ...filter, HasNext: data.HasNext || false, LastSeen: data.LastSeen, TotalItems: data.TotalItems },
        });
      } else {
        callback({ isLoading: false });
      }
    } catch (e) {
      callback({ isLoading: false });
      console.log(e);
    }
  };
};

export const getContractorDetails = (contractor_id, successCallback = () => null) => {
  return async () => {
    try {
      const res = await contractorsApi.getContractorDetails([{ contractor_id }]);
      const { Data } = res.data;

      successCallback && successCallback(Data);
    } catch (error) {
      console.error(error);
    }
  };
};

export const createContractor = (params, successCallback, errorCallback) => {
  return async () => {
    try {
      const res = await contractorsApi.createContractor(params);
      const { Data } = res.data;

      successCallback && successCallback(Data);
    } catch (error) {
      console.error(error);
      errorCallback && errorCallback(error);
    }
  };
};

export const updateContractor = (projectId, params, successCallback, errorCallback) => {
  return async () => {
    try {
      const res = await contractorsApi.updateContractor({ ...params, ProjectID: projectId });
      const { Data } = res.data;

      successCallback && successCallback(Data);
    } catch (error) {
      console.error(error);
      errorCallback && errorCallback(error);
    }
  };
};

export const updateContractorStatus = (params, successCallback = () => null) => {
  return async () => {
    try {
      await contractorsApi.updateStatus(params);
      successCallback && successCallback(params);
    } catch (error) {
      console.error(error);
    }
  };
};

export const deleteContractor = (contractor, successCallback = () => null) => {
  return async () => {
    try {
      await contractorsApi.deleteContractor({ ContractorID: contractor[formConstants.fields.id] });

      successCallback && successCallback(contractor);
    } catch (error) {
      console.error(error);
    }
  };
};

export const getContractorFiles = (contractor_id, successCallback = () => null) => {
  return async () => {
    try {
      const res = await contractorsApi.getContractorFiles([{ contractor_id }]);
      const { Data } = res.data;

      successCallback && successCallback(Data);
    } catch (error) {
      console.error(error);
    }
  };
};

export const deleteContractorFile = (params, successCallback = () => null) => {
  return async () => {
    try {
      await contractorsApi.deleteContractorFile(params);

      successCallback && successCallback();
    } catch (error) {
      console.error(error);
    }
  };
};

export const getContractorUserList = (params, successCallback = () => null) => {
  return async () => {
    try {
      const res = await contractorsApi.getUsers({ ...params, CompanyID: 1 });
      const { Data } = res.data;

      successCallback && successCallback(Data);
    } catch (error) {
      console.error(error);
    }
  };
};

export const restoreContractor = (contractor, successCallback = () => null) => {
  return async () => {
    try {
      const res = await contractorsApi.restoreContractor({
        [formConstants.fields.email]: contractor[formConstants.fields.email],
        [formConstants.fields.projectId]: contractor[formConstants.fields.projectId],
      });
      const { Data } = res.data;
      successCallback && successCallback(Data);
    } catch (error) {
      console.error(error);
    }
  };
};
