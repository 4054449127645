import actionTypes from '../constants/action-types.js';
import { findIndex } from 'lodash';

const pdfTag = {
  pdfList: [],
  selectedFile: null,
  pdfComponents: null,
  componentsLoading: false,
  selectedDrawing: null,
  pdfFileLoading: false,
  pdfPopupLoading: false,
  pdfPageNumber: 1,
};

export const pdfTagReducer = (state = pdfTag, payload) => {
  switch (payload.type) {
    case actionTypes.SET_PDF_LIST:
      return { ...state, pdfList: payload.data };
    case actionTypes.SET_PDF_FILE_LOADING:
      return { ...state, pdfFileLoading: payload.data || false };
    case actionTypes.SET_PDF_POPUP_COMPONENTS_LOADING:
      return { ...state, pdfPopupLoading: payload.data || false };
    case actionTypes.SET_SELECTED_FILE:
      return { ...state, selectedFile: payload.data };
    case actionTypes.SET_PDF_COMPONENTS:
      return { ...state, pdfComponents: payload.data };
    case actionTypes.AMEND_PDF_COMPONENTS:
      return { ...state, pdfComponents: state.pdfComponents[payload.data] };
    case actionTypes.SET_COMPONENTS_LOADING:
      return { ...state, componentsLoading: payload.data || false };
    case actionTypes.HANDLE_ADD_NEW_COMPONENT:
      return { ...state, pdfComponents: state?.pdfComponents?.concat([payload.data]) || [] };
    case actionTypes.HANDLE_UPDATE_COMPONENT_IN_ARRAY:
      const index = findIndex(state.pdfComponents, { [payload.data.prop]: payload.data.id });
      if (index > -1)
        return { ...state, pdfComponents: [...state.pdfComponents.slice(0, index), { ...state.pdfComponents[index], ...payload.data.component }, ...state.pdfComponents.slice(index + 1)] };
      return { ...state };
    case actionTypes.HANDLE_REMOVE_COMPONENT_IN_ARRAY:
      const ind = findIndex(state.pdfComponents, { [payload.data.prop]: payload.data.id });
      if (ind > -1) {
        return { ...state, pdfComponents: [...state.pdfComponents.slice(0, ind), ...state.pdfComponents.slice(ind + 1)] };
      }
      return { ...state };
    case actionTypes.SELECT_ACTIVE_DRAWING:
      return { ...state, selectedDrawing: payload.data };
    case actionTypes.SET_PDF_PAGE_NUMBER:
      return { ...state, pdfPageNumber: payload.data };

    default:
      return state;
  }
};
