import React from 'react';
import { FEATURES } from '../../../common/constants';
import Icon from '../../../common/icon/components/icon';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../common/permissions-constants';

export const formConstants = {
  fields: {
    id: 'ID',
    name: 'Name',
    actionedAt: 'ActionedAt',
    actionedBy: 'ActionedBy',
    closedAt: 'ClosedAt',
    closedBy: 'ClosedBy',
    createdAt: 'CreatedAt',
    createdBy: 'CreatedBy',
    inspectionID: 'InspectionID',
    severity: 'Severity',
    source: 'Source',
    sourceName: 'SourceName',
    sourceID: 'SourceID',
    startedAt: 'StartedAt',
    status: 'Status',
    type: 'Type',
    updatedAt: 'UpdatedAt',
    startDate: 'StartDate',
    endDate: 'ExpectedDueDate',
    description: 'Description',
    dueDate: 'DueDate',
    statusComments: 'StatusComments',
    statusText: 'statusText',
    overdue: 'Overdue',
    dateLong: 'dateLong',
    assigneesNumber: 'AssigneesNumber',
    permitsNumber: 'PermitsNumber',
    componentsNumber: 'ComponentsNumber',
    checklistsNumber: 'ChecklistsNumber',
    filledChecklistsNumber: 'FilledChecklistsNumber',
    dateShort: 'dateShort',
    viewIcon: 'viewIcon',
    planned: 'SubType',
    hazard: 'hazard',
    hasWorkArea: 'HasWorkArea',
    nameLite: 'nameLite',
    externalWorkOrderID: 'ExternalWorkOrderID',
    additionalSubType: 'AdditionalSubType',
  },
};

export const notificationStatus = {
  closed: 'CLOSED',
  actioned: 'ACTIONED',
  open: 'OPEN',
};

export const statusColors = {
  severityGreen: {
    status: notificationStatus.closed,
    key: 'severityGreen',
    label: 'WORKORDER_STATUS_COLORS.GREEN',
    active: false,
    customComponent: (className = '', active = true, restProps) => <Icon name="checkmark" size="xs" active={active} className={`def-icon ${className || ''}`} handleHover={false} {...restProps} />,
  },
  severityYellow: {
    status: notificationStatus.actioned,
    key: 'severityOrange',
    label: 'WORKORDER_STATUS_COLORS.ORANGE',
    active: true,
    customComponent: (className = '', restProps) => <Icon name="exclamation-mark" size="xs" warning className={`def-icon ${className || ''}`} handleHover={false} {...restProps} />,
  },
  severityRed: {
    status: notificationStatus.open,
    key: 'severityRed',
    label: 'WORKORDER_STATUS_COLORS.RED',
    active: true,
    customComponent: (className = '', restProps) => <Icon name="exclamation-mark" size="xs" danger className={`def-icon ${className || ''}`} handleHover={false} {...restProps} />,
  },
};

export const severityConstants = {
  green: { keys: [0, -1], color: 'severityGreen' },
  yellow: { keys: [1, 2, 3], color: 'severityYellow' },
  orange: { keys: [4, 5, 6, 7], color: 'severityOrange' },
  red: { keys: [8, 9, 10], color: 'severityRed' },
};

export const filterFields = {
  searchText: 'SearchText',
  limit: 'Limit',
  includedIDs: 'IncludedIDs',
  lastSeen: 'LastSeen',
  sortBy: 'SortBy',
  sortDirection: 'SortDirection',
  statusFilter: 'StatusFilter',
  sourceFilter: 'SourceFilter',
  selectedNotificationIds: 'SelectedNotificationIDs',
  perPage: 'PerPage',
  totalItems: 'TotalNumber',
  hasNext: 'HasNext',
  severityFilter: 'SeverityFilter',
  componentTypeFilter: 'ComponentTypeFilter',
  typeFilter: 'TypeFilter',
  workOrderTypeFilter: 'WorkOrderTypeFilter',
  assignedToFilter: 'AssignedToFilter',
  createdByFilter: 'CreatedByFilter',
  componentIDsFilter: 'ComponentIDsFilter',
  startDateFrom: 'StartDateFrom',
  startDateTo: 'StartDateTo',
  overdueFilter: 'OverdueFilter',
  assignedToMeFilter: 'AssignedToLoggedUserFilter',
  startInNextWeekFilter: 'StartInNextWeekFilter',
  startInThirtyDaysFilter: 'StartInThirtyDaysFilter',
  archived: 'ArchivedFilter',
  onHold: 'OnHoldFilter',
  // filters used for regime WO
  startFrom: 'StartFrom',
  startTo: 'StartTo',
  endFrom: 'EndFrom',
  endTo: 'EndTo',
  dueFrom: 'DueFrom',
  dueTo: 'DueTo',
  sortByColumn: 'SortByColumn',
  regimeId: 'RegimeID',
  searchByExternalID: 'SearchByExternalID',
  properties: 'Properties',
  assignees: 'Assignees',
  collaborators: 'Collaborators',
  linkedToMe: 'LinkedToMe',
  componentsSearchFilter: 'ComponentSearchText',
  contractors: 'Contractors',
  hasObservationFilter: 'HasObservationFilter',
  hasChecklistFilter: 'HasChecklistFilter',
  checklistIDsFilter: 'ChecklistIDsFilter',
  additionalSubType: 'AdditionalSubType',
  additionalSubTypeFilter: 'AdditionalSubTypeFilter',
};

export const multiselectFields = [filterFields.statusFilter];

export const sortDirections = {
  asc: 'ASC',
  desc: 'DESC',
};

export const defaultPagingObj = {
  [filterFields.lastSeen]: 0,
  [filterFields.perPage]: 20,
  [filterFields.totalItems]: 0,
  [filterFields.hasNext]: false,
};

export const defaultSortingFilter = {
  [filterFields.sortByColumn]: formConstants.fields.startDate,
  [filterFields.sortDirection]: sortDirections.asc,
};

export const defaultFilters = {
  [filterFields.sortByColumn]: formConstants.fields.startDate,
  [filterFields.sortDirection]: sortDirections.asc,
  [filterFields.perPage]: 20,
  [filterFields.lastSeen]: 0,
  [filterFields.searchText]: '',
  [filterFields.hasNext]: false,
  [filterFields.status]: [],
};

export const defaultQuickFilters = {
  [filterFields.statusFilter]: [],
  [filterFields.onHold]: false,
  [filterFields.archived]: false,
  [filterFields.overdueFilter]: false,
  [filterFields.startInNextWeekFilter]: false,
  // set start in next 30 days by default
  [filterFields.startInThirtyDaysFilter]: true,
  [filterFields.searchByExternalID]: FEATURES.workorders.externalID.visible,
};

export const defaultQuickFilterRegimeTasks = {
  [filterFields.statusFilter]: '',
  [filterFields.onHold]: false,
  [filterFields.archived]: false,
  [filterFields.overdueFilter]: false,
  [filterFields.startInNextWeekFilter]: false,
  // set start in next 30 days by default
  [filterFields.startInThirtyDaysFilter]: true,
  [filterFields.searchByExternalID]: FEATURES.workorders.externalID.visible,
};

export const WOTypeFields = {
  value: 'status',
  name: 'label',
};

export const tableConfig = [
  {
    title: FEATURES.workorders.externalID.visible ? 'EXTERNAL_ID_SHORT' : 'WORK_ORDERS.ID',
    key: FEATURES.workorders.externalID.visible ? formConstants.fields.externalWorkOrderID : formConstants.fields.id,
    type: FEATURES.workorders.externalID.visible ? formConstants.fields.externalWorkOrderID : formConstants.fields.id,
    enableSort: true,
  },
  {
    title: 'WORK_ORDERS.NAME',
    key: formConstants.fields.name,
    enableSort: true,
    className: 'inline-left',
    type: formConstants.fields.nameLite,
  },
  {
    title: 'WORK_ORDERS.START_DATE',
    key: formConstants.fields.startDate,
    enableSort: true,
    type: formConstants.fields.dateShort,
    class: 'start-date',
  },
  {
    key: formConstants.fields.status,
    type: formConstants.fields.status,
    className: 'inline-right',
  },
  {
    key: '',
    type: formConstants.fields.viewIcon,
  },
];
export const tableConfigLite = [
  {
    title: FEATURES.workorders.externalID.visible ? 'EXTERNAL_ID_SHORT' : 'WORK_ORDERS.ID',
    key: FEATURES.workorders.externalID.visible ? formConstants.fields.externalWorkOrderID : formConstants.fields.id,
    type: FEATURES.workorders.externalID.visible ? formConstants.fields.externalWorkOrderID : formConstants.fields.id,
    enableSort: true,
  },
  {
    title: 'WORK_ORDERS.NAME',
    key: formConstants.fields.name,
    enableSort: true,
    className: 'inline-left',
    class: 'big',
    type: formConstants.fields.name,
  },
  {
    title: 'WORK_ORDERS.STATUS',
    enableSort: true,
    class: 'medium',
    key: formConstants.fields.status,
    type: formConstants.fields.statusText,
  },
  {
    title: 'WORK_ORDERS.DESCRIPTION',
    enableSort: true,
    class: 'medium',
    className: 'description-column',
    key: formConstants.fields.description,
    type: formConstants.fields.description,
  },
  {
    title: 'WORK_ORDERS.OVERDUE',
    enableSort: true,
    key: formConstants.fields.overdue,
    type: formConstants.fields.overdue,
  },
  {
    title: 'WORK_ORDERS.SEVERITY',
    key: formConstants.fields.severity,
    enableSort: true,
    type: formConstants.fields.severity,
    class: 'medium',
  },
  {
    title: 'WORK_ORDERS.DUE_DATE',
    key: formConstants.fields.endDate,
    enableSort: true,
    class: 'medium',
    type: formConstants.fields.dateLong,
  },
  {
    title: 'WORK_ORDERS.START_DATE',
    key: formConstants.fields.startDate,
    enableSort: true,
    class: 'medium',
    type: formConstants.fields.dateLong,
  },
  {
    title: 'WORK_ORDERS.END_DATE',
    key: formConstants.fields.dueDate,
    enableSort: true,
    class: 'medium',
    type: formConstants.fields.dateLong,
  },
  {
    title: 'WORK_ORDERS.TYPE',
    key: formConstants.fields.type,
    enableSort: true,
    type: formConstants.fields.type,
  },
  {
    title: 'WORK_ORDERS.SUB_TYPE',
    key: formConstants.fields.additionalSubType,
    enableSort: true,
    class: 'medium',
    type: formConstants.fields.additionalSubType,
  },
  {
    title: 'WORK_ORDERS.PLANNED',
    key: formConstants.fields.planned,
    enableSort: true,
    type: formConstants.fields.planned,
  },
];
export const tableConfigRegime = [
  {
    title: '',
    enableSort: false,
    class: 'medium',
    key: formConstants.fields.status,
    type: formConstants.fields.statusText,
  },
  {
    title: 'WORK_ORDERS.NAME',
    key: formConstants.fields.name,
    enableSort: true,
    className: 'inline-left',
    type: formConstants.fields.nameLite,
  },
  {
    title: 'WORK_ORDERS.DUE_DATE',
    key: formConstants.fields.dueDate,
    enableSort: true,
    type: formConstants.fields.dateShort,
    class: 'due-date',
  },
];

export const workAreaConstants = {
  fields: {
    id: 'ID',
    name: 'Name',
    color: 'Colour',
    camPosition: 'CameraPosition.coordinates',
    points: 'Geometry.coordinates',
    camPos: 'CameraPosition',
  },
};

export const statuses = {
  draft: {
    value: 'DRAFT',
    title: 'WORK_ORDERS.DRAFT',
    icon: 'clock',
    goToStatuses: ['RAISED'],
    isRegularStatus: true,
    modalTitle: 'ISOLATION_CERTIFICATE_STATUS.DRAFT_TITLE',
    editPermission: PERMISSIONS[PERMISSION_TYPES.workOrders].edit.name,
    ownershipPermission: PERMISSIONS[PERMISSION_TYPES.workOrders].create.name,
    skipPermissionCheck: true,
  },
  raised: {
    value: 'RAISED',
    title: 'WORK_ORDERS.RAISED',
    icon: 'clock-filled',
    editPermission: PERMISSIONS[PERMISSION_TYPES.workOrders].edit.name,
    transitionPermission: PERMISSIONS[PERMISSION_TYPES.workOrders].edit.name,
    ownershipPermission: PERMISSIONS[PERMISSION_TYPES.workOrders].create.name,
    goToStatuses: ['APPROVED'],
    isRegularStatus: true,
  },
  approved: {
    value: 'APPROVED',
    title: 'WORK_ORDERS.APPROVED',
    icon: 'checkmark-rounded',
    iconProps: {
      active: true,
    },
    iconFilledWhenActive: true,
    editPermission: PERMISSIONS[PERMISSION_TYPES.workOrders].approveEdit.name,
    transitionPermission: PERMISSIONS[PERMISSION_TYPES.workOrders].toApprove.name,
    goToStatuses: ['AUTHORISED', 'COMPLETED', 'CLOSED'],
    isRegularStatus: true,
  },
  rejected: {
    value: 'REJECTED',
    title: 'WORK_ORDERS.REJECTED',
    icon: 'alert-octagon',
    editPermission: PERMISSIONS[PERMISSION_TYPES.workOrders].edit.name,
    transitionPermission: PERMISSIONS[PERMISSION_TYPES.workOrders].edit.name,
    ownershipPermission: PERMISSIONS[PERMISSION_TYPES.workOrders].create.name,
    fromStatuses: ['RAISED', 'APPROVED', 'AUTHORISED'],
    isRegularStatus: true,
    goToStatuses: ['DRAFT'],
  },
  authorised: {
    value: 'AUTHORISED',
    title: 'WORK_ORDERS.AUTHORISED',
    icon: 'check-rounded',
    iconProps: {
      active: true,
    },
    editPermission: PERMISSIONS[PERMISSION_TYPES.workOrders].authoriseEdit.name,
    transitionPermission: PERMISSIONS[PERMISSION_TYPES.workOrders].toAuthorise.name,
    goToStatuses: ['IN-PROGRESS', 'COMPLETED', 'CLOSED'],
    isRegularStatus: true,
  },
  inProgress: {
    value: 'IN-PROGRESS',
    title: 'WORK_ORDERS.IN_PROGRESS',
    icon: 'in-progress',
    iconProps: {
      active: true,
    },
    iconFilledWhenActive: true,
    editPermission: PERMISSIONS[PERMISSION_TYPES.workOrders].inProgressEdit.name,
    transitionPermission: PERMISSIONS[PERMISSION_TYPES.workOrders].toInProgress.name,
    goToStatuses: ['COMPLETED', 'CLOSED'],
    isRegularStatus: true,
  },
  completed: {
    value: 'COMPLETED',
    title: 'WORK_ORDERS.COMPLETED',
    icon: 'checkmark',
    iconProps: {
      active: true,
    },
    transitionPermission: PERMISSIONS[PERMISSION_TYPES.workOrders].toComplete.name,
    isFinalStatus: true,
  },
  closed: {
    value: 'CLOSED',
    title: 'WORK_ORDERS.CLOSED',
    icon: 'close-filled',
    iconProps: {
      active: true,
    },
    transitionPermission: PERMISSIONS[PERMISSION_TYPES.workOrders].toClose.name,
    isRegularStatus: true,
    isFinalStatus: true,
  },
  startInNextWeek: {
    value: filterFields.startInNextWeekFilter,
    title: 'WORK_ORDERS.START_IN_NEXT_WEEK',
    isSingleSelect: true,
    includeInStatuses: true,
  },
  startInNextMonth: {
    value: filterFields.startInThirtyDaysFilter,
    title: 'WORK_ORDERS.START_IN_NEXT_MONTH',
    isSingleSelect: true,
    includeInStatuses: true,
  },
  onHold: {
    value: filterFields.onHold,
    title: 'WORK_ORDERS.ON_HOLD',
    isSingleSelect: true,
    includeInStatuses: true,
  },
  archived: {
    value: filterFields.archived,
    title: 'WORK_ORDERS.ARCHIVED',
    isSingleSelect: true,
    includeInStatuses: true,
  },
  overdueFilter: {
    value: filterFields.overdueFilter,
    title: 'WORKORDER_FILTERS.OVERDUE',
    isSingleSelect: true,
    includeInStatuses: true,
  },
  linkedToMe: {
    value: filterFields.linkedToMe,
    title: 'WORKORDER_FILTERS.LINKED_TO_ME',
    isSingleSelect: true,
    includeInStatuses: true,
  },
};

export const priorities = {
  none: {
    value: 1,
    title: 'WORK_ORDERS.NONE_PRIORITY',
  },
  lowLow: {
    value: 2,
    title: 'WORK_ORDERS.LOW_LOW_PRIORITY',
    color: 'priorityLowLow',
  },
  low: {
    value: 4,
    title: 'WORK_ORDERS.LOW_PRIORITY',
    color: 'priorityLow',
  },
  medium: {
    value: 6,
    title: 'WORK_ORDERS.MEDIUM_PRIORITY',
    color: 'priorityMedium',
  },
  high: {
    value: 8,
    title: 'WORK_ORDERS.HIGH_PRIORITY',
    color: 'priorityHigh',
  },
  critical: {
    value: 10,
    title: 'WORK_ORDERS.CRITICAL_PRIORITY',
    color: 'priorityCritical',
  },
};

export const pdfDownloadOptions = {
  includeChecklists: {
    name: 'include_checklists',
    includeHandler: 'includeChecklists',
  },
};
