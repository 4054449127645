const formConstants = {
  fields: {
    name: 'Name',
    id: 'Name',
    count: 'EquipmentCounter',
    children: 'ChildNodes',
    objectType: 'ObjectType',
  },
};

const objectTypes = {
  location: 'FL',
  equipment: 'EQU',
};

export default {
  formConstants,
  objectTypes,
};
