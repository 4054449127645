import { uniqBy } from 'lodash';

import isolationCertificatesApi from '../../../api/isolation-certificates/actions';
import { setIsolationCertificates, setIsolationCertificatesFilters } from './action-creators';
import { filterProps, fields } from '../components/isolation-certificates/constants/isolation-certificates-table';

export const getIsolationCertificates = (filters, isolationCertificates, loadMore = false, callback, advancedFilters = {}) => {
  return async dispatch => {
    try {
      dispatch(setIsolationCertificates(true));
      const res = await isolationCertificatesApi.getIsolationCertificates({ ...filters, ...advancedFilters });
      const data = res?.data?.Data;

      if (data?.IsolationCertificates) {
        let certificates = loadMore ? [...isolationCertificates, ...data.IsolationCertificates] : data.IsolationCertificates;
        certificates = uniqBy(certificates, fields.id);

        dispatch(setIsolationCertificates(false, certificates));

        const newFilters = {
          ...filters,
          [filterProps.hasNext]: data[filterProps.hasNext] || false,
          [filterProps.lastSeen]: data[filterProps.lastSeen],
          [filterProps.totalItems]: data[filterProps.totalItems],
        };

        dispatch(setIsolationCertificatesFilters(newFilters));

        if (typeof callback === 'function') {
          callback(certificates.length);
        }
      } else {
        dispatch(setIsolationCertificates(false));
      }
    } catch (e) {}
  };
};
