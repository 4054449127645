import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { updateSyncErrors, submit } from 'redux-form';
import { connect } from 'react-redux';
import { map, debounce, findIndex, isEmpty } from 'lodash';

import Stepper from '../../../common/stepper/components/stepper';
import CreateKeyboxForm from './create-keybox-form';
import Helpers from '../../../common/helpers';
import { stepperData, steps, fields, keyFields } from '../constants/constants';
import { FORMS } from '../../../common/constants';

import { validateFirstStep, validateSecondStep } from '../validatiors/create-keybox-validator';

import '../styles/create-keybox.scss';

class CreateKeybox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: steps.firstStep,
      keys: props?.initialValues?.Keys || [],
      keyBox: props?.initialValues || {},
      updateInProgress: false,
    };
    this.handleUpdateQuestionOptionDebounced = debounce(this.handleUpdateQuestionOption, 500);
  }

  handleSubmit = async values => {
    const { step, keys, keyBox } = this.state;
    const { createAction, isEdit, editDetails } = this.props;
    if (!isEdit) {
      if (step === steps.firstStep) {
        this.setState({ step: steps.secondStep, keyBox: { ...keyBox, ...values } });
      } else if (step === steps.secondStep) {
        const data = {
          ...keyBox,
          [fields.keys]: map(keys, item => {
            return item[keyFields.name];
          }),
        };
        createAction(data);
      }
    } else {
      if (step === steps.firstStep) {
        editDetails(values);
        this.setState({ step: steps.secondStep, keyBox: { ...keyBox, ...values } });
      } else if (step === steps.secondStep) {
        this.setState({ updateInProgress: true });
        const { closeAction } = this.props;
        await this.updateAllKeys();
        this.setState({ updateInProgress: false });
        closeAction();
      }
    }
  };

  updateAllKeys = () => {
    const { keys } = this.state;
    const { updateKeyName } = this.props;

    for (let i = 0; i < keys.length; i++) {
      if (keys[i].isUpdated) {
        updateKeyName(keys[i]);
      }
    }
  };

  getKeyName = () => {
    const { keys } = this.state;

    if (!keys.length) {
      return 'New key';
    }

    let name = `${keys[keys.length - 1][keyFields.name]}-1`;
    let existing = findIndex(keys, { [keyFields.name]: name });

    while (existing > -1) {
      name = `${name}-1`;
      existing = findIndex(keys, { [keyFields.name]: name });
    }

    return name;
  };

  validateKeys = () => {
    const { keys } = this.state;
    const { updateSyncErrors, submitForm } = this.props;
    const errors = {};

    for (let i = 0; i < keys.length; i++) {
      const existing = findIndex(keys, key => {
        return key[keyFields.name] === keys[i][keyFields.name] && key[keyFields.id] !== keys[i][keyFields.id];
      });

      if (existing > -1) {
        errors[`Keys-Name-${keys[i][keyFields.id]}`] = 'KEYBOX_FIELDS.NAME_UNIQ';
      }
    }

    if (!isEmpty(errors)) {
      updateSyncErrors(FORMS.addKeyboxKeys, errors);
      submitForm(FORMS.addKeyboxKeys);
    }
  };

  handleAddQuestionOption = () => {
    const { keys } = this.state;
    const { isEdit, addKey, keyboxes, updateKeyBoxes, initialValues } = this.props;

    const key = {
      [keyFields.name]: this.getKeyName(),
    };
    if (!isEdit) {
      const keyBoxKeys = Object.assign([], keys);
      keyBoxKeys.push({ ...key, ID: Helpers.uuid4() });
      this.setState({ keys: keyBoxKeys });
    } else {
      addKey(key, incomingKey => {
        const keyBoxKeys = Object.assign([], keys);
        keyBoxKeys.push({ ...key, ID: incomingKey[fields.id] });
        this.setState({ keys: keyBoxKeys });

        if (keyboxes && typeof updateKeyBoxes === 'function') {
          const index = findIndex(keyboxes, { [fields.id]: initialValues[fields.id] });

          if (index > -1) {
            const keyBox = Object.assign({}, keyboxes[index]);
            keyBox[fields.freeKeys] += 1;

            keyboxes.splice(index, 1, keyBox);
            updateKeyBoxes(keyboxes);
          }
        }
      });
    }
  };

  handleDeleteQuestionOption = (index, key) => {
    const { keys } = this.state;
    const { isEdit, deleteKey, keyboxes, updateKeyBoxes, initialValues } = this.props;

    if (!isEdit) {
      const keyBoxKeys = Object.assign([], keys);
      keyBoxKeys.splice(index, 1);
      this.setState({ keys: keyBoxKeys });
    } else {
      deleteKey(key[fields.id], () => {
        keys.splice(index, 1);

        if (keyboxes && typeof updateKeyBoxes === 'function') {
          const index = findIndex(keyboxes, { [fields.id]: initialValues[fields.id] });

          if (index > -1) {
            const keyBox = Object.assign({}, keyboxes[index]);

            if (key.IssuedToID) {
              keyBox[fields.issuedkeys] -= 1;
            } else {
              keyBox[fields.freeKeys] -= 1;
            }

            keyboxes.splice(index, 1, keyBox);
            updateKeyBoxes(keyboxes);
          }
        }

        this.validateKeys();
      });
    }
  };

  updateKey = (value, index) => {
    const { isEdit } = this.props;
    const keyBoxKeys = Object.assign([], this.state.keys);
    let key = Object.assign({}, keyBoxKeys[index]);

    if (key) {
      key = value;
      key.isUpdated = isEdit;
      keyBoxKeys.splice(index, 1, key);

      this.setState({ keys: keyBoxKeys });
    }
  };

  handleUpdateQuestionOption = (value, index) => {
    const { keys } = this.state;

    const keyBoxKeys = Object.assign([], keys);
    keyBoxKeys.splice(index, 1);
    const existing = findIndex(keyBoxKeys, { [keyFields.name]: value[keyFields.name] });

    if (existing > -1) {
      const { updateSyncErrors } = this.props;
      const error = { _error: 'Key name has to be uniq' };
      error[`Keys-Name-${value[keyFields.id]}`] = 'KEYBOX_FIELDS.NAME_UNIQ';

      updateSyncErrors(FORMS.addKeyboxKeys, error);
      return;
    }

    this.updateKey(value, index);
  };

  getFormInitialValues = () => {
    const { step, keyBox } = this.state;

    if (step === steps.firstStep) {
      return keyBox || {};
    } else {
      const { keys } = this.state;
      const secondStepInitialValues = {};

      for (let i = 0; i < keys.length; i++) {
        secondStepInitialValues[`${fields.keys}-${keyFields.name}-${keys[i][keyFields.id]}`] = keys[i][keyFields.name];
      }

      return secondStepInitialValues;
    }
  };

  render() {
    const { closeAction, isEdit } = this.props;
    const { step, keys, updateInProgress } = this.state;

    return (
      <div className="create-keybox">
        <Stepper stepperData={stepperData} activeStep={step} />
        <CreateKeyboxForm
          validate={step === steps.firstStep ? validateFirstStep : validateSecondStep}
          onSubmit={this.handleSubmit}
          onChangeKeyName={() => {
            // changeKeyName
          }}
          step={step}
          handleAddQuestionOption={this.handleAddQuestionOption}
          handleUpdateQuestionOption={this.handleUpdateQuestionOptionDebounced}
          handleDeleteQuestionOption={this.handleDeleteQuestionOption}
          backAction={() => this.setState({ step: step - 1 })}
          closeAction={closeAction}
          initialValues={this.getFormInitialValues()}
          isEdit={isEdit}
          options={keys}
          disabled={updateInProgress}
        />
      </div>
    );
  }
}

CreateKeybox.contextTypes = {
  t: PropTypes.func.isRequired,
};

CreateKeybox.propTypes = {};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  updateSyncErrors: (formName, error) => dispatch(updateSyncErrors(formName, error)),
  submitForm: formName => dispatch(submit(formName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateKeybox);
