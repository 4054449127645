import { findIndex } from 'lodash';

class Helpers {
  static addItemToIndex = (list, item, index) => {
    return [...list.slice(0, index), item, ...list.slice(index)];
  };
  static updateItemInListByProp = (list, item, prop) => {
    const foundIndex = findIndex(list, it => it[prop] === item[prop]);
    if (foundIndex === -1) {
      return list;
    }

    return [...list.slice(0, foundIndex), { ...list[foundIndex], ...item }, ...list.slice(foundIndex + 1)];
  };

  static removeItemByProp = (list, item, prop) => {
    const foundIndex = findIndex(list, it => it[prop] === item[prop]);
    if (foundIndex === -1) {
      return list;
    }

    return [...list.slice(0, foundIndex), ...list.slice(foundIndex + 1)];
  };
  static incrementItemPropInListByProp = (list, item, prop, field, value) => {
    const foundIndex = findIndex(list, it => it[prop] === item[prop]);

    if (foundIndex === -1) {
      return list;
    }

    const itemToSave = (list[foundIndex] = {
      ...list[foundIndex],
      [field]: (list[foundIndex][field] || 0) + value,
    });

    return [...list.slice(0, foundIndex), { ...list[foundIndex], ...itemToSave }, ...list.slice(foundIndex + 1)];
  };
}

export default Helpers;
