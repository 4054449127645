import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';

import ProjectDetails from './project-details';
import CustomMarker from './custom-marker';

import { GOOGLE_API_KEY } from '../../../common/constants';
import { mapSettings } from '../constants/map-settings';

import '../styles/map.scss';

class ProjectMap extends Component {
  renderMarkers = () => {
    const { selectProject, handleMarkerClick, data } = this.props;

    return data.map(project => (
      <CustomMarker
        onMouseEnter={() => selectProject(project.ID)}
        onClick={() => handleMarkerClick(project)}
        key={project.ID}
        lat={project.Location.Latitude}
        lng={project.Location.Longitude}
        selected={project.selected}
      />
    ));
  };
  getMapOptions = maps => {
    const { styles } = this.props;

    return {
      styles,
      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.ROADMAP,
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.DEFAULT,
        position: maps.ControlPosition.TOP_RIGHT,
        mapTypeIds: [maps.MapTypeId.ROADMAP, maps.MapTypeId.SATELLITE, maps.MapTypeId.HYBRID, maps.MapTypeId.TERRAIN],
      },
    };
  };

  render() {
    const { selectedProject } = this.props;
    const markers = this.renderMarkers();

    return (
      <div className="google-map" data-cy="projects-map">
        <GoogleMapReact
          bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
          defaultZoom={2}
          defaultCenter={{ lat: mapSettings.defaultLocation.lat, lng: mapSettings.defaultLocation.lng }}
          options={this.getMapOptions}
          yesIWantToUseGoogleMapApiInternals
        >
          {markers}
        </GoogleMapReact>
        <ProjectDetails project={selectedProject} />
      </div>
    );
  }
}

ProjectMap.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ProjectMap;
