import React, { useCallback, useEffect, useState } from 'react';
//styles
import '../../styles/change-measurement-group-modal.scss';
//components
import TableTemplate from '../../../../../../common/double-table/components/table-template';
import Modal from '../../../../../../common/modal/components/modal';
//constants
import { FORMS } from '../../../../../../common/constants';
import { changeGroupTableConfig, fields, getTableFormat, manageGroupsDefaultFilters, sortDirections, viewOptions } from '../../constants/constants';
//package imports
import { debounce, uniqBy } from 'lodash';
import { connect } from 'react-redux';
import { isDirty } from 'redux-form';
//prop types
import PropTypes from 'prop-types';
// actions
import { setGenericNotification } from '../../../../../../common/notification/actions/action-creators';
import { createGroup, deleteGroup, editGroup, fetchGroups } from '../../actions/measurement-group-actions';
//helpers
import EmptyState from '../../../../../../common/empty-state-v2/components/empty-state';
import Helpers from '../../../../../../common/helpers';
import ActionModal from '../../../../../document-management/components/modals/action-modal/action-modal';

const ChangeMeasurementGroupModal = (props, { t }) => {
  const { projectID, fetchGroups, activeGroupId, handleChangeMeasurementGroup, measurementLocation } = props;

  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({ ...manageGroupsDefaultFilters, [fields.projectID]: projectID });
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [confirmModalData, setConfirmModalData] = useState({ isOpen: false });

  const fetchData = useCallback(
    (filters, loadMore = false) => {
      const setNewData = newData => {
        // filter out the groups by id, so there arent any duplicates
        const dataToSet = loadMore ? uniqBy([...data, ...newData], fields.id) : newData;
        setData(dataToSet);
      };
      const setDataLoaded = isLoading => {
        setIsDataLoading(isLoading);
        if (loadMore && !isLoading) {
          Helpers.scrollIntoView('groups-table', `row-${filters[fields.lastSeen] - 1}`, -250);
        }
      };
      fetchGroups(filters, setNewData, setFilters, null, setDataLoaded);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchTextChanged = useCallback(
    debounce(searchText => fetchData({ ...filters, [fields.lastSeen]: 0, [fields.searchText]: searchText }), 300),
    [fetchData]
  );

  const handleSearchInputChange = e => {
    const searchText = e.target.value;
    setSearchText(searchText);
    searchTextChanged(searchText);
  };

  const handleLoadMoreClick = () => fetchData(filters, true);

  const handleSortClick = sortByColumn => {
    fetchData({
      ...filters,
      [fields.lastSeen]: 0,
      [fields.sortDirection]: filters[fields.sortDirection] === sortDirections.ASC ? sortDirections.DESC : sortDirections.ASC,
      [fields.sortByColumn]: sortByColumn,
    });
  };

  const closeConfirmModal = () => setConfirmModalData({ isOpen: false });

  const openConfirmModal = group => {
    setConfirmModalData({
      isOpen: true,
      closeAction: closeConfirmModal,
      type: '',
      CustomContent: dynamicProps => <ActionModal {...dynamicProps} />,
      title: t('READINGS_AND_GAUGES.CHANGE_MEASUREMENT_GROUP.CONFIRM_MODAL.TITLE'),
      confirmButtonText: 'READINGS_AND_GAUGES.CHANGE_MEASUREMENT_GROUP.CONFIRM_MODAL.CONFIRM_BTN',
      confirmButtonProps: { variant: 'success' },
      firstParagraph: t('READINGS_AND_GAUGES.CHANGE_MEASUREMENT_GROUP.CONFIRM_MODAL.DESC', { groupName: t(group[fields.name]), mlName: measurementLocation[fields.name] }),
      customCloseAction: closeConfirmModal,
      customConfirmAction: () => {
        handleChangeMeasurementGroup(group, closeConfirmModal);
      },
    });
  };

  const emptyStateProps = {
    emptyStateText: t('READINGS_AND_GAUGES.GROUP_EMPTY_STATE.TEXT'),
    transparent: true,
  };

  useEffect(() => {
    fetchData(filters);
    //eslint-disable-next-line
  }, []);

  return (
    <div className="change-measurement-group-modal">
      <TableTemplate
        customTableClass="groups-table"
        data={(data || []).map(group => ({ ...group, [fields.isActive]: group[fields.id] === activeGroupId ? true : false }))}
        handleFormatCells={getTableFormat[viewOptions.group]}
        tableConfig={changeGroupTableConfig}
        filters={filters}
        sortByColumn={fields.sortByColumn}
        sortDirection={fields.sortDirection}
        searchPlaceholder={t('SEARCH')}
        loadMoreOnClick={handleLoadMoreClick}
        onSortClick={handleSortClick}
        searchInputOnChange={handleSearchInputChange}
        isLoading={isDataLoading}
        searchTerm={searchText}
        hasNext={filters[fields.hasNext]}
        TotalItems={filters[fields.totalNumber]}
        addButtonText={t('READINGS_AND_GAUGES.NEW_GROUP')}
        onRowClick={() => null}
        onDropdownClick={(e, row) => {
          openConfirmModal(row);
        }}
        emptyStateComponent={() => <EmptyState {...emptyStateProps} />}
      />
      {/* Close Action is being set this way due to need to update isEditFormDirty prop for the case
      when user closes the editing group modal with unsaved changes */}
      <Modal {...confirmModalData} />
    </div>
  );
};

ChangeMeasurementGroupModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isEditFormDirty: isDirty(FORMS.groupForm)(state),
  defaultReadingGroupID: state.projectDetailsReducer.DefaultReadingGroupID,
});

const mapDispatchToProps = dispatch => ({
  fetchGroups: (filters, callback, filtersCallback, errorCallback, loadingCallback) => dispatch(fetchGroups(filters, callback, filtersCallback, errorCallback, loadingCallback)),
  createGroup: (name, projectID, inspectionId, callback) => dispatch(createGroup(name, projectID, inspectionId, callback)),
  editGroup: (name, ID, projectID, callback) => dispatch(editGroup(name, ID, projectID, callback)),
  deleteGroup: (id, movePointsToGroupID, password, deleteAll, callback) => dispatch(deleteGroup(id, movePointsToGroupID, password, deleteAll, callback)),
  setGenericNotification: data => dispatch(setGenericNotification(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeMeasurementGroupModal);
