import React from 'react';
import PropTypes from 'prop-types';
import { findIndex, values } from 'lodash';

import Checkbox from '../../../../common/form/components/checkbox';
import DisplayStatus from '../../../../common/display-status/components/display-status';
import Icon from '../../../../common/icon/components/icon';
import InfoTooltip from '../../../../common/tooltip/components/info-tooltip';
import SearchInput from '../../../../common/input/components/search-input';
import Helpers from '../../../../common/helpers';
import { assigneeFields, permitDetailsDisplayProps } from './constants/constants';
import { statuses } from '../../components/permits/constants/permit-constants';
import AddButton from '../../../../common/add-button/components/add-button';
import '../../styles/add-assignees.scss';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../common/permissions-constants';
import AccessRenderer from '../../../../common/access-renderer/components/access-renderer';
import { userFields } from '../../../members/constants/team-constants';

const AddPermits = ({ permits, workorderPermits, handleSearchPermits, addPermitToWorkorder, removePermitFromWorkorder, openCreateWorkOrderPermitModal, user, closeConfirmModal }, { t }) => {
  const handleAssignPermit = (item, isSelected) => {
    if (isSelected) {
      removePermitFromWorkorder(item);
    } else {
      addPermitToWorkorder(item);
    }
  };

  return (
    <div className="add-work-order-permits">
      <div className="input-with-dropdown">
        <div className="d-flex align-items-center justify-content-between">
          <label className="search-input-label">{t('CHOOSE_WORK_ORDER_NOTIFICATION.ADD_PERMITS_LABEL')}</label>
          <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.permits].create.name} id={user[userFields.userId]} ownerRequiredPermission={PERMISSIONS[PERMISSION_TYPES.permits].create.name}>
            {({ hasAccess }) => {
              return (
                <AddButton disabled={!hasAccess} onClick={() => openCreateWorkOrderPermitModal()}>
                  {'WORK_ORDER.NEW_PERMIT'}
                </AddButton>
              );
            }}
          </AccessRenderer>
        </div>
        <SearchInput
          onChange={e => handleSearchPermits(e.target.value)}
          placeholder={t('PROJECT.SEARCH.PLACEHOLDER')}
          includeDropdown={true}
          items={permits || []}
          emptyStateLabel={'CREATE_WORKORDER_FORM.PERMITS_EMPTY_STATE'}
          renderItem={(item, index) => {
            const isSelected = findIndex(workorderPermits, { [assigneeFields.id]: item[assigneeFields.id] }) > -1;

            return (
              <div
                className={`dropdown-render-item-container ${isSelected ? 'selected' : ''}`}
                key={`work-order-permit-${item[assigneeFields.id]}`}
                onClick={() => handleAssignPermit(item, isSelected)}
              >
                <div className="user-checkbox-wrapper">
                  {index === 0 && <p className="f-secondary-dark user-title bold">{t('WORK_ORDER.PERMIT_NAME')}</p>}
                  <div>
                    <Checkbox meta={{}} input={{ value: isSelected }} />
                  </div>
                  <p className="f-primary light-bold module-name">{item[assigneeFields.name]}</p>
                </div>
                <div className="user-email-wrapper">
                  {index === 0 && <p className="f-secondary-dark user-title bold">{t('WORK_ORDER.PERMIT_STATUS')}</p>}
                  <DisplayStatus statuses={values(statuses)} status={item[assigneeFields.status]} />
                </div>
              </div>
            );
          }}
        />
      </div>
      <div className="added-items">
        <p className="f-secondary-dark bold">{t('CREATE_WORKORDER_FORM.ADDED_COMPONENTS', { number: workorderPermits ? workorderPermits.length : 0 })}</p>
        <div className="added-items-list">
          {workorderPermits &&
            workorderPermits.map((item, index) => {
              return (
                <div className="item-r" key={`work-order-permits-assigned-${item[assigneeFields.id]}`}>
                  <p className="f-primary c-title">{`${index + 1}. ${item[assigneeFields.name]}`}</p>
                  <InfoTooltip
                    actionsMenu={Helpers.mapInfoIconDisplayProps(item, permitDetailsDisplayProps)}
                    offsetY={8}
                    offsetX={8}
                    Component={() => <Icon name="info" size="sm" />}
                    componentProps={{}}
                  />
                  <Icon name="close" className="x-hover pointer" onClick={() => removePermitFromWorkorder(item, closeConfirmModal)} />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

AddPermits.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default AddPermits;
