import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ResizeBox from './resize-box';
import ReactResizeDetector from 'react-resize-detector';

import '../styles/split-screen.scss';

const mainDivClass = 'split-screen-resizable';

class SplitScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultWidth: props.defaultWidth || 300,
      defaultHeight: props.defaultHeight || 300,
      style: props.direction === 'w' ? { gridTemplateColumns: '50% 50%' } : { gridTemplateRows: '50% 50%' },
      width: props.width || 300,
      height: props.height || 0,
    };
  }

  componentDidMount() {
    this.setBoundaries();
  }

  componentDidUpdate(prevProps) {
    const { direction, isActive } = this.props;
    if (direction !== prevProps.direction || isActive !== prevProps.isActive) {
      this.setState({ style: direction === 'w' ? { gridTemplateColumns: '50% 50%' } : { gridTemplateRows: '50% 50%' } }, () => {
        this.setBoundaries();
      });
    }
  }

  onResize = ({ width, height }) => {
    const { direction } = this.props;
    const { defaultWidth, defaultHeight } = this.state;

    const minWidth = defaultWidth / 2;

    if (width < minWidth) {
      width = minWidth;
    }

    const minHeight = defaultHeight / 2;

    if (height < minHeight) {
      height = minHeight;
    }

    if (direction === 'w') {
      this.setState({ width });
    } else {
      this.setState({ height });
    }

    this.setState({ style: direction === 'w' ? { gridTemplateColumns: `auto ${width}px` } : { gridTemplateRows: `auto ${height}px` } });
  };

  setBoundaries = () => {
    const { direction } = this.props;
    let el = document.getElementsByClassName(mainDivClass)[0];
    el = el && el.getBoundingClientRect();

    if (el && !isNaN(el.width) && direction === 'w') {
      this.setState({ width: Math.floor(el.width) });
      this.setState({ defaultWidth: Math.floor(el.width) });
      this.setState({ style: { gridTemplateColumns: `auto ${el.width}px` } });
    } else if (el && !isNaN(el.height) && direction !== 'w') {
      this.setState({ height: Math.floor(el.height) });
      this.setState({ defaultHeight: Math.floor(el.height) });
      this.setState({ style: { gridTemplateRows: `auto ${el.height}px` } });
    }
  };

  // onScreenResize = () => {
  //   const { direction } = this.props;

  //   this.setState({ style: direction === 'w' ? { gridTemplateColumns: `50% 50%` } : { gridTemplateRows: `50% 50%` } });

  //   // setTimeout(() => {
  //   //   this.setBoundaries();
  //   // }, 300);
  // };

  renderScreens = () => {
    const { screenRenderers, direction, isActive } = this.props;
    const { width, height } = this.state;

    return (screenRenderers || []).map((screenRenderer, index) => {
      if (index === 0) {
        return screenRenderer();
      } else {
        if (!screenRenderer()) {
          return (
            <ResizeBox isHidden={!isActive} {...{ width, height }} direction={direction} onResize={this.onResize} className={mainDivClass} key={index}>
              <></>
            </ResizeBox>
          );
        }
        return (
          <ResizeBox isHidden={!isActive} {...{ width, height }} direction={direction} onResize={this.onResize} className={mainDivClass} key={index}>
            {screenRenderer()}
          </ResizeBox>
        );
      }
    });
  };

  render() {
    const { direction } = this.props;
    const { style } = this.state;

    return (
      <ReactResizeDetector handleWidth handleHeight skipOnMount onResize={this.onScreenResize}>
        <div className={`split-screen ${direction}`} style={style}>
          {this.renderScreens()}
        </div>
      </ReactResizeDetector>
    );
  }
}

SplitScreen.defaultProps = {
  direction: 'w',
  isActive: false,
};

SplitScreen.propTypes = {
  direction: PropTypes.oneOf(['w', 'n']),
};

export default SplitScreen;
