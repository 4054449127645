export default {
  SET_UPLOAD_TMP_FILES: 'setUploadTmpFiles',
  SET_SINGLE_UPLOADITEM_PROGRESS: 'setSingleUploadItemProgress',
  SET_EXTERNAL_UPLOADED_FILES: 'setExternalUploadedFiles',
  SET_ITEM_TO_CANCEL: 'setItemToCancel',
  REMOVE_ITEM_TO_CANCEL: 'removeItemToCancel',
  SET_COMPONENT_FILES_UPLOADED: 'setComponentFilesUploaded',
  SET_DEFECT_FILES_UPLOADED: 'setDefectFilesUploaded',
  SET_NOTIFICATION_FILES_UPLOADED: 'setNotificationFilesUploaded',
  SET_DEFECT_FILES_UPLOADED_LOADING: 'setDefectFilesUploadedLoading',
  SET_MEASUREMENT_FILES_UPLOADED: 'setMeasurementFilesUploaded',
  SET_MEASUREMENT_FILES_UPLOADED_LOADING: 'setMeasurementFilesUploadedLoading',
  SET_NDT_MEASUREMENT_FILES_UPLOADED: 'setNDTMeasurementFilesUploaded',
  SET_NDT_MEASUREMENT_POINT_FILES_UPLOADED: 'setNDTMeasurementPointFilesUploaded',
  SET_WORKORDER_FILES_UPLOADED: 'setWorkorderFilesUploaded',
  AMEND_WORKORDER_FILES_UPLOADED: 'amendWorkorderFilesUploaded',
  REMOVE_WORKORDER_FILE_BY_ID: 'removeWorkorderFileUploadedByID',
  //Batches related actions
  SET_UPLOAD_BATCH_FILES: 'setUploadBatchFiles',
  UPDATE_UPLOAD_BATCH_FILE: 'updateUploadBatchFile',
  DO_BATCH_CLEAN_UP: 'doBatchCleanUp',
  SET_BATCH_FILE_FAILED: 'setBatchFileFailed',
  SET_CHECKLIST_QUESTION_FILES_UPLOADED: 'setChecklistQuestionFilesUploaded',
  AMEND_CHECKLIST_QUESTION_FILES_UPLOADED: 'amendChecklistQuestionFilesUploaded',
  REMOVE_CHECKLIST_QUESTION_FILE_BY_PROP: 'removeChecklistQuestionFileByProp',
};
