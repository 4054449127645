export default {
  GET_PDF_LIST: '/inspection/tagged/documents/list/lite',
  GET_PDF_COMPONENTS: '/inspection/component/tagged/list',
  SAVE_COMPONENT_TAG: '/inspection/component/tag/save',
  CONFIRM_TAG: '/inspection/component/tag/confirm',
  GET_COMPONENT_DRAWINGS: '/inspection/component/drawings',
  NOT_IN_THIS_PDF_CV_FOUND: '/inspection/component/tag/unconfirmed/delete',
  LINK_TO_COMPONENT_TAG: '/inspection/component/tag/link',
  DELETE_COMPONENT_TAG: '/inspection/component/tag/delete',
  GET_PDF_LIST_DMS: '/project/tagged/documents/list/lite',
  GET_PDF_COMPONENTS_DMS: '/project/component/tagged/list',
  SAVE_COMPONENT_TAG_DMS: '/project/component/tag/save',
  CONFIRM_TAG_DMS: '/project/component/tag/confirm',
  GET_COMPONENT_DRAWINGS_DMS: '/project/component/drawings',
  NOT_IN_THIS_PDF_CV_FOUND_DMS: '/project/component/tag/unconfirmed/delete',
  LINK_TO_COMPONENT_TAG_DMS: '/project/component/tag/link',
  DELETE_COMPONENT_TAG_DMS: '/project/component/tag/delete',
  DELETE_COMPONENT_TAGS_DMS: '/project/component/tags/delete',
  UPDATE_COMPONENT_TAG_DMS: '/project/component/tag/update',
  GET_COMPONENT_DRAWINGS_DETAILS: '/project/component/drawings/details',
};
