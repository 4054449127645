import React, { Component } from 'react';
import Icon from '../../icon/components/icon';
import PropTypes from 'prop-types';

import '../styles/warning-indicator.scss';

class WarningIndicator extends Component {
  render() {
    const { title, description } = this.props;

    return (
      <div className="warning-indicator">
        <div className="warning-indicator__inner">
          <div className="warning-indicator__title">
            <Icon name="warning" warning handleHover={false} size="md" title={'Warning'} />
            <p className="warning-indicator__text light-bold">{title}</p>
          </div>
          <p className="warning-indicator__text">{description}</p>
        </div>
      </div>
    );
  }
}
WarningIndicator.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default WarningIndicator;
