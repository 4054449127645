import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getFormSyncErrors, reduxForm } from 'redux-form';

import { FORMS } from '../../../../../common/constants';
import Button from '../../../../../common/form/components/button';
import Helpers from '../../../../../common/helpers';
import { validate } from '../../../components/right-toolbar/validators/change-status-questions-validator';
import { questionFields, steps } from '../../right-toolbar/isolation-certificate-details/constants/change-status-constants';
import SectionQuestion from './section-question';

const ChangeStatusQuestions = (
  { change, handleSubmit, anyTouched, formErrors, questions = [], answers = [], handleFieldChange, handleFinishQuestions, handleCloseModal, toggleQuestionDetails },
  { t }
) => {
  const onFieldChange = (name, question, value, questionType) => {
    handleFieldChange(name, question, value, change, questionType);
  };

  return (
    <div className="change-status-questions">
      <form onSubmit={handleSubmit(() => handleFinishQuestions(steps.second))} noValidate>
        {questions?.length > 0 &&
          questions.map((question, index) => (
            <SectionQuestion
              key={`change-status-question-item-${question[questionFields.id]}`}
              question={question}
              answers={answers}
              questionNumber={index + 1}
              onFieldChange={onFieldChange}
              formErrors={formErrors}
              anyTouched={anyTouched}
              toggleQuestionDetails={toggleQuestionDetails}
              hideQuestionAdditionalDetails
            />
          ))}
        <div className="questions-buttons-container">
          <Button type="button" variant="success-outline" height="md" width="sm" text={t('ISOLATION_CERTIFICATE.CANCEL_BUTTON')} onClick={handleCloseModal} />
          <Button type="submit" className="save-btn" height="md" width="sm" variant="success" text={t('ISOLATION_CERTIFICATE_STATUS.NEXT_BUTTON')} />
        </div>
      </form>
    </div>
  );
};

ChangeStatusQuestions.contextTypes = {
  t: PropTypes.func.isRequired,
};

ChangeStatusQuestions.propTypes = {
  anyTouched: PropTypes.bool,
  formErrors: PropTypes.object,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number,
      Name: PropTypes.string.isRequired,
      QuestionType: PropTypes.number,
      QuestionOrder: PropTypes.number,
      IsMandatory: PropTypes.bool,
      AllowMultipleAnswers: PropTypes.bool,
      EstimatedTime: PropTypes.number,
      CreatedAt: PropTypes.number,
      SectionID: PropTypes.number,
    })
  ),
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number,
      QuestionID: PropTypes.number,
      Name: PropTypes.string.isRequired,
      QuestionType: PropTypes.number,
      QuestionOrder: PropTypes.number,
      IsMandatory: PropTypes.bool,
      AllowMultipleAnswers: PropTypes.bool,
      EstimatedTime: PropTypes.number,
      CreatedAt: PropTypes.number,
      SectionID: PropTypes.number,
      AnsweredBy: PropTypes.number,
      AnsweredAt: PropTypes.number,
      AnswerText: PropTypes.string,
      AnswerNumber: PropTypes.number,
      AnswerCheckbox: PropTypes.bool,
      AnswerDate: PropTypes.number,
      AnswerOptionIDs: PropTypes.arrayOf(PropTypes.number),
    })
  ),
  handleFieldChange: PropTypes.func.isRequired,
  handleFinishQuestions: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  toggleQuestionDetails: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  formErrors: getFormSyncErrors(FORMS.changeModuleStatusQuestionsForm)(state),
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: FORMS.changeModuleStatusQuestionsForm,
    enableReinitialize: true,
    touchOnChange: true,
    destroyOnUnmount: true,
    validate,
    onSubmitFail: errors => Helpers.scrollToFirstError(errors),
  })(ChangeStatusQuestions)
);
