import setPasswordConstatns from '../constants/set-password-constants';
import { PASSWORD_REGEX } from '../../../common/constants';

export const validate = values => {
  const errors = {};

  if (!values[setPasswordConstatns.fields.password] || !PASSWORD_REGEX.test(values[setPasswordConstatns.fields.password])) {
    errors[setPasswordConstatns.fields.password] = 'LOGIN.PASSWORD_NOT_VALID';
  }

  if (!values[setPasswordConstatns.fields.confirmPassword] || !PASSWORD_REGEX.test(values[setPasswordConstatns.fields.confirmPassword])) {
    errors[setPasswordConstatns.fields.confirmPassword] = 'LOGIN.PASSWORD_NOT_VALID';
  }

  if (values[setPasswordConstatns.fields.confirmPassword] !== values[setPasswordConstatns.fields.password])
    errors[setPasswordConstatns.fields.confirmPassword] = 'RESET_PASSWORD.PASSWORD_DID_NOT_MATCH';

  if (!values[setPasswordConstatns.fields.termsAndConditionsAccepted]) {
    errors[setPasswordConstatns.fields.termsAndConditionsAccepted] = 'SET_PASSWORD.PLEASE_ACCEPT_TERMS';
  }

  return errors;
};
