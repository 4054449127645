import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import ButtonWithIcon from '../../../../../../common/button-with-icon/button-with-icon';
import CustomInput from '../../../../../../common/form/components/input';
import Helpers from '../../../../../../common/helpers';
import Icon from '../../../../../../common/icon/components/icon';
import { calculateReadingStats } from '../../actions/table-formatting';
import { previewConstants } from '../../constants/measurement-readings-constants';

const ReadingFormFields = (
  { fields, meta: { error, submitFailed }, unit, isEdit, showLabel = true, placeholder = '', showInputAddon = true, disabled = false, onChange = () => null, removeReadingHandler = null },
  { t }
) => {
  // Ensure there is always one field present
  if (fields.length === 0 && !isEdit) {
    fields.push('');
  }

  const addReading = () => {
    fields.push('');
    setTimeout(() => {
      const element = document.getElementById('action-buttons');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 100);
  };

  const renderFields = () => {
    return (
      <div>
        {fields.map((reading, index) => (
          <div key={index} className="reading-field">
            <Field
              id={reading}
              isRequired
              name={reading}
              type="text"
              component={CustomInput}
              label={showLabel ? t('READINGS_AND_GAUGES.MEASUREMENT_READINGS_FORM.READING', { number: index + 1 }) : null}
              placeholder={placeholder || `${t('READINGS_AND_GAUGES.MEASUREMENT_READINGS_FORM.READING', { number: index + 1 })}*`}
              labelClass="defect-form__label"
              disabled={disabled}
              onChange={value => onChange(index, value)}
            />
            <span className="unit-text" data-after={Helpers.truncateText(unit, 6)} title={unit} />
            {showInputAddon && (
              <div className="input-addon">
                {fields.length > 1 && (
                  <Icon
                    size="sm"
                    className="question-options__wrapper__field__icon"
                    name="close"
                    onClick={
                      removeReadingHandler
                        ? () =>
                            removeReadingHandler(index, () => {
                              fields.remove(index);
                            })
                        : () => fields.remove(index)
                    }
                  />
                )}
              </div>
            )}
          </div>
        ))}
        <ButtonWithIcon
          icon="plus-circle"
          noBorder
          text={t('READINGS_AND_GAUGES.MEASUREMENT_READINGS_FORM_BUTTON.NEW_VALUE')}
          onClick={addReading}
          type="success"
          isDisabled={fields.length >= 5 || disabled}
        />
        {submitFailed && error && <span>{error}</span>}
      </div>
    );
  };

  const renderPreview = () => {
    const readings = fields.getAll();
    const previewCalculations = readings ? calculateReadingStats(readings) : [];

    return (
      <React.Fragment>
        {fields.length >= 2 ? (
          <div className="create-measurement-reading-form__preview">
            {previewConstants.map(({ id, label }) => (
              <div key={id} className="reading-stat">
                <span className="reading-stat__label">{t(label)}:</span>
                <span className="reading-stat__value">{previewCalculations[id]}</span>
                <span className="reading-stat__value">{unit}</span>
              </div>
            ))}
          </div>
        ) : null}
      </React.Fragment>
    );
  };

  return (
    <div>
      {renderFields()}
      {renderPreview()}
    </div>
  );
};

ReadingFormFields.propTypes = {
  fields: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  unit: PropTypes.string,
};

ReadingFormFields.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ReadingFormFields;
