import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { debounce, each } from 'lodash';

import TableComponent from '../../../../common/table/components/table-component';
import SearchInput from '../../../../common/input/components/search-input';
import Button from '../../../../common/form/components/button';
import Modal from '../../../../common/modal/components/modal';
import UserModal from './user-modal';

import Helpers from '../../../../common/helpers';
import Icon from '../../../../common/icon/components/icon';
import InfoTooltip from '../../../../common/tooltip/components/info-tooltip';
import constants, { formConstants } from '../../constants/constants';

import { getUsersTableData, setUsersTableParams, toggleUserModal } from '../../actions/users-data-actions';
import { setUsersData } from '../../actions/action-creators';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../common/permissions-constants';
import AccessRenderer from '../../../../common/access-renderer/components/access-renderer';
import { roleFields } from '../../constants/role-constants';

class Users extends Component {
  onRowClick = id => {
    //TO DO do something
  };
  constructor(props) {
    super(props);

    this.searchChangeDebounced = debounce(this.search, 500);
  }

  componentWillUnmount() {
    const { setUsersTableParams, resetUsersData } = this.props;
    this.searchChangeDebounced.cancel();
    //reset table to default query params
    setUsersTableParams(constants.userDataParams);
    resetUsersData();
  }

  handleInputChange = e => {
    this.searchChangeDebounced(e.target.value);
  };
  search = SearchText => {
    const { getUsersTableData, usersTableParams } = this.props;
    getUsersTableData({ ...usersTableParams, SearchText, LastSeen: 0 });
  };
  onColumnSort = SortByColumn => {
    const { getUsersTableData, usersTableParams, setUsersTableParams } = this.props;
    const newParams = {
      ...usersTableParams,
      SortByColumn,
      SortDirection: usersTableParams.SortDirection === constants.sortDirection.asc ? constants.sortDirection.desc : constants.sortDirection.asc,
      LastSeen: 0,
    };
    setUsersTableParams(newParams);

    getUsersTableData(newParams);
  };

  loadMore = () => {
    const { getUsersTableData, usersTableParams, usersTableData, setUsersTableParams } = this.props;
    const newParams = { ...usersTableParams, LastSeen: usersTableParams.LastSeen + usersTableParams.PerPage };

    setUsersTableParams(newParams);
    getUsersTableData(newParams, usersTableData, () => Helpers.scrollIntoView('members-container', `row-${usersTableData.length - 1}`));
  };

  componentDidMount = () => {
    const { getUsersTableData } = this.props;
    getUsersTableData(constants.userDataParams);
  };

  openCreateUserModal = () => {
    const { toggleUserModal } = this.props;
    const { t } = this.context;
    const closeAction = () => {
      const modalData = {
        isOpen: false,
      };
      toggleUserModal(modalData);
    };

    const modalData = {
      CustomContent: props => <UserModal {...props} closeAction={closeAction} />,
      isOpen: true,
      title: t('CREATE_USER.TITLE'),
      type: 'none',
      closeAction,
    };
    toggleUserModal(modalData);
  };

  render() {
    const { t } = this.context;
    const {
      usersTableData,
      usersTableParams,
      activateModalData,
      userModalData,
      usersTableParams: { HasNext },
    } = this.props;
    return (
      <div className="members-container">
        <div className="search-combo">
          <SearchInput onChange={this.handleInputChange} placeholder={t('PROJECT.SEARCH.PLACEHOLDER')} />
          <AccessRenderer visibleFor={[PERMISSIONS[PERMISSION_TYPES.people].create.name]}>
            {({ hasAccess }) => (hasAccess ? <Button type="button" variant="success" text={t('USERS_TABLE.ADD_USER')} onClick={this.openCreateUserModal} /> : null)}
          </AccessRenderer>
        </div>
        <TableComponent
          tableConfig={constants.usersTableHeader}
          data={usersTableData || []}
          tableCustomClass="users-table"
          onRowClick={this.onRowClick}
          onColumnSort={this.onColumnSort}
          sortingObj={usersTableParams}
          translationModule={t}
          formatCell={(value, type, index, item) => {
            if (type === formConstants.fields.role) {
              const policies = usersTableData[index][formConstants.fields.role] || [];
              let actionsMenu = {};
              if (policies.length > 0) {
                each(policies, (item, index) => {
                  actionsMenu = { ...actionsMenu, [`${index + 1}`]: item[roleFields.name] };
                });
              } else {
                actionsMenu = t('USER_TABLE.ROLES_TOOLTIP_EMPTY');
              }

              return (
                <div className="info-box">
                  <p className="f-primary">{policies.length || 0}</p>
                  <InfoTooltip
                    actionsMenu={actionsMenu}
                    offsetY={10}
                    offsetX={15}
                    Component={() => <Icon name="info" handleHover={false} size="xs" />}
                    containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
                  />
                </div>
              );
            } else {
              return Helpers.formatCell(value, type, index, item);
            }
          }}
          stickyHeader={true}
        />
        <Button disabled={!HasNext} text={t('LOAD_MORE')} variant="success-outline" height="md" className="load-more" width="sm" onClick={this.loadMore} type="button" />
        <Modal {...activateModalData} />
        <Modal {...userModalData} usersTableData={usersTableData} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  usersTableData: state.usersTableReducer.usersTableData,
  usersTableParams: state.usersTableReducer.usersTableParams,
  activateModalData: state.usersTableReducer.activateModalData,
  userModalData: state.usersTableReducer.userModalData,
  user: state.userReducer,
});

const mapDispatchToProps = dispatch => ({
  getUsersTableData: (params, loadMore, callback) => dispatch(getUsersTableData(params, loadMore, callback)),
  setUsersTableParams: params => dispatch(setUsersTableParams(params)),
  resetUsersData: () => dispatch(setUsersData([])),
  toggleUserModal: params => dispatch(toggleUserModal(params)),
});

Users.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
