import PropTypes from 'prop-types';
import { Component, forwardRef } from 'react';

import { keys } from 'lodash';
import { iconstMap, sizeMap } from '../constants/constants';

import '../styles/styles.scss';

class Icon extends Component {
  render() {
    const { name, active, solid, handleHover, size, title, rotation, className, warning, danger, disabled, forwardRef, ...restProps } = this.props;

    const Icon = iconstMap[name];
    const sizeObj = sizeMap[size];

    let status = '';
    let style = {};
    if (active) {
      status = 'active';
    } else {
      if (solid) {
        status = 'solid';
      }
      if (warning) {
        status = 'warning';
      }
      if (danger) {
        status = 'danger';
      }
      if (disabled) {
        status = 'disabled';
      }
    }

    if (rotation) {
      style = { transform: `rotate(${rotation}deg)` };
    }

    return (
      (Icon && (
        <Icon
          {...{
            style,
            className: `icon-${name} ${status} ${handleHover ? 'handle-hover' : ''} ${className}`,
            title,
            ...sizeObj,
            ...restProps,
          }}
          ref={forwardRef}
        />
      )) ||
      null
    );
  }
}

Icon.defaultProps = {
  name: '',
  active: false,
  solid: false,
  warning: false,
  danger: false,
  disabled: false,
  handleHover: true,
  size: 'md',
  title: '',
  className: '',
  rotation: 0,
};
Icon.propTypes = {
  name: PropTypes.oneOf(keys(iconstMap)),
  size: PropTypes.oneOf(keys(sizeMap)),
  className: PropTypes.string,
};

export default forwardRef((props, ref) => <Icon forwardRef={ref} {...props} />);
