export const highlightedOpacityLevel = {
  selected: 0.7,
  notSelected: 0.5,
};

export const higlightedStrokeWidth = 4;

export const PDFErrors = {
  shading: 'Unknown IR type: Shading',
  subArray: "Cannot read properties of null (reading 'subarray')",
  // this might change in the future, so we can maybe use .includes('SVG')
  svgDeprecated: 'Deprecated API usage: The SVG back-end is no longer maintained and *may* be removed in the future.',
};
