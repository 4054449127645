import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
//components
import SingleRnGItemTable from '../single-item-table/single-item-table';
//constants
import { isEmpty, uniqBy } from 'lodash';
import AccessRenderer from '../../../../../../common/access-renderer/components/access-renderer';
import EmptyState from '../../../../../../common/empty-state-v2/components/empty-state';
import Helpers from '../../../../../../common/helpers';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../../../common/permissions-constants';
import { setMeasurementPoints } from '../../actions/action-creators';
import { fetchMeasurementPoints } from '../../actions/measurement-point-actions';
import { fields, viewOptions } from '../../constants/constants';
import { defaultFilter, filterParams } from '../../constants/measurement-point-constants';

const MeasurementPointsTableView = (props, { t }) => {
  const {
    isFullScreen,
    projectID,
    handlePathClick,
    handleBackClick,
    navigateToPath,
    path,
    selectedClusterElement,
    areAllMeasurementPointsHidden,
    showPath = true,
    user,
    searchPlaceholder,
    selectedMeasurementLocation,
    isNewMeasurementPointModal,
    fetchDataAction,
    additionalFetchMLProps,
    applyEmptyMeasurementPointsList = false,
    toggleModalAction,
    visibleFor,
  } = props;

  const [measurementPoints, setMeasurementPoints] = useState([]);
  const [measurementPointsFilter, setMeasurementPointsFilter] = useState(defaultFilter);
  const [measurementPointsLoading, setMeasurementPointsLoading] = useState(false);

  const fetchDataCallback = useCallback(
    (filters, loadMore = false) => {
      if (!selectedMeasurementLocation || !selectedMeasurementLocation?.[fields.id] || applyEmptyMeasurementPointsList) return;
      const handleFetchData = (data, newFilters) => {
        const newData = loadMore ? [...measurementPoints, ...data] : data;

        setMeasurementPoints(uniqBy(newData, fields.id));
        setMeasurementPointsFilter({ ...measurementPointsFilter, ...newFilters });
        setMeasurementPointsLoading(false);
        if (loadMore) {
          Helpers.scrollIntoView('measurement-points-table', null, -250);
        }
      };

      fetchDataAction(
        { ...filters, ...additionalFetchMLProps, [filterParams.projectID]: projectID, [fields.measurementLocationID]: selectedMeasurementLocation[fields.id] },
        handleFetchData,
        setMeasurementPointsLoading
      );
    },
    [fetchDataAction, projectID, additionalFetchMLProps, selectedMeasurementLocation, measurementPoints, measurementPointsFilter, applyEmptyMeasurementPointsList]
  );

  useEffect(() => {
    if (selectedMeasurementLocation && !applyEmptyMeasurementPointsList) {
      fetchDataCallback({ ...measurementPointsFilter, [fields.lastSeen]: 0, [fields.searchText]: '' });
    } else {
      setMeasurementPoints([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMeasurementLocation, applyEmptyMeasurementPointsList]);

  const toggleModalActionHandler = selectedMeasurementPoint => {
    toggleModalAction(selectedMeasurementPoint, () => {
      // this callback removes the linked MP from the MP table list
      // Find the index of the selected measurement location by ID
      const foundIndex = measurementPoints.findIndex(l => l[fields.id] === selectedMeasurementPoint[fields.id]);

      if (foundIndex !== -1) {
        // Remove the found measurement point from the array
        const updatedMeasurementPoints = measurementPoints.filter((_, index) => index !== foundIndex);

        // Update the state with the new array
        setMeasurementPoints(updatedMeasurementPoints);
        // Update the total number filter by incrementing it by one (since a single MP has been linked)
        setMeasurementPointsFilter({ ...measurementPointsFilter, [fields.totalNumber]: measurementPointsFilter[fields.totalNumber] - 1 });
      }
    });
  };

  if (isEmpty(selectedMeasurementLocation)) {
    return <EmptyState emptyStateText={t('READINGS_AND_GAUGES.MEASUREMENT_POINTS.NO_SELECTED_ML_EMPTY_STATE.TITLE')} showButton={false} transparent />;
  }

  const emptyStateText = t('READINGS_AND_GAUGES.MEASUREMENT_POINTS.EMPTY_STATE.TITLE');
  const emptyStateProps = {
    emptyStateText: emptyStateText,
    showButton: false,
    transparent: true,
    buttonText: null,
    buttonAction: null,
    buttonDisabled: !Helpers.hasAccess({ user, visibleFor: [PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].pointCreate.name] }),
  };

  return (
    <AccessRenderer visibleFor={visibleFor} id={selectedClusterElement && selectedClusterElement[fields.createdBy]}>
      {({ hasAccess: canEdit }) => {
        return canEdit ? (
          <SingleRnGItemTable
            searchPlaceholder={searchPlaceholder}
            customTableClass={'measurement-points-table'}
            isFullScreen={isFullScreen}
            view={viewOptions.points}
            handleRowClick={() => null}
            path={path}
            navigateToPath={navigateToPath}
            handleBackClick={handleBackClick}
            handlePathClick={handlePathClick}
            projectID={projectID}
            fetchData={fetchDataCallback}
            toggleVisibilityHandler={() => null}
            data={measurementPoints}
            filters={measurementPointsFilter}
            defaultFilters={defaultFilter}
            isDataLoading={measurementPointsLoading}
            selectedItem={null}
            allHidden={areAllMeasurementPointsHidden}
            toggleAll={() => null}
            showPath={showPath}
            showEmptyState={isEmpty(measurementPoints)}
            emptyStateProps={emptyStateProps}
            user={user}
            isNewMeasurementPointModal={isNewMeasurementPointModal}
            toggleModalAction={toggleModalActionHandler}
          />
        ) : null;
      }}
    </AccessRenderer>
  );
};

MeasurementPointsTableView.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  measurementPoints: state.measurementPointReducer.measurementPoints,
  measurementPointsFilter: state.measurementPointReducer.measurementPointsFilter,
  measurementPointsLoading: state.measurementPointReducer.measurementPointsLoading,
  measurementLocations: state.measurementLocationReducer.measurementLocations,
});

const mapDispatchToProps = dispatch => ({
  fetchMeasurementPoints: (filters, loadMore, successCallback) => dispatch(fetchMeasurementPoints(filters, loadMore, successCallback)),
  setMeasurementPoints: data => dispatch(setMeasurementPoints(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MeasurementPointsTableView);
