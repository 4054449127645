import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { isEmpty } from 'lodash';

import { actionTypes, actionsMenu } from '../../constants/team-constants';
import SmallDropdown from '../../../../common/small-dropdown/components/small-dropdown';

class TableDropdown extends Component {
  getClickAction = action => {
    const { data, openEditTeam, openAddMembers, openDeleteTeam, itemIndex } = this.props;
    if (action === actionTypes.editTeam) {
      openEditTeam(data);
    } else if (action === actionTypes.addMembers) {
      openAddMembers(data, itemIndex);
    } else if (action === actionTypes.deleteTeam) {
      openDeleteTeam(data);
    }
  };
  render() {
    return (
      <Fragment>
        <SmallDropdown className={isEmpty(actionsMenu) ? 'disabled' : ''} actionsMenu={actionsMenu} getClickAction={this.getClickAction} offsetX={-150} />
      </Fragment>
    );
  }
}

TableDropdown.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default TableDropdown;
