import { combineReducers } from 'redux';

import { reducer as formReducer } from 'redux-form';
import { i18nState } from 'redux-i18n';
import { persistReducer } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import storage from 'redux-persist/lib/storage';

import { createProjectReducer } from '../app/create-project/reducers/create-project-reducer';
import { potreeReducer } from '../app/inspections/potree/reducers/potree-reducer';
import { inspectionReducer } from '../app/inspections/reducers/inspection-reducer';
import { loginReducer } from '../app/login/reducers/login-reducer';
import { userReducer } from '../app/login/reducers/user-reducer';
import { rolesTableReducer } from '../app/members/reducers/roles-table-reducer';
import { teamsTableReducer } from '../app/members/reducers/teams-table-reducer';
import { usersTableReducer } from '../app/members/reducers/users-table-reducer';
import { navReducer } from '../app/navigation/reducers/navigation-reducer';
import { themeReducer } from '../app/profile/reducers/theme-reducer';
import { projectEditReducer } from '../app/project/reducers/project-edit-reducer';
import { projectReducer } from '../app/project/reducers/project-reducer';
import { passwordResetReducer } from '../app/reset-password/reducers/reset-password-reducer';
import { uploadReducer } from '../app/upload/reducers/upload-reducer';
import { loaderReducer } from '../common/global-loader/reducers/loader-reducer';
import { errorModalReducer } from '../common/modal/reducers/error-modal-reducer';
// TODO: workflow reducers to be removed
import { dmsLabelsReducer } from '../app/document-management/reducers/dms-label-reducer';
import { dmsVersionHistoryReducer } from '../app/document-management/reducers/dms-version-history-reducers';
import { workflowReducer } from '../app/inspection-workflow/reducers/workflow-reducer';
import { measurementGroupReducer } from '../app/inspections/components/readings-and-gauges/reducers/measurement-group-reducer';
import { measurementLocationReducer } from '../app/inspections/components/readings-and-gauges/reducers/measurement-location-reducer';
import { measurementPointReducer } from '../app/inspections/components/readings-and-gauges/reducers/measurement-point-reducer';
import { areasReducer } from '../app/inspections/reducers/area-reducer';
import { explosiveZonesReducer } from '../app/inspections/reducers/explosive-zones-reducer';
import { isolationCertificatesReducer } from '../app/inspections/reducers/isolation-certificates-reducer';
import { ndtReducer } from '../app/inspections/reducers/ndt-reducer';
import { notificationReducer } from '../app/inspections/reducers/notification-reducer';
import { permitsReducer } from '../app/inspections/reducers/permits-reducer';
import { unsavedChangesReducer } from '../app/inspections/reducers/unsaved-changes-reducer';
import { workOrderReducer } from '../app/inspections/reducers/work-order-reducer';
import { sapReducer } from '../app/integrations/reducers/sap-reducer';
import { checklistReducer } from '../app/maintenance-regime/reducers/checklist-reducer';
import { regimeReducer } from '../app/maintenance-regime/reducers/regime-reducer';
import { mfaReducer } from '../app/mfa/reducers/mfa-reducer';
import { pdfTableReducer } from '../app/pdf-tag-list/reducers/pdf-tables-reducer';
import { pdfTagReducer } from '../app/pdf-tag/reducers/pdf-tag-reducer';
import { projectDetailsReducer } from '../app/project/reducers/project-details-reducer';
import { startWorkflowReducer } from '../app/start-workflow/reducers/start-workflow-reducer';
import { templateReducer } from '../app/templates/reducers/template-reducer';
import { uploadBatchesReducer } from '../app/upload/reducers/upload-batches-reducer';
import { collapsableInfoReducer } from '../common/collapsible-info/reducers/collapsible-info-reducer';
import { downloadReportReducer } from '../common/download-report-modal/reducers/download-report-reducer';
import { genericNotificationReducer } from '../common/notification/reducers/notification-reducer';

import { PERSISTOR_SECRET_KEY } from '../common/constants';

const baseReducer = combineReducers({
  loaderReducer,
  loginReducer,
  userReducer,
  navReducer,
  errorModalReducer,
  i18nState,
  inspectionReducer,
  potreeReducer,
  projectReducer,
  themeReducer,
  createProjectReducer,
  usersTableReducer,
  passwordResetReducer,
  uploadReducer,
  projectEditReducer,
  workflowReducer,
  startWorkflowReducer,
  downloadReportReducer,
  sapReducer,
  notificationReducer,
  explosiveZonesReducer,
  areasReducer,
  mfaReducer,
  pdfTableReducer,
  pdfTagReducer,
  ndtReducer,
  checklistReducer,
  regimeReducer,
  workOrderReducer,
  teamsTableReducer,
  templateReducer,
  rolesTableReducer,
  isolationCertificatesReducer,
  permitsReducer,
  genericNotificationReducer,
  dmsLabelsReducer,
  projectDetailsReducer,
  collapsableInfoReducer,
  uploadBatchesReducer,
  unsavedChangesReducer,
  dmsVersionHistoryReducer,
  measurementLocationReducer,
  measurementGroupReducer,
  measurementPointReducer,
  form: formReducer,
});

const encryptor = encryptTransform({
  secretKey: PERSISTOR_SECRET_KEY,
});
const config = {
  key: 'root',
  // for those we need to be saved (only these will be saved)
  whitelist: ['userReducer', 'themeReducer', 'projectEditReducer', 'projectDetailsReducer'],
  storage,
  transforms: [encryptor],
};

const rootReducer = persistReducer(config, baseReducer);

export { config, rootReducer };
