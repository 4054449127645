import React from 'react';
import PropTypes from 'prop-types';
import { fields, severityColors } from '../../observations/constants/constants';

const AddObservationPopover = ({ observation }, { t }) => {
  return (
    <div>
      <p className="bold">{observation[fields.name]}</p>
      <p className="add-module-item-modal__popover-title">{t('DISPLAY_PROPS.DESCRIPTION')}</p>
      <p className="mt-4">{observation[fields.description] ? observation[fields.description] : '-'}</p>
      <p className="add-module-item-modal__popover-title">{t('DISPLAY_PROPS.SEVERITY')}</p>
      <p className={`severity-icon ${severityColors[observation[fields.severity]]} mt-4`}>{observation[fields.severity]}</p>
    </div>
  );
};

AddObservationPopover.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default AddObservationPopover;
