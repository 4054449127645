import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ToolWrapper from '../../../../common/toolbox/components/tool-wrapper';

import { objectTools } from '../../constants/constants';
import { measurementTools } from '../../constants/measurement-constants';
import { categoryTools, componentDropdownActions } from '../../constants/component-constants';
import { observationTools } from '../../constants/defect-constants';

import { PERMISSIONS, PERMISSION_TYPES } from '../../../../common/permissions-constants';
import Icon from '../../../../common/icon/components/icon';

import '../../styles/toolbox.scss';
class ObjectToolbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toolbox: [
        {
          icon: props => <Icon name="cursor" solid {...props} />,
          action: act => props.handleClick(objectTools.cursor, act, true, null, true),
          toolType: null,
        },
        {
          icon: props => <Icon name="measurements" solid {...props} />,
          action: act => props.handleClick(objectTools.measurement, act, true, null, true),
          title: 'OBJECT_TOOLBOX.CREATE_MEASUREMENT_TITLE',
          toolType: objectTools.measurement,
          toolsMenu: measurementTools,
          access: {
            visibleFor: PERMISSIONS[PERMISSION_TYPES.measurements].create.name,
          },
        },
        {
          icon: props => <Icon name="components" solid {...props} />,
          action: act => (act === componentDropdownActions.points ? props.handleClick(objectTools.component, act, true, null, true) : null),
          title: 'OBJECT_TOOLBOX.CREATE_COMPONENT_TITLE',
          toolType: objectTools.component,
          toolsMenu: categoryTools,
          access: {
            visibleFor: PERMISSIONS[PERMISSION_TYPES.components].create.name,
          },
        },
        {
          icon: props => <Icon name="observations" solid {...props} />,
          action: act => props.handleClick(objectTools.link, act, true, null, true),
          title: 'OBJECT_TOOLBOX.CREATE_DEFECT_TITLE',
          toolType: objectTools.link,
          toolsMenu: observationTools,
          access: {
            visibleFor: PERMISSIONS[PERMISSION_TYPES.observations].create.name,
          },
        },
        {
          icon: props => <Icon name="areas" solid {...props} />,
          action: act => props.handleClick(objectTools.volumeClip, act, true, null, true),
          title: 'OBJECT_TOOLBOX.VOLUME_CLIPPING',
          toolType: objectTools.volumeClip,
          access: {
            visibleFor: PERMISSIONS[PERMISSION_TYPES.areas].create.name,
          },
        },

        {
          icon: props => <Icon name="target" solid {...props} />,
          action: act => props.handleClick(objectTools.inspect, true, true, null, true),
          title: 'OBJECT_TOOLBOX.CLICK_TO_INSPECT',
          toolType: objectTools.inspect,
        },
      ],
    };
  }

  render() {
    const { toolbox } = this.state;
    const { activeTool } = this.props;

    return (
      <div className="top-toolbox">
        <ToolWrapper {...{ activeTool, toolbox }} />
      </div>
    );
  }
}

ObjectToolbox.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ObjectToolbox;
