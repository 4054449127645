import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import { FORMS } from '../../../common/constants';

import CustomCheckBox from '../../../common/form/components/checkbox';
import setPasswordConstants from '../constants/set-password-constants';
import { validate } from '../validators/set-password-validators';

import Button from '../../../common/form/components/button';
import PasswordInput from '../../../common/form/components/password-input';
import '../styles/reset-password-form.scss';

class SetPasswordForm extends Component {
  render() {
    const { t } = this.context;
    const { handleSubmit, invalid, anyTouched, CustomLabel } = this.props;
    return (
      <form className="login-form" onSubmit={handleSubmit} noValidate>
        <Field
          id={setPasswordConstants.fields.password}
          name={setPasswordConstants.fields.password}
          label="SET_PASSWORD.PASSWORD"
          labelClass="f-secondary-dark"
          component={PasswordInput}
          type="password"
          placeholder="SET_PASSWORD.PASSWORD"
        />
        <Field
          id={setPasswordConstants.fields.confirmPassword}
          name={setPasswordConstants.fields.confirmPassword}
          label={'SET_PASSWORD.CONFIRM_PASSWORD'}
          labelClass="f-secondary-dark"
          component={PasswordInput}
          type="password"
          placeholder="SET_PASSWORD.PASSWORD"
        />
        <Field
          id={setPasswordConstants.fields.termsAndConditionsAccepted}
          name={setPasswordConstants.fields.termsAndConditionsAccepted}
          labelClass="f-secondary-dark"
          component={CustomCheckBox}
          CustomLabel={CustomLabel}
        />
        <Button disabled={invalid && anyTouched} keepOriginalText text={t('SET_PASSWORD')} />
      </form>
    );
  }
}

SetPasswordForm.contextTypes = {
  t: PropTypes.func.isRequired,
};
SetPasswordForm = reduxForm({
  form: FORMS.setPassword,
  validate,
})(SetPasswordForm);

export default SetPasswordForm;
