export const timeToMilliseconds = time => {
  let result;
  // the function expects time in format "hh:mm"
  const times = time.split(':');

  if (times && times.length && times.length === 2) {
    const hours = times[0];
    const minutes = times[1];

    result = (hours * 60 * 60 + minutes * 60) * 1000;
  }

  return result;
};

export const millisecondsToTime = value => {
  let seconds = Math.floor(value / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  // 👇️ if seconds are greater than 30, round minutes up (optional)
  minutes = seconds >= 30 ? minutes + 1 : minutes;

  minutes = minutes % 60;

  return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
};
