import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from '../../icon/components/icon';
import ItemsTableRenderer from '../../items-renderer/components/items-table-renderer';
import componentConstants from '../constants/component-constants';

import '../styles/component-list.scss';

class ComponentList extends Component {
  render() {
    const { t } = this.context;
    const { handleDeleteComponent, question, selectedComponents } = this.props;

    return (
      <div className="component-list">
        <h5 className="f-primary">{t('COMPONENT_PICKER.COMPONENT_LIST_TITLE')}</h5>
        <ItemsTableRenderer
          tableConfig={[
            ...componentConstants.componentsTableHeader,
            {
              CustomComponent: (data, itemIndex) => (
                <div className="component-list__table-actions">
                  <Icon name="trash" size="xs" onClick={() => handleDeleteComponent(question?.ID, data.ID, data[componentConstants.formConstants.fields.name])} />
                </div>
              ),
              enableSort: false,
            },
          ]}
          translationModule={t}
          data={selectedComponents}
          onRowClick={(e, item) => null}
          formatCell={(value, type, index, item) => {
            return value;
          }}
          emptyStateText={'SECTIONS.QUESTION_EMPTY_COMPONENTS'}
        />
      </div>
    );
  }
}

ComponentList.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ComponentList;
