import { createFolderModalFormFields, fileFormFields } from '../constants/constants';

export const validateCreateNewFolder = values => {
  const errors = {};

  if (!values[createFolderModalFormFields.name.name]) {
    errors[createFolderModalFormFields.name.name] = 'DOCUMENT_MANAGEMENT_MODAL.FOLDER_NAME_REQUIRED';
  }

  if (values[createFolderModalFormFields.name.name]?.length > 100) {
    errors[createFolderModalFormFields.name.name] = 'DOCUMENT_MANAGEMENT_MODAL.FOLDER_NAME_MAX';
  }

  return errors;
};

export const validateUpdateFile = values => {
  const errors = {};

  if (!values[fileFormFields.fileName.name]) {
    errors[fileFormFields.fileName.name] = 'DOCUMENT_MANAGEMENT_MODAL.FILE_NAME_REQUIRED';
  }

  if (values[fileFormFields.fileName.name]?.length < fileFormFields.fileName.minChars) {
    errors[fileFormFields.fileName.name] = 'DOCUMENT_MANAGEMENT_MODAL.FILE_NAME_MIN';
  }

  if (values[fileFormFields.fileName.name]?.length > fileFormFields.fileName.maxChars) {
    errors[fileFormFields.fileName.name] = 'DOCUMENT_MANAGEMENT_MODAL.FILE_NAME_MAX';
  }

  if (values[fileFormFields.description.name]?.length > fileFormFields.description.maxChars) {
    errors[fileFormFields.description.name] = 'DOCUMENT_MANAGEMENT_MODAL.FILE_DESC_MAX';
  }

  return errors;
};

export const validateChangeCategory = values => {
  const errors = {};

  // TODO: if no category selected, disable submit button
  // if (!values[createFolderModalFormFields.name.name]) {
  //   errors[createFolderModalFormFields.name.name] = 'DOCUMENT_MANAGEMENT_MODAL.FOLDER_NAME_REQUIRED';
  // }

  // if (values[createFolderModalFormFields.name.name]?.length > 100) {
  //   errors[createFolderModalFormFields.name.name] = 'DOCUMENT_MANAGEMENT_MODAL.FOLDER_NAME_MAX';
  // }

  return errors;
};
