/**
 * This hook is used globally for handling unsaved changes across all forms in our application,
 * it can be integrated at a higher level in our component hierarchy,
 * such as in our main application component or
 * a layout component that wraps around our application's routes.
 */
import { isEqual } from 'lodash';
import { useEffect, useRef } from 'react';

const useConfirmOnInspectionExit = props => {
  const { title, message, onConfirm, onCancel, router, route, isDirty, clearUnsavedChangesDirty } = props;
  const confirmLeaveRef = useRef(null);

  useEffect(() => {
    const handleBeforeUnload = event => {
      /**
       * `&& !confirmLeaveRef.current` prevents double load of
       * unsaved changes popup if leave is confirmed.
       * This scenario happens, for example, when going from 3D mode to fullscreen mode.
       */
      if (isDirty && !confirmLeaveRef.current) {
        event.returnValue = message; // Standard for most browsers
        event.preventDefault(); // For Chrome
        return message; // For some browsers
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty, message, router, route]);

  useEffect(() => {
    if (router && route) {
      const isPathnameEqual = (prevPathname, nextPathname) => {
        return isEqual(prevPathname, nextPathname);
      };

      const routerWillLeave = nextLocation => {
        const { location: prevLocation } = router;
        const { pathname: prevPathname } = prevLocation;
        const { pathname: nextPathname } = nextLocation;
        confirmLeaveRef.current = true;

        if (isDirty && confirmLeaveRef.current && !isPathnameEqual(prevPathname, nextPathname)) {
          const confirmLeavePrompt = window.confirm(`${title}\n\n${message}`);
          if (confirmLeavePrompt) {
            clearUnsavedChangesDirty();
            confirmLeaveRef.current = true;
            if (typeof onConfirm === 'function') {
              onConfirm();
            }
            return true;
          } else {
            confirmLeaveRef.current = false;
            if (typeof onCancel === 'function') {
              onCancel();
            }
            return false; // Explicitly return false to block the navigation
          }
        }
      };

      const unlisten = router.listenBefore(routerWillLeave);

      return () => {
        unlisten();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, route, isDirty, message]);
};

export default useConfirmOnInspectionExit;
