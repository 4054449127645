import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DoubleTable from '../../../../../common/double-table/components/double-table';
import Helpers from '../../../../../common/helpers';
import PathViewer from '../../../../../common/path-viewer/components/path-viewer';
import { formConstants } from '../../../../contractor-management/constants/constants';
import { formatFileTableCells, formatFolderTableCells } from '../../../actions/table-formatting';
import { changeDMSLocationFileTable, changeDMSLocationFolderTable, chooseDMSFromComponentFileTable, defaultFilesFilters, defaultFolderFilters, filterProps } from '../../../constants/constants';
import DMSHelpers from '../../../helpers/dms-helpers';
import '../../../styles/dms-change-hierarchy.scss';
import ChangeDMSLocationRightSide from './change-dms-location-right-side';
import ChooseDmsLocationRightSide from './choose-dms-location-right-side';

const ChangeDMSLocation = (props, context) => {
  // props are extracted this way since there will be multiple props and this is cleaner than to have it deconstructed in the args pass
  const {
    fetchDMFolders,
    fetchDMFiles,
    fileInformation,
    customCloseAction,
    customConfirmAction,
    isChooseDocument,
    onLinkFileFromSelectedModuleItem,
    onUnlinkFileFromSelectedModuleItem,
    selectedModuleItem,
    hasLinkDMSFilePermissions,
    hasUnlinkDMSFilePermissions,
    // moduleItemType is undefined when opened from document-management.js (DMS)
    moduleItemType,
    projectID,
    // work orders specific, used in work order creation wizard
    modalWorkorderFiles,
    checklistQuestionEvidenceFiles,
  } = props;

  //context deconstruction
  const { t } = context;

  //state
  const [path, setPath] = useState([{ Name: t('DOCUMENT_MANAGEMENT.ALL_DOCUMENTS'), ID: null }]);
  // hooks & lifecycles
  useEffect(() => {
    getFolders(defaultFolderFilters);
    //eslint-disable-next-line
  }, []);
  //filters
  const [foldersFilters, setFolderFilters] = useState(defaultFolderFilters);
  const [filesFilters, setFilesFilters] = useState(defaultFilesFilters);

  //data
  const [currentLevel, setCurrentLevel] = useState(null);
  const [folderData, setFolderData] = useState([]);
  const [isFoldersDataLoading, setIsFoldersDataLoading] = useState(false);
  const [foldersSearchText, setFoldersSearchText] = useState('');

  const [fileData, setFileData] = useState([]);
  const [isFilesDataLoading, setIsFilesDataLoading] = useState(false);
  const [filesSearchText, setFilesSearchText] = useState('');

  //folder related logic

  const handleFolderSearchInput = e => {
    setFoldersSearchText(e.target.value);
    foldersSearchTextChanged(e.target.value);
  };

  const handleFolderSearch = SearchText => DMSHelpers.onSearch(SearchText, foldersFilters, getFolders);

  const foldersSearchTextChanged = debounce(handleFolderSearch, 300);

  const handleFolderSort = SortByColumn => DMSHelpers.onSort(SortByColumn, foldersFilters, getFolders);

  const handleOnFolderClick = row => {
    DMSHelpers.onFolderRowClick(row, path, foldersFilters, defaultFolderFilters, getFolders, setPath, setFoldersSearchText, setCurrentLevel);
    DMSHelpers.onFolderChangeFetchFiles(row, path, filesFilters, defaultFilesFilters, getFiles, setFilesSearchText);
  };

  const loadMoreFolders = () => getFolders(foldersFilters, true);

  const getFolders = (filters, loadMore = false) => {
    const handleGetFolders = (data, newFilters) => {
      const newData = loadMore ? [...folderData, ...data] : data;
      setFolderData(newData);
      setFolderFilters({ ...foldersFilters, ...newFilters });
      setIsFoldersDataLoading(false);
      if (loadMore) {
        Helpers.scrollIntoView('folders-table', `row-${filters[filterProps.lastSeen] - 1}`, -250);
      }
    };
    fetchDMFolders({ ...filters, [formConstants.fields.projectId]: projectID }, handleGetFolders, setIsFoldersDataLoading);
  };

  //files related logic

  const handleFileSearchInput = e => {
    setFilesSearchText(e.target.value);
    filesSearchTextChanged(e.target.value);
  };
  const handleFileSearch = SearchText => DMSHelpers.onSearch(SearchText, filesFilters, getFiles);

  const filesSearchTextChanged = debounce(handleFileSearch, 300);

  const handleFilesSort = SortByColumn => DMSHelpers.onSort(SortByColumn, filesFilters, getFiles);

  const loadMoreFiles = () => getFiles(filesFilters, true);

  const getFiles = (filters, loadMore = false) => {
    const handleGetFolders = (data, newFilters) => {
      const newData = loadMore ? [...fileData, ...data] : data;
      setFileData(newData);
      setFilesFilters({ ...foldersFilters, ...newFilters });
      setIsFilesDataLoading(false);
      if (loadMore) {
        Helpers.scrollIntoView('files-table', `row-${filters[filterProps.lastSeen] - 1}`, -250);
      }
    };

    fetchDMFiles({ ...filters, [formConstants.fields.projectId]: projectID }, handleGetFolders, setIsFilesDataLoading);
  };

  const handleOnPathBackClick = () => {
    DMSHelpers.onPathBackClick(path, foldersFilters, defaultFolderFilters, getFolders, setPath, setFoldersSearchText, setCurrentLevel);
    DMSHelpers.onPathBackFetchFiles(path, filesFilters, defaultFilesFilters, getFiles, setFilesSearchText);
  };

  const handleOnPathClick = ID => {
    DMSHelpers.onPathClick(ID, path, foldersFilters, defaultFolderFilters, getFolders, setPath, setFoldersSearchText, setCurrentLevel);
    DMSHelpers.onPathClickFetchFiles(ID, filesFilters, defaultFilesFilters, getFiles, setFilesSearchText);
  };

  const isMoveButtonDisabled = (fileInformation.DocumentHierarchyID && fileInformation.DocumentHierarchyID === currentLevel) || currentLevel === null;

  const handleSuccessButtonClick = () => {
    customConfirmAction({ [filterProps.sourceID]: fileInformation[filterProps.sourceID], [filterProps.documentHierarchyID]: path[path.length - 1].ID }, path, fileInformation);
  };

  return (
    <div className="dms-change-location">
      <div className="dms-change-location__container">
        <div className="dms-change-location__container__left-side">
          <div className={`dms-change-location__container__left-side__path `}>
            <PathViewer levels={path} propInPath="Name" onBackClick={handleOnPathBackClick} onPathClick={handleOnPathClick} />
          </div>
          <div className={`dms-change-location__container__left-side__table`}>
            <DoubleTable
              firstTableProps={{
                data: folderData,
                tableConfig: changeDMSLocationFolderTable,
                filters: foldersFilters,
                sortDirection: filterProps.sortDirection,
                sortByColumn: filterProps.sortByColumn,
                handleFormatCells: formatFolderTableCells,
                TotalItems: foldersFilters[filterProps.totalItems],
                searchPlaceholder: t('DOCUMENT_MANAGEMENT_TABLE.SEARCH_FOLDER', { currentFolder: path[path.length - 1].Name }),
                noDataText: t('DOCUMENT_MANAGEMENT_TABLE.FOLDER_TABLE_NO_DATA'),
                onRowClick: handleOnFolderClick,
                onSortClick: handleFolderSort,
                isLoading: isFoldersDataLoading,
                hasNext: foldersFilters[filterProps.hasNext],
                loadMoreOnClick: loadMoreFolders,
                customTableClass: 'folders-table',
                searchInputValue: foldersSearchText,
                searchInputOnChange: handleFolderSearchInput,
                onDropdownClick: () => null,
                selectedItem: null,
                pointerDotID: fileInformation.DocumentHierarchyID,
              }}
              secondTableProps={{
                data: fileData,
                tableConfig: isChooseDocument ? chooseDMSFromComponentFileTable : changeDMSLocationFileTable,
                filters: filesFilters,
                sortDirection: filterProps.sortDirection,
                sortByColumn: filterProps.sortByColumn,
                handleFormatCells: formatFileTableCells,
                TotalItems: filesFilters[filterProps.totalItems],
                searchPlaceholder: t('DOCUMENT_MANAGEMENT_TABLE.SEARCH_FILE', { currentFolder: path[path.length - 1].Name }),
                noDataText: t('DOCUMENT_MANAGEMENT_TABLE.FILE_TABLE_NO_DATA'),
                onRowClick: () => null,
                onSortClick: handleFilesSort,
                isLoading: isFilesDataLoading,
                hasNext: filesFilters[filterProps.hasNext],
                loadMoreOnClick: loadMoreFiles,
                customTableClass: isChooseDocument ? 'choose-documents-table files-table' : 'files-table',
                searchInputValue: filesSearchText,
                searchInputOnChange: handleFileSearchInput,
                onDropdownClick: () => null,
                selectedItem: null,
                onLinkFileClick: file => onLinkFileFromSelectedModuleItem([file], modalWorkorderFiles),
                hasLinkDMSFilePermissions: hasLinkDMSFilePermissions,
              }}
            />
          </div>
        </div>
        <div className="dms-change-location__container__folder-info">
          {isChooseDocument ? (
            <ChooseDmsLocationRightSide
              initialValues={fileInformation}
              onUnlinkFileFromSelectedModuleItem={onUnlinkFileFromSelectedModuleItem}
              moduleItemType={moduleItemType}
              selectedModuleItem={selectedModuleItem}
              hasUnlinkDMSFilePermissions={hasUnlinkDMSFilePermissions}
              checklistQuestionEvidenceFiles={checklistQuestionEvidenceFiles}
            />
          ) : (
            <ChangeDMSLocationRightSide initialValues={fileInformation} closeModal={customCloseAction} successButtonDisabled={isMoveButtonDisabled} successButtonAction={handleSuccessButtonClick} />
          )}
        </div>
      </div>
    </div>
  );
};

ChangeDMSLocation.contextTypes = {
  t: PropTypes.func.isRequired,
};

ChangeDMSLocation.defaultProps = {
  isChooseDocument: false,
};

export default ChangeDMSLocation;
