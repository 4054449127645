import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { debounce } from 'lodash';

import { setSearchText } from '../../../actions/action-creators';

import SearchInputSmall from '../../../../../common/input/components/search-input-small';

class SearchText extends React.Component {
  searchText = debounce(this.props.setSearchText, this.props.debounceTime);

  componentWillUnmount = () => {
    const { setSearchText, resetOnUnmount } = this.props;
    resetOnUnmount && setSearchText('');
  };

  render() {
    const { t } = this.context;
    return <SearchInputSmall placeholder={t('PROJECT.SEARCH.PLACEHOLDER')} onChange={e => this.searchText(e.target.value)} {...this.props} />;
  }
}
SearchText.contextTypes = {
  t: PropTypes.func.isRequired,
};
SearchText.defaultProps = {
  debounceTime: 500,
  resetOnUnmount: true,
};

const mapDispatchToProps = dispatch => ({
  setSearchText: val => dispatch(setSearchText(val)),
});

export default connect(null, mapDispatchToProps)(SearchText);
