import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CreateKeyboxFirstStep from './create-keybox-first-step';
import CreateKeyboxSecondStep from './create-keybox-second-step';
import { steps } from '../constants/constants';

class CreateKeyboxFrom extends Component {
  render() {
    const { step } = this.props;

    return (
      <div className="keybox-create" noValidate>
        {step === steps.firstStep && <CreateKeyboxFirstStep {...this.props} />}
        {step === steps.secondStep && <CreateKeyboxSecondStep {...this.props} />}
      </div>
    );
  }
}

CreateKeyboxFrom.contextTypes = {
  t: PropTypes.func.isRequired,
};

CreateKeyboxFrom.propTypes = {};

export default CreateKeyboxFrom;
