import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import Helpers from '../../../common/helpers';

import IntegrationList from './integration-list';
import SAP from '../forms/components/sap';

import routesConstants from '../../../common/routes-constants';
import { FEATURES } from '../../../common/constants';

import '../styles/integrations.scss';

class Integrations extends Component {
  componentDidMount() {
    const { query } = this.props.location;
    const projectID = get(query, 'project_id');

    if (!projectID || !FEATURES.integrations.visible) {
      Helpers.goTo(routesConstants.routes.protectedRoutes.project.fullPath);
    }
  }

  render() {
    const { t } = this.context;
    const { query } = this.props.location;
    const projectID = parseInt(get(query, 'project_id'));

    return (
      <div className="integrations">
        <div className="integrations__wrapper">
          <h3 className="integrations__title f-primary">{t('INTEGRATIONS.INTEGRATION_SETTINGS')}</h3>
          <div className="integrations__list-container">
            <IntegrationList />
          </div>
          <div className="integrations__form-container">
            <SAP projectID={projectID} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

Integrations.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Integrations);
