import React, { Component } from 'react';
import PropTypes from 'prop-types';

import '../../../styles/quick-filter.scss';

class QuickFilter extends Component {
  render() {
    const { t } = this.context;
    const { items, className, onClick, title } = this.props;

    return (
      <div className={`quick-filter ${className || ''}`}>
        <p className="f-secondary-dark title">{t(title)}</p>
        <div className="items">
          {items.map((item, index) => {
            return (
              <div className={`filter ${item.active ? 'active' : ''}`} key={index} onClick={() => onClick(item, index)}>
                {item.customComponent && (
                  <div className="custom-item">
                    <item.customComponent />
                  </div>
                )}
                <label className="f-primary">{t(item.label)}</label>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

QuickFilter.defaultProps = {
  onClick: () => null,
  title: 'QUICK_FILTER',
};

QuickFilter.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default QuickFilter;
