const assetActions = {
  openFile: 'openFile',
  downloadFile: 'downloadFile',
  deleteFile: 'deleteFile',
};
const actionsMenu = [
  {
    title: 'COMPONENT_ASSETS_DROPDOWN.SETTINGS_MENU.ITEM_1',
    id: '1',
    action: assetActions.openFile,
    // visibleFor: [],
  },
  {
    title: 'COMPONENT_ASSETS_DROPDOWN.SETTINGS_MENU.ITEM_2',
    id: '2',
    action: assetActions.downloadFile,
    // visibleFor: [],
  },
  {
    title: 'COMPONENT_ASSETS_DROPDOWN.SETTINGS_MENU.ITEM_4',
    id: '4',
    action: assetActions.deleteFile,
    // visibleFor: [],
  },
];

export { assetActions, actionsMenu };
