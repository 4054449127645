import React from 'react';
import Button from '../../../../../../common/form/components/button';
import TableTemplate from '../../../../../../common/double-table/components/table-template';
import PropTypes from 'prop-types';
import { modules } from '../../../../constants/constants';
import { defectNotificationsTableConfig, fields } from '../constants/constants';
import { defectNotificationTableFormatting } from '../actions/table-formatting';

// TO DO: Create a unified component for this types of components, this replicates the NotficationsWOTab component

const DefectNotifications = ({ notifications, onCreateNotificationClick, handleActivePage, isCreateButtonDisabled, isLoading, isLoadingNotificationDetails }, { t }) => {
  const onNotificationClick = notification => {
    handleActivePage(modules.notifications, notification[fields.id]);
  };
  return (
    <div>
      <div className="notification-WO-tab__create-button__container">
        <Button
          type="button"
          text={t('DEFECT_DETAILS.CREATE_NOTIFICATION')}
          variant="success-outline"
          onClick={onCreateNotificationClick}
          keepOriginalText
          className="notification-WO-tab__create-button__container__button-width"
          disabled={isCreateButtonDisabled || isLoadingNotificationDetails}
          isLoading={isLoadingNotificationDetails}
        />
      </div>
      <div className="notification-WO-tab__table">
        <TableTemplate
          data={notifications}
          handleFormatCells={defectNotificationTableFormatting}
          showSearchBar={false}
          tableConfig={defectNotificationsTableConfig}
          showInfo={false}
          onRowClick={onNotificationClick}
          noDataText={t('NOTIFICATIONS_FORM.WO_EMPTY_STATE')}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

DefectNotifications.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default DefectNotifications;
