import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { each, isEmpty } from 'lodash';

import Helpers from '../../helpers';

import Icon from '../../icon/components/icon';
import '../styles/input.scss';

const events = ['change', 'cut', 'paste', 'drop', 'keydown'];

export default class Textarea extends Component {
  constructor(props) {
    super(props);

    const currentCount = (props.input.value && props.input.value.length) || 0;

    this.state = {
      charCount: Helpers.getCharLabel(currentCount, props.maxChars),
      initialCharCountUpdated: false,
    };
    this.node = React.createRef();
  }

  componentDidMount = () => {
    const { enableAutoResize } = this.props;

    if (enableAutoResize) {
      this.addEventListeners();
    }
  };

  componentDidUpdate = () => {
    const { input, maxChars, enableAutoResize } = this.props;
    const { initialCharCountUpdated, charCount } = this.state;

    if (input?.value && !initialCharCountUpdated) {
      this.setState({ charCount: Helpers.getCharLabel(input.value.length, maxChars), initialCharCountUpdated: true });
    } else if (!input?.value && initialCharCountUpdated) {
      let currentCharCount = charCount.split('/');

      if (currentCharCount && currentCharCount.length > 1 && currentCharCount[0]) {
        currentCharCount = parseInt(currentCharCount[0], 10);

        if (currentCharCount) {
          this.setState({ charCount: Helpers.getCharLabel(0, maxChars), initialCharCountUpdated: true });
        }
      }
    } else if (input?.value && initialCharCountUpdated) {
      // check if the char count updated is correct
      let currentCharCount = charCount.split('/');

      // if the updated char count is correct return
      if (parseInt(currentCharCount[0], 10) === input.value.length) return;

      // else update the char count
      this.setState({ charCount: Helpers.getCharLabel(input.value.length, maxChars) });
    }

    if (enableAutoResize) {
      this.addEventListeners();
    }
  };

  componentWillUnmount = () => {
    const { current } = this.node;

    each(events, event => {
      current.removeEventListener(event, this.resizeWithTimeout, true);
    });
  };

  addEventListeners = () => {
    const { current } = this.node;

    if (current && current.scrollHeight) {
      each(events, event => {
        current.addEventListener(event, this.resizeWithTimeout, true);
      });
      this.resize();
    } else {
      //retry if events are not attached
      setTimeout(this.addEventListeners, 1000);
    }
  };

  resize = () => {
    const { current } = this.node;

    //create virtual element in order to avoid scrollbar jumping on main element
    if (!isEmpty(current)) {
      const el = current.cloneNode(true);
      const styles = getComputedStyle(current);

      const css = styles.cssText ? styles.cssText : Object.values(styles).reduce((css, propertyName) => `${css}${propertyName}:${styles.getPropertyValue(propertyName)};`);

      el.style.cssText = css;
      el.style.height = 'auto';
      el.style.visibility = 'hidden';

      document.body.appendChild(el);

      const height = el.scrollHeight;
      current.style.height = `${height}px`;

      document.body.removeChild(el);
    }
  };

  resizeWithTimeout = () => setTimeout(this.resize, 100);

  getCharCount = e => {
    const currentCount = (e.target && e.target.value && e.target.value.length) || 0;
    const { maxChars } = this.props;

    this.setState({ charCount: Helpers.getCharLabel(currentCount, maxChars) });
  };

  render() {
    const { t } = this.context;
    const { charCount } = this.state;

    const {
      input,
      placeholder,
      name,
      id,
      label,
      sublabel,
      labelClass,
      disabled,
      isHidden,
      rows,
      cols,
      meta,
      className,
      wrapperClassName,
      maxChars,
      enableAutoResize,
      onlyDisabled,
      withHiddenError,
      isRequired,
      showAtSign = false,
      onAtSignClick,
      atSignVerticalCentered,
    } = this.props;

    return (
      <div
        className={`field-wrapper${disabled ? ' field-wrapper--disabled' : ''}${onlyDisabled ? ' field-wrapper--only-disabled' : ''} ${isHidden ? 'hidden-field' : ''} ${wrapperClassName || ''} ${
          enableAutoResize ? 'auto-resize' : ''
        } textarea`}
      >
        {label && (
          <label className={`field-wrapper__label ${labelClass || ''}`} htmlFor={id}>
            {`${t(label)}${isRequired ? '*' : ''}`}
          </label>
        )}
        {sublabel && (
          <label className={`field-wrapper__label sublabel ${labelClass || 'f-secondary-dark'}`} htmlFor={id}>
            {sublabel}
          </label>
        )}
        <div className={`input-wrapper ${enableAutoResize ? 'auto-resize' : ''}`}>
          <textarea
            id={id}
            name={name}
            {...input}
            readOnly={disabled}
            disabled={disabled}
            rows={rows}
            cols={cols}
            placeholder={placeholder ? t(placeholder) : ''}
            className={(meta && meta.error && meta.touched ? 'invalid ' : '') + `${className || 'input-wrapper__input'}`}
            onKeyUp={this.getCharCount}
            ref={this.node}
          />
          {showAtSign && onAtSignClick && <Icon name="at-sign" className={`at-sign ${atSignVerticalCentered ? 'ver-center' : ''}`} active onClick={onAtSignClick} size="sm" />}
          <div className="additional-data">
            <label className="char-count">{charCount}</label>
            <p className={`error-message${meta && meta.error && meta.touched ? '--visible' : ''}  ${withHiddenError ? 'with-hidden-error-message' : ''}`}>
              {meta && meta.error && t(meta.error, { textAreaMaxChars: maxChars })}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

Textarea.contextTypes = {
  t: PropTypes.func.isRequired,
};
