export default {
  inspection: {
    STATUS: 'Status',
  },
};

export const projectStatus = {
  NEW: 'NEW',
  READY: 'READY',
};

export const projectTypes = {
  // new: {
  //   name: 'new',
  //   title: 'PROJECTS.NEW',
  //   customFilter: p => p.Active && (!p.Inspections || !p.Inspections[0]),
  // },
  active: {
    name: 'active',
    title: 'PROJECTS.ACTIVE',
    customFilter: p => p.Active,
  },
  archived: {
    name: 'archived',
    title: 'PROJECTS.ARCHIVED',
    customFilter: p => !p.Active,
  },
};

export const sortingOptions = {
  sortByColumn: 'SortByColumn',
  order: 'SortDirection',
  search: 'SearchText',
};

export const projectSortData = [
  {
    label: 'PROJECT_FILTER.NAME',
    value: 'Name',
  },
  {
    label: 'PROJECT_FILTER.DATE',
    value: 'InspectionDate',
  },
];

export const sortingOrder = {
  asc: 'ASC',
  desc: 'DESC',
  none: '',
};

export const tableConfig = [
  {
    title: 'PROJECT_FILTER.NAME',
    key: 'Name',
    enableSort: true,
    class: 'f-secondary-dark',
  },
  {
    title: 'PROJECT_FILTER.DATE',
    key: 'InspectionDate',
    enableSort: true,
    class: 'f-secondary-dark move-right',
  },
];
