import { API_BASE_URL } from '../../common/constants';
import { Api } from '../../core/api';
import { generateQueryParams } from '../helpers/helper';
import routesConstants from './routes';

const createChecklistProcedure = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CREATE_CHECKLIST_PROCEDURE}`, data);
};

const fetchChecklistsProcedures = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_CHECKLISTS_PROCEDURES}`, data);
};

const deleteChecklistProcedure = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_CHECKLIST_PROCEDURE}`, data);
};

const fetchChecklistProcedure = async id => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_CHECKLIST_PROCEDURE}?checklist_and_procedure_template_id=${id}`);
};

const updateChecklistProcedure = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_CHECKLIST_PROCEDURE}`, data);
};

const changeChecklistProcedureStatus = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CHANGE_CHECKLIST_PROCEDURE_STATUS}`, data);
};

const updateSection = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_SECTION}`, data);
};

const fetchChecklistProcedureSections = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_CHECKLIST_PROCEDURE_SECTIONS}`, data);
};

const fetchChecklistProcedureSectionsV2 = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_CHECKLIST_PROCEDURE_SECTIONS_V2}`, data);
};

const fetchChecklistProcedureSectionQuestions = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_CHECKLIST_PROCEDURE_SECTION_QUESTIONS}`, data);
};

const addSectionQuestion = async sectionId => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_SECTION_QUESTION}`, { SectionID: sectionId });
};

const deleteSection = async sectionId => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_SECTION}`, { SectionID: sectionId });
};

const addSection = async checklistProcedureId => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_SECTION}`, { ChecklistAndProcedureTemplateID: checklistProcedureId });
};

const updateSectionQuestion = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_SECTION_QUESTION}`, data);
};

const deleteSectionQuestion = async sectionQuestionId => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_SECTION_QUESTION}`, { SectionQuestionID: sectionQuestionId });
};

const fetchQuestionTypes = async () => {
  return await Api.get(`${API_BASE_URL}${routesConstants.FETCH_QUESTION_TYPES}`);
};

const addQuestionComponent = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_QUESTION_COMPONENT}`, data);
};

const deleteQuestionComponent = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_QUESTION_COMPONENT}`, data);
};

const addQuestionWorkArea = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_QUESTION_WORK_AREA}`, data);
};

const deleteQuestionWorkArea = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_QUESTION_WORK_AREA}`, data);
};

const updateQuestionWorkArea = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_QUESTION_WORK_AREA}`, data);
};

const addQuestionOption = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_QUESTION_OPTION}`, data);
};

const updateQuestionOption = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_QUESTION_OPTION}`, data);
};

const deleteQuestionOption = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_QUESTION_OPTION}`, data);
};

const orderSections = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ORDER_SECTIONS}`, data);
};

const orderSectionQuestions = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ORDER_SECTION_QUESTIONS}`, data);
};

const changeChecklistProcedureStatusDraft = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CHANGE_CHECKLIST_PROCEDURE_STATUS_DRAFT}`, data);
};

const copyChecklistProcedure = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.COPY_CHECKLIST_PROCEDURE}`, data);
};

const deleteSectionQuestionFile = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_SECTION_QUESTION_FILE}`, data);
};

const getChecklistProceduresCustomPropertyNames = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_CHECKLIST_PROCEDURES_CUSTOM_PROPERTY_NAMES}`, data);
};

const getChecklistProceduresCustomProperties = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_CHECKLIST_PROCEDURES_CUSTOM_PROPERTIES}`, data);
};

const checklistProceduresCustomPropertiesAdd = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CHECKLIST_PROCEDURES_CUSTOM_PROPERTIES_ADD}`, data);
};

const checklistProceduresCustomPropertiesUpdate = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CHECKLIST_PROCEDURES_CUSTOM_PROPERTIES_UPDATE}`, data);
};

const checklistProceduresCustomPropertiesDelete = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CHECKLIST_PROCEDURES_CUSTOM_PROPERTIES_DELETE}`, data);
};

const addQuestionTagLocation = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_QUESTION_TAG_LOCATION}`, data);
};

const generatePDF = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GENERATE_PDF}${generateQueryParams(params)}`, { responseType: 'blob' });
};

const fetchChecklistQuestionFiles = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.FETCH_CHECKLIST_QUESTION_FILES}${generateQueryParams(params)}`);
};

export default {
  createChecklistProcedure,
  fetchChecklistsProcedures,
  deleteChecklistProcedure,
  fetchChecklistProcedure,
  updateChecklistProcedure,
  changeChecklistProcedureStatus,
  updateSection,
  fetchChecklistProcedureSections,
  addSectionQuestion,
  deleteSection,
  addSection,
  updateSectionQuestion,
  deleteSectionQuestion,
  fetchQuestionTypes,
  addQuestionComponent,
  deleteQuestionComponent,
  addQuestionWorkArea,
  deleteQuestionWorkArea,
  updateQuestionWorkArea,
  addQuestionOption,
  updateQuestionOption,
  deleteQuestionOption,
  orderSections,
  orderSectionQuestions,
  changeChecklistProcedureStatusDraft,
  copyChecklistProcedure,
  deleteSectionQuestionFile,
  getChecklistProceduresCustomPropertyNames,
  getChecklistProceduresCustomProperties,
  checklistProceduresCustomPropertiesAdd,
  checklistProceduresCustomPropertiesUpdate,
  checklistProceduresCustomPropertiesDelete,
  addQuestionTagLocation,
  generatePDF,
  fetchChecklistQuestionFiles,
  fetchChecklistProcedureSectionsV2,
  fetchChecklistProcedureSectionQuestions,
};
