import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import CustomSelect from '../../../../../common/form/components/select';
import CubeShapeForm from './cube-shape-form';

import { isEqual } from 'lodash';
import Button from '../../../../../common/form/components/button';
import { defectType } from '../../../constants/defect-constants';
import { defaultModelDetails, modelTypes, shapeTypeDropdownData, zoneAreaConstants } from '../../../constants/explosive-zones-constants';
import AreaMeasurementForm from './area-measurement-form';
import CylinderShapeForm from './cylinder-shape-form';
import RingShapeForm from './ring-shape-form';
import SphereShapeForm from './sphere-shape-form';

class BaseShapeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };
  }

  componentDidMount() {
    const { initialValues } = this.props;
    const modelDetails = initialValues[zoneAreaConstants.fields.modelDetails];
    this.setState({
      data: {
        ...initialValues,
        [zoneAreaConstants.fields.modelDetails]: { ...defaultModelDetails[initialValues.Geometry.type], ...(modelDetails || {}) },
      },
    });
  }

  componentDidUpdate = prevProps => {
    const { initialValues } = this.props;
    // used mostly to update the state if the 3D position or colour is edited in the parent component
    if (!isEqual(prevProps.initialValues, initialValues)) {
      this.setState({
        data: initialValues,
      });
    }
  };

  handleInputChange = (fieldName, value) => {
    const { handleChange } = this.props;
    this.setState(
      state => ({ data: { ...state.data, [fieldName]: value } }),
      () => {
        if (handleChange) {
          handleChange(this.state.data, { [fieldName]: value });
        }
      }
    );
  };

  handleModalDetailsChange = (fieldName, value) => {
    const { handleChange } = this.props;
    this.setState(
      state => ({ data: { ...state.data, [zoneAreaConstants.fields.modelDetails]: { ...state.data[zoneAreaConstants.fields.modelDetails], [fieldName]: value } } }),
      () => {
        if (handleChange) {
          handleChange(this.state.data, { [zoneAreaConstants.fields.modelDetails]: { ...this.state.data[zoneAreaConstants.fields.modelDetails], [fieldName]: value } });
        }
      }
    );
  };

  handleShapeTypeChange = selectedType => {
    const { handleChange, setSelectedType } = this.props;

    if (setSelectedType) {
      setSelectedType(selectedType.modelType);
    }
    this.setState(
      state => ({
        data: {
          ...state.data,
          Geometry: { type: selectedType.value, coordinates: this.state.data.Geometry.coordinates },
          [zoneAreaConstants.fields.modelDetails]: defaultModelDetails[selectedType.value],
        },
      }),
      () => {
        if (handleChange) {
          handleChange(this.state.data, {
            Geometry: { type: selectedType.value, coordinates: this.state.data.Geometry.coordinates },
            [zoneAreaConstants.fields.modelDetails]: defaultModelDetails[selectedType.value],
          });
        }
      }
    );
  };

  renderFormButtons = () => {
    const { t } = this.context;
    const { isAdding, isAddingLocation } = this.props;

    if (!isAdding) {
      // editing
      return (
        <div className="editing-location">
          <Button width="lg" height="md" text={t('MARK_ON_3D.EDIT.FINISH_BUTTON.TEXT')} />
        </div>
      );
    }
    return <>{isAdding && !isAddingLocation && <Button width="lg" height="md" text={t('ADD_ZONE_AREA.FORM_MARK_ON_3D')} />}</>;
  };

  renderShapeForm = (selectedType, dropdownAction = null) => {
    const { Potree } = window;
    const { t } = this.context;
    const { handleModalDetailsChange, handleInputChange } = this;
    const { data } = this.state;
    const { isAdding, handleSubmit, disabled, nameField, isAddingLocation, viewer, finishEditing } = this.props;

    const formProps = {
      data,
      onSubmit: values => (!isAdding ? finishEditing({ ...values, ...data }, dropdownAction) : handleSubmit({ ...values, ...data }, dropdownAction)),
      isAdding,
      isAddingLocation,
      handleModalDetailsChange,
      handleInputChange,
      disabled,
      nameField,
      dropdownAction,
      viewer,
      FormButtons: props => this.renderFormButtons({ ...formProps, ...props }, { t }),
    };

    switch (selectedType) {
      case Potree.ShapeTypes.cube:
        return <CubeShapeForm {...formProps} />;
      case Potree.ShapeTypes.sphere:
        return <SphereShapeForm {...formProps} />;
      case Potree.ShapeTypes.cylinder:
        return <CylinderShapeForm {...formProps} />;
      case Potree.ShapeTypes.ring:
        return <RingShapeForm {...formProps} />;
      case defectType.area:
        return <AreaMeasurementForm {...formProps} />;
      default:
        return <AreaMeasurementForm {...formProps} />;
    }
  };

  render() {
    const { t } = this.context;
    const { disabled, showOnly2D, isAdding, isAddingLocation } = this.props;
    const { data } = this.state;
    const selectedShapeType = shapeTypeDropdownData.find(el => (data.Geometry ? el.value === data.Geometry.type : false)) || shapeTypeDropdownData[0];
    const dropdownData = shapeTypeDropdownData.map(item => ({ ...item, label: t(item.label) }));
    const filteredDropdownData = showOnly2D ? dropdownData.filter(value => value.modelType === modelTypes._2D) : dropdownData;

    return (
      <Fragment>
        <CustomSelect
          input={{
            onChange: selectedType => {
              this.handleShapeTypeChange(selectedType);
            },
            value: selectedShapeType,
          }}
          placeholder={t('WORK_ORDER_DETAILS.FORM_TYPE')}
          label={t('WORK_ORDER_DETAILS.FORM_TYPE')}
          labelClass="f-secondary-dark defect-form__label"
          data={filteredDropdownData}
          disabled={disabled || !isAdding || isAddingLocation}
          disabledItems={disabled || !isAdding ? filteredDropdownData : null}
          size="lg"
          isRequired
          isEnhancedDesignCustomProp
        />
        {this.renderShapeForm(selectedShapeType.value, selectedShapeType.dropdownAction)}
      </Fragment>
    );
  }
}

BaseShapeForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

BaseShapeForm.defaultProps = {
  nameField: zoneAreaConstants.fields.name,
  finishEditing: () => null,
};

export default BaseShapeForm;
