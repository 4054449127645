import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { find } from 'lodash';

import { Field, reduxForm } from 'redux-form';

import CustomInput from '../../../common/form/components/input';
import Button from '../../../common/form/components/button';

import { validate } from '../validators/code-validators';

import loginConstants from '../../../app/login/constants/login-constants';
import { FORMS } from '../../../common/constants';

import '../styles/verification-form.scss';

class VerificationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timer: 0,
    };
    this.node = React.createRef();
  }

  componentDidMount = () => {
    const { current } = this.node;
    current.addEventListener(
      'keydown',
      e => {
        //if enter is pressed submit the form
        if (e.keyCode === 13) {
          e.preventDefault();

          const button = find(current.children, item => {
            if (item && item.type === 'submit') {
              return item;
            }
          });
          button && button.click();
        }
      },
      false
    );
  };

  setTimer = expirationTime => {
    this.setState({ timer: expirationTime });

    const interval = setInterval(() => {
      const { timer } = this.state;
      const newTime = timer - 1000;
      this.setState({ timer: newTime });

      if (newTime <= 0) {
        clearInterval(interval);
      }
    }, 1000);
  };

  render() {
    const { t } = this.context;
    const { timer } = this.state;
    const { title, subtitle, handleSubmit, resend, resendExpiration, invalid, anyTouched, cancelClick, enableTimer } = this.props;

    return (
      <Fragment>
        <form onSubmit={handleSubmit} className="verification-form" ref={this.node}>
          {title && <h5 className="f-primary noselect center"> {title}</h5>}
          {subtitle && <p className="f-primary noselect center">{subtitle}</p>}
          <Field
            id={loginConstants.verificationFields.code}
            name={loginConstants.verificationFields.code}
            label="LOGIN.EMAIL_CODE_FIELD_LABEL"
            component={CustomInput}
            type="number"
            placeholder="LOGIN.EMAIL_CODE_FIELD_PLACEHOLDER"
            autoFocus
          />
          {resend && (
            <Button
              type="button"
              disabled={timer > 0}
              variant="gray-outline"
              className="noselect resend-button"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                if (timer <= 0) {
                  resend(() => {
                    enableTimer && this.setTimer(resendExpiration);
                  });
                }
              }}
              text={t(timer > 0 ? `LOGIN.RESEND_NEW_CODE_AFTER` : 'LOGIN.RESEND_NEW_CODE', { timer: timer / 1000 })}
            />
          )}

          <Button disabled={invalid && anyTouched} text={t('LOGIN.NEXT')} />
          <Button type="button" onClick={cancelClick} variant="gray-outline" text={t('LOGIN.CANCEL')} />
        </form>
      </Fragment>
    );
  }
}

VerificationForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

VerificationForm = reduxForm({
  form: FORMS.emailVerification,
  validate,
})(VerificationForm);

export default VerificationForm;
