export const filterProps = {
  searchText: 'SearchText',
  sortByColumn: 'SortByColumn',
  sortDirection: 'SortDirection',
  dateFrom: 'DateFrom',
  dateTo: 'DateTo',
  lastSeen: 'LastSeen',
  perPage: 'PerPage',
  hasNext: 'HasNext',
  totalItems: 'TotalNumber',
};

export const observationFields = {
  icon: 'icon',
  name: 'Name',
  status: 'Status',
  severity: 'Severity',
  createdAt: 'CreatedAt',
  dateCreated: 'DateCreated',
};

export const sortDirection = {
  asc: 'ASC',
  desc: 'DESC',
};

export const reorderDirection = {
  up: 'UP',
  down: 'DOWN',
};

export const defaultFilters = {
  [filterProps.searchText]: '',
  [filterProps.perPage]: 10,
  [filterProps.lastSeen]: 0,
  [filterProps.sortDirection]: sortDirection.asc,
  [filterProps.sortByColumn]: observationFields.name,
};

export const observationsTableConfig = [
  {
    title: '',
    key: observationFields.status,
    type: observationFields.icon,
    class: 'icon-column',
  },
  {
    title: 'OBSERVATIONS.TITLE',
    key: observationFields.name,
    type: observationFields.name,
    class: 'name-column__observations',
    isSort: true,
  },
  {
    title: 'TABLE.DEFECT_SEVERITY',
    key: observationFields.severity,
    type: observationFields.severity,
    class: 'severity-column',
    isSort: true,
  },
  {
    title: 'OBSERVATIONS.REPORTED_DATE',
    key: observationFields.createdAt,
    type: observationFields.createdAt,
    class: 'created-at-column',
    isSort: true,
  },
];
