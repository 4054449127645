import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { find } from 'lodash';

import Tabs from '../../../common/tabs/component/tabs';
import Tab from '../../../common/tabs/component/tab';
import Users from './users/users';
import Teams from './teams/teams';
import Roles from './roles/roles';

import { routes } from '../../../common/routes-constants';
import Helpers from '../../../common/helpers';

import '../styles/members.scss';

class Members extends Component {
  constructor(props) {
    super(props);

    this.state = {
      membersTabs: {
        users: {
          name: 'users',
          key: routes.protectedRoutes.members.subPaths.users.fullPath,
          title: 'MEMBERS.USERS',
        },
        teams: {
          name: 'teams',
          key: routes.protectedRoutes.members.subPaths.teams.fullPath,
          title: 'MEMBERS.TEAMS',
        },
        roles: {
          name: 'roles',
          key: routes.protectedRoutes.members.subPaths.roles.fullPath,
          title: 'MEMBERS.ROLES',
        },
      },
    };
  }

  render() {
    const { membersTabs } = this.state;

    const detaultKey = (
      find(membersTabs, item => {
        return item.key === this.props.location.pathname;
      }) || membersTabs.users
    ).key;

    return (
      <div className="members">
        <Tabs
          onChange={key => {
            Helpers.goTo(key);
          }}
          defaultTabKey={detaultKey}
        >
          <Tab title={membersTabs.users.title} tabKey={membersTabs.users.key}>
            <Users />
          </Tab>
          <Tab title={membersTabs.teams.title} tabKey={membersTabs.teams.key}>
            <Teams />
          </Tab>
          <Tab title={membersTabs.roles.title} tabKey={membersTabs.roles.key}>
            <Roles />
          </Tab>
        </Tabs>
      </div>
    );
  }
}

Members.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Members;
