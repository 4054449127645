import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import DatePicker from '../../../../common/form/components/date-picker';
import BasicInput from '../../../../common/form/components/input';
import CustomSelect from '../../../../common/form/components/select';

import { dateFields } from './constants/section-form-constants';
import sectionsValidator from './validators/sections-validator';
import Helpers from '../../../../common/helpers';
import { connect } from 'react-redux';

const timeIntervalItems = [
  { ID: 'HOUR', Name: 'Hour' },
  { ID: 'DAY', Name: 'Day' },
  { ID: 'WEEK', Name: 'Week' },
  { ID: 'MONTH', Name: 'Month' },
  { ID: 'YEAR', Name: 'Year' },
];
let DatesSectionForm = ({ disabled }) => {
  const { startDate, endDate, revalidationTime, timeInterval } = dateFields;

  return (
    <form noValidate>
      <Field
        id={startDate.id}
        name={startDate.name}
        label={startDate.label}
        component={DatePicker}
        placeholder={startDate.placeholder}
        disabled={disabled}
        disabledKeyboardNavigation
        dateFormat="MMMM d, yyyy h:mm aa"
        timeFormat="h:mm aa"
        showTimeSelect
        validateUsingReduxForm
        isRequired
        size="lg"
      />
      <Field
        id={endDate.id}
        name={endDate.name}
        label={endDate.label}
        component={DatePicker}
        placeholder={endDate.placeholder}
        disabled={disabled}
        disabledKeyboardNavigation
        dateFormat="MMMM d, yyyy h:mm aa"
        timeFormat="h:mm aa"
        showTimeSelect
        validateUsingReduxForm
        isRequired
        isEnd
        size="lg"
      />
      <Field
        id={revalidationTime.id}
        normalize={value => Helpers.parseDecimal(value)}
        name={revalidationTime.name}
        component={BasicInput}
        placeholder={revalidationTime.placeholder}
        label={revalidationTime.label}
        disabled={disabled}
        type="number"
        size="lg"
      />
      <Field
        id={timeInterval.id}
        name={timeInterval.name}
        label={timeInterval.label}
        disabled={disabled}
        component={CustomSelect}
        data={timeIntervalItems}
        textField="Name"
        valueField="ID"
        defaultValue={'HOUR'}
        size="lg"
      />
    </form>
  );
};

DatesSectionForm.propTypes = {
  disabled: PropTypes.bool,
};

const mapStateToProps = (state, props) => {
  return {
    initialValues: {
      ...props.initialValues,
      [dateFields.startDate.name]: props.initialValues[dateFields.startDate.name] ? new Date(props.initialValues[dateFields.startDate.name] * 1000) : null,
      [dateFields.endDate.name]: props.initialValues[dateFields.endDate.name] ? new Date(props.initialValues[dateFields.endDate.name] * 1000) : null,
    },
  };
};

DatesSectionForm = reduxForm({
  validate: sectionsValidator.datesValidate,
  enableReinitialize: true,
  touchOnChange: true,
})(DatesSectionForm);

export default connect(mapStateToProps, null)(DatesSectionForm);
