import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { reduxForm, Field } from 'redux-form';

import { debounce, keys } from 'lodash';

import MultiSelect from '../../../../common/form/components/multiselect';
import Button from '../../../../common/form/components/button';
import DatePicker from '../../../../common/form/components/date-picker';

import { getUsers } from '../../../members/actions/users-data-actions';
import { getPermitTemplatesFilters } from '../../actions/permit-actions';

import Helpers from '../../../../common/helpers';

import { FORMS } from '../../../../common/constants';

import { filterFields as templateFilterFields, defaultFilter } from './constants/permit-form-constants';
import { filterProps as filterFields } from './constants/permit-constants';

import '../../styles/defects-filter.scss';

class PermitsFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templates: [],
    };
    this.getPermitTemplatesDebounced = debounce(this.getPermitTemplatesFilters, 300);
    this.getUsersDebounced = debounce(props.getUsers, 300);
  }

  componentDidMount = () => {
    const { getUsers } = this.props;

    this.getPermitTemplatesFilters('');
    getUsers();
  };

  getPermitTemplatesFilters = searchText => {
    const { getPermitTemplatesFilters, projectId } = this.props;
    getPermitTemplatesFilters({ ...defaultFilter, [templateFilterFields.perPage]: Number.MAX_SAFE_INTEGER, ProjectID: projectId, [templateFilterFields.searchText]: searchText }, templates =>
      this.setState({ templates })
    );
  };

  render() {
    const { t } = this.context;

    const { templates } = this.state;
    const { handleSubmit, resetFilter, users, formValues, submitForm } = this.props;

    return (
      <form
        className="filter-form"
        onSubmit={handleSubmit(vals => {
          submitForm(Helpers.removeUnusedKeys(vals));
        })}
      >
        <div className="scrollable">
          <div className="grid">
            <Field
              label={'PERMIT_FILTERS.PERMIT_TEMPLATE'}
              placeholder={'PERMIT_FILTERS.PERMIT_TEMPLATE'}
              id={filterFields.permitType}
              name={filterFields.permitType}
              component={MultiSelect}
              valueField={'Name'}
              textField={'Name'}
              data={templates || []}
              onSearch={text => {
                this.getPermitTemplatesDebounced(text);
              }}
              onToggle={isOpen => {
                if (isOpen && templates?.length === 0) {
                  this.getPermitTemplatesDebounced('');
                }
              }}
            />
          </div>

          <div className="grid">
            <Field
              disabledKeyboardNavigation={true}
              dateFormat="MMMM d, yyyy"
              id={filterFields.dateFrom}
              name={filterFields.dateFrom}
              component={DatePicker}
              maxDate={formValues?.[filterFields.dateTo] || null}
              popperPlacement="bottom-end"
              placeholder={'PERMIT_FILTERS.DATE_FROM'}
              label={'PERMIT_FILTERS.DATE_FROM'}
              isClearable={true}
            />
            <Field
              disabledKeyboardNavigation={true}
              dateFormat="MMMM d, yyyy"
              id={filterFields.dateTo}
              name={filterFields.dateTo}
              component={DatePicker}
              minDate={formValues?.[filterFields.dateFrom] || null}
              popperPlacement="bottom-end"
              placeholder={'PERMIT_FILTERS.DATE_TO'}
              label={'PERMIT_FILTERS.DATE_TO'}
              isClearable={true}
            />
            <Field
              label={'PERMIT_FILTERS.CREATED_BY'}
              placeholder={'PERMIT_FILTERS.CREATED_BY'}
              id={filterFields.createdBy}
              name={filterFields.createdBy}
              component={MultiSelect}
              data={users || []}
              valueField={'UserID'}
              textField={'UserName'}
              onSearch={text => {
                this.getUsersDebounced(text);
              }}
              onToggle={isOpen => {
                if (isOpen && (!users || users.length === 0)) {
                  this.getUsersDebounced('');
                }
              }}
            />
          </div>

          <div className="grid">
            <Field
              disabledKeyboardNavigation={true}
              dateFormat="MMMM d, yyyy"
              id={filterFields.issuedDateFrom}
              name={filterFields.issuedDateFrom}
              component={DatePicker}
              maxDate={formValues?.[filterFields.issuedDateTo] || null}
              popperPlacement="bottom-end"
              placeholder={'PERMIT_FILTERS.ISSUED_DATE_FROM'}
              label={'PERMIT_FILTERS.ISSUED_DATE_FROM'}
              isClearable={true}
            />
            <Field
              disabledKeyboardNavigation={true}
              dateFormat="MMMM d, yyyy"
              id={filterFields.issuedDateTo}
              name={filterFields.issuedDateTo}
              component={DatePicker}
              minDate={formValues?.[filterFields.issuedDateFrom] || null}
              popperPlacement="bottom-end"
              placeholder={'PERMIT_FILTERS.ISSUED_DATE_TO'}
              label={'PERMIT_FILTERS.ISSUED_DATE_TO'}
              isClearable={true}
            />
            <Field
              label={'PERMIT_FILTERS.ISSUED_TO'}
              placeholder={'PERMIT_FILTERS.ISSUED_TO'}
              id={filterFields.issuedTo}
              name={filterFields.issuedTo}
              component={MultiSelect}
              data={users || []}
              valueField={'UserID'}
              textField={'UserName'}
              onSearch={text => {
                this.getUsersDebounced(text);
              }}
              onToggle={isOpen => {
                if (isOpen && (!users || users.length === 0)) {
                  this.getUsersDebounced('');
                }
              }}
            />
          </div>

          <div className="grid">
            <Field
              disabledKeyboardNavigation={true}
              dateFormat="MMMM d, yyyy"
              id={filterFields.handbackedDateFrom}
              name={filterFields.handbackedDateFrom}
              component={DatePicker}
              maxDate={formValues?.[filterFields.handbackedDateTo] || null}
              popperPlacement="bottom-end"
              placeholder={'PERMIT_FILTERS.HANDBACKED_DATE_FROM'}
              label={'PERMIT_FILTERS.HANDBACKED_DATE_FROM'}
              isClearable={true}
            />
            <Field
              disabledKeyboardNavigation={true}
              dateFormat="MMMM d, yyyy"
              id={filterFields.handbackedDateTo}
              name={filterFields.handbackedDateTo}
              minDate={formValues?.[filterFields.handbackedDateFrom] || null}
              component={DatePicker}
              popperPlacement="bottom-end"
              placeholder={'PERMIT_FILTERS.HANDBACKED_DATE_TO'}
              label={'PERMIT_FILTERS.HANDBACKED_DATE_TO'}
              isClearable={true}
            />
            <Field
              label={'PERMIT_FILTERS.HANDBACKED_TO'}
              placeholder={'PERMIT_FILTERS.HANDBACKED_TO'}
              id={filterFields.handbackedTo}
              name={filterFields.handbackedTo}
              component={MultiSelect}
              data={users || []}
              valueField={'UserID'}
              textField={'UserName'}
              onSearch={text => {
                this.getUsersDebounced(text);
              }}
              onToggle={isOpen => {
                if (isOpen && (!users || users.length === 0)) {
                  this.getUsersDebounced('');
                }
              }}
            />
          </div>
        </div>
        <div className="buttons">
          <Button type="button" variant="gray-outline" text={t('RESET_FILTERS')} onClick={resetFilter} />
          <Button text={t('APPLY_FILTERS', { count: keys(Helpers.removeUnusedKeys(formValues)).length || 0 })} />
        </div>
      </form>
    );
  }
}
PermitsFilter.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  getPermitTemplatesFilters: (params, callback) => dispatch(getPermitTemplatesFilters(params, callback)),
  getUsers: SearchText => dispatch(getUsers({ SearchText })),
});

const mapStateToProps = (state, { initialValues }) => {
  const {
    usersTableReducer: { usersTableData },
  } = state;

  return {
    users: usersTableData,
    formValues: state.form[FORMS.permitFilters]?.values || {},
    initialValues,
  };
};

PermitsFilter = reduxForm({
  form: FORMS.permitFilters,
  enableReinitialize: true,
})(PermitsFilter);

export default connect(mapStateToProps, mapDispatchToProps)(PermitsFilter);
