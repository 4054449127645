import actionTypes from '../constants/action-types';
import defectList, { TABS } from '../constants/defect-list-constants';
import { defaultInspectedImagesCount } from '../constants/image-statistic-constants';
import { Tools } from '@tarik.djurdjevic/react-sketch';

const inspectionState = {
  selectedDefect: null,
  defectComments: [],
  linkedDefect: null,
  defects: [],
  allDefects: [],
  imageDefects: [],
  componentDefects: [],
  defectsTemp: [],
  activeDefectsTab: TABS.single,
  activeStep: 0,
  currentImage: null,
  currentImageIndex: 0,
  newDefect: null,
  defectsTableParams: defectList.defectsDataParams,
  delImageDefectModalData: {
    isOpen: false,
  },
  defectsLoading: false,
  cameras: [],
  cameraPositionVisible: true,
  cameraThumbnailVisible: false,
  selectedTool: Tools.DefaultTool,
  drawings: null,
  selectedDefectLoader: false,
  activeCameraHelper: null,
  drawingsLoading: false,
  inspectedImagesCount: defaultInspectedImagesCount,
};

export const startWorkflowReducer = (state = inspectionState, payload) => {
  switch (payload.type) {
    case actionTypes.SET_SELECTED_DEFECT:
      return {
        ...state,
        selectedDefect: payload.data,
      };
    case actionTypes.SET_SELECTED_DEFECT_LOADER:
      return {
        ...state,
        selectedDefectLoader: payload.data,
      };
    case actionTypes.SET_DEFECTS_DATA:
      return {
        ...state,
        defects: payload.data.map(defect => ({ ...defect, visible: true })),
      };
    case actionTypes.SET_DEFECTS_DATA_LODADING:
      return {
        ...state,
        defectsLoading: payload.data,
      };
    case actionTypes.SET_DRAWINGS_DATA_LODADING:
      return {
        ...state,
        drawingsLoading: payload.data,
      };
    case actionTypes.SET_ALL_DEFECTS_DATA:
      return {
        ...state,
        allDefects: payload.data.map(defect => ({ ...defect, visible: true })),
      };
    case actionTypes.SET_IMAGE_DEFECTS_DATA:
      return {
        ...state,
        imageDefects: payload.data.map(defect => ({ ...defect, visible: true })),
      };
    case actionTypes.UPDATE_DEFECTS_DATA:
      return {
        ...state,
        defects: payload.data,
      };
    case actionTypes.SET_ACTIVE_DEFECTS_TAB:
      return { ...state, activeDefectsTab: payload.data };
    case actionTypes.SET_CURRENT_IMAGE:
      return { ...state, currentImage: payload.data.image, currentImageIndex: payload.data.index };
    case actionTypes.TOGGLE_WORKFLOW_DEFECT:
      return {
        ...state,
        defects: state.defects.map(defect => {
          return { ...defect, visible: defect.ID === payload.data ? !defect.visible : defect.visible };
        }),
      };
    case actionTypes.SELECT_ALL_WORKFLOW_DEFECTS:
      return {
        ...state,
        defects: state.defects.map(defect => ({ ...defect, visible: true })),
      };
    case actionTypes.SELECT_ALL_WORKFLOW_DEFECTS_TEMP:
      return {
        ...state,
        defectsTemp: state.defectsTemp.map(defect => ({ ...defect, visible: true })),
      };
    case actionTypes.TOGGLE_WORKFLOW_DEFECT_TEMP:
      return {
        ...state,
        defectsTemp: state.defectsTemp.map(defect => {
          return { ...defect, visible: defect.ID === payload.data ? !defect.visible : defect.visible };
        }),
      };
    case actionTypes.DESELECT_ALL_WORKFLOW_DEFECTS:
      return {
        ...state,
        defects: state.defects.map(defect => ({ ...defect, visible: false })),
      };
    case actionTypes.DESELECT_ALL_WORKFLOW_DEFECTS_TEMP:
      return {
        ...state,
        defectsTemp: state.defectsTemp.map(defect => ({ ...defect, visible: false })),
      };
    case actionTypes.SET_ACTIVE_STEP:
      return {
        ...state,
        activeStep: payload.data,
      };
    case actionTypes.NEXT_STEP:
      return {
        ...state,
        activeStep: state.activeStep + 1,
      };
    case actionTypes.PREVIOUS_STEP:
      return {
        ...state,
        activeStep: state.activeStep - 1,
      };
    case actionTypes.SET_NEW_DEFECT:
      return {
        ...state,
        newDefect: payload.data,
      };
    case actionTypes.SET_COMPONENT_DEFECTS:
      return {
        ...state,
        componentDefects: payload.data,
      };
    case actionTypes.SET_LINKED_DEFECT_DETAILS:
      return {
        ...state,
        linkedDefect: payload.data,
      };
    case actionTypes.SET_DEFECTS_DATA_TABLE_PARAMS:
      return { ...state, defectsTableParams: payload.data };
    case actionTypes.SET_COMMENTS_DATA:
      return { ...state, defectComments: payload.data };
    case actionTypes.HANDLE_DELETE_IMAGE_DEFECT_MODAL:
      return { ...state, delImageDefectModalData: payload.data };
    case actionTypes.TOGGLE_WORKFLOW_CAMERA:
      return {
        ...state,
        cameras: state.cameras.map(camera => {
          if (camera.ID === payload.data) {
            return { ...camera, visible: !camera.visible, withThumbnail: !camera.visible };
          } else return camera;
        }),
      };
    case actionTypes.SELECT_ALL_WORKFLOW_CAMERAS:
      return {
        ...state,
        cameraPositionVisible: true,
        cameraThumbnailVisible: true,
      };
    case actionTypes.DESELECT_ALL_WORKFLOW_CAMERAS:
      return {
        ...state,
        cameraPositionVisible: false,
        cameraThumbnailVisible: false,
      };
    case actionTypes.SELECT_ALL_WORKFLOW_CAMERAS_POSITIONS:
      return {
        ...state,
        cameraPositionVisible: true,
      };
    case actionTypes.DESELECT_ALL_WORKFLOW_CAMERAS_POSITIONS:
      return {
        ...state,
        cameraPositionVisible: false,
      };
    case actionTypes.SELECT_ALL_WORKFLOW_CAMERA_THUMBNAILS:
      return {
        ...state,
        cameraThumbnailVisible: true,
      };
    case actionTypes.DESELECT_ALL_WORKFLOW_CAMERA_THUMBNAILS:
      return {
        ...state,
        cameraThumbnailVisible: false,
      };
    case actionTypes.SET_IMAGE_TOOL:
      return {
        ...state,
        selectedTool: payload.data,
      };
    case actionTypes.SET_IMAGE_DRAWINGS:
      return {
        ...state,
        drawings: payload.data,
      };
    case actionTypes.SET_ACTIVE_CAMERA_HELPER:
      return {
        ...state,
        activeCameraHelper: payload.data,
      };
    case actionTypes.SET_IMAGE_COUNT_STATISTICS:
      return {
        ...state,
        inspectedImagesCount: payload.data,
      };
    default:
      return state;
  }
};
