import clarityLogo, { ReactComponent as logo } from '../assets/logo.svg';

import sliderImage5 from './assets/gr-IMG-3835.jpg';
import sliderImage6 from './assets/gr-IMG-3868.jpg';
import sliderImage1 from './assets/gr_2-DJI_0902-HDR.jpg';
import sliderImage2 from './assets/gr_3-DJI_0004-HDR.jpg';
import sliderImage3 from './assets/gr_4-DJI_0009-HDR.jpg';
import sliderImage4 from './assets/gr_5-DJI_0010-HDR.jpg';

export const raicom = {
  GOOGLE_API_KEY: 'AIzaSyAm6CHXBiungNUOEscRSdTnZSrFpe4WooQ',
  API_BASE_URL: 'https://raico-m-api.hybirdtech.com',
  API_PROCESSING_BASE_URL: 'https://raico-m-processing-api.hybirdtech.com',
  PUBLIC_URL: 'https://raico-m.hybirdtech.com',
  TERMS_LINK: 'https://www.hybirdtech.com/terms-and-conditions/',
  PRIVACY_AND_POLICY_LINK: 'https://www.hybirdtech.com/privacy-policy/',
  DROPBOX_CLIENT_ID: '19kg1w632t74jr0',
  DROPBOX_BASE_URL: 'https://www.dropbox.com/oauth2/authorize',
  SYNC_API_BASE_URL: 'https://raico-m-sync-api.hybirdtech.com',
  FAVICON: '/favicon.png',
  MAIN_LOGO: logo,
  HYBIRD_ID: 1,
  FOOTER_TEXT: 'FOOTER_TEXT.GREEN',
  FOOTER_LOGO: clarityLogo,
  HUBSPOT_PORTAL_ID: 8772031,
  HUBSPOT_BASE_URL: 'https://js.hs-scripts.com',
  POTREE_STATS_VISIBLE: false,
  LENGTH_UNIT: 'm',
  SHOW_GEOMETRY_MISSING_WARNING: true,
  FEATURES: {
    components: {
      visible: true,
      newFeature: true,
      comingSoon: false,
      tabs: {
        work: {
          visible: true,
        },
      },
    },
    workflowChartView: {
      visible: true,
      newFeature: false,
      comingSoon: false,
    },
    integrations: {
      visible: false,
      newFeature: false,
      comingSoon: false,
    },
    notifications: {
      visible: true,
      newFeature: false,
      comingSoon: false,
      externalID: {
        visible: true,
      },
    },
    workorders: {
      visible: true,
      newFeature: false,
      comingSoon: false,
      completionTab: {
        visible: true,
      },
      flowTab: {
        visible: true,
      },
      externalID: {
        visible: true,
      },
    },
    toolsButton: {
      visible: true,
      newFeature: false,
      comingSoon: false,
    },
    company: {
      visible: false,
      newFeature: false,
      comingSoon: false,
    },
    areas: {
      visible: true,
      newFeature: false,
      comingSoon: false,
    },
    addProject: {
      visible: false,
      newFeature: false,
      comingSoon: false,
    },
    addInspection: {
      visible: false,
      newFeature: false,
      comingSoon: false,
    },
    updateProject: {
      visible: true,
      newFeature: false,
      comingSoon: false,
    },
    updateInspection: {
      visible: false,
      newFeature: false,
      comingSoon: false,
    },
    deleteProject: {
      visible: false,
      newFeature: false,
      comingSoon: false,
    },
    deleteInspection: {
      visible: false,
      newFeature: false,
      comingSoon: false,
    },
    pdfTag: {
      visible: true,
      newFeature: false,
      comingSoon: false,
    },
    maintenanceRegime: {
      visible: true,
      newFeature: false,
      comingSoon: false,
      hideDuplicate: true,
    },
    workflow: {
      visible: false,
      newFeature: false,
      comingSoon: true,
    },
    corrosionDetection: {
      visible: true,
      newFeature: false,
      comingSoon: true,
    },
    modulesHistory: {
      visible: false,
      newFeature: false,
      comingSoon: false,
    },
    workManagement: {
      visible: true,
      newFeature: false,
      comingSoon: false,
    },
    inspectCameraFocus: {
      visible: false,
      newFeature: false,
      comingSoon: false,
    },
    projectSettings: {
      visible: true,
      newFeature: false,
      comingSoon: false,
    },
    contractors: {
      visible: true,
      newFeature: false,
      comingSoon: false,
    },
    keybox: {
      visible: true,
      newFeature: false,
      comingSoon: false,
    },
    permits: {
      visible: true,
      newFeature: false,
      comingSoon: false,
    },
    isolations: {
      visible: true,
      newFeature: false,
      comingSoon: false,
    },
    componentHierarchy: {
      visible: true,
      newFeature: true,
      comingSoon: false,
      assignComponents: {
        visible: true,
      },
      componentsModule: {
        visible: true,
      },
      componentFormFields: {
        visible: true,
      },
      chooseFromCH: {
        visible: true,
      },
    },
    settings360: {
      visible: true,
      newFeature: true,
      comingSoon: false,
    },
    documentManagement: {
      visible: true,
      newFeature: false,
      comingSoon: false,
    },
    checklistsAndProcedures: {
      visible: true,
    },
    tiling360Image: {
      visible: true,
      newFeature: false,
      comingSoon: false,
    },
    readingsAndGauges: {
      visible: false,
      newFeature: false,
      comingSoon: false,
    },
    clusteringSettings: {
      numberOfRings: 15,
      numberOfSlices: 6,
      initialDistance: 10,
      multiplier: 2,
    },
  },
  LOGIN_SLIDER_ITEMS: [
    {
      src: sliderImage1,
      name: 'slide1',
    },
    {
      src: sliderImage2,
      name: 'slide2',
    },
    {
      src: sliderImage3,
      name: 'slide3',
    },
    {
      src: sliderImage4,
      name: 'slide4',
    },
    {
      src: sliderImage5,
      name: 'slide5',
    },
    {
      src: sliderImage6,
      name: 'slide6',
    },
  ],
};
