import { cloneDeep } from 'lodash';
import actionTypes from './action-types';

// Helpers
export const updateElementInArray = (oldElement, oldElements) => {
  const index = oldElements.findIndex(element => element.ID === oldElement.ID);
  if (index > -1) {
    let newElements = cloneDeep(oldElements);
    newElements[index] = { ...oldElements[index], ...oldElement };
    return newElements;
  }
  return oldElements;
};

export const setDMSLabels = data => {
  return {
    data,
    type: actionTypes.SET_DMS_LABELS,
  };
};

export const setDMSLabelsLoading = data => {
  return {
    data,
    type: actionTypes.SET_DMS_LABELS_LOADING,
  };
};

export const setSelectedDMSFileLabels = data => {
  return {
    data,
    type: actionTypes.SET_SELECTED_DMS_FILE_LABELS,
  };
};

export const setVersionHistory = data => {
  return {
    data,
    type: actionTypes.SET_VERSION_HISTORY,
  };
};

export const setVersionHistoryLoading = data => {
  return {
    data,
    type: actionTypes.SET_VERSION_HISTORY_LOADING,
  };
};

export const addVersionHistoryItem = data => {
  return {
    data,
    type: actionTypes.ADD_VERSION_HISTORY_ITEM,
  };
};

export const setNewActiveVersion = data => {
  return {
    data,
    type: actionTypes.SET_NEW_ACTIVE_VERSION,
  };
};

export const updateVersionDetails = data => {
  return {
    data,
    type: actionTypes.UPDATE_VERSION_DETAILS,
  };
};
