import Helpers from '../../../../../common/helpers';
import Icon from '../../../../../common/icon/components/icon';
import { fields, severityColors, statuses } from '../constants/constants';

const statusesIconMap = {
  [statuses.open.value]: {
    icon: statuses.open.icon,
    props: statuses.open.iconProps,
  },
  [statuses.actioned.value]: {
    icon: statuses.actioned.icon,
    props: statuses.actioned.iconProps,
  },
  [statuses.closed.value]: {
    icon: statuses.closed.icon,
    props: statuses.closed.iconProps,
  },
};

export const formatObservationsTableCells = (
  value,
  type,
  searchTerm,
  row,
  onDropdownClick,
  _t,
  _pointerDotID,
  _user,
  _onToggleClick,
  _onDeleteRow,
  _statusData,
  _handleChangeStatus,
  onLinkFileClick,
  selectedItem
) => {
  if (type === fields.icon) {
    return <Icon name={statusesIconMap[value].icon} {...statusesIconMap[value].props} size="sm" />;
  } else if (type === fields.dateCreated) {
    return <p className="f-primary">{Helpers.getDateFromUnix(value, 'D MMM, YYYY')}</p>;
  } else if (type === fields.severity) {
    return <div className={`severity-icon ${severityColors[value] || 'severity-green'}`}>{value}</div>;
  } else if (type === fields.name) {
    return (
      <p className="f-primary" title={value}>
        {Helpers.getSearchTermText(Helpers.getShortenedText(value, 25), searchTerm)}
      </p>
    );
  } else {
    return value ? value : '-';
  }
};
