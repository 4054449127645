import React from 'react';
import '../../styles/add-observations.scss';
import { connect } from 'react-redux';
import { fetchObservationsForWorkOrders, linkObservationToWorkOrder, unlinkObservationToWorkOrder } from '../../actions/work-order-actions';
import ObservationItemRow from './add-observations/observation-item-row';
import { setWorkOrderObservations } from '../../actions/action-creators';
import AddModuleItemModal from '../../../../common/add-module-item-modal/components/add-module-item-modal';

const AddObservationsToWorkOrder = props => {
  const { linkedObservations, fetchObservationsForWorkOrders, linkObservationToWorkOrder, unlinkObservationToWorkOrder, setObservationsInReducer, customCloseAction } = props;

  return (
    <AddModuleItemModal
      ItemRowComponent={ObservationItemRow}
      linkedModuleItems={linkedObservations}
      customCloseAction={customCloseAction}
      moduleItemTitle="INSPECTION_DEFECTS"
      emptyStateLabel="OBSERVATION_SEARCH.EMPTY_STATE"
      fetchModuleItems={fetchObservationsForWorkOrders}
      linkCall={linkObservationToWorkOrder}
      unlinkCall={unlinkObservationToWorkOrder}
      setModuleItemsInReducer={setObservationsInReducer}
    />
  );
};

const mapDispatchToProps = (dispatch, props) => ({
  fetchObservationsForWorkOrders: (searchText, pagingFilters, callback, loadingCallback) =>
    dispatch(fetchObservationsForWorkOrders(props.workOrderID, props.inspectionId, searchText, pagingFilters, callback, loadingCallback)),
  linkObservationToWorkOrder: (defectID, callback) => dispatch(linkObservationToWorkOrder(defectID, props.workOrderID, callback)),
  unlinkObservationToWorkOrder: (defectID, callback) => dispatch(unlinkObservationToWorkOrder(defectID, props.workOrderID, callback)),
  setObservationsInReducer: observations => dispatch(setWorkOrderObservations(observations)),
});

export default connect(null, mapDispatchToProps)(AddObservationsToWorkOrder);
