import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../styles/input.scss';
import Helpers from '../../helpers';

export default class CheckBox extends Component {
  render() {
    const { t } = this.context;
    const { input, meta, className, id, label, labelClass, disabled, isHidden, checkboxText, grayCheckboxText, CustomLabel, onChange, isRequired, shortenText = false, ...restprops } = this.props;

    return (
      <div className={`field-wrapper${disabled ? ' field-wrapper--disabled' : ''} ${isHidden ? 'hidden-field' : ''}`}>
        {label && (
          <label className={`field-wrapper__label ${labelClass || ''}`} htmlFor={id}>
            {`${t(label)}${isRequired ? '*' : ''}`}
          </label>
        )}
        <div className="input-wrapper">
          <div className="checkbox-wrapper">
            <input {...restprops} {...input} checked={Boolean(input.value)} id={id} disabled={disabled} type={'checkbox'} className={`${className || 'input-wrapper__input'}`} />
            {checkboxText && (
              // on hover displays full text
              <label className={`field-wrapper__label`} title={t(checkboxText)}>
                {/* display three dots if text longer than 13 characters (mostly for multianswer options) */}
                {shortenText ? Helpers.getShortenedText(t(checkboxText), 13) : t(checkboxText)}
              </label>
            )}
            {grayCheckboxText && <label className={`field-wrapper__label text-gray`}>{t(grayCheckboxText)}</label>}
            {CustomLabel}
          </div>
          <div className={`error-message${meta && meta.error && meta.touched ? '--visible' : ''}`}>{meta && meta.error && t(meta.error)}</div>
        </div>
      </div>
    );
  }
}

CheckBox.contextTypes = {
  t: PropTypes.func.isRequired,
};
