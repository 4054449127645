import '../../../component-hierarchy/styles/ch-right-side.scss';
import '../../styles/dms-right-side.scss';
import Loader from '../../../../common/global-loader/components/simple-loader';
import RenderIf from '../../../../common/render-if/components/render-if';
import DmsRightSideFolderBasicForm from './dms-right-side-folder-basic-form';
import DmsRightSideFileBasicForm from './dms-right-side-file-basic.form';

const DMSRightSide = (props, context) => {
  const {
    isLoading,
    initialValues,
    isDisabled,
    isFolderSelected,
    user,
    openChangeLocationModal,
    statusData,
    onFileStatusChange,
    openEditFunction,
    openEditLabelsModal,
    openFile,
    downloadFile,
    handleFormSubmit,
    onModuleItemDeepLinkHandler,
    formHasUnsavedChanges,
    onFormChange,
    onUploadFileClick,
    versionHistoryModalRef,
    updateSelectedHierarchyDetailsData,
    openEditVersionModal,
    handleOnDropdownClick,
  } = props;
  return (
    <div className="ch-right-side dms-right-side">
      <RenderIf if={isLoading}>
        <Loader isLoading={isLoading} />
      </RenderIf>
      {/* folder details */}
      <RenderIf if={!isLoading && isFolderSelected}>
        <DmsRightSideFolderBasicForm
          initialValues={initialValues}
          handleFormSubmit={handleFormSubmit}
          isDisabled={isDisabled}
          formHasUnsavedChanges={formHasUnsavedChanges}
          onFormChange={onFormChange}
        />
      </RenderIf>
      {/* file details */}
      <RenderIf if={!isLoading && !isFolderSelected}>
        <DmsRightSideFileBasicForm
          initialValues={initialValues}
          handleFormSubmit={handleFormSubmit}
          isDisabled={isDisabled}
          user={user}
          openChangeLocationModal={openChangeLocationModal}
          statusData={statusData}
          onFileStatusChange={onFileStatusChange}
          onEditIconClick={openEditFunction}
          openEditLabelsModal={openEditLabelsModal}
          openFile={openFile}
          downloadFile={downloadFile}
          onModuleItemDeepLinkHandler={onModuleItemDeepLinkHandler}
          formHasUnsavedChanges={formHasUnsavedChanges}
          onFormChange={onFormChange}
          onUploadFileClick={onUploadFileClick}
          onDeleteFileClick={handleOnDropdownClick}
          versionHistoryModalRef={versionHistoryModalRef}
          updateSelectedHierarchyDetailsData={updateSelectedHierarchyDetailsData}
          openEditVersionModal={openEditVersionModal}
          handleOnDropdownClick={handleOnDropdownClick}
        />
      </RenderIf>
    </div>
  );
};
export default DMSRightSide;
