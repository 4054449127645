import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { find, values } from 'lodash';

import ItemsTableRenderer from '../../../../../common/items-renderer/components/items-table-renderer';
import DisplayStatus from '../../../../../common/display-status/components/display-status';
import SearchInput from '../../../../../common/input/components/search-input';
import Icon from '../../../../../common/icon/components/icon';

import Helpers from '../../../../../common/helpers';
import { tableConfig, filterProps, defaultFilters, fields, initialValuesProp } from '../constants/isolation-certificate-constants';
import { getPermitIsolationCertificates } from '../../../actions/permit-actions';
import { statuses } from '../../isolation-certificates/constants/isolation-certificates-table';

import '../../../styles/isolation-certificates-modal.scss';

class IsolationCertificateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isolationCertificates: [],
      filters: { ...defaultFilters, PermitID: props.module['ID'] },
    };
  }

  componentDidMount() {
    const { filters } = this.state;
    this.fetchIsolationCertificates(filters, false);
  }

  fetchIsolationCertificates = (params, loadMore) => {
    const { isolationCertificates: currentIsolationCertificates } = this.state;
    const { getPermitIsolationCertificates } = this.props;
    params[filterProps.lastSeen] = loadMore ? params[filterProps.lastSeen] : defaultFilters[filterProps.lastSeen];

    getPermitIsolationCertificates(params, currentIsolationCertificates, loadMore, ({ filters, isolationCertificates }) => this.setState({ filters, isolationCertificates }));
  };

  handleSearch = SearchText => {
    const { filters } = this.state;
    const newParams = {
      ...filters,
      [filterProps.lastSeen]: defaultFilters[filterProps.lastSeen],
      SearchText,
    };
    this.fetchIsolationCertificates(newParams);
  };

  render() {
    const { t } = this.context;
    const { addIsolationCertificate, removeIsolationCertificate, module } = this.props;
    const { isolationCertificates, filters } = this.state;
    const addedIsolationCertificates = module[initialValuesProp] || [];

    return (
      <div className="isolation-certificates-modal">
        <SearchInput
          onChange={e => this.handleSearch(e.target.value)}
          label={'PERMIT.ISOLATION_CERTIFICATE_MODAL.SEARCH_LABEL'}
          placeholder={t('PERMIT.ISOLATION_CERTIFICATE_MODAL.SEARCH_PLACEHOLDER')}
          includeDropdown
          items={isolationCertificates}
          emptyStateLabel={'PERMIT.ISOLATION_CERTIFICATE_MODAL.EMPTY_STATE_SEARCH'}
          renderItem={(item, index) => {
            const isDisabled = find(addedIsolationCertificates, i => item[fields.id.name] === i[fields.id.name]);
            return (
              <p key={index} className={`pointer ${isDisabled ? 'disabled' : ''}`} onClick={isDisabled ? () => null : () => addIsolationCertificate(module, item)}>
                {item[fields.name.name]}
              </p>
            );
          }}
        />
        <p className="f-secondary-dark">{t('ISOLATION_CERTIFICATE.ADD_COMPONENTS_TITLE', { number: addedIsolationCertificates.length })}</p>
        <div className="table-container">
          <ItemsTableRenderer
            isLoading={false}
            tableConfig={[
              ...tableConfig,
              {
                title: '',
                CustomComponent: (data, itemIndex) => (
                  <div className="component-list__table-actions">
                    <Icon name="close" onClick={() => removeIsolationCertificate(module, data)} />
                  </div>
                ),
                enableSort: false,
              },
            ]}
            translationModule={t}
            data={addedIsolationCertificates}
            onRowClick={() => null}
            sortingObj={{
              [filterProps.sortByColumn]: filters[filterProps.sortByColumn],
              [filterProps.sortDirection]: filters[filterProps.sortDirection],
            }}
            formatCell={(value, type, index, item) => {
              if (type === 'status') {
                return <DisplayStatus statuses={values(statuses)} status={value} />;
              }

              return Helpers.formatCell(value, type, index, item);
            }}
            sortByColumnProp={filterProps.sortByColumn}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  getPermitIsolationCertificates: (params, isolationCertificates, loadMore, callback) => dispatch(getPermitIsolationCertificates(params, isolationCertificates, loadMore, callback)),
});
IsolationCertificateModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

IsolationCertificateModal = connect(mapStateToProps, mapDispatchToProps)(IsolationCertificateModal);

export default IsolationCertificateModal;
