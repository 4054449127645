import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import Checkbox from '../../../../common/form/components/checkbox';
import Helpers from '../../../../common/helpers';
import Icon from '../../../../common/icon/components/icon';
import { ComponentPicker } from '../../../../common/question-components/components';
import InfoTooltip from '../../../../common/tooltip/components/info-tooltip';
import { componentDetailsDisplayProps, componentFields, formConstants } from './constants/constants';

import '../../styles/work-order-components.scss';

const WorkOrderComponents = (
  {
    components,
    workorderComponents,
    addComponentToWorkorder,
    handleComponentPicker3D,
    handleComponentPickerPDF,
    handleComponentPickerCH,
    removeComponentFromWorkorder,
    closeConfirmModal,
    hideCheckbox,
    searchInputLabel,
    perComponentLabel,
    perComponentFieldName,
    disabled,
    searchPlaceholder,
  },
  { t }
) => {
  return (
    <div className="work-order-components">
      <div className="input-with-dropdown">
        {!disabled && components && components.length > 0 && (
          <ComponentPicker
            components={components}
            questionId={1}
            handleComponentSelected={(_moduleId, components) => addComponentToWorkorder(components)}
            handleComponentPicker3D={() => handleComponentPicker3D()}
            handleComponentPickerPDF={() => handleComponentPickerPDF()}
            handleComponentPickerCH={() => handleComponentPickerCH()}
            selectedComponents={workorderComponents}
            searchInputLabel={searchInputLabel || 'WORKORDER_SECTION_TITLE.COMPONENTS'}
            searchPlaceholder={searchPlaceholder}
          />
        )}
      </div>
      {!hideCheckbox && (
        <Field
          id={perComponentFieldName || formConstants.fields.perComponent}
          name={perComponentFieldName || formConstants.fields.perComponent}
          labelClass="f-secondary-dark"
          component={Checkbox}
          checkboxText={perComponentLabel || 'CREATE_WORKORDER_FORM.PER_COMPONENT'}
          disabled={disabled || workorderComponents?.length < 1}
        />
      )}
      <div className="added-items">
        <p className="f-secondary-dark bold">{t('CREATE_WORKORDER_FORM.ADDED_COMPONENTS', { number: workorderComponents ? workorderComponents.length : 0 })}</p>
        <div className="added-items-list">
          {workorderComponents &&
            workorderComponents.map((item, index) => {
              return (
                <div className="item-r" key={index}>
                  <p className="f-primary c-title">{`${index + 1}. ${item[componentFields.name]}`}</p>
                  <InfoTooltip
                    actionsMenu={Helpers.mapInfoIconDisplayProps(item, componentDetailsDisplayProps)}
                    offsetY={8}
                    offsetX={8}
                    Component={() => <Icon name="info" size="sm" />}
                    componentProps={{ title: '' }}
                  />
                  {!disabled && <Icon name="close" className="x-hover pointer" onClick={() => removeComponentFromWorkorder(item, closeConfirmModal)} />}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

WorkOrderComponents.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default WorkOrderComponents;
