import { PERMISSIONS, PERMISSION_TYPES } from '../../../common/permissions-constants';
import { sortDirections } from '../../inspections/constants/work-order-constants';

export const formConstants = {
  fields: {
    UserID: 'UserID',
    id: 'ID',
    title: 'Name',
    description: 'Description',
    dateCreated: 'CreatedAt',
    interval: 'IntervalName',
    startDate: 'StartDate',
    components: 'Components',
    descripton: 'Description',
    importance: 'Importance',
    endDate: 'EndDate',
    intervalId: 'TaskIntervalID',
    intervalNumber: 'IntervalNumber',
    intervalEnabled: 'IntervalEnabled',
    notificationAmount: 'notificationAmount',
    notificationType: 'notificationType',
    nextMaintenanceTask: 'NextTask',
    nextMaintenanceTaskStart: 'NextTaskStart',
    inspectionId: 'InspectionID',
    status: 'Status',
    ghostComponents: 'GhostComponents',
    ghostChecklists: 'GhostChecklist',
    plainInterval: 'Interval',
    source: 'Source',
    toolsRequired: 'ToolsRequired',
    estimatedWrenchTime: 'EstimatedWrenchTime',
    perComponent: 'PerComponent',
    recurringTask: 'RecurringTask',
    taskIntervalNumber: 'TaskIntervalNumber',
    taskIntervalId: 'IntervalID',
    occurancesTreshold: 'OccurancesTreshold',
    ends: 'ends',
    intervalName: 'IntervalName',
    intervalTaskName: 'IntervalTaskName',
    checklists: 'Checklists',
    createdBy: 'CreatedByID',
    dueDate: 'DueDate',
    dateShort: 'dateShort',
    statusText: 'statusText',
    additionalSubType: 'AdditionalSubType',
    critical: 'Critical',
    createdByUser: 'CreatedBy',
  },
};

export const formFields = {
  name: {
    name: formConstants.fields.title,
    label: 'CREATE_REGIME_FORM.TITLE',
    id: 'maintenance-regime-basic-details-name',
  },
  description: {
    name: formConstants.fields.description,
    label: 'CREATE_REGIME_FORM.DESCRIPTION',
    id: 'maintenance-regime-basic-details-description',
    maxChars: 250,
  },
  toolsRequired: {
    name: formConstants.fields.toolsRequired,
    label: 'CREATE_REGIME_FORM.TOOLS_REQUIRED',
    id: 'maintenance-regime-basic-details-tools-required',
    maxChars: 250,
  },
  intervalNumber: {
    name: formConstants.fields.taskIntervalNumber,
    label: 'CREATE_REGIME_FORM.TASK_DURATION',
    id: 'maintenance-regime-basic-details-task-duration',
  },
  intervalName: {
    name: formConstants.fields.intervalTaskName,
    label: 'CREATE_REGIME_FORM.TASK_DURATION_INTERVAL',
    id: 'maintenance-regime-basic-details-task-duration-interval',
  },
  estimatedWrenchTime: {
    name: formConstants.fields.estimatedWrenchTime,
    label: 'CREATE_REGIME_FORM.ESTIMATED_WRENCH_TIME',
    id: 'maintenance-regime-basic-details-estimated-wrench-time',
  },
};

export const textAreaMaxChars = 2000;

export const steps = {
  firstStep: 1,
  secondStep: 2,
  thirdStep: 3,
  fourthStep: 4,
  fifthStep: 5,
  sixthStep: 6,
};

export const filterFields = {
  projectID: 'ProjectID',
  inspectionID: 'InspectionID',
  searchText: 'SearchText',
  sortBy: 'SortBy',
  sortByColumn: 'SortByColumn',
  sortByDirection: 'SortByDirection',
  sortDirection: 'SortDirection',
  perPage: 'PerPage',
  lastSeen: 'LastSeen',
  totalItems: 'TotalNumber',
  hasNext: 'HasNext',
  assignedToMeFilter: 'AssignedToLoggedUserFilter',
  startInNextWeekFilter: 'StartInNextWeekFilter',
  startInThirtyDaysFilter: 'StartInThirtyDaysFilter',
  onHold: 'OnHoldFilter',
  archived: 'ArchivedFilter',
  overdueFilter: 'OverdueFilter',
};

export const sortDirection = {
  asc: 'ASC',
  desc: 'DESC',
};

export const defaultSortingFilterRegime = {
  [filterFields.sortByColumn]: formConstants.fields.dueDate,
  [filterFields.sortDirection]: sortDirections.asc,
};

export const defaultFilter = {
  [filterFields.searchText]: '',
  [filterFields.sortByColumn]: formConstants.fields.id,
  [filterFields.sortByDirection]: sortDirection.desc,
  [filterFields.perPage]: 20,
  [filterFields.lastSeen]: 0,
  [filterFields.totalItems]: 0,
};

export const defaultPagingObjRegime = {
  [filterFields.lastSeen]: 0,
  [filterFields.perPage]: 10,
  [filterFields.totalItems]: 0,
  [filterFields.hasNext]: false,
};

export const fileGroups = {
  checklistFiles: 'CHECKLIST_FILES',
  otherFiles: 'OTHER_FILES',
  images: 'IMAGES',
  permits: 'PERMITS',
  otherImages: 'OTHER_IMAGES',
  regimeFiles: 'OTHER_FILES',
};

export const fileTypes = {
  regimeFiles: 'MAINTENANCE_REGIME_FILES',
};

export const MRFileGroups = {
  otherFiles: 'OTHER_FILES',
  images: 'IMAGES',
  checklistFiles: 'CHECKLIST_FILES',
  otherImages: 'OTHER_IMAGES',
};

export const MRUploadType = 'MAINTENANCE_REGIME_FILES';

export const fileFields = {
  id: 'FileID',
  name: 'FileName',
};

export const componentFields = {
  id: 'ID',
  name: 'Name',
  compID: 'ComponentID',
  compName: 'ComponentName',
};

export const intervalFields = {
  name: 'Name',
  code: 'Code',
  id: 'ID',
};

export const intervalListMaxValue = 60;

export const tableIntervalCombineKey = 'combine-interval';

export const regimeStatus = {
  confirmed: 'CONFIRMED',
};

export const componentSearchCharLimitation = 1;

export const sources = {
  notification: 'NOTIFICATION',
  workOrder: 'WORK-ORDER',
};

export const advancedDetailsMaxChars = {
  description: 250,
  toolsRequired: 250,
};

export const imagesValidation = {
  maxFiles: 5,
  value: 50,
  unit: 'mb',
  bytes: 52428800,
};

export const endCheckBoxValues = {
  on: 'ON',
  off: 'OFF',
};

export const statuses = {
  draft: {
    value: 'DRAFT',
    title: 'MAINTENANCE_REGIME.DRAFT',
    icon: 'clock',
    skipPermissionCheck: true,
    goToStatuses: ['RAISED'],
    isRegularStatus: true,
    modalTitle: 'MAINTENANCE_REGIME_STATUS.DRAFT_TITLE',
    editPermission: PERMISSIONS[PERMISSION_TYPES.maintenanceRegimes].edit.name,
    ownershipPermission: PERMISSIONS[PERMISSION_TYPES.maintenanceRegimes].create.name,
  },
  raised: {
    value: 'RAISED',
    title: 'MAINTENANCE_REGIME.RAISED',
    icon: 'clock-filled',
    editPermission: PERMISSIONS[PERMISSION_TYPES.maintenanceRegimes].edit.name,
    transitionPermission: PERMISSIONS[PERMISSION_TYPES.maintenanceRegimes].edit.name,
    ownershipPermission: PERMISSIONS[PERMISSION_TYPES.maintenanceRegimes].create.name,
    goToStatuses: ['DRAFT', 'LIVE'],
    isRegularStatus: true,
    modalTitle: 'MAINTENANCE_REGIME_STATUS.RAISED_TITLE',
  },
  live: {
    value: 'LIVE',
    title: 'MAINTENANCE_REGIME.LIVE',
    icon: 'checkmark',
    iconProps: {
      active: true,
    },
    editPermission: PERMISSIONS[PERMISSION_TYPES.maintenanceRegimes].edit.name,
    transitionPermission: PERMISSIONS[PERMISSION_TYPES.maintenanceRegimes].edit.name,
    goToStatuses: ['RAISED'],
    modalTitle: 'MAINTENANCE_REGIME_STATUS.LIVE_TITLE',
    sectionName: 'Live',
  },
};

export const confirmationModalTypes = {
  duplicate: 'duplicate',
  delete: 'delete',
};

export const confirmationModalContent = {
  [confirmationModalTypes.changeStatus]: {
    content: 'MAINTENANCE_REGIME_STATUS.CHANGE_STATUS_ACTION',
  },
  [confirmationModalTypes.duplicate]: {
    content: 'MAINTENANCE_REGIME_DUPLICATE.DUPLICATE_ACTION_TITLE',
  },
  [confirmationModalTypes.delete]: {
    content: 'MAINTENANCE_REGIME_DELETE.DELETE_ACTION_TITLE',
  },
};

export const regimeNameMaxChars = 200;

export const formattedIntervals = {
  hour: 'Hourly',
  day: 'Daily',
  week: 'Weekly',
  month: 'Monthly',
  year: 'Yearly',
};

export const regimeWOstatuses = {
  draft: {
    value: 'DRAFT',
    title: 'WORK_ORDERS.DRAFT',
    icon: 'clock',
    goToStatuses: ['RAISED'],
    isRegularStatus: true,
    modalTitle: 'ISOLATION_CERTIFICATE_STATUS.DRAFT_TITLE',
    editPermission: PERMISSIONS[PERMISSION_TYPES.workOrders].edit.name,
    ownershipPermission: PERMISSIONS[PERMISSION_TYPES.workOrders].create.name,
    skipPermissionCheck: true,
  },
  raised: {
    value: 'RAISED',
    title: 'WORK_ORDERS.RAISED',
    icon: 'clock-filled',
    editPermission: PERMISSIONS[PERMISSION_TYPES.workOrders].edit.name,
    transitionPermission: PERMISSIONS[PERMISSION_TYPES.workOrders].edit.name,
    ownershipPermission: PERMISSIONS[PERMISSION_TYPES.workOrders].create.name,
    goToStatuses: ['APPROVED'],
    isRegularStatus: true,
  },
  approved: {
    value: 'APPROVED',
    title: 'WORK_ORDERS.APPROVED',
    icon: 'checkmark-rounded',
    iconProps: {
      active: true,
    },
    iconFilledWhenActive: true,
    editPermission: PERMISSIONS[PERMISSION_TYPES.workOrders].approveEdit.name,
    transitionPermission: PERMISSIONS[PERMISSION_TYPES.workOrders].toApprove.name,
    goToStatuses: ['AUTHORISED', 'COMPLETED', 'CLOSED'],
    isRegularStatus: true,
  },
  rejected: {
    value: 'REJECTED',
    title: 'WORK_ORDERS.REJECTED',
    icon: 'alert-octagon',
    editPermission: PERMISSIONS[PERMISSION_TYPES.workOrders].edit.name,
    transitionPermission: PERMISSIONS[PERMISSION_TYPES.workOrders].edit.name,
    ownershipPermission: PERMISSIONS[PERMISSION_TYPES.workOrders].create.name,
    fromStatuses: ['RAISED', 'APPROVED', 'AUTHORISED'],
    isRegularStatus: true,
    goToStatuses: ['DRAFT'],
  },
  authorised: {
    value: 'AUTHORISED',
    title: 'WORK_ORDERS.AUTHORISED',
    icon: 'check-rounded',
    iconProps: {
      active: true,
    },
    editPermission: PERMISSIONS[PERMISSION_TYPES.workOrders].authoriseEdit.name,
    transitionPermission: PERMISSIONS[PERMISSION_TYPES.workOrders].toAuthorise.name,
    goToStatuses: ['IN-PROGRESS', 'COMPLETED', 'CLOSED'],
    isRegularStatus: true,
  },
  inProgress: {
    value: 'IN-PROGRESS',
    title: 'WORK_ORDERS.IN_PROGRESS',
    icon: 'in-progress',
    iconProps: {
      active: true,
    },
    iconFilledWhenActive: true,
    editPermission: PERMISSIONS[PERMISSION_TYPES.workOrders].inProgressEdit.name,
    transitionPermission: PERMISSIONS[PERMISSION_TYPES.workOrders].toInProgress.name,
    goToStatuses: ['COMPLETED', 'CLOSED'],
    isRegularStatus: true,
  },
  completed: {
    value: 'COMPLETED',
    title: 'WORK_ORDERS.COMPLETED',
    icon: 'checkmark',
    iconProps: {
      active: true,
    },
    transitionPermission: PERMISSIONS[PERMISSION_TYPES.workOrders].toComplete.name,
    isFinalStatus: true,
  },
  closed: {
    value: 'CLOSED',
    title: 'WORK_ORDERS.CLOSED',
    icon: 'close-filled',
    iconProps: {
      active: true,
    },
    transitionPermission: PERMISSIONS[PERMISSION_TYPES.workOrders].toClose.name,
    isRegularStatus: true,
    isFinalStatus: true,
  },
  startInNextWeek: {
    value: filterFields.startInNextWeekFilter,
    title: 'WORK_ORDERS.START_IN_NEXT_WEEK',
    isSingleSelect: true,
    includeInStatuses: true,
  },
  startInNextMonth: {
    value: filterFields.startInThirtyDaysFilter,
    title: 'WORK_ORDERS.START_IN_NEXT_MONTH',
    isSingleSelect: true,
    includeInStatuses: true,
  },
  onHold: {
    value: filterFields.onHold,
    title: 'WORK_ORDERS.ON_HOLD',
    isSingleSelect: true,
    includeInStatuses: true,
  },
  archived: {
    value: filterFields.archived,
    title: 'WORK_ORDERS.ARCHIVED',
    isSingleSelect: true,
    includeInStatuses: true,
  },
  overdueFilter: {
    value: filterFields.overdueFilter,
    title: 'WORKORDER_FILTERS.OVERDUE',
    isSingleSelect: true,
    includeInStatuses: true,
  },
};
