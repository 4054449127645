import routesConstants from './routes';
import { Api } from '../../core/api';
import { API_BASE_URL } from '../../common/constants';
import { generateQueryParams } from '../helpers/helper';

export const getContractorsList = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_CONTRACTORS_LIST}`, data);
};

export const getContractorDetails = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_CONTRACTOR_DETAILS}${generateQueryParams(params)}`);
};

export const createContractor = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CREATE_CONTRACTOR}`, data, { skipErrorModal: true });
};

export const updateContractor = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_CONTRACTOR}`, data);
};

export const updateStatus = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_CONTRACTOR_STATUS}`, data);
};

export const deleteContractor = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_CONTRACTOR}`, data);
};

export const getContractorFiles = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_CONTRACTOR_FILES}${generateQueryParams(params)}`);
};

export const deleteContractorFile = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UNLINK_CONTRACTOR_FILE}`, data);
};

export const getUsers = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_USERS}`, data);
};

export const restoreContractor = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.RESTORE_CONTRACTOR}`, data);
};

export default {
  getContractorsList,
  getContractorDetails,
  createContractor,
  updateContractor,
  deleteContractor,
  getContractorFiles,
  deleteContractorFile,
  updateStatus,
  getUsers,
  restoreContractor,
};
