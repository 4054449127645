import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { find } from 'lodash';

import routesConstants from '../../../common/routes-constants';
import { helpkitURL, userbackSelector } from '../constants/navigation-constants';

import AccessRenderer from '../../../common/access-renderer/components/access-renderer';
import Helpers from '../../../common/helpers';
import Icon from '../../../common/icon/components/icon';

import { history } from '../../../core/routes';

class NavigationItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activePath: '',
      navigationItemsTop: [
        {
          name: 'project',
          icon: props => <Icon name="projects" {...props} />,
          path: routesConstants.routes.protectedRoutes.project.fullPath,
          visibleFor: routesConstants.routes.protectedRoutes.project.visibleFor,
          onClick: () => this.goTo(routesConstants.routes.protectedRoutes.project.fullPath),
          title: 'NAVIGATION.PROJECT',
        },
        {
          name: 'members',
          icon: props => <Icon name="users" {...props} />,
          path: routesConstants.routes.protectedRoutes.members.fullPath,
          visibleFor: routesConstants.routes.protectedRoutes.members.visibleFor,
          onClick: () => this.goTo(routesConstants.routes.protectedRoutes.members.fullPath),
          title: 'NAVIGATION.MEMBERS',
        },
      ],
      navigationItemsBottom: [
        {
          name: 'feedback',
          icon: props => <Icon name="feedback" {...props} />,
          onClick: () => {
            const button = document.querySelector(`${userbackSelector}`);
            button?.click?.();
          },
          title: 'NAVIGATION.FEEDBACK',
        },
        {
          name: 'question',
          icon: props => <Icon name="help-circle" {...props} />,
          onClick: () => {
            const button = window.open(`${helpkitURL}`);
            button?.click?.();
          },
          title: 'NAVIGATION.HELP',
        },
        {
          name: 'profile',
          icon: props => <Icon name="profile" {...props} />,
          path: routesConstants.routes.protectedRoutes.profile.fullPath,
          visibleFor: routesConstants.routes.protectedRoutes.profile.visibleFor,
          onClick: () => this.goTo(routesConstants.routes.protectedRoutes.profile.fullPath),
          title: 'NAVIGATION.PROFILE',
        },
        {
          name: 'logout',
          icon: props => <Icon name="logout" {...props} />,
          onClick: () => null,
          isEsential: true,
          title: 'NAVIGATION.LOGOUT',
        },
      ],
    };
  }

  componentDidMount() {
    const location = window.location.href;

    this.setActive({ pathname: location });
    history.listen(route => this.setActive(route));
  }

  setActive = ({ pathname }) => {
    const { navigationItemsTop, navigationItemsBottom } = this.state;
    const navigationItems = [...navigationItemsTop, ...navigationItemsBottom];

    let currentRoute = find(navigationItems, item => {
      return pathname.indexOf(item.path) > -1;
    });
    currentRoute = currentRoute?.path || navigationItems[0].path;

    this.setState({ activePath: currentRoute });
  };

  isActive = path => {
    if (!path) return false;

    const { activePath } = this.state;

    return activePath === path;
  };

  goTo = path => {
    this.setState({ activePath: path });
    Helpers.goTo(path);
  };

  renderItems = items => {
    const { t } = this.context;
    const { logoutProps, renderEssential } = this.props;
    return items.map((item, index) => {
      if (renderEssential && !item.isEsential) {
        return null;
      }
      if (item.name === 'logout') {
        item = { ...item, ...logoutProps };
      }

      const { visibleFor, onClick, path, isHidden, title } = item;

      return (
        <AccessRenderer visibleFor={visibleFor} key={index}>
          {({ hasAccess }) => {
            return hasAccess && !isHidden ? (
              <div className={`menu-item`} onClick={onClick} data-cy={item.name}>
                <item.icon active={this.isActive(path)} title={(title && t(title)) || ''} />
              </div>
            ) : null;
          }}
        </AccessRenderer>
      );
    });
  };

  render() {
    const { navigationItemsTop, navigationItemsBottom } = this.state;
    return (
      <div className="main-menu">
        <div className="top">{this.renderItems(navigationItemsTop)}</div>
        <div className="bottom">{this.renderItems(navigationItemsBottom)}</div>
      </div>
    );
  }
}

NavigationItems.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default NavigationItems;
