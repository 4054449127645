import PropTypes from 'prop-types';
import React from 'react';

class Tab extends React.Component {
  render() {
    console.error(new Error("The `Tab` component is not meant to be rendered! It's an abstract component that is only valid as a direct Child of the `Tabs` Component. "));
    // eslint-disable-next-line no-unreachable
    return null;
  }
}

Tab.propTypes = {
  title: PropTypes.node.isRequired,
  tabKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Tab.defaultProps = {
  visible: true,
};

export default Tab;
