import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Portal from '../portal/components/portal';
import Popover from './components/popover';
import './styles/pointer-dot.scss';

class PointerDot extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hovered: false,
      pageX: 0,
      pageY: 0,
    };
  }
  handleHover = e => {
    const { pageX, pageY } = e;
    this.setState({ hovered: true, pageX, pageY });
  };
  handleHoverOut = () => {
    this.setState({ hovered: false });
  };
  render() {
    const { type, text, ignoreTheme, popoverClassName } = this.props;
    const { hovered, pageX, pageY } = this.state;
    return (
      <div className={`pointer-dot`}>
        <div className={`pointer-dot__outer ${type ? type : ''}`} onMouseOver={this.handleHover} onMouseOut={this.handleHoverOut}>
          <div className={`pointer-dot__inner ${type ? type : ''}`} />
        </div>
        <Portal shouldRender>
          <Popover className={popoverClassName} text={text} hovered={hovered} pageX={pageX} pageY={pageY} pageXOffset={10} pageYOffset={10} ignoreTheme={ignoreTheme} />
        </Portal>
      </div>
    );
  }
}

PointerDot.propTypes = {
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

PointerDot.defaultProps = {
  ignoreTheme: false,
};

export default PointerDot;
