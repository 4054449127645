import { fields, keyFields } from '../constants/constants';

export const validateFirstStep = values => {
  const errors = {};

  if (!values[fields.name]) {
    errors[fields.name] = 'KEYBOX_NAME.REQUIRED';
  }

  if (values[fields.name] && values[fields.name].length < 3) {
    errors[fields.name] = 'KEYBOX_NAME.MIN_LENGTH';
  }

  if (values[fields.name] && values[fields.name].length > 20) {
    errors[fields.name] = 'KEYBOX_NAME.MAX_LENGTH';
  }

  if (values[fields.description] && values[fields.description].length > 2000) {
    errors[fields.description] = 'KEYBOX_DESCRIPTION.MAX_LENGTH';
  }

  return errors;
};

export const validateSecondStep = (values, props) => {
  const errors = {};
  const options = props?.options;

  if (options && options.length > 0) {
    for (let i = 0; i < options.length; i++) {
      const fieldName = `${fields.keys}-${keyFields.name}-${options[i][keyFields.id]}`;

      if (!values[fieldName]) {
        errors[fieldName] = 'KEY_NAME.MIN_LENGTH';
      }
    }
  }

  return errors;
};
