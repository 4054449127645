import { openNavigation, closeNavigation, setScrollPosition } from './action-creators';

export const toggleNav = () => {
  return (dispatch, getState) => {
    const {
      navReducer: { opened },
    } = getState();
    if (opened) {
      dispatch(closeNavigation());
    } else {
      dispatch(openNavigation());
    }
  };
};

export const setWindowScrollPosition = pos => {
  return dispatch => {
    dispatch(setScrollPosition(pos));
  };
};
