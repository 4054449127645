import actionTypes from '../constants/action-types';

const projectDetailsState = {
  DefaultComponentDMSCategoryID: null,
  DefaultObservationDMSCategoryID: null,
  DefaultNotificationDMSCategoryID: null,
  DefaultWorkorderDMSCategoryID: null,
  DefaultReadingGroupID: null,
  NumberOfInspections: null,
  ProjectName: '',
  ProjectID: null,
  projectDMSCategories: [],
  isLoading: false,
  DefaultUnassignedDMSFolderID: null,
  DefaultComponentUnassignedDMSFolderID: null,
  DefaultReadingsAndGaugesUnassignedDMSFolderID: null,
  DefaultObservationstUnassignedDMSFolderID: null,
  DefaultWorkOrdersUnassignedDMSFolderID: null,
  DefaultNotificationsUnassignedDMSFolderID: null,
  DefaultIsolationsUnassignedDMSFolderID: null,
  DefaultPermitsUnassignedDMSFolderID: null,
  DefaultChecklistProcedureUnassignedDMSFolderID: null,
};

export const projectDetailsReducer = (state = projectDetailsState, payload) => {
  switch (payload.type) {
    case actionTypes.SET_PROJECT_DETAILS:
      return {
        ...state,
        ...payload.data,
      };
    case actionTypes.RESET_PROJECT_DETAILS:
      return projectDetailsState;
    case actionTypes.SET_PROJECT_DMS_CATEGORIES:
      return { ...state, projectDMSCategories: payload.data };
    case actionTypes.SET_IS_LOADING_PROJECT_DETAILS:
      return { ...state, isLoading: payload.data };
    default:
      return state;
  }
};
