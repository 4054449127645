import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import Button from '../../../../../common/form/components/button';
import ToggleField from '../../../../../common/form/components/toggle-field';
import { FORMS } from '../../../../../common/constants';
import { pdfDownloadOptions } from '../isolation-certificate-details/constants/constants';

class DownloadPDFOptionsForm extends Component {
  render() {
    const { t } = this.context;
    const { closeAction, handleSubmit, submitForm } = this.props;

    return (
      <form
        className="include-options-form"
        onSubmit={handleSubmit(vals => {
          submitForm(vals);
        })}
      >
        <label className="include-label">{t('ISOLATION_CERTIFICATE.INCLUDE_LABEL')}</label>
        <div className="download-option">
          <Field
            id={pdfDownloadOptions.populatedDrawings.name}
            name={pdfDownloadOptions.populatedDrawings.name}
            component={ToggleField}
            label={t('ISOLATION_CERTIFICATE.POPULATED_DRAWINGS_SECTION')}
            className={'multiple-labels-toggle'}
          />
        </div>
        {/* <div className="download-option">
          <Field
            id={pdfDownloadOptions.filesAndImages.name}
            name={pdfDownloadOptions.filesAndImages.name}
            component={ToggleField}
            label={t('ISOLATION_CERTIFICATE.FILES_AND_IMAGES_SECTION')}
            className={'multiple-labels-toggle'}
          />
        </div> */}
        <div className="buttons">
          <Button
            onClick={handleSubmit(vals => {
              submitForm(vals);
            })}
            type="button"
            text={t('GENERATE_AND_DOWNLOAD')}
            className="modal__button modal__button_red"
          />
          <Button onClick={closeAction} type="button" text={t('CANCEL')} variant="gray-outline" />
        </div>
      </form>
    );
  }
}
DownloadPDFOptionsForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

DownloadPDFOptionsForm = reduxForm({
  form: FORMS.downloadPDFOptionsForm,
  enableReinitialize: true,
})(DownloadPDFOptionsForm);

export default connect(null, null)(DownloadPDFOptionsForm);
