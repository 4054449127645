import { setComponentDetailsLoading, setElementDetails, updateElementDetails } from '../../inspections/actions/action-creators';
import { selectDefect, updateDefect } from '../../inspections/actions/inspection-actions';
import {
  amendPdfComponents,
  removeComponentInArray,
  selectActiveDrawing,
  setComponentsLoading,
  setPdfComponents,
  setPdfFileLoading,
  setPdfList,
  setPdfPageNumber,
  setPdfPopupComponentsLoading,
  setSelectedFile,
  updateComponentInArray,
} from './action-creators';

import inspectionApi from '../../../api/inspection/actions';
import pdfTagApi from '../../../api/pdf-tag/actions';

import { find, get, isEmpty, omit } from 'lodash';
import DrawingHelpers from '../../../common/drawing-helpers';
import { componentFields, componentFilterValues, pdfStatus } from '../constants/constants';

import axios from 'axios';
import { measurementTypes } from '../../inspections/constants/constants';
import ElementActions from '../../inspections/constants/element-actions';

export const getPdfList = (InspectionID, fileId, callback) => {
  return async dispatch => {
    dispatch(setPdfFileLoading(true));
    const res = await pdfTagApi.getPdfList({ InspectionID });
    const { Data } = res.data;

    const selectedFile = find(Data, item => parseInt(item.FileID) === fileId) || Data[0];
    dispatch(setPdfList(Data || []));

    dispatch(setSelectedFile(selectedFile));
    dispatch(setPdfFileLoading(false));

    callback && callback(Data || [], selectedFile);
    return selectedFile;
  };
};

let cancelTokenComponents;

export const getPdfComponents = (
  InspectionID,
  FileID,
  filter = {
    SearchText: '',
    ComponentFilter: componentFilterValues.all,
  },
  pageNumber,
  componentID,
  callback,
  loadMore = false,
  dataCallback
) => {
  return async dispatch => {
    dispatch(setComponentsLoading(true));

    //Check if there are any previous pending requests
    if (typeof cancelTokenComponents != typeof undefined) {
      cancelTokenComponents.cancel('Operation canceled due to new request.');
    }

    //Save the cancel token for the current request
    cancelTokenComponents = axios.CancelToken.source();

    try {
      const res = await pdfTagApi.getPdfComponents(
        {
          InspectionID,
          FileID,
          ...filter,
          PageNumber: pageNumber,
        },
        { cancelToken: cancelTokenComponents.token }
      );
      const { TaggedComponents } = res.data.Data;
      let normalizedComponents = [];
      try {
        normalizedComponents = DrawingHelpers.normalizeDrawings(TaggedComponents);
      } catch (e) {
        console.warn('corrupted drawings');
      }
      if (componentID) {
        // updates selected component with latest info in DB
        const selectedComp = find(normalizedComponents, { [componentFields.componentId]: componentID });
        dispatch(updateElementDetails({ ...selectedComp }));
      }
      /**
       * TODO: what to do with the logic line below, as loadMore is always being sent as false?
       * maybe for future usage once we introduce Load More drawings/components per PDF?
       */
      loadMore ? dispatch(amendPdfComponents(normalizedComponents || [])) : dispatch(setPdfComponents(normalizedComponents || []));
      callback && callback();
      dataCallback && dataCallback(normalizedComponents);
      dispatch(setComponentsLoading(false));
    } catch (err) {
      dispatch(setComponentsLoading(false));
    }
  };
};

export const getPdfComponentsForPopup = (InspectionID, FileID, filter, pageNumber = 1, componentID, callback) => {
  return async dispatch => {
    dispatch(setPdfPopupComponentsLoading(true));
    try {
      const res = await pdfTagApi.getPdfComponents({
        InspectionID,
        FileID,
        ...filter,
        PageNumber: pageNumber,
      });
      const { TaggedComponents } = res.data.Data;
      let normalizedComponents = [];
      try {
        normalizedComponents = DrawingHelpers.normalizeDrawings(TaggedComponents);
      } catch (e) {
        console.warn('corrupted drawings');
      }
      if (componentID) {
        // updates selected component with latest info in DB
        const selectedComp = find(normalizedComponents, { [componentFields.componentId]: componentID });
        dispatch(updateElementDetails({ ...selectedComp }));
      }
      dispatch(setPdfComponents(normalizedComponents || []));
      callback && callback(normalizedComponents);
      dispatch(setPdfPopupComponentsLoading(false));
    } catch (err) {
      dispatch(setPdfPopupComponentsLoading(false));
    }
  };
};

export const initPdfTagScreen = (InspectionID, fileId, filter, pageNumber = 1, componentID, callback, loadMore = false, dataCallback) => {
  return async dispatch => {
    dispatch(setPdfComponents([]));
    const selectedPdf = await dispatch(getPdfList(InspectionID, fileId));
    if ((selectedPdf || {}).FileID) {
      dispatch(getPdfComponents(InspectionID, selectedPdf.FileID, filter, pageNumber, componentID, callback, loadMore, dataCallback));
    }
  };
};

export const createComponent = component => {
  return async dispatch => {
    const res = await inspectionApi.createComponent({ ...component });
    //BE is not taking into consideration all props while creating component
    const newComponent = { ...res.data.Data, ...omit(component, [componentFields.componentId, componentFields.isTemp]) };

    dispatch(setElementDetails({ defect: newComponent || {} }));
    dispatch(updateComponentInArray(newComponent, component.ID));
  };
};

export const updateComponent = component => {
  return async dispatch => {
    try {
      if (component.ID > 0) {
        await dispatch(updateDefect(component));
      }
      const hasCv = component[componentFields.cvFoundID] > 0;
      if (hasCv) {
        return;
      }
      dispatch(updateComponentInArray(component, component.ID));
      dispatch(updateElementDetails(component));
    } catch (e) {
      console.error(e);
    }
  };
};

export const saveComponentTag = (FileID, selectedComponent, Drawing, pageNumber = 1, reloadDrawings = false, drawingCallback = () => null) => {
  return async dispatch => {
    try {
      if (reloadDrawings) {
        dispatch(setComponentsLoading(true));
      }
      const drawingString = JSON.stringify(Drawing);
      const res = await pdfTagApi.saveComponentTag({
        FileID,
        ComponentID: selectedComponent[componentFields.componentId] || null,
        CvFoundID: selectedComponent[componentFields.cvFoundID] || null,
        Drawing: drawingString,
        PageNumber: pageNumber,
      });

      const { Data } = res.data;

      const propId = componentFields.componentId;
      const updatedComponent = {
        [componentFields.confirmed]: false,
        [componentFields.drawings]: selectedComponent[componentFields.drawings] ? [...selectedComponent[componentFields.drawings], Data] : [...[], Data],
      };

      dispatch(updateComponentInArray({ ...updatedComponent }, selectedComponent[propId], propId));
      dispatch(updateElementDetails({ ...updatedComponent }));
      drawingCallback && drawingCallback();

      if (reloadDrawings) {
        dispatch(setComponentsLoading(false));
      }
    } catch (e) {
      if (reloadDrawings) {
        dispatch(setComponentsLoading(false));
      }
      console.error(e);
    }
  };
};

export const selectDrawing = drawing => {
  return dispatch => {
    dispatch(selectActiveDrawing(drawing));
  };
};

export const confirmComponentTag = (FileID, ComponentID, selectedComponent, pageNumber, successCallback = () => null, drawingCallback = () => null) => {
  return async dispatch => {
    try {
      if (ComponentID > 0) {
        await pdfTagApi.confirmTag({ FileID, ComponentID, PageNumber: pageNumber });
        dispatch(
          updateComponentInArray(
            {
              [componentFields.confirmed]: true,
              // Update all of the drawings that their tags are confirmed
              [componentFields.drawings]: (selectedComponent?.[componentFields.drawings] || []).map(drawing => ({
                ...drawing,
                [componentFields.confirmed]: true,
              })),
            },
            ComponentID
          )
        );
        dispatch(updateElementDetails({ [componentFields.confirmed]: true }));
        successCallback && typeof successCallback === 'function' && successCallback();
        drawingCallback && typeof drawingCallback === 'function' && drawingCallback();
      }
    } catch (e) {
      console.error(e);
    }
  };
};

export const handleMergeCvWithNewComponent = (cvComponent, inspectionId) => {
  return async dispatch => {
    const defaultComp = {
      CameraPosition: {
        coordinates: [0, 0, 0],
      },
      ComponentType: 'New Equipment',
      Type: 'New Equipment',
      ComponentCode: '',
    };

    const res = await inspectionApi.createComponent({
      ...cvComponent,
      InspectionID: inspectionId,
      ...defaultComp,
    });
    let componentToSave = { ...cvComponent, ...res.data.Data };

    // link to the component instead of save tag
    await pdfTagApi.linkToComponent({
      ComponentID: componentToSave[componentFields.componentId],
      UnconfirmedTagID: cvComponent[componentFields.cvFoundID],
    });

    componentToSave = { ...componentToSave, [componentFields.taggingType]: pdfStatus.computerVision };

    dispatch(
      updateComponentInArray(
        {
          ...cvComponent,
          [componentFields.componentId]: componentToSave[componentFields.componentId],
        },
        cvComponent[componentFields.cvFoundID],
        componentFields.cvFoundID
      )
    );
    dispatch(
      selectDefect({
        defect: {
          ...cvComponent,
          [componentFields.componentId]: componentToSave[componentFields.componentId],
          ...defaultComp,
        },
      })
    );
  };
};

export const handleMergeCvWithExistingComponent = (cvComponent, { value: CompID, label }) => {
  return async dispatch => {
    await pdfTagApi.linkToComponent({
      ComponentID: CompID,
      UnconfirmedTagID: cvComponent[componentFields.cvFoundID],
    });

    const { requestActions } = ElementActions[measurementTypes.component];
    const res = await requestActions.getElementDetails(CompID);
    const { Data: actualComponent } = res.data;

    dispatch(updateComponentInArray({ ...cvComponent, [componentFields.componentId]: CompID }, cvComponent[componentFields.cvFoundID], componentFields.cvFoundID));

    dispatch(
      selectDefect({
        defect: {
          ...cvComponent,
          ...actualComponent,
          [componentFields.name]: label,
          [componentFields.componentId]: CompID,
        },
      })
    );
  };
};

export const handleNotInThisPdfCVFound = (UnconfirmedTagID, shouldEmptySelected, drawingCallback = () => null) => {
  return async dispatch => {
    try {
      dispatch(setComponentsLoading(true));
      await pdfTagApi.notInThisPdfCVFound({ UnconfirmedTagID });
      dispatch(removeComponentInArray(UnconfirmedTagID, componentFields.cvFoundID));
      shouldEmptySelected && dispatch(setElementDetails({ defect: {} }));
      drawingCallback && drawingCallback();
      dispatch(setComponentsLoading(false));
    } catch (err) {
      dispatch(setComponentsLoading(false));
    }
  };
};

// shouldRemoveItFromTheList might be obsolete, as it is set to false at all action calls
export const handleNotInThisPdf = (Component, FileID, TagID, shouldRemoveItFromTheList, drawingCallback = () => null) => {
  return async dispatch => {
    try {
      dispatch(setComponentsLoading(true));

      // if tag is sent, remove only that tag, otherwise remove all tags
      if (TagID) {
        await pdfTagApi.deleteComponentTag({ ComponentID: Component[componentFields.componentId], FileID, TagID });
      } else await pdfTagApi.deleteComponentTags({ ComponentID: Component[componentFields.componentId], FileID });

      if (shouldRemoveItFromTheList) {
        dispatch(removeComponentInArray(Component[componentFields.componentId], componentFields.componentId));
      } else if (TagID) {
        const foundDrawingIndex = Component[componentFields.drawings].findIndex(drawing => drawing[componentFields.componentId] === TagID);
        if (foundDrawingIndex > -1) {
          // Create a new array with the updated object
          Component[componentFields.drawings].splice(foundDrawingIndex, 1);

          dispatch(updateComponentInArray({ ...Component }, Component[componentFields.componentId], componentFields.componentId));
          dispatch(updateElementDetails({ ...Component }));
        }
      } else {
        // deletes all tags from component
        dispatch(
          updateComponentInArray(
            {
              [componentFields.drawings]: [],
              [componentFields.confirmed]: false,
            },
            Component[componentFields.componentId]
          )
        );
        dispatch(setElementDetails({ defect: {} }));
      }

      drawingCallback && drawingCallback();
      dispatch(setComponentsLoading(false));
    } catch (err) {
      dispatch(setComponentsLoading(false));
    }
  };
};

export const confirmTagCvFound = (FileID, ComponentID, selectedComponent, pageNumber = 1, componentFilter) => {
  return async dispatch => {
    try {
      if (ComponentID > 0) {
        dispatch(setComponentsLoading(true));

        let drawings = null;
        if (!isEmpty(selectedComponent[componentFields.drawings]) && selectedComponent[componentFields.drawings][0]?.Drawing) {
          // Only one drawing can be present under one CV Found component
          drawings = selectedComponent[componentFields.drawings][0]?.Drawing;
        }

        await pdfTagApi.saveComponentTag({
          FileID,
          ComponentID: selectedComponent[componentFields.componentId],
          CvFoundID: selectedComponent[componentFields.cvFoundID],
          Drawing: drawings,
          PageNumber: pageNumber,
        });

        await pdfTagApi.notInThisPdfCVFound({ UnconfirmedTagID: selectedComponent[componentFields.cvFoundID] });

        dispatch(removeComponentInArray(selectedComponent[componentFields.cvFoundID], componentFields.cvFoundID));
        dispatch(setElementDetails({ defect: {} }));
        dispatch(setComponentsLoading(false));
      }
    } catch (e) {
      console.error(e);
      dispatch(setComponentsLoading(false));
    }
  };
};

export const updateComponentTag = (TagID, Drawing, selectedComponent, reloadDrawings = false, callback = () => null) => {
  return async dispatch => {
    try {
      if (selectedComponent[componentFields.componentId] > 0) {
        if (reloadDrawings) {
          dispatch(setComponentsLoading(true));
        }
        const res = await pdfTagApi.updateComponentTag({
          TagID,
          Drawing: Drawing,
          // adds filter depending on if it is from CV Found list or from All components or CV Linked list
          // CV_FOUND || ALL
          Filter: selectedComponent && selectedComponent[componentFields.cvFoundID] ? 'CV_FOUND' : 'ALL',
        });
        const { Data } = res.data;
        const propId = componentFields.componentId;

        const foundDrawingIndex = selectedComponent[componentFields.drawings].findIndex(drawing => drawing[componentFields.componentId] === Data[componentFields.componentId]);
        if (foundDrawingIndex > -1) {
          // Create a new array with the updated object
          const updatedDrawings = [...selectedComponent[componentFields.drawings].slice(0, foundDrawingIndex), Data, ...selectedComponent[componentFields.drawings].slice(foundDrawingIndex + 1)];
          const updatedComponent = {
            [componentFields.confirmed]: false,
            [componentFields.drawings]: updatedDrawings,
          };

          dispatch(updateComponentInArray({ ...updatedComponent }, selectedComponent[propId], propId));
          dispatch(updateElementDetails({ ...updatedComponent }));
        }

        if (reloadDrawings) {
          dispatch(setComponentsLoading(false));
        }

        callback && callback();
      }
    } catch (e) {
      if (reloadDrawings) {
        dispatch(setComponentsLoading(false));
      }
      console.error(e);
    }
  };
};

export const getComponentDrawingDetails = (FileID = null, ComponentID = null, CvFoundID = null, selectedComponent, dataCallback = () => null) => {
  return async dispatch => {
    try {
      dispatch(setComponentDetailsLoading(true));

      let apiParams = {};
      const propId = componentFields.componentId;

      if (FileID && ComponentID) {
        apiParams = { FileID, ComponentID };
      }
      if (CvFoundID) {
        apiParams = { CvFoundID };
      }

      const res = await pdfTagApi.getComponentDrawingsDetails(apiParams);
      const componentDrawingsDetails = get(res, 'data.Data');

      if (componentDrawingsDetails) {
        dataCallback && dataCallback(componentDrawingsDetails);

        /**
         * TODO: this logic of updating reducer is a bit redundant in reference
         * to updateComponentTag method above and might require refactoring
         */
        const updatedComponent = {
          [componentFields.drawings]: componentDrawingsDetails,
        };

        dispatch(updateElementDetails({ ...updatedComponent }));
        dispatch(updateComponentInArray({ ...updatedComponent }, selectedComponent[propId], propId));
      }

      dispatch(setComponentDetailsLoading(false));
    } catch (e) {
      console.error(e);
      dispatch(setComponentDetailsLoading(false));
    }
  };
};

export const changePDFPage = (newPDFPageNumber, inspectionId, fileId, filter, selectedComponent, showOnlyConfirmedDrawings, callback = () => null) => {
  return async dispatch => {
    try {
      dispatch(setPdfPopupComponentsLoading(true));
      dispatch(setPdfPageNumber(newPDFPageNumber));
      dispatch(
        getPdfComponents(inspectionId, fileId, filter, newPDFPageNumber, selectedComponent ? selectedComponent[componentFields.componentId] : null, null, false, newDrawings => {
          // when PDF opened in popup from module details or DMS, we show only confirmed tags/drawings
          if (showOnlyConfirmedDrawings) {
            const confirmedDrawings = (newDrawings || []).filter(cDrawing => cDrawing[componentFields.confirmed]);
            dispatch(setPdfComponents(confirmedDrawings));
          } else dispatch(setPdfComponents(newDrawings));
          dispatch(setPdfPopupComponentsLoading(false));
        })
      );
      callback && callback();
    } catch (e) {
      console.error(e);
      dispatch(setPdfPopupComponentsLoading(false));
    }
  };
};

export const getPdfComponentsAndChangePageBySelectedComponent = (inspectionId, fileId, filter, selectedComponent, showOnlyConfirmedDrawings, callback = () => null) => {
  return async dispatch => {
    try {
      if (isEmpty(selectedComponent)) {
        return;
      }
      dispatch(setPdfPopupComponentsLoading(true));
      dispatch(
        getPdfComponents(inspectionId, fileId, filter, undefined, selectedComponent[componentFields.componentId], null, false, newDrawings => {
          // when PDF opened in popup from module details or DMS, we show only confirmed tags/drawings
          const selectedComponentDrawings = newDrawings.find(item => item[componentFields.componentId] === selectedComponent[componentFields.componentId]);
          if (!isEmpty(selectedComponentDrawings?.[componentFields.drawings] && selectedComponentDrawings[componentFields.drawings][0])) {
            dispatch(setPdfPageNumber(selectedComponentDrawings[componentFields.drawings][0][componentFields.pageNumber]));
          } else {
            dispatch(setPdfPageNumber(1));
          }

          if (showOnlyConfirmedDrawings) {
            const confirmedDrawings = (newDrawings || []).filter(cDrawing => cDrawing[componentFields.confirmed]);
            dispatch(setPdfComponents(confirmedDrawings));
          } else dispatch(setPdfComponents(newDrawings));
          dispatch(setPdfPopupComponentsLoading(false));
        })
      );
      callback && callback();
    } catch (e) {
      console.error(e);
      dispatch(setPdfPopupComponentsLoading(false));
    }
  };
};
