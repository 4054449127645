import React, { useEffect, useRef } from 'react';
import { TablePropTypes, scrollDirections } from '../constants/constants';
import '../styles/table-body.scss';

const renderCells = (data, tableConfig, formatCell, rowIndex) => {
  return tableConfig.map((cell, index) => {
    let value = data[cell.key];
    return (
      <div className={`twp-body__row__cells__cell ${cell.class} ${cell.bodyClassName ? cell.bodyClassName : ''}`} key={index}>
        {formatCell(value, cell.type, data, cell, rowIndex)}
      </div>
    );
  });
};

const Body = ({ data, tableConfig, onRowClick, rowClickDisabled = false, formatCell, selectedItem, loadForDMS, previousScroll, containerRef }) => {
  const rowRefs = useRef([]);
  const observer = useRef(null);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      () => {},
      { threshold: [0, 0.25] } // Observe both entering and exiting the viewport
    );

    rowRefs.current.forEach(rowRef => {
      if (rowRef.current) {
        observer.current.observe(rowRef.current);
      }
    });

    return () => {
      observer.current.disconnect();
    };
  }, []);

  // only for DMS sticky rows, once we do decide to go for CH as well with this approach just remove this if and else part of it
  if (loadForDMS) {
    const renderRow = (cells, index) => {
      let stickyPosition = scrollDirections.up;
      const container = containerRef.current;
      if (container && cells.ID === selectedItem && rowRefs.current[index] && index !== 0) {
        const containerRect = container.getBoundingClientRect();
        const targetRect = rowRefs.current[index - 1].getBoundingClientRect();
        const scrollTop = container.scrollTop;
        const targetPosition = targetRect.top - containerRect.top + scrollTop;
        stickyPosition = scrollTop > targetPosition ? scrollDirections.up : scrollDirections.down;
      }
      return (
        <div
          className={`twp-body__row ${cells.ID === selectedItem ? `selected sticky__${stickyPosition}` : ''} `}
          ref={ref => (rowRefs.current[index] = ref)}
          id={`row-${index}`}
          onClick={() => onRowClick(cells)}
          key={`row-${index}-${cells?.ID}`}
        >
          <div className="twp-body__row__cells">{renderCells(cells, tableConfig, formatCell, index)}</div>
          {cells.Path ? <div className="twp-body__row__path">{cells.Path}</div> : null}
        </div>
      );
    };

    return <>{data.map((cells, index) => renderRow(cells, index))} </>;
  }

  return data.map((cells, index) => {
    cells.index = index;
    return (
      <div
        className={`twp-body__row ${selectedItem === cells?.ID ? 'selected' : ''} ${rowClickDisabled ? 'twp-body__row-click-disabled' : ''}`}
        id={`row-${index}`}
        onClick={rowClickDisabled ? null : () => onRowClick(cells)}
        disabled={rowClickDisabled}
        key={`row-${index}-${cells?.ID}`}
        data-cy="table-row"
      >
        <div className="twp-body__row__cells">{renderCells(cells, tableConfig, formatCell, index)}</div>
        {cells.Path ? <div className="twp-body__row__path">{cells.Path}</div> : null}
      </div>
    );
  });
};

const { data, tableConfig, onRowClick, rowClickDisabled, formatCell } = TablePropTypes;

Body.propTypes = {
  data,
  tableConfig,
  onRowClick,
  rowClickDisabled,
  formatCell,
};

export default Body;
