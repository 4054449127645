import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RenderIf from '../../render-if/components/render-if';
import Icon from '../../../common/icon/components/icon';
import { colorsMap } from '../../../app/inspections/components/ndt/constants/ndt-alarms';

const HistoryItemColorDisplay = ({ from, to, availableColors }) => {
  const getColor = color => {
    return availableColors[color] ? availableColors[color] : color;
  };

  return (
    <div className="history-item-color-display">
      <RenderIf if={from}>
        <div className="history-item-color-display__item" style={{ backgroundColor: getColor(from) }}></div>
      </RenderIf>
      <RenderIf if={from}>
        <Icon name="arrow-right" size="sm" className="history-item-display__icon" />
      </RenderIf>
      <div className="history-item-color-display__item" style={{ backgroundColor: getColor(to) }}></div>
    </div>
  );
};

HistoryItemColorDisplay.propTypes = {
  from: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  to: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const mapStateToProps = state => {
  const colors = state.themeReducer.severityColors;
  const availableColors = colors
    ? {
        [colorsMap.severityYellow]: colors.severityYellow,
        [colorsMap.severityOrange]: colors.severityOrange,
        [colorsMap.severityRed]: colors.severityRed,
      }
    : {};

  return {
    availableColors,
  };
};

export default connect(mapStateToProps, null)(HistoryItemColorDisplay);
