import PropTypes from 'prop-types';
import React from 'react';
import TableTemplate from '../../../../common/double-table/components/table-template';
import '../../styles/dms-linked-equipment.scss';

const DMSFileLinkedModules = ({ value, onModuleItemDeepLinkClick, tableConfig, handleFormatCells, noDataText, emptyStateComponent }, { t }) => {
  return (
    <div className="dms-linked-equipment">
      <TableTemplate
        showSearchBar={false}
        showInfo={false}
        tableConfig={tableConfig}
        data={value || []}
        filters={{}}
        handleFormatCells={handleFormatCells()}
        selectedItem={null}
        customTableClass="dms-linked-equipment__table"
        noDataText={t(noDataText)}
        onRowClick={item => onModuleItemDeepLinkClick(item)}
        emptyStateComponent={emptyStateComponent}
      />
    </div>
  );
};

DMSFileLinkedModules.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default DMSFileLinkedModules;
