import { filterProps } from '../constants/constants';

// TODO: reuse this into single helper file for all tabs when refactoring of component-tab-wrapper begins @deniz
export const deleteAdvancedFiltersFromFilters = filters => {
  filters[filterProps.startDateFrom] = null;
  filters[filterProps.startDateTo] = null;
  filters[filterProps.severityFilter] = [];
  filters[filterProps.assignedTo] = [];
  filters[filterProps.workOrderTypeFilter] = [];
  filters[filterProps.additionalSubTypeFilter] = [];
  filters[filterProps.createdByFilter] = [];
  // added since we use these filters on WO module but not on Component tabs
  filters[filterProps.componentIDsFilter] = [];
  filters[filterProps.componentTypeFilter] = [];
  filters[filterProps.properties] = undefined;
  filters[filterProps.assignees] = undefined;
  filters[filterProps.collaborators] = undefined;
  filters[filterProps.componentsSearchFilter] = undefined;
  filters[filterProps.hasObservationFilter] = null;
  filters[filterProps.hasChecklistFilter] = null;
  filters[filterProps.checklistIDsFilter] = [];

  return filters;
};
