import React from 'react';
import SmallDropdown from '../../../../../../common/small-dropdown/components/small-dropdown';
import { ReactComponent as edit } from '../../../../../../common/icon/assets/pen.svg';
import { ReactComponent as download } from '../../../../../../common/icon/assets/download.svg';
import { ReactComponent as checkmark } from '../../../../../../common/icon/assets/checkmark.svg';
import { ReactComponent as trash } from '../../../../../../common/icon/assets/trash.svg';
import PropTypes from 'prop-types';
import { versionHistoryDropdownActions } from '../constants/constants';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../../../common/permissions-constants';
import { fields } from '../../../../constants/constants';

const VersionHistoryDropdown = (props, context) => {
  const { isActive, getClickAction, documentDetails } = props;
  const { t } = context;

  const getDropdownActionsMenu = () => {
    const actionsMenu = [
      {
        title: t('EDIT'),
        icon: edit,
        customMenuItemClassName: 'green-icon stroke',
        iconProps: { height: 20, width: 20 },
        action: versionHistoryDropdownActions.edit,
        access: {
          visibleFor: PERMISSIONS[PERMISSION_TYPES.documentManagement].edit.name,
          id: documentDetails[fields.createdByID],
          ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.documentManagement].create.name,
        },
      },
      {
        title: t('DOWNLOAD'),
        icon: download,
        customMenuItemClassName: 'green-icon stroke',
        iconProps: { height: 20, width: 20 },
        action: versionHistoryDropdownActions.download,
      },
    ];

    !isActive &&
      actionsMenu.push(
        {
          title: t('DMS_FILE.SET_AS_ACTIVE_VERSION'),
          icon: checkmark,
          customMenuItemClassName: 'green-icon fill',
          iconProps: { height: 20, width: 20 },
          action: versionHistoryDropdownActions.setAsActive,
          access: {
            visibleFor: PERMISSIONS[PERMISSION_TYPES.documentManagement].edit.name,
            id: documentDetails[fields.createdByID],
            ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.documentManagement].create.name,
          },
        },
        {
          title: t('DMS_FILE.DELETE_VERSION'),
          icon: trash,
          separator: true,
          isHighlighted: true,
          customMenuItemClassName: 'red-icon stroke',
          iconProps: { height: 20, width: 20 },
          action: versionHistoryDropdownActions.delete,
          access: {
            visibleFor: PERMISSIONS[PERMISSION_TYPES.documentManagement].delete.name,
          },
        }
      );

    return actionsMenu;
  };
  return (
    <SmallDropdown
      actionsMenu={getDropdownActionsMenu()}
      getClickAction={(action, b, selfClose, e) => {
        e?.preventDefault && e.preventDefault();
        selfClose();
        if (action) {
          if (typeof action === 'function') {
            action();
          } else if (typeof action === 'string' && getClickAction) {
            getClickAction(action);
          }
        }
      }}
    />
  );
};

VersionHistoryDropdown.contextTypes = {
  t: PropTypes.func.isRequired,
};

VersionHistoryDropdown.propTypes = {
  isActive: PropTypes.bool.isRequired,
  file: PropTypes.object.isRequired,
  getClickAction: PropTypes.func.isRequired,
};

export default VersionHistoryDropdown;
