export const filterProps = {
  searchText: 'SearchText',
  sortByColumn: 'SortByColumn',
  sortDirection: 'SortDirection',
  dateFrom: 'DateFrom',
  dateTo: 'DateTo',
  lastSeen: 'LastSeen',
  perPage: 'PerPage',
  hasNext: 'HasNext',
  totalItems: 'TotalNumber',
};

export const notificationFields = {
  id: 'ID',
  icon: 'icon',
  name: 'Name',
  status: 'Status',
  createdAt: 'CreatedAt',
};

export const sortDirection = {
  asc: 'ASC',
  desc: 'DESC',
};

export const defaultFilters = {
  [filterProps.searchText]: '',
  [filterProps.perPage]: 10,
  [filterProps.lastSeen]: 0,
  [filterProps.sortDirection]: sortDirection.asc,
  [filterProps.sortByColumn]: notificationFields.name,
};

export const notificationsTableConfig = [
  {
    title: '',
    key: notificationFields.status,
    type: notificationFields.icon,
    class: 'icon-column',
  },
  {
    title: 'ID',
    key: notificationFields.id,
    type: notificationFields.id,
    class: 'id-column',
    isSort: true,
  },
  {
    title: 'TABLE.DEFECT_NAME',
    key: notificationFields.name,
    type: notificationFields.name,
    class: 'name-column',
    isSort: true,
  },
  {
    title: 'TABLE.DEFECT_CREATED',
    key: notificationFields.createdAt,
    type: notificationFields.createdAt,
    class: 'created-at-column',
    isSort: true,
  },
];
