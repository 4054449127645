import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DefaultImage from '../assets/profile-placeholder.svg';
import { ReactComponent as overlayImage } from '../assets/photo.svg';
import DropZone from '../../upload/components/upload-dropzone';

import '../styles/profile-image.scss';

class ProfileImage extends Component {
  state = {
    visible: false,
  };

  render() {
    const { image, imageAction, OverlayImage, squared, imageEvents, active, enableCheckmark } = this.props;
    const { visible } = this.state;
    //TO DO retrun image in prop
    return (
      <div className="profile-image noselect">
        <div className="image-wrapper image-item" onMouseEnter={() => this.setState({ visible: true })} onMouseLeave={() => this.setState({ visible: false })}>
          {enableCheckmark && <input id={`${image}`} name={`${image}`} type="checkbox" onChange={() => null} checked={active || false} />}

          <label htmlFor={image} style={{ backgroundImage: `url('${image || DefaultImage}')` }} className={`bg-image image-wrapper__image ${squared ? '' : 'rounded'}`} {...imageEvents} />
          {imageAction && <DropZone CustomImage={() => <OverlayImage />} className={`image-wrapper__overlay ${visible ? 'visible' : ''}`} onDrop={imageAction} />}
        </div>
      </div>
    );
  }
}

ProfileImage.contextTypes = {
  t: PropTypes.func.isRequired,
};

ProfileImage.defaultProps = {
  image: DefaultImage,
  imageAction: null,
  squared: false,
  imageClick: null,
  imageEvents: null,
  OverlayImage: overlayImage,
};

export default ProfileImage;
