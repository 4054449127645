import React, { Component } from 'react';
import PropTypes from 'prop-types';

import '../styles/project-details.scss';

class ProjectDetails extends Component {
  render() {
    const { t } = this.context;
    const { project } = this.props;

    if (!project) return null;

    return (
      <div className="project-details">
        <div className="container">
          <div className="project-details__label-container">
            <span className="f-secondary-dark label">{t('PROJECT_DETAILS.PROJECT_NAME')}:</span>
            <span className="f-secondary-dark label">{t('PROJECT_DETAILS.NUMBER_OF_INSPECTIONS')}:</span>
            <span className="f-secondary-dark label">{t('PROJECT_DETAILS.NUMBER_OF_DEFECTS')}:</span>
            <span className="f-secondary-dark label">{t('PROJECT_DETAILS.LOCATION')}:</span>
            <span className="f-secondary-dark label">{t('PROJECT_DETAILS.CONTACT_NAME')}:</span>
            <span className="f-secondary-dark label">{t('PROJECT_DETAILS.CONTACT_EMAIL')}:</span>
            <span className="f-secondary-dark label">{t('PROJECT_DETAILS.CONTACT_PHONE')}:</span>
          </div>
          <div className="project-details__value-container">
            <span className="f-primary value">{project.Name || '--'}</span>
            <span className="f-primary value">{project.NumberOfInspections || '--'}</span>
            <span className="f-primary value">{project.NumberOfDefects || 0}</span>
            <span className="f-primary value">{`${project.Location.Latitude || '--'}° N, ${project.Location.Longitude || '--'}° W`}</span>
            <span className="f-primary value">{project.ContactPerson.Name || '--'}</span>
            <span className="f-primary value">{project.ContactPerson.Email || '--'}</span>
            <span className="f-primary value">{project.ContactPerson.Phone || '--'}</span>
          </div>
        </div>
      </div>
    );
  }
}

ProjectDetails.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ProjectDetails;
