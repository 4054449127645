import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Field, reduxForm } from 'redux-form';

import Helpers from '../../../../common/helpers';

import BasicInput from '../common/input/basic-input';
import Button from '../../../../common/form/components/button';
import DatePicker from '../../../../common/form/components/date-picker';

import { AMAZON_IMAGE_SIZES, FORMS } from '../../../../common/constants';
import { measurementPointConstants as formConstants } from '../../constants/ndt-constants';

import { validate } from './validators/measurement-point-validator';
import Textarea from '../../../../common/form/components/text-area';

import '../../styles/component-form.scss';

class AddQuickPointForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageType: AMAZON_IMAGE_SIZES.small.name,
    };
  }

  formatDate = input => {
    if (!input) return;

    return Helpers.getDateFromUnix(input);
  };

  handleDateChange = (date, fieldName) => {
    const { changeField } = this.props;

    if (!date) return;
    const dateToSave = new Date(date).getTime();
    changeField(FORMS.addQuickNDTPointForm, fieldName, dateToSave);
  };

  render() {
    const { t } = this.context;
    const { handleSubmit, unit, description, threshold, onCancel } = this.props;

    return (
      <form className="defect-form component-form" onSubmit={handleSubmit} noValidate>
        <div className="main-section">
          <Textarea
            placeholder={'NDT_MEASUREMENT_DETAILS.FORM_MEASUREMENT_DESCRIPTION'}
            label={'NDT_MEASUREMENT_DETAILS.FORM_MEASUREMENT_DESCRIPTION'}
            labelClass="f-secondary-dark defect-form__label"
            enableAutoResize={true}
            disabled={true}
            wrapperClassName={'inspection-input'}
            input={{ value: description }}
          />
          <BasicInput
            placeholder={t('NDT_MEASUREMENT_DETAILS.FORM_MEASUREMENT_THRESHOLD')}
            label={t('NDT_MEASUREMENT_DETAILS.FORM_MEASUREMENT_THRESHOLD')}
            labelClass="f-secondary-dark defect-form__label"
            type="text"
            disabled={true}
            input={{ value: threshold }}
          />
          <div className="inline-wrapper">
            <Field
              id={formConstants.fields.measuredValue}
              name={formConstants.fields.measuredValue}
              component={BasicInput}
              placeholder={t('NDT_MEASUREMENT_POINT.FORM_MEASURED_VALUE', { unit })}
              label={t('NDT_MEASUREMENT_POINT.FORM_MEASURED_VALUE', { unit })}
              labelClass="f-secondary-dark defect-form__label"
              type="number"
            />
            <Field
              disabledKeyboardNavigation={true}
              dateFormat="MMMM d, yyyy h:mm aa"
              timeFormat="h:mm aa"
              showTimeSelect
              id={formConstants.fields.measuredDate}
              name={formConstants.fields.measuredDate}
              placeholder={t('NDT_MEASUREMENT_POINT.FORM_MEASURED_DATE')}
              label="NDT_MEASUREMENT_POINT.FORM_MEASURED_DATE"
              labelClass="f-secondary-dark defect-form__label"
              onChange={d => this.handleDateChange(d, formConstants.fields.measuredDate)}
              popperPlacement="bottom-end"
              component={DatePicker}
              maxDate={new Date()}
            />
          </div>
        </div>
        <div className="buttons">
          <Button type="button" variant="gray-outline" width="sm" onClick={onCancel} text={t('ADD_QUICK_NDT_POINT.FORM_CANCEL_MEASUREMENT_POINT')} />
          <Button width="sm" text={t('ADD_QUICK_NDT_POINT.FORM_SAVE_MEASUREMENT_POINT')} />
        </div>
      </form>
    );
  }
}

AddQuickPointForm.contextTypes = {
  t: PropTypes.func.isRequired,
};
AddQuickPointForm = reduxForm({
  form: FORMS.addQuickNDTPointForm,
  validate,
})(AddQuickPointForm);

export default AddQuickPointForm;
