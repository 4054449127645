export default {
  GET_ISOLATION_TEMPLATES: '/project/isolation/templates/list',
  GET_ISOLATION_TEMPLATE_DETAILS: '/project/isolation/template/details',
  CREATE_ISOLATION_TEMPLATE: '/project/isolation/template/create',
  UPDATE_ISOLATION_TEMPLATE: '/project/isolation/template/update',
  DUPLICATE_ISOLATION_TEMPLATE: '/project/isolation/template/copy',
  DELETE_ISOLATION_TEMPLATE: '/project/isolation/template/delete',
  UPDATE_ISOLATION_TEMPLATE_STATUS: '/project/isolation/template/status',
  UPDATE_ISOLATION_TEMPLATE_LIVE_STATUS: '/project/isolation/template/status/live',
  GET_ISOLATION_TEMPLATE_SECTIONS: '/project/isolation/template/section/list',
  ADD_ISOLATION_TEMPLATE_SECTION: '/project/isolation/template/section/add',
  UPDATE_ISOLATION_TEMPLATE_SECTION: '/project/isolation/template/section/update',
  DELETE_ISOLATION_TEMPLATE_SECTION: '/project/isolation/template/section/delete',
  ADD_ISOLATION_TEMPLATE_SECTION_QUESTION: '/project/isolation/template/section/question/add',
  DELETE_ISOLATION_TEMPLATE_SECTION_QUESTION: '/project/isolation/template/section/question/delete',
  UPDATE_ISOLATION_TEMPLATE_SECTION_QUESTION: '/project/isolation/template/section/question/update',
  ADD_ISOLATION_TEMPLATE_SECTION_QUESTION_OPTION: '/project/isolation/template/section/question/option/add',
  UPDATE_ISOLATION_TEMPLATE_SECTION_QUESTION_OPTION: '/project/isolation/template/section/question/option/update',
  DELETE_ISOLATION_TEMPLATE_SECTION_QUESTION_OPTION: '/project/isolation/template/section/question/option/delete',
  SORT_ISOLATION_TEMPLATE_SECTION: '/project/isolation/template/section/order',
  SORT_ISOLATION_TEMPLATE_SECTION_QUESTION: '/project/isolation/template/section/question/order',
  GET_ISOLATION_TEMPLATE_FILES: '/project/isolation/template/files/list',
  DELETE_ISOLATION_TEMPLATE_FILE: '/project/isolation/template/file/unlink',
};
