export const fields = {
  isolationTemplate: {
    name: 'IsolationTemplate',
    label: 'PERMIT.ISOLATION_TEMPLATE',
    placeholder: 'PERMIT.ISOLATION_TEMPLATE_PLACEHOLDER',
    errorRequired: 'PERMIT.ISOLATION_TEMPLATE_REQUIRED',
    id: 'isolation-template',
    showInDisplay: true,
  },
  assignedKeybox: {
    name: 'KeyboxName',
    label: 'PERMIT.KEYBOX_NAME',
    placeholder: 'PERMIT_KEYBOX.NAME_PLACEHOLDER',
    errorRequired: 'PERMIT_KEYBOX.NAME_REQUIRED',
    id: 'keybox-name',
    showInDisplay: true,
  },
  createdAt: {
    name: 'CreatedAt',
    label: 'PERMIT.CREATED_AT',
    placeholder: 'PERMIT.CREATED_AT_PLACEHOLDER',
    errorRequired: 'PERMIT.CREATED_AT_REQUIRED',
    id: 'created-at',
    type: 'date',
    showInDisplay: true,
  },
  status: {
    name: 'Status',
    label: 'PERMIT.STATUS',
    placeholder: 'PERMIT.STATUS_PLACEHOLDER',
    errorRequired: 'PERMIT.STATUS_REQUIRED',
    id: 'status',
    showInDisplay: true,
  },
  id: {
    name: 'ID',
  },
  name: {
    name: 'Name',
  },
};

export const sortDirection = {
  asc: 'ASC',
  desc: 'DESC',
};

export const filterProps = {
  sortByColumn: 'SortByColumn',
  sortDirection: 'SortDirection',
  perPage: 'PerPage',
  lastSeen: 'LastSeen',
  searchText: 'SearchText',
  hasNext: 'HasNext',
  totalItems: 'TotalItems',
  status: 'StatusFilter',
};

export const defaultFilters = {
  [filterProps.sortByColumn]: fields.id.name,
  [filterProps.sortDirection]: sortDirection.asc,
  [filterProps.perPage]: Number.MAX_SAFE_INTEGER,
  [filterProps.lastSeen]: 0,
  [filterProps.searchText]: '',
  [filterProps.hasNext]: false,
  [filterProps.status]: ['Live'],
};

export const tableConfig = [
  {
    title: 'PERMIT.NAME',
    key: fields.name.name,
  },
  {
    title: 'PERMIT.ISOLATION_TEMPLATE',
    key: fields.isolationTemplate.name,
  },
  {
    title: 'PERMIT.KEYBOX_NAME',
    key: fields.assignedKeybox.name,
  },
  {
    title: 'PERMIT.CREATED_AT',
    key: fields.createdAt.name,
    className: 'date-column',
    type: 'date',
  },
  {
    title: 'PERMIT.STATUS',
    key: fields.status.name,
    type: 'status',
  },
];

export const initialValuesProp = 'IsolationCertificates';
