import axios from 'axios';

import { get, isEmpty } from 'lodash';
import { store } from '../index';

import { initializeInterceptor } from './interceptor';

import { dispatchUserLogoutWired } from '../app/login/actions/login-actions';
import { dispatchGlobalLoaderdWired } from '../common/global-loader/actions/loader-actions';
import { dispatchErrorModalWired } from '../common/modal/actions/modal-actions';

import { customHandledErrors } from './custom-errors';

import Helpers from '../common/helpers';

const CancelToken = axios.CancelToken;
let cancelObj;

initializeInterceptor(axios);

export class Api {
  static headers = async headersIncome => {
    const { Token } = store.getState().userReducer;
    let headers = {};
    if (Token && Token.Token) {
      headers['App-Token'] = Token.Token;
    }
    if (!isEmpty(headersIncome)) {
      headers = { ...headers, ...headersIncome };
    }
    return headers;
  };

  static get = (route, options, headers) => Api.xhr({ route, method: 'GET', options, headersIncome: headers });

  static put = (route, data, options, headers) => {
    options = {
      ...options,
      cancelToken: new CancelToken(function executor(cancel) {
        // An executor function receives a cancel function as a parameter
        cancelObj = {
          cancel,
          timeStamp: data.timeStamp,
        };
      }),
    };

    return Api.xhr({ route, method: 'PUT', data, options, headersIncome: headers });
  };

  static post = (route, data, options, headers) => Api.xhr({ route, method: 'POST', data, options, headersIncome: headers });

  static delete = (route, options, headers) => Api.xhr({ route, method: 'DELETE', options, headersIncome: headers });

  static xhr({ route, method, data, options = {}, headersIncome = {} }) {
    options = {
      needLoader: false,
      onUploadProgress: (p, cancelObj) => {},
      ...options,
    };
    const { userReducer } = store.getState();
    if (options.needLoader) {
      dispatchGlobalLoaderdWired(true);
    }
    return Api.headers(headersIncome)
      .then(headers => {
        //block guest user to do actions
        if (Helpers.isGuestUser(userReducer) && !options.allowGuestUserAction) {
          return Promise.resolve({ data: {} });
        }
        return axios({
          method,
          url: route,

          ...options,
          headers: { ...headers, ...headersIncome },
          data: data,
          onUploadProgress: progressEvent => {
            const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
            //persist loader if upload is in progress
            if (options.needLoader && percentCompleted < 100) {
              dispatchGlobalLoaderdWired(true);
            }

            options.onUploadProgress(percentCompleted, cancelObj, progressEvent);
          },
        });
      })
      .then(response => {
        if (options.needLoader) {
          dispatchGlobalLoaderdWired(false);
        }
        return response;
      })
      .catch(err => {
        if (axios.isCancel(err)) {
          return Promise.reject(err);
        }
        console.error(err);
        if (options.needLoader) {
          dispatchGlobalLoaderdWired(false);
        }

        const errCode = Helpers.getErrorCode(err);
        const generalCode = get(err, 'response.status');

        //custom errors
        if (!isNaN(errCode) && customHandledErrors.indexOf(errCode) > -1) {
          return Promise.reject(err);
        }

        //logout if there is no codes
        if (generalCode === 401 && isNaN(errCode)) {
          dispatchUserLogoutWired();
          return Promise.reject(err);
        }

        //display generic message
        if (!options.skipErrorModal) {
          dispatchErrorModalWired(true, err);
        }

        return Promise.reject(err);
      });
  }
}
