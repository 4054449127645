export default {
  SET_TABLE_DATA: 'setTableData',
  SET_TABLE_PARAMS: 'setTableParams',
  SET_LOAD_MORE_ITEMS: 'setLoadMoreItems',
  HANDLE_ACTIVATE_USER_MODAL: 'handleActivateUserModal',
  HANDLE_DELETE_ITEM_AT_INDEX: 'handleDeleteItemAtIndex',
  HANDLE_UPDATE_ITEM_AT_INDEX: 'handleUpdateItemAtIndex',
  HANDLE_ADD_ITEM_AT_INDEX: 'handleAddItemAtIndex',
  HANDLE_UPDATE_ITEM_BY_ID: 'hadnleUpdateItemByID',
  HANDLE_CLEAR_TABLE_DATA: 'handleClearTableData',
  HANDLE_TABLE_DATA_LOADING: 'handleTableDataLoading',
};
