import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isArray, remove, filter, find } from 'lodash';

import Icon from '../../icon/components/icon';
import RenderIf from '../../render-if/components/render-if';

import '../styles/status-filters.scss';

class StatusesFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isExpanded: false,
    };
  }

  renderStatuses = () => {
    const { statuses, selected, singleSelected, valueKey, iconKey, titleKey, nonTranslatable, isSingleSelect, onChange, isLoading } = this.props;
    const { t } = this.context;

    const handleFilterChange = item => {
      if (isLoading) return;
      if (typeof onChange === 'function') {
        let value;

        if (isSingleSelect || item.isSingleSelect) {
          value = item[valueKey] === singleSelected && !item.includeInStatuses ? '' : item[valueKey];
        } else {
          value = Object.assign([], selected);
          value = filter(value, item => {
            return find(statuses, status => {
              return status[valueKey] === item && !status.isSingleSelect;
            });
          });

          if (value.indexOf(item[valueKey]) > -1) {
            remove(value, status => {
              return status === item[valueKey];
            });
          } else {
            value.push(item[valueKey]);
          }
        }

        onChange(value);
      }
    };

    const isStatusActive = (status, isItemSingleSelect, includeInStatuses) => {
      if (isSingleSelect && !includeInStatuses) {
        return status === selected;
      }

      if (isItemSingleSelect && singleSelected && !includeInStatuses) {
        return singleSelected === status;
      }

      return selected && isArray(selected) ? selected.indexOf(status) > -1 : false;
    };

    return statuses.map(item => (
      <div
        key={`status-filter-item-${item[valueKey]}`}
        className={`statuses-filter__wrapper__item ${isLoading ? 'disabled' : ''} ${
          isStatusActive(item[valueKey], item.isSingleSelect, item.includeInStatuses) ? 'statuses-filter__wrapper__item-active' : ''
        }`}
        onClick={() => handleFilterChange(item)}
        data-cy="status-filter"
      >
        {item[iconKey] ? <Icon className={`statuses-filter__wrapper__item__icon ${item.iconClass || ''}`} name={item[iconKey]} size="sm" {...(item.iconProps || {})} /> : null}
        {item[titleKey] ? <p className={`statuses-filter__wrapper__item__text f-primary`}>{nonTranslatable ? item[titleKey] : t(item[titleKey])}</p> : null}
      </div>
    ));
  };

  render() {
    const { isAdjustedForSmallerScreen } = this.props;
    const { isExpanded } = this.state;
    const { t } = this.context;
    const isRegularView = !isAdjustedForSmallerScreen || (isAdjustedForSmallerScreen && isExpanded);

    return (
      <div className={`statuses-filter ${isRegularView ? 'statuses-filter-expanded' : ''}`}>
        <div className={`statuses-filter__wrapper ${isRegularView ? 'statuses-filter-expanded__wrapper' : ''}`}>
          {this.renderStatuses()}
          <RenderIf if={isExpanded}>
            <div className="statuses-filter__more-wrapper statuses-filter__more-wrapper-adjusted" onClick={() => this.setState({ isExpanded: false })}>
              <Icon name="minus" />
              <p className="f-primary light-bold">{t('STATUS_FILTERS.LESS')}</p>
            </div>
          </RenderIf>
        </div>
        {isAdjustedForSmallerScreen && (
          <>
            <RenderIf if={!isExpanded}>
              <div className="statuses-filter__more-wrapper" onClick={() => this.setState({ isExpanded: true })}>
                <Icon name="plus" />
                <p className="f-primary light-bold">{t('STATUS_FILTERS.MORE')}</p>
              </div>
            </RenderIf>
          </>
        )}
      </div>
    );
  }
}

StatusesFilter.contextTypes = {
  t: PropTypes.func.isRequired,
};

StatusesFilter.propTypes = {
  statuses: PropTypes.array.isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  singleSelected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valueKey: PropTypes.string,
  iconKey: PropTypes.string,
  titleKey: PropTypes.string,
  nonTranslatable: PropTypes.bool,
  isSingleSelect: PropTypes.bool,
  onChange: PropTypes.func,
  isAdjustedForSmallerScreen: PropTypes.bool,
  maxAdjusted: PropTypes.number,
  isLoading: PropTypes.bool,
};

StatusesFilter.defaultProps = {
  statuses: [],
  valueKey: 'value',
  iconKey: 'icon',
  titleKey: 'title',
  nonTranslatable: false,
  isSingleSelect: false,
  isAdjustedForSmallerScreen: false,
  isLoading: false,
};

export default StatusesFilter;
