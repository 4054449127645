import routesConstants from './routes';
import { Api } from '../../core/api';
import { API_BASE_URL } from '../../common/constants';
import { generateQueryParams } from '../helpers/helper';

const fetchComponentHierarchyLevels = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_COMPONENT_HIERARCHY_LEVELS}`, data);
};
const createComponentHierarchyLevels = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CREATE_COMPONENT_HIERARCHY_LEVELS}`, data);
};
const fetchComponentHierarchyLevelDetails = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.FETCH_COMPONENT_HIERARCHY_LEVEL_DETAILS}${generateQueryParams(params)}`);
};
const updateComponentHierarchyLevel = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_COMPONENT_HIERARCHY_LEVEL}`, data);
};
const fetchComponentHierarchyLevelPropertiesSuggestions = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_COMPONENT_HIERARCHY_PROP_SUGGESTION}`, data);
};
const addComponentHierarchyLevelProperty = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_COMPONENT_HIERARCHY_PROP}`, data);
};
const updateComponentHierarchyLevelProperty = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_COMPONENT_HIERARCHY_PROP}`, data);
};
const deleteComponentHierarchyLevelProperty = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_COMPONENT_HIERARCHY_PROP}`, data);
};
const updateComponentHierarchyComponent = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_COMPONENT_HIERARCHY_COMPONENT}`, data);
};
const fetchUnassignedComponents = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_UNASSIGNED_COMPONENTS}`, data);
};
const fetchComponentHierarchyComponents = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_COMPONENT_HIERARCHY_COMPONENT}`, data);
};
const fetchComponentHierarchyComponentDetails = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.FETCH_COMPONENT_DETAILS}${generateQueryParams(params)}`);
};
const deleteComponentHierarchyLevel = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_COMPONENT_HIERARCHY_LEVEL}`, data);
};
const fetchComponentHierarchyComponentProperties = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_COMPONENT_PROPERTIES}`, data);
};

export default {
  fetchComponentHierarchyLevels,
  createComponentHierarchyLevels,
  fetchComponentHierarchyLevelDetails,
  updateComponentHierarchyLevel,
  fetchComponentHierarchyLevelPropertiesSuggestions,
  addComponentHierarchyLevelProperty,
  updateComponentHierarchyLevelProperty,
  deleteComponentHierarchyLevelProperty,
  updateComponentHierarchyComponent,
  fetchUnassignedComponents,
  fetchComponentHierarchyComponents,
  fetchComponentHierarchyComponentDetails,
  deleteComponentHierarchyLevel,
  fetchComponentHierarchyComponentProperties,
};
