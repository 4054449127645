import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Helpers from '../../../common/helpers';

import Lottie from 'react-lottie';
import SmallDropdown from '../../../common/small-dropdown/components/small-dropdown';

import { animation } from '../../../common/global-loader/constants/demo';
import fields, { projectStatus } from '../constants/project-fields';

import { isEmpty } from 'lodash';

class InspectionItem extends Component {
  handleDateClick = () => {
    const { project, inspection, handleViewInspection } = this.props;

    if (inspection[fields.inspection.STATUS] === projectStatus.NEW) {
      return;
    }

    handleViewInspection(project.ID, inspection.ID);
  };

  render = () => {
    const { t } = this.context;
    const { project, inspection, getInspectionClickAction, inspectionMenu, handleViewInspection } = this.props;

    return (
      <div key={`${project.ID}__${inspection.ID}`} className="sub-projects__wrapper">
        <p onClick={this.handleDateClick} className={`f-primary sub-projects__title ${inspection[fields.inspection.STATUS] === projectStatus.NEW ? 'noselect' : 'sub-projects__title-clickable'}`}>
          {Helpers.getDateFromUnix(inspection.InspectionDate)}
        </p>
        {inspection[fields.inspection.STATUS] === projectStatus.NEW ? (
          <Fragment>
            <div className="sub-projects__processing">
              <p className="f-secondary-dark text noselect">{t('PROJECT.PROCESSING_FILE')}</p>
              <Lottie
                options={{
                  animationData: animation,
                  loop: true,
                  autoplay: true,
                }}
                height={30}
                width={30}
              />
            </div>
            {!isEmpty(inspectionMenu) && (
              <SmallDropdown actionsMenu={inspectionMenu} getClickAction={action => getInspectionClickAction(action, project, inspection)} offsetX={10} className="processing" />
            )}
          </Fragment>
        ) : (
          <div className="inspection-actions">
            <p className="sub-projects__link noselect" onClick={() => handleViewInspection(project.ID, inspection.ID)} data-cy="sub-project-view-button">
              {t('PROJECT.VIEW_SITE')}
            </p>
            {!isEmpty(inspectionMenu) && <SmallDropdown actionsMenu={inspectionMenu} getClickAction={action => getInspectionClickAction(action, project, inspection)} offsetX={10} />}
          </div>
        )}
      </div>
    );
  };
}

InspectionItem.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default InspectionItem;
