export const tabConstants = {
  permits: {
    tabKey: 'PERMITS',
    title: 'TEMPLATES.TAB_TITLE_1',
  },
  isolations: {
    tabKey: 'ISOLATIONS',
    title: 'TEMPLATES.TAB_TITLE_2',
  },
  senseChecks: {
    tabKey: 'SENSE_CHECKS',
    title: 'TEMPLATES.TAB_TITLE_3',
  },
};
