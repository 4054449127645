import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Helpers from '../../helpers';
import SearchInput from '../../input/components/search-input';
import ReducerHelpers from '../../reducer-helpers';
import '../styles/input.scss';
import CustomCheckBox from './custom-checkbox';

const CustomMultiSelectV2 = (props, { t }) => {
  const {
    valueField = 'value',
    textField = 'label',
    data = [],
    size = 'sm',
    disabled = false,
    labelClass = '',
    showError = true,
    isEnhancedDesignCustomProp = false,
    input,
    className,
    meta,
    label,
    labelActions,
    onSearch,
    shouldRenderPortal,
    portalProps,
    isRequired,
  } = props;

  const [identificator, setIdentificator] = useState('');
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const handleSearch = e => {
    const searchText = e.target.value;
    onSearch && onSearch(searchText);
    if (searchText === '' || !filteredData || filteredData?.length === 0) {
      setFilteredData(data);
    } else {
      const matchedData = data.filter(d => d[textField].toLowerCase().includes(searchText.toLowerCase()));
      setFilteredData(matchedData);
    }
  };

  const getSearchInputValue = () => (input?.value?.length === 1 ? input?.value[0][textField] : input?.value?.length > 1 ? t('MULTIPLE', { numberOfItems: input?.value.length }) : t('SEARCH'));

  const handleSelect = (item, index) => {
    const selectedItems = input?.value;
    if (isEmpty(item)) {
      return;
    }
    if ((selectedItems || []).findIndex(mp => mp?.[valueField] === item[valueField]) > -1) {
      const newData = ReducerHelpers.removeItemByProp(selectedItems, item, valueField);
      input?.onChange([...newData]);
    } else {
      const newData = [...selectedItems, item];
      input?.onChange(newData);
    }
  };

  useEffect(() => {
    setIdentificator(`${props.id}_${Helpers.uuid4()}`);
  }, [props.id]);

  return (
    <div className={`field-wrapper multiselect ${className || ''}`} id={identificator}>
      {label && (
        <div className="inline-wrapper">
          <label className={`field-wrapper__label ${labelClass}`}>{`${t(label)}${isRequired ? '*' : ''}`}</label>
          {!isEmpty(labelActions) &&
            labelActions.map((el, index) => (
              <el.IconComponent key={index} onClick={el.onClick} className={`${el.iconClass || ''} label-container__icon ${el.type || ''} label-container__icon--active svg-gray-g`} />
            ))}
        </div>
      )}
      <div className={`input-wrapper ${isEnhancedDesignCustomProp ? 'enhanced-design-custom-prop' : ''}`}>
        <div className={`select-container ${disabled ? 'disabled' : ''} ${size}`}>
          <SearchInput
            onChange={handleSearch}
            onInputFocus={() => setFilteredData(data)}
            placeholder={getSearchInputValue()}
            wrapperClass={getSearchInputValue() !== t('SEARCH') ? 'highlight-placeholder' : ''}
            includeDropdown={true}
            keepDropdownVisible={false}
            items={filteredData}
            isDropdownDataLoading={false}
            shouldRenderPortal={shouldRenderPortal}
            portalProps={portalProps}
            showSearchIcon={false}
            renderItem={(item, index) => {
              return (
                <div
                  className="dropdown-item-wrapper"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleSelect(item, index);
                  }}
                  key={`dropdown-item-${item[valueField]}`}
                >
                  <CustomCheckBox
                    meta={{}}
                    input={{
                      value: (input?.value || []).findIndex(c => c[valueField] === item[valueField]) > -1,
                      onChange: e => {
                        e.stopPropagation();
                        handleSelect(item, index);
                      },
                    }}
                    id={`row-${index}`}
                  />
                  <p id={`row-${index}`} className="pointer">
                    {item[textField]}
                  </p>
                </div>
              );
            }}
          />
          {showError && <div className={`error-message${meta?.error ? '--visible' : ''}`}>{meta?.error && t(meta.error)}</div>}
        </div>
      </div>
    </div>
  );
};

CustomMultiSelectV2.contextTypes = {
  t: PropTypes.func.isRequired,
};

CustomMultiSelectV2.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  labelClass: PropTypes.string,
  label: PropTypes.string,
  labelActions: PropTypes.array,
  disabled: PropTypes.bool,
  valueField: PropTypes.string,
  textField: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'lg']),
  onSearch: PropTypes.func,
  showError: PropTypes.bool,
  isEnhancedDesignCustomProp: PropTypes.bool,
  shouldRenderPortal: PropTypes.bool,
  portalProps: PropTypes.object,
};

export default CustomMultiSelectV2;
