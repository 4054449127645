import React from 'react';
import PropTypes from 'prop-types';

import RenderIf from '../../render-if/components/render-if';
import Icon from '../../icon/components/icon';
import Helpers from '../../helpers';
import { modules } from '../../../app/inspections/constants/constants';

const HistoryItemComponentChange = ({ from, to, getRedirectPath }) => {
  const getObject = value => {
    if (!value) {
      return {};
    }

    if (value && parseInt(value)) {
      return { ID: parseInt(value), Name: '' };
    } else {
      let res = {};

      try {
        return JSON.parse(value);
      } catch (err) {
        return res;
      }
    }
  };

  const redirectPathFrom = getRedirectPath(getObject(from)?.ID, modules.components),
    redirectPathTo = getRedirectPath(getObject(to)?.ID, modules.components);

  return (
    <div className="history-item-display">
      <RenderIf if={from}>
        <div className={`f-secondary-green history-item-component-display__item${!redirectPathFrom ? ' disabled' : ''}`} onClick={() => redirectPathFrom && Helpers.goTo(redirectPathFrom)}>{`#${
          getObject(from)?.ID
        } ${getObject(from)?.Name}`}</div>
      </RenderIf>
      <Icon name="arrow-right" size="sm" className="history-item-display__icon" />

      <RenderIf if={to}>
        <div className={`f-secondary-green history-item-component-display__item${!redirectPathTo ? ' disabled' : ''}`} onClick={() => redirectPathTo && Helpers.goTo(redirectPathTo)}>{`#${
          getObject(to)?.ID
        } ${getObject(to)?.Name}`}</div>
      </RenderIf>
    </div>
  );
};
HistoryItemComponentChange.contextTypes = {
  t: PropTypes.func.isRequired,
};

HistoryItemComponentChange.propTypes = {
  from: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  to: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  getRedirectPath: PropTypes.func.isRequired,
};

export default HistoryItemComponentChange;
