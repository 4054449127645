import React from 'react';
import PropTypes from 'prop-types';

import SmallDropdown from '../../../common/small-dropdown/components/small-dropdown';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../common/permissions-constants';
import { steps, fields, statuses, confirmationModalTypes } from '../constants/checklists-procedures-constants';
import { openCreateChecklistsForm } from '../helpers/checklists-procedures-management-helpers';

const TableDropdown = ({ item, queryParams, toggleConfirmationModal, handleDownloadPDF, isDownloadInProgress }, { t }) => {
  const translationData = {
    name: item[fields.name],
  };

  const menuOptions = [
    {
      title: 'CHECKLISTS_PROCEDURES_LIST.VIEW',
      action: () => openCreateChecklistsForm('view', steps.firstStep, item[fields.id], queryParams),
    },
    {
      title: 'CHECKLISTS_PROCEDURES_LIST.EDIT',
      action: () => openCreateChecklistsForm('edit', steps.firstStep, item[fields.id], queryParams),
      access: {
        visibleFor: PERMISSIONS[PERMISSION_TYPES.checklists].edit.name,
        id: item[fields.createdBy],
        ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.checklists].create.name,
      },
      isDisabled: item[fields.status] === statuses.live.value,
    },
    {
      title: 'CHECKLISTS_PROCEDURES_LIST.CHANGE_TO_RAISED',
      isHidden: item[fields.status] !== statuses.draft.value,
      action: () =>
        toggleConfirmationModal(
          true,
          confirmationModalTypes.changeStatus,
          { ChecklistAndProcedureTemplateID: item[fields.id], [fields.status]: statuses.raised.value },
          { ...translationData, ...{ status: t('CHECKLISTS_PROCEDURES_LIST.RAISED_STATUS') } }
        ),
      access: {
        visibleFor: PERMISSIONS[PERMISSION_TYPES.checklists].edit.name,
        id: item[fields.createdBy],
        ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.checklists].create.name,
      },
    },
    {
      title: 'CHECKLISTS_PROCEDURES_LIST.CHANGE_TO_DRAFT',
      isHidden: item[fields.status] !== statuses.raised.value,
      action: () =>
        toggleConfirmationModal(
          true,
          confirmationModalTypes.changeStatus,
          { ChecklistAndProcedureTemplateID: item[fields.id], [fields.status]: statuses.draft.value },
          { ...translationData, ...{ status: t('CHECKLISTS_PROCEDURES_LIST.DRAFT_STATUS') } }
        ),
      access: {
        visibleFor: PERMISSIONS[PERMISSION_TYPES.checklists].edit.name,
        id: item[fields.createdBy],
        ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.checklists].create.name,
      },
    },
    {
      title: 'CHECKLISTS_PROCEDURES_LIST.CHANGE_TO_LIVE',
      isHidden: item[fields.status] !== statuses.raised.value,
      action: () =>
        toggleConfirmationModal(
          true,
          confirmationModalTypes.changeStatus,
          { ChecklistAndProcedureTemplateID: item[fields.id], [fields.status]: statuses.live.value },
          { ...translationData, ...{ status: t('CHECKLISTS_PROCEDURES_LIST.LIVE_STATUS') } },
          true
        ),
      access: {
        visibleFor: PERMISSIONS[PERMISSION_TYPES.checklists].approve.name,
      },
    },
    {
      title: 'CHECKLISTS_PROCEDURES_LIST.CHANGE_TO_RAISED',
      isHidden: item[fields.status] !== statuses.live.value,
      action: () =>
        toggleConfirmationModal(
          true,
          confirmationModalTypes.changeStatus,
          { ChecklistAndProcedureTemplateID: item[fields.id], [fields.status]: statuses.raised.value },
          { ...translationData, ...{ status: t('CHECKLISTS_PROCEDURES_LIST.RAISED_STATUS') } },
          true
        ),
      access: {
        visibleFor: PERMISSIONS[PERMISSION_TYPES.checklists].approve.name,
      },
    },
    {
      title: 'CHECKLISTS_PROCEDURES_LIST.EXPORT_PDF',
      action: () => handleDownloadPDF(item[fields.id]),
      isDisabled: isDownloadInProgress,
    },
    {
      title: 'CHECKLISTS_PROCEDURES_LIST.DUPLICATE',
      action: () => toggleConfirmationModal(true, confirmationModalTypes.duplicate, { [fields.id]: item[fields.id] }, translationData),
      access: {
        visibleFor: PERMISSIONS[PERMISSION_TYPES.checklists].edit.name,
        id: item[fields.createdBy],
        ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.checklists].create.name,
      },
      isDisabled: item[fields.status] === statuses.live.value,
    },
    {
      title: 'CHECKLISTS_PROCEDURES_LIST.DELETE',
      action: () => toggleConfirmationModal(true, confirmationModalTypes.delete, { ChecklistAndProcedureTemplateID: item[fields.id] }, translationData),
      access: {
        visibleFor: PERMISSIONS[PERMISSION_TYPES.checklists].delete.name,
      },
      separator: true,
      isHighlighted: true,
      isDisabled: item[fields.status] === statuses.live.value,
    },
  ];

  return (
    <SmallDropdown
      className="page-navigation__wrapper__title-container__menu__dropdown"
      actionsMenu={menuOptions}
      getClickAction={(action, b, selfClose, e) => {
        e?.preventDefault && e.preventDefault();
        selfClose();
        if (action) {
          if (typeof action === 'function') {
            action();
          }
        }
      }}
      offsetX={10}
    />
  );
};

TableDropdown.contextTypes = {
  t: PropTypes.func.isRequired,
};

TableDropdown.propTypes = {
  item: PropTypes.shape({
    ID: PropTypes.number.isRequired,
    Name: PropTypes.string.isRequired,
    Status: PropTypes.string.isRequired,
    Description: PropTypes.string,
    CreatedAt: PropTypes.number.isRequired,
    CreatedBy: PropTypes.number.isRequired,
    CreatedByName: PropTypes.string.isRequired,
  }).isRequired,
  queryParams: PropTypes.string,
  toggleConfirmationModal: PropTypes.func.isRequired,
};

export default TableDropdown;
