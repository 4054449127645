import React, { Component } from 'react';

class CustomLabel extends Component {
  render() {
    const { labels } = this.props;
    return (
      <label className={`field-wrapper__label`}>
        {labels.map((item, index) => {
          if (item.action)
            return (
              <em key={index} className="clickable" onClick={item.action}>
                {item.key}
              </em>
            );
          return item.key;
        })}
      </label>
    );
  }
}

export default CustomLabel;
