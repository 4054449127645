import ActionTypes from '../constants/action-types';

const initialState = {
  createProjectInProgress: false,
  contactList: [],
  fetchContactError: null,
};

export const createProjectReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case ActionTypes.HANDLE_CREATE_PROJECT:
      return { ...state, createProjectInProgress: payload.data };
    case ActionTypes.GET_CONTACT_LIST_SUCCESS:
      return { ...state, contactList: payload.data || [] };
    case ActionTypes.GET_CONTACT_LIST_FAILURE:
      return { ...state, fetchContactError: payload.data };
    default:
      return state;
  }
};
