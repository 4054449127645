import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { chain, isEmpty, keys, pick, values } from 'lodash';

import ExpandableSection from '../../../../common/expandable-section/components/expandable-section';
import Button from '../../../../common/form/components/button';
import CheckBox from '../../../../common/form/components/custom-checkbox';
import BasicInput from '../../../../common/form/components/input';

import { FORMS } from '../../../../common/constants';
import { PERMISSIONS, PERMISSION_TYPES, excludedProps } from '../../../../common/permissions-constants';
import { roleFields } from '../../constants/role-constants';

import '../../styles/create-role-popup.scss';

class RoleForm extends Component {
  componentDidMount = () => {
    const { initialValues, change } = this.props;

    if (!isEmpty(initialValues)) {
      //for edit mode select "ALL" checkbox if all are selected form it's group
      Object.values(PERMISSION_TYPES).forEach(value => {
        const allValuesCount = (
          chain(initialValues)
            .keys()
            .filter(item => item.startsWith(value))
            .value() || []
        ).length;

        //remove other props like disabled etc
        const availableValues = chain(PERMISSIONS[value])
          .keys()
          .filter(item => {
            return (values(excludedProps) || []).indexOf(item) === -1;
          })
          .value().length;

        if (allValuesCount === availableValues) {
          change(value, true);
        }
      });
    }
  };

  handleSelectAllFromGroup = (checked, permissionType) => {
    const { change } = this.props;
    const vals = values(PERMISSIONS[permissionType]);

    vals.forEach(element => {
      change(element.name, checked);
    });
  };

  handleBasePermission = (checked, permissionType, { isBase, name }) => {
    const { change, formValues } = this.props;
    //  handle deselect all when base permission not checked
    if (!checked && isBase) {
      const vals = [...values(PERMISSIONS[permissionType]), { name: permissionType }];
      vals.forEach(element => {
        change(element.name, false);
      });
    } else if (checked && !isBase && PERMISSIONS[permissionType].view) {
      change(PERMISSIONS[permissionType].view.name, true);
    }

    //handle if all are selected and if not
    const totalPermissions = (values(PERMISSIONS[permissionType]) || []).length;
    const allCheckboxes = { ...formValues, [name]: checked };

    const totalSectionChecked = (
      chain(allCheckboxes)
        .keys()
        .filter(key => {
          return key.startsWith(`${permissionType}:`) && allCheckboxes[key];
        })
        .value() || []
    ).length;

    if (totalPermissions === totalSectionChecked) {
      change(permissionType, true);
    } else {
      change(permissionType, false);
    }
  };

  render() {
    const { t } = this.context;
    const { handleSubmit, submitButtonText = 'ROLE_FORM_POPUP.SUBMIT', submitForm } = this.props;

    return (
      <form
        onSubmit={handleSubmit(vals => {
          // Remove "ALL" input values
          const nonCheckboxFields = [roleFields.name, roleFields.description];
          const READINGS_ADDITIONAL_PERMISSIONS = ['MeasurementPoint:View', 'MeasurementReading:View', 'MeasurementLocation:View', 'ReadingsAndGaugesGroup:View'];

          const res = chain(vals)
            .omit(nonCheckboxFields)
            .reduce((object, value, key) => {
              if (key.indexOf(':') > -1 && value) {
                object[key] = value;
              }
              return object;
            }, {})
            .value();

          // Add hardcoded "Components:View" permission
          let permissions = [...keys(res), PERMISSIONS[PERMISSION_TYPES.components].view.name];

          // Check if "ReadingsAndGauges:View" is selected
          if (res[PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].view.name]) {
            // Add the additional permissions
            permissions = permissions.concat(READINGS_ADDITIONAL_PERMISSIONS);
          } else {
            // Remove the additional permissions if "ReadingsAndGauges:View" is unselected
            permissions = permissions.filter(permission => !READINGS_ADDITIONAL_PERMISSIONS.includes(permission));
          }

          submitForm({ ...pick(vals, nonCheckboxFields), Permissions: permissions });
        })}
        onChange={this.handleSelectAll}
        data-cy="role-form"
      >
        <Field id={roleFields.name} name={roleFields.name} component={BasicInput} placeholder={'ROLE_FORM_POPUP.NAME'} label={'ROLE_FORM_POPUP.NAME'} />
        <Field id={roleFields.description} name={roleFields.description} component={BasicInput} placeholder={'ROLE_FORM_POPUP.DESCRIPTION'} label={'ROLE_FORM_POPUP.DESCRIPTION'} />
        {Object.keys(PERMISSIONS).map((permissionType, index) => {
          const { [excludedProps.options]: options, ...section } = PERMISSIONS[permissionType];
          if (options?.disabled) {
            return null;
          }

          return (
            <ExpandableSection key={index} expanded={index === 0} title={t(`PERMISSIONS.${permissionType}`)}>
              <Field
                id={permissionType}
                name={permissionType}
                component={CheckBox}
                checkboxText={`PERMISSIONS_CHECKBOX.ALL`}
                className={'base'}
                onChange={e => this.handleSelectAllFromGroup(e.target.checked, permissionType)}
                data-cy="permissions-all"
              />
              <div className="sub-base">
                {Object.keys(section).map((sectionKey, ind) => {
                  const permission = section[sectionKey];
                  if (permission.disabled) {
                    return <CheckBox key={ind} input={{ value: permission.value || false }} disabled checkboxText={`PERMISSIONS_CHECKBOX.${sectionKey}`} />;
                  }

                  return (
                    <Field
                      key={ind}
                      id={permission.name}
                      name={permission.name}
                      component={CheckBox}
                      checkboxText={`PERMISSIONS_CHECKBOX.${sectionKey}`}
                      onChange={e => this.handleBasePermission(e.target.checked, permissionType, permission)}
                    />
                  );
                })}
              </div>
            </ExpandableSection>
          );
        })}
        <Button className="submit-btn" text={t(submitButtonText)} />
      </form>
    );
  }
}
RoleForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  formValues: state.form[FORMS.createRole]?.values || {},
});

RoleForm = reduxForm({
  form: FORMS.createRole,
  enableReinitialize: true,
  validate: values => {
    let errors = {};
    if (!values[roleFields.name]) {
      errors[roleFields.name] = 'ROLE_FORM_POPUP.NAME_VALIDATION';
    }
    return errors;
  },
})(RoleForm);

export default connect(mapStateToProps, null)(RoleForm);
