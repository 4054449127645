import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change, formValueSelector } from 'redux-form';

import PropTypes from 'prop-types';
import PredictiveSettingsForm from './predictive-settings-form';

import { getPredictiveTypesDropdown, updatePredictiveSettings } from '../../actions/ndt-actions';
import PredictiveResults from './predictive-results';

import { formConstants } from './constants/predictive-settings';
import { FORMS } from '../../../../common/constants';

import { chartFilters } from '../../constants/ndt-constants';

import '../../styles/predictive-settings.scss';
import Helpers from '../../../../common/helpers';

class PredictiveSettings extends Component {
  componentDidMount() {
    const { getPredictiveTypesDropdownData, getPredictiveSettings } = this.props;
    getPredictiveSettings();
    getPredictiveTypesDropdownData();
  }

  submitForm = values => {
    const { [formConstants.fields.predictiveType]: predictiveType, ...restValues } = values;
    const { updatePredictiveSettings, measurementId, unit, chartFiltersData } = this.props;
    updatePredictiveSettings(
      {
        ...restValues,
        [formConstants.fields.dateFrom]: restValues[formConstants.fields.dateFrom] ? Helpers.getUnixDate(new Date(restValues[formConstants.fields.dateFrom]).getTime()) : null,
        [formConstants.fields.dateTo]: restValues[formConstants.fields.dateTo] ? Helpers.getUnixDate(new Date(restValues[formConstants.fields.dateTo]).getTime()) : null,
      },
      measurementId,
      unit,
      chartFiltersData
    );
  };

  render() {
    const { submitForm } = this;
    const { initialValues, changeField, predictiveTypes, severityColors, predictiveTypeID, dateFrom, dateTo, dateRange, ndtPredictiveResults, loadingPredictiveResults } = this.props;

    return (
      <div className="predictive-settings">
        <PredictiveSettingsForm
          onChange={submitForm}
          predictiveTypeID={predictiveTypeID}
          dateFrom={dateFrom}
          dateTo={dateTo}
          dateRange={dateRange}
          initialValues={initialValues}
          changeField={changeField}
          predictiveTypes={predictiveTypes}
        />
        <PredictiveResults severityColors={severityColors} results={ndtPredictiveResults} loadingResults={loadingPredictiveResults} />
      </div>
    );
  }
}
PredictiveSettings.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  const { [chartFilters.dateTo]: defaultDateTo, [chartFilters.dateFrom]: defaultDateFrom } = state.ndtReducer.ndtChartFilters;
  const { [formConstants.fields.dateFrom]: initialDateFrom, [formConstants.fields.dateTo]: initialDateTo } = state.ndtReducer.predictiveSettings;

  const selector = formValueSelector(FORMS.ndtPredictiveSettings);
  return {
    initialValues: {
      ...props.predictiveSettings,
      [formConstants.fields.dateFrom]: initialDateFrom ? new Date(initialDateFrom * 1000) : new Date(defaultDateFrom),
      [formConstants.fields.dateTo]: initialDateTo ? new Date(initialDateTo * 1000) : new Date(defaultDateTo),
    },
    predictiveTypeID: selector(state, formConstants.fields.predictiveTypeID),
    dateFrom: selector(state, formConstants.fields.dateFrom) || new Date(defaultDateFrom),
    dateTo: selector(state, formConstants.fields.dateTo) || new Date(defaultDateTo),
    dateRange: selector(state, formConstants.fields.dateRange),
    predictiveTypes: state.ndtReducer.predictiveTypes.map(el => ({ ...el, label: el.Value, value: el.ID, description: el.Description })),
    severityColors: state.themeReducer.severityColors,
  };
};

const mapDispatchToProps = dispatch => ({
  changeField: (formName, fieldName, value) => dispatch(change(formName, fieldName, value)),
  getPredictiveTypesDropdownData: () => dispatch(getPredictiveTypesDropdown()),
  updatePredictiveSettings: (data, measurementId, unit, chartFiltersData, callback) => dispatch(updatePredictiveSettings(data, measurementId, unit, chartFiltersData, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PredictiveSettings);
