import React from 'react';
import PropTypes from 'prop-types';

import { isEmpty } from 'lodash';

import '../styles/progress-bar.scss';

const ProgressBar = props => {
  return (
    <div style={{ height: props.height || '20px' }} className={`progress-bar ${props.isRounded ? 'rounded' : ''}`}>
      <div className="percentage-bars">
        {!isEmpty(props.percentages) &&
          props.percentages.map((percentage, index) => <div key={index} style={{ width: `${percentage}%`, backgroundColor: props.colors[index] }} className="percentage-bars__progress" />)}
      </div>
    </div>
  );
};

ProgressBar.contextTypes = {
  t: PropTypes.func.isRequired,
};

ProgressBar.propTypes = {
  percentages: PropTypes.arrayOf(PropTypes.number).isRequired,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ProgressBar;
