import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Tools } from '@tarik.djurdjevic/react-sketch';
import { ReactComponent as SelectIcon } from '../assets/select.svg';
import { ReactComponent as MoveIcon } from '../assets/move.svg';
import { ReactComponent as PanIcon } from '../assets/pan-cursor.svg';

import { formConstants } from '../../inspections/constants/defect-constants';

import ColorPicker from '../../inspections/components/common/input/color-picker';

class DrawingTools extends Component {
  render() {
    const { selectedTool, fillColor, selectDrawingTool, onColorChange, enableDrawing, disableColorPicker } = this.props;
    const { t } = this.context;

    return (
      <div className="drawing-tools">
        {onColorChange && (
          <ColorPicker
            color={fillColor}
            onColorChange={onColorChange}
            changeField={() => null}
            name={formConstants.fields.color}
            defaultColor={'#8E919B'}
            className="inline"
            showLabel={false}
            size="small"
            placeholder={t('INSPECTION_SETTINGS.FORM_COLOR')}
            hideBorder
            title={t('DRAWING_TOOLBOX.COLOR_PICKER')}
            disableColorPicker={disableColorPicker}
          />
        )}
        <div onClick={() => selectDrawingTool(Tools.DefaultTool)} className="tools-container__rounded-container" title={t('DRAWING_TOOLBOX.MOVE_IMAGE')}>
          <PanIcon width={14} height={14} className={`image ${selectedTool === Tools.DefaultTool ? 'active' : 'svg-gray-g'}`} />
        </div>
        <div onClick={() => selectDrawingTool(Tools.Select)} className="tools-container__rounded-container" title={t('DRAWING_TOOLBOX.MANIPULATE_DRAWING')}>
          <MoveIcon className={`image ${selectedTool === Tools.Select ? 'active' : 'svg-gray'}`} />
        </div>
        {enableDrawing && (
          <div onClick={() => selectDrawingTool(Tools.Rectangle)} className="tools-container__rounded-container" title={t('DRAWING_TOOLBOX.CREATE_DRAWING')}>
            <SelectIcon className={`image ${selectedTool === Tools.Rectangle ? 'active' : 'svg-gray-g'}`} />
          </div>
        )}
      </div>
    );
  }
}

DrawingTools.contextTypes = {
  t: PropTypes.func.isRequired,
};

class DrawingToolsFullscreen extends Component {
  render() {
    const { selectedTool, fillColor, selectDrawingTool, onColorChange, enableDrawing } = this.props;
    const { t } = this.context;

    return (
      <Fragment>
        {onColorChange && (
          <div className="tools-container__rounded-container transparent">
            <ColorPicker
              color={fillColor}
              onColorChange={onColorChange}
              changeField={() => null}
              name={formConstants.fields.color}
              defaultColor={'#8E919B'}
              className="inline"
              showLabel={false}
              size="medium"
              placeholder={t('INSPECTION_SETTINGS.FORM_COLOR')}
              hideBorder
            />
          </div>
        )}
        <div onClick={() => selectDrawingTool(Tools.DefaultTool)} className="tools-container__rounded-container">
          <PanIcon width={14} height={14} className={`image ${selectedTool === Tools.DefaultTool ? 'active' : 'svg-gray-g'}`} />
        </div>
        <div onClick={() => selectDrawingTool(Tools.Select)} className="tools-container__rounded-container">
          <MoveIcon className={`image ${selectedTool === Tools.Select ? 'active' : 'svg-gray'}`} />
        </div>
        {enableDrawing && (
          <div onClick={() => selectDrawingTool(Tools.Rectangle)} className="tools-container__rounded-container">
            <SelectIcon className={`image ${selectedTool === Tools.Rectangle ? 'active' : 'svg-gray-g'}`} />
          </div>
        )}
      </Fragment>
    );
  }
}

DrawingToolsFullscreen.contextTypes = {
  t: PropTypes.func.isRequired,
};

export { DrawingToolsFullscreen, DrawingTools };
