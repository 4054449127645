import { find, findLast, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { change, getFormValues, updateSyncErrors } from 'redux-form';
import { FORMS } from '../../../../../../common/constants';
import Helpers from '../../../../../../common/helpers';
import { setGenericNotification } from '../../../../../../common/notification/actions/action-creators';
import Stepper from '../../../../../../common/stepper/components/stepper';
import { userTeamFields } from '../../../../../../common/user-team/constants/constants';
import { fetchComponentsToLink } from '../../../../../document-management/actions/dm-api-calls';
import { getDefectDetails } from '../../../../actions/inspection-actions';
import { defaultComponentName } from '../../../../constants/component-constants';
import { detailsPages, measurementTypes, modules } from '../../../../constants/constants';
import { filterProps } from '../../../right-toolbar/readings-and-gauges/constants/measurement-location-constants';
import { fetchUnits } from '../../actions/measurement-group-actions';
import { createMeasurementPoint, fetchAlarmsDescriptors } from '../../actions/measurement-point-actions';
import { ALARMS, formConstants as alarmFormConstants, defaultAlarmValues, descriptorFields } from '../../constants/alarm-constants';
import { fields, viewOptions } from '../../constants/constants';
import { formConstants, steps } from '../../constants/measurement-point-constants';
import '../../styles/create-measurement-point-modal.scss';
import { alarmsValidate } from '../../validators/alarms-validator';
import CreateMeasurementPointForm from './create-measurement-point-form';

const CreateMeasurementPointModal = (props, { t }) => {
  const {
    user,
    inspectionId,
    projectID,
    measurementLocationID,
    measurementPointsFilter,
    className,
    severityColors,
    searchComponents,
    closeAction,
    formValues,
    changeField,
    createMeasurementPoint,
    setGenericNotification,
    fetchAlarmsDescriptors,
    handleCreateMPSuccess = () => null,
    noRedirect,
    getDefectDetails,
    inspectionDetails,
    navigateToPath,
    updateSyncErrors,
    fetchUnits,
    measurementLocationComponentId,
    mpList,
    mpFilters,
  } = props;

  const [activeStep, setActiveStep] = useState(steps.firstStep);
  const [lastStep, setLastStep] = useState(steps.secondStep);
  const [stepperData, setStepperData] = useState([
    {
      name: 'MEASUREMENT_POINT_DETAILS.STEPPER.DETAILS_STEP.TITLE',
      stepValue: steps.firstStep,
      description: 'MEASUREMENT_POINT_DETAILS.STEPPER.DETAILS_STEP.DESCRIPTION',
    },
    {
      name: 'MEASUREMENT_POINT_DETAILS.STEPPER.ALARMS_STEP.TITLE',
      stepValue: steps.secondStep,
      description: 'MEASUREMENT_POINT_DETAILS.STEPPER.ALARMS_STEP.DESCRIPTION',
    },
  ]);
  const [selectedComponent, setSelectedComponent] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [alarmDescriptors, setAlarmDescriptors] = useState([]);
  const [measurementLocationComponent, setMeasurementLocationComponent] = useState(null);

  const fetchMeasurementLocationComponent = useCallback(() => {
    getDefectDetails(
      { [formConstants.fields.id]: measurementLocationComponentId || inspectionDetails.DefaultComponent, SystemType: measurementTypes.component },
      component => {
        setMeasurementLocationComponent(component);
      },
      false
    );
  }, [getDefectDetails, measurementLocationComponentId, inspectionDetails]);

  useEffect(() => {
    const mappedStepperData = stepperData.map(step => ({ ...step, isHidden: step.access ? !Helpers.hasAccess({ user, ...step.access }) : false }));
    const lastStepData = findLast(mappedStepperData, step => !step.isHidden);
    const newLastStep = !isEmpty(lastStepData) ? lastStepData.stepValue : lastStep;
    setLastStep(newLastStep);
    setStepperData(mappedStepperData.filter(step => !step.isHidden));
    setInitialAlarmValues();
    fetchMeasurementLocationComponent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = values => {
    if (activeStep === lastStep) {
      // Run validation before proceeding
      const errors = alarmsValidate(values);

      // Check if there are validation errors for alarms field
      const alarmsErrors = errors?.[formConstants.fields.alarms];

      const hasAlarmsErrors = alarmsErrors && alarmsErrors.some(error => !isEmpty(error));

      // If there are validation errors, update the form state and do not proceed with submission
      if (hasAlarmsErrors) {
        updateSyncErrors(FORMS.createMeasurementPointForm, errors);
        return; // Stop the submit if validation fails
      }

      // Proceed with submission if no validation errors
      handleCreateMeasurementPoint(values);
    } else if (activeStep !== lastStep) {
      setStep(true);
    }
  };

  const setStep = forward => {
    const nextStep = forward ? activeStep + 1 : activeStep - 1;
    if (nextStep < steps.firstStep || nextStep > lastStep) {
      closeAction();
      return;
    }
    setActiveStep(nextStep);
  };

  const handleCreateMeasurementPoint = values => {
    const alarms = (values[formConstants.fields.alarms] || [])
      .filter(alarm => alarm[alarmFormConstants.fields.enabled.name])
      .map(alarm => {
        const notifyAssignees = (alarm[alarmFormConstants.fields.notifyAssignees.name] || []).map(assignee => ({
          AssigneeID: assignee[userTeamFields.id],
          AssigneeType: assignee[userTeamFields.type],
        }));

        return {
          ...alarm,
          [alarmFormConstants.fields.descriptor.name]: alarm[alarmFormConstants.fields.descriptor.name][descriptorFields.id],
          [alarmFormConstants.fields.notifyAssignees.name]: notifyAssignees,
          [alarmFormConstants.fields.alarmLevel.name]: parseFloat(alarm?.[alarmFormConstants.fields.alarmLevel.name]),
        };
      });

    createMeasurementPoint({ ...values, [formConstants.fields.alarms]: alarms }, projectID, measurementLocationID, measurementPointsFilter, newData => {
      setStep(true);
      setGenericNotification({
        isDisplayed: true,
        type: 'success',
        icon: 'checkmark-outline',
        text: t('MEASUREMENT_POINT_DETAILS.CREATE_SUCCESS_NOTIFICATION', { mpName: newData[formConstants.fields.name] }),
      });
      if (!noRedirect) {
        const newPathItem = {
          [formConstants.fields.name]: newData[formConstants.fields.name],
          queryParams: { type: modules.readingsAndGauges, selected_item: newData[formConstants.fields.id], details: detailsPages.measurementPoint, view: viewOptions.location_and_points },
        };
        navigateToPath(newPathItem);
      } else if (handleCreateMPSuccess && typeof handleCreateMPSuccess === 'function') {
        handleCreateMPSuccess([...mpList, newData], { ...mpFilters, [filterProps.totalItems]: (mpFilters[filterProps.totalItems] || 0) + 1 });
      }
    });
  };

  const addComponentToMeasurementPoint = items => {
    if (isEmpty(items)) {
      if (selectedComponent) {
        setSelectedComponent([]);
        changeField(formConstants.fields.componentId, null);
      } else {
        return;
      }
    } else {
      setSelectedComponent([items[0]]);
      changeField(formConstants.fields.componentId, items[0]?.[formConstants.fields.id]);
    }
  };

  const setInitialAlarmValues = () => {
    fetchAlarmsDescriptors(descriptors => {
      setAlarmDescriptors(descriptors);
      const mappedAlarms = ALARMS.map(alarm => defaultAlarmValues(alarm, descriptors));
      setInitialValues({ [formConstants.fields.alarms]: mappedAlarms });
    });
  };

  const componentPickerAdditionalOptions = [
    {
      onClickHandler: setComponent => {
        setComponent(measurementLocationComponent);
      },
      text: `${t('COPY_FROM')} ${t('READINGS_AND_GAUGES.MEASUREMENT_LOCATION')}`,
    },
  ];

  const activeStepObj = find(stepperData, item => item.stepValue === activeStep);
  return (
    <div className={`create-measurement-point-modal ${className || ''}`}>
      <Stepper {...{ stepperData, activeStep }} showInfoIcon={false} />
      <CreateMeasurementPointForm
        onSubmit={handleSubmit}
        stepAction={setStep}
        activeStep={activeStep}
        activeStepObj={activeStepObj}
        lastStep={lastStep}
        severityColors={severityColors}
        closeCreateMeasurementPointModal={closeAction}
        fetchComponents={searchComponents}
        inspectionId={inspectionId}
        projectID={projectID}
        handleOnComponentSelect={addComponentToMeasurementPoint}
        selectedComponent={selectedComponent}
        formValues={formValues}
        alarmDescriptors={alarmDescriptors}
        initialValues={initialValues}
        componentPickerAdditionalOptions={componentPickerAdditionalOptions}
        defaultComponent={{ [fields.name]: defaultComponentName, [fields.id]: inspectionDetails?.DefaultComponent }}
        fetchUnits={fetchUnits}
        changeField={changeField}
      />
    </div>
  );
};

CreateMeasurementPointModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    severityColors: state.themeReducer.severityColors,
    user: state.userReducer,
    formValues: getFormValues(FORMS.createMeasurementPointForm)(state),
    measurementPointsFilter: state.measurementPointReducer.measurementPointsFilter,
    inspectionDetails: state.inspectionReducer.inspectionDetails,
  };
};

const mapDispatchToProps = dispatch => ({
  searchComponents: (filters, callback, loadingCallback) => dispatch(fetchComponentsToLink(filters, callback, loadingCallback)),
  changeField: (fieldName, value) => dispatch(change(FORMS.createMeasurementPointForm, fieldName, value)),
  createMeasurementPoint: (data, projectID, measurementLocationID, filters, successCallback, errorCallback) =>
    dispatch(createMeasurementPoint(data, projectID, measurementLocationID, filters, successCallback, errorCallback)),
  setGenericNotification: data => dispatch(setGenericNotification(data)),
  fetchAlarmsDescriptors: (successCallback, errorCallback) => dispatch(fetchAlarmsDescriptors(successCallback, errorCallback)),
  getDefectDetails: (defect, callback, legacyProps, includeLoader, saveItem) => dispatch(getDefectDetails(defect, callback, {}, true, saveItem)),
  updateSyncErrors: (formName, error) => dispatch(updateSyncErrors(formName, error)),
  fetchUnits: (filters, callback, filtersCallback, errorCallback, loadingCallback) => dispatch(fetchUnits(filters, callback, filtersCallback, errorCallback, loadingCallback)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateMeasurementPointModal));
