import inspectionWorkflowConstants from '../../inspection-workflow/constants/inspection-workflow-constants';

export const BasicCamera = {
  _colorFrustum: 0x33393f,
  _colorCone: 0x33393f,
  _colorUp: 0xffffff,
  _colorCross: 0xffffff,
  _sphereColor: 0x33393f,
};

export const SelectedCamera = {
  _colorFrustum: 0xffffff,
  _colorCone: 0xffffff,
  _colorUp: 0xffffff,
  _colorCross: 0xffffff,
  _sphereColor: 0xffffff,
};

export const CameraGreen = {
  _colorFrustum: 0x01f6a5, // Corresponds --severity-green
  _colorCone: 0x01f6a5,
  _colorUp: 0xffffff,
  _colorCross: 0xffffff,
  _sphereColor: 0x00c282,
};

export const CameraOrange = {
  _colorFrustum: 0xffa500, // Corresponds --severity-orange
  _colorCone: 0xffa500,
  _colorUp: 0xffffff,
  _colorCross: 0xffffff,
  _sphereColor: 0xffa500,
};

export const CameraYellow = {
  _colorFrustum: 0xe5e500, // Corresponds --severity-yellow
  _colorCone: 0xe5e500,
  _colorUp: 0xffffff,
  _colorCross: 0xffffff,
  _sphereColor: 0xe5e500,
};

export const CameraRed = {
  _colorFrustum: 0xcc0033, // Corresponds --severity-red
  _colorCone: 0xcc0033,
  _colorUp: 0xffffff,
  _colorCross: 0xffffff,
  _sphereColor: 0xcc0033,
};

export default {
  default: BasicCamera,
  selected: SelectedCamera,
  [inspectionWorkflowConstants.severity.green.color]: CameraGreen,
  [inspectionWorkflowConstants.severity.orange.color]: CameraOrange,
  [inspectionWorkflowConstants.severity.yellow.color]: CameraYellow,
  [inspectionWorkflowConstants.severity.red.color]: CameraRed,
};
