import { findIndex } from 'lodash';

import Helpers from '../../../common/helpers';
import { routes } from '../../../common/routes-constants';

export const openCreateChecklistsForm = (mode, step, id, queryParams) => {
  if (!step) {
    return;
  }

  Helpers.goTo(`${routes.protectedRoutes.checklistsProceduresManagement.fullPath}/${mode}/${step}/${id || ''}${queryParams || ''}`);
};

export const getSectionAndQuestionDetails = async (questionId, sections = []) => {
  let result = {};

  for (let i = 0; i < sections.length; i++) {
    const index = findIndex(sections[i].SectionQuestions, { ID: questionId });

    if (index > -1) {
      result = {
        sectionIndex: i,
        components: sections[i].SectionQuestions[index].ComponentIDs || [],
        question: sections[i].SectionQuestions[index],
        questionIndex: index,
      };

      break;
    }
  }

  return result;
};

export const formatSections = async (sections, questionId, areAdditionalFieldsExpanded) => {
  for (let i = 0; i < sections.length; i++) {
    sections[i].SectionQuestions = sections[i].SectionQuestions.map(question => {
      if (questionId && question.ID === questionId) {
        question.areAdditionalFieldsExpanded = areAdditionalFieldsExpanded;
      }

      return question;
    });
  }

  return sections;
};
