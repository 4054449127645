const orientation = {
  grid: 'grid',
  list: 'list',
  full: 'full',
  chart: 'chart',
};

const fields = {
  date: 'InspectionDate',
  projectId: 'ProjectID',
  projectName: 'ProjectName',
};

export default {
  orientation,
  fields,
};
