import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import '../styles/input.scss';

const CustomCheckBox = (
  { input, meta, className = '', id, label, labelClass, disabled, isHidden, checkboxText, CustomLabel, enableError = false, defaultChecked, isRequired, ...restprops },
  context
) => {
  const { t } = context;

  let valueProps = {};
  if (!isEmpty(input)) {
    valueProps = {
      checked: input?.value || false,
    };
  } else {
    valueProps = {
      defaultChecked: defaultChecked || false,
    };
  }

  return (
    <div className={`field-wrapper${disabled ? ' field-wrapper--disabled' : ''} ${className} ${isHidden ? 'hidden-field' : ''}`}>
      {label && (
        <label className={`field-wrapper__label ${labelClass || ''}`} htmlFor={id}>
          {`${t(label)}${isRequired ? '*' : ''}`}
        </label>
      )}
      <div className="input-wrapper">
        <div className={`checkbox-wrapper`}>
          <label className="custom-checkbox">
            <input id={id} disabled={disabled} type="checkbox" {...input} {...restprops} {...valueProps} />
            <span className="checkmark" />
          </label>

          {checkboxText && <label className="field-wrapper__label">{t(checkboxText)}</label>}
          {CustomLabel}
        </div>
        {enableError && <div className={`error-message${meta && meta.error && meta.touched ? '--visible' : ''}`}>{meta && meta.error && t(meta.error)}</div>}
      </div>
    </div>
  );
};

CustomCheckBox.contextTypes = {
  t: PropTypes.func.isRequired,
};

CustomCheckBox.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelClass: PropTypes.string,
  disabled: PropTypes.bool,
  isHidden: PropTypes.bool,
  checkboxText: PropTypes.string,
  CustomLabel: PropTypes.node,
  enableError: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  isRequired: PropTypes.bool,
};

export default CustomCheckBox;
