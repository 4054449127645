import Helpers from '../../../common/helpers';

export const tabConstants = {
  active: {
    tabKey: 'ACTIVE',
    title: 'CONTRACTOR_MANAGEMENT.TAB_TITLE_1',
  },
  archived: {
    tabKey: 'ARCHIVED',
    title: 'CONTRACTOR_MANAGEMENT.TAB_TITLE_2',
  },
};

export const contractorFields = {
  id: 'ID',
  name: 'Name',
  company: 'Company',
  email: 'Email',
  phone: 'Phone',
  permitTestDate: 'PermitTestDate',
  permitTestExpirationDate: 'PermitTestExpirationDate',
  isUser: 'IsUser',
};

export const contractorTableHeader = [
  {
    title: 'CONTRACTORS_TABLE.ID',
    key: contractorFields.id,
    enableSort: true,
  },
  {
    title: 'CONTRACTORS_TABLE.NAME',
    key: contractorFields.name,
    enableSort: true,
  },
  {
    title: 'CONTRACTORS_TABLE.PERMIT_TEST_EXPIRATION_DATE',
    key: contractorFields.permitTestExpirationDate,
    CustomComponent: data => (
      <p className={`${Helpers.isDateInPast(data[contractorFields.permitTestExpirationDate]) ? 'f-secondary-red' : 'f-primary'}`}>
        {Helpers.formatCell(data[contractorFields.permitTestExpirationDate], 'date')}
      </p>
    ),
    enableSort: true,
  },
  {
    title: 'CONTRACTORS_TABLE.COMPANY',
    key: contractorFields.company,
    enableSort: true,
  },
  {
    title: 'CONTRACTORS_TABLE.IS_USER',
    key: contractorFields.isUser,
    type: 'yes-no',
    enableSort: true,
    translatable: true,
  },
  {
    title: 'CONTRACTORS_TABLE.EMAIL',
    key: contractorFields.email,
    enableSort: true,
  },
  {
    title: 'CONTRACTORS_TABLE.PHONE',
    key: contractorFields.phone,
    enableSort: true,
  },
  {
    title: 'CONTRACTORS_TABLE.PERMIT_TEST_DATE',
    key: contractorFields.permitTestDate,
    enableSort: true,
    type: 'date',
  },
];

export const sortingDirection = {
  asc: 'ASC',
  desc: 'DESC',
};

export const filterProps = {
  sortByColumn: 'SortByColumn',
  sortDirection: 'SortDirection',
  perPage: 'PerPage',
  lastSeen: 'LastSeen',
  searchText: 'SearchText',
  hasNext: 'HasNext',
  totalItems: 'TotalItems',
};

export const defaultContractorsFilter = {
  [filterProps.sortByColumn]: contractorFields.id,
  [filterProps.sortDirection]: sortingDirection.asc,
  [filterProps.perPage]: 20,
  [filterProps.lastSeen]: 0,
  [filterProps.searchText]: '',
  [filterProps.hasNext]: false,
};

export const steps = {
  firstStep: 1,
  secondStep: 2,
};

export const fileFields = {
  id: 'FileID',
  name: 'FileName',
  url: 'URL',
};

export const formConstants = {
  fields: {
    id: 'ID',
    name: 'Name',
    company: 'Company',
    email: 'Email',
    phone: 'Phone',
    permitTestDate: 'PermitTestDate',
    permitTestExpirationDate: 'PermitTestExpirationDate',
    createdBy: 'CreatedBy',
    active: 'Active',
    userId: 'UserID',
    userObject: 'UserObject',
    contractorId: 'ContractorID',
    projectId: 'ProjectID',
  },
};

export const userFields = {
  id: 'ID',
  name: 'Name',
  email: 'Email',
  phone: 'Phone',
  company: 'Company',
  notContractor: 'NotContractor',
};

export const imagesValidation = {
  maxFiles: 5,
  value: 50,
  unit: 'mb',
  bytes: 52428800,
};
