const metricFields = {
  key: 'Key',
  value: 'Value',
};

const params = {
  distance: 'DistanceUnit',
  temperature: 'TemperatureUnit',
  theme: 'Theme',
  language: 'Language',
  fullScreenEnabled: 'FullScreenEnabled',
  mfaMethod: 'MFAMethod',
  commentNotificationEnabled: 'CommentNotificationEnabled',
};

const fullScreenData = [
  { [metricFields.value]: true, [metricFields.key]: 'THEME_PICKER.OPTION_FULL_SCREEN' },
  { [metricFields.value]: false, [metricFields.key]: 'THEME_PICKER.OPTION_3D' },
];

const mainProps = [params.distance, params.temperature, params.theme, params.language, params.fullScreenEnabled, params.commentNotificationEnabled];

export { metricFields, params, mainProps, fullScreenData };
