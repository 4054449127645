import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import EmptyState from '../../../../common/empty-state/components/empty-state';
import HistoryItem from '../../../../common/history-item/components/history-item';
import LoadMore from '../../../../common/load-more/components/load-more';
import ToolbarTitle from '../../../../common/toolbar-title/components/toolbar-title';
import RenderIf from '../../../../common/render-if/components/render-if';
import { getHistoryLength } from '../../helpers/inspection-helper';
import { sortingDirection, historyDefaultPagingObj, historyFilterParams, historyItems, measurementModificationEvents } from '../../constants/measurement-constants';
import { getMeasurementHistory } from '../../actions/inspection-actions';

import '../../styles/module-item-history.scss';

class MeasurementHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      history: [],
      paging: historyDefaultPagingObj,
    };
  }

  fetchHistory = () => {
    this.props.getMeasurementHistory(
      {
        ItemId: this.props.measurementID,
        LastSeen: this.state.paging[historyFilterParams.lastSeen],
        PerPage: this.state.paging[historyFilterParams.perPage],
        SortByColumn: historyFilterParams.sortByColumn,
        SortDirection: sortingDirection.desc,
      },
      this.state.history,
      this.state.paging,
      data => this.setState(data)
    );
  };

  componentDidMount = () => {
    this.fetchHistory();
  };

  render() {
    const historyLength = getHistoryLength(
      this.state.history,
      measurementModificationEvents,
      historyItems,
      measurementModificationEvents.measurement_created.event,
      measurementModificationEvents.measurement_deleted.event
    );
    const areHistoryItemsEmpty = !this.state.isLoading && (!this.state.history.length || !historyLength);

    return (
      <div className="module-item-history">
        <ToolbarTitle variant="medium">{'MEASUREMENT_HISTORY.TITLE'}</ToolbarTitle>
        <div className="module-item-history__wrapper">
          <RenderIf if={areHistoryItemsEmpty}>
            <EmptyState title="MEASUREMENT_HISTORY.EMPTY_STATE_TITLE" message="MEASUREMENT_HISTORY.EMPTY_STATE_MESSAGE" />
          </RenderIf>
          <RenderIf if={!areHistoryItemsEmpty}>
            <>
              <div className={this.state.isLoading ? 'background-blurred' : ''}>
                {this.state.history.map(item => (
                  <HistoryItem
                    key={item.id}
                    items={historyItems}
                    item={item}
                    moduleName="MEASUREMENT_HISTORY.MODULE_NAME"
                    events={measurementModificationEvents}
                    moduleCreateEvent={measurementModificationEvents.measurement_created.event}
                    moduleFileUnlinkEvent={measurementModificationEvents.measurement_file_unlinked.event}
                    moduleDeleteEvent={measurementModificationEvents.measurement_deleted.event}
                  />
                ))}
              </div>
              <LoadMore
                disabled={!this.state.paging[historyFilterParams.hasNext] || this.state.isLoading}
                loaded={historyLength}
                total={this.state.paging[historyFilterParams.totalItems]}
                totalPosition="center"
                isLoading={this.state.isLoading}
                label="COMPONENT_HISTORY.LOAD_MORE"
                isFixed
                onClick={this.fetchHistory}
              />
            </>
          </RenderIf>
        </div>
      </div>
    );
  }
}

MeasurementHistory.contextTypes = {
  t: PropTypes.func.isRequired,
};

MeasurementHistory.propTypes = {
  measurementID: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  getMeasurementHistory: (params, history, paging, callback) => dispatch(getMeasurementHistory(params, history, paging, callback)),
});

MeasurementHistory = connect(mapStateToProps, mapDispatchToProps)(MeasurementHistory);

export default MeasurementHistory;
