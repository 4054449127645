import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import InspectionRenderer from '../../../app/inspections/components/left-toolbar/inspection-renderer';

import ComponentList from './component-list';

class ComponentsDisplay3D extends Component {
  render() {
    const { viewer, handleDeleteComponent, selectedComponents, elementsClustered, question, onComponentChange } = this.props;

    return (
      <Fragment>
        <InspectionRenderer
          deselectAll={() => null}
          selectAll={() => null}
          toggleElement={() => null}
          deselectAllTemp={() => null}
          selectAllTemp={() => null}
          toggleElementTemp={() => null}
          updateElementGeometry={element => null}
          selectElement={data => {
            if (data.NumberOfPoints === 1) {
              // disables selecting clusters
              onComponentChange(data);
            }
          }}
          elements={elementsClustered.map(el => ({ ...el, visible: true }))}
          selectedDefect={null}
          viewer={viewer}
        >
          {({ elementClickHandler, elementShowHandler, elementHideHandler, selectAllHandler, deselectAllHandler }) => (
            <ComponentList handleDeleteComponent={handleDeleteComponent} question={question} selectedComponents={selectedComponents} />
          )}
        </InspectionRenderer>
      </Fragment>
    );
  }
}

ComponentsDisplay3D.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ComponentsDisplay3D;
