import PropTypes from 'prop-types';
import React from 'react';
import EmptyState from '../../../../../common/empty-state-v2/components/empty-state';
import Icon from '../../../../../common/icon/components/icon';
import RenderIf from '../../../../../common/render-if/components/render-if';
import InfoTooltip from '../../../../../common/tooltip/components/info-tooltip';
import { formConstants } from '../../../constants/defect-constants';
import { expandableSectionInfoTypes, formConstants as fieldConstants } from '../../../constants/notification-constants';

const ExpandableSectionInfo = ({ data, type, onClick, defaultComponent, emptyStateProps, className }, { t }) => {
  const propToRender = data && type === expandableSectionInfoTypes.source ? 'ID' : 'Code';
  const isDefaultComponentLinked = data && defaultComponent && data[formConstants.fields.id] === defaultComponent[formConstants.fields.id];

  const critical = data && data[fieldConstants.fields.critical];
  const severity = data && data[fieldConstants.fields.severity];
  const name = data && data[fieldConstants.fields.name];

  return (
    <div className={`expandable-section-info ${className} ${!data || isDefaultComponentLinked ? 'empty-state-section' : ''}`}>
      <RenderIf if={!data || isDefaultComponentLinked}>
        <EmptyState {...emptyStateProps} iconName="sad-face" />
      </RenderIf>
      <RenderIf if={data && !isDefaultComponentLinked}>
        <div className="expandable-section-info__container" onClick={() => onClick(data)}>
          <div className="expandable-section-info__container__row header">
            <div className="expandable-section-info__container__id ">{t(type === expandableSectionInfoTypes.source ? 'ID' : 'CODE')}</div>
            <div className="expandable-section-info__container__name ">{t('NAME')}</div>
          </div>
          <div className="expandable-section-info__container__row data">
            <div className="expandable-section-info__container__id ">
              <p className="f-primary id">{data && data[propToRender] ? data[propToRender] : '-'}</p>
            </div>
            <div className="expandable-section-info__container__name">
              <InfoTooltip
                className="equipment-info-tooltip"
                offsetY={6}
                offsetX={6}
                customComponent={
                  <div className="equipment-info-tooltip__wrapper">
                    <div>
                      <label>{t(type === expandableSectionInfoTypes.source ? 'ID' : 'CODE')}</label>
                      <p className="f-primary">{data && data[propToRender] ? data[propToRender] : '-'}</p>
                    </div>
                    <div>
                      <label>{t('NAME')}</label>
                      <p className="f-primary">{name}</p>
                    </div>
                  </div>
                }
                Component={() => (
                  <div className="name_container">
                    <p className="f-primary name">{name}</p>
                    <Icon name="info" handleHover={false} size="sm" className="info-icon" />
                  </div>
                )}
                componentProps={{ title: '' }}
                containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
              />

              <RenderIf if={severity}>
                <div className={`severity-icon ${severity || 'severity-green'}`}>{severity}</div>
              </RenderIf>
              <RenderIf if={critical}>
                <InfoTooltip
                  className="critical-tooltip"
                  offsetY={6}
                  offsetX={6}
                  customComponent={<span className="f-primary">{t('CRITICAL_EQUIPMENT.TITLE')}</span>}
                  Component={() => <Icon name="critical-hazard" handleHover={false} size="sm" />}
                  componentProps={{ title: '' }}
                  containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
                />
              </RenderIf>
            </div>
          </div>
        </div>
      </RenderIf>
    </div>
  );
};

ExpandableSectionInfo.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  emptyStateText: PropTypes.string.isRequired,
  defaultComponent: PropTypes.object,
};

ExpandableSectionInfo.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ExpandableSectionInfo;
