export default {
  SET_CHECKLIST_LIST: 'handleSetCheklistList',
  AMEND_CHECKLIST_LIST: 'amendChecklistList',
  SET_CHECKLIST_TYPES: 'handleSetChecklistTypes',
  SET_SELECTED_CHECKLIST: 'setSelectedChecklist',
  SET_SELECTED_CHECKLIST_ITEMS: 'setSelectedChecklistItems',
  UPDATE_SELECTED_CHECKLIST: 'handleUpdateSelectedChecklist',
  ADD_CHECKLIST_ITEM_AT_INDEX: 'addChecklistItemAtIndex',
  REMOVE_CHECKLIST_BY_PROP: 'handleRemoveChecklistByProp',
  UPDATE_CHECKLIST_ITEM_BY_PROP: 'updateChecklistItemAtIndex',
  HANDLE_ADD_CHECKLIST_ITEM_AT_INDEX: 'handleAddChecklistItemAtIndex',
  HANDLE_REMOVE_CHECKLIST_ITEM_BY_PROP: 'handleRemoveChecklistItemByProp',
  HANDLE_UPDATE_CHECKLIST_ITEM_BY_PROP: 'handleUpdateChecklistByProp',
  SET_REGIME_LIST: 'handleSetRegimeList',
  AMEND_REGIME_LIST: 'handleAmendRegimeList',
  REMOVE_REGIME_BY_PROP: 'handleRemoveRegimeByProp',
  SET_SELECTED_REGIME: 'setSelectedRegime',
  SET_REGIME_DETAILS_LOADING: 'setRegimeDetailsLoading',
  ADD_REGIME_AT_INDEX: 'handleAddRegimeAtIndex',
  UPDATE_SELECTED_REGIME: 'handleUpdateSelectedRegime',
  UPDATE_REGIME_ITEM_BY_PROP: 'handleUpdateRegimeItemByProp',
  SET_REGIME_FILES: 'setRegimeFiles',
  REMOVE_REGIME_FILE_BY_GROUP: 'removeRegimeFileByGroup',
  SET_REGIME_COMPONENTS: 'setRegimeComponents',
  ADD_COMPONENT_TO_INDEX: 'addComponentToIndex',
  REMOVE_REGIME_COMPONENT_BY_PROP: 'removeRegimeComponentByProp',
  SET_REGIME_CHECKLISTS: 'setRegimeChecklists',
  REMOVE_REGIME_CHECKLIST_BY_PROP: 'removeRegimeChecklistByProp',
  ADD_REGIME_CHECKLIST: 'addRegimeChecklist',
  SET_INTERVAL_LIST: 'setIntervalList',
  SET_REGIME_TASKS: 'setRegimeTasks',
  AMEND_REGIME_WORK_ORDERS: 'handleAmendRegimeWorkOrders',
  SET_WORK_ORDER_PAGING_REGIME: 'setWorkOrderPagingRegime',
  SET_REGIME_WORK_ORDER_FILTERS: 'setRegimeWorkOrderFilters',
  HANDLE_REGIME_WORK_ORDERS_LOADING: 'handleSetRegimeWorkOrdersLoading',
  LINK_REGIME_CP_TEMPLATE_TO_COMPONENTS: 'linkRegimeCPTemplateToComponents',
  UNLINK_REGIME_CP_TEMPLATE_FROM_COMPONENTS: 'unlinkRegimeCPTemplateFromComponents',
  REMOVE_REGIME_COMPONENT_FROM_CP_TEMPLATE: 'removeRegimeComponentFromCPTemplate',
};
