import PropTypes from 'prop-types';
import React from 'react';
import '../styles/popover.scss';

const Popover = ({ text, hovered, pageX, pageY, pageXOffset, pageYOffset, ignoreTheme, className }) => {
  return (
    <div className={`popover ${hovered ? 'show' : 'hide'} ${ignoreTheme ? 'without-theme' : ''} ${className}`} style={{ left: pageX + pageXOffset, top: pageY + pageYOffset }}>
      <p>{text}</p>
    </div>
  );
};

Popover.propTypes = {
  text: PropTypes.string.isRequired,
  hovered: PropTypes.bool.isRequired,
  pageX: PropTypes.number,
  pageY: PropTypes.number,
  pageXOffset: PropTypes.number,
  pageYOffset: PropTypes.number,
};

Popover.defaultProps = {
  pageXOffset: -100,
  pageYOffset: +10,
  ignoreTheme: false,
};

export default Popover;
