import React, { Component } from 'react';
import PropTypes from 'prop-types';

import integrationConstants from '../constants/constants';

import '../styles/integration-list.scss';

class IntegrationList extends Component {
  renderIntegrationList = () => {
    const { t } = this.context;
    return integrationConstants.integrations.map(el => {
      return (
        <div key={el.key} className="integration-item">
          <div className="integration-item__image">
            <img src={el.imageSrc} alt={el.name} />
          </div>
          <label className="f-primary integration-item__label">{t(el.title)}</label>
        </div>
      );
    });
  };
  render() {
    return <div className="integration-list">{this.renderIntegrationList()}</div>;
  }
}

IntegrationList.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default IntegrationList;
