export const fields = {
  startDate: {
    name: 'StartDate',
    label: 'PERMIT_WORK_ORDERS.START_DATE',
    placeholder: 'PERMIT_WORK_ORDERS.START_DATE_PLACEHOLDER',
    id: 'permit-work-orders-start-date',
    showInDisplay: true,
    type: 'date',
  },
  endDate: {
    name: 'EndDate',
    label: 'PERMIT_WORK_ORDERS.END_DATE',
    placeholder: 'PERMIT_WORK_ORDERS.END_DATE_PLACEHOLDER',
    id: 'permit-work-orders-end-date',
    showInDisplay: true,
    type: 'date',
  },
  status: {
    name: 'Status',
    label: 'PERMIT_WORK_ORDERS.STATUS',
    placeholder: 'PERMIT_WORK_ORDERS.STATUS_PLACEHOLDER',
    id: 'permit-work-orders-status',
    showInDisplay: true,
  },
  id: {
    name: 'ID',
  },
  name: {
    name: 'Name',
  },
};

export const permitWorkOrderTableColumnTypes = {
  name: 'name',
  startDate: 'StartDate',
  endDate: 'EndDate',
};

export const permitWorkOrderTableConfig = [
  {
    title: 'PERMITS_TABLE.NAME',
    key: fields.name,
    enableSort: false,
    type: permitWorkOrderTableColumnTypes.name,
  },
  {
    title: 'PERMITS_TABLE.START_DATE',
    key: fields.startDate,
    enableSort: true,
    type: permitWorkOrderTableColumnTypes.startDate,
  },
  {
    title: 'PERMITS_TABLE.END_DATE',
    key: fields.startDate,
    enableSort: true,
    type: permitWorkOrderTableColumnTypes.endDate,
  },
];

export const initialValuesProp = 'WorkOrders';
