import actionTypes from '../constants/action-types';
import { formConstants } from '../constants/areas-constants';

import ReducerHelpers from '../../../common/reducer-helpers';

const areaState = {
  areas: [],
  areasClustered: [],
  areasLoading: [],
  delAreaModalData: {
    isOpen: false,
  },
  zoneAreas: [],
  areaGroupsDropdownData: [],
  areaGroupsFilterDropdownData: [],
};

export const areasReducer = (state = areaState, payload) => {
  switch (payload.type) {
    case actionTypes.SET_AREAS:
      return {
        ...state,
        areas: payload.data,
      };
    case actionTypes.SET_AREAS_LOADING:
      return {
        ...state,
        areasLoading: payload.data,
      };
    case actionTypes.AMEND_AREAS:
      return {
        ...state,
        areas: [...state.areas, ...payload.data],
      };

    case actionTypes.HANDLE_DELETE_AREA_MODAL:
      return { ...state, delAreaModalData: payload.data };

    case actionTypes.UPDATE_AREA_IN_ARRAY:
      const index = (state.areas || []).findIndex(el => el[formConstants.fields.id] === payload.data.id);

      return {
        ...state,
        areas: [...state.areas.slice(0, index), { ...state.areas[index], ...payload.data.item }, ...state.areas.slice(index + 1)],
      };
    case actionTypes.DELETE_AREA_IN_ARRAY:
      const i = (state.areas || []).findIndex(el => el[formConstants.fields.id] === payload.data);
      return {
        ...state,
        areas: [...state.areas.slice(0, i), ...state.areas.slice(i + 1)],
      };
    case actionTypes.SELECT_ALL_AREAS:
      return {
        ...state,
        areas: state.areas.map(area => ({ ...area, visible: true })),
      };
    case actionTypes.DESELECT_ALL_AREAS:
      return {
        ...state,
        areas: state.areas.map(area => ({ ...area, visible: false })),
      };
    case actionTypes.TOGGLE_AREA:
      return {
        ...state,
        areas: state.areas.map(area => {
          if (area.ID === payload.data) {
            return { ...area, visible: !area.visible };
          }
          return area;
        }),
      };
    case actionTypes.SHOW_AREA:
      return {
        ...state,
        areas: state.areas.map(area => {
          if (area.ID === payload.data) {
            return { ...area, visible: true };
          }
          return area;
        }),
      };
    case actionTypes.HIDE_AREA:
      return {
        ...state,
        areas: state.areas.map(area => {
          if (area.ID === payload.data) {
            return { ...area, visible: false };
          }
          return area;
        }),
      };
    case actionTypes.FETCH_AREA_GROUPS_DROPDOWN:
      return {
        ...state,
        areaGroupsDropdownData: [...payload.data],
      };
    case actionTypes.FETCH_AREA_GROUPS_FILTER_DROPDOWN:
      return {
        ...state,
        areaGroupsFilterDropdownData: [...payload.data],
      };

    case actionTypes.ADD_AREA_GROUPS_DROPDOWN_ITEM_AT_INDEX:
      return {
        ...state,
        areaGroupsDropdownData: ReducerHelpers.addItemToIndex(state.areaGroupsDropdownData, payload.data.item, payload.data.index),
      };
    case actionTypes.UPDATE_AREA_GROUPS_DROPDOWN_ITEM_BY_PROP:
      return {
        ...state,
        areaGroupsDropdownData: ReducerHelpers.updateItemInListByProp(state.areaGroupsDropdownData, payload.data.item, payload.data.prop),
      };
    case actionTypes.REMOVE_AREA_GROUPS_DROPDOWN_ITEM_BY_PROP:
      return {
        ...state,
        areaGroupsDropdownData: ReducerHelpers.removeItemByProp(state.areaGroupsDropdownData, payload.data.item, payload.data.prop),
      };

    case actionTypes.SET_AREA_DETAILS_LOADING:
      return {
        ...state,
        areaDetailsLoading: payload.data,
      };
    case actionTypes.SET_INSPECTION_AREAS_CLUSTERED:
      return {
        ...state,
        areasClustered: payload.data.map(area => ({ ...area, visible: true })),
      };
    default:
      return state;
  }
};
