import { setFetchingActive, setDownloadReportData, setDownloadReportPreferences, setDownloadReportPreferencesField, setSortByFilter } from '../../../app/inspections/actions/action-creators';
import { notificationModalCustom } from '../../modal/actions/modal-actions';
import { uploadAsset } from '../../../app/upload/actions/upload-actions';
import { handleGlobalLoader } from '../../global-loader/actions/loader-actions';

import reportApi from '../../../api/report-api/actions';
import filterApi from '../../../api/filter-api/actions';

import { get } from 'lodash';

import Helpers from '../../helpers';

export const fetchDownloadReportData = () => {
  return async dispatch => {
    try {
      dispatch(setFetchingActive(true));
      await dispatch(getSortByFilter());
      const res = await reportApi.fetchPreferences();
      const { Data } = res.data;
      dispatch(setDownloadReportPreferences(Data));
      dispatch(setFetchingActive(false));
    } catch (e) {
      dispatch(setFetchingActive(false));
    }
  };
};

export const getSortByFilter = () => {
  return async dispatch => {
    const res = await filterApi.getSortByFilter();
    if (res.data) {
      const { Data } = res.data;

      dispatch(setSortByFilter(Data.Items));
    }
  };
};

export const fetchDownloadReportHistory = InspectionID => {
  return async dispatch => {
    dispatch(setFetchingActive(true));
    let res = await reportApi.fetchDownloadReportHistory([{ inspection_id: InspectionID }]);

    res = get(res, 'data.Data');

    dispatch(setDownloadReportData(res || []));
    dispatch(setFetchingActive(false));
  };
};

export const clearReportData = () => {
  return async dispatch => {
    dispatch(setDownloadReportPreferences());
  };
};

export const downloadReport = (data, callback, successMessage, successMessageTitle, selectedFile) => {
  return async dispatch => {
    try {
      dispatch(handleGlobalLoader(true));
      if (selectedFile)
        await dispatch(
          uploadAsset(
            selectedFile,
            null,
            () => null,
            0,
            () => {
              dispatch(executeDownloadReport(data, callback, successMessage, successMessageTitle));
            }
          )
        );
      else {
        await dispatch(executeDownloadReport(data, callback, successMessage, successMessageTitle));
      }

      // no need to clear severity as it is always the same
    } catch (err) {
      callback();
      dispatch(handleGlobalLoader(false));
    }
  };
};

export const executeDownloadReport = (data, callback, successMessage, successMessageTitle) => {
  return async dispatch => {
    try {
      const res = await reportApi.downloadReport(data);
      if (res && res.data) {
        if (res.data.size < 500) {
          //when report is to large response is empty/ish file
          // notify user that email is sent
          dispatch(notificationModalCustom(true, successMessage, successMessageTitle));
        } else {
          //trigger download
          Helpers.downloadPDFFile(res.data);
        }
      }
      dispatch(handleGlobalLoader(false));
      callback();
      // no need to clear severity as it is always the same
    } catch (err) {
      callback();
      dispatch(handleGlobalLoader(false));
    }
  };
};

export const removeCoverImage = (callback, fieldToRemove) => {
  return async dispatch => {
    try {
      await reportApi.removeCoverImage();
      dispatch(setDownloadReportPreferencesField({ [fieldToRemove]: null }));
      callback && callback();
    } catch (err) {
      console.log(err);
    }
  };
};
