import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { FORMS } from '../../../../common/constants';
import CustomInput from '../../../../common/form/components/input';
import UneditableInfo from '../../../../common/form/components/uneditable-info';
import { folderFormFields } from '../../constants/constants';
import PropTypes from 'prop-types';
import { validateCreateNewFolder } from '../../validators/form-validators';
import Helpers from '../../../../common/helpers';
import Button from '../../../../common/form/components/button';

const DMSRightSideFolderBasicForm = ({ handleSubmit, handleFormSubmit, isDisabled, invalid, anyTouched, formHasUnsavedChanges, onFormChange }, { t }) => {
  const { name, createdAt, createdByUser } = folderFormFields;

  const formatCreatedAt = otherField => (value, previousValue, allValues) => Helpers.getDateFromUnix(value);

  const onFormSubmit = values => handleFormSubmit(values);

  const onFormChangeHandler = () => {
    if (!formHasUnsavedChanges) {
      onFormChange(true);
    }
  };

  return (
    <form className={`details-form ${isDisabled ? 'ch-right-side-form__disabled' : ''}`} onSubmit={handleSubmit(onFormSubmit)} noValidate onChange={onFormChangeHandler}>
      <p className="f-primary ch-right-side-form__title">{t('COMPONENT_HIERARCHY_LEVEL_DETAILS.TITLE')}</p>
      <Field
        id={name.id}
        name={name.name}
        component={isDisabled ? UneditableInfo : CustomInput}
        placeholder={t(name.label)}
        label={t(name.label)}
        type="text"
        size="lg"
        isRequired
        additionalClassName="text-transform-none"
        labelClass="text-transform-none"
      />
      <div className="ch-right-side-form__uneditable-info">
        <Field id={createdByUser.id} name={createdByUser.name} component={UneditableInfo} placeholder={t(createdByUser.label)} label={t(createdByUser.label)} type="text" size="lg" />
        <Field id={createdAt.id} name={createdAt.name} component={UneditableInfo} format={formatCreatedAt()} placeholder={t(createdAt.label)} label={t(createdAt.label)} type="text" size="lg" />
      </div>
      <Button type="submit" disabled={isDisabled || !anyTouched || invalid} width="sm" height="md" text={t('SAVE')} />
    </form>
  );
};

DMSRightSideFolderBasicForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default reduxForm({ form: FORMS.DMSFolderHierarchyLevel, validate: validateCreateNewFolder, enableReinitialize: true, touchOnChange: true, destroyOnUnmount: true })(
  DMSRightSideFolderBasicForm
);
