import routesConstants from './routes';
import { Api } from '../../core/api';
import { API_BASE_URL } from '../../common/constants';
import { generateQueryParams } from '../helpers/helper';

const getExplosiveZones = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_ZONES}`, data);
};

const getExplosiveZoneDetails = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_ZONE_DETAILS}${generateQueryParams(params)}`);
};

const createExplosiveZone = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CREATE_ZONE}`, data);
};

const updateExplosiveZone = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_ZONE}`, data);
};

const deleteExplosiveZone = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_ZONE}`, data);
};

const getExplosiveZoneAreas = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_ZONE_AREAS}`, data);
};

const createExplosiveZoneArea = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CREATE_ZONE_AREA}`, data);
};

const updateExplosiveZoneArea = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_ZONE_AREA}`, data);
};

const deleteExplosiveZoneArea = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_ZONE_AREA}`, data);
};

export const getExplosiveZonesClustered = async (data, options) => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_EXPLOSIVE_ZONE_CLUSTERED}`, data, options);
};

export const getExplosiveZoneHistory = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_EXPLOSIVE_ZONE_HISTORY}`, data);
};

export default {
  getExplosiveZones,
  getExplosiveZoneDetails,
  createExplosiveZone,
  updateExplosiveZone,
  deleteExplosiveZone,
  getExplosiveZoneAreas,
  createExplosiveZoneArea,
  updateExplosiveZoneArea,
  deleteExplosiveZoneArea,
  getExplosiveZonesClustered,
  getExplosiveZoneHistory,
};
