// import landingApi from '../../../api/landing-api/actions';
import { updateUser } from '../../login/actions/login-actions';

export const saveAccesToken = (obj, callback) => {
  return async dispatch => {
    try {
      // await landingApi.saveAccessToken({
      //   AccessToken: obj.access_token,
      //   AccountID: obj.account_id,
      //   UID: obj.uid,
      // });
      dispatch(updateUser({ DropbboxAccessToken: obj.access_token }));

      callback && callback();
    } catch (err) {
      console.log(err);
    }
  };
};

export const deleteAccessToken = () => {
  return async dispatch => {
    try {
      // await landingApi.deleteAccessToken({
      //   AccessToken: obj.access_token,
      //   AccountID: obj.account_id,
      //   UID: obj.uid,
      // });
      dispatch(updateUser({ DropbboxAccessToken: null }));
    } catch (err) {
      console.log(err);
    }
  };
};
