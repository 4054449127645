import { uploadGroups } from '../../inspections/constants/component-constants';

export const fields = {
  title: 'Title',
  drawingNumber: 'DrawingNumber',
  revision: 'Revision',
  revisionDate: 'RevisionDate',
  createdAt: 'CreatedAt',
  createdBy: 'CreatedBy',
  lastModified: 'LastModified',
  modifiedBy: 'ModifiedBy',
  fileName: 'FileName',
  id: 'FileID',
  password: 'Password',
  status: 'Status',
};

const statusOptions = {
  started: 'STARTED',
  failed: 'FAILED',
  finished: 'FINISHED',
};

const pdfTableHeader = [
  // {
  //   title: 'PDF_TABLE.NAME',
  //   key: 'FileName',
  //   enableSort: true,
  // },
  {
    title: 'PDF_TABLE.DRAWING_TITLE', //
    key: fields.fileName,
    enableSort: true,
    type: fields.title,
  },
  {
    title: 'PDF_TABLE.DRAWING_NUMBER', //
    key: fields.drawingNumber,
    enableSort: false,
    type: 'text',
  },
  {
    title: 'PDF_TABLE.REVISION', //
    key: fields.revision,
    enableSort: false,
    type: 'text',
  },
  {
    title: 'PDF_TABLE.REVISION_DATE', //
    key: fields.revisionDate,
    enableSort: false,
    type: 'date',
  },
  {
    title: 'PDF_TABLE.CREATED',
    key: fields.createdAt,
    enableSort: true,
    type: 'date',
  },
  // {
  //   title: 'PDF_TABLE.CREATED_BY',
  //   key: fields.createdBy,
  //   enableSort: true,
  //   type: 'text',
  // },
  // {
  //   title: 'PDF_TABLE.LAST_MODIFIED',
  //   key: fields.lastModified,
  //   enableSort: true,
  //   type: 'date',
  // },
  // {
  //   title: 'PDF_TABLE.MODIFIED_BY',
  //   key: fields.modifiedBy,
  //   enableSort: true,
  //   type: 'text',
  // },
  // {
  //   title: 'PDF_TABLE.NO_OF_TAGS',
  //   key: 'NumberOfTags',
  //   enableSort: true,
  //   type: 'no-of-tags',
  // },
  // {
  //   title: 'PDF_TABLE.UN_TAGGED',
  //   key: 'UnTagged',
  //   enableSort: true,
  // },
];

const sortDirection = {
  asc: 'ASC',
  desc: 'DESC',
};

const pdfDataParams = {
  SortByColumn: fields.createdAt,
  SortDirection: sortDirection.desc,
  LastSeen: 0,
  SearchText: '',
  PerPage: 10,
  HasNext: false,
};

const sections = {
  mAndE: {
    name: 'mAndE',
    supportedMimeTypes: 'application/pdf',
    title: 'PDF_TABLE.SECTION_2_TITLE',
    uploadGroup: uploadGroups.mechanicalAndTechnical,
    hidden: true,
  },
  pAndId: {
    name: 'pAndId',
    supportedMimeTypes: 'application/pdf',
    title: 'PDF_TABLE.SECTION_1_TITLE',
    uploadGroup: uploadGroups.pAndId,
    hidden: true,
  },
};

const usersTableState = {
  tables: {
    [sections.pAndId.name]: {
      tableConfig: pdfTableHeader,
      tableParams: { ...pdfDataParams, UploadGroup: uploadGroups.pAndId },
      tableData: [],
      name: sections.pAndId.name,
      uploadGroup: uploadGroups.pAndId,
    },
    [sections.mAndE.name]: {
      tableConfig: pdfTableHeader,
      tableParams: { ...pdfDataParams, UploadGroup: uploadGroups.mechanicalAndTechnical },
      tableData: [],
      name: sections.mAndE.name,
      uploadGroup: uploadGroups.mechanicalAndTechnical,
    },
  },
  activateModalData: {
    isOpen: false,
  },
};

export default {
  pdfTableHeader,
  sortDirection,
  pdfDataParams,
  sections,
  fields,
  statusOptions,
  usersTableState,
};
