export const formConstants = {
  fields: {
    id: 'ID',
    name: 'Name',
    description: 'Description',
    dateCreated: 'CreatedAt',
    creator: 'CreatedBy',
    question: 'Question',
    questionType: 'QuestionType',
    regimesNumber: 'RegimesNumber',
    numberOfQuestions: 'QuestionsNumber',
    projectId: 'ProjectID',
    checklistItemID: 'ID',
    checklistTemplateId: 'ChecklistTemplateID',
    checklistTemplateItemTypeId: 'ChecklistTemplateItemTypeID',
    checklistTemplateItemName: 'Name',
    checklistTypeId: 'ID',
    checklistTemplateItemId: 'ChecklistTemplateItemID',
    checklistItemTypeID: 'ChecklistItemTypeID',
    checklistItemType: 'ChecklistItemType',
    status: 'Status',
  },
};

export const filterFields = {
  projectID: 'ProjectID',
  searchText: 'SearchText',
  sortByColumn: 'SortByColumn',
  sortByDirection: 'SortByDirection',
  perPage: 'PerPage',
  lastSeen: 'LastSeen',
  hasNext: 'HasNext',
  status: 'StatusFilter',
};

export const sortDirection = {
  asc: 'ASC',
  desc: 'DESC',
};

export const checklistTypeFields = {
  text: 'ChecklistItemTypeName',
  value: 'ChecklistItemType',
  id: 'ID',
};

export const defaultFilter = {
  [filterFields.searchText]: '',
  [filterFields.sortByColumn]: formConstants.fields.name,
  [filterFields.sortByDirection]: sortDirection.asc,
  [filterFields.status]: 'LIVE',
  [filterFields.perPage]: 30,
  [filterFields.lastSeen]: 0,
};

export const textAreaMaxChars = 2000;

const steps = {
  firstStep: 1,
  secondStep: 2,
};

const stepperData = [
  {
    name: 'CHECKLIST_TEMPLATE.STEP_1',
    stepValue: steps.firstStep,
  },
  {
    name: 'CHECKLIST_TEMPLATE.STEP_2',
    stepValue: steps.secondStep,
  },
];

export default {
  steps,
  stepperData,
};
