import routesConstants from './routes';
import { Api } from '../../core/api';
import { API_BASE_URL } from '../../common/constants';
import { generateQueryParams } from '../helpers/helper';

const getRoles = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_ROLES}`, params);
};

const deleteRole = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_ROLE}`, params);
};

const createRole = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CREATE_ROLE}`, params);
};

const updateRole = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_ROLE}`, params);
};
const getRolePermissions = async (params, opt) => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_ROLE_PERMISSIONS}${generateQueryParams(params)}`, opt);
};

const getRoleUsers = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_ROLE_USERS}${generateQueryParams(params)}`, { needLoader: true });
};

export default {
  getRoles,
  createRole,
  updateRole,
  deleteRole,
  getRolePermissions,
  getRoleUsers,
};
