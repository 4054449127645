export default {
  SET_PDF_LIST: 'setPdfList',
  SET_SELECTED_FILE: 'setSelectedFile',
  SET_PDF_COMPONENTS: 'setPdfComponents',
  AMEND_PDF_COMPONENTS: 'amendPdfComponents',
  SET_COMPONENTS_LOADING: 'setComponentsLoading',
  HANDLE_ADD_NEW_COMPONENT: 'handleAddNewComponent',
  HANDLE_UPDATE_COMPONENT_IN_ARRAY: 'updateComponentInArray',
  SET_PDF_FILE_LOADING: 'handlePdfFileLoading',
  SET_PDF_POPUP_COMPONENTS_LOADING: 'handleSetPdfPopupComponentsLoading',
  SELECT_ACTIVE_DRAWING: 'selectActiveDrawing',
  HANDLE_REMOVE_COMPONENT_IN_ARRAY: 'handleRemoveComponentInArray',
  SET_PDF_PAGE_NUMBER: 'setPdfPageNumber',
};
