export const getKeyBoxesFields = {
  projectId: 'ProjectID',
  moduleId: 'ModuleID',
};

export const dropdownFields = {
  valueField: 'ID',
  textField: 'Name',
  status: 'Status',
  prevKeyBoxID: 'prevKeyBoxID',
};

export const keyBoxDetailsFields = {
  keyBoxProp: 'Keybox',
  freeKeys: 'FreeKeys',
  issuedKeys: 'LockedKeys',
};

export const fields = {
  keyBox: {
    name: 'KeyboxID',
    displayData: 'Name',
    initialValuesName: 'ID',
    label: 'ISOLATION_CERTIFICATE.KEYBOX_LABEL',
    placeholder: 'ISOLATION_CERTIFICATE.KEYBOX_PLACEHOLDER',
    id: 'key-box-form',
  },
};
