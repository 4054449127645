export default {
  GET_AREAS: '/inspection/area/list',
  GET_AREA_DETAILS: '/inspection/area/details',
  CREATE_AREA: '/inspection/area/create',
  DELETE_AREA: '/inspection/area/delete',
  UPDATE_AREA: '/inspection/area/update',
  GET_AREA_GROUPS: '/inspection/area-group/list',
  GET_AREA_GROUPS_FILTER: '/inspection/area-group/filter/list',
  CREATE_AREA_GROUP: '/inspection/area-group/create',
  DELETE_AREA_GROUP: '/inspection/area-group/delete',
  UPDATE_AREA_GROUP: '/inspection/area-group/update',
  GET_AREAS_CLUSTERED: '/inspection/area/clustered/list',
  GET_AREA_ITEM_HISTORY: '/inspection/area/history/get',
};
