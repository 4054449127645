import React, { Component } from 'react';

class Table extends Component {
  renderCells = (row, rowIndex) => {
    const { tableConfig, formatCell, translationModule } = this.props;
    return tableConfig.map((item, index) => {
      let value = row[item.key];
      if (item.hidden) {
        return null;
      }
      if (formatCell && item.type) {
        value = formatCell(value, item.type, rowIndex, row);
      }
      if (item.translatable) {
        value = translationModule(value);
      }

      if (item.CustomComponent) {
        const { CustomComponent } = item;
        return <td key={index}>{CustomComponent(row, rowIndex)}</td>;
      }
      return (
        <td key={index} className={item.className || ''} title={typeof value === 'string' ? value : null}>
          {value}
        </td>
      );
    });
  };

  render() {
    const { data, onRowClick, customIdentificatorFunction } = this.props;

    return (
      <tbody>
        {data?.length > 0 &&
          data.map((row, index) => {
            let customIdentificator = '';
            if (!row) return null;

            if (customIdentificatorFunction) {
              customIdentificator = customIdentificatorFunction(row, index);
            } else {
              customIdentificator = `row-${index}`;
            }
            return (
              <tr
                id={customIdentificator}
                className={`${row?.selected ? 'selected' : ''} ${onRowClick && !row.CustomComponent ? 'pointer' : ''} ${customIdentificator}`}
                key={index}
                onClick={onRowClick && !row.CustomComponent ? e => onRowClick(e, row) : null}
              >
                {row?.CustomComponent ? row.CustomComponent(row, index) : this.renderCells(row, index)}
              </tr>
            );
          })}
      </tbody>
    );
  }
}

export default Table;
