import { API_BASE_URL } from '../../common/constants';
import { Api } from '../../core/api';
import { generateQueryParams } from '../helpers/helper';
import routesConstants from './routes';

const getInspectionDetails = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_INSPECTION_DETAILS}${generateQueryParams(params)}`, { allowGuestUserAction: true });
};

const getInspectionDefects = async values => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_INSPECTION_DEFECTS}`, values, { allowGuestUserAction: true });
};

const getInspectionDefectsLite = async values => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_INSPECTION_DEFECTS_LITE}`, values, { allowGuestUserAction: true });
};

const getInspectionMeasurements = async values => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_INSPECTION_MEASUREMENTS}`, values, { allowGuestUserAction: true });
};

const getInspectionComponentsLite = async values => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_INSPECTION_COMPONENTS_LITE}`, values, { allowGuestUserAction: true });
};

const getInspectionComponents = async values => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_INSPECTION_COMPONENTS}`, values, { allowGuestUserAction: true });
};

const getProjectInspections = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_PROJECT_INSPECTIONS}${generateQueryParams(params)}`);
};

const getDefectDetails = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_DEFECT_DETAILS}${generateQueryParams(params)}`, { allowGuestUserAction: true });
};

const getMeasurementDetails = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_MEASUREMENT_DETAILS}${generateQueryParams(params)}`, { allowGuestUserAction: true });
};

const getComponentDetails = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_COMPONENT_DETAILS}${generateQueryParams(params)}`, { allowGuestUserAction: true });
};

const createDefect = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CREATE_NEW_DEFECT}`, data);
};

const createMeasurement = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CREATE_NEW_MEASUREMENT}`, data);
};

const createComponent = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CREATE_NEW_COMPONENT}`, data);
};

const updateDefect = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_DEFECT}`, data);
};

const updateDefectGeometry = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_DEFECT_GEOMETRY}`, data);
};

const updateMeasurementGeometry = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_MEASUREMENT_GEOMETRY}`, data);
};

const updateMeasurement = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_MEASUREMENT}`, data);
};

const updateDefectProperty = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_DEFECT_PROPERTY}`, data);
};

const deleteDefectProperty = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_DEFECT_PROPERTY}`, data);
};

const updateComponentProperty = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_COMPONENT_PROPERTY}`, data);
};

const deleteComponentProperty = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_COMPONENT_PROPERTY}`, data);
};

const getDefectProperties = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_DEFECT_PROPERTIES}`, data);
};

const getComponentProperties = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_COMPONENT_PROPERTIES}`, data);
};

const addDefectProperty = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_DEFECT_PROPERTY}`, data);
};

const addComponentProperty = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_COMPONENT_PROPERTY}`, data);
};

const getDefectPropertyNames = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_DEFECT_PROPERTY_NAMES}`, data);
};

const getComponentPropertyNames = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_COMPONENT_PROPERTY_NAMES}`, data);
};

const updateComponent = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_COMPONENT}`, data, { skipErrorModal: true });
};

const updateComponentGeometry = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_COMPONENT_GEOMETRY}`, data);
};

const deleteDefect = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_DEFECT}`, data);
};

const deleteMeasurement = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_MEASUREMENT}`, data);
};

const deleteComponent = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_COMPONENT}`, data);
};

const addDefectComment = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_DEFECT_COMMENT}`, data);
};

const getDefectComments = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_DEFECT_COMMENTS}${generateQueryParams(params)}`, { allowGuestUserAction: true });
};

const addMeasurementComment = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_MEASUREMENT_COMMENT}`, data);
};

const getMeasurementComments = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_MEASUREMENT_COMMENTS}${generateQueryParams(params)}`, { allowGuestUserAction: true });
};

const getShareLinks = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_SHARE_LINKS}${generateQueryParams(params)}`);
};

const generateShareLink = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GENERATE_SHARE_LINK}`, data);
};

const toggleShareLink = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.TOGGLE_SHARE_LINK}`, data);
};

const deleteShareLink = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_SHARE_LINK}`, data);
};

const updateInspectionDetails = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_INSPECTION_DETAILS}`, data);
};

const getComponentAssetSuggestions = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_COMPONENT_ASSET_SUGGESTIONS}`, params, { allowGuestUserAction: true });
};

const getComponentTypeSuggestions = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_COMPONENT_TYPE_SUGGESTIONS}`, params, { allowGuestUserAction: true });
};

const getComponentSubTypeSuggestions = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_COMPONENT_SUB_TYPE_SUGGESTIONS}`, params, { allowGuestUserAction: true });
};

const getComponentMaterialSuggestions = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_COMPONENT_MATERIAL_SUGGESTIONS}`, params, { allowGuestUserAction: true });
};

const getComponentManufacturerSuggestions = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_COMPONENT_MANUFACTURER_SUGGESTIONS}`, params, { allowGuestUserAction: true });
};

const getComponentLocationSuggestions = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_COMPONENT_LOCATION_SUGGESTIONS}`, params, { allowGuestUserAction: true });
};

const getDefectTypeSuggestions = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_COMPONENT_DEFECT_TYPE_SUGGESTIONS}`, params, { allowGuestUserAction: true });
};

const editInspectionDetails = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.EDIT_INSPECTION_DETAILS}`, data);
};
export const getComponentLinkedDefects = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_COMPONENT_LINKED_DEFECTS}`, data);
};

// TODO: remove and use unlinkDMSFileFromComponent
export const unlinkComponentFile = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UNLINK_DMS_FILE_FROM_COMPONENT}`, data);
};

export const unlinkMeasurementFile = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UNLINK_MEASUREMENT_FILE}`, data);
};

export const duplicateComponent = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DUPLICATE_COMPONENT}`, data);
};

export const getComponentRelatedDefects = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_COMPONENT_RELATED_DEFECTS}`, data);
};

export const getUpcomingComponentTasks = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_UPCOMING_COMPONENT_TASKS}`, data);
};

export const getComponentTasksByType = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_COMPONENT_TASKS_BY_TYPE}`, data);
};

export const getComponentsClustered = async (data, options) => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_COMPONENTS_CLUSTERED}`, data, options);
};

export const getObservationsClustered = async (data, options) => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_OBSERVATIONS_CLUSTERED}`, data, options);
};

export const getMeasurementsClustered = async (data, options) => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_MEASUREMENTS_CLUSTERED}`, data, options);
};

export const getObservationTypes = async () => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_OBSERVATION_TYPES}`);
};

export const getObservationNotifications = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_OBSERVATION_NOTIFICATIONS}${generateQueryParams(params)}`);
};

export const getNdtMeasurementNotifications = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_NDT_NOTIFICATIONS}${generateQueryParams(params)}`);
};

export const updateObservationComponent = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_OBSERVATION_COMPONENT}`, data);
};

export const getComponentHistory = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_COMPONENT_HISTORY}`, data);
};

export const getComponentModuleHistory = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_COMPONENT_MODULE_HISTORY}`, data);
};

export const getDefectHistory = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_DEFECT_HISTORY}`, data);
};

export const getDefectModuleHistory = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_DEFECT_MODULE_HISTORY}`, data);
};

export const getMeasurementHistory = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_MEASUREMENT_HISTORY}`, data);
};

export const getMeasurementModuleHistory = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_MEASUREMENT_MODULE_HISTORY}`, data);
};

export const getAreaModuleHistory = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_AREA_MODULE_HISTORY}`, data);
};

export const getNDTHistory = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_NDT_HISTORY}`, data);
};

export const getNDTModuleHistory = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_NDT_MODULE_HISTORY}`, data);
};

export const getExplosiveZonesModuleHistory = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_ZONES_MODULE_HISTORY}`, data);
};

export const getInspectionHierarchyComponents = async values => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_INSPECTION_HIERARCHY_COMPONENTS}`, values, { allowGuestUserAction: true });
};

export const getHierarchyComponentsClustered = async (data, options) => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_HIERARCHY_COMPONENTS_CLUSTERED}`, data, options);
};

export const getPanoramicImages = async (data, options) => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_PANORAMIC_IMAGES}`, data, options);
};

export const getPanoramicImageTiles = async (data, options) => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_PANORAMIC_IMAGE_TILES}`, data, options);
};

export const getInspectionHierarchyUnassignedComponents = async values => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_HIERARCHY_UNASSIGNED_COMPONENTS}`, values, { allowGuestUserAction: true });
};

export const getHierarchyUnassignedComponentsClustered = async (data, options) => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_HIERARCHY_UNASSIGNED_COMPONENTS_CLUSTERED}`, data, options);
};

export const linkDMSFileFromComponent = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.LINK_DMS_FILE_FROM_COMPONENT}`, data);
};

export const unlinkDMSFileFromComponent = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UNLINK_DMS_FILE_FROM_COMPONENT}`, data);
};

export const fetchComponentComments = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.FETCH_COMPONENT_COMMENTS}${generateQueryParams(params)}`);
};

export const addComponentComment = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_COMPONENT_COMMENT}`, params);
};

export const deleteComponentComment = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_COMPONENT_COMMENT}`, params);
};

export const fetchCommentUsersAndTeams = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_COMMENT_USERS_AND_TEAMS}`, params);
};

export const fetchObservationComments = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.FETCH_OBSERVATION_COMMENTS}${generateQueryParams(params)}`);
};

export const addObservationComment = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_OBSERVATION_COMMENT}`, params);
};

export const deleteObservationComment = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_OBSERVATION_COMMENT}`, params);
};

export const fetchComponentObservations = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_COMPONENTS_OBSERVATIONS}`, params);
};

export const fetchComponentNotifications = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_COMPONENTS_NOTIFICATIONS}`, params);
};

export const fetchComponentWorkOrders = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_COMPONENTS_WORK_ORDERS}`, params);
};

const updateNotificationGeometry = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_NOTIFICATION_GEOMETRY}`, data);
};

export const fetchObservationFiles = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.FETCH_OBSERVATION_FILES}${generateQueryParams(params)}`);
};

export const saveObservationDrawings = async data => {
  return await Api.get(`${API_BASE_URL}${routesConstants.SAVE_OBSERVATION_DRAWING}}`, data);
};

export const updateObservationDrawings = async data => {
  return await Api.get(`${API_BASE_URL}${routesConstants.UPDATE_OBSERVATION_DRAWING}}`, data);
};

const getObservationSubTypesSuggestions = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_OBSERVATION_SUBTYPE_SUGGESTIONS}`, params, { allowGuestUserAction: true });
};

export const fetchNotificationFiles = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.FETCH_NOTIFICATION_FILES}${generateQueryParams(params)}`);
};

const searchObservationContributors = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.SEARCH_OBSERVATION_CONTRIBUTORS}`, data);
};

const searchContributors = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.SEARCH_CONTRIBUTORS}`, data);
};

const getObservationContributors = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_OBSERVATION_CONTRIBUTORS}`, data);
};
const addObservationContributor = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_OBSERVATION_CONTRIBUTOR}`, data);
};

const deleteObservationContributor = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_OBSERVATION_CONTRIBUTOR}`, data);
};

export const unlinkObservationComponent = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UNLINK_OBSERVATION_COMPONENT}`, data);
};

export const fetchWorkorderFiles = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.FETCH_WORKORDER_FILES}${generateQueryParams(params)}`);
};
export const fetchWorkorderCompletionFiles = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.FETCH_WORKORDER_COMPLETION_FILES}${generateQueryParams(params)}`);
};

export const fetchWorkorderEvidenceFiles = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.FETCH_WORKORDER_EVIDENCE_FILES}${generateQueryParams(params)}`);
};

export const fetchObservationWorkOrders = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_OBSERVATION_WORK_ORDERS}`, data);
};

export const fetchWorkOrdersForObservations = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_WORK_ORDERS_FOR_OBSERVATION}`, data);
};

export const linkWorkOrderToObservation = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.LINK_WORK_ORDER_TO_OBSERVATION}`, data);
};

export const unlinkWorkOrderFromObservation = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UNLINK_WORK_ORDER_FROM_OBSERVATION}`, data);
};

export const updateComponentPropetries = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_COMPONENT_PROPERTIES}`, data);
};

export const fetchComponentRegimes = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_COMPONENT_REGIMES}`, params);
};

export default {
  getInspectionDetails,
  getProjectInspections,
  getInspectionDefects,
  getInspectionDefectsLite,
  getInspectionMeasurements,
  getInspectionComponentsLite,
  getInspectionComponents,
  getDefectDetails,
  getMeasurementDetails,
  getComponentDetails,
  createDefect,
  createMeasurement,
  createComponent,
  updateDefect,
  updateMeasurement,
  addDefectComment,
  getDefectComments,
  addMeasurementComment,
  getMeasurementComments,
  deleteDefect,
  deleteMeasurement,
  deleteComponent,
  getShareLinks,
  generateShareLink,
  toggleShareLink,
  deleteShareLink,
  updateInspectionDetails,
  getComponentAssetSuggestions,
  getComponentTypeSuggestions,
  getComponentSubTypeSuggestions,
  getComponentMaterialSuggestions,
  getComponentManufacturerSuggestions,
  getComponentLocationSuggestions,
  updateComponent,
  updateDefectProperty,
  updateComponentProperty,
  deleteDefectProperty,
  deleteComponentProperty,
  getDefectProperties,
  getComponentProperties,
  getDefectPropertyNames,
  getComponentPropertyNames,
  addDefectProperty,
  addComponentProperty,
  editInspectionDetails,
  getDefectTypeSuggestions,
  getComponentLinkedDefects,
  updateDefectGeometry,
  updateMeasurementGeometry,
  updateComponentGeometry,
  unlinkComponentFile,
  unlinkMeasurementFile,
  duplicateComponent,
  getComponentRelatedDefects,
  getComponentTasksByType,
  getComponentsClustered,
  getObservationsClustered,
  getMeasurementsClustered,
  getObservationTypes,
  getObservationNotifications,
  getNdtMeasurementNotifications,
  updateObservationComponent,
  getComponentHistory,
  getComponentModuleHistory,
  getDefectHistory,
  getDefectModuleHistory,
  getMeasurementHistory,
  getMeasurementModuleHistory,
  getAreaModuleHistory,
  getNDTHistory,
  getNDTModuleHistory,
  getExplosiveZonesModuleHistory,
  getInspectionHierarchyComponents,
  getHierarchyComponentsClustered,
  getPanoramicImages,
  getPanoramicImageTiles,
  getHierarchyUnassignedComponentsClustered,
  getInspectionHierarchyUnassignedComponents,
  linkDMSFileFromComponent,
  unlinkDMSFileFromComponent,
  fetchComponentComments,
  addComponentComment,
  deleteComponentComment,
  fetchCommentUsersAndTeams,
  fetchObservationComments,
  addObservationComment,
  deleteObservationComment,
  fetchComponentObservations,
  fetchComponentNotifications,
  fetchComponentWorkOrders,
  updateNotificationGeometry,
  fetchObservationFiles,
  saveObservationDrawings,
  updateObservationDrawings,
  getObservationSubTypesSuggestions,
  fetchNotificationFiles,
  searchObservationContributors,
  getObservationContributors,
  addObservationContributor,
  deleteObservationContributor,
  unlinkObservationComponent,
  searchContributors,
  fetchObservationWorkOrders,
  fetchWorkOrdersForObservations,
  linkWorkOrderToObservation,
  unlinkWorkOrderFromObservation,
  updateComponentPropetries,
  fetchComponentRegimes,
};
