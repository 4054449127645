import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { FORMS } from '../../../../common/constants';

import { reduxForm } from 'redux-form';

import { debounce } from 'lodash';

import { regimeValidate } from '../../validators/regime-validators';

import Button from '../../../../common/form/components/button';
import RenderIf from '../../../../common/render-if/components/render-if';
import AddCPTemplates from '../../../inspections/components/work-order/add-cp-templates';
import WorkOrderComponents from '../../../inspections/components/work-order/work-order-components';
import WorkOrderFiles from '../../../inspections/components/work-order/work-order-files';
import AdvancedDetailsForm from './advance-details-form';
import BasicDetailsFormStep from './basic-details-form-step';
import ScheduleRegimeForm from './schedule-regime-form';

import { componentFields, endCheckBoxValues, fileFields, fileGroups, formConstants, intervalFields, intervalListMaxValue, sources, steps } from '../../constants/regime-constants';

import '../../styles/maintenance-regime-create-modal.scss';

class CreateRegimeForm extends Component {
  constructor(props) {
    super(props);

    this.searchComponentSearchChangeDebounced = debounce(props.searchComponents, 500);
  }
  handleComponentSearch = e => {
    this.searchComponentSearchChangeDebounced(e.target.value);
  };

  getIntervalNumberList = () => {
    let arr = [];
    for (var i = 1; i <= intervalListMaxValue; i++) {
      arr = [...arr, i];
    }
    return arr;
  };
  //trick redux form to trigger validation
  validateComponent = () => 'REGIME_VALIDATION.COMPONENT_REQUIRED';
  validateChecklist = () => 'REGIME_VALIDATION.CHECKLIST_REQUIRED';

  handleEndsChange = (_e, value) => {
    const { change, intervalList } = this.props;

    if (value) {
      change(formConstants.fields.ends, endCheckBoxValues.on);
      change(formConstants.fields.intervalNumber, 1);
      change(formConstants.fields.taskIntervalId, intervalList[0][intervalFields.id]);
    } else {
      change(formConstants.fields.ends, null);
      change(formConstants.fields.intervalNumber, null);
      change(formConstants.fields.taskIntervalId, null);
      change(formConstants.fields.endDate, null);
      change(formConstants.fields.occurancesTreshold, null);
    }
  };

  handleOnOrAfterChange = value => {
    const { change } = this.props;

    if (value === endCheckBoxValues.on) {
      change(formConstants.fields.occurancesTreshold, null);
    } else {
      change(formConstants.fields.endDate, null);
    }
  };

  render() {
    const { t } = this.context;
    const {
      handleSubmit,
      activeStep,
      lastStep,
      stepAction,
      isPreviewMode,
      hideCheckbox,
      regimeFiles,
      openFilesModalData,
      unlinkRegimeFile,
      regimeComponents,
      components,
      addComponentToRegime,
      removeComponentFromRegime,
      intervalList,
      setConfirmModalData,
      closeConfirmModal,
      severityColors,
      closeAction,
      handleComponentPicker3D,
      handleComponentPickerPDF,
      handleComponentPickerCH,
      formValues,
      disableStepNavigation,
      user,
      linkRegimeCPTemplateToComponents,
      unlinkRegimeCPTemplateFromComponents,
      checklists,
      regimeChecklists,
      removeComponentFromCPTemplate,
      addChecklistToTheRegime,
      deleteRegimeChecklist,
      searchChecklists,
      additionalWorkorderTypes,
    } = this.props;

    return (
      <form className="regime-form" onSubmit={handleSubmit} noValidate>
        {activeStep === steps.firstStep && <BasicDetailsFormStep isPreviewMode={isPreviewMode} severityColors={severityColors} additionalWorkorderTypes={additionalWorkorderTypes} />}
        {activeStep === steps.secondStep && intervalList && intervalList.length > 0 && <AdvancedDetailsForm isPreviewMode={isPreviewMode} intervalList={intervalList} />}
        {activeStep === steps.thirdStep && (
          <WorkOrderFiles
            workorderFiles={regimeFiles}
            openFilesModalData={() => openFilesModalData(fileGroups.otherFiles)}
            deleteWorkOrderFile={file => {
              setConfirmModalData(() => unlinkRegimeFile(file[fileFields.id], fileGroups.otherFiles), t('CREATE_REGIME_FORM.REMOVE_FILE_POPUP_TEXT', { file: file[fileFields.name] }));
            }}
            otherFilesLabel="CREATE_REGIME_FORM.UPLOAD_FILES_LABEL"
            hideRams
            isFilesSectionSelected
            disabled={isPreviewMode}
          />
        )}
        {activeStep === steps.fourthStep && (
          <WorkOrderComponents
            components={components}
            workorderComponents={regimeComponents}
            addComponentToWorkorder={addComponentToRegime}
            removeComponentFromWorkorder={item =>
              setConfirmModalData(() => removeComponentFromRegime(item, closeConfirmModal), t('CREATE_REGIME_FORM.REMOVE_COMPONENT_POPUP_TEXT', { component: item[componentFields.name] }))
            }
            closeConfirmModal={closeConfirmModal}
            handleComponentPicker3D={handleComponentPicker3D}
            handleComponentPickerPDF={handleComponentPickerPDF}
            handleComponentPickerCH={handleComponentPickerCH}
            searchInputLabel="CREATE_REGIME_FORM.COMPONENTS_LABEL"
            perComponentFieldName={formConstants.fields.perComponent}
            perComponentLabel={formValues?.[formConstants.fields.source] === sources.notification ? 'CREATE_REGIME_FORM.PER_COMPONENT_LABEL' : 'CREATE_REGIME_FORM.WORK_ORDER_PER_COMPONENT_LABEL'}
            disabled={isPreviewMode}
            hideCheckbox={hideCheckbox}
            searchPlaceholder="CREATE_REGIME_FORM.COMPONENTS_PLACEHOLDER"
          />
        )}
        {activeStep === steps.fifthStep && (
          <AddCPTemplates
            handleSearchCPTemplates={searchChecklists}
            addCPTemplateToWorkorder={addChecklistToTheRegime}
            removeCPTemplateFromWorkorder={deleteRegimeChecklist}
            user={user}
            linkToComponent={linkRegimeCPTemplateToComponents}
            unlinkFromComponent={unlinkRegimeCPTemplateFromComponents}
            workorderComponents={regimeComponents}
            cpTemplates={checklists}
            workorderCPTemplates={regimeChecklists}
            removeComponentFromCPTemplate={removeComponentFromCPTemplate}
          />
        )}
        {activeStep === steps.sixthStep && (
          <ScheduleRegimeForm
            isPreviewMode={isPreviewMode}
            formValues={formValues}
            intervalList={intervalList}
            handleEndsChange={this.handleEndsChange}
            handleOnOrAfterChange={this.handleOnOrAfterChange}
          />
        )}

        <div className="buttons">
          {disableStepNavigation ? (
            <>
              <Button type="button" variant="gray-outline" width="xs" text={t('CREATE_REGIME_FORM.CLOSE')} onClick={() => closeAction(activeStep)} height="md" />
              <RenderIf if={!isPreviewMode}>
                <div className="right-buttons">
                  <Button width="xs" height="md" className="submit-button" text={t('CREATE_REGIME_FORM.SAVE')} />
                </div>
              </RenderIf>
            </>
          ) : (
            <>
              <Button type="button" variant="gray-outline" width="xs" text={t('CREATE_REGIME_FORM.CANCEL')} onClick={() => closeAction(activeStep)} height="md" />
              <div className="right-buttons">
                {activeStep !== steps.firstStep && (
                  <Button disabled={disableStepNavigation} type="button" variant="gray-outline" width="xs" text={t('CREATE_REGIME_FORM.BACK')} onClick={() => stepAction(false)} height="md" />
                )}
                <Button
                  // disables the navigation on form popup for files & components steps
                  disabled={disableStepNavigation === steps.thirdStep || disableStepNavigation === steps.fourthStep}
                  width="xs"
                  height="md"
                  className="submit-button"
                  text={activeStep === lastStep ? (isPreviewMode ? t('CREATE_REGIME_FORM.CLOSE') : t('CREATE_REGIME_FORM.SAVE')) : t('CREATE_REGIME_FORM.NEXT')}
                />
              </div>
            </>
          )}
        </div>
      </form>
    );
  }
}

CreateRegimeForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

CreateRegimeForm = reduxForm({
  form: FORMS.createRegimeForm,
  enableReinitialize: true,
  validate: regimeValidate,
  touchOnChange: true,
  destroyOnUnmount: true,
  shouldValidate: ({ values, nextProps, props, initialRender, structure, syncErrors, asyncErrors, submitErrors, trigger }) => {
    // Validate on every step change
    return true;
  },
})(CreateRegimeForm);

export default CreateRegimeForm;
