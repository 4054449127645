import React from 'react';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';

import IsolationComponentsOrderList from './isolation-components-order-list';
import Button from '../../../../../../../common/form/components/button';
import { fields } from '../../constants/isolation-components-constants';

import '../../../../../styles/isolation-components-order-modal.scss';

const IsolationComponentsOrderModal = ({ components, handleOrderComponents, closeAction, modalDisabled }, { t }) => {
  const isolatedOrderedComponents = React.useMemo(() => {
    return orderBy(components, [fields.isolationOrder.name], ['asc']);
  }, [components]);

  const deIsolatedOrderComponents = React.useMemo(() => {
    return orderBy(components, [fields.order.name], ['asc']);
  }, [components]);

  return (
    <div className="isolation-components-order-modal">
      <IsolationComponentsOrderList
        title="ISOLATION_CERTIFICATE.ISOLATION_ORDER_TITLE"
        components={isolatedOrderedComponents}
        handleOrderComponents={handleOrderComponents}
        isIsolationOrder
        disabled={modalDisabled}
      />
      <IsolationComponentsOrderList
        title="ISOLATION_CERTIFICATE.DE_ISOLATION_ORDER_TITLE"
        components={deIsolatedOrderComponents}
        handleOrderComponents={handleOrderComponents}
        disabled={modalDisabled}
      />
      <div className="modal-action-buttons">
        <Button disabled={modalDisabled} variant="success-outline" height="md" width="sm" text={t('ISOLATION_CERTIFICATE.CANCEL_BUTTON')} onClick={closeAction} />
        <Button disabled={modalDisabled} className="save-btn" height="md" width="sm" variant="success" text={t('ISOLATION_CERTIFICATE.SAVE_BUTTON')} onClick={closeAction} />
      </div>
    </div>
  );
};

IsolationComponentsOrderModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

IsolationComponentsOrderModal.propTypes = {
  components: PropTypes.arrayOf(
    PropTypes.shape({
      ComponentID: PropTypes.number,
      DeisolationOrder: PropTypes.number,
      DesiredState: PropTypes.string,
      Files: PropTypes.array,
      IsolationMethod: PropTypes.string,
      IsolationOrder: PropTypes.number,
      Name: PropTypes.string,
      NormalPosition: PropTypes.string,
    })
  ),
  handleOrderComponents: PropTypes.func.isRequired,
};

export default IsolationComponentsOrderModal;
