import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { rehydrate } from '../../../index';

import CustomSelect from '../../../common/form/components/select';

import ProfileDetails from './profile-details';

import { changeTheme, changeSettings, getUnitData, changeUserLanguage, changeEmailCommentAlerting } from '../actions/profile-actions';
import { deleteAccessToken } from '../../landing/actions/actions';
import { find, pick } from 'lodash';

import { ReactComponent as Danger } from '../assets/danger.svg';

import { metricFields, params, mainProps, fullScreenData } from '../constants/profile-constants';

import '../styles/profile.scss';
import Icon from '../../../common/icon/components/icon';
import Toggle from '../../../common/form/components/toggle-field';

class Profile extends Component {
  state = {
    profile: {},
  };

  componentDidMount = () => {
    const { getUnitData } = this.props;
    getUnitData(profile => this.setState({ profile }));
  };

  toggleTheme = (theme, param) => {
    const { changeTheme, submitData } = this.props;
    const data = { ...submitData, ...{ [param]: theme[metricFields.value] } };
    changeTheme(theme[metricFields.value], data);
  };

  changeSettings = params => {
    const { changeSettings, submitData } = this.props;
    const data = { ...submitData, ...params };

    changeSettings(data);
  };

  changeLanguage = (lang, param) => {
    const { changeUserLanguage, submitData } = this.props;
    const data = { ...submitData, ...{ [param]: lang[metricFields.value] } };
    changeUserLanguage(lang[metricFields.value], data);
  };

  toggleEmailCommentAlerting = () => {
    const {
      changeEmailCommentAlerting,
      user: { CommentNotificationEnabled },
      submitData,
    } = this.props;
    const data = { ...submitData, [params.commentNotificationEnabled]: !CommentNotificationEnabled };
    changeEmailCommentAlerting(data);
  };

  handleDropboxClick = url => {
    const {
      user: { DropbboxAccessToken },
      deleteAccessToken,
    } = this.props;
    if (!DropbboxAccessToken) {
      rehydrate();
      window.open(url, '_blank');
    } else {
      // disconnect user
      deleteAccessToken();
    }
  };

  render() {
    const { t } = this.context;
    const {
      profile: { DistanceSettings: distanceUnitData, TemperatureSettings: temperatureUnitData, ThemeSettings: themeData, SupportedLanguages: languageData },
    } = this.state;
    const {
      user,
      user: { MFAMethod, Theme, Language, DistanceUnit, TemperatureUnit, FullScreenEnabled, CommentNotificationEnabled },
    } = this.props;

    // TODO: Restructure the profile page and apply BEM to the whole component

    return (
      <div className="profile">
        <div className="profile__container">
          <div className="profile__container__content-wrapper">
            <div className="profile-details">
              <div className="mfa-message">
                {MFAMethod ? <Icon name="checkmark" active={true} size="xs" /> : <Danger />}
                <p className="f-secondary-dark noselect">{t(MFAMethod ? 'PROFILE.MFA_ACTIVE' : 'PROFILE.MFA_NOT_ACTIVE')}</p>
              </div>
            </div>
            <ProfileDetails user={user} handleDropboxClick={this.handleDropboxClick} />
            <div className="profile-settings">
              <h4 className="mb-20 f-primary">{t('PROFILE.MORE_OPTIONS')}</h4>
              <div className="item">
                <CustomSelect
                  onChange={item => this.changeSettings({ FullScreenEnabled: item[metricFields.value] || false })}
                  label={'THEME_PICKER.VIEW_MODE'}
                  valueField={metricFields.value}
                  textField={metricFields.key}
                  data={fullScreenData || []}
                  valueComponent={({ item }) => item && t(item[metricFields.key])}
                  itemComponent={({ item }) => item && t(item[metricFields.key])}
                  defaultValue={find(fullScreenData, item => item[metricFields.value] === FullScreenEnabled)}
                />
              </div>
              <div className="item">
                <CustomSelect
                  onChange={theme => this.toggleTheme(theme, params.theme)}
                  label={'THEME_PICKER.CHOOSE_THEME'}
                  valueField={metricFields.value}
                  textField={metricFields.key}
                  data={themeData || []}
                  defaultValue={{ [metricFields.value]: Theme, [metricFields.key]: Theme }}
                />
              </div>
              <div className="item">
                <CustomSelect
                  onChange={lang => this.changeLanguage(lang, params.language)}
                  label={'THEME_PICKER.CHOOSE_LANGUAGE'}
                  valueField={metricFields.value}
                  textField={metricFields.key}
                  data={languageData || []}
                  defaultValue={{ [metricFields.value]: Language, [metricFields.key]: Language }}
                />
              </div>

              {false && (
                <Fragment>
                  <h4 className="f-primary">{t('THEME_PICKER.UNIT')}</h4>
                  <div className="dropdown-group">
                    <div className="item">
                      <CustomSelect
                        valueField={metricFields.value}
                        textField={metricFields.key}
                        onChange={unit => this.changeSettings({ [params.distance]: unit[metricFields.value] })}
                        data={distanceUnitData || []}
                        label={'THEME_PICKER.CHOOSE_DISTANCE_UNIT'}
                        defaultValue={{ [metricFields.value]: DistanceUnit, [metricFields.key]: DistanceUnit }}
                      />
                    </div>
                    <div className="item">
                      <CustomSelect
                        valueField={metricFields.value}
                        textField={metricFields.key}
                        onChange={unit => this.changeSettings({ [params.temperature]: unit[metricFields.value] })}
                        data={temperatureUnitData || []}
                        label={'THEME_PICKER.CHOOSE_TEMPERATURE_UNIT'}
                        defaultValue={{ [metricFields.value]: TemperatureUnit, [metricFields.key]: TemperatureUnit }}
                      />
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
            <div className="profile__alert-settings">
              <h4 className="mb-20 f-primary">{t('PROFILE.ALERT_OPTIONS')}</h4>
              <div className="item">
                <Toggle label={t('ALERT_OPTIONS.EMAIL')} input={{ value: CommentNotificationEnabled, onChange: this.toggleEmailCommentAlerting }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userReducer,
  //get data for submit as BE always expects all params
  submitData: pick(state.userReducer, mainProps),
});

const mapDispatchToProps = dispatch => ({
  changeTheme: (theme, data) => dispatch(changeTheme(theme, data)),
  changeSettings: unit => dispatch(changeSettings(unit)),
  getUnitData: callback => dispatch(getUnitData(callback)),
  deleteAccessToken: () => dispatch(deleteAccessToken()),
  changeUserLanguage: (lang, data) => dispatch(changeUserLanguage(lang, data)),
  changeEmailCommentAlerting: data => dispatch(changeEmailCommentAlerting(data)),
});

Profile.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
