import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { find, isEmpty, debounce } from 'lodash';

import { ReactComponent as EmptyIcon } from '../../../common/assets/electrician.svg';

import SearchInput from '../../../common/input/components/search-input';
import Toolbar from '../../../common/toolbar/components/toolbar';
import TableComponent from '../../../common/table/components/table-component';

import { getDefects } from '../actions/start-workflow-actions';
import { setActiveDefectsTab, setDefectsDataTableParams } from '../actions/action-creators';

import constants, { TABS, defectTableHeader, TOOLBAR_DATA } from '../constants/defect-list-constants';
import Helpers from '../../../common/helpers';
import { formConstants } from '../../inspections/constants/defect-constants';

import '../styles/defect-list.scss';

class DefectList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
    };
    this.searchChangeDebounced = debounce(this.searchDefects, 500);
  }

  componentDidMount() {
    const { setActiveDefectsTab, setDefectsDataTableParams, handleDefectClick, preSelectedDefect, defects } = this.props;
    setActiveDefectsTab(TABS.single);
    setDefectsDataTableParams(constants.defectsDataParams);

    if (!isNaN(preSelectedDefect) && preSelectedDefect > 0) {
      const selectedDef = find(defects, item => item[formConstants.fields.id] === preSelectedDefect);
      if (selectedDef) {
        handleDefectClick(
          {
            stopPropagation: () => null,
          },
          selectedDef
        );
      }
    }
  }

  componentWillUnmount() {
    this.searchChangeDebounced.cancel();
  }

  renderEmpty = () => {
    const { t } = this.context;

    return (
      <div className="defects-empty">
        <EmptyIcon className="defects-empty__icon svg-grag-g" />
        <div className="defects-empty__title">
          <p className="f-secondary-dark">{t('START_WORKFLOW.DEFECTS.EMPTY_TITLE')}</p>
        </div>
        <div className="defects-empty__description">
          <label className="defects-empty__description f-secondary-dark">{t('START_WORKFLOW.DEFECTS.EMPTY_DESCRIPTION')}</label>
        </div>
      </div>
    );
  };

  onColumnSort = SortByColumn => {
    const { getDefects, defectsTableParams, setDefectsDataTableParams, inspectionID, activeTab, currentImage } = this.props;
    const newParams = {
      ...defectsTableParams,
      SortByColumn,
      SortDirection: defectsTableParams.SortDirection === constants.sortDirection.asc ? constants.sortDirection.desc : constants.sortDirection.asc,
    };

    setDefectsDataTableParams(newParams);
    getDefects(inspectionID, newParams, activeTab, currentImage);
  };

  renderDefects = defects => {
    const { t } = this.context;
    const { handleDefectClick, handleHideDefect, handleShowDefect, defectsTableParams, selectedDefect } = this.props;
    const defectsData = defects.map(el => (!isEmpty(selectedDefect) && el[formConstants.fields.id] === selectedDefect[formConstants.fields.id] ? { ...el, selected: true } : el));

    if (isEmpty(defectsData)) {
      return this.renderEmpty();
    }

    return (
      <div className="defects-wrapper">
        <TableComponent
          tableConfig={defectTableHeader(handleHideDefect, handleShowDefect)}
          data={defectsData || []}
          tableCustomClass="defects-table"
          onRowClick={handleDefectClick}
          onColumnSort={this.onColumnSort}
          sortingObj={defectsTableParams}
          translationModule={t}
          formatCell={Helpers.formatCell}
          stickyHeader={true}
        />
      </div>
    );
  };

  searchDefects = newParams => {
    const { getDefects, inspectionID, activeTab, currentImage } = this.props;
    if (!inspectionID) return;
    getDefects(inspectionID, newParams, activeTab, currentImage);
  };

  handleInputChange = e => {
    const { defectsTableParams, setDefectsDataTableParams } = this.props;
    this.setState({ searchText: e.target.value });
    const newParams = {
      ...defectsTableParams,
      Searchtext: (e.target.value || '').trim(),
    };
    setDefectsDataTableParams(newParams);
    this.searchChangeDebounced(newParams);
  };

  handleTabClick = tab => {
    const { getDefects, inspectionID, currentImage, setActiveDefectsTab, defectsTableParams } = this.props;

    getDefects(inspectionID, defectsTableParams, tab, currentImage);
    setActiveDefectsTab(tab);
  };

  render() {
    const { defects, activeTab } = this.props;
    const { searchText } = this.state;
    const { t } = this.context;

    return (
      <div className="defect-list auto-scroll">
        <div className="header">
          <div className="header__left">
            <Toolbar activeItem={activeTab} handleActiveTab={this.handleTabClick} toolbarItems={TOOLBAR_DATA} />
          </div>
          <div className="header__right">
            <SearchInput value={searchText} placeholder={t('PROJECT.SEARCH.PLACEHOLDER')} onChange={this.handleInputChange} />
          </div>
        </div>
        {this.renderDefects(defects)}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  inspectionID: state.inspectionReducer.inspectionID,
  activeTab: state.startWorkflowReducer.activeDefectsTab,
  selectedDefect: state.startWorkflowReducer.selectedDefect,
  defectsTableParams: state.startWorkflowReducer.defectsTableParams,
  currentImage: state.startWorkflowReducer.currentImage,
});

const mapDispatchToProps = dispatch => ({
  getDefects: (inspectionID, params, activeDefectsTab, currentImage) => dispatch(getDefects(inspectionID, params, activeDefectsTab, currentImage)),
  setActiveDefectsTab: tab => dispatch(setActiveDefectsTab(tab)),
  setDefectsDataTableParams: params => dispatch(setDefectsDataTableParams(params)),
});

DefectList.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(DefectList);
