import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, getFormSyncErrors } from 'redux-form';

import Button from '../../../../../common/form/components/button';
import Helpers from '../../../../../common/helpers';
import { FORMS } from '../../../../../common/constants';
import { isolationComponentFields, steps } from '../../right-toolbar/isolation-certificate-details/constants/change-status-constants';
import { validateIsolationComponentCheckboxes } from '../../../components/right-toolbar/validators/change-status-isolation-components-validator';
import SectionIsolationComponent from './section-isolation-component';

const ChangeStatusIsolationComponents = (
  {
    handleSubmit,
    anyTouched,
    formErrors,
    isolationComponents = [],
    handleFieldChange,
    handleFinishIsolationComponents,
    handleCloseModal,
    toggleIsolationComponentDetails,
    hasQuestions,
    handleGoBack,
    handleResetIsolationComponentCheckboxes,
  },
  { t }
) => {
  const onFieldChange = (isolationComponent, value) => {
    handleFieldChange(isolationComponent, value);
  };

  const handleCancel = () => {
    handleResetIsolationComponentCheckboxes(isolationComponents);
    hasQuestions ? handleGoBack(steps.first) : handleCloseModal();
  };

  const someIsolationComponentsUnchecked = isolationComponents.some(isolationComponent => !isolationComponent[isolationComponentFields.isChecked]);

  return (
    <div className="change-status-isolation-components">
      <form onSubmit={handleSubmit(() => handleFinishIsolationComponents(hasQuestions ? steps.third : steps.second))} noValidate>
        {isolationComponents?.length > 0 &&
          isolationComponents.map((isolationComponent, index) => (
            <SectionIsolationComponent
              key={`change-status-isolation-component-item-${isolationComponent[isolationComponentFields.componentId]}`}
              isolationComponent={isolationComponent}
              isolationComponentNumber={index + 1}
              onFieldChange={onFieldChange}
              formErrors={formErrors}
              anyTouched={anyTouched}
              toggleIsolationComponentDetails={toggleIsolationComponentDetails}
              hideIsolationComponentAdditionalDetails
            />
          ))}
        <div className="questions-buttons-container">
          <Button
            type="button"
            variant="success-outline"
            height="md"
            width="sm"
            text={hasQuestions ? t('ISOLATION_CERTIFICATE_STATUS.BACK_BUTTON') : t('ISOLATION_CERTIFICATE.CANCEL_BUTTON')}
            onClick={handleCancel}
          />
          <Button type="submit" className="save-btn" disabled={someIsolationComponentsUnchecked} height="md" width="sm" variant="success" text={t('ISOLATION_CERTIFICATE_STATUS.NEXT_BUTTON')} />
        </div>
      </form>
    </div>
  );
};

ChangeStatusIsolationComponents.contextTypes = {
  t: PropTypes.func.isRequired,
};

ChangeStatusIsolationComponents.propTypes = {
  anyTouched: PropTypes.bool,
  formErrors: PropTypes.object,
  isolationComponents: PropTypes.arrayOf(
    PropTypes.shape({
      ComponentID: PropTypes.number,
      Name: PropTypes.string.isRequired,
      Colour: PropTypes.string,
      ComponentCode: PropTypes.string,
      ComponentType: PropTypes.string,
      ComponentSubType: PropTypes.string,
      DesiredState: PropTypes.string,
      NormalPosition: PropTypes.string,
      IsolationMethod: PropTypes.string,
      DeisolationOrder: PropTypes.number,
      IsolationOrder: PropTypes.number,
      Files: PropTypes.array,
    })
  ),
  handleFieldChange: PropTypes.func.isRequired,
  handleFinishIsolationComponents: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  toggleIsolationComponentDetails: PropTypes.func.isRequired,
  hasQuestions: PropTypes.bool,
  handleResetIsolationComponentCheckboxes: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  formErrors: getFormSyncErrors(FORMS.changeModuleStatusIsolationComponentsForm)(state),
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: FORMS.changeModuleStatusIsolationComponentsForm,
    enableReinitialize: true,
    touchOnChange: true,
    destroyOnUnmount: true,
    validateIsolationComponentCheckboxes,
    onSubmitFail: errors => Helpers.scrollToFirstError(errors),
  })(ChangeStatusIsolationComponents)
);
