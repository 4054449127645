import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import PropTypes from 'prop-types';

import { chain, debounce, find, get, isEmpty, isString, keys, map, reduce, toInteger, values } from 'lodash';

import { notificationModalCustom } from '../../../../common/modal/actions/modal-actions';
import {
  clearUnsavedChangesDirty,
  deselectAllDefects,
  deselectAllDefectsTemp,
  selectAllDefects,
  selectAllDefectsTemp,
  setComponentFilterData,
  toggleDefect,
  toggleDefectTemp,
} from '../../actions/action-creators';
import { updateElementGeometry } from '../../actions/inspection-actions';

import AccessRenderer from '../../../../common/access-renderer/components/access-renderer';
import Icon from '../../../../common/icon/components/icon';
import ItemsTableRenderer from '../../../../common/items-renderer/components/items-table-renderer';
import ModuleHeader from './common/module-header';
import SearchText from './common/search-text';
import VisibilityBox from './common/visibility-box';
import DefectFilters from './filters/defects-filter';
import QuickFilter from './filters/quick-filter';

import InspectionRenderer from './inspection-renderer';

import {
  assigneeFields,
  assigneeTypes,
  collaboratorFields,
  defaultPagingObj,
  defaultSortingFilter,
  downloadReportLimitation,
  filterParams,
  formConstants,
  observationTools,
  quickFilter,
  sortingDirection,
  tableConfig,
  tableConfigTypes,
} from '../../constants/defect-constants';
import { severityColors as severityColorsConstants } from '../observations/constants/constants';

import { FEATURES } from '../../../../common/constants';
import Helpers from '../../../../common/helpers';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../common/permissions-constants';
import { routes } from '../../../../common/routes-constants';
import { teamFields } from '../../../members/constants/team-constants';
import { fetchDMSCategories } from '../../../project/actions/project-actions';

import TruncatedText from '../../../../common/form/components/truncated-text';
import { DMSCategoriesModules } from '../../../document-management/constants/constants';
import '../../styles/defects-section.scss';

class Defects extends Component {
  constructor(props) {
    super(props);
    const colors = values(quickFilter);
    const { location } = props;
    const { query } = location;

    this.state = {
      quickFilter: map(colors, item => {
        return {
          ...item,
          color: props.severityColors[item.colorKey],
          customComponent: (...args) => item.customComponent(...args),
        };
      }),
      advancedFilter: {},
      pagingObject: defaultPagingObj,
      sortingFilter: defaultSortingFilter,
    };
    this.ProjectID = toInteger(get(query, 'project_id'));
    this.searchChangeDebounced = debounce(this.getDefects, 300);
  }

  componentDidMount = () => {
    const { queryItem, fetchDMSCategories } = this.props;
    let params = {};

    if (queryItem) {
      params = {
        [filterParams.includedIDs]: [queryItem],
      };
    }
    this.getDefects(params);
    fetchDMSCategories(this.ProjectID, DMSCategoriesModules.observations);
  };

  componentDidUpdate = prevProps => {
    const { searchText } = this.props;

    //handle search text changed
    if (isString(searchText) && prevProps.searchText !== searchText) {
      this.setState({
        pagingObject: defaultPagingObj,
      });
      this.searchChangeDebounced();
    }
  };

  componentWillUnmount = () => {
    const { handleDefectRelatedComponentsVisible } = this.props;
    handleDefectRelatedComponentsVisible(false);
  };

  addCurrentUserToAdvancedFilter = (advancedFilter, fields = { id: assigneeFields.id, name: assigneeFields.name, type: assigneeFields.type, assigneeType: assigneeFields.assigneeType }) => {
    const { user } = this.props;

    const userToAdd = {
      [fields.id]: user[teamFields.userId],
      [fields.name]: user[teamFields.name],
      [fields.assigneeType]: assigneeTypes.user,
    };
    if (!isEmpty(advancedFilter)) {
      const foundUserIndex = advancedFilter.filter(assignee => assignee[fields.assigneeType] === assigneeTypes.user).findIndex(assignee => assignee[fields.id] === userToAdd[fields.id]);
      if (foundUserIndex < 0) {
        // user not found in advanced filter, add current user
        advancedFilter.push(userToAdd);
      }
    } else {
      advancedFilter = [userToAdd];
    }
    return advancedFilter;
  };

  getDefects = (params, loadMore = false) => {
    const { getInspectionDefects, searchText, setClusteringFilters } = this.props;
    let { quickFilter, advancedFilter, pagingObject, sortingFilter } = this.state;

    //normalize advanced filter
    const newAdvancedFilter = reduce(
      advancedFilter,
      (obj, it, key) => {
        if (key === filterParams.severityFilter) {
          //need to check with BE
          obj[key] = [advancedFilter[filterParams.severityFilter].min, advancedFilter[filterParams.severityFilter].max];
        } else if ([filterParams.componentTypeFilter, filterParams.defectMainTypeFilter, filterParams.defectTypeFilter].indexOf(key) > -1) {
          obj[key] = advancedFilter[key].toString();
        } else if ([filterParams.componentIDsFilter, filterParams.createdByFilter].indexOf(key) > -1) {
          obj[key] = map(advancedFilter[key], ({ ID, UserID }) => ID || UserID);
        } else if ([filterParams.dateFrom, filterParams.actionedFrom, filterParams.closedFrom].indexOf(key) > -1) {
          obj[key] = (advancedFilter[key] && Helpers.getUnixDate(new Date(advancedFilter[key]).getTime())) || null;
        } else if ([filterParams.dateTo, filterParams.actionedTo, filterParams.closedTo].indexOf(key) > -1) {
          // sets end/to dates to end of day 23:59:59 to include that day in filter results
          obj[key] = (advancedFilter[key] && Helpers.getUnixDate(new Date(advancedFilter[key]).setHours(23, 59, 59))) || null;
        } else if ([filterParams.hasWorkOrder, filterParams.hasNotificationFilter, filterParams.hasFiles, filterParams.has3DLocationFilter].indexOf(key) > -1) {
          // casting YES / NO to boolean values, otherwise sending filter as null
          obj[key] = Helpers.castToggleRadioInputAnswer(advancedFilter[key]);
        } else {
          obj[key] = advancedFilter[key];
        }
        return obj;
      },
      {}
    );

    //normalize quick filter
    const qFilter =
      chain(quickFilter)
        .filter(item => item.active)
        .groupBy(item => item.field)
        .reduce((obj, items, key) => {
          if (key === filterParams.statusFilter) {
            obj[key] = map(items, it => it.value).toString();
          }
          if (key === filterParams.linkedToMe) {
            obj[key] = true;
            // Linked to me quick filter is active, add current user to assignees and collaborators advanced filters
            // Add assignee
            newAdvancedFilter[filterParams.assignees] = this.addCurrentUserToAdvancedFilter(newAdvancedFilter[filterParams.assignees], assigneeFields);
            // Add collaborator
            newAdvancedFilter[filterParams.collaborators] = this.addCurrentUserToAdvancedFilter(newAdvancedFilter[filterParams.collaborators], collaboratorFields);
          }
          return obj;
        }, {})
        .value() || {};

    setClusteringFilters({ ...newAdvancedFilter, ...qFilter });

    getInspectionDefects(
      searchText,
      { ...newAdvancedFilter, ...qFilter, ...pagingObject, ...sortingFilter, ...params },
      (arr, incomingFilter, c) => {
        this.setState({ pagingObject: { ...pagingObject, [filterParams.totalItems]: incomingFilter[filterParams.totalItems], [filterParams.hasNext]: incomingFilter[filterParams.hasNext] } });
      },
      loadMore
    );
  };

  loadMore = () => {
    const { pagingObject } = this.state;
    const { elements } = this.props;
    this.setState({
      pagingObject: {
        ...pagingObject,
        [filterParams.lastSeen]: elements.length || 0,
      },
    });
    this.searchChangeDebounced(null, true);
  };

  handleQuickFilter = item => {
    const { quickFilter } = this.state;
    //inclusive filter
    this.setState({
      quickFilter: map(quickFilter, it => {
        if (item.label === it.label) {
          it = { ...it, active: !it.active };
        }
        return it;
      }),
      pagingObject: defaultPagingObj,
    });
    this.searchChangeDebounced();
  };

  onColumnSort = column => {
    const { sortingFilter } = this.state;

    this.setState({
      sortingFilter: {
        [filterParams.sortByColumn]: column,
        [filterParams.sortDirection]: sortingFilter[filterParams.sortDirection] === sortingDirection.asc ? sortingDirection.desc : sortingDirection.asc,
      },
      pagingObject: defaultPagingObj,
    });

    this.searchChangeDebounced();
  };

  openAdvancedFilter = () => {
    const { t } = this.context;
    const { setModalState, inspectionId } = this.props;
    const { advancedFilter } = this.state;
    const closeAction = () => setModalState({ isOpen: false });

    const modalData = {
      isOpen: true,
      type: 'none',
      title: t('ADVANCED_FILTERS.TITLE'),
      CustomContent: () => (
        <DefectFilters
          submitForm={values => {
            this.setState({
              advancedFilter: values,
              pagingObject: defaultPagingObj,
            });
            closeAction();
            this.searchChangeDebounced();
          }}
          resetFilter={() => {
            this.setState({
              advancedFilter: {},
              pagingObject: defaultPagingObj,
            });
            closeAction();
            this.searchChangeDebounced();
          }}
          initialValues={advancedFilter}
          closeAction={closeAction}
          inspectionId={inspectionId}
        />
      ),
      customClassName: 'modal-large defects-filter',
      closeAction,
    };
    setModalState(modalData);
  };

  manageDownloadReport = () => {
    const { openDownloadReportModal, elements, notificationModalCustom } = this.props;

    if (!elements?.length || elements?.length === 0) {
      notificationModalCustom(true, 'OBSERVATION_REPORT.NO_ITEMS', 'OBSERVATION_REPORT.WARNING');
    } else if (elements?.length > downloadReportLimitation) {
      notificationModalCustom(
        true,
        {
          key: 'OBSERVATION_REPORT.MAX_ITEMS_REACHED',
          props: {
            count: downloadReportLimitation,
          },
        },
        'OBSERVATION_REPORT.WARNING'
      );
    } else {
      openDownloadReportModal(elements);
    }
  };

  goToHistoryPage = queryParams => {
    const path = `${routes.protectedRoutes.modulesHistory.fullPath}${queryParams}`;
    Helpers.goTo(path);
  };

  createNewDefectHandler = async type => {
    const { isDirty, createNewDefect, clearUnsavedChangesDirty, viewer } = this.props;
    if (viewer) {
      // remove all insertions if selecting through defects to prevent duplicates
      viewer.dispatchEvent({
        type: 'cancel_all_insertions',
      });
    }
    if (isDirty) {
      if (window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
        await clearUnsavedChangesDirty();
        createNewDefect(type);
      }
    } else createNewDefect(type);
  };

  render() {
    const { t } = this.context;
    const { quickFilter, pagingObject, sortingFilter, advancedFilter } = this.state;
    const { search } = this.props.location;
    const {
      elements,
      deselectAll,
      selectAll,
      toggleDefect,
      deselectAllDefectsTemp,
      selectAllDefectsTemp,
      toggleDefectTemp,
      updateGeometry,
      scrollToElement,
      generateCustomIdentificator,
      defectsLoading,
      handleItemClick,
      queryItem,
      elementsClustered,
      selectedClusterElement,
      title,
      backAction,
      moduleActionsMenu,
      tableConfig,
      viewer,
      icon,
      defectLocationEditing,
      allDefectsVisible,
    } = this.props;

    let observationActionsMenu = FEATURES.modulesHistory?.visible
      ? [
          {
            title: 'INSPECTION_FULLSCREEN.VIEW_HISTORY',
            action: () => this.goToHistoryPage(search),
          },
        ]
      : [];

    return (
      <AccessRenderer
        visibleFor={PERMISSIONS[PERMISSION_TYPES.observations].edit.name}
        id={selectedClusterElement && selectedClusterElement[formConstants.fields.createdById]}
        ownerRequiredPermission={PERMISSIONS[PERMISSION_TYPES.observations].create.name}
      >
        {({ hasAccess }) => {
          return (
            <InspectionRenderer
              deselectAll={deselectAll}
              selectAll={selectAll}
              toggleElement={toggleDefect}
              deselectAllTemp={deselectAllDefectsTemp}
              selectAllTemp={selectAllDefectsTemp}
              toggleElementTemp={toggleDefectTemp}
              updateElementGeometry={(...args) => {
                if (!hasAccess) return;
                updateGeometry(...args);
              }}
              selectElement={(data, autoScroll) => {
                if (autoScroll) {
                  scrollToElement(data);
                }
                handleItemClick(data[formConstants.fields.id]);
              }}
              elements={elementsClustered.map(item => {
                if (item[formConstants.fields.id] === queryItem) {
                  return { ...item, enableMove: defectLocationEditing };
                } else {
                  return item;
                }
              })}
              queryItem={queryItem}
              disableMove={!defectLocationEditing}
              viewer={viewer}
            >
              {({ elementClickHandler, elementShowHandler, elementHideHandler, selectAllHandler, deselectAllHandler }) => (
                <div className="defects-section">
                  <ModuleHeader
                    {...{
                      title,
                      icon,
                      backAction,
                      getClickAction: this.createNewDefectHandler,
                      actionsMenu: [
                        {
                          title: 'INSPECTION_DETAILS_DOWNLOAD_REPORT',
                          action: this.manageDownloadReport,
                        },
                        ...moduleActionsMenu,
                        ...observationActionsMenu,
                      ],
                      actionButtontext: t('INSPECTION_DETAILS.NEW'),
                      // Null just to show the button
                      handleActionButtonClick: e => null,
                      actionButtonProps: {
                        getClickAction: this.createNewDefectHandler,
                        dropdownMenu: observationTools,
                        hasDropdown: true,
                        icon: 'chevron-down',
                        iconProps: { solid: true, handleHover: false },
                        stopProp: true,
                        disabled: defectLocationEditing,
                      },
                    }}
                  />
                  <div className="advanced-filter">
                    <SearchText maxLength={60} />
                    <p className="f-secondary-green noselect pointer link" onClick={() => this.openAdvancedFilter()}>
                      {t('ADVANCED_FILTER', { active: keys(advancedFilter).length })}
                    </p>
                  </div>

                  <QuickFilter items={quickFilter} onClick={this.handleQuickFilter} />

                  {viewer && (
                    <VisibilityBox
                      {...{
                        selectAllHandler,
                        deselectAllHandler,
                        showIcon: true,
                        allVisible: allDefectsVisible,
                      }}
                    />
                  )}
                  <ItemsTableRenderer
                    isLoading={defectsLoading}
                    tableConfig={tableConfig}
                    translationModule={t}
                    data={map(elements, item => ({ ...item, selected: item[formConstants.fields.id] === queryItem ? true : false }))}
                    onRowClick={elementClickHandler}
                    loadMore={{
                      visible: pagingObject[filterParams.hasNext],
                      disabled: defectsLoading,
                      onClick: this.loadMore,
                    }}
                    sortingObj={sortingFilter}
                    onColumnSort={this.onColumnSort}
                    formatCell={(value, type, index, item) => {
                      const { t } = this.context;
                      if (type === tableConfigTypes.name) {
                        return <TruncatedText text={value} />;
                      }
                      if (type === tableConfigTypes.iconsCustom) {
                        const statusObj = find(quickFilter, { value: item[formConstants.fields.status] });
                        return <>{!isEmpty(statusObj) && statusObj.customComponent('small')} </>;
                      }
                      if (type === tableConfigTypes.severity || type === tableConfigTypes.severityLite) {
                        const { t } = this.context;
                        return (
                          <div className={`severity-icon ${severityColorsConstants[item[formConstants.fields.severity]] || 'severity-green'}`} title={t('DEFECT_LIST.SEVERITY_ICON_TEXT')}>
                            {item[formConstants.fields.severity]}
                          </div>
                        );
                      }
                      if (type === tableConfigTypes.statusCustom) {
                        return (
                          <>
                            {viewer && (
                              <Icon
                                name={item.visible ? 'eye' : 'not-visible'}
                                size="md"
                                onClick={item.visible ? e => elementHideHandler(e, item) : e => elementShowHandler(e, item)}
                                title={t('ITEMS_LIST.VISIBILTY_ICON')}
                              />
                            )}
                          </>
                        );
                      }
                      if (type === tableConfigTypes.nameLite) {
                        return <TruncatedText text={value} />;
                      }
                      if (type === tableConfigTypes.iconsCustomLite) {
                        return (
                          !item.Geometry ||
                          isEmpty(item.Geometry) ||
                          (isEmpty(item.Geometry.coordinates) && (
                            <div className="missing-pin">
                              <Icon name="warning" warning handleHover={false} size="md" title={t('ITEMS_LIST.WARNING_ICON_TEXT')} />
                            </div>
                          ))
                        );
                      }

                      if (type === tableConfigTypes.statusCustomLite) {
                        const statusObj = find(quickFilter, { value: item[formConstants.fields.status] });

                        return (
                          !isEmpty(statusObj) && (
                            <>
                              {statusObj.customComponent('small status-icon-defect')}
                              <p className="status-n f-primary">{t(statusObj.label)}</p>
                            </>
                          )
                        );
                      }

                      if (type === tableConfigTypes.dateLite) {
                        return Helpers.getDateFromUnix(value);
                      }
                      if (type === tableConfigTypes.date) {
                        return Helpers.getDateFromUnix(value, 'll');
                      }
                      if (type === tableConfigTypes.descriptionLite || type === tableConfigTypes.recommendationLite) {
                        return (
                          <div className="long-field" title={value}>
                            {value?.length > 50 ? value.substring(0, 50) : value}
                          </div>
                        );
                      }
                      if (type === tableConfigTypes.mainTypeLite) {
                        const res = find(observationTools, ({ action }) => action === value);

                        return (
                          <>
                            <div className="type-icon">{res?.icon && <res.icon />}</div>
                            {res?.title ? t(res.title) : value}
                          </>
                        );
                      }

                      return Helpers.formatCell(value, type, index, item);
                    }}
                    emptyStateText={'INSPECTION_DEFECTS.EMPTY_STATE'}
                    customIdentificatorFunction={generateCustomIdentificator}
                  />
                  {pagingObject[filterParams.totalItems] > 0 && (
                    <p className="f-secondary-dark total">
                      {t('SHOWING_TOTAL_ITEMS', {
                        items: elements?.length || 0,
                        totalItems: pagingObject[filterParams.totalItems],
                      })}
                    </p>
                  )}
                </div>
              )}
            </InspectionRenderer>
          );
        }}
      </AccessRenderer>
    );
  }
}

const mapStateToProps = state => ({
  defectLocationEditing: state.inspectionReducer.defectLocationEditing,
  allDefectsVisible: state.inspectionReducer.allDefectsVisible,
  user: state.userReducer,
  isDirty: state.unsavedChangesReducer.isDirty,
});

const mapDispatchToProps = dispatch => ({
  deselectAll: () => dispatch(deselectAllDefects()),
  selectAll: () => dispatch(selectAllDefects()),
  toggleDefect: id => dispatch(toggleDefect(id)),
  // TEMP
  deselectAllDefectsTemp: () => dispatch(deselectAllDefectsTemp()),
  selectAllDefectsTemp: () => dispatch(selectAllDefectsTemp()),
  toggleDefectTemp: id => dispatch(toggleDefectTemp(id)),
  //
  setComponentFilterData: data => dispatch(setComponentFilterData(data)),
  updateGeometry: data => dispatch(updateElementGeometry(data)),
  notificationModalCustom: (isOpen, message, title) => dispatch(notificationModalCustom(isOpen, message, title)),
  fetchDMSCategories: (projectId, moduleName, callback) => dispatch(fetchDMSCategories(projectId, moduleName, callback)),
  clearUnsavedChangesDirty: () => dispatch(clearUnsavedChangesDirty()),
});

Defects.defaultProps = {
  tableConfig: tableConfig,
};

Defects.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Defects));
