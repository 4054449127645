import React from 'react';
import PropTypes from 'prop-types';
import { fields } from '../../../../../work-order/constants/work-order-right-side-constants';
import RenderIf from '../../../../../../../../common/render-if/components/render-if';

const AddWorkOrderPopover = ({ workOrder }, { t }) => {
  return (
    <div>
      <p className="bold">{workOrder[fields.name]}</p>
      <p className="add-module-item-modal__popover-title">{t('DISPLAY_PROPS.DESCRIPTION')}</p>
      <p className="mt-4">{workOrder[fields.description] ? workOrder[fields.description] : '-'}</p>
      <p className="add-module-item-modal__popover-title">{t('INSPECTION_COMPONENTS')}</p>
      <RenderIf if={workOrder[fields.components]}>
        {(workOrder[fields.components] || []).map(component => {
          return <p className="mt-4">{component}</p>;
        })}
      </RenderIf>
      <RenderIf if={!workOrder[fields.components]}>
        <p className="mt-4">-</p>
      </RenderIf>
    </div>
  );
};

AddWorkOrderPopover.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default AddWorkOrderPopover;
