import { AMAZON_IMAGE_SIZES } from './constants';

import inspectionWorkflowConstants from '../app/inspection-workflow/constants/inspection-workflow-constants';

const formatAmazonImageSource = (src, type, item) => {
  let tempSrc = null;
  switch (type) {
    case AMAZON_IMAGE_SIZES.small.name:
      tempSrc = item.thumbnailUrl;
      break;
    case AMAZON_IMAGE_SIZES.medium.name:
      tempSrc = item.documentSizeUrl;
      break;
    case AMAZON_IMAGE_SIZES.large.name:
      tempSrc = item.src;
      break;
    default:
      tempSrc = src;
      break;
  }
  if (tempSrc) {
    return tempSrc;
  } else {
    console.warn(`This image item id: ${item[inspectionWorkflowConstants.formConstants.fields.id]} doesn't have available src for ${type} type`);
    return src;
  }
};

/*eslint no-extend-native:*/
Object.defineProperty(String.prototype, 'getImageSize', {
  value: function getImageSize(type, item) {
    const url = formatAmazonImageSource(this, type, item);

    return url;
  },
  writable: true,
  configurable: true,
});
