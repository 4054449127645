import PropTypes from 'prop-types';
import React from 'react';

import { Field, reduxForm } from 'redux-form';

import { FORMS } from '../../../../../../common/constants';
import Button from '../../../../../../common/form/components/button';
import CustomMultiSelectV2 from '../../../../../../common/form/components/multiselect-v2';
import CustomSelect from '../../../../../../common/form/components/select';
import Toggle from '../../../../../../common/form/components/toggle-field';
import Loader from '../../../../../../common/global-loader/components/simple-loader';
import Icon from '../../../../../../common/icon/components/icon';
import { aggregationFields, chunkFields, settingsFormConstants as formConstants, formConstants as graphingGroupFields, timePeriodFields } from '../../constants/time-series-graph-constants';
import '../../styles/time-series-graph-settings-form.scss';
import TimePeriodField from '../graphing-groups/time-period-field';

const TimeSeriesGraphSettingsForm = (props, context) => {
  const { t } = context;
  const {
    handleSubmit,
    graphingGroups,
    aggregationList,
    chunks,
    change,
    handleChangeGraphingGroup,
    graphSettingsLoading,
    showGraphingGroup,
    changeField,
    formValues,
    timePeriodsList,
    handleSaveSettings,
    canSave = false,
  } = props;

  return (
    <form className="time-series-graph-settings-form" onSubmit={handleSubmit} noValidate>
      {showGraphingGroup && (
        <Field
          id={formConstants.graphingGroup.id}
          name={formConstants.graphingGroup.name}
          label={formConstants.graphingGroup.label}
          disabled={false}
          component={CustomSelect}
          data={graphingGroups}
          textField={graphingGroupFields.name}
          valueField={graphingGroupFields.id}
          size="lg"
          className="graphing-group"
          onChange={val => {
            change(formConstants.graphingGroup.name, val);
            handleChangeGraphingGroup(val);
          }}
          isEnhancedDesignCustomProp
        />
      )}
      {graphSettingsLoading ? (
        <Loader isLoading={true} />
      ) : (
        <>
          <Field
            id={formConstants.aggregation.id}
            name={formConstants.aggregation.name}
            label={formConstants.aggregation.label}
            disabled={false}
            component={CustomMultiSelectV2}
            data={aggregationList}
            textField={aggregationFields.value}
            valueField={aggregationFields.key}
            // size="lg"
            isEnhancedDesignCustomProp
          />
          <TimePeriodField
            id={formConstants.timePeriod.id}
            name={formConstants.timePeriod.name}
            label={formConstants.timePeriod.label}
            placeholder={t(formConstants.timePeriod.placeholder)}
            disabled={false}
            data={timePeriodsList}
            textField={timePeriodFields.key}
            valueField={timePeriodFields.value}
            formValues={formValues}
            changeField={changeField}
            CustomSelectIcon={<Icon name="chevron-down" className="dropdown-icon" handleHover={true} size="sm" />}
          />
          <Field
            id={formConstants.chunks.id}
            name={formConstants.chunks.name}
            label={formConstants.chunks.label}
            disabled={false}
            component={CustomSelect}
            data={chunks}
            textField={chunkFields.value}
            valueField={chunkFields.key}
            size="lg"
            placeholder={t(formConstants.chunks.placeholder)}
            isEnhancedDesignCustomProp
            CustomSelectIcon={<Icon name="chevron-down" className="dropdown-icon" handleHover={true} size="sm" />}
          />
          <Field
            id={formConstants.showAlarms.id}
            name={formConstants.showAlarms.name}
            component={Toggle}
            infoText={formConstants.showAlarms.description}
            label={t(formConstants.showAlarms.label)}
            disabled={false}
          />
          <div className="buttons">
            <Button type="submit" width="lg" height="md" text={t('TIME_SERIES_GRAPH.SETTINGS_FORM.BUTTON.APPLY')} keepOriginalText />
            <Button
              type="button"
              width="lg"
              height="md"
              variant="success-outline"
              text={t('TIME_SERIES_GRAPH.SETTINGS_FORM.BUTTON.SAVE_SETTINGS')}
              onClick={!canSave ? null : handleSaveSettings}
              keepOriginalText
              disabled={!canSave}
            />
          </div>
        </>
      )}
    </form>
  );
};

TimeSeriesGraphSettingsForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default reduxForm({
  form: FORMS.timeSeriesGraphSettingsForm,
  enableReinitialize: true,
})(TimeSeriesGraphSettingsForm);
