import { debounce, get, isArray, toInteger } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Button from '../../../../common/form/components/button';
import CustomCheckBox from '../../../../common/form/components/custom-checkbox';
import MultipleCategorySelect from '../../../../common/form/components/multiple-category-select';
import Helpers from '../../../../common/helpers';
import Icon from '../../../../common/icon/components/icon';
import SearchInput from '../../../../common/input/components/search-input';
import Modal from '../../../../common/modal/components/modal';
import { ComponentPicker, ComponentPicker3D, ComponentPickerCH, ComponentPickerPDF } from '../../../../common/question-components/components';
import RenderIf from '../../../../common/render-if/components/render-if';
import InfoTooltip from '../../../../common/tooltip/components/info-tooltip';
import { fetchComponentsToLink } from '../../actions/dm-api-calls';
import { componentDetailsDisplayProps, defaultFileComponentsFilters, fields, filterProps } from '../../constants/constants';
import dmsHelpers from '../../helpers/dms-helpers';
import '../../styles/dms-components-picker.scss';
import ActionModal from '../modals/action-modal/action-modal';
import ChangeCategoryForm from '../modals/change-category-modal/change-category-modal';

const DMSFileComponentPickerModal = (
  { fileID, initialComponents, customCloseAction, fetchComponentsToLink, location, projectDMSCategories, defaultComponentDMSCategoryID, customConfirmAction },
  { t }
) => {
  const { query } = location;
  const inspectionID = toInteger(get(query, 'inspection_id'));

  const [components, setComponents] = useState([]);
  const [fileComponentsFilters, setFileComponentsFilters] = useState(defaultFileComponentsFilters);
  const [componentsSearchText, setComponentsSearchText] = useState('');
  const [componentsLoading, setComponentsLoading] = useState(false);
  const [keepComponentsDropdownVisible, setKeepComponentsDropdownVisible] = useState(false);
  const [isCategorySelectOpen, toggleCategorySelectOpen] = useState(false);
  const [selectedCheckedComponents, setSelectedCheckedComponents] = useState([]);
  const [stateCategoriesList, setStateCategoriesList] = useState(dmsHelpers.getCategories(projectDMSCategories, t));
  const [selectedComponents, setSelectedComponents] = useState(
    [...(initialComponents || [])].map(component => ({
      ...component,
      [fields.componentID]: component[fields.id],
      [fields.name]: component[fields.componentName],
      [fields.componentCodeShort]: component[fields.componentCode],
      [fields.componentTypeShort]: component[fields.componentType],
      // makes sure that initial state of the component has at least the 'Uncategorised' set by default,
      // otherwise maps through the assigned categories
      [fields.componentSelectedCategories]:
        component[fields.categoriesID]?.length > 0
          ? stateCategoriesList.filter(category => component[fields.categoriesID].includes(category[fields.id]))
          : [{ [fields.id]: defaultComponentDMSCategoryID, [fields.name]: t('NO_CATEGORY') }],
    }))
  );

  const [modalData, setModalData] = useState({ isOpen: false });
  const [actionModalData, setActionModalData] = useState({ isOpen: false });
  const [errorModalData, setErrorModalData] = useState({ isOpen: false });

  useEffect(() => {
    getComponentsToLink(fileComponentsFilters);
    //eslint-disable-next-line
  }, []);

  const getComponentsToLink = (filters, loadMore = false) => {
    const handleGetComponentsToLink = (data, newFilters) => {
      const newData = loadMore ? [...components, ...data] : data;

      setComponents(newData);
      setFileComponentsFilters({ ...fileComponentsFilters, ...newFilters });
      if (loadMore) {
        Helpers.scrollIntoView('items-dropdown', `row-${filters[filterProps.lastSeen] - 1}`, 0);
      }
    };
    fetchComponentsToLink({ ...filters, InspectionID: inspectionID }, handleGetComponentsToLink, setComponentsLoading);
  };

  const handleComponentAddedModal = () => {
    setErrorModalData({
      isOpen: true,
      title: t('ERROR_MODAL.TITLE'),
      CustomContent: () => <div className="mb-12">{t('DOCUMENT_MANAGEMENT.COMPONENT_ALREADY_LINKED')}</div>,
      customClassName: 'modal-no-max-height modal-large',
      type: 'ok',
      confirmAction: () => setErrorModalData({ isOpen: false }),
      closeAction: () => setErrorModalData({ isOpen: false }),
    });
  };

  const handleComponentSelect = (_, component, addedComponents = selectedComponents) => {
    // disables adding of same component
    let foundComponentIndex = -1;
    component.forEach(incomingComponent => {
      foundComponentIndex = addedComponents.findIndex(addedComponent => addedComponent[fields.id] === incomingComponent[fields.id]);
    });

    if (foundComponentIndex > -1) {
      handleComponentAddedModal();
      return;
    }
    const compsToAdd = component.map(comp => {
      return {
        ...comp,
        [fields.categoriesID]: [defaultComponentDMSCategoryID],
        [fields.componentSelectedCategories]: [{ [fields.id]: defaultComponentDMSCategoryID, [fields.name]: t('NO_CATEGORY') }],
        [fields.componentCodeShort]: comp[fields.componentCode] || comp[fields.componentCodeShort],
      };
    });
    const newComponents = [...addedComponents, ...compsToAdd];
    setSelectedComponents(newComponents);
    setModalData(prevState => ({
      ...prevState,
      question: { QuestionsComponents: newComponents },
      handleComponentSelected: (_, component) => handleComponentSelect(_, component, newComponents),
      handleDeleteComponent: (_, id) => handleDeleteComponent(id, newComponents),
    }));
  };

  const handleDeleteComponent = (componentIDs, selectedComps = selectedComponents, actionModalCallback = () => null) => {
    if (!componentIDs || componentIDs?.length === 0) return;

    let componentIDsArray = [];
    // handles if array of IDs was provided as prop
    if (isArray(componentIDs)) {
      componentIDsArray = [...componentIDsArray, ...componentIDs];
    } else componentIDsArray = [...componentIDsArray, componentIDs];

    const newSelectedComponents = Object.assign([], selectedComps);
    // handles if multiple componentIDs are provided in the props
    componentIDsArray.forEach(id => {
      const foundIndex = newSelectedComponents.findIndex(c => c[fields.id] === id);
      if (foundIndex > -1) {
        newSelectedComponents.splice(foundIndex, 1);
      }
    });

    setModalData(prevState => ({
      ...prevState,
      question: { QuestionsComponents: newSelectedComponents },
      handleComponentSelected: (_, component) => handleComponentSelect(_, component, newSelectedComponents),
      handleDeleteComponent: (_, id) => handleDeleteComponent(id, newSelectedComponents),
    }));

    setSelectedComponents(newSelectedComponents);
    actionModalCallback && typeof actionModalCallback === 'function' && actionModalCallback();
  };

  const handleFileSearchInput = SearchText => {
    setKeepComponentsDropdownVisible(true);
    setComponentsSearchText(SearchText);
    componentsSearchTextChanged(SearchText);
  };

  const handleComponentSearch = SearchText => dmsHelpers.onSearch(SearchText, fileComponentsFilters, getComponentsToLink);

  const componentsSearchTextChanged = debounce(handleComponentSearch, 300);

  const onComponentSelected = (fileID, components) => {
    if (components && components.length > 0) {
      handleComponentSelect(fileID, components, selectedComponents);
    }
  };

  const closeModalAction = () => {
    setModalData({ isOpen: false });
    setKeepComponentsDropdownVisible(false);
    setComponents([]);
  };

  const handleComponentPicker3D = () => {
    setComponents([]);
    setKeepComponentsDropdownVisible(false);
    setModalData({
      isOpen: true,
      CustomContent: dynamicProps => <ComponentPicker3D {...dynamicProps} inspectionId={inspectionID} closeAction={closeModalAction} />,
      customClassName: 'modal-no-max-height modal-large dms-component-picker-pdf-3d',
      question: { QuestionsComponents: selectedComponents },
      handleComponentSelected: handleComponentSelect,
      handleDeleteComponent: (_, id) => handleDeleteComponent(id),
      type: 'none',
      closeAction: closeModalAction,
    });
  };

  const handleComponentPickerPDF = () => {
    setComponents([]);
    setKeepComponentsDropdownVisible(false);
    setModalData({
      isOpen: true,
      CustomContent: dynamicProps => <ComponentPickerPDF {...dynamicProps} inspectionId={inspectionID} closeAction={closeModalAction} />,
      customClassName: 'modal-no-max-height modal-large dms-component-picker-pdf-3d',
      question: { QuestionsComponents: selectedComponents },
      handleComponentSelected: handleComponentSelect,
      handleDeleteComponent: (_, id) => handleDeleteComponent(id),
      type: 'none',
      closeAction: closeModalAction,
    });
  };

  const handleComponentPickerCH = () => {
    setModalData({
      isOpen: true,
      CustomContent: dynamicProps => <ComponentPickerCH {...dynamicProps} inspectionId={inspectionID} closeAction={closeModalAction} />,
      customClassName: 'modal-no-max-height modal-large',
      type: 'none',
      title: t('QUESTION_COMPONENT_PICKER.CHOOSE_COMPONENTS'),
      closeAction: closeModalAction,
      customCloseAction: closeModalAction,
      question: { QuestionsComponents: selectedComponents },
      handleComponentSelected: handleComponentSelect,
      handleDeleteComponent: (_, id) => handleDeleteComponent(id),
    });
  };

  const handleComponentUnlink = (component = null) => {
    if (!component && (!selectedCheckedComponents || selectedCheckedComponents.length === 0)) return;
    const selectedCheckedComponentsIDs = selectedCheckedComponents.map(c => {
      return c[fields.id];
    });
    let componentName = '';
    // handles if single component clicked X from a list OR if multiple components selected and Unlink action clicked
    if (component) {
      componentName = component[fields.componentName];
    } else if (selectedCheckedComponents?.length === 1) {
      componentName = selectedCheckedComponents[0][fields.componentName];
    } else if (selectedCheckedComponents?.length > 1) componentName = selectedCheckedComponents.length.toString();

    setActionModalData({
      type: '',
      isOpen: true,
      closeAction: () => setActionModalData({ isOpen: false }),
      customCloseAction: () => setActionModalData({ isOpen: false }),
      className: 'modal-no-max-height modal-medium',
      CustomContent: dynamicProps => <ActionModal {...dynamicProps} />,
      customConfirmAction: () =>
        handleDeleteComponent(component ? component[fields.id] : selectedCheckedComponentsIDs, selectedComponents, () => {
          setSelectedCheckedComponents([]);
          setActionModalData({ isOpen: false });
        }),
      closeButtonText: t('CANCEL'),
      confirmButtonText: t('UNLINK_EQUIPMENT'),
      firstParagraph: t('UNLINK_COMPONENT_CATEGORY_CONFIRMATION'),
      firstParagraphProps: { componentName: componentName, componentLabel: !component && selectedCheckedComponents?.length > 1 ? t('COMPONENT') : '' },
      title: t('UNLINK_EQUIPMENT'),
    });
  };

  const onChangeCategoryClick = () => {
    setModalData({
      type: '',
      title: 'Change Category',
      isOpen: true,
      closeAction: () => setModalData({ isOpen: false }),
      customCloseAction: () => setModalData({ isOpen: false }),
      customClassName: 'modal-no-max-height modal-medium change-category-modal',
      CustomContent: dynamicProps => <ChangeCategoryForm {...dynamicProps} />,
      onSubmit: selectedCategories => handleComponentChangeCategory(selectedCategories),
      categoriesList: dmsHelpers.getCategories(projectDMSCategories, t),
      defaultDMSCategoryIDBackup: defaultComponentDMSCategoryID,
    });
  };

  const handleComponentChangeCategory = selectedCategories => {
    const updatedSelectedCheckedComponents = [...selectedCheckedComponents];
    updatedSelectedCheckedComponents.forEach(component => {
      component[fields.categoriesID] = selectedCategories.map(category => category[fields.id]);
      component[fields.componentSelectedCategories] = selectedCategories;
    });
    setSelectedCheckedComponents(updatedSelectedCheckedComponents);
    setModalData({
      isOpen: false,
    });
  };

  const loadMoreComponents = () => {
    setKeepComponentsDropdownVisible(true);
    getComponentsToLink(fileComponentsFilters, true);
  };

  const updateSelectedCheckedComponents = component => {
    const updatedCheckedComponents = [...selectedCheckedComponents];

    const foundSelectedCheckedComponentIndex = updatedCheckedComponents.findIndex(c => c[fields.id] === component[fields.id]);
    if (foundSelectedCheckedComponentIndex > -1) {
      updatedCheckedComponents.splice(foundSelectedCheckedComponentIndex, 1);
      setSelectedCheckedComponents(updatedCheckedComponents);
    } else setSelectedCheckedComponents([...updatedCheckedComponents, component]);
  };

  return (
    <div className="dms-components-picker-modal">
      <div className="dms-components-picker-modal__picker">
        <ComponentPicker
          components={components}
          questionId={fileID}
          handleComponentSelected={onComponentSelected}
          handleComponentPickerPDF={handleComponentPickerPDF}
          handleComponentPicker3D={handleComponentPicker3D}
          handleComponentPickerCH={handleComponentPickerCH}
          selectedComponents={selectedComponents}
          // searchApiRequired because of lazy loading and pagination when loading components for DMS file
          searchApiRequired={true}
          handleAPIComponentSearch={handleFileSearchInput}
          componentsFilterProps={fileComponentsFilters}
          loadMoreOnClick={loadMoreComponents}
          componentsSearchText={componentsSearchText}
          keepComponentsDropdownVisible={keepComponentsDropdownVisible}
          componentsLoading={componentsLoading}
          searchPlaceholder={'SEARCH'}
          searchInputLabel={'QUESTION_COMPONENT_PICKER.ADD_COMPONENT_BUTTON'}
        />
      </div>
      <div className="selected-components-wrapper">
        {selectedComponents && selectedComponents.length > 0 && (
          <div className="selected-components">
            <div className="selected-components__header-wrapper">
              <div className="selected-components__header">
                <p className="f-secondary-dark">{t('ISOLATION_CERTIFICATE.ADD_COMPONENTS_TITLE', { number: selectedComponents.length })}</p>
                <RenderIf if={!isCategorySelectOpen}>
                  <p className="f-secondary-dark multiple-category-select-cta" onClick={() => toggleCategorySelectOpen(!isCategorySelectOpen)}>
                    <Icon name="multiSelect" /> {t('MULTI_SELECT')}
                  </p>
                </RenderIf>
              </div>
              <RenderIf if={isCategorySelectOpen}>
                <MultipleCategorySelect
                  selectedCheckedComponentsLength={selectedCheckedComponents.length}
                  onCancelSelection={() => {
                    toggleCategorySelectOpen(false);
                    setSelectedCheckedComponents([]);
                  }}
                  onUnlinkCategory={() => handleComponentUnlink()}
                  onChangeCategory={() => onChangeCategoryClick()}
                />
              </RenderIf>
            </div>
            <div className="selected-components-container">
              <div className="selected-components-container__header">
                <RenderIf if={isCategorySelectOpen}>
                  <CustomCheckBox
                    meta={{}}
                    input={{
                      value: selectedCheckedComponents.length === selectedComponents.length,
                      onChange: () => (selectedComponents.length === selectedCheckedComponents.length ? setSelectedCheckedComponents([]) : setSelectedCheckedComponents(selectedComponents)),
                    }}
                  />
                </RenderIf>
                <div className="left-side">{t('NAME')}</div>
                <div className="right-side">{t('CATEGORY_SINGULAR')}</div>
              </div>
              {selectedComponents.map((component, componentIndex) => (
                <div className="component-info-wrapper" key={`file-component-${componentIndex}`}>
                  <div className="component-item">
                    <div className="component-name-container">
                      <RenderIf if={isCategorySelectOpen}>
                        <CustomCheckBox
                          meta={{}}
                          input={{
                            value: selectedCheckedComponents.findIndex(c => c[fields.id] === component[fields.id]) > -1,
                            onChange: () => updateSelectedCheckedComponents(component),
                          }}
                        />
                      </RenderIf>
                      <p className="f-primary ">{componentIndex + 1}.</p>
                      <p className="f-primary name" title={component[fields.componentName]}>
                        {component[fields.componentName]}
                      </p>
                      <InfoTooltip
                        actionsMenu={Helpers.mapInfoIconDisplayProps(component, componentDetailsDisplayProps)}
                        offsetY={8}
                        offsetX={8}
                        Component={() => <Icon name="info" size="sm" />}
                        componentProps={{ title: '' }}
                        containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
                      />
                    </div>
                    <div className="component-cat-container">
                      <SearchInput
                        onChange={e => dmsHelpers.filterCategoriesByName(e.target.value, stateCategoriesList, dmsHelpers.getCategories(projectDMSCategories, t), setStateCategoriesList)} // should we debounce by 300ms?
                        onInputFocus={() => setStateCategoriesList(dmsHelpers.getCategories(projectDMSCategories, t))} // resets the search input categories list
                        placeholder={dmsHelpers.getSearchInputValue(component[fields.componentSelectedCategories], t)}
                        wrapperClass={dmsHelpers.getSearchInputValue(component[fields.componentSelectedCategories], t) !== t('SEARCH') ? 'highlight-placeholder' : ''}
                        includeDropdown={true}
                        keepDropdownVisible={false}
                        items={stateCategoriesList}
                        emptyStateLabel={'NO_CATEGORIES_FOUND'}
                        isDropdownDataLoading={false}
                        shouldRenderPortal={true}
                        portalProps={{
                          id: `category-dropdown-items-${componentIndex}`,
                        }}
                        renderItem={(category, categoryIndex) => {
                          return (
                            <div
                              className="category-item-wrapper"
                              onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                dmsHelpers.handleComponentCategorySelect(
                                  componentIndex,
                                  category,
                                  component[fields.componentSelectedCategories] || [],
                                  selectedComponents,
                                  defaultComponentDMSCategoryID,
                                  setSelectedComponents,
                                  t
                                );
                              }}
                              key={`category-${categoryIndex}`}
                            >
                              <CustomCheckBox
                                meta={{}}
                                input={{
                                  value: (component[fields.componentSelectedCategories] || []).findIndex(c => c[fields.id] === category[fields.id]) > -1,
                                  onChange: e => {
                                    e.stopPropagation();
                                    dmsHelpers.handleComponentCategorySelect(
                                      componentIndex,
                                      category,
                                      component[fields.componentSelectedCategories] || [],
                                      selectedComponents,
                                      defaultComponentDMSCategoryID,
                                      setSelectedComponents,
                                      t
                                    );
                                  },
                                }}
                                id={`row-${categoryIndex}`}
                              />
                              <p id={`row-${categoryIndex}`} className="pointer">
                                {category[fields.name]}
                              </p>
                            </div>
                            /**
                             *   Note: for future improvement, Load More can be added here to handle larger number of categories,
                             * which would imply that search would then happen on the API side
                             */
                          );
                        }}
                      />
                      <div key={`component-index-${componentIndex}`} id={`category-dropdown-items-${componentIndex}`} className="search-dropdown-items-wrapper" />
                    </div>
                    <div className="component-delete-container">
                      <Icon
                        name="close"
                        className="delete-action"
                        onClick={() => {
                          handleComponentUnlink(component);
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className={`dms-components-picker-footer ${selectedComponents && selectedComponents.length > 0 ? 'dms-components-picker-footer-with-border' : ''}`}>
        <div className="action-buttons-container">
          <Button variant="success-outline" height="md" width="sm" text={t('Cancel')} onClick={customCloseAction} />
          <Button className="save-btn" height="md" width="sm" variant="success" text={t('SAVE')} onClick={() => customConfirmAction(selectedComponents)} />
        </div>
      </div>
      <Modal
        {...modalData}
        // in order to lift state up and avoid using reducer, the following states and methods are propagated directly to modal component
        searchApiRequired={true}
        handleAPIComponentSearch={handleFileSearchInput}
        components={components}
        componentsFilterProps={fileComponentsFilters}
        componentsSearchText={componentsSearchText}
        keepComponentsDropdownVisible={keepComponentsDropdownVisible}
        loadMoreOnClick={loadMoreComponents}
        componentsLoading={componentsLoading}
      />
      <Modal {...actionModalData} />
      <Modal {...errorModalData} />
    </div>
  );
};

DMSFileComponentPickerModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  fetchComponentsToLink: (filters, callback, loadingCallback) => dispatch(fetchComponentsToLink(filters, callback, loadingCallback)),
});

const mapStateToProps = state => ({
  // TODO: see if projectDMSCategories will make problem since we store in this reducer both Observations, Notifications and Components DMS categories
  projectDMSCategories: state.projectDetailsReducer.projectDMSCategories,
  defaultComponentDMSCategoryID: state.projectDetailsReducer.DefaultComponentDMSCategoryID,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DMSFileComponentPickerModal));
