import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import SimpleLoader from '../../global-loader/components/simple-loader';
import PDFToolbox from './pdf-toolbox';
import PdfViewer from './pdf-viewer';

import { SketchField, Tools } from '@tarik.djurdjevic/react-sketch';

import { defaultStroke } from '../../../app/inspections/constants/default-drawing';

import { highlightedOpacityLevel, PDFErrors } from '../constants/constants';

import DrawingHelpers from '../../drawing-helpers';

import { useCallback } from 'react';
import { componentFilterValues, filterValues } from '../../../app/pdf-tag/constants/constants';
import '../style/pdf-with-drawing.scss';

const PDFWithDrawing = (
  {
    selectedTool = Tools.Pan,
    lineColor = defaultStroke,
    disableZoomAndMove = false,
    onClick,
    sketchProps = {},
    includeLoader,
    pdfViewerProps,
    drawingsLoading,
    isEditingDrawing,
    toggleEditMode,
    editEnabled,
    transformProps = {},
    changePage,
    pageNumber,
    alignPDFLeft,
    filter,
    separatePage,
    splitScreenEnabled,
    splitScreenLayoutDirection,
    ...rest
  },
  { t }
) => {
  const MAX_NUMBER_PIXEL_DENSITY = 3502322.6628592007;
  const zoomToElementRef = useRef(null);
  const setTransformRef = useRef(null);

  const sketchRef = useRef(null);
  const [pdfLoaded, setPDFLoaded] = useState(false);
  const [drawings, setDrawings] = useState([]);
  const [pdfWidth, setPDFWidth] = useState('100%');
  const [pdfHeight, setPDFHeight] = useState('100%');
  const [hoveredDrawing, setHoveredDrawing] = useState({});
  // pdf-viewer related state management
  const pdfAndDrawingsLoaded = pdfLoaded && !drawingsLoading;
  const [numPages, setNumPages] = useState(0);
  const [calculatedScale, setCalculatedScale] = useState(1); // Default scale is 1
  const [renderNavButtons, setRenderNavButtons] = useState(false);
  const [renderViewerProps, setRenderViewerProps] = useState({
    pageProps: {
      onRenderError: error => {
        if (error.message === PDFErrors.shading || error.message === PDFErrors.subArray) {
          setRenderViewerProps({ ...viewerProps, renderMode: 'canvas' });
        }
        console.error(error.message);
      },
    },
    ...pdfViewerProps,
  });

  const viewerProps = {
    pageProps: {
      onRenderError: error => {
        console.error(error);
      },
    },
    ...pdfViewerProps,
  };

  // since we have no way of catching if the SVG is included in the PDF, the only way is through the console.log produced by pdf.js
  // Function to monitor console logs for the specific message
  const monitorConsoleLogs = () => {
    const consoleLog = console.log;
    console.log = function (message) {
      // console.log('message', message);
      if (typeof message === 'string' && message === PDFErrors.svgDeprecated) {
        // this might change in the future, so we can maybe use .includes('SVG')
        // SVG deprecated Log message detected, set renderMode to 'canvas'
        setRenderViewerProps({ ...viewerProps, renderMode: 'canvas' });
      }
      // Call the original console.log function
      consoleLog.apply(console, arguments);
    };
  };

  // Call the monitoring function to start checking console logs
  monitorConsoleLogs();

  useEffect(() => {
    const { severityColors, drawings: restDrawings } = rest;

    if (!isEmpty(restDrawings)) {
      setDrawings(
        DrawingHelpers.extractImageDrawings(
          restDrawings,
          severityColors,
          pageNumber,
          filter ? filter[filterValues.componentFilter] : componentFilterValues.all,
          pdfWidth * calculatedScale,
          pdfHeight * calculatedScale,
          calculatedScale
        )
      );
    } else setDrawings([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest.drawings, drawingsLoading, pdfWidth, pdfHeight, calculatedScale]);

  const setInitialZoomPosition = useCallback(() => {
    if (zoomToElementRef.current && setTransformRef.current && sketchRef.current && sketchRef.current._container) {
      const sketchRefCurrentId = sketchRef.current._container.id;
      zoomToElementRef.current(sketchRefCurrentId);
      // splitScreenLayoutDirection === 0 indicates that the split screen is active, and its split direction is horizontal
      if (alignPDFLeft || splitScreenLayoutDirection === 0) {
        setTransformRef.current(0, 0, 1 / calculatedScale);
      }
    }
    // eslint-disable-next-line
  }, [setTransformRef, zoomToElementRef, alignPDFLeft, calculatedScale]);

  useEffect(() => {
    if (pdfAndDrawingsLoaded && zoomToElementRef.current && setTransformRef.current && sketchRef.current) {
      setInitialZoomPosition();
    }
  }, [pdfAndDrawingsLoaded, sketchRef, setInitialZoomPosition, separatePage, splitScreenEnabled, splitScreenLayoutDirection]);

  const { ref, ...sketchPropsFiltered } = sketchProps;
  const sketchFieldProps = {
    className: 'canvas-drawer',
    ref: c => {
      if (!c) return;
      ref(c);
      if ((!sketchRef.current || !sketchRef.current?._container) && ref) {
        sketchRef.current = c;
        sketchRef.current?._container?.setAttribute('id', c._canvas.id);
      }
    },
    height: pdfHeight * calculatedScale,
    width: pdfWidth * calculatedScale,
    tool: selectedTool,
    value: drawings || null,
    lineColor: lineColor,
    fillColor: 'transparent',
    lineWidth: 3,
    forceValue: true,
    pageNumber: pageNumber,
    onMouseDown: e => {
      if (!e.target || !e.target.ID) return;

      if (onClick) {
        onClick(e, sketchRef.current, e.target);
      }
    },
    onObjectOver: e => {
      if (!e.target || !e.target.uuid) return;
      setHoveredDrawing(Object.assign({}, e.target));

      e.target.opacity = highlightedOpacityLevel.selected;
      sketchRef.current._fc.renderAll();
    },
    onMouseOut: e => {
      if (!e.target || !e.target.uuid) return;

      e.target.opacity = hoveredDrawing.opacity;
      sketchRef.current._fc.renderAll();
      setHoveredDrawing(null);
    },
    ...sketchPropsFiltered,
  };

  // pdf-viewer related logic
  const onDocumentLoadSuccess = async data => {
    const { numPages } = data;

    const pageObj = await data.getPage(1);
    let pageWidth = pageObj.view[2];
    let pageHeight = pageObj.view[3];
    const isOdd = num => {
      return num % 2;
    };
    if (isOdd(parseInt(pageObj.rotate / 90))) {
      let pdfHeight = pageHeight,
        pdfWidth = pageWidth;
      // if pdf is rotated upside down reverse the width and height
      pageWidth = pdfHeight;
      pageHeight = pdfWidth;
    }

    setPDFWidth(pageWidth);
    setPDFHeight(pageHeight);
    setPDFLoaded(true);
    setCalculatedScale(Math.min(Math.max(MAX_NUMBER_PIXEL_DENSITY / (pageWidth * pageHeight), 1), 3));
    setNumPages(numPages);
    setRenderNavButtons(true);
  };

  const onDocumentLoadError = error => {
    // TODO: add error state
    console.error(error);
  };

  if (!pdfLoaded) {
    return (
      <>
        <PdfViewer
          {...renderViewerProps}
          onDocumentLoadSuccess={onDocumentLoadSuccess}
          onDocumentLoadError={onDocumentLoadError}
          pageNumber={pageNumber}
          numPages={numPages}
          renderNavButtons={renderNavButtons}
          changePage={changePage}
          calculatedScale={calculatedScale}
        />
        {pdfAndDrawingsLoaded && <SketchField {...sketchFieldProps} />}
      </>
    );
  } else {
    return (
      <TransformWrapper
        disabled={!pdfLoaded || disableZoomAndMove}
        minScale={0.1}
        limitToBounds={false}
        panning={{
          disabled: selectedTool !== Tools.Pan,
          lockAxisX: false,
          lockAxisY: false,
          velocityDisabled: true,
        }}
        pinch={{ disabled: true }}
        initialScale={calculatedScale}
        centerOnInit
        {...transformProps}
      >
        {({ zoomIn, zoomOut, zoomToElement, setTransform }) => {
          zoomToElementRef.current = zoomToElement;
          setTransformRef.current = setTransform;

          return (
            <div className="pdf-with-drawing">
              <div className="pdf-with-drawing__controls">
                <PDFToolbox
                  resetTransform={() => setInitialZoomPosition()}
                  zoomIn={() => zoomIn()}
                  zoomOut={() => zoomOut()}
                  isEditingDrawing={isEditingDrawing}
                  toggleEditMode={toggleEditMode}
                  editEnabled={editEnabled}
                  activeTool={selectedTool}
                />
              </div>
              <TransformComponent wrapperStyle={{ width: '100%', height: '100%' }}>
                <div
                  className="pdf-with-drawing__wrapper"
                  style={{ width: typeof pdfWidth === 'number' ? pdfWidth * calculatedScale : 300, height: typeof pdfHeight === 'number' ? pdfHeight * calculatedScale : 300 }}
                >
                  {includeLoader && (!pdfLoaded || drawingsLoading) && (
                    <SimpleLoader isLoading={true} className="pdf-with-drawing__loader" customText={drawingsLoading ? t('LOADING_TAGS') : t('LOADING_PDF')} />
                  )}
                  <>
                    <PdfViewer
                      {...renderViewerProps}
                      onDocumentLoadSuccess={onDocumentLoadSuccess}
                      onDocumentLoadError={onDocumentLoadError}
                      pageNumber={pageNumber}
                      numPages={numPages}
                      renderNavButtons={renderNavButtons}
                      changePage={changePage}
                      calculatedScale={calculatedScale}
                    />
                    {pdfAndDrawingsLoaded && <SketchField key={JSON.stringify(drawings)} {...sketchFieldProps} />}
                  </>
                </div>
              </TransformComponent>
            </div>
          );
        }}
      </TransformWrapper>
    );
  }
};

PDFWithDrawing.propTypes = {
  selectedTool: PropTypes.string,
  lineColor: PropTypes.string,
  disableZoomAndMove: PropTypes.bool,
  onClick: PropTypes.func,
  sketchProps: PropTypes.object,
  includeLoader: PropTypes.bool,
  pdfViewerProps: PropTypes.object,
  drawingsLoading: PropTypes.bool,
  isEditingDrawing: PropTypes.bool,
  toggleEditMode: PropTypes.func,
  editEnabled: PropTypes.bool,
  transformProps: PropTypes.object,
  changePage: PropTypes.func,
};

PDFWithDrawing.defaultProps = {
  isFullScreen: false,
  selectedTool: Tools.Pan,
  disableZoomAndMove: false,
  lineColor: defaultStroke,
  sketchProps: {},
  alignPDFLeft: false,
};

PDFWithDrawing.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default PDFWithDrawing;
