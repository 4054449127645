import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { debounce, find, isEmpty, isEqual } from 'lodash';

import { amendInspectionDetails, setActiveLeftSidebar, setImages360Ref, setInspectionDetails, setInspectionID, setSearchText } from '../actions/action-creators';
import { getInspectionDetails, getInspectionsByProjectId, getPanoramicImages, getShareLinks, selectDetailsPage, setCurrentProjectId } from '../actions/inspection-actions';

import { notificationModalCustom } from '../../../common/modal/actions/modal-actions';
import { setSingleUploadItem, setUploadItems, uploadAsset } from '../../upload/actions/upload-actions';

import DownloadReportModalMeasurements from '../../../common/download-report-modal-measurments/components/download-report-modal';
import DownloadReportModal from '../../../common/download-report-modal/components/download-report-modal';
import DownloadReportSingleDefectModal from '../../../common/download-single-defect-report-modal/components/download-single-defect-report-modal';

import AddQuickPointModal from './ndt/add-quick-point-modal';

import Helpers from '../../../common/helpers';
import routesConstants from '../../../common/routes-constants';

import { detailsPages, modules, viewQueryPropTypes } from '../constants/constants';

import { getPanoramicImageTiles } from '../../../api/inspection/actions';
import { FEATURES } from '../../../common/constants';
import { fetchProjectDetails } from '../../project/actions/project-actions';
import { InitialInspectionSettings } from '../constants/inspection';
import { userSettings } from '../constants/inspection-settings';

class InspectionWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: props.modules || modules,
      modalData: {
        isOpen: false,
        closeAction: this.closeModal.bind(this),
        prevCameraPosition: null,
        prevRadius: null,
      },
      prevQuery: null,
    };
    this.viewChangedDebounce = debounce(this.handleOnViewChange, 600);
  }

  handleOnViewChange = e => {
    const {
      viewer,
      inspectionDetails,
      getPanoramicImages,
      user: { Setting360View },
      images360Ref,
      setImages360Ref,
    } = this.props;
    const {
      query: { inspection_id },
    } = this.props.location;
    const { Potree } = window;
    const { prevCameraPosition, prevRadius } = this.state;

    if (!viewer) {
      return;
    }

    const defaultCameraPosition =
      inspectionDetails && inspectionDetails.CameraPosition && !isEmpty(inspectionDetails.CameraPosition.coordinates) ? inspectionDetails.CameraPosition.coordinates : undefined;
    const cameraPosition = Helpers.getCameraPosition(viewer, defaultCameraPosition);
    const radius = Setting360View[userSettings.fields.radiusNeighbouringFootprints];
    if (isEqual(prevCameraPosition, cameraPosition) && prevRadius === radius) {
      // No need to update the images, camera position has not been changed
      return;
    }
    const payload = {
      X: cameraPosition.x,
      Y: cameraPosition.y,
      Z: cameraPosition.z,
      Radius: radius,
    };

    getPanoramicImages(inspection_id, payload, panoramicImages => {
      const ImageInfos = panoramicImages && !isEmpty(panoramicImages.Images) ? panoramicImages.Images : [];
      const ClosestImage = panoramicImages && !isEmpty(panoramicImages.ClosestImage) ? panoramicImages.ClosestImage : null;
      this.setState({ prevCameraPosition: cameraPosition, prevRadius: radius });
      if (!images360Ref) {
        Potree.NavvisImages360Loader.load(
          { ImageInfos },
          viewer,
          {
            transform: null,
          },
          getPanoramicImageTiles,
          !FEATURES.tiling360Image.visible
        ).then(images360 => {
          viewer.scene.add360Images(images360);
          images360.showFootprints = Setting360View[userSettings.fields.showNeighbouringPositions];
          setImages360Ref(images360);
          images360.updateImages(ImageInfos, ClosestImage);
        });
        return;
      } else {
        images360Ref.showFootprints = Setting360View[userSettings.fields.showNeighbouringPositions];
        images360Ref.updateImages(ImageInfos, ClosestImage);
      }
    });
  };

  componentDidMount() {
    const { setActiveProject, getInspectionsByProjectId, getInspectionDetails, fetchProjectDetails, setInspectionID, viewer } = this.props;
    const { modules } = this.state;
    const {
      query: { project_id, inspection_id, type, selected_item, details },
    } = this.props.location;

    if (project_id && inspection_id) {
      const projectID = parseInt(project_id);
      setActiveProject(projectID);
      fetchProjectDetails(projectID);
      setInspectionID(parseInt(inspection_id));
      getInspectionsByProjectId(projectID);
      getInspectionDetails(inspection_id);
      //getShareLinks(inspection_id);
      this.handleOnViewChange();

      const actualType = find(modules, item => item === type);
      this.handleActivePageChanged(actualType || modules.details, actualType ? selected_item : null, details);
    }
    if (viewer) {
      Helpers.addViewerControlsListener(viewer, this.viewChangedDebounce);
    }
  }

  componentDidUpdate = prevProps => {
    const { viewer, user } = this.props;
    const { query } = this.props.location;
    const { prevQuery } = this.state;

    if (viewer && prevProps.viewer !== viewer) {
      if (prevProps.viewer) {
        Helpers.removeViewerControlsListener(prevProps.viewer, this.viewChangedDebounce);
      }
      Helpers.addViewerControlsListener(viewer, this.viewChangedDebounce);

      this.viewChangedDebounce();
    }
    if (prevProps.user !== user || (prevProps.user && user && prevProps.user.Setting360View !== user.Setting360View)) {
      this.viewChangedDebounce();
    }
    if (!isEqual(prevQuery, query)) {
      // Handle incoming query params
      this.setState({ prevQuery: query });
      const { type, selected_item, details } = query;
      this.handleActivePageChanged(type, selected_item, details);
    }
  };

  componentWillUnmount() {
    const { viewer, setImages360Ref, amendInspectionDetails } = this.props;
    if (viewer) {
      Helpers.removeViewerControlsListener(viewer, this.viewChangedDebounce);
    }
    amendInspectionDetails(InitialInspectionSettings);
    setImages360Ref(null);
  }

  handleActivePageChanged = (type, selected_item, details = null) => {
    const { setActivePage, selectDetailsPage, activeItem, setSearchText, viewer } = this.props;
    const { modules } = this.state;
    // Clear search text if module is being changed
    if (type !== activeItem) {
      setSearchText('');
    }
    //verify query params
    if (selected_item && !isNaN(selected_item) && type !== modules.details) {
      if (!details) {
        // fallback if details param is not passed
        selectDetailsPage(type || null);
      } else {
        selectDetailsPage(details);
      }
    } else {
      if (details && [detailsPages.inspect, detailsPages.settings].indexOf(details) > -1) {
        // Handle specific details pages where there is no need for selected_item to open the details
        selectDetailsPage(details);
      } else {
        // Added cancel_all_insertions instead of handling handleObjectToolClick remove eventListeners
        if (viewer) {
          viewer.dispatchEvent({
            type: 'cancel_all_insertions',
          });
        }
        selectDetailsPage(null);
      }
      if (viewer) {
        viewer.scene.unselectMeasurement();
      }
    }
    setActivePage(type);
  };

  handleActivePage = (type, selected_item, details = null, view = null) => {
    const { modules } = this.state;
    const {
      query: { inspection_id, project_id },
      pathname,
    } = this.props.location;
    const params = [{ project_id }, { inspection_id }, { type }];

    //verify query params
    if (selected_item && !isNaN(selected_item) && type !== modules.details) {
      params.push({ selected_item });
      if (details) {
        params.push({ details });
      }
    }
    if (view && type === modules.readingsAndGauges && viewQueryPropTypes[modules.readingsAndGauges][view]) {
      params.push({ view });
    }

    // Will trigger component did update
    Helpers.goTo(pathname, params);
  };

  openDownloadReportModal = defects => {
    const { t } = this.context;
    const {
      query: { inspection_id, project_id },
    } = this.props.location;
    this.setState(prevState => ({
      modalData: {
        ...prevState.modalData,
        customClassName: 'download-report-modal modal-medium',
        title: t('INSPECTION_DETAILS.DOWNLOAD_REPORT'),
        CustomContent: () => <DownloadReportModal projectId={parseInt(project_id)} inspectionId={parseInt(inspection_id)} closeModal={this.closeModal} defects={defects} />,
        isOpen: true,
        type: 'none',
        closeAction: this.closeModal,
      },
    }));
  };

  openDownloadReportModalSingleDefect = defect => {
    const {
      query: { inspection_id, project_id },
    } = this.props.location;

    const { t } = this.context;
    this.setState(prevState => ({
      modalData: {
        ...prevState.modalData,
        customClassName: 'download-defect-report-modal modal-medium',
        title: t('INSPECTION_DETAILS.DOWNLOAD_REPORT'),
        CustomContent: () => <DownloadReportSingleDefectModal projectId={parseInt(project_id)} inspectionId={parseInt(inspection_id)} defect={defect} closeModal={this.closeModal} />,
        isOpen: true,
        type: 'none',
        closeAction: this.closeModal,
      },
    }));
  };

  closeModal = () => {
    this.setState(prevState => ({ modalData: { ...prevState.modalData, isOpen: false } }));
  };

  handleInspectionClick = inspection_id => {
    const { getInspectionDetails } = this.props;
    const {
      query: { project_id },
    } = this.props.location;

    Helpers.goTo(routesConstants.routes.protectedRoutes.inspections.fullPath, [{ project_id }, { inspection_id }]);

    getInspectionDetails(inspection_id);

    // if (!Helpers.isGuestUser(user)) {
    // getShareLinks(inspection_id);
    // }
  };

  openDownloadReportModalForMeasurments = () => {
    const { t } = this.context;
    const { query } = this.props.location;

    const inspectionId = parseInt(query.inspection_id);

    this.setState(prevState => ({
      modalData: {
        ...prevState.modalData,
        customClassName: 'download-report-modal-measurements modal-medium',
        title: t('INSPECTION_DETAILS.DOWNLOAD_REPORT'),
        CustomContent: () => <DownloadReportModalMeasurements inspectionId={inspectionId} closeModal={this.closeModal} />,
        isOpen: true,
        type: 'none',
        closeAction: this.closeModal,
      },
    }));
  };

  openAddQuickNDTPointModal = (title, otherProps = {}) => {
    const { query } = this.props.location;

    const inspectionId = parseInt(query.inspection_id);

    this.setState(prevState => ({
      modalData: {
        ...prevState.modalData,
        customClassName: 'add-quick-point-modal modal-medium',
        title,
        CustomContent: () => <AddQuickPointModal inspectionId={inspectionId} closeModal={this.closeModal} {...otherProps} />,
        isOpen: true,
        type: 'none',
        closeAction: () => this.setState({ modalData: { isOpen: false } }),
      },
    }));
  };

  switchTo3DMode = () => {
    const {
      query: { inspection_id, project_id, type, selected_item, details },
    } = this.props.location;
    const { modules } = this.state;

    const params = [{ project_id }, { inspection_id }, { type }];
    //verify query params
    if (selected_item && !isNaN(selected_item) && type !== modules.details) {
      params.push({ selected_item });
      if (details) {
        params.push({ details });
      }
    }
    Helpers.goTo(routesConstants.routes.protectedRoutes.inspections.fullPath, params);
  };

  switchToFullScreenMode = () => {
    const {
      query: { inspection_id, project_id, type, selected_item, details },
    } = this.props.location;
    const { modules } = this.state;

    const params = [{ project_id }, { inspection_id }, { type }];
    //verify query params
    if (selected_item && !isNaN(selected_item) && type !== modules.details) {
      params.push({ selected_item });
      if (details) {
        params.push({ details });
      }
    }
    Helpers.goTo(routesConstants.routes.protectedRoutes.fullScreen.fullPath, params);
  };

  render() {
    const { children } = this.props;
    const { modalData } = this.state;
    const {
      handleActivePage,
      openDownloadReportModal,
      openDownloadReportModalSingleDefect,
      handleInspectionClick,
      openDownloadReportModalForMeasurments,
      openAddQuickNDTPointModal,
      switchTo3DMode,
      switchToFullScreenMode,
    } = this;

    return (
      children &&
      children({
        handleActivePage,
        openDownloadReportModal,
        openDownloadReportModalSingleDefect,
        handleInspectionClick,
        openDownloadReportModalForMeasurments,
        openAddQuickNDTPointModal,
        switchTo3DMode,
        switchToFullScreenMode,
        setModalState: modalData => {
          this.setState({ modalData });
        },
        modalData,
      })
    );
  }
}

const mapStateToProps = state => ({
  user: state.userReducer,
  activeItem: state.inspectionReducer.activeLeftSidebar,
  images360Ref: state.inspectionReducer.images360Ref,
});

const mapDispatchToProps = dispatch => ({
  selectDetailsPage: page => dispatch(selectDetailsPage(page)),
  setActiveProject: projectId => dispatch(setCurrentProjectId(projectId)),
  setInspectionID: inspectionID => dispatch(setInspectionID(inspectionID)),
  getInspectionsByProjectId: projectId => dispatch(getInspectionsByProjectId(projectId)),
  getInspectionDetails: inspectionId => dispatch(getInspectionDetails(inspectionId)),
  setInspectionDetails: data => dispatch(setInspectionDetails(data)),
  amendInspectionDetails: data => dispatch(amendInspectionDetails(data)),
  getShareLinks: inspectionId => dispatch(getShareLinks(inspectionId)),
  setUploadItems: assets => dispatch(setUploadItems(assets)),
  uploadAsset: (file, IDs, callbackFunction, index, customDoneFunction, cbDocumentFinished) => dispatch(uploadAsset(file, IDs, callbackFunction, index, customDoneFunction, cbDocumentFinished)),
  setSingleUploadItem: (progress, index) => dispatch(setSingleUploadItem(progress, index)),
  notificationModalCustom: (isOpen, errorMessage, title) => dispatch(notificationModalCustom(isOpen, errorMessage, title)),
  setActivePage: value => dispatch(setActiveLeftSidebar(value)),
  setSearchText: value => dispatch(setSearchText(value)),
  getPanoramicImages: (inspectionId, additionalData, callback) => dispatch(getPanoramicImages(inspectionId, additionalData, callback)),
  setImages360Ref: data => dispatch(setImages360Ref(data)),
  fetchProjectDetails: projectID => dispatch(fetchProjectDetails(projectID)),
});

InspectionWrapper.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(InspectionWrapper);
