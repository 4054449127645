import PropTypes from 'prop-types';
import React from 'react';

import Icon from '../../icon/components/icon';
import RenderIf from '../../render-if/components/render-if';
import InfoTooltip from '../../tooltip/components/info-tooltip';

import { colorPalette } from '../constants/constants';

import '../styles/color-palette.scss';

const ColorPalette = ({ input, handleChange, disabled, colorPalette, label, className, mapValue, size, id, labelClass, labelTooltip, labelTooltipText, labelTooltipIcon, isRequired }, { t }) => {
  const handleClick = val => {
    input.onChange(val);

    if (handleChange) {
      handleChange(val);
    }
  };
  const renderColors = () =>
    Object.keys(colorPalette).map((key, index) => {
      const color = colorPalette.hasOwnProperty(key) ? colorPalette[key] : null;
      const mainProp = mapValue ? color : key;
      const isObject = typeof color === 'object';

      if (color)
        return (
          <div
            key={index}
            onClick={() => (!disabled ? handleClick(mainProp) : null)}
            style={{
              backgroundColor: isObject ? color.value : color,
            }}
            className={`colors-wrapper__color${disabled ? ' colors-wrapper__color--disabled' : ''} ${mainProp === input.value ? 'selected' : ''} ${size}`}
          >
            <RenderIf if={isObject && color.icon}>
              <Icon name={color.icon} {...color.iconProps} />
            </RenderIf>
          </div>
        );
      else return '';
    });
  return (
    <div className={`colors-container ${className}`}>
      {label && (
        <label className={`colors-container__label ${labelClass}`} htmlFor={id}>
          {`${t(label)}${isRequired ? '*' : ''}`}
          <RenderIf if={labelTooltip}>
            <InfoTooltip
              customComponent={<p>{labelTooltipText}</p>}
              offsetY={10}
              offsetX={-20}
              Component={() => <Icon name={labelTooltipIcon} handleHover={false} size="xs" />}
              containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
            />
          </RenderIf>
        </label>
      )}
      <div className="colors-wrapper">{renderColors()}</div>
    </div>
  );
};

ColorPalette.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
};
ColorPalette.defaultProps = {
  colorPalette: colorPalette,
  size: 'xl',
};

ColorPalette.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ColorPalette;
