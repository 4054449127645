import { Component } from 'react';
import NavigationButton from '../../../common/navigation-button/components/navigation-button';
import { MODULES } from '../constants';
import AccessRenderer from '../../../common/access-renderer/components/access-renderer';
import { isEmpty } from 'lodash';
import routesConstants from '../../../common/routes-constants';
import Helpers from '../../../common/helpers';
import { params } from '../../profile/constants/profile-constants';
import { connect } from 'react-redux';
import PageNavigation from '../../../common/page-navigation/components/page-navigation';
import PropTypes from 'prop-types';

import '../styles/project-settings.scss';

class ProjectSettings extends Component {
  render() {
    const { user, location } = this.props;
    const { query, search } = location;
    const { t } = this.context;

    const backButtonPath = `${user[params.fullScreenEnabled] ? routesConstants.routes.protectedRoutes.fullScreen.fullPath : routesConstants.routes.protectedRoutes.inspections.fullPath}${search}`;

    const action = route => {
      const goToRoute = !isEmpty(route) ? routesConstants.routes.protectedRoutes[route].fullPath : null;
      goToRoute && Helpers.goTo(goToRoute, [{ project_id: query.project_id }, { inspection_id: query.inspection_id }]);
    };

    return (
      <div className="project-settings">
        <PageNavigation backButtonPath={backButtonPath} title={t('PROJECT_SETTINGS.TITLE')} icon="settings" withBottomBorder />
        <div className="project-settings__container">
          {MODULES.map((item, i) => {
            const { visibleFor, hidden } = item;
            const { visible, newFeature, comingSoon } = item.feature;
            item.action = action;
            return (
              <AccessRenderer visibleFor={visibleFor} key={i}>
                {({ hasAccess }) => {
                  return hasAccess && visible && !hidden ? <NavigationButton newFeature={newFeature} comingSoon={comingSoon} {...item} /> : null;
                }}
              </AccessRenderer>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userReducer,
});

ProjectSettings.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, null)(ProjectSettings);
