import React, { Component } from 'react';
import Dropzone from '../../../common/upload/components/upload-dropzone';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { isEmpty } from 'lodash';

import UploadBar from '../../upload/components/generic-upload-bar';

import '../styles/upload-modal.scss';

class UploadAssetsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderUploadFiles = uploadFiles => {
    return uploadFiles.map((file, index) => {
      return <UploadBar key={index} percent={file.progress || 0} fileName={file.real_name} />;
    });
  };

  render() {
    const { uploadFiles, label, labelWhite, labelGreen } = this.props;
    return (
      <div className="upload-modal noselect">
        {isEmpty(uploadFiles) && <Dropzone {...this.props} label={label} labelWhite={labelWhite} labelGreen={labelGreen} multiple={true} />}
        {!isEmpty(uploadFiles) && <div>{this.renderUploadFiles(uploadFiles)}</div>}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  uploadFiles: state.uploadReducer.uploadTmpFiles,
});

UploadAssetsModal.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, null)(UploadAssetsModal);
