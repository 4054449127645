import { isEmpty } from 'lodash';
import Helpers from '../../../../helpers';
import { tableFields } from '../constants/constants';
import { colorPalette } from '../../../../form/constants/constants';
import Icon from '../../../../icon/components/icon';

const getValueWithSearchTerm = (value, searchTerm) => {
  return isEmpty(value) ? '-' : Helpers.getSearchTermText(value, searchTerm);
};
const getComponentNameCell = (item, value, searchTerm) => {
  return (
    <div className="assign-components__name">
      {item.Colour ? <div className="assign-components__name__status-icon" style={{ backgroundColor: colorPalette[item.Colour] }} /> : null}
      <div className="f-primary assign-components__name__text">{Helpers.getSearchTermText(value, searchTerm)}</div>
    </div>
  );
};
export const formatLevelCells = (value, type, searchTerm) => {
  if (type === tableFields.icon) {
    return <Icon name="folder" size="sm" />;
  } else if (type === tableFields.code || type === tableFields.name) {
    return getValueWithSearchTerm(value, searchTerm);
  } else {
    return value ? value : '-';
  }
};

export const formatComponentCells = (value, type, searchTerm, row, onClick, t) => {
  if (type === tableFields.icon) {
    return <Icon name="components" active size="sm" />;
  } else if (type === tableFields.code || type === tableFields.type) {
    return getValueWithSearchTerm(value, searchTerm);
  } else if (type === tableFields.name) {
    return value ? getComponentNameCell(row, value, searchTerm) : '-';
  } else if (type === tableFields.critical) {
    return value ? t('COMPONENT_DETAILS.CRITICAL_YES') : t('COMPONENT_DETAILS.CRITICAL_NO');
  } else if (type === tableFields.actionMenu) {
    // improvement: if row.ID is in provided selectedItems then disable the adding/linking of component
    return (
      <button className="action-menu-column__button" onClick={e => onClick(true, e, row)}>
        <Icon name="plus-circle" active handleHover={false} />
      </button>
    );
  } else {
    return value ? value : '-';
  }
};
