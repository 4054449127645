/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { change, Field, FieldArray, getFormSyncErrors, getFormValues, reduxForm } from 'redux-form';

import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { FORMS } from '../../../../../../common/constants';
import Button from '../../../../../../common/form/components/button';
import CustomDatePicker from '../../../../../../common/form/components/date-picker';
import UneditableInfo from '../../../../../../common/form/components/uneditable-info';
import { formConstants } from '../../constants/measurement-point-constants';

import Loader from '../../../../../../common/global-loader/components/simple-loader';
import Helpers from '../../../../../../common/helpers';
import RenderIf from '../../../../../../common/render-if/components/render-if';
import useConfirmOnInspectionExit from '../../../../../../hooks/use-confirm-on-inspection-exit';
import { getDefectDetails } from '../../../../actions/inspection-actions';
import { measurementTypes } from '../../../../constants/constants';
import { measurementReadingFormConstants } from '../../constants/measurement-readings-constants';
import { formConstants as timeSeriesGraphConstants } from '../../constants/time-series-graph-modal-constants';
import '../../styles/measurement-reading-form.scss';
import { measurementReadingValidate } from '../../validators/measurement-reading-validator';
import ReadingFormFields from './reading-form-fields';

const MeasurementReadingForm = (props, { t }) => {
  const { handleSubmit, formErrors, isEdit, dirty, customCloseAction, getDefectDetails, measurementPointId, initialValues, formValues, changeField, requestInProgress = false, router } = props;
  const [data, setData] = useState([]);
  const [dateValue, setDateValue] = useState(!isEdit ? Helpers.getCurrentDateAndTimeInMs() : Helpers.getDateFromUnix(initialValues[timeSeriesGraphConstants.fields.measurementDate]));
  const [isLoading, setIsLoading] = useState(false);

  const fetchMeasurementPointDetails = useCallback(() => {
    setIsLoading(true);
    getDefectDetails(
      { ID: measurementPointId, SystemType: measurementTypes.rgMeasurementPoint },
      measurementPointDetails => {
        setData(measurementPointDetails);
        setIsLoading(false);
      },
      false
    );
  }, [measurementPointId, measurementTypes.rgMeasurementPoint]);

  useEffect(() => {
    handleDateChange(dateValue);
    fetchMeasurementPointDetails();
  }, []);

  const handleDateChange = date => {
    const formattedDate = !isEdit ? date : Helpers.getCurrentDateAndTimeInMs(date);
    setDateValue(formattedDate);
    changeField(dateField, formattedDate, false);
  };

  const isSubmitButtonDisabled = useMemo(() => {
    // TODO: maybe even disable the button if the form isPristine as well
    if (!isEdit) {
      return formValues?.Values?.some(value => isEmpty(value) || !value) || !isEmpty(formErrors);
    }

    return !isEmpty(formErrors);
  }, [formErrors, formValues]);
  const dateField = isEdit ? timeSeriesGraphConstants.fields.measurementDate : measurementReadingFormConstants.fields.date;

  useConfirmOnInspectionExit({
    title: t('GENERIC_UNSAVED_CHANGES_TITLE'),
    message: t('GENERIC_UNSAVED_CHANGES_MESSAGE'),
    router,
    route: router.location,
    isDirty: dirty,
    clearUnsavedChangesDirty: () => null,
  });
  return (
    <div>
      <form className="create-measurement-reading-form" onSubmit={handleSubmit} noValidate>
        <RenderIf if={isLoading}>
          <Loader isLoading={isLoading} />
        </RenderIf>
        <RenderIf if={!isLoading}>
          <div className="create-measurement-reading-form__content">
            <div className="create-measurement-reading-form__double-input">
              <Field
                id={formConstants.fields.description}
                name={formConstants.fields.description}
                component={UneditableInfo}
                placeholder={'READINGS_AND_GAUGES.MEASUREMENT_READINGS_FORM.DESCRIPTION'}
                label={'READINGS_AND_GAUGES.MEASUREMENT_READINGS_FORM.DESCRIPTION'}
                labelClass="defect-form__label"
                enableAutoResize={true}
                input={{ value: data ? data[formConstants.fields.description] : '' }}
              />
              <Field
                id={formConstants.fields.expectedRange}
                name={formConstants.fields.expectedRange}
                component={UneditableInfo}
                placeholder={'READINGS_AND_GAUGES.MEASUREMENT_READINGS_FORM.EXPECTED_RANGE'}
                label={'READINGS_AND_GAUGES.MEASUREMENT_READINGS_FORM.EXPECTED_RANGE'}
                labelClass="defect-form__label"
                enableAutoResize={true}
                value={data ? data[formConstants.fields.expectedRange] : ''}
                input={{ value: data ? data[formConstants.fields.expectedRange] : '' }}
              />
            </div>
            <Field
              disabledKeyboardNavigation={true}
              id={dateField}
              name={dateField}
              component={CustomDatePicker}
              popperPlacement="bottom-end"
              placeholder={'READINGS_AND_GAUGES.MEASUREMENT_READINGS_FORM.DATE_TIME'}
              label={'READINGS_AND_GAUGES.MEASUREMENT_READINGS_FORM.DATE_TIME'}
              size="lg"
              dateFormat="MMMM d, yyyy h:mm aa"
              timeFormat="h:mm aa"
              showTimeSelect
              validateUsingReduxForm
              isEnd
              isRequired
              input={{
                value: dateValue,
                onChange: handleDateChange,
              }}
            />
            <FieldArray name={measurementReadingFormConstants.fields.values} component={ReadingFormFields} unit={data ? data[formConstants.fields.unit] : ''} isEdit={isEdit} />
          </div>
          <div className="buttons" id="action-buttons">
            <Button
              onClick={e => {
                e.preventDefault();
                customCloseAction();
              }}
              variant="gray-outline"
              text={t('CANCEL')}
              height="md"
              width="sm"
              isCapitalized
            />
            <Button onClick={handleSubmit} text={t('SAVE')} height="md" width="sm" isCapitalized disabled={isSubmitButtonDisabled || requestInProgress} />
          </div>
        </RenderIf>
      </form>
    </div>
  );
};

MeasurementReadingForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  formValues: getFormValues(FORMS.measurementPointMeasurementReadingForm)(state),
  formErrors: getFormSyncErrors(FORMS.measurementPointMeasurementReadingForm)(state),
});

const mapDispatchToProps = dispatch => ({
  getDefectDetails: (defect, callback, saveItem) => dispatch(getDefectDetails(defect, callback, {}, false, saveItem)),
  changeField: (fieldName, value, touch) => dispatch(change(FORMS.measurementPointMeasurementReadingForm, fieldName, value, touch)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({ form: FORMS.measurementPointMeasurementReadingForm, validate: measurementReadingValidate, touchOnChange: true, destroyOnUnmount: true, enableReinitialize: true })(MeasurementReadingForm)
);
