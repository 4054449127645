import React, { Component } from 'react';

class InfiniteScroll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingInProgress: false,
      innerScrollTotal: 0,
      functionTriggered: true,
    };
    this.node = React.createRef();
  }

  componentDidMount() {
    if (this.node) {
      this.node.addEventListener('scroll', this.handleScroll);
    }
  }

  handleScroll = () => {
    if (this.node) {
      const { innerScrollTotal, functionTriggered } = this.state;
      const { loadMoreFunction, offsetTrigger } = this.props;
      let { scrollHeight, clientHeight, scrollTop } = this.node;

      const scrollTotal = scrollHeight - clientHeight;

      if (scrollTotal !== innerScrollTotal) {
        if (scrollTotal > innerScrollTotal) {
          this.setState({ functionTriggered: false });
        }

        this.setState({ innerScrollTotal: scrollTotal });
      }

      if (scrollTotal - offsetTrigger < scrollTop && !functionTriggered) {
        if (loadMoreFunction) {
          this.setState({ functionTriggered: true });
          loadMoreFunction();
        }
      }
    }
  };

  render() {
    const { children } = this.props;

    return children
      ? React.cloneElement(children, {
          ref: ref => (this.node = ref),
        })
      : null;
  }
}
InfiniteScroll.defaultProps = {
  loadMoreFunction: () => null,
  offsetTrigger: 100,
};

export default InfiniteScroll;
