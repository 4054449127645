import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { get, isEmpty, isString, debounce } from 'lodash';
import '../styles/autocomplete.scss';
import '../styles/basic-input.scss';
import Icon from '../../../../../common/icon/components/icon';
import RenderIf from '../../../../../common/render-if/components/render-if';

class SearchSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.input.value || '',
      shouldRenderSuggestion: false,
    };
    this.fetchRequestedDebounce = debounce(this.handleSuggestionsFetchRequested, 500);
    this.node = React.createRef();
  }
  componentDidMount() {
    const { value } = this.state;
    const { shouldRenderSuggestionsOnInitialFocus } = this.props;
    document.addEventListener('mousedown', this.handleClickOutside, false);

    if (shouldRenderSuggestionsOnInitialFocus) {
      this.getSuggestions(value);
    }
  }
  componentWillUnmount() {
    this.fetchRequestedDebounce.cancel();
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }

  handleClickOutside = e => {
    if (this.node && this.node.current && this.node.current.contains(e.target)) {
      return;
    }
    const { value } = this.state;
    const { defaultSuggestion, selectedSuggestion } = this.props;
    if (!isEmpty(selectedSuggestion)) {
      if (value !== selectedSuggestion.label) {
        this.setState({ value: selectedSuggestion.label });
      }
    } else {
      this.onSuggestionSelected(null, { suggestion: defaultSuggestion });
    }
  };

  componentDidUpdate(prevProps) {
    const { value } = this.state;
    const { selectedSuggestion } = this.props;
    const currentSuggestionLabel = get(selectedSuggestion, 'label'),
      prevSuggestionLabel = get(prevProps.selectedSuggestion, 'label'),
      currentSuggestionValue = get(selectedSuggestion, 'value'),
      prevSuggestionValue = get(prevProps.selectedSuggestion, 'value');

    if (currentSuggestionLabel && currentSuggestionLabel !== value && (currentSuggestionLabel !== prevSuggestionLabel || currentSuggestionValue !== prevSuggestionValue)) {
      this.setState({ value: currentSuggestionLabel });
    }
  }

  handleChange = e => {
    this.setState({ value: e.target.value, shouldRenderSuggestion: true });
  };

  renderInput = inputProps => {
    const { ref, placeholder, hideBorder, meta, isEnhancedDesign, hasIcon, iconName, iconRotation, ...other } = inputProps;
    return (
      <div className={`inspection-input__input-wrapper${hideBorder ? '' : ' border'} ${isEnhancedDesign ? 'enhanced-design-input search-select' : ''}` + (meta.error ? ' invalid ' : '')}>
        <input {...other} ref={ref} type={'text'} placeholder={placeholder || ''} className={`input f-primary  ${isEnhancedDesign ? 'enhanced-design-input' : ''} ${hasIcon ? 'has-icon' : ''}`} />
        <RenderIf if={hasIcon}>
          <Icon name={iconName} className="input-icon" rotation={iconRotation} size="md" />
        </RenderIf>
      </div>
    );
  };

  renderSuggestion = (suggestion, { query }) => {
    const matches = match(suggestion.label, query);
    const parts = parse(suggestion.label, matches);
    return (
      <div>
        <div>
          {parts.map((part, index) => {
            return part.highlight ? (
              <span className="f-secondary-green" key={String(index)}>
                {part.text}
              </span>
            ) : (
              <span className="f-primary" key={String(index)}>
                {part.text}
              </span>
            );
          })}
        </div>
      </div>
    );
  };

  renderSuggestionsContainer = ({ containerProps, children }) => {
    const { isEnhancedDesign } = this.props;
    const { className, ...restProps } = containerProps;
    return (
      <div className={`${className} ${isEnhancedDesign ? 'enhanced-design-custom-prop' : ''}`} {...restProps}>
        {children}
      </div>
    );
  };

  getSuggestionValue = suggestion => {
    return suggestion.value;
  };

  getSuggestions = value => {
    const { fetchSuggestions } = this.props;
    if (isString(value)) {
      fetchSuggestions(value.trim().toLowerCase());
    }
  };
  handleSuggestionsFetchRequested = ({ value, reason }) => {
    this.getSuggestions(value);
  };

  handleSuggestionsClearRequested = () => {
    const { clearSuggestions } = this.props;
    clearSuggestions();
  };

  onSuggestionSelected = (e, { suggestion, suggestionValue }) => {
    if (!suggestion) {
      return;
    }
    const { handleChange } = this.props;
    if (handleChange) {
      handleChange(suggestion, e);
    }
    this.setState({ value: suggestion.label });
    this.setState({ shouldRenderSuggestion: false });
  };

  render() {
    const { value, shouldRenderSuggestion } = this.state;
    const {
      input: { onChange: changeInput, ...restInput },
      inputProps: { onChange: changeInputProps, ...restInputProps },
      placeholder,
      meta,
      // id,
      labelActions,
      label,
      labelClass,
      isHidden,
      suggestions,
      hideBorder,
      disabled,
      className,
      // isEnhancedDesign - should be temporary flag until all of the components in app use the same design
      isEnhancedDesign,
      hasIcon,
      iconName,
      iconRotation,
    } = this.props;
    const { t } = this.context;

    return (
      <div className={`inspection-input ${className || ''} ${isHidden ? 'hidden-field' : ''} ${isEnhancedDesign ? 'enhanced-design-custom-prop' : ''}`} ref={this.node}>
        {label && (
          <div className={`inline-wrapper ${isEnhancedDesign ? 'enhanced-design-input' : ''}`}>
            <label className={`${labelClass || 'f-secondary-dark'} noselect`}>{t(label)}</label>
            {!isEmpty(labelActions) &&
              labelActions.map((el, index) => {
                return <el.IconComponent key={index} onClick={el.onClick} className={`${el.iconClass || ''} label-container__icon ${el.type || ''} label-container__icon--active svg-gray-g`} />;
              })}
          </div>
        )}
        <Autosuggest
          renderInputComponent={this.renderInput}
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.fetchRequestedDebounce}
          onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          renderSuggestionsContainer={this.renderSuggestionsContainer}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          shouldRenderSuggestions={(_value, renderReason) => {
            if (renderReason === 'input-focused') {
              this.setState({ shouldRenderSuggestion: true });
              return true;
            }
            return shouldRenderSuggestion;
          }}
          inputProps={{
            placeholder,
            meta,
            hideBorder,
            disabled,
            ...restInput,
            ...restInputProps,
            value,
            onChange: this.handleChange,
            onFocus: () => {
              this.setState({ shouldRenderSuggestion: true });
            },
            isEnhancedDesign,
            hasIcon,
            iconName,
            iconRotation,
          }}
          pop
          highlightFirstSuggestion
        />
        <div className={`error-message${meta && meta.error ? '--visible' : ''}`}>{meta && meta.error && t(meta.error.string, meta.error.params)}</div>
      </div>
    );
  }
}
SearchSelect.contextTypes = {
  t: PropTypes.func.isRequired,
};

SearchSelect.defaultProps = {
  inputProps: {},
  placeholder: '',
  defaultSuggestion: null,
  isEnhancedDesign: false,
  shouldRenderSuggestionsOnInitialFocus: false,
};

SearchSelect.propTypes = {
  suggestions: PropTypes.array.isRequired,
  fetchSuggestions: PropTypes.func.isRequired,
  clearSuggestions: PropTypes.func.isRequired,
  isEnhancedDesign: PropTypes.bool,
  shouldRenderSuggestionsOnInitialFocus: PropTypes.bool,
};

export default SearchSelect;
