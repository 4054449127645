import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { isEmpty } from 'lodash';

import ImageGallery from 'react-image-gallery';
import ImageWithDrawing from '../../../../common/image-components/components/image-with-drawing';
import Button from '../../../../common/form/components/button';
import SimpleLoader from '../../../../common/global-loader/components/simple-loader';
import AccessRenderer from '../../../../common/access-renderer/components/access-renderer';

import { Tools } from '@tarik.djurdjevic/react-sketch';
import { defaultStroke } from '../../../inspections/constants/default-drawing';
import DrawingHelpers from '../../../../common/drawing-helpers';

import workflowConstants from '../../../inspection-workflow/constants/inspection-workflow-constants';
import { AMAZON_IMAGE_SIZES } from '../../../../common/constants';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../common/permissions-constants';

import '../../styles/image-slider.scss';
import { connect } from 'react-redux';

const objectPropsToInclude = ['strokeUniform', 'noScaleCache', 'angle', 'transparentCorners', 'hasRotatingPoint'];

class ImageSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFullScreen: false,
      enableDrawing: false,
      fillColor: defaultStroke,
      selectedTool: Tools.DefaultTool,
      customDrawings: null,
      loadingDrawings: false,
    };
    this.sketchRef = null;
  }

  setSketchRef = ref => (this.sketchRef = ref);

  onDrawingAdded = () => {
    const { sketchRef } = this;

    this.setState({ selectedTool: Tools.Select });
    if (sketchRef && sketchRef._container) {
      const savedSketch = Object.assign({}, sketchRef.toJSON(objectPropsToInclude));

      let { offsetWidth, clientHeight } = sketchRef._container;
      savedSketch.canvasWidth = offsetWidth;
      savedSketch.canvasHeight = clientHeight;
      if (DrawingHelpers.isValid(savedSketch)) {
        this.setState({ customDrawings: savedSketch });
      }
    }
  };

  handleEnableDrawing = () => {
    const { enableDrawing } = this.state;

    if (!enableDrawing) {
      this.setState({ selectedTool: Tools.Rectangle });
      this.setState({ customDrawings: {} });

      this.setState({ loadingDrawings: true });

      setTimeout(() => {
        this.setState({ loadingDrawings: false });
      }, 500);
    } else {
      this.resetState();
    }
    this.setState({ enableDrawing: !enableDrawing });
  };

  resetState = () => {
    this.setState({ selectedTool: Tools.DefaultTool });
    this.setState({ loadingDrawings: true });
    this.setState({ customDrawings: null });
    this.setState({ enableDrawing: false });

    setTimeout(() => {
      this.setState({ loadingDrawings: false });
    }, 500);
  };

  isItemActive = item => {
    const { currentSlideIndex, images } = this.props;

    return !isEmpty(images) && !isEmpty(item) && images[currentSlideIndex][workflowConstants.formConstants.fields.fileID] === item[workflowConstants.formConstants.fields.fileID];
  };

  executeWithSketchRef = (func, retries = 0) => {
    const { sketchRef } = this;
    if (retries > 3) {
      console.warn('Unable to find sketch reference');
      return;
    }
    setTimeout(() => {
      if (sketchRef && sketchRef._container) {
        func(sketchRef);
      } else {
        this.executeWithSketchRef(func, retries + 1);
      }
    }, 500 * retries);
  };

  renderItem = item => {
    const { t } = this.context;
    const { handleQuickDefect, imageType, activeCameraHelper } = this.props;
    const { isFullScreen, enableDrawing, fillColor, selectedTool, customDrawings, loadingDrawings } = this.state;
    const { onDrawingAdded, setSketchRef } = this;

    if (!this.isItemActive(item)) {
      return null;
    }

    if (!isFullScreen) {
      return <ImageWithDrawing item={item} drawings={item.Drawings} isFullScreen={isFullScreen} imageType={imageType || AMAZON_IMAGE_SIZES.medium.name} />;
    }

    if (loadingDrawings) {
      return <SimpleLoader isLoading={true} className={'drawings-loader'} />;
    }

    const sketchProps = enableDrawing
      ? {
          onMouseUp: () => {
            if (selectedTool === Tools.Rectangle) {
              onDrawingAdded();
            }
          },
          onObjectMoving: () => console.log('Object is moving '),
          onObjectModified: () => onDrawingAdded(),
          onObjectAdded: () => null,
          ref: setSketchRef,
        }
      : {
          ref: setSketchRef,
        };

    return (
      <Fragment>
        <Fragment>
          {handleQuickDefect && !enableDrawing && (
            <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.observations].create.name}>
              {({ hasAccess }) => {
                return <Button disabled={!hasAccess} type="button" width="sm" text={t('POINT_IMAGES.ADD_DEFECT')} onClick={() => this.handleEnableDrawing()} />;
              }}
            </AccessRenderer>
          )}
          {handleQuickDefect && enableDrawing && <Button type="button" variant="gray-outline" width="sm" text={t('POINT_IMAGES.CANCEL')} onClick={() => this.handleEnableDrawing()} />}
          {handleQuickDefect && enableDrawing && (
            <Fragment>
              <Button
                disabled={isEmpty(customDrawings)}
                type="button"
                width="sm"
                text={t('POINT_IMAGES.SAVE_DEFECT')}
                onClick={() => {
                  this.executeWithSketchRef(sketchRef => {
                    const imagePosition = DrawingHelpers.getImagePointCalculation({}, item, sketchRef, customDrawings);

                    handleQuickDefect(customDrawings, item, imagePosition, activeCameraHelper, 1);
                  });
                }}
              />
              {isEmpty(customDrawings) && (
                <div className="defect-message">
                  <p>{t('POINT_IMAGE.DRAW_YOUR_DEFECT')}</p>
                </div>
              )}
            </Fragment>
          )}
        </Fragment>

        <ImageWithDrawing
          {...{
            item,
            sketchProps,
            selectedTool,
            isFullScreen,
            lineColor: fillColor,
            imageType: AMAZON_IMAGE_SIZES.large.name,
            drawings: customDrawings ? null : item.Drawings,
          }}
        />
      </Fragment>
    );
  };

  updateFullScreen = isFullScreen => {
    this.setState({ isFullScreen: isFullScreen || false });
    if (isEmpty(isFullScreen)) {
      this.setState({ customDrawings: null });
      this.setState({ enableDrawing: false });
    }
  };

  render() {
    const { t } = this.context;
    const { images, currentSlideIndex, onSlide, style, setImageSliderRef } = this.props;
    const { resetState } = this;
    if (isEmpty(images)) return <p className="f-primary">{t('SELECTED_POINT.EMPTY_PLACEHOLDER')}</p>;

    return (
      <Fragment>
        <section className="image-slider slider" style={style || {}}>
          <ImageGallery
            ref={setImageSliderRef}
            items={images}
            renderItem={this.renderItem}
            lazyLoad={true}
            infinite={true}
            showBullets={false}
            showFullscreenButton={true}
            showPlayButton={false}
            showThumbnails={false}
            showIndex={false}
            showNav={true}
            isRTL={false}
            onScreenChange={this.updateFullScreen}
            slideDuration={450}
            slideInterval={2000}
            startIndex={currentSlideIndex > 0 ? currentSlideIndex : 0}
            onSlide={currentIndex => {
              resetState();
              onSlide(currentIndex);
            }}
            additionalClass="image-slider__image-gallery"
          />
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCameraHelper: state.startWorkflowReducer.activeCameraHelper,
});

ImageSlider.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, null)(ImageSlider);
