import loginConstants from '../constants/login-constants';
import { PASSWORD_REGEX, EMAIL_REGEX } from '../../../common/constants';

export const validate = values => {
  const errors = {};

  if (!EMAIL_REGEX.test(values[loginConstants.fields.username])) {
    errors[loginConstants.fields.username] = 'LOGIN.USERNAME_NOT_VALID';
  }

  if (!PASSWORD_REGEX.test(values[loginConstants.fields.password])) {
    errors[loginConstants.fields.password] = 'LOGIN.PASSWORD_NOT_VALID';
  }

  if (!values[loginConstants.fields.username]) {
    errors[loginConstants.fields.username] = 'LOGIN.USERNAME_REQUIRED';
  }

  if (!values[loginConstants.fields.password]) {
    errors[loginConstants.fields.password] = 'LOGIN.PASSWORD_REQUIRED';
  }

  return errors;
};
