import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Helpers from '../../helpers';

import SimpleLoader from '../../global-loader/components/simple-loader';
import { imageLoadMaxRetryCount } from '../constants/constants';

import '../styles/basic-image.scss';

class BasicImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageLoaded: false,
      retryCount: 1,
      imageId: `res-image-${Helpers.uuid4()}`,
    };
  }

  handleImageLoaded = e => {
    const { imageLoaded } = this.state;

    if (imageLoaded) return;

    this.setState({ imageLoaded: true });
    this.setState({ retryCount: 1 });
  };

  handleImageError = e => {
    const { imageId, retryCount } = this.state;
    this.setState({ retryCount: retryCount + 1 });

    if (retryCount < imageLoadMaxRetryCount) {
      setTimeout(() => {
        const image = document.getElementById(imageId);
        if (image) {
          image.src = image.src + ' ';
        }
      }, 1000 * retryCount);
    }
  };

  render() {
    const { imageLoaded, imageId } = this.state;
    const { src, className, customImageComponent, hideImageLoader, ...restProps } = this.props;

    return (
      <>
        {!imageLoaded && !hideImageLoader && <SimpleLoader isLoading={true} className="basic-image-loader" />}
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img
          {...{
            src,
            onLoad: e => {
              this.handleImageLoaded(e, src);
            },
            onError: this.handleImageError,
            id: imageId,
            className: `basic-image ${imageLoaded && !customImageComponent ? 'loaded' : ''} ${className}`,
            ...restProps,
          }}
        />

        {imageLoaded && customImageComponent && customImageComponent()}
      </>
    );
  }
}

BasicImage.defaultProps = {
  alt: 'image',
  className: '',
  hideImageLoader: false,
};

BasicImage.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default BasicImage;
