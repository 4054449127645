import { filter } from 'lodash';
import actionTypes from '../constants/action-types';

import { chartFilters, measurementConstants } from '../constants/ndt-constants';

const ndtState = {
  measurementLocationsLoading: false,
  measurementLocations: [],
  measurementLocationsClustered: [],
  measurementPoints: [],
  measurementPropertySuggestions: [],
  measurementProperties: [],
  measurementPointPropertySuggestions: [],
  measurementPointProperties: [],
  selectedMeasurementPoint: null,
  delMeasurementModalData: {
    isOpen: false,
  },
  ndtAlarms: [],
  descriptorData: [],
  measurementPointModalData: {
    isOpen: false,
  },
  ndtChartFilters: {
    [chartFilters.type]: null,
    [chartFilters.group]: null,
    [chartFilters.dateFrom]: null,
    [chartFilters.dateTo]: null,
  },
  chartTypes: [],
  chartPeriods: [],
  chartGroups: [],
  ndtMainChartData: [],
  ndtSmallChartData: [],
  ndtSmallChartDataLoading: false,
  ndtChartPeriodGroupData: [],
  ndtEmails: {},
  ndtMeasurementsComponents: [],
  ndtMeasurementsUnits: [],
  ndtAlarmsTriggered: [],
  predictiveTypes: [],
  predictiveSettings: null,
  loadingPredictiveResults: false,
  ndtPredictiveResults: [],
  filterGroupSuggestions: [],
  measurementNotifications: [],
  measurementDetailsLoading: false,
};

export const ndtReducer = (state = ndtState, payload) => {
  switch (payload.type) {
    case actionTypes.SET_NDT_MEASUREMENT_LOCATIONS:
      return {
        ...state,
        measurementLocations: payload.data,
      };
    case actionTypes.AMEND_NDT_MEASUREMENT_LOCATIONS:
      return {
        ...state,
        measurementLocations: [...state.measurementLocations, ...payload.data],
      };
    case actionTypes.SET_NDT_MEASUREMENT_LOCATIONS_LOADING:
      return {
        ...state,
        measurementLocationsLoading: payload.data,
      };
    case actionTypes.SET_NDT_MEASUREMENT_POINTS:
      return {
        ...state,
        measurementPoints: payload.data,
      };
    case actionTypes.SET_ACTIVE_NDT_MEASUREMENT_POINT:
      return {
        ...state,
        selectedMeasurementPoint: payload.data,
      };
    case actionTypes.SET_NDT_MEASUREMENT_LABEL_SUGGESTIONS:
      return { ...state, measurementPropertySuggestions: payload.data };
    case actionTypes.CLEAR_NDT_MEASUREMENT_LABEL_SUGGESTIONS:
      return { ...state, measurementPropertySuggestions: [] };
    case actionTypes.SET_NDT_MEASUREMENT_POINT_LABEL_SUGGESTIONS:
      return { ...state, measurementPointPropertySuggestions: payload.data };
    case actionTypes.CLEAR_NDT_MEASUREMENT_POINT_LABEL_SUGGESTIONS:
      return { ...state, measurementPointPropertySuggestions: [] };
    case actionTypes.FETCH_NDT_MEASUREMENT_PROPERTIES_SUCCESS:
      return { ...state, measurementProperties: payload.data };
    case actionTypes.APPEND_NDT_MEASUREMENT_PROPERTY:
      return { ...state, measurementProperties: [...state.measurementProperties, payload.data] };
    case actionTypes.UPDATE_NDT_MEASUREMENT_PROPERTY: {
      const propIndex = state.measurementProperties.findIndex(el => el.ID === payload.data.ID);
      if (propIndex > -1) {
        let newProperties = [...state.measurementProperties];
        newProperties[propIndex] = payload.data;
        return { ...state, measurementProperties: newProperties };
      }
      return { ...state };
    }
    case actionTypes.REMOVE_NDT_MEASUREMENT_PROPERTY:
      return { ...state, measurementProperties: state.measurementProperties.filter(el => el.ID !== payload.data.ID) };
    case actionTypes.FETCH_NDT_MEASUREMENT_POINT_PROPERTIES_SUCCESS:
      return { ...state, measurementPointProperties: payload.data };
    case actionTypes.HANDLE_DELETE_NDT_MEASUREMENT_MODAL:
      return { ...state, delMeasurementModalData: payload.data };
    case actionTypes.HANDLE_NDT_MEASUREMENT_POINT_MODAL:
      return { ...state, measurementPointModalData: payload.data };
    case actionTypes.TOGGLE_NDT_MEASUREMENT:
      return {
        ...state,
        measurementLocations: state.measurementLocations.map(measurementLocation => {
          return { ...measurementLocation, visible: measurementLocation.ID === payload.data ? !measurementLocation.visible : measurementLocation.visible };
        }),
      };
    case actionTypes.SHOW_ALL_NDT_MEASUREMENT:
      return {
        ...state,
        measurementLocations: state.measurementLocations.map(measurementLocation => {
          if (payload.data) {
            if (measurementLocation[measurementConstants.fields.componentName] === payload.data) {
              return {
                ...measurementLocation,
                visible: true,
              };
            } else {
              return measurementLocation;
            }
          } else {
            return {
              ...measurementLocation,
              visible: true,
            };
          }
        }),
      };
    case actionTypes.HIDE_ALL_NDT_MEASUREMENT:
      return {
        ...state,
        measurementLocations: state.measurementLocations.map(measurementLocation => {
          if (payload.data) {
            if (measurementLocation[measurementConstants.fields.componentName] === payload.data) {
              return {
                ...measurementLocation,
                visible: false,
              };
            } else {
              return measurementLocation;
            }
          } else {
            return {
              ...measurementLocation,
              visible: false,
            };
          }
        }),
      };
    case actionTypes.SET_NDT_ALARMS:
      return { ...state, ndtAlarms: payload.data };
    case actionTypes.SET_DESCRIPTOR_DATA:
      return { ...state, descriptorData: payload.data };
    case actionTypes.SET_NDT_CHART_FILTER:
      return { ...state, ndtChartFilters: { ...state.ndtChartFilters, [payload.data.key]: payload.data.value } };
    case actionTypes.SET_NDT_CHART_TYPES_DATA:
      return { ...state, chartTypes: payload.data };
    case actionTypes.SET_NDT_CHART_PERIODS_DATA:
      return { ...state, chartPeriods: payload.data };
    case actionTypes.SET_NDT_PREDICTIVE_TYPES_DATA:
      return { ...state, predictiveTypes: payload.data };
    case actionTypes.SET_NDT_PREDICTIVE_SETTINGS_DATA:
      return { ...state, predictiveSettings: payload.data };
    case actionTypes.SET_NDT_CHART_GROUPS_DATA:
      return { ...state, chartGroups: payload.data };
    case actionTypes.SET_NDT_MAIN_CHART_DATA:
      return { ...state, ndtMainChartData: payload.data };
    case actionTypes.SET_NDT_SMALL_CHART_DATA:
      return { ...state, ndtSmallChartData: payload.data };
    case actionTypes.SET_NDT_SMALL_CHART_DATA_LOADING:
      return { ...state, ndtSmallChartDataLoading: payload.data };
    case actionTypes.SET_NDT_CHART_PPERIOD_GROUPS_DATA:
      return { ...state, ndtChartPeriodGroupData: payload.data };
    case actionTypes.SET_NDT_EMAILS:
      return { ...state, ndtEmails: payload.data };
    case actionTypes.ADD_ALARM_EMAIL_INTO_ARRAY:
      return {
        ...state,
        ndtEmails: {
          ...state.ndtEmails,
          [payload.data.type]: [...state.ndtEmails[payload.data.type], payload.data.item],
        },
      };
    case actionTypes.REMOVE_ALARM_EMAIL_FROM_ARRAY:
      const resArr = filter(state.ndtEmails[payload.data.type], item => item.ID !== payload.data.id);
      return {
        ...state,
        ndtEmails: {
          ...state.ndtEmails,
          [payload.data.type]: resArr,
        },
      };
    case actionTypes.SET_NDT_MEASUREMENTS_COMPONENTS:
      return { ...state, ndtMeasurementsComponents: payload.data };
    case actionTypes.SET_NDT_MEASUREMENTS_UNITS:
      return { ...state, ndtMeasurementsUnits: payload.data };
    case actionTypes.SET_NDT_ALARMS_TRIGGERED:
      return { ...state, ndtAlarmsTriggered: payload.data };
    case actionTypes.FETCH_PREDICTIVE_RESULTS_START:
      return { ...state, loadingPredictiveResults: true, ndtPredictiveResults: payload.data };
    case actionTypes.FETCH_PREDICTIVE_RESULTS_SUCCESS:
      return { ...state, loadingPredictiveResults: false, ndtPredictiveResults: payload.data };
    case actionTypes.FETCH_PREDICTIVE_RESULTS_FAILURE:
      return { ...state, loadingPredictiveResults: false, ndtPredictiveResults: [] };
    case actionTypes.SET_FILTER_GROUP_SUGGESTIONS:
      return { ...state, filterGroupSuggestions: payload.data };
    case actionTypes.CLEAR_FILTER_GROUP_SUGGESTIONS:
      return { ...state, filterGroupSuggestions: [] };
    case actionTypes.HANDLE_SET_NDT_MEASUREMENT_NOTIFICATIONS:
      return { ...state, measurementNotifications: payload.data };
    case actionTypes.SET_INSPECTION_NDT_MEASUREMENTS_CLUSTERED:
      return {
        ...state,
        measurementLocationsClustered: payload.data.map(measurement => ({ ...measurement, visible: true })),
      };
    case actionTypes.SET_NDT_MEASUREMENT_DETAILS_LOADING:
      return {
        ...state,
        measurementDetailsLoading: payload.data,
      };
    default:
      return state;
  }
};
