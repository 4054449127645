import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { debounce, filter, isEmpty, isEqual, isString } from 'lodash';

import ComponentList from './component-list';
import CustomSelect from '../../form/components/select';
import DrawingHelpers from '../../drawing-helpers';
import ChooseComponents from './choose-components';
import ComponentPDF from '../../../app/inspections/components/right-toolbar/component-pdf';

import { getPdfList, initPdfTagScreen, selectDrawing } from '../../../app/pdf-tag/actions/pdf-tag-actions';
import { setSelectedFile, setPdfList, setPdfComponents } from '../../../app/pdf-tag/actions/action-creators';
import { componentsPickerSelectKeys } from '../constants/question-constants';

import '../styles/component-picker-pdf.scss';
import { measurementTypes } from '../../../app/inspections/constants/constants';
import { selectDefect } from '../../../app/inspections/actions/inspection-actions';
import { componentFields, defaultFilter } from '../../../app/pdf-tag/constants/constants';

class ComponentPickerPDF extends Component {
  constructor(props) {
    super(props);
    this.handleComponentSearch = debounce(this.handleComponentSearch, 300);

    this.state = {
      filteredComponents: [],
      selectedComponent: null,
    };
  }

  componentDidMount() {
    const { inspectionId, getPdfList, initPdfTagScreen, selectedComponent, setPdfComponents } = this.props;
    getPdfList(inspectionId, null, (_pdfList, selectedFile) => {
      if (selectedFile) {
        initPdfTagScreen(
          inspectionId,
          selectedFile.FileID,
          defaultFilter,
          1,
          selectedComponent ? selectedComponent[componentFields.componentId] : null,
          () => null,
          false,
          newDrawings => setPdfComponents(newDrawings)
        );
      }
    });
  }

  // only handles for DMS related search API triggering
  componentDidUpdate() {
    const { components, searchApiRequired } = this.props;
    const { filteredComponents } = this.state;

    // For small arrays like these (max 20 items per array), the performance impact of using _.isEqual()
    // is likely negligible, and the convenience it provides for deep comparison outweighs
    // any minor performance considerations.
    if (searchApiRequired && !isEqual(filteredComponents, components)) {
      this.setState({
        filteredComponents: components,
      });
    }
  }

  componentWillUnmount() {
    const { setSelectedFile, setPdfList, selectDefect } = this.props;
    // Restart the state
    setSelectedFile(null);
    setPdfList([]);
    selectDefect({ defect: {} });
  }

  onComponentChange = component => {
    const { selectDefect } = this.props;

    let drawing = {};

    //Remove logic after drawings are updated on be
    if (!isEmpty(component.Drawing) && isString(component.Drawing)) {
      try {
        drawing = JSON.parse(component.Drawing.split("'").join('"'));
      } catch (err) {
        drawing = {};
      }
    } else {
      drawing = component.Drawing;
    }

    selectDefect({ defect: {} });
    setTimeout(() => {
      selectDefect({ defect: { ...component, Drawing: drawing, SystemType: measurementTypes.component, Geometry: {} } });
    }, 500);

    this.setState({ selectedComponent: component });
  };

  addSelectedComponent = () => {
    const { selectedComponent } = this.state;
    const { handleComponentSelected, question } = this.props;

    if (selectedComponent) {
      selectedComponent.ComponentName = selectedComponent[componentsPickerSelectKeys.textKey];
      selectedComponent.ComponentID = selectedComponent[componentsPickerSelectKeys.valueKey];
      const componentIds = [selectedComponent];
      if (typeof handleComponentSelected === 'function') {
        handleComponentSelected(question.ID, componentIds);
      }
    }
  };

  // TODO: this is redundant code as it appears both on component-picker as well, move it to parent
  handleComponentSearch = value => {
    const { components, searchApiRequired, handleAPIComponentSearch } = this.props;
    const lowerCaseValue = value.toLowerCase();

    if (searchApiRequired) {
      handleAPIComponentSearch(lowerCaseValue);
    } else {
      const filtered = value
        ? filter(components, item => {
            return item[componentsPickerSelectKeys.textKey].toLowerCase().indexOf(lowerCaseValue) > -1;
          })
        : components;

      this.setState({ filteredComponents: filtered });
    }
  };

  // TODO: redundant code, requires refactoring (appears on component-picker)
  handleInputFocus = () => {
    const { filteredComponents } = this.state;

    if (!filteredComponents || !filteredComponents.length) {
      const { searchApiRequired } = this.props;
      if (searchApiRequired) {
        const { handleAPIComponentSearch } = this.props;
        handleAPIComponentSearch('');
      } else {
        const { components } = this.props;
        this.setState({ filteredComponents: components });
      }
    }
  };

  selectComponentFromDrawing = clickedObj => {
    const { pdfComponents } = this.props;
    const comp = DrawingHelpers.findComponentByDrawingGuid(pdfComponents, clickedObj);
    if (isEmpty(comp)) return;

    comp && this.onComponentChange(comp);
  };

  render() {
    const { t } = this.context;
    const {
      question,
      inspectionId,
      handleDeleteComponent,
      pdfList,
      selectedFile,
      pdfFileLoading,
      componentsLoading,
      initPdfTagScreen,
      selectDrawing,
      selectedDrawing,
      searchApiRequired,
      componentsFilterProps,
      loadMoreOnClick,
      keepComponentsDropdownVisible,
      pdfComponentsLoading,
    } = this.props;
    const { filteredComponents, selectedComponent } = this.state;
    const selectedComponents = question?.QuestionsComponents || [];

    return (
      <div className="component-picker-pdf">
        {selectedFile && selectedFile.URL && !pdfFileLoading && (
          <ComponentPDF
            file={selectedFile}
            componentsLoading={pdfComponentsLoading}
            selectComponent={this.selectComponentFromDrawing}
            selectDrawing={selectDrawing}
            selectedDrawing={selectedDrawing}
            rightCollapsed={true}
          />
        )}
        <div className="top-toolbar">
          <CustomSelect
            className="pdf-select"
            label="PDF_TAG.TOP.DROPDOWN_TITLE"
            labelClass="f-secondary-light"
            data={pdfList || []}
            defaultValue={pdfList && pdfList[0] && selectedFile ? selectedFile : null}
            type="text"
            placeholder={t('PDF_TAG.TOP.DROPDOWN_PLACEHOLDER')}
            valueField="FileID"
            textField={'FileName'}
            onChange={file => {
              initPdfTagScreen(inspectionId, file.FileID);
            }}
            groupBy={'FileType'}
          />
        </div>
        <div className="sidebar">
          <ComponentList handleDeleteComponent={handleDeleteComponent} componentsLoading={componentsLoading} question={question} selectedComponents={selectedComponents} />
          <ChooseComponents
            selectedComponents={selectedComponents}
            handleComponentSearch={this.handleComponentSearch}
            filteredComponents={filteredComponents}
            onComponentChange={this.onComponentChange}
            handleInputFocus={this.handleInputFocus}
            selectedComponent={selectedComponent}
            addSelectedComponent={this.addSelectedComponent}
            searchApiRequired={searchApiRequired}
            componentsFilterProps={componentsFilterProps}
            loadMoreOnClick={loadMoreOnClick}
            keepComponentsDropdownVisible={keepComponentsDropdownVisible}
            componentsLoading={componentsLoading}
          />
        </div>
      </div>
    );
  }
}

ComponentPickerPDF.propTypes = {
  searchApiRequired: PropTypes.bool,
  handleAPIComponentSearch: PropTypes.func,
  componentsFilterProps: PropTypes.object,
  loadMoreOnClick: PropTypes.func,
  componentsSearchText: PropTypes.string,
  keepComponentsDropdownVisible: PropTypes.bool,
};

ComponentPickerPDF.defaultProps = {
  searchApiRequired: false,
  handleAPIComponentSearch: searchTerm => null,
  componentsFilterProps: {},
  loadMoreOnClick: () => null,
  componentsSearchText: '',
  keepComponentsDropdownVisible: false,
};

const mapStateToProps = state => {
  return {
    pdfList: state.pdfTagReducer.pdfList,
    selectedFile: state.pdfTagReducer.selectedFile,
    pdfComponentsLoading: state.pdfTagReducer.componentsLoading,
    pdfFileLoading: state.pdfTagReducer.pdfFileLoading,
    selectedDrawing: state.pdfTagReducer.selectedDrawing,
    pdfComponents: state.pdfTagReducer.pdfComponents,
  };
};

const mapDispatchToProps = dispatch => ({
  getPdfList: (inspectionId, selectedFileID, callback) => dispatch(getPdfList(inspectionId, selectedFileID, callback)),
  setSelectedFile: file => dispatch(setSelectedFile(file)),
  setPdfList: data => dispatch(setPdfList(data)),
  initPdfTagScreen: (inspectionID, fileId, filter, pdfPageNumber, componentId, callback, loadMore, dataCallback) =>
    dispatch(initPdfTagScreen(inspectionID, fileId, filter, pdfPageNumber, componentId, callback, loadMore, dataCallback)),
  selectDrawing: drawing => dispatch(selectDrawing(drawing)),
  selectDefect: component => dispatch(selectDefect(component)),
  setPdfComponents: selectedModuleItemDrawings => dispatch(setPdfComponents(selectedModuleItemDrawings)),
});

ComponentPickerPDF.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ComponentPickerPDF);
