import constants from '../constants/constants';
import { isEmpty } from 'lodash';
import { EMAIL_REGEX, SPECIAL_CHAR_REGEX, PHONE_REGEX } from '../../../common/constants';

export const validate = (values, props) => {
  const errors = {};

  if (!values[constants.fields.projectName]) {
    errors[constants.fields.projectName] = 'CREATE_PROJECT.PROJECT_NAME_REQUIRED';
  }

  if (values[constants.fields.projectName] && (values[constants.fields.projectName].length < 3 || values[constants.fields.projectName].length > 50)) {
    errors[constants.fields.projectName] = 'CREATE_PROJECT.PROJECT_NAME_LENGTH_ERROR';
  }
  if (isEmpty(values[constants.fields.selectContact]) || values[constants.fields.selectContact] === '') {
    errors[constants.fields.selectContact] = 'CREATE_PROJECT.CONTACT_REQUIRED';
  } else {
    // If option is selected validate manager details
    if (!values[constants.fields.contactName]) {
      errors[constants.fields.contactName] = 'CREATE_PROJECT.CONTACT_NAME_REQUIRED';
    }
    if (values[constants.fields.contactName] && SPECIAL_CHAR_REGEX.test(values[constants.fields.contactName])) {
      errors[constants.fields.contactName] = 'CREATE_COMPANY.COMPANY_USER_NAME_SPECIAL_CHAR_NOT_ALLOWED';
    }
    if (!values[constants.fields.email]) {
      errors[constants.fields.email] = 'CREATE_PROJECT.CONTACT_EMAIL_REQUIRED';
    }
  }
  //validate only for create project
  if (!props.isEdit && !values[constants.fields.company]) {
    errors[constants.fields.company] = 'CREATE_PROJECT.COMPANY_REQUIRED';
  }

  if (!EMAIL_REGEX.test(values[constants.fields.email])) {
    errors[constants.fields.email] = 'CREATE_PROJECT.EMAIL_INVALID';
  }
  if (PHONE_REGEX.test(values[constants.fields.phone])) {
    errors[constants.fields.phone] = 'CREATE_PROJECT.CONTACT_PHONE_INVALID';
  }
  if (!values[constants.fields.address]) {
    errors[constants.fields.address] = 'CREATE_PROJECT.ADDRESS_REQUIRED';
  }

  return errors;
};
