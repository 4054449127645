import constants from '../constants/sap-constants';

export const validate = values => {
  const errors = {};

  if (!values[constants.formConstants.fields.clientToken]) {
    errors[constants.formConstants.fields.clientToken] = 'INTEGRATIONS.FORMS.SAP.CLIENT_TOKEN_REQUIRED';
  }
  if (!values[constants.formConstants.fields.clientID]) {
    errors[constants.formConstants.fields.clientID] = 'INTEGRATIONS.FORMS.SAP.CLIENT_ID_REQUIRED';
  }
  if (!values[constants.formConstants.fields.integrationUrl]) {
    errors[constants.formConstants.fields.integrationUrl] = 'INTEGRATIONS.FORMS.SAP.INTEGRATION_URL_REQUIRED';
  }

  return errors;
};
