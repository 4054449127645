import React, { useMemo } from 'react';
import Button from '../../../../../common/form/components/button';
import PropTypes from 'prop-types';
import SearchInput from '../../../../../common/input/components/search-input';
import CheckBox from '../../../../../common/form/components/checkbox';
import { connect } from 'react-redux';
import Icon from '../../../../../common/icon/components/icon';
import { findIndex, orderBy } from 'lodash';
import { fields } from '../../../constants/constants';

const EditLabelsModal = ({ customCloseAction, handleAddLabelToDMSFile, handleRemoveLabelFromDMSFile, handleSearchDMSFileLabels, selectedDMSFileLabels, dmsLabels }, { t }) => {
  const handleAssignLabel = (label, isSelected) => {
    if (isSelected) {
      removeLabelFromDMSFileHandler(label);
    } else {
      addLabelToDMSFileHandler(label);
    }
  };

  const addLabelToDMSFileHandler = label => {
    const newAddedLabels = [...selectedDMSFileLabels, label];
    handleAddLabelToDMSFile(newAddedLabels, label);
  };

  const removeLabelFromDMSFileHandler = label => {
    const newAddedLabels = selectedDMSFileLabels.filter(l => l[fields.id] !== label[fields.id]);
    handleRemoveLabelFromDMSFile(newAddedLabels, label);
  };

  const sortedSelectedDMSFileLabels = useMemo(() => {
    return orderBy(selectedDMSFileLabels, fields.name, 'asc');
  }, [selectedDMSFileLabels]);

  return (
    <div className="document-management__modal__create-folder edit-labels-modal">
      <SearchInput
        onChange={e => handleSearchDMSFileLabels(e.target.value)}
        placeholder={t('PROJECT.SEARCH.PLACEHOLDER')}
        includeDropdown={true}
        items={dmsLabels || []}
        emptyStateLabel={'DOCUMENT_MANAGEMENT.LABELS_EMPTY_STATE'}
        renderItem={item => {
          const isSelected = findIndex(selectedDMSFileLabels, { [fields.id]: item[fields.id] }) > -1;

          return (
            <div className={`dropdown-render-item-container ${isSelected ? 'selected' : ''}`} key={`dms-file-label-${item[fields.id]}`} onClick={() => handleAssignLabel(item, isSelected)}>
              <div className="label-checkbox-wrapper">
                <CheckBox meta={{}} input={{ value: isSelected }} />
                {item.Colour && item.Colour !== '' && <div className="dms-labels__wrapper__item__color" style={{ backgroundColor: item.Colour }} />}
                <p className="f-primary light-bold module-name">{item[fields.name]}</p>
              </div>
            </div>
          );
        }}
      />
      {sortedSelectedDMSFileLabels?.length > 0 ? (
        <div className="added-items-renderer">
          {sortedSelectedDMSFileLabels.map(addedLabel => {
            return (
              <div className="dropdown-render-item-container" key={`dms-file-label-${addedLabel[fields.id]}`}>
                <div className="label-checkbox-wrapper">
                  {addedLabel.Colour && addedLabel.Colour !== '' && <div className="dms-labels__wrapper__item__color" style={{ backgroundColor: addedLabel.Colour }} />}
                  <p className="f-primary light-bold module-name">{addedLabel[fields.name]}</p>
                  <Icon name="close" size="sm" className="action" onClick={() => removeLabelFromDMSFileHandler(addedLabel)} />
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <label className="no-label-added">{t('NO_LABEL_ADDED_SEARCH_TO_ADD')}</label>
      )}
      <div className="buttons-wrapper">
        <Button onClick={customCloseAction} type="button" text={t('CLOSE')} width="xs" variant="gray-outline" className="modal-button-custom h-md" />
      </div>
    </div>
  );
};

EditLabelsModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  dmsLabels: state.dmsLabelsReducer.dmsLabels,
  selectedDMSFileLabels: state.dmsLabelsReducer.selectedDMSFileLabels,
});

export default connect(mapStateToProps, null)(EditLabelsModal);
