import constants from '../constants/download-report-constants';

export const validate = values => {
  const errors = {};

  if (values[constants.fields.description] && values[constants.fields.description].length > constants.descriptionMaxChars) {
    errors[constants.fields.description] = 'DOWLOAD_REPORT.DESCRIPTION_MAX_CHARS';
  }
  if (values[constants.fields.recomendations] && values[constants.fields.recomendations].length > constants.descriptionMaxChars) {
    errors[constants.fields.recomendations] = 'DOWLOAD_REPORT.DESCRIPTION_MAX_CHARS';
  }

  return errors;
};
