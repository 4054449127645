import React, { Component } from 'react';
import PropTypes from 'prop-types';

import '../styles/upload.scss';

class UploadBar extends Component {
  render() {
    const { t } = this.context;
    return (
      <div className="guest-message-container">
        <p> {t('GUEST_USER.MESSAGE')}</p>
      </div>
    );
  }
}

UploadBar.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default UploadBar;
