import actionTypes from '../constants/action-types';

const sapState = {
  isSaved: false,
  isSaving: false,
  isSynchronizing: false,
  formError: null,
  sapDetails: null,
  infoModalData: {
    isOpen: false,
  },
  equipment: [],
  fetchingEquipment: false,
  integrationStatus: null,
  notifications: [],
  selectedNotification: null,
};

export const sapReducer = (state = sapState, payload) => {
  switch (payload.type) {
    case actionTypes.SAP_SET_INTEGRATION_DETAILS:
      return {
        ...state,
        sapDetails: payload.data,
      };
    case actionTypes.SAP_SET_CHANGES_SAVED:
      return {
        ...state,
        isSaved: payload.data,
      };
    case actionTypes.SAP_SAVE_DATA_START:
      return {
        ...state,
        isSaving: true,
        formError: null,
      };
    case actionTypes.SAP_SAVE_DATA_FAILURE:
      return {
        ...state,
        isSaving: false,
        formError: payload.data,
      };
    case actionTypes.SAP_SAVE_DATA_SUCCESS:
      return {
        ...state,
        isSaving: false,
        isSaved: true,
        formError: null,
      };
    case actionTypes.SAP_SYNC_DATA_START:
      return {
        ...state,
        isSynchronizing: true,
        formError: null,
      };
    case actionTypes.SAP_SYNC_DATA_FAILURE:
      return {
        ...state,
        isSynchronizing: false,
        formError: payload.data,
      };
    case actionTypes.SAP_SYNC_DATA_SUCCESS:
      return {
        ...state,
        isSynchronizing: false,
        formError: null,
      };
    case actionTypes.SET_INFO_MODAL_DATA_SAP:
      return {
        ...state,
        infoModalData: payload.data,
      };
    case actionTypes.SET_EQUIPMENT_DATA:
      return {
        ...state,
        equipment: payload.data,
      };
    case actionTypes.GET_EQUIPMENT_START:
      return {
        ...state,
        fetchingEquipment: true,
      };
    case actionTypes.GET_EQUIPMENT_SUCCESS:
      return {
        ...state,
        fetchingEquipment: false,
      };
    case actionTypes.GET_EQUIPMENT_FAILURE:
      return {
        ...state,
        fetchingEquipment: false,
      };
    // NOTIFICATIONS
    case actionTypes.SET_SELECTED_NOTIFICATION:
      return {
        ...state,
        selectedNotification: payload.data || state.selectedNotification,
      };
    case actionTypes.SET_INTEGRATION_STATUS:
      return {
        ...state,
        integrationStatus: payload.data,
      };
    case actionTypes.SET_NOTIFICATIONS_DATA:
      return {
        ...state,
        notifications: payload.data,
      };
    case actionTypes.GET_NOTIFICATIONS_START:
      return {
        ...state,
        fetchingNotifications: true,
      };
    case actionTypes.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        fetchingNotifications: false,
      };
    case actionTypes.GET_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        fetchingNotifications: false,
      };
    //  END
    default:
      return state;
  }
};
