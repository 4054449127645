import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Field, change, reduxForm } from 'redux-form';
import { FORMS } from '../../../../common/constants';
import EmptyState from '../../../../common/empty-state-v2/components/empty-state';
import ExpandableSection from '../../../../common/expandable-section/components/expandable-section';
import Button from '../../../../common/form/components/button';
import CustomInput from '../../../../common/form/components/input';
import Textarea from '../../../../common/form/components/text-area';
import UneditableInfo from '../../../../common/form/components/uneditable-info';
import Helpers from '../../../../common/helpers';
import { ReactComponent as upload } from '../../../../common/icon/assets/dms-documents.svg';
import { ReactComponent as trash } from '../../../../common/icon/assets/trash.svg';
import Modal from '../../../../common/modal/components/modal';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../common/permissions-constants';
import RenderIf from '../../../../common/render-if/components/render-if';
import ChangeStatusDropdown from '../../../inspections/components/right-toolbar/common/change-status-dropdown';
import CriticalEquipmentInfo from '../../../inspections/components/right-toolbar/common/critical-equipment-info';
import ModuleHeader from '../../../inspections/components/right-toolbar/common/module-header';
import { fields, fileDetailsSections, fileFormFields, itemActionTypes } from '../../constants/constants';
import dmsHelpers from '../../helpers/dms-helpers';
import { validateUpdateFile } from '../../validators/form-validators';
import VersionHistoryModal from '../modals/version-history-modal/version-history-modal';

const DMSRightSideFileBasicForm = (
  {
    initialValues,
    isDisabled,
    user,
    openChangeLocationModal,
    statusData,
    onFileStatusChange,
    onEditIconClick,
    openFile,
    downloadFile,
    handleSubmit,
    handleFormSubmit,
    invalid,
    anyTouched,
    onModuleItemDeepLinkHandler,
    formHasUnsavedChanges,
    onFormChange,
    onUploadFileClick,
    onDeleteFileClick,
    versionHistoryModalRef,
    updateSelectedHierarchyDetailsData,
    openEditVersionModal,
    handleOnDropdownClick,
    changeField,
  },
  { t }
) => {
  const {
    fileId,
    fileName,
    description,
    location,
    smartDrawing,
    fileSize,
    uploadedAt,
    uploadedBy,
    systemVersion,
    versionDate,
    versionNumber,
    versionDescription,
    versionUploadedBy,
    versionUploadedAt,
  } = fileFormFields;

  const [modalData, setModalData] = useState({ isOpen: false });
  const statuses = dmsHelpers.getStatuses(statusData);

  useEffect(() => {
    setModalData({ ...modalData, documentDetails: initialValues });
    // eslint-disable-next-line
  }, [initialValues]);

  const moduleHeaderMenuOptions = [
    {
      title: t('DMS_FILE.UPLOAD_NEW_VERSION'),
      icon: upload,
      showInfoTooltip: initialValues && initialValues[fields.smartDrawing],
      infoTooltipTitle: 'DMS_FILE.UPLOAD_NEW_VERSION_DISABLED',
      customMenuItemClassName: 'green-icon stroke',
      iconProps: { height: 20, width: 20 },
      action: () => onUploadFileClick(true),
      access: {
        visibleFor: [PERMISSIONS[PERMISSION_TYPES.documentManagement].edit.name],
        id: initialValues[fields.createdByID],
        ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.documentManagement].create.name,
      },
      isDisabled: initialValues && initialValues[fields.smartDrawing],
    },
    {
      title: t('DELETE_FILE'),
      icon: trash,
      separator: true,
      customMenuItemClassName: 'red-icon stroke',
      iconProps: { height: 20, width: 20 },
      action: () => onDeleteFileClick(initialValues, itemActionTypes.file.delete),
      access: {
        visibleFor: [PERMISSIONS[PERMISSION_TYPES.documentManagement].delete.name],
      },
    },
  ];

  const handleDeleteVersionAction = file => handleOnDropdownClick(file, itemActionTypes.fileVersion.delete);
  const closeModal = () => setModalData({ isOpen: false });

  const closeVersionHistoryModal = () => {
    versionHistoryModalRef.current = { isOpen: false };
    closeModal();
  };

  const handleVersionHistoryClick = () => {
    if (!initialValues || !initialValues[fields.sourceID]) return;
    const sourceID = initialValues[fields.sourceID];
    versionHistoryModalRef.current = { isOpen: true, sourceID };
    setModalData({
      type: '',
      title: t('DMS_FILE.VERSION_HISTORY'),
      isOpen: true,
      closeAction: closeVersionHistoryModal,
      customCloseAction: closeVersionHistoryModal,
      customClassName: 'modal-no-max-height modal-large',
      CustomContent: dynamicProps => <VersionHistoryModal {...dynamicProps} />,
      sourceID,
      openUploadNewVersion: () => onUploadFileClick(true),
      updateSelectedHierarchyDetailsData: updateSelectedHierarchyDetailsData,
      openEditVersionModal,
      handleDeleteVersionAction: handleDeleteVersionAction,
      canUploadNewVersion:
        Helpers.hasAccess({
          user,
          visibleFor: [PERMISSIONS[PERMISSION_TYPES.documentManagement].edit.name],
          id: initialValues[fields.createdByID],
          ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.documentManagement].create.name,
        }) &&
        initialValues &&
        !initialValues[fields.smartDrawing],
      documentDetails: initialValues,
      formHasUnsavedChanges: formHasUnsavedChanges,
    });
  };

  const onFormSubmit = values => handleFormSubmit(values);

  const onFormChangeHandler = () => {
    if (!formHasUnsavedChanges) {
      onFormChange(true);
    }
  };

  const relatedComponents = initialValues[fileDetailsSections.components.valueKey];
  const containsCriticalEquipment = relatedComponents && relatedComponents.some(component => component[fields.critical]);

  return (
    <form className={`dms-right-side-form details-form ${isDisabled ? 'ch-right-side-form__disabled' : ''}`} onSubmit={handleSubmit(onFormSubmit)} noValidate onChange={onFormChangeHandler}>
      <p className="f-primary ch-right-side-form__title">{t('COMPONENT_HIERARCHY_LEVEL_DETAILS.TITLE')}</p>
      <div className="pr-10">
        <ModuleHeader id={initialValues[fileId.name]} hideShare hideDownload hideMaximize menuOptions={moduleHeaderMenuOptions} />
      </div>
      <RenderIf if={containsCriticalEquipment}>
        <CriticalEquipmentInfo title={'CRITICAL_EQUIPMENT.TITLE'} paragraph={'CRITICAL_EQUIPMENT_DMS.PARAGRAPH'} />
      </RenderIf>
      <div className="dms-right-side-form__cta-wrapper">
        <Button
          type="button"
          width="md"
          variant="success-outline"
          keepOriginalText={true}
          icon="newTab"
          className="load-more__button"
          onClick={() => openFile(initialValues)}
          text={t('OPEN_IN_CLARITY')}
        />
        <Button
          type="button"
          width="md"
          variant="success-outline"
          keepOriginalText={true}
          icon="download"
          className="load-more__button"
          onClick={() => downloadFile(initialValues)}
          text={t('DOWNLOAD_REPORT.DOWNLOAD_BUTTON')}
        />
      </div>
      <ChangeStatusDropdown
        statuses={statuses}
        module={Object.assign({}, initialValues)}
        user={user}
        handleChangeStatus={onFileStatusChange}
        createdById={initialValues[fields.createdByID]}
        // in order to enable changing of status when active is LIVE BE needs to change isFinalStatus: true to false
        finalStatus={''}
        disabled={isDisabled}
      />
      <Field
        id={fileName.id}
        name={fileName.name}
        component={CustomInput}
        placeholder={t(fileName.label)}
        label={t(fileName.label)}
        type="text"
        size="lg"
        isRequired
        additionalClassName="text-transform-none"
        labelClass="text-transform-none"
        input={{
          // set default file name, so it can be overwritten with onChange action
          defaultValue: Helpers.decodeIfStringEncoded(initialValues[fields.fileName]),
          // update file name in form, so it is saved for on Save action
          onChange: e => {
            changeField(FORMS.DMSFileHierarchyLevel, fileName.name, e, true);
          },
        }}
      />
      <Field
        id={description.id}
        name={description.name}
        component={isDisabled ? UneditableInfo : Textarea}
        placeholder={t(description.label)}
        label={t(description.label)}
        size="lg"
        wrapperClassName="text-transform-none border-bottom-dropdown mb-20"
        labelClass="text-transform-none"
        maxChars={description.maxChars}
      />
      <div className="dms-right-side-form__location-wrapper">
        <Field
          id={location.id}
          name={location.name}
          component={UneditableInfo}
          placeholder={t(location.label)}
          label={t(location.label)}
          size="lg"
          additionalClassName="text-transform-none dms-right-side-form__section-wrapper"
          labelClass="text-transform-none"
          withTopRightButton={!isDisabled}
          topRightButtonAction={openChangeLocationModal}
          topRightButtonText="COMPONENT_DETAILS.FORM_HIERARCHY_CHANGE"
          visible={true}
        />
      </div>

      <div className="ch-right-side-form__uneditable-info">
        <Field
          id={smartDrawing.id}
          name={smartDrawing.name}
          component={UneditableInfo}
          placeholder={t(smartDrawing.label)}
          label={t(smartDrawing.label)}
          size="lg"
          additionalClassName="text-transform-none dms-right-side-form__section-wrapper"
          labelClass="text-transform-none"
          input={{ value: initialValues[fields.smartDrawing] ? t('YES_CAPITALIZED') : t('NO_CAPITALIZED') }}
        />

        <Field
          id={fileSize.id}
          name={fileSize.name}
          component={UneditableInfo}
          placeholder={t(fileSize.label)}
          label={t(fileSize.label)}
          size="lg"
          additionalClassName="text-transform-none dms-right-side-form__section-wrapper"
          labelClass="text-transform-none"
          input={{ value: Helpers.formatBytes(initialValues[fields.fileSizeKB]) }}
        />
        <Field
          id={uploadedAt.id}
          name={uploadedAt.name}
          component={UneditableInfo}
          placeholder={t(uploadedAt.label)}
          label={t(uploadedAt.label)}
          type="text"
          size="lg"
          input={{ value: Helpers.getDateFromUnix(initialValues[fields.createdAt]) }}
        />
        <Field
          id={uploadedBy.id}
          name={uploadedBy.name}
          component={UneditableInfo}
          placeholder={t(uploadedBy.label)}
          label={t(uploadedBy.label)}
          type="text"
          size="lg"
          wrapperClassName="text-transform-none dms-right-side-form__section-wrapper border-bottom-dropdown"
        />
        <div className="details-form__info-row">
          <div className="details-form__info-row__cell__system-version">
            <Field id={systemVersion.id} name={systemVersion.name} component={UneditableInfo} placeholder={t(systemVersion.label)} label={t(systemVersion.label)} />
          </div>
          <div className="details-form__info-row__cell__version-number">
            <Field id={versionNumber.id} name={versionNumber.name} component={UneditableInfo} placeholder={t(versionNumber.label)} label={t(versionNumber.label)} />
          </div>
        </div>
        <Field
          id={versionDescription.id}
          name={versionDescription.name}
          component={UneditableInfo}
          placeholder={t(versionDescription.label)}
          label={t(versionDescription.label)}
          textClassName="break-text"
        />
        <Field
          id={versionDate.id}
          name={versionDate.name}
          component={UneditableInfo}
          placeholder={t(versionDate.label)}
          label={t(versionDate.label)}
          input={{ value: initialValues[versionDate.name] && initialValues[versionDate.name] !== 0 ? Helpers.getDateFromUnix(initialValues[versionDate.name]) : '-' }}
        />
        <Field
          id={versionUploadedAt.id}
          name={versionUploadedAt.name}
          component={UneditableInfo}
          placeholder={t(versionUploadedAt.label)}
          label={t(versionUploadedAt.label)}
          input={{ value: initialValues[versionUploadedAt.name] && initialValues[versionUploadedAt.name] !== 0 ? Helpers.getDateFromUnix(initialValues[versionUploadedAt.name]) : '-' }}
        />
        <Field id={versionUploadedBy.id} name={versionUploadedBy.name} component={UneditableInfo} placeholder={t(versionUploadedBy.label)} label={t(versionUploadedBy.label)} />
        <p className="f-secondary-green noselect pointer link light-bold mb-20" onClick={handleVersionHistoryClick}>
          {t('DMS_FILE.VERSION_HISTORY')}
        </p>
        <hr className="details-form__separator-line" />
      </div>
      {Object.values(fileDetailsSections).map((item, index) => {
        const editFunction = onEditIconClick[item.valueKey] ? onEditIconClick[item.valueKey] : () => {};
        return (
          item.component && (
            <ExpandableSection
              title={item.title ? t(item.title) : ''}
              className={item.className ? item.className : `section-${t(item.title)}`}
              hideEditAction={item.hideEditAction || isDisabled}
              onEditClick={editFunction}
              key={`section-${t(item.title)}-${index}`}
            >
              <item.component
                {...item.componentProps}
                labels={initialValues[item.valueKey]}
                value={initialValues[item.valueKey]}
                moduleItemTitleIcon={item.moduleItemTitleIcon}
                moduleItemTitle={item.moduleItemTitle}
                onModuleItemDeepLinkClick={moduleItem => onModuleItemDeepLinkHandler(moduleItem, item.valueKey.toLowerCase())}
                tableConfig={item.tableConfig}
                handleFormatCells={item.handleFormatCells}
                noDataText={!item.emptyStateComponent && item.noDataText}
                emptyStateComponent={
                  item.emptyStateComponent
                    ? () => <EmptyState buttonAction={editFunction} buttonText={t(item.emptyStateButtonText)} transparent showButton={!isDisabled} emptyStateText={t(item.noDataText)} />
                    : null
                }
              />
            </ExpandableSection>
          )
        );
      })}
      <div className="dms-right-side-form__save-cta-wrapper">
        <Button type="submit" disabled={isDisabled || !anyTouched || invalid} width="sm" height="md" text={t('SAVE')} />
      </div>
      <Modal {...modalData} />
    </form>
  );
};

DMSRightSideFileBasicForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  changeField: (formName, field, value, touch) => dispatch(change(formName, field, value, touch)),
});

export default connect(
  null,
  mapDispatchToProps
)(reduxForm({ form: FORMS.DMSFileHierarchyLevel, validate: validateUpdateFile, enableReinitialize: true, touchOnChange: true, destroyOnUnmount: true })(DMSRightSideFileBasicForm));
