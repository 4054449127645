import React from 'react';
import PropTypes from 'prop-types';
import { values, filter, find } from 'lodash';

import CustomSelect from '../../../../../common/form/components/select';
import Icon from '../../../../../common/icon/components/icon';
import { formFields, fields, dropdownFields } from '../isolation-certificate-details/constants/change-status-constants';
import { fields as workOrderFields } from '../../work-order/constants/work-order-right-side-constants';

import '../../../styles/module-change-status.scss';
import WarningIndicator from '../../../../../common/warning-indicator/components/warning-indicator';
import RenderIf from '../../../../../common/render-if/components/render-if';

const ChangeStatusDropdown = ({ statuses, disabled, module, user, createdById, handleChangeStatus, finalStatus, rejectedStatus, descriptionType }, { t }) => {
  const { status } = formFields;
  const renderStatuses = React.useMemo(() => {
    const result = values(statuses);
    return filter(result, item => !item.isSingleSelect);
  }, [statuses]);

  const isEnabled = statusItem => {
    const selected = find(renderStatuses, { [dropdownFields.valueField]: module[status.name] });

    if (!selected || selected.isFinalStatus) {
      return false;
    }

    if (statusItem.skipPermissionCheck || statusItem[dropdownFields.valueField] === rejectedStatus) {
      return true;
    }

    if (user.DistinctPermissions && user.DistinctPermissions.length > 0 && (statusItem[fields.transitionPermission] || statusItem[fields.ownershipPermission])) {
      if (statusItem[fields.transitionPermission] && user.DistinctPermissions.indexOf(statusItem[fields.transitionPermission]) > -1) {
        return true;
      }

      if (statusItem[fields.ownershipPermission] && user.DistinctPermissions.indexOf(statusItem[fields.ownershipPermission]) > -1 && user.UserID === createdById) {
        return true;
      }
    }

    return false;
  };

  const isNextStatusDisabled = statusItem => {
    if (statusItem[dropdownFields.valueField] === module[status.name]) {
      return true;
    }

    if (statusItem[dropdownFields.valueField] === rejectedStatus) {
      const currentStatus = find(renderStatuses, { [dropdownFields.valueField]: module[status.name] });

      if (
        currentStatus &&
        user.DistinctPermissions.indexOf(currentStatus[fields.editPermission]) === -1 &&
        (user.DistinctPermissions.indexOf(currentStatus[fields.ownershipPermission]) === -1 || user.UserID !== createdById)
      ) {
        return true;
      }
    }

    const selected = find(renderStatuses, { [dropdownFields.valueField]: module[status.name] });

    if (statuses.rejected && statusItem[dropdownFields.valueField] === statuses.rejected.value && statusItem[fields.fromStatuses]) {
      return statusItem[fields.fromStatuses].indexOf(module[status.name]) === -1;
    }

    if (selected && selected[fields.goToStatuses]) {
      return selected[fields.goToStatuses].indexOf(statusItem[dropdownFields.valueField]) === -1;
    }

    return false;
  };

  return (
    <div className="change-status-container">
      <CustomSelect
        meta={{}}
        input={{ value: module?.[status.name], onChange: handleChangeStatus }}
        id={status.id}
        name={status.name}
        label={status.label}
        component={CustomSelect}
        data={renderStatuses}
        disabled={module[workOrderFields.archived] || module[workOrderFields.onHold]}
        size="lg"
        valueField={dropdownFields.valueField}
        textField={dropdownFields.textField}
        withHiddenError
        valueComponent={selected => (
          <div className="change-status-select-item">
            <div className="display-value-item">
              <Icon name={selected?.item?.[dropdownFields.icon]} className="certificate-status-icon" {...(selected?.item?.iconProps || {})} />
              <p className="f-primary bold">{t(selected?.item?.[dropdownFields.textField])}</p>
            </div>
          </div>
        )}
        optionComponent={({ dataItem, onSelect }) => {
          const optionDisabled = disabled || module[status.name] === finalStatus || !isEnabled(dataItem) || isNextStatusDisabled(dataItem);

          return (
            <div
              className={`change-status-select-item ${optionDisabled ? 'change-status-select-item-disabled' : ''}`}
              onClick={() => {
                if (optionDisabled) {
                  return;
                }

                dataItem[status.name] = dataItem[dropdownFields.valueField];
                onSelect(dataItem);
              }}
            >
              <div className="status-item-display">
                <Icon name={dataItem[dropdownFields.icon]} className={`certificate-status-icon ${optionDisabled ? 'certificate-status-icon-disabled' : ''}`} {...(dataItem?.iconProps || {})} />
                <p className={`${optionDisabled ? 'f-secondary-dark' : ''} bold`}>{t(dataItem[dropdownFields.textField])}</p>
              </div>
            </div>
          );
        }}
      />
      <RenderIf if={module[workOrderFields.archived]}>
        <WarningIndicator title={t('WARNING.ARCHIVED')} description={t(`WARNING.DESCRIPTION_${descriptionType}`)} />
      </RenderIf>
      <RenderIf if={module[workOrderFields.onHold]}>
        <WarningIndicator title={t('WARNING.ON_HOLD')} description={t(`WARNING.DESCRIPTION_${descriptionType}`)} />
      </RenderIf>
    </div>
  );
};

ChangeStatusDropdown.contextTypes = {
  t: PropTypes.func.isRequired,
};

ChangeStatusDropdown.defaultProps = {
  disabled: false,
};

export default ChangeStatusDropdown;
