import React, { Fragment } from 'react';
import { DrawingTools } from './drawing-tools';

import { isEmpty } from 'lodash';

import { ReactComponent as GalleryViewIcon } from '../assets/image-gallery-view.svg';
import { ReactComponent as SingleViewIcon } from '../assets/image-single-view.svg';
import { ReactComponent as ChartViewIcon } from '../assets/chart-icon.svg';
import { ReactComponent as PrevIcon } from '../assets/prev.svg';

import orientationConstants from '../../start-workflow/constants/orientation-constants';

import Legend from '../../inspection-workflow/components/legend';
import { FEATURES } from '../../../common/constants';

const ToolsBar = props => {
  const {
    workflowImagesSelected,
    currentSlideIndex,
    previousClick,
    nextClick,
    orientation,
    fillColor,
    selectedTool,
    onColorChange,
    selectDrawingTool,
    setOrientation,
    drawingToolsEnabled,
    enableDrawing,
    disableColorPicker,
    disabledOrientations,
    CustomToolsComponent,
    currentImage,
  } = props;

  return (
    <div className="tools-container">
      {orientation && (
        <Fragment>
          {disabledOrientations.indexOf(orientationConstants.orientation.full) === -1 && (
            <div className="tools-container__rounded-container" onClick={() => setOrientation(orientationConstants.orientation.full)}>
              <SingleViewIcon className={`image ${orientation === orientationConstants.orientation.full ? 'active' : 'svg-gray-g'}`} />
            </div>
          )}
          {disabledOrientations.indexOf(orientationConstants.orientation.grid) === -1 && (
            <div className="tools-container__rounded-container" onClick={() => setOrientation(orientationConstants.orientation.grid)}>
              <GalleryViewIcon className={`image ${orientation === orientationConstants.orientation.grid ? 'active' : 'svg-gray-g'}`} />
            </div>
          )}
          {disabledOrientations.indexOf(orientationConstants.orientation.chart) === -1 && FEATURES.workflowChartView.visible && (
            <div className="tools-container__rounded-container" onClick={() => setOrientation(orientationConstants.orientation.chart)}>
              <ChartViewIcon className={`image ${orientation === orientationConstants.orientation.chart ? 'active' : 'svg-gray-g'}`} />
            </div>
          )}
        </Fragment>
      )}
      {(orientation === orientationConstants.orientation.full || orientation === null) && (
        <Fragment>
          <div className="tools-container__controls">
            <div
              className="prev rounded"
              onClick={() => {
                if (previousClick) {
                  previousClick();
                }
              }}
            >
              <PrevIcon className="image svg-gray-g" />
            </div>
            {isEmpty(workflowImagesSelected) ? <p className="f-primary text"> {`0 / 0`}</p> : <p className="f-primary text"> {`${currentSlideIndex + 1} / ${workflowImagesSelected.length}`}</p>}
            <div
              className="next rounded"
              onClick={() => {
                if (nextClick) {
                  nextClick();
                }
              }}
            >
              <PrevIcon className="image svg-gray-g reverse" />
            </div>
          </div>
          {drawingToolsEnabled && (
            <DrawingTools
              onColorChange={onColorChange}
              selectedTool={selectedTool}
              fillColor={fillColor}
              selectDrawingTool={selectDrawingTool}
              enableDrawing={enableDrawing}
              disableColorPicker={disableColorPicker}
            />
          )}
        </Fragment>
      )}
      {orientation === orientationConstants.orientation.grid && <Legend />}
      {CustomToolsComponent && <CustomToolsComponent currentImage={currentImage} />}
    </div>
  );
};

ToolsBar.defaultProps = {
  disabledOrientations: [],
};

export default ToolsBar;
