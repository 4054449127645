import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { FORMS } from '../../../../common/constants';
import { defaultComponentName } from '../../constants/component-constants';
import { formConstants } from '../../constants/defect-constants';
import { fields } from '../observations/constants/constants';
import DefectForm from './defect-form';
import { validate } from './validators/defect-validator';

const DefectFormWrapper = (props, { t }) => {
  const {
    changeLinkedImagesColor,
    inspectionDetails,
    projectID,
    inspectionID,
    handleDefectRelatedComponentsVisible,
    componentsVisible,
    changeField,
    defectFiles,
    openConfirmationModal,
    openDownloadReportModalSingleDefect,
    observationTypes,
    observationNotifications,
    handleActivePage,
    defect,
    handleEditDrawing,
    objectToolClick,
    formValues,
    formChangeDebounce,
    getDefectFiles,
    openRelatedImagesModal,
    fetchInspectionComponents,
    inspectionComponents,
    showGeometryWarning,
    handleInputDisabled,
    openFile,
    openAssigneesModal,
    addedAssignees,
    addedCollaborators,
    isLoadingNotificationDetails,
    submitForm,
    formHasUnsavedChanges,
    handleObservationComponentChange,
    handleCustomPropertyUpdate,
    observationWorkOrders,
    locationObject,
    toggleElement,
  } = props;

  const handleComponentChange = value => {
    const defectID = get(defect, 'ID');
    handleObservationComponentChange(defectID, value);
  };

  // still used, but should be cleaned up soon
  const handleFieldChange = (value, field) => {
    const errors = validate({ ...formValues, [field]: value });

    if (isEmpty(errors)) {
      if (field === formConstants.fields.status || field === formConstants.fields.mainType) {
        formChangeDebounce({ ...formValues, [field]: value }, true);
      } else if (field === formConstants.fields.points) {
        formChangeDebounce(formValues);
      } else {
        formChangeDebounce({ ...formValues, [field]: value });
      }
    }
  };

  const handleStatusChange = status => {
    if (!status || !status.value) return;

    const onSuccessfulChange = () => {
      // change status property only
      changeField(fields.status, status.value);
    };

    submitForm({ ...formValues, [fields.status]: status.value }, false, onSuccessfulChange);
  };

  const handleSubmittedForm = values => {
    submitForm(values, false);
  };

  return (
    <DefectForm
      onSubmit={handleSubmittedForm}
      handleFieldChange={handleFieldChange}
      changeLinkedImagesColor={changeLinkedImagesColor}
      defaultComponent={{ Name: defaultComponentName, ID: inspectionDetails?.DefaultComponent }}
      handleEditDrawing={({ currentImage }, defectId) => {
        handleEditDrawing(projectID, inspectionID, currentImage, defectId);
      }}
      inspectionID={inspectionID}
      toggleComponentsVisible={handleDefectRelatedComponentsVisible}
      componentsVisible={componentsVisible}
      changeField={changeField}
      defectFiles={defectFiles}
      openRelatedImagesModal={openRelatedImagesModal}
      openConfirmationModal={openConfirmationModal}
      getDefectFiles={getDefectFiles}
      openDownloadReportModalSingleDefect={openDownloadReportModalSingleDefect}
      observationTypes={observationTypes}
      observationNotifications={observationNotifications}
      handleActivePage={handleActivePage}
      initialValues={defect}
      formValues={formValues}
      selectedDefect={defect}
      objectToolClick={objectToolClick}
      fetchInspectionComponents={fetchInspectionComponents}
      inspectionComponents={inspectionComponents}
      showGeometryWarning={showGeometryWarning}
      handleInputDisabled={handleInputDisabled}
      openFile={openFile}
      openAssigneesModal={openAssigneesModal}
      addedAssignees={addedAssignees}
      addedCollaborators={addedCollaborators}
      isLoadingNotificationDetails={isLoadingNotificationDetails}
      handleStatusChange={handleStatusChange}
      formHasUnsavedChanges={formHasUnsavedChanges}
      onChange={formChangeDebounce}
      handleComponentChange={handleComponentChange}
      handleCustomPropertyUpdate={handleCustomPropertyUpdate}
      observationWorkOrders={observationWorkOrders}
      locationObject={locationObject}
      toggleElement={toggleElement}
    />
  );
};

const mapStateToProps = state => ({ formValues: state.form[FORMS.defect]?.values || {} });

DefectFormWrapper.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, null)(DefectFormWrapper);
