import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';

import Icon from '../../../../../common/icon/components/icon';
import { questionTypes } from '../../../../../common/question-components/constants/question-constants';
import RenderIf from '../../../../../common/render-if/components/render-if';
import { setWorkOrderCPSections } from '../../../actions/action-creators';
import { moduleSectionQuestionFields } from '../../../constants/constants';
import { questionAnswered } from '../../work-order/helpers/checklist-procedure-helpers';

const SectionTitle = (
  {
    title,
    number,
    titleTranslatable = false,
    isSectionExpanded = false,
    isExpanded,
    handleSectionActionClicked,
    sectionAction,
    module = {},
    section,
    disabled,
    icon,
    iconClass,
    skipOrdering,
    validate = () => null,
    formValues = {},
    questions = [],
    showError = true,
    isChecklistProcedureTemplate = false,
    setWorkOrderCPSections,
    workOrderCPSections,
  },
  { t }
) => {
  const isSectionInvalid = showError && !isEmpty(validate(formValues, { questions }));

  // TODO: move all this logic to WorkOrderSectionChecklistDetails and getCustomSectionFormInitialValues method
  useEffect(() => {
    if (isChecklistProcedureTemplate) {
      // logic for disabling the submit checklist and procedure submit button
      const currentSections = workOrderCPSections || [];

      const foundIndex = currentSections.findIndex(s => s[moduleSectionQuestionFields.id] === section[moduleSectionQuestionFields.id]);
      const updatedSection = { ...section, SectionInvalid: isSectionInvalid };

      if (foundIndex > -1) {
        // check if section already in array and SectionInvalid is different
        if (currentSections[foundIndex].SectionInvalid !== isSectionInvalid) {
          // remove the old section and add the updated one
          const updatedSections = [...currentSections];
          updatedSections.splice(foundIndex, 1, updatedSection);
          setWorkOrderCPSections(updatedSections);
        }
      } else {
        // if the section is not in the array, add it
        const newSections = [...currentSections, updatedSection];
        setWorkOrderCPSections(newSections);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSectionInvalid, section, isChecklistProcedureTemplate, workOrderCPSections]);

  // TODO: this can be moved to reducer and reused in WorkOrderSectionChecklistQuestionsForm
  const allSectionQuestionsAreAnswered = isChecklistProcedureTemplate && questions?.every(q => questionAnswered(q, formValues));
  const someSectionQuestionsAreAnswered = isChecklistProcedureTemplate && questions?.some(q => questionAnswered(q, formValues));
  const someSectionMultipleAnswersAreAnswered =
    isChecklistProcedureTemplate &&
    questions?.some(sq => sq[moduleSectionQuestionFields.type] === questionTypes.MULTI_ANSWER.valueId && sq[moduleSectionQuestionFields.questionOptions]?.some(qo => questionAnswered(qo, formValues)));

  const renderSectionTitle = number => {
    if (isChecklistProcedureTemplate) {
      if (someSectionQuestionsAreAnswered && !allSectionQuestionsAreAnswered && !isSectionInvalid) {
        return <h5 className="section-question-state section-question-state__started">{number}.</h5>;
      } else if ((allSectionQuestionsAreAnswered || someSectionMultipleAnswersAreAnswered) && !isSectionInvalid) {
        return <h5 className="section-question-state section-question-state__completed">{number}.</h5>;
      } else if (isSectionInvalid) {
        return <h5 className="section-question-state section-question-state__error f-secondary-red">{number}.</h5>;
      } else return <h5 className="section-question-state">{number}.</h5>;
    } else {
      return <h5 className="f-primary">{number}.</h5>;
    }
  };
  return (
    <>
      <div className={`section-title`}>
        {!skipOrdering && renderSectionTitle(number)}
        <RenderIf if={icon}>
          <Icon name={icon} className={`section-title-icon ${iconClass || ''}`} active />
        </RenderIf>
        <h5
          className={`${isSectionInvalid && !isChecklistProcedureTemplate ? 'f-secondary-red' : 'f-primary'} ${icon ? '' : 'section-name'}`}
          id={`module-section-title-${titleTranslatable ? t(title) : title}`}
        >
          {titleTranslatable ? t(title) : title}
        </h5>

        <div className="section-title-actions">
          {typeof handleSectionActionClicked === 'function' && sectionAction && !disabled && (
            <Icon
              name="pen"
              className="toggle-action toggle-action-edit"
              size="sm"
              onClick={e => {
                e.stopPropagation();
                handleSectionActionClicked(sectionAction, module, section);
              }}
            />
          )}
          <Icon name={isSectionExpanded || isExpanded ? 'chevron-up' : 'chevron-down'} className="toggle-action toggle-action-expand" size="md" />
        </div>
      </div>
      <RenderIf if={isSectionInvalid && !isChecklistProcedureTemplate}>
        <p className="f-secondary-red">{t('SECTION_TITLE.ERROR_TEXT')}</p>
      </RenderIf>
    </>
  );
};

SectionTitle.contextTypes = {
  t: PropTypes.func.isRequired,
};

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  titleTranslatable: PropTypes.bool,
  isSectionExpanded: PropTypes.bool,
  handleSectionActionClicked: PropTypes.func,
  sectionAction: PropTypes.string,
  module: PropTypes.object,
  section: PropTypes.object,
  disabled: PropTypes.bool,
  skipOrdering: PropTypes.bool,
  showError: PropTypes.bool,
};

const mapStateToProps = (state, props) => {
  return {
    formValues: getFormValues(props.formName)(state),
    workOrderCPSections: state.workOrderReducer.workOrderCPSections,
  };
};

const mapDispatchToProps = dispatch => ({
  setWorkOrderCPSections: data => dispatch(setWorkOrderCPSections(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SectionTitle);
