import React from 'react';

import RenderIf from '../../render-if/components/render-if';
import EditableSectionTitle from './editable-section-title';
import LockedSectionTitle from './locked-section-title';
import SectionActions from './section-actions';

const SectionTitle = ({
  ID,
  isLocked,
  hideReorder,
  deleteDisabled,
  sectionsLength,
  Name,
  nonTranslatable,
  sectionIndex,
  isExpandable,
  isSectionExpanded,
  toggleSectionExpanded,
  handleSort,
  onDeleteSection,
  handleUpdateSection,
  isLoading,
  isDisabled,
  stickActions,
  sortUpDisabled,
  sortDownDisabled,
  hideLockIcon,
  sections,
  sectionUpdateDebounce,
}) => (
  <div className={`section__container ${isLocked ? 'section__container-locked' : 'section__container-unlocked'} ${isSectionExpanded ? 'section__container-expanded' : ''}`}>
    <RenderIf if={isLocked}>
      <LockedSectionTitle
        Name={Name}
        nonTranslatable={nonTranslatable}
        sectionIndex={sectionIndex}
        isExpandable={isExpandable}
        isSectionExpanded={isSectionExpanded}
        toggleSectionExpanded={toggleSectionExpanded}
        hideLockIcon={hideLockIcon}
      />
    </RenderIf>
    <RenderIf if={!isLocked}>
      <EditableSectionTitle sectionIndex={sectionIndex} ID={ID} handleUpdateSection={handleUpdateSection} isDisabled={isDisabled} sections={sections} sectionUpdateDebounce={sectionUpdateDebounce} />
    </RenderIf>
    {/* <RenderIf if={!isDisabled}> */}
    <SectionActions
      currentIndex={sectionIndex}
      isLocked={isLocked}
      showExpandableAction={isExpandable && !isLocked}
      hideReorder={hideReorder || isDisabled}
      sortDownDisabled={!sectionIndex || sortDownDisabled || isDisabled}
      sortUpDisabled={sectionIndex === sectionsLength - 1 || sortUpDisabled || isDisabled}
      deleteDisabled={deleteDisabled || isDisabled}
      isSectionExpanded={isSectionExpanded}
      toggleSectionExpanded={toggleSectionExpanded}
      handleDeleteSection={onDeleteSection}
      handleSort={handleSort}
      isLoading={isLoading}
      stickActions={stickActions}
    />
    {/* </RenderIf> */}
  </div>
);

export default SectionTitle;
