import React from 'react';
import PropTypes from 'prop-types';

import '../styles/legend-square.scss';

const LegendSquare = props => {
  return (
    <div className="icon-legend-wrapper" title={props.title}>
      <div className={`icon-legend-square ${props.className}`} />
    </div>
  );
};
LegendSquare.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default LegendSquare;
