import PropTypes from 'prop-types';
const sortingOrder = {
  asc: 'ASC',
  desc: 'DESC',
  none: '',
};
const TablePropTypes = {
  data: PropTypes.array.isRequired,
  tableConfig: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      isSort: PropTypes.bool,
      class: PropTypes.string.isRequired,
      headerClassName: PropTypes.string,
      bodyClassName: PropTypes.string,
    })
  ),
  onRowClick: PropTypes.func,
  formatCell: PropTypes.func.isRequired,
  translationModule: PropTypes.func,
  onSortClick: PropTypes.func,
  sortingObj: PropTypes.object.isRequired,
  stickyHeader: PropTypes.bool,
  sortByColumnProp: PropTypes.string,
  sortDirectionProp: PropTypes.string,
  selectedItem: PropTypes.number,
  showHeader: PropTypes.bool,
};

const scrollDirections = {
  up: 'up',
  down: 'down',
};

export { sortingOrder, TablePropTypes, scrollDirections };
