import { isEmpty } from 'lodash';
import { colorPalette } from '../../../../../../common/form/constants/constants';
import Helpers from '../../../../../../common/helpers';
import Icon from '../../../../../../common/icon/components/icon';
import PointerDot from '../../../../../../common/pointer-dot/pointer-dot';
import RenderIf from '../../../../../../common/render-if/components/render-if';
import { fields } from '../../../../constants/constants';

const getValueWithSearchTerm = (value, searchTerm) => {
  return isEmpty(value) ? '-' : searchTerm ? Helpers.getSearchTermText(value, searchTerm) : value;
};
const getComponentNameCell = (item, value, searchTerm) => {
  return (
    <div className="assign-components__name">
      {item.Colour ? <div className="assign-components__name__status-icon" style={{ backgroundColor: colorPalette[item.Colour] }} /> : null}
      <div className="f-primary assign-components__name__text">{Helpers.getSearchTermText(value, searchTerm)}</div>
    </div>
  );
};
export const formatLevelCells = (value, type, searchTerm, row, onClick, t, pointerDotID) => {
  if (type === fields.icon) {
    return <Icon name="folder" size="sm" />;
  } else if (type === fields.code) {
    return getValueWithSearchTerm(value, searchTerm);
  } else if (type === fields.name) {
    return (
      <div className="change_hierarchy_modal_name">
        {getValueWithSearchTerm(value, searchTerm)}
        <RenderIf if={pointerDotID === row.ID}>
          <PointerDot type="success" text={t('COMPONENT_CHANGE_HIERARCHY.CURRENT_LOCATION')} ignoreTheme />
        </RenderIf>
      </div>
    );
  } else {
    return value ? value : '-';
  }
};

export const formatComponentCells = (value, type, searchTerm, row, _, t) => {
  if (type === fields.icon) {
    return <Icon name="components" active size="sm" />;
  } else if (type === fields.code || type === fields.type) {
    return getValueWithSearchTerm(value, searchTerm);
  } else if (type === fields.name) {
    return value ? getComponentNameCell(row, value, searchTerm) : '-';
  } else if (type === fields.critical) {
    return value ? t('COMPONENT_DETAILS.CRITICAL_YES') : t('COMPONENT_DETAILS.CRITICAL_NO');
  } else {
    return value ? value : '-';
  }
};
