import React from 'react';
import Icon from '../../icon/components/icon';
import '../styles/collapsible-info.scss';
import { connect } from 'react-redux';
import { setCollapsibleInfoIsExpanded, resetCollapsibleInfo } from '../actions/action-creators';
//TODO: Add collapsibleInfo to the storybook
const CollapsibleInfo = ({ isDisplayed, isExpanded, CustomContent, customContentProps, title, setCollapsibleInfoIsExpanded, resetCI }) => {
  const handleCollapseClick = () => {
    setCollapsibleInfoIsExpanded(!isExpanded);
  };

  const handleCloseClick = e => {
    e?.preventDefault();
    resetCI();
  };

  if (!isDisplayed) return <></>;

  return (
    <div className="collapsable-info default-background">
      <div className="collapsable-info__header" onClick={handleCollapseClick}>
        <div className="collapsable-info__header__title">
          <h5 className="f-primary">{title}</h5>
        </div>
        <div className="collapsable-info__header__actions">
          <Icon name="chevron-up" size="md" className={`collapse-icon ${isExpanded ? 'expanded' : ''}`} onClick={handleCollapseClick} />
          <Icon name="close" size="md" className="close-icon" onClick={handleCloseClick} />
        </div>
      </div>
      <div className={`collapsable-info__content ${isExpanded ? 'expanded' : 'collapsed'}`}>{<CustomContent {...customContentProps} />}</div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  setCollapsibleInfoIsExpanded: isExpanded => dispatch(setCollapsibleInfoIsExpanded(isExpanded)),
  resetCI: () => dispatch(resetCollapsibleInfo()),
});

const mapStateToProps = state => ({
  isExpanded: state.collapsableInfoReducer.isExpanded,
  isDisplayed: state.collapsableInfoReducer.isDisplayed,
  CustomContent: state.collapsableInfoReducer.customContent,
  customContentProps: state.collapsableInfoReducer.customContentProps,
  title: state.collapsableInfoReducer.title,
});

export default connect(mapStateToProps, mapDispatchToProps)(CollapsibleInfo);
