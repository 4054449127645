import PropTypes from 'prop-types';
import React from 'react';

import ExpandableSection from '../../../../../../../common/expandable-section/components/expandable-section';
import Icon from '../../../../../../../common/icon/components/icon';
import { fields, initialValuesProp } from '../../constants/isolation-components-constants';
import IsolationComponentsItem from './isolation-components-item';

import EmptyState from '../../../../../../../common/empty-state-v2/components/empty-state';
import RenderIf from '../../../../../../../common/render-if/components/render-if';
import InfoTooltip from '../../../../../../../common/tooltip/components/info-tooltip';
import '../../../../../styles/isolation-components-section.scss';

/*
 DUPLICATE OF:
  src/app/inspections/components/right-toolbar/common/section-isolation-component.js
 */
const IsolationComponents = ({ module, disabled, handleCustomAction, sectionAction, initialValues }, { t }) => {
  const components = module[initialValuesProp];

  if (!components || !components.length) {
    return (
      <EmptyState
        buttonAction={() => handleCustomAction(sectionAction, module, initialValues)}
        buttonText={t('WORK_ORDER.ADD_COMPONENTS')}
        transparent
        showButton={!disabled}
        emptyStateText={t('NO_EQUIPMENT_EMPTY_STATE')}
      />
    );
  }

  return (
    <div className="isolation-components-section">
      {components && components.length > 0 && (
        <>
          {components.map((component, index) => (
            <div className="component-item-header" key={`section-component-item-${index}`}>
              <ExpandableSection
                CustomComponent={({ isExpanded }) => (
                  <div className="component-header">
                    <h6 className="f-primary bold">{`${index + 1}.  ${component[fields.componentName.name]}`}</h6>
                    <div className="component-actions">
                      <RenderIf if={component[fields.componentName.critical] === true}>
                        <InfoTooltip
                          className="critical-tooltip"
                          offsetY={6}
                          offsetX={6}
                          customComponent={<span>{t('CRITICAL_EQUIPMENT.TITLE')}</span>}
                          Component={() => <Icon name="critical-hazard" handleHover={false} size="sm" />}
                          componentProps={{ title: '' }}
                          containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
                        />
                      </RenderIf>{' '}
                      <Icon name={isExpanded ? 'chevron-up' : 'chevron-down'} className="component-header-toggle" />
                    </div>
                  </div>
                )}
                className="expanded-section"
                customExpanded={component.isExpanded}
                hideExpandAction
              >
                <IsolationComponentsItem component={component} componentIndex={index} />
              </ExpandableSection>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

IsolationComponents.propTypes = {
  module: PropTypes.shape({
    Components: PropTypes.arrayOf(
      PropTypes.shape({
        ComponentID: PropTypes.number,
        Name: PropTypes.string,
        DesiredState: PropTypes.string,
        IsolationMethod: PropTypes.string,
        NormalPosition: PropTypes.string,
        DeisolationOrder: PropTypes.number,
      })
    ),
  }),
};

IsolationComponents.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default IsolationComponents;
