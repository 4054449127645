import html2canvas from 'html2canvas';

class ScreenshotHelpers {
  static safeExecuteWithCanvasRef = (func, querySelector, html2canvasOptions = {}, retries = 0) => {
    if (retries > 3) {
      console.warn('Unable to find canvas reference');
      return;
    }
    setTimeout(() => {
      const htmlElement = typeof querySelector === 'function' ? querySelector() : document.querySelector(querySelector);
      html2canvas(htmlElement, html2canvasOptions).then(canvas => {
        if (canvas && canvas.width > 0 && canvas.height > 0) {
          htmlElement.classList.add('screenshot'); // Triggers screenshot animation

          func(canvas);
          return;
        } else {
          this.safeExecuteWithCanvasRef(func, querySelector, html2canvasOptions, retries + 1);
        }
      });
    }, 500 * retries);
  };

  static takeScreenshot = (querySelector, onComplete, onFinish, options = {}) => {
    // const htmlElement = typeof querySelector === 'function' ? querySelector() : document.querySelector(querySelector);
    // htmlElement.classList.remove('screenshot');
    this.safeExecuteWithCanvasRef(
      canvas => {
        const url = this.canvasToDataUrl(canvas);

        onComplete && onComplete(url);

        setTimeout(() => {
          onFinish && onFinish();
          const htmlElement = typeof querySelector === 'function' ? querySelector() : document.querySelector(querySelector);
          if (htmlElement?.classList) {
            htmlElement?.classList.remove('screenshot');
          }
        }, 600); // screenshot animation delay
      },
      querySelector,
      options
    );
  };

  static canvasToDataUrl = canvas => {
    return canvas.toDataURL('png');
  };
}

export default ScreenshotHelpers;
