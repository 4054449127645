import routesConstants from './routes';
import { Api } from '../../core/api';
import { API_BASE_URL } from '../../common/constants';

const getMfaTypes = async () => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_MFA_TYPES}`);
};

const setupAuthenticatior = async () => {
  return await Api.get(`${API_BASE_URL}${routesConstants.SETUP_AUTHENTICATOR}`);
};

const verifyAuthenticator = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.VERIFY_AUTHENTICATOR}`, params);
};

const setupEmail = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.SETUP_EMAIL}`, params);
};
const verifySetupEmail = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.VERIFY_SETUP_EMAIL}`, data);
};

export default {
  getMfaTypes,
  setupAuthenticatior,
  verifyAuthenticator,
  setupEmail,
  verifySetupEmail,
};
