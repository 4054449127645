/**
 * This hook is used globally for handling unsaved changes across all forms in our application,
 * it can be integrated at a higher level in our component hierarchy,
 * such as in our main application component or
 * a layout component that wraps around our application's routes.
 */
import { isEqual } from 'lodash';
import { useEffect, useRef } from 'react';

const useConfirmOnInspectionExit = props => {
  const { title, message, onConfirm, onCancel, router, route, isDirty, clearUnsavedChangesDirty } = props;
  const confirmLeaveRef = useRef(null);

  useEffect(() => {
    const handleBeforeUnload = event => {
      /**
       * `&& !confirmLeaveRef.current` prevents double load of
       * unsaved changes popup if leave is confirmed.
       * This scenario happens, for example, when going from 3D mode to fullscreen mode.
       */
      if (isDirty && !confirmLeaveRef.current) {
        event.returnValue = message; // Standard for most browsers
        event.preventDefault(); // For Chrome
        return message; // For some browsers
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty, message, router, route]);

  useEffect(() => {
    if (router && route) {
      const isPathnameEqual = (prevPathname, nextPathname) => {
        return isEqual(prevPathname, nextPathname);
      };
      const routerWillLeave = nextLocation => {
        const { location: prevLocation } = router;
        const { query: prevQuery, pathname: prevPathname } = prevLocation;
        const { query: nextQuery, pathname: nextPathname, state: nextState, search: nextSearch } = nextLocation;
        const { type: prevType } = prevQuery;
        const { type: nextType } = nextQuery;
        const prevQueryItem = !isNaN(parseInt(prevQuery.selected_item, 10)) ? parseInt(prevQuery.selected_item, 10) : null;
        const nextQueryItem = !isNaN(parseInt(nextQuery.selected_item, 10)) && parseInt(nextQuery.selected_item, 10) > 0 ? parseInt(nextQuery.selected_item, 10) : null;
        confirmLeaveRef.current = true;
        if (isDirty && !prevQueryItem) {
          clearUnsavedChangesDirty();
          return;
        }

        const isCreateOrDeleteAction = prevQueryItem && !nextQueryItem && prevType === nextType;

        if (isDirty && isCreateOrDeleteAction && nextState !== 'explicitCreateAction') {
          confirmLeaveRef.current = false;
        }

        /* The condition below is checking if there are unsaved changes (`isDirty`) and if the selected
        item has changed between the previous and next query parameters (`prevQueryItem !==
        nextQueryItem`). Additionally, it checks if the pathname of the previous and next locations
        are equal using the `isPathnameEqual` function. `nextSearch === ''`is added to check if 
        the User is trying to navigate outside of the project page (like Profile, People, Projects pages) */
        if (isDirty && confirmLeaveRef.current && ((prevQueryItem !== nextQueryItem) === isPathnameEqual(prevPathname, nextPathname) || nextSearch === '')) {
          const updatedMessage = nextState === 'explicitCreateAction' ? 'If you continue you will need to select the object from toolbox again in order to create it.' : message;
          const confirmLeavePrompt = window.confirm(`${title}\n\n${updatedMessage}`);
          if (confirmLeavePrompt) {
            clearUnsavedChangesDirty();
            confirmLeaveRef.current = true;
            if (typeof onConfirm === 'function') {
              onConfirm();
            }
            return true;
          } else {
            confirmLeaveRef.current = false;
            if (typeof onCancel === 'function') {
              onCancel();
            }
            return false; // Explicitly return false to block the navigation
          }
        }
      };

      const unlisten = router.listenBefore(routerWillLeave);

      return () => {
        unlisten();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, route, isDirty, message]);
};

export default useConfirmOnInspectionExit;
