import { setTeamsData, amendTeamsData, handleUpdateTeam, handleRemoveTeam, handleTeamsLoading, handleAddTeam } from './action-creators';
import teamsApi from '../../../api/teams/actions';
import { teamFields, userFields } from '../constants/team-constants';
import ReducerHelpers from '../../../common/reducer-helpers';

export const getTeamsData = (params, callback, loadMore = false) => {
  return async dispatch => {
    try {
      dispatch(handleTeamsLoading(true));
      const res = await teamsApi.getTeams(params);
      let { Data } = res.data;
      const data = Data.Teams;
      if (loadMore) {
        dispatch(amendTeamsData(data));
      } else {
        dispatch(setTeamsData(data));
      }

      dispatch(handleTeamsLoading(false));

      callback && callback(Data.HasNext);
    } catch (err) {
      dispatch(handleTeamsLoading(false));
    }
  };
};

export const createTeam = (params, callback) => {
  return async dispatch => {
    try {
      const res = await teamsApi.createTeam(params);
      callback && callback();
      dispatch(handleAddTeam(res.data.Data));
    } catch (err) {}
  };
};

export const updateTeam = (params, callback) => {
  return async dispatch => {
    try {
      await teamsApi.updateTeam(params);
      callback && callback();
      dispatch(handleUpdateTeam(params));
    } catch (err) {
      callback && callback();
    }
  };
};

export const deleteTeam = (params, callback) => {
  return async dispatch => {
    try {
      await teamsApi.deleteTeam({ [teamFields.id]: params[teamFields.id] });
      callback && callback();
      dispatch(handleRemoveTeam(params));
    } catch (err) {
      callback && callback();
    }
  };
};

export const addTeamUser = (team, user, callback) => {
  return async dispatch => {
    try {
      await teamsApi.addTeamUser({ [teamFields.teamId]: team[teamFields.id], [teamFields.userId]: user[userFields.id] });
      const teams = [...(team[teamFields.users] || []), user];

      callback && callback(teams);

      dispatch(handleUpdateTeam({ ...team, [teamFields.users]: teams }));
    } catch (err) {
      // callback && callback();
    }
  };
};

export const deleteTeamUser = (team, user, callback) => {
  return async dispatch => {
    try {
      await teamsApi.deleteTeamUser({ [teamFields.teamId]: team[teamFields.id], [teamFields.userId]: user[userFields.id] });

      const teams = ReducerHelpers.removeItemByProp(team[teamFields.users], user, userFields.id);
      callback && callback(teams);

      callback && callback(teams);
      dispatch(handleUpdateTeam({ ...team, [teamFields.users]: teams }));
    } catch (err) {
      // callback && callback();
    }
  };
};

export const getTeamUsers = (id, callback) => {
  return async dispatch => {
    try {
      const res = await teamsApi.getTeamUsers({ [teamFields.teamId]: id });

      callback && callback(res.data.Data);
    } catch (err) {
      // callback && callback();
    }
  };
};
