import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../icon/components/icon';

const SectionActions = ({
  currentIndex,
  isLocked,
  showExpandableAction,
  hideReorder,
  sortDownDisabled,
  sortUpDisabled,
  deleteDisabled,
  isSectionExpanded,
  toggleSectionExpanded,
  handleDeleteSection,
  handleSort,
  isLoading,
  stickActions,
  className,
  areQuestionsActions,
}) => {
  const onSort = (isDisabled, oldIndex, newIndex) => {
    if (isDisabled || !handleSort || isLoading) {
      return;
    }

    handleSort(oldIndex, newIndex);
  };

  return (
    <div
      className={`section__container__actions ${className || ''} ${isLocked ? 'section__container__actions-locked-section' : 'section__container__actions-unlocked-section'} ${
        stickActions ? 'section__container__actions-sticked' : ''
      } ${areQuestionsActions ? ' section__container__questions-actions' : ''} ${stickActions && !isLocked ? 'section__container__actions-unlocked-sticked' : ''}`}
    >
      {showExpandableAction && (
        <Icon name={isSectionExpanded ? 'chevron-up' : 'chevron-down'} className="section__clickable section__container__actions__expandable-icon" onClick={toggleSectionExpanded} />
      )}
      {!hideReorder && (
        <>
          <Icon name="arrow-down" className={`section__clickable ${sortUpDisabled || isLoading ? 'section__disabled' : ''}`} onClick={() => onSort(sortUpDisabled, currentIndex, currentIndex + 1)} />
          <Icon name="arrow-up" className={`section__clickable ${sortDownDisabled || isLoading ? 'section__disabled' : ''}`} onClick={() => onSort(sortDownDisabled, currentIndex, currentIndex - 1)} />
        </>
      )}
      {!isLocked && <Icon name="close" className={`section__clickable section__container__actions__close-icon ${deleteDisabled ? 'section__disabled' : ''}`} onClick={handleDeleteSection} />}
    </div>
  );
};

SectionActions.propTypes = {
  currentIndex: PropTypes.number.isRequired,
  isLocked: PropTypes.bool,
  showExpandableAction: PropTypes.bool,
  hideReorder: PropTypes.bool,
  sortDownDisabled: PropTypes.bool,
  sortUpDisabled: PropTypes.bool,
  deleteDisabled: PropTypes.bool,
  isExpandable: PropTypes.bool,
  isSectionExpanded: PropTypes.bool,
  toggleSectionExpanded: PropTypes.func,
  handleDeleteSection: PropTypes.func,
  handleSort: PropTypes.func,
  isLoading: PropTypes.bool,
  stickActions: PropTypes.bool,
  className: PropTypes.string,
  areQuestionsActions: PropTypes.bool,
};

export default SectionActions;
