import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { reduxForm, Field } from 'redux-form';

import { keys } from 'lodash';

import Button from '../../../../../common/form/components/button';
import DatePicker from '../../../../../common/form/components/date-picker';

import Helpers from '../../../../../common/helpers';

import { FORMS } from '../../../../../common/constants';

import { filterFields } from '../../../constants/work-order-constants';

import '../../../styles/defects-filter.scss';

class RegimeWorkordersFilter extends Component {
  render() {
    const { t } = this.context;

    const { handleSubmit, resetFilter, formValues, submitForm } = this.props;

    return (
      <form
        className="filter-form"
        onSubmit={handleSubmit(vals => {
          submitForm(Helpers.removeUnusedKeys(vals));
        })}
      >
        <div className="scrollable">
          <div className="grid">
            <Field
              disabledKeyboardNavigation={true}
              dateFormat="MMMM d, yyyy"
              id={filterFields.startFrom}
              name={filterFields.startFrom}
              component={DatePicker}
              maxDate={formValues?.[filterFields.startTo] || null}
              popperPlacement="bottom-end"
              placeholder={'WORKORDER_FILTERS.DATE_FROM'}
              label={'WORKORDER_FILTERS.DATE_FROM'}
              isClearable={true}
            />
            <Field
              disabledKeyboardNavigation={true}
              dateFormat="MMMM d, yyyy"
              id={filterFields.startTo}
              name={filterFields.startTo}
              component={DatePicker}
              minDate={formValues?.[filterFields.startDateFrom] || null}
              popperPlacement="bottom-end"
              placeholder={'WORKORDER_FILTERS.DATE_TO'}
              label={'WORKORDER_FILTERS.DATE_TO'}
              isClearable={true}
            />
          </div>
          <div className="grid">
            <Field
              disabledKeyboardNavigation={true}
              dateFormat="MMMM d, yyyy"
              id={filterFields.endFrom}
              name={filterFields.endFrom}
              component={DatePicker}
              maxDate={formValues?.[filterFields.endTo] || null}
              popperPlacement="bottom-end"
              placeholder={'WORKORDER_FILTERS.END_FROM'}
              label={'WORKORDER_FILTERS.END_FROM'}
              isClearable={true}
            />
            <Field
              disabledKeyboardNavigation={true}
              dateFormat="MMMM d, yyyy"
              id={filterFields.endTo}
              name={filterFields.endTo}
              component={DatePicker}
              minDate={formValues?.[filterFields.endFrom] || null}
              popperPlacement="bottom-end"
              placeholder={'WORKORDER_FILTERS.END_TO'}
              label={'WORKORDER_FILTERS.END_TO'}
              isClearable={true}
            />
          </div>
          <div className="grid">
            <Field
              disabledKeyboardNavigation={true}
              dateFormat="MMMM d, yyyy"
              id={filterFields.dueFrom}
              name={filterFields.dueFrom}
              component={DatePicker}
              maxDate={formValues?.[filterFields.dueTo] || null}
              popperPlacement="bottom-end"
              placeholder={'WORKORDER_FILTERS.DUE_FROM'}
              label={'WORKORDER_FILTERS.DUE_FROM'}
              isClearable={true}
            />
            <Field
              disabledKeyboardNavigation={true}
              dateFormat="MMMM d, yyyy"
              id={filterFields.dueTo}
              name={filterFields.dueTo}
              component={DatePicker}
              minDate={formValues?.[filterFields.dueFrom] || null}
              popperPlacement="bottom-end"
              placeholder={'WORKORDER_FILTERS.DUE_TO'}
              label={'WORKORDER_FILTERS.DUE_TO'}
              isClearable={true}
            />
          </div>
        </div>

        <div className="buttons">
          <Button type="button" variant="gray-outline" text={t('RESET_ALL_FILTERS')} onClick={resetFilter} />
          <Button text={t('APPLY_FILTERS', { count: keys(Helpers.removeUnusedKeys(formValues)).length || 0 })} />
        </div>
      </form>
    );
  }
}
RegimeWorkordersFilter.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { initialValues }) => {
  return {
    formValues: state.form[FORMS.regimeWorkordersFilter]?.values || {},
  };
};

RegimeWorkordersFilter = reduxForm({
  form: FORMS.regimeWorkordersFilter,
  enableReinitialize: true,
})(RegimeWorkordersFilter);

export default connect(mapStateToProps, null)(RegimeWorkordersFilter);
