import React from 'react';
import PropTypes from 'prop-types';
import { isArray, isEmpty } from 'lodash';

import RenderIf from '../../render-if/components/render-if';
import Icon from '../../../common/icon/components/icon';

const HistoryItemDisplay = ({ items, from, to, columnName, translatable, ...rest }, { t }) => {
  const formatArrayValue = array => {
    const { decimals } = rest;

    return decimals ? array.map(item => parseFloat(item).toFixed(decimals)).join(', ') : array.join();
  };

  const extractValue = value => {
    try {
      let obj = JSON.parse(value);
      const item = items[columnName];

      if ((obj instanceof Object && isEmpty(obj)) || (item.subProp && !obj[item.subProp])) {
        return '';
      }

      // logic fro extracting coordinates because they are in an array
      obj = item && item.subProp && obj[item.subProp] ? obj[item.subProp] : obj;
      const result = isArray(obj) ? formatArrayValue(obj) : obj.toString();

      return result;
    } catch {
      return value;
    }
  };

  const fromValue = extractValue(from),
    toValue = extractValue(to);

  return (
    <div className="history-item-display">
      <RenderIf if={fromValue && fromValue !== 'null'}>
        <p className="f-primary history-item-display__value">{translatable ? t(`HISTORY_ITEM_DISPLAY.${fromValue}`) : fromValue}</p>
      </RenderIf>
      <RenderIf if={fromValue && fromValue !== 'null'}>
        <Icon name="arrow-right" size="sm" handleHover={false} className="history-item-display__icon" />
      </RenderIf>
      <p className="f-primary  history-item-display__value">{translatable ? t(`HISTORY_ITEM_DISPLAY.${toValue}`) : toValue}</p>
    </div>
  );
};

HistoryItemDisplay.contextTypes = {
  t: PropTypes.func.isRequired,
};

HistoryItemDisplay.propTypes = {
  items: PropTypes.object.isRequired,
  from: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  to: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  columnName: PropTypes.string,
};

export default HistoryItemDisplay;
