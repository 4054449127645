import { debounce, find, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FEATURES } from '../../constants';
import SearchInput from '../../input/components/search-input';
import LoadMore from '../../load-more/components/load-more';
import RenderIf from '../../render-if/components/render-if';
import { additionalFields, componentsPickerSelectKeys } from '../constants/question-constants';
import '../styles/question-field.scss';

const ComponentPicker = (props, { t }) => {
  const [filteredComponents, setFilteredComponents] = useState([]);

  // only handles for DMS related search API triggering
  useEffect(() => {
    const { components, searchApiRequired } = props;

    // For small arrays like these (max 20 items per array), the performance impact of using _.isEqual()
    // is likely negligible, and the convenience it provides for deep comparison outweighs
    // any minor performance considerations.
    if (searchApiRequired && !isEqual(filteredComponents, components)) {
      setFilteredComponents(components);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.components]);

  const handleComponentSearch = value => {
    const { components, searchApiRequired, handleAPIComponentSearch } = props;
    const lowerCaseValue = value.toLowerCase();

    if (searchApiRequired) {
      //  150 max chars validation, as agreed with the API since the max Name and Code fields length can be 150
      if (value?.length >= 150) return;
      handleAPIComponentSearch(lowerCaseValue);
    } else {
      const filtered = value ? components.filter(item => Object.values(componentsPickerSelectKeys).some(key => item[key].toString().toLowerCase().includes(lowerCaseValue))) : components;
      setFilteredComponents(filtered);
    }
  };

  const handleComponentSearchDebounce = debounce(handleComponentSearch, 300);

  const onChange = value => {
    const { handleComponentSelected, questionId } = props;
    value.ComponentName = value[componentsPickerSelectKeys.textKey];
    value.ComponentID = value[componentsPickerSelectKeys.valueKey];
    const componentIds = [value];

    if (typeof handleComponentSelected === 'function') {
      handleComponentSelected(questionId, componentIds);
    }
  };

  // TODO: redundant code, requires refactoring (appears on component-picker-pdf)
  const handleInputFocus = () => {
    if (!filteredComponents || !filteredComponents.length) {
      const { searchApiRequired } = props;
      if (searchApiRequired) {
        const { handleAPIComponentSearch } = props;
        handleAPIComponentSearch('');
      } else {
        const { components } = props;
        setFilteredComponents(components);
      }
    }
  };

  const {
    selectedComponents,
    wrapperClassName,
    isInline,
    fullWidth,
    withRightPadding,
    withLeftPadding,
    style,
    handleComponentPicker3D,
    handleComponentPickerPDF,
    handleComponentPickerCH,
    searchInputLabel,
    searchPlaceholder,
    searchApiRequired,
    componentsFilterProps,
    loadMoreOnClick,
    keepComponentsDropdownVisible,
    componentsLoading,
    additionalOptions,
  } = props;
  const { component } = additionalFields;
  const isCHVisible = FEATURES.componentHierarchy.chooseFromCH.visible;

  return (
    <div
      className={`question-field ${wrapperClassName} ${fullWidth ? 'full-width' : ''} ${isInline ? 'inline' : ''} ${withRightPadding ? 'with-right-padding' : ''} ${
        withLeftPadding ? 'with-left-padding' : ''
      }`}
      style={style}
    >
      <label className="question-field__label">{t(searchInputLabel || component.label)}</label>
      <SearchInput
        onChange={e => handleComponentSearchDebounce(e.target.value)}
        placeholder={t(searchPlaceholder || 'SECTIONS.QUESTION_COMPONENT_SEARCH')}
        includeDropdown={true}
        keepDropdownVisible={keepComponentsDropdownVisible}
        items={filteredComponents}
        emptyStateLabel={'SECTIONS.QUESTION_COMPONENT_SEARCH_PLACEHOLDER'}
        onInputFocus={handleInputFocus}
        isDropdownDataLoading={componentsLoading}
        renderItem={(item, index) => {
          const isDisabled = find(selectedComponents, i => item[componentsPickerSelectKeys.valueKey] === i.ComponentID);
          return (
            <>
              <p key={index} id={`row-${index}`} className={`pointer ${isDisabled ? 'disabled' : ''}`} onClick={isDisabled ? () => null : () => onChange(item)}>
                {item[component.name]}
              </p>
              {searchApiRequired && filteredComponents?.length > 0 && filteredComponents.length - index === 1 && componentsFilterProps.HasNext && (
                <LoadMore
                  disabled={!componentsFilterProps.HasNext}
                  label="COMPONENT_HISTORY.LOAD_MORE"
                  showButton
                  buttonVariant="success-outline"
                  buttonWidth="sm"
                  onClick={loadMoreOnClick}
                  showTotalItems={false}
                />
              )}
            </>
          );
        }}
      />
      <div className="question-field__actions">
        <span className="choose-from-label">{t('CHOOSE_FROM')}</span>
        <div onClick={handleComponentPicker3D} className={`question-field__actions__left`}>
          <p className="f-secondary-green">{t('3D_MODEL')}</p>
        </div>
        <div onClick={handleComponentPickerPDF} className={`question-field__actions__${isCHVisible ? 'middle' : 'right'} `}>
          <p className="f-secondary-green">{t('DRAWING')}</p>
        </div>
        <RenderIf if={isCHVisible}>
          <div onClick={handleComponentPickerCH} className={`question-field__actions__${isCHVisible && additionalOptions ? 'middle' : 'right'} `}>
            <p className="f-secondary-green">{t('LIST')}</p>
          </div>
        </RenderIf>
        <RenderIf if={additionalOptions && additionalOptions.length > 0}>
          <>
            {additionalOptions?.map(({ text, onClickHandler }, index) => (
              <div key={index} onClick={() => onClickHandler(onChange)} className="question-field__actions__right">
                <p className="f-secondary-green">{text}</p>
              </div>
            ))}
          </>
        </RenderIf>
      </div>
    </div>
  );
};

ComponentPicker.contextTypes = {
  t: PropTypes.func.isRequired,
};

ComponentPicker.propTypes = {
  questionId: PropTypes.number,
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
  components: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
    })
  ),
  selectedComponents: PropTypes.arrayOf(
    PropTypes.shape({
      ComponentID: PropTypes.number.isRequired,
      ComponentName: PropTypes.string.isRequired,
    })
  ),
  isInline: PropTypes.bool,
  fullWidth: PropTypes.bool,
  withRightPadding: PropTypes.bool,
  withLeftPadding: PropTypes.bool,
  style: PropTypes.object,
  handleComponentSelected: PropTypes.func,
  searchInputLabel: PropTypes.string,
  searchApiRequired: PropTypes.bool,
  handleAPIComponentSearch: PropTypes.func,
  componentsFilterProps: PropTypes.object,
  loadMoreOnClick: PropTypes.func,
  componentsSearchText: PropTypes.string,
  keepComponentsDropdownVisible: PropTypes.bool,
  componentsLoading: PropTypes.bool,
  additionalOptions: PropTypes.shape({
    onClickHandler: PropTypes.func,
    text: PropTypes.string,
  }),
};

ComponentPicker.defaultProps = {
  wrapperClassName: '',
  className: '',
  components: [],
  selectedComponents: [],
  isInline: false,
  fullWidth: false,
  withRightPadding: false,
  withLeftPadding: false,
  style: {},
  searchApiRequired: false,
  handleAPIComponentSearch: searchTerm => null,
  componentsFilterProps: {},
  loadMoreOnClick: () => null,
  componentsSearchText: '',
  keepComponentsDropdownVisible: false,
  componentsLoading: false,
  additionalOptions: null,
};

export default ComponentPicker;
