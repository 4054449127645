import { find, isEmpty, isObject } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Stepper from '../../../../../common/stepper/components/stepper';
import IssuePermitForm from './issue-permit-form';

import { questionTypes } from '../../../../../common/question-components/constants/question-constants';
import { moduleSectionsFields } from '../../../constants/constants';
import { questionFields } from '../../right-toolbar/isolation-certificate-details/constants/change-status-constants';
import { formConstants, keyFields, steps } from '../constants/issue-permit-constants';
import { initialValuesProp, singleKeyFields } from '../constants/keybox-constants';
import { statuses } from '../constants/permit-constants';

import '../../../styles/issue-permit-modal.scss';
// TODO: refactor to functional component
class IssuePermitModal extends Component {
  constructor(props) {
    super(props);
    const keyboxes = props.module[initialValuesProp];
    const activeStep = keyboxes && keyboxes.length > 0 ? steps.firstStep : steps.secondStep;

    this.state = {
      isPreviewMode: props.isPreviewMode,
      activeStep,
      lastStep: steps.secondStep,
      stepperData: [
        {
          name: 'PERMIT.ISSUE_PERMIT.STEP_1',
          stepValue: steps.firstStep,
          description: 'PERMIT.ISSUE_PERMIT.STEP_1_DESC',
          disabled: false,
        },
        {
          name: 'PERMIT.ISSUE_PERMIT.STEP_3',
          stepValue: steps.secondStep,
          description: 'PERMIT.ISSUE_PERMIT.STEP_3_DESC',
          disabled: false,
        },
      ],
    };
  }

  handleSubmit = values => {
    let { activeStep, lastStep } = this.state;
    const { answers } = this.state;
    const { user, module, handleStatusChanged } = this.props;
    const sections = module[moduleSectionsFields.sections];
    const { fields } = formConstants;

    if (activeStep === lastStep) {
      const contractorId = values[fields.contractor.name] && isObject(values[fields.contractor.name]) ? values[fields.contractor.name].ID : values[fields.contractor.name];
      const section = find(sections, { Name: statuses.issued.sectionName });
      const questions = Object.assign([], section?.SectionQuestions);

      const data = {
        [fields.answers]: answers,
        [fields.contractorId]: contractorId,
        [fields.sectionId]: section.ID,
        ...values,
      };

      const assigneeQuestion = find(questions, { [questionFields.type]: questionTypes.SIGNATURE.valueId, [questionFields.name]: questionTypes.SIGNATURE.assigneeName });
      const witnessQuestion = find(questions, { [questionFields.type]: questionTypes.SIGNATURE.valueId, [questionFields.name]: questionTypes.SIGNATURE.witnessName });

      data[fields.assigneeSignature.name] = {
        QuestionID: assigneeQuestion?.[questionFields.id],
        PersonID: contractorId,
        Payload: values[fields.assigneeSignature.name],
      };

      data[fields.witnessSignature.name] = {
        QuestionID: witnessQuestion?.[questionFields.id],
        PersonID: user.UserID,
        Payload: values[fields.witnessSignature.name],
      };

      handleStatusChanged(data);
    }
    this.setStep(true);
  };

  setStep = forward => {
    const { closeAction, module } = this.props;
    let { activeStep, lastStep } = this.state;
    const keyboxes = module[initialValuesProp];
    const nextStep = forward ? ++activeStep : --activeStep;

    if (nextStep < steps.firstStep || nextStep > lastStep) {
      closeAction();
      return;
    }

    if (nextStep === steps.firstStep && isEmpty(keyboxes)) {
      return;
    }

    this.setState({ activeStep: nextStep });
  };

  render() {
    const { t } = this.context;
    const { stepperData, activeStep, lastStep } = this.state;
    const { className, module, disabled, signatureRequired, status, contractors = [] } = this.props;

    const activeStepObj = find(stepperData, item => item.stepValue === activeStep);

    const keyboxes = module[initialValuesProp];
    return (
      <div className={`issue-permit-modal ${className || ''}`}>
        <Stepper {...{ stepperData, activeStep }} />

        <p className="f-primary step-desc">{(activeStepObj && t(activeStepObj.description)) || ''}</p>
        <IssuePermitForm
          onSubmit={this.handleSubmit}
          initialValues={{
            [formConstants.fields.assignedKeys]: keyboxes.map(keybox => ({ [keyFields.id]: null, [keyFields.keyboxId]: keybox[singleKeyFields.id] })),
          }}
          keyboxes={keyboxes}
          stepAction={this.setStep}
          activeStep={activeStep}
          lastStep={lastStep}
          contractors={contractors}
          status={status}
          signatureRequired={signatureRequired}
          disabled={disabled}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userReducer,
});

const mapDispatchToProps = dispatch => ({});

IssuePermitModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(IssuePermitModal);
