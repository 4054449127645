import React, { useEffect } from 'react';
import TableTemplate from '../../../../../common/double-table/components/table-template';
import Button from '../../../../../common/form/components/button';
import { modules } from '../../../constants/constants';
import { fields, tableConfig } from './constants/notification-WO-tab';
import './styles/notification-WO-tab.scss';
import PropTypes from 'prop-types';
import { notificationWOTableFormatting } from './actions/table-formatting';
import { getNotificationWorkOrders } from '../../../actions/notification-actions';
import { connect } from 'react-redux';
import RenderIf from '../../../../../common/render-if/components/render-if';
import EmptyState from '../../../../../common/empty-state/components/empty-state';
import Helpers from '../../../../../common/helpers';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../../common/permissions-constants';
import { setNotificationWorkOrders } from '../../../actions/action-creators';

const NotificationWorkOrdersTab = (
  { notificationWorkOrders, getNotificationWorkOrders, setNotificationWorkOrders, user, openWorkOrderCreationModal, notificationType, notificationName, handleActivePage, queryItem },
  { t }
) => {
  // checks if the User has WorkOrder:View role
  const userHasWOViewPermission = Helpers.hasAccess({ user, visibleFor: PERMISSIONS[PERMISSION_TYPES.workOrders].view.name });
  const userHasWOCreatePermission = Helpers.hasAccess({ user, visibleFor: PERMISSIONS[PERMISSION_TYPES.workOrders].create.name });

  useEffect(() => {
    if (userHasWOViewPermission) {
      getNotificationWorkOrders(queryItem);
    }

    return () => {
      // cleanup Work Orders in the tab
      setNotificationWorkOrders([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onWorkOrderClick = workOrder => {
    handleActivePage(modules.workorders, workOrder[fields.id]);
  };

  return (
    <div>
      <RenderIf if={userHasWOViewPermission}>
        <div className="notification-WO-tab__create-button__container">
          <Button
            type="button"
            text={t('NOTIFICATION_DETAILS.CREATE_WORK_ORDER')}
            variant="success-outline"
            onClick={() => openWorkOrderCreationModal(notificationType, notificationName)}
            keepOriginalText
            className="notification-WO-tab__create-button__container__button-width"
            disabled={!userHasWOCreatePermission}
          />
        </div>
        <div className="notification-WO-tab__table">
          <TableTemplate
            data={notificationWorkOrders}
            handleFormatCells={notificationWOTableFormatting}
            showSearchBar={false}
            tableConfig={tableConfig}
            showInfo={false}
            onRowClick={onWorkOrderClick}
            noDataText={t('NOTIFICATIONS_FORM.WO_EMPTY_STATE')}
          />
        </div>
      </RenderIf>
      <RenderIf if={!userHasWOViewPermission}>
        <EmptyState icon="crossed-out-circle" iconSize="xs" message="COMPONENTS_TAB.NO_PERMISSION" title="COMPONENTS_TAB.NO_PERMISSION_TITLE" />
      </RenderIf>
    </div>
  );
};

NotificationWorkOrdersTab.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    notificationWorkOrders: state.notificationReducer.notificationWorkOrders,
  };
};

const mapDispatchToProps = dispatch => ({
  getNotificationWorkOrders: id => dispatch(getNotificationWorkOrders(id)),
  setNotificationWorkOrders: data => dispatch(setNotificationWorkOrders(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationWorkOrdersTab);
