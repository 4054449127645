import React from 'react';
import { tableConfig, fields, filterProps } from '../../constants/constants';
import PropTypes from 'prop-types';
import Icon from '../../../../common/icon/components/icon';
import CHTableDropdown from './ch-table-dropdown';
import { isEmpty } from 'lodash';

import '../../styles/ch-table.scss';
import LoadMore from '../../../../common/load-more/components/load-more';
import TableWithPath from '../../../../common/table-with-path/components/table';
import Helpers from '../../../../common/helpers';

const handleFormatCells = (value, type, searchTerm, row, onDropdownClick) => {
  if (type === fields.icon) {
    return <Icon name="folder" size="sm" />;
  } else if (type === fields.actionMenu) {
    return <CHTableDropdown onClick={onDropdownClick} row={row} />;
  } else if (type === fields.code || type === fields.name) {
    return isEmpty(value) ? '-' : searchTerm ? Helpers.getSearchTermText(value, searchTerm) : value;
  } else {
    return isEmpty(value) ? '-' : value;
  }
};

const CHTable = ({ data, filters, searchTerm, TotalItems, onSortClick, loadMoreOnClick, loadMoreDisabled, onRowClick, onDropdownClick, selectedItem }, { t }) => {
  return (
    <div className="ch-table-container">
      <TableWithPath
        tableConfig={tableConfig}
        formatCell={(value, type, row) => handleFormatCells(value, type, searchTerm, row, onDropdownClick)}
        translationModule={t}
        stickyHeader
        sortingObj={filters}
        sortDirectionProp={filterProps.sortDirection}
        sortByColumnProp={filterProps.sortByColumn}
        data={data}
        onSortClick={onSortClick}
        onRowClick={onRowClick}
        selectedItem={selectedItem}
      />
      <div className="ch-table-container__info">
        <LoadMore
          loaded={data.length}
          total={TotalItems}
          onlyText={data.length >= TotalItems}
          totalPosition="center"
          label="LOAD_MORE"
          showButton
          showTotalItems
          disabled={loadMoreDisabled}
          buttonVariant="success-outline"
          resultsText="COMPONENT_HIERARCHY_TABLE.SHOWING_TOTAL_ITEMS"
          onClick={loadMoreOnClick}
          buttonWidth="sm"
        />
      </div>
    </div>
  );
};

CHTable.contextTypes = {
  t: PropTypes.func.isRequired,
};

CHTable.propTypes = {
  data: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  searchTerm: PropTypes.string,
  TotalItems: PropTypes.number,
  onSortClick: PropTypes.func,
  loadMoreOnClick: PropTypes.func,
  loadMoreDisabled: PropTypes.bool,
  onRowClick: PropTypes.func,
  selectedItem: PropTypes.number,
};

export default CHTable;
