import modalActionTypes from '../constants/action-types';

export const toggleGenericModal = (isOpen, content = '', title = '', error) => {
  return {
    type: modalActionTypes.HANDLE_GENERIC_MODAL,
    data: { isOpen, content, title, error },
  };
};

export const passwordVerificationError = data => {
  return {
    type: modalActionTypes.HANDLE_PASSWORD_VERIFICATION_ERROR,
    data,
  };
};

export const toggleRejectionNoteModal = (isOpen, content = '', title = '', error) => {
  return {
    type: modalActionTypes.HANDLE_REJECTION_NOTE_MODAL,
    data: { isOpen, content, title, error },
  };
};
