import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SearchInput from '../../../common/input/components/search-input';
import TableHeader from '../../../common/table/components/table-header';
import { sortingOrder, sortingOptions, tableConfig } from '../constants/project-fields';

class ProjectHeader extends Component {
  render() {
    const { t } = this.context;
    const { handleFilterChanged, sortingObj, handleSortingChanged } = this.props;

    return (
      <div className="sidebar__header-container">
        <h3 className="f-primary sidebar__title noselect">{t('PROJECT.SIDEBAR.TITLE')}</h3>

        <div className="sidebar__search">
          <SearchInput
            onChange={e => handleFilterChanged({ key: sortingOptions.search, value: e.target.value })}
            placeholder={t('PROJECT.SEARCH.PLACEHOLDER')}
            wrapperClass="sidebar__search--input"
            stripped
          />

          <div className="filter">
            <div className="filter-items">
              <table>
                <TableHeader
                  tableConfig={tableConfig}
                  translationModule={t}
                  onColumnSort={column =>
                    handleSortingChanged({ [sortingOptions.sortByColumn]: column, [sortingOptions.order]: sortingObj.SortDirection === sortingOrder.asc ? sortingOrder.desc : sortingOrder.asc })
                  }
                  sortingObj={sortingObj}
                  sortDirectionProp={sortingOptions.order}
                  sortByColumnProp={sortingOptions.sortByColumn}
                />
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ProjectHeader.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ProjectHeader;
