import routesConstants from './routes';
import { Api } from '../../core/api';
import { API_BASE_URL } from '../../common/constants';

const getPointImages = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_POINT_IMAGES}`, data);
};

const selectMultipleWorkflowImages = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.SELECT_MULTIPLE_WORKFLOW_IMAGES}`, data);
};

const image2DTo3D = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.IMAGE_2D_TO_3D_POSITION}`, data);
};

export default {
  getPointImages,
  selectMultipleWorkflowImages,
  image2DTo3D,
};
