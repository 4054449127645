import actionTypes from '../constants/action-types';

const themeState = {
  lineChartOptions: null,
  comlumnChartOptions: null,
  thisMonthLine: null,
  lastMonthLine: null,
  uploadProgress: null,
  map: null,
};

export const themeReducer = (state = themeState, payload) => {
  switch (payload.type) {
    case actionTypes.HANDLE_THEME_CHANGE:
      return { ...state, ...payload.data };
    default:
      return state;
  }
};
