import { Component } from 'react';
import { connect } from 'react-redux';
import { setGenericNotification } from '../actions/action-creators';
import Notification from './notification';

// TODO: Refactor to functional component, since we have a bug when user clicks timer stays active, could be solved with useEffect
class GenericNotification extends Component {
  closeNotification = () => {
    const { setGenericNotification } = this.props;
    setGenericNotification({ isDisplayed: false });
  };

  componentDidUpdate = () => {
    const { notification } = this.props;
    if (notification.isDisplayed === true) {
      setTimeout(this.closeNotification, 6000);
    }
  };

  render() {
    const { notification } = this.props;
    return <Notification {...notification} onClickClose={this.closeNotification} />;
  }
}

const mapStateToProps = state => ({
  notification: state.genericNotificationReducer,
});
const mapDispatchToProps = dispatch => ({
  setGenericNotification: data => dispatch(setGenericNotification(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GenericNotification);
