import img_0 from '../images/img_2.png';
import img_1 from '../images/img_1.png';

export const computerVision = {
  v: '5.6.5',
  fr: 30,
  ip: 0,
  op: 300,
  w: 1000,
  h: 1000,
  nm: 'Comp 1',
  ddd: 0,
  assets: [
    { id: 'image_0', w: 512, h: 512, p: img_0, e: 0 },
    { id: 'image_1', w: 1000, h: 1000, p: img_1, e: 0 },
  ],
  fonts: { list: [{ fName: 'SofiaProLight', fFamily: 'Sofia Pro', fStyle: 'Light', ascent: 73.0095727536827 }] },
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 2,
      nm: 'checkmark.png',
      cl: 'png',
      refId: 'image_0',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 254, s: [0] },
            { t: 263, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [500, 500, 0], ix: 2 },
        a: { a: 0, k: [256, 256, 0], ix: 1 },
        s: { a: 0, k: [14.062, 14.062, 100], ix: 6 },
      },
      ao: 0,
      ip: 0,
      op: 300,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 5,
      nm: 'Best images selected!',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 179, s: [0] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.333], y: [0] }, t: 188, s: [100] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 194, s: [100] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 199, s: [0] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 203, s: [100] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 207, s: [0] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 211, s: [100] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 215, s: [0] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 219, s: [100] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 223, s: [0] },
            { t: 227, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            { i: { x: 0.667, y: 1 }, o: { x: 0.333, y: 0 }, t: 179, s: [321.015, 594, 0], to: [0, 8, 0], ti: [0, -8, 0] },
            { t: 188, s: [321.015, 642, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      t: {
        d: { k: [{ s: { s: 25, f: 'SofiaProLight', t: 'Best images selected!', j: 0, tr: 226, lh: 30, ls: 8, fc: [1, 1, 1], sc: [1, 1, 1], sw: 0.00999999977648, of: false }, t: 0 }] },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: [],
      },
      ip: 0,
      op: 300,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 5,
      nm: 'Selecting best photos to inspect',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 92, s: [0] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.333], y: [0] }, t: 100, s: [100] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 109, s: [100] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 123, s: [50] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 134, s: [100] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 179, s: [100] },
            { t: 188, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            { i: { x: 0.667, y: 1 }, o: { x: 0.333, y: 0 }, t: 92, s: [234.41, 612, 0], to: [0, 5, 0], ti: [0, -5, 0] },
            { i: { x: 0.667, y: 0.667 }, o: { x: 0.167, y: 0.167 }, t: 100, s: [234.41, 642, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { i: { x: 0.667, y: 1 }, o: { x: 0.167, y: 0 }, t: 179, s: [234.41, 642, 0], to: [0, 7.167, 0], ti: [0, -7.167, 0] },
            { t: 188, s: [234.41, 685, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      t: {
        d: { k: [{ s: { s: 25, f: 'SofiaProLight', t: 'Selecting best photos to inspect', j: 0, tr: 226, lh: 30, ls: 8, fc: [1, 1, 1], sc: [1, 1, 1], sw: 0.00999999977648, of: false }, t: 0 }] },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: [],
      },
      ip: 0,
      op: 301,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Cursor',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 6, s: [100] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 12, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 18, s: [100] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 25, s: [0] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.333], y: [0] }, t: 33, s: [100] },
            { t: 72, s: [1] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            { i: { x: 0.667, y: 1 }, o: { x: 0.333, y: 0 }, t: 33, s: [720.679, 495, 0], to: [100.5, 0, 0], ti: [-100.5, 0, 0] },
            { t: 53, s: [1323.679, 495, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [174.628, 102.467, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            { ty: 'rc', d: 1, s: { a: 0, k: [2.984, 76.258], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, r: { a: 0, k: 0, ix: 4 }, nm: 'Rectangle Path 1', mn: 'ADBE Vector Shape - Rect', hd: false },
            { ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
            {
              ty: 'tr',
              p: { a: 0, k: [-298.096, 135.225], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [60.832, 70.665], ix: 3 },
              r: { a: 0, k: 0.086, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 138,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 5,
      nm: 'Applying computer vision algorithm',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 92, s: [100] },
            { t: 100, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            { i: { x: 0.667, y: 1 }, o: { x: 0.333, y: 0 }, t: 92, s: [207.016, 650, 0], to: [0, 4.333, 0], ti: [0, -4.333, 0] },
            { t: 100, s: [207.016, 676, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: 'a',
          pt: {
            a: 1,
            k: [
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 34,
                s: [
                  {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [0.347, -26],
                      [0.984, -27],
                      [0.984, 1.098],
                      [0.347, 1.098],
                    ],
                    c: true,
                  },
                ],
              },
              {
                t: 54,
                s: [
                  {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [591.347, -32],
                      [0.984, -27],
                      [0.984, 1.098],
                      [592.347, -0.902],
                    ],
                    c: true,
                  },
                ],
              },
            ],
            ix: 1,
          },
          o: { a: 0, k: 100, ix: 3 },
          x: { a: 0, k: 0, ix: 4 },
          nm: 'Mask 1',
        },
      ],
      t: {
        d: { k: [{ s: { s: 25, f: 'SofiaProLight', t: 'Applying computer vision algorithm', j: 0, tr: 226, lh: 30, ls: 8, fc: [1, 1, 1], sc: [1, 1, 1], sw: 0.00999999977648, of: false }, t: 0 }] },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: [],
      },
      ip: 37,
      op: 302,
      st: 37,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'Border 3',
      sr: 1,
      ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [801, 500, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 } },
      ao: 0,
      ef: [
        {
          ty: 21,
          nm: 'Fill',
          np: 9,
          mn: 'ADBE Fill',
          ix: 1,
          en: 1,
          ef: [
            { ty: 10, nm: 'Fill Mask', mn: 'ADBE Fill-0001', ix: 1, v: { a: 0, k: 0, ix: 1 } },
            { ty: 7, nm: 'All Masks', mn: 'ADBE Fill-0007', ix: 2, v: { a: 0, k: 0, ix: 2 } },
            { ty: 2, nm: 'Color', mn: 'ADBE Fill-0002', ix: 3, v: { a: 0, k: [0.003921568859, 0.964705884457, 0.647058844566, 1], ix: 3 } },
            { ty: 7, nm: 'Invert', mn: 'ADBE Fill-0006', ix: 4, v: { a: 0, k: 0, ix: 4 } },
            { ty: 0, nm: 'Horizontal Feather', mn: 'ADBE Fill-0003', ix: 5, v: { a: 0, k: 0, ix: 5 } },
            { ty: 0, nm: 'Vertical Feather', mn: 'ADBE Fill-0004', ix: 6, v: { a: 0, k: 0, ix: 6 } },
            { ty: 0, nm: 'Opacity', mn: 'ADBE Fill-0005', ix: 7, v: { a: 0, k: 1, ix: 7 } },
          ],
        },
      ],
      shapes: [
        {
          ty: 'gr',
          it: [
            { ty: 'rc', d: 1, s: { a: 0, k: [100.77, 99.945], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, r: { a: 0, k: 0, ix: 4 }, nm: 'Rectangle Path 1', mn: 'ADBE Vector Shape - Rect', hd: false },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 4, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-150.115, -0.027], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [116.844, 116.844], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 13, s: [0] },
                  { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 28, s: [100] },
                  { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 136, s: [100] },
                  { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 138, s: [36] },
                  { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 140, s: [100] },
                  { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 142, s: [36] },
                  { i: { x: [0.635], y: [0.827] }, o: { x: [0.158], y: [0] }, t: 143, s: [100] },
                  { i: { x: [0.563], y: [54.727] }, o: { x: [0.167], y: [-179.919] }, t: 148, s: [19.618] },
                  { i: { x: [0.651], y: [2.914] }, o: { x: [0.313], y: [-1.629] }, t: 202, s: [20] },
                  { i: { x: [0.665], y: [2.708] }, o: { x: [0.327], y: [-1.795] }, t: 208, s: [20] },
                  { i: { x: [0.677], y: [2.176] }, o: { x: [0.339], y: [-1.729] }, t: 214, s: [20] },
                  { i: { x: [0.703], y: [-5.338] }, o: { x: [0.362], y: [-1.537] }, t: 220, s: [20] },
                  { i: { x: [0.78], y: [1] }, o: { x: [0.429], y: [-0.851] }, t: 227, s: [20] },
                  { t: 240, s: [0] },
                ],
                ix: 7,
              },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'tm',
          s: { a: 0, k: 0, ix: 1 },
          e: {
            a: 1,
            k: [
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 13, s: [0] },
              { t: 38, s: [100] },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 0,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 19.618, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 0,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 19.618, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 0,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 13,
      op: 301,
      st: 13,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: 'Border 2',
      sr: 1,
      ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [650, 500, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 } },
      ao: 0,
      ef: [
        {
          ty: 21,
          nm: 'Fill',
          np: 9,
          mn: 'ADBE Fill',
          ix: 1,
          en: 1,
          ef: [
            { ty: 10, nm: 'Fill Mask', mn: 'ADBE Fill-0001', ix: 1, v: { a: 0, k: 0, ix: 1 } },
            { ty: 7, nm: 'All Masks', mn: 'ADBE Fill-0007', ix: 2, v: { a: 0, k: 0, ix: 2 } },
            { ty: 2, nm: 'Color', mn: 'ADBE Fill-0002', ix: 3, v: { a: 0, k: [0.003921568859, 0.964705884457, 0.647058844566, 1], ix: 3 } },
            { ty: 7, nm: 'Invert', mn: 'ADBE Fill-0006', ix: 4, v: { a: 0, k: 0, ix: 4 } },
            { ty: 0, nm: 'Horizontal Feather', mn: 'ADBE Fill-0003', ix: 5, v: { a: 0, k: 0, ix: 5 } },
            { ty: 0, nm: 'Vertical Feather', mn: 'ADBE Fill-0004', ix: 6, v: { a: 0, k: 0, ix: 6 } },
            { ty: 0, nm: 'Opacity', mn: 'ADBE Fill-0005', ix: 7, v: { a: 0, k: 1, ix: 7 } },
          ],
        },
      ],
      shapes: [
        {
          ty: 'gr',
          it: [
            { ty: 'rc', d: 1, s: { a: 0, k: [100.77, 99.945], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, r: { a: 0, k: 0, ix: 4 }, nm: 'Rectangle Path 1', mn: 'ADBE Vector Shape - Rect', hd: false },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 4, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-150.115, -0.027], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [116.844, 116.844], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [0] },
                  { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 15, s: [100] },
                  { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 130, s: [100] },
                  { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 132, s: [36] },
                  { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 134, s: [100] },
                  { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 136, s: [36] },
                  { i: { x: [0.635], y: [0.827] }, o: { x: [0.158], y: [0] }, t: 137, s: [100] },
                  { i: { x: [0.563], y: [55.722] }, o: { x: [0.2], y: [-220.149] }, t: 142, s: [19.618] },
                  { i: { x: [0.651], y: [2.914] }, o: { x: [0.313], y: [-1.629] }, t: 197, s: [20] },
                  { i: { x: [0.665], y: [2.708] }, o: { x: [0.327], y: [-1.795] }, t: 203, s: [20] },
                  { i: { x: [0.677], y: [2.176] }, o: { x: [0.339], y: [-1.729] }, t: 209, s: [20] },
                  { i: { x: [0.703], y: [-5.338] }, o: { x: [0.362], y: [-1.537] }, t: 215, s: [20] },
                  { i: { x: [0.78], y: [1] }, o: { x: [0.429], y: [-0.851] }, t: 222, s: [20] },
                  { t: 235, s: [0] },
                ],
                ix: 7,
              },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'tm',
          s: { a: 0, k: 0, ix: 1 },
          e: {
            a: 1,
            k: [
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [0] },
              { t: 25, s: [100] },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false,
        },
      ],
      ip: 0,
      op: 300,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: 'Border 1',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 268, s: [100] },
            { t: 278, s: [34] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [500, 500, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      ef: [
        {
          ty: 21,
          nm: 'Fill',
          np: 9,
          mn: 'ADBE Fill',
          ix: 1,
          en: 1,
          ef: [
            { ty: 10, nm: 'Fill Mask', mn: 'ADBE Fill-0001', ix: 1, v: { a: 0, k: 0, ix: 1 } },
            { ty: 7, nm: 'All Masks', mn: 'ADBE Fill-0007', ix: 2, v: { a: 0, k: 0, ix: 2 } },
            { ty: 2, nm: 'Color', mn: 'ADBE Fill-0002', ix: 3, v: { a: 0, k: [0.003921568859, 0.964705884457, 0.647058844566, 1], ix: 3 } },
            { ty: 7, nm: 'Invert', mn: 'ADBE Fill-0006', ix: 4, v: { a: 0, k: 0, ix: 4 } },
            { ty: 0, nm: 'Horizontal Feather', mn: 'ADBE Fill-0003', ix: 5, v: { a: 0, k: 0, ix: 5 } },
            { ty: 0, nm: 'Vertical Feather', mn: 'ADBE Fill-0004', ix: 6, v: { a: 0, k: 0, ix: 6 } },
            { ty: 0, nm: 'Opacity', mn: 'ADBE Fill-0005', ix: 7, v: { a: 0, k: 1, ix: 7 } },
          ],
        },
      ],
      shapes: [
        {
          ty: 'gr',
          it: [
            { ty: 'rc', d: 1, s: { a: 0, k: [100.77, 99.945], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, r: { a: 0, k: 0, ix: 4 }, nm: 'Rectangle Path 1', mn: 'ADBE Vector Shape - Rect', hd: false },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 4, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 1,
                k: [
                  { i: { x: 0.354, y: 1 }, o: { x: 0.593, y: 0 }, t: 226, s: [-150.115, -0.027], to: [25.333, 0], ti: [-25.333, 0] },
                  { t: 242, s: [1.885, -0.027] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [116.844, 116.844], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 4, s: [0] },
                  { t: 19, s: [100] },
                ],
                ix: 7,
              },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'tm',
          s: { a: 0, k: 0, ix: 1 },
          e: {
            a: 1,
            k: [
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 4, s: [0] },
              { t: 29, s: [100] },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false,
        },
      ],
      ip: 4,
      op: 300,
      st: 4,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 1,
      nm: 'Box 1',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [0.906] }, o: { x: [0.333], y: [0] }, t: 14, s: [0] },
            { i: { x: [0.667], y: [1.13] }, o: { x: [0.333], y: [-0.151] }, t: 16, s: [57.715] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0.208] }, t: 18, s: [21.869] },
            { t: 25, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            { i: { x: 0.368, y: 1 }, o: { x: 0.586, y: 0 }, t: 226, s: [350, 500, 0], to: [25.333, 0, 0], ti: [-25.333, 0, 0] },
            { t: 242, s: [502, 500, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [50, 50, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      ef: [
        {
          ty: 21,
          nm: 'Fill',
          np: 9,
          mn: 'ADBE Fill',
          ix: 1,
          en: 1,
          ef: [
            { ty: 10, nm: 'Fill Mask', mn: 'ADBE Fill-0001', ix: 1, v: { a: 0, k: 0, ix: 1 } },
            { ty: 7, nm: 'All Masks', mn: 'ADBE Fill-0007', ix: 2, v: { a: 0, k: 0, ix: 2 } },
            { ty: 2, nm: 'Color', mn: 'ADBE Fill-0002', ix: 3, v: { a: 0, k: [0.003921568859, 0.964705884457, 0.647058844566, 1], ix: 3 } },
            { ty: 7, nm: 'Invert', mn: 'ADBE Fill-0006', ix: 4, v: { a: 0, k: 0, ix: 4 } },
            { ty: 0, nm: 'Horizontal Feather', mn: 'ADBE Fill-0003', ix: 5, v: { a: 0, k: 0, ix: 5 } },
            { ty: 0, nm: 'Vertical Feather', mn: 'ADBE Fill-0004', ix: 6, v: { a: 0, k: 0, ix: 6 } },
            { ty: 0, nm: 'Opacity', mn: 'ADBE Fill-0005', ix: 7, v: { a: 0, k: 1, ix: 7 } },
          ],
        },
      ],
      sw: 100,
      sh: 100,
      sc: '#ffffff',
      ip: 14,
      op: 440,
      st: 14,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 1,
      nm: 'Box 2',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [0.873] }, o: { x: [0.333], y: [0] }, t: 11, s: [0] },
            { i: { x: [0.667], y: [1.114] }, o: { x: [0.333], y: [-0.24] }, t: 19, s: [73.625] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0.156] }, t: 26, s: [39.395] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 43, s: [100] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 145, s: [100] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 155, s: [35] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 227, s: [35] },
            { t: 242, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [500, 500, 0], ix: 2 },
        a: { a: 0, k: [50, 50, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      ef: [
        {
          ty: 21,
          nm: 'Fill',
          np: 9,
          mn: 'ADBE Fill',
          ix: 1,
          en: 1,
          ef: [
            { ty: 10, nm: 'Fill Mask', mn: 'ADBE Fill-0001', ix: 1, v: { a: 0, k: 0, ix: 1 } },
            { ty: 7, nm: 'All Masks', mn: 'ADBE Fill-0007', ix: 2, v: { a: 0, k: 0, ix: 2 } },
            { ty: 2, nm: 'Color', mn: 'ADBE Fill-0002', ix: 3, v: { a: 0, k: [0.003921568859, 0.964705884457, 0.647058844566, 1], ix: 3 } },
            { ty: 7, nm: 'Invert', mn: 'ADBE Fill-0006', ix: 4, v: { a: 0, k: 0, ix: 4 } },
            { ty: 0, nm: 'Horizontal Feather', mn: 'ADBE Fill-0003', ix: 5, v: { a: 0, k: 0, ix: 5 } },
            { ty: 0, nm: 'Vertical Feather', mn: 'ADBE Fill-0004', ix: 6, v: { a: 0, k: 0, ix: 6 } },
            { ty: 0, nm: 'Opacity', mn: 'ADBE Fill-0005', ix: 7, v: { a: 0, k: 1, ix: 7 } },
          ],
        },
      ],
      sw: 100,
      sh: 100,
      sc: '#ffffff',
      ip: 11,
      op: 463,
      st: 11,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 11,
      ty: 1,
      nm: 'Box 3',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [0.853] }, o: { x: [0.333], y: [0] }, t: 23, s: [0] },
            { i: { x: [0.667], y: [1.451] }, o: { x: [0.333], y: [-0.391] }, t: 25, s: [36.715] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0.223] }, t: 28, s: [15.941] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 34, s: [100] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 149, s: [100] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 159, s: [35] },
            { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 233, s: [35] },
            { t: 248, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [650, 500, 0], ix: 2 },
        a: { a: 0, k: [50, 50, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      ef: [
        {
          ty: 21,
          nm: 'Fill',
          np: 9,
          mn: 'ADBE Fill',
          ix: 1,
          en: 1,
          ef: [
            { ty: 10, nm: 'Fill Mask', mn: 'ADBE Fill-0001', ix: 1, v: { a: 0, k: 0, ix: 1 } },
            { ty: 7, nm: 'All Masks', mn: 'ADBE Fill-0007', ix: 2, v: { a: 0, k: 0, ix: 2 } },
            { ty: 2, nm: 'Color', mn: 'ADBE Fill-0002', ix: 3, v: { a: 0, k: [0.003921568859, 0.964705884457, 0.647058844566, 1], ix: 3 } },
            { ty: 7, nm: 'Invert', mn: 'ADBE Fill-0006', ix: 4, v: { a: 0, k: 0, ix: 4 } },
            { ty: 0, nm: 'Horizontal Feather', mn: 'ADBE Fill-0003', ix: 5, v: { a: 0, k: 0, ix: 5 } },
            { ty: 0, nm: 'Vertical Feather', mn: 'ADBE Fill-0004', ix: 6, v: { a: 0, k: 0, ix: 6 } },
            { ty: 0, nm: 'Opacity', mn: 'ADBE Fill-0005', ix: 7, v: { a: 0, k: 1, ix: 7 } },
          ],
        },
      ],
      sw: 100,
      sh: 100,
      sc: '#ffffff',
      ip: 23,
      op: 301,
      st: 23,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 12,
      ty: 2,
      nm: 'Background.png',
      cl: 'png',
      refId: 'image_1',
      sr: 1,
      ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [500, 500, 0], ix: 2 }, a: { a: 0, k: [500, 500, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 } },
      ao: 0,
      ip: 0,
      op: 300,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
  chars: [
    {
      ch: 'A',
      size: 25,
      style: 'Light',
      w: 60.9,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [53.677, 0],
                      [60.626, 0],
                      [34.241, -69.388],
                      [27.191, -69.388],
                      [0.705, 0],
                      [7.755, 0],
                      [14.703, -18.43],
                      [46.729, -18.43],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'A',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [17.02, -24.371],
                      [30.716, -60.526],
                      [44.412, -24.371],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'A',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'A',
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Sofia Pro',
    },
    {
      ch: 'p',
      size: 25,
      style: 'Light',
      w: 61.5,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [13.193, 0],
                      [3.424, -6.445],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-7.956, 0],
                      [0, 14.301],
                    ],
                    o: [
                      [-7.855, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [3.525, 6.445],
                      [13.193, 0],
                      [0, -14.301],
                    ],
                    v: [
                      [33.234, -48.038],
                      [14.502, -37.766],
                      [14.502, -47.031],
                      [8.057, -47.031],
                      [8.057, 22.861],
                      [14.502, 22.861],
                      [14.502, -9.164],
                      [33.234, 1.007],
                      [57.101, -23.465],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'p',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [9.97, 0],
                      [0, 11.38],
                      [-9.769, 0],
                      [0, -10.474],
                    ],
                    o: [
                      [-9.366, 0],
                      [0, -10.876],
                      [9.97, 0],
                      [0, 10.373],
                    ],
                    v: [
                      [32.73, -5.136],
                      [14.502, -23.666],
                      [32.73, -41.895],
                      [50.757, -23.364],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'p',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'p',
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Sofia Pro',
    },
    {
      ch: 'l',
      size: 25,
      style: 'Light',
      w: 22.4,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [8.057, 0],
                      [14.502, 0],
                      [14.502, -73.517],
                      [8.057, -73.517],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'l',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'l',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Sofia Pro',
    },
    {
      ch: 'y',
      size: 25,
      style: 'Light',
      w: 49.8,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [41.391, -47.031],
                      [25.177, -8.56],
                      [9.164, -47.031],
                      [2.216, -47.031],
                      [21.652, -0.604],
                      [11.984, 22.861],
                      [18.832, 22.861],
                      [48.34, -47.031],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'y',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'y',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Sofia Pro',
    },
    {
      ch: 'i',
      size: 25,
      style: 'Light',
      w: 22.4,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-2.618, 0],
                      [0, 2.618],
                      [2.82, 0],
                      [0, -2.719],
                    ],
                    o: [
                      [2.82, 0],
                      [0, -2.719],
                      [-2.618, 0],
                      [0, 2.618],
                    ],
                    v: [
                      [11.279, -59.821],
                      [16.315, -64.352],
                      [11.279, -68.985],
                      [6.345, -64.352],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'i',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [8.057, 0],
                      [14.502, 0],
                      [14.502, -47.031],
                      [8.057, -47.031],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'i',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'i',
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Sofia Pro',
    },
    {
      ch: 'n',
      size: 25,
      style: 'Light',
      w: 56.9,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [11.38, 0],
                      [2.82, -6.747],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-7.553, 0],
                      [0, -9.064],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [-5.841, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0.201, -11.38],
                      [7.553, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, -12.991],
                    ],
                    v: [
                      [30.817, -48.038],
                      [14.502, -38.571],
                      [14.502, -47.031],
                      [8.057, -47.031],
                      [8.057, 0],
                      [14.502, 0],
                      [14.502, -26.083],
                      [30.212, -41.693],
                      [43.506, -27.09],
                      [43.506, 0],
                      [49.951, 0],
                      [49.951, -27.393],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'n',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'n',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Sofia Pro',
    },
    {
      ch: 'g',
      size: 25,
      style: 'Light',
      w: 61.5,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [7.956, 0],
                      [0, -14.301],
                      [-13.092, 0],
                      [-3.525, 6.345],
                      [0, 0],
                      [11.481, 0],
                      [3.424, 5.539],
                      [0, 0],
                      [-7.956, 0],
                      [0, 22.76],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [-3.525, -6.345],
                      [-13.193, 0],
                      [0, 14.301],
                      [7.855, 0],
                      [0, 0],
                      [0, 18.127],
                      [-5.64, 0],
                      [0, 0],
                      [4.633, 7.654],
                      [15.308, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [47.433, -47.031],
                      [47.433, -37.967],
                      [28.702, -48.038],
                      [4.834, -23.566],
                      [28.702, 1.007],
                      [47.433, -9.064],
                      [47.433, -6.647],
                      [28.4, 17.322],
                      [13.495, 8.963],
                      [8.057, 11.984],
                      [28.4, 23.465],
                      [53.879, -6.949],
                      [53.879, -47.031],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'g',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [9.769, 0],
                      [0, 10.474],
                      [-9.869, 0],
                      [0, -11.38],
                    ],
                    o: [
                      [-9.869, 0],
                      [0, -10.373],
                      [9.366, 0],
                      [0, 10.876],
                    ],
                    v: [
                      [29.205, -5.136],
                      [11.279, -23.666],
                      [29.205, -41.895],
                      [47.433, -23.364],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'g',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'g',
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Sofia Pro',
    },
    { ch: ' ', size: 25, style: 'Light', w: 26.2, data: {}, fFamily: 'Sofia Pro' },
    {
      ch: 'c',
      size: 25,
      style: 'Light',
      w: 48.7,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-13.394, 0],
                      [-4.23, 4.028],
                      [0, 0],
                      [5.035, 0],
                      [0, 10.977],
                      [-10.272, 0],
                      [-3.223, -2.921],
                      [0, 0],
                      [6.747, 0],
                      [0, -14.905],
                    ],
                    o: [
                      [6.747, 0],
                      [0, 0],
                      [-3.223, 2.921],
                      [-10.272, 0],
                      [0, -11.078],
                      [5.035, 0],
                      [0, 0],
                      [-4.23, -3.928],
                      [-13.394, 0],
                      [0, 14.905],
                    ],
                    v: [
                      [29.709, 0.906],
                      [46.628, -5.539],
                      [42.599, -9.869],
                      [29.91, -5.237],
                      [11.279, -23.566],
                      [29.91, -41.895],
                      [42.599, -37.161],
                      [46.628, -41.592],
                      [29.709, -48.038],
                      [4.834, -23.566],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'c',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'c',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Sofia Pro',
    },
    {
      ch: 'o',
      size: 25,
      style: 'Light',
      w: 58.4,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-13.394, 0],
                      [0, 14.905],
                      [13.394, 0],
                      [0, -14.905],
                    ],
                    o: [
                      [13.394, 0],
                      [0, -14.905],
                      [-13.394, 0],
                      [0, 14.905],
                    ],
                    v: [
                      [29.407, 1.007],
                      [54.08, -23.666],
                      [29.407, -48.038],
                      [4.834, -23.666],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'o',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [9.869, 0],
                      [0, 11.179],
                      [-9.97, 0],
                      [0, -11.078],
                    ],
                    o: [
                      [-9.97, 0],
                      [0, -11.078],
                      [9.869, 0],
                      [0, 11.179],
                    ],
                    v: [
                      [29.407, -5.136],
                      [11.279, -23.666],
                      [29.407, -41.895],
                      [47.635, -23.666],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'o',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'o',
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Sofia Pro',
    },
    {
      ch: 'm',
      size: 25,
      style: 'Light',
      w: 89.9,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [11.38, 0],
                      [3.424, -9.366],
                      [7.654, 0],
                      [2.417, -6.244],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-7.553, 0],
                      [0, -9.164],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-8.862, -0.101],
                      [0, -9.064],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [-5.74, 0],
                      [-2.618, -7.251],
                      [-6.949, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0.201, -11.279],
                      [7.452, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-0.705, -11.481],
                      [7.755, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, -12.991],
                    ],
                    v: [
                      [64.453, -48.038],
                      [47.131, -36.758],
                      [30.615, -48.038],
                      [14.502, -38.571],
                      [14.502, -47.031],
                      [8.057, -47.031],
                      [8.057, 0],
                      [14.502, 0],
                      [14.502, -26.083],
                      [29.507, -41.794],
                      [42.398, -27.09],
                      [42.398, 0],
                      [48.743, 0],
                      [48.743, -24.371],
                      [48.843, -23.969],
                      [63.446, -41.794],
                      [76.639, -27.09],
                      [76.639, 0],
                      [83.084, 0],
                      [83.084, -27.393],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'm',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'm',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Sofia Pro',
    },
    {
      ch: 'u',
      size: 25,
      style: 'Light',
      w: 56.9,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [7.452, 0],
                      [0, 9.064],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-11.38, 0],
                      [-2.82, 6.647],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [-0.101, 11.38],
                      [-7.654, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-0.101, 12.991],
                      [5.841, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [42.801, -47.031],
                      [42.801, -20.947],
                      [27.191, -5.338],
                      [13.898, -19.94],
                      [13.898, -47.031],
                      [7.452, -47.031],
                      [7.452, -19.638],
                      [26.486, 1.007],
                      [42.801, -8.258],
                      [42.801, 0],
                      [49.246, 0],
                      [49.246, -47.031],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'u',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'u',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Sofia Pro',
    },
    {
      ch: 't',
      size: 25,
      style: 'Light',
      w: 30.1,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [28.4, -47.031],
                      [17.624, -47.031],
                      [17.624, -65.762],
                      [11.279, -65.762],
                      [11.279, -47.031],
                      [2.719, -47.031],
                      [2.719, -41.492],
                      [11.279, -41.492],
                      [11.279, 0],
                      [17.624, 0],
                      [17.624, -41.492],
                      [28.4, -41.492],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 't',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 't',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Sofia Pro',
    },
    {
      ch: 'e',
      size: 25,
      style: 'Light',
      w: 56.1,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0.906],
                      [13.394, 0],
                      [0, -14.905],
                      [-13.293, 0],
                      [-4.129, 7.553],
                      [0, 0],
                      [6.445, 0],
                      [1.108, 10.071],
                      [0, 0],
                    ],
                    o: [
                      [0, -14.905],
                      [-13.293, 0],
                      [0, 14.905],
                      [8.862, 0],
                      [0, 0],
                      [-3.122, 5.136],
                      [-9.467, 0],
                      [0, 0],
                      [0.101, -0.906],
                    ],
                    v: [
                      [51.965, -23.666],
                      [28.802, -48.038],
                      [4.834, -23.666],
                      [28.802, 1.007],
                      [49.85, -10.776],
                      [44.312, -13.193],
                      [29.004, -5.136],
                      [11.38, -20.847],
                      [51.865, -20.847],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'e',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [-9.265, 0],
                      [-0.906, -9.769],
                    ],
                    o: [
                      [1.208, -10.071],
                      [9.164, 0],
                      [0, 0],
                    ],
                    v: [
                      [11.481, -26.385],
                      [28.802, -41.895],
                      [45.621, -26.385],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'e',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'e',
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Sofia Pro',
    },
    {
      ch: 'r',
      size: 25,
      style: 'Light',
      w: 37.4,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [2.618, 0],
                      [2.316, -7.956],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-11.179, 0],
                      [-2.417, -1.007],
                      [0, 0],
                    ],
                    o: [
                      [-7.352, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, -11.581],
                      [1.712, 0],
                      [0, 0],
                      [-1.813, -0.906],
                    ],
                    v: [
                      [29.306, -48.038],
                      [14.603, -35.55],
                      [14.603, -47.031],
                      [8.057, -47.031],
                      [8.057, 0],
                      [14.603, 0],
                      [14.603, -17.725],
                      [29.205, -41.794],
                      [35.55, -40.485],
                      [36.758, -46.628],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'r',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'r',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Sofia Pro',
    },
    {
      ch: 'v',
      size: 25,
      style: 'Light',
      w: 50,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [41.391, -47.031],
                      [25.076, -7.855],
                      [8.963, -47.031],
                      [2.115, -47.031],
                      [21.753, 0],
                      [28.4, 0],
                      [48.34, -47.031],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'v',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'v',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Sofia Pro',
    },
    {
      ch: 's',
      size: 25,
      style: 'Light',
      w: 42.9,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-8.157, 0],
                      [0, 8.359],
                      [9.164, 2.216],
                      [0, 0],
                      [0, 4.431],
                      [-5.438, 0],
                      [-0.302, -4.23],
                      [0, 0],
                      [7.855, 0],
                      [0, -7.654],
                      [-6.647, -1.611],
                      [0, 0],
                      [0, -3.827],
                      [6.747, 0],
                      [0.705, 3.928],
                      [0, 0],
                    ],
                    o: [
                      [9.769, 0],
                      [0, -5.438],
                      [0, 0],
                      [-3.424, -0.806],
                      [0, -4.431],
                      [4.633, 0],
                      [0, 0],
                      [-0.302, -8.459],
                      [-8.963, 0],
                      [0, 7.855],
                      [0, 0],
                      [4.834, 1.208],
                      [0, 4.733],
                      [-6.848, 0],
                      [0, 0],
                      [0.806, 8.963],
                    ],
                    v: [
                      [21.35, 1.007],
                      [38.672, -12.689],
                      [26.688, -25.882],
                      [19.739, -27.594],
                      [12.488, -34.744],
                      [21.652, -42.297],
                      [30.716, -35.248],
                      [36.96, -35.248],
                      [21.552, -48.038],
                      [6.042, -34.845],
                      [18.027, -21.954],
                      [24.774, -20.343],
                      [32.227, -12.79],
                      [21.35, -4.733],
                      [10.172, -12.689],
                      [4.23, -12.689],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 's',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 's',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Sofia Pro',
    },
    {
      ch: 'a',
      size: 25,
      style: 'Light',
      w: 61.5,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [7.956, 0],
                      [0, -14.301],
                      [-13.092, 0],
                      [-3.525, 6.445],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [-3.525, -6.345],
                      [-13.193, 0],
                      [0, 14.301],
                      [7.855, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [47.433, -47.031],
                      [47.433, -37.967],
                      [28.702, -48.038],
                      [4.834, -23.566],
                      [28.702, 1.007],
                      [47.433, -9.164],
                      [47.433, 0],
                      [53.879, 0],
                      [53.879, -47.031],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'a',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [9.769, 0],
                      [0, 10.474],
                      [-9.869, 0],
                      [0, -11.38],
                    ],
                    o: [
                      [-9.869, 0],
                      [0, -10.373],
                      [9.366, 0],
                      [0, 10.876],
                    ],
                    v: [
                      [29.205, -5.136],
                      [11.279, -23.666],
                      [29.205, -41.895],
                      [47.433, -23.364],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'a',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'a',
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Sofia Pro',
    },
    {
      ch: 'h',
      size: 25,
      style: 'Light',
      w: 56.9,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [11.38, 0],
                      [2.82, -6.747],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-7.553, 0],
                      [0, -9.064],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [-5.841, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0.201, -11.38],
                      [7.553, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, -12.991],
                    ],
                    v: [
                      [30.817, -48.038],
                      [14.502, -38.571],
                      [14.502, -73.517],
                      [8.057, -73.517],
                      [8.057, 0],
                      [14.502, 0],
                      [14.502, -26.083],
                      [30.212, -41.693],
                      [43.506, -27.09],
                      [43.506, 0],
                      [49.951, 0],
                      [49.951, -27.393],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'h',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'h',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Sofia Pro',
    },
    {
      ch: 'S',
      size: 25,
      style: 'Light',
      w: 56.6,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-13.596, 0],
                      [0, 9.769],
                      [10.977, 2.719],
                      [0, 0],
                      [0, 3.223],
                      [-7.855, 0],
                      [-0.403, -7.654],
                      [0, 0],
                      [11.783, 0],
                      [0, -11.481],
                      [-14.301, -3.424],
                      [0, 0],
                      [0, -7.553],
                      [11.078, 0],
                      [0.705, 6.848],
                      [0, 0],
                    ],
                    o: [
                      [15.207, 0],
                      [0, -10.071],
                      [0, 0],
                      [-10.373, -2.417],
                      [0, -7.755],
                      [8.359, 0],
                      [0, 0],
                      [-0.403, -11.581],
                      [-11.481, 0],
                      [0, 5.539],
                      [0, 0],
                      [6.848, 1.712],
                      [0, 6.546],
                      [-9.769, 0],
                      [0, 0],
                      [0.504, 10.071],
                    ],
                    v: [
                      [28.802, 1.007],
                      [52.066, -19.135],
                      [33.435, -37.866],
                      [24.069, -40.082],
                      [12.689, -51.26],
                      [27.896, -63.95],
                      [43.002, -51.361],
                      [49.649, -51.361],
                      [28.098, -70.193],
                      [6.042, -51.26],
                      [22.458, -33.939],
                      [31.824, -31.723],
                      [45.419, -18.832],
                      [28.802, -5.237],
                      [12.085, -18.631],
                      [5.539, -18.631],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'S',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'S',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Sofia Pro',
    },
    {
      ch: 'b',
      size: 25,
      style: 'Light',
      w: 61.5,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [13.193, 0],
                      [3.525, -6.445],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-7.855, 0],
                      [0, 14.301],
                    ],
                    o: [
                      [-7.956, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [3.424, 6.445],
                      [13.193, 0],
                      [0, -14.301],
                    ],
                    v: [
                      [33.234, -48.038],
                      [14.502, -37.866],
                      [14.502, -73.517],
                      [8.057, -73.517],
                      [8.057, 0],
                      [14.502, 0],
                      [14.502, -9.265],
                      [33.234, 1.007],
                      [57.101, -23.566],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'b',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [9.97, 0],
                      [0, 10.876],
                      [-9.366, 0],
                      [0, -10.373],
                    ],
                    o: [
                      [-9.769, 0],
                      [0, -11.38],
                      [9.97, 0],
                      [0, 10.474],
                    ],
                    v: [
                      [32.73, -5.136],
                      [14.502, -23.364],
                      [32.73, -41.895],
                      [50.757, -23.666],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'b',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'b',
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Sofia Pro',
    },
    {
      ch: 'B',
      size: 25,
      style: 'Light',
      w: 62.8,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [8.862, 2.316],
                      [0, 6.244],
                      [11.179, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 9.869],
                    ],
                    o: [
                      [6.445, -2.618],
                      [0, -10.776],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [12.286, 0],
                      [0, -7.251],
                    ],
                    v: [
                      [44.714, -36.96],
                      [53.879, -52.267],
                      [35.449, -69.388],
                      [10.272, -69.388],
                      [10.272, 0],
                      [37.967, 0],
                      [57.605, -19.135],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'B',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, -7.05],
                      [6.848, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [7.352, 0],
                      [0, 6.848],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [35.349, -63.144],
                      [47.232, -51.764],
                      [36.658, -39.578],
                      [16.919, -39.578],
                      [16.919, -63.144],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'B',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 2,
                ty: 'sh',
                ix: 3,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [8.963, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, -7.452],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [9.064, 0],
                      [0, 7.15],
                    ],
                    v: [
                      [36.859, -6.244],
                      [16.919, -6.244],
                      [16.919, -33.737],
                      [36.557, -33.737],
                      [50.958, -19.638],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'B',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'B',
            np: 6,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Sofia Pro',
    },
    {
      ch: 'd',
      size: 25,
      style: 'Light',
      w: 61.5,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [7.956, 0],
                      [0, -14.301],
                      [-13.092, 0],
                      [-3.525, 6.445],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [-3.525, -6.345],
                      [-13.193, 0],
                      [0, 14.301],
                      [7.855, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [47.433, -73.517],
                      [47.433, -37.967],
                      [28.702, -48.038],
                      [4.834, -23.566],
                      [28.702, 1.007],
                      [47.433, -9.164],
                      [47.433, 0],
                      [53.879, 0],
                      [53.879, -73.517],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'd',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [9.769, 0],
                      [0, 10.474],
                      [-9.869, 0],
                      [0, -11.38],
                    ],
                    o: [
                      [-9.869, 0],
                      [0, -10.373],
                      [9.366, 0],
                      [0, 10.876],
                    ],
                    v: [
                      [29.205, -5.136],
                      [11.279, -23.666],
                      [29.205, -41.895],
                      [47.433, -23.364],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'd',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'd',
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Sofia Pro',
    },
    {
      ch: '!',
      size: 25,
      style: 'Light',
      w: 25,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [9.97, -18.228],
                      [15.308, -18.228],
                      [15.811, -69.388],
                      [9.366, -69.388],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '!',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-2.719, 0],
                      [0, 2.719],
                      [2.921, 0],
                      [0, -2.719],
                    ],
                    o: [
                      [2.921, 0],
                      [0, -2.719],
                      [-2.719, 0],
                      [0, 2.719],
                    ],
                    v: [
                      [12.488, 0.705],
                      [17.725, -4.028],
                      [12.488, -8.862],
                      [7.452, -4.028],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '!',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '!',
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Sofia Pro',
    },
  ],
};
