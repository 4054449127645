import PropTypes from 'prop-types';
import React from 'react';

import { connect } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { FORMS } from '../../constants';
import Helpers from '../../helpers';
import '../styles/date-range-picker-form.scss';
import Button from './button';
import CustomDatePicker from './date-picker';

export const dateRangeFormConstants = {
  dateFrom: {
    id: 'dateFrom',
    name: 'DateFrom',
    label: 'TIME_SERIES_GRAPH.SETTINGS_FORM.DATE_FROM.LABEL',
    placeholder: 'TIME_SERIES_GRAPH.SETTINGS_FORM.DATE_FROM.PLACEHOLDER',
    errorRequired: 'TIME_SERIES_GRAPH.SETTINGS_FORM.DATE_FROM.ERROR_REQUIRED',
  },
  dateTo: {
    id: 'dateTo',
    name: 'DateTo',
    label: 'TIME_SERIES_GRAPH.SETTINGS_FORM.DATE_TO.LABEL',
    placeholder: 'TIME_SERIES_GRAPH.SETTINGS_FORM.DATE_TO.PLACEHOLDER',
    errorRequired: 'TIME_SERIES_GRAPH.SETTINGS_FORM.DATE_FROM.ERROR_REQUIRED',
  },
};

const DateRangePickerForm = ({ formValues, size = 'lg', isEnhancedDesignCustomProp = true, isRequired = true, customCloseAction, handleSubmit, ...restProps }, { t }) => {
  return (
    <form
      className="date-range-picker-form"
      onSubmit={e => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit(e);
      }}
    >
      <div className="date-range-picker-form__content">
        <Field
          disabledKeyboardNavigation={true}
          dateFormat="MMMM d, yyyy HH:mm"
          timeFormat="HH:mm"
          showTimeSelect
          id={dateRangeFormConstants.dateFrom.name}
          name={dateRangeFormConstants.dateFrom.name}
          component={CustomDatePicker}
          maxDate={formValues?.[dateRangeFormConstants.dateTo.name] ? Helpers.addDays(formValues?.[dateRangeFormConstants.dateTo.name], -1) : null}
          popperPlacement="bottom-end"
          placeholder={dateRangeFormConstants.dateFrom.placeholder}
          label={dateRangeFormConstants.dateFrom.label}
          validateUsingReduxForm
          isEnhancedDesignCustomProp
          isRequired
          size={size}
        />
        <Field
          disabledKeyboardNavigation={true}
          dateFormat="MMMM d, yyyy HH:mm"
          timeFormat="HH:mm"
          showTimeSelect
          id={dateRangeFormConstants.dateTo.name}
          name={dateRangeFormConstants.dateTo.name}
          component={CustomDatePicker}
          minDate={formValues?.[dateRangeFormConstants.dateFrom.name] ? Helpers.addDays(formValues?.[dateRangeFormConstants.dateFrom.name], 1) : null}
          popperPlacement="bottom-end"
          placeholder={dateRangeFormConstants.dateTo.placeholder}
          label={dateRangeFormConstants.dateTo.label}
          validateUsingReduxForm
          isEnhancedDesignCustomProp
          isRequired
          size={size}
        />
      </div>
      <div className="date-range-picker-form__buttons">
        <Button type="submit" text={t('CONFIRM')} variant="danger" className="modal__button" />
        <Button onClick={customCloseAction} type="button" text={t('CANCEL')} variant="gray-outline" />
      </div>
    </form>
  );
};

DateRangePickerForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  formValues: getFormValues(FORMS.dateRangePickerForm)(state),
});

export default connect(
  mapStateToProps,
  null
)(
  reduxForm({
    form: FORMS.dateRangePickerForm,
    enableReinitialize: true,
  })(DateRangePickerForm)
);
