import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { FORMS } from '../../../../../../common/constants';
import UneditableInfo from '../../../../../../common/form/components/uneditable-info';
import { formConstants } from '../../../../constants/component-constants';
import PropTypes from 'prop-types';
import Button from '../../../../../../common/form/components/button';
import DisplayColor from '../../../../../../common/display-color/components/display-color';
import { colorPalette } from '../../../../../../common/form/constants/constants';
import ButtonWithIcon from '../../../../../../common/button-with-icon/button-with-icon';

import '../../../../styles/change-hierarchy-details-section.scss';

const ChangeHierarchyDetailsSection = ({ initialValues, successButtonDisabled, successButtonAction, dangerButtonAction, closeModal, dangerButtonDisabled }, { t }) => {
  return (
    <form noValidate className="change-hierarchy-details-section">
      <ButtonWithIcon
        text={t('COMPONENT_CHANGE_HIERARCHY.BACK_BUTTON', { name: initialValues[formConstants.fields.name] })}
        type="info"
        icon="chevron"
        noBorder
        iconRotation={180}
        onClick={closeModal}
      />
      <div className="change-hierarchy-details-section__title">
        <div className="change-hierarchy-details-section__title__color">
          <DisplayColor color={colorPalette[initialValues[formConstants.fields.color]]} />
        </div>
        <div className="change-hierarchy-details-section__title__text">
          <h5 className="f-primary bold">{initialValues[formConstants.fields.name]}</h5>
        </div>
      </div>
      <Button
        variant="success"
        type="button"
        text={t('COMPONENT_CHANGE_HIERARCHY_SECTION.ADD')}
        height="md"
        width="lg"
        className="mb-12"
        disabled={successButtonDisabled}
        onClick={() => successButtonAction(false)}
      />
      <Button
        variant="danger-outline"
        type="button"
        text={t('COMPONENT_CHANGE_HIERARCHY_SECTION.UNASSIGN')}
        height="md"
        width="lg"
        className="mb-32"
        onClick={dangerButtonAction}
        disabled={dangerButtonDisabled}
      />
      <Field id={formConstants.fields.hierarchy} name={formConstants.fields.hierarchy} component={UneditableInfo} label={t('COMPONENT_DETAILS.FORM_HIERARCHY')} textClassName="break-text" />
      <Field id={formConstants.fields.componentType} name={formConstants.fields.componentType} component={UneditableInfo} label={t('COMPONENT_DETAILS.FORM_COMPONENT_TYPE')} />
      <Field id={formConstants.fields.componentSubType} name={formConstants.fields.componentSubType} component={UneditableInfo} label={t('COMPONENT_DETAILS.FORM_COMPONENT_SUB_TYPE')} />
      <Field id={formConstants.fields.asset} name={formConstants.fields.asset} component={UneditableInfo} label={t('COMPONENT_DETAILS.FORM_PARENT_ASSET')} />
      <Field id={formConstants.fields.code} name={formConstants.fields.code} component={UneditableInfo} label={t('COMPONENT_DETAILS.FORM_CODE')} />
      <Field id={formConstants.fields.weight} name={formConstants.fields.weight} component={UneditableInfo} label={t('COMPONENT_DETAILS.FORM_WEIGHT')} />
      <Field id={formConstants.fields.manufacturer} name={formConstants.fields.manufacturer} component={UneditableInfo} label={t('COMPONENT_DETAILS.FORM_MANUFACTURER')} />
    </form>
  );
};

ChangeHierarchyDetailsSection.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default reduxForm({ form: FORMS.changeHierarchyDetailsSection, enableReinitialize: true, touchOnChange: true, destroyOnUnmount: true })(ChangeHierarchyDetailsSection);
