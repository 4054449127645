import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import ExpandableSection from '../../../../common/expandable-section/components/expandable-section';

import { isObject } from 'lodash';
import { connect } from 'react-redux';
import TableTemplate from '../../../../common/double-table/components/table-template';
import Button from '../../../../common/form/components/button';
import Helpers from '../../../../common/helpers';
import Modal from '../../../../common/modal/components/modal';
import ReducerHelpers from '../../../../common/reducer-helpers';
import RenderIf from '../../../../common/render-if/components/render-if';
import { unlinkDMSFileFromChecklistQuestionEvidence } from '../../../document-management/actions/dm-api-calls';
import ActionModal from '../../../document-management/components/modals/action-modal/action-modal';
import { fields } from '../../../document-management/constants/constants';
import { modules } from '../../constants/constants';
import '../../styles/work-order-section-evidence-files.scss';
import EditModuleItemFilesModal from '../right-toolbar/common/edit-module-item-files-modal';
import { getWorkorderChecklistEvidenceDMSFilesUploaded } from '../work-orders/actions/work-orders-api-calls';
import { formatFileTableCells, tableConfig } from './constants/work-order-section-evidence-files-constants';

const WorkOrderSectionEvidenceFiles = (
  {
    isMandatory,
    input,
    handleFileOpen,
    disabled,
    meta,
    touchField,
    module,
    defaultWorkorderDMSCategoryID,
    setModalData,
    updateFiles,
    unlinkDMSFileFromChecklistQuestionEvidence,
    getWorkorderChecklistEvidenceDMSFilesUploaded,
  },
  { t }
) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [confirmModalData, setConfirmModalData] = useState({ isOpen: false });
  const isMetaErrorObject = isObject(meta.error);

  const files = useMemo(() => {
    return input.value || [];
  }, [input.value]);

  const hasErrorAndTouched = useMemo(() => {
    return meta?.error && meta?.touched;
  }, [meta]);

  const [buttonClassName, isButtonActive, buttonVariant] = useMemo(() => {
    let className = '',
      active = false,
      variant = 'success-outline';

    if (hasErrorAndTouched || isMandatory) {
      className = 'white';
    }

    if (!hasErrorAndTouched && !isMandatory) {
      active = true;
    }

    if (hasErrorAndTouched) {
      variant = 'danger';
    } else if (isMandatory) {
      variant = 'success';
    }

    return [className, active, variant];
  }, [hasErrorAndTouched, isMandatory]);

  const openUploadEvidenceFilesModal = () => {
    const closeModal = () => {
      setModalData({ isOpen: false });
      // update the original files in form once popup is closed
      touchField && touchField();
    };

    setModalData({
      isOpen: true,
      type: '',
      title: t('EDIT_FILES'),
      CustomContent: ({ checklistQuestionEvidenceFiles, ...restProps }) => {
        return (
          <EditModuleItemFilesModal
            projectDMSCategories={[{ [fields.id]: defaultWorkorderDMSCategoryID, [fields.name]: 'WORK_ORDER.FILES_TITLE' }]}
            selectedModuleItem={module}
            moduleType={modules.checklistQuestionEvidence}
            overrideModuleTypeWithProp
            onFileUploaded={() => {
              getWorkorderChecklistEvidenceDMSFilesUploaded(module[fields.id], updatedFiles => {
                updateFiles(updatedFiles);
              });
            }}
            onFilesLinked={linkedFiles => {
              updateFiles([...checklistQuestionEvidenceFiles, ...(linkedFiles || [])]);
            }}
            onFileUnlinked={file => {
              const newFiles = ReducerHelpers.removeItemByProp(checklistQuestionEvidenceFiles, file, fields.fileId) || [];
              updateFiles(newFiles);
            }}
            checklistQuestionEvidenceFiles={checklistQuestionEvidenceFiles}
            maxFiles={5}
            {...restProps}
          />
        );
      },
      selectedQuestion: module,
      customClassName: 'modal-no-max-height modal-large',
      closeAction: closeModal,
      customCloseAction: closeModal,
    });
  };

  const handleUnlinkFile = file => {
    const unlinkFileConfirmed = () => {
      unlinkDMSFileFromChecklistQuestionEvidence(file[fields.sourceID], module[fields.id], defaultWorkorderDMSCategoryID, () => {
        const newFiles = ReducerHelpers.removeItemByProp(files, file, fields.fileId) || [];
        updateFiles(newFiles);
      });
      setConfirmModalData({ isOpen: false });
    };

    setConfirmModalData({
      type: '',
      isOpen: true,
      closeAction: () => setConfirmModalData({ isOpen: false }),
      customCloseAction: () => setConfirmModalData({ isOpen: false }),
      className: 'modal-no-max-height modal-medium',
      CustomContent: dynamicProps => <ActionModal {...dynamicProps} />,
      confirmButtonText: 'UNLINK_FILE',
      customConfirmAction: () => unlinkFileConfirmed(),
      firstParagraphProps: { name: Helpers.decodeIfStringEncoded(file.FileName || file[fields.realName]), component: module[fields.name] },
      firstParagraph: 'UNLINK_FILE_CONFIRMATION',
      title: t('UNLINK_FILE'),
    });
  };

  return (
    <div className="work-order-section-evidence-files">
      <div className="work-order-section-evidence-files-upload">
        <div className="work-order-section-evidence-files-upload__label-container">
          <ExpandableSection
            CustomComponent={() => (
              <p className="f-primary light-bold">
                {t('QUESTION_EVIDENCE_FILE.TITLE')} <span className="f-secondary-dark">({t('QUESTION_EVIDENCE_FILE.MAX_FILES.ERROR_LABEL')})</span>
              </p>
            )}
            handlePreventClick={() => {
              setIsExpanded(!isExpanded);
              touchField && touchField();
            }}
            customExpanded={isExpanded}
            hideExpandAction={!files || files.length === 0}
            className="work-order-section-evidence-files-upload__label-container"
          />
        </div>
        <div className="work-order-section-evidence-files-upload__button-container">
          <div>
            <Button
              text={`${t('QUESTION_EVIDENCE_FILE.UPLOAD_BUTTON_TEXT')}${isMandatory ? '*' : ''}`}
              width="md"
              height="sm"
              variant={buttonVariant}
              icon="upload"
              iconProps={{ size: 'sm', className: buttonClassName, active: isButtonActive }}
              disabled={disabled}
              onClick={openUploadEvidenceFilesModal}
            />
            <RenderIf if={hasErrorAndTouched}>
              <p className="f-secondary-red">{t(isMetaErrorObject ? meta?.error?.string : meta?.error, isMetaErrorObject ? meta?.error.params : null)}</p>
            </RenderIf>
          </div>
        </div>
      </div>
      <RenderIf if={isExpanded}>
        <div className="work-order-section-evidence-files-list">
          <TableTemplate
            data={files}
            tableConfig={tableConfig}
            filters={{}}
            handleFormatCells={formatFileTableCells}
            TotalItems={files.length}
            noDataText={t('QUESTION_EVIDENCE_FILE.TABLE_EMPTY_STATE')}
            onRowClick={file => handleFileOpen(file)}
            isLoading={false}
            showSearchBar={false}
            showInfo={false}
            selectedItem={null}
            onDeleteRow={handleUnlinkFile}
          />
        </div>
      </RenderIf>
      <Modal {...confirmModalData} />
    </div>
  );
};

WorkOrderSectionEvidenceFiles.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  unlinkDMSFileFromChecklistQuestionEvidence: (sourceId, questionId, categoryID, callback) => dispatch(unlinkDMSFileFromChecklistQuestionEvidence(sourceId, questionId, categoryID, callback)),
  getWorkorderChecklistEvidenceDMSFilesUploaded: (questionId, successCallback, errorCallback) => dispatch(getWorkorderChecklistEvidenceDMSFilesUploaded(questionId, successCallback, errorCallback)),
});

export default connect(null, mapDispatchToProps)(WorkOrderSectionEvidenceFiles);
