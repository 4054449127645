import actionTypes from '../constants/action-types';

import ReducerHelpers from '../../../common/reducer-helpers';

const teamsState = {
  teamsData: null,
  teamsLoading: false,
};

export const teamsTableReducer = (state = teamsState, payload) => {
  switch (payload.type) {
    case actionTypes.SET_TEAMS:
      return { ...state, teamsData: payload.data };
    case actionTypes.SET_TEAMS_LOADING:
      return { ...state, teamsLoading: payload.data };
    case actionTypes.AMEND_TEAMS:
      return { ...state, teamsData: [...state.teamsData, ...payload.data] };
    case actionTypes.UPDATE_TEAM_BY_PROP: {
      return { ...state, teamsData: ReducerHelpers.updateItemInListByProp(state.teamsData, payload.data.item, payload.data.prop) };
    }
    case actionTypes.HANDLE_ADD_TEAM: {
      return {
        ...state,
        teamsData: [payload.data, ...state.teamsData],
      };
    }

    case actionTypes.REMOVE_TEAM_BY_PROP:
      return {
        ...state,
        teamsData: ReducerHelpers.removeItemByProp(state.teamsData, payload.data.item, payload.data.prop),
      };
    default:
      return state;
  }
};
