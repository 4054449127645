import { fields } from '../constants/isolation-components-constants';

export const validate = values => {
  const errors = {};
  const { isolationState, isolationMethod, normalPosition } = fields;

  if (!values[isolationState.name]) {
    errors[isolationState.name] = isolationState.errorRequired;
  }

  if (values[isolationState.name] && values[isolationState.name].length > isolationState.maxLength) {
    errors[isolationState.name] = isolationState.errorMaxLength;
  }

  if (!values[isolationMethod.name]) {
    errors[isolationMethod.name] = isolationMethod.errorRequired;
  }

  if (values[isolationMethod.name] && values[isolationMethod.name].length > isolationMethod.maxLength) {
    errors[isolationMethod.name] = isolationMethod.errorMaxLength;
  }

  if (!values[normalPosition.name]) {
    errors[normalPosition.name] = normalPosition.errorRequired;
  }

  if (values[normalPosition.name] && values[normalPosition.name].length > normalPosition.maxLength) {
    errors[normalPosition.name] = normalPosition.errorMaxLength;
  }

  return errors;
};
