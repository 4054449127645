import { getStoredState, REHYDRATE } from 'redux-persist';

export const crossTabListener = (store, persistConfig, removeListener) => {
  return async () => {
    const { userReducer } = store.getState();
    const state = await getStoredState(persistConfig);

    if (!userReducer.DropbboxAccessToken) {
      store.dispatch({
        type: REHYDRATE,
        key: persistConfig.key,
        payload: state,
      });
    } else {
      removeListener();
    }
  };
};
