import { store } from '../../../index';
import { toggleGenericModal } from './action-creators';
import Helpers from '../../../common/helpers';
import { modalTypeConstants } from '../constants/modal-constants';

export const errorModal = (isOpen, error, title) => {
  return dispatch => {
    const message = Helpers.getErrorContent(error);
    title = Helpers.getErrorTitle(title, error);
    dispatch(toggleGenericModal(isOpen, message, title, modalTypeConstants.error));
  };
};
export const notificationModalCustom = (isOpen, message, title, type = modalTypeConstants.info) => {
  return dispatch => {
    dispatch(toggleGenericModal(isOpen, message, title, type));
  };
};
export const dispatchErrorModalWired = (isOpen, error, title) => store.dispatch(errorModal(isOpen, error, title));
