import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import Button from '../../../../common/form/components/button';

import { userFields } from '../../constants/team-constants';

import '../../styles/role-users-popup.scss';

class RoleUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
    };
    // this.searchUsersSearchChangeDebounced = debounce(props.searchUsers, 500);
  }

  componentDidMount = () => {
    const { searchUsers } = this.props;

    searchUsers(users => {
      this.setState({ users: users || [] });
    });
  };

  // handleUsersSearch = e => {
  //   this.searchUsersSearchChangeDebounced(e.target.value);
  // };

  render() {
    const { t } = this.context;
    const { users } = this.state;
    const { closeAction } = this.props;

    return (
      <div className="role-users">
        {/* <SearchInput onChange={this.handleUsersSearch} placeholder={t('PROJECT.SEARCH.PLACEHOLDER')} includeDropdown={true} items={users || []} emptyStateLabel={'ROLE_USERS_POPUP.EMPTY_SEARCH'} /> */}

        <div className="added-items">
          <div className="added-items-list">
            {users &&
              users.map((item, index) => {
                return (
                  <div className="item-r" key={index}>
                    <p className="f-primary c-title">{`${index + 1}. ${item[userFields.name]}`}</p>
                  </div>
                );
              })}

            {isEmpty(users) && (
              <div className="empty-state">
                <p className="f-secondary-dark">{t('ROLE_USERS_POPUP.NO_MEMBERS')} </p>
              </div>
            )}
          </div>
        </div>

        <Button type="button" text={t('ROLE_USERS_POPUP.OK')} onClick={closeAction} />
      </div>
    );
  }
}

RoleUsers.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default RoleUsers;
