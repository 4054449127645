import { FEATURES } from '../../../common/constants';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../common/permissions-constants';

export const MODULES = [
  {
    icon: 'component-hierarchy',
    label: 'PROJECT_SETTINGS.COMPONENT_HIERARCHY',
    feature: FEATURES.componentHierarchy,
    visibleFor: [PERMISSIONS[PERMISSION_TYPES.componentsHierarchy].view.name],
    route: 'componentHierarchy',
  },
  {
    icon: 'templates',
    label: 'PROJECT_SETTINGS.TEMPLATES',
    feature: FEATURES.workManagement,
    visibleFor: [PERMISSIONS[PERMISSION_TYPES.permitTemplates].view.name, PERMISSIONS[PERMISSION_TYPES.isolationTemplates].view.name, PERMISSIONS[PERMISSION_TYPES.senseChecks].view.name],
    route: 'templates',
    hidden: !FEATURES.permits.visible && !FEATURES.isolations.visible,
  },
];
