import { formConstants } from '../constants/constants';

export const validate = values => {
  const errors = {};

  if (!values[formConstants.fields.intervalNumber]) {
    errors[formConstants.fields.intervalNumber] = 'WORK_ORDER.INTERVAL_NUMBER_REQUIRED';
  }

  if (!values[formConstants.fields.intervalId]) {
    errors[formConstants.fields.intervalId] = 'WORK_ORDER.INTERVAL_TYPE_REQUIRED';
  }

  return errors;
};
