import loginActionTypes from '../constants/action-types';

const errorModalState = {
  isOpen: false,
  content: '',
  title: '',
  passwordFormError: null,
};

export const errorModalReducer = (state = errorModalState, payload) => {
  switch (payload.type) {
    case loginActionTypes.HANDLE_GENERIC_MODAL:
      return { ...state, ...payload.data };
    case loginActionTypes.HANDLE_PASSWORD_VERIFICATION_ERROR:
      return { ...state, passwordFormError: payload.data };
    case loginActionTypes.HANDLE_REJECTION_NOTE_MODAL:
      return { ...state, ...payload.data };
    default:
      return state;
  }
};
