export const tabNames = {
  details: 'DETAILS',
  pmTasks: 'PM Tasks',
};

export const toolbarItems = {
  [tabNames.details]: {
    name: tabNames.details,
    label: 'MAINTENANCE_REGIME.TAB_DETAILS',
  },
  [tabNames.pmTasks]: {
    name: tabNames.pmTasks,
    label: 'MAINTENANCE_REGIME.PM_TASKS',
  },
};
