import mfaApi from '../../../api/mfa/actions';
import { handleGlobalLoader } from '../../../common/global-loader/actions/loader-actions';
import { setMfaTypeCodes, setMfaTypeData, setMfaTypes } from './action-creators';

import loginConstants from '../../login/constants/login-constants';

import { isEmpty } from 'lodash';

export const getMfaTypes = () => {
  return async dispatch => {
    try {
      dispatch(handleGlobalLoader(true));
      dispatch(setMfaTypeData({}));
      dispatch(setMfaTypeCodes([]));

      const res = await mfaApi.getMfaTypes();

      const { Data } = res.data;
      if (!isEmpty(Data)) {
        const dataToSave = [];

        Object.keys(Data).forEach(key => {
          dataToSave.push({ ...Data[key], type: key, disabled: key === loginConstants.verificationTypes.sms });
        });

        dispatch(setMfaTypes(dataToSave));
        dispatch(handleGlobalLoader(false));
      }
    } catch (err) {
      dispatch(handleGlobalLoader(false));
    }
  };
};

export const setupMfa = (type, callback = () => null) => {
  // /account/mfa/authenticator/setup
  return async dispatch => {
    try {
      dispatch(handleGlobalLoader(true));

      let res = null;
      switch (type) {
        case loginConstants.verificationTypes.authenticatior:
          res = await mfaApi.setupAuthenticatior();
          const { AuthenticatorData } = res.data.Data;
          dispatch(setMfaTypeData(AuthenticatorData));
          break;
        case loginConstants.verificationTypes.sms:
          // dispatch(setMfaTypeData(res.data.Data));
          break;
        case loginConstants.verificationTypes.email:
          res = await mfaApi.setupEmail({ Method: type });
          // dispatch(setMfaTypeData(res.data.Data));
          break;
        default:
          res = null;
          break;
      }

      callback();
      dispatch(handleGlobalLoader(false));
    } catch (err) {}
    dispatch(handleGlobalLoader(false));
  };
};

export const verifyMfa = (type, data, successCallback = () => null, errorCallback = () => null) => {
  return async dispatch => {
    try {
      dispatch(handleGlobalLoader(true));
      let res = null;

      switch (type) {
        case loginConstants.verificationTypes.authenticatior:
          res = await mfaApi.verifyAuthenticator(data);
          const { Codes, Verified } = res.data.Data;
          if (Verified) {
            dispatch(setMfaTypeCodes(Codes));
            successCallback();
          } else {
            errorCallback();
          }

          break;
        case loginConstants.verificationTypes.sms:
          // TO DO
          break;
        case loginConstants.verificationTypes.email:
          res = await mfaApi.verifySetupEmail(data);
          successCallback();
          break;
        default:
          res = null;
          break;
      }

      dispatch(handleGlobalLoader(false));
    } catch (err) {}
    dispatch(handleGlobalLoader(false));
  };
};
