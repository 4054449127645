import React from 'react';
import PropTypes from 'prop-types';

const HistoryItemFileDisplay = ({ fileName, to, isDeleted }) => {
  return <span className={isDeleted ? 'f-secondary-red' : 'f-secondary-green'}>{fileName}</span>;
};

HistoryItemFileDisplay.propTypes = {
  fileName: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  isDeleted: PropTypes.bool,
};

export default HistoryItemFileDisplay;
