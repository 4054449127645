import { debounce, find, findLast, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { change, getFormValues, isDirty } from 'redux-form';
import { FORMS } from '../../../../../../common/constants';
import Helpers from '../../../../../../common/helpers';
import { setGenericNotification } from '../../../../../../common/notification/actions/action-creators';
import Stepper from '../../../../../../common/stepper/components/stepper';
import useConfirmOnInspectionExit from '../../../../../../hooks/use-confirm-on-inspection-exit';
import { getGraphingGroupDetails, getTimeSeriesAggregationTypes, getTimeSeriesChunks } from '../../actions/measurement-location-actions';
import { fetchMeasurementPoints, getMeasurementPointsTimeSeriesGraph } from '../../actions/measurement-point-actions';
import { formConstants as mlFormConstants } from '../../constants/measurement-location-constants';
import { formConstants as mpFormConstants } from '../../constants/measurement-point-constants';
import { aggregationFields, chunkFields, defaultGraphSettingsValues, formConstants, settingsFormConstants, steps, timePeriodFields, timePeriods } from '../../constants/time-series-graph-constants';
import '../../styles/create-graphing-group-modal.scss';
import { createGraphingGroupValidate } from '../../validators/create-graphing-group-validator';
import CreateGraphingGroupForm from './create-graphing-group-form';

const CreateGraphingGroupModal = (props, { t }) => {
  const {
    user,
    className,
    customCloseAction,
    getAggregationTypes,
    getChunks,
    fetchMeasurementPoints,
    projectID,
    formValues,
    handleFormSubmit,
    measurementLocation,
    graphingGroup,
    getGraphingGroupDetails,
    changeField,
    getMeasurementPointsTimeSeriesGraph,
    router,
    isDirty,
    handleFormSubmitSuccess,
  } = props;

  const [activeStep, setActiveStep] = useState(steps.firstStep);
  const [lastStep, setLastStep] = useState(steps.secondStep);
  const [stepperData, setStepperData] = useState([
    {
      name: 'CREATE_GRAPHING_GROUP.STEPPER.FIRST_STEP.TITLE',
      stepValue: steps.firstStep,
      description: 'CREATE_GRAPHING_GROUP.STEPPER.FIRST_STEP.DESCRIPTION',
    },
    {
      name: 'CREATE_GRAPHING_GROUP.STEPPER.SECOND_STEP.TITLE',
      stepValue: steps.secondStep,
      description: 'CREATE_GRAPHING_GROUP.STEPPER.SECOND_STEP.DESCRIPTION',
    },
  ]);
  const [initialValues, setInitialValues] = useState({});
  const [aggregationList, setAggregationList] = useState([]);
  const [chunks, setChunks] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [graphLoading, setGraphLoading] = useState(false);
  const timePeriodsList = timePeriods(t);

  const formIsValid = useMemo(() => {
    const errors = { ...(createGraphingGroupValidate(formValues, { activeStep: steps.firstStep }) || {}), ...(createGraphingGroupValidate(formValues, { activeStep: steps.secondStep }) || {}) };
    return isEmpty(errors);
  }, [formValues]);

  useEffect(() => {
    const mappedStepperData = stepperData.map(step => ({ ...step, isHidden: step.access ? !Helpers.hasAccess({ user, ...step.access }) : false }));
    const lastStepData = findLast(mappedStepperData, step => !step.isHidden);
    const newLastStep = !isEmpty(lastStepData) ? lastStepData.stepValue : lastStep;
    setLastStep(newLastStep);
    setStepperData(mappedStepperData.filter(step => !step.isHidden));
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // aggregationList is in params because it can be called before the state is updated, pass aggregation list or any other param after mutation
  const applyGraphingGroupDetails = (graphingGroupDetails, aggregationList, chunks) => {
    const selectedTimePeriodKey = graphingGroupDetails?.[formConstants.timePeriod];
    const dateRange = Helpers.getDateRangeByTimePeriod(selectedTimePeriodKey, graphingGroupDetails?.[formConstants.startDateFrom], graphingGroupDetails?.[formConstants.startDateTo]);
    const selectedAggregationKeys = Helpers.parseAggregationsFromString(graphingGroupDetails?.[formConstants.aggregation]);
    let initialAggregation = [];
    (selectedAggregationKeys || []).forEach(key => {
      initialAggregation.push(Helpers.getObjectByKey(aggregationList, aggregationFields.key, key));
    });

    // Set initial settings form values
    const values = {
      ...graphingGroupDetails,
      [settingsFormConstants.aggregation.name]: initialAggregation,
      [settingsFormConstants.timePeriod.name]: Helpers.getObjectByKey(timePeriodsList, timePeriodFields.value, selectedTimePeriodKey) || timePeriodsList[timePeriodsList.length - 1],
      [settingsFormConstants.chunks.name]: Helpers.getObjectByKey(chunks, chunkFields.key, graphingGroupDetails?.[formConstants.chunk]) || chunks[chunks.length - 1],
      [settingsFormConstants.dateFrom.name]: new Date(dateRange.DateFrom * 1000),
      [settingsFormConstants.dateTo.name]: new Date(dateRange.DateTo * 1000),
    };

    setInitialValues(values);
    fetchGraphData(values[formConstants.measurementPoints], values);
  };

  const fetchData = async () => {
    const aggregationList = await getAggregationTypes();
    const chunks = await getChunks();
    setAggregationList(aggregationList);
    setChunks(chunks);
    if (graphingGroup?.[formConstants.id] && graphingGroup?.[formConstants.id] > 0) {
      getGraphingGroupDetails(
        measurementLocation[mlFormConstants.fields.id],
        graphingGroup?.[formConstants.id],
        data => {
          applyGraphingGroupDetails(data, aggregationList, chunks);
        },
        () => {}
      );
    } else {
      const initialFormValues = defaultGraphSettingsValues(aggregationList, chunks, timePeriodsList);
      setInitialValues(initialFormValues);
    }
  };

  const handleSubmit = values => {
    if (activeStep === lastStep) {
      const formattedValues = {
        ...values,
        [settingsFormConstants.aggregation.name]: (values[settingsFormConstants.aggregation.name] || []).map(el => el[aggregationFields.key]),
        [settingsFormConstants.chunks.name]: values[settingsFormConstants.chunks.name]?.[chunkFields.key],
        [formConstants.startDateFrom]: Helpers.dateToUnix(values[settingsFormConstants.dateFrom.name]),
        [formConstants.startDateTo]: Helpers.dateToUnix(values[settingsFormConstants.dateTo.name]),
        [formConstants.timePeriod]: values[settingsFormConstants.timePeriod.name]?.[timePeriodFields.value],
      };

      handleFormSubmit && handleFormSubmit(formattedValues);

      setStep(true);
    }
    setStep(true);
  };

  const setStep = forward => {
    const nextStep = forward ? activeStep + 1 : activeStep - 1;
    if (nextStep < steps.firstStep || nextStep > lastStep) {
      handleFormSubmitSuccess && handleFormSubmitSuccess();
      return;
    }
    setActiveStep(nextStep);
  };

  const activeStepObj = find(stepperData, item => item.stepValue === activeStep);

  const fetchGraphData = useCallback((measurementPoints, values) => {
    if (isEmpty(values)) {
      return;
    }

    setGraphLoading(true);
    setGraphData([]);
    const formatedGraphSettings = {
      ...values,
      [settingsFormConstants.aggregation.name]: (values[settingsFormConstants.aggregation.name] || []).map(el => el[aggregationFields.key]),
      [settingsFormConstants.chunks.name]: values[settingsFormConstants.chunks.name]?.[chunkFields.key],
      [settingsFormConstants.dateFrom.name]: Helpers.dateToUnix(values[settingsFormConstants.dateFrom.name]),
      [settingsFormConstants.dateTo.name]: Helpers.dateToUnix(values[settingsFormConstants.dateTo.name]),
      [settingsFormConstants.timePeriod.name]: values[settingsFormConstants.timePeriod.name]?.[timePeriodFields.value],
    };

    getMeasurementPointsTimeSeriesGraph(
      measurementPoints,
      formatedGraphSettings,
      data => {
        try {
          const mergedData = (data || []).map(data => ({
            ...data,
            Series: Helpers.mergeSeriesData(data.Series, data[mpFormConstants.fields.scaleFactor] || undefined, data[mpFormConstants.fields.inverted]),
          })); // TODO: Pass ScaleFactor and Inverted
          setGraphData(mergedData);
          setGraphLoading(false);
        } catch (e) {
          setGraphLoading(false);
          console.error('Unable to format chart data', e);
        }
      },
      () => setGraphLoading(false)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFormOnChange = useCallback(
    debounce(values => {
      const errors = { ...(createGraphingGroupValidate(values, { activeStep: steps.firstStep }) || {}), ...(createGraphingGroupValidate(values, { activeStep: steps.secondStep }) || {}) };
      if (isEmpty(errors)) {
        // Form is valid fetch the data
        fetchGraphData(values[formConstants.measurementPoints], values);
      }
    }, 300),
    [fetchGraphData]
  );

  useConfirmOnInspectionExit({
    title: t('GENERIC_UNSAVED_CHANGES_TITLE'),
    message: t('GENERIC_UNSAVED_CHANGES_MESSAGE'),
    router,
    route: router.location,
    isDirty: isDirty,
    clearUnsavedChangesDirty: () => null,
  });

  return (
    <div className={`create-graphing-group-modal ${className || ''}`}>
      <Stepper {...{ stepperData, activeStep }} />
      <CreateGraphingGroupForm
        onSubmit={handleSubmit}
        stepAction={setStep}
        activeStep={activeStep}
        activeStepObj={activeStepObj}
        lastStep={lastStep}
        handleCancel={customCloseAction}
        initialValues={initialValues}
        aggregationList={aggregationList}
        chunks={chunks}
        timePeriodsList={timePeriodsList}
        fetchMeasurementPoints={fetchMeasurementPoints}
        projectID={projectID}
        formValues={formValues}
        changeField={changeField}
        graphData={graphData}
        graphLoading={graphLoading}
        formIsValid={formIsValid}
        onChange={handleFormOnChange}
        measurementLocation={measurementLocation}
      />
    </div>
  );
};

CreateGraphingGroupModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    user: state.userReducer,
    formValues: getFormValues(FORMS.createGraphingGroupForm)(state),
    isDirty: isDirty(FORMS.createGraphingGroupForm)(state),
  };
};

const mapDispatchToProps = dispatch => ({
  changeField: (fieldName, value) => dispatch(change(FORMS.createGraphingGroupForm, fieldName, value)),
  setGenericNotification: data => dispatch(setGenericNotification(data)),
  getAggregationTypes: () => dispatch(getTimeSeriesAggregationTypes()),
  getChunks: () => dispatch(getTimeSeriesChunks()),
  fetchMeasurementPoints: (filters, loadMore, successCallback, errorCallback, saveToReducer) => dispatch(fetchMeasurementPoints(filters, loadMore, successCallback, errorCallback, saveToReducer)),
  getGraphingGroupDetails: (measurementLocationId, graphingGroupId, successCallback, errorCallback) =>
    dispatch(getGraphingGroupDetails(measurementLocationId, graphingGroupId, successCallback, errorCallback)),
  getMeasurementPointsTimeSeriesGraph: (measurementPoints, graphSettings, successCallback, errorCallback) =>
    dispatch(getMeasurementPointsTimeSeriesGraph(measurementPoints, graphSettings, successCallback, errorCallback)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateGraphingGroupModal));
