import { isEmpty } from 'lodash';
import { formConstants, keyFields } from '../constants/issue-permit-constants';

export const validate = (values, props) => {
  const errors = {};
  const assignedKeysErrors = {};
  const { assignedKeys, contractor, assigneeSignature, witnessSignature } = formConstants.fields;

  if (!values[contractor.name]) {
    errors[contractor.name] = contractor.errorRequired;
  }

  if (props?.signatureRequired) {
    if (!values[assigneeSignature.name]) {
      errors[assigneeSignature.name] = assigneeSignature.errorRequired;
    }

    if (!values[witnessSignature.name]) {
      errors[witnessSignature.name] = witnessSignature.errorRequired;
    }
  }

  (values[assignedKeys] || []).forEach((assignedKey, index) => {
    const assignedKeyErrors = {};
    if (!assignedKey || !assignedKey[keyFields.id]) {
      assignedKeyErrors[keyFields.selectedKey] = 'PERMIT.ISSUE_PERMIT_FORM.LOCK_KEY.IS_REQUIRED';
      assignedKeysErrors[index] = assignedKeyErrors;
    }
  });
  if (!isEmpty(assignedKeysErrors)) {
    errors[assignedKeys] = assignedKeysErrors;
  }

  return errors;
};
