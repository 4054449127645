export const commentsField = 'Comments';

export const fields = {
  comment: {
    name: 'Comment',
    label: 'ISOLATION_CERTIFICATE.COMMENT_LABEL',
    maxChars: 500,
  },
  sectionId: {
    name: 'ID',
  },
  moduleSectionId: {
    name: 'ModuleSectionId',
  },
  createdBy: {
    name: 'CreatedByUser',
  },
  createdAt: {
    name: 'CreatedAt',
  },
  commentText: {
    name: 'Value',
  },
  id: {
    name: 'ID',
  },
};
