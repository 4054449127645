import HistoryItemColorDisplay from '../../../common/history-item/components/history-item-color-display';
import HistoryItemFileDisplay from '../../../common/history-item/components/history-item-file-display';
import Icon from '../../../common/icon/components/icon';
import ComponentMaintenanceRegimesTab from '../components/maintenance-regimes/components/component-maintenance-regimes-tab';
import ComponentsNotificationsTab from '../components/notifications/components/component-notifications-tab';
import ComponentsObservationsTab from '../components/observations/components/component-observations-tab';
import { validate } from '../components/right-toolbar/validators/component-validator';
import ComponentWorkOrdersTab from '../components/work-orders/components/component-work-orders-tab';

import { FEATURES } from '../../../common/constants';

export const componentsSection = {
  title: 'DETAILS',
  translatableTitle: true,
  showError: 'showError',
  validate: validate,
  formName: 'component-form',
  skipOrdering: true,
};

export const componentDropdownActions = {
  points: 'toggle-points',
  point: 'point-action',
  items3d: 'toggle-3Ditems',
  box: 'box-action',
  sphere: 'sphere-action',
  cone: 'cone-action',
  cylinder: 'cylinder-action',
  tube: 'tube-action',
};

export const categoryTools = [
  {
    id: '1',
    icon: ({ className, ...restProps }) => <Icon name="componentPoints" size="xs" handleHover={false} className={`${className} concern`} {...restProps} />,
    title: 'COMPONENT_TOOLS.POINTS',
    action: componentDropdownActions.points,
    className: '',
  },
  {
    id: '2',
    icon: ({ className, ...restProps }) => <Icon name="measurementArea" size="xs" handleHover={false} className={`${className} concern`} {...restProps} />,
    title: 'COMPONENT_TOOLS.3D_ITEMS',
    action: componentDropdownActions.items3d,
    children: [
      {
        id: '21',
        icon: ({ className, ...restProps }) => <Icon name="componentBox" size="xs" handleHover={false} className={`${className} concern`} {...restProps} />,
        title: 'COMPONENT_TOOLS.3D_ITEMS.BOX',
        action: componentDropdownActions.box,
        className: 'coming-soon ',
      },
      {
        id: '22',
        icon: ({ className, ...restProps }) => <Icon name="componentSphere" size="xs" handleHover={false} className={`${className} concern`} {...restProps} />,
        title: 'COMPONENT_TOOLS.3D_ITEMS.SPHERE',
        action: componentDropdownActions.sphere,
        className: 'coming-soon ',
      },
      {
        id: '23',
        icon: ({ className, ...restProps }) => <Icon name="componentCone" size="xs" handleHover={false} className={`${className} concern`} {...restProps} />,
        title: 'COMPONENT_TOOLS.3D_ITEMS.CONE',
        action: componentDropdownActions.cone,
        className: 'coming-soon ',
      },
      {
        id: '24',
        icon: ({ className, ...restProps }) => <Icon name="componentCylinder" size="xs" handleHover={false} className={`${className} concern`} {...restProps} />,
        title: 'COMPONENT_TOOLS.3D_ITEMS.CYLINDER',
        action: componentDropdownActions.cylinder,
        className: 'coming-soon ',
      },
      {
        id: '25',
        icon: ({ className, ...restProps }) => <Icon name="componentTube" size="xs" handleHover={false} className={`${className} concern`} {...restProps} />,
        title: 'COMPONENT_TOOLS.3D_ITEMS.TUBE',
        action: componentDropdownActions.tube,
        className: 'coming-soon ',
      },
    ],
  },
];

export const formConstants = {
  fields: {
    id: 'ID',
    name: 'Name',
    color: 'Colour',
    code: 'Code',
    asset: 'AssetType',
    componentType: 'Type',
    componentSubType: 'SubType',
    manufacturer: 'Manufacturer',
    material: 'Materials',
    weight: 'Weight',
    description: 'Description',
    points: 'Geometry.coordinates',
    camPosition: 'CameraPosition.coordinates',
    date: 'DateCreated',
    createdBy: 'CreatedByUser.Name',
    createdByUserID: 'CreatedByUser.ID',
    unresolvedDefectsCount: 'OpenDefectsCounter',
    systemType: 'SystemType',
    createdById: 'CreatedBy',
    integrationName: 'IntegrationName',
    location: 'Location',
    hierarchy: 'Hierarchy',
    critical: 'Critical',
  },
};

export const mappedComponentFields = {
  [formConstants.fields.id]: 'ComponentID',
  [formConstants.fields.name]: 'ComponentName',
  [formConstants.fields.componentType]: 'ComponentType',
  [formConstants.fields.componentSubType]: 'ComponentSubType',
  [formConstants.fields.code]: 'ComponentCode',
  [formConstants.fields.createdBy]: 'CreatedBy',
};

export const tabNames = {
  details: 'DETAILS',
  comments: 'COMMENTS',
  defects: 'DEFECTS',
  history: 'HISTORY',
  tasks: 'TASKS',
  work: 'WORK',
};

export const toolbarItems = {
  [tabNames.details]: {
    name: tabNames.details,
    label: 'COMPONENET_FORM_TAB.DETAILS',
  },
  [tabNames.comments]: {
    name: tabNames.comments,
    label: 'COMPONENET_FORM_TAB.COMMENTS',
  },
  [tabNames.history]: {
    name: tabNames.history,
    label: 'COMPONENET_FORM_TAB.HISTORY',
  },
  [tabNames.defects]: {
    name: tabNames.defects,
    label: 'COMPONENET_FORM_TAB.DEFECTS',
  },
  [tabNames.tasks]: {
    name: tabNames.tasks,
    label: 'COMPONENET_FORM_TAB.TASKS',
  },
  [tabNames.work]: {
    name: tabNames.work,
    label: 'COMPONENT_FORM_TAB.WORK',
  },
};

export const preventedFieldsPriorComponentIsCreated = {
  upload: 'upload',
  customFields: 'add-custom-field',
};

export const showFields = [
  {
    name: formConstants.fields.name,
  },
  {
    name: formConstants.fields.color,
  },
  {
    name: formConstants.fields.code,
  },
  {
    name: formConstants.fields.asset,
  },
  {
    name: formConstants.fields.componentType,
  },
  {
    name: formConstants.fields.componentSubType,
  },
  {
    name: formConstants.fields.manufacturer,
  },
  {
    name: formConstants.fields.weight,
  },
  {
    name: formConstants.fields.description,
  },
  {
    name: formConstants.fields.date,
    type: 'date',
  },
];

export const defaultComponentName = 'No Component';

export const DefaultComponent = camPos => ({
  SystemType: 'Component',
  Name: 'New Equipment',
  ComponentType: 'Default',
  ComponentCode: '',
  DefectType: 'Default',
  Drawing: { objects: [] },
  Geometry: {
    type: 'Point',
    coordinates: [],
  },
  CameraPosition: camPos || {
    coordinates: [0, 0, 0],
  },
  commentsTemp: [],
});

export const textAreaMaxChars = 2000;

export const uploadGroups = {
  mechanicalAndTechnical: 'MECHANICAL_AND_TECHNICAL',
  operations: 'OPERATIONS_AND_MAINTENANCE',
  pAndId: 'P_AND_ID',
  other: 'OTHER_FILES',
  images: 'IMAGES',
  // taggedMechanical: 'TAGGED_FILE',
  // taggedPAndId: 'TAGGED_FILE',
  taggedPAndId: 'TAGGED_PANDID_FILE',
  taggedMechanical: 'TAGGED_MECHANICAL_FILE',
  uncategorised: 'NO_CATEGORY',
};

// component tasks
export const statusNames = {
  inProgress: 'STARTED',
  completed: 'FINISHED',
  unableToComplete: 'UNABLE_TO_COMPLETE',
  overdue: 'EXPIRED',
  upcoming: 'UPCOMING',
};

export const tableConfig = [
  {
    title: 'COMPONENTS.ID',
    key: formConstants.fields.id,
    enableSort: true,
  },
  {
    title: 'COMPONENTS.NAME',
    key: formConstants.fields.name,
    enableSort: true,
    type: formConstants.fields.name,
    className: 'column-name',
  },
  {
    key: formConstants.fields.unresolvedDefectsCount,
    type: formConstants.fields.unresolvedDefectsCount,
    className: 'inline-right',
  },
];

export const tableConfigLite = [
  {
    title: 'COMPONENTS.ID',
    key: formConstants.fields.id,
    enableSort: true,
  },
  {
    title: 'COMPONENTS.NAME',
    key: formConstants.fields.name,
    enableSort: true,
    type: formConstants.fields.name,
    className: 'big',
  },
  {
    title: 'COMPONENTS.DESCRIPTION',
    key: formConstants.fields.description,
    enableSort: true,
    type: formConstants.fields.description,
    className: 'description-column',
  },
  {
    title: 'COMPONENT_HIERARCHY_TABLE.CRITICAL',
    enableSort: true,
    key: formConstants.fields.critical,
    type: formConstants.fields.critical,
    className: 'inline-left',
  },
  {
    key: formConstants.fields.unresolvedDefectsCount,
    type: formConstants.fields.unresolvedDefectsCount,
    className: 'w-24-fixed',
  },
  {
    title: 'COMPONENTS.CODE',
    key: formConstants.fields.code,
    enableSort: true,
    type: formConstants.fields.code,
  },
  {
    title: 'COMPONENTS.TYPE',
    key: formConstants.fields.componentType,
    enableSort: true,
    type: formConstants.fields.componentType,
  },
  {
    title: 'COMPONENTS.SUB_TYPE',
    key: formConstants.fields.componentSubType,
    enableSort: true,
    type: formConstants.fields.componentSubType,
  },
  {
    title: 'COMPONENTS.PARENT_ASSET',
    key: formConstants.fields.asset,
    enableSort: true,
    type: formConstants.fields.asset,
  },
];

export const sortingDirection = {
  asc: 'ASC',
  desc: 'DESC',
};

export const filterParams = {
  searchText: 'SearchText',
  includedIDs: 'IncludedIDs',
  dateFrom: 'DateFrom',
  dateTo: 'DateTo',
  componentTypeFilter: 'ComponentTypeFilter',
  componentSubTypeFilter: 'ComponentSubTypeFilter',
  componentAssetTypeFilter: 'ComponentAssetTypeFilter',
  createdByFilter: 'CreatedByFilter',
  hasNotificationFilter: 'HasNotificationFilter',
  hasObservationFilter: 'HasObservationFilter',
  hasWorkOrderFilter: 'HasWorkOrderFilter',
  hasNotLocationFilter: 'HasNotLocationFilter',
  lastSeen: 'LastSeen',
  perPage: 'PerPage',
  sortByColumn: 'SortByColumn',
  sortDirection: 'SortDirection',
  totalItems: 'TotalNumber',
  hasNext: 'HasNext',
  projectID: 'ProjectID',
  hierarchyID: 'HierarchyID',
  isCriticalEquipmentFilter: 'IsCriticalEquipmentFilter',
  hasLocationFilter: 'HasLocationFilter',
};

export const defaultFilter = {
  [filterParams.lastSeen]: 0,
  [filterParams.perPage]: 20,
  [filterParams.totalItems]: 0,
  [filterParams.hasNext]: false,
  [filterParams.sortByColumn]: formConstants.fields.id,
  [filterParams.sortDirection]: sortingDirection.asc,
  [filterParams.searchText]: '',
  [filterParams.hierarchyID]: null,
};

export const historyItems = {
  Name: {
    title: 'COMPONENT_HISTORY.NAME',
  },
  Colour: {
    title: 'COMPONENT_HISTORY.COLOUR',
    component: HistoryItemColorDisplay,
  },
  Code: {
    title: 'COMPONENT_HISTORY.CODE',
  },
  AssetType: {
    title: 'COMPONENT_HISTORY.ASSET_TYPE',
  },
  Type: {
    title: 'COMPONENT_HISTORY.TYPE',
  },
  SubType: {
    title: 'COMPONENT_HISTORY.SUB_TYPE',
  },
  Manufacturer: {
    title: 'COMPONENT_HISTORY.MANUFACTURER',
  },
  Materials: {
    title: 'COMPONENT_HISTORY.MATERIALS',
  },
  Weight: {
    title: 'COMPONENT_HISTORY.WEIGHT',
  },
  Description: {
    title: 'COMPONENT_HISTORY.DESCRIPTION',
  },
  Geometry: {
    subProp: 'coordinates',
    title: 'COMPONENT_HISTORY.GEOMETRY',
    decimals: 2,
  },
  CameraPosition: {
    title: 'COMPONENT_HISTORY.CAMERA_POSITION',
    decimals: 2,
  },
  SystemType: {
    title: 'COMPONENT_HISTORY.SYSTEM_TYPE',
  },
  Files: {
    title: 'COMPONENT_HISTORY.FILE',
    isFile: true,
    component: HistoryItemFileDisplay,
  },
  Property: {
    isCustomProp: true,
    nonTranslatableTitle: true,
  },
};

export const componentModificationEvents = {
  component_created: {
    title: 'COMPONENT_HISTORY.CREATED_EVENT',
    event: 'component_created',
  },
  component_updated: {
    title: 'COMPONENT_HISTORY.UPDATED_EVENT',
    event: 'component_updated',
  },
  component_deleted: {
    title: 'COMPONENT_HISTORY.DELETE_EVENT',
    event: 'component_deleted',
    titleClass: 'f-secondary-red',
  },
  component_file_linked: {
    title: 'COMPONENT_HISTORY.FILE_UPLOADED',
    event: 'component_file_linked',
  },
  component_file_unlinked: {
    title: 'COMPONENT_HISTORY.FILE_DELETED',
    event: 'component_file_unlinked',
    titleClass: 'f-secondary-red',
  },
  component_custom_property_added: {
    title: 'COMPONENT_HISTORY.CUSTOM_PROPERTY_ADDED',
    event: 'component_custom_property_added',
  },
  component_custom_property_deleted: {
    title: 'COMPONENT_HISTORY.CUSTOM_PROPERTY_DELETED',
    event: 'component_custom_property_deleted',
    titleClass: 'f-secondary-red',
  },
  component_custom_property_updated: {
    title: 'COMPONENT_HISTORY.CUSTOM_PROPERTY_UPDATED',
    event: 'component_custom_property_updated',
  },
  component_position_updated: {
    title: 'COMPONENT_HISTORY.COMPONENT_POSITION_UPDATED',
    event: 'component_position_updated',
  },
};

export const historyFilterParams = {
  lastSeen: 'LastSeen',
  perPage: 'PerPage',
  sortByColumn: 'ModifiedAt',
  sortDirection: 'SortDirection',
  totalItems: 'TotalNumber',
  hasNext: 'HasNext',
};

export const historyDefaultPagingObj = {
  [historyFilterParams.lastSeen]: 0,
  [historyFilterParams.perPage]: 20,
  [historyFilterParams.totalItems]: 0,
  [historyFilterParams.hasNext]: false,
};

export const workTabs = [
  { title: 'INSPECTION_DEFECTS', tabKey: 'observation', Children: ComponentsObservationsTab, visible: true },
  { title: 'INSPECTION_NOTIFICATIONS', tabKey: 'notification', Children: ComponentsNotificationsTab, visible: FEATURES.notifications.visible },
  { title: 'INSPECTION_WORKORDERS', tabKey: 'workOrder', Children: ComponentWorkOrdersTab, visible: FEATURES.workorders.visible },
  { title: 'INSPECTION_MAINTENANCE_REGIMES', tabKey: 'maintenanceRegimes', Children: ComponentMaintenanceRegimesTab, visible: true },
];
