import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import { debounce } from 'lodash';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import '../styles/autocomplete.scss';
import '../styles/basic-input.scss';

class AutoComplete extends React.Component {
  constructor(props) {
    super(props);
    this.fetchRequestedDebounce = debounce(this.handleSuggestionsFetchRequested, 500);
  }

  componentWillUnmount() {
    this.fetchRequestedDebounce.cancel();
  }
  handleChange = e => {
    const {
      input: { onChange },
      inputProps,
    } = this.props;
    onChange(e);
    if (inputProps && inputProps.onChange) inputProps.onChange(e.target.value);
  };

  renderInput = inputProps => {
    const { inputRef, ref, placeholder, hideBorder, meta, isEnhancedDesignCustomProp, ...other } = inputProps;
    return (
      <div ref={inputRef} className={`inspection-input__label--input${hideBorder ? '' : ' border'} ` + (meta.error ? ' invalid ' : '')}>
        <input {...other} ref={ref} type={'text'} placeholder={placeholder || ''} className={`input f-primary ${isEnhancedDesignCustomProp ? 'enhanced-design-input prop' : ''}`} />
      </div>
    );
  };

  renderSuggestion = (suggestion, { query }) => {
    const matches = match(suggestion.label, query);
    const parts = parse(suggestion.label, matches);

    return (
      <div>
        {parts.map((part, index) => {
          return part.highlight ? (
            <span className="f-secondary-green" key={String(index)}>
              {part.text}
            </span>
          ) : (
            <span className="f-primary" key={String(index)}>
              {part.text}
            </span>
          );
        })}
      </div>
    );
  };

  renderSuggestionsContainer = ({ containerProps, children }) => {
    const { isEnhancedDesignCustomProp } = this.props;
    const { className, ...restProps } = containerProps;
    return (
      <div className={`${className} ${isEnhancedDesignCustomProp ? 'enhanced-design-custom-prop' : ''}`} {...restProps}>
        {children}
      </div>
    );
  };

  getSuggestionValue = suggestion => {
    return suggestion.label;
  };

  getSuggestions = value => {
    const { fetchSuggestions } = this.props;
    const inputValue = value.trim().toLowerCase();
    fetchSuggestions(inputValue);
  };
  handleSuggestionsFetchRequested = ({ value, reason }) => {
    this.getSuggestions(value);
  };

  handleSuggestionsClearRequested = () => {
    const { clearSuggestions } = this.props;
    clearSuggestions();
  };

  onSuggestionSelected = (_, { suggestionValue }) => {
    const {
      input: { onChange },
      inputProps,
    } = this.props;
    onChange(suggestionValue);
    if (inputProps && inputProps.onChange) inputProps.onChange(suggestionValue);
  };

  render() {
    const {
      input: { onChange: changeInput, ...restInput },
      inputProps: { onChange: changeInputProps, ...restInputProps },
      placeholder,
      meta,
      id,
      label,
      labelClass,
      isHidden,
      suggestions,
      hideBorder,
      inputRef,
      isEnhancedDesignCustomProp,
    } = this.props;
    const { t } = this.context;
    return (
      <div className={`inspection-input ${isHidden ? 'hidden-field' : ''}`}>
        {label && (
          <label className={`inspection-input__label ${labelClass || 'f-secondary-dark'} `} htmlFor={id}>
            {label}
          </label>
        )}
        <Autosuggest
          renderInputComponent={this.renderInput}
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.fetchRequestedDebounce}
          onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          renderSuggestionsContainer={this.renderSuggestionsContainer}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={{
            inputRef,
            placeholder,
            meta,
            hideBorder,
            isEnhancedDesignCustomProp,
            ...restInput,
            ...restInputProps,
            onChange: this.handleChange,
          }}
        />
        <div className={`error-message${meta.error ? '--visible' : ''}`}>{meta.error && t(meta.error.string, meta.error.params)}</div>
      </div>
    );
  }
}
AutoComplete.contextTypes = {
  t: PropTypes.func.isRequired,
};

AutoComplete.defaultProps = {
  inputProps: {},
};

AutoComplete.propTypes = {
  suggestions: PropTypes.array.isRequired,
  fetchSuggestions: PropTypes.func.isRequired,
  clearSuggestions: PropTypes.func.isRequired,
};

export default AutoComplete;
