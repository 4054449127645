import routesConstants from './routes';
import { Api } from '../../core/api';
import { API_BASE_URL } from '../../common/constants';
import { generateQueryParams } from '../helpers/helper';

const getNotifications = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_NOTIFICATIONS}`, data);
};

const createNotificationFromObservation = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CREATE_NOTIFICATION_FROM_OBSERVATION}`, data);
};

const getNotificationDetails = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_NOTIFICATION_DETAILS}${generateQueryParams(params)}`);
};

const updateNotification = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_NOTIFICATION}`, data);
};

const deleteNotification = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_NOTIFICATION}`, data);
};

const getComponentsByNotificationIds = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_COMPONENTS_BY_NOTIFICATIONS}`, data);
};

const getChecklistsByNotificationIds = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_CHECKLISTS_BY_NOTIFICATIONS}`, data);
};

const getFilesByNotificationIds = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_FILES_BY_NOTIFICATIONS}`, data);
};

const getNotificationWorkOrders = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_NOTIFICATION_WORK_ORDERS}`, data);
};

const getNotificationCustomProperties = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_NOTIFICATION_CUSTOM_PROPERTIES}`, data);
};

const deleteNotificationCustomProperty = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_NOTIFICATION_CUSTOM_PROPERTY}`, data);
};

const updateNotificationCustomProperty = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_NOTIFICATION_CUSTOM_PROPERTY}`, data);
};

const getNotificationCustomPropertyNames = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_NOTIFICATION_CUSTOM_PROPERTY_NAMES}`, data);
};

const fetchNotificationComments = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.FETCH_NOTIFICATION_COMMENTS}${generateQueryParams(params)}`);
};

const addNotificationComment = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_NOTIFICATION_COMMENT}`, params);
};

const deleteNotificationComment = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_NOTIFICATION_COMMENT}`, params);
};

const fetchNotificationsClustered = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_NOTIFICATION_CLUSTERED}`, data);
};

const updateOrAddNotificationCustomProperties = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_NOTIFICATION_CUSTOM_PROPERTY}`, data);
};

const fetchDetailsForCreationOfNotification = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_DETAILS_FOR_OBSERVATION_CREATION}`, data);
};

const fetchCustomPropDependantValues = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_CUSTOM_PROP_DEPENDANT_VALUES}`, data);
};

const fetchNotificationCustomPropDependantValues = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_NOTIFICATION_CUSTOM_PROP_DEPENDANT_VALUES}`, data);
};

export default {
  getNotifications,
  createNotificationFromObservation,
  getNotificationDetails,
  updateNotification,
  deleteNotification,
  getComponentsByNotificationIds,
  getChecklistsByNotificationIds,
  getFilesByNotificationIds,
  getNotificationWorkOrders,
  getNotificationCustomProperties,
  deleteNotificationCustomProperty,
  updateNotificationCustomProperty,
  getNotificationCustomPropertyNames,
  fetchNotificationComments,
  addNotificationComment,
  deleteNotificationComment,
  fetchNotificationsClustered,
  updateOrAddNotificationCustomProperties,
  fetchDetailsForCreationOfNotification,
  fetchCustomPropDependantValues,
  fetchNotificationCustomPropDependantValues,
};
