import { userResetPassword } from './action-creators';
import resetApi from '../../../api/reset-password/actions';
import { handleGlobalLoader } from '../../../common/global-loader/actions/loader-actions';

import Helpers from '../../../common/helpers';

import routesConstants from '../../../common/routes-constants';

export const resetPassword = values => {
  return async dispatch => {
    const res = await resetApi.resetPassword(values);
    let {
      data: { Data },
    } = res;
    if (Data) {
      dispatch(handleGlobalLoader(true));

      setTimeout(() => {
        Helpers.goTo(routesConstants.routes.unProtectedRoutes.login.fullPath);
        dispatch(handleGlobalLoader(false));
      }, 200);
    }
  };
};
export const setPassword = values => {
  return async dispatch => {
    const res = await resetApi.resetPassword(values);
    let {
      data: { Data },
    } = res;
    if (Data && Data.Email) {
      dispatch(handleGlobalLoader(true));
      //create delay in order to let reducer change state
      setTimeout(() => {
        Helpers.goTo(routesConstants.routes.unProtectedRoutes.login.fullPath);
        dispatch(handleGlobalLoader(false));
      }, 200);
    }
  };
};

export const requestUserReset = (values, openSuccessModal) => {
  return async dispatch => {
    await resetApi.requestResetPassword(values);
    openSuccessModal();
    //lock form possibly
    dispatch(userResetPassword(true));
  };
};

export const checkToken = (Token, callback) => {
  return async () => {
    const res = await resetApi.checkSetTokenPassword({ Token });
    const { Data } = res.data;
    callback && typeof callback === 'function' && callback(Data);
  };
};
