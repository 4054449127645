import { measurementConstants } from '../../../constants/ndt-constants';

export const validate = values => {
  const errors = {};

  if (!values[measurementConstants.fields.name] || values[measurementConstants.fields.name].length < 3) {
    errors[measurementConstants.fields.name] = {
      string: 'DEFECT_FORM.ERROR.NAME_LENGTH_MIN',
      params: { systemType: 'Component' },
    };
  }

  return errors;
};
