import actionTypes from '../constants/action-types';

export const createViewerInstance = data => {
  return {
    type: actionTypes.CREATE_VIEWER_INSTANCE,
    data,
  };
};

export const setNodesLoading = data => {
  return {
    type: actionTypes.SET_NODES_LOADING,
    data,
  };
};

export const setPointImages = data => {
  return {
    type: actionTypes.SET_POINT_IMAGES,
    data,
  };
};

export const setPointImagesLoading = data => {
  return {
    type: actionTypes.SET_POINT_IMAGES_LOADING,
    data,
  };
};

export const setPointImagesTake = data => {
  return {
    type: actionTypes.SET_POINT_IMAGES_TAKE,
    data,
  };
};

export const setActiveSlide = data => {
  return {
    type: actionTypes.SET_ACTIVE_POINT_INDEX,
    data,
  };
};
