import actionTypes from '../constants/action-types';
import { pointImagesStep } from '../constants/point-images-contstants';
const potreeState = {
  viewerInstance: null,
  nodesLoading: 0,
  pointImages: [],
  activeItemIndex: 0,
  pointImagesLoading: false,
  pointImagesTake: 0,
};

export const potreeReducer = (state = potreeState, payload) => {
  switch (payload.type) {
    case actionTypes.CREATE_VIEWER_INSTANCE:
      return {
        ...state,
        viewerInstance: payload.data,
      };
    case actionTypes.SET_NODES_LOADING:
      return {
        ...state,
        nodesLoading: payload.data,
      };
    case actionTypes.SET_POINT_IMAGES:
      return {
        ...state,
        pointImages: payload.data,
      };
    case actionTypes.SET_POINT_IMAGES_LOADING: {
      return {
        ...state,
        pointImagesLoading: payload.data,
      };
    }
    case actionTypes.SET_POINT_IMAGES_TAKE: {
      return {
        ...state,
        pointImagesTake: payload.data > -1 ? state.pointImagesTake + payload.data : pointImagesStep,
      };
    }
    case actionTypes.SET_ACTIVE_POINT_INDEX:
      return {
        ...state,
        activeItemIndex: payload.data,
      };
    default:
      return state;
  }
};
