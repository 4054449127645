import { store } from '../../../index';

import { find, isNumber } from 'lodash';

import { handleGlobalLoader } from '../../../common/global-loader/actions/loader-actions';
import { doBatchCleanUp, removeItemToCancel, setBatchFileFailed, setSingleUploadItemProgress, setUploadBatchFiles, setUploadTmpFiles, updateUploadBatchFile } from './action-creators';

import { notificationModalCustom } from '../../../common/modal/actions/modal-actions';

import uploadApi from '../../../api/upload/actions';

import Helpers from '../../../common/helpers';

import { supportedAwsErrors } from '../../../common/modal/constants/modal-constants';
import uploadConstants from '../constants/constants';

const upload = (url, file, type, callbackFunction, index, customDoneFunction, needLoader, headers, fileId) => {
  return async dispatch => {
    await uploadApi.upload(
      url,
      file,
      (progress, cancelObj) => {
        if (callbackFunction) {
          if (checkShouldCancel(file.timeStamp, cancelObj)) {
            callbackFunction(progress, index, Helpers.getKeyFromUrl(url), cancelObj);
            if (progress === 100 && isNumber(index)) {
              //other uploads
              dispatch(doneFunctionMultiple(url, index, customDoneFunction, fileId));
            } else if (progress === 100 && !isNumber(index)) {
              //main upload
              //refresh data
              customDoneFunction(url, file.real_name, file.InspectionID);
            }
          }
        }
      },
      type,
      needLoader,
      headers
    );
  };
};
//asset/multiple upload
const doneFunctionMultiple = (url, index, customDoneFunction, fileId) => {
  return async () => {
    if (customDoneFunction) {
      customDoneFunction(index, url, fileId);
    } else {
      await uploadApi.setUploadDone({ Key: Helpers.getKeyFromUrl(url) });
    }
  };
};

export const uploadAsset = (file, IDs, callbackFunction, index, customDoneFunction, cbDocumentFinished, needLoader, retry = 0, enforceRetry = true) => {
  return async dispatch => {
    try {
      const getSignedUrl = uploadConstants.uploadActionsByType[file.uploadType]?.getSignedUrl;
      if (!getSignedUrl) {
        console.warn(`Upload action not found for "${file.uploadType}" upload type! Please make sure you add it to upload constants.`);
        return;
      }
      const res = await getSignedUrl({ UploadType: file.uploadType, FileName: file.real_name, ...IDs }, null);
      const { Data } = res.data;
      await dispatch(upload(Data.URL, file, file.type, callbackFunction, index, customDoneFunction, needLoader, Data.HeaderName ? { [Data.HeaderName]: Data.HeaderValue } : {}, Data.FileID));
      /* 
      this callback is used when document upload is finished, 
      for example to fetch Documents list on Documents module
      after the upload of Document/File is finished
       */
      if (cbDocumentFinished && typeof cbDocumentFinished === 'function') cbDocumentFinished();
    } catch (err) {
      if (enforceRetry && retry < uploadConstants.photohrammetryUploadRetryCount) {
        retry += 1;
        setTimeout(() => {
          dispatch(uploadAsset(file, IDs, callbackFunction, index, customDoneFunction, needLoader, retry));
        }, 500 * retry);
      } else {
        dispatch(setUploadItems());
        dispatch(notificationModalCustom(true, 'PHOTOGRAMMETRY_UPLOAD.UPLOAD_FAILED_MESSAGE', 'PHOTOGRAMMETRY_UPLOAD.UPLOAD_FAILED_TITLE'));
        dispatch(handleGlobalLoader(false));
      }
      //clear files if upload is interrupted
    }
  };
};

export const setUploadItems = items => {
  return async dispatch => {
    dispatch(setUploadTmpFiles(items));
  };
};

export const setSingleUploadItem = (progress, index, awsKey) => {
  return async dispatch => {
    dispatch(setSingleUploadItemProgress({ index, progress, awsKey }));
  };
};

// MAIN UPLOAD
const checkShouldCancel = (item, cancelObj) => {
  const { cancelArr } = store.getState().uploadReducer;
  const itemToCancel = find(cancelArr, item.timeStamp);
  if (itemToCancel) {
    cancelObj.cancel(supportedAwsErrors[0]);
    store.dispatch(removeItemToCancel(itemToCancel.timeStamp));
    return false;
  } else {
    return true;
  }
};

export const setUploadBatchItems = items => {
  return async dispatch => {
    dispatch(setUploadBatchFiles(items));
  };
};

/* 
  Function to upload specific upload item in batch
  batchID  - id of batch where is file
  index    - index of file in batch
  progress - progress to be set for file 

*/
export const updateUploadBatchItem = (batchID, index, progress, cancelObj) => {
  return async dispatch => {
    dispatch(updateUploadBatchFile({ batchID, index, progress, cancelObj }));
  };
};

/* 
  Function to start the batch cleanup process
  batchID  - id of batch where is file
*/
export const cleanUpBatchItems = batchID => {
  return async dispatch => {
    setTimeout(() => {
      dispatch(doBatchCleanUp(batchID));
    }, 10000);
  };
};

/* 
  Function to set the file to failed in case of failure
  batchID  - id of batch where is file
  index    - index of file in batch
*/

export const setBatchItemFailed = (batchID, index) => {
  return async dispatch => {
    dispatch(setBatchFileFailed({ batchID, index }));
  };
};

// functions to upload file of a batch
export const uploadBatchItems = (batch, getIDs, customDoneFunction, needLoader, isVersionUpload) => {
  return async dispatch => {
    const progressCallback = (progress, index, _, cancelObj) => {
      const modifiedProgress = progress < 95 ? progress : 95;
      dispatch(updateUploadBatchItem(batch.batchID, index, modifiedProgress, cancelObj));
    };
    const doneFileUpload = (index, fileID, isVersionUpload, isActive, fileObj) => {
      dispatch(updateUploadBatchItem(batch.batchID, index, 100));
      customDoneFunction && typeof customDoneFunction === 'function' && customDoneFunction(fileID, batch, isVersionUpload, isActive, index, fileObj);
      dispatch(cleanUpBatchItems(batch.batchID));
    };
    // dummyCallback is here since i don't want to create new function on every loop iteration
    const dummyCallback = () => {};
    batch.files.forEach(async (file, index) => {
      try {
        const getSignedUrl = uploadConstants.uploadActionsByType[file.uploadType]?.getSignedUrl;
        if (!getSignedUrl) {
          console.warn(`Upload action not found for "${file.uploadType}" upload type! Please make sure you add it to upload constants.`);
          return;
        }
        const res = await getSignedUrl({ UploadType: file.uploadType, FileName: file.real_name, ...getIDs(file) }, null);
        const { Data } = res.data;
        await dispatch(upload(Data.URL, file.realFile, file.type, progressCallback, index, dummyCallback, needLoader, Data.HeaderName ? { [Data.HeaderName]: Data.HeaderValue } : {}, Data.FileID));
        // doneFileUpload was originally as a callback but due to strange behavior from the axios/aws upload we put it outside
        doneFileUpload(index, Data.FileID, isVersionUpload, file.IsActive, Data);
      } catch (err) {
        // since we don't want retry we shall only set the file item to failed to upload
        console.error(err);
        dispatch(setBatchItemFailed(batch.batchID, index));
      }
    });
  };
};
