import Helpers from '../../../../../common/helpers';
import Icon from '../../../../../common/icon/components/icon';
import { sourceLabelTranslationMap, typeLabelTranslationMap } from '../../../constants/notification-constants';
import { severityColors } from '../../observations/constants/constants';
import { fields, statuses } from '../constants/constants';

const statusesMap = {
  [statuses.open.value]: {
    icon: statuses.open.icon,
    iconProps: statuses.open.iconProps,
    title: statuses.open.title,
  },
  [statuses.actioned.value]: {
    icon: statuses.actioned.icon,
    iconProps: statuses.actioned.iconProps,
    title: statuses.actioned.title,
  },
  [statuses.closed.value]: {
    icon: statuses.closed.icon,
    iconProps: statuses.closed.iconProps,
    title: statuses.closed.title,
  },
};

export const formatNotificationsTableCells = (
  value,
  type,
  searchTerm,
  row,
  onDropdownClick,
  t,
  _pointerDotID,
  _user,
  _onToggleClick,
  _onDeleteRow,
  _statusData,
  _handleChangeStatus,
  _onLinkFileClick,
  _selectedItem,
  cell
) => {
  if (type === fields.icon) {
    return <Icon name={statusesMap[value].icon} {...statusesMap[value].iconProps} size="sm" />;
  } else if (type === fields.createdAt) {
    return <p className="f-primary">{Helpers.getDateFromUnix(value, cell.format || null)}</p>;
  } else if (type === fields.actionedAt) {
    return <p className="f-primary">{row[fields.actionedAt] ? Helpers.getDateFromUnix(value, cell.format || null) : '-'}</p>;
  } else if (type === fields.closedAt) {
    return <p className="f-primary">{row[fields.closedAt] ? Helpers.getDateFromUnix(value, cell.format || null) : '-'}</p>;
  } else if (type === fields.name) {
    return (
      <p className="f-primary" title={value}>
        {Helpers.getSearchTermText(Helpers.getShortenedText(value, 25), searchTerm)}
      </p>
    );
  } else if (type === fields.status) {
    return (
      <>
        <Icon name={statusesMap[value].icon} {...statusesMap[value].iconProps} size="sm" />
        <p className="f-primary status-text">{t(statusesMap[value].title)}</p>
      </>
    );
  } else if (type === fields.severity) {
    return <div className={`severity-icon ${severityColors[value] || 'severity-green'}`}>{value}</div>;
  } else if (type === fields.toggle) {
    return <Icon name={row.visible ? 'eye' : 'not-visible'} size="sm" onClick={e => onDropdownClick(e, row)} />;
  } else if (type === fields.type) {
    return <p className="f-primary">{typeLabelTranslationMap[value] ? t(typeLabelTranslationMap[value]) : value}</p>;
  } else if (type === fields.source) {
    return <p className="f-primary">{sourceLabelTranslationMap[value] ? t(sourceLabelTranslationMap[value]) : value}</p>;
  } else {
    return <p className="f-primary">{value ? value : '-'}</p>;
  }
};
