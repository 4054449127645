import routesConstants from './routes';
import { Api } from '../../core/api';
import { API_BASE_URL } from '../../common/constants';
import { generateQueryParams } from '../helpers/helper';

const saveDrawing = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.SAVE_DRAWING}`, data);
};

const removeImageDefect = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.REMOVE_DEFECT_FROM_IMAGE}`, data);
};

const getInspectedImagesCount = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_INSPECTED_IMAGES_COUNT}${generateQueryParams(params)}`);
};

export default {
  saveDrawing,
  removeImageDefect,
  getInspectedImagesCount,
};
