import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';

import AddAlerts from './add-alerts';
import Button from '../../../../common/form/components/button';
import { FORMS } from '../../../../common/constants';
import { validate } from './validators/work-order-alert-validator';

const ManageAlertForm = ({ handleSubmit, handleAddAlert, handleSearchUsers, users, workOrderAlertList, addUserToAlertList, removeUserFromAlertList, closeModal }, { t }) => {
  return (
    <div className="work-order-form">
      <form onSubmit={handleSubmit(handleAddAlert)} noValidate>
        <AddAlerts
          handleUsersAndTeamsSearch={handleSearchUsers}
          usersAndTeams={users}
          workOrderAlertList={workOrderAlertList}
          addUserToAlertList={addUserToAlertList}
          removeUserFromAlertList={removeUserFromAlertList}
        />
        <div className="work-order-default-modal-buttons buttons-without-spacing">
          <Button type="button" variant="gray-outline" height="md" width="sm" onClick={closeModal} text={t('CANCEL')} />
          <Button className="modal-confirm-btn" height="md" width="sm" text={t('WORK_ORDER.ADD_ALERT')} />
        </div>
      </form>
    </div>
  );
};

ManageAlertForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default reduxForm({
  form: FORMS.manageWorkOrderAlerts,
  validate,
})(ManageAlertForm);
