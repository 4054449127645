import routesConstants from './routes';
import { Api } from '../../core/api';
import { API_BASE_URL } from '../../common/constants';

const getSortByFilter = async () => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_SORT_BY_DATA}`);
};

export default {
  getSortByFilter,
};
