import actionTypes from '../constants/action-types';

// SAP ACTIONS

export const setSAPDetails = data => {
  return {
    data,
    type: actionTypes.SAP_SET_INTEGRATION_DETAILS,
  };
};

export const setSAPChangesSaved = data => {
  return {
    data,
    type: actionTypes.SAP_SET_CHANGES_SAVED,
  };
};

export const saveDataStartSAP = () => {
  return {
    type: actionTypes.SAP_SAVE_DATA_START,
  };
};

export const saveDataFailureSAP = data => {
  return {
    data,
    type: actionTypes.SAP_SAVE_DATA_FAILURE,
  };
};

export const saveDataSuccessSAP = () => {
  return {
    type: actionTypes.SAP_SAVE_DATA_SUCCESS,
  };
};

export const syncDataStartSAP = () => {
  return {
    type: actionTypes.SAP_SYNC_DATA_START,
  };
};

export const syncDataFailureSAP = data => {
  return {
    data,
    type: actionTypes.SAP_SYNC_DATA_FAILURE,
  };
};

export const syncDataSuccessSAP = () => {
  return {
    type: actionTypes.SAP_SYNC_DATA_SUCCESS,
  };
};

export const setInfoModalDataSAP = data => {
  return {
    data,
    type: actionTypes.SET_INFO_MODAL_DATA_SAP,
  };
};

export const setEquipmentData = data => {
  return {
    data,
    type: actionTypes.SET_EQUIPMENT_DATA,
  };
};

export const getEquipmentStart = () => {
  return {
    type: actionTypes.GET_EQUIPMENT_START,
  };
};

export const getEquipmentSuccess = () => {
  return {
    type: actionTypes.GET_EQUIPMENT_SUCCESS,
  };
};

export const getEquipmentFailure = data => {
  return {
    data,
    type: actionTypes.GET_EQUIPMENT_FAILURE,
  };
};

export const setIntegrationStatus = data => {
  return {
    data,
    type: actionTypes.SET_INTEGRATION_STATUS,
  };
};

// NOTIFICATIONS

export const setNotificationDetails = data => {
  return {
    data,
    type: actionTypes.SET_SELECTED_NOTIFICATION,
  };
};
export const setNotificationsData = data => {
  return {
    data,
    type: actionTypes.SET_NOTIFICATIONS_DATA,
  };
};

export const getNotificationsStart = () => {
  return {
    type: actionTypes.GET_NOTIFICATIONS_START,
  };
};

export const getNotificationsSuccess = () => {
  return {
    type: actionTypes.GET_NOTIFICATIONS_SUCCESS,
  };
};

export const getNotificationsFailure = () => {
  return {
    type: actionTypes.GET_NOTIFICATIONS_FAILURE,
  };
};

// NOTIFICATIONS - END

// END
