import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Chart from 'react-google-charts';
import ReactResizeDetector from 'react-resize-detector';
import Helpers from '../../../../common/helpers';
import { ReactComponent as ChartViewIcon } from '../../assets/chart-icon.svg';
import { ReactComponent as RefreshIcon } from '../../assets/refresh-icon.svg';
import { isEqual, isEmpty } from 'lodash';

// import '../styles/line-chart.scss';

class LineChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartType: 'LineChart',
      height: '280px',
      width: '100%',
      fakeKey: true,
    };
  }

  componentWillUnmount = () => {
    //line chart bug fix with not removing redundant html on unmount from body element
    const elements = document.body.querySelectorAll('body > div');
    if (!isEmpty(elements)) {
      for (var i = 0; i < elements.length; i++) {
        if ((elements[i].classList.length === 0 || elements[i].classList.value.indexOf('goog-tooltip') > -1) && !elements[i].id) {
          document.body.removeChild(elements[i]);
        }
      }
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    const { lineChartOptions, data } = this.props;
    const shouldUpdate = !isEqual(nextProps.lineChartOptions, lineChartOptions) || !isEqual(nextProps.data, data) || !isEqual(this.state, nextState);

    return shouldUpdate;
  }

  redrawChart = () => {
    const { fakeKey } = this.state;
    this.setState({ fakeKey: !fakeKey });
  };

  onResize = () => {
    const { chartType } = this.state;
    this.setState({ chartType: chartType });
  };

  render() {
    const { t } = this.context;
    const { chartType, height, width, fakeKey } = this.state;
    const { data, lineChartOptions, chartHeight, isEmptyData = Helpers.isLineChartEmpty } = this.props;

    const isLineChartEmpty = isEmptyData(data);

    return (
      <ReactResizeDetector handleWidth onResize={this.onResize} refreshMode={'debounce'} refreshRate={500}>
        <div className="chart-wrapper">
          <div className="line-chart">
            {!isLineChartEmpty ? (
              <>
                <Chart
                  loader={
                    <div className={`empty-state-active`}>
                      <p className="f-primary">Loading...</p>
                    </div>
                  }
                  key={fakeKey}
                  width={width}
                  height={chartHeight || height}
                  chartType={chartType}
                  data={data}
                  options={lineChartOptions}
                />
                <div title={t('LINE_CHART.REFRESH')} onClick={this.redrawChart} className="refresh-button">
                  <RefreshIcon width={20} height={20} className="svg-white-g" />
                </div>
              </>
            ) : (
              <div style={{ width: width, height: chartHeight || height }}>
                <div className={`empty-state-active`}>
                  <ChartViewIcon width={30} height={30} className="svg-gray-g" />
                  <h4 className="noselect f-secondary-light"> {t('CHART_DATA_IS_EMPTY')}</h4>
                </div>
              </div>
            )}
          </div>
        </div>
      </ReactResizeDetector>
    );
  }
}

LineChart.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default LineChart;
