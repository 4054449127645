import React from 'react';
import PropTypes from 'prop-types';

import '../styles/ch-no-data.scss';

const CHNoData = (_, { t }) => {
  return (
    <div className="ch-no-data">
      <h6 className="ch-no-data__title">{t('COMPONENT_HIERARCHY.NO_DATA')}</h6>
    </div>
  );
};

CHNoData.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default CHNoData;
