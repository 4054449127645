import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Button from '../../../common/form/components/button';
import VerificationFrom from '../../../common/verification-form/components/verification-form';
import RecoveryKeys from './recovery-keys';

class AuthenticatiorType extends Component {
  state = {
    step: 1,
  };

  stepForward = () => {
    let { step } = this.state;
    this.setState({ step: ++step });
  };

  render() {
    const { t } = this.context;
    const { step } = this.state;
    const {
      cancelClick,
      data: { QRCodeBASE64, Secret },
      verifyMfa,
      mfaCodes,
    } = this.props;

    return (
      <Fragment>
        {step === 1 && (
          <Fragment>
            <h5 className="f-primary noselect">{t('MFA.AUTHENTICATOR_TITLE')}</h5>
            <p className="f-secondary-dark noselect short">{t('MFA.AUTHENTICATOR_DESC')}</p>
            <img src={`data:image/png;base64, ${QRCodeBASE64}`} alt={'qr-code'} className="qr-code" />
            <p className="f-secondary-dark noselect">{t('MFA.AUTHENTICATOR_BE_SURE')}</p>
            <p className="f-primary">{Secret}</p>
            <p className="f-secondary-dark noselect short">{t('MFA.AUTHENTICATOR_ONCE')}</p>
            <div className="buttons">
              <Button type="button" onClick={this.stepForward} text={t('MFA.NEXT')} />
              <Button type="button" onClick={cancelClick} variant="gray-outline" text={t('MFA.CANCEL')} />
            </div>
          </Fragment>
        )}
        {step === 2 && (
          <Fragment>
            <VerificationFrom title={t('MFA.ATUHENTICAOR_CODE_TITLE')} subtitle={t('MFA.ATUHENTICAOR_CODE_SUBTITLE')} cancelClick={cancelClick} onSubmit={vals => verifyMfa(vals, this.stepForward)} />
          </Fragment>
        )}
        {step === 3 && (
          <Fragment>
            <RecoveryKeys codes={mfaCodes} finishClick={cancelClick} />
          </Fragment>
        )}
      </Fragment>
    );
  }
}

AuthenticatiorType.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default AuthenticatiorType;
