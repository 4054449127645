import { debounce, isArray, isEmpty, isEqual, keys, map, reduce, values } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { updateSyncErrors } from 'redux-form';
import { FORMS } from '../../../../../common/constants';
import TableTemplate from '../../../../../common/double-table/components/table-template';
import Helpers from '../../../../../common/helpers';
import Modal from '../../../../../common/modal/components/modal';
import { filterFields } from '../../../constants/notification-constants';
import NotificationsFilter from '../../left-toolbar/filters/notifications-filter';
import { validate } from '../../left-toolbar/validators/notification-filters-validator';
import { formatNotificationsTableCells } from '../actions/table-formatting';
import { defaultFilters, filterProps, getTableConfig, sortDirection, statuses } from '../constants/constants';
import { deleteAdvancedFiltersFromFilters, getNotificationsActiveAdvancedFilters, getSelectedStatuses } from '../helpers/notifications-helpers';

const NotificationsWrapper = (
  {
    data,
    filters,
    wrapperClassName,
    loading,
    fetchNotifications,
    customTableClassName,
    onRowClick,
    isTab = false,
    isFullScreen,
    selectedItem = null,
    onDropdownClick,
    allHidden,
    toggleAll,
    updateSyncErrors,
    inspectionId,
  },
  { t }
) => {
  const [searchText, setSearchText] = useState('');
  // getNotificationsActiveAdvancedFilters is to set advanced filters if the values from filters have been persisted
  const [advancedFilters, setAdvancedFilters] = useState(getNotificationsActiveAdvancedFilters(filters));
  const [modalData, setModalData] = useState({ isOpen: false });
  const tableConfig = useMemo(() => {
    return getTableConfig(isTab, isFullScreen);
  }, [isTab, isFullScreen]);

  const isFetchNotificationsValid = fetchNotifications && typeof fetchNotifications === 'function';

  const onSort = (SortByColumn = {}) => {
    const newFilters = {
      ...filters,
      [filterProps.sortByColumn]: SortByColumn,
      [filterProps.sortDirection]: filters[filterProps.sortDirection] === sortDirection.asc ? sortDirection.desc : sortDirection.asc,
      [filterProps.lastSeen]: 0,
    };
    // Add SortBy prop if we are not in tab since the endpoint expect 2 diffrent name of props
    // BE doesn't want to fix it since it might hurt mobile
    if (!isTab) newFilters[filterProps.sortBy] = SortByColumn;

    isFetchNotificationsValid && fetchNotifications(newFilters);
  };

  const onSearch = SearchText => {
    const newFilters = {
      ...filters,
      [filterProps.lastSeen]: 0,
      SearchText,
    };
    isFetchNotificationsValid && fetchNotifications(newFilters);
  };

  const onQuickFilterClick = quickFilters => {
    const newFilters = {
      ...filters,
      [filterProps.lastSeen]: 0,
    };

    if (isArray(quickFilters)) {
      newFilters[filterProps.statusFilter] = [quickFilters.length ? quickFilters.toString() : ''];
    } else {
      newFilters[quickFilters] = !newFilters[quickFilters];
      // if 7 days selected, toggle off the 30 days and vice versa
      if (quickFilters === filterProps.lastSevenDays) {
        newFilters[filterProps.lastThirtyDays] = false;
      } else if (quickFilters === filterProps.lastThirtyDays) {
        newFilters[filterProps.lastSevenDays] = false;
      }
    }

    isFetchNotificationsValid && fetchNotifications(newFilters);
  };

  const onLoadMoreClick = () => {
    // TODO: fix onLoadMore (list not updating)
    isFetchNotificationsValid && fetchNotifications(filters, true);
  };

  const handleFileSearchInput = e => {
    setSearchText(e.target.value);
    searchTextChanged(e.target.value);
  };

  const searchTextChanged = debounce(onSearch, 300);

  const applyAdvancedFilters = (values = {}) => {
    const errors = validate(values);
    if (!isEmpty(errors)) {
      updateSyncErrors(FORMS.notificationFilters, errors);
      return;
    }
    setAdvancedFilters(values);
    const newAdvancedFilter = reduce(
      values,
      (obj, _it, key) => {
        if (key === filterFields.typeFilter) {
          obj[key] = values[key];
        } else if ([filterProps.createdByFilter].indexOf(key) > -1) {
          obj[key] = map(values[key], ({ ID, UserID }) => ID || UserID);
        } else if ([filterProps.dateFrom].indexOf(key) > -1) {
          obj[key] = (values[key] && Helpers.getUnixDate(new Date(values[key]).getTime())) || null;
        } else if ([filterProps.dateTo].indexOf(key) > -1) {
          obj[key] = (values[key] && Helpers.getUnixDate(new Date(values[key]).setHours(23, 59, 59))) || null;
        } else if ([filterFields.hasWorkOrdersFilter, filterFields.hasLinkedEquipment, filterFields.hasFiles, filterFields.has3DLocationFilter].indexOf(key) > -1) {
          obj[key] = Helpers.castToggleRadioInputAnswer(values[key]);
        } else if (key === filterFields.severityFilter) {
          obj[key] = [values[filterFields.severityFilter].min, values[filterFields.severityFilter].max];
        } else if (key === filterFields.createdInLastXDaysFilter) {
          // toggles off the 7 days and 30 days quick filters when advanced filter createdInLastXDaysFilter is set
          filters[filterProps.lastSevenDays] = false;
          filters[filterProps.lastThirtyDays] = false;

          // casts selected number of days to integer
          obj[key] = parseInt(values[key], 10);
        } else if (key === filterFields.sourceFilter) {
          let sourceFilters = [];
          sourceFilters = values[key].map(filter => filter.value);
          const sourceFilterToBeSent = sourceFilters.join(',');
          obj[key] = sourceFilterToBeSent;
        } else {
          obj[key] = values[key];
        }
        return obj;
      },
      {}
    );
    fetchNotifications({ ...deleteAdvancedFiltersFromFilters(filters), ...newAdvancedFilter, [filterProps.lastSeen]: 0 });
    closeModal();
  };

  const resetAdvancedFilters = () => {
    setAdvancedFilters({});
    fetchNotifications({ ...deleteAdvancedFiltersFromFilters(filters), [filterProps.lastSeen]: 0 });
    closeModal();
  };

  const openAdvancedFilters = () =>
    setModalData({
      isOpen: true,
      type: 'none',
      title: t('NOTIFICATIONS.ADVANCED_FILTERS'),
      CustomContent: () => (
        <NotificationsFilter
          hideComponentFilters={isTab}
          initialValues={advancedFilters}
          submitForm={applyAdvancedFilters}
          resetFilter={resetAdvancedFilters}
          closeModal={closeModal}
          inspectionId={inspectionId}
          hideCustomPropsFilter={isTab}
        />
      ),
      customClassName: 'modal-large ',
      closeAction: closeModal,
    });

  const closeModal = () => setModalData({ isOpen: false });

  const advancedFiltersLength = keys(advancedFilters).length;
  const isFilterApplied =
    advancedFiltersLength === 0 && isEqual(defaultFilters[filterProps.statusFilter], filters[filterProps.statusFilter]) && defaultFilters[filterProps.searchText] === filters[filterProps.searchText];
  let noDataText = t('NOTIFICATIONS.EMPTY_STATE');
  if (isTab) {
    noDataText = isFilterApplied ? t('COMPONENT_NOTIFICATIONS.NO_DATA') : t('COMPONENT_NOTIFICATIONS.NO_DATA_FILTERED');
  }

  return (
    <div className={wrapperClassName ? wrapperClassName : ''}>
      <TableTemplate
        tableConfig={tableConfig}
        filters={filters}
        data={data}
        onSortClick={onSort}
        loadMoreOnClick={onLoadMoreClick}
        quickFilters={values(statuses)}
        onQuickFilterClick={onQuickFilterClick}
        handleFormatCells={formatNotificationsTableCells}
        openAdvancedFilters={openAdvancedFilters}
        selectedItem={selectedItem}
        isLoading={loading}
        TotalItems={filters.TotalNumber}
        searchPlaceholder={t('SEARCH')}
        sortDirection={filterProps.sortDirection}
        sortByColumn={filterProps.sortByColumn}
        resultsText={'COMPONENT_NOTIFICATIONS.TOTAL'}
        searchInputOnChange={handleFileSearchInput}
        searchInputValue={searchText}
        hasNext={filters[filterProps.hasNext]}
        selectedQuickFilters={getSelectedStatuses(filters)}
        activeAdvancedFilters={advancedFiltersLength}
        noDataText={noDataText}
        customTableClass={`${customTableClassName} ${isFullScreen ? 'full-screen' : 'three-d'}`}
        onRowClick={onRowClick}
        onDropdownClick={onDropdownClick}
        showOptionFor3DToggle={!isTab && !isFullScreen}
        allHidden={allHidden}
        toggleAll={toggleAll}
      />
      <Modal {...modalData} />
    </div>
  );
};

NotificationsWrapper.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  updateSyncErrors: (form, errors) => dispatch(updateSyncErrors(form, errors)),
});

export default connect(null, mapDispatchToProps)(NotificationsWrapper);
