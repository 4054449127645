import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../../../../../common/icon/components/icon';
import '../../../styles/critical-equipment-info.scss';

const CriticalEquipmentInfo = ({ title, paragraph, className }, { t }) => {
  return (
    <div className={`critical-equipment-info-container ${className}`}>
      <div className="critical-equipment-info-container__wrapper">
        <div className="critical-equipment-info-container__title">
          <Icon name="critical-hazard" handleHover={false} size="sm" />
          <span> {t(title)}</span>
        </div>
        <div className="critical-equipment-info-container__paragraph">
          <p>{t(paragraph)}</p>
        </div>
      </div>
    </div>
  );
};

CriticalEquipmentInfo.contextTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
};

export default CriticalEquipmentInfo;
