import { values } from 'lodash';
import Icon from '../../../../../../common/icon/components/icon';
import { statuses } from '../../../../constants/work-order-constants';
import { fields } from '../constants/notification-WO-tab';

export const notificationWOTableFormatting = (value, type) => {
  if (type === fields.icon) {
    const statusesArray = values(statuses);
    const foundStatus = statusesArray.find(obj => obj.value === value);
    return <Icon name={foundStatus.icon} size="xs" />;
  } else return <p className="f-primary">{value ? value : '-'}</p>;
};
