import { formConstants } from '../../../constants/areas-constants';

export const validate = values => {
  const errors = {};

  if (!values[formConstants.fields.name] || values[formConstants.fields.name].length < 3) {
    errors[formConstants.fields.name] = {
      string: 'AREA_FORM.ERROR.NAME_LENGTH',
    };
  }

  return errors;
};
