import PropTypes from 'prop-types';
import React from 'react';
import { FieldArray, getFormValues, reduxForm } from 'redux-form';

import { connect } from 'react-redux';
import { FORMS } from '../../../../../../common/constants';
import Button from '../../../../../../common/form/components/button';
import { formConstants as mpFormConstants } from '../../constants/measurement-point-constants';
import '../../styles/measurement-point-alarms-form.scss';
import { alarmsValidate } from '../../validators/alarms-validator';
import AlarmsFormFields from '../alarms/alarms-form-fields';

const MeasurementPointAlarmForm = (props, { t }) => {
  const { handleSubmit, invalid, handleCancel, formValues, readonly, alarmDescriptors, defaultComponent, pristine } = props;

  return (
    <form className="measurement-point-alarms-form" onSubmit={handleSubmit} noValidate>
      <h5 className="f-primary measurement-point-alarms-form-heading ">{t('ALARMS')}</h5>
      <FieldArray
        name={mpFormConstants.fields.alarms}
        id={mpFormConstants.fields.alarms}
        readonly={readonly}
        component={AlarmsFormFields}
        alarmDescriptors={alarmDescriptors}
        formValues={formValues}
        defaultComponent={defaultComponent}
      />
      <div className="buttons">
        <Button type="button" variant="gray-outline" width="sm" height="md" text={t('CREATE_WORKORDER_FORM.CANCEL')} onClick={handleCancel} />
        <div className="right-buttons">
          <Button height="md" className="button-submit" width="sm" disabled={invalid || pristine} text={t('SAVE')} />
        </div>
      </div>
    </form>
  );
};

MeasurementPointAlarmForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    formValues: getFormValues(FORMS.measurementPointAlarmsForm)(state),
  };
};

export default reduxForm({
  form: FORMS.measurementPointAlarmsForm,
  validate: alarmsValidate,
})(connect(mapStateToProps, null)(MeasurementPointAlarmForm));
