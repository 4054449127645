import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { isEmpty } from 'lodash';

import { validate } from '../validators/sap-validators';

import { ReactComponent as SyncIcon } from '../assets/sap-assets/sync-icon.svg';

import PasswordInput from '../../../../common/form/components/password-input';
import Button from '../../../../common/form/components/button';

import constants from '../constants/sap-constants';
import { FORMS } from '../../../../common/constants';

import '../styles/sap-form.scss';
import Helpers from '../../../../common/helpers';
import sapConstants from '../constants/sap-constants';

class SapForm extends Component {
  getValues() {
    const { clientID, clientToken, integrationUrl } = this.props;

    return {
      [constants.formConstants.fields.clientID]: clientID,
      [constants.formConstants.fields.clientToken]: clientToken,
      [constants.formConstants.fields.integrationUrl]: integrationUrl,
    };
  }

  render() {
    const { t } = this.context;
    const {
      handleSubmit,
      invalid,
      anyTouched,
      onCancel,
      isSaved,
      saveData,
      isSaving,
      // isSynchronizing,
      // theme,
      formError,
      status,
      openSyncModal,
    } = this.props;

    return (
      <form className="sap-form" onSubmit={handleSubmit} noValidate>
        <Field
          id={constants.formConstants.fields.clientID}
          name={constants.formConstants.fields.clientID}
          label="INTEGRATIONS.FORMS.SAP.CLIENT_ID.LABEL"
          labelClass="f-secondary-light"
          component={PasswordInput}
          type="password"
          placeholder="INTEGRATIONS.FORMS.SAP.CLIENT_ID.PLACEHOLDER"
        />
        <Field
          id={constants.formConstants.fields.clientToken}
          name={constants.formConstants.fields.clientToken}
          label="INTEGRATIONS.FORMS.SAP.CLIENT_TOKEN.LABEL"
          labelClass="f-secondary-light"
          component={PasswordInput}
          type="password"
          placeholder="INTEGRATIONS.FORMS.SAP.CLIENT_TOKEN.PLACEHOLDER"
        />
        <Field
          id={constants.formConstants.fields.integrationUrl}
          name={constants.formConstants.fields.integrationUrl}
          label="INTEGRATIONS.FORMS.SAP.INTEGRATION_URL.LABEL"
          labelClass="f-secondary-light"
          component={PasswordInput}
          type="password"
          placeholder="INTEGRATIONS.FORMS.SAP.INTEGRATION_URL.PLACEHOLDER"
        />
        <div className="error-placeholder">{!isEmpty(formError) && t(formError)}</div>
        {isSaved ? (
          <Fragment>
            <div className={`status status--${Helpers.getSAPIntegrationStatusClassName(status)}`}>
              <p className="noselect">{status}</p>
            </div>
            {status === sapConstants.integrationSatus.completed && (
              <Button type="button" onClick={() => openSyncModal(this.getValues)} className="cancel-button" width="lg" text={t('INTEGRATIONS.FORMS.SAP.SELECT_EQUIPMENT')} />
            )}
            <Button width="lg" disabled={status === sapConstants.integrationSatus.inProgress || (invalid && anyTouched)} className="cancel-button" text={t('INTEGRATIONS.FORMS.SAP.SYNCHRONISE')} />
            <div className="synchronise-description">
              <SyncIcon className="synchronise-description__icon" />
              <label className="f-secondary-green">Your ID and Token are ready to Synchronise!</label>
            </div>
          </Fragment>
        ) : (
          <div className="buttons">
            <Button type="button" variant="gray-outline" width="lg" className="cancel-button" text={t('INTEGRATIONS.FORMS.SAP.CANCEL')} onClick={onCancel} />
            <Button type="button" width="lg" onClick={() => saveData(this.getValues())} disabled={invalid} text={isSaving ? '' : t('INTEGRATIONS.FORMS.SAP.SAVE')} />
          </div>
        )}
      </form>
    );
  }
}

SapForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

SapForm = reduxForm({
  form: FORMS.sapIntegrationForm,
  validate,
  enableReinitialize: true,
})(SapForm);

const selector = formValueSelector(FORMS.sapIntegrationForm);

const mapStateToProps = state => {
  const clientID = selector(state, constants.formConstants.fields.clientID),
    clientToken = selector(state, constants.formConstants.fields.clientToken),
    integrationUrl = selector(state, constants.formConstants.fields.integrationUrl);

  return {
    clientID,
    clientToken,
    integrationUrl,
  };
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SapForm);
