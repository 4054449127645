import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ChangePasswordForm from './change-password-form';
import { changePassword } from '../actions/change-password-actions';

import Modal from '../../../common/modal/components/modal';

import Helpers from '../../../common/helpers';
import routesConstants from '../../../common/routes-constants';

import '../styles/change-password.scss';

class ChangePassword extends Component {
  state = {
    modalOpen: false,
  };
  handleSubmit = values => {
    const { changePassword } = this.props;
    changePassword(values, this.openModal);
  };
  closeModal = () => {
    Helpers.goTo(routesConstants.routes.protectedRoutes.profile.fullPath);
    this.setState({ modalOpen: false });
  };
  openModal = () => {
    this.setState({ modalOpen: true });
  };

  cancelAction = () => {
    Helpers.goTo(routesConstants.routes.protectedRoutes.profile.fullPath);
  };

  render() {
    const { t } = this.context;
    const { modalOpen } = this.state;
    return (
      <div className="change-password">
        <div className="change-password-container">
          <ChangePasswordForm onSubmit={this.handleSubmit} cancelAction={this.cancelAction} />
          <Modal title={t('CHANGE_PASSWORD_MODAL.TITLE')} content={t('CHANGE_PASSWORD_MODAL.DESC')} isOpen={modalOpen} type="ok" closeAction={this.closeModal} confirmAction={this.closeModal} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({ changePassword: (values, modalCallback) => dispatch(changePassword(values, modalCallback)) });

ChangePassword.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(ChangePassword);
