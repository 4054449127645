import actionTypes from '../constants/action-types';

const explosiveZonesState = {
  zones: [],
  zonesClustered: [],
  zonesLoading: [],
  delZoneModalData: {
    isOpen: false,
  },
  zoneAreas: [],
};

export const explosiveZonesReducer = (state = explosiveZonesState, payload) => {
  switch (payload.type) {
    case actionTypes.SET_EXPLOSIVE_ZONES:
      return {
        ...state,
        zones: payload.data,
      };
    case actionTypes.SET_EXPLOSIVE_ZONES_LOADING:
      return {
        ...state,
        zonesLoading: payload.data,
      };
    case actionTypes.HANDLE_DELETE_EXPLOSIVE_ZONE_MODAL:
      return { ...state, delZoneModalData: payload.data };
    case actionTypes.SET_EXPLOSIVE_ZONE_AREAS:
      return { ...state, zoneAreas: payload.data };
    case actionTypes.AMEND_EXPLOSIVE_ZONE_AREAS:
      return { ...state, zones: [...state.zones, ...payload.data] };

    case actionTypes.TOGGLE_EXPLOSIVE_ZONE:
      return {
        ...state,
        zones: state.zones.map(zone => {
          return { ...zone, visible: zone.ID === payload.data ? !zone.visible : zone.visible };
        }),
        zonesClustered: state.zonesClustered.map(zone => {
          return { ...zone, visible: zone.ID === payload.data ? !zone.visible : zone.visible };
        }),
      };
    case actionTypes.SELECT_ALL_ZONES:
      return {
        ...state,
        zones: state.zones.map(zone => ({ ...zone, visible: true })),
        zonesClustered: state.zonesClustered.map(zone => ({ ...zone, visible: true })),
      };
    case actionTypes.DESELECT_ALL_ZONES:
      return {
        ...state,
        zones: state.zones.map(zone => ({ ...zone, visible: false })),
        zonesClustered: state.zonesClustered.map(zone => ({ ...zone, visible: false })),
      };
    case actionTypes.SET_EXPLOSIVE_ZONE_DETAILS_LOADING:
      return { ...state, zoneDetailsLoading: payload.data };
    case actionTypes.SET_INSPECTION_EXPLOSIVE_ZONES_CLUSTERED:
      return {
        ...state,
        zonesClustered: payload.data.map(area => ({ ...area, visible: true })),
      };
    default:
      return state;
  }
};
