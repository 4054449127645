import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ToggleButton from 'react-switch';

import { isEmpty } from 'lodash';
import Icon from '../../icon/components/icon';
import InfoTooltip from '../../tooltip/components/info-tooltip';
import '../styles/toggle.scss';

export default class Toggle extends Component {
  render() {
    const {
      input,
      inputProps,
      meta,
      id,
      label,
      labelClass,
      disabled,
      isHidden,
      sublabel,
      className,
      leftValue,
      rightValue,
      inline,
      isRequired,
      infoText,
      width = 36,
      height = 20,
      handleDiameter = 21,
    } = this.props;
    const { t } = this.context;

    return (
      <div
        className={`field-wrapper ${inline ? 'inline' : ''} ${disabled ? 'field-wrapper--disabled' : ''} ${isHidden ? 'hidden-field' : ''} ${className} ${
          leftValue || rightValue ? 'include-values' : ''
        }`}
      >
        {(label || sublabel) && (
          <div className="labels">
            {label && (
              <label className={`field-wrapper__label ${labelClass || ''}`} htmlFor={id}>
                {`${label}${isRequired ? '*' : ''}`}
                {!isEmpty(infoText) && (
                  <InfoTooltip
                    actionsMenu={t(infoText)}
                    offsetY={10}
                    offsetX={-250}
                    containerProps={{
                      onMouseEnter: () => null,
                      onMouseLeave: () => null,
                      autoHandlePopover: true,
                    }}
                    Component={() => <Icon name="info" handleHover={false} size="xs" />}
                    className="custom-toggle__info-icon"
                    componentProps={{ title: '' }}
                  />
                )}
              </label>
            )}

            {sublabel && <label className="field-wrapper__label sublabel">{sublabel}</label>}
          </div>
        )}

        <div className="input-wrapper">
          {leftValue && <label className="value left">{leftValue}</label>}
          <ToggleButton
            className={`custom-toggle${input.value ? '' : ' unchecked'}`}
            width={width || 36}
            height={height || 20}
            handleDiameter={handleDiameter || 21}
            onChange={val => {
              if (disabled) {
                return;
              }
              input?.onChange?.(val);
              inputProps?.onChange?.(val);
            }}
            checked={input.value || false}
            checkedIcon={<div />}
            uncheckedIcon={<div />}
          />

          {rightValue && <label className="value right">{rightValue}</label>}
        </div>
        <div className={`error-message${meta.error && meta.touched ? '--visible' : ''}`}>{meta.error && t(meta.error)}</div>
      </div>
    );
  }
}

Toggle.defaultProps = {
  inline: true,
  meta: {},
  input: {},
};

Toggle.contextTypes = {
  t: PropTypes.func.isRequired,
};
