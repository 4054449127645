import routesConstants from './routes';
import { Api } from '../../core/api';
import { API_BASE_URL } from '../../common/constants';

const changePassword = async values => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CHANGE_PASSWORD}`, values, { needLoader: true });
};

export default {
  changePassword,
};
