import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import LoginForm from './login-form';
import RegisterWrapper from '../../shared/common-component/components/register-wrapper';
import Modal from '../../../common/modal/components/modal';

import CustomSlider from '../../../common/slider/components/custom-slider';

import EmailVerification from './email-verification';

import { loginError, authenticateUser, clearTempLoginData, logout, verifyUser, resend } from '../actions/login-actions';

import loginConstants from '../constants/login-constants';
import { LOGIN_SLIDER_ITEMS, FOOTER_TEXT, FOOTER_LOGO } from '../../../common/constants';
import { withRouter } from 'react-router';

class Login extends Component {
  state = {
    modalData: {
      isOpen: false,
    },
  };
  handleLoginSubmit = values => {
    const { authenticateUser } = this.props;

    authenticateUser(values);
  };

  componentWillUnmount = () => {
    const { clearTempLoginData } = this.props;
    clearTempLoginData();
  };

  renderNav = (onClick, right) => {
    return <span onClick={onClick} className={`slider-nav slider-nav-${right ? 'right' : 'left'}`} />;
  };

  renderItem = item => {
    return <div className="bg-image" style={{ background: `url('${item.src}') no-repeat center center` }} />;
  };

  openCustomSignoutModal = () => {
    const { t } = this.context;
    const { clearTempLoginData } = this.props;
    this.setState({
      modalData: {
        title: t('LOGOUT.TITLE'),
        content: t('LOGOUT.DESC'),
        isOpen: true,
        type: 'yes-no',
        confirmAction: () => {
          clearTempLoginData();
          this.closeSignoutModal();
        },
        closeAction: this.closeSignoutModal,
      },
    });
  };

  closeSignoutModal = () => {
    this.setState({
      modalData: {
        isOpen: false,
      },
    });
  };

  renderStep() {
    const { t } = this.context;
    const { loginError, clearErrors, loginStep, tempToken, resend, resendExpiration, verifyUser, clearTempLoginData, location } = this.props;

    // 12 is the number of length of ?redirectTo=
    // the reason for using slice and location.search instead of query is we have "&" in url and that separates the whole redirectTo query on 3/4 items
    const redirectRoute = location.search.slice(12);

    switch (loginStep) {
      case loginConstants.loginSteps.LOGIN:
        return (
          <RegisterWrapper>
            <div className="bg-wrapper">
              <CustomSlider
                showFullscreenButton={false}
                items={LOGIN_SLIDER_ITEMS}
                showPlayButton={false}
                showThumbnails={true}
                renderRightNav={onClick => this.renderNav(onClick, true)}
                renderLeftNav={onClick => this.renderNav(onClick)}
                slideDuration={500}
                autoPlay={true}
                renderItem={this.renderItem}
                showBullets={true}
                showNav={true}
              />
            </div>
            <div className="login-wrapper">
              <LoginForm onSubmit={this.handleLoginSubmit} loginError={loginError} clearErrors={clearErrors} />
            </div>
          </RegisterWrapper>
        );
      case loginConstants.loginSteps.EMAIL_VERIFICATION:
        return (
          <EmailVerification
            handleSubmit={params => {
              verifyUser({ ...params, Token: tempToken }, loginConstants.loginSteps.EMAIL_VERIFICATION, redirectRoute);
            }}
            customSignOutClick={this.openCustomSignoutModal}
            hideToggle={true}
            resend={setTimer => resend({ Token: tempToken }, loginConstants.loginSteps.EMAIL_VERIFICATION, setTimer)}
            resendExpiration={resendExpiration}
            cancelClick={clearTempLoginData}
            title={t('LOGIN.EMAIL_VERIFICATION_SUB_TITLE')}
            subtitle={t('LOGIN.EMAIL_VERIFICATION_DESCRIPTION')}
          />
        );
      case loginConstants.loginSteps.ATUHENTICATOR_VERIFICATION:
        return (
          <EmailVerification
            handleSubmit={params => {
              verifyUser({ ...params, Token: tempToken }, loginConstants.loginSteps.ATUHENTICATOR_VERIFICATION, redirectRoute);
            }}
            customSignOutClick={this.openCustomSignoutModal}
            hideToggle={true}
            cancelClick={clearTempLoginData}
            // title={t('LOGIN.AUTHENTICATOR_VERIFICATION_SUB_TITLE')}
            subtitle={t('LOGIN.AUTHENTICATOR_VERIFICATION_DESCRIPTION')}
          />
        );
      default:
        return (
          <RegisterWrapper>
            <div className="bg-wrapper">
              <CustomSlider
                showFullscreenButton={false}
                items={LOGIN_SLIDER_ITEMS}
                showPlayButton={false}
                showThumbnails={true}
                renderRightNav={onClick => this.renderNav(onClick, true)}
                renderLeftNav={onClick => this.renderNav(onClick)}
                slideDuration={500}
                autoPlay={true}
                renderItem={this.renderItem}
                showBullets={true}
              />
            </div>
            <div className="login-wrapper">
              <LoginForm onSubmit={this.handleLoginSubmit} loginError={loginError} clearErrors={clearErrors} />
            </div>
          </RegisterWrapper>
        );
    }
  }

  render() {
    const { t } = this.context;
    const { modalData } = this.state;

    return (
      <Fragment>
        {FOOTER_TEXT && (
          <p className="f-primary footer-text">
            {t(FOOTER_TEXT)} {FOOTER_LOGO && <img className="footer-logo" src={FOOTER_LOGO} alt="logo" />}
          </p>
        )}
        {this.renderStep()}
        <Modal {...modalData} />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userReducer,
  loginError: state.loginReducer.loginError,
  loginStep: state.loginReducer.loginStep,
  tempToken: state.loginReducer.loginTempToken,
  resendExpiration: state.loginReducer.resendExpiration,
});

const mapDispatchToProps = dispatch => ({
  clearErrors: () => dispatch(loginError(null)),
  authenticateUser: params => dispatch(authenticateUser(params)),
  verifyUser: (params, type, redirectRoute) => dispatch(verifyUser(params, type, redirectRoute)),
  resend: (params, type, setTimer) => dispatch(resend(params, type, setTimer)),
  clearTempLoginData: () => dispatch(clearTempLoginData()),
  logout: () => dispatch(logout()),
});

Login.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
