import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TableHeader from './table-header';
import Table from './table';

import '../styles/table.scss';

class TableComponent extends Component {
  render() {
    const { tableConfig, tableCustomClass, data, onRowClick, translationModule, onColumnSort, sortingObj, formatCell, stickyHeader, sortByColumnProp, sortDirectionProp, customIdentificatorFunction } =
      this.props;

    return (
      <table className={tableCustomClass || ''} data-cy="table">
        <TableHeader
          {...{
            tableConfig,
            translationModule,
            onColumnSort,
            sortingObj,
            stickyHeader,
            sortByColumnProp,
            sortDirectionProp,
          }}
        />
        <Table
          {...{
            tableConfig,
            translationModule,
            data,
            onRowClick,
            formatCell,
            customIdentificatorFunction,
          }}
        />
      </table>
    );
  }
}

TableComponent.propTypes = {
  tableCustomClass: PropTypes.string,
  onRowClick: PropTypes.func,
  translationModule: PropTypes.func,
};

TableComponent.defaultProps = {
  tableConfig: [],
  data: [],
  sortByColumnProp: 'SortByColumn',
  sortDirectionProp: 'SortDirection',
  sortingObj: {},
};

export default TableComponent;
