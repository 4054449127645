import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { chain, find, isEmpty } from 'lodash';

import Components from '../../inspections/components/left-toolbar/components';
import Defects from '../../inspections/components/left-toolbar/defects';
import InspectionDetails from '../../inspections/components/left-toolbar/inspection-details';
import Measurements from '../../inspections/components/left-toolbar/measurements';
import PageNotFound from '../../page-not-found/components/page-not-found';

import { handleCreateWorkOrderModal, handleDefectRelatedComponentsVisible, setWorkOrderFilters, setWorkOrderPaging } from '../../inspections/actions/action-creators';
import { createAreaGroup, deleteAreaGroup, getAreas, updateAreaGroupName } from '../../inspections/actions/area-actions';
import { addExplosiveZone, getExplosiveZones } from '../../inspections/actions/explosive-zones-actions';
import { createNewDefect, editInspectionDetails, getDefectDetails, getInspectionDefects } from '../../inspections/actions/inspection-actions';
import { getNotifications } from '../../inspections/actions/notification-actions';
import { getWorkOrders } from '../../inspections/actions/work-order-actions';

import AccessRenderer from '../../../common/access-renderer/components/access-renderer';

import IsolationCertificates from '../../inspections/components/isolation-certificates/isolation-certificates';
import Areas from '../../inspections/components/left-toolbar/areas';
import NDTMeasurements from '../../inspections/components/left-toolbar/ndt-measurements';
import Notifications from '../../inspections/components/left-toolbar/notifications';
import Workorders from '../../inspections/components/left-toolbar/work-orders';
import Permits from '../../inspections/components/permits/permits';
import ReadingsAndGauges from '../../inspections/components/readings-and-gauges/readings-and-gauges';

import Helpers from '../../../common/helpers';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../common/permissions-constants';
import routesConstants from '../../../common/routes-constants';
import { getIsolationCertificates } from '../../inspections/actions/isolation-certificates';
import { addMeasurement, getMeasurements } from '../../inspections/actions/ndt-actions';
import { getPermits } from '../../inspections/actions/permit-actions';
import { tableConfig as isolationCertificateTableConfig } from '../../inspections/components/isolation-certificates/constants/isolation-certificates-table';
import EditableDate from '../../inspections/components/left-toolbar/editable-date';
import { tableConfig as permitsTableConfig } from '../../inspections/components/permits/constants/permit-constants';
import { tableConfigLite as componentsTableConfig } from '../../inspections/constants/component-constants';
import { measurementTypes, modules, toolTypes } from '../../inspections/constants/constants';
import { formConstants as defectConstants, tableConfigLite as observationConfigLite } from '../../inspections/constants/defect-constants';
import { tableConfigLite as ndtConfigLite } from '../../inspections/constants/ndt-constants';
import { tableConfigLite as notificationConfigLite } from '../../inspections/constants/notification-constants';
import { tableConfigLite as WOConfigLite } from '../../inspections/constants/work-order-constants';
import { getElementActions } from '../../inspections/helpers/inspection-helper';

import Loader from '../../../common/global-loader/components/simple-loader';
import RenderIf from '../../../common/render-if/components/render-if';
import '../styles/module-picker.scss';

class ModulePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: null,
    };
  }

  handleDateChange = date => {
    //selectedDate
    const { editInspectionDetails, inspectionId, inspections } = this.props;
    const dateToSave = new Date(date);

    const today = new Date();
    // if date to save is in future with time, reset time to todays max available time in hours
    if (dateToSave.getDate() === today.getDate() && dateToSave.getHours() > today.getHours()) {
      dateToSave.setHours(today.getHours());
      dateToSave.setMinutes(0);
      dateToSave.setSeconds(0);
      dateToSave.setMilliseconds(0);
    }

    this.setState({ selectedDate: new Date(dateToSave) });
    // handle date changed on Api
    const unixDate = Helpers.getUnixDate(new Date(dateToSave).getTime());

    editInspectionDetails(inspectionId, unixDate, inspections, () => this.setState({ selectedDate: null }));
  };

  renderContent = () => {
    const { t } = this.context;
    const {
      activeItem,
      severityColors,
      inspectionDetails,
      defects,
      measurements,
      measurementsLoading,
      components,
      componentsLoading,
      defectsTemp,
      measurementsTemp,
      componentsTemp,
      openDownloadReportModal,
      getDefectDetails,
      // fetchingNotifications,
      notifications,
      getCameraPosition,
      // explosiveZones,
      // getExplosiveZones,
      // addExplosiveZone,
      areas,
      getAreas,
      addArea,
      getNDTMeasurementsLite,
      ndtMeasurementLocations,
      addNdtMeasurement,
      openDownloadReportModalForMeasurments,
      viewer,
      setModalState,
      componentsVisible,
      componentsClustered,
      handleDefectRelatedComponentsVisible,
      inspectionId,
      addAreaGroup,
      deleteAreaGroup,
      getInspectionDefects,
      updateAreaGroupName,
      scrollToElement,
      searchText,
      openAddQuickNDTPointModal,
      defectsLoading,
      // zonesLoading,
      areasLoading,
      measurementLocationsLoading,
      getNotifications,
      notificationsLoading,
      getWorkOrdersLite,
      workOrders,
      handleCreateWorkOrderModal,
      workOrderAreas,
      queryItem,
      handleActivePage,
      workOrdersLoading,
      sharedModuleActionsMenu,
      activeItemsData,
      createNewDefect,
      getIsolationCertificates,
      isolationCertificates,
      isolationCertificatesLoading,
      getPermits,
      permits,
      permitsLoading,
      workOrderFilters,
      workOrderPagingObject,
      setWorkOrderFilters,
      setWorkOrderPaging,
      user,
      chShow,
      displayUnassignedComponentsLevel,
      chHierarchyID,
      projectId,
    } = this.props;

    if (isEmpty(inspectionDetails)) return null;

    const autoScrollConfig = Helpers.autoScrollConstants[activeItem];
    const autoScrollProps = {
      scrollToElement: element => !isEmpty(autoScrollConfig) && scrollToElement(element, autoScrollConfig.generateCustomIdentificator, autoScrollConfig.offset),
      generateCustomIdentificator: !isEmpty(autoScrollConfig) && autoScrollConfig.generateCustomIdentificator,
    };

    const headerProps = {
      title: t(find(activeItemsData, { name: activeItem })?.label || ''),
      icon: find(activeItemsData, { name: activeItem })?.icon,
      backAction: () => handleActivePage(modules.details),
      moduleActionsMenu: sharedModuleActionsMenu,
    };

    switch (activeItem) {
      case modules.details:
        return inspectionDetails ? (
          <InspectionDetails
            details={inspectionDetails}
            modules={chain(activeItemsData)
              .filter(item => item.name !== modules.details)
              .map(item => ({
                ...item,
                action: () => {
                  handleActivePage(item.name);
                },
              }))
              .value()}
            tools={toolTypes.map(item => ({
              ...item,
              action: () => {
                const { newFeature, comingSoon } = item.feature;
                if (newFeature || comingSoon) {
                  return;
                }

                const goToRoute = !isEmpty(item.route) ? routesConstants.routes.protectedRoutes[item.route].fullPath : null;
                goToRoute && Helpers.goTo(goToRoute, [{ project_id: projectId }, { inspection_id: inspectionDetails.InspectionID }]);
              },
            }))}
          />
        ) : null;
      case modules.measurements:
        return (
          <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.measurements].view.name}>
            {({ hasAccess }) => {
              return hasAccess ? (
                <Measurements
                  measurementsLoading={measurementsLoading}
                  getMeasurements={(searchT, params, callback, loadMore) => getInspectionDefects(inspectionId, modules.measurements, searchT, params, callback, loadMore)}
                  handleItemClick={selectedItem => handleActivePage(modules.measurements, selectedItem)}
                  setModalState={setModalState}
                  module={modules.measurements}
                  queryItem={queryItem}
                  searchText={searchText}
                  inspectionId={inspectionId}
                  viewer={viewer}
                  elements={[...measurements, ...measurementsTemp]}
                  elementsClustered={[]}
                  setClusteringFilters={() => null}
                  {...headerProps}
                  {...autoScrollProps}
                />
              ) : (
                <PageNotFound />
              );
            }}
          </AccessRenderer>
        );
      case modules.defects:
        return (
          <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.observations].view.name}>
            {({ hasAccess }) => {
              return hasAccess ? (
                <Defects
                  openDownloadReportModal={openDownloadReportModal}
                  tableConfig={observationConfigLite}
                  selectDefect={element => getDefectDetails(element)}
                  components={componentsVisible ? componentsClustered : []}
                  handleDefectRelatedComponentsVisible={handleDefectRelatedComponentsVisible}
                  severityColors={severityColors}
                  getInspectionDefects={(searchT, params, callback, loadMore) => getInspectionDefects(inspectionId, modules.defects, searchT, params, callback, loadMore)}
                  defectsLoading={defectsLoading}
                  handleItemClick={selectedItem => handleActivePage(activeItem, selectedItem)}
                  createNewDefect={type => {
                    const { defaultElement } = getElementActions({ SystemType: measurementTypes.defect });
                    createNewDefect(
                      {
                        ...defaultElement(inspectionDetails.CameraPosition),
                        ComponentID: inspectionDetails.DefaultComponent,
                        [defectConstants.fields.mainType]: type,
                        InspectionID: inspectionId,
                      },
                      data => {
                        handleActivePage(activeItem, data.ID);
                        autoScrollProps.scrollToElement(data);
                      }
                    );
                  }}
                  setModalState={setModalState}
                  module={modules.defects}
                  queryItem={queryItem}
                  searchText={searchText}
                  inspectionId={inspectionId}
                  viewer={viewer}
                  elements={[...defects, ...defectsTemp]}
                  elementsClustered={[]}
                  setClusteringFilters={() => null}
                  {...headerProps}
                  {...autoScrollProps}
                />
              ) : (
                <PageNotFound />
              );
            }}
          </AccessRenderer>
        );
      case modules.components:
        return (
          <Components
            componentsLoading={componentsLoading}
            setClusteringFilters={() => null}
            elementsClustered={[]}
            tableConfig={componentsTableConfig}
            getComponents={(searchT, params, callback, loadMore) => {
              getInspectionDefects(inspectionId, modules.components, searchT, params, callback, loadMore, chShow, displayUnassignedComponentsLevel);
            }}
            createNewComponent={callback => {
              const { defaultElement } = getElementActions({ SystemType: measurementTypes.component });
              createNewDefect({ ...defaultElement(inspectionDetails.CameraPosition), InspectionID: inspectionId, HierarchyID: chHierarchyID }, data => {
                handleActivePage(activeItem, data.ID);
                autoScrollProps.scrollToElement(data);
                callback && callback();
              });
            }}
            handleItemClick={selectedItem => handleActivePage(activeItem, selectedItem)}
            setModalState={setModalState}
            module={modules.components}
            queryItem={queryItem}
            searchText={searchText}
            inspectionId={inspectionId}
            elements={[...components, ...componentsTemp]}
            user={user}
            fullScreen
            {...headerProps}
            {...autoScrollProps}
          />
        );
      case modules.workorders:
        return (
          <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.workOrders].view.name}>
            {({ hasAccess }) =>
              hasAccess ? (
                <Workorders
                  elements={workOrders}
                  tableConfig={WOConfigLite}
                  getWorkOrders={(params, loadMore, sucessCallback) => getWorkOrdersLite(inspectionDetails.InspectionID, params, loadMore, sucessCallback)}
                  severityColors={severityColors}
                  workOrdersLoading={workOrdersLoading}
                  searchText={searchText}
                  handleCreateWorkOrderModal={handleCreateWorkOrderModal}
                  inspectionId={inspectionId}
                  workOrderAreas={workOrderAreas}
                  handleItemClick={selectedItem => handleActivePage(activeItem, selectedItem)}
                  setModalState={setModalState}
                  queryItem={queryItem}
                  filters={workOrderFilters}
                  pagingObject={workOrderPagingObject}
                  setWorkOrderFilters={setWorkOrderFilters}
                  setWorkOrderPaging={setWorkOrderPaging}
                  projectId={projectId}
                  user={user}
                  {...headerProps}
                />
              ) : (
                <PageNotFound />
              )
            }
          </AccessRenderer>
        );
      case modules.notifications:
        return (
          <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.notifications].view.name}>
            {({ hasAccess }) =>
              hasAccess ? (
                <Notifications
                  tableConfig={notificationConfigLite}
                  getNotifications={(params, successCallback, loadMore) => getNotifications(inspectionDetails.InspectionID, params, successCallback, loadMore)}
                  notifications={notifications}
                  notificationsLoading={notificationsLoading}
                  viewer={viewer}
                  searchText={searchText}
                  queryItem={queryItem}
                  setModalState={setModalState}
                  handleItemClick={selectedItem => handleActivePage(activeItem, selectedItem)}
                  isFullScreen
                  projectId={projectId}
                  {...headerProps}
                />
              ) : (
                <PageNotFound />
              )
            }
          </AccessRenderer>
        );
      /**
       * Commented out temporary due to the request in https://hybirdclarity.atlassian.net/browse/CLR-5965
       * and until we implement the new Explosive Zones module along with R&G module development
       */
      // case modules.explosiveZones:
      //   return (
      //     <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.zones].view.name}>
      //       {({ hasAccess }) =>
      //         hasAccess ? (
      //           <ExplosiveZones
      //             getExplosiveZones={(searchText, params, loadMore, callback) => getExplosiveZones(inspectionId, searchText, params, loadMore, callback)}
      //             addExplosiveZone={data => addExplosiveZone(inspectionId, explosiveZones, data, newZone => handleActivePage(modules.explosiveZones, newZone.ID))}
      //             zonesLoading={zonesLoading}
      //             handleItemClick={selectedItem => handleActivePage(modules.explosiveZones, selectedItem)}
      //             module={modules.explosiveZones}
      //             queryItem={queryItem}
      //             searchText={searchText}
      //             inspectionId={inspectionId}
      //             viewer={viewer}
      //             elements={explosiveZones}
      //             elementsClustered={[]}
      //             setClusteringFilters={() => null}
      //             {...headerProps}
      //             {...autoScrollProps}
      //           />
      //         ) : (
      //           <PageNotFound />
      //         )
      //       }
      //     </AccessRenderer>
      //   );
      case modules.areas:
        return (
          <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.areas].view.name}>
            {({ hasAccess }) => {
              return hasAccess ? (
                <Areas
                  getAreas={(searchText, params, loadMore, callback) => getAreas(inspectionId, searchText, params, loadMore, callback)}
                  addArea={(data, camPos, callback) =>
                    addArea(inspectionId, areas, data, camPos, data => {
                      callback && callback();
                      scrollToElement(data, autoScrollConfig.areas.generateCustomIdentificator, autoScrollConfig.areas.offset);
                      handleActivePage(modules.areas, data.ID);
                    })
                  }
                  deleteAreaGroup={(id, emptyGroups) => deleteAreaGroup(id, emptyGroups, areas)}
                  updateAreaGroupName={(id, name) => updateAreaGroupName(id, name, areas)}
                  createAreaGroup={(name, callback) => addAreaGroup(inspectionId, name, callback)}
                  getCameraPosition={getCameraPosition}
                  areasLoading={areasLoading}
                  handleItemClick={selectedItem => handleActivePage(modules.areas, selectedItem)}
                  setModalState={setModalState}
                  module={modules.areas}
                  queryItem={queryItem}
                  searchText={searchText}
                  inspectionId={inspectionId}
                  viewer={viewer}
                  elements={areas}
                  elementsClustered={[]}
                  setClusteringFilters={() => null}
                  {...headerProps}
                  {...autoScrollProps}
                />
              ) : (
                <PageNotFound />
              );
            }}
          </AccessRenderer>
        );
      case modules.ndtData:
        return (
          <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.ndt].view.name}>
            {({ hasAccess }) => {
              return hasAccess ? (
                <NDTMeasurements
                  severityColors={severityColors}
                  defaultComponentID={inspectionDetails.DefaultComponent}
                  getMeasurements={(searchText, params, loadMore, callback) => getNDTMeasurementsLite(inspectionId, searchText, params, loadMore, callback)}
                  measurementLocationsLoading={measurementLocationsLoading}
                  addMeasurement={(data, camPos) =>
                    addNdtMeasurement(inspectionId, ndtMeasurementLocations, data, camPos, data => {
                      autoScrollProps.scrollToElement(data);
                      handleActivePage(modules.ndtData, data.ID);
                    })
                  }
                  tableConfig={ndtConfigLite}
                  getCameraPosition={getCameraPosition}
                  openDownloadReportModalForMeasurments={openDownloadReportModalForMeasurments}
                  openAddQuickNDTPointModal={openAddQuickNDTPointModal}
                  handleItemClick={selectedItem => handleActivePage(activeItem, selectedItem)}
                  setModalState={setModalState}
                  module={modules.ndtData}
                  queryItem={queryItem}
                  searchText={searchText}
                  inspectionId={inspectionId}
                  viewer={viewer}
                  elements={ndtMeasurementLocations}
                  elementsClustered={[]}
                  setClusteringFilters={() => null}
                  {...headerProps}
                  {...autoScrollProps}
                />
              ) : (
                <PageNotFound />
              );
            }}
          </AccessRenderer>
        );

      case modules.isolationCertificates:
        return (
          <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.isolationCertificates].view.name}>
            {({ hasAccess }) => {
              return hasAccess ? (
                <IsolationCertificates
                  projectId={projectId}
                  searchText={searchText}
                  queryItem={queryItem}
                  tableConfig={isolationCertificateTableConfig}
                  getIsolationCertificates={(params, loadMore, callback, advancedFilter) =>
                    getIsolationCertificates(params, Object.assign([], isolationCertificates), loadMore, callback, advancedFilter)
                  }
                  isolationCertificates={isolationCertificates}
                  isolationCertificatesLoading={isolationCertificatesLoading}
                  handleItemClick={selectedItem => handleActivePage(activeItem, selectedItem)}
                  setModalState={setModalState}
                  {...headerProps}
                  {...autoScrollProps}
                />
              ) : (
                <PageNotFound />
              );
            }}
          </AccessRenderer>
        );
      case modules.permits:
        return (
          <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.permits].view.name}>
            {({ hasAccess }) => {
              return hasAccess ? (
                <Permits
                  projectId={projectId}
                  searchText={searchText}
                  queryItem={queryItem}
                  tableConfig={permitsTableConfig}
                  getPermits={(params, loadMore, callback, advancedFilter) => getPermits(params, Object.assign([], permits), loadMore, callback, advancedFilter)}
                  permits={permits}
                  permitsLoading={permitsLoading}
                  handleItemClick={selectedItem => handleActivePage(activeItem, selectedItem)}
                  setModalState={setModalState}
                  {...headerProps}
                  {...autoScrollProps}
                />
              ) : (
                <PageNotFound />
              );
            }}
          </AccessRenderer>
        );
      case modules.readingsAndGauges:
        return (
          <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].view.name}>
            {({ hasAccess }) => {
              return hasAccess ? (
                <ReadingsAndGauges
                  handleActivePage={handleActivePage}
                  queryItem={queryItem}
                  projectID={projectId}
                  inspectionId={inspectionId}
                  isFullScreen
                  user={user}
                  {...headerProps}
                  {...autoScrollProps}
                />
              ) : (
                <PageNotFound />
              );
            }}
          </AccessRenderer>
        );
      default:
        return <div>{t('INSPECTION_NO_CONTENT')}</div>;
    }
  };

  render() {
    const { className, projectDetails, activeItem, inspectionDetails } = this.props;

    return (
      <Fragment>
        <div className={`module-picker padding-horizontal padding-vertical ${className}`}>
          <RenderIf if={projectDetails.isLoading}>
            <Loader isLoading={projectDetails.isLoading} />
          </RenderIf>
          <RenderIf if={!projectDetails.isLoading}>
            <RenderIf if={activeItem === modules.details}>
              <div className="module-picker__header">
                <div className="header">
                  <h2 className="f-primary noselect">{projectDetails ? projectDetails.ProjectName : ''}</h2>
                  <EditableDate
                    displayValue={inspectionDetails ? Helpers.getDateFromUnix(inspectionDetails.InspectionDate) : ''}
                    currentDate={inspectionDetails ? inspectionDetails.InspectionDate : null}
                    disabled
                  />
                </div>
              </div>
            </RenderIf>

            <div className="module-picker__main-content">{this.renderContent()}</div>
          </RenderIf>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  inspectionDetails: state.inspectionReducer.inspectionDetails,
  inspections: state.inspectionReducer.inspections,
  defects: state.inspectionReducer.defects,
  defectsLoading: state.inspectionReducer.defectsLoading,
  defectsTemp: state.inspectionReducer.defectsTemp,
  measurements: state.inspectionReducer.measurements,
  measurementsTemp: state.inspectionReducer.measurementsTemp,
  measurementsLoading: state.inspectionReducer.measurementsLoading,
  components: state.inspectionReducer.components,
  componentsLoading: state.inspectionReducer.componentsLoading,
  componentsClustered: state.inspectionReducer.componentsClustered,
  componentsVisible: state.inspectionReducer.componentsVisible,
  componentsTemp: state.inspectionReducer.componentsTemp,
  severityColors: state.themeReducer.severityColors,
  chShow: state.inspectionReducer.chShow,
  chHierarchyID: state.inspectionReducer.chHierarchyID,
  displayUnassignedComponentsLevel: state.inspectionReducer.displayUnassignedComponentsLevel,
  //notifications
  notifications: state.notificationReducer.notifications,
  notificationsLoading: state.notificationReducer.notificationsLoading,
  //workorders
  workOrders: state.workOrderReducer.workOrders,
  workOrderAreas: state.workOrderReducer.workOrderAreas,
  workOrdersLoading: state.workOrderReducer.workOrdersLoading,
  workOrderFilters: state.workOrderReducer.filters,
  workOrderPagingObject: state.workOrderReducer.pagingObject,
  explosiveZones: state.explosiveZonesReducer.zones,
  zonesLoading: state.explosiveZonesReducer.zonesLoading,
  areas: state.areasReducer.areas,
  areasLoading: state.areasReducer.areasLoading,
  ndtMeasurementLocations: state.ndtReducer.measurementLocations,
  measurementLocationsLoading: state.ndtReducer.measurementLocationsLoading,
  viewer: null,
  inspectionModalData: state.inspectionReducer.inspectionModalData,
  searchText: state.inspectionReducer.searchText,
  isolationCertificates: state.isolationCertificatesReducer.isolationCertificates,
  isolationCertificatesLoading: state.isolationCertificatesReducer.isolationCertificatesLoading,
  // permits
  permits: state.permitsReducer.permits,
  permitsLoading: state.permitsReducer.permitsLoading,
  //project
  projectDetails: state.projectDetailsReducer,
  user: state.userReducer,
});

const mapDispatchToProps = dispatch => ({
  getInspectionDefects: (id, activePage, search, additionalProps, callback, loadMore, isHierarchy, isUnassignedLevel) =>
    dispatch(getInspectionDefects(id, activePage, search, additionalProps, callback, loadMore, true, isHierarchy, isUnassignedLevel)),
  editInspectionDetails: (inspectionId, date, inspections, callback) => dispatch(editInspectionDetails(inspectionId, date, inspections, callback)),
  getDefectDetails: element => dispatch(getDefectDetails(element)),
  getNotifications: (inspectionId, params, successCallback, loadMore) => dispatch(getNotifications(inspectionId, params, successCallback, loadMore)),
  getNDTMeasurementsLite: (inspectionId, searchText, params, loadMore, callback) => dispatch(getMeasurements(inspectionId, searchText, params, loadMore, callback, true)),
  getExplosiveZones: (inspectionId, searchText, params, loadMore, callback) => dispatch(getExplosiveZones(inspectionId, searchText, params, loadMore, callback)),
  addExplosiveZone: (inspectionId, currentZones, newData, callback) => dispatch(addExplosiveZone(inspectionId, currentZones, newData, callback)),
  getAreas: (inspectionId, searchText, params, loadMore, callback) => dispatch(getAreas(inspectionId, searchText, params, loadMore, callback)),
  addNdtMeasurement: (inspectionId, currentZones, newData, camPos, callback) => dispatch(addMeasurement(inspectionId, currentZones, newData, camPos, callback)),
  handleDefectRelatedComponentsVisible: isVisible => dispatch(handleDefectRelatedComponentsVisible(isVisible)),
  addAreaGroup: (inspectionId, name, callback) => dispatch(createAreaGroup(inspectionId, name, callback)),
  deleteAreaGroup: (areaGroupId, emptyGroups, currentAreas) => dispatch(deleteAreaGroup(areaGroupId, emptyGroups, currentAreas)),
  updateAreaGroupName: (areaGroupId, name) => dispatch(updateAreaGroupName(areaGroupId, name)),
  //workOrders
  getWorkOrdersLite: (inspectionId, params, loadMore, sucessCallback) => dispatch(getWorkOrders(inspectionId, params, loadMore, sucessCallback, true)),
  handleCreateWorkOrderModal: data => dispatch(handleCreateWorkOrderModal(data)),
  createNewDefect: (data, callback) => dispatch(createNewDefect(data, callback)),
  getIsolationCertificates: (filters, isolationCertificates, loadMore, callback, advancedFilter) =>
    dispatch(getIsolationCertificates(filters, isolationCertificates, loadMore, callback, advancedFilter)),
  getPermits: (filters, isolationCertificates, loadMore, callback, advancedFilter) => dispatch(getPermits(filters, isolationCertificates, loadMore, callback, advancedFilter)),
  setWorkOrderFilters: filters => dispatch(setWorkOrderFilters(filters)),
  setWorkOrderPaging: pagingObject => dispatch(setWorkOrderPaging(pagingObject)),
});

ModulePicker.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModulePicker);
