import React, { useState, useMemo } from 'react';
import VersionModalForm from './components/version-modal-form';
import { fields } from './constants/constants';
import { fields as dmsFields } from '../../../../../constants/constants';
import Helpers from '../../../../../../../common/helpers';
import { pick } from 'lodash';

import PropTypes from 'prop-types';

const VersionModal = props => {
  const { versionFile, documentDetails, closeAction, customCloseAction, fileUploadHandler, isEditMode, handleEditAction, nextVersionNumber } = props;
  const [isButtonDisabled, setIsButtonDisabled] = useState(isEditMode);

  const handleOnSubmitForm = values => {
    if (isEditMode) {
      const prepareFile = {
        ...pick(values, [fields.versionDescription, fields.versionNumber, fields.fileVersion]),
        [fields.versionID]: values[fields.id],
        [fields.versionDate]: values[fields.versionDate] ? Helpers.getUnixDate(new Date(values[fields.versionDate]).getTime()) : null,
      };
      handleEditAction(prepareFile, documentDetails);
      return;
    }
    const prepareFile = {
      ...versionFile,
      ...values,
      [dmsFields.fileName]: versionFile[dmsFields.realName],
      [dmsFields.sourceID]: documentDetails[dmsFields.sourceID],
      [dmsFields.status]: documentDetails[dmsFields.status],
      [dmsFields.documentHierarchyID]: documentDetails[dmsFields.documentHierarchyID],
      [dmsFields.smartDrawing]: documentDetails[dmsFields.smartDrawing],
      [fields.versionDate]: values[fields.versionDate] ? Helpers.getUnixDate(new Date(values[fields.versionDate]).getTime()) : null,
    };
    fileUploadHandler([prepareFile], null, true);
  };

  const initialValues = useMemo(() => {
    if (isEditMode) {
      return { ...versionFile, [fields.versionDate]: versionFile[fields.versionDate] ? Helpers.getDateFromUnix(versionFile[fields.versionDate]) : null, [fields.name]: versionFile[fields.fileName] };
    } else return { [fields.versionDate]: new Date(), [fields.isActive]: false };
  }, [versionFile, isEditMode]);

  const onFormChange = (_val, _func, form) => {
    if (!form && !form.dirty && isEditMode) return;
    setIsButtonDisabled(!form.dirty);
  };

  const modalCloseAction = useMemo(() => (isEditMode ? customCloseAction : closeAction), [isEditMode, closeAction, customCloseAction]);

  return (
    <VersionModalForm
      file={versionFile}
      nextVersionNumber={nextVersionNumber}
      closeAction={modalCloseAction}
      initialValues={initialValues}
      onSubmit={handleOnSubmitForm}
      isEditMode={isEditMode}
      onChange={onFormChange}
      isButtonDisabled={isEditMode ? isButtonDisabled : false}
    />
  );
};

VersionModal.defaultProps = {
  isEditMode: false,
};

VersionModal.propTypes = {
  versionFile: PropTypes.object.isRequired,
  documentDetails: PropTypes.object.isRequired,
  closeAction: PropTypes.func.isRequired,
  fileUploadHandler: PropTypes.func,
  isEditMode: PropTypes.bool.isRequired,
  handleEditAction: PropTypes.func,
  nextVersionNumber: PropTypes.number,
};

export default VersionModal;
