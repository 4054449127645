import { fields, fieldsValidatorConstants } from '../constants/constants';

export const validateVersionMetaData = values => {
  const errors = {};

  if (values[fields.versionNumber] && values[fields.versionNumber].length > fieldsValidatorConstants[fields.versionNumber].maxChars) {
    errors[fields.versionNumber] = 'DMS_FILE.VERSION_NUMBER.MAX_CHARS';
  }
  if (values[fields.versionDescription] && values[fields.versionDescription].length > fieldsValidatorConstants[fields.versionDescription].maxChars) {
    errors[fields.versionDescription] = 'DMS_FILE.DESCRIPTION.MAX_CHARS';
  }
  if (!values[fields.versionDate]) {
    errors[fields.versionDate] = 'DMS_FILE.VERSION_DATE.REQUIRED';
  }

  return errors;
};
