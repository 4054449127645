import Helpers from '../../../../../../common/helpers';
import Icon from '../../../../../../common/icon/components/icon';
import RenderIf from '../../../../../../common/render-if/components/render-if';
import InfoTooltip from '../../../../../../common/tooltip/components/info-tooltip';
import { fields } from '../../../../constants/constants';
import VersionHistoryDropdown from '../components/version-history-dropdown';

export const formatVersionHistoryTable = (
  value,
  type,
  _searchTerm,
  row,
  onDropdownClick,
  t,
  _pointerDotID,
  _user,
  _onToggleClick,
  _onDeleteRow,
  _statusData,
  _handleChangeStatus,
  _onLinkFileClick,
  _selectedItem,
  _cells
) => {
  if (type === fields.isActive) {
    return value ? <Icon name="checkmark" active handleHover={false} size="sm" /> : <></>;
  } else if (type === fields.versionDate) {
    return value ? Helpers.getDateFromUnix(value, 'DD MMM,YYYY hh:mm A') : '-';
  } else if (type === fields.fileName) {
    return value ? (
      <span className="name-column__container">
        <p title={value} className="name-column__text three-dot-text">
          {value}
        </p>
        <RenderIf if={!row.loading}>
          <InfoTooltip
            actionsMenu={{
              [t('DMS_FILE.VERSION_UPLOADED_BY')]: row[fields.versionCreatedBy],
              [t('DMS_FILE.VERSION_UPLOADED_DATE')]: row[fields.versionCreatedAt] ? Helpers.getDateFromUnix(row[fields.versionCreatedAt], 'DD MMM, YYYY hh:mm A') : null,
            }}
            offsetY={8}
            offsetX={8}
            Component={() => <Icon name="info" size="sm" />}
            componentProps={{ title: '' }}
            containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
          />
        </RenderIf>
      </span>
    ) : (
      '-'
    );
  } else if (type === fields.actionMenu && !row.loading) {
    return <VersionHistoryDropdown isActive={row[fields.isActive]} getClickAction={action => onDropdownClick(action, row, row.documentDetails)} documentDetails={row.documentDetails} />;
  } else return value ? value : '-';
};
