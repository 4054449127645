import { capitalize } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from '../../icon/components/icon';
import RenderIf from '../../render-if/components/render-if';
import '../styles/button.scss';

export const Button = props => {
  const { text, disabled, type, className, onClick, height, width, variant, marginAuto, isCapitalized, keepOriginalText, icon, iconProps, isLoading, iconPosition = 'right', ...restProps } = props;

  const { className: iconClassName, ...restIconProps } = iconProps || {};
  const getButtonText = () => {
    if (isCapitalized || keepOriginalText) {
      return text;
    }

    return capitalize(text);
  };

  return (
    <button disabled={disabled} type={type} className={`btn ${className} btn-${variant} h-${height} w-${width} ${marginAuto ? 'auto' : ''}`} onClick={onClick} {...restProps} data-cy="button">
      <div className={`button-text noselect ${isCapitalized ? 'capitalized' : ''}`}>
        <RenderIf if={icon}>
          <>
            {icon && iconPosition === 'left' && <Icon name={icon} handleHover={false} className={`with-icon with-icon__position-${iconPosition} ${iconClassName || ''}`} {...(restIconProps || {})} />}
            {getButtonText()}
            {icon && iconPosition === 'right' && (
              <Icon name={icon} handleHover={false} className={`with-icon with-icon__position-${iconPosition}  ${iconClassName || ''}`} {...(restIconProps || {})} />
            )}
          </>
        </RenderIf>
        <RenderIf if={!icon}>
          <>{getButtonText()}</>
        </RenderIf>
        <RenderIf if={isLoading}>
          <Icon name="loader" size="xs" className="loader-icon" />
        </RenderIf>
      </div>
    </button>
  );
};

Button.defaultProps = {
  text: '',
  disabled: false,
  type: 'submit',
  height: 'sm',
  width: 'md',
  variant: 'success',
  onClick: () => null,
  className: '',
  marginAuto: false,
  isCapitalized: false,
  keepOriginalText: false,
  icon: '',
  iconProps: {},
  isLoading: false,
};
Button.propTypes = {
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['submit', 'button']).isRequired,
  height: PropTypes.oneOf(['sm', 'md']).isRequired,
  width: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']).isRequired,
  variant: PropTypes.oneOf(['success', 'success-outline', 'danger', 'danger-outline', 'gray', 'gray-outline', 'warning', 'warning-outline']).isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  marginAuto: PropTypes.bool,
  isCapitalized: PropTypes.bool,
  keepOriginalText: PropTypes.bool,
  icon: PropTypes.string,
  iconProps: PropTypes.object,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  isLoading: PropTypes.bool,
};

export default Button;
