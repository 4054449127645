import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import PropTypes from 'prop-types';

import Button from '../../../../common/form/components/button';
import BasicInput from '../../../../common/form/components/input';

import { teamFields } from '../../constants/team-constants';
import { FORMS } from '../../../../common/constants';

class TeamsForm extends Component {
  render() {
    const { t } = this.context;
    const { handleSubmit, submitButtonText } = this.props;

    return (
      <form onSubmit={handleSubmit} data-cy="team-form">
        <Field id={teamFields.name} name={teamFields.name} component={BasicInput} placeholder={'CREATE_TEAM_POPUP.NAME'} label={'CREATE_TEAM_POPUP.NAME'} />
        <Button text={t(submitButtonText)} />
      </form>
    );
  }
}
TeamsForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

TeamsForm.defaultProps = {
  submitButtonText: 'CREATE_TEAM_POPUP.SUBMIT',
};

TeamsForm = reduxForm({
  form: FORMS.createTeam,
  enableReinitialize: true,
  validate: values => {
    let errors = {};
    if (!values[teamFields.name]) {
      errors[teamFields.name] = 'CREATE_TEAM_POPUP.NAME_VALIDATION';
    }
    return errors;
  },
})(TeamsForm);

export default TeamsForm;
