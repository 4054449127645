import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Helpers from '../../helpers';

const AccessRenderer = ({ children, user, visibleFor, id, ownerRequiredPermission, operator }) => {
  return (
    <>
      {children
        ? children({
            hasAccess: Helpers.hasAccess({ user, visibleFor, id, ownerRequiredPermission, operator }),
          })
        : null}
    </>
  );
};

const mapStateToProps = state => ({
  user: state.userReducer,
});

AccessRenderer.propTypes = {
  children: PropTypes.func.isRequired,
  visibleFor: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  id: PropTypes.number,
  isAllowed: PropTypes.bool,
  ownerRequiredPermission: PropTypes.string,
  operator: PropTypes.oneOf(['OR', 'AND']),
};

AccessRenderer.defaultProps = {
  visibleFor: [],
  ownerRequiredPermission: null,
  operator: 'OR',
};

export default connect(mapStateToProps, null)(AccessRenderer);
