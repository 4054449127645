import React from 'react';
import PropTypes from 'prop-types';

import { Circle } from 'rc-progress';

import '../styles/loader.scss';

const ImageLoader = props => {
  const { percent } = props;
  return (
    <div className={`image-loader ${props.className || ''}`}>
      <Circle {...props} percent={percent} />
    </div>
  );
};

ImageLoader.defaultProps = {
  percent: 0,
};

ImageLoader.propTypes = {
  percent: PropTypes.number,
};

export default ImageLoader;
