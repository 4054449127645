export const steps = {
  firstStep: 1,
  secondStep: 2,
  thirdStep: 3,
};

export const formConstants = {
  fields: {
    id: 'ID',
    name: 'Name',
    assignedKeys: 'AssignedKeys',
    contractorId: 'ContractorID',
    sectionId: 'IssuedSectionID',
    answers: 'Answers',
    contractor: {
      id: 'change-status-contractor',
      name: 'PersonID',
      label: 'PERMIT.ISSUE_PERMIT_FORM.CONTRACTOR_LABEL',
      placeholder: 'PERMIT.ISSUE_PERMIT_FORM.CONTRACTOR_LABEL',
      errorRequired: 'PERMIT.ISSUE_PERMIT_FORM.CONTRACTOR_REQUIRED',
    },
    assigneeSignature: {
      id: 'change-status-assignee-signature',
      querySelector: 'change-status-assignee-signature-query-selector',
      name: 'AssigneeSignature',
      label: 'PERMIT.ISSUE_PERMIT_FORM.ASSIGNEE_SIGNATURE_LABEL',
      errorRequired: 'PERMIT.ISSUE_PERMIT_FORM.ASSIGNEE_SIGNATURE_REQUIRED',
    },
    witnessSignature: {
      id: 'change-status-witness-signature',
      querySelector: 'change-status-witness-signature-query-selector',
      name: 'WitnessSignature',
      label: 'PERMIT.ISSUE_PERMIT_FORM.WITNESS_SIGNATURE_LABEL',
      errorRequired: 'PERMIT.ISSUE_PERMIT_FORM.WITNESS_SIGNATURE_REQUIRED',
    },
  },
};

export const keyFields = {
  id: 'KeyID',
  name: 'Name',
  keyboxId: 'KeyboxID',
  selectedKey: 'SelectedKey',
};

export const contractorDropdownFields = {
  valueField: 'ID',
  textField: 'Name',
};

export const componentFields = {
  id: 'ID',
  code: 'Code',
  name: 'Name',
  critical: 'Critical',
};

export const componentsTableConfig = [
  { title: 'CODE', key: componentFields.code, type: componentFields.code, class: 'code' },
  { title: 'NAME', key: componentFields.name, type: componentFields.name, class: 'name' },
  { title: '', key: componentFields.critical, type: componentFields.critical, class: 'critical-icon' },
];
