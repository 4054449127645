import React from 'react';
import PropTypes from 'prop-types';

import '../styles/ch-empty-state.scss';

const CHEmptyState = ({ create }, { t }) => {
  return (
    <div className="ch-empty-state">
      <h6 className="ch-empty-state__title">{t('COMPONENT_HIERARCHY.NO_HIERARCHY')}</h6>
      <p className="ch-empty-state__description">
        {t('COMPONENT_HIERARCHY.CLICK')} <span className="light-bold">{t('COMPONENT_HIERARCHY.NEW_LEVEL')}</span> {t(create ? 'COMPONENT_HIERARCHY.CREATE' : 'COMPONENT_HIERARCHY.START')}
      </p>
    </div>
  );
};

CHEmptyState.contextTypes = {
  t: PropTypes.func.isRequired,
  create: PropTypes.bool,
};

export default CHEmptyState;
