import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { reduxForm } from 'redux-form';

import Toolbar from '../../toolbar/components/toolbar';
import DownloadReportFrom from './download-report-form';
import CustomTable from '../../table/components/table-component';

import Stepper from '../../stepper/components/stepper';

import { isEmpty, map } from 'lodash';

import Helpers from '../../helpers';
import ScreenshotHelpers from '../../screenshoot-helpers';

import { fetchDownloadReportData, clearReportData, downloadReport, fetchDownloadReportHistory, removeCoverImage } from '../actions/download-report-actions';
import { notificationModalCustom } from '../../modal/actions/modal-actions';

import { FORMS } from '../../constants';

import constants from '../constants/download-report-constants';

import { loadProjects } from '../../../app/project/actions/project-actions';
import { sortingOptions, projectSortData, sortingOrder } from '../../../app/project/constants/project-fields';

import '../styles/download-report.scss';

class DownloadReportModal extends Component {
  state = {
    activeItem: constants.tabs[0].name,
    step: constants.steps.firstStep,
    selectedFile: {},
    ModelView: null,
    GoogleMapsView: null,
    lastStep: constants.steps.fifthStep,
    instance: null,
  };

  componentDidMount = () => {
    const { inspectionId, fetchDownloadReportData, loadProjects } = this.props;

    fetchDownloadReportData(inspectionId);
    loadProjects(
      {
        [sortingOptions.sortByColumn]: projectSortData[0].value,
        [sortingOptions.order]: sortingOrder.asc,
      },
      null
    );
  };

  handleActiveTab = type => {
    const { inspectionId } = this.props;
    const { fetchDownloadReportHistory, fetchDownloadReportData, clearReportData } = this.props;
    this.setState({ activeItem: type });

    if (type === constants.tabNames.reportHistory) {
      fetchDownloadReportHistory(inspectionId);
      clearReportData();
    }
    if (type === constants.tabNames.downloadReport) {
      fetchDownloadReportData(inspectionId);
      this.setState({ step: constants.steps.firstStep });
    }
  };

  submitForm = vs => {
    const { selectedFile } = this.state;
    const { downloadReport } = this.props;
    const vals = this.prepareReportValues(vs);

    downloadReport(vals, this.callbackAction, 'DOWNLOAD_REPORT.SUCCESS_MESSAGE', 'DOWNLOAD_REPORT.SUCCESS_MESSAGE_TITLE', selectedFile.uploadType ? selectedFile : null);
  };

  prepareReportValues = vs => {
    const { ModelView, GoogleMapsView } = this.state;
    const { inspectionId, reduceToSelectedImages, defects } = this.props;

    // remove ALL prop if present or empty prop values
    let vals = Object.assign({}, vs);
    vals[constants.fields.sortBy] = vals[constants.fields.sortBy] ? vals[constants.fields.sortBy][constants.objectConstruction.value] : null;

    vals = Helpers.mapDownloadReportFilter(vals, constants.defaultValue);

    vals = {
      InspectionID: inspectionId,
      SelectionReport: reduceToSelectedImages || false,
      [constants.fields.includedIDs]: map(defects, item => item.ID),
      ...vals,
      ModelView,
      GoogleMapsView,
    };
    return vals;
  };

  callbackAction = () => {
    const { clearReportData, closeModal } = this.props;
    clearReportData();
    closeModal(true);
  };

  onRowClick = (e, item) => {
    window.open(item.ReportUrl, '_blank');
  };

  onStepLoaded = step => {
    const { loadProjects } = this.props;

    if (step === constants.steps.fourthStep) {
      loadProjects(
        {
          [sortingOptions.sortByColumn]: projectSortData[0].value,
          [sortingOptions.order]: sortingOrder.asc,
        },
        null
      );
    }
  };

  changeStep = direction => {
    let { step } = this.state;

    const nextStep = direction ? step + 1 : step - 1;

    const setStep = () => {
      this.setState({ step: nextStep });
      this.onStepLoaded(nextStep);
    };

    //generate screenshot when going from 4th step to fifth
    if (direction && step === constants.steps.thirdStep) {
      this.getInspectionScreenshot(`#${constants.potreeId}`, () => setStep());

      return;
    } else if (direction && step === constants.steps.fourthStep) {
      this.getMapsScreenshot(`#${constants.mapId}`, () => setStep());
      return;
    }
    setStep();
  };

  getInspectionScreenshot = (querySelector, onFinish) => {
    ScreenshotHelpers.takeScreenshot(
      querySelector,
      url => {
        this.setState({ ModelView: url });
      },
      onFinish
    );
  };

  getMapsScreenshot = (querySelector, onFinish) => {
    const options = {
      useCORS: true,
      allowTaint: false,
      ignoreElements: node => node.nodeName === 'IFRAME' || node.classList.contains('gmnoprint'),
    };

    ScreenshotHelpers.takeScreenshot(
      querySelector,
      url => {
        this.setState({ GoogleMapsView: url });
      },
      onFinish,
      options
    );
  };

  removeImage = () => {
    const { removeCoverImage, coverPDF } = this.props;

    if (coverPDF) {
      removeCoverImage(() => {
        this.setState({ selectedFile: {} });
      }, constants.fields.coverPDF);
    } else {
      this.setState({ selectedFile: {} });
    }
  };

  onDrop = files => {
    if (files[0].size > constants.maxFileSize) {
      const { notificationModalCustom } = this.props;
      //open modal with message
      notificationModalCustom(true, 'DOWNLOAD_REPORT.ERROR_TEXT', 'DOWNLOAD_REPORT.ERROR_TITLE');
      return;
    } else {
      //handle upload image preference
      var file = new Blob([files[0]], { type: 'application/pdf' });
      var fileURL = URL.createObjectURL(file);

      files[0].uploadType = constants.fileUploadType;
      files[0].timeStamp = Date.now();
      files[0].fileURL = fileURL;

      this.setState({ selectedFile: files[0] });
    }
  };

  createViewerInstance = newInstance => {
    this.setState({ instance: newInstance });
  };

  render() {
    const { t } = this.context;
    const {
      activeItem,
      step,
      selectedFile: { name, fileURL },
      lastStep,
      instance,
    } = this.state;
    const { sortByFilter, handleSubmit, fetchingReportData, downloadReportHistory, initialValues, coverPDF, defects, projects, projectId } = this.props;

    return (
      <div className="download-report">
        <Toolbar toolbarItems={constants.tabs} activeItem={activeItem} handleActiveTab={this.handleActiveTab} />

        {activeItem === constants.tabNames.downloadReport && (
          <div className="download-report__form">
            <Stepper stepperData={constants.stepperData} activeStep={step} />

            <DownloadReportFrom
              sortByFilter={sortByFilter}
              handleSubmit={step === lastStep ? handleSubmit : null}
              fetchingReportData={fetchingReportData}
              downloadReport={this.submitForm}
              initialValues={initialValues}
              step={step}
              nextStep={step === lastStep ? null : () => this.changeStep(true)}
              previousStep={step === constants.steps.firstStep ? null : () => this.changeStep(false)}
              onDrop={this.onDrop}
              selectedFileUrl={fileURL || coverPDF}
              selectedFileName={name || coverPDF}
              createViewerInstance={this.createViewerInstance}
              viewer={instance}
              potreeId={constants.potreeId}
              defects={defects || []}
              projectId={projectId}
              projects={projects}
              mapId={constants.mapId}
              removeImage={this.removeImage}
              defectsAffected={defects?.length || 0}
            />
          </div>
        )}
        {activeItem === constants.tabNames.reportHistory && (
          <div className="download-report__table">
            {!isEmpty(downloadReportHistory) && (
              <CustomTable
                tableConfig={constants.reportTableHeader}
                data={downloadReportHistory || []}
                translationModule={t}
                formatCell={Helpers.formatCell}
                onRowClick={this.onRowClick}
                stickyHeader={true}
              />
            )}
            {isEmpty(downloadReportHistory) && (
              <div>
                <p className="f-primary">{t('DOWNLOAD_REPORT.EMPTY_STATE')}</p>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
DownloadReportModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = ({ inspectionReducer: { fetchingReportData }, downloadReportReducer: { downloadReportHistory, downloadReportPreferences, sortByFilter }, projectReducer: { projects } }) => ({
  sortByFilter: sortByFilter,
  fetchingReportData,
  downloadReportHistory,
  projects,
  coverPDF: downloadReportPreferences?.[constants.fields.coverPDF] || null,
  initialValues: {
    [constants.fields.description]: '',
    [constants.fields.recomendations]: '',
    [constants.fields.sortBy]: sortByFilter?.[0],
    [constants.fields.imagesPerPage]: downloadReportPreferences?.[constants.fields.imagesPerPage],
    [constants.fields.includeComponentDetails]: downloadReportPreferences?.[constants.fields.includeComponentDetails],
    [constants.fields.includeDefectSummary]: downloadReportPreferences?.[constants.fields.includeDefectSummary],
    [constants.fields.includeRecommendationSummary]: downloadReportPreferences?.[constants.fields.includeRecommendationSummary],
  },
});

const mapDispatchToProps = dispatch => ({
  fetchDownloadReportData: (inspectionId, callback) => dispatch(fetchDownloadReportData(inspectionId, callback)),
  clearReportData: () => dispatch(clearReportData()),
  downloadReport: (data, callback, successMessage, successMessageTitle, selectedFile) => dispatch(downloadReport(data, callback, successMessage, successMessageTitle, selectedFile)),
  fetchDownloadReportHistory: inspectionID => dispatch(fetchDownloadReportHistory(inspectionID)),
  notificationModalCustom: (isOpen, errorMessage, title) => dispatch(notificationModalCustom(isOpen, errorMessage, title)),
  loadProjects: (data, persistState) => dispatch(loadProjects(data, persistState)),
  removeCoverImage: (callback, fieldToRemove) => dispatch(removeCoverImage(callback, fieldToRemove)),
});

DownloadReportModal = reduxForm({
  form: FORMS.downloadReport,
  enableReinitialize: true,
})(DownloadReportModal);

export default connect(mapStateToProps, mapDispatchToProps)(DownloadReportModal);
