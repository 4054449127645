import { isEmpty, some } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import Icon from '../../icon/components/icon';
import InfoTooltip from '../../tooltip/components/info-tooltip';
import '../styles/custom-legend.scss';
import AggregationLabelIcon from './aggregation-label-icon';

const CustomLegend = ({ chart }) => {
  const [seriesGroups, setSeriesGroups] = useState({});

  const refreshSeriesGroups = useCallback(() => {
    const newSeriesGroups = {};

    chart.series?.forEach(series => {
      const groupName = series.userOptions.measurementPointName;
      if (!newSeriesGroups[groupName]) {
        newSeriesGroups[groupName] = [];
      }
      newSeriesGroups[groupName].push(series);
    });

    setSeriesGroups(newSeriesGroups);
  }, [chart]);

  const isLegendItemDisabled = useCallback(
    groupName => {
      return !some(seriesGroups[groupName], s => s.visible);
    },
    [seriesGroups]
  );

  useEffect(() => {
    if (!chart) return;

    refreshSeriesGroups();
  }, [chart, refreshSeriesGroups]);

  if (!chart) {
    return null;
  }

  const tooltipContent = groupName => {
    const content = seriesGroups[groupName].map((series, index) => (
      <div
        key={`${groupName}-${index}`}
        className={`custom-tooltip__series ${!series.visible ? 'disabled' : ''}`}
        onMouseOver={() => handleLegendMouseOver(series)}
        onMouseOut={() => handleLegendMouseOut(series)}
        onClick={() => {
          series.setVisible(!series.visible);
          refreshSeriesGroups();
        }}
      >
        <AggregationLabelIcon aggregationName={series.name} color={series.color} />
        {series.name}
      </div>
    ));
    return content;
  };

  const handleSeriesGroupClick = (e, groupName) => {
    e.stopPropagation();
    const allSeriesDisabled = isLegendItemDisabled(groupName);

    seriesGroups[groupName].forEach(s => {
      s.setVisible(allSeriesDisabled);
    });

    refreshSeriesGroups();
  };

  const handleSeriesGroupMouseOver = (e, groupName) => {
    e.stopPropagation();
    chart.series?.forEach(s => {
      if (seriesGroups[groupName].findIndex(series => s === series) > -1) {
        s.setState('normal');
      } else {
        s.setState('inactive');
      }
    });

    refreshSeriesGroups();
  };

  const handleSeriesGroupMouseOut = e => {
    e.stopPropagation();
    // Reset the state of all series and points
    chart.series?.forEach(s => {
      s.setState('normal');
    });
  };

  const handleLegendMouseOver = series => {
    // Highlight the hovered series and corresponding points
    if (!series.visible) {
      return;
    }
    chart.series?.forEach(s => {
      if (s === series) {
        s.setState('normal');
      } else {
        s.setState('inactive');
      }
    });
  };

  const handleLegendMouseOut = series => {
    // Reset the state of all series and points
    chart.series?.forEach(s => {
      s.setState('normal');
    });
  };

  const renderMultipleGroupsView = () => {
    return Object.keys(seriesGroups).map(groupName => (
      <div key={groupName} className={`custom-legend__item ${isLegendItemDisabled(groupName) ? 'disabled' : ''}`}>
        {seriesGroups[groupName]?.[0]?.color && (
          <div
            style={{
              backgroundColor: seriesGroups[groupName][0].color,
            }}
            className="circle"
          />
        )}
        <p
          onMouseOver={e => handleSeriesGroupMouseOver(e, groupName)}
          onMouseOut={e => handleSeriesGroupMouseOut(e)}
          onClick={e => handleSeriesGroupClick(e, groupName)}
          className={`three-dot-text custom-legend__item-text`}
          title={groupName}
        >
          {groupName}
        </p>
        {seriesGroups[groupName]?.length > 1 && (
          <InfoTooltip
            customComponent={tooltipContent(groupName)}
            offsetY={0}
            offsetX={0}
            Component={() => <Icon name="info" size="sm" />}
            componentProps={{ title: '' }}
            containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
          />
        )}
      </div>
    ));
  };

  const renderSingleGroupView = () => {
    const groupName = Object.keys(seriesGroups)[0];
    if (seriesGroups[groupName].length === 1) {
      return null;
    }

    return seriesGroups[groupName].map(series => (
      <div key={series.name} className={`custom-legend__item ${!series.visible ? 'disabled' : ''}`}>
        <AggregationLabelIcon aggregationName={series.name} color={series.color} />
        <p
          onMouseOver={() => handleLegendMouseOver(series)}
          onMouseOut={() => handleLegendMouseOut(series)}
          onClick={() => {
            series.setVisible(!series.visible);
            refreshSeriesGroups();
          }}
          className={`three-dot-text custom-legend__item-text`}
          title={series.name}
        >
          {series.name}
        </p>
      </div>
    ));
  };

  if (isEmpty(seriesGroups)) {
    return null;
  }

  return (
    <div className="custom-legend">
      <div className="custom-legend__container">{Object.keys(seriesGroups).length === 1 ? renderSingleGroupView() : renderMultipleGroupsView()}</div>
    </div>
  );
};

export default CustomLegend;
