import actionTypes from '../constants/action-types';

export const setSelectedDefectData = data => {
  return {
    type: actionTypes.SET_SELECTED_DEFECT,
    data,
  };
};

export const setSelectedDefectLoader = data => {
  return {
    type: actionTypes.SET_SELECTED_DEFECT_LOADER,
    data,
  };
};

export const setCommentsData = data => {
  return {
    type: actionTypes.SET_COMMENTS_DATA,
    data,
  };
};

export const setDefectsData = data => {
  return {
    type: actionTypes.SET_DEFECTS_DATA,
    data,
  };
};

export const setAllDefectsData = data => {
  return {
    type: actionTypes.SET_ALL_DEFECTS_DATA,
    data,
  };
};

export const setImageDefectsData = data => {
  return {
    type: actionTypes.SET_IMAGE_DEFECTS_DATA,
    data,
  };
};

export const setDefectsLoading = data => {
  return {
    type: actionTypes.SET_DEFECTS_DATA_LODADING,
    data,
  };
};
export const setDrawingsLoading = data => {
  return {
    type: actionTypes.SET_DRAWINGS_DATA_LODADING,
    data,
  };
};

export const updateDefectsData = data => {
  return {
    type: actionTypes.UPDATE_DEFECTS_DATA,
    data,
  };
};

export const setActiveDefectsTab = data => {
  return {
    type: actionTypes.SET_ACTIVE_DEFECTS_TAB,
    data,
  };
};

export const setCurrentImageData = data => {
  return {
    type: actionTypes.SET_CURRENT_IMAGE,
    data,
  };
};

export const setActiveStep = data => {
  return {
    type: actionTypes.SET_ACTIVE_STEP,
    data,
  };
};

export const nextStep = data => {
  return {
    type: actionTypes.NEXT_STEP,
    data,
  };
};

export const previousStep = data => {
  return {
    type: actionTypes.PREVIOUS_STEP,
    data,
  };
};

// DEFECT ACTIONS

export const setDefectsDataTableParams = data => {
  return {
    type: actionTypes.SET_DEFECTS_DATA_TABLE_PARAMS,
    data,
  };
};

export const setNewDefect = data => {
  return {
    type: actionTypes.SET_NEW_DEFECT,
    data,
  };
};

export const selectAllDefects = () => {
  return {
    type: actionTypes.SELECT_ALL_WORKFLOW_DEFECTS,
  };
};

export const deselectAllDefects = () => {
  return {
    type: actionTypes.DESELECT_ALL_WORKFLOW_DEFECTS,
  };
};

export const toggleDefect = data => {
  return {
    data,
    type: actionTypes.TOGGLE_WORKFLOW_DEFECT,
  };
};

export const selectAllDefectsTemp = () => {
  return {
    type: actionTypes.SELECT_ALL_WORKFLOW_DEFECTS_TEMP,
  };
};

export const deselectAllDefectsTemp = () => {
  return {
    type: actionTypes.DESELECT_ALL_WORKFLOW_DEFECTS_TEMP,
  };
};

export const toggleDefectTemp = data => {
  return {
    data,
    type: actionTypes.TOGGLE_WORKFLOW_DEFECT_TEMP,
  };
};

export const setComponentDefectsData = data => {
  return {
    type: actionTypes.SET_COMPONENT_DEFECTS,
    data,
  };
};

export const setLinkedDefectDetails = data => {
  return {
    type: actionTypes.SET_LINKED_DEFECT_DETAILS,
    data,
  };
};

export const handleDeleteImageDefectModal = data => {
  return {
    type: actionTypes.HANDLE_DELETE_IMAGE_DEFECT_MODAL,
    data,
  };
};

export const setActiveCameraHelper = data => {
  return {
    data,
    type: actionTypes.SET_ACTIVE_CAMERA_HELPER,
  };
};

export const selectAllCameras = () => {
  return {
    type: actionTypes.SELECT_ALL_WORKFLOW_CAMERAS,
  };
};

export const deselectAllCameras = () => {
  return {
    type: actionTypes.DESELECT_ALL_WORKFLOW_CAMERAS,
  };
};

export const selectAllCameraPositions = () => {
  return {
    type: actionTypes.SELECT_ALL_WORKFLOW_CAMERAS_POSITIONS,
  };
};

export const deselectAllCameraPositions = () => {
  return {
    type: actionTypes.DESELECT_ALL_WORKFLOW_CAMERAS_POSITIONS,
  };
};

export const selectAllCameraThumbnails = () => {
  return {
    type: actionTypes.SELECT_ALL_WORKFLOW_CAMERA_THUMBNAILS,
  };
};

export const deselectAllCameraThumbnails = () => {
  return {
    type: actionTypes.DESELECT_ALL_WORKFLOW_CAMERA_THUMBNAILS,
  };
};

export const toggleCamera = data => {
  return {
    data,
    type: actionTypes.TOGGLE_WORKFLOW_CAMERA,
  };
};

export const setImageTool = data => {
  return {
    data,
    type: actionTypes.SET_IMAGE_TOOL,
  };
};

export const setImageDrawings = data => {
  return {
    data,
    type: actionTypes.SET_IMAGE_DRAWINGS,
  };
};

export const selectCamera = data => {
  return {
    data,
    type: actionTypes.SELECT_WORKFLOW_CAMERA,
  };
};

export const setImagesCountStatistics = data => {
  return {
    data,
    type: actionTypes.SET_IMAGE_COUNT_STATISTICS,
  };
};
