import navigationActionTypes from '../constants/action-types';

const navState = {
  opened: true,
  scrollPosition: null,
};

export const navReducer = (state = navState, payload) => {
  switch (payload.type) {
    case navigationActionTypes.HANDLE_OPEN_NAV:
      return { ...state, opened: true };
    case navigationActionTypes.HANDLE_CLOSE_NAV:
      return { ...state, opened: false };
    case navigationActionTypes.SET_SCROLL_POSITION:
      return { ...state, scrollPosition: payload.data };
    default:
      return state;
  }
};
