import React, { Component } from 'react';
import PropTypes from 'prop-types';

import EquipmentItem from './equipment-item';

import constants from '../constants/equipment-constants';

import '../styles/equipment.scss';

class Equipment extends Component {
  handleItemClick = item => {
    const { collapseEquipmentItem } = this.props;

    collapseEquipmentItem(item.id);
  };

  handleItemSelect = item => {
    const { selectEquipmentItem } = this.props;

    selectEquipmentItem(item.id);
  };

  renderItems = () => {
    const { equipment } = this.props;
    return equipment.map(item => (
      <EquipmentItem
        level={0}
        key={item[constants.formConstants.fields.id]}
        handleItemClick={this.handleItemClick}
        handleItemSelect={this.handleItemSelect}
        item={{
          ...item,
          id: item[constants.formConstants.fields.id],
          name: item[constants.formConstants.fields.name],
          count: item[constants.formConstants.fields.count],
          children: (item[constants.formConstants.fields.children] || []).map(child => ({
            ...child,
            id: child[constants.formConstants.fields.id],
            name: child[constants.formConstants.fields.name],
            count: child[constants.formConstants.fields.count],
          })),
        }}
      />
    ));
  };

  render() {
    return <div className="equipment">{this.renderItems()}</div>;
  }
}

Equipment.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Equipment;
