import inspectionApi from '../../../api/inspection/actions';
import notificationsApi from '../../../api/notifications/actions';
import readingsAndGaugesAPI from '../../../api/readings-and-gauges/actions';
import {
  amendInspectionComponents,
  amendInspectionDefects,
  amendInspectionMeasurements,
  appendComponent,
  appendComponentTempComment,
  appendDefect,
  appendDefectTempComment,
  appendMeasurement,
  appendMeasurementTempComment,
  appendTempComponent,
  appendTempDefect,
  appendTempInspectPoint,
  appendTempMeasurement,
  handleDeleteDefectModal,
  setComponentDetailsLoading,
  setDefectDetailsLoading,
  setInspectionComponents,
  setInspectionComponentsLoading,
  setInspectionDefects,
  setInspectionDefectsLoading,
  setInspectionMeasurements,
  setInspectionMeasurementsLoading,
  setMeasurementDetailsLoading,
  setNotificationDetailsLoading,
  updateComponentSuccess,
  updateComponentTempSuccess,
  updateDefectSuccess,
  updateDefectTempSuccess,
  updateInspectPointTempSuccess,
  updateMeasurementSuccess,
  updateMeasurementTempSuccess,
  updateNotificationSuccess,
} from '../actions/action-creators';

import { fields as fieldsCH } from '../../../app/component-hierarchy/constants/constants';
import { FORMS } from '../../../common/constants';
import {
  setMeasurementLocationDetailsLoading,
  setMeasurementPointDetailsLoading,
  updateMeasurementLocationInArray,
  updateMeasurementPointInArray,
} from '../components/readings-and-gauges/actions/action-creators';
import { DefaultMeasurementLocation, formConstants as measurementLocationForm } from '../components/readings-and-gauges/constants/measurement-location-constants';
import { DefaultMeasurementPoint, formConstants as measurementPointForm } from '../components/readings-and-gauges/constants/measurement-point-constants';
import { formConstants as fieldsComponentDetails } from '../constants/component-constants';
import { imagesCameraPositionDefaults } from '../constants/constants';
import { getImagesForSelectedPoint } from '../potree/actions/actions';
import { DefaultComponent, formConstants as componentForm } from './component-constants';
import { measurementTypes } from './constants';
import { DefaultDefect, DefaultInspectPoint, formConstants as defectForm } from './defect-constants';
import { DefaultNotification, formConstants as notificationForm } from './notification-constants';

export default {
  [measurementTypes.defect]: {
    dispatchActions: {
      setInspectionElements: setInspectionDefects,
      amendInspectionElements: amendInspectionDefects,
      setElementsLoading: setInspectionDefectsLoading,
      appendElement: appendDefect,
      appendElementTemp: appendTempDefect,
      updateElementSuccess: updateDefectSuccess,
      updateElementTempSuccess: updateDefectTempSuccess,
      appendElementTempComment: appendDefectTempComment,
      handleDeleteElementModal: handleDeleteDefectModal,
      handleElementDetailsLoading: setDefectDetailsLoading,
    },
    requestActions: {
      getElementDetails: id => inspectionApi.getDefectDetails([{ defect_id: id }]),
      createElement: inspectionApi.createDefect,
      updateElement: inspectionApi.updateDefect,
      deleteElement: inspectionApi.deleteDefect,
      getInspectionElements: args => inspectionApi.getInspectionDefects({ ...args, SystemType: measurementTypes.defect }),
      getInspectionElementsLite: args => inspectionApi.getInspectionDefectsLite({ ...args, SystemType: measurementTypes.defect }),
      updateElementGeometry: data => inspectionApi.updateDefectGeometry({ ID: data.ID, Geometry: data.Geometry, CameraPosition: data.CameraPosition }),
      getElementComments: () => null,
      addComment: (message, id) => inspectionApi.addDefectComment({ Message: message, DefectID: id }),
    },
    defaultElement: camPos => DefaultDefect(camPos),
    FORM: {
      name: FORMS.defect,
      fields: {
        points: defectForm.fields.points,
        camPosition: defectForm.fields.camPosition,
      },
    },
  },
  [measurementTypes.measurement]: {
    dispatchActions: {
      setInspectionElements: setInspectionMeasurements,
      amendInspectionElements: amendInspectionMeasurements,
      setElementsLoading: setInspectionMeasurementsLoading,
      appendElement: appendMeasurement,
      appendElementTemp: appendTempMeasurement,
      updateElementSuccess: updateMeasurementSuccess,
      updateElementTempSuccess: updateMeasurementTempSuccess,
      appendElementTempComment: appendMeasurementTempComment,
      handleDeleteElementModal: handleDeleteDefectModal,
      handleElementDetailsLoading: setMeasurementDetailsLoading,
    },
    requestActions: {
      getElementDetails: id => inspectionApi.getMeasurementDetails([{ measurement_id: id }]),
      createElement: inspectionApi.createMeasurement,
      updateElement: inspectionApi.updateMeasurement,
      deleteElement: inspectionApi.deleteMeasurement,
      getInspectionElements: args => inspectionApi.getInspectionMeasurements({ ...args, SystemType: measurementTypes.measurement }),
      getInspectionElementsLite: args => inspectionApi.getInspectionMeasurements({ ...args, SystemType: measurementTypes.measurement }),
      updateElementGeometry: data => inspectionApi.updateMeasurementGeometry({ ID: data.ID, Geometry: data.Geometry }),
      getElementComments: id => inspectionApi.getMeasurementComments([{ measurement_id: id }]),
      addComment: (message, id) => inspectionApi.addMeasurementComment({ Message: message, MeasurementID: id }),
    },
    defaultElement: camPos => DefaultDefect(camPos),
    FORM: {
      name: FORMS.measurementForm,
      fields: {
        points: defectForm.fields.points,
        camPosition: defectForm.fields.camPosition,
      },
    },
  },
  [measurementTypes.component]: {
    dispatchActions: {
      setInspectionElements: setInspectionComponents,
      amendInspectionElements: amendInspectionComponents,
      setElementsLoading: setInspectionComponentsLoading,
      appendElement: appendComponent,
      appendElementTemp: appendTempComponent,
      updateElementSuccess: (data, method) => updateComponentSuccess({ ...data, [fieldsCH.parentAsset]: data?.[fieldsComponentDetails.fields.asset] }, method),
      updateElementTempSuccess: updateComponentTempSuccess,
      appendElementTempComment: appendComponentTempComment,
      handleDeleteElementModal: handleDeleteDefectModal,
      handleElementDetailsLoading: setComponentDetailsLoading,
    },
    requestActions: {
      getElementDetails: id => inspectionApi.getComponentDetails([{ component_id: id }]),
      createElement: inspectionApi.createComponent,
      updateElement: inspectionApi.updateComponent,
      deleteElement: inspectionApi.deleteComponent,
      getInspectionElements: inspectionApi.getInspectionComponents,
      getInspectionHierarchyElements: inspectionApi.getInspectionHierarchyComponents,
      getInspectionHierarchyUnassignedElements: inspectionApi.getInspectionHierarchyUnassignedComponents,
      getInspectionElementsLite: inspectionApi.getInspectionComponentsLite,
      updateElementGeometry: data => inspectionApi.updateComponentGeometry({ ID: data.ID, Geometry: data.Geometry, CameraPosition: data.CameraPosition }),
      getElementComments: () => null,
      addComment: () => null,
    },
    defaultElement: camPos => DefaultComponent(camPos),
    FORM: {
      name: FORMS.componentForm,
      fields: {
        points: componentForm.fields.points,
        camPosition: componentForm.fields.camPosition,
      },
    },
  },
  [measurementTypes.inspect]: {
    dispatchActions: {
      setInspectionElements: () => null,
      amendInspectionElements: () => null,
      appendElement: () => null,
      setElementsLoading: () => null,
      appendElementTemp: data => {
        return (dispatch, getState) => {
          const {
            inspectionReducer: { inspectionID, projectID },
          } = getState();

          dispatch(appendTempInspectPoint(data));
          const { Type, Distance = imagesCameraPositionDefaults[Type] } = data;
          const PointPosition = { X: data.Geometry.coordinates[0][0], Y: data.Geometry.coordinates[0][1], Z: data.Geometry.coordinates[0][2] };

          dispatch(getImagesForSelectedPoint(inspectionID, projectID, { PointPosition, Type, Distance }, null, null, false, null, null));
        };
      },
      updateElementSuccess: () => null,
      updateElementTempSuccess: updateInspectPointTempSuccess,
      appendElementTempComment: () => null,
      handleDeleteElementModal: () => null,
    },
    requestActions: {
      getElementDetails: () => null,
      createElement: () => null,
      updateElement: () => null,
      deleteElement: () => null,
      getInspectionElements: () => null,
      getInspectionElementsLite: () => null,
      updateElementGeometry: () => null,
      getElementComments: id => null,
      addComment: (message, id) => null,
    },
    defaultElement: camPos => DefaultInspectPoint(camPos),
  },
  [measurementTypes.notification]: {
    dispatchActions: {
      updateElementSuccess: updateNotificationSuccess,
      handleElementDetailsLoading: setNotificationDetailsLoading,
    },
    requestActions: {
      getElementDetails: ID => notificationsApi.getNotificationDetails([{ notification_id: ID }]),
      updateElementGeometry: data => inspectionApi.updateNotificationGeometry({ NotificationID: data.ID, Geometry: data.Geometry, CameraPosition: data.CameraPosition }),
    },
    defaultElement: camPos => DefaultNotification(camPos),
    FORM: {
      name: FORMS.notificationForm,
      fields: {
        points: notificationForm.fields.points,
        camPosition: notificationForm.fields.camPosition,
      },
    },
  },
  [measurementTypes.rgMeasurementLocation]: {
    dispatchActions: {
      updateElementSuccess: updateMeasurementLocationInArray,
      handleElementDetailsLoading: setMeasurementLocationDetailsLoading,
    },
    requestActions: {
      getElementDetails: ID => readingsAndGaugesAPI.getMeasurementLocationDetails([{ measurement_location_id: ID }]),
      updateElementGeometry: data => readingsAndGaugesAPI.updateMeasurementLocationGeometry({ MeasurementLocationID: data.ID, Geometry: data.Geometry, CameraPosition: data.CameraPosition }),
    },
    defaultElement: camPos => DefaultMeasurementLocation(camPos),
    FORM: {
      name: FORMS.measurementLocationForm,
      fields: {
        points: measurementLocationForm.fields.points,
        camPosition: measurementLocationForm.fields.camPosition,
      },
    },
  },
  [measurementTypes.rgMeasurementPoint]: {
    dispatchActions: {
      updateElementSuccess: updateMeasurementPointInArray,
      handleElementDetailsLoading: setMeasurementPointDetailsLoading,
    },
    requestActions: {
      getElementDetails: ID => readingsAndGaugesAPI.getMeasurementPointDetails([{ measurement_point_id: ID }]),
      updateElementGeometry: data => readingsAndGaugesAPI.updateMeasurementPointGeometry({ MeasurementPointID: data.ID, Geometry: data.Geometry, CameraPosition: data.CameraPosition }),
    },
    defaultElement: camPos => DefaultMeasurementPoint(camPos),
    FORM: {
      name: FORMS.measurementPointForm,
      fields: {
        points: measurementPointForm.fields.points,
        camPosition: measurementPointForm.fields.camPosition,
      },
    },
  },
};
