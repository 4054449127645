import actionTypes from '../constants/action-types';
import { fields } from '../constants/constants';

const dmsVersionHistoryState = {
  versionHistory: [],
  versionHistoryLoading: false,
};

export const dmsVersionHistoryReducer = (state = dmsVersionHistoryState, payload) => {
  switch (payload.type) {
    case actionTypes.SET_VERSION_HISTORY:
      return {
        ...state,
        versionHistory: payload.data,
      };
    case actionTypes.SET_VERSION_HISTORY_LOADING:
      return {
        ...state,
        versionHistoryLoading: payload.data,
      };
    case actionTypes.ADD_VERSION_HISTORY_ITEM:
      return {
        ...state,
        versionHistory: [...state.versionHistory, payload.data],
      };
    case actionTypes.SET_NEW_ACTIVE_VERSION:
      const versionHistoryWithNewActiveItem = (state.versionHistory || []).map(version => {
        return {
          ...version,
          [fields.isActive]: payload.data === version[fields.id],
        };
      });
      return {
        ...state,
        versionHistory: versionHistoryWithNewActiveItem,
      };
    case actionTypes.UPDATE_VERSION_DETAILS:
      return {
        ...state,
        versionHistory: (state.versionHistory || []).map(version => {
          if (version[fields.id] === payload.data[fields.versionID]) {
            return {
              ...version,
              ...payload.data,
            };
          } else return version;
        }),
      };
    default:
      return state;
  }
};
