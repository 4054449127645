import React from 'react';
import Helpers from '../../../../common/helpers';
import { formConstants, formattedIntervals } from '../../constants/regime-constants';
import PropTypes from 'prop-types';
import '../../../inspections/styles/work-order-section-components.scss';

const MaintenanceRegimeSectionScheduler = ({ module }, { t }) => {
  const intervalLabelFormatter = interval => {
    if (!interval) return;
    return formattedIntervals[interval.toLowerCase()];
  };

  return (
    <div className="maintenance-regime-section-scheduler">
      {module && (
        <>
          <div className="scheduler-row">
            <p className="f-secondary-dark">{t('REGIME_TABLE.START_DATE')}</p>
            <p className="f-primary-dark">{Helpers.getDateFromUnix(module[formConstants.fields.startDate])}</p>
          </div>
          {module[formConstants.fields.intervalNumber] && (
            <div className="scheduler-row">
              <p className="f-secondary-dark">{t('REGIME_TABLE.INTERVAL')}</p>
              <p className="f-primary-dark">{intervalLabelFormatter(module[formConstants.fields.interval])}</p>
            </div>
          )}
          {module[formConstants.fields.nextMaintenanceTaskStart] && (
            <div className="scheduler-row">
              <p className="f-secondary-dark">{t('REGIME_TABLE.NEXT_TASK')}</p>
              <p className="f-primary-dark">{Helpers.getDateFromUnix(module[formConstants.fields.nextMaintenanceTaskStart], 'MMMM Do YYYY')}</p>
            </div>
          )}
          {((module[formConstants.fields.recurringTask] && module[formConstants.fields.occurancesTreshold]) || module[formConstants.fields.endDate]) && (
            <div className="scheduler-row">
              <p className="f-secondary-dark">{t('REGIME_TABLE.ENDS')}</p>
              <p className="f-primary-dark">
                {module[formConstants.fields.endDate]
                  ? Helpers.getDateFromUnix(module[formConstants.fields.endDate])
                  : t('REGIME_TABLE.AFTER_OCCURRENCES', { number: module[formConstants.fields.occurancesTreshold] })}
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

MaintenanceRegimeSectionScheduler.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default MaintenanceRegimeSectionScheduler;
