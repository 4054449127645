import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';

import Icon from '../../icon/components/icon';
import ObjectDropdown from './object-dropdown';

import '../styles/dropdown-tool.scss';

class ToolRenderer extends Component {
  constructor(props) {
    super(props);
    this.node = createRef();
  }

  render() {
    const { t } = this.context;
    const { disabled, toolsMenu, handleClick, title, active, icon: MenuIcon, hide, dropdownOffsetY, dropdownOffsetX } = this.props;

    if (hide) {
      return null;
    }

    return (
      <div className={`${active ? 'active-tool' : ''} dropdown-tool`} style={{ position: 'relative' }}>
        <div ref={this.node} className={`tool ${disabled ? 'disabled' : ''}`}>
          {MenuIcon && <MenuIcon active={active} size="sm" handleHover={!disabled} title={title ? t(title) : ''} onClick={!toolsMenu ? handleClick : null} />}

          {toolsMenu && <Icon rotation={0} name="arrow90" handleHover={false} className="arrow" size="xs" />}
        </div>
        {toolsMenu && (
          <ObjectDropdown
            isDisabled={disabled}
            refNode={this.node}
            actionsMenu={toolsMenu}
            getClickAction={action => handleClick(action)}
            placement={'left'}
            offsetY={dropdownOffsetY}
            offsetX={dropdownOffsetX || '-10px'}
          />
        )}
      </div>
    );
  }
}

ToolRenderer.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ToolRenderer;
