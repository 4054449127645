import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import EmptyState from '../../../../common/empty-state/components/empty-state';
import HistoryItem from '../../../../common/history-item/components/history-item';
import LoadMore from '../../../../common/load-more/components/load-more';
import ToolbarTitle from '../../../../common/toolbar-title/components/toolbar-title';
import RenderIf from '../../../../common/render-if/components/render-if';

import { getHistoryLength } from '../../helpers/inspection-helper';
import { sortingDirection, historyDefaultPagingObj, historyFilterParams, historyItems, areaModificationEvents } from '../../constants/areas-constants';

import { getAreaItemHistory } from '../../actions/area-actions';

import '../../styles/module-item-history.scss';

class AreaHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      history: [],
      paging: historyDefaultPagingObj,
    };
  }

  fetchHistory = () => {
    this.props.getAreaItemHistory(
      {
        ItemId: this.props.areaID,
        LastSeen: this.state.paging[historyFilterParams.lastSeen],
        PerPage: this.state.paging[historyFilterParams.perPage],
        SortByColumn: historyFilterParams.sortByColumn,
        SortDirection: sortingDirection.desc,
      },
      this.state.history,
      this.state.paging,
      data => this.setState(data)
    );
  };

  componentDidMount = () => {
    this.fetchHistory();
  };

  render() {
    const historyLength = getHistoryLength(this.state.history, areaModificationEvents, historyItems, areaModificationEvents.area_created.event, areaModificationEvents.area_deleted.event);
    const areHistoryItemsEmpty = !this.state.isLoading && (!this.state.history.length || !historyLength);

    return (
      <div className="module-item-history">
        <ToolbarTitle variant="medium">{'AREA_HISTORY.TITLE'}</ToolbarTitle>
        <div className="module-item-history__wrapper">
          <RenderIf if={areHistoryItemsEmpty}>
            <EmptyState title="AREA_HISTORY.EMPTY_STATE_TITLE" message="AREA_HISTORY.EMPTY_STATE_MESSAGE" />
          </RenderIf>
          <RenderIf if={!areHistoryItemsEmpty}>
            <>
              <div className={this.state.isLoading ? 'background-blurred' : ''}>
                {this.state.history.map(item => (
                  <HistoryItem
                    key={item.id}
                    items={historyItems}
                    item={item}
                    moduleName="AREA_HISTORY.MODULE_NAME"
                    events={areaModificationEvents}
                    moduleCreateEvent={areaModificationEvents.area_created.event}
                    moduleDeleteEvent={areaModificationEvents.area_deleted.event}
                  />
                ))}
              </div>
              <LoadMore
                disabled={!this.state.paging[historyFilterParams.hasNext] || this.state.isLoading}
                loaded={historyLength}
                total={this.state.paging[historyFilterParams.totalItems]}
                totalPosition="center"
                isLoading={this.state.isLoading}
                label="COMPONENT_HISTORY.LOAD_MORE"
                isFixed
                onClick={this.fetchHistory}
              />
            </>
          </RenderIf>
        </div>
      </div>
    );
  }
}

AreaHistory.contextTypes = {
  t: PropTypes.func.isRequired,
};

AreaHistory.propTypes = {
  areaID: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  getAreaItemHistory: (params, history, paging, callback) => dispatch(getAreaItemHistory(params, history, paging, callback)),
});

AreaHistory = connect(mapStateToProps, mapDispatchToProps)(AreaHistory);

export default AreaHistory;
