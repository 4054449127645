import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Field, reduxForm } from 'redux-form';

import { FORMS } from '../../../common/constants';

import { validate } from '../validators/register-validators';

import registerConstants from '../constants/register-constants';
import routesConstants from '../../../common/routes-constants';
import Helpers from '../../../common/helpers';

import CustomInput from '../../../common/form/components/input';
import Button from '../../../common/form/components/button';
import { ReactComponent as Logo } from '../../../common/assets/logo.svg';

import '../styles/register-form.scss';

class RegisterForm extends Component {
  clearErrors = () => {
    const { clearErrors } = this.props;
    clearErrors();
  };

  render() {
    const { t } = this.context;
    const { handleSubmit, invalid, anyTouched } = this.props;
    return (
      <form className="register-form" onSubmit={handleSubmit} noValidate>
        <div className="logo">
          <Logo />
        </div>
        <h4> {t('REGISTER.TITLE')}</h4>
        <Field
          id={registerConstants.fields.companyName}
          name={registerConstants.fields.companyName}
          label="REGISTER.COMPANY_NAME_LABEL"
          labelClass="f-secondary-dark"
          component={CustomInput}
          type="text"
          placeholder="REGISTER.COMPANY_NAME"
        />
        <Field
          id={registerConstants.fields.name}
          name={registerConstants.fields.name}
          label="REGISTER.NAME_LABEL"
          labelClass="f-secondary-dark"
          component={CustomInput}
          type="text"
          placeholder="REGISTER.NAME"
        />
        <Field
          id={registerConstants.fields.email}
          name={registerConstants.fields.email}
          label="REGISTER.EMAIL_LABEL"
          labelClass="f-secondary-dark"
          component={CustomInput}
          type="text"
          placeholder="REGISTER.EMAIL"
        />
        <Field
          id={registerConstants.fields.phone}
          name={registerConstants.fields.phone}
          label="REGISTER.PHONE"
          labelClass="f-secondary-dark"
          component={CustomInput}
          type="text"
          placeholder="REGISTER.PHONE"
        />
        <Button disabled={invalid && anyTouched} text={t('REGISTER')} />
        <label onClick={() => Helpers.goTo(routesConstants.routes.unProtectedRoutes.login.fullPath)} className="login-form__link f-secondary-dark noselect space">
          {t('REGISTER.BACK_TO_LOGIN')}
        </label>
      </form>
    );
  }
}

RegisterForm.contextTypes = {
  t: PropTypes.func.isRequired,
};
RegisterForm = reduxForm({
  form: FORMS.register,
  validate,
})(RegisterForm);

export default RegisterForm;
