import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { FORMS } from '../../../common/constants';

import { validate } from '../validators/password-validator';

import { passwordVerificationError } from '../actions/action-creators';

import passwordConstants from '../constants/password-constants';

import CustomInput from '../../../common/form/components/input';
import RenderIf from '../../../common/render-if/components/render-if';
import Button from '../../form/components/button';

import '../styles/password-form.scss';

class PasswordForm extends Component {
  componentDidMount() {
    this.clearErrors();
  }

  clearErrors = () => {
    const { clearErrors } = this.props;
    clearErrors();
  };

  render() {
    const { t } = this.context;
    const { handleSubmit, invalid, passwordError, closeAction, additionalText = () => null } = this.props;
    return (
      <form className="password-form" onSubmit={handleSubmit} noValidate>
        {additionalText && <p className="f-primary explanation">{additionalText}</p>}
        <Field
          id={passwordConstants.fields.password}
          name={passwordConstants.fields.password}
          component={CustomInput}
          type="password"
          placeholder="LOGIN.PASSWORD_FIELD"
          label={t('LOGIN.PASSWORD_FIELD')}
          labelClass="f-secondary-dark"
          onChange={() => this.clearErrors()}
        />
        <div className="error-placeholder">
          <RenderIf if={passwordError}>{t(passwordError || '')}</RenderIf>
        </div>

        <Button onClick={closeAction} type="button" text={t('NO')} variant="gray-outline" />
        <Button text={t('YES')} className="modal__button" disabled={invalid} />
      </form>
    );
  }
}

PasswordForm.contextTypes = {
  t: PropTypes.func.isRequired,
};
PasswordForm = reduxForm({
  form: FORMS.passwordVerification,
  enableReinitialize: true,
  validate,
})(PasswordForm);

const mapStateToProps = state => ({
  passwordError: state.errorModalReducer.passwordFormError,
});
const mapDispatchToProps = dispatch => ({ clearErrors: () => dispatch(passwordVerificationError(null)) });

export default connect(mapStateToProps, mapDispatchToProps)(PasswordForm);
