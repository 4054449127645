import { isArray, isEmpty, pick } from 'lodash';
import { severityDefault } from '../../../constants/defect-constants';
import { filterProps } from '../constants/constants';

// TODO: reuse this into single helper file for all tabs when refactoring of component-tab-wrapper begins @deniz
export const deleteAdvancedFiltersFromFilters = filters => {
  filters[filterProps.dateFrom] = null;
  filters[filterProps.dateTo] = null;
  filters[filterProps.typeFilter] = [];
  filters[filterProps.createdByFilter] = [];
  filters[filterProps.sourceFilter] = '';
  filters[filterProps.componentsSearchFilter] = '';
  filters[filterProps.severityFilter] = [severityDefault.filter.min, severityDefault.filter.max];
  filters[filterProps.createdInLastXDaysFilter] = 0;
  filters[filterProps.has3DLocationFilter] = null;
  filters[filterProps.hasLinkedEquipment] = null;
  filters[filterProps.properties] = [];
  filters[filterProps.hasFiles] = null;
  filters[filterProps.hasWorkOrdersFilter] = null;

  return filters;
};
/**
 * getNotificationsActiveAdvancedFilters - is function which picks and returns the active filters for notifications
 * It checks if the values are not empty for the specific key and adds them to object which will be returned
 * @param {Object} filters - whole filters object is passed so that Advanced filters can be picked and filtered through
 * @returns {Object} - can be empty object or object that contains active advanced filters
 */
export const getNotificationsActiveAdvancedFilters = filters => {
  const advancedFilters = pick(filters, [
    [filterProps.dateFrom],
    [filterProps.dateTo],
    [filterProps.typeFilter],
    [filterProps.createdByFilter],
    [filterProps.properties],
    [filterProps.severityFilter],
    [filterProps.componentsSearchFilter],
    [filterProps.sourceFilter],
    [filterProps.createdInLastXDaysFilter],
    [filterProps.has3DLocationFilter],
    [filterProps.hasFiles],
    [filterProps.hasLinkedEquipment],
    [filterProps.hasWorkOrdersFilter],
  ]);
  const clearedAdvancedFilters = {};
  Object.keys(advancedFilters).forEach(key => {
    if (!isEmpty(advancedFilters[key])) {
      clearedAdvancedFilters[key] = advancedFilters[key];
    }
  });

  return clearedAdvancedFilters;
};

export const getSelectedStatuses = filters => {
  let result;
  if (isArray(filters[filterProps.statusFilter])) {
    result = !isEmpty(filters[filterProps.statusFilter]) ? filters[filterProps.statusFilter].toString().split(',') : [];
  } else result = filters[filterProps.statusFilter] ? filters[filterProps.statusFilter].split(',') : [];

  if (filters[filterProps.lastSevenDays]) {
    result.push(filterProps.lastSevenDays);
  }

  if (filters[filterProps.lastThirtyDays]) {
    result.push(filterProps.lastThirtyDays);
  }

  return result;
};
