const fields = {
  manufacturer: 'Manufacturer',
  assetType: 'AssetType',
  componentType: 'ComponentType',
  defectType: 'DefectType',
  severity: 'Severity',
  inspectionSummary: 'InspectionSummary',
  sortBy: 'SortBy',
  description: 'InspectionSummary',
  recomendations: 'Recomendations',
  imagesPerPage: 'AdditionalImages',
  includeComponentDetails: 'IncludeComponentDetails',
  includeDefectSummary: 'IncludeDefectSummary',
  includeRecommendationSummary: 'IncludeRecommendedSummary',
  coverPDF: 'CoverPDF',
  emailList: 'EmailList',
};

const steps = {
  firstStep: 1,
  secondStep: 2,
};

const stepperData = [
  {
    name: 'DOWNLOAD_REPORT.STEP_3',
    stepValue: steps.firstStep,
  },
  {
    name: 'DOWNLOAD_REPORT.STEP_6',
    stepValue: steps.secondStep,
  },
];

const imagesPerPageValues = {
  rightValue: 6,
  leftValue: 2,
};

const potreeId = 'download_report_potree_3';

const mapId = 'download_report_map_3';

export default {
  fields,
  steps,
  stepperData,
  imagesPerPageValues,
  potreeId,
  mapId,
};
