import PropTypes from 'prop-types';
import React from 'react';
import Helpers from '../../../../common/helpers';
import RenderIf from '../../../../common/render-if/components/render-if';

import { sortBy, uniqBy } from 'lodash';
import TableTemplate from '../../../../common/double-table/components/table-template';
import Icon from '../../../../common/icon/components/icon';
import InfoTooltip from '../../../../common/tooltip/components/info-tooltip';
import { componentFields, componentsTableConfig, fields } from '../../../inspections/components/maintenance-regimes/constants/constants';
import { componentDetailsDisplayProps } from '../../../inspections/components/work-order/constants/constants';

import EmptyState from '../../../../common/empty-state-v2/components/empty-state';
import { PERMISSION_TYPES, PERMISSIONS } from '../../../../common/permissions-constants';
import '../../../inspections/styles/work-order-section-components.scss';
import { formConstants } from '../../constants/regime-constants';

const MaintenanceRegimeSectionComponents = ({ module, disabled, handleCustomAction, sectionAction, initialValues, user, editPermission, ...rest }, { t }) => {
  const isCreatedByUser = user[formConstants.fields.UserID] === module[formConstants.fields.createdByUser];
  const hasAccess = Helpers.hasAccess({ user: user, visibleFor: PERMISSIONS[PERMISSION_TYPES.maintenanceRegimes].create.name });
  const showButton = isCreatedByUser ? isCreatedByUser && hasAccess : !disabled;

  return (
    <div className="work-order-section-components">
      <RenderIf if={module && module.maintenanceRegimeComponents?.length > 0}>
        <div className="notification-WO-tab__table">
          <TableTemplate
            data={uniqBy(sortBy(module.maintenanceRegimeComponents, [fields.componentID]), fields.id)}
            handleFormatCells={(value, type, index, item) => {
              if (type === fields.name) {
                return (
                  <div className="checklist-name-container" key={`work-order-component-${item[formConstants.fields.id]}`}>
                    <p className="f-primary light-bold ">{item[formConstants.fields.title]}</p>
                    <InfoTooltip
                      actionsMenu={Helpers.mapInfoIconDisplayProps(item, componentDetailsDisplayProps)}
                      offsetY={-100}
                      offsetX={16}
                      Component={() => <Icon name="info" className="info-tooltip-icon" size="sm" />}
                      componentProps={{ title: '' }}
                    />
                  </div>
                );
              } else if (type === componentFields.critical) {
                return (
                  <>
                    {value === true ? (
                      <div className="linked-to-eq-container">
                        <InfoTooltip
                          className="critical-tooltip"
                          offsetY={6}
                          offsetX={6}
                          customComponent={<span className="f-primary">Critical Equipment</span>}
                          Component={() => <Icon name="critical-hazard" handleHover={false} size="sm" />}
                          componentProps={{ title: '' }}
                          containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
                        />
                      </div>
                    ) : null}
                  </>
                );
              } else if (!value) {
                return value ? value : '-';
              }
              return Helpers.formatCell(value, type, index, item);
            }}
            showSearchBar={false}
            tableConfig={componentsTableConfig}
            showInfo={false}
            onRowClick={row => null}
            noDataText={'WORK_ORDERS.NO_COMPONENTS'}
          />
        </div>
      </RenderIf>
      <RenderIf if={module && module.maintenanceRegimeComponents?.length === 0}>
        <EmptyState
          buttonAction={() => handleCustomAction(sectionAction, module, initialValues)}
          buttonText={t('WORK_ORDER.ADD_COMPONENTS')}
          transparent
          showButton={showButton}
          emptyStateText={t('NO_EQUIPMENT_EMPTY_STATE')}
        />
      </RenderIf>
    </div>
  );
};

MaintenanceRegimeSectionComponents.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default MaintenanceRegimeSectionComponents;
