import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { find } from 'lodash';

import ItemsTableRenderer from '../../../../../common/items-renderer/components/items-table-renderer';
import SearchInput from '../../../../../common/input/components/search-input';
import Icon from '../../../../../common/icon/components/icon';

import Helpers from '../../../../../common/helpers';
import { tableConfig, filterProps, defaultFilters, fields, initialValuesProp, columnTypes, singleKeyFields } from '../constants/keybox-constants';
import { getPermitKeyboxes } from '../../../actions/permit-actions';
import { statusTypes } from '../../../../keybox/constants/constants';

import '../../../styles/keybox-modal.scss';

class PermitKeyboxModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyboxes: [],
      filters: { ...defaultFilters, PermitID: props.module['ID'] },
    };
  }

  componentDidMount() {
    const { filters } = this.state;
    this.fetchKeyboxes(filters);
  }

  fetchKeyboxes = (params, loadMore = false) => {
    const currentKeyboxes = module[initialValuesProp];
    const { getPermitKeyboxes } = this.props;
    params[filterProps.lastSeen] = loadMore ? params[filterProps.lastSeen] : defaultFilters[filterProps.lastSeen];

    getPermitKeyboxes(params, currentKeyboxes, loadMore, ({ filters, keyboxes }) => this.setState({ filters, keyboxes }));
  };

  handleSearch = SearchText => {
    const { filters } = this.state;
    const newParams = {
      ...filters,
      [filterProps.lastSeen]: defaultFilters[filterProps.lastSeen],
      SearchText,
    };
    this.fetchKeyboxes(newParams);
  };

  getKeyBoxStatus = status => {
    const { t } = this.context;

    switch (status) {
      case statusTypes.inUse:
        return (
          <div className="key-box-status">
            <p className="f-secondary-dark">{t('KEYBOX.IN_USE')}</p>
          </div>
        );
      case statusTypes.locked:
        return (
          <div className="key-box-status">
            <Icon name="lock" className="locked-icon" />
            <p className="f-secondary-dark">{t('KEYBOX.LOCKED')}</p>
          </div>
        );
      default:
        return (
          <div className="key-box-status">
            <p className="f-secondary-dark">{t('KEYBOX.NOT_IN_USE')}</p>
          </div>
        );
    }
  };

  render() {
    const { t } = this.context;
    const { addKeybox, removeKeybox, module } = this.props;
    const { keyboxes, filters } = this.state;
    const addedKeyBoxes = module[initialValuesProp] || [];

    return (
      <div className="keybox-modal">
        <SearchInput
          onChange={e => this.handleSearch(e.target.value)}
          placeholder={t('PROJECT.SEARCH.PLACEHOLDER')}
          label={'PERMIT_KEYBOX.SEARCH.LABEL'}
          includeDropdown
          items={keyboxes}
          emptyStateLabel={'PERMIT.ISOLATION_CERTIFICATE_MODAL.EMPTY_STATE_SEARCH'}
          renderItem={(item, index) => {
            const isDisabled = find(addedKeyBoxes, i => item[fields.id.name] === i[fields.id.name]);
            return (
              <div className={`key-boxes-select-item pointer ${isDisabled ? 'disabled' : ''}`} onClick={isDisabled ? () => null : () => addKeybox(module, item)}>
                <p className="f-primary" key={index}>
                  {item[fields.name.name]}
                </p>
                {this.getKeyBoxStatus(item[fields.status.name])}
              </div>
            );
          }}
        />
        <p className="f-secondary-dark">{t('ISOLATION_CERTIFICATE.ADD_COMPONENTS_TITLE', { number: addedKeyBoxes.length })}</p>
        <div className="table-container">
          <ItemsTableRenderer
            isLoading={false}
            tableConfig={[
              ...tableConfig,
              {
                title: '',
                CustomComponent: (data, itemIndex) =>
                  data[fields.editable.name] && (
                    <div className="component-list__table-actions">
                      <Icon name="close" onClick={() => removeKeybox(module, data)} />
                    </div>
                  ),
                enableSort: false,
              },
            ]}
            translationModule={t}
            data={addedKeyBoxes}
            onRowClick={() => null}
            sortingObj={{
              [filterProps.sortByColumn]: filters[filterProps.sortByColumn],
              [filterProps.sortDirection]: filters[filterProps.sortDirection],
            }}
            formatCell={(value, type, index, item) => {
              if (type === columnTypes.source) {
                return item[fields.editable.name] ? t('PERMIT_KEYBOX.SOURCE.PERMIT') : t('PERMIT_KEYBOX.SOURCE.ISOLATION_CERTIFICATE');
              } else if (type === columnTypes.issuedKeys) {
                const issuedKeys = (item[fields.keys.name] || []).filter(key => key[singleKeyFields.issuedToId]);
                return issuedKeys.map(key => key[singleKeyFields.name]).join(', ') || '-';
              }

              return Helpers.formatCell(value, type, index, item);
            }}
            sortByColumnProp={filterProps.sortByColumn}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  getPermitKeyboxes: (params, keyboxes, loadMore, callback) => dispatch(getPermitKeyboxes(params, keyboxes, loadMore, callback)),
});

PermitKeyboxModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

PermitKeyboxModal = connect(mapStateToProps, mapDispatchToProps)(PermitKeyboxModal);

export default PermitKeyboxModal;
