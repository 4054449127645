import { findIndex, isArray } from 'lodash';

import {
  addComponentAtIndex,
  addRegimeAtIndex,
  amendRegimeList,
  removeRegimeByProp,
  removeRegimeComponentByProp,
  removeRegimeFileByGroup,
  setIntervalList,
  setRegimeChecklists,
  setRegimeComponents,
  setRegimeDetailsLoading,
  setRegimeFiles,
  setRegimeList,
  setRegimeTasks,
  setSelectedRegime,
  updateRegimeItemByProp,
  updateSelectedRegime,
} from './action-creators';

import { measurementTypes, moduleSectionsFields } from '../../inspections/constants/constants';

import ElementActions from '../../inspections/constants/element-actions';

import regimeActions from '../../../api/regime/actions';

import Helpers from '../../../common/helpers';
import ReducerHelpers from '../../../common/reducer-helpers';
import { amendRegimeWorkOrders, setRegimeWorkOrderFilters, setRegimeWorkOrdersLoading } from '../../inspections/actions/action-creators';
import { componentFields, endCheckBoxValues, fileGroups, formConstants, regimeStatus } from '../constants/regime-constants';

export const getRegimes = (params, successAction = () => null, loadMore) => {
  return async dispatch => {
    try {
      const res = await regimeActions.getRegimes(params);
      const { MaintenanceRegimes, ...filterParams } = res.data.Data;
      if (loadMore) {
        dispatch(amendRegimeList(MaintenanceRegimes));
      } else {
        dispatch(setRegimeList(MaintenanceRegimes));
      }
      successAction && successAction(filterParams);
    } catch (error) {}
  };
};

export const getRegimeDetails = (maintenance_regime_id, successCallback) => {
  return async dispatch => {
    try {
      dispatch(setRegimeDetailsLoading(true));
      const res = await regimeActions.getRegimeDetails([{ maintenance_regime_id }]);
      const { Data } = res.data;
      Data[formConstants.fields.ends] = Data[formConstants.fields.endDate] ? endCheckBoxValues.on : Data[formConstants.fields.occurancesTreshold] ? endCheckBoxValues.off : null;
      Data[formConstants.fields.recurringTask] = Data[formConstants.fields.endDate] || Data[formConstants.fields.occurancesTreshold] ? true : false;
      dispatch(setSelectedRegime(Data));
      dispatch(setRegimeDetailsLoading(false));

      successCallback && successCallback(Data);
    } catch (error) {
      dispatch(setRegimeDetailsLoading(false));
    }
  };
};

export const createRegime = (params, successCallback) => {
  return async dispatch => {
    try {
      const res = await regimeActions.createRegime(params);
      const { Data } = res.data;
      dispatch(setSelectedRegime(Data));
      dispatch(addRegimeAtIndex(Data));

      successCallback(Data);
    } catch (error) {}
  };
};

export const duplicateRegime = (ID, successCallback) => {
  return async dispatch => {
    try {
      const res = await regimeActions.duplicateRegime({ ID });
      const { Data } = res.data;
      dispatch(setSelectedRegime(Data));
      dispatch(addRegimeAtIndex(Data));

      successCallback(Data);
    } catch (error) {}
  };
};

export const deleteRegime = (regime, successCallback) => {
  return async dispatch => {
    try {
      await regimeActions.deleteRegime({ ID: regime[formConstants.fields.id] });
      dispatch(removeRegimeByProp(regime));
      successCallback && successCallback();
    } catch (error) {}
  };
};

export const updateRegime = (params, successCallback) => {
  return async dispatch => {
    try {
      const res = await regimeActions.updateRegime(params);
      const { Data } = res.data;
      dispatch(updateSelectedRegime(Data));
      dispatch(updateRegimeItemByProp(Data));
      successCallback();
    } catch (error) {}
  };
};

export const changeStatus = (data, callback, regimes) => {
  return async dispatch => {
    try {
      await regimeActions.changeStatus(data);

      if (regimes) {
        const index = findIndex(regimes, { [formConstants.fields.id]: data.ID });

        if (index > -1) {
          dispatch(updateRegimeItemByProp({ ...regimes[index], [formConstants.fields.status]: data[formConstants.fields.status] }));
        }
      }

      callback();
    } catch (e) {
      console.log(e);
    }
  };
};

export const getRegimeComponents = (regime, successCallback) => {
  return async dispatch => {
    try {
      const res = await regimeActions.getRegimeComponents({ [formConstants.fields.id]: regime });
      const { Data } = res.data;
      let components = Object.assign([], Data);
      components = components.map(component => {
        return { ...component, [componentFields.compID]: component[componentFields.id], [componentFields.compName]: component[componentFields.name] };
      });

      dispatch(setRegimeComponents(components));
      successCallback();
    } catch (error) {}
  };
};

export const searchComponents = (inspection_id, searchText, otherParams) => {
  return async dispatch => {
    try {
      const { dispatchActions, requestActions } = ElementActions[measurementTypes.component];
      const res = await requestActions.getInspectionElements({ ...{ InspectionID: parseInt(inspection_id), SearchText: searchText }, ...(otherParams || {}) });
      const { Data } = res.data;
      let components = Object.assign([], Data.Components);
      components.map(component => {
        return { ...component, [componentFields.compID]: component[componentFields.id], [componentFields.compName]: component[componentFields.name] };
      });
      dispatch(dispatchActions.setInspectionElements(components));
    } catch (error) {}
  };
};

export const addComponentToRegime = (regime, component, currentComponents, successCallback) => {
  return async dispatch => {
    try {
      component = isArray(component) && component.length > 0 ? component[0] : component;
      const res = await regimeActions.addComponentToRegime({ ComponentID: component.ID, MaintenanceRegimeID: regime[formConstants.fields.id] });
      const { Data } = res.data;
      Data[componentFields.compName] = Data[componentFields.name];
      Data[componentFields.compID] = Data[componentFields.id];

      dispatch(addComponentAtIndex(Data));
      currentComponents.push(component);
      dispatch(updateSelectedRegime({ [formConstants.fields.components]: currentComponents }));
      dispatch(updateRegimeItemByProp({ [formConstants.fields.id]: regime[formConstants.fields.id], [formConstants.fields.components]: currentComponents }));

      successCallback();
    } catch (error) {}
  };
};

export const removeComponentFromRegime = (regime, component, currentComponents, successCallback) => {
  return async dispatch => {
    try {
      await regimeActions.removeComponentFromRegime({ ComponentID: component[componentFields.id], RegimeID: regime[formConstants.fields.id] });
      dispatch(removeRegimeComponentByProp(component));
      currentComponents = ReducerHelpers.removeItemByProp(currentComponents, component, 'ID');
      dispatch(updateSelectedRegime({ [formConstants.fields.components]: currentComponents }));
      dispatch(updateRegimeItemByProp({ [formConstants.fields.id]: regime[formConstants.fields.id], [formConstants.fields.components]: currentComponents }));

      successCallback();
    } catch (error) {}
  };
};

export const getIntervalList = (successCallback = () => null) => {
  return async dispatch => {
    try {
      const res = await regimeActions.getIntervalList();

      dispatch(setIntervalList(res.data.Data));
      successCallback && successCallback(res.data.Data);
    } catch (error) {}
  };
};

export const getRegimeFiles = (id, group, successCallback = () => null) => {
  return async dispatch => {
    try {
      const res = await regimeActions.getRegimeFiles({ MaintenanceRegimeID: id, UploadGroup: group });
      dispatch(setRegimeFiles(res.data.Data));
      successCallback();
    } catch (error) {}
  };
};

export const unlinkRegimeFile = (MaintenanceRegimeID, FileID, uploadGroup, successCallback) => {
  return async dispatch => {
    try {
      await regimeActions.unlinkRegimeFile({ MaintenanceRegimeID, FileID });

      dispatch(removeRegimeFileByGroup({ FileID }, uploadGroup));

      successCallback();
    } catch (error) {}
  };
};

export const getRegimeChecklists = (RegimeID, successCallback = () => null, errorCallback = () => null) => {
  return async dispatch => {
    try {
      const res = await regimeActions.getRegimeChecklists({ RegimeID });
      dispatch(setRegimeChecklists(res.data.Data));
      successCallback();
    } catch (error) {
      errorCallback();
    }
  };
};

export const getRegimeChecklistTemplateSections = (data, callback, questionId, areAdditionalFieldsExpanded) => {
  return async () => {
    try {
      callback(true, true);
      const res = await regimeActions.getRegimeChecklistTemplateSections(data);
      const { Items: sections, ...newFilter } = res?.data?.Data;

      if (sections) {
        callback(
          false,
          false,
          sections.map(el => ({ ...el, [moduleSectionsFields.expanded]: false })),
          true,
          false,
          newFilter
        );
      }
    } catch (e) {
      callback(false, false);
      console.error(e);
    }
  };
};

export const getRegimeChecklistTemplateSectionQuestions = (ChecklistAndProcedureTemplateID, SectionID, filters, callback) => {
  return async () => {
    try {
      callback(true, true, SectionID);
      const res = await regimeActions.getRegimeChecklistTemplateSectionQuestions({ ChecklistAndProcedureTemplateID, SectionID, ...filters });
      const { Items: questions, ...newFilter } = res?.data?.Data;

      if (questions) {
        callback(false, false, SectionID, questions, { ...filters, ...newFilter });
      }
    } catch (e) {
      callback(false, false, SectionID);
      console.error(e);
    }
  };
};

export const searchChecklists = (params, successAction = () => null) => {
  return async dispatch => {
    try {
      const res = await regimeActions.getChecklists({ ...params, PerPage: 1000 });
      const { ChecklistAndProcedureTemplates } = res.data.Data;

      successAction(ChecklistAndProcedureTemplates || []);
    } catch (error) {
      console.error(error);
    }
  };
};

export const saveChecklists = (RegimeID, data, successCallback, failCallback) => {
  return async () => {
    try {
      await regimeActions.saveWorkOrderChecklistTemplate({ RegimeID, ...data });

      successCallback && successCallback();
    } catch (e) {
      console.error(e);
      failCallback && failCallback(e);
    }
  };
};

export const generateAndDownloadRegimeChecklistTemplatePDF = (checklist_template_id, callback) => {
  return async () => {
    try {
      const res = await regimeActions.generateAndDownloadRegimeChecklistTemplatePDF([{ checklist_template_id }]);
      const fileName = res.request.getResponseHeader('content-disposition').split('filename=')[1].split(';')[0];

      const file = res?.data;
      file.name = fileName || null;
      if (file) {
        Helpers.downloadPDFFile(file);
      }

      callback && callback();
    } catch (e) {
      console.error(e);
      callback && callback();
    }
  };
};

export const updateInterval = (regime, params, successCallback = () => null, errorCallback = () => null) => {
  return async dispatch => {
    try {
      const res = await regimeActions.updateInterval({ ...params, MaintenanceRegimeID: regime[formConstants.fields.id] });
      dispatch(updateSelectedRegime(res.data.Data));
      dispatch(updateRegimeItemByProp({ ...res.data.Data, [formConstants.fields.id]: regime[formConstants.fields.id], [formConstants.fields.interval]: params[formConstants.fields.interval] }));
      if (regime[formConstants.fields.status] !== regimeStatus.confirmed) {
        await regimeActions.confirmRegime({ [formConstants.fields.status]: regimeStatus.confirmed, [formConstants.fields.id]: regime[formConstants.fields.id] });
      }
      successCallback();
    } catch (error) {
      errorCallback();
    }
  };
};

export const clearRegimeModalData = () => {
  return async dispatch => {
    dispatch(setSelectedRegime(null));
    dispatch(setRegimeChecklists([]));
    dispatch(setRegimeFiles({ [fileGroups.cheklistFiles]: [] }));
    dispatch(setRegimeFiles({ [fileGroups.regimeFiles]: [] }));
    dispatch(setRegimeComponents([]));
  };
};

export const getRegimeTasks = (params, loadMore, successCallback) => {
  return async dispatch => {
    try {
      dispatch(setRegimeWorkOrdersLoading(true));
      const res = await regimeActions.getRegimeTasks(params);
      const { Data } = res.data;
      const { Items, ...restProps } = Data;

      if (loadMore) {
        dispatch(amendRegimeWorkOrders(Items || []));
      } else {
        dispatch(setRegimeTasks(Items || []));
      }

      dispatch(setRegimeWorkOrderFilters(Object.assign(params, restProps)));

      successCallback && successCallback(Items, restProps);
      dispatch(setRegimeWorkOrdersLoading(false));
    } catch (error) {
      dispatch(setRegimeWorkOrdersLoading(false));
    }
  };
};
