import { debounce, isArray, isEmpty, isEqual, keys, map, reduce, values } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import TableTemplate from '../../../../../common/double-table/components/table-template';
import Helpers from '../../../../../common/helpers';
import Modal from '../../../../../common/modal/components/modal';
import WorkordersFilter from '../../left-toolbar/filters/workorders-filter';
import { getSelectedStatuses } from '../../work-order/helpers/work-order-helpers';
import { formatWorkOrdersTableCells } from '../actions/table-formatting';
import { defaultFilters, filterProps, sortDirection, statuses, tableConfig } from '../constants/constants';
import { deleteAdvancedFiltersFromFilters } from '../helpers/work-orders-helpers';

const WorkOrdersWrapper = ({ data, filters, wrapperClassName, loading, fetchWorkOrders, customTableClassName, onRowClick, projectId, inspectionId }, { t }) => {
  const [searchText, setSearchText] = useState('');
  const [advancedFilters, setAdvancedFilters] = useState({});
  const [modalData, setModalData] = useState({ isOpen: false });

  const isFetchWorkOrdersValid = fetchWorkOrders && typeof fetchWorkOrders === 'function';

  const onSort = (SortByColumn = {}) => {
    const newFilters = {
      ...filters,
      [filterProps.sortByColumn]: SortByColumn,
      [filterProps.sortDirection]: filters[filterProps.sortDirection] === sortDirection.asc ? sortDirection.desc : sortDirection.asc,
      [filterProps.lastSeen]: 0,
    };
    isFetchWorkOrdersValid && fetchWorkOrders(newFilters);
  };

  const onSearch = SearchText => {
    const newFilters = {
      ...filters,
      [filterProps.lastSeen]: 0,
      SearchText,
    };
    isFetchWorkOrdersValid && fetchWorkOrders(newFilters);
  };

  const onQuickFilterClick = quickFilters => {
    const newFilters = {
      ...filters,
      [filterProps.lastSeen]: 0,
    };
    if (isArray(quickFilters)) {
      newFilters[filterProps.statusFilter] = [quickFilters.length ? quickFilters.toString() : ''];
    } else {
      newFilters[quickFilters] = !newFilters[quickFilters];
    }

    isFetchWorkOrdersValid && fetchWorkOrders(newFilters);
  };

  const onLoadMoreClick = () => {
    // TODO: fix onLoadMore (list not updating)
    isFetchWorkOrdersValid && fetchWorkOrders(filters, true);
  };

  const handleFileSearchInput = e => {
    setSearchText(e.target.value);
    searchTextChanged(e.target.value);
  };

  const searchTextChanged = debounce(onSearch, 300);

  const applyAdvancedFilters = (values = {}) => {
    setAdvancedFilters(values);
    const newAdvancedFilter = reduce(
      values,
      (obj, _it, key) => {
        if (key === filterProps.severityFilter) {
          obj[key] = [values[filterProps.severityFilter].min, values[filterProps.severityFilter].max];
        } else if ([filterProps.workOrderTypeFilter].indexOf(key) > -1) {
          obj[key] = values[key];
        } else if ([filterProps.createdByFilter, filterProps.assignedTo, filterProps.checklistIDsFilter].indexOf(key) > -1) {
          obj[key] = map(values[key], ({ ID, UserID }) => ID || UserID);
        } else if ([filterProps.startDateFrom].indexOf(key) > -1) {
          obj[key] = (values[key] && Helpers.getUnixDate(new Date(values[key]).getTime())) || null;
        } else if ([filterProps.startDateTo].indexOf(key) > -1) {
          // sets end/to dates to end of day 23:59:59 to include that day in filter results
          obj[key] = (values[key] && Helpers.getUnixDate(new Date(values[key]).setHours(23, 59, 59))) || null;
        } else if ([filterProps.hasChecklistFilter, filterProps.hasObservationFilter].indexOf(key) > -1) {
          obj[key] = Helpers.castToggleRadioInputAnswer(values[key]);
        } else {
          obj[key] = values[key];
        }
        return obj;
      },
      {}
    );

    /* Checking if the advancedFilter is empty, if it is, it deletes the startDateFrom and startDateTo filters. */
    if (isEmpty(advancedFilters)) {
      delete filters[filterProps.startDateFrom];
      delete filters[filterProps.startDateTo];
    }

    /* Checking if the advancedFilter is empty and if the startDateFrom and startDateTo are not in the
    advancedFilter. If they are not in the advancedFilter, then it deletes them from the filters. */
    if (!isEmpty(advancedFilters) && !advancedFilters[filterProps.startDateFrom] && filters[filterProps.startDateFrom]) {
      delete filters[filterProps.startDateFrom];
    }
    if (!isEmpty(advancedFilters) && !advancedFilters[filterProps.startDateTo] && filters[filterProps.startDateTo]) {
      delete filters[filterProps.startDateTo];
    }

    fetchWorkOrders({ ...deleteAdvancedFiltersFromFilters(filters), ...newAdvancedFilter, [filterProps.lastSeen]: 0 });
    closeModal();
  };

  const resetAdvancedFilters = () => {
    setAdvancedFilters({});
    fetchWorkOrders({ ...deleteAdvancedFiltersFromFilters(filters), [filterProps.lastSeen]: 0 });
    closeModal();
  };

  const openAdvancedFilters = () =>
    setModalData({
      isOpen: true,
      type: 'none',
      title: t('WORK_ORDERS.ADVANCED_FILTERS'),
      CustomContent: () => (
        <WorkordersFilter
          hideComponentFilters
          hideCustomPropsFilter
          initialValues={advancedFilters}
          submitForm={applyAdvancedFilters}
          resetFilter={resetAdvancedFilters}
          projectId={projectId}
          inspectionId={inspectionId}
        />
      ),
      customClassName: 'modal-large ',
      closeAction: closeModal,
    });

  const closeModal = () => setModalData({ isOpen: false });

  const advancedFiltersLength = keys(advancedFilters).length;
  const isFilterApplied =
    advancedFiltersLength === 0 && isEqual(defaultFilters[filterProps.statusFilter], filters[filterProps.statusFilter]) && defaultFilters[filterProps.searchText] === filters[filterProps.searchText];

  return (
    <div className={wrapperClassName ? wrapperClassName : ''}>
      <TableTemplate
        tableConfig={tableConfig}
        filters={filters}
        data={data}
        onSortClick={onSort}
        loadMoreOnClick={onLoadMoreClick}
        quickFilters={values(statuses)}
        onQuickFilterClick={onQuickFilterClick}
        handleFormatCells={formatWorkOrdersTableCells}
        openAdvancedFilters={openAdvancedFilters}
        selectedItem={null}
        isLoading={loading}
        TotalItems={filters.TotalNumber}
        searchPlaceholder={t('SEARCH')}
        sortDirection={filterProps.sortDirection}
        sortByColumn={filterProps.sortByColumn}
        resultsText={'COMPONENT_WORK_ORDERS.TOTAL'}
        searchInputOnChange={handleFileSearchInput}
        searchInputValue={searchText}
        hasNext={filters[filterProps.hasNext]}
        selectedQuickFilters={getSelectedStatuses(filters)}
        singleSelected={filters[filterProps.archived]}
        activeAdvancedFilters={advancedFiltersLength}
        noDataText={isFilterApplied ? t('COMPONENT_WORK_ORDERS.NO_DATA') : t('COMPONENT_WORK_ORDERS.NO_DATA_FILTERED')}
        customTableClass={customTableClassName}
        onRowClick={onRowClick}
        isQFAdjustedForSmallerScreen
      />
      <Modal {...modalData} />
    </div>
  );
};

WorkOrdersWrapper.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default WorkOrdersWrapper;
