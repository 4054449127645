export const tableFields = {
  code: 'Code',
  name: 'Name',
  costCenter: 'CostCenter',
  location: 'Location',
  owner: 'Owner',
  maintenanceID: 'MaintenanceID',
  type: 'Type',
  critical: 'Critical',
  parentAsset: 'ParentAsset',
  id: 'ID',
  actionMenu: 'ActionMenu',
};

export const firstTableConfig = [
  {
    title: '',
    key: tableFields.icon,
    type: tableFields.icon,
    class: 'icon-column',
  },
  {
    title: 'COMPONENT_HIERARCHY_TABLE.CODE',
    key: tableFields.code,
    type: tableFields.code,
    isSort: true,
    class: 'code-column',
  },
  {
    title: 'COMPONENT_HIERARCHY_TABLE.NAME',
    key: tableFields.name,
    type: tableFields.name,
    isSort: true,
    class: 'name-column',
  },
  {
    title: 'COMPONENT_HIERARCHY_TABLE.COST_CENTRE',
    key: tableFields.costCenter,
    type: tableFields.costCenter,
    isSort: true,
    class: 'cost-centre-column',
  },
  {
    title: 'COMPONENT_HIERARCHY_TABLE.LOCATION',
    key: tableFields.location,
    type: tableFields.location,
    isSort: true,
    class: 'location-column',
  },
  {
    title: 'COMPONENT_HIERARCHY_TABLE.OWNER',
    key: tableFields.owner,
    type: tableFields.owner,
    isSort: true,
    class: 'owner-column',
  },
  {
    title: 'COMPONENT_HIERARCHY_TABLE.MAINTENANCE_ID',
    key: tableFields.maintenanceID,
    type: tableFields.maintenanceID,
    isSort: true,
    class: 'maintenance-id-column',
  },
];

export const secondTableConfig = [
  {
    title: '',
    key: tableFields.icon,
    type: tableFields.icon,
    class: 'icon-column',
  },
  {
    title: 'COMPONENT_HIERARCHY_TABLE.CODE',
    key: tableFields.code,
    type: tableFields.code,
    isSort: true,
    class: 'code-column',
  },
  {
    title: 'COMPONENT_HIERARCHY_TABLE.NAME',
    key: tableFields.name,
    type: tableFields.name,
    isSort: true,
    class: 'name-column',
  },
  {
    title: 'COMPONENT_HIERARCHY_TABLE.TYPE',
    key: tableFields.type,
    type: tableFields.type,
    isSort: true,
    class: 'cost-centre-column',
  },
  {
    title: 'COMPONENT_HIERARCHY_TABLE.CRITICAL',
    key: tableFields.critical,
    type: tableFields.critical,
    isSort: true,
    class: 'location-column',
  },
  {
    title: 'COMPONENT_HIERARCHY_TABLE.PARENT_ASSET',
    key: tableFields.parentAsset,
    type: tableFields.parentAsset,
    isSort: true,
    class: 'owner-column',
  },
  {
    title: 'COMPONENT_HIERARCHY_TABLE.ID',
    key: tableFields.id,
    type: tableFields.id,
    isSort: true,
    class: 'maintenance-id-column',
  },
  {
    title: '',
    key: tableFields.actionMenu,
    type: tableFields.actionMenu,
    isSort: false,
    class: 'action-menu-column',
  },
];

export const unassignedTableConfig = [
  {
    title: 'COMPONENT_HIERARCHY_TABLE.CODE',
    key: tableFields.code,
    type: tableFields.code,
    isSort: true,
    class: 'code-column',
  },
  {
    title: 'COMPONENT_HIERARCHY_TABLE.NAME',
    key: tableFields.name,
    type: tableFields.name,
    isSort: true,
    class: 'name-column',
  },
  {
    title: '',
    key: tableFields.actionMenu,
    type: tableFields.actionMenu,
    isSort: false,
    class: 'action-menu-column',
  },
];

export const filterProps = {
  sortByColumn: 'SortByColumn',
  sortDirection: 'SortDirection',
  perPage: 'PerPage',
  lastSeen: 'LastSeen',
  searchText: 'SearchText',
  hasNext: 'HasNext',
  totalItems: 'TotalItems',
  parentID: 'ParentID',
  rootID: 'RootID',
  hierarchyID: 'HierarchyID',
};

export const sortDirection = {
  asc: 'ASC',
  desc: 'DESC',
};

export const defaultLevelFilters = {
  [filterProps.sortByColumn]: tableFields.code,
  [filterProps.sortDirection]: sortDirection.asc,
  [filterProps.perPage]: 10,
  [filterProps.lastSeen]: 0,
  [filterProps.searchText]: '',
  [filterProps.hasNext]: false,
};
export const defaultUnassignedFilters = {
  [filterProps.sortByColumn]: tableFields.code,
  [filterProps.sortDirection]: sortDirection.asc,
  [filterProps.perPage]: 50,
  [filterProps.lastSeen]: 0,
  [filterProps.searchText]: '',
  [filterProps.hasNext]: false,
};
export const defaultComponentsFilters = {
  [filterProps.sortByColumn]: tableFields.code,
  [filterProps.sortDirection]: sortDirection.asc,
  [filterProps.perPage]: 10,
  [filterProps.lastSeen]: 0,
  [filterProps.searchText]: '',
  [filterProps.hasNext]: false,
};

export const componentActions = {
  assign: 'assign',
  unassign: 'unassign',
};

export const componentActionsProps = {
  hierarchyID: 'HierarchyID',
  componentID: 'ComponentID',
};

export const placements = {
  right: 'right',
};

export const SearchPropName = {
  level: 'levelsSearchText',
  components: 'componentsSearchText',
  unassigned: 'unassignedSearchText',
};
