import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import UserForm from './user-form';

import { formConstants } from '../../constants/constants';

import { createUser, editUser, fetchUserDetails, getAllRoles, getUsersTableData } from '../../actions/users-data-actions';

import '../../styles/user-form.scss';

class EditUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userToEdit: {},
      loading: false,
    };
  }
  componentDidMount() {
    const { getRoles, fetchUserDetails, userId } = this.props;
    if (userId) {
      this.setState({ loading: true });
      // TODO: phone mapping needs to be fixed, Phone -> UserPhone
      fetchUserDetails(userId, userToEdit => {
        this.setState({ userToEdit, loading: false });
      });
    } else {
      this.setState({ loading: false });
    }
    getRoles();
  }

  handleSubmit = values => {
    const { userId, editUser, createUser, usersTableData, getUsersTableData, usersTableParams } = this.props;
    values[formConstants.fields.role] = (values[formConstants.fields.role] || []).map(role => role.ID);

    if (userId) {
      editUser(values, () => getUsersTableData(usersTableParams));
    } else {
      createUser(values, usersTableData);
    }
  };

  onCancel = () => {
    const { closeAction } = this.props;
    if (closeAction) closeAction();
  };

  render() {
    const { userToEdit } = this.state;
    const { getRoles } = this.props;
    return (
      <div className="create-user-container" data-cy="create-user-container">
        <UserForm onSubmit={this.handleSubmit} onCancel={this.onCancel} userToEdit={userToEdit} getRoles={getRoles} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  usersTableParams: state.usersTableReducer.usersTableParams,
});

const mapDispatchToProps = dispatch => ({
  fetchUserDetails: (id, callback) => dispatch(fetchUserDetails(id, callback)),
  createUser: (values, usersTableData) => dispatch(createUser(values, usersTableData)),
  editUser: (values, callback) => dispatch(editUser(values, callback)),
  getRoles: searchText => dispatch(getAllRoles(searchText)),
  getUsersTableData: (params, loadMore, callback) => dispatch(getUsersTableData(params, loadMore, callback)),
});

EditUser.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
