import { cloneDeep, isEmpty, some } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';

import { modelDetailsConstants, zoneAreaConstants as shapeConstants, shapeTypes, formConstants as zoneConstants } from '../../constants/explosive-zones-constants';

class ShapeRenderer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      objectElements: [],
    };
  }

  componentDidMount() {
    const { elements, viewer } = this.props;
    if (!viewer) return;
    this.shapeTool = new window.Potree.ShapeTool(viewer);
    this.volumeTool = new window.Potree.VolumeTool(viewer);
    elements.forEach(element => {
      this.addMeasure(element);
    });
  }

  componentWillUnmount() {
    this.removeAll();
  }

  removeAll = () => {
    const { viewer } = this.props;
    const { objectElements } = this.state;
    if (viewer) {
      objectElements.forEach(defectObject => {
        const measure = defectObject.measure;
        measure.removeEventListener('position_changed_drop', this.handlePositionChange);
        measure.removeEventListener('rotation_changed_drop', this.handleRotationChange);
        measure.removeEventListener('scale_changed_drop', this.handleScaleChange);
        measure.removeEventListener('shape_select', this.onShapeSelect);
        if (measure.shapeType === shapeTypes.volume) {
          viewer.scene.removeVolume(measure);
        } else {
          viewer.scene.removeShape(measure);
        }
      });
      this.setState({ objectElements: [] });
    }
  };

  componentDidUpdate = prevProps => {
    let { elements, viewer, disableMove } = this.props;
    if (!viewer) {
      return;
    }
    if (viewer !== prevProps.viewer) {
      this.shapeTool = new window.Potree.ShapeTool(viewer);
      this.volumeTool = new window.Potree.VolumeTool(viewer);
    }
    if (this.defectsChanged(prevProps.elements, elements) || viewer !== prevProps.viewer || disableMove !== prevProps.disableMove) {
      this.removeAll();
      // Some element id changed but arrays has same length
      elements.forEach(element => {
        if (element.visible) {
          this.addMeasure(element);
        }
      });
    }
  };

  defectsChanged = (prevElements, currentElements) => {
    if (prevElements.length !== currentElements.length) return true;
    // ARRAYS HAS SAME LENGTH
    else if (!isEmpty(prevElements)) {
      prevElements.forEach((element, index) => {
        if (element.ID === currentElements[index].ID) {
          // UPDATE MEASURE
          if (
            !isEmpty(element.Geometry) &&
            !isEmpty(currentElements[index]) &&
            !isEmpty(currentElements[index].Geometry) &&
            !isEmpty(currentElements[index].Geometry.coordinates) &&
            !isEmpty(currentElements[index].Geometry.coordinates[0]) &&
            (isEmpty(element.Geometry.coordinates) ||
              isEmpty(element.Geometry.coordinates[0]) ||
              element.Geometry.coordinates[0][0] !== currentElements[index].Geometry.coordinates[0][0] ||
              element.Geometry.coordinates[0][1] !== currentElements[index].Geometry.coordinates[0][1] ||
              element.Geometry.coordinates[0][2] !== currentElements[index].Geometry.coordinates[0][2])
          ) {
            if (currentElements[index].visible) {
              // const existingIndex = this.state.objectElements.findIndex(el => el.id === element.ID);
              // if (existingIndex > -1) {
              //   const measure = this.state.objectElements[existingIndex].measure;
              //   measure.setShapePosition(
              //     new window.THREE.Vector3(currentElements[index].Geometry.coordinates[0][0], currentElements[index].Geometry.coordinates[0][1], currentElements[index].Geometry.coordinates[0][2])
              //   );
              // }
            }
          }
          if (
            element.Colour !== currentElements[index].Colour ||
            element.Name !== currentElements[index].Name ||
            element[zoneConstants.fields.transparency] !== currentElements[index][zoneConstants.fields.transparency]
          ) {
            if (currentElements[index].visible) {
              this.removeMeasure(currentElements[index]);
              this.addMeasure(currentElements[index]);
            }
          }
          if (
            !isEmpty(element[shapeConstants.fields.modelDetails]) &&
            !isEmpty(currentElements[index]) &&
            !isEmpty(currentElements[index][shapeConstants.fields.modelDetails]) &&
            element[shapeConstants.fields.modelDetails] !== currentElements[index][shapeConstants.fields.modelDetails]
          ) {
            const existingIndex = this.state.objectElements.findIndex(el => el.id === element.ID);

            if (existingIndex > -1) {
              const measure = this.state.objectElements[existingIndex].measure;
              measure.modelDetails = currentElements[index][shapeConstants.fields.modelDetails];
            }
          }
          if (element.visible !== currentElements[index].visible) {
            if (currentElements[index].visible) this.addMeasure(currentElements[index]);
            else this.removeMeasure(currentElements[index]);
          }
        }
      });
      return some(prevElements, (element, index) => element.ID !== currentElements[index].ID); // Array has the same length but don't have same elements
    }
    return false;
  };

  addAllMeasurementsToScene = (type, persistState) => {
    const { elements } = this.props;

    elements.forEach(element => {
      if (this.state.objectElements.findIndex(el => el.id === element.ID) > -1) return;
      if (element.Geometry && type === element.Geometry.type) {
        if (persistState) {
          if (element.visible) this.addMeasure(element);
        }
      }
    });
  };

  removeAllMeasurementsFromScene = type => {
    const { elements } = this.props;

    elements.forEach(element => {
      if (!element.visible) return;
      if (element.Geometry && type === element.Geometry.type) this.removeMeasure(element);
    });
  };

  onShapeSelect = e => {
    const { elements, selectElement } = this.props;
    const defectObject = this.state.objectElements.find(el => el.measure.uuid === e.object.uuid);
    if (defectObject && defectObject.id) {
      const element = elements.find(d => d.ID === defectObject.id);
      selectElement(element, true);
    }
  };

  handlePositionChange = e => {
    const { elements, updateElementGeometry, handlePositionChange } = this.props;
    const defectObject = this.state.objectElements.find(el => el.measure.uuid === e.object.uuid);
    if (handlePositionChange) return handlePositionChange(defectObject.id, e);

    if (!defectObject) return;
    let defect = elements.find(el => el.ID === defectObject.id);
    if (defect && defect.Geometry && !isEmpty(defect.Geometry.coordinates) && updateElementGeometry) {
      const element = cloneDeep(defect);
      element.Geometry.coordinates = [[e.object.position.x, e.object.position.y, e.object.position.z]];
      element[shapeConstants.fields.modelDetails] = {
        ...defect[shapeConstants.fields.modelDetails],
        ...e.object.modelDetails,
      };

      updateElementGeometry(element);
    }
  };

  handleRotationChange = e => {
    const { elements, updateElementGeometry, handlePositionChange } = this.props;
    const defectObject = this.state.objectElements.find(el => el.measure.uuid === e.object.uuid);
    if (handlePositionChange) return handlePositionChange(defectObject.id, e);

    if (!defectObject) return;
    let defect = elements.find(el => el.ID === defectObject.id);
    if (defect && updateElementGeometry) {
      const element = cloneDeep(defect);
      const newModelDetails = {
        ...defect[shapeConstants.fields.modelDetails],
        ...e.object.modelDetails,
        [modelDetailsConstants.fields.rotation]: { x: e.object.rotation.x, y: e.object.rotation.y, z: e.object.rotation.z },
      };

      element[shapeConstants.fields.modelDetails] = {
        ...newModelDetails,
      };
      e.object.modelDetails = { ...newModelDetails };

      updateElementGeometry(element);
    }
  };

  handleScaleChange = e => {
    const { elements, updateElementGeometry, handlePositionChange } = this.props;
    const defectObject = this.state.objectElements.find(el => el.measure.uuid === e.object.uuid);
    if (handlePositionChange) return handlePositionChange(defectObject.id, e);

    if (!defectObject) return;
    let defect = elements.find(el => el.ID === defectObject.id);
    if (defect && updateElementGeometry) {
      const element = cloneDeep(defect);
      element.Geometry.coordinates = [[e.object.position.x, e.object.position.y, e.object.position.z]];
      const newModelDetails = {
        ...defect[shapeConstants.fields.modelDetails],
        ...e.object.modelDetails,
        [modelDetailsConstants.fields.scale]: { x: e.object.scale.x, y: e.object.scale.y, z: e.object.scale.z },
      };
      element[shapeConstants.fields.modelDetails] = {
        ...newModelDetails,
      };
      e.object.modelDetails = { ...newModelDetails };

      updateElementGeometry(element);
    }
  };

  measureConfig = (args = {}) => {
    let measure = null;
    switch (args.shapeType) {
      case shapeTypes.volume:
        measure = new window.Potree.BoxVolume({ ...args, clip: true });
        measure.name = 'Volume';
        break;
      default:
        measure = new window.Potree.Shape(args);
        measure.name = 'Shape';
        break;
    }

    measure.addEventListener('position_changed_drop', this.handlePositionChange);
    measure.addEventListener('rotation_changed_drop', this.handleRotationChange);
    measure.addEventListener('scale_changed_drop', this.handleScaleChange);
    measure.addEventListener('shape_select', this.onShapeSelect);

    return measure;
  };

  addMeasure = element => {
    const { viewer, disableMove } = this.props;
    if (!viewer) return;
    let measure = null;

    // MEASURE CONFIGURATION
    if (!element.Geometry || isEmpty(element.Geometry.coordinates)) return;
    if (element.Geometry.coordinates[0].length === 3) {
      measure = this.measureConfig({
        colorName: element.Colour,
        shapeType: element.Geometry.type,
        transparency: element[zoneConstants.fields.transparency],
        modelDetails: element[shapeConstants.fields.modelDetails],
        enableMove: !disableMove,
      });
    } else return;

    element.Geometry.coordinates.forEach(position => {
      measure.setShapePosition(new window.THREE.Vector3(position[0], position[1], position[2]));
    });

    const modelDetails = element[shapeConstants.fields.modelDetails];
    if (modelDetails && modelDetails[modelDetailsConstants.fields.rotation]) {
      measure.setShapeRotation(
        new window.THREE.Vector3(modelDetails[modelDetailsConstants.fields.rotation].x, modelDetails[modelDetailsConstants.fields.rotation].y, modelDetails[modelDetailsConstants.fields.rotation].z)
      );
    }

    if (modelDetails && modelDetails[modelDetailsConstants.fields.scale]) {
      measure.setShapeScale(
        new window.THREE.Vector3(modelDetails[modelDetailsConstants.fields.scale].x, modelDetails[modelDetailsConstants.fields.scale].y, modelDetails[modelDetailsConstants.fields.scale].z)
      );
    }

    // CHECK IF DEFECT IS ALREADY IN ARRAY
    this.setState(prevState => {
      const existingIndex = prevState.objectElements.findIndex(el => el.id === element.ID);
      if (existingIndex > -1) {
        const newDefectObjects = [...prevState.objectElements];
        newDefectObjects[existingIndex].measure = measure;
        return { objectElements: newDefectObjects };
      }

      if (measure.shapeType === shapeTypes.volume) {
        viewer.scene.addVolume(measure);

        this.volumeTool.scene.add(measure);
      } else {
        viewer.scene.addShape(measure);

        this.shapeTool.scene.add(measure);
      }
      return { objectElements: [...prevState.objectElements, { id: element.ID, measure }] };
    });
  };

  removeMeasure = element => {
    const { viewer } = this.props;

    const existingIndex = this.state.objectElements.findIndex(el => el.id === element.ID);

    if (existingIndex > -1) {
      const measure = this.state.objectElements[existingIndex].measure;
      measure.removeEventListener('position_changed_drop', this.handlePositionChange);
      measure.removeEventListener('rotation_changed_drop', this.handleRotationChange);
      measure.removeEventListener('scale_changed_drop', this.handleScaleChange);
      measure.removeEventListener('shape_select', this.onShapeSelect);
      if (measure.shapeType === shapeTypes.volume) {
        viewer.scene.removeVolume(measure);
      } else {
        viewer.scene.removeShape(measure);
      }
      this.setState(prevState => {
        let newDefectObjects = prevState.objectElements.filter(el => el.id !== element.ID);
        return { objectElements: newDefectObjects };
      });
    }
  };
  // END

  onElementClick = (e, element) => {
    const { viewer, selectElement } = this.props;
    e.stopPropagation();
    if (
      !isEmpty(element.Geometry) &&
      !isEmpty(element.Geometry.coordinates) &&
      !isEmpty(element.Geometry.coordinates[0]) &&
      !isEmpty(element.CameraPosition) &&
      !isEmpty(element.CameraPosition.coordinates)
    ) {
      viewer.zoomToPosition({ x: element.CameraPosition.coordinates[0], y: element.CameraPosition.coordinates[1], z: element.CameraPosition.coordinates[2] }, element.Geometry.coordinates, 500);
    }

    // const existingIndex = this.state.objectElements.findIndex(el => el.id === element.ID);

    // if (existingIndex > -1) {
    //   const measure = this.state.objectElements[existingIndex].measure;
    //   if (viewer.inputHandler) {
    //     viewer.inputHandler.toggleSelection(measure);
    //   }
    // }

    selectElement(element);
  };

  showElement = (e, element) => {
    const { toggleElement, toggleElementTemp } = this.props;

    e.stopPropagation();
    if (element.IsTemp) toggleElementTemp(element.ID);
    else toggleElement(element.ID);
  };

  hideElement = (e, element) => {
    const { toggleElement, toggleElementTemp } = this.props;

    e.stopPropagation();
    if (element.IsTemp) toggleElementTemp(element.ID);
    else toggleElement(element.ID);
  };

  handleSelectAll = (type = null, persistState = false) => {
    const { selectAll, selectAllTemp } = this.props;
    if (type) {
      selectAll(type, persistState);
      selectAllTemp(type, persistState);
      this.addAllMeasurementsToScene(type, persistState);
    } else {
      selectAll();
      selectAllTemp();
    }
  };

  handleDeselectAll = (type = null, persistState = false) => {
    const { deselectAll, deselectAllTemp } = this.props;

    if (type) {
      deselectAll(type, persistState);
      deselectAllTemp(type, persistState);
      this.removeAllMeasurementsFromScene(type);
    } else {
      deselectAll();
      deselectAllTemp();
    }
  };

  render() {
    const { children } = this.props;

    return (
      <>
        {children &&
          children({
            elementClickHandler: this.onElementClick,
            elementShowHandler: this.showElement,
            elementHideHandler: this.hideElement,
            selectAllHandler: this.handleSelectAll,
            deselectAllHandler: this.handleDeselectAll,
          })}
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  user: state.userReducer,
});
const mapDispatchToProps = dispatch => ({
  changeField: (form, field, value) => dispatch(change(form, field, value)),
});

ShapeRenderer.contextTypes = {
  t: PropTypes.func.isRequired,
};

ShapeRenderer.defaultProps = {
  updateElement: () => null,
  children: () => null,
};

ShapeRenderer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShapeRenderer);
