import { questionTypes } from '../../../../../common/question-components/constants/question-constants';

export const formConstants = {
  fields: {
    id: 'ID',
    name: 'Name',
    dateCreated: 'CreatedAt',
    status: 'Status',
    description: 'Description',
    createdByUser: 'CreatedBy',
    createdByUserID: 'CreatedByUserID',
    signatureRequired: 'SignatureRequired',
  },
};

export const isolationTableHeader = [
  {
    title: 'ISOLATION_TEMPLATE_TABLE.ID',
    key: formConstants.fields.id,
    class: 'id-column',
    enableSort: true,
  },
  {
    title: 'ISOLATION_TEMPLATE_TABLE.NAME',
    key: formConstants.fields.name,
    class: 'name-column',
    enableSort: true,
  },
  {
    title: 'ISOLATION_TEMPLATE_TABLE.DESCRIPTION',
    key: formConstants.fields.description,
    class: 'description',
    enableSort: true,
    type: formConstants.fields.description,
  },
  {
    title: 'ISOLATION_TEMPLATE_TABLE.STATUS',
    key: formConstants.fields.status,
    enableSort: true,
    type: formConstants.fields.status,
  },
  {
    title: 'ISOLATION_TEMPLATE_TABLE.DATE_CREATED',
    key: formConstants.fields.dateCreated,
    enableSort: true,
    type: 'date',
  },
  {
    title: 'ISOLATION_TEMPLATE_TABLE.CREATED_BY',
    key: formConstants.fields.createdByUser,
    enableSort: true,
  },
];

export const filterFields = {
  projectID: 'ProjectID',
  searchText: 'SearchText',
  sortByColumn: 'SortByColumn',
  sortByDirection: 'SortDirection',
  perPage: 'PerPage',
  lastSeen: 'LastSeen',
  hasNext: 'HasNext',
  totalItems: 'TotalItems',
};

export const sortDirection = {
  asc: 'ASC',
  desc: 'DESC',
};

export const defaultFilter = {
  [filterFields.searchText]: '',
  [filterFields.sortByColumn]: formConstants.fields.id,
  [filterFields.sortByDirection]: sortDirection.asc,
  [filterFields.perPage]: 20,
  [filterFields.lastSeen]: 0,
};

export const statuses = {
  draft: {
    value: 'DRAFT',
    title: 'CHECKLISTS_PROCEDURES_LIST.DRAFT_STATUS',
    icon: 'clock',
  },
  raised: {
    value: 'RAISED',
    title: 'CHECKLISTS_PROCEDURES_LIST.RAISED_STATUS',
    icon: 'clock',
    iconClass: 'checklists__table-wrapper__table__icon-grey',
  },
  live: {
    value: 'LIVE',
    title: 'CHECKLISTS_PROCEDURES_LIST.LIVE_STATUS',
    icon: 'checkmark',
    iconProps: {
      active: true,
    },
    iconClass: 'checklists__table-wrapper__table__icon-green',
  },
};

export const steps = {
  firstStep: 1,
  secondStep: 2,
};

export const descriptionMaxChars = 4000;

export const isolationTemplateSections = [
  {
    Name: 'Details',
    nonTranslatable: true,
    lockedQuestions: [
      {
        name: 'Details_Name',
        isLocked: true,
        type: questionTypes.TEXT.value,
        label: 'ISOLATION_TEMPLATE_SECTIONS.DETAILS_NAME',
        placeholder: 'ISOLATION_TEMPLATE_SECTIONS.DETAILS_NAME',
        props: {
          style: {
            maxWidth: '220px',
          },
        },
      },
      {
        name: 'Description',
        isLocked: true,
        type: questionTypes.TEXTAREA.value,
        label: 'ISOLATION_TEMPLATE_SECTIONS.DETAILS_DESCRIPTION',
        placeholder: 'ISOLATION_TEMPLATE_SECTIONS.DETAILS_DESCRIPTION',
        props: {
          fullWidth: true,
          isInline: true,
          maxChars: 2000,
        },
      },
      {
        name: 'Start_Date',
        isLocked: true,
        type: questionTypes.TEXT.value,
        label: 'ISOLATION_TEMPLATE_SECTIONS.DETAILS_START_DATE',
        placeholder: 'ISOLATION_TEMPLATE_SECTIONS.DETAILS_START_DATE',
        props: {
          withRightPadding: true,
          isInline: true,
          style: {
            width: '50%',
          },
        },
      },
      {
        name: 'End_Date',
        isLocked: true,
        type: questionTypes.TEXT.value,
        label: 'ISOLATION_TEMPLATE_SECTIONS.DETAILS_END_DATE',
        placeholder: 'ISOLATION_TEMPLATE_SECTIONS.DETAILS_END_DATE',
        props: {
          withLeftPadding: true,
          isInline: true,
          style: {
            width: '50%',
          },
        },
      },
    ],
  },
  {
    Name: 'Files',
    nonTranslatable: true,
    isFilesUpload: true,
    hideLockIcon: true,
  },
];
