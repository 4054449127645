import React from 'react';
import AddButton from '../../../../common/add-button/components/add-button';
import '../../styles/work-order-section-assignees.scss';
import ContributorsList from '../../../../common/user-team/components/contributors-list';

const WorkOrderSectionAssignees = ({ module, handleCustomAction, sectionAction, disabled }) => {
  if (!module && !disabled) {
    return <AddButton onClick={() => handleCustomAction(sectionAction)}>{'WORK_ORDER.ADD_ASSIGNEES'}</AddButton>;
  }

  return (
    <div className="work-order-section-assignees">
      {module && (
        <ContributorsList
          contributors={module.workorderContributors}
          handleCustomAction={handleCustomAction}
          sectionAction={sectionAction}
          disabled={disabled}
          emptyStateText={'WORK_ORDERS.NO_ASSIGNEES'}
          buttonText={'WORK_ORDER.ADD_ASSIGNEES'}
        />
      )}
    </div>
  );
};

export default WorkOrderSectionAssignees;
