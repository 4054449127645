import React, { Component } from 'react';
import { ReactComponent as SortUp } from '../images/sort-up.svg';
import { ReactComponent as SortDown } from '../images/sort-down.svg';
import { sortingOrder } from '../constants/constants';

class SortingIcon extends Component {
  sortingOrder = () => {
    const { sortingObj, isDisabled, sortDirectionProp } = this.props;

    if (!sortingObj || isDisabled) {
      return sortingOrder.none;
    }

    return sortingObj[sortDirectionProp] === sortingOrder.asc ? sortingOrder.asc : sortingOrder.desc;
  };

  render() {
    const sort = this.sortingOrder();

    return (
      <div className="sort-icon" data-cy="sort-icon">
        <SortUp className={`small ${sort === sortingOrder.desc ? 'svg-primary-g' : 'svg-gray-g'}`} />
        <SortDown className={`small ${sort === sortingOrder.asc ? 'svg-primary-g' : 'svg-gray-g'}`} />
      </div>
    );
  }
}
export default SortingIcon;
