import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { isEmpty } from 'lodash';

import ProgressBar from '../../../common/progress-bar/components/progress-bar';

import Helpers from '../../../common/helpers';
import { inspectedCountConstants } from '../constants/image-statistic-constants';
import LegendSquare from '../../inspection-workflow/components/legend-square';
import inspectionWorkflowConstants from '../../inspection-workflow/constants/inspection-workflow-constants';

import '../styles/charts-view.scss';

const orderedFields = [inspectedCountConstants.imagesNoDefect, inspectedCountConstants.images1to3Severity, inspectedCountConstants.images4to7Severity, inspectedCountConstants.images8to10Severity];

class ChartView extends Component {
  componentDidMount() {
    const { getInspectedImagesCount } = this.props;
    getInspectedImagesCount();
  }

  getPercentages = () => {
    const { inspectedImagesCount } = this.props;

    if (isEmpty(inspectedImagesCount)) return [0, 0, 0, 0];
    const perc1 = Helpers.getPercentage(inspectedImagesCount[orderedFields[0]] || 0, inspectedImagesCount[inspectedCountConstants.totalImages] || 0);
    const perc2 = Helpers.getPercentage(inspectedImagesCount[orderedFields[1]] || 0, inspectedImagesCount[inspectedCountConstants.totalImages] || 0);
    const perc3 = Helpers.getPercentage(inspectedImagesCount[orderedFields[2]] || 0, inspectedImagesCount[inspectedCountConstants.totalImages] || 0);
    const perc4 = Helpers.getPercentage(inspectedImagesCount[orderedFields[3]] || 0, inspectedImagesCount[inspectedCountConstants.totalImages] || 0);

    return [perc1, perc2, perc3, perc4];
  };

  getTotalPercentages = percentages => {
    let sum = 0;
    percentages.forEach(p => (sum += p));
    return sum;
  };

  getTotalAmount = () => {
    const { inspectedImagesCount } = this.props;
    let sum = 0;
    orderedFields.forEach(fieldName => (sum += inspectedImagesCount[fieldName] || 0));
    return parseInt(sum);
  };

  render() {
    const { severityColors, inspectedImagesCount } = this.props;
    const { t } = this.context;
    const colors = Object.keys(severityColors || {}).map(colorName => severityColors[colorName]);
    const percentages = this.getPercentages();
    const totalPerc = this.getTotalPercentages(percentages).toFixed(1);
    const totalAmount = this.getTotalAmount();

    return (
      <div className="charts-view">
        <div className="image-progress">
          <div className="image-progress__title-container">
            <p className="f-primary title">{t('WORKFLOW.CHART_VIEW.SEVERITY_DISTRIBUTION')}</p>
            <p className="f-primary">
              {t('WORKFLOW.CHART_VIEW.INSPECTED_IMAGES_COUNT', {
                inspectedImagesPercentage: totalPerc,
                inspectedImagesCount: totalAmount,
                totalImages: inspectedImagesCount[inspectedCountConstants.totalImages],
              })}
            </p>
          </div>
          <ProgressBar percentages={percentages} colors={colors} />
          <div className="image-progress__legend-container">
            {Object.keys(inspectionWorkflowConstants.severity).map((key, index) => {
              return (
                <div key={index} className="legend-item">
                  <LegendSquare className={inspectionWorkflowConstants.severity[key].color} title={t(inspectionWorkflowConstants.severity[key].label)} />
                  <p className="f-primary legend-item__text">
                    {percentages[index].toFixed(1)}% ({inspectedImagesCount[orderedFields[index]]})
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

ChartView.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ChartView;
