import { get } from 'lodash';
import { fetchComponentNotifications, fetchNotificationFiles } from '../../../../../api/inspection/actions';
import Helpers from '../../../../../common/helpers';
import genericFileImage from '../../../../inspections/assets/component-generic-file.svg';
import { setNotificationFilesUploaded } from '../../../../upload/actions/action-creators';

export const getComponentNotifications = (filters, dataCallback, loadingCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      const res = await fetchComponentNotifications(filters);
      const { Data } = res?.data;
      const { Items, ...rest } = Data;
      dataCallback && typeof dataCallback === 'function' && dataCallback(Items, { ...filters, ...rest });
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
    }
  };
};

export const getNotificationDMSFilesUploaded = notification_id => {
  return async dispatch => {
    try {
      const res = await fetchNotificationFiles([{ notification_id }]);
      let Data = get(res, 'data.Data');

      if (Data) {
        Data = Helpers.mapExternalFilesForModal(Data, genericFileImage);
        dispatch(setNotificationFilesUploaded(Data));
      } else {
        dispatch(setNotificationFilesUploaded({}));
      }
    } catch (e) {
      console.error(e);
      dispatch(setNotificationFilesUploaded({}));
    }
  };
};
