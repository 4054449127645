import { orderBy, pick } from 'lodash';
import { default as Helpers, default as ReducerHelpers } from '../../../common/reducer-helpers';
import { defaultFilters, fields } from '../components/notifications/constants/constants';
import actionTypes from '../constants/action-types';

const notificationState = {
  notifications: [],
  notificationsLoading: false,
  delNotificationModalData: {
    isOpen: false,
  },
  notificationWorkOrders: [],
  notificationDetailsLoading: false,
  customProperties: [],
  customPropertySuggestions: [],
  notificationsFilters: defaultFilters,
  notificationsClustered: [],
  areAllNotificationsHidden: false,
  notificationForm: {
    hasUnsavedChanges: false,
    unsavedChanges: {},
    hasUnsavedCustomProps: false,
    unsavedCustomProps: [],
    requestInProgress: false,
  },
};

export const notificationReducer = (state = notificationState, payload) => {
  switch (payload.type) {
    case actionTypes.SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: payload.data.map(notification => ({ ...notification, visible: true })),
        areAllNotificationsHidden: false,
      };
    case actionTypes.AMEND_MOTIFICATIONS:
      return {
        ...state,
        notifications: [...state.notifications, ...payload.data.map(notification => ({ ...notification, visible: true }))],
        areAllNotificationsHidden: false,
      };
    case actionTypes.SET_NOTIFICATIONS_LOADING:
      return {
        ...state,
        notificationsLoading: payload.data,
      };
    case actionTypes.REMOVE_NOTIFICATION_BY_PROP:
      return {
        ...state,
        notifications: ReducerHelpers.removeItemByProp(state.notifications, payload.data.item, payload.data.prop),
        notificationsClustered: ReducerHelpers.removeItemByProp(state.notificationsClustered, payload.data.item, payload.data.prop),
      };
    case actionTypes.UPDATE_NOTIFICATION_BY_PROP:
      return {
        ...state,
        notifications: ReducerHelpers.updateItemInListByProp(state.notifications, payload.data.item, payload.data.prop),
        notificationsClustered: ReducerHelpers.updateItemInListByProp(state.notificationsClustered, payload.data.item, payload.data.prop),
      };
    case actionTypes.HANDLE_DELETE_NOTIFICATION_MODAL:
      return { ...state, delNotificationModalData: payload.data };
    case actionTypes.SET_NOTIFICATION_WORK_ORDERS:
      return { ...state, notificationWorkOrders: payload.data };
    case actionTypes.AMEND_NOTIFICATION_WORK_ORDERS:
      return { ...state, notificationWorkOrders: [...state.notificationWorkOrders, ...payload.data] };
    case actionTypes.SET_NOTIFICATION_DETAILS_LOADING:
      return {
        ...state,
        notificationDetailsLoading: payload.data,
      };
    case actionTypes.FETCH_NOTIFICATION_PROPERTIES_SUCCESS:
      return { ...state, customProperties: payload.data };
    case actionTypes.ADD_NOTIFICATION_PROPERTIES:
      return {
        ...state,
        customProperties: [
          ...(state.customProperties || []).map(prop => {
            // find unsaved custom prop index
            const unsavedPropIndex = state.notificationForm.unsavedCustomProps?.findIndex(unsavedProp => unsavedProp.ID === prop.ID);
            // if there unsaved custom prop with the same ID update it with new value
            if (state.notificationForm.unsavedCustomProps[unsavedPropIndex]) {
              return {
                ...prop,
                Value: state.notificationForm.unsavedCustomProps[unsavedPropIndex].Value,
              };
            }
            return prop;
          }),
          payload.data,
        ],
      };
    case actionTypes.REMOVE_DELETED_NOTIFICATION_CUSTOM_PROPERTY:
      return { ...state, customProperties: Helpers.removeItemByProp(state.customProperties, { ID: payload.data }, 'ID') };
    case actionTypes.REPLACE_ADDED_NOTIFICATION_CUSTOM_PROPERTY:
      const updatedProps = Object.assign([], payload.data || []);
      const newCustomProps = (state.customProperties || []).map(prop => {
        // If the custom prop is updated it will have ID, so we look for the updated props that already
        // have ID to just update the prop
        const sameIDpropIndex = updatedProps.findIndex(updatedProp => updatedProp.ID === prop.ID);
        let propToReturn = prop;
        if (sameIDpropIndex > -1) {
          // We set the prop to updated prop value
          propToReturn = { ...pick(prop, [fields.hasDependencies]), ...updatedProps[sameIDpropIndex] };
          // We remove the updated prop
          updatedProps.splice(sameIDpropIndex, 1);
        }

        return propToReturn;
      });
      // We order it so it is displayed as it will be on next api call
      orderBy(updatedProps, ['ID']);
      // We combine them together and present them
      return { ...state, customProperties: [...newCustomProps, ...updatedProps], notificationForm: { ...state.notificationForm, unsavedCustomProps: [] } };
    case actionTypes.SET_NOTIFICATION_LABEL_SUGGESTIONS:
      return { ...state, customPropertySuggestions: payload.data };
    case actionTypes.CLEAR_NOTIFICATION_LABEL_SUGGESTIONS:
      return { ...state, customPropertySuggestions: [] };
    case actionTypes.SET_NOTIFICATIONS_FILTER:
      return { ...state, notificationsFilters: payload.data };
    case actionTypes.RESET_NOTIFICATION_FILTERS_PROP:
      return { ...state, notificationsFilters: { ...state.notificationsFilters, [payload.data]: defaultFilters[payload.data] } };
    case actionTypes.SET_NOTIFICATIONS_CLUSTERED:
      return { ...state, notificationsClustered: payload.data.map(notification => ({ ...notification, visible: true })) };
    case actionTypes.SET_TOGGLED_NOTIFICATION:
      const notificationToBeSet = state.notifications.map(notification => {
        return { ...notification, visible: notification.ID === payload.data ? !notification.visible : notification.visible };
      });
      return {
        ...state,
        notifications: notificationToBeSet,
        notificationsClustered: state.notificationsClustered.map(notification => {
          return { ...notification, visible: notification.ID === payload.data ? !notification.visible : notification.visible };
        }),
        areAllNotificationsHidden: notificationToBeSet.every(notification => notification.visible === false),
      };
    case actionTypes.SET_TOGGLE_NOTIFICATIONS_HIDDEN:
      const willAllBeHidden = !state.areAllNotificationsHidden;
      return {
        ...state,
        areAllNotificationsHidden: willAllBeHidden,
        notifications: state.notifications.map(notification => {
          return { ...notification, visible: !willAllBeHidden };
        }),
        notificationsClustered: state.notificationsClustered.map(notification => {
          return { ...notification, visible: !willAllBeHidden };
        }),
      };
    case actionTypes.UPDATE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: payload.data,
        notificationsClustered: payload.clusters,
      };
    case actionTypes.SET_NOTIFICATION_FORM_STATE:
      return { ...state, notificationForm: { ...state.notificationForm, ...payload.data } };
    default:
      return state;
  }
};
