import React from 'react';
import { defaultColor } from '../../../../../common/form/constants/constants';
import { defectType } from '../../../constants/defect-constants';
import { workAreaConstants } from '../../../constants/work-order-constants';
import BaseShapeForm from './base-shape-form';

const ZoneAreaForm = ({ zoneArea, ...restProps }) => {
  const renderZoneAreaType = () => {
    let initialValues = { ...zoneArea };

    if (!initialValues.Geometry) {
      initialValues = {
        ...initialValues,
        Geometry: {
          type: defectType.area,
          coordinates: [],
        },
        [workAreaConstants.fields.color]: defaultColor,
      };
    }
    return <BaseShapeForm initialValues={initialValues} {...restProps} />;
  };

  return <div className="zone-area-form">{renderZoneAreaType()}</div>;
};

export default ZoneAreaForm;
