import React, { Component } from 'react';

import PropTypes from 'prop-types';

import '../styles/visible-area.scss';

class VisibleArea extends Component {
  render() {
    const { leftCollapsed, topCollapsed, rightCollapsed, children, interactable } = this.props;

    return (
      <div className={`visible-area ${interactable ? 'interactable' : ''} ${leftCollapsed ? '' : ' left-padding'}${rightCollapsed ? '' : ' right-padding'}${topCollapsed ? '' : ' top-padding'}`}>
        <div className="visible-area__container">{children}</div>
      </div>
    );
  }
}

VisibleArea.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default VisibleArea;
