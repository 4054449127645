import PropTypes from 'prop-types';
import React from 'react';

//styles
import RenderIf from '../../render-if/components/render-if';
import '../styles/uneditable-info.scss';

class UneditableInfo extends React.Component {
  render() {
    const { t } = this.context;
    const { input, label, wrapperClassName, labelClass, withTopRightButton, topRightButtonAction, topRightButtonText, visible, textClassName, disabled } = this.props;
    return (
      <RenderIf if={visible}>
        <div className={`uneditable-info ${wrapperClassName || ''}`}>
          <div className="uneditable-info__header">
            <div className={`uneditable-info__header__label ${withTopRightButton ? 'label-shortened' : ''}`}>
              <p className={`f-secondary-dark light-bold ${labelClass || ''}`}>{label ? `${t(label)}` : ''}</p>
            </div>
            <div className={`uneditable-info__header__top-right-button ${withTopRightButton ? 'button-displayed' : 'button-hidden'}`}>
              <button type="button" disabled={disabled} onClick={topRightButtonAction}>
                <p className={`f-secondary-green noselect pointer link${disabled ? '--disabled' : ''}`}>{topRightButtonText ? `${t(topRightButtonText)}` : ''}</p>
              </button>
            </div>
          </div>
          <p className={`f-primary light-bold ${textClassName}`}>{input?.value || '-'}</p>
        </div>
      </RenderIf>
    );
  }
}
UneditableInfo.propTypes = {
  label: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  labelClass: PropTypes.string,
  withTopRightButton: PropTypes.bool,
  topRightButtonAction: PropTypes.func,
  topRightButtonText: PropTypes.string,
  visible: PropTypes.bool,
  textClassName: PropTypes.string,
  disabled: PropTypes.bool,
};
UneditableInfo.contextTypes = {
  t: PropTypes.func.isRequired,
};
UneditableInfo.defaultProps = {
  withTopRightButton: false,
  visible: true,
  textClassName: '',
};
export default UneditableInfo;
