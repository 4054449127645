import { getChartPointGroups, getNdtMeasurementsComponents, getNdtMeasurementsUnits, getNdtAlarmsTriggered, getNDTChartPeriodData } from '../../../app/inspections/actions/ndt-actions';
import { notificationModalCustom } from '../../../common/modal/actions/modal-actions';

import ndtActions from '../../../api/ndt-api/actions';

export const getXslxData = inspectionId => {
  return async dispatch => {
    try {
      await dispatch(getChartPointGroups());
      await dispatch(getNdtMeasurementsComponents(inspectionId));
      await dispatch(getNdtMeasurementsUnits(inspectionId));
      await dispatch(getNdtAlarmsTriggered(inspectionId));
      await dispatch(getNDTChartPeriodData(inspectionId));
    } catch (err) {
      console.log(err);
    }
  };
};

export const downloadNDTReport = (InspectionID, params, sucessCallback) => {
  return async dispatch => {
    try {
      await ndtActions.downloadReport({ InspectionID, ...params });

      sucessCallback && sucessCallback();
      dispatch(notificationModalCustom(true, 'DOWNLOAD_REPORT.SUCCESS_MESSAGE', 'DOWNLOAD_REPORT.SUCCESS_MESSAGE_TITLE'));

      //open sucess modal to say check email
    } catch (err) {
      console.log(err);
    }
  };
};
