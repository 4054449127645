const fields = {
  components: 'ComponentIDs',
  measurmentUnits: 'MeasurementUnits',
  alarmsTriggered: 'AlarmsTriggered',
  timeline: 'MeasurementTimeline',
  whichMeasurement: 'WhichMeasurement',
  includeAnalytics: 'IncludeAnalytics',
  filterGroups: 'FilterGroups',
  dateFrom: 'DateFrom',
  dateTo: 'DateTo',
  includeAlarmsTriggered: 'HasAlarm',
  measurementIDs: 'MeasurementLocationIDs',
};

const multiSelectFields = {
  components: fields.components,
  measurmentUnits: fields.measurmentUnits,
  // alarmsTriggered: fields.alarmsTriggered,
  filterGroups: fields.filterGroups,
};

const dropdownFields = {
  components: fields.timeline,
  measurmentUnits: fields.whichMeasurement,
};

const dateFields = {
  dateFrom: fields.dateFrom,
  dateTo: fields.dateTo,
};

const objectConstruction = {
  key: 'Key',
  value: 'Value',
};

const tabNames = {
  downloadReport: 'download-report',
  reportHistory: 'reportHistory',
};

const tabs = [
  {
    name: tabNames.downloadReport,
    label: 'DOWNLOAD_REPORT.NEW_REPORT',
  },
  {
    name: tabNames.reportHistory,
    label: 'DOWNLOAD_REPORT.REPORT_HISTORY',
  },
];

const reportTableHeader = [
  {
    type: 'enumerated',
    title: 'DOWNLOAD_REPORT_TABLE.CREATOR',
    key: 'UserName',
    className: 'three-dots',
  },
  {
    title: 'DOWNLOAD_REPORT_TABLE.DATE_CREATED',
    key: 'ReportDate',
    type: 'date',
  },
];

const descriptionMaxChars = 4000;

const defaultValue = 'ALL';

const imagesPerPageValues = {
  rightValue: 6,
  leftValue: 2,
};
const supportedMimeTypes = 'application/pdf';

//5 MB in bytes
const maxFileSize = 5000000;

const fileUploadType = 'PDF_COVER';

const potreeId = 'download_report_potree_2';

const mapId = 'download_report_map_2';

export default {
  fields,
  objectConstruction,
  defaultValue,
  multiSelectFields,
  dropdownFields,
  dateFields,
  tabs,
  tabNames,
  reportTableHeader,
  descriptionMaxChars,
  imagesPerPageValues,
  supportedMimeTypes,
  maxFileSize,
  fileUploadType,
  potreeId,
  mapId,
};
