import actionTypes from '../constants/action-types';

import ReducerHelpers from '../../../common/reducer-helpers';
import { defaultQuickFilterRegimeTasks } from '../../inspections/constants/work-order-constants';
import { defaultPagingObjRegime } from '../constants/regime-constants';
import Helpers from '../../../common/helpers';
import { cloneDeep, filter, findIndex, some } from 'lodash';
import { checklistTemplateFields } from '../../inspections/components/work-order/constants/work-order-right-side-constants';
import { checklistFields } from '../../inspections/components/work-order/constants/constants';

const regimeState = {
  regimeList: null,
  selectedRegime: null,
  regimeFiles: {},
  regimeComponents: [],
  regimeChecklists: [],
  intervalList: [],
  regimeTasks: [],
  pagingObjectRegime: defaultPagingObjRegime,
  regimeFilters: defaultQuickFilterRegimeTasks,
  regimeTasksLoading: false,
  maintenanceRegimeDetailsLoading: false,
};

export const regimeReducer = (state = regimeState, payload) => {
  switch (payload.type) {
    case actionTypes.SET_REGIME_LIST:
      return { ...state, regimeList: payload.data };
    case actionTypes.AMEND_REGIME_LIST:
      return { ...state, regimeList: [...state.regimeList, ...payload.data] };
    case actionTypes.SET_SELECTED_REGIME:
      return { ...state, selectedRegime: payload.data };
    case actionTypes.SET_REGIME_DETAILS_LOADING:
      return { ...state, maintenanceRegimeDetailsLoading: payload.data };
    case actionTypes.ADD_REGIME_AT_INDEX:
      return {
        ...state,
        regimeList: ReducerHelpers.addItemToIndex(state.regimeList, payload.data.item, payload.data.index),
      };
    case actionTypes.UPDATE_SELECTED_REGIME:
      return { ...state, selectedRegime: { ...state.selectedRegime, ...payload.data } };

    case actionTypes.UPDATE_REGIME_ITEM_BY_PROP:
      return {
        ...state,
        regimeList: ReducerHelpers.updateItemInListByProp(state.regimeList, payload.data.item, payload.data.prop),
      };

    case actionTypes.SET_REGIME_FILES:
      return {
        ...state,
        regimeFiles: {
          ...payload.data,
        },
      };
    case actionTypes.REMOVE_REGIME_BY_PROP:
      return {
        ...state,
        regimeList: ReducerHelpers.removeItemByProp(state.regimeList, payload.data.item, payload.data.prop),
      };

    case actionTypes.REMOVE_REGIME_FILE_BY_GROUP:
      return {
        ...state,
        regimeFiles: {
          ...state.regimeFiles,
          [payload.data.group]: ReducerHelpers.removeItemByProp(state.regimeFiles[payload.data.group], payload.data.file, payload.data.prop),
        },
      };
    case actionTypes.SET_REGIME_COMPONENTS:
      return { ...state, regimeComponents: payload.data };
    case actionTypes.ADD_COMPONENT_TO_INDEX:
      return {
        ...state,
        regimeComponents: ReducerHelpers.addItemToIndex(state.regimeComponents, payload.data.item, payload.data.index === -1 ? state.regimeComponents.length : payload.data.index),
      };

    case actionTypes.REMOVE_REGIME_COMPONENT_BY_PROP:
      return {
        ...state,
        regimeComponents: ReducerHelpers.removeItemByProp(state.regimeComponents, payload.data.item, payload.data.prop),
      };
    case actionTypes.SET_REGIME_CHECKLISTS:
      return {
        ...state,
        regimeChecklists: Helpers.addStandaloneCPTemplates(payload.data).map(template => {
          const hasLinkedComponent = some(payload.data, i => i.ID === template.ID && i[checklistTemplateFields.componentID] !== undefined);
          return { ...template, [checklistFields.isLinkedToComponent]: hasLinkedComponent };
        }),
      };
    case actionTypes.ADD_REGIME_CHECKLIST:
      return {
        ...state,
        regimeChecklists: [...state.regimeChecklists, payload.data],
      };
    case actionTypes.REMOVE_REGIME_CHECKLIST_BY_PROP: {
      let newWorkorderChecklists = cloneDeep(state.regimeChecklists);
      // remove all checklists by id
      newWorkorderChecklists = filter(newWorkorderChecklists, it => it[payload.data.prop] !== payload.data.item[payload.data.prop]);
      return {
        ...state,
        regimeChecklists: newWorkorderChecklists,
      };
    }
    case actionTypes.LINK_REGIME_CP_TEMPLATE_TO_COMPONENTS:
      return {
        ...state,
        regimeChecklists: ReducerHelpers.updateItemInListByProp(state.regimeChecklists, { ...payload.data.item, [checklistFields.isLinkedToComponent]: true }, payload.data.prop),
      };
    case actionTypes.UNLINK_REGIME_CP_TEMPLATE_FROM_COMPONENTS: {
      let newWorkorderChecklists = cloneDeep(state.regimeChecklists);
      // remove all components
      newWorkorderChecklists = filter(
        newWorkorderChecklists,
        it =>
          it[payload.data.prop] !== payload.data.item[payload.data.prop] || (it[payload.data.prop] === payload.data.item[payload.data.prop] && it[checklistTemplateFields.componentID] === undefined)
      );
      // update regime checklist isLinkedToComponent prop
      newWorkorderChecklists = ReducerHelpers.updateItemInListByProp(
        newWorkorderChecklists || [],
        { [payload.data.prop]: payload.data.item[payload.data.prop], [checklistFields.isLinkedToComponent]: false },
        payload.data.prop
      );
      return {
        ...state,
        regimeChecklists: newWorkorderChecklists,
      };
    }
    case actionTypes.REMOVE_REGIME_COMPONENT_FROM_CP_TEMPLATE: {
      const foundIndex = findIndex(
        state.regimeChecklists,
        it => it[payload.data.prop] === payload.data.item[payload.data.prop] && it[checklistTemplateFields.componentID] === payload.data.component[payload.data.prop]
      );
      if (foundIndex === -1) {
        return;
      }

      const newWorkorderChecklists = [...state.regimeChecklists.slice(0, foundIndex), ...state.regimeChecklists.slice(foundIndex + 1)];
      return {
        ...state,
        regimeChecklists: newWorkorderChecklists,
      };
    }
    case actionTypes.SET_INTERVAL_LIST:
      return {
        ...state,
        intervalList: payload.data,
      };
    case actionTypes.SET_REGIME_TASKS:
      return { ...state, regimeTasks: payload.data };
    case actionTypes.AMEND_REGIME_WORK_ORDERS:
      return {
        ...state,
        regimeTasks: [...state.regimeTasks, ...payload.data],
      };
    case actionTypes.HANDLE_REGIME_WORK_ORDERS_LOADING:
      return {
        ...state,
        regimeTasksLoading: payload.data,
      };
    case actionTypes.SET_WORK_ORDER_PAGING_REGIME:
      return {
        ...state,
        pagingObjectRegime: payload.data,
      };
    case actionTypes.SET_REGIME_WORK_ORDER_FILTERS:
      return {
        ...state,
        regimeFilters: payload.data,
      };
    default:
      return state;
  }
};
