import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Navigation from '../../navigation/components/navigation';
import VerificationForm from '../../../common/verification-form/components/verification-form';

import '../styles/profile-wrapper.scss';

class EmailVerification extends Component {
  render() {
    const { t } = this.context;
    const { handleSubmit, customSignOutClick, hideToggle, resend, resendExpiration, cancelClick, title, subtitle } = this.props;

    return (
      <div className="profiles-container">
        <Navigation customSignOutClick={customSignOutClick} hideToggle={hideToggle} />
        <h3 className="f-primary noselect"> {t('LOGIN.EMAIL_VERIFICATION_TITLE')}</h3>

        <VerificationForm
          {...{
            resend,
            resendExpiration,
            cancelClick,
            title,
            subtitle,
            enableTimer: true,
            onSubmit: handleSubmit,
          }}
        />
      </div>
    );
  }
}

EmailVerification.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default EmailVerification;
