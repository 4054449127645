import PropTypes from 'prop-types';
import React from 'react';

import BasicImage from '../../../../../common/image-components/components/basic-image';
import RenderIf from '../../../../../common/render-if/components/render-if';
import { fields } from '../isolation-certificate-details/constants/change-status-constants';

const ChangeStatusSignatures = ({ assignee, witness, expanded, showAssignee, showWitness, witnessLabel, signatureRequired }, { t }) => {
  return (
    <div className="status-signatures-wrapper">
      <RenderIf if={showAssignee}>
        <div className="assignee">
          <p className="f-secondary-dark bold signatures-title-separator">{t('CHANGE_STATUS_SECTION.ISOLATOR')}</p>
          <p className="f-primary bold signatures-separator">{assignee[fields.assigneeName] || '-'}</p>
          <RenderIf if={expanded && signatureRequired}>
            <div className="user-signatures-wrapper">
              <p className="f-secondary-dark bold signatures-title-separator">{t('CHANGE_STATUS_SECTION.ISOLATOR_SIGNATURE')}</p>
              <RenderIf if={assignee[fields.signature]}>
                <BasicImage className="user-signature-field" src={assignee[fields.signature]} />
              </RenderIf>
              <RenderIf if={!assignee[fields.signature]}>
                <p className="f-primary bold signatures-separator">{'-'}</p>
              </RenderIf>
            </div>
          </RenderIf>
        </div>
      </RenderIf>
      <RenderIf if={showWitness}>
        <div className="witness">
          <p className="f-secondary-dark bold signatures-title-separator">{t(witnessLabel || 'CHANGE_STATUS_SECTION.WITNESS')}</p>
          <p className="f-primary bold signatures-separator">{witness[fields.witnessName] || '-'}</p>
          <RenderIf if={expanded && signatureRequired}>
            <div className="user-signatures-wrapper">
              <p className="f-secondary-dark bold signatures-title-separator">{t('CHANGE_STATUS_SECTION.WITNESS_SIGNATURE')}</p>
              <RenderIf if={assignee[fields.signature]}>
                <BasicImage className="user-signature-field" src={witness[fields.signature]} />
              </RenderIf>
              <RenderIf if={!assignee[fields.signature]}>
                <p className="f-primary bold signatures-separator">{'-'}</p>
              </RenderIf>
            </div>
          </RenderIf>
        </div>
      </RenderIf>
    </div>
  );
};

ChangeStatusSignatures.contextTypes = {
  t: PropTypes.func.isRequired,
};

ChangeStatusSignatures.propTypes = {
  assignee: PropTypes.shape({
    SignedByUser: PropTypes.string,
    Signature: PropTypes.string,
  }),
  witness: PropTypes.shape({
    SignedByUser: PropTypes.string,
    Signature: PropTypes.string,
  }),
  expanded: PropTypes.bool,
};

export default ChangeStatusSignatures;
