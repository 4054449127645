import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Field, reduxForm } from 'redux-form';
import BasicInput from '../../common/input/basic-input';
import Icon from '../../../../../common/icon/components/icon';

import { FORMS } from '../../../../../common/constants';
import { areaGroupConstants } from '../../../constants/areas-constants';
import { validateArea } from '../validators/area-group-validator';

import '../../../styles/area-group.scss';

class AreaGroupName extends Component {
  render() {
    const { t } = this.context;
    const { handleOnClose, handleSubmit } = this.props;

    return (
      <form className="area-group__form" onSubmit={handleSubmit} noValidate>
        <Field
          id={areaGroupConstants.fields.name}
          name={areaGroupConstants.fields.name}
          component={BasicInput}
          showError={false}
          autoFocus={true}
          placeholder={t('AREA_GROUP.FORM_NAME_PLACEHOLDER')}
          type="text"
        />
        <div className="edit-mode-actions">
          <button type="submit">
            <div className="checkmark" />
          </button>
          <button onClick={handleOnClose}>X</button>
        </div>
      </form>
    );
  }
}

AreaGroupName.contextTypes = {
  t: PropTypes.func.isRequired,
};

AreaGroupName = reduxForm({
  validate: validateArea,
  enableReinitialize: true,
})(AreaGroupName);

class AreaGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditMode: false,
    };
    this.node = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }

  handleClickOutside = e => {
    if (this.node && this.node.current && this.node.current.contains(e.target)) {
      // element is clicked no need to call click outside handler function
      return;
    }
    this.handleOnClose();
  };

  submitAreaGroupName = vals => {
    const { id, updateAreaGroup } = this.props;
    if (vals[areaGroupConstants.fields.name]) {
      updateAreaGroup(id, vals[areaGroupConstants.fields.name]);
    }
    this.handleOnClose();
  };

  handleEditMode = () => {
    const { isEditMode } = this.state;
    this.setState({ isEditMode: !isEditMode });
  };

  handleOnClose = () => {
    const { isEditMode } = this.state;
    if (isEditMode) {
      this.handleEditMode();
    }
  };

  render() {
    const { name, id, deleteAreaGroup, defaultGroupId } = this.props;
    const { isEditMode } = this.state;

    return (
      <div className="area-group" ref={this.node}>
        {!isEditMode ? (
          <>
            <p className="f-primary">{name}</p>
            {defaultGroupId !== id && (
              <div className="area-group__group_actions">
                <Icon name="pen" size="xs" onClick={this.handleEditMode} className={`icon`} />
                <Icon name="delete" size="xs" onClick={() => deleteAreaGroup(id, name)} className={`icon`} />
              </div>
            )}
          </>
        ) : (
          <AreaGroupName initialValues={{ [areaGroupConstants.fields.name]: name }} handleOnClose={this.handleOnClose} onSubmit={this.submitAreaGroupName} form={`${FORMS.areaGroupForm}_${id}`} />
        )}
      </div>
    );
  }
}

AreaGroup.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default AreaGroup;
