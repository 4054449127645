export const fields = {
  name: 'Name',
  email: 'Email',
};
export const showTeamUserTableConfig = [
  {
    title: 'NAVIGATION.MEMBERS',
    key: fields.name,
    type: fields.name,
    isSort: false,
    class: 'user-name-and-email-col',
  },
  {
    title: 'TABLE.EMAIL',
    key: fields.email,
    type: fields.email,
    isSort: false,
    class: 'user-name-and-email-col',
  },
];
