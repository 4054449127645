import React from 'react';
import PropTypes from 'prop-types';

import { find } from 'lodash';

import RenderIf from '../../render-if/components/render-if';
import Icon from '../../icon/components/icon';

import { quickFilter } from '../../../app/inspections/constants/defect-constants';

const HistoryItemDefectStatus = ({ from, to }, { t }) => {
  const getIcon = value => {
    const obj = find(quickFilter, { value });

    return obj?.customComponent('history-item-defect-status-display__icon', 'xs');
  };

  const getLabel = value => {
    const obj = find(quickFilter, { value });
    return obj?.label ? t(obj.label) : '';
  };

  return (
    <div className="history-item-defect-status-display">
      <RenderIf if={from}>
        <div className="history-item-defect-status-display__item">
          {getIcon(from)}
          {getLabel(from)}
        </div>
      </RenderIf>
      <Icon name="arrow-right" size="sm" className="history-item-display__icon" />

      <RenderIf if={to}>
        <div className="history-item-defect-status-display__item">
          {getIcon(to)}
          {getLabel(to)}
        </div>
      </RenderIf>
    </div>
  );
};
HistoryItemDefectStatus.contextTypes = {
  t: PropTypes.func.isRequired,
};

HistoryItemDefectStatus.propTypes = {
  from: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  to: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default HistoryItemDefectStatus;
