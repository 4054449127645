import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { get, isEmpty, reduce } from 'lodash';
import Helpers from '../../../../common/helpers';

import AccessRenderer from '../../../../common/access-renderer/components/access-renderer';
import Navigation from '../../../navigation/components/navigation';

import routesConstants from '../../../../common/routes-constants';
import { setWindowScrollPosition } from '../../../navigation/actions/navigation-actions';
import { fetchProjectDetails, setProjectToEdit } from '../../../project/actions/project-actions';

import GuestUserBar from '../../../footer-bar/components/guest-user-bar';

import '../styles/common-component.scss';

class CommonComponent extends Component {
  componentDidMount = () => {
    this.clearEditState();
    this.fetchProjectDetails();
  };

  componentDidUpdate = () => {
    this.clearEditState();
  };

  getRouteProps = firstChild => {
    if (!firstChild || !firstChild.props) return null;

    const { route } = firstChild.props;

    return { ...route };
  };

  clearEditState = () => {
    const { setProjectToEdit } = this.props;
    const { pathname } = this.props.location;

    if (routesConstants.routes.protectedRoutes.editProject.fullPath !== pathname) {
      setProjectToEdit(null);
    }
  };

  calculateSpacing = (includeSidebar, includeSpacing, navActive) => {
    if (includeSidebar) {
      let spacing = '';
      if (navActive) {
        spacing += ' nav-space';
      }
      if (includeSpacing) {
        spacing += ' left-space';
      }
      return spacing;
    }
    return '';
  };

  onScroll = e => {
    const { setWindowScrollPosition } = this.props;
    e = e && e.target && e.target.scrollTop;
    setWindowScrollPosition(e);
  };

  fetchProjectDetails = () => {
    const { location, projectDetails, fetchProjectDetails } = this.props;
    const { query } = location;
    const { ProjectID: detailsProjectID } = projectDetails;

    // this will not cause double call on entering the inspection, since component is mounted already
    // this is only for when entering diff project through link

    const URLProjectID = parseInt(get(query, 'project_id'));
    if (detailsProjectID && URLProjectID && URLProjectID !== detailsProjectID) {
      fetchProjectDetails(URLProjectID);
    }
  };

  render() {
    const { t } = this.context;
    const { navActive, userReducer, isLoading } = this.props;
    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        currentPath: this.props.currentPath,
      })
    );

    const children = reduce(childrenWithProps);
    const {
      titleComponent: TitleComponent = null,
      includeSidebar = null,
      includeSpacing = null,
      alignTitleCentral = null,
      pageTitle = null,
      rightMargin = null,
      persistFooter = null,
      visibleFor = null,
      isAllowed = true,
      parentComponentClass,
      titleComponentProps,
    } = this.getRouteProps(children) || {};

    const isGuest = Helpers.isGuestUser(userReducer);
    const isFooterBarOpened = isGuest || persistFooter ? 'footer-bar-opened' : '';
    const spacing = this.calculateSpacing(includeSidebar, includeSpacing, navActive);

    return (
      <div className={`common-component-container ${parentComponentClass}`}>
        {includeSidebar && <Navigation />}
        <div className={`common-component-wrapper ${isFooterBarOpened} ${spacing}`} style={{ marginRight: rightMargin }} onScroll={this.onScroll}>
          <AccessRenderer visibleFor={visibleFor}>
            {({ hasAccess }) => {
              return hasAccess && isAllowed ? (
                <>
                  {!isEmpty(pageTitle) && <h3 className={`${alignTitleCentral ? 'central-align' : ''} f-secondary-light main-title`}>{t(pageTitle)}</h3>}
                  {TitleComponent && <TitleComponent {...(titleComponentProps || {})} />}
                  {childrenWithProps}
                </>
              ) : (
                !isLoading && <routesConstants.routes.unProtectedRoutes.notFound.component />
              );
            }}
          </AccessRenderer>
        </div>

        <AccessRenderer visibleFor={visibleFor}>
          {({ hasAccess }) => {
            if (hasAccess && isGuest) return <GuestUserBar />;
          }}
        </AccessRenderer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  navActive: state.navReducer.opened,
  userReducer: state.userReducer,
  isLoading: state.loaderReducer.isLoading,
  projectDetails: state.projectDetailsReducer,
});

const mapDispatchToProps = dispatch => ({
  setProjectToEdit: value => dispatch(setProjectToEdit(value)),
  setWindowScrollPosition: value => dispatch(setWindowScrollPosition(value)),
  fetchProjectDetails: projectID => dispatch(fetchProjectDetails(projectID)),
});

CommonComponent.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CommonComponent);
