import constants from '../constants/register-constants';
import { EMAIL_REGEX, SPECIAL_CHAR_REGEX, PHONE_REGEX } from '../../../common/constants';

export const validate = values => {
  const errors = {};

  if (!values[constants.fields.companyName]) {
    errors[constants.fields.companyName] = 'CREATE_COMPANY.COMPANY_NAME_REQUIRED';
  }
  if (values[constants.fields.companyName] && values[constants.fields.companyName].length < 3) {
    errors[constants.fields.companyName] = 'CREATE_COMPANY.COMPANY_NAME_MIN_LENGTH';
  }
  if (values[constants.fields.companyName] && values[constants.fields.companyName].length >= 50) {
    errors[constants.fields.companyName] = 'CREATE_COMPANY.COMPANY_NAME_MAX_LENGTH';
  }
  if (!values[constants.fields.name]) {
    errors[constants.fields.name] = 'CREATE_COMPANY.COMPANY_USER_NAME_REQUIRED';
  }
  if (values[constants.fields.name] && values[constants.fields.name].length < 3) {
    errors[constants.fields.name] = 'CREATE_COMPANY.COMPANY_USER_NAME_MIN_LENGTH';
  }

  if (values[constants.fields.name] && values[constants.fields.name].length >= 50) {
    errors[constants.fields.name] = 'CREATE_COMPANY.COMPANY_USER_NAME_MAX_LENGTH';
  }

  if (values[constants.fields.name] && SPECIAL_CHAR_REGEX.test(values[constants.fields.name])) {
    errors[constants.fields.name] = 'CREATE_COMPANY.COMPANY_USER_NAME_SPECIAL_CHAR_NOT_ALLOWED';
  }

  if (!values[constants.fields.email]) {
    errors[constants.fields.email] = 'CREATE_COMPANY.CONTACT_USER_EMAIL_REQUIRED';
  }

  if (values[constants.fields.email] && !EMAIL_REGEX.test(values[constants.fields.email])) {
    errors[constants.fields.email] = 'CREATE_COMPANY.EMAIL_INVALID';
  }

  if (values[constants.fields.phone] && PHONE_REGEX.test(values[constants.fields.phone])) {
    errors[constants.fields.phone] = 'CREATE_PROJECT.CONTACT_PHONE_INVALID';
  }

  return errors;
};
