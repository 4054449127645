import { debounce, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import CustomSelect from '../../../../common/form/components/select';
import InfiniteScroll from '../../../../common/infinite-scroll/components/infinite-scroll';
import SearchInput from '../../../../common/input/components/search-input';
import NDTPointDetails from './ndt-point-details';

import { PERMISSIONS, PERMISSION_TYPES } from '../../../../common/permissions-constants';
import ndtConstants, {
  chartDateConfig,
  chartFilters,
  emptyStateIndicator,
  excessiveRateAlarms,
  graphTypeValues,
  measurementConstants,
  measurementPointConstants,
  perPage,
  sortingDirections,
} from '../../constants/ndt-constants';
import { lineChartStyle } from './constants/line-chart-styles';

import AccessRenderer from '../../../../common/access-renderer/components/access-renderer';
import Toggle from '../../../../common/form/components/toggle';
import Helpers from '../../../../common/helpers';
import TableComponent from '../../../../common/table/components/table-component';

import DatePicker from '../../../../common/form/components/date-picker';
import Tab from '../../../../common/tabs/component/tab';
import Tabs from '../../../../common/tabs/component/tabs';
import {
  addTempMeasurementPoint,
  getMeasurementPoints,
  getNDTChartDropdownsData,
  getNDTMainChartData,
  getPredictiveSettings,
  selectNDTMeasurementPoint,
  setChartFilter,
} from '../../actions/ndt-actions';
import { getNdtAlarms } from '../../actions/ndt-alarms-actions';
import { tabConstants } from './constants/ndt-modal-constants';
import NDTMainChart from './ndt-main-chart';
import PredictiveSettings from './predictive-settings';

import { selectNDTMeasurement } from '../../actions/inspection-actions';
import '../../styles/ndt-modal.scss';

class NDTModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartWrapper: null,
      alarms: [],
      sortingObj: {
        SortDirection: sortingDirections.desc,
        SortByColumn: measurementPointConstants.fields.measuredDate,
        PerPage: perPage,
        SearchText: '',
        HasNext: false,
        LastSeen: 0,
        chartOptions: lineChartStyle,
        chartData: [
          [emptyStateIndicator, ''],
          ['', 0],
        ],
      },
      selectedMeasurement: {},
    };
    this.filtersChangeDebounce = debounce(this.fetchChartData, 200);
    this.handleSearchDebounce = debounce(this.handleSearch, 300);
  }

  componentDidMount() {
    const { sortingObj } = this.state;
    const { getMeasurementPoints, closeModal, getChartDropdownsData, getNdtAlarms, getPredictiveSettings, selectMeasurement, measurementId } = this.props;

    // FETCH MEASUREMENT DETAILS
    selectMeasurement({ ID: measurementId }, null, selectedMeasurement => {
      this.setState({ selectedMeasurement });
    });

    if (measurementId) {
      getChartDropdownsData(measurementId);
      getNdtAlarms(measurementId, data => this.setState({ alarms: data.map(el => ({ ...el, active: true, color: el.Colour, label: el.AlarmType, alarmType: el.AlarmType, value: el.Value })) }));
      getMeasurementPoints(measurementId, sortingObj, data => this.setState({ sortingObj: { ...sortingObj, ...data } }));
      getPredictiveSettings(measurementId);
    } else {
      closeModal();
    }
  }

  componentDidUpdate(prevProps) {
    const { chartFiltersData, unit, measurementId } = this.props;
    if (chartFiltersData !== prevProps.chartFiltersData) {
      this.filtersChangeDebounce(measurementId, unit);
    }
  }

  componentWillUnmount() {
    this.filtersChangeDebounce.cancel();
  }

  fetchChartData = (measurementId, unit) => {
    const { getNDTChartData, chartFiltersData } = this.props;
    getNDTChartData(measurementId, unit, chartFiltersData);
  };

  getMeasurementPointsTableData = measurementPoints => {
    const { t } = this.context;
    const { selectedMeasurement } = this.state;
    const { selectedMeasurementPoint, inspectionId, unit, predictiveSettings } = this.props;
    if (!selectedMeasurementPoint) return measurementPoints;
    const countVisibleHeaders = (ndtConstants.pointsTableHeader(t, unit).filter(headerItem => !headerItem.visible) || []).length;
    const selectedIndex = measurementPoints.findIndex(measurementPoint => measurementPoint[measurementPointConstants.fields.id] === selectedMeasurementPoint[measurementPointConstants.fields.id]);
    if (selectedIndex > -1) {
      return [
        ...measurementPoints.slice(0, selectedIndex),
        {
          ...measurementPoints[selectedIndex],
          selected: true,
        },
        {
          CustomComponent: () => (
            <td colSpan={countVisibleHeaders} className="details">
              <NDTPointDetails selectedMeasurement={selectedMeasurement} inspectionId={inspectionId} predictiveSettings={predictiveSettings} />
            </td>
          ),
        },
        ...measurementPoints.slice(selectedIndex + 1),
      ];
    } else {
      return measurementPoints;
    }
  };

  mapChartData = (data, alarmLabels, alarmValues) => {
    //return default value to clear the chart from prevoius values
    if (!data || !data[0])
      return [
        [emptyStateIndicator, ''],
        ['', 0],
      ];
    const newData = [[...data[0], ...alarmLabels], ...data.slice(1).map(el => [...el, ...alarmValues])];

    return newData;
  };

  scrollTop = (timeout = 200, anim = false) => {
    setTimeout(() => {
      Helpers.scrollTop('section--scrollable', 0, anim);
    }, timeout);
  };

  customEmptyFunc = data => {
    return !data || !data[0] || data[0][0] === emptyStateIndicator;
  };

  onColumnSort = SortByColumn => {
    const { selectMeasurementPoint, selectedMeasurementPoint, getMeasurementPoints } = this.props;
    const { sortingObj, selectedMeasurement } = this.state;

    this.scrollTop(0);

    selectMeasurementPoint({}, selectedMeasurementPoint);

    const measurementId = selectedMeasurement[measurementConstants.fields.id];

    const newData = {
      ...sortingObj,
      SortDirection: sortingObj.SortDirection === sortingDirections.asc ? sortingDirections.desc : sortingDirections.asc,
      SortByColumn,
      LastSeen: 0,
      HasNext: false,
      SearchText: '',
    };
    this.setState({ sortingObj: { ...sortingObj, SearchText: '' } });

    getMeasurementPoints(measurementId, newData, data => {
      this.setState({ sortingObj: { ...newData, ...data } });
    });
  };

  loadMore = () => {
    const { selectMeasurementPoint, selectedMeasurementPoint, getMeasurementPoints, measurementPoints } = this.props;
    const { sortingObj, SearchText, selectedMeasurement } = this.state;

    selectMeasurementPoint({}, selectedMeasurementPoint);

    const measurementId = selectedMeasurement[measurementConstants.fields.id];

    const newData = {
      ...sortingObj,
      HasNext: false,
      SearchText,
    };

    this.setState({ sortingObj: newData });

    getMeasurementPoints(
      measurementId,
      newData,
      data => {
        this.setState({ sortingObj: { ...sortingObj, ...data } });
      },
      measurementPoints
    );
  };

  handleSearch = SearchText => {
    const { selectMeasurementPoint, selectedMeasurementPoint, getMeasurementPoints } = this.props;
    const { sortingObj, selectedMeasurement } = this.state;
    selectMeasurementPoint({}, selectedMeasurementPoint);

    const measurementId = selectedMeasurement[measurementConstants.fields.id];
    const newData = {
      ...sortingObj,
      LastSeen: 0,
      HasNext: false,
      SearchText,
    };

    getMeasurementPoints(measurementId, newData, data => {
      this.setState({ sortingObj: { ...{ ...sortingObj, SearchText }, ...data } });
    });
  };

  getFilteredAlarms = () => {
    const { alarms } = this.state;
    const {
      chartFiltersData: { [chartFilters.type]: selectedType, [chartFilters.group]: selectedGroup },
    } = this.props;
    const measurementValuesAlarms = ['HH', 'H', 'LL', 'L'];
    const rateAlarms = selectedGroup ? excessiveRateAlarms[selectedGroup] || [] : [];

    const visibleAlarmTypes = selectedType === graphTypeValues.excessiveRate ? rateAlarms : measurementValuesAlarms;
    const filteredAlarms = alarms.filter(alarm => {
      return alarm.Active && visibleAlarmTypes.indexOf(alarm.alarmType) > -1;
    });

    return filteredAlarms;
  };

  getChartConfig = () => {
    const { t } = this.context;
    const {
      colors,
      chartData,
      unit,
      chartFiltersData: { [chartFilters.type]: selectedType, [chartFilters.group]: selectedGroup },
    } = this.props;

    const filteredAlarms = this.getFilteredAlarms();

    const activeAlarms = filteredAlarms.filter(alarm => alarm.active);
    const alarmLabels = activeAlarms.map(alarm => t(`NDT_ALARMS_MODAL.FORM_LABELS_VALUE_${alarm.label}`, { unit: `${alarm.value} (${unit})` }));
    const alarmValues = activeAlarms.map(alarm => alarm.value);
    const alarmColors = activeAlarms.map(alarm => colors[alarm.color] || colors.severityGreen);
    const alarmSeries = {};
    const mappedChartData = this.mapChartData(chartData, alarmLabels, alarmValues);

    activeAlarms.forEach((alarm, index) => {
      const baseOptions = {
        pointsVisible: false,
        lineDashStyle: [8, 8],
      };
      alarmSeries[index - 1 + mappedChartData[0].length - activeAlarms.length] = {
        ...baseOptions,
      };
    });

    let optionalColors = [];
    const showMin = !Helpers.isLineChartEmpty(mappedChartData) && mappedChartData[0].findIndex(string => string.includes('Min')) > -1 ? true : false;
    const showMax = !Helpers.isLineChartEmpty(mappedChartData) && mappedChartData[0].findIndex(string => string.includes('Max')) > -1 ? true : false;
    const showPredictive = !Helpers.isLineChartEmpty(mappedChartData) && mappedChartData[0].findIndex(string => string.includes('Predictive')) > -1 ? true : false;
    if (showMin) {
      optionalColors.push(colors.severityRed);
    }
    if (showMax) {
      optionalColors.push(colors.severityGreen);
    }
    if (showPredictive) {
      optionalColors.push('#2150d1');
    }

    const viewWindow = Helpers.isLineChartEmpty(mappedChartData)
      ? {}
      : {
          min: mappedChartData && mappedChartData[1] ? mappedChartData[1][0] : 0,
          max: mappedChartData && mappedChartData.length <= 1000 ? mappedChartData[mappedChartData.length - 1][0] : mappedChartData[1000][0],
        };

    const options = {
      ...lineChartStyle,
      backgroundColor: colors.bacgroundColorLight,
      legend: {
        ...lineChartStyle.legend,
        textStyle: {
          color: colors.secondaryFontColorLight,
        },
      },
      series: {
        [optionalColors.length - 1]: {
          pointsVisible: false,
          curveType: showPredictive ? 'function' : 'none',
        },
        ...lineChartStyle.series,
        ...alarmSeries,
      },
      colors: [...optionalColors, colors.primaryFontColor, ...alarmColors],
      hAxis: {
        gridlines: {
          color: colors.boxItemsSeparatorColor,
        },
        minorGridlines: {
          color: colors.boxItemsThemeColor,
        },
        baselineColor: colors.boxItemsSeparatorColor,
        textStyle: {
          color: colors.secondaryFontColorLight,
        },
        viewWindow,
      },
      vAxis: {
        gridlines: {
          color: colors.boxItemsSeparatorColor,
        },
        minorGridlines: {
          color: colors.boxItemsThemeColor,
        },
        baselineColor: colors.boxItemsSeparatorColor,
        textStyle: {
          color: colors.secondaryFontColorLight,
        },
        titleTextStyle: {
          color: colors.secondaryFontColorLight,
        },
        title: (selectedType && t(`NDT_MODAL.V_AXIS_TITLE.${selectedType}`, { unit: selectedType === graphTypeValues.excessiveRate ? `${unit}/${selectedGroup}` : unit })) || '',
      },
      explorer: {
        actions: ['dragToPan', 'rightClickToReset'],
        axis: 'horizontal',
      },
      chartArea: {
        left: 60,
        width: '94%',
        height: '80%',
      },
      width: '100%',
    };

    return { chartOptions: options, chartData: mappedChartData };
  };

  toggleAlarm = alarmType => {
    const { alarms } = this.state;

    const newAlarms = [...alarms];
    const foundIndex = alarms.findIndex(el => el.alarmType === alarmType);
    if (foundIndex > -1) {
      newAlarms[foundIndex] = { ...newAlarms[foundIndex], active: !newAlarms[foundIndex].active };
      this.setState({ alarms: newAlarms });
    }
  };

  renderAlarmFilters = () => {
    const { t } = this.context;
    const filteredAlarms = this.getFilteredAlarms();

    return filteredAlarms.map((alarm, index) => (
      <div key={alarm.label} className="alarm-toggle">
        <p className="f-primary">{t(`NDT_ALARMS_MODAL.FORM_LABELS_VALUE_${alarm.label}`, { unit: '' })}</p>
        <Toggle value={alarm.active} onChange={() => this.toggleAlarm(alarm.alarmType)} className="actions__item" />
      </div>
    ));
  };

  renderGroupingTags = () => {
    const {
      groups,
      chartFiltersData: { [chartFilters.group]: selectedGroup },
      setChartFilter,
    } = this.props;
    const { selectedMeasurement } = this.state;
    const groupsDropdownData = (groups || []).map(el => ({ label: el.Value, value: el.Key }));
    const measurementId = selectedMeasurement ? selectedMeasurement[measurementConstants.fields.id] : null;

    return groupsDropdownData.map(tag => {
      const isActive = selectedGroup && selectedGroup === tag.value;
      return (
        <div key={tag.label} className={`grouping-tags__tag ${isActive ? 'active' : 'not-active'}`} onClick={!isActive ? () => setChartFilter(chartFilters.group, tag.value, measurementId) : null}>
          <span className="tag-text f-primary">{tag.label}</span>
        </div>
      );
    });
  };

  handleDateChange = (date, fieldName) => {
    const { setChartFilter } = this.props;
    const { selectedMeasurement } = this.state;
    const measurementId = selectedMeasurement ? selectedMeasurement[measurementConstants.fields.id] : null;
    //validate dates... start date must not be past end date
    if (fieldName === chartFilters.dateFrom) {
      let {
        chartFiltersData: { [chartFilters.dateTo]: endDate },
      } = this.props;
      const startDate = new Date(date).getTime();
      endDate = new Date(endDate).getTime();

      if (startDate > endDate) {
        setChartFilter(chartFilters.dateTo, startDate, measurementId);
      }
    }

    if (fieldName === chartFilters.dateTo) {
      let {
        chartFiltersData: { [chartFilters.dateFrom]: startDate },
      } = this.props;
      const endDate = new Date(date).getTime();
      startDate = new Date(startDate).getTime();

      if (startDate > endDate) {
        setChartFilter(chartFilters.dateFrom, endDate, measurementId);
      }
    }

    const dateToSave = new Date(date);
    setChartFilter(fieldName, dateToSave, measurementId);
  };

  render() {
    const { t } = this.context;
    const {
      sortingObj,
      sortingObj: { SearchText, HasNext },
      selectedMeasurement,
    } = this.state;
    const {
      selectMeasurementPoint,
      selectedMeasurementPoint,
      addTempMeasurementPoint,
      measurementPoints,

      types,
      chartFiltersData: { [chartFilters.group]: selectedGroup, [chartFilters.type]: selectedType, [chartFilters.dateTo]: selectedDateTo, [chartFilters.dateFrom]: selectedDateFrom },
      chartFiltersData,
      setChartFilter,
      unit,
      predictiveSettings,
      getPredictiveSettings,
      loadingPredictiveResults,
      ndtPredictiveResults,
    } = this.props;

    const typesDropdownData = (types || []).map(el => ({ label: el.Value, value: el.Key }));

    const tableData = this.getMeasurementPointsTableData(measurementPoints);

    const dateConfig = chartDateConfig[selectedGroup] || chartDateConfig.default;

    const { chartOptions, chartData } = this.getChartConfig();
    const measurementId = selectedMeasurement ? selectedMeasurement[measurementConstants.fields.id] : null;

    return (
      <div className="ndt-modal-content">
        <div className="section">
          <div className="ndt-modal-content__header">
            <h5 className="f-primary" title={selectedMeasurement[measurementConstants.fields.name]}>
              {selectedMeasurement[measurementConstants.fields.name] || ''}
            </h5>
            <div className="filter-wrapper">
              <div className="ndt-modal-content__filter">
                <p className="f-secondary-dark">{t('NDT_MODAL.FILTER_DATE_FROM')}</p>
                <DatePicker
                  disabledKeyboardNavigation={true}
                  {...dateConfig}
                  selected={selectedDateFrom}
                  onChange={d => this.handleDateChange(d, chartFilters.dateFrom)}
                  popperPlacement="bottom-end"
                  onCalendarClose={this.handleOnClose}
                />
              </div>
              <div className="ndt-modal-content__filter">
                <p className="f-secondary-dark">{t('NDT_MODAL.FILTER_DATE_TO')}</p>
                <DatePicker
                  disabledKeyboardNavigation={true}
                  {...dateConfig}
                  selected={selectedDateTo}
                  onChange={d => this.handleDateChange(d, chartFilters.dateTo)}
                  popperPlacement="bottom-end"
                  onCalendarClose={this.handleOnClose}
                />
              </div>
              <div className="ndt-modal-content__filter">
                <p className="f-secondary-dark">{t('NDT_MODAL.FILTER_ALARM_TYPE')}</p>
                <CustomSelect onChange={({ value }) => (measurementId ? setChartFilter(chartFilters.type, value, measurementId) : null)} data={typesDropdownData} defaultValue={selectedType} />
              </div>
            </div>
          </div>
        </div>
        <Tabs>
          <Tab title={tabConstants.graph.title} tabKey={tabConstants.graph.tabKey}>
            <NDTMainChart
              chartOptions={chartOptions}
              chartData={chartData}
              isEmptyData={this.customEmptyFunc}
              renderAlarmFilters={this.renderAlarmFilters}
              graphType={selectedType}
              renderGroupingTags={this.renderGroupingTags}
              getChartData={() => (measurementId ? this.fetchChartData(measurementId, unit) : null)}
            />
          </Tab>
          <Tab title={tabConstants.points.title} tabKey={tabConstants.points.tabKey}>
            <div className="section">
              <div className="ndt-modal-content__header horizontal-padding points-header">
                <h6 className="f-primary">{t('NDT_MODAL.MEASUREMENT_POINTS_TITLE')}</h6>
                <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.ndt].createMeasurementPoint.name}>
                  {({ hasAccess }) => {
                    return (
                      <p
                        className={`f-secondary-green link${hasAccess ? '' : '--disabled'}`}
                        onClick={
                          hasAccess
                            ? () => {
                                addTempMeasurementPoint({}, measurementPoints, () => this.scrollTop(0, true));
                              }
                            : null
                        }
                      >
                        {t('NDT_MODAL.CREATE_NEW_MEASUREMENT_POINT')}
                      </p>
                    );
                  }}
                </AccessRenderer>
              </div>
              <div className="ndt-modal-content__header horizontal-padding">
                <SearchInput
                  value={SearchText}
                  onChange={e => {
                    this.scrollTop(0);
                    this.setState({ sortingObj: { ...sortingObj, SearchText: e.target.value } });
                    this.handleSearchDebounce(e.target.value);
                  }}
                  placeholder={t('NDT_ALARMS.SEARCH.PLACEHOLDER')}
                />
              </div>
              {isEmpty(tableData) ? (
                <div className="ndt-points-empty">
                  <p className="f-primary  horizontal-padding">{t('NDT_MODAL.MEASUREMENT_POINTS.EMPTY_PLACEHOLER')}</p>
                </div>
              ) : (
                <InfiniteScroll loadMoreFunction={HasNext ? this.loadMore : null}>
                  <div className="section--scrollable">
                    <TableComponent
                      tableConfig={ndtConstants.pointsTableHeader(t, unit)}
                      data={tableData || []}
                      tableCustomClass="ndt-points-table"
                      translationModule={t}
                      formatCell={Helpers.formatCell}
                      onColumnSort={this.onColumnSort}
                      sortingObj={sortingObj}
                      onRowClick={(e, item) => {
                        if (selectedMeasurementPoint && selectedMeasurementPoint[measurementPointConstants.fields.id] === item[measurementPointConstants.fields.id]) {
                          selectMeasurementPoint({}, selectedMeasurementPoint);
                        } else {
                          selectMeasurementPoint(item, selectedMeasurementPoint);
                        }
                      }}
                      stickyHeader={true}
                    />
                  </div>
                </InfiniteScroll>
              )}
            </div>
          </Tab>
          <Tab title={tabConstants.predictiveSettings.title} tabKey={tabConstants.predictiveSettings.tabKey}>
            <PredictiveSettings
              predictiveSettings={predictiveSettings}
              getPredictiveSettings={() => getPredictiveSettings(selectedMeasurement[measurementConstants.fields.id])}
              ndtPredictiveResults={ndtPredictiveResults}
              loadingPredictiveResults={loadingPredictiveResults}
              measurementId={selectedMeasurement && selectedMeasurement[measurementConstants.fields.id] ? selectedMeasurement[measurementConstants.fields.id] : null}
              unit={unit}
              chartFiltersData={chartFiltersData}
            />
          </Tab>
        </Tabs>
      </div>
    );
  }
}

NDTModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  colors: state.themeReducer.severityColors,
  selectedMeasurementPoint: state.ndtReducer.selectedMeasurementPoint,
  measurementPoints: state.ndtReducer.measurementPoints,
  chartFiltersData: state.ndtReducer.ndtChartFilters,
  types: state.ndtReducer.chartTypes,
  groups: state.ndtReducer.chartGroups,
  alarms: state.ndtReducer.ndtAlarms,
  chartData: state.ndtReducer.ndtMainChartData,
  loadingPredictiveResults: state.ndtReducer.loadingPredictiveResults,
  ndtPredictiveResults: state.ndtReducer.ndtPredictiveResults,
  predictiveSettings: state.ndtReducer.predictiveSettings,
});

const mapDispatchToProps = dispatch => ({
  getMeasurementPoints: (measurementId, filterData, callback, currentPoints) => dispatch(getMeasurementPoints(measurementId, filterData, callback, currentPoints)),
  selectMeasurement: (data, currentMeasurement, callback) => dispatch(selectNDTMeasurement(data, currentMeasurement, callback, false)),
  selectMeasurementPoint: (data, currentMeasurementPoint) => dispatch(selectNDTMeasurementPoint(data, currentMeasurementPoint)),
  addTempMeasurementPoint: (data, currentMeasurementPoints, callback) => dispatch(addTempMeasurementPoint(data, currentMeasurementPoints, callback)),
  getChartDropdownsData: measurementId => dispatch(getNDTChartDropdownsData(measurementId)),
  setChartFilter: (key, value, measurementId) => dispatch(setChartFilter(key, value, measurementId)),
  getNdtAlarms: (measurmentId, callback) => dispatch(getNdtAlarms(measurmentId, callback)),
  getNDTChartData: (measurementId, unit, chartFiltersData, callback) => dispatch(getNDTMainChartData(measurementId, unit, chartFiltersData, callback)),
  getPredictiveSettings: measurementId => dispatch(getPredictiveSettings(measurementId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NDTModal);
