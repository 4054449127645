import React from 'react';
import PropTypes from 'prop-types';
import { values, filter } from 'lodash';

import FilesDisplay from './files-display';
import { fields } from '../../constants/isolation-components-constants';

import '../../../../../styles/files-manipulation.scss';

const IsolationComponentsItem = ({ component = {} }, { t }) => {
  let componentFields = values(fields);
  componentFields = filter(componentFields, { showInDisplay: true });

  const getValue = field => {
    if (component[field.name]) {
      return field.name === fields.order.name ? `${component[field.name]}.` : component[field.name];
    }

    return '-';
  };

  return (
    <div className="isolation-component-item-container">
      {componentFields.map(field => (
        <div className="isolation-component-item" key={`component-item-${component[fields.componentId.name]}-${field.name}`}>
          <p className="f-secondary-dark">{t(field.label)}</p>
          <p className="f-primary">{getValue(field)}</p>
        </div>
      ))}
      <div className="files-items">
        <FilesDisplay title={component[fields.files.name] && component[fields.files.name].length > 0 ? 'ISOLATION_CERTIFICATE.FILES' : ''} files={component[fields.files.name]} />
      </div>
    </div>
  );
};

IsolationComponentsItem.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default IsolationComponentsItem;
