import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import GuestUserForm from './guest-user-message';
import Helpers from '../../../common/helpers';
import routesConstants from '../../../common/routes-constants';
import { isEmpty } from 'lodash';

import BasicWrapper from '../../shared/common-component/components/basic-wrapper';
import { login } from '../../login/actions/login-actions';

import Modal from '../../../common/modal/components/modal';

import CustomLabel from '../../reset-password/components/custom-label';

import TermsAndConditionsFrom from '../../reset-password/components/terms-and-conditions';
import DataPolicy from '../../reset-password/components/data-policy';

class GuestUser extends Component {
  state = {
    token: null,
    params: null,
    modalData: {
      isOpen: false,
    },
  };

  handleSubmit = () => {
    const { token, params } = this.state;
    const { login } = this.props;
    login(token, params);
  };

  componentDidMount = () => {
    const { query } = this.props.location;

    if (isEmpty(query)) {
      Helpers.goTo(routesConstants.routes.unProtectedRoutes.login.fullPath);
    }

    const { token } = query;

    if (!token) {
      Helpers.goTo(routesConstants.routes.unProtectedRoutes.login.fullPath);
    }

    const params = Helpers.composeQueryParamsArray(query);

    this.setState({ token });
    this.setState({ params });
  };

  openTermsAndConditionsModal = e => {
    e.preventDefault();
    this.setState(prevProps => ({
      modalData: {
        ...prevProps.modalData,
        isOpen: true,
        CustomContent: () => <TermsAndConditionsFrom />,
        confirmAction: this.closeModal,
        closeAction: this.closeModal,
      },
    }));
  };
  openDataPolicyModal = e => {
    e.preventDefault();
    this.setState(prevProps => ({
      modalData: {
        ...prevProps.modalData,
        isOpen: true,
        CustomContent: () => <DataPolicy />,
        confirmAction: this.closeModal,
        closeAction: this.closeModal,
      },
    }));
  };
  closeModal = () => {
    this.setState(prevProps => ({
      modalData: {
        ...prevProps.modalData,
        isOpen: false,
      },
    }));
  };

  render() {
    const { t } = this.context;
    const { modalData } = this.state;
    const { openTermsAndConditionsModal, openDataPolicyModal } = this;
    return (
      <BasicWrapper>
        <GuestUserForm
          onSubmit={this.handleSubmit}
          CustomLabel={
            <CustomLabel
              labels={[
                {
                  key: t('GUEST_USER.TERMS_AND_CONDITIONS_1'),
                  action: null,
                },
                {
                  key: t('GUEST_USER.TERMS_AND_CONDITIONS_2'),
                  action: openTermsAndConditionsModal,
                },
                {
                  key: t('GUEST_USER.TERMS_AND_CONDITIONS_3'),
                  action: null,
                },
                {
                  key: t('GUEST_USER.TERMS_AND_CONDITIONS_4'),
                  action: openDataPolicyModal,
                },
              ]}
            />
          }
        />
        <Modal {...modalData} />
      </BasicWrapper>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({ login: (token, params) => dispatch(login(null, token, params)) });

GuestUser.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestUser);
