import PropTypes from 'prop-types';
import React from 'react';
import { FieldArray, reduxForm } from 'redux-form';

import { FORMS } from '../../../../../../common/constants';
import Button from '../../../../../../common/form/components/button';
import { formConstants, steps } from '../../constants/measurement-point-constants';
import '../../styles/create-measurement-point-form.scss';
import { measurementPointValidate } from '../../validators/create-measurement-point-validator';
import AlarmsFormFields from '../alarms/alarms-form-fields';
import MeasurementPointDetailsFormFields from './measurement-point-details-form-fields';

const CreateMeasurementPointForm = (props, { t }) => {
  const {
    handleSubmit,
    activeStep,
    lastStep,
    stepAction,
    closeCreateMeasurementPointModal,
    selectedComponent,
    handleOnComponentSelect,
    fetchComponents,
    inspectionId,
    formValues,
    alarmDescriptors,
    componentPickerAdditionalOptions,
    invalid,
    defaultComponent,
    fetchUnits,
    projectID,
    changeField,
  } = props;

  return (
    <form
      className="create-measurement-point-form"
      onSubmit={e => {
        e?.preventDefault();
        e?.stopPropagation();
        handleSubmit(e);
      }}
      noValidate
    >
      <div className="create-measurement-point-form__content">
        {activeStep === steps.firstStep && (
          <MeasurementPointDetailsFormFields
            isPreviewMode={false}
            fetchUnits={fetchUnits}
            inspectionId={inspectionId}
            changeField={changeField}
            projectID={projectID}
            fetchComponents={fetchComponents}
            selectedComponents={selectedComponent}
            onComponentSelect={handleOnComponentSelect}
            componentPickerAdditionalOptions={componentPickerAdditionalOptions}
            formValues={formValues}
          />
        )}
        {activeStep === steps.secondStep && (
          <FieldArray
            name={formConstants.fields.alarms}
            id={formConstants.fields.alarms}
            component={AlarmsFormFields}
            formValues={formValues}
            alarmDescriptors={alarmDescriptors}
            defaultComponent={defaultComponent}
          />
        )}
      </div>
      <div className="buttons">
        <Button type="button" variant="gray-outline" width="sm" height="md" text={t('CREATE_WORKORDER_FORM.CANCEL')} onClick={closeCreateMeasurementPointModal} />
        <div className="right-buttons">
          {activeStep !== steps.firstStep ? (
            <Button type="button" variant="gray-outline" width="sm" disabled={activeStep === steps.firstStep} height="md" text={t('CREATE_WORKORDER_FORM.BACK')} onClick={() => stepAction(false)} />
          ) : null}
          <Button height="md" className="button-submit" type="submit" width="sm" disabled={invalid} text={t(activeStep === lastStep ? 'CONTRACTOR_FORM.CREATE' : 'CREATE_WORKORDER_FORM.NEXT')} />
        </div>
      </div>
    </form>
  );
};

CreateMeasurementPointForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

CreateMeasurementPointForm.propTypes = {
  // Define prop types here
};

export default reduxForm({
  form: FORMS.createMeasurementPointForm,
  enableReinitialize: true,
  validate: measurementPointValidate,
})(CreateMeasurementPointForm);
