import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getFormSyncErrors, reduxForm } from 'redux-form';
import { FORMS } from '../../../../../common/constants';
import Button from '../../../../../common/form/components/button';
import CustomCheckBox from '../../../../../common/form/components/custom-checkbox';
import SearchInput from '../../../../../common/input/components/search-input';
import { fields } from '../../../constants/constants';
import dmsHelpers from '../../../helpers/dms-helpers';
import '../../../styles/_dms-change-category.scss';
import { validateChangeCategory as validate } from '../../../validators/form-validators';

const ChangeCategoryForm = ({ handleSubmit, onSubmit, categoriesList, searchInputLabel, defaultDMSCategoryIDBackup, customCloseAction, formErrors }, { t }) => {
  const [stateCategoriesList, setStateCategoriesList] = useState(categoriesList); // added only for the purposes of the filtering by name on the FE side
  const [selectedCategories, setSelectedCategories] = useState([]);

  const onFormSubmit = () => onSubmit(selectedCategories);

  return (
    <form noValidate className="change-category-form-wrapper" onSubmit={handleSubmit(onFormSubmit)}>
      <label className="question-field__label">{t(searchInputLabel || 'CATEGORY_SINGULAR')}</label>
      <SearchInput
        onChange={e => dmsHelpers.filterCategoriesByName(e.target.value, stateCategoriesList, categoriesList, setStateCategoriesList)} // should we debounce by 300ms?
        placeholder={dmsHelpers.getSearchInputValue(selectedCategories, t)}
        wrapperClass={dmsHelpers.getSearchInputValue(selectedCategories, t) !== t('SEARCH') ? 'highlight-placeholder' : ''}
        includeDropdown={true}
        keepDropdownVisible={true}
        items={stateCategoriesList}
        emptyStateLabel={'NO_CATEGORIES_FOUND'}
        isDropdownDataLoading={false}
        shouldRenderPortal={true}
        portalProps={{
          id: 'search-dropdown-items',
        }}
        renderItem={(category, categoryIndex) => {
          return (
            <div
              className="category-item-wrapper"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                dmsHelpers.handleCategorySelect(category, selectedCategories, defaultDMSCategoryIDBackup, setSelectedCategories, t);
              }}
              key={`category-${categoryIndex}`}
            >
              <CustomCheckBox
                meta={{}}
                input={{
                  value: selectedCategories.findIndex(c => c[fields.id] === category[fields.id]) > -1,
                  onChange: e => {
                    e.stopPropagation();
                    dmsHelpers.handleCategorySelect(category, selectedCategories, defaultDMSCategoryIDBackup, setSelectedCategories, t);
                  },
                }}
                id={`row-${categoryIndex}`}
              />
              <p id={`row-${categoryIndex}`} className="pointer">
                {category[fields.name]}
              </p>
            </div>
            /**
             *   Note: for future improvement, Load More can be added here to handle larger number of categories,
             * which would imply that search would then happen on the API side
             */
          );
        }}
      />
      <div id="search-dropdown-items" />

      <div className="form-buttons">
        <Button onClick={customCloseAction} type="button" text={t('CANCEL')} variant="gray-outline" className="modal-button-custom h-md" />
        <Button disabled={(formErrors && !isEmpty(formErrors)) || selectedCategories?.length === 0} text={t('CONFIRM')} variant="success" className="modal-button-custom h-md" />
      </div>
    </form>
  );
};

ChangeCategoryForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

ChangeCategoryForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  categoriesList: PropTypes.array.isRequired,
  defaultDMSCategoryIDBackup: PropTypes.number,
};

ChangeCategoryForm.defaultProps = {
  handleSubmit: () => null,
  categoriesList: [],
};

const mapStateToProps = state => ({
  formErrors: getFormSyncErrors(FORMS.changeCategoryForm)(state),
});

export default connect(null, mapStateToProps)(reduxForm({ form: FORMS.changeCategoryForm, validate })(ChangeCategoryForm));
