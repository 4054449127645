import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';

import { FORMS } from '../../../common/constants';

import Button from '../../../common/form/components/button';

import '../styles/guest-user-message.scss';

class GuestUserForm extends Component {
  render() {
    const { t } = this.context;
    const { handleSubmit, CustomLabel } = this.props;
    return (
      <div className="guset-user-message">
        <form onSubmit={handleSubmit} noValidate>
          <div className="content">
            <h3>{t('GUEST_USER.TITLE')}</h3>
            <hr />
            <h5>{t('GUEST_USER.SUBTITLE')}</h5>
            <p> {t('GUEST_USER.MESSAGE')}</p>
          </div>
          <Button text={t('GUEST_USER.SUBMIT BUTTON')} />
          <div className="message">{CustomLabel}</div>
        </form>
      </div>
    );
  }
}

GuestUserForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

GuestUserForm = reduxForm({
  form: FORMS.guestUserCheck,
})(GuestUserForm);

export default GuestUserForm;
