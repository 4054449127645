import actionTypes from '../constants/action-types.js';
import { defaultFilter, formConstants } from '../constants/measurement-location-constants.js';

const measurementPointState = {
  measurementPoints: [],
  measurementPointsClustered: [],
  measurementPointsFilter: defaultFilter,
  measurementPointsLoading: false,
  measurementPointFormState: {
    hasUnsavedCustomProps: false,
    unsavedCustomProps: [],
    requestInProgress: false,
  },
  measurementPointLocationObjectEditing: false,
  measurementPointLocationObjectAdding: false,
  areAllMeasurementPointsHidden: false,
  measurementPointDetailsLoading: false,
};

export const measurementPointReducer = (state = measurementPointState, payload) => {
  switch (payload.type) {
    case actionTypes.SELECT_ALL_MEASUREMENT_POINTS:
      return {
        ...state,
        areAllMeasurementPointsHidden: false,
        measurementPoints: state.measurementPoints.map(el => {
          return { ...el, visible: true };
        }),
        measurementPointsClustered: state.measurementPointsClustered.map(el => {
          return { ...el, visible: true };
        }),
      };
    case actionTypes.DESELECT_ALL_MEASUREMENT_POINTS:
      return {
        ...state,
        areAllMeasurementPointsHidden: true,
        measurementPoints: state.measurementPoints.map(el => {
          return { ...el, visible: false };
        }),
        measurementPointsClustered: state.measurementPointsClustered.map(el => {
          return { ...el, visible: false };
        }),
      };
    case actionTypes.TOGGLE_MEASUREMENT_POINT:
      return {
        ...state,
        measurementPoints: state.measurementPoints.map(el => {
          return { ...el, visible: el[formConstants.fields.id] === payload.data ? !el.visible : el.visible };
        }),
      };
    case actionTypes.SET_MEASUREMENT_POINTS:
      return {
        ...state,
        measurementPoints: payload.data.map(el => ({ ...el, visible: !state.areAllMeasurementPointsHidden })),
      };
    case actionTypes.AMEND_MEASUREMENT_POINTS:
      return {
        ...state,
        measurementPoints: [...state.measurementPoints, ...payload.data.map(el => ({ ...el, visible: !state.areAllMeasurementPointsHidden }))],
      };
    case actionTypes.SET_MEASUREMENT_POINTS_FILTER:
      return { ...state, measurementPointsFilter: payload.data };
    case actionTypes.SET_MEASUREMENT_POINTS_LOADING:
      return {
        ...state,
        measurementPointsLoading: payload.data,
      };
    case actionTypes.SET_MEASUREMENT_POINT_FORM_STATE:
      return {
        ...state,
        measurementPointFormState: {
          ...state.measurementPointFormState,
          ...payload.data,
        },
      };
    case actionTypes.SET_MEASUREMENT_POINTS_CLUSTERED:
      return {
        ...state,
        measurementPointsClustered: payload.data.map(el => ({ ...el, visible: true })),
      };
    case actionTypes.SET_MEASUREMENT_POINT_LOCATION_OBJECT_EDITING:
      return { ...state, measurementPointLocationObjectEditing: payload.data };
    case actionTypes.SET_MEASUREMENT_POINT_LOCATION_OBJECT_ADDING:
      return { ...state, measurementPointLocationObjectAdding: payload.data };
    case actionTypes.UPDATE_MEASUREMENT_POINT_SUCCESS:
      return {
        ...state,
        measurementPoints: payload.data,
        measurementPointsClustered: payload.clusters,
      };
    case actionTypes.SET_MEASUREMENT_POINT_DETAILS_LOADING:
      return {
        ...state,
        measurementPointDetailsLoading: payload.data,
      };
    default:
      return state;
  }
};
