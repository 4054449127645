import ndtActions from '../../../api/ndt-api/actions';
import { setNdtAlarms, setDescriptorData, setNdtEmails, addAlarmEmailIntoArray, removeAlarmEmailFromArray } from '../actions/action-creators';

import { map } from 'lodash';

export const getNdtAlarms = (MeasurementID, callbackSuccess, includeEmails) => {
  return async dispatch => {
    const res = await ndtActions.getAlarms({ MeasurementID });

    const { Data } = res.data;

    dispatch(setNdtAlarms(Data));
    if (includeEmails) {
      await dispatch(fetchNdtEmailsRecursively(Data));
    }

    callbackSuccess && callbackSuccess(Data || []);
  };
};

export const getDescriptorData = () => {
  return async dispatch => {
    const res = await ndtActions.getDescriptorData();

    const { Data } = res.data;

    const dataToSave = map(Data, item => {
      return {
        label: item.Description,
        value: item.ID,
      };
    });

    dispatch(setDescriptorData(dataToSave));
  };
};

export const saveAlarms = (MeasurementID, Alarms, callback) => {
  return async dispatch => {
    await ndtActions.saveAlarms({ MeasurementID, Alarms });
    callback && callback();
  };
};

const fetchNdtEmailsRecursively = (elements, index = 0, returnObj = {}) => {
  return async dispatch => {
    const currentElement = elements && elements[index];
    if (currentElement) {
      const res = await ndtActions.getNdtEmails([{ alarm_id: currentElement.ID }]);
      returnObj[currentElement.AlarmType] = res.data.Data || [];

      dispatch(fetchNdtEmailsRecursively(elements, ++index, returnObj));
    } else {
      dispatch(setNdtEmails(returnObj));
    }
  };
};

export const addAlarmEmail = (Email, AlarmID, type, callback) => {
  return async dispatch => {
    const res = await ndtActions.addAlarmEmail({ AlarmID, Email });
    callback && callback();
    dispatch(addAlarmEmailIntoArray(res.data.Data, type));
  };
};

export const removeAlarmEmail = (AlarmEmailID, type) => {
  return async dispatch => {
    await ndtActions.removeAlarmEmail({ AlarmEmailID });

    dispatch(removeAlarmEmailFromArray(AlarmEmailID, type));
  };
};

export const updateAlarmsNotifications = (alarms, successCallback) => {
  return async dispatch => {
    await ndtActions.updateAlarmsNotifications(alarms);

    successCallback && successCallback();
  };
};
