import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { debounce, pick } from 'lodash';
import SettingsForm from './inspection-settings-form';
import { updateInspectionSettings } from '../../actions/action-creators';
import { updateInspectionDetails } from '../../actions/inspection-actions';
import { changeUser360Settings } from '../../../profile/actions/profile-actions';

import { selectAllCameraThumbnails, deselectAllCameraThumbnails, selectAllCameraPositions, deselectAllCameraPositions } from '../../../start-workflow/actions/action-creators';
import { userSettings, formConstants } from '../../constants/inspection-settings';

import '../../styles/inspection-settings.scss';

class Settings extends Component {
  constructor(props) {
    super(props);

    this.formChangeDebounce = debounce(this.submitForm, 100);
  }
  componentWillUnmount() {
    this.formChangeDebounce.cancel();
  }

  submitForm = values => {
    const { updateInspectionDetails, changeUser360Settings } = this.props;
    const userSettingsProperties = Object.values(userSettings.fields);
    const inspectionSettingsProperties = Object.values(formConstants.fields);
    const userSettingsValues = pick(values, userSettingsProperties);
    const inspectionSettingsValues = pick(values, inspectionSettingsProperties);

    changeUser360Settings({ ...userSettingsValues });
    updateInspectionDetails(inspectionSettingsValues);
  };

  handleChange = values => {
    const { updateInspectionSettings, changeUser360Settings, user } = this.props;
    const userSettingsProperties = Object.values(userSettings.fields);
    const inspectionSettingsProperties = Object.values(formConstants.fields);
    const userSettingsValues = pick(values, userSettingsProperties);
    const inspectionSettingsValues = pick(values, inspectionSettingsProperties);

    changeUser360Settings({ ...user.Setting360View, ...userSettingsValues });
    updateInspectionSettings({
      ...inspectionSettingsValues,
    });
    this.formChangeDebounce(values);
  };

  selectAllCameras = () => {
    const { selectAllCameras } = this.props;
    selectAllCameras();
  };

  deselectAllCameras = () => {
    const { deselectAllCameras } = this.props;
    deselectAllCameras();
  };

  render() {
    const { t } = this.context;
    const { user, isWorkflow, selectAllCameraThumbnails, deselectAllCameraThumbnails, is3DViewModeActive } = this.props;

    return (
      <div className="inspection-settings">
        <h5 className="f-primary inspection-settings__title padding">{t('INSPECTION_SETTINGS')}</h5>
        <SettingsForm
          onChange={this.handleChange}
          onSubmit={this.submitForm}
          isWorkflow={isWorkflow}
          selectAllCameras={this.selectAllCameras}
          deselectAllCameras={this.deselectAllCameras}
          selectAllCameraThumbnails={selectAllCameraThumbnails}
          deselectAllCameraThumbnails={deselectAllCameraThumbnails}
          user={user}
          is3DViewModeActive={is3DViewModeActive}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({ user: state.userReducer, is3DViewModeActive: state.inspectionReducer.is3DViewModeActive });

const mapDispatchToProps = dispatch => ({
  updateInspectionSettings: data => dispatch(updateInspectionSettings(data)),
  updateInspectionDetails: data => dispatch(updateInspectionDetails(data)),
  selectAllCameras: () => dispatch(selectAllCameraPositions()),
  deselectAllCameras: () => dispatch(deselectAllCameraPositions()),
  selectAllCameraThumbnails: () => dispatch(selectAllCameraThumbnails()),
  deselectAllCameraThumbnails: () => dispatch(deselectAllCameraThumbnails()),
  changeUser360Settings: data => dispatch(changeUser360Settings(data)),
});

Settings.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
