import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import { capitalize, chain, debounce, find, isArray, isEmpty, isString, keys, map, reduce, values } from 'lodash';

import DisplayStatus from '../../../../common/display-status/components/display-status';
import Icon from '../../../../common/icon/components/icon';
import LoadMore from '../../../../common/load-more/components/load-more';
import StatusesFilter from '../../../../common/statuses-filter/components/statuses-filter';

import ItemsTableRenderer from '../../../../common/items-renderer/components/items-table-renderer';
import RenderIf from '../../../../common/render-if/components/render-if';
import CreateWorkOrderModal from '../work-order/create-work-order-modal';
import ModuleHeader from './common/module-header';
import SearchText from './common/search-text';
import WorkordersFilter from './filters/workorders-filter';

import Helpers from '../../../../common/helpers';
import { PERMISSION_TYPES, PERMISSIONS } from '../../../../common/permissions-constants';
import {
  defaultPagingObj,
  defaultQuickFilters,
  defaultSortingFilter,
  filterFields,
  formConstants,
  priorities,
  severityConstants,
  sortDirections,
  statuses,
  tableConfig,
} from '../../constants/work-order-constants';
import { getSelectedStatuses } from '../work-order/helpers/work-order-helpers';

import { connect } from 'react-redux';
import { destroy } from 'redux-form';
import { FORMS } from '../../../../common/constants';
import ReadMoreTooltip from '../../../../common/read-more-tooltip/components/read-more-tooltip';
import { DMSCategoriesModules } from '../../../document-management/constants/constants';
import { teamFields } from '../../../members/constants/team-constants';
import { fetchDMSCategories } from '../../../project/actions/project-actions';
import { assigneeTypes } from '../../constants/defect-constants';
import '../../styles/work-order-list.scss';
import { assigneeFields } from '../work-order/constants/constants';
import { deleteAdvancedFiltersFromFilters } from '../work-orders/helpers/work-orders-helpers';

class WorkOrders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      advancedFilters: {},
      sortingFilter: defaultSortingFilter,
    };
    this.searchChangeDebounced = debounce(this.getWorkOrders, 250);
  }

  componentDidMount = () => {
    const { queryItem, fetchDMSCategories, projectId } = this.props;
    let params = {};

    if (queryItem) {
      params = {
        ...params,
        [filterFields.includedIDs]: [queryItem],
      };
    }
    this.getWorkOrders(params);
    fetchDMSCategories(projectId, DMSCategoriesModules.workOrders);
  };

  componentDidUpdate = prevProps => {
    const { searchText, setWorkOrderPaging } = this.props;

    if (isString(searchText) && prevProps.searchText !== searchText) {
      setWorkOrderPaging(defaultPagingObj);
      this.searchChangeDebounced();
    }
  };

  componentWillUnmount = () => {
    const { setWorkOrderFilters, setWorkOrderPaging } = this.props;

    // TODO: this still does not reset quick filters ¯\_(ツ)_/¯
    setWorkOrderFilters(defaultQuickFilters);
    setWorkOrderPaging(defaultPagingObj);
  };

  getWorkOrders = (params, loadMore = false) => {
    const { sortingFilter } = this.state;
    const { getWorkOrders, searchText, filters, pagingObject, setWorkOrderPaging } = this.props;

    if (params && params[filterFields.linkedToMe]) {
      // Linked to me quick filter is active, add current user to assignees and collaborators advanced filters
      // Add assignee
      params[filterFields.assignees] = this.addCurrentUserToAdvancedFilter(params[filterFields.assignees], assigneeFields);
      // Add collaborator
      params[filterFields.collaborators] = this.addCurrentUserToAdvancedFilter(params[filterFields.collaborators], assigneeFields);
    }

    getWorkOrders({ ...filters, ...pagingObject, ...sortingFilter, ...params, [filterFields.searchText]: searchText }, loadMore, (arr, incomingFilter, c) => {
      setWorkOrderPaging({ ...pagingObject, [filterFields.totalItems]: incomingFilter[filterFields.totalItems], [filterFields.hasNext]: incomingFilter[filterFields.hasNext] });

      if (loadMore) {
        Helpers.scrollIntoView('table-container', `row-${arr?.length - 1}`);
      }
    });
  };

  addCurrentUserToAdvancedFilter = (advancedFilter, fields = { id: assigneeFields.id, name: assigneeFields.name, type: assigneeFields.type, assigneeType: assigneeFields.assigneeType }) => {
    const { user } = this.props;

    const userToAdd = {
      [fields.id]: user[teamFields.userId],
      [fields.name]: user[teamFields.name],
      [fields.assigneeType]: assigneeTypes.user,
    };
    if (!isEmpty(advancedFilter)) {
      const foundUserIndex = advancedFilter.filter(assignee => assignee[fields.assigneeType] === assigneeTypes.user).findIndex(assignee => assignee[fields.id] === userToAdd[fields.id]);
      if (foundUserIndex < 0) {
        // user not found in advanced filter, add current user
        advancedFilter.push(userToAdd);
      }
    } else {
      advancedFilter = [userToAdd];
    }
    return advancedFilter;
  };

  applyAdvancedFilters = (values = {}) => {
    const { advancedFilters } = this.state;
    const { filters, setModalState } = this.props;
    this.setState({ advancedFilters: values });
    // TODO: check if Properties[index].Name exists and it is not a empty string, if that fails remove the value from custom property value
    const newAdvancedFilter = reduce(
      values,
      (obj, _it, key) => {
        if (key === filterFields.severityFilter) {
          obj[key] = [values[filterFields.severityFilter].min, values[filterFields.severityFilter].max];
        } else if ([filterFields.componentTypeFilter, filterFields.workOrderTypeFilter, filterFields.additionalSubTypeFilter].indexOf(key) > -1) {
          obj[key] = values[key];
        } else if ([filterFields.createdByFilter, filterFields.assignedToFilter, filterFields.componentIDsFilter, filterFields.checklistIDsFilter].indexOf(key) > -1) {
          obj[key] = map(values[key], ({ ID, UserID }) => ID || UserID);
        } else if ([filterFields.startDateFrom].indexOf(key) > -1) {
          obj[key] = (values[key] && Helpers.getUnixDate(new Date(values[key]).getTime())) || null;
        } else if ([filterFields.startDateTo].indexOf(key) > -1) {
          // sets end/to dates to end of day 23:59:59 to include that day in filter results
          obj[key] = (values[key] && Helpers.getUnixDate(new Date(values[key]).setHours(23, 59, 59))) || null;
        } else if ([filterFields.hasObservationFilter, filterFields.hasChecklistFilter].indexOf(key) > -1) {
          obj[key] = Helpers.castToggleRadioInputAnswer(values[key]);
        } else {
          obj[key] = values[key];
        }
        return obj;
      },
      {}
    );

    /* Checking if the advancedFilter is empty, if it is, it deletes the startDateFrom and startDateTo filters. */
    if (isEmpty(advancedFilters)) {
      delete filters[filterFields.startDateFrom];
      delete filters[filterFields.startDateTo];
    }

    /* Checking if the advancedFilter is empty and if the startDateFrom and startDateTo are not in the
    advancedFilter. If they are not in the advancedFilter, then it deletes them from the filters. */
    if (!isEmpty(advancedFilters) && !advancedFilters[filterFields.startDateFrom] && filters[filterFields.startDateFrom]) {
      delete filters[filterFields.startDateFrom];
    }
    if (!isEmpty(advancedFilters) && !advancedFilters[filterFields.startDateTo] && filters[filterFields.startDateTo]) {
      delete filters[filterFields.startDateTo];
    }

    this.getWorkOrders({ ...deleteAdvancedFiltersFromFilters(filters), ...newAdvancedFilter, [filterFields.lastSeen]: 0 });
    setModalState({ isOpen: false });
  };

  loadMore = () => {
    const { elements, pagingObject, setWorkOrderPaging } = this.props;
    setWorkOrderPaging({
      ...pagingObject,
      [filterFields.lastSeen]: elements.length || 0,
    });

    this.searchChangeDebounced(null, true);
  };

  handleQuickFilter = values => {
    const { setWorkOrderPaging, user } = this.props;
    const filters = defaultQuickFilters;

    // deselecting linked to me filter, remove assignees and collaborators from filters
    if (values === filterFields.linkedToMe && filters[filterFields.linkedToMe]) {
      const filteredAssignees = filters[filterFields.assignees].filter(assignee => assignee[formConstants.fields.id] !== user.UserID);
      const filteredCollaborators = filters[filterFields.collaborators].filter(collaborator => collaborator[formConstants.fields.id] !== user.UserID);

      filters[filterFields.assignees] = filteredAssignees;
      filters[filterFields.collaborators] = filteredCollaborators;
    }
    if (isArray(values)) {
      filters[filterFields.statusFilter] = values || [];
    } else {
      filters[values] = !filters[values];
    }

    setWorkOrderPaging(defaultPagingObj);

    this.searchChangeDebounced(filters);
  };

  onColumnSort = column => {
    const { sortingFilter } = this.state;
    const { setWorkOrderPaging } = this.props;

    this.setState({
      sortingFilter: {
        [filterFields.sortByColumn]: column,
        [filterFields.sortDirection]: sortingFilter[filterFields.sortDirection] === sortDirections.asc ? sortDirections.desc : sortDirections.asc,
      },
    });

    setWorkOrderPaging(defaultPagingObj);

    this.searchChangeDebounced();
  };

  getSeverityObj = severity => {
    return (
      chain(severityConstants)
        .values()
        .find(item => item.keys.indexOf(severity) > -1)
        .value() || severityConstants.green
    );
  };

  resetAdvancedFilters = () => {
    const { filters, setModalState } = this.props;
    this.setState({ advancedFilters: {} });
    this.getWorkOrders({ ...deleteAdvancedFiltersFromFilters(filters), [filterFields.lastSeen]: 0 });
    setModalState({ isOpen: false });
  };

  openAdvancedFilter = () => {
    const { t } = this.context;
    const { setModalState, projectId, inspectionId } = this.props;
    const { advancedFilters } = this.state;
    const closeAction = () => setModalState({ isOpen: false });

    const modalData = {
      isOpen: true,
      type: 'none',
      title: t('ADVANCED_FILTERS.TITLE'),
      CustomContent: () => (
        <WorkordersFilter
          submitForm={this.applyAdvancedFilters}
          resetFilter={this.resetAdvancedFilters}
          initialValues={advancedFilters}
          closeAction={closeAction}
          projectId={projectId}
          inspectionId={inspectionId}
        />
      ),
      customClassName: 'modal-large defects-filter',
      closeAction,
    };

    setModalState(modalData);
  };

  openWorkOrderCreationModal = () => {
    const { t } = this.context;
    const { handleCreateWorkOrderModal, inspectionId, destroyForm, isDirty } = this.props;
    let modalData;
    const closeAction = () => {
      destroyForm(FORMS.createWorkorderForm);
      modalData = { isOpen: false };
      handleCreateWorkOrderModal(modalData);
    };

    modalData = {
      isOpen: true,
      title: t('CREATE_WORK_ORDER_MODAL.TITLE'),
      CustomContent: dynamicProps => <CreateWorkOrderModal {...dynamicProps} inspectionId={inspectionId} closeAction={closeAction} />,
      customClassName: 'modal-large',
      type: 'none',
      closeAction,
    };
    if (isDirty) {
      if (window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
        handleCreateWorkOrderModal(modalData);
      } else return;
    } else {
      handleCreateWorkOrderModal(modalData);
    }
  };

  formatCell = (value, type, _index, item) => {
    const { t } = this.context;
    const { severityColors, filters, handleItemClick } = this.props;

    if ([formConstants.fields.startDate, formConstants.fields.dueDate].indexOf(type) > -1) {
      return !isNaN(value) && value > 0 ? Helpers.getDateFromUnix(value, 'DD/MM/YY') : '-';
    }

    if (type === formConstants.fields.nameLite) {
      return (
        <div className="work-order-priority">
          <p className="f-primary work-order-name ">{value || '-'}</p>
        </div>
      );
    } else if (type === formConstants.fields.dateLong) {
      return !isNaN(value) && value > 0 ? Helpers.getDateFromUnix(value, 'lll') : '-';
    } else if (type === formConstants.fields.dateShort) {
      return !isNaN(value) && value > 0 ? Helpers.getDateFromUnix(value, 'MMM DD, YYYY') : '-';
    } else if (type === formConstants.fields.status) {
      const statusObj = find(filters, { value: item[formConstants.fields.status] });
      return !isEmpty(statusObj) && statusObj.customComponent('small');
    } else if (type === formConstants.fields.statusText) {
      return <DisplayStatus statuses={values(statuses)} status={value} />;
    } else if (type === formConstants.fields.type || type === formConstants.fields.planned) {
      return value ? capitalize(value) : '-';
    } else if (type === formConstants.fields.overdue) {
      return value ? t('WORK_ORDERS.IS_OVERDUE_YES') : t('WORK_ORDERS.IS_OVERDUE_NO');
    } else if (type === formConstants.fields.description) {
      return value ? (
        <span className="description-column__container">
          <ReadMoreTooltip value={value} onClick={() => handleItemClick(item[formConstants.fields.id])} />
        </span>
      ) : (
        '-'
      );
    } else if (type === formConstants.fields.severity) {
      const workOrderPriorities = values(priorities);
      const selected = find(workOrderPriorities, { value });

      return (
        <div className="work-order-priority">
          {selected?.color && <div className="work-order-priority-color" style={{ backgroundColor: severityColors[selected?.color] }}></div>}
          <p className="f-primary">{t(selected?.title ? selected.title : priorities.none.title)}</p>
        </div>
      );
    } else if (type === formConstants.fields.viewIcon) {
      return <Icon name="eye" title={t('ITEMS_LIST.VISIBILTY_ICON')} />;
    }
    return value || '-';
  };

  render() {
    const { t } = this.context;
    const { advancedFilters, sortingFilter } = this.state;
    const { elements, workOrdersLoading, handleItemClick, tableConfig, queryItem, title, backAction, moduleActionsMenu, filters, icon, pagingObject, user, isInLeftToolbar } = this.props;

    return (
      <Fragment>
        <div className="work-order-list">
          <ModuleHeader
            {...{
              title,
              icon,
              backAction,
              actionsMenu: moduleActionsMenu,
              actionButtontext: 'CREATE_BUTTON_TEXT.NEW',
              handleActionButtonClick: this.openWorkOrderCreationModal,
              actionButtonProps: {
                user,
                visibleFor: PERMISSIONS[PERMISSION_TYPES.workOrders].create.name,
              },
            }}
          />
          <div className="advanced-filter">
            <SearchText maxLength={60} />
            <p className="f-secondary-green noselect pointer link" onClick={this.openAdvancedFilter}>
              {t('ADVANCED_FILTER', { active: keys(advancedFilters).length })}
            </p>
          </div>

          <div className="status-filters">
            <p className="f-secondary-dark status-filters-title bold">{t('ISOLATION_CERTIFICATES.FILTERS_TITLE')}</p>
            <StatusesFilter
              statuses={values(statuses)}
              selected={getSelectedStatuses(filters)}
              singleSelected={filters[filterFields.archived]}
              onChange={this.handleQuickFilter}
              isAdjustedForSmallerScreen={isInLeftToolbar}
              isLoading={workOrdersLoading}
            />
          </div>
          <div className="table-container">
            <ItemsTableRenderer
              isLoading={workOrdersLoading}
              tableConfig={tableConfig}
              translationModule={t}
              data={map(elements, item => ({ ...item, selected: item[formConstants.fields.id] === queryItem ? true : false }))}
              onRowClick={(_e, item) => {
                handleItemClick(item[formConstants.fields.id]);
              }}
              sortingObj={sortingFilter}
              onColumnSort={this.onColumnSort}
              formatCell={this.formatCell}
              emptyStateText={'WORK_ORDERS.EMPTY_STATE'}
              sortByColumnProp={filterFields.sortByColumn}
            />
            <RenderIf if={elements && elements.length > 0}>
              <LoadMore
                disabled={!pagingObject[filterFields.hasNext]}
                loaded={elements.length}
                total={pagingObject[filterFields.totalItems]}
                totalPosition="center"
                label="COMPONENT_HISTORY.LOAD_MORE"
                showButton
                showTotalUp
                buttonVariant="success-outline"
                resultsText="WORK_ORDERS.SHOWING_TOTAL_ITEMS"
                onClick={this.loadMore}
              />
            </RenderIf>
          </div>
        </div>
      </Fragment>
    );
  }
}

WorkOrders.defaultProps = {
  tableConfig: tableConfig,
};

WorkOrders.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isDirty: state.unsavedChangesReducer.isDirty,
});

const mapDispatchToProps = dispatch => ({
  destroyForm: formName => dispatch(destroy(formName)),
  fetchDMSCategories: (projectId, moduleName, callback) => dispatch(fetchDMSCategories(projectId, moduleName, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkOrders);
