import actionTypes from '../constants/action-types';
import { getWholeArrayOfFiles } from '../helpers/upload-helpers';

const uploadBatchesState = {
  uploadBatches: [],
  uploadFiles: [],
};

export const uploadBatchesReducer = (state = uploadBatchesState, payload) => {
  switch (payload.type) {
    case actionTypes.SET_UPLOAD_BATCH_FILES:
      //Add batch to the current state and spread the batch files to uploadFiles to be shown
      return { ...state, uploadBatches: [payload.data, ...state.uploadBatches], uploadFiles: [...payload.data.files, ...state.uploadFiles] };
    case actionTypes.UPDATE_UPLOAD_BATCH_FILE:
      //Update file progress
      const updatedBatches = state.uploadBatches?.map(batch => {
        if (batch?.batchID === payload?.data?.batchID) {
          batch.files = batch.files.map((file, index) => {
            return index === payload.data.index ? { ...file, progress: payload.data.progress, cancel: payload.data.cancelObj } : file;
          });
        }
        batch.batchCompleted = batch.files.every(file => file.progress === 100);
        return batch;
      });
      return {
        ...state,
        uploadBatches: updatedBatches,
        uploadFiles: getWholeArrayOfFiles(updatedBatches),
      };
    case actionTypes.DO_BATCH_CLEAN_UP:
      //Check if each batch is completed, then clean the state
      if (state.uploadBatches.every(batch => batch.batchCompleted === true)) {
        return uploadBatchesState;
      }
      return state;
    case actionTypes.SET_BATCH_FILE_FAILED:
      //Set upload file to failed
      const newBatches = state.uploadBatches?.map(batch => {
        if (batch?.batchID === payload?.data?.batchID) {
          batch.files = batch.files.map((file, index) => {
            return index === payload.data.index ? { ...file, progress: 100, failedUpload: true } : file;
          });
        }
        batch.batchCompleted = batch.files.every(file => file.progress === 100);
        return batch;
      });
      return {
        ...state,
        uploadBatches: newBatches,
        uploadFiles: getWholeArrayOfFiles(newBatches),
      };
    default:
      return state;
  }
};
