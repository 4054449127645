import PropTypes from 'prop-types';
import React from 'react';

import { Field, FieldArray } from 'redux-form';
import ColorPalette from '../../../../../../common/form/components/color-pallete';
import CustomInput from '../../../../../../common/form/components/input';
import CustomSelect from '../../../../../../common/form/components/select';
import Toggle from '../../../../../../common/form/components/toggle-field';
import { alarmColorPalette, descriptorFields, formConstants } from '../../constants/alarm-constants';
import { formConstants as mpFormConstants } from '../../constants/measurement-point-constants';
import '../../styles/alarm-form-field.scss';
import AlarmAssigneesFields from './alarm-assignees-fields';

const AlarmFormField = ({ fieldName, readOnly, formValues, searchUsersAndTeams, alarmDescriptors, defaultComponent }, { t }) => {
  return (
    <div className="alarm-form-field">
      <div className="double">
        <div className="split-input">
          <Field
            id={`${fieldName}.${formConstants.fields.descriptor.id}`}
            name={`${fieldName}.${formConstants.fields.descriptor.name}`}
            data={alarmDescriptors}
            readOnly={readOnly}
            valueField={descriptorFields.id}
            textField={descriptorFields.description}
            component={CustomSelect}
            placeholder={t(formConstants.fields.descriptor.label)}
            label={t(formConstants.fields.descriptor.label)}
            size="lg"
            isRequired
            isEnhancedDesignCustomProp
          />
        </div>
        <div className="split-input">
          <Field
            id={`${fieldName}.${formConstants.fields.alarmLevel.id}`}
            name={`${fieldName}.${formConstants.fields.alarmLevel.name}`}
            component={CustomInput}
            label={t(formConstants.fields.alarmLevel.label, { unit: formValues?.[mpFormConstants.fields.unit] || '-' })}
            disabled={readOnly}
            placeholder={t(formConstants.fields.alarmLevel.placeholder, { unit: formValues?.[mpFormConstants.fields.unit] || '-' })}
            type="text"
            size="lg"
            additionalClassName="text-transform-none"
            labelClass="text-transform-none"
            isRequired
          />
        </div>
      </div>
      <div className="double">
        <div className="split-input">
          <FieldArray
            id={`${fieldName}.${formConstants.fields.notifyAssignees.id}`}
            name={`${fieldName}.${formConstants.fields.notifyAssignees.name}`}
            component={AlarmAssigneesFields}
            formValues={formValues}
            searchUsersAndTeams={searchUsersAndTeams}
          />
        </div>
        <div className="split-input">
          <Field
            id={`${fieldName}.${formConstants.fields.colour.id}`}
            name={`${fieldName}.${formConstants.fields.colour.name}`}
            disabled={readOnly}
            component={ColorPalette}
            className="alarm-form-field__color-tag"
            label={t(formConstants.fields.colour.label)}
            colorPalette={alarmColorPalette}
          />
        </div>
      </div>
      <div className="double">
        <div className="split-input">
          <Field
            id={`${fieldName}.${formConstants.fields.enableNotificationCreation.id}`}
            name={`${fieldName}.${formConstants.fields.enableNotificationCreation.name}`}
            component={Toggle}
            label={t(formConstants.fields.enableNotificationCreation.label)}
            className="mb-0"
            inline={true}
            disabled={readOnly || !formValues?.[mpFormConstants.fields.componentId] || formValues?.[mpFormConstants.fields.componentId] === defaultComponent?.[mpFormConstants.fields.id]}
            sublabel={t(formConstants.fields.enableNotificationCreation.placeholder)}
          />
        </div>
        <div className="split-input">
          <Field
            id={`${fieldName}.${formConstants.fields.enableDefectCreation.id}`}
            name={`${fieldName}.${formConstants.fields.enableDefectCreation.name}`}
            component={Toggle}
            label={t(formConstants.fields.enableDefectCreation.label)}
            className="mb-0"
            inline={true}
            disabled={readOnly}
            sublabel={t(formConstants.fields.enableDefectCreation.placeholder)}
          />
        </div>
      </div>
    </div>
  );
};

AlarmFormField.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default AlarmFormField;
