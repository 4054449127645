import { formConstants as regimeConstants, tableIntervalCombineKey } from './regime-constants';
import { formConstants as mtaskConstants, importanceType, statusType } from './mtask-constants';
import { formConstants as checklistConstants } from './checklist-constants';

const dateShortType = 'date-short';

export const columnTypes = {
  components: 'Components',
  checklists: 'Checklists',
  status: 'Status',
  priority: 'Priority',
};

const regimeTableHeader = [
  {
    title: 'REGIME_TABLE.ID',
    key: regimeConstants.fields.id,
    enableSort: true,
  },
  {
    title: 'REGIME_TABLE.TITLE',
    key: regimeConstants.fields.title,
    enableSort: true,
  },
  {
    title: 'REGIME_TABLE.STATUS',
    key: regimeConstants.fields.status,
    enableSort: true,
    type: columnTypes.status,
  },
  {
    title: 'REGIME_TABLE.PRIORITY',
    key: regimeConstants.fields.importance,
    enableSort: true,
    type: columnTypes.priority,
  },
  {
    title: 'REGIME_TABLE.COMPONENTS',
    key: regimeConstants.fields.components,
    enableSort: true,
    type: columnTypes.components,
  },
  // {
  //   title: 'REGIME_TABLE.CHECKLISTS',
  //   key: regimeConstants.fields.checklists,
  //   enableSort: true,
  //   type: columnTypes.checklists,
  // },
  {
    title: 'REGIME_TABLE.NEXT_TASK',
    key: regimeConstants.fields.nextMaintenanceTask,
    enableSort: true,
    type: dateShortType,
  },
  {
    title: 'REGIME_TABLE.INTERVAL',
    key: regimeConstants.fields.interval,
    enableSort: true,
    type: tableIntervalCombineKey,
  },
  {
    title: 'REGIME_TABLE.START_DATE',
    key: regimeConstants.fields.startDate,
    enableSort: true,
    type: 'date',
  },
];

const checklistTableHeader = [
  {
    title: 'CHECKLIST_TABLE.NAME',
    key: checklistConstants.fields.name,
    enableSort: true,
  },
  {
    title: 'CHECKLIST_TABLE.QUESTIONS_NUMBER',
    key: checklistConstants.fields.numberOfQuestions,
    className: 'description',
    enableSort: true,
  },
  {
    title: 'CHECKLIST_TABLE.REGIMES_NUMBER',
    key: checklistConstants.fields.regimesNumber,
    className: 'description',
    enableSort: true,
  },
  {
    title: 'CHECKLIST_TABLE.DATE_CREATED',
    key: checklistConstants.fields.dateCreated,
    enableSort: true,
    type: 'date',
  },
  {
    title: 'CHECKLIST_TABLE.CREATOR',
    key: checklistConstants.fields.creator,
    enableSort: true,
  },
];

const taskTableHeader = [
  {
    title: 'MTASK_TABLE.NAME',
    key: mtaskConstants.fields.name,
    enableSort: true,
  },
  // {
  //   title: 'MTASK_TABLE.DESCRIPTION',
  //   key: mtaskConstants.fields.description,
  //   className: 'description',
  //   enableSort: true,
  // },
  {
    title: 'MTASK_TABLE.START_DATE',
    key: mtaskConstants.fields.startDate,
    enableSort: true,
    type: dateShortType,
  },
  {
    title: 'MTASK_TABLE.DATE_COMPONENT',
    key: mtaskConstants.fields.dateCompleted,
    enableSort: true,
    type: dateShortType,
  },
  {
    title: 'MTASK_TABLE.CREATOR',
    key: mtaskConstants.fields.creator,
    enableSort: true,
  },
  {
    title: 'MTASK_TABLE.IMPORTANCE',
    key: mtaskConstants.fields.importance,
    enableSort: true,
    type: importanceType,
  },
  {
    title: 'MTASK_TABLE.COMPONENT_NAME',
    key: mtaskConstants.fields.componentName,
    enableSort: true,
  },
  // {
  //   title: 'MTASK_TABLE.COMPONENT_CODE',
  //   key: mtaskConstants.fields.componentCode,
  //   enableSort: true,
  // },
  {
    title: 'MTASK_TABLE.STATUS',
    key: mtaskConstants.fields.status,
    enableSort: true,
    type: statusType,
  },
];

export const tabConstants = {
  regimes: {
    tabKey: 'REGIMES',
    title: 'MAINTENANCE_REGIME.TAB_TITLE_1',
  },
  checklists: {
    tabKey: 'CHECKLISTS',
    title: 'MAINTENANCE_REGIME.TAB_TITLE_2',
  },
  tasks: {
    tabKey: 'TASKS',
    title: 'MAINTENANCE_REGIME.TAB_TITLE_3',
  },
};

export default {
  regimeTableHeader,
  checklistTableHeader,
  taskTableHeader,
  dateShortType,
};
