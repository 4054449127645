import { isNumber } from 'lodash';

import { previousStep, nextStep, setActiveStep, setImageTool, selectAllDefects, setNewDefect } from '../actions/action-creators';
import { selectObjectTool } from '../../inspections/actions/action-creators';
import { getInspectionElementsByType } from '../../inspections/actions/inspection-actions';

import { measurementTypes } from '../../inspections/constants/constants';
import { stepperConstants } from '../constants/constants';

import { Tools } from '@tarik.djurdjevic/react-sketch';
import { setSelectedDefect } from './start-workflow-actions';

export const goToNextStep = currentStep => {
  return async dispatch => {
    dispatch(nextStep());
    if (isNumber(currentStep)) {
      dispatch(executeStepActions(currentStep + 1));
    }
  };
};

export const goToPreviousStep = currentStep => {
  return async dispatch => {
    dispatch(previousStep());
    if (isNumber(currentStep)) {
      dispatch(executeStepActions(currentStep - 1));
    }
  };
};

export const goToStep = (futureStep, currentStep) => {
  return async dispatch => {
    dispatch(setActiveStep(futureStep));
    if (isNumber(futureStep)) {
      dispatch(executeStepActions(futureStep));
    }
  };
};
//actions that are always executed when step is active
const executeStepActions = futureStep => {
  return async (dispatch, getState) => {
    const { inspectionID } = getState().inspectionReducer;

    switch (futureStep) {
      case stepperConstants.QUESTIONNAIRE:
        dispatch(selectAllDefects());
        dispatch(selectObjectTool(null));
        dispatch(setImageTool(Tools.DefaultTool));
        dispatch(setNewDefect(null));
        dispatch(setSelectedDefect(null));
        break;
      case stepperConstants.DEFECT_DRAWING:
        dispatch(selectAllDefects());
        // dispatch(selectObjectTool(objectTools.inspect));
        dispatch(setImageTool(Tools.Rectangle));
        break;
      case stepperConstants.DEFECT_DRAWING_COMPLETED:
        dispatch(selectAllDefects());
        // dispatch(selectObjectTool(objectTools.inspect));
        dispatch(setImageTool(Tools.DefaultTool));
        break;
      case stepperConstants.COMPONENT_SELECTION:
        dispatch(selectAllDefects());
        dispatch(getInspectionElementsByType(inspectionID, measurementTypes.component));
        // dispatch(selectObjectTool(objectTools.inspect));
        dispatch(setImageTool(Tools.DefaultTool));
        break;
      case stepperConstants.CREATE_COMPONENT:
        dispatch(selectAllDefects());
        // dispatch(selectObjectTool(objectTools.inspect));
        dispatch(setImageTool(Tools.DefaultTool));
        break;
      default:
      case stepperConstants.DEFECT_EDIT:
        dispatch(selectAllDefects());
        dispatch(selectObjectTool(null));
        dispatch(setImageTool(Tools.Select));
        break;
    }
  };
};
