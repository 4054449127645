import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Button from '../../../../../common/form/components/button';
import CustomSelect from '../../../../../common/form/components/select';
import RenderIf from '../../../../../common/render-if/components/render-if';
import SignatureField from '../../../../../common/form/components/signature-field';
import { changeStatusFields, contractorDropdownFields, steps } from '../../../components/right-toolbar/isolation-certificate-details/constants/change-status-constants';
import { FORMS } from '../../../../../common/constants';
import { validate } from '../../../components/right-toolbar/validators/change-status-validator';

import '../../../styles/change-status-modal.scss';

const ChangeStatusForm = (
  {
    handleSubmit,
    handleFormSubmit,
    contractors = [],
    status,
    signatureRequired,
    handleCloseModal,
    showGoBackButton,
    handleGoBack,
    disabled,
    disableContractorPicker,
    hasIsolationComponents,
    hasQuestions,
    handleResetIsolationComponentCheckboxes,
  },
  { t }
) => {
  const handleCancel = () => {
    handleResetIsolationComponentCheckboxes();
    hasQuestions && hasIsolationComponents ? handleGoBack(steps.second) : handleGoBack(steps.first);
  };

  const { contractor, assigneeSignature, witnessSignature } = changeStatusFields;

  return (
    <form noValidate onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="contractors-dropdown">
        <Field
          id={contractor.id}
          name={contractor.name}
          label={contractor.label}
          placeholder={t(contractor.placeholder)}
          component={CustomSelect}
          data={contractors}
          size="lg"
          valueField={contractorDropdownFields.valueField}
          textField={contractorDropdownFields.textField}
          disabled={disabled || disableContractorPicker}
          filter="contains"
          preventOnBlur
        />
      </div>
      <RenderIf if={signatureRequired}>
        <div className="signature-field-container">
          <Field
            id={assigneeSignature.id}
            querySelector={assigneeSignature.querySelector}
            name={assigneeSignature.name}
            label={assigneeSignature.label}
            placeholder={assigneeSignature.label}
            component={SignatureField}
            size="lg"
            disabled={disabled}
          />
        </div>
        <div className="signature-field-container">
          <Field
            id={witnessSignature.id}
            querySelector={witnessSignature.querySelector}
            name={witnessSignature.name}
            label={witnessSignature.label}
            placeholder={witnessSignature.label}
            component={SignatureField}
            size="lg"
            disabled={disabled}
          />
        </div>
      </RenderIf>
      <div className="change-status-buttons-container">
        <Button
          type="button"
          className="small-buttons"
          variant="success-outline"
          height="md"
          width="sm"
          text={t('ISOLATION_CERTIFICATE_STATUS.CANCEL_BUTTON')}
          onClick={handleCloseModal}
          disabled={disabled}
        />
        <div className="right-buttons">
          <RenderIf if={showGoBackButton}>
            <Button
              type="button"
              className="small-buttons go-back-button"
              variant="success-outline"
              height="md"
              width="sm"
              text={t('ISOLATION_CERTIFICATE_STATUS.BACK_BUTTON')}
              onClick={handleCancel}
              disabled={disabled}
            />
          </RenderIf>
          <Button
            type="submit"
            className="large-button"
            height="md"
            width="sm"
            variant="success"
            text={t(status.actionButtonText || 'ISOLATION_CERTIFICATE_STATUS.CHANGE_STATUS_BUTTON')}
            disabled={disabled}
            keepOriginalText
          />
        </div>
      </div>
    </form>
  );
};

ChangeStatusForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

ChangeStatusForm.propTypes = {
  contractors: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number,
      Name: PropTypes.string,
    })
  ),
  status: PropTypes.shape({
    value: PropTypes.string.isRequired,
    title: PropTypes.string,
    permission: PropTypes.string,
    modalTitle: PropTypes.string,
    actionButtonText: PropTypes.string,
  }).isRequired,
  signatureRequired: PropTypes.bool,
  handleCloseModal: PropTypes.func.isRequired,
  showGoBackButton: PropTypes.bool,
  handleGoBack: PropTypes.func.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  handleResetIsolationComponentCheckboxes: PropTypes.func.isRequired,
};

ChangeStatusForm.propTypes = {
  contractors: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number,
      Name: PropTypes.string,
    })
  ),
};

export default reduxForm({
  form: FORMS.changeModuleStatusForm,
  enableReinitialize: true,
  touchOnChange: true,
  destroyOnUnmount: true,
  validate,
})(ChangeStatusForm);
