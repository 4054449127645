import actionTypes from '../constants/action-types';

const usersTableState = {
  passwordResetDisabled: false,
};

export const passwordResetReducer = (state = usersTableState, payload) => {
  switch (payload.type) {
    case actionTypes.HANDLE_USER_RESET_PASSWORD:
      return { ...state, passwordResetDisabled: payload.data };
    default:
      return state;
  }
};
