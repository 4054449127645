import { detailsFields, dateFields } from '../constants/section-form-constants';

export default {
  detailsValidate: values => {
    const errors = {};
    const { name, description } = detailsFields;

    if (!values[name.name]) {
      errors[name.name] = name.errorRequired;
    }

    if (values[name.name] && values[name.name].length > name.maxChars) {
      errors[name.name] = name.errorMaxChars;
    }

    if (values[name.name] && values[name.name].length < name.minChars) {
      errors[name.name] = name.errorMinChars;
    }

    if (values[description.name] && values[description.name].length > description.maxChars) {
      errors[description.name] = description.errorMaxChars;
    }

    return errors;
  },
  datesValidate: values => {
    const errors = {};
    const { startDate, endDate, revalidationTime } = dateFields;
    if (!values[startDate.name]) {
      errors[startDate.name] = startDate.errorRequired;
    }
    if (!values[endDate.name]) {
      errors[endDate.name] = endDate.errorRequired;
    }
    if (values[startDate.name] && values[endDate.name] && new Date(values[endDate.name]) <= new Date(values[startDate.name])) {
      errors[endDate.name] = endDate.errorMinDate;
    }
    if (values[revalidationTime.name] < 0) {
      errors[revalidationTime.name] = revalidationTime.errorMin;
    }
    if (values[revalidationTime.name] > 32500) {
      errors[revalidationTime.name] = revalidationTime.errorMax;
    }
    return errors;
  },
};
