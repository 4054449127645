import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import PasswordForm from './password-form';
import Modal from './modal';
import passwordConstants from '../constants/password-constants';
import { passwordVerificationError } from '../actions/action-creators';

class PasswordConfirmationModal extends Component {
  handleSubmit = values => {
    const { confirmAction, setFormError } = this.props;
    confirmAction(values[passwordConstants.fields.password], setFormError);
  };

  CustomContent = () => {
    const { closeAction, additionalText } = this.props;

    return <PasswordForm onSubmit={this.handleSubmit} closeAction={closeAction} additionalText={additionalText} />;
  };

  render() {
    return <Modal {...this.props} type="" CustomContent={this.CustomContent} />;
  }
}

PasswordConfirmationModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({ setFormError: error => dispatch(passwordVerificationError(error)) });

export default connect(null, mapDispatchToProps)(PasswordConfirmationModal);
