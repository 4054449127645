import React, { Component } from 'react';
import { connect } from 'react-redux';

import { cloneDeep, isEmpty, last, take } from 'lodash';

import { createNewComponent, duplicateComponent, handleQuickDefect, setCurrentImage, updateDefect } from '../../actions/inspection-actions';

import { getImagesForSelectedPoint, getOtherImages, loadMoreImages } from '../../../inspections/potree/actions/actions';
import { changeRealCameraType, clearUnsavedChangesDirty, setToggledNotification, toggleComponent, toggleDefect } from '../../actions/action-creators';
import { setActiveSlide } from '../../potree/actions/action-creators';

import Helpers from '../../../../common/helpers';

import workflowConstants from '../../../inspection-workflow/constants/inspection-workflow-constants';
import { DefaultComponent, formConstants as componentFormConstants } from '../../constants/component-constants';
import { averageBoxSize, imageHeightCorrection, imagesCameraPosition, measurementTypes, modules, realCameraTypeItems } from '../../constants/constants';
import { measurementDropdownActions } from '../../constants/measurement-constants';
import { defaultFilterOther, pointImagesStep } from '../../potree/constants/point-images-contstants';

import Content from './toolbar-content.js';

import { withRouter } from 'react-router';
import ConfirmOnInspectionExitWrapper from '../../../shared/common-component/components/use-confirm-inspection-wrapper.js';
import '../../styles/right-toolbar.scss';

class RightToolbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      realCameraTypeItems: realCameraTypeItems,
      nonCFilter: {
        ...defaultFilterOther,
      },
      otherImages: [],
    };
  }

  handleInspectPointCloud = nextPosition => {
    const { inspectPoint, createNewDefect, realCameraType } = this.props;
    let newInspectPoint = cloneDeep(inspectPoint);
    newInspectPoint.Geometry.coordinates = [[nextPosition.x, nextPosition.y, nextPosition.z]];
    createNewDefect({ ...newInspectPoint, Type: realCameraType });
  };

  loadMore = () => {
    const { nonCFilter, otherImages } = this.state;
    const { loadMoreImages, pointImagesTake, inspectionId, projectId, getOtherImages, realCameraType } = this.props;

    if (realCameraType === imagesCameraPosition.other) {
      getOtherImages(inspectionId, projectId, workflowConstants.imageSelectionTypes.all, nonCFilter, false, (images, nonCFilterIncoming) => {
        const newImages = otherImages.concat(images);
        this.setState({ nonCFilter: { ...defaultFilterOther, ...nonCFilterIncoming } });
        this.setState({ otherImages: newImages });
        Helpers.scrollIntoView('point-images__section', newImages.length);
      });
    } else {
      const newTake = pointImagesTake + pointImagesStep;
      loadMoreImages(pointImagesStep, this.scrolIntoView(newTake));
    }
  };

  scrolIntoView = newTake => {
    const { images } = this.props;
    //create small for items to render
    setTimeout(() => {
      Helpers.scrollIntoView('point-images__section', last(take(images, newTake)).name);
    }, 500);
  };

  isDisabled = () => {
    const { realCameraType } = this.props;
    const { nonCFilter } = this.state;

    if (realCameraType === imagesCameraPosition.other) {
      return !nonCFilter.HasNext;
    }

    const { images, pointImagesTake } = this.props;

    return !images || images.length <= pointImagesTake;
  };

  startShapeInsertion = (shapeType, element, onFinish) => {
    const { viewer, currentInsertion, setCurrentInsertion } = this.props;

    if (viewer) {
      const shapeTool = new window.Potree.ShapeTool(viewer);

      if (currentInsertion !== null) {
        currentInsertion.removeEventListener('finish_inserting_shape', e => this.onShapeInsertionFinish(e, onFinish));
      }
      // Start insertion
      let measurement = shapeTool.startInsertion({
        shapeType,
        modelDetails: element.ModelDetails,
        name: 'Shape',
      });
      shapeTool.addEventListener('finish_inserting_shape', e => this.onShapeInsertionFinish(e, onFinish));
      measurement.addEventListener('finish_inserting_shape', e => this.onShapeInsertionFinish(e, onFinish));
      setCurrentInsertion(measurement);
    }
  };

  onShapeInsertionFinish = (e, callback) => {
    const { selectTool, inspectionId, viewer, getCameraPosition, setCurrentInsertion } = this.props;
    selectTool(null);
    setCurrentInsertion(null);
    const camPos = getCameraPosition();
    const type = e.shape.shapeType;
    let body = {
      InspectionID: parseInt(inspectionId),
      CameraPosition: {
        coordinates: [camPos.x, camPos.y, camPos.z],
      },
      Geometry: {
        type: type,
        coordinates: [[e.shape.position.x, e.shape.position.y, e.shape.position.z]],
      },
    };

    callback(body);

    viewer.scene.removeShape(e.shape);
    e.target.removeEventListener('finish_inserting_shape', e => this.onShapeInsertionFinish(e, callback));
    e.shape.removeEventListener('finish_inserting_shape', e => this.onShapeInsertionFinish(e, callback));
  };

  startWorkAreaInsertion = (action, onFinish) => {
    const { viewer, currentInsertion, setCurrentInsertion } = this.props;
    if (viewer) {
      const measuringTool = new window.Potree.MeasuringTool(viewer);

      if (currentInsertion !== null) {
        currentInsertion.removeEventListener('finish_inserting_measurement', e => this.onWorkAreaInsertionFinish(e, onFinish));
      }
      // Start insertion
      if (action === measurementDropdownActions.point) {
        let measurement = measuringTool.startInsertion({
          showDistances: false,
          showAngles: false,
          showCoordinates: true,
          showArea: false,
          closed: true,
          maxMarkers: 1,
          coordinatesText: 'New Area',
          systemType: measurementTypes.measurement,
          name: 'Point',
        });
        measuringTool.addEventListener('finish_inserting_measurement', e => this.onWorkAreaInsertionFinish(e, onFinish));
        measurement.addEventListener('finish_inserting_measurement', e => this.onWorkAreaInsertionFinish(e, onFinish));
        setCurrentInsertion(measurement);
      } else if (action === measurementDropdownActions.area) {
        let measurement = measuringTool.startInsertion({
          showDistances: true,
          showArea: true,
          closed: true,
          coordinatesText: 'New Area',
          systemType: measurementTypes.measurement,
          name: 'Area',
        });
        measuringTool.addEventListener('finish_inserting_measurement', e => this.onWorkAreaInsertionFinish(e, onFinish));
        measurement.addEventListener('finish_inserting_measurement', e => this.onWorkAreaInsertionFinish(e, onFinish));
        setCurrentInsertion(measurement);
      } else if (action === measurementDropdownActions.distance) {
        let measurement = measuringTool.startInsertion({
          showDistances: true,
          showArea: false,
          closed: false,
          coordinatesText: 'New Area',
          systemType: measurementTypes.measurement,
          name: 'Distance',
        });
        measuringTool.addEventListener('finish_inserting_measurement', e => this.onWorkAreaInsertionFinish(e, onFinish));
        measurement.addEventListener('finish_inserting_measurement', e => this.onWorkAreaInsertionFinish(e, onFinish));
        setCurrentInsertion(measurement);
      } else if (action === measurementDropdownActions.circle) {
        let measurement = measuringTool.startInsertion({
          showDistances: true,
          showArea: false,
          showCircle: true,
          closed: false,
          maxMarkers: 2,
          coordinatesText: 'New circle',
          systemType: measurementTypes.measurement,
          name: 'Circle',
        });
        measuringTool.addEventListener('finish_inserting_measurement', e => this.onWorkAreaInsertionFinish(e, onFinish));
        measurement.addEventListener('finish_inserting_measurement', e => this.onWorkAreaInsertionFinish(e, onFinish));
        setCurrentInsertion(measurement);
      } else return;
    }
  };

  onWorkAreaInsertionFinish = (e, callback) => {
    const { selectTool, inspectionId, viewer, getCameraPosition, setCurrentInsertion, getDefectType } = this.props;
    selectTool(null);
    setCurrentInsertion(null);
    if (isEmpty(e.measure.points)) {
      e.target.removeEventListener('finish_inserting_measurement', e => this.onWorkAreaInsertionFinish(e, callback));
      e.measure.removeEventListener('finish_inserting_measurement', e => this.onWorkAreaInsertionFinish(e, callback));
      viewer.scene.removeMeasurement(e.measure);
      return;
    }
    const camPos = getCameraPosition();
    const type = getDefectType(e.measure.name);
    let body = {
      InspectionID: parseInt(inspectionId),
      CameraPosition: {
        coordinates: [camPos.x, camPos.y, camPos.z],
      },
      Geometry: {
        type: type,
        coordinates: [...e.measure.points.map(point => [point.position.x, point.position.y, point.position.z])],
      },
    };

    callback(body);

    viewer.scene.removeMeasurement(e.measure);
    e.target.removeEventListener('finish_inserting_measurement', e => this.onWorkAreaInsertionFinish(e, callback));
    e.measure.removeEventListener('finish_inserting_measurement', e => this.onWorkAreaInsertionFinish(e, callback));
  };

  getSliderStyle = () => {
    const { currentWidth } = this.props;
    const maxImageHeight = 500;

    let height = currentWidth - imageHeightCorrection;
    height = height > maxImageHeight ? maxImageHeight : height;

    return { height: height + 'px' };
  };

  handleQuickDefect = (drawing, file, imagePosition, cameraHelper, depth) => {
    const { handleQuickDefect, inspectionId, projectId, inspectPoint, getCameraPosition, realCameraType } = this.props;

    let location = null;
    let camPos = getCameraPosition();
    camPos = { X: camPos.x, Y: camPos.y, Z: camPos.z };

    location = { X: inspectPoint.Geometry.coordinates[0][0], Y: inspectPoint.Geometry.coordinates[0][1], Z: inspectPoint.Geometry.coordinates[0][2] };

    if (realCameraType === imagesCameraPosition.other) {
      file[workflowConstants.formConstants.fields.fileID] = file[workflowConstants.formConstants.fields.id];
    }

    handleQuickDefect(drawing, projectId, inspectionId, file, location, camPos, imagePosition, cameraHelper, depth);
  };

  handleRealCameraChange = (Type, Distance = null) => {
    const { inspectPoint, getImagesForSelectedPoint, inspectionId, projectId, getOtherImages, changeRealCameraType } = this.props;
    if (Type === imagesCameraPosition.other) {
      getOtherImages(inspectionId, projectId, workflowConstants.imageSelectionTypes.all, defaultFilterOther, true, (images, nonCFilterIncoming) => {
        this.setState({ nonCFilter: { ...defaultFilterOther, ...nonCFilterIncoming } });
        changeRealCameraType(Type);
        this.setState({ otherImages: images || [] });
      });
    } else {
      // reset prevous data
      this.setState({ otherImages: [] });
      this.setState({ nonCFilter: defaultFilterOther });
      //reset end

      const PointPosition = { X: inspectPoint.Geometry.coordinates[0][0], Y: inspectPoint.Geometry.coordinates[0][1], Z: inspectPoint.Geometry.coordinates[0][2] };

      getImagesForSelectedPoint(inspectionId, projectId, { PointPosition, Type, Distance }, () => changeRealCameraType(Type));
    }
  };

  setActiveSlide = index => {
    const { setActiveSlide, setCurrentImage, images, realCameraType } = this.props;
    setActiveSlide(index);

    if (realCameraType !== imagesCameraPosition.other) {
      setCurrentImage(images[index], index, false);
    }
  };

  render() {
    const { realCameraTypeItems, otherImages } = this.state;
    const {
      inspectionId,
      activeDetailsPage,
      images,
      activeItemIndex,
      viewer,
      createComponent,
      pointImagesLoading,
      pointImagesTake,
      inspectPoint,
      updateDefect,
      currentWidth,
      onClose,
      projectId,
      duplicateComponent,
      openInspectionAssetModal,
      handleObjectToolClick,
      openDownloadReportModalSingleDefect,
      realCameraType,
      queryItem,
      handleActivePage,
      showGeometryWarning,
      toggleDefect,
      toggleComponent,
      toggleNotification,
      view,
    } = this.props;

    const toggleElementVisibilityActionsMap = {
      [modules.defects]: toggleDefect,
      [modules.components]: toggleComponent,
      [modules.notifications]: toggleNotification,
    };

    const selectedToggleElementVisibilityAction = toggleElementVisibilityActionsMap[activeDetailsPage] || null;
    // TODO: dont load right-side if there is no queryItem and collapse the right-toolbar, except for details pages that do not require queryItem ([detailsPages.inspect, detailsPages.settings].indexOf(details) > -1)

    return (
      <div className="right-toolbar">
        <Content
          //main params
          inspectionId={inspectionId}
          projectId={projectId}
          handleActivePage={handleActivePage}
          openDownloadReportModalSingleDefect={openDownloadReportModalSingleDefect}
          activePage={activeDetailsPage}
          images={realCameraType === imagesCameraPosition.other ? otherImages : pointImagesTake > 0 ? take(images, pointImagesTake) : images}
          activeItemIndex={activeItemIndex}
          setActiveSlide={this.setActiveSlide}
          updateDefect={updateDefect}
          createComponent={createComponent}
          pointImagesLoading={pointImagesLoading}
          loadMoreImages={this.isDisabled() ? null : this.loadMore}
          imagesCount={(images || []).length}
          inspectPoint={inspectPoint}
          handleInspectPointCloud={this.handleInspectPointCloud}
          objectToolClick={handleObjectToolClick}
          cols={Math.floor(currentWidth / averageBoxSize)}
          onClose={onClose}
          sliderStyle={this.getSliderStyle()}
          handleQuickDefect={this.handleQuickDefect}
          handleRealCameraChange={this.handleRealCameraChange}
          realCameraType={realCameraType}
          realCameraTypeItems={realCameraTypeItems}
          startWorkAreaInsertion={this.startWorkAreaInsertion}
          startShapeInsertion={this.startShapeInsertion}
          duplicateComponent={(id, callback) => duplicateComponent(id, DefaultComponent()[componentFormConstants.fields.name], callback)}
          viewer={viewer}
          openInspectionAssetModal={
            realCameraType === imagesCameraPosition.other
              ? () =>
                  openInspectionAssetModal(() => {
                    this.handleRealCameraChange(imagesCameraPosition.other);
                  })
              : null
          }
          queryItem={queryItem}
          view={view}
          showGeometryWarning={showGeometryWarning}
          toggleElement={selectedToggleElementVisibilityAction}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  images: state.potreeReducer.pointImages,
  pointImagesLoading: state.potreeReducer.pointImagesLoading,
  pointImagesTake: state.potreeReducer.pointImagesTake,
  activeItemIndex: state.potreeReducer.activeItemIndex,
  inspectPoint: state.inspectionReducer.inspectPoint,
  realCameraType: state.inspectionReducer.realCameraType,
  isDirty: state.unsavedChangesReducer.isDirty,
});

const mapDispatchToProps = dispatch => ({
  updateDefect: defect => dispatch(updateDefect(defect)),
  createComponent: component => dispatch(createNewComponent(component)),
  setActiveSlide: index => dispatch(setActiveSlide(index)),
  setCurrentImage: (image, index, moveCamera) => dispatch(setCurrentImage(image, index, moveCamera)),
  loadMoreImages: step => dispatch(loadMoreImages(step)),
  handleQuickDefect: (drawing, projectId, inspectionID, file, location, cameraPosition, imagePosition, cameraHelper, depth, withContextualization) =>
    dispatch(handleQuickDefect(drawing, projectId, inspectionID, file, location, cameraPosition, imagePosition, cameraHelper, depth, withContextualization)),
  getImagesForSelectedPoint: (inspectionID, projectID, data, callback) => dispatch(getImagesForSelectedPoint(inspectionID, projectID, data, null, null, false, null, null, callback)),
  getOtherImages: (inspectionID, projectID, selectionType, filter, includeLoader, callback) => dispatch(getOtherImages(inspectionID, projectID, selectionType, filter, includeLoader, callback)),
  duplicateComponent: (id, name, callback) => dispatch(duplicateComponent(id, name, callback)),
  changeRealCameraType: type => dispatch(changeRealCameraType(type)),
  clearUnsavedChangesDirty: () => dispatch(clearUnsavedChangesDirty()),
  toggleDefect: id => dispatch(toggleDefect(id)),
  toggleComponent: id => dispatch(toggleComponent(id)),
  toggleNotification: id => dispatch(setToggledNotification(id)),
});

RightToolbar.defaultProps = {
  showGeometryWarning: true,
};

// Wrapping the class component with ConfirmOnInspectionExitWrapper
const WrappedRightToolbar = props => (
  <ConfirmOnInspectionExitWrapper isDirty={props.isDirty} router={props.router} route={props.route} clearUnsavedChangesDirty={props.clearUnsavedChangesDirty}>
    <RightToolbar {...props} />
  </ConfirmOnInspectionExitWrapper>
);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WrappedRightToolbar));
