import React from 'react';
import PropTypes from 'prop-types';

import '../../styles/isolation-certificate-popover.scss';
import { workOrderFields } from './constants/permit-form-constants';

const PermitWorkOrderPopover = ({ workOrderName, workOrderDescription, workOrderComponents = [] }, { t }) => (
  <div className="isolation-certificate-popover permit-workorder-popover">
    <p className="f-primary field-value title">{workOrderName}</p>
    <p className="f-primary field-label">{t('WORK_ORDER.DESCRIPTION_LABEL')}</p>
    <p className="f-primary field-value">{workOrderDescription}</p>
    <p className="f-secondary-dark field-label">{t('WORK_ORDER.COMPONENTS_SECTION')}</p>
    {workOrderComponents && workOrderComponents.length > 0 && workOrderComponents.map(woComponent => <p className="f-primary field-value">{woComponent[workOrderFields.name]}</p>)}
  </div>
);

PermitWorkOrderPopover.contextTypes = {
  t: PropTypes.func.isRequired,
};

PermitWorkOrderPopover.propTypes = {
  workOrderName: PropTypes.string,
  workOrderDescription: PropTypes.string,
  workOrderComponents: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number,
      Name: PropTypes.string,
    })
  ),
};

export default PermitWorkOrderPopover;
