import routesConstants from './routes';
import { Api } from '../../core/api';
import { API_BASE_URL } from '../../common/constants';

const getDetailsSAP = async values => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_SAP_DETAILS}`, values, { needLoader: true });
};

const testConnectionSAP = async values => {
  return await Api.post(`${API_BASE_URL}${routesConstants.TEST_SAP_CONNECTION}`, values);
};

const saveSAP = async values => {
  return await Api.post(`${API_BASE_URL}${routesConstants.SAVE_SAP_DATA}`, values);
};

const syncSAP = async values => {
  return await Api.post(`${API_BASE_URL}${routesConstants.SYNC_SAP}`, values);
};

const fetchProjectEquipment = async values => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_PROJECT_EQUIPMENT}`, values);
};

const getIntegrationStatus = async values => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_INTEGRATION_STATUS}`, values);
};

const selectLocations = async values => {
  return await Api.post(`${API_BASE_URL}${routesConstants.SELECT_LOCATIONS}`, values);
};

const getNotifications = async values => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_NOTIFICATIONS}`, values);
};

const getNotificationDetails = async values => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_NOTIFICATION_DETAILS}`, values);
};

export default {
  getDetailsSAP,
  testConnectionSAP,
  saveSAP,
  syncSAP,
  fetchProjectEquipment,
  getIntegrationStatus,
  selectLocations,
  getNotifications,
  getNotificationDetails,
};
