import img_0 from '../images/img_0.png';

export const animation = {
  v: '5.5.7',
  fr: 29.9700012207031,
  ip: 0,
  op: 450.000018328876,
  w: 400,
  h: 225,
  nm: 'Drone loader',
  ddd: 0,
  assets: [{ id: 'image_0', w: 512, h: 512, p: img_0, e: 0 }],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Shape Layer 2',
      sr: 1,
      ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [200, 112.5, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 } },
      ao: 0,
      shapes: [],
      ip: 0,
      op: 450.000018328876,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'circle static',
      sr: 1,
      ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [201, 112.5, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [23.099, 23.099, 100], ix: 6 } },
      ao: 0,
      ef: [
        {
          ty: 5,
          nm: 'Glow',
          np: 17,
          mn: 'ADBE Glo2',
          ix: 1,
          en: 1,
          ef: [
            { ty: 7, nm: 'Glow Based On', mn: 'ADBE Glo2-0001', ix: 1, v: { a: 0, k: 2, ix: 1 } },
            { ty: 0, nm: 'Glow Threshold', mn: 'ADBE Glo2-0002', ix: 2, v: { a: 0, k: 153, ix: 2 } },
            { ty: 0, nm: 'Glow Radius', mn: 'ADBE Glo2-0003', ix: 3, v: { a: 0, k: 21, ix: 3 } },
            { ty: 0, nm: 'Glow Intensity', mn: 'ADBE Glo2-0004', ix: 4, v: { a: 0, k: 6.03, ix: 4 } },
            { ty: 7, nm: 'Composite Original', mn: 'ADBE Glo2-0005', ix: 5, v: { a: 0, k: 2, ix: 5 } },
            { ty: 7, nm: 'Glow Operation', mn: 'ADBE Glo2-0006', ix: 6, v: { a: 0, k: 3, ix: 6 } },
            { ty: 7, nm: 'Glow Colors', mn: 'ADBE Glo2-0007', ix: 7, v: { a: 0, k: 1, ix: 7 } },
            { ty: 7, nm: 'Color Looping', mn: 'ADBE Glo2-0008', ix: 8, v: { a: 0, k: 3, ix: 8 } },
            { ty: 0, nm: 'Color Loops', mn: 'ADBE Glo2-0009', ix: 9, v: { a: 0, k: 1, ix: 9 } },
            { ty: 0, nm: 'Color Phase', mn: 'ADBE Glo2-0010', ix: 10, v: { a: 0, k: 0, ix: 10 } },
            { ty: 0, nm: 'A & B Midpoint', mn: 'ADBE Glo2-0011', ix: 11, v: { a: 0, k: 0.5, ix: 11 } },
            { ty: 2, nm: 'Color A', mn: 'ADBE Glo2-0012', ix: 12, v: { a: 0, k: [1, 1, 1, 0], ix: 12 } },
            { ty: 2, nm: 'Color B', mn: 'ADBE Glo2-0013', ix: 13, v: { a: 0, k: [0, 0, 0, 0], ix: 13 } },
            { ty: 7, nm: 'Glow Dimensions', mn: 'ADBE Glo2-0014', ix: 14, v: { a: 0, k: 1, ix: 14 } },
            { ty: 7, nm: 'GPU Rendering', mn: 'ADBE Force CPU GPU', ix: 15, v: { a: 0, k: 1, ix: 15 } },
          ],
        },
      ],
      shapes: [
        { d: 1, ty: 'el', s: { a: 0, k: [697, 697], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false },
        {
          ty: 'st',
          c: { a: 0, k: [0.003921568859, 0.964705884457, 0.647058844566, 1], ix: 3 },
          o: { a: 0, k: 37, ix: 4 },
          w: { a: 0, k: 11, ix: 5 },
          lc: 1,
          lj: 1,
          ml: 4,
          bm: 0,
          d: [
            { n: 'd', nm: 'dash', v: { a: 0, k: 0, ix: 1 } },
            { n: 'o', nm: 'offset', v: { a: 0, k: 0, ix: 7 } },
          ],
          nm: 'Stroke 1',
          mn: 'ADBE Vector Graphic - Stroke',
          hd: false,
        },
      ],
      ip: 0,
      op: 450.000018328876,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'outer ring rotating',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
            { t: 449.000018288145, s: [3600] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [200, 112.5, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [23.099, 23.099, 100], ix: 6 },
      },
      ao: 0,
      ef: [
        {
          ty: 5,
          nm: 'Glow',
          np: 17,
          mn: 'ADBE Glo2',
          ix: 1,
          en: 1,
          ef: [
            { ty: 7, nm: 'Glow Based On', mn: 'ADBE Glo2-0001', ix: 1, v: { a: 0, k: 2, ix: 1 } },
            { ty: 0, nm: 'Glow Threshold', mn: 'ADBE Glo2-0002', ix: 2, v: { a: 0, k: 153, ix: 2 } },
            { ty: 0, nm: 'Glow Radius', mn: 'ADBE Glo2-0003', ix: 3, v: { a: 0, k: 21, ix: 3 } },
            { ty: 0, nm: 'Glow Intensity', mn: 'ADBE Glo2-0004', ix: 4, v: { a: 0, k: 6.03, ix: 4 } },
            { ty: 7, nm: 'Composite Original', mn: 'ADBE Glo2-0005', ix: 5, v: { a: 0, k: 2, ix: 5 } },
            { ty: 7, nm: 'Glow Operation', mn: 'ADBE Glo2-0006', ix: 6, v: { a: 0, k: 3, ix: 6 } },
            { ty: 7, nm: 'Glow Colors', mn: 'ADBE Glo2-0007', ix: 7, v: { a: 0, k: 1, ix: 7 } },
            { ty: 7, nm: 'Color Looping', mn: 'ADBE Glo2-0008', ix: 8, v: { a: 0, k: 3, ix: 8 } },
            { ty: 0, nm: 'Color Loops', mn: 'ADBE Glo2-0009', ix: 9, v: { a: 0, k: 1, ix: 9 } },
            { ty: 0, nm: 'Color Phase', mn: 'ADBE Glo2-0010', ix: 10, v: { a: 0, k: 0, ix: 10 } },
            { ty: 0, nm: 'A & B Midpoint', mn: 'ADBE Glo2-0011', ix: 11, v: { a: 0, k: 0.5, ix: 11 } },
            { ty: 2, nm: 'Color A', mn: 'ADBE Glo2-0012', ix: 12, v: { a: 0, k: [1, 1, 1, 0], ix: 12 } },
            { ty: 2, nm: 'Color B', mn: 'ADBE Glo2-0013', ix: 13, v: { a: 0, k: [0, 0, 0, 0], ix: 13 } },
            { ty: 7, nm: 'Glow Dimensions', mn: 'ADBE Glo2-0014', ix: 14, v: { a: 0, k: 1, ix: 14 } },
            { ty: 7, nm: 'GPU Rendering', mn: 'ADBE Force CPU GPU', ix: 15, v: { a: 0, k: 1, ix: 15 } },
          ],
        },
      ],
      shapes: [
        { d: 1, ty: 'el', s: { a: 0, k: [697, 697], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false },
        {
          ty: 'st',
          c: { a: 0, k: [0.003921568859, 0.964705884457, 0.647058844566, 1], ix: 3 },
          o: { a: 0, k: 100, ix: 4 },
          w: { a: 0, k: 22, ix: 5 },
          lc: 1,
          lj: 1,
          ml: 4,
          bm: 0,
          d: [
            { n: 'd', nm: 'dash', v: { a: 0, k: 335, ix: 1 } },
            { n: 'o', nm: 'offset', v: { a: 0, k: 0, ix: 7 } },
          ],
          nm: 'Stroke 1',
          mn: 'ADBE Vector Graphic - Stroke',
          hd: false,
        },
      ],
      ip: 0,
      op: 450.000018328876,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'Shape Layer 1',
      sr: 1,
      ks: {
        o: { a: 0, k: 30, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
            { t: 449.000018288145, s: [1440] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [200, 112.5, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [41.927, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-41.927, 0],
                  ],
                  v: [
                    [-46, -65.5],
                    [0, -0.5],
                    [52, -61.5],
                    [3.927, -79.707],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            { ty: 'fl', c: { a: 0, k: [0, 0.996078431373, 0.764705882353, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 450.000018328876,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
