import loadingActionTypes from '../constants/action-types';
import { store } from '../../../index';

export const handleGlobalLoader = (isLoading, type) => {
  return {
    type: loadingActionTypes.HANDLE_GLOBAL_LOADER,
    data: {
      isLoading,
      type,
    },
  };
};

export const dispatchGlobalLoaderdWired = payload => store.dispatch(handleGlobalLoader(payload));
