import loginActionTypes from '../constants/action-types';

export const userLogin = data => {
  return {
    type: loginActionTypes.HANDLE_USER_LOGIN,
    data,
  };
};

export const userLoginError = data => {
  return {
    type: loginActionTypes.HANDLE_LOGIN_ERROR,
    data,
  };
};

export const userLogout = data => {
  return {
    type: loginActionTypes.HANDLE_LOG_OUT,
    data,
  };
};

export const setLoginStep = data => {
  return {
    type: loginActionTypes.SET_LOGIN_STEP,
    data,
  };
};
export const setLoginTempToken = data => {
  return {
    type: loginActionTypes.SET_LOGIN_TEMP_TOKEN,
    data,
  };
};
export const setResendExpiration = data => {
  return {
    type: loginActionTypes.RESEND_EXPIRATION,
    data,
  };
};
