import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm } from 'redux-form';

import { debounce, keys } from 'lodash';

import Button from '../../../../../common/form/components/button';
import DatePicker from '../../../../../common/form/components/date-picker';
import MultiSelect from '../../../../../common/form/components/multiselect';

import { getUsers } from '../../../../members/actions/users-data-actions';
import { getComponentSubTypeSuggestions, getComponentTypeSuggestions } from '../../../actions/inspection-actions';

import Helpers from '../../../../../common/helpers';

import { FORMS } from '../../../../../common/constants';

import { filterParams } from '../../../constants/component-constants';

import ToggleRadioInput from '../../../../../common/form/components/toggle-radio-input-field';
import '../../../styles/ch-components-filter.scss';

class ComponentsFilter extends Component {
  constructor(props) {
    super(props);
    this.getComponentTypeSuggestionsDebounced = debounce(props.getComponentTypeSuggestions, 300);
    this.getUsersDebounced = debounce(props.getUsers, 300);
    this.getComponentSubTypeSuggestionsDebounced = debounce(props.getComponentSubTypeSuggestions, 300);
  }
  componentDidMount = () => {
    const { getComponentTypeSuggestions, getUsers, getComponentSubTypeSuggestions } = this.props;
    getComponentTypeSuggestions();
    getComponentSubTypeSuggestions();
    getUsers();
  };

  render() {
    const { t } = this.context;

    const { componentTypeSuggestions, componentSubTypeSuggestions, handleSubmit, resetFilter, users, formValues, submitForm, closeAction } = this.props;

    return (
      <form
        className="ch-filter-form"
        onSubmit={handleSubmit(vals => {
          submitForm(Helpers.removeUnusedKeys(vals));
        })}
      >
        <div className="scrollable">
          <div className="grid">
            <Field
              label={'COMPONENT_FILTERS.COMPONENT_TYPE'}
              id={filterParams.componentTypeFilter}
              name={filterParams.componentTypeFilter}
              component={MultiSelect}
              data={componentTypeSuggestions || []}
              placeholder={'COMPONENT_FILTERS.COMPONENT_TYPE'}
              onSearch={text => {
                this.getComponentTypeSuggestionsDebounced(text);
              }}
              onToggle={isOpen => {
                if (isOpen && componentTypeSuggestions?.length === 0) {
                  this.getComponentTypeSuggestionsDebounced('');
                }
              }}
            />
            <Field
              label={'COMPONENT_FILTERS.COMPONENT_SUB_TYPE'}
              placeholder={'COMPONENT_FILTERS.COMPONENT_SUB_TYPE'}
              id={filterParams.componentSubTypeFilter}
              name={filterParams.componentSubTypeFilter}
              component={MultiSelect}
              data={componentSubTypeSuggestions || []}
              onSearch={text => {
                this.getComponentSubTypeSuggestionsDebounced(text);
              }}
              onToggle={isOpen => {
                if (isOpen && componentTypeSuggestions?.length === 0) {
                  this.getComponentSubTypeSuggestionsDebounced('');
                }
              }}
            />
          </div>
          <div className="grid">
            <Field
              label={'COMPONENT_FILTERS.CREATED_BY'}
              placeholder={'COMPONENT_FILTERS.CREATED_BY'}
              id={filterParams.createdByFilter}
              name={filterParams.createdByFilter}
              component={MultiSelect}
              data={users || []}
              valueField={'UserID'}
              textField={'UserName'}
              onSearch={text => {
                this.getUsersDebounced(text);
              }}
              onToggle={isOpen => {
                if (isOpen && (!users || users.length === 0)) {
                  this.getUsersDebounced('');
                }
              }}
            />
          </div>
          <div className="grid">
            <Field
              disabledKeyboardNavigation={true}
              dateFormat="MMMM d, yyyy"
              id={filterParams.dateFrom}
              name={filterParams.dateFrom}
              component={DatePicker}
              maxDate={formValues?.[filterParams.dateTo] || null}
              popperPlacement="bottom-end"
              placeholder={'COMPONENT_FILTERS.DATE_FROM'}
              label={'COMPONENT_FILTERS.DATE_FROM'}
              isClearable={true}
            />
            <Field
              disabledKeyboardNavigation={true}
              dateFormat="MMMM d, yyyy"
              id={filterParams.dateTo}
              name={filterParams.dateTo}
              component={DatePicker}
              minDate={formValues?.[filterParams.dateFrom] || null}
              popperPlacement="bottom-end"
              placeholder={'COMPONENT_FILTERS.DATE_TO'}
              label={'COMPONENT_FILTERS.DATE_TO'}
              isClearable={true}
            />
          </div>
          <div className="grid">
            <Field
              id={filterParams.hasNotificationFilter}
              name={filterParams.hasNotificationFilter}
              component={ToggleRadioInput}
              label={t('COMPONENTS_FILTERS.HAS_NOTIFICATION')}
              className={'multiple-labels-toggle'}
            />
            <Field
              id={filterParams.hasObservationFilter}
              name={filterParams.hasObservationFilter}
              component={ToggleRadioInput}
              label={t('COMPONENTS_FILTERS.HAS_OBSERVATION')}
              className={'multiple-labels-toggle'}
            />
          </div>

          <div className="grid">
            <Field
              id={filterParams.hasWorkOrderFilter}
              name={filterParams.hasWorkOrderFilter}
              component={ToggleRadioInput}
              label={t('COMPONENTS_FILTERS.HAS_WORK_ORDER')}
              className={'multiple-labels-toggle'}
            />
            <Field
              id={filterParams.hasLocationFilter}
              name={filterParams.hasLocationFilter}
              component={ToggleRadioInput}
              label={t('COMPONENTS_FILTERS.HAS_LOCATION')}
              // sublabel={t('DOWNLOAD_REPORT.IMAGES_PER_PAGE_SUBLABEL')}
              className={'multiple-labels-toggle'}
            />
            <Field
              id={filterParams.isCriticalEquipmentFilter}
              name={filterParams.isCriticalEquipmentFilter}
              component={ToggleRadioInput}
              label={t('COMPONENTS_FILTERS.IS_CRITICAL_EQUIPMENT')}
              className={'multiple-labels-toggle'}
            />
          </div>
        </div>
        <div className="component-filter-action-buttons">
          <Button type="button" variant="gray-outline" text={t('CANCEL')} onClick={closeAction} height="md" width="sm" />
          <div className="component-filter-action-buttons__multiple-wrapper">
            <Button type="button" variant="gray-outline" text={t('RESET_FILTERS')} onClick={resetFilter} height="md" width="sm" />
            <Button text={t('APPLY_FILTERS', { count: keys(Helpers.removeUnusedKeys(formValues)).length || 0 })} height="md" width="sm" />
          </div>
        </div>
      </form>
    );
  }
}
ComponentsFilter.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  getComponentTypeSuggestions: text => dispatch(getComponentTypeSuggestions(text)),
  getComponentSubTypeSuggestions: text => dispatch(getComponentSubTypeSuggestions(text)),
  getUsers: SearchText => dispatch(getUsers({ SearchText })),
});

const mapStateToProps = state => {
  const {
    inspectionReducer: { componentTypeSuggestions, componentSubTypeSuggestions },
    usersTableReducer: { usersTableData },
  } = state;

  return {
    componentTypeSuggestions: componentTypeSuggestions,
    componentSubTypeSuggestions: componentSubTypeSuggestions,
    users: usersTableData,
    formValues: state.form[FORMS.componentsFilter]?.values || {},
  };
};

ComponentsFilter = reduxForm({
  form: FORMS.componentsFilter,
  enableReinitialize: true,
})(ComponentsFilter);

export default connect(mapStateToProps, mapDispatchToProps)(ComponentsFilter);
