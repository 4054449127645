export const formConstants = {
  fields: {
    keyboxesAndKeys: 'AssignedKeyboxesAndKeys',
    section: 'Section',
  },
};

export const keyFields = {
  id: {
    name: 'KeyID',
    label: 'PERMIT.RETURN_KEYS_TABLE.KEY_ID',
  },
  issuedAt: {
    name: 'KeyIssuedAt',
    label: 'PERMIT.RETURN_KEYS_TABLE.KEY_ISSUED_AT',
  },
  issuedTo: {
    name: 'KeyIssuedTo',
    label: 'PERMIT.RETURN_KEYS_TABLE.KEY_ISSUED_TO',
  },
  name: {
    name: 'KeyName',
    label: 'PERMIT.RETURN_KEYS_TABLE.KEY_NAME',
  },
  keyboxId: {
    name: 'KeyboxID',
    label: 'PERMIT.RETURN_KEYS_TABLE.KEYBOX_ID',
  },
  keyboxName: {
    name: 'KeyboxName',
    label: 'PERMIT.RETURN_KEYS_TABLE.KEYBOX_NAME',
  },
};

export const tableConfig = [
  {
    title: keyFields.name.label,
    key: keyFields.name.name,
  },
  {
    title: keyFields.keyboxName.label,
    key: keyFields.keyboxName.name,
  },
  {
    key: 'returnKeyCheckboxes',
    type: 'action',
  },
];
