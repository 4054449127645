import ReactDOM from 'react-dom';
const Portal = props => {
  const el = document.getElementById(props.id);

  if (!el || !props.shouldRender) {
    return null;
  }
  return ReactDOM.createPortal(props.children, el);
};

Portal.defaultProps = {
  shouldRender: true,
  id: 'root',
};

export default Portal;
