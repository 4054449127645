import actionTypes from '../constants/action-types';
import { defaultFilters } from '../components/isolation-certificates/constants/isolation-certificates-table';

const isolationCertificatesState = {
  isolationCertificates: [],
  isolationCertificatesLoading: false,
  filters: defaultFilters,
};

export const isolationCertificatesReducer = (state = isolationCertificatesState, payload) => {
  switch (payload.type) {
    case actionTypes.SET_ISOLATION_CERTIFICATES:
      return {
        ...state,
        isolationCertificatesLoading: payload.data.isLoading || false,
        isolationCertificates: payload.data.isolationCertificates || [],
      };
    case actionTypes.SET_ISOLATION_CERTIFICATES_FILTERS:
      return {
        ...state,
        filters: payload.data,
      };
    default:
      return state;
  }
};
