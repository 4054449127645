import { fields } from '../constants/permit-form-constants';

export const validate = values => {
  const errors = {};
  const { permitTemplate, name, description } = fields;

  if (!values[permitTemplate.name]) {
    errors[permitTemplate.name] = permitTemplate.errorRequired;
  }

  if (!values[name.name]) {
    errors[name.name] = name.errorRequired;
  }

  if (values[name.name] && values[name.name].length < name.minChars) {
    errors[name.name] = name.errorMinChars;
  }

  if (values[name.name] && values[name.name].length > name.maxChars) {
    errors[name.name] = name.errorMaxChars;
  }

  if (values[description.name] && values[description.name].length > description.maxChars) {
    errors[description.name] = description.errorMaxChars;
  }

  return errors;
};
