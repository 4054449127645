const formConstants = {
  fields: {
    clientID: 'ClientID',
    clientToken: 'ClientSecret',
    activated: 'Activated',
    synchronized: 'Synchronized',
    integrationUrl: 'IntegrationURL',
  },
};

const DefaultNotification = {
  NotificationID: '1BE97E98252343ADB816DADE77642D1B',
  ShortDescription: 'CW_DK_Test_LIVEO_NOTI',
  Status: 'NEW',
  StatusDescription: 'New',
  NotificationType: 'M1',
  NotificationTypeDescription: 'Maintenance Request',
  Priority: 20,
  PriorityDescription: 'Very High',
  IsInternal: '1',
  CreatedBy: 'Christian Warmuth',
  CreationDateTime: '2020-05-14',
  LastChangedBy: 'Christian Warmuth',
  LastChangeDateTime: '2020-05-14',
  LongDescription: 'CW_DK_Test_LIVEO_NOTI',
  StartDate: '2020-05-14',
  EndDate: '2020-05-15',
  MalfunctionStartDate: '2020-05-05',
  MalfunctionEndDate: '',
  ProgressStatus: '15',
  ProgressStatusDescription: 'Pending',
  EquipmentID: '',
  EquipmentName: '10000024',
  RootEquipmentID: '',
  RootEquipmentName: '10000024',
  LocationID: '',
  Breakdown: '0',
  Coordinates: '',
  Source: 'Intermixx',
  OperatorID: '',
  Location: '',
  AssetCoreEquipmentID: '68FEE7F9AE4E47879C541D73EA9E0430',
  Operator: '',
  InternalID: 'NO.OPER.100',
  ModelID: '9C41FDE9C2DF458591AA4D87FF28A298',
  ProposedFailureModeID: '',
  ProposedFailureModeDisplayID: '',
  ProposedFailureModeDesc: '',
  ConfirmedFailureModeID: '',
  ConfirmedFailureModeDesc: '',
  ConfirmedFailureModeDisplayID: '',
  SystemProposedFailureModeID: '',
  SystemProposedFailureModeDesc: '',
  SystemProposedFailureModeDisplayID: '',
  EffectID: '',
  EffectDisplayID: '',
  EffectDesc: '',
  CauseID: '',
  CauseDisplayID: '',
  CauseDesc: '',
  InstructionID: '',
  InstructionTitle: '',
};

const integrationSatus = {
  inProgress: 'IN PROGRESS',
  notStarted: 'NOT STARTED',
  completed: 'COMPLETED',
  failed: 'ERROR',
};

export default {
  formConstants,
  DefaultNotification,
  integrationSatus,
};
