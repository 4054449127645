import actionCreators from '../constants/action-types';
import { formConstants as checklistConstants } from '../constants/checklist-constants';
import { formConstants as regimeConstatns, fileFields, componentFields } from '../constants/regime-constants';

//checklist
export const setChecklistList = data => {
  return {
    type: actionCreators.SET_CHECKLIST_LIST,
    data,
  };
};

export const amendChecklistList = data => {
  return {
    type: actionCreators.AMEND_CHECKLIST_LIST,
    data,
  };
};

export const addChecklistAtIndex = (item, index = 0) => {
  return {
    type: actionCreators.ADD_CHECKLIST_ITEM_AT_INDEX,
    data: {
      item,
      index,
    },
  };
};

export const handleRemoveChecklistByProp = (item, prop = checklistConstants.fields.id) => {
  return {
    type: actionCreators.REMOVE_CHECKLIST_BY_PROP,
    data: {
      item,
      prop,
    },
  };
};

export const updateChecklistByProp = (item, prop = checklistConstants.fields.id) => {
  return {
    type: actionCreators.UPDATE_CHECKLIST_ITEM_BY_PROP,
    data: {
      item,
      prop,
    },
  };
};

export const setChecklistTypes = data => {
  return {
    type: actionCreators.SET_CHECKLIST_TYPES,
    data,
  };
};

export const setSelectedChecklist = data => {
  return {
    type: actionCreators.SET_SELECTED_CHECKLIST,
    data,
  };
};

export const updateSelectedChecklist = data => {
  return {
    type: actionCreators.UPDATE_SELECTED_CHECKLIST,
    data,
  };
};

//checklist items

export const setSelectedChecklistItems = data => {
  return {
    type: actionCreators.SET_SELECTED_CHECKLIST_ITEMS,
    data,
  };
};

export const handleAddChecklistItemAtIndex = (item, index = 0) => {
  return {
    type: actionCreators.HANDLE_ADD_CHECKLIST_ITEM_AT_INDEX,
    data: {
      item,
      index,
    },
  };
};

export const handleUpdateChecklistItemByProp = (item, prop = checklistConstants.fields.checklistItemID) => {
  return {
    type: actionCreators.HANDLE_UPDATE_CHECKLIST_ITEM_BY_PROP,
    data: {
      item,
      prop,
    },
  };
};

export const handleRemoveChecklistItemByProp = (item, prop = checklistConstants.fields.checklistItemID) => {
  return {
    type: actionCreators.HANDLE_REMOVE_CHECKLIST_ITEM_BY_PROP,
    data: {
      item,
      prop,
    },
  };
};

// regime
export const setRegimeList = data => {
  return {
    type: actionCreators.SET_REGIME_LIST,
    data,
  };
};

export const amendRegimeList = data => {
  return {
    type: actionCreators.AMEND_REGIME_LIST,
    data,
  };
};

export const setSelectedRegime = data => {
  return {
    type: actionCreators.SET_SELECTED_REGIME,
    data,
  };
};

export const setRegimeDetailsLoading = data => {
  return {
    type: actionCreators.SET_REGIME_DETAILS_LOADING,
    data,
  };
};

export const addRegimeAtIndex = (item, index = 0) => {
  return {
    type: actionCreators.ADD_REGIME_AT_INDEX,
    data: {
      item,
      index,
    },
  };
};
export const updateSelectedRegime = data => {
  return {
    type: actionCreators.UPDATE_SELECTED_REGIME,
    data,
  };
};

export const removeRegimeByProp = (item, prop = 'ID') => {
  return {
    type: actionCreators.REMOVE_REGIME_BY_PROP,
    data: {
      item,
      prop,
    },
  };
};

export const updateRegimeItemByProp = (item, prop = regimeConstatns.fields.id) => {
  return {
    type: actionCreators.UPDATE_REGIME_ITEM_BY_PROP,
    data: {
      item,
      prop,
    },
  };
};

export const setIntervalList = data => {
  return {
    type: actionCreators.SET_INTERVAL_LIST,
    data,
  };
};

export const setRegimeFiles = data => {
  return {
    type: actionCreators.SET_REGIME_FILES,
    data,
  };
};

export const removeRegimeFileByGroup = (file, group, prop = fileFields.id) => {
  return {
    type: actionCreators.REMOVE_REGIME_FILE_BY_GROUP,
    data: {
      file,
      group,
      prop,
    },
  };
};

export const setRegimeComponents = data => {
  return {
    type: actionCreators.SET_REGIME_COMPONENTS,
    data,
  };
};

export const addComponentAtIndex = (item, index = -1) => {
  return {
    type: actionCreators.ADD_COMPONENT_TO_INDEX,
    data: {
      item,
      index,
    },
  };
};

export const removeRegimeComponentByProp = (item, prop = componentFields.id) => {
  return {
    type: actionCreators.REMOVE_REGIME_COMPONENT_BY_PROP,
    data: {
      item,
      prop,
    },
  };
};

// regime Checklists

export const setRegimeChecklists = data => {
  return {
    type: actionCreators.SET_REGIME_CHECKLISTS,
    data,
  };
};

export const removeRegimeChecklistByProp = (item, prop = checklistConstants.fields.id) => {
  return {
    type: actionCreators.REMOVE_REGIME_CHECKLIST_BY_PROP,
    data: {
      item,
      prop,
    },
  };
};
export const addRegimeChecklist = data => {
  return {
    type: actionCreators.ADD_REGIME_CHECKLIST,
    data,
  };
};

export const linkRegimeCPTemplateToComponents = (item, prop = 'ID') => {
  return {
    type: actionCreators.LINK_REGIME_CP_TEMPLATE_TO_COMPONENTS,
    data: {
      item,
      prop,
    },
  };
};

export const unlinkRegimeCPTemplateFromComponents = (item, prop = 'ID') => {
  return {
    type: actionCreators.UNLINK_REGIME_CP_TEMPLATE_FROM_COMPONENTS,
    data: {
      item,
      prop,
    },
  };
};

export const removeRegimeComponentFromCPTemplate = (item, component, prop = 'ID') => {
  return {
    type: actionCreators.REMOVE_REGIME_COMPONENT_FROM_CP_TEMPLATE,
    data: {
      item,
      component,
      prop,
    },
  };
};

export const setRegimeTasks = data => {
  return {
    type: actionCreators.SET_REGIME_TASKS,
    data,
  };
};
