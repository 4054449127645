const formConstants = {
  fields: {
    id: 'ComponentID',
    name: 'ComponentName',
  },
};

const potreeId = 'component_picker_potree';

export const componentsTableHeader = [
  {
    title: 'COMPONENTS.ID',
    key: formConstants.fields.id,
  },
  {
    title: 'COMPONENTS.NAME',
    key: formConstants.fields.name,
  },
];

export default {
  potreeId,
  formConstants,
  componentsTableHeader,
};
