import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Field, reduxForm } from 'redux-form';

import { validate } from '../validators/download-report-validators-xslx';

import MultiSelect from '../../form/components/multiselect';
import DatePicker from '../../form/components/date-picker';
import Button from '../../form/components/button';
import ToggleField from '../../form/components/toggle-field';

import { FORMS } from '../../constants';

import constants from '../constants/download-report-constants';

class DownloadReportFromXslx extends Component {
  constructor(props) {
    super(props);
    this.parentRef = React.createRef();
    this.state = {
      dateFrom: new Date().setDate(new Date().getDate() - 7),
      dateTo: new Date(),
    };
  }

  render() {
    const { t } = this.context;
    const { dateFrom, dateTo } = this.state;
    const { handleSubmit, fetchingReportData, invalid, ndtMeasurementsComponents, ndtMeasurementsUnits, submitTrigger, filterGroups, skipStep, change, closeModal } = this.props;

    return (
      <>
        <form className={`download-report-form`} onSubmit={handleSubmit} noValidate>
          {!skipStep && (
            <Fragment>
              <Field
                label={'DOWNLOAD_REPORT_MEASUREMENT.COMPONENTS'}
                id={constants.fields.components}
                name={constants.fields.components}
                component={MultiSelect}
                disabled={isEmpty(ndtMeasurementsComponents)}
                data={ndtMeasurementsComponents || []}
                valueField={constants.objectConstruction.key}
                textField={constants.objectConstruction.value}
              />
              <Field
                label={'DOWNLOAD_REPORT_MEASUREMENT.UNITS'}
                id={constants.fields.measurmentUnits}
                name={constants.fields.measurmentUnits}
                component={MultiSelect}
                disabled={isEmpty(ndtMeasurementsUnits)}
                data={ndtMeasurementsUnits || []}
                valueField={constants.objectConstruction.key}
                textField={constants.objectConstruction.value}
              />
              <div className="single-option">
                <div className="left">
                  <p className="bold">{t('DOWNLOAD_REPORT_MEASUREMENT.INCLUDE_ALARMS_TRIGGERED_TITLE')}</p>
                  <p className="">{t('DOWNLOAD_REPORT_MEASUREMENT.INCLUDE_ALARMS_TRIGGERED_TEXT')}</p>
                </div>

                <div className="right">
                  <Field id={constants.fields.includeAlarmsTriggered} name={constants.fields.includeAlarmsTriggered} component={ToggleField} />
                </div>
              </div>
              <Field
                label={'DOWNLOAD_REPORT_MEASUREMENT.FILTER_GROUPS'}
                id={constants.fields.filterGroups}
                name={constants.fields.filterGroups}
                component={MultiSelect}
                disabled={isEmpty(filterGroups)}
                data={filterGroups || []}
                valueField={constants.objectConstruction.key}
                textField={constants.objectConstruction.value}
              />
            </Fragment>
          )}
          <div className="dates">
            <div className="date-wrapper">
              <p className="f-secondary-dark">{t('DOWNLOAD_REPORT.FILTER_DATE_FROM')}</p>
              <Field
                selected={dateFrom}
                dateFormat="MMMM d, yyyy"
                id={constants.fields.dateFrom}
                name={constants.fields.dateFrom}
                onChange={(e, d) => {
                  change(constants.fields.dateFrom, d);
                  this.setState({ dateFrom: d });
                }}
                maxDate={dateTo}
                component={DatePicker}
              />
            </div>
            <div className="date-wrapper">
              <p className="f-secondary-dark">{t('DOWNLOAD_REPORT.FILTER_DATE_TO')}</p>

              <Field
                selected={dateTo}
                dateFormat="MMMM d, yyyy"
                id={constants.fields.dateTo}
                name={constants.fields.dateTo}
                onChange={(e, d) => {
                  change(constants.fields.dateTo, d);
                  this.setState({ dateTo: d });
                }}
                minDate={dateFrom}
                component={DatePicker}
              />
            </div>
          </div>
        </form>
        <div className="multiple-buttons">
          <Button type="button" variant="gray-outline" width="sm" text={t('DOWNLOAD_REPORT.CANCEL_BUTTON')} onClick={closeModal} />
          <Button disabled={fetchingReportData || invalid} width="sm" text={t('DOWNLOAD_REPORT.DOWNLOAD_BUTTON')} onClick={submitTrigger} />
        </div>
      </>
    );
  }
}
DownloadReportFromXslx.contextTypes = {
  t: PropTypes.func.isRequired,
};

DownloadReportFromXslx = reduxForm({
  form: FORMS.downloadReportXslx,
  enableReinitialize: true,
  validate,
})(DownloadReportFromXslx);

export default DownloadReportFromXslx;
