import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../../common/form/components/button';
import Icon from '../../../../../common/icon/components/icon';
// import FilesManipulation from '../../right-toolbar/isolation-certificate-details/components/details/files-manipulation';
import { ComponentPicker } from '../../../../../common/question-components/components';
import InfoTooltip from '../../../../../common/tooltip/components/info-tooltip';

import Helpers from '../../../../../common/helpers';
import { componentDetailsDisplayProps, fields } from '../constants/components-constants';
import '../../../styles/permit-components-modal.scss';
import '../../../styles/isolation-components-modal.scss';

const PermitComponentsModal = (
  {
    permitId,
    components,
    selectedComponents = [],
    handleComponentSelected,
    handleComponentPicker3D,
    handleComponentPickerPDF,
    handleComponentPickerCH,
    handleDeleteComponent,
    // toggleUploadModal,
    // handleDeleteFile,
    closeAction,
    // handleScreenshotFrom3D,
  },
  { t }
) => {
  const onComponentSelected = (permitId, components) => {
    if (components && components.length > 0) {
      handleComponentSelected(permitId, components);
    }
  };

  return (
    <div className="isolation-components-modal">
      {components && components.length > 0 && (
        <ComponentPicker
          components={components}
          questionId={permitId}
          handleComponentSelected={onComponentSelected}
          handleComponentPicker3D={() => handleComponentPicker3D()}
          handleComponentPickerPDF={() => handleComponentPickerPDF()}
          handleComponentPickerCH={() => handleComponentPickerCH()}
          selectedComponents={selectedComponents}
        />
      )}
      {selectedComponents && selectedComponents.length > 0 && (
        <div className="selected-components">
          <p className="f-secondary-dark">{t('ISOLATION_CERTIFICATE.ADD_COMPONENTS_TITLE', { number: selectedComponents.length })}</p>
          <div className="selected-components-container">
            {selectedComponents.map((component, index) => (
              <div key={`isolation-component-${index}`}>
                <div className="component-title-wrapper">
                  <p className="f-primary">{`${index + 1}.`}</p>
                  <div className="component-item">
                    <div className="component-name-container">
                      <p className="f-primary">{component[fields.componentName.name]}</p>
                      <InfoTooltip
                        actionsMenu={Helpers.mapInfoIconDisplayProps(component, componentDetailsDisplayProps)}
                        offsetY={8}
                        offsetX={8}
                        Component={() => <Icon name="info" className="info-tooltip-icon" size="sm" />}
                        componentProps={{ title: '' }}
                      />
                      <Icon
                        name="close"
                        className="delete-action"
                        onClick={() => {
                          handleDeleteComponent(permitId, component[fields.componentId.name]);
                        }}
                      />
                    </div>
                    {/* <FilesManipulation
                      componentId={component[fields.component.name]}
                      files={component[fields.files.name]}
                      toggleUploadModal={toggleUploadModal}
                      handleDeleteFile={handleDeleteFile}
                      isInModal
                      handleScreenshotFrom3D={handleScreenshotFrom3D}
                    /> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className={`isolations-modal-footer ${selectedComponents && selectedComponents.length > 0 ? 'isolations-modal-footer-with-border' : ''}`}>
        <div className="action-buttons-container">
          <Button variant="success-outline" height="md" width="sm" text={t('ISOLATION_CERTIFICATE.CANCEL_BUTTON')} onClick={closeAction} />
          <Button className="save-btn" height="md" width="sm" variant="success" text={t('ISOLATION_CERTIFICATE.SAVE_BUTTON')} onClick={closeAction} />
        </div>
      </div>
    </div>
  );
};

PermitComponentsModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

PermitComponentsModal.propTypes = {
  permitId: PropTypes.number,
  components: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
    })
  ),
  selectedComponents: PropTypes.arrayOf(
    PropTypes.shape({
      ComponentID: PropTypes.number,
      DeisolationOrder: PropTypes.number,
      DesiredState: PropTypes.string,
      Files: PropTypes.array,
      IsolationMethod: PropTypes.string,
      IsolationOrder: PropTypes.number,
      Name: PropTypes.string,
      NormalPosition: PropTypes.string,
    })
  ),
  handleComponentSelected: PropTypes.func.isRequired,
  handleComponentPicker3D: PropTypes.func,
  handleComponentPickerPDF: PropTypes.func,
  handleDeleteComponent: PropTypes.func.isRequired,
  //   toggleUploadModal: PropTypes.func.isRequired,
  //   handleDeleteFile: PropTypes.func.isRequired,
  //   handleScreenshotFrom3D: PropTypes.func.isRequired,
};

export default PermitComponentsModal;
