import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { FORMS } from '../../../../../common/constants';

import ColorPalette from '../../../../../common/form/components/color-pallete';
import BasicInput from '../../../../../common/form/components/input';
import CustomInputRange from '../../common/input/input-range';
import SetCameraPosition from '../set-camera-position';

import { modelDetailsConstants, zoneAreaConstants } from '../../../constants/explosive-zones-constants';
import { workAreaConstants } from '../../../constants/work-order-constants';
import { validate } from '../validators/area-measurement-validator';

class CylinderShapeForm extends Component {
  render() {
    const { t } = this.context;
    const { handleSubmit, data, handleInputChange, handleModalDetailsChange, disabled, nameField, FormButtons, viewer } = this.props;

    const modelDetails = data[zoneAreaConstants.fields.modelDetails];
    return (
      <form onSubmit={handleSubmit}>
        <Field
          component={BasicInput}
          inputProps={{ value: data[nameField] }}
          handleChange={val => handleInputChange(nameField, val)}
          placeholder={t('WORK_ORDER_DETAILS.FORM_NAME')}
          label={t('WORK_ORDER_DETAILS.FORM_NAME')}
          labelClass="f-secondary-dark defect-form__label"
          type="text"
          disabled={disabled}
          name={nameField}
          id={nameField}
          size="lg"
          isRequired
        />
        <Field
          id={workAreaConstants.fields.color}
          name={workAreaConstants.fields.color}
          handleChange={val => handleInputChange(workAreaConstants.fields.color, val)}
          component={ColorPalette}
          label="COLOUR_TAG"
          size="xl"
          isRequired
        />
        <CustomInputRange
          inputProps={{
            step: 0.5,
            formatLabel: value => (value % 1 === 0 ? value : parseFloat(value).toFixed(2)),
          }}
          input={{
            onChange: val => {
              handleModalDetailsChange(modelDetailsConstants.fields.radiusTop, val);
            },
            value: modelDetails && modelDetails[modelDetailsConstants.fields.radiusTop],
          }}
          minValue={1}
          maxValue={10}
          placeholder={t('3D_SHAPE.FORM_TOP_DIAMETER')}
          label={t('3D_SHAPE.FORM_TOP_DIAMETER')}
          labelClass="f-secondary-dark"
          hideBorder
          disabled={disabled}
          type="range"
          size="lg"
        />
        <CustomInputRange
          inputProps={{
            step: 0.5,
            formatLabel: value => (value % 1 === 0 ? value : parseFloat(value).toFixed(2)),
          }}
          input={{
            onChange: val => {
              handleModalDetailsChange(modelDetailsConstants.fields.radiusBottom, val);
            },
            value: modelDetails && modelDetails[modelDetailsConstants.fields.radiusBottom],
          }}
          minValue={1}
          maxValue={10}
          placeholder={t('3D_SHAPE.FORM_BOTTOM_DIAMETER')}
          label={t('3D_SHAPE.FORM_BOTTOM_DIAMETER')}
          labelClass="f-secondary-dark"
          hideBorder
          disabled={disabled}
          type="range"
          size="lg"
        />
        <CustomInputRange
          inputProps={{
            step: 0.5,
            formatLabel: value => (value % 1 === 0 ? value : parseFloat(value).toFixed(2)),
          }}
          input={{
            onChange: val => {
              handleModalDetailsChange(modelDetailsConstants.fields.height, val);
            },
            value: modelDetails && modelDetails[modelDetailsConstants.fields.height],
          }}
          minValue={1}
          maxValue={10}
          placeholder={t('3D_SHAPE.FORM_HEIGHT')}
          label={t('3D_SHAPE.FORM_HEIGHT')}
          labelClass="f-secondary-dark"
          hideBorder
          disabled={disabled}
          type="range"
          size="lg"
        />
        <SetCameraPosition disabled={disabled} handleChange={newValue => handleInputChange('CameraPosition', { coordinates: newValue })} horizontalAlign="left" viewer={viewer} />
        <FormButtons />
      </form>
    );
  }
}

CylinderShapeForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

CylinderShapeForm = reduxForm({
  form: FORMS.cylinderShapeForm,
  validate,
  enableReinitialize: true,
})(CylinderShapeForm);

const mapStateToProps = (state, props) => ({
  initialValues: props.data,
});

export default connect(mapStateToProps, null)(CylinderShapeForm);
