export default {
  CREATE_CHECKLIST_PROCEDURE: '/project/checklists/create',
  FETCH_CHECKLISTS_PROCEDURES: '/project/checklists/list',
  DELETE_CHECKLIST_PROCEDURE: '/project/checklists/delete',
  GET_CHECKLIST_PROCEDURE: '/project/checklists/details',
  UPDATE_CHECKLIST_PROCEDURE: '/project/checklists/update',
  CHANGE_CHECKLIST_PROCEDURE_STATUS: '/project/checklists/status/update',
  UPDATE_SECTION: '/project/checklists/section/update',
  FETCH_CHECKLIST_PROCEDURE_SECTIONS: '/project/checklists/section/list',
  ADD_SECTION_QUESTION: '/project/checklists/section/question/add',
  DELETE_SECTION: '/project/checklists/section/delete',
  ADD_SECTION: '/project/checklists/sections/add',
  UPDATE_SECTION_QUESTION: '/project/checklists/section/question/update',
  DELETE_SECTION_QUESTION: '/project/checklists/section/question/delete',
  FETCH_QUESTION_TYPES: '/project/checklist/template/item/type/list',
  ADD_QUESTION_COMPONENT: '/project/checklists/section/question/component/add',
  DELETE_QUESTION_COMPONENT: '/project/checklists/section/question/component/delete',
  ADD_QUESTION_OPTION: '/project/checklists/section/question/option/add',
  UPDATE_QUESTION_OPTION: '/project/checklists/section/question/option/update',
  DELETE_QUESTION_OPTION: '/project/checklists/section/question/option/delete',
  ORDER_SECTIONS: '/project/checklists/section/order',
  ORDER_SECTION_QUESTIONS: '/project/checklists/section/question/order',
  ADD_QUESTION_WORK_AREA: '/project/checklists/section/question/area/add',
  DELETE_QUESTION_WORK_AREA: '/project/checklists/section/question/area/delete',
  UPDATE_QUESTION_WORK_AREA: '/project/checklists/section/question/area/update',
  CHANGE_CHECKLIST_PROCEDURE_STATUS_DRAFT: '/project/checklists/status/update/draft',
  COPY_CHECKLIST_PROCEDURE: '/project/checklists/copy',
  DELETE_SECTION_QUESTION_FILE: '/project/checklists/section/question/file/unlink',
  GET_CHECKLIST_PROCEDURES_CUSTOM_PROPERTY_NAMES: '/properties/checklist/template/names',
  GET_CHECKLIST_PROCEDURES_CUSTOM_PROPERTIES: '/properties/checklist/template/list',
  CHECKLIST_PROCEDURES_CUSTOM_PROPERTIES_ADD: '/properties/checklist/template/add',
  CHECKLIST_PROCEDURES_CUSTOM_PROPERTIES_UPDATE: '/properties/checklist/template/update',
  CHECKLIST_PROCEDURES_CUSTOM_PROPERTIES_DELETE: '/properties/checklist/template/delete',
  ADD_QUESTION_TAG_LOCATION: '/project/checklists/section/question/geometry/update',
  GENERATE_PDF: '/project/checklist/template/pdf',
  FETCH_CHECKLIST_QUESTION_FILES: '/project/document/checklist/question/files/list',
  FETCH_CHECKLIST_PROCEDURE_SECTIONS_V2: '/project/checklists/section/list/v2',
  FETCH_CHECKLIST_PROCEDURE_SECTION_QUESTIONS: '/project/checklists/section/question/list',
};
