import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { debounce, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { FieldArray, reduxForm } from 'redux-form';

import Button from '../../../common/form/components/button';
import Icon from '../../../common/icon/components/icon';
import ChecklistsProceduresCustomPropertyFields from './checklists-procedures-custom-property-fields';
import { FORMS } from '../../../common/constants';
import { fields as fieldConstants } from '../../../common/advanced-filter-custom-property/constants/constants';
import { fields as checklistFields } from '../constants/checklists-procedures-constants';
import { validate, validateSingleField } from '../validators/checklist-procedures-custom-properties-validator';

const ChecklistsProceduresCustomProperties = ({ isDisabled, isLoading, handleBackButtonClick, handleSubmit, formValues, submitForm, updateCustomProperty, deleteCustomProperty }, { t }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSinglePropertyChange = useCallback(
    debounce((property, newVal, index, fieldName) => {
      if (property?.[fieldConstants.id]) {
        const propertyError = validateSingleField(property, index, []);

        const isValid = isEmpty(propertyError);
        if (isValid) {
          // Update property
          updateCustomProperty(property);
        }
      }
    }, 500),
    []
  );

  return (
    <form onSubmit={handleSubmit(submitForm)} className="checklists-procedures-management__container__second-step">
      <div className="checklists-procedures-management__container__second-step__description">
        <Icon name="info" handleHover={false} size="sm" />
        <p className="f-primary">{t('CHECKLISTS_PROCEDURES_MANAGEMENT.SECOND_STEP_DESCRIPTION')}</p>
      </div>
      <FieldArray
        name={checklistFields.properties}
        arrayName={checklistFields.properties}
        component={ChecklistsProceduresCustomPropertyFields}
        formValues={formValues}
        onChange={onSinglePropertyChange}
        deleteCustomProperty={deleteCustomProperty}
        isDisabled={isDisabled || isLoading}
        form={FORMS.checklistProceduresTemplateCustomProperties}
      />

      <div className="checklists-procedures-management__container__second-step__buttons">
        <div className="checklists-procedures-management__container__second-step__buttons__wrapper">
          <Button
            variant="gray-outline"
            type="button"
            text={'BACK'}
            width="sm"
            height="md"
            className="checklists-procedures-management__container__second-step__buttons__wrapper__btn"
            onClick={handleBackButtonClick}
          />
          <Button text={'NEXT'} width="sm" height="md" />
        </div>
      </div>
    </form>
  );
};

ChecklistsProceduresCustomProperties.contextTypes = {
  t: PropTypes.func.isRequired,
};

ChecklistsProceduresCustomProperties.propTypes = {
  isLoading: false,
  isDisabled: PropTypes.bool,
  handleBackButtonClick: PropTypes.func.isRequired,
  handleFinishButtonClick: PropTypes.func.isRequired,
};

ChecklistsProceduresCustomProperties.defaultProps = {
  isDisabled: false,
};

const mapStateToProps = (state, { initialValues }) => {
  return {
    initialValues,
    formValues: state.form[FORMS.checklistProceduresTemplateCustomProperties]?.values || {},
  };
};

export default connect(
  mapStateToProps,
  null
)(
  reduxForm({
    form: FORMS.checklistProceduresTemplateCustomProperties,
    enableReinitialize: true,
    validate,
  })(ChecklistsProceduresCustomProperties)
);
