import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import ItemsTableRenderer from '../../../../common/items-renderer/components/items-table-renderer';
import CheckBox from '../../../../common/form/components/checkbox';
import Button from '../../../../common/form/components/button';

import Helpers from '../../../../common/helpers';
import ReducerHelpers from '../../../../common/reducer-helpers';

import { getHandbackStatusData } from '../../actions/permit-actions';
import { formConstants, keyFields, tableConfig } from './constants/return-keys-constants';

import '../../styles/return-keys-modal.scss';

class ReturnKeysModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      keys: [],
      keysToReturn: [],
    };
  }

  componentDidMount() {
    const { ModuleID, SectionID, getHandbackStatusData, handleSubmit } = this.props;
    this.setState({ isLoading: true });
    const params = {
      ModuleID,
      SectionID,
    };
    getHandbackStatusData(params, (isSuccess, handbackData) => {
      if (isSuccess) {
        if (isEmpty(handbackData[formConstants.fields.keyboxesAndKeys])) {
          // If there is no keys change status immedietely
          handleSubmit([]);
        }
        this.setState({
          isLoading: false,
          keys: handbackData[formConstants.fields.keyboxesAndKeys] || [],
        });
      } else {
        this.setState({ isLoading: false });
      }
    });
  }

  isChecked = item => {
    const { keysToReturn } = this.state;
    const foundIndex = keysToReturn.findIndex(returnedKey => returnedKey[keyFields.id.name] === item[keyFields.id.name]);

    return foundIndex > -1;
  };

  render() {
    const { t } = this.context;
    const { isLoading, keys, keysToReturn } = this.state;
    const { className, closeAction, handleSubmit } = this.props;
    return (
      <div className={`return-keys-modal ${className || ''}`}>
        <div className="table-container">
          <ItemsTableRenderer
            isLoading={isLoading}
            tableConfig={tableConfig}
            translationModule={t}
            data={keys}
            formatCell={(value, type, index, item) => {
              if (type === 'action') {
                const isChecked = this.isChecked(item);
                return (
                  <div className="return-keys-modal__checkbox-container">
                    <CheckBox
                      input={{
                        value: isChecked,
                        onChange: e => this.setState({ keysToReturn: ReducerHelpers.addItemToIndex(keysToReturn, item, keysToReturn.length - 1) }),
                      }}
                      label={'TABLE_COLUMN_DATA.YES'}
                    />
                    <CheckBox
                      input={{
                        value: !isChecked,
                        onChange: e => this.setState({ keysToReturn: ReducerHelpers.removeItemByProp(keysToReturn, item, keyFields.id.name) }),
                      }}
                      label={'TABLE_COLUMN_DATA.NO'}
                    />
                  </div>
                );
              }
              return Helpers.formatCell(value, type, index, item);
            }}
          />
        </div>
        <div className="buttons">
          <Button type="button" variant="gray-outline" width="sm" text={t('PERMIT.RETURN_KEYS_MODAL.CANCEL')} onClick={closeAction} />
          <Button
            type="button"
            onClick={() => handleSubmit(keysToReturn.map(returnedKey => ({ KeyboxID: returnedKey[keyFields.keyboxId.name], KeyID: returnedKey[keyFields.id.name] })))}
            className="buttons__submit"
            disabled={isLoading}
            width="sm"
            text={t('PERMIT.RETURN_KEYS_MODAL.CONFIRM')}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userReducer,
});

const mapDispatchToProps = dispatch => ({
  getHandbackStatusData: (data, callback) => dispatch(getHandbackStatusData(data, callback)),
});

ReturnKeysModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReturnKeysModal);
