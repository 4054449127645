import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { debounce, values } from 'lodash';

import Helpers from '../../../../../common/helpers';

import SearchInput from '../../../../../common/input/components/search-input';
import TableComponent from '../../../../../common/table/components/table-component';
import Modal from '../../../../../common/modal/components/modal';
import UploadProjectAssetsModal from '../../../../project/components/upload-assets-modal';
import SmallDropdown from '../../../../../common/small-dropdown/components/small-dropdown';
import DisplayStatus from '../../../../../common/display-status/components/display-status';
import LoadMore from '../../../../../common/load-more/components/load-more';
import IsolationTemplateModal from './isolation-template-modal';
import RenderIf from '../../../../../common/render-if/components/render-if';

import { uploadAsset, setUploadItems, setSingleUploadItem } from '../../../../upload/actions/upload-actions';
import { setSelectedIsolationTemplate } from '../../../actions/action-creators';
import { deleteIsolationTemplate, duplicateIsolationTemplate, getIsolationTemplateDetails, updateLiveStatus, updateStatus } from '../../../actions/isolation-actions';

import { isolationTableHeader, formConstants, defaultFilter, filterFields, sortDirection, statuses } from '../constants/constants';
import { uploadType } from '../../../../upload/constants/constants';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../../common/permissions-constants';

import '../styles/isolations.scss';

class Isolations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalData: {
        isOpen: false,
      },
      uploadModalData: {
        isOpen: false,
      },
      confirmModalData: {
        isOpen: false,
      },
      warningModalData: {
        isOpen: false,
      },
    };
    this.searchChangeDebounced = debounce(this.search, 250);
  }

  onRowClick = id => {
    //TO DO do something
  };

  componentWillUnmount() {
    this.searchChangeDebounced.cancel();
  }

  handleInputChange = e => {
    this.searchChangeDebounced(e.target.value);
  };

  search = SearchText => {
    const { getIsolationTemplates, setFilter, filter } = this.props;
    getIsolationTemplates({ ...filter, [filterFields.lastSeen]: defaultFilter[filterFields.lastSeen], SearchText }, newFilter => {
      setFilter({ ...filter, ...newFilter, [filterFields.lastSeen]: defaultFilter[filterFields.lastSeen] });
    });
  };

  onColumnSort = SortByColumn => {
    const { getIsolationTemplates, setFilter, filter } = this.props;

    const newParams = {
      ...filter,
      [filterFields.sortByColumn]: SortByColumn,
      [filterFields.sortByDirection]: filter[filterFields.sortByDirection] === sortDirection.asc ? sortDirection.desc : sortDirection.asc,
      LastSeen: defaultFilter[filterFields.lastSeen],
    };

    getIsolationTemplates(newParams, newFilter => {
      setFilter({ ...filter, ...newParams, ...newFilter, [filterFields.lastSeen]: defaultFilter[filterFields.lastSeen] });
    });
  };

  loadMore = () => {
    const { getIsolationTemplates, setFilter, filter, isolations } = this.props;
    const newParams = { ...filter, LastSeen: filter[filterFields.lastSeen] + defaultFilter[filterFields.perPage] };

    getIsolationTemplates(
      newParams,
      (incomingParams, isolationsLength) => {
        setFilter({ ...newParams, [filterFields.hasNext]: incomingParams[filterFields.hasNext] });

        if (isolationsLength) {
          Helpers.scrollIntoView('isolations__table', `row-${isolationsLength - 1}`);
        }
      },
      true,
      isolations.length
    );
  };

  onDropAsset = (files, UploadGroup) => {
    const { uploadAsset, setUploadItems, setSingleUploadItem, projectId, selectedIsolation } = this.props;

    const tmp_files = files.map((file, index) => {
      const tmp_file = {
        type: Helpers.getFileType(file.type),
        mimeType: file.type,
        title: '',
        description: '',
        real_name: Helpers.formatFileName(file.name),
      };
      file.timeStamp = Date.now();
      file.real_name = tmp_file.real_name;
      file.uploadType = uploadType.isolationTemplateFiles;

      uploadAsset(file, { ProjectID: projectId, UploadGroup, IsolationTemplateID: selectedIsolation[formConstants.fields.id] }, setSingleUploadItem, index);
      return { ...tmp_file, ...file };
    });
    setUploadItems(tmp_files);
  };

  openFilesModalData = (uploadGroup, onModalClose) => {
    const { t } = this.context;
    const { setUploadItems } = this.props;

    const confirmAction = () => {
      setUploadItems([]);
      onModalClose();
      this.setState({
        uploadModalData: {
          isOpen: false,
        },
      });
    };

    setUploadItems([]);

    this.setState(prevState => ({
      uploadModalData: {
        ...prevState.uploadModalData,
        title: t('ISOLATION_TEMPLATE_FILES_UPLOAD_MODAL.TITLE'),
        customClassName: 'project-assets-modal modal-small',
        CustomContent: () => (
          <UploadProjectAssetsModal
            onDrop={files => this.onDropAsset(files, uploadGroup)}
            className="project-dropzone"
            labelWhite={t('ISOLATION_TEMPLATE_FILES_UPLOAD_MODAL.TITLE')}
            labelGreen={t('ISOLATION_TEMPLATE_FILES_UPLOAD_MODAL.BROWSE')}
          />
        ),
        isOpen: true,
        type: 'ok',
        confirmAction: confirmAction,
        closeAction: confirmAction,
      },
    }));
  };

  closeConfirmModal = () =>
    this.setState({
      confirmModalData: {
        isOpen: false,
      },
    });

  setConfirmModalData = (confirmAction, content, title = 'ISOLATION_TEMPLATE_FORM.DELETE_POPUP_TITLE') => {
    const { t } = this.context;
    this.setState({
      confirmModalData: {
        isOpen: true,
        title: t(title),
        content,
        type: 'yes-no',
        customClassName: 'isolation-template-confirm-modal',
        confirmAction: () => {
          confirmAction();
          this.closeConfirmModal();
        },
        closeAction: this.closeConfirmModal,
      },
    });
  };

  openIsolationEditModal = (isolation, viewOnly = false) => {
    const { getIsolationTemplateDetails, setTemplateModalData, setSelectedIsolationTemplate } = this.props;
    const { t } = this.context;

    const closeAction = () => {
      setTemplateModalData({ isOpen: false });
      setSelectedIsolationTemplate({});
    };

    //for edit mode fetch details
    if (isolation && isolation[formConstants.fields.id]) {
      getIsolationTemplateDetails(isolation[formConstants.fields.id], Data => {
        if (Data && Data[formConstants.fields.id] > 0) {
          setTemplateModalData({
            isOpen: true,
            title: t('ISOLATION_TEMPLATE_MODAL.EDIT_TITLE'),
            CustomContent: dynamicProps => <IsolationTemplateModal {...dynamicProps} viewOnly={viewOnly} isEditMode={true} closeAction={closeAction} />,
            customClassName: 'modal-large',
            type: 'none',
            closeAction,
          });
        }
      });
    }
  };

  deleteIsolationTemplate = values => {
    const { t } = this.context;
    const { deleteIsolationTemplate } = this.props;

    const onDeleteSuccess = () => {
      const { filter, setFilter } = this.props;

      setFilter({ ...filter, [filterFields.totalItems]: filter[filterFields.totalItems] - 1, [filterFields.lastSeen]: filter[filterFields.lastSeen] - 1 });
    };

    this.setConfirmModalData(() => deleteIsolationTemplate(values, onDeleteSuccess), t('ISOLATION_TEMPLATE_MODAL.DELETE_ARE_YOU_SURE', { isolation: values[formConstants.fields.name] }));
  };

  render() {
    const { t } = this.context;
    const { isolations, uploadInProgress, filter, modalData, selectedIsolation, projectId, duplicateIsolationTemplate, updateStatus, updateLiveStatus, setFilter, isolationTemplatesFetched } =
      this.props;
    const { uploadModalData, confirmModalData, warningModalData } = this.state;
    const { handleInputChange, onRowClick, onColumnSort, loadMore, openIsolationEditModal, deleteIsolationTemplate } = this;

    const menuOptions = data => [
      {
        title: 'ISOLATION_TEMPLATE_TABLE.ACTIONS_MENU_ITEM_VIEW',
        action: () => openIsolationEditModal(data, true),
      },
      {
        title: 'ISOLATION_TEMPLATE_TABLE.ACTIONS_MENU_ITEM_EDIT',
        action: () => openIsolationEditModal(data),
        access: {
          visibleFor: PERMISSIONS[PERMISSION_TYPES.isolationTemplates].edit.name,
          id: data[formConstants.fields.createdByUserID],
          ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.isolationTemplates].create.name,
        },
        isDisabled: data[formConstants.fields.status] === statuses.live.value,
      },
      {
        title: 'ISOLATION_TEMPLATE_TABLE.ACTIONS_MENU_ITEM_MARK_AS_DRAFT',
        isHidden: data[formConstants.fields.status] !== statuses.raised.value,
        action: () =>
          this.setConfirmModalData(
            () => {
              const payload = { [formConstants.fields.id]: data[formConstants.fields.id], [formConstants.fields.status]: statuses.draft.value };
              updateStatus(payload);
            },
            t('ISOLATION_TEMPLATE_TABLE.ACTIONS_MENU_ITEM_MARK_AS_DRAFT_CONTENT', { name: data[formConstants.fields.name] }),
            'ISOLATION_TEMPLATE_FORM.CHANGE_STATUS_POPUP_TITLE'
          ),
        access: {
          visibleFor: PERMISSIONS[PERMISSION_TYPES.isolationTemplates].edit.name,
          id: data[formConstants.fields.createdByUserID],
          ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.isolationTemplates].create.name,
        },
      },
      {
        title: 'ISOLATION_TEMPLATE_TABLE.ACTIONS_MENU_ITEM_MARK_AS_RAISED',
        isHidden: data[formConstants.fields.status] !== statuses.draft.value,
        action: () =>
          this.setConfirmModalData(
            () => {
              const payload = { [formConstants.fields.id]: data[formConstants.fields.id], [formConstants.fields.status]: statuses.raised.value };
              updateStatus(payload);
            },
            t('ISOLATION_TEMPLATE_TABLE.ACTIONS_MENU_ITEM_MARK_AS_RAISED_CONTENT', { name: data[formConstants.fields.name] }),
            'ISOLATION_TEMPLATE_FORM.CHANGE_STATUS_POPUP_TITLE'
          ),
        access: {
          visibleFor: PERMISSIONS[PERMISSION_TYPES.isolationTemplates].edit.name,
          id: data[formConstants.fields.createdByUserID],
          ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.isolationTemplates].create.name,
        },
      },
      {
        title: 'ISOLATION_TEMPLATE_TABLE.ACTIONS_MENU_ITEM_MARK_AS_RAISED',
        isHidden: data[formConstants.fields.status] !== statuses.live.value,
        action: () =>
          this.setConfirmModalData(
            () => {
              const payload = { [formConstants.fields.id]: data[formConstants.fields.id], [formConstants.fields.status]: statuses.raised.value };
              updateLiveStatus(payload);
            },
            t('ISOLATION_TEMPLATE_TABLE.ACTIONS_MENU_ITEM_MARK_AS_RAISED_CONTENT', { name: data[formConstants.fields.name] }),
            'ISOLATION_TEMPLATE_FORM.CHANGE_STATUS_POPUP_TITLE'
          ),
        access: {
          visibleFor: PERMISSIONS[PERMISSION_TYPES.isolationTemplates].live.name,
        },
      },
      {
        title: 'ISOLATION_TEMPLATE_TABLE.ACTIONS_MENU_ITEM_MARK_AS_LIVE',
        isHidden: data[formConstants.fields.status] !== statuses.raised.value,
        action: () =>
          this.setConfirmModalData(
            () => {
              const payload = { [formConstants.fields.id]: data[formConstants.fields.id], [formConstants.fields.status]: statuses.live.value };
              updateLiveStatus(payload);
            },
            t('ISOLATION_TEMPLATE_TABLE.ACTIONS_MENU_ITEM_MARK_AS_LIVE_CONTENT', { name: data[formConstants.fields.name] }),
            'ISOLATION_TEMPLATE_FORM.CHANGE_STATUS_POPUP_TITLE'
          ),
        access: {
          visibleFor: PERMISSIONS[PERMISSION_TYPES.isolationTemplates].live.name,
        },
      },
      {
        title: 'ISOLATION_TEMPLATE_TABLE.ACTIONS_MENU_ITEM_DUPLICATE',
        action: () => {
          duplicateIsolationTemplate(data[formConstants.fields.id], isolation => {
            setFilter({ ...filter, [filterFields.totalItems]: filter[filterFields.totalItems] + 1 });
            openIsolationEditModal(isolation);
          });
        },
        access: {
          visibleFor: PERMISSIONS[PERMISSION_TYPES.isolationTemplates].create.name,
        },
      },
      {
        title: 'ISOLATION_TEMPLATE_TABLE.ACTIONS_MENU_ITEM_DELETE',
        action: () => deleteIsolationTemplate(data),
        access: {
          visibleFor: PERMISSIONS[PERMISSION_TYPES.isolationTemplates].delete.name,
        },
        separator: true,
        isHighlighted: true,
      },
    ];

    return (
      <div className="isolations">
        <div className="header">
          <SearchInput onChange={handleInputChange} placeholder={t('PROJECT.SEARCH.PLACEHOLDER')} stripped />
        </div>
        <RenderIf if={(!isolations || !isolations.length) && isolationTemplatesFetched}>
          <div className="empty-state">
            <p className="f-primary">{t('ISOLATION_TEMPLATES.EMPTY_STATE')}</p>
          </div>
        </RenderIf>
        <RenderIf if={isolationTemplatesFetched && isolations && isolations.length > 0}>
          <div className="isolations__table">
            <TableComponent
              tableConfig={[
                ...isolationTableHeader,
                {
                  title: 'ISOLATION_TEMPLATE_TABLE.ACTIONS',
                  CustomComponent: (data, itemIndex) => (
                    <SmallDropdown
                      actionsMenu={menuOptions(data, itemIndex)}
                      getClickAction={(action, b, selfClose, e) => {
                        e?.preventDefault && e.preventDefault();
                        selfClose();
                        if (action) {
                          action();
                        }
                      }}
                      offsetX={-150}
                    />
                  ),
                  enableSort: false,
                },
              ]}
              data={isolations || []}
              tableCustomClass="isolations-table"
              onRowClick={onRowClick}
              onColumnSort={onColumnSort}
              sortingObj={filter}
              translationModule={t}
              formatCell={(value, type, index, item) => {
                if (type === formConstants.fields.status) {
                  return <DisplayStatus statuses={values(statuses)} status={value} />;
                }

                if (type === formConstants.fields.description) {
                  return value ? <p className="f-primary shortened">{value}</p> : null;
                }

                return Helpers.formatCell(value, type, index, item);
              }}
              stickyHeader={true}
              sortDirectionProp={filterFields.sortByDirection}
            />
          </div>
        </RenderIf>
        <div className="isolations__load-more-wrapper">
          <LoadMore
            disabled={!filter[filterFields.hasNext]}
            loaded={isolations.length}
            total={filter[filterFields.totalItems]}
            totalPosition="center"
            isLoading={false}
            label="ISOLATION_TABLE.LOAD_MORE"
            showButton
            showTotalUp
            buttonVariant="success-outline"
            resultsText="ISOLATION_TABLE.SHOWING_TOTAL_ITEMS"
            onClick={loadMore}
          />
        </div>

        <Modal
          {...{
            ...modalData,
            isolation: selectedIsolation,
            projectId,
            filter,
            setFilter,
          }}
        />
        <Modal {...uploadModalData} modalDisabled={uploadInProgress} />
        <Modal {...confirmModalData} modalDisabled={uploadInProgress} />
        <Modal {...warningModalData} />
      </div>
    );
  }
}

Isolations.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isolations: state.templateReducer.isolations,
  selectedIsolation: state.templateReducer.selectedIsolation,
});

const mapDispatchToProps = dispatch => ({
  uploadAsset: (file, IDs, callbackFunction, index) => dispatch(uploadAsset(file, IDs, callbackFunction, index)),
  setUploadItems: assets => dispatch(setUploadItems(assets)),
  setSingleUploadItem: (progress, index) => dispatch(setSingleUploadItem(progress, index)),
  getIsolationTemplateDetails: (templateId, callback) => dispatch(getIsolationTemplateDetails(templateId, callback)),
  duplicateIsolationTemplate: (data, callback) => dispatch(duplicateIsolationTemplate(data, callback)),
  deleteIsolationTemplate: (data, callback) => dispatch(deleteIsolationTemplate(data, callback)),
  updateStatus: data => dispatch(updateStatus(data)),
  updateLiveStatus: data => dispatch(updateLiveStatus(data)),
  setSelectedIsolationTemplate: template => dispatch(setSelectedIsolationTemplate(template)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Isolations);
