import { debounce } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DatePicker } from 'react-widgets/cjs';
import Button from '../../../../../../../common/form/components/button';
import CustomCheckBox from '../../../../../../../common/form/components/custom-checkbox';
import Toggle from '../../../../../../../common/form/components/toggle-field';
import Helpers from '../../../../../../../common/helpers';
import Icon from '../../../../../../../common/icon/components/icon';
import SearchInput from '../../../../../../../common/input/components/search-input';
import LoadMore from '../../../../../../../common/load-more/components/load-more';
import RenderIf from '../../../../../../../common/render-if/components/render-if';
import { fetchComponentsToLink } from '../../../../../../document-management/actions/dm-api-calls';
import { defaultFileComponentsFilters as defaultEquipmentFilters } from '../../../../../../document-management/constants/constants';
import dmsHelpers from '../../../../../../document-management/helpers/dms-helpers';
import { editInspectionMinDate } from '../../../../../constants/edit-inspection';
import { fetchGroups, fetchUnits } from '../../../../readings-and-gauges/actions/measurement-group-actions';
import { defaultFilters as defaultGroupFilters, defaultUnitsFilters, fields } from '../../../../readings-and-gauges/constants/constants';
import '../../styles/generate-report-modal.scss';

const searchDropdownOptions = {
  MG: 'MEASUREMENT_GROUP',
  EQUIPMENT: 'EQUIPMENT',
  MU: 'MEASUREMENT_UNIT',
};

// helper for selecting/unselecting item from the dropdown list
const updateSelectedItemHelper = (selectedItem, initialSelectedItems, key = fields.id) => {
  let updatedSelectedItems = [...initialSelectedItems];
  const selectedItemIndex = updatedSelectedItems.findIndex(cat => cat[key] === selectedItem[key]);

  if (selectedItemIndex > -1) {
    updatedSelectedItems.splice(selectedItemIndex, 1);
  } else {
    updatedSelectedItems.push(selectedItem);
  }

  return updatedSelectedItems;
};

const GenerateReportModal = (props, { t }) => {
  const { fetchGroups, projectID, inspectionId, fetchComponents, fetchUnits, handleSubmit, showDatesOnly = true, selectedMeasurementLocation = null, selectedMeasurementPoint = null } = props;
  // Measurement groups state
  const [groupFilters, setGroupFilters] = useState(defaultGroupFilters);
  const [isGroupsLoading, setIsGroupsLoading] = useState(false);
  const [measurementGroups, setMeasurementGroups] = useState([]);
  const [selectedMeasurementGroups, setSelectedMeasurementGroups] = useState([]);
  // Equipment state
  const [equipmentFilters, setEquipmentFilters] = useState(defaultGroupFilters);
  const [isEquipmentLoading, setIsEquipmentLoading] = useState(false);
  const [equipmentList, setEquipmentList] = useState([]);
  const [selectedEquipment, setSelectedEquipment] = useState([]);
  // Measurement units state
  const [unitsFilters, setUnitFilters] = useState(defaultUnitsFilters);
  const [isUnitsLoading, setIsUnitsLoading] = useState(false);
  const [measurementUnits, setMeasurementUnits] = useState([]);
  const [selectedMeasurementUnits, setSelectedMeasurementUnits] = useState([]);
  // Toggle state
  const [onlyMLsWithAlarms, setOnlyMLsWithAlarms] = useState(false);
  const [selectedDateFrom, setSelectedDateFrom] = useState(Helpers.addDays(new Date(), -7)); // 1 week needs to be the initial date range
  const [isDateFromInEdit, setIsDateFromInEdit] = useState(false);
  const [selectedDateTo, setSelectedDateTo] = useState(new Date());
  const [isDateToInEdit, setIsDateToInEdit] = useState(false);

  useEffect(() => {
    if (!showDatesOnly) {
      fetchGroupsData(defaultGroupFilters);
      fetchEquipmentData(defaultEquipmentFilters);
      fetchUnitsData(defaultUnitsFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Measurement Groups related logic
  const fetchGroupsData = useCallback(
    (filters, loadMore = false) => {
      const setNewData = newData => {
        const dataToSet = loadMore ? [...measurementGroups, ...newData] : newData;
        setMeasurementGroups(dataToSet);
      };
      const setDataLoaded = isLoading => {
        setIsGroupsLoading(isLoading);

        if (loadMore && !isLoading) {
          Helpers.scrollIntoView('items-dropdown', null, -250);
        }
      };
      fetchGroups({ ...filters, [fields.projectID]: projectID }, setNewData, setGroupFilters, null, setDataLoaded);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [measurementGroups]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchGroupsTextChanged = useCallback(
    debounce(searchText => fetchGroupsData({ ...defaultGroupFilters, [fields.lastSeen]: 0, [fields.searchText]: searchText }), 300),
    [fetchGroupsData]
  );

  // Equipment related logic
  const fetchEquipmentData = useCallback(
    (filters, loadMore = false) => {
      const handleFetchEquipment = (data, newFilters) => {
        const setNewData = newData => {
          const dataToSet = loadMore ? [...equipmentList, ...newData] : newData;
          setEquipmentList(dataToSet);
        };

        setNewData(data);
        setEquipmentFilters({ ...equipmentFilters, ...newFilters });
        if (loadMore) {
          Helpers.scrollIntoView('items-dropdown', null, -250);
        }
      };
      fetchComponents({ ...filters, [fields.inspectionID]: inspectionId }, handleFetchEquipment, setIsEquipmentLoading);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [equipmentList]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchEquipmentTextChanged = useCallback(
    debounce(searchText => fetchEquipmentData({ ...defaultEquipmentFilters, [fields.lastSeen]: 0, [fields.searchText]: searchText }), 300),
    [fetchEquipmentData]
  );

  // Measurement Units related logic
  const fetchUnitsData = useCallback(
    (filters, loadMore = false) => {
      const setNewData = newData => {
        const dataToSet = loadMore ? [...measurementUnits, ...newData] : newData;
        setMeasurementUnits(dataToSet);
      };
      const setDataLoaded = isLoading => {
        setIsUnitsLoading(isLoading);

        if (loadMore && !isLoading) {
          Helpers.scrollIntoView('items-dropdown', null, -250);
        }
      };
      fetchUnits({ ...filters, [fields.projectID]: projectID }, setNewData, setUnitFilters, null, setDataLoaded);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [measurementUnits]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchUnitsTextChanged = useCallback(
    debounce(searchText => fetchUnitsData({ ...defaultEquipmentFilters, [fields.lastSeen]: 0, [fields.searchText]: searchText }), 300),
    [fetchUnitsData]
  );

  // Generic logic
  const handleSearchInputChange = (e, type) => {
    const searchText = e.target.value;
    if (type === searchDropdownOptions.MG) {
      searchGroupsTextChanged(searchText);
    } else if (type === searchDropdownOptions.EQUIPMENT) {
      //  150 max chars validation, as agreed with the API since the max Name and Code fields length can be 150
      if (searchText?.length >= 150) return;

      searchEquipmentTextChanged(searchText);
    } else if (type === searchDropdownOptions.MU) {
      searchUnitsTextChanged(searchText);
    }
  };

  const handleLoadMoreClick = type => {
    if (type === searchDropdownOptions.MG) {
      fetchGroupsData(groupFilters, true);
    } else if (type === searchDropdownOptions.EQUIPMENT) {
      fetchEquipmentData(equipmentFilters, true);
    } else if (type === searchDropdownOptions.MU) {
      fetchUnitsData(unitsFilters, true);
    }
  };

  const handleDropdownItemClick = (item, type) => {
    if (type === searchDropdownOptions.MG) {
      const updatedItems = updateSelectedItemHelper(item, selectedMeasurementGroups);
      setSelectedMeasurementGroups(updatedItems);
    } else if (type === searchDropdownOptions.EQUIPMENT) {
      const updatedItems = updateSelectedItemHelper(item, selectedEquipment);
      setSelectedEquipment(updatedItems);
    } else if (type === searchDropdownOptions.MU) {
      const updatedItems = updateSelectedItemHelper(item, selectedMeasurementUnits, fields.unit);
      setSelectedMeasurementUnits(updatedItems);
    }
  };

  const handleDateFromChange = val => {
    setSelectedDateFrom(val);
  };

  const handleDateToChange = val => {
    setSelectedDateTo(val);
  };

  const submitHandler = () => {
    const selectedMeasurementGroupIDs = selectedMeasurementGroups.map(mg => mg[fields.id]);
    const selectedEquipmentIDs = selectedEquipment.map(e => e[fields.id]);
    const selectedMeasurementUnitIDs = selectedMeasurementUnits.map(mu => mu[fields.unit]);
    let payload = {
      [fields.projectID]: projectID,
      [fields.inspectionID]: inspectionId,
      [fields.readingsAndGaugesGroupID]: selectedMeasurementGroupIDs,
      [fields.measurementLocationID]: selectedMeasurementLocation ? selectedMeasurementLocation[fields.id] : null,
      [fields.dateFrom]: moment(selectedDateFrom).unix(),
      [fields.dateTo]: moment(selectedDateTo).unix(),
      [fields.componentIDs]: selectedEquipmentIDs,
      [fields.measurementUnits]: selectedMeasurementUnitIDs,
      [fields.hasAlarm]: onlyMLsWithAlarms,
      [fields.measurementPointIDs]: selectedMeasurementPoint ? [selectedMeasurementPoint[fields.id]] : [], // for some strange reason we need to send it as array of IDs even though it is always only one MP
      [fields.includeAnalytics]: false,
    };

    handleSubmit(payload);
  };

  return (
    <form className={`generate-report-modal-wrapper${showDatesOnly ? ' show-dates-only' : ''} ${showDatesOnly && (isDateToInEdit || isDateFromInEdit) ? 'expand-modal' : ''} `}>
      <RenderIf if={!showDatesOnly}>
        <div className="search-input-wrapper">
          <h5 className="search-input-wrapper__title">{t('MEASUREMENT_LOCATION_DETAILS.FORM_GROUP')}</h5>
          <SearchInput
            onChange={e => handleSearchInputChange(e, searchDropdownOptions.MG)}
            //   onInputFocus={() => fetchGroupsData()} // resets the search input groups list
            placeholder={t(dmsHelpers.getSearchInputValue(selectedMeasurementGroups, t))}
            wrapperClass={dmsHelpers.getSearchInputValue(selectedMeasurementGroups, t) !== t('SEARCH') ? 'highlight-placeholder' : ''}
            includeDropdown={true}
            keepDropdownVisible={false}
            items={measurementGroups}
            emptyStateLabel={'NO_MEASUREMENT_GROUPS_FOUND'}
            isDropdownDataLoading={isGroupsLoading}
            shouldRenderPortal={true}
            portalProps={{
              id: `generate-report-measurement-groups-dropdown-items`,
            }}
            renderItem={(group, groupIndex) => {
              return (
                <>
                  <div
                    className="group-item-wrapper"
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleDropdownItemClick(group, searchDropdownOptions.MG);
                    }}
                    key={`measurement-group-${groupIndex}`}
                  >
                    <CustomCheckBox
                      meta={{}}
                      input={{
                        value: (selectedMeasurementGroups || []).findIndex(g => g[fields.id] === group[fields.id]) > -1,
                        onChange: e => {
                          e.stopPropagation();
                          //   handleDropdownItemClick(group, 'MEASUREMENT_GROUP'); does not require it to work
                        },
                      }}
                      id={`row-${groupIndex}`}
                    />
                    <p id={`row-${groupIndex}`} className="pointer">
                      {t(group[fields.name])}
                    </p>
                  </div>
                  <RenderIf if={measurementGroups.length - groupIndex === 1}>
                    <LoadMore
                      loaded={measurementGroups?.length}
                      total={groupFilters.TotalNumber}
                      onlyText={!groupFilters.HasNext}
                      totalPosition="center"
                      label="LOAD_MORE"
                      showButton
                      showTotalItems
                      disabled={false} // TODO: fix
                      buttonVariant="success-outline"
                      onClick={() => handleLoadMoreClick(searchDropdownOptions.MG)}
                      buttonWidth="sm"
                    />
                  </RenderIf>
                </>
              );
            }}
          />

          <div key={'generate-report-measurement-groups-dropdown-items'} id={'generate-report-measurement-groups-dropdown-items'} className="search-dropdown-items-wrapper" />
        </div>
        <div className="search-input-wrapper">
          <h5 className="search-input-wrapper__title">{t('INSPECTION_COMPONENTS')}</h5>
          <SearchInput
            onChange={e => handleSearchInputChange(e, searchDropdownOptions.EQUIPMENT)}
            //   onInputFocus={() => fetchEquipmentData()} // resets the search input groups list
            placeholder={dmsHelpers.getSearchInputValue(selectedEquipment, t)}
            wrapperClass={dmsHelpers.getSearchInputValue(selectedEquipment, t) !== t('SEARCH') ? 'highlight-placeholder' : ''}
            includeDropdown={true}
            keepDropdownVisible={false}
            items={equipmentList}
            emptyStateLabel={'NO_EQUIPMENT_FOUND'}
            isDropdownDataLoading={isEquipmentLoading}
            shouldRenderPortal={true}
            portalProps={{
              id: `generate-report-equipment-dropdown-items`,
            }}
            renderItem={(equipment, equipmentIndex) => {
              return (
                <>
                  <div
                    className="group-item-wrapper"
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleDropdownItemClick(equipment, searchDropdownOptions.EQUIPMENT);
                    }}
                    key={`equipment-${equipmentIndex}`}
                  >
                    <CustomCheckBox
                      meta={{}}
                      input={{
                        value: (selectedEquipment || []).findIndex(e => e[fields.id] === equipment[fields.id]) > -1,
                        onChange: e => {
                          e.stopPropagation();
                          //   handleDropdownItemClick(group, 'EQUIPMENT'); does not require it to work
                        },
                      }}
                      id={`row-${equipmentIndex}`}
                    />
                    <p id={`row-${equipmentIndex}`} className="pointer">
                      {equipment[fields.name]}
                    </p>
                  </div>
                  <RenderIf if={equipmentList.length - equipmentIndex === 1}>
                    <LoadMore
                      loaded={equipmentList?.length}
                      total={equipmentFilters.TotalNumber}
                      onlyText={!equipmentFilters.HasNext}
                      totalPosition="center"
                      label="LOAD_MORE"
                      showButton
                      showTotalItems
                      disabled={false} // TODO: fix
                      buttonVariant="success-outline"
                      onClick={() => handleLoadMoreClick(searchDropdownOptions.EQUIPMENT)}
                      buttonWidth="sm"
                    />
                  </RenderIf>
                </>
              );
            }}
          />

          <div key={'generate-report-equipment-dropdown-items'} id={'generate-report-equipment-dropdown-items'} className="search-dropdown-items-wrapper" />
        </div>
        <div className="search-input-wrapper">
          <h5 className="search-input-wrapper__title">{t('DOWNLOAD_REPORT_MEASUREMENT.UNITS')}</h5>
          <SearchInput
            onChange={e => handleSearchInputChange(e, searchDropdownOptions.MU)}
            //   onInputFocus={() => fetchMeasurementUnitData()} // resets the search input groups list
            placeholder={dmsHelpers.getSearchInputValue(selectedMeasurementUnits, t, fields.unit)}
            wrapperClass={dmsHelpers.getSearchInputValue(selectedMeasurementUnits, t, fields.unit) !== t('SEARCH') ? 'highlight-placeholder' : ''}
            includeDropdown={true}
            keepDropdownVisible={false}
            items={measurementUnits}
            emptyStateLabel={'NO_MEASUREMENT_UNIT_FOUND'}
            isDropdownDataLoading={isUnitsLoading}
            shouldRenderPortal={true}
            portalProps={{
              id: `generate-report-measurement-unit-dropdown-items`,
            }}
            renderItem={(measurementUnit, measurementUnitIndex) => {
              return (
                <>
                  <div
                    className="group-item-wrapper"
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleDropdownItemClick(measurementUnit, searchDropdownOptions.MU);
                    }}
                    key={`measurement-unit-${measurementUnitIndex}`}
                  >
                    <CustomCheckBox
                      meta={{}}
                      input={{
                        value: (selectedMeasurementUnits || []).findIndex(e => e[fields.unit] === measurementUnit[fields.unit]) > -1,
                        onChange: e => {
                          e.stopPropagation();
                          //   handleDropdownItemClick(group, 'MU'); does not require it to work
                        },
                      }}
                      id={`row-${measurementUnitIndex}`}
                    />
                    <p id={`row-${measurementUnitIndex}`} className="pointer">
                      {measurementUnit[fields.unit]}
                    </p>
                  </div>
                  <RenderIf if={measurementUnits.length - measurementUnitIndex === 1}>
                    <LoadMore
                      loaded={measurementUnits?.length}
                      total={unitsFilters.TotalNumber} // TODO
                      onlyText={!unitsFilters.HasNext} // TODO
                      totalPosition="center"
                      label="LOAD_MORE"
                      showButton
                      showTotalItems
                      disabled={false} // TODO: fix
                      buttonVariant="success-outline"
                      onClick={() => handleLoadMoreClick(searchDropdownOptions.MU)}
                      buttonWidth="sm"
                    />
                  </RenderIf>
                </>
              );
            }}
          />

          <div key={'generate-report-measurement-unit-dropdown-items'} id={'generate-report-measurement-unit-dropdown-items'} className="search-dropdown-items-wrapper" />
        </div>
        <div className="toggle-field-wrapper">
          <label className="label">{t('MEASUREMENT_LOCATION_WITH_ALARM_ONLY')}</label>
          <Toggle input={{ value: onlyMLsWithAlarms, onChange: () => setOnlyMLsWithAlarms(!onlyMLsWithAlarms) }} />
        </div>
      </RenderIf>
      {/* TODO: add validation DateFrom >= DateTo */}
      <div className="date-pickers-wrapper">
        <div className="single-date-picker-wrapper">
          <label className="label">{t('PERMIT_FILTERS.DATE_FROM')}</label>
          <DatePicker
            disabledKeyboardNavigation={true}
            // defaultValue={new Date()}
            value={selectedDateFrom}
            onChange={handleDateFromChange}
            valueFormat={{ dateStyle: 'medium' }}
            showTimeSelect={false}
            popperPlacement="bottom-end"
            onCalendarClose={() => setIsDateFromInEdit(false)}
            min={new Date(editInspectionMinDate * 1000)}
            max={selectedDateTo || new Date()}
            disabled={false}
            open={isDateFromInEdit}
            onClickOutside={() => setIsDateFromInEdit(false)}
            onBlur={() => setIsDateFromInEdit(false)}
            onFocus={() => setIsDateFromInEdit(true)}
            selectIcon={<Icon name="calendar" solid />}
          />
        </div>
        <div className="single-date-picker-wrapper">
          <label className="label">{t('PERMIT_FILTERS.DATE_TO')}</label>
          <DatePicker
            disabledKeyboardNavigation={true}
            // defaultValue={new Date()}
            value={selectedDateTo}
            onChange={handleDateToChange}
            valueFormat={{ dateStyle: 'medium' }}
            showTimeSelect={false}
            popperPlacement="bottom-end"
            onCalendarClose={() => setIsDateToInEdit(false)}
            min={selectedDateFrom || new Date(editInspectionMinDate * 1000)}
            // max={new Date()}
            disabled={false}
            open={isDateToInEdit}
            onClickOutside={() => setIsDateToInEdit(false)}
            onBlur={() => setIsDateToInEdit(false)}
            onFocus={() => setIsDateToInEdit(true)}
            selectIcon={<Icon name="calendar" solid />}
          />
        </div>
      </div>
      <div className="button-wrapper">
        <Button text={t('GENERATE_AND_DOWNLOAD_REPORT')} type="button" height="md" width="lg" keepOriginalText onClick={submitHandler} />
      </div>
    </form>
  );
};

GenerateReportModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  measurementGroups: state.measurementGroupReducer.measurementGroups,
});

const mapDispatchToProps = dispatch => ({
  fetchGroups: (filters, callback, filtersCallback, errorCallback, loadingCallback) => dispatch(fetchGroups(filters, callback, filtersCallback, errorCallback, loadingCallback)),
  fetchComponents: (filters, callback, loadingCallback) => dispatch(fetchComponentsToLink(filters, callback, loadingCallback)),
  fetchUnits: (filters, callback, filtersCallback, errorCallback, loadingCallback) => dispatch(fetchUnits(filters, callback, filtersCallback, errorCallback, loadingCallback)),
});

// GenerateReportModal = reduxForm({
//   form: FORMS.generateReportModalForm,
//   enableReinitialize: true,
// })(GenerateReportModal);

export default connect(mapStateToProps, mapDispatchToProps)(GenerateReportModal);
