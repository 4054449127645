import { formConstants } from '../../../constants/defect-constants';

export const validate = values => {
  const errors = {};

  if (!values[formConstants.fields.name] || values[formConstants.fields.name].length < 3) {
    errors[formConstants.fields.name] = 'NOTIFICATION_FORM.ERROR.NAME_LENGTH';
  }

  if (values[formConstants.fields.name] && values[formConstants.fields.name].length > 300) {
    errors[formConstants.fields.name] = 'NOTIFICATION_FORM.ERROR.NAME_MAX_LENGTH';
  }

  return errors;
};
