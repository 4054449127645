import { changeStatusFields } from '../../../components/right-toolbar/isolation-certificate-details/constants/change-status-constants';

export const validate = (values, props) => {
  const { contractor, assigneeSignature, witnessSignature } = changeStatusFields;
  const errors = {};

  if (!values[contractor.name]) {
    errors[contractor.name] = contractor.errorRequired;
  }

  if (props?.signatureRequired) {
    if (!values[assigneeSignature.name]) {
      errors[assigneeSignature.name] = assigneeSignature.errorRequired;
    }

    if (!values[witnessSignature.name]) {
      errors[witnessSignature.name] = witnessSignature.errorRequired;
    }
  }

  return errors;
};
