import { PERMISSIONS, PERMISSION_TYPES } from '../../../common/permissions-constants';

const itemActions = {
  download: 'download',
  reupload: 'reupload',
  delete: 'delete',
  edit: 'edit',
};

const reducedItemActions = {
  download: 'download',
  delete: 'delete',
  reupload: 'reupload',
};

const actionsMenu = [
  {
    title: 'PDF_TABLE_SIDEBAR.SETTINGS_MENU.ITEM_1',
    id: '1',
    action: itemActions.view,
  },
  {
    title: 'PDF_TABLE_SIDEBAR.SETTINGS_MENU.ITEM_5',
    id: '5',
    action: itemActions.edit,
    access: {
      visibleFor: [PERMISSIONS[PERMISSION_TYPES.smartDrawing].edit.name],
    },
  },
  {
    title: 'PDF_TABLE_SIDEBAR.SETTINGS_MENU.ITEM_2',
    id: '2',
    action: itemActions.download,
  },
  // {
  //   title: 'PDF_TABLE_SIDEBAR.SETTINGS_MENU.ITEM_3',
  //   id: '3',
  //   action: itemActions.reupload,
  //   access: {
  //     visibleFor: [PERMISSIONS[PERMISSION_TYPES.smartDrawing].edit.name],
  //   },
  // },
  {
    title: 'PDF_TABLE_SIDEBAR.SETTINGS_MENU.ITEM_4',
    id: '4',
    action: itemActions.delete,
    access: {
      visibleFor: [PERMISSIONS[PERMISSION_TYPES.smartDrawing].delete.name],
    },
  },
];

export { itemActions, actionsMenu, reducedItemActions };
