import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';

import Button from '../../../../common/form/components/button';
import CustomInput from '../../../../common/form/components/input';
import CustomSelect from '../../../../common/form/components/select';
import Icon from '../../../../common/icon/components/icon';
import Textarea from '../../../../common/form/components/text-area';
import Helpers from '../../../../common/helpers';
import { FORMS } from '../../../../common/constants';
import { fields, defaultFilter, filterFields, permitTemplateFields } from '../permits/constants/permit-form-constants';
import { validate } from '../permits/validators/create-permit-validator';
import '../../styles/create-permit-form.scss';
import { connect } from 'react-redux';

const WorkOrderCreatePermitForm = (
  { handleSubmit, handleFormSubmit, closeModal, projectId, templates, onSearchPermitTemplates, change, modalDisabled, initialPermitName, initialPermitDescription },
  { t }
) => {
  const { permitTemplate, name, description, startDate, endDate } = fields;

  const handleSearch = value => {
    defaultFilter[filterFields.searchText] = value;
    defaultFilter[filterFields.projectID] = projectId;

    onSearchPermitTemplates(defaultFilter);
  };

  const onFormSubmit = values => {
    values[startDate.name] = Helpers.getUnixDate(Helpers.dateToUnix(values[startDate.name]));
    values[endDate.name] = Helpers.getUnixDate(Helpers.dateToUnix(values[endDate.name]));
    handleFormSubmit(values);
  };

  return (
    <div className="create-permit-form">
      <form onSubmit={handleSubmit(onFormSubmit)} noValidate>
        <div className="permit-template">
          <Field
            id={permitTemplate.id}
            name={permitTemplate.name}
            label={permitTemplate.label}
            placeholder={
              <div className="template-placeholder">
                <Icon name="search" className="template-placeholder-icon" />
                <p className="f-secondary-dark">{t(permitTemplate.placeholder)}</p>
              </div>
            }
            component={CustomSelect}
            data={templates}
            size="lg"
            disabled={modalDisabled}
            onSearch={handleSearch}
            valueField={permitTemplateFields.id}
            textField={permitTemplateFields.name}
            filter="contains"
            isRequired
            input={{
              onChange: item => {
                change(permitTemplate.name, item[permitTemplateFields.id], true);
              },
            }}
          />
        </div>
        <div className="input-name">
          <Field
            id={name.id}
            name={name.name}
            label={name.label}
            component={CustomInput}
            placeholder={name.placeholder}
            disabled={modalDisabled}
            isRequired
            size="lg"
            additionalClassName="text-transform-none"
            labelClass="text-transform-none"
          />
        </div>
        <Field
          id={description.id}
          name={description.name}
          label={description.label}
          component={Textarea}
          placeholder={description.placeholder}
          maxChars={description.maxChars}
          disabled={modalDisabled}
          size="lg"
        />
        <div className="buttons-container">
          <Button type="button" variant="gray-outline" width="sm" height="md" text={t('CREATE_PERMIT_FORM.CANCEL_BUTTON')} disabled={modalDisabled} onClick={closeModal} />
          <Button type="submit" className="submit-button" width="sm" height="md" text={t('CREATE_PERMIT_FORM.SUBMIT_BUTTON')} disabled={modalDisabled} />
        </div>
      </form>
    </div>
  );
};

WorkOrderCreatePermitForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { initialPermitName, initialPermitDescription } = ownProps;
  return {
    initialValues: {
      [fields.name.name]: initialPermitName ? initialPermitName : '',
      [fields.permitTemplate.name]: null,
      [fields.description.name]: initialPermitDescription ? initialPermitDescription : '',
      [fields.startDate.name]: null,
      [fields.endDate.name]: null,
    },
  };
};

const FormDecoratedComponent = reduxForm({
  form: FORMS.workOrderCreatePermitForm,
  validate,
  touchOnChange: true,
  enableReinitialize: true,
  destroyOnUnmount: false,
})(WorkOrderCreatePermitForm);

const ConnectedAndFormDecoratedWorkOrderPermitComponent = connect(mapStateToProps, null)(FormDecoratedComponent);

export default ConnectedAndFormDecoratedWorkOrderPermitComponent;
