import React, { Component } from 'react';

import PropTypes from 'prop-types';
import KeyboardKey from './keyboard-key';

import { keyboardKeyTypes } from '../constants/constants';

import '../styles/controls.scss';

class PanControls extends Component {
  render() {
    const { t } = this.context;
    return (
      <div className="controls">
        <div className="controls__row">
          <div className="controls__row__keys-visual-col controls__item">
            <KeyboardKey type={keyboardKeyTypes.leftClick} />
          </div>
          <div className="controls__row__keys-textual-col controls__item">
            <p className="f-secondary-dark">{t('PAN_CONTROLS.KEYS_TEXTUAL.MOVE.VARIANT_1')}</p>
          </div>
          <div className="controls__row__description-col controls__item">
            <p className="f-primary">{t('PAN_CONTROLS.DESCRIPTION.MOVE.VARIANT_1')}</p>
          </div>
        </div>
        <div className="controls__row">
          <div className="controls__row__keys-visual-col controls__item">
            <KeyboardKey type={keyboardKeyTypes.rightClick} />
          </div>
          <div className="controls__row__keys-textual-col controls__item">
            <p className="f-secondary-dark">{t('PAN_CONTROLS.KEYS_TEXTUAL.ROTATE.VARIANT_1')}</p>
          </div>
          <div className="controls__row__description-col controls__item">
            <p className="f-primary">{t('PAN_CONTROLS.DESCRIPTION.ROTATE.VARIANT_1')}</p>
          </div>
        </div>
        <div className="controls__row">
          <div className="controls__row__keys-visual-col controls__item">
            <KeyboardKey type={keyboardKeyTypes.scroll} />
          </div>
          <div className="controls__row__keys-textual-col controls__item">
            <p className="f-secondary-dark">{t('PAN_CONTROLS.KEYS_TEXTUAL.ZOOM.VARIANT_1')}</p>
          </div>
          <div className="controls__row__description-col controls__item">
            <p className="f-primary">{t('PAN_CONTROLS.DESCRIPTION.ZOOM.VARIANT_1')}</p>
          </div>
        </div>
      </div>
    );
  }
}
PanControls.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default PanControls;
