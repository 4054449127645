import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { FORMS } from '../../../../common/constants';

import Helpers from '../../../../common/helpers';

import { uploadExternalPrefix } from '../../constants/upload-constants';

import ImageWithDrawings from '../../../../common/image-components/components/image-with-drawing';
import Button from '../../../../common/form/components/button';
import Icon from '../../../../common/icon/components/icon';

import '../../styles/external-upload-form.scss';

class ExternalUploadFrom extends Component {
  render() {
    const { t } = this.context;
    const { handleSubmit, invalid, anyTouched, fields, imageType, loadWithDrawings, cols, includeLoader, loadMore, includePaging } = this.props;
    return (
      <form className="upload-form" onSubmit={handleSubmit} noValidate>
        <div className="upload-fields" style={{ gridTemplateColumns: '1fr '.repeat(cols) }}>
          {fields.map((item, index) => {
            return (
              <div key={index} className={`image-item item-${index}`} text={item.name}>
                <Field
                  id={`${uploadExternalPrefix + index}`}
                  name={`${uploadExternalPrefix + index}`}
                  component={({ input, id, name }) => <input {...input} type="checkbox" id={id} name={name} checked={(input && input.value) || false} />}
                />
                <label
                  htmlFor={`${uploadExternalPrefix + index}`}
                  style={loadWithDrawings ? {} : { background: `black url("${item.isImage ? (item.src && item.src.getImageSize(imageType, item)) || '' : item.src}") center / contain no-repeat` }}
                >
                  {loadWithDrawings && <ImageWithDrawings item={item} drawings={item.Drawings} imageType={imageType} disableZoomAndMove={true} includeLoader={includeLoader} />}

                  <Icon name="newTab" size="xs" className="new-tab" onClick={e => Helpers.openInNewTab(e, item.src)} />
                </label>
              </div>
            );
          })}
        </div>
        {includePaging && (
          <Button
            type="button"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              loadMore();
            }}
            disabled={!loadMore}
            text={t('LOAD_MORE')}
          />
        )}
        <Button disabled={invalid && anyTouched} text={t('CONFIRM')} />
      </form>
    );
  }
}
ExternalUploadFrom.defaultProps = {
  cols: 5,
};

ExternalUploadFrom.contextTypes = {
  t: PropTypes.func.isRequired,
};
ExternalUploadFrom = reduxForm({
  form: FORMS.externalUploadForm,
  enableReinitialize: true,
})(ExternalUploadFrom);

export default ExternalUploadFrom;
