export default {
  SET_DMS_LABELS: 'setDMSLabels',
  SET_DMS_LABELS_LOADING: 'setDMSLabelsLoading',
  SET_SELECTED_DMS_FILE_LABELS: 'setSelectedDMSFileLabels',
  SET_VERSION_HISTORY: 'setVersionHistory',
  SET_VERSION_HISTORY_LOADING: 'setVersionHistoryLoading',
  ADD_VERSION_HISTORY_ITEM: 'addVersionHistoryItem',
  SET_NEW_ACTIVE_VERSION: 'setNewActiveVersion',
  UPDATE_VERSION_DETAILS: 'updateVersionDetails',
};
