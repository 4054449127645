import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import Button from '../../../../../../common/form/components/button';
import { fetchComponentsToLink } from '../../../../../document-management/actions/dm-api-calls';
import ComponentPickerWithList from '../../../common/component-picker/component-picker-with-list';
import '../styles/defect-component-picker.scss';

const DefectComponentPicker = ({ defectID, component, inspectionID, fetchComponentsToLink, customCloseAction, customConfirmAction, additionalOptions, defaultComponent }, { t }) => {
  const [selectedComponent, setSelectedComponent] = useState([component]);

  return (
    <div className="defect-components-picker-modal">
      <ComponentPickerWithList
        questionId={defectID}
        fetchComponents={fetchComponentsToLink}
        inspectionId={inspectionID}
        selectedComponents={selectedComponent}
        onComponentSelect={setSelectedComponent}
        disabled={false}
        additionalOptions={additionalOptions}
      />
      <div className={`defect-components-picker-footer ${selectedComponent && selectedComponent.length > 0 ? 'dms-components-picker-footer-with-border' : ''}`}>
        <div className="action-buttons-container">
          <Button variant="success-outline" height="md" width="sm" text={t('Cancel')} onClick={customCloseAction} />
          <Button className="save-btn" height="md" width="sm" variant="success" text={t('SAVE')} onClick={() => customConfirmAction(selectedComponent)} />
        </div>
      </div>
    </div>
  );
};

DefectComponentPicker.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  fetchComponentsToLink: (filters, callback, loadingCallback) => dispatch(fetchComponentsToLink(filters, callback, loadingCallback)),
});

const mapStateToProps = state => ({
  inspectionID: state.inspectionReducer.inspectionID,
});

export default connect(mapStateToProps, mapDispatchToProps)(DefectComponentPicker);
