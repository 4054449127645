import { dropRight, findIndex } from 'lodash';
import { ComponentHierarchyUnassignedLevelID, fields, filterProps, sortDirection } from '../constants/constants';

class ComponentHierarchyHelpers {
  static onColumnSort = (SortByColumn, filters, apiCall, additionalFilters = {}, isUnassignedLevel = false) => {
    const newFilters = {
      ...filters,
      ...additionalFilters,
      [filterProps.sortByColumn]: SortByColumn,
      [filterProps.sortDirection]: filters[filterProps.sortDirection] === sortDirection.asc ? sortDirection.desc : sortDirection.asc,
      [filterProps.lastSeen]: 0,
    };
    apiCall && apiCall(newFilters, false, isUnassignedLevel);
  };

  static onSearch = (SearchText, filters, defaultFilters, apiCall, additionalFilters = {}, isLevelUnassigned = false) => {
    const newParams = {
      ...filters,
      ...additionalFilters,
      [filterProps.lastSeen]: defaultFilters[filterProps.lastSeen],
      SearchText,
    };
    apiCall && apiCall(newParams, false, isLevelUnassigned);
  };

  static onRowLevelClick = (row, levelPath, filters, defaultFilters, apiCall, stateCallback, additionalFilters = {}, searchTextPropName = 'SearchText') => {
    if (!levelPath || (levelPath && levelPath.length < 1)) return;
    const newPath = row?.PathItems ? [levelPath[0], ...row.PathItems] : [...levelPath, { Name: row?.Name, ID: row?.ID, Code: row?.Code }];
    const isLevelUnassigned = row?.ID === ComponentHierarchyUnassignedLevelID;
    const newParams = isLevelUnassigned
      ? defaultFilters
      : {
          ...filters,
          ...additionalFilters,
          [filterProps.lastSeen]: defaultFilters[filterProps.lastSeen],
          [filterProps.searchText]: defaultFilters[filterProps.searchText],
          [filterProps.parentID]: typeof row?.ID === 'number' ? row.ID : null,
          [filterProps.rootID]: newPath[1]?.ID,
        };

    apiCall && apiCall(newParams, false, isLevelUnassigned);
    stateCallback && stateCallback({ levelPath: newPath, [searchTextPropName]: defaultFilters[filterProps.searchText], currentLevelID: row?.ID, levelNestedNumber: row?.NestedNumber });
  };

  static onComponentLevelClick = (row, filters, defaultFilters, apiCall, stateCallback, additionalFilters = {}, searchTextPropName = 'SearchText') => {
    const isLevelUnassigned = row?.ID === ComponentHierarchyUnassignedLevelID;
    const newParams = isLevelUnassigned
      ? defaultFilters
      : {
          ...filters,
          ...additionalFilters,
          [filterProps.lastSeen]: defaultFilters[filterProps.lastSeen],
          [filterProps.searchText]: defaultFilters[filterProps.searchText],
          [filterProps.hierarchyID]: typeof row?.ID === 'number' ? row.ID : null,
        };

    apiCall && apiCall(newParams, false, isLevelUnassigned);
    stateCallback && stateCallback({ [searchTextPropName]: defaultFilters[filterProps.searchText] });
  };

  static onPathBackClick = (levelPath, filters, defaultFilters, apiCall, stateCallback, additionalFilters = {}, searchTextPropName = 'SearchText', reducerActionCallback) => {
    const newPath = Object.assign([], levelPath);
    newPath.pop();
    const newParams = {
      ...filters,
      ...additionalFilters,
      [filterProps.lastSeen]: defaultFilters[filterProps.lastSeen],
      [filterProps.searchText]: defaultFilters[filterProps.searchText],
      [filterProps.parentID]: newPath[newPath.length - 1].ID,
    };

    apiCall && apiCall(newParams);
    stateCallback &&
      stateCallback({ levelPath: [...newPath], [searchTextPropName]: defaultFilters[filterProps.searchText], currentLevelID: newParams[filterProps.parentID], levelNestedNumber: newPath?.length });
    reducerActionCallback && reducerActionCallback(newPath[newPath.length - 1].ID);
  };

  static onPathBackClickComponents = (levelPath, filters, defaultFilters, apiCall, stateCallback, additionalFilters = {}, searchTextPropName = 'SearchText') => {
    const newPath = Object.assign([], levelPath);
    newPath.pop();
    const newParams = {
      ...filters,
      ...additionalFilters,
      [filterProps.lastSeen]: defaultFilters[filterProps.lastSeen],
      [filterProps.searchText]: defaultFilters[filterProps.searchText],
      [filterProps.hierarchyID]: newPath[newPath.length - 1].ID,
    };
    apiCall && apiCall(newParams);
    stateCallback && stateCallback({ [searchTextPropName]: defaultFilters[filterProps.searchText] });
  };

  static onPathClick = (ID, levelPath, filters, defaultFilters, apiCall, stateCallback, additionalFilters = {}, searchTextPropName = 'SearchText') => {
    let newPath = Object.assign([], levelPath);
    newPath = ID ? dropRight(newPath, newPath.length - 1 - findIndex(newPath, { ID })) : [newPath[0]];
    const newParams = {
      ...filters,
      ...additionalFilters,
      [filterProps.lastSeen]: defaultFilters[filterProps.lastSeen],
      [filterProps.searchText]: defaultFilters[filterProps.searchText],
      [filterProps.parentID]: ID,
    };
    apiCall && apiCall(newParams);
    stateCallback && stateCallback({ levelPath: [...newPath], [searchTextPropName]: defaultFilters[filterProps.searchText], currentLevelID: ID, levelNestedNumber: newPath?.length });
  };

  static onPathClickComponent = (ID, filters, defaultFilters, apiCall, stateCallback, additionalFilters = {}, searchTextPropName = 'SearchText') => {
    const newParams = {
      ...filters,
      ...additionalFilters,
      [filterProps.lastSeen]: defaultFilters[filterProps.lastSeen],
      [filterProps.searchText]: defaultFilters[filterProps.searchText],
      [filterProps.hierarchyID]: ID,
    };
    apiCall && apiCall(newParams);
    stateCallback && stateCallback({ [searchTextPropName]: defaultFilters[filterProps.searchText] });
  };

  static onLoadMore = (filters, apiCall) => {
    apiCall && apiCall({ ...filters }, true);
  };

  static addUnassignedLevel = (componentLevels, levelPath, hideUnassignedComponents, t) => {
    const isAllLevel = levelPath.length === 1;
    if (!componentLevels) {
      return isAllLevel
        ? [{ ID: ComponentHierarchyUnassignedLevelID, [fields.name]: t('COMPONENT_HIERARCHY.UNASSIGNED'), [fields.code]: t('COMPONENT_HIERARCHY.UNASSIGNED'), visible: hideUnassignedComponents }]
        : [];
    }
    const isUnassignedLevelPresent = componentLevels[0][fields.id] === ComponentHierarchyUnassignedLevelID;
    return isAllLevel && !isUnassignedLevelPresent
      ? [
          { ID: ComponentHierarchyUnassignedLevelID, [fields.name]: t('COMPONENT_HIERARCHY.UNASSIGNED'), [fields.code]: t('COMPONENT_HIERARCHY.UNASSIGNED'), visible: hideUnassignedComponents },
          ...componentLevels,
        ]
      : componentLevels;
  };
}

export default ComponentHierarchyHelpers;
