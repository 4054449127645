import { fields } from '../constants/details-form-constants';

export const validate = values => {
  const errors = {};
  const { name, description, startDate, endDate } = fields;

  if (!values[name.name]) {
    errors[name.name] = name.errorRequired;
  }

  if (values[name.name] && values[name.name].length < name.minChars) {
    errors[name.name] = name.errorMinChars;
  }

  if (values[name.name] && values[name.name].length > name.maxChars) {
    errors[name.name] = name.errorMaxChars;
  }

  if (values[description.name] && values[description.name].length > description.maxChars) {
    errors[description.name] = description.errorMaxChars;
  }

  if (!values[startDate.name]) {
    errors[startDate.name] = startDate.errorRequired;
  }

  if (values[startDate.name] && values[endDate.name] && new Date(values[endDate.name]) < new Date(values[startDate.name])) {
    errors[endDate.name] = endDate.errorMinDate;
  }

  return errors;
};
