import React from 'react';
import { statuses } from '../permits/constants/permit-constants';
import '../../styles/work-order-section-permits.scss';
import RenderIf from '../../../../common/render-if/components/render-if';
import TableTemplate from '../../../../common/double-table/components/table-template';
import { formConstants, permitsTableConfig } from './constants/constants';
import { modules } from '../../constants/constants';
import EmptyState from '../../../../common/empty-state-v2/components/empty-state';
import PropTypes from 'prop-types';
import { values } from 'lodash';
import DisplayStatus from '../../../../common/display-status/components/display-status';

const WorkOrderSectionPermits = ({ module, handleCustomAction, sectionAction, disabled }, { t }) => {
  const { onModuleItemDeepLinkHandler } = module;

  return (
    <div className="work-order-section-permits">
      <RenderIf if={module && module.workOrderPermits?.length > 0}>
        <div className="notification-WO-tab__table">
          <TableTemplate
            data={module.workOrderPermits}
            handleFormatCells={(val, type) => {
              if (type === formConstants.fields.status) {
                return <DisplayStatus statuses={values(statuses)} status={val} displayTitle={false} />;
              }
              return val;
            }}
            showSearchBar={false}
            tableConfig={permitsTableConfig}
            showInfo={false}
            onRowClick={row => onModuleItemDeepLinkHandler(row, modules.permits.toLowerCase())}
            noDataText={t('WORK_ORDERS.NO_PERMITS')}
          />
        </div>
      </RenderIf>
      <RenderIf if={(module && !module.workOrderPermits) || module.workOrderPermits.length === 0}>
        <EmptyState emptyStateText={t('WORK_ORDERS.NO_PERMITS')} showButton={false} buttonAction={() => handleCustomAction(sectionAction)} buttonText={t('WORK_ORDERS.NO_PERMITS')} transparent />
      </RenderIf>
    </div>
  );
};

WorkOrderSectionPermits.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default WorkOrderSectionPermits;
