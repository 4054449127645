export default {
  GET_SAP_DETAILS: '/project/integration/details/sap',
  TEST_SAP_CONNECTION: '/project/integration/test/sap',
  SAVE_SAP_DATA: '/project/integration/add/sap',
  SYNC_SAP: '/project/integration/sync/sap',
  FETCH_PROJECT_EQUIPMENT: '/project/integration/locations/sap',
  GET_INTEGRATION_STATUS: '/project/integration/status/sap',
  SELECT_LOCATIONS: '/project/integration/locations/select/sap',
  GET_NOTIFICATIONS: '/project/integration/notifications/lite/sap',
  GET_NOTIFICATION_DETAILS: '',
};
