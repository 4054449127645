import React, { Component } from 'react';

import { assetActions, actionsMenu } from './constants/workorder-assets-dropdown';
import SmallDropdown from '../../../../common/small-dropdown/components/small-dropdown';

import Helpers from '../../../../common/helpers';
import { connect } from 'react-redux';

class ImagesDropdownMenu extends Component {
  getClickAction = (action, index, selfClose) => {
    const { data, openFile, deleteFile } = this.props;
    if (action === assetActions.openFile) {
      if (openFile) {
        openFile();
      } else {
        window.open(data.URL);
      }
    } else if (action === assetActions.downloadFile) {
      Helpers.getFileExtensionAndDownload(data);
    } else if (action === assetActions.deleteFile && deleteFile) {
      deleteFile(data.FileID);
      selfClose && selfClose();
    }
  };

  render() {
    const {
      user: { Role },
      deleteFile,
    } = this.props;
    let filteredMenu = actionsMenu.filter(item => item?.visibleFor?.indexOf(Role) > -1) || [];
    if (!deleteFile) {
      filteredMenu = actionsMenu.filter(item => item.action !== assetActions.deleteFile);
    }
    return <SmallDropdown actionsMenu={filteredMenu} getClickAction={this.getClickAction} offsetX={-100} />;
  }
}

const mapStateToProps = state => ({
  user: state.userReducer,
});

export default connect(mapStateToProps, null)(ImagesDropdownMenu);
