import { formConstants, textAreaMaxChars } from '../constants/measurement-location-constants';

export const validate = values => {
  const errors = {};

  if (!values[formConstants.fields.name] || values[formConstants.fields.name].length < 3) {
    errors[formConstants.fields.name] = {
      string: 'MEASUREMENT_LOCATION_FORM.ERROR.NAME_LENGTH',
    };
  }

  if (values[formConstants.fields.name] && values[formConstants.fields.name].length > 50) {
    errors[formConstants.fields.name] = {
      string: 'MEASUREMENT_POINT_DETAILS.FORM_NAME.ERROR_MAX_CHARS',
    };
  }

  if (values[formConstants.fields.description] && values[formConstants.fields.description].length > textAreaMaxChars) {
    errors[formConstants.fields.description] = 'MEASUREMENT_LOCATION_FORM.ERROR.DESCRIPTION_LENGTH';
  }

  return errors;
};
