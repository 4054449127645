import { formConstants as componentFields } from '../../../constants/component-constants';

export const filterParams = {
  searchText: 'SearchText',
  sortByColumn: 'SortByColumn',
  sortDirection: 'SortDirection',
  selectedFilter: 'SelectedFilter',
  includedIDs: 'IncludedIDs',
  statusFilter: 'StatusFilter',
  severityFilter: 'SeverityFilter',
  dateFrom: 'DateFrom',
  dateTo: 'DateTo',
  actionedFrom: 'ActionedFrom',
  actionedTo: 'ActionedTo',
  closedFrom: 'ClosedFrom',
  closedTo: 'ClosedTo',
  componentTypeFilter: 'ComponentTypeFilter',
  componentIDsFilter: 'ComponentIDsFilter',
  defectTypeFilter: 'DefectTypeFilter',
  defectMainTypeFilter: 'DefectMainTypeFilter',
  createdByFilter: 'CreatedByFilter',
  hasNotificationFilter: 'HasNotificationFilter',
  lastSeen: 'LastSeen',
  perPage: 'PerPage',
  totalItems: 'TotalNumber',
  hasNext: 'HasNext',
  assignees: 'Assignees',
  collaborators: 'Collaborators',
  linkedToMe: 'LinkedToMe',
  hasWorkOrder: 'HasWorkOrdersFilter',
  hasFiles: 'HasFilesFilter',
  has3DLocationFilter: 'Has3DLocationFilter',
  properties: 'Properties',
  componentsSearchFilter: 'ComponentSearchText',
};

export const sortDirection = {
  asc: 'ASC',
  desc: 'DESC',
};

export const defaultComponentsFilters = {
  [filterParams.sortByColumn]: componentFields.fields.id,
  [filterParams.sortDirection]: sortDirection.desc,
  [filterParams.perPage]: 20,
  [filterParams.lastSeen]: 0,
  [filterParams.searchText]: '',
  [filterParams.hasNext]: false,
};
