import React from 'react';
import { connect } from 'react-redux';
import AddModuleItemModal from '../../../../../../../common/add-module-item-modal/components/add-module-item-modal';
import { fetchWorkOrdersForObservations, linkWorkOrderToObservation, unlinkWorkOrderFromObservation } from '../../../../../actions/inspection-actions';
import { FEATURES } from '../../../../../../../common/constants';
import WorkOrderItemRow from './components/work-order-item-row';
import { setObservationWorkOrders } from '../../../../../actions/action-creators';

const AddWorkOrdersToObservation = props => {
  const { linkedWorkOrders, fetchWorkOrdersForObservations, unlinkWorkOrderFromObservation, linkObservationToWorkOrder, setObservationWorkOrders, customCloseAction } = props;

  return (
    <AddModuleItemModal
      ItemRowComponent={WorkOrderItemRow}
      linkedModuleItems={linkedWorkOrders}
      customCloseAction={customCloseAction}
      moduleItemTitle="INSPECTION_WORKORDERS"
      emptyStateLabel="WORK_ORDERS_SEARCH.EMPTY_STATE"
      linkCall={linkObservationToWorkOrder}
      unlinkCall={unlinkWorkOrderFromObservation}
      fetchModuleItems={fetchWorkOrdersForObservations}
      setModuleItemsInReducer={setObservationWorkOrders}
    />
  );
};

const mapStateToProps = state => ({
  linkedWorkOrders: state.inspectionReducer.observationWorkOrders,
});

const mapDispatchToProps = (dispatch, props) => ({
  fetchWorkOrdersForObservations: (searchText, pagingFilters, callback, loadingCallback) =>
    dispatch(fetchWorkOrdersForObservations(props.defectID, props.inspectionID, searchText, pagingFilters, FEATURES.workorders.externalID.visible, callback, loadingCallback)),
  unlinkWorkOrderFromObservation: (workOrderID, callback) => dispatch(unlinkWorkOrderFromObservation(props.defectID, workOrderID, callback)),
  linkObservationToWorkOrder: (workOrderID, callback) => dispatch(linkWorkOrderToObservation(props.defectID, workOrderID, callback)),
  setObservationWorkOrders: workOrders => dispatch(setObservationWorkOrders(workOrders)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddWorkOrdersToObservation);
