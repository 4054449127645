import {
  setExternaledUploadFiles,
  setComponentFilesUploaded,
  setDefectFilesUploaded,
  defectFilesLoading,
  setNDTMeasurementFilesUploaded,
  setNDTMeasurementPointFilesUploaded,
  setMeasurementFilesUploaded,
  measurementFilesLoading,
} from './action-creators';
import uploadApi from '../../../api/upload/actions';

import { isEmpty, get, map } from 'lodash';

import { measurementTypes } from '../../inspections/constants/constants';

import { uploadGroups, displayProps, formConstants } from '../../inspections/constants/defect-constants';

import genericFileImage from '../../inspections/assets/component-generic-file.svg';

import Helpers from '../../../common/helpers';
import DrawingHelpers from '../../../common/drawing-helpers';
//assets uploads

export const getFilesExternalUploaded = (ProjectID, defect) => {
  return async dispatch => {
    try {
      const elementID = get(defect, 'ID');
      let res = null;
      if (defect.SystemType === measurementTypes.component) {
        res = await uploadApi.getFilesExternalUpload({ ProjectID, ComponentID: elementID });
      } else {
        res = await uploadApi.getFilesExternalUpload({ ProjectID, DefectID: elementID });
      }
      let Data = get(res, 'data.Data');

      if (Data) {
        Data = Helpers.mapExternalFilesForModal(Data.Files);

        dispatch(setExternaledUploadFiles(Data));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const getComponentFilesUploaded = component_id => {
  return async dispatch => {
    try {
      const res = await uploadApi.getComponentFilesUploaded([{ component_id }]);
      let Data = get(res, 'data.Data');

      if (Data) {
        Object.keys(Data).map(key => {
          Data[key] = Helpers.mapExternalFilesForModal(Data[key], genericFileImage);
          return Data[key];
        });
        dispatch(setComponentFilesUploaded(Data));
      } else {
        dispatch(setDefectFilesUploaded({}));
      }
    } catch (e) {
      console.log(e);
      dispatch(setDefectFilesUploaded({}));
    }
  };
};

export const getComponentDMSFilesUploaded = component_id => {
  return async dispatch => {
    try {
      const res = await uploadApi.getComponentDMSFilesUploaded([{ component_id }]);
      let Data = get(res, 'data.Data');

      if (Data) {
        Data = Helpers.mapExternalFilesForModal(Data, genericFileImage);
        dispatch(setComponentFilesUploaded(Data));
      } else {
        dispatch(setDefectFilesUploaded({}));
      }
    } catch (e) {
      console.error(e);
      dispatch(setDefectFilesUploaded({}));
    }
  };
};

export const getDefectFilesUploaded = (defect_id, severity) => {
  return async (dispatch, getState) => {
    try {
      dispatch(defectFilesLoading(true));
      const { severityColors } = getState().themeReducer;

      const res = await uploadApi.getDefectFilesUploaded([{ defect_id }]);
      let Data = get(res, 'data.Data');

      if (Data) {
        Object.keys(Data).map(key => {
          Data[key] = Helpers.mapExternalFilesForModal(Data[key], genericFileImage);
          return Data[key];
        });

        if (Data[uploadGroups.linkedImages]) {
          Data[uploadGroups.linkedImages] = map(Data[uploadGroups.linkedImages], item => {
            let drawings = {};

            if (item[formConstants.fields.drawings]) {
              try {
                drawings = JSON.parse(item[formConstants.fields.drawings]);
              } catch (e) {
                drawings = {};
              }
            }

            return {
              ...item,
              [formConstants.fields.drawings]: item[formConstants.fields.drawings] ? drawings : null,
              actionsMenu: { ...Helpers.mapInfoIconDisplayProps(item, displayProps) },
            };
          });

          //related images reflect the severity color
          if (!isNaN(severity)) {
            Data[uploadGroups.linkedImages] = map(Data[uploadGroups.linkedImages], item => {
              item.Drawings = DrawingHelpers.adjustStrokeColor(item.Drawings, severity, severityColors);
              return item;
            });
          }
        }
        dispatch(defectFilesLoading(false));
        dispatch(setDefectFilesUploaded(Data));
      } else {
        dispatch(setDefectFilesUploaded({}));
      }
      dispatch(defectFilesLoading(false));
    } catch (e) {
      console.log(e);
      dispatch(setDefectFilesUploaded({}));
      dispatch(defectFilesLoading(false));
    }
  };
};

export const getMeasurementFilesUploaded = measurement_id => {
  return async dispatch => {
    try {
      dispatch(measurementFilesLoading(true));
      const res = await uploadApi.getMeasurementFilesUploaded([{ measurement_id }]);
      let Data = get(res, 'data.Data');

      if (Data) {
        Object.keys(Data).map(key => {
          Data[key] = Helpers.mapExternalFilesForModal(Data[key], genericFileImage);
          return Data[key];
        });

        dispatch(measurementFilesLoading(false));
        dispatch(setMeasurementFilesUploaded(Data));
      } else {
        dispatch(setMeasurementFilesUploaded({}));
      }
      dispatch(measurementFilesLoading(false));
    } catch (e) {
      console.log(e);
      dispatch(setMeasurementFilesUploaded({}));
      dispatch(measurementFilesLoading(false));
    }
  };
};

export const getNDTMeasurementFilesUploaded = measurement_id => {
  return async dispatch => {
    try {
      const res = await uploadApi.getNDTMeasurementFilesUploaded([{ measurement_id }]);
      let Data = get(res, 'data.Data');

      if (Data) {
        Object.keys(Data).map(key => {
          Data[key] = Helpers.mapExternalFilesForModal(Data[key], genericFileImage);
          return Data[key];
        });
        dispatch(setNDTMeasurementFilesUploaded(Data));
      } else {
        dispatch(setNDTMeasurementFilesUploaded({}));
      }
    } catch (e) {
      console.log(e);
      dispatch(setNDTMeasurementFilesUploaded({}));
    }
  };
};

export const getNDTMeasurementPointFilesUploaded = (measure_id, isTemp = false) => {
  return async dispatch => {
    try {
      if (isTemp) {
        dispatch(setNDTMeasurementPointFilesUploaded({}));
        return;
      }
      const res = await uploadApi.getNDTMeasurementPointFilesUploaded([{ measure_id }]);
      let Data = get(res, 'data.Data');

      if (Data) {
        Object.keys(Data).map(key => {
          Data[key] = Helpers.mapExternalFilesForModal(Data[key], genericFileImage);
          return Data[key];
        });
        dispatch(setNDTMeasurementPointFilesUploaded(Data));
      } else {
        dispatch(setNDTMeasurementPointFilesUploaded({}));
      }
    } catch (e) {
      console.log(e);
      dispatch(setNDTMeasurementPointFilesUploaded({}));
    }
  };
};

//related images reflect the severity color
export const changeLinkedImagesColor = (defectFiles, severity, onSuccess = () => null) => {
  return async (dispatch, getState) => {
    try {
      const { severityColors } = getState().themeReducer;
      dispatch(defectFilesLoading(true));

      let linkedImages = !isEmpty(defectFiles) ? defectFiles[uploadGroups.linkedImages] || [] : [];
      if (linkedImages) {
        linkedImages = map(linkedImages, item => {
          item.Drawings = DrawingHelpers.adjustStrokeColor(item.Drawings, severity, severityColors);
          return item;
        });
        await dispatch(setDefectFilesUploaded({ ...defectFiles, [uploadGroups.linkedImages]: linkedImages }));
        // small delay for reducer to change the data
        setTimeout(() => {
          dispatch(defectFilesLoading(false));
          onSuccess();
        }, 500);
      }
    } catch (e) {
      console.log(e);
      dispatch(defectFilesLoading(false));
    }
  };
};

export const saveExternalFiles = (defect, files, updloadGroup, callback) => {
  return async dispatch => {
    try {
      const elementID = get(defect, 'ID');
      if (!elementID) return;
      if (defect.SystemType === measurementTypes.component) {
        await uploadApi.saveExternalComponentFiles({ ComponentID: elementID, FileIDS: files, UpdloadGroup: updloadGroup });
        dispatch(getComponentFilesUploaded(elementID));
        callback();
      } else {
        await uploadApi.saveExternalFiles({ DefectID: elementID, FileIDS: files, UpdloadGroup: updloadGroup });
        dispatch(getDefectFilesUploaded(elementID));
        callback();
      }
    } catch (e) {
      console.log(e);
    }
  };
};
