import loginActionTypes from '../constants/action-types';

const loaderState = {
  isLoading: 0,
  type: null,
};

export const loaderReducer = (state = loaderState, payload) => {
  switch (payload.type) {
    case loginActionTypes.HANDLE_GLOBAL_LOADER:
      const isLoading = payload.data.isLoading ? ++state.isLoading : --state.isLoading;
      return { ...state, isLoading: isLoading < 0 ? 0 : isLoading, type: payload.data.type };
    default:
      return state;
  }
};
