import actionTypes from '../constants/action-types';
import { roleFields } from '../constants/role-constants';
import { teamFields } from '../constants/team-constants';

export const setUsersData = data => {
  return {
    type: actionTypes.SET_USERS_DATA,
    data,
  };
};
export const setUsersDataTableParams = data => {
  return {
    type: actionTypes.SET_USERS_DATA_TABLE_PARAMS,
    data,
  };
};
export const handleActivateUserModal = data => {
  return {
    type: actionTypes.HANDLE_ACTIVATE_USER_MODAL,
    data,
  };
};

export const handleUserModal = data => {
  return {
    type: actionTypes.HANDLE_USER_MODAL,
    data,
  };
};

export const removeUser = data => {
  return {
    type: actionTypes.DELETE_USER,
    data,
  };
};

export const setRoleListData = data => {
  return {
    type: actionTypes.SET_ROLE_LIST_DATA,
    data,
  };
};

// teams
export const setTeamsData = data => {
  return {
    type: actionTypes.SET_TEAMS,
    data,
  };
};

export const amendTeamsData = data => {
  return {
    type: actionTypes.AMEND_TEAMS,
    data,
  };
};
export const handleUpdateTeam = (item, prop = teamFields.id) => {
  return {
    type: actionTypes.UPDATE_TEAM_BY_PROP,
    data: {
      item,
      prop,
    },
  };
};
export const handleRemoveTeam = (item, prop = teamFields.id) => {
  return {
    type: actionTypes.REMOVE_TEAM_BY_PROP,
    data: {
      item,
      prop,
    },
  };
};

export const handleTeamsLoading = data => {
  return {
    type: actionTypes.SET_TEAMS_LOADING,
    data,
  };
};
export const handleAddTeam = data => {
  return {
    type: actionTypes.HANDLE_ADD_TEAM,
    data,
  };
};

// roles
export const setRolesData = data => {
  return {
    type: actionTypes.SET_ROLES,
    data,
  };
};

export const amendRolesData = data => {
  return {
    type: actionTypes.AMEND_ROLES,
    data,
  };
};

export const handleRemoveRole = (item, prop = roleFields.id) => {
  return {
    type: actionTypes.REMOVE_ROLE_BY_PROP,
    data: {
      item,
      prop,
    },
  };
};

export const handleRoleLoading = data => {
  return {
    type: actionTypes.SET_ROLE_LOADING,
    data,
  };
};

export const handleAddRole = data => {
  return {
    type: actionTypes.HANDLE_ADD_ROLE,
    data,
  };
};

export const handleUpdateRole = (item, prop = roleFields.id) => {
  return {
    type: actionTypes.UPDATE_ROLE_BY_PROP,
    data: {
      item,
      prop,
    },
  };
};
