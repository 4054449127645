import { remove, findIndex } from 'lodash';

import isolationCertificateApi from '../../../../../../api/isolation-certificates/actions';
import Helpers from '../../../../../../common/helpers';
import { sections } from '../constants/section-constants';
import { fields } from '../constants/details-form-constants';
import { fields as certificateFields } from '../constants/constants';
import { moduleSectionsFields } from '../../../../constants/constants';
import { fields as keyBoxFields, dropdownFields, keyBoxDetailsFields } from '../constants/key-boxes-form-constants';
import { filterProps, fields as tableFields } from '../../../isolation-certificates/constants/isolation-certificates-table';
import { fields as isolationComponentFields } from '../constants/isolation-components-constants';
import { fields as changeStatusFields } from '../constants/change-status-constants';
import { mapSections } from '../../../../helpers/inspection-helper';
import { setIsolationCertificates, setIsolationCertificatesFilters } from '../../../../actions/action-creators';
import { getIsolationCertificates } from '../../../../actions/isolation-certificates';

export const getIsolationCertificateDetails = (isolationCertificateId, callback) => {
  return async () => {
    try {
      callback(true);
      const res = await isolationCertificateApi.getIsolationCertificateDetails(isolationCertificateId);
      let certificate = res?.data?.Data;

      if (certificate[fields.startDate.name]) {
        certificate[fields.startDate.name] = new Date(certificate[fields.startDate.name] * 1000);
      }

      if (certificate[fields.endDate.name]) {
        certificate[fields.endDate.name] = new Date(certificate[fields.endDate.name] * 1000);
      }

      if (certificate && certificate.Sections) {
        certificate = await mapSections(certificate, sections, fields.name.name, null, true);
      }

      callback(false, certificate);
    } catch (e) {
      callback(false);
      console.error(e);
    }
  };
};

export const archiveIsolationCertificate = (data, isolationCertificate, certificates, filters, callback) => {
  return async dispatch => {
    try {
      await isolationCertificateApi.archiveIsolationCertificate(data);
      isolationCertificate[certificateFields.archived] = data[certificateFields.archive];

      dispatch(getIsolationCertificates(filters, certificates, false));

      callback(isolationCertificate);
    } catch (e) {
      console.error(e);
    }
  };
};

export const deleteIsolationCertificate = (data, certificates, filters, callback) => {
  return async dispatch => {
    try {
      await isolationCertificateApi.deleteIsolationCertificate(data);
      remove(certificates, { [certificateFields.id]: data[certificateFields.isolationCertificateId] });

      filters[filterProps.totalItems] -= 1;
      filters[filterProps.lastSeen] -= 1;

      dispatch(setIsolationCertificatesFilters(filters));
      dispatch(setIsolationCertificates(false, certificates));
      callback({}, true);
    } catch (e) {
      console.error(e);
    }
  };
};

export const updateIsolationCertificateDetails = data => {
  return async dispatch => {
    try {
      //this here was done due to error that would be caused if the field was touched but no value was given to it and it would be '' instead of int
      data.values[fields.endDate.name] = data.values[fields.endDate.name] ? data.values[fields.endDate.name] : null;
      await isolationCertificateApi.updateIsolationCertificateDetails(data.values);
      const certificates = Object.assign([], data.modules);

      const index = findIndex(certificates, { [certificateFields.id]: data.values[certificateFields.isolationCertificateId] });

      if (index > -1) {
        const selected = Object.assign({}, certificates[index]);
        selected[fields.name.name] = data.values[fields.name.name];
        certificates[index] = selected;

        dispatch(setIsolationCertificates(false, certificates));
      }
    } catch (e) {
      console.error(e);
    }
  };
};

export const answerOnQuestion = (data, callback) => {
  return async () => {
    try {
      await isolationCertificateApi.answerOnQuestion(data);

      if (typeof callback === 'function') {
        callback();
      }
    } catch (e) {
      console.error(e);
    }
  };
};

export const getAvailableKeyBoxes = (data, callback) => {
  return async () => {
    try {
      const res = await isolationCertificateApi.getAvailableKeyBoxes(data);
      const keyBoxes = res?.data?.Data;

      if (keyBoxes) {
        callback(keyBoxes);
      }
    } catch (e) {
      console.error(e);
    }
  };
};

export const assignKeyBox = data => {
  return async dispatch => {
    try {
      const values = Object.assign({}, data.values);
      const isAssign = values[keyBoxFields.keyBox.name] && values[keyBoxFields.keyBox.name][dropdownFields.valueField];
      values[keyBoxFields.keyBox.name] = isAssign ? values[keyBoxFields.keyBox.name][dropdownFields.valueField] : values[keyBoxFields.keyBox.name];
      if (isAssign) {
        await isolationCertificateApi.assignKeyBox(values);
      } else {
        await isolationCertificateApi.unAssignKeyBox(Helpers.formatDataForUnassignKeyBox(data.values, values[keyBoxFields.keyBox.name][dropdownFields.prevKeyBoxID]));
      }
      const moduleDetails = Object.assign([], data.module);

      // assign new key box details to the main object for displaying sections
      if (data.section && typeof data.handleChangeModule === 'function' && moduleDetails.Sections) {
        const sectionIndex = findIndex(moduleDetails.Sections, { [certificateFields.id]: data.section[certificateFields.id] });
        if (sectionIndex > -1) {
          const selectedSection = Object.assign({}, moduleDetails.Sections[sectionIndex]);
          selectedSection[moduleSectionsFields.expanded] = true;
          moduleDetails.Sections[sectionIndex] = selectedSection;
          moduleDetails[keyBoxDetailsFields.keyBoxProp] = Object.assign({}, data.values[keyBoxFields.keyBox.name]);
          data.values[keyBoxFields.keyBox.name].callback && data.values[keyBoxFields.keyBox.name].callback();
          data.handleChangeModule(moduleDetails);
        }
      }

      const certificates = Object.assign([], data.modules);
      const index = findIndex(certificates, { [certificateFields.id]: data.values[certificateFields.isolationCertificateId] });

      // adjust key box number in the certificates list so it's displayed in the table to the left
      if (index > -1) {
        const selected = Object.assign({}, certificates[index]);
        selected[tableFields.keySafeNumber] = data.values[keyBoxFields.keyBox.name][keyBoxFields.keyBox.initialValuesName]
          ? data.values[keyBoxFields.keyBox.name][keyBoxFields.keyBox.displayData]
          : null;
        certificates[index] = selected;

        dispatch(setIsolationCertificates(false, certificates));
      }
    } catch (e) {
      console.error(e);
    }
  };
};

export const addIsolationComponent = (data, callback) => {
  return async () => {
    try {
      const res = await isolationCertificateApi.addIsolationComponent(data);
      const isolationComponent = res?.data?.Data;

      callback(isolationComponent);
    } catch (e) {
      console.error(e);
    }
  };
};

export const updateIsolationComponent = (data, callback) => {
  return async () => {
    try {
      const res = await isolationCertificateApi.updateIsolationComponent(data);
      const isolationComponent = res?.data?.Data;

      callback(isolationComponent);
    } catch (e) {
      console.error(e);
    }
  };
};

export const deleteIsolationComponent = (data, callback) => {
  return async () => {
    try {
      await isolationCertificateApi.deleteIsolationComponent(data);

      callback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const deleteIsolationComponentFile = (data, callback) => {
  return async () => {
    try {
      await isolationCertificateApi.deleteIsolationComponentFile(data);

      callback(data);
    } catch (e) {
      console.error(e);
    }
  };
};

export const orderIsolationComponents = (data, callback) => {
  return async () => {
    try {
      await isolationCertificateApi.orderIsolationComponents(data);

      callback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const generateAndDownloadPDF = (isolationCertificateId, includePopulatedDrawingsPDF, callback) => {
  return async () => {
    try {
      const res = await isolationCertificateApi.generatePDF(isolationCertificateId, includePopulatedDrawingsPDF);
      const fileName = res.request.getResponseHeader('content-disposition').split('filename=')[1].split(';')[0];

      const file = res?.data;
      file.name = fileName || null;
      if (file) {
        Helpers.downloadPDFFile(file);
      }

      callback && callback();
    } catch (e) {
      console.error(e);
      callback();
    }
  };
};

export const generateAndDownloadPDFPopulatedDrawings = data => {
  return async () => {
    try {
      const res = await isolationCertificateApi.generatePDFPopulatedDrawing(data);
      const fileName = res.request.getResponseHeader('content-disposition').split('filename=')[1].split(';')[0];

      const file = res?.data;
      file.name = fileName || null;
      if (file) {
        Helpers.downloadPDFFile(file);
      }
    } catch (e) {
      console.error(e);
    }
  };
};

export const changeIsolationCertificateStatus = (data, certificates, filters, callback) => {
  return async dispatch => {
    try {
      await isolationCertificateApi.changeIsolationCertificateStatus(data);

      certificates = await formatCertificatesOnStatusChange(certificates, filters, data);

      dispatch(setIsolationCertificates(false, certificates));
      callback(true);
    } catch (e) {
      console.error(e);
      callback();
    }
  };
};

export const getContractors = (data, callback) => {
  return async () => {
    try {
      const res = await isolationCertificateApi.getIsolationContractorsList(data);
      const contractors = res?.data?.Data?.Contractors;

      if (contractors) {
        callback(contractors);
      }
    } catch (e) {
      console.error(e);
    }
  };
};

export const changeIsolationCertificateStatusWithData = (data, certificates, filters, callback) => {
  return async dispatch => {
    try {
      await isolationCertificateApi.changeIsolationCertificateStatusWithData(data);

      certificates = await formatCertificatesOnStatusChange(certificates, filters, data);

      dispatch(setIsolationCertificates(false, certificates));
      callback(true);
    } catch (e) {
      console.error(e);
      callback();
    }
  };
};

const formatCertificatesOnStatusChange = async (certificates, filters, data) => {
  const index = findIndex(certificates, { [moduleSectionsFields.id]: data[isolationComponentFields.isolationCertificate.name] });

  if (index > -1) {
    // if the new status of the certificate is one of the selected for the filters, or it's show all, just change it,
    // else, remove it since now it shouldn't be in the list
    if (!filters[filterProps.status] || !filters[filterProps.status].length || filters[filterProps.status].indexOf(data[changeStatusFields.status]) > -1) {
      const certificate = Object.assign({}, certificates[index]);
      certificate[changeStatusFields.status] = data[changeStatusFields.status];
      certificates.splice(index, 1, certificate);
    } else {
      certificates.splice(index, 1);
    }
  }

  return certificates;
};

export const deleteIsolationCertificateFile = (IsolationCertificateID, FileID, sucessCallback) => {
  return async dispatch => {
    try {
      await isolationCertificateApi.deleteIsolationCertificateFile({ FileID, IsolationCertificateID });

      sucessCallback && sucessCallback();
    } catch (err) {
      console.log(err);
    }
  };
};

export const getIsolationCertificateStatusFlows = (certificateId, callback) => {
  return async () => {
    try {
      const res = await isolationCertificateApi.getIsolationCertificateStatusFlows([{ isolation_certificate_id: certificateId }]);
      const { Data } = res.data;
      if (Data) {
        callback && callback(Data);
      }
    } catch (e) {
      console.error(e);
    }
  };
};

// isolation certificate comments
export const fetchIsolationCertificateComments = (params, callback) => {
  return async () => {
    try {
      callback({
        commentsLoading: true,
      });
      const res = await isolationCertificateApi.fetchIsolationCertificateComments([params]);
      const { Data } = res?.data;
      callback({
        commentsList: Data,
        commentsLoading: false,
      });
    } catch (e) {
      callback({
        commentsLoading: false,
      });
      console.error(e);
    }
  };
};

export const addIsolationCertificateComment = (filters, dataCallback, loadingCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      await isolationCertificateApi.addIsolationCertificateComment(filters);
      dataCallback && typeof dataCallback === 'function' && dataCallback();
      isLoadingCallbackValid && loadingCallback(false);
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
    }
  };
};

export const deleteIsolationCertificateComment = (filters, dataCallback, loadingCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      await isolationCertificateApi.deleteIsolationCertificateComment(filters);
      dataCallback && typeof dataCallback === 'function' && dataCallback();
      isLoadingCallbackValid && loadingCallback(false);
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
    }
  };
};
