import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { detailsPages } from '../../constants/constants';

import AccessRenderer from '../../../../common/access-renderer/components/access-renderer';
import MaintenanceRegimeRightSide from '../../../maintenance-regime/components/maintenance-regime/maintenance-regime-right-side';
import NDTMeasurementDetails from '../ndt/ndt-measurement-details';
import NDTMeasurementDetailsGrouped from '../ndt/ndt-measurement-details-grouped';
import PermitDetails from '../permits/permit-details';
import WorkOrderRightSide from '../work-order/work-order-right-side';
import AreasDetails from './area-details';
import ComponentDetails from './component-details';
import DefectDetails from './defect-details';
import InspectionSettings from './inspection-settings';
import IsolationCertificateDetails from './isolation-certificate-details/components/isolation-certificate-details';
import MeasurementDetails from './measurement-details';
import NotificationDetails from './notification-details';
import PointImages from './point-images';
import MeasurementLocationDetails from './readings-and-gauges/components/measurement-location-details';

import Icon from '../../../../common/icon/components/icon';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../common/permissions-constants';
import MeasurementPointDetails from './readings-and-gauges/components/measurement-point-details';

class Content extends Component {
  openFullScreen = (_item, index) => {
    const { setActiveSlide } = this.props;
    const el = document.querySelectorAll('.point-images__container .image-gallery-fullscreen-button');
    //click on full screen icon
    //click on full screen will also slide the slider to the clicked element
    setActiveSlide(index);
    if (el && el[0]) {
      el[0].click();
    }
  };

  renderContent = () => {
    const {
      activePage,
      updateDefect,
      createComponent,
      images,
      setActiveSlide,
      activeItemIndex,
      sliderStyle,
      pointImagesLoading,
      loadMoreImages,
      imagesCount,
      inspectPoint,
      handleInspectPointCloud,
      objectToolClick,
      cols,
      onClose,
      handleQuickDefect,
      handleRealCameraChange,
      realCameraType,
      realCameraTypeItems,
      startWorkAreaInsertion,
      // startShapeInsertion,
      inspectionId,
      projectId,
      duplicateComponent,
      openInspectionAssetModal,
      openDownloadReportModalSingleDefect,
      queryItem,
      handleActivePage,
      viewer,
      showGeometryWarning,
      toggleElement,
    } = this.props;

    switch (activePage) {
      case detailsPages.settings:
        return <InspectionSettings />;
      case detailsPages.inspect:
        return (
          <PointImages
            images={images}
            activeItemIndex={activeItemIndex}
            onSlide={currentIndex => setActiveSlide(currentIndex)}
            slideToIndex={(item, index) => setActiveSlide(index)}
            CustomComponent={(item, index) => <Icon name="newTab" size="xs" className="new-tab" onClick={() => this.openFullScreen(item, index)} />}
            pointImagesLoading={pointImagesLoading}
            loadMoreImages={loadMoreImages}
            imagesCount={imagesCount}
            inspectPoint={inspectPoint}
            handleInspectPointCloud={handleInspectPointCloud}
            cols={cols}
            onClose={onClose}
            sliderStyle={sliderStyle}
            handleQuickDefect={handleQuickDefect}
            handleRealCameraChange={handleRealCameraChange}
            realCameraType={realCameraType}
            realCameraTypeItems={realCameraTypeItems}
            openInspectionAssetModal={openInspectionAssetModal}
          />
        );
      case detailsPages.defect:
        return (
          <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.observations].view.name}>
            {({ hasAccess }) => {
              return hasAccess ? (
                <DefectDetails
                  openDownloadReportModalSingleDefect={openDownloadReportModalSingleDefect}
                  queryItem={queryItem}
                  handleActivePage={handleActivePage}
                  inspectionID={inspectionId}
                  projectID={projectId}
                  objectToolClick={objectToolClick}
                  viewer={viewer}
                  showGeometryWarning={showGeometryWarning}
                  toggleElement={toggleElement}
                />
              ) : null;
            }}
          </AccessRenderer>
        );
      case detailsPages.measurement:
        return (
          <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.measurements].view.name}>
            {({ hasAccess }) => {
              return hasAccess ? (
                <MeasurementDetails
                  openDownloadReportModalSingleDefect={openDownloadReportModalSingleDefect}
                  queryItem={queryItem}
                  inspectionID={inspectionId}
                  handleActivePage={handleActivePage}
                  viewer={viewer}
                  showGeometryWarning={showGeometryWarning}
                />
              ) : null;
            }}
          </AccessRenderer>
        );
      case detailsPages.component:
        return (
          <ComponentDetails
            updateDefect={updateDefect}
            objectToolClick={objectToolClick}
            createComponent={createComponent}
            duplicateComponent={duplicateComponent}
            inspectionId={inspectionId}
            projectId={projectId}
            queryItem={queryItem}
            viewer={viewer}
            handleActivePage={handleActivePage}
            showGeometryWarning={showGeometryWarning}
            toggleElement={toggleElement}
          />
        );
      case detailsPages.notifications:
        return (
          <NotificationDetails
            inspectionId={inspectionId}
            projectId={projectId}
            queryItem={queryItem}
            handleActivePage={handleActivePage}
            showGeometryWarning={showGeometryWarning}
            objectToolClick={objectToolClick}
            viewer={viewer}
            toggleElement={toggleElement}
          />
        );
      // case detailsPages.explosiveZone:
      //   return (
      //     <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.zones]?.view.name}>
      //       {({ hasAccess }) =>
      //         hasAccess ? (
      //           <ExplosiveZoneDetails
      //             inspectionId={inspectionId}
      //             projectId={projectId}
      //             queryItem={queryItem}
      //             startWorkAreaInsertion={startWorkAreaInsertion}
      //             startShapeInsertion={startShapeInsertion}
      //             handleActivePage={handleActivePage}
      //           />
      //         ) : null
      //       }
      //     </AccessRenderer>
      //   );
      case detailsPages.areas:
        return (
          <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.areas].view.name}>
            {({ hasAccess }) => {
              return hasAccess ? (
                <AreasDetails
                  inspectionId={inspectionId}
                  projectId={projectId}
                  queryItem={queryItem}
                  handleActivePage={handleActivePage}
                  startWorkAreaInsertion={startWorkAreaInsertion}
                  objectToolClick={objectToolClick}
                />
              ) : null;
            }}
          </AccessRenderer>
        );
      case detailsPages.ndt:
        return (
          <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.ndt].view.name}>
            {({ hasAccess }) => {
              return hasAccess ? (
                <NDTMeasurementDetails
                  inspectionId={inspectionId}
                  projectId={projectId}
                  queryItem={queryItem}
                  handleActivePage={handleActivePage}
                  markPointLocation={startWorkAreaInsertion}
                  viewer={viewer}
                  showGeometryWarning={showGeometryWarning}
                />
              ) : null;
            }}
          </AccessRenderer>
        );

      case detailsPages.ndtGrouped:
        return (
          <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.ndt].view.name}>
            {({ hasAccess }) => {
              return hasAccess ? (
                <NDTMeasurementDetailsGrouped inspectionId={inspectionId} projectId={projectId} queryItem={queryItem} handleActivePage={handleActivePage} markPointLocation={startWorkAreaInsertion} />
              ) : null;
            }}
          </AccessRenderer>
        );

      case detailsPages.isolationCertificate:
        return (
          <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.isolationCertificates].view.name}>
            {({ hasAccess }) => {
              return hasAccess ? <IsolationCertificateDetails inspectionId={inspectionId} projectId={projectId} queryItem={queryItem} /> : null;
            }}
          </AccessRenderer>
        );
      case detailsPages.permits:
        return (
          <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.permits].view.name}>
            {({ hasAccess }) => {
              return hasAccess ? <PermitDetails inspectionId={inspectionId} projectId={projectId} queryItem={queryItem} /> : null;
            }}
          </AccessRenderer>
        );

      case detailsPages.workorder:
        return <WorkOrderRightSide startWorkAreaInsertion={startWorkAreaInsertion} inspectionId={inspectionId} projectId={projectId} queryItem={queryItem} handleActivePage={handleActivePage} />;

      case detailsPages.maintenanceRegime:
        return (
          <MaintenanceRegimeRightSide startWorkAreaInsertion={startWorkAreaInsertion} inspectionId={inspectionId} projectId={projectId} queryItem={queryItem} handleActivePage={handleActivePage} />
        );
      case detailsPages.measurementLocation:
        return (
          <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].view.name}>
            {({ hasAccess }) => {
              return hasAccess ? (
                <MeasurementLocationDetails
                  queryItem={queryItem}
                  handleActivePage={handleActivePage}
                  inspectionID={inspectionId}
                  projectID={projectId}
                  objectToolClick={objectToolClick}
                  viewer={viewer}
                  showGeometryWarning={showGeometryWarning}
                />
              ) : null;
            }}
          </AccessRenderer>
        );
      case detailsPages.measurementPoint:
        return (
          <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].view.name}>
            {({ hasAccess }) => {
              return hasAccess ? (
                <MeasurementPointDetails
                  queryItem={queryItem}
                  handleActivePage={handleActivePage}
                  inspectionID={inspectionId}
                  projectID={projectId}
                  objectToolClick={objectToolClick}
                  viewer={viewer}
                  showGeometryWarning={showGeometryWarning}
                />
              ) : null;
            }}
          </AccessRenderer>
        );
      default:
        return '';
    }
  };

  render() {
    return <div className="content">{this.renderContent()}</div>;
  }
}

Content.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Content;
