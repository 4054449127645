import Helpers from '../../../../../common/helpers';
import { fields } from '../constants/constants';

export const formatMaintenanceRegimesTableCells = (
  value,
  type,
  searchTerm,
  _row,
  _onDropdownClick,
  _t,
  _pointerDotID,
  _user,
  _onToggleClick,
  _onDeleteRow,
  _statusData,
  _handleChangeStatus,
  _onLinkFileClick,
  _selectedItem
) => {
  if (type === fields.name) {
    return (
      <p className="f-primary" title={value}>
        {Helpers.getSearchTermText(Helpers.getShortenedText(value, 25), searchTerm)}
      </p>
    );
  } else if (type === fields.nextDate) {
    return <p className="f-primary">{Helpers.getDateFromUnix(value, 'DD MMM, YYYY')}</p>;
  } else {
    return value ? value : '-';
  }
};
