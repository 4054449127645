export default {
  FETCH_COMPONENT_HIERARCHY_LEVELS: '/project/hierarchy/list',
  CREATE_COMPONENT_HIERARCHY_LEVELS: '/project/hierarchy/add',
  FETCH_COMPONENT_HIERARCHY_LEVEL_DETAILS: '/project/hierarchy/details',
  UPDATE_COMPONENT_HIERARCHY_LEVEL: '/project/hierarchy/update',
  FETCH_COMPONENT_HIERARCHY_PROP_SUGGESTION: '/project/hierarchy/properties/names',
  ADD_COMPONENT_HIERARCHY_PROP: '/project/hierarchy/property/add',
  UPDATE_COMPONENT_HIERARCHY_PROP: '/project/hierarchy/property/update',
  DELETE_COMPONENT_HIERARCHY_PROP: '/project/hierarchy/property/delete',
  UPDATE_COMPONENT_HIERARCHY_COMPONENT: '/project/hierarchy/component/update',
  FETCH_UNASSIGNED_COMPONENTS: '/project/hierarchy/components/unassigned',
  FETCH_COMPONENT_HIERARCHY_COMPONENT: '/project/hierarchy/components/list',
  FETCH_COMPONENT_DETAILS: '/inspection/component/details',
  DELETE_COMPONENT_HIERARCHY_LEVEL: '/project/hierarchy/delete',
  FETCH_COMPONENT_PROPERTIES: '/properties/component/get',
};
