import PropTypes from 'prop-types';
import React from 'react';
import TableTemplate from '../../../../common/double-table/components/table-template';
import EmptyState from '../../../../common/empty-state-v2/components/empty-state';
import { colorPalette } from '../../../../common/form/constants/constants';
import Icon from '../../../../common/icon/components/icon';
import RenderIf from '../../../../common/render-if/components/render-if';
import '../../styles/module-section-work-areas.scss';
import SetCameraPosition from '../right-toolbar/set-camera-position';
import { formConstants, workAreaTableConfig } from './constants/constants';

const ModuleSectionWorkAreas = ({ module, handleCustomAction, sectionAction, disabled, viewer }, { t }) => {
  return (
    <div className="work-order-section-work-areas">
      <RenderIf if={module && module.workAreas?.length > 0}>
        <div className="notification-WO-tab__table">
          <TableTemplate
            data={module.workAreas}
            handleFormatCells={(value, type, index, item) => {
              if (type === formConstants.fields.name) {
                return (
                  <div className="work-areas__name">
                    {item.Colour ? <div className="work-areas__name__status-icon" style={{ backgroundColor: colorPalette[item.Colour] }} /> : null}
                    <div title={value} className="f-primary work-areas__name__text">
                      {value}
                    </div>
                  </div>
                );
              }
              if (type === 'focus') {
                return (
                  <Icon
                    onClick={() => {
                      if (item?.Geometry?.coordinates?.[0] && item?.CameraPosition?.coordinates?.[0] && viewer) {
                        viewer.zoomToPosition({ x: item.CameraPosition.coordinates[0], y: item.CameraPosition.coordinates[1], z: item.CameraPosition.coordinates[2] }, item.Geometry.coordinates, 500);
                      }
                    }}
                    name={'crosshair'}
                    size="sm"
                  />
                );
              }
              return value;
            }}
            showSearchBar={false}
            tableConfig={workAreaTableConfig}
            showInfo={false}
            onRowClick={() => {}}
            noDataText={t('WORK_ORDERS.NO_WORK_AREAS')}
          />
        </div>
        <SetCameraPosition className="camera-pos" disabled={disabled} handleChange={module.setCameraPosition} horizontalAlign="left" />
      </RenderIf>
      <RenderIf if={(module && !module.workAreas) || module.workAreas.length === 0}>
        <EmptyState emptyStateText={t('WORK_ORDERS.NO_WORK_AREAS')} showButton={false} buttonAction={() => handleCustomAction(sectionAction)} buttonText={t('WORK_ORDER.NO_WORK_AREAS')} transparent />
      </RenderIf>
    </div>
  );
};

ModuleSectionWorkAreas.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ModuleSectionWorkAreas;
