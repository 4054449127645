import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../common/form/components/button';
import RenderIf from '../../../common/render-if/components/render-if';
import Helpers from '../../../common/helpers';
import { ReactComponent as Pipes } from '../assets/pipes.svg';
import { routes } from '../../../common/routes-constants';

import '../styles/page-not-found.scss';

const PageNotFound = ({ hideSideImage }, { t }) => {
  return (
    <div className="page-not-found">
      <div className="page-not-found__content">
        <div>
          <h2 className="f-primary bold">{t('PAGE_NOT_FOUND.TITLE')}</h2>
          <h3 className="f-primary bold">{t('PAGE_NOT_FOUND.SUBTITLE')}</h3>
          <h6 className="f-secondary-dark page-not-found__content--message">{t('PAGE_NOT_FOUND.MESSAGE')}</h6>
          <Button
            type="button"
            variant="success"
            width="sm"
            className="page-not-found__content--button"
            text={t('PAGE_NOT_FOUND.BUTTON')}
            onClick={() => Helpers.goTo(routes.protectedRoutes.project.fullPath)}
          />
        </div>
      </div>
      <RenderIf if={!hideSideImage}>
        <div className="page-not-found__side-image">
          <Pipes />
        </div>
      </RenderIf>
    </div>
  );
};

PageNotFound.contextTypes = {
  t: PropTypes.func.isRequired,
};

PageNotFound.propTypes = {
  hideSideImage: PropTypes.bool,
};

export default PageNotFound;
