import { head, takeRight } from 'lodash';
import React from 'react';
import Icon from '../../icon/components/icon';
import RenderIf from '../../render-if/components/render-if';
import '../styles/path-viewer.scss';
import BreadcrumbsPopover from './breadcrumb-popover';

const renderPath = (levels, onClick, propInPath) => {
  let renderLevels = levels;
  const isLarger = renderLevels.length > 4;
  if (isLarger) {
    const rootLevel = head(renderLevels);
    const levelsToShow = takeRight(renderLevels, 3);
    renderLevels = [rootLevel, { levels: [...renderLevels], isBreadcrumbs: true }, ...levelsToShow];
  }
  return renderLevels.map((level, i) => {
    return (
      <div key={i} className="path-viewer__path__level ">
        {level.isBreadcrumbs ? (
          <BreadcrumbsPopover levels={level.levels} propInPath={propInPath} />
        ) : (
          // Truncating to 16 characters due to size limit and text breaking on UI - as per agreement
          <p className={`path-viewer__path__level__title ${renderLevels.length - 1 === i ? 'last' : 'button'}`} onClick={() => onClick(level.ID, level)} title={level[propInPath]}>
            {level[propInPath].length > 16 ? `${level[propInPath].slice(0, 16)}...` : level[propInPath]}
          </p>
        )}
        <p>{renderLevels.length - 1 === i ? null : '/'}</p>
      </div>
    );
  });
};

const PathViewer = ({ levels, onBackClick, onPathClick, propInPath }) => {
  const displayCode = levels[levels.length - 1].Code ? levels[levels.length - 1].Code : '';
  const displayName = levels[levels.length - 1].Name ? levels[levels.length - 1].Name : '';

  return (
    <div className="path-viewer">
      <div className="path-viewer__info">
        <RenderIf if={levels.length > 1}>
          <div onClick={onBackClick}>
            <Icon name="chevron" size="md" />
          </div>
        </RenderIf>
        <h5>{`${displayCode} ${displayName}`}</h5>
      </div>
      <div className={`path-viewer__path ${levels.length > 1 ? 'has-chevron' : ''}`}>{renderPath(levels, onPathClick, propInPath)}</div>
    </div>
  );
};

PathViewer.defaultProps = {
  propInPath: 'Code',
};

export default PathViewer;
