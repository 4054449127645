import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { FORMS } from '../constants';
import Button from '../form/components/button';
import CustomInput from '../form/components/input';
import './styles/confirm-with-modal.scss';
import { validate as confirmModalValidate } from './validators/confirm-with-modal-form';

const ConfirmWithModal = (props, { t }) => {
  const { modalDisabled, customCloseAction, handleSubmit, text, textProps, label, placeholder, name, id } = props;
  return (
    <form onSubmit={handleSubmit} className="confirm-password-modal">
      <p className="mb-20 mt-20">{t(text, textProps)}</p>
      <Field component={CustomInput} label={t(label)} placeholder={t(placeholder)} name={name} id={id} />
      <Button onClick={customCloseAction} type="button" disabled={modalDisabled} text={t('CANCEL')} variant="gray-outline" height="md" className="modal-button-custom" />
      <Button type="submit" text={t('CONFIRM')} variant="danger" height="md" className="modal-button-custom" />
    </form>
  );
};

ConfirmWithModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

ConfirmWithModal.propTypes = {
  modalDisabled: PropTypes.bool,
  customCloseAction: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  text: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
};

export default reduxForm({ form: FORMS.deleteWithModalForm, validate: confirmModalValidate })(ConfirmWithModal);
