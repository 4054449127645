import actionTypes from '../constants/action-types';

const inspectionState = {
  projects: [],
  activeProject: null,
  hoveredProject: null,
  projectToEdit: null,
  archiveProjectModalData: {
    isOpen: false,
  },
  deleteProjectModalData: {
    isOpen: false,
  },
};

export const projectReducer = (state = inspectionState, payload) => {
  switch (payload.type) {
    case actionTypes.LOAD_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: payload.data.map(project => ({ ...project, active: project.active || false, selected: project.selected || false })),
      };

    case actionTypes.SELECT_PROJECT:
      return {
        ...state,
        projects: state.projects.map(el => {
          if (el.ID === payload.data) {
            el.selected = true;
          } else {
            el.selected = false;
          }
          return el;
        }),
        hoveredProject: state.projects.find(el => el.ID === payload.data && el.selected) || null,
      };
    case actionTypes.TOGGLE_PROJECT_INSPECTIONS:
      return {
        ...state,
        projects: state.projects.map(el => {
          if (el.ID === payload.data) {
            el.selected = true;
            el.active = !el.active;
          } else {
            el.selected = false;
            el.active = false;
          }
          return el;
        }),
        activeProject: state.projects.find(el => el.ID === payload.data && el.active) || null,
      };
    case actionTypes.SET_PROJECT_EDIT:
      return {
        ...state,
        projectToEdit: payload.data,
      };
    case actionTypes.HANDLE_ARCHIVE_PROJECT_MODAL:
      return {
        ...state,
        archiveProjectModalData: payload.data,
      };
    case actionTypes.HANDLE_DELETE_PROJECT_MODAL:
      return {
        ...state,
        deleteProjectModalData: payload.data,
      };
    default:
      return state;
  }
};
