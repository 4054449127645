import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Loader from '../../../../../../../common/global-loader/components/simple-loader';
import Modal from '../../../../../../../common/modal/components/modal';
import { userTeamFields } from '../../../../../../../common/user-team/constants/constants';
import { fetchAlarmsDescriptors, fetchMeasurementPointAlarms, updateMeasurementPointAlarms } from '../../../../readings-and-gauges/actions/measurement-point-actions';
import MeasurementPointAlarmForm from '../../../../readings-and-gauges/components/measurement-points/measurement-point-alarm-form';
import { ALARMS, formConstants as alarmFormConstants, defaultAlarmValues, descriptorFields } from '../../../../readings-and-gauges/constants/alarm-constants';
import { formConstants } from '../../constants/measurement-point-constants';

import ExpandableSection from '../../../../../../../common/expandable-section/components/expandable-section';
import AlarmsSection from '../../../../readings-and-gauges/components/alarms/alarms-section/alarms-section';
import '../../styles/measurement-point-alarms-tab.scss';

const MeasurementPointAlarmsTab = (props, { t }) => {
  const { hasEdit, queryItem, fetchMeasurementPointAlarms, fetchAlarmsDescriptors, selectedComponent, updateAlarms, selectedMeasurementPoint, defaultComponent } = props;

  const [modalData, setModalData] = useState({ isOpen: false });
  const [alarms, setAlarms] = useState([]);
  const [alarmsLoading, setAlarmsLoading] = useState(false);
  const [alarmDescriptors, setAlarmDescriptors] = useState([]);

  const enabledAlarms = alarms && alarms.filter(item => item[alarmFormConstants.fields.enabled.name]).map(item => item);

  useEffect(() => {
    setAlarmsLoading(true);

    // Set initial form values
    fetchMeasurementPointAlarms(
      queryItem,
      addedAlarms => {
        fetchAlarmsDescriptors(
          descriptors => {
            setAlarmDescriptors(descriptors);
            const mappedAlarms = ALARMS.map(alarm => {
              const foundAlarmIndex = (addedAlarms || []).findIndex(el => {
                return el[alarmFormConstants.fields.name.name] === alarm.id;
              });
              if (foundAlarmIndex === -1) {
                // default alarm configuration
                return defaultAlarmValues(alarm, descriptors);
              } else {
                // Means alarm is already active/enabled
                const notifyAssignees = (addedAlarms[foundAlarmIndex]?.[alarmFormConstants.fields.notifyAssignees.name] || []).map(assignee => ({
                  [userTeamFields.id]: assignee.AssigneeID,
                  [userTeamFields.type]: assignee.AssigneeType,
                  [userTeamFields.name]: assignee.AssigneeName,
                }));

                return {
                  ...addedAlarms[foundAlarmIndex],
                  [alarmFormConstants.fields.notifyAssignees.name]: notifyAssignees,
                  [alarmFormConstants.fields.descriptor.name]:
                    descriptors.find(d => d[descriptorFields.id] === addedAlarms[foundAlarmIndex]?.[alarmFormConstants.fields.descriptor.name]) || descriptors[0],
                  [alarmFormConstants.fields.enabled.name]: true,
                };
              }
            });

            setAlarms(mappedAlarms);
            setAlarmsLoading(false);
          },
          () => setAlarmsLoading(false)
        );
      },
      () => setAlarmsLoading(false)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (alarmsLoading) {
    return <Loader isLoading={true} />;
  }

  const closeModal = () => {
    setModalData({ isOpen: false });
  };

  const handleEditAlarmsSubmit = values => {
    const newAlarms = (values?.[formConstants.fields.alarms] || [])
      .filter(alarm => alarm[alarmFormConstants.fields.enabled.name])
      .map(alarm => {
        const notifyAssignees = (alarm[alarmFormConstants.fields.notifyAssignees.name] || []).map(assignee => ({
          AssigneeID: assignee[userTeamFields.id],
          AssigneeType: assignee[userTeamFields.type],
        }));

        return {
          ...alarm,
          [alarmFormConstants.fields.descriptor.name]: alarm[alarmFormConstants.fields.descriptor.name][descriptorFields.id],
          [alarmFormConstants.fields.notifyAssignees.name]: notifyAssignees,
          [alarmFormConstants.fields.alarmLevel.name]: parseFloat(alarm?.[alarmFormConstants.fields.alarmLevel.name]),
        };
      });
    updateAlarms(queryItem, newAlarms, () => {
      setAlarms(values?.[formConstants.fields.alarms]);
      closeModal();
    });
  };

  const openAlarmsEditModal = e => {
    e?.stopPropagation();
    setModalData({
      isOpen: true,
      type: '',
      customClassName: 'alarms-edit-modal modal-large',
      customClassWrapperName: 'alarms-edit-modal__picker',
      closeAction: closeModal,
      customCloseAction: closeModal,
      CustomContent: dynamicProps => (
        <MeasurementPointAlarmForm
          onSubmit={handleEditAlarmsSubmit}
          handleCancel={closeModal}
          initialValues={{
            [formConstants.fields.componentId]: selectedComponent?.[formConstants.fields.id],
            [formConstants.fields.alarms]: alarms,
            [formConstants.fields.unit]: selectedMeasurementPoint?.[formConstants.fields.unit],
          }}
          readonly={hasEdit}
          alarmDescriptors={alarmDescriptors}
          defaultComponent={defaultComponent}
          {...dynamicProps}
        />
      ),
      title: t('MEASUREMENT_POINT_DETAILS.EDIT_ALARMS_MODAL.TITLE'),
    });
  };

  return (
    <React.Fragment>
      <div className="f-primary">
        <ExpandableSection className={'alarms-tab-expandable-section'} title={t('ALARMS')} expanded={true} hideExpandAction={true} hideEditAction={hasEdit} onEditClick={openAlarmsEditModal}>
          <AlarmsSection
            alarms={enabledAlarms}
            t={t}
            openAlarmsEditModal={openAlarmsEditModal}
            handlePreventClick={() => null}
            unit={selectedMeasurementPoint[formConstants.fields.unit]}
            readonly={hasEdit}
            defaultComponent={defaultComponent}
          />
        </ExpandableSection>
      </div>
      <Modal {...modalData} />
    </React.Fragment>
  );
};

MeasurementPointAlarmsTab.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  fetchMeasurementPointAlarms: (measurementPointId, successCallback, errorCallback) => dispatch(fetchMeasurementPointAlarms(measurementPointId, successCallback, errorCallback)),
  fetchAlarmsDescriptors: (successCallback, errorCallback) => dispatch(fetchAlarmsDescriptors(successCallback, errorCallback)),
  updateAlarms: (measurementPointId, alarms, successCallback, errorCallback) => dispatch(updateMeasurementPointAlarms(measurementPointId, alarms, successCallback, errorCallback)),
});

export default connect(null, mapDispatchToProps)(MeasurementPointAlarmsTab);
