export const measurementReadingFormConstants = {
  fields: {
    values: 'Values',
    date: 'Date',
    measurementPointID: 'MeasurementPointID',
    measurementLocationID: 'MeasurementLocationID',
    measurementReadingID: 'MeasurementReadingID',
  },
};

export const previewConstants = [
  { id: 'max', label: 'MAX' },
  { id: 'min', label: 'MIN' },
  { id: 'avg', label: 'AVERAGE' },
  { id: 'median', label: 'MEDIAN' },
];
