import { setUsersData, setUsersDataTableParams, handleActivateUserModal, removeUser, handleUserModal, setRoleListData } from './action-creators';
import usersApi from '../../../api/users/actions';
import rolesApi from '../../../api/roles/actions';
import { defaultRolesFilter, filterProps } from '../constants/role-constants';
import { capitalize, isEmpty, uniqBy } from 'lodash';
import Helpers from '../../../common/helpers';
import { updateSyncErrors } from 'redux-form';
import { FORMS } from '../../../common/constants';
import { formConstants } from '../constants/constants';

export const toggleUserModal = params => {
  return dispatch => {
    dispatch(handleUserModal(params));
  };
};

export const createUser = (values, data) => {
  return async dispatch => {
    try {
      const response = await usersApi.createUser(values);
      dispatch(toggleUserModal({ isOpen: false }));
      dispatch(setUsersData([...data, Helpers.remapCreateUserObject(response.data.Data, data.length)], 'UserID'));
    } catch (e) {
      console.error(e);
      const errCode = Helpers.getErrorCode(e);
      // errCode === 1122 means that email is already taken we show message only
      if (errCode === 1122) {
        dispatch(
          updateSyncErrors(FORMS.createUser, {
            [formConstants.fields.email]: Helpers.getErrorContent(e),
          })
        );
      }
    }
  };
};

export const editUser = (values, callback = () => null) => {
  return async dispatch => {
    try {
      await usersApi.editUser(values);

      dispatch(toggleUserModal({ isOpen: false }));
      // ReducerHelpers.updateItemInListByProp does not work since UserID and AccountID props are used
      // dispatched an action to update the row in the list of Users
      callback && callback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const fetchUserDetails = (UserID, callback) => {
  return async () => {
    try {
      const res = await usersApi.getUserDetails({ UserID });
      let { Data } = res.data;
      callback && callback(Data);
    } catch (err) {
      callback && callback({});
    }
  };
};

export const getAllRoles = (SearchText = '') => {
  return async dispatch => {
    try {
      const res = await rolesApi.getRoles({ ...defaultRolesFilter, [filterProps.perPage]: Number.MAX_SAFE_INTEGER, SearchText });
      let { Data } = res.data;
      const data = Data.Items;

      dispatch(setRoleListData(data));
    } catch (err) {}
  };
};

export const getUsersTableData = (params, loadMore, callback) => {
  return async dispatch => {
    const res = await usersApi.getUsersTableData(params);

    let { Data } = res.data;
    const data = Data.Users;
    if (loadMore) {
      dispatch(setUsersData(uniqBy(loadMore.concat(data), 'UserID')));
    } else {
      dispatch(setUsersData(data));
    }

    callback && callback();

    dispatch(setUsersTableParams({ ...params, HasNext: Data.HasNext, PerPage: Data.PerPage }));
  };
};

export const getUsers = (params, loadMore, callback) => {
  return async dispatch => {
    const res = await usersApi.getUsers({ ...params, PerPage: Number.MAX_SAFE_INTEGER });

    let { Data } = res.data;
    const data = Data.Users;
    if (loadMore) {
      dispatch(setUsersData(loadMore.concat(data)));
    } else {
      dispatch(setUsersData(data));
    }

    callback && callback();

    dispatch(setUsersTableParams({ ...params, HasNext: Data.HasNext, PerPage: Data.PerPage }));
  };
};

export const setUsersTableParams = params => {
  return dispatch => {
    dispatch(setUsersDataTableParams(params));
  };
};

export const toggleActivateUserModal = params => {
  return dispatch => {
    dispatch(handleActivateUserModal(params));
  };
};

export const activateUser = (params, usersTableData) => {
  return async dispatch => {
    try {
      await usersApi.activateUser({ UserID: params.UserID, UserRoleID: params.UserRoleID });
      const foundIndex = usersTableData.findIndex(u => u.UserID === params.UserID);
      if (foundIndex > -1) {
        let newUsers = [...usersTableData];
        newUsers[foundIndex] = { ...params, UserActive: !params.UserActive };
        dispatch(setUsersData(newUsers));
        dispatch(
          toggleActivateUserModal({
            isOpen: false,
          })
        );
      }
    } catch (err) {
      dispatch(
        toggleActivateUserModal({
          isOpen: false,
        })
      );
    }
  };
};

export const deleteUser = (params, callback, index) => {
  return async dispatch => {
    try {
      await usersApi.deleteUser(params);
      callback && callback();
      dispatch(removeUser(index));
    } catch (err) {
      callback && callback();
    }
  };
};

export const fetchAndCompareMappedErrorCodes = (translationConstants, supportedErrorCodes, callback) => {
  return async dispatch => {
    try {
      const res = await usersApi.getErrorCodes();
      let { Data } = res.data;

      let translatedErrors = Object.keys(translationConstants)
        .filter(key => /^ERROR_MODAL./.test(key))
        .reduce((object, key) => {
          object[key.replace('ERROR_MODAL.', '')] = translationConstants[key];
          return object;
        }, {});

      const missingTranslations = {};
      const missingSupportedErrorCodes = [];
      Object.keys(Data).forEach(errorCodeKey => {
        const foundIndex = Object.keys(translatedErrors).findIndex(key => key === errorCodeKey);
        if (foundIndex === -1) {
          missingTranslations[`ERROR_MODAL.${errorCodeKey}`] = capitalize(Data[errorCodeKey]);
        }
        const supportedCodeFoundIndex = supportedErrorCodes.findIndex(errCode => errCode === parseInt(errorCodeKey));
        if (supportedCodeFoundIndex === -1) {
          missingSupportedErrorCodes.push(parseInt(errorCodeKey));
        }
      });
      if (!isEmpty(missingTranslations)) {
        console.warn('IMPORTANT: Missing translations for these error codes, please make sure you add them to src/languages/en.json file, ', missingTranslations);
      }
      if (!isEmpty(missingSupportedErrorCodes)) {
        console.warn(
          'IMPORTANT: Error codes that are not supported, please make sure you add them to supportedErrorsContent in src/common/modal/constants/modal-constants.js file',
          missingSupportedErrorCodes
        );
      }

      callback && callback();
    } catch (err) {
      callback && callback();
    }
  };
};
